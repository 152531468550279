var config = {
	DEFAULT_DATETIME_FORMATER: "MM/DD/YYYY",
	DEFAULT_DATETIME_SUBMIT_FORMATER: "YYYY-MM-DD[T]HH:mm:ss",
	DEFAULT_CURRENCY_UNIT: "$",
	DEFAULT_CURRENCY_FORMAT: "###,###,###.##",
	DEFAULT_CODETABLE_KEYVALUE: { KEY: "id", VALUE: "text" },
	DEFAULT_BOOLEAN_VALUE: { "TRUE": "Y", "FALSE": "N" },
	DEFAULT_VALIDATOR_CONTAINER: "popover",
	DEFAULT_STYLE_CLASS: "primary",
	DEFAULT_LOCALSTORAGE_I18NKEY: "system_i18nKey",
	DEFAULT_SYSTEM_I18N: "en_US",
	DEFAULT_DATATABLE_DROPDOWNLIST: [10, 20, 30, 40, 50],
	DEFAULT_NUMBER_FORMAT: "###,###,###,###.##",
	DEFAULT_SYSTEM_THEME: "fwd",
	DEFAULT_MESSAGE_POSITION: { DEFAULT_POSITION: "toast-top-right", SUCCESS_POSITION: "toast-top-right", INFO_POSITION: "toast-top-right", WARNING_POSITION: "toast-top-right", ERROR_POSITION: "toast-top-right" },
	DEFAULT_INPUT_LAYOUT: "horizontal",
	DEFAULT_DATATABLE_IS_INDEX: "true",
	DEFAULT_DATE_MANUAL_INPUT: "false",
	DEFAULT_LOGOUT_IS_WORKING: "false",
	DEFAULT_LOGOUT_TIME_MINUTES: "30",
	DEFAULT_COUNTDOWN_IS_WORKING: "false",
	SMART_PANELGRID_COLUMN: "2",
	DEFAULT_CLASS: { LOGIN: require('../node_modules/RainbowUI/src/js/util/LoginUtil'), PAGE_HEADER: require('../node_modules/RainbowUI/src/js/common/PageHeader') },
};
export default config;
