import {
  UICheckbox,
  UIParam,
  UIDialog,
  UILink,
  UIColumn,
  UIDataTable,
  UIPanel,
} from 'RainbowUI';
import EditPartyContactDialogReadOnly from './EditPartyContactDialogReadOnly';

const PartyContactInfo = React.createClass({
  getInitialState() {
    let PartyContactList = this.props.partyContactList;
    if (!PartyContactList) {
      PartyContactList = [];
    }
    _.forEach(PartyContactList, (tempPartyContact, index) => {
      const partyContact = tempPartyContact;
      partyContact.DefinedIndex = index + 1;
    });
    const totalNum = PartyContactList.length;
    return {
      PartyContactList,
      TotalNum: totalNum,
      party: this.props.party,
    };
  },
  viewHandler(clickEvent) {
    let partyContact = {};
    if (!_.isEmpty(clickEvent.paramJson)) {
      partyContact = clickEvent.paramJson[0].value;
    }
    this.refs.editPartyContactDialogReadOnly.showDialog(partyContact);
  },
  onClickClose() {
    UIDialog.hide('ContactDialogReadOnly');
  },

  render() {
    const { PartyContactList } = this.state;
    return (
      <div>
        <UIPanel panelTitle={i18n.ContactInfo}>
          <UIDataTable
            id="partyContractInfo"
            value={PartyContactList}
            indexable="false"
          >
            <UIColumn
              headerTitle={i18n.PrimaryContact}
              render={data => {
                return (
                  <UICheckbox
                    single="true"
                    model={data}
                    property="IsPrimaryContact"
                    disabled="true"
                  />
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.ContactPerson}
              value="Name"
              render={data => {
                return (
                  <div>
                    <UILink
                      value={data.ContactPerson}
                      enabled="true"
                      disabled="false"
                      onClick={this.viewHandler}
                    >
                      <UIParam name="data" value={data} />
                    </UILink>
                  </div>
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.MobileTel}
              value="HandPhone"
            />
            <UIColumn
              headerTitle={i18n.WorkTel}
              value="BusinessTel"
            />
            <UIColumn headerTitle={i18n.email} value="Email" />
          </UIDataTable>
          <EditPartyContactDialogReadOnly ref="editPartyContactDialogReadOnly" />
        </UIPanel>
      </div>
    );
  },
});
export default PartyContactInfo;
