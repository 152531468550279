export default {
  Remark: '备注',
  Calculate: '理算',
  Calculation: '理算',
  CalculationPanelTitle: '理算明细',
  AddItem: '添加',
  Selected: ' 已选',
  PleaseSelect: '请选择',

  SelectCoverage: '选择理算险种',
  TotalIndemnityAmount: '累计损失',
  TotalExpenseAmount: '累计费用',
  NoItem: '请添加理算项目',

  AppraisalAmount: '估算金额',
  NumberofUnits: '单位数量',
  CalculatedAmount: '核算金额',
  AdjustedAmount: '调整金额',
  LimitAndDeductionInfo: '赔偿限额及免赔额信息',

  SettledAmount: '结算金额',
  ExpenseAmount: '费用',

  CalculationHistory: '理算历史',
  TransactionDate: '提交时间',
  IssuedBy: '提交人',
  Approver: '审核人',
  CalculationAmount: '理算金额',
  SeqNo: '序号',
  CalculationHistoryDetail: '理算历史明细',
  CalculationExpense: '计算开支',

  CalculationSaveSuccess: '理算保存成功',
  CalculationSubmitSuccess: '理算提交成功',
  CalculationCalculateSuccess: '理算成功',
  CalculattionApprovalSubmitSuccess: '理算签核提交成功',
  AtleastOneItem: '至少添加一个子赔案',
  SameReserveTypeError: '同一子赔案下的同一准备金类型不能多次选择',
  IndemnityAmountError: '调整金额不能少于结算金额',
  ExpenseAmountError: '费用金额不能少于结算金额',
  CalculateApprove: '理算签核',
};
