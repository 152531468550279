export default {
  calculationItemToAdd: {
    insuredId: null,
    coverageCode: null,
    index: 0,
    initial(insuredId, coverageCode, index) {
      this.insuredId = insuredId;
      this.coverageCode = coverageCode;
      this.index = index;
    },
    setIndex(index) {
      this.index = index;
    },
    get() {
      return {
        '@pk': null,
        '@type': 'ClaimCalculationItem-ClaimCalculationItem',

        InsuredId: this.insuredId,
        CoverageCode: this.coverageCode,
        Index: this.index,
        ReserveCurrency: '',
        CurrencyCode: '',
        ItemId: '',
        RequestAmount: '',
        RecognizedAmount: '',
        IndemnityAmount: '',
        InvoiceNo: '',
      };
    },
  },
  calculationExpenseToAdd: {
    insuredId: null,
    coverageCode: null,
    index: 0,
    initial(insuredId, coverageCode, index) {
      this.insuredId = insuredId;
      this.coverageCode = coverageCode;
      this.index = index;
    },
    setIndex(index) {
      this.index = index;
    },
    get() {
      return {
        '@pk': null,
        '@type': 'ClaimCalculationExpense-ClaimCalculationExpense',
        BusinessObjectId: null,
        CalExpenseId: null,
        CoverageCode: this.coverageCode,
        ExpenseAmount: null,
        Index: this.index,
        InsuredId: this.insuredId,
        ItemId: null,
        ReserveAmount: null,
        ReserveCurrency: '',
        ReserveId: null,
        ReserveType: null,
        SettledAmount: null,
        CurrencyCode: '',
      };
    },
  },
};
