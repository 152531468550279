import React from 'react';
import PropTypes from 'prop-types';
import BindToMixin from 'react-binding';

const SubrogationReadOnlyStore = require('../store/SubrogationReadOnlyStore');
const SubrogationAction = require('../action/SubrogationAction');
const SubrogationInfo = require('./SubrogationInfo');

const SubrogationReadOnly = React.createClass({
  mixins: [BindToMixin],
  propTypes: {
    subrogationId: PropTypes.string.isRequired,
    isApproveTask: PropTypes.bool,
  },
  getInitialState() {
    return {
      SubrogationReadOnly: {
        SubrogationInfo: { SubrogationItems: [] },
        OtherInfo: {},
      },

      SubrogationApprove: {
        SubrogationInfo: { SubrogationItems: [] },
        OtherInfo: {},
      },
    };
  },

  componentWillMount() {
    SubrogationAction.loadSubrogationReadOnly(
      this.props.subrogationId,
      this.props.isApproveTask
    );
    SubrogationReadOnlyStore.listen(this.onStoreStateChange);
  },

  componentWillUnMount() {
    SubrogationReadOnlyStore.unlisten(this.onStoreStateChange);
  },

  onStoreStateChange(storeState) {
    if (this.props.isApproveTask == true) {
      if (!_.isEmpty(storeState.SubrogationApprove)) {
        this.setState({
          SubrogationApprove: storeState.SubrogationApprove,
        });
      }
    } else if (!_.isEmpty(storeState.SubrogationReadOnly)) {
      this.setState({
        SubrogationReadOnly: storeState.SubrogationReadOnly,
      });
    }
  },

  render() {
    let subrogationInfo = this.bindToState(
      'SubrogationReadOnly',
      'SubrogationInfo'
    );
    let otherInfo = this.bindToState(
      'SubrogationReadOnly',
      'OtherInfo'
    );
    const { isApproveTask } = this.props;
    const { history } = this.props;
    if (isApproveTask == true) {
      subrogationInfo = this.bindToState(
        'SubrogationApprove',
        'SubrogationInfo'
      );
      otherInfo = this.bindToState('SubrogationApprove', 'OtherInfo');
    }
    if (_.isEmpty(subrogationInfo.value)) {
      return <div />;
    }
    return (
      <div id={`SubrogationReadOnly_${isApproveTask}_div`}>
        <SubrogationInfo
          subrogationInfo={subrogationInfo}
          history={history}
          otherInfo={otherInfo}
          readOnly
          isApproveTask={isApproveTask}
        />
      </div>
    );
  },
});
export default SubrogationReadOnly;
