import { UIConfirmDialog } from 'RainbowUI';
import ClaimAuthorityAction from '../../action/ClaimAuthorityAction';

const ComfirmClosedTask = React.createClass({
  showDialog() {
    UIConfirmDialog.show('ConfirmDialog');
  },
  resetAuthority() {
    const { permissionCode } = this.props.parentParam;
    const { path } = this.props.parentParam;
    const { type } = this.props.parentParam;
    ClaimAuthorityAction.resetAuthority(permissionCode, path, type);
    UIConfirmDialog.hide('ConfirmDialog');
  },
  render() {
    return (
      <div>
        <UIConfirmDialog
          id="ConfirmDialog"
          title={i18n.ClaimCommon.ResetAuthority}
          message={i18n.ClaimCommon.AuthorityConfirmReset}
          onConfirm={this.resetAuthority}
        />
      </div>
    );
  },
});
export default ComfirmClosedTask;
