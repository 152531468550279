export default {
  '@type': 'ClaimPolicy-ClaimPolicy',
  OrganId: '1',
  PolicyNo: 'Q20160000000005',
  ProductCode: 'BTPA 1.0',
  ProductTypeCode: 'BTPA',
  EffDate: '2015-01-01',
  ExpDate: '2016-12-30',
  Premium: 5000.0,
  PremiumIsPaid: 'Y',
  CurrencyCode: 'USD',
  InsuredList: [
    {
      '@type': 'ClaimPolicyInsured-ClaimPolicyInsured',
      InsuredName: 'Sherry Gou',
      SumInsured: 0.0,
      InsuredCategory: 2,
      InsuredUid: 'Sherry Gou',
      CoverageList: [
        {
          '@type': 'ClaimPolicyCoverage-ClaimPolicyCoverage',
          CoverageName: 'Accidental Death, Burns& dismemberment',
          DeductibleAmount: 0,
          DeductibleRate: 0.0,
          SumInsured: 200000,
          PolicySumInsured: 200000.0,
          PolicyDeductibleRate: 0.0,
          PolicyDeductibleAmount: 0.0,
          CoverageCode: 'ACCI',
          ProductCode: 'BTPA 1.0',
          PolicyLimit: {
            '@type': 'ClaimPolicyLimit-ClaimPolicyLimit',
            PolicyLimitValue: [
              {
                '@type':
                  'ClaimPolicyLimitValue-ClaimPolicyLimitValue',
                LimitValue: 200000,
                ModeType: 'AOA',
                Term: '200000 Per Accident',
                ValueType: 'AMOUNT',
              },
            ],
          },
        },
        {
          '@type': 'ClaimPolicyCoverage-ClaimPolicyCoverage',
          CoverageName: 'Medical Reimbursement',
          DeductibleAmount: 0,
          DeductibleRate: 0.0,
          SumInsured: 100000,
          PolicySumInsured: 100000.0,
          PolicyDeductibleRate: 0.0,
          PolicyDeductibleAmount: 0.0,
          CoverageCode: 'MEDI1.0',
          ProductCode: 'BTPA 1.0',
          PolicyLimit: {
            '@type': 'ClaimPolicyLimit-ClaimPolicyLimit',
            PolicyLimitValue: [
              {
                '@type':
                  'ClaimPolicyLimitValue-ClaimPolicyLimitValue',
                LimitValue: 100000,
                ModeType: 'AOA',
                Term: '100000 Per Accident',
                ValueType: 'AMOUNT',
              },
            ],
          },
        },
        {
          '@type': 'ClaimPolicyCoverage-ClaimPolicyCoverage',
          CoverageName: 'Travler Personal Effects',
          DeductibleAmount: 0,
          DeductibleRate: 0.0,
          SumInsured: 8000,
          PolicySumInsured: 8000.0,
          PolicyDeductibleRate: 0.0,
          PolicyDeductibleAmount: 0.0,
          CoverageCode: 'TRAV',
          ProductCode: 'BTPA 1.0',
          PolicyLimit: {
            '@type': 'ClaimPolicyLimit-ClaimPolicyLimit',
            PolicyLimitValue: [
              {
                '@type':
                  'ClaimPolicyLimitValue-ClaimPolicyLimitValue',
                LimitValue: 8000,
                ModeType: 'AOA',
                Term: '8000 Per Accident',
                ValueType: 'AMOUNT',
              },
            ],
          },
        },
        {
          '@type': 'ClaimPolicyCoverage-ClaimPolicyCoverage',
          CoverageName: 'Property Damage',
          DeductibleAmount: 100,
          DeductibleRate: 0.0,
          SumInsured: 1500,
          PolicySumInsured: 1500.0,
          PolicyDeductibleRate: 0.0,
          PolicyDeductibleAmount: 0.0,
          CoverageCode: 'PRDA2.0',
          UpperCoverageCode: 'TRAV',
          UpperCoverageName: 'Travler Personal Effects',
          ProductCode: 'BTPA 1.0',
          PolicyLimit: {
            '@type': 'ClaimPolicyLimit-ClaimPolicyLimit',
            PolicyLimitValue: [
              {
                '@type':
                  'ClaimPolicyLimitValue-ClaimPolicyLimitValue',
                IsAllowance: 0,
                ModeType: 'AOA',
                Term: '1500 Per Item',
                UnitAmount: 1500,
                UnitType: 'ITEM',
                UnitValue: 1,
                ValueType: 'UNIT',
              },
            ],
          },
          PolicyDeductible: {
            '@type': 'ClaimPolicyDeductible-ClaimPolicyDeductible',
            PolicyDeductibleValue: [
              {
                '@type':
                  'ClaimPolicyDeductibleValue-ClaimPolicyDeductibleValue',
                IsAbsolute: 1,
                ModeType: 'AOA',
                Term: '100 Per Item Deductible',
                UnitAmount: 100,
                UnitType: 'ITEM',
                UnitValue: 1,
                ValueType: 'UNIT',
              },
            ],
          },
        },
        {
          '@type': 'ClaimPolicyCoverage-ClaimPolicyCoverage',
          CoverageName: 'Electronic Device',
          DeductibleAmount: 100,
          DeductibleRate: 0.0,
          SumInsured: 3500,
          PolicySumInsured: 3500.0,
          PolicyDeductibleRate: 0.0,
          PolicyDeductibleAmount: 0.0,
          CoverageCode: 'ELEC',
          UpperCoverageCode: 'TRAV',
          UpperCoverageName: 'Travler Personal Effects',
          ProductCode: 'BTPA 1.0',
          PolicyLimit: {
            '@type': 'ClaimPolicyLimit-ClaimPolicyLimit',
            PolicyLimitValue: [
              {
                '@type':
                  'ClaimPolicyLimitValue-ClaimPolicyLimitValue',
                IsAllowance: 0,
                ModeType: 'AOA',
                Term: '3500 Per Item',
                UnitAmount: 3500,
                UnitType: 'ITEM',
                UnitValue: 1,
                ValueType: 'UNIT',
              },
            ],
          },
          PolicyDeductible: {
            '@type': 'ClaimPolicyDeductible-ClaimPolicyDeductible',
            PolicyDeductibleValue: [
              {
                '@type':
                  'ClaimPolicyDeductibleValue-ClaimPolicyDeductibleValue',
                IsAbsolute: 1,
                ModeType: 'AOA',
                Term: '100 Per Item Deductible',
                UnitAmount: 100,
                UnitType: 'ITEM',
                UnitValue: 1,
                ValueType: 'UNIT',
              },
            ],
          },
        },
        {
          '@type': 'ClaimPolicyCoverage-ClaimPolicyCoverage',
          CoverageName: 'Loss of Money',
          DeductibleAmount: 100,
          DeductibleRate: 0.0,
          SumInsured: 8000,
          PolicySumInsured: 8000.0,
          PolicyDeductibleRate: 0.0,
          PolicyDeductibleAmount: 0.0,
          CoverageCode: 'LOMO',
          ProductCode: 'BTPA 1.0',
          PolicyLimit: {
            '@type': 'ClaimPolicyLimit-ClaimPolicyLimit',
            PolicyLimitValue: [
              {
                '@type':
                  'ClaimPolicyLimitValue-ClaimPolicyLimitValue',
                LimitValue: 8000,
                ModeType: 'AOA',
                Term: '8000 Per Accident',
                ValueType: 'AMOUNT',
              },
            ],
          },
          PolicyDeductible: {
            '@type': 'ClaimPolicyDeductible-ClaimPolicyDeductible',
            PolicyDeductibleValue: [
              {
                '@type':
                  'ClaimPolicyDeductibleValue-ClaimPolicyDeductibleValue',
                DeductibleValue: 100,
                IsAbsolute: 1,
                ModeType: 'AOA',
                Term: '100 Deductible Per Accident',
                ValueType: 'AMOUNT',
              },
            ],
          },
        },
        {
          '@type': 'ClaimPolicyCoverage-ClaimPolicyCoverage',
          CoverageName: 'Loss of Travel Documents',
          DeductibleAmount: 0,
          DeductibleRate: 0.0,
          SumInsured: 2500,
          PolicySumInsured: 2500.0,
          PolicyDeductibleRate: 0.0,
          PolicyDeductibleAmount: 0.0,
          CoverageCode: 'LOTD',
          ProductCode: 'BTPA 1.0',
          PolicyLimit: {
            '@type': 'ClaimPolicyLimit-ClaimPolicyLimit',
            PolicyLimitValue: [
              {
                '@type':
                  'ClaimPolicyLimitValue-ClaimPolicyLimitValue',
                LimitValue: 2500,
                ModeType: 'AOA',
                Term: '2500 Per Accident',
                ValueType: 'AMOUNT',
              },
            ],
          },
        },
        {
          '@type': 'ClaimPolicyCoverage-ClaimPolicyCoverage',
          CoverageName: 'Travel Delay',
          DeductibleAmount: 0,
          DeductibleRate: 0.0,
          SumInsured: 600,
          PolicySumInsured: 600.0,
          PolicyDeductibleRate: 0.0,
          PolicyDeductibleAmount: 0.0,
          CoverageCode: 'TRDE',
          ProductCode: 'BTPA 1.0',
          PolicyLimit: {
            '@type': 'ClaimPolicyLimit-ClaimPolicyLimit',
            PolicyLimitValue: [
              {
                '@type':
                  'ClaimPolicyLimitValue-ClaimPolicyLimitValue',
                IsAllowance: 1,
                LimitValue: 600,
                ModeType: 'AOA',
                Term: '300 Every 4 Hour, 600 Per Accident',
                UnitAmount: 300,
                UnitType: 'HOUR',
                UnitValue: 4,
                ValueType: 'AMOUNT',
              },
            ],
          },
        },
        {
          '@type': 'ClaimPolicyCoverage-ClaimPolicyCoverage',
          CoverageName: 'Baggage Delay',
          DeductibleAmount: 0,
          DeductibleRate: 0.0,
          SumInsured: 1000,
          PolicySumInsured: 1000.0,
          PolicyDeductibleRate: 0.0,
          PolicyDeductibleAmount: 0.0,
          CoverageCode: 'BAGA',
          ProductCode: 'BTPA 1.0',
          PolicyLimit: {
            '@type': 'ClaimPolicyLimit-ClaimPolicyLimit',
            PolicyLimitValue: [
              {
                '@type':
                  'ClaimPolicyLimitValue-ClaimPolicyLimitValue',
                LimitValue: 1000,
                ModeType: 'AOA',
                Term: '1000 Per Accident',
                ValueType: 'AMOUNT',
              },
            ],
          },
        },
      ],
    },
    {
      '@type': 'ClaimPolicyInsured-ClaimPolicyInsured',
      InsuredName: 'Alfonso Wang',
      SumInsured: 0.0,
      InsuredCategory: 2,
      InsuredUid: 'Alfonso Wang',
      CoverageList: [
        {
          '@type': 'ClaimPolicyCoverage-ClaimPolicyCoverage',
          CoverageName: 'Accidental Death, Burns& dismemberment',
          DeductibleAmount: 0,
          DeductibleRate: 0.0,
          SumInsured: 400000,
          PolicySumInsured: 400000.0,
          PolicyDeductibleRate: 0.0,
          PolicyDeductibleAmount: 0.0,
          CoverageCode: 'ACCI',
          ProductCode: 'BTPA 1.0',
          PolicyLimit: {
            '@type': 'ClaimPolicyLimit-ClaimPolicyLimit',
            PolicyLimitValue: [
              {
                '@type':
                  'ClaimPolicyLimitValue-ClaimPolicyLimitValue',
                LimitValue: 400000,
                ModeType: 'AOA',
                Term: '400000 Per Accident',
                ValueType: 'AMOUNT',
              },
            ],
          },
        },
        {
          '@type': 'ClaimPolicyCoverage-ClaimPolicyCoverage',
          CoverageName: 'Medical Reimbursement',
          DeductibleAmount: 0,
          DeductibleRate: 0.0,
          SumInsured: 200000,
          PolicySumInsured: 200000.0,
          PolicyDeductibleRate: 0.0,
          PolicyDeductibleAmount: 0.0,
          CoverageCode: 'MEDI1.0',
          ProductCode: 'BTPA 1.0',
          PolicyLimit: {
            '@type': 'ClaimPolicyLimit-ClaimPolicyLimit',
            PolicyLimitValue: [
              {
                '@type':
                  'ClaimPolicyLimitValue-ClaimPolicyLimitValue',
                LimitValue: 200000,
                ModeType: 'AOA',
                Term: '100000 Per Accident',
                ValueType: 'AMOUNT',
              },
            ],
          },
        },
        {
          '@type': 'ClaimPolicyCoverage-ClaimPolicyCoverage',
          CoverageName: 'Travler Personal Effects',
          DeductibleAmount: 0,
          DeductibleRate: 0.0,
          SumInsured: 8000,
          PolicySumInsured: 8000.0,
          PolicyDeductibleRate: 0.0,
          PolicyDeductibleAmount: 0.0,
          CoverageCode: 'TRAV',
          ProductCode: 'BTPA 1.0',
          PolicyLimit: {
            '@type': 'ClaimPolicyLimit-ClaimPolicyLimit',
            PolicyLimitValue: [
              {
                '@type':
                  'ClaimPolicyLimitValue-ClaimPolicyLimitValue',
                LimitValue: 8000,
                ModeType: 'AOA',
                Term: '8000 Per Accident',
                ValueType: 'AMOUNT',
              },
            ],
          },
        },
        {
          '@type': 'ClaimPolicyCoverage-ClaimPolicyCoverage',
          CoverageName: 'Property Damage',
          DeductibleAmount: 100,
          DeductibleRate: 0.0,
          SumInsured: 1500,
          PolicySumInsured: 1500.0,
          PolicyDeductibleRate: 0.0,
          PolicyDeductibleAmount: 0.0,
          CoverageCode: 'PRDA2.0',
          UpperCoverageCode: 'TRAV',
          UpperCoverageName: 'Travler Personal Effects',
          ProductCode: 'BTPA 1.0',
          PolicyLimit: {
            '@type': 'ClaimPolicyLimit-ClaimPolicyLimit',
            PolicyLimitValue: [
              {
                '@type':
                  'ClaimPolicyLimitValue-ClaimPolicyLimitValue',
                IsAllowance: 0,
                ModeType: 'AOA',
                Term: '1500 Per Item',
                UnitAmount: 1500,
                UnitType: 'ITEM',
                UnitValue: 1,
                ValueType: 'UNIT',
              },
            ],
          },
          PolicyDeductible: {
            '@type': 'ClaimPolicyDeductible-ClaimPolicyDeductible',
            PolicyDeductibleValue: [
              {
                '@type':
                  'ClaimPolicyDeductibleValue-ClaimPolicyDeductibleValue',
                IsAbsolute: 1,
                ModeType: 'AOA',
                Term: '100 Per Item Deductible',
                UnitAmount: 100,
                UnitType: 'ITEM',
                UnitValue: 1,
                ValueType: 'UNIT',
              },
            ],
          },
        },
        {
          '@type': 'ClaimPolicyCoverage-ClaimPolicyCoverage',
          CoverageName: 'Electronic Device',
          DeductibleAmount: 100,
          DeductibleRate: 0.0,
          SumInsured: 3500,
          PolicySumInsured: 3500.0,
          PolicyDeductibleRate: 0.0,
          PolicyDeductibleAmount: 0.0,
          CoverageCode: 'ELEC',
          UpperCoverageCode: 'TRAV',
          UpperCoverageName: 'Travler Personal Effects',
          ProductCode: 'BTPA 1.0',
          PolicyLimit: {
            '@type': 'ClaimPolicyLimit-ClaimPolicyLimit',
            PolicyLimitValue: [
              {
                '@type':
                  'ClaimPolicyLimitValue-ClaimPolicyLimitValue',
                IsAllowance: 0,
                ModeType: 'AOA',
                Term: '3500 Per Item',
                UnitAmount: 3500,
                UnitType: 'ITEM',
                UnitValue: 1,
                ValueType: 'UNIT',
              },
            ],
          },
          PolicyDeductible: {
            '@type': 'ClaimPolicyDeductible-ClaimPolicyDeductible',
            PolicyDeductibleValue: [
              {
                '@type':
                  'ClaimPolicyDeductibleValue-ClaimPolicyDeductibleValue',
                IsAbsolute: 1,
                ModeType: 'AOA',
                Term: '100 Per Item Deductible',
                UnitAmount: 100,
                UnitType: 'ITEM',
                UnitValue: 1,
                ValueType: 'UNIT',
              },
            ],
          },
        },
        {
          '@type': 'ClaimPolicyCoverage-ClaimPolicyCoverage',
          CoverageName: 'Loss of Money',
          DeductibleAmount: 100,
          DeductibleRate: 0.0,
          SumInsured: 8000,
          PolicySumInsured: 8000.0,
          PolicyDeductibleRate: 0.0,
          PolicyDeductibleAmount: 0.0,
          CoverageCode: 'LOMO',
          ProductCode: 'BTPA 1.0',
          PolicyLimit: {
            '@type': 'ClaimPolicyLimit-ClaimPolicyLimit',
            PolicyLimitValue: [
              {
                '@type':
                  'ClaimPolicyLimitValue-ClaimPolicyLimitValue',
                LimitValue: 8000,
                ModeType: 'AOA',
                Term: '8000 Per Accident',
                ValueType: 'AMOUNT',
              },
            ],
          },
          PolicyDeductible: {
            '@type': 'ClaimPolicyDeductible-ClaimPolicyDeductible',
            PolicyDeductibleValue: [
              {
                '@type':
                  'ClaimPolicyDeductibleValue-ClaimPolicyDeductibleValue',
                DeductibleValue: 100,
                IsAbsolute: 1,
                ModeType: 'AOA',
                Term: '100 Deductible Per Accident',
                ValueType: 'AMOUNT',
              },
            ],
          },
        },
        {
          '@type': 'ClaimPolicyCoverage-ClaimPolicyCoverage',
          CoverageName: 'Loss of Travel Documents',
          DeductibleAmount: 0,
          DeductibleRate: 0.0,
          SumInsured: 2500,
          PolicySumInsured: 2500.0,
          PolicyDeductibleRate: 0.0,
          PolicyDeductibleAmount: 0.0,
          CoverageCode: 'LOTD',
          ProductCode: 'BTPA 1.0',
          PolicyLimit: {
            '@type': 'ClaimPolicyLimit-ClaimPolicyLimit',
            PolicyLimitValue: [
              {
                '@type':
                  'ClaimPolicyLimitValue-ClaimPolicyLimitValue',
                LimitValue: 2500,
                ModeType: 'AOA',
                Term: '2500 Per Accident',
                ValueType: 'AMOUNT',
              },
            ],
          },
        },
        {
          '@type': 'ClaimPolicyCoverage-ClaimPolicyCoverage',
          CoverageName: 'Travel Delay',
          DeductibleAmount: 0,
          DeductibleRate: 0.0,
          SumInsured: 600,
          PolicySumInsured: 600.0,
          PolicyDeductibleRate: 0.0,
          PolicyDeductibleAmount: 0.0,
          CoverageCode: 'TRDE',
          ProductCode: 'BTPA 1.0',
          PolicyLimit: {
            '@type': 'ClaimPolicyLimit-ClaimPolicyLimit',
            PolicyLimitValue: [
              {
                '@type':
                  'ClaimPolicyLimitValue-ClaimPolicyLimitValue',
                IsAllowance: 1,
                LimitValue: 600,
                ModeType: 'AOA',
                Term: '300 Every 4 Hour, 600 Per Accident',
                UnitAmount: 300,
                UnitType: 'HOUR',
                UnitValue: 4,
                ValueType: 'AMOUNT',
              },
            ],
          },
        },
        {
          '@type': 'ClaimPolicyCoverage-ClaimPolicyCoverage',
          CoverageName: 'Baggage Delay',
          DeductibleAmount: 0,
          DeductibleRate: 0.0,
          SumInsured: 1000,
          PolicySumInsured: 1000.0,
          PolicyDeductibleRate: 0.0,
          PolicyDeductibleAmount: 0.0,
          CoverageCode: 'BAGA',
          ProductCode: 'BTPA 1.0',
          PolicyLimit: {
            '@type': 'ClaimPolicyLimit-ClaimPolicyLimit',
            PolicyLimitValue: [
              {
                '@type':
                  'ClaimPolicyLimitValue-ClaimPolicyLimitValue',
                LimitValue: 1000,
                ModeType: 'AOA',
                Term: '1000 Per Accident',
                ValueType: 'AMOUNT',
              },
            ],
          },
        },
      ],
    },
  ],
  PartyList: [
    {
      '@type': 'ClaimPolicyParty-ClaimPolicyParty',
      RoleType: '07',
      PartyName: 'Stella Company',
      ContactFirstName: '',
      ContactLastName: '',
      ContactName: 'Stella Company',
      ContactTelephone: '1380013800',
      IdType: '1',
      IdNumber: 'MockPolicy43209223454563443279',
      ContactAddress: '777 Guoding Rd, Shanghai, China, 200433',
      ContactType: '2',
      CommonPartyId: 36400366,
    },
    {
      '@type': 'ClaimPolicyParty-ClaimPolicyParty',
      RoleType: '08',
      PartyName: 'Fengying Ye',
      ContactFirstName: 'Fengying',
      ContactLastName: 'Ye',
      ContactName: 'Fengying Ye',
      ContactTelephone: '115261511579',
      IdType: '1',
      IdNumber: 'MockPolicy43209223454563443277',
      ContactAddress: 'wuxi',
      ContactType: '1',
      CommonPartyId: 36981385,
    },
  ],
};
