import AuditService from '../service/AuditService';
import ClaimCstUI from '../constant/ClaimCstUI';
import CodeTableAction from './CodeTableAction';
import AuditUtil from '../util/AuditUtil';

export default {
  getAuditDataList(
    auditEventType,
    objectId,
    pageIndex,
    pageSize,
    caseId
  ) {
    let searchResult = {};
    AuditService.queryAuditData(
      auditEventType,
      objectId,
      pageIndex,
      pageSize
    ).then(data => {
      if (data.Status == ClaimCstUI.REST_RESTPONSE_STATUS_OK) {
        searchResult = data.Model;
      } else if (
        data.Status == ClaimCstUI.REST_RESTPONSE_STATUS_BLOCK
      ) {
        MessageHelper.error(i18n.ClaimCommon.Fail);
      }
    });

    return searchResult;
  },

  handleChangeData(content, auditEventType, caseId) {
    const self = this;
    const result = [];
    let claimants = [];
    AuditService.queryCaseClaimantPartyData(caseId).then(data => {
      if (data.Status == ClaimCstUI.REST_RESTPONSE_STATUS_OK) {
        claimants = data.Model;
      } else if (
        data.Status == ClaimCstUI.REST_RESTPONSE_STATUS_BLOCK
      ) {
        MessageHelper.error(i18n.ClaimCommon.Fail);
      }
    });

    $(content).each((index, datas) => {
      const data = datas;
      const changeFieldList = [];
      $(JSON.parse(data.ChangeData)).each((index1, data1) => {
        const path = data1.path.replace('/', '');

        const i18nParm = AuditUtil[auditEventType][path];
        if (i18nParm) {
          const codetableId =
            AuditUtil[auditEventType][`${path}CodeTableId`];
          const i18nParmList = i18nParm.split('.');
          let msg;
          if (ClaimCstUI.AUDIT_ADD == data1.op) {
            msg = i18n.ClaimCommon.AuditAdd;

            let newVal;
            if (codetableId == 1051) {
              $(claimants).each((i, d) => {
                if (d.PartyId == data1.value) {
                  newVal = d.PartyName;
                  return false;
                }
              });
            } else {
              newVal = self.getValue(data1.value, codetableId);
            }
            changeFieldList.push(
              msg
                .replace(
                  ClaimCstUI.PLACEHOLDER_FIELDNAME,
                  i18n[i18nParmList[0]][i18nParmList[1]]
                )
                .replace(ClaimCstUI.PLACEHOLDER_NEW_VALUE, newVal)
            );
          } else if (ClaimCstUI.AUDIT_REMOVE == data1.op) {
            msg = i18n.ClaimCommon.AuditRemove;
            changeFieldList.push(
              msg.replace(
                ClaimCstUI.PLACEHOLDER_FIELDNAME,
                i18n[i18nParmList[0]][i18nParmList[1]]
              )
            );
          } else if (ClaimCstUI.AUDIT_REPLACE == data1.op) {
            msg = i18n.ClaimCommon.AuditReplace;
            let oldVal;
            let newVal;

            if (codetableId == 1051) {
              oldVal = data1.oldValue;
              newVal = data1.newValue;
            } else {
              oldVal = self.getValue(data1.oldValue, codetableId);
              newVal = self.getValue(data1.newValue, codetableId);
            }

            changeFieldList.push(
              msg
                .replace(
                  ClaimCstUI.PLACEHOLDER_FIELDNAME,
                  i18n[i18nParmList[0]][i18nParmList[1]]
                )
                .replace(ClaimCstUI.PLACEHOLDER_OLD_VALUE, oldVal)
                .replace(ClaimCstUI.PLACEHOLDER_NEW_VALUE, newVal)
            );
          }
        }
      });
      if (changeFieldList.length > 0) {
        data.ChangeFieldList = _.sortBy(changeFieldList, n => {
          return n;
        });
        result.push(data);
      }
    });

    return result;
  },

  getValue(tmpvalue, codetableId) {
    let value = tmpvalue;
    if (codetableId != null && codetableId !== '') {
      const codeTableData = CodeTableAction.loadDataList(
        codetableId,
        null
      );
      if (codeTableData.map[value]) {
        value = codeTableData.map[value].text;
      }
    }

    return value;
  },
};
