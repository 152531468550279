import { MessageHelper } from 'RainbowUI';
import alt from '../alt';

class ClaimAuthorityUtilAction {
  getParent(list, vo) {
    const ParentCode = vo.pId;
    for (const item in list) {
      if (ParentCode == list[item].id) {
        return list[item];
      }
    }
  }

  resetChild(tmplist, vo) {
    const list = tmplist;
    for (const item in list) {
      if (list[item].pId == vo.Code) {
        list[item].vo.AssignedFlag = 'N';
        list[item].vo.LimitedAmount = 0;

        list[item].io = 'out';
        const showFlag = this.actions.checkShowFlag(list[item]);
        list[item].showFlag = showFlag;
      }
    }
    return list;
  }

  checkChild(tmplist, vo) {
    const list = tmplist;
    for (const item in list) {
      if (list[item].pId && list[item].pId == vo.Code) {
        list[item].io = 'in';
      }
    }

    return list;
  }

  getPermissionCode(href) {
    const href_arry1 = href.split('?');
    let permissionCode = null;
    for (const item1 in href_arry1) {
      const index1 = href_arry1[item1].indexOf('permissionCode');

      if (index1 > -1) {
        const href_arry2 = href_arry1[item1].split('&');
        for (const item2 in href_arry2) {
          const str = href_arry2[item2];
          const index2 = str.indexOf('permissionCode');

          if (index2 > -1) {
            permissionCode = str.substring(
              str.indexOf('=') + 1,
              str.length
            );
          }
        }
      }
    }
    if (!permissionCode) {
      MessageHelper.error(
        i18n.ClaimCommon.ErrorMsg.PARSEPERMISSIONCODEERROR,
        null,
        null
      );
    } else {
      return permissionCode;
    }
  }

  checkShowFlag(data) {
    const { vo } = data;

    if (vo.path === 'root') {
      return 'N';
    }
    let showFlag = null;
    if (vo.ConfigFlag === 'Y') {
      showFlag = 'N';
    } else if (vo.DataType === 'checkbox') {
      if (vo.AssignedFlag == vo.OrginalValue) {
        showFlag = 'Y';
      } else {
        showFlag = 'N';
      }
    } else if (vo.DataType === 'amount') {
      if (vo.LimitedAmount == vo.OrginalValue) {
        showFlag = 'Y';
      } else {
        showFlag = 'N';
      }
    }
    return showFlag;
  }

  getAssignFlag(name, list) {
    let flag = 'N';
    for (const item in list) {
      const data = list[item];
      if (name == `checkable${data.id}`) {
        flag = data.vo.AssignedFlag;
      }
    }
    return flag;
  }
}
export default alt.createActions(ClaimAuthorityUtilAction);
