import ClaimWorkFlowMessageCriteria from './ClaimWorkFlowMessageCriteria';
import ClaimWorkFlowMessageList from './ClaimWorkFlowMessageList';

const ClaimWorkFlowMessage = React.createClass({
  format(date, tmpfmt, status) {
    let fmt = tmpfmt;
    const o = {
      'M+': date.getMonth() + 1,
      'd+': date.getDate() + status,
      'h+': date.getHours(),
      'm+': date.getMinutes(),
      's+': date.getSeconds(),
      'q+': Math.floor((date.getMonth() + 3) / 3),
      S: date.getMilliseconds(),
    };
    if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        `${date.getFullYear()}`.substr(4 - RegExp.$1.length)
      );
    }
    for (const k in o) {
      if (new RegExp(`(${k})`).test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          RegExp.$1.length === 1
            ? o[k]
            : `00${o[k]}`.substr(`${o[k]}`.length)
        );
      }
    }
    return fmt;
  },
  render() {
    return (
      <div>
        <ClaimWorkFlowMessageCriteria format={this.format} />
        <ClaimWorkFlowMessageList format={this.format} />
      </div>
    );
  },
});
export default ClaimWorkFlowMessage;
