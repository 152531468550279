import {
  UIDataTable,
  UIColumn,
  UIDateTimePicker,
  UIText,
} from 'RainbowUI';
import BindToMixin from 'react-binding';

const ApprovalHistoryAction = require('../../common/action/ApprovalHistoryAction');
const CodeTableAction = require('../../common/action/CodeTableAction');
const CodeTableCstUI = require('../../common/constant/CodeTableCstUI');

const ReserveApprovalHistory = React.createClass({
  mixins: [BindToMixin],

  getInitialState() {
    return {
      approvelist: {},
    };
  },

  componentWillMount() {
    const data = ApprovalHistoryAction.initDataSyn(
      this.props.processObjectId,
      this.props.isHistory
    );
    this.setState({ approvelist: data });
  },

  componentWillUnmount() { },

  componentWillReceiveProps(nextProps) {
    if (
      this.props.processObjectId &&
      this.props.processObjectId !== nextProps.processObjectId
    ) {
      const data = ApprovalHistoryAction.initDataSyn(
        nextProps.processObjectId,
        nextProps.isHistory
      );
      if (!data) {
        this.setState({ approvelist: {} });
      } else {
        this.setState({ approvelist: data });
      }
    }
  },
  render() {
    const { approvelist } = this.state;
    const { componentId } = this.props;
    const data = approvelist;

    const ComponentId = componentId || '';
    const approveCode = CodeTableAction.loadDataList(
      CodeTableCstUI.ClaimApproveDecison,
      null
    );
    const approvalDecision = approveCode.codes;
    const nameCode = CodeTableAction.loadDataList(
      CodeTableCstUI.ClaimUser,
      null
    );
    return (
      <UIDataTable
        indexable="false"
        id={`approvalhistory_table${ComponentId}`}
        value={data}
        pageable="false"
        isHeading="true"
        headerTitle={i18n.ClaimCommon.ApprovalHistory}
        toggleable="true"
      >
        <UIColumn
          headerTitle={i18n.ClaimCommon.ApprovalDate}
          value="Update"
          width="20%"
        >
          <UIDateTimePicker format="MM/DD/YYYY HH:mm:ss" io="out" />
        </UIColumn>
        <UIColumn
          headerTitle={i18n.ClaimCommon.ApprovalName}
          value="ActionName"
          width="20%"
          render={data => {
            const names = nameCode.codes;
            const approver = data.Approver;
            let name;
            for (let i = 0; i < names.length; i += 1) {
              if (names[i].id == approver) {
                name = names[i].text;
              }
            }
            return <UIText io="out" value={name} />;
          }}
        />
        <UIColumn
          headerTitle={i18n.ClaimCommon.ApprovalSummay}
          value="ProcessSummary"
          width="20%"
          render={data => {
            return <UIText io="out" value={data.ProcessSummary} />;
          }}
        />
        <UIColumn
          headerTitle={i18n.ClaimCommon.ApprovalDecision}
          value="DecisionCode"
          width="20%"
          render={data => {
            const decisionCode = `${data.DecisionCode} `.trim();
            let decision;
            for (let i = 0; i < approvalDecision.length; i += 1) {
              if (decisionCode == approvalDecision[i].id) {
                decision = approvalDecision[i].text;
              }
            }
            return <UIText io="out" value={decision} />;
          }}
        />
        <UIColumn
          headerTitle={i18n.ClaimCommon.ApprovalRemark}
          value="Remark"
          width="20%"
          render={data => {
            return <UIText io="out" value={data.Remark} />;
          }}
        />
      </UIDataTable>
    );
  },
});
export default ReserveApprovalHistory;
