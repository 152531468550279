import { UIPanel, UIButton, UIBox } from 'RainbowUI';
import BindToMixin from 'react-binding';
import PropTypes from 'prop-types';
import AppraisalVehicleStore from '../../store/AppraisalVehicleStore';
import TaskTransferDialog from '../../../../../../common/module/common/component/TaskTransferDialog';

const AppraisalPropertyAction = require('../../action/AppraisalPropertyAction');
const AppraisalProperInfo = require('./AppraisalPropertyInfo');
const AppraisalPropertyItem = require('./AppraisalPropertyItem');
const AppraisalPropertyRemark = require('./AppraisalPropertyRemark');
const ApprovalDecision = require('./../vehicle/ApprovalDecision');
const AppraisalHistory = require('./AppraisalPropertyHistory');
const ApprovalHistory = require('./../vehicle/ApprovalHistory');

const AppraisalProperty = React.createClass({
  mixins: [BindToMixin],
  propTypes: {
    objectId: PropTypes.string.isRequired,
  },

  componentWillMount() {
    AppraisalVehicleStore.listen(this.onStoreStateChange);
    AppraisalPropertyAction.loadClaimAppraisal(
      this.props.objectId,
      this.props.openForm
    );
  },
  getInitialState() {
    return {
      AppraisalData: null,
      AppraisalInfo: null,
      AppraisalProperty: null,
      AppraisalHistory: [],
      ApprovalHistoryVO: [],
      SynBtnStatus: false,
      CurrencyCode: null,
    };
  },
  onStoreStateChange(storeState) {
    if (!_.isEmpty(storeState.AppraisalData)) {
      this.setState({ AppraisalData: storeState.AppraisalData });
      this.setState({
        AppraisalInfo: storeState.AppraisalData.AppraisalInfo,
      });
      this.setState({
        AppraisalProperty:
          storeState.AppraisalData.AppraisalInfo.appraisalProperty,
      });
      this.setState({
        AppraisalHistory: storeState.AppraisalData.AppraisalHistory,
      });
      this.setState({
        ApprovalHistoryVO: storeState.AppraisalData.ApprovalHistoryVO,
      });
      this.setState({
        CurrencyCode: storeState.AppraisalData.CurrencyCode,
      });
      this.setState({
        SynBtnStatus: storeState.AppraisalData.SynBtnStatus,
      });
    }
  },
  getIOStyle() {
    return this.state.AppraisalInfo.AppraisalStatus === 'CLOSED'
      ? 'out'
      : 'in';
  },
  updateAppraisalProperty(domainObject) {
    if (domainObject) {
      const { AppraisalInfo } = this.state;
      AppraisalInfo.claimAppraisalProperty = domainObject;
      this.setState({ AppraisalInfo });
    }
  },
  render() {
    const _self = this;
    const { taskInfo, subClaim } = this.props;
    const {
      AppraisalData,
      AppraisalInfo,
      ApprovalHistoryVO,
    } = this.state;
    const claimAppraisalProperty = this.bindToState(
      'AppraisalInfo',
      'appraisalProperty'
    );

    if (
      taskInfo &&
      taskInfo.TaskCode === 'ClaimAppraisalPropertyTask'
    ) {
      return (
        <div>
          <TaskTransferDialog ref="ClaimTaskTransferDialog" />
          <AppraisalProperInfo
            appraisalProperInfo={_self.state.AppraisalProperty}
            subClaim={subClaim}
            updateAppraisalProperty={this.updateAppraisalProperty.bind(
              this
            )}
            taskCode={taskInfo.TaskCode}
          />
          <UIPanel
            panelTitle={i18n.ClaimAppraisal.LossItems}
            toggleable="true"
          >
            <AppraisalPropertyItem
              claimAppraisalProperty={claimAppraisalProperty}
              currencyCode={AppraisalData.CurrencyCode}
            />
            <AppraisalPropertyRemark
              appraisalProperInfo={_self.state.AppraisalProperty}
              appraisalInfo={AppraisalInfo}
            />
          </UIPanel>
          {this.getOperationButton()}
          <br />
          {/* <ApprovalHistory parentComponent={this} appraisalApproveHistory={this.state.ApprovalHistoryVO}/>
                        <AppraisalHistory parentComponent={this} appraisalHistory={this.state.AppraisalHistory}/> */}
        </div>
      );
    }
    if (
      taskInfo &&
      taskInfo.TaskCode === 'ClaimAppraisalPApprovalTask'
    ) {
      return (
        <div>
          <TaskTransferDialog ref="ClaimTaskTransferDialog" />
          <AppraisalProperInfo
            appraisalProperInfo={_self.state.AppraisalProperty}
            subClaim={subClaim}
            updateAppraisalProperty={this.updateAppraisalProperty.bind(
              this
            )}
            ioSytle="out"
            taskCode={taskInfo.TaskCode}
          />
          <UIPanel
            panelTitle={i18n.ClaimAppraisal.LossItems}
            toggleable="true"
          >
            <AppraisalPropertyItem
              claimAppraisalProperty={claimAppraisalProperty}
              ioSytle="out"
              appraisalProperInfo={_self.state.AppraisalProperty}
              currencyCode={AppraisalData.CurrencyCode}
            />
            <AppraisalPropertyRemark
              appraisalProperInfo={_self.state.AppraisalProperty}
              ioSytle="out"
              appraisalInfo={AppraisalInfo}
            />
          </UIPanel>
          <br />
          <ApprovalDecision
            decision={this.bindToState(
              'AppraisalData',
              'ApprovalDecision'
            )}
            remark={this.bindToState('AppraisalData', 'ApproveMark')}
            appraisalData={AppraisalData}
            taskInstanceId={taskInfo.TaskInstanceId}
          />
          <ApprovalHistory
            parentComponent={this}
            appraisalApproveHistory={ApprovalHistoryVO}
          />
          <AppraisalHistory
            parentComponent={this}
            appraisalHistory={this.state.AppraisalHistory}
          />
        </div>
      );
    }
    return (
      <div>
        <TaskTransferDialog ref="ClaimTaskTransferDialog" />
        <AppraisalProperInfo
          appraisalProperInfo={_self.state.AppraisalProperty}
          updateAppraisalProperty={this.updateAppraisalProperty.bind(
            this
          )}
          subClaim={subClaim}
          ioSytle={this.getIOStyle()}
          taskCode={taskInfo.TaskCode}
        />
        <UIPanel
          panelTitle={i18n.ClaimAppraisal.LossItems}
          toggleable="true"
        >
          <AppraisalPropertyItem
            ioSytle={this.getIOStyle()}
            claimAppraisalProperty={claimAppraisalProperty}
            currencyCode={AppraisalData.CurrencyCode}
            io={this.getIOStyle}
          />
          <AppraisalPropertyRemark
            ioSytle={this.getIOStyle()}
            appraisalProperInfo={_self.state.AppraisalProperty}
            appraisalInfo={AppraisalInfo}
          />
        </UIPanel>
        <br />
        {/* <ApprovalHistory parentComponent={this} appraisalApproveHistory={this.state.ApprovalHistoryVO}/>
                        <AppraisalHistory parentComponent={this} appraisalHistory={this.state.AppraisalHistory}/> */}
      </div>
    );
  },
  getOperationButton() {
    const buttons = [];
    const { SynBtnStatus } = this.state;
    buttons.push(
      <UIButton
        id="Syncinfo_button"
        value={i18n.ClaimAppraisal.SyncInfo}
        onClick={this.synFIProperty}
        enabled={SynBtnStatus}
      />
    );
    buttons.push(
      <UIButton
        id="save_appra_totalcost_button"
        value={i18n.ClaimAppraisal.Save}
        onClick={this.saveAppraisalProperty}
      />
    );
    buttons.push(
      <UIButton
        id="submit_appra_totalcost_button"
        value={i18n.ClaimAppraisal.SubmitTotal}
        onClick={this.submitAppraisalProperty}
        validationGroup="AppraisalProperty"
        causeValidation="true"
      />
    );
    return <UIBox direction="right">{buttons}</UIBox>;
  },
  synFIProperty() {
    AppraisalPropertyAction.getProperty(this.props.objectId);
  },
  saveAppraisalProperty() {
    AppraisalPropertyAction.saveAppraisalProperty(
      this.state.AppraisalInfo
    );
  },
  submitAppraisalProperty() {
    const tempData = this.state.AppraisalData;
    tempData.TaskInstanceId = this.props.taskInfo.TaskInstanceId;
    tempData.ApprovalHistoryVO = null;
    tempData.AppraisalHistory = [];
    AppraisalPropertyAction.submitAppraisalProperty(tempData);
  },
});
export default AppraisalProperty;
