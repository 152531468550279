import Document from './Document';
import ClaimBaseInfo from '../claimhandling/ClaimBaseInfo';

export default class AP98FileUpload extends React.Component {
  render() {
    const { claimNo, claimEntity } = this.props;

    return (
      <div>
        <ClaimBaseInfo claimEntity={claimEntity} />
        <Document claimNo={claimNo} claimEntity={claimEntity} />
      </div>
    );
  }
}
