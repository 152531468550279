import React from 'react';
import {
  UIPanel,
  UIDataTable,
  UIColumn,
  UILink,
  UIParam,
  UIText,
} from 'RainbowUI';
import SearchForParticipation from '../../searchForRelation/SearchForParticipation';
import EditParticipationInfo from '../../EditParticipationInfo';
import PartyAction from '../../../actions/PartyAction';

const Participation = React.createClass({
  getInitialState() {
    const { party } = this.props;
    const partyAction = new PartyAction();
    const partyParticipationList = party.PartyParticipationList;
    _.forEach(partyParticipationList, tempPartyParticipation => {
      const partyParticipation = tempPartyParticipation;
      const result = partyAction.loadPartyByPartyId(
        partyParticipation.ParticipationPartyId
      );
      partyParticipation.ParticipationPartyInfo = result;
      partyParticipation.CompanyName = result.PartyName;
      partyParticipation.CountryDom = result.CountryDom;
      partyParticipation.OrganizationIdNumber =
        result.OrganizationIdNumber;
    });
    return {
      party,
      partyParticipationList,
    };
  },

  componentWillMount() {},

  componentDidMount() {},

  render() {
    const self = this;
    return (
      <div>
        {self.renderRoleInfo()}
        <EditParticipationInfo
          ref="editPartyParticipationDialog"
          searchPartyName={self.searchPartyName}
        />
        <SearchForParticipation ref="searchIndSearchParticipationDialog" />
      </div>
    );
  },

  renderRoleInfo() {
    const self = this;
    return (
      <UIPanel panelTitle={i18n.RoleInfo}>
        <UIDataTable
          id="Participation"
          value={self.state.partyParticipationList}
        >
          <UIColumn
            headerTitle={i18n.CompanyName}
            render={data => {
              return (
                <div>
                  <UILink
                    value={data.CompanyName}
                    onClick={self.viewHandler}
                  >
                    <UIParam name="data" value={data} />
                  </UILink>
                </div>
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.IdOrCompanyRegNumber}
            value="OrganizationIdNumber"
          />
          <UIColumn
            headerTitle={i18n.CountryDom}
            value="CountryDom"
          />
          <UIColumn
            headerTitle={i18n.SharePercentage}
            render={data => {
              const SharePercentageData = `${data.SharePercentage}%`;
              return (
                <div>
                  <UIText
                    value={SharePercentageData}
                    disabled="true"
                  />
                </div>
              );
            }}
          />
        </UIDataTable>
      </UIPanel>
    );
  },

  viewHandler(clickEvent) {
    let partyParticipation = {};
    if (!_.isEmpty(clickEvent.paramJson)) {
      partyParticipation = clickEvent.paramJson[0].value;
    }
    this.refs.editPartyParticipationDialog.showDialog(
      partyParticipation,
      true
    );
  },

  searchPartyName(partyParticipation, readOnly) {
    this.refs.searchIndSearchParticipationDialog.showDialog(
      partyParticipation,
      readOnly
    );
  },
});
export default Participation;
