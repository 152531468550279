import alt from '../alt';
import ClaimWorkFlowLeftAction from '../action/desktop/ClaimWorkFlowLeftAction';

class PageStore {
  constructor() {
    this.page = {};

    this.bindListeners({
      handlePage: ClaimWorkFlowLeftAction.PAGE_CHANGE,
    });
  }

  handlePage(page) {
    this.page = page;
  }
}

export default alt.createStore(PageStore, PageStore);
