import { UIBox, UIButton } from 'RainbowUI';
import BindToMixin from 'react-binding';
import PropTypes from 'prop-types';
import ClaimCommonExt from 'ClaimCommonExt';
import ClaimPartyStore from '../store/ClaimPartyStore';
import ClaimCstUI from '../../common/constant/ClaimCstUI';

const ClaimPartyDetailPanel = React.createClass({
  mixins: [BindToMixin],

  propTypes: {
    showRadio: PropTypes.string,
  },

  getDefaultProps() {
    return {
      showRadio: 'false',
    };
  },

  getInitialState() {
    const partyVo = this.props.data ? this.props.data : {};
    if (this.props.fromWhere === 'claimant') {
      partyVo.ClmPtyRole = ClaimCstUI.PARTY_ROLE.Claimant;
    }
    return { partyVo, partyAuth: this.props.partyAuth };
  },
  onStoreStateChange(storeState) {
    if (!_.isEmpty(storeState.partyVo)) {
      this.setState({ partyVo: storeState.partyVo });
    }
  },

  componentWillMount() {
    ClaimPartyStore.listen(this.onStoreStateChange);
  },
  componentWillUnmount() {
    ClaimPartyStore.unlisten(this.onStoreStateChange);
  },
  getPartyVo() {
    const { partyVo } = this.state;
    partyVo.AddressVoList = this.refs.addressList_ref.getAddressList();
    partyVo.PartyAccountList = this.refs.accountList_ref.getAccountList();
    if (partyVo.PartyType == ClaimCstUI.PARTY_TYPE_ORGANIZATION) {
      partyVo.PartyContactList = this.refs.contactList_ref.getContactList();
    }
    return partyVo;
  },

  clickSubmit() {
    const ChoosePayeeAction = ClaimCommonExt.getChoosePayeeAction();

    if (
      this.props.fromWhere === 'payee' ||
      this.props.fromWhere === 'payer' ||
      this.props.fromWhere === 'noticeNewClaimant'
    ) {
      ChoosePayeeAction.selectedAsPayee(
        this.props.caseId,
        this.getPartyVo(),
        this.props.index,
        this.props.callback
      );
    }
  },

  clickCancel(event) {
    const ChoosePayeeAction = ClaimCommonExt.getChoosePayeeAction();
    if (
      this.props.fromWhere === 'payee' ||
      this.props.fromWhere === 'payer'
    ) {
      ChoosePayeeAction.onHide(this.props.index);
    }
    if (this.props.fromWhere === 'noticeNewClaimant') {
      this.props.callbackList?.hide();
    }
  },

  render() {
    const { partyAuth, partyVo } = this.state;
    const {
      fromWhere,
      showRadio,
      index,
      isEdit,
      showContactRadio,
    } = this.props;
    const PartyBasicInfo = ClaimCommonExt.getPartyBasicInfo();
    const PartyAccount = ClaimCommonExt.getPartyAccount();
    const PartyAddress = ClaimCommonExt.getPartyAddress();
    return (
      <div>
        <PartyBasicInfo
          index={index}
          ref="basicInfo_ref"
          partyVo={partyVo}
          partyAuth={partyAuth}
          fromWhere={fromWhere}
          isEdit={isEdit}
          colspan="2"
        />

        {this.getPartyContact()}

        <PartyAddress
          showRadio={showRadio}
          index={index}
          ref="addressList_ref"
          parentComponent={this}
          colspan="2"
          addressId={partyVo.PtyAddressId}
          addressList={partyVo.AddressVoList}
          partyAuth={partyAuth}
          partyVo={partyVo}
        />

        <PartyAccount
          showRadio={showRadio}
          index={index}
          ref="accountList_ref"
          parentComponent={this}
          colspan="2"
          accountId={partyVo.PtyAccountId}
          accountList={partyVo.PartyAccountList}
          partyAuth={partyAuth}
          partyVo={partyVo}
        />

        {this.getButtons()}
      </div>
    );
  },
  getSuperComponent() {
    return this;
  },

  getPartyContact() {
    const { partyAuth, partyVo } = this.state;
    if (partyVo.PartyType == ClaimCstUI.PARTY_TYPE_ORGANIZATION) {
      const { showRadio, index, showContactRadio } = this.props;

      const PartyContact = ClaimCommonExt.getPartyContact();
      return (
        <PartyContact
          showRadio={showRadio}
          showContactRadio={showContactRadio}
          index={index}
          ref="contactList_ref"
          parentComponent={this}
          colspan="2"
          contactId={partyVo.PtyContactId}
          contactList={partyVo.PartyContactList}
          partyAuth={partyAuth}
        />
      );
    }
    return <div />;
  },

  getButtons() {
    const { existButtons, fromWhere } = this.props;
    if (existButtons) {
      return <div />;
    }

    return (
      <UIBox direction="center">
        <UIButton
          value={
            fromWhere === 'payee'
              ? i18n.ClaimParty.SelectAsPayee
              : fromWhere === 'noticeNewClaimant'
              ? i18n.ClaimCommon.SelectAsClaimant
              : i18n.ClaimParty.SelectAsPayer
          }
          onClick={this.clickSubmit}
          causeValidation="true"
          validationGroup="partyDetail"
        />
        <UIButton
          value={i18n.ClaimCommon.Cancel}
          onClick={this.clickCancel}
        />
      </UIBox>
    );
  },
});
export default ClaimPartyDetailPanel;
