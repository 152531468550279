import {
  DataContext,
  UIPage,
  UIPanel,
  UIParam,
  UICell,
  UIBox,
  UIText,
  UIDataTable,
  UIDialog,
  MessageHelper,
  UIDateTimePicker,
  UISmartPanelGrid,
  UIRadio,
  UIButton,
  UIColumn,
  UILink,
  UISelect,
} from 'RainbowUI';
import BindToMixin from 'react-binding';
import MassiveReassignmentTaskOwnerAction from '../../action/MassiveReassignmentTaskOwnerAction';
import ClaimWorkFlowSharePoolAction from '../../action/desktop/ClaimWorkFlowSharePoolAction';
import ClaimCstUI from '../../constant/ClaimCstUI';
import ClaimAuthorityAction from '../../action/ClaimAuthorityAction';
import ConditionStore from '../../store/PoolConditionStore';
import ReassignmentTaskOwnerStore from '../../store/ReassignmentTaskOwnerStore';
import ExitButton from '../../button/ExitButton';
import Url from '../../url/Url';

const CodeTableAction = require('../../action/CodeTableAction');
const CodeTableCstUI = require('../../constant/CodeTableCstUI');

const MassiveReassignmentTaskOwnerDialog = require('./MassiveReassignmentTaskOwnerDialog');

const TaskOwnerResult = React.createClass({
  mixins: [BindToMixin],

  getInitialState() {
    return {
      condition: {},
      isInital: true,
      update: false,
      taskOwnerAtuh: 'ture',
    };
  },

  componentWillMount() {
    const result = ClaimAuthorityAction.checkAuth(
      '',
      ClaimCstUI.AUTHORITY_CLAIM_TASK_ASSIGNMENT
    );
    this.setState({ taskOwnerAtuh: result });
    ReassignmentTaskOwnerStore.listen(this.onStoreStateChange);
  },

  componentWillUnmount() {
    ReassignmentTaskOwnerStore.unlisten(this.onStoreStateChange);
  },
  onStoreStateChange(storeState) {
    console.log(' store state is ', storeState.taskSearchOptions);
    this.setState({ condition: storeState.taskSearchOptions });
    this.setState({ isInital: false });
  },
  updateThisUI() {
    console.log('  updatethis ui');
    this.setState({ update: true });
  },
  getSharePoolList(pageindex, pagesize) {
    DataContext.put('pageindex', pageindex);
    DataContext.put('pagesize', pagesize);
    if (this.state.isInital) {
      return [];
    }
    return MassiveReassignmentTaskOwnerAction.getSharePoolList(
      pageindex,
      pagesize,
      this.state.condition
    );
  },
  showDialog() {
    const selRecord = UIDataTable.getSelectedRecord(
      'reassignmentList'
    );
    if (selRecord == '') {
      MessageHelper.info(
        i18n.ClaimCommon.NoRecSelected,
        i18n.ClaimCommon.MassiveReassignmentTaskowner
      );
      return;
    }
    console.log(
      selRecord[0].id,
      selRecord[0].TaskAssignee,
      selRecord.length
    );
    DataContext.put('claimList', selRecord);
    UIDialog.show('massiveReassignmentTaskOwnerDialog');
  },
  render() {
    const self = this;
    return (
      <div>
        <UIDataTable
          id="reassignmentList"
          indexable="false"
          var="poolSelected"
          provider={this.getSharePoolList}
          headerTitle={i18n.ClaimCommon.SearchResult}
        >
          <UIColumn selectionMode="multiple" enabled="ture" />
          <UIColumn
            headerTitle={i18n.ClaimCommon.TaskType}
            value="TaskDefinitionKey"
            sortable="true"
          >
            <UISelect
              codeTable={CodeTableAction.loadDataList(
                CodeTableCstUI.CLAIMTASK,
                null
              )}
              io="out"
            />
          </UIColumn>
          <UIColumn
            headerTitle={i18n.ClaimCommon.TaskOwner}
            value="TaskAssignee"
            sortable="true"
          >
            <UISelect
              codeTable={CodeTableAction.loadDataList(
                CodeTableCstUI.ClaimUser,
                null
              )}
              io="out"
            />
          </UIColumn>
          {/*		<UIColumn headerTitle={i18n.ClaimCommon.ClaimOwner} value="ClaimOwner" sortable="true">
			<UISelect codeTable={CodeTableAction.loadDataList(CodeTableCstUI.ClaimUser,null)} io="out"/>
		</UIColumn> */}
          <UIColumn
            headerTitle={i18n.ClaimCommon.ClaimNumber}
            value="ClaimNo"
            sortable="true"
          />
          <UIColumn
            headerTitle={i18n.ClaimCommon.CreateDate}
            value="TaskCreateTime"
            sortable="true"
          >
            <UIDateTimePicker
              placeHolder="MM/DD/YYYY HH:mm:ss"
              format="MM/DD/YYYY HH:mm:ss"
              io="out"
            />
          </UIColumn>
          <UIColumn
            headerTitle={i18n.ClaimCommon.DueDate}
            value="TaskDueTime"
            sortable="true"
          >
            <UIDateTimePicker
              placeHolder="MM/DD/YYYY HH:mm:ss"
              format="MM/DD/YYYY HH:mm:ss"
              io="out"
            />
          </UIColumn>
          <UIColumn
            headerTitle={i18n.ClaimCommon.Priority}
            value="TaskPrioriry"
            sortable="true"
          >
            <UISelect
              codeTable={CodeTableAction.loadDataList(
                CodeTableCstUI.Prioriry,
                null
              )}
              io="out"
            />
          </UIColumn>
        </UIDataTable>
        <UIBox direction="left">
          <UIButton
            value={i18n.ClaimCommon.ChangeTaskOwner}
            onClick={this.showDialog}
            enabled={self.state.taskOwnerAtuh}
          />
        </UIBox>
        <MassiveReassignmentTaskOwnerDialog
          updateparentUI={this.updateThisUI}
        />
      </div>
    );
  },
});
export default TaskOwnerResult;
