import { AjaxUtil } from 'RainbowUI';
import Url from '../url/Url';

export default {
  loadRelation(subclaimTypeCode) {
    const url =
      Url.ClaimProductRelationOfSubclaimTypeDamageLoad +
      subclaimTypeCode;
    let list = null;
    AjaxUtil.doGet(
      url,
      {},
      {
        async: false,
        done(data) {
          list = data;
        },
      }
    );

    return list.Model;
  },
  loadDamageType(productLineCode) {
    const url =
      Url.ClaimProductRelationOfSubclaimTypeDamage + productLineCode;
    let list = null;
    AjaxUtil.doGet(
      url,
      {},
      {
        async: false,
        done(data) {
          list = data;
        },
      }
    );

    return list.Model;
  },
  updateClaimDamageTypeRelation(claimRelationData, subclaimTypeCode) {
    const url =
      Url.ClaimProductRelationOfSubclaimTypeDamageUpdate +
      subclaimTypeCode;
    AjaxUtil.doPost(url, claimRelationData, {
      async: false,
      done() {},
    });
  },
};
