import {
  UIText,
  UpdateContext,
  UISelect,
  UIDateTimePicker,
  UIDialog,
  UISmartPanelGrid,
  UIBox,
  UIButton,
  UIUpdatePanel,
  UIRadio,
  UICell,
  MessageHelper,
} from 'RainbowUI';
import CommonCodeTable from '../../codetable/CodeTable';
import PartyConst from '../../const/PartyConst';
import DateUtil from '../../../common/util/DateUtil';

const _ = require('underscore');

const DriverIndRole = React.createClass({
  getInitialState() {
    const partyRoleList = this.props.partyRoleSelected;
    let model = {
      '@type': 'PartyIndividualDriverRole-PartyIndividualDriverRole',
      BusinessObjectId: 75628452,
      RoleType: PartyConst.PARTY_ROLE_CODE_IND_DRIVER,
    };
    _.each(partyRoleList, partyRole => {
      if (
        partyRole.RoleType == PartyConst.PARTY_ROLE_CODE_IND_DRIVER
      ) {
        model = partyRole;

        return model;
      }
    });

    const status = model.Status;
    const visible = !(
      status == '1' ||
      status === undefined ||
      status === null
    );
    let leftHandExpYrsVisible = false;
    if (model.LeftHandSideExp === 'Y') {
      leftHandExpYrsVisible = true;
    }
    return {
      PartyId: this.props.partyId,
      PartyRoleModel: model,
      Visible: visible,
      LeftHandExpYrsVisible: leftHandExpYrsVisible,
    };
  },

  render() {
    const _self = this;
    const {
      PartyRoleModel,
      Visible,
      LeftHandExpYrsVisible,
    } = this.state;
    const { isEdit } = this.props;
    return (
      <div>
        <UISmartPanelGrid column="2">
          <UISelect
            label={i18n.PartyRole}
            value={PartyConst.PARTY_ROLE_CODE_IND_DRIVER}
            enabled="false"
            codeTable={CommonCodeTable.getIndPartyRole}
            required="true"
            model={PartyRoleModel}
            property="CustomerCode"
          />
          <UIUpdatePanel id="NUMBER_CODE_DriverPenaltyPointsUpdatePanel">
            <UIText
              label={i18n.driverPenaltyPoints}
              id="NUMBER_CODE_DriverPenaltyPoints"
              model={PartyRoleModel}
              property="DriverPenaltyPoints"
              allowChars="0123456789"
              required="true"
              validationGroup="driverDetailInformation"
              onChange={this.driverPenaltyPointsOnChange}
            />
          </UIUpdatePanel>
          <UIDateTimePicker
            label={i18n.licenseIssueDate}
            manualInput="true"
            showToday="true"
            format="MM/DD/YYYY"
            model={PartyRoleModel}
            property="DriverLicenseIssueDate"
          />
          <UISelect
            label={i18n.licenseHeldAs}
            codeTable={CommonCodeTable.getCodeTable(73982693)}
            model={PartyRoleModel}
            property="LicenseTerm"
          />
          <UISelect
            label={i18n.TypeOfLicense}
            required="true"
            validationGroup="driverDetailInformation"
            codeTable={CommonCodeTable.getCodeTable(73982696)}
            model={PartyRoleModel}
            property="LicenseTypeCd"
          />
          <UIRadio
            label={i18n.leftExperience}
            required="true"
            validationGroup="driverDetailInformation"
            codeTable={CommonCodeTable.getYesOrNo}
            model={PartyRoleModel}
            property="LeftHandSideExp"
            onChange={this.setLeftHandSideExp}
          />
          <UISelect
            label={i18n.LeftHandSideoftheRoadExperienceYears}
            required="true"
            codeTable={CommonCodeTable.getCodeTable(73982694)}
            visibled={LeftHandExpYrsVisible}
            model={PartyRoleModel}
            property="LeftHandExpYrs"
            validationGroup="driverDetailInformation"
          />
          <UICell />
          <UISelect
            label={i18n.Status}
            model={PartyRoleModel}
            property="Status"
            defaultValue="1"
            showBlankOption="false"
            codeTable={CommonCodeTable.getPartyStatus}
            onChange={_self.changeStatus}
            disabled={!Object.is(isEdit, 'Y')}
          />
          <UIDateTimePicker
            label={i18n.InactiveDate}
            disabled="true"
            format="MM/DD/YYYY"
            visibled={Visible}
            model={PartyRoleModel}
            property="InactiveDate"
          />
          <UIText
            label={i18n.Remarks}
            colspan="2"
            model={PartyRoleModel}
            property="Remark"
          />
        </UISmartPanelGrid>
        <UIBox direction="center">
          <UIButton
            value={i18n.Save}
            causeValidation="true"
            validationGroup="driverDetailInformation"
            onClick={_self.onClickSubmit}
          />
          <UIButton
            value={i18n.Cancel}
            onClick={_self.onClickClose}
          />
        </UIBox>
      </div>
    );
  },
  driverPenaltyPointsOnChange(event) {
    if (!event.newValue) {
      MessageHelper.error(
        '',
        i18n.PartyMsg.ErrorMsg.Error_0011,
        MessageHelper.POSITION_TOP_RIGHT
      );
      return;
    }
    const regex = new RegExp('^(([0-9])|(1[0-2]))$', 'gi');
    const myArray = regex.exec(event.newValue);
    if (myArray) {
      this.state.PartyRoleModel.DriverPenaltyPoints = myArray[0];
    } else {
      MessageHelper.error(
        '',
        i18n.PartyMsg.ErrorMsg.Error_0011,
        MessageHelper.POSITION_TOP_RIGHT
      );
      this.state.PartyRoleModel.DriverPenaltyPoints = myArray;
    }

    UpdateContext.forceUpdate(
      'NUMBER_CODE_DriverPenaltyPointsUpdatePanel'
    );
  },

  changeStatus() {
    this.setState(prevState => {
      const activeStatus = prevState.PartyRoleModel.Status;
      const partyRoleModel = prevState.PartyRoleModel;
      partyRoleModel.InactiveDate = DateUtil.getCurrentDateTime();
      let visible = prevState.Visible;
      visible = !(
        activeStatus === undefined ||
        activeStatus === null ||
        activeStatus == '1'
      );
      return {
        Visible: visible,
        partyRoleModel,
      };
    });
  },

  setLeftHandSideExp(event) {
    if (event.newValue === 'Y') {
      this.setState({
        LeftHandExpYrsVisible: true,
      });
    } else {
      this.setState({
        LeftHandExpYrsVisible: false,
      });
    }
  },

  onClickSubmit() {
    const _self = this;
    _.each(_self.props.RoleList, temppartyRole => {
      const partyRole = temppartyRole;
      if (
        partyRole.RoleType == PartyConst.PARTY_ROLE_CODE_IND_DRIVER
      ) {
        partyRole.Status = _self.state.PartyRoleModel.Status;
        return partyRole;
      }
    });
    const superParty = _self.props.parentComponent.state.Party;
    const { PartyHistoryList } = superParty;
    if (!_self.state.PartyRoleModel['@pk']) {
      const newPartyHistory = PartyConst.PARTY_HISTORY_CREATE_ROLE;
      PartyHistoryList.push(newPartyHistory);
    } else {
      const newPartyHistory = PartyConst.PARTY_HISTORY_UPDATE_ROLE;
      PartyHistoryList.push(newPartyHistory);
    }
    if (!superParty.PartyRoleList) {
      superParty.PartyRoleList = [];
    }
    superParty.PartyRoleList.push(_self.state.PartyRoleModel);

    _self.props.submitInfoHandler(superParty);
    UIDialog.hide('createDriverIndPartyDialog');
  },
  onClickClose() {
    UIDialog.hide('createDriverIndPartyDialog');
  },
});
export default DriverIndRole;
