export default {
  VictimName: 'Injured Person',
  Gender: 'Gender',
  Age: 'Age',
  HomeAddress: 'Home Address',
  ContactTel: 'Contact Telephone',
  InjuryType: 'Injured Type',
  DisabilityGrade: 'Disability Grade',
  Treatment: 'Treatment',
  Hospital: 'Hospital',
  DaysInHospital: 'Days In Hospital',
  InjuryDescription: 'Injury Description',
  RemarktoVictim: 'Remark to Victim',
  RemarktoInvestigation: 'Remark to Field Investigation',

  PlateNo: 'Plate No.',
  Maker: 'Make',
  Model: 'Model',
  ModuleYear: 'Module Year',
  MakeYear: 'Make Year',
  VehicleType: 'Vehicle Type',
  VehicleColor: 'Vehicle Color',
  VIN: 'VIN',
  EngineNumber: 'Engine Number',
  InvestigatorArrivalTime: 'Investigator Arrival Time',

  PropertyName: 'Property Name',
  ClaimantIsOwner: 'Claimant is Owner',
  OwnerName: 'Owner Name',
  FieldInvestigationAddress: 'Field Investigation Address',
  PropertyDamageDescription: 'Property Damage Description',
  RemarkToLossItems: 'Remark to Loss Items',
  FieldInvestigation: 'Field Investigation',

  VehicleInfo: 'Vehicle Info',
  PropertyInfo: 'Property Info',
  VictimInfo: 'Injured Person Info',
  SaveSuccess: 'Field Investigation is saved successfully',
  SubmitSuccess: 'Field Investigation is submitted successfully',
  Failed: 'Failed',
  VictimSynSuccess: 'Victim Info is synchronized successfully',
  VehicleSynSuccess: 'Vehicle Info is synchronized successfully',
  PropertySynSuccess: 'Property Info is synchronized successfully',
  whetherRegistered: 'Is the vehicle registered at DOT',
  retrieve: 'RETRIEVE',
  registrationNo: 'Registration No./Plate No.',
  vinNumber: 'Chassis/VIN Number',
  manufactureYear: 'Manufacture Year',
  registrationDate: 'Registration Date',
  investigatorArrival: 'Investigator Arrival Date and Time',
  investigatorTime: 'Investigation Date and Time',
  investigatorName: 'Investigator Name',
  investigatorTelephone: 'Investigator Telephone',
  doctorName: 'Doctor Name',
  specialty: 'Specialty',
  dateOfAdmission: 'Date of Admission',
  dateOfDischarge: 'Date of Discharge',
  remark1: 'Remark 1',
  remark2: 'Remark 2',

  ErrorMsg: {
    PleaseentertheRegistration: 'Please enter the Registration',
    DOTServicenotresponse: 'DOT Service not response.',
    RegistrationNumberFormat:
      'Registration number  with format: XXX000 or XX000 or  X000.',
    VehicleInformationNotFoundInDOT:
      'Vehicle information not found in DOT ',
  },

  ConfirmMsg: {},

  SuccessMsg: {},
  InfoMsg: {},

  WarnMsg: {
    DischargeShouldLaterThanDateOfAdmission:
      'Date Of Discharge should be later than DateOfAdmission',
    PleaseCheckYourVinNumberImput:
      'please check your Vin Number Imput',
    ValueMustLessThan3000:
      'The value must be less than 3000 characters long',
  },
};
