import alt from '../alt';
import AttachmentService from '../service/AttachmentService';

class AttachmentAction {
  initAttachment(caseId) {
    AttachmentService.loadAttachmentInfo(caseId).then(result => {
      this.dispatch(result);
    });
  }
}
export default alt.createActions(AttachmentAction);
