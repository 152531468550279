const ProductLine = require('../component/ProductLine');
const ProductLineList = require('../component/ProductLineList');
const ProductTypeList = require('../component/ProductTypeList');
const ProductList = require('../component/ProductList');
const ProductCoverageList = require('../component/ProductCoverageList');
const DefaultReserve = require('../component/DefaultReserve');
const SettlementTypeList = require('../component/SettlementTypeList');
const ReserverTypeList = require('../component/ReserverTypeList');
const ViewParameterMatrix = require('../component/ViewParameterMatrix');
const CoverageCategory = require('../component/CoverageCategory');
const CoverageCategoryOfProductLine = require('../component/CoverageCategoryOfProductLine');
const LossCauseList = require('../component/LossCauseList');
const SubclaimTypeList = require('../component/SubclaimTypeList');

export default {
  UrlMapComponent: {
    pl: <ProductLineList />,
    pt: <ProductLine />,
    productType: <ProductTypeList />,
    coverage: <ProductCoverageList />,
    product: <ProductList />,
    defaultReserve: <DefaultReserve />,
    commonReserveType: <ReserverTypeList />,
    commonSettlementType: <SettlementTypeList />,
    parameterMatrix: <ViewParameterMatrix />,
    producttypecoverageCategory: <CoverageCategory />,
    coverageCategory: <CoverageCategoryOfProductLine />,
    causeofLoss: <LossCauseList />,
    subclaimType: <SubclaimTypeList />,
  },
};
