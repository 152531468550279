/**
 * Created by jie.huang on 2019/4/23.
 */
import {
  UIText,
  UISmartPanelGrid,
  UIDateTimePicker,
  UIDialog,
} from 'RainbowUI';
import {
  PartyConst,
  PtyIntegrationSearchDialog,
  PartyAction,
  PtyIntegrationThirdParty,
} from 'EU00PartyCommonUI';
import moment from 'moment';

const partyAction = new PartyAction();

export default class ThirdPartyDriverInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      objectEntity: props.objectEntity,
      claimEntity: props.claimEntity,
      io: props.io,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      objectEntity: nextProps.objectEntity,
    });
  }

  render() {
    const { objectEntity } = this.state;
    const { forModify, io } = this.props;
    if (objectEntity.TpDriverId) {
      const party = partyAction.loadPartyByPartyId(
        objectEntity.TpDriverId
      );
      objectEntity.TpDriverName = party.PartyName;
      objectEntity.IdNumber = party.IdNumber;
    }
    if (objectEntity.TpDriverBirthDate) {
      const { claimEntity } = this.state;
      objectEntity.Age = moment(claimEntity.AccidentTime).diff(
        moment(objectEntity.TpDriverBirthDate),
        'year'
      );
    } else {
      objectEntity.Age = null;
    }
    const ptyIntegrationSearchDialog =
      forModify === 'true' ? (
        <div />
      ) : (
        <PtyIntegrationSearchDialog
          ref="PartySearchDialogForDriver_Ref"
          id={`partySearchDialogForDriver${objectEntity.SeqNo}`}
          isSearch
          getSearchResult={this.getThirdPartyDriver.bind(this)}
          isVisibled={false}
          isSearchRole
          title={i18n.SearchDriver}
          createClicked={this.createNewThirdParty.bind(this)}
          RoleName={PartyConst.PARTY_ROLE_NAME_DRIVER}
          enableChangePartyCategory="N"
        />
      );
    const ptyIntegrationThirdParty =
      forModify === 'true' ? (
        <div />
      ) : (
        <PtyIntegrationThirdParty
          id={`tpDriverCreationDialogId${objectEntity.SeqNo}`}
          onClickSubmit={this.onClickSubmit.bind(this)}
          onClickBack={this.onClickBack.bind(this)}
        />
      );
    return (
      <UISmartPanelGrid column="3">
        <UIText
          label={i18n.ClaimCommon.TPDriverName}
          io={io}
          onSuffixIconClick={this.showPartySearchPanelDialog.bind(
            this
          )}
          model={objectEntity}
          property="TpDriverName"
          disabled="true"
          suffixIcon="fa fa-search"
        />
        <UIText
          label={i18n.ClaimCommon.TPDriverIdNumber}
          model={objectEntity}
          property="IdNumber"
          io="out"
        />
        <UIText
          label={i18n.ClaimCommon.TPDriverTel}
          model={objectEntity}
          property="TpTelNo"
          io={io}
        />
        <UIDateTimePicker
          label={i18n.ClaimCommon.TPDateOfBirth}
          model={objectEntity}
          format="MM/DD/YYYY"
          io={io}
          maxDate="TODAY"
          property="TpDriverBirthDate"
          onChange={this.changeBirthDate.bind(this)}
        />
        <UIText
          label={i18n.ClaimCommon.TPDriverAge}
          model={objectEntity}
          io="out"
          property="Age"
        />
        <UIText
          label={i18n.ClaimCommon.TPPlateNo}
          model={objectEntity}
          io={io}
          property="TpPlateNo"
        />
        {ptyIntegrationSearchDialog}
        {ptyIntegrationThirdParty}
      </UISmartPanelGrid>
    );
  }

  changeBirthDate() {
    const { claimEntity } = this.state;
    const { objectEntity } = this.state;
    objectEntity.Age = moment(claimEntity.AccidentTime).diff(
      moment(objectEntity.TpDriverBirthDate),
      'year'
    );
    this.setState({
      objectEntity,
    });
  }

  getThirdPartyDriver(party) {
    const { objectEntity } = this.state;
    const { forModify } = this.props;
    objectEntity.TpDriverName = party.PartyName;
    objectEntity.TpDriverId = party.PartyId;
    objectEntity.IdNumber = party.IdNumber;
    objectEntity.TpTelNo = party.MobileTel;
    objectEntity.TpDriverBirthDate = party.DateOfBirth;
    if (forModify === 'true') {
      UIDialog.show('ModifySubclaimInfo_Dialog');
    }
    this.setState({
      objectEntity,
    });
  }

  createNewThirdParty() {
    this.refs.PartySearchDialogForDriver_Ref.hide();
    const {
      objectEntity: { SeqNo },
    } = this.state;
    UIDialog.show(`tpDriverCreationDialogId${SeqNo}`);
  }

  onClickBack() {
    this.refs.PartySearchDialogForDriver_Ref.show();
  }

  onClickSubmit(party) {
    const { objectEntity } = this.state;
    objectEntity.TpDriverName = party.PartyName;
    objectEntity.TpDriverId = party.PartyId;
    objectEntity.IdNumber = party.IdNumber;
    objectEntity.TpTelNo = party.MobileTel;
    objectEntity.TpDriverBirthDate = party.DateOfBirth;
    this.setState({
      objectEntity,
    });
  }

  showPartySearchPanelDialog() {
    const { forModify } = this.props;
    if (forModify === 'true') {
      UIDialog.hide('ModifySubclaimInfo_Dialog');
      UIDialog.show('partySearchDialogForTPDriverForModify');
    } else {
      const { objectEntity } = this.state;
      UIDialog.show(
        `partySearchDialogForDriver${objectEntity.SeqNo}`
      );
    }
  }
}
