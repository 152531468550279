import { UIPage, UIPanel } from 'RainbowUI';
import PartyInfoTabReadOnly from '../views/PartyInfoTabReadOnly';

const PartyMaintainReadOnly = React.createClass({
  render() {
    const { params } = this.props;
    return (
      <UIPage>
        <UIPanel
          style={{ margin: '0 0 0 0' }}
          panelTitle={i18n.PartyMaintenance}
        >
          <PartyInfoTabReadOnly
            partyId={params.partyId}
            disabled="true"
          />
        </UIPanel>
      </UIPage>
    );
  },
});
export default PartyMaintainReadOnly;
