import { AjaxUtil, MessageHelper } from 'RainbowUI';
import Url from '../../url/Url';
import SolrService from '../../service/SolrService';
import SearchTaskMessage from '../SearchTaskMessage';

export default {
  getclaimlist(data, pageindex, pagesize) {
    let { Conditions } = data;
    if (!Conditions) Conditions = ' ';
    const { FuzzyConditions } = data;
    const { OrConditions } = data;
    const { BetweenConditions } = data;

    if (FuzzyConditions) {
      if (FuzzyConditions.ProcessOrganId)
        FuzzyConditions.ProcessOrganId *= 1;
    }
    if (Conditions) {
      if (Conditions.ProcessOrganId) Conditions.ProcessOrganId *= 1;
      if (Conditions.PolicyBranch) Conditions.PolicyBranch *= 1;
    }
    let result = {};
    let total = {};
    const PageNo = (pageindex - 1) / pagesize + 1;
    const claimQueryEntity = {
      Conditions,
      PageNo,
      PageSize: pagesize,

      FuzzyConditions,
      OrConditions,
      BetweenConditions,
      Module: 'ClaimCase',
      SortField: 'LastReviewDate',
      SortType: 'DESC',
      SearchType: 0,
    };

    SolrService.queryEntitySolr(claimQueryEntity).then(data => {
      if (data.Total === 0) {
        result = null;
        total = 0;
      } else {
        result = data.Results[0].SolrDocs;
        total = data.Total;
      }
    });

    const resultObj = { count: total, result };

    const searchTaskMessage = new SearchTaskMessage(resultObj);
    searchTaskMessage.showMessage();
    return resultObj;
  },

  getCaseIdByClaimNo(data) {
    const url = Url.ClaimQueryLoad + data;
    let result = {};
    AjaxUtil.doGet(
      url,
      {},
      {
        async: false,
        done(data) {
          result = data;
        },
        fail() {
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
        },
      }
    );
    return { result: result.Model };
  },
};
