import { AjaxUtil } from 'RainbowUI';
import Url from '../url/Url';

export default {
  getProductLineList(pageindex, pagesize) {
    let result = {};
    AjaxUtil.doGet(
      `${Url.ClaimProductLineLoad}?pageindex=${pageindex -
        1}&pagesize=${pagesize}`,
      {},
      {
        async: false,
        done(data) {
          result = data.Model;
        },
        fail() {
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
        },
      }
    );
    return {
      count: result.claimProductLineAmount,
      result: result.claimProductLineListByPage,
    };
  },
};
