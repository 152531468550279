export default {
  HelloWorld: '你好人类',
  HelloEbao: '你好易保',
  HelloInsure: '你好保险',

  AuditAdd: '{fieldName}：新增值是“{newValue}”',
  AuditRemove: '{fieldName}： 清空数据',
  AuditReplace: '{fieldName}：修改数据从“{oldValue}”到“{newValue}”',

  ConflictedSubclaimList: '不匹配子赔案列表',
  SubclaimNumber: '子赔案号码',
  ClaimHandling: '赔案处理',
  LossDescription: '损失描述',
  InsuredLiability: '被保险人责任',
  ContactType: '联系人类别',
  DateofRegistration: '立案日期',
  Catastrophe: '巨灾编号',
  CatastropheCode: '巨灾编号',
  CauseofLoss: '出险原因',
  SalesChannel: '代理人',
  SalesChannelInfo: '代理人信息',
  Claim: '赔案',
  Claimant: '索赔人',
  ClaimantInfo: '索赔人信息',
  SubclaimInfo: '子赔案信息',
  Subclaim: '子赔案',
  TaskTransfer: '任务切换',
  TaskTransferM: '手动任务指派',
  AssignTo: '指派到',
  TaskStatus: '任务状态',
  PolicyInfo: '保单信息',
  PolicyNumber: '保单号',
  ProductName: '产品名称',
  ProductTypeName: '产品类型名称',
  PolicyHolderName: '保单持有人姓名',
  PolicyHolderInfo: '保单持有人信息',
  PolicyBranch: '保单所属机构',
  PolicyBranchSearch: '保单所属机构查询',
  ContactPerson: '联系人',
  ContactTel: '联系电话',
  DateOfLoss: '出险日期',
  DateOfNotice: '报案日期',
  ClaimBranch: '赔案所属机构',
  ClaimBranchSearch: '赔案所属机构查询',
  ClaimNumber: '赔案号码',
  AccidentDistrict: '事故地区',
  AccidentLocation: '事故地点',
  DamageType: '损失类型',
  DamageItem: '损失项目',
  FirstOr3rdParty: '一者/三者',
  IDCard: '证件号码',
  EstimatedLoss: '预估损失金额',
  Currency: '币种',
  AddressLine1: '详细地址',
  Detail: '详情',
  ClaimsMade: '理赔发生制',

  CoverageName: '险种名称',
  DeductibleAmount: '免赔额',
  DeductibleRate: '免赔率',
  Deductible: '免赔',
  SumInsured: '保额',
  SILimit: '保额',
  InsuredList: '被保险人信息',
  InsuredName: '被保险人',
  InsuredCategory: '标的类型',
  InsuredId: '标的代码',
  InsuredUid: '标的 代码',
  InsuredIDNumber: '标的代码号',
  PolicyDetail: '保单详细信息',
  CoverageList: '险种信息',
  EffectiveDate: '保单生效日',
  ExpiryDate: '保单截止日',
  PremiunIsPaid: '保费是否收齐',
  PolicySearch: '保单查询',
  PolicyEffectiveDataFromTo: '保单起止日期',
  IDNumberOrBusinessRegisterNumber: '证件号码或商业注册码',
  PolicyEffectiveDate: '保单生效日',
  PolicyExpiryDate: '保单截止日',
  PolicyList: '保单列表',
  Product: '产品',
  IdType: '证件类型',
  ContactEmail: '联系邮件',
  CountryStateCity: '国家/州/市',
  Country: '国家',
  OrganzationName: '机构名称',

  RejectConfirmation: '拒赔确认',
  RejectReason: '拒赔原因',
  Remark: '备注',

  InsuredObject: '承保标的',
  PossibleSubrogation: '是否有追偿',
  PossibleSalvage: '是否有残值',
  DamageObject: '损失标的',
  DamageSeverity: '损失程度',
  FirstName: '名',
  LastName: '姓',
  Postcode: '邮编',
  PlaceOfInspection: '查勘地点类型',
  InspectionAddress: '查勘地点',
  Litigation: '诉讼',
  TotalLoss: '全损',
  DamageDescription: '损失描述',
  Coverage: '险别',
  InitialReserve: '初始准备金',
  TaskType: '任务类型',
  DueDate: '到期日',
  TaskDetail: '任务详情',
  AssignedBy: '指派人',
  Priority: '优先级',
  ClaimTask: '领取任务',
  MessageType: '信息类型',
  Date: '日期',
  Content: '内容',
  Dismiss: '忽略',
  Complete: '完成',
  ClaimantName: '索赔人',
  SubclaimType: '子赔案类型',
  AssignedDate: '指派时间',
  Status: '状态',
  ShowUnreadMessageOnly: '只显示未读信息',
  Datefromto: '日期',
  ShowOpenClaimsOnly: '只显示开启赔案',
  NewMessage: '新信息',
  MessageResult: '信息',
  MyClaims: '我的赔案',
  ShareingPool: '共享池',
  MyTask: '我的任务',
  OverDueDate: '逾期任务',
  Home: '首页',
  Configuration: '配置',
  ProductConfiguration: '产品配置',
  SearchClaim: '赔案查询',
  NewNoiceofLoss: '报案',
  CheckNoticeVersionMessage: '当前报案不是最新版本',
  Registration: '立案',
  Settlement: '决赔',
  SettlementApproval: '决赔签核',
  PendingNotice: '待处理报案',
  Yes: '是',
  No: '否',
  QueryCondition: '查询条件',
  MessageQueryCondition: '查询我的消息',
  TaskQueryCondition: '查询我的任务',
  CliamQueryCondition: '查询我的赔案',
  SharePoolQueryCondition: '查询共享池',
  ModifyClaimInfo: '修改赔案信息',
  ModifySublaimInfo: '修改子赔案信息',
  ValidationInfo: '校验信息',
  ModificationHistory: '修改历史',

  Assignment: '任务指派',
  AssignmentPendingTask: '待处理任务',
  AssignmentClosedTask: '已关任务',
  AssignmentNewTask: '创建任务',
  AssignmentReassignTask: '改派任务',
  AssignmentReassign: '改派任务',
  AssignmentCloseTask: '关闭任务',
  AssignmentAttachTo: '附属于',
  AssignmentAssignTo: '指派到',
  AssignmentClosedBy: '关闭人员',
  SendMessage: '发送信息',
  AssignmentApproach: '指派方式',
  AccidentCode: '巨灾编号',
  AccidentName: '巨灾名称',
  AccidentDesc: '巨灾描述',
  AccidentValidFlag: '有效',
  AccidentDefineCode: '定义巨灾代码',
  AssignmentStatus: '状态',
  AssignmentClosedDate: '关闭日期',
  AttachmentList: '文件列表',
  AttachmentPanel: '文件上传',
  DocumentName: '文件名称',
  DocumentType: '文件类型',
  UploadDate: '上传日期',
  CreatorName: '创建人',
  ReMark: '备注',
  SearchResult: '查询结果',

  ClaimQuery: '赔案查询',
  ClaimOwner: '赔案负责人',
  LossDateSection: '事故起/止日',
  LossStatus: '赔案状态',
  HideSearchOptions: '隐藏查询条件',
  ShowSearchOptions: '展开查询条件',
  TotalIncurred: '金额',
  PriorClaims: 'CLP历史赔案',
  PriorClaimsLink: '历史赔案',
  RENotice: '今天已经存在报案，是否继续报案',

  NoteDate: '日期',
  NoteBy: '提交人',
  NoteCategory: '分类',
  NoteContent: '内容',
  NoteSendMessageTo: '发送信息至',
  Note: '笔记',
  NoticeofLoss: '理赔处理',

  Continue: '继续',
  Retrieve: '抄单',
  Clear: '重置',
  Action: '操作',
  Edit: '编辑',
  Delete: '删除',
  Save: '保存',
  btnSave: '保存',
  Submit: '提交',
  Reject: '拒赔',
  WorKOn: '操作',
  BacktoClaim: '返回赔案',
  Cancel: '取消',
  Add: '添加',
  Search: '查询',
  Reset: '重置',
  New: '新建',
  okButton: '确定',
  Filter: '查询',
  RejectSubclaim: '拒赔子赔案',
  Upload: '上传',
  Back: '返回',
  SelectAsClaimant: '选为索赔人',
  Hide: '隐藏',

  deleteConfirmText: '确定删除参数吗?',

  NoRiskSelected: '至少添加一条RISK',
  NoPolicy: '该保单号不存在。',
  WarnMessage:
    '这项索赔的未决任务如下。您可以选择分配给您的任务来完成它。',
  RejectDialogConfirm: '确定要中断此',
  NoCoverageSelectedMessage: '请至少选择一个利益项,对于这些子赔案。',
  SubclaimInitLossIsEmpty: '请输入初始准备金，对于这些子赔案。',
  NewSubclaimShouldSubmit: '请先提交新增加的子赔案。',
  AllSubclaimAreRejected: '所有的子赔案都已经被拒绝，请拒绝赔案。',
  SubclaimInfoRequiredFieldIsMissing:
    '请完善子赔案信息页面的必填项。',
  ChangePolicyMessage: '重新抄单将会清除以下信息，确定继续吗？',
  ChangeCauseofLossMessage:
    '改变出险原因可能会清除以下信息，确定继续吗？',
  ConflictedSubclaimMessage:
    '改变出险原因会关闭子赔案(SubclaimNo), 确定继续吗？',
  SubclaimHaveSettle: '相关子赔案已做决赔，出险原因不可变更',
  CheckClaimDataMessage01: '出险日期不能晚于报案日期',
  CheckClaimDataMessage02: '立案日期不能晚于报案日期',
  CheckClaimDataMessage03: '出险日期不能晚于当前日期',
  CheckClaimDataMessage04: '报案日期不能晚于当前日期',
  CheckSubClaimTypeMsg:
    '只能创建一个subclaim 属于以下subClaimType(s)类型：',
  NoClaimantError: '索赔人信息不能为空',

  ApprovalHistory: '签核历史',
  ApprovalDate: '日期',
  ApprovalName: '签核人',
  ApprovalSummay: '内容描述',
  ApprovalDecision: '签核决定',
  ApprovalRemark: '备注',
  Decision: '决定',
  ApprovalRemarkHolder: '请输入备注',

  MassiveReassignmentTaskowner: '批量改派（任务负责人）',
  MassiveReassignment: '批量改派',
  TaskOwner: '任务负责人',
  CreateDate: '创建日期',
  CreateUser: '创建人',
  ChangeTaskOwner: '更改任务负责人',
  ChangeTaskOwnerPage: '更改任务负责人',
  NoRecSelected: '请至少选择一条记录进行改派',
  NoAssignToSelected: '请选择用户进行任务改派',
  ReassignSus: '以下任务已改派成功。',
  ReassignFail: '由于当前用户缺少相关任务权限，以下任务改派失败。',
  TaskList: '任务列表',
  ReassignClaimSus: '以下赔案已改派成功。',
  ReassignClaimFail:
    '由于当前用户缺少相关‘赔案负责人’权限，以下赔案改派失败。',
  ClaimList: '赔案列表',
  MassiveReassignmentClaimowner: '批量改派（赔案负责人）',
  PolicyHolder: '保单持有人',
  SubClaimNo: '子赔案号码',
  ReassignSubclaimType: '子赔案类型',
  ChangeClaimOwner: '更改赔案负责人',
  ChangeClaimOwnerPage: '更改赔案负责人',

  SaveSuccess: '{0}保存成功',
  SaveFail: '{0}保存失败,请联系系统管理员',
  SubmitSuccess: '{0}提交成功',
  SubmitFail: '{0}提交失败,请联系系统管理员',
  DeleteSuccess: '{0}删除成功',
  DeleteFail: '{0}删除失败,请联系系统管理员',
  IsExist: '{0}已存在',
  Fail: '失败',

  ManualAssignmentApproach: '手动指派方式',
  AutomaticAssignmentApproach: '自动指派方式',

  ClaimTitle: '理赔',
  AssignRight: '权限分配',
  ClaimAuthority: '理赔权限',
  InheritedAuthority: '继承权限',
  Authority: '权限',
  AuthorizedAmount: '权限金额',
  authoritySubmitSuccess: '权限保存成功',
  authoritySubmitError: '提交错误',
  authorityResetSuccess: '权限重置成功',
  onlyShowNoTaskOwner: '仅显示无负责人的任务',
  ClaimAuthorityJunior: '初级理赔人员',
  ClaimAuthoritySenior: '高级理赔人员',
  ClaimAuthorityManager: '理赔经理',
  AuthorityConfirmReset:
    '确认重置权限？ 重置成功后所有数据将默认继承上级权限。',

  assignmentNewTaskSu: '任务创建成功',
  assignmentReassignTaskSu: '任务改派成功',
  assignmentCloseTaskSu: '任务关闭成功',
  assignmentComfirmClosed: '任务关闭后数据将丢失，确认是否关闭',
  CatastropheSaved: '巨灾编号保存成功',
  CatastropheCheck: '巨灾编号已存在',

  selectATaskFirst: '请先选择一个任务',
  selectAssignTo: '请选择任务负责人',
  ClaimTaskSU: '任务认领成功',
  ClaimTaskFail: '任务认领失败',
  ErrorMessage: {
    CLM__UNKNOWN_ERROR: '未知系统错误，请联系管理员',
  },
  ClearCache: '清空缓存',
  ReLogin: '请重新登陆',
  ReserveHistoryBlankOption: '全部',
  RetrievePolicySuccessfully: '抄单成功',

  I18nUtilValidate(arg) {
    return `字段 (${arg}) 必填,不能为空`;
  },
  FieldMaxLength: '{0}最大长度为{1}',
  LitigationAction: '操作',
  SuitStatus: '案件状态',
  AmountAppealed: '申诉金额',
  SuitLocation: '案件地址',
  AttachTo: '附属于',
  DipositionAmount: '判决金额',
  removeLitigationConfirm: '确认是否删除当前诉讼记录？',
  LitigationButtonEdit: '编辑',
  Lawyer: '律师',
  LawyerName: '律师姓名',
  LawyerTel: '律师电话',
  LawyerEmail: '律师邮箱',
  provideValidEmail: '请提供有效邮箱地址',
  LitigationButtonRemove: '删除',
  LitigationStatus: '状态',
  AssignToSelectBlankOption: 'POOL',
  litigationInfo: '诉讼详情',
  litigationList: '诉讼列表',
  SuitConclusion: '诉讼判决',
  SuitName: '诉讼名称',
  SuitType: '诉讼类型',
  SuitStatusRemark: '状态备注',
  SuitOpenDate: '诉讼开始日期',
  SuitCaseNumber: '诉讼单号',
  SuitCloseDate: '诉讼结束日期',
  FraudIndicatorTitle: '欺诈指标',
  FraudIndicatorAndDesc: '欺诈指标及描述',
  others: '其他',
  MessageDimissed: 'Message is completed successfully',

  NotPremiumRecord: '保单无保费记录 ',
  PremiumNotCollected: '保单保费未收齐 ',
  PremiumCollected: '保单保费已收齐 ',

  ClaimHandlingTitle(claimData) {
    return `赔案 ${claimData.ClaimNo}`;
  },

  ReserveBigThenSumInsuredError: '初始准备金不能大于赔偿限额',

  NoRecordFound: 'No Record Found',

  ProductInfo: 'Product Infomation',
  RiskInfo: 'Risk Infomation',
  RiskName: 'Risk Name',
  AddRiskName: 'Add Risk',
  AddRiskButton: 'ADD RISK',
  AddRiskFail: 'The Policy has new Endorsement.',
  PolicyRetrieveFail: 'Fail to  get the Policy infomatioin',

  SyncInfo: '同步',
};
