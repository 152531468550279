import {
  DataContext,
  UIMenuBar,
  UISubMenu,
  UIMenuItem,
  UIParam,
  I18NUtil,
  AjaxUtil,
} from 'RainbowUI';
import UserSetting from './UserSetting';
import Url from '../url/Url';
import UserUtil from '../util/UserUtil';
import ClaimMenu from '../constant/ClaimMenu';
import CodeTableAction from '../action/CodeTableAction';

const ClaimPageHeader = React.createClass({
  name: 'ClaimPageHeader',

  getLogo() {
    return (
      <img
        alt=""
        src={config.logo}
        style={{
          display: 'block',
          position: 'relative',
          top: '50%',
          transform: 'translate(0 , -50%)',
          width: '66px',
        }}
        onClick={this.onClickHome}
      />
    );
  },

  componentDidMount() { },
  businessContent() {
    return <UserSetting id="1" userRealName={env.UserRealName} userRole="" />;
  },
  render() {
    UserUtil.initUser();
    console.log('env: ', JSON.stringify(env));
    return (
      <div>
        <UIMenuBar
          headerTitle={this.getLogo()}
          showBusinessUser
          businessValue={this.businessContent()}
          logout={window.logout}
          styleClass="black"
          onHeaderTitleClick=""
        >
          <UIMenuItem
            value={i18n.ClaimCommon.Home}
            icon="fa fa-bank"
            onClick={this.onClickHome}
          />
          <UIMenuItem
            value={i18n.ClaimCommon.NewNoiceofLoss}
            icon="fa fa-pencil-square-o"
            onClick={this.showNewNoticeofLossDialog}
            visibled={UserUtil.hasMenu(ClaimMenu.NewNoticeOfLoss)}
          >
            <UIParam name="param" value="#/notice/noticeofloss" />
          </UIMenuItem>
          <UIMenuItem
            value={i18n.ClaimCommon.SearchClaim}
            icon="fa fa-search"
            onClick={this.showClaimQueryDialog}
            visibled={UserUtil.hasMenu(ClaimMenu.SearchClaim)}
          >
            <UIParam name="param" value="#/query/claimQuery" />
          </UIMenuItem>
          {/*
          <UIMenuItem
            value="Dashboard"
            icon="fa fa-dashboard"
            onClick={this.showClaimQueryDialog}
            visibled={UserUtil.hasRole('Claim Manager')}
          >
            <UIParam name="param" value="#/dashboard/dashboard" />
          </UIMenuItem>
          */}
          <UISubMenu
            value={i18n.ClaimCommon.MassiveReassignment}
            icon="fa fa-cog"
            visibled={UserUtil.hasMenu(ClaimMenu.MassiveReassignment)}
          >
            <UIMenuItem
              value={i18n.ClaimCommon.MassiveReassignmentTaskownerHeader}
              onClick={this.onClickMenuItem}
              visibled={UserUtil.hasMenu(
                ClaimMenu.MassiveReassignmentTaskOwner
              )}
            >
              <UIParam
                name="param"
                value="#/reassignment/MassiveReassignmentTaskOwner"
              />
            </UIMenuItem>
            <UIMenuItem
              value={i18n.ClaimCommon.MassiveReassignmentClaimownerHeader}
              onClick={this.onClickMenuItem}
              visibled={UserUtil.hasMenu(
                ClaimMenu.MassiveReassignmentClaimOwner
              )}
            >
              <UIParam
                name="param"
                value="#/reassignment/MassiveReassignmentClaimOwner"
              />
            </UIMenuItem>
          </UISubMenu>

          <UISubMenu
            value={i18n.ClaimCommon.Configuration}
            icon="fa fa-cogs"
            visibled={UserUtil.hasMenu(ClaimMenu.Configuration)}
          >
            <UIMenuItem
              value={i18n.ClaimCommon.Catastrophe}
              icon="fa fa-pencil-square-o"
              onClick={this.onClickMenuItem}
              visibled={UserUtil.hasMenu(ClaimMenu.Catastrophe)}
            >
              <UIParam name="param" value="#/Catastrophe" />
            </UIMenuItem>
            <UIMenuItem
              value={i18n.ClaimCommon.ProductConfiguration}
              icon="fa fa-cogs"
              onClick={this.onClickMenuItem}
              visibled={UserUtil.hasMenu(
                ClaimMenu.ProductConfiguration
              )}
            >
              <UIParam name="param" value="#/product/configuration" />
            </UIMenuItem>
            <UIMenuItem
              value={i18n.ClaimCommon.ClaimAuthority}
              icon="fa fa-cogs"
              onClick={this.onClickMenuItem}
              visibled={UserUtil.hasMenu(
                ClaimMenu.ProductConfiguration
              )}
            >
              <UIParam
                name="param"
                value="#/authority/configuration"
              />
            </UIMenuItem>
            <UIMenuItem
              value={i18n.ClaimCommon.ClaimMatrixExport}
              icon="fa fa-cogs"
              onClick={this.onClickMenuItem}
              visibled={UserUtil.hasMenu(
                ClaimMenu.ClaimMatrixExportImport
              )}
            >
              <UIParam name="param" value="#/claimMatri" />
            </UIMenuItem>

            <UIMenuItem
              value={i18n.ClaimCommon.AssignmentApproach}
              icon="fa fa-cogs"
              onClick={this.onClickMenuItem}
              visibled={UserUtil.hasMenu(
                ClaimMenu.AssignmentApproach
              )}
            >
              <UIParam name="param" value="#/AssignmentApproach" />
            </UIMenuItem>

            {/** <UIMenuItem value={i18n.ClaimCommon.ClearCache} icon="fa fa-cogs" onClick={this.onClickClearCache}></UIMenuItem>* */}
          </UISubMenu>

          <UISubMenu
            value={i18n.ClaimCommon.AccidentFrom3rd}
            icon="fa fa-hourglass"
            visibled={UserUtil.hasMenu(ClaimMenu.AccidentFrom3rd)}
          >
            <UIMenuItem
              value={i18n.ClaimCommon.AccidentFromRTA}
              icon="fa fa-hourglass"
              onClick={this.onClickMenuItem}
              visibled={UserUtil.hasMenu(ClaimMenu.AccidentFromRTA)}
            >
              <UIParam name="param" value="#/Accident/Rta" />
            </UIMenuItem>
          </UISubMenu>
          <UIMenuItem
            value="Policy Data Management"
            icon="fa fa-file"
            onClick={this.showClaimQueryDialog}
            visibled={UserUtil.hasMenu(
              ClaimMenu.PolicyDataManagement
            )}
          >
            <UIParam name="param" value="#/policydata/policydata" />
          </UIMenuItem>
          <UISubMenu
            value={i18n.ClaimCommon.More}
            icon="fa fa-ellipsis-h"
            visibled={UserUtil.hasMenu(ClaimMenu.More)}
          >
            <UIMenuItem
              value={i18n.ClaimCommon.Party}
              onClick={this.openNewDialog}
              visibled={UserUtil.hasMenu(
                ClaimMenu.Party
              )}
            >
              <UIParam name="param" value={env.PARTY_HOME_URL} />
            </UIMenuItem>
            <UIMenuItem
              value={i18n.ClaimCommon.UserAccountAndAuthority}
              onClick={this.openNewDialog}
              visibled={UserUtil.hasMenu(
                ClaimMenu.UserAccountAndAuthority
              )}
            >
              <UIParam name="param" value={env.USER_ACCOUNT_AND_AUTHORITY} />
            </UIMenuItem>
          </UISubMenu>
        </UIMenuBar>
      </div>
    );
  },
  onClickMenuItem(event) {
    if (event.getParameter('param') === '#/') {
      DataContext.put('myPage', null);
    }
    window.location.hash = event.getParameter('param');
  },

  onClickHome(event) {
    window.location.hash = '#/';
  },

  onChangLanguage(event) {
    const language = event.getParameter('param');
    AjaxUtil.doPost(
      `${Url.UserProfileLocale}?lang=${language}`,
      {},
      {
        async: false,
        done() {
          console.log('Change language succeed.');
          I18NUtil.setSystemI18N(language);
        },
        fail() {
          console.log('Change language failed.');
        },
      }
    );

    location.reload();
  },

  showNewNoticeofLossDialog(event) {
    window.location.hash = event.getParameter('param');
    window.location.reload(true);
  },

  showClaimQueryDialog(event) {
    window.location.hash = event.getParameter('param');
  },

  openNewDialog(event) {
    window.open(event.getParameter('param'));
  },

  onClickClearCache() {
    CodeTableAction.clearCacheAll();
    AjaxUtil.doGet(
      Url.ClearCache,
      {},
      {
        async: false,
        done() {
          console.log('Clear cache succeed.');
        },
        fail() {
          console.log('Clear cache failed.');
        },
      }
    );
    location.reload();
  },
});

export default ClaimPageHeader;
