import alt from '../alt';
import SettlementHistoryAction from '../action/SettlementHistoryAction';

class SettlementHistoryStore {
  constructor() {
    this.bindListeners({
      initData: SettlementHistoryAction.INIT_DATA,
      onShow: SettlementHistoryAction.ON_SHOW,
      cancelSubmit: SettlementHistoryAction.CANCEL_SUBMIT,
    });

    this.historyList = [];
    this.settleid = null;
  }

  initData(historyList) {
    this.historyList = historyList;
  }

  onShow(settleid) {
    this.settleid = settleid;
  }

  cancelSubmit(settlePayeeId) {
    const list = this.historyList;
    const pid = settlePayeeId;
    for (let i = 0; i < list.length; i += 1) {
      if (list[i].SettlePayeeId == pid) {
        list[i].PaymentStatus = 'CANCEL';
      }
    }
  }
}
export default alt.createStore(
  SettlementHistoryStore,
  SettlementHistoryStore
);
