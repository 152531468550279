import alt from '../alt';

const SubrogationAction = require('../action/SubrogationAction');

class SubrogationApproveStore {
  constructor() {
    this.approveInfo = {
      BusinessId: null,
      TaskInstanceId: null,
      Remark: '',
      DecisionCode: null,
    };

    this.bindListeners({
      handleLoadApproveTask: SubrogationAction.LOAD_APPROVE_TASK,
    });
  }

  handleLoadApproveTask(data) {
    this.approveInfo = data;
    this.approveInfo.Remark = '';
    this.approveInfo.DecisionCode = null;
  }
}
export default alt.createStore(
  SubrogationApproveStore,
  SubrogationApproveStore
);
