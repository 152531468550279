import { UIPanel, DataContext } from 'RainbowUI';
import { ARAPSearchResult } from 'EU00PartyCommonUI';
import ClaimCommonExt from 'ClaimCommonExt';
import ClaimConstant from '../../constant/ClaimConstant';

const ClaimBaseInfo = ClaimCommonExt.getClaimBaseInfo();

export default class PremiumDetailTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = { claimEntity: props.claimEntity };
  }

  render() {
    const _self = this;
    const { claimEntity } = this.state;
    const NoteType = {};
    NoteType.ARAP_FEE = 1;
    NoteType.COMMISSION_FEE = 2;
    NoteType.COLLECTION = 3;
    NoteType.PAYMENT = 4;
    NoteType.OFFSET = 5;
    NoteType.ARAP_FLAGGING = 6;
    NoteType.CHEQUE_VOID = 7;
    let policyNo = claimEntity.PolicyEntity.PolicyNo;
    if (
      claimEntity.PolicyEntity.GroupFlag ==
        ClaimConstant.GroupPolicyType ||
      claimEntity.PolicyEntity.SettlementOption ==
        ClaimConstant.ByDeclaration
    ) {
      policyNo = claimEntity.PolicyEntity.MasterPolicyNo;
    }
    DataContext.put('arapSearchModel', {
      ArapType: '-1001',
      PolicyNo: policyNo,
    });

    return (
      <div>
        <ClaimBaseInfo claimEntity={_self.props.claimEntity} />
        <UIPanel
          id="premiumDetailPanel"
          panelTitle={i18n.ClaimCommon.PremiumDetail}
        >
          <ARAPSearchResult
            NoteType={NoteType}
            selectable="none"
            policyNo={policyNo}
            isQuery
            currencyService={window.EU00}
          />
        </UIPanel>
      </div>
    );
  }
}
