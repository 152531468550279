import { AjaxUtil } from 'RainbowUI';
import Url from '../url/Url';

export default {
  queryAuditData(auditEventType, objectId, pageIndex, pageSize) {
    console.log('==========auditEventType: ', auditEventType);
    console.log('==========objectId: ', objectId);

    const deferred = $.Deferred();
    AjaxUtil.doGet(
      `${Url.QueryAuditData +
        auditEventType}/${objectId}/${pageIndex - 1}/${pageSize}`,
      {},
      {
        async: false,
        done(data) {
          console.log('======queryAuditData success====');
          deferred.resolve(data);
        },
        fail(err) {
          console.log('======queryAuditData fail====');
          deferred.reject(err);
        },
      }
    );
    return deferred.promise();
  },

  queryCaseClaimantPartyData(caseId) {
    const deferred = $.Deferred();
    AjaxUtil.doGet(
      Url.QueryCaseClaimantPartyData + caseId,
      {},
      {
        async: false,
        done(data) {
          console.log('======queryCaseClaimantPartyData success====');
          deferred.resolve(data);
        },
        fail(err) {
          console.log('======queryCaseClaimantPartyData fail====');
          deferred.reject(err);
        },
      }
    );
    return deferred.promise();
  },
};
