const Util = {
  isEmptyObject(obj) {
    for (const n in obj) {
      return false;
    }
    return true;
  },
  currencyFormat(num) {
    const format = uiconfig.DEFAULT_CURRENCY_FORMAT;
  },
  isEmptyQueryObject(validateArray, module) {
    try {
      if (!validateArray) {
        return false;
      }
      let count = 0;
      for (let i = 0; i < validateArray.length; i += 1) {
        const objName = validateArray[i];
        if (module[objName]) {
          count += 1;
        }
      }
      if (count > 0) {
        return false;
      }
      return true;
    } catch (e) {
      console.error(e);
    }
  },
  isEmptyQueryModel(obj) {
    for (const key in obj) {
      if (
        obj[key] &&
        key !== 'PageNo' &&
        key !== 'PageSize' &&
        key !== 'UploadType' &&
        key !== 'QueryType'
      ) {
        return false;
      }
    }
    return true;
  },
};

export default Util;
