const home = env.serverUrl;
const urlDefine = {
  SalvageTaskLoad: `${home}salvage/load/task/`,
  SalvageReadOnlyLoad: `${home}salvage/load/`,
  SalvageItemsLoad: `${home}salvage/load/items/`,
  SalvageHistoryLoad: `${home}salvage/load/history/`,
  SalvageClosedTasksLoad: `${home}salvage/load/closedTasks/`,

  SalvageApproveLoad: `${home}common/approve/load/`,

  SalvageSave: `${home}salvage/save/`,
  SalvageSubmit: `${home}salvage/submit/`,
  SalvageApprove: `${home}salvage/approve/`,
};
export default urlDefine;
