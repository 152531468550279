import { AjaxUtil } from 'RainbowUI';
import ClaimCommonExt from 'ClaimCommonExt';
// var Q = require('q');
const Url = ClaimCommonExt.getUrl();
export default {
  loadClaimAuthority(permissionCode, path, type) {
    const url = `${Url.ClaimAuthorityLoad}?permissionCode=${permissionCode}&authorityPath=${path}&leafType=${type}`;
    console.log('url===>>>', url);
    const defer = $.Deferred();
    AjaxUtil.doGet(
      url,
      {},
      {
        async: false,
        done(data, textStatus, jqXHR) {
          console.log(`done:======>>>>>>>${JSON.stringify(data)}`);
          if (data) {
            defer.resolve(data);
          } else {
            defer.reject({
              status: jqXHR.status,
              responseText: jqXHR.responseText,
            });
          }
        },
        fail(jqXHR) {
          // jqXHR.status, jqXHR.responseText
          defer.reject({
            status: jqXHR.status,
            responseText: jqXHR.responseText,
          });
        },
      }
    );
    return defer.promise();
  },
  initClaimAuthorityTree() {
    const url = Url.ClaimAuthorityInit;
    let returnData = null;
    AjaxUtil.doGet(
      url,
      {},
      {
        async: false,
        done(data) {
          returnData = data;
        },
      }
    );
    return returnData;
  },
  submitClaimAuthorityList(
    claimAuthorityList,
    permissionCode,
    authorityPath,
    type
  ) {
    const url = `${Url.ClaimAuthoritySubmit}?permissionCode=${permissionCode}&authorityPath=${authorityPath}&type=${type}`;
    console.log('submit authority url====>>>', url);
    let returnData = null;
    AjaxUtil.doPost(url, claimAuthorityList, {
      async: false,
      done(data) {
        returnData = data;
      },
    });
    return returnData;
  },
  resetClaimAuthorityList(permissionCode, authorityPath) {
    const url = `${Url.ClaimAuthorityReset}?permissionCode=${permissionCode}&authorityPath=${authorityPath}`;
    console.log('reset authority url====>>>', url);
    let returnData = null;
    AjaxUtil.doGet(
      url,
      {},
      {
        async: false,
        done(data) {
          returnData = data;
        },
      }
    );
    return returnData;
  },
  checkAuth(caseId, code) {
    let result;
    const url = `${Url.ClaimAuthorityCheck}?caseId=${caseId}&code=${code}`;
    console.log(url);
    AjaxUtil.doGet(
      url,
      {},
      {
        async: false,
        done(data) {
          console.log('====data====', data);
          result = data;
        },
      }
    );
    return result;
  },
  getAuthortyByPermissionCode(permissionCode) {
    let result;
    const url = Url.getAuthortyByPermissionCode;
    console.log(url);
    AjaxUtil.doGet(url, permissionCode, {
      async: false,
      done(data) {
        console.log('====data====', data);
        result = data;
      },
    });
    return result;
  },
};
