import {
  UIBox,
  UISelect,
  UIDialog,
  UIUpdatePanel,
  UIText,
  UIButton,
  UISmartPanelGrid,
  UIRadio,
} from 'RainbowUI';
import BindToMixin from 'react-binding';
import { CodeTableAction, CodeTableCstUI } from 'ClaimCommonConstant';

const SubclaimTypeAction = require('../action/SubclaimTypeAction');

const SubclaimTypeDialog = React.createClass({
  mixins: [BindToMixin],

  getInitialState() {
    return { subclaimTypeEntity: {}, flag: {} };
  },

  showDialog(data, flag) {
    this.setState({ subclaimTypeEntity: data });
    this.setState({ flag });
    UIDialog.show('subclaimTypeDialog');
  },

  clickSave(evevt) {
    const result = SubclaimTypeAction.saveSubclaim(
      this.state.subclaimTypeEntity
    );
    if (result === 'true') {
      this.props.parentComponet.updatePage();
      UIDialog.hide('subclaimTypeDialog');
    }
  },

  render() {
    return (
      <UIDialog
        id="subclaimTypeDialog"
        title={i18n.ClaimProduct.SubclaimTypeManagement}
        width="80%"
        height="600px"
        modal="false"
      >
        <UIUpdatePanel
          id="subclaimTypeDialogUIUpdate"
          render={this.renderDialogContent}
        />
        <UIBox direction="left">
          <UIButton
            value={i18n.ClaimProduct.Save}
            onClick={this.clickSave}
            validationGroup="subclaim"
            causeValidation="true"
          />
          <UIButton
            value={i18n.ClaimProduct.Cancel}
            buttonType="cancel"
          />
        </UIBox>
      </UIDialog>
    );
  },
  renderDialogContent() {
    const _self = this;
    const flag = _self.state.flag.out;
    return (
      <UISmartPanelGrid column="2" widthAllocation="4,8">
        <UIText
          io={flag}
          id="subclaimTypeCode_01"
          label={i18n.ClaimProduct.SubclaimTypeCode}
          valueLink={this.bindToState(
            'subclaimTypeEntity',
            'SubclaimTypeCode'
          )}
          required="true"
          validationGroup="subclaim"
          render={data => {
            if (data.typeCode !== '') {
              _self.init();
            }
          }}
        />
        <UIText
          id="subclaimTypeName"
          label={i18n.ClaimProduct.SubclaimTypeName}
          valueLink={this.bindToState(
            'subclaimTypeEntity',
            'SubclaimTypeName'
          )}
          required="true"
          validationGroup="subclaim"
        />
        <UIRadio
          id="isUnique"
          label={i18n.ClaimProduct.IsUnique}
          valueLink={this.bindToState(
            'subclaimTypeEntity',
            'IsUnique'
          )}
          codeTable={CodeTableAction.loadDataList(
            CodeTableCstUI.ClaimYesOrNo,
            null
          )}
        />
        <UIRadio
          id="isInsuredObject"
          label={i18n.ClaimProduct.IsInsuredObject}
          valueLink={this.bindToState(
            'subclaimTypeEntity',
            'IsInsuredObject'
          )}
          codeTable={CodeTableAction.loadDataList(
            CodeTableCstUI.ClaimYesOrNo,
            null
          )}
        />
        <UISelect
          id="fraudSubjectCode"
          label={i18n.ClaimProduct.FraudSubjectCode}
          valueLink={this.bindToState(
            'subclaimTypeEntity',
            'FraudSubjectCode'
          )}
          codeTable={CodeTableAction.loadDataList(
            CodeTableCstUI.ClaimFraudSubject,
            null
          )}
          validationGroup="subclaim"
        />
        <UIText
          colspan="2"
          id="subclaimTypeDescription"
          label={i18n.ClaimProduct.SubclaimTypeDescription}
          valueLink={this.bindToState(
            'subclaimTypeEntity',
            'SubclaimTypeDescription'
          )}
        />
      </UISmartPanelGrid>
    );
  },
});
export default SubclaimTypeDialog;
