import PageStore from '../../store/PageStore';

const ClaimWorkFlowRight = React.createClass({
  componentWillUnmount() {
    PageStore.unlisten(this.onStoreStateChange);
  },

  onStoreStateChange(storeState) {
    if (!_.isEmpty(storeState.page)) {
      this.setState({ page: storeState.page });
    }
  },
  getInitialState() {
    return { page: {} };
  },
  render() {
    let dis = 'block';
    let disp = 'block';
    const { page } = this.state;
    const { menuDefinitions } = this.props;
    if (page.myPage === 'MyTask' || !page.myPage) {
      dis = 'block';
      disp = 'none';
    } else if (page.myPage === 'PoolTask') {
      dis = 'none';
      disp = 'block';
    } else {
      dis = 'none';
      disp = 'none';
    }
    return (
      <div>
        <div style={{ display: dis }} id="myTask">
          {menuDefinitions.MyTask}
        </div>
        <div style={{ display: disp }} id="poolTask">
          {menuDefinitions.PoolTask}
        </div>
        {this.getContent()}
      </div>
    );
  },

  componentWillMount() {
    PageStore.listen(this.onStoreStateChange);
  },

  getContent() {
    const { myPage } = this.state.page;
    let component = '';
    if (myPage !== 'MyTask' && myPage !== 'PoolTask')
      component = this.props.menuDefinitions[myPage];
    return component;
  },
});
export default ClaimWorkFlowRight;
