import alt from '../alt';

const SubrogationAction = require('../action/SubrogationAction');

class SubrogationHistoryStore {
  constructor() {
    this.SubrogationHistory = [];
    this.SubrogationId = null;
    this.CurrencyCode = null;
    this.bindListeners({
      handleLoad: SubrogationAction.LOAD_SUBROGATION_HISTORY,
      handleShowReadOnly: SubrogationAction.SHOW_READ_ONLY,
    });
  }

  handleLoad(data) {
    this.SubrogationHistory = data;
  }

  handleShowReadOnly(subrogationId) {
    this.SubrogationId = subrogationId;
  }
}
export default alt.createStore(
  SubrogationHistoryStore,
  SubrogationHistoryStore
);
