import {
  UIColumn,
  UILink,
  UISelect,
  UIDataTable,
  UIButton,
  UIDateTimePicker,
  UIParam,
} from 'RainbowUI';
import BindToMixin from 'react-binding';
import ClaimWorkFlowMessageAction from '../../action/desktop/ClaimWorkFlowMessageAction';
import ConditionStore from '../../store/ConditionStore';

const CodeTableAction = require('../../action/CodeTableAction');
const CodeTableCstUI = require('../../constant/CodeTableCstUI');

const ClaimWorkFlowMessageList = React.createClass({
  mixins: [BindToMixin],
  componentWillUnmount() {
    ConditionStore.unlisten(this.onStoreStateChange);
  },

  onStoreStateChange(storeState) {
    if (!_.isEmpty(storeState.condition)) {
      this.setState({ condition: storeState.condition });
    }
  },
  getInitialState() {
    return {
      condition: {
        isInital: true,
        Status: '100007',
        DueDate_from: '2000-01-01',
        DueDate_to: this.props.format(new Date(), 'yyyy-MM-dd', 1),
      },
      isUpdate: false,
    };
  },
  componentDidMount() {
    ConditionStore.listen(this.onStoreStateChange);
  },
  getMessageList() {
    return ClaimWorkFlowMessageAction.initMessage(
      this.state.condition
    );
  },
  dismissed(event) {
    const data = event.getParameter('data');
    const ok = ClaimWorkFlowMessageAction.dimissed(data);
    if (ok) {
      this.state.condition.isUpdate = true;
      ClaimWorkFlowMessageAction.initCondition(this.state.condition);
    }
  },
  openMyTask(event) {
    const data = event.getParameter('data');
    window.location.hash = `#/handling/tasks?caseId=${data.contextDisplays[0].ContextData.CaseId}`;
  },

  render() {
    const self = this;
    return (
      <UIDataTable
        indexable="false"
        id="messageListTable"
        value={this.getMessageList}
        headerTitle={i18n.ClaimCommon.MessageResult}
      >
        <UIColumn
          headerTitle={i18n.ClaimCommon.MessageType}
          value="oaSubType.SubTypeId"
          sortable="true"
          width="15%"
        >
          <UISelect
            io="out"
            codeTable={CodeTableAction.loadDataList(
              CodeTableCstUI.ClaimMsgType,
              null
            )}
          />
        </UIColumn>
        <UIColumn
          headerTitle={i18n.ClaimCommon.ClaimNumber}
          value="contextDisplays[0].ContextData.ClaimNo"
          sortable="true"
          width="20%"
          render={data => {
            return (
              <UILink
                value={data.contextDisplays[0].ContextData.CliamNo}
                onClick={self.openMyTask}
                width="20%"
                sortable="true"
              >
                <UIParam name="data" value={data} />
              </UILink>
            );
          }}
        />

        <UIColumn
          headerTitle={i18n.ClaimCommon.MessageGenerationDate}
          value="DueDate"
          sortable="true"
          width="15%"
        >
          <UIDateTimePicker
            placeHolder={config.DEFAULT_DATETIME_FORMATER}
            format="MM/DD/YYYY"
            io="out"
          />
        </UIColumn>

        <UIColumn
          headerTitle={i18n.ClaimCommon.Content}
          value="Description"
          sortable="true"
        />

        <UIColumn
          width="10%"
          render={data => {
            if (data.oaIntrinsicStatus.StatusId === 100007)
              return (
                <UIButton
                  value={i18n.ClaimCommon.Complete}
                  size="xs"
                  onClick={self.dismissed}
                >
                  <UIParam name="data" value={data} />
                </UIButton>
              );
            return <div />;
          }}
        />
      </UIDataTable>
    );
  },
});
export default ClaimWorkFlowMessageList;
