import alt from '../alt';
import ApprovalHistoryAction from '../action/ApprovalHistoryAction';

class ApprovalStore {
  constructor() {
    this.approvelist = {};
    this.bindListeners({
      handleApprovalHistory: [ApprovalHistoryAction.INIT_DATA],
    });
  }

  handleApprovalHistory(approvelist) {
    console.log(`=approvelist==${JSON.stringify(approvelist)}`);
    this.approvelist = approvelist;
  }
}

export default alt.createStore(ApprovalStore, ApprovalStore);
