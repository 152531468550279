export default {
  ClaimHandling: '保険金処理',
  LossDescription: '事故詳細',
  InsuredLiability: '保障内容',
  ContactType: '連絡方法',
  DateofRegistration: '請求登録日',
  CatastropheCode: '大災害コード',
  CauseofLoss: '事故原因',
  SalesChannel: '販売チャンネル',
  SalesChannelInfo: '販売チャンネル情報',
  Claim: 'クレーム',
  Claimant: '請求者',
  ClaimantInfo: '請求者情報',
  SubclaimInfo: 'サブクレーム情報',
  Subclaim: 'サブクレーム',
  TaskTransfer: 'タスク移転',
  TaskTransferM: '手動でタスク移動',
  AssignTo: 'アサイン先',
  TaskStatus: 'タスク状態',
  PolicyInfo: '契約情報',
  PolicyNumber: '証券番号',
  ProductName: '商品名',
  ProductTypeName: '产品类型名称',
  PolicyHolderName: '契約者氏名',
  PolicyHolderInfo: '契約者情報',
  PolicyBranch: '契約所属支店',
  PolicyBranchSearch: 'アフィリエーションポリシーお問い合わせ',
  ContactPerson: '連絡人',
  ContactTel: '連絡電話',
  DateOfLoss: '事故日',
  DateOfNotice: '通知日',
  ClaimBranch: 'クレーム支店',
  ClaimNumber: 'クレーム番号',
  ClaimBranchSearch: 'ペイアフィリエーション問い合わせ',
  AccidentDistrict: '事故発生地',
  AccidentLocation: '事故発生地住所',
  DamageType: '損害種類',
  DamageItem: '損害詳細項目',
  FirstOr3rdParty: '当事者あるいは第三者',
  IDCard: 'IDカード番号',
  EstimatedLoss: '損害見込額',
  Currency: '通貨',
  CoverageName: '保障名称',
  DeductibleAmount: '免責控除額',
  DeductibleRate: '免責控除率',
  Deductible: '免責控除',
  SumInsured: '保険金額',
  SILimit: '保険限度額',
  InsuredList: '被保険物明細',
  InsuredName: '被保険物名称',
  InsuredCategory: '被保険物カテゴリ',
  InsuredId: '被保険物ID',
  PolicyDetail: '契約詳細',
  EffectiveDate: '有効日',
  ExpiryDate: '満期日',
  PremiunIsPaid: '保険料払込済',
  PolicySearch: '契約検索',
  PolicyEffectiveDataFromTo: '有効契約　From/To',
  IDNumberOrBusinessRegisterNumber:
    '身分証明書番号・ビジネス登記番号',
  PolicyList: '契約リスト',
  PolicyEffectiveDate: '契約有効期間',
  PolicyExpiryDate: '契約失効日',
  Product: '商品',
  IdType: 'Id種類',
  ContactEmail: '連絡Eメール',
  CountryStateCity: '国/省/市',
  Country: '国',
  OrganzationName: '組織名',

  RejectConfirmation: '拒否確認',
  RejectReason: '拒否自由',
  Remark: '備考',

  InsuredObject: '被保険対象',
  PossibleSubrogation: '代位求償可否',
  PossibleSalvage: 'Possible 回収可否',
  DamageObject: '損害物',
  DamageSeverity: '損害レベル',
  FirstName: '氏名（名）',
  LastName: '氏名（姓）',
  Postcode: '郵便番号',
  PlaceOfInspection: '調査地',
  InspectionAddress: '調査地住所',
  Litigation: '訴訟',
  TotalLoss: '総損害額',
  DamageDescription: '損害詳細',
  Coverage: '保障',
  InitialReserve: '初期備金',
  TaskType: 'タスク種類',
  DueDate: '期限日',
  TaskDetail: 'タスク詳細',
  AssignedBy: 'アサイン者',
  Priority: '優先度',
  ClaimTask: 'クレームタスク',
  MessageType: 'メッセージ種類',
  Date: '日',
  Content: 'コンテンツ',
  Dismiss: '免訴',
  Complete: '完成',
  ClaimantName: '請求者名',
  SubclaimType: 'サブクレームタイプ',
  AssignedDate: 'アサイン日',
  Status: '状態',
  ShowUnreadMessageOnly: '未読メッセージのみ表示',
  Datefromto: '日付 from/to',
  ShowOpenClaimsOnly: 'オープンクレームのみ表示',
  NewMessage: '新メッセージ',
  MyClaims: '担当するクレーム案件',
  ShareingPool: '共有プール',
  MyTask: 'マイータスク',
  OverDueDate: '期限切れタスク',
  Registration: '請求登録',
  Settlement: '決済',
  SettlementApproval: '決済承認',
  PendingNotice: 'ペンディング通知',
  Yes: 'Yes',
  No: 'No',
  QueryCondition: '検索条件',
  ModifyClaimInfo: 'クレーム情報編集',
  ModifySublaimInfo: 'サブクレーム情報編集',
  ValidationInfo: '検証情報',

  Assignment: 'アサインメント',
  AssignmentPendingTask: 'ペンディングタスク',
  AssignmentClosedTask: 'クローズタスク',
  AssignmentNewTask: '新しいタスク',
  AssignmentReassignTask: 'リーアサイン',
  AssignmentReassign: 'リーアサイン',
  AssignmentCloseTask: 'クローズタスク',
  AssignmentAttachTo: '添付',
  AssignmentAssignTo: 'アサイン先',
  AssignmentClosedBy: 'クローズ担当者',
  AssignmentClosedDate: 'クローズ日',
  SendMessage: 'メッセージ送信',
  AssignmentApproach: 'アサインメント アプローチ',
  AccidentCode: '大災害番号.',
  AccidentName: '大災害名称',
  AccidentDesc: '詳細',
  AccidentValidFlag: '適用可否',
  AccidentDefineCode: '大災害コード定義',
  AssignmentStatus: '状態',
  ManualAssignmentApproach: '手動アサイン方式',
  AutomaticAssignmentApproach: '自動アサイン方式',
  AttachmentList: 'ドキュメントリスト',
  AttachmentPanel: 'ドキュメントアップロード',
  DocumentName: 'ドキュメント名称',
  DocumentType: 'ドキュメント種類',
  UploadDate: 'アップロード日',
  CreatorName: '創設者氏名',
  ReMark: '備考',
  SearchResult: '検索結果',

  // claimquery
  ClaimQuery: 'クレーム検索',
  ClaimOwner: 'クレーム担当者',
  LossDateSection: '事故日 from/to',
  LossStatus: '損害状態',
  HideSearchOptions: '検索条件非表示',
  ShowSearchOptions: '検索条件表示',
  TotalIncurred: '総損害',

  // Note
  NoteDate: '記入日',
  NoteBy: '記入者',
  NoteCategory: 'カテゴリ',
  NoteContent: 'コンテンツ',
  NoteSendMessageTo: 'メッセージ送信先',
  Note: 'ノーツ',
  NoticeofLoss: '事故通知',
  // 按钮
  Continue: '継続',
  Retrieve: '回復',
  Clear: 'クリア',
  Action: 'アクション',
  Edit: '編集',
  Delete: '削除',
  Save: '保存',
  Submit: '確定',
  Reject: '拒否',
  WorKOn: '処理',
  Cancel: 'キャンセル',
  Add: '追加',
  Search: '検索',
  Reset: '再設定',
  New: '新規',
  Filter: 'フィルター',
  Home: 'ホーム',
  Configuration: '定義',
  ProductConfiguration: '商品定義',
  SearchClaim: 'クレーム検索',
  NewNoiceofLoss: '新しい事故通知',
  RejectSubclaim: 'サブクレーム拒否',
  Upload: 'アップロード',
  Back: '戻る',
  SelectAsClaimant: '請求権利者選択',

  // Message
  NoPolicy: '無効な証券番号',
  WarnMessage:
    '該当クレームに対するペンティングタスクがリストされます。タスクを選択して処理してください。',
  RejectDialogConfirm: '拒否しますか？確認してください。 ',
  NoCoverageSelectedMessage: 'サブクレームの保障を指定してください。',
  NewSubclaimShouldSubmit: '先に新規サブクレームを提出してください。',

  // Approval History
  ApprovalHistory: '承認履歴',
  ApprovalDate: '承認日',
  ApprovalName: '承認者',
  ApprovalSummay: 'サーマリ',
  ApprovalDecision: '承認詳細',
  ApprovalRemark: '備考',
  Decision: '決定',
  ApprovalRemarkHolder: '備考内容を入力してください。',

  // Massive Reassignment(Task owner)
  MassiveReassignmentTaskowner: '一括リアサイン（担当者）',
  MassiveReassignment: '一括リアサイン',
  TaskOwner: '担当者',
  CreateDate: '生成日',
  ChangeTaskOwner: '担当者変更',
  ChangeTaskOwnerPage: '担当者変更',
  ChangeClaimOwner: 'クレーム担当者変更',
  ChangeClaimOwnerPage: 'クレーム担当者変更',
  NoRecSelected: '少なくても一つのレコードをリアサインしてください。',
  NoAssignToSelected: '担当者を選択してください。',
  submitSuccessfully: '処理完了しました。',
  CatastropheSaved: 'Catastrophe is created successfully',
  CatastropheCheck: 'Catastrophe Code is exist',
  // message
  SaveSuccess: '保存しました。',
  Fail: '失敗',
  ReLogin: 'please reLogin',

  ErrorMessage: {
    CLM__UNKNOWN_ERROR:
      '未知のシステムエラー、管理者に連絡してください',
  },
  ReserveHistoryBlankOption: '全部',
  I18nUtilValidate(arg) {
    return `The ${arg} is required and cannot be empty`;
  },
  ClaimHandlingTitle(claimData) {
    return `クレーム ${claimData.ClaimNo}`;
  },

  ReserveBigThenSumInsuredError: '初始准备金不能大于赔偿限额',

  NoRecordFound: '无记录',
  //= =ploicyinfo=======
  ProductInfo: 'Product Infomation',
  RiskInfo: 'Risk Infomation',
  RiskName: 'Risk Name',
  AddRiskName: 'Add Risk',
  AddRiskButton: 'ADD RISK',
  AddRiskFail: 'The Policy has new Endorsement.',
  PolicyRetrieveFail: 'Fail to  get the Policy infomatioin',
};
