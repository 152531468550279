import { MessageHelper } from 'RainbowUI';
import ClaimCommonExt from 'ClaimCommonExt';
import NewAppraisalItem from './vo/NewAppraisalItem';
import alt from '../alt';

const TriggerTaskTransformDialogAction = ClaimCommonExt.getReserveAction();
const AppraisalPropertyService = require('../service/AppraisalPropertyService');

class AppraisalPropertyAction {
  loadClaimAppraisal(subClaimId, openForm) {
    if (openForm === 'Other') {
      return AppraisalPropertyService.loadClaimAppraisal4Other(
        subClaimId
      );
    }
    return AppraisalPropertyService.loadClaimAppraisal(subClaimId);
  }

  getProperty(subClaimId) {
    return AppraisalPropertyService.getProperty(subClaimId);
  }

  saveAppraisalProperty(appraisalProperty) {
    return AppraisalPropertyService.saveAppraisalProperty(
      appraisalProperty
    );
  }

  approveAppraisalProperty(appraisalProperty) {
    const promise = AppraisalPropertyService.approveAppraisalProperty(
      appraisalProperty
    );
    promise.then(
      data => {
        if (data.Status === 'OK') {
          TriggerTaskTransformDialogAction.reserveApprovalTaskAssigned(
            'TaskComplete'
          );
        }
      },
      () => {
        MessageHelper.error(
          '',
          i18n.ClaimAppraisal.ErrorMsg.Error_0001
        );
      }
    );
  }

  submitAppraisalProperty(appraisalProperty) {
    const promise = AppraisalPropertyService.submitAppraisalProperty(
      appraisalProperty
    );
    promise.then(
      data => {
        if (data.Status === 'OK') {
          MessageHelper.success(
            i18n.ClaimAppraisal.SuccessMsg.Success_0001
          );
          TriggerTaskTransformDialogAction.reserveApprovalTaskAssigned(
            'TaskComplete'
          );
        }
      },
      () => {
        MessageHelper.error(
          '',
          i18n.ClaimAppraisal.ErrorMsg.Error_0001
        );
      }
    );
  }

  synButtonIsEnable(taskInfo, damageType) {
    const enabled = AppraisalPropertyService.synButtonIsEnable(
      taskInfo,
      damageType
    );
    return enabled;
  }

  changeAppraisalPropertyItem(event) {}

  addPropertyItem() {
    const itemToAdd = NewAppraisalItem.propertyItemToAdd;
    this.dispatch(itemToAdd);
  }

  removePropertyItem(event) {
    const removeIndex = event.getParameter('removeIndex');
    this.dispatch(removeIndex);
  }
}
export default alt.createActions(AppraisalPropertyAction);
