import {
  UICheckbox,
  UIBox,
  UISelect,
  UIDialog,
  UISmartPanelGrid,
  UIText,
  UIButton,
  MessageHelper,
} from 'RainbowUI';
import PartyAction from '../actions/PartyAction';
import PartyAccountAction from '../actions/PartyAccountAction';
import DefinedCodeTable from '../codetable/CodeTable';
import PartyConst from '../const/PartyConst';

const EditPartyAccountDialog = React.createClass({
  getInitialState() {
    return {
      partyAccount: {},
    };
  },

  onClickClose() {
    const partyAccountAction = new PartyAccountAction();
    partyAccountAction.removeMaskInfo(this.state.partyAccount);
    UIDialog.hide('AccountDialog');
    this.props.resetState();
  },

  onSave() {
    const partyAccountAction = new PartyAccountAction();
    const { partyAccount } = this.state;

    if (!partyAccount.SwiftCode) {
      MessageHelper.error(
        i18n.PartyMsg.Error.Error_0013,
        null,
        MessageHelper.POSITION_TOP_RIGHT
      );
      return;
    }

    partyAccountAction.removeMaskInfo(partyAccount);
    const { IBANNo } = partyAccount;
    const { IsPrimaryAccount } = partyAccount;
    if (partyAccount.AccountStatus == '2') {
      partyAccount.IsPrimaryAccount = 'N';
    }
    const partyAction = new PartyAction();
    const status = partyAction.checkIBANNo(IBANNo);
    if (status === 'success') {
      const result = this.props.submitHandler(partyAccount);
      if (!(PartyConst.FAIL == result)) {
        UIDialog.hide('AccountDialog');
      } else {
        partyAccount.IsPrimaryAccount = IsPrimaryAccount;
        this.setState({
          partyAccount,
        });
      }
    }
  },

  render() {
    const _self = this;
    const { partyAccount } = this.state;
    const partyAccountAction = new PartyAccountAction();
    partyAccountAction.initMaskInfo(partyAccount);
    return (
      <UIDialog
        id="AccountDialog"
        width="80%"
        title={i18n.BankAccountDetail}
        onClose={_self.onClickClose}
      >
        <UISmartPanelGrid column="3">
          <UICheckbox
            single="true"
            singleLabel={i18n.PrimaryAccount}
            model={partyAccount}
            property="IsPrimaryAccount"
            defaultValue="Y"
          />
          <UISelect
            label={i18n.Bank}
            model={partyAccount}
            property="BankName"
            required="true"
            validationGroup="UpdateAccountInfo"
            codeTable={DefinedCodeTable.getActiveBankName}
            onChange={_self.onChangeBankInfo}
          />
          <UIText
            label={i18n.BankAccount}
            model={partyAccount}
            property="AccountNoMask"
            required="true"
            validationGroup="UpdateAccountInfo"
            onChange={_self.onChangeBankAccount}
          />
          <UIText
            label={i18n.IBANNo}
            model={partyAccount}
            property="IBANNoMask"
            required="true"
            validationGroup="UpdateAccountInfo"
            toUpperLowerCase="upper"
            onChange={_self.onChangeIbanNo}
          />
          <UISelect
            label={i18n.IBANType}
            model={partyAccount}
            property="AccountType"
            codeTable={DefinedCodeTable.getIBANType}
            showBlankOption="false"
          />
          <UIText
            id="Edit_PartyAccountSwiftCode"
            label={i18n.Account.SwiftCode}
            model={partyAccount}
            property="SwiftCodeMask"
            required="true"
            validationGroup="UpdateAccountInfo"
            onChange={_self.onChangeSwiftCode}
            disabled="true"
          />
          <UISelect
            label={i18n.Status}
            codeTable={DefinedCodeTable.getPartyStatus}
            model={partyAccount}
            property="AccountStatus"
            showBlankOption="false"
          />
        </UISmartPanelGrid>
        <UIBox direction="center">
          <UIButton
            value={i18n.Save}
            onClick={_self.onSave}
            validationGroup="UpdateAccountInfo"
            causeValidation="true"
          />
          <UIButton
            value={i18n.Cancel}
            onClick={_self.onClickClose}
          />
        </UIBox>
      </UIDialog>
    );
  },

  showDialog(partyAccount) {
    const partyAccountAction = new PartyAccountAction();
    partyAccountAction.initMaskInfo(partyAccount);
    UIDialog.show('AccountDialog');
    this.setState(
      {
        partyAccount,
      },
      () => console.log('partyAccount', this.state.partyAccount)
    );
  },

  onChangeBankAccount(event) {
    const { partyAccount } = this.state;
    partyAccount.AccountNo = event.newValue;
  },

  onChangeIbanNo(event) {
    const { partyAccount } = this.state;
    partyAccount.IBANNo = event.newValue;
  },

  onChangeSwiftCode(event) {
    const { partyAccount } = this.state;
    partyAccount.SwiftCode = event.newValue;
  },

  onChangeBankInfo(event) {
    const { partyAccount } = this.state;
    partyAccount.BankName = event.newValue;
    if (!event.newValue) {
      delete partyAccount.SwiftCode;
      delete partyAccount.SwiftCodeMask;
      $('#Edit_PartyAccountSwiftCode').val('');
      return;
    }
    const partyAction = new PartyAction();
    const party = partyAction.loadPartyByPartyId(event.newValue);
    if (
      party &&
      party.PartyRoleList &&
      party.PartyRoleList.length > 0
    ) {
      for (let i = 0; i < party.PartyRoleList.length; i += 1) {
        const partyRole = party.PartyRoleList[i];
        if (
          partyRole.RoleType === 'BAKORG' &&
          partyRole.Status == 1
        ) {
          partyAccount.SwiftCode = partyRole.SwiftCode;
          partyAccount.SwiftCodeMask = partyRole.SwiftCode;
          $('#Edit_PartyAccountSwiftCode').val(partyRole.SwiftCode);
          break;
        }
      }
    }
  },
});
export default EditPartyAccountDialog;
