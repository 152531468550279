import { UIBox, UICell, UIButton, MessageHelper } from 'RainbowUI';
import BindToMixin from 'react-binding';
import PropTypes from 'prop-types';
import { ClaimTaskTransferDialog } from 'ClaimCommon';
import { ClaimCstUI } from 'ClaimCommonConstant';
import ClaimSettlement from 'ClaimSettlement';
import ClaimCommonExt from 'ClaimCommonExt';
import SettlementStore from '../../common/store/SettlementStore';

const SettlementLoadAction = ClaimSettlement.getSettlementLoadAction();

const ClaimBaseInfo = ClaimCommonExt.getClaimBaseInfo();

const RefundInfoLoad = require('./RefundInfoLoad');

const RefundTaskPage = React.createClass({
  mixins: [BindToMixin],

  propTypes: {
    taskInstanceId: PropTypes.string,
    caseId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  },

  getInitialState() {
    return { SettlementData: {}, CheckCoInsurance: {} };
  },

  componentWillMount() {
    SettlementStore.listen(this.onStoreStateChange);
    if (!_.isEmpty(this.props.taskInstanceId)) {
      SettlementLoadAction.checkCoInsurance(
        this.props.caseId,
        this.props.policyNo,
        this.props.taskInstanceId
      );
      SettlementLoadAction.loadSettlementTask(
        this.props.taskInstanceId
      );
    }
  },

  componentWillUnmount() {
    SettlementStore.unlisten(this.onStoreStateChange);
  },

  onStoreStateChange(storeState) {
    if (
      !_.isEmpty(storeState.SettlementData) ||
      storeState.CheckCoInsurance
    ) {
      this.setState({
        SettlementData: storeState.SettlementData,
        CheckCoInsurance: storeState.CheckCoInsurance,
      });
    }
  },
  reSetAmount() {
    if (this.state.SettlementData.SettlementInfo.SettlementPayee) {
      for (
        let i = 0;
        i <
        this.state.SettlementData.SettlementInfo.SettlementPayee
          .length;
        i += 1
      ) {
        const settlementPayee = this.state.SettlementData
          .SettlementInfo.SettlementPayee[i];
        settlementPayee.SettleAmount = 0;
        settlementPayee.LocalSettleAmount = 0;
        settlementPayee.ReserveSettleAmount = 0;
        if (settlementPayee.SettlementItem) {
          for (
            let j = 0;
            j < settlementPayee.SettlementItem.length;
            j += 1
          ) {
            const settlementItem = settlementPayee.SettlementItem[j];
            if (settlementItem.ReserveSign < 0) {
              if (settlementItem.OutstandingAmount > 0) {
                settlementItem.OutstandingAmount *=
                  settlementItem.ReserveSign;
              }
              if (settlementItem.SettleAmount > 0) {
                settlementItem.SettleAmount *=
                  settlementItem.ReserveSign;
              }
              if (settlementItem.LocalSettleAmount > 0) {
                settlementItem.LocalSettleAmount *=
                  settlementItem.ReserveSign;
              }
              if (settlementItem.OurShareAmount > 0) {
                settlementItem.OurShareAmount *=
                  settlementItem.ReserveSign;
              }
              if (settlementItem.ReserveSettleAmount > 0) {
                settlementItem.ReserveSettleAmount *=
                  settlementItem.ReserveSign;
              }
            }
            settlementPayee.SettleAmount +=
              settlementItem.SettleAmount;
            settlementPayee.LocalSettleAmount +=
              settlementItem.LocalSettleAmount;
            settlementPayee.ReserveSettleAmount +=
              settlementItem.ReserveSettleAmount;
          }
        }
      }
    }
  },
  onClickSave() {
    this.reSetAmount();
    const flag = SettlementLoadAction.saveSettlement(
      this.state.SettlementData
    );
    if (flag) {
      MessageHelper.success(
        '',
        i18n.ClaimSettlement.RefundSaveSuccess
      );
    }
  },

  onClickSubmit() {
    this.reSetAmount();
    if (!this.props.policyNo) {
      MessageHelper.error(
        '',
        i18n.ClaimSettlement.WithOutPolicyError
      );
      return;
    }
    const payees = this.state.SettlementData.SettlementInfo
      .SettlementPayee;
    for (let i = 0; i < payees.length; i += 1) {
      if (!payees[i].PayeeName) {
        MessageHelper.error(
          '',
          i18n.ClaimSettlement.ChoosePayeeError
        );
        return;
      }

      const items = payees[i].SettlementItem;
      if (items.length === 0) {
        MessageHelper.error(
          '',
          i18n.ClaimSettlement.RefundSubmitError
        );
        return;
      }
    }
    const result = SettlementLoadAction.submitSettlement(
      this.state.SettlementData,
      true
    );
    if (result === 'OK') {
      this.refs.ClaimTaskTransferDialog.showDialog(
        { CaseId: this.props.caseId },
        'refundSuccess'
      );
    } else if (result.Messages) {
      MessageHelper.error('', result.Messages[0].Message);
    }
  },
  updateCoInsurancePayment(event) {
    this.state.SettlementData.SettlementInfo.CoInsurancePayment = event;
  },
  render() {
    const SettlementHistory = ClaimSettlement.getSettlementHistory();
    const _self = this;
    const {
      componentId,
      claimEntity,
      caseId,
      policyNo,
      taskInstanceId,
    } = this.props;
    const {
      SettlementData: { SettlementInfo, OtherInfo },
      CheckCoInsurance,
    } = this.state;
    const ComponentId = componentId || 'Refund';
    if (_.isEmpty(taskInstanceId)) {
      return (
        <div>
          <ClaimBaseInfo claimEntity={claimEntity} />
          <SettlementHistory
            caseId={caseId}
            taskCode={ClaimCstUI.CLAIM_TASK_CODE.Refund}
            componentId={ComponentId}
            canInit={false}
            policyNo={policyNo}
          />
        </div>
      );
    }

    return (
      <div>
        <ClaimBaseInfo claimEntity={claimEntity} />
        <div>
          <RefundInfoLoad
            settlementInfo={SettlementInfo}
            otherInfo={OtherInfo}
            readOnly={false}
            isApprove={false}
            componentId={ComponentId}
            updateCoInsurancePayment={this.updateCoInsurancePayment}
            CoInsurance={CheckCoInsurance}
            caseId={_self.props.caseId}
          />
        </div>
        <div>
          <ClaimTaskTransferDialog
            ref="ClaimTaskTransferDialog"
            componentId={ComponentId}
          />
          <UIBox direction="left">
            <UIButton
              value={i18n.ClaimCommon.Save}
              onClick={_self.onClickSave}
            />
            <UIButton
              value={i18n.ClaimCommon.Submit}
              onClick={_self.onClickSubmit}
              validationGroup="submit_validate"
              causeValidation="true"
            />
          </UIBox>
        </div>
        <div>
          <UICell style={{ textAlign: 'left' }} />

          <UICell
            style={{ textAlign: 'left', paddingTop: '30px' }}
            width="13"
          >
            <SettlementHistory
              caseId={_self.props.caseId}
              taskCode={ClaimCstUI.CLAIM_TASK_CODE.Refund}
              componentId={ComponentId}
              canInit
              policyNo={_self.props.policyNo}
            />
          </UICell>
        </div>
      </div>
    );
  },
});
export default RefundTaskPage;
