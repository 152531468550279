import { UIUpdatePanel } from 'RainbowUI';
import PtyIntegrationSearchInfo from './PtyIntegrationSearchInfo';
import PartyConst from '../../../party/const/PartyConst';
/**
 * Parameters:
 *  customerChose - function: handler return party
 *  createClicked - function: handler the event when user click create
 */
const PtyIntegrationSearchPanel = React.createClass({
  getInitialState() {
    let outConditions = {};
    if (this.props.conditions) {
      outConditions = this.props.conditions;
    }
    return {
      definedCondition: { PartyType: PartyConst.PARTY_TYPE_IND },
      conditions: outConditions,
      fuzzyConditions: {},
      customerList: [],
      orCondtions: {},
      currentCustomer: {},
      customer: {},
      onCustomerReturn: this.props.customerChose,
      RoleName: this.props.RoleName,
      isVisibled: this.props.isVisibled,
      enableChangePartyCategory: this.props.enableChangePartyCategory,
      roleType: this.props.roleType,
      claimFlag: this.props.claimFlag,
      isSearchRole: this.props.isSearchRole,
	   isClaimant: 'false',
    };
  },
  createNewCustomer(partyType) {
    this.props.createClicked(partyType);
  },
  createHandler() {
    this.props.createNewCustomer();
  },
  render() {
    const {
      definedCondition,
      roleType,
      claimFlag,
      conditions,
      fuzzyConditions,
      orCondtions,
      customerList,
      currentCustomer,
      RoleName,
      isVisibled,
      enableChangePartyCategory,
      isSearchRole,
	  isClaimant,
    } = this.state;
    return (
      <div>
        <UIUpdatePanel id="updatePtyIntegerationSearchCriteria">
          <PtyIntegrationSearchInfo
            create={this.createNewCustomer}
            definedCondition={definedCondition}
            roleType={roleType}
            claimFlag={claimFlag}
            conditions={conditions}
            fuzzyConditions={fuzzyConditions}
            orCondtions={orCondtions}
            customerList={customerList}
            currentCustomer={currentCustomer}
            isQueryPolicy={false}
            faxIndividual
            RoleName={RoleName}
            createHandler={this.createHandler}
            isVisibled={isVisibled}
            enableChangePartyCategory={enableChangePartyCategory}
            isSearchRole={isSearchRole}
			isClaimant={true}
          />
        </UIUpdatePanel>
      </div>
    );
  },
});
export default PtyIntegrationSearchPanel;
