import {
  UICheckbox,
  UIParam,
  UISelect,
  UILink,
  UIColumn,
  UIDataTable,
  UIPanel,
  UIText,
} from 'RainbowUI';
import EditPartyAccountDialogReadOnly from './EditPartyAccountDialogReadOnly';
import DefinedCodeTable from '../../../codetable/CodeTable';
import PartyAccountAction from '../../../actions/PartyAccountAction';

const _ = require('underscore');

const PartyAccountInfoReadOnly = React.createClass({
  getInitialState() {
    const { party } = this.props;
    let { PartyAccountList } = party;
    if (!PartyAccountList) {
      PartyAccountList = [];
    }
    _.forEach(PartyAccountList, (tempPartyAccount, index) => {
      const partyAccount = tempPartyAccount;
      partyAccount.DefinedIndex = index + 1;
    });
    const totalNum = PartyAccountList.length;

    return {
      party: this.props.party,
      PartyAccountList,
      TotalNum: totalNum,
    };
  },
  queryHandler(clickEvent) {
    let partyAccount = {};
    if (!_.isEmpty(clickEvent.paramJson)) {
      partyAccount = clickEvent.paramJson[0].value;
    }
    this.refs.editPartyAccountDialogReadOnlyReadOnly.showDialog(
      partyAccount
    );
  },
  handlePrimaryAccount(data) {
    const { PartyAccountList } = this.state;
    _.each(PartyAccountList, tempPartyAccount => {
      const partyAccount = tempPartyAccount;
      if (partyAccount.IsPrimaryAccount === 'Y') {
        partyAccount.IsPrimaryAccount = 'N';
        return partyAccount;
      }
    });
    const pk = data.component.props.model['@pk'];
    if (data.newValue === 'Y') {
      _.each(PartyAccountList, tempPartyAccount => {
        const partyAccount = tempPartyAccount;
        if (partyAccount['@pk'] == pk) {
          partyAccount.IsPrimaryAccount = 'Y';
        }
      });
    }
    this.setState({
      PartyAccountList,
    });
  },
  render() {
    const _self = this;
    const partyAccountAction = new PartyAccountAction();
    let { PartyAccountList } = this.state;
    PartyAccountList = _.sortBy(PartyAccountList, item => {
      return item.InsertTime;
    });
    return (
      <UIPanel panelTitle={i18n.BankAccount}>
        <UIDataTable
          id="bankAccountInfo"
          value={PartyAccountList}
          indexable="false"
        >
          <UIColumn
            headerTitle={i18n.PrimaryAccount}
            render={data => {
              return (
                <div>
                  <UICheckbox
                    single="true"
                    model={data}
                    property="IsPrimaryAccount"
                    onChange={_self.handlePrimaryAccount}
                    disabled="true"
                  />
                </div>
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.Bank}
            render={data => {
              return (
                <UISelect
                  model={data}
                  property="BankName"
                  codeTable={DefinedCodeTable.getBankName}
                  io="out"
                />
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.BankAccount}
            render={data => {
              const maskBankAccount = partyAccountAction.getMaskValue(
                'PartyAccount',
                'AccountNo',
                data.AccountNo
              );
              return (
                <div>
                  <UILink
                    value={maskBankAccount}
                    onClick={_self.queryHandler}
                  >
                    <UIParam name="data" value={data} />
                  </UILink>
                </div>
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.IBANNo}
            render={data => {
              const maskIbanNo = partyAccountAction.getMaskValue(
                'PartyAccount',
                'IBANNo',
                data.IBANNo
              );
              return <UIText io="out" value={maskIbanNo} />;
            }}
          />
          <UIColumn
            headerTitle={i18n.Account.SwiftCode}
            render={data => {
              const maskSwiftCode = partyAccountAction.getMaskValue(
                'PartyAccount',
                'SwiftCode',
                data.SwiftCode
              );
              return <UIText io="out" value={maskSwiftCode} />;
            }}
          />
          <UIColumn
            headerTitle={i18n.Status}
            render={data => {
              return (
                <UISelect
                  io="out"
                  model={data}
                  property="AccountStatus"
                  codeTable={DefinedCodeTable.getPartyStatus}
                />
              );
            }}
          />
        </UIDataTable>
        <EditPartyAccountDialogReadOnly ref="editPartyAccountDialogReadOnlyReadOnly" />
      </UIPanel>
    );
  },
});
export default PartyAccountInfoReadOnly;
