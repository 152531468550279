import React from 'react';
import {
  UIPanel,
  UIBox,
  UIDataTable,
  UIColumn,
  DataContext,
  UILink,
  UIParam,
  UIText,
  MessageHelper,
} from 'RainbowUI';
import PartyAction from '../actions/PartyAction';
import EditParticipationInfo from './EditParticipationInfo';
import SearchForParticipation from './searchForRelation/SearchForParticipation';

const Participation = React.createClass({
  getInitialState() {
    let { party } = this.props;
    const partyAction = new PartyAction();
    if (DataContext.get('Party')) {
      party = DataContext.get('Party');
    }
    let { PartyParticipationList } = party;
    if (!PartyParticipationList) {
      PartyParticipationList = [];
    }
    _.forEach(
      PartyParticipationList,
      (temppartyParticipation, index) => {
        const partyParticipation = temppartyParticipation;
        partyParticipation.DefinedIndex = index + 1;
        const result = partyAction.loadPartyByPartyId(
          partyParticipation.ParticipationPartyId
        );
        partyParticipation.ParticipationPartyInfo = result;
        partyParticipation.CompanyName = result.PartyName;
        partyParticipation.CountryDom = result.CountryDom;
        partyParticipation.OrganizationIdNumber =
          result.OrganizationIdNumber;
      }
    );
    const totalNum = PartyParticipationList.length;
    return {
      PartyParticipationList,
      TotalNum: totalNum,
      party,
      readOnly: false,
    };
  },

  componentWillMount() { },

  componentDidMount() { },

  render() {
    const self = this;
    return (
      <div>
        {self.renderRoleInfo()}
        <EditParticipationInfo
          ref="editPartyParticipationDialog"
          partyParticipationList={self.state.PartyParticipationList}
          readOnly={self.state.readOnly}
          searchPartyName={self.searchPartyName}
          submitHandler={self.savePartyParticipation}
        />
        <SearchForParticipation
          ref="searchIndSearchParticipationDialog"
          submitHandler={this.setPartyParticipation}
        />
      </div>
    );
  },

  renderRoleInfo() {
    const self = this;
    return (
      <UIPanel panelTitle={i18n.RoleInfo}>
        <UIBox direction="right">
          <UILink
            direction="right"
            icon="fa fa-plus"
            size="lg"
            tooltip={i18n.Add}
            iconClass="add_iconbtn"
            onClick={self.createParticipationHandler}
          />
          <UILink
            direction="right"
            icon="fa fa-pencil"
            size="lg"
            tooltip={i18n.Edit}
            iconClass="edit_iconbtn"
            onClick={self.editParticipationHandler}
          />
        </UIBox>
        <UIDataTable
          id="Participation"
          value={self.state.PartyParticipationList}
        >
          <UIColumn selectionMode="single" />
          {/* <UIColumn headerTitle="Company Name"  value="CompanyName" /> */}
          <UIColumn
            headerTitle={i18n.CompanyName}
            render={data => {
              return (
                <div>
                  <UILink
                    value={data.CompanyName}
                    onClick={self.viewHandler}
                  >
                    <UIParam name="data" value={data} />
                  </UILink>
                </div>
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.CompanyRegNumber}
            value="OrganizationIdNumber"
          />
          <UIColumn
            headerTitle={i18n.CountryDom}
            value="CountryDom"
          />
          <UIColumn
            headerTitle={i18n.SharePercentage1}
            render={data => {
              if (data.SharePercentage) {
                const SharePercentageData = `${data.SharePercentage}%`;
                return (
                  <div>
                    <UIText
                      value={SharePercentageData}
                      disabled="true"
                    />
                  </div>
                );
              }
            }}
          />
          {/* <UIColumn headerTitle="Share Percentage" value="SharePercentage"/> */}
        </UIDataTable>
      </UIPanel>
    );
  },

  searchPartyName(partyParticipation, readOnly) {
    this.refs.searchIndSearchParticipationDialog.showDialog(
      partyParticipation,
      readOnly
    );
  },

  setPartyParticipation(partyParticipation, partySearchType) {
    this.refs.editPartyParticipationDialog.showDialog(
      partyParticipation,
      false,
      partySearchType
    );
  },

  createParticipationHandler() {
    const definedIndex = this.state.TotalNum + 1;
    const createPartyParticipation = {
      '@type': 'PartyParticipation-PartyParticipation',
      DefinedIndex: definedIndex,
    };
    this.refs.editPartyParticipationDialog.showDialog(
      createPartyParticipation,
      false
    );
  },

  editParticipationHandler() {
    const selectData = UIDataTable.getSelectedRecord('Participation');
    if (!selectData.length) {
      MessageHelper.warning(
        i18n.PartyMsg.WarnMsg.Warn_0005,
        i18n.PartyMsg.WarnMsg.Warn_0003,
        MessageHelper.POSITION_TOP_RIGHT
      );
      return;
    }
    const participation = selectData[0];
    this.refs.editPartyParticipationDialog.showDialog(
      participation,
      false
    );
  },

  viewHandler(clickEvent) {
    let partyParticipation = {};
    if (!_.isEmpty(clickEvent.paramJson)) {
      partyParticipation = clickEvent.paramJson[0].value;
    }
    this.refs.editPartyParticipationDialog.showDialog(
      partyParticipation,
      true
    );
  },

  savePartyParticipation(partyParticipation) {
    const { PartyHistoryList } = this.state.party;
    if (partyParticipation['@pk']) {
      const newPartyHistory = {
        '@type': 'PartyHistory-PartyHistory',
        BusinessObjectId: 75158352,
        HistoryType: 21,
      };
      PartyHistoryList.push(newPartyHistory);
    } else {
      const newPartyHistory = {
        '@type': 'PartyHistory-PartyHistory',
        BusinessObjectId: 75158352,
        HistoryType: 20,
      };
      PartyHistoryList.push(newPartyHistory);
    }
    const { PartyParticipationList } = this.state;
    const definedIndex = partyParticipation.DefinedIndex;
    for (let idx = 0; idx < PartyParticipationList.length; idx += 1) {
      if (PartyParticipationList[idx].DefinedIndex == definedIndex) {
        PartyParticipationList.splice(idx, 1);
        break;
      }
    }
    PartyParticipationList.push(partyParticipation);
    _.forEach(PartyParticipationList, tempdata => {
      const data = tempdata;
      delete data.ParticipationPartyInfo;
      delete data.CompanyName;
      delete data.CountryDom;
      delete data.OrganizationIdNumber;
    });
    const { party } = this.state;
    party.PartyParticipationList = PartyParticipationList;
    const partyAction = new PartyAction();
    const copyParty = partyAction.loadPartyByPartyId(party.PartyId);
    party.PartyAccountList = copyParty.PartyAccountList;
    party.PartyContactList = copyParty.PartyContactList;
    party.AddressVoList = copyParty.AddressVoList;
    let result = this.props.submitInfoHandler(party, 10);
    if (!(result === 'fail')) {
      _.forEach(
        result.PartyParticipationList,
        (temppartyParticipation, index) => {
          const partyParticipation = temppartyParticipation;
          partyParticipation.DefinedIndex = index + 1;
        }
      );
      const resultList = result.PartyParticipationList;
      this.setState({
        party: result,
        PartyParticipationList: resultList,
        TotalNum: PartyParticipationList.length + 1,
      });
    }
    if (result === 'fail') {
      const partyAction = new PartyAction();
      result = partyAction.loadPartyByPartyId(party.PartyId);
      _.forEach(
        result.PartyParticipationList,
        (temppartyParticipation, index) => {
          const partyParticipation = temppartyParticipation;
          partyParticipation.DefinedIndex = index + 1;
        }
      );
      DataContext.put('Party', result);
      this.setState({
        party: result,
        PartyParticipationList: result.PartyParticipationList,
        TotalNum: result.PartyParticipationList.length + 1,
      });
      return 'fail';
    }
    return 'success';
  },
});
export default Participation;
