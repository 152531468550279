import {
  UIBox,
  UIRadio,
  UINumber,
  UITextarea,
  UISelect,
  UIButton,
  UIText,
  UISmartPanelGrid,
} from 'RainbowUI';
import React from 'react';
import BindToMixin from 'react-binding';
import {
  CodeTableAction,
  CodeTableCstUI,
  ClaimCstUI,
} from 'ClaimCommonConstant';
import DamageItemContact from './DamageItemContact';

const NoticeOfLossAction = require('../action/NoticeOfLossAction');

const DamageItemDetail = React.createClass({
  mixins: [BindToMixin],

  getInitialState() {
    return {
      damage: {},
      damageContact: {},
      showStatus: 'Add',
      codeTableClaimant: null,
    };
  },
  showDetail(data, damageContact) {
    const { damageList } = this.props.parentComponent.state;
    const policyEntity = this.props.parentComponent.props.parentComponent.refs.AccidentInfo.getPolicyEntity();
    this.setState({
      damage: data,
      damageContact,
      showStatus: 'showDetail',
      codeTableClaimant: NoticeOfLossAction.getCodeTableClaimant(
        damageList,
        policyEntity
      ),
    });

    if (
      $("input[name='damageParty']").get(0) &&
      data.DamageParty === 'FP'
    ) {
      $("input[name='damageParty']").get(0).checked = true;
    } else if (
      $("input[name='damageParty']").get(1) &&
      data.DamageParty === 'TP'
    ) {
      $("input[name='damageParty']").get(1).checked = true;
    }
    if (
      damageContact.ContactType == ClaimCstUI.PARTY_TYPE_INDIVIDUAL
    ) {
      if ($("input[name='damageOrgOrInd']").get(0)) {
        $("input[name='damageOrgOrInd']").get(0).checked = true;
      }
    } else if (
      damageContact.ContactType == ClaimCstUI.PARTY_TYPE_ORGANIZATION
    ) {
      if ($("input[name='damageOrgOrInd']").get(1)) {
        $("input[name='damageOrgOrInd']").get(1).checked = true;
      }
    }
  },

  onSave() {
    const { damageContact } = this.state;
    const { damage } = this.state;
    if (
      damageContact.ContactType == ClaimCstUI.PARTY_TYPE_INDIVIDUAL
    ) {
      if (!damageContact.ContactName) {
        damageContact.ContactName = `${damageContact.ContactFirstName} ${damageContact.ContactLastName}`;
      }
    }

    if (
      damage.ContactRefId == -1 ||
      (isNaN(damage.ContactRefId) &&
        damage.ContactRefId.substr(0, 1) === 'H')
    ) {
      damage.ContactRefId = damage.isAdd
        ? this.props.parentComponent.state.damageList.length
        : damage.dataIndex;
    }

    this.props.parentComponent.addDamageItem(
      this.state.damage,
      damageContact
    );
    this.setState({ showStatus: 'Add' });
  },

  onCancel() {
    this.setState({ showStatus: 'Add' });
  },

  addDamage() {
    const damage = {
      isAdd: true,
      ContactType: ClaimCstUI.PARTY_TYPE_INDIVIDUAL,
    };
    const { damageList } = this.props.parentComponent.state;
    const policyEntity = this.props.parentComponent.props.parentComponent.refs.AccidentInfo.getPolicyEntity();
    this.setState({
      damage,
      damageContact: damage,
      showStatus: 'showDetail',
      codeTableClaimant: NoticeOfLossAction.getCodeTableClaimant(
        damageList,
        policyEntity
      ),
    });
    this.props.parentComponent.openAddItemDetail();
  },

  getCodeTableClaimantByFlag() {
    const { copyPolicyFlag } = this.props.parentComponent.state;
    if (!copyPolicyFlag) {
      return this.state.codeTableClaimant;
    }
    return this.props.ClaimantCodeTable;
  },

  chanageClaimant(event) {
    const { damage } = this.state;
    const policyEntity = this.props.parentComponent.props.parentComponent.refs.AccidentInfo.getPolicyEntity();
    if (!event.newValue) {
      this.setState({
        damageContact: {
          isAdd: true,
          ContactType: ClaimCstUI.PARTY_TYPE_INDIVIDUAL,
        },
      });
    } else if (event.newValue == -1) {
      this.refs.DamageItemContact.refs.ClaimPartyRightPanel_Ref.showDialog();
      this.refs.DamageItemContact.refs.ClaimPartyRightPanel_Ref.showPageChange(
        'QueryCommonParty',
        {},
        'claimant'
      );
      this.setState({
        damageContact: {
          ContactType: ClaimCstUI.PARTY_TYPE_INDIVIDUAL,
          dataIndex: damage.dataIndex,
          ContactRefId: event.newValue,
          ContactRole: ClaimCstUI.PARTY_ROLE.Claimant,
        },
      });
    } else if (event.newValue.substr(0, 1) === 'H') {
      const damageContact = {
        dataIndex: damage.dataIndex,
        ContactRefId: event.newValue,
        CommonPartyId: event.newValue.substr(1),
      };
      this.setState({
        damageContact: NoticeOfLossAction.copyDamageContactFromPartyList(
          damageContact,
          policyEntity,
          event.newValue.substr(1)
        ),
      });
    } else {
      damage.ContactRefId = event.newValue;
      this.setState({
        damage,
        damageContact: this.props.parentComponent.state.damageList[
          event.newValue
        ],
      });
    }

    if (
      this.state.damageContact.ContactType ==
      ClaimCstUI.PARTY_TYPE_INDIVIDUAL
    ) {
      $("input[name='damageOrgOrInd']").get(0).checked = true;
    } else if (
      this.state.damageContact.ContactType ==
      ClaimCstUI.PARTY_TYPE_ORGANIZATION
    ) {
      $("input[name='damageOrgOrInd']").get(1).checked = true;
    }
  },

  chanageContactType() {
    $('#damageIdType')
      .find('option')
      .eq(0)
      .attr('selected', 'selected');
  },

  getIDTypeCodeTable() {
    if (this.state.damageContact) {
      if (
        this.state.damageContact.ContactType ==
        ClaimCstUI.PARTY_TYPE_INDIVIDUAL
      ) {
        return CodeTableAction.loadDataList(
          CodeTableCstUI.ClaimPartyCertiType,
          null
        );
      }
      if (
        this.state.damageContact.ContactType ==
        ClaimCstUI.PARTY_TYPE_ORGANIZATION
      ) {
        return CodeTableAction.loadDataList(
          CodeTableCstUI.ClaimPartyOrgType,
          null
        );
      }
    }
  },

  getEnabled() {
    let isPartyCopy = 'true';
    if (this.state.damageContact.CommonPartyId) {
      isPartyCopy = 'false';
    }
    return isPartyCopy;
  },
  reSetDamageContact(damageContact) {
    const param = {};

    param.ContactType = damageContact.ClmPtyType;

    console.info('reSetDamageContact', damageContact);
    if (
      damageContact.ClmPtyType == ClaimCstUI.PARTY_TYPE_ORGANIZATION
    ) {
      param.OrganizationIdType = damageContact.OrganizationIdType;
      param.OrganizationIdNumber = damageContact.OrganizationIdNumber;
    } else {
      param.IdType = damageContact.IdType;
      param.IdNumber = damageContact.IdNumber;
    }
    param.ContactName = damageContact.PartyName;

    param.CommonPartyId = damageContact.PartyId;
    param.ContactFirstName = damageContact.FirstName;
    param.ContactLastName = damageContact.LastName;
    if (
      damageContact.PartyContactList &&
      damageContact.PartyContactList.length > 0
    ) {
      const { selectedContactIndex } = damageContact;
      if (selectedContactIndex !== -1 && selectedContactIndex) {
        param.ContactTelephone =
          damageContact.PartyContactList[
            selectedContactIndex
          ].HandPhone;
        param.ContactEmail =
          damageContact.PartyContactList[selectedContactIndex].Email;
      }
    }
    if (
      damageContact.AddressVoList &&
      damageContact.AddressVoList.length > 0
    ) {
      const { selectedAddressIndex } = damageContact;
      if (selectedAddressIndex !== -1 && selectedAddressIndex) {
        param.ContactCountryCode =
          damageContact.AddressVoList[
            selectedAddressIndex
          ].Country;
        param.ContactAddress =
          damageContact.AddressVoList[
            selectedAddressIndex
          ].AddressLine1;
      }
    }
    param.ContactRole = ClaimCstUI.PARTY_ROLE.Claimant;
    this.setState({ damageContact: param });
  },

  render() {
    const { showStatus } = this.state;
    if (showStatus === 'Add') {
      /* empty */
    } else if (showStatus === 'showDetail') {
      return (
        <div>
          <UISmartPanelGrid column="3">
            <UIRadio
              id="damageParty"
              name="damageParty"
              label={i18n.ClaimCommon.FirstOr3rdParty}
              codeTable={CodeTableAction.loadDataList(
                CodeTableCstUI.ClaimDamagePartyCode,
                null
              )}
              valueLink={this.bindToState('damage', 'DamageParty')}
              required="true"
              validationGroup="damageDetail"
            />

            <UISelect
              id="damageType"
              label={i18n.ClaimCommon.DamageType}
              codeTable={CodeTableAction.loadDataList(
                CodeTableCstUI.ClaimDamageTypeCode,
                null
              )}
              valueLink={this.bindToState('damage', 'DamageType')}
              required="true"
              validationGroup="damageDetail"
            />

            <UIText
              id="damageItem"
              label={i18n.ClaimCommon.DamageItem}
              autoFocus="true"
              valueLink={this.bindToState('damage', 'DamageItem')}
              required="true"
              validationGroup="damageDetail"
            />

            {this.getComponetEstimatedLoss()}

            <UITextarea
              id="damageDescription"
              label={i18n.ClaimNotice.DamageDescription}
              valueLink={this.bindToState(
                'damage',
                'DamageDescription'
              )}
              colspan="2"
            />

            <UISelect
              id="claimant"
              label={i18n.ClaimCommon.Claimant}
              required="true"
              validationGroup="damageDetail"
              codeTable={this.getCodeTableClaimantByFlag}
              onChange={this.chanageClaimant}
              valueLink={this.bindToState('damage', 'ContactRefId')}
            />
            <UIRadio
              id="damageOrgOrInd"
              name="damageOrgOrInd"
              codeTable={CodeTableAction.loadDataList(
                CodeTableCstUI.ClaimPartyType,
                null
              )}
              onChange={this.chanageContactType}
              valueLink={this.bindToState(
                'damageContact',
                'ContactType'
              )}
              required="true"
              validationGroup="damageDetail"
              colspan="2"
              enabled={this.getEnabled}
            />

            {this.getComponetPartyName(this.getEnabled())}

            <UIText
              id="damageContactNumber"
              label={i18n.ClaimCommon.ContactTel}
              valueLink={this.bindToState(
                'damageContact',
                'ContactTelephone'
              )}
              enabled={this.getEnabled}
            />

            <UIText
              id="damageContactEmail"
              label={i18n.ClaimCommon.ContactEmail}
              valueLink={this.bindToState(
                'damageContact',
                'ContactEmail'
              )}
              enabled={this.getEnabled}
            />

            <UISelect
              id="damageCountryStateCity"
              label={i18n.ClaimCommon.CountryStateCity}
              valueLink={this.bindToState(
                'damageContact',
                'ContactCountryCode'
              )}
              codeTable={CodeTableAction.loadDataList(
                CodeTableCstUI.ClaimPartyCountry,
                null
              )}
              enabled={this.getEnabled}
            />

            <UITextarea
              id="damageAddressDetail"
              label={i18n.ClaimNotice.DamageAddressDetail}
              valueLink={this.bindToState(
                'damageContact',
                'ContactAddress'
              )}
              colspan="2"
              enabled={this.getEnabled}
            />
          </UISmartPanelGrid>
          <UIBox direction="left">
            <UIButton
              value={i18n.ClaimCommon.Save}
              onClick={this.onSave}
              causeValidation="true"
              validationGroup="damageDetail"
            />
            <UIButton
              value={i18n.ClaimCommon.Cancel}
              onClick={this.onCancel}
            />
          </UIBox>
          <DamageItemContact
            ref="DamageItemContact"
            parentFunction={this.reSetDamageContact}
          />
        </div>
      );
    }
    return null;
  },

  getComponetPartyName(isPartyCopy) {
    const { damageContact } = this.state;
    if (
      damageContact &&
      damageContact.ContactType == ClaimCstUI.PARTY_TYPE_ORGANIZATION
    ) {
      const orgType = CodeTableAction.loadDataList(
        CodeTableCstUI.ClaimPartyOrgType,
        null
      );
      return (
        <UISmartPanelGrid colspan="2" column="2">
          <UIText
            id="damageOrgName"
            label={i18n.ClaimCommon.OrganzationName}
            enabled={isPartyCopy}
            valueLink={this.bindToState(
              'damageContact',
              'ContactName'
            )}
            colspan="2"
            required="true"
            validationGroup="damageDetail"
          />
          <UISelect
            id="OrganizationIdType"
            label={i18n.ClaimParty.IDType}
            valueLink={this.bindToState(
              'damageContact',
              'OrganizationIdType'
            )}
            validationGroup="damageDetail"
            required="true"
            codeTable={orgType}
            enabled={isPartyCopy}
          />
          <UIText
            id="OrganizationIdNumber"
            label={i18n.ClaimParty.IDNumber}
            valueLink={this.bindToState(
              'damageContact',
              'OrganizationIdNumber'
            )}
            validationGroup="damageDetail"
            required="true"
            enabled={isPartyCopy}
          />
        </UISmartPanelGrid>
      );
    }
    return (
      <UISmartPanelGrid colspan="2" column="2">
        <UIText
          id="damageFirstName"
          label={i18n.ClaimCommon.FirstName}
          enabled={isPartyCopy}
          valueLink={this.bindToState(
            'damageContact',
            'ContactFirstName'
          )}
          required="true"
          validationGroup="damageDetail"
        />
        <UIText
          id="damageLastName"
          label={i18n.ClaimCommon.LastName}
          enabled={isPartyCopy}
          valueLink={this.bindToState(
            'damageContact',
            'ContactLastName'
          )}
          required="true"
          validationGroup="damageDetail"
        />
        <UISelect
          id="damageIdType"
          label={i18n.ClaimCommon.IdType}
          codeTable={this.getIDTypeCodeTable}
          valueLink={this.bindToState('damageContact', 'IdType')}
          enabled={this.getEnabled}
          required="true"
          validationGroup="damageDetail"
        />

        <UIText
          id="damageIdCard"
          label={i18n.ClaimCommon.IDCard}
          valueLink={this.bindToState('damageContact', 'IdNumber')}
          enabled={this.getEnabled}
          required="true"
          validationGroup="damageDetail"
        />
      </UISmartPanelGrid>
    );
  },

  getComponetEstimatedLoss() {
    return (
      <UISmartPanelGrid column="2" widthAllocation="5,7">
        <UISelect
          id="currency"
          label={i18n.ClaimCommon.EstimatedLoss}
          codeTable={CodeTableAction.loadDataList(
            CodeTableCstUI.ClaimCurrencyCode,
            null
          )}
          valueLink={this.bindToState('damage', 'CurrencyCode')}
        />
        <UINumber
          id="NUMBER_CODE_damageEstimatedLoss"
          valueLink={this.bindToState('damage', 'EstimatedLoss')}
        />
      </UISmartPanelGrid>
    );
  },
});
export default DamageItemDetail;
