import {
  AjaxUtil,
  MessageHelper,
  DataContext,
  UIDataTable,
} from 'RainbowUI';
import alt from '../../alt';
import Url from '../../url/Url';

class ClaimWorkFlowSharePoolAction {
  getSharePoolList(pageindex, pagesize, tmpclaimTaskInstance) {
    const claimTaskInstance = tmpclaimTaskInstance;
    const pageNo = (pageindex - 1) / pagesize + 1;

    let localUrl = Url.ClaimWorkflowGetSharingPoolList;
    let result = null;
    claimTaskInstance.PageSize = pagesize;
    claimTaskInstance.PageIndex = pageNo;
    delete claimTaskInstance.flag;
    if (env.IS_MOCK) {
      localUrl = 'workflow/getTaskList';
    }
    AjaxUtil.doPost(localUrl, claimTaskInstance, {
      async: false,
      done(datas) {
        const data = datas;
        if (!data.Model.model.Results) {
          data.Model.model.Results = [];
          const SolrDocs = {};
          data.Model.model.Results.push(SolrDocs);
        }

        if (data.Status === 'OK') {
          result = data;
        } else {
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
        }
      },
      fail(data) {
        if (data.status == 401 || data.status == 403) {
          MessageHelper.error(i18n.ClaimCommon.ReLogin);
        } else {
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
        }
      },
    });

    const resultObj = {
      count: result.Model.model.Total,
      result: result.Model.model.Results[0].SolrDocs,
      user: result.Model.user,
    };
    return resultObj;
  }

  getSharePoolDetail(event) {
    const data = event.getParameter('data');
    window.location.hash = `#/handling/tasks?caseId=${data.CaseId}`;
  }

  onRowSelect(event) {
    const data = event.getSelectRecord('workflowsharepoollist');
    DataContext.put('SharePoolTask', data);
  }

  updateSelectedTask() {
    const selecteds = UIDataTable.getSelectedRecord(
      'workflowsharepoollist'
    );

    const taskInstances = [];
    for (let i = 0; i < selecteds.length; i += 1) {
      const taskInstance = {};
      taskInstance.TaskInstanceId = selecteds[i].entity_id;
      taskInstance.TaskCode = selecteds[i].TaskDefinitionKey;
      taskInstance.DueDate = selecteds[i].DueDate;
      taskInstance.Priority = selecteds[i].TaskPrioriry;
      taskInstance.CaseId = selecteds[i].CaseId;
      taskInstance.AssignTo = selecteds[i].TaskAssignee;
      taskInstance.TaskDescription = selecteds[i].TaskDescription;
      taskInstances.push(taskInstance);
    }
    let result = null;
    AjaxUtil.doPost(Url.ClaimWorkflowUpdateTasks, taskInstances, {
      async: false,
      done(data) {
        if (data.Status === 'OK') {
          result = 'OK';

          MessageHelper.info(i18n.ClaimCommon.ClaimTaskSU);
        } else {
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMsg.ClaimTaskFailT
          );
        }
      },
      fail() {
        MessageHelper.error(
          i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
        );
      },
    });

    return result;
  }
}
export default alt.createActions(ClaimWorkFlowSharePoolAction);
