export default {
  Appraisal: '估损',
  LossItems: '损失项目',
  LossItem: '损失项目',
  Description: '描述',
  ClaimAmount: '损失金额',
  DeductionAmount: '扣减额',
  NetAmount: '净损失金额',
  NewLossItem: '新增损失金额',
  TotalAmount: '总金额',
  RemarkToLossItems: '损失金额备注',
  RemarkToAppraisal: '估损备注',
  PropertyInfo: '物损信息',

  VehicleInfo: 'Vehicle Info',
  PlateNo: 'Plate No.',
  Maker: 'Maker',
  Model: 'Model',
  ModelYear: 'Model Year',
  ModuleYear: 'Module Year',
  MakeYear: 'Make Year',
  VehicleType: 'Vehicle Type',
  VehicleColor: 'Vehicle Color',
  VIN: 'VIN',
  EngineNumber: 'Engine Number',
  BodyShopName: 'Bodyshop Name',
  BodyShopContact: 'Bodyshop Contact',
  BodyShopTel: 'Bodyshop Tel',
  BodyShopAddress: 'Bodyshop Address',
  BodyShopArrTime: 'Bodyshop Arrival Time',
  DamagePart: 'Damage Part',
  LaborCost: 'Labor Cost',
  PartsName: 'Parts Name',
  PartsDescription: 'Parts Description',
  WorkType: 'Work Type',
  DamageType: 'Damage Type',
  Delete: 'Delete',
  TotalLaborCost: 'Total Labor Cost',
  ApprovalHistory: 'Approval History',
  TransactionDate: 'Transaction Date',
  ApprovedBy: 'Operated By',
  TotalNetAmount: 'Total Net Amount',
  ApprovalDecision: 'Approval Decision',
  Remark: 'Remark',

  TotalCost: 'Total Cost',
  SparePartCost: 'Spare Part Cost',
  SalvageValue: 'SalvageValue',
  NetAmt: 'Net Amount',
  RemarktoAppraisal: 'Remark to Appraisal',
  SyncInfo: 'SYNCHRONIZE INFO',
  Save: 'SAVE',
  SubmitTotal: 'SUBMIT',
  Back: 'BACK',

  Decision: 'Decision',

  CompanyReferencePrice: 'Company Reference Price',
  BodyShopPrice: 'Body Shop Price',
  ProviderPrice: 'Provider Price',
  TotalSparePartCost: 'Total Spare Part Cost : ',
  SeqNo: 'Seq No.',
  OperatedBy: 'Operated By',
  NumberOfLossItems: 'Number Of Loss Items',
  AppraisalHistory: 'Appraisal History',
  AppraisalHistoryDetail: 'Appraisal History Detail',

  VictimInfo: 'Victim Info',
  VictimName: 'Victim Name',
  Gender: 'Gender',
  Age: 'Age',
  HomeAddress: 'Home Address',
  ContactTel: 'Contact Tel',
  InjuryType: 'Injury Type',
  DisabilityGrade: 'Disability Grade',
  Treatment: 'Treatment',
  Hospital: 'Hospital',
  DaysInHospital: 'Days in Hospital',
  InjuryDescription: 'Injury Description',
  RemarkVictim: 'Remark to Victim',
  ItemDesc: 'Description',

  EnterPositiveError: 'Enter the amount can not be positive!',
  CantGreaterAmount:
    'The absolute value of salvage amount cannot be greater than the total amount of Labor Cost and Spare Part Cost',
};
