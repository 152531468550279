import {
  UIPanel,
  UISmartPanelGrid,
  UIText,
  UIBox,
  UIButton,
  UIDateTimePicker,
  UILink,
  UISelect,
  UpdateContext,
  MessageHelper,
  UIUpdatePanel,
} from 'RainbowUI';
import BindToMixin from 'react-binding';
import {
  PtyIntegrationGruopId,
  CodeTableTextTwoLines,
  PtyIntegrationSearchDialog,
  PartyConst,
} from 'EU00PartyCommonUI';
import CodeTableCstUI from '../../constant/CodeTableCstUI';
import CodeTableAction from '../../action/CodeTableAction';
import SolrService from '../../service/SolrService';

const NoticeOfLossAction = require('../../../../../notice/module/notice/common/action/NoticeOfLossAction');

const ComplexQueryCriteria = React.createClass({
  mixins: [BindToMixin],

  getInitialState() {
    const user = JSON.parse(sessionStorage.getItem('user'));
    const producerDisabled = false;
    const producerSearchable = true;
    const producerCode = '';
    // if (user.ProducerCode) {
    //   producerCode = user.ProducerCode;
    //   if (user.IsHaveProducerRole && user.IsHaveEmployeeRole) {
    //     producerCode = '';
    //   }
    // }
    // if (user.IsHaveProducerRole) {
    //   if (!user.IsHaveEmployeeRole) {
    //     producerDisabled = true;
    //   }
    // } else if (!user.IsHaveEmployeeRole) {
    //   producerDisabled = true;
    //   producerSearchable = false;
    // }
    return {
      Conditions: { AgentCode: producerCode },
      FuzzyConditions: {},
      BetweenConditions: {},
      OrConditions: {},
      ProducerDisabled: producerDisabled,
      ProducerSearchable: producerSearchable,
    };
  },

  updateState() {
    this.props.parentComponet.storeChangeStatus('Simple');
  },

  queryClaim() {
    let lastDays = $('#qLastDaysReview').val();
    const BetweenConditions = {};
    const OrConditions = {};
    const { FuzzyConditions } = this.state;
    if (lastDays) {
      const daysTime = lastDays * 1000 * 60 * 60 * 24;
      const ddTime = new ServerDate().getTime();
      const beTime = ddTime - daysTime;
      const dd = new Date(beTime);
      const y = dd.getFullYear();
      let m = dd.getMonth() + 1;
      let d = dd.getDate();
      if (m < 10) m = `0${m}`;
      if (d < 10) d = `0${d}`;
      lastDays = `${y}-${m}-${d}T23:59:59`;
      BetweenConditions.LastReviewDate_to = lastDays;
    }
    const conditions = this.state.Conditions;
    let condition = {};
    const user = CodeTableAction.renderUser();
    if (this.state.OrConditions.RefNo) {
      OrConditions.PlateNo = this.state.OrConditions.RefNo;
      OrConditions.VesselNames = this.state.OrConditions.RefNo;
    }
    if (this.state.OrConditions.CustomerTelephone) {
      OrConditions.CustomerTelephone = this.state.OrConditions.CustomerTelephone;
    }
    // if (user.DirectUser !== 'Y') {
    //   FuzzyConditions.PermitOrgId = `,${user.OrgId},`;
    // }

    if (this.state.OrConditions.GroupId) {
      const searchConditions = {
        GroupId: this.state.OrConditions.GroupId,
      };
      const searchCondition = {
        Conditions: searchConditions,
        PageNo: 1,
        PageSize: 9999,
        Module: 'Party',
      };
      let result = '';
      const partyIdList = [];
      SolrService.queryEntitySolr(searchCondition).then(data => {
        if (data.Total === 0) {
          result = null;
        } else {
          result = data.Results[0].SolrDocs;
        }
      });
      if (result) {
        _.each(result, solrDoc => {
          if (solrDoc.PartyId) {
            partyIdList.push(solrDoc.PartyId);
          }
        });
      }
      if (partyIdList.length === 0) {
        partyIdList.push(0);
      }
      conditions.PolicyholderId = partyIdList;
    } else if (!this.state.OrConditions.GroupId) {
      delete conditions.PolicyholderId;
    }
    if (!this.state.ProducerSearchable) {
      conditions.AgentCode = '0';
    }
    condition = {
      Conditions: conditions,
      FuzzyConditions,
      OrConditions,
      BetweenConditions,
    };

    this.props.parentComponet.childStateChange(condition);
  },
  validateDateOfDueDateFromTo(event) {
    const { Conditions } = this.state;
    if (Conditions.AccidentTime_from > Conditions.AccidentTime_to) {
      MessageHelper.warning(
        i18n.ClaimCommon.WarnMsg.DateToCannotEarlierThanDateFrom,
        null,
        MessageHelper.POSITION_TOP_RIGHT
      );
      if (event.component.componentId === 'qLossDateFrom') {
        Conditions.AccidentTime_from = null;
      } else {
        Conditions.AccidentTime_to = null;
      }

      UpdateContext.forceUpdate('fresh1');
      UpdateContext.forceUpdate('fresh2');
      this.setState({ Conditions });
    } else if (event.component.componentId === 'qLossDateTo') {
      Conditions.AccidentTime_to = Conditions.AccidentTime_to.replace(
        '00:00:00',
        '23:59:59'
      );
      this.setState({ Conditions });
    }
  },
  validateLastReview(event) {
    const reg = /[0-9]*/;
    const s = reg.exec(event.newValue);
    if (s) {
      $('#qLastDaysReview').val(s[0]);
    } else {
      $('#qLastDaysReview').val('');
    }
  },
  clear() {
    $("input[name='IdForPartyGroupSearch']").val(null);
    $("input[name='IdForPartyGroupSearch_value']").val(null);
    let producerCode = '';
    if (this.state.ProducerDisabled) {
      const user = JSON.parse(sessionStorage.getItem('user'));
      if (user.ProducerCode) {
        producerCode = user.ProducerCode;
      }
    }
    this.setState({
      Conditions: { AgentCode: producerCode },
      FuzzyConditions: {},
      BetweenConditions: {},
      OrConditions: {},
    });
    this.props.clearResult();
  },
  getParentComponent() {
    return this;
  },
  render() {
    const self = this;
    const { ProducerDisabled } = this.state;
    const producerDisabled = ProducerDisabled;
    const producerPanel = producerDisabled ? (
      <UIUpdatePanel id="producerUpdatePanel">
        <CodeTableTextTwoLines
          label={i18n.Producer}
          disabled="true"
          valueLink={this.bindToState('Conditions', 'AgentCode')}
          codeTable={CodeTableAction.getSalesChannelCodeTable}
          condition="CHANNEL_CODE"
        />
      </UIUpdatePanel>
    ) : (
        <UIUpdatePanel id="producerUpdatePanel">
          <CodeTableTextTwoLines
            label={i18n.Producer}
            onIconClick={this.onShowSearchPopUp.bind(this)}
            valueLink={this.bindToState('Conditions', 'AgentCode')}
            codeTable={CodeTableAction.getSalesChannelCodeTable}
            condition="CHANNEL_CODE"
          />
        </UIUpdatePanel>
      );
    return (
      <UIPanel panelTitle={i18n.ClaimCommon.ClaimQuery}>
        <UISmartPanelGrid column="2" widthAllocation="4,8">
          <UIText
            id="qClaimNumber"
            label={i18n.ClaimCommon.ClaimNumber}
            valueLink={this.bindToState('FuzzyConditions', 'ClaimNo')}
          />

          <UIText
            id="qPolicyNumber"
            label={i18n.ClaimCommon.PolicyNumber}
            valueLink={this.bindToState(
              'FuzzyConditions',
              'PolicyNo'
            )}
          />

          <UIText
            id="qClaimantName"
            label={i18n.ClaimCommon.ClaimantName}
            valueLink={this.bindToState(
              'FuzzyConditions',
              'ClaimantNames'
            )}
          />

          <UIText
            id="qPolicyholderName"
            label={i18n.ClaimCommon.PolicyHolderName}
            valueLink={this.bindToState(
              'FuzzyConditions',
              'PolicyholderName'
            )}
          />

          <UIText
            id="qPolicyholderIdNumber"
            label={i18n.ClaimCommon.PolicyHolderIDNumber}
            valueLink={this.bindToState(
              'Conditions',
              'PolicyHolderIdNumber'
            )}
          />

          <UIText
            id="qPolicyholderTelephone"
            label={i18n.ClaimCommon.PolicyHolderTelNo}
            valueLink={this.bindToState(
              'OrConditions',
              'CustomerTelephone'
            )}
          />

          <PtyIntegrationGruopId
            onCallBack={this.getGroupIdResult}
            onChangeGroupId={this.onChangeGroupId}
          />
          {producerPanel}

          <UISelect
            label={i18n.ClaimCommon.ProductName}
            valueLink={this.bindToState('Conditions', 'ProductCode')}
            codeTable={CodeTableAction.loadDataList(
              CodeTableCstUI.ClaimProductCode,
              null
            )}
          />
          <UIText
            id="qVinNoOrPlateNo"
            label={i18n.ClaimCommon.VinNoOrPlateNo}
            valueLink={this.bindToState('OrConditions', 'RefNo')}
            placeHolder={i18n.ClaimCommon.PlateNoVesselNo}
          />

          {}
          <UISelect
            id="qClaimOwner"
            label={i18n.ClaimCommon.ClaimOwner}
            valueLink={this.bindToState(
              'FuzzyConditions',
              'OwnerNames'
            )}
            codeTable={CodeTableAction.loadDataList(
              CodeTableCstUI.ClaimUser,
              null
            )}
          />
          {}
          <UISelect
            id="qClaimStatus"
            label={i18n.ClaimCommon.ClaimStatus}
            valueLink={this.bindToState('Conditions', 'StatusCode')}
            codeTable={CodeTableAction.loadDataListAsync(
              CodeTableCstUI.ClaimStatus,
              'qClaimStatus'
            )}
          />
          <UIUpdatePanel id="fresh1">
            <UIDateTimePicker
              id="qLossDateFrom"
              format="MM/DD/YYYY"
              label={i18n.ClaimCommon.LossDateSectionFrom}
              valueLink={this.bindToState(
                'Conditions',
                'AccidentTime_from'
              )}
              onChange={this.validateDateOfDueDateFromTo}
            />
          </UIUpdatePanel>
          <UIUpdatePanel id="fresh2">
            <UIDateTimePicker
              id="qLossDateTo"
              format="MM/DD/YYYY"
              label={i18n.ClaimCommon.LossDateSectionTo}
              valueLink={this.bindToState(
                'Conditions',
                'AccidentTime_to'
              )}
              onChange={this.validateDateOfDueDateFromTo}
            />
          </UIUpdatePanel>
          <UISelect
            id="qPolicyBranch"
            label={i18n.ClaimCommon.PolicyBranch}
            valueLink={this.bindToState('Conditions', 'PolicyBranch')}
            codeTable={NoticeOfLossAction.getBranchCodetable()}
          />
          <UIText
            id="qLastDaysReview"
            label={i18n.ClaimCommon.LastDaysReview}
            valueLink={this.bindToState(
              'BetweenConditions',
              'LastReviewDate_to'
            )}
            onChange={this.validateLastReview}
          />
        </UISmartPanelGrid>
        <UISmartPanelGrid column="2" widthAllocation="4,8">
          <UIBox direction="left">
            <UIButton
              value={i18n.ClaimCommon.Search}
              onClick={self.queryClaim}
            />
            <UIButton
              value={i18n.ClaimCommon.Reset}
              onClick={self.clear}
            />
          </UIBox>
          <UIBox direction="right">
            <UILink
              value={i18n.ClaimCommon.HideSearchOptions}
              onClick={self.updateState}
            />
          </UIBox>
        </UISmartPanelGrid>
        <PtyIntegrationSearchDialog
          ref="PartySearchDialogForProducer_Ref"
          id="partySearchDialogForProducer"
          isSearch
          getSearchResult={this.getProducer.bind(this)}
          isVisibled={false}
          title={i18n.SearchProducer}
          RoleName={PartyConst.PARTY_ROLE_NAME_PRODUCER}
        />
      </UIPanel>
    );
  },

  getGroupIdResult(groupInfo) {
    const orConditions = this.state.OrConditions;
    orConditions.GroupId = groupInfo.GroupId;
  },

  onChangeGroupId(groupInfo) {
    const orConditions = this.state.OrConditions;
    if (!groupInfo) {
      orConditions.GroupId = $(
        "input[name='IdForPartyGroupSearch']"
      ).val();
    } else {
      orConditions.GroupId = groupInfo.GroupId;
    }
  },

  onShowSearchPopUp() {
    this.refs.PartySearchDialogForProducer_Ref.show();
  },

  getProducer(party) {
    const conditions = this.state.Conditions;
    if (party.PartyRoleList) {
      _.each(party.PartyRoleList, role => {
        if (
          PartyConst.PARTY_ROLE_CODE_IND_PRODUCER == role.RoleType ||
          PartyConst.PARTY_ROLE_CODE_ORG_PRODUCER == role.RoleType
        ) {
          conditions.AgentCode = role.ProducerCode;
        }
      });
    }
    UpdateContext.forceUpdate('producerUpdatePanel');
  },
});
export default ComplexQueryCriteria;
