import {
  UISelect,
  UICell,
  UIButton,
  CodeTable,
  UITree,
  UIParam,
  UIText,
} from 'RainbowUI';
import React from 'react';
import PropTypes from 'prop-types';
import BindToMixin from 'react-binding';

const CoverageTreeAction = require('../../action/CoverageTreeAction');
const CoverageTreeStore = require('../../store/CoverageTreeStore');

const CoverageTree = React.createClass({
  mixins: [BindToMixin],

  propTypes: {
    taskInstanceId: PropTypes.string.isRequired,
    caseId: PropTypes.string.isRequired,
  },

  getInitialState() {
    return { CoverageTreeData: {}, SelectedLength: 0 };
  },
  componentWillMount() {
    const { coverageTree } = this.props;

    CoverageTreeStore.listen(this.onStoreStateChange);
    if (!_.isEmpty(this.props.taskInstanceId)) {
      CoverageTreeAction.loadCoverageTree(coverageTree);
    }
  },
  componentWillUnmount() {
    CoverageTreeStore.unlisten(this.onStoreStateChange);
  },
  onStoreStateChange(storeState) {
    if (!_.isEmpty(storeState.CoverageTreeData)) {
      this.setState({
        CoverageTreeData: storeState.CoverageTreeData,
        SelectedLength: storeState.SelectedLength,
      });
    }
  },

  getSelectCode() {
    const coverageTree =
      this.bindToState('CoverageTreeData', 'CoverageTree').value ||
      [];

    const seleCodeTableList = [];
    seleCodeTableList.push({ id: 0, text: 'Please select' });
    for (let i = 1; i < coverageTree.length; i += 1) {
      seleCodeTableList.push({ id: i, text: `${i}Selected` });
    }
    const seleCodeTable = new CodeTable(seleCodeTableList);

    return seleCodeTable;
  },

  handleChooseCoverage(event) {
    CoverageTreeAction.chooseCoverage(event);
    const checkedList = this.bindToState(
      'CoverageTreeData',
      'CheckedCoverage'
    ).value;

    this.props.handleParentChooseCoverage(checkedList);
  },

  render() {
    const { selectCoverageName, taskInstanceId } = this.props;
    if (_.isEmpty(taskInstanceId)) {
      return <div />;
    }
    const suffixId = `coverageTree_${taskInstanceId}_`;
    //      var otherInfo=this.bindToState("CoverageTreeData","OtherInfo");
    //
    //      var coverageTree=otherInfo.value.CoverageTree;
    const coverageTree =
      this.bindToState('CoverageTreeData', 'CoverageTree').value ||
      [];

    return (
      <div>
        <div>
          <UICell width="2" style={{ textAlign: 'left' }}>
            <UIText
              widthAllocation="11,1"
              label={selectCoverageName}
              io="out"
            />
          </UICell>
          <UICell
            width="8"
            style={{ textAlign: 'left', marginLeft: '-5%' }}
          >
            <div
              id={`${suffixId}coverageValueDiv`}
              className="coverageValue"
              style={{ display: '' }}
            >
              <UISelect
                id={`${suffixId}coverage`}
                onFocus={CoverageTreeAction.displayCoverageTree}
                required="true"
                codeTable={this.getSelectCode()}
                value={
                  this.bindToState(
                    'CoverageTreeData',
                    'SelectedLength'
                  ).value == 0
                    ? ''
                    : this.bindToState(
                        'CoverageTreeData',
                        'SelectedLength'
                      ).value
                }
                label=""
                widthAllocation="1,11"
              />
            </div>
            <div
              id={`${suffixId}coverageTreeDiv`}
              className="coverageTree"
              style={{
                display: 'none',
                position: 'absolute',
                backgroundColor: '#fff',
                zIndex: '9',
                padding: '15px',
                border: 'solid 1px #e5e5e5',
                marginLeft: '5%',
              }}
            >
              <UITree
                id={`${suffixId}coverageTree`}
                searchable="true"
                checkable="true"
                dataSource={coverageTree}
              />
              <br />
              <UIButton
                id={`${suffixId}chooseCoverageBtn`}
                value={i18n.ClaimCommon.Submit}
                onClick={this.handleChooseCoverage}
              >
                <UIParam
                  name="treeId"
                  value={`${suffixId}coverageTree`}
                />
                <UIParam
                  name="taskInstanceId"
                  value={taskInstanceId}
                />
              </UIButton>
            </div>
          </UICell>
        </div>
      </div>
    );
  },
});
export default CoverageTree;
