import {
  UIPanel,
  UICell,
  UIUpdatePanel,
  UITextSelect,
  UIBox,
  UIButton,
  UISmartPanelGrid,
} from 'RainbowUI';
import { CodeTableAction, CodeTableCstUI } from 'ClaimCommonConstant';
import DefaultReserveAction from '../action/DefaultReserveAction';
import DefaultReserveStore from '../store/DefaultReserveStore';

export default class DefaultReserveCriteria extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      coverageFilters: null,
      subClaimTypeFilters: null,
    };
  }

  render() {
    const { subClaimTypeFilters, coverageFilters } = this.state;
    return (
      <UIUpdatePanel id="defaultReserveCriteriaPanel">
        <UIPanel headerable="false">
          <UISmartPanelGrid column="2" widthAllocation="4,8">
            <UITextSelect
              id="textSelect01"
              label={i18n.ClaimProduct.Coverage}
              value="#{defaultReserve.CoverageCode}"
              codeTable={CodeTableAction.loadDataList(
                CodeTableCstUI.ClaimCoverageCodeName,
                coverageFilters
              )}
            />
            <UITextSelect
              id="textSelect02"
              label={i18n.ClaimCommon.SubclaimType}
              value="#{defaultReserve.SubclaimTypeCode}"
              codeTable={CodeTableAction.loadDataList(
                CodeTableCstUI.SubclaimType,
                { PRODUCT_LINE_CODE: subClaimTypeFilters }
              )}
            />
            <UITextSelect
              id="textSelect03"
              label={i18n.ClaimProduct.ReserveType}
              value="#{defaultReserve.ReserveTypeCode}"
              codeTable={CodeTableAction.loadDataList(
                CodeTableCstUI.ClaimReserveType,
                null
              )}
            />
            <UICell>
              <UIBox direction="left">
                <UIButton
                  value={i18n.ClaimProduct.Search}
                  onClick={DefaultReserveAction.searchChange}
                />
              </UIBox>
            </UICell>
          </UISmartPanelGrid>
        </UIPanel>
      </UIUpdatePanel>
    );
  }

  componentWillMount() {
    DefaultReserveAction.init();
  }

  componentDidMount() {
    DefaultReserveStore.listen(() => {
      const coverageFilters = DefaultReserveAction.getCoverageFilterList(
        DefaultReserveStore.state
      );
      const subClaimTypeFilters =
        DefaultReserveStore.state.productLineCode;
      this.setState({
        coverageFilters,
        subClaimTypeFilters,
      });
      this.forceUpdate();
    });
  }
}
