import { MessageHelper } from 'RainbowUI';
import {
  ApprovalInfo,
  ApprovalHistory,
  ClaimTaskTransferDialog,
} from 'ClaimCommon';
import { ClaimCstUI } from 'ClaimCommonConstant';
import BindToMixin from 'react-binding';
import PropTypes from 'prop-types';
import ClaimSettlement from 'ClaimSettlement';
import ClaimCommonExt from 'ClaimCommonExt';
import SettlementApproveStore from '../store/SettlementApproveCancelStore';

const SettlementReadOnly = require('./SettlementReadOnly');
const SettlementApproveAction = require('../action/SettlementApproveAction');

const SettlementValidationAction = ClaimSettlement.getSettlementValidationAction();

const ClaimBaseInfo = ClaimCommonExt.getClaimBaseInfo();

const SettlementApprove = React.createClass({
  mixins: [BindToMixin],

  propTypes: {
    taskInstanceId: PropTypes.string.isRequired,
    caseId: PropTypes.string.isRequired,
  },

  getInitialState() {
    return {
      approveInfo: {
        BusinessId: null,
        TaskInstanceId: null,
        CaseId: null,
        Remark: '',
        DecisionCode: null,
      },
    };
  },
  onStoreStateChange(storeState) {
    if (!_.isEmpty(storeState.approveInfo)) {
      this.setState({ approveInfo: storeState.approveInfo });
    }
  },
  componentWillMount() {
    SettlementApproveStore.listen(this.onStoreStateChange);
    SettlementApproveAction.init(this.props.taskInstanceId);
  },
  componentWillUnmount() {
    SettlementApproveStore.unlisten(this.onStoreStateChange);
  },
  render() {
    const SettlementHistory = ClaimSettlement.getSettlementHistory();
    const _self = this;
    const {
      claimEntity,
      caseId,
      policyNo,
      taskInstanceId,
    } = this.props;
    const settlementId = _self.state.approveInfo.BusinessId;
    const CommonConfirmDialog = ClaimCommonExt.getCommonConfirmDialog();
    if (
      settlementId === null ||
      settlementId === undefined ||
      settlementId === ''
    ) {
      return (
        <div>
          <ClaimBaseInfo claimEntity={claimEntity} />
          <SettlementHistory
            caseId={caseId}
            taskCode={ClaimCstUI.CLAIM_TASK_CODE.SettlementApproval}
            canInit={false}
            policyNo={policyNo}
          />
        </div>
      );
    }

    return (
      <div>
        <ClaimBaseInfo claimEntity={claimEntity} />
        <ClaimTaskTransferDialog
          ref="ClaimTaskTransferDialog"
          componentId="set_app"
        />
        <SettlementReadOnly settlementId={settlementId} isApprove />
        <ApprovalInfo
          decision={this.bindToState('approveInfo', 'DecisionCode')}
          remark={this.bindToState('approveInfo', 'Remark')}
          onClick={_self.onClickSubmit}
        />
        <ApprovalHistory processObjectId={settlementId} />
        <SettlementHistory
          caseId={caseId}
          taskCode={ClaimCstUI.CLAIM_TASK_CODE.SettlementApproval}
          canInit
          policyNo={policyNo}
        />

        <CommonConfirmDialog
          ref="confirmDialog_ref"
          objectId={`_settlementTaskApprovalPage${taskInstanceId}`}
          onConfirm={this.submitSettlementApprovalTask}
          onCancel={this.cancel}
        />
      </div>
    );
  },
  onClickSubmit() {
    if (this.validate()) {
      return;
    }
    this.submitSettlementApprovalTask();
  },

  submitSettlementApprovalTask() {
    const result = SettlementApproveAction.submit(
      this.state.approveInfo
    );
    if (result) {
      MessageHelper.success(
        '',
        i18n.ClaimSettlement.SettlementApprovalSubmitSuccess
      );
      this.refs.ClaimTaskTransferDialog.showDialog({
        CaseId: this.props.caseId,
      });
    }
  },

  validate() {
    if (
      this.state.approveInfo.DecisionCode ==
      ClaimCstUI.BooleanConstant.FALSE
    ) {
      return false;
    }
    const result = SettlementValidationAction.validateSettlementApproval(
      this.state.approveInfo
    );
    if (
      result &&
      result.Status == ClaimCstUI.REST_RESTPONSE_STATUS_WARN
    ) {
      this.refs.confirmDialog_ref.showDialog(
        i18n.ClaimSettlement.SettlementApproval,
        result.Messages
      );
      return true;
    }
    if (
      result &&
      result.Status == ClaimCstUI.REST_RESTPONSE_STATUS_BLOCK
    ) {
      MessageHelper.error('', result.Messages[0].Message);
      return true;
    }
    return false;
  },
  cancel() {},
});
export default SettlementApprove;
