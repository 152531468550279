import {
  UITabItem,
  UITab,
  UIBox,
  UICell,
  DataContext,
  UIButton,
} from 'RainbowUI';
import PartyOrgBasicInfo from './PartyOrgBasicInfoReadOnly';
import PartyIndBasicInfo from './PartyIndBasicInfoReadOnly';
import PartyVenBasicInfo from './PartyVenBasicInfoReadOnly';
import RoleInfoReadOnly from './RoleInfoReadOnly';
import PartyAddressInfoReadOnly from './PartyAddressInfoReadOnly';
import PartyContactInfoReadOnly from './PartyContactInfoReadOnly';
import PartyAccountInfoReadOnly from './PartyAccountInfoReadOnly';
import RelationShipInfoReadOnly from './RelationShipInfoReadOnly';
import PartyAction from '../../../actions/PartyAction';
import PartyHistory from '../../PartyHistory';
import PartyConst from '../../../const/PartyConst';
import PartyIndAddInfoReadOnly from './PartyIndAddInfoReadOnly';
import PartyDocument from '../../dms/PartyDocument';
import PartyGDPRReadOnly from './PartyGDPRReadOnly';
import PartyShareholderInfoReadOnly from './PartyShareholderInfoReadOnly';
import ParticipationInfoReadOnly from './ParticipationInfoReadOnly';

const PartyInfoTabReadOnly = React.createClass({
  getInitialState() {
    return {
      party: {},
    };
  },

  componentWillMount() {
    const { partyId } = this.props;
    const partyAction = new PartyAction();
    const party = partyAction.loadPartyByPartyId(partyId);
    this.setState({
      party,
    });
    DataContext.put('DataAccessRequestCategory', {});
    let { PartyGDPR } = party;
    if (!PartyGDPR) {
      PartyGDPR = {
        '@type': 'PartyGDPR-PartyGDPR',
        BusinessObjectId: 75698989,
      };
      DataContext.put('PartyGDPR', PartyGDPR);
    } else {
      DataContext.put('PartyGDPR', PartyGDPR);
    }
    let { PartyGDPRDataAccess } = PartyGDPR;
    if (!PartyGDPRDataAccess) {
      PartyGDPRDataAccess = {
        '@type': 'PartyGDPRDataAccess-PartyGDPRDataAccess',
        BusinessObjectId: 75699032,
      };
      DataContext.put('PartyGDPRDataAccess', PartyGDPRDataAccess);
    } else {
      DataContext.put('PartyGDPRDataAccess', PartyGDPRDataAccess);
    }
  },

  exitHandler() {
    window.close();
  },

  render() {
    const { party } = this.state;
    let BasicInfo;
    let AdditionalInfo;
    let ShareholderInfo;
    let ParticipationInfo;
    if (party.PartyType == PartyConst.PARTY_TYPE_IND) {
      BasicInfo = this.renderIndBasicInfo();
      AdditionalInfo = this.renderIndAddInfo();
    }
    if (party.PartyType == PartyConst.PARTY_TYPE_ORG) {
      BasicInfo = this.renderOrgBasicInfo();
      AdditionalInfo = this.renderOrgAddInfo();
      ShareholderInfo = this.renderShareholderInfo();
    }
    if (party.PartyType == PartyConst.PARTY_TYPE_VEN) {
      BasicInfo = this.renderVenBasicInfo();
      AdditionalInfo = this.renderOrgAddInfo();
      ShareholderInfo = this.renderShareholderInfo();
      ParticipationInfo = this.renderParticipationInfo();
    }
    return (
      <div>
        <UICell width="14">
          {BasicInfo}
          <UITab activeIndex="1">
            <UITabItem title={i18n.RoleDetail}>
              <RoleInfoReadOnly party={party} />
            </UITabItem>
            <UITabItem title={i18n.AddressDetail}>
              <PartyAddressInfoReadOnly party={party} />
            </UITabItem>
            <UITabItem title={i18n.BankAccount}>
              <PartyAccountInfoReadOnly party={party} />
            </UITabItem>
            <UITabItem title={i18n.RelationShip}>
              <RelationShipInfoReadOnly party={party} />
            </UITabItem>
            {AdditionalInfo}
            <UITabItem title={i18n.GDPR}>
              <PartyGDPRReadOnly party={party} />
            </UITabItem>
            <UITabItem title={i18n.DMS}>
              <PartyDocument readOnly domainObject={party} />
            </UITabItem>
            {ShareholderInfo}
            <UITabItem title={i18n.History}>
              <PartyHistory party={party} />
            </UITabItem>
            {ParticipationInfo}
          </UITab>
        </UICell>

        <UIBox direction="center">
          <UIButton value={i18n.Exit} onClick={this.exitHandler} />
        </UIBox>
      </div>
    );
  },
  renderIndBasicInfo() {
    const { party } = this.state;
    return <PartyIndBasicInfo party={party} />;
  },
  renderOrgBasicInfo() {
    const { party } = this.state;
    return <PartyOrgBasicInfo party={party} />;
  },
  renderVenBasicInfo() {
    const { party } = this.state;
    return <PartyVenBasicInfo party={party} />;
  },
  renderIndAddInfo() {
    const { party } = this.state;
    return (
      <UITabItem title={i18n.AdditionalId}>
        <PartyIndAddInfoReadOnly
          party={party}
          partyAdditionalList={party.PartyAdditionalList}
        />
      </UITabItem>
    );
  },
  renderOrgAddInfo() {
    const { party } = this.state;
    return (
      <UITabItem title={i18n.ContactInfo}>
        <PartyContactInfoReadOnly
          partyContactList={party.PartyContactList}
          party={party}
        />
      </UITabItem>
    );
  },
  renderShareholderInfo() {
    const { party } = this.state;
    return (
      <UITabItem title={i18n.ShareholderInfo}>
        <PartyShareholderInfoReadOnly party={party} />
      </UITabItem>
    );
  },
  renderParticipationInfo() {
    const { party } = this.state;
    return (
      <UITabItem title="Participation">
        <ParticipationInfoReadOnly party={party} />
      </UITabItem>
    );
  },
});
export default PartyInfoTabReadOnly;
