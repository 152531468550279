import { MessageHelper, AjaxUtil, CodeTable } from 'RainbowUI';
import AppraisalVehicleService from '../service/AppraisalVehicleService';
import NewAppraisalItem from './vo/NewAppraisalItem';
import alt from '../alt';

class AppraisalVehicleAction {
  addLaborCostItem() {
    const itemToAdd = NewAppraisalItem.laborCostItemToAdd;

    this.dispatch(itemToAdd);
  }

  removeLaborCostItem(event) {
    const removeIndex = event.getParameter('removeIndex');
    this.dispatch(removeIndex);
  }

  addSpareCostItem() {
    const itemToAdd = NewAppraisalItem.spareCostItemToAdd;

    this.dispatch(itemToAdd);
  }

  removeSpareCostItem(event) {
    const removeIndex = event.getParameter('removeIndex');
    this.dispatch(removeIndex);
  }

  getVehicle(subClaimId) {
    return AppraisalVehicleService.getVehicle(subClaimId);
  }

  getDotInfo(tmpdomainObject) {
    const domainObject = tmpdomainObject;
    const _self = this;
    if (!domainObject.PlateNo) {
      MessageHelper.error(i18n.ClaimAppraisal.ErrorMsg.Error_0002);
    } else {
      let result;
      const url = `${
        env.serverUrl
        }/pa/eu00/dot/getVehicleByVrm/${encodeURI(
          domainObject.PlateNo
        ).toUpperCase()}`;
      AjaxUtil.doGet(url, null, {
        async: false,
        done(response) {
          result = response;
          if (result) {
            domainObject.ModelYear = result.RegistrationDate;
            domainObject.Vin = result.Vin;
            domainObject.Model = result.ModelDescription;
            const table4 = [
              {
                id: result.ModelDescription,
                text: `${result.ModelDescription}`,
              },
            ];
            window.modelCodeTable = new CodeTable(table4);
            domainObject.Maker = result.MakeCode;
            const table2 = [
              { id: result.MakeCode, text: `${result.MakeCodeDesc}` },
            ];
            window.vehicleMakeCodeTable = new CodeTable(table2);
            const dateyear = new Date(result.ManufactureDate);
            const year = dateyear.getFullYear();
            domainObject.MakerYear = year;
          } else {
            domainObject.Vin = null;
            domainObject.Maker = null;
            domainObject.Model = null;
            domainObject.ModelYear = null;
            domainObject.MakerYear = null;
            const table4 = [];
            window.modelCodeTable = new CodeTable(table4);
            domainObject.VehBodyTypeCd = null;
            const table1 = [];
            window.bodyTypeCodeTable = new CodeTable(table1);

            domainObject.VehTypeCd = null;
            const table3 = [];
            window.vehicleCategoryCodeTable = new CodeTable(table3);
            domainObject.DotReceive = null;
          }
        },
        fail(error) {
          if (error.responseJSON) {
            MessageHelper.error(
              error.responseJSON.Messages['0'].Message
            );
          } else {
            MessageHelper.error(
              i18n.ClaimAppraisal.ErrorMsg.Error_0003
            );
          }
          if (_self.retrieveVehicleFromDOT()) {
            _self.retrieveVehicleFromDOT(domainObject);
          }
        },
      });
    }
    return domainObject;
  }

  getModelCodeTable(maker) {
    let result = [];
    if (!maker) return new CodeTable(result);
    const url = `${env.serverUrl}public/codetable/data/condition/name/Model`;
    const data = { Make: maker };
    AjaxUtil.doPost(url, data, {
      async: false,
      done(response) {
        result = response.map(code => {
          const tmp = {};
          for (const obj in code) {
            if (obj === 'Id') tmp.id = code[obj];
            else if (obj === 'Description') tmp.text = code[obj];
            else tmp[obj] = code[obj];
          }
          return tmp;
        });
      },
    });
    return new CodeTable(result);
  }

  validatePlateNo(domainObject) {
    if (domainObject.IsDot !== 'Y') {
      return true;
    }
    if (domainObject.PlateNo) {
      const plateno = domainObject.PlateNo;
      if (plateno.length > 6 || plateno.length < 4) {
        MessageHelper.error(i18n.ClaimAppraisal.ErrorMsg.Error_0004);
        return false;
      }
      const prefix = plateno.substring(0, plateno.length - 3);
      const prefix1 = prefix.substring(0, 1);
      let prefix2 = prefix.substring(1, 2);
      let prefix3 = prefix.substring(2, 3);
      if (prefix2 === '') {
        prefix2 = 'X';
      }
      if (prefix3 === '') {
        prefix3 = 'X';
      }
      const sufix = plateno.substring(
        plateno.length - 3,
        plateno.length
      );
      let prefix1is = false;
      let prefix2is = false;
      let prefix3is = false;
      if (
        (prefix1 <= 'Z' && prefix1 >= 'A') ||
        (prefix1 <= 'z' && prefix1 >= 'a')
      ) {
        prefix1is = true;
      }
      if (
        (prefix2 <= 'Z' && prefix2 >= 'A') ||
        (prefix2 <= 'z' && prefix2 >= 'a')
      ) {
        prefix2is = true;
      }
      if (
        (prefix3 <= 'Z' && prefix3 >= 'A') ||
        (prefix3 <= 'z' && prefix3 >= 'a')
      ) {
        prefix3is = true;
      }
      if (
        isNaN(sufix) ||
        prefix1is == false ||
        prefix2is == false ||
        prefix3is == false
      ) {
        MessageHelper.error(i18n.ClaimAppraisal.ErrorMsg.Error_0004);
        return false;
      }
      return true;
    }
    return false;
  }

  retrieveVehicleFromDOT(tmpdomainObject, result) {
    const domainObject = tmpdomainObject;
    if (result) {
      domainObject.ModelYear = result.RegistrationDate;
      domainObject.Vin = result.Vin;
      domainObject.Model = result.ModelDescription;
      const table4 = [
        {
          id: result.ModelDescription,
          text: `${result.ModelDescription}`,
        },
      ];
      window.modelCodeTable = new CodeTable(table4);
      domainObject.Maker = result.MakeCode;
      const table2 = [
        { id: result.MakeCode, text: `${result.MakeCodeDesc}` },
      ];
      window.vehicleMakeCodeTable = new CodeTable(table2);
      const dateyear = new Date(result.ManufactureDate);
      const year = dateyear.getFullYear();
      domainObject.MakerYear = year;
    } else {
      domainObject.Vin = null;
      domainObject.Maker = null;
      domainObject.Model = null;
      domainObject.ModelYear = null;
      domainObject.MakerYear = null;
      const table4 = [];
      window.modelCodeTable = new CodeTable(table4);
      domainObject.VehBodyTypeCd = null;
      const table1 = [];
      window.bodyTypeCodeTable = new CodeTable(table1);

      domainObject.VehTypeCd = null;
      const table3 = [];
      window.vehicleCategoryCodeTable = new CodeTable(table3);
      domainObject.DotReceive = null;
    }
    return domainObject;
  }
}
export default alt.createActions(AppraisalVehicleAction);
