import {
  UIBox,
  UISelect,
  UIDialog,
  UISmartPanelGrid,
  UIText,
  UIButton,
} from 'RainbowUI';
import DefinedCodeTable from '../../../codetable/CodeTable';

const EditAdditionalDialogReadOnly = React.createClass({
  getInitialState() {
    return {
      partyAdditional: {},
      Status: false,
    };
  },

  onClickClose() {
    UIDialog.hide('AdditionalDialogReadOnly');
  },

  render() {
    const _self = this;
    const { partyAdditional, Status } = this.state;
    const status = Status;
    return (
      <UIDialog
        id="AdditionalDialogReadOnly"
        width="90%"
        title={i18n.AdditionalId}
      >
        <UISmartPanelGrid column="3">
          <UISelect
            label={i18n.IdType}
            model={partyAdditional}
            property="IdType"
            codeTable={DefinedCodeTable.IdType}
            required="true"
            validationGroup="UpdateAdditionalInfo"
            disabled="true"
          />
          <UIText
            label={i18n.IdNo}
            model={partyAdditional}
            property="IdNumber"
            disabled="true"
          />
          <UISelect
            label={i18n.CountryOfIssue}
            model={partyAdditional}
            property="Country"
            codeTable={DefinedCodeTable.getCountry}
            disabled="true"
          />
          <UISelect
            label={i18n.Status}
            codeTable={DefinedCodeTable.getPartyStatus}
            model={partyAdditional}
            property="Status"
            showBlankOption="false"
            disabled="true"
          />
          <UISelect
            label={i18n.InactiveReason}
            visibled={status}
            model={partyAdditional}
            property="InactiveReason"
            codeTable={DefinedCodeTable.getInactiveReason}
            disabled="true"
          />
        </UISmartPanelGrid>
        <UIBox direction="center">
          <UIButton
            value={i18n.Save}
            onClick={_self.onSave}
            disabled="true"
          />
          <UIButton
            value={i18n.Cancel}
            onClick={_self.onClickClose}
          />
        </UIBox>
      </UIDialog>
    );
  },

  showDialog(partyAdditional) {
    UIDialog.show('AdditionalDialogReadOnly');
    this.setState({
      partyAdditional,
    });
  },
  getInactiveStatus() {
    const status = this.state.partyAdditional.Status;
    if (status == 1 || status === undefined || status === null) {
      this.setState({
        Status: false,
      });
    } else {
      this.setState({
        Status: true,
      });
    }
  },
});
export default EditAdditionalDialogReadOnly;
