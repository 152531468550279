export default {
  ErrorMsg: {},
  ConfirmMsg: {},
  WarnMsg: {
    Warn_0001:
      'Please verify the policy and retrieve a valid policy first',
    Warn_0002: 'Modify Operation Warning ',
    Warn_0003: 'Select Claimant first please.',
  },
  SuccessMsg: {},
  InfoMsg: {},
};
