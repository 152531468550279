import {
  UIPanel,
  UISmartPanelGrid,
  UIText,
  UIDateTimePicker,
  UISelect,
  UIRadio,
  MessageHelper,
  UIParam,
} from 'RainbowUI';
import BindToMixin from 'react-binding';
import { CodeTableAction, CodeTableCstUI } from 'ClaimCommonConstant';
import {
  PartySearchPanelDialog,
  PartyConst,
} from 'EU00PartyCommonUI';
import ClaimCommonExt from 'ClaimCommonExt';

const ClaimPartyAction = ClaimCommonExt.getClaimPartyAction();

const AppraisalBodyInjury = React.createClass({
  mixins: [BindToMixin],
  getInitialState() {
    return {
      AppraisalBodyInjury: this.props.appraisalBodyInjuryInfo,
      PartyName: this.props.partyName,
      IsFirstParty: this.props.isFirstParty,
      hospitalOrg: {},
      indDoctor: {},
    };
  },
  getCodeTable(p) {
    return CodeTableAction.loadDataList(p, null, true);
  },
  getIOStyle() {
    let io = null;
    const { taskCode } = this.props;

    if (
      (taskCode && taskCode !== 'ClaimAppraisalBodilyInjuryTask') ||
      !taskCode
    ) {
      io = 'out';
    }
    return io;
  },
  getEnabled() {
    let enable = true;
    const { taskCode } = this.props;

    if (
      (taskCode && taskCode !== 'ClaimAppraisalBodilyInjuryTask') ||
      !taskCode
    ) {
      enable = false;
    }
    return enable;
  },
  getDaysInHospital() {
    this.setState(prevState => {
      const model = prevState.AppraisalBodyInjury;
      if (model.DateOfAdmission && model.DateOfDischarge) {
        const startDate = new Date(model.DateOfDischarge);
        const endDate = new Date(model.DateOfAdmission);
        model.DayInHospital =
          Math.floor(
            (startDate.getTime() - endDate.getTime()) /
            (24 * 3600 * 1000)
          ) + 1;
        if (model.DayInHospital < 1) {
          MessageHelper.warning(
            i18n.ClaimAppraisal.WarnMsg.Warn_0001
          );
          model.DateOfDischarge = null;
          model.DateOfAdmission = null;
          model.DayInHospital = null;
        }
      }
      return {
        AppraisalBodyInjury: model,
      };
    });
  },
  showTreatment() {
    const model = this.state.AppraisalBodyInjury;
    if (model.Treatment == 2) {
      return false;
    }
    return true;
  },
  changeTreatment() {
    const model = this.state.AppraisalBodyInjury;
    if (model.Treatment == 2) {
      model.DateOfAdmission = null;
      model.dateOfDischarge = null;
      model.DayInHospital = null;
      this.setState({ AppraisalBodyInjury: model });
    }
  },
  validationAge() {
    this.setState(prevState => {
      const model = prevState.AppraisalBodyInjury;

      if (!model.Age || model.Age > 150 || model.Age < 0) {
        model.Age = null;
        MessageHelper.warning(i18n.ClaimAppraisal.WarnMsg.Warn_0002);
      }
      return {
        AppraisalBodyInjury: model,
      };
    });
  },
  showPartySearchPanelDialog(event) {
    const partyRole = event.getParameter('partyRole');
    const partyCategory = event.getParameter('partyCategory');
    this.refs.PartySearchPanelDialog_ref.doContinue = (...args) => {
      const party = args[0];
      if (!party) return;

      const stateJson = {};
      if (party.partyInfo.length === 1) {
        this.setState(stateJson);
        if (PartyConst.PARTY_ROLE_CODE_ORG_HEALTHCARE == partyRole) {
          this.state.AppraisalBodyInjury.Hospital =
            party.partyInfo[0].PartyName;
          this.state.AppraisalBodyInjury.HospitalPartyId =
            party.partyInfo[0].PartyId;
        } else {
          this.state.AppraisalBodyInjury.DoctorName =
            party.partyInfo[0].PartyName;
          this.state.AppraisalBodyInjury.DoctorPartyId =
            party.partyInfo[0].PartyId;
        }
        const commonParty = {
          NewRoleType: this.state.roleType,
          PtyPartyId: Number(party.partyInfo[0].PartyId),
          PartyName: party.partyInfo[0].PartyName,
        };
        this.createClaimParty(commonParty);
      }
      this.refs.PartySearchPanelDialog_ref.doContinue = () => { };
    };
    this.refs.PartySearchPanelDialog_ref.showDialog({
      partyCategory,
      partyRole,
      partyRoleSelectEnable: false,
      partyCategorySelectEnable: false,
      createBtnDisable: true,
      editLinkVisiable: false,
      partyRoleSelectVisiable: false,
    });
  },

  createClaimParty(commonParty) {
    ClaimPartyAction.createPartyParty(this.props.caseId, commonParty);
  },

  render() {
    const io = this.getIOStyle();
    const _self = this;
    const { IsFirstParty, PartyName } = this.state;
    const { history } = this.props;
    const enabled = this.getEnabled();
    let style;
    if (history) {
      style = 'default';
    } else {
      style = 'primary';
    }
    const showTreatment = this.showTreatment();
    let victimName = _self.state.AppraisalBodyInjury.VictimName;
    if (IsFirstParty == true && !victimName) {
      if (PartyName) {
        victimName = PartyName;
      }
    }
    if (victimName === ' ') {
      victimName = '';
    }
    AppraisalBodyInjury.VictimName = victimName;
    return (
      <div>
        <PartySearchPanelDialog
          ref="PartySearchPanelDialog_ref"
          parentComponent={this}
          endId="_appraisal_bodyinjury"
        />
        <UIPanel
          panelTitle={i18n.ClaimAppraisal.VictimInfo}
          styleClass={style}
        >
          <UISmartPanelGrid column="3">
            <UIText
              label={i18n.ClaimAppraisal.VictimName}
              validationGroup="bodilyInjury"
              required="true"
              valueLink={this.bindToState("AppraisalBodyInjury", "VictimName")}
              model={AppraisalBodyInjury}
              property="VictimName"
              io={io}
            />
            <UIRadio
              label={i18n.ClaimAppraisal.Gender}
              validationGroup="bodilyInjury"
              required="true"
              valueLink={this.bindToState(
                'AppraisalBodyInjury',
                'Gender'
              )}
              io={io}
              codeTable={this.getCodeTable.bind(
                this,
                CodeTableCstUI.Gender
              )}
              enabled={enabled}
            />
            <UIText
              label={i18n.ClaimAppraisal.Age}
              valueLink={this.bindToState(
                'AppraisalBodyInjury',
                'Age'
              )}
              io={io}
              onChange={this.validationAge.bind(this)}
            />
            <UIText
              label={i18n.ClaimAppraisal.HomeAddress}
              valueLink={this.bindToState(
                'AppraisalBodyInjury',
                'Address'
              )}
              io={io}
              colspan="2"
            />
            <UIText
              label={i18n.ClaimAppraisal.ContactTel}
              valueLink={this.bindToState(
                'AppraisalBodyInjury',
                'ContactTel'
              )}
              io={io}
              allowChars="/^\d*$/"
            />
            <UIRadio
              label={i18n.ClaimAppraisal.InjuryType}
              valueLink={this.bindToState(
                'AppraisalBodyInjury',
                'InjuryType'
              )}
              codeTable={this.getCodeTable.bind(
                this,
                CodeTableCstUI.ClaimInjuryType
              )}
              enabled={enabled}
            />
            <UISelect
              label={i18n.ClaimAppraisal.DisabilityGrade}
              valueLink={this.bindToState(
                'AppraisalBodyInjury',
                'InjuryGrade'
              )}
              codeTable={this.getCodeTable.bind(
                this,
                CodeTableCstUI.ClaimDisabilityGrade
              )}
              enabled={enabled}
            />
            <UIRadio
              label={i18n.ClaimAppraisal.Treatment}
              valueLink={this.bindToState(
                'AppraisalBodyInjury',
                'Treatment'
              )}
              codeTable={this.getCodeTable.bind(
                this,
                CodeTableCstUI.ClaimTreatment
              )}
              enabled={enabled}
            />
            <UIText
              label={i18n.ClaimAppraisal.Hospital}
              io={io}
              enabled={false}
              suffixIcon="fa fa-search"
              onSuffixIconClick={this.showPartySearchPanelDialog.bind(
                this
              )}
              valueLink={this.bindToState(
                'AppraisalBodyInjury',
                'Hospital'
              )}
            >
              <UIParam
                name="partyRole"
                value={PartyConst.PARTY_ROLE_CODE_ORG_HEALTHCARE}
              />
              <UIParam
                name="partyCategory"
                value={PartyConst.PARTY_TYPE_ORG}
              />
            </UIText>
            <UIText
              label={i18n.ClaimAppraisal.DoctorName}
              io={this.getIOStyle()}
              enabled={false}
              suffixIcon="fa fa-search"
              onSuffixIconClick={this.showPartySearchPanelDialog.bind(
                this
              )}
              valueLink={this.bindToState(
                'AppraisalBodyInjury',
                'DoctorName'
              )}
            >
              <UIParam
                name="partyRole"
                value={PartyConst.PARTY_ROLE_CODE_IND_HEALTHCARE}
              />
              <UIParam
                name="partyCategory"
                value={PartyConst.PARTY_TYPE_IND}
              />
            </UIText>
            <UIText
              label={i18n.ClaimAppraisal.Specialty}
              valueLink={this.bindToState(
                'AppraisalBodyInjury',
                'Specialty'
              )}
              io={this.getIOStyle()}
            />
            <UIDateTimePicker
              label={i18n.ClaimAppraisal.DateOfAdmission}
              validationGroup="bodilyInjury"
              format="MM/DD/YYYY"
              visibled={showTreatment}
              placeHolder="MM/DD/YYYY"
              valueLink={this.bindToState(
                'AppraisalBodyInjury',
                'DateOfAdmission'
              )}
              io={io}
              enabled={enabled}
              maxDate="TODAY"
              onChange={this.getDaysInHospital.bind(this)}
            />
            <UIDateTimePicker
              label={i18n.ClaimAppraisal.DateOfDischarge}
              validationGroup="bodilyInjury"
              format="MM/DD/YYYY"
              visibled={showTreatment}
              placeHolder="MM/DD/YYYY"
              valueLink={this.bindToState(
                'AppraisalBodyInjury',
                'DateOfDischarge'
              )}
              io={io}
              enabled={enabled}
              onChange={this.getDaysInHospital.bind(this)}
              maxDate="TODAY"
            />
            <UIText
              label={i18n.ClaimAppraisal.DaysInHospital}
              valueLink={this.bindToState(
                'AppraisalBodyInjury',
                'DayInHospital'
              )}
              io={io}
              visibled={showTreatment}
            />
            <UIText
              label={i18n.ClaimAppraisal.InjuryDescription}
              valueLink={this.bindToState(
                'AppraisalBodyInjury',
                'InjuryRemark'
              )}
              colspan="2"
              io={io}
            />
            <UIDateTimePicker
              label={i18n.ClaimAppraisal.investigatorArrival}
              validationGroup="bodilyInjury"
              placeHolder="MM/DD//YYYY HH:mm:ss"
              format="MM/DD//YYYY HH:mm:ss"
              valueLink={this.bindToState(
                'AppraisalBodyInjury',
                'DateOfArrival'
              )}
              io={io}
              enabled={enabled}
              maxDate="TODAY"
            />
            <UIText
              label={i18n.ClaimAppraisal.Remark}
              valueLink={this.bindToState(
                'AppraisalBodyInjury',
                'VictimRemark'
              )}
              colspan="3"
              io={io}
            />
          </UISmartPanelGrid>
        </UIPanel>
      </div>
    );
  },
});
export default AppraisalBodyInjury;
