import {
  UIDialog,
  UIPanel,
  UICell,
  UIButton,
  UISmartPanelGrid,
  UISelect,
  UITextarea,
  UIBox,
} from 'RainbowUI';
import React from 'react';
import BindToMixin from 'react-binding';

const ClaimHandlingAction = require('../../action/ClaimHandlingAction');
const CodeTableAction = require('../../action/CodeTableAction');
const CodeTableCstUI = require('../../constant/CodeTableCstUI');

const RejectDialog = React.createClass({
  mixins: [BindToMixin],

  getInitialState() {
    return {
      rejectInfo: {},
      rejectTarget: '',
      resetSubclaimFuncFunc: {},
    };
  },

  clickSubmit() {
    const obj = ClaimHandlingAction.rejectSubmit(
      this.state.rejectInfo,
      this.state.rejectTarget
    );

    if (this.state.rejectInfo.ObjectId) {
      this.state.resetSubclaimFunc(obj);
      UIDialog.hide('Reject_UIDialog');
    } else if (this.state.rejectInfo.CaseId) {
      window.close();
    }
  },

  showDialog(rejectInfo, rejectTarget, resetSubclaimFunc) {
    this.setState({
      rejectInfo,
      rejectTarget,
      resetSubclaimFunc,
    });
    UIDialog.show('Reject_UIDialog');
  },

  clickCancel() {
    UIDialog.hide('Reject_UIDialog');
  },

  render() {
    const { rejectTarget } = this.state;
    const warnMessage = `${i18n.ClaimCommon.RejectDialogConfirm +
      rejectTarget.toLowerCase()}?`;
    return (
      <div>
        <UIDialog
          id="Reject_UIDialog"
          title={i18n.ClaimCommon.RejectConfirmation}
        >
          <UIPanel
            panelTitle={i18n.ClaimCommon.RejectConfirmation}
            styleClass="default"
          >
            <h6 style={{ margin: '10px 17%' }}>{warnMessage}</h6>
            <UISmartPanelGrid>
              <UISelect
                id="rejectReason_UISelect"
                label={i18n.ClaimCommon.RejectReason}
                codeTable={CodeTableAction.loadDataList(
                  CodeTableCstUI.ClaimRejectReason,
                  null
                )}
                valueLink={this.bindToState(
                  'rejectInfo',
                  'RejectReason'
                )}
                required="true"
                validationGroup="RejectInfo"
              />
              <UICell />

              <UITextarea
                id="rejectRemark_UITextarea"
                label={i18n.ClaimCommon.Remark}
                required="true"
                validationGroup="RejectInfo"
                valueLink={this.bindToState(
                  'rejectInfo',
                  'RejectRemark'
                )}
                colspan="2"
              />
            </UISmartPanelGrid>
          </UIPanel>

          <UIBox direction="center">
            <UIButton
              value={i18n.ClaimCommon.Submit}
              onClick={this.clickSubmit}
              causeValidation="true"
              validationGroup="RejectInfo"
            />
            <UIButton
              value={i18n.ClaimCommon.Cancel}
              onClick={this.clickCancel}
            />
          </UIBox>
        </UIDialog>
      </div>
    );
  },
});
export default RejectDialog;
