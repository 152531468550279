import {
  MessageHelper,
  CodeTable,
  UIBox,
  UIPanel,
  UIDataTable,
  UIDateTimePicker,
  UISelect,
  UIColumn,
  UIButton,
} from 'RainbowUI';
import TaskAssignUserRuleService from '../../service/TaskAssignUserRuleService';
import AssignmentAction from '../../action/AssignmentAction';
import AssignmentStore from '../../store/AssignmentStore';
import NewTaskDialog from './NewTaskDialog';
import ComfirmClosedTask from './ComfirmClosedTask';
import CodeTableCstUI from '../../constant/CodeTableCstUI';
import CodeTableAction from '../../action/CodeTableAction';
import ClaimBaseInfo from '../claimhandling/ClaimBaseInfo';
import TaskTransferDialog from '../TaskTransferDialog';

const code = require('../../mock/CodeTable');
const Assignment = React.createClass({
  getInitialState() {
    const approval = this.props.assignAuth == false;
    return {
      assgnments: {},
      isApproval: approval,
      isSelected: '',
      currentUserApproval: true,
    };
  },

  componentWillMount() {
    AssignmentStore.listen(this.onStoreStateChange);
    AssignmentAction.initAssignment(this.props.caseId);
  },

  componentWillUnmount() {
    AssignmentStore.unlisten(this.onStoreStateChange);
    TaskAssignUserRuleService.clearUserList();
  },

  onStoreStateChange(storeState) {
    if (!_.isEmpty(storeState.assgnments)) {
      this.setState({ assgnments: storeState.assgnments });
    }
  },

  attachToCodeTable() {
    const codeTable = [{ id: 'claim', text: 'Claim' }];
    const params = this.props.subclaimList;
    for (let i = 0; i < params.length; i += 1) {
      codeTable.push({
        id: params[i].ObjectId,
        text: `Subclaim${params[i].Name}`,
      });
    }

    return new CodeTable(codeTable);
  },
  mockUser() {
    return TaskAssignUserRuleService.queryUserList(
      'noAuthCode',
      this.props.caseId
    );
  },
  reAssignmentTask() {
    const selected = UIDataTable.getSelectedRecord(
      'assignmentPendingTaskTable'
    )[0];
    if (!selected || !selected.CaseId) {
      MessageHelper.info(i18n.ClaimCommon.selectATaskFirst);
      return;
    }
    this.refs.NewTaskDialog_ref.showDialog('edit', selected);
  },
  newTask() {
    this.refs.NewTaskDialog_ref.showDialog('new', null);
  },
  closeTaskComfirmDialog() {
    const selected = UIDataTable.getSelectedRecord(
      'assignmentPendingTaskTable'
    )[0];
    if (!selected || !selected.CaseId) {
      MessageHelper.info(i18n.ClaimCommon.selectATaskFirst);
      return;
    }
    this.refs.ComfirmCloadTask_ref.showDialog();
  },
  onClickPending() {
    let currentUserApproval = true;
    const selRecord = UIDataTable.getSelectedRecord(
      'assignmentPendingTaskTable'
    );

    if (
      selRecord[0].TaskDefinitionKey.indexOf('Approval') > -1 ||
      this.props.assignAuth == false
    ) {
      if (
        selRecord[0].UpdateBy == this.state.assgnments.user.UserId
      ) {
        currentUserApproval = false;
      }
      if (!this.props.assignAuth || !currentUserApproval)
        currentUserApproval = false;
      this.setState({
        isApproval: true,
        isSelected: selRecord[0].dataIndex,
        currentUserApproval,
      });
    } else {
      this.setState({
        isApproval: false,
        isSelected: selRecord[0].dataIndex,
        currentUserApproval,
      });
    }
  },
  taskTransfer() {
    this.refs.ClaimTaskTransferDialog.showDialog({
      CaseId: this.props.caseId,
      TaskTransfer: true,
    });
  },
  render() {
    const _self = this;
    const {
      assignAuth,
      claimEntity,
      caseId,
      claimNumber,
      subclaimList,
      claimStatus,
      userTaskInfo,
    } = this.props;
    const { isSelected, assgnments, isApproval } = this.state;
    const { closedTasks } = assgnments;
    if (closedTasks) {
      _.each(closedTasks, (obj, index) => {
        if (obj) {
          if (
            obj.TaskStatus === 'Pending' ||
            obj.TaskStatus === 'New'
          ) {
            closedTasks.splice(index, 1);
          }
        }
      });
    }
    console.log(`===closedTasks===${JSON.stringify(closedTasks)}`);
    return (
      <div>
        <ClaimBaseInfo claimEntity={claimEntity} />
        <UIPanel
          id="assignmentPendingTask"
          panelTitle={i18n.ClaimCommon.AssignmentPendingTask}
          toggleable="true"
        >
          <UIDataTable
            id="assignmentPendingTaskTable"
            indexable="false"
            selectState={[isSelected]}
            onRowSelect={this.onClickPending}
            headable="false"
            value={assgnments.pendingTasks}
          >
            <UIColumn selectionMode="single" />
            <UIColumn
              headerTitle={i18n.ClaimCommon.Id}
              width="10%"
              value="id"
            />
            <UIColumn
              headerTitle={i18n.ClaimCommon.AssignmentAttachTo}
              width="10%"
              value="AttachTo"
            >
              <UISelect
                codeTable={_self.attachToCodeTable}
                io="out"
              />
            </UIColumn>
            <UIColumn
              headerTitle={i18n.ClaimCommon.TaskType}
              width="30%"
              value="TaskDefinitionKey"
              sortable="true"
            >
              <UISelect
                io="out"
                codeTable={CodeTableAction.loadDataList(
                  CodeTableCstUI.CLAIMTASK,
                  null
                )}
              />
            </UIColumn>

            <UIColumn
              headerTitle={i18n.ClaimCommon.AssignmentAssignTo}
              width="10%"
              value="TaskAssignee"
              sortable="true"
            >
              <UISelect codeTable={this.mockUser} io="out" />
            </UIColumn>
            <UIColumn
              headerTitle={i18n.ClaimCommon.AssignedBy}
              width="10%"
              value="AssignBy"
              sortable="true"
            >
              <UISelect codeTable={this.mockUser} io="out" />
            </UIColumn>
            <UIColumn
              headerTitle={i18n.ClaimCommon.AssignedDate}
              width="15%"
              value="AssignDate"
              sortable="true"
            >
              <UIDateTimePicker
                placeHolder="MM/DD/YYYY HH:mm:ss"
                format="MM/DD/YYYY HH:mm:ss"
                io="out"
              />
            </UIColumn>
            <UIColumn
              headerTitle={i18n.ClaimCommon.DueDate}
              width="15%"
              value="TaskDueTime"
              sortable="true"
            >
              <UIDateTimePicker
                placeHolder="MM/DD/YYYY HH:mm:ss"
                format="MM/DD/YYYY HH:mm:ss"
                io="out"
              />
            </UIColumn>
            <UIColumn
              headerTitle={i18n.ClaimCommon.Priority}
              width="10%"
              value="TaskPrioriry"
              sortable="true"
            >
              <UISelect
                io="out"
                codeTable={CodeTableAction.loadDataList(
                  CodeTableCstUI.Prioriry,
                  null
                )}
              />
            </UIColumn>
          </UIDataTable>

          <UIBox direction="left">
            <UIButton
              value={i18n.ClaimCommon.AssignmentNewTask}
              onClick={this.newTask}
              disabled={assignAuth == false ? 'true' : 'false'}
              position="left"
            />
            <UIButton
              value={i18n.ClaimCommon.AssignmentReassign}
              onClick={this.reAssignmentTask}
              disabled={assignAuth == false ? 'true' : 'false'}
              position="left"
            />
            <UIButton
              value={i18n.ClaimCommon.AssignmentCloseTask}
              disabled={isApproval}
              onClick={this.closeTaskComfirmDialog}
              position="left"
            />
            <UIButton
              value={i18n.ClaimCommon.WorkOnTasks}
              onClick={this.taskTransfer}
              position="left"
            />
          </UIBox>
        </UIPanel>
        <UIPanel
          id="assignmentColseTask"
          panelTitle={i18n.ClaimCommon.AssignmentClosedTask}
          toggleable="true"
          expendOnPageLoad="false"
        >
          <UIDataTable
            id="assignmentCloseTaskTable"
            indexable="false"
            headable="false"
            value={closedTasks}
          >
            <UIColumn
              headerTitle={i18n.ClaimCommon.Id}
              width="10%"
              value="id"
            />
            <UIColumn
              headerTitle={i18n.ClaimCommon.AssignmentAttachTo}
              value="AttachTo"
              sortable="true"
            >
              <UISelect
                codeTable={_self.attachToCodeTable}
                io="out"
              />
            </UIColumn>
            <UIColumn
              headerTitle={i18n.ClaimCommon.TaskType}
              value="TaskDefinitionKey"
              sortable="true"
            >
              <UISelect
                io="out"
                codeTable={CodeTableAction.loadDataList(
                  CodeTableCstUI.CLAIMTASK,
                  null
                )}
              />
            </UIColumn>
            <UIColumn
              headerTitle={i18n.ClaimCommon.AssignedBy}
              value="AssignBy"
              sortable="true"
            >
              <UISelect codeTable={this.mockUser} io="out" />
            </UIColumn>

            <UIColumn
              headerTitle={i18n.ClaimCommon.AssignedDate}
              value="AssignDate"
              sortable="true"
            >
              <UIDateTimePicker
                placeHolder="MM/DD/YYYY HH:mm:ss"
                format="MM/DD/YYYY HH:mm:ss"
                io="out"
              />
            </UIColumn>
            <UIColumn
              headerTitle={i18n.ClaimCommon.AssignmentClosedBy}
              value="CloseBy"
              sortable="true"
            >
              <UISelect codeTable={this.mockUser} io="out" />
            </UIColumn>
            <UIColumn
              headerTitle={i18n.ClaimCommon.AssignmentClosedDate}
              value="CloseDate"
              sortable="true"
            >
              <UIDateTimePicker
                placeHolder="MM/DD/YYYY HH:mm:ss"
                format="MM/DD/YYYY HH:mm:ss"
                io="out"
              />
            </UIColumn>
            <UIColumn
              headerTitle={i18n.ClaimCommon.TaskStatus}
              value="TaskStatus"
            >
              <UISelect io="out" codeTable={code.taskStatus} />
            </UIColumn>
            <UIColumn
              headerTitle={i18n.ClaimCommon.Priority}
              value="TaskPrioriry"
              sortable="true"
            >
              <UISelect
                io="out"
                codeTable={CodeTableAction.loadDataList(
                  CodeTableCstUI.Prioriry,
                  null
                )}
              />
            </UIColumn>
          </UIDataTable>
        </UIPanel>
        <TaskTransferDialog
          ref="ClaimTaskTransferDialog"
          componentId="Assignment"
        />
        <NewTaskDialog
          ref="NewTaskDialog_ref"
          caseId={caseId}
          claimNumber={claimNumber}
          subclaimList={subclaimList}
          claimEntity={claimEntity}
          claimStatus={claimStatus}
          userTaskInfo={userTaskInfo}
        />
        <ComfirmClosedTask
          ref="ComfirmCloadTask_ref"
          caseId={caseId}
        />
      </div>
    );
  },
});
export default Assignment;
