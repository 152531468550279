import {
  MessageHelper,
  CodeTable,
  Caller,
  UIDialog,
  DataContext,
} from 'RainbowUI';
import { ClaimMessageForamtUtils } from 'ClaimCommonConstant';
import ClaimHandlingService from '../service/ClaimHandlingService';
import alt from '../alt';
import ClaimUtil from '../util/ClaimUtil';
import Url from '../url/Url';

const CaseFormMock = require('../mock/CaseFormMock');
const code = require('../mock/CodeTable');
const CodeTableAction = require('./CodeTableAction');
const CodeTableCstUI = require('../constant/CodeTableCstUI');
const ClaimCstUI = require('../constant/ClaimCstUI');

class ClaimHandlingAction {
  initHandling() {
    const result = this.actions.getParamId();
    if (env.IS_MOCK) {
      this.dispatch(CaseFormMock);
      return;
    }

    ClaimHandlingService.loadClaimHandlingInfo(result).then(
      tmpCaseForm => {
        const CaseForm = tmpCaseForm;
        this.actions.handlingCodeTable(CaseForm.ClaimEntity);

        sessionStorage.setItem(
          'claim_caseId',
          CaseForm.ClaimData.CaseId || ''
        );
        sessionStorage.setItem('claim_objectId', '');

        if (CaseForm.ClaimEntity.PolicyNo) {
          const checkFeeStatus = this.actions.validatePolicyPremium(
            CaseForm.ClaimEntity.PolicyNo
          );
          let validationMsg = CaseForm.ClaimData.WarningMsg;
          if (!validationMsg) {
            validationMsg = [];
            CaseForm.ClaimData.WarningMsg = validationMsg;
          }
          if (checkFeeStatus)
            CaseForm.ClaimData.WarningMsg.push(checkFeeStatus);
        }

        this.dispatch(CaseForm);
      }
    );
  }

  getRegionByCountryCodeAndRegionName(countryCode, regionName) {
    const url = `${Url.queryRegionByCountryCodeAndRegionName}?countryCode=${countryCode}&regionName=${regionName}`;

    let results = null;
    Caller.call(url, null, {
      method: 'GET',
      async: false,
      block: true,
    }).then(
      data => {
        results = data;
      },
      err => {
        MessageHelper.error(
          err,
          '',
          MessageHelper.POSITION_TOP_RIGHT
        );
      }
    );
    return results;
  }

  isMotor(caseEntity) {
    const productCode = caseEntity.ProductCode;
    let result;
    if (productCode) {
      Caller.doService(
        ClaimHandlingService.isMotor(productCode)
      ).then(
        data => {
          result = data;
        },
        ex => {
          MessageHelper.error(JSON.stringify(ex), '');
        }
      );
    }

    return result;
  }

  cancelNewSubclaimHandling(caseId, tmpcaseForm, objectEntity) {
    const caseForm = tmpcaseForm;
    if (!objectEntity.ObjectId) {
      caseForm.ClaimEntity.ObjectList = caseForm.ClaimEntity.ObjectList.filter(
        object => object.SeqNo !== objectEntity.SeqNo
      );
      this.dispatch(caseForm);
    } else {
      const result = this.actions.getParamId();

      ClaimHandlingService.cancelNewSubclaim(result, caseId).then(
        tmpCaseForm => {
          const CaseForm = tmpCaseForm;
          this.actions.handlingCodeTable(CaseForm.ClaimEntity);

          sessionStorage.setItem(
            'claim_caseId',
            CaseForm.ClaimData.CaseId || ''
          );
          sessionStorage.setItem('claim_objectId', '');

          if (CaseForm.ClaimEntity.PolicyNo) {
            const checkFeeStatus = this.actions.validatePolicyPremium(
              CaseForm.ClaimEntity.PolicyNo
            );
            let validationMsg = CaseForm.ClaimData.WarningMsg;
            if (!validationMsg) {
              validationMsg = [];
              CaseForm.ClaimData.WarningMsg = validationMsg;
            }
            if (checkFeeStatus)
              CaseForm.ClaimData.WarningMsg.push(checkFeeStatus);
          }

          this.dispatch(CaseForm);
        }
      );
    }
  }

  validatePolicyPremium(policyNo) {
    const checkFeeStatus = '';
    // TODO BCP to be integrated
    // ClaimHandlingService.checkPolicyFeeStatus(policyNo).then(
    //   checkData => {
    //     if (checkData && checkData.IsSuccessful) {
    //       if (checkData.HasNotPayPremiumByPolicy) {
    //         checkFeeStatus = i18n.ClaimCommon.PremiumNotCollected;
    //       } else {
    //       }
    //     } else {
    //     }
    //   },
    //   data => {}
    // );
    return checkFeeStatus;
  }

  newSubclaim(caseForm) {
    if (caseForm) {
      this.actions.handlingCodeTable(caseForm.ClaimEntity);
    }
    this.dispatch(caseForm);
  }

  getParamId() {
    let taskInstranceId = 0;
    const caseId = 0;
    const urlParam = window.location.hash;
    const begin = urlParam.indexOf('?');
    const paramStr = urlParam.substr(begin + 1);
    const paramArray = paramStr.split('&');

    const obj = {};
    for (let i = 0; i < paramArray.length; i += 1) {
      obj[paramArray[i].split('=')[0]] = paramArray[i].split('=')[1];
      if (paramArray[i].split('=')[0] === 'taskInstanceId') {
        taskInstranceId = paramArray[i].split('=')[1];
      }
    }

    if (
      !Object.prototype.hasOwnProperty.call(obj, 'taskInstranceId')
    ) {
      obj.taskInstranceId = taskInstranceId;
    }
    if (!Object.prototype.hasOwnProperty.call(obj, 'caseId')) {
      obj.caseId = caseId;
    }

    return obj;
  }

  handlingCodeTable(tmpClaimEntity) {
    const ClaimEntity = tmpClaimEntity;
    if (ClaimEntity.PolicyEntity.PolicyId) {
      let codeTableInsured = [];
      const insuredList = ClaimEntity.PolicyEntity.InsuredList;
      if (insuredList && insuredList.constructor == Array) {
        codeTableInsured = insuredList.map((data, index) => {
          return { id: data.InsuredId, text: data.InsuredName || '' };
        });
      }
      if (!ClaimEntity.CurrencyCode) {
        ClaimEntity.CurrencyCode =
          ClaimEntity.PolicyEntity.CurrencyCode;
      }
      const isMotor = this.actions.isMotor(ClaimEntity);
      ClaimEntity.IsMotor = isMotor;
      ClaimEntity.CodeTableInsured = new CodeTable(codeTableInsured);
      ClaimEntity.CodeTableCauseOfLoss = this.actions.getCodeTableCauseOfLoss(
        ClaimEntity.PolicyEntity.ProductLineCode
      );
      ClaimEntity.CodeTableSubclaimType = this.actions.getCodeTableSubclaimType(
        ClaimEntity.PolicyEntity.ProductLineCode
      );
      ClaimEntity.CodeTableDamageType = this.actions.getCodeTableDamageType(
        ClaimEntity.PolicyEntity.ProductLineCode
      );
      ClaimEntity.CodeTableClaimant = this.actions.getCodeTableClaimant(
        ClaimEntity.ClaimPartyList
      );
      ClaimEntity.CodeTableClaimOwner = this.actions.getCodeTableClaimOwner(
        ClaimEntity.OwnerList
      );
    }
  }

  getCodeTableClaimOwner(ownerList) {
    if (_.isEmpty(ownerList)) {
      return null;
    }
    let codeTableClaimOwner = [];
    codeTableClaimOwner = ownerList.map((data, index) => {
      return { id: data.UserId, text: data.RealName };
    });

    return new CodeTable(
      _.sortBy(codeTableClaimOwner, n => {
        return n.text;
      })
    );
  }

  getCodeTableClaimant(claimPartyList) {
    if (_.isEmpty(claimPartyList)) {
      return null;
    }
    let codeTableClaimant = [];
    codeTableClaimant = claimPartyList.map((data, index) => {
      return { id: data.PartyId, text: data.PartyName };
    });

    const codeTableClaimantSorted = _.sortBy(codeTableClaimant, n => {
      return n.text;
    });
    codeTableClaimantSorted.push({ id: -1, text: '*New Claimant' });
    return new CodeTable(codeTableClaimantSorted);
  }

  getCodeTableDamageType(productLineCode) {
    if (env.IS_MOCK) {
      return code.damageType;
    }

    let conditions = null;
    if (productLineCode) {
      conditions = { PRODUCT_LINE_CODE: productLineCode };
    }

    return CodeTableAction.loadDataList(
      CodeTableCstUI.ClaimDamageTypeCondition,
      conditions,
      true
    );
  }

  getCodeTableSubclaimType(productLineCode) {
    if (env.IS_MOCK) {
      return code.subclaimType;
    }

    let conditions = null;
    if (productLineCode) {
      conditions = { PRODUCT_LINE_CODE: productLineCode };
    }
    return CodeTableAction.loadDataList(
      CodeTableCstUI.SubclaimType,
      conditions,
      true
    );
  }

  getCodeTableCauseOfLoss(productLineCode) {
    if (env.IS_MOCK) {
      return code.causeOfLoss;
    }

    let conditions = null;
    if (productLineCode) {
      conditions = { PRODUCT_LINE_CODE: productLineCode };
    }
    return CodeTableAction.loadDataList(
      CodeTableCstUI.LossCause,
      conditions
    );
  }

  retrievePolicyCountinue(caseForm) {
    const caseFormBak = jQuery.extend(true, {}, caseForm);
    delete caseFormBak.ClaimEntity.CodeTableInsured;
    delete caseFormBak.ClaimEntity.CodeTableCauseOfLoss;
    delete caseFormBak.ClaimEntity.CodeTableSubclaimType;
    delete caseFormBak.ClaimEntity.CodeTableDamageType;
    delete caseFormBak.ClaimEntity.CodeTableClaimant;
    delete caseFormBak.ClaimEntity.CodeTableClaimOwner;
    delete caseFormBak.ClaimEntity.cityCodeTable;
    delete caseFormBak.ClaimEntity.postalCodeTable;
    delete caseFormBak.ClaimEntity.streetCodeTable;
    delete caseFormBak.ClaimEntity.townCodeTable;
    delete caseFormBak.ClaimEntity.countryCodeTable;
    delete caseFormBak.ClaimEntity.regions;
    delete caseFormBak.ClaimEntity.IsMotor;
    caseFormBak.ClaimEntity.AccidentCity =
      caseFormBak.ClaimEntity.City;
    caseFormBak.ClaimEntity.AccidentTown =
      caseFormBak.ClaimEntity.Area;
    caseFormBak.ClaimEntity.AccidentStreet =
      caseFormBak.ClaimEntity.StreetName;
    caseFormBak.ClaimEntity.PostalCode =
      caseFormBak.ClaimEntity.PostCode;
    delete caseFormBak.ClaimEntity.City;
    delete caseFormBak.ClaimEntity.Area;
    delete caseFormBak.ClaimEntity.StreetName;
    delete caseFormBak.ClaimEntity.PostCode;
    const objects = caseFormBak.ClaimEntity.ObjectList;
    if (objects) {
      for (let i = 0; i < objects.length; i += 1) {
        objects[i].RentalCarCompany = objects[i].RentalCarCompanyId;
        delete objects[i].RentalCarCompanyId;
      }
    }
    ClaimHandlingService.retrievePolicy(caseFormBak).then(data => {
      const caseEntity = data.Model;
      this.actions.handlingCodeTable(caseEntity);
      this.dispatch(caseEntity);
    });
  }

  changeStatus() {
    const claim_caseId = sessionStorage.getItem('claim_caseId');
    ClaimHandlingService.loadStatus(claim_caseId).then(data => {
      this.dispatch(data);
    });
  }

  modifyClaimInfo(event) {
    this.actions.getClaimInfo(event.getParameter('caseId'));
    UIDialog.show('ModifyClaimInfo_Dialog');
  }

  modifyClaimSubmit(tmpclaimEntity) {
    let claimEntity = tmpclaimEntity;
    claimEntity = this.actions.modifyClaimAddress(claimEntity);
    ClaimHandlingService.putClaimInfo(claimEntity).then(data => {
      if (data.Status == ClaimCstUI.REST_RESTPONSE_STATUS_OK) {
        this.actions.handlingCodeTable(data.Model.ClaimEntity);
        this.dispatch(data.Model);
        setTimeout(() => {
          UIDialog.hide('ModifyClaimInfo_Dialog');
        }, 0);
      } else if (
        data.Status == ClaimCstUI.REST_RESTPONSE_STATUS_BLOCK
      ) {
        MessageHelper.error(i18n.ClaimCommon.Fail);
      }
    });
  }

  modifyClaimAddress(tmpclaimEntity) {
    const claimEntity = tmpclaimEntity;
    if (claimEntity) {
      /* if(claimEntity.CountryCode){
				claimEntity.AccidentCountryCode = claimEntity.CountryCode;
				delete claimEntity.CountryCode;
			} */
      if (claimEntity.City) {
        claimEntity.AccidentCity = claimEntity.City;
        delete claimEntity.City;
      }
      if (claimEntity.Area) {
        claimEntity.AccidentTown = claimEntity.Area;
        delete claimEntity.Area;
      }
      if (claimEntity.StreetName) {
        claimEntity.AccidentStreet = claimEntity.StreetName;
        delete claimEntity.StreetName;
      }
      if (claimEntity.PostCode) {
        claimEntity.PostalCode = claimEntity.PostCode;
        delete claimEntity.PostCode;
      }
    }
    return claimEntity;
  }

  changeNotification(caseId) {
    let claim = null;
    ClaimHandlingService.changeNotification(caseId).then(data => {
      if (data.Status == ClaimCstUI.REST_RESTPONSE_STATUS_OK) {
        claim = data.Model;
      } else if (
        data.Status == ClaimCstUI.REST_RESTPONSE_STATUS_BLOCK
      ) {
        MessageHelper.error(i18n.ClaimCommon.Fail);
      }
    });
    return claim;
  }

  checkCauseOfLoss(oldCauseOfLoss, caseEntity) {
    let objectIdList = '';
    let conflictedSubclaims = [];
    if (oldCauseOfLoss !== caseEntity.LossCause) {
      const subclaimTypeList = this.actions.getCodeTableSubclaimType(
        caseEntity.PolicyEntity.ProductLineCode
      );
      const newPossibleSubclaimTypes = subclaimTypeList.codes
        .filter(data => data.Parent == caseEntity.LossCause)
        .map(data => data.id);
      if (caseEntity.ObjectList) {
        conflictedSubclaims = caseEntity.ObjectList.filter(
          data =>
            ClaimUtil.statusIsOpen(data.StatusCode) &&
            newPossibleSubclaimTypes.indexOf(data.SubclaimType) == -1
        );

        if (
          conflictedSubclaims.length ==
          caseEntity.ObjectList.length &&
          caseEntity.ObjectList.length !== 0
        ) {
          /* empty */
        }

        if (conflictedSubclaims.length > 1) {
          objectIdList = conflictedSubclaims.reduce(
            (a, b) => `${a.ObjectId}_${b.ObjectId}`
          );
        } else {
          objectIdList =
            conflictedSubclaims.length === 1
              ? conflictedSubclaims[0].ObjectId
              : '';
        }
      }
      return {
        conflictedSubclaims,
        objectIdList,
      };
    }
    return {
      conflictedSubclaims,
      objectIdList,
    };
  }

  allowToCloseSubclaim(objectIdList) {
    let result = true;

    ClaimHandlingService.checkSubclaimDidSettlement(
      objectIdList
    ).then(data => {
      if (data.Status == ClaimCstUI.REST_RESTPONSE_STATUS_WARN) {
        result = false;
        MessageHelper.error(i18n.ClaimCommon.SubclaimHaveSettle);
      }
    });

    return result;
  }

  modifySubclaimSubmit(objectEntity, callback) {
    if (this.actions.checkSubclaim(objectEntity)) {
      ClaimHandlingService.putSubclaim(objectEntity).then(data => {
        if (data.Status == ClaimCstUI.REST_RESTPONSE_STATUS_OK) {
          this.dispatch(data.Model);
          UIDialog.hide('ModifySubclaimInfo_Dialog');
          if (callback) callback.call();

          this.actions.changeStatus();
        }
      });
    }
  }

  rejectSubmit(rejectInfo, rejectTarget) {
    let result;
    if (rejectInfo.CaseId && rejectTarget == i18n.ClaimCommon.Claim) {
      result = this.actions.rejectClaimSubmit(rejectInfo);
    } else if (rejectTarget == i18n.ClaimCommon.Subclaim) {
      result = this.actions.rejectSubClaimSubmit(rejectInfo);
    }
    return result;
  }

  rejectClaimSubmit(tmpclaimEntity) {
    let claimEntity = tmpclaimEntity;
    claimEntity.StatusCode = 'REJECT';
    const objectList = [];
    if (claimEntity.ObjectList) {
      for (let i = 0; i < claimEntity.ObjectList.length; i += 1) {
        if (claimEntity.ObjectList[i].ObjectId) {
          objectList.push(claimEntity.ObjectList[i]);
        }
      }
    }
    claimEntity.ObjectList = objectList;
    claimEntity = this.actions.modifyClaimAddress(claimEntity);
    ClaimHandlingService.putClaimInfo(claimEntity).then(data => { });
  }

  rejectSubClaimSubmit(tmpobjectEntity) {
    const objectEntity = tmpobjectEntity;
    objectEntity.StatusCode = 'REJECT';
    let result;
    ClaimHandlingService.putSubclaim(objectEntity).then(data => {
      result = data.Model;
    });
    return result;
  }

  getClaimInfo(caseId) {
    ClaimHandlingService.getClaimInfo(caseId).then(data => {
      this.actions.handlingCodeTable(data);
      this.dispatch(data);
    });
  }

  getClaimDataInfo(caseId) {
    let claimEntity = null;
    ClaimHandlingService.getClaimInfo(caseId).then(data => {
      claimEntity = data;
    });
    return claimEntity;
  }

  newSubclaimSubmit(caseId, objectEntity) {
    let result;
    if (this.actions.checkSubclaim(objectEntity)) {
      ClaimHandlingService.postNewSubclaim(caseId, objectEntity).then(
        data => {
          if (data.Status == ClaimCstUI.REST_RESTPONSE_STATUS_OK) {
            result = data.Model;
            MessageHelper.success(
              ClaimMessageForamtUtils.formatI18NMsg(
                i18n.ClaimCommon.SubmitSuccess,
                `${
                i18n.ClaimCommon.New
                } ${i18n.ClaimCommon.Subclaim.toLowerCase()}`
              )
            );
            this.dispatch({
              objectEntity: result,
              claimData: this.actions.getClaimData(caseId),
            });
          }
        }
      );
    }
    return result;
  }

  checkNewSubClaimType(caseId, ObjectEntity) {
    let checkResult = true;
    let msg = '';
    const checkSubclaims = [];
    const msgSubClaimType = [];
    ClaimHandlingService.checkNewSubClaimType(
      caseId,
      ObjectEntity
    ).then(data => {
      if (data.Model) {
        data.Model.forEach((flag, index) => {
          const ifTrue = flag.split(';')[0];
          const subclaimtype = flag.split(';')[2];
          if (ifTrue === 'true') {
            if (
              checkSubclaims.indexOf(`${ifTrue}${subclaimtype}`) ===
              -1
            ) {
              checkSubclaims.push(`${ifTrue}${subclaimtype}`);
            } else if (msgSubClaimType.indexOf(subclaimtype) == -1) {
              msgSubClaimType.push(subclaimtype);
            }
          }
        });
        if (msgSubClaimType.length > 0) {
          msgSubClaimType.forEach((data, index) => {
            msg += `${data},`;
          });
          MessageHelper.error(
            i18n.ClaimCommon.CheckSubClaimTypeMsg + msg
          );
          checkResult = false;
        }
      }
    });
    return checkResult;
  }

  handleClaimEntity(tmpClaimEntity) {
    const ClaimEntity = tmpClaimEntity;
    delete ClaimEntity.cityCodeTable;
    delete ClaimEntity.postalCodeTable;
    delete ClaimEntity.streetCodeTable;
    delete ClaimEntity.townCodeTable;
    delete ClaimEntity.countryCodeTable;
    delete ClaimEntity.regions;
    delete ClaimEntity.IsMotor;
    return ClaimEntity;
  }

  checkChangeSubClaimType(tmpcaseForm) {
    const caseForm = tmpcaseForm;
    let checkResult = true;
    let msg = '';
    const checkSubclaims = [];
    const msgSubClaimType = [];
    if (
      caseForm.ClaimEntity.UnpaidReasonCode &&
      caseForm.ClaimEntity.UnpaidReasonCode.constructor == Array
    ) {
      const unpaidReason = JSON.stringify(
        caseForm.ClaimEntity.UnpaidReasonCode
      );
      caseForm.ClaimEntity.UnpaidReasonCode = unpaidReason;
    }
    if (
      caseForm.ClaimEntity.UnpaidResReasonCode &&
      caseForm.ClaimEntity.UnpaidResReasonCode.constructor == Array
    ) {
      const unpaidReason = JSON.stringify(
        caseForm.ClaimEntity.UnpaidResReasonCode
      );
      caseForm.ClaimEntity.UnpaidResReasonCode = unpaidReason;
    }

    const caseFormBak = jQuery.extend(true, {}, caseForm);
    delete caseFormBak.ClaimEntity.CodeTableInsured;
    delete caseFormBak.ClaimEntity.CodeTableCauseOfLoss;
    delete caseFormBak.ClaimEntity.CodeTableSubclaimType;
    delete caseFormBak.ClaimEntity.CodeTableDamageType;
    delete caseFormBak.ClaimEntity.CodeTableClaimant;
    delete caseFormBak.ClaimEntity.CodeTableClaimOwner;

    delete caseFormBak.ClaimEntity.CountryCode;
    delete caseFormBak.ClaimEntity.City;
    delete caseFormBak.ClaimEntity.Area;
    delete caseFormBak.ClaimEntity.StreetName;
    delete caseFormBak.ClaimEntity.PostCode;

    caseFormBak.ClaimEntity = this.actions.handleClaimEntity(
      caseFormBak.ClaimEntity
    );

    ClaimHandlingService.checkChangeSubClaimType(caseFormBak).then(
      data => {
        if (data.Model) {
          data.Model.forEach((flag, index) => {
            const ifTrue = flag.split(';')[0];
            const subclaimtype = flag.split(';')[2];
            if (ifTrue === 'true') {
              if (
                checkSubclaims.indexOf(`${ifTrue}${subclaimtype}`) ==
                -1
              ) {
                checkSubclaims.push(`${ifTrue}${subclaimtype}`);
              } else if (
                msgSubClaimType.indexOf(subclaimtype) == -1
              ) {
                msgSubClaimType.push(subclaimtype);
              }
            }
          });
          if (msgSubClaimType.length > 0) {
            msgSubClaimType.forEach((data, index) => {
              msg += `${data},`;
            });
            MessageHelper.error(
              i18n.ClaimCommon.CheckSubClaimTypeMsg + msg
            );
            checkResult = false;
          }
        }
      }
    );
    return checkResult;
  }

  checkSubclaim(tmpobjectEntity) {
    const objectEntity = tmpobjectEntity;
    //let subclaimInitLossIsEmpty = false;
    let noCoverageSelected = true;
    objectEntity.RentalCarCompany = objectEntity.RentalCarCompanyId;
    delete objectEntity.RentalCarCompanyId;
    $(objectEntity.PolicyCoverageList).each((index, data) => {
      if (data.Selected == '1') {
        noCoverageSelected = false;
        return false;
        // if (_.isEmpty(`${data.InitLossIndemnity}`)) {
        //   subclaimInitLossIsEmpty = true;
        //   return false;
        // }
      }
    });

    if (noCoverageSelected) {
      MessageHelper.error(i18n.ClaimCommon.NoCoverageSelectedMessage);
      return false;
    }
    // if (subclaimInitLossIsEmpty) {
    //   MessageHelper.error(i18n.ClaimCommon.SubclaimInitLossIsEmpty);
    //   return false;
    // }

    return true;
  }

  getClaimData(caseId) {
    let result;
    ClaimHandlingService.loadStatus(caseId).then(data => {
      result = data;
    });
    return result;
  }

  updateClaimPartyList(claimParty, tmpcaseEntity, subclaimIndex) {
    const caseEntity = tmpcaseEntity;
    let isAdd = true;
    $(caseEntity.ClaimPartyList).each((index, data) => {
      if (claimParty.PtyPartyId == data.PtyPartyId) {
        this.actions.copyClaimPartyInfo(
          claimParty,
          caseEntity.ClaimPartyList[index]
        );
        isAdd = false;
        return false;
      }
    });
    caseEntity.ClaimPartyList = caseEntity.ClaimPartyList || [];
    if (isAdd) {
      caseEntity.ClaimPartyList.push(claimParty);
      if (subclaimIndex) {
        caseEntity.ObjectList[subclaimIndex].ClaimParty = claimParty;
        caseEntity.ObjectList[subclaimIndex].ClaimantId =
          claimParty.PartyId;
      }
    } else {
      if (
        claimParty.PtyPartyId ==
        caseEntity.PolicyHolderParty.PtyPartyId
      ) {
        this.actions.copyClaimPartyInfo(
          claimParty,
          caseEntity.PolicyHolderParty
        );
      }

      $(caseEntity.ObjectList).each((index, datas) => {
        const data = datas;
        if (data.ClaimParty.PtyPartyId == claimParty.PtyPartyId) {
          this.actions.copyClaimPartyInfo(
            claimParty,
            data.ClaimParty
          );
          data.ClaimParty = claimParty;
        }
      });
    }
    caseEntity.CodeTableClaimant = this.actions.getCodeTableClaimant(
      caseEntity.ClaimPartyList
    );

    if (subclaimIndex) {
      DataContext.put(`ClaimantId${subclaimIndex}`, claimParty.PartyId);
    }

    this.dispatch(caseEntity);
    return caseEntity;
  }

  copyClaimPartyInfo(fromData, tmptoData) {
    const toData = tmptoData;
    toData.PartyName = fromData.PartyName;
    toData.PtyAccountId = fromData.PtyAccountId;
    toData.PtyAddressId = fromData.PtyAddressId;
  }

  dealWithOutPolicy(tmppolicyInfo, caseId) {
    const policyInfo = tmppolicyInfo;
    policyInfo.CaseId = caseId;
    this.dispatch(policyInfo);
  }

  getExchangeRate2LocalCurrency(currencyCode) {
    let rate = null;
    ClaimHandlingService.getExchangeRate2LocalCurrency(
      currencyCode
    ).then(
      data => {
        rate = data;
      },
      err => {
        MessageHelper.error(err, '');
      }
    );
    return rate;
  }

  submitWithOutTaskAssigned(eventType) {
    this.dispatch({ eventType });
  }

  submitWithOutPolicy(retrievePolicy, caseId, initReserve, callback) {
    const _self = this;
    let result = false;
    ClaimHandlingService.submitWithOutPolicy(
      retrievePolicy,
      caseId
    ).then(
      data => {
        _self.actions.getClaimInfo(caseId);
        const objectId = sessionStorage.getItem('claim_objectId');
        if (!_.isEmpty(objectId)) {
          initReserve(objectId);
        }
        MessageHelper.success(
          '',
          i18n.ClaimCommon.RetrievePolicySuccessfully
        );
        result = true;

        if (callback) callback.call();
        _self.actions.submitWithOutTaskAssigned(
          'WithOutPolicyInsuredDialog'
        );
      },
      err => {
        MessageHelper.error(err, '');
      }
    );
    return result;
  }

  getCurrentUserInfo() {
    let result = null;
    ClaimHandlingService.getCurrentUserInfo().then(
      data => {
        result = data;
      },
      err => { }
    );

    return result;
  }

  initClaimSummary(event) {
    const caseId = event.getParameter('caseId');
    if (caseId != null && caseId !== '') {
      ClaimHandlingService.getClaimInfo(caseId).then(data => {
        this.actions.handlingCodeTable(data);
        this.dispatch(data);
      });
    }
  }
}
export default alt.createActions(ClaimHandlingAction);
