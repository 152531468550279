import {
  AjaxUtil,
  DataContext,
  UIPickList,
  UpdateContext,
} from 'RainbowUI';
import ReserveRelationService from '../service/ReserveRelationService';
import TreeNode from '../constant/TreeNodeCache';

const Url = require('../url/Url');
const ProductCfgTreeAction = require('./ProductCfgTreeAction');

export default {
  getClmReserveRelation(pageindex, pagesize) {
    const data = TreeNode.TreeCache.get('TreeNode');
    const productLineCodeJS = {
      productLineCode: data.treeNodeCode,
    };
    const searchCondition = {};
    searchCondition.ProductLineCode =
      productLineCodeJS.productLineCode;
    searchCondition.PageNo = pageindex - 1;
    searchCondition.PageSize = pagesize;
    const url =
      Url.SubclaimCoverageCategoryReserveBySubclaimTypeCodeAndCoverageCategoryCode;
    if (DataContext.get('searchReserveTypeCondition')) {
      const condition = DataContext.get('searchReserveTypeCondition');
      if (condition.subclaimTypeCode) {
        searchCondition.SubclaimTypeCode = condition.subclaimTypeCode
          .replace(/(^\s*)|(\s*$)/g, '')
          .toUpperCase();
      }
      if (condition.coverageCategoryCode) {
        searchCondition.CoverageCategoryCode = condition.coverageCategoryCode
          .replace(/(^\s*)|(\s*$)/g, '')
          .toUpperCase();
      }
    }
    let result = {};
    AjaxUtil.doPost(url, searchCondition, {
      async: false,
      done(data) {
        result = data.Model;
      },
      fail() {
        MessageHelper.error(
          i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
        );
      },
    });

    return { count: result.Total, result: result.Content };
  },
  getPickList(entity) {
    const data = ReserveRelationService.loadRelation(entity);

    const pickList = [];
    let pickListItem = {};
    const selectList = data.SelectedList;
    const noSelectList = data.NoSelectedList;
    for (const item in selectList) {
      pickListItem = {
        value: selectList[item].Desc.ReserveTypeCode,
        label: `${selectList[item].Desc.ReserveTypeCode}:${selectList[item].Desc.ReserveTypeName}`,
        selected: true,
      };
      pickList.push(pickListItem);
    }
    for (const item in noSelectList) {
      pickListItem = {
        value: noSelectList[item].ReserveTypeCode,
        label: `${noSelectList[item].ReserveTypeCode}:${noSelectList[item].ReserveTypeName}`,
        selected: false,
      };
      pickList.push(pickListItem);
    }
    return pickList;
  },
  openSubclaimCoverageReserveRelation(event) {
    const subclaimTypeCode = event.getParameter('subclaimTypeCode');
    const coverageTypeCode = event.getParameter('coverageTypeCode');
    const productLineCode = event.getParameter('productLineCode');
    const coverageTypeName = event.getParameter('coverageTypeName');
    const subclaimTypeName = event.getParameter('subclaimTypeName');
    const nodeName = event.getParameter('nodeName');

    const entity = {
      subclaimTypeCode,
      coverageTypeCode,
      subclaimTypeName,
      coverageTypeName,
      treeNodeCode: productLineCode,
      treeNodeUrl: 'reserveTypeRelation',
      name: nodeName,
    };

    ProductCfgTreeAction.loadNode(event, entity.treeId, entity);
  },
  saveSubclaimCoverageReserve(event) {
    const param = event.getParameter('param');
    const productLineCode = param.treeNodeCode;
    const { coverageTypeCode } = param;
    const { subclaimTypeCode } = param;
    const entity = {
      SubclaimTypeCode: subclaimTypeCode,
      CoverageTypeCode: coverageTypeCode,
      ProductLineCode: productLineCode,
    };
    const selectList = UIPickList.getTargetList(
      'SubclaimCoverageReserveRelationPickList'
    );
    const saveList = [];
    let saveObj = {};
    for (const item in selectList) {
      saveObj = {
        ProductLineCode: productLineCode,
        SubclaimTypeCode: subclaimTypeCode,
        CoverageTypeCode: coverageTypeCode,
        ReserveTypeCode: selectList[item],
      };
      saveList.push(saveObj);
    }
    const claimRelationData = {
      ClaimPrdReserveRelationEntity: entity,
      ClaimPrdReserveRelationList: saveList,
    };

    ReserveRelationService.updateCoverageCategoryReserve(
      claimRelationData
    );
    param.treeNodeUrl = 'reserveType';

    ProductCfgTreeAction.loadNode(event, param.treeId, param);
  },
  cancelSubclaimCoverageReserve(event) {
    const param = event.getParameter('param');
    param.treeNodeUrl = 'reserveType';
    ProductCfgTreeAction.loadNode(event, param.treeId, param);
  },

  searchReserveTypeList(condition) {
    DataContext.put('searchReserveTypeCondition', condition);
    UpdateContext.forceUpdate('subClaimCoverageReserveListProvider');
  },
};
