import {
  UIPanel,
  UISmartPanelGrid,
  UIText,
  UIBox,
  UIButton,
  UILink,
} from 'RainbowUI';
import BindToMixin from 'react-binding';
import CodeTableAction from '../../action/CodeTableAction';

const SimpleQueryCriteria = React.createClass({
  mixins: [BindToMixin],

  getInitialState() {
    return { claimSimpleSearchCriteria: {} };
  },

  updateState() {
    this.props.parentComponet.storeChangeStatus('Complex');
  },

  queryClaim() {
    const { claimSimpleSearchCriteria } = this.state;

    const FuzzyConditions = {};
    const OrConditions = {};
    const user = CodeTableAction.renderUser();
    // if (user.DirectUser !== 'Y') {
    //   FuzzyConditions.PermitOrgId = `,${user.OrgId},`;
    // }
    this.props.parentComponet.childStateChange({
      Conditions: claimSimpleSearchCriteria,
      OrConditions,
      FuzzyConditions,
    });
  },
  clear() {
    this.setState({ claimSimpleSearchCriteria: {} });
    this.props.clearResult.call();
  },

  render() {
    self = this;
    return (
      <UIPanel panelTitle={i18n.ClaimCommon.ClaimQuery}>
        <UISmartPanelGrid column="2">
          <UIText
            id="qSimpleText"
            valueLink={this.bindToState(
              'claimSimpleSearchCriteria',
              'text'
            )}
          />
          <br />

          <UIBox direction="left">
            <UIButton
              value={i18n.ClaimCommon.Search}
              onClick={self.queryClaim}
            />
            <UIButton
              value={i18n.ClaimCommon.Reset}
              onClick={self.clear}
            />
          </UIBox>

          <UIBox direction="right">
            <UILink
              value={i18n.ClaimCommon.ShowSearchOptions}
              onClick={self.updateState}
            />
          </UIBox>
        </UISmartPanelGrid>
      </UIPanel>
    );
  },
});
export default SimpleQueryCriteria;
