const urlDefine = {
  RegistrationUpdate: `${env.serverUrl}registration/saveClaim`,
  RegistrationSubmit: `${env.serverUrl}registration/submitClaim`,
  CheckSubClaimType: `${env.serverUrl}claimhandling/subClaimType/`,
  ClaimCatastropheActiveCode: `${env.serverUrl}common/accident`,
  SaveLastReviewClaim: `${env.serverUrl}registration/saveLastReviewClaim?caseId=`,
  EXChangeRate: `${env.serverUrl}registration/exchangeRate/`,
  localExchangeRate: `${env.serverUrl}util/localExchangeRate/`,

  PartyLoad: `${env.serverUrl}party/party/party/byPartyId?partyId=`,

  ClaimHandlingCancelNewSubclaim: `${env.serverUrl}claimhandling/cancelNewSubclaim/`,
  PartyCommonUrl: `${env.PARTY_HOME_URL}`,
};
export default urlDefine;
