import { UIDialog, UIPanel, DataContext } from 'RainbowUI';
import {
  PolicyInfo,
  PolicySearchDialog,
  PolicyDetailDialog,
  ClaimHandlingAction,
} from 'ClaimCommon';
import ClaimRegistration from 'ClaimRegistration';
import ClaimProduct from 'ClaimProduct';

const ProductDialogAction = ClaimProduct.getProductDialogAction();

const RegistrationPolicyDialog = React.createClass({
  showDialog() {
    UIDialog.show('registrationPolicy_dialog');
  },

  updatePolicyInfo(policyInfo) {
    if (this.props.isWithOutPolicy) {
      ClaimHandlingAction.dealWithOutPolicy(
        policyInfo,
        this.props.CaseId
      );
      UIDialog.hide('registrationPolicy_dialog');
      this.refs.WithOutPolicyInsuredDialog.showDialog(
        policyInfo,
        this.props.parentComponent.state
      );
    } else {
      this.props.parentComponent.updatePolicyInfo(policyInfo);
      UIDialog.hide('registrationPolicy_dialog');
    }
  },

  getRegistrationTime() {
    return this.props.parentComponent.state.caseEntity.RegisterTime;
  },
  getParentComponent() {
    return this;
  },
  render() {
    const WithOutPolicyInsuredDialog = ClaimRegistration.getWithOutPolicyInsuredDialog();
    const {
      productCode,
      parentComponent: {
        state: { caseEntity },
      },
    } = this.props;
    ProductDialogAction.getProduct(productCode);
    const result = DataContext.get('showProduct');
    let productTypeCode;
    if (result) {
      productTypeCode = result.ProductTypeCode;
    }
    return (
      <div>
        <UIDialog
          id="registrationPolicy_dialog"
          title={i18n.ClaimCommon.PolicyInfo}
          width="95%"
        >
          <UIPanel
            panelTitle={i18n.ClaimCommon.PolicyInfo}
            styleClass="default"
          >
            <PolicyInfo
              ref="PolicyInfo"
              from="registration"
              data={caseEntity}
              existNoticeNo
              parentComponent={this}
              productCode={productCode}
            />
          </UIPanel>
        </UIDialog>
        <PolicySearchDialog
          ref="PolicySearchDialog"
          parentComponent={this}
          productDisabled
          productTypeCode={productTypeCode}
        />
        <PolicyDetailDialog
          ref="PolicyDetailDialog"
          parentComponent={this}
        />
        <WithOutPolicyInsuredDialog ref="WithOutPolicyInsuredDialog" />
      </div>
    );
  },
});
export default RegistrationPolicyDialog;
