import { UICell } from 'RainbowUI';
import ClaimCommonExt from 'ClaimCommonExt';
import ClaimWorkFlowRight from './ClaimWorkFlowRight';
import BaseComponent from './BaseComponent';

const ClaimWorkFlowLeft = ClaimCommonExt.getClaimWorkFlowLeft();

export default class ClaimWorkFlow extends BaseComponent {
  render() {
    return (
      <div style={{ paddingTop: '15px' }}>
        <UICell width="3">
          <ClaimWorkFlowLeft />
        </UICell>
        <UICell width="9">
          <ClaimWorkFlowRight
            menuDefinitions={this.props.menuDefinitions}
          />
        </UICell>
      </div>
    );
  }
}
