import { MessageHelper, DataContext, UpdateContext } from 'RainbowUI';
import DesKTopService from '../../service/DesKTopService';
import alt from '../../alt';

class ClaimWorkFlowLeftAction {
  onClickPendingTaskItemLeft(event) {
    const data = event.getParameter('data');
    DataContext.put('myPage', data);

    UpdateContext.forceUpdate('claimWorkFlowRightId');
  }

  onClickPendingNoticeLeft() {
    window.location.hash = '#/notice/pool';
  }

  pendingNoticeCount() {
    let result = null;
    DesKTopService.pendingNoticeCount().then(
      data => {
        result = data;
      },
      ex => {
        if (ex.status == 401 || ex.status == 403) {
          MessageHelper.error(i18n.ClaimCommon.ReLogin);
        } else {
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
        }
      }
    );
    return result;
  }

  pendingTaskCount() {
    let result = null;
    DesKTopService.pendingTaskCount().then(
      data => {
        result = data;
      },
      ex => {
        if (ex.status == 401 || ex.status == 403) {
          MessageHelper.error(i18n.ClaimCommon.ReLogin);
        } else {
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
        }
      }
    );
    return result.Model;
  }

  sharingPoolCount() {
    let result = null;
    DesKTopService.sharingPoolCount().then(
      data => {
        result = data;
      },
      ex => {
        if (ex.status == 401 || ex.status == 403) {
          MessageHelper.error(i18n.ClaimCommon.ReLogin);
        } else {
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
        }
      }
    );
    return result.Model;
  }

  myClaimCount() {
    let result = null;
    DesKTopService.myClaimCount().then(
      data => {
        result = data;
      },
      ex => {
        if (ex.status == 401 || ex.status == 403) {
          MessageHelper.error(i18n.ClaimCommon.ReLogin);
        } else {
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
        }
      }
    );
    return result.Model;
  }

  newMessageCount(date_to) {
    let result = null;
    const condition = {};
    condition.Status = '100007';
    condition.DueDate_from = '2000-01-01';
    condition.DueDate_to = date_to;
    DesKTopService.newMessageCount(condition).then(
      data => {
        result = data;
      },
      ex => {
        if (ex.status == 401 || ex.status == 403) {
          MessageHelper.error(i18n.ClaimCommon.ReLogin);
        } else {
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
        }
      }
    );
    return result;
  }

  initCondition(data) {
    this.dispatch(data);
  }

  pageChange(data) {
    this.dispatch(data);
    return 'OK';
  }
}
export default alt.createActions(ClaimWorkFlowLeftAction);
