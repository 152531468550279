import {
  UIConfirmDialog,
  MessageHelper,
  Caller,
  AjaxUtil,
  UpdateContext,
  UIDropZone,
} from 'RainbowUI';

import UrlConfig from '../../../UrlConfig';

const DocumentAction = {
  queryDocumentsList(moduleName, policyId) {
    const preurl = UrlConfig.queryDocList.replace(
      '{ModuleName}',
      moduleName
    );
    const url = preurl.replace('{PolicyId}', policyId);

    let result = {};

    Caller.call(url, null, {
      method: 'GET',
      async: false,
      block: true,
    }).then(
      data => {
        result = data;
      },
      () => {
        MessageHelper.error(
          i18n.PartyMsg.ErrorMsg.Error_0006,
          i18n.PartyMsg.ErrorMsg.Error_0005,
          MessageHelper.POSITION_TOP_RIGHT
        );
      }
    );
    for (let i = 0; i < result.length; i += 1) {
      result[i].DocumentSize = `${(
        result[i].DocumentSize / 1024
      ).toFixed(2)}KB`;
    }
    return result;
  },

  downloadFileFromDMS(id) {
    const url = UrlConfig.downloadFileFromDMS.replace('{id}', id);
    let result = {};

    Caller.call(url, null, {
      method: 'GET',
      async: false,
      block: true,
    }).then(
      data => {
        result = data;
      },
      () => {
        MessageHelper.error(
          i18n.PartyMsg.ErrorMsg.Error_0006,
          i18n.PartyMsg.ErrorMsg.Error_0005,
          MessageHelper.POSITION_TOP_RIGHT
        );
      }
    );
    return result;
  },

  onCompleteUpload() {
    UpdateContext.forceUpdate(
      'DocumentSearchResult, documentForm_updatePanel'
    );
    MessageHelper.success(i18n.PartyMsg.SuccessMsg.Success_0002);
  },

  onUpload(documentModel) {
    const fileList = UIDropZone.getAcceptedFiles('dropZone');
    const { ModuleName } = documentModel;
    const { ObjectId } = documentModel;
    let fileSizeAll = 0;
    if (fileList.length === 0) {
      MessageHelper.error(i18n.PartyMsg.ErrorMsg.Error_0007);
    } else {
      for (let i = 0; i < fileList.length; i += 1) {
        const fileName = fileList[i].name;
        const result = DocumentAction.checkDuplicateFileName(
          ModuleName,
          fileName
        );
        if (result) {
          for (let j = 0; j < result.length; j += 1) {
            const dmsName = result[j].Name;
            const dmsId = result[j].TransactionNo;
            if (ObjectId == dmsId) {
              if (fileName == dmsName) {
                UIConfirmDialog.show('duplicateCheck');
                return;
              }
            }
          }
        }
        fileSizeAll += fileList[i].size;
      }
      fileSizeAll = Number((fileSizeAll / 1024).toFixed(2));

      const exitResult = DocumentAction.queryDocumentsList(
        ModuleName,
        ObjectId
      );
      let exitFileSizeAll = 0;
      for (let i = 0; i < exitResult.length; i += 1) {
        exitFileSizeAll =
          Number(exitFileSizeAll) +
          Number(
            exitResult[i].DocumentSize.substr(
              0,
              exitResult[i].DocumentSize.length - 2
            )
          );
      }
      if (exitFileSizeAll + fileSizeAll > 25 * 1024) {
        MessageHelper.error(
          i18n.PartyMsg.ErrorMsg.Error_0012,
          '',
          MessageHelper.POSITION_TOP_RIGHT
        );
        return;
      }
      const { DocumentType } = documentModel;
      const { DocumentSubType } = documentModel;

      const { TransactionType } = documentModel;

      const { Remark } = documentModel;

      const uploadUrl = `${
        UrlConfig.uploadFileToDMS
      }/${ModuleName}/${TransactionType}/${DocumentType}/${DocumentSubType}/${ObjectId}?remark=${encodeURIComponent(
        Remark
      )}`;

      MessageHelper.success(
        i18n.PartyMsg.SuccessMsg.Success_0003,
        null,
        MessageHelper.POSITION_TOP_RIGHT
      );
      UIDropZone.getDropZone('dropZone').options.url = uploadUrl;

      UIDropZone.upload('dropZone');
    }
  },

  removeFileFromDMS(event) {
    const id = event.getParameter('item').Id;
    const url = UrlConfig.removeFileFromDMS.replace('{id}', id);
    let result = {};

    Caller.call(url, null, {
      method: 'GET',
      async: false,
      block: true,
    }).then(
      data => {
        result = data;
      },
      () => {
        MessageHelper.error(
          i18n.PartyMsg.ErrorMsg.Error_0006,
          i18n.PartyMsg.ErrorMsg.Error_0005,
          MessageHelper.POSITION_TOP_RIGHT
        );
      }
    );
    UpdateContext.forceUpdate(
      'DocumentSearchResult, documentForm_updatePanel'
    );
    return result;
  },

  checkDuplicateFileName(moduleName, fileName) {
    const preurl = UrlConfig.queryByModuleAndFileName.replace(
      '{ModuleName}',
      moduleName
    );
    const url = preurl.replace('{Name}', fileName);
    let result = {};

    Caller.call(url, null, {
      method: 'GET',
      async: false,
      block: true,
    }).then(
      data => {
        result = data;
      },
      () => {
        MessageHelper.error(
          i18n.PartyMsg.ErrorMsg.Error_0006,
          i18n.PartyMsg.ErrorMsg.Error_0005,
          MessageHelper.POSITION_TOP_RIGHT
        );
      }
    );
    return result;
  },

  previewFilesFromDMS(id) {
    const preurl = UrlConfig.previewFileFromDMS + id;
    const url = preurl.replace('{id}', id);
    const policyDefer = $.Deferred();
    AjaxUtil.doGet(url, null, {
      async: false,
      done(data) {
        policyDefer.resolve(data);
      },
      fail(err) {
        policyDefer.reject(err);
        MessageHelper.error(err.statusText, i18n.Error);
      },
    });

    return policyDefer.promise();
  },
};
export default DocumentAction;
