import React from 'react';
import PropTypes from 'prop-types';
import BindToMixin from 'react-binding';

const SalvageReadOnlyStore = require('../store/SalvageReadOnlyStore');
const SalvageLoadAction = require('../action/SalvageLoadAction');
const SalvageInfo = require('./SalvageInfo');

const SalvageReadOnly = React.createClass({
  mixins: [BindToMixin],
  propTypes: {
    salvageId: PropTypes.string.isRequired,
    isApproveTask: PropTypes.bool,
  },
  getInitialState() {
    return {
      SalvageReadOnly: {
        SalvageInfo: { SalvageItem: [] },
        OtherInfo: {},
      },

      SalvageApprove: {
        SalvageInfo: { SalvageItem: [] },
        OtherInfo: {},
      },
    };
  },

  componentDidMount() {
    SalvageLoadAction.loadSalvageReadOnly(
      this.props.salvageId,
      this.props.isApproveTask
    );
    SalvageReadOnlyStore.listen(this.onStoreStateChange);
  },

  componentWillUnMount() {
    SalvageReadOnlyStore.unlisten(this.onStoreStateChange);
  },

  onStoreStateChange(storeState) {
    if (this.props.isApproveTask == true) {
      if (!_.isEmpty(storeState.SalvageApprove)) {
        this.setState({ SalvageApprove: storeState.SalvageApprove });
      }
    } else if (!_.isEmpty(storeState.SalvageReadOnly)) {
      this.setState({
        SalvageReadOnly: storeState.SalvageReadOnly,
      });
    }
  },

  render() {
    let salvageInfo = this.bindToState(
      'SalvageReadOnly',
      'SalvageInfo'
    );
    let otherInfo = this.bindToState('SalvageReadOnly', 'OtherInfo');
    const { isApproveTask, history } = this.props;
    const his = history;
    if (isApproveTask == true) {
      salvageInfo = this.bindToState('SalvageApprove', 'SalvageInfo');
      otherInfo = this.bindToState('SalvageApprove', 'OtherInfo');
    }
    if (_.isEmpty(salvageInfo.value)) {
      return <div />;
    }

    return (
      <div id={`SalvageReadOnly_${isApproveTask}_div`}>
        <SalvageInfo
          salvageInfo={salvageInfo}
          otherInfo={otherInfo}
          history={his}
          readOnly
          isApproveTask={isApproveTask}
        />
      </div>
    );
  },
});
export default SalvageReadOnly;
