import {
  UpdateContext,
  UICheckbox,
  UIDataTable,
  MessageHelper,
  UIColumn,
  UIBox,
  UIButton,
  UIPanel,
  UILink,
  UIParam,
  UISmartPanelGrid,
  UIText,
  UIDateTimePicker,
  UISelect,
  UIUpdatePanel,
} from 'RainbowUI';
import PartyAction from '../../../party/actions/PartyAction';
import DefinedCodeTable from '../../../party/codetable/CodeTable';
import PartyCreationDialog from '../../../party/views/PartyCreationDialog';
import UrlConfig from '../../../../UrlConfig';
import PartyConst from '../../../party/const/PartyConst';
import PtyIntegrationCreationDialog from './PtyIntegrationCreationDialog';

const PtyIntegrationSearchInfoNew = React.createClass({
  getInitialState() {
    let partyRoleSelectEnable = true;
    let partyCategorySelectEnable = true;
    let partyCategorySelectDefaultValue;
    let createBtnDisable = true;
    let editLinkVisiable = false;
    let partyRoleSelectDisable = false;
    let partyRoleSelectVisiable = true;
    let partyRole;

    const $dialog = this.props.parentDialog;
    console.log(
      `====json====${JSON.stringify($dialog.dialog_p)}`
    );

    if ($dialog && $dialog.dialog_p) {
      const params = $dialog.dialog_p;
      partyRoleSelectEnable = params.partyRoleSelectEnable;
      partyCategorySelectEnable = params.partyCategorySelectEnable;
      partyRole = params.partyRole;
      partyCategorySelectDefaultValue = params.partyCategory;
      editLinkVisiable = params.editLinkVisiable;
      createBtnDisable = params.createBtnDisable;
      partyRoleSelectDisable = params.partyRoleSelectDisable;
      partyRoleSelectVisiable = params.partyRoleSelectVisiable;
    }
    if (partyCategorySelectDefaultValue) {
      this.props.definedCondition.PartyType = partyCategorySelectDefaultValue;
    }

    return {
      definedCondition: this.props.definedCondition,
      conditions: this.props.conditions,
      fuzzyConditions: this.props.fuzzyConditions,
      orCondtions: this.props.orCondtions,
      isSearch: false,
      partyCategorySelectEnable,
      partyRoleSelectEnable,
      editLinkVisiable,
      createBtnDisable,
      partyRoleSelectDisable,
      partyRoleSelectVisiable,
      partyRole,
    };
  },

  render() {
    const { definedCondition, parentDialog } = this.props;
    const partyType = definedCondition.PartyType;
    let partyConditionRender;
    // 1: Individual; 2:Organization
    if (partyType == PartyConst.PARTY_TYPE_IND) {
      partyConditionRender = this.indiviPartyConditionRender();
    } else {
      partyConditionRender = this.orgPartyConditionRender();
    }
    let renderSearchResult;

    if (partyType == PartyConst.PARTY_TYPE_IND) {
      renderSearchResult = this.renderIndividualSearchResult();
    } else {
      renderSearchResult = this.renderOrgSearchResult();
    }

    let creationDialog = (
      <PartyCreationDialog
        ref="PartyCreationDialog"
        id="PartyCreationDialog"
        parentDialog={parentDialog}
      />
    );
    if (parentDialog) {
      creationDialog = (
        <PtyIntegrationCreationDialog
          ref="PartyCreationDialog"
          id="PartyCreationDialog"
          parentDialog={parentDialog}
        />
      );
    }
    return (
      <div id="testetee">
        <UIUpdatePanel id="conditionRenderTable">
          {partyConditionRender}
          {renderSearchResult}
        </UIUpdatePanel>
        {creationDialog}
      </div>
    );
  },

  partyCategoryChange(evnet) {
    this.resetHandler();
    if (evnet.newValue) {
      const party = {};
      party.PartyType = evnet.newValue;
    }
  },

  resetHandler() {
    this.setState({
      conditions: {},
      fuzzyConditions: {},
      orCondtions: {},
      isSearch: false,
    });
    UpdateContext.forceUpdate('conditionRenderTable');
  },
  searchHandler() {
    const { conditions } = this.state;
    if (conditions.Status) {
      if (conditions.Status == '0') {
        conditions.Status = 0;
      }
      if (conditions.Status == '1') {
        conditions.Status = 1;
      }
    }

    this.setState({ isSearch: true });
  },
  editHandler(clickEvent) {
    const selectData = UIDataTable.getSelectedRecord('PartyRecord');

    if (!selectData.length) {
      MessageHelper.warning(
        i18n.PartyMsg.WarnMsg.Warn_0005,
        i18n.PartyMsg.WarnMsg.Warn_0003,
        MessageHelper.POSITION_TOP_RIGHT
      );
      return;
    }
    let party = selectData[0];
    if (!_.isEmpty(clickEvent.paramJson)) {
      party = clickEvent.paramJson[0].value;
    }
    const { PartyId } = party;
    if (PartyId === null || PartyId === undefined || PartyId === '') {
      MessageHelper.error(
        i18n.PartyIdIsNull,
        i18n.PartyMsg.ErrorMsg.Error_0001,
        MessageHelper.POSITION_TOP_RIGHT
      );
    } else {
      window.location.href = `${UrlConfig.partyCommonUrl}#/createPartyMaintain/${PartyId}`;
    }
  },

  queryHandler(clickEvent) {
    let party = {};
    if (!_.isEmpty(clickEvent.paramJson)) {
      party = clickEvent.paramJson[0].value;
    }
    const { PartyId } = party;
    if (PartyId === null || PartyId === undefined || PartyId === '') {
      MessageHelper.error(
        i18n.PartyIdIsNull,
        i18n.PartyMsg.ErrorMsg.Error_0001,
        MessageHelper.POSITION_TOP_RIGHT
      );
    } else {
      window.location.href = `${UrlConfig.partyCommonUrl}#/readOnlyPartyMaintain/${PartyId}`;
    }
  },

  createHandler() {
    this.refs.PartyCreationDialog.showPartyCreateDialog();
  },

  indiviPartyConditionRender() {
    const {
      conditions,
      fuzzyConditions,
      partyRole,
      partyCategorySelectEnable,
      partyCategorySelectDefaultValue,
      partyRoleSelectVisiable,
      partyRoleSelectEnable,
      createBtnDisable,
    } = this.state;
    const _self = this;
    fuzzyConditions.RoleType = partyRole;
    return (
      <div>
        <UIPanel panelTitle={i18n.PartySearchCriteria}>
          <UISmartPanelGrid column="4">
            <UISelect
              label={i18n.PartyCategory}
              codeTable={DefinedCodeTable.getCategroyCodeTable}
              onChange={_self.partyCategoryChange}
              required="true"
              model={_self.state.definedCondition}
              property="PartyType"
              showBlankOption="false"
              widthAllocation="7,5"
              colspan="2"
              enabled={partyCategorySelectEnable}
              defaultValue={partyCategorySelectDefaultValue}
            />
            <UISelect
              label={i18n.PartyRole}
              codeTable={DefinedCodeTable.getIndPartyRole}
              model={fuzzyConditions}
              defaultValue={partyRole}
              enabled={partyRoleSelectEnable}
              property="RoleType"
              widthAllocation="7,5"
              colspan="2"
              visibled={partyRoleSelectVisiable}
            />
            <UIText
              label={i18n.PartyName}
              model={conditions}
              property="PartyName"
              widthAllocation="7,5"
            />
            <UICheckbox
              single="true"
              label={i18n.FuzzySearch}
              model={conditions}
              property="FuzzySearch"
              defaultValue="Y"
            />
            <UIText
              label={i18n.PartyFormerCode}
              model={conditions}
              property="PartyCode"
              widthAllocation="7,5"
              colspan="2"
            />
            <UIText
              label={i18n.IdNo}
              model={conditions}
              property="IdNumber"
              widthAllocation="7,5"
              colspan="2"
            />
            <UIDateTimePicker
              format="MM/DD/YYYY"
              label={i18n.BirthDay}
              model={conditions}
              property="DateOfBirth"
              widthAllocation="7,5"
              manualInput="true"
              colspan="2"
            />
            <UIText
              label={i18n.MobileHomeWorkNo}
              model={conditions}
              property="WorkHomeMobileTel"
              widthAllocation="7,5"
              colspan="2"
            />
            <UIText
              label={i18n.SSSEmployerId}
              model={conditions}
              property="EmployerId"
              widthAllocation="7,5"
              colspan="2"
            />
            <UIText
              label={i18n.PostalCode}
              model={conditions}
              property="PostCode"
              widthAllocation="7,5"
              colspan="2"
            />
          </UISmartPanelGrid>
          <UIBox direction="left">
            <UIButton
              value={i18n.Reset}
              onClick={_self.resetHandler}
            />
            <UIButton
              value={i18n.Search}
              onClick={_self.searchHandler}
              update="conditionRenderTable"
            />
            <UIButton
              value={i18n.Create}
              onClick={_self.createHandler}
              disabled={createBtnDisable}
              visibled={!createBtnDisable}
            />
          </UIBox>
        </UIPanel>
      </div>
    );
  },
  orgPartyConditionRender() {
    const _self = this;
    const {
      conditions,
      fuzzyConditions,
      partyRole,
      partyCategorySelectEnable,
      partyCategorySelectDefaultValue,
      partyRoleSelectVisiable,
      partyRoleSelectEnable,
      createBtnDisable,
    } = this.state;
    console.log('conditions', conditions);
    fuzzyConditions.RoleType = partyRole;
    return (
      <div>
        <UIPanel panelTitle={i18n.PartySearchCriteria}>
          <UISmartPanelGrid column="4">
            <UISelect
              label={i18n.PartyCategory}
              codeTable={DefinedCodeTable.getCategroyCodeTable}
              onChange={this.partyCategoryChange}
              required="true"
              model={_self.state.definedCondition}
              property="PartyType"
              showBlankOption="false"
              widthAllocation="7,5"
              colspan="2"
              enabled={partyCategorySelectEnable}
              defaultValue={partyCategorySelectDefaultValue}
            />
            <UISelect
              ref="PartyRoleSelect_ref"
              label={i18n.PartyRole}
              visibled={partyRoleSelectVisiable}
              codeTable={DefinedCodeTable.getOrgPartyRole}
              defaultValue={partyRole}
              enabled={partyRoleSelectEnable}
              model={fuzzyConditions}
              colspan="2"
              property="RoleType"
              widthAllocation="7,5"
            />
            <UIText
              label={i18n.PartyName}
              model={conditions}
              property="PartyName"
              widthAllocation="7,5"
            />
            <UICheckbox
              single="true"
              label={i18n.FuzzySearch}
              model={conditions}
              defaultValue="Y"
              property="FuzzySearch"
            />
            <UIText
              label={i18n.PartyFormerCode}
              model={conditions}
              property="PartyCode"
              colspan="2"
              widthAllocation="7,5"
            />
            <UIText
              label={i18n.CompanyNo}
              model={conditions}
              property="OrganizationIdNumber"
              colspan="2"
              widthAllocation="7,5"
            />
            <UIText
              label={i18n.MobileWorkNo}
              model={conditions}
              colspan="2"
              property="MobileWorkTel"
              widthAllocation="7,5"
            />
            <UIText
              label={i18n.SSSEmployerId}
              model={conditions}
              colspan="2"
              property="EmployerId"
              widthAllocation="7,5"
            />
            <UIText
              label={i18n.PostalCode}
              model={conditions}
              colspan="2"
              property="PostCode"
              widthAllocation="7,5"
            />
          </UISmartPanelGrid>
          <UIBox direction="left">
            <UIButton
              value={i18n.Reset}
              onClick={this.resetHandler}
            />
            <UIButton
              value={i18n.Search}
              onClick={this.searchHandler}
              update="conditionRenderTable"
            />
            <UIButton
              value={i18n.Create}
              onClick={this.createHandler}
              disabled={createBtnDisable}
              visibled={!createBtnDisable}
            />
          </UIBox>
        </UIPanel>
      </div>
    );
  },
  renderIndividualSearchResult() {
    const _self = this;
    const { editLinkVisiable } = this.state;
    return (
      <UIPanel panelTitle={i18n.PartyResult}>
        <UIBox direction="right">
          <UILink
            icon="fa fa-pencil"
            size="lg"
            tooltip={i18n.Edit}
            iconClass="edit_iconbtn"
            onClick={_self.editHandler}
            visibled={editLinkVisiable}
          />
        </UIBox>
        <UIDataTable
          provider={_self.getIndPartyResult}
          selectionMode="single"
          indexable="false"
          id="PartyRecord"
        >
          <UIColumn headerTitle={i18n.PartyCode} value="PartyCode" />
          <UIColumn
            headerTitle={i18n.FormerPartyCode}
            value="PartyFormerCode"
          />
          <UIColumn
            headerTitle={i18n.PartyName}
            render={data => {
              return (
                <div>
                  <UILink
                    value={data.PartyName}
                    onClick={_self.queryHandler}
                  >
                    <UIParam name="data" value={data} />
                  </UILink>
                </div>
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.IdType}
            value="IdType"
            render={data => {
              return (
                <UISelect
                  io="out"
                  model={data}
                  property="IdType"
                  codeTable={DefinedCodeTable.IdType}
                />
              );
            }}
          />
          <UIColumn headerTitle={i18n.IdNo} value="IdNumber" />
          <UIColumn headerTitle={i18n.Gender} value="Gender">
            <UISelect
              codeTable={DefinedCodeTable.getGenderCodeTable()}
              io="out"
            />
          </UIColumn>
          <UIColumn
            headerTitle={i18n.PartyRoleStatus}
            render={data => {
              if (data.PartyRoleVoList) {
                if (
                  PartyConst.PARTY_ROLE_CODE_IND_PRODUCER ==
                  data.PartyRoleVoList[0].RoleType ||
                  PartyConst.PARTY_ROLE_CODE_ORG_PRODUCER ==
                  data.PartyRoleVoList[0].RoleType
                ) {
                  return (
                    <UISelect
                      codeTable={
                        DefinedCodeTable.getPartyRoleProducerStatus
                      }
                      io="out"
                      value={data.PartyRoleStatus}
                    />
                  );
                }
                return (
                  <UISelect
                    codeTable={DefinedCodeTable.getPartyStatus}
                    io="out"
                    value={data.PartyRoleStatus}
                  />
                );
              }
              return (
                <UISelect
                  codeTable={DefinedCodeTable.getPartyStatus}
                  io="out"
                  value={data.PartyRoleStatus}
                />
              );
            }}
          />
        </UIDataTable>
      </UIPanel>
    );
  },

  renderOrgSearchResult() {
    const _self = this;
    const { editLinkVisiable } = this.state;
    return (
      <UIPanel panelTitle={i18n.PartyResult}>
        <UIBox direction="right">
          <UILink
            icon="fa fa-pencil"
            size="lg"
            tooltip={i18n.Edit}
            iconClass="edit_iconbtn"
            onClick={_self.editHandler}
            visibled={editLinkVisiable}
          />
        </UIBox>
        <UIDataTable
          provider={_self.getOrgPartyResult}
          selectionMode="single"
          indexable="false"
          id="PartyRecord"
        >
          <UIColumn headerTitle={i18n.PartyCode} value="PartyCode" />
          <UIColumn
            headerTitle={i18n.FormerPartyCode}
            value="PartyFormerCode"
          />
          <UIColumn
            headerTitle={i18n.PartyName}
            render={data => {
              return (
                <div>
                  <UILink
                    value={data.PartyName}
                    onClick={_self.queryHandler}
                  >
                    <UIParam name="data" value={data} />
                  </UILink>
                </div>
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.CompanyNo}
            value="OrganizationIdNumber"
          />
          <UIColumn
            headerTitle={i18n.CountryDom}
            render={data => {
              return (
                <UISelect
                  model={data}
                  property="Country"
                  codeTable={DefinedCodeTable.getCountry}
                  io="out"
                />
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.PartyRoleStatus}
            render={data => {
              if (data.PartyRoleVoList) {
                if (
                  PartyConst.PARTY_ROLE_CODE_IND_PRODUCER ==
                  data.PartyRoleVoList[0].RoleType ||
                  PartyConst.PARTY_ROLE_CODE_ORG_PRODUCER ==
                  data.PartyRoleVoList[0].RoleType
                ) {
                  return (
                    <UISelect
                      codeTable={
                        DefinedCodeTable.getPartyRoleProducerStatus
                      }
                      io="out"
                      value={data.PartyRoleStatus}
                    />
                  );
                }
                return (
                  <UISelect
                    codeTable={DefinedCodeTable.getPartyStatus}
                    io="out"
                    value={data.PartyRoleStatus}
                  />
                );
              }
              return (
                <UISelect
                  codeTable={DefinedCodeTable.getPartyStatus}
                  io="out"
                  value={data.PartyRoleStatus}
                />
              );
            }}
          />
        </UIDataTable>
      </UIPanel>
    );
  },
  getOrgPartyResult(pageindex, pagesize) {
    const { isSearch } = this.state;
    if (!isSearch) {
      return { count: 0, result: [] };
    }
    const partyAction = new PartyAction();
    const partyName = this.state.conditions.PartyName;
    const fuzzySearch = this.state.conditions.FuzzySearch;
    const mobileWorkTel = this.state.conditions.MobileWorkTel;
    const { conditions } = this.state;
    const { fuzzyConditions } = this.state;
    const orConditions = this.state.orCondtions;
    if (conditions.FuzzySearch === 'Y') {
      fuzzyConditions.PartyName = conditions.PartyName;
      delete conditions.PartyName;
    }
    if (conditions.MobileWorkTel) {
      orConditions.WorkTel = conditions.MobileWorkTel;
      orConditions.MobileTel = conditions.MobileWorkTel;
      delete conditions.MobileWorkTel;
    }
    delete conditions.FuzzySearch;
    const partyType = this.state.definedCondition.PartyType;
    const result = partyAction.searchPartyByConditions(
      conditions,
      orConditions,
      fuzzyConditions,
      partyType,
      pageindex,
      pagesize
    );
    conditions.PartyName = partyName;
    conditions.FuzzySearch = fuzzySearch;
    conditions.MobileWorkTel = mobileWorkTel;
    if (result) {
      const partyList = this.getPartyList(result.result);
      return { count: result.count, result: partyList };
    }
    return { count: 0, result: [] };
  },
  getIndPartyResult(pageindex, pagesize) {
    const { isSearch } = this.state;
    if (!isSearch) {
      return { count: 0, result: [] };
    }
    const partyAction = new PartyAction();
    const partyName = this.state.conditions.PartyName;
    const fuzzySearch = this.state.conditions.FuzzySearch;
    const workHomeMobileTel = this.state.conditions.WorkHomeMobileTel;
    const { conditions } = this.state;
    const { fuzzyConditions } = this.state;
    const orConditions = this.state.orCondtions;
    if (conditions.FuzzySearch === 'Y') {
      fuzzyConditions.PartyName = conditions.PartyName;
      delete conditions.PartyName;
    }
    if (conditions.WorkHomeMobileTel) {
      orConditions.WorkHomeTel = conditions.WorkHomeMobileTel;
      orConditions.MobileTel = conditions.WorkHomeMobileTel;
      delete conditions.WorkHomeMobileTel;
    }
    delete conditions.FuzzySearch;
    const partyType = this.state.definedCondition.PartyType;
    const result = partyAction.searchPartyByConditions(
      conditions,
      orConditions,
      fuzzyConditions,
      partyType,
      pageindex,
      pagesize
    );
    conditions.PartyName = partyName;
    conditions.FuzzySearch = fuzzySearch;
    conditions.WorkHomeMobileTel = workHomeMobileTel;
    if (result) {
      const partyList = this.getPartyList(result.result);
      return { count: result.count, result: partyList };
    }
    return { count: 0, result: [] };
  },
  getPartyList(data) {
    const _self = this;
    const partyList = data;
    _.each(partyList, temppartyVo => {
      const partyVo = temppartyVo;
      let partyRoleStatus;
      _.each(partyVo.PartyRoleVoList, partyRole => {
        console.log(`==partyRole.RoleType==${partyRole.RoleType}`);
        console.log(`==self.state.partyRole${_self.state.partyRole}`);
        if (partyRole.RoleType == _self.state.partyRole) {
          partyRoleStatus = partyRole.Status;
        }
      });
      partyVo.PartyRoleStatus = partyRoleStatus;
    });
    return partyList;
  },
});
export default PtyIntegrationSearchInfoNew;
