import { UITabItem } from 'RainbowUI';
import ClaimFieldInvestigationExt from 'ClaimInvestigationExt';
import ClaimAppraisalExt from 'ClaimAppraisalExt';

const FIProperty = ClaimFieldInvestigationExt.PropertyComponent;
const FIVehicle = ClaimFieldInvestigationExt.VehicleComponent;
const FIBodilyInjury =
  ClaimFieldInvestigationExt.BodilyInjuryComponent;
const { InvestigationAction } = ClaimFieldInvestigationExt;
const AppraisalProperty = ClaimAppraisalExt.getAppraisalProperty();
const AppraisalBodyInjury = ClaimAppraisalExt.getAppraisalBodyInjury();
const AppraisalVehicle = ClaimAppraisalExt.getAppraisalVehicle();
const AppraisaAction = ClaimAppraisalExt.getAppraisaAction();

let data = {};
let objectId;
let taskCode;
let taskInfo;
let damageType;
let claimNo;
let subClaim;
let policyInfo;

const context = {};

const FAtaskTab = p => {
  return new FAtaskTab.fn(p);
};

FAtaskTab.fn = function fn(p) {
  this.init(p);
};
FAtaskTab.fn.prototype.init = function init(p) {
  data = p;
  claimNo = data.claimNo;
  objectId = data.objectId;
  taskInfo = data.taskInfo;
  taskCode = taskInfo.TaskCode;
  damageType = data.subClaim.DamageType;
  subClaim = data.subClaim;
  policyInfo = data.policyInfo;
  //  fc = AssignmentAction.getFATaskRelationInfo(claimNo,'Y');

  context.FromSelf = this.fromSelfStrategy();
  context.FromOther = this.fromOtherStrategy();
};

FAtaskTab.fn.prototype.tabs = function tabs(s, defTabContent) {
  const self = this;
  const tabsVar = context[s] || [];
  console.info('FAtaskTab tabs ===== ', tabsVar);
  if (Array.isArray(tabsVar) && tabsVar.length > 0) {
    tabsVar.forEach(d => {
      const o = self[d].call(null, s.replace('From', ''));
      defTabContent.push(o);
    });
  }

  return defTabContent;
};

FAtaskTab.fn.prototype.taskPool1 = [
  'ClaimFIPropertyTask',
  'ClaimFIVehicleTask',
  'ClaimFIBodilyInjuryTask',
];
FAtaskTab.fn.prototype.taskPool2 = [
  'ClaimAppraisalPropertyTask',
  'ClaimAppraisalVehicleTask',
  'ClaimAppraisalBodilyInjuryTask',
];

FAtaskTab.fn.prototype.fromSelfStrategy = function fromSelfStrategy() {
  const arr = [];
  arr.push(taskCode);
  return arr;
};

FAtaskTab.fn.prototype.fromOtherStrategy = function fromOtherStrategy() {
  const arr = [];
  const b = ['P', 'V', 'I'];
  const index = b.indexOf(damageType);
  if (index < 0) return arr;
  const isInvestigationClosed = InvestigationAction.getClosedTasks(
    objectId
  );
  if (objectId != null && isInvestigationClosed == true) {
    console.info('show investigation tab');
    arr.push(this.taskPool1[index]);
  }

  const isAppraisalClosed = AppraisaAction.getClosedTasks(objectId);
  if (objectId != null && isAppraisalClosed == true) {
    console.info('show appraisal tab');
    arr.push(this.taskPool2[index]);
  }
  return arr;
};

FAtaskTab.fn.prototype.ClaimFIPropertyTask = function ClaimFIPropertyTask(
  OpenFrom
) {
  return (
    <UITabItem
      title={i18n.ClaimFieldInvestigation.FieldInvestigation}
    >
      <FIProperty
        objectId={objectId}
        taskInfo={taskInfo}
        openForm={OpenFrom}
        policyInfo={policyInfo}
        subClaim={subClaim}
      />
    </UITabItem>
  );
};

FAtaskTab.fn.prototype.ClaimFIVehicleTask = function ClaimFIVehicleTask(
  OpenFrom
) {
  return (
    <UITabItem
      title={i18n.ClaimFieldInvestigation.FieldInvestigation}
    >
      <FIVehicle
        objectId={objectId}
        taskInfo={taskInfo}
        openForm={OpenFrom}
        policyInfo={policyInfo}
        subClaim={subClaim}
      />
    </UITabItem>
  );
};

FAtaskTab.fn.prototype.ClaimFIBodilyInjuryTask = function ClaimFIBodilyInjuryTask(
  OpenFrom
) {
  return (
    <UITabItem
      title={i18n.ClaimFieldInvestigation.FieldInvestigation}
    >
      <FIBodilyInjury
        objectId={objectId}
        taskInfo={taskInfo}
        openForm={OpenFrom}
        policyInfo={policyInfo}
        subClaim={subClaim}
      />
    </UITabItem>
  );
};

FAtaskTab.fn.prototype.ClaimAppraisalPropertyTask = function ClaimAppraisalPropertyTask(
  openFrom
) {
  return (
    <UITabItem title={i18n.ClaimAppraisal.Appraisal}>
      <AppraisalProperty
        objectId={objectId}
        taskInfo={taskInfo}
        openForm={openFrom}
        policyInfo={policyInfo}
        subClaim={subClaim}
      />
    </UITabItem>
  );
};

FAtaskTab.fn.prototype.ClaimAppraisalPApprovalTask =
  FAtaskTab.fn.prototype.ClaimAppraisalPropertyTask;

FAtaskTab.fn.prototype.ClaimAppraisalBodilyInjuryTask = function ClaimAppraisalBodilyInjuryTask(
  openFrom
) {
  return (
    <UITabItem title={i18n.ClaimAppraisal.Appraisal}>
      <AppraisalBodyInjury
        objectId={objectId}
        taskInfo={taskInfo}
        openForm={openFrom}
        policyInfo={policyInfo}
        subClaim={subClaim}
      />
    </UITabItem>
  );
};

FAtaskTab.fn.prototype.ClaimAppraisalIApprovalTask =
  FAtaskTab.fn.prototype.ClaimAppraisalBodilyInjuryTask;

FAtaskTab.fn.prototype.ClaimAppraisalVehicleTask = function ClaimAppraisalVehicleTask(
  OpenFrom
) {
  return (
    <UITabItem title={i18n.ClaimAppraisal.Appraisal}>
      <AppraisalVehicle
        objectId={objectId}
        taskInfo={taskInfo}
        openForm={OpenFrom}
        policyInfo={policyInfo}
        subClaim={subClaim}
      />
    </UITabItem>
  );
};

FAtaskTab.fn.prototype.ClaimAppraisalVApprovalTask =
  FAtaskTab.fn.prototype.ClaimAppraisalVehicleTask;

export default FAtaskTab;
