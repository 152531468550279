import {
  UIBox,
  UIButton,
  UIPanel,
  UIText,
  UISmartPanelGrid,
  UIDataTable,
  UIColumn,
} from 'RainbowUI';
import BindToMixin from 'react-binding';

const PolicyRisk = React.createClass({
  mixins: [BindToMixin],

  getInitialState() {
    return {
      policySearchOptions: {},
      productList: this.props.productList,
      riskList: {},
      CopyRiskName: [],
    };
  },

  componentWillMount() {},

  componentWillUnmount() {},

  onStoreStateChange(storeState) {},
  getRiskList(pageIndex, pageSize) {
    let pageRiskList = [];
    const riskList = this.state.riskList.result;
    if (riskList) {
      if (riskList.length - pageIndex + 1 > pageSize) {
        pageRiskList = riskList.slice(
          pageIndex - 1,
          pageIndex + pageSize - 1
        );
      } else {
        pageRiskList = riskList.slice(pageIndex - 1, riskList.length);
      }
      return { count: riskList.length, result: pageRiskList };
    }
    return riskList;
  },
  clickSearch() {
    const riskName = this.state.policySearchOptions.RiskName;
    if (this.state.CopyRiskName.length === 0) {
      _.forEach(this.state.riskList.result, (data, index) => {
        this.state.CopyRiskName.push(data);
      });
    }
    const riskList = [];
    if (riskName) {
      _.forEach(this.state.CopyRiskName, (data, index) => {
        if (
          data &&
          data.RiskName &&
          data.RiskName.toUpperCase().indexOf(
            riskName.toUpperCase()
          ) > -1
        ) {
          riskList.push(data);
        }
      });
      this.setState({
        riskList: { count: riskList.length, result: riskList },
      });
    }
  },
  onRowSelect(event) {
    this.setState(prevState => {
      const { dataIndex } = event.selectRecord;
      const riskList = prevState.riskList.result;
      const beforeSelected = riskList[dataIndex].Selected == '1';

      let selected = '';
      if (!beforeSelected) {
        selected = '1';
      }
      riskList[dataIndex].Selected = selected;
      return {
        riskList: { count: riskList.length, result: riskList },
      };
    });
  },
  onSelectAll(event) {
    this.setState(prevState => {
      const riskList = prevState.riskList.result;
      let selectAllFlag = false;
      $('input:not(:disabled)[type=checkbox]').each(
        (index, element) => {
          if (element.checked) {
            selectAllFlag = true;
          }
          return false;
        }
      );

      riskList.map((datas, index) => {
        const data = datas;
        if (selectAllFlag) {
          if (data.Selected != '1') {
            data.Selected = '1';
          }
        } else {
          data.Selected = '';
        }
        return data.Selected;
      });
      return {
        riskList: { count: riskList.length, result: riskList },
      };
    });
  },

  filterRiskList(policyEntityCriteria) {
    const productCode = policyEntityCriteria.ProductCode;
    let riskList = [];
    const { productList } = this.state;

    if (productCode && productList) {
      riskList = productList
        .filter(product => product.ProductCode == productCode)
        .map(product => product.InsuredList)
        .reduce(
          (initList, insuredList) => initList.concat(insuredList),
          []
        )
        .map(insured => ({
          Selected: '',
          InsuredId: insured.InsuredId,
          RiskName: insured.InsuredName || 'NoRiskName',
        }));
    }

    this.setState({
      policySearchOptions: policyEntityCriteria,
      riskList: { count: riskList.length, result: riskList },
    });
  },

  getProductRisk() {
    const productCode = this.state.policySearchOptions.ProductCode;
    const riskList = this.state.riskList.result;

    const insuredList = [];
    const productListChoose = [];
    if (riskList) {
      riskList.forEach((data, index) => {
        if (data.Selected == '1') {
          insuredList.push({
            InsuredId: data.InsuredId,
            InsuredName: data.RiskName,
          });
        }
      });
    }
    productListChoose.push({
      ProductCode: productCode,
      InsuredList: insuredList,
    });
    return productListChoose;
  },

  render() {
    return (
      <UIPanel panelTitle={i18n.ClaimCommon.RiskInfo}>
        <UISmartPanelGrid column="3" widthAllocation="5,7">
          <UIText
            id="QueryPolicy_RiskName"
            label={i18n.ClaimCommon.RiskName}
            valueLink={this.bindToState(
              'policySearchOptions',
              'RiskName'
            )}
          />
        </UISmartPanelGrid>
        <UIBox>
          <UIButton
            value={i18n.ClaimCommon.Search}
            onClick={this.clickSearch}
          />
        </UIBox>

        <UIDataTable
          id="policySearchResult"
          provider={this.getRiskList}
          indexable="false"
          pageable="true"
          isHeading="true"
          onRowSelect={this.onRowSelect}
          onSelectAll={this.onSelectAll}
        >
          <UIColumn selectionMode="multiple" value="Selected" />

          <UIColumn
            headerTitle={i18n.ClaimCommon.RiskName}
            value="RiskName"
          >
            <UIText io="out" />
          </UIColumn>
        </UIDataTable>
      </UIPanel>
    );
  },
});
export default PolicyRisk;
