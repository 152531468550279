import { UIUpdatePanel, UIDataTable, UIColumn } from 'RainbowUI';
import ViewParameterMatrixAction from '../action/ViewParameterMatrixAction';

export default class ViewParameterMatrix extends React.Component {
  render() {
    return (
      <UIUpdatePanel id="parameterMatrixListProvider">
        <UIDataTable
          indexable="false"
          id="parameterMatrixList"
          provider={this.getData}
        >
          <UIColumn
            headerTitle={i18n.ClaimCommon.SubclaimType}
            value="subclaimType"
          />
          <UIColumn
            headerTitle={i18n.ClaimProduct.CoverageCategory}
            value="coverageCategory"
          />
          <UIColumn
            headerTitle={i18n.ClaimProduct.ReserveType}
            value="reserveType"
          />
          <UIColumn
            headerTitle={i18n.ClaimProduct.SettlementTypeList}
            value="settlementTypeList"
          />
        </UIDataTable>
      </UIUpdatePanel>
    );
  }

  getData(pageindex, pagesize) {
    const parameterMatrixList = ViewParameterMatrixAction.getParameterMatrix(
      pageindex,
      pagesize
    );
    return parameterMatrixList;
  }
}
