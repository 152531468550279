import { AjaxUtil } from 'RainbowUI';

const Url = require('../url/Url');

export default {
  loadHistory(caseId, taskCode) {
    const deferred = $.Deferred();
    AjaxUtil.doGet(
      `${Url.SettlementHistory}?caseId=${caseId}&taskCode=${taskCode}`,
      {},
      {
        async: false,
        done(data, jqXHR) {
          if (data) {
            deferred.resolve(data);
          } else {
            deferred.reject({
              status: jqXHR.status,
              responseText: jqXHR.responseText,
            });
          }
        },
        fail(jqXHR) {
          deferred.reject({
            status: jqXHR.status,
            responseText: jqXHR.responseText,
          });
        },
      }
    );
    return deferred.promise();
  },
  cancelSubmit(cancelInfo) {
    const defer = $.Deferred();
    AjaxUtil.doPost(Url.SettlementCancel, cancelInfo, {
      async: false,
      done(data, jqXHR) {
        if (data) {
          defer.resolve(data);
        } else {
          defer.reject({
            status: jqXHR.status,
            responseText: jqXHR.responseText,
          });
        }
      },
      fail(jqXHR) {
        // jqXHR.status, jqXHR.responseText
        defer.reject({
          status: jqXHR.status,
          responseText: jqXHR.responseText,
        });
      },
    });
    return defer.promise();
  },

  getPayModeBySettlePayeeId(SettlePayeeId) {
    const defer = $.Deferred();
    const url = `${Url.SettlementPayModeLoad}?settlePayeeId=${SettlePayeeId}`;
    AjaxUtil.doGet(
      url,
      {},
      {
        async: false,
        done(data, jqXHR) {
          if (data) {
            defer.resolve(data);
          } else {
            defer.reject({
              status: jqXHR.status,
              responseText: jqXHR.responseText,
            });
          }
        },
        fail(jqXHR) {
          // jqXHR.status, jqXHR.responseText
          defer.reject({
            status: jqXHR.status,
            responseText: jqXHR.responseText,
          });
        },
      }
    );
    return defer.promise();
  },
};
