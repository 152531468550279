import {
  UIDataTable,
  UIColumn,
  UIText,
  DataContext,
  UICurrency,
  UILink,
  UIParam,
  UIDialog,
  UIPercent,
} from 'RainbowUI';
import { RiskBrokerByClaim } from 'EU00PartyCommonUI';

const CurrencyDataTableRate = React.createClass({
  getInitialState() {
    return {
      dataValue: this.props.defaultData,
    };
  },

  componentWillMount() {},

  componentDidMount() {
    this.props.getChildMethod(this.renderDataValue);
    this.addRowSpan();
  },

  render() {
    const self = this;
    return (
      <div>
        {self.renderCurrencyDataTableRate()}
        <UIDialog
          id="detailMessageByClaim"
          title={i18n.ReinsurerReinsuranceBroker}
        >
          <RiskBrokerByClaim />
        </UIDialog>
      </div>
    );
  },

  addRowSpan() {
    const xolNum = DataContext.get('XOLNum');
    if (xolNum) {
      if (xolNum !== 0) {
        $('#ClaimCurrencyDataRate tr:eq(1) td:eq(2)').attr(
          'rowspan',
          xolNum + 1
        );
        $('#ClaimCurrencyDataRate').attr('border', 0);
        const trSelector = $('#ClaimCurrencyDataRate tr:eq(1)');
        trSelector
          .css('background-color', 'white')
          .siblings()
          .css('background-color', 'white');
        for (let i = 1; i <= xolNum + 1; i += 1) {
          $(`#ClaimCurrencyDataRate tr:eq(${i}) td:eq(2)`).css(
            'background-color',
            '#e5e5e5'
          );
        }
        for (let index = 1; index <= xolNum; index += 1) {
          const selectorString =
            `#ClaimCurrencyDataRate tr:eq(${index + 1})` +
            ` td:eq(2)`;
          const selector = $(selectorString);
          selector.css({ display: 'none' });
        }
      }
    }
  },

  renderCurrencyDataTableRate() {
    const self = this;
    const riskUnitNo = DataContext.get('RiskUnitNo');
    const shareRateTableByClaim = DataContext.get(
      'ShareRateTableByClaim'
    );
    let shareDataList = [];
    _.each(shareRateTableByClaim, shareRateData => {
      if (shareRateData.RiskUnitNo == riskUnitNo) {
        shareDataList = shareRateData.RiskUnitShareRateTable;
      }
    });

    return (
      <UIDataTable
        id="ClaimCurrencyDataRate"
        pageable="false"
        value={shareDataList}
        indexable="false"
      >
        <UIColumn
          headerTitle={i18n.ClaimCommon.TreatyCode}
          render={data => {
            if (data.TreatyCode === 'Retention') {
              return (
                <div>
                  <UIText io="out" value={data.TreatyCode} />
                </div>
              );
            }
            return (
              <div>
                <UILink
                  onClick={self.viewBrokerDetail}
                  value={data.TreatyCode}
                >
                  <UIParam name="data" value={data} />
                </UILink>
              </div>
            );
          }}
        />
        <UIColumn
          headerTitle={i18n.ClaimCommon.PriorityOrder}
          render={data => {
            return (
              <div>
                <UIText value={data.Priority} io="out" />
              </div>
            );
          }}
        />
        <UIColumn
          headerTitle={i18n.ClaimCommon.ShareRate}
          width="20%"
          render={data => {
            const index = self.getArrIndex(shareDataList, data);
            const tempId = `PERCENT_CODE_SHARE_RATE_IN_CLAIM_${index}`;
            return (
              <div>
                <UIPercent
                  id={tempId}
                  style={self.getCommonStyle(data)}
                  disabled="true"
                  value={data.ShareRate}
                  symbolPosition="none"
                  decimalPrecision="6"
                />
              </div>
            );
          }}
        />
        <UIColumn
          headerTitle={i18n.ClaimCommon.OSReserve}
          width="auto"
          render={data => {
            return (
              <div style={{ float: 'left' }}>
                <UICurrency
                  value={data.Reserve}
                  unit={window.EU00.getCurrencySignByCurrencyCode(
                    data.ClaimCurrencyCode
                  )}
                  io="out"
                />
              </div>
            );
          }}
        />
        <UIColumn
          headerTitle={i18n.ClaimCommon.SettledAmount}
          width="auto"
          render={data => {
            return (
              <div style={{ float: 'left' }}>
                <UICurrency
                  value={data.PaymentAmount}
                  unit={window.EU00.getCurrencySignByCurrencyCode(
                    data.ClaimCurrencyCode
                  )}
                  io="out"
                />
              </div>
            );
          }}
        />
        <UIColumn
          headerTitle={i18n.ClaimCommon.IncurredAmount}
          width="auto"
          render={data => {
            return (
              <div style={{ float: 'left' }}>
                <UICurrency
                  value={data.TotalAmount}
                  unit={window.EU00.getCurrencySignByCurrencyCode(
                    data.ClaimCurrencyCode
                  )}
                  io="out"
                />
              </div>
            );
          }}
        />
      </UIDataTable>
    );
  },

  renderDataValue(data) {
    this.setState({ dataValue: data });
  },

  viewBrokerDetail(event) {
    const data = event.getParameter('data');
    const TreatyCode = data.TreatyCode.replace(/&/g, '%26');
    const RiclaimInfo = `${data.ClaimCurrencyCode}:${data.ClaimCurrencyCode}:${data.PaymentAmount}:${data.PaymentAmount}`;
    data.RiclaimInfo = RiclaimInfo;
    DataContext.put('TreatyCode', TreatyCode);
    DataContext.put('UrlData', data);
    UIDialog.show('detailMessageByClaim');
  },

  getArrIndex(arr, obj) {
    return $.inArray(obj, arr);
  },

  getCommonStyle(data) {
    if (data.Priority === 'Total') {
      return { 'font-weight': 'bold' };
    }
    return {};
  },
});
export default CurrencyDataTableRate;
