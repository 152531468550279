import { UIDialog, UIButton, UIBox } from 'RainbowUI';
import React from 'react';
import PropTypes from 'prop-types';
import BindToMixin from 'react-binding';
import SettlementHistoryStore from '../../common/store/SettlementHistoryStore';

const RefundReadOnly = require('./RefundReadOnly');

const RefundReadOnlyDialog = React.createClass({
  mixins: [BindToMixin],

  propTypes: {
    settlementId: PropTypes.string,
  },

  getInitialState() {
    return {};
  },

  componentWillMount() {
    SettlementHistoryStore.listen(this.onStoreStateChange);
  },

  componentWillUnmount() {
    SettlementHistoryStore.unlisten(this.onStoreStateChange);
  },

  onStoreStateChange(storeState) {
    this.setState({ settleid: storeState.settleid });
  },

  onClose() {
    const { componentId } = this.props;
    const ComponentId = componentId || '';
    const id = `SettlementReadOnlyDialog${ComponentId}`;
    UIDialog.hide(id);
  },

  render() {
    const { componentId } = this.props;
    const { settleid } = this.state;
    const ComponentId = componentId || '';
    return (
      <UIDialog
        id={`SettlementReadOnlyDialog${ComponentId}`}
        title={i18n.ClaimSettlement.RefundHistoryDetail}
        width="90%"
        height="1000px"
        modal="false"
      >
        <RefundReadOnly
          settlementId={settleid}
          readOnly
          history
          isApprove={false}
          componentId={ComponentId}
        />
        <UIBox direction="center">
          <UIButton
            value={i18n.ClaimCommon.Back}
            onClick={this.onClose}
            position="right"
          />
        </UIBox>
      </UIDialog>
    );
  },
});
export default RefundReadOnlyDialog;
