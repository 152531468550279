import { MessageHelper, Caller, CodeTable } from 'RainbowUI';
import urlConfig from '../../../UrlConfig';
import CommonService from '../service/CommonService';

export default class CommonAction {
  onClickedHome() {
    const ip = window.location.origin;
    const url = `${ip}/portal`;
    window.location.href = url;
  }

  goMaintenancecenter() {
    window.location.href = urlConfig.maintenancecenter;
  }

  getPermissionByType(type) {
    const url = urlConfig.getPermissionByType + type;
    let result = [];
    Caller.call(url, null, {
      method: 'GET',
      async: false,
      block: true,
    }).then(data => {
      result = data;
    });
    return result;
  }

  getPermissionCode(permissionId, permissions) {
    for (let i = 0; i < permissions.length; i += 1) {
      if (permissionId == permissions[i].Id) {
        return permissions[i].Code;
      }
    }
    return '';
  }

  openArapDetail(event) {
    const feeId = event.getParameter('feeId');
    const groupCount = event.getParameter('groupCount');
    const amount = event.getParameter('amount');
    const balance = event.getParameter('balance');
    const localAmount = event.getParameter('localAmount');
    const commissionAmount = event.getParameter('commissionAmount');
    const ip = window.location.origin;
    window.location.href = `${ip}/bcp/#/query/arapDetail?feeId=${feeId}&groupCount=${groupCount}&amount=${amount}&balance=${balance}&localAmount=${localAmount}&commissionAmount=${commissionAmount}`;
  }

  openReceiptDetail(event) {
    const collectionId = event.getParameter('collectionId');
    window.location.hash = `#/query/receiptDetail?collectionId=${collectionId}`;
  }

  openPaymentDetail(event) {
    const paymentId = event.getParameter('paymentId');
    window.location.hash = `#/query/paymentDetail?paymentId=${paymentId}`;
  }

  openOffsetDetail(event) {
    const offsetId = event.getParameter('offsetId');
    window.location.hash = `#/query/applicationDetail?offsetId=${offsetId}`;
  }

  openPolicyDetail(event) {
    const policyNo = event.getParameter('policyNo');
    const policyId = '-9999';
    const ip = window.location.origin;
    const url = `${ip}/pa/common/#/workCenter?policyId=${policyId}&transactionCode=query&transactionType=bcp&policyNo=${policyNo}`;
    window.location.href = url;
  }

  onClickedClose() {
    window.close();
  }

  onClearCache() {
    const url = urlConfig.clearCache;
    const setting = { method: 'GET', async: false, block: false };
    Caller.call(url, null, setting).then(
      data => {
        if (data) {
          MessageHelper.success('', i18n.clearSuccess);
        } else {
          MessageHelper.error(i18n.clearError);
        }
      },
      err => {
        MessageHelper.error(i18n.clearError + err);
      }
    );
  }

  onClickedExit() {
    window.location.hash = '/';
  }

  loadLocalCurrency() {
    let localCurrency = null;
    const url = urlConfig.loadLocalCurrency;
    new CommonService().ajaxGet(null, url).then(data => {
      if (data) {
        localCurrency = data.CurrencyCode;
      }
    });
    return localCurrency;
  }

  getCurrentMonthForEndBatch() {
    const url = urlConfig.getCurrentMonthForEndBatch;
    let result = null;
    return result;
  }

  downloadFile(suffixUrl, fileName) {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', suffixUrl, true);
    xhr.setRequestHeader(
      'Authorization',
      `Bearer ${
      localStorage
        .getItem('Authorization')
        .substr(13)
        .split('&')[0]
      }`
    );
    xhr.responseType = 'blob';
    xhr.onreadystatechange = () => {
      if (
        xhr.readyState == XMLHttpRequest.DONE &&
        xhr.status == 200
      ) {
        const blob = new Blob([this.response], {
          type: this.getResponseHeader('content-type'),
        });
        if (!!window.ActiveXObject || 'ActiveXObject' in window) {
          window.navigator.msSaveBlob(blob, fileName);
        } else {
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
        }
      }
    };
    xhr.send(null);
  }

  showandPrintPDF(suffixUrl) {
    const xhr = new XMLHttpRequest();

    xhr.open('GET', suffixUrl, true);
    xhr.setRequestHeader(
      'Authorization',
      `Bearer ${
      localStorage
        .getItem('Authorization')
        .substr(13)
        .split('&')[0]
      }`
    );
    xhr.responseType = 'blob';
    xhr.onreadystatechange = () => {
      if (
        xhr.readyState == XMLHttpRequest.DONE &&
        xhr.status == 200
      ) {
        let fileName = this.getResponseHeader(
          'Content-Disposition'
        ).split('=')[1];
        if (fileName) {
          const nameStr = fileName.split('"');
          if (nameStr && nameStr.length === 3) {
            fileName = nameStr[1];
          }
        }
        const blob = new Blob([this.response], {
          type: this.getResponseHeader('content-type'),
        });
        if (!!window.ActiveXObject || 'ActiveXObject' in window) {
          window.navigator.msSaveBlob(blob, fileName);
        } else {
          const downloadUrl = URL.createObjectURL(blob);

          const win = window.open(downloadUrl, 'print');
          win.origin = null;
          win.parent = null;
          win.opener = null;
          win.location.reload();
          win.print();
        }
      }
    };
    xhr.send(null);
  }

  ajaxGetBlob(docId, callback) {
    const suffixUrl = urlConfig.downloadFileFromDMS + docId;
    const xhr = new XMLHttpRequest();
    xhr.open('GET', suffixUrl, true);
    xhr.setRequestHeader(
      'Authorization',
      `Bearer ${
      localStorage
        .getItem('Authorization')
        .substr(13)
        .split('&')[0]
      }`
    );
    xhr.responseType = 'blob';
    xhr.onreadystatechange = () => {
      if (
        xhr.readyState == XMLHttpRequest.DONE &&
        xhr.status == 200
      ) {
        let fileName = this.getResponseHeader(
          'Content-Disposition'
        ).split('=')[1];
        if (fileName) {
          const nameStr = fileName.split('"');
          if (nameStr && nameStr.length === 3) {
            fileName = nameStr[1];
          }
        }
        const blob = new Blob([this.response], {
          type: this.getResponseHeader('content-type'),
        });
        if (!!window.ActiveXObject || 'ActiveXObject' in window) {
          window.navigator.msSaveBlob(blob, fileName);
        } else {
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
        }
        if (callback) {
          callback();
        }
      }
    };
    xhr.send(null);
  }

  showPDF(docId) {
    const suffixUrl = urlConfig.downloadFileFromDMS + docId;
    const xhr = new XMLHttpRequest();

    xhr.open('GET', suffixUrl, true);
    xhr.setRequestHeader(
      'Authorization',
      `Bearer ${
      localStorage
        .getItem('Authorization')
        .substr(13)
        .split('&')[0]
      }`
    );
    xhr.responseType = 'blob';
    xhr.onreadystatechange = () => {
      if (
        xhr.readyState == XMLHttpRequest.DONE &&
        xhr.status == 200
      ) {
        let fileName = this.getResponseHeader(
          'Content-Disposition'
        ).split('=')[1];
        if (fileName) {
          const nameStr = fileName.split('"');
          if (nameStr && nameStr.length === 3) {
            fileName = nameStr[1];
          }
        }
        let type = 'application/octet-stream';
        let preview = false;
        if (fileName.endsWith('.pdf')) {
          type = 'application/pdf';
          preview = true;
        } else if (
          fileName.endsWith('.jpg') ||
          fileName.endsWith('.bmp')
        ) {
          type = 'image/bmp';
          preview = true;
        } else if (fileName.endsWith('.gif')) {
          type = 'image/gif';
          preview = true;
        } else if (fileName.endsWith('.jpeg')) {
          type = 'image/jpeg';
          preview = true;
        } else if (fileName.endsWith('.png')) {
          type = 'image/png';
          preview = true;
        } else if (fileName.endsWith('.icon')) {
          type = 'image/x-icon';
          preview = true;
        }
        const blob = new Blob([this.response], { type });
        if (!!window.ActiveXObject || 'ActiveXObject' in window) {
          window.navigator.msSaveBlob(blob, fileName);
        } else if (preview) {
          const downloadUrl = URL.createObjectURL(blob);
          const win = window.open(downloadUrl, 'privew');
          win.origin = null;
          win.parent = null;
          win.opener = null;
          win.location.reload();
        } else {
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
        }
      }
    };
    xhr.send(null);
  }

  getPermissionByTypeUserIdParentName(parentName) {
    return new CodeTable([]);

    if (parentName) {
      const permissions = CommonService.getPermissionByTypeUserIdParentName(
        102,
        parentName
      );
      const codeTableValue = new CodeTable(
        this.formatCodeTable(permissions)
      );
      return codeTableValue;
    }
    return new CodeTable([]);
  }

  formatCodeTable(data) {
    const codeList = [];
    for (let i = 0; i < data.length; i += 1) {
      const newData = {};
      newData.id = data[i].Id;

      if (data[i].Description) {
        newData.text = data[i].Description;
      } else {
        const code = data[i].Code;
        if (code.indexOf('Non_GDPR') !== -1) {
          newData.text = 'Non_GDPR';
        } else if (code.indexOf('GDPR') !== -1) {
          newData.text = 'GDPR';
        } else {
          newData.text = code;
        }
      }

      if (data[i].Parent) {
        newData.Parent = data[i].Parent;
      }

      codeList.push(newData);
    }
    return codeList;
  }
}
