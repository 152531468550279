import {
  UILink,
  UIText,
  UIParam,
  UIDataTable,
  UIColumn,
  UICurrency,
  UIUpdatePanel,
  UpdateContext,
  DataContext,
} from 'RainbowUI';
import BindToMixin from 'react-binding';
import PropTypes from 'prop-types';
import { ClaimMessageForamtUtils } from 'ClaimCommonConstant';
import {
  PartyConst,
  PtyIntegrationSearchDialog,
  PartyAction,
} from 'EU00PartyCommonUI';

const SalvageLoadAction = require('../action/SalvageLoadAction');

const SalvageItemInfo = React.createClass({
  mixins: [BindToMixin],

  propTypes: {
    otherInfo: PropTypes.object.isRequired,
    insuredId: PropTypes.string.isRequired,
    coverageCode: PropTypes.string.isRequired,
    salvageItem: PropTypes.object.isRequired,
    readOnly: PropTypes.bool,
    isApproveTask: PropTypes.bool,
  },

  render() {
    const salvageItem = this.getSalvageItemPanel();

    return <div>{salvageItem}</div>;
  },

  getIOStyle() {
    return this.props.readOnly == true ? 'out' : null;
  },
  getDisplayStyle() {
    return this.props.readOnly == true ? 'none' : '';
  },

  getVisibled() {
    return this.props.readOnly !== true;
  },

  buildTableFunction() {
    const visibled = this.getVisibled();
    const {
      itemId,
      coverageCode,
      objectId,
      currencyCode,
    } = this.props;
    return (
      <div>
        <UILink
          icon="fa fa-plus"
          size="lg"
          tooltip={i18n.Add}
          visibled={visibled}
          iconClass="add_iconbtn"
          onClick={SalvageLoadAction.addItem}
        >
          <UIParam name="objectId" value={objectId} />
          <UIParam name="itemId" value={itemId} />
          <UIParam name="coverageCode" value={coverageCode} />
          <UIParam name="currencyCode" value={currencyCode} />
        </UILink>
      </div>
    );
  },

  showPartySearchDialog(event) {
    let index;
    if (event.paramJson.length > 0) {
      index = event.paramJson[0].value;
    }
    DataContext.put('salvageIndex', index);
    this.refs.partySearchDialogForRecover.show();
  },

  getRecoveryParty(party) {
    const index = DataContext.get('salvageIndex');
    if (index != undefined) {
      this.props.salvageItem[index].RecoverFrom = party.PartyId;
    }
    UpdateContext.forceUpdate('salvageUpdatePanel');
  },

  getSalvageItemPanel() {
    const _self = this;
    var { componentId } = this.props;
    const io = this.getIOStyle();
    var { componentId } = this.props;
    const { objectId } = this.props;
    const { coverageCode } = this.props;
    const { readOnly } = this.props;
    const { salvageItem } = this.props;
    const suffixId = `salvageItem_${readOnly}_${
      this.props.isApproveTask
    }${this.props.objectId}_${this.props.coverageCode.replace(
      /\./g,
      '_'
    )}_${componentId}`;

    return (
      <div>
        <PtyIntegrationSearchDialog
          ref="partySearchDialogForRecover"
          id="partySearchDialogForRecover"
          title={i18n.ClaimCommon.SearchRecovery}
          isSearch
          getSearchResult={_self.getRecoveryParty}
          isVisibled={false}
          RoleName={PartyConst.PARTY_ROLE_NAME_RECOVER_FROM}
        />
        <UIUpdatePanel id="salvageUpdatePanel">
          <UIDataTable
            id={`${suffixId}dataTable`}
            indexable="false"
            value={salvageItem}
            pageable="false"
            functions={this.buildTableFunction(io)}
          >
            <UIColumn
              headerTitle={i18n.ClaimSalvage.RecoverFrom}
              render={data => {
                let partyName = '';
                const partyAction = new PartyAction();
                if (
                  data.RecoverFrom != '' &&
                  data.RecoverFrom != undefined &&
                  data.RecoverFrom != null
                ) {
                  const party = partyAction.loadPartyByPartyId(
                    data.RecoverFrom
                  );
                  partyName = party.PartyName;
                }
                return (
                  <UIText
                    id={`${suffixId + data.dataIndex}_recover_from`}
                    value={partyName}
                    io={io}
                    required="true"
                    disabled="true"
                    onChange={SalvageLoadAction.recoverFromChange}
                    suffixIcon="fa fa-search"
                    validationGroup="salvage_submit_validate"
                    onSuffixIconClick={_self.showPartySearchDialog}
                    requiredMessage={ClaimMessageForamtUtils.formatI18NMsg(
                      i18n.ClaimCommon.I18nUtilValidate,
                      i18n.ClaimSalvage.RecoverFrom
                    )}
                  >
                    <UIParam name="index" value={data.dataIndex} />
                  </UIText>
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.ClaimSalvage.SalvageAmount}
              render={data => {
                if (data.SalvageAmount == null) {
                  return (
                    <UICurrency
                      id={`CURRENCY_CODE_${suffixId}${data.dataIndex}_salvage_amount`}
                      value=""
                      required="true"
                      io={io}
                      allowNegative="false"
                      defaultValue="0"
                      unit={window.EU00.getCurrencySignByCurrencyCode(
                        data.CurrencyCode
                      )}
                      onChange={SalvageLoadAction.salvageAmountChange}
                      validationGroup="salvage_submit_validate"
                      requiredMessage={ClaimMessageForamtUtils.formatI18NMsg(
                        i18n.ClaimCommon.I18nUtilValidate,
                        i18n.ClaimSalvage.SalvageAmount
                      )}
                    >
                      <UIParam name="index" value={data.dataIndex} />
                    </UICurrency>
                  );
                }
                return (
                  <UICurrency
                    id={`CURRENCY_CODE_${suffixId}${data.dataIndex}_salvage_amount`}
                    required="true"
                    value={data.SalvageAmount}
                    io={io}
                    allowNegative="true"
                    defaultValue="0"
                    unit={window.EU00.getCurrencySignByCurrencyCode(
                      data.CurrencyCode
                    )}
                    onChange={SalvageLoadAction.salvageAmountChange}
                    validationGroup="salvage_submit_validate"
                    requiredMessage={ClaimMessageForamtUtils.formatI18NMsg(
                      i18n.ClaimCommon.I18nUtilValidate,
                      i18n.ClaimSalvage.SalvageAmount
                    )}
                  >
                    <UIParam name="index" value={data.dataIndex} />
                  </UICurrency>
                );
              }}
            />

            <UIColumn
              headerTitle={i18n.ClaimSalvage.RemarkForSalvage}
              render={data => {
                return (
                  <UIText
                    id={`${suffixId +
                      data.dataIndex}_remark_for_salvage`}
                    value={data.Remark}
                    io={io}
                    onChange={
                      SalvageLoadAction.remarkForSalvageChange
                    }
                  >
                    <UIParam name="index" value={data.dataIndex} />
                  </UIText>
                );
              }}
            />
            <UIColumn
              headerTitle={readOnly ? '' : i18n.ClaimCommon.Action}
              render={data => {
                console.log('data123', data);
                if (readOnly) {
                  return '';
                }
                return (
                  <UILink
                    id={`${suffixId + data.dataIndex}del_item`}
                    icon="fa fa-trash"
                    onClick={SalvageLoadAction.deleteItem}
                  >
                    <UIParam name="objectId" value={objectId} />
                    <UIParam
                      name="coverageCode"
                      value={coverageCode}
                    />
                    <UIParam name="index" value={data.dataIndex} />
                    <UIParam
                      name="itemsLength"
                      value={_self.props.salvageItem.length}
                    />
                  </UILink>
                );
              }}
            />
          </UIDataTable>
        </UIUpdatePanel>
      </div>
    );
  },
});
export default SalvageItemInfo;