import alt from '../alt';
import PartyMaintenanceAction from '../action/PartyMaintenanceAction';

class PartyMaintenanceStore {
  constructor() {
    this.clickTabTimes = 0;

    this.bindListeners({
      handleClickPartyTab: PartyMaintenanceAction.CLICK_PARTY_TAB,
    });
  }

  handleClickPartyTab(data) {
    this.clickTabTimes = this.clickTabTimes + 1;
  }
}

export default alt.createStore(
  PartyMaintenanceStore,
  PartyMaintenanceStore
);
