import { Caller, MessageHelper } from 'RainbowUI';
import alt from '../alt';
import SettlementDetailDialogService from '../service/SettlementDetailDialogService';
import Url from '../url/Url';

class SettlementDetailDialogAction {
  saveSettlementIssueDate(data) {
    SettlementDetailDialogService.saveSettlementIssueDate(data).then(
      data => {
        if (data.Status === 'OK') {
          if (typeof callback === 'function') {
            callback(cancelInfo.SettlePayeeId);
          }
          UIDialog.hide('SettlementDetailDialogService');
        } else {
          MessageHelper.error(data.Messages[0].Message, '');
        }
      }
    );
  }

  saveSettlementReceiveDate(data) {
    SettlementDetailDialogService.saveReceiveDate(data).then(data => {
      if (data.Status === 'OK') {
        MessageHelper.success(
          '',
          i18n.ClaimSettlement.SettlementSaveSuccess
        );
        UIDialog.hide('SettlementDetailDialogService');
      } else {
        MessageHelper.error(data.Messages[0].Message, '');
      }
    });
  }

  saveBothDate(data) {
    SettlementDetailDialogService.saveBothDate(data).then(data => {
      if (data.Status === 'OK') {
        if (typeof callback === 'function') {
          callback(cancelInfo.SettlePayeeId);
        }
        UIDialog.hide('SettlementDetailDialogService');
      } else {
        MessageHelper.error(data.Messages[0].Message, '');
      }
    });
  }

  loadSettlementSubmitDate(settlementId) {
    const url = Url.SettlementReadOnlyLoad + settlementId;
    let result = {};
    Caller.call(url, null, {
      method: 'GET',
      async: false,
      block: true,
    }).then(
      data => {
        result = data.Model.SettlementInfo.SubmitDate;
      },
      () => {
        MessageHelper.error(data, '');
      }
    );
    return result;
  }
}
export default alt.createActions(SettlementDetailDialogAction);
