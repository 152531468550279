import {
  UIPanel,
  UITextarea,
  UIRadio,
  UISmartPanelGrid,
  UIButton,
  UIBox,
} from 'RainbowUI';
import BindToMixin from 'react-binding';

const code = require('../../mock/CodeTable');
const AppraisalPropertyAction = require('../../action/AppraisalPropertyAction');

const ApprovalDecision = React.createClass({
  mixins: [BindToMixin],

  getInitialState() {
    return {
      AppraisalData: this.props.appraisalData,
    };
  },

  getEnabled() {
    return this.props.readOnly == true ? 'false' : 'true';
  },

  getIOStyle() {
    return this.props.readOnly == true ? 'out' : null;
  },

  render() {
    const io = this.getIOStyle();
    const enabled = this.getEnabled();

    return (
      <UIPanel
        panelTitle={i18n.ClaimAppraisal.ApprovalDecision}
        toggleable="true"
      >
        <UISmartPanelGrid>
          <UIRadio
            id="approveDecision"
            label={i18n.ClaimAppraisal.Decision}
            enabled={enabled}
            codeTable={code.decisionType}
            colspan="2"
            valueLink={this.bindToState(
              'AppraisalData',
              'ApprovalDecision'
            )}
            required="true"
            validationGroup="AppraisalPropertyApprove"
          />
          <UITextarea
            id="approveRemark"
            label={i18n.ClaimAppraisal.Remark}
            io={io}
            colspan="2"
            valueLink={this.bindToState(
              'AppraisalData',
              'ApproveMark'
            )}
          />
        </UISmartPanelGrid>
        {this.getDecisionButton()}
      </UIPanel>
    );
  },

  getDecisionButton() {
    const { readOnly } = this.props;
    const a = readOnly == true;

    if (a) {
      return null;
    }

    return (
      <UIBox direction="right">
        <UIButton
          value={i18n.ClaimAppraisal.SubmitTotal}
          onClick={this.approveAppraisalProperty}
          validationGroup="AppraisalPropertyApprove"
          causeValidation="true"
        />
      </UIBox>
    );
  },
  approveAppraisalProperty() {
    const tempData = this.state.AppraisalData;

    tempData.ApprovalHistoryVO = null;
    tempData.AppraisalHistory = [];
    tempData.TaskInstanceId = this.props.taskInstanceId;
    AppraisalPropertyAction.approveAppraisalProperty(tempData);
  },
});
export default ApprovalDecision;
