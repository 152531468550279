import {
  UICell,
  UISelect,
  UIButton,
  UIDataTable,
  UIColumn,
  UIBox,
  UIParam,
  UICurrency,
} from 'RainbowUI';
import { CodeTableAction, CodeTableCstUI } from 'ClaimCommonConstant';
import BindToMixin from 'react-binding';
import DefaultReserveAction from '../action/DefaultReserveAction';

// export default class DefaultReserveResult extends React.Component{
const DefaultReserveResult = React.createClass({
  mixins: [BindToMixin],

  getInitialState() {
    return { currencyCode: '' };
  },

  getDefaultList(pageindex, pagesize) {
    const model = DefaultReserveAction.getDefaultReserveList(
      pageindex,
      pagesize
    );
    this.state.currencyCode = model.result.CurrencyCode;
    this.state.ClaimReserveDefault = model.result.ClaimReserveDefault;
    return {
      count: model.count,
      result: model.result.ClaimReserveDefault,
    };
  },
  render() {
    self = this;
    return (
      <div>
        <UIDataTable
          indexable="false"
          id="defaultReserveResultTable"
          provider={self.getDefaultList}
          var="DefaultReserveList"
        >
          <UIColumn
            headerTitle={i18n.ClaimCommon.SubclaimType}
            value="SubclaimTypeCode"
            render={data => {
              return (
                <UISelect
                  value={data.SubclaimTypeCode}
                  io="out"
                  codeTable={CodeTableAction.loadDataList(
                    CodeTableCstUI.SubclaimType,
                    null
                  )}
                />
              );
            }}
          />

          <UIColumn
            headerTitle={i18n.ClaimProduct.Coverage}
            value="CoverageCode"
            render={data => {
              return (
                <UISelect
                  value={data.CoverageCode}
                  io="out"
                  codeTable={CodeTableAction.loadDataList(
                    CodeTableCstUI.ClaimCoverageCodeName,
                    null
                  )}
                />
              );
            }}
          />

          <UIColumn
            headerTitle={i18n.ClaimProduct.CoverageCategory}
            value="CoverageCategory"
            render={data => {
              return (
                <UISelect
                  value={data.CoverageCategory}
                  io="out"
                  codeTable={CodeTableAction.loadDataList(
                    CodeTableCstUI.ClaimCoverageType,
                    null
                  )}
                />
              );
            }}
          />

          <UIColumn
            headerTitle={i18n.ClaimProduct.ReserveType}
            value="ReserveTypeCode"
            render={data => {
              return (
                <UISelect
                  value={data.ReserveTypeCode}
                  io="out"
                  codeTable={CodeTableAction.loadDataList(
                    CodeTableCstUI.ClaimReserveType,
                    null
                  )}
                />
              );
            }}
          />

          <UIColumn
            id="defaultReserve"
            headerTitle={i18n.ClaimProduct.DefaultReserve}
            render={data => {
              const getArrIndex = (arr, obj) => {
                return $.inArray(obj, arr);
              };
              const index = getArrIndex(
                self.state.ClaimReserveDefault,
                data
              );
              const tempId = `CURRENCY_CODE_DefaultAmount_${index}`;
              return (
                <UICurrency
                  id={tempId}
                  io="in"
                  value={data.DefaultAmount}
                  unit={window.EU00.getCurrencySignByCurrencyCode(
                    self.state.currencyCode
                  )}
                  onChange={DefaultReserveAction.saveChangeData}
                >
                  <UIParam name="data" value={data} />
                </UICurrency>
              );
            }}
          />
        </UIDataTable>
        <UICell>
          <UIBox direction="left">
            <UIButton
              value={i18n.ClaimProduct.Save}
              onClick={DefaultReserveAction.save}
            />
          </UIBox>
        </UICell>
      </div>
    );
  },
});
export default DefaultReserveResult;
