import {
  UIBox,
  UIDialog,
  UIText,
  UISelect,
  UIButton,
  UISmartPanelGrid,
  UIRadio,
  UINumber,
} from 'RainbowUI';
import { CodeTableAction, CodeTableCstUI } from 'ClaimCommonConstant';
import BindToMixin from 'react-binding';

const ReserverTypeAction = require('../action/ReserverTypeAction');

const ReserverTypeDialog = React.createClass({
  mixins: [BindToMixin],

  getInitialState() {
    return { reserveTypeEntity: {} };
  },

  showDialog(data) {
    this.setState({ reserveTypeEntity: data });
    UIDialog.show('reserverTypeDialog');
  },

  clickSave() {
    const result = ReserverTypeAction.saveReserveType(
      this.state.reserveTypeEntity
    );
    if (result === 'true') {
      this.props.parentComponet.updateReserveType();
      UIDialog.hide('reserverTypeDialog');
    }
  },

  render() {
    return (
      <UIDialog
        id="reserverTypeDialog"
        title={i18n.ClaimProduct.ReserveType}
        width="80%"
        height="600px"
        modal="false"
      >
        <UISmartPanelGrid column="2" widthAllocation="4,8">
          <UIText
            io="out"
            id="reserveTypeCode"
            label={i18n.ClaimProduct.ReserveTypeCode}
            valueLink={this.bindToState(
              'reserveTypeEntity',
              'ReserveTypeCode'
            )}
            required="true"
            validationGroup="productType"
          />
          <UIText
            id="reserveTypeName"
            label={i18n.ClaimProduct.ReserveTypeName}
            valueLink={this.bindToState(
              'reserveTypeEntity',
              'ReserveTypeName'
            )}
            required="true"
            validationGroup="productType"
          />
          <UIRadio
            id="positive"
            label={i18n.ClaimProduct.Positive}
            valueLink={this.bindToState(
              'reserveTypeEntity',
              'ReserveSign'
            )}
            codeTable={CodeTableAction.loadDataList(
              CodeTableCstUI.ClaimReserveSign,
              null
            )}
          />
          <UISelect
            id="claimFileType"
            label={i18n.ClaimProduct.ClaimFileType}
            valueLink={this.bindToState(
              'reserveTypeEntity',
              'ClaimTypeCode'
            )}
            codeTable={CodeTableAction.loadDataList(
              CodeTableCstUI.ClaimFileType,
              null
            )}
          />
          <UINumber
            id="NUMBER_CODE_SortNo"
            format="######"
            label={i18n.ClaimProduct.SortNo}
            valueLink={this.bindToState(
              'reserveTypeEntity',
              'SortNumber'
            )}
          />
          <UIText
            colspan="2"
            id="reserveTypeDescription"
            label={i18n.ClaimProduct.ReserveTypeDescription}
            valueLink={this.bindToState(
              'reserveTypeEntity',
              'ReserveTypeDescription'
            )}
          />
        </UISmartPanelGrid>

        <UIBox direction="left">
          <UIButton
            value={i18n.ClaimProduct.Save}
            onClick={this.clickSave}
            validationGroup="productType11"
            causeValidation="true"
          />
          <UIButton
            value={i18n.ClaimProduct.Cancel}
            buttonType="cancel"
          />
        </UIBox>
      </UIDialog>
    );
  },
});
export default ReserverTypeDialog;
