import { UIText } from 'RainbowUI';
import BindToMixin from 'react-binding';
import PropTypes from 'prop-types';

const SalvageInsuredInfo = require('./SalvageInsuredInfo');

const SalvageInfo = React.createClass({
  mixins: [BindToMixin],

  propTypes: {
    salvageInfo: PropTypes.object.isRequired,
    otherInfo: PropTypes.object.isRequired,
    readOnly: PropTypes.bool,
    isApproveTask: PropTypes.bool,
  },
  getIOStyle() {
    return this.props.readOnly == true ? 'out' : null;
  },
  render() {
    const { salvageInfo, readOnly, isApproveTask } = this.props;
    const salvageItem = this.bindTo(salvageInfo, 'SalvageItem');

    if (!salvageItem || salvageItem.length < 1) {
      return <div />;
    }
    const salvageId = this.bindTo(salvageInfo, 'SalvageId').value;
    const suffixId = `salvageInfo_${readOnly}_${isApproveTask}_${salvageId}_`;
    const insuredInfo = this.getInsuredInfo();
    const io = this.getIOStyle();
    return (
      <div>
        {insuredInfo}
        <UIText
          id={`${suffixId}remark`}
          label={i18n.ClaimSalvage.Remark}
          widthAllocation="2,9"
          valueLink={this.bindTo(salvageInfo, 'Remark')}
          io={io}
        />
      </div>
    );
  },

  getInsuredInfo() {
    const { salvageInfo } = this.props;
    const { otherInfo } = this.props;

    const coverageStructure = this.bindArrayTo(
      otherInfo,
      'CoverageStructure'
    );

    if (_.isEmpty(coverageStructure.items)) {
      return <div />;
    }
    const { readOnly } = this.props;
    const { isApproveTask } = this.props;
    const his = this.props.history;
    const insuredInfo = coverageStructure.items.map(
      (insured, index) => {
        return (
          <SalvageInsuredInfo
            salvageInfo={salvageInfo}
            history={his}
            insured={insured}
            otherInfo={otherInfo}
            readOnly={readOnly}
            isApproveTask={isApproveTask}
            componentId={index}
          />
        );
      }
    );

    return insuredInfo;
  },
});
export default SalvageInfo;
