import { UIDialog, UIConfirmDialog, DataContext } from 'RainbowUI';

const CommonAction = require('../action/CommonAction');

const RemoveProductLineConfirmDialog = React.createClass({
  getInitialState() {
    return { deteleEntity: {} };
  },

  showDialog(data) {
    this.setState({ deteleEntity: data });
    UIDialog.show('RemovePrdLineConfirmDialog');
  },

  onConfirmClick() {
    const data = DataContext.get('data');
    console.log('-----props  is ', this.props);

    // DataContext.put("removeType",this.props.removeType);

    DataContext.put('data', data);
    CommonAction.remove(this.state.deteleEntity);
    this.props.parentComponet.updatePage();
    UIDialog.hide('RemovePrdLineConfirmDialog');
  },

  render() {
    return (
      <UIConfirmDialog
        id="RemovePrdLineConfirmDialog"
        title={i18n.ClaimProduct.ProductLine}
        message={i18n.ClaimCommon.deleteConfirmText}
        onConfirm={this.onConfirmClick}
      />
    );
  },
});
export default RemoveProductLineConfirmDialog;
