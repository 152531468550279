import { UIConfirmDialog } from 'RainbowUI';
import BindToMixin from 'react-binding';
import reactMixin from 'react-mixin';

export default class CommonConfirmDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = { title: '', message: [] };
  }

  showDialog(title, message) {
    const _self = this;
    this.setState({ title, message });
    UIConfirmDialog.show(`confirmDialog_${_self.props.objectId}_0`);
  }

  render() {
    const _self = this;
    let msgArray = new Array();
    if (
      Object.prototype.toString.call(_self.state.message) ===
      '[object Array]'
    ) {
      msgArray = _self.state.message;
    } else {
      msgArray.push(_self.state.message);
    }
    const msgLength = msgArray.length;
    let msgDialog = msgArray.map((message, i) => {
      if (i + 1 == msgLength) {
        return (
          <UIConfirmDialog
            id={`confirmDialog_${_self.props.objectId}_${i}`}
            title={_self.state.title}
            message={message}
            confirmText={i18n.ClaimCommon.Continue}
            cancelText={i18n.ClaimCommon.Cancel}
            onConfirm={() => {
              UIConfirmDialog.hide(
                `confirmDialog_${_self.props.objectId}_${i}`
              );
              _self.props.onConfirm.call();
            }}
          />
        );
      }
      return (
        <UIConfirmDialog
          id={`confirmDialog_${_self.props.objectId}_${i}`}
          title={_self.state.title}
          message={message}
          confirmText={i18n.ClaimCommon.Continue}
          cancelText={i18n.ClaimCommon.Cancel}
          onConfirm={() => {
            const index = i + 1;
            UIConfirmDialog.hide(
              `confirmDialog_${_self.props.objectId}_${i}`
            );
            UIConfirmDialog.show(
              `confirmDialog_${_self.props.objectId}_${index}`
            );
          }}
        />
      );
    });

    if (!msgDialog || msgDialog.length < 1) {
      msgDialog = (
        <UIConfirmDialog
          id={`confirmDialog_${_self.props.objectId}_0`}
          title={_self.state.title}
          message=""
          confirmText={i18n.ClaimCommon.Continue}
          cancelText={i18n.ClaimCommon.Cancel}
          onConfirm={_self.props.onConfirm}
        />
      );
    }

    return <div>{msgDialog}</div>;
  }
}
reactMixin.onClass(CommonConfirmDialog, BindToMixin);
