import React, { Component } from 'react';
import {
  UIDialog,
  UIButton,
  UIDropZone,
  UIBox,
  DataContext,
} from 'RainbowUI';
import urlConfig from '../../../UrlConfig';
import PartyAction from '../actions/PartyAction';

export default class UploadDataBreachDocumentDialog extends Component {
  constructor(props) {
    super(props);
    const url = `${urlConfig.uploadFile}/${DataContext.get(
      'uploadUrlParam'
    )}`;
    this.state = {
      url,
    };
  }

  componentWillMount() {}

  componentDidMount() {}

  render() {
    const { url } = this.state;
    return (
      <div>
        <UIDropZone
          id="dropZone"
          onSuccess={this.onCompleteUpload.bind(this)}
          autoUpload="true"
          url={url}
          acceptFile=".doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,.jpg,.png,.tiff,.tif,image/bmp,image/gif,application/pdf"
          colspan="2"
        />
        <UIBox direction="center">
          <UIButton
            value={i18n.Exit}
            onClick={this.hideDialog.bind(this)}
          />
        </UIBox>
      </div>
    );
  }

  hideDialog() {
    UIDialog.hide('uploadDataBreachDocument');
  }

  onCompleteUpload() {
    const _self = this;
    const party = DataContext.get('Party');
    const partyAction = new PartyAction();
    const partyResult = partyAction.loadPartyByPartyId(party.PartyId);
    _self.props.renderDataBreachDatas(
      partyResult.PartyGDPR.PartyGDPRDataBreachList
    );
  }
}
