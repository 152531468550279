const {
  addEventListener,
  removeEventListener,
  localStorage,
  console,
} = window;
let oktaAuthClient;
let config;

function parseURL(url) {
  const a = document.createElement('a');
  a.href = url;
  const host = a.hostname;
  const { port } = a;
  const index = url.indexOf('//');
  const http = url.substring(0, index);
  return {
    source: url,
    baseUrl: port ? `${http}//${host}:${port}` : `${http}//${host}`,
    isLocalHost:
      a.host.indexOf('localhost') > -1 ||
      a.host.indexOf('127.0.0.1') > -1,
    protocol: a.protocol.replace(':', ''),
    host: a.hostname,
    port: a.port,
    query: a.search,
    params: (function () {
      const ret = {};
      const seg = a.search.replace(/^\?/, '').split('&');
      const len = seg.length;
      let i = 0;
      let s;
      for (; i < len; i++) {
        if (!seg[i]) {
          continue;
        }
        s = seg[i].split('=');
        ret[s[0]] = s[1];
      }
      return ret;
    })(),
    file: (a.pathname.match(/\/([^\/?#]+)$/i) || [, ''])[1],
    hash: a.hash.replace('#', ''),
    path: a.pathname.replace(/^([^\/])/, '/$1'),
    relative: (a.href.match(/tps?:\/\/[^\/]+(.+)/) || [, ''])[1],
    segments: a.pathname.replace(/^\//, '').split('/'),
  };
}

const currentUrl = parseURL(window.location.href);

const initAuthClient = () => {
  oktaAuthClient = new OktaAuth({
    // Org URL;
    issuer: config.oktaIssuer,
    // // OpenID Connect APP Client ID;
    clientId: config.oktaClientId,
    // // Trusted Origin Redirect URI;
    redirectUri: config.callbackUrl,
  });
};

const parseTokenFromURL = () => {
  console.log('>>>>>>>>>> [Okta] getting token from code');
  oktaAuthClient.token.parseFromUrl().then(res => {
    const { tokens } = res;
    oktaAuthClient.tokenManager.add('idToken', tokens.idToken);
    oktaAuthClient.tokenManager.add(
      'accessToken',
      tokens.accessToken
    );
    localStorage.setItem(
      config.key,
      // prepend 13 characters to by pass RainbowUI token retrieval logic for CAS;
      `0123456789012${tokens.idToken.idToken}`
    );
    oktaAuthClient.tokenManager.on('renewed', newToken => {
      localStorage.setItem(
        config.key,
        newToken.tokens.idToken.idToken
      );
    });
    $.cookie('session', 'active');
    liveLonger(); // initialize session timer
    console.log(">>>>>>>>>> [Okta] logged in !!!!! ");
    // refresh page since log-in is async and the page renderring decision has already passed
    window.location.reload();
  });
};

const getAuthCode = () => {
  $.cookie('session', 'logging-in');
  console.log('>>>>>>>>>> [Okta] go to okta to log in');
  oktaAuthClient.token.getWithRedirect({
    scopes: ['openid', 'email', 'profile'],
    responseType: 'code',
  });
};

const codeReturned = () => window.location.href.includes('code=');
const liveLonger = () => {
  if ($.cookie('session') !== 'active') return;
  const time = new Date();
  time.setTime(time.getTime() + config.expiry);
  console.log('>>>>>>>>>> life extended');
  $.cookie('session', 'active', { expires: time });
};

const login = () => {
  oktaAuthClient.tokenManager
    .get('accessToken')
    .then(accessToken => {
      if (!accessToken) {
        storeAccessToken();
      } else if (!$.cookie('session')) { // session timeout already
        logout();
      } else {
        addEventListeners();
      }
    });
};

const storeAccessToken = () => {
  if (codeReturned() && $.cookie('session') === 'logging-in') {
    parseTokenFromURL();
  } else {
    getAuthCode();
  }
};

const isLocalStorageCleared = () => {
  if (
    !localStorage.getItem('okta-token-storage') ||
    localStorage.getItem('okta-token-storage') === '{}'
  ) {
    logout();
  }
};

const logout = () => {
  if ($.cookie('session') === 'logging-in') return;
  console.log('>>>>>>>>>> [Okta] logging out...');
  removeEventListener('storage', isLocalStorageCleared);
  localStorage.clear();
  $.cookie('session', null);
  oktaAuthClient.revokeAccessToken().then(() => {
    oktaAuthClient
      .closeSession()
      .then(() => {
        console.log('>>>>>>>>>> [okta] logged out');
        window.location.reload();
      });
  });
};

const getConfig = () =>
  $.ajax({
    method: 'get',
    url: './project_config.json',
    async: false,
    dataType: 'json',
    success(data) {
      config = data;
      if (!currentUrl.isLocalHost) {
        $.ajax({
          method: 'post',
          url: `${config.serverUrl}config/v1/ui/config/keys`,
          async: false,
          dataType: 'json',
          success(data) {
            config = {
              ...config,
              auth_type: data['auth.type.tokenserver'],
              oktaIssuer: data['okta.oauth2.issuer'],
              oktaClientId: data['okta.oauth2.audience'],
              isDummyPolicy: data.UI_USE_DUMMY_POLICY === 'true',
              cas_url: `${data.UI_CAS_SERVER}/cas`,
              serverUrl: `${data.UI_CONTAINER_API_URL}`,
              claimIntserverUrl: `${data.UI_CONTAINER_CLAIM_INT_API_URL}`,
              policyDataUploadEnabled: `${data.UI_POLICY_DATA_UPLOAD_ENABLED}`,
              CLAIM_HOME_URL: `${currentUrl.baseUrl}/ui/us-vault-claim/`,
              callbackUrl: currentUrl.baseUrl + currentUrl.path,
            };
          },
          error(text) {
            console.error('config error', text);
          },
        });
      }
      localStorage.setItem('project_config', JSON.stringify(config));
    },
    error(text) {
      console.error('config error', text);
    },
  });

const checkToken = () => {
  if (oktaAuthClient) {
    oktaAuthClient.tokenManager
      .get('accessToken')
      .then(accessToken => {
        // Token has expired;
        if (!accessToken) {
          oktaAuthClient.tokenManager
            .renew('accessToken')
            .catch(logout);
        } else {
          liveLonger();
        }
      });
  }
};

const checkSession = () => {
  if (oktaAuthClient) {
    if (!$.cookie('session')) {
      logout();
    } else {
      checkToken();
    }
  }
};

const addEventListeners = () => {
  addEventListener('storage', isLocalStorageCleared);
};

const initApp = () => {
  getConfig();
  initAuthClient();
  login();
};

initApp();

export { oktaAuthClient, checkSession, checkToken, logout };
