import ReactDOMServer from 'react-dom/server';
import {
  UIUpdatePanel,
  UIDataTable,
  UIColumn,
  UILink,
  UIParam,
  UISelect,
  UIText,
  UICurrency,
  UICheckbox,
  UISmartPanelGrid,
  UIDateTimePicker,
  UICell,
  UIDialog,
  UIBox,
  UIButton,
  DataContext,
  MessageHelper,
} from 'RainbowUI';
import moment from 'moment';
import ARAPSearchAction from './ARAPSearchAction';
import CommonCodeTable from '../../codetable/CodeTable';
import CommonAction from '../../action/CommonAction';
import FeeType from './FeeType';
import FeeDetailDialog from './FeeDetailDialog';
import FeeDetailDialogAction from './FeeDetailDialogAction';
import EU00CommonMath from '../../service/EU00CommonMath';
import Note from './Note';

const ARAPSearchResult = React.createClass({
  getInitialState() {
    const { policyNo } = this.props;
    const { isQuery } = this.props;
    const { currencyService } = this.props;
    return {
      NoteType: this.props.NoteType,
      PolicyNo: policyNo,
      IsQuery: isQuery,
      currencyService,
    };
  },

  componentWillMount() {
    this.extendWindowMathFn();
  },

  onRowSelect(event) {
    const data = event.selectRecord;
    if (data.ArapType !== -1001) {
      MessageHelper.warning('Please select policy premium recocrd');
      DataContext.put('paymentModel', null);
      this.setState();
    } else if (data.Balance == 0 || !data.IsAR) {
      MessageHelper.warning(
        'Please select recocrd still remain balance, Please choose another record for payment'
      );
      DataContext.put('paymentModel', null);
      this.setState();
    } else {
      DataContext.put('paymentModel', data);
    }
  },

  extendWindowMathFn() {
    if (
      !Math.accAdd ||
      !Math.accSub ||
      !Math.accMul ||
      !Math.accDiv
    ) {
      EU00CommonMath.extendWindowMathFn();
    }
  },

  render() {
    const arapSearchAction = new ARAPSearchAction();
    const commonCodeTable = new CommonCodeTable();
    const commonAction = new CommonAction();
    const { NoteType } = this.state;
    const _self = this;
    DataContext.put('PAGE_READONLY', false);
    const { currencyService } = this.state;
    const feeDetailDialogAction = new FeeDetailDialogAction();
    const { selectable } = this.props;
    return (
      <div>
        <Note />
        <UIUpdatePanel id="updateAparResultPanel">
          <UIDataTable
            id="query_resultTable"
            provider={arapSearchAction.getARAPProvider.bind(this)}
            indexable="false"
            headerTitle={i18n.arapResult}
            detailable="true"
            selectionMode={selectable}
            onRowSelect={this.onRowSelect}
            rowDetailRender={this.renderRowDetail}
          >
            <UIColumn
              headerTitle={i18n.dncnNo}
              sortable="true"
              value="DebitCreditNo"
              render={data => {
                let amount = data.Amount;
                let localAmount = data.LocalAmount;
                let balance = data.Balance;
                if (!data.IsAR) {
                  amount = 0 - amount;
                  balance = 0 - balance;
                  localAmount = 0 - localAmount;
                }
                let commissionAmount = data.CommissionAmount;
                if (data.IsARCommission && !data.IsARCommission) {
                  commissionAmount = 0 - data.CommissionAmount;
                }
                return (
                  <div>
                    <UILink
                      value={data.DebitCreditNo}
                      onClick={commonAction.openArapDetail}
                    >
                      <UIParam name="feeId" value={data.FeeId} />
                      <UIParam
                        name="groupCount"
                        value={data.GroupCount}
                      />
                      <UIParam name="amount" value={amount} />
                      <UIParam name="balance" value={balance} />
                      <UIParam
                        name="localAmount"
                        value={localAmount}
                      />
                      <UIParam
                        name="commissionAmount"
                        value={commissionAmount}
                      />
                    </UILink>
                  </div>
                );
              }}
            />

            <UIColumn
              headerTitle={i18n.arapType}
              value="ArapType"
              sortable="true"
            >
              <UISelect
                io="out"
                codeTable={commonCodeTable.getARAPType}
              />
            </UIColumn>

            <UIColumn
              headerTitle={i18n.PolicyNo}
              value="PolicyNo"
              sortable="true"
              render={data => {
                return (
                  <div>
                    <UILink
                      value={data.PolicyNo}
                      onClick={commonAction.openPolicyDetail}
                    >
                      <UIParam
                        name="policyId"
                        value={data.PolicyId}
                      />
                      <UIParam
                        name="policyNo"
                        value={data.PolicyNo}
                      />
                    </UILink>
                  </div>
                );
              }}
            />

            <UIColumn
              headerTitle={i18n.payerOrPayee}
              value="PartyName"
              render={data => {
                const name = data.PartyName;
                return <UIText io="out" value={name} />;
              }}
            />
            <UIColumn
              headerTitle={i18n.amount}
              textAlign="center"
              render={data => {
                let amount = data.Amount;
                if (!data.IsAR) {
                  amount = 0 - amount;
                }
                let unit = '';
                if (data.CurrencyCode) {
                  unit = currencyService.getCurrencySignByCurrencyCode(
                    data.CurrencyCode
                  );
                }
                return (
                  <UICurrency value={amount} io="out" unit={unit} />
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.offsetCommission}
              render={data => {
                let type = '';
                if (
                  data.PaymentType &&
                  data.PaymentType == 1 &&
                  (data.FeeType == FeeType.DIRECT_BUSINESS ||
                    data.FeeType == FeeType.SETTLE_COMMISSION_ARAP)
                ) {
                  type = 'Y';
                }
                return (
                  <UICheckbox
                    enabled="false"
                    single="true"
                    defaultValue={type}
                  />
                );
              }}
            />

            <UIColumn
              headerTitle={i18n.balance}
              textAlign="center"
              render={data => {
                let balance = data.Balance;
                if (!data.IsAR) {
                  balance = 0 - balance;
                }
                let unit = '';
                if (data.CurrencyCode) {
                  unit = currencyService.getCurrencySignByCurrencyCode(
                    data.CurrencyCode
                  );
                }
                return (
                  <UICurrency value={balance} io="out" unit={unit} />
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.InsPlan}
              render={data => {
                return (
                  <UIUpdatePanel
                    id={`updateInstallmentPlanLink_${data.dataIndex}`}
                    render={() => {
                      if (data.GroupCount > 1) {
                        return (
                          <div>
                            <UILink
                              id={`installmentPlan_${data.dataIndex}`}
                              value={i18n.InsDetail}
                              onClick={
                                arapSearchAction.onClickedInstallmentPlanInfo
                              }
                            >
                              <UIParam name="data" value={data} />
                            </UILink>
                          </div>
                        );
                      }
                      return <div />;
                    }}
                  />
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.TransDetail}
              render={data => {
                let enabled = false;
                if (data.ArapType == -1001) {
                  enabled = true;
                }
                return (
                  <div>
                    <UILink
                      id={`ddd${data.dataIndex}`}
                      enabled={enabled}
                      value="Fee detail"
                      onClick={feeDetailDialogAction.onClickedFeeDetail.bind(
                        _self
                      )}
                    >
                      <UIParam name="feeId" value={data.FeeId} />
                      <UIParam name="data" value={data} />
                    </UILink>
                  </div>
                );
              }}
            />

            <UIColumn
              headerTitle={i18n.note}
              render={data => {
                let hasNote = data.HasNote;
                if (hasNote == '1') {
                  hasNote = 'Y';
                } else {
                  hasNote = 'N';
                }
                return (
                  <div>
                    <UILink
                      value={hasNote}
                      onClick={arapSearchAction.onClickedNote}
                    >
                      <UIParam name="refId" value={data.FeeId} />
                      <UIParam
                        name="noteType"
                        value={NoteType.ARAP_FEE}
                      />
                      <UIParam
                        name="tableId"
                        value="query_resultTable"
                      />
                    </UILink>
                  </div>
                );
              }}
            />
          </UIDataTable>
        </UIUpdatePanel>

        {this.installmentPlanInfoDialogRender()}
        <FeeDetailDialog ref="feeDetailDialog" />
      </div>
    );
  },

  renderRowDetail(data) {
    const commonCodeTable = new CommonCodeTable();

    const groupCount = data.GroupCount;
    let commissionAmount = data.CommissionAmount;
    if (data.IsARCommission && !data.IsARCommission) {
      commissionAmount = 0 - data.CommissionAmount;
    }
    let amount = data.LocalAmount;
    if (!data.IsAR) {
      amount = 0 - amount;
    }
    let policyPeriod = '';
    if (data.PolicyEffDate) {
      policyPeriod = moment(data.PolicyEffDate).format(
        i18n.DateFormatBCP
      );
    }
    policyPeriod += '~';
    if (data.PolicyExpDate) {
      policyPeriod += moment(data.PolicyExpDate).format(
        i18n.DateFormatBCP
      );
    }

    let unitLocal = '';
    if (data.LocalCurrency) {
      unitLocal = window.EU00.getCurrencySignByCurrencyCode(
        data.LocalCurrency
      );
    }
    let unitCommission = '';
    if (data.CommissionCurrencyCode) {
      unitCommission = window.EU00.getCurrencySignByCurrencyCode(
        data.CommissionCurrencyCode
      );
    }

    if (groupCount > 1) {
      return ReactDOMServer.renderToString(
        <UISmartPanelGrid column="3" widthAllocation="6,6">
          <UIDateTimePicker
            format="MM/DD/YYYY"
            label={i18n.arapGenerationDate}
            value={data.InsertTime}
            io="out"
          />
          <UICell />
          <UISelect
            label={i18n.branch}
            value={data.BranchId}
            io="out"
            codeTable={commonCodeTable.getBranch}
          />
          <UIText
            io="out"
            label={i18n.customer}
            value={data.PolicyHolderName}
          />
          <UIText
            io="out"
            label={i18n.Producer}
            value={data.AgentName}
          />
          <UIText
            label={i18n.policyPeriod}
            value={policyPeriod}
            io="out"
          />
          <UIText
            label={i18n.EndorsementNo}
            value={data.EndoNo}
            io="out"
          />
          <UISelect
            label={i18n.endorsementType}
            value={data.EndoType}
            codeTable={commonCodeTable.getARAPEndoType}
            io="out"
          />
          <UIDateTimePicker
            format="MM/DD/YYYY"
            label={i18n.endorsementEffDate}
            value={data.EndoEffDate}
            io="out"
          />
          <UICurrency
            label={`${i18n.arapAmount} in ${data.LocalCurrency}`}
            value={amount}
            io="out"
            unit={unitLocal}
          />
          <UIText
            label={`${i18n.exchangeRate}(ARAP:${data.LocalCurrency})`}
            value={data.ExchangeRate.toFixed(6)}
            io="out"
          />
          <UICurrency
            label={i18n.commissionAmount}
            value={commissionAmount}
            io="out"
            unit={unitCommission}
          />
          <UIText
            label={i18n.claimNo}
            value={data.ClaimNo}
            io="out"
          />
          <UIText
            label={i18n.statementNo}
            value={data.StatementNo}
            io="out"
          />
        </UISmartPanelGrid>
      );
    }
    return ReactDOMServer.renderToString(
      <UISmartPanelGrid column="3" widthAllocation="6,6">
        <UIDateTimePicker
          format="MM/DD/YYYY"
          label={i18n.arapGenerationDate}
          value={data.InsertTime}
          io="out"
        />
        <UIText label={i18n.arapNo} value={data.FeeNo} io="out" />
        <UISelect
          label={i18n.branch}
          value={data.BranchId}
          io="out"
          codeTable={commonCodeTable.getBranch}
        />
        <UIText
          io="out"
          label={i18n.customer}
          value={data.PolicyHolderName}
        />
        <UIText
          io="out"
          label={i18n.Producer}
          value={data.AgentName}
        />
        <UIText
          label={i18n.policyPeriod}
          value={policyPeriod}
          io="out"
        />
        <UIText
          label={i18n.EndorsementNo}
          value={data.EndoNo}
          io="out"
        />
        <UISelect
          label={i18n.endorsementType}
          value={data.EndoType}
          codeTable={commonCodeTable.getARAPEndoType}
          io="out"
        />
        <UIDateTimePicker
          format="MM/DD/YYYY"
          label={i18n.endorsementEffDate}
          value={data.EndoEffDate}
          io="out"
        />
        <UICurrency
          label={`${i18n.arapAmount} in ${data.LocalCurrency}`}
          value={amount}
          io="out"
          unit={unitLocal}
        />
        <UIText
          label={`${i18n.exchangeRate}(ARAP:${data.LocalCurrency})`}
          value={data.ExchangeRate.toFixed(6)}
          io="out"
        />
        <UICurrency
          label={i18n.commissionAmount}
          value={commissionAmount}
          io="out"
          unit={unitCommission}
        />
        <UISelect
          label={i18n.arapStatus}
          value={data.Status}
          io="out"
          codeTable={commonCodeTable.getARAPStatusAll}
        />
        <UIDateTimePicker
          format="MM/DD/YYYY"
          label={i18n.dueDate}
          value={data.DueDate}
          io="out"
        />
        <UISelect
          label={i18n.paymentMethod}
          value={data.Paymode}
          io="out"
          codeTable={commonCodeTable.getAllActivePaymentMethod}
        />
        <UIText label={i18n.claimNo} value={data.ClaimNo} io="out" />
        <UIText
          label={i18n.statementNo}
          value={data.StatementNo}
          io="out"
        />
      </UISmartPanelGrid>
    );
  },

  installmentPlanInfoDialogRender() {
    const arapSearchAction = new ARAPSearchAction();
    const commonCodeTable = new CommonCodeTable();
    const commonAction = new CommonAction();

    return (
      <UIDialog
        id="installmentPlanInfoDialog"
        title={i18n.installmentPlanDetail}
        width="80%"
      >
        <UIUpdatePanel>
          <UIDataTable
            id="installmentsTable"
            provider={
              arapSearchAction.getInstallmentDetailInfoProvider
            }
            pageable="false"
            indexable="false"
            detailVisible="0"
            detailable="false"
          >
            <UIColumn
              headerTitle={i18n.installmentNo}
              value="CurPeriod"
              render={data => {
                if (data.CurPeriod !== 'Total') {
                  return <UIText io="out" value={data.CurPeriod} />;
                }
                return (
                  <strong>
                    <UIText io="out" value={data.CurPeriod} />
                  </strong>
                );
              }}
            />

            <UIColumn
              headerTitle={i18n.arapNo}
              sortable="true"
              sortValue="FeeNo"
              render={data => {
                if (data.CurPeriod !== 'Total') {
                  return (
                    <div>
                      <UILink
                        value={data.FeeNo}
                        onClick={commonAction.openArapDetail}
                      >
                        <UIParam name="feeId" value={data.FeeId} />
                      </UILink>
                    </div>
                  );
                }
              }}
            />

            <UIColumn
              headerTitle={i18n.originalInstallmentAmount}
              render={data => {
                if (data.CurPeriod !== 'Total') {
                  let amount = data.Amount;
                  if (!data.IsAR) {
                    amount = 0 - amount;
                  }
                  let unit = '';
                  if (data.CurrencyCode) {
                    unit = window.EU00.getCurrencySignByCurrencyCode(
                      data.CurrencyCode
                    );
                  }
                  return (
                    <UICurrency value={amount} io="out" unit={unit} />
                  );
                }
                return (
                  <strong>
                    <UICurrency
                      io="out"
                      unit={data.Unit}
                      value={data.Amount}
                    />
                  </strong>
                );
              }}
            />

            <UIColumn
              headerTitle={i18n.offsetCommission}
              render={data => {
                if (data.CurPeriod !== 'Total') {
                  let type = '';
                  if (
                    data.PaymentType &&
                    data.PaymentType == 1 &&
                    (data.FeeType == FeeType.DIRECT_BUSINESS ||
                      data.FeeType == FeeType.SETTLE_COMMISSION_ARAP)
                  ) {
                    type = 'Y';
                  }
                  if (data.CurPeriod === 'Total') {
                    return <div />;
                  }
                  return (
                    <UICheckbox
                      enabled="false"
                      single="true"
                      defaultValue={type}
                    />
                  );
                }
              }}
            />

            <UIColumn
              headerTitle={i18n.commission}
              render={data => {
                if (data.CurPeriod !== 'Total') {
                  let unit = '';
                  if (data.CommissionCurrencyCode) {
                    unit = window.EU00.getCurrencySignByCurrencyCode(
                      data.CommissionCurrencyCode
                    );
                  }
                  return (
                    <UICurrency
                      value={data.CommissionAmount}
                      io="out"
                      unit={unit}
                    />
                  );
                }
                return (
                  <strong>
                    <UICurrency
                      io="out"
                      unit={data.UnitCommisson}
                      value={data.CommissionAmount}
                    />
                  </strong>
                );
              }}
            />

            <UIColumn
              headerTitle={i18n.osInstallmentamount}
              render={datas => {
                const data = datas;
                return (
                  <UIUpdatePanel
                    id={`updateInstallmentsBalance_${data.dataIndex}`}
                    render={() => {
                      if (data.CurPeriod !== 'Total') {
                        data.NewBalance = data.Balance;
                        if (Math.abs(data.NewAppliedAmount) > 0) {
                          data.NewBalance =
                            data.Balance -
                            Math.abs(data.NewAppliedAmount);
                        }
                        let balance = data.NewBalance;
                        if (!data.IsAR) {
                          balance = 0 - balance;
                        }
                        let unit = '';
                        if (data.CurrencyCode) {
                          unit = window.EU00.getCurrencySignByCurrencyCode(
                            data.CurrencyCode
                          );
                        }
                        return (
                          <UICurrency
                            id={`InstallmentsBalance_${data.dataIndex}`}
                            value={balance}
                            io="out"
                            allowNegative="true"
                            unit={unit}
                          />
                        );
                      }
                      return (
                        <strong>
                          <UICurrency
                            io="out"
                            unit={data.Unit}
                            value={data.Balance}
                          />
                        </strong>
                      );
                    }}
                  />
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.arapStatus}
              value="Status"
              render={data => {
                if (data.CurPeriod !== 'Total') {
                  return (
                    <UISelect
                      io="out"
                      value={data.Status}
                      codeTable={commonCodeTable.getARAPStatusAll}
                    />
                  );
                }
              }}
            />

            <UIColumn
              headerTitle={i18n.dueDate}
              value="DueDate"
              render={data => {
                if (data.CurPeriod !== 'Total') {
                  return (
                    <UIDateTimePicker
                      format="MM/DD/YYYY"
                      io="out"
                      value={data.DueDate}
                    />
                  );
                }
              }}
            />

            <UIColumn
              headerTitle={i18n.xmlFile}
              render={data => {
                if (data.CurPeriod !== 'Total') {
                  return (
                    <div>
                      <UILink
                        value={data.DddcFileName}
                        onClick={
                          arapSearchAction.onDownloadXMLlinkClick
                        }
                      >
                        <UIParam
                          name="DddcMsgNo"
                          value={data.DddcMsgNo}
                        />
                      </UILink>
                    </div>
                  );
                }
              }}
            />
          </UIDataTable>
        </UIUpdatePanel>
        <UIBox direction="center">
          <UIButton
            value={i18n.Exit}
            onClick={
              arapSearchAction.onInstallmentDialogClickedCancel
            }
          />
        </UIBox>
      </UIDialog>
    );
  },
});
export default ARAPSearchResult;
