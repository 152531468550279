import {
  UIPanel,
  UICell,
  UIButton,
  UISelect,
  UIParam,
  UISmartPanelGrid,
  DataContext,
} from 'RainbowUI';
import BindToMixin from 'react-binding';
import PropTypes from 'prop-types';
import {
  ClaimCstUI,
  CodeTableAction,
  CodeTableCstUI,
} from 'ClaimCommonConstant';
import ClaimSettlement from 'ClaimSettlement';

const SettlementLoadService = require('../../common/service/SettlementLoadService');

const SettlementLoadAction = ClaimSettlement.getSettlementLoadAction();
const SettlementItemInfo = ClaimSettlement.getSettlementItemInfo();

const RecoveryInfoLoad = React.createClass({
  mixins: [BindToMixin],
  propTypes: {
    settlementInfo: PropTypes.object.isRequired,
    otherInfo: PropTypes.object.isRequired,
    readOnly: PropTypes.bool,
    isApprove: PropTypes.bool,
  },
  getInitialState() {
    return {
      settlementInfo: this.props.settlementInfo,
      settlementTypeList: '',
    };
  },

  checkReadOnlyCoInsurance(coInsurancePayment) {
    let readOnlyPayment;
    if (coInsurancePayment) {
      let codePay = [];
      if (
        ClaimCstUI.CoLeader.PayFull == coInsurancePayment ||
        ClaimCstUI.CoLeader.PayPortion == coInsurancePayment
      ) {
        codePay = CodeTableAction.loadDataList(
          CodeTableCstUI.ClaimCoLeader,
          null
        );
      } else {
        codePay = CodeTableAction.loadDataList(
          CodeTableCstUI.ClaimCoFollower,
          null
        );
      }
      readOnlyPayment = (
        <UISmartPanelGrid id="fullPortion_grid_readOnly" column="3">
          <UISelect
            id="fullPortion_select_readOnly"
            codeTable={codePay}
            value={coInsurancePayment}
            label={i18n.ClaimSettlement.CoInsurancePayment}
            visibled={coInsurancePayment !== 'full'}
            io="out"
          />
        </UISmartPanelGrid>
      );
    } else {
      readOnlyPayment = <div />;
    }
    return readOnlyPayment;
  },
  updateSettlementPayee(
    name,
    newvalue,
    itemIndex,
    payeeIndex,
    roleType
  ) {
    DataContext.put(`settlementPayeeName${payeeIndex}`, name);
    DataContext.put(`roleType${payeeIndex}`, roleType);

    if (name) {
      this.props.settlementInfo.SettlementPayee[
        payeeIndex
      ].PayeeName = name;

      if (name.codes && name.codes.length) {
        const payeeId = name.codes[0].id;
        this.props.settlementInfo.SettlementPayee[
          payeeIndex
        ].PayeeId = payeeId;
      }
    }
    this.props.settlementInfo.SettlementPayee[
      payeeIndex
    ].SettlementItem[itemIndex].PaymentType = newvalue;
    this.setState({ settlementInfo: this.props.settlementInfo });
  },
  getSettlementType() {
    let settTypeList = [];
    settTypeList = SettlementLoadService.getSettlementTypeList();
    this.state.settlementTypeList = settTypeList;
  },
  checkEditCoInsurance(coInsurancePayment) {
    const coInsurance = this.props.CoInsurance;
    let codePay = {};
    if (
      coInsurance.CoinLeaderFlag == ClaimCstUI.BooleanConstant.TRUE
    ) {
      codePay = CodeTableAction.loadDataList(
        CodeTableCstUI.ClaimCoLeader,
        null
      );
    } else {
      codePay = CodeTableAction.loadDataList(
        CodeTableCstUI.ClaimCoFollower,
        null
      );
    }
    if (coInsurancePayment) {
      this.props.updateCoInsurancePayment(coInsurancePayment);
    }
    const cPayment = (
      <UISmartPanelGrid id="fullPortion_grid" column="3">
        <UISelect
          id="fullPortion_select"
          codeTable={codePay}
          value={coInsurancePayment || ''}
          onChange={SettlementLoadAction.chooseFullOrPortion}
          visibled={coInsurancePayment !== 'full'}
          required="true"
          validationGroup="fullOrPartion_validate"
          label={i18n.ClaimSettlement.CoInsurancePayment}
        >
          <UIParam
            name="taskInstanceId"
            value={this.props.taskInstanceId}
          />
          <UIParam name="coInsurance" value={coInsurance} />
        </UISelect>
      </UISmartPanelGrid>
    );
    return cPayment;
  },

  render() {
    const _self = this;
    const componentId = _self.props.componentId || '';
    const settlementPayee = this.getSettlementPayee();
    let coInsurancePayment =
      _self.props.settlementInfo.CoInsurancePayment;
    const { history, CoInsurance } = this.props;
    let style;
    if (history) {
      style = 'default';
    } else {
      style = 'primary';
    }
    if (_self.props.readOnly) {
      const readOnlyPayment = _self.checkReadOnlyCoInsurance(
        coInsurancePayment
      );
      return (
        <UIPanel
          id={`settlement_detail${componentId}`}
          styleClass={style}
          panelTitle={i18n.ClaimSettlement.RecoveryDetail}
          toggleable="true"
        >
          {readOnlyPayment}
          {settlementPayee}
        </UIPanel>
      );
    }
    let cPayment;
    if (CoInsurance.CoinFlag == ClaimCstUI.BooleanConstant.TRUE) {
      if (CoInsurance.CoinLeaderFlag) {
        coInsurancePayment = ClaimCstUI.CoLeader.PayFull;
      }
      cPayment = _self.checkEditCoInsurance(coInsurancePayment);
      if (!coInsurancePayment) {
        return (
          <UIPanel
            id="settlement_detail"
            panelTitle={i18n.ClaimSettlement.SettlementDetail}
            toggleable="true"
            panelFooter={(
              <UIButton
                value={i18n.ClaimSettlement.AddPayee}
                onClick={SettlementLoadAction.addPayee}
                validationGroup="fullOrPartion_validate"
                causeValidation="true"
              />
            )}
          >
            {cPayment}
          </UIPanel>
        );
      }
    } else {
      cPayment = <div />;
    }
    return (
      <UIPanel
        id={`settlement_detail${componentId}`}
        panelTitle={i18n.ClaimSettlement.RecoveryDetail}
        toggleable="true"
        panelFooter={(
          <UIButton
            value={i18n.ClaimSettlement.AddRecoveryPayer}
            onClick={SettlementLoadAction.addPayee}
          />
        )}
      >
        {cPayment}
        {settlementPayee}
      </UIPanel>
    );
  },

  getSettlementPayee() {
    const RecoveryPayeeInfo = ClaimSettlement.getRecoveryPayeeInfo();
    const _self = this;
    const componentId = this.props.componentId || '';
    DataContext.put(
      'ClaimType',
      _self.props.settlementInfo.ClaimType
    );

    const settlementPayeeList = this.props.settlementInfo
      .SettlementPayee;
    if (!settlementPayeeList) {
      return <div />;
    }
    if (settlementPayeeList.length === 0) {
      return <div />;
    }

    const coInsurance = this.props.CoInsurance;

    const payeePanel = settlementPayeeList.map(
      (settlementPayee, payeeIndex) => {
        return (
          <div>
            <UICell style={{ textAlign: 'left' }}>
              <SettlementItemInfo
                payeeIndex={payeeIndex}
                settlementPayee={settlementPayee}
                otherInfo={_self.props.otherInfo}
                readOnly={_self.props.readOnly}
                caseId={_self.props.caseId}
                isApprove={_self.props.isApprove}
                taskCode={ClaimCstUI.CLAIM_TASK_CODE.Recovery}
                componentId={componentId}
                coInsurance={coInsurance}
                CoInsurancePayment={
                  _self.props.settlementInfo.CoInsurancePayment
                }
                settlementType={_self.state.settlementTypeList}
                updateSettlementPayee={_self.updateSettlementPayee}
              />
            </UICell>
            <UICell style={{ textAlign: 'left' }}>
              <RecoveryPayeeInfo
                index={payeeIndex}
                settlementPayee={settlementPayee}
                readOnly={_self.props.readOnly}
                componentId={componentId}
                caseId={_self.props.caseId}
              />
            </UICell>
          </div>
        );
      }
    );
    return payeePanel;
  },
});
export default RecoveryInfoLoad;
