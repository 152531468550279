import {
  UIDialog,
  UIDialogFooter,
  UIDataTable,
  UIButton,
  UIColumn,
  UISmartPanelGrid,
  UIText,
  UIDateTimePicker,
  UISelect,
} from 'RainbowUI';

import PropTypes from 'prop-types';
import ClaimLitigationAction from '../../action/ClaimLitigationAction';
import CodeTableAction from '../../action/CodeTableAction';
import CodeTableCstUI from '../../constant/CodeTableCstUI';

const LitigationModificationHistoryDialog = React.createClass({
  propTypes: {
    domain: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  },

  getDefaultProps() {
    return { domain: 'claim_litigation' };
  },

  getInitialState() {
    return { data: {} };
  },

  showDialog(data) {
    this.setState({ data });
    UIDialog.show(`ModificationHistory_Dialog_${this.props.domain}`);
  },

  getHistoryList(pageIndex, pageSize) {
    return ClaimLitigationAction.getAuditDataList(
      this.state.data,
      pageIndex,
      pageSize
    );
  },

  render() {
    const { domain } = this.state;
    return (
      <UIDialog
        id={`ModificationHistory_Dialog_${domain}`}
        width="95%"
      >
        <UIDataTable
          id={`ModificationHistory_Dialog_${domain}`}
          indexable="false"
          pageable="true"
          provider={this.getHistoryList}
          headerTitle={i18n.ClaimCommon.ModificationHistory}
          isHeading="true"
        >
          <UIColumn
            headerTitle={i18n.ClaimCommon.CreateDate}
            value="AuditTime"
          >
            <UIDateTimePicker
              placeHolder="MM/DD/YYYY HH:mm:ss"
              format="MM/DD/YYYY HH:mm:ss"
              io="out"
            />
          </UIColumn>
          <UIColumn
            headerTitle={i18n.ClaimCommon.CreateUser}
            value="OperatorId"
          >
            <UISelect
              codeTable={CodeTableAction.loadDataList(
                CodeTableCstUI.ClaimUser,
                null
              )}
              io="out"
            />
          </UIColumn>

          <UIColumn
            headerTitle={i18n.ClaimCommon.Detail}
            render={data => {
              const coinList = data.ChangeFieldList.map(val_ => {
                return (
                  <div>
                    <UIText io="out" value={val_} />
                  </div>
                );
              });
              return (
                <div>
                  {coinList}
                  <UIText io="out" />
                </div>
              );
            }}
          />
        </UIDataTable>

        <UIDialogFooter>
          <UIButton
            value={i18n.ClaimCommon.Cancel}
            buttonType="cancel"
          />
        </UIDialogFooter>
      </UIDialog>
    );
  },

  getDetail(changeDataList) {
    const result = changeDataList.map(data => {
      return <UIText value={data} io="out" />;
    });

    return <UISmartPanelGrid column="1">{result}</UISmartPanelGrid>;
  },
});
export default LitigationModificationHistoryDialog;
