import {
  UITabItem,
  UITab,
  UIParam,
  UICell,
  DataContext,
  UIUpdatePanel,
  UpdateContext,
} from 'RainbowUI';
import ClaimCommonExt from 'ClaimCommonExt';
import TaskTransferDialog from '../TaskTransferDialog';
import ClaimTabStore from '../../store/ClaimTabStore';
import ClaimTabCommonStore from '../../store/ClaimTabCommonStore';
import RiAction from '../../../claimparty/action/RiAction';

const ClaimInfoTab = ClaimCommonExt.getClaimInfoTab();

const ClaimInfoTabs = React.createClass({
  getInitialState() {
    const ReinsuranceDetail = {};
    RiAction.generateReinsuranceDetail(
      ReinsuranceDetail,
      this.props.claimInfo.props.data
    );
    const id = null;
    if (ReinsuranceDetail.RiPolicyHistoryId) {
      /* empty */
    }
    return {
      litigationTab: '',
      reserveApprovalTasks: [],
      id,
      ReinsuranceDetail,
    };
  },

  handleClaimTabStoreChange(state) {
    switch (state.eventType) {
      case 'ReserveApprovalTaskTransferAssign':
      case 'ReserveApprovalTaskTransferNoAssign':
      case 'WithOutPolicyInsuredDialog':
      case 'TaskComplete':
        this.refs.ClaimTaskTransferDialog.showDialog({
          CaseId: this.props.claimData.CaseId,
        });
        break;
      default:
    }
  },

  componentDidMount() {
    ClaimTabStore.listen(this.handleClaimTabStoreChange);
    ClaimTabCommonStore.listen(this.handleClaimTabStoreChange);
  },

  componentWillUnmount() {
    ClaimTabStore.unlisten(this.handleClaimTabStoreChange);
    ClaimTabCommonStore.unlisten(this.handleClaimTabStoreChange);
  },

  updateLitigationTabs(flag) {
    if (flag) {
      this.setState({
        litigationTab: `${i18n.ClaimCommon.Litigation} (Y) `,
      });
    } else {
      this.setState({ litigationTab: i18n.ClaimCommon.Litigation });
    }
  },

  claimTabAction(event) {
    if (event.getParameter('action')) {
      event.getParameter('action')(event);
    }
    if (event.component.props.title === 'Documents') {
      DataContext.put('dmsUpdate', true);
      UpdateContext.forceUpdate('plan_info');
    }
  },
  orderTabs(accidentTabs = []) {
    let tabsOrdered = [];
    [
      'Tasks',
      'Notes (Y) ',
      'Notes',
      'Diary',
      'Documents',
      'Claim Party Maintenance',
      'Litigation',
      'Payment',
      'Claim Financial Summary',
      'Recovery',
      'Refund',
      'Premium Detail',
      'Other Policy Claims',
      'Reinsurance',
      'Calculation',
      'Subrogation',
      'Settlement Approval',
      'Recovery Approval',
      'Refund Approval',
      'Calculation Approval',
      'Reserve Update Approval',
      'Reserve Closure Approval',
      'Subrogation Approval',
    ].forEach(el => {
      let tempEl = accidentTabs.find(tab => tab.title === el)
      if (tempEl) tabsOrdered.push(tempEl);
    })
    return tabsOrdered;
  },
  getAccidentItemAndActiveIndex() {
    const self = this;
    const { accidentTabs } = this.props;
    let flag = false;
    let tabsOrdered = this.orderTabs(accidentTabs)
    if (tabsOrdered instanceof Array && tabsOrdered.length > 0) {
      let activeIndex = 0;
      const dataTabs = tabsOrdered.map((tmpitem, pindex) => {
        const item = tmpitem;
        let dataParams = [];
        if (item.params instanceof Array && item.params.length > 0) {
          dataParams = item.params.map(param => {
            return <UIParam name={param.name} value={param.value} />;
          });
        }

        if (item.action) {
          dataParams.push(
            <UIParam name="action" value={item.action} />
          );
        }
        if (item.IsActive) {
          activeIndex = pindex + 1;
        }
        if (item.title === 'Reinsurance') {
          if (self.state.ReinsuranceDetail.ClaimInfoModel) {
            if (
              self.state.ReinsuranceDetail.ClaimInfoModel.Records
                .length > 0
            ) {
              _.forEach(
                self.state.ReinsuranceDetail.ClaimInfoModel.Records,
                record => {
                  if (
                    record.ClaimNo == self.props.claimData.ClaimNo
                  ) {
                    flag = true;
                  }
                }
              );
              _.forEach(
                self.state.ReinsuranceDetail.RiskUnitModel,
                data => {
                  _.forEach(
                    data.RiPolicyRiskUnitContent
                      .RiPolicyTreatyCessions,
                    treatyCession => {
                      if (treatyCession.CessionType == '4') {
                        flag = true;
                      }
                    }
                  );
                }
              );
              if (flag == true) {
                item.title = 'Reinsurance(Y)';
                return (
                  <UITabItem title={item.title} param={dataParams}>
                    {item.content}
                  </UITabItem>
                );
              }
              return (
                <UITabItem title={item.title} param={dataParams}>
                  {item.content}
                </UITabItem>
              );
            }
            return (
              <UITabItem title={item.title} param={dataParams}>
                {item.content}
              </UITabItem>
            );
          }
          return (
            <UITabItem title={item.title} param={dataParams}>
              {item.content}
            </UITabItem>
          );
        }
        if (item.title === 'Documents') {
          return (
            <UITabItem title={item.title} param={dataParams} disabled={true}>
              <UIUpdatePanel id="plan_info">
                {item.content}
              </UIUpdatePanel>
            </UITabItem>
          );
        }
        if (item.title === 'Diary') {
          return (
            <UITabItem title={item.title} param={dataParams} disabled={true}>
            </UITabItem>
          );
        }
        return (
          <UITabItem title={item.title} param={dataParams}>
            {item.content}
          </UITabItem>
        );
      });
      const dataTabObject = {};
      dataTabObject.dataTabs = dataTabs;
      dataTabObject.activeIndex = activeIndex;
      return dataTabObject;
    }
    return [];
  },

  render() {
    const _self = this;
    const tabObj = this.getAccidentItemAndActiveIndex();
    const {
      dtClaimData,
      claimData,
      showReserve,
      claimInfo,
      taskInstanceInfo,
      parentComponent,
      registrationHandling,
      claimLink,
      clickModifyHistory,
      actionButton,
    } = this.props;
    const tabItems = tabObj.dataTabs || [];
    const activeIndex = tabObj.activeIndex || 0;
    tabItems.unshift(
      <UITabItem title={i18n.ClaimCommon.Claim}>
        <ClaimInfoTab
          dtClaimData={dtClaimData}
          claimData={claimData}
          showReserve={showReserve}
          claimInfo={claimInfo}
          taskInstanceInfo={taskInstanceInfo}
          parentComponent={parentComponent}
          registrationHandling={registrationHandling}
          claimLink={claimLink}
          clickModifyHistory={clickModifyHistory}
          actionButton={actionButton}
        />
      </UITabItem>
    );
    return (
      <UICell id="tab_whole" width="13">
        <UITab
          id="claimInfoTabs"
          lazyLoad="false"
          activeIndex={activeIndex + 1}
          onTabChange={_self.claimTabAction}
        >
          {tabItems}
        </UITab>
        <TaskTransferDialog
          ref="ClaimTaskTransferDialog"
          componentId="ReserveapprovalHandling"
        />
      </UICell>
    );
  },
});
export default ClaimInfoTabs;
