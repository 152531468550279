import {
  MessageHelper,
  UICell,
  UICurrency,
  UISelect,
  UIButton,
  UIPanel,
  UITextarea,
  UIDialog,
  UIDialogFooter,
} from 'RainbowUI';
import BindToMixin from 'react-binding';
import PropTypes from 'prop-types';
import renderReserveDialogTitle from '../service/ReserveCommonService';

const ReserveAction = require('../action/ReserveAction');
const CodeTableAction = require('../../common/action/CodeTableAction');
const CodeTableCstUI = require('../../common/constant/CodeTableCstUI');

const ReopenReserveDialog = React.createClass({
  mixins: [BindToMixin],

  propTypes: {
    objectId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  },

  getInitialState() {
    return { newReopen: {} };
  },

  showDialog(data) {
    const newReopen = {};
    newReopen.changeUnit = data;
    this.setState({ newReopen });
    UIDialog.show(`reopenReserveDialog_${this.props.objectId}`);
  },

  render() {
    const _self = this;
    return (
      <UIDialog
        id={`reopenReserveDialog_${_self.props.objectId}`}
        title={i18n.ClaimReserve.Reopeningconfirmation}
        width="80%"
        height="600px"
        modal="false"
      >
        <UIPanel
          id={`reopenReservePanel_${_self.props.objectId}`}
          panelTitle={_self.getCoverageName}
          styleClass="default"
        >
          <UICell style={{ textAlign: 'left' }}>
            {i18n.ClaimReserve.AreYouSure}
          </UICell>

          <UICell width="12">
            <UISelect
              id={`reopenCause_${_self.props.objectId}`}
              label={i18n.ClaimReserve.CauseOfReopening}
              required="true"
              valueLink={_self.bindToState(
                'newReopen',
                'changeUnit.reopenCause'
              )}
              validationGroup="reopen_valid"
              codeTable={CodeTableAction.loadDataList(
                CodeTableCstUI.ClaimCauseOfReopening,
                null
              )}
            />
          </UICell>
          <UICell width="12">
            <UICurrency
              visibled={false}
              defaultValue="0"
              id={`CURRENCY_CODE_newOutstanding_${_self.props.objectId}`}
              label={i18n.ClaimReserve.NewOutstandingReserve}
              unit={window.EU00.getCurrencySignByCurrencyCode(
                !_self.state.newReopen.changeUnit
                  ? ''
                  : _self.state.newReopen.changeUnit.CurrencyCode
              )}
              valueLink={_self.bindToState(
                'newReopen',
                'changeUnit.outstanding'
              )}
              allowNegative={
                !_self.state.newReopen.changeUnit
                  ? ' '
                  : _self.state.newReopen.changeUnit.Positive
              }
              helpText={
                !_self.state.newReopen.changeUnit
                  ? ''
                  : _self.state.newReopen.changeUnit.Positive
                  ? i18n.ClaimReserve.InputNegative
                  : i18n.ClaimReserve.InputPositive
              }
            />
          </UICell>
          <UICell width="12">
            <UITextarea
              id={`remark_${_self.props.objectId}`}
              label={i18n.ClaimReserve.Remark}
              rows="3"
              valueLink={_self.bindToState(
                'newReopen',
                'changeUnit.remark'
              )}
            />
          </UICell>
        </UIPanel>
        <UIDialogFooter>
          <UIButton
            value={i18n.ClaimReserve.Submit}
            position="right"
            onClick={_self.submit}
            validationGroup="reopen_valid"
            causeValidation="true"
          />
          <UIButton
            value={i18n.ClaimReserve.Cancel}
            position="right"
            onClick={_self.cancel}
          />
        </UIDialogFooter>
      </UIDialog>
    );
  },
  getCoverageName() {
    const reserveType = this.state.newReopen.changeUnit.ReserveType;
    const subclaimIndex = this.state.newReopen.changeUnit
      .SubclaimIndex;
    const coverageName = this.state.newReopen.changeUnit.CoverageName;

    const title = renderReserveDialogTitle(
      subclaimIndex,
      reserveType,
      coverageName,
      ''
    );
    return title;
  },
  submit() {
    const { newReopen } = this.state;
    const { changeUnit } = newReopen;
    const { objectId } = this.props;
    if (changeUnit.Positive && changeUnit.outstanding > 0) {
      MessageHelper.error('', i18n.ClaimReserve.InputNegative);
      return;
    }
    if (!changeUnit.Positive && changeUnit.outstanding < 0) {
      MessageHelper.error('', i18n.ClaimReserve.InputPositive);
      return;
    }

    const dataVO = {
      ReserveId: changeUnit.ReserveId,
      OutstandingAmount: changeUnit.outstanding,
      CurrencyCode: changeUnit.CurrencyCode,
      ReopenCause: changeUnit.reopenCause,
      Remark: changeUnit.remark,
      ObjectId: objectId,
    };
    ReserveAction.reopenSubmit(dataVO, this.props.objectId);
  },

  cancel() {
    UIDialog.hide(`reopenReserveDialog_${this.props.objectId}`);
  },
  getSuperComponent() {
    return this;
  },
});

export default ReopenReserveDialog;
