import {
  UpdateContext,
  MessageHelper,
  UIPickList,
  AjaxUtil,
} from 'RainbowUI';
import {
  RestStatus,
  ClaimMessageForamtUtils,
} from 'ClaimCommonConstant';
import TreeNode from '../constant/TreeNodeCache';

const url = require('../url/Url');

export default {
  saveOption() {
    const treeNode = TreeNode.TreeCache.get('TreeNode');
    const productTypeCode = treeNode.objectMap.pId;
    const productLineCode = treeNode.treeNodeCode;
    const data = UIPickList.getTargetList('pickList02');
    const params = [];
    for (let i = 0; i < data.length; i += 1) {
      const param = {};
      param.CoverageTypeCode = data[i];
      param.ProductLineCode = productLineCode;
      param.ProductTypeCode = productTypeCode;
      params[i] = param;
    }
    AjaxUtil.doPost(
      url.ClaimProductTypeCoverageCategorySave,
      JSON.stringify(params),
      {
        async: false,
        done(data) {
          if (data.Status == RestStatus.OK) {
            MessageHelper.success(
              ClaimMessageForamtUtils.formatI18NMsg(
                i18n.ClaimCommon.SaveSuccess,
                i18n.ClaimProduct.ProductCoverageCategory
              )
            );
            UpdateContext.forceUpdate('productCfgDetailUIupdateId');
          } else {
            MessageHelper.error(data.Status);
          }
        },
        fail() {
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
        },
      }
    );
  },

  initData() {
    const treeNode = TreeNode.TreeCache.get('TreeNode');
    const productTypeCode = treeNode.objectMap.pId;
    const productLineCode = treeNode.treeNodeCode;
    let result = null;
    AjaxUtil.doGet(
      `${url.ClaimProductTypeCoverageCategoryList}/${productLineCode}/${productTypeCode}`,
      {},
      {
        async: false,
        done(data) {
          if (data.Status == RestStatus.OK) {
            result = data.Model;
          } else MessageHelper.error(data.Status);
        },
        fail() {
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
        },
      }
    );
    return result;
  },
};
