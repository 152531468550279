import { UICell, UIPanel, UICurrency } from 'RainbowUI';
import BindToMixin from 'react-binding';
import PropTypes from 'prop-types';

const SalvageItemInfo = require('./SalvageItemInfo');

const SalvageCoverageInfo = React.createClass({
  mixins: [BindToMixin],

  propTypes: {
    otherInfo: PropTypes.object.isRequired,
    insured: PropTypes.object.isRequired,
    coverage: PropTypes.object.isRequired,
    salvageInfo: PropTypes.object.isRequired,
    readOnly: PropTypes.bool,
    isApproveTask: PropTypes.bool,
  },

  getCurrentSalvageItem() {
    const currentItems = new Array();
    const { items } = this.bindArrayTo(
      this.props.salvageInfo,
      'SalvageItem'
    );
    // console.log('SalvageItem=============',items);
    const coverageCode = this.props.coverage.CoverageCode;
    const objectId = this.props.insured.value.ObjectId;
    // console.log('this.props.insured.value',this.props.insured.value);
    for (let i = 0; i < items.length; i += 1) {
      if (
        items[i].value.ObjectId == objectId &&
        items[i].value.CoverageCode == coverageCode
      ) {
        currentItems.push(items[i].value);
      }
    }
    return currentItems;
  },

  getSalvageTotalAmountVal() {
    let totalSalvageAmount = 0;
    const { items } = this.bindArrayTo(
      this.props.salvageInfo,
      'SalvageItem'
    );
    const insuredValue = this.props.insured.value;
    const coverageCode = this.props.coverage.CoverageCode;
    for (let i = 0; i < items.length; i += 1) {
      for (let j = 0; j < insuredValue.CoverageList.length; j += 1) {
        if (
          items[i].value.ItemId ==
            insuredValue.CoverageList[j].ItemId &&
          items[i].value.CoverageCode == coverageCode
        ) {
          totalSalvageAmount += Number(items[i].value.SalvageAmount);
        }
      }
    }

    return totalSalvageAmount;
  },

  getSalvageTotalAmount(suffixId, currencyCode) {
    return (
      <div>
        <UICell width="4">
          <UICurrency
            id={`CURRENCY_CODE_${suffixId}totalSalvageAmount`}
            label={i18n.ClaimSalvage.TotalAmount}
            value={this.getSalvageTotalAmountVal()}
            unit={window.EU00.getCurrencySignByCurrencyCode(
              currencyCode
            )}
            io="out"
            widthAllocation="5,7"
          />
        </UICell>
      </div>
    );
  },

  render() {
    const _self = this;
    const {
      componentId,
      readOnly,
      isApproveTask,
      insured: {
        value: { value, SubclaimName },
      },
      coverage: { CoverageCode, ItemId, CoverageName },
      salvageInfo: {
        value: { CaseCurrency },
      },
      history,
      otherInfo,
    } = this.props;
    const objectId = value;
    const coverageCode = CoverageCode;
    const currencyCode = CaseCurrency;
    const itemId = ItemId;
    const suffixId = `salvageCov${objectId}_${coverageCode.replace(
      /\./g,
      '_'
    )}_${readOnly}_${isApproveTask}${componentId}`;
    const salvageItem = this.getCurrentSalvageItem();
    const his = history;
    console.log('this.props', this.props);
    const salvageTotalAmount = this.getSalvageTotalAmount(
      suffixId,
      currencyCode
    );
    let style;
    if (history) {
      style = 'default';
    } else {
      style = 'primary';
    }

    if (!salvageItem || salvageItem.length < 1) {
      return <div />;
    }
    console.info('salvageItem   order', salvageItem);
    return (
      <div id={`${suffixId}_div`} style={{ marginTop: '20px' }}>
        <UIPanel
          panelTitle={`${SubclaimName}_${CoverageName}`}
          toggleable="true"
          styleClass={style}
        >
          <SalvageItemInfo
            otherInfo={otherInfo}
            history={his}
            itemId={itemId}
            objectId={objectId}
            coverageCode={coverageCode}
            currencyCode={currencyCode}
            salvageItem={salvageItem}
            readOnly={readOnly}
            isApproveTask={isApproveTask}
            componentId={componentId}
          />
          {salvageTotalAmount}
        </UIPanel>
      </div>
    );
  },
});
export default SalvageCoverageInfo;
