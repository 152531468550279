const SettlementLoadService = require('../service/SettlementLoadService');

export default class SettlementLoadProcess extends React.Component {
  changeSettlementItem(event) {
    const data = event.getParameter('data');
    if (event.jsEvent.target.id.indexOf('_settlementAmount') > 0) {
      let amount = event.newValue;
      if (amount.indexOf(',') !== -1) {
        amount = amount.replace(/,/g, '');
      }
      if (data.ReserveSign < 0 && amount > 0) {
        amount = -amount;
      } else if (data.ReserveSign > 0 && amount < 0) {
        amount = -amount;
      }
      data.SettleAmount = amount;
    } else if (event.jsEvent.target.id.indexOf('_pf') > 0) {
      data.PayFinal = event.newValue;
    } else if (event.jsEvent.target.id.indexOf('_paymentType') > 0) {
      data.PaymentType = event.newValue;
    } else if (
      event.jsEvent.target.id.indexOf('_paymentTaxCode') > 0
    ) {
      data.TaxCode = event.newValue;
    } else if (
      event.jsEvent.target.id.indexOf('_paymentTaxRate') > 0
    ) {
      data.TaxRate = event.newValue / 100;
    } else if (
      event.jsEvent.target.id.indexOf('_paymentTaxAmount') > 0
    ) {
      data.TaxAmount = event.newValue;
    }
    return data;
  }

  changeCurrencyCode(event) {
    const payeeIndex = event.getParameter('payeeIndex');
    const caseId = event.getParameter('caseId');
    const currency = event.newValue;
    const reserveExchangeRate = SettlementLoadService.loadExchangeRate(
      caseId,
      currency
    );
    const data = {
      PayeeIndex: payeeIndex,
      ReserveExchangeRate: reserveExchangeRate,
      SettlementCurrencyCode: currency,
    };
    return data;
  }

  changeField() {
    return null;
  }
}
