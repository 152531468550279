import { AjaxUtil } from 'RainbowUI';
import Url from '../url/Url';

export default {
  loadCatastrophe(param) {
    const defer = $.Deferred();
    AjaxUtil.doPost(Url.ClaimCatastropheQueryList, param, {
      async: false,
      done(data, textStatus, jqXHR) {
        if (data) {
          defer.resolve(data);
        } else {
          defer.reject({
            status: jqXHR.status,
            responseText: jqXHR.responseText,
          });
        }
      },
      fail(jqXHR) {
        // jqXHR.status, jqXHR.responseText
        defer.reject({
          status: jqXHR.status,
          responseText: jqXHR.responseText,
        });
      },
    });
    return defer.promise();
  },
  saveCatastrophe(catastrophe) {
    const defer = $.Deferred();
    AjaxUtil.doPost(Url.ClaimCatastropheSave, catastrophe, {
      async: false,
      done(data, textStatus, jqXHR) {
        console.log(`done:${JSON.stringify(data)}`);
        if (data) {
          defer.resolve(data);
        } else {
          defer.reject({
            status: jqXHR.status,
            responseText: jqXHR.responseText,
          });
        }
      },
      fail(jqXHR) {
        // jqXHR.status, jqXHR.responseText
        defer.reject({
          status: jqXHR.status,
          responseText: jqXHR.responseText,
        });
      },
    });
    return defer.promise();
  },
  loadCatastropheByAccidentCode(accidentCode) {
    const defer = $.Deferred();
    AjaxUtil.doGet(
      `${Url.ClaimCatastropheQueryDetailByAccidentCode}/${accidentCode}/accident`,
      {},
      {
        async: false,
        done(data, textStatus, jqXHR) {
          console.log(`done:${JSON.stringify(data)}`);
          if (data) {
            defer.resolve(data);
          } else {
            defer.reject({
              status: jqXHR.status,
              responseText: jqXHR.responseText,
            });
          }
        },
        fail(jqXHR) {
          // jqXHR.status, jqXHR.responseText
          defer.reject({
            status: jqXHR.status,
            responseText: jqXHR.responseText,
          });
        },
      }
    );
    return defer.promise();
  },
  checkAccidentCodeExists(accidentCode) {
    const defer = $.Deferred();
    AjaxUtil.doGet(
      `${Url.ClaimCatastropheCheckAccidentCode}/${accidentCode}/isExists`,
      {},
      {
        async: false,
        done(data, textStatus, jqXHR) {
          console.log(`done:${JSON.stringify(data)}`);
          if (data) {
            defer.resolve(data);
          } else {
            defer.reject({
              status: jqXHR.status,
              responseText: jqXHR.responseText,
            });
          }
        },
        fail(jqXHR) {
          // jqXHR.status, jqXHR.responseText
          defer.reject({
            status: jqXHR.status,
            responseText: jqXHR.responseText,
          });
        },
      }
    );
    return defer.promise();
  },
};
