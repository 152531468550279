import React from 'react';

import BindToMixin from 'react-binding';

const PolicyDetailPolicy = require('./PolicyDetailPolicy');
const PolicyStore = require('../../store/PolicyStore');
const PolicyAction = require('../../action/PolicyAction');
const PolicyDetailInsuredInfo = require('./PolicyDetailInsuredInfo');

const PolicyDetail = React.createClass({
  mixins: [BindToMixin],
  getInitialState() {
    return {
      policyInfo: this.props.policyInfo,
      retrieveInfo: this.props.retrieveInfo,
    };
  },

  componentWillMount() {
    PolicyStore.listen(this.onStoreStateChange);
  },

  componentDidMount() {

  },

  componentWillUnmount() {
    PolicyStore.unlisten(this.onStoreStateChange);
  },

  onStoreStateChange(storeState) {
    if (!_.isEmpty(storeState.policyInfo)) {
      this.setState({ policyInfo: storeState.policyInfo });
    }
  },
  getParentComponent() {
    return this;
  },

  render() {
    const { policyInfo } = this.state;
    return (
      <div>
        <PolicyDetailPolicy policyInfo={policyInfo} />
        <PolicyDetailInsuredInfo
          policyInfo={policyInfo}
          componentId="detailPolicy"
        />
      </div>
    );
  },
});
export default PolicyDetail;
