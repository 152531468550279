import {
  UIUpdatePanel,
  UITextarea,
  UISelect,
  UIText,
  UINumber,
  UISmartPanelGrid,
  UIPanel,
  UICell,
  UpdateContext,
  UIEmail
} from 'RainbowUI';
import React from 'react';
import BindToMixin from 'react-binding';
import { CodeTableAction, CodeTableCstUI } from 'ClaimCommonConstant';
import {
  PolicySearchDialog,
  ProductTree,
  EU00TwoText,
} from 'ClaimCommon';
import ClaimCommonExt from 'ClaimCommonExt';
import { BaseAddress } from 'EU00PartyCommonUI';
import NoticeStore from '../store/NoticeStore';

const NoticeOfLossAction = require('../action/NoticeOfLossAction');

const AccidentInfo = React.createClass({
  mixins: [BindToMixin],
  isMotor() {
    const requireFlag = NoticeOfLossAction.isMotor(
      this.state.noticeEntity
    );
    return requireFlag;
  },

  render() {
    const _self = this;
    const { noticeEntity } = this.state;
    const PolicyInfo = ClaimCommonExt.getPolicyInfo();
    const isMotor = NoticeOfLossAction.isMotor(noticeEntity);
    const PolicyDetailDialog = ClaimCommonExt.getPolicyDetailDialog();
    const noticeNo = noticeEntity.NoticeNo
      ? noticeEntity.NoticeNo
      : '';
    const productDisabled = false;
    return (
      <UIPanel panelTitle={`${i18n.ClaimNotice.Notice} ${noticeNo}`}>
        <PolicySearchDialog
          ref="PolicySearchDialog"
          parentComponent={this}
          productDisabled={productDisabled}
          productTypeCode={noticeEntity.ProductTypeCode}
        />
        <PolicyDetailDialog
          ref="PolicyDetailDialog"
          parentComponent={this}
        />
        <UICell width="12">
          <PolicyInfo
            ref="PolicyInfo"
            existNoticeNo={productDisabled}
            from="notice"
            data={noticeEntity}
            inNotice="Y"
            productCode={noticeEntity.ProductCode}
            parentComponent={this}
          />
        </UICell>
        <UIUpdatePanel id="accidentInfoUpdate">
          <UICell width="12">
            <UISmartPanelGrid column="3" widthAllocation="5,7">
              <EU00TwoText
                id="noticeEntity_policyBranch"
                label={i18n.ClaimCommon.PolicyBranch}
                searchDialog={{
                  dialogTitle: i18n.ClaimCommon.PolicyBranchSearch,
                }}
                enabled={this.isWithPolicy.bind(this)}
                model={noticeEntity}
                property="PolicyBranch"
                codeTable={NoticeOfLossAction.getBranchCodetable()}
              />

              <ProductTree
                id="ProductTree"
                ref="ProductTree"
                productCode={noticeEntity.ProductCode}
                treeCallBack={this.treeCallBack2}
                required={
                  this.isWithOutPolicy() === 'false'
                    ? 'true'
                    : 'false'
                }
                disabled={productDisabled}
                enabled={this.getEnabled}
                validationGroup="noticeDetail"
              />

              <UIText
                id="noticeEntity_policyHolderName"
                label={i18n.ClaimCommon.PolicyHolderName}
                enabled={this.getEnabled}
                model={noticeEntity}
                property="PolicyHolderName"
                validationGroup="noticeDetail"
              />

              <UISelect
                id="noticeEntity_contactType"
                label={i18n.ClaimCommon.ContactType}
                validationGroup="noticeDetail"
                model={noticeEntity}
                property="ContactType"
                onChange={_self.changeContactType}
                codeTable={CodeTableAction.loadDataList(
                  CodeTableCstUI.ClaimContactType,
                  null
                )}
              />

              <UIText
                id="noticeEntity_contactPerson"
                label={i18n.ClaimCommon.ContactPerson}
                required="true"
                model={noticeEntity}
                property="ContactPerson"
                validationGroup="noticeDetail"
              />
              <UIUpdatePanel id="accidentInfocontactTelUpdate">
                <UINumber
                  id="noticeEntity_contactTel"
                  label={i18n.ClaimCommon.ContactTel}
                  required="true"
                  model={noticeEntity}
                  property="ContactTelephone"
                  validationGroup="noticeDetail"
                  format="##########"
                  onBlur={this.onBlurContactTelephone}
                  placeHolder="0000000000"
                />
              </UIUpdatePanel>
              <UIEmail
                label={i18n.ClaimCommon.ClaimContactEmail}
                model={noticeEntity}
                property="ContactPersonEmail"
                maxLength="60"
                validationGroup="noticeDetail"
              />
            </UISmartPanelGrid>
            <UITextarea
              id="noticeEntity_accidentDescription"
              label={i18n.ClaimNotice.AccidentDescription}
              required="true"
              validationGroup="noticeDetail"
              model={noticeEntity}
              property="AccidentDescription"
              colspan="3"
            />
            <UIUpdatePanel id="countryCityForUpdate">
              <BaseAddress
                baseAddress={noticeEntity.AddressVo}
                readOnly={false}
                type="claim"
                isMotor={isMotor}
                validationGroup="noticeDetail"
              />
            </UIUpdatePanel>
            <UISmartPanelGrid column="3" widthAllocation="5,7">
              <UIText
                id="detailLocation"
                label={i18n.ClaimCommon.DetailLocation}
                model={noticeEntity}
                property="AccidentAddress"
                colspan="3"
                validationGroup="noticeDetail"
              />
              <UICell />

            </UISmartPanelGrid>
          </UICell>
        </UIUpdatePanel>
      </UIPanel>
    );
  },

  onBlurContactTelephone(event) {
    const reg = /^[+]?[0-9]{0,60}$/im;
    const s = reg.exec(event.component.onEvent.newValue);
    if (s) {
      this.state.noticeEntity.ContactTelephone = s[0];
    } else {
      //this.state.noticeEntity.ContactTelephone = null;
    }
    UpdateContext.forceUpdate('accidentInfocontactTelUpdate');
  },

  getInitialState() {
    const initNotice = this.props.data;
    const accidentid = this.props.parentComponent.props.params
      .AccidentId;
    const regNo = this.props.parentComponent.props.params.RegNo;

    if (undefined !== accidentid && undefined !== regNo) {
      this.initDataFromRtaAccident(accidentid, regNo, initNotice);
    }

    return {
      noticeEntity: initNotice,
      readOnly: false,
      regions: null,
      requireFlag: false,
    };
  },

  componentWillMount() {
    NoticeStore.listen(this.onStoreStateChange);
  },

  componentWillUnmount() {
    NoticeStore.unlisten(this.onStoreStateChange);
  },

  onStoreStateChange(storeState) {
    if (!_.isEmpty(storeState.noticeEntity)) {
      this.setState({ noticeEntity: storeState.noticeEntity });
    }
  },

  initDataFromRtaAccident(accidentid, regNo, tmpinitNotice) {
    const initNotice = tmpinitNotice;
    if (undefined == accidentid || undefined == regNo) {
      return;
    }
    const accident = NoticeOfLossAction.loadRtAccidentInfo(
      accidentid
    );
    let vehicle = null;
    for (let i = 0; i < accident.VehicleList.length; i += 1) {
      const veh = accident.VehicleList[i];
      if (veh.RegNo == regNo) {
        vehicle = veh;
        break;
      }
    }
    if (undefined !== accident.AccidentTime) {
      initNotice.AccidentTime = accident.AccidentTime.replace(
        'Z',
        ''
      );
    }
    initNotice.AccidentCountryCode = accident.LocationDistrict;
    initNotice.AccidentAddress = accident.LocationRoad;
    initNotice.AccidentDescription = vehicle.Declaration;
    initNotice.PolicyNo = vehicle.PolicyNo;
    initNotice.RtaAccidentId = accidentid;
    initNotice.RtaRegNo = regNo;
  },

  getEnabled() {
    let isEnabled = 'true';
    if (this.state.noticeEntity.PolicyEntity) {
      isEnabled = 'false';
    }
    return isEnabled;
  },

  getParentComponent() {
    return this;
  },

  getParentCurrentUser() {
    this.props.parentComponent.getCurrentUser();
  },

  isWithOutPolicy() {
    let flag = 'false';
    if (
      !!this.state.noticeEntity.PolicyEntity &&
      !!this.state.noticeEntity.PolicyEntity.PolicyNo
    ) {
      flag = 'true';
    }
    return flag;
  },

  isWithPolicy() {
    return this.isWithOutPolicy() === 'true' ? 'false' : 'true';
  },

  getPolicyHoldername(policyInfo) {
    let partyName;
    if (policyInfo.PartyList && policyInfo.PartyList.length > 0) {
      for (let i = 0; i < policyInfo.PartyList.length; i += 1) {
        if (i === 0) {
          partyName = policyInfo.PartyList[i].PartyName;
        }
      }
    }

    return partyName;
  },

  updatePolicyInfo(policyInfo) {
    if (policyInfo) {
      const { noticeEntity } = this.state;
      noticeEntity.PolicyBranch = policyInfo.OrganId;
      noticeEntity.PolicyHolderName = policyInfo.PolicyholderName;
      noticeEntity.ProductTypeCode = policyInfo.ProductTypeCode;
      noticeEntity.PolicyNo = policyInfo.PolicyNo;

      noticeEntity.ProductCode = policyInfo.ProductCode;
      noticeEntity.ProductName = this.refs.ProductTree.getProductName(
        policyInfo.ProductCode
      );

      noticeEntity.PolicyEntity = policyInfo;

      this.refs.PolicyInfo.retrieveFromAccident();

      UpdateContext.forceUpdate('countryCityForUpdate');

      this.forceUpdate();
    }
  },

  getPolicyEntity() {
    return this.state.noticeEntity.PolicyEntity;
  },
  clearPolicyInfo() {
    const { noticeEntity } = this.state;
    noticeEntity.PolicyBranch = null;
    noticeEntity.PolicyHolderName = null;
    noticeEntity.ProductTypeCode = null;
    noticeEntity.ProductCode = null;
    noticeEntity.PolicyEntity = null;
    noticeEntity.PolicyNo = null;
    noticeEntity.InsuranceType = null;
    noticeEntity.ProfitCenter = null;
    noticeEntity.PolicyTypeCode = null;
    this.refs.ProductTree.clearSelect();
    this.setState({
      noticeEntity,
      selectedCode: [{ id: '', text: '' }],
    });
  },
  treeCallBack(data) {
    this.state.noticeEntity.ProductTypeCode = data.productTypeCode;
    this.state.noticeEntity.ProductCode = data.productCode;
    this.forceUpdate();
  },

  treeCallBack2(data) {
    this.state.noticeEntity.ProductTypeCode = data.productTypeCode;
    this.state.noticeEntity.ProductCode = data.productCode;
    this.forceUpdate();
    UpdateContext.forceUpdate('countryCityForUpdate');
  },

  resetCityCodeTable() {
    UpdateContext.forceUpdate('countryCityForUpdate');
  },
});
export default AccidentInfo;
