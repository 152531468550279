import { MessageHelper, UIDialog } from 'RainbowUI';
import alt from '../alt';

const AppraisalPropertyService = require('../service/AppraisalPropertyService');
const AppraisalService = require('../service/AppraisalService');

class AppraisalLoadAction {
  showReadOnly(event) {
    UIDialog.show('AppraisalReadOnlyDialog');
  }

  showAppraisal(subClaimId) {
    return AppraisalPropertyService.showAppraisal(subClaimId);
  }

  getClosedTasks(objectId) {
    let result = null;
    if (objectId) {
      AppraisalService.getClosedTasks(objectId).then(
        data => {
          result = data.Model;
        },
        data => {
          MessageHelper.error(data.Messages[0].Message, '');
        }
      );
    }
    return result;
  }
}
export default alt.createActions(AppraisalLoadAction);
