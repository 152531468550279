import {
  UIConfirmDialog,
  MessageHelper,
  Caller,
  UpdateContext,
  UIDropZone,
} from 'RainbowUI';

import UrlConfig from '../url/Url';

const DocumentAction = {
  queryTransactionType(caseId) {
    const preurl = UrlConfig.queryTransactionType.replace(
      '{caseId}',
      caseId
    );
    const url = preurl.replace('{needNoAssignee}', 'false');
    let result = {};
    Caller.call(url, null, {
      method: 'GET',
      async: false,
      block: true,
    }).then(
      data => {
        result = data;
      },
      () => {
        MessageHelper.error(
          i18n.ClaimCommon.ErrorMsg.QueryTransactionTypeError,
          i18n.ClaimCommon.ErrorMsg.SystemError,
          MessageHelper.POSITION_TOP_RIGHT
        );
      }
    );
    return result;
  },
  queryDocumentsList(
    transactionType,
    transactionNo,
    subclaims,
    tmprelalist
  ) {
    let relalist = tmprelalist;
    if (!relalist) {
      relalist = [];
    }
    const preurl = UrlConfig.queryDocList.replace(
      '{TransactionType}',
      transactionType
    );
    const url = preurl.replace('{TransactionNo}', transactionNo);
    let result = {};
    const p = {};

    if (p.subclaims !== 'undefined') {
      /* empty */
    }

    Caller.call(url, relalist, {
      method: 'POST',
      async: false,
      block: true,
    }).then(
      data => {
        result = data;
      },
      () => {
        MessageHelper.error(
          i18n.ClaimCommon.ErrorMsg.GetDocumentError,
          i18n.ClaimCommon.ErrorMsg.SystemError,
          MessageHelper.POSITION_TOP_RIGHT
        );
      }
    );
    return result;
  },

  downloadFileFromDMS(id) {
    const url = UrlConfig.downloadFileFromDMS.replace('{id}', id);
    let result = {};
    Caller.call(url, null, {
      method: 'GET',
      async: false,
      block: true,
    }).then(
      data => {
        result = data;
      },
      () => {
        MessageHelper.error(
          i18n.ClaimCommon.ErrorMsg.GetDocumentError,
          i18n.ClaimCommon.ErrorMsg.SystemError,
          MessageHelper.POSITION_TOP_RIGHT
        );
      }
    );
    return result;
  },

  onUpload(documentModel) {
    const fileList = UIDropZone.getAcceptedFiles(
      'claim_fileupload_dropZone'
    );
    const { ModuleName } = documentModel;
    const { MainTransationNo } = documentModel;
    let fileSizeAll = 0;
    if (fileList.length === 0) {
      MessageHelper.error(
        i18n.ClaimCommon.ErrorMsg.PleaseUploadTheRequiredDocument
      );
    } else {
      for (let i = 0; i < fileList.length; i += 1) {
        const fileName = fileList[i].name;
        const result = DocumentAction.checkDuplicateFileName(
          ModuleName,
          fileName
        );
        if (result) {
          for (let j = 0; j < result.length; j += 1) {
            const dmsName = result[j].Name;
            const dmsId = result[j].TransactionNo;
            if (MainTransationNo == dmsId) {
              if (fileName == dmsName) {
                UIConfirmDialog.show('duplicateCheck');
                return;
              }
            }
          }
        }
        fileSizeAll += fileList[i].size;
      }

      const exitResult = DocumentAction.queryDocumentsList(
        ModuleName,
        MainTransationNo,
        null
      );
      let exitFileSizeAll = 0;
      for (let i = 0; i < exitResult.length; i += 1) {
        exitFileSizeAll += exitResult[i].DocumentSize;
      }
      if (exitFileSizeAll + fileSizeAll > 25 * 1024 * 1024) {
        MessageHelper.error(
          i18n.ClaimCommon.ErrorMsg.Error_0003,
          '',
          MessageHelper.POSITION_TOP_RIGHT
        );
        return;
      }
      const { DocumentType } = documentModel;
      const { DocumentSubType } = documentModel;
      const { TransactionType } = documentModel;

      let { TransactionNo } = documentModel;
      const { SubClaimNumber } = documentModel;
      const { Remark } = documentModel;
      if (SubClaimNumber) {
        TransactionNo = `${TransactionNo}|${SubClaimNumber}`;
      }

      const uploadUrl = `${
        UrlConfig.uploadFileToDMS
        }/${ModuleName}/${TransactionType}/${DocumentType}/${DocumentSubType}/${TransactionNo}?remark=${encodeURI(
          Remark,
          'UTF-8'
        )}`;
      UIDropZone.getDropZone(
        'claim_fileupload_dropZone'
      ).options.url = uploadUrl;
      UIDropZone.upload('claim_fileupload_dropZone');
    }
  },

  removeFileFromDMS(event) {
    const id = event.getParameter('item').Id;
    const url = UrlConfig.removeFileFromDMS.replace('{id}', id);
    let result = {};

    Caller.call(url, null, {
      method: 'GET',
      async: false,
      block: true,
    }).then(
      data => {
        result = data;
      },
      () => {
        MessageHelper.error(
          i18n.ClaimCommon.ErrorMsg.GetDocumentError,
          i18n.ClaimCommon.ErrorMsg.SystemError,
          MessageHelper.POSITION_TOP_RIGHT
        );
      }
    );
    UpdateContext.forceUpdate(
      'DocumentSearchResult, documentForm_updatePanel'
    );
    return result;
  },

  checkDuplicateFileName(moduleName, fileName) {
    const preurl = UrlConfig.queryByModuleAndFileName.replace(
      '{ModuleName}',
      moduleName
    );
    const url = preurl.replace('{Name}', fileName);
    let result = {};

    Caller.call(url, null, {
      method: 'GET',
      async: false,
      block: true,
    }).then(
      data => {
        result = data;
      },
      () => {
        MessageHelper.error(
          i18n.ClaimCommon.ErrorMsg.GetDocumentError,
          i18n.ClaimCommon.ErrorMsg.SystemError,
          MessageHelper.POSITION_TOP_RIGHT
        );
      }
    );
    return result;
  },
  previewFilesFromDMS(id) {
    const preurl = UrlConfig.previewFileFromDMS + id;
    const result = {};

    return result;
  },
  ajaxGetBlob(suffixUrl, callback) {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', suffixUrl, true);
    xhr.setRequestHeader(
      'Authorization',
      `Bearer ${
      localStorage
        .getItem('Authorization')
        .substr(13)
        .split('&')[0]
      }`
    );
    xhr.responseType = 'blob';
    xhr.onreadystatechange = () => {
      if (
        xhr.readyState == XMLHttpRequest.DONE &&
        xhr.status == 200
      ) {
        let fileName = this.getResponseHeader(
          'Content-Disposition'
        ).split('=')[1];
        if (fileName) {
          const nameStr = fileName.split('"');
          if (nameStr && nameStr.length === 3) {
            fileName = nameStr[1];
          }
        }
        const blob = new Blob([this.response], {
          type: this.getResponseHeader('content-type'),
        });
        if (!!window.ActiveXObject || 'ActiveXObject' in window) {
          window.navigator.msSaveBlob(blob, fileName);
        } else {
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
        }
        if (callback) {
          callback();
        }
      }
    };
    xhr.send(null);
  },
  showPDF(suffixUrl) {
    const xhr = new XMLHttpRequest();

    xhr.open('GET', suffixUrl, true);
    xhr.setRequestHeader(
      'Authorization',
      `Bearer ${
      localStorage
        .getItem('Authorization')
        .substr(13)
        .split('&')[0]
      }`
    );
    xhr.responseType = 'blob';
    xhr.onreadystatechange = () => {
      if (
        xhr.readyState == XMLHttpRequest.DONE &&
        xhr.status == 200
      ) {
        let fileName = this.getResponseHeader(
          'Content-Disposition'
        ).split('=')[1];
        if (fileName) {
          const nameStr = fileName.split('"');
          if (nameStr && nameStr.length === 3) {
            fileName = nameStr[1];
          }
        }

        const blob = new Blob([this.response], {
          type: this.getResponseHeader('content-type'),
          tttt: 'ttttw',
        });
        if (!!window.ActiveXObject || 'ActiveXObject' in window) {
          window.navigator.msSaveBlob(blob, fileName);
        } else {
          const downloadUrl = URL.createObjectURL(blob);

          const win = window.open(downloadUrl, 'privew');

          win.origin = null;
          win.parent = null;
          win.opener = null;

          win.location.reload();
        }
      }
    };
    xhr.send(null);
  },
};
export default DocumentAction;
