import { UIConfirmDialog } from 'RainbowUI';
import JCCPaymentAction from '../../action/JCCPaymentAction';

const PaymentPendingDialog = React.createClass({
  getInitialState() {
    return {};
  },

  componentWillMount() {},

  onShowDialog(orderId) {
    this.state.orderId = orderId;
    UIConfirmDialog.show('paymentPendingDialog');
  },

  render() {
    const message =
      'Please click on “Payment Complete” button below once transaction is completed';
    return (
      <UIConfirmDialog
        id="paymentPendingDialog"
        title={i18n.waitingforPayment}
        message={message}
        cancelButton="false"
        confirmText={i18n.PaymentComplete}
        firmText={i18n.PaymentComplete}
        onConfirm={this.onsubmit.bind(this)}
        closeable="false"
      />
    );
  },

  onsubmit() {
    const paymentAction = new JCCPaymentAction();
    const result = paymentAction.queryOnlinePaymentAndDoCollection(
      this.state.orderId,
      this.props.withoutCollectionFlag
    );
    UIConfirmDialog.hide('paymentPendingDialog');
    this.props.onSubmit(result);
  },
});
export default PaymentPendingDialog;
