import {
  MessageHelper,
  UIBox,
  UIButton,
  UICheckbox,
  UIColumn,
  UIDataTable,
  UIDateTimePicker,
  UILink,
  UIPanel,
  UIParam,
  UISelect,
  UISmartPanelGrid,
  UIText,
  UIUpdatePanel,
  UpdateContext,
} from 'RainbowUI';
import PartyAction from '../actions/PartyAction';
import DefineCodeTable from '../codetable/CodeTable';
import PartyCreationDialog from './PartyCreationDialog';
import UrlConfig from '../../../UrlConfig';
import PartyConst from '../const/PartyConst';
import PtyIntegrationGruopId from '../../common/component/integration/PtyIntegrationGruopId';

const PartySearchInfo = React.createClass({
  getInitialState() {
    return {
      definedCondition: this.props.definedCondition,
      conditions: this.props.conditions,
      fuzzyConditions: this.props.fuzzyConditions,
      orCondtions: this.props.orCondtions,
      isSearch: false,
      groupName: null,
    };
  },

  componentDidUpdate() {
    const { conditions } = this.state;
    if (conditions.GroupId) {
      $("input[name='IdForPartyGroupSearch']").val(
        conditions.GroupId
      );
      $("input[name='IdForPartyGroupSearch_value']").val(
        this.state.groupName
      );
    }
  },

  render() {
    const { definedCondition } = this.props;
    const partyType = definedCondition.PartyType;
    let partyConditionRender;

    if (partyType == PartyConst.PARTY_TYPE_IND) {
      partyConditionRender = this.indiviPartyConditionRender();
    }
    if (partyType == PartyConst.PARTY_TYPE_ORG) {
      partyConditionRender = this.orgPartyConditionRender();
    }
    if (partyType == PartyConst.PARTY_TYPE_VEN) {
      partyConditionRender = this.venPartyConditionRender();
    }
    let renderSearchResult;

    if (partyType == PartyConst.PARTY_TYPE_IND) {
      renderSearchResult = this.renderIndividualSearchResult();
    }
    if (partyType == PartyConst.PARTY_TYPE_ORG) {
      renderSearchResult = this.renderOrgSearchResult();
    }
    if (partyType == PartyConst.PARTY_TYPE_VEN) {
      renderSearchResult = this.renderVenSearchResult();
    }

    return (
      <div id="testetee">
        <UIUpdatePanel id="conditionRenderTable">
          {partyConditionRender}
          {renderSearchResult}
        </UIUpdatePanel>
        <PartyCreationDialog
          ref="PartyCreationDialog"
          id="PartyCreationDialog"
        />
      </div>
    );
  },

  partyCategoryChange(evnet) {
    this.resetHandler();
    if (evnet.newValue) {
      const party = {};
      party.PartyType = evnet.newValue;
    }
  },

  resetHandler() {
    this.setState({
      conditions: {},
      fuzzyConditions: {},
      orCondtions: {},
      isSearch: false,
    });
    UpdateContext.forceUpdate('conditionRenderTable');
  },

  searchHandler() {
    const { conditions } = this.state;
    if (conditions.Status) {
      if (conditions.Status == '0') {
        conditions.Status = 0;
      }
      if (conditions.Status == '1') {
        conditions.Status = 1;
      }
    }

    this.setState({ isSearch: true });
  },
  editHandler(clickEvent) {
    const selectData = UIDataTable.getSelectedRecord('PartyRecord');
    if (!selectData.length) {
      MessageHelper.warning(
        i18n.PartyMsg.WarnMsg.Warn_0005,
        i18n.PartyMsg.WarnMsg.Warn_0003,
        MessageHelper.POSITION_TOP_RIGHT
      );
      return;
    }
    let party = selectData[0];
    if (!_.isEmpty(clickEvent.paramJson)) {
      party = clickEvent.paramJson[0].value;
    }
    const { PartyId } = party;
    if (PartyId === null || PartyId === undefined || PartyId === '') {
      MessageHelper.error(
        i18n.PartyIdIsNull,
        i18n.PartyMsg.ErrorMsg.Error_0002,
        MessageHelper.POSITION_TOP_RIGHT
      );
    } else {
      window.location.href = `${UrlConfig.partyCommonUrl}#/createPartyMaintain/${PartyId}`;
    }
  },

  queryHandler(clickEvent) {
    let party = {};
    if (!_.isEmpty(clickEvent.paramJson)) {
      party = clickEvent.paramJson[0].value;
    }
    const { PartyId } = party;
    if (PartyId === null || PartyId === undefined || PartyId === '') {
      MessageHelper.error(
        i18n.PartyIdIsNull,
        i18n.PartyMsg.ErrorMsg.Error_0002,
        MessageHelper.POSITION_TOP_RIGHT
      );
    } else {
      window.location.href = `${UrlConfig.partyCommonUrl}#/readOnlyPartyMaintain/${PartyId}`;
    }
  },

  createHandler() {
    this.refs.PartyCreationDialog.showPartyCreateDialog();
  },

  indiviPartyConditionRender() {
    const { conditions } = this.state;
    const { fuzzyConditions } = this.state;
    const _self = this;
    return (
      <div>
        <UIPanel panelTitle={i18n.PartySearchCriteria}>
          <UISmartPanelGrid column="4">
            <UISelect
              label={i18n.PartyCategory}
              codeTable={DefineCodeTable.getCategroyCodeTable}
              onChange={_self.partyCategoryChange}
              required="true"
              model={_self.state.definedCondition}
              property="PartyType"
              showBlankOption="false"
              widthAllocation="7,5"
              colspan="2"
            />
            <UISelect
              label={i18n.PartyRole}
              colspan="2"
              codeTable={DefineCodeTable.getIndPartyRole}
              model={fuzzyConditions}
              property="RoleType"
              widthAllocation="7,5"
            />
            <UIText
              label={i18n.PartyName}
              model={conditions}
              property="PartyName"
              widthAllocation="7,5"
            />
            <UICheckbox
              single="true"
              label={i18n.FuzzySearch}
              model={conditions}
              property="FuzzySearch"
              defaultValue="Y"
            />
            <UIText
              label={i18n.PartyFormerCode}
              model={conditions}
              property="PartyCode"
              widthAllocation="7,5"
              colspan="2"
            />
            <UIText
              label={i18n.IdNo}
              model={conditions}
              property="IdNumber"
              widthAllocation="7,5"
              colspan="2"
            />
            <UIDateTimePicker
              label={i18n.BirthDay}
              model={conditions}
              property="DateOfBirth"
              widthAllocation="7,5"
              manualInput="true"
              format="MM/DD/YYYY"
              maxDate="TODAY"
              onClick={_self.setDate}
              colspan="2"
            />
            <UIText
              label={i18n.MobileHomeWorkNo}
              model={conditions}
              property="WorkHomeMobileTel"
              widthAllocation="7,5"
              colspan="2"
            />
            <UIText
              label={i18n.SSSEmployerId}
              model={conditions}
              property="EmployerId"
              widthAllocation="7,5"
              colspan="2"
            />
            <UIText
              label={i18n.PostalCode}
              model={conditions}
              property="PostCode"
              widthAllocation="7,5"
              colspan="2"
            />
            <PtyIntegrationGruopId
              widthAllocation="7,5"
              colspan="2"
              onCallBack={_self.onGetGroupIDInfoFromPoput}
              onChangeGroupId={_self.onChangeGroupId}
            />
          </UISmartPanelGrid>
          <UIBox direction="left">
            <UIButton
              value={i18n.Reset}
              onClick={_self.resetHandler}
            />
            <UIButton
              value={i18n.Search}
              onClick={_self.searchHandler}
              update="conditionRenderTable"
            />
            <UIButton
              value={i18n.Create}
              onClick={_self.createHandler}
            />
          </UIBox>
        </UIPanel>
      </div>
    );
  },
  orgPartyConditionRender() {
    const _self = this;
    const { conditions } = this.state;
    const { fuzzyConditions } = this.state;

    return (
      <div>
        <UIPanel panelTitle={i18n.PartySearchCriteria}>
          <UISmartPanelGrid column="4">
            <UISelect
              label={i18n.PartyCategory}
              codeTable={DefineCodeTable.getCategroyCodeTable}
              onChange={this.partyCategoryChange}
              required="true"
              model={_self.state.definedCondition}
              property="PartyType"
              showBlankOption="false"
              widthAllocation="7,5"
              colspan="2"
            />
            <UISelect
              label={i18n.PartyRole}
              colspan="2"
              codeTable={DefineCodeTable.getOrgPartyRole}
              model={fuzzyConditions}
              property="RoleType"
              widthAllocation="7,5"
            />
            <UIText
              label={i18n.PartyName}
              model={conditions}
              property="PartyName"
              widthAllocation="7,5"
            />
            <UICheckbox
              single="true"
              label={i18n.FuzzySearch}
              model={conditions}
              property="FuzzySearch"
              defaultValue="Y"
            />
            <UIText
              label={i18n.PartyFormerCode}
              model={conditions}
              property="PartyCode"
              widthAllocation="7,5"
              colspan="2"
            />
            <UIText
              label={i18n.CompanyNo}
              model={conditions}
              property="OrganizationIdNumber"
              widthAllocation="7,5"
              colspan="2"
            />
            <UIText
              label={i18n.MobileWorkNo}
              model={conditions}
              property="MobileWorkTel"
              widthAllocation="7,5"
              colspan="2"
            />
            <UIText
              label={i18n.SSSEmployerId}
              model={conditions}
              property="EmployerId"
              widthAllocation="7,5"
              colspan="2"
            />
            <UIText
              label={i18n.PostalCode}
              model={conditions}
              property="PostCode"
              widthAllocation="7,5"
              colspan="2"
            />
            <PtyIntegrationGruopId
              widthAllocation="7,5"
              colspan="2"
              onCallBack={_self.onGetGroupIDInfoFromPoput}
              onChangeGroupId={_self.onChangeGroupId}
            />
          </UISmartPanelGrid>
          <UIBox direction="left">
            <UIButton
              value={i18n.Reset}
              onClick={this.resetHandler}
            />
            <UIButton
              value={i18n.Search}
              onClick={this.searchHandler}
              update="conditionRenderTable"
            />
            <UIButton
              value={i18n.Create}
              onClick={this.createHandler}
            />
          </UIBox>
        </UIPanel>
      </div>
    );
  },

  venPartyConditionRender() {
    const _self = this;
    const { conditions } = this.state;
    const { fuzzyConditions } = this.state;
    return (
      <div>
        <UIPanel panelTitle={i18n.PartySearchCriteria}>
          <UISmartPanelGrid column="4">
            <UISelect
              label={i18n.PartyCategory}
              codeTable={DefineCodeTable.getCategroyCodeTable}
              onChange={this.partyCategoryChange}
              required="true"
              model={_self.state.definedCondition}
              property="PartyType"
              showBlankOption="false"
              widthAllocation="7,5"
              colspan="2"
            />
            <UISelect
              label={i18n.PartyRole}
              colspan="2"
              codeTable={DefineCodeTable.getOrgPartyRole}
              model={fuzzyConditions}
              property="RoleType"
              widthAllocation="7,5"
            />
            <UIText
              label={i18n.PartyName}
              model={conditions}
              property="PartyName"
              widthAllocation="7,5"
            />
            <UICheckbox
              single="true"
              label={i18n.FuzzySearch}
              model={conditions}
              property="FuzzySearch"
              defaultValue="Y"
            />
            <UIText
              label={i18n.PartyFormerCode}
              model={conditions}
              property="PartyCode"
              widthAllocation="7,5"
              colspan="2"
            />
            <UIText
              label={i18n.MobileWorkNo}
              model={conditions}
              property="MobileWorkTel"
              widthAllocation="7,5"
              colspan="2"
            />
            <UIText
              label={i18n.SSSEmployerId}
              model={conditions}
              property="EmployerId"
              widthAllocation="7,5"
              colspan="2"
            />
            <UIText
              label={i18n.PostalCode}
              model={conditions}
              property="PostCode"
              widthAllocation="7,5"
              colspan="2"
            />
            <PtyIntegrationGruopId
              widthAllocation="7,5"
              colspan="2"
              onCallBack={_self.onGetGroupIDInfoFromPoput}
              onChangeGroupId={_self.onChangeGroupId}
            />
          </UISmartPanelGrid>
          <UIBox direction="left">
            <UIButton
              value={i18n.Reset}
              onClick={this.resetHandler}
            />
            <UIButton
              value={i18n.Search}
              onClick={this.searchHandler}
              update="conditionRenderTable"
            />
            <UIButton
              value={i18n.Create}
              onClick={this.createHandler}
            />
          </UIBox>
        </UIPanel>
      </div>
    );
  },

  renderIndividualSearchResult() {
    const _self = this;
    return (
      <UIPanel panelTitle={i18n.PartyResult}>
        <UIBox direction="right">
          <UILink
            icon="fa fa-pencil"
            size="lg"
            tooltip={i18n.Edit}
            iconClass="edit_iconbtn"
            onClick={_self.editHandler}
          />
        </UIBox>
        <UIDataTable
          provider={_self.getIndPartyResult}
          selectionMode="single"
          indexable="false"
          id="PartyRecord"
        >
          <UIColumn headerTitle={i18n.PartyCode} value="PartyCode" />
          <UIColumn
            headerTitle={i18n.FormerPartyCode}
            value="PartyFormerCode"
          />
          <UIColumn
            headerTitle={i18n.PartyName}
            render={data => {
              return (
                <div>
                  <UILink
                    value={data.PartyName}
                    onClick={_self.queryHandler}
                  >
                    <UIParam name="data" value={data} />
                  </UILink>
                </div>
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.IdType}
            value="IdType"
            render={data => {
              return (
                <UISelect
                  io="out"
                  model={data}
                  property="IdType"
                  codeTable={DefineCodeTable.IdType()}
                />
              );
            }}
          />
          <UIColumn headerTitle={i18n.IdNo} value="IdNumber" />
          <UIColumn headerTitle={i18n.Gender} value="Gender">
            <UISelect
              codeTable={DefineCodeTable.getGenderCodeTable()}
              io="out"
            />
          </UIColumn>
        </UIDataTable>
      </UIPanel>
    );
  },

  renderOrgSearchResult() {
    const _self = this;
    return (
      <UIPanel panelTitle={i18n.PartyResult}>
        <UIBox direction="right">
          <UILink
            icon="fa fa-pencil"
            size="lg"
            tooltip={i18n.Edit}
            iconClass="edit_iconbtn"
            onClick={_self.editHandler}
          />
        </UIBox>
        <UIDataTable
          provider={_self.getOrgPartyResult}
          selectionMode="single"
          indexable="false"
          id="PartyRecord"
        >
          <UIColumn headerTitle={i18n.PartyCode} value="PartyCode" />
          <UIColumn
            headerTitle={i18n.FormerPartyCode}
            value="PartyFormerCode"
          />
          <UIColumn
            headerTitle={i18n.PartyName}
            render={data => {
              return (
                <div>
                  <UILink
                    value={data.PartyName}
                    onClick={_self.queryHandler}
                  >
                    <UIParam name="data" value={data} />
                  </UILink>
                </div>
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.CompanyNo}
            value="OrganizationIdNumber"
          />
          <UIColumn
            headerTitle={i18n.CountryDom}
            render={data => {
              return (
                <UISelect
                  model={data}
                  property="Country"
                  codeTable={DefineCodeTable.getCountry}
                  io="out"
                />
              );
            }}
          />
        </UIDataTable>
      </UIPanel>
    );
  },

  renderVenSearchResult() {
    const _self = this;
    return (
      <UIPanel panelTitle={i18n.PartyResult}>
        <UIBox direction="right">
          <UILink
            icon="fa fa-pencil"
            size="lg"
            tooltip={i18n.Edit}
            iconClass="edit_iconbtn"
            onClick={_self.editHandler}
          />
        </UIBox>
        <UIDataTable
          provider={_self.getVenPartyResult}
          selectionMode="single"
          indexable="false"
          id="PartyRecord"
        >
          <UIColumn headerTitle={i18n.PartyCode} value="PartyCode" />
          <UIColumn
            headerTitle={i18n.FormerPartyCode}
            value="PartyFormerCode"
          />
          <UIColumn
            headerTitle={i18n.PartyName}
            render={data => {
              return (
                <div>
                  <UILink
                    value={data.PartyName}
                    onClick={_self.queryHandler}
                  >
                    <UIParam name="data" value={data} />
                  </UILink>
                </div>
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.CountryDom}
            render={data => {
              return (
                <UISelect
                  model={data}
                  property="Country"
                  codeTable={DefineCodeTable.getCountry}
                  io="out"
                />
              );
            }}
          />
        </UIDataTable>
      </UIPanel>
    );
  },

  getOrgPartyResult(pageindex, pagesize) {
    const { isSearch } = this.state;
    if (!isSearch) {
      return { count: 0, result: [] };
    }
    const partyAction = new PartyAction();
    const partyName = this.state.conditions.PartyName;
    const fuzzySearch = this.state.conditions.FuzzySearch;
    const mobileWorkTel = this.state.conditions.MobileWorkTel;
    const { conditions } = this.state;
    const { fuzzyConditions } = this.state;
    const orConditions = this.state.orCondtions;
    if (conditions.FuzzySearch === 'Y') {
      fuzzyConditions.PartyName = conditions.PartyName;
      delete conditions.PartyName;
    }
    if (conditions.MobileWorkTel) {
      orConditions.WorkTel = conditions.MobileWorkTel;
      orConditions.MobileTel = conditions.MobileWorkTel;
      delete conditions.MobileWorkTel;
    }
    delete conditions.FuzzySearch;
    const partyType = this.state.definedCondition.PartyType;
    const result = partyAction.searchPartyByConditions(
      conditions,
      orConditions,
      fuzzyConditions,
      partyType,
      pageindex,
      pagesize
    );
    conditions.PartyName = partyName;
    conditions.FuzzySearch = fuzzySearch;
    conditions.MobileWorkTel = mobileWorkTel;
    if (result) {
      return { count: result.count, result: result.result };
    }
    return { count: 0, result: [] };
  },
  getIndPartyResult(pageindex, pagesize) {
    const { isSearch } = this.state;
    if (!isSearch) {
      return { count: 0, result: [] };
    }
    const partyAction = new PartyAction();
    const partyName = this.state.conditions.PartyName;
    const fuzzySearch = this.state.conditions.FuzzySearch;
    const workHomeMobileTel = this.state.conditions.WorkHomeMobileTel;
    const { conditions } = this.state;
    const { fuzzyConditions } = this.state;
    const orConditions = this.state.orCondtions;
    if (conditions.FuzzySearch === 'Y') {
      fuzzyConditions.PartyName = conditions.PartyName;
      delete conditions.PartyName;
    }
    if (conditions.WorkHomeMobileTel) {
      orConditions.WorkHomeTel = conditions.WorkHomeMobileTel;
      orConditions.MobileTel = conditions.WorkHomeMobileTel;
      delete conditions.WorkHomeMobileTel;
    }
    delete conditions.FuzzySearch;
    const partyType = this.state.definedCondition.PartyType;
    const result = partyAction.searchPartyByConditions(
      conditions,
      orConditions,
      fuzzyConditions,
      partyType,
      pageindex,
      pagesize
    );
    conditions.PartyName = partyName;
    conditions.FuzzySearch = fuzzySearch;
    conditions.WorkHomeMobileTel = workHomeMobileTel;
    if (result) {
      return { count: result.count, result: result.result };
    }
    return { count: 0, result: [] };
  },

  getVenPartyResult(pageindex, pagesize) {
    const { isSearch } = this.state;
    if (!isSearch) {
      return { count: 0, result: [] };
    }
    const partyAction = new PartyAction();
    const partyName = this.state.conditions.PartyName;
    const fuzzySearch = this.state.conditions.FuzzySearch;
    const mobileWorkTel = this.state.conditions.MobileWorkTel;
    const { conditions } = this.state;
    const { fuzzyConditions } = this.state;
    const orConditions = this.state.orCondtions;
    if (conditions.FuzzySearch === 'Y') {
      fuzzyConditions.PartyName = conditions.PartyName;
      delete conditions.PartyName;
    }
    if (conditions.MobileWorkTel) {
      orConditions.WorkTel = conditions.MobileWorkTel;
      orConditions.MobileTel = conditions.MobileWorkTel;
      delete conditions.MobileWorkTel;
    }
    delete conditions.FuzzySearch;
    const partyType = this.state.definedCondition.PartyType;
    const result = partyAction.searchPartyByConditions(
      conditions,
      orConditions,
      fuzzyConditions,
      partyType,
      pageindex,
      pagesize
    );
    conditions.PartyName = partyName;
    conditions.FuzzySearch = fuzzySearch;
    conditions.MobileWorkTel = mobileWorkTel;
    if (result) {
      return { count: result.count, result: result.result };
    }
    return { count: 0, result: [] };
  },

  onGetGroupIDInfoFromPoput(groupInfo) {
    const { conditions } = this.state;
    conditions.GroupId = groupInfo.GroupId;
    this.state.groupName = groupInfo.GroupName;
  },

  onChangeGroupId(groupInfo) {
    const { conditions } = this.state;
    if (!groupInfo) {
      conditions.GroupId = null;
      this.state.groupName = null;
    } else {
      conditions.GroupId = groupInfo.GroupId;
      this.state.groupName = groupInfo.GroupName;
    }
  },
});

export default PartySearchInfo;
