import {
  UIUpdatePanel,
  UIDataTable,
  UIColumn,
  DataContext,
  UIBox,
  UILink,
  UIParam,
  UIButton,
  UICell,
  UIText,
} from 'RainbowUI';

const CoverageCategoryOfLineDialog = require('./CoverageCategoryOfLineDialog');
const RemoveCoverageTypeWarning = require('./RemoveCoverageTypeWarning');
const RemoveProductLineConfirmDialog = require('./RemoveProductLineConfirmDialog');
const CoverageCategoryOfProductLineAction = require('../action/CoverageCategoryOfProductLineAction');

const CoverageCategoryOfProductLine = React.createClass({
  getInitialState() {
    return { count: 0 };
  },

  getCoverageCategoryList(pageindex, pagesize) {
    const coverageCategoryList = CoverageCategoryOfProductLineAction.getCoverageCategoryList(
      pageindex,
      pagesize
    );

    return coverageCategoryList;
  },

  showCoverageCategoryDetail(event) {
    let data = event.getParameter('data');
    const flag = {};
    if (!data) {
      data = {};
      flag.out = 'in';
    } else {
      flag.out = 'out';
    }
    this.refs.CoverageCategoryDialog_ref.showDialog(data, flag);
  },

  updatePage() {
    this.setState(prevState => ({ count: prevState.count + 1 }));
  },

  removeCoverageTypeConfirmDialog(event) {
    const data = event.getParameter('data');

    data.removeType = 'coveragecategoryofline';
    DataContext.put('data', data);
    this.refs.RemoveProductLineConfirmDialog_ref.showDialog(data);
  },

  render() {
    const self = this;
    return (
      <div>
        <UIUpdatePanel id="CoverageCategoryOfProductLinePageSearchResultProvider">
          <UIDataTable
            indexable="false"
            id="CoverageCategoryOfProductLinePage"
            provider={this.getCoverageCategoryList}
          >
            <UIColumn
              headerTitle={i18n.ClaimProduct.CoverageCategoryCode}
              width="15%"
              render={data => {
                return (
                  <u>
                    <UILink
                      value={data.CoverageTypeCode}
                      onClick={self.showCoverageCategoryDetail}
                    >
                      <UIParam name="data" value={data} />
                    </UILink>
                  </u>
                );
              }}
            />

            <UIColumn
              headerTitle={i18n.ClaimProduct.CoverageCategoryName}
              width="20%"
              render={data => {
                const tempData = `${data.CoverageTypeCode}:${data.CoverageTypeName}`;
                return <UIText value={tempData} io="out" />;
              }}
            />
            <UIColumn
              headerTitle={
                i18n.ClaimProduct.CoverageCategoryDescription
              }
              value="CoverageTypeDescription"
              width="50%"
            />
            <UIColumn
              headerTitle={i18n.ClaimProduct.Action}
              width="15%"
              render={data => {
                return (
                  <UILink
                    value={i18n.ClaimProduct.Remove}
                    onClick={self.removeCoverageTypeConfirmDialog}
                  >
                    <UIParam value={data} name="data" />
                  </UILink>
                );
              }}
            />
          </UIDataTable>
          <UICell>
            <UIBox direction="left">
              <UIButton
                value={i18n.ClaimProduct.New}
                onClick={self.showCoverageCategoryDetail}
              />
            </UIBox>
          </UICell>
          <CoverageCategoryOfLineDialog
            ref="CoverageCategoryDialog_ref"
            parentComponet={this}
          />
          <RemoveCoverageTypeWarning />
          <RemoveProductLineConfirmDialog
            ref="RemoveProductLineConfirmDialog_ref"
            parentComponet={this}
          />
        </UIUpdatePanel>
      </div>
    );
  },
});
export default CoverageCategoryOfProductLine;
