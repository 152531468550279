import { ClaimProduct_i18n_en_US } from 'ClaimProductConstant';
import { ClaimRegistration_i18n_en_US } from 'ClaimRegistrationConstant';
import { ClaimSettlement_i18n_en_US } from 'ClaimSettlementConstant';
import { ClaimCalculation_i18n_en_US } from 'ClaimCalculationConstant';
import { ClaimAppraisal_i18n_en_US } from 'ClaimAppraisalConstant';
import { ClaimFieldInvestigation_i18n_en_US } from 'ClaimFieldInvestigationConstant';
import { ClaimSubrogation_i18n_en_US } from 'ClaimSubrogationConstant';
import { ClaimSalvage_i18n_en_US } from 'ClaimSalvageConstant';
import { PartyCommon_i18n_en_US } from 'EU00PartyCommonConstant';
import jQuery from 'jquery';
import { ClaimNotice_i18n_en_US } from './notice/constant-lang';
import {
  ClaimCommon_i18n_en_US,
  ClaimReserve_i18n_en_US,
  ClaimParty_i18n_en_US,
} from './common/constant-lang';
import RainbowUI_i18n_en_US from '../node_modules/RainbowUI/src/js/i18n/reactjs-tag.i18n.en_US';

const Claim_i18n_en_US = {
  Language: 'Language',
  English: 'English',
  Chinese: '中文',
  Japanese: '日本の',
  ClaimCommon: ClaimCommon_i18n_en_US,
  ClaimReserve: ClaimReserve_i18n_en_US,
  ClaimParty: ClaimParty_i18n_en_US,
  ClaimProduct: ClaimProduct_i18n_en_US,
  ClaimNotice: ClaimNotice_i18n_en_US,
  ClaimRegistration: ClaimRegistration_i18n_en_US,
  ClaimSettlement: ClaimSettlement_i18n_en_US,
  ClaimCalculation: ClaimCalculation_i18n_en_US,
  ClaimAppraisal: ClaimAppraisal_i18n_en_US,
  ClaimFieldInvestigation: ClaimFieldInvestigation_i18n_en_US,
  ClaimSubrogation: ClaimSubrogation_i18n_en_US,
  ClaimSalvage: ClaimSalvage_i18n_en_US,
  PartyCommon: PartyCommon_i18n_en_US,
};

export default jQuery.extend(
  {},
  Claim_i18n_en_US,
  PartyCommon_i18n_en_US,
  RainbowUI_i18n_en_US
);
