import {
  CodeTable,
  UIPanel,
  UIBox,
  UIUpdatePanel,
  UpdateContext,
  MessageHelper,
  UISmartPanelGrid,
  UIText,
  UISelect,
  UIRadio,
  UIButton,
  UIDateTimePicker,
} from 'RainbowUI';
import BindToMixin from 'react-binding';
import ClaimWorkFlowMessageAction from '../../action/desktop/ClaimWorkFlowMessageAction';

const code = require('../../mock/CodeTable');
const CodeTableAction = require('../../action/CodeTableAction');
const CodeTableCstUI = require('../../constant/CodeTableCstUI');

const ClaimWorkFlowMessageCriteria = React.createClass({
  mixins: [BindToMixin],
  getInitialState() {
    return { condition: { Status: '100007' } };
  },
  messageType() {
    return new CodeTable([
      { id: '1', text: 'info' },
      { id: '2', text: 'note' },
      { id: '3', text: 'warning' },
    ]);
  },
  validateDateOfDueDateFromTo(event) {
    const { condition } = this.state;
    if (condition.DueDate_to) {
      condition.DueDate_to = this.props.format(
        new Date(condition.DueDate_to),
        'yyyy-MM-dd',
        1
      );
    }
    if (condition.DueDate_from > condition.DueDate_to) {
      MessageHelper.warning(
        i18n.ClaimCommon.WarnMsg.DateToCannotEarlierThanDateFrom,
        null,
        MessageHelper.POSITION_TOP_RIGHT
      );
      if (event.component.componentId === 'qLossDateFrom') {
        condition.DueDate_from = null;
      } else {
        condition.DueDate_to = null;
      }

      UpdateContext.forceUpdate('fresh1');
      UpdateContext.forceUpdate('fresh2');
      this.setState({ condition });
    }
  },
  initCondition() {
    this.state.condition.flag = 'message';
    ClaimWorkFlowMessageAction.initCondition(this.state.condition);
  },
  Clear() {
    this.setState({ condition: { Status: '100007' } });
  },
  render() {
    const self = this;
    return (
      <UIPanel
        id="searchCriteriaofMessage"
        panelTitle={i18n.ClaimCommon.MessageQueryCondition}
      >
        <UISmartPanelGrid
          id="idMessageUISmartPanelGrid"
          panelTitle={i18n.ClaimCommon.QueryCondition}
          layout="horizontal"
          column="2"
        >
          <UISelect
            id="messageTypeSelect"
            valueLink={this.bindToState('condition', 'SubType')}
            label={i18n.ClaimCommon.MessageType}
            codeTable={CodeTableAction.loadDataList(
              CodeTableCstUI.ClaimMsgType,
              null
            )}
          />
          <UIText
            id="claimNumberTextId"
            valueLink={this.bindToState('condition', 'Context')}
            label={i18n.ClaimCommon.ClaimNumber}
          />
          <UIUpdatePanel id="fresh1">
            <UIDateTimePicker
              id="qLossDateFrom"
              label={i18n.ClaimCommon.MessageGenerationDateFrom}
              valueLink={this.bindToState(
                'condition',
                'DueDate_from'
              )}
              defaultValue="2000-01-01"
              onChange={this.validateDateOfDueDateFromTo}
              format="MM/DD/YYYY"
            />
          </UIUpdatePanel>
          <UIUpdatePanel id="fresh2">
            <UIDateTimePicker
              id="qLossDateTo"
              label={i18n.ClaimCommon.MessageGenerationDateTo}
              valueLink={this.bindToState('condition', 'DueDate_to')}
              defaultValue={self.props.format(
                new Date(),
                'yyyy-MM-dd',
                1
              )}
              onChange={this.validateDateOfDueDateFromTo}
              format="MM/DD/YYYY"
            />
          </UIUpdatePanel>
          <UIRadio
            id="myMessageRadio"
            valueLink={this.bindToState('condition', 'Status')}
            label={i18n.ClaimCommon.ShowUnreadMessageOnly}
            codeTable={code.YesOrNoMessage}
          />
        </UISmartPanelGrid>
        <UIBox direction="left">
          <UIButton
            value={i18n.ClaimCommon.Filter}
            onClick={this.initCondition}
          />
          <UIButton
            value={i18n.ClaimCommon.Reset}
            onClick={this.Clear}
          />
        </UIBox>
      </UIPanel>
    );
  },
});
export default ClaimWorkFlowMessageCriteria;
