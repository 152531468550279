import { UITwoText, CodeTable, UpdateContext } from 'RainbowUI';
import PropTypes from 'prop-types';
import PartyAction from '../../party/actions/PartyAction';

export default class PartyCodeTableText extends UITwoText {
  initValue() {
    if (this.props.condition) {
      const key = this.getComponentValue();
      let value;
      if (this.props.descLink) {
        value = this.props.descLink.value;
      } else {
        value = this.getCodeTableValue(key);
      }

      $(`#${this.componentId}`).val(key);
      $(`#${this.componentId}_value`).val(value || '');
    } else {
      return new CodeTable();
    }
  }

  componentDidMount() {
    super._componentDidMount();
    if (this.props.io !== 'out') {
      this.initEvent();
      this.initProperty();
      this.initValidator();
      UpdateContext.put(this.componentId, this);

      const _self = this;
      $(`#${this.componentId}`).blur(event => {
        const key = event.target.value;
        const value = _self.getCodeTableValue(key);
        if (!value) {
          _self.setValue(_self.props.value, '');
          $(`#${_self.componentId}`).val('');
          if (!_self.props.isQuery) {
            $(`#${_self.componentId}_value`).val('');
          }
        } else {
          if (_self.props.onAfterBlur) {
            _self.props.descLink.requestChange(value);
          }
          $(`#${_self.componentId}_value`).val(value);
          if (_self.props.onAfterBlur) {
            _self.props.onAfterBlur(key);
          }
        }
      });
    }
  }

  componentDidUpdate() {
    super._componentDidUpdate();
    if (this.props.io !== 'out') {
      this.initCodeTableEvent();
      this.initProperty();
      this.initValidator();
      this.initComponent();

      UpdateContext.put(this.componentId, this);
    }
  }

  getCodeTableValue(key) {
    console.info('getCodeTableValue ', key);
    const value = '';
    if (!key) {
      return value;
    }
    if (this.props.condition) {
      const partyAction = new PartyAction();
      return partyAction.getPartyCodeTable(key).text;
    }
    return value;
  }

  renderInput() {
    let valuePlaceHolder;
    if (Array.isArray(this.props.placeHolder)) {
      valuePlaceHolder = this.props.placeHolder[1];
    } else {
      valuePlaceHolder = this.props.placeHolder;
    }
    return (
      <div>
        <div className="input-group col-sm-12 col-md-12 col-lg-12">
          <div
            className="col-sm-12 col-md-12 col-lg-12"
            style={{ padding: '0px' }}
          >
            <input
              id={`${this.componentId}_value`}
              name={`${this.componentId}_value`}
              type="text"
              className="form-control"
              style={{ width: '90%' }}
              placeholder={valuePlaceHolder}
              disabled={this.getDisabled()}
              data-auto-test={`${this.getNameForTest()}_value`}
            />
          </div>

          <span
            className="input-group-addon fixalliconposition"
            style={{ cursor: 'pointer' }}
            onClick={this.props.onIconClick}
          >
            <span className="fa fa-search" />
          </span>
        </div>
      </div>
    );
  }
}
PartyCodeTableText.propTypes = $.extend({}, UITwoText.propTypes, {
  onIconClick: PropTypes.func,
  getValueInfo: PropTypes.func,
  condition: PropTypes.string,
  onAfterBlur: PropTypes.func,
  descLink: PropTypes.shape({
    value: PropTypes.string.isRequired,
    requestChange: PropTypes.func.isRequired,
  }),
});
