import {
  UIDataTable,
  UIColumn,
  UIText,
  DataContext,
  UICurrency,
  UILink,
  UIParam,
  UIDialog,
  UIUpdatePanel,
  UIPercent,
} from 'RainbowUI';
import RiskBroker from './RiskBroker';

const SettlementDetailRateData = React.createClass({
  getInitialState() {
    return {
      dataValue: this.props.defaultData,
    };
  },

  componentWillMount() { },

  componentDidMount() {
    this.props.getChildMethod(this.renderDataValue);
    this.addRowSpan();
  },

  render() {
    const self = this;
    const { activeIndex } = this.props;
    return (
      <div>
        {self.renderCurrencyDataTableRate()}
        <UIDialog
          id="detailMessage"
          title={i18n.ReinsurerReinsuranceBroker}
        >
          <RiskBroker activeIndex={activeIndex} />
        </UIDialog>
      </div>
    );
  },

  addRowSpan() {
    const xolNum = this.props.XOLNum;
    if (xolNum) {
      if (xolNum !== 0) {
        $('#SettlementDetailRateDataRate tr:eq(1) td:eq(3)').attr(
          'rowspan',
          xolNum + 1
        );
        $('#SettlementDetailRateDataRate').attr('border', 0);
        const trSelector = $(
          '#SettlementDetailRateDataRate tr:eq(1)'
        );
        for (let i = 1; i <= xolNum + 1; i += 1) {
          $(`#SettlementDetailRateDataRate tr:eq(${i}) td:eq(3)`).css(
            'background-color',
            '#e5e5e5'
          );
          // $("#SettlementDetailRateDataRate tr:eq("+i+") td:eq(2)").mouseover(() => {
          //     $(this).css("background-color", "#e5e5e5");
          // })
          // $("#SettlementDetailRateDataRate tr:eq("+i+") td:eq(2)").mouseout(() => {
          //     $(this).css("background-color", "white");
          // })
        }
        trSelector
          .css('background-color', 'white')
          .siblings()
          .css('background-color', 'white');
        for (let index = 1; index <= xolNum; index += 1) {
          const selectorString =
            `#SettlementDetailRateDataRate tr:eq(${index + 1})` +
            ` td:eq(3)`;
          const selector = $(selectorString);
          selector.css({ display: 'none' });
        }
      }
    }
  },

  renderCurrencyDataTableRate() {
    const self = this;
    const settlementNo = DataContext.get('SettlementNo');
    let settlementShareRateTable = DataContext.get(
      'SettlementShareRateTable'
    );
    let settlementList = [];
    settlementShareRateTable = _.each(
      settlementShareRateTable,
      settlementTreatyObj => {
        if (settlementTreatyObj.SettlementNo == settlementNo) {
          settlementList =
            settlementTreatyObj.SettlementShareRateTable;
          return settlementTreatyObj;
        }
      }
    );
    const { activeIndex } = this.props;
    return (
      <UIUpdatePanel id="SettlementRateDataUpdatePanel">
        <UIDataTable
          id="SettlementDetailRateDataRate"
          pageable="false"
          value={settlementList}
          indexable="false"
        >
          <UIColumn width="10%" />
          <UIColumn
            headerTitle={i18n.TreatyCode}
            width="20%"
            render={data => {
              if (data.TreatyCode === 'Retention') {
                return (
                  <div>
                    <UIText value={data.TreatyCode} io="out" />
                  </div>
                );
              }
              return (
                <div>
                  <UILink
                    onClick={self.viewBrokerDetail}
                    value={data.TreatyCode}
                  >
                    <UIParam name="data" value={data} />
                  </UILink>
                </div>
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.PriorityOrder}
            width="20%"
            render={data => {
              if (data.Priority === 'Total') {
                return (
                  <div>
                    <UIText
                      io="out"
                      style={self.getCommonStyle(data)}
                      value={data.Priority}
                    />
                  </div>
                );
              }
              return (
                <div>
                  <UIText io="out" value={data.Priority} />
                </div>
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.ShareRate}
            width="20%"
            render={data => {
              const index = self.getArrIndex(settlementList, data);
              const tempId = `PERCENT_CODE_PROPORTIONAL_SHARE_RATE_${index}`;
              return (
                <div>
                  <UIPercent
                    id={tempId}
                    style={self.getCommonStyle(data)}
                    disabled="true"
                    value={data.ShareRate}
                    symbolPosition="none"
                    decimalPrecision="6"
                  />
                </div>
              );
            }}
          />

          <UIColumn
            headerTitle={i18n.SettledAmount}
            width="auto"
            render={data => {
              const index = self.getArrIndex(settlementList, data);
              const tempId = `CURRENCY_CODE_LEFT_PROPORTIONAL_AMOUNT_1${index}`;
              const treatyCurrencyCode = DataContext.get(
                'TreatyCurrencyCode'
              );
              if (activeIndex == '1') {
                return (
                  <div style={{ float: 'left' }}>
                    <UICurrency
                      id={tempId}
                      value={data.SettledAmount}
                      style={self.getCommonStyle(data)}
                      unit={window.EU00.getCurrencySignByCurrencyCode(
                        data.CurrencyCode
                      )}
                      io="out"
                    />
                  </div>
                );
              }
              return (
                <div style={{ float: 'left' }}>
                  <UICurrency
                    id={tempId}
                    value={data.SettledAmountTreatyCur}
                    style={self.getCommonStyle(data)}
                    unit={window.EU00.getCurrencySignByCurrencyCode(
                      treatyCurrencyCode
                    )}
                    io="out"
                  />
                </div>
              );
            }}
          />
        </UIDataTable>
      </UIUpdatePanel>
    );
  },

  renderDataValue(data) {
    this.setState({ dataValue: data });
  },

  viewBrokerDetail(event) {
    const data = event.getParameter('data');
    const TreatyCode = data.TreatyCode.replace(/&/g, '%26');
    const RiclaimInfo = `${data.SettleCurrency}:${data.SettleAmount}`;
    data.RiclaimInfo = RiclaimInfo;
    DataContext.put('TreatyCode', TreatyCode);
    DataContext.put('UrlData', data);
    DataContext.put('Dimension', 'bySettlement');
    UIDialog.show('detailMessage');
  },

  getCommonStyle(data) {
    if (data.Priority === 'Total') {
      return { 'font-weight': 'bold' };
    }
    return {};
  },

  getArrIndex(arr, obj) {
    return $.inArray(obj, arr);
  },
});
export default SettlementDetailRateData;
