import { CodeTable } from 'RainbowUI';

export default {
  taskType: new CodeTable([
    { id: '1', text: 'Claim Registration' },
    { id: '2', text: 'Settelement for Loss' },
  ]),

  taskStatus: new CodeTable([
    { id: '1', text: 'New' },
    { id: '2', text: 'Close' },
  ]),
  motorFlag: new CodeTable([
    { id: '1', text: 'yes' },
    { id: '0', text: 'no' },
  ]),
  YesOrNo: new CodeTable([
    { id: 'Y', text: 'Yes' },
    { id: 'N', text: 'No' },
  ]),
  ClaimFileType: new CodeTable([
    { id: 'LOS', text: 'Loss' },
    { id: 'SAL', text: 'Salvage' },
    { id: 'SUB', text: 'Subrogation' },
  ]),
  ReserveSign: new CodeTable([
    { id: '1', text: 'Y' },
    { id: '-1', text: 'N' },
  ]),
};
