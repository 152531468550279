import {
  UIBox,
  UIPanel,
  UISmartPanelGrid,
  UITextarea,
  UIRadio,
  UIButton,
  MessageHelper,
} from 'RainbowUI';

const codetable = require('../mock/CodeTable');

const ApprovalInfo = React.createClass({
  componentWillMount() {},
  confirm() {
    if (!this.props.decision.sourceObject.DecisionCode) {
      MessageHelper.error(
        i18n.ClaimCommon.ErrorMsg.PleaseSelectTheDecisionOptionFirst,
        ''
      );
      return;
    }
    this.props.onClick();
  },
  render() {
    const { decision, remark } = this.props;
    return (
      <span>
        <UIPanel
          id="approval_decision_panel"
          panelTitle={i18n.ClaimCommon.ApprovalDecision}
          toggleable="true"
        >
          <UISmartPanelGrid id="approval_smart_panel_grid">
            <UIRadio
              id="approval_decision_radio"
              label={i18n.ClaimCommon.Decision}
              codeTable={codetable.approvalDecision}
              valueLink={decision}
              required="true"
              validationGroup="approval_decision_vg"
            />
            <UITextarea
              id="approval_decision_remark"
              label={i18n.ClaimCommon.ApprovalRemark}
              placeHolder={i18n.ClaimCommon.ApprovalRemarkHolder}
              colspan="2"
              valueLink={remark}
              validationGroup="approval_decision_vg"
            />
          </UISmartPanelGrid>

          <UIBox id="approval_decision_cell_footer" direction="left">
            <UIButton
              id="approve_decision_button_submit"
              value={i18n.ClaimCommon.Submit}
              buttonType="confirm"
              onClick={this.confirm}
              validationGroup="approval_decision_vg"
              causeValidation="false"
            />
          </UIBox>
        </UIPanel>
      </span>
    );
  },
});
export default ApprovalInfo;
