const CalculationLoadAction = require('./CalculationLoadAction');

export default {
  changeCalculation(event) {
    const taskInstanceId = event.getParameter('taskInstanceId');

    CalculationLoadAction.loadCalculationTask(taskInstanceId);
  },

  getTaskInstance() {
    let taskInstranceId = 0;
    const urlParam = window.location.hash;
    const begin = urlParam.indexOf('?');
    const paramStr = urlParam.substr(begin + 1);
    const paramArray = paramStr.split('&');
    for (let i = 0; i < paramArray.length; i += 1) {
      if (paramArray[i].split('=')[0] === 'taskInstranceId') {
        taskInstranceId = paramArray[i].split('=')[1];
        break;
      }
    }
    sessionStorage.setItem(
      'Calculation_taskInstanceId',
      taskInstranceId
    );

    return taskInstranceId;
  },
};
