import {
  UILink,
  UICell,
  UIParam,
  UIBox,
  UIButton,
  UIDataTable,
  UIColumn,
} from 'RainbowUI';

const ProductListAction = require('../action/ProductListAction');
const RemoveConfirmDialog = require('./RemoveConfirmDialog');
const ShowProductDialog = require('./ShowProductDialog');

const ProductList = React.createClass({
  showDialog(event) {
    const data = event.getParameter('data');
    this.refs.showProductDialog_ref.showDialog(data);
  },
  remove() {
    ProductListAction.removeProduct();
  },
  render() {
    const _self = this;
    return (
      <div>
        <UIDataTable
          indexable="false"
          provider={ProductListAction.getProductList}
          id="productList"
        >
          <UIColumn
            id="Product_Code"
            headerTitle={i18n.ClaimProduct.ProductCode}
            width="15%"
            value="ProductCode"
            render={data => {
              return (
                <u>
                  <UILink
                    value={data.ProductCode}
                    onClick={_self.showDialog}
                  >
                    <UIParam name="data" value={data} />
                  </UILink>
                </u>
              );
            }}
          />
          <UIColumn
            id="ProductName"
            headerTitle={i18n.ClaimProduct.ProductName}
            width="20%"
            value="ProductName"
          />
          <UIColumn
            id="ProductDescription"
            headerTitle={i18n.ClaimProduct.ProductDescription}
            width="50%"
            value="ProductDescription"
          />
          <UIColumn
            id="Action"
            headerTitle={i18n.ClaimProduct.Action}
            width="15%"
            render={data => {
              return (
                <span>
                  <UILink
                    id="Remove"
                    value={i18n.ClaimProduct.Remove}
                    icon="fa fa-trash"
                    onClick={ProductListAction.removeProductDialog}
                  >
                    <UIParam name="data" value={data} />
                  </UILink>
                  {}
                </span>
              );
            }}
          />
        </UIDataTable>
        <UICell>
          <UIBox direction="left">
            <UIButton
              value={i18n.ClaimProduct.New}
              onClick={this.showDialog}
            />
          </UIBox>
        </UICell>
        <RemoveConfirmDialog parentFunction={this.remove} />
        <ShowProductDialog ref="showProductDialog_ref" />
      </div>
    );
  },
});
export default ProductList;
