import { AjaxUtil } from 'RainbowUI';
import alt from '../alt';
import Url from '../url/Url';

export default alt.createActions({
  submitClaimNote(caseId, claimNo, tmpclaimNote) {
    const claimNote = tmpclaimNote;
    const url = Url.ClaimNoteSave;
    claimNote.CaseId = caseId;
    claimNote.ClaimNo = claimNo;

    AjaxUtil.doPost(url, claimNote, {
      async: false,
      done: data => {
        this.dispatch({ flag: true });
      },
    });
  },
  loadList(caseId, pageIndex, pageSize) {
    const url = `${Url.ClaimNoteLoad +
      caseId}/list?start=${pageIndex - 1}&limit=${pageSize}`;

    let list = null;
    AjaxUtil.doGet(
      url,
      {},
      {
        async: false,
        done(data) {
          list = data;
        },
      }
    );
    return { result: list.Model.Content, count: list.Model.Total };
  },
});
