import {
  DataContext,
  UIColumn,
  UILink,
  UISelect,
  UIDataTable,
  UIDateTimePicker,
  UIParam,
} from 'RainbowUI';

import BindToMixin from 'react-binding';

import ClaimWorkFlowMyClaimStore from '../../store/ClaimWorkFlowMyClaimStore';

const ClaimWorkFlowMyClaimAction = require('../../action/desktop/ClaimWorkFlowMyClaimAction');

const CodeTableAction = require('../../action/CodeTableAction');
const CodeTableCstUI = require('../../constant/CodeTableCstUI');

const ClaimWorkFlowMyClaimResult = React.createClass({
  mixins: [BindToMixin],

  getInitialState() {
    return { condition: {}, isInital: true };
  },

  componentWillMount() {
    ClaimWorkFlowMyClaimStore.listen(this.onStoreStateChange);
  },

  componentWillUnmount() {
    ClaimWorkFlowMyClaimStore.unlisten(this.onStoreStateChange);
  },
  onStoreStateChange(storeState) {
    this.setState({ condition: storeState.taskSearchOptions });
    this.setState({ isInital: false });
  },
  loadClaimList(pageindex, pagesize) {
    DataContext.put('pageindex', pageindex);
    DataContext.put('pagesize', pagesize);
    return ClaimWorkFlowMyClaimAction.loadClaimList(
      pageindex,
      pagesize,
      this.state.condition
    );
  },
  onClickLink(event) {
    const caseId = event.getParameter('data');

    window.location.hash = `#/handling/tasks?caseId=${caseId}`;
  },
  render() {
    const self = this;

    return (
      <div>
        <UIDataTable
          indexable="false"
          id="myClaimListTable"
          provider={this.loadClaimList}
          headerTitle={i18n.ClaimCommon.MyClaims}
        >
          <UIColumn
            headerTitle={i18n.ClaimCommon.ClaimNumber}
            value="ClaimNumber"
            sortable="true"
            render={data => {
              return (
                <UILink
                  value={data.ClaimNumber}
                  onClick={self.onClickLink}
                >
                  <UIParam value={data.CaseId} name="data" />
                </UILink>
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.ClaimCommon.ClaimantName}
            value="Claimant"
            sortable="true"
          />
          <UIColumn
            headerTitle={i18n.ClaimCommon.SubclaimType}
            value="SubclaimType"
            sortable="true"
          >
            <UISelect
              codeTable={CodeTableAction.loadDataList(
                CodeTableCstUI.SubclaimType,
                null
              )}
              io="out"
            />
          </UIColumn>
          <UIColumn
            headerTitle={i18n.ClaimCommon.AssignedBy}
            value="AssignedBy"
            sortable="true"
          >
            <UISelect
              codeTable={CodeTableAction.loadDataList(
                CodeTableCstUI.ClaimUser,
                "useListNeedSystem"
              )}
              io="out"
            />
          </UIColumn>
          <UIColumn
            headerTitle={i18n.ClaimCommon.AssignedDate}
            value="AssginedDate"
            sortable="true"
          >
            <UIDateTimePicker format="MM/DD/YYYY" io="out" />
          </UIColumn>
          <UIColumn
            headerTitle={i18n.ClaimCommon.Status}
            value="LossStatus"
            sortable="true"
          >
            <UISelect
              codeTable={CodeTableAction.loadDataList(
                CodeTableCstUI.ClaimLossStatus,
                null
              )}
              io="out"
            />
          </UIColumn>
        </UIDataTable>
      </div>
    );
  },
});
export default ClaimWorkFlowMyClaimResult;
