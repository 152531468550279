import alt from '../alt';

const CalculationLoadAction = require('../action/CalculationLoadAction');

class CalculationApproveStore {
  constructor() {
    this.approveInfo = {
      BusinessId: null,
      TaskInstanceId: null,
      Remark: '',
      DecisionCode: null,
    };

    this.bindListeners({
      handleLoadApproveTask: CalculationLoadAction.LOAD_APPROVE_TASK,
    });
  }

  handleLoadApproveTask(data) {
    this.approveInfo = data;
    this.approveInfo.Remark = '';
    this.approveInfo.DecisionCode = null;
  }
}
export default alt.createStore(
  CalculationApproveStore,
  CalculationApproveStore
);
