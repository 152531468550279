import {
  UIDateTimePicker,
  UICheckbox,
  UISelect,
  UIRadio,
  UISmartPanelGrid,
  UIPanel,
  UIText,
  UIEmail,
  UITextarea,
  DataContext,
} from 'RainbowUI';
import DefinedCodeTable from '../codetable/CodeTable';
import PartyAction from '../actions/PartyAction';
import GroupIdAction from '../actions/GroupIdAction';
import GroupIdSearchDialog from './groupId/GroupIdSearchDialog';
import CodeTableTextNoValuelink from '../../common/component/CodeTableTextNoValuelink';

const PartyIndBasicInfo = React.createClass({
  getInitialState() {
    let { ReceiveEmailAlerts } = this.props.party;
    let { ReceiveSMSAlerts } = this.props.party;
    let EmailStatus = 'false';
    let emailDefault = 'N';
    if (ReceiveEmailAlerts === 'Y') {
      EmailStatus = 'true';
      emailDefault = 'Y';
    }
    let MobileStatus = 'false';
    let mobileDefault = 'N';
    if (ReceiveSMSAlerts === 'Y') {
      MobileStatus = 'true';
      mobileDefault = 'Y';
    }
    const result = DataContext.get('Party');
    let require = 'false';
    const activeStatus = 1;

    if (result.PartyRoleList) {
      _.forEach(result.PartyRoleList, data => {
        if (data.BusinessObjectId == 75198294) {
          if (data.Status == activeStatus) {
            if (data.GdprOfficer === 'Y') {
              require = 'true';
              EmailStatus = 'true';
              MobileStatus = 'true';
              mobileDefault = 'Y';
              emailDefault = 'Y';
              ReceiveEmailAlerts = 'Y';
              ReceiveSMSAlerts = 'Y';
            }
          } else {
            require = 'false';
            EmailStatus = 'false';
            MobileStatus = 'false';
            mobileDefault = 'N';
            emailDefault = 'N';
            ReceiveEmailAlerts = 'N';
            ReceiveSMSAlerts = 'N';
          }
        }
      });
    }
    this.props.party.ReceiveEmailAlerts = ReceiveEmailAlerts;
    this.props.party.ReceiveSMSAlerts = ReceiveSMSAlerts;
    return {
      isReadOnly: false,
      mobileDefault,
      emailDefault,
      require,
      party: this.props.party,
      EmailStatus,
      MobileStatus,
    };
  },

  componentDidMount() {
    if (this.state.party) {
      const groupId = this.state.party.GroupId;
      if (groupId != null && groupId !== '') {
        $("input[name='IdForPartyGroupSearch']").val(groupId);
        const groupIdAction = new GroupIdAction();
        const groupInfo = groupIdAction.searchByGroupId(groupId);
        if (groupInfo) {
          $("input[name='IdForPartyGroupSearch_value']").val(
            groupInfo.GroupName
          );
          $("textarea[name='IdForPartyGroupDesc']").val(
            groupInfo.Description
          );
        }
      }
    }
  },

  onPartyStoreChange(PartyStoreState) {
    if (PartyStoreState.party) {
      this.setState({ party: PartyStoreState.party });
    }
  },

  render() {
    const basicInfoRender = this.indiBasicInfoRender();
    return (
      <UIPanel panelTitle={i18n.BasicInfo}>{basicInfoRender}</UIPanel>
    );
  },

  indiBasicInfoRender() {
    const {
      party,
      MobileStatus,
      mobileDefault,
      EmailStatus,
      emailDefault,
      require,
    } = this.state;
    return (
      <div>
        <UISmartPanelGrid column="3">
          <UISelect
            label={i18n.PartyCategory}
            disabled="true"
            model={party}
            property="PartyType"
            codeTable={DefinedCodeTable.getCategroyCodeTable}
          />
          <UIText
            label={i18n.PartyCode}
            disabled="true"
            model={party}
            property="PartyCode"
          />
          <UISelect
            label={i18n.Prefix}
            codeTable={DefinedCodeTable.getPrefixCodeTable}
            model={party}
            property="Prefix"
          />
          <UIText
            label={i18n.FirstName}
            required="true"
            model={party}
            property="FirstName"
            validationGroup="UpdateBasicInfo"
          />
          <UIText
            label={i18n.MiddleName}
            model={party}
            property="MiddleName"
          />
          <UIText
            label={i18n.LastName}
            required="true"
            model={party}
            property="LastName"
            validationGroup="UpdateBasicInfo"
          />
          <UISelect
            label={i18n.IdType}
            model={party}
            property="IdType"
            codeTable={DefinedCodeTable.IdType}
            required="true"
            validationGroup="UpdateBasicInfo"
          />
          <UIText
            label={i18n.idNo}
            model={party}
            property="IdNumber"
            required="true"
            validationGroup="UpdateBasicInfo"
          />
          <UISelect
            label={i18n.CountryOfIssue}
            model={party}
            property="Country"
            codeTable={DefinedCodeTable.getCountry}
            defaultValue="CY"
            required="true"
            validationGroup="UpdateBasicInfo"
          />
          <UISelect
            label={i18n.Gender}
            model={party}
            property="Gender"
            required="true"
            codeTable={DefinedCodeTable.getGenderCodeTable}
            validationGroup="UpdateBasicInfo"
          />
          <UIDateTimePicker
            label={i18n.BirthDay}
            model={party}
            property="DateOfBirth"
            format="MM/DD/YYYY"
            required="true"
            maxDate="TODAY"
            manualInput="true"
            validationGroup="UpdateBasicInfo"
            onChange={this.changeDate.bind(this)}
          />
          <UISelect
            label={i18n.ContactLanguage}
            codeTable={DefinedCodeTable.ContactLanguage}
            model={party}
            property="LanguagePreferred"
            defaultValue="el_GR"
            showBlankOption="false"
            required="true"
            validationGroup="UpdateBasicInfo"
          />
          <UISelect
            label={i18n.Nationality}
            model={party}
            property="Nationality"
            codeTable={DefinedCodeTable.getNationalityCodeTabled}
          />
          <UISelect
            label={i18n.Occupation}
            model={party}
            property="Occupation"
            codeTable={DefinedCodeTable.getOccupation}
          />
          <UISelect
            label={i18n.MaritalStatus}
            codeTable={DefinedCodeTable.getMaritalStatus}
            model={party}
            property="MaritalStatus"
          />
          <UIText
            label={i18n.MobileTel}
            model={party}
            property="MobileTel"
            required={MobileStatus}
            validationGroup="UpdateBasicInfo"
          />
          <UICheckbox
            single="true"
            label={i18n.ReceiveSMSAlerts}
            defaultValue={mobileDefault}
            onChange={this.setMobileStatus}
            model={party}
            property="ReceiveSMSAlerts"
          />
          <UIText
            label={i18n.SSSEmployerId}
            model={party}
            property="EmployerId"
          />
          <div id="email">
            <UIEmail
              label={i18n.email}
              model={party}
              property="Email"
              required={EmailStatus}
              validationGroup="UpdateBasicInfo"
            />
          </div>
          <UICheckbox
            single="true"
            label={i18n.ReceiveEmailAlerts}
            defaultValue={emailDefault}
            onChange={this.setEmailStatus}
            model={party}
            property="ReceiveEmailAlerts"
          />
          <UIText
            label={i18n.WorkHomeTel}
            model={party}
            property="WorkHomeTel"
            required={require}
            validationGroup="UpdateBasicInfo"
          />
          <UIText
            label={i18n.FormerPartyCode}
            disabled="true"
            model={party}
            property="FormerPartyCode"
          />
          <UIText
            label={i18n.vat}
            model={party}
            property="VAT"
            allowChars="0123456789"
          />
          <UIText label={i18n.TIC} model={party} property="TIC" />
          <UIRadio
            label={i18n.ConsentDirectMarketing}
            model={party}
            property="ConsentForDirectMarketing"
            defaultValue="N"
            codeTable={DefinedCodeTable.getYesOrNo}
          />
          <UISelect
            label={i18n.DefaultPaymentMethod}
            model={party}
            property="PaymentMethodId"
            defaultValue="101"
            codeTable={DefinedCodeTable.getDefaultPaymentMethod}
            required="true"
            validationGroup="UpdateBasicInfo"
          />
          <UITextarea
            label={i18n.Description}
            model={party}
            property="Description"
          />
          <CodeTableTextNoValuelink
            id="IdForPartyGroupSearch"
            label={i18n.PtyGroupID}
            model={party}
            property="GroupId"
            onIconClick={this.onClickGroupIdSearch}
            onChange={this.onChangeGroupId}
          />
          <UITextarea
            id="IdForPartyGroupDesc"
            label={i18n.PtyGroupIdDesc}
            model={party}
            property="GroupIdDesc"
            disabled="true"
          />
        </UISmartPanelGrid>
        <GroupIdSearchDialog
          ref="refIndiGroupIdSearchDialog"
          customerChose={this.onGetGroupInfoFromPopup}
        />
      </div>
    );
  },

  setEmailStatus() {
    const { party } = this.state;
    if (this.state.emailDefault === 'Y') {
      this.setState({
        EmailStatus: 'false',
        emailDefault: 'N',
        party,
      });
    } else {
      this.setState({
        EmailStatus: 'true',
        emailDefault: 'Y',
        party,
      });
    }
  },

  setMobileStatus() {
    const { party } = this.state;
    if (this.state.mobileDefault === 'Y') {
      this.setState({
        MobileStatus: 'false',
        mobileDefault: 'N',
        party,
      });
    } else {
      this.setState({
        MobileStatus: 'true',
        mobileDefault: 'Y',
        party,
      });
    }
  },

  changeDate() {
    const partyAction = new PartyAction();
    const minorAge = partyAction.getMinorAge();
    const birthYear = this.state.party.DateOfBirth.split('-')[0];
    const checkDate =
      `${parseInt(birthYear, 10) + minorAge}` +
      `-${this.state.party.DateOfBirth.split('-')[1]}-${
      this.state.party.DateOfBirth.split('-')[2]
      }`;
    if (Date.parse(checkDate) - new ServerDate().getTime() > 0) {
      if (DataContext.get('PartyGDPR')) {
        DataContext.get('PartyGDPR').IsMinor = 'Y';
        if (DataContext.get('isRender')) {
          this.props.changeStatus();
        }
      }
    } else if (DataContext.get('PartyGDPR')) {
      DataContext.get('PartyGDPR').IsMinor = 'N';
      if (DataContext.get('isRender')) {
        this.props.changeStatus();
      }
    }
  },

  onClickGroupIdSearch() {
    this.refs.refIndiGroupIdSearchDialog.showDialog();
  },

  onGetGroupInfoFromPopup(groupInfo) {
    if (groupInfo && groupInfo) {
      const { party } = this.state;
      party.GroupId = groupInfo.GroupId;
      $("input[name='IdForPartyGroupSearch']").val(groupInfo.GroupId);
      $("input[name='IdForPartyGroupSearch_value']").val(
        groupInfo.GroupName
      );
      $("textarea[name='IdForPartyGroupDesc']").val(
        groupInfo.Description
      );
    }
  },

  onChangeGroupId(event) {
    const { newValue } = event;
    if (newValue) {
      const groupIdAction = new GroupIdAction();
      const groupInfo = groupIdAction.searchByGroupId(newValue);
      if (groupInfo) {
        $("input[name='IdForPartyGroupSearch']").val(
          groupInfo.GroupId
        );
        $("input[name='IdForPartyGroupSearch_value']").val(
          groupInfo.GroupName
        );
        $("textarea[name='IdForPartyGroupDesc']").val(
          groupInfo.Description
        );
      } else {
        $("input[name='IdForPartyGroupSearch_value']").val(null);
        $("textarea[name='IdForPartyGroupDesc']").val(null);
      }
    }
  },
});

export default PartyIndBasicInfo;
