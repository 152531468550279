export default {
  LossDateSectionFrom: 'Date of Loss from',
  LossDateSectionTo: 'Date of Loss to',
  Datefrom: 'Date from',
  DateTo: 'Date to',
  HelloWorld: 'HelloWorld',
  HelloEbao: 'HelloeBao',
  HelloInsure: 'HelloInsure',

  AuditAdd: "{fieldName}: The new value is '{newValue}' ",
  AuditRemove: '{fieldName}: Clear data',
  AuditReplace:
    "{fieldName}: Update from '{oldValue}' to '{newValue}'",
  Insured: 'Insured',

  ConflictedSubclaimList: 'Conflicted Subclaim List',
  SubclaimNumber: 'Sub Claim Number',
  ClaimHandling: 'Claim Handling',
  LossDescription: 'Loss Description',
  InsuredLiability: 'Estimated Insured Liability Share',
  ContactType: 'Claim Contact Type',
  DateofRegistration: 'Date of Registration',
  Catastrophe: 'Catastrophe',
  CatastropheCode: 'Catastrophe Code',
  CauseofLoss: 'Cause of Loss',
  SalesChannel: 'Sales Channel',
  SalesChannelInfo: 'Sales Channel Info',
  Claim: 'Claim',

  Reinsurance: 'Reinsurance',
  ClaimInfomation: 'Claim Infomation',
  ResinsuranceSummary: 'Resinsurance Summary',
  ClaimCurrency: 'Claim Currency',
  TreatyCurrency: 'Treaty Currency',
  SettlementDetail: 'Settlement Detail',
  SettlementType: 'Settlement Type',
  SettlementNo: 'Settlement No.',
  TransactionDate: 'Transaction Date',
  PartialFinal: 'Partial/Final',
  SettledAmount: 'Settled Amount',
  TreatyCode: 'Treaty Code',
  PriorityOrder: 'Priority Order',
  ShareRate: 'Share Rate%',
  RiskUnitName: 'Risk Unit Name',
  RiskCategory: 'Risk Category',
  RiskLevel: 'Risk Level',
  OSReserve: 'O/S Reserve',
  IncurredAmount: 'Incurred Amount',

  ClaimInfo: 'Claim Information',
  Claimant: 'Claimant',
  ClaimantInfo: 'Claimant Info',
  SubclaimInfo: 'Subclaim Info',
  Subclaim: 'Sub Claim',
  TaskTransferA: 'Manual Task Assignment',
  TaskTransfer: 'Task Transfer',
  TaskTransferM: 'Pending Task',
  WorkOnTasks: 'Work On Tasks',
  AssignTo: 'Assign to',
  TaskStatus: 'Task Status',
  PolicyInfo: 'Policy Info',
  PolicyNumber: 'Policy Number',
  ProductName: 'Product Name',
  VinNoOrPlateNo: 'Reference Number',
  PlateNoVesselNo: 'Plate No./Vessel No.',
  ReviewConfirmation: 'REVIEW CONFIRMATION',
  LastDaysReview: 'Days since Last Review',
  LastReviewDate: 'Last Review Date',
  DaysSinceLastReview: 'Days Since Last Review',
  ProductTypeName: 'Product Type Name',
  PolicyHolderName: 'Policy Holder Name',
  PolicyHolderInfo: 'Policy Holder Info',
  PolicyBranch: 'Policy Branch',
  PolicyBranchSearch: 'Policy Branch Search',
  ContactPerson: 'Claim Contact Person',
  ContactTel: 'Claim Contact Telephone',
  DateOfLoss: 'Date of Loss',
  DateOfNotice: 'Date of Notification',
  ClaimBranch: 'Claim Branch',
  ClaimBranchSearch: 'Claim Branch Search',
  ClaimNumber: 'Claim Number',
  Officer1: 'Officer 1',
  Officer2: 'Officer 2',

  AccidentDistrict: 'Accident District',
  AccidentLocation: 'Accident Address',
  DetailLocation: 'Address Details',
  AccidentCountry: 'Accident Country',
  AccidentCity: 'Accident City',
  AccidentTown: 'Accident State',
  AccidentStreet: 'Accident Street',
  PostalCode: 'Postal Code',
  DamageType: 'Damage Type',
  DamageItem: 'Damage Item',
  FirstOr3rdParty: '1st or 3rd Party',
  IDCard: 'ID Card Number',
  EstimatedLoss: 'Estimated Loss',
  Currency: 'Currency',
  Limit: 'Limit',
  CoverageName: 'Coverage Name',
  DeductibleAmount: 'Deductible Amount',
  DeductibleRate: 'Deductible Rate',
  Deductible: 'Deductible',
  SumInsured: 'Sum Insured',
  SILimit: 'SI/Limit',
  InsuredList: 'Insured Info',
  InsuredName: 'Insured Name',
  InsuredCategory: 'Insured Category',
  InsuredId: 'Insured ID',
  InsuredUid: 'InsuredUid',
  InsuredIDNumber: 'Insured ID Number',
  PolicyDetail: 'Policy Detail',
  CoverageList: 'Coverage Info',
  EffectiveDate: 'Effective Date',
  ExpiryDate: 'Expiry Date',
  PremiunIsPaid: 'Premiun Is Paid',
  PolicySearch: 'Policy Search',
  PolicyEffectiveDataFromTo: 'Policy Effective Date from/to',
  PolicyEffectiveDataFrom: 'Policy Effective Date from',
  PolicyEffectiveDataTo: 'Policy Effective Date to',
  IDNumberOrBusinessRegisterNumber:
    'ID Number/Business Register Number',
  PolicyHolderIDNumber: 'Policy Holder ID Number',
  PolicyList: 'Policy List',
  PolicyEffectiveDate: 'Policy Effective Date',
  PolicyExpiryDate: 'Policy Expiry Date',
  Product: 'Product',
  IdType: 'ID Type',
  ContactEmail: 'Contact Email',
  CountryStateCity: 'Country/State/City',
  Country: 'Country',
  OrganzationName: 'Organization Name',
  AddressLine1: 'Address Line 1',
  Area: 'Area',
  StreetName: 'Street Name',
  Detail: 'Detail',
  ClaimsMade: 'Claims Made',

  WorkListCode: 'Please input code here',
  WorkListValue: 'Please input name here',

  RejectConfirmation: 'Reject Confirmation',
  RejectReason: 'Reject Reason',
  Remark: 'Remark',
  Id: 'Id',
  InsuredObject: 'Insured Object',
  PossibleSubrogation: 'Possible Subrogation',
  PossibleSalvage: 'Possible Salvage',
  DamageObject: 'Damage Object',
  DamageSeverity: 'Damage Severity',
  FirstName: 'First Name',
  LastName: 'Last Name',
  Postcode: 'Postcode',
  PlaceOfInspection: 'Place of Inspection',
  InspectionAddress: 'Inspection Address',
  Litigation: 'Litigation',
  TotalLoss: 'Total Loss',
  DamageDescription: 'Damage Description',
  Coverage: 'Coverage',
  InitialReserve: 'Initial Reserve',
  TaskType: 'Task Type',
  PolicyNo: 'Policy No.',
  ProductType: 'Product Type',
  DueDate: 'Due Date',
  TaskDetail: 'Task Detail',
  AssignedBy: 'Assigned By',
  Priority: 'Priority',
  ClaimTask: 'Claim Task',
  MessageType: 'Message Type',
  PoliceStation: 'Police Station',
  CareOperator: 'Care Operator',
  CareProvider: 'Care Provider',
  CareServiceTime: 'Care Service Time',
  CompanyCare: 'Company Care',
  CareCallDate: 'Care Call Date',
  RentalCarService: 'Rental Car Service',
  RentalCarCompany: 'Rental Car Company',
  CarRentalTown: 'Car Rental Town',
  RentalPeriod: 'Rental Period',
  TotalRentalFee: 'Total Rental Fee',
  DailyRentalFee: 'Daily Rental Fee',
  Date: 'Date',
  Content: 'Content',
  Dismiss: 'Dismiss',
  Complete: 'COMPLETE',
  ClaimantName: 'Claimant Name',
  ClaimantAddress: 'Claimant Address',
  ClaimStatus: 'Claim Status',
  SubclaimType: 'Subclaim Type',
  AssignedDate: 'Assigned Date',
  Status: 'Status',
  ShowUnreadMessageOnly: 'Show Un-read Message Only',
  Datefromto: 'Date from/to',
  ShowOpenClaimsOnly: 'Show Open Claims Only',
  NewMessage: 'New Message',
  MessageResult: 'Message',
  MyClaims: 'My Claim',
  ShareingPool: 'Sharing Pool',
  MyTask: 'My Task',
  OverDueDate: 'Over Due Task',
  Registration: 'Registration',
  Settlement: 'Settlement',
  SettlementApproval: 'Settlement Approval',
  PendingNotice: 'Pending Notice',
  Yes: 'Yes',
  No: 'No',
  QueryCondition: 'QueryCondition',
  MessageQueryCondition: 'Search Message',
  TaskQueryCondition: 'Search My Task',
  CliamQueryCondition: 'Search My Claim',
  SharePoolQueryCondition: 'Search Sharing Pool',
  ModifyClaimInfo: 'Modify Claim Info',
  ModifySublaimInfo: 'Modify Subclaim Info',
  ValidationInfo: 'Validation Info',
  ModificationHistory: 'Modification History',
  Badge: 'Badge',
  Assignment: 'Assignment',
  AssignmentPendingTask: 'Pending Tasks',
  AssignmentClosedTask: 'Closed Tasks',
  AssignmentNewTask: 'New Task',
  AssignmentReassignTask: 'Reassign Task',
  AssignmentReassign: 'REASSIGN',
  AssignmentCloseTask: 'CLOSE TASK',
  AssignmentAttachTo: 'Attach to',
  AssignmentAssignTo: 'Assigned to',
  AssignmentClosedBy: 'Closed by',
  AssignmentClosedDate: 'Closed Date',
  SendMessage: 'Send Message',
  AssignmentApproach: 'Assignment Approach',
  Party: 'Party',
  UserAccountAndAuthority: 'User Account / Authority',
  More: 'More',
  AccidentCode: 'Catastrophe Code',
  AccidentName: 'Catastrophe Name',
  AccidentDesc: 'Description',
  AccidentValidFlag: 'Active',
  AccidentDefineCode: 'Define Catastrophe Code',
  AssignmentStatus: 'Status',
  ManualAssignmentApproach: 'Manual Assignment Approach',
  AutomaticAssignmentApproach: 'Automatic Assignment Approach',
  AttachmentList: 'Document List',
  AttachmentPanel: 'Upload Document',
  DocumentName: 'Document Name',
  DocumentSize: 'Document Size',
  DocumentType: 'Document Type',
  DocumentSubType: 'Document Sub-Type',
  UploadDate: 'Upload Date',
  CreatorName: 'Creator Name',
  ReMark: 'ReMark',
  SearchResult: 'Search Result',

  ClaimQuery: 'Claim search criteria',
  ClaimResult: 'Claim Search Result',
  ClaimOwner: 'Claim Owner',
  LossDateSection: 'Date of Loss from/to',
  LossStatus: 'Loss Status',
  HideSearchOptions: 'Hide Search Options',
  ShowSearchOptions: 'Show Search Options',
  TotalIncurred: 'Total Incurred',
  PriorClaims: 'Prior Claims from CLP',
  PriorClaimsLink: 'Prior Claims',
  OtherPolicyClaims: 'Other Policy Claims',
  RENotice:
    'Claim record(s) exists on the same day, do you want to continue?',

  NoteDate: 'Date',
  NoteBy: 'By',
  NoteCategory: 'Category',
  NoteContent: 'Content',
  NoteSendMessageTo: 'Send Message to',
  Note: 'Notes',
  NoticeofLoss: 'Notice of Loss',
  NoteRecipient: 'Recipient',

  Continue: 'CONTINUE',
  Retrieve: 'RETRIEVE',
  Clear: 'RESET',
  Action: 'Action',
  Edit: 'EDIT',
  Delete: 'DELETE',
  Save: 'SAVE',
  btnSave: 'SAVE',
  Submit: 'SUBMIT',
  Reject: 'REJECT',
  WorKOn: 'WORK ON',
  BacktoClaim: 'BACK TO CLAIM',
  Cancel: 'CANCEL',
  Add: 'ADD',
  Search: 'SEARCH',
  Reset: 'RESET',
  New: 'NEW',
  Filter: 'SEARCH',
  Home: 'Dashboard',
  okButton: 'OK',
  Configuration: 'Configuration',
  ProductConfiguration: 'Product Configuration',
  ClaimMatrixExport: 'Claim Matrix Export/Import',
  SearchClaim: 'Search',
  NewNoiceofLoss: 'FNOL',
  CheckNoticeVersionMessage:
    'The current notice is not the latest version',
  RejectSubclaim: 'REJECT SUBCLAIM',
  Upload: 'Upload',
  Back: 'BACK',
  SelectAsClaimant: 'SELECT AS CLAIMANT',
  Hide: 'Hide',
  Exit: 'EXIT',

  NoRiskSelected: 'At least one Risk must be selected.',
  NoPolicy: "The policy number doesn't exist.",
  WarnMessage:
    'The pending tasks for this claim are listed below. You can select a task assigned to you to work on',
  RejectDialogConfirm: 'Are you sure to reject the current ',
  NoCoverageSelectedMessage: 'Please select a coverage for subclaim ',
  SubclaimInitLossIsEmpty:
    'Please input inital reserve for subclaim ',
  NewSubclaimShouldSubmit: 'Please submit new subclaim firstly. ',
  AllSubclaimAreRejected:
    'All sub claims are rejected, please reject the claim. ',
  SubclaimInfoRequiredFieldIsMissing:
    'Please complete the required fields in subclaim. ',
  ChangePolicyMessage:
    'Re-retrieving policy will clear the following information, do you want to continue ',
  ChangeCauseofLossMessage:
    'Changing cause of loss will clear the following information, do you want to continue ',
  ConflictedSubclaimMessage:
    'Below subclaims are conflict with updated Cause of Loss. If you confirm to keep change, those subclaim will be closed automatically.',
  SubclaimHaveSettle:
    'The cause of loss change will not be allowed for existing successful settlement records in the claim!',
  CheckClaimDataMessage01:
    'Date of loss cannot be later than Date of Notification to Insurer.',
  CheckClaimDataMessage02:
    'Date of Notification must be earlier or equal to Date of Registration.',
  CheckClaimDataMessage03:
    'Date of Loss cannot be later than current date',
  CheckClaimDataMessage04:
    'Date of Notification cannot be later than current date.',
  CheckSubClaimTypeMsg:
    'You can only create one subclaim with below subclaim type(s):',
  NoClaimantError: 'Claimant infomation cannot  be empty.',

  ApprovalHistory: 'Approval History',
  ApprovalDate: 'Transaction Date',
  ApprovalName: 'Operated By',
  ApprovalSummay: 'Summary',
  ApprovalDecision: 'Approval Decision',
  ApprovalRemark: 'Remark',
  Decision: 'Decision',
  ApprovalRemarkHolder: 'Please input remark',

  AccidentFrom3rd: 'Accident From Third Party',
  AccidentFromRTA: 'Accident From RTA',
  AccidentDate: 'Accident Date',
  AccidentDateFrom: 'Accident Date From',
  AccidentDateTo: 'Accident Date To',
  VehicleRegistrationNo: 'Vehicle Registration No.',
  AccidentStatus: 'Accident Status',
  AccidentId: 'Accident ID',
  AccidentTime: 'Accident Time',
  Cateogry: 'Accident Cateogry',
  Classification: 'Accident Classification',
  CallTime: 'CallTime',
  Operator: 'Operator',
  VisitPerson: 'Visit Person',
  VisitTime: 'Visit Time',
  PolicePresence: 'Police Presence',
  PoliceNumber: 'Police Number',
  PoliceReport: 'Police Report',
  AccidentCondition: 'Accident Condition',
  AccidentComment: 'Accident Comment',
  LocationType: 'Location Type',
  LocationDistrict: 'Location District',
  LocationRoad: 'Location Road',
  LocationPostalCode: 'Location Postal Code',
  LocationPOI: 'Location POI',
  LocationMap: 'Location Map',
  DamageInformation: 'Damage Information',
  AccurateAccidentLocation: 'Accurate Accident Location',

  DownloadDocument: 'Download Document',
  RejectAccident: 'Reject Accident',
  CompleteAccident: 'Complete Accident',

  AgencyCode: 'Agency Code',
  RegistrationNo: 'Registration Number',
  Brand: 'Brand',
  Model: 'Model',
  Type: 'Type',
  Capacity: 'Capacity',
  Color: 'Color',
  Passenger: 'Passenger',
  Declaration: 'Declaration',
  VisitorNoate: 'Visitor Note',
  DamagePart: 'Damage Part',
  Insurance: 'Insurance',
  UploadDeclaration: 'UploadD eclaration',
  UploadDrivingLicense: 'Upload Driving License',
  UploadOwnerCertificate: 'Upload Owner Certificate',
  UploadRentalAggrement: 'Upload Rental Aggrement',
  UploadInsuranceCertificate: 'Upload Insurance Certificate',
  RelevantPerson: 'Relevant Person',

  PoliticalID: 'Political ID',
  Name: 'Name',
  Sex: 'Sex',
  BirthDate: 'Birth Date',
  Tel: 'Tel',
  LicenseNumber: 'License Number',
  LicenseDate: 'License Date',
  PersonType: 'Person Type',
  InsuranceOwner: 'Insurance Owner',
  Injuries: 'Injuries',

  Vehicle: 'Vehicle',
  Rejected: 'Rejected',
  RejectVehicle: 'Reject Vehicle',
  CreateClaim: 'Create Claim',
  ClaimGenerated: 'Claim Generated',
  RtaVehicleNotiGenerated:
    'Claim nofification has already been generated : ',
  RtaVehicleClaimNoGenerated:
    'Claim case has already been generated : ',

  NoofMatchedPolicy: 'No. of Matched Policy',
  MatchedPolicyNo: 'Match policy number : ',
  ViewAccident: 'View Accident',
  ProcessAccident: 'Process Accident',
  RegNo: 'Reg No.',
  MatchPolicyNo: 'Match Policy No.',
  PolicyPeriod: 'Policy Period',
  NotMatchPolicyMsgSimple: 'Does not match any valid policy.',
  NotMatchPolicyMsg:
    "The vehicle and/or driver in the provided accident information cannot be matched with an active policy. Please, match the accident xxxxxx with a policy or discard the accident notification as 'Processed'",
  AccidentInformation: 'Accident Information',

  MassiveReassignmentTaskowner: 'Massive Reassignment (Task Owner)',
  MassiveReassignment: 'Bulk Reassignment',
  TaskOwner: 'Task Owner',
  CreateDate: 'Create Date',
  CreateUser: 'Create User',
  ChangeTaskOwner: 'CHANGE  TASK  OWNER',
  ChangeTaskOwnerPage: 'Change Task Owner',
  NoRecSelected:
    'Please select at least one record for reassignment.',
  NoAssignToSelected: 'Please select one user for reassignment. ',
  MassiveReassignmentClaimowner: 'Massive Reassignment (Claim Owner)',
  PolicyHolder: 'Policyholder',
  SubClaimNo: 'Subclaim Number',
  ReassignSubclaimType: 'Subclaim Type',
  ChangeClaimOwner: 'CHANGE  CLAIM  OWNER',
  ChangeClaimOwnerPage: 'Change Claim Owner',
  ReassignSus: 'Below task(s) are reassigned successfully.',
  ReassignFail:
    'Below task(s) are failed to reassign, because current user is without related task authority.',
  TaskList: 'Task List',
  ReassignClaimSus: 'Below claim(s) are reassigned successfully.',
  ReassignClaimFail:
    "Below claim(s) are failed to reassign, because current user is without 'Claim Owner' authority for following claim(s).",
  ClaimList: 'Claim List',

  SaveSuccess: '{0} is saved successfully.',
  SaveFail:
    '{0} is saved failed, please contact system administrator',
  SubmitSuccess: '{0} is submitted successfully.',
  SubmitFail:
    '{0} is submitted failed, please contact system administrator',
  DeleteSuccess: '{0} is removed successfully.',
  DeleteFail:
    '{0} is removed failed, please contact system administrator',
  IsExist: '{0} is existed.',
  Fail: 'Fail',

  claimAuthority: 'Claim Authority',
  permissionName: 'Permission Name',
  permissionCode: 'Permission Code',
  applicableProducts: 'Applicable Products',
  taskAuthority: 'Task Authority',
  ClaimTitle: 'CLAIM',
  AssignRight: 'Assign Right',
  ClaimAuthority: 'Claim Authority',
  InheritedAuthority: 'Inherited Authority',
  Authority: 'Authority',
  AuthorizedAmount: 'Authorized Amount',
  authoritySubmitSuccess: 'Authority is saved successfully',
  authoritySubmitError: 'Fail',
  authorityResetSuccess: 'Authority is reset successfully',
  onlyShowNoTaskOwner: 'Only Show Tasks without Owner',
  ClaimAuthorityJunior: 'Junior Claim Adjuster',
  ClaimAuthoritySenior: 'Senior Claim Adjuster',
  ClaimAuthorityManager: 'Claim Manager',
  AuthorityConfirmReset:
    'Are you sure you want to reset the authority? All the data will be inherited from the higher level by default once reset is confirmed.',

  assignmentNewTaskSu: 'Task is created successfully',
  assignmentReassignTaskSu: 'Task is reassigned successfully',
  assignmentCloseTaskSu: 'Task is closed successfully',
  assignmentComfirmClosed:
    'Are you sure you want to close the task? Data entered in this task will be lost once the task is closed',

  selectATaskFirst: 'Please select a task first',

  selectAssignTo: 'Please select Assign To',

  ClaimTaskSU: 'Task(s) are claimed successfully',
  ClaimTaskFail: 'Task(s) are claimed fail',

  SuitName: 'Suit Name',
  Lawyer: 'Lawyer',
  AttachTo: 'Attach to',
  LitigationStatus: 'Status',
  LitigationAction: 'Action',
  LitigationButtonEdit: 'Edit',
  LitigationButtonRemove: 'Remove',
  SuitType: 'Suit Type',
  SuitStatus: 'Suit Status',
  SuitCaseNumber: 'Suit Case Number',
  SuitOpenDate: 'Suit Open Date',
  AmountAppealed: 'Amount Appealed',
  SuitLocation: 'Suit Location',
  LawyerName: 'Lawyer Name',
  LawyerTel: 'Lawyer Telephone',
  LawyerEmail: 'Lawyer E-Mail',
  SuitConclusion: 'Suit Conclusion',
  SuitCloseDate: 'Suit Close Date',
  DipositionAmount: 'Disposition Amount',
  SuitStatusRemark: 'Suit Status Remark',
  removeLitigationConfirm:
    'Are you sure you want to remove the suit?',
  provideValidEmail: 'Please provide valid Lawyer Email',
  FieldMaxLength: '{0} max length is {1}',
  litigationInfo: 'Litigation Info',
  litigationList: 'Litigation List',
  others: 'Others',

  deleteConfirmText: 'Are you sure to delete this parameter?',
  ReLogin: 'please reLogin',

  CatastropheSaved: 'Catastrophe is created successfully',
  CatastropheCheck: 'Catastrophe Code is exist',

  NotPremiumRecord: 'No premium records found on this policy. ',
  PremiumNotCollected:
    'The premium on this policy has not been collected. ',
  PremiumCollected:
    'The premium on this policy has  been collected. ',

  FraudStatus: 'Fraud Status',
  FraudIndicatorTitle: 'Fraud Indicator',
  FraudIndicatorAndDesc: 'Fraud Indicators and Description',

  ErrorMessage: {
    CLM__UNKNOWN_ERROR:
      'Unknown system error, please contact the administrator',
  },
  ClearCache: 'Clear Cache',
  ReserveHistoryBlankOption: 'All',
  RetrievePolicySuccessfully: 'Retrieve the Policy successfully',

  I18nUtilValidate: 'The {0} is required and cannot be empty',

  ClaimHandlingTitle: 'Claim {0}',
  AssignToSelectBlankOption: 'POOL',

  MessageDimissed: 'Message is completed successfully',

  ReserveBigThenSumInsuredError:
    'Initial reserve should not be grater than sum insured',

  NoRecordFound: 'No Record Found',

  ProductInfo: 'Product Infomation',
  RiskInfo: 'Risk Infomation',
  RiskName: 'Risk Name',
  Producer: 'Producer',
  AddRiskName: 'Add Risk',
  AddRiskButton: 'ADD RISK',
  AddRiskFail: 'The Policy has new Endorsement.',
  PolicyRetrieveFail: 'Fail to  get the Policy infomatioin',

  SyncInfo: 'SYNCHRONIZE INFO',
  DocumentUpload: 'Documents',
  Document: 'Document',
  TransactionType: 'Transaction Type',
  UploadedBy: 'Uploaded By',
  Time: 'Date',
  UploadDocument: 'Upload Document',
  DateFormat: 'MM/DD/YYYY',
  DateFullFormat: 'MM/DD/YYYY HH:MM',
  FilePreview: 'Preview',
  FileDownload: 'Download',
  FileDelete: 'Delete',
  DuplicateCheck:
    'The selected document already exist.Cannot upload again.',
  duplicateDialog: 'Duplicate Document Check',
  DeleteMessage: 'Are you sure to delete?',
  ApprovalTaskCodeToName: {
    ClaimAppraisalPApprovalTask:
      'The appraisal task need to be sent for senior approval',
    ClaimAppraisalIApprovalTask:
      'The appraisal task need to be sent for senior approval',
    ClaimAppraisalVApprovalTask:
      'The appraisal task need to be sent for senior approval',
    ClaimSubrogationApprovalTask:
      'All subrogation tasks need to be sent for senior approval',
    ClaimSalvageApprovalTask:
      'All salvage tasks need to be sent for senior approval',
    ClaimRecoveryApprovalTask:
      'All recovery tasks need to be sent for senior approval',
    ClaimRefundApprovalTask:
      'All refund tasks need to be sent for senior approval',
    ClaimSettlementApprovalTask:
      'The settlement task need to be sent for senior approval',
    ClaimCalculationApprovalTask:
      'The calculation task need to be sent for senior approval',
    ClaimReserveCloseApprovalTask:
      'The reserve task need to be sent for senior approval',
    ClaimReserveUpdateApprovalTask:
      'The reserve task need to be sent for senior approval',
  },
  ClaimSettlementApprovalTask: 'ClaimSettlementApprovalTask',

  Routes: 'Routes',
  Path: 'Path',
  Description: 'Description',
  CloseTask: 'Close Task',
  ResetAuthority: 'Reset Authority',
  Common: 'Common',
  SearchCriteria: 'Search Criteria',
  FileUpload: 'File Upload',
  ProductLineName: 'ProductLineName',
  ProductCode: 'ProductCode',
  UnreadMessages: 'Unread Messages ',
  claimNo: 'claimNo',
  Subject: 'Subject',
  PendingTasks: 'Pending Tasks',
  DiaryDate: 'Diary Date',
  MessageDetail: 'Message Detail',
  Test: 'test',
  LOS: 'LOS',
  SAL: 'SAL',
  SUB: 'SUB',
  DownLoad: 'DownLoad',
  CLOSE: 'CLOSE',
  ProductLine: 'Product Line',
  ErrorMessageB: 'Error Message',
  Query: 'Query',
  NewNoiceOfLoss: 'FNOL',
  From: 'From',
  DateT: 'Date:',
  ClaimantT: 'Claimant:',
  MessageBody: 'Message Body',
  InseredPersonName: 'Insered Person Name',
  Field01: 'field01',
  Field02: 'field02',
  Field05: 'field05',
  Field04: 'field04',
  Click: 'click',
  PartyName: 'Party Name',
  TreeSelect: 'treeSelect',
  NewOutstandingReserve: 'New Outstanding Reserve',

  ErrorMsg: {
    Error_0001: 'init Assignment Approach Fail',
    Error_0002: 'ERROR',
    APermissionCannotBeEmpty:
      'A permission cannot be empty, please try again.',
    PARSEPERMISSIONCODEERROR: 'PARSE PERMISSIONCODE ERROR',
    QueryTransactionTypeError: 'QueryTransactionType Error.',
    SystemError: 'System Error',
    GetDocumentError: 'Get Document Error.',
    PleaseUploadTheRequiredDocument:
      'Please upload the required document.',
    ClaimTaskFailT: 'claimTask Fail:',
    ClaimTaskFailP: 'claimTask Fail:{0}',
    UserHasNoAuthorityToRetrievePolicyPleaseCheck:
      'User has no authority to retrieve policy, please check',
    QueryFail: 'query fail',
    PleaseSelectTheDecisionOptionFirst:
      "'Please select the decision option first.",
    DateOfLossIsNotInThePolicyPeriod:
      'Date of Loss is not in the policy period.',
    DownloadError: 'Download error',
    UpdateTaskOwnerPoolToCurrentUser:
      'update taskOwner pool to currentUser',
    LoadCodeTableError: 'Load codeTable error.',
    Failed: 'failed',
    DataLoadERROR: 'CodeTable->DataList Load Error,id:{0}',
    GetPoolTaskFail: 'getPoolTask Fail:{0}',
    FailUPPERCASE: 'FAIL',
    SubmitFailP: 'Submit fail{0}',
    GOrMPolicyChangeCertificate:
      'The Policy you retrieve is Group Policy No. or Master Policy No., Pls change to Certificate Policy No.</br>{0}',
    Error_0003:
      'the total size limitation of documents had been exceeded 25 MB, system can’t allow to upload any more',
  },

  WarnMsg: {
    PleaseSelectOneResult: 'Please select one result.',
    DateToCannotEarlierThanDateFrom:
      "'Date of Loss to' can not be earlier than 'Date of Loss from'! ",
    TheSizeOfFileExceedsTheLimitation:
      'The size of file exceeds the limitation',
    ProductIsDifferentNeedCheckPolicy:
      'The product on the policy is different from current product, please verify the policy no.',
    ClaimIsBeyondYourAuthorityNeedCheck:
      'The new accumulated reserve amount for the claim is beyond your authority,please contact your supervisor to handle it.',
  },
  SuccessMsg: {
    Downloadsuccessfully: 'Download successfully',
  },

  ConfirmMsg: {},

  InfoMsg: {},
};
