import {
  UIPanel,
  UIText,
  UIParam,
  UIDataTable,
  UISelect,
  UIColumn,
  UICurrency,
  UILink,
} from 'RainbowUI';
import BindToMixin from 'react-binding';
import {
  CodeTableAction,
  CodeTableCstUI,
  ClaimMessageForamtUtils,
} from 'ClaimCommonConstant';
import AppraisalVehicleStore from '../../store/AppraisalVehicleStore';

const AppraisalVehicleAction = require('../../action/AppraisalVehicleAction');

const AppraisalLaborCost = React.createClass({
  mixins: [BindToMixin],

  getInitialState() {
    return { claimAppraisalVehicle: this.props.appraisalVehicle };
  },

  componentWillMount() {
    AppraisalVehicleStore.listen(this.onStoreStateChange);
  },

  componentWillUnmount() {
    AppraisalVehicleStore.unlisten(this.onStoreStateChange);
  },

  onStoreStateChange(storeState) {
    this.setState({
      appraisalVehicle:
        storeState.AppraisalData.AppraisalInfo.appraisalVehicle
          .claimAppraisalVehicleLabor,
    });

    this.forceUpdate();
  },

  addLaborCostItem() {
    AppraisalVehicleAction.addLaborCostItem();
  },

  buildTableFunction(io) {
    return (
      <div>
        <UILink
          icon="fa fa-plus"
          size="lg"
          iconClass="add_iconbtn"
          onClick={this.addLaborCostItem}
          visibled={io !== 'out'}
        />
      </div>
    );
  },

  getIOStyle() {
    return this.props.readOnly == true ? 'out' : null;
  },
  getVisibled() {
    return this.props.readOnly == true ? 'false' : 'true';
  },
  render() {
    const io = this.getIOStyle();
    const visibled = this.getVisibled();
    const _ref = this;
    const {
      parentComponent,
      appraisalVehicle: claimAppraisalVehicle,
      history,
    } = this.props;
    const { CurrencyCode } = parentComponent.state;

    const appraisalVehicleLaborItem = this.bindArrayTo(
      claimAppraisalVehicle,
      'claimAppraisalVehicleLabor'
    ).items;
    let style;
    if (history) {
      style = 'default';
    } else {
      style = 'primary';
    }
    const length = appraisalVehicleLaborItem.length - 1;
    return (
      <UIPanel
        panelTitle={i18n.ClaimAppraisal.LaborCost}
        toggleable="true"
        styleClass={style}
      >
        <div>
          <UIDataTable
            id="ClaimAppraisalVehicleItems_DataTable"
            colspan="2"
            pageable="false"
            indexable="false"
            value={appraisalVehicleLaborItem}
            functions={this.buildTableFunction(io)}
          >
            <UIColumn
              headerTitle={i18n.ClaimAppraisal.PartsName}
              render={data => {
                if (data.dataIndex == length) {
                  return <UIText io="out" />;
                }
                return (
                  <UIText
                    model={data.value}
                    enabled={visibled}
                    property="PartsName"
                    required="true"
                    validationGroup="appraisalSubmit"
                    requiredMessage={ClaimMessageForamtUtils.formatI18NMsg(
                      i18n.ClaimCommon.I18nUtilValidate,
                      i18n.ClaimAppraisal.PartsName
                    )}
                    label=""
                    widthAllocation="2,10"
                  />
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.ClaimAppraisal.PartsDescription}
              width="200px"
              render={data => {
                if (data.dataIndex == length) {
                  return (
                    <UIText
                      model={data.value}
                      property="PartsDesc"
                      io="out"
                    />
                  );
                }
                return (
                  <UIText
                    model={data.value}
                    enabled={visibled}
                    property="PartsDesc"
                  />
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.ClaimAppraisal.WorkType}
              render={data => {
                if (data.dataIndex == length) {
                  return <UIText io="out" />;
                }
                return (
                  <UISelect
                    model={data.value}
                    property="WorkType"
                    enabled={visibled}
                    codeTable={CodeTableAction.loadDataList(
                      CodeTableCstUI.ClaimWorkType,
                      null
                    )}
                    required="true"
                    validationGroup="appraisalSubmit"
                    label=""
                    widthAllocation="2,10"
                    requiredMessage={ClaimMessageForamtUtils.formatI18NMsg(
                      i18n.ClaimCommon.I18nUtilValidate,
                      i18n.ClaimAppraisal.WorkType
                    )}
                  />
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.ClaimAppraisal.DamageType}
              render={data => {
                if (data.dataIndex == length) {
                  return <UIText io="out" />;
                }
                return (
                  <UISelect
                    model={data.value}
                    property="DamageType"
                    enabled={visibled}
                    codeTable={CodeTableAction.loadDataList(
                      CodeTableCstUI.ClaimDamageType,
                      null
                    )}
                    required="true"
                    validationGroup="appraisalSubmit"
                    label=""
                    widthAllocation="2,10"
                    requiredMessage={ClaimMessageForamtUtils.formatI18NMsg(
                      i18n.ClaimCommon.I18nUtilValidate,
                      i18n.ClaimAppraisal.DamageType
                    )}
                  />
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.ClaimAppraisal.LaborCost}
              render={data => {
                if (data.dataIndex == length) {
                  return (
                    <UICurrency
                      id={`CURRENCY_CODE_LaborCost${data.dataIndex}`}
                      model={data.value}
                      property="LaborAmount"
                      defaultValue="0"
                      unit={window.EU00.getCurrencySignByCurrencyCode(
                        CurrencyCode
                      )}
                      enabled="false"
                    />
                  );
                }
                return (
                  <UICurrency
                    id={`CURRENCY_CODE_LaborCost${data.dataIndex}`}
                    model={data.value}
                    property="LaborAmount"
                    defaultValue="0"
                    unit={window.EU00.getCurrencySignByCurrencyCode(
                      CurrencyCode
                    )}
                    enabled={visibled}
                    onChange={_ref.changeLaborCost}
                  />
                );
              }}
            />
            <UIColumn
              width="20px"
              render={data => {
                if (data.dataIndex == length) {
                  return <UIText io="io" />;
                }
                return (
                  <UILink
                    visibled={visibled}
                    onClick={_ref.removeLaborCostItem}
                    icon="fa fa-trash"
                  >
                    <UIParam
                      name="removeIndex"
                      value={data.dataIndex}
                    />
                  </UILink>
                );
              }}
            />
          </UIDataTable>
        </div>
      </UIPanel>
    );
  },
  getTotalLaborCost() {
    const { appraisalVehicle } = this.props;
    const tableresult =
      appraisalVehicle.sourceObject.appraisalVehicle
        .claimAppraisalVehicleLabor;

    if (tableresult) {
      let totalLC = 0;
      for (let i = 0; i <= tableresult.length - 2; i += 1) {
        totalLC += tableresult[i].LaborAmount;
      }
      tableresult[tableresult.length - 1].LaborAmount = totalLC;

      return totalLC;
    }
    return null;
  },

  changeLaborCost() {
    const totalLC = this.getTotalLaborCost();

    this.props.appraisalVehicle.sourceObject.appraisalVehicle.TotalLaborAmount = totalLC;
    this.forceUpdate();
    this.props.parentComponent.changeLaborInTotalCost();
  },

  removeLaborCostItem(event) {
    AppraisalVehicleAction.removeLaborCostItem(event);
    this.changeLaborCost();
  },
});
export default AppraisalLaborCost;
