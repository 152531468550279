import { UIParam, UIButton } from 'RainbowUI';

const ClaimCstUI = require('../../constant/ClaimCstUI');

const ReserveLight = React.createClass({
  getStyle(tmpdisabled) {
    const disabled = tmpdisabled;
    const styles = new Array();
    let { reserveStatus } = this.props;
    if (!reserveStatus || reserveStatus.length === 0) {
      reserveStatus = [
        ClaimCstUI.RESERVE_STATUS.Unopen,
        ClaimCstUI.RESERVE_STATUS.Unopen,
        ClaimCstUI.RESERVE_STATUS.Unopen,
      ];
    }

    reserveStatus.forEach((status, index) => {
      const style = { color: 'white', fontWeight: 'bold' };
      switch (status) {
        case null:
          style.backgroundColor = 'gray';
          disabled[index] = true;
          break;
        case undefined:
          style.backgroundColor = 'gray';
          disabled[index] = true;
          break;
        case '':
          style.backgroundColor = 'gray';
          disabled[index] = true;
          break;
        case ClaimCstUI.RESERVE_STATUS.Unopen:
          style.backgroundColor = 'gray';
          disabled[index] = true;
          break;
        case 'NULL':
          style.backgroundColor = 'gray';
          disabled[index] = true;
          break;
        case 'NEW':
          style.backgroundColor = 'red';
          break;
        case ClaimCstUI.RESERVE_STATUS.Open:
          style.backgroundColor = 'red';
          break;
        case ClaimCstUI.RESERVE_STATUS.Closed:
          style.backgroundColor = 'green';
          disabled[index] = true;
          break;
        case ClaimCstUI.RESERVE_STATUS.Reopen:
          style.backgroundColor = 'orange';
          break;
        default:
      }
      styles.push(style);
    });
    return styles;
  },
  getDisabled() {
    let { reserveCloseAuth } = this.props;
    if (!reserveCloseAuth || reserveCloseAuth.length === 0) {
      reserveCloseAuth = [true, true, true];
    }
    const disabled = [false, false, false];
    for (let i = 0; i < 3; i += 1) {
      if (!reserveCloseAuth[i]) {
        disabled[i] = true;
      }
    }
    return disabled;
  },
  returnEmptySpan() {
    const { level } = this.props;
    if (
      !level ||
      ((level == ClaimCstUI.CLAIM_LEVEL ||
        level == ClaimCstUI.SUBCLAIM_LEVEL) &&
        this.props.relateStatusCode === 'REJECT')
    ) {
      return true;
    }
    return false;
  },
  getParentComponent() {
    return this;
  },
  componentWillReceiveProps(nextProps) {
    if (this.props.reserveStatus !== nextProps.reserveStatus) {
      this.forceUpdate();
    }
  },
  render() {
    const { level } = this.props;
    if (this.returnEmptySpan() == true) {
      return <span />;
    }
    const { relateId } = this.props;
    const { coverageName } = this.props;
    let { reserveStatus } = this.props;
    if (!reserveStatus) {
      reserveStatus = [
        ClaimCstUI.RESERVE_STATUS.Unopen,
        ClaimCstUI.RESERVE_STATUS.Unopen,
        ClaimCstUI.RESERVE_STATUS.Unopen,
      ];
    }
    const disabled = this.getDisabled();
    const styles = this.getStyle(disabled);
    return (
      <span>
        <span>
          <UIButton
            style={styles[0]}
            value={i18n.ClaimCommon.LOS}
            size="xs"
            onClick={this.clickReserve}
            disabled={disabled[0]}
          >
            <UIParam name="type" value="LOS" />
            <UIParam name="statusIndex" value={0} />
            <UIParam name="status" value={reserveStatus[0]} />
            <UIParam name="relateId" value={relateId} />
            <UIParam name="level" value={level} />
            <UIParam name="coverageName" value={coverageName} />
          </UIButton>
        </span>
        <span>
          <UIButton
            style={styles[1]}
            value={i18n.ClaimCommon.SAL}
            size="xs"
            onClick={this.clickReserve}
            disabled={disabled[1]}
          >
            <UIParam name="type" value="SAL" />
            <UIParam name="statusIndex" value={1} />
            <UIParam name="status" value={reserveStatus[1]} />
            <UIParam name="relateId" value={relateId} />
            <UIParam name="level" value={level} />
            <UIParam name="coverageName" value={coverageName} />
          </UIButton>
        </span>
        <span>
          <UIButton
            style={styles[2]}
            value={i18n.ClaimCommon.SUB}
            size="xs"
            onClick={this.clickReserve}
            disabled={disabled[2]}
          >
            <UIParam name="type" value="SUB" />
            <UIParam name="statusIndex" value={2} />
            <UIParam name="status" value={reserveStatus[2]} />
            <UIParam name="relateId" value={relateId} />
            <UIParam name="level" value={level} />
            <UIParam name="coverageName" value={coverageName} />
          </UIButton>
        </span>
      </span>
    );
  },

  clickReserve(event) {
    const level = event.getParameter('level');
    const reserveType = event.getParameter('type');
    const relateId = event.getParameter('relateId');
    const coverageName = event.getParameter('coverageName');
    const { subclaimIndex } = this.props;

    const _status = ['LOS', 'SAL', 'SUB'];
    const statusIndex = event.getParameter('statusIndex');
    const status = _status[statusIndex];
    this.props.parentComponent.refs.CloseReserveDialog_ref.showDialog(
      reserveType,
      level,
      relateId,
      null,
      coverageName,
      status,
      subclaimIndex
    );
  },
});
export default ReserveLight;
