export default {
  subrogationItemToAdd: {
    objectId: null,
    itemId: null,
    coverageCode: null,
    currencyCode: null,
    index: 0,
    initial(objectId, coverageCode, index, currencyCode, itemId) {
      this.objectId = objectId;
      this.coverageCode = coverageCode;
      this.index = index;
      this.currencyCode = currencyCode;
      this.itemId = itemId;
    },
    setIndex(index) {
      this.index = index;
    },
    get() {
      return {
        '@pk': null,
        '@type': 'ClaimSubrogationItem-ClaimSubrogationItem',
        ObjectId: this.objectId,
        CoverageCode: this.coverageCode,
        ItemId: this.itemId,
        Index: this.index,
        ReserveCurrency: '',
        RecoverFrom: null,
        Amount: 0,
        LimitationDate: null,
        Remark: null,
        CurrencyCode: this.currencyCode,
      };
    },
  },
};
