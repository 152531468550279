import { AjaxUtil } from 'RainbowUI';
import Url from '../url/Url';

export default {
  pendingTaskCount() {
    const deferred = $.Deferred();
    const url = Url.ClaimWorkflowGetMyTaskListSummary;
    AjaxUtil.doGet(
      url,
      {},
      {
        async: false,
        done(data) {
          deferred.resolve(data);
        },
        fail(err) {
          deferred.reject(err);
        },
      }
    );
    return deferred.promise();
  },
  pendingNoticeCount() {
    const deferred = $.Deferred();
    const url = Url.NoticeLoadList;
    AjaxUtil.doPost(
      url,
      { '@type': 'ClaimNotice-ClaimNotice' },
      {
        async: false,
        done(data) {
          deferred.resolve(data);
        },
        fail(err) {
          deferred.reject(err);
        },
      }
    );
    return deferred.promise();
  },
  sharingPoolCount() {
    const deferred = $.Deferred();
    const url = Url.ClaimWorkflowGetSharingPoolListSummary;
    AjaxUtil.doGet(
      url,
      {},
      {
        async: false,
        done(data) {
          deferred.resolve(data);
        },
        fail(err) {
          deferred.reject(err);
        },
      }
    );
    return deferred.promise();
  },
  myClaimCount() {
    const deferred = $.Deferred();
    const url = Url.ClaimWorkFlowCountMyClaim;
    AjaxUtil.doGet(
      url,
      {},
      {
        async: false,
        done(data) {
          deferred.resolve(data);
        },
        fail(err) {
          deferred.reject(err);
        },
      }
    );
    return deferred.promise();
  },
  newMessageCount(condition) {
    const deferred = $.Deferred();
    const url = Url.InitMessage;

    AjaxUtil.doPost(url, condition, {
      async: false,
      done(data) {
        if (data) {
          deferred.resolve(data.length);
        } else {
          deferred.resolve('0');
        }
      },
      fail(err) {
        deferred.reject(err);
      },
    });

    return deferred.promise();
  },
};
