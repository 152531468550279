import {
  UIDialog,
  UIParam,
  UIRadio,
  UIBox,
  MessageHelper,
  DataContext,
  CodeTable,
  UIDataTable,
  UISelect,
  UIButton,
  UIColumn,
  UIPanel,
  UIUpdatePanel,
  UpdateContext,
} from 'RainbowUI';
import BindToMixin from 'react-binding';
import { History } from 'react-router';
import TaskTransferService from '../service/TaskTransferService';

import TaskAssignUserRuleService from '../service/TaskAssignUserRuleService';

const TaskTransferAction = require('../action/TaskTransferAction');
const code = require('../mock/CodeTable');
const CodeTableAction = require('../action/CodeTableAction');
const CodeTableCstUI = require('../constant/CodeTableCstUI');
const ClaimAuthorityCode = require('../constant/ClaimAuthorityCode');

const TaskTransferDialog = React.createClass({
  mixins: [BindToMixin, History],
  getInitialState() {
    return {
      caseId: '',
      taskList: [],
      authInfo: {},
      data: {},
      isCancel: false,
      taskInstance: {},
      updateAble: false,
      currentUserId: '',
    };
  },

  componentWillUnmount() {
    TaskAssignUserRuleService.clearUserList();
  },
  onClose() {
    UIDialog.hide('taskTransferDialog');
    UpdateContext.forceUpdate('taskTransferDialogUpdatePanel');
  },
  init(param) {
    const { CaseId, currentUserId } = param;
    const authInfo = TaskAssignUserRuleService.queryUserAuthInfo(
      currentUserId,
      CaseId
    );
    this.setState({
      authInfo,
      currentUserId,
      data: param,
      taskInstance: {},
      caseId: CaseId,
    });
    return authInfo;
  },

  showDialog(tmpparam, flag) {
    const param = tmpparam;
    delete param.WorkflowEnd;
    this.state.updateAble = true;
    DataContext.remove('ChangeData');

    const authInfo = this.init(param);

    const paramCondition = {};
    paramCondition.CaseId = param.CaseId;
    const code = TaskTransferAction.AssignmentApprochCode(
      paramCondition
    );
    const componentId = this.props.componentId || '';
    param.warnMessage = i18n.ClaimCommon.WarnMessage;
    if (param.TaskTransfer) {
      const taskList = TaskTransferAction.getTaskList(
        param,
        'false',
        authInfo
      );
      /* for(var a=0;a<taskList.length;a++){
        if(taskList[a].TaskAssignee == "andy" &&taskList[a].TaskDefinitionKey=='ClaimRegistrationTask'
                    &&taskList[a].InsertBy==taskList[a].CurrUserId){
          taskList[a].TaskAssignee = taskList[a].CurrUserId;
        }
      } */
      this.setState({ taskList });
      UIDialog.show(`taskTransferDialog${componentId}`);
    } else if (param.CloseTaskTransfer || code === 'WARN') {
      param.warnMessage = i18n.ClaimCommon.WarnMessage;
      this.setState({
        taskList: TaskTransferAction.getTaskList(
          param,
          'false',
          authInfo
        ),
      });
      UIDialog.show(`taskTransferDialog${componentId}`);
    } else {
      param.warnMessage = i18n.ClaimCommon.WarnMessage;
      if (code === 'USER') {
        let taskList = TaskTransferAction.getTaskList(
          param,
          'true',
          authInfo
        );
        if (taskList.length > 0) {
          TaskTransferAction.checkApprovalTask(
            taskList[taskList.length - 1].TaskDefinitionKey,
            flag
          );
          taskList = [taskList[taskList.length - 1]];
        }
        if (flag === 'settlementSuccess') {
          MessageHelper.success(
            '',
            i18n.ClaimSettlement.SettlementSubmitSuccess
          );
        }
        if (flag === 'recoverySuccess') {
          MessageHelper.success(
            '',
            i18n.ClaimSettlement.RecoverySubmitSuccess
          );
        }
        if (flag === 'refundSuccess') {
          MessageHelper.success(
            '',
            i18n.ClaimSettlement.RefundsSubmitSuccess
          );
        }
        delete param.TaskTransfer;
        this.setState({ taskList });
        UIDialog.show(`taskTransferDialog_user${componentId}`);
      } else {
        delete param.TaskTransfer;
        const ok = TaskTransferAction.updateTaskWithUserName(param);
        if (ok === 'OK') {
          this.setState({
            taskList: TaskTransferAction.getTaskList(
              param,
              'false',
              authInfo
            ),
          });
          UIDialog.show(`taskTransferDialog${componentId}`);
        }
      }
    }
  },
  shouldComponentUpdate() {
    return this.state.updateAble;
  },
  userShow(code, io, taskInstanceId) {
    const authCode =
      io === 'out' ? 'noAuthCode' : ClaimAuthorityCode[code];
    const caseId = this.state.data.CaseId;
    const taskId = io === 'out' ? '' : taskInstanceId;
    if (code.indexOf('Approval') != -1) {
      io == 'out';
    }
    const codeTable = TaskAssignUserRuleService.queryUserList(
      authCode,
      caseId,
      taskId
    );
    return codeTable;
  },
  selecedUser() {
    const componentId = this.props.componentId || '';
    const changedData = DataContext.get('ChangeData');
    if (
      !changedData ||
      changedData.length < this.state.taskList.length
    ) {
      MessageHelper.info(i18n.ClaimCommon.selectAssignTo);
      return;
    }
    for (let i = 0; i < this.state.taskList.length; i += 1) {
      const da = this.state.taskList[i];

      let existing = false;
      for (let j = 0; j < changedData.length; j += 1) {
        const chData = changedData[j];
        if (chData.TaskInstanceId == da.id && chData.AssignTo) {
          existing = true;
        } else if (
          chData.TaskInstanceId == da.id &&
          !chData.AssignTo
        ) {
          existing = false;
        }
      }
      if (existing == false) {
        MessageHelper.info(i18n.ClaimCommon.selectAssignTo);
        return;
      }
    }

    const ok = TaskTransferAction.updateTask();
    if (ok === 'OK') {
      this.setState(prevState => {
        return {
          taskList: TaskTransferAction.getTaskList(
            prevState.data,
            'false',
            prevState.authInfo
          ),
        };
      });

      UIDialog.hide(`taskTransferDialog_user${componentId}`);
      UIDialog.show(`taskTransferDialog${componentId}`);
    }
  },
  AssignmentAttachTo() {
    const codeTable = [{ id: 'claim', text: 'Claim' }];
    const params = this.state.data.CaseId;

    const entityList = TaskTransferAction.getSubClaimNoByCaseId(
      params
    );
    for (let i = 0; i < entityList.length; i += 1) {
      codeTable.push({
        id: entityList[i].ObjectId,
        text: `Subclaim ${entityList[i].SeqNo}`,
      });
    }

    return new CodeTable(codeTable);
  },
  getSelectStatus() {
    return true;
  },
  cancelSelect() {
    this.setState({ isCancel: true });

    const ok = TaskTransferAction.updateTaskWithUserName(
      this.state.data
    );
    const componentId = this.props.componentId || '';
    if (ok === 'OK') {
      this.setState(prevState => {
        return {
          taskList: TaskTransferAction.getTaskList(
            prevState.data,
            'false',
            prevState.authInfo
          ),
        };
      });
      UIDialog.hide(`taskTransferDialog_user${componentId}`);
      UIDialog.show(`taskTransferDialog${componentId}`);
    }
  },
  singleValue(data) {
    return false;
  },

  getRadioColumn() {
    const result = (
      <UIColumn
        render={data => {
          const checkIfCurrUserHasAuth = () => {
            const authcode =
              ClaimAuthorityCode[data.TaskDefinitionKey];
            const caseId = data.CaseId;
            const taskId = data.id;
            const currUserId = data.CurrUserId;
            let ifContainsCurrUser = false;
            if (data.TaskAssignee === 'pool') {
              const codes = TaskAssignUserRuleService.queryUserList(
                authcode,
                caseId,
                taskId
              );
              const codeArr = codes.getCode();
              for (let i = 0; i < codeArr.length; i += 1) {
                if (currUserId == codeArr[i].id) {
                  ifContainsCurrUser = true;
                  break;
                }
              }
            } else if (data.TaskAssignee == data.CurrUserId) {
              ifContainsCurrUser = true;
            }

            return ifContainsCurrUser;
          };
          return (
            <UIRadio
              id={`selectAddrsssRadio${this.props.index}${data.dataIndex}`}
              codeTable={code.test}
              onChange={this.selectItem}
              model={data}
              property="selected"
              enabled={checkIfCurrUserHasAuth}
            >
              <UIParam name="dataIndex" value={data.dataIndex} />
              <UIParam name="data" value={data} />
            </UIRadio>
          );
        }}
      />
    );

    return result;
  },

  selectItem(event) {
    this.setState(prevState => {
      const dataIndex = event.getParameter('dataIndex');
      const data = event.getParameter('data');
      const list = prevState.taskList;
      for (const item in list) {
        list[item].selected = dataIndex == item ? 'Y' : 'N';
      }
      return {
        taskList: list,
        taskInstance: data,
      };
    });
  },
  workOn(event) {
    const componentId = event.getParameter('componentId') || '';
    const taskInstance = event.getParameter('taskInstance');
    if (!taskInstance || taskInstance == {} || !taskInstance.id) {
      MessageHelper.warning(i18n.ClaimCommon.selectATaskFirst);
      return;
    }
    const assignData = {
      TaskInstanceId: taskInstance.id,
      AssignTo: taskInstance.TaskAssignee,
    };
    TaskTransferService.assignPoolToCurrentUser(assignData).then(
      data => {
        const status = data.Status;
      },
      err => {
        if (err.status !== 200) {
          MessageHelper.error(
            err.statusText,
            i18n.ClaimCommon.ErrorMsg.UpdateTaskOwnerPoolToCurrentUser
          );
        }
      }
    );

    if (taskInstance.TaskDefinitionKey === 'ClaimRegistrationTask') {
      window.location.hash = `#/handling/registration?taskInstanceId=${taskInstance.id}`;
    } else if (
      taskInstance.TaskDefinitionKey ==
      'ClaimReserveUpdateApprovalTask'
    ) {
      this.history.pushState(
        null,
        `/handling/tasks?taskInstanceId=${taskInstance.id}`,
        {
          businessId: taskInstance.BusinessId,
          reserveApprovalType: 0,
        }
      );
      window.location.reload();
    } else if (
      taskInstance.TaskDefinitionKey ==
      'ClaimReserveCloseApprovalTask'
    ) {
      this.history.pushState(
        null,
        `/handling/tasks?taskInstanceId=${taskInstance.id}`,
        {
          businessId: taskInstance.BusinessId,
          reserveApprovalType: 1,
        }
      );
      window.location.reload();
    } else {
      this.history.pushState(
        null,
        `/handling/tasks?taskInstanceId=${taskInstance.id}`,
        {}
      );
      window.location.reload();
    }
    UIDialog.hide(`taskTransferDialog${componentId}`);
  },
  onBackClaim() {
    this.history.pushState(
      null,
      `/handling/tasks?caseId=${this.state.caseId}`,
      {}
    );
    UIDialog.hide('taskTransferDialog');
    window.location.reload();
  },

  taskAssignRender() {
    const self = this;
    return data => {
      TaskTransferAction.saveChangeDataByCurrent(
        data,
        self.state.currentUserId
      );
      if (self.state.currentUserId) {
        return (
          <UISelect
            io="out"
            value={self.state.currentUserId}
            codeTable={self.userShow(
              data.TaskDefinitionKey,
              'out',
              data.id
            )}
          />
        );
      }
      return (
        <UISelect
          id={`select${data.dataIndex}`}
          value={data.AssignTo}
          codeTable={self.userShow(
            data.TaskDefinitionKey,
            'in',
            data.id
          )}
          validationGroup="newTask"
          required="true"
          onChange={TaskTransferAction.saveChangeData}
        >
          <UIParam name="data" value={data} />
        </UISelect>
      );
    };
  },

  render() {
    const _self = this;
    const componentId = _self.props.componentId || '';
    return (
      <UIUpdatePanel id="taskTransferDialogUpdatePanel">
        <div>
          <UIDialog
            id={`taskTransferDialog_user${componentId}`}
            title={i18n.ClaimCommon.TaskTransferA}
            width="80%"
            height="500px"
            onClose={this.cancelSelect}
          >
            <UIPanel
              panelTitle={i18n.ClaimCommon.TaskTransferM}
              styleClass="default"
            >
              <h6 style={{ margin: '10px 17%', color: 'red' }} />
              <UIDataTable
                id={`taskTransferDataTable${componentId}`}
                isHeading="false"
                indexable="false"
                pageable="false"
                value={_self.state.taskList}
              >
                <UIColumn
                  headerTitle={i18n.ClaimCommon.AssignmentAttachTo}
                  value="AttachTo"
                >
                  <UISelect
                    codeTable={this.AssignmentAttachTo}
                    io="out"
                  />
                </UIColumn>
                <UIColumn
                  headerTitle={i18n.ClaimCommon.ClaimNumber}
                  value="ClaimNo"
                />
                <UIColumn
                  headerTitle={i18n.ClaimCommon.TaskType}
                  value="TaskDefinitionKey"
                >
                  <UISelect
                    codeTable={CodeTableAction.loadDataList(
                      CodeTableCstUI.CLAIMTASK,
                      null
                    )}
                    io="out"
                  />
                </UIColumn>

                <UIColumn
                  id="TaskAssignee"
                  headerTitle={i18n.ClaimCommon.AssignTo}
                  value="TaskAssignee"
                  render={this.taskAssignRender()}
                />

                <UIColumn
                  headerTitle={i18n.ClaimCommon.TaskStatus}
                  value="TaskStatus"
                >
                  <UISelect io="out" codeTable={code.taskStatus} />
                </UIColumn>
              </UIDataTable>
              <UIBox direction="center">
                <UIButton
                  value={i18n.ClaimCommon.Submit}
                  onClick={this.selecedUser}
                  validationGroup="newTask"
                />
                <UIButton
                  value={i18n.ClaimCommon.Cancel}
                  buttonType="cancel"
                  onClick={this.cancelSelect}
                />
              </UIBox>
            </UIPanel>
          </UIDialog>

          <UIDialog
            id={`taskTransferDialog${componentId}`}
            title={i18n.ClaimCommon.TaskTransfer}
            width="80%"
            height="500px"
            onClose={this.onBackClaim}
          >
            <UIPanel
              panelTitle={i18n.ClaimCommon.TaskTransferM}
              styleClass="default"
            >
              <p style={{ color: 'red', fontSize: '1em' }}>
                {_self.state.data.warnMessage}
              </p>
              <UIDataTable
                id="taskTransferDataTable"
                isHeading="false"
                indexable="false"
                pageable="false"
                value={_self.state.taskList}
              >
                {this.getRadioColumn()}
                <UIColumn
                  headerTitle={i18n.ClaimCommon.AssignmentAttachTo}
                  value="AttachTo"
                >
                  <UISelect
                    codeTable={this.AssignmentAttachTo}
                    io="out"
                  />
                </UIColumn>
                <UIColumn
                  headerTitle={i18n.ClaimCommon.ClaimNumber}
                  value="ClaimNo"
                />
                <UIColumn
                  headerTitle={i18n.ClaimCommon.TaskType}
                  value="TaskDefinitionKey"
                >
                  <UISelect
                    codeTable={CodeTableAction.loadDataList(
                      CodeTableCstUI.CLAIMTASK,
                      null
                    )}
                    io="out"
                  />
                </UIColumn>
                <UIColumn
                  headerTitle={i18n.ClaimCommon.AssignTo}
                  value="TaskAssignee"
                >
                  <UISelect
                    value="assignee"
                    io="out"
                    codeTable={_self.userShow('', 'out', '')}
                  />
                </UIColumn>
                <UIColumn
                  headerTitle={i18n.ClaimCommon.TaskStatus}
                  value="TaskStatus"
                >
                  <UISelect io="out" codeTable={code.taskStatus} />
                </UIColumn>
              </UIDataTable>
              {/*       <UIDialogFooter>
               <UIButton value={i18n.ClaimCommon.WorKOn} position="center" onClick={this.workOn}>
               <UIParam name="componentId" value={componentId}/>
               <UIParam name="WorkflowEnd" value={this.state.data.WorkflowEnd}/>
               <UIParam name="taskInstance" value={this.state.taskInstance}/>
               </UIButton>
               <UIButton value={i18n.ClaimCommon.BacktoClaim} position="center"  onClick={this.onBackClaim}/>
               <UIButton value={i18n.ClaimCommon.Cancel} position="center" buttonType='cancel' onClick={this.onClose}/>
               </UIDialogFooter> */}
              <div style={{ textAlign: 'center' }}>
                <UIButton
                  style={{ margin: '5px' }}
                  value={i18n.ClaimCommon.WorKOn}
                  position="center"
                  onClick={this.workOn}
                >
                  <UIParam name="componentId" value={componentId} />
                  <UIParam
                    name="WorkflowEnd"
                    value={_self.state.data.WorkflowEnd}
                  />
                  <UIParam
                    name="taskInstance"
                    value={_self.state.taskInstance}
                  />
                </UIButton>
                <UIButton
                  style={{ margin: '5px' }}
                  value={i18n.ClaimCommon.BacktoClaim}
                  position="center"
                  onClick={this.onBackClaim}
                />
              </div>
            </UIPanel>
          </UIDialog>
        </div>
      </UIUpdatePanel>
    );
  },
});
export default TaskTransferDialog;
