import ReactDOM from 'react-dom';
import {
  UIColumn,
  UIDataTable,
  UIText,
  UIParam,
  UILink,
  UICurrency,
  MessageHelper,
  UIDialog,
} from 'RainbowUI';
import { ClaimHandlingStore } from 'ClaimCommon';
import React from 'react';
import BindToMixin from 'react-binding';
import ClaimRegistration from 'ClaimRegistration';
import {
  CalculationLoadAction,
  LimitAndDeductionInfoDialog,
} from 'ClaimCalculation';
import { CommonConstant } from 'EU00PartyCommonUI';

const BigNumber = require('bignumber.js');
const LimitDeductDialog = require('./LimitDeductDialog');

const Coverage = React.createClass({
  mixins: [BindToMixin],

  getInitialState() {
    return {
      policyCoverageList: this.props.data.PolicyCoverageList,
      claimCurrency: this.props.parentComponent.props.data
        .CurrencyCode,
      currencyCode: this.props.policyData.CurrencyCode,
      claimType: this.props.parentComponent.state.claimType,
    };
  },

  componentWillMount() {
    ClaimHandlingStore.listen(this.onStoreStateChange);
  },

  componentDidMount() {
    if (this.props.forModify === 'true') {
      $(this.node)
        .find(":enabled[type='checkbox']")
        .each((index, data) => {
          $(data).attr('disabled', true);
        });
    }
  },

  componentDidUpdate() {
    if (this.props.forModify === 'false' && this.props.io === 'out') {
      $(this.node)
        .find(":enabled[type='checkbox']")
        .each((index, data) => {
          $(data).attr('disabled', true);
        });
    }
  },

  componentWillUnmount() {
    ClaimHandlingStore.unlisten(this.onStoreStateChange);
  },

  onStoreStateChange(storeState) {
    if (!_.isEmpty(storeState.caseForm)) {
      if (storeState.caseForm.ClaimEntity.PolicyEntity) {
        this.setState({
          currencyCode:
            storeState.caseForm.ClaimEntity.PolicyEntity.CurrencyCode,
        });
      }
      if (
        storeState.caseForm.ClaimEntity.ObjectList[
          this.props.subclaimIndex
        ]
      ) {
        this.setState({
          policyCoverageList:
            storeState.caseForm.ClaimEntity.ObjectList[
              this.props.subclaimIndex
            ].PolicyCoverageList,
        });
      }
    }
  },

  subclaimTypeChanged() {
    this.setState({
      policyCoverageList: this.props.data.PolicyCoverageList,
    });
  },

  componentWillReceiveProps(nextProps) {
    this.setState({
      policyCoverageList: nextProps.data.PolicyCoverageList,
    });
  },

  changeInitLossIndemnity(event) {
    const data = event.getParameter('data');
    const { policyCoverageList } = this.state;
    let { newValue } = event;
    while (newValue.indexOf(',') > 0) {
      newValue = newValue.replace(',', '');
    }
    const { oldValue } = event;
    const maxAmount = Number(999999999.99);
    const initLossIndemnity = Number(newValue);
    if (maxAmount < initLossIndemnity) {
      if (maxAmount < Number(oldValue)) {
        this.state.objectEntity.EstimatedLossAmount = 0;
      } else {
        policyCoverageList[data.dataIndex].InitLossIndemnity = Number(
          oldValue
        );
      }
      this.forceUpdate();
      MessageHelper.error(i18n.ClaimReserve.MaxAmountMessage);
      return;
    }
    policyCoverageList[
      data.dataIndex
    ].InitLossIndemnity = initLossIndemnity;
    this.setState({ policyCoverageList });
    if (this.props.synCoverageData) {
      this.props.synCoverageData(policyCoverageList);
    }
  },

  onSelectAll() {
    const { policyCoverageList } = this.state;
    let selectAllFlag = false;
    const { partOfId } = this.props;
    $('input:not(:disabled)[type=checkbox]').each(
      (index, element) => {
        const checkId = `coverageList_${partOfId}_multiple_selectall`;
        if (element.checked && checkId == element.id) {
          selectAllFlag = true;
          return selectAllFlag;
        }
      }
    );

    policyCoverageList.map(datas => {
      const data = datas;
      if (!data.ItemStatusCode) {
        if (selectAllFlag) {
          if (data.Selected != '1') {
            data.ItemCurrencyCode = this.state.claimCurrency;
            data.InitLossIndemnity = `${data.LossConfigDefaultAmount}`;
            data.Selected = '1';
          }
        } else {
          data.InitLossIndemnity = 0.0;
          data.ItemCurrencyCode = '';
          data.Selected = '';
        }
      }
      return data;
    });

    this.setState({ policyCoverageList });
    if (this.props.synCoverageData) {
      this.props.synCoverageData(policyCoverageList);
    }
  },

  onRowSelect(event) {
    const { dataIndex } = event.selectRecord;
    const { policyCoverageList } = this.state;
    const beforeSelected =
      policyCoverageList[dataIndex].Selected == '1';

    let initalReserve = 0.0;
    let itemCurrency = '';
    let selected = '';
    if (!beforeSelected) {
      initalReserve = `${policyCoverageList[dataIndex].LossConfigDefaultAmount}`;
      itemCurrency = this.state.claimCurrency;
      selected = '1';
    }
    policyCoverageList[dataIndex].InitLossIndemnity = initalReserve;
    policyCoverageList[dataIndex].ItemCurrencyCode = itemCurrency;
    policyCoverageList[dataIndex].Selected = selected;

    this.setState({ policyCoverageList });
    if (this.props.synCoverageData) {
      this.props.synCoverageData(policyCoverageList);
    }
  },

  currencyChanged(newCurrency) {
    let exRate = 1;
    let srcCode;
    const dstCode = newCurrency;
    if (this.state.policyCoverageList) {
      for (
        let i = 0;
        i < this.state.policyCoverageList.length;
        i += 1
      ) {
        if (this.state.policyCoverageList[i].Selected == '1') {
          srcCode = this.state.policyCoverageList[i].ItemCurrencyCode;
          break;
        }
      }
      if (srcCode) {
        const RegistrationHandlingAction = ClaimRegistration.getClaimRegistrationHandlingAction();
        const registrationHandlingAction = new RegistrationHandlingAction();
        if (dstCode !== srcCode) {
          exRate = registrationHandlingAction.getExchangeRate({
            dstCode,
            srcCode,
          });
        }
      }
    }

    if (this.state.policyCoverageList) {
      const {
        policyCoverageList: statePolicyCoverageList,
      } = this.state;
      const policyCoverageList = statePolicyCoverageList.map(
        datas => {
          const data = datas;
          if (data.Selected == '1') {
            data.ItemCurrencyCode = newCurrency;
            data.InitLossIndemnity = new BigNumber(
              data.InitLossIndemnity
            ).times(exRate);
          }
          return data;
        }
      );
      this.setState({
        policyCoverageList,
        claimCurrency: newCurrency,
      });
      if (this.props.synCoverageData) {
        this.props.synCoverageData(policyCoverageList);
      }
    }
  },
  changeCoverageSelected() {
    if (this.state.policyCoverageList) {
      const { policyCoverageList } = this.state;
      policyCoverageList.forEach(datas => {
        const data = datas;
        data.Selected = '';
      });
      this.setState({ policyCoverageList });
      if (this.props.synCoverageData) {
        this.props.synCoverageData(policyCoverageList);
      }
    }
  },
  LimitDedutInfo(event) {
    const data = event.getParameter('data');
    this.refs.LimitDeductDialog_ref.showLimitDeductDialog(data);
  },
  render() {
    const { partOfId, io } = this.props;
    const { policyCoverageList } = this.state;
    if (policyCoverageList && policyCoverageList.length > 0) {
      const self = this;
      let unit = '';
      if (self.state.currencyCode) {
        unit = window.EU00.getCurrencySignByCurrencyCode(
          self.state.currencyCode
        );
      }
      return (
        <div>
          <UIDataTable
            id={`coverageList_${partOfId}`}
            indexable="false"
            pageable="false"
            disabled={io === 'out'}
            value={policyCoverageList}
            onRowSelect={this.onRowSelect}
            onSelectAll={this.onSelectAll}
          >
            <UIColumn
              selectionMode="multiple"
              value="Selected"
              enabled={io === 'in'}
            />

            <UIColumn
              headerTitle={i18n.ClaimCommon.CoverageName}
              width="35%"
              value="CoverageName"
            >
              <UIText io="out" />
            </UIColumn>

            <UIColumn
              headerTitle={i18n.ClaimCommon.SILimit}
              width="12%"
              textAlign="right"
              render={data => {
                const id = `CURRENCY_CODE_PolicySumInsured${data.PolCoverageId}${self.props.data.ObjectId}`;
                let aggregateLimitAmount = 0;
                let occurenceLimitAmount = 0;

                if (data.PolicyLimit) {
                  _.each(data.PolicyLimit, limitEntity => {
                    if (limitEntity.TotalAmount) {
                      if (
                        Number(limitEntity.TotalAmount) >
                        aggregateLimitAmount
                      ) {
                        aggregateLimitAmount =
                          limitEntity.TotalAmount;
                      }
                    }
                    if (limitEntity.OccurrenceAmount) {
                      if (occurenceLimitAmount === 0) {
                        occurenceLimitAmount =
                          limitEntity.OccurrenceAmount;
                      }
                      if (
                        Number(limitEntity.OccurrenceAmount) <
                        occurenceLimitAmount
                      ) {
                        occurenceLimitAmount =
                          limitEntity.OccurrenceAmount;
                      }
                    }
                  });
                }
                let showAmount = 0;

                if (occurenceLimitAmount > 0) {
                  showAmount = occurenceLimitAmount;
                } else {
                  showAmount = aggregateLimitAmount;
                }
                
                if(Number(showAmount) == 0){
                  showAmount='';
                  unit='';
                }
                return (
                  <div style={{ float: 'right' }}>
                    <UICurrency
                      id={id}
                      value={showAmount}
                      io="out"
                      unit={unit}
                    />
                  </div>
                );
              }}
            />

            <UIColumn
              headerTitle={i18n.ClaimCommon.Deductible}
              width="12%"
              textAlign="right"
              value="PolicyDeductibleAmount"
              render={data => {
                const id = `NUMBER_CODE_Deductible${data.PolCoverageId}${self.props.data.ObjectId}`;
                let aggregateLimitAmount = 0;
                let occurenceLimitAmount = 0;
                if (data.PolicyDeductible) {
                  _.each(data.PolicyDeductible, deductibleEntity => {
                    if (deductibleEntity.TotalAmount) {
                      if (
                        Number(deductibleEntity.TotalAmount) >
                        aggregateLimitAmount
                      ) {
                        aggregateLimitAmount =
                          deductibleEntity.TotalAmount;
                      }
                    }
                    if (deductibleEntity.OccurrenceAmount) {
                      if (occurenceLimitAmount === 0) {
                        occurenceLimitAmount =
                          deductibleEntity.OccurrenceAmount;
                      }
                      if (
                        Number(deductibleEntity.OccurrenceAmount) <
                        occurenceLimitAmount
                      ) {
                        occurenceLimitAmount =
                          deductibleEntity.OccurrenceAmount;
                      }
                    }
                  });
                }
                let showAmount = 0;

                if (occurenceLimitAmount > 0) {
                  showAmount = occurenceLimitAmount;
                } else {
                  showAmount = aggregateLimitAmount;
                }

                if(Number(showAmount) == 0){
                  showAmount='';
                  unit='';
                }

                return (
                  <div style={{ float: 'right' }}>
                    <UICurrency
                      id={id}
                      io="out"
                      value={showAmount}
                      unit={unit}
                    />
                  </div>
                );
              }}
            />

            <UIColumn
              headerTitle={i18n.ClaimCommon.InitialReserve}
              textAlign="right"
              width="12%"
              render={data => {
                let enabled = 'out';
                if (data.Selected == '1' && self.props.io === 'in') {
                  enabled = 'in';
                }

                return (
                  <div style={{ float: 'right' }}>
                    <UICurrency
                      io={enabled}
                      maxLength={
                        CommonConstant.AMOUNT_MAX_LENGTH_4_CLAIM
                      }
                      maxLengthMessage={
                        i18n.ClaimReserve.MaxAmountMessage
                      }
                      value={data.InitLossIndemnity}
                      onChange={self.changeInitLossIndemnity}
                      unit={window.EU00.getCurrencySignByCurrencyCode(
                        self.state.claimCurrency
                          ? self.state.claimCurrency
                          : ''
                      )}
                      validationGroup="SubClaimAmountCheck"
                    >
                      <UIParam name="data" value={data} />
                    </UICurrency>
                  </div>
                );
              }}
            />

            <UIColumn
              headerTitle={
                i18n.ClaimCalculation.LimitAndDeductionInfo
              }
              width="24%"
              textAlign="right"
              render={data => {
                return (
                  <div style={{ float: 'right' }}>
                    <UILink
                      id={`${data.CoverageCode}ladi`}
                      value={
                        i18n.ClaimCalculation.LimitAndDeductionInfo
                      }
                      onClick={CalculationLoadAction.showLimitDialog}
                    >
                      <UIParam
                        name="insuredId"
                        value={self.props.data.InsuredId}
                      />
                      <UIParam
                        name="coverageCode"
                        value={data.CoverageCode}
                      />
                      <UIParam name="id" value={partOfId} />
                      <UIParam
                        name="policyCurrencyCode"
                        value={self.state.currencyCode}
                      />
                    </UILink>
                  </div>
                );
              }}
            />
          </UIDataTable>
          <LimitAndDeductionInfoDialog
            id={partOfId}
            onClose={this.onCloseLimitAndDeductionDialog}
          />
          <LimitDeductDialog
            ref="LimitDeductDialog_ref"
            index="LimitDeductDialog"
            level="inusred"
            parentComponent={this}
          />
        </div>
      );
    }
    return null;
  },

  getParentComponent() {
    return this;
  },

  onCloseLimitAndDeductionDialog() {
    if (this.props.forModify === 'true') {
      UIDialog.show('ModifySubclaimInfo_Dialog');
    }
  },
});
export default Coverage;
