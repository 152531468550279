import {
  UIDataTable,
  UICheckbox,
  UIColumn,
  UILink,
  UISmartPanelGrid,
  UIParam,
  UISelect,
  UIText,
  UIRadio,
} from 'RainbowUI';
import ClaimCommonExt from 'ClaimCommonExt';
import PropTypes from 'prop-types';

const code = require('../mock/codeTable');

const PartyAccount = React.createClass({
  propTypes: {
    accountList: PropTypes.Array,
  },
  getDefaultProps() {
    return {
      accountList: [],
    };
  },
  getInitialState() {
    const partyVo = this.props.partyVo;
    let list;
    let accountId;
    if (partyVo) {
      list = partyVo.PartyAccountList || [];
      accountId = partyVo.PtyAccountId;
    } else {
      list = this.props.accountList;
      accountId = this.props.accountId;
    }
    for (const item in list) {
      const vo = list[item];
      if (list.length === 1) {
        vo.selected = 'Y';
        vo.IsPrimaryAccount = 'Y';
        vo.AccountStatus = '1';
      } else if (vo.AccountId == accountId) {
        vo.selected = 'Y';
      } else if (
        accountId == undefined &&
        vo.IsPrimaryAccount === 'Y'
      ) {
        vo.selected = 'Y';
      }
    }
    return {
      list,
    };
  },

  componentWillReceiveProps(nextProps) {
    const list = nextProps.accountList;
    for (const item in list) {
      const vo = list[item];
      if (vo.IsPrimaryAccount === 'Y') {
        vo.selected = 'Y';
      }
    }

    this.setState({ list });
  },
  getAccountList() {
    return this.state.list;
  },
  editAccount(event) {
    if (this.props.partyAuth === 'true') {
      this.refs.basicAccount_ref.editAccount(
        event.getParameter('data')
      );
    }
  },

  changePrimary(event) {
    this.setState(prevState => {
      const dataIndex = event.getParameter('dataIndex');
      const accountList = prevState.list;
      for (const item in accountList) {
        accountList[item].IsPrimaryAccount =
          item == dataIndex ? 'Y' : 'N';
      }
      return {
        list: accountList,
      };
    });
  },

  changeActive(event) {
    this.setState(prevState => {
      const dataIndex = event.getParameter('dataIndex');
      const accountList = prevState.list;
      const accountVo = accountList[dataIndex];
      if (accountVo.AccountStatus == '1') {
        accountVo.AccountStatus = '2';
        accountVo.selected = 'N';
      } else {
        accountVo.AccountStatus = '1';
      }
      accountList[dataIndex] = accountVo;
      return {
        list: accountList,
      };
    });
  },

  saveAccount(tmpaccountVo) {
    let accountVo = tmpaccountVo;
    this.setState(prevState => {
      const accountList = prevState.list;

      if (accountVo.isAdd) {
        accountVo.isAdd = false;
        accountVo.AccountStatus = '1';
        if (accountList.length === 0) {
          accountVo.IsPrimaryAccount = 'Y';
          accountVo.selected = 'Y';
        }
        accountList.push(accountVo);
      } else {
        const { dataIndex } = accountVo;
        accountList[dataIndex] = accountVo;
      }
      return {
        list: accountList,
      };
    });
  },

  getEnabled() {
    if(this.props.partyVo.PartyId){
        return true;
   }else{
        return false;
   }
  },

  selectAccount(event) {
    this.setState(prevState => {
      const dataIndex = event.getParameter('dataIndex');

      const accountList = prevState.list;
      for (const item in accountList) {
        accountList[item].selected = dataIndex == item ? 'Y' : 'N';
      }
      return {
        list: accountList,
      };
    });
  },

  addButton() {
    if (this.props.partyAuth === 'true') {
      this.refs.basicAccount_ref.addAccount();
    }
  },
  buildTableFunction() {
    return (
      <UILink
        icon="fa fa-plus"
        size="lg"
        iconClass="add_iconbtn"
        onClick={this.addButton}
        enabled={this.getEnabled}
      />
    );
  },
  render() {
    const { index: _index } = this.props;
    const { list } = this.state;
    const self = this;
    const enabledVal = () => {
      const hasAuth = self.props.partyAuth === 'true';
      let pm = false;
      if (self.props.index === 'PartyMaintance') {
        pm = true;
      }
      return hasAuth && pm;
    };
    return (
      <div>
        <UIDataTable
          id={`ClaimPartyAccountId${_index}`}
          value={list}
          functions={this.buildTableFunction()}
          indexable="false"
          pageable="false"
          headable="true"
          styleClass="default"
          headerTitle={i18n.ClaimParty.AccountInfo}
        >
          {this.getRadioColumn()}

          <UIColumn
            headerTitle={i18n.ClaimParty.AccountType}
            render={data => {
              return (
                <UISelect
                  id={`accountVoAccountTypeShowId${_index}`}
                  codeTable={code.accountType}
                  value={data.AccountType}
                  io="out"
                />
              );
            }}
          />

          <UIColumn
            headerTitle={i18n.ClaimParty.AccountDetail}
            render={data => {
              return (
                <div>
                  <UISelect
                    codeTable={code.bankCode}
                    value={data.BankId}
                    io="out"
                    style={{ padding: '10px' }}
                  />
                  <UIText
                    value={data.AccountHolderName}
                    io="out"
                    style={{ padding: '10px' }}
                  />
                  <UIText
                    value={data.AccountNo}
                    io="out"
                    style={{ padding: '10px' }}
                  />
                </div>
              );
            }}
          />
          <UIColumn
            render={data => {
              return (
                <UISmartPanelGrid>
                  <UIRadio
                    id={`accountRadio${_index}${data.dataIndex}`}
                    name="isPrimary"
                    codeTable={code.isPrimary}
                    value={data.IsPrimaryAccount}
                    onChange={self.changePrimary}
                    enabled={enabledVal}
                  >
                    <UIParam
                      name="dataIndex"
                      value={data.dataIndex}
                    />
                  </UIRadio>
                  <UICheckbox
                    id={`accountCheckbox${_index}${data.dataIndex}`}
                    codeTable={code.isActive}
                    value={`${data.AccountStatus}`}
                    onChange={self.changeActive}
                    enabled={enabledVal}
                  >
                    <UIParam
                      name="dataIndex"
                      value={data.dataIndex}
                    />
                  </UICheckbox>
                </UISmartPanelGrid>
              );
            }}
          />

          {this.getLinkColumn()}
        </UIDataTable>
        {this.getDetailPage()}
      </div>
    );
  },

  getRadioColumn() {
    let result = <div />;
    if (this.props.showRadio === 'true') {
      result = (
        <UIColumn
          render={data => {
            if (data.AccountStatus == '1') {
              return (
                <UIRadio
                  codeTable={code.test}
                  value={data.selected}
                  onChange={this.selectAccount}
                >
                  <UIParam name="dataIndex" value={data.dataIndex} />
                </UIRadio>
              );
            }
            return null;
          }}
        />
      );
    }
    return result;
  },
  getLinkColumn() {
    let result = <div />;
    if (this.props.partyAuth === 'true') {
      result = (
        <UIColumn
          headerTitle={i18n.ClaimCommon.Action}
          render={data => {
            return (
              <UILink
                tooltip={i18n.ClaimParty.EditLink}
                onClick={this.editAccount}
                icon="fa fa-pencil"
              >
                <UIParam name="data" value={data} />
                <UIParam name="index" value={this.props.index} />
              </UILink>
            );
          }}
        />
      );
    }
    return result;
  },
  getDetailPage() {
    const PartyAccountInfo = ClaimCommonExt.getPartyAccountInfo();
    let result = <div />;
    if (this.props.partyAuth === 'true') {
      result = (
        <PartyAccountInfo
          accountVo={this.state.accountVo}
          addAccount={this.state.addAccount}
          ref="basicAccount_ref"
          parentComponent={this}
          index={this.props.index}
        />
      );
    }
    return result;
  },
  getSuperComponent() {
    return this;
  },
});
export default PartyAccount;
