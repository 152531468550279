import {
  MessageHelper,
  UICheckbox,
  UIBox,
  UISelect,
  UIDialog,
  UILink,
  UIColumn,
  UIDataTable,
  UISmartPanelGrid,
  UIPanel,
  UIText,
  UIButton,
  UIDateTimePicker,
  UIParam,
  UpdateContext,
  UIUpdatePanel,
} from 'RainbowUI';
import PartyAction from '../../actions/PartyAction';
import DefinedCodeTable from '../../codetable/CodeTable';
import PartyConst from '../../const/PartyConst';
import UrlConfig from '../../../../UrlConfig';

const SearchForRelationShip = React.createClass({
  getInitialState() {
    return {
      partyRelationShip: {},
      definedCondition: { PartyType: PartyConst.PARTY_TYPE_IND },
      conditions: {},
      fuzzyConditions: {},
      orCondtions: {},
      isSearch: false,
    };
  },

  onClickClose() {
    this.setState({
      definedCondition: { PartyType: PartyConst.PARTY_TYPE_IND },
      conditions: {},
      fuzzyConditions: {},
      orCondtions: {},
      isSearch: false,
    });
    UIDialog.hide('SearchRelationShipDialog');
  },

  onSave(clickEvent) {
    const selectData = UIDataTable.getSelectedRecord('PartyRecord');
    if (!selectData.length) {
      MessageHelper.warning(
        i18n.PartyMsg.WarnMsg.Warn_0005,
        i18n.PartyMsg.WarnMsg.Warn_0003,
        MessageHelper.POSITION_TOP_RIGHT
      );
      return;
    }
    let party = selectData[0];
    if (!_.isEmpty(clickEvent.paramJson)) {
      party = clickEvent.paramJson[0].value;
    }
    const { partyRelationShip } = this.state;
    if (
      this.state.definedCondition.PartyType ==
      PartyConst.PARTY_TYPE_IND
    ) {
      partyRelationShip.RelationShipPartyId = party.PartyId;
      partyRelationShip.IdNumber = party.IdNumber;
    } else {
      partyRelationShip.RelationShipPartyId = party.PartyId;
      partyRelationShip.IdNumber = party.OrganizationIdNumber;
    }
    this.props.submitHandler(
      partyRelationShip,
      this.state.definedCondition.PartyType
    );
    this.setState({ isSearch: false });
    UIDialog.hide('SearchRelationShipDialog');
  },

  partyCategoryChange(evnet) {
    this.resetHandler();
    if (evnet.newValue) {
      const party = {};
      party.PartyType = evnet.newValue;
    }
  },

  resetHandler() {
    this.setState({
      conditions: {},
      fuzzyConditions: {},
      orCondtions: {},
      isSearch: false,
    });
    UpdateContext.forceUpdate('searchForRelationShip');
  },

  render() {
    const _self = this;
    const { definedCondition } = this.state;
    const partyType = definedCondition.PartyType;
    let partyConditionRender;

    if (partyType == PartyConst.PARTY_TYPE_IND) {
      partyConditionRender = this.indiviPartyConditionRender();
    } else {
      partyConditionRender = this.orgPartyConditionRender();
    }
    let renderSearchResult;

    if (partyType == PartyConst.PARTY_TYPE_IND) {
      renderSearchResult = this.renderIndividualSearchResult();
    } else {
      renderSearchResult = this.renderOrgSearchResult();
    }
    return (
      <UIDialog
        id="SearchRelationShipDialog"
        width="90%"
        title={i18n.PartySearch}
      >
        <UIUpdatePanel id="relationshipSearchRender">
          {partyConditionRender}
          {renderSearchResult}
        </UIUpdatePanel>
        <UIBox direction="center">
          <UIButton value={i18n.Save} onClick={_self.onSave} />
          <UIButton
            value={i18n.Cancel}
            onClick={_self.onClickClose}
          />
        </UIBox>
      </UIDialog>
    );
  },
  indiviPartyConditionRender() {
    const { conditions } = this.state;
    const { fuzzyConditions } = this.state;
    const _self = this;
    return (
      <UIUpdatePanel id="searchForRelationShip">
        <UIPanel panelTitle={i18n.PartySearchCriteria}>
          <UISmartPanelGrid column="4">
            <UISelect
              label={i18n.PartyCategory}
              codeTable={DefinedCodeTable.getCategroyCodeTable}
              onChange={_self.partyCategoryChange}
              required="true"
              model={_self.state.definedCondition}
              property="PartyType"
              showBlankOption="false"
              widthAllocation="7,5"
              colspan="2"
            />
            <UISelect
              label={i18n.PartyRole}
              colspan="2"
              codeTable={DefinedCodeTable.getIndPartyRole}
              model={fuzzyConditions}
              property="RoleType"
              widthAllocation="7,5"
            />
            <UIText
              label={i18n.PartyName}
              model={conditions}
              property="PartyName"
              widthAllocation="7,5"
            />
            <UICheckbox
              single="true"
              label={i18n.FuzzySearch}
              model={conditions}
              property="FuzzySearch"
              defaultValue="Y"
            />
            <UIText
              label={i18n.PartyFormerCode}
              model={conditions}
              property="PartyCode"
              widthAllocation="7,5"
              colspan="2"
            />
            <UIText
              label={i18n.IdNo}
              model={conditions}
              property="IdNumber"
              widthAllocation="7,5"
              colspan="2"
            />
            <UIDateTimePicker
              label={i18n.BirthDay}
              model={conditions}
              property="DateOfBirth"
              widthAllocation="7,5"
              manualInput="true"
              maxDate="TODAY"
              onClick={_self.setDate}
              colspan="2"
              format="MM/DD/YYYY"
            />
            <UIText
              label={i18n.MobileHomeWorkNo}
              model={conditions}
              property="WorkHomeMobileTel"
              widthAllocation="7,5"
              colspan="2"
            />
            <UIText
              label={i18n.SSSEmployerId}
              model={conditions}
              property="EmployerId"
              widthAllocation="7,5"
              colspan="2"
            />
            <UIText
              label={i18n.PostalCode}
              model={conditions}
              property="PostCode"
              widthAllocation="7,5"
              colspan="2"
            />
          </UISmartPanelGrid>
          <UIBox direction="left">
            <UIButton
              value={i18n.Reset}
              onClick={_self.resetHandler}
            />
            <UIButton
              value={i18n.Search}
              onClick={_self.searchHandler}
              update="relationshipSearchRender"
            />
          </UIBox>
        </UIPanel>
      </UIUpdatePanel>
    );
  },

  orgPartyConditionRender() {
    const _self = this;
    const { conditions } = this.state;
    const { fuzzyConditions } = this.state;

    return (
      <div>
        <UIPanel panelTitle={i18n.PartySearchCriteria}>
          <UISmartPanelGrid column="4">
            <UISelect
              label={i18n.PartyCategory}
              codeTable={DefinedCodeTable.getCategroyCodeTable}
              onChange={this.partyCategoryChange}
              required="true"
              model={_self.state.definedCondition}
              property="PartyType"
              showBlankOption="false"
              widthAllocation="7,5"
              colspan="2"
            />
            <UISelect
              label={i18n.PartyRole}
              colspan="2"
              codeTable={DefinedCodeTable.getOrgPartyRole}
              model={fuzzyConditions}
              property="RoleType"
              widthAllocation="7,5"
            />
            <UIText
              label={i18n.PartyName}
              model={conditions}
              property="PartyName"
              widthAllocation="7,5"
            />
            <UICheckbox
              single="true"
              label={i18n.FuzzySearch}
              model={conditions}
              property="FuzzySearch"
              defaultValue="Y"
            />
            <UIText
              label={i18n.PartyFormerCode}
              model={conditions}
              property="PartyCode"
              widthAllocation="7,5"
              colspan="2"
            />
            <UIText
              label={i18n.CompanyNo}
              model={conditions}
              property="OrganizationIdNumber"
              widthAllocation="7,5"
              colspan="2"
            />
            <UIText
              label={i18n.MobileWorkNo}
              model={conditions}
              property="MobileWorkTel"
              widthAllocation="7,5"
              colspan="2"
            />
            <UIText
              label={i18n.SSSEmployerId}
              model={conditions}
              property="EmployerId"
              widthAllocation="7,5"
              colspan="2"
            />
            <UIText
              label={i18n.PostalCode}
              model={conditions}
              property="PostCode"
              widthAllocation="7,5"
              colspan="2"
            />
          </UISmartPanelGrid>
          <UIBox direction="left">
            <UIButton
              value={i18n.Reset}
              onClick={this.resetHandler}
            />
            <UIButton
              value={i18n.Search}
              onClick={this.searchHandler}
              update="relationshipSearchRender"
            />
          </UIBox>
        </UIPanel>
      </div>
    );
  },

  renderIndividualSearchResult() {
    const _self = this;
    return (
      <UIPanel panelTitle={i18n.PartyResult}>
        <UIDataTable
          provider={_self.getIndPartyResult}
          selectionMode="single"
          indexable="false"
          id="PartyRecord"
        >
          <UIColumn headerTitle={i18n.PartyCode} value="PartyCode" />
          <UIColumn
            headerTitle={i18n.FormerPartyCode}
            value="PartyFormerCode"
          />
          <UIColumn
            headerTitle={i18n.PartyName}
            render={data => {
              return (
                <div>
                  <UILink
                    value={data.PartyName}
                    onClick={_self.queryHandler}
                  >
                    <UIParam name="data" value={data} />
                  </UILink>
                </div>
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.IdType}
            value="IdType"
            render={data => {
              return (
                <UISelect
                  io="out"
                  model={data}
                  property="IdType"
                  codeTable={DefinedCodeTable.IdType}
                />
              );
            }}
          />
          <UIColumn headerTitle={i18n.IdNo} value="IdNumber" />
          <UIColumn headerTitle={i18n.Gender} value="Gender">
            <UISelect
              codeTable={DefinedCodeTable.getGenderCodeTable()}
              io="out"
            />
          </UIColumn>
        </UIDataTable>
      </UIPanel>
    );
  },

  renderOrgSearchResult() {
    const _self = this;
    return (
      <UIPanel panelTitle={i18n.PartyResult}>
        <UIBox direction="right" />
        <UIDataTable
          provider={_self.getOrgPartyResult}
          selectionMode="single"
          indexable="false"
          id="PartyRecord"
        >
          <UIColumn headerTitle={i18n.PartyCode} value="PartyCode" />
          <UIColumn
            headerTitle={i18n.FormerPartyCode}
            value="PartyFormerCode"
          />
          <UIColumn
            headerTitle={i18n.PartyName}
            render={data => {
              return (
                <div>
                  <UILink
                    value={data.PartyName}
                    onClick={_self.queryHandler}
                  >
                    <UIParam name="data" value={data} />
                  </UILink>
                </div>
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.CompanyNo}
            value="OrganizationIdNumber"
          />
          <UIColumn
            headerTitle={i18n.CountryDom}
            render={data => {
              return (
                <UISelect
                  model={data}
                  property="Country"
                  codeTable={DefinedCodeTable.getCountry}
                  io="out"
                />
              );
            }}
          />
        </UIDataTable>
      </UIPanel>
    );
  },

  searchHandler() {
    const { conditions } = this.state;
    if (conditions.Status) {
      if (conditions.Status == '0') {
        conditions.Status = 0;
      }
      if (conditions.Status == '1') {
        conditions.Status = 1;
      }
    }

    this.setState({ isSearch: true });
  },

  getIndPartyResult(pageindex, pagesize) {
    const { isSearch } = this.state;
    if (!isSearch) {
      return { count: 0, result: [] };
    }
    const partyAction = new PartyAction();
    const partyName = this.state.conditions.PartyName;
    const fuzzySearch = this.state.conditions.FuzzySearch;
    const workHomeMobileTel = this.state.conditions.WorkHomeMobileTel;
    const { conditions } = this.state;
    const { fuzzyConditions } = this.state;
    const orConditions = this.state.orCondtions;
    if (conditions.FuzzySearch === 'Y') {
      fuzzyConditions.PartyName = conditions.PartyName;
      delete conditions.PartyName;
    }
    if (conditions.WorkHomeMobileTel) {
      orConditions.WorkHomeTel = conditions.WorkHomeMobileTel;
      orConditions.MobileTel = conditions.WorkHomeMobileTel;
      delete conditions.WorkHomeMobileTel;
    }
    delete conditions.FuzzySearch;
    const partyType = this.state.definedCondition.PartyType;
    const result = partyAction.searchPartyByConditions(
      conditions,
      orConditions,
      fuzzyConditions,
      partyType,
      pageindex,
      pagesize
    );
    conditions.PartyName = partyName;
    conditions.FuzzySearch = fuzzySearch;
    conditions.WorkHomeMobileTel = workHomeMobileTel;
    if (result) {
      return { count: result.count, result: result.result };
    }
    return { count: 0, result: [] };
  },

  getOrgPartyResult(pageindex, pagesize) {
    const { isSearch } = this.state;
    if (!isSearch) {
      return { count: 0, result: [] };
    }
    const partyAction = new PartyAction();
    const partyName = this.state.conditions.PartyName;
    const fuzzySearch = this.state.conditions.FuzzySearch;
    const mobileWorkTel = this.state.conditions.MobileWorkTel;
    const { conditions } = this.state;
    const { fuzzyConditions } = this.state;
    const orConditions = this.state.orCondtions;
    if (conditions.FuzzySearch === 'Y') {
      fuzzyConditions.PartyName = conditions.PartyName;
      delete conditions.PartyName;
    }
    if (conditions.MobileWorkTel) {
      orConditions.WorkTel = conditions.MobileWorkTel;
      orConditions.MobileTel = conditions.MobileWorkTel;
      delete conditions.MobileWorkTel;
    }
    delete conditions.FuzzySearch;
    const partyType = this.state.definedCondition.PartyType;
    const result = partyAction.searchPartyByConditions(
      conditions,
      orConditions,
      fuzzyConditions,
      partyType,
      pageindex,
      pagesize
    );
    conditions.PartyName = partyName;
    conditions.FuzzySearch = fuzzySearch;
    conditions.MobileWorkTel = mobileWorkTel;
    if (result) {
      return { count: result.count, result: result.result };
    }
    return { count: 0, result: [] };
  },

  queryHandler(clickEvent) {
    let party = {};
    if (!_.isEmpty(clickEvent.paramJson)) {
      party = clickEvent.paramJson[0].value;
    }
    const { PartyId } = party;
    if (PartyId === null || PartyId === undefined || PartyId === '') {
      MessageHelper.error(
        i18n.PartyIdIsNull,
        i18n.PartyMsg.ErrorMsg.Error_0002,
        MessageHelper.POSITION_TOP_RIGHT
      );
    } else {
      window.location.href = `${UrlConfig.partyCommonUrl}#/readOnlyPartyMaintain/${PartyId}`;
    }
  },

  showDialog(partyRelationShip) {
    UIDialog.show('SearchRelationShipDialog');
    this.setState(
      {
        partyRelationShip,
      },
      () =>
        console.log('partyRelationShip', this.state.partyRelationShip)
    );
  },

  showPartySearchDialog() {
    UIDialog.hide('SearchRelationShipDialog');
  },
});
export default SearchForRelationShip;
