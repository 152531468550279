import { MessageHelper, UITree, UIDialog } from 'RainbowUI';
import alt from '../alt';

const CalculationService = require('../service/CalculationService');
const NewCalculationVO = require('./vo/NewCalculationVO');

class CalculationLoadAction {
  loadCalculationTask(taskInstanceId) {
    CalculationService.loadCalculationTask(taskInstanceId).then(
      data => {
        this.dispatch(data);
      },
      data => {
        MessageHelper.error(data.Messages[0].Message, '');
      }
    );
  }

  loadCalculationReadOnly(calculateId, isApproveTask) {
    CalculationService.loadCalculationReadOnly(calculateId).then(
      data => {
        this.dispatch({ data, isApproveTask });
      },
      data => {
        MessageHelper.error(data.Messages[0].Message, '');
      }
    );
  }

  loadCalculationHistory(caseId) {
    CalculationService.loadCalculationHistory(caseId).then(
      data => {
        this.dispatch(data);
      },
      data => {
        MessageHelper.error(data.Messages[0].Message, '');
      }
    );
  }

  showReadOnly(event) {
    const calculateId = event.getParameter('calculateId');
    this.dispatch(calculateId);
    UIDialog.show('CalculateReadOnlyDialog');
  }

  loadApproveTask(taskInstanceId) {
    CalculationService.loadCalculationApproveTask(
      taskInstanceId
    ).then(
      data => {
        this.dispatch(data);
      },
      data => {
        MessageHelper.error(data.Messages[0].Message, '');
      }
    );
  }

  displayCoverageTree() {
    $('.coverageTree').show();
    $('.coverageValue').hide();
  }

  chooseCoverage(event) {
    const treeId = event.getParameter('treeId');
    const checkedList = UITree.getCheckedNode(treeId, true);
    $('.coverageValue').show();
    $('.coverageTree').hide();
    this.dispatch(checkedList);
  }

  clangeShowReserve(event) {
    const showReserve = event.getParameter('showReserve');
    const expenseToAdd = NewCalculationVO.calculationExpenseToAdd;
    expenseToAdd.initial(
      showReserve.InsuredId,
      showReserve.CoverageCode,
      0
    );
    this.dispatch(expenseToAdd);
  }

  showLimitDialog(event, tmpid = '') {
    let id = tmpid;
    const insuredId = event.getParameter('insuredId');
    const coverageCode = event.getParameter('coverageCode');
    const policyCurrencyCode = event.getParameter(
      'policyCurrencyCode'
    );
    if (event.getParameter('id')) {
      id = event.getParameter('id');
    }
    CalculationService.loadLimitDialogData(
      insuredId,
      coverageCode
    ).then(
      datas => {
        const data = datas;
        if (policyCurrencyCode) {
          data.PolicyCurrencyCode = policyCurrencyCode;
        }
        this.dispatch(data);
        UIDialog.show(`limitAndDeductionInfoDialog${id}`);
      },
      reason => MessageHelper.error(reason.Messages[0].Message, '')
    );
  }

  chooseItem(event) {
    const insuredId = event.getParameter('insuredId');
    const coverageCode = event.getParameter('coverageCode');
    const index = event.getParameter('index');
    const calculationItem = event.getParameter('calculationItem');
    const indemnityAmount = calculationItem.IndemnityAmount;
    const calculatedAmount = calculationItem.CalculatedAmount;
    const recognizedAmount = calculationItem.RecognizedAmount;
    const requestAmount = calculationItem.RequestAmount;
    const data = {
      InsuredId: insuredId,
      CoverageCode: coverageCode,
      Index: index,
      ItemId: event.newValue,
      IndemnityAmount: indemnityAmount,
      CalculatedAmount: calculatedAmount,
      RecognizedAmount: recognizedAmount,
      RequestAmount: requestAmount,
    };
    this.dispatch(data);
  }

  addItem(event) {
    const insuredId = event.getParameter('insuredId');
    const coverageCode = event.getParameter('coverageCode');
    const itemToAdd = NewCalculationVO.calculationItemToAdd;
    itemToAdd.initial(insuredId, coverageCode, 0);
    this.dispatch(itemToAdd);
  }

  deleteItem(event) {
    const insuredId = event.getParameter('insuredId');
    const coverageCode = event.getParameter('coverageCode');
    const index = event.getParameter('index');
    const indemnityAmount = event.getParameter('indemnityAmount');
    const itemsLength = event.getParameter('itemsLength');
    if (itemsLength <= 1) {
      MessageHelper.error('', i18n.ClaimCalculation.AtleastOneItem);
      return;
    }
    const data = {
      InsuredId: insuredId,
      CoverageCode: coverageCode,
      Index: index,
      IndemnityAmount: indemnityAmount,
      Length: itemsLength,
    };

    this.dispatch(data);
  }

  appraisalAmountChange(event) {
    const index = event.getParameter('index');
    const appraisalAmount = event.newValue;
    const data = {
      AppraisalAmount: appraisalAmount,
      Index: index,
    };
    this.dispatch(data);
  }

  recognizedAmountChange(event) {
    const calculationItem = event.getParameter('calculationItem');
    let requestAmount = calculationItem.RequestAmount;
    if (!requestAmount) {
      requestAmount = 0;
    }
    const index = event.getParameter('index');
    let recognizedAmount = event.newValue;
    if (Number(recognizedAmount) > Number(requestAmount)) {
      MessageHelper.error(i18n.ClaimCalculation.ErrorMsg.Error_0001);
      recognizedAmount = requestAmount;
    }
    const data = {
      RecognizedAmount: Number(recognizedAmount),
      Index: index,
    };
    this.dispatch(data);
  }

  requestAmountChange(event) {
    const index = event.getParameter('index');
    const requestAmount = event.newValue;
    const data = {
      RequestAmount: Number(requestAmount),
      RecognizedAmount: Number(requestAmount),
      Index: index,
    };
    this.dispatch(data);
  }

  lossUnitChange(event) {
    /* if (event.newValue==event.oldValue) {
			return;
		}; */
    const index = event.getParameter('index');
    const lossUnitValue = event.newValue;
    const data = {
      LossUnitValue: lossUnitValue,
      Index: index,
    };
    this.dispatch(data);
  }

  indemnityAmountChange(event) {
    /* if (event.newValue==event.oldValue) {
			return;
		}; */
    const index = event.getParameter('index');
    const calculatedAmount = event.getParameter('calculatedAmount');
    let indemnityAmount = event.newValue;
    if (
      indemnityAmount != null &&
      Number(calculatedAmount) < Number(indemnityAmount)
    ) {
      MessageHelper.error(
        '',
        i18n.ClaimCalculation.IndemnityAmountError
      );
      indemnityAmount = calculatedAmount;
    }
    const data = {
      IndemnityAmount: Number(indemnityAmount),
      Index: index,
    };

    this.dispatch(data);
  }

  invoiceNoChange(event) {
    const index = event.getParameter('index');
    const invoiceNo = event.newValue;
    const data = {
      InvoiceNo: invoiceNo,
      Index: index,
    };

    this.dispatch(data);
  }

  addExpense(event) {
    const insuredId = event.getParameter('insuredId');
    const coverageCode = event.getParameter('coverageCode');
    const expenseToAdd = NewCalculationVO.calculationExpenseToAdd;
    expenseToAdd.initial(insuredId, coverageCode, 0);
    this.dispatch(expenseToAdd);
  }

  showExpenseTree(event) {
    const reserveTypeParam = event.getParameter('reserveTypeParam');
    $(
      `#${reserveTypeParam.suffixId}${reserveTypeParam.dataIndex}reserveTypeSele`
    ).hide();
    $(
      `#${reserveTypeParam.suffixId}${reserveTypeParam.dataIndex}reserveTypeTree`
    ).show();
  }

  chooseExpenseReserve(event) {
    const reserveTypeParam = event.getParameter('reserveTypeParam');
    const insuredId = event.getParameter('insuredId');
    const coverageCode = event.getParameter('coverageCode');
    const expenseAmount = event.getParameter('expenseAmount');
    const oldReserveId = event.getParameter('reserveId');
    const index = event.getParameter('index');
    $(
      `#${reserveTypeParam.suffixId}${reserveTypeParam.dataIndex}reserveTypeSele`
    ).show();
    $(
      `#${reserveTypeParam.suffixId}${reserveTypeParam.dataIndex}reserveTypeTree`
    ).hide();
    const node = UITree.getSelectedNode(
      `${reserveTypeParam.suffixId +
        reserveTypeParam.dataIndex}cal_exp_tree`
    );
    const reserveId = node[0].id;
    if (oldReserveId == reserveId) {
      return;
    }
    const data = {
      ReserveId: reserveId,
      InsuredId: insuredId,
      CoverageCode: coverageCode,
      Index: index,
      ExpenseAmount: expenseAmount,
    };

    this.dispatch(data);
  }

  deleteExpenseReserve(event) {
    const insuredId = event.getParameter('insuredId');
    const coverageCode = event.getParameter('coverageCode');
    const reserveId = event.getParameter('reserveId');
    const expenseAmount = event.getParameter('expenseAmount');
    const index = event.getParameter('index');
    const data = {
      ReserveId: reserveId,
      InsuredId: insuredId,
      CoverageCode: coverageCode,
      Index: index,
      ExpenseAmount: expenseAmount,
    };
    this.dispatch(data);
  }

  expenseAmountChange(event) {
    /* if (event.newValue==event.oldValue) {
			return;
		}; */
    const insuredId = event.getParameter('insuredId');
    const coverageCode = event.getParameter('coverageCode');
    const reserveId = event.getParameter('reserveId');
    const settedReserveAmmout = event.getParameter(
      'settedReserveAmmout'
    );
    if (
      reserveId === null ||
      reserveId === undefined ||
      reserveId === ''
    ) {
      return;
    }
    const expenseAmount = event.newValue;
    if (expenseAmount < settedReserveAmmout) {
      MessageHelper.error(
        '',
        i18n.ClaimCalculation.ExpenseAmountError
      );
    }
    const data = {
      InsuredId: insuredId,
      CoverageCode: coverageCode,
      ReserveId: reserveId,
      ExpenseAmount: expenseAmount,
    };
    this.dispatch(data);
  }
}
export default alt.createActions(CalculationLoadAction);
