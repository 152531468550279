import alt from '../alt';
import ReserveAction from '../action/ReserveAction';
import ReserveHistoryAction from '../action/ReserveHistoryAction';
import OpenReserveAction from '../action/OpenReserveAction';

const ClaimCstUI = require('../../common/constant/ClaimCstUI');

class ReserveStore {
  constructor() {
    this.bindListeners({
      initReserve: ReserveAction.INIT_RESERVE,
      closeSubmit: ReserveAction.CLOSE_SUBMIT,
      submitCalculate: ReserveAction.SUBMIT_CALCULATE,
      reopenSubmit: ReserveAction.REOPEN_SUBMIT,
      showHistory: ReserveHistoryAction.SHOW_HISTORY,
      chooseHistory: ReserveHistoryAction.CHOOSE_HISTORY,
      showOpenReserve: OpenReserveAction.SHOW_OPEN_RESERVE,
      saveOpenReserve: OpenReserveAction.SAVE_OPEN_RESERVE,
      changeHistory: ReserveHistoryAction.CHANGE_HISTORY,
    });

    this.reserveListData = [];

    this.historyList = [];
    this.reserveType = null;
    this.showHistoryList = [];

    this.openReserveList = [];

    this.newReopen = {};

    this.newCalculate = {};
  }

  initReserve(reserveListData) {
    this.reserveListData = reserveListData;
  }

  showHistory(historyList) {
    this.historyList = historyList;
    this.showHistoryList = historyList;
    this.reserveType = { reserveType: '' };
  }

  changeHistory(historyList) {
    this.historyList = historyList;
    this.showHistoryList = historyList;
  }

  chooseHistory(reserveType) {
    const allData = this.historyList;
    let historyselect = [];
    for (let i = 0; i < allData.length; i += 1) {
      if (!reserveType || reserveType == ClaimCstUI.All) {
        historyselect = allData;
        break;
      }
      if (allData[i].ReserveType == reserveType) {
        historyselect[historyselect.length] = allData[i];
      }
    }
    this.reserveType = { reserveType };
    this.showHistoryList = historyselect;
  }

  showOpenReserve(openReserveList) {
    this.openReserveList = openReserveList;
  }

  saveOpenReserve(reserveListData) {
    this.reserveListData = reserveListData;
  }

  closeSubmit(reserveListData) {
    this.reserveListData = reserveListData;
  }

  reopenSubmit(reserveListData) {
    this.reserveListData = reserveListData;
  }

  onCalculate(newCalculate) {
    this.newCalculate = newCalculate;
  }

  submitCalculate(reserveVO) {
    console.log('storeSubmitCalculate');
    const { reserveListData } = this;
    if (reserveListData) {
      for (let j = 0; j < reserveListData.length; j += 1) {
        const reserveList = reserveListData[j].ReserveList;
        if (reserveList) {
          for (let i = 0; i < reserveList.length; i += 1) {
            const reserve_i = reserveList[i];
            if (reserve_i) {
              if (reserve_i.ReserveId == reserveVO.ReserveId) {
                reserve_i.OutstandingAmount =
                  reserveVO.OutstandingAmount;
                reserveList[i] = reserve_i;
              }
            }
          }
          reserveListData[j].ReserveList = reserveList;
        }
      }
    }
    this.reserveListData = reserveListData;
  }
}
export default alt.createStore(ReserveStore, ReserveStore);
