import {
  UIText,
  UISmartPanelGrid,
  UIButton,
  UIBox,
  MessageHelper,
} from 'RainbowUI';
import BindToMixin from 'react-binding';
import PropTypes from 'prop-types';
import AppraisalVehicleStore from '../../store/AppraisalVehicleStore';

const AppraisalBodyInfo = require('./AppraisalBodyInfo');
const AppraisalBodyLossItems = require('./AppraisalBodyLossItems');
const AppraisalBodyInjuryAction = require('../../action/AppraisalBodyInjuryAction');
const AppraisalPropertyAction = require('../../action/AppraisalPropertyAction');
const ApprovalDecision = require('./../vehicle/ApprovalDecision');
const ApprovalHistory = require('./../vehicle/ApprovalHistory');
const ApprovalBodyHistory = require('./AppraisalBodyHistory');

const AppraisalBodyInjury = React.createClass({
  mixins: [BindToMixin],

  propTypes: {
    objectId: PropTypes.string.isRequired,
  },

  componentWillMount() {
    AppraisalVehicleStore.listen(this.onStoreStateChange);
    AppraisalPropertyAction.loadClaimAppraisal(
      this.props.objectId,
      this.props.openForm
    );
  },

  componentDidMount() {
    $('#remarkInfo')
      .find('span')
      .css('margin-left', '16px');
    this.synBtnEnable();
  },

  getInitialState() {
    return {
      AppraisalData: {},
      AppraisalInfo: {},
      appraisalBodyInjury: {},
      ApprovalHistoryVO: {},
      AppraisalBodyHistory: {},
      CurrencyCode: null,
      SynBtnStatus: false,
    };
  },

  onStoreStateChange(storeState) {
    if (!_.isEmpty(storeState.AppraisalData)) {
      this.setState({ AppraisalData: storeState.AppraisalData });
      this.setState({
        AppraisalInfo: storeState.AppraisalData.AppraisalInfo,
      });
      this.setState({
        appraisalBodyInjury: storeState.AppraisalData.AppraisalInfo
          ? storeState.AppraisalData.AppraisalInfo.appraisalBodyInjury
          : {},
      });
      this.setState({
        ApprovalHistoryVO: storeState.AppraisalData.ApprovalHistoryVO
          ? storeState.AppraisalData.ApprovalHistoryVO
          : {},
      });
      this.setState({
        AppraisalBodyHistory: storeState.AppraisalData
          .AppraisalHistory
          ? storeState.AppraisalData.AppraisalHistory
          : {},
      });
      this.setState({
        CurrencyCode: storeState.AppraisalData.CurrencyCode,
      });
      this.setState({ SynBtnStatus: true });
    }
  },

  saveAppraisal() {
    if (this.state.AppraisalInfo.appraisalBodyInjury) {
      if (!this.state.AppraisalInfo.appraisalBodyInjury.VictimName) {
        this.state.AppraisalInfo.appraisalBodyInjury.VictimName = ' ';
      }
    }
    AppraisalPropertyAction.saveAppraisalProperty(
      this.state.AppraisalInfo
    );
  },
  updateAppraisalBodyInjury(domainObject) {
    if (domainObject) {
      const { AppraisalInfo } = this.state;
      AppraisalInfo.appraisalBodyInjury = domainObject;
      this.setState({ AppraisalInfo });
    }
  },
  synFIBody() {
    const _synModel = AppraisalBodyInjuryAction.getVictim(
      this.props.objectId
    );
    let p;
    const newSynModelState = this.state.AppraisalInfo
      .appraisalBodyInjury;

    for (p in _synModel) {
      newSynModelState[p] = _synModel[p];
    }
    if (_synModel.HomeAddress) {
      newSynModelState.Address = _synModel.HomeAddress;
    }

    this.forceUpdate();
  },

  synBtnEnable() {
    const damageType = 'I';
    const enabled = AppraisalPropertyAction.synButtonIsEnable(
      this.props.taskInfo,
      damageType
    );

    this.setState({
      SynBtnStatus: enabled,
    });
  },

  submitAppraisalBody() {
    const tempData = this.state.AppraisalData;

    tempData.TaskInstanceId = this.props.taskInfo.TaskInstanceId;
    tempData.ApprovalHistoryVO = null;
    tempData.AppraisalHistory = [];
    AppraisalPropertyAction.submitAppraisalProperty(tempData);
  },
  validateRemark1() {
    this.setState(prevState => {
      const appraisalProperty = prevState.appraisalBodyInjury;
      if (
        appraisalProperty.Remark1 &&
        appraisalProperty.Remark1.length > 3000
      ) {
        appraisalProperty.Remark1 = null;
        MessageHelper.warning(i18n.ClaimAppraisal.WarnMsg.Warn_0003);
      }
      return {
        appraisalBodyInjury: appraisalProperty,
      };
    });
  },
  validateRemark2() {
    this.setState(prevState => {
      const appraisalProperty = prevState.appraisalBodyInjury;
      if (
        appraisalProperty.Remark2 &&
        appraisalProperty.Remark2.length > 3000
      ) {
        appraisalProperty.Remark2 = null;
        MessageHelper.warning(i18n.ClaimAppraisal.WarnMsg.Warn_0003);
      }
      return {
        appraisalBodyInjury: appraisalProperty,
      };
    });
  },
  getOperationButton() {
    const { taskInfo } = this.props;
    const { SynBtnStatus } = this.state;
    const buttons = [];
    if (taskInfo) {
      buttons.push(
        <UIButton
          value={i18n.ClaimCommon.SyncInfo}
          buttonType="confirm"
          onClick={this.synFIBody}
          causeValidation="false"
          enabled={SynBtnStatus}
        />
      );
      buttons.push(
        <UIButton
          value={i18n.ClaimCommon.Save}
          buttonType="confirm"
          onClick={this.saveAppraisal}
        />
      );
      buttons.push(
        <UIButton
          value={i18n.ClaimCommon.Submit}
          buttonType="confirm"
          validationGroup="bodilyInjury"
          causeValidation="true"
          onClick={this.submitAppraisalBody}
        />
      );
    }
    return <UIBox direction="right">{buttons}</UIBox>;
  },

  render() {
    const ClaimAppraisalBodyInjury = this.bindToState(
      'AppraisalInfo',
      'appraisalBodyInjury'
    );
    let showApprove = false;
    const { taskInfo, policyInfo, subClaim } = this.props;
    const {
      appraisalBodyInjury,
      AppraisalData,
      ApprovalHistoryVO,
      AppraisalBodyHistory,
    } = this.state;
    if (taskInfo) {
      showApprove =
        taskInfo.TaskCode == 'ClaimAppraisalBodilyInjuryTask';
    }

    const caseId = policyInfo.CaseId;

    let isFirstParty;
    if (subClaim) {
      if (subClaim.damageParty === 'FP') {
        isFirstParty = true;
      } else {
        isFirstParty = false;
      }
    }
    let party;
    let partyName;
    if (policyInfo) {
      if (policyInfo.PartyList.length > 0) {
        for (let i = 0; i < policyInfo.PartyList.length; i += 1) {
          if (policyInfo.PartyList[i].IsInsured === 'Y') {
            party = policyInfo.PartyList[i];
            break;
          }
        }
      }
    }
    if (party) {
      partyName = party.PartyName;
    }
    if (
      taskInfo &&
      taskInfo.TaskCode === 'ClaimAppraisalBodilyInjuryTask'
    ) {
      return (
        <div>
          <AppraisalBodyInfo
            appraisalBodyInjuryInfo={appraisalBodyInjury}
            caseId={caseId}
            taskCode={taskInfo.TaskCode}
            isFirstParty={isFirstParty}
            partyName={partyName}
          />
          <AppraisalBodyLossItems
            ref="lossItems_ref"
            ClaimAppraisalBodyInjury={ClaimAppraisalBodyInjury}
            currencyCode={AppraisalData.CurrencyCode}
            taskCode={taskInfo.TaskCode}
          />
          {this.remarkInfo(true)}
          <div style={{ height: '50px' }}>
            {this.getOperationButton()}
          </div>
          {}
        </div>
      );
    }
    if (
      taskInfo &&
      taskInfo.TaskCode === 'ClaimAppraisalIApprovalTask'
    ) {
      return (
        <div>
          <AppraisalBodyInfo
            appraisalBodyInjuryInfo={appraisalBodyInjury}
            caseId={caseId}
            taskCode={taskInfo.TaskCode}
            isFirstParty={isFirstParty}
            partyName={partyName}
          />
          <AppraisalBodyLossItems
            ref="lossItems_ref"
            ClaimAppraisalBodyInjury={ClaimAppraisalBodyInjury}
            currencyCode={AppraisalData.CurrencyCode}
            taskCode={taskInfo.TaskCode}
          />
          {this.remarkInfo(false)}
          <br />
          <ApprovalDecision
            decision={this.bindToState(
              'AppraisalData',
              'ApprovalDecision'
            )}
            remark={this.bindToState('AppraisalData', 'ApproveMark')}
            appraisalData={AppraisalData}
            taskInstanceId={taskInfo.TaskInstanceId}
          />
          <ApprovalHistory
            parentComponent={this}
            appraisalApproveHistory={ApprovalHistoryVO}
            taskCode={taskInfo.TaskCode}
          />
          <ApprovalBodyHistory
            parentComponent={this}
            currencyCode={AppraisalData.CurrencyCode}
            appraisalHistory={AppraisalBodyHistory}
          />
        </div>
      );
    }
    return (
      <div>
        <AppraisalBodyInfo
          appraisalBodyInjuryInfo={appraisalBodyInjury}
          caseId={caseId}
          taskCode={taskInfo.TaskCode}
          isFirstParty={isFirstParty}
          partyName={partyName}
        />
        <AppraisalBodyLossItems
          ref="lossItems_ref"
          ClaimAppraisalBodyInjury={ClaimAppraisalBodyInjury}
          currencyCode={AppraisalData.CurrencyCode}
          taskCode={taskInfo.TaskCode}
        />
        {this.remarkInfo(false)}
        <br />
        {/* <ApprovalHistory parentComponent={this} appraisalApproveHistory={this.state.ApprovalHistoryVO} taskCode={this.props.taskInfo.TaskCode}/>
                     <ApprovalBodyHistory parentComponent={this} currencyCode={this.state.AppraisalData.CurrencyCode} appraisalHistory={this.state.AppraisalBodyHistory}/> */}
      </div>
    );
  },
  remarkInfo(enabled) {
    return (
      <div id="remarkInfo">
        <UISmartPanelGrid column="3">
          <UIText
            label={i18n.ClaimAppraisal.Remark1}
            valueLink={this.bindToState('AppraisalInfo', 'Remark1')}
            enabled={enabled}
            onChange={this.validateRemark1.bind(this)}
            colspan="3"
          />
          <UIText
            label={i18n.ClaimAppraisal.Remark2}
            valueLink={this.bindToState('AppraisalInfo', 'Remark2')}
            nabled={enabled}
            onChange={this.validateRemark2.bind(this)}
            colspan="3"
          />
        </UISmartPanelGrid>
      </div>
    );
  },
});
export default AppraisalBodyInjury;
