import React from 'react'
import {
  UIPanel,
  UIColumn,
  UIDataTable,
  UISelect,
  UILink,
  UIParam,
} from 'RainbowUI';
import { CodeTableAction, CodeTableCstUI } from 'ClaimCommonConstant';
import ClaimNoticeExt from 'ClaimNoticeExt';
import NoticeStore from '../store/NoticeStore';

const DamageItemDetail = ClaimNoticeExt.getDamageItemDetail();
const NoticeOfLossAction = require('../action/NoticeOfLossAction');

const DamageInfo = React.createClass({
  getInitialState() {
    return {
      damageList: this.props.data.DamageList
        ? this.props.data.DamageList
        : [],
    };
  },

  componentWillMount() {
    NoticeStore.listen(this.onStoreStateChange);
  },

  componentWillUnmount() {
    NoticeStore.unlisten(this.onStoreStateChange);
  },

  onStoreStateChange(storeState) {
    if (!_.isEmpty(storeState.noticeEntity)) {
      this.setState({
        damageList: storeState.noticeEntity.DamageList,
      });
    }
  },

  policyNoChange(policyEntity) {
    let damageList = this.state.damageList
      ? this.state.damageList
      : [];
    if (damageList) {
      damageList = NoticeOfLossAction.copyDamageContactFromPartyListWhenNoChange(
        damageList,
        policyEntity
      );
      this.setState({ damageList });
    }
  },

  copyPolicyChangeCodeTableClaimant(policyEntity) {
    const damageList = this.state.damageList
      ? this.state.damageList
      : [];
    this.setState({
      copyPolicyFlag: true,
      codeTableClaimant: NoticeOfLossAction.getCodeTableClaimant(
        damageList,
        policyEntity
      ),
    });
  },
  openAddItemDetail() {
    this.setState({ copyPolicyFlag: false });
  },

  addDamageItem(damage, damageContact) {
    const damageList = this.state.damageList
      ? this.state.damageList
      : [];
    if (damage.isAdd) {
      damageList.push(
        NoticeOfLossAction.copyDamageContact(
          damage,
          damageContact,
          damage.ContactRefId == damageList.length
        )
      );
    } else {
      const oldDamage = damageList[damage.dataIndex];
      if (
        oldDamage.ContactRefId !== damageContact.ContactRefId &&
        oldDamage.dataIndex == oldDamage.ContactRefId
      ) {
        NoticeOfLossAction.transferDamageContact(
          damageList,
          oldDamage,
          oldDamage.dataIndex
        );
      }
      damageList[
        damage.dataIndex
      ] = NoticeOfLossAction.copyDamageContact(
        damage,
        damageContact.dataIndex !== damage.dataIndex
          ? {}
          : damageContact,
        true
      );
    }

    if (damageContact.ContactRefId) {
      damageList[damageContact.ContactRefId] = damageContact;
    }
    this.setState({
      damageList: NoticeOfLossAction.showDamageListContactName(
        damageList
      ),
    });
  },

  editDamage(event) {
    const damage = $.extend({}, event.getParameter('data'), true);
    const damageContact =
      damage.dataIndex == damage.ContactRefId
        ? damage
        : this.state.damageList[damage.ContactRefId];
    damage.isAdd = false;
    this.refs.DamageItemDetail.showDetail(damage, damageContact);
  },

  deleteDamage(event) {
    const deletetDataIndex = event.getParameter('dataIndex');
    const { damageList } = this.state;
    const damage = damageList[deletetDataIndex];

    if (damage.dataIndex == damage.ContactRefId) {
      NoticeOfLossAction.transferDamageContact(
        damageList,
        damage,
        deletetDataIndex,
        true
      );
    }

    damageList.splice(deletetDataIndex, 1);
    this.setState({ damageList });
    this.refs.DamageItemDetail.onCancel();
  },
  buildTableFunction() {
    return (
      <UILink
        icon="fa fa-plus"
        size="lg"
        iconClass="add_iconbtn"
        onClick={this.addDamage}
      />
    );
  },
  addDamage() {
    this.refs.DamageItemDetail.addDamage();
  },
  render() {
    const sel_this = this;
    const { codeTableClaimant, damageList } = this.state;
    return (
      <UIPanel
        id="damageItem_UIPanel"
        panelTitle={i18n.ClaimNotice.DamageInfo}
      >
        <UIDataTable
          id="damageList_UIDataTable"
          functions={this.buildTableFunction()}
          value={NoticeOfLossAction.showDamageListContactName(
            damageList
          )}
          indexable="false"
          infoable="true"
          isHeading="true"
          pageable="false"
        >
          <UIColumn
            headerTitle={i18n.ClaimCommon.DamageType}
            value="DamageType"
          >
            <UISelect
              io="out"
              codeTable={CodeTableAction.loadDataList(
                CodeTableCstUI.ClaimDamageTypeCode,
                null
              )}
            />
          </UIColumn>
          <UIColumn
            headerTitle={i18n.ClaimCommon.DamageItem}
            value="DamageItem"
          />
          <UIColumn
            headerTitle={i18n.ClaimCommon.Claimant}
            value="ContactName"
          />
          <UIColumn
            headerTitle={i18n.ClaimCommon.Action}
            width="15%"
            render={data => {
              return (
                <div>
                  <UILink
                    value={i18n.ClaimCommon.Edit}
                    icon="fa fa-pencil"
                    onClick={sel_this.editDamage}
                  >
                    <UIParam name="data" value={data} />
                  </UILink>
                  <UILink
                    value={i18n.ClaimCommon.Delete}
                    icon="fa fa-trash"
                    onClick={sel_this.deleteDamage}
                    style={{ marginLeft: '10px' }}
                  >
                    <UIParam
                      name="dataIndex"
                      value={data.dataIndex}
                    />
                  </UILink>
                </div>
              );
            }}
          />
        </UIDataTable>
        <DamageItemDetail
          ref="DamageItemDetail"
          ClaimantCodeTable={codeTableClaimant}
          parentComponent={this}
        />
      </UIPanel>
    );
  },
});
export default DamageInfo;
