import { MessageHelper } from 'RainbowUI';
import {
  ApprovalInfo,
  ApprovalHistory,
  ClaimTaskTransferDialog,
} from 'ClaimCommon';
import { ClaimCstUI } from 'ClaimCommonConstant';
import BindToMixin from 'react-binding';
import PropTypes from 'prop-types';
import ClaimSettlement from 'ClaimSettlement';
import ClaimCommonExt from 'ClaimCommonExt';
import SettlementApproveStore from '../../common/store/SettlementApproveCancelStore';

const RefundReadOnly = require('./RefundReadOnly');
const SettlementApproveAction = require('../../common/action/SettlementApproveAction');

const ClaimBaseInfo = ClaimCommonExt.getClaimBaseInfo();

const SettlementApprove = React.createClass({
  mixins: [BindToMixin],

  propTypes: {
    taskInstanceId: PropTypes.string.isRequired,
    caseId: PropTypes.string.isRequired,
  },

  getInitialState() {
    return {
      approveInfo: {
        BusinessId: null,
        TaskInstanceId: null,
        CaseId: null,
        Remark: '',
        DecisionCode: null,
      },
    };
  },
  onStoreStateChange(storeState) {
    if (!_.isEmpty(storeState.approveInfo)) {
      this.setState({ approveInfo: storeState.approveInfo });
    }
  },
  componentWillMount() {
    SettlementApproveStore.listen(this.onStoreStateChange);
    SettlementApproveAction.init(this.props.taskInstanceId);
  },
  componentWillUnmount() {
    SettlementApproveStore.unlisten(this.onStoreStateChange);
  },
  render() {
    const SettlementHistory = ClaimSettlement.getSettlementHistory();
    const _self = this;
    const componentId = _self.props.componentId || 'rec_app';
    const settlementId = _self.state.approveInfo.BusinessId;
    const { claimEntity, caseId } = this.props;
    if (
      settlementId === null ||
      settlementId === undefined ||
      settlementId === ''
    ) {
      return (
        <div>
          <ClaimBaseInfo claimEntity={claimEntity} />
          <SettlementHistory
            caseId={caseId}
            componentId={componentId}
            taskCode={ClaimCstUI.CLAIM_TASK_CODE.RefundApproval}
            canInit={false}
          />
        </div>
      );
    }
    return (
      <div>
        <ClaimBaseInfo claimEntity={claimEntity} />
        <RefundReadOnly
          settlementId={settlementId}
          readOnly
          isApprove
        />
        <ApprovalInfo
          decision={this.bindToState('approveInfo', 'DecisionCode')}
          remark={this.bindToState('approveInfo', 'Remark')}
          onClick={_self.confirm}
        />
        <ApprovalHistory
          processObjectId={settlementId}
          componentId={componentId}
        />
        <SettlementHistory
          caseId={caseId}
          componentId={componentId}
          taskCode={ClaimCstUI.CLAIM_TASK_CODE.RefundApproval}
          canInit
        />
        <ClaimTaskTransferDialog
          ref="ClaimTaskTransferDialog"
          componentId={componentId}
        />
      </div>
    );
  },
  confirm() {
    const flag = SettlementApproveAction.submit(
      this.state.approveInfo
    );
    if (flag) {
      MessageHelper.success(
        '',
        i18n.ClaimSettlement.RefundApprovalSubmitSuccess
      );
      this.refs.ClaimTaskTransferDialog.showDialog({
        CaseId: this.props.caseId,
      });
    }
  },
});
export default SettlementApprove;
