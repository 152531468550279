import { AjaxUtil, MessageHelper } from 'RainbowUI';

const Q = require('q');
const Url = require('../constant/AppraisalUrl');

export default {
  postPromise(url, payLoad, successMessage) {
    const deffered = Q.defer();
    const entity = payLoad || {};
    AjaxUtil.doPost(url, entity, {
      async: false,
      done(data) {
        deffered.resolve(data);
        if (data.Status === 'OK') {
          MessageHelper.success(successMessage);
        }
      },
      fail(err) {
        deffered.reject(err);
      },
    });
    return deffered.promise;
  },

  saveAppraisalBodyInfo(appraisalBodyInfo) {
    const url = Url.saveAppraisalBodyInfo;
    return this.postPromise(
      url,
      appraisalBodyInfo,
      'Appraisal  is saved successfully'
    );
  },

  loadClaimAppraisal(subClaimId) {
    let returnVal = null;
    const url = `${Url.loadClaimAppraisal}?objectId=${subClaimId}`;
    AjaxUtil.doGet(
      url,
      {},
      {
        async: false,
        done(data) {
          if (data.Status === 'OK') {
            returnVal = data.Model;
          }
        },
        fail() {
          MessageHelper.error(
            '',
            i18n.ClaimAppraisal.ErrorMsg.Error_0001
          );
        },
      }
    );
    return returnVal;
  },

  approveAppraisalBody(claimAppraisal) {
    let message = '';
    if (claimAppraisal.ApprovalDecision === 'Y') {
      message = 'Appraisal is approved successfully';
    } else {
      message = 'Appraisal is rejected successfully';
    }
    const url = Url.approveAppraisalProperty;
    return this.postPromise(url, claimAppraisal, message);
  },
  submitAppraisalBody(claimAppraisal) {
    const url = Url.submitAppraisalProperty;
    return this.postPromise(
      url,
      claimAppraisal,
      'Appraisal  is submitted successfully'
    );
  },
  getVictim(subClaimId) {
    let returnVal = null;
    const url = Url.syncFIData + subClaimId;
    AjaxUtil.doGet(
      url,
      {},
      {
        async: false,
        done(data) {
          if (data.Status === 'OK') {
            returnVal = data.Model;
            MessageHelper.success(
              i18n.ClaimAppraisal.SuccessMsg.Success_0002
            );
          }
        },
        fail() {
          MessageHelper.error(
            '',
            i18n.ClaimAppraisal.ErrorMsg.Error_0001
          );
        },
      }
    );
    return returnVal;
  },
};
