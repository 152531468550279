import ReactDOM from 'react-dom';
import {
  MessageHelper,
  UIParam,
  UIBox,
  CodeTable,
  UIDataTable,
  UIDateTimePicker,
  UIButton,
  UIColumn,
  UILink,
  UISelect,
} from 'RainbowUI';
import BindToMixin from 'react-binding';
import ClaimWorkFlowSharePoolAction from '../../action/desktop/ClaimWorkFlowSharePoolAction';
import ClaimWorkFlowLeftAction from '../../action/desktop/ClaimWorkFlowLeftAction';
import SharePoolConditionStore from '../../store/SharePoolConditionStore';

const CodeTableAction = require('../../action/CodeTableAction');
const CodeTableCstUI = require('../../constant/CodeTableCstUI');

let content = [];
let user = 0;

const ClaimWorkFlowSharePoolList = React.createClass({
  mixins: [BindToMixin],
  componentWillUnmount() {
    SharePoolConditionStore.unlisten(this.onStoreStateChange);
  },

  onStoreStateChange(storeState) {
    if (!_.isEmpty(storeState.condition)) {
      this.setState({ condition: storeState.condition });
    }
  },

  getInitialState() {
    return { condition: {}, isInital: false, list: [] };
  },
  getSharePoolList(pageindex, pagesize) {
    const { condition } = this.state;
    delete condition.isUpdate;
    delete condition.flag;
    const result = ClaimWorkFlowSharePoolAction.getSharePoolList(
      pageindex,
      pagesize,
      condition
    );
    content = result.result;
    user = result.user;

    setTimeout(this.componentDidUpdate.bind(this), 0);
    return result;
  },
  componentDidMount() {
    SharePoolConditionStore.listen(this.onStoreStateChange);
  },
  mockUser() {
    const codeTable = [
      { id: '66', text: 'andy' },
      { id: '-11', text: 'System' },
      { id: 'pool', text: 'Pool' },
    ];
    return new CodeTable(codeTable);
  },
  updateSharePool() {
    const selecteds = UIDataTable.getSelectedRecord(
      'workflowsharepoollist'
    );
    if (!selecteds[0]) {
      MessageHelper.info(i18n.ClaimCommon.selectATaskFirst);
      return;
    }
    const ok = ClaimWorkFlowSharePoolAction.updateSelectedTask();
    if (ok === 'OK') {
      ClaimWorkFlowLeftAction.pageChange({ myPage: 'MyTask' });
      window.location.reload();
    }
  },
  componentDidUpdate() {
    let allDisabled = true;
    $("input[name='workflowsharepoollist_multiple']").each((index, datas) => {
      const data = datas;
      if (
        content[index].isHaveAuth == false ||
        (content[index].InsertBy &&
          content[index].InsertBy == user &&
          content[index].TaskDefinitionKey.indexOf('Approval') >
          -1) ||
        (content[index].PreInstanceId.indexOf(`,${user},`) > -1 &&
          content[index].TaskDefinitionKey.indexOf('Approval') > -1)
      ) {
        data.disabled = true;
      } else {
        data.disabled = false;
        allDisabled = false;
      }
    });

    $("input[name='workflowsharepoollist_multiple_selectall']").each((index, datas) => {
      const data = datas;
      data.disabled = allDisabled;
    });
  },
  selectAll() {
    $("input[name='workflowsharepoollist_multiple']").each((index, datas) => {
      const data = datas;
      if (data.disabled) {
        data.checked = false;
      }
    });
  },

  rowSelect() {
    $("input[name='workflowsharepoollist_multiple']").each((index, data) => {
      if (data.checked) {
        return;
      }
      if (data.disabled) {
        /* empty */
      }
    });
  },
  onClickClear() {
    this.setState({ condition: {} });
    UpdateContext.forceUpdate('workflowsharepoollist');
  },
  render() {
    const _self = this;
    return (
      <div>
        <UIDataTable
          id="workflowsharepoollist"
          indexable="false"
          var="poolSelected"
          provider={this.getSharePoolList}
          headerTitle={i18n.ClaimCommon.ShareingPool}
          onSelectAll={_self.selectAll}
          onRowSelect={_self.rowSelect}
        >
          <UIColumn selectionMode="multiple" />
          <UIColumn
            headerTitle={i18n.ClaimCommon.TaskType}
            value="TaskDefinitionKey"
            sortable="true"
          >
            <UISelect
              codeTable={CodeTableAction.loadDataList(
                CodeTableCstUI.CLAIMTASK,
                null
              )}
              io="out"
            />
          </UIColumn>

          <UIColumn
            headerTitle={i18n.ClaimCommon.ClaimNumber}
            value="ClaimNo"
            sortable="true"
            render={data => {
              return (
                <UILink
                  value={data.ClaimNo}
                  onClick={
                    ClaimWorkFlowSharePoolAction.getSharePoolDetail
                  }
                >
                  <UIParam name="data" value={data} />
                </UILink>
              );
            }}
          />

          <UIColumn
            headerTitle={i18n.ClaimCommon.TaskDetail}
            value="TaskDescription"
            sortable="true"
          />

          <UIColumn
            headerTitle={i18n.ClaimCommon.AssignedBy}
            value="AssignBy"
            sortable="true"
          >
            <UISelect codeTable={this.mockUser} io="out" />
          </UIColumn>

          <UIColumn
            headerTitle={i18n.ClaimCommon.DueDate}
            value="TaskDueTime"
            sortable="true"
          >
            <UIDateTimePicker
              format="MM/DD/YYYY"
              io="out"
              sortable="true"
            />
          </UIColumn>
          <UIColumn
            headerTitle={i18n.ClaimCommon.Priority}
            value="TaskPrioriry"
            sortable="true"
          >
            <UISelect
              value="TaskPrioriry"
              codeTable={CodeTableAction.loadDataList(
                CodeTableCstUI.Prioriry,
                null
              )}
              io="out"
            />
          </UIColumn>
        </UIDataTable>
        <UIBox>
          <UIButton
            value={i18n.ClaimCommon.ClaimTask}
            onClick={this.updateSharePool}
          />
        </UIBox>
      </div>
    );
  },
});
export default ClaimWorkFlowSharePoolList;
