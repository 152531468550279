import { AjaxUtil } from 'RainbowUI';
import Url from '../url/Url';

export default {
  initHisClaimTask(caseId) {
    const defer = $.Deferred();
    AjaxUtil.doGet(
      `${Url.ClaimWorkflowQueryHisClaimTasks}/${caseId}`,
      {},
      {
        async: false,
        done(data, textStatus, jqXHR) {
          console.log(`done:${JSON.stringify(data)}`);
          if (data) {
            defer.resolve(data);
          } else {
            defer.reject({
              status: jqXHR.status,
              responseText: jqXHR.responseText,
            });
          }
        },
        fail(jqXHR) {
          // jqXHR.status, jqXHR.responseText
          defer.reject({
            status: jqXHR.status,
            responseText: jqXHR.responseText,
          });
        },
      }
    );
    return defer.promise();
  },
  /* initApprovalHistoryData:(processObjectId) => {
        console.log("Load ClaimApproveEntity for subclaim:"+processObjectId);
		var defer = $.Deferred();
        AjaxUtil.doGet(Url.ApprovalHistory+processObjectId,{}, {
			async : false,
			done : (data, textStatus, jqXHR) => {
				console.log("done:" + data);
            	if(data!=null){
            		defer.resolve(data);
            	}else{
            		defer.reject({status:jqXHR.status,responseText:jqXHR.responseText});
            	}
			},
			fail : (jqXHR, textStatus, errorThrown) => {
				//jqXHR.status, jqXHR.responseText
        		defer.reject({status:jqXHR.status,responseText:jqXHR.responseText});
			}
        });
		return defer.promise();
	}, */
  /* initData:(caseId,pageIndex,pageSize) => {
		
		var defer = $.Deferred();
		AjaxUtil.doGet(Url.ClaimWorkflowQueryClaimTasks+caseId, {},{
			async : false,
			done : (data, textStatus, jqXHR) => {
            	if(data!=null){
            		defer.resolve(data);
            	}else{
            		defer.reject({status:jqXHR.status,responseText:jqXHR.responseText});
            	}
			},
			fail : (jqXHR, textStatus, errorThrown) => {
				//jqXHR.status, jqXHR.responseText
        		defer.reject({status:jqXHR.status,responseText:jqXHR.responseText});
			}
        });
        return defer.promise()
	}, */

  loadAssignmentInfo(caseId, needAssignee) {
    const deferred = $.Deferred();
    AjaxUtil.doGet(
      `${Url.ClaimWorkflowQueryClaimTasks}/${caseId}/${needAssignee}`,
      {},
      {
        async: false,
        done(data, textStatus, jqXHR) {
          if (data) {
            deferred.resolve(data);
          } else {
            deferred.reject({
              status: jqXHR.status,
              responseText: jqXHR.responseText,
            });
          }
        },
        fail(jqXHR) {
          deferred.reject({
            status: jqXHR.status,
            responseText: jqXHR.responseText,
          });
        },
      }
    );
    return deferred.promise();
  },

  closeClaimTask(taskInstance) {
    const task = {};
    task.IntanceId = taskInstance.id;
    task.TaskCode = taskInstance.TaskDefinitionKey;
    task.CaseId = taskInstance.CaseId;
    task.AssignedBy = taskInstance.AssignBy;
    task.AttachTo = taskInstance.AttachTo;
    task.AssignedDate = taskInstance.TaskCreateTime.substr(0, 10);
    task.Priority = taskInstance.TaskPrioriry;
    console.log('=======taskInstance========', taskInstance);
    const defer = $.Deferred();
    AjaxUtil.doPost(Url.ClaimWorkflowCloseTask, task, {
      async: false,
      done(data, textStatus, jqXHR) {
        console.log(`done:${data}`);
        if (data) {
          defer.resolve(data);
        } else {
          defer.reject({
            status: jqXHR.status,
            responseText: jqXHR.responseText,
          });
        }
      },
      fail(jqXHR) {
        // jqXHR.status, jqXHR.responseText
        defer.reject({
          status: jqXHR.status,
          responseText: jqXHR.responseText,
        });
      },
    });
    return defer.promise();
  },

  saveClaimTask(taskInstance) {
    const defer = $.Deferred();
    AjaxUtil.doPost(
      Url.ClaimWorkflowAssignOrReAssignTask,
      taskInstance,
      {
        async: false,
        done(data, textStatus, jqXHR) {
          console.log(`done:${data}`);
          if (data) {
            defer.resolve(data);
          } else {
            defer.reject({
              status: jqXHR.status,
              responseText: jqXHR.responseText,
            });
          }
        },
        fail(jqXHR) {
          // jqXHR.status, jqXHR.responseText
          defer.reject({
            status: jqXHR.status,
            responseText: jqXHR.responseText,
          });
        },
      }
    );
    return defer.promise();
  },
  UserListByAuthoryCode(authoryCode) {
    const deferred = $.Deferred();
    const url = Url.UserListByAuthoryCode;
    AjaxUtil.doGet(
      `${url}/${authoryCode}`,
      {},
      {
        async: false,
        done(data) {
          deferred.resolve(data);
        },
        fail(err) {
          deferred.reject(err);
        },
      }
    );
    return deferred.promise();
  },
  getApproachCodeByTaskCode(taskCode) {
    const deferred = $.Deferred();
    const url = Url.getApproachCodeByTaskCode;
    AjaxUtil.doGet(
      `${url}/${taskCode}`,
      {},
      {
        async: false,
        done(data) {
          deferred.resolve(data);
        },
        fail(err) {
          deferred.reject(err);
        },
      }
    );
    return deferred.promise();
  },
  getTaskCode(caseId) {
    const deferred = $.Deferred();
    const url = `${Url.getTaskCode}/${caseId}`;
    AjaxUtil.doGet(
      url,
      {},
      {
        async: false,
        done(data) {
          deferred.resolve(data);
        },
        fail(err) {
          deferred.reject(err);
        },
      }
    );
    return deferred.promise();
  },
  getAttachTo() {
    const deferred = $.Deferred();
    const url = Url.AssignmentAttachTo;
    AjaxUtil.doGet(
      url,
      {},
      {
        async: false,
        done(data) {
          deferred.resolve(data);
        },
        fail(err) {
          deferred.reject(err);
        },
      }
    );
    return deferred.promise();
  },
  getFATaskRelationInfo(claimNo, complete) {
    const deferred = $.Deferred();
    const url = `${Url.FATaskRelationInfo}/${claimNo}/${complete}`;
    AjaxUtil.doGet(
      url,
      {},
      {
        async: false,
        done(data) {
          deferred.resolve(data);
        },
        fail(err) {
          deferred.reject(err);
        },
      }
    );
    return deferred.promise();
  },
};
