import {
  UIText,
  UIDataTable,
  UIColumn,
  UICurrency,
  UIPanel,
  UIDialog,
  UICell,
  UIUpdatePanel,
} from 'RainbowUI';
import FeeDetailDialogAction from './FeeDetailDialogAction';
import EndoInCancellation from './EndoInCancellation';

const FeeDetailDialog = React.createClass({
  getInitialState() {
    const installmentPlanInfo = {};
    return {
      InstallmentPlanInfo: installmentPlanInfo,
    };
  },

  /* componentWillMount: () => {
		let installmentPlanInfo = DataContext.get("installmentPlanInfo");
	}, */

  showDialog(installmentPlanInfo) {
    console.log(`showDialog: ${JSON.stringify(installmentPlanInfo)}`);
    if (installmentPlanInfo) {
      this.setState({
        InstallmentPlanInfo: installmentPlanInfo,
      });
    }
    // console.log("showDialog "+ JSON.stringify(this.state.InstallmentPlanInfo));
    UIDialog.show('feeDetailDialog');
  },

  render() {
    const { InstallmentPlanInfo } = this.state;
    const feeDetailDialogAction = new FeeDetailDialogAction();
    const _self = this;
    /* let installmentPlanInfo = this.state.InstallmentPlanInfo;
		let type = "newbiz";
		if(installmentPlanInfo) {
			if(installmentPlanInfo.endoId) {
				type = "endo";
			}
		} */
    const installmentPlanInfo = InstallmentPlanInfo;
    /*
		{_self.renderNewbizOrEndoFeeDetail()}
		{_self.renderEndorsementCancellation()}
		 */
    return (
      <div>
        <UIDialog
          id="feeDetailDialog"
          title={`Fee Detail - ${installmentPlanInfo.DebitCreditNo}`}
        >
          <UIUpdatePanel id="feeDetailUpdatePanel">
            {_self.renderContent(installmentPlanInfo)}
          </UIUpdatePanel>
        </UIDialog>
      </div>
    );
  },

  renderContent(installmentPlanInfo) {
    const _self = this;
    console.log(`endotype: ${installmentPlanInfo.EndoType}`);
    if (installmentPlanInfo.EndoType == 21) {
      return _self.renderEndorsementCancellation();
    }
    return _self.renderNewbizOrEndoFeeDetail();
  },

  renderNewbizOrEndoFeeDetail() {
    const feeDetailDialogAction = new FeeDetailDialogAction();
    const _self = this;
    return (
      <UIUpdatePanel
        id="newbizOrEndoFeeDetailUpdatePanel"
        render={() => {
          let panelTitle = null;
          if (_self.state.InstallmentPlanInfo.EndoId) {
            panelTitle = `${
              i18n.endorsement
            }-${feeDetailDialogAction.getEndoType(
              _self.state.InstallmentPlanInfo.EndoType
            )}-${_self.state.InstallmentPlanInfo.EndoNo}`;
          } else {
            panelTitle = i18n.newBusiness;
          }
          return (
            <UIPanel panelTitle={panelTitle}>
              <UIDataTable
                id="feeDetailForNewbizEndoDataTable"
                provider={feeDetailDialogAction.getProvider}
                pageable="false"
                indexable="false"
              >
                <UIColumn
                  headerTitle={i18n.installmentNo}
                  render={data => {
                    return (
                      <UIText
                        io="out"
                        value={data.InstallmentNo}
                        style={_self.getBoldStyle(data)}
                      />
                    );
                  }}
                />
                <UIColumn
                  headerTitle={i18n.premiumDue}
                  render={data => {
                    return (
                      <UICell style={_self.getBoldStyle(data)}>
                        <UICurrency
                          unit={data.CurrencySign}
                          io="out"
                          value={data.PremiumDue}
                        />
                      </UICell>
                    );
                  }}
                />
                <UIColumn
                  headerTitle={i18n.actualPremium}
                  render={data => {
                    return (
                      <UICell style={_self.getBoldStyle(data)}>
                        <UICurrency
                          unit={data.CurrencySign}
                          io="out"
                          value={data.ActualPremium}
                        />
                      </UICell>
                    );
                  }}
                />
                <UIColumn
                  headerTitle={i18n.policyFee}
                  render={data => {
                    return (
                      <UICell style={_self.getBoldStyle(data)}>
                        <UICurrency
                          unit={data.CurrencySign}
                          io="out"
                          value={data.Fee}
                        />
                      </UICell>
                    );
                  }}
                />
                <UIColumn
                  headerTitle={i18n.tax}
                  render={data => {
                    return (
                      <UICell style={_self.getBoldStyle(data)}>
                        <UICurrency
                          unit={data.CurrencySign}
                          io="out"
                          value={data.Tax}
                        />
                      </UICell>
                    );
                  }}
                />
                <UIColumn
                  headerTitle={i18n.stamps}
                  render={data => {
                    return (
                      <UICell style={_self.getBoldStyle(data)}>
                        <UICurrency
                          unit={data.CurrencySign}
                          io="out"
                          value={data.StampDuty}
                        />
                      </UICell>
                    );
                  }}
                />
              </UIDataTable>
            </UIPanel>
          );
        }}
      />
    );
  },

  renderEndorsementCancellation() {
    const feeDetailDialogAction = new FeeDetailDialogAction();
    // let installmentPlanInfo = this.state.InstallmentPlanInfo;
    // let cancelEndoId = installmentPlanInfo.CancelEndoId;
    const _self = this;
    const { InstallmentPlanInfo } = this.state;
    return (
      <div>
        <UIPanel
          panelTitle={`${i18n.newBusiness}-${InstallmentPlanInfo.PolicyNo}`}
        >
          <UIDataTable
            id="feeDetailForNewbizDataTable"
            provider={
              feeDetailDialogAction.getProviderForNewbizInCancellation
            }
            pageable="false"
            indexable="false"
          >
            <UIColumn
              headerTitle={i18n.installmentNo}
              render={data => {
                return (
                  <UIText
                    io="out"
                    value={data.InstallmentNo}
                    style={_self.getBoldStyle(data)}
                  />
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.premiumDue}
              render={data => {
                return (
                  <UICell style={_self.getBoldStyle(data)}>
                    <UICurrency
                      unit={data.LocalCurrencySign}
                      io="out"
                      value={data.PremiumDue}
                    />
                  </UICell>
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.actualPremium}
              render={data => {
                return (
                  <UICell style={_self.getBoldStyle(data)}>
                    <UICurrency
                      unit={data.LocalCurrencySign}
                      io="out"
                      value={data.ActualPremium}
                    />
                  </UICell>
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.policyFee}
              render={data => {
                return (
                  <UICell style={_self.getBoldStyle(data)}>
                    <UICurrency
                      unit={data.LocalCurrencySign}
                      io="out"
                      value={data.Fee}
                    />
                  </UICell>
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.tax}
              render={data => {
                return (
                  <UICell style={_self.getBoldStyle(data)}>
                    <UICurrency
                      unit={data.LocalCurrencySign}
                      io="out"
                      value={data.Tax}
                    />
                  </UICell>
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.stamps}
              render={data => {
                return (
                  <UICell style={_self.getBoldStyle(data)}>
                    <UICurrency
                      unit={data.LocalCurrencySign}
                      io="out"
                      value={data.StampDuty}
                    />
                  </UICell>
                );
              }}
            />
          </UIDataTable>
        </UIPanel>
        {_self.renderEndoInEndoCancellation()}
        <UIPanel
          panelTitle={`${i18n.endoCancellation}-${InstallmentPlanInfo.EndoNo}`}
        >
          <UIDataTable
            id="feeDetailForNewbizEndoDataTable"
            provider={
              feeDetailDialogAction.getProviderForEndoCancellation
            }
            pageable="false"
            indexable="false"
          >
            <UIColumn
              headerTitle={null}
              render={data => {
                return (
                  <UIText
                    io="out"
                    value={data.InstallmentNo}
                    style={_self.getBoldStyle(data)}
                  />
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.endoPremium}
              render={data => {
                return (
                  <UICell style={_self.getBoldStyle(data)}>
                    <UICurrency
                      unit={data.LocalCurrencySign}
                      io="out"
                      value={data.PremiumDue}
                    />
                  </UICell>
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.cancelledPremium}
              render={data => {
                return (
                  <UICell style={_self.getBoldStyle(data)}>
                    <UICurrency
                      unit={data.LocalCurrencySign}
                      io="out"
                      value={data.ActualPremium}
                    />
                  </UICell>
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.policyFee}
              render={data => {
                return (
                  <UICell style={_self.getBoldStyle(data)}>
                    <UICurrency
                      unit={data.LocalCurrencySign}
                      io="out"
                      value={data.Fee}
                    />
                  </UICell>
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.tax}
              render={data => {
                return (
                  <UICell style={_self.getBoldStyle(data)}>
                    <UICurrency
                      unit={data.LocalCurrencySign}
                      io="out"
                      value={data.Tax}
                    />
                  </UICell>
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.stamps}
              render={data => {
                return (
                  <UICell style={_self.getBoldStyle(data)}>
                    <UICurrency
                      unit={data.LocalCurrencySign}
                      io="out"
                      value={data.StampDuty}
                    />
                  </UICell>
                );
              }}
            />
          </UIDataTable>
        </UIPanel>
      </div>
    );
  },

  renderEndoInEndoCancellation() {
    const feeDetailDialogAction = new FeeDetailDialogAction();
    const installmentPlanInfo = this.state.InstallmentPlanInfo;
    const item = [];
    if (installmentPlanInfo) {
      if (installmentPlanInfo.EndoType == 21) {
        const endoIdList = feeDetailDialogAction.getEndoIdListByPolicyId(
          installmentPlanInfo.PolicyId
        );
        for (let i = 0; i < endoIdList.length; i += 1) {
          const endoId = endoIdList[i];
          const FeeDetail = feeDetailDialogAction.getEndoFeeListByEndoId(
            endoId,
            installmentPlanInfo.EndoType
          );
          if (FeeDetail) {
            item.push(<EndoInCancellation FeeDetail={FeeDetail} />);
          }
        }
      }
    }
    return item;
  },

  getBoldStyle(data) {
    if (data.InstallmentNo === 'Total') {
      return { 'font-weight': 'bold' };
    }
    return {};
  },
});
export default FeeDetailDialog;
