const ClaimProduct = {
  getProductCfgDetail() {
    return require('./module/product/component/ProductCfgDetail');
  },
  getProductConfiguration() {
    return require('./module/product/component/ProductParameterCfg');
  },
  getProductCfgTreeAction() {
    return require('./module/product/action/ProductCfgTreeAction');
  },
  getAlt() {
    return require('./module/product/alt');
  },
  getProductDialogAction() {
    return require('./module/product/action/ProductDialogAction');
  },
};

export default ClaimProduct;
