import {
  UIPanel,
  DataContext,
  UISmartPanelGrid,
  UIButton,
  UIText,
  UIBox,
  UISelect,
  UILink,
  UIDateTimePicker,
  UpdateContext,
  MessageHelper,
  UIUpdatePanel,
  UIDialog,
} from 'RainbowUI';
import BindToMixin from 'react-binding';
import React from 'react';
import { PtyIntegrationGruopId } from 'EU00PartyCommonUI';
import SolrService from '../../service/SolrService';

const PolicyAction = require('../../action/PolicyAction');
const CodeTableAction = require('../../action/CodeTableAction');
const CodeTableCstUI = require('../../constant/CodeTableCstUI');

const PolicySearchOptions = React.createClass({
  mixins: [BindToMixin],

  getInitialState() {
    const policySearchOptions = {
      PolicyStatus: [2, 3],
      PolicyType: ['1', '2', '3', '4'],
    };
    const policySearchOptions2 = { PolicyStatus: [2, 3] };

    const user = CodeTableAction.renderUser();
    const FuzzyConditions = {};
    // if (user.DirectUser !== 'Y') {
    //   FuzzyConditions.PermitOrgId = `,${user.OrgId},`;
    // }
    return {
      hideSearchOptions: false,
      policySearchOptions,
      policySearchOptions2,
      FuzzyConditions,
      finalFuzzyConditions: FuzzyConditions,
      selectedPolicyNo: '',
      SortField: 'index_time',
      SortType: 'desc',
    };
  },

  clickSearch() {
    UIDialog.show('policySearch_dialog');
    const fuzzyConditions = this.state.FuzzyConditions;
    const fuzzyCondition = {};
    for (const key in fuzzyConditions) {
      if (key === 'InsuredCode') {
        fuzzyCondition.InsuredCode = `,${fuzzyConditions.InsuredCode},`;
      } else {
        fuzzyCondition[key] = fuzzyConditions[key];
      }
    }

    if (this.state.policySearchOptions.ProductCode) {
      this.state.policySearchOptions2.ProductCode = this.state.policySearchOptions.ProductCode;
    }
    if (this.state.policySearchOptions.EffectiveDate_from) {
      this.state.policySearchOptions2.EffectiveDate_from = this.state.policySearchOptions.EffectiveDate_from;
    }
    if (this.state.policySearchOptions.EffectiveDate_to) {
      this.state.policySearchOptions2.EffectiveDate_to = this.state.policySearchOptions.EffectiveDate_to;
    }
    if (this.state.policySearchOptions.PolicyholderCode) {
      this.state.policySearchOptions2.PolicyholderCode = this.state.policySearchOptions.PolicyholderCode;
    }
    if (this.state.policySearchOptions.CustomerTelephone_Fuzzy) {
      this.state.policySearchOptions2.CustomerTelephone_Fuzzy = this.state.policySearchOptions.CustomerTelephone_Fuzzy;
    }
    if (this.state.policySearchOptions.CustomerRegistrationNumber) {
      this.state.policySearchOptions2.CustomerRegistrationNumber = this.state.policySearchOptions.CustomerRegistrationNumber;
    }
    DataContext.put('onchange', true);
    if (this.state.GroupId) {
      const searchConditions = {
        GroupId: this.state.GroupId,
      };
      const searchCondition = {
        Conditions: searchConditions,
        PageNo: 1,
        PageSize: 9999,
        Module: 'Party',
      };
      let result = '';
      const partyIdList = [];
      SolrService.queryEntitySolr(searchCondition).then(data => {
        if (data.Total === 0) {
          result = null;
        } else {
          result = data.Results[0].SolrDocs;
        }
      });
      if (result) {
        _.each(result, solrDoc => {
          if (solrDoc.PartyId) {
            partyIdList.push(solrDoc.PartyId);
          }
        });
      }
      if (partyIdList.length === 0) {
        partyIdList.push(0);
      }

      this.state.policySearchOptions.owned_customer_id = partyIdList;
      this.state.policySearchOptions2.owned_customer_id = partyIdList;
    } else if (!this.state.GroupId) {
      delete this.state.policySearchOptions.owned_customer_id;
      delete this.state.policySearchOptions2.owned_customer_id;
    }

    PolicyAction.searchPolicy({
      policySearchOptions: this.state.policySearchOptions,
      policySearchOptions2: this.state.policySearchOptions2,
      FuzzyConditions: fuzzyConditions,
    });
  },

  reset() {
    DataContext.put('onchange', true);

    const policySearchOptions = {
      PolicyStatus: [2, 3],
      PolicyType: ['1', '2', '3', '4'],
    };
    const policySearchOptions2 = { PolicyStatus: [2, 3] };

    delete this.state.FuzzyConditions.PolicyNo;
    delete this.state.FuzzyConditions.PolicyHolder;
    delete this.state.FuzzyConditions.InsuredName;
    delete this.state.FuzzyConditions.InsuredCode;

    $("input[name='IdForPartyGroupSearch']").val(null);
    $("input[name='IdForPartyGroupSearch_value']").val(null);
    this.setState(prevState => {
      return {
        policySearchOptions,
        policySearchOptions2,
        FuzzyConditions: prevState.finalFuzzyConditions,
        GroupId: '',
      };
    });
    this.props.clearResult();
  },

  changeSearchOptions(event) {
    this.setState(prevState => {
      return {
        hideSearchOptions: !prevState.hideSearchOptions,
        policySearchOptions: {},
        policySearchOptions2: {},
        FuzzyConditions: {},
      };
    });
  },
  getParentComponent() {
    return this;
  },
  render() {
    return (
      <UIPanel
        styleClass="default"
        panelTitle={i18n.ClaimCommon.PolicySearch}
      >
        {this.getSearchOptions()}
      </UIPanel>
    );
  },
  changeValue() {
    DataContext.put('onchange', true);
  },
  validateDateOfDueDateFromTo(event) {
    DataContext.put('onchange', true);
    const Conditions = this.state.policySearchOptions;
    if (Conditions.EffectiveDate_from > Conditions.EffectiveDate_to) {
      MessageHelper.warning(
        i18n.ClaimCommon.WarnMsg.DateToCannotEarlierThanDateFrom,
        null,
        MessageHelper.POSITION_TOP_RIGHT
      );
      if (event.component.componentId === 'qLossDateFrom') {
        Conditions.EffectiveDate_from = null;
      } else {
        Conditions.EffectiveDate_to = null;
      }

      UpdateContext.forceUpdate('fresh1');
      UpdateContext.forceUpdate('fresh2');
      this.setState({
        policySearchOptions: Conditions,
        policySearchOptions2: Conditions,
      });
    }
  },
  getSearchOptions() {
    const {
      policySearchOptions,
      policySearchOptions2,
      hideSearchOptions,
    } = this.state;
    const { productTypeCode, productDisabled } = this.props;
    if (productTypeCode && productDisabled) {
      policySearchOptions.ProductCode = productTypeCode;
      policySearchOptions2.ProductCode = productTypeCode;
    }
    if (hideSearchOptions) {
      return (
        <div>
          <UISmartPanelGrid column="1">
            <UIText
              id="qSimpleText"
              valueLink={this.bindToState(
                'policySearchOptions',
                'text'
              )}
            />
          </UISmartPanelGrid>
          <UIBox direction="left">
            <UIButton
              value={i18n.ClaimCommon.Search}
              onClick={this.clickSearch}
            />
            <UILink
              style={{ float: 'right' }}
              value={
                hideSearchOptions
                  ? i18n.ClaimCommon.ShowSearchOptions
                  : i18n.ClaimCommon.HideSearchOptions
              }
              onClick={this.changeSearchOptions}
            />
          </UIBox>
        </div>
      );
    }
    return (
      <div>
        <UISmartPanelGrid column="2" widthAllocation="5,7">
          <UIText
            id="QueryPolicy_PolicyNumber"
            label={i18n.ClaimCommon.PolicyNumber}
            onChange={this.changeValue}
            valueLink={this.bindToState(
              'FuzzyConditions',
              'PolicyNo'
            )}
          />

          <UISelect
            id="QueryPolicy_ProductCode"
            label={i18n.ClaimCommon.ProductTypeName}
            disabled={productDisabled}
            codeTable={CodeTableAction.loadDataList(
              CodeTableCstUI.ProductTypeCode,
              null
            )}
            onChange={this.changeValue}
            valueLink={this.bindToState(
              'policySearchOptions',
              'ProductCode'
            )}
          />
        </UISmartPanelGrid>
        <UISmartPanelGrid column="2">
          <UIUpdatePanel id="fresh1">
            <UIDateTimePicker
              id="qLossDateFrom"
              label={i18n.ClaimCommon.PolicyEffectiveDataFrom}
              valueLink={this.bindToState(
                'policySearchOptions',
                'EffectiveDate_from'
              )}
              widthAllocation="5,7"
              onChange={this.validateDateOfDueDateFromTo}
              format="MM/DD/YYYY"
            />
          </UIUpdatePanel>
          <UIUpdatePanel id="fresh2">
            <UIDateTimePicker
              id="qLossDateTo"
              label={i18n.ClaimCommon.PolicyEffectiveDataTo}
              valueLink={this.bindToState(
                'policySearchOptions',
                'EffectiveDate_to'
              )}
              widthAllocation="5,7"
              onChange={this.validateDateOfDueDateFromTo}
              format="MM/DD/YYYY"
            />
          </UIUpdatePanel>
        </UISmartPanelGrid>
        <UISmartPanelGrid column="2" widthAllocation="5,7">
          <UIText
            id="QueryPolicy_PolicyholderName"
            label={i18n.ClaimCommon.PolicyHolderName}
            onChange={this.changeValue}
            valueLink={this.bindToState(
              'FuzzyConditions',
              'PolicyHolder'
            )}
          />

          <UIText
            id="QueryPolicy_PolicyholderIDNumber"
            colspan="1"
            label={i18n.ClaimCommon.PolicyHolderIDNumber}
            onChange={this.changeValue}
            valueLink={this.bindToState(
              'policySearchOptions',
              'CustomerRegistrationNumber'
            )}
          />

          <UIText
            id="QueryPolicy_PolicyholderTel"
            colspan="1"
            label={i18n.ClaimCommon.PolicyHolderTelNo}
            onChange={this.changeValue}
            valueLink={this.bindToState(
              'policySearchOptions',
              'CustomerTelephone_Fuzzy'
            )}
          />
        </UISmartPanelGrid>
        <UISmartPanelGrid column="2" widthAllocation="5,7">
          <UIText
            id="QueryPolicy_InsuredName"
            label={i18n.ClaimCommon.InsuredName}
            onChange={this.changeValue}
            valueLink={this.bindToState(
              'FuzzyConditions',
              'InsuredName'
            )}
          />

          <UIText
            id="QueryPolicy_InsuredIDNumber"
            colspan="1"
            label={i18n.ClaimCommon.InsuredIDNumber}
            onChange={this.changeValue}
            valueLink={this.bindToState(
              'FuzzyConditions',
              'InsuredCode'
            )}
          />
          <PtyIntegrationGruopId
            onCallBack={this.getGroupIdResult}
            onChangeGroupId={this.onChangeGroupId}
            widthAllocation="5,7"
            onHideDialog={this.onHideDialog}
          />
        </UISmartPanelGrid>

        <UIBox direction="left">
          <UIButton
            value={i18n.ClaimCommon.Search}
            onClick={this.clickSearch}
          />
          <UIButton
            value={i18n.ClaimCommon.Reset}
            onClick={this.reset}
          />
          <UILink
            style={{ float: 'right' }}
            value={
              hideSearchOptions
                ? i18n.ClaimCommon.ShowSearchOptions
                : i18n.ClaimCommon.HideSearchOptions
            }
            onClick={this.changeSearchOptions}
          />
        </UIBox>
      </div>
    );
  },

  getGroupIdResult(groupInfo) {
    const { state } = this;
    state.GroupId = groupInfo.GroupId;
  },

  onChangeGroupId(groupInfo) {
    const { state } = this;
    if (!groupInfo) {
      state.GroupId = $("input[name='IdForPartyGroupSearch']").val();
    } else {
      state.GroupId = groupInfo.GroupId;
    }
  },
});
export default PolicySearchOptions;
