import { AjaxUtil } from 'RainbowUI';
import Url from '../url/Url';

export default {
  loadRelation(entity) {
    const url = Url.SubclaimCoverageCategoryReservePaymentLoad;
    let list = null;
    AjaxUtil.doPost(url, entity, {
      async: false,
      done(data) {
        list = data;
      },
    });
    return list.Model;
  },

  loadCoverageCategoryReservePayment(productLineCode) {
    const url =
      Url.SubclaimCoverageCategoryReservePayment + productLineCode;
    let list = null;
    AjaxUtil.doGet(
      url,
      {},
      {
        async: false,
        done(data) {
          list = data;
        },
      }
    );

    return list.Model;
  },
  updateCoverageCategoryReservePayment(claimRelationData) {
    const url = Url.SubclaimCoverageCategoryReservePaymentUpdate;
    AjaxUtil.doPost(url, claimRelationData, {
      async: false,
      done() {},
    });
  },
};
