import {
  UIDateTimePicker,
  UIBox,
  UIText,
  UICurrency,
  UIColumn,
  UISelect,
  UIButton,
  UIDialog,
  UIDataTable,
  UICheckbox,
  UIParam,
} from 'RainbowUI';
import BindToMixin from 'react-binding';
import PropTypes from 'prop-types';
import { CommonAction } from 'EU00PartyCommonUI';
import ReserveStore from '../store/ReserveStore';

const ReserveHistoryAction = require('../action/ReserveHistoryAction');
const CodeTableAction = require('../../common/action/CodeTableAction');
const CodeTableCstUI = require('../../common/constant/CodeTableCstUI');
const ClaimCstUI = require('../../common/constant/ClaimCstUI');

const HistoryDialog = React.createClass({
  mixins: [BindToMixin],

  propTypes: {
    objectId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  },

  getInitialState() {
    const commonAction = new CommonAction();
    const currentMonthForEndBatch = commonAction.getCurrentMonthForEndBatch();
    let currentMonthDate = new Date();
    if (currentMonthForEndBatch) {
      currentMonthDate = new Date(currentMonthForEndBatch);
    }
    currentMonthDate.setMonth(currentMonthDate.getMonth() + 1);

    return {
      reserveType: null,
      showHistoryList: [],
      currentMonthDate,
    };
  },

  componentWillMount() {
    ReserveStore.listen(this.onStoreStateChange);
  },

  componentWillUnmount() {
    ReserveStore.unlisten(this.onStoreStateChange);
  },

  onStoreStateChange(storeState) {
    if (
      !_.isEmpty(storeState.showHistoryList) ||
      !_.isEmpty(storeState.reserveType)
    ) {
      this.setState({
        showHistoryList: storeState.showHistoryList,
        reserveType: storeState.reserveType,
      });
    }
  },

  reserveTypeCodeTable() {
    const reserveTypeCode = CodeTableAction.loadDataList(
      CodeTableCstUI.ClaimReserveType,
      null
    );

    return reserveTypeCode;
  },

  negateAmount(number, needChange) {
    if (needChange) {
      return -1 * number;
    }
    return number;
  },
  retroacitveAdjustChange(event) {
    ReserveHistoryAction.changeHistory(event);
  },

  render() {
    const _self = this;
    const { showHistoryList, reserveType } = this.state;
    const { objectId } = this.props;
    const data = showHistoryList;
    const closeReasonCode = CodeTableAction.loadDataList(
      CodeTableCstUI.ClaimCloseReason,
      null
    );
    const reopenReasonCode = CodeTableAction.loadDataList(
      CodeTableCstUI.ClaimCauseOfReopening,
      null
    );

    let coinColumn_Leader = <div />;
    let adjustColumn = <div />;
    let saveButton = <div />;

    let enabled = true;
    if (
      _self.props.authMap.CLAIM_RESERVE_MONTH_END_ADJUSTMENT == true
    ) {
      adjustColumn = (
        <UIColumn
          width="6%"
          headerTitle={i18n.ClaimReserve.RetroacitveAdjust}
          render={data => {
            const insertTime = new Date(data.InsertTime);
            if (_self.state.currentMonthDate >= insertTime) {
              enabled = false;
            } else {
              enabled = true;
              if (data.RetroacitveAdjust === 'Y') {
                console.info('RetroacitveAdjust: data', data);
                for (
                  let i = showHistoryList.length - 1;
                  i >= 0;
                  i -= 1
                ) {
                  const oneList = showHistoryList[i];
                  if (oneList.RetroacitveAdjust === 'Y') {
                    const currentMonth = new Date(
                      oneList.InsertTime.substr(0, 7)
                    );
                    currentMonth.setHours(0);
                    const nextMonth = new Date(
                      oneList.InsertTime.substr(0, 7)
                    );
                    nextMonth.setHours(0);
                    nextMonth.setMonth(nextMonth.getMonth() + 1);
                    if (
                      data.ReserveType == oneList.ReserveType &&
                      insertTime > currentMonth &&
                      insertTime < new Date(oneList.InsertTime)
                    ) {
                      enabled = false;
                      console.info(
                        'RetroacitveAdjust: oneList',
                        oneList
                      );
                      break;
                    }
                  }
                }
              }
            }
            return (
              <UICheckbox
                single="true"
                model={data}
                property="RetroacitveAdjust"
                enabled={enabled}
                codeTable={CodeTableAction.loadDataList(
                  CodeTableCstUI.ClaimYesOrNo
                )}
                onChange={_self.retroacitveAdjustChange}
              >
                <UIParam name="data" value={data} />
                <UIParam
                  name="showHistoryList"
                  value={_self.state.showHistoryList}
                />
                <UIParam
                  name="currentMonthDate"
                  value={_self.state.currentMonthDate}
                />
              </UICheckbox>
            );
          }}
        />
      );
      saveButton = (
        <UIButton
          value={i18n.ClaimReserve.SaveAndExit}
          onClick={_self.submit}
        />
      );
    }
    let coinColumn_Insurance = <div />;

    if (
      data.length === 0 ||
      !data[0].ClaimReserveCoinHis ||
      data[0].ClaimReserveCoinHis.length === 0
    ) {
      coinColumn_Leader = <div />;
      coinColumn_Insurance = <div />;
    } else {
      if (data[0].ClaimReserveCoinHis.length > 0) {
        coinColumn_Leader = (
          <UIColumn
            headerTitle={i18n.ClaimReserve.CoinLeader}
            width="8%"
            render={data => {
              const coinList = data.ClaimReserveCoinHis.map(val_ => {
                return (
                  <div>
                    <UIText io="out" value={val_.Leader} />
                    <br />
                  </div>
                );
              });
              return (
                <div>
                  {coinList}
                  <UIText io="out" value="-" />
                </div>
              );
            }}
          />
        );
      }
      if (data[0].ClaimReserveCoinHis.length > 0) {
        coinColumn_Insurance = (
          <UIColumn
            headerTitle={i18n.ClaimReserve.CoinSurance}
            width="15%"
            render={data => {
              const coinList = data.ClaimReserveCoinHis.map(val_ => {
                return (
                  <div>
                    <UIText io="out" value={val_.CoinCompanyName} />
                    <br />
                  </div>
                );
              });
              return (
                <div>
                  {coinList}
                  <UIText io="out" value={i18n.ClaimReserve.Total} />
                </div>
              );
            }}
          />
        );
      }
    }
    let renderColumnIndex = 0;
    return (
      <UIDialog
        id={`historyDialog_${objectId}`}
        title={i18n.ClaimReserve.ReserveHistory}
        width="98%"
        height="600px"
        modal="false"
      >
        <UISelect
          id={`reserveType_${objectId}`}
          label={i18n.ClaimReserve.ReserveType}
          value={reserveType ? reserveType.reserveType : ''}
          codeTable={this.reserveTypeCodeTable()}
          onChange={this.change}
          widthAllocation="1,6"
        />
        <hr />
        <UIDataTable
          id={`historydialog_updatePanel${objectId}`}
          value={data}
          indexable="false"
          infoable="true"
          isHeading="false"
          pageable="false"
          detailable="false"
        >
          <UIColumn
            headerTitle={i18n.ClaimReserve.ReserveType}
            render={data => {
              return (
                <UISelect
                  value={data.ReserveType}
                  codeTable={CodeTableAction.loadDataList(
                    CodeTableCstUI.ClaimReserveType,
                    null
                  )}
                  io="out"
                />
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.ClaimReserve.Status}
            width="6%"
            value="NewStatus"
          >
            <UIText io="out" />
          </UIColumn>
          {coinColumn_Insurance}
          {coinColumn_Leader}
          <UIColumn
            headerTitle={i18n.ClaimReserve.ChangeAmount}
            render={data => {
              renderColumnIndex += 1;
              let coinList = null;
              if (
                data.ClaimReserveCoinHis &&
                data.ClaimReserveCoinHis.length > 0
              ) {
                const seq = 0;
                coinList = data.ClaimReserveCoinHis.map(val_ => {
                  return (
                    <div style={{ float: 'left' }}>
                      <UICurrency
                        id={`CURRENCY_CODE_ChangeAmount${renderColumnIndex}_${seq}`}
                        io="out"
                        value={val_.OutstandingChanged}
                        unit={
                          data.CurrencyCode
                            ? window.EU00.getCurrencySignByCurrencyCode(
                              data.CurrencyCode
                            )
                            : window.EU00.getCurrencySignByCurrencyCode(
                              ''
                            )
                        }
                      />
                      <br />
                    </div>
                  );
                });
              }

              return (
                <div style={{ float: 'left' }}>
                  {coinList}
                  <UICurrency
                    id={`CURRENCY_CODE_OutstandingChanged${renderColumnIndex}`}
                    io="out"
                    unit={window.EU00.getCurrencySignByCurrencyCode(
                      data.CurrencyCode
                    )}
                    value={data.OutstandingChanged}
                  />
                </div>
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.ClaimReserve.OutstandingReserve}
            render={data => {
              let coinList = null;
              if (
                data.ClaimReserveCoinHis &&
                data.ClaimReserveCoinHis.length > 0
              ) {
                const seq = 0;
                coinList = data.ClaimReserveCoinHis.map(val_ => {
                  return (
                    <div style={{ float: 'left' }}>
                      <UICurrency
                        id={`CURRENCY_CODE_OutstandingReserve${renderColumnIndex}_${seq}`}
                        io="out"
                        value={val_.OutstandingAmount}
                        unit={
                          data.CurrencyCode
                            ? window.EU00.getCurrencySignByCurrencyCode(
                              data.CurrencyCode
                            )
                            : window.EU00.getCurrencySignByCurrencyCode(
                              ''
                            )
                        }
                      />
                      <br />
                    </div>
                  );
                });
              }
              return (
                <div style={{ float: 'left' }}>
                  {coinList}
                  <UICurrency
                    id={`CURRENCY_CODE_OutstandingAmount${renderColumnIndex}`}
                    io="out"
                    unit={window.EU00.getCurrencySignByCurrencyCode(
                      data.CurrencyCode
                    )}
                    value={data.OutstandingAmount}
                  />
                </div>
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.ClaimReserve.Operator}
            render={data => {
              return (
                <UISelect
                  value={data.Operator}
                  codeTable={CodeTableAction.loadDataList(
                    CodeTableCstUI.ClaimUser,
                    null
                  )}
                  io="out"
                />
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.ClaimReserve.Date}
            width="6%"
            value="InsertTime"
          >
            <UIDateTimePicker format="MM/DD/YYYY" io="out" />
          </UIColumn>
          <UIColumn
            headerTitle={i18n.ClaimReserve.CategoryOfReserveChange}
            render={data => {
              return (
                <UISelect
                  value={data.ChangeType}
                  codeTable={CodeTableAction.loadDataList(
                    CodeTableCstUI.ClaimChangeType,
                    null
                  )}
                  io="out"
                />
              );
            }}
          />
          {adjustColumn}
          <UIColumn
            headerTitle={i18n.ClaimReserve.Remarks}
            value="Remark"
            render={data => {
              let reason;
              if (
                data.NewStatus == ClaimCstUI.RESERVE_STATUS.Reopen
              ) {
                const reasons = reopenReasonCode.codes;
                for (let i = 0; i < reasons.length; i += 1) {
                  const temp = reasons[i];
                  if (Number(data.ReopenCause) == temp.id) {
                    reason = `${temp.text};`;
                    break;
                  }
                }
              } else if (
                data.NewStatus == ClaimCstUI.RESERVE_STATUS.Closed
              ) {
                const reasons = closeReasonCode.codes;
                for (let i = 0; i < reasons.length; i += 1) {
                  const temp = reasons[i];
                  if (Number(data.RejectReason) == temp.id) {
                    reason = `${temp.text};`;
                    break;
                  }
                }
              }
              reason = reason || '';
              const dataRemark = data.Remark || '';
              return <UIText io="out" value={reason + dataRemark} />;
            }}
          />
        </UIDataTable>
        <UIBox direction="center">
          {saveButton}
          <UIButton
            value={i18n.ClaimReserve.Cancel}
            position="right"
            buttonType="cancel"
            onClick={this.cancel}
          />
        </UIBox>
      </UIDialog>
    );
  },

  change(event) {
    ReserveHistoryAction.chooseHistory(event.newValue);
  },

  cancel() {
    UIDialog.hide('historyDialog');
  },
  submit() {
    const { showHistoryList } = this.state;
    if (this.state.showHistoryList.length > 0) {
      const tempList = [];
      for (let i = 0; i < showHistoryList.length; i += 1) {
        const tempOne = showHistoryList[i];
        delete tempOne.dataIndex;
        delete tempOne.ClaimReserveCoinHis;
        tempList.push(tempOne);
      }
      ReserveHistoryAction.updateReserveHistory(tempList);
    }
    UIDialog.hide(`historyDialog_${this.props.objectId}`);
  },
});

export default HistoryDialog;
