import {
  UIBox,
  UIDialog,
  UIUpdatePanel,
  UIText,
  UIButton,
  UISmartPanelGrid,
} from 'RainbowUI';
import BindToMixin from 'react-binding';
import ProductCfgTreeAction from '../action/ProductCfgTreeAction';

const ProductDialogAction = require('../action/ProductDialogAction');

const ShowProductDialog = React.createClass({
  mixins: [BindToMixin],

  getInitialState() {
    return { Product: {}, io: 'out' };
  },
  showDialog(data) {
    if (data) {
      this.setState({ io: 'out' });
      this.setState({ Product: data });
    } else {
      this.setState({ Product: {} });
      this.setState({ io: 'in' });
    }
    UIDialog.show('showProductDialog');
  },
  save() {
    ProductDialogAction.save(this.state.Product, this.state.io);
    if (this.props.parentFunction) {
      this.props.parentFunction();
    }
    ProductCfgTreeAction.initData();
  },
  render() {
    return (
      <UIDialog
        id="showProductDialog"
        title={i18n.ClaimProduct.Product}
        width="80%"
        height="600px"
        modal="false"
      >
        <UIUpdatePanel
          id="showProductDialogUIUpdate"
          render={this.renderDialogContent}
        />
        <UIBox direction="left">
          <UIButton
            value={i18n.ClaimProduct.Save}
            onClick={this.save}
            validationGroup="productType"
            causeValidation="true"
          />
          <UIButton
            value={i18n.ClaimProduct.Cancel}
            buttonType="cancel"
          />
        </UIBox>
      </UIDialog>
    );
  },

  renderDialogContent() {
    const { io } = this.state;
    return (
      <UISmartPanelGrid column="2" widthAllocation="4,8">
        <UIText
          io={io}
          id="productTypeCode"
          label={i18n.ClaimProduct.ProductCode}
          valueLink={this.bindToState('Product', 'ProductCode')}
          required="true"
          validationGroup="productType"
        />
        <UIText
          id="productTypeName"
          label={i18n.ClaimProduct.ProductName}
          valueLink={this.bindToState('Product', 'ProductName')}
          required="true"
          validationGroup="productType"
        />
        <UIText
          id="productTypeDescription"
          label={i18n.ClaimProduct.ProductDescription}
          valueLink={this.bindToState(
            'Product',
            'ProductDescription'
          )}
        />
        <UIText
          id="policySysCode"
          label={i18n.ClaimProduct.ExtProductCode}
          valueLink={this.bindToState('Product', 'ExtProductCode')}
        />
      </UISmartPanelGrid>
    );
  },
});
export default ShowProductDialog;
