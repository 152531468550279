import alt from '../alt';
import ClaimAuthorityAction from '../action/ClaimAuthorityAction';

class ClaimAuthorityStore {
  constructor() {
    this.param = {
      authorityList: [],
    };
    this.bindListeners({
      handleClaimAuthority: [ClaimAuthorityAction.LOAD_DATA, ClaimAuthorityAction.CLEAR_DATA],
    });
  }

  handleClaimAuthority(param) {
    //console.log('=authorityList==', param);
    this.param = param;
  }
}

export default alt.createStore(
  ClaimAuthorityStore,
  ClaimAuthorityStore
);
