import {
  UIDialog,
  UIDataList,
  UIPanel,
  UIBox,
  UIButton,
  UISmartPanelGrid,
  UITextarea,
  UIDateTimePicker,
  UISelect,
  UIUpdatePanel,
} from 'RainbowUI';
import NoteAction from './NoteAction';
import CommonCodeTable from '../../codetable/CodeTable';

const Note = React.createClass({
  componentWillMount() {
    new NoteAction().initDate();
  },
  render() {
    const noteAction = new NoteAction();
    return (
      <div>
        <UIDialog id="noteDialog" title={i18n.note} width="80%">
          <UIUpdatePanel id="updateNote">
            <UIPanel
              panelTitle={i18n.existingNote}
              styleClass="default"
            >
              <UIDataList
                value={noteAction.getDataValue}
                contentRender={this.renderDataGrid}
                pageable="false"
              />
            </UIPanel>
            <UIPanel
              panelTitle={i18n.addNewNote}
              styleClass="default"
            >
              <UITextarea
                label={i18n.note}
                value="#{noteModel.NoteContent}"
                widthAllocation="1,11"
                required="true"
                validationGroup="note"
              />
            </UIPanel>
          </UIUpdatePanel>
          <UIBox direction="center">
            <UIButton
              value={i18n.Exit}
              onClick={noteAction.onClickedExit}
            />
          </UIBox>
        </UIDialog>
      </div>
    );
  },
  renderDataGrid(data) {
    const commonCodeTable = new CommonCodeTable();
    return (
      <div>
        <UISmartPanelGrid column="4">
          <UISelect
            value={data.InsertBy}
            codeTable={commonCodeTable.getUser}
            io="out"
          />
          <UIDateTimePicker
            value={data.InsertTime}
            io="out"
            format="MM/DD/YYYY"
          />
          <UITextarea
            colspan="4"
            value={data.NoteContent}
            enabled="false"
          />
        </UISmartPanelGrid>
      </div>
    );
  },
});
export default Note;
