import alt from '../alt';
import MassiveReassignmentTaskOwnerAction from '../action/MassiveReassignmentTaskOwnerAction';

class ReassignmentTaskOwnerStore {
  constructor() {
    this.taskSearchOptions = {};

    this.bindListeners({
      handleSearchTaskPool:
        MassiveReassignmentTaskOwnerAction.SEARCH_TASK,
    });
  }

  handleSearchTaskPool(taskSearchOptions) {
    console.log(
      '============taskSearchOptions=taskSearchOptions==',
      taskSearchOptions
    );
    this.taskSearchOptions = taskSearchOptions;
  }
}

export default alt.createStore(
  ReassignmentTaskOwnerStore,
  ReassignmentTaskOwnerStore
);
