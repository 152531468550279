import {
  UISelect,
  UIPercent,
  UIDialog,
  UISmartPanelGrid,
  UIText,
  UIButton,
  UIDateTimePicker,
  UIBox,
} from 'RainbowUI';
import PartyConst from '../../../const/PartyConst';
import DefinedCodeTable from '../../../codetable/CodeTable';

const PartyShareholderDialogReadOnly = React.createClass({
  getInitialState() {
    const nationalityCodeTable = DefinedCodeTable.getCountry();
    return {
      PartyShareholder: {},
      nationalityCodeTable,
    };
  },

  onClickClose() {
    UIDialog.hide('PartyShareholderDialogReadOnly');
  },

  render() {
    const _self = this;
    const { nationalityCodeTable, PartyShareholder } = this.state;
    const partyShareholder = PartyShareholder;
    return (
      <UIDialog
        id="PartyShareholderDialogReadOnly"
        width="90%"
        title={i18n.ShareholderDetail}
      >
        <UISmartPanelGrid column="3">
          <UIText
            label={i18n.ShareholderName}
            model={partyShareholder}
            property="ShareholderName"
            required="true"
            validationGroup="UpdateShareholderInfo"
            disabled="true"
          />
          <UISelect
            label={i18n.ShareholderCategory}
            model={partyShareholder}
            property="ShareholderCategory"
            codeTable={DefinedCodeTable.getCategroyCodeTable}
            required="true"
            validationGroup="UpdateShareholderInfo"
            disabled="true"
          />
          <UIPercent
            label={i18n.ShareholderPercentage}
            model={partyShareholder}
            property="ShareholderPercentage"
            required="true"
            suffixText="%"
            validationGroup="UpdateShareholderInfo"
            disabled="true"
          />
          <UIText
            label={i18n.IDNumberOrCompanyRegNumber}
            model={partyShareholder}
            property="IdNumberOrCompanyRegNumber"
            required="true"
            validationGroup="UpdateShareholderInfo"
            disabled="true"
          />
          {_self.renderDateOfBirth(partyShareholder)}
          <UISelect
            label={
              partyShareholder.ShareholderCategory ==
                PartyConst.PARTY_TYPE_IND
                ? i18n.Nationality
                : i18n.CountryOfIncorporation
            }
            model={partyShareholder}
            property="Country"
            codeTable={nationalityCodeTable}
            required="true"
            validationGroup="UpdateShareholderInfo"
            disabled="true"
          />
        </UISmartPanelGrid>
        <UIBox direction="center">
          <UIButton
            value={i18n.Cancel}
            onClick={_self.onClickClose}
          />
        </UIBox>
      </UIDialog>
    );
  },

  renderDateOfBirth(partyShareholder) {
    if (
      partyShareholder.ShareholderCategory ==
      PartyConst.PARTY_TYPE_IND
    ) {
      return (
        <UIDateTimePicker
          label={i18n.BirthDay}
          model={partyShareholder}
          property="DateOfBirth"
          required="true"
          maxDate="TODAY"
          manualInput="true"
          validationGroup="UpdateShareholderInfo"
          disabled="true"
          format="MM/DD/YYYY"
        />
      );
    }
  },

  showDialog(partyShareholder) {
    UIDialog.show('PartyShareholderDialogReadOnly');
    this.setState({
      PartyShareholder: partyShareholder,
    });
  },
});
export default PartyShareholderDialogReadOnly;
