import {
  UIText,
  UISelect,
  UIButton,
  UIParam,
  UISmartPanelGrid,
  UIBox,
  CodeTable,
  MessageHelper,
} from 'RainbowUI';
import { CodeTableAction, CodeTableCstUI } from 'ClaimCommonConstant';
import {
  PartyConst,
  PartyAction,
} from 'EU00PartyCommonUI';

import BindToMixin from 'react-binding';

const PartyAccountInfo = React.createClass({
  mixins: [BindToMixin],

  getInitialState() {
    let partyAction = new PartyAction();
    let result = partyAction.searchActivePartyNameForBank(PartyConst.PARTY_ROLE_CODE_ORG_BANK);
    console.log("result", new CodeTable(result));
    return {
      showAddButton: true,
      backNameCodetable: new CodeTable(result),
    };
  },
  onChangeBankInfo: function (event) {
    let { accountVo } = this.state;
    accountVo.BankName = event.newValue;
    if (!event.newValue || event.newValue == "") {
      delete accountVo.SwiftCode;
    } else {
      let partyAction = new PartyAction();
      let party = partyAction.loadPartyByPartyId(event.newValue);
      if (party && party.PartyRoleList && party.PartyRoleList.length > 0) {
        for (let i = 0; i < party.PartyRoleList.length; i++) {
          let partyRole = party.PartyRoleList[i];
          if (partyRole.RoleType == PartyConst.PARTY_ROLE_CODE_ORG_BANK && partyRole.Status == 1) {
            accountVo.SwiftCode = partyRole.SwiftCode;
            break;
          }
        }
      }
    }
    this.setState({ accountVo });
  },

  addAccount() {
    this.setState({
      accountVo: {
        '@type': 'PartyAccount-PartyAccount',
        AccountStatus: '1',
        isAdd: true,
      },
      showAddButton: false,
    });
  },

  editAccount(accountVo) {
    this.setState({ accountVo, showAddButton: false });
  },

  saveAccount() {
    if (!this.state.accountVo.SwiftCode) {
      //The SWIFT Code is required and cannot be empty.
      MessageHelper.error(i18n.PartyMsg.Error.Error_0013, null, MessageHelper.POSITION_TOP_RIGHT);
      return;
    }
    this.props.parentComponent.saveAccount(this.state.accountVo);
    this.cancel();
  },

  cancel() {
    this.setState({ showAddButton: true });
  },
  getSuperComponent() {
    return this;
  },
  render() {
    const { showAddButton, backNameCodetable } = this.state;
    const { index } = this.props;
    if (showAddButton) {
      return <div />;
    }
    let isRequired = true;

    return (
      <div>
        <UISmartPanelGrid>
          <UISelect
            label={i18n.ClaimParty.AccountType}
            id="accountVoAccountType"
            codeTable={CodeTableAction.loadDataList(
              CodeTableCstUI.ClaimPartyAccountType,
              null
            )}
            valueLink={this.bindToState('accountVo', 'AccountType')}
            validationGroup="PartyAccountInfo"
            required={isRequired}
          />

          <UISelect
            label={i18n.ClaimParty.BankName}
            id="accountVoBankCodeId"
            codeTable={backNameCodetable}
            onChange={this.onChangeBankInfo}
            valueLink={this.bindToState('accountVo', 'BankId')}
            validationGroup="PartyAccountInfo"
            required={isRequired}
          />

          <UIText
            label={i18n.ClaimParty.AccountHolderName}
            id="accountVoAccountHolderNameId"
            valueLink={this.bindToState(
              'accountVo',
              'AccountHolderName'
            )}
            validationGroup="PartyAccountInfo"
            required={isRequired}
          />

          <UIText
            label={i18n.ClaimParty.BankAccountNo}
            id="accountVoAccountNoId"
            valueLink={this.bindToState('accountVo', 'AccountNo')}
            validationGroup="PartyAccountInfo"
            required={isRequired}
          />

          <UIText
            label={i18n.ClaimParty.SwiftCode}
            id="accountVoSwiftCodeId"
            valueLink={this.bindToState('accountVo', 'SwiftCode')}
            validationGroup="PartyAccountInfo"
            required={isRequired}
            disabled="true"
          />
          {}
        </UISmartPanelGrid>

        <UIBox direction="left">
          <UIButton
            value={i18n.ClaimParty.SaveButton}
            validationGroup="PartyAccountInfo"
            causeValidation="true"
            onClick={this.saveAccount}
          >
            <UIParam name="index" value={index} />
          </UIButton>
          <UIButton
            value={i18n.ClaimParty.CancelButton}
            onClick={this.cancel}
          >
            <UIParam name="index" value={index} />
          </UIButton>
        </UIBox>
        <hr />
      </div>
    );
  },
});
export default PartyAccountInfo;
