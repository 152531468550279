import alt from '../alt';
import MassiveReassignmentClaimOwnerAction from '../action/MassiveReassignmentClaimOwnerAction';

class ReassignmentClaimOwnerStore {
  constructor() {
    this.taskSearchOptions = {};

    this.bindListeners({
      handleSearchTaskPool:
        MassiveReassignmentClaimOwnerAction.SEARCH_TASK,
    });
  }

  handleSearchTaskPool(taskSearchOptions) {
    console.log(
      '============taskSearchOptions=taskSearchOptions==',
      taskSearchOptions
    );
    this.taskSearchOptions = taskSearchOptions;
  }
}

export default alt.createStore(
  ReassignmentClaimOwnerStore,
  ReassignmentClaimOwnerStore
);
