import BindToMixin from 'react-binding';
import PropTypes from 'prop-types';
import ClaimSettlement from 'ClaimSettlement';
import SettlementReadOnlyStore from '../../common/store/SettlementReadOnlyStore';

const SettlementLoadAction = ClaimSettlement.getSettlementLoadAction();

const RefundInfoLoad = require('./RefundInfoLoad');

const RefundReadOnly = React.createClass({
  mixins: [BindToMixin],

  propTypes: {
    settlementId: PropTypes.string,
    isApprove: PropTypes.bool,
  },

  getInitialState() {
    return { SettlementReadOnly: {} };
  },

  componentWillMount() {
    SettlementReadOnlyStore.listen(this.onStoreStateChange);
    SettlementLoadAction.loadSettlementReadOnly(
      this.props.settlementId
    );
  },
  componentWillReceiveProps(nextProps) {
    if (this.props.settlementId !== nextProps.settlementId) {
      SettlementLoadAction.loadSettlementReadOnly(
        nextProps.settlementId
      );
    }
  },

  componentWillUnmount() {
    SettlementReadOnlyStore.unlisten(this.onStoreStateChange);
  },

  onStoreStateChange(storeState) {
    if (!_.isEmpty(storeState.SettlementReadOnly)) {
      this.setState({
        SettlementReadOnly: storeState.SettlementReadOnly,
      });
    }
  },
  render() {
    const { componentId, isApprove, history } = this.props;
    const {
      SettlementReadOnly: { SettlementInfo, OtherInfo },
    } = this.state;
    const ComponentId = componentId || '';
    if (_.isEmpty(SettlementInfo)) {
      return <div />;
    }
    return (
      <div>
        <RefundInfoLoad
          settlementInfo={SettlementInfo}
          otherInfo={OtherInfo}
          history={history}
          readOnly
          isApprove={isApprove}
          componentId={ComponentId}
        />
      </div>
    );
  },
});
export default RefundReadOnly;
