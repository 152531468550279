import { UIPage } from 'RainbowUI';

import ClaimOwnerSearch from './ClaimOwnerSearch';
import ClaimOwnerResult from './ClaimOwnerResult';

const MassiveReassignmentClaimOwner = React.createClass({
  render() {
    return (
      <UIPage>
        <ClaimOwnerSearch />
        <ClaimOwnerResult ref="ClaimOwnerResult" />
      </UIPage>
    );
  },
});
export default MassiveReassignmentClaimOwner;
