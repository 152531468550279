export default {
  ClaimDesktop: require('./module/common/ClaimDesktop'),

  // ClaimMessageList: require('./module/common/component/ClaimMessageList'),
  // ClaimWorkList: require('./module/common/component/ClaimWorkList'),
  PolicyDetail: require('./module/common/component/policy/PolicyDetail'),
  PolicyInfo: require('./module/common/component/policy/PolicyInfo'),
  PolicySearchDialog: require('./module/common/component/policy/PolicySearchDialog'),
  PolicyDetailDialog: require('./module/common/component/policy/PolicyDetailDialog'),
  PolicyDetailDialogColls: require('./module/common/component/policy/PolicyDetailDialogColls'),
  EU00TwoText: require('./module/common/component/EU00TwoText'),

  ClaimHandling: require('./module/common/component/claimhandling/ClaimHandling'),
  // HandlingLinks: require('./module/common/component/claimhandling/HandlingLinks'),
  ClaimTabs: require('./module/common/component/claimhandling/ClaimTabs'),
  ClaimHandlingAction: require('./module/common/action/ClaimHandlingAction'),
  ClaimHandlingCommonAction: require('./module/common/action/ClaimHandlingCommonAction'),
  ClaimHandlingStore: require('./module/common/store/ClaimHandlingStore'),

  RejectDialog: require('./module/common/component/claimhandling/RejectDialog'),
  CaseFormMock: require('./module/common/mock/CaseFormMock'),
  PolicyAction: require('./module/common/action/PolicyAction'),
  PolicyResultBO: require('./module/common/mock/policy/PolicyResultBO'),
  ReserveAction: require('./module/reserve/action/ReserveAction'),
  ReserveInfo: require('./module/reserve/component/ReserveInfo'),
  ClaimTaskTransferDialog: require('./module/common/component/TaskTransferDialog'),
  ChoosePayeeOrPayerDialog: require('./module/claimparty/component/ChoosePayeeOrPayerDialog'),
  AddOrEditPartyDialog: require('./module/claimparty/component/AddOrEditPartyDialog'),
  ChooseClaimantDialog: require('./module/claimparty/component/ChooseClaimantDialog'),
  SelectPayeeDialog: require('./module/claimparty/component/SelectPayeeDialog'),

  // ClaimCommonRoutes: require('./module/common/routes'),
  // ClaimPartyRoutes: require('./module/claimparty/routes'),
  // ClaimReserveRoutes: require('./module/reserve/routes'),

  ApprovalInfo: require('./module/common/component/ApprovalInfo'),
  ApprovalHistory: require('./module/common/component/ApprovalHistory'),

  // PartyAddress:require('./module/claimparty/component/PartyAddress'),
  // PartyNameInd:require('./module/claimparty/component/PartyNameInd'),
  // PartyNameOrg:require('./module/claimparty/component/PartyNameInd'),
  // Test1:require('./module/common/component/workflow/Test1'),

  // PendingNotice: require('./module/common/component/sample/EmptyPage'),

  ClaimWorkFlowMessage: require('./module/common/component/desktop/ClaimWorkFlowMessage'),
  ClaimWorkFlowMyClaims: require('./module/common/component/desktop/ClaimWorkFlowMyClaims'),
  ClaimWorkFlowMyTask: require('./module/common/component/desktop/ClaimWorkFlowMyTask'),
  ClaimWorkFlowSharePool: require('./module/common/component/desktop/ClaimWorkFlowSharePool'),

  ClaimPageHeader: require('./module/common/component/ClaimPageHeader'),

  Catastrophe: require('./module/common/component/catastrophe/Catastrophe'),
  Attachment: require('./module/common/component/attachment/Attachment'),
  AuthorityConfiguration: require('./module/common/component/authority/AuthorityConfiguration'),

  ClaimQueryPage: require('./module/common/component/claimquery/ClaimQuery'),
  PolicyDataDocument: require('./module/common/component/fileupload/PolicyDataDocument'),
  AssignmentApproach: require('./module/common/component/approach/AssignmentApproach'),
  MassiveReassignmentTaskOwner: require('./module/common/component/reassignment/MassiveReassignmentTaskOwner'),
  MassiveReassignmentClaimOwner: require('./module/common/component/reassignment/MassiveReassignmentClaimOwner'),
  FraudIndicatorInfo: require('./module/common/component/claimhandling/FraudInfos'),
  FraudIndicatorAction: require('./module/common/action/FraudIndicatorAction'),

  ProductTree: require('./module/common/component/public/ProductTree'),

  // litigation
  LitigationTab: require('./module/common/component/litigation/LitigationTab'),
  ClaimLitigationAction: require('./module/common/action/ClaimLitigationAction'),

  // Assignment
  Assignment: require('./module/common/component/assignment/Assignment'),
  AssignmentAction: require('./module/common/action/AssignmentAction'),

  // PartyMaintenance
  PartyMaintenance: require('./module/claimparty/component/PartyMaintenance'),
  PartyMaintenanceAction: require('./module/claimparty/action/PartyMaintenanceAction'),

  // ReserveApproval
  ReserveApproval: require('./module/reserve/component/ReserveApproval'),

  CoverageTree: require('./module/common/component/coverageTree/CoverageTree'),

  AccidentRta: require('./module/common/component/rta/AccidentRta'),
  AccidentRtaDetail: require('./module/common/component/rta/AccidentRtaDetail'),

  // claim matrix
  ClaimMatri: require('./module/common/component/claimMatri/ClaimMatri'),
  ClaimSummaryTab: require('./module/common/component/claimhandling/ClaimSummaryTab'),
  PremiumDetailTab: require('./module/common/component/claimhandling/PremiumDetailTab'),

  name: 'ClaimCommon',
};
