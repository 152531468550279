import { CodeTable, MessageHelper, DataContext } from 'RainbowUI';
import TaskTransferService from '../service/TaskTransferService';

export default {
  getTaskList(data, needNoAssignee, taskAuthMap) {
    let taskList = [];
    TaskTransferService.loadTaskList(
      data.CaseId,
      needNoAssignee
    ).then(
      data => {
        const user = data.Model.user.UserId;
        taskList = data.Model.loadClaimTasks;

        for (let i = 0; i < taskList.length; i += 1) {
          taskList[i].CurrUserId = user;
          if (
            taskList[i].TaskAssignee == user ||
            (taskList[i].TaskAssignee === 'pool' &&
              taskAuthMap.indexOf(taskList[i].TaskDefinitionKey) !==
                -1)
          )
            taskList[i].single = true;
          else taskList[i].single = false;
          taskList[i].dataIndex = i;
        }
      },
      err => {
        if (err.status !== 200) {
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
        }
      }
    );

    return taskList;
  },

  checkApprovalTask(taskCode) {
    const taskName = this.checkApprovalTaskName(taskCode);
    TaskTransferService.checkApprovalTask(taskCode).then(
      data => {
        if (data.Model) {
          if (data.Model.TaskStatus == true) {
            MessageHelper.warning(
              '',
              taskName,
              MessageHelper.POSITION_TOP_RIGHT,
              10000
            );
          }
        }
        return data.Model;
      },
      err => {
        if (err.status !== 200) {
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
        }
      }
    );
  },

  checkApprovalTaskName(taskCode) {
    return i18n.ClaimCommon.ApprovalTaskCodeToName[taskCode];
  },

  onClose() {
    window.close();
  },
  updateTaskWithUserName(tmpparam) {
    const param = tmpparam;
    let status = null;
    delete param.warnMessage;
    TaskTransferService.updateTaskWithUserName(param).then(
      data => {
        status = data.Status;
        if (status === 'ERROR')
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
      },
      err => {
        if (err.status !== 200) {
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
        }
      }
    );
    return status;
  },
  updateTask() {
    let status = null;

    const changedData = DataContext.get('ChangeData');

    TaskTransferService.updateTask(changedData).then(
      data => {
        status = data.Status;

        if (status === 'ERROR') {
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
        }
      },
      err => {
        if (err.status !== 200) {
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
        }
      }
    );
    return status;
  },
  AssignmentApprochCode(param) {
    let code = null;
    TaskTransferService.AssignmentApprochCode(param).then(
      data => {
        code = data.Model;
        if (data.Status === 'ERROR')
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
        if (data.Status === 'WARN') {
          code = 'WARN';
        }
      },
      err => {
        if (err.status !== 200) {
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
        }
      }
    );
    return code;
  },
  UserListByAuthoryCode(authoryCode) {
    let result = null;
    TaskTransferService.UserListByAuthoryCode(authoryCode).then(
      data => {
        result = data;
        if (data.Status === 'ERROR')
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
      },
      err => {
        if (err.status !== 200) {
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
        }
      }
    );
    return new CodeTable(
      _.sortBy(result.Model, n => {
        return n.text;
      })
    );
  },

  TaskAuthoryUserList() {
    let result = null;
    TaskTransferService.TaskAuthoryUserList().then(
      data => {
        result = data;
        if (data.Status === 'ERROR')
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
      },
      err => {
        if (err.status !== 200) {
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
        }
      }
    );
    return new CodeTable(
      _.sortBy(result.Model, n => {
        return n.text;
      })
    );
  },
  UserListByAuthoryCodeCaseId(authoryCode, caseId, taskInstanceId) {
    let result = null;
    TaskTransferService.UserListByAuthoryCodeCaseId(
      authoryCode,
      caseId,
      taskInstanceId
    ).then(
      data => {
        result = data;
        if (data.Status === 'ERROR')
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
      },
      err => {
        if (err.status !== 200) {
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
        }
      }
    );
    return new CodeTable(
      _.sortBy(result.Model, n => {
        return n.text;
      })
    );
  },
  UserListWithPoolSystem(authoryCode, caseId) {
    let result = null;

    TaskTransferService.UserListWithPoolSystem(
      authoryCode,
      caseId
    ).then(
      data => {
        result = data;

        if (data.Status === 'ERROR')
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
      },
      err => {
        if (err.status !== 200) {
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
        }
      }
    );
    return new CodeTable(
      _.sortBy(result.Model, n => {
        return n.text;
      })
    );
  },
  getSubClaimNoByCaseId(caseId) {
    let result = null;
    TaskTransferService.getSubClaimNoByCaseId(caseId).then(
      data => {
        if (data.Status === 'OK') result = data;
      },
      err => {
        if (err.status !== 200) {
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
        }
      }
    );
    return result.Model;
  },
  saveChangeData(event) {
    const data = event.getParameter('data');
    data.AssignTo = event.newValue;

    let list = DataContext.get('ChangeData');
    if (!list) {
      list = [];
    }

    const assignData = {
      TaskInstanceId: data.id,
      AssignTo: data.AssignTo,
    };

    list.push(assignData);
    DataContext.put('ChangeData', list);
  },

  saveChangeDataByCurrent(datas, userId) {
    const data = datas;
    if (userId != null && userId !== '') {
      data.AssignTo = userId;
    } else if (data.TaskAssignee == data.currentUserId) {
      data.AssignTo = data.TaskAssignee;
    }

    let list = DataContext.get('ChangeData');
    if (!list) {
      list = [];
    }

    const assignData = {
      TaskInstanceId: data.id,
      AssignTo: data.AssignTo,
    };

    list.push(assignData);
    DataContext.put('ChangeData', list);
  },
};
