import { UIDialog } from 'RainbowUI';
import ClaimCommonExt from 'ClaimCommonExt';
import React from 'react';
import BindToMixin from 'react-binding';

const DamageItemContact = React.createClass({
  mixins: [BindToMixin],
  hide() {
    UIDialog.hide('newClaimantDialog');
  },
  submit(partVO) {
    this.props.parentFunction(partVO);
  },
  addOrUpdateParty(tmpresult) {
    const result = tmpresult;
    if (result.claimParty) {
      result.commonParty.ClmPtyType = result.claimParty.ClmPtyType;
    }
    this.props.parentFunction(result.commonParty);
  },

  copyClaimPartyInfo(fromData, tmptoData) {
    const toData = tmptoData;
    toData.PartyName = fromData.PartyName;
    toData.PtyAccountId = fromData.PtyAccountId;
    toData.PtyAddressId = fromData.PtyAddressId;
  },
  render() {
    const ComponetComonParty = ClaimCommonExt.getClaimPartyAddOrEditPartyDialog();
    return (
      <ComponetComonParty
        showRadio="true"
        ref="ClaimPartyRightPanel_Ref"
        index="110"
        fromWhere="claimant"
        parentComponent={this}
      />
    );
  },
});
export default DamageItemContact;
