export default {
  Salvage: 'Salvage',
  RecoverFrom: 'Recover From',
  SalvageAmount: 'Salvage Amount',
  RemarkForSalvage: 'Remark for Salvage',
  SelectCoverage: 'Please select Coverage for Salvage',
  PleaseSelect: 'Please Select',
  Selected: ' Selected',
  ApprovalHistory: 'Approval History',
  SalvageHistory: 'Salvage History',
  SeqNo: 'Seq No.',
  TransactionDate: 'Transaction Date',
  OperatedBy: 'Operated By',
  TotalSalvageAmount: 'Total Salvage Amount',
  Remark: 'Remark',
  AtleastOneItem: 'At least one subclaim need to be added',
  SameReserveTypeError:
    'You cannot  create 2 records with same subclaim and same Reserve Type',
  SalvageSaveSuccess: 'Salvage is saved successfully',
  AddItem: 'Add Item',
  SalvageHistoryDetail: 'Salvage History Detail',
  SalvageSubmitSuccess:
    'All salvage tasks need to be sent for senior approval ',
  NoItem: 'Please add Salvage Item',
  SalvageApprove: 'Salvage Approval',
  SalvagePanelTitle: 'Salvage Detail',
  TotalAmount: 'Total Amount',
  ApprovedSalvageSuccess: 'Salvage is approved successfully',
  RejectedSalvageSuccess: 'Salvage is rejected successfully',

  ErrorMsg: {
    Error_0001: 'Fail',
    Error_0002: 'failed',
  },
  ConfirmMsg: {},
  WarnMsg: {},
  SuccessMsg: {},
  InfoMsg: {},
};
