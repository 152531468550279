import {
  UIBox,
  UIButton,
  UISmartPanelGrid,
  UISelect,
  UINumber,
  DataContext,
  UIText,
  UIParam,
  UITextarea,
  UILabel,
  UpdateContext,
  CodeTable,
  CodeTableSorter,
  UICell,
} from 'RainbowUI';
import BindToMixin from 'react-binding';
import PropTypes from 'prop-types';
import { SelectPayeeDialog } from 'ClaimCommon';

import {
  CodeTableAction,
  CodeTableCstUI,
  ClaimCstUI,
} from 'ClaimCommonConstant';
import ClaimCommonExt from 'ClaimCommonExt';
import { PartyAction } from 'EU00PartyCommonUI';

import ClaimSettlement from 'ClaimSettlement';

const ClaimPartyAction = ClaimCommonExt.getClaimPartyAction();

const SettlementLoadAction = ClaimSettlement.getSettlementLoadAction();

const mockCodeTable = require('../../common/mock/CodeTable');

const RefundPayeeInfo = React.createClass({
  mixins: [BindToMixin],

  propTypes: {
    index: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
    settlementPayee: PropTypes.object.isRequired,
    caseId: PropTypes.string.isRequired,
  },
  getInitialState() {
    const payeeTypes = [];
    if (this.props.settlementPayee.PayeeId) {
      const claimParty = ClaimPartyAction.loadClaimParty(
        this.props.settlementPayee.PayeeId
      );
      const partyId = claimParty.Model.PtyPartyId;
      const partyAction = new PartyAction();
      const party = partyAction.loadPartyByPartyId(partyId);
      if (party && party.PartyRoleList) {
        for (let i = 0; i < party.PartyRoleList.length; i += 1) {
          payeeTypes.push({
            id: party.PartyRoleList[i].RoleType,
            text: party.PartyRoleList[i].RoleName,
          });
        }
      }
    }
    const payeeTypeCodeTable = new CodeTable(
      payeeTypes,
      null,
      new CodeTableSorter()
    );
    const $state = {
      roleType: '',
      payeeTypes: payeeTypeCodeTable,
    };
    return $state;
  },
  render() {
    const _self = this;
    const suffixId = `settlementPayeeInfo_${_self.props.readOnly}_${_self.props.index}_`;
    const payee = _self.props.settlementPayee;
    const settlementPayeeNamePanel = this.settlementPayeeNamePanel();
    return (
      <div>
        <UISmartPanelGrid id={`${suffixId}payeePanel`} column="3">
          {settlementPayeeNamePanel}
          <div
            id={`${suffixId}deletePayeeDiv`}
            style={{ display: _self.getDisplayStyle() }}
          >
            <UIBox direction="left">
              <UIButton
                id={`${suffixId}removePayee`}
                value={i18n.ClaimSettlement.DeleteRefundPayer}
                onClick={_self.removePayee}
              >
                <UIParam
                  name="removeIndex"
                  value={_self.props.index}
                />
              </UIButton>
            </UIBox>
          </div>
          <UICell />

          <UISelect
            id={`${suffixId}payMode`}
            label={i18n.ClaimSettlement.PayMode}
            model={payee}
            property="PayMode"
            codeTable={
              env.IS_MOCK == true
                ? mockCodeTable.payMode
                : _self.filter4PayMode(
                    CodeTableAction.loadDataList(
                      CodeTableCstUI.PlateCommonFormPayMode,
                      null
                    )
                  )
            }
            io={_self.getIOStyle()}
            required="true"
            validationGroup="submit_validate"
          />
          <UISelect
            id={`${suffixId}currency`}
            label={i18n.ClaimCommon.Currency}
            model={payee}
            property="SettleCurrency"
            codeTable={CodeTableAction.loadDataList(
              CodeTableCstUI.ClaimCurrencyCode,
              null
            )}
            onChange={SettlementLoadAction.changeCurrency}
            required="true"
            validationGroup="submit_validate"
            io={_self.getIOStyle()}
          >
            <UIParam name="payeeIndex" value={_self.props.index} />
            <UIParam name="caseId" value={_self.props.caseId} />
          </UISelect>
          <UINumber
            id={`NUMBER_CODE_${suffixId}exchangeRate`}
            label={i18n.ClaimSettlement.ExchangeRate}
            model={payee}
            property="ReserveExchangeRate"
            io="out"
            format="###,###,###.######"
          />
          <UITextarea
            id={`${suffixId}remark`}
            label={i18n.ClaimCommon.Remark}
            model={payee}
            property="Remark"
            colspan="3"
            io={_self.getIOStyle()}
          />
          <UILabel id={`${suffixId}label`} label="" />
        </UISmartPanelGrid>
        {this.getSelectPayeeDialog()}
      </div>
    );
  },
  getPayeeTypes() {
    if (this.state.payeeTypes.codes.length === 0) {
      return this.props.settlementPayee.PayeeTypeCodeTable;
    }
    return this.state.payeeTypes;
  },
  removePayee(event) {
    SettlementLoadAction.removePayee(
      event.getParameter('removeIndex')
    );
  },
  settlementPayeeNamePanel() {
    const _self = this;
    const nameList = DataContext.get(
      `settlementPayeeName${this.props.index}`
    );
    const roleType = DataContext.get(`roleType${this.props.index}`);
    this.state.roleType = roleType;
    const suffixId = this.props.index;
    const payee = this.props.settlementPayee;
    let actionColumn = (
      <div>
        <UIText
          id={`${suffixId}payeeName`}
          label={i18n.ClaimSettlement.RefundFrom}
          model={payee}
          property="PayeeName"
          colspan="2"
          suffixIcon="fa fa-search"
          onSuffixIconClick={SettlementLoadAction.selectPayee}
          required="true"
          validationGroup="submit_validate"
          io={_self.getIOStyle()}
          enabled="false"
        >
          <UIParam name="payeeIndex" value={_self.props.index} />
          <UIParam name="roleType" value={roleType} />
        </UIText>
      </div>
    );
    if (nameList) {
      actionColumn = (
        <div>
          <UISelect
            id={`${suffixId}payeeName`}
            label={i18n.ClaimSettlement.RefundFrom}
            model={payee}
            property="PayeeId"
            codeTable={nameList}
            io={this.getIOStyle()}
            required="true"
          >
            <UIParam name="payeeNameIndex" value={suffixId} />
          </UISelect>
        </div>
      );
    }
    return actionColumn;
  },
  getIOStyle() {
    const { coInsurance, readOnly } = this.props;
    if (readOnly == true) {
      return 'out';
    }
    if (
      coInsurance &&
      coInsurance.CoinFlag == ClaimCstUI.BooleanConstant.TRUE &&
      coInsurance.CoinLeaderFlag ==
        ClaimCstUI.BooleanConstant.FALSE &&
      coInsurance.Selected == ClaimCstUI.CoFollower.ToCoLeader
    ) {
      return 'out';
    }
    return null;
  },

  getDisplayStyle() {
    return this.props.readOnly == true ? 'none' : '';
  },

  callback(index, payee, roleList) {
    if (payee) {
      SettlementLoadAction.callbackPayee(index, payee);
    }
    if (roleList) {
      const payerTypeCode = [];
      for (let i = 0; i < roleList.length; i += 1) {
        payerTypeCode.push({
          id: roleList[i].RoleType,
          text: roleList[i].RoleName,
        });
      }
      this.state.payeeTypes = new CodeTable(
        payerTypeCode,
        null,
        new CodeTableSorter()
      );
      const suffixId =
        `settlementPayeeInfo_${this.props.readOnly}_${this.props.index}_` +
        `payeeType`;
      UpdateContext.forceUpdate(suffixId);
    }

    if (payee.PaymentMethodId) {
      this.props.settlementPayee.PayMode = payee.PaymentMethodId;
    }
    this.forceUpdate();
  },
  getComponent() {
    return this;
  },
  getSelectPayeeDialog() {
    const _self = this;
    const componentId = _self.props.componentId || '';
    return (
      <SelectPayeeDialog
        index={_self.props.index}
        caseId={_self.props.caseId}
        roleType={_self.state.roleType}
        callback={_self.callback}
        componentId={componentId}
        fromWhere="payer"
      />
    );
  },
  filter4PayMode(data) {
    const temp = [];
    if (data) {
      for (const code in data.codes) {
        if (
          data.codes[code].id === '100' ||
          data.codes[code].id === '101' ||
          data.codes[code].id === '106'
        ) {
          temp.push(data.codes[code]);
        } else {
          /* empty */
        }
      }
    }
    return new CodeTable(temp);
  },
});
export default RefundPayeeInfo;
