import { UIText } from 'RainbowUI';

const SettlementInfo = React.createClass({
  render() {
    return (
      <UIText
        id="SettlementInfo1"
        label={i18n.ClaimCommon.Settlement}
        io="out"
        value="settlement info"
      />
    );
  },
});
export default SettlementInfo;
