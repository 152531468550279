import {
  AjaxUtil,
  DataContext,
  UIPickList,
  UpdateContext,
} from 'RainbowUI';
import CoverageCategoryRelationService from '../service/CoverageCategoryRelationService';
import TreeNode from '../constant/TreeNodeCache';

const Url = require('../url/Url');

const ProductCfgTreeAction = require('./ProductCfgTreeAction');

export default {
  getSubclaimTypeCoverageCategoryRelation(pageindex, pagesize) {
    const data = TreeNode.TreeCache.get('TreeNode');
    const productLineCodeJS = {
      productLineCode: data.treeNodeCode,
    };
    const searchCondition = {};
    searchCondition.ProductLineCode =
      productLineCodeJS.productLineCode;
    searchCondition.PageNo = pageindex - 1;
    searchCondition.PageSize = pagesize;
    const url =
      Url.ClaimProductRelationOfSubclaimTypeCoverageCategoryBySubclaimTypeCode;
    if (
      DataContext.get(
        'searchSubclaimTypeandCoverageCategoryCondition'
      )
    ) {
      const condition = DataContext.get(
        'searchSubclaimTypeandCoverageCategoryCondition'
      );
      if (condition.subclaimTypeCode) {
        searchCondition.SubclaimTypeCode = condition.subclaimTypeCode
          .replace(/(^\s*)|(\s*$)/g, '')
          .toUpperCase();
      }
    }
    let result = {};
    AjaxUtil.doPost(url, searchCondition, {
      async: false,
      done(data) {
        result = data.Model;
      },
      fail() {
        MessageHelper.error(
          i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
        );
      },
    });

    return { count: result.Total, result: result.Content };
  },
  getPickList(subclaimTypeCode) {
    const data = CoverageCategoryRelationService.loadRelation(
      subclaimTypeCode
    );
    const pickList = [];
    let pickListItem = {};
    const selectList = data.SelectedList;
    const noSelectList = data.NoSelectedList;
    for (const item in selectList) {
      pickListItem = {
        value: selectList[item].Desc.CoverageTypeCode,
        label: `${selectList[item].Desc.CoverageTypeCode}:${selectList[item].Desc.CoverageTypeName}`,
        selected: true,
      };
      pickList.push(pickListItem);
    }
    for (const item in noSelectList) {
      pickListItem = {
        value: noSelectList[item].CoverageTypeCode,
        label: `${noSelectList[item].CoverageTypeCode}:${noSelectList[item].CoverageTypeName}`,
        selected: false,
      };
      pickList.push(pickListItem);
    }
    return pickList;
  },
  openCoverageCategoryRelation(event) {
    const subclaimTypeCode = event.getParameter('subclaimTypeCode');
    const ProductLineCode = event.getParameter('productLineCode');
    const subclaimTypeName = event.getParameter('subclaimTypeName');
    const nodeName = event.getParameter('nodeName');
    const entity = {
      treeNodeCode: ProductLineCode,
      subclaimTypeCode,
      subclaimTypeName,
      treeNodeUrl: 'subclaimTypeandCoverageCategoryRelation',
      name: nodeName,
    };

    ProductCfgTreeAction.loadNode(event, entity.treeId, entity);
  },
  saveSubclaimCoverageCategory(event) {
    const entity = event.getParameter('entity');

    const { subclaimTypeCode } = entity;
    const productLineCode = entity.treeNodeCode;
    const selectList = UIPickList.getTargetList(
      'CoverageCategoryRelationPickList'
    );

    const saveList = [];
    let saveObj = {};

    for (const item in selectList) {
      saveObj = {
        ProductLineCode: productLineCode,
        SubclaimTypeCode: subclaimTypeCode,
        CoverageTypeCode: selectList[item],
      };
      saveList.push(saveObj);
    }
    const claimRelationData = { ClaimProLossRelationList: saveList };

    CoverageCategoryRelationService.updateCoverageCategory(
      claimRelationData,
      subclaimTypeCode
    );

    entity.treeNodeUrl = 'subclaimTypeandCoverageCategory';
    ProductCfgTreeAction.loadNode(event, entity.treeId, entity);
  },
  cancelCoverageCategory(event) {
    const entity = event.getParameter('entity');

    entity.treeNodeUrl = 'subclaimTypeandCoverageCategory';
    ProductCfgTreeAction.loadNode(event, entity.treeId, entity);
  },

  searchSubclaimTypeandCoverageCategoryList(condition) {
    DataContext.put(
      'searchSubclaimTypeandCoverageCategoryCondition',
      condition
    );
    UpdateContext.forceUpdate(
      'coverageCategoryRelationListSearchResultProvider'
    );
  },
};
