export default {
  CalculationData: {
    CoverageTree: [
      {
        id: '2001',
        name: 'G111111 Audi A6',
        open: true,
        pId: '0',
        checked: false,
      },
      {
        id: 'CT_7283',
        name: 'Medical Expense',
        open: true,
        pId: '2001',
        checked: false,
      },
      {
        id: '2002',
        name: 'G324599 Audi A8',
        open: true,
        pId: '0',
        checked: true,
      },
      {
        id: 'CT_7283',
        name: 'Medical Expense',
        open: true,
        pId: '2002',
        checked: true,
      },
      {
        id: 'CT_7284',
        name: 'Towing Fee',
        open: true,
        pId: '2002',
        checked: true,
      },
    ],
    CoverageStructure: [
      {
        InsuredId: '2001',
        InsuredName: 'G111111',
        CoverageList: [
          {
            CoverageCode: 'CT_7283',
            CoverageName: 'Medical Expense',
          },
        ],
      },
      {
        InsuredId: '2002',
        InsuredName: 'G324599',
        CoverageList: [
          { CoverageCode: 'CT_7283', CoverageName: 'Medical' },
          { CoverageCode: 'CT_7284', CoverageName: 'Towing Fee' },
        ],
      },
    ],
    CalculationStructure: [
      {
        InsuredId: '2001',
        CoverageCode: 'CT_7283',
        ExistUnit: 'Y',
        SubclaimCodeTable: [{ id: 3001, text: '001_Lili' }],
        ReserveTree: [
          { id: '3001', name: '001_Lili', open: true, pId: '0' },
          {
            id: 'r001',
            name: 'Loss Expense',
            open: true,
            pId: '1001',
          },
          { id: 'r002', name: 'ALAE', open: true, pId: '1001' },
        ],
      },
      {
        InsuredId: '2002',
        CoverageCode: 'CT_7283',
        ExistUnit: 'Y',
        SubclaimCodeTable: [{ id: 3002, text: '002_Cathy' }],
        ReserveTree: [
          { id: '1002', name: '002_Cathy', open: true, pId: '0' },
          {
            id: 'r003',
            name: 'Loss Expense',
            open: true,
            pId: '1002',
          },
        ],
      },
      {
        InsuredId: '2002',
        CoverageCode: 'CT_7284',
        ExistUnit: 'N',
        SubclaimCodeTable: [
          { id: 3003, text: '002_Cathy' },
          { id: 3004, text: '001_Cathy' },
        ],
        ReserveTree: [
          { id: '1002', name: '002_Cathy', open: true, pId: '0' },
          {
            id: 'r004',
            name: 'Loss Expense',
            open: true,
            pId: '1002',
          },
          { id: 'r005', name: 'Loss 5', open: true, pId: '1002' },
        ],
      },
    ],

    ReserveCodeTable: [
      {
        id: 'r001',
        text: 'Loss (Expenses)',
      },
      {
        id: 'r002',
        text: 'ALAE',
      },
      {
        id: 'r003',
        text: 'Loss (Expenses)',
      },
      {
        id: 'r004',
        text: 'Loss (Expenses)',
      },
    ],
    ReserveStructure: [
      {
        CaseId: 15869363,
        InsuredId: 2001,
        CoverageCode: 'CT_7283',
        CoverageName: 'Medical Expense',
        CoverageTypeCode: '7894',
        CurrencyCode: 'CNY',
        ItemId: 3001,
        ObjectId: 1001,
        OutstandingAmount: 100.0,
        SettleAmount: 123,
        ReserveSign: 1,
        ReserveId: 'r001',
        ReserveName: 'Loss (Expense)',
        ReserveType: 'RC_02',
        SeqNo: '001',
        StatusCode: 'REOPEN',
        SubclaimType: '1_ST01',
        ClaimTypeCode: 'LOS', // ZLE
        ExpenseFlag: 'N', // ZLE
      },
      {
        CaseId: 15869363,
        InsuredId: 2001,
        CoverageCode: 'CT_7283',
        CoverageName: 'Medical Expense',
        CoverageTypeCode: '7894',
        CurrencyCode: 'CNY',
        ItemId: 3001,
        ObjectId: 1001,
        OutstandingAmount: 100.0,
        SettleAmount: 123,
        ReserveSign: 1,
        ReserveId: 'r002',
        ReserveName: 'ALAE',
        ReserveType: 'RC_03',
        SeqNo: '001',
        StatusCode: 'REOPEN',
        SubclaimType: '1_ST01',
        ClaimTypeCode: 'LOS',
        ExpenseFlag: 'Y',
      },
      {
        CaseId: 15869363,
        InsuredId: 2001,
        CoverageCode: 'CT_7283',
        CoverageName: 'Medical Expense',
        CoverageTypeCode: '7894',
        CurrencyCode: 'CNY',
        ItemId: 3002,
        ObjectId: 1002,
        OutstandingAmount: 100,
        SettleAmount: 123,
        ReserveSign: 1,
        ReserveId: 'rrr',
        ReserveName: 'Loss (Indemnity)',
        ReserveType: 'RC_01',
        SeqNo: '001',
        StatusCode: 'REOPEN',
        SubclaimType: '1_ST01',
        ClaimTypeCode: 'LOS',
        ExpenseFlag: 'N',
      },
      {
        CaseId: 15869363,
        InsuredId: 2001,
        CoverageCode: 'CT_7283',
        CoverageName: 'Medical Expense',
        CoverageTypeCode: '7894',
        CurrencyCode: 'CNY',
        ItemId: 3003,
        ObjectId: 1002,
        OutstandingAmount: 100,
        SettleAmount: 10086,
        ReserveSign: 1,
        ReserveId: 'rrr',
        ReserveName: 'Loss (Indemnity)',
        ReserveType: 'RC_01',
        SeqNo: '001',
        StatusCode: 'REOPEN',
        SubclaimType: '1_ST01',
        ClaimTypeCode: 'LOS',
        ExpenseFlag: 'N',
      },
      {
        CaseId: 15869363,
        InsuredId: 2002,
        CoverageCode: 'CT_7284',
        CoverageName: 'Medical Expense',
        CoverageTypeCode: '7894',
        CurrencyCode: 'CNY',
        ItemId: 3004,
        ObjectId: 1002,
        OutstandingAmount: 100,
        SettleAmount: 12306,
        CalculatedAmount: 10010,
        ReserveSign: 1,
        ReserveId: 'r004',
        ReserveName: 'Loss (Indemnity)',
        ReserveType: 'RC_01',
        SeqNo: '001',
        StatusCode: 'REOPEN',
        SubclaimType: '1_ST01',
        ClaimTypeCode: 'LOS',
        ExpenseFlag: 'N',
      },
      {
        CaseId: 15869363,
        InsuredId: 2002,
        CoverageCode: 'CT_7284',
        CoverageName: 'Medical Expense',
        CoverageTypeCode: '7894',
        CurrencyCode: 'CNY',
        ItemId: 3004,
        ObjectId: 1002,
        OutstandingAmount: 500,
        SettleAmount: 12306,
        CalculatedAmount: 10010,
        ReserveSign: 1,
        SettledAmount: 55,
        ReserveId: 'r005',
        ReserveName: 'Loss (Indemnity5)',
        ReserveType: 'RC_01',
        SeqNo: '001',
        StatusCode: 'REOPEN',
        SubclaimType: '1_ST01',
        ClaimTypeCode: 'LOS',
        ExpenseFlag: 'N',
      },
    ],

    CalculationInfo: {
      CaseId: '15869363',
      '@pk': 25629363,
      CalculateId: 25629363,
      CaseCurrency: 'USD',
      CalculationItem: [
        {
          CalItemId: 1,
          ItemId: 3002,
          AppraisalAmount: 100.0,
          CalculatedAmount: 200.0,
          LossUnitValue: 1, // Number of Unit
          IndemnityAmount: 200.0, // AdjustedAmount
          ReserveAmount: 100,
          SettledAmount: 10,
          CurrencyCode: 'USD',
          // -----start
          InsuredId: 2002,
          CoverageCode: 'CT_7283',
          Index: 0,
          ReserveCurrency: 'USD',
          Deleted: false,
          // "ExistCalExpense":"true",
          // -----end
        },
        {
          CalItemId: 1,
          ItemId: 3003,
          AppraisalAmount: 100.0,
          CalculatedAmount: 200.0,
          LossUnitValue: 1, // Number of Unit
          IndemnityAmount: 200.0, // AdjustedAmount
          ReserveAmount: 300,
          SettledAmount: 30,
          CurrencyCode: 'USD',
          // -----start
          InsuredId: 2002,
          CoverageCode: 'CT_7284',
          Index: 1,
          ReserveCurrency: 'USD',
          Deleted: false,
          // "ExistCalExpense":"true",
          // -----end
        },
      ],

      CalculationExpense: [
        {
          ExpenseId: '9',
          CalItemId: '1',
          ReserveType: 'RC_01',
          ExpenseAmount: 100,
          ReserveAmount: 100.0,
          SettledAmount: 10.0,
          //--
          ReserveId: 'r001',
          ReserveCurrency: 'USD',
          //---
        },
        {
          CaseId: 15869363,
          InsuredId: 2002,
          CoverageCode: 'CT_7284',
          CoverageName: 'Medical Expense',
          CoverageTypeCode: '7894',
          CurrencyCode: 'CNY',
          ItemId: 3004,
          ObjectId: 1002,
          OutstandingAmount: 100,
          SettleAmount: 12306,
          CalculatedAmount: 10010,
          ReserveSign: 1,
          ReserveId: 'r004',
          ReserveName: 'Loss (Indemnity)',
          ReserveType: 'RC_01',
          SeqNo: '001',
          StatusCode: 'REOPEN',
          SubclaimType: '1_ST01',
          ClaimTypeCode: 'LOS',
          ExpenseFlag: 'N',
        },
      ],
    },
  },

  CalculationHistory: [
    {
      '@pk': 23365363,
      '@type': 'ClaimCalculation-ClaimCalculation',
      BusinessObjectId: 989122,
      CalculateId: 23365363,
      CaseCurrency: 'USD',
      CalculationExpense: [
        {
          '@pk': 23366363,
          '@type': 'ClaimCalculationExpense-ClaimCalculationExpense',
          BusinessObjectId: 989172,
          CalExpenseId: 23366363,
          ExpenseAmount: 30,
          ItemId: 18045401,
          ReserveAmount: 100,
          ReserveType: 'RC_02',
          SettledAmount: 20,
        },
      ],
      CalculationItem: [
        {
          '@pk': 23367363,
          '@type': 'ClaimCalculationItem-ClaimCalculationItem',
          AppraisalAmount: 10,
          BusinessObjectId: 989137,
          CalItemId: 23367363,
          CalculatedAmount: 100,
          IndemnityAmount: 100,
          ItemId: 18045401,
          ReserveAmount: 100,
          SettledAmount: 200,
        },
      ],
      CaseId: 17308383,
      Remark: 'Update ',
      Status: 'CLOSED',
      TotalExpenseAmount: 30,
      TotalIndemnityAmount: 100,
      Submitter: -11,
      SubmitDate: '2016-01-21T12:51:07.832',
      ApproveDate: '2016-01-25T15:19:05.983',
      Approver: -11,
    },
    {
      '@pk': 23265363,
      '@type': 'ClaimCalculation-ClaimCalculation',
      BusinessObjectId: 989122,
      CalculateId: 23265363,
      CaseCurrency: 'USD',
      CalculationExpense: [
        {
          '@pk': 23266363,
          '@type': 'ClaimCalculationExpense-ClaimCalculationExpense',
          BusinessObjectId: 989172,
          CalExpenseId: 23266363,
          CurrencyCode: 'USD',
          ExpenseAmount: 30,
          ItemId: 18045401,
          ReserveAmount: 100,
          ReserveType: 'RC_02',
          SettledAmount: 20,
        },
      ],
      CalculationItem: [
        {
          '@pk': 23267363,
          '@type': 'ClaimCalculationItem-ClaimCalculationItem',
          AppraisalAmount: 10,
          BusinessObjectId: 989137,
          CalItemId: 23267363,
          CalculatedAmount: 100,
          CurrencyCode: 'USD',
          IndemnityAmount: 100,
          ItemId: 18045401,
          ReserveAmount: 100,
          SettledAmount: 200,
        },
      ],
      CaseId: 17308383,
      Remark: 'Update Calculation 2',
      Status: 'CLOSED',
      TotalExpenseAmount: 30,
      TotalIndemnityAmount: 100,
      Submitter: -11,
      SubmitDate: '2016-01-21T12:51:07.832',
      ApproveDate: '2016-01-25T15:19:05.983',
      Approver: -11,
    },
  ],

  LimitAndDeductInformation: [
    {
      Name: 'PolicyInfo',
      Info: [
        { Name: 'Limit Mode', Value: 'Limit Amount Per Period:5000' },
        {
          Name: 'Deduct Mofe',
          Value: 'Deduct Amount Per Period:100',
        },
      ],
    },
    {
      Name: 'PolicyInfo',
      Info: [
        { Name: 'Limit Mode', Value: 'Limit Amount Per Period:5000' },
        {
          Name: 'Deduct Mofe',
          Value: 'Deduct Amount Per Period:100',
        },
      ],
    },
  ],
};
