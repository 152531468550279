import {
  UIDataTable,
  UIColumn,
  UIText,
  UISelect,
  DataContext,
  UICurrency,
} from 'RainbowUI';
import TreatyCurrencyDataTableRate from './TreatyCurrencyDataTableRate';
import RiAction from '../../../claimparty/action/RiAction';

const TreatyCurrencyData = React.createClass({
  getInitialState() {
    return {
      childMethod: null,
      selectedData: [],
      list: this.props.list,
    };
  },

  componentWillMount() {},

  componentDidMount() {},

  render() {
    const self = this;
    return (
      <div>
        {self.renderCurrencyDataTable()}
        <TreatyCurrencyDataTableRate
          XOLNum={self.props.XOLNum}
          getChildMethod={this.getChildMethod.bind(this)}
        />
      </div>
    );
  },

  renderCurrencyDataTable() {
    const self = this;
    const { list } = self.state;
    if (list[0]) {
      DataContext.put('RiskUnitNo', list[0].RiskUnitNo);
    } else {
      DataContext.put('RiskUnitNo', null);
    }
    return (
      <UIDataTable
        id="TreatyCurrencyData"
        pageable="true"
        indexable="false"
        value={list}
        selectionMode="single"
        onRowSelect={self.selectRiskUnitData.bind(this)}
        selectState={[0]}
      >
        <UIColumn
          headerTitle={i18n.ClaimCommon.RiskUnitName}
          render={data => {
            return (
              <div>
                <UIText value={data.RiskUnitNo} io="out" />
              </div>
            );
          }}
        />
        <UIColumn
          headerTitle={i18n.ClaimCommon.RiskCategory}
          value="RiskCategory"
        >
          <UISelect
            io="out"
            codeTable={RiAction.getRiskCategoryCache()}
          />
        </UIColumn>
        <UIColumn
          headerTitle={i18n.ClaimCommon.RiskLevel}
          value="RiskLevel"
        >
          <UISelect
            codeTable={RiAction.getRiskLevelCache()}
            io="out"
          />
        </UIColumn>
        <UIColumn
          headerTitle={i18n.ClaimCommon.OSReserve}
          width="auto"
          render={data => {
            return (
              <div style={{ float: 'left' }}>
                <UICurrency
                  value={data.Reserve}
                  io="out"
                  unit={window.EU00.getCurrencySignByCurrencyCode(
                    data.CurrencyCode
                  )}
                />
              </div>
            );
          }}
        />
        <UIColumn
          headerTitle={i18n.ClaimCommon.SettledAmount}
          width="auto"
          render={data => {
            return (
              <div style={{ float: 'left' }}>
                <UICurrency
                  value={data.PaymentAmount}
                  io="out"
                  unit={window.EU00.getCurrencySignByCurrencyCode(
                    data.CurrencyCode
                  )}
                />
              </div>
            );
          }}
        />
        <UIColumn
          headerTitle={i18n.ClaimCommon.IncurredAmount}
          width="auto"
          render={data => {
            return (
              <div style={{ float: 'left' }}>
                <UICurrency
                  value={data.totalAmount}
                  io="out"
                  unit={window.EU00.getCurrencySignByCurrencyCode(
                    data.CurrencyCode
                  )}
                />
              </div>
            );
          }}
        />
      </UIDataTable>
    );
  },

  selectRiskUnitData() {
    const self = this;
    let polCoverageIdsArray = [];
    const selectedData = UIDataTable.getSelectedRecord(
      'TreatyCurrencyData'
    )[0];
    if (selectedData && selectedData.CedingPolicyCoverageIds) {
      polCoverageIdsArray = selectedData.CedingPolicyCoverageIds.split(
        ' '
      );
    }
    const settlementDetailDatas = RiAction.generateSettlementDetailDatas(
      polCoverageIdsArray,
      self.props.claimEntity.CaseId
    );
    let commonTreatyArray = RiAction.generateShareRateArray(
      selectedData
    );
    // RiAction.generateFinalShareRateArray(commonTreatyArray,selectedData,settlementDetailDatas);
    // let commonTreatyArrayCopy=RiAction.generateShareRateArray(selectedData);
    const shareRateTableByClaim = RiAction.generateCurrencyRateData(
      commonTreatyArray,
      selectedData,
      settlementDetailDatas
    );
    if (settlementDetailDatas.length > 0) {
      const results = RiAction.generateSettlementDetailRateArray(
        commonTreatyArray,
        settlementDetailDatas
      );
      commonTreatyArray = RiAction.getTreatySourceList(
        results,
        settlementDetailDatas
      );
    }
    DataContext.put('SettlementShareRateTable', commonTreatyArray);
    DataContext.put('RiskUnitNo', selectedData.RiskUnitNo);
    DataContext.put('ShareRateTableByClaim', shareRateTableByClaim);
    this.state.childMethod(commonTreatyArray);
    this.props.renderSettleDatas(
      settlementDetailDatas,
      commonTreatyArray
    );
  },

  getChildMethod(childMethod) {
    this.state.childMethod = childMethod;
  },
});
export default TreatyCurrencyData;
