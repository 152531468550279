const home = env.serverUrl;
const urlDefine = {
  CalculationTaskLoad: `${home}calculation/load/task/`,
  CalculationReadOnlyLoad: `${home}calculation/load/`,
  CalculationHistoryLoad: `${home}calculation/load/history/`,
  LimitAndDeductionInfo: `${home}calculation/load/limitAndDeduct/`,

  CalculationApproveLoad: `${home}common/approve/load/`,

  CalculationSave: `${home}calculation/save/`,
  CalculationSubmit: `${home}calculation/submit/`,
  CalculationApprove: `${home}calculation/approve/`,
  CalculationCalculate: `${home}calculation/calculate`,
};
export default urlDefine;
