import {
  DataContext,
  UpdateContext,
  MessageHelper,
  UIDialog,
  AjaxUtil,
} from 'RainbowUI';
import {
  RestStatus,
  ClaimMessageForamtUtils,
} from 'ClaimCommonConstant';
import alt from '../alt';

const url = require('../url/Url');
const ProductTypeService = require('../service/ProductTypeService');

class ProductTypeAction {
  saveProducttype(ProductTypeEntity) {
    ProductTypeService.saveProductType(ProductTypeEntity).then(
      ProductTypeEntity => {
        if (ProductTypeEntity.Status == RestStatus.OK) {
          this.dispatch(ProductTypeEntity.Model);
          UIDialog.hide('showProductTypeDialog');
          MessageHelper.success(
            ClaimMessageForamtUtils.formatI18NMsg(
              i18n.ClaimCommon.SaveSuccess,
              i18n.ClaimProduct.ProductType
            )
          );
          UpdateContext.forceUpdate('productTypeListId');
        } else if (ProductTypeEntity.Status == RestStatus.BLOCK) {
          MessageHelper.error(
            ClaimMessageForamtUtils.formatI18NMsg(
              i18n.ClaimCommon.IsExist,
              i18n.ClaimProduct.ProductType
            )
          );
        } else {
          MessageHelper.error(i18n.ClaimProduct.ErrorMsg.SaveFAIL);
        }
      }
    );
  }

  initProducttype(data) {
    ProductTypeService.loadProductType(data.objectMap.id).then(
      ProductTypeEntity => {
        if (ProductTypeEntity.Status == RestStatus.OK) {
          this.dispatch(ProductTypeEntity.Model);
        } else {
          MessageHelper.error(ProductTypeEntity.Status);
        }
      }
    );
    UIDialog.show('showProductTypeDialog');
  }

  newProducttype() {
    const productType = {};
    productType.out = 'in';
    DataContext.put('showProductType', productType);
    const ProductTypeEntity = {};
    ProductTypeEntity.ProductTypeCode = null;
    this.dispatch(ProductTypeEntity);
    UIDialog.show('showProductTypeDialog');
  }

  removeProductType() {
    const data = DataContext.get('data');
    AjaxUtil.doGet(
      `${url.ClaimProductTypeDelete}/${data.ProductTypeCode}`,
      {},
      {
        async: false,
        done(data) {
          if (data.Status == RestStatus.OK) {
            MessageHelper.success(
              ClaimMessageForamtUtils.formatI18NMsg(
                i18n.ClaimCommon.DeleteSuccess,
                i18n.ClaimProduct.ProductType
              )
            );
            UpdateContext.forceUpdate('treeCfgUIupdateId');
            UpdateContext.forceUpdate('productTypeListId');
          } else {
            MessageHelper.error(data.Status);
          }
        },
        fail() {
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
        },
      }
    );
  }

  removeProductTypeDialog(event) {
    const data = event.getParameter('data');
    data.removeType = 'productType';
    DataContext.put('data', data);
    UIConfirmDialog.show('RemoveConfirmDialog');
  }
}
export default alt.createActions(ProductTypeAction);
