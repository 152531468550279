import {
  UIDialog,
  UIDialogFooter,
  UICell,
  UIButton,
} from 'RainbowUI';
import PropTypes from 'prop-types';

const LimitDeductDialog = React.createClass({
  propTypes: {
    index: PropTypes.string,
  },

  getDefaultProps() {
    return { index: '_0' };
  },
  getInitialState() {
    return { LimitInfo: '', DedutInfo: '' };
  },

  showLimitDeductDialog(data) {
    console.log('=========showLimitDeductDialog: ');
    if (data) {
      let limintInfo = '';      
      if (data.PolicyLimit) {
        let limitArray = data.PolicyLimit[0].PolicyLimitValueList;
        if (limitArray && limitArray.length > 0) {
          limintInfo = `   ${ limitArray[0].ModeType} ${ limitArray[0].ValueType} ${ limitArray[0].LimitValue}`;
        }
      }

      let dedInfo = '';      
      if (data.PolicyDeductible) {
        let dedArray = data.PolicyDeductible[0].PolicyDeductibleValueList;
        if (dedArray && dedArray.length > 0) {
          dedInfo = `   ${ dedArray[0].ModeType} ${ dedArray[0].ValueType} ${ dedArray[0].DeductibleValue}`;
        }
      }

      this.setState({ LimitInfo: limintInfo, DedutInfo: dedInfo });
    }
    UIDialog.show(
      `LimitDeductDialog${this.props.index}${this.props.level}`
    );
  },

  clickCancelDialog() {
    UIDialog.hide(
      `LimitDeductDialog${this.props.index}${this.props.level}`
    );
  },

  render() {
    const { index, level } = this.props;
    return (
      <div>
        <UIDialog
          id={`LimitDeductDialog${index}${level}`}
          width="600px"
          height="300px"
          modal="false"
        >
          {this.getMessage()}

          <UIDialogFooter>
            <UIButton
              value={i18n.ClaimCommon.Cancel}
              buttonType="cancel"
              onClick={this.clickCancelDialog}
            />
          </UIDialogFooter>
        </UIDialog>
      </div>
    );
  },

  getMessage() {
    const { LimitInfo, DedutInfo } = this.state;
    return (
      <div
        style={{ borderBottom: '1px solid #cbcbcb' }}
        className="clearfix"
      >
        <h5
          style={{
            borderBottom: '1px solid #cbcbcb',
            marginBottom: '0px',
          }}
        >
          <UICell width="12">{`Limit Info is ${LimitInfo}`}</UICell>
          <br />
          <UICell width="10">
            {`Deduction Info is ${DedutInfo}`}
          </UICell>
        </h5>
      </div>
    );
  },
});
export default LimitDeductDialog;
