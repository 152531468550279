import { ClaimCstUI } from 'ClaimCommonConstant';
import CommonPartyService from '../service/CommonPartyService';

export default class ClaimPartyActionProcess extends React.Component {
  constructClaimParty(
    partyVo,
    commonParty,
    caseId,
    addressId,
    accountId,
    subclaimIndex
  ) {
    const claimParty = {
      '@type': 'ClaimParty-ClaimParty',
      CaseId: caseId,

      PartyRole: partyVo.ClmPtyRole,
      PtyPartyId: commonParty.PartyId,
      PartyName: commonParty.PartyName,
      PtyAccountId: accountId,
      PtyAddressId: addressId,
    };
    return claimParty;
  }

  updateCommonParty(tmppartyVo) {
    const partyVo = tmppartyVo;
    if (partyVo['@type'] == ClaimCstUI.CUSTOMER_PARTY_INDIVIDUAL) {
      if (!partyVo.PartyName)
        partyVo.PartyName = `${partyVo.FirstName} ${partyVo.LastName}`;
    }
    partyVo.AddressVoList = this.deleteObjectField(
      partyVo.AddressVoList
    );
    partyVo.PartyContactList = this.deleteObjectField(
      partyVo.PartyContactList
    );
    partyVo.PartyAccountList = this.deleteObjectField(
      partyVo.PartyAccountList
    );
    delete partyVo.ClmPtyRole;
    delete partyVo.ClmPtyType;
    delete partyVo.ClmPtyId;
    delete partyVo.PartyType;
    delete partyVo.dataIndex;
    delete partyVo.partyAuth;
    delete partyVo.PtyContactId;
    delete partyVo.PtyAddressId;
    delete partyVo.PtyAccountId;
    return partyVo;
  }

  createCommonParty(partyVo) {
    let commonParty = {};
    let condition;
    if (ClaimCstUI.PARTY_TYPE_INDIVIDUAL == partyVo.ClmPtyType) {
      condition = {
        '@type': ClaimCstUI.CUSTOMER_PARTY_INDIVIDUAL,
        FirstName: partyVo.FirstName,
        LastName: partyVo.LastName,
        PartyName: `${partyVo.FirstName} ${partyVo.LastName}`,
        IdNumber: partyVo.IdNumber,
        IdType: partyVo.IdType,
      };
    } else {
      condition = {
        '@type': ClaimCstUI.CUSTOMER_PARTY_ORGANIZATION,
        PartyName: partyVo.PartyName,
        OrganizationIdType: partyVo.OrganizationIdType,
        OrganizationIdNumber: partyVo.OrganizationIdNumber,
      };
    }
    commonParty = condition;
    commonParty.AddressVoList = this.deleteObjectField(
      partyVo.AddressVoList
    );
    commonParty.PartyContactList = this.deleteObjectField(
      partyVo.PartyContactList
    );
    commonParty.PartyAccountList = this.deleteObjectField(
      partyVo.PartyAccountList
    );
    console.log('before to create common party==', commonParty);
    for (const item in commonParty) {
      if (!commonParty[item]) {
        delete commonParty[item];
      }
    }
    return commonParty;
  }

  deleteObjectField(dataList) {
    $(dataList).each((index, datas) => {
      const data = datas;
      delete data.dataIndex;
      delete data.selected;
      delete data.isAdd;
    });

    return dataList;
  }

  loadParty(partyId, clmPtyRole) {
    console.log(
      'szm--process--loadParty:',
      `${partyId}role:${clmPtyRole}`
    );
    return CommonPartyService.loadParty(partyId);
  }
}
