import { Caller, MessageHelper } from 'RainbowUI';
import ClaimCommonExt from 'ClaimCommonExt';
import { ClaimCstUI } from 'ClaimCommonConstant';
import ClaimSettlement from 'ClaimSettlement';
import { PartyAction } from 'EU00PartyCommonUI';
import Url from '../url/Url';

import alt from '../alt';

const NewSettlementVO = require('./vo/NewSettlementVO');
const SettlementLoadService = require('../service/SettlementLoadService');

class SettlementLoadAction {
  loadSettlementTask(taskInstanceId) {
    SettlementLoadService.loadSettlementTask(taskInstanceId).then(
      datas => {
        const data = datas;
        NewSettlementVO.payeeToAdd.setPayeeIndex(0);
        if (!data.SettlementInfo) {
          data.SettlementInfo = NewSettlementVO.newSettlement;
        }
        if (
          !data.SettlementInfo.SettlementPayee ||
          data.SettlementInfo.SettlementPayee.length < 1
        ) {
          const payeeToAdd = NewSettlementVO.payeeToAdd.get();
          data.SettlementInfo.SettlementPayee = [];
          data.SettlementInfo.SettlementPayee.push(payeeToAdd);
        }
        this.dispatch(data);
      },
      data => {
        if (data) {
          MessageHelper.error(data.Messages[0].Message, '');
          this.dispatch(data);
        }
      }
    );
  }

  loadClaimCase(caseid) {
    const url = Url.loadClaimCase.replace('{caseId}', caseid);
    let result = {};
    Caller.call(url, null, {
      method: 'GET',
      async: false,
      block: true,
    }).then(
      data => {
        result = data;
      },
      () => {
        MessageHelper.error(
          i18n.ClaimSettlement.ErrorMsg.Error_0001,
          i18n.ClaimSettlement.ErrorMsg.Error_0002,
          MessageHelper.POSITION_TOP_RIGHT
        );
      }
    );
    return result;
  }

  loadCoInsuranceByCaseIdPolicyNo(caseId, policyNo) {
    return SettlementLoadService.loadCoInsuranceByCaseIdPolicyNo(
      caseId,
      policyNo
    );
  }

  checkCoInsurance(caseId, policyNo) {
    if (!policyNo) {
      this.dispatch({});
      return;
    }
    SettlementLoadService.checkCoInsurance(caseId, policyNo).then(
      datas => {
        const data = datas;
        if (!data) {
          this.dispatch({});
          return;
        }
        data.policyNo = policyNo;

        this.dispatch(data);
        if (
          data.CoinFlag == ClaimCstUI.BooleanConstant.TRUE &&
          data.CoinLeaderFlag == ClaimCstUI.BooleanConstant.FALSE
        ) {
          for (let i = 0; i < data.PolicyCoinList.length; i += 1) {
            const temp = data.PolicyCoinList[i];
            if (temp.Leader == ClaimCstUI.BooleanConstant.TRUE) {
              this.actions.loadPartyLeader(temp.CoinCompanyId);
              break;
            }
          }
        }
      },
      data => {
        MessageHelper.error(data, '');
      }
    );
  }

  chooseFullOrPortion(event) {
    if (!event.newValue) {
      return;
    }
    this.dispatch(event.newValue);
  }

  loadPartyLeader(coinCompanyId) {
    SettlementLoadService.loadPartyLeader(coinCompanyId).then(
      data => {
        let account;
        let address;
        let contact;
        if (data.PartyAccountList) {
          for (let i = 0; i < data.PartyAccountList.length; i += 1) {
            const accountTemp = data.PartyAccountList[i];
            if (
              accountTemp.IsPrimaryAccount ==
              ClaimCstUI.BooleanConstant.TRUE
            ) {
              account = accountTemp;
              break;
            }
          }
        }

        if (data.AddressVoList) {
          for (let i = 0; i < data.AddressVoList.length; i += 1) {
            const addressTemp = data.AddressVoList[i];
            if (
              addressTemp.IsPrimaryAddress ==
              ClaimCstUI.BooleanConstant.TRUE
            ) {
              address = addressTemp;
              break;
            }
          }
        }

        if (data.PartyContactList) {
          for (let i = 0; i < data.PartyContactList.length; i += 1) {
            const contactTemp = data.PartyContactList[i];
            if (
              contactTemp.IsPrimaryContact ==
              ClaimCstUI.BooleanConstant.TRUE
            ) {
              contact = contactTemp;
              break;
            }
          }
        }

        const conPrimartyInfo = {};
        conPrimartyInfo.account = account;
        conPrimartyInfo.PartyName = data.PartyName;
        if (address) {
          const partyAction = new PartyAction();
          conPrimartyInfo.address = partyAction.buildAddress(address);
        } else {
          address = '';
        }
        conPrimartyInfo.address = address;
        conPrimartyInfo.contact = contact;
        this.dispatch(conPrimartyInfo);
      }
    );
  }

  getSettlementTypeList() {
    return SettlementLoadService.getSettlementTypeList();
  }

  loadSettlementReadOnly(settlementId, isApprove) {
    SettlementLoadService.loadSettlementReadOnly(settlementId).then(
      data => {
        this.dispatch({ data, isApprove });
      },
      data => {
        MessageHelper.error(data, '');
      }
    );
  }

  saveSettlement(TempsettlementData) {
    const settlementData = TempsettlementData;
    let flag = false;
    let payeeList = [];
    payeeList = settlementData.SettlementInfo.SettlementPayee;
    for (let i = 0; i < payeeList.length; i += 1) {
      if (typeof payeeList[i].PayeeName === 'object') {
        const name = payeeList[i].PayeeName.map;
        const payName = name[payeeList[i].PayeeId].text;
        settlementData.SettlementInfo.SettlementPayee[
          i
        ].PayeeName = payName;
      }
      delete payeeList[i].PayeeTypeCodeTable;
    }
    // this.dispatch(settlementData);

    const data = SettlementLoadService.saveSettlement(settlementData);

    if (undefined != data.Messages) {
      if (data.Messages[0].Message) {
        MessageHelper.error(data.Messages[0].Message, '');
      } else {
        MessageHelper.error(data.Messages[0].Type, '');
      }
    } else {
      flag = true;
    }

    if (flag) {
      if (settlementData.SettlementInfo.ClaimType === 'LOS,SAL,SUB') {
        MessageHelper.success(i18n.ClaimSettlement.RefundSaveSuccess);
      } else if (settlementData.SettlementInfo.ClaimType === 'LOS') {
        MessageHelper.success(
          i18n.ClaimSettlement.SettlementSaveSuccess
        );
      } else {
        MessageHelper.success(
          i18n.ClaimSettlement.RecoverySaveSuccess
        );
      }
    }
    return data;
  }

  submitSettlement(settlementData, isRecoverySubmit) {
    const settlementInfo = settlementData.SettlementInfo;
    if (
      !settlementInfo ||
      !settlementInfo.SettlementPayee ||
      settlementInfo.SettlementPayee.length < 1
    ) {
      MessageHelper.error(i18n.ClaimSettlement.NoPayee, '');
      return;
    }
    let payeeList = [];
    payeeList = settlementData.SettlementInfo.SettlementPayee;
    for (let i = 0; i < payeeList.length; i += 1) {
      delete payeeList[i].PayeeTypeCodeTable;
    }
    const result = SettlementLoadService.submitSettlement(
      settlementData
    );
    if (result !== 'OK') {
      if (isRecoverySubmit) {
        if (
          result.Messages[0].Message &&
          result.Messages[0].Message ==
          i18n.ClaimSettlement
            .SettlementSubmitBlockByExceedOutstandingReserveAmount
        ) {
          MessageHelper.error(
            i18n.ClaimSettlement
              .RecoverySubmitBlockByExceedOutstandingReserveAmount
          );
        }
        return result;
      }
      MessageHelper.error(result.Messages[0].Message.split(':')[1]);
    }
    return result;
  }

  addPayee() {
    const { payeeToAdd } = NewSettlementVO;
    this.dispatch(payeeToAdd);
  }

  removePayee(removeIndex) {
    this.dispatch(removeIndex);
  }

  addSettlementItem(event) {
    const payeeIndex = event.getParameter('payeeIndex');
    const { itemToAdd } = NewSettlementVO;
    itemToAdd.setPayeeIndex(payeeIndex);
    this.dispatch(itemToAdd);
  }

  removeSettlementItem(event) {
    const removeIndex = event.getParameter('removeIndex');
    const payeeIndex = event.getParameter('payeeIndex');
    const settlementItemLevel = {
      PayeeIndex: payeeIndex,
      RemoveIndex: removeIndex,
    };
    this.dispatch(settlementItemLevel);
  }

  changeCurrency(event) {
    const SettlementLoadActionProcess = ClaimSettlement.getSettlementLoadActionProcess();
    const data = SettlementLoadActionProcess.changeCurrencyCode(
      event
    );
    this.dispatch(data);
  }

  changeSettlementItem(paymentT, event) {
    const claimPartyRole = paymentT;
    const caseId = event.getParameter('data');
    if (!claimPartyRole) {
      return null;
    }
    return SettlementLoadService.LoadClaimPartyList(
      claimPartyRole,
      caseId
    );
  }

  displayReserveTree(event) {
    const reserveTreeDivId = event.getParameter('reserveTreeDivId');
    const reserveValueDivId = event.getParameter('reserveValueDivId');
    $('.reserveTree').hide();
    $(`#${reserveValueDivId}`).hide();
    $(`#${reserveTreeDivId}`).show();
  }

  displayReserveTreeBlur(event) {
    const reserveValueDivId = event.getParameter('reserveValueDivId');
    $(`#${reserveValueDivId}`).show();
  }

  chooseReserve(event, treeId, treeNode) {
    const reserveTreeDivId = event.getParameter('reserveTreeDivId');
    const reserveValueDivId = event.getParameter('reserveValueDivId');
    $(`#${reserveValueDivId}`).show();
    $(`#${reserveTreeDivId}`).hide();
    const data = event.getParameter('data');
    if (data.ReserveId == treeNode.id) {
      return;
    }
    const settlementItemLevel = {
      PayeeIndex: data.PayeeIndex,
      Index: data.Index,
      ReserveId: treeNode.id,
    };
    this.dispatch(settlementItemLevel);
  }

  choosePayee(event) {
    const ChoosePayeeAction = ClaimCommonExt.getChoosePayeeAction();
    const payeeIndex = event.getParameter('payeeIndex');

    ChoosePayeeAction.onShow(payeeIndex);
  }

  selectPayee(event) {
    const ChoosePayeeAction = ClaimCommonExt.getChoosePayeeAction();
    const payeeIndex = event.getParameter('payeeIndex');

    ChoosePayeeAction.showSelectPayee(payeeIndex);
  }

  callbackPayee(index, payee) {
    const ChoosePayeeAction = ClaimCommonExt.getChoosePayeeAction();

    if (index === undefined || index === null || !payee) {
      return;
    }
    this.dispatch({ index, payee });
    ChoosePayeeAction.onHide(index);
  }

  changeField(event) {
    const SettlementLoadActionProcess = ClaimSettlement.getSettlementLoadActionProcess();
    const data = SettlementLoadActionProcess.changeField(event);
    this.dispatch(data);
  }
}
export default alt.createActions(SettlementLoadAction);
