export default class RegistrationValidationAction extends React.Component {
  /**
   * false
   * true exceeds the Limit,
   * todo cr to check from app result api
   * @param objectEntity
   */
  validateSILimitForModifySubclaim(objectEntity) {
    const message =
      'The initial reserve of Loss (Indemnity) of this coverage/insured object/policy exceeds the Limit of this coverage/insured object/policy';
    for (
      let i = 0;
      i < objectEntity.PolicyCoverageList.length;
      i += 1
    ) {
      const coverageTemp = objectEntity.PolicyCoverageList[i];
      if (
        coverageTemp.Selected == '1' &&
        coverageTemp.InitLossIndemnity > coverageTemp.PolicySumInsured
      ) {
        return { Model: true, Message: message };
      }
    }
    return { Model: false, Message: message };
  }

  validateSILimitForNewSubclaim(caseForm) {
    const caseEntity = caseForm.ClaimEntity;
    if (caseEntity) {
      if (caseEntity.ObjectList) {
        for (let i = 0; i < caseEntity.ObjectList.length; i += 1) {
          const result = this.validateSILimitForModifySubclaim(
            caseEntity.ObjectList[0]
          );
          if (result.Model) {
            return result;
          }
        }
      }
    }
    return { Model: false };
  }
}
