import { UIDataTable, UIColumn, UISelect } from 'RainbowUI';
import { CodeTableAction, CodeTableCstUI } from 'ClaimCommonConstant';

const ConflictSubclaimList = React.createClass({
  getInitialState() {
    return { dataList: this.props.dataList };
  },

  componentWillReceiveProps(nextProps) {
    this.setState({ dataList: nextProps.dataList });
  },

  render() {
    const { dataList } = this.state;
    return (
      <div>
        <h6 style={{ color: 'red' }}>
          {i18n.ClaimCommon.ConflictedSubclaimMessage}
        </h6>
        <UIDataTable
          id="conflictSubclaimList"
          indexable="false"
          pageable="false"
          value={dataList}
          headerTitle={i18n.ClaimCommon.ConflictedSubclaimList}
          isHeading="true"
        >
          <UIColumn
            headerTitle={i18n.ClaimCommon.SubclaimNumber}
            value="SeqNo"
          />
          <UIColumn
            headerTitle={i18n.ClaimCommon.SubclaimType}
            value="SubclaimType"
          >
            <UISelect
              id="conflictSubclaimListSubclaimType"
              codeTable={CodeTableAction.loadDataList(
                CodeTableCstUI.SubclaimType,
                null
              )}
              io="out"
            />
          </UIColumn>
          <UIColumn
            headerTitle={i18n.ClaimCommon.Claimant}
            value="ClaimParty.PartyName"
          />
        </UIDataTable>
      </div>
    );
  },
});
export default ConflictSubclaimList;
