import {
  UIEmail,
  UIDateTimePicker,
  UICheckbox,
  UISelect,
  UISmartPanelGrid,
  UIPanel,
  UIText,
  UIRadio,
  UITextarea,
} from 'RainbowUI';
import DefinedCodeTable from '../../../codetable/CodeTable';
import GroupIdAction from '../../../actions/GroupIdAction';
import CodeTableTextNoValuelink from '../../../../common/component/CodeTableTextNoValuelink';

const PartyIndBasicInfoReadOnly = React.createClass({
  getInitialState() {
    return {
      isReadOnly: false,
      party: this.props.party,
    };
  },

  onPartyStoreChange(PartyStoreState) {
    if (PartyStoreState.party) {
      this.setState({ party: PartyStoreState.party });
    }
  },

  componentDidMount() {
    if (this.state.party) {
      const groupId = this.state.party.GroupId;
      if (groupId != null && groupId !== '') {
        $("input[name='IdForPartyGroupSearch']").val(groupId);
        const groupIdAction = new GroupIdAction();
        const groupInfo = groupIdAction.searchByGroupId(groupId);
        if (groupInfo) {
          $("input[name='IdForPartyGroupSearch_value']").val(
            groupInfo.GroupName
          );
          $("textarea[name='IdForPartyGroupDesc']").val(
            groupInfo.Description
          );
        }
      }
    }
  },

  render() {
    const basicInfoRender = this.indiBasicInfoRender();
    return (
      <UIPanel panelTitle={i18n.BasicInfo}>{basicInfoRender}</UIPanel>
    );
  },

  indiBasicInfoRender() {
    const { party, MobileStatus, EmailStatus } = this.state;
    return (
      <UISmartPanelGrid column="3">
        <UISelect
          label={i18n.PartyCategory}
          disabled="true"
          model={party}
          property="PartyType"
          codeTable={DefinedCodeTable.getCategroyCodeTable}
        />
        <UIText
          label={i18n.PartyCode}
          model={party}
          property="PartyCode"
          disabled="true"
        />
        <UISelect
          label={i18n.Prefix}
          codeTable={DefinedCodeTable.getPrefixCodeTable}
          model={party}
          property="Prefix"
          disabled="true"
        />
        <UIText
          label={i18n.FirstName}
          required="true"
          model={party}
          property="FirstName"
          validationGroup="UpdateBasicInfo"
          disabled="true"
        />
        <UIText
          label={i18n.MiddleName}
          model={party}
          property="MiddleName"
          disabled="true"
        />
        <UIText
          label={i18n.LastName}
          required="true"
          model={party}
          property="LastName"
          validationGroup="UpdateBasicInfo"
          disabled="true"
        />
        <UISelect
          label={i18n.IdType}
          model={party}
          property="IdType"
          codeTable={DefinedCodeTable.IdType}
          required="true"
          validationGroup="UpdateBasicInfo"
          disabled="true"
        />
        <UIText
          label={i18n.IdNo}
          model={party}
          property="IdNumber"
          required="true"
          validationGroup="UpdateBasicInfo"
          disabled="true"
        />
        <UISelect
          label={i18n.CountryOfIssue}
          model={party}
          property="Country"
          codeTable={DefinedCodeTable.getCountry}
          required="true"
          validationGroup="UpdateBasicInfo"
          disabled="true"
        />
        <UISelect
          label={i18n.Gender}
          model={party}
          property="Gender"
          required="true"
          codeTable={DefinedCodeTable.getGenderCodeTable}
          validationGroup="UpdateBasicInfo"
          disabled="true"
        />
        <UIDateTimePicker
          label={i18n.BirthDay}
          model={party}
          property="DateOfBirth"
          required="true"
          format="MM/DD/YYYY"
          maxDate="TODAY"
          manualInput="true"
          validationGroup="UpdateBasicInfo"
          disabled="true"
        />
        <UISelect
          label={i18n.ContactLanguage}
          codeTable={DefinedCodeTable.ContactLanguage}
          model={party}
          property="LanguagePreferred"
          showBlankOption="false"
          required="true"
          validationGroup="UpdateBasicInfo"
          disabled="true"
        />
        <UISelect
          label={i18n.Nationality}
          model={party}
          property="Nationality"
          codeTable={DefinedCodeTable.getNationalityCodeTabled}
          disabled="true"
        />
        <UISelect
          label={i18n.Occupation}
          model={party}
          property="Occupation"
          codeTable={DefinedCodeTable.getOccupation}
          disabled="true"
        />
        <UISelect
          label={i18n.MaritalStatus}
          codeTable={DefinedCodeTable.getMaritalStatus}
          model={party}
          property="MaritalStatus"
          disabled="true"
        />
        <UIText
          label={i18n.MobileTel}
          model={party}
          property="MobileTel"
          required={MobileStatus}
          validationGroup="UpdateBasicInfo"
          disabled="true"
        />
        <UICheckbox
          single="true"
          label={i18n.ReceiveSMSAlerts}
          onChange={this.setMobileStatus}
          model={party}
          property="ReceiveSMSAlerts"
          disabled="true"
        />
        <UIText
          label={i18n.SSSEmployerId}
          model={party}
          property="EmployerId"
          disabled="true"
        />
        <div id="email">
          <UIEmail
            label={i18n.email}
            model={party}
            property="Email"
            required={EmailStatus}
            validationGroup="UpdateBasicInfo"
            disabled="true"
          />
        </div>
        <UICheckbox
          single="true"
          label={i18n.ReceiveEmailAlerts}
          onChange={this.setEmailStatus}
          model={party}
          property="ReceiveEmailAlerts"
          disabled="true"
        />
        <UIText
          label={i18n.WorkHomeTel}
          model={party}
          property="WorkHomeTel"
          disabled="true"
        />
        <UIText
          label={i18n.FormerPartyCode}
          model={party}
          property="FormerPartyCode"
          disabled="true"
        />
        <UIText
          label={i18n.vat}
          model={party}
          property="VAT"
          disabled="true"
        />
        <UIText
          label={i18n.TIC}
          model={party}
          property="TIC"
          disabled="true"
        />
        <UIRadio
          label={i18n.ConsentDirectMarketing}
          model={party}
          disabled="true"
          property="ConsentForDirectMarketing"
          defaultValue="N"
          codeTable={DefinedCodeTable.getYesOrNo}
        />
        <UISelect
          label={i18n.DefaultPaymentMethod}
          model={party}
          property="PaymentMethodId"
          codeTable={DefinedCodeTable.getDefaultPaymentMethod}
          required="true"
          validationGroup="UpdateBasicInfo"
          disabled="true"
        />
        <UITextarea
          label={i18n.Description}
          model={party}
          property="Description"
          disabled="true"
        />
        <CodeTableTextNoValuelink
          id="IdForPartyGroupSearch"
          label={i18n.PtyGroupID}
          onIconClick={this.onClickGroupIdSearch}
          onChange={this.onChangeGroupId}
          disabled="true"
        />
        <UITextarea
          id="IdForPartyGroupDesc"
          label={i18n.PtyGroupIdDesc}
          disabled="true"
        />
      </UISmartPanelGrid>
    );
  },
});
export default PartyIndBasicInfoReadOnly;
