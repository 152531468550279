import { CodeTable } from 'RainbowUI';
import { RemoteCodeTable } from 'EU00PartyCommonUI';

export default {
  customerGategory: new CodeTable([
    { id: 'I', text: 'Individual' },
    { id: 'O', text: 'Organization' },
  ]),

  gender: new CodeTable([
    { id: 'F', text: 'Famle' },
    { id: 'M', text: 'Male' },
  ]),

  transcationOptions: new CodeTable([
    { id: '1', text: 'New Business' },
    { id: '2', text: 'Renewal' },
    { id: '3', text: 'Endorsement' },
    { id: '4', text: 'ARAP' },
  ]),

  rtaAccidentStatus() {
    return new CodeTable(RemoteCodeTable.codeTableById(76242858));
  },
  getPrintFormat() {
    return new CodeTable([
      { id: '1', text: 'PDF' },
      { id: '2', text: 'WORD' },
    ]);
  },
  getPrintStatus() {
    return new CodeTable(RemoteCodeTable.codeTableById(78053109));
  },
  rtaVehicleStatus() {
    return new CodeTable(RemoteCodeTable.codeTableById(76242868));
  },

  documentOptions: new CodeTable([
    { id: '-1', text: 'No  Map' },
    { id: '-2', text: 'No  Map' },
    { id: '-3', text: 'No  Map' },
    { id: '-4', text: 'No  Map' },
    { id: '1', text: 'Policy Document' },
    { id: '2', text: 'Motor Cover Note' },
    { id: '3', text: 'CAR Certifate' },
    { id: '4', text: 'Quotation Form' },
  ]),

  printOptions: new CodeTable([
    { id: '1', text: 'Initial Print' },
    { id: '2', text: 'Reprint' },
  ]),

  statusOptions: new CodeTable([
    { id: '1', text: 'Print' },
    { id: '2', text: 'Email' },
  ]),
  messageType: new CodeTable([
    { id: '1', text: 'Reminder1' },
    { id: '2', text: 'Reminder2' },
    { id: '3', text: 'Reminder3' },
  ]),
  username: new CodeTable([
    { id: '1', text: 'name1' },
    { id: '2', text: 'name2' },
    { id: '3', text: 'name3' },
  ]),
  taskType: new CodeTable([
    {
      id: 'ClaimRegistrationTask',
      text: 'Claim Registration',
      url: '/registration/',
    },
    {
      id: 'ClaimSettlementTask',
      text: 'Claim Settlement',
      url: '/settlement/',
    },
    {
      id: 'ClaimSettlementApprovalTask',
      text: 'Claim Settlement Approval',
      url: '/settlementApproval/',
    },
  ]),

  radio: new CodeTable([{ id: '1', text: '' }]),

  priority: new CodeTable([
    { id: '1', text: 'Low' },
    { id: '2', text: 'Medium' },
    { id: '3', text: 'High' },
  ]),
  product: new CodeTable([
    { id: 'code1', text: 'product_1' },
    { id: 'code2', text: 'product_2' },
    { id: 'code3', text: 'product_3' },
    { id: 'code1', text: 'product_1' },
  ]),
  YesOrNo: new CodeTable([
    { id: '1', text: i18n.ClaimCommon.Yes },
    { id: '0', text: i18n.ClaimCommon.No },
  ]),
  checkable: new CodeTable([{ id: 'Y', text: '' }]),

  ClaimBranch: new CodeTable([
    { id: 'Shanghai', text: 'Shangahi' },
    { id: 'Wuxi', text: 'Wuxi' },
  ]),

  getTransactionType: new CodeTable([
    { id: '6', text: 'Registration' },
    { id: '7', text: 'Field Investigation' },
    { id: '8', text: 'Settlement' },
    { id: '9', text: 'Salvage' },
    { id: '10', text: 'Subrogation' },
    { id: '11', text: 'Appraisal' },
    { id: '12', text: 'Calculation' },
    { id: '13', text: 'Recovery' },
    { id: '14', text: 'Refund' },
  ]),
};
