import { ClaimCstUI } from 'ClaimCommonConstant';
import { ClaimHandlingAction } from 'ClaimCommon';

export default class SettlementValidationAction extends React.Component {
  validateSettlement(settlementData) {
    const payees = settlementData.SettlementInfo.SettlementPayee;
    for (let i = 0; i < payees.length; i += 1) {
      if (!payees[i].PayeeName) {
        return {
          Status: ClaimCstUI.REST_RESTPONSE_STATUS_BLOCK,
          Messages: [
            { Message: i18n.ClaimSettlement.ChoosePayeeError },
          ],
        };
      }

      const items = payees[i].SettlementItem;
      if (!items || items.length === 0) {
        return {
          Status: ClaimCstUI.REST_RESTPONSE_STATUS_BLOCK,
          Messages: [
            { Message: i18n.ClaimSettlement.SettlementSubmitError },
          ],
        };
      }
    }

    const claimEntity = ClaimHandlingAction.getClaimDataInfo(
      settlementData.SettlementInfo.CaseId
    );

    const checkFeeStatus = ClaimHandlingAction.validatePolicyPremium(
      claimEntity.PolicyEntity.PolicyNo
    );

    const warnMsg = new Array();
    if (checkFeeStatus) {
      warnMsg.push({
        Message:
          checkFeeStatus +
          i18n.ClaimSettlement.FeeStatusContinutMessage,
      });
    }

    if (warnMsg && warnMsg.length > 0) {
      return {
        Status: ClaimCstUI.REST_RESTPONSE_STATUS_WARN,
        Messages: warnMsg,
      };
    }

    return null;
  }

  validateSettlementApproval() {
    const warnMsg = new Array();

    if (warnMsg && warnMsg.length > 0) {
      return {
        Status: ClaimCstUI.REST_RESTPONSE_STATUS_WARN,
        Messages: warnMsg,
      };
    }
    return null;
  }
}
