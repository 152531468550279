import alt from '../alt';
import ClaimWorkFlowLeftAction from '../action/desktop/ClaimWorkFlowLeftAction';

class SharePoolConditionStore {
  constructor() {
    this.condition = {};

    this.bindListeners({
      handleCondition: ClaimWorkFlowLeftAction.INIT_CONDITION,
      // handlePage:ClaimWorkFlowLeftAction.PAGE_CHANGE
    });
  }

  handleCondition(tmpcondition) {
    const condition = tmpcondition;
    console.log('SharePoolConditionStore', condition);
    if (condition.flag === 'sharingPool') {
      delete condition.flag;
      delete condition.isUpdate;
      this.condition = condition;
    }
  }
  /* handlePage(page){
         console.log("ClaimWorkFlowLeftAction===>>handlePage");
    	this.page = page;
    } */
}

export default alt.createStore(
  SharePoolConditionStore,
  SharePoolConditionStore
);
