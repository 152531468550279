import {
  AjaxUtil,
  DataContext,
  UIPickList,
  UpdateContext,
} from 'RainbowUI';
import TreeNode from '../constant/TreeNodeCache';

const ClaimDamageTypeRelationService = require('../service/ClaimDamageTypeRelationService');
const Url = require('../url/Url');
const ProductCfgTreeAction = require('./ProductCfgTreeAction');

export default {
  getClmDamageTypeRelation(pageindex, pagesize) {
    const data = TreeNode.TreeCache.get('TreeNode');
    const productLineCodeJS = {
      productLineCode: data.treeNodeCode,
    };
    const searchCondition = {};
    searchCondition.ProductLineCode =
      productLineCodeJS.productLineCode;
    searchCondition.PageNo = pageindex - 1;
    searchCondition.PageSize = pagesize;
    const url =
      Url.ClaimProductRelationOfSubclaimTypeDamageBySubClaimType;
    if (
      DataContext.get('searchClaimDamageTypeRelationCondition') !=
      null
    ) {
      const condition = DataContext.get(
        'searchClaimDamageTypeRelationCondition'
      );
      if (condition.subclaimTypeCode) {
        searchCondition.SubclaimTypeCode = condition.subclaimTypeCode
          .replace(/(^\s*)|(\s*$)/g, '')
          .toUpperCase();
      }
    }
    let result = {};
    AjaxUtil.doPost(url, searchCondition, {
      async: false,
      done(data) {
        result = data.Model;
      },
      fail() {
        MessageHelper.error(
          i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
        );
      },
    });

    return { count: result.Total, result: result.Content };
  },
  openClaimDamageRelationType(event) {
    const subclaimTypeCode = event.getParameter('subclaimTypeCode');
    const ProductLineCode = event.getParameter('productLineCode');
    const subclaimTypeName = event.getParameter('subclaimTypeName');
    const nodeName = event.getParameter('nodeName');
    const entity = {
      treeNodeCode: ProductLineCode,
      subclaimTypeCode,
      subclaimTypeName,
      treeNodeUrl: 'subclaimTypeandDamageTypeRelation',
      name: nodeName,
    };

    ProductCfgTreeAction.loadNode(event, entity.treeId, entity);
  },
  getPickList(subclaimTypeCode) {
    const data = ClaimDamageTypeRelationService.loadRelation(
      subclaimTypeCode
    );

    const pickList = [];
    let pickListItem = {};
    const selectList = data.SelectedList;
    const noSelectList = data.NoSelectedList;
    for (const item in selectList) {
      pickListItem = {
        value: selectList[item].Desc.DamageTypeCode,
        label: `${selectList[item].Desc.DamageTypeCode}:${selectList[item].Desc.DamageTypeDescription}`,
        selected: true,
      };
      pickList.push(pickListItem);
    }
    for (const item in noSelectList) {
      pickListItem = {
        value: noSelectList[item].DamageTypeCode,
        label: `${noSelectList[item].DamageTypeCode}:${noSelectList[item].DamageTypeDescription}`,
        selected: false,
      };
      pickList.push(pickListItem);
    }
    return pickList;
  },
  saveClmDamageType(event) {
    const entity = event.getParameter('entity');

    const { subclaimTypeCode } = entity;
    const productLineCode = entity.treeNodeCode;
    const selectList = UIPickList.getTargetList(
      'claimTypeAndDamagePickList'
    );
    const saveList = [];
    let saveObj = {};
    for (const item in selectList) {
      saveObj = {
        ProductLineCode: productLineCode,
        SubclaimTypeCode: subclaimTypeCode,
        DamageTypeCode: selectList[item],
      };
      saveList.push(saveObj);
    }
    const claimRelationData = {
      SubclaimTypeDamageTypeList: saveList,
    };

    ClaimDamageTypeRelationService.updateClaimDamageTypeRelation(
      claimRelationData,
      subclaimTypeCode
    );
    entity.treeNodeUrl = 'subclaimTypeandDamageType';
    ProductCfgTreeAction.loadNode(event, entity.treeId, entity);
  },
  cancelClmDamageType(event) {
    const entity = event.getParameter('entity');
    entity.treeNodeUrl = 'subclaimTypeandDamageType';
    ProductCfgTreeAction.loadNode(event, entity.treeId, entity);
  },

  searchClaimDamageTypeRelationList(condition) {
    DataContext.put(
      'searchClaimDamageTypeRelationCondition',
      condition
    );
    UpdateContext.forceUpdate(
      'subclaimTypeandDamageTypeListSearchResultProvider'
    );
  },
};
