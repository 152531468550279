import { UIText } from 'RainbowUI';
import ReactMixin from 'react-mixin';
import BindToMixin from 'react-binding';

const SubrogationInsuredInfo = require('./SubrogationInsuredInfo');

export default class SubrogationInfo extends React.Component {
  getIOStyle() {
    const { readOnly } = this.props;
    return readOnly == true ? 'out' : null;
  }

  render() {
    const {
      otherInfo,
      subrogationInfo,
      readOnly,
      isApproveTask,
      history,
    } = this.props;
    const coverageStructure = this.bindArrayTo(
      otherInfo,
      'CoverageStructure'
    );
    if (_.isEmpty(coverageStructure.items)) {
      return (
        <div>
          <UIText
            io={this.getIOStyle()}
            label={i18n.ClaimSubrogation.Remark}
            widthAllocation="1,11"
            valueLink={this.bindTo(subrogationInfo, 'Remark')}
          />
        </div>
      );
    }
    const insuredInfo = coverageStructure.items.map(
      (insured, index) => {
        return (
          <SubrogationInsuredInfo
            history={history}
            subrogationInfo={subrogationInfo}
            insured={insured}
            otherInfo={otherInfo}
            readOnly={readOnly}
            isApproveTask={isApproveTask}
            componentId={index}
          />
        );
      }
    );
    return (
      <div>
        {insuredInfo}
        <UIText
          io={this.getIOStyle()}
          label={i18n.ClaimSubrogation.Remark}
          widthAllocation="1,11"
          valueLink={this.bindTo(subrogationInfo, 'Remark')}
        />
        <br />
      </div>
    );
  }
}
ReactMixin.onClass(SubrogationInfo, BindToMixin);
