import { UIPage } from 'RainbowUI';
import ClaimWorkFlow from './component/desktop/ClaimWorkFlow';
import ClaimWorkFlowMyClaims from './component/desktop/ClaimWorkFlowMyClaims';
import ClaimWorkFlowMessageList from './component/desktop/ClaimWorkFlowMessageList';
import ClaimWorkFlowMyTask from './component/desktop/ClaimWorkFlowMyTask';
import ClaimWorkFlowFlowSharePool from './component/desktop/ClaimWorkFlowSharePool';
import EmptyPage from './component/sample/EmptyPage';
import BaseComponent from './component/desktop/BaseComponent';

export default class ClaimDesktop extends BaseComponent {
  render() {
    return (
      <UIPage>
        <div>
          <ClaimWorkFlow
            taskDefinitions={this.props.taskDefinitions}
            menuDefinitions={this.props.menuDefinitions}
          />
        </div>
      </UIPage>
    );
  }
}

/**
 * Define component prop types
 */
ClaimDesktop.propTypes = {};

/**
 * Get component default props
 */
ClaimDesktop.defaultProps = {
  name: 'ClaimDesktop',
  taskDefinitions: {
    ClaimRegistrationTask: '#/task/registration',
    ClaimSettlementTask: '#/task/settlement',
    ClaimSettlementApprovalTask: '#/task/settlementApproval',
  },
  menuDefinitions: {
    NewMessage: <ClaimWorkFlowMessageList />,
    PendingNotice: <EmptyPage />,
    MyClaim: <ClaimWorkFlowMyClaims />,
    MyTask: <ClaimWorkFlowMyTask />,
    PoolTask: <ClaimWorkFlowFlowSharePool />,
  },
};
