import { AjaxUtil } from 'RainbowUI';
import Url from '../constant/FieldinvestigationUrl';

let Q = require('q');

class BodilyInjuryService {
  postPromise(url, payload) {
    let deferred = Q.defer();
    let entity = payload || {};
    AjaxUtil.doPost(url, entity, {
      async: false,
      done: function(data) {
        deferred.resolve(data);
      },
      fail: function(err) {
        deferred.reject(err);
      },
    });
    return deferred.promise;
  }

  bodilyInjurySubmit(entity) {
    let url = Url.BodilyInjurySubmit;
    return this.postPromise(url, entity);
  }

  vehicleSave(entity) {
    let url = Url.BodilyInjurySave;
    return this.postPromise(url, entity);
  }
}

let BodilyInjuryServiceInstance = new BodilyInjuryService();
export { BodilyInjuryServiceInstance as BodilyInjuryService };
