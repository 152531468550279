import { AjaxUtil, MessageHelper } from 'RainbowUI';
import alt from '../../alt';
import Url from '../../url/Url';

class ClaimWorkFlowMytaskAction {
  getMyTaskList(pageindex, pagesize, tmpclaimTaskInstance) {
    const claimTaskInstance = tmpclaimTaskInstance;
    const pageNo = (pageindex - 1) / pagesize + 1;
    claimTaskInstance.PageSize = pagesize;
    claimTaskInstance.PageIndex = pageNo;

    let localUrl = Url.ClaimWorkflowGetMyTaskList;
    let result = null;
    if (env.IS_MOCK) {
      localUrl = 'workflow/getTaskList';
      AjaxUtil.doGet(
        localUrl,
        {},
        {
          async: false,
          done(data) {
            result = data;
          },
          fail(data) {
            if (data.status == 401 || data.status == 403) {
              MessageHelper.error(i18n.ClaimCommon.ReLogin);
            } else {
              MessageHelper.error(
                i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
              );
            }
          },
        }
      );
      return { count: 5, result: result.data };
    }
    AjaxUtil.doPost(localUrl, claimTaskInstance, {
      async: false,
      done(datas) {
        const data = datas;
        if (!data.Model) data.Model = {};
        if (!data.Model.Results) {
          data.Model.Results = [];
          const SolrDocs = {};
          data.Model.Results.push(SolrDocs);
        }
        if (data.Status === 'OK') {
          result = data;
        } else if (data.status == 401 || data.status == 403) {
          MessageHelper.error(i18n.ClaimCommon.ReLogin);
        } else {
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
        }
      },
      fail(data) {
        if (data.status == 401 || data.status == 403) {
          MessageHelper.error(i18n.ClaimCommon.ReLogin);
        } else {
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
        }
      },
    });
    const resultObj = {
      count: result.Model.Total,
      result: result.Model.Results[0].SolrDocs,
    };
    return resultObj;
  }

  openMyTask(event) {
    const data = event.getParameter('data');

    if (data.TaskDefinitionKey === 'ClaimRegistrationTask') {
      window.location.hash = `#/handling/registration?taskInstanceId=${data.entity_id}&readOnly=true&a=a`;
    } else {
      window.location.hash = `#/handling/tasks?taskInstanceId=${data.entity_id}&readOnly=false&a=a`;
    }
  }
}
export default alt.createActions(ClaimWorkFlowMytaskAction);
