import { UIDialog, UIConfirmDialog } from 'RainbowUI';

const ClaimLitigationAction = require('../../action/ClaimLitigationAction');

const LitigationRemoveConfirmDialog = React.createClass({
  remove() {
    const litigatoin = this.props.litigationObject;
    ClaimLitigationAction.remove(litigatoin);
    const litigations = ClaimLitigationAction.loadLitigations(
      this.props.CaseId
    );
    const { index } = this.props;
    if (!litigations || litigations.length === 0) {
      this.props.updateParentTab(false, index);
    }
    if (this.props.parentFunction) {
      this.props.parentFunction();
    }
    UIDialog.hide('removeLitigationDialog');
  },
  render() {
    return (
      <UIConfirmDialog
        id="removeLitigationDialog"
        title={i18n.ClaimCommon.Litigation}
        message={i18n.ClaimCommon.removeLitigationConfirm}
        onConfirm={this.remove}
      />
    );
  },
});
export default LitigationRemoveConfirmDialog;
