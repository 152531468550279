import {
  UICheckbox,
  CodeTable,
  UpdateContext,
  UIDialog,
  UIUpdatePanel,
  UIButton,
  MessageHelper,
  UIBox,
  DataContext,
} from 'RainbowUI';

import CommonCodeTable from '../../codeTable/CommonCodeTable';
import CommonService from '../../service/CommonService';

const EmailRecipientDialog = React.createClass({
  getInitialState() {
    const commonCodeTable = new CommonCodeTable();
    const allRecipTypeCodeTable = commonCodeTable.getPrintRecipientType();
    return {
      allRecipTypeCodeTable,
      recipTypesCodeTable: [],
      checkBoxValues: [],
      taskIds: [],
    };
  },

  onInitAndShow(taskIds) {
    const _self = this;
    if (taskIds.length === 0) {
      MessageHelper.warning(
        i18n.PrintMessage.WarnMsg.PleaseSelectOne,
        null,
        MessageHelper.POSITION_TOP_RIGHT
      );
      return;
    }
    if (this.props.onStartEmailCallBack) {
      this.props.onStartEmailCallBack();
    }
    const printTasks = CommonService.queryPrintTask(taskIds);
    const recipTypes = [];
    _.each(printTasks, task => {
      if (task.Recipients && task.Recipients.RecipientList) {
        _.each(task.Recipients.RecipientList, oneRecip => {
          let fand = false;
          _.each(recipTypes, rec => {
            if (rec.RecipientType == oneRecip.RecipientType) {
              fand = true;
            }
          });
          if (fand == false) {
            recipTypes.push(oneRecip);
          }
        });
      }
    });

    const recipTypesCodes = [];
    const checkBoxValues = [];
    _.each(recipTypes, type => {
      _.each(_self.state.allRecipTypeCodeTable.codes, code => {
        if (type.RecipientType == code.id) {
          recipTypesCodes.push(code);
          checkBoxValues.push(code.id);
        }
      });
    });

    const recipTypesCodeTable = new CodeTable(recipTypesCodes);

    this.state.taskIds = taskIds;
    this.state.recipTypesCodeTable = recipTypesCodeTable;
    this.state.checkBoxValues = checkBoxValues;

    DataContext.put('recipTypesCodeTable', recipTypesCodeTable);
    DataContext.put('taskIds', taskIds);

    UIDialog.show('emailRecipientDialog');
    UpdateContext.forceUpdate('updateReceiptList');
  },

  onEmail() {
    const ids = DataContext.get('taskIds');
    const param = {
      RecipentTypeList: this.state.checkBoxValues,
      TaskList: ids,
    };
    CommonService.emailLetterToRecipients(param);
    this.onBack();
  },

  onBack() {
    const recipTypesCodes = [];
    this.setState({
      taskIds: [],
      recipTypesCodeTable: new CodeTable(recipTypesCodes),
      checkBoxValues: [],
    });
    UIDialog.hide('emailRecipientDialog');
    if (this.props.onEndEmailCallBack) {
      this.props.onEndEmailCallBack();
    }
  },

  onChanged(event) {
    this.state.checkBoxValues = event.newValue;
  },
  getRecipientTypes() {
    const codeTableData = DataContext.get('recipTypesCodeTable');
    return codeTableData;
  },

  render() {
    const _self = this;
    return (
      <div>
        <UIDialog
          id="emailRecipientDialog"
          width="40%"
          ccloseable="false"
          onClose={_self.onBack}
          title={i18n.EmailRecipientList}
        >
          <UIUpdatePanel
            id="updateReceiptList"
            render={() => {
              return (
                <div
                  style={{ marginBottom: '20px', marginLeft: '40px' }}
                >
                  <UICheckbox
                    value={_self.state.checkBoxValues}
                    enabled="true"
                    disabled="false"
                    onChange={_self.onChanged}
                    validationGroup="email"
                    displayStyle="horizontal"
                    required="true"
                    codeTable={_self.getRecipientTypes}
                  />
                  <UIBox direction="center">
                    <UIButton
                      value={i18n.Submit}
                      enabled="true"
                      disabled="false"
                      onClick={_self.onEmail}
                      causeValidation="true"
                      validationGroup="email"
                      styleClass="primary"
                    />
                    <UIButton
                      value={i18n.Cancel}
                      enabled="true"
                      disabled="false"
                      onClick={_self.onBack}
                      styleClass="primary"
                    />
                  </UIBox>
                </div>
              );
            }}
          />
        </UIDialog>
      </div>
    );
  },
});
export default EmailRecipientDialog;
