import { UIPanel, UICell } from 'RainbowUI';

const ClaimWarning = React.createClass({
  render() {
    let content = '';
    const { warningMsg } = this.props;
    if (warningMsg && warningMsg.length > 0) {
      content = warningMsg.map(message => {
        return (
          <UICell style={{ textAlign: 'left', color: 'red' }}>
            {message}
          </UICell>
        );
      });
      return <UIPanel headerable="false">{content}</UIPanel>;
    }
    return <div>{}</div>;
  },
});
export default ClaimWarning;
