import { MessageHelper } from 'RainbowUI';
import alt from '../alt';

const CalculationLoadAction = require('../action/CalculationLoadAction');
const NewCalculationVO = require('../action/vo/NewCalculationVO');

const CalculationSubmitAction = require('../action/CalculationSubmitAction');

class CalculationStore {
  constructor() {
    this.CalculationData = {
      CalculationInfo: {
        CalculationItem: [],
        CalculationExpense: [],
      },
      OtherInfo: {
        CoverageTree: [],
        CoverageStructure: [{ CoverageList: [] }],
        CalculationStructure: [],
        ReserveCodeTable: [],
        ReserveStructure: [],
      },
      TaskInstanceId: null,
    };
    this.SelectedLength = 0;
    this.LimitAndDeductInformation = {};
    this.bindListeners({
      handleLoadCalculationTask:
        CalculationLoadAction.LOAD_CALCULATION_TASK,
      handleChooseCoverage: CalculationLoadAction.CHOOSE_COVERAGE,
      clangeShowReserve: CalculationLoadAction.CLANGE_SHOW_RESERVE,
      showLimitDialog: CalculationLoadAction.SHOW_LIMIT_DIALOG,
      handleChooseItem: CalculationLoadAction.CHOOSE_ITEM,
      handleAddItem: CalculationLoadAction.ADD_ITEM,
      handleDeleteItem: CalculationLoadAction.DELETE_ITEM,
      appraisalAmountChange:
        CalculationLoadAction.APPRAISAL_AMOUNT_CHANGE,
      requestAmountChange:
        CalculationLoadAction.REQUEST_AMOUNT_CHANGE,
      recognizedAmountChange:
        CalculationLoadAction.RECOGNIZED_AMOUNT_CHANGE,
      invoiceNoChange: CalculationLoadAction.INVOICE_NO_CHANGE,
      lossUnitChange: CalculationLoadAction.LOSS_UNIT_CHANGE,
      indemnityAmountChange:
        CalculationLoadAction.INDEMNITY_AMOUNT_CHANGE,
      handleAddExpense: CalculationLoadAction.ADD_EXPENSE,
      handleChooseExpenseReserve:
        CalculationLoadAction.CHOOSE_EXPENSE_RESERVE,
      handleDeleteExpenseReserve:
        CalculationLoadAction.DELETE_EXPENSE_RESERVE,
      expenseAmountChange:
        CalculationLoadAction.EXPENSE_AMOUNT_CHANGE,

      handleSaveCalculationTask:
        CalculationSubmitAction.SAVE_CALCULATION,

      handleCalculate: CalculationSubmitAction.CALCULATE,
    });
  }

  handleLoadCalculationTask(calculationData) {
    this.CalculationData.OtherInfo.CoverageStructure =
      calculationData.CoverageStructure;
    this.CalculationData.OtherInfo.CoverageTree =
      calculationData.CoverageTree;
    this.CalculationData.OtherInfo.CalculationStructure =
      calculationData.CalculationStructure;
    this.CalculationData.OtherInfo.ReserveCodeTable =
      calculationData.ReserveCodeTable;
    this.CalculationData.CalculationInfo =
      calculationData.CalculationInfo;
    this.CalculationData.OtherInfo.ReserveStructure =
      calculationData.ReserveStructure;
    this.CalculationData.TaskInstanceId =
      calculationData.TaskInstanceId;
    if (!this.CalculationData.CalculationInfo.CalculationItem) {
      this.CalculationData.CalculationInfo.CalculationItem = [];
    }
    if (!this.CalculationData.CalculationInfo.CalculationExpense) {
      this.CalculationData.CalculationInfo.CalculationExpense = [];
    }
    if (calculationData.CalculationInfo.CalculationItem) {
      this.SelectedLength =
        calculationData.CalculationInfo.CalculationItem.length;
    }
  }

  handleChooseCoverage(checkedList) {
    const coverageStructure = new Array();
    const items = this.CalculationData.CalculationInfo
      .CalculationItem;
    const updatedItems = new Array();
    const coverageTrees = this.CalculationData.OtherInfo.CoverageTree;
    let selectedLength = 0;

    for (let i = 0; i < checkedList.length; i += 1) {
      const checked = checkedList[i];
      if (checked.isParent == true) {
        const coverageArray = new Array();
        const insuredElement = {
          InsuredId: checked.id,
          InsuredName: checked.name,
          CoverageList: [],
        };
        const coverageList = checked.children;
        for (let j = 0; j < coverageList.length; j += 1) {
          const coverage = coverageList[j];
          if (coverage.checked == true) {
            coverageArray.push({
              CoverageCode: coverage.id,
              CoverageName: coverage.name,
            });

            let exists = false;
            for (let k = 0; k < items.length; k += 1) {
              if (
                items[k].InsuredId == checked.id &&
                items[k].CoverageCode == coverage.id
              ) {
                exists = true;
                updatedItems.push(items[k]);
              }
            }
            if (exists == false) {
              const itemToAdd = NewCalculationVO.calculationItemToAdd;
              itemToAdd.initial(
                checked.id,
                coverage.id,
                updatedItems.length
              );
              updatedItems.push(itemToAdd.get());
            }
            selectedLength += 1;
          }
        }
        insuredElement.CoverageList = coverageArray;
        coverageStructure.push(insuredElement);
      }
    }
    this.SelectedLength = selectedLength;

    for (let k = 0; k < coverageTrees.length; k += 1) {
      coverageTrees[k].checked = false;
      for (let i = 0; i < coverageStructure.length; i += 1) {
        if (coverageTrees[k].id == coverageStructure[i].InsuredId) {
          coverageTrees[k].checked = true;
        }
        for (
          let j = 0;
          j < coverageStructure[i].CoverageList.length;
          j += 1
        ) {
          if (
            coverageTrees[k].pId == coverageStructure[i].InsuredId &&
            coverageTrees[k].id ==
            coverageStructure[i].CoverageList[j].CoverageCode
          ) {
            coverageTrees[k].checked = true;
          }
        }
      }
    }
    this.CalculationData.CalculationInfo.CalculationItem = updatedItems;
    this.CalculationData.OtherInfo.CoverageStructure = coverageStructure;
  }

  clangeShowReserve(expenseToAdd) {
    const expenses = this.CalculationData.CalculationInfo
      .CalculationExpense;
    let flag = false;
    for (let i = 0; i < expenses.length; i += 1) {
      const temp = expenses[i];
      if (
        temp.InsuredId == expenseToAdd.insuredId &&
        temp.CoverageCode == expenseToAdd.coverageCode
      ) {
        flag = true;
        break;
      }
    }
    if (!flag) {
      expenseToAdd.setIndex(expenses.length);
      expenses.push(expenseToAdd.get());
    } else {
      for (let i = 0; i < expenses.length; i += 1) {
        const temp = expenses[i];
        if (
          temp.InsuredId == expenseToAdd.insuredId &&
          temp.CoverageCode == expenseToAdd.coverageCode
        ) {
          expenses.splice(i, 1);
        }
      }
    }
  }

  showLimitDialog(limitAndDeductInformation) {
    this.LimitAndDeductInformation = limitAndDeductInformation;
  }

  handleChooseItem(datas) {
    const data = datas;
    const reserveStructure = this.CalculationData.OtherInfo
      .ReserveStructure;
    const calculationItems = this.CalculationData.CalculationInfo
      .CalculationItem;
    if (_.isEmpty(data.ItemId)) {
      data.ReserveAmount = '';
      data.SettledAmount = '';
      data.ReserveCurrency = '';
      data.CurrencyCode = '';
      data.IndemnityAmount = '';
      data.RequestAmount = '';
      data.RecognizedAmount = '';
      data.CalculatedAmount = '';
      data.InvoiceNo = '';
    } else {
      for (let i = 0; i < calculationItems.length; i += 1) {
        if (calculationItems[i].ItemId == data.ItemId) {
          MessageHelper.error(
            '',
            i18n.ClaimCalculation.SameReserveTypeError
          );
          calculationItems[data.Index].ItemId = '';
          return;
        }
      }
    }
    for (let i = 0; i < reserveStructure.length; i += 1) {
      if (
        reserveStructure[i].ItemId == data.ItemId &&
        reserveStructure[i].ClaimTypeCode === 'LOS' &&
        reserveStructure[i].ExpenseFlag === 'N'
      ) {
        data.ReserveAmount = reserveStructure[i].OutstandingAmount;
        data.SettledAmount = reserveStructure[i].SettleAmount;
        data.ReserveCurrency = reserveStructure[i].CurrencyCode;
        data.CurrencyCode = reserveStructure[i].CurrencyCode;
        data.SettedReserveAmmout =
          reserveStructure[i].SettedReserveAmmout;
        break;
      }
    }
    for (let i = 0; i < calculationItems.length; i += 1) {
      if (
        calculationItems[i].Index == data.Index &&
        calculationItems[i].InsuredId == data.InsuredId &&
        calculationItems[i].CoverageCode == data.CoverageCode
      ) {
        calculationItems[i].ReserveAmount = data.ReserveAmount;
        calculationItems[i].SettledAmount = data.SettledAmount;
        calculationItems[i].ReserveCurrency = data.ReserveCurrency;
        calculationItems[i].CurrencyCode = data.CurrencyCode;
        calculationItems[i].ItemId = data.ItemId;
        calculationItems[i].SettedReserveAmmout =
          data.SettedReserveAmmout;
        calculationItems[i].IndemnityAmount = 0;
        calculationItems[i].LossUnitValue = '';
        calculationItems[i].AppraisalAmount = null;
        calculationItems[i].CalculatedAmount = data.CalculatedAmount;
        calculationItems[i].RecognizedAmount = data.RecognizedAmount;
        calculationItems[i].RequestAmount = data.RequestAmount;
        calculationItems[i].InvoiceNo = data.InvoiceNo;
        break;
      }
    }

    let totalIndeminityAmountInit = Number(0);
    if (
      this.CalculationData.CalculationInfo.TotalIndemnityAmount !=
      null &&
      this.CalculationData.CalculationInfo.TotalIndemnityAmount !=
      undefined
    ) {
      totalIndeminityAmountInit = this.CalculationData.CalculationInfo
        .TotalIndemnityAmount;
    }

    const totalIndemnity = totalIndeminityAmountInit * 100;
    let indemnityAmount = Number(0);

    if (data.IndemnityAmount) {
      indemnityAmount = Number(data.IndemnityAmount) * 100;
    }

    const ss = totalIndemnity - indemnityAmount;
    this.CalculationData.CalculationInfo.TotalIndemnityAmount =
      ss / 100;
  }

  handleAddItem(itemToAdd) {
    const items = this.CalculationData.CalculationInfo
      .CalculationItem;
    itemToAdd.setIndex(items.length);
    items.push(itemToAdd.get());
  }

  handleDeleteItem(data) {
    const items = this.CalculationData.CalculationInfo
      .CalculationItem;
    if (data.Length > 1) {
      let deleteIndex = null;
      for (let i = 0; i < items.length; i += 1) {
        const temp = items[i];
        if (temp.Index == data.Index) {
          deleteIndex = temp.Index;
          items.splice(i, 1);
          break;
        }
      }
      const filtered = items.filter(tmpval => {
        const val = tmpval;
        if (val.Index > deleteIndex) {
          val.Index -= 1;
        }
        return val;
      });
      this.CalculationData.CalculationInfo.CalculationItem = filtered;
    } else {
      for (let i = 0; i < items.length; i += 1) {
        const temp = items[i];
        if (temp.Index == data.Index) {
          temp.ReserveAmount = '';
          temp.SettledAmount = '';
          temp.SettledAmount = '';
          temp.ReserveCurrency = '';
          temp.CurrencyCode = '';
          temp.ItemId = '';
          temp.IndemnityAmount = null;
          temp.LossUnitValue = '';
          temp.AppraisalAmount = null;
          temp.CalculatedAmount = null;
          break;
        }
      }
    }

    const totalIndemnity =
      this.CalculationData.CalculationInfo.TotalIndemnityAmount * 100;
    let indemnityAmount = 0;
    if (data.IndemnityAmount) {
      indemnityAmount = Number(data.IndemnityAmount) * 100;
    }
    this.CalculationData.CalculationInfo.TotalIndemnityAmount =
      (totalIndemnity - indemnityAmount) / 100;
  }

  appraisalAmountChange(data) {
    const items = this.CalculationData.CalculationInfo
      .CalculationItem;
    for (let i = 0; i < items.length; i += 1) {
      const temp = items[i];
      if (temp.Index == data.Index) {
        items[i].AppraisalAmount = data.AppraisalAmount;
        break;
      }
    }
  }

  requestAmountChange(data) {
    const items = this.CalculationData.CalculationInfo
      .CalculationItem;
    for (let i = 0; i < items.length; i += 1) {
      const temp = items[i];
      if (temp.Index == data.Index) {
        items[i].RequestAmount = data.RequestAmount;
        if (items[i].RecognizedAmount) {
          /* empty */
        } else {
          items[i].RecognizedAmount = data.RequestAmount;
        }
        break;
      }
    }
  }

  invoiceNoChange(data) {
    const items = this.CalculationData.CalculationInfo
      .CalculationItem;
    for (let i = 0; i < items.length; i += 1) {
      const temp = items[i];
      if (temp.Index == data.Index) {
        items[i].InvoiceNo = data.InvoiceNo;
        break;
      }
    }
  }

  recognizedAmountChange(data) {
    const items = this.CalculationData.CalculationInfo
      .CalculationItem;
    for (let i = 0; i < items.length; i += 1) {
      const temp = items[i];
      if (temp.Index == data.Index) {
        items[i].RecognizedAmount = data.RecognizedAmount;
        break;
      }
    }
  }

  lossUnitChange(data) {
    const items = this.CalculationData.CalculationInfo
      .CalculationItem;
    for (let i = 0; i < items.length; i += 1) {
      const temp = items[i];
      if (temp.Index == data.Index) {
        items[i].LossUnitValue = data.LossUnitValue;
        break;
      }
    }
  }

  indemnityAmountChange(data) {
    const items = this.CalculationData.CalculationInfo
      .CalculationItem;
    for (let i = 0; i < items.length; i += 1) {
      const temp = items[i];
      if (temp.Index == data.Index) {
        items[i].IndemnityAmount = data.IndemnityAmount;
        break;
      }
    }
    let totalIndemnity = 0;

    for (let i = 0; i < items.length; i += 1) {
      if (items[i].IndemnityAmount) {
        totalIndemnity += Number(items[i].IndemnityAmount) * 100;
      }
    }

    this.CalculationData.CalculationInfo.TotalIndemnityAmount =
      totalIndemnity / 100;
  }

  handleAddExpense(expenseToAdd) {
    const expenses = this.CalculationData.CalculationInfo
      .CalculationExpense;
    expenseToAdd.setIndex(expenses.length);
    expenses.push(expenseToAdd.get());
  }

  handleChooseExpenseReserve(data) {
    const calculationExpenses = this.CalculationData.CalculationInfo
      .CalculationExpense;
    const reserveStructures = this.CalculationData.OtherInfo
      .ReserveStructure;
    let seleReserve = {};
    for (let i = 0; i < reserveStructures.length; i += 1) {
      const temp = reserveStructures[i];
      if (
        temp.ReserveId == data.ReserveId &&
        temp.InsuredId == data.InsuredId &&
        temp.CoverageCode == data.CoverageCode
      ) {
        seleReserve = temp;
        break;
      }
    }
    if (_.isEmpty(seleReserve)) {
      return;
    }
    for (let i = 0; i < calculationExpenses.length; i += 1) {
      const temp = calculationExpenses[i];
      if (
        temp.ReserveId == seleReserve.ReserveId &&
        temp.InsuredId == seleReserve.InsuredId &&
        temp.CoverageCode == seleReserve.CoverageCode
      ) {
        MessageHelper.error(
          '',
          i18n.ClaimCalculation.SameReserveTypeError
        );
        return;
      }
    }
    for (let i = 0; i < calculationExpenses.length; i += 1) {
      const temp = calculationExpenses[i];
      if (
        temp.InsuredId == seleReserve.InsuredId &&
        temp.CoverageCode == seleReserve.CoverageCode &&
        temp.Index == data.Index
      ) {
        temp.ExpenseAmount = '';
        temp.ItemId = seleReserve.ItemId;
        temp.ReserveAmount = seleReserve.OutstandingAmount;
        temp.ReserveCurrency = seleReserve.CurrencyCode;

        temp.CurrencyCode = seleReserve.CurrencyCode || '';
        temp.ReserveId = seleReserve.ReserveId;
        temp.SettledAmount = seleReserve.SettleAmount;
        temp.ReserveType = seleReserve.ReserveType;
        temp.SettedReserveAmmout = seleReserve.SettedReserveAmmout;
        calculationExpenses[i] = temp;

        break;
      }
    }
    const totalExpenseAmount =
      this.CalculationData.CalculationInfo.TotalExpenseAmount * 100;
    let expenseAmount = 0;
    if (data.ExpenseAmount) {
      expenseAmount = Number(data.ExpenseAmount) * 100;
    }
    this.CalculationData.CalculationInfo.TotalExpenseAmount =
      (totalExpenseAmount - expenseAmount) / 100;
  }

  handleDeleteExpenseReserve(data) {
    const calculationExpenses = this.CalculationData.CalculationInfo
      .CalculationExpense;
    let deleteIndex = null;
    for (let i = 0; i < calculationExpenses.length; i += 1) {
      const temp = calculationExpenses[i];
      if (
        temp.InsuredId == data.InsuredId &&
        temp.CoverageCode == data.CoverageCode
      ) {
        if (temp.Index == data.Index) {
          deleteIndex = data.Index;
          calculationExpenses.splice(i, 1);
          break;
        }
        /* if(data.Index!=null&&temp.Index==data.Index){
					calculationExpenses.splice(i,1);
					break;
				}
				if (data.ReserveId!=null&&temp.ReserveId==data.ReserveId) {
					calculationExpenses.splice(i,1);
					break;
				}; */
      }
    }
    const filtered = calculationExpenses.filter(tmpval => {
      const val = tmpval;
      if (val.Index > deleteIndex) {
        val.Index -= 1;
      }
      return val;
    });
    this.CalculationData.CalculationInfo.CalculationExpense = filtered;
    const totalExpenseAmount =
      this.CalculationData.CalculationInfo.TotalExpenseAmount * 100;
    let expenseAmount = 0;
    if (data.ExpenseAmount) {
      expenseAmount = Number(data.ExpenseAmount) * 100;
    }
    this.CalculationData.CalculationInfo.TotalExpenseAmount =
      (totalExpenseAmount - expenseAmount) / 100;
  }

  expenseAmountChange(data) {
    const calculationExpenses = this.CalculationData.CalculationInfo
      .CalculationExpense;

    for (let i = 0; i < calculationExpenses.length; i += 1) {
      const temp = calculationExpenses[i];
      if (temp.ReserveId == data.ReserveId) {
        calculationExpenses[i].ExpenseAmount = data.ExpenseAmount;
        break;
      }
    }
    let totalExpenseAmount = 0;
    for (let i = 0; i < calculationExpenses.length; i += 1) {
      if (calculationExpenses[i].ExpenseAmount) {
        totalExpenseAmount +=
          Number(calculationExpenses[i].ExpenseAmount) * 100;
      }
    }
    this.CalculationData.CalculationInfo.TotalExpenseAmount =
      totalExpenseAmount / 100;
  }

  handleSaveCalculationTask(calculationData) {
    this.CalculationData.OtherInfo.CoverageStructure =
      calculationData.CoverageStructure;
    this.CalculationData.OtherInfo.CoverageTree =
      calculationData.CoverageTree;
    this.CalculationData.OtherInfo.CalculationStructure =
      calculationData.CalculationStructure;
    this.CalculationData.OtherInfo.ReserveCodeTable =
      calculationData.ReserveCodeTable;
    this.CalculationData.CalculationInfo =
      calculationData.CalculationInfo;
    this.CalculationData.OtherInfo.ReserveStructure =
      calculationData.ReserveStructure;
    if (!this.CalculationData.CalculationInfo.CalculationItem) {
      this.CalculationData.CalculationInfo.CalculationItem = [];
    }
    if (!this.CalculationData.CalculationInfo.CalculationExpense) {
      this.CalculationData.CalculationInfo.CalculationExpense = [];
    }
  }

  handleCalculate(calculationData) {
    this.CalculationData.OtherInfo.CoverageStructure =
      calculationData.CoverageStructure;
    this.CalculationData.OtherInfo.CoverageTree =
      calculationData.CoverageTree;
    this.CalculationData.OtherInfo.CalculationStructure =
      calculationData.CalculationStructure;
    this.CalculationData.OtherInfo.ReserveCodeTable =
      calculationData.ReserveCodeTable;
    this.CalculationData.OtherInfo.ReserveStructure =
      calculationData.ReserveStructure;
    this.CalculationData.CalculationInfo =
      calculationData.CalculationInfo;
    if (!this.CalculationData.CalculationInfo.CalculationItem) {
      this.CalculationData.CalculationInfo.CalculationItem = [];
    }
    if (!this.CalculationData.CalculationInfo.CalculationExpense) {
      this.CalculationData.CalculationInfo.CalculationExpense = [];
    }

    const items = this.CalculationData.CalculationInfo
      .CalculationItem;
    let totalIndemnity = 0;
    for (let i = 0; i < items.length; i += 1) {
      if (items[i].IndemnityAmount) {
        totalIndemnity += Number(items[i].IndemnityAmount) * 100;
      }
    }
    this.CalculationData.CalculationInfo.TotalIndemnityAmount =
      totalIndemnity / 100;

    const calculationExpenses = this.CalculationData.CalculationInfo
      .CalculationExpense;
    let totalExpenseAmount = 0;
    if (calculationExpenses) {
      for (let i = 0; i < calculationExpenses.length; i += 1) {
        if (calculationExpenses[i].ExpenseAmount) {
          totalExpenseAmount +=
            Number(calculationExpenses[i].ExpenseAmount) * 100;
        }
      }
      this.CalculationData.CalculationInfo.TotalExpenseAmount =
        totalExpenseAmount / 100;
    }
  }
}
export default alt.createStore(CalculationStore, CalculationStore);
