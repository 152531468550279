import { UIConfirmDialog, UIDataTable } from 'RainbowUI';
import AssignmentAction from '../../action/AssignmentAction';
import TaskTransferDialog from '../TaskTransferDialog';

const ComfirmClosedTask = React.createClass({
  showDialog() {
    UIConfirmDialog.show('ConfirmDialog');
  },
  closeTask() {
    const selected = UIDataTable.getSelectedRecord(
      'assignmentPendingTaskTable'
    )[0];
    selected.caseId = this.props.caseId;
    AssignmentAction.closeClaimTask(selected);
    UIConfirmDialog.hide('ConfirmDialog');
    this.refs.ClaimTaskTransferDialog.showDialog({
      CaseId: this.props.caseId,
      CloseTaskTransfer: true,
    });
  },
  render() {
    return (
      <div>
        <TaskTransferDialog
          ref="ClaimTaskTransferDialog"
          componentId="closedTask"
        />
        <UIConfirmDialog
          id="ConfirmDialog"
          title={i18n.ClaimCommon.CloseTask}
          message={i18n.ClaimCommon.assignmentComfirmClosed}
          onConfirm={this.closeTask}
        />
      </div>
    );
  },
});
export default ComfirmClosedTask;
