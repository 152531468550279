import {
  DataContext,
  CodeTable,
  AjaxUtil,
  MessageHelper,
} from 'RainbowUI';
import {
  RestStatus,
  ClaimMessageForamtUtils,
} from 'ClaimCommonConstant';
import Url from '../url/Url';

export default {
  getSettlementTypeList(pageindex, pagesize) {
    let result = {};
    AjaxUtil.doGet(
      `${Url.ClaimCommonSettlementTypeLoad}?pageindex=${pageindex -
        1}&pagesize=${pagesize}`,
      {},
      {
        async: false,
        done(data) {
          result = data.Model;
        },
        fail(data) {
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
          result = data;
        },
      }
    );
    return {
      count: result.allClaimPaymentTypeList,
      result: result.claimPaymentTypeListByPage,
    };
  },
  removeSelectSettlementType(data) {
    var data = data;
    const settlementTypeCode = {
      PaymentTypeCode: data.PaymentTypeCode,
    };

    AjaxUtil.doPost(
      Url.ClaimCommonSettlementTypeRemove +
        settlementTypeCode.PaymentTypeCode,
      {},
      {
        async: false,
        done(data) {
          if (data.Status == RestStatus.BLOCK) {
            DataContext.put(
              'warning',
              'The Settlement Type Code has been associated with others.'
            );
            MessageHelper.error(
              ClaimMessageForamtUtils.formatI18NMsg(
                i18n.ClaimCommon.DeleteFail,
                i18n.ClaimProduct.SettlementType
              )
            );
          } else if (data.Status == RestStatus.OK) {
            MessageHelper.success(
              ClaimMessageForamtUtils.formatI18NMsg(
                i18n.ClaimCommon.DeleteSuccess,
                i18n.ClaimProduct.SettlementType
              )
            );
          }
        },
        fail() {
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
        },
      }
    );
  },
  getPartyRole(codeTableId) {
    let result = [];
    AjaxUtil.doGet(
      Url.CodeTableDataList + codeTableId,
      {},
      {
        async: false,
        done(data) {
          _.each(data, data => {
            const v = {
              id: data.Id,
              text: data.Description,
              code: data.Code,
            };
            result.push(v);
          });
        },
        fail(data) {
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
          result = data;
        },
      }
    );
    const codeTableData = new CodeTable(result);
    return codeTableData;
  },

  saveSettlementType(newdata, flag) {
    const newSettlementType = newdata;
    let result = 'false';
    let url = Url.ClaimCommonSettlementTypeSave;
    if (flag.out === 'out') {
      url = Url.ClaimCommonSettlementTypeUpdate;
    }
    AjaxUtil.doPost(url, newSettlementType, {
      async: false,
      done(data) {
        if (data.Status == RestStatus.OK) {
          result = 'true';
          MessageHelper.success(
            ClaimMessageForamtUtils.formatI18NMsg(
              i18n.ClaimCommon.SaveSuccess,
              i18n.ClaimProduct.SettlementType
            )
          );
        } else if (data.Status == RestStatus.BLOCK) {
          MessageHelper.error(
            ClaimMessageForamtUtils.formatI18NMsg(
              i18n.ClaimCommon.IsExist,
              i18n.ClaimProduct.SettlementType
            )
          );
        } else if (data.Status == RestStatus.ERROR) {
          MessageHelper.error(
            ClaimMessageForamtUtils.formatI18NMsg(
              i18n.ClaimCommon.SaveFail,
              i18n.ClaimProduct.SettlementType
            )
          );
        }
      },
      fail() {
        MessageHelper.error(
          i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
        );
      },
    });
    return result;
  },
};
