import {
  UIDataTable,
  UIColumn,
  UIDateTimePicker,
  UILink,
  UIParam,
  UICurrency,
  UIText,
} from 'RainbowUI';
import { CodeTableAction, CodeTableCstUI } from 'ClaimCommonConstant';
import PropTypes from 'prop-types';

const SalvageHistoryStore = require('../store/SalvageHistoryStore');
const SalvageLoadAction = require('../action/SalvageLoadAction');
const SalvageReadOnlyDialog = require('./SalvageReadOnlyDialog');

const SalvageHistory = React.createClass({
  propTypes: {
    caseId: PropTypes.string.isRequired,
  },

  getInitialState() {
    return { SalvageHistory: [] };
  },

  componentWillMount() {
    SalvageHistoryStore.listen(this.onStoreStateChange);

    SalvageLoadAction.loadSalvageHistory(this.props.caseId);
  },

  componentWillUnmount() {
    SalvageHistoryStore.unlisten(this.onStoreStateChange);
  },
  onStoreStateChange(storeState) {
    if (!_.isEmpty(storeState.SalvageHistory)) {
      this.setState({ SalvageHistory: storeState.SalvageHistory });
    }
  },

  render() {
    const _self = this;
    const { caseId } = this.props;
    const surffix = `salvageHis_${caseId}_`;
    const nameCode = CodeTableAction.loadDataList(
      CodeTableCstUI.ClaimUser,
      null
    );
    let i = 1;

    return (
      <div>
        <div>
          <UIDataTable
            id={`${surffix}dataTable`}
            value={_self.state.SalvageHistory}
            indexable="false"
            isHeading="true"
            pageable="false"
            headerTitle={i18n.ClaimSalvage.SalvageHistory}
          >
            <UIColumn
              headerTitle={i18n.ClaimSalvage.SeqNo}
              width="20%"
              render={data => {
                const temp = i;
                i += 1;
                return (
                  <UILink
                    value={temp}
                    onClick={SalvageLoadAction.showReadOnly}
                  >
                    <UIParam
                      name="salvageId"
                      value={data.SalvageId}
                    />
                  </UILink>
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.ClaimSalvage.TransactionDate}
              value="SubmitDate"
              width="20%"
              render={data => {
                return (
                  <UIDateTimePicker
                    id={`${surffix + data.SalvageId}_submitDate`}
                    value={data.ApproveDate}
                    placeHolder="MM/DD/YYYY HH:mm:ss"
                    format="MM/DD/YYYY HH:mm:ss"
                    io="out"
                  />
                );
              }}
            />

            <UIColumn
              headerTitle={i18n.ClaimSalvage.OperatedBy}
              value="Submiter"
              width="20%"
              render={data => {
                const names = nameCode.codes;
                const submiter = data.Submiter;
                let name;
                for (let i = 0; i < names.length; i += 1) {
                  if (names[i].id == submiter) {
                    name = names[i].text;
                  }
                }
                return <UIText io="out" value={name} />;
              }}
            />
            <UIColumn
              headerTitle={i18n.ClaimSalvage.TotalSalvageAmount}
              width="20%"
              render={data => {
                return (
                  <UICurrency
                    id={`CURRENCY_CODE_${surffix}${data.SalvageId}_salvageAmount`}
                    value={data.TotalSalvageAmount}
                    unit={window.EU00.getCurrencySignByCurrencyCode(
                      data.CaseCurrency
                    )}
                    io="out"
                  />
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.ClaimSalvage.Remark}
              width="20%"
              render={data => {
                return <UIText io="out" value={data.Remark} />;
              }}
            />
          </UIDataTable>
        </div>
        <div>
          <SalvageReadOnlyDialog />
        </div>
      </div>
    );
  },
});
export default SalvageHistory;
