import CommonDocument from '../../../common/component/dms/CommonDocument';
import DocumentAction from '../../actions/DocumentAction';
import PartyConst from '../../const/PartyConst';

export default class PartyDocument extends CommonDocument {
  componentWillMount() {
    const objectId = this.props.domainObject['@pk'];
    const documentModel = {
      ModuleName: 'Party',
      ObjectId: objectId,
      TransactionType: PartyConst.PARTY_PERMISSION_TRANS_TYPE,
      DocumentType: null,
      DocumentSubType: null,
      Remark: '',
    };
    const result = DocumentAction.queryDocumentsList(
      'Party',
      objectId
    );
    this.setState({
      documentModel,
      data: result,
      moduleName: 'Party',
      objectId,
    });
  }
}
