export default {
  /* settlementInfo :{
			"SettlementPayee":[
				{"Index":0,"PayMode":"1","Currency":1,"ExchangeRate":"1.0","PayeeId":1,"PayeeName":"Zhangsan1","Address":"aaa","Remark":"remark detail",
				"SettlementItem":[
					{"Index":0,"PayeeIndex":0,"SettlementItemId":"1","SeqNo":"001","ItemId":"81","ReserveType":"RC_02",
					"Coverage":"CT11","Outstanding":"100.00","SettlementType":"ST_11","SettlementAmount":"22","PF":"6"}
				]
				}
			] 
			
		}, */
  SettlementData: {
    PaymentTypeCodeTable: [
      {
        Parent: '-41',
        id: 'PT_01',
        text: 'Insured',
      },
      {
        Parent: '-41',
        id: 'PT_02',
        text: 'Bodyshop',
      },
      {
        Parent: '-41',
        id: 'PT_03',
        text: 'Spare Parts Provider ',
      },
      {
        Parent: '-41',
        id: 'PT_04',
        text: 'Other Providers',
      },
      {
        Parent: '1670403',
        id: 'PT_05',
        text: 'Towing expenses ',
      },
      {
        Parent: '1670403',
        id: 'PT_06',
        text: 'Other coverage expenses ',
      },
      {
        Parent: '1670403',
        id: 'PT_07',
        text: 'Other Party Lawyers expenses',
      },
      {
        Parent: '1670403',
        id: 'PT_08',
        text: 'Lawsuit expenses ',
      },
      {
        Parent: '1670404',
        id: 'PT_09',
        text: 'Independent Adjusters',
      },
      {
        Parent: '1670404',
        id: 'PT_10',
        text: 'Independent Lawyers',
      },
      {
        Parent: '1670404',
        id: 'PT_11',
        text: 'Independent others professionals',
      },
    ],
    ReserveCodeTable: [
      {
        id: '-41',
        text: 'Loss (Indemnity)',
      },
      {
        id: '1670403',
        text: 'Loss (Expenses)',
      },
      {
        id: '1670404',
        text: 'ALAE',
      },
      {
        id: '-43',
        text: 'Loss (Indemnity)',
      },
    ],
    ReserveStructure: [
      {
        CaseId: -1,
        CoverageCode: 'CI_-7282',
        CoverageName: 'Accessory',
        CoverageTypeCode: '7894',
        CurrencyCode: 'CNY',
        ItemId: -31,
        ObjectId: -21,
        OutstandingAmount: 2000,
        OurShareAmount: 1600,
        /* Co-leader t_clm_reserve= total 2000
  Payfull ---OS=OutstandingAmount
  PayPortion-OS=OurShareAmount

Co-follower t_clm_reserve=our share 1600 =OutstandingAmount
  to-leader    OS=OutstandingAmount
  to-follower  OS=OutstandingAmount */

        ReserveSign: 1,
        ReserveId: -41,
        ReserveName: 'Loss (Indemnity)',
        ReserveType: 'RC_01',
        SeqNo: '001',
        StatusCode: 'REOPEN',
        SubclaimType: '1_ST01',
      },
      {
        CaseId: -1,
        CoverageCode: 'CI_-7282',
        CoverageName: 'Accessory',
        CoverageTypeCode: '7894',
        CurrencyCode: 'EUR',
        ItemId: -31,
        ObjectId: -21,
        OutstandingAmount: 15,
        Positive: true,
        ReserveId: 1670403,
        ReserveName: 'Loss (Expenses)',
        ReserveType: 'RC_02',
        SeqNo: '001',
        StatusCode: 'REOPEN',
        SubclaimType: '1_ST01',
      },
      {
        CaseId: -1,
        CoverageCode: 'CI_-7282',
        CoverageName: 'Accessory',
        CoverageTypeCode: '7894',
        CurrencyCode: 'CNY',
        ItemId: -31,
        ObjectId: -21,
        OutstandingAmount: 100,
        Positive: true,
        ReserveId: 1670404,
        ReserveName: 'ALAE',
        ReserveType: 'RC_03',
        SeqNo: '001',
        StatusCode: 'REOPEN',
        SubclaimType: '1_ST01',
      },
      {
        CaseId: -1,
        CoverageCode: 'CI_47766',
        CoverageName: 'Third Party Property Damage',
        CoverageTypeCode: '47765',
        CurrencyCode: 'CNY',
        ItemId: -32,
        ObjectId: -21,
        OutstandingAmount: 1,
        Positive: true,
        ReserveId: -43,
        ReserveName: 'Loss (Indemnity)',
        ReserveType: 'RC_01',
        SeqNo: '001',
        StatusCode: 'REOPEN',
        SubclaimType: '1_ST01',
      },
    ],
    ReserveTree: [
      {
        id: '-21',
        name: '001',
        open: true,
        pId: '0',
      },
      {
        id: '-31',
        name: 'Accessory',
        open: true,
        pId: '-21',
      },
      {
        id: '-41',
        name: 'Loss (Indemnity)',
        open: true,
        pId: '-31',
      },
      {
        id: '1670403',
        name: 'Loss (Expenses)',
        open: true,
        pId: '-31',
      },
      {
        id: '1670404',
        name: 'ALAE',
        open: true,
        pId: '-31',
      },
      {
        id: '-32',
        name: 'Third Party Property Damage',
        open: true,
        pId: '-21',
      },
      {
        id: '-43',
        name: 'Loss (Indemnity)',
        open: true,
        pId: '-32',
      },
    ],
    SettlementContext: {
      CaseId: -1,
      ObjectId: -21,
    },
    /* "SettlementInfo": {
      "@pk": 1610712,
      "@type": "ClaimSettlement-ClaimSettlement",
      "BusinessObjectId": 562948,
      "CaseId": -1,
      "ObjectId": -21,
      "Remark": "aaaa",
      "SettleId": 1610712,
      "SettlementPayee": [
        {
          "@pk": 1610722,
          "@type": "ClaimSettlementPayee-ClaimSettlementPayee",
          "BusinessObjectId": 577164,
          "Index": 0,
          "LocalCurrency": "USD",
          "LocalExchangeRate": 0.1567,
          "LocalSettleAmount": 1.57,
          "PayMode": "101",
          "PayeeName": "Li si",
          "PaymentStatus": "PENDING",
          "SettleAmount": 10,
          "SettleCurrency": "CNY",
          "SettlePayeeId": 1610722,
          "SettlementItem": [
            {
              "@pk": 1610732,
              "@type": "ClaimSettlementItem-ClaimSettlementItem",
              "BusinessObjectId": 563031,
              "Index": 0,
              "ItemId": -31,
              "LocalCurrency": "USD",
              "LocalExchangeRate": 0.1567,
              "LocalSettleAmount": 1.57,
              "OutstandingAmount": 10000,
              "PayFinal": "5",
              "PayeeIndex": 0,
              "PaymentType": "PT_02",
              "ReserveExchangeRate": 0,
              "ReserveSettleAmount": 0,
              "ReserveType": "RC_01",
              "SettleAmount": 10,
              "SettleCurrency": "CNY",
              "SettleItemId": 1610732
            }
          ]
        }
      ],
      "SubmitDate": "2015-12-08T18:18:56",
      "Submiter": -11
    } */
  },
};
