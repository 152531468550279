import {
  UIUpdatePanel,
  UIPanel,
  UIText,
  UISmartPanelGrid,
  UIDataTable,
  UIBox,
  UIButton,
  UIColumn,
  UILink,
  UIParam,
} from 'RainbowUI';

import GroupIdDefineDialog from './GroupIdDefineDialog';
import GroupIdAction from '../../actions/GroupIdAction';

const GroupIdDefine = React.createClass({
  getInitialState() {
    const searchCondition = {
      GroupId: null,
      Description: null,
    };
    return {
      isSearch: false,
      searchCondition,
    };
  },

  render() {
    const _self = this;
    const groupIdAction = new GroupIdAction();
    return (
      <div>
        <UIUpdatePanel id="GroupIdDefine_Search">
          <UIPanel panelTitle={i18n.PtyDefGroupID}>
            <UISmartPanelGrid>
              <UIText
                label={i18n.PtyGroupID}
                model={_self.state.searchCondition}
                property="GroupId"
              />
              <UIText
                label={i18n.Description}
                model={_self.state.searchCondition}
                property="Description"
              />
            </UISmartPanelGrid>
            <UIBox direction="left">
              <UIButton
                value={i18n.Search}
                onClick={this.onSearchHandler}
              />
            </UIBox>
          </UIPanel>
        </UIUpdatePanel>
        {this.renderTable()}
        <UIBox direction="left">
          <UIButton
            value={i18n.create}
            onClick={this.showGroupIdDefineDialog}
          />
          <UIButton
            value={i18n.Exit}
            onClick={groupIdAction.onClickedExit}
          />
        </UIBox>
        <GroupIdDefineDialog
          ref="refGroupIdDefineDialog"
          onSearchHandler={this.onSearchHandler}
        />
      </div>
    );
  },

  renderTable() {
    const _self = this;
    return (
      <UIUpdatePanel id="GroupIdDefine_SearchRst">
        <UIDataTable
          id="groupIdDefine_resultTable"
          provider={_self.getPartyGroupResult}
          headerTitle={i18n.searchResult}
          indexable="false"
          detailable="false"
        >
          <UIColumn
            headerTitle={i18n.PtyGroupID}
            render={data => {
              return (
                <div>
                  <UILink
                    value={data.GroupId}
                    onClick={_self.editGroupIdDefineDialog}
                  >
                    <UIParam name="defId" value={data.DefId} />
                    <UIParam name="groupId" value={data.GroupId} />
                    <UIParam
                      name="groupName"
                      value={data.GroupName}
                    />
                    <UIParam
                      name="description"
                      value={data.Description}
                    />
                  </UILink>
                </div>
              );
            }}
          />
          <UIColumn headerTitle={i18n.PtyGroupName} value="GroupName">
            <UIText io="out" />
          </UIColumn>
          <UIColumn
            headerTitle={i18n.Description}
            value="Description"
          >
            <UIText io="out" />
          </UIColumn>
        </UIDataTable>
      </UIUpdatePanel>
    );
  },

  showGroupIdDefineDialog() {
    this.refs.refGroupIdDefineDialog.showDialog();
  },

  editGroupIdDefineDialog(event) {
    const groupDefined = {};
    groupDefined.DefId = event.getParameter('defId');
    groupDefined.GroupId = event.getParameter('groupId');
    groupDefined.GroupName = event.getParameter('groupName');
    groupDefined.Description = event.getParameter('description');

    this.refs.refGroupIdDefineDialog.editDialog(groupDefined);
  },

  onSearchHandler() {
    this.setState({ isSearch: true });
  },

  getPartyGroupResult(pageIndex, pageSize) {
    const groupIdAction = new GroupIdAction();

    const { isSearch } = this.state;
    if (!isSearch) {
      return { count: 0, result: [] };
    }

    return groupIdAction.getPartyGroupResult(
      this.state.searchCondition,
      pageIndex,
      pageSize
    );
  },
});
export default GroupIdDefine;
