import {
  UIParam,
  UIBox,
  UISelect,
  UIPage,
  UIDataTable,
  UIColumn,
  UIButton,
} from 'RainbowUI';
import BindToMixin from 'react-binding';
import CodeTableCstUI from '../../constant/CodeTableCstUI';
import CodeTableAction from '../../action/CodeTableAction';
import AssignmentApproachAction from '../../action/AssignmentApproachAction';
import Url from '../../url/Url';

const AssignmentApproach = React.createClass({
  mixins: [BindToMixin],
  getInitialState() {
    return { manualAssignData: [] };
  },
  saveAssignmentApproach() {
    AssignmentApproachAction.saveAssignmentApproach();
  },
  render() {
    return (
      <UIPage>
        <UIDataTable
          indexable="false"
          id="catastropheListTableId"
          pageable="false"
          value={AssignmentApproachAction.initAssignmentApproach}
        >
          <UIColumn
            id="taskType"
            headerTitle={i18n.ClaimCommon.TaskType}
            value="TaskCode"
            render={data => {
              return (
                <UISelect
                  value={data.TaskCode}
                  codeTable={CodeTableAction.loadDataList(
                    CodeTableCstUI.CLAIMTASK,
                    null,
                    null
                  )}
                  io="out"
                />
              );
            }}
          />
          <UIColumn
            id="ManualAssignCheckedData"
            headerTitle={i18n.ClaimCommon.ManualAssignmentApproach}
            value="ManualAssignCheckedData"
            render={data => {
              return (
                <UISelect
                  multiSelect="true"
                  autoComplete="true"
                  style={{ width: '30px' }}
                  defaultValue={data.ManualAssignCheckedData}
                  value={data.ManualAssignCheckedData}
                  codeTable={CodeTableAction.loadDataList(
                    CodeTableCstUI.ClaimAssignType,
                    null,
                    null
                  )}
                  onChange={
                    AssignmentApproachAction.saveChangeDataForCheckBox
                  }
                >
                  <UIParam name="data" value={data} />
                </UISelect>
              );
            }}
          />

          <UIColumn
            id="AutomaticAssignmentApproach"
            headerTitle={i18n.ClaimCommon.AutomaticAssignmentApproach}
            value="AutomaticAssignmentApproach"
            render={data => {
              return (
                <UISelect
                  value={data.AutomaticAssignmentApproach}
                  codeTable={CodeTableAction.loadDataList(
                    CodeTableCstUI.ClaimAssignType,
                    null,
                    null
                  )}
                  onChange={AssignmentApproachAction.saveChangeData}
                >
                  <UIParam name="data" value={data} />
                </UISelect>
              );
            }}
          />
        </UIDataTable>
        <UIBox>
          <UIButton
            value={i18n.ClaimCommon.Save}
            onClick={AssignmentApproachAction.save}
          />
        </UIBox>
      </UIPage>
    );
  },

});
export default AssignmentApproach;
