import { MessageHelper } from 'RainbowUI';
import alt from '../alt';

const SubrogationAction = require('../action/SubrogationAction');
const NewSubrogationItemVO = require('../action/vo/NewSubrogationItemVO');

class SubrogationStore {
  constructor() {
    this.SubrogationData = {
      SubrogationInfo: {
        SubrogationItem: [],
      },
      OtherInfo: {
        CoverageTree: [],
        CoverageStructure: [{ CoverageList: [] }],
        SubrogationStructure: [],
        ReserveCodeTable: [],
        ReserveStructure: [],
        SubrogationApprovalHistory: [],
      },
      TaskInstanceId: null,
      CaseId: null,
    };
    this.SelectedLength = 0;
    this.LimitAndDeductInformation = {};
    this.bindListeners({
      handleLoadSubrogationData: SubrogationAction.LOAD_DATA,
      handleChooseCoverage: SubrogationAction.CHOOSE_COVERAGE,
      handleRemarkChange: SubrogationAction.REMARK_CHANGE,
      handleDateChange: SubrogationAction.DATE_CHANGE,
      handleAmountChange: SubrogationAction.AMOUNT_CHANGE,
      handleFromChange: SubrogationAction.FROM_CHANGE,
      handleChooseItem: SubrogationAction.CHOOSE_ITEM,
      handleAddItem: SubrogationAction.ADD_ITEM,
      handleDeleteItem: SubrogationAction.deleteItem,
    });
  }

  handleLoadSubrogationData(subrogationData) {
    this.SubrogationData.OtherInfo.CoverageStructure =
      subrogationData.CoverageStructure;
    this.SubrogationData.OtherInfo.CoverageTree =
      subrogationData.CoverageTree;
    this.SubrogationData.OtherInfo.ReserveStructure =
      subrogationData.ReserveStructure;

    this.SubrogationData.OtherInfo.SubrogationStructure =
      subrogationData.SubrogationStructure;

    this.SubrogationData.SubrogationInfo =
      subrogationData.SubrogationInfo;
    this.SubrogationData.TaskInstanceId =
      subrogationData.TaskInstanceId;

    this.SubrogationData.CaseId =
      subrogationData.SubrogationInfo.CaseId;

    if (subrogationData.SelectCoverage) {
      this.SelectedLength = subrogationData.SelectCoverage.length;
    }
  }

  handleChooseCoverage(checkedList) {
    const coverageStructure = new Array();
    let items = this.SubrogationData.SubrogationInfo.SubrogationItems;
    const currencyCode = this.SubrogationData.SubrogationInfo
      .CurrencyCode;
    if (!items) {
      items = [];
    }

    const updatedItems = new Array();
    const coverageTrees = this.SubrogationData.OtherInfo.CoverageTree;

    for (let i = 0; i < checkedList.length; i += 1) {
      const checked = checkedList[i];
      if (checked.isParent == true) {
        const coverageArray = new Array();
        const insuredElement = {
          InsuredId: checked.id,
          InsuredName: checked.name,
          CoverageList: [],
        };
        const coverageList = checked.children;
        for (let j = 0; j < coverageList.length; j += 1) {
          let itemId;
          const coverage = coverageList[j];
          if (coverage.checked == true) {
            let exists = false;
            for (let k = 0; k < items.length; k += 1) {
              if (
                items[k].ObjectId == checked.id &&
                items[k].CoverageCode == coverage.id
              ) {
                exists = true;
                updatedItems.push(items[k]);
              }
            }
            if (exists == false) {
              const tempReserveStructure = this.SubrogationData
                .OtherInfo.ReserveStructure;
              for (
                let l = 0;
                l < tempReserveStructure.length;
                l += 1
              ) {
                if (
                  tempReserveStructure[l].ObjectId == checked.id &&
                  tempReserveStructure[l].CoverageCode == coverage.id
                ) {
                  itemId = tempReserveStructure[l].ItemId;
                  break;
                }
              }

              const itemToAdd =
                NewSubrogationItemVO.subrogationItemToAdd;
              itemToAdd.initial(
                checked.id,
                coverage.id,
                updatedItems.length,
                currencyCode,
                itemId
              );
              updatedItems.push(itemToAdd.get());
            }
          }
          coverageArray.push({
            CoverageCode: coverage.id,
            CoverageName: coverage.name,
            ItemId: itemId,
          });
        }
        insuredElement.CoverageList = coverageArray;
        coverageStructure.push(insuredElement);
      }
    }

    for (let k = 0; k < coverageTrees.length; k += 1) {
      coverageTrees[k].checked = false;
      for (let i = 0; i < checkedList.length; i += 1) {
        const checked = checkedList[i];
        if (coverageTrees[k].id == checked.id && checked.checked) {
          coverageTrees[k].checked = true;
        }
      }
    }

    this.SubrogationData.SubrogationInfo.SubrogationItems = updatedItems;
    this.SubrogationData.OtherInfo.CoverageStructure = coverageStructure;
  }

  handleRemarkChange(data) {
    const items = this.SubrogationData.SubrogationInfo
      .SubrogationItems;
    for (let i = 0; i < items.length; i += 1) {
      const temp = items[i];
      if (temp.Index == data.Index) {
        items[i].Remark = data.remark;
        break;
      }
    }
  }

  handleDateChange(data) {
    const items = this.SubrogationData.SubrogationInfo
      .SubrogationItems;
    for (let i = 0; i < items.length; i += 1) {
      const temp = items[i];
      if (temp.Index == data.Index) {
        items[i].LimitationDate = data.dataChange;
        break;
      }
    }
  }

  handleAmountChange(data) {
    const items = this.SubrogationData.SubrogationInfo
      .SubrogationItems;
    for (let i = 0; i < items.length; i += 1) {
      const temp = items[i];
      if (temp.Index == data.Index) {
        items[i].Amount = data.dataChange;
        break;
      }
    }
  }

  handleFromChange(data) {
    const items = this.SubrogationData.SubrogationInfo
      .SubrogationItems;
    for (let i = 0; i < items.length; i += 1) {
      const temp = items[i];
      if (temp.Index == data.Index) {
        items[i].RecoverFrom = data.dataChange;
        break;
      }
    }
  }

  handleChooseItem(data) {
    const items = this.SubrogationData.SubrogationInfo
      .SubrogationItems;
    for (let i = 0; i < items.length; i += 1) {
      const temp = items[i];
      if (items[i].ItemId == data.ItemId) {
        MessageHelper.error(
          '',
          i18n.ClaimCalculation.SameReserveTypeError
        );
        return;
      }
      if (temp.Index == data.Index) {
        items[i].ItemId = data.ItemId;
        break;
      }
    }
  }

  handleAddItem(itemToAdd) {
    const items = this.SubrogationData.SubrogationInfo
      .SubrogationItems;

    itemToAdd.setIndex(items.length);
    items.push(itemToAdd.get());
  }

  handleDeleteItem(data) {
    const items = this.SubrogationData.SubrogationInfo
      .SubrogationItems;
    let deleteIndex = null;
    for (let i = 0; i < items.length; i += 1) {
      const temp = items[i];
      if (temp.Index == data.Index) {
        deleteIndex = temp.Index;
        items.splice(i, 1);
        break;
      }
    }
    const filtered = items.filter(value => {
      const val = value;
      if (val.Index > deleteIndex) {
        val.Index -= 1;
      }
      return val;
    });
    this.SubrogationData.SubrogationInfo.SubrogationItems = filtered;
  }
}
export default alt.createStore(SubrogationStore, SubrogationStore);
