import {
  UICell,
  UIDataTable,
  UIColumn,
  UIUpdatePanel,
  UIText,
  UILink,
  UIParam,
  UISmartPanelGrid,
} from 'RainbowUI';
import PropTypes from 'prop-types';

const SubclaimCoverageReserveOfProductLineAction = require('../../action/SubclaimCoverageReserveOfProductLineAction');

const ClaimDamageTypeRelationProductLine = React.createClass({
  propTypes: {
    entity: PropTypes.object,
  },
  getData(pageindex, pagesize) {
    const coverageReserveRelationList = SubclaimCoverageReserveOfProductLineAction.getClmReserveRelation(
      pageindex,
      pagesize
    );
    return coverageReserveRelationList;
  },
  render() {
    const _self = this;
    return (
      <div>
        <UIUpdatePanel id="subClaimCoverageReserveListProvider">
          <UIDataTable
            indexable="false"
            id="subClaimCoverageReserveList"
            provider={this.getData}
          >
            <UIColumn
              headerTitle={i18n.ClaimProduct.SubclaimType}
              render={data => {
                const tempData = `${data.Object.SubclaimTypeCode}:${data.Object.SubclaimTypeName}`;
                return (
                  <UILink
                    value={tempData}
                    onClick={
                      SubclaimCoverageReserveOfProductLineAction.openSubclaimCoverageReserveRelation
                    }
                  >
                    <UIParam
                      name="subclaimTypeCode"
                      value={data.Object.SubclaimTypeCode}
                    />
                    <UIParam
                      name="subclaimTypeName"
                      value={data.Object.SubclaimTypeName}
                    />
                    <UIParam
                      name="coverageTypeCode"
                      value={data.Object1.CoverageTypeCode}
                    />
                    <UIParam
                      name="coverageTypeName"
                      value={data.Object1.CoverageTypeName}
                    />
                    <UIParam
                      name="productLineCode"
                      value={data.Object1.ProductLineCode}
                    />
                    <UIParam
                      name="nodeName"
                      value={_self.props.entity.name}
                    />
                  </UILink>
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.ClaimProduct.CoverageCategory}
              render={data => {
                const tempData = `${data.Object1.CoverageTypeCode}:${data.Object1.CoverageTypeName}`;
                return <UIText value={tempData} io="out" />;
              }}
            />
            <UIColumn
              headerTitle={i18n.ClaimProduct.ListofReserveType}
              render={data => {
                return this.getDetail(data.SelectedList);
              }}
            />
          </UIDataTable>
        </UIUpdatePanel>
      </div>
    );
  },
  getDetail(changeDataList) {
    if (changeDataList) {
      const result = changeDataList.map(data => {
        return (
          <UIText
            value={`${data.Desc.ReserveTypeCode}:${data.Desc.ReserveTypeName}`}
            io="out"
          />
        );
      });

      return (
        <UISmartPanelGrid column="1">
          <UICell>{result}</UICell>
        </UISmartPanelGrid>
      );
    }
  },
});
export default ClaimDamageTypeRelationProductLine;
