import alt from '../alt';
import ClmPartyService from '../service/ClmPartyService';
import SearchTaskMessage from '../../common/action/SearchTaskMessage';

class PartyMaintenanceAction {
  clickPartyTab(event) {
    this.dispatch(event);
  }

  getPartyList(caseId, partyName, pageIndex, pageSize) {
    let result = { count: 0, result: [] };
    if (!partyName) {
      ClmPartyService.getPartyListByCaseId(
        caseId,
        pageIndex - 1,
        pageSize
      ).then(data => {
        result = {
          count: data.Model.Total,
          result: data.Model.Content,
        };
      });
    } else {
      ClmPartyService.getPartyListByPartyName(
        caseId,
        partyName,
        pageIndex - 1,
        pageSize
      ).then(data => {
        result = {
          count: data.Model.Total,
          result: data.Model.Content,
        };
      });
    }

    const searchTaskMessage = new SearchTaskMessage(result);
    searchTaskMessage.showMessage();
    return result;
  }

  getPartyIdList(caseId) {
    return ClmPartyService.getPartyIdByCaseId(caseId);
  }
}
export default alt.createActions(PartyMaintenanceAction);
