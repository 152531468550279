import {
  UICheckbox,
  UIBox,
  UISelect,
  UIDialog,
  UISmartPanelGrid,
  UIText,
  UIButton,
  UIEmail,
} from 'RainbowUI';
import DefinedCodeTable from '../../../codetable/CodeTable';

const EditPartyContactDialogReadOnly = React.createClass({
  getInitialState() {
    return {
      partyContact: {},
    };
  },

  onClickClose() {
    UIDialog.hide('ContactDialogReadOnly');
  },
  render() {
    const _self = this;
    const { partyContact } = this.state;
    return (
      <UIDialog
        id="ContactDialogReadOnly"
        width="80%"
        title={i18n.ContactDetail}
      >
        <UISmartPanelGrid column="3">
          <UICheckbox
            single="true"
            singleLabel={i18n.PrimaryContact}
            model={partyContact}
            property="IsPrimaryContact"
            disabled="true"
          />
          <UISelect
            label={i18n.Position}
            codeTable={DefinedCodeTable.getPartyPosition}
            model={partyContact}
            property="Position"
            disabled="true"
          />
          <UISelect
            label={i18n.Prefix}
            codeTable={DefinedCodeTable.getPrefixCodeTable}
            model={partyContact}
            property="Prefix"
            disabled="true"
          />
          <UIText
            label={i18n.FirstName}
            model={partyContact}
            property="FirstName"
            disabled="true"
            required="true"
          />
          <UIText
            label={i18n.MiddleName}
            model={partyContact}
            property="MiddleName"
            disabled="true"
          />
          <UIText
            label={i18n.LastName}
            model={partyContact}
            property="LastName"
            disabled="true"
            required="true"
          />
          <UIText
            label={i18n.MobileTel}
            model={partyContact}
            property="HandPhone"
            disabled="true"
            required={partyContact.ReceiveSMSAlerts === 'Y'}
          />
          <div id="email">
            <UIEmail
              label={i18n.email}
              model={partyContact}
              property="Email"
              disabled="true"
              required={partyContact.ReceiveEmailAlerts === 'Y'}
            />
          </div>
          <UIText
            label={i18n.WorkTel}
            model={partyContact}
            property="BusinessTel"
            disabled="true"
          />
          <UICheckbox
            single="true"
            label={i18n.ReceiveSMSAlerts}
            disabled="true"
            model={partyContact}
            property="ReceiveSMSAlerts"
          />
          <UICheckbox
            single="true"
            label={i18n.ReceiveEmailAlerts}
            model={partyContact}
            property="ReceiveEmailAlerts"
            disabled="true"
          />
          <UIText
            label={i18n.FaxNumber}
            model={partyContact}
            property="Fax"
            disabled="true"
          />
          <UISelect
            label={i18n.Status}
            codeTable={DefinedCodeTable.getPartyStatus}
            model={partyContact}
            property="ContactStatus"
            showBlankOption="false"
            disabled="true"
          />
        </UISmartPanelGrid>
        <UIBox direction="center">
          <UIButton value={i18n.Save} disabled="true" />
          <UIButton
            value={i18n.Cancel}
            onClick={_self.onClickClose}
          />
        </UIBox>
      </UIDialog>
    );
  },

  showDialog(partyContact) {
    UIDialog.show('ContactDialogReadOnly');
    this.setState({
      partyContact,
    });
  },
});
export default EditPartyContactDialogReadOnly;
