import { ClaimCstUI } from 'ClaimCommonConstant';

const TabStrategy = require('./TabStrategy');
const AccidentTabStrategy = require('./AccidentTabStrategy');

export default {
  getAccidentTabs(caseForm, isRegistration) {
    const claimData = caseForm.ClaimData;
    const objectDatas = claimData.ObjectDatas;
    if (!objectDatas) {
      return;
    }
    const taskCode = caseForm.TaskInstance
      ? caseForm.TaskInstance.TaskCode
      : '';
    for (let i = 0; i < objectDatas.length; i += 1) {
      if (objectDatas[i].Name === '001') {
        sessionStorage.setItem(
          'claim_objectId',
          objectDatas[i].ObjectId
        );
        break;
      }
    }

    const ats = AccidentTabStrategy(caseForm, isRegistration);
    const ts = TabStrategy(caseForm);
    let accidentTabs = [];
    let claimTabs = [];
    if (isRegistration) {
      accidentTabs = ats.tabs('RegistrationStrategy');
      claimTabs = ts.tabs('RegistrationStrategy');
    } else if (
      taskCode ==
        ClaimCstUI.CLAIM_TASK_CODE.FieldInvestigationProperty ||
      taskCode ==
        ClaimCstUI.CLAIM_TASK_CODE.FieldInvestigationVehicle ||
      taskCode ==
        ClaimCstUI.CLAIM_TASK_CODE.FieldInvestigationBodilyInjury ||
      taskCode.indexOf('Appraisal') !== -1
    ) {
      accidentTabs = ats.tabs('FIStrategy');
      claimTabs = ts.tabs('FIStrategy');
    } else {
      accidentTabs = ats.tabs('DefaultStrategy');
      claimTabs = ts.tabs('DefaultStrategy');
    }
    accidentTabs = accidentTabs.concat(claimTabs);

    return accidentTabs;
  },

  getClaimTabs(caseForm) {
    let claimTabs = [];

    const t = TabStrategy(caseForm);
    const taskCode = caseForm.TaskInstance
      ? caseForm.TaskInstance.TaskCode
      : '';
    if (
      taskCode ==
        CaimCstUI.CLAIM_TASK_CODE.FieldInvestigationProperty ||
      taskCode ==
        ClaimCstUI.CLAIM_TASK_CODE.FieldInvestigationVehicle ||
      taskCode ==
        ClaimCstUI.CLAIM_TASK_CODE.FieldInvestigationBodilyInjury ||
      taskCode.indexOf('Appraisal') !== -1
    ) {
      claimTabs = t.tabs('FIStrategy');
    } else {
      claimTabs = t.tabs('DefaultStrategy');
    }

    return claimTabs;
  },
};
