import {
  UIPanel,
  UIText,
  UIParam,
  UIDataTable,
  UIDateTimePicker,
  UISelect,
  UIColumn,
  UICurrency,
  UILink,
} from 'RainbowUI';
import { CodeTableAction, CodeTableCstUI } from 'ClaimCommonConstant';

const AppraisalPropertyReadOnlyDialog = require('./AppraisalPropertyReadOnlyDialog');

const AppraisalPropertyHistory = React.createClass({
  getInitialState() {
    return { appraisalHistory: this.props.appraisalHistory };
  },

  showAppraisalReadOnly(event) {
    this.refs.AppraisalPropertyReadOnlyDialog_ref.showDialog(
      event.getParameter('data')
    );
  },

  render() {
    const _self = this;
    const { parentComponent } = this.props;
    const { CurrencyCode } = parentComponent.state;
    const { appraisalHistory } = this.state;
    let index = 0;
    return (
      <UIPanel
        panelTitle={i18n.ClaimAppraisal.AppraisalHistory}
        toggleable="true"
      >
        <UIDataTable
          pageable="false"
          indexable="false"
          value={appraisalHistory}
        >
          <UIColumn
            headerTitle={i18n.ClaimAppraisal.SeqNo}
            width="20%"
            render={data => {
              return (
                <UILink
                  value={data.AppraisalInfo.SeqNo}
                  onClick={_self.showAppraisalReadOnly}
                >
                  <UIParam name="data" value={data} />
                </UILink>
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.ClaimAppraisal.TransactionDate}
            render={data => {
              return (
                <UIDateTimePicker
                  value={data.AppraisalInfo.SubmitDate}
                  placeHolder="MM/DD/YYYY HH:mm:ss"
                  format="MM/DD/YYYY HH:mm:ss"
                  io="out"
                />
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.ClaimAppraisal.OperatedBy}
            value="OperatedBy"
            render={data => {
              return (
                <UISelect
                  value={data.AppraisalInfo.Submitter}
                  codeTable={CodeTableAction.loadDataList(
                    CodeTableCstUI.ClaimUser,
                    null
                  )}
                  io="out"
                />
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.ClaimAppraisal.NumberOfLossItems}
            value="NumberOfLossItems"
            render={data => {
              return (
                <UIText
                  value={
                    data.AppraisalInfo.appraisalProperty
                      .claimAppraisalPropertyItems.length - 1
                  }
                  io="out"
                />
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.ClaimAppraisal.TotalNetAmount}
            value="TotalNetAmount"
            render={data => {
              index += 1;
              return (
                <UICurrency
                  id={`CURRENCY_CODE_Property_Appraisal_TotalNetAmount${index}`}
                  value={
                    data.AppraisalInfo.appraisalProperty
                      .TotalNetAmount
                  }
                  unit={window.EU00.getCurrencySignByCurrencyCode(
                    CurrencyCode
                  )}
                  io="out"
                />
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.ClaimAppraisal.Remark}
            value="PropertyRemark"
            render={data => {
              return (
                <UIText
                  value={data.AppraisalInfo.AppraisalRemark}
                  io="out"
                />
              );
            }}
          />
        </UIDataTable>
        <AppraisalPropertyReadOnlyDialog
          ref="AppraisalPropertyReadOnlyDialog_ref"
          parentComponet={this}
        />
      </UIPanel>
    );
  },
});
export default AppraisalPropertyHistory;
