import {
  CodeTable,
  UIPanel,
  UISmartPanelGrid,
  UIText,
  UIRadio,
  UIButton,
  UICell,
  UIBox,
  UIParam,
  MessageHelper,
} from 'RainbowUI';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactMixin from 'react-mixin';
import BindToMixin from 'react-binding';
import {
  PtyIntegrationAddress,
  PartyConst,
  PtyIntegrationSearchDialog,
} from 'EU00PartyCommonUI';
import PropertyAction from '../action/PropertyAction';
import Const from '../constant/FieldinvestigationConst';

export default class Property extends Component {
  constructor(props) {
    super(props);
    this.state = {
      model: {
        PropertyEntity: {},
      },
      synBtnStatu: false,
      propertyInvestgatorInd: {},
      FieldInvestigationAddress: '',
    };
    this.save = this.save.bind(this);
    this.submit = this.submit.bind(this);
    this.claimantIsOwner = this.claimantIsOwner.bind(this);
    this.claimantEnabled = this.claimantEnabled.bind(this);
    this.showPartySearchPanelDialog = this.showPartySearchPanelDialog.bind(
      this
    );
    this.validateRemark1 = this.validateRemark1.bind(this);
    this.validateRemark2 = this.validateRemark2.bind(this);
    this.getFieldInvestigator = this.getFieldInvestigator.bind(this);
  }

  componentDidMount() {
    this.initData();
    this.synBtnEnable();
  }

  componentWillUnmount() {}

  getFieldInvestigator(party) {
    const { model } = this.state;
    model.InvestigatorName = party.PartyName;
    model.InvestigatorPartyId = party.PartyId;
    if (party.PartyType == PartyConst.PARTY_TYPE_IND) {
      model.InvestigatorTel = party.MobileTel;
    } else if (party.PartyType == PartyConst.PARTY_TYPE_ORG) {
      if (party.PartyContactList) {
        if (party.PartyContactList.length > 0) {
          _.each(party.PartyContactList, partyContact => {
            if (
              partyContact.IsPrimaryContact == PartyConst.IS_PRIMARY
            ) {
              model.InvestigatorTel = !partyContact.HandPhone
                ? partyContact.BusinessTel
                : partyContact.HandPhone;
            }
          });
        }
      }
    }
    this.setState({
      model,
    });
  }

  getIOStyle() {
    const self = this;
    const { model } = this.state;
    return model.Fistatus == Const.DONE ||
      self.props.openForm == Const.Other
      ? 'out'
      : 'in';
  }

  getEnabledStyle() {
    const self = this;
    const { model } = this.state;
    return model.Fistatus == Const.DONE ||
      self.props.openForm == Const.Other
      ? 'false'
      : 'true';
  }

  getButtons() {
    const self = this;
    if (self.props.openForm == Const.Self) {
      return (
        <UIBox>
          <UIButton
            value={i18n.ClaimCommon.Save}
            onClick={this.save}
          />
          <UIButton
            value={i18n.ClaimCommon.Submit}
            validationGroup="property"
            causeValidation="true"
            onClick={this.submit}
          />
        </UIBox>
      );
    }
    return undefined;
  }

  buildAddress(addressInfo) {
    const streetNo = addressInfo.StreetNo;
    const { StreetName } = addressInfo;
    const { BuildingName } = addressInfo;
    const { ApartmentNumber } = addressInfo;
    const { Postcode } = addressInfo;
    const { Area } = addressInfo;
    const { City } = addressInfo;

    let addressStr = '';
    if (streetNo) {
      addressStr += streetNo;
    }
    if (StreetName) {
      addressStr += `${StreetName}, `;
    }
    if (BuildingName) {
      addressStr += `${BuildingName}, `;
    }
    if (ApartmentNumber) {
      addressStr += `${ApartmentNumber}, `;
    }
    if (Postcode) {
      addressStr += `${Postcode}, `;
    }
    if (Area) {
      addressStr += `${Area}, `;
    }
    if (City) {
      addressStr += `${City}, `;
    }
    return addressStr;
  }

  showPartySearchPanelDialog() {
    this.refs.PartySearchDialogForInvestigator_Ref.show();
  }

  claimantIsOwner() {
    const { model } = this.state;
    const { subClaim } = this.props;
    if (model.PropertyEntity.ClaimantIsOwner === 'Y') {
      model.PropertyEntity.OwnerName = subClaim.ClaimantName;
    } else {
      model.PropertyEntity.OwnerName = null;
    }
    this.setState({ model });
  }

  claimantEnabled() {
    const { model } = this.state;
    if (
      model.PropertyEntity &&
      model.PropertyEntity.ClaimantIsOwner &&
      model.PropertyEntity.ClaimantIsOwner === 'Y'
    ) {
      return true;
    }
    return false;
  }

  submitCallback(data) {
    this.setState({
      model: data.Model,
    });
  }

  save() {
    const entity = this.buildData();
    PropertyAction.save(entity, this.submitCallback.bind(this));
  }

  submit() {
    const entity = this.buildData();
    PropertyAction.submit(entity);
  }

  buildData() {
    const vo = {};
    const { model } = this.state;
    const self = this;
    const entity = $.extend({}, model);

    entity.ObjectId = self.props.objectId;
    entity['@type'] =
      'ClaimFieldInvestigation-ClaimFieldInvestigation';
    entity.PropertyEntity['@type'] =
      'ClaimFIProperty-ClaimFIProperty';

    vo.TaskInstanceId = self.props.taskInfo.TaskInstanceId;
    vo.ClaimFIEntity = entity;
    return vo;
  }

  validateRemark2() {
    const { model } = this.state;
    if (model.Remark2 && model.Remark2.length > 3000) {
      model.Remark2 = null;
      MessageHelper.warning(
        i18n.ClaimFieldInvestigation.WarnMsg.ValueMustLessThan3000
      );
    }
    this.setState({ model });
  }

  validateRemark1() {
    const { model } = this.state;
    if (model.Remark1 && model.Remark1.length > 3000) {
      model.Remark1 = null;
      MessageHelper.warning(
        i18n.ClaimFieldInvestigation.WarnMsg.ValueMustLessThan3000
      );
    }
    this.setState({ model });
  }

  synchronizationFromAppraisal() {
    const { model } = this.state;
    const { objectId } = this.props;
    const synModel = PropertyAction.synModel(objectId);
    let hasChange = false;
    const newSynModelState = model.PropertyEntity;
    const keyps = Object.keys(synModel);
    for (let i = 0; i < keyps.length; i += 1) {
      newSynModelState[keyps[i]] = synModel[keyps[i]];
      hasChange = true;
    }
    this.forceUpdate();

    if (hasChange) {
      MessageHelper.success(
        i18n.ClaimFieldInvestigation.PropertySynSuccess
      );
    }
  }

  synBtnEnable() {
    const self = this;
    const enabled = PropertyAction.synButtonIsEnable(
      self.props.taskInfo
    );

    this.setState({
      synBtnStatu: enabled,
    });
  }

  initData() {
    const self = this;
    let fiStatus = Const.DONE;
    if (self.props.openForm == Const.Self) {
      fiStatus = Const.NEW;
    }
    const data = PropertyAction.initData(
      self.props.objectId,
      fiStatus
    );

    if (data) {
      if (Const.NEW == fiStatus && Const.DONE == data.Fistatus) {
        data.Fistatus = Const.NEW;
        data.Fiid = null;
      }

      this.setState({
        model: data,
      });
    }
  }

  isOwnerCodeTable() {
    return new CodeTable(
      [{ id: 'Y', text: 'Yes' }, { id: 'N', text: 'No' }],
      null,
      null
    );
  }

  render() {
    const { model } = this.state;
    return (
      <div>
        <UIPanel
          panelTitle={i18n.ClaimFieldInvestigation.PropertyInfo}
        >
          <UISmartPanelGrid column="3">
            <UIText
              label={i18n.ClaimFieldInvestigation.PropertyName}
              validationGroup="property"
              required="true"
              valueLink={this.bindToState(
                'model',
                'PropertyEntity.PropertyName'
              )}
              io={this.getIOStyle()}
            />
            <UIRadio
              label={i18n.ClaimFieldInvestigation.ClaimantIsOwner}
              valueLink={this.bindToState(
                'model',
                'PropertyEntity.ClaimantIsOwner'
              )}
              onChange={this.claimantIsOwner}
              codeTable={this.isOwnerCodeTable}
              io={this.getIOStyle()}
            />
            <UIText
              label={i18n.ClaimFieldInvestigation.OwnerName}
              validationGroup="property"
              required="true"
              valueLink={this.bindToState(
                'model',
                'PropertyEntity.OwnerName'
              )}
              io={this.getIOStyle()}
              disabled={this.claimantEnabled}
            />
            <UIText
              label={i18n.ClaimFieldInvestigation.investigatorName}
              io={this.getIOStyle()}
              onSuffixIconClick={this.showPartySearchPanelDialog}
              value={model.InvestigatorName}
              suffixIcon="fa fa-search"
            >
              <UIParam
                name="partyRole"
                value={
                  PartyConst.PARTY_ROLE_CODE_IND_CLAIMANT_INVESTIGATOR
                }
              />
              <UIParam
                name="partyCategory"
                value={PartyConst.PARTY_TYPE_IND}
              />
              <UIParam
                name="stateParam"
                value="propertyInvestgatorInd"
              />
            </UIText>
            <UIText
              label={
                i18n.ClaimFieldInvestigation.investigatorTelephone
              }
              colspan="2"
              allowChars="/^\d*$/"
              property="InvestigatorTel"
              model={model}
              io={this.getIOStyle()}
            />
            <UIText
              label={
                i18n.ClaimFieldInvestigation.FieldInvestigationAddress
              }
              colspan="3"
              valueLink={this.bindToState(
                'model',
                'PropertyEntity.InvestigateAddress'
              )}
              io={this.getIOStyle()}
            />
            <UIText
              label={
                i18n.ClaimFieldInvestigation.PropertyDamageDescription
              }
              colspan="3"
              valueLink={this.bindToState(
                'model',
                'PropertyEntity.PropertyRemark'
              )}
              io={this.getIOStyle()}
            />
            <UIText
              label={i18n.ClaimFieldInvestigation.remark1}
              colspan="3"
              valueLink={this.bindToState('model', 'Remark1')}
              io={this.getIOStyle()}
              onChange={this.validateRemark1}
            />
            <UIText
              label={i18n.ClaimFieldInvestigation.remark2}
              colspan="3"
              valueLink={this.bindToState('model', 'Remark2')}
              io={this.getIOStyle()}
              onChange={this.validateRemark2}
            />
            <UIText
              visibled={false}
              value={model.InvestigatorPartyId}
            />
          </UISmartPanelGrid>
        </UIPanel>

        <UICell style={{ textAlign: 'left' }}>
          {this.getButtons()}
        </UICell>
        <PtyIntegrationSearchDialog
          ref="PartySearchDialogForInvestigator_Ref"
          id="partySearchDialogForInvestigator"
          isSearch={false}
          getSearchResult={this.getFieldInvestigator}
          isVisibled={false}
          title={i18n.SearchClaimInvestigator}
          RoleName={PartyConst.PARTY_ROLE_NAME_CLAIM_INVESTIGATOR}
        />
        <PtyIntegrationAddress
          ref="PtyIntegrationAddress_ref"
          parentComponent={this}
          endId="_fi_property"
        />
      </div>
    );
  }
}
ReactMixin.onClass(Property, BindToMixin);
Property.defaultProps = {
  showButtons: true,
};
Property.propTypes = {
  showButtons: PropTypes.bool,
  objectId: PropTypes.string.isRequired,
};
