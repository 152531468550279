import React from 'react';
import {
  UIDialog,
  UIButton,
  UIBox,
  UISmartPanelGrid,
  UIText,
  MessageHelper,
  UISelect,
} from 'RainbowUI';
import PartyAction from '../actions/PartyAction';
import PartyCodeTableText from '../../common/component/PartyCodeTableText';
import DefinedCodeTable from '../codetable/CodeTable';

const EditParticipationInfo = React.createClass({
  getInitialState() {
    return {
      readOnly: false,

      partyParticipation: {},
    };
  },

  componentWillMount() {},

  componentDidMount() {},

  render() {
    const self = this;
    const { partyParticipation } = this.state;
    return (
      <div>
        <UIDialog
          id="addParticipation"
          width="80%"
          title={i18n.ParticipationDetail}
        >
          {self.renderParticipationDetail(partyParticipation)}
          <UIBox direction="center">
            <UIButton
              value={i18n.Save}
              onClick={self.onSave}
              disabled={self.state.readOnly}
              validationGroup="UpdateParticipationInfo"
              causeValidation="true"
            />
            <UIButton
              value={i18n.Cancel}
              onClick={self.onClickClose}
            />
          </UIBox>
        </UIDialog>
      </div>
    );
  },

  renderParticipationDetail(partyParticipation) {
    const self = this;
    return (
      <UISmartPanelGrid column="3">
        <PartyCodeTableText
          label={i18n.PartyName}
          model={partyParticipation}
          property="ParticipationPartyId"
          condition="ParticipationPartyId"
          validationGroup="UpdateParticipationInfo"
          suffixIcon="fa fa-search"
          disabled={self.state.readOnly}
          onIconClick={self.showPartySearchDialog}
          required="true"
        />
        {/* <UIPercent label="Share Percentage" disabled={self.state.readOnly} model={partyParticipation}
                property="SharePercentage" validationGroup="UpdateParticipationInfo" required="true"/> */}
        <UIText
          label={i18n.SharePercentage}
          disabled={self.state.readOnly}
          model={partyParticipation}
          property="SharePercentage"
        />
        <UISelect
          label={i18n.Status}
          codeTable={DefinedCodeTable.getPartyStatus}
          model={partyParticipation}
          property="Status"
          showBlankOption="false"
          disabled={self.state.readOnly}
        />
        <UIText
          label={i18n.Remarks}
          model={partyParticipation}
          property="Remarks"
          colspan="2"
          disabled={self.state.readOnly}
        />
      </UISmartPanelGrid>
    );
  },

  showPartySearchDialog() {
    if (this.state.readOnly == false) {
      this.props.searchPartyName(
        this.state.partyParticipation,
        false
      );
    } else {
      this.props.searchPartyName(this.state.partyParticipation, true);
    }
  },

  showDialog(partyParticipation, readOnly, partySearchType) {
    UIDialog.show('addParticipation');
    this.setState(
      {
        partyParticipation,
        readOnly,
        partySearchType,
      },
      () =>
        console.log(
          'partyRelationShip',
          this.state.partyParticipation
        )
    );
  },

  onSave() {
    const partyAction = new PartyAction();
    const { partyParticipation } = this.state;
    const result = partyAction.loadPartyByPartyId(
      partyParticipation.ParticipationPartyId
    );
    const { partyParticipationList } = this.props;
    if (partyParticipationList.length > 0) {
      for (let i = 0; i < partyParticipationList.length; i += 1) {
        if (
          partyParticipationList[i].CompanyName == result.PartyName
        ) {
          MessageHelper.error(
            'This party was already existed under this joint venture',
            '',
            MessageHelper.POSITION_TOP_RIGHT
          );
          return;
        }
      }
    }
    if (partyParticipation.SharePercentage > 100) {
      MessageHelper.error(
        'share percentage is not allowed more than 100%. ',
        '',
        MessageHelper.POSITION_TOP_RIGHT
      );
    } else {
      const result = this.props.submitHandler(partyParticipation);
      if (result === 'fail') {
        /* empty */
      } else {
        UIDialog.hide('addParticipation');
      }
    }
  },
});
export default EditParticipationInfo;
