import { DataContext, UpdateContext, AjaxUtil } from 'RainbowUI';
import TreeNode from '../constant/TreeNodeCache';

const Url = require('../url/Url');

export default {
  getParameterMatrix(pageindex, pagesize) {
    const data = TreeNode.TreeCache.get('TreeNode');
    const productLineCodeJS = {
      productLineCode: data.treeNodeCode,
      productTypeCode: data.objectMap.pId,
    };
    const searchCondition = {};
    searchCondition.ProductLineCode =
      productLineCodeJS.productLineCode;
    searchCondition.ProductTypeCode =
      productLineCodeJS.productTypeCode;
    searchCondition.PageNo = pageindex - 1;
    searchCondition.PageSize = pagesize;
    const url = Url.ClaimProductTypeViewParameterMatrixByConditions;
    if (DataContext.get('searchParameterMatrixCondition')) {
      const condition = DataContext.get(
        'searchParameterMatrixCondition'
      );
      if (condition.subclaimTypeCode) {
        searchCondition.SubclaimTypeCode = condition.subclaimTypeCode
          .replace(/(^\s*)|(\s*$)/g, '')
          .toUpperCase();
      }
      if (condition.coverageCategoryCode) {
        searchCondition.CoverageCategoryCode = condition.coverageCategoryCode
          .replace(/(^\s*)|(\s*$)/g, '')
          .toUpperCase();
      }
      if (condition.reserveTypeCode) {
        searchCondition.ReserveTypeCode = condition.reserveTypeCode
          .replace(/(^\s*)|(\s*$)/g, '')
          .toUpperCase();
      }
    }
    let result = {};
    AjaxUtil.doPost(url, searchCondition, {
      async: false,
      done(data) {
        result = data;
      },
      fail() {
        MessageHelper.error(
          i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
        );
      },
    });
    return { count: result.Count, result: result.Module };
  },

  searchParameterMatrixList(condition) {
    DataContext.put('searchParameterMatrixCondition', condition);
    UpdateContext.forceUpdate('parameterMatrixListProvider');
  },
};
