import {
  UIDialog,
  UIPanel,
  UIText,
  UISmartPanelGrid,
  UIBox,
  UIButton,
} from 'RainbowUI';
import BindToMixin from 'react-binding';

const AppraisalProperInfo = require('./AppraisalPropertyInfo');
const AppraisalPropertyItem = require('./AppraisalPropertyItem');
const ApprovalHistory = require('./../vehicle/ApprovalHistory');

const AppraisalPropertyReadOnlyDialog = React.createClass({
  mixins: [BindToMixin],

  getInitialState() {
    return {
      AppraisalInfo: {},
      AppraisalProperty: {},
      CurrencyCode: null,
      ApprovalHistoryVO: [],
    };
  },

  showDialog(data) {
    this.setState({ AppraisalInfo: data.AppraisalInfo });
    this.setState({ CurrencyCode: data.CurrencyCode });
    this.setState({
      AppraisalProperty: data.AppraisalInfo.appraisalProperty,
    });
    this.setState({ ApprovalHistoryVO: data.ApprovalHistoryVO });
    UIDialog.show('AppraisalPropertyReadOnlyDialog');
  },
  onClose() {
    UIDialog.hide('AppraisalPropertyReadOnlyDialog');
  },
  render() {
    const {
      AppraisalInfo,
      AppraisalProperty,
      CurrencyCode,
      ApprovalHistoryVO,
    } = this.state;
    if (!AppraisalInfo) {
      return (
        <UIDialog
          id="AppraisalPropertyReadOnlyDialog"
          title={i18n.ClaimAppraisal.AppraisalHistoryDetail}
          width="90%"
          height="1000px"
          modal="false"
        />
      );
    }
    const claimAppraisalProperty = this.bindToState(
      'AppraisalInfo',
      'appraisalProperty'
    );
    return (
      <UIDialog
        id="AppraisalPropertyReadOnlyDialog"
        title={i18n.ClaimAppraisal.AppraisalHistoryDetail}
        width="90%"
        height="1000px"
        modal="false"
      >
        <AppraisalProperInfo
          ioSytle="out"
          history
          appraisalProperInfo={AppraisalProperty}
        />
        <UIPanel
          panelTitle={i18n.ClaimAppraisal.LossItems}
          toggleable="true"
          styleClass="default"
        >
          <AppraisalPropertyItem
            ioSytle="out"
            history
            claimAppraisalProperty={claimAppraisalProperty}
            currencyCode={CurrencyCode}
          />
          <UISmartPanelGrid column="3">
            <UIText
              colspan="3"
              io="out"
              widthAllocation="2,10"
              id="RemarkToLossItems"
              label={i18n.ClaimAppraisal.RemarkToLossItems}
              valueLink={this.bindToState(
                'AppraisalProperty',
                'LossItemRemark'
              )}
            />
            <UIText
              colspan="3"
              io="out"
              widthAllocation="2,10"
              id="RemarkToAppraisal"
              label={i18n.ClaimAppraisal.RemarkToAppraisal}
              valueLink={this.bindToState(
                'AppraisalInfo',
                'AppraisalRemark'
              )}
            />
          </UISmartPanelGrid>
        </UIPanel>
        <ApprovalHistory
          ioSytle="out"
          history
          parentComponent={this}
          appraisalApproveHistory={ApprovalHistoryVO}
          currencyCode={CurrencyCode}
        />
        <UIBox direction="center">
          <UIButton
            value={i18n.ClaimAppraisal.Back}
            position="right"
            onClick={this.onClose}
          />
        </UIBox>
      </UIDialog>
    );
  },
});
export default AppraisalPropertyReadOnlyDialog;
