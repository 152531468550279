import { AjaxUtil } from 'RainbowUI';
import { RestStatus } from 'ClaimCommonConstant';
import Url from '../url/Url';

export default {
  queryNoticeList(noticeEntity) {
    const deferred = $.Deferred();
    AjaxUtil.doPost(Url.NoticeLoadList, noticeEntity, {
      async: false,
      done(data) {
        deferred.resolve(data);
      },
      fail(err) {
        deferred.reject(err);
      },
    });
    return deferred.promise();
  },

  createNotice(noticeEntity) {
    const deferred = $.Deferred();
    AjaxUtil.doPost(Url.NoticeCreate, noticeEntity, {
      async: false,
      done(data) {
        deferred.resolve(data);
      },
      fail(err) {
        deferred.reject(err);
      },
    });
    return deferred.promise();
  },

  updateNotice(noticeEntity) {
    const deferred = $.Deferred();
    AjaxUtil.doPost(Url.NoticeUpdate, noticeEntity, {
      async: false,
      done(data) {
        deferred.resolve(data);
      },
      fail(err) {
        deferred.reject(err);
      },
    });
    return deferred.promise();
  },

  isMotor(productTypeCode) {
    const deferred = $.Deferred();
    let result = false;
    const url = `${Url.IsMotor}?productTypeCode=${productTypeCode}`;
    AjaxUtil.doGet(
      url,
      {},
      {
        async: false,
        done(data) {
          try {
            if (RestStatus.OK == data.Status) {
              result = data.Model;
            }
          } catch (err) {
            // continue regardless of error
          }
        },
        fail(err) {
          deferred.reject(err);
          return result;
        },
      }
    );
    return result;
  },

  loadNotice(noticeId) {
    const deferred = $.Deferred();
    AjaxUtil.doGet(
      Url.NoticeLoad + noticeId,
      {},
      {
        async: false,
        done(data) {
          deferred.resolve(data);
        },
        fail(err) {
          deferred.reject(err);
        },
      }
    );
    return deferred.promise();
  },
  loadRtaAccident(accidentId) {
    const url = Url.RtaLoadAccident + accidentId;

    let accident = null;
    AjaxUtil.doGet(
      url,
      {},
      {
        async: false,
        done(data) {
          accident = data;
        },
      }
    );
    return accident;
  },

  loadSyn(noticeId) {
    const deferred = $.Deferred();
    AjaxUtil.doPost(
      Url.NoticeVersionUpdate + noticeId,
      {},
      {
        async: false,
        done(data) {
          deferred.resolve(data);
        },
        fail(err) {
          deferred.reject(err);
        },
      }
    );
    return deferred.promise();
  },
  getCurrentUserInfo() {
    const deferred = $.Deferred();
    AjaxUtil.doGet(
      Url.NoticeCurrentUserInformation,
      {},
      {
        async: false,
        done(data) {
          deferred.resolve(data);
        },
        fail() { },
      }
    );
    return deferred.promise();
  },
  getBranchData() {
    const deferred = $.Deferred();
    AjaxUtil.doGet(
      Url.BranchQuery,
      {},
      {
        async: false,
        done(data) {
          deferred.resolve(data);
        },
        fail() { },
      }
    );
    return deferred.promise();
  },
};
