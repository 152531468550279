import { Caller, MessageHelper, CodeTable } from 'RainbowUI';
import RemoteCodeTable from '../../common/codetable/RemoteCodeTable';
import { getRegionData } from '../../../../common/module/common/util/ClaimUtil';

const UrlConfig = require('../../../UrlConfig');

const CodeTableService = {
  getCodeTable(codeTableId) {
    const url = UrlConfig.loadCodeTable_url + codeTableId;
    let result = [];
    Caller.call(url, null, {
      method: 'GET',
      async: false,
      block: false,
    }).then(
      data => {
        result = data;
      },
      () => {
        MessageHelper.error(
          i18n.PartyMsg.ErrorMsg.Error_0009,
          i18n.PartyMsg.ErrorMsg.Error_0005,
          MessageHelper.POSITION_TOP_RIGHT
        );
      }
    );
    return result;
  },

  getCodeTableTemp(codeTableId, conditions, settings) {

    const url = UrlConfig.loadCodeTableByCondition + codeTableId;
    let result = [];
    Caller.call(url, conditions, settings).then(
      data => {
        result = data;
      },
      () => {
        MessageHelper.error(
          i18n.PartyMsg.ErrorMsg.Error_0009,
          i18n.PartyMsg.ErrorMsg.Error_0005,
          MessageHelper.POSITION_TOP_RIGHT
        );
      }
    );
    return result;
  },

  getDelegationUser() {
    const url = UrlConfig.getDelegationUsers;
    let result = [];
    const settings = { method: 'POST', async: false, block: false };
    const conditions = ['Underwriter', 'OPS'];
    Caller.call(url, conditions, settings).then(
      data => {
        result = data;
      },
      () => {
        MessageHelper.error(
          i18n.PartyMsg.ErrorMsg.Error_0009,
          i18n.PartyMsg.ErrorMsg.Error_0005,
          MessageHelper.POSITION_TOP_RIGHT
        );
      }
    );
    return result;
  },

  getUserByBranchId() {
    const url = UrlConfig.getBranchUserUrl;
    let result = [];
    const settings = { method: 'POST', async: false, block: false };
    Caller.call(url, null, settings).then(
      data => {
        result = data;
      },
      () => {
        MessageHelper.error(
          i18n.PartyMsg.ErrorMsg.Error_0009,
          i18n.PartyMsg.ErrorMsg.Error_0005,
          MessageHelper.POSITION_TOP_RIGHT
        );
      }
    );
    return result;
  },

  getQuotationVersion(policyId) {
    const url = `${UrlConfig.getQuotationVersionCodeTable}?policyId=${policyId}`;
    let result = [];
    const settings = { method: 'POST', async: false, block: false };
    Caller.call(url, null, settings).then(
      data => {
        result = data;
      },
      () => {
        MessageHelper.error(
          i18n.PartyMsg.ErrorMsg.Error_0009,
          i18n.PartyMsg.ErrorMsg.Error_0005,
          MessageHelper.POSITION_TOP_RIGHT
        );
      }
    );
    return result;
  },

  getAgentCode() {
    return RemoteCodeTable.AgentCodeCache(-105);
  },

  geRegion(countryCode, regionLevel, parentRegionId) {
    return getRegionData(countryCode, regionLevel, parentRegionId);
  },
};

export default CodeTableService;
