import {
  UIText,
  UISelect,
  UIDataTable,
  UIColumn,
  UICurrency,
  UIPercent,
  UIBox,
  UIButton,
  Caller,
  MessageHelper,
  DataContext,
  UIDialog,
} from 'RainbowUI';
import CommonCodeTable from '../../codetable/CodeTable';
import urlConfig from '../../../../UrlConfig';

const RiskBrokerByClaim = React.createClass({
  getInitialState() {
    return {
      TreatyDetail: {},
    };
  },
  componentWillMount() {
    const urlData = DataContext.get('UrlData');
    console.log('urlData===1111', urlData);
    const ids = DataContext.get('Ids');
    const ContractID = urlData.Priority;
    const { RiclaimInfo } = urlData;
    const CurrencyCode = RiclaimInfo.split(':')[0];
    const FinalCurrencyCode = RiclaimInfo.split(':')[1];
    const SettledAmount = RiclaimInfo.split(':')[2];
    const FinalSettledAmount = RiclaimInfo.split(':')[3];
    const treatyCode = urlData.TreatyCode.replace(/%26/g, '&');
    let results = null;
    if (ids) {
      const url = urlConfig.loadQueryRiDetail + ids;
      console.log('url==========', url);
      Caller.call(url, null, {
        method: 'GET',
        async: false,
        block: true,
      }).then(
        data => {
          if (data.Status === 'OK') {
            const model = JSON.parse(data.Model);
            results = model;
          }
        },
        err => {
          MessageHelper.error(
            err.responseJSON.Messages[0].Message,
            i18n.Reinsurance.ErrorMsg.ERROR
          );
          console.error('========err:', err);
        }
      );
    }
    console.log('results====', results);
    const flag = ids.split(':')[1];
    let TreatyDetail;
    if (flag == '4') {
      TreatyDetail = results.RiPolicyRiskUnitContent;
    } else if (flag == '1') {
      TreatyDetail =
        results.RiPolicyRiskUnitHistory
          .RiPolicyRiskUnitHistoryContent;
    }
    this.setState({
      TreatyCode: treatyCode,
      TreatyDetail,
      ContractID,
      SettledAmount,
      FinalSettledAmount,
      CurrencyCode,
      FinalCurrencyCode,
    });
  },
  onClickBack() {
    UIDialog.hide('detailMessageByClaim');
  },
  render() {
    const _self = this;
    // if(_self.state.TreatyDetail.CessionType==ReinsuranceConstant.CessionType_QuotaShare
    //  ||_self.state.TreatyDetail.CessionType==ReinsuranceConstant.CessionType_Surplus){
    if (_self.state.TreatyCode === 'Facultative Outwards') {
      return (
        <div>
          {_self.renderFacBrokerTable()}
          <UIBox direction="center">
            <UIButton value={i18n.Back} onClick={_self.onClickBack} />
          </UIBox>
        </div>
      );
    }
    if (_self.state.TreatyCode === 'Facultative XOL') {
      return (
        <div>
          {_self.renderXOLBrokerTable()}
          <UIBox direction="center">
            <UIButton value={i18n.Back} onClick={_self.onClickBack} />
          </UIBox>
        </div>
      );
    }
    return (
      <div>
        {_self.renderQuotaShareOrSurplusBrokerTable()}
        <UIBox direction="center">
          <UIButton value={i18n.Back} onClick={_self.onClickBack} />
        </UIBox>
      </div>
    );
  },
  renderQuotaShareOrSurplusBrokerTable() {
    const _self = this;
    const { CurrencyCode, FinalCurrencyCode } = this.state;
    const commonCodeTable = new CommonCodeTable();
    let list = {};
    _.each(
      _self.state.TreatyDetail.RiPolicyTreatyCessions,
      riPolicyTreatyCession => {
        if (
          _self.state.TreatyCode == riPolicyTreatyCession.TreatyCode
        ) {
          console.info(
            'renderQuotaShareOrSurplusBrokerTable ',
            riPolicyTreatyCession.TreatyCode
          );
          list = riPolicyTreatyCession;
        }
      }
    );
    const dataTableList = [];
    let index = 0;
    if (list.CessionAmount > 0) {
      _.each(
        list.RiPolicyTreatyReinsurers,
        tmpriPolicyTreatyReinsurer => {
          const riPolicyTreatyReinsurer = tmpriPolicyTreatyReinsurer;
          if (riPolicyTreatyReinsurer !== 0) {
            riPolicyTreatyReinsurer.ContractID =
              _self.state.ContractID;
            riPolicyTreatyReinsurer.Index = index;
            dataTableList.push(riPolicyTreatyReinsurer);
            index += 1;
          }
        }
      );
    }
    if (list.CessionType == '4') {
      _.each(
        list.RiPolicyTreatyReinsurers,
        tmpriPolicyTreatyReinsurer => {
          const riPolicyTreatyReinsurer = tmpriPolicyTreatyReinsurer;
          if (riPolicyTreatyReinsurer !== 0) {
            riPolicyTreatyReinsurer.ContractID =
              _self.state.ContractID;
            riPolicyTreatyReinsurer.Index = index;
            dataTableList.push(riPolicyTreatyReinsurer);
            index += 1;
          }
        }
      );
    }
    console.log('list====', dataTableList);
    const currencyCode = CurrencyCode;
    const finalCurrencyCode = FinalCurrencyCode;
    let exchangeRate = 1;
    const { SettledAmount } = this.state;
    const { FinalSettledAmount } = this.state;
    exchangeRate = Number(FinalSettledAmount) / Number(SettledAmount);
    return (
      <UIDataTable
        id="brokerTable"
        value={dataTableList}
        pageable="false"
        indexable="false"
      >
        <UIColumn
          headerTitle={i18n.reinsuranceCompany}
          value="ReinsurerId"
        >
          <UISelect
            codeTable={commonCodeTable.getReinsuranceCompany}
            io="out"
          />
        </UIColumn>

        <UIColumn
          headerTitle={i18n.reinsuranceBroker}
          value="ReinsurerBrokerId"
        >
          <UISelect
            codeTable={commonCodeTable.getReinsuranceBroker}
            io="out"
          />
        </UIColumn>

        <UIColumn
          headerTitle={i18n.share}
          render={data => {
            return (
              <UIPercent
                id={`PERCENT_CODE_SHARE_RATE_${data.Index}`}
                disabled="true"
                value={data.TreatyShareRate}
                symbolPosition="none"
              />
            );
          }}
        />

        <UIColumn
          headerTitle={i18n.riRecoveryAmount}
          render={data => {
            const value = Number(
              SettledAmount * data.TreatyShareRate
            ).toFixed(2);
            return (
              <UICurrency
                id={`CURRENCY_CODE_LEFT_1000${data.Index}`}
                value={value}
                unit={window.EU00.getCurrencySignByCurrencyCode(
                  currencyCode
                )}
                disabled="true"
              />
            );
          }}
        />

        <UIColumn
          headerTitle={i18n.exchangeRate}
          render={data => {
            const value = Number(exchangeRate).toFixed(6);
            return <UIText io="out" value={value} />;
          }}
        />

        <UIColumn
          headerTitle={i18n.finalRiRecoveryAmount}
          render={data => {
            const value = Number(
              FinalSettledAmount * data.TreatyShareRate
            ).toFixed(2);
            return (
              <UICurrency
                id={`CURRENCY_CODE_LEFT_2000${data.Index}`}
                value={value}
                unit={window.EU00.getCurrencySignByCurrencyCode(
                  finalCurrencyCode
                )}
                disabled="true"
              />
            );
          }}
        />
      </UIDataTable>
    );
  },
  renderXOLBrokerTable() {
    const _self = this;
    const commonCodeTable = new CommonCodeTable();
  },

  renderFacBrokerTable() {
    const _self = this;
    const contractID = 1;
    let Index = 1;
    const dataList = [];
    const riPolicyFacOutPropotionCession =
      _self.state.TreatyDetail.RiPolicyFacOutPropotionCession;
    console.log(
      'riPolicyFacOutPropotionCession====',
      riPolicyFacOutPropotionCession
    );
    const treatyCurrencyCode =
      _self.state.TreatyDetail.TreatyCurrencyCode;
    _.each(
      riPolicyFacOutPropotionCession.RiPolicyFacOutReinsurers,
      tempdata1 => {
        const data1 = tempdata1;
        data1.contractID = _self.state.ContractID;
        data1.Index = Index;
        Index += 1;
        dataList.push(data1);
      }
    );
    console.info('dataList: ', dataList);
    const commonCodeTable = new CommonCodeTable();
    const { ClaimCurrency } = this.state;
    let exchangeRate = 1;
    const { SettledAmount } = this.state;
    const { FinalSettledAmount } = this.state;
    exchangeRate = Number(FinalSettledAmount) / Number(SettledAmount);
    return (
      <UIDataTable
        id="brokerTable"
        value={dataList}
        pageable="false"
        indexable="false"
      >
        <UIColumn
          headerTitle={i18n.reinsuranceCompany}
          value="ReinsurerId"
        >
          <UISelect
            codeTable={commonCodeTable.getReinsuranceCompany}
            io="out"
          />
        </UIColumn>

        <UIColumn headerTitle={i18n.broker} value="ReinsurerBrokerId">
          <UISelect
            codeTable={commonCodeTable.getReinsuranceBroker}
            io="out"
          />
        </UIColumn>

        <UIColumn
          headerTitle={i18n.share}
          render={data => {
            const tempId = `PERCENT_CODE_BROKER_SHARE_RATE_${data.Index}`;
            return (
              <UIPercent
                id={tempId}
                io="out"
                model={data}
                property="TreatyShareRate"
                decimalPrecision="2"
              />
            );
          }}
        />

        <UIColumn
          headerTitle={i18n.riRecoveryAmount}
          render={data => {
            const value = Number(
              SettledAmount * data.TreatyShareRate
            ).toFixed(2);
            return (
              <UICurrency
                id={`CURRENCY_CODE_LEFT_1000${data.Index}`}
                value={value}
                unit={window.EU00.getCurrencySignByCurrencyCode(
                  ClaimCurrency
                )}
                disabled="true"
              />
            );
          }}
        />

        <UIColumn
          headerTitle={i18n.exchangeRate}
          render={data => {
            const value = Number(exchangeRate).toFixed(2);
            return <UIText io="out" value={value} />;
          }}
        />

        <UIColumn
          headerTitle={i18n.finalRiRecoveryAmount}
          render={data => {
            const value = Number(
              FinalSettledAmount * data.TreatyShareRate
            ).toFixed(2);
            return (
              <UICurrency
                id={`CURRENCY_CODE_LEFT_2000${data.Index}`}
                value={value}
                unit={window.EU00.getCurrencySignByCurrencyCode(
                  treatyCurrencyCode
                )}
                disabled="true"
              />
            );
          }}
        />
      </UIDataTable>
    );
  },
});
export default RiskBrokerByClaim;
