import React from 'react';
import PropTypes from 'prop-types';
import BindToMixin from 'react-binding';
import ClaimSettlement from 'ClaimSettlement';
import SettlementReadOnlyStore from '../store/SettlementReadOnlyStore';

const SettlementLoadAction = ClaimSettlement.getSettlementLoadAction();

const SettlementInfoLoad = ClaimSettlement.getSettlementInfoLoad();
const SettlementReadOnly = React.createClass({
  mixins: [BindToMixin],
  propTypes: {
    settlementId: PropTypes.string,
    isApprove: PropTypes.bool,
  },

  getInitialState() {
    return { SettlementReadOnly: {}, SettlementApprove: {} };
  },
  componentDidMount() {
    SettlementReadOnlyStore.listen(this.onStoreStateChange);
  },
  componentWillMount() {
    SettlementLoadAction.loadSettlementReadOnly(
      this.props.settlementId,
      this.props.isApprove
    );
  },

  componentWillUnmount() {
    SettlementReadOnlyStore.unlisten(this.onStoreStateChange);
  },

  onStoreStateChange(storeState) {
    if (this.props.isApprove == true) {
      if (!_.isEmpty(storeState.SettlementApprove)) {
        this.setState({
          SettlementApprove: storeState.SettlementApprove,
        });
      }
    } else if (!_.isEmpty(storeState.SettlementReadOnly)) {
      this.setState({
        SettlementReadOnly: storeState.SettlementReadOnly,
      });
    }
  },
  render() {
    const _self = this;
    let settlementInfo =
      _self.state.SettlementReadOnly.SettlementInfo;
    let otherInfo = _self.state.SettlementReadOnly.OtherInfo;

    const { isApprove } = this.props;
    if (isApprove == true) {
      settlementInfo = _self.state.SettlementApprove.SettlementInfo;
      otherInfo = _self.state.SettlementApprove.OtherInfo;
    }
    if (_.isEmpty(settlementInfo)) {
      return <div />;
    }
    return (
      <div>
        <SettlementInfoLoad
          history
          settlementInfo={settlementInfo}
          otherInfo={otherInfo}
          readOnly
          isApprove={isApprove}
        />
      </div>
    );
  },
});
export default SettlementReadOnly;
