import alt from '../alt';

const CalculationLoadAction = require('../action/CalculationLoadAction');

class CalculationReadOnlyStore {
  constructor() {
    this.CalculationReadOnly = {
      CalculationInfo: {
        CalculationItem: [],
        CalculationExpense: [],
      },
      OtherInfo: {
        CoverageStructure: [{ CoverageList: [] }],
        CalculationStructure: [],
        ReserveCodeTable: [],
        ReserveStructure: [],
      },
    };

    this.CalculationApprove = {
      CalculationInfo: {
        CalculationItem: [],
        CalculationExpense: [],
      },
      OtherInfo: {
        CoverageStructure: [{ CoverageList: [] }],
        CalculationStructure: [],
        ReserveCodeTable: [],
        ReserveStructure: [],
      },
    };

    this.bindListeners({
      handleLoadCalculationReadOnly:
        CalculationLoadAction.LOAD_CALCULATION_READ_ONLY,
    });
  }

  handleLoadCalculationReadOnly(response) {
    let updateObj = this.CalculationReadOnly;
    if (response.isApproveTask == true) {
      updateObj = this.CalculationApprove;
    }
    updateObj.OtherInfo.CoverageStructure =
      response.data.CoverageStructure;
    updateObj.OtherInfo.CalculationStructure =
      response.data.CalculationStructure;
    updateObj.OtherInfo.ReserveCodeTable =
      response.data.ReserveCodeTable;
    updateObj.OtherInfo.ReserveStructure =
      response.data.ReserveStructure;
    updateObj.CalculationInfo = response.data.CalculationInfo;
  }
}
export default alt.createStore(
  CalculationReadOnlyStore,
  CalculationReadOnlyStore
);
