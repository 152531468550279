import React from 'react';
import {
  UIPanel,
  UIDataTable,
  UIColumn,
  UIRadio,
  DataContext,
  UISmartPanelGrid,
  UIText,
  UICheckbox,
} from 'RainbowUI';
import CommonCodeTable from '../../../codetable/CodeTable';

const PartyGDPRReadOnly = React.createClass({
  getInitialState() {
    let { party } = this.props;
    if (DataContext.get('Party')) {
      party = DataContext.get('Party');
    }
    let { PartyGDPRDataBreachList } = party.PartyGDPR;
    if (!PartyGDPRDataBreachList) {
      PartyGDPRDataBreachList = [];
    }
    const checkBoxDatas = [];
    if (
      party.PartyGDPR.PartyGDPRDataAccess.RestricationOfProcessing ==
      'Y'
    ) {
      checkBoxDatas.push('1');
    }
    if (
      party.PartyGDPR.PartyGDPRDataAccess.DataPortabilityRequest ==
      'Y'
    ) {
      checkBoxDatas.push('2');
    }
    if (
      party.PartyGDPR.PartyGDPRDataAccess.DataRectificationRequest ==
      'Y'
    ) {
      checkBoxDatas.push('3');
    }
    if (
      party.PartyGDPR.PartyGDPRDataAccess.RightOfAccessRequest === 'Y'
    ) {
      checkBoxDatas.push('4');
    }
    if (party.PartyGDPR.PartyGDPRDataAccess.RightToErasure === 'Y') {
      checkBoxDatas.push('5');
    }
    DataContext.get(
      'DataAccessRequestCategory'
    ).checkBoxDatas = checkBoxDatas;
    return {
      party,
      PartyGDPRDataBreachList,
    };
  },

  componentWillMount() {},

  componentDidMount() {},

  render() {
    const self = this;
    return (
      <div>
        {self.renderNote()}
        {self.renderConsentInfo()}
        {self.renderContactInfo()}
        {self.renderMinorIndicator()}
        {self.renderDataAccess()}
        {self.renderDataBreach()}
        {self.renderPrivacyNotice()}
      </div>
    );
  },

  renderNote() {
    return (
      <UIPanel panelTitle={i18n.Note}>
        <UIText
          label={i18n.AddNotes}
          value="#{PartyGDPR.AddNotes}"
          enabled="false"
        />
        <UIText
          label={i18n.Declaration}
          value="#{PartyGDPR.Declaration}"
          enabled="false"
        />
      </UIPanel>
    );
  },

  renderConsentInfo() {
    return (
      <UIPanel panelTitle={i18n.ConsentInfo}>
        <UIRadio
          label={i18n.Didyouconsent}
          enabled="false"
          value="#{PartyGDPR.Consent}"
          required="true"
          codeTable={CommonCodeTable.getGDPR}
          defaultValue="N"
        />
      </UIPanel>
    );
  },

  renderContactInfo() {
    return (
      <UIPanel panelTitle={i18n.ContactInfo}>
        <UIRadio
          label={i18n.DoNotSendEmail}
          enabled="false"
          value="#{PartyGDPR.SendEmail}"
          required="true"
          codeTable={CommonCodeTable.getGDPR}
          defaultValue="N"
        />
        <UIRadio
          label={i18n.DoNotSendSMS}
          enabled="false"
          value="#{PartyGDPR.SendSMS}"
          required="true"
          codeTable={CommonCodeTable.getGDPR}
          defaultValue="N"
        />
        <UIRadio
          label={i18n.ContactForMarketing}
          enabled="false"
          value="#{PartyGDPR.ContactMarketing}"
          required="true"
          codeTable={CommonCodeTable.getGDPR}
          defaultValue="N"
        />
      </UIPanel>
    );
  },

  renderMinorIndicator() {
    return (
      <UIPanel panelTitle={i18n.MinorIndicator}>
        <UIRadio
          label={i18n.IsThisPartyaMinor}
          enabled="false"
          value="#{PartyGDPR.IsMinor}"
          required="true"
          codeTable={CommonCodeTable.getGDPR}
          defaultValue="N"
        />
      </UIPanel>
    );
  },

  renderDataAccess() {
    return (
      <UIPanel panelTitle={i18n.DataAccess}>
        <UISmartPanelGrid column="2">
          <UIRadio
            label={i18n.DataAccessRequestByDataSubject}
            enabled="false"
            value="#{PartyGDPRDataAccess.RequestByDataSubject}"
            codeTable={CommonCodeTable.getGDPR}
            defaultValue="N"
          />
        </UISmartPanelGrid>
        <UISmartPanelGrid column="2">
          <UICheckbox
            label={i18n.DataAccessRequestCategory}
            enabled="false"
            value="#{DataAccessRequestCategory.checkBoxDatas}"
            codeTable={CommonCodeTable.getDataAccessRequestCategory}
          />
        </UISmartPanelGrid>
      </UIPanel>
    );
  },

  renderDataBreach() {
    const { PartyGDPRDataBreachList } = this.state;
    return (
      <UIPanel panelTitle={i18n.DataBreach}>
        {/* <UIBox direction="right">
                    <UILink direction="right" onClick={this.showAddDataBreach.bind(this)} icon="fa fa-plus" size="lg" tooltip={i18n.Add} iconClass ="add_iconbtn" />
                </UIBox> */}
        <UIDataTable id="DataBreach" value={PartyGDPRDataBreachList}>
          <UIColumn
            headerTitle={i18n.BreachIndicator}
            value="BreachIndicator"
          />
          <UIColumn
            headerTitle={i18n.BreachDate}
            value="BreachDate"
          />
          <UIColumn
            headerTitle={i18n.BreachComments}
            value="BreachComments"
          />
          {/* <UIColumn headerTitle={i18n.Action} render={
                            (data) => {
                                return (
                                    <div>
                                        <UILink icon="fa fa-upload" onClick={this.showUploadDialog.bind(this)} >

                                        </UILink>
                                        <UILink icon="fa fa-download"  >

                                        </UILink>
                                    </div>
                                );
                            }.bind(this)}/> */}
        </UIDataTable>
      </UIPanel>
    );
  },

  renderPrivacyNotice() {
    return (
      <UIPanel panelTitle={i18n.PrivacyNotice}>
        <UIRadio
          label={i18n.PrivacyNoticeSend}
          required="true"
          enabled="false"
          value="#{PartyGDPR.PrivacyNoticeSend}"
          codeTable={CommonCodeTable.getGDPR}
          defaultValue="N"
        />
      </UIPanel>
    );
  },
});
export default PartyGDPRReadOnly;
