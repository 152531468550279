import { AjaxUtil } from 'RainbowUI';
import Url from '../url/Url';

export default {
  loadList(pageIndex, pageSize, caseId) {
    const deferred = $.Deferred();
    AjaxUtil.doGet(
      `${Url.PriorClaimsList + caseId}/${pageIndex}/${pageSize}`,
      {},
      {
        async: false,
        done(data) {
          console.log('======getPriorClaimList success====');
          deferred.resolve(data);
        },
        fail(err) {
          console.log('======getPriorClaimList fail====');
          deferred.reject(err);
        },
      }
    );
    return deferred.promise();
  },
};
