import { UIButton } from 'RainbowUI';
import Url from '../url/Url';


const ExitButton = React.createClass({
  getInitialState() {
    return {};
  },
  handleExit() {
    window.location.href = Url.Exit;
  },

  render() {
    return (
      <UIButton
        value={i18n.ClaimCommon.Exit}
        onClick={this.handleExit}
      />
    );
  },
});

export default ExitButton;
