import { CodeTable, MessageHelper, AjaxUtil } from 'RainbowUI';
import ClaimCommonExt from 'ClaimCommonExt';
import VehicleService from '../service/VehicleService';
import InvestigationService from '../service/InvestigationService';

const TriggerTaskTransformDialogAction = ClaimCommonExt.getReserveAction();

class VehicleAction {
  synButtonIsEnable(taskInfo, damageType = 'V') {
    const enabled = InvestigationService.synButtonIsEnable(
      taskInfo,
      damageType
    );
    return enabled;
  }

  synModel(subClaimId) {
    return InvestigationService.synData(subClaimId);
  }

  save(entity, callback) {
    const promise = VehicleService.vehicleSave(entity);
    promise.then(
      data => {
        if (data.Status === 'OK') {
          callback(data);
          MessageHelper.success(
            i18n.ClaimFieldInvestigation.SaveSuccess
          );
        }
      },
      () => {
        MessageHelper.error('', i18n.ClaimFieldInvestigation.Failed);
      }
    );
  }

  submit(entity) {
    const promise = VehicleService.vehicleSubmit(entity);
    promise.then(
      data => {
        if (data.Status === 'OK') {
          MessageHelper.success(
            i18n.ClaimFieldInvestigation.SubmitSuccess
          );
          TriggerTaskTransformDialogAction.reserveApprovalTaskAssigned(
            'TaskComplete'
          );
        }
      },
      () => {
        MessageHelper.error('', i18n.ClaimFieldInvestigation.Failed);
      }
    );
  }

  initData(subCliamId, fiStatus) {
    return InvestigationService.initData(subCliamId, fiStatus);
  }

  getDotInfo(domainObject) {
    const self = this;
    if (!domainObject.PlateNo) {
      MessageHelper.error(
        i18n.ClaimFieldInvestigation.ErrorMsg
          .PleaseentertheRegistration
      );
    } else {
      let result;
      const url = `${
        env.serverUrl
        }/pa/eu00/dot/getVehicleByVrm/${encodeURI(
          domainObject.PlateNo
        ).toUpperCase()}`;
      AjaxUtil.doGet(url, null, {
        async: false,
        done(response) {
          result = response;
          if (result) {
            self.retrieveVehicleFromDOT(domainObject, result);
          }
        },
        fail(error) {
          if (error.responseJSON) {
            MessageHelper.error(
              error.responseJSON.Messages['0'].Message
            );
          } else {
            MessageHelper.error(
              i18n.ClaimFieldInvestigation.ErrorMsg
                .DOTServicenotresponse
            );
          }
          if (result) {
            self.retrieveVehicleFromDOT(domainObject);
          }
        },
      });
    }
    return domainObject;
  }

  getModelCodeTable(maker) {
    let result = [];
    if (!maker) return new CodeTable(result);
    const url = `${env.serverUrl}public/codetable/data/condition/name/Model`;
    const data = { Make: maker };
    AjaxUtil.doPost(url, data, {
      async: false,
      done(response) {
        result = response.map(code => {
          const tmp = {};
          const objArr = Object.keys(code);
          for (let i = 0; i < objArr.length; i += 1) {
            if (objArr[i] === 'Id') tmp.id = code[objArr[i]];
            else if (objArr[i] === 'Description')
              tmp.text = code[objArr[i]];
            else tmp[objArr[i]] = code[objArr[i]];
          }
          return tmp;
        });
      },
    });
    return new CodeTable(result);
  }

  validatePlateNo(domainObject) {
    if (domainObject.IsDot !== 'Y') {
      return true;
    }
    if (domainObject.PlateNo) {
      const plateno = domainObject.PlateNo;
      if (plateno.length > 6 || plateno.length < 4) {
        MessageHelper.error(
          i18n.ClaimFieldInvestigation.ErrorMsg
            .RegistrationNumberFormat
        );
        return false;
      }
      const prefix = plateno.substring(0, plateno.length - 3);
      const prefix1 = prefix.substring(0, 1);
      let prefix2 = prefix.substring(1, 2);
      let prefix3 = prefix.substring(2, 3);
      if (prefix2 === '') {
        prefix2 = 'X';
      }
      if (prefix3 === '') {
        prefix3 = 'X';
      }
      const sufix = plateno.substring(
        plateno.length - 3,
        plateno.length
      );
      let prefix1is = false;
      let prefix2is = false;
      let prefix3is = false;
      if (
        (prefix1 <= 'Z' && prefix1 >= 'A') ||
        (prefix1 <= 'z' && prefix1 >= 'a')
      ) {
        prefix1is = true;
      }
      if (
        (prefix2 <= 'Z' && prefix2 >= 'A') ||
        (prefix2 <= 'z' && prefix2 >= 'a')
      ) {
        prefix2is = true;
      }
      if (
        (prefix3 <= 'Z' && prefix3 >= 'A') ||
        (prefix3 <= 'z' && prefix3 >= 'a')
      ) {
        prefix3is = true;
      }
      if (
        Number.isNaN(sufix) ||
        prefix1is == false ||
        prefix2is == false ||
        prefix3is == false
      ) {
        MessageHelper.error(
          i18n.ClaimFieldInvestigation.ErrorMsg
            .RegistrationNumberFormat
        );
        return false;
      }
      return true;
    }
    return false;
  }

  retrieveVehicleFromDOT(tmpdomainObject, result) {
    const domainObject = tmpdomainObject;
    let table4;
    if (result) {
      domainObject.ModelYear = result.RegistrationDate;
      domainObject.Vin = result.Vin;
      domainObject.Model = result.ModelDescription;
      table4 = [
        {
          id: result.ModelDescription,
          text: `${result.ModelDescription}`,
        },
      ];
      window.modelCodeTable = new CodeTable(table4);
      domainObject.Maker = result.MakeCode;
      const table2 = [
        { id: result.MakeCode, text: `${result.MakeCodeDesc}` },
      ];
      window.vehicleMakeCodeTable = new CodeTable(table2);
      const dateyear = new Date(result.ManufactureDate);
      const year = dateyear.getFullYear();
      domainObject.MakerYear = year;
    } else {
      domainObject.Vin = null;
      domainObject.Maker = null;
      domainObject.Model = null;
      domainObject.ModelYear = null;
      domainObject.MakerYear = null;
      table4 = [];
      window.modelCodeTable = new CodeTable(table4);
      domainObject.VehBodyTypeCd = null;
      const table1 = [];
      window.bodyTypeCodeTable = new CodeTable(table1);

      domainObject.VehTypeCd = null;
      const table3 = [];
      window.vehicleCategoryCodeTable = new CodeTable(table3);
      domainObject.DotReceive = null;
    }
    return domainObject;
  }
}

const VehicleActionInstance = new VehicleAction();
export default VehicleActionInstance;
