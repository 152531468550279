import {
  UICell,
  UIText,
  UIButton,
  UIBox,
  UISmartPanelGrid,
} from 'RainbowUI';
import BindToMixin from 'react-binding';

const ProductLineAction = require('../action/ProductLineAction');
const ProductTreeNodeStore = require('../store/ProductTreeNodeStore');

const ProductLine = React.createClass({
  mixins: [BindToMixin],

  getInitialState() {
    return { count: 0, claimProductLineEntity: {} };
  },

  componentWillMount() {
    const productLine = ProductLineAction.getProductLine();

    this.setState({ claimProductLineEntity: productLine.result });
    ProductTreeNodeStore.listen(this.onStoreStateChange);
  },

  componentWillUnmount() {
    ProductTreeNodeStore.unlisten(this.onStoreStateChange);
  },

  onStoreStateChange(storeState) {
    if (!_.isEmpty(storeState.ProductTreeEntity)) {
      if (storeState.ProductTreeEntity.treeNodeUrl === 'pt') {
        const productLine = ProductLineAction.getProductLine();

        this.setState({ claimProductLineEntity: productLine.result });
      }
    }
  },

  clickSave() {
    const { claimProductLineEntity } = this.state;
    ProductLineAction.save(claimProductLineEntity);
    this.setState(prevState => ({ count: prevState.count + 1 }));
  },

  render() {
    const { claimProductLineEntity } = this.state;
    return (
      <div>
        <UISmartPanelGrid column="2" widthAllocation="4,8">
          <UIText
            id="productCode"
            label={i18n.ClaimProduct.ProductLineCode}
            value={claimProductLineEntity.ProductLineCode}
            io="out"
          />

          <UIText
            id="productName"
            label={i18n.ClaimProduct.ProductLineName}
            valueLink={this.bindToState(
              'claimProductLineEntity',
              'ProductLineName'
            )}
            required="true"
            validationGroup="productName"
          />

          <UIText
            id="productLineDescription"
            label={i18n.ClaimProduct.ProductLineDescription}
            valueLink={this.bindToState(
              'claimProductLineEntity',
              'ProductLineDesc'
            )}
          />

          <UIText
            id="PolicySysCode"
            label={i18n.ClaimProduct.PolicySysCode}
            valueLink={this.bindToState(
              'claimProductLineEntity',
              'ExtProductLineCode'
            )}
            colspan="2"
          />
        </UISmartPanelGrid>
        <UICell>
          <UIBox direction="left">
            <UIButton
              value={i18n.ClaimProduct.Save}
              onClick={this.clickSave}
              causeValidation="true"
              validationGroup="productName"
            />
          </UIBox>
        </UICell>
      </div>
    );
  },
});
export default ProductLine;
