import React from 'react';
import PropTypes from 'prop-types';
import BindToMixin from 'react-binding';

const SubrogationCoverageInfo = require('./SubrogationCoverageInfo');

const SubrogationInsuredInfo = React.createClass({
  mixins: [BindToMixin],

  propTypes: {
    subrogationInfo: PropTypes.object.isRequired,
    insured: PropTypes.object.isRequired,
    coverageCode: PropTypes.string.isRequired,
    otherInfo: PropTypes.object.isRequired,
    readOnly: PropTypes.bool,
    isApproveTask: PropTypes.bool,
  },

  render() {
    const componentId = this.props;
    const { insured } = this.props;
    const { readOnly } = this.props;
    const { isApproveTask } = this.props;
    const { subrogationInfo } = this.props;
    const { otherInfo } = this.props;
    const { history } = this.props;
    const coverageList = this.bindArrayTo(insured, 'CoverageList');
    if (_.isEmpty(coverageList)) {
      return <div />;
    }
    const coverageInfo = coverageList.items.map((coverage, index) => {
      return (
        <div
          id={`div_${insured.value.InsuredId}${readOnly}${isApproveTask}`}
        >
          <SubrogationCoverageInfo
            insured={insured}
            history={history}
            coverage={coverage.sourceObject}
            subrogationInfo={subrogationInfo}
            otherInfo={otherInfo}
            readOnly={readOnly}
            isApproveTask={isApproveTask}
            componentId={`${componentId}_${index}`}
          />
        </div>
      );
    });
    return <div>{coverageInfo}</div>;
  },
});
export default SubrogationInsuredInfo;
