import { ReserveAction } from 'ClaimCommon';
import ClaimSettlement from 'ClaimSettlement';

const SettlementLoadAction = ClaimSettlement.getSettlementLoadAction();
const SettlementHistoryAction = require('./SettlementHistoryAction');

export default {
  changeSettlement(event) {
    const taskInstanceId = event.getParameter('taskInstanceId');
    const caseId = event.getParameter('caseId');
    const taskCode = event.getParameter('taskCode');
    const policyNo = event.getParameter('policyNo');
    if (
      taskInstanceId === null ||
      taskInstanceId === undefined ||
      taskInstanceId === '' ||
      caseId === null ||
      caseId === undefined ||
      caseId === '' ||
      !taskCode
    ) {
      /* empty */
    } else {
      if (policyNo) {
        SettlementLoadAction.checkCoInsurance(
          caseId,
          policyNo,
          taskInstanceId
        );
      }
      SettlementLoadAction.loadSettlementTask(taskInstanceId);
      SettlementHistoryAction.initData(caseId, taskCode);
    }
  },
  changeHistory(event) {
    const caseId = event.getParameter('caseId');
    const taskCode = event.getParameter('taskCode');
    if (
      caseId === null ||
      caseId === undefined ||
      caseId === '' ||
      !taskCode
    ) {
      /* empty */
    } else {
      SettlementHistoryAction.initData(caseId, taskCode);
    }
  },

  policyInfo() {},

  taskTransfer() {},

  modifyClaimInfo() {},

  modifySublaimInfo() {},
  changeSubclaim(event) {
    const objectId = event.getParameter('objectId');

    sessionStorage.setItem('claim_objectId', objectId);

    ReserveAction.initReserve(objectId);
  },

  getTaskInstance() {
    let taskInstranceId = 0;
    const urlParam = window.location.hash;
    const begin = urlParam.indexOf('?');
    const paramStr = urlParam.substr(begin + 1);
    const paramArray = paramStr.split('&');
    for (let i = 0; i < paramArray.length; i += 1) {
      if (paramArray[i].split('=')[0] === 'taskInstranceId') {
        taskInstranceId = paramArray[i].split('=')[1];
        break;
      }
    }
    sessionStorage.setItem(
      'Settlement_taskInstanceId',
      taskInstranceId
    );
    return taskInstranceId;
  },
};
