import {
  UIBox,
  UIDialog,
  UIText,
  UIButton,
  UISmartPanelGrid,
  UIUpdatePanel,
} from 'RainbowUI';
import BindToMixin from 'react-binding';

const LossCauseAction = require('../action/LossCauseAction');

const LossCauseDialog = React.createClass({
  mixins: [BindToMixin],

  getInitialState() {
    return { lossCauseTypeEntity: {}, flag: {} };
  },

  showDialog(data, flag) {
    this.setState({ lossCauseTypeEntity: data });
    this.setState({ flag });
    UIDialog.show('lossCauseDialog');
  },

  clickSave(evevt) {
    const result = LossCauseAction.saveLossCause(
      this.state.lossCauseTypeEntity
    );
    if (result === 'true') {
      this.props.parentComponet.updatePage();
      UIDialog.hide('lossCauseDialog');
    }
  },

  render() {
    return (
      <UIDialog
        id="lossCauseDialog"
        title={i18n.ClaimProduct.CauseofLoss}
        width="80%"
        height="600px"
        modal="false"
      >
        <UIUpdatePanel
          id="lossCauseUIUpdate"
          render={this.renderDialogContent}
        />
        <UIBox direction="left">
          <UIButton
            value={i18n.ClaimProduct.Save}
            onClick={this.clickSave}
            validationGroup="lossCause"
            causeValidation="true"
          />
          <UIButton
            value={i18n.ClaimProduct.Cancel}
            buttonType="cancel"
          />
        </UIBox>
      </UIDialog>
    );
  },
  renderDialogContent() {
    const _self = this;
    const flag = _self.state.flag.out;
    return (
      <UISmartPanelGrid column="2" widthAllocation="4,8">
        <UIText
          io={flag}
          id="causeOfLossCode"
          label={i18n.ClaimProduct.CauseofLossCode}
          valueLink={this.bindToState(
            'lossCauseTypeEntity',
            'LossCauseCode'
          )}
          required="true"
          validationGroup="lossCause"
          render={data => {
            if (data.typeCode !== '') {
              _self.init();
            }
          }}
        />
        <UIText
          id="causeOfLoss"
          label={i18n.ClaimProduct.CauseofLossName}
          valueLink={this.bindToState(
            'lossCauseTypeEntity',
            'LossCauseName'
          )}
          required="true"
          validationGroup="lossCause"
        />
        <UIText
          colspan="2"
          id="lossCauseeDescription"
          label={i18n.ClaimProduct.CauseofLossDescription}
          valueLink={this.bindToState(
            'lossCauseTypeEntity',
            'LossCauseDescription'
          )}
        />
      </UISmartPanelGrid>
    );
  },
});
export default LossCauseDialog;
