import { MessageHelper, Caller, UIDialog, Cache } from 'RainbowUI';
import UrlConfig from '../../../UrlConfig';
import PartyConst from '../../party/const/PartyConst';
import PartyAction from '../../party/actions/PartyAction';

export default class JCCPaymentAction {
  queryReceiptAmount(refNo, refType, currency) {
    const url = `${UrlConfig.queryPaidAmount}/${refNo}/${refType}/${currency}`;
    let result = {};
    Caller.call(url, null, {
      method: 'GET',
      async: false,
      block: true,
    }).then(data => {
      result = data;
    });
    return result.Model;
  }

  savePaymentLog(paymentLog, withoutCollection) {
    let url = UrlConfig.createNewJCCPayment;
    if (withoutCollection) {
      url = UrlConfig.createNewJCCPaymentWithoutCollection;
    }
    let result = {};
    Caller.call(url, paymentLog, {
      method: 'POST',
      async: false,
      block: true,
    }).then(data => {
      result = data;
    });
    if (result.Model) {
      return result.Model;
    }
    MessageHelper.warning('There is pending payment, please wait.');

    UIDialog.hide('paymentAmountDialog');
    UIDialog.hide('paymentPosAuthCodeDialog');
  }

  queryBalance(refNo, refType) {
    const url = `${UrlConfig.getTotalBalanceByRefNo}/${refNo}/${refType}`;
    let result = {};
    Caller.call(url, null, {
      method: 'GET',
      async: false,
      block: true,
    }).then(data => {
      result = data;
    });
    return result.Model;
  }

  queryOnlinePaymentAndDoCollection(orderId, withoutCollection) {
    let url = UrlConfig.updateJCCPaymentRecord;
    if (withoutCollection) {
      url = UrlConfig.updateJCCPaymentRecordWithoutCollection;
    }
    url = `${url}/${orderId}`;
    let result = {};
    Caller.call(url, null, {
      method: 'GET',
      async: false,
      block: true,
    }).then(data => {
      result = data;
    });
    if (!result.Model || result.Model.ProcessStatus == 2) {
      UIDialog.show('paymentFail');
    }
    return result.Model;
  }

  checkPremByPolicyAndBuildPayment(policy, issued) {
    const paymentAction = new JCCPaymentAction();
    let onlinePaymentFlag = true;
    if (
      !policy.PolicyPaymentInfoList ||
      policy.PolicyPaymentInfoList.length === 0
    ) {
      return {};
    }
    const policyPaymentInfo = policy.PolicyPaymentInfoList[0];
    if (
      policyPaymentInfo.PayMode !== 108 &&
      policyPaymentInfo.PayMode !== 107
    ) {
      onlinePaymentFlag = false;
    }
    const paymentPlanList = policy.PaymentPlanOptionList;
    let paymentPlanOption = null;
    for (let i = 0; i < paymentPlanList.length; i += 1) {
      if (paymentPlanList[i].IsSelected == '1') {
        paymentPlanOption = paymentPlanList[i];
        break;
      }
    }
    if (issued) {
      const result = {};
      if (!onlinePaymentFlag) {
        return {};
      }
      const feeInfo = paymentAction.queryBalance(policy.PolicyNo, 1);
      result.maxPremium = feeInfo.Balance;
      if (result.maxPremium == 0) {
        return result;
      }
      result.minPremium = 0;
      const paymentLog = paymentAction.buildPayment(
        policy,
        policy.PremiumCurrencyCode,
        feeInfo.FeeIds
      );
      if (!paymentLog) {
        return result;
      }
      paymentLog.ReferenceNo = policy.PolicyNo;
      paymentLog.ReferenceNoType = 3;
      result.Status = 'show';
      result.paymentLog = paymentLog;
      return result;
    }
    const result = paymentAction.calculatePrePaidPremium(
      policy,
      paymentPlanOption,
      policy.AdjustedPremium,
      policy.QuotationNo,
      1,
      policy.PremiumCurrencyCode,
      onlinePaymentFlag
    );
    if (!result.Status || result.Status === 'block') {
      return result;
    }
    const paymentLog = paymentAction.buildPayment(
      policy,
      policy.PremiumCurrencyCode,
      null
    );
    paymentLog.MaxPrePaidPermium = policy.AdjustedPremium;
    paymentLog.ReferenceNo = policy.QuotationNo;
    paymentLog.ReferenceNoType = 1;
    result.paymentLog = paymentLog;

    return result;
  }

  checkPremByEndoAndBuildPayment(
    endorsement,
    paymentPlan,
    issued,
    endorsementNo
  ) {
    const paymentAction = new JCCPaymentAction();
    let onlinePaymentFlag = true;
    if (
      !endorsement.PolicyPaymentInfoList ||
      endorsement.PolicyPaymentInfoList.length === 0
    ) {
      return {};
    }
    const policyPaymentInfo = endorsement.PolicyPaymentInfoList[0];
    if (
      policyPaymentInfo.PayMode !== 108 &&
      policyPaymentInfo.PayMode !== 107
    ) {
      onlinePaymentFlag = false;
    }
    if (endorsement.AdjustedEndoPremiumDifference <= 0) {
      return {};
    }
    if (issued) {
      if (!onlinePaymentFlag) {
        return {};
      }
      const result = {};
      const feeInfo = paymentAction.queryBalance(endorsementNo, 2);
      result.maxPremium = feeInfo.Balance;
      if (result.maxPremium <= 0) {
        return result;
      }
      result.minPremium = 0;
      const paymentLog = paymentAction.buildPayment(
        endorsement,
        endorsement.Policy.PremiumCurrencyCode,
        feeInfo.FeeIds
      );
      if (!paymentLog) {
        return result;
      }
      paymentLog.ReferenceNo = endorsementNo;
      paymentLog.ReferenceNoType = 3;
      result.paymentLog = paymentLog;
      result.Status = 'show';
      return result;
    }
    const result = paymentAction.calculatePrePaidPremium(
      endorsement,
      paymentPlan,
      endorsement.AdjustedEndoPremiumDifference,
      endorsement.EndoId,
      2,
      endorsement.Policy.PremiumCurrencyCode,
      onlinePaymentFlag
    );
    if (!result.Status || result.Status === 'block') {
      return result;
    }
    const paymentLog = paymentAction.buildPayment(
      endorsement,
      endorsement.Policy.PremiumCurrencyCode,
      null
    );
    paymentLog.ReferenceNo = endorsement.EndoId;
    paymentLog.ReferenceNoType = 2;
    paymentLog.MaxPrePaidPermium =
      endorsement.AdjustedEndoPremiumDifference;
    result.paymentLog = paymentLog;

    return result;
  }

  buildPayment(data, currency, feeIds) {
    const policyPaymentInfo = data.PolicyPaymentInfoList[0];
    if (
      policyPaymentInfo.PayMode !== 108 &&
      policyPaymentInfo.PayMode !== 107
    ) {
      MessageHelper.warning(
        'Mayment Method is not appliable for Online payment please check'
      );
      return null;
    }
    const paymentAction = new JCCPaymentAction();
    const partyAction = new PartyAction();
    const paymentLog = {};
    paymentLog.PayerId = policyPaymentInfo.PayerPayeeId;
    const party = partyAction.loadPartyByPartyId(paymentLog.PayerId);
    paymentLog.PayerType = paymentAction.getPartyType(
      data.CAPType,
      party.PartyType
    );
    if (feeIds) {
      paymentLog.IsPrePaid = 'N';
      paymentLog.PaymentWithIssance = 'N';
      paymentLog.FeeIds = feeIds;
      paymentLog.ApplicationUserId = Cache.get('currentUser').UserId;
    } else {
      paymentLog.IsPrePaid = 'Y';
      paymentLog.PaymentWithIssance = 'Y';
    }
    paymentLog.Currency = currency;
    paymentLog.PaymentMethod = policyPaymentInfo.PayMode;
    paymentLog.BranchCode = Cache.get('currentUser').OrgId;
    paymentLog.ReferencePerson = party.PartyName;
    paymentLog.RequestSystem = 'PA';
    paymentLog.ProcessStatus = 1;
    return paymentLog;
  }

  getPartyType(CAPType, partyType) {
    if (CAPType == 1) {
      if (partyType == 1) {
        // ind
        return PartyConst.PARTY_ROLE_CODE_IND_CUSTOMER;
      }
      // org
      return PartyConst.PARTY_ROLE_CODE_ORG_CUSTOMER;
    }
    if (CAPType == 2) {
      if (partyType == 1) {
        // ind
        return PartyConst.PARTY_ROLE_CODE_IND_PRODUCER;
      }
      // org
      return PartyConst.PARTY_ROLE_CODE_ORG_PRODUCER;
    }
    return PartyConst.PARTY_ROLE_CODE_IND_EMPLOYEE;
  }

  calculatePrePaidPremium(
    model,
    paymentPlanOption,
    premiumDue,
    refNo,
    isEndo,
    currency,
    onlinePaymentFlag
  ) {
    let minPremium = 0;
    const paymentAction = new JCCPaymentAction();
    const result = {};
    if (
      paymentPlanOption.DownPayment !== 'Y' ||
      model.IsFollowOriginalPlan === 'Y'
    ) {
      minPremium = 0;
    } else {
      minPremium = paymentPlanOption.DownPaymentAmount;
    }
    if (minPremium > premiumDue) {
      result.Status = 'block';
      return result;
    }
    if (!onlinePaymentFlag) {
      return {};
    }
    const receiptAmount = paymentAction.queryReceiptAmount(
      refNo,
      isEndo,
      currency
    );
    const maxPremium = premiumDue - receiptAmount;
    minPremium -= receiptAmount;
    if (maxPremium <= 0) {
      result.Status = false;
    } else if (minPremium <= 0) {
      minPremium = 0;
    }
    result.Status = 'show';
    result.maxPremium = maxPremium;
    result.minPremium = minPremium;
    return result;
  }

  gotoPayment(args) {
    const url =
      'https://tjccpg.jccsecure.com/EcomPayment/RedirectAuthLink';
    const form = $("<form method='post' target='_blank'></form>");
    let input;
    form.attr({ action: url });
    $.each(args, (key, value) => {
      input = $("<input type='hidden'>");
      input.attr({ name: key });
      input.val(value);
      form.append(input);
    });
    form.appendTo(document.body);
    form.submit();
    document.body.removeChild(form[0]);
    UIDialog.show('PaymentPendingDialog');
  }
}
