import {
  UIText,
  UIParam,
  UILink,
  UISelect,
  UIDateTimePicker,
  UIDataTable,
  UIUpdatePanel,
  UIColumn,
  UIPanel,
} from 'RainbowUI';
import DateUtil from '../../../../common/util/DateUtil';
import {
  CommonCodeTable,
  DefinedCodeTable,
} from '../../../codetable/CodeTable';
import PartyConst from '../../../const/PartyConst';
import ViewBankRole from '../partyRoleDialog/ViewBankRole';
import ViewClaimantIndRole from '../partyRoleDialog/ViewClaimantIndRole';
import ViewClaimantOrgRole from '../partyRoleDialog/ViewClaimantOrgRole';
import ViewCollectorIndRole from '../partyRoleDialog/ViewCollectorIndRole';
import ViewCollectorOrgRole from '../partyRoleDialog/ViewCollectorOrgRole';
import ViewClaimantInvestigatorRole from '../partyRoleDialog/ViewClaimantInvestigatorRole';
import ViewOrgClaimantInvestigatorRole from '../partyRoleDialog/ViewClaimantInvestigatorOrgRole';
import ViewDriverIndRole from '../partyRoleDialog/ViewDriverIndRole';
import ViewCustomerIndRole from '../partyRoleDialog/ViewCustomerIndRole';
import ViewCustomerOrgRole from '../partyRoleDialog/ViewCustomerOrgRole';
import ViewEmployeeIndRole from '../partyRoleDialog/ViewEmployeeIndRole';
import ViewGarageIndRole from '../partyRoleDialog/ViewGarageIndRole';
import ViewGarageOrgRole from '../partyRoleDialog/ViewGarageOrgRole';
import ViewHealthCareProviderIndRole from '../partyRoleDialog/ViewHealthCareProviderIndRole';
import ViewHealthCareProviderOrgRole from '../partyRoleDialog/ViewHealthCareProviderOrgRole';
import ViewInsuranceRole from '../partyRoleDialog/ViewInsuranceRole';
import ViewIntroducerIndRole from '../partyRoleDialog/ViewIntroducerIndRole';
import ViewIntroducerOrgRole from '../partyRoleDialog/ViewIntroducerOrgRole';
import ViewLawyerRole from '../partyRoleDialog/ViewLawyerRole';
import ViewLegalFirmRole from '../partyRoleDialog/ViewLegalFirmRole';
import ViewPayeeOrPayerIndRole from '../partyRoleDialog/ViewPayeeOrPayerIndRole';
import ViewPayeeOrPayerOrgRole from '../partyRoleDialog/ViewPayeeOrPayerOrgRole';
import ViewProducerIndRole from '../partyRoleDialog/ViewProducerIndRole';
import ViewProducerOrgRole from '../partyRoleDialog/ViewProducerOrgRole';
import ViewReinsuranceRole from '../partyRoleDialog/ViewReinsuranceRole';
import ViewRiskAssessorIndRole from '../partyRoleDialog/ViewRiskAssessorIndRole';
import ViewRiskAssessorOrgRole from '../partyRoleDialog/ViewRiskAssessorOrgRole';
import ViewRecoveryIndRole from '../partyRoleDialog/ViewRecoveryIndRole';
import ViewRecoveryOrgRole from '../partyRoleDialog/ViewRecoveryOrgRole';
// import DefinedCodeTable from '../../../codetable/CodeTable';

const RoleInfoReadOnly = React.createClass({
  getInitialState() {
    const { party } = this.props;
    return {
      Party: party,
    };
  },
  render() {
    const _self = this;
    const { styleClass } = this.props;
    if (_self.props.party.IsOrgParty === 'Y') {
      /* empty */
    }
    if (_self.props.party.IsOrgParty === 'N') {
      /* empty */
    }
    return (
      <div>
        <UIPanel styleClass={styleClass} panelTitle={i18n.RoleInfo}>
          {_self.renderRoleInfoTable()}
        </UIPanel>
        <ViewBankRole ref="ViewBankRoleDialog" />
        <ViewClaimantIndRole ref="ViewClaimantIndRole" />
        <ViewClaimantOrgRole ref="ViewClaimantOrgRole" />
        <ViewClaimantInvestigatorRole ref="ViewClaimantInvestigatorRole" />
        <ViewOrgClaimantInvestigatorRole ref="ViewOrgClaimantInvestigatorRole" />
        <ViewCollectorIndRole ref="ViewCollectorIndRole" />
        <ViewCollectorOrgRole ref="ViewCollectorOrgRole" />
        <ViewDriverIndRole ref="ViewDriverIndRole" />
        <ViewCustomerIndRole ref="ViewCustomerIndRole" />
        <ViewCustomerOrgRole ref="ViewCustomerOrgRole" />
        <ViewEmployeeIndRole ref="ViewEmployeeIndRole" />
        <ViewGarageIndRole ref="ViewGarageIndRole" />
        <ViewGarageOrgRole ref="ViewGarageOrgRole" />
        <ViewHealthCareProviderIndRole ref="ViewHealthCareProviderIndRole" />
        <ViewHealthCareProviderOrgRole ref="ViewHealthCareProviderOrgRole" />
        <ViewInsuranceRole ref="ViewInsuranceRole" />
        <ViewIntroducerIndRole ref="ViewIntroducerIndRole" />
        <ViewIntroducerOrgRole ref="ViewIntroducerOrgRole" />
        <ViewLawyerRole ref="ViewLawyerRole" />
        <ViewLegalFirmRole ref="ViewLegalFirmRole" />
        <ViewPayeeOrPayerIndRole ref="ViewPayeeOrPayerIndRole" />
        <ViewPayeeOrPayerOrgRole ref="ViewPayeeOrPayerOrgRole" />
        <ViewProducerIndRole ref="ViewProducerIndRole" />
        <ViewProducerOrgRole ref="ViewProducerOrgRole" />
        <ViewReinsuranceRole ref="ViewReinsuranceRole" />
        <ViewRiskAssessorIndRole ref="ViewRiskAssessorIndRole" />
        <ViewRiskAssessorOrgRole ref="ViewRiskAssessorOrgRole" />
        <ViewRecoveryIndRole ref="ViewRecoveryIndRole" />
        <ViewRecoveryOrgRole ref="ViewRecoveryOrgRole" />
      </div>
    );
  },
  viewHandler(event) {
    const data = event.getParameter('data');
    const roleType = data.RoleType;
    if (roleType == PartyConst.PARTY_ROLE_CODE_ORG_BANK) {
      this.refs.ViewBankRoleDialog.showDialog(data);
    } else if (roleType == PartyConst.PARTY_ROLE_CODE_IND_CLAIMANT) {
      this.refs.ViewClaimantIndRole.showDialog(data);
    } else if (
      roleType == PartyConst.PARTY_ROLE_CODE_IND_CLAIMANT_INVESTIGATOR
    ) {
      this.refs.ViewClaimantInvestigatorRole.showDialog(data);
    } else if (
      roleType == PartyConst.PARTY_ROLE_CODE_ORG_CLAIMANT_INVESTIGATOR
    ) {
      this.refs.ViewOrgClaimantInvestigatorRole.showDialog(data);
    } else if (roleType == PartyConst.PARTY_ROLE_CODE_ORG_CLAIMANT) {
      this.refs.ViewClaimantOrgRole.showDialog(data);
    } else if (roleType == PartyConst.PARTY_ROLE_CODE_IND_COLLECTOR) {
      this.refs.ViewCollectorIndRole.showDialog(data);
    } else if (roleType == PartyConst.PARTY_ROLE_CODE_ORG_COLLECTOR) {
      this.refs.ViewCollectorOrgRole.showDialog(data);
    } else if (roleType == PartyConst.PARTY_ROLE_CODE_IND_CUSTOMER) {
      this.refs.ViewCustomerIndRole.showDialog(data);
    } else if (roleType == PartyConst.PARTY_ROLE_CODE_ORG_CUSTOMER) {
      this.refs.ViewCustomerOrgRole.showDialog(data);
    } else if (roleType == PartyConst.PARTY_ROLE_CODE_IND_EMPLOYEE) {
      this.refs.ViewEmployeeIndRole.showDialog(data);
    } else if (roleType == PartyConst.PARTY_ROLE_CODE_IND_GARAGE) {
      this.refs.ViewGarageIndRole.showDialog(data);
    } else if (roleType == PartyConst.PARTY_ROLE_CODE_ORG_GARAGE) {
      this.refs.ViewGarageOrgRole.showDialog(data);
    } else if (
      roleType == PartyConst.PARTY_ROLE_CODE_IND_HEALTHCARE
    ) {
      this.refs.ViewHealthCareProviderIndRole.showDialog(data);
    } else if (
      roleType == PartyConst.PARTY_ROLE_CODE_ORG_HEALTHCARE
    ) {
      this.refs.ViewHealthCareProviderOrgRole.showDialog(data);
    } else if (roleType == PartyConst.PARTY_ROLE_CODE_ORG_INSURANCE) {
      this.refs.ViewInsuranceRole.showDialog(data);
    } else if (
      roleType == PartyConst.PARTY_ROLE_CODE_IND_INTRODUCER
    ) {
      this.refs.ViewIntroducerIndRole.showDialog(data);
    } else if (
      roleType == PartyConst.PARTY_ROLE_CODE_ORG_INTRODUCER
    ) {
      this.refs.ViewIntroducerOrgRole.showDialog(data);
    } else if (
      roleType == PartyConst.PARTY_ROLE_CODE_IND_LAWYERLEGALFIRM
    ) {
      this.refs.ViewLawyerRole.showDialog(data);
    } else if (
      roleType == PartyConst.PARTY_ROLE_CODE_ORG_LAWYERLEGALFIRM
    ) {
      this.refs.ViewLegalFirmRole.showDialog(data);
    } else if (
      roleType == PartyConst.PARTY_ROLE_CODE_IND_PAYEEPAYER
    ) {
      this.refs.ViewPayeeOrPayerIndRole.showDialog(data);
    } else if (
      roleType == PartyConst.PARTY_ROLE_CODE_ORG_PAYEEPAYER
    ) {
      this.refs.ViewPayeeOrPayerOrgRole.showDialog(data);
    } else if (roleType == PartyConst.PARTY_ROLE_CODE_IND_PRODUCER) {
      this.refs.ViewProducerIndRole.showDialog(data);
    } else if (roleType == PartyConst.PARTY_ROLE_CODE_ORG_PRODUCER) {
      this.refs.ViewProducerOrgRole.showDialog(data);
    } else if (
      roleType == PartyConst.PARTY_ROLE_CODE_ORG_REINSURANCE
    ) {
      this.refs.ViewReinsuranceRole.showDialog(data);
    } else if (
      roleType == PartyConst.PARTY_ROLE_CODE_IND_RISKASSESSOR
    ) {
      this.refs.ViewRiskAssessorIndRole.showDialog(data);
    } else if (
      roleType == PartyConst.PARTY_ROLE_CODE_ORG_RISKASSESSOR
    ) {
      this.refs.ViewRiskAssessorOrgRole.showDialog(data);
    } else if (roleType == PartyConst.PARTY_ROLE_CODE_IND_DRIVER) {
      this.refs.ViewDriverIndRole.showDialog(data);
    } else if (
      roleType == PartyConst.PARTY_ROLE_CODE_IND_RECOVER_FROM
    ) {
      this.refs.ViewRecoveryIndRole.showDialog(data);
    } else if (
      roleType == PartyConst.PARTY_ROLE_CODE_ORG_RECOVER_FROM
    ) {
      this.refs.ViewRecoveryOrgRole.showDialog(data);
    }
  },

  renderRoleInfoTable() {
    const _self = this;
    return (
      <UIUpdatePanel id="PartyRole_info_result">
        <UIDataTable
          id="roleTable"
          value={_self.state.Party.PartyRoleList}
          indexable="false"
        >
          <UIColumn
            headerTitle={i18n.PartyRole}
            render={data => {
              let roleName;
              if (_self.props.party.IsOrgParty === 'Y') {
                const map = CommonCodeTable.getOrgPartyRole();
                roleName = map.map[data.RoleType].text;
              }
              if (_self.props.party.IsOrgParty === 'N') {
                const map = CommonCodeTable.getIndPartyRole();
                roleName = map.map[data.RoleType].text;
              }
              return (
                <div>
                  <UILink
                    onClick={_self.viewHandler}
                    value={roleName}
                  >
                    <UIParam name="data" value={data} />
                  </UILink>
                </div>
              );
            }}
          />
          <UIColumn headerTitle={i18n.RoleCode} value="ExternalCode">
            <UIText io="out" />
          </UIColumn>

          <UIColumn headerTitle={i18n.CreateDate} value="InsertTime">
            <UIDateTimePicker io="out" format="MM/DD/YYYY" />
          </UIColumn>

          <UIColumn
            headerTitle={i18n.InactiveDate}
            render={data => {
              if (
                data.Status.toString() != '1' &&
                !(
                  PartyConst.PARTY_ROLE_CODE_IND_PRODUCER ==
                  data.RoleType
                ) &&
                !(
                  PartyConst.PARTY_ROLE_CODE_ORG_PRODUCER ==
                  data.RoleType
                )
              )
                return (
                  <UIDateTimePicker
                    io="out"
                    model={data}
                    property="InactiveDate"
                    format="MM/DD/YYYY"
                  />
                );
              if (
                !(data.Status.toString() == '1') &&
                (PartyConst.PARTY_ROLE_CODE_IND_PRODUCER ==
                  data.RoleType ||
                  PartyConst.PARTY_ROLE_CODE_ORG_PRODUCER ==
                  data.RoleType)
              ) {
                return (
                  <UIDateTimePicker
                    io="out"
                    defaultValue={DateUtil.formatDateTime(
                      new ServerDate()
                    )}
                    model={data}
                    property="InactiveDate"
                    format="MM/DD/YYYY"
                  />
                );
              }
            }}
          />

          <UIColumn
            headerTitle={i18n.Status}
            render={data => {
              if (
                PartyConst.PARTY_ROLE_CODE_IND_PRODUCER ==
                data.RoleType ||
                PartyConst.PARTY_ROLE_CODE_ORG_PRODUCER ==
                data.RoleType
              ) {
                return (
                  <UISelect
                    codeTable={
                      DefinedCodeTable.getPartyRoleProducerStatus
                    }
                    io="out"
                    value={data.Status}
                  />
                );
              }
              return (
                <UISelect
                  codeTable={DefinedCodeTable.getPartyStatus}
                  io="out"
                  value={data.Status}
                />
              );
            }}
          />

          <UIColumn headerTitle={i18n.Remarks} value="Remark">
            <UIText io="out" />
          </UIColumn>
        </UIDataTable>
      </UIUpdatePanel>
    );
  },
});
export default RoleInfoReadOnly;
