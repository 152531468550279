import { UIDialog } from 'RainbowUI';
import BankRole from './partyrole/BankRole';
import CustomerIndRole from './partyrole/CustomerIndRole';
import CustomerOrgRole from './partyrole/CustomerOrgRole';
import DriverIndRole from './partyrole/DriverIndRole';
import InsuranceRole from './partyrole/InsuranceRole';
import ProducerOrgRole from './partyrole/ProducerOrgRole';
import ProducerIndRole from './partyrole/ProducerIndRole';
import ReinsuranceRole from './partyrole/ReinsuranceRole';
import RiskAssessorOrgRole from './partyrole/RiskAssessorOrgRole';
import RiskAssessorIndRole from './partyrole/RiskAssessorIndRole';
import GarageIndRole from './partyrole/GarageIndRole';
import GarageOrgRole from './partyrole/GarageOrgRole';
import LawyerRole from './partyrole/LawyerRole';
import LegalFirmRole from './partyrole/LegalFirmRole';
import HealthCareProviderOrgRole from './partyrole/HealthCareProviderOrgRole';
import HealthCareProviderIndRole from './partyrole/HealthCareProviderIndRole';
import PayeeOrPayerOrgRole from './partyrole/PayeeOrPayerOrgRole';
import PayeeOrPayerIndRole from './partyrole/PayeeOrPayerIndRole';
import ClaimantInvestigatorRole from './partyrole/ClaimantInvestigatorRole';
import ClaimantInvestigatorOrgRole from './partyrole/ClaimantInvestigatorOrgRole';
import ClaimantOrgRole from './partyrole/ClaimantOrgRole';
import ClaimantIndRole from './partyrole/ClaimantIndRole';
import CollectorOrgRole from './partyrole/CollectorOrgRole';
import CollectorIndRole from './partyrole/CollectorIndRole';
import EmployeeIndRole from './partyrole/EmployeeIndRole';
import IntroducerOrgRole from './partyrole/IntroducerOrgRole';
import IntroducerIndRole from './partyrole/IntroducerIndRole';
import RecoverFromIndRole from './partyrole/RecoverFromIndRole';
import RecoverFromOrgRole from './partyrole/RecoverFromOrgRole';
import CarRentalRole from './partyrole/CarRentalRole';
import ProducerSupportOfficerOrgRole from './partyrole/ProducerSupportOfficerOrgRole';
import ProducerSupportOfficerIndRole from './partyrole/ProducerSupportOfficerIndRole';
import ForgottenRole from './partyrole/ForgottenRole';

const RoleDetail = React.createClass({
  getInitialState() {
    const { partyId } = this.props;
    return {
      PartyId: partyId,
      RoleList: this.props.RoleList,
    };
  },

  render() {
    const { partyRoleSelected } = this.props;
    const view = [];
    view.push(this.createLawyerParty(partyRoleSelected));
    view.push(this.createLegalFirmParty(partyRoleSelected));
    view.push(this.createGarageOrgParty(partyRoleSelected));
    view.push(this.createGarageIndParty(partyRoleSelected));
    view.push(this.createRiskAssessorOrgParty(partyRoleSelected));
    view.push(this.createRiskAssessorIndParty(partyRoleSelected));
    view.push(this.createProducerOrgParty(partyRoleSelected));
    view.push(this.createProducerIndParty(partyRoleSelected));
    view.push(this.createCustomerOrgParty(partyRoleSelected));
    view.push(this.createCustomerIndParty(partyRoleSelected));
    view.push(this.createPayeeOrPayerOrgParty(partyRoleSelected));
    view.push(this.createPayeeOrPayerIndParty(partyRoleSelected));
    view.push(this.createCollectorOrgParty(partyRoleSelected));
    view.push(this.createCollectorIndParty(partyRoleSelected));
    view.push(this.createEmployeeIndParty(partyRoleSelected));
    view.push(
      this.createClaimantInvestigatorParty(partyRoleSelected)
    );
    view.push(
      this.createClaimantInvestigatorOrgParty(partyRoleSelected)
    );
    view.push(this.createClaimantOrgParty(partyRoleSelected));
    view.push(this.createClaimantIndParty(partyRoleSelected));
    view.push(this.createInsuranceParty(partyRoleSelected));
    view.push(this.createReinsuranceParty(partyRoleSelected));
    view.push(this.createBankOrgParty(partyRoleSelected));
    view.push(
      this.createHealthCareProviderOrgParty(partyRoleSelected)
    );
    view.push(
      this.createHealthCareProviderIndParty(partyRoleSelected)
    );
    view.push(this.createIntroducerOrgParty(partyRoleSelected));
    view.push(this.createIntroducerIndParty(partyRoleSelected));
    view.push(this.createRecoverFromIndParty(partyRoleSelected));
    view.push(this.createRecoverFromOrgParty(partyRoleSelected));
    view.push(this.createCarRentalOrgParty(partyRoleSelected));
    view.push(this.createProducerSuppOrgParty(partyRoleSelected));
    view.push(this.createProducerSuppIndParty(partyRoleSelected));
    view.push(this.createForgottenParty(partyRoleSelected));
    view.push(this.createDriverIndParty(partyRoleSelected));
    return <div>{view}</div>;
  },

  createCustomerIndParty(partyRoleSelected) {
    const { parentComponent, submitInfoHandler, isEdit } = this.props;
    const { RoleList, PartyId } = this.state;
    const roleSelected = !partyRoleSelected ? [] : partyRoleSelected;
    const displayPage = (
      <CustomerIndRole
        parentComponent={parentComponent}
        submitInfoHandler={submitInfoHandler}
        RoleList={RoleList}
        partyRoleSelected={roleSelected}
        partyId={PartyId}
        isEdit={isEdit}
      />
    );

    return (
      <UIDialog
        title={i18n.PartyRoleDetail}
        width="80%"
        id="createCustomerIndPartyDialog"
      >
        {{ displayPage }}
      </UIDialog>
    );
  },

  createCustomerOrgParty(partyRoleSelected) {
    const { parentComponent, submitInfoHandler, isEdit } = this.props;
    const { RoleList, PartyId } = this.state;
    const roleSelected = !partyRoleSelected ? [] : partyRoleSelected;
    const displayPage = (
      <CustomerOrgRole
        parentComponent={parentComponent}
        submitInfoHandler={submitInfoHandler}
        RoleList={RoleList}
        partyRoleSelected={roleSelected}
        partyId={PartyId}
        isEdit={isEdit}
      />
    );

    return (
      <UIDialog
        title={i18n.PartyRoleDetail}
        width="80%"
        id="createCustomerOrgPartyDialog"
      >
        {{ displayPage }}
      </UIDialog>
    );
  },

  createBankOrgParty(partyRoleSelected) {
    const { parentComponent, submitInfoHandler, isEdit } = this.props;
    const { RoleList, PartyId } = this.state;
    const roleSelected = !partyRoleSelected ? [] : partyRoleSelected;
    const displayPage = (
      <BankRole
        parentComponent={parentComponent}
        submitInfoHandler={submitInfoHandler}
        RoleList={RoleList}
        partyRoleSelected={roleSelected}
        partyId={PartyId}
        isEdit={isEdit}
      />
    );

    return (
      <UIDialog
        title={i18n.PartyRoleDetail}
        width="80%"
        id="createBankPartyDialog"
      >
        {{ displayPage }}
      </UIDialog>
    );
  },

  createProducerOrgParty(partyRoleSelected) {
    const { parentComponent, submitInfoHandler, isEdit } = this.props;
    const { RoleList, PartyId } = this.state;
    const roleSelected = !partyRoleSelected ? [] : partyRoleSelected;
    const displayPage = (
      <ProducerOrgRole
        parentComponent={parentComponent}
        submitInfoHandler={submitInfoHandler}
        RoleList={RoleList}
        partyRoleSelected={roleSelected}
        partyId={PartyId}
        isEdit={isEdit}
      />
    );

    return (
      <UIDialog
        title={i18n.PartyRoleDetail}
        width="80%"
        id="createProducerOrgPartyDialog"
      >
        {{ displayPage }}
      </UIDialog>
    );
  },

  createProducerIndParty(partyRoleSelected) {
    const { parentComponent, submitInfoHandler, isEdit } = this.props;
    const { RoleList, PartyId } = this.state;
    const roleSelected = !partyRoleSelected ? [] : partyRoleSelected;
    const displayPage = (
      <ProducerIndRole
        parentComponent={parentComponent}
        submitInfoHandler={submitInfoHandler}
        RoleList={RoleList}
        partyRoleSelected={roleSelected}
        partyId={PartyId}
        isEdit={isEdit}
      />
    );

    return (
      <UIDialog
        title={i18n.PartyRoleDetail}
        width="80%"
        id="createProducerIndPartyDialog"
      >
        {{ displayPage }}
      </UIDialog>
    );
  },

  createReinsuranceParty(partyRoleSelected) {
    const { parentComponent, submitInfoHandler, isEdit } = this.props;
    const { RoleList, PartyId } = this.state;
    const roleSelected = !partyRoleSelected ? [] : partyRoleSelected;
    const displayPage = (
      <ReinsuranceRole
        parentComponent={parentComponent}
        submitInfoHandler={submitInfoHandler}
        RoleList={RoleList}
        partyRoleSelected={roleSelected}
        partyId={PartyId}
        isEdit={isEdit}
      />
    );

    return (
      <UIDialog
        title={i18n.PartyRoleDetail}
        width="80%"
        id="createReinsurancePartyDialog"
      >
        {{ displayPage }}
      </UIDialog>
    );
  },

  createInsuranceParty(partyRoleSelected) {
    const { parentComponent, submitInfoHandler, isEdit } = this.props;
    const { RoleList, PartyId } = this.state;
    const roleSelected = !partyRoleSelected ? [] : partyRoleSelected;
    const displayPage = (
      <InsuranceRole
        parentComponent={parentComponent}
        submitInfoHandler={submitInfoHandler}
        RoleList={RoleList}
        partyRoleSelected={roleSelected}
        partyId={PartyId}
        isEdit={isEdit}
      />
    );

    return (
      <UIDialog
        title={i18n.PartyRoleDetail}
        width="80%"
        id="createInsurancePartyDialog"
      >
        {{ displayPage }}
      </UIDialog>
    );
  },

  createRiskAssessorOrgParty(partyRoleSelected) {
    const { parentComponent, submitInfoHandler, isEdit } = this.props;
    const { RoleList, PartyId } = this.state;
    const roleSelected = !partyRoleSelected ? [] : partyRoleSelected;
    const displayPage = (
      <RiskAssessorOrgRole
        parentComponent={parentComponent}
        submitInfoHandler={submitInfoHandler}
        RoleList={RoleList}
        partyRoleSelected={roleSelected}
        partyId={PartyId}
        isEdit={isEdit}
      />
    );

    return (
      <UIDialog
        title={i18n.PartyRoleDetail}
        width="80%"
        id="createRiskAssessorOrgPartyDialog"
      >
        {{ displayPage }}
      </UIDialog>
    );
  },

  createRiskAssessorIndParty(partyRoleSelected) {
    const { parentComponent, submitInfoHandler, isEdit } = this.props;
    const { RoleList, PartyId } = this.state;
    const roleSelected = !partyRoleSelected ? [] : partyRoleSelected;
    const displayPage = (
      <RiskAssessorIndRole
        parentComponent={parentComponent}
        submitInfoHandler={submitInfoHandler}
        RoleList={RoleList}
        partyRoleSelected={roleSelected}
        partyId={PartyId}
        isEdit={isEdit}
      />
    );

    return (
      <UIDialog
        title={i18n.PartyRoleDetail}
        width="80%"
        id="createRiskAssessorIndPartyDialog"
      >
        {{ displayPage }}
      </UIDialog>
    );
  },

  createLawyerParty(partyRoleSelected) {
    const { parentComponent, submitInfoHandler, isEdit } = this.props;
    const { RoleList, PartyId } = this.state;
    const roleSelected = !partyRoleSelected ? [] : partyRoleSelected;
    const displayPage = (
      <LawyerRole
        parentComponent={parentComponent}
        submitInfoHandler={submitInfoHandler}
        RoleList={RoleList}
        partyRoleSelected={roleSelected}
        partyId={PartyId}
        isEdit={isEdit}
      />
    );

    return (
      <UIDialog
        title={i18n.PartyRoleDetail}
        width="80%"
        id="createLawyerPartyDialog"
      >
        {{ displayPage }}
      </UIDialog>
    );
  },

  createLegalFirmParty(partyRoleSelected) {
    const { parentComponent, submitInfoHandler, isEdit } = this.props;
    const { RoleList, PartyId } = this.state;
    const roleSelected = !partyRoleSelected ? [] : partyRoleSelected;
    const displayPage = (
      <LegalFirmRole
        parentComponent={parentComponent}
        submitInfoHandler={submitInfoHandler}
        RoleList={RoleList}
        partyRoleSelected={roleSelected}
        partyId={PartyId}
        isEdit={isEdit}
      />
    );

    return (
      <UIDialog
        title={i18n.PartyRoleDetail}
        width="80%"
        id="createLegalFirmPartyDialog"
      >
        {{ displayPage }}
      </UIDialog>
    );
  },

  createGarageOrgParty(partyRoleSelected) {
    const { parentComponent, submitInfoHandler, isEdit } = this.props;
    const { RoleList, PartyId } = this.state;
    const roleSelected = !partyRoleSelected ? [] : partyRoleSelected;
    const displayPage = (
      <GarageOrgRole
        parentComponent={parentComponent}
        submitInfoHandler={submitInfoHandler}
        RoleList={RoleList}
        partyRoleSelected={roleSelected}
        partyId={PartyId}
        isEdit={isEdit}
      />
    );

    return (
      <UIDialog
        title={i18n.PartyRoleDetail}
        width="80%"
        id="createGarageOrgPartyDialog"
      >
        {{ displayPage }}
      </UIDialog>
    );
  },

  createGarageIndParty(partyRoleSelected) {
    const { parentComponent, submitInfoHandler, isEdit } = this.props;
    const { RoleList, PartyId } = this.state;
    const roleSelected = !partyRoleSelected ? [] : partyRoleSelected;
    const displayPage = (
      <GarageIndRole
        parentComponent={parentComponent}
        submitInfoHandler={submitInfoHandler}
        RoleList={RoleList}
        partyRoleSelected={roleSelected}
        partyId={PartyId}
        isEdit={isEdit}
      />
    );

    return (
      <UIDialog
        title={i18n.PartyRoleDetail}
        width="80%"
        id="createGarageIndPartyDialog"
      >
        {{ displayPage }}
      </UIDialog>
    );
  },

  createHealthCareProviderIndParty(partyRoleSelected) {
    const { parentComponent, submitInfoHandler, isEdit } = this.props;
    const { RoleList, PartyId } = this.state;
    const roleSelected = !partyRoleSelected ? [] : partyRoleSelected;
    const displayPage = (
      <HealthCareProviderIndRole
        parentComponent={parentComponent}
        submitInfoHandler={submitInfoHandler}
        RoleList={RoleList}
        partyRoleSelected={roleSelected}
        partyId={PartyId}
        isEdit={isEdit}
      />
    );

    return (
      <UIDialog
        title={i18n.PartyRoleDetail}
        width="80%"
        id="createHealthCareProviderIndPartyDialog"
      >
        {{ displayPage }}
      </UIDialog>
    );
  },

  createHealthCareProviderOrgParty(partyRoleSelected) {
    const { parentComponent, submitInfoHandler, isEdit } = this.props;
    const { RoleList, PartyId } = this.state;
    const roleSelected = !partyRoleSelected ? [] : partyRoleSelected;
    const displayPage = (
      <HealthCareProviderOrgRole
        parentComponent={parentComponent}
        submitInfoHandler={submitInfoHandler}
        RoleList={RoleList}
        partyRoleSelected={roleSelected}
        partyId={PartyId}
        isEdit={isEdit}
      />
    );

    return (
      <UIDialog
        title={i18n.PartyRoleDetail}
        width="80%"
        id="createHealthCareProviderOrgPartyDialog"
      >
        {{ displayPage }}
      </UIDialog>
    );
  },

  createPayeeOrPayerOrgParty(partyRoleSelected) {
    const { parentComponent, submitInfoHandler, isEdit } = this.props;
    const { RoleList, PartyId } = this.state;
    const roleSelected = !partyRoleSelected ? [] : partyRoleSelected;
    const displayPage = (
      <PayeeOrPayerOrgRole
        parentComponent={parentComponent}
        submitInfoHandler={submitInfoHandler}
        RoleList={RoleList}
        partyRoleSelected={roleSelected}
        partyId={PartyId}
        isEdit={isEdit}
      />
    );

    return (
      <UIDialog
        title={i18n.PartyRoleDetail}
        width="80%"
        id="createPayeeOrPayerOrgPartyDialog"
      >
        {{ displayPage }}
      </UIDialog>
    );
  },

  createPayeeOrPayerIndParty(partyRoleSelected) {
    const { parentComponent, submitInfoHandler, isEdit } = this.props;
    const { RoleList, PartyId } = this.state;
    const roleSelected = !partyRoleSelected ? [] : partyRoleSelected;
    const displayPage = (
      <PayeeOrPayerIndRole
        parentComponent={parentComponent}
        submitInfoHandler={submitInfoHandler}
        RoleList={RoleList}
        partyRoleSelected={roleSelected}
        partyId={PartyId}
        isEdit={isEdit}
      />
    );

    return (
      <UIDialog
        title={i18n.PartyRoleDetail}
        width="80%"
        id="createPayeeOrPayerIndPartyDialog"
      >
        {{ displayPage }}
      </UIDialog>
    );
  },

  createClaimantOrgParty(partyRoleSelected) {
    const { parentComponent, submitInfoHandler, isEdit } = this.props;
    const { RoleList, PartyId } = this.state;
    const roleSelected = !partyRoleSelected ? [] : partyRoleSelected;
    const displayPage = (
      <ClaimantOrgRole
        parentComponent={parentComponent}
        submitInfoHandler={submitInfoHandler}
        RoleList={RoleList}
        partyRoleSelected={roleSelected}
        partyId={PartyId}
        isEdit={isEdit}
      />
    );

    return (
      <UIDialog
        title={i18n.PartyRoleDetail}
        width="80%"
        id="createClaimantOrgPartyDialog"
      >
        {{ displayPage }}
      </UIDialog>
    );
  },

  createClaimantIndParty(partyRoleSelected) {
    const { parentComponent, submitInfoHandler, isEdit } = this.props;
    const { RoleList, PartyId } = this.state;
    const roleSelected = !partyRoleSelected ? [] : partyRoleSelected;
    const displayPage = (
      <ClaimantIndRole
        parentComponent={parentComponent}
        submitInfoHandler={submitInfoHandler}
        RoleList={RoleList}
        partyRoleSelected={roleSelected}
        partyId={PartyId}
        isEdit={isEdit}
      />
    );

    return (
      <UIDialog
        title={i18n.PartyRoleDetail}
        width="80%"
        id="createClaimantIndPartyDialog"
      >
        {{ displayPage }}
      </UIDialog>
    );
  },

  createCollectorOrgParty(partyRoleSelected) {
    const { parentComponent, submitInfoHandler, isEdit } = this.props;
    const { RoleList, PartyId } = this.state;
    const roleSelected = !partyRoleSelected ? [] : partyRoleSelected;
    const displayPage = (
      <CollectorOrgRole
        parentComponent={parentComponent}
        submitInfoHandler={submitInfoHandler}
        RoleList={RoleList}
        partyRoleSelected={roleSelected}
        partyId={PartyId}
        isEdit={isEdit}
      />
    );

    return (
      <UIDialog
        title={i18n.PartyRoleDetail}
        width="80%"
        id="createCollectorOrgPartyDialog"
      >
        {{ displayPage }}
      </UIDialog>
    );
  },

  createCollectorIndParty(partyRoleSelected) {
    const { parentComponent, submitInfoHandler, isEdit } = this.props;
    const { RoleList, PartyId } = this.state;
    const roleSelected = !partyRoleSelected ? [] : partyRoleSelected;
    const displayPage = (
      <CollectorIndRole
        parentComponent={parentComponent}
        submitInfoHandler={submitInfoHandler}
        RoleList={RoleList}
        partyRoleSelected={roleSelected}
        partyId={PartyId}
        isEdit={isEdit}
      />
    );

    return (
      <UIDialog
        title={i18n.PartyRoleDetail}
        width="80%"
        id="createCollectorIndPartyDialog"
      >
        {{ displayPage }}
      </UIDialog>
    );
  },

  createEmployeeIndParty(partyRoleSelected) {
    const { parentComponent, submitInfoHandler, isEdit } = this.props;
    const { RoleList, PartyId } = this.state;
    const roleSelected = !partyRoleSelected ? [] : partyRoleSelected;
    const displayPage = (
      <EmployeeIndRole
        parentComponent={parentComponent}
        submitInfoHandler={submitInfoHandler}
        RoleList={RoleList}
        partyRoleSelected={roleSelected}
        partyId={PartyId}
        isEdit={isEdit}
      />
    );

    return (
      <UIDialog
        title={i18n.PartyRoleDetail}
        width="80%"
        id="createEmployeeIndPartyDialog"
      >
        {{ displayPage }}
      </UIDialog>
    );
  },

  createIntroducerOrgParty(partyRoleSelected) {
    const { parentComponent, submitInfoHandler, isEdit } = this.props;
    const { RoleList, PartyId } = this.state;
    const roleSelected = !partyRoleSelected ? [] : partyRoleSelected;
    const displayPage = (
      <IntroducerOrgRole
        parentComponent={parentComponent}
        submitInfoHandler={submitInfoHandler}
        RoleList={RoleList}
        partyRoleSelected={roleSelected}
        partyId={PartyId}
        isEdit={isEdit}
      />
    );

    return (
      <UIDialog
        title={i18n.PartyRoleDetail}
        width="80%"
        id="createIntroducerOrgPartyDialog"
      >
        {{ displayPage }}
      </UIDialog>
    );
  },

  createIntroducerIndParty(partyRoleSelected) {
    const { parentComponent, submitInfoHandler, isEdit } = this.props;
    const { RoleList, PartyId } = this.state;
    const roleSelected = !partyRoleSelected ? [] : partyRoleSelected;
    const displayPage = (
      <IntroducerIndRole
        parentComponent={parentComponent}
        submitInfoHandler={submitInfoHandler}
        RoleList={RoleList}
        partyRoleSelected={roleSelected}
        partyId={PartyId}
        isEdit={isEdit}
      />
    );

    return (
      <UIDialog
        title={i18n.PartyRoleDetail}
        width="80%"
        id="createIntroducerIndPartyDialog"
      >
        {{ displayPage }}
      </UIDialog>
    );
  },

  createRecoverFromIndParty(partyRoleSelected) {
    const { parentComponent, submitInfoHandler, isEdit } = this.props;
    const { RoleList, PartyId } = this.state;
    const roleSelected = !partyRoleSelected ? [] : partyRoleSelected;
    const displayPage = (
      <RecoverFromIndRole
        parentComponent={parentComponent}
        submitInfoHandler={submitInfoHandler}
        RoleList={RoleList}
        partyRoleSelected={roleSelected}
        partyId={PartyId}
        isEdit={isEdit}
      />
    );

    return (
      <UIDialog
        title={i18n.PartyRoleDetail}
        width="80%"
        id="createRecoverFromIndPartyDialog"
      >
        {{ displayPage }}
      </UIDialog>
    );
  },

  createRecoverFromOrgParty(partyRoleSelected) {
    const { parentComponent, submitInfoHandler, isEdit } = this.props;
    const { RoleList, PartyId } = this.state;
    const roleSelected = !partyRoleSelected ? [] : partyRoleSelected;
    const displayPage = (
      <RecoverFromOrgRole
        parentComponent={parentComponent}
        submitInfoHandler={submitInfoHandler}
        RoleList={RoleList}
        partyRoleSelected={roleSelected}
        partyId={PartyId}
        isEdit={isEdit}
      />
    );

    return (
      <UIDialog
        title={i18n.PartyRoleDetail}
        width="80%"
        id="createRecoverFromOrgPartyDialog"
      >
        {{ displayPage }}
      </UIDialog>
    );
  },

  createCarRentalOrgParty(partyRoleSelected) {
    const { parentComponent, submitInfoHandler, isEdit } = this.props;
    const { RoleList, PartyId } = this.state;
    const roleSelected = !partyRoleSelected ? [] : partyRoleSelected;
    const displayPage = (
      <CarRentalRole
        parentComponent={parentComponent}
        submitInfoHandler={submitInfoHandler}
        RoleList={RoleList}
        partyRoleSelected={roleSelected}
        partyId={PartyId}
        isEdit={isEdit}
      />
    );

    return (
      <UIDialog
        title={i18n.PartyRoleDetail}
        width="80%"
        id="createCarRentalOrgPartyDialog"
      >
        {{ displayPage }}
      </UIDialog>
    );
  },

  createProducerSuppOrgParty(partyRoleSelected) {
    const { parentComponent, submitInfoHandler, isEdit } = this.props;
    const { RoleList, PartyId } = this.state;
    const roleSelected = !partyRoleSelected ? [] : partyRoleSelected;
    const displayPage = (
      <ProducerSupportOfficerOrgRole
        parentComponent={parentComponent}
        submitInfoHandler={submitInfoHandler}
        RoleList={RoleList}
        partyRoleSelected={roleSelected}
        partyId={PartyId}
        isEdit={isEdit}
      />
    );

    return (
      <UIDialog
        title={i18n.PartyRoleDetail}
        width="80%"
        id="createProducerSupportOfficerOrgPartyDialog"
      >
        {{ displayPage }}
      </UIDialog>
    );
  },

  createProducerSuppIndParty(partyRoleSelected) {
    const { parentComponent, submitInfoHandler, isEdit } = this.props;
    const { RoleList, PartyId } = this.state;
    const roleSelected = !partyRoleSelected ? [] : partyRoleSelected;
    const displayPage = (
      <ProducerSupportOfficerIndRole
        parentComponent={parentComponent}
        submitInfoHandler={submitInfoHandler}
        RoleList={RoleList}
        partyRoleSelected={roleSelected}
        partyId={PartyId}
        isEdit={isEdit}
      />
    );

    return (
      <UIDialog
        title={i18n.PartyRoleDetail}
        width="80%"
        id="createProducerSupportOfficerIndPartyDialog"
      >
        {{ displayPage }}
      </UIDialog>
    );
  },

  createForgottenParty(partyRoleSelected) {
    const { parentComponent, submitInfoHandler, isEdit } = this.props;
    const { RoleList, PartyId } = this.state;
    const roleSelected = !partyRoleSelected ? [] : partyRoleSelected;
    const displayPage = (
      <ForgottenRole
        parentComponent={parentComponent}
        submitInfoHandler={submitInfoHandler}
        RoleList={RoleList}
        partyRoleSelected={roleSelected}
        partyId={PartyId}
        isEdit={isEdit}
      />
    );

    return (
      <UIDialog
        title={i18n.PartyRoleDetail}
        width="80%"
        id="createForgottenPartyDialog"
      >
        {{ displayPage }}
      </UIDialog>
    );
  },

  createClaimantInvestigatorParty(partyRoleSelected) {
    const { parentComponent, submitInfoHandler, isEdit } = this.props;
    const { RoleList, PartyId } = this.state;
    const roleSelected = !partyRoleSelected ? [] : partyRoleSelected;
    const displayPage = (
      <ClaimantInvestigatorRole
        parentComponent={parentComponent}
        isEdit={isEdit}
        submitInfoHandler={submitInfoHandler}
        RoleList={RoleList}
        partyRoleSelected={roleSelected}
        partyId={PartyId}
      />
    );

    return (
      <UIDialog
        title={i18n.PartyRoleDetail}
        width="80%"
        id="createClaimantInvestigatorPartyDialog"
      >
        {{ displayPage }}
      </UIDialog>
    );
  },

  createClaimantInvestigatorOrgParty(partyRoleSelected) {
    const { parentComponent, submitInfoHandler, isEdit } = this.props;
    const { RoleList, PartyId } = this.state;
    const roleSelected = !partyRoleSelected ? [] : partyRoleSelected;
    const displayPage = (
      <ClaimantInvestigatorOrgRole
        parentComponent={parentComponent}
        isEdit={isEdit}
        submitInfoHandler={submitInfoHandler}
        RoleList={RoleList}
        partyRoleSelected={roleSelected}
        partyId={PartyId}
      />
    );

    return (
      <UIDialog
        title={i18n.PartyRoleDetail}
        width="80%"
        id="createClaimantInvestigatorOrgPartyDialog"
      >
        {{ displayPage }}
      </UIDialog>
    );
  },

  createDriverIndParty(partyRoleSelected) {
    const { parentComponent, submitInfoHandler, isEdit } = this.props;
    const { RoleList, PartyId } = this.state;
    const roleSelected = !partyRoleSelected ? [] : partyRoleSelected;
    const displayPage = (
      <DriverIndRole
        parentComponent={parentComponent}
        submitInfoHandler={submitInfoHandler}
        RoleList={RoleList}
        partyRoleSelected={roleSelected}
        partyId={PartyId}
        isEdit={isEdit}
      />
    );

    return (
      <UIDialog
        title={i18n.PartyRoleDetail}
        width="80%"
        id="createDriverIndPartyDialog"
      >
        {{ displayPage }}
      </UIDialog>
    );
  },
});
export default RoleDetail;
