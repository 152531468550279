import { AjaxUtil } from 'RainbowUI';
import Url from '../url/Url';

export default {
  initMessage(tmpcondition) {
    const condition = tmpcondition;
    const deferred = $.Deferred();
    const url = Url.InitMessage;
    delete condition.isInital;
    delete condition.flag;
    AjaxUtil.doPost(url, condition, {
      async: false,
      done(data) {
        deferred.resolve(data);
      },
      fail(err) {
        deferred.reject(err);
      },
    });
    return deferred.promise();
  },
  updateMessage(vo) {
    const deferred = $.Deferred();
    const url = `${Url.UpdateMessage}/${vo.EntityId}/statusType/${vo.Status}`;
    AjaxUtil.doPost(url, vo, {
      async: false,
      done(data) {
        deferred.resolve(data);
      },
      fail(err) {
        deferred.reject(err);
      },
    });
    return deferred.promise();
  },
  saveMessage() {
    const deferred = $.Deferred();
    const url = '';
    AjaxUtil.doGet(url, condition, {
      async: false,
      done(data) {
        deferred.resolve(data);
      },
      fail(err) {
        deferred.reject(err);
      },
    });
    return deferred.promise();
  },
};
