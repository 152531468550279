export default {
  '@type': 'ClaimPolicy-ClaimPolicy',
  OrganId: '1',
  PolicyNo: 'V20160000000006',
  ProductCode: 'CGL_1.0',
  ProductTypeCode: 'CGL',
  EffDate: '2014-07-01',
  ExpDate: '2015-06-30',
  RetroactiveDate: '2014-01-01',
  ComplementaryExtendedPeriod: '2015-07-31',
  SupplementaryExtendedPeriod: '2016-01-31',
  Premium: 5000.0,
  PremiumIsPaid: 'Y',
  CurrencyCode: 'USD',
  InsuredList: [
    {
      '@type': 'ClaimPolicyInsured-ClaimPolicyInsured',
      InsuredName: 'GL Company- East',
      SumInsured: 0.0,
      InsuredCategory: 2,
      InsuredUid: 'GL Company- East',
      CoverageList: [
        {
          '@type': 'ClaimPolicyCoverage-ClaimPolicyCoverage',
          CoverageName: 'General Liability',
          DeductibleAmount: 5000.0,
          DeductibleRate: 0.0,
          SumInsured: 10000000.0,
          PolicySumInsured: 10000000.0,
          PolicyDeductibleRate: 0,
          PolicyDeductibleAmount: 5000,
          CoverageCode: 'CGL_C',
          ProductCode: 'CGL_1.0',
          CoverageGroupCode: 'GC_001',
          PolicyLimit: {
            '@type': 'ClaimPolicyLimit-ClaimPolicyLimit',
            PolicyLimitValue: [
              {
                '@type':
                  'ClaimPolicyLimitValue-ClaimPolicyLimitValue',
                LimitValue: 10000000,
                ModeType: 'AOA',
                Term: '10000000 Per Accident',
                ValueType: 'AMOUNT',
              },
            ],
          },
          PolicyDeductible: {
            '@type': 'ClaimPolicyDeductible-ClaimPolicyDeductible',
            PolicyDeductibleValue: [
              {
                '@type':
                  'ClaimPolicyDeductibleValue-ClaimPolicyDeductibleValue',
                IsAbsolute: 1,
                ModeType: 'AOA',
                Term: '5000 per accident',
                DeductibleValue: 5000,
                UnitAmount: 500,
                UnitType: 'ITEM',
                UnitValue: 1,
                ValueType: 'UNIT',
              },
            ],
          },
        },
        {
          '@type': 'ClaimPolicyCoverage-ClaimPolicyCoverage',
          CoverageName: 'Product Liability',
          DeductibleAmount: 5000.0,
          DeductibleRate: 0.0,
          SumInsured: 10000000.0,
          PolicySumInsured: 10000000.0,
          PolicyDeductibleRate: 0,
          PolicyDeductibleAmount: 5000,
          CoverageCode: 'CPL',
          ProductCode: 'CGL_1.0',
          CoverageGroupCode: 'GC_002',
          PolicyLimit: {
            '@type': 'ClaimPolicyLimit-ClaimPolicyLimit',
            PolicyLimitValue: [
              {
                '@type':
                  'ClaimPolicyLimitValue-ClaimPolicyLimitValue',
                LimitValue: 10000000,
                ModeType: 'AOA',
                Term: '10000000 Per Accident',
                ValueType: 'AMOUNT',
              },
            ],
          },
          PolicyDeductible: {
            '@type': 'ClaimPolicyDeductible-ClaimPolicyDeductible',
            PolicyDeductibleValue: [
              {
                '@type':
                  'ClaimPolicyDeductibleValue-ClaimPolicyDeductibleValue',
                IsAbsolute: 1,
                ModeType: 'AOA',
                Term: '5000 per accident',
                DeductibleValue: 5000,
                UnitAmount: 500,
                UnitType: 'ITEM',
                UnitValue: 1,
                ValueType: 'UNIT',
              },
            ],
          },
        },

        // GROUP
        {
          '@type': 'ClaimPolicyCoverage-ClaimPolicyCoverage',
          CoverageName: 'Policy level group-General Liability',
          DeductibleAmount: 5000.0,
          DeductibleRate: 0.0,
          SumInsured: 10000000.0,
          PolicySumInsured: 10000000.0,
          PolicyDeductibleRate: 0,
          PolicyDeductibleAmount: 5000,
          CoverageCode: 'GC_001',
          ProductCode: 'CGL_1.0',
          PolicyLimit: {
            '@type': 'ClaimPolicyLimit-ClaimPolicyLimit',
            PolicyLimitValue: [
              {
                '@type':
                  'ClaimPolicyLimitValue-ClaimPolicyLimitValue',
                LimitValue: 10000000,
                ModeType: 'AOA',
                Term: '10000000 Per Accident',
                ValueType: 'AMOUNT',
              },
            ],
          },
          PolicyDeductible: {
            '@type': 'ClaimPolicyDeductible-ClaimPolicyDeductible',
            PolicyDeductibleValue: [
              {
                '@type':
                  'ClaimPolicyDeductibleValue-ClaimPolicyDeductibleValue',
                IsAbsolute: 1,
                ModeType: 'AOA',
                Term: '5000 per accident',
                DeductibleValue: 5000,
                UnitAmount: 500,
                UnitType: 'ITEM',
                UnitValue: 1,
                ValueType: 'UNIT',
              },
            ],
          },
        },
        {
          '@type': 'ClaimPolicyCoverage-ClaimPolicyCoverage',
          CoverageName: 'Policy level group-Product Liability',
          DeductibleAmount: 5000.0,
          DeductibleRate: 0.0,
          SumInsured: 10000000.0,
          PolicySumInsured: 10000000.0,
          PolicyDeductibleRate: 0,
          PolicyDeductibleAmount: 5000,
          CoverageCode: 'GC_002',
          ProductCode: 'CGL_1.0',
          PolicyLimit: {
            '@type': 'ClaimPolicyLimit-ClaimPolicyLimit',
            PolicyLimitValue: [
              {
                '@type':
                  'ClaimPolicyLimitValue-ClaimPolicyLimitValue',
                LimitValue: 10000000,
                ModeType: 'AOA',
                Term: '10000000 Per Accident',
                ValueType: 'AMOUNT',
              },
            ],
          },
          PolicyDeductible: {
            '@type': 'ClaimPolicyDeductible-ClaimPolicyDeductible',
            PolicyDeductibleValue: [
              {
                '@type':
                  'ClaimPolicyDeductibleValue-ClaimPolicyDeductibleValue',
                IsAbsolute: 1,
                ModeType: 'AOA',
                Term: '5000 per accident',
                DeductibleValue: 5000,
                UnitAmount: 500,
                UnitType: 'ITEM',
                UnitValue: 1,
                ValueType: 'UNIT',
              },
            ],
          },
        },
      ],
    },
    {
      '@type': 'ClaimPolicyInsured-ClaimPolicyInsured',
      InsuredName: 'GL Company- West',
      SumInsured: 0.0,
      InsuredCategory: 2,
      InsuredUid: 'GL Company- West',
      CoverageList: [
        {
          '@type': 'ClaimPolicyCoverage-ClaimPolicyCoverage',
          CoverageName: 'General Liability',
          DeductibleAmount: 5000.0,
          DeductibleRate: 0.0,
          SumInsured: 10000000.0,
          PolicySumInsured: 10000000.0,
          PolicyDeductibleRate: 0,
          PolicyDeductibleAmount: 5000,
          CoverageCode: 'CGL_C',
          ProductCode: 'CGL_1.0',
          CoverageGroupCode: 'GC_001',
          PolicyLimit: {
            '@type': 'ClaimPolicyLimit-ClaimPolicyLimit',
            PolicyLimitValue: [
              {
                '@type':
                  'ClaimPolicyLimitValue-ClaimPolicyLimitValue',
                LimitValue: 10000000,
                ModeType: 'AOA',
                Term: '10000000 Per Accident',
                ValueType: 'AMOUNT',
              },
            ],
          },
          PolicyDeductible: {
            '@type': 'ClaimPolicyDeductible-ClaimPolicyDeductible',
            PolicyDeductibleValue: [
              {
                '@type':
                  'ClaimPolicyDeductibleValue-ClaimPolicyDeductibleValue',
                IsAbsolute: 1,
                ModeType: 'AOA',
                Term: '5000 per accident',
                DeductibleValue: 5000,
                UnitAmount: 500,
                UnitType: 'ITEM',
                UnitValue: 1,
                ValueType: 'UNIT',
              },
            ],
          },
        },
        {
          '@type': 'ClaimPolicyCoverage-ClaimPolicyCoverage',
          CoverageName: 'Product Liability',
          DeductibleAmount: 5000.0,
          DeductibleRate: 0.0,
          SumInsured: 10000000.0,
          PolicySumInsured: 10000000.0,
          PolicyDeductibleRate: 0,
          PolicyDeductibleAmount: 5000,
          CoverageCode: 'CPL',
          ProductCode: 'CGL_1.0',
          CoverageGroupCode: 'GC_002',
          PolicyLimit: {
            '@type': 'ClaimPolicyLimit-ClaimPolicyLimit',
            PolicyLimitValue: [
              {
                '@type':
                  'ClaimPolicyLimitValue-ClaimPolicyLimitValue',
                LimitValue: 10000000,
                ModeType: 'AOA',
                Term: '10000000 Per Accident',
                ValueType: 'AMOUNT',
              },
            ],
          },
          PolicyDeductible: {
            '@type': 'ClaimPolicyDeductible-ClaimPolicyDeductible',
            PolicyDeductibleValue: [
              {
                '@type':
                  'ClaimPolicyDeductibleValue-ClaimPolicyDeductibleValue',
                IsAbsolute: 1,
                ModeType: 'AOA',
                Term: '5000 per accident',
                DeductibleValue: 5000,
                UnitAmount: 500,
                UnitType: 'ITEM',
                UnitValue: 1,
                ValueType: 'UNIT',
              },
            ],
          },
        },

        // GROUP
        {
          '@type': 'ClaimPolicyCoverage-ClaimPolicyCoverage',
          CoverageName: 'Policy level group-General Liability',
          DeductibleAmount: 5000.0,
          DeductibleRate: 0.0,
          SumInsured: 10000000.0,
          PolicySumInsured: 10000000.0,
          PolicyDeductibleRate: 0,
          PolicyDeductibleAmount: 5000,
          CoverageCode: 'GC_001',
          ProductCode: 'CGL_1.0',
          PolicyLimit: {
            '@type': 'ClaimPolicyLimit-ClaimPolicyLimit',
            PolicyLimitValue: [
              {
                '@type':
                  'ClaimPolicyLimitValue-ClaimPolicyLimitValue',
                LimitValue: 10000000,
                ModeType: 'AOA',
                Term: '10000000 Per Accident',
                ValueType: 'AMOUNT',
              },
            ],
          },
          PolicyDeductible: {
            '@type': 'ClaimPolicyDeductible-ClaimPolicyDeductible',
            PolicyDeductibleValue: [
              {
                '@type':
                  'ClaimPolicyDeductibleValue-ClaimPolicyDeductibleValue',
                IsAbsolute: 1,
                ModeType: 'AOA',
                Term: '5000 per accident',
                DeductibleValue: 5000,
                UnitAmount: 500,
                UnitType: 'ITEM',
                UnitValue: 1,
                ValueType: 'UNIT',
              },
            ],
          },
        },
        {
          '@type': 'ClaimPolicyCoverage-ClaimPolicyCoverage',
          CoverageName: 'Policy level group-Product Liability',
          DeductibleAmount: 5000.0,
          DeductibleRate: 0.0,
          SumInsured: 10000000.0,
          PolicySumInsured: 10000000.0,
          PolicyDeductibleRate: 0,
          PolicyDeductibleAmount: 5000,
          CoverageCode: 'GC_002',
          ProductCode: 'CGL_1.0',
          PolicyLimit: {
            '@type': 'ClaimPolicyLimit-ClaimPolicyLimit',
            PolicyLimitValue: [
              {
                '@type':
                  'ClaimPolicyLimitValue-ClaimPolicyLimitValue',
                LimitValue: 10000000,
                ModeType: 'AOA',
                Term: '10000000 Per Accident',
                ValueType: 'AMOUNT',
              },
            ],
          },
          PolicyDeductible: {
            '@type': 'ClaimPolicyDeductible-ClaimPolicyDeductible',
            PolicyDeductibleValue: [
              {
                '@type':
                  'ClaimPolicyDeductibleValue-ClaimPolicyDeductibleValue',
                IsAbsolute: 1,
                ModeType: 'AOA',
                Term: '5000 per accident',
                DeductibleValue: 5000,
                UnitAmount: 500,
                UnitType: 'ITEM',
                UnitValue: 1,
                ValueType: 'UNIT',
              },
            ],
          },
        },
      ],
    },
  ],
  PartyList: [
    {
      '@type': 'ClaimPolicyParty-ClaimPolicyParty',
      RoleType: '07',
      PartyName: 'GL Company',
      ContactFirstName: '',
      ContactLastName: '',
      ContactName: 'GL Company',
      ContactTelephone: '666000777',
      IdType: '1',
      IdNumber: 'MockPolicy53209223454563443279',
      ContactAddress: '777 Guoding Rd, Shanghai, China, 200433',
      ContactType: '2',
      CommonPartyId: 47335367,
    },
    {
      '@type': 'ClaimPolicyParty-ClaimPolicyParty',
      RoleType: '09',
      PartyName: 'GL Company- East',
      ContactFirstName: 'GL',
      ContactLastName: 'Company- East',
      ContactName: 'GL Company- East',
      ContactTelephone: '115261511579',
      IdType: '1',
      IdNumber: 'MockPolicy53209223454563443277',
      ContactAddress: 'wuxi',
      ContactType: '1',
      CommonPartyId: 47335366,
    },
    {
      '@type': 'ClaimPolicyParty-ClaimPolicyParty',
      RoleType: '09',
      PartyName: 'GL Company- West',
      ContactFirstName: 'GL',
      ContactLastName: 'Company- West',
      ContactName: 'GL Company- West',
      ContactTelephone: '115261511579',
      IdType: '2',
      IdNumber: 'MockPolicy53209223454563443288',
      ContactAddress: 'wuxi',
      ContactType: '1',
      CommonPartyId: 47335365,
    },
    {
      '@type': 'ClaimPolicyParty-ClaimPolicyParty',
      RoleType: '10',
      PartyName: 'cherry zha',
      ContactFirstName: 'cherry',
      ContactLastName: 'zha',
      ContactName: 'cherry zha',
      ContactTelephone: '232453453',
      IdType: '1',
      IdNumber: '32454354365',
      ContactAddress: 'wuxi',
      ContactType: '1',
      CommonPartyId: 22603375,
    },
  ],
};
