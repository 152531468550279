import {
  CodeTable,
  UITextarea,
  UISelect,
  UIText,
  UIRadio,
  UISmartPanelGrid,
  UICell,
  UICurrency,
  UIParam,
  UIButton,
  UIBox,
  UINumber,
  MessageHelper,
  UIUpdatePanel,
  UpdateContext,
  DataContext,
  UIDialog,
} from 'RainbowUI';
import React from 'react';
import PropTypes from 'prop-types';
import ClaimCommonExt from 'ClaimCommonExt';
import {
  ChoosePayeeOrPayerDialog,
  ClaimHandlingStore,
  ClaimHandlingCommonAction,
  ClaimHandlingAction,
  AddOrEditPartyDialog,
  ChooseClaimantDialog,
} from 'ClaimCommon';

import BindToMixin from 'react-binding';

import {
  CodeTableAction,
  CodeTableCstUI,
  ClaimCstUI,
} from 'ClaimCommonConstant';
import {
  PtyIntegrationAddress,
  PartyConst,
  PartyAction,
  CommonConstant,
  PartySearchPanelDialog,
} from 'EU00PartyCommonUI';

import ClaimRegistration from 'ClaimRegistration';
import moment from 'moment';
import ThirdPartyDriverInfo from './ThirdPartyDriverInfo';

const ClaimCompanyCareInfo = require('./ClaimCompanyCareInfo');
const DefineCodeTable = require('../mock/CodeTable');

const RegistrationValidationAction = ClaimRegistration.getClaimRegistrationValidationAction();

let code = require('../mock/CodeTable');
const Cascader = require('./Cascader');

const SubclaimInfo = React.createClass({
  mixins: [BindToMixin, Cascader],

  propTypes: {
    io: PropTypes.string,
    forModify: PropTypes.string,
  },

  getDefaultProps() {
    return { io: 'out', forModify: 'false' };
  },
  cancel() { },
  getInitialState() {
    const subclaimIndex =
      this.props.forModify === 'true'
        ? sessionStorage.getItem('ModifySubclaim_index')
        : this.props.subclaimIndex;
    const isMotor = this.props.data.IsMotor;
    const policeInfoIsRequired = false;
    console.log(`====subclaimIndex====${subclaimIndex}`);

    let mediumLoss = 0;
    let highLoss = 0;
    const exchangeRateUSD2EUR = ClaimHandlingAction.getExchangeRate2LocalCurrency(
      'USD'
    );
    const SeverityTable = CodeTableAction.loadDataList(
      CodeTableCstUI.ClaimDamageSeverityThreshold,
      null
    );
    SeverityTable.codes.forEach(code => {
      if (code.text === 'MediumLoss') {
        mediumLoss = code.id;
      } else if (code.text === 'HighLoss') {
        highLoss = code.id;
      }
    });
    const objectEntity = this.props.data.ObjectList[subclaimIndex];
    console.info('ClaimPartyList ', this.props.data.ClaimPartyList);
    const newClaimCodeTableList = this.getClaimAntCodeTable(
      this.props.data.ClaimPartyList,
      objectEntity
    );
    console.info('codeTableClaimant ', newClaimCodeTableList);
    console.info('newClaimCodeTableList ', newClaimCodeTableList);
    let accidentTime = null;
    if (this.props.data) {
      accidentTime = this.props.data.AccidentTime;
    }
    const filterCoverageList = _.filter(
      objectEntity.PolicyCoverageList,
      coverage => {
        if (!accidentTime) {
          return coverage;
        }
        if (!coverage.EffDate) {
          return coverage;
        }
        if (
          moment(accidentTime).isBetween(
            moment(coverage.EffDate),
            moment(coverage.ExpDate)
          ) ||
          moment(accidentTime).isSame(moment(coverage.EffDate)) ||
          moment(accidentTime).isSame(moment(coverage.ExpDate))
        ) {
          return coverage;
        }
      }
    );
    objectEntity.PolicyCoverageList = filterCoverageList;
    return {
      isMotor,
      policeInfoIsRequired,
      objectEntity,
      currentLossCause: this.props.data.LossCause,
      codeTableClaimant: newClaimCodeTableList,
      codeTableSubclaimType: this.props.data.CodeTableSubclaimType,
      codeTableDamageType: this.props.data.CodeTableDamageType,
      claimPartyList: this.props.data.ClaimPartyList,
      claimType: this.props.data.ClaimType,
      commonPartyInfo: {},
      claimantInfo: '',
      damageType: '',
      mediumLoss,
      highLoss,
      rate: exchangeRateUSD2EUR,
      // isRead:isRead
    };
  },

  componentWillMount() {
    const { state } = this;
    if (state.objectEntity && !state.objectEntity.LitigationFlag) {
      state.objectEntity.LitigationFlag = 'N';
    }
    if (state.objectEntity && !state.objectEntity.TotalLossFlag) {
      state.objectEntity.TotalLossFlag = 'N';
    }
    if (state.objectEntity && !state.objectEntity.IsSubrogation) {
      state.objectEntity.IsSubrogation = 'N';
    }
    if (state.objectEntity && !state.objectEntity.IsSalvage) {
      state.objectEntity.IsSalvage = 'N';
    }
    this.setState(state);

    ClaimHandlingStore.listen(this.onStoreStateChange);
  },

  getClaimAntCodeTable(partyList, objectEntity) {
    const newClaimCodeTableList = [];
    const tempClaimCodeTableMap = {};
    if (partyList) {
      for (let j = 0; j < partyList.length; j += 1) {
        const tempClaimParty = partyList[j];
        console.info('tempClaimParty', tempClaimParty);
        console.info('tempClaimParty', tempClaimParty.PartyRole);
        if (
          ClaimCstUI.PARTY_ROLE.PolicyHolder ==
          tempClaimParty.PartyRole ||
          ClaimCstUI.PARTY_ROLE.Insured == tempClaimParty.PartyRole ||
          ClaimCstUI.PARTY_ROLE.Claimant == tempClaimParty.PartyRole
        ) {
          if (
            ClaimCstUI.PARTY_ROLE.PolicyHolder ==
            tempClaimParty.PartyRole
          ) {
            DataContext.put('policyHolder', tempClaimParty);
          }
          if (tempClaimCodeTableMap[tempClaimParty.PtyPartyId]) {
            let addClaimAnt = false;
            for (
              let i = 0;
              i < newClaimCodeTableList.length;
              i += 1
            ) {
              console.info(
                'tempClaimParty',
                tempClaimCodeTableMap[tempClaimParty.PtyPartyId]
              );
              console.info(
                'tempClaimParty',
                newClaimCodeTableList[i].id
              );
              if (
                tempClaimCodeTableMap[tempClaimParty.PtyPartyId] ==
                newClaimCodeTableList[i].id &&
                ClaimCstUI.PARTY_ROLE.Claimant ==
                tempClaimParty.PartyRole
              ) {
                newClaimCodeTableList.splice(i, 1);
                addClaimAnt = true;
                break;
              }
            }
            if (addClaimAnt) {
              tempClaimCodeTableMap[tempClaimParty.PtyPartyId] =
                tempClaimParty.PartyId;
              newClaimCodeTableList.push({
                id: tempClaimParty.PartyId,
                text: tempClaimParty.PartyName,
              });
            }
          } else {
            tempClaimCodeTableMap[tempClaimParty.PtyPartyId] =
              tempClaimParty.PartyId;
            newClaimCodeTableList.push({
              id: tempClaimParty.PartyId,
              text: tempClaimParty.PartyName,
            });
          }
        }
      }
    }
    newClaimCodeTableList.push({ id: -1, text: '*New Claimant' });
    return new CodeTable(newClaimCodeTableList);
  },
  componentDidMount() {
    console.log('===SubclaimInfo===componentDidMount');
    const flag = 'componentDidMount';
    this.filterSubclaimType(this.state.currentLossCause, flag, null);
    const { claimPartyList } = this.state;
    const codeItem = { id: -1, text: '*New Claimant' };
    if (!claimPartyList) {
      const withOutCode = new CodeTable([codeItem]);
      this.setState({ codeTableClaimant: withOutCode });
      return;
    }
    code = this.getClaimAntCodeTable(
      this.state.claimPartyList,
      this.state.objectEntity
    );
    this.setState({ codeTableClaimant: code });

    console.log('===componentDidMount===', this.state.objectEntity);
    if (this.state.objectEntity.ClaimParty) {
      if (this.state.objectEntity.ClaimParty.PtyPartyId) {
        const claimparty = {};
        claimparty.PtyPartyId = this.state.objectEntity.ClaimParty.PtyPartyId;
        console.log('===componentDidMount===', claimparty);
        this.getCommonPartyBasicInfo(claimparty);
      }
    }

    const { objectEntity } = this.state;
    if (objectEntity.InsuredId) {
      const ultCodeTabSub = this.initSubclaimTypeCode(
        objectEntity.InsuredId,
        this.props.data.ProductCode
      );
      this.setState({ codeTableSubclaimType: ultCodeTabSub });
    } else {
      this.setState({ codeTableSubclaimType: new CodeTable([]) });
    }
  },

  componentWillUnmount() {
    ClaimHandlingStore.unlisten(this.onStoreStateChange);
  },

  onStoreStateChange(storeState) {
    const objectEntity =
      storeState.caseForm.ClaimEntity.ObjectList[
      this.props.subclaimIndex
      ];
    console.info(
      'HANDING STORE CHANGE TRIGGER SUBCLAIM_{0} object_entity:{1}',
      this.props.subclaimIndex,
      objectEntity
    );
    const claimPartyList = storeState.caseForm.ClaimEntity.ClaimPartyList;
    const code = this.getClaimAntCodeTable(
      claimPartyList,
      this.state.objectEntity
    );
    if (!_.isEmpty(storeState.caseForm)) {
      this.setState({
        objectEntity,
        codeTableClaimant: code,
        claimPartyList,
        initRole: false,
      });
    }
  },

  getSubclaimType() {
    return this.state.codeTableSubclaimType;
  },

  changeInsured(event) {
    this.cascaderSubclaim(event);
  },

  changeSubclaimType(event) {
    //   need   check  subclaimType unique;
    const {
      caseEntity,
    } = this.props.parentComponent.refs.claim_ref.state;
    if (this.checkChangeSubClaimType()) {
      const flag = 'changeSubclaimType';
      this.filterDamageType(flag, caseEntity.LossCause);
      this.filterCoverageList(caseEntity);
    } else {
      const { objectEntity } = this.state;
      objectEntity.SubclaimType = null;
      this.setState({ objectEntity });
      const flag = 'changeSubclaimType';
      this.filterDamageType(flag, caseEntity.LossCause);
      this.filterCoverageList(caseEntity);
    }
    DataContext.put('defaultValue', undefined);
    this.setDamageParty();
  },

  setDamageParty() {
    const _self = this;
    const subClaimTypeCodeTable = this.getSubclaimType().codes;
    const subClaimType = this.state.objectEntity.SubclaimType;
    DataContext.put('fromPartyChoose', false);
    if (subClaimType) {
      subClaimTypeCodeTable.forEach(subClaimTypeTemp => {
        if (subClaimTypeTemp.id == subClaimType) {
          const type = subClaimTypeTemp.text.substring(0, 2);
          if (type === 'FP' || type === 'TP') {
            const tempObjEntity = _self.state.objectEntity;
            tempObjEntity.damageParty = type;
            let ClaimantId = null;
            const codesArr = _self.state.codeTableClaimant.codes;
            for (let i = 0; i < codesArr.length; i += 1) {
              if (codesArr[i].id !== -1) {
                ClaimantId = codesArr[i].id;
                break;
              }
            }
            if (ClaimantId != null && ClaimantId !== '') {
              tempObjEntity.ClaimParty = ClaimHandlingCommonAction.getClaimParty(
                ClaimantId,
                _self.state.claimPartyList
              );
              _self.getCommonPartyBasicInfo(tempObjEntity.ClaimParty);
            }
            tempObjEntity.ClaimantId = null;
            _self.setState({ objectEntity: tempObjEntity });
          }
        }
      });
    }
  },

  checkChangeSubClaimType() {
    const {
      caseEntity,
    } = this.props.parentComponent.refs.claim_ref.state;
    const objSize = caseEntity.ObjectList.length;
    const objectList = [];
    for (let i = 0; i < objSize; i += 1) {
      const { objectEntity } = this.props.parentComponent.refs[
        `subclaim_ref${i}`
      ].state;
      objectList.push(objectEntity);
    }
    caseEntity.ObjectList = objectList;
    return ClaimHandlingAction.checkChangeSubClaimType({
      ClaimEntity: caseEntity,
    });
  },

  filterCoverageList(caseEntity) {
    const { objectEntity } = this.state;
    let accidentTime = null;
    if (caseEntity) {
      accidentTime = caseEntity.AccidentTime;
    }
    objectEntity.PolicyCoverageList = ClaimHandlingCommonAction.filterCoverageList(
      this.state.objectEntity.SubclaimType,
      this.state.objectEntity.InsuredId,
      this.props.data.ProductCode,
      this.state.objectEntity.ItemList,
      accidentTime
    );

    this.setState({ objectEntity });
    this.refs[
      `coverage_ref${this.props.subclaimIndex}_forModify_${this.props.forModify}`
    ].subclaimTypeChanged();
    if (
      this.props.parentComponent &&
      this.props.parentComponent.refs.claim_ref
    ) {
      this.currencyChanged(
        this.props.parentComponent.refs.claim_ref.state.caseEntity
          .CurrencyCode
      );
    }
  },

  filterDamageType(flag, oldValue) {
    const { objectEntity } = this.state;
    const codeTableDamageType = ClaimHandlingCommonAction.filterDamageType(
      this.props.data.CodeTableDamageType,
      objectEntity.SubclaimType
    );
    this.setState({
      codeTableDamageType,
      objectEntity:
        !oldValue && codeTableDamageType.codes.length !== 1
          ? objectEntity
          : this.updateObjectEntity(
            'DamageType',
            codeTableDamageType,
            flag
          ),
    });
  },

  filterSubclaimType(lossCause, flag, oldValue, cleanType) {
    let codeTableSubclaimType = null;
    if (lossCause && this.state.objectEntity.ObjectId) {
      codeTableSubclaimType = ClaimHandlingCommonAction.filterSubclaimType(
        this.props.data.CodeTableSubclaimType,
        lossCause
      );
    } else if (cleanType && lossCause) {
      codeTableSubclaimType = ClaimHandlingCommonAction.filterSubclaimType(
        this.props.data.CodeTableSubclaimType,
        lossCause
      );
    }
    let { objectEntity } = this.state;
    const SubclaimTypeValue = objectEntity.SubclaimType;
    const ifExistInNewLossCause = ClaimHandlingCommonAction.ifExistedSubclaimTypeOnLossCause(
      this.props.data.CodeTableSubclaimType,
      lossCause,
      SubclaimTypeValue
    );
    if (ifExistInNewLossCause) {
      this.setState({
        codeTableSubclaimType,
        currentLossCause: lossCause,
        objectEntity,
      });
      return;
    }
    if (cleanType == true) {
      objectEntity = this.updateObjectEntity(
        'SubclaimType',
        codeTableSubclaimType,
        flag
      );
      objectEntity.PolicyCoverageList = [];
      objectEntity.DamageType = null;
      objectEntity.InsuredId = null;
    } else {
      this.filterDamageType(flag, oldValue);
      this.filterCoverageList(this.props.data);
    }
    this.setState({
      codeTableSubclaimType,
      currentLossCause: lossCause,
      objectEntity,
    })
  },

  ifSubClaimTypeExistedInNewCauseOfLoss(causeLoss) {
    const { objectEntity } = this.state;
    const SubclaimTypeValue = objectEntity.SubclaimType;
    if (!SubclaimTypeValue) {
      return true;
    }
    const ifExistInNewLossCause = ClaimHandlingCommonAction.ifExistedSubclaimTypeOnLossCause(
      this.props.data.CodeTableSubclaimType,
      causeLoss,
      SubclaimTypeValue
    );
    return ifExistInNewLossCause;
  },

  updateObjectEntity(fieldName, codeTable, flag) {
    const { objectEntity } = this.state;
    if (codeTable && codeTable.codes.length === 1) {
      objectEntity[fieldName] = codeTable.codes[0].id;
    }
    //----------------------
    else if (
      flag === 'countinueChangeCauseOfLoss' ||
      flag === 'changeSubclaimType'
    ) {
      objectEntity[fieldName] = null;
    } else {
      objectEntity[
        fieldName
      ] = ClaimHandlingCommonAction.handlingCurrentValue(
        codeTable,
        this.state.objectEntity[fieldName]
      );
    }
    return objectEntity;
  },

  cancelNewSubclaim() {
    if (
      !this.state.objectEntity.ObjectId &&
      this.props.parentComponent
    ) {
      const { caseForm } = this.props.parentComponent.state;
      ClaimHandlingAction.cancelNewSubclaimHandling(
        this.props.data.CaseId,
        caseForm,
        this.state.objectEntity
      );
      ClaimHandlingAction.initHandling();
    } else {
      this.rejectSubclaim();
    }
  },

  resetSubclaim(TempObjectEntity) {
    const objectEntity = TempObjectEntity;
    //  the dataState  in SubclaimInfo and   in SubClaimTypeare change   unsimulataneous .
    //  here passedin parameter - objectEntity is actually obsolete data(not include the data  of coverageList)[why did this i dont want to konw - V- ]
    //  so   attach PolicyCoverageList data from `Component state`  into objectEntity
    if (this.state.objectEntity.PolicyCoverageList) {
      objectEntity.PolicyCoverageList = this.state.objectEntity.PolicyCoverageList;
    }
    if (
      !this.state.objectEntity.ObjectId ||
      objectEntity.StatusCode === 'REJECT'
    ) {
      this.setState({ objectEntity });
    } else if (this.state.objectEntity.ObjectId) {
      this.setState({ objectEntity });
    }
    if (this.state.objectEntity.PolicyCoverageList) {
      objectEntity.PolicyCoverageList = this.state.objectEntity.PolicyCoverageList;
    }
  },
  validate() {
    const result = RegistrationValidationAction.validateSILimitForNewSubclaim(
      this.props.data.CaseId,
      this.state.objectEntity
    );
    if (result && result.Status === 'WARN') {
      this.refs.confirmDialog_ref.showDialog(
        i18n.ClaimCommon.Registration,
        result.Messages[0].Message
      );
      return true;
    }
    if (result && result.Status === 'BLOCK') {
      MessageHelper.error('', result.Messages[0].Message);
      return true;
    }
  },
  submitNewSubclaim() {
    if (this.state.objectEntity.RentalCarCompanyId) {
      this.state.objectEntity.RentalCarCompany = this.state.objectEntity.RentalCarCompanyId;
      delete this.state.objectEntity.RentalCarCompanyId;
    }
    const { objectEntity } = this.state;
    for (
      let i = 0;
      i < this.getCodeTableClaimant().codes.length;
      i += 1
    ) {
      const temp = this.getCodeTableClaimant().codes[i];
      if (temp.id == objectEntity.ClaimantId) {
        objectEntity.ClaimantName = temp.text;
        break;
      }
    }
    if (
      ClaimHandlingAction.checkNewSubClaimType(
        this.props.data.CaseId,
        this.state.objectEntity
      ) == true
    ) {
      ClaimHandlingAction.newSubclaimSubmit(
        this.props.data.CaseId,
        this.state.objectEntity
      );
    }
  },
  onClickSubmit() {
    this.submitNewSubclaim();
    DataContext.put('defaultValue', undefined);
  },
  rejectSubclaim() {
    let targetClaim;
    if (this.props.data.ObjectList.length === 1) {
      targetClaim = i18n.ClaimCommon.Claim;
      this.props.data.ObjectList[0].CaseId = this.props.data.CaseId;
      delete this.props.data.IsMotor;
      this.props.parentComponent.refs.claim_ref.refs.RejectDialog_ref.showDialog(
        this.props.data,
        targetClaim,
        this.resetSubclaim
      );
    } else {
      targetClaim = i18n.ClaimCommon.Subclaim;
      this.props.parentComponent.refs.claim_ref.refs.RejectDialog_ref.showDialog(
        this.state.objectEntity,
        targetClaim,
        this.resetSubclaim
      );
    }
  },

  editClaimant() {
    const subclaimIndex =
      this.props.forModify === 'true'
        ? sessionStorage.getItem('ModifySubclaim_index')
        : this.props.subclaimIndex;
    this.refs[`AddOrEditPartyDialog_ref${subclaimIndex}`].showDialog(
      this.state.objectEntity.ClaimParty
    );
  },

  getCodeTableClaimant() {
    return this.state.codeTableClaimant;
  },
  getCodeTablePartyRole() {
    const codeTablePartyRole = ClaimHandlingCommonAction.getCodeTableClaimPartyRole(
      CodeTableAction.loadDataList(
        CodeTableCstUI.ClaimPartyRole,
        null
      ),
      this.state.claimPartyList
    );
    return codeTablePartyRole;
  },
  getSwissTableClaimant() {
    const codeTableClaimantData = ClaimHandlingCommonAction.changeClaimPartyRole(
      this.state.objectEntity.ClaimantType,
      this.state.claimPartyList
    );
    return codeTableClaimantData;
  },
  changePartyRole(event) {
    const { claimPartyList } = this.state;
    const codeTableClaimantData = ClaimHandlingCommonAction.changeClaimPartyRole(
      event.newValue,
      claimPartyList
    );
    const { objectEntity } = this.state;
    objectEntity.ClaimantId = '';
    if (codeTableClaimantData.codes.length === 1) {
      this.state.objectEntity.ClaimantId =
        codeTableClaimantData.codes[0].id;
      const event = {};
      event.newValue = codeTableClaimantData.codes[0].id;
      this.changeClaimant(event);
    }
    //---------------------------;
    objectEntity.ClaimParty = ClaimHandlingCommonAction.getClaimParty(
      '',
      this.props.data.ClaimPartyList
    );
    this.setState({
      codeTableClaimant: codeTableClaimantData,
      objectEntity,
      initRole: true,
    });
  },
  getClaimantInfo() {
    const claimParty = ClaimHandlingCommonAction.getClaimPartyInfo(
      this.state.objectEntity.ClaimantId,
      this.state.claimPartyList
    );

    let claimantInfo = '';
    if (claimParty && claimParty.PartyContactList) {
      claimParty.PartyContactList.forEach((data, index) => {
        if (data.IsPrimaryContact === 'Y') {
          claimantInfo = `${claimantInfo + data.HandPhone};${
            data.Email
            };`;
        }
      });
    }
    if (claimParty && claimParty.AddressVoList) {
      claimParty.AddressVoList.forEach((data, index) => {
        if (data.IsPrimaryAddress === 'Y') {
          claimantInfo += data.AddressLine1;
        }
      });
    }
    return claimantInfo;
  },
  changeClaimant(event) {
    const { objectEntity } = this.state;
    const subclaimIndex =
      this.props.forModify === 'true'
        ? sessionStorage.getItem('ModifySubclaim_index')
        : this.props.subclaimIndex;
    DataContext.remove(`ClaimantId${subclaimIndex}`);
    if (event.newValue == -1 && event.component) {
      objectEntity.ClaimantId = objectEntity.ClaimParty
        ? objectEntity.ClaimParty.PartyId
        : '';
      DataContext.put('ClaimType', 'SAL,SUB');
      const RegistrationHandlingAction = ClaimRegistration.getClaimRegistrationHandlingAction();
      const registrationHandlingAction = new RegistrationHandlingAction();
      registrationHandlingAction.chooseNewClaimant(
        null,
        subclaimIndex
      );
      DataContext.put('defaultValue', '');
      this.setState({ objectEntity });
    } else if (event.newValue !== '') {
      objectEntity.ClaimParty = ClaimHandlingCommonAction.getClaimParty(
        event.newValue,
        this.state.claimPartyList
      );
      this.getCommonPartyBasicInfo(objectEntity.ClaimParty);
      if (event.newValue == -1 && !event.component) {
        DataContext.put('defaultValue', '');
      } else {
        DataContext.put('defaultValue', event.newValue);
      }
      this.setState({ objectEntity });
    } else if (!event.newValue) {
      DataContext.put('defaultValue', event.newValue);
      objectEntity.AccidentAddress1 = '';
      this.setState({
        objectEntity,
        commonPartyInfo: {},
      });
    }
  },

  getSubclaimIndex() {
    const subclaimIndex =
      this.props.forModify === 'true'
        ? sessionStorage.getItem('ModifySubclaim_index')
        : this.props.subclaimIndex;
    return subclaimIndex;
  },

  getPartySearchPanelDialog() {
    const _self = this;
    const subclaimIndex =
      _self.props.forModify === 'true'
        ? sessionStorage.getItem('ModifySubclaim_index')
        : _self.props.subclaimIndex;
    console.log(`==subclaimIndex==${subclaimIndex}`);

    if (_self.props.forModify === 'true') {
      return (
        <ChooseClaimantDialog
          index={subclaimIndex + _self.props.forModify}
          getDefaultClaimant={_self.getDefaultClaimant}
          caseId={_self.props.data.CaseId}
          fromWhere="claimant"
          parentComponent={_self}
        />
      );
    }
    return (
      <ChooseClaimantDialog
        index={subclaimIndex}
        getDefaultClaimant={this.getDefaultClaimant}
        caseId={_self.props.data.CaseId}
        fromWhere="claimant"
        parentComponent={_self}
      />
    );
  },

  getDefaultClaimant(claimantParty) {
    const { objectEntity } = this.state;
    objectEntity.ClaimantId = claimantParty.PartyId;
    this.getCommonPartyBasicInfo(claimantParty);
    DataContext.put('defaultValue', undefined);
    DataContext.put('fromPartyChoose', true);
    this.setState({ objectEntity });
  },

  showPartyAddressDialog() {
    if (!this.state.commonPartyInfo.PartyId) {
      MessageHelper.warning(
        i18n.ClaimAppraisal.WarnMsg.Warn_0005,
        i18n.ClaimAppraisal.WarnMsg.Warn_0006,
        MessageHelper.POSITION_TOP_RIGHT
      );
      return;
    }
    console.log(
      `==this.state.commonPartyInfo==${JSON.stringify(
        this.state.commonPartyInfo
      )}`
    );
    this.refs.PtyIntegrationAddress_ref.showDialog(
      null,
      this.state.commonPartyInfo
    );
  },

  callBackGetRecord(newAddress) {
    console.log(`==newAddress==${JSON.stringify(newAddress)}`);
    if (newAddress) {
      const { objectEntity } = this.state;
      this.getCommonPartyBasicInfo(objectEntity.ClaimParty);
      objectEntity.AccidentAddress1 = this.buildAddress(newAddress);
      this.setState({ objectEntity });
    }
    this.refs.PtyIntegrationAddress_ref.hideDialog();
  },

  buildAddress(addressInfo) {
    let addressInfoCity;
    let addressInfoArea;
    let addressInfoStreetName;

    if (addressInfo.Country === 'US') {
      const cityCodeTable = DefineCodeTable.geRegion(
        addressInfo.Country,
        2,
        null
      ).codes;
      const areaCodeTable = DefineCodeTable.geRegion(
        addressInfo.Country,
        3,
        addressInfo.City
      ).codes;
      const streetNameCodeTable = DefineCodeTable.geRegion(
        addressInfo.Country,
        5,
        addressInfo.Area
      ).codes;
      _.forEach(cityCodeTable, data => {
        if (data.Code == addressInfo.City) {
          addressInfoCity = data.text;
        }
      });
      _.forEach(areaCodeTable, data => {
        if (data.Code == addressInfo.Area) {
          addressInfoArea = data.text;
        }
      });
      _.forEach(streetNameCodeTable, data => {
        if (data.Code == addressInfo.StreetName) {
          addressInfoStreetName = data.text;
        }
      });
    } else {
      addressInfoCity = addressInfo.City;
      addressInfoArea = addressInfo.Area;
      addressInfoStreetName = addressInfo.StreetName;
    }
    const streetNo = addressInfo.StreetNo;
    const StreetName = addressInfo.StreetName;
    const { BuildingName } = addressInfo;
    const { Country } = addressInfo;
    const Postcode = addressInfo.PostCode;
    const Area = addressInfoArea || addressInfo.State;
    const City = addressInfoCity || addressInfo.City;

    let addressStr = null;

    addressStr = this.appendAddress(addressStr, Postcode);
    addressStr = this.appendAddress(addressStr, Area);
    addressStr = this.appendAddress(addressStr, City);
    addressStr = this.appendAddress(addressStr, StreetName);
    addressStr = this.appendAddress(addressStr, Country);
    return addressStr;
  },

  appendAddress(addressStr, appendStr) {
    if (appendStr) {
      if (addressStr) {
        addressStr += `, ${appendStr}`;
      } else {
        addressStr = appendStr;
      }
    }

    return addressStr;
  },

  getCommonPartyBasicInfo(claimParty) {
    if (claimParty && claimParty.PtyPartyId) {
      const partyAction = new PartyAction();
      const commonParty = partyAction.loadPartyByPartyId(
        claimParty.PtyPartyId
      );
      this.state.commonPartyInfo = commonParty;
      this.updateClaimantInfo();
    }
  },
  updateClaimantInfo() {
    let AddressVoList = [];

    if (this.state.commonPartyInfo) {
      AddressVoList = this.state.commonPartyInfo.AddressVoList;
    }

    let newclaimantinfo = '';
    for (const index in AddressVoList) {
      const vo = AddressVoList[index];
      if (vo.IsPrimaryAddress === 'Y') {
        newclaimantinfo = this.buildAddress(vo);
      }
    }
    this.state.objectEntity.AccidentAddress1 = newclaimantinfo;
    this.forceUpdate();
  },
  showClaimantInfo(claimantInfo) {
    let newclaimantinfo = '';
    if (claimantInfo.ContantNumber) {
      newclaimantinfo = `${newclaimantinfo +
        claimantInfo.ContantNumber};`;
    }
    if (claimantInfo.Email) {
      newclaimantinfo = `${newclaimantinfo + claimantInfo.Email};`;
    }
    if (claimantInfo.AddressType) {
      newclaimantinfo = `${newclaimantinfo +
        claimantInfo.AddressType};`;
    }
    if (claimantInfo.Country) {
      newclaimantinfo = `${newclaimantinfo + claimantInfo.Country};`;
    }
    if (claimantInfo.AddressLine1) {
      newclaimantinfo = `${newclaimantinfo +
        claimantInfo.AddressLine1};`;
    }
    if (claimantInfo.PostCode) {
      newclaimantinfo = `${newclaimantinfo + claimantInfo.PostCode};`;
    }
    return newclaimantinfo;
  },

  updateSelectedClaimatInfo(commonParty) {
    const { commonPartyInfo } = this.state;
    commonPartyInfo.PartyContactList = commonParty.PartyContactList;
    commonPartyInfo.AddressVoList = commonParty.AddressVoList;
    this.setState({ commonPartyInfo });
    this.updateClaimantInfo();
  },

  addOrUpdateParty(result) {
    let claimEntity;
    if (this.props.parentComponent) {
      try {
        // from registation;
        claimEntity = this.props.parentComponent.getCaseForm()
          .ClaimEntity;
      } catch (e) {
        // from New Claim page(When click new subclaim);
        claimEntity = this.props.parentComponent.refs.claim_ref.state
          .caseEntity;
      }
    }
    let { subclaimIndex } = this.props;
    if (!this.props.subclaimIndex) {
      subclaimIndex = sessionStorage.getItem('ModifySubclaim_index');
    }
    const caseEntity = ClaimHandlingAction.updateClaimPartyList(
      result.claimParty,
      this.props.parentComponent ? claimEntity : this.props.data,
      subclaimIndex
    );
    DataContext.put(
      'objectEntity',
      caseEntity.ObjectList[subclaimIndex]
    );
    this.setState({
      initRole: true,
    });
  },

  getIo() {
    let io = this.state.objectEntity.ObjectId ? this.props.io : 'in';
    io = this.state.objectEntity.StatusCode === 'REJECT' ? 'out' : io;
    return io;
  },

  currencyChanged(newCurrency) {
    this.refs[
      `coverage_ref${this.props.subclaimIndex}_forModify_${this.props.forModify}`
    ].currencyChanged(newCurrency);
  },
  getParentComponent() {
    return this;
  },

  getDefaultClaimantId(claimPartyList) {
    let result = null;
    const dmgParty = this.state.objectEntity.damageParty;
    const { codeTableClaimant } = this.state;
    if (
      codeTableClaimant.codes &&
      codeTableClaimant.codes.length > 0 &&
      dmgParty &&
      dmgParty === 'FP'
    ) {
      codeTableClaimant.codes.forEach(claimParty => {
        if (DataContext.get('policyHolder')) {
          if (
            DataContext.get('policyHolder').PartyName ==
            claimParty.text
          ) {
            result = claimParty.id;
          }
        }
      });
    }
    return result;
  },

  totalLossVisibility() {
    const dmgType = this.state.objectEntity.DamageType;
    if (dmgType === 'I') {
      return false;
    }
    return true;
  },

  onChangeIsRentalCarService(event) {
    this.forceUpdate();
    UpdateContext.forceUpdate('rentalCarInformation');
  },

  getTownCodeTable() {
    // City?r
    const condition = {
      REGION_LEVEL: 2,
      COUNTRY_CODE: this.props.data.CountryCode,
    };
    const cyCityCodeTable = CodeTableAction.loadDataList(
      CodeTableCstUI.ClaimPartyCity,
      condition
    );
    return cyCityCodeTable;
  },

  feeRegex(data) {
    const regex = new RegExp('^[0-9]+(.[0-9]{1,3})?$');

    return regex.exec(data);
  },

  dayRegex(data) {
    const regex = new RegExp('[1-9][0-9]*');
    return regex.exec(data);
  },

  rentalCarCompanyOnchange(data) {
    this.state.objectEntity.RentalCarCompany = data;
  },

  carRentalTownOnChange(data) {
    this.state.objectEntity.CarRentalTown = data.newValue;
  },

  dailyRentalFeeOnChange(data) {
    const dailyRentalFee = this.feeRegex(data.newValue);
    if (dailyRentalFee) {
      this.state.objectEntity.DailyRentalFee = dailyRentalFee[0];
      this.state.objectEntity.TotalRentalFee =
        dailyRentalFee[0] * this.state.objectEntity.RentalPeriod;
    } else {
      this.state.objectEntity.DailyRentalFee = '';
      this.state.objectEntity.TotalRentalFee = '';
    }
    // rentalCarInformation
    this.forceUpdate();
  },

  rentalPeriodOnChange(data) {
    const rentalPeriod = this.dayRegex(data.newValue);
    if (rentalPeriod) {
      this.state.objectEntity.RentalPeriod = rentalPeriod[0];
      this.state.objectEntity.TotalRentalFee =
        this.state.objectEntity.DailyRentalFee * rentalPeriod[0];
    } else {
      this.state.objectEntity.RentalPeriod = '';
      this.state.objectEntity.TotalRentalFee = '';
    }
    //rentalCarInformation;
    this.forceUpdate();
  },
  showPartySearchPanelDialog(event) {
    const partyRole = event.getParameter('partyRole');
    const stateParam = event.getParameter('stateParam');
    let partyCategory = event.getParameter('partyCategory');
    partyCategory = event.getParameter('partyCategory');
    this.refs.partySearchDialogForCarRental.showDialog({
      partyCategory,
      partyRole,
      stateParam,
      partyRoleSelectEnable: false,
      partyCategorySelectEnable: false,
      createBtnDisable: true,
      editLinkVisiable: false,
      partyRoleSelectVisiable: false,
    });
    this.refs.partySearchDialogForCarRental.doContinue = (
      ...args
    ) => {
      const party = args[0];
      if (!party) return;
      const stateJson = {};
      if (party.partyInfo.length === 1) {
        stateJson[stateParam] = party.partyInfo[0];
        this.setState(stateJson);
        if (stateParam === 'rentalOrgCarCompany') {
          this.state.objectEntity.RentalCarCompany =
            party.partyInfo[0].PartyName;
          this.state.objectEntity.RentalCarCompanyId =
            party.partyInfo[0].PartyId;
        }
      }
    };
  },
  syncProperty(caseEntity) {
    const caseObject = this.state.objectEntity;
    caseObject.CareCallDate = caseEntity.CareCallDate;
    caseObject.CareOperator = caseEntity.CareOperator;
    caseObject.CareProvider = caseEntity.CareProvider;
    caseObject.CareServiceDate = caseEntity.CareServiceDate;
    caseObject.CompanyCare = caseEntity.CompanyCare;
  },
  render() {
    const {
      readOnly,
      subclaimIndex,
      from,
      forModify,
      data: {
        CaseId,
        PolicyEntity,
        IsMotor,
        CodeTableClaimOwner,
        CodeTableInsured,
      },
    } = this.props;
    const {
      objectEntity,
      objectEntity: {
        ObjectId,
      },
      policeInfoIsRequired,
      isMotor,
      claimPartyList,
    } = this.state;
    const partOfId = `${subclaimIndex}_forModify_${forModify}`;
    if (!DataContext.get('objectEntity')) {
      if (!ObjectId) {
        DataContext.put('objectEntity', objectEntity);
      }
    }
    if (!objectEntity) {
      this.state.objectEntity = DataContext.get('objectEntity');
    }

    const partyAction = new PartyAction();
    const copyObjectEntity = objectEntity;
    if (
      copyObjectEntity &&
      copyObjectEntity.ClaimPartycopy &&
      ObjectEntity.ClaimParty.PtyPartyId
    ) {
      const commonParty = partyAction.loadPartyByPartyId(
        copyObjectEntity.ClaimParty.PtyPartyId
      );
      let AddressVoList = [];
      if (commonParty) {
        AddressVoList = commonParty.AddressVoList;
      }
      let newclaimantinfo = '';
      for (const index in AddressVoList) {
        const vo = AddressVoList[index];
        if (vo.IsPrimaryAddress === 'Y') {
          newclaimantinfo = this.buildAddress(vo);
        }
      }
      objectEntity.AccidentAddress1 = newclaimantinfo;
    }

    const io = this.getIo();
    let coverageIo = this.getIo();
    if (forModify === 'true') {
      coverageIo = 'out';
    }
    let enable = 'true';
    const Coverage = ClaimRegistration.getCoverage();
    const CommonConfirmDialog = ClaimCommonExt.getCommonConfirmDialog();
    let defaultClaimantId = this.getDefaultClaimantId(claimPartyList);

    if (io === 'in') {
      if (DataContext.get('fromPartyChoose') !== true) {
        if (
          defaultClaimantId == undefined &&
          from !== 'modifySubClaim' &&
          !objectEntity.ClaimantId
        ) {
          objectEntity.ClaimantId = null;
          DataContext.put('fromPartyChoose', false);
        }
      }
      if (
        DataContext.get(`ClaimantId${subclaimIndex}`) != '' &&
        DataContext.get(`ClaimantId${subclaimIndex}`) != undefined
      ) {
        const ci = DataContext.get(`ClaimantId${subclaimIndex}`);
        objectEntity.ClaimantId = ci;
        defaultClaimantId = ci;
      } else if (
        DataContext.get('defaultValue') ||
        DataContext.get('defaultValue') === ''
      ) {
        if (DataContext.get('defaultValue') !== '') {
          defaultClaimantId = DataContext.get('defaultValue');
        } else if (objectEntity.ClaimantId) {
          defaultClaimantId = objectEntity.ClaimantId;
        } else {
          objectEntity.ClaimantId = null;
          defaultClaimantId = null;
        }
      }
    } else {
      enable = 'false';
    }

    const partydialogId = `_subclaim_${subclaimIndex}`;
    const partyFiPropertyId = `_fi_property${subclaimIndex}`;
    return (
      <UIUpdatePanel id="subclaimInfo">
        <PartySearchPanelDialog
          ref="partySearchDialogForCarRental"
          parentComponent={this}
          endId={partyFiPropertyId}
          onClose={this.onCloseRentalCar}
        />
        <PtyIntegrationAddress
          ref="PtyIntegrationAddress_ref"
          onClose={this.onCloseRentalCar}
          submitHandler={this.onCloseRentalCar}
          callBackGetRecord={this.callBackGetRecord}
          parentComponent={this}
          endId={partydialogId}
          title={i18n.ClaimCommon.ClaimantAddress}
        />
        <UISmartPanelGrid column="3" widthAllocation="5,7">
          <UISelect
            id={`insuredObject${partOfId}`}
            label={i18n.ClaimCommon.InsuredObject}
            required="true"
            validationGroup="RegistrationInfo"
            codeTable={CodeTableInsured}
            onChange={this.changeInsured}
            model={objectEntity}
            property="InsuredId"
            io={forModify === 'true' ? 'out' : io}
          />

          <UIRadio
            id={`isSal${partOfId}`}
            label={i18n.ClaimCommon.PossibleSalvage}
            codeTable={CodeTableAction.loadDataList(
              CodeTableCstUI.PlateFormYesNoYN,
              null
            )}
            model={objectEntity}
            property="IsSalvage"
            required="true"
            validationGroup="RegistrationInfo"
            io={io}
          />

          <UIRadio
            id={`isSub${partOfId}`}
            label={i18n.ClaimCommon.PossibleSubrogation}
            codeTable={CodeTableAction.loadDataList(
              CodeTableCstUI.PlateFormYesNoYN,
              null
            )}
            model={objectEntity}
            property="IsSubrogation"
            required="true"
            validationGroup="RegistrationInfo"
            io={io}
          />

          {this.getUISelect()}

          <UIText
            id={`damageObject${partOfId}`}
            label={i18n.ClaimCommon.DamageObject}
            required="true"
            model={objectEntity}
            property="RiskName"
            validationGroup="RegistrationInfo"
            io={io}
          />

          {this.getComponetEstimatedLoss(objectEntity)}

          <UIRadio
            id={`damageSeverity${partOfId}`}
            label={i18n.ClaimCommon.DamageSeverity}
            codeTable={CodeTableAction.loadDataList(
              CodeTableCstUI.ClaimObjectDamageSeverity,
              null
            )}
            disabled="true"
            model={objectEntity}
            property="DamageSeverity"
            required="true"
            validationGroup="RegistrationInfo"
            io={io}
          />

          <UISelect
            id={`Owner${partOfId}`}
            label={i18n.ClaimCommon.ClaimOwner}
            codeTable={CodeTableClaimOwner}
            model={objectEntity}
            property="OwnerId"
            required="true"
            validationGroup="RegistrationInfo"
            io={io}
          />

          <UIRadio
            id={`litigation${partOfId}`}
            label={i18n.ClaimCommon.Litigation}
            codeTable={CodeTableAction.loadDataList(
              CodeTableCstUI.PlateFormYesNoYN,
              null
            )}
            itemSpacing="20px"
            model={objectEntity}
            property="LitigationFlag"
            required="true"
            validationGroup="RegistrationInfo"
            io={io}
          />

          <UIRadio
            id={`totalLoss${partOfId}`}
            label={i18n.ClaimCommon.TotalLoss}
            codeTable={CodeTableAction.loadDataList(
              CodeTableCstUI.PlateFormYesNoYN,
              null
            )}
            itemSpacing="20px"
            model={objectEntity}
            property="TotalLossFlag"
            visibled={this.totalLossVisibility}
            defaultValue="N"
            required="true"
            validationGroup="RegistrationInfo"
            io={io}
          />
          <UICell />

          <UISelect
            id={`Claimant${partOfId}`}
            label={i18n.ClaimCommon.Claimant}
            codeTable={this.getCodeTableClaimant}
            model={objectEntity}
            property="ClaimantId"
            onChange={this.changeClaimant}
            required="true"
            defaultValue={defaultClaimantId}
            validationGroup="RegistrationInfo"
            io={io}
          />
          <UIText
            id={`ClaimantAddress${partOfId}`}
            label={i18n.ClaimCommon.ClaimantAddress}
            colspan="2"
            enabled="false"
            model={objectEntity}
            property="AccidentAddress1"
            suffixIcon="fa fa-edit"
            onSuffixIconClick={this.showPartyAddressDialog}
            io={io}
          />

          <UISelect
            id={`placeOfInspection${partOfId}`}
            label={i18n.ClaimCommon.PlaceOfInspection}
            codeTable={CodeTableAction.loadDataList(
              CodeTableCstUI.ClaimPlaceOfInspaction,
              null
            )}
            model={objectEntity}
            property="WorkshopId"
            validationGroup="RegistrationInfo"
            io={io}
          />

          <UIText
            id={`inspectionAddress${partOfId}`}
            label={i18n.ClaimCommon.InspectionAddress}
            colspan="2"
            model={objectEntity}
            property="ObjectPlace"
            validationGroup="RegistrationInfo"
            io={io}
          />

          <UITextarea
            id={`damageDescription${partOfId}`}
            label={i18n.ClaimCommon.DamageDescription}
            model={objectEntity}
            property="DamageDesc"
            colspan="3"
            enabled={enable}
          />
        </UISmartPanelGrid>

        <UIUpdatePanel id="rentalCarInformation" colspan="3">
          {this.renderRentalCarInfo(partOfId, io)}
        </UIUpdatePanel>
        <br />

        <Coverage
          ref={`coverage_ref${partOfId}`}
          io={coverageIo}
          forModify={forModify}
          partOfId={partOfId}
          parentComponent={this}
          subclaimIndex={subclaimIndex}
          data={objectEntity}
          policyData={PolicyEntity}
          synCoverageData={this.coverageDataChanged}
        />
        {
          <AddOrEditPartyDialog
            fromWhere="claimant"
            ref={`AddOrEditPartyDialog_ref${subclaimIndex}`}
            index={subclaimIndex}
            caseId={CaseId}
            parentComponent={this}
            title={i18n.ClaimCommon.ClaimantInfo}
          />
        }

        {this.getChoosePayeeOrPayerDialog()}
        {this.getSubclaimButton()}
        <CommonConfirmDialog
          ref="confirmDialog_ref"
          parentComponent={this}
          objectId={`_subclaimInfo${readOnly}_${subclaimIndex}`}
          onConfirm={this.submitNewSubclaim}
          onCancel={this.cancel}
        />
      </UIUpdatePanel>
    );
  },

  renderRentalCarInfo(partOfId, io) {
    if (this.state.objectEntity.DamageType === 'V') {
      let { objectEntity, objectEntity: { RentalCarCompanyId, RentalCarCompany, DailyRentalFee, RentalPeriod } } = this.state;
      let isRentalCarServiceFlag = objectEntity.RentalCarService === 'Y';
      let RentalCurrrencySign = window.EU00.getCurrencySignByCurrencyCode(
        objectEntity.EstimatedLossCurrency
      );

      if (RentalCarCompany && (!RentalCarCompanyId || RentalCarCompany === RentalCarCompanyId)) {
        const partyAction = new PartyAction();
        const partyMo = partyAction.loadPartyByPartyId(
          RentalCarCompany
        );
        if (partyMo) {
          objectEntity.RentalCarCompanyId = RentalCarCompany;
          objectEntity.RentalCarCompany = partyMo.PartyName;
        }
      }

      return (
        <UISmartPanelGrid column="3" widthAllocation="5,7">
          <UIRadio
            id={`rentalCarService${partOfId}`}
            label={i18n.ClaimCommon.RentalCarService}
            model={objectEntity}
            property="RentalCarService"
            onChange={this.onChangeIsRentalCarService}
            codeTable={CodeTableAction.loadDataList(
              CodeTableCstUI.PlateFormYesNoYN,
              null
            )}
            validationGroup="RegistrationInfo"
            io={io}
          />

          <UIText
            id={`rentalCarCompany${partOfId}`}
            label={i18n.ClaimCommon.RentalCarCompany}
            model={objectEntity}
            property="RentalCarCompany"
            required={isRentalCarServiceFlag}
            disabled="true"
            onChange={this.rentalCarCompanyOnchange}
            suffixIcon="fa fa-search"
            onSuffixIconClick={this.showPartySearchPanelDialog}
            validationGroup="RegistrationInfo"
            io={io}
          >
            <UIParam
              name="partyRole"
              value={PartyConst.PARTY_ROLE_CODE_ORG_CAR_RENTAL}
            />
            <UIParam
              name="partyCategory"
              value={PartyConst.PARTY_TYPE_ORG}
            />
            <UIParam
              name="stateParam"
              value="rentalOrgCarCompany"
            />
          </UIText>

          <UISelect
            id={`carRentalTown${partOfId}`}
            label={i18n.ClaimCommon.CarRentalTown}
            model={objectEntity}
            property="CarRentalTown"
            validationGroup="RegistrationInfo"
            required={isRentalCarServiceFlag}
            codeTable={this.getTownCodeTable}
            onChange={this.carRentalTownOnChange}
            suffixIcon="fa fa-search"
            io={io}
          />

          <UICurrency
            id={`dailyRentalFee${partOfId}`}
            unit={RentalCurrrencySign}
            label={i18n.ClaimCommon.DailyRentalFee}
            model={objectEntity}
            property="DailyRentalFee"
            required={isRentalCarServiceFlag}
            validationGroup="RegistrationInfo"
            minValue="0"
            onChange={this.dailyRentalFeeOnChange}
            io={io}
          />
          <UIText
            id={`rentalPeriod${partOfId}`}
            label={i18n.ClaimCommon.RentalPeriod}
            model={objectEntity}
            property="RentalPeriod"
            required={isRentalCarServiceFlag}
            validationGroup="RegistrationInfo"
            minValue="0"
            onChange={this.rentalPeriodOnChange}
            io={io}
          />
          <UICurrency
            id={`totalRentalFee${partOfId}`}
            unit={RentalCurrrencySign}
            label={i18n.ClaimCommon.TotalRentalFee}
            model={objectEntity}
            enabled={false}
            value={DailyRentalFee * RentalPeriod}
            property="TotalRentalFee"
            required={isRentalCarServiceFlag}
            validationGroup="RegistrationInfo"
            minValue="0"
            defaultValue=""
            io={io}
          />
          <ClaimCompanyCareInfo
            caseEntity={objectEntity}
            syncProperty={this.syncProperty}
            ioflag={io}
            colspan="3"
          />

          <UISelect
            label={i18n.ClaimCommon.InsuranceCompany}
            model={objectEntity}
            property="InsuranceCompany"
            codeTable={CodeTableAction.getInsuranceCompany()}
            io={io}
          />
          {/*this.renderThirdPartyDriverInfo(isMotor)}
          {this.renderMigrationRemark(partOfId, io)*/}
        </UISmartPanelGrid>
      );
    }
  },

  renderThirdPartyDriverInfo(isMotor) {
    const dmgParty = this.state.objectEntity.damageParty;
    if (isMotor && dmgParty === 'TP') {
      return (
        <ThirdPartyDriverInfo
          claimEntity={this.props.data}
          io={this.getIo()}
          forModify={this.props.forModify}
          objectEntity={this.state.objectEntity}
          colspan="3"
        />
      );
    }
  },

  coverageDataChanged(policyCoverageList) {
    this.state.objectEntity.PolicyCoverageList = policyCoverageList;
    this.forceUpdate();
  },
  renderMigrationRemark(partOfId, io) {
    const { objectEntity } = this.state;
    if (io === 'out') {
      return (
        <UIText
          id={`Remark${partOfId}`}
          label={i18n.ClaimCommon.MigrationRemark}
          validationGroup="RegistrationInfo"
          model={objectEntity}
          property="Remark"
          disabled="true"
          colspan="3"
        />
      );
    }
    return <div />;
  },
  getClaimant() {
    const _self = this;
    const subclaimIndex =
      _self.props.forModify === 'true'
        ? sessionStorage.getItem('ModifySubclaim_index')
        : _self.props.subclaimIndex;
    const ComponetComonParty = ClaimCommonExt.getClaimPartyAddOrEditPartyDialog();
    return (
      <ComponetComonParty
        showRadio="true"
        fromWhere="claimant"
        ref={`AddOrEditPartyDialog_ref${subclaimIndex}`}
        index={subclaimIndex}
        caseId={_self.props.data.CaseId}
        parentComponent={this}
        title={i18n.ClaimCommon.ClaimantInfo}
      />
    );
  },
  getChoosePayeeOrPayerDialog() {
    const _self = this;
    const subclaimIndex =
      _self.props.forModify === 'true'
        ? sessionStorage.getItem('ModifySubclaim_index')
        : _self.props.subclaimIndex;
    return (
      <ChoosePayeeOrPayerDialog
        index={`a${subclaimIndex}`}
        roleType="01"
        caseId={_self.props.data.CaseId}
        callback={_self.callback}
        fromWhere="claimant"
        title={i18n.PtySearchClaimant}
      />
    );
  },
  callback(TempSubclaimIndex, payee, roleList) {
    let subclaimIndex = TempSubclaimIndex;
    subclaimIndex = subclaimIndex.substring(1, subclaimIndex.length);
    let claimEntity;
    if (this.props.parentComponent) {
      try {
        // from registation
        claimEntity = this.props.parentComponent.getCaseForm()
          .ClaimEntity;
      } catch (e) {
        //from New Claim page(When click new subclaim);
        claimEntity = this.props.parentComponent.refs.claim_ref.state
          .caseEntity;
      }
    }

    const caseEntity = ClaimHandlingAction.updateClaimPartyList(
      payee,
      this.props.parentComponent ? claimEntity : this.props.data,
      subclaimIndex
    );
    DataContext.put(
      'objectEntity',
      caseEntity.ObjectList[subclaimIndex]
    );
    this.getCommonPartyBasicInfo(payee);
  },
  getSubclaimButton() {
    const initIo = this.getIo();
    const buttons = [];
    const { forModify, subclaimIndex, io } = this.props;
    const {
      objectEntity: { ObjectId },
    } = this.state;
    if (ObjectId) {
      if (initIo === 'in' && forModify === 'false') {
        // for registration and modify page
        buttons.push(
          <UIButton
            id={`Rejected_button${subclaimIndex}`}
            value={i18n.ClaimCommon.RejectSubclaim}
            onClick={this.cancelNewSubclaim}
          />
        );
      }
    } else {
      if (io === 'out') {
        buttons.push(
          <UIButton
            id={`submit_newSubclaim_button${subclaimIndex}`}
            value={i18n.ClaimCommon.Submit}
            causeValidation="true"
            validationGroup="RegistrationInfo,SubClaimAmountCheck"
            onClick={this.onClickSubmit}
          />
        );
      }

      buttons.push(
        <UIButton
          id={`Cancel_subclaim_button${subclaimIndex}`}
          value={i18n.ClaimCommon.RejectSubclaim}
          onClick={this.cancelNewSubclaim}
        />
      )
    }
    return <UIBox direction="left">{buttons}</UIBox>;
  },

  getUISelect() {
    const {
      io,
      subclaimIndex,
      data: {
        CodeTableSubclaimType,
        CodeTableDamageType,
        PolicyEntity: { ProductLineCode },
      },
      forModify,
    } = this.props;
    const {
      objectEntity: {
        ObjectId,
        StatusCode,
        SubclaimType,
        DamageType,
      },
    } = this.state;
    if ((ObjectId && io === 'out') || StatusCode === 'REJECT') {
      return (
        <UISmartPanelGrid colspan="2" column="2">
          <UISelect
            id={`SubclaimType${subclaimIndex}`}
            label={i18n.ClaimCommon.SubclaimType}
            codeTable={CodeTableSubclaimType}
            value={SubclaimType}
            io="out"
            onChange={this.setDamageParty}
          />

          <UISelect
            id={`damageType${subclaimIndex}`}
            label={i18n.ClaimCommon.DamageType}
            codeTable={CodeTableDamageType}
            value={DamageType}
            io="out"
          />
        </UISmartPanelGrid>
      );
    }
    if (!SubclaimType) {
      return (
        <UISmartPanelGrid colspan="2" column="2">
          <UISelect
            id={`SubclaimType${subclaimIndex}`}
            label={i18n.ClaimCommon.SubclaimType}
            required="true"
            validationGroup="RegistrationInfo"
            codeTable={this.getSubclaimType}
            io={forModify === 'true' ? 'out' : 'in'}
            childrenId={`damageType${subclaimIndex}`}
            valueLink={this.bindToState(
              'objectEntity',
              'SubclaimType'
            )}
            onChange={this.changeSubclaimType}
          />

          <UISelect
            id={`damageType${subclaimIndex}`}
            label={i18n.ClaimCommon.DamageType}
            required="true"
            validationGroup="RegistrationInfo"
            codeTable={new CodeTable([])}
            io={forModify === 'true' ? 'out' : 'in'}
            valueLink={this.bindToState('objectEntity', 'DamageType')}
            foreignKey="Parent"
          />
        </UISmartPanelGrid>
      );
    }

    return (
      <UISmartPanelGrid colspan="2" column="2">
        <UISelect
          id={`SubclaimType${subclaimIndex}`}
          label={i18n.ClaimCommon.SubclaimType}
          required="true"
          validationGroup="RegistrationInfo"
          codeTable={this.getSubclaimType}
          io={forModify === 'true' ? 'out' : 'in'}
          valueLink={this.bindToState('objectEntity', 'SubclaimType')}
          onChange={this.changeSubclaimType}
          childrenId={`damageType${subclaimIndex}`}
        />

        <UISelect
          id={`damageType${subclaimIndex}`}
          label={i18n.ClaimCommon.DamageType}
          required="true"
          validationGroup="RegistrationInfo"
          codeTable={CodeTableAction.loadDataList(
            CodeTableCstUI.ClaimDamageTypeCondition,
            {
              PRODUCT_LINE_CODE: ProductLineCode,
            },
            true
          )}
          io={forModify === 'true' ? 'out' : 'in'}
          valueLink={this.bindToState('objectEntity', 'DamageType')}
          foreignKey="Parent"
          parentId={`SubclaimType${subclaimIndex}`}
        />
      </UISmartPanelGrid>
    );
  },
  changeEstimatedLossAmount(event) {
    const { newValue } = event;
    const { oldValue } = event;
    const maxAmount = Number(999999999.99);
    const outstandingAmount = Number(newValue);
    if (maxAmount < outstandingAmount) {
      if (maxAmount < Number(oldValue)) {
        this.state.objectEntity.EstimatedLossAmount = 0;
      } else {
        this.state.objectEntity.EstimatedLossAmount = Number(
          oldValue
        );
      }
      this.forceUpdate();
      MessageHelper.error(i18n.ClaimReserve.MaxAmountMessage);
      return;
    }
    this.measureSeverity();
  },
  measureSeverity() {
    let severityChoice = '1'; // 1:small 2:medium 3:large
    const { mediumLoss } = this.state;
    const { highLoss } = this.state;
    let estimatedLossAmount = this.state.objectEntity
      .EstimatedLossAmount;
    const tempObjectEntity = this.state.objectEntity;
    if (estimatedLossAmount) {
      if (this.state.objectEntity.EstimatedLossCurrency === 'USD') {
        estimatedLossAmount *= this.state.rate;
      }
      if (estimatedLossAmount <= mediumLoss) {
        severityChoice = '1';
      } else if (
        mediumLoss < estimatedLossAmount &&
        estimatedLossAmount <= highLoss
      ) {
        severityChoice = '2';
      } else {
        severityChoice = '3';
      }
      tempObjectEntity.DamageSeverity = severityChoice;
      this.setState({ objectEntity: tempObjectEntity });
    }
    if (!estimatedLossAmount) {
      tempObjectEntity.DamageSeverity = null;
      this.setState({ objectEntity: tempObjectEntity });
    }
  },

  getComponetEstimatedLoss(objectEntity) {
    const io = this.getIo();
    const { subclaimIndex } = this.props;
    return (
      <UISmartPanelGrid column="2" widthAllocation="6,6">
        <UISelect
          id={`currency${subclaimIndex}`}
          label={i18n.ClaimCommon.EstimatedLoss}
          codeTable={CodeTableAction.loadDataList(
            CodeTableCstUI.ClaimCurrencyCode,
            null
          )}
          defaultValue="USD"
          model={objectEntity}
          property="EstimatedLossCurrency"
          io={io}
          onChange={this.measureSeverity}
          showBlankOption="false"
          required="true"
          validationGroup="RegistrationInfo"
        />
        <UINumber
          maxLength={CommonConstant.AMOUNT_MAX_LENGTH_4_CLAIM}
          maxLengthMessage={i18n.ClaimReserve.MaxAmountMessage}
          id={`NUMBER_CODE_objectEstimatedLoss${subclaimIndex}`}
          model={objectEntity}
          property="EstimatedLossAmount"
          // valueLink={this.bindToState("objectEntity", "EstimatedLossAmount")}
          io={io}
          onChange={this.changeEstimatedLossAmount}
          required="true"
          validationGroup="RegistrationInfo"
        />
      </UISmartPanelGrid>
    );
  },

  onCloseRentalCar() {
    if (this.props.forModify === 'true') {
      UIDialog.show('ModifySubclaimInfo_Dialog');
    }
  },
  //  getClaimantBasicInfo:() => {
  //    let claimantInfo = {};
  //    //claimantInfo = this.state
  //  }
});
export default SubclaimInfo;
