export default {
  VictimName: 'Victim Name',
  Gender: 'Gender',
  Age: 'Age',
  HomeAddress: 'Home Address',
  ContactTel: 'Contact Telephone',
  InjuryType: 'Injury Type',
  DisabilityGrade: 'Disability Grade',
  Treatment: 'Treatment',
  Hospital: 'Hospital',
  DaysInHospital: 'Days In Hospital',
  InjuryDescription: 'Injury Description',
  RemarktoVictim: 'Remark to Victim',
  RemarktoInvestigation: 'Remark to Field Investigation',

  PlateNo: 'Plate No.',
  Maker: 'Maker',
  Model: 'Model',
  ModuleYear: 'Module Year',
  MakeYear: 'Make Year',
  VehicleType: 'Vehicle Type',
  VehicleColor: 'Vehicle Color',
  VIN: 'VIN',
  EngineNumber: 'Engine Number',
  InvestigatorArrivalTime: 'Investigator Arrival Time',

  PropertyName: '物损名称',
  ClaimantIsOwner: '索赔人同物主',
  OwnerName: '物主姓名',
  FieldInvestigationAddress: 'Field Investigation Address',
  PropertyDamageDescription: 'Property Damage Description',
  RemarkToLossItems: 'Remark to Loss Items',
  FieldInvestigation: 'Field Investigation',

  VehicleInfo: 'Vehicle Info',
  PropertyInfo: 'Property Info',
  VictimInfo: 'Victim Info',
  SaveSuccess: 'Field Investigation is saved successfully',
  SubmitSuccess: 'Field Investigation is submitted successfully',
  Failed: 'Failed',
  VictimSynSuccess: 'Victim Info is synchronized successfully',
  VehicleSynSuccess: 'Vehicle Info is synchronized successfully',
  PropertySynSuccess: 'Property Info is synchronized successfully',
};
