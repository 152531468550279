export default {
  FirstOr3rdParty: '第一人或第三人',
  Currency: '币种',
  Remark: '备注',

  // 按钮
  Action: '操作',
  Submit: '提交',
  Cancel: '取消',

  // reservelist
  Reserve: '准备金',
  ReserveHistory: '准备金更改历史',
  OpenReserve: '开启准备金',
  ReserveType: '准备金类型',
  ReserveStatus: '准备金状态',
  OutstandingReserve: '准备金金额',
  ReserveUpdate: '更新',
  Settled: '结算金额',
  LockStatus: '锁定状态',
  Locked: '锁定',
  Unlock: '未锁定',

  // reservereopen
  Reopeningconfirmation: '重开准备金确定',
  AreYouSure: '确定重开准备金?',
  CauseOfReopening: '重开原因',
  NewOutstandingReserve: '未决赔款准备金',

  // reserveclose
  ClosureConfirmation: '关闭确认',
  AreYouSureCloseReserve: '确定关闭准备金?',
  AreYouSureCloseItem: '确定关闭险种?',
  AreYouSureCloseObject: '确定关闭子赔案?',
  AreYouSureCloseCase: '确定关闭赔案?',
  CauseOfClosure: '关闭类型',
  ReasonTypeOfClosure: '关闭原因',

  // openreserve
  Status: '状态',

  // reservehistory
  ClaimFileType: '准备金类型',
  ChangeAmount: '改变金额',
  Operator: '操作人员',
  Date: '时间',
  CategoryOfReserveChange: '准备金改变类型',
  CoinSurance: '共保公司',
  CoinLeader: '主联方',
  ShareRate: '共保比例',
  All: '全部',

  Remarks: '备注',
  Total: '总计',

  // calculateReserve
  ReserveCalculation: '准备金更新',
  CalculationResult: '准备金金额更新至',

  // MessageHelper
  OpenReserveSuccess: '开启准备金提交成功',
  CloseReserveSuccess: '关闭准备金提交成功',
  UpdateReserveSucess: '更新准备金提交成功',
  ReopenReserveSucess: '重开准备金提交成功',
  InputNegative: '请输入负数',
  InputPositive: '请输入正数',
  OpenReserveError: '请选择需要打开的准备金',

  // reserveApproval
  ReserveUpdateApproval: '准备金更新签核',
  ReserveClosureApproval: '准备金关闭签核',
};
