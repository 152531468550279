import { DataContext, CodeTable } from 'RainbowUI';
import CommonService from '../service/CommonService';
import urlConfig from '../url/Url';

export default {
  CodeTableIdfromCache(codeTableId) {
    let codetableList = null;
    if (DataContext.get(codeTableId)) {
      codetableList = DataContext.get(codeTableId);
    } else {
      codetableList = this.CodeTableId(codeTableId);
      DataContext.put(codeTableId, codetableList);
    }
    return new CodeTable(codetableList);
  },
  EndoTypeCodeTableIdfromCache(codeTableId) {
    let codetableList = [];
    if (DataContext.get(codeTableId)) {
      codetableList = DataContext.get(codeTableId);
    } else {
      const newCodetableList = this.CodeTableId(codeTableId);

      for (const codetable of newCodetableList) {
        if (
          codetable.id == 11 ||
          codetable.id == 21 ||
          codetable.id == 31 ||
          codetable.id == 51
        ) {
          codetableList.push(codetable);
        }
      }
      DataContext.put(codeTableId, codetableList);
    }
    return new CodeTable(codetableList);
  },
  CodeTableId(codeTableId) {
    const url = urlConfig.codetableList + codeTableId;
    const codetableList = [];
    CommonService.ajaxGet(null, url).then(data => {
      if (data) {
        for (let i = 0; i < data.length; i += 1) {
          codetableList.push({
            id: data[i].Id,
            text: data[i].Description,
          });
        }
      }
    });
    return codetableList;
  },
  CodeTableCodefromCache(codeTableId) {
    let codetableList = null;
    if (DataContext.get(codeTableId)) {
      codetableList = DataContext.get(codeTableId);
    } else {
      codetableList = this.CodeTableCode(codeTableId);
      DataContext.put(codeTableId, codetableList);
    }
    return new CodeTable(codetableList);
  },
  CodeTableCode(codeTableId) {
    const url = urlConfig.codetableList + codeTableId;
    const codetableList = [];
    CommonService.ajaxGet(null, url).then(data => {
      if (data) {
        for (let i = 0; i < data.length; i += 1) {
          codetableList.push({ id: data[i].Id, text: data[i].Code });
        }
      }
    });
    return codetableList;
  },
  CodeTableDistinctCodefromCache(codeTableId) {
    let codetableList = null;
    if (DataContext.get(codeTableId)) {
      codetableList = DataContext.get(codeTableId);
    } else {
      codetableList = this.CodeTableDistinctCode(codeTableId);
      DataContext.put(codeTableId, codetableList);
    }
    return new CodeTable(codetableList);
  },
  CodeTableDistinctCode(codeTableId) {
    const url = urlConfig.codetableList + codeTableId;
    const codetableList = [];
    const codetableObj = {};
    CommonService.ajaxGet(null, url).then(data => {
      if (data) {
        for (let i = 0; i < data.length; i += 1) {
          if (typeof codetableObj[data[i].Id] === 'undefined') {
            codetableList.push({
              id: data[i].Id,
              text: data[i].Code,
            });
            codetableObj[data[i].Id] = data[i].Code;
          }
        }
      }
    });
    return codetableList;
  },

  BankAccountCodeTablefromCache(accountType) {
    let bankAccountList = null;
    if (DataContext.get('BankAccountCodeTable')) {
      bankAccountList = DataContext.get('BankAccountCodeTable');
    } else {
      bankAccountList = this.BankAccountCodeTable();
      DataContext.put('BankAccountCodeTable', bankAccountList);
    }
    const codetableList = [];
    for (let i = 0; i < bankAccountList.length; i += 1) {
      const account = bankAccountList[i].BranchAccountRelationList;
      for (let j = 0; account && j < account.length; j += 1) {
        if (account[j].AccountType == accountType) {
          codetableList.push({
            id: bankAccountList[i].AccountId,
            text: bankAccountList[i].AccountName,
          });
          break;
        }
      }
    }
    return new CodeTable(codetableList);
  },
  BankAccountCodeTable() {
    const url = urlConfig.queryBranchAccountByStatus;
    let codetableList = [];
    CommonService.ajaxPost(null, url).then(data => {
      codetableList = data;
    });
    return codetableList;
  },
  AgentCodeCache(codeTableId) {
    let codetableList = null;
    if (DataContext.get(codeTableId)) {
      codetableList = DataContext.get(codeTableId);
    } else {
      codetableList = this.AgentCode(codeTableId);
      DataContext.put(codeTableId, codetableList);
    }
    return new CodeTable(codetableList);
  },
  AgentCode(codeTableId) {
    const url = urlConfig.codetableList + codeTableId;
    const codetableList = [];
    const currentUserCodetableList = [];
    const { agentCode } = DataContext.get('userMenuCodes');
    let agentCodeList = null;
    if (agentCode) {
      CommonService.ajaxGet(
        null,
        urlConfig.currentUserAllAgentCodes
      ).then(data => {
        agentCodeList = data;
      });
    }
    CommonService.ajaxGet(null, url).then(data => {
      if (data) {
        for (let i = 0; i < data.length; i += 1) {
          if (
            agentCodeList &&
            agentCodeList.indexOf(data[i].Code) > -1
          ) {
            currentUserCodetableList.push({
              id: data[i].Code,
              text: data[i].Description,
            });
          }
          codetableList.push({
            id: data[i].Code,
            text: data[i].Description,
          });
        }
      }
    });
    if (currentUserCodetableList.length > 0) {
      return currentUserCodetableList;
    }
    return codetableList;
  },
};
