import ReactDOM from 'react-dom';
import { UITree, UIInput } from 'RainbowUI';
import React from 'react';
import PropTypes from 'prop-types';

export default class UITreeSelect extends UIInput {
  constructor(props) {
    super(props);

    const initval = this.formatInitVal(this.getComponentValue());
    this.state = {
      open: false,
      values: initval,
      focusSelection: false,
      treedataSource: this.props.treedataSource,
    };
  }

  onDocumentClick(event) {
    if (this.state.open == false || this.props.io === 'out') return;
    const root = this.node;
    if (!root.contains(event.srcElement)) {
      this.state.open = false;
      this.state.focusSelection = false;
      if (this.state.open == false) {
        document.removeEventListener(
          'click',
          this.onDocumentClick.bind(this)
        );
      }
      this.forceUpdate();
    }
  }

  formatInitVal(val) {
    let formatInitVal = [];
    let needformat = true;
    let initVal = {};
    if (!val) return formatInitVal;
    if (
      Object.prototype.toString.call(this.getComponentValue()) ===
      '[object Object]'
    ) {
      const values = [];
      values.push(this.getComponentValue());
      formatInitVal = values;
      needformat = false;
    } else if (typeof val === 'string') {
      if (this.getComponentValue().length <= 4) {
        needformat = false;
      }
      const str2val = this.getComponentValue().substring(
        2,
        this.getComponentValue().length - 2
      );
      initVal = str2val.split('","');
    } else if (Array.isArray(val)) {
      initVal = this.getComponentValue();
    }
    if (needformat) {
      this.props.treedataSource.forEach((item, index) => {
        for (let i = 0; i < initVal.length; i += 1) {
          if (item.id == initVal[i]) {
            formatInitVal.push({ id: item.id, name: item.name });
            break;
          }
        }
      });
    }
    return formatInitVal;
  }

  getStyles() {
    const styles = {
      'ui-treeselect': {
        boxSizing: 'border-box',
        display: 'inline-block',
        position: 'relative',
        verticalAlign: 'middle',
        color: '#666',
        fontSize: '12px',
        width: '100%',
      },
      'ui-treeselect-selection-focus': {
        outline: 'none',
        userSelect: 'none',
        boxSizing: 'border-box',
        display: 'block',
        backgroundColor: '#fff',

        borderBottom: '2px solid #2196f3',
        minHeight: '28px',
      },
      'ui-treeselect-selection': {
        outline: 'none',
        userSelect: 'none',
        boxSizing: 'border-box',
        display: 'block',
        backgroundColor: '#fff',

        borderBottom: '1px solid #d9d9d9',
        minHeight: '28px',
      },
      'ui-treeselect-ul': {
        paddingLeft: 0,
        marginBottom: '4px',
        minHeight: '28px',
        width: '100%',
        overflow: 'auto',
      },
      'ui-treeselect-li': {
        listStyle: 'none',
        float: 'left',
        marginRight: '4px',
        maxWidth: '98%',
        position: 'relative',
        paddingTop: '4px',
        paddingRight: '20px',
        paddingLeft: '4px',
      },
      'ui-treeselect-li-input': {
        width: '100%',
        listStyle: 'none',
        float: 'left',
        marginRight: '4px',

        position: 'absolute',
      },
      'ui-treeselect-dropdown': {
        backgroundColor: '#fff',
        borderLeft: '1px solid #d9d9d9',
        borderRight: '1px solid #d9d9d9',
        boxShadow: '0 1px 6px hsla(0,0%,39%,.2)',
        borderRadius: '6px',
        boxSizing: 'border-box',
        outline: 'none',
        position: 'absolute',
        left: 0,
        top: 'auto',
        zIndex: '100',
        width: '100%',
      },
    };
    return styles;
  }

  handleTreeNodeChecked() {
    if (this.props.treeCheckable === 'false') return;

    const checkedValues = [];
    const allCheckedNode = UITree.getCheckedNode(
      'selectTreeNode',
      'tree'
    );
    let i = 0;
    while (i < allCheckedNode.length) {
      const p = allCheckedNode[i];
      if (p.check_Child_State == '2') {
        checkedValues.push({ id: p.id, name: p.name });

        let nextNode = allCheckedNode[i + 1];

        while (nextNode && nextNode.level == p.level * 1 + 1) {
          i += 1;
          nextNode = allCheckedNode[i];
        }
      } else if (p.check_Child_State == '1') {
        i += 1;
      } else if (p.check_Child_State === '-1') {
        let jj = p.level;
        if (jj === 0) {
          checkedValues.push({ id: p.id, name: p.name });
        } else {
          let pp = p;
          for (jj; jj > 0; jj -= 1) {
            pp = pp.getParentNode();
            if (pp.check_Child_State != '2') {
              checkedValues.push({ id: p.id, name: p.name });
              break;
            }
          }
        }

        i += 1;
      } else {
        i += 1;
      }
    }

    this.state.values = checkedValues;
    this.setComponentValue();

    if (this.props.afterChecked) {
      this.props.afterChecked.call(null, checkedValues);
    }

    setTimeout(this.forceUpdate.bind(this), 0);
  }

  handleTreeNodeClick(event, treeId, treeNode) {
    if (this.props.treeCheckable === 'true') return;

    const curNode = { id: treeNode.id, name: treeNode.name };
    this.state.values.pop();
    this.state.values.push(curNode);

    this.setComponentValue();
    this.forceUpdate();
  }

  renderTree() {
    const checkfunc = (tmpitem, index) => {
      const item = tmpitem;
      if (this.state.values.length === 0) item.checked = false;
      for (let i = 0; i < this.state.values.length; i += 1) {
        item.checked = false;
        if (item.id == this.state.values[i].id) {
          item.checked = true;
          break;
        } else if (
          item.pId !== 0 &&
          item.pId == this.state.values[i].id
        ) {
          item.checked = true;
          break;
        }
      }
    };
    this.state.treedataSource.map(checkfunc);

    return (
      <UITree
        id="selectTreeNode"
        dataSource={this.state.treedataSource}
        checkable={this.props.treeCheckable}
        editable="false"
        removeable="false"
        onClick={this.handleTreeNodeClick.bind(this)}
        renameable="false"
        moveable="false"
        onCheck={this.handleTreeNodeChecked.bind(this)}
      />
    );
  }

  showDropdown() {
    if (this.props.io === 'out') return;

    this.state.open = !this.state.open;
    this.state.focusSelection = !this.state.focusSelection;
    if (this.state.open == true) {
      document.addEventListener(
        'click',
        this.onDocumentClick.bind(this)
      );
    } else {
      document.removeEventListener(
        'click',
        this.onDocumentClick.bind(this)
      );
    }
    const timer = () => {
      this.forceUpdate();
    };
    setTimeout(timer.bind(this), 300);
  }

  setComponentValue(event) {
    let value;
    if (this.state.values.length === 0) {
      value = null;
    } else {
      value = this.state.values.map(item => {
        return item.id;
      });
    }

    if (this.props.valueLink) {
      this.props.valueLink.requestChange(value);
    } else {
      this.setValue(this.props.value, value);
    }

    $(`#${this.componentId}`).val(value);
  }

  renderTip() {
    const _self = this;
    const styles = this.getStyles();
    const { values } = this.state;

    const removeItem = (item, index) => {
      if (this.props.io === 'out') return;
      this.state.values = values.filter(i => {
        return item !== i;
      });

      this.setComponentValue();
      if (this.state.values.length === 0 && this.state.open) {
        this.state.open = !this.state.open;
        this.state.focusSelection = !this.state.focusSelection;
      }
      this.forceUpdate();
    };

    if (values.length < 1) {
      return (
        <ul style={styles['ui-treeselect-ul']}>
          <li style={styles['ui-treeselect-li-input']}>
            <span
              style={{
                position: 'relative',
                top: '5px',
                color: '#333',
                fontSize: '1.2em',
              }}
            >
              {this.props.BlankOption}
            </span>
          </li>
        </ul>
      );
    }
    return (
      <ul style={styles['ui-treeselect-ul']}>
        {values.map((item, index) => {
          return (
            <li
              unselectable="unselectable"
              style={styles['ui-treeselect-li']}
              title={item.name}
            >
              {}
              <span>{item.name}</span>
              <span
                className={
                  _self.props.io === 'out' ? 'fa' : 'fa fa-remove'
                }
                style={{ paddingLeft: '4px', cursor: 'pointer' }}
                onClick={removeItem.bind(_self, item, index)}
              />
            </li>
          );
        })}

        <li style={styles['ui-treeselect-li-input']}>
          <span>
            {/** <input style = {styles['ant-select-search-field']} role="textbox"  id={this.componentId} ref={this.componentId + "_ref"}/>* */}
          </span>
        </li>
      </ul>
    );
  }

  renderIcon() {
    return (
      <span
        style={{
          position: 'absolute',
          right: '8px',
          top: '50%',
          transform: 'translate(0,-50%)',
          cursor: 'pointer',
        }}
        className={
          this.state.open ? 'fa fa-chevron-up' : 'fa fa-chevron-down'
        }
        onMouseDown={this.showDropdown.bind(this)}
      />
    );
  }

  renderOutput() {
    return (
      <span className="outPutText" style={this.props.style}>
        {this.renderTip()}
      </span>
    );
  }

  renderInput() {
    const styles = this.getStyles();
    let popupElement;
    if (this.state.open) {
      popupElement = <div>{this.renderTree()}</div>;
    } else {
      popupElement = <div />;
    }

    return (
      <span role="textbox" style={styles['ui-treeselect']}>
        <input
          id={this.componentId}
          ref={`${this.componentId}_ref`}
          style={{ height: '0px', width: '1px' }}
          name={this.getName()}
          className="form-control"
        />
        <span
          style={
            this.state.focusSelection
              ? styles['ui-treeselect-selection-focus']
              : styles['ui-treeselect-selection']
          }
          role="combobox"
        >
          {this.renderTip()}
          {this.renderIcon()}
        </span>
        <span style={styles['ui-treeselect-dropdown']}>
          {popupElement}
        </span>
      </span>
    );
  }

  getSelfElement() {
    return $(`#${this.componentId}`);
  }

  componentDidMount() {
    super._componentDidMount();
    if (this.props.io !== 'out') {
      this.initEvent();
      this.initProperty();
      this.initValidator();
    }
  }
}

UITreeSelect.propTypes = $.extend({}, UIInput.propTypes, {
  treeCheckable: PropTypes.oneOfType(['true', 'false']),
  treedataSource: PropTypes.array,
  afterChecked: PropTypes.func,
});

UITreeSelect.defaultProps = $.extend({}, UIInput.defaultProps, {
  treeCheckable: 'true',
});
