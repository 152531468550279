const UiCacheConstants = {
  COMM_CONNECTOR: '_',
  DD_SCHEMA_DATA: '1',
  CODE_TABLE_DATA: '2',
  BIG_CODE_TABLE: '3',
  UI_CACHE_VERSION: 'Version',
  POLICY_MODEL_NAME: 'Policy',
  POLICY_OBJECT_CODE: 'POLICY',
  MASTER_POLICY_OBJECT_CODE: 'MASTERPOLICY',
  GROUP_POLICY_OBJECT_CODE: 'GroupPolicy',
  GROUP_POLICY_CERTIFICATE_OBJECT_CODE: 'CERTIFICATE',
  MASTER_CERTIFICATE_OBJECT_CODE: 'CERTIFICATE',
  IS_DIRTY_FLG: 'IsDirty',
  DIRTY_OBJECT: 'DirtyObject',
  PRODUCT_CONTEXT_TYPE: -2,
  COMMON_CONTEXT_TYPE: -1,
  COMMON_CONTEXT_REFERENCE_ID: -1,
  VERSION_FOR_CLIENT: 'versionForClient',
};

export default UiCacheConstants;
