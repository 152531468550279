import { UIDialog, UIButton, UIBox } from 'RainbowUI';
import BindToMixin from 'react-binding';

const AppraisalVehicleInfo = require('./AppraisalVehicleInfo');
const AppraisalLaborCost = require('./AppraisalLaborCost');
const AppraisalSparePartCost = require('./AppraisalSparePartCost');
const ApprovalHistory = require('./ApprovalHistory');
const AppraisalTotalCost = require('./AppraisalTotalCost');

const AppraisalReadOnlyDialog = React.createClass({
  mixins: [BindToMixin],

  getInitialState() {
    return {
      AppraisalInfo: null,
      appraisalVehicle: null,
      AppraisalHistory: null,
      ApprovalHistoryVO: null,
      CurrencyCode: null,
    };
  },

  componentWillMount() {},
  componentWillUnmount() {},

  onStoreStateChange() {},

  showDialog(data) {
    console.log('[showDialog]', data);

    this.setState({ AppraisalInfo: data.AppraisalInfo });
    this.setState({
      appraisalVehicle: data.AppraisalInfo.appraisalVehicle,
    });
    this.setState({ ApprovalHistoryVO: data.ApprovalHistoryVO });
    this.setState({ CurrencyCode: data.CurrencyCode });
    UIDialog.show('AppraisalReadOnlyDialog');
  },

  render() {
    const { AppraisalInfo, ApprovalHistoryVO } = this.state;
    if (!AppraisalInfo) {
      console.log('===isNull');
      return (
        <UIDialog
          id="AppraisalReadOnlyDialog"
          title={i18n.ClaimAppraisal.AppraisalHistoryDetail}
          width="90%"
          height="1000px"
          modal="false"
        />
      );
    }
    const claimAppraisalVehicle = this.bindToState(
      'AppraisalInfo',
      'appraisalVehicle'
    );
    return (
      <UIDialog
        id="AppraisalReadOnlyDialog"
        title={i18n.ClaimAppraisal.AppraisalHistoryDetail}
        width="90%"
        height="1000px"
        modal="false"
      >
        <AppraisalVehicleInfo
          history
          readOnly
          parentComponent={this}
          appraVehicleInfo={claimAppraisalVehicle}
        />
        <AppraisalLaborCost
          history
          readOnly
          parentComponent={this}
          appraisalVehicle={claimAppraisalVehicle}
        />
        <AppraisalSparePartCost
          history
          readOnly
          parentComponent={this}
          appraisalVehicle={claimAppraisalVehicle}
        />
        <AppraisalTotalCost
          history
          readOnly
          parentComponent={this}
          appraisalVehicle={claimAppraisalVehicle}
        />
        <ApprovalHistory
          history
          readOnly
          parentComponent={this}
          appraisalApproveHistory={ApprovalHistoryVO}
        />

        <UIBox direction="center">
          <UIButton
            value={i18n.ClaimAppraisal.Back}
            position="right"
            onClick={this.onClose}
          />
        </UIBox>
      </UIDialog>
    );
  },

  onClose() {
    UIDialog.hide('AppraisalReadOnlyDialog');
  },
});
export default AppraisalReadOnlyDialog;
