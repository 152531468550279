import { CodeTable, MessageHelper, Caller } from 'RainbowUI';
import ClaimCommonExt from 'ClaimCommonExt';
import { BodilyInjuryService } from '../service/BodilyInjuryService';
import InvestigationService from '../service/InvestigationService';

const TriggerTaskTransformDialogAction = ClaimCommonExt.getReserveAction(); //used for trigger taskTransform Dialog

class BodilyInjuryAction {
  synButtonIsEnable(taskInfo, damageType = 'I') {
    const enabled = InvestigationService.synButtonIsEnable(
      taskInfo,
      damageType
    );
    return enabled;
  }

  synModel(subClaimId) {
    return InvestigationService.synData(subClaimId);
  }

  save(entity, callback) {
    console.log('Injury save start!');
    const promise = BodilyInjuryService.vehicleSave(entity);
    promise.then(
      function (data) {
        if (data.Status === 'OK') {
          callback(data);
          MessageHelper.success(
            i18n.ClaimFieldInvestigation.SaveSuccess
          );
        }
      },
      function (reason) {
        MessageHelper.error('', i18n.ClaimFieldInvestigation.Failed);
        console.log('failed reason', reason);
      }
    );
  }

  submit(entity, callback) {
    console.log('BodilyInjury submit start!');
    const promise = BodilyInjuryService.bodilyInjurySubmit(entity);
    promise.then(
      function (data) {
        if (data.Status === 'OK') {
          MessageHelper.success(
            i18n.ClaimFieldInvestigation.SubmitSuccess
          );
          TriggerTaskTransformDialogAction.reserveApprovalTaskAssigned(
            'TaskComplete'
          );
        }
      },
      function (reason) {
        MessageHelper.error('', i18n.ClaimFieldInvestigation.Failed);
        console.log('failed reason', reason);
      }
    );
  }

  initData(subCliamId, fiStatus) {
    return InvestigationService.initData(subCliamId, fiStatus);
  }
}

const BodilyInjuryActionInstance = new BodilyInjuryAction();
export default BodilyInjuryActionInstance;
