import { AjaxUtil } from 'RainbowUI';
import Url from '../url/Url';

export default {
  loadApproveInfo(taskInstanceId) {
    const defer = $.Deferred();
    AjaxUtil.doGet(Url.SettlementApproveLoad + taskInstanceId, null, {
      async: false,
      done(data, jqXHR) {
        if (data) {
          defer.resolve(data.Model);
        } else {
          defer.reject({
            status: jqXHR.status,
            responseText: jqXHR.responseText,
          });
        }
      },
      fail(jqXHR) {
        defer.reject({
          status: jqXHR.status,
          responseText: jqXHR.responseText,
        });
      },
    });
    return defer.promise();
  },
  cancel(cancelInfo) {
    const defer = $.Deferred();
    AjaxUtil.doPost(Url.SettlementCancel, cancelInfo, {
      async: false,
      done(data, jqXHR) {
        if (data) {
          defer.resolve(data);
        } else {
          defer.reject({
            status: jqXHR.status,
            responseText: jqXHR.responseText,
          });
        }
      },
      fail(jqXHR) {
        // jqXHR.status, jqXHR.responseText
        defer.reject({
          status: jqXHR.status,
          responseText: jqXHR.responseText,
        });
      },
    });
    return defer.promise();
  },
  approve(approveInfo) {
    const defer = $.Deferred();
    AjaxUtil.doPost(Url.SettlementApprove, approveInfo, {
      async: false,
      done(data, jqXHR) {
        if (data) {
          defer.resolve(data);
        } else {
          defer.reject({
            status: jqXHR.status,
            responseText: jqXHR.responseText,
          });
        }
      },
      fail(jqXHR) {
        defer.reject({
          status: jqXHR.status,
          responseText: jqXHR.responseText,
        });
      },
    });
    return defer.promise();
  },
};
