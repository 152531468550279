import { DataContext, UpdateContext } from 'RainbowUI';
import CommonService from '../../service/CommonService';
import urlConfig from '../../../../UrlConfig';
import CommonCodeTable from '../../codetable/CodeTable';
import EU00CommonMath from '../../service/EU00CommonMath';

const UiCacheConstants = require('../../util/UiCacheConstants');

export default class FeeDetailDialogAction {
  onClickedFeeDetail(event) {
    const feeDetailDialogAction = new FeeDetailDialogAction();
    const installmentPlanInfo = $.extend(
      {},
      event.getParameter('data')
    );
    feeDetailDialogAction.initGroupInstallmentDetailInfo(
      installmentPlanInfo
    );
    feeDetailDialogAction.extendWindowMathFn();
    DataContext.put('installmentPlanInfo', installmentPlanInfo);
    this.refs.feeDetailDialog.showDialog(installmentPlanInfo);
  }

  getProvider(pageIndex, pageSize) {
    const feeDetailDialogAction = new FeeDetailDialogAction();
    const installmentPlanInfo = DataContext.get(
      'installmentPlanInfo'
    );
    console.log(
      `installmentInfo: ${JSON.stringify(installmentPlanInfo)}`
    );
    let premiumDetail = null;
    if (installmentPlanInfo) {
      // If EndoID exists, the record is about endorsement.
      if (installmentPlanInfo.EndoId) {
        const endoId = installmentPlanInfo.EndoId;
        premiumDetail = feeDetailDialogAction.getPremiumDetailForEndo(
          endoId
        );
      } else {
        const policyId = installmentPlanInfo.PolicyId;
        premiumDetail = feeDetailDialogAction.getPremiumDetailForNewbiz(
          policyId
        );
      }
    }
    const result = [];
    // Add all the info to result
    const { detailList } = installmentPlanInfo;
    const total = {
      InstallmentNo: 'Total',
      PremiumDue: 0,
      ActualPremium: 0,
      Fee: 0,
      Tax: 0,
      StampDuty: 0,
      CurrencySign: window.EU00.getCurrencySignByCurrencyCode(
        detailList[0].CurrencyCode
      ),
    };
    const totalExcludeFirst = {
      ActualPremium: 0,
      Fee: 0,
      Tax: 0,
      StampDuty: 0,
    };
    let revision = null;
    if (installmentPlanInfo.EndoId) {
      const url4LoadFirstEffectiveRevision = `${urlConfig.getFirstEffectiveRevision}?policyId=${installmentPlanInfo.PolicyId}`;
      new CommonService.ajaxGet(
        null,
        url4LoadFirstEffectiveRevision
      ).then(data => {
        revision = data;
      });
      const url4LoadRevision = `${urlConfig.loadIssuedNewbiz}?policyId=${installmentPlanInfo.PolicyId}&revisionId=${revision.Id}`;
      new CommonService.ajaxGet(null, url4LoadRevision).then(data => {
        newbizPremiumDue = data.PremiumDue;
      });
    }
    for (let i = 0; i < detailList.length; i += 1) {
      // Status: Outstanding: 1, Settled: 2
      if (detailList[i].IsAR) {
        const item = {
          InstallmentNo: detailList[i].InstallmentNo,
          PremiumDue: detailList[i].Amount,
          ActualPremium: null,
          Fee: Math.accDiv(premiumDetail.Fee, detailList.length),
          Tax: Math.accDiv(premiumDetail.Tax, detailList.length),
          StampDuty: Math.accDiv(
            premiumDetail.StampDuty,
            detailList.length
          ),
          CurrencySign: window.EU00.getCurrencySignByCurrencyCode(
            detailList[i].CurrencyCode
          ),
        };
        item.ActualPremium = Math.accSub(
          item.PremiumDue,
          Math.accAdd(Math.accAdd(item.Fee, item.Tax), item.StampDuty)
        );
        result.push(item);
        // Add all the amount to total
        total.PremiumDue = Math.accAdd(
          total.PremiumDue,
          item.PremiumDue
        );
        total.ActualPremium = Math.accAdd(
          total.ActualPremium,
          item.ActualPremium
        );
        total.Fee = Math.accAdd(total.Fee, item.Fee);
        total.Tax = Math.accAdd(total.Tax, item.Tax);
        total.StampDuty = Math.accAdd(
          total.StampDuty,
          item.StampDuty
        );
        if (i > 0) {
          totalExcludeFirst.ActualPremium = Math.accAdd(
            totalExcludeFirst.ActualPremium,
            item.ActualPremium
          );
          totalExcludeFirst.Fee = Math.accAdd(
            totalExcludeFirst.Fee,
            item.Fee
          );
          totalExcludeFirst.Tax = Math.accAdd(
            totalExcludeFirst.Tax,
            item.Tax
          );
          totalExcludeFirst.StampDuty = Math.accAdd(
            totalExcludeFirst.StampDuty,
            item.StampDuty
          );
        }
      } else {
        const item = {
          InstallmentNo: detailList[i].InstallmentNo,
          PremiumDue: 0 - detailList[i].Amount,
          ActualPremium: null,
          Fee: Math.accDiv(premiumDetail.Fee, detailList.length),
          Tax: Math.accDiv(premiumDetail.Tax, detailList.length),
          StampDuty: Math.accDiv(
            premiumDetail.StampDuty,
            detailList.length
          ),
          CurrencySign: window.EU00.getCurrencySignByCurrencyCode(
            detailList[i].CurrencyCode
          ),
        };
        item.ActualPremium = Math.accSub(
          item.PremiumDue,
          Math.accAdd(Math.accAdd(item.Fee, item.Tax), item.StampDuty)
        );
        result.push(item);
        // Add all the amount to total
        total.PremiumDue = Math.accAdd(
          total.PremiumDue,
          item.PremiumDue
        );
        total.ActualPremium = Math.accAdd(
          total.ActualPremium,
          item.ActualPremium
        );
        total.Fee = Math.accAdd(total.Fee, item.Fee);
        total.Tax = Math.accAdd(total.Tax, item.Tax);
        total.StampDuty = Math.accAdd(
          total.StampDuty,
          item.StampDuty
        );
        if (i > 0) {
          totalExcludeFirst.ActualPremium = Math.accAdd(
            totalExcludeFirst.ActualPremium,
            item.ActualPremium
          );
          totalExcludeFirst.Fee = Math.accAdd(
            totalExcludeFirst.Fee,
            item.Fee
          );
          totalExcludeFirst.Tax = Math.accAdd(
            totalExcludeFirst.Tax,
            item.Tax
          );
          totalExcludeFirst.StampDuty = Math.accAdd(
            totalExcludeFirst.StampDuty,
            item.StampDuty
          );
        }
      }
    }
    total.Fee = premiumDetail.Fee;
    total.Tax = premiumDetail.Tax;
    total.StampDuty = premiumDetail.StampDuty;
    total.ActualPremium = Math.accSub(
      Math.accSub(
        Math.accSub(total.PremiumDue, total.Fee),
        total.Tax
      ),
      total.StampDuty
    );
    const handledResult = [];
    if (result && result.length > 0) {
      for (let j = 0; j < result.length; j += 1) {
        let itemNew = result[j];
        if (j === 0) {
          itemNew = {
            InstallmentNo: itemNew.InstallmentNo,
            PremiumDue: itemNew.PremiumDue,
            ActualPremium: Math.accSub(
              total.ActualPremium,
              totalExcludeFirst.ActualPremium
            ),
            Fee: Math.accSub(total.Fee, totalExcludeFirst.Fee),
            Tax: Math.accSub(total.Tax, totalExcludeFirst.Tax),
            StampDuty: Math.accSub(
              total.StampDuty,
              totalExcludeFirst.StampDuty
            ),
            CurrencySign: itemNew.CurrencySign,
          };
        }
        handledResult.push(itemNew);
      }
    }
    handledResult.push(total);
    //		result.push(total);
    return { count: 0, result: handledResult };
  }

  getProviderForNewbizInCancellation(pageIndex, pageSize) {
    const feeDetailDialogAction = new FeeDetailDialogAction();
    const installmentPlanInfo = DataContext.get(
      'installmentPlanInfo'
    );
    let policyId = null;
    let premiumDetail = null;
    if (installmentPlanInfo) {
      policyId = installmentPlanInfo.PolicyId;
      premiumDetail = feeDetailDialogAction.getPremiumDetailForNewbiz(
        policyId
      );
    }
    const result = [];
    // Add all the info to result
    let feeList = null;
    const url = `${urlConfig.queryPolicyFeeListByPolicyIdIncludeCancel}?policyId=${policyId}`;
    new CommonService.ajaxGet(null, url).then(data => {
      feeList = data;
    });

    let currencyCode = 'EUR';
    if (!feeList || feeList.length <= 0) {
      feeList = [];
    } else {
      currencyCode = feeList[0].CurrencyCode;
    }

    const total = {
      InstallmentNo: 'Total',
      PremiumDue: 0,
      ActualPremium: 0,
      Fee: 0,
      Tax: 0,
      StampDuty: 0,
      LocalCurrencySign: window.EU00.getCurrencySignByCurrencyCode(
        currencyCode
      ),
    };
    const totalExcludeFirst = {
      ActualPremium: 0,
      Fee: 0,
      Tax: 0,
      StampDuty: 0,
    };

    let index = 0;
    let isHaveSettled = false;
    for (let i = 0; i < feeList.length; i += 1) {
      // Status: Outstanding: 1, Settled: 2
      // If a cancelled fee was settled, it could be recognized by balance.
      if (feeList[i].Status !== 2 && feeList[i].Balance !== 0) {
        const item = {
          InstallmentNo: feeList[i].InstallmentNo,
          PremiumDue: feeList[i].Amount,
          ActualPremium: null,
          Fee: Math.accDiv(premiumDetail.Fee, feeList.length),
          Tax: Math.accDiv(premiumDetail.Tax, feeList.length),
          StampDuty: Math.accDiv(
            premiumDetail.StampDuty,
            feeList.length
          ),
          LocalCurrencySign: window.EU00.getCurrencySignByCurrencyCode(
            feeList[i].CurrencyCode
          ),
          Title: feeList[0].EndoNo,
        };
        item.ActualPremium = Math.accSub(
          item.PremiumDue,
          Math.accAdd(Math.accAdd(item.Fee, item.Tax), item.StampDuty)
        );
        result.push(item);
        // Add all the amount to total
        total.PremiumDue = Math.accAdd(
          total.PremiumDue,
          item.PremiumDue
        );
        total.ActualPremium = Math.accAdd(
          total.ActualPremium,
          item.ActualPremium
        );
        total.Fee = Math.accAdd(total.Fee, item.Fee);
        total.Tax = Math.accAdd(total.Tax, item.Tax);
        total.StampDuty = Math.accAdd(
          total.StampDuty,
          item.StampDuty
        );
        if (index > 0) {
          totalExcludeFirst.ActualPremium = Math.accAdd(
            totalExcludeFirst.ActualPremium,
            item.ActualPremium
          );
          totalExcludeFirst.Fee = Math.accAdd(
            totalExcludeFirst.Fee,
            item.Fee
          );
          totalExcludeFirst.Tax = Math.accAdd(
            totalExcludeFirst.Tax,
            item.Tax
          );
          totalExcludeFirst.StampDuty = Math.accAdd(
            totalExcludeFirst.StampDuty,
            item.StampDuty
          );
        }
        index += 1;
      } else {
        isHaveSettled = true;
      }
    }
    if (!isHaveSettled) {
      total.Fee = premiumDetail.Fee;
      total.Tax = premiumDetail.Tax;
      total.StampDuty = premiumDetail.StampDuty;
      total.ActualPremium = Math.accSub(
        Math.accSub(
          Math.accSub(total.PremiumDue, total.Fee),
          total.Tax
        ),
        total.StampDuty
      );
    }
    const handledResult = [];
    if (result && result.length > 0) {
      for (let j = 0; j < result.length; j += 1) {
        let itemNew = result[j];
        if (j === 0) {
          itemNew = {
            InstallmentNo: itemNew.InstallmentNo,
            PremiumDue: itemNew.PremiumDue,
            ActualPremium: Math.accSub(
              total.ActualPremium,
              totalExcludeFirst.ActualPremium
            ),
            Fee: Math.accSub(total.Fee, totalExcludeFirst.Fee),
            Tax: Math.accSub(total.Tax, totalExcludeFirst.Tax),
            StampDuty: Math.accSub(
              total.StampDuty,
              totalExcludeFirst.StampDuty
            ),
            CurrencySign: itemNew.CurrencySign,
            LocalCurrencySign: itemNew.LocalCurrencySign,
          };
        }
        handledResult.push(itemNew);
      }
      handledResult.push(total);
    }
    //		result.push(total);
    return { count: 0, result: handledResult };
  }

  getProviderForEndoInCancellation(pageIndex, pageSize, endoId) {
    const feeDetailDialogAction = new FeeDetailDialogAction();
    let premiumDetail = null;
    let policyFeeList = null;
    if (endoId != null && endoId !== '') {
      premiumDetail = feeDetailDialogAction.getPremiumDetailForEndo(
        endoId
      );
    } else {
      return null;
    }
    const result = [];
    // Add all the info to result
    const url4GetEndoPolicyFeeInfo = `${urlConfig.getEndoPolicyFeeInfo}?endoId=${endoId}`;
    const policyFeeSplitList = null;
    new CommonService.ajaxGet(null, url4GetEndoPolicyFeeInfo).then(
      data => {
        policyFeeList = data.PolicyFeeList;
      }
    );
    const item = {
      InstallmentNo: '',
      PremiumDue: 0,
      ActualPremium: 0,
      Fee: 0,
      Tax: 0,
      StampDuty: 0,
      LocalCurrencySign: '',
    };
    for (let i = 0; i < policyFeeList.length; i += 1) {
      if (i.FeeType === '100101') {
        item.PremiumDue = policyFeeList[i].FeeAmount;
        item.Fee = premiumDetail.Fee;
        item.Tax = premiumDetail.Tax;
        item.StampDuty = premiumDetail.StampDuty;
        item.ActualPremium = Math.accSub(
          item.PremiumDue,
          Math.accAdd(Math.accAdd(item.Fee, item.Tax), item.StampDuty)
        );
        item.LocalCurrencySign = window.EU00.getCurrencySignByCurrencyCode(
          policyFeeSplitList[i].CurrencyCode
        );
      }
      result.push(item);
    }
    const total = $.extend({}, item);
    total.InstallmentNo = 'Total';
    result.push(total);
    return { count: 0, result };
  }

  getProviderForEndoCancellation() {
    const feeDetailDialogAction = new FeeDetailDialogAction();
    const installmentPlanInfo = DataContext.get(
      'installmentPlanInfo'
    );
    let premiumDetail = null;
    if (installmentPlanInfo) {
      if (installmentPlanInfo.EndoType === 21) {
        const endoId = installmentPlanInfo.EndoId;
        premiumDetail = feeDetailDialogAction.getPremiumDetailForEndo(
          endoId
        );
      }
    }
    const result = [];
    // Add all the info to result
    let cancellation = null;
    const url4LoadEndorsement =
      urlConfig.loadEndorsement + installmentPlanInfo.EndoId;
    new CommonService.ajaxGet(null, url4LoadEndorsement).then(
      data => {
        cancellation = data;
      }
    );
    const total = {
      InstallmentNo: 'Total',
      PremiumDue: 0,
      ActualPremium: 0,
      Fee: 0,
      Tax: 0,
      StampDuty: 0,
      LocalCurrencySign: window.EU00.getCurrencySignByCurrencyCode(
        cancellation.Policy.PremiumCurrencyCode
      ),
      Title: installmentPlanInfo.EndoNo,
    };
    const item = {
      InstallmentNo: '',
      PremiumDue: cancellation.EndorsementPremiumDue,
      ActualPremium: null,
      Fee: premiumDetail.Fee,
      Tax: premiumDetail.Tax,
      StampDuty: premiumDetail.StampDuty,
      LocalCurrencySign: window.EU00.getCurrencySignByCurrencyCode(
        cancellation.Policy.PremiumCurrencyCode
      ),
      Title: installmentPlanInfo.EndoNo,
    };
    item.ActualPremium = Math.accSub(
      item.PremiumDue,
      Math.accAdd(Math.accAdd(item.Fee, item.Tax), item.StampDuty)
    );
    result.push(item);
    // Add all the amount to total
    total.PremiumDue = Math.accAdd(total.PremiumDue, item.PremiumDue);
    total.ActualPremium = Math.accAdd(
      total.ActualPremium,
      item.ActualPremium
    );
    total.Fee = Math.accAdd(total.Fee, item.Fee);
    total.Tax = Math.accAdd(total.Tax, item.Tax);
    total.StampDuty = Math.accAdd(total.StampDuty, item.StampDuty);

    result.push(total);
    return { count: 0, result };
  }

  getPremiumDetailForNewbiz(policyId) {
    const feeDetailDialogAction = new FeeDetailDialogAction();
    // Load Policy
    let policy = null;
    let revision = null;
    // Load First Revision
    const url4LoadFirstEffectiveRevision = `${urlConfig.getFirstEffectiveRevision}?policyId=${policyId}`;
    new CommonService.ajaxGet(
      null,
      url4LoadFirstEffectiveRevision
    ).then(data => {
      revision = data;
    });
    // Loading Policy
    const url4LoadIssuedNewbiz = `${urlConfig.loadIssuedNewbiz}?policyId=${policyId}&revisionId=${revision.Id}`;
    new CommonService.ajaxGet(null, url4LoadIssuedNewbiz).then(
      data => {
        policy = data;
      }
    );
    // Get Premium Detail For Newbiz
    return feeDetailDialogAction.getPremiumDetail(policy);
  }

  getPremiumDetailForEndo(endoId, endoType) {
    const feeDetailDialogAction = new FeeDetailDialogAction();
    // Load Policy
    let policy = null;
    // Loading Policy In Endorsement
    const url4LoadPolicyInEndorsement =
      urlConfig.loadEndorsement + endoId;
    let endorsement = null;
    new CommonService.ajaxGet(null, url4LoadPolicyInEndorsement).then(
      data => {
        policy = data.Policy;
        endorsement = data;
      }
    );

    // for cancel fee detail no need to show undo endorsement fee
    if (endoType && endorsement && endorsement.StatusId === 400) {
      return null;
    }
    // Get Premium Detail For Endorsement
    return feeDetailDialogAction.getPremiumDetailByEndo(
      policy,
      endoId
    );
  }

  getPremiumDetailByEndo(temppolicy, endoId) {
    const policy = temppolicy;
    delete policy[UiCacheConstants.IS_DIRTY_FLG];
    delete policy[UiCacheConstants.DIRTY_OBJECT];
    delete policy[UiCacheConstants.VERSION_FOR_CLIENT];
    let premiumDetail = null;
    const policyScheduleList = policy.PolicyScheduleList;
    if (policyScheduleList && policyScheduleList.length > 0) {
      let taxAmount = 0;
      let feeAmount = 0;
      let stampDutyAmount = 0;
      for (let i = 0; i < policyScheduleList.length; i += 1) {
        const item = policyScheduleList[i];
        if (item.TaxType) {
          if (item.EndoId == endoId) {
            if (item.TaxType === 1) {
              taxAmount = Math.accAdd(taxAmount, item.FinalAmount);
            } else if (item.TaxType === 2 || item.TaxType === 4) {
              feeAmount = Math.accAdd(feeAmount, item.FinalAmount);
            } else if (item.TaxType === 3) {
              stampDutyAmount = Math.accAdd(
                stampDutyAmount,
                item.FinalAmount
              );
            }
          }
        }
      }
      premiumDetail = {
        Tax: taxAmount,
        Fee: feeAmount,
        StampDuty: stampDutyAmount,
      };
    } else {
      premiumDetail = {
        Tax: 0.0,
        Fee: 0.0,
        StampDuty: 0.0,
      };
    }
    return premiumDetail;
  }

  getPremiumDetail(temppolicy) {
    // Loading Premium Detail
    const policy = temppolicy;
    const url4GetPremiumDetails = urlConfig.ap00GeneratePrmDtls;
    delete policy[UiCacheConstants.IS_DIRTY_FLG];
    delete policy[UiCacheConstants.DIRTY_OBJECT];
    delete policy[UiCacheConstants.VERSION_FOR_CLIENT];
    let premiumDetail = null;
    new CommonService.ajaxPost(policy, url4GetPremiumDetails).then(
      data => {
        let taxAmount = 0;
        let feeAmount = 0;
        let stampDutyAmount = 0;
        // FeeType: Tax:1, Fee:2, StampDuty:3, Additional Fee:4
        for (const i in data.TaxPrmDtl) {
          if (data.TaxPrmDtl[i].Type === 1) {
            taxAmount = Math.accAdd(
              taxAmount,
              Math.accAdd(
                data.TaxPrmDtl[i].Amount,
                data.TaxPrmDtl[i].AdjustedValue
              )
            );
          } else if (
            data.TaxPrmDtl[i].Type === 2 ||
            data.TaxPrmDtl[i].Type === 4
          ) {
            feeAmount = Math.accAdd(
              feeAmount,
              Math.accAdd(
                data.TaxPrmDtl[i].Amount,
                data.TaxPrmDtl[i].AdjustedValue
              )
            );
          } else if (data.TaxPrmDtl[i].Type === 3) {
            stampDutyAmount = Math.accAdd(
              stampDutyAmount,
              Math.accAdd(
                data.TaxPrmDtl[i].Amount,
                data.TaxPrmDtl[i].AdjustedValue
              )
            );
          }
        }
        premiumDetail = {
          Tax: taxAmount.toFixed(2),
          Fee: feeAmount.toFixed(2),
          StampDuty: stampDutyAmount.toFixed(2),
        };
      }
    );

    if (!premiumDetail) {
      premiumDetail = {
        Tax: 0.0,
        Fee: 0.0,
        StampDuty: 0.0,
      };
    }
    return premiumDetail;
  }

  initGroupInstallmentDetailInfo(tempgroupdata) {
    const groupdata = tempgroupdata;
    let results = [];
    const para = {
      policyId: groupdata.PolicyId,
      debitCreditNo: groupdata.DebitCreditNo,
      arapType: groupdata.ArapType,
      paymentType: groupdata.PaymentType,
      endoId: groupdata.EndoId,
      claimId: groupdata.ClaimId,
      showDDDCRecord: true,
    };
    const url = urlConfig.arapSearchListByGroupInfo;
    new CommonService.ajaxGet(para, url).then(data => {
      results = data;
    });
    groupdata.detailList = results;
    groupdata.AppliedAmount = 0;
  }

  getEndoIdListByPolicyId(policyId) {
    // Endorsement Cancellation wouldn't be loaded
    const url = `${urlConfig.getEndoIdListByPolicyId}?policyId=${policyId}`;
    let endoIdList = null;
    new CommonService.ajaxGet(null, url).then(data => {
      endoIdList = data;
    });
    return endoIdList;
  }

  getEndoFeeListByEndoId(endoId, endoType) {
    const feeDetailDialogAction = new FeeDetailDialogAction();
    let feeList = null;
    const url = `${urlConfig.queryEndoFeeListByEndoIdIncludeCancel}?endoId=${endoId}`;
    new CommonService.ajaxGet(null, url).then(data => {
      feeList = data;
    });
    const panelTitle = `${feeDetailDialogAction.getEndoType(
      feeList[0].EndoType
    )}-${feeList[0].EndoNo}`;
    const endoId2 = feeList[0].EndoId;
    const premiumDetail = feeDetailDialogAction.getPremiumDetailForEndo(
      endoId2,
      endoType
    );
    if (!premiumDetail) {
      return null;
    }
    const total = {
      InstallmentNo: 'Total',
      PremiumDue: 0,
      ActualPremium: 0,
      Fee: 0,
      Tax: 0,
      StampDuty: 0,
      LocalCurrencySign: window.EU00.getCurrencySignByCurrencyCode(
        feeList[0].CurrencyCode
      ),
      Title: feeList[0].EndoNo,
    };
    const totalExcludeFirst = {
      ActualPremium: 0,
      Fee: 0,
      Tax: 0,
      StampDuty: 0,
    };
    const result = [];
    let index = 0;
    let isHaveSettled = false;
    for (let i = 0; i < feeList.length; i += 1) {
      // Status: Outstanding: 1, Settled: 2, Cancelled: 7
      if (feeList[i].Status !== 2 && feeList[i].Balance !== 0) {
        if (feeList[i].IsAR) {
          const item = {
            InstallmentNo: feeList[i].InstallmentNo,
            PremiumDue: feeList[i].Amount,
            ActualPremium: null,
            Fee: Math.accDiv(premiumDetail.Fee, feeList.length),
            Tax: Math.accDiv(premiumDetail.Tax, feeList.length),
            StampDuty: Math.accDiv(
              premiumDetail.StampDuty,
              feeList.length
            ),
            LocalCurrencySign: window.EU00.getCurrencySignByCurrencyCode(
              feeList[i].CurrencyCode
            ),
            Title: feeList[0].EndoNo,
          };
          item.ActualPremium = Math.accSub(
            item.PremiumDue,
            Math.accAdd(
              Math.accAdd(item.Fee, item.Tax),
              item.StampDuty
            )
          );
          result.push(item);
          // Add all the amount to total
          total.PremiumDue = Math.accAdd(
            total.PremiumDue,
            item.PremiumDue
          );
          total.ActualPremium = Math.accAdd(
            total.ActualPremium,
            item.ActualPremium
          );
          total.Fee = Math.accAdd(total.Fee, item.Fee);
          total.Tax = Math.accAdd(total.Tax, item.Tax);
          total.StampDuty = Math.accAdd(
            total.StampDuty,
            item.StampDuty
          );
          if (index > 0) {
            totalExcludeFirst.ActualPremium = Math.accAdd(
              totalExcludeFirst.ActualPremium,
              item.ActualPremium
            );
            totalExcludeFirst.Fee = Math.accAdd(
              totalExcludeFirst.Fee,
              item.Fee
            );
            totalExcludeFirst.Tax = Math.accAdd(
              totalExcludeFirst.Tax,
              item.Tax
            );
            totalExcludeFirst.StampDuty = Math.accAdd(
              totalExcludeFirst.StampDuty,
              item.StampDuty
            );
          }
          index += 1;
        } else {
          const item = {
            InstallmentNo: feeList[i].InstallmentNo,
            PremiumDue: 0 - feeList[i].Amount,
            ActualPremium: null,
            Fee: Math.accDiv(premiumDetail.Fee, feeList.length),
            Tax: Math.accDiv(premiumDetail.Tax, feeList.length),
            StampDuty: Math.accDiv(
              premiumDetail.StampDuty,
              feeList.length
            ),
            LocalCurrencySign: window.EU00.getCurrencySignByCurrencyCode(
              feeList[i].CurrencyCode
            ),
            Title: feeList[0].EndoNo,
          };
          item.ActualPremium = Math.accSub(
            item.PremiumDue,
            Math.accAdd(
              Math.accAdd(item.Fee, item.Tax),
              item.StampDuty
            )
          );
          result.push(item);
          // Add all the amount to total
          total.PremiumDue = Math.accAdd(
            total.PremiumDue,
            item.PremiumDue
          );
          total.ActualPremium = Math.accAdd(
            total.ActualPremium,
            item.ActualPremium
          );
          total.Fee = Math.accAdd(total.Fee, item.Fee);
          total.Tax = Math.accAdd(total.Tax, item.Tax);
          total.StampDuty = Math.accAdd(
            total.StampDuty,
            item.StampDuty
          );
          if (index > 0) {
            totalExcludeFirst.ActualPremium = Math.accAdd(
              totalExcludeFirst.ActualPremium,
              item.ActualPremium
            );
            totalExcludeFirst.Fee = Math.accAdd(
              totalExcludeFirst.Fee,
              item.Fee
            );
            totalExcludeFirst.Tax = Math.accAdd(
              totalExcludeFirst.Tax,
              item.Tax
            );
            totalExcludeFirst.StampDuty = Math.accAdd(
              totalExcludeFirst.StampDuty,
              item.StampDuty
            );
          }

          index += 1;
        }
      } else {
        isHaveSettled = true;
      }
    }
    if (!isHaveSettled) {
      total.Fee = premiumDetail.Fee;
      total.Tax = premiumDetail.Tax;
      total.StampDuty = premiumDetail.StampDuty;
      total.ActualPremium = Math.accSub(
        Math.accSub(
          Math.accSub(total.PremiumDue, total.Fee),
          total.Tax
        ),
        total.StampDuty
      );
    }
    const handledResult = [];
    if (result && result.length > 0) {
      for (let j = 0; j < result.length; j += 1) {
        let itemNew = result[j];
        if (j === 0) {
          itemNew = {
            InstallmentNo: itemNew.InstallmentNo,
            PremiumDue: itemNew.PremiumDue,
            ActualPremium: Math.accSub(
              total.ActualPremium,
              totalExcludeFirst.ActualPremium
            ),
            Fee: Math.accSub(total.Fee, totalExcludeFirst.Fee),
            Tax: Math.accSub(total.Tax, totalExcludeFirst.Tax),
            StampDuty: Math.accSub(
              total.StampDuty,
              totalExcludeFirst.StampDuty
            ),
            CurrencySign: itemNew.CurrencySign,
            LocalCurrencySign: itemNew.LocalCurrencySign,
            Title: panelTitle,
          };
        }
        handledResult.push(itemNew);
      }
    }
    handledResult.push(total);
    // result.push(total);
    return { count: 0, result: handledResult };
  }

  getEndoType(endoType) {
    const commonCodeTable = new CommonCodeTable();
    const codeTable = commonCodeTable.getEndoTypeBCP().codes;
    for (let i = 0; i < codeTable.length; i += 1) {
      if (codeTable[i].id == endoType) {
        UpdateContext.forceUpdate('newbizOrEndoFeeDetailUpdatePanel');
        return codeTable[i].text;
      }
    }
  }

  extendWindowMathFn() {
    if (!Math.accAdd || !Math.accSub) {
      EU00CommonMath.extendWindowMathFn();
    }
  }
}
