import {
  UIBox,
  UISelect,
  UIPanel,
  UICell,
  UIButton,
  CodeTable,
  UITree,
  UIParam,
  UIText,
  MessageHelper,
} from 'RainbowUI';
import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import BindToMixin from 'react-binding';
import {
  ApprovalHistory,
  ClaimTaskTransferDialog,
} from 'ClaimCommon';
import ClaimCommonExt from 'ClaimCommonExt';
import ClaimCalculationExt from 'ClaimCalculationExt';

const CalculationLoadAction = require('../action/CalculationLoadAction');
const CalculationSubmitAction = require('../action/CalculationSubmitAction');
const CalculationStore = require('../store/CalculationStore');
const CalculationInfo = require('./CalculationInfo');
const CalculationHistory = require('./CalculationHistory');

const CalculationValidationAction = ClaimCalculationExt.getClaimCalculationValidationAction();

const ClaimBaseInfo = ClaimCommonExt.getClaimBaseInfo();

const CalculationTaskPage = React.createClass({
  mixins: [BindToMixin],

  propTypes: {
    taskInstanceId: PropTypes.string,
    caseId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  },

  getInitialState() {
    return { CalculationData: { OtherInfo: {} }, SelectedLength: 0 };
  },
  componentWillMount() {
    CalculationStore.listen(this.onStoreStateChange);
    if (!_.isEmpty(this.props.taskInstanceId)) {
      CalculationLoadAction.loadCalculationTask(
        this.props.taskInstanceId
      );
    }
  },
  componentWillUnmount() {
    CalculationStore.unlisten(this.onStoreStateChange);
  },
  onStoreStateChange(storeState) {
    if (!_.isEmpty(storeState.CalculationData)) {
      this.setState({
        CalculationData: storeState.CalculationData,
        SelectedLength: storeState.SelectedLength,
      });
    }
  },
  validate() {
    const result = CalculationValidationAction.validateSILimitForCalculationSubmit(
      this.state.CalculationData
    );
    if (result && result.Status === 'WARN') {
      this.refs.confirmDialog_ref.showDialog(
        i18n.ClaimCalculation.Calculation,
        result.Messages[0].Message
      );
      return true;
    }
    if (result && result.Status === 'BLOCK') {
      MessageHelper.error('', result.Messages[0].Message);
      return true;
    }
  },
  cancel() {},
  onClickSubmit() {
    if (this.validate()) {
      return;
    }
    this.submitCalculation();
  },
  submitCalculation() {
    const result = CalculationSubmitAction.submitCalculation(
      this.state.CalculationData
    );
    if (result === 'OK') {
      MessageHelper.success(
        '',
        i18n.ClaimCalculation.CalculationSubmitSuccess
      );
      this.refs.ClaimTaskTransferDialog.showDialog({
        CaseId: this.props.caseId,
      });
    }
  },
  onClickSave() {
    CalculationSubmitAction.saveCalculation(
      this.state.CalculationData
    );
  },
  onClickCalculate() {
    CalculationSubmitAction.calculate(this.state.CalculationData);
  },

  getSelectCode() {
    const otherInfo = this.bindToState(
      'CalculationData',
      'OtherInfo'
    );
    const coverageTree = otherInfo.value.CoverageTree || [];
    const seleCodeTableList = [];
    seleCodeTableList.push({
      id: 0,
      text: i18n.ClaimCalculation.PleaseSelect,
    });
    for (let i = 1; i < coverageTree.length; i += 1) {
      seleCodeTableList.push({
        id: i,
        text: i + i18n.ClaimCalculation.Selected,
      });
    }
    const seleCodeTable = new CodeTable(seleCodeTableList);
    return seleCodeTable;
  },

  displayCoverageTree() {
    this.createMask();
    this.CoverageTreeDiv.style.display = 'block';
    CalculationLoadAction.displayCoverageTree();
  },

  createMask() {
    const maskDiv = document.createElement('div');
    maskDiv.style.zIndex = '90';
    maskDiv.style.width = '100%';
    maskDiv.style.height = 'auto';
    maskDiv.style.minHeight = '768px';
    maskDiv.style.position = 'absolute';
    maskDiv.style.top = 0;
    document.body.appendChild(maskDiv);

    let removeMaskFn = () => {
      $('.coverageValue').show();
      maskDiv.removeEventListener('click', removeMaskFn);
      document.body.removeChild(maskDiv);
      removeMaskFn = null;
      this.hideTree();
    };

    maskDiv.addEventListener('click', removeMaskFn);
  },

  hideTree() {
    this.CoverageTreeDiv.style.display = 'none';
  },

  render() {
    const {
      taskInstanceId,
      claimEntity,
      caseId,
      readOnly,
    } = this.props;
    const { SelectedLength } = this.state;
    if (_.isEmpty(taskInstanceId)) {
      return (
        <div>
          <ClaimBaseInfo claimEntity={claimEntity} />
          <CalculationHistory caseId={caseId} />
        </div>
      );
    }
    const suffixId = `calculation_${taskInstanceId}_`;
    const otherInfo = this.bindToState(
      'CalculationData',
      'OtherInfo'
    );
    const calculationInfo = this.bindToState(
      'CalculationData',
      'CalculationInfo'
    );
    const calculateId = this.bindTo(calculationInfo, '@pk').value;
    const approvalHistory =
      calculateId === null ||
      calculateId === undefined ||
      calculateId === '' ? (
        <UICell />
      ) : (
        <UICell style={{ textAlign: 'left', padding: '0' }}>
          <ApprovalHistory
            processObjectId={calculateId}
            componentId={suffixId}
          />
        </UICell>
      );
    const coverageTree = otherInfo.value.CoverageTree;
    const CommonConfirmDialog = ClaimCommonExt.getCommonConfirmDialog();
    return (
      <div>
        <ClaimBaseInfo claimEntity={claimEntity} />
        <div>
          <UIPanel
            id={`${suffixId}calculationTaskPanel`}
            panelTitle={i18n.ClaimCalculation.CalculationPanelTitle}
            toggleable="false"
          >
            <UICell width="2" style={{ textAlign: 'left' }}>
              <UIText
                widthAllocation="1200,0"
                label={
                  i18n.ClaimCalculation.SelectSubCoverageAndCoverage
                }
                io="out"
              />
            </UICell>
            <UICell
              width="8"
              style={{ textAlign: 'left', marginLeft: '-5%' }}
            >
              <div
                id={`${suffixId}coverageValueDiv`}
                className="coverageValue"
                style={{ display: '' }}
              >
                <UISelect
                  id={`${suffixId}coverage`}
                  onFocus={this.displayCoverageTree}
                  required="true"
                  codeTable={this.getSelectCode()}
                  value={SelectedLength === 0 ? '' : SelectedLength}
                  validationGroup="calculation_submit_validate"
                  label=""
                  widthAllocation="1,11"
                />
              </div>
              <div
                id={`${suffixId}coverageTreeDiv`}
                ref={node => (this.CoverageTreeDiv = node)}
                // ref="CoverageTreeDiv"
                className="coverageTree"
                style={{
                  display: 'none',
                  position: 'absolute',
                  backgroundColor: '#fff',
                  zIndex: '99',
                  padding: '15px',
                  border: 'solid 1px #e5e5e5',
                  marginLeft: '5%',
                }}
              >
                <UITree
                  id={`${suffixId}coverageTree`}
                  searchable="true"
                  checkable="true"
                  dataSource={coverageTree}
                />
                <br />
                <UIButton
                  id={`${suffixId}chooseCoverageBtn`}
                  value={i18n.ClaimCommon.Submit}
                  onClick={CalculationLoadAction.chooseCoverage}
                >
                  <UIParam
                    name="treeId"
                    value={`${suffixId}coverageTree`}
                  />
                </UIButton>
              </div>
            </UICell>
            <UICell style={{ textAlign: 'left' }}>
              <CalculationInfo
                calculationInfo={calculationInfo}
                otherInfo={otherInfo}
                readOnly={false}
                isApproveTask={false}
              />
            </UICell>

            <UICell style={{ textAlign: 'left', margin: '15px 0' }}>
              <UIBox direction="left">
                <UIButton
                  value={i18n.ClaimCalculation.Calculate}
                  validationGroup="calculation_submit_validate"
                  causeValidation="true"
                  onClick={this.onClickCalculate}
                />
                <UIButton
                  value={i18n.ClaimCommon.Save}
                  onClick={this.onClickSave}
                />
                <UIButton
                  value={i18n.ClaimCommon.Submit}
                  validationGroup="calculation_submit_validate"
                  causeValidation="true"
                  onClick={this.onClickSubmit}
                />
              </UIBox>
            </UICell>

            <UICell style={{ textAlign: 'left', padding: '0' }} />
            <ClaimTaskTransferDialog
              ref="ClaimTaskTransferDialog"
              componentId="calcul_task"
            />
          </UIPanel>
          <CommonConfirmDialog
            ref="confirmDialog_ref"
            parentComponent={this}
            objectId={`_subclaimInfo${readOnly}_${caseId}`}
            onConfirm={this.submitCalculation}
            onCancel={this.cancel}
          />
        </div>

        <UICell style={{ textAlign: 'left' }} />

        {approvalHistory}

        <UICell style={{ textAlign: 'left', padding: '0' }}>
          <CalculationHistory caseId={caseId} />
        </UICell>
        {/* <UIAjaxStatus id="calculation_status"/> */}
      </div>
    );
  },
});
export default CalculationTaskPage;
