import {
  UIDialog,
  CodeTable,
  UIConfirmDialog,
  MessageHelper,
} from 'RainbowUI';
import alt from '../alt';
import AssignmentService from '../service/AssignmentService';
import ClaimCacheUI from '../constant/ClaimCacheUI';
import CodeTableService from '../service/CodeTableService';

class AssignmentAction {
  changeTopTab(event) {
    const caseId = event.getParameter('caseId');
    if (caseId != null && caseId !== '') {
      this.actions.initAssignment(caseId);
    } else {
      /* empty */
    }
  }

  initHisClaimTask(caseId) {
    AssignmentService.initHisClaimTask(caseId).then(
      data => {
        const pendingTasks = JSON.parse(data.Model).data;
        for (const pendingTask in pendingTasks) {
          for (const v in pendingTask.variables) {
            pendingTask[
              v.name.substring(0, 1).toLowerCase() +
                v.name.substring(1, v.name.length)
            ] = v.value;
          }
        }
        return pendingTasks;
      },
      ex => {
        MessageHelper.error(
          i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
        );
      }
    );
  }

  newTask() {
    UIDialog.show('newTaskDialog');
  }

  saveClaimTask(data, flag) {
    // var editornew = DataContext.get('editornew');
    const id = data.CaseId;
    AssignmentService.saveClaimTask(data).then(
      data => {
        if (data.Status === 'OK') {
          if (flag == true) {
            MessageHelper.info(i18n.ClaimCommon.assignmentNewTaskSu);
          } else {
            MessageHelper.info(
              i18n.ClaimCommon.assignmentReassignTaskSu
            );
          }

          UIDialog.hide('newTaskDialog');
          this.actions.initAssignment(id);
        } else if (data.Status === 'WARN') {
          MessageHelper.warning(data.Messages[0].Message);
        } else {
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
        }
      },
      ex => {
        MessageHelper.error(
          i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
        );
      }
    );
  }

  closeClaimTask(data) {
    const _self = this;
    const id = data.caseId;
    AssignmentService.closeClaimTask(data).then(
      data => {
        if (data.Status === 'OK') {
          MessageHelper.info(i18n.ClaimCommon.assignmentCloseTaskSu);
          UIConfirmDialog.hide('ConfirmDialog');
          _self.actions.initAssignment(id);
        } else {
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
        }
      },
      ex => {
        MessageHelper.error(
          i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
        );
      }
    );
  }

  initData(caseId) {
    const result = null;
    AssignmentService.initData(caseId).then(
      result => {
        this.dispatch(JSON.parse(result.Model).data);
      },

      ex => {
        MessageHelper.error(
          i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
        );
      }
    );
    return JSON.parse(result.Model).data;
  }

  initAssignment(caseId) {
    // 11011
    AssignmentService.loadAssignmentInfo(caseId, 'false').then(
      result => {
        const assignment = {};
        assignment.pendingTasks = result.Model.loadClaimTasks;
        assignment.closedTasks = result.Model.loadClosedTasks;
        assignment.user = result.Model.user;
        this.dispatch(assignment);
      }
    );
  }

  loadDataList(codeTableId, conditionMap) {
    const cacheId = `dataListById_${codeTableId}`;
    const cache = ClaimCacheUI.CodeTableCache;
    if (cache.get(cacheId)) {
      return cache.get(cacheId);
    }
    const codeTableList = [];
    CodeTableService.loadDataList(codeTableId, conditionMap).then(
      dataList => {
        dataList.forEach((data, index) => {
          if (
            data.ConditionFields &&
            data.ConditionFields.length > 0
          ) {
            data.ConditionFields.forEach((condition, index) => {
              codeTableList.push({
                id: data.Code,
                text: data.Description,
                Parent: condition.ParentId,
              });
            });
          } else {
            codeTableList.push({
              id: data.Code,
              text: data.Description,
            });
          }
        });

        cache.put(cacheId, new CodeTable(codeTableList));
      },
      err => {
        if (err.status !== 200) {
          MessageHelper.error(
            err.statusText,
            i18n.ClaimCommon.ErrorMsg.DataLoadERROR.replace(
              '{0}',
              codeTableId
            )
          );
        }
      }
    );
    return new CodeTable(codeTableList);
  }

  UserListByAuthoryCode(authoryCode) {
    let result = null;
    AssignmentService.UserListByAuthoryCode(authoryCode).then(
      data => {
        result = data;
        if (data.Status === 'ERROR')
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
      },
      err => {
        if (err.status !== 200) {
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
        }
      }
    );
    return new CodeTable(result.Model);
  }

  getApproachCodeByTaskCode(taskCode) {
    let result = null;
    AssignmentService.getApproachCodeByTaskCode(taskCode).then(
      data => {
        result = data.Model;
        if (data.Status === 'ERROR')
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
      },
      err => {
        if (err.status !== 200) {
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
        }
      }
    );
    return result;
  }

  getTaskCode(caseId) {
    let result = null;
    AssignmentService.getTaskCode(caseId).then(
      data => {
        result = data.Model;
        if (data.Status === 'ERROR')
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
      },
      err => {
        if (err.status !== 200) {
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
        }
      }
    );
    return result;
  }

  getAttachTo() {
    let result = null;
    AssignmentService.getAttachTo().then(
      data => {
        result = data.Model;
        if (data.Status === 'ERROR')
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
      },
      err => {
        if (err.status !== 200) {
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
        }
      }
    );
    return result;
  }

  getFATaskRelationInfo(claimNo, complete) {
    let result = null;
    AssignmentService.getFATaskRelationInfo(claimNo, complete).then(
      data => {
        result = data.Model;
        if (data.Status === 'ERROR')
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
      },
      err => {
        if (err.status !== 200) {
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
        }
      }
    );
    return result;
  }
}
export default alt.createActions(AssignmentAction);
