import { UIDialog, MessageHelper, AjaxUtil } from 'RainbowUI';
import alt from '../alt';

const Url = require('../url/Url');

const ReserveService = require('../service/ReserveService');
const ClaimHandlingAction = require('../../common/action/ClaimHandlingAction');

class ReserveAction {
  reserveApprovalTaskAssigned(eventType) {
    this.dispatch({ eventType });
  }

  confirmReserveApprovalInfo(claimData, reserveApprovalInfo) {

    const approvalInfo = {};

    approvalInfo.Remark = reserveApprovalInfo.Remark;
    approvalInfo.DecisionCode = reserveApprovalInfo.DecisionCode;
    approvalInfo.TaskInstanceId = claimData.TaskInstanceId.replace("ClaimTask_", "");
    approvalInfo.ProcessObjectId = claimData.BusinessId;
    let url;
    if (claimData.Commontype === 'update') {
      url = Url.ReserveApprovalInfo;
    } else if (claimData.Commontype === 'closure') {
      url = Url.ReserveClosureApprovalInfo;
    } else {
      return;
    }

    let noassign = false;
    AjaxUtil.doPost(url, approvalInfo, {
      async: false,
      done(data) {
        if (data.Status === 'OK') {
          if (approvalInfo.DecisionCode === 'Y') {
            MessageHelper.success(
              i18n.ClaimReserve.ApprovedReserveSuccess
            );
          } else {
            MessageHelper.success(
              i18n.ClaimReserve.RejectedReserveSuccess
            );
          }
          noassign = true;
        } else if (data.Status === 'BLOCK') {
          noassign = true;
        } else if (data.Status === 'WARN') {
          if (data.Model) {
            noassign = data.Model.NoAssign;
          }
        } else {
          MessageHelper.error('', i18n.ClaimCommon.ErrorMsg.Failed);
        }
      },
      fail(jqXHR) {
        MessageHelper.error('', jqXHR.responseText);
      },
    });

    if (noassign) {
      this.actions.reserveApprovalTaskAssigned(
        'ReserveApprovalTaskTransferNoAssign'
      );
    }
  }

  initReserveChanged(reserveId) {
    ReserveService.initReserveChanged(reserveId).then(data => {
      if (data.Status === 'OK') {
        this.dispatch(data.Model);
      } else {
        MessageHelper.error(data.Messages[0].Message, '');
      }
    });
  }

  initReserve(objectId) {
    ReserveService.initReserve(objectId).then(data => {
      if (data.Status === 'OK') {
        this.dispatch(data.Model);
      } else {
        MessageHelper.error(data.Messages[0].Message, '');
      }
    });
  }

  closeSubmit(newClosure, objectId) {
    ReserveService.closeSubmit(newClosure).then(data => {
      let storObjectId;
      if (data.Status === 'OK') {
        const closeType = sessionStorage.getItem('claim_closeType');
        storObjectId = sessionStorage.getItem('claim_objectId');
        switch (closeType) {
          case 'item':
            this.dispatch(data.Model);
            break;
          case 'case':
            if (!_.isEmpty(storObjectId)) {
              this.actions.initReserve(storObjectId);
            }
            break;
          case 'reserve':
            this.actions.initReserve(newClosure.ObjectId);
            break;
          case 'object':
            if (!_.isEmpty(storObjectId)) {
              this.actions.initReserve(storObjectId);
            }
            break;
          default:
        }
        UIDialog.hide(`closeReserveDialog_${objectId}`);
        MessageHelper.success(
          '',
          i18n.ClaimReserve.CloseReserveSuccess
        );
        ClaimHandlingAction.changeStatus();
      } else if (data.Status === 'WARN') {
        storObjectId = sessionStorage.getItem('claim_objectId');
        this.actions.initReserve(storObjectId);
        UIDialog.hide(`closeReserveDialog_${objectId}`);
        ClaimHandlingAction.changeStatus();
        this.actions.reserveApprovalTaskAssigned(
          'ReserveApprovalTaskTransferAssign'
        );
      } else {
        MessageHelper.error(data.Messages[0].Message, '');
      }
    });
  }

  submitCalculate(calculateVO, objectId) {
    ReserveService.submitCalculate(calculateVO).then(
      data => {
        if (data.Status === 'OK') {
          this.actions.initReserve(objectId);
          MessageHelper.success(
            '',
            i18n.ClaimReserve.UpdateReserveSucess
          );
          UIDialog.hide(`calculateReserveDialog_${objectId}`);
        } else if (data.Status === 'WARN') {
          MessageHelper.warning(
            i18n.ClaimCommon.WarnMsg
              .ClaimIsBeyondYourAuthorityNeedCheck,
            '',
            MessageHelper.POSITION_TOP_RIGHT
          );
          this.actions.initReserve(objectId);
          UIDialog.hide(`calculateReserveDialog_${objectId}`);
          this.actions.reserveApprovalTaskAssigned(
            'ReserveApprovalTaskTransferAssign'
          );
        } else {
          MessageHelper.error(data.Messages[0].Message, '');
        }
      },
      err => {
        if (err.responseJSON) {
          if (err.responseJSON.Messages) {
            MessageHelper.error(
              err.responseJSON.Messages['0'].Message
            );
          }
        }
      }
    );
  }

  reopenSubmit(dataVO, objectId) {
    ReserveService.submitReopen(dataVO).then(data => {
      if (data.Status === 'OK') {
        MessageHelper.success(
          '',
          i18n.ClaimReserve.ReopenReserveSucess
        );
        UIDialog.hide(`reopenReserveDialog_${objectId}`);
        ClaimHandlingAction.changeStatus();
        this.dispatch(data.Model);
      } else {
        MessageHelper.error(data.Messages[0].Message, '');
      }
    });
  }
}
export default alt.createActions(ReserveAction);
