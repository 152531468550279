import { MessageHelper, UITree, UIDialog } from 'RainbowUI';
import alt from '../alt';

const NewSalvageVO = require('./vo/NewSalvageVO');
const SalvageService = require('../service/SalvageService');

class SalvageLoadAction {
  loadSalvageTask(taskInstanceId) {
    SalvageService.loadSalvageTask(taskInstanceId).then(
      datas => {
        let data = datas;
        // data = SalvageService.convertLoadFullSalvageData2Positive(
        //   data
        // );
        this.dispatch(data);
      },
      data => {
        MessageHelper.error(data.Messages[0].Message, '');
      }
    );
  }

  loadSalvageReadOnly(salvageId, isApproveTask) {
    SalvageService.loadSalvageReadOnly(salvageId).then(
      datas => {
        let data = datas;
        // data = SalvageService.convertLoadFullSalvageData2Positive(
        //   data
        // );
        this.dispatch({
          data,
          isApproveTask,
        });
      },
      data => {
        MessageHelper.error(data.Messages[0].Message, '');
      }
    );
  }

  loadSalvageHistory(caseId) {
    SalvageService.loadSalvageHistory(caseId).then(
      Tempdata => {
        let dataTemp = Tempdata;
        // dataTemp = dataTemp.filter(tempdata => {
        //   let data = tempdata;
        //   if (
        //     data.TotalSalvageAmount &&
        //     data.TotalSalvageAmount < 0
        //   ) {
        //     data.TotalSalvageAmount *= -1;
        //   }
        //   data = SalvageService.convertLoadLessSalvageData2Positive(
        //     data
        //   );
        //   return data;
        // });

        this.dispatch(dataTemp);
      },
      data => {
        MessageHelper.error(data.Messages[0].Message, '');
      }
    );
  }

  getClosedTasks(caseId) {
    let result = SalvageService.getClosedTasks(caseId);
    result = SalvageService.convertLoadLessSalvageData2Positive(
      result
    );

    return result;
  }

  showReadOnly(event) {
    const salvageId = event.getParameter('salvageId');
    this.dispatch(salvageId);
    UIDialog.show('SalvageReadOnlyDialog');
  }

  loadApproveTask(taskInstanceId) {
    SalvageService.loadSalvageApproveTask(taskInstanceId).then(
      data => {
        this.dispatch(data);
      },
      data => {
        MessageHelper.error(data.Messages[0].Message, '');
      }
    );
  }

  displayCoverageTree() {
    $('.coverageTree').show();
    $('.coverageValue').hide();
  }

  chooseCoverage(checkedList, taskInstanceId, salvageId) {
    const para = [];

    if (
      salvageId === null ||
      salvageId === undefined ||
      salvageId === ''
    ) {
      para[0] = '';
      para[1] = checkedList;
      this.dispatch(para);
    } else {
      SalvageService.loadSalvageItems(taskInstanceId, salvageId).then(
        datas => {
          let data = datas;
          data = SalvageService.convertLoadLessSalvageData2Positive(
            data
          );
          para[0] = data;
          para[1] = checkedList;
          this.dispatch(para);
        },
        data => {
          MessageHelper.error(data.Messages[0].Message, '');
        }
      );
    }
  }

  chooseCoverageOld(event) {
    const treeId = event.getParameter('treeId');
    const taskInstanceId = event.getParameter('taskInstanceId');
    const salvageId = event.getParameter('salvageId');
    const checkedList = UITree.getCheckedNode(treeId, true);
    $('.coverageValue').show();
    $('.coverageTree').hide();

    const para = [];

    if (
      salvageId === null ||
      salvageId === undefined ||
      salvageId === ''
    ) {
      para[0] = '';
      para[1] = checkedList;
      this.dispatch(para);
    } else {
      SalvageService.loadSalvageItems(taskInstanceId, salvageId).then(
        datas => {
          let data = datas;
          data = SalvageService.convertLoadLessSalvageData2Positive(
            data
          );
          para[0] = data;
          para[1] = checkedList;
          this.dispatch(para);
        },
        data => {
          MessageHelper.error(data.Messages[0].Message, '');
        }
      );
    }
  }

  addItem(event) {
    const objectId = event.getParameter('objectId');
    const itemId = event.getParameter('itemId');
    const coverageCode = event.getParameter('coverageCode');
    const currencyCode = event.getParameter('currencyCode');
    const itemToAdd = NewSalvageVO.salvageItemToAdd;
    itemToAdd.initial(
      objectId,
      itemId,
      coverageCode,
      currencyCode,
      0
    );
    this.dispatch(itemToAdd);
  }

  deleteItem(event) {
    const objectId = event.getParameter('objectId');
    const coverageCode = event.getParameter('coverageCode');
    const index = event.getParameter('index');
    const itemsLength = event.getParameter('itemsLength');
    if (itemsLength <= 1) {
      MessageHelper.error('', i18n.ClaimSalvage.AtleastOneItem);
      return;
    }
    const data = {
      ObjectId: objectId,
      CoverageCode: coverageCode,
      Index: index,
      Length: itemsLength,
    };

    this.dispatch(data);
  }

  recoverFromChange(event) {
    const index = event.getParameter('index');
    const recoverFrom = event.newValue;
    const data = {
      RecoverFrom: recoverFrom,
      Index: index,
    };
    this.dispatch(data);
  }

  salvageAmountChange(event) {
    const index = event.getParameter('index');
    var tempSalvageAmnt = event.newValue;

    if (tempSalvageAmnt > 0) {
      tempSalvageAmnt = tempSalvageAmnt * -1;
    }

    const salvageAmount = tempSalvageAmnt;
    const data = {
      SalvageAmount: salvageAmount,
      Index: index,
    };

    this.dispatch(data);
  }

  remarkForSalvageChange(event) {
    const index = event.getParameter('index');
    const remark = event.newValue;
    const data = {
      Remark: remark,
      Index: index,
    };
    this.dispatch(data);
  }
}
export default alt.createActions(SalvageLoadAction);
