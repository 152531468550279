export default {
  // common
  Status: '状態',
  SettlementAmount: '決済金額',
  CoInsurancePayment: '共保支付',

  CancelSettlement: '決済キャンセル',
  CancelRecovery: '求償取消',
  CancelConfirmation: '确认取消',
  CancelRemarkPlaceHolder:
    'キャンセルのため、備考を入力してください。',
  CancelRecoveryMessage: '求償を取り消しますか？',
  CancelSettlementMessage: '決済処理をキャンセルしてよろしいですか？',

  // Settlement History
  SettlementHistory: '決済履歴',
  SettlementNo: '決済番号',
  TransactionDate: '処理日',
  IssuedBy: '発行者',
  ApprovedBy: '承認者',
  Name: '氏名',

  // Settlement Info
  PayMode: '支払方法',
  ExchangeRate: '為替レート',
  PayeeName: '支払先氏名',
  PayeeAddress: '支払先住所',
  PayeeAccount: '支払金額',
  OSReserve: 'O/S 備金',
  PartialFinal: '一部/最終',
  SettlementType: '決済種類',
  DeletePayee: '支払先削除',
  AddItem: '項目追加',
  AddPayee: '支払先追加',
  AddPayer: '支払人追加',
  DeletePayer: '支払人削除',

  SettlementDetail: '決済詳細',
  Settlement: '決済',
  SettlementApproval: '決済承認',

  NoPayee: '支払先を追加してください。',

  ReferenceNumber: '参照番号',
  ReferenceDate: '参照日',

  SettlemenAmountCheck: '決済金額は必ずマイナスです。',

  ConfirmMessage: ' クローズしますか？',
  ConfirmMessageTitle: ' 確認',

  Recovery: '求償',
  RecoveryDetail: '求償情報',
  RecoveryHistory: '求償履歴',
  RecoveryHistoryDetail: '求償履歴情報',
  PayerName: '支払人',
  RecoveryNo: '求償番号',
  RecoveryAmount: '求償金額',
  RecoveryType: '求償タイプ',

  // MessageHelper
  SettlementSaveSuccess: '决赔保存成功',
  SettlementSubmitSuccess: '决赔提交成功',
  SettlementApprovalSubmitSuccess: '决赔签核提交成功',
  SettlementCancelSuccess: '取消决赔历史提交成功',
  SettlementSubmitError: '每个领款人至少包含一项',
  RecoverySaveSuccess: '追偿保存成功',
  RecoverySubmitSuccess: '追偿提交成功',
  RecoveryApprovalSubmitSuccess: '追偿签核提交成功',
  RecoveryCancelSuccess: '取消追偿历史提交成功',
  RecoverySubmitError: '每个付款人至少包含一项',
  ChoosePartialFinalFail:
    '针对同一准备金的添加多条决赔项目时，只有最后一条决赔项目允许选择最终赔付',
  DeletePayeeError: '系统必须要有一个领款人',
  ChooseReserveError:
    '不能在同一个领款人或者付款人下选相同的准备金类型',
  NotZero: '你不能输入零',
  ChoosePayeeError: '请添加付款人/领款人',
  WithOutPolicyError: '无保单赔案,请先抄单',
};
