import MessageHelper from 'RainbowUI';
import i18n from 'i18n';
import ClaimCommonExt from 'ClaimCommonExt';
import NewAppraisalItem from './vo/NewAppraisalItem';
import alt from '../alt';

const TriggerTaskTransformDialogAction = ClaimCommonExt.getReserveAction();
const AppraisalBodyInjuryService = require('../service/AppraisalBodyInjuryService');
const AppraisalPropertyService = require('../service/AppraisalPropertyService');

class AppraialBodyInjuryAction {
  static saveAppraisalBodyInjury(claimAppraisal) {
    AppraisalPropertyService.saveAppraisalProperty(claimAppraisal)
      .then(() => {})
      .catch(data => {
        MessageHelper.error(data, '');
      });
  }

  static loadClaimAppraisal(subClaimId) {
    return AppraisalBodyInjuryService.loadClaimAppraisal(subClaimId);
  }

  static approveAppraisalBody(appraisalBody) {
    return AppraisalBodyInjuryService.approveAppraisalBody(
      appraisalBody
    );
  }

  static submitAppraisalBody(appraisalBody) {
    const promise = AppraisalBodyInjuryService.submitAppraisalBody(
      appraisalBody
    );
    promise
      .then(data => {
        if (data.Status === 'OK') {
          MessageHelper.success(
            i18n.ClaimAppraisal.SuccessMsg.Success_0001
          );
          TriggerTaskTransformDialogAction.reserveApprovalTaskAssigned(
            'FieldInvestigationTaskComplete'
          );
        }
      })
      .catch(() => {
        MessageHelper.error(
          '',
          i18n.ClaimAppraisal.ErrorMsg.Error_0001
        );
      });
  }

  addBodyItem() {
    const itemToAdd = NewAppraisalItem.bodyItemToAdd;
    this.dispatch(itemToAdd);
  }

  removeBodyItem(event) {
    const removeIndex = event.getParameter('removeIndex');
    this.dispatch(removeIndex);
  }

  static getVictim(subClaimId) {
    return AppraisalBodyInjuryService.getVictim(subClaimId);
  }
}
export default alt.createActions(AppraialBodyInjuryAction);
