import {
  UIButton,
  UISmartPanelGrid,
  UITextarea,
  UIParam,
  UISelect,
  UIBox,
  UILink,
  UICell,
} from 'RainbowUI';
import BindToMixin from 'react-binding';
import PropTypes from 'prop-types';
import ClaimNoteAction from '../../action/ClaimNoteAction';
import CodeTableCstUI from '../../constant/CodeTableCstUI';
import CodeTableAction from '../../action/CodeTableAction';

const CreateNote = React.createClass({
  mixins: [BindToMixin],
  propTypes: {
    caseId: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  },
  getDefaultProps() {
    return {
      caseId: -1,
    };
  },
  getInitialState() {
    return {
      addStatus: '',
      noteVo: {},
    };
  },
  addNotes() {
    const { caseId } = this.props;
    this.setState({ addStatus: 'add' });
    this.setState({ noteVo: {} });
  },
  saveNotes(event) {
    const caseId = event.getParameter('caseId');
    const claimNo = event.getParameter('claimNo');

    ClaimNoteAction.submitClaimNote(
      caseId,
      claimNo,
      this.state.noteVo
    );
    this.setState({ addStatus: '' });
    this.props.parentComponent.stateStoreChange();
  },
  cancel() {
    this.setState({ addStatus: '' });
  },
  render() {
    const { addStatus } = this.state;
    const { caseId, claimNo, noteAuth } = this.props;
    if (addStatus === 'add') {
      return (
        <div>
          <UICell width="12">
            <UISmartPanelGrid
              layout="horizontal"
              column="3"
            >
              <UISelect
                label={i18n.ClaimCommon.NoteCategory}
                id="noteCategoryId"
                codeTable={CodeTableAction.loadDataList(
                  CodeTableCstUI.ClaimNoteCategory,
                  null
                )}
                validationGroup="createNote"
                required="true"
                valueLink={this.bindToState('noteVo', 'NoteCategory')}
              />
              <UISelect
                label={i18n.ClaimCommon.NoteSendMessageTo}
                autoComplete="true"
                valueLink={this.bindToState('noteVo', 'SendMessageTo')}
                codeTable={CodeTableAction.loadDataList(
                  CodeTableCstUI.ClaimUser,
                  null
                )}
              />
              <UISelect
                label={i18n.ClaimCommon.NoteUserType}
                valueLink={this.bindToState('noteVo', 'UserType')}
                codeTable={CodeTableAction.loadDataList(
                  CodeTableCstUI.ClaimNoteUserType,
                  null
                )}
                defaultValue="I"
                required="true"
              />
              <UISelect
                label={i18n.ClaimCommon.NoteLevel}
                valueLink={this.bindToState('noteVo', 'NoteLevel')}
                codeTable={this.props.showNoteLevel}
                defaultValue="Claim"
                required="true"
              />
            </UISmartPanelGrid>
          </UICell>
          <UICell>
            <UITextarea
              label={i18n.ClaimCommon.NoteOverview}
              widthAllocation="2, 10"
              valueLink={this.bindToState('noteVo', 'NoteOverview')}
              maxLength="500"
              validationGroup="createNote"
              required="true"
            />
            <UITextarea
              label={i18n.ClaimCommon.NoteContent}
              colspan="2"
              valueLink={this.bindToState('noteVo', 'NoteContent')}
              validationGroup="createNote"
              required="true"
            />
            <UIBox direction="left">
              <UIButton
                value={i18n.ClaimCommon.Save}
                onClick={this.saveNotes}
                validationGroup="createNote"
                causeValidation="true"
              >
                <UIParam name="caseId" value={caseId} />
                <UIParam name="claimNo" value={claimNo} />
              </UIButton>
              <UIButton
                value={i18n.ClaimCommon.Cancel}
                onClick={this.cancel}
              />
            </UIBox>
          </UICell>
        </div >
      );
    }
    return (
      <UIBox>
        <UILink
          icon="fa fa-plus"
          size="lg"
          iconClass="add_iconbtn"
          onClick={this.addNotes}
          visibled={noteAuth}
          enabled={noteAuth}
        />
      </UIBox>
    );
  },
});
export default CreateNote;
