import React, { Component, PropTypes } from 'react';

import ReactMixin from 'react-mixin';
import BindToMixin from 'react-binding';
import ClaimCommonExt from 'ClaimCommonExt';
import UpdateCommon from './UpdateCommon';
import ClosureCommon from './ClosureCommon';
import ReserveApprovalHistory from './ReserveApprovalHistory';
import ReserveApprovalStore from '../store/ReserveApprovalStore';
import ReserveAction from '../action/ReserveAction';

const ClaimBaseInfo = ClaimCommonExt.getClaimBaseInfo();

export default class ReserveApproval extends Component {
  constructor(props) {
    super(props);
    this.state = {
      common: {
        Update: {},
        Closure: {},
      },
      approvalInfo: {
        DecisionCode: null,
        Remark: null,
      },
      approvalHistory: {},
      commontype: {},
      claimNo: '',
    };
    this.handlerStoreChange = this.handlerStoreChange.bind(this);
    this.renderApprovalInfo = this.renderApprovalInfo.bind(this);
    this.renderCommon = this.renderCommon.bind(this);
    this.renderApprovalHistory = this.renderApprovalHistory.bind(
      this
    );
  }

  confirm() {
    const { claimData, reserveApprovalId } = this.props;
    const _self = this;
    claimData.BusinessId = reserveApprovalId;
    claimData.Commontype = _self.state.commontype;
    claimData.ClaimNo = _self.props.claimNo;
    ReserveAction.confirmReserveApprovalInfo(
      claimData,
      _self.state.approvalInfo
    );
  }

  renderCommon() {
    const _self = this;
    if (
      _self.props.commontype === 'update' ||
      _self.state.commontype === 'update'
    ) {
      return (
        <span>
          <UpdateCommon
            model={this.bindToState('common', 'Update')}
            title={_self.state.common.Update.ShowType}
            curreny={_self.state.common.Update.CurrenyCode}
          />
        </span>
      );
    }
    if (
      _self.props.commontype === 'closure' ||
      _self.state.commontype === 'closure'
    ) {
      return (
        <span>
          <ClosureCommon
            model={this.bindToState('common', 'Closure')}
            title={_self.state.common.Closure.ShowType}
          />
        </span>
      );
    }
  }

  renderApprovalHistory() {
    const { reserveApprovalId } = this.props;
    return (
      <ReserveApprovalHistory
        processObjectId={reserveApprovalId}
        isHistory={false}
      />
    );
  }

  renderApprovalInfo() {
    const ApprovalInfo = ClaimCommonExt.getApprovalInfo();
    return (
      <ApprovalInfo
        decision={this.bindToState('approvalInfo', 'DecisionCode')}
        remark={this.bindToState('approvalInfo', 'Remark')}
        onClick={this.confirm.bind(this)}
      />
    );
  }

  render() {
    const _self = this;
    return (
      <div>
        <ClaimBaseInfo claimEntity={_self.props.claimEntity} />
        {this.renderCommon()}
        {this.renderApprovalInfo()}
        {this.renderApprovalHistory()}
      </div>
    );
  }

  handlerStoreChange() {
    this.setState(ReserveApprovalStore.getState());
    this.forceUpdate();
  }

  componentWillReceiveProps(nextProps) {
    const { reserveApprovalId } = this.props;
    if (
      reserveApprovalId &&
      reserveApprovalId !== nextProps.reserveApprovalId
    ) {
      this.state.approvalInfo = {
        DecisionCode: null,
        Remark: null,
      };
      ReserveAction.initReserveChanged(nextProps.reserveApprovalId);
    }
  }

  componentDidMount() {
    const { reserveApprovalId } = this.props;
    ReserveApprovalStore.listen(this.handlerStoreChange);
    ReserveAction.initReserveChanged(reserveApprovalId);
  }

  componentWillUnmount() {
    ReserveApprovalStore.unlisten(this.handlerStoreChange);
  }
}

ReactMixin.onClass(ReserveApproval, BindToMixin);
ReserveApproval.defaultProps = {};
ReserveApproval.propTypes = {
  a: PropTypes.node,
  renderCommon: PropTypes.function,
  commontype: PropTypes.oneOf(['update', 'closure']),
  d: PropTypes.bool,
  claimData: PropTypes.object,
  reserveApprovalId: PropTypes.array,
};
