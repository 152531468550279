import alt from '../alt';
import ClaimHandlingAction from '../action/ClaimHandlingAction';
import ClaimLitigationAction from '../action/ClaimLitigationAction';
import ClaimNoteAction from '../action/ClaimNoteAction';

class ClaimHandlingStore {
  constructor() {
    this.caseForm = {};

    this.bindListeners({
      handleInitHandling: [
        ClaimHandlingAction.INIT_HANDLING,
        ClaimHandlingAction.MODIFY_CLAIM_SUBMIT,
        ClaimHandlingAction.CANCEL_NEW_SUBCLAIM_HANDLING,
      ],
      handleUpdateClaimEntity: [
        ClaimHandlingAction.GET_CLAIM_INFO,
        ClaimHandlingAction.UPDATE_CLAIM_PARTY_LIST,
        ClaimHandlingAction.RETRIEVE_POLICY_COUNTINUE,
      ],
      handleModifySubclaimSubmit:
        ClaimHandlingAction.MODIFY_SUBCLAIM_SUBMIT,
      handleNewSubclaim: ClaimHandlingAction.NEW_SUBCLAIM,
      handleNewSubclaimSubmit:
        ClaimHandlingAction.NEW_SUBCLAIM_SUBMIT,
      changeStatus: ClaimHandlingAction.CHANGE_STATUS,
      handleLitigationFlag: [
        ClaimLitigationAction.SAVE,
        ClaimLitigationAction.REMOVE,
      ],
      handleFileNoteFlag: ClaimNoteAction.submitClaimNote,
      initClaimSummary: ClaimHandlingAction.initClaimSummary,
    });
  }

  handleNewSubclaim(tmpcaseForm) {
    const caseForm = tmpcaseForm;
    console.log(
      '---------------handleNewSubclaim---------------------',
      caseForm
    );
    console.log('-----1----handle', caseForm);
    let objectSize = 1;
    if (this.caseForm.ClaimData.ObjectDatas) {
      objectSize = this.caseForm.ClaimData.ObjectDatas.length + 1;
    }

    let seqNo = '';
    if (objectSize < 10) {
      seqNo = `00${objectSize}`;
    } else if (objectSize >= 10 && objectSize < 100) {
      seqNo = `0${objectSize}`;
    }

    if (caseForm) {
      // registration
      if (!caseForm.ClaimEntity.ObjectList) {
        caseForm.ClaimEntity.ObjectList = [];
      }
      if (!caseForm.ClaimData.ObjectDatas) {
        caseForm.ClaimData.ObjectDatas = [];
      }
      caseForm.ClaimEntity.ObjectList.push({
        '@type': 'ClaimObject-ClaimObject',
        ClaimParty: { '@type': 'ClaimParty-ClaimParty' },
        SeqNo: seqNo,
      });
      caseForm.ClaimData.ObjectDatas.push({
        IsActive: 'Y',
        Name: seqNo,
        newSubclaim: true,
      });
      this.caseForm = caseForm;
    } else if (
      this.caseForm.ClaimData.ObjectDatas[objectSize - 2].ObjectId
    ) {
      // after registration
      this.caseForm.ClaimEntity.ObjectList.push({
        '@type': 'ClaimObject-ClaimObject',
        ClaimParty: { '@type': 'ClaimParty-ClaimParty' },
        SeqNo: seqNo,
      });
      this.caseForm.ClaimData.ObjectDatas.push({
        IsActive: 'Y',
        Name: seqNo,
        newSubclaim: true,
      });
    }
  }

  handleNewSubclaimSubmit(datas) {
    const data = datas;
    console.log('-----2----handle', data);
    const subclaimIndex = Number(data.objectEntity.SeqNo) - 1;
    data.claimData.ObjectDatas[subclaimIndex].newSubclaim = true;
    data.claimData.reloadReserveByObjectId =
      data.objectEntity.ObjectId;

    this.caseForm.ClaimEntity.ObjectList[subclaimIndex] =
      data.objectEntity;
    this.caseForm.ClaimData = data.claimData;
  }

  handleLitigationFlag(datas) {
    const data = datas;
    console.log('-----7----handleLitigationFlag', data);
    const entityList = data.objectEntity;
    for (let i = 0; i < entityList.length; i += 1) {
      const subclaimIndex = Number(entityList[i].SeqNo) - 1;
      data.claimData.reloadReserveByObjectId = entityList[i].ObjectId;
      this.caseForm.ClaimEntity.ObjectList[subclaimIndex] =
        entityList[i];
    }
  }

  handleFileNoteFlag(flag) {
    console.log('-----8----handleFileNoteFlag', flag);
    this.caseForm.ClaimData.HasFileNotes = 'true';
  }

  handleInitHandling(caseForm) {
    console.log('-----3----handle', caseForm);
    this.caseForm = caseForm;
  }

  handleUpdateClaimEntity(data) {
    console.log('-----4---handle', data);
    console.log(
      '================ClaimHandlingStore======handleUpdateClaimEntity================'
    );
    this.caseForm.ClaimEntity = data;
  }

  handleModifySubclaimSubmit(objectEntity) {
    console.log('-----5----handle', objectEntity);
    console.log(
      '================ClaimHandlingStore======handleModifySubclaimSubmit================'
    );
    /* var colorStatus = ['UNOPEN','CLOSED' ,'REOPEN','OPEN'];
        var status = [0,0,0];
        if(objectEntity.ItemList){
            for(var i = 0; i < objectEntity.ItemList.length ;i++){
                for(var j = 0;j < colorStatus.length ;j++){
                    if(j > status[0] && colorStatus[j] == objectEntity.ItemList[i]['LossStatus'] ) status[0] = j;
                    if(j > status[1] && colorStatus[j] == objectEntity.ItemList[i]['SubrogationStatus'] )  status[1] = j;
                    if(j > status[2] && colorStatus[j] == objectEntity.ItemList[i]['SalvageStatus'])   status[2] = j;
                }
            }
        }
        status = [1,2,3];
        this.event = {
            eventType: 'ModifySubclaim' ,
            eventTarget: 'ReserveLight' ,
            data:{
                reserveLevel: 2 ,
                reserveStatus: status ,
            }
        } */

    const subclaimIndex = Number(objectEntity.SeqNo) - 1;
    this.caseForm.ClaimEntity.ObjectList[
      subclaimIndex
    ] = objectEntity;
    this.caseForm.ClaimData.reloadReserveByObjectId =
      objectEntity.ObjectId;
    this.caseForm.ClaimEntity.ClaimPartyList =
      objectEntity.ClaimantPartyList;
  }

  changeStatus(data) {
    console.log('-----6----handle', data);
    this.caseForm.ClaimData = data;
  }

  initClaimSummary(data) {
    this.caseForm.ClaimEntity = data;
  }
}

export default alt.createStore(
  ClaimHandlingStore,
  ClaimHandlingStore
);
