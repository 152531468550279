import {
  UISmartPanelGrid,
  UIBox,
  UIPanel,
  UIRadio,
  UIText,
  UIParam,
  UIButton,
  UIDialog,
} from 'RainbowUI';
import { PtyIntegrationCreationDialog } from 'EU00PartyCommonUI';

import BindToMixin from 'react-binding';

const CodeTableAction = require('../../common/action/CodeTableAction');
const CodeTableCstUI = require('../../common/constant/CodeTableCstUI');

const QueryCommonParty = React.createClass({
  mixins: [BindToMixin],

  getInitialState() {
    return { queryCommonPartyOptions: { PartyType: '1' } };
  },

  reset() {
    this.setState({ queryCommonPartyOptions: { PartyType: '1' } });
  },
  create() {
    UIDialog.show(`CreateParty${this.props.index}`);
  },

  search() {
    this.props.parentComponent.refs.QueryCommonPartyResult_ref.queryOptionsChange(
      this.state.queryCommonPartyOptions
    );
  },
  callback() {},
  render() {
    const _self = this;
    const { index } = this.props;
    return (
      <UIPanel
        panelTitle={i18n.ClaimParty.RoleInfo}
        styleClass="default"
      >
        <PtyIntegrationCreationDialog
          ptyIntegrationCreated={_self.props.parentComponent.callback}
          id={`CreateParty${index}`}
        />
        <UISmartPanelGrid>
          <UIRadio
            id={`searchVpartyTypeId${index}`}
            label={i18n.ClaimParty.IndOrOrgRadio}
            colspan="2"
            valueLink={this.bindToState(
              'queryCommonPartyOptions',
              'PartyType'
            )}
            validationGroup="addToClmPty"
            required="true"
            itemSpacing="4"
            codeTable={CodeTableAction.loadDataList(
              CodeTableCstUI.ClaimPartyType,
              null
            )}
          />

          <UIText
            id={`partyNameId${index}`}
            required="true"
            validationGroup="onSearch"
            label={i18n.ClaimParty.PartyName}
            valueLink={this.bindToState(
              'queryCommonPartyOptions',
              'PartyName'
            )}
          />

          <UIText
            id={`idNumberId${index}`}
            label={i18n.ClaimParty.IDNumberForSearch}
            valueLink={this.bindToState(
              'queryCommonPartyOptions',
              'IdNumber'
            )}
          />
        </UISmartPanelGrid>
        <UIBox direction="left">
          <UIButton
            value={i18n.ClaimCommon.Search}
            onClick={this.search}
            validationGroup="onSearch"
            causeValidation="true"
          >
            <UIParam name="index" value={index} />
          </UIButton>
          <UIButton
            value={i18n.ClaimCommon.Reset}
            onClick={this.reset}
          >
            <UIParam name="index" value={index} />
          </UIButton>
          <UIButton
            value={i18n.ClaimCommon.Create}
            onClick={this.create}
          >
            <UIParam name="index" value={index} />
          </UIButton>
        </UIBox>
      </UIPanel>
    );
  },
});
export default QueryCommonParty;
