import alt from '../alt';

const SalvageLoadAction = require('../action/SalvageLoadAction');

class SalvageReadOnlyStore {
  constructor() {
    this.SalvageReadOnly = {
      SalvageInfo: {
        SalvageItem: [],
      },
      OtherInfo: {
        CoverageStructure: [{ CoverageList: [] }],
      },
    };

    this.SalvageApprove = {
      SalvageInfo: {
        SalvageItem: [],
      },
      OtherInfo: {
        CoverageStructure: [{ CoverageList: [] }],
      },
    };

    this.bindListeners({
      handleLoadSalvageReadOnly:
        SalvageLoadAction.LOAD_SALVAGE_READ_ONLY,
    });
  }

  handleLoadSalvageReadOnly(response) {
    let updateObj = this.SalvageReadOnly;
    if (response.isApproveTask == true) {
      updateObj = this.SalvageApprove;
    }
    updateObj.OtherInfo.CoverageStructure =
      response.data.CoverageStructure;

    updateObj.SalvageInfo = response.data.SalvageInfo;
  }
}
export default alt.createStore(
  SalvageReadOnlyStore,
  SalvageReadOnlyStore
);
