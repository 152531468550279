import { UIDialog, UIButton, UIBox } from 'RainbowUI';
import React from 'react';
import PropTypes from 'prop-types';
import BindToMixin from 'react-binding';
import SettlementHistoryStore from '../store/SettlementHistoryStore';

const SettlementReadOnly = require('./SettlementReadOnly');

const SettlementReadOnlyDialog = React.createClass({
  mixins: [BindToMixin],
  propTypes: {
    settlementId: PropTypes.string,
  },

  getInitialState() {
    return {};
  },

  componentWillMount() {
    SettlementHistoryStore.listen(this.onStoreStateChange);
  },
  componentWillUnmount() {
    SettlementHistoryStore.unlisten(this.onStoreStateChange);
  },

  onStoreStateChange(storeState) {
    this.setState({ settleid: storeState.settleid });
  },
  onClose() {
    UIDialog.hide('SettlementReadOnlyDialog');
  },
  render() {
    const { settleid } = this.state;
    return (
      <UIDialog
        id="SettlementReadOnlyDialog"
        title={i18n.ClaimSettlement.SettlementHistoryDetail}
        width="90%"
        height="1000px"
        modal="false"
      >
        <SettlementReadOnly
          settlementId={settleid}
          isApprove={false}
        />
        <UIBox direction="center">
          <UIButton
            value={i18n.ClaimCommon.Back}
            onClick={this.onClose}
            position="right"
          />
        </UIBox>
      </UIDialog>
    );
  },
});
export default SettlementReadOnlyDialog;
