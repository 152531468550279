import { AjaxUtil, MessageHelper } from 'RainbowUI';
import {
  RestStatus,
  ClaimMessageForamtUtils,
} from 'ClaimCommonConstant';
import Url from '../url/Url';

export default {
  getReserverTypeList(pageindex, pagesize) {
    let result = {};
    AjaxUtil.doGet(
      `${Url.ClaimCommonReserveTypeLoad}?pageindex=${pageindex -
        1}&pagesize=${pagesize}`,
      {},
      {
        async: false,
        done(data) {
          result = data.Model;
        },
        fail() {
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
        },
      }
    );
    return {
      count: result.allClaimReserveTypeList,
      result: result.claimReserveTypeListByPage,
    };
  },

  saveReserveType(newdata) {
    const newReserveType = newdata;
    let result = 'false';
    AjaxUtil.doPost(Url.ClaimCommonReserveTypeSave, newReserveType, {
      async: false,
      done(data) {
        if (data.Status == RestStatus.OK) {
          result = 'true';
          MessageHelper.success(
            ClaimMessageForamtUtils.formatI18NMsg(
              i18n.ClaimCommon.SaveSuccess,
              i18n.ClaimProduct.ReserveType
            )
          );
        }
      },
      fail() {
        MessageHelper.error(
          i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
        );
      },
    });
    return result;
  },
};
