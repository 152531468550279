import { CodeTable, DataContext, CodeTableSorter } from 'RainbowUI';
import RemoteCodeTable from './RemoteCodeTable';
import CommonService from '../service/CommonService';
import ClaimCacheUI from '../constant/ClaimCacheUI';

const _ = require('underscore');

export default class CommonCodeTable {
  getBranch() {
    return RemoteCodeTable.CodeTableIdfromCache(-103);
  }

  getCurrencyCode() {
    return RemoteCodeTable.CodeTableCodefromCache(-104);
  }

  getAgentName() {
    return RemoteCodeTable.CodeTableIdfromCache(-105);
  }

  getAgentCode() {
    return RemoteCodeTable.AgentCodeCache(-105);
  }

  getCurrency() {
    return RemoteCodeTable.CodeTableIdfromCache(-104);
  }

  getUser() {
    return RemoteCodeTable.CodeTableCodefromCache(600026);
  }

  getPrintRecipientType() {
    return RemoteCodeTable.CodeTableCodefromCache(600175);
  }

  getPrintTemplate() {
    return RemoteCodeTable.CodeTableCodefromCache(73549777);
  }

  getLanguageType() {
    const codes = RemoteCodeTable.CodeTableCodefromCache(1108002);
    return codes;
  }

  getEndoType() {
    return RemoteCodeTable.EndoTypeCodeTableIdfromCache(600029);
  }

  getPolicyHolderCodeTable() {
    return RemoteCodeTable.CodeTableIdfromCache(8561208);
  }

  getPolicyProducer() {
    return RemoteCodeTable.CodeTableIdfromCache(73936681);
  }

  getSubClaimType() {
    return RemoteCodeTable.CodeTableIdfromCache(1007);
  }

  getDocumentType() {
    return RemoteCodeTable.CodeTableIdfromCache(73503842);
  }

  getTransType() {
    return RemoteCodeTable.CodeTableIdfromCache(600176);
  }

  getPaymentMethodForCollection() {
    return RemoteCodeTable.CodeTableIdfromCache(-304);
  }

  getCurrencyCode() {
    return RemoteCodeTable.CodeTableCodefromCache(-104);
  }

  getPolicyBussinessTypes() {
    return RemoteCodeTable.CodeTableIdfromCache(72927902);
  }

  getClaimTaskType() {
    return new CodeTable([
      { id: 'Settlement', text: 'Settlement' },
      { id: 'Salvage', text: 'Salvage' },
      { id: 'Subrogation', text: 'Subrogation' },
    ]);
  }

  getOverDueHistoryStatusCodeTable() {
    return new CodeTable([
      { id: '1', text: 'Waiting for handle' },
      { id: '2', text: 'No need handle' },
      { id: '3', text: 'Success' },
      { id: '4', text: 'Retry' },
      { id: '5', text: 'Fail' },
    ]);
  }

  getReceiptStatus() {
    return new CodeTable([
      { id: '1', text: 'Collected' },
      { id: '3', text: 'Reversed' },
      { id: '4', text: 'Refund' },
    ]);
  }

  getPrintStatus() {
    return RemoteCodeTable.CodeTableIdfromCache(78053109);
  }

  getPolicyStatus() {
    return new CodeTable([
      { id: 1, text: 'Not Effective' },
      { id: 2, text: 'In Force' },
      { id: 3, text: 'Terminated' },
    ]);
  }

  getSchedulePolicyStatus() {
    return new CodeTable([
      { id: 2, text: 'In Force' },
      { id: 3, text: 'Terminated' },
    ]);
  }

  getPrintFormat() {
    return new CodeTable([
      { id: '1', text: 'PDF' },
      { id: '2', text: 'WORD' },
    ]);
  }

  getYesNoOption() {
    return new CodeTable([
      { id: 1, text: 'Yes' },
      { id: 0, text: 'No' },
    ]);
  }

  getYesNoOptionTrueFalse() {
    return new CodeTable([
      { id: true, text: 'Yes' },
      { id: false, text: 'No' },
    ]);
  }

  getTemplateCategory() {
    return new CodeTable([
      { id: 1, text: 'Common' },
      { id: 2, text: 'Product Specific' },
    ]);
  }

  getRestrictName() {
    return new CodeTable([
      { id: 'RestrictNone', text: 'RestrictNone' },
      { id: 'RestrictSccessOnce', text: 'RestrictSccessOnce' },
      { id: 'RestrictRejectStage', text: 'RestrictRejectStage' },
      {
        id: 'RestrictCancelNonpayment',
        text: 'RestrictCancelNonpayment',
      },
      { id: 'RestrictSettledPaid', text: 'RestrictSettledPaid' },

      { id: 'RestrictRenewalIssue', text: 'RestrictRenewalIssue' },
      {
        id: 'RestrictEndoReinstatment',
        text: 'RestrictEndoReinstatment',
      },
      {
        id: 'RestrictCoverNoteIssue',
        text: 'RestrictCoverNoteIssue',
      },
      {
        id: 'RestrictEndoCancellation',
        text: 'RestrictEndoCancellation',
      },
      { id: 'RestrictProposalStage', text: 'RestrictProposalStage' },
      {
        id: 'RestrictAfterCalculated',
        text: 'RestrictAfterCalculated',
      },
    ]);
  }

  getApplyToInfo() {
    return new CodeTable([
      { id: 1, text: 'All' },
      { id: 0, text: 'Not For All' },
    ]);
  }

  getClaimPrintLevel() {
    return new CodeTable([
      { id: 1, text: 'Claim Level' },
      { id: 2, text: 'Sub-Claim Level' },
      { id: 3, text: 'Task Level' },
    ]);
  }

  getDocConfig() {
    return new CodeTable([
      { id: 'RenewIssue', text: 'Renewal issue auto generate' },
      { id: 'NBIssue', text: 'NB issue auto generate' },

      { id: 'QuotePage', text: 'Quotation document' },
      { id: 'PolicyPage', text: 'Policy document' },
      { id: 'EndoPage', text: 'Endocument document' },
      { id: 'RenewPage', text: 'Renewal document' },
      { id: 'GroupPage', text: 'Group documents' },

      { id: 'FinReceipt', text: 'Finance receipt' },
      { id: 'FinCheque', text: 'Finance cheque' },
      { id: 'FinOverdue', text: 'Finance overdue' },
      { id: 'FinOther', text: 'Finance other' },

      { id: 'ByCertificate', text: 'ByCertificate' },
    ]);
  }

  loadDataList(codeTableId, conditionMap, isChild) {
    const cacheId = `dataListById_${codeTableId}${isChild}${JSON.stringify(
      conditionMap
    )}`;
    const cache_codetable = ClaimCacheUI.CodeTableCache;

    if (cache_codetable.get(cacheId)) {
      return cache_codetable.get(cacheId);
    }

    let codeTableList = [];
    CommonService.loadDataList(codeTableId, conditionMap, false).then(
      dataList => {
        if (!dataList) new CodeTable([]);
        dataList.forEach((data, index) => {
          if (
            isChild &&
            data.ConditionFields &&
            data.ConditionFields.length > 0
          ) {
            data.ConditionFields.forEach((condition, index) => {
              codeTableList.push({
                id: data.Id,
                text: data.Description,
                Parent: condition.ParentId,
              });
            });
          } else {
            codeTableList.push({
              id: data.Id,
              text: data.Description,
            });
          }
        });

        if (codeTableId == 1834755 && codeTableList.length) {
          codeTableList = codeTableList.filter(
            codes => codes.id !== 'W'
          );
        }
        cache_codetable.put(cacheId, new CodeTable(codeTableList));
      },
      err => {
        if (err.status !== 200) {
          MessageHelper.error(
            err.statusText,
            i18n.PrintMessage.ErrorMsg.Error_0004.replace(
              '{0}',
              codeTableId
            )
          );
        }
      }
    );
    if (!codeTableList) {
      codeTableList = [];
    }
    return new CodeTable(codeTableList);
  }

  getProductNameCodeList() {
    const codeList = [];
    let data = DataContext.get('outputProductCodeNameList');
    if (!data) {
      data = CommonService.loadProductList();
      DataContext.put('outputProductCodeNameList', data);
    }
    const productNameList = [];
    for (let i = 0; i < data.length; i += 1) {
      if (
        !_.contains(
          productNameList,
          data[i].ProductMaster.ProductCode
        ) &&
        data[i].EffectiveFlag === 'Y' &&
        data[i].PackageSubProduct !== 'Y'
      ) {
        productNameList.push(data[i].ProductMaster.ProductCode);
        codeList.push({
          id: data[i].ProductId,
          text: `${data[i].ProductMaster.ProductCode}-${data[i].ProductMaster.ProductName}`,
        });
      }
    }
    return new CodeTable(codeList, null, new CodeTableSorter());
  }

  getInitialDataTypeList() {
    const codeList = [];
    let data = DataContext.get('getInitialDataTypeList');
    if (!data) {
      data = CommonService.getInitialDataTypeList();
      DataContext.put('getInitialDataTypeList', data);
    }
    for (let i = 0; i < data.length; i += 1) {
      codeList.push({ id: data[i].id, text: data[i].value });
    }
    return new CodeTable(codeList, null, new CodeTableSorter());
  }

  getGroupProductNameCodeList() {
    const codeList = [];
    let data = DataContext.get('outputProductCodeNameList');
    if (!data) {
      data = CommonService.loadProductList();
      DataContext.put('outputProductCodeNameList', data);
    }
    const productNameList = [];
    for (let i = 0; i < data.length; i += 1) {
      if (
        !_.contains(
          productNameList,
          data[i].ProductMaster.ProductCode
        ) &&
        data[i].EffectiveFlag === 'Y' &&
        data[i].PolicyWorkProcess == '3'
      ) {
        productNameList.push(data[i].ProductMaster.ProductCode);
        codeList.push({
          id: data[i].ProductId,
          text: `${data[i].ProductMaster.ProductCode}-${data[i].ProductMaster.ProductName}`,
        });
      }
    }
    return new CodeTable(codeList, null, new CodeTableSorter());
  }

  getProductLineList() {
    const codeList = [];
    let data = DataContext.get('outputProductLineList');
    if (!data) {
      data = CommonService.loadProductLineList();
      DataContext.put('outputProductLineList', data);
    }
    if (
      data.Model &&
      data.Model.claimProductLineListByPage &&
      data.Model.claimProductLineListByPage.length > 0
    ) {
      const { length } = data.Model.claimProductLineListByPage;
      for (let i = 0; i < length; i += 1) {
        const dataItem = data.Model.claimProductLineListByPage;
        codeList.push({
          id: dataItem[i].ProductLineCode,
          text: `${dataItem[i].ProductLineCode}-${dataItem[i].ProductLineName}`,
        });
      }
    }
    const productNameList = [];
    for (let i = 0; i < data.length; i += 1) {
      if (
        !_.contains(
          productNameList,
          data[i].ProductMaster.ProductCode
        ) &&
        data[i].EffectiveFlag === 'Y'
      ) {
        productNameList.push(data[i].ProductMaster.ProductCode);
        codeList.push({
          id: data[i].ProductCode,
          text: `${data[i].ProductMaster.ProductCode}-${data[i].ProductMaster.ProductName}`,
        });
      }
    }
    return new CodeTable(codeList, null, new CodeTableSorter());
  }

  getProductCodeList() {
    const codeList = [];
    let data = DataContext.get('outputProductCodeNameList');
    if (!data) {
      data = CommonService.loadProductList();
      DataContext.put('outputProductCodeNameList', data);
    }
    const productNameList = [];
    for (let i = 0; i < data.length; i += 1) {
      if (
        !_.contains(
          productNameList,
          data[i].ProductMaster.ProductCode
        ) &&
        data[i].EffectiveFlag === 'Y'
      ) {
        productNameList.push(data[i].ProductMaster.ProductCode);
        codeList.push({
          id: data[i].ProductMaster.ProductCode,
          text: data[i].ProductMaster.ProductCode,
        });
      }
    }
    return new CodeTable(codeList, null, new CodeTableSorter());
  }

  getXSDSchemaList() {
    const schemaCodeTableList = [];
    const schemaList = CommonService.queryAllSchema();
    if (schemaList && schemaList.length > 0) {
      schemaList.forEach(data => {
        schemaCodeTableList.push({ id: data.Id, text: data.Name });
      });
    }
    return new CodeTable(schemaCodeTableList);
  }

  getSubClaimTypeByProductCategory(productCategory) {
    const codeList = [];
    const data = CommonService.getSubClaimTypeByProductCategory(
      productCategory
    );
    if (data.Model) {
      if (data.Model.Content && data.Model.Content.length > 0) {
        data.Model.Content.forEach(data => {
          codeList.push({
            id: data.SubclaimTypeCode,
            text: data.SubclaimTypeCode + data.SubclaimTypeName,
          });
        });
      }
    }
    return new CodeTable(codeList, null, new CodeTableSorter());
  }
}
