import ClaimNoticeExt from 'ClaimNoticeExt';
import alt from '../alt';

const NoticePoolAction = ClaimNoticeExt.getNoticePoolAction();

class NoticePoolStore {
  constructor() {
    this.noticeSearchOptions = {};

    this.bindListeners({
      handleSearchNoticePool: NoticePoolAction.SEARCH_NOTICE,
    });
  }

  handleSearchNoticePool(noticeSearchOptions) {
    this.noticeSearchOptions = noticeSearchOptions;
  }
}
export default alt.createStore(NoticePoolStore, NoticePoolStore);
