import { UIPage } from 'RainbowUI';

import { CodeTableAction, CodeTableCstUI } from 'ClaimCommonConstant';
import TaskOwnerSearch from './TaskOwnerSearch';
import TaskOwnerResult from './TaskOwnerResult';

const MassiveReassignmentTaskOwner = React.createClass({
  render() {
    return (
      <UIPage>
        <TaskOwnerSearch />
        <TaskOwnerResult ref="TaskOwnerResult" />
      </UIPage>
    );
  },
});
export default MassiveReassignmentTaskOwner;
