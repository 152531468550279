import {
  UIDataTable,
  UIColumn,
  UIDateTimePicker,
  UIText,
} from 'RainbowUI';
import BindToMixin from 'react-binding';

const ApprovalHistoryAction = require('../action/ApprovalHistoryAction');
const ApprovalStore = require('../store/ApprovalStore');
const CodeTableAction = require('../action/CodeTableAction');
const CodeTableCstUI = require('../constant/CodeTableCstUI');

const ApprovalHistory = React.createClass({
  mixins: [BindToMixin],

  getInitialState() {
    return {
      approvelist: {},
    };
  },

  onStoreStateChange(storeState) {
    if (!_.isEmpty(storeState.approvelist)) {
      this.setState({ approvelist: storeState.approvelist });
    }
  },

  componentWillMount() {
    ApprovalStore.listen(this.onStoreStateChange);
    ApprovalHistoryAction.initData(this.props.processObjectId);
  },

  componentWillReceiveProps(nextProps) {
    if (this.props.processObjectId !== nextProps.processObjectId) {
      ApprovalHistoryAction.initData(nextProps.processObjectId);
    }
  },
  componentWillUnmount() {
    ApprovalStore.unlisten(this.onStoreStateChange);
  },
  removeNegativeSign(datas) {
    let data = datas;
    data = data.replace('EUR', '€');
    data = data.replace('USD', '$');
    data = data.replace('CNY', '¥');
    return data;
  },

  render() {
    const _self = this;
    const { approvelist } = this.state;
    const { history } = this.props;
    const data = approvelist;
    const componentId = _self.props.componentId || '';
    const approveCode = CodeTableAction.loadDataList(
      CodeTableCstUI.ClaimApproveDecison,
      null
    );
    const approvalDecision = approveCode.codes;
    const nameCode = CodeTableAction.loadDataList(
      CodeTableCstUI.ClaimUser,
      null
    );
    let style;
    if (history) {
      style = 'default';
    } else {
      style = 'primary';
    }
    return (
      <UIDataTable
        indexable="false"
        styleClass={style}
        id={`approvalhistory_table${componentId}`}
        value={data}
        pageable="false"
        isHeading="true"
        headerTitle={i18n.ClaimCommon.ApprovalHistory}
        toggleable="true"
      >
        <UIColumn
          headerTitle={i18n.ClaimCommon.ApprovalDate}
          value="Update"
          width="20%"
        >
          <UIDateTimePicker format="MM/DD/YYYY HH:mm:ss" io="out" />
        </UIColumn>
        <UIColumn
          headerTitle={i18n.ClaimCommon.ApprovalName}
          value="ActionName"
          width="20%"
          render={data => {
            const names = nameCode.codes;
            const approver = data.Approver;
            let name;
            for (let i = 0; i < names.length; i += 1) {
              if (names[i].id == approver) {
                name = names[i].text;
              }
            }
            return <UIText io="out" value={name} />;
          }}
        />
        <UIColumn
          headerTitle={i18n.ClaimCommon.ApprovalSummay}
          value="ProcessSummary"
          width="20%"
          render={data => {
            return (
              <UIText
                io="out"
                value={_self.removeNegativeSign(data.ProcessSummary)}
              />
            );
          }}
        />
        <UIColumn
          headerTitle={i18n.ClaimCommon.ApprovalDecision}
          value="DecisionCode"
          width="20%"
          render={data => {
            const decisionCode = `${data.DecisionCode} `.trim();
            let decision;
            for (let i = 0; i < approvalDecision.length; i += 1) {
              if (decisionCode == approvalDecision[i].id) {
                decision = approvalDecision[i].text;
              }
            }
            return <UIText io="out" value={decision} />;
          }}
        />
        <UIColumn
          headerTitle={i18n.ClaimCommon.ApprovalRemark}
          value="Remark"
          width="20%"
          render={data => {
            return <UIText io="out" value={data.Remark} />;
          }}
        />
      </UIDataTable>
    );
  },
});
export default ApprovalHistory;
