import {
  CodeTable,
  MessageHelper,
  UICheckbox,
  UIParam,
  UISelect,
  UILink,
  UIColumn,
  UIDataTable,
  UIPanel,
  UIText,
  DataContext,
  UINumber,
} from 'RainbowUI';

import DefinedCodeTable from '../codetable/CodeTable';
import PartyAddressInfoDialog from './PartyAddressInfoDialog';
import PartyAddressInfoDialogReadOnly from './readonly/views/PartyAddressInfoDialogReadOnly';
import PartyConst from '../const/PartyConst';
import PartyAction from '../actions/PartyAction';

const PartyAddressInfo = React.createClass({
  getInitialState() {
    let { party } = this.props;
    if (DataContext.get('Party')) {
      party = DataContext.get('Party');
    }

    let { AddressVoList } = party;
    if (!AddressVoList) {
      AddressVoList = [];
    }
    const countryCodeTable = DefinedCodeTable.getCountry();
    const statusCodeTable = DefinedCodeTable.getPartyStatus();
    const addressTypeCodeTable = DefinedCodeTable.getAddressType();

    const regionCodeTable = new CodeTable([]);
    return {
      AddressVoList,
      party,
      countryCodeTable,
      statusCodeTable,
      addressTypeCodeTable,
      regionCodeTable,
    };
  },
  createAddressHandler() {
    const createPartyAddress = {
      '@type': 'PartyAddress-PartyAddress',
    };
    this.refs.partyAddressInfoDialog.onShowPartyAddressInfoDialog(
      createPartyAddress,
      'create',
      this.state.regionCodeTable
    );
  },

  editAddressHandler() {
    const selectData = UIDataTable.getSelectedRecord(
      'partyAddressInfo'
    );
    if (!selectData.length) {
      MessageHelper.warning(
        i18n.PartyMsg.WarnMsg.Warn_0005,
        i18n.PartyMsg.WarnMsg.Warn_0003,
        MessageHelper.POSITION_TOP_RIGHT
      );
      return;
    }
    const partyAddress = selectData[0];
    this.refs.partyAddressInfoDialog.onShowPartyAddressInfoDialog(
      partyAddress,
      'edit',
      this.state.regionCodeTable
    );
  },
  viewHandler(event) {
    const partyAddress = event.getParameter('data');
    this.refs.partyAddressInfoDialogReadOnly.onShowPartyAddressInfoDialogReadOnly(
      partyAddress,
      'view',
      this.state.regionCodeTable
    );
  },

  render() {
    const _self = this;
    const { AddressVoList, statusCodeTable, party } = this.state;
    const { parentDialog, readOnly } = this.props;
    return (
      <div>
        <UIPanel panelTitle={i18n.AddressDetail}>
          <UIDataTable
            id="partyAddressInfo"
            value={AddressVoList}
            functions={_self.buildTableFunction()}
            indexable="false"
          >
            <UIColumn selectionMode="single" />
            <UIColumn
              headerTitle={i18n.CorrespondenceAddress}
              width="20%"
              render={data => {
                return (
                  <div className="dochis">
                    <UICheckbox
                      single="true"
                      model={data}
                      property="IsPrimaryAddress"
                      disabled={data.AddressStatus == '2'}
                      onChange={_self.handlePrimaryAddress}
                      enabled={!readOnly}
                    />
                  </div>
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.AddressType}
              value="AddressType"
              render={data => {
                const val = _self.convertAddressType(
                  data.AddressType
                );
                return (
                  <div>
                    <UILink
                      value={val}
                      enabled="true"
                      disabled="false"
                      onClick={this.viewHandler}
                    >
                      <UIParam name="data" value={data} />
                    </UILink>
                  </div>
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.StreetName}
              render={data => {
                if (
                  _self.state.regionCodeTable.map[data.AddressLine1]
                ) {
                  return (
                    <div>
                      <UISelect
                        codeTable={_self.state.regionCodeTable}
                        value={data.AddressLine1}
                        io="out"
                      />
                    </div>
                  );
                }
                return (
                  <div>
                    <UIText value={data.AddressLine1} io="out" />
                  </div>
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.Area}
              render={data => {
                if (_self.state.regionCodeTable.map[data.State]) {
                  return (
                    <div>
                      <UISelect
                        codeTable={_self.state.regionCodeTable}
                        value={data.State}
                        io="out"
                      />
                    </div>
                  );
                }
                return (
                  <div>
                    <UIText value={data.State} io="out" />
                  </div>
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.DistrictOrCity}
              render={data => {
                if (_self.state.regionCodeTable.map[data.City]) {
                  return (
                    <div>
                      <UISelect
                        codeTable={_self.state.regionCodeTable}
                        value={data.City}
                        io="out"
                      />
                    </div>
                  );
                }
                return (
                  <div>
                    <UIText value={data.City} io="out" />
                  </div>
                );
              }}
            />

            <UIColumn
              headerTitle={i18n.Country}
              render={data => {
                if (!data.Country) {
                  return (
                    <div>
                      <UISelect
                        defaultValue="US"
                        codeTable={_self.state.countryCodeTable}
                        io="out"
                      />
                    </div>
                  );
                }
                return (
                  <div>
                    <UISelect
                      value={data.Country}
                      codeTable={_self.state.countryCodeTable}
                      io="out"
                    />
                  </div>
                );
              }}
            />

            {/* <UIColumn headerTitle={i18n.Country} value="CountryCode">
                            <UISelect codeTable={this.state.countryCodeTable} io="out" />
                        </UIColumn> */}

            <UINumber
              headerTitle={i18n.PostalCode}
              render={data => {
                if (_self.state.regionCodeTable.map[data.PostCode]) {
                  return (
                    <div>
                      <UISelect
                        codeTable={_self.state.regionCodeTable}
                        value={data.PostCode}
                        io="out"
                      />
                    </div>
                  );
                }
                return (
                  <div>
                    <UIText value={data.PostCode} io="out" />
                  </div>
                );
              }}
            />
            <UIColumn headerTitle={i18n.Status} value="AddressStatus">
              <UISelect codeTable={statusCodeTable} io="out" />
            </UIColumn>
          </UIDataTable>
        </UIPanel>
        <PartyAddressInfoDialog
          ref="partyAddressInfoDialog"
          parentComponet={this}
          parentDialog={parentDialog}
          party={party}
        />
        <PartyAddressInfoDialogReadOnly
          ref="partyAddressInfoDialogReadOnly"
          parentComponet={this}
          party={party}
        />
      </div>
    );
  },

  buildTableFunction() {
    const _self = this;
    const { isQuery } = this.props;
    return (
      <div>
        <UILink
          icon="fa fa-plus"
          size="lg"
          iconClass="add_iconbtn"
          tooltip={i18n.add}
          visibled={!isQuery}
          onClick={_self.createAddressHandler}
          validationGroup="CustomerOrgAddress"
          causeValidation="true"
        />
        <UILink
          icon="fa fa-pencil"
          size="lg"
          iconClass="edit_iconbtn"
          tooltip={i18n.edit}
          visibled={!isQuery}
          onClick={_self.editAddressHandler}
        />
      </div>
    );
  },

  updatePanel(temp) {
    const party = temp;
    const partyAction = new PartyAction();
    const copyParty = partyAction.loadPartyByPartyId(party.PartyId);
    party.PartyAccountList = copyParty.PartyAccountList;
    party.PartyContactList = copyParty.PartyContactList;
    const result = this.props.submitInfoHandler(party, 2);
    if (!(PartyConst.FAIL == result)) {
      this.setState({
        party: result,
        AddressVoList: result.AddressVoList,
      });
      return result;
    }
    return result;
  },
  handlePrimaryAddress(data) {
    const { AddressVoList } = this.state;
    _.each(AddressVoList, temp => {
      const partyAddress = temp;
      if (partyAddress.IsPrimaryAddress === 'Y') {
        partyAddress.IsPrimaryAddress = 'N';
      }
    });
    const pk = data.component.props.model['@pk'];
    if (data.newValue === 'Y') {
      _.each(AddressVoList, temp => {
        const partyAddress = temp;
        if (partyAddress['@pk'] == pk) {
          partyAddress.IsPrimaryAddress = 'Y';
        }
      });
    }
    this.props.party.AddressVoList = AddressVoList;
    DataContext.get('Party').AddressVoList = AddressVoList;
    this.setState({
      AddressVoList,
    });
  },
  convertAddressType(addressType) {
    const addressCodeTable = this.state.addressTypeCodeTable;
    let text = '';
    _.each(addressCodeTable.codes, obj => {
      if (obj.id == addressType) {
        text = obj.text;
      }
    });
    return text;
  },
});

export default PartyAddressInfo;
