import alt from '../alt';
import ClaimWorkFlowLeftAction from '../action/desktop/ClaimWorkFlowLeftAction';

class ConditionStore {
  constructor() {
    this.condition = {};
    this.conditionPool = {};

    this.bindListeners({
      handleCondition: ClaimWorkFlowLeftAction.INIT_CONDITION,
      // handlePage:ClaimWorkFlowLeftAction.PAGE_CHANGE
    });
  }

  handleCondition(tmpcondition) {
    const condition = tmpcondition;
    console.log('ConditionStore', condition);
    if (condition.flag === 'pendingTask') {
      this.condition = condition;
      delete condition.flag;
    } else if (condition.flag === 'sharePool') {
      delete condition.isUpdate;
      this.conditionPool = condition;
    }
  }
  /* handlePage(page){
         console.log("ClaimWorkFlowLeftAction===>>handlePage");
    	this.page = page;
    } */
}

export default alt.createStore(ConditionStore, ConditionStore);
