export default {
  '@type': 'ClaimPolicy-ClaimPolicy',
  OrganId: '1',
  PolicyNo: 'Q15123400031679',
  ProductCode: '204',
  ProductTypeCode: 'FCL',
  EffDate: '2015-02-01T00:00:00',
  ExpDate: '2016-02-03T00:00:00',
  ClaimsMade: 'N',

  Premium: 5000.0,
  PremiumIsPaid: 'Y',
  CurrencyCode: 'USD',
  InsuredList: [
    {
      '@type': 'ClaimPolicyInsured-ClaimPolicyInsured',
      InsuredName: 'G324593T Jackie An',
      SumInsured: 500000.0,
      InsuredCategory: 2,
      InsuredUid: 0,
      CoverageList: [
        {
          '@type': 'ClaimPolicyCoverage-ClaimPolicyCoverage',
          CoverageName: 'Accidental Death & Disablement Benefits',
          DeductibleAmount: 500,
          DeductibleRate: 0.0,
          SumInsured: 200000,
          PolicySumInsured: 500000.0,
          PolicyDeductibleRate: 0.0,
          PolicyDeductibleAmount: 0.0,
          CoverageCode: 'TEST_code',
          ProductCode: '204',
        },
        {
          '@type': 'ClaimPolicyCoverage-ClaimPolicyCoverage',
          CoverageName: 'Delay of Flight Benefits',
          DeductibleAmount: 0,
          DeductibleRate: 0.0,
          SumInsured: 400,
          PolicySumInsured: 500000.0,
          PolicyDeductibleRate: 0.0,
          PolicyDeductibleAmount: 0.0,
          CoverageCode: 'CI_47600',
          ProductCode: '204',
        },
        {
          '@type': 'ClaimPolicyCoverage-ClaimPolicyCoverage',
          CoverageName: 'Travel Delay Benefits',
          DeductibleAmount: 0,
          DeductibleRate: 0.0,
          SumInsured: 600,
          PolicySumInsured: 500000.0,
          PolicyDeductibleRate: 0.0,
          PolicyDeductibleAmount: 0.0,
          CoverageCode: 'CI_47811',
          ProductCode: '204',
        },
        {
          '@type': 'ClaimPolicyCoverage-ClaimPolicyCoverage',
          CoverageName: 'Loss of Baggage Benefits',
          DeductibleAmount: 0,
          DeductibleRate: 0.0,
          SumInsured: 1000,
          PolicySumInsured: 500000.0,
          PolicyDeductibleRate: 0.0,
          PolicyDeductibleAmount: 0.0,
          CoverageCode: 'CI_47585',
          ProductCode: '204',
        },
        {
          '@type': 'ClaimPolicyCoverage-ClaimPolicyCoverage',
          CoverageName: 'Replacement of Travel Document Benefits',
          DeductibleAmount: 0,
          DeductibleRate: 0.0,
          SumInsured: 1000,
          PolicySumInsured: 500000.0,
          PolicyDeductibleRate: 0.0,
          PolicyDeductibleAmount: 0.0,
          CoverageCode: 'CI_47587',
          ProductCode: '204',
        },
        {
          '@type': 'ClaimPolicyCoverage-ClaimPolicyCoverage',
          CoverageName: 'Delay or Loss of Baggage Benefits',
          DeductibleAmount: 0,
          DeductibleRate: 0.0,
          SumInsured: 1000,
          PolicySumInsured: 500000.0,
          PolicyDeductibleRate: 0.0,
          PolicyDeductibleAmount: 0.0,
          CoverageCode: 'CI_47589',
          ProductCode: '204',
        },
      ],
    },
  ],
  PartyList: [
    {
      '@type': 'ClaimPolicyParty-ClaimPolicyParty',
      RoleType: '07',
      PartyName: 'Stella Tang',
      ContactFirstName: 'Stella',
      ContactLastName: 'Stella Tang',
      ContactName: 'Stella Tang',
      ContactTelephone: '118093034233479',
      IdType: '1',
      IdNumber: 'MockPolicy3209223454563443279',
      ContactAddress: 'shaghai in  china',
      ContactType: '1',
      CommonPartyId: 39242367,
    },
    {
      '@type': 'ClaimPolicyParty-ClaimPolicyParty',
      RoleType: '08',
      PartyName: 'Fengying Ye',
      ContactFirstName: 'Fengying',
      ContactLastName: 'Ye',
      ContactName: 'Fengying Ye',
      ContactTelephone: '115261511579',
      IdType: '4',
      IdNumber: 'MockPolicy3209223454563443277',
      ContactAddress: 'wuxi',
      ContactType: '2',
      CommonPartyId: 36981386,
    },
    {
      '@type': 'ClaimPolicyParty-ClaimPolicyParty',
      RoleType: '09',
      PartyName: 'Mickle Zhang',
      ContactFirstName: 'Mickle',
      ContactLastName: 'Zhang',
      ContactName: 'Mickle Zhang',
      ContactTelephone: '130193293534376',
      IdType: '7',
      IdNumber: 'MockPolicy3209223454563443276',
      ContactAddress: 'yancheng',
      ContactType: '1',
      CommonPartyId: 38813405,
    },
    {
      '@type': 'ClaimPolicyParty-ClaimPolicyParty',
      RoleType: '09',
      PartyName: 'Test Zhang',
      ContactFirstName: 'Test',
      ContactLastName: 'Zhang',
      ContactName: 'PartyRole is insured',
      ContactTelephone: '130193293534379',
      IdType: '1',
      IdNumber: 'MockPolicy3209223454563443275',
      ContactAddress: 'yancheng',
      ContactType: '2',
      CommonPartyId: 39396364,
    },
  ],
};
