import { MessageHelper, AjaxUtil } from 'RainbowUI';
import Url from '../constant/SubrogationUrl';

const Q = require('q');

class SubrogationService {
  postPromise(url, payload, message) {
    const deferred = Q.defer();
    const entity = payload || {};
    AjaxUtil.doPost(url, entity, {
      async: false,
      done(data) {
        deferred.resolve(data);
        if (data.Status === 'OK') {
          MessageHelper.success(message);
        } else {
          MessageHelper.error(
            data.Messages[0].Message,
            i18n.ClaimSubrogation.ErrorMsg.Error_0001
          );
        }
      },
      fail(err) {
        deferred.reject(err);
        MessageHelper.error(
          err,
          i18n.ClaimSubrogation.ErrorMsg.Error_0001
        );
      },
    });
    return deferred.promise;
  }

  saveSubrogation(subrogationData) {
    const url = Url.subrogationSave;
    return this.postPromise(
      url,
      {
        SubrogationEntity: subrogationData.SubrogationInfo,
        TaskInstanceId: subrogationData.TaskInstanceId,
      },
      'Subrogation is saved successfully'
    );
  }

  submitSubrogation(subrogationData) {
    const url = Url.subrogationSubmit;
    let result = null;
    AjaxUtil.doPost(
      url,
      {
        SubrogationEntity: subrogationData.SubrogationInfo,
        TaskInstanceId: subrogationData.TaskInstanceId,
      },
      {
        async: false,
        done(data) {
          if (data.Status === 'OK') {
            result = data.Status;
          } else {
            result = data;
          }
        },
        fail(jqXHR) {
          result = jqXHR.responseText;
        },
      }
    );
    return result;
  }

  loadSubrogationDatal(taskInstanceId) {
    let returnVal = null;
    const url = Url.subrogationLoad + taskInstanceId;
    AjaxUtil.doGet(
      url,
      {},
      {
        async: false,
        done(data) {
          if (data.Status === 'OK') {
            returnVal = data.Model;
          }
        },
        fail() {
          MessageHelper.error(
            '',
            i18n.ClaimSubrogation.ErrorMsg.Error_0002
          );
        },
      }
    );
    return returnVal;
  }

  loadSubrogationReadOnly(subrogationId) {
    const deferred = $.Deferred();
    if (env.IS_MOCK) {
      deferred.resolve(SubrogationMockData.SubrogationData);
      return deferred.promise();
    }
    AjaxUtil.doGet(
      Url.subrogationReadOnlyLoad + subrogationId,
      null,
      {
        async: true,
        done(data) {
          if (data.Status !== 'OK') {
            deferred.reject(data);
          } else {
            deferred.resolve(data.Model);
          }
        },
        fail(jqXHR) {
          deferred.reject({
            status: jqXHR.status,
            responseText: jqXHR.responseText,
          });
        },
      }
    );
    return deferred.promise();
  }

  loadSubrogationHistory(caseId) {
    let returnVal = null;
    const url = Url.subrogationHistoryLoad + caseId;
    AjaxUtil.doGet(
      url,
      {},
      {
        async: false,
        done(data) {
          if (data.Status === 'OK') {
            returnVal = data.Model;
          }
        },
        fail() {
          MessageHelper.error(
            '',
            i18n.ClaimSubrogation.ErrorMsg.Error_0002
          );
        },
      }
    );
    return returnVal;
  }

  loadApprovalSubrogationHistory(subrogationId) {
    let returnVal = null;
    const url = Url.loadApprovalSubrogationHistory + subrogationId;
    AjaxUtil.doGet(
      url,
      {},
      {
        async: false,
        done(data) {
          if (data.Status === 'OK') {
            returnVal = data.Model;
          }
        },
        fail() {
          MessageHelper.error(
            '',
            i18n.ClaimSubrogation.ErrorMsg.Error_0002
          );
        },
      }
    );
    return returnVal;
  }

  loadApproveTask(taskInstanceId) {
    let returnVal = null;
    const url = Url.subrogationApproveLoad + taskInstanceId;
    AjaxUtil.doGet(
      url,
      {},
      {
        async: false,
        done(data) {
          if (data.Status === 'OK') {
            returnVal = data.Model;
          }
        },
        fail() {
          MessageHelper.error(
            '',
            i18n.ClaimSubrogation.ErrorMsg.Error_0002
          );
        },
      }
    );
    return returnVal;
  }

  approveSubrogation(approveInfo) {
    const defer = $.Deferred();
    AjaxUtil.doPost(Url.subrogationApprove, approveInfo, {
      async: false,
      done(data) {
        defer.resolve(data);
      },
      fail(jqXHR) {
        defer.reject({
          status: jqXHR.status,
          responseText: jqXHR.responseText,
        });
      },
    });
    return defer.promise();
  }

  convertLoadSubrogationData2Positive(TempsubrogationData) {
    let subrogationDatas = TempsubrogationData;
    subrogationDatas = subrogationDatas.filter(data => {
      const subrogationData = data;
      if (
        subrogationData.TotalAmount &&
        subrogationData.TotalAmount < 0
      ) {
        subrogationData.TotalAmount *= -1;
      }
      subrogationData.SubrogationItems = subrogationData.SubrogationItems.filter(
        Temp => {
          const temp = Temp;
          if (temp.Amount && temp.Amount < 0) {
            temp.Amount *= -1;
          }
          return temp;
        }
      );
      return subrogationData;
    });
    return subrogationDatas;
  }

  convertLoadFullSubrogationData2Positive(tmp) {
    const fullSubrogationData = tmp;
    if (fullSubrogationData.SubrogationInfo) {
      if (
        fullSubrogationData.SubrogationInfo.TotalAmount &&
        fullSubrogationData.SubrogationInfo.TotalAmount < 0
      ) {
        fullSubrogationData.SubrogationInfo.TotalAmount *= -1;
      }
      if (fullSubrogationData.SubrogationInfo.SubrogationItems) {
        fullSubrogationData.SubrogationInfo.SubrogationItems = fullSubrogationData.SubrogationInfo.SubrogationItems.filter(
          Temp => {
            const temp = Temp;
            if (temp.Amount && temp.Amount < 0) {
              temp.Amount *= -1;
            }
            return temp;
          }
        );
      }
    }
    return fullSubrogationData;
  }

  convertSaveFullSubrogationData2Negative(tempfullSubrogationData) {
    const fullSubrogationData = tempfullSubrogationData;
    if (fullSubrogationData.SubrogationInfo) {
      if (
        fullSubrogationData.SubrogationInfo.TotalAmount &&
        fullSubrogationData.SubrogationInfo.TotalAmount > 0
      ) {
        fullSubrogationData.SubrogationInfo.TotalAmount *= -1;
      }
      if (fullSubrogationData.SubrogationInfo.SubrogationItems) {
        fullSubrogationData.SubrogationInfo.SubrogationItems = fullSubrogationData.SubrogationInfo.SubrogationItems.filter(
          Temp => {
            const temp = Temp;
            if (temp.Amount && temp.Amount > 0) {
              temp.Amount *= -1;
            }
            return temp;
          }
        );
      }
    }
    return fullSubrogationData;
  }
}

const SubrogationServiceInstance = new SubrogationService();
export default SubrogationServiceInstance;
