import { CodeTable, MessageHelper, Caller } from 'RainbowUI';
import {
  ClaimCstUI,
  ClaimMessageForamtUtils,
  CodeTableAction,
  CodeTableCstUI,
} from 'ClaimCommonConstant';
import ClaimCommonExt from 'ClaimCommonExt';
import { PartyAction } from 'EU00PartyCommonUI';
import alt from '../alt';

const noticeService = require('../service/NoticeService');

const PolicyAction = ClaimCommonExt.getPolicyAction();
let noticeId = 0;
class NoticeOfLossAction {
  initNotice() {
    if (this.actions.isEditNotice()) {
      noticeService.loadNotice(noticeId).then(tmpnoticeEntity => {
        const noticeEntity = tmpnoticeEntity;
        if (noticeEntity.PolicyNo) {
          noticeEntity.PolicyEntity = PolicyAction.copyPolicy(noticeEntity);
        }
        this.dispatch(noticeEntity);
      });
    }
  }

  loadRtAccidentInfo(accidentId) {
    return noticeService.loadRtaAccident(accidentId);
  }

  isEditNotice() {
    const urlParam = window.location.hash;
    const begin = urlParam.indexOf('?');
    const paramStr = urlParam.substr(begin + 1);
    const paramArray = paramStr.split('&');
    for (let i = 0; i < paramArray.length; i += 1) {
      if (paramArray[i].split('=')[0] === 'noticeId') {
        noticeId = paramArray[i].split('=')[1];
        break;
      }
    }
    return noticeId !== 0;
  }

  getCodeTableClaimant(damageList, policyEntity) {
    const claimantList = [];
    const commonPartyIds = [];
    if (damageList) {
      damageList.forEach(data => {
        if (data.dataIndex == data.ContactRefId) {
          commonPartyIds.push(data.CommonPartyId);
          if (data.ContactName !== '') {
            claimantList.push({
              id: data.dataIndex,
              text: data.ContactName,
            });
          }
        }
      });
    }

    if (policyEntity && policyEntity.PartyList) {
      policyEntity.PartyList.forEach(data => {
        if (commonPartyIds.indexOf(data.CommonPartyId) == -1) {
          commonPartyIds.push(data.CommonPartyId);
          if (data.RoleType !== '11') {
            claimantList.push({
              id: `H${data.CommonPartyId}`,
              text: data.PartyName,
            });
          }
        }
      });
    }
    const sort = _.sortBy(claimantList, n => {
      return n.text;
    });
    sort.push({ id: -1, text: '*New Claimant' });

    return new CodeTable(sort);
  }

  saveNotice(noticeEntity) {
    const result = this.actions.handleNoticeEntity(noticeEntity);
    if (result) {
      if (result.Status == ClaimCstUI.REST_RESTPONSE_STATUS_OK) {
        this.dispatch(result.Model);
        MessageHelper.success(
          ClaimMessageForamtUtils.formatI18NMsg(
            i18n.ClaimCommon.SaveSuccess,
            i18n.ClaimCommon.NewNoiceofLoss
          )
        );
      } else if (
        result.Status == ClaimCstUI.REST_RESTPONSE_STATUS_WARN
      ) {
        MessageHelper.error(
          '',
          i18n.ClaimCommon.CheckNoticeVersionMessage
        );
      }
    }
  }

  isMotor(noticeEntity) {
    let productTypeCode;
    let result;
    try {
      productTypeCode = noticeEntity.props.model.ProductTypeCode;
    } catch (err) {
      if (err.name === 'TypeError') {
        productTypeCode = noticeEntity.ProductTypeCode;
      }
    }

    if (productTypeCode) {
      Caller.doService(noticeService.isMotor(productTypeCode)).then(
        data => {
          result = data;
        },
        ex => {
          MessageHelper.error(JSON.stringify(ex), '');
        }
      );
    }
    return result;
  }

  createCodeTable(tmpnoticeEntity, onEvent, keepFieldValue) {
    const noticeEntity = tmpnoticeEntity;
    if (noticeEntity) {
      let accidentCity = noticeEntity.AccidentCity;
      let accidentTown = noticeEntity.AccidentTown;
      let accidentStreet = noticeEntity.AccidentStreet;
      let postalcode = noticeEntity.PostalCode;

      const { tempCodeTable } = noticeEntity;
      let { cityCodeTable } = noticeEntity;
      let { townCodeTable } = noticeEntity;
      let { streetCodeTable } = noticeEntity;
      let { postalCodeTable } = noticeEntity;
      const partyAction = new PartyAction();
      let { regions } = noticeEntity;
      if (!regions || regions[0].RegionName !== accidentCity) {
        regions = partyAction.getRegionByCountryCodeAndRegionName(
          accidentCountryCode,
          accidentCity
        );
        if (undefined !== regions) {
          noticeEntity.regions = regions;
        } else {
          return noticeEntity;
        }
      }

      let weight = -1;
      if (!accidentCountryCode) {
        weight = 5;
      } else if (!accidentCity || onEvent === 'changeCountry') {
        weight = 4;
      } else if (!accidentTown || onEvent === 'changeCity') {
        weight = 3;
      } else if (!accidentStreet || onEvent === 'changeTown') {
        weight = 2;
      } else if (!postalcode || onEvent === 'changeStreet') {
        weight = 1;
      } else if (postalcode && onEvent === 'changePostalCode') {
        weight = 0;
      }

      switch (weight) {
        case 1: {
          const postalCodeList = [];
          const postalCodeListTemp = new Set();

          for (let i = 0; i < regions.length; i += 1) {
            if (regions[i].StreetName == accidentStreet) {
              postalCodeListTemp.add(regions[i].PostCode);
            }
          }

          postalCodeListTemp.forEach(item => {
            postalCodeList.push({ id: item, text: item });
          });
          postalCodeTable = new CodeTable(postalCodeList);
          postalcode = null;
          break;
        }
        case 2: {
          const streetList = [];
          const streetListTemp = new Set();

          for (let i = 0; i < regions.length; i += 1) {
            if (regions[i].Area == accidentTown) {
              streetListTemp.add(regions[i].StreetName);
            }
          }

          streetListTemp.forEach(item => {
            streetList.push({ id: item, text: item });
          });
          streetCodeTable = new CodeTable(streetList);

          postalCodeTable = tempCodeTable;
          postalcode = null;
          accidentStreet = null;

          break;
        }
        case 3: {
          const townList = [];
          const townListTemp = new Set();

          for (let i = 0; i < regions.length; i += 1) {
            if (regions[i].Area) {
              townListTemp.add(regions[i].Area);
            }
          }

          townListTemp.forEach(item => {
            townList.push({ id: item, text: item });
          });
          townCodeTable = new CodeTable(townList);

          streetCodeTable = tempCodeTable;
          postalCodeTable = tempCodeTable;

          postalcode = null;
          accidentStreet = null;
          accidentTown = null;
          break;
        }
        case 4:
          if (accidentCountryCode === 'US') {
            cityCodeTable = CodeTableAction.loadDataList(
              CodeTableCstUI.ClaimPartyCity,
              null
            );
          } else {
            cityCodeTable = tempCodeTable;
          }

          townCodeTable = tempCodeTable;
          streetCodeTable = tempCodeTable;
          postalCodeTable = tempCodeTable;

          postalcode = null;
          accidentStreet = null;
          accidentTown = null;
          accidentCity = null;
          break;

        case 5:
        default:
          break;
      }
      if (!keepFieldValue) {
        noticeEntity.AccidentCity = accidentCity;
        noticeEntity.AccidentTown = accidentTown;
        noticeEntity.AccidentStreet = accidentStreet;
        noticeEntity.PostalCode = postalcode;
      }

      noticeEntity.cityCodeTable = cityCodeTable;
      noticeEntity.townCodeTable = townCodeTable;
      noticeEntity.streetCodeTable = streetCodeTable;
      noticeEntity.postalCodeTable = postalCodeTable;
    }
    return noticeEntity;
  }

  submitNotice(noticeEntity) {
    let caseId = null;
    let processInstanceId = null;
    const result = this.actions.handleNoticeEntity(noticeEntity);

    if (result) {
      if (ClaimCstUI.REST_RESTPONSE_STATUS_OK == result.Status) {
        caseId = result.Model.CaseIds[0];
        processInstanceId = result.Model.ProcessInstanceId;
        MessageHelper.success(
          ClaimMessageForamtUtils.formatI18NMsg(
            i18n.ClaimCommon.SubmitSuccess,
            i18n.ClaimCommon.NewNoiceofLoss
          )
        );
      } else if (
        ClaimCstUI.REST_RESTPONSE_STATUS_WARN == result.Status
      ) {
        MessageHelper.error(
          '',
          i18n.ClaimCommon.CheckNoticeVersionMessage
        );
      }
    }

    return { CaseId: caseId, ProcessInstanceId: processInstanceId };
  }

  rejectSubmit(tmpnoticeEntity) {
    const noticeEntity = tmpnoticeEntity;
    noticeEntity.NoticeStatus = 'REJECT';
    delete noticeEntity.NoticeTime;
    const result = this.actions.handleNoticeEntity(noticeEntity);

    if (result) {
      if (ClaimCstUI.REST_RESTPONSE_STATUS_OK == result.Status) {
        window.close();
      } else if (
        ClaimCstUI.REST_RESTPONSE_STATUS_WARN == result.Status
      ) {
        MessageHelper.error(
          '',
          i18n.ClaimCommon.CheckNoticeVersionMessage
        );
      }
    }
  }

  handleNoticeEntity(tmpnoticeEntity) {
    const noticeEntity = tmpnoticeEntity;
    let result;
    noticeEntity.AccidentCountryCode = noticeEntity.CountryCode;
    noticeEntity.AccidentCity = noticeEntity.City;
    noticeEntity.AccidentTown = noticeEntity.Area;
    noticeEntity.AccidentStreet = noticeEntity.StreetName;
    noticeEntity.PostalCode = noticeEntity.PostCode;
    delete noticeEntity.CountryCode;
    delete noticeEntity.City;
    delete noticeEntity.Area;
    delete noticeEntity.StreetName;
    delete noticeEntity.PostCode;
    const serviceCall = noticeEntity.NoticeId ? noticeService.updateNotice : noticeService.createNotice;

    Caller.doService(serviceCall(noticeEntity)).then(
      data => {
        result = data;
      },
      ex => {
        MessageHelper.error(JSON.stringify(ex), '');
      }
    );

    if (result.Status === ClaimCstUI.REST_RESTPONSE_STATUS_ERROR) {
      MessageHelper.error(result.Messages[0].Message, '');
      return;
    }
    result.Model.CountryCode = result.Model.AccidentCountryCode;
    result.Model.City = result.Model.AccidentCity;
    result.Model.Area = result.Model.AccidentTown;
    result.Model.StreetName = result.Model.AccidentStreet;
    result.Model.PostCode = result.Model.PostalCode;
    noticeEntity.CountryCode = result.Model.AccidentCountryCode;
    noticeEntity.City = result.Model.AccidentCity;
    noticeEntity.Area = result.Model.AccidentTown;
    noticeEntity.StreetName = result.Model.AccidentStreet;
    noticeEntity.PostCode = result.Model.PostalCode;
    delete noticeEntity.AccidentCountryCode;
    delete noticeEntity.AccidentCity;
    delete noticeEntity.AccidentTown;
    delete noticeEntity.AccidentStreet;
    delete noticeEntity.PostalCode;
    delete result.Model.AccidentCountryCode;
    delete result.Model.AccidentCity;
    delete result.Model.AccidentTown;
    delete result.Model.AccidentStreet;
    delete result.Model.PostalCode;
    return result;
  }

  copyDamageContact(damage, damageContact, needCopy) {
    if (damage.ContactRefId == damage.dataIndex || needCopy) {
      return this.actions.copyData(damage, damageContact);
    }
    return damage;
  }

  copyDamageContactFromPartyList(tmpdamageContact, policyEntity) {
    let damageContact = tmpdamageContact;
    let partyRole = '';
    let commonFlag = 0;
    const _self = this;
    policyEntity.PartyList.forEach(data => {
      if (damageContact.CommonPartyId == data.CommonPartyId) {
        if (commonFlag == 0) {
          commonFlag += 1;
          damageContact = _self.actions.copyData(damageContact, data);
          partyRole = data.RoleType;
          damageContact.ContactRole = partyRole;
        } else {
          partyRole = `${partyRole}_${data.RoleType}`;
        }
      } else if (
        !damageContact.CommonPartyId ||
        damageContact.CommonPartyId === 'undefined'
      ) {
        if (
          !damageContact.ContactFirstName ||
          damageContact.ContactFirstName === 'undefined'
        ) {
          if (damageContact.ContactName) {
            const contactName = damageContact.ContactName;
            contactName.replace(/(^\s*)|(\s*$)/g, '');
            let subindex = 0;
            const len = contactName.length;
            for (let i = 0; i < contactName.length; i += 1) {
              if (contactName.charAt(i) === ' ') {
                subindex = i;
              }
            }

            damageContact.ContactFirstName = contactName.substring(
              0,
              subindex
            );
            damageContact.ContactLastName = contactName.substring(
              subindex + 1,
              len
            );
          }
        }
      }
    });

    damageContact.PartyRole = partyRole;
    return damageContact;
  }

  copyDamageContactFromPartyListWhenNoChange(
    damageList,
    policyEntity
  ) {
    const self = this;

    damageList.forEach(data => {
      if (data.dataIndex == data.ContactRefId) {
        const damage = data;
        let hasCommon = false;
        policyEntity.PartyList.forEach(data => {
          if (
            damage.CommonPartyId &&
            damage.CommonPartyId == data.CommonPartyId
          ) {
            hasCommon = true;
          } else if (!damage.CommonPartyId) {
            hasCommon = true;
          }
        });

        if (hasCommon) {
          /* empty */
        } else {
          damageList.forEach(data => {
            if (damage.ContactRefId == data.ContactRefId) {
              self.actions.resetData(data);
            }
          });
        }
      }
    });

    return damageList;
  }

  submitCheckClaimantChanged(damageList, policyEntity) {
    if (damageList) {
      damageList.forEach(data => {
        if (data.ContactRefId == data.dataIndex) {
          const damage = data;
          if (damage.CommonPartyId) {
            if (policyEntity.PartyList) {
              policyEntity.PartyList.forEach(data => {
                if (data.CommonPartyId == damage.CommonPartyId) {
                  if (data.ContactAddress !== damage.ContactAddress) {
                    damage.EditContactAddress = true;
                  }
                  if (
                    data.ContactTelephone !== damage.ContactTelephone
                  ) {
                    damage.EditContactTelephone = true;
                  }
                  if (data.ContactEmail !== damage.ContactEmail) {
                    damage.EditContactEmail = true;
                  }
                }
              });
            }
          } else {
            damage.EditContactAddress = true;
            damage.EditContactTelephone = true;
            damage.EditContactEmail = true;
          }
        }
      });
    }
    if (policyEntity.PartyList) {
      policyEntity.PartyList.map(datas => {
        const data = datas;
        delete data.dataIndex;
        return data;
      });
    }
    return damageList;
  }

  submitCheckClaimant(damageList) {
    let nullClaimant = true;
    if (damageList) {
      damageList.forEach(data => {
        if (data.dataIndex == data.ContactRefId) {
          if (!data.ContactName) {
            nullClaimant = false;
          }
        }
      });
    }

    if (!nullClaimant) {
      MessageHelper.error(i18n.ClaimCommon.NoClaimantError, null);
    }
    return nullClaimant;
  }

  resetData(tmpdamage) {
    const damage = tmpdamage;
    damage.ContactType = null;
    damage.ContactFirstName = null;
    damage.ContactLastName = null;
    damage.ContactName = '';
    damage.ContactTelephone = null;
    damage.IdType = null;
    damage.IdNumber = null;
    damage.ContactRole = null;
    damage.ContactCountryCode = null;

    damage.ContactEmail = null;
    damage.ContactAddress = null;
    damage.CommonPartyId = null;
    damage.PartyRole = null;
    return damage;
  }

  copyData(tmpdamage, damageContact) {
    const damage = tmpdamage;
    damage.ContactType = damageContact.ContactType;
    damage.ContactFirstName = damageContact.ContactFirstName;
    damage.ContactLastName = damageContact.ContactLastName;
    damage.ContactName =
      damageContact.PartyName || damageContact.ContactName;
    damage.ContactTelephone = damageContact.ContactTelephone;
    damage.IdType = damageContact.IdType;
    damage.IdNumber = damageContact.IdNumber;
    damage.ContactRole = damageContact.ContactRole;
    damage.ContactCountryCode = damageContact.ContactCountryCode;

    let emailC = damageContact.ContactEmail;
    if (!emailC) {
      emailC = damageContact.Email;
    }
    damage.ContactEmail = emailC;
    damage.ContactAddress = damageContact.ContactAddress;
    damage.CommonPartyId = damageContact.CommonPartyId;
    damage.PartyRole = damageContact.PartyRole;
    return damage;
  }

  transferDamageContact(
    damageList,
    damageContact,
    parentDataIndex,
    isDelete
  ) {
    let newRefId;
    const self = this;
    $.each(damageList, index => {
      let data = damageList[index];
      if (
        data.ContactRefId == parentDataIndex &&
        parentDataIndex !== data.dataIndex
      ) {
        newRefId = data.dataIndex;
        data = self.actions.copyDamageContact(
          data,
          damageContact,
          true
        );
        return false;
      }
    });

    $(damageList).each((index, datas) => {
      const data = datas;
      if (data.ContactRefId == parentDataIndex) {
        data.ContactRefId = newRefId;
      }
      if (isDelete && index > parentDataIndex) {
        data.ContactRefId -= 1;
      }
    });
  }

  cancelNotice() {
    window.close();
  }

  showDamageListContactName(damageList) {
    if (damageList) {
      damageList.map(datas => {
        const data = datas;
        if (data) {
          if (data.ContactRefId) {
            data.ContactName =
              damageList[data.ContactRefId].ContactName;
          }
        }
        return data;
      });
    }
    return damageList;
  }

  getCurrentUserInfo() {
    let result = null;
    noticeService.getCurrentUserInfo().then(
      data => {
        result = data;
      },
      () => { }
    );

    return result;
  }

  getBranchCodetable() {
    let result = [];
    noticeService.getBranchData().then(
      data => {
        if (data && data.ElementsInCurrentPage) {
          for (let i = 0; i < data.ElementsInCurrentPage.length; i++) {
            result.push({ id: data.ElementsInCurrentPage[i].PartyId, text: data.ElementsInCurrentPage[i].PartyName });
          }
        }
      },
      () => { }
    );
    return new CodeTable(result);
  }
}
export default alt.createActions(NoticeOfLossAction);
