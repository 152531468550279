import React from 'react';
import {
  UIPanel,
  UIDialog,
  UIBox,
  UIDataTable,
  UIColumn,
  UIRadio,
  DataContext,
  UISmartPanelGrid,
  UILink,
  UIText,
  UICheckbox,
} from 'RainbowUI';
import urlConfig from '../../../UrlConfig';
import CommonCodeTable from '../codetable/CodeTable';
import AddDataBreachDialog from './AddDataBreachDialog';
import PartyAction from '../actions/PartyAction';
import UploadDataBreachDocumentDialog from './UploadDataBreachDocumentDialog';

const PartyGDPR = React.createClass({
  getInitialState() {
    let { party } = this.props;
    if (DataContext.get('Party')) {
      party = DataContext.get('Party');
    }
    let PartyGDPRDataBreachList = [];
    if (party.PartyGDPR && party.PartyGDPR.PartyGDPRDataBreachList) {
      PartyGDPRDataBreachList =
        party.PartyGDPR.PartyGDPRDataBreachList;
    }
    const checkBoxDatas = [];
    if (
      party.PartyGDPR &&
      party.PartyGDPR.PartyGDPRDataAccess.RestricationOfProcessing ==
      'Y'
    ) {
      checkBoxDatas.push('1');
    }
    if (
      party.PartyGDPR &&
      party.PartyGDPR.PartyGDPRDataAccess.DataPortabilityRequest ==
      'Y'
    ) {
      checkBoxDatas.push('2');
    }
    if (
      party.PartyGDPR &&
      party.PartyGDPR.PartyGDPRDataAccess.DataRectificationRequest ==
      'Y'
    ) {
      checkBoxDatas.push('3');
    }
    if (
      party.PartyGDPR &&
      party.PartyGDPR.PartyGDPRDataAccess.RightOfAccessRequest === 'Y'
    ) {
      checkBoxDatas.push('4');
    }
    if (
      party.PartyGDPR &&
      party.PartyGDPR.PartyGDPRDataAccess.RightToErasure === 'Y'
    ) {
      checkBoxDatas.push('5');
    }
    DataContext.get(
      'DataAccessRequestCategory'
    ).checkBoxDatas = checkBoxDatas;
    let renderCheckBox = 'false';
    if (
      party.PartyGDPR &&
      party.PartyGDPR.PartyGDPRDataAccess.RequestByDataSubject === 'Y'
    ) {
      renderCheckBox = 'true';
    }
    DataContext.put('isRender', 'true');

    return {
      party,
      PartyGDPRDataBreachList,
      renderCheckBox,
      renderUI: true,
    };
  },

  componentWillMount() { },

  componentDidMount() {
    this.props.getChildMethod(this.renderUI.bind(this));
  },

  render() {
    const self = this;
    const { party } = this.props;
    return (
      <div>
        {self.renderNote()}
        {self.renderConsentInfo()}
        {self.renderContactInfo()}
        {self.renderMinorIndicator()}
        {self.renderDataAccess()}
        {self.renderDataBreach()}
        {self.renderPrivacyNotice()}
        <UIDialog
          id="addDataBreach"
          title={i18n.DataBreach}
          heigh="400px"
          width="1000px"
        >
          <AddDataBreachDialog
            party={party}
            submitHandler={this.savePartyGDPRDataBreach}
          />
        </UIDialog>
        <UIDialog
          id="uploadDataBreachDocument"
          title={i18n.UploadDataBreachDocument}
          heigh="600px"
          width="1000px"
        >
          <UploadDataBreachDocumentDialog
            renderDataBreachDatas={self.renderDataBreachDatas.bind(
              this
            )}
          />
        </UIDialog>
      </div>
    );
  },

  renderNote() {
    return (
      <UIPanel panelTitle={i18n.Note}>
        <UIText label={i18n.AddNotes} value="#{PartyGDPR.AddNotes}" />
        <UIText
          label={i18n.Declaration}
          value="#{PartyGDPR.Declaration}"
        />
      </UIPanel>
    );
  },

  renderConsentInfo() {
    return (
      <UIPanel panelTitle={i18n.ConsentInfo}>
        <UIRadio
          label={i18n.Didyouconsent}
          value="#{PartyGDPR.Consent}"
          required="true"
          validationGroup="UpdateBasicInfo"
          codeTable={CommonCodeTable.getGDPR}
          defaultValue="N"
        />
      </UIPanel>
    );
  },

  renderContactInfo() {
    return (
      <UIPanel panelTitle={i18n.ContactInfo}>
        <UIRadio
          label={i18n.DoNotSendEmail}
          value="#{PartyGDPR.SendEmail}"
          required="true"
          validationGroup="UpdateBasicInfo"
          codeTable={CommonCodeTable.getGDPR}
          defaultValue="N"
        />
        <UIRadio
          label={i18n.DoNotSendSMS}
          value="#{PartyGDPR.SendSMS}"
          required="true"
          validationGroup="UpdateBasicInfo"
          codeTable={CommonCodeTable.getGDPR}
          defaultValue="N"
        />
        <UIRadio
          label={i18n.ContactForMarketing}
          value="#{PartyGDPR.ContactMarketing}"
          required="true"
          validationGroup="UpdateBasicInfo"
          codeTable={CommonCodeTable.getGDPR}
          defaultValue="N"
        />
      </UIPanel>
    );
  },

  renderMinorIndicator() {
    if (this.state.party.IsOrgParty === 'N') {
      return (
        <UIPanel panelTitle={i18n.MinorIndicator}>
          <UIRadio
            label={i18n.IsThisPartyaMinor}
            value="#{PartyGDPR.IsMinor}"
            required="true"
            validationGroup="UpdateBasicInfo"
            codeTable={CommonCodeTable.getGDPR}
            defaultValue="N"
          />
        </UIPanel>
      );
    }
  },

  renderDataAccess() {
    const { renderCheckBox } = this.state;

    return (
      <UIPanel panelTitle={i18n.DataAccess}>
        <UISmartPanelGrid column="2">
          <UIRadio
            label={i18n.DataAccessRequestByDataSubject}
            onChange={this.changeRequestByDataSubject.bind(this)}
            value="#{PartyGDPRDataAccess.RequestByDataSubject}"
            codeTable={CommonCodeTable.getGDPR}
            defaultValue="N"
          />
        </UISmartPanelGrid>
        <UISmartPanelGrid column="2">
          <UICheckbox
            label={i18n.DataAccessRequestCategory}
            required={renderCheckBox}
            validationGroup="UpdateBasicInfo"
            value="#{DataAccessRequestCategory.checkBoxDatas}"
            codeTable={CommonCodeTable.getDataAccessRequestCategory}
          />
        </UISmartPanelGrid>
      </UIPanel>
    );
  },

  renderDataBreach() {
    const { PartyGDPRDataBreachList } = this.state;
    return (
      <UIPanel panelTitle={i18n.DataBreach}>
        <UIBox direction="right">
          <UILink
            direction="right"
            onClick={this.showAddDataBreach.bind(this)}
            icon="fa fa-plus"
            size="lg"
            tooltip={i18n.Add}
            iconClass="add_iconbtn"
          />
        </UIBox>
        <UIDataTable id="DataBreach" value={PartyGDPRDataBreachList}>
          <UIColumn
            headerTitle={i18n.BreachIndicator}
            value="BreachIndicator"
          />
          <UIColumn
            headerTitle={i18n.BreachDate}
            value="BreachDate"
          />
          <UIColumn
            headerTitle={i18n.BreachComments}
            value="BreachComments"
          />
          <UIColumn
            headerTitle={i18n.Action}
            render={data => {
              if (data.BreachFilePath) {
                return (
                  <div>
                    <UILink
                      icon="fa fa-upload"
                      onClick={this.showUploadDialog.bind(this, data)}
                    />
                    <UILink
                      icon="fa fa-download"
                      onClick={this.downloadFile.bind(this, data)}
                    />
                  </div>
                );
              }
              return (
                <div>
                  <UILink
                    icon="fa fa-upload"
                    onClick={this.showUploadDialog.bind(this, data)}
                  />
                </div>
              );
            }}
          />
        </UIDataTable>
      </UIPanel>
    );
  },

  renderPrivacyNotice() {
    return (
      <UIPanel panelTitle={i18n.PrivacyNotice}>
        <UIRadio
          label={i18n.PrivacyNoticeSend}
          required="true"
          validationGroup="UpdateBasicInfo"
          value="#{PartyGDPR.PrivacyNoticeSend}"
          codeTable={CommonCodeTable.getGDPR}
          enabled="false"
        />
      </UIPanel>
    );
  },

  showAddDataBreach() {
    DataContext.put('PartyDataBreach', {
      '@type': 'PartyGDPRDataBreach-PartyGDPRDataBreach',
      BusinessObjectId: 75699063,
    });
    UIDialog.show('addDataBreach');
  },

  showUploadDialog(data) {
    DataContext.put('uploadUrlParam', data.DataBreachId);
    UIDialog.show('uploadDataBreachDocument');
  },

  downloadFile(data) {
    const url = `${urlConfig.downloadFile}/${data.DataBreachId}`;
    const token = localStorage.getItem('Authorization');
    window.location.href = `${url}?${token}`;
  },

  savePartyGDPRDataBreach(partyGDPRDataBreach) {
    const { PartyGDPRDataBreachList } = this.state;
    PartyGDPRDataBreachList.push(partyGDPRDataBreach);
    const { party } = this.state;
    party.PartyGDPR.PartyGDPRDataBreachList = PartyGDPRDataBreachList;
    const newPartyHistory = {
      '@type': 'PartyHistory-PartyHistory',
      BusinessObjectId: 75158352,
      HistoryType: 15,
    };
    party.PartyHistoryList.push(newPartyHistory);
    const partyAction = new PartyAction();
    const partyResult = partyAction.loadPartyByPartyId(party.PartyId);
    const resultList = partyResult.PartyGDPR.PartyGDPRDataBreachList;
    DataContext.get('PartyGDPR').PartyGDPRDataBreachList = resultList;
    this.props.getParty(partyResult);
    this.setState({
      party: partyResult,
      PartyGDPRDataBreachList: resultList,
    });
    UIDialog.hide('addDataBreach');
  },

  changeRequestByDataSubject() {
    if (
      DataContext.get('PartyGDPRDataAccess').RequestByDataSubject ==
      'Y'
    ) {
      this.setState({ renderCheckBox: 'true' });
    } else {
      this.setState({ renderCheckBox: 'false' });
    }
  },

  renderUI() {
    this.setState(prevState => ({ renderUI: !prevState.renderUI }));
  },

  renderDataBreachDatas(datas) {
    this.setState({ PartyGDPRDataBreachList: datas });
  },
});
export default PartyGDPR;
