export default {
  Subrogation: 'Subrogation',
  SubrogationApproval: 'Subrogation Approval',
  SelectCoverage: 'Please select Coverage for Subrogation',

  SeqNo: 'Seq No.',
  TransactionDate: 'Transaction Date',
  OperatedBy: 'Operated By',
  TotalSubrogationAmount: 'Total Subrogation Amount',
  Remark: 'Remark',
  ApprovedBy: 'Approved By',
  ApprovalDecision: 'Approval Decision',

  SubrogationHistoryDetail: 'Subrogation History Detail',
  SubrogationHistory: 'Subrogation History',
  ApprovalHistory: 'Approval History',

  RecoverFrom: 'Recover From',
  SubrogationAmount: 'Subrogation Amount',
  LimitationDate: 'Limitation Date',
  RemarkForSubrogation: 'Remark for Subrogation',
  OverdueDate: 'Overdue Date',

  PleaseSelect: 'Please Select',
  Selected: ' Selected',
  SubrogationSubmitSuccess:
    'All subrogation tasks need to be sent for senior approval',
  SubrogationApprovalSubmitSuccess:
    'Subrogation is approved successfully',
  SubrogationRejectSubmitSuccess:
    'Subrogation is rejected successfully',
  SubrogationPanelTitle: 'Subrogation Detail',
  SubrogationSubClaimTotalAmount: 'Total Amount',

  ErrorMsg: {
    Error_0001: 'Fail',
    Error_0002: 'failed',
  },
  WarnMsg: {
    Warn_0001: "The Subrogation Item can't be empty.",
  },
  ConfirmMsg: {},
  SuccessMsg: {},
  InfoMsg: {},
};
