/**
 * Created by jie.huang on 12/24/2017.
 */
import { UIAutoComplete, DataContext } from 'RainbowUI';
import PropTypes from 'prop-types';

export default class EU00AutoComplete extends UIAutoComplete {
  getInputValue(event) {
    if (this.props.DataContextName) {
      DataContext.put(this.props.DataContextName, event.target.value);
    }
    return this.codeTable.getKey(event.target.value);
  }
}

EU00AutoComplete.propTypes = $.extend({}, UIAutoComplete.propTypes, {
  dropDown: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  acceptInput: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  limit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});

EU00AutoComplete.defaultProps = $.extend(
  {},
  UIAutoComplete.defaultProps,
  {
    dropDown: false,
    acceptInput: false,
  }
);
