import { CodeTable } from 'RainbowUI';
import { ClaimHandlingCommonAction } from 'ClaimCommon';

function CascaderCreator() {
  function Cascader() {}
  Cascader.prototype = {
    constructor: Cascader,
    initSubclaimTypeCode(insuredId, productCode) {
      const codeTableSubclaimType = ClaimHandlingCommonAction.filterSubclaimType(
        this.props.data.CodeTableSubclaimType,
        this.state.currentLossCause
      );
      let result = null;
      const ultCodeTabSub = new CodeTable([]);
      if (codeTableSubclaimType) {
        for (
          let i = 0;
          i < codeTableSubclaimType.codes.length;
          i += 1
        ) {
          const subclaimType = codeTableSubclaimType.codes[i].id;
          result = ClaimHandlingCommonAction.hasCoverageList(
            subclaimType,
            insuredId,
            productCode
          );
          if (result && result.length > 0) {
            ultCodeTabSub.codes.push(codeTableSubclaimType.codes[i]);
            ultCodeTabSub.map[codeTableSubclaimType.codes[i].id] =
              codeTableSubclaimType.codes[i];
            result = null;
          }
        }
      }
      return ultCodeTabSub;
    },
    cascaderSubclaim(event) {
      const n = event.newValue;
      const subclaimChangeEvent = {};
      subclaimChangeEvent.oldValue = this.state.objectEntity.SubclaimType;
      this.state.objectEntity.SubclaimType = null;
      if (!n) {
        this.state.codeTableSubclaimType = new CodeTable([]);
      } else {
        const insuredId = n;
        const productCode = this.props.data.ProductCode;
        const ultCodeTabSub = this.initSubclaimTypeCode(
          insuredId,
          productCode
        );
        this.state.codeTableSubclaimType = ultCodeTabSub;
      }
      subclaimChangeEvent.newValue = null;

      this.cascaderCoverage(subclaimChangeEvent);
      this.forceUpdate();
    },
    cascaderCoverage(event) {
      const n = event.newValue;
      const o = event.oldValue;
      if (n !== o) this.state.objectEntity.PolicyCoverageList = [];
    },
  };
  return Cascader;
}

function exactProto(cascader) {
  const proto = {};
  for (const key in cascader.prototype) {
    if (key !== 'constructor') proto[key] = cascader.prototype[key];
  }
  return proto;
}

const Cascader = exactProto(CascaderCreator());
export default Cascader;
