import {
  UIDialog,
  UISmartPanelGrid,
  MessageHelper,
  UICurrency,
  UISelect,
  UIButton,
  UICell,
  UIBox,
} from 'RainbowUI';
import Binder from 'react-binding';
import JCCPaymentAction from '../../action/JCCPaymentAction';
import DefineCodeTable from '../../../party/codetable/CodeTable';

const PaymentAmountDialog = React.createClass({
  getInitialState() {
    return { paymentLog: {} };
  },
  componentWillMount() {},
  onshowDialog(maxPremium, minAmount, paymentLog) {
    this.state.maxPremium = Number(maxPremium).toFixed(2);
    this.state.minAmount = Number(minAmount).toFixed(2);
    this.state.paymentLog = paymentLog;
    this.state.currencySign = window.EU00.getCurrencySignByCurrencyCode(
      paymentLog.Currency
    );
    this.setState();
    UIDialog.show('paymentAmountDialog');
  },
  renderMinMessage() {
    const { minAmount, currencySign } = this.state;
    if (minAmount === 0) {
      return <div />;
    }
    const minMessage = `The Minimum Payment Amount is ${currencySign} ${minAmount}.`;
    return (
      <div>
        <UICell
          style={{
            'font-size': '16',
            color: 'black',
            'border-radius': '6px',
          }}
        >
          {minMessage}
        </UICell>
      </div>
    );
  },
  getPaymentChannel() {
    return DefineCodeTable.getCodeTable(1506);
  },
  renderPaymentMethod() {
    const { paymentLog, currencySign } = this.state;
    const { id } = this.props;
    if (paymentLog.FeeIds && paymentLog.RequestSystem === 'PA') {
      return (
        <div>
          <UISmartPanelGrid>
            <UISelect
              label={i18n.PaymentChannel}
              disabled="true"
              id={`s_${id}`}
              value={paymentLog.PaymentMethod}
              codeTable={this.getPaymentChannel()}
            />
            <UICurrency
              label={i18n.PaymentAmount}
              valueLink={Binder.bindToState(
                this,
                'paymentLog',
                'PurchaseAmt'
              )}
              unit={currencySign}
              onChange={this.onChange.bind(this)}
              disabled="false"
              enabled="true"
              validationGroup="submitAmount"
            />
          </UISmartPanelGrid>
        </div>
      );
    }
    return (
      <div>
        <UICurrency
          label={i18n.PaymentAmount}
          valueLink={Binder.bindToState(
            this,
            'paymentLog',
            'PurchaseAmt'
          )}
          unit={currencySign}
          onChange={this.onChange.bind(this)}
          disabled="false"
          enabled="true"
          validationGroup="submitAmount"
        />
      </div>
    );
  },
  render() {
    const { currencySign, maxPremium } = this.state;
    const maxmessage = `Warning: Total OutStanding Premium amount is ${currencySign} ${maxPremium}.`;
    return (
      <div>
        <UIDialog
          id="paymentAmountDialog"
          title={i18n.paymentAmountTitle}
        >
          <UICell
            style={{
              'font-size': '16',
              color: 'black',
              'border-radius': '6px',
            }}
          >
            {maxmessage}
          </UICell>
          {this.renderMinMessage()}
          {this.renderPaymentMethod()}
          <UIBox direction="left">
            <UIButton
              value={i18n.SUBMIT}
              onClick={this.onsubmit.bind(this)}
              disabled="false"
              enabled="true"
              validationGroup="submitAmount"
            />
            <UIButton
              value={i18n.Cancel}
              onClick={this.onCancel.bind(this)}
              disabled="false"
              enabled="true"
            />
          </UIBox>
        </UIDialog>
      </div>
    );
  },
  onCancel() {
    UIDialog.hide('paymentAmountDialog');
  },
  onsubmit() {
    const paymentAction = new JCCPaymentAction();
    if (!this.state.paymentLog.PurchaseAmt) {
      this.state.paymentLog.PurchaseAmt = null;
      MessageHelper.warning(i18n.PartyMsg.Warning.Warn_0013);
      this.setState();
      return;
    }
    if (this.state.maxPremium < this.state.paymentLog.PurchaseAmt) {
      // MessageHelper.warning("Maximum Payment Amount is "+this.state.currencySign+" "+this.state.maxPremium+", Please adjust.")
      MessageHelper.warning(
        i18n.PartyMsg.Warning.Warn_0015.replace(
          /{currencySign}/,
          this.state.currencySign
        ).replace(/{maxPremium}/, this.state.maxPremium)
      );
      this.setState();
      return;
    }
    if (this.state.minAmount > this.state.paymentLog.PurchaseAmt) {
      MessageHelper.warning(
        i18n.PartyMsg.Warning.Warn_0016.replace(
          /{currencySign}/,
          this.state.currencySign
        ).replace(/{minAmount}/, this.state.minAmount)
      );
      this.setState();
      return;
    }
    const paymentlog = this.state.paymentLog;
    if (paymentlog.PaymentMethod == 108) {
      const result = paymentAction.savePaymentLog(
        paymentlog,
        this.props.withoutCollectionFlag
      );
      if (result) {
        UIDialog.hide('paymentAmountDialog');
        this.props.onSubmit(result);
        paymentAction.gotoPayment(result);
      }
    } else {
      this.props.onshowAuthDialog(paymentlog);
      UIDialog.hide('paymentAmountDialog');
    }
  },
  onChange() {
    if (!this.state.paymentLog.PurchaseAmt) {
      this.state.paymentLog.PurchaseAmt = null;
      MessageHelper.warning(i18n.PartyMsg.Warning.Warn_0013);
    } else if (
      this.state.maxPremium < this.state.paymentLog.PurchaseAmt
    ) {
      // MessageHelper.warning("Maximum Payment Amount is "+this.state.currencySign+" "+this.state.maxPremium+", Please adjust.")
      MessageHelper.warning(
        i18n.PartyMsg.Warning.Warn_0015.replace(
          /{currencySign}/,
          this.state.currencySign
        ).replace(/{maxPremium}/, this.state.maxPremium)
      );
      this.state.paymentLog.PurchaseAmt = null;
    } else if (
      this.state.minAmount > this.state.paymentLog.PurchaseAmt
    ) {
      // MessageHelper.warning("Minimum Payment Amount is "+this.state.currencySign+" "+this.state.minAmount+", Please adjust.")
      MessageHelper.warning(
        i18n.PartyMsg.Warning.Warn_0016.replace(
          /{currencySign}/,
          this.state.currencySign
        ).replace(/{minAmount}/, this.state.minAmount)
      );
      this.state.paymentLog.PurchaseAmt = null;
    } else if (this.state.paymentLog.PurchaseAmt <= 0) {
      // PartyMsg.Warning.Warn_0017
      MessageHelper.warning('Payment amount must be greater than 0');
      this.state.paymentLog.PurchaseAmt = null;
    }
    this.setState();
  },
});
export default PaymentAmountDialog;
