import {
  DataContext,
  UpdateContext,
  MessageHelper,
  UIConfirmDialog,
  AjaxUtil,
} from 'RainbowUI';
import {
  RestStatus,
  ClaimMessageForamtUtils,
} from 'ClaimCommonConstant';
import TreeNode from '../constant/TreeNodeCache';

const url = require('../url/Url');

export default {
  getProductList(pageindex, pagesize) {
    let result = null;
    const treeNode = TreeNode.TreeCache.get('TreeNode');
    AjaxUtil.doGet(
      `${url.ClaimProductGetProductList}/${pagesize}/${pageindex}/${treeNode.objectMap.pId}`,
      {},
      {
        async: false,
        done(data) {
          result = data.Model;
        },
        fail() {
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
        },
      }
    );
    return { count: result.Count, result: result.Module };
  },
  removeProductDialog(event) {
    const data = event.getParameter('data');
    data.removeType = 'product';
    DataContext.put('data', data);
    UIConfirmDialog.show('RemoveConfirmDialog');
  },

  removeProduct() {
    const data = DataContext.get('data');
    AjaxUtil.doGet(
      `${url.ClaimProductDelete}/${data.ProductCode}`,
      {},
      {
        async: false,
        done(data) {
          if (data.Status == RestStatus.OK) {
            MessageHelper.success(
              ClaimMessageForamtUtils.formatI18NMsg(
                i18n.ClaimProduct.DeleteSuccess,
                i18n.ClaimProduct.Product
              )
            );
            UpdateContext.forceUpdate('treeCfgUIupdateId');
            UpdateContext.forceUpdate('productList');
          } else {
            MessageHelper.error(
              ClaimMessageForamtUtils.formatI18NMsg(
                i18n.ClaimProduct.DeleteFail,
                i18n.ClaimProduct.Product
              )
            );
          }
        },
        fail() {
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
        },
      }
    );
  },
};
