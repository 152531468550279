import {
  UIDialog,
  UIPanel,
  UICell,
  UIDialogFooter,
  UITextarea,
  UISelect,
  UIButton,
  UISmartPanelGrid,
} from 'RainbowUI';
import React from 'react';
import BindToMixin from 'react-binding';
import { CodeTableAction, CodeTableCstUI } from 'ClaimCommonConstant';

const NoticeOfLossAction = require('../action/NoticeOfLossAction');

const RejectNoticeDialog = React.createClass({
  mixins: [BindToMixin],

  getInitialState() {
    return { noticeEntity: {} };
  },

  showDialog() {
    UIDialog.show('rejectNotice_UIDialog');
  },

  clickSubmit() {
    const {
      noticeEntity,
    } = this.props.parentComponent.refs.AccidentInfo.state;
    noticeEntity.RejectReasonCode = this.state.noticeEntity.RejectReasonCode;
    noticeEntity.RejectRemark = this.state.noticeEntity.RejectRemark;
    NoticeOfLossAction.rejectSubmit(noticeEntity);
  },

  render() {
    return (
      <UIDialog id="rejectNotice_UIDialog" title="" width="70%">
        <UIPanel panelTitle={i18n.ClaimCommon.RejectConfirmation}>
          <UISmartPanelGrid>
            <UISelect
              id="rejectReason"
              label={i18n.ClaimCommon.RejectReason}
              codeTable={CodeTableAction.loadDataList(
                CodeTableCstUI.ClaimRejectReason,
                null
              )}
              valueLink={this.bindToState(
                'noticeEntity',
                'RejectReasonCode'
              )}
              required="true"
              validationGroup="rejectNoticeDetail"
            />
            <UICell />

            <UITextarea
              id="remark"
              label={i18n.ClaimCommon.Remark}
              valueLink={this.bindToState(
                'noticeEntity',
                'RejectRemark'
              )}
              colspan="2"
            />
          </UISmartPanelGrid>
        </UIPanel>
        <UIDialogFooter>
          <UIButton
            value={i18n.ClaimCommon.Submit}
            onClick={this.clickSubmit}
            causeValidation="true"
            validationGroup="rejectNoticeDetail"
          />
          <UIButton
            value={i18n.ClaimCommon.Cancel}
            buttonType="cancel"
          />
        </UIDialogFooter>
      </UIDialog>
    );
  },
});
export default RejectNoticeDialog;
