import {
  UIPanel,
  UISmartPanelGrid,
  UICell,
  MessageHelper,
  UISelect,
  CodeTable,
  UIDataTable,
  UIColumn,
  DataContext,
  UIBox,
  UIButton,
  UIDialog,
  UIDropZone,
  UITextarea,
  UIDialogFooter,
} from 'RainbowUI';
import BindToMixin from 'react-binding';
import ClaimMatrixAction from '../../action/ClaimMatrixAction';
import CodeTableAction from '../../action/CodeTableAction';
import Url from '../../url/Url';

const ProductMatrix = React.createClass({
  mixins: [BindToMixin],
  getInitialState() {
    return {
      SearchModel: {},
      ProductTypeList: null,
      ProductList: null,
    };
  },

  componentWillMount() {
    ClaimMatrixAction.initQuery();
    let productTypeList = null;
    let productList = null;
    const { productLineCode } = DataContext.get('queryModel').Complex;
    const { productTypeCode } = DataContext.get('queryModel').Complex;
    if (productLineCode) {
      productTypeList = this.getProductTypeByLineCode(
        productLineCode
      );
    }
    if (productTypeCode) {
      productList = this.getProductByProductTypeCode(productTypeCode);
    }
    this.setState({
      ProductTypeList: productTypeList,
      ProductList: productList,
    });
  },
  generateProductTypeList() {
    if (!this.state.ProductTypeList) {
      if (this.state.SearchModel.ProductLineCode) {
        return this.getProductTypeByLineCode(
          this.state.SearchModel.ProductLineCode
        );
      }
      return new CodeTable([]);
    }
    return this.state.ProductTypeList;
  },

  generateProductList() {
    if (!this.state.ProductList) {
      if (this.state.SearchModel.ProductTypeCode) {
        return this.getProductByProductTypeCode(
          this.state.SearchModel.ProductTypeCode
        );
      }
      return new CodeTable([]);
    }
    return this.state.ProductList;
  },

  getProductTypeByLineCode(productLineCode) {
    const codeTableId = 1015;
    const conditions = { PRODUCT_LINE_CODE: productLineCode };
    const ProductTypeList = CodeTableAction.getCodeTableByConditions(
      codeTableId,
      conditions,
      null
    );
    return ProductTypeList;
  },
  getProductByProductTypeCode(productTypeCode) {
    const codeTableId = 1017;
    const conditions = { PRODUCT_TYPE_CODE: productTypeCode };
    const ProductList = CodeTableAction.getCodeTableByConditions(
      codeTableId,
      conditions,
      null
    );
    return ProductList;
  },

  handleOnChangeProductLine() {
    this.setState(prevState => {
      const searchModel = prevState.SearchModel;
      searchModel.ProductTypeCode = null;
      searchModel.ProductCode = null;
      searchModel.ProductLineCode = DataContext.get(
        'queryModel'
      ).Complex.productLineCode;
      if (!DataContext.get('queryModel').Complex.productLineCode) {
        DataContext.get('queryModel').Complex.productTypeCode = null;
        DataContext.get('queryModel').Complex.productCode = null;
      }
      return {
        SearchModel: searchModel,
      };
    });
  },

  handleOnChangeProductType() {
    this.setState(prevState => {
      const searchModel = prevState.SearchModel;
      searchModel.ProductTypeCode = DataContext.get(
        'queryModel'
      ).Complex.productTypeCode;
      if (!DataContext.get('queryModel').Complex.productTypeCode) {
        DataContext.get('queryModel').Complex.productCode = null;
      }
      return {
        SearchModel: searchModel,
      };
    });
  },

  handleOnChangeProductCode() {
    this.setState(prevState => {
      const searchModel = prevState.SearchModel;
      searchModel.ProductCode = DataContext.get(
        'queryModel'
      ).Complex.productCode;
      return {
        SearchModel: searchModel,
      };
    });
  },

  uploadHandler() {
    this.setState({ errData: '', Status: 'OK' });
    UIDialog.show('fileUploadDialog');
  },
  handleSuccess(file) {
    let status = '';
    const dataList = file.Messages[0].Message;
    if (dataList !== 'Success') {
      status = 'BLOCK';
    } else {
      status = 'OK';
    }
    const errData = dataList;
    this.setState({ errData, Status: status });
  },
  getVisibled() {
    if (this.state.Status !== 'OK') {
      return true;
    }
    return false;
  },
  hideAllDialog() {
    UIDialog.hide('fileUploadDialog');
  },
  render() {
    const uploadUrl = Url.upLoadClaimMatrix;
    const { errData } = this.state;
    return (
      <div>
        <UIPanel panelTitle={i18n.ClaimCommon.SearchCriteria}>
          <UISmartPanelGrid column="2">
            <UISelect
              label={i18n.ClaimCommon.ProductLine}
              codeTable={ClaimMatrixAction.getProductLineList}
              value="#{queryModel.Complex.productLineCode}"
              onChange={this.handleOnChangeProductLine}
            />
            <UISelect
              label={i18n.ClaimCommon.ProductType}
              codeTable={this.generateProductTypeList}
              value="#{queryModel.Complex.productTypeCode}"
              onChange={this.handleOnChangeProductType}
            />
            <UISelect
              label={i18n.ClaimCommon.ProductName}
              codeTable={this.generateProductList}
              onChange={this.handleOnChangeProductCode}
              value="#{queryModel.Complex.productCode}"
            />
          </UISmartPanelGrid>
          <br />
          <UIBox direction="right">
            <UIButton
              value={i18n.ClaimCommon.DownLoad}
              onClick={this.onClickDownload}
            />
            <UIButton
              value={i18n.ClaimCommon.Upload}
              onClick={this.uploadHandler}
            />
          </UIBox>
        </UIPanel>
        {this.renderSearchResult()}
        <UIDialog
          id="fileUploadDialog"
          title={i18n.ClaimCommon.FileUpload}
          width="960px"
        >
          <UIPanel id="panelDropzone" headerable="false">
            <UICell>
              <UIDropZone
                id="dropzone"
                autoUpload="true"
                url={uploadUrl}
                onSuccess={this.handleSuccess}
                onError={this.handleError}
              />
            </UICell>
          </UIPanel>
          <UICell visibled={this.getVisibled}>
            <UITextarea
              id="comments"
              label={i18n.ClaimCommon.ErrorMessage}
              value={errData}
            />
          </UICell>
          <UIDialogFooter>
            <UIButton
              value={i18n.ClaimCommon.CLOSE}
              position="right"
              onClick={this.hideAllDialog}
            />
          </UIDialogFooter>
        </UIDialog>
      </div>
    );
  },

  renderSearchResult() {
    return (
      <div>
        <UIPanel panelTitle={i18n.ClaimCommon.SearchResult}>
          <UIDataTable
            id="productList"
            provider={ClaimMatrixAction.getQueryClaimProductProvider}
          >
            <UIColumn selectionMode="multiple" enabled="ture" />
            <UIColumn
              headerTitle={i18n.ClaimCommon.ProductLineName}
              value="ProductLineName"
              width="20%"
            />
            <UIColumn
              headerTitle={i18n.ClaimCommon.ProductTypeName}
              value="ProductTypeName"
              width="30%"
            />
            <UIColumn
              headerTitle={i18n.ClaimCommon.ProductName}
              value="ProductName"
              width="30%"
            />
            <UIColumn
              headerTitle={i18n.ClaimCommon.ProductCode}
              value="ProductCode"
              width="30%"
            />
          </UIDataTable>
        </UIPanel>
      </div>
    );
  },

  onClickDownload() {
    const recordData = UIDataTable.getSelectedRecord('productList');
    if (!recordData) {
      MessageHelper.warning(
        i18n.ClaimCommon.WarnMsg.PleaseSelectOneResult
      );
      return;
    }
    ClaimMatrixAction.downLoadByProductLine(recordData);
  },
});
export default ProductMatrix;
