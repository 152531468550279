import { UIParam, UIConfirmDialog } from 'RainbowUI';
import ProductCfgTreeAction from '../action/ProductCfgTreeAction';
const CommonAction = require('../action/CommonAction');


const RemoveConfirmDialog = React.createClass({
  remove() {
    if (this.props.parentFunction) {
      this.props.parentFunction();
    }
    CommonAction.remove();
    UIConfirmDialog.hide('RemoveConfirmDialog');
    ProductCfgTreeAction.initData();
  },
  render() {
    return (
      <UIConfirmDialog
        id="RemoveConfirmDialog"
        title={i18n.ClaimProduct.Product}
        message={i18n.ClaimProduct.ConfirmMessage}
        onConfirm={this.remove}
      >
        <UIParam name="productyTypeId" value="productyTypeId" />
      </UIConfirmDialog>
    );
  },
});
export default RemoveConfirmDialog;
