import {
  UIPanel,
  UIDateTimePicker,
  UISmartPanelGrid,
  UIText,
  UISelect,
  UIRadio,
  UIButton,
  UICell,
  UIBox,
  UIParam,
  MessageHelper,
} from 'RainbowUI';
import React, { Component, PropTypes } from 'react';
import ReactMixin from 'react-mixin';
import BindToMixin from 'react-binding';
import { CodeTableAction, CodeTableCstUI } from 'ClaimCommonConstant';
import ClaimCommonExt from 'ClaimCommonExt';
import {
  PartySearchPanelDialog,
  PartyConst,
  PtyIntegrationSearchDialog,
} from 'EU00PartyCommonUI';
import Const from '../constant/FieldinvestigationConst';
import BodilyInjuryAction from '../action/BodilyInjuryAction';

const ClaimPartyAction = ClaimCommonExt.getClaimPartyAction();

export default class BodilyInjury extends Component {
  constructor(props) {
    super(props);
    let isFirstParty;
    const { subClaim, policyInfo } = this.props;
    if (subClaim) {
      if (subClaim.damageParty == 'FP') {
        isFirstParty = true;
      } else {
        isFirstParty = false;
      }
    }
    let party;
    let partyName;
    if (policyInfo) {
      if (policyInfo.PartyList.length > 0) {
        for (let i = 0; i < policyInfo.PartyList.length; i++) {
          if (policyInfo.PartyList[i].IsInsured == 'Y') {
            party = policyInfo.PartyList[i];
            break;
          }
        }
      }
    }
    if (party) {
      partyName = party.PartyName;
    }
    this.state = {
      model: {
        InjuryEntity: {},
      },
      synBtnStatu: false,
      hospitalOrg: {},
      indDoctor: {},
      bodilyInjuryInvetigatorInd: {},
      IsFirstParty: isFirstParty,
      PartyName: partyName,
    };

    this.showPartySearchPanelDialog = this.showPartySearchPanelDialog.bind(
      this
    );
    this.showPartySearchPanelDialog = this.showPartySearchPanelDialog.bind(
      this
    );
    this.getEnabledStyle = this.getEnabledStyle.bind(this);
    this.getDaysInHospital = this.getDaysInHospital.bind(this);
    this.showPartySearchPanel4InvestigatorInfoDialog = this.showPartySearchPanel4InvestigatorInfoDialog.bind(
      this
    );
    this.validateRemark1 = this.validateRemark1.bind(this);
    this.validateRemark2 = this.validateRemark2.bind(this);
    this.getFieldInvestigator = this.getFieldInvestigator.bind(this);
    this.save = this.save.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    this.initData();
    this.synBtnEnable();
  }

  componentWillUnmount() { }

  getFieldInvestigator(party) {
    const { model } = this.state;
    model.InvestigatorName = party.PartyName;
    model.InvestigatorPartyId = party.PartyId;
    if (party.PartyType === PartyConst.PARTY_TYPE_IND) {
      model.InvestigatorTel = party.MobileTel;
    } else if (party.PartyType === PartyConst.PARTY_TYPE_ORG) {
      if (
        party.PartyContactList !== null &&
        party.PartyContactList !== undefined
      ) {
        if (party.PartyContactList.length > 0) {
          _.each(party.PartyContactList, partyContact => {
            if (
              partyContact.IsPrimaryContact === PartyConst.IS_PRIMARY
            ) {
              model.InvestigatorTel =
                partyContact.HandPhone === undefined ||
                  partyContact.HandPhone === null
                  ? partyContact.BusinessTel
                  : partyContact.HandPhone;
            }
          });
        }
      }
    }
    this.setState({
      model,
    });
  }

  getCodeTable(p) {
    return CodeTableAction.loadDataList(p, null, true);
  }

  getIOStyle() {
    const { model } = this.state;
    const { openForm } = this.props;
    return model.Fistatus == Const.DONE || openForm === Const.Other
      ? 'out'
      : 'in';
  }

  getEnabledStyle() {
    const { model } = this.state;
    const { openForm } = this.props;
    return model.Fistatus == Const.DONE || openForm === Const.Other
      ? 'false'
      : 'true';
  }

  getButtons() {
    const { openForm } = this.props;
    if (openForm === Const.Self) {
      return (
        <UIBox>
          <UIButton
            value={i18n.ClaimCommon.Save}
            onClick={this.save}
          />
          <UIButton
            value={i18n.ClaimCommon.Submit}
            validationGroup="bodilyInjury"
            causeValidation="true"
            onClick={this.submit}
          />
        </UIBox>
      );
    }
    return undefined;
  }

  getDaysInHospital() {
    const { model } = this.state;
    if (
      model.InjuryEntity.DateOfAdmission &&
      model.InjuryEntity.DateOfDischarge
    ) {
      const startDate = new Date(model.InjuryEntity.DateOfDischarge);
      const endDate = new Date(model.InjuryEntity.DateOfAdmission);
      console.log(
        '-------------DateOfAdmission----------',
        model.InjuryEntity.DateOfAdmission,
        model.InjuryEntity.DateOfDischarge
      );
      model.InjuryEntity.DayInHospital =
        Math.floor(
          (startDate.getTime() - endDate.getTime()) /
          (24 * 3600 * 1000)
        ) + 1;
      if (model.InjuryEntity.DayInHospital < 0) {
        MessageHelper.warning(
          i18n.ClaimFieldInvestigation.WarnMsg
            .DischargeShouldLaterThanDateOfAdmission
        );
        model.InjuryEntity.DateOfDischarge = null;
        model.InjuryEntity.DateOfAdmission = null;
        model.InjuryEntity.DayInHospital = null;
      }
    }
    this.setState({ model });
  }

  // load initData from  former investigationTask
  initData() {
    const { openForm, objectId } = this.props;
    let fiStatus = Const.DONE;
    if (openForm === Const.Self) {
      fiStatus = Const.NEW;
    }
    const data = BodilyInjuryAction.initData(objectId, fiStatus);

    console.info('initData: ', data);
    if (data != null) {
      if (Const.NEW === fiStatus && Const.DONE === data.Fistatus) {
        data.Fistatus = Const.NEW;
        data.Fiid = null;
      }

      this.setState({
        model: data,
      });
    }
  }

  synchronizationFromAppraisal() {
    const { objectId } = this.props;
    const { model } = this.state;
    const synModel = BodilyInjuryAction.synModel(objectId);
    // Type Property Id sunclaimType subCliamId
    let hasChange = false;
    const newSynModelState = model.InjuryEntity;
    const keyps = Object.keys(synModel);
    for (let i = 0; i < keyps.length; i += 1) {
      newSynModelState[keyps[i]] = synModel[keyps[i]];
      hasChange = true;
    }
    console.log('newSynModelState is ', newSynModelState);
    this.forceUpdate();

    if (hasChange) {
      MessageHelper.success(
        i18n.ClaimFieldInvestigation.VictimSynSuccess
      );
    }
  }

  buildData() {
    const vo = {};
    // ie not compality
    let entity = $.extend({}, this.state.model);
    const { objectId, taskInfo } = this.props;
    entity.ObjectId = objectId;
    entity['@type'] =
      'ClaimFieldInvestigation-ClaimFieldInvestigation';
    entity.InjuryEntity['@type'] = 'ClaimFIInjury-ClaimFIInjury';
    console.info('submit injury entity ', entity);
    vo.TaskInstanceId = taskInfo.TaskInstanceId;
    vo.ClaimFIEntity = entity;
    return vo;
  }

  submit() {
    const entity = this.buildData();
    BodilyInjuryAction.submit(entity);
  }

  save() {
    const entity = this.buildData();
    if (entity.ClaimFIEntity.InjuryEntity) {
      if (!entity.ClaimFIEntity.InjuryEntity.VictimName) {
        entity.ClaimFIEntity.InjuryEntity.VictimName = ' ';
      }
    }
    BodilyInjuryAction.save(entity, this.submitCallback.bind(this));
  }

  submitCallback(data) {
    console.log(
      `-------------submitCallback----------${JSON.stringify(data)}`
    );
    this.setState({
      model: data.Model,
    });
  }

  synBtnEnable() {
    const { taskInfo } = this.props;
    const enabled = BodilyInjuryAction.synButtonIsEnable(taskInfo);
    this.setState({
      synBtnStatu: enabled,
    });
  }

  validateRemark1() {
    const { model } = this.state;
    if (model.Remark1 != null && model.Remark1.length > 3000) {
      model.Remark1 = null;
      MessageHelper.warning(
        i18n.ClaimFieldInvestigation.WarnMsg.ValueMustLessThan3000
      );
    }
    this.setState({ model });
  }

  validateRemark2() {
    const { model } = this.state;
    if (model.Remark2 != null && model.Remark2.length > 3000) {
      model.Remark2 = null;
      MessageHelper.warning(
        i18n.ClaimFieldInvestigation.WarnMsg.ValueMustLessThan3000
      );
    }
    this.setState({ model });
  }

  showTreatment() {
    const { model } = this.state;
    if (model.InjuryEntity && model.InjuryEntity.Treatment == 2) {
      return false;
    }
    return true;
  }

  changeTreatment() {
    const { model } = this.state;
    console.log('model', model);
    if (model.InjuryEntity.Treatment == 2) {
      model.InjuryEntity.DateOfAdmission = null;
      model.InjuryEntity.dateOfDischarge = null;
      model.InjuryEntity.DayInHospital = null;
      this.setState({ model });
    }
  }

  showPartySearchPanelDialog(event) {
    const partyRole = event.getParameter('partyRole');
    const stateParam = event.getParameter('stateParam');
    const partyCategory = event.getParameter('partyCategory');
    this.refs.PartySearchPanelDialog_ref.showDialog({
      partyCategory,
      partyRole,
      partyRoleSelectEnable: false,
      partyCategorySelectEnable: false,
      createBtnDisable: true,
      editLinkVisiable: false,
      partyRoleSelectVisiable: false,
    });
    this.refs.PartySearchPanelDialog_ref.doContinue = function doContinue(
      ...args
    ) {
      const party = args[0];
      const { model, roleType } = this.state;
      if (!party) return;
      console.log(`=====party=====${JSON.stringify(party)}`);
      const stateJson = {};
      if (party.partyInfo.length == 1) {
        eval(`stateJson.${stateParam}=party.partyInfo[0]`);
        this.setState(stateJson);
        if (PartyConst.PARTY_ROLE_CODE_ORG_HEALTHCARE == partyRole) {
          model.InjuryEntity.Hospital = party.partyInfo[0].PartyName;
        } else {
          model.InjuryEntity.DoctorName =
            party.partyInfo[0].PartyName;
        }
        const commonParty = {
          PartyRole: roleType,
          PtyPartyId: Number(party.partyInfo[0].PartyId),
          PartyName: party.partyInfo[0].PartyName,
        };
        this.createClaimParty(commonParty);
      }

      this.refs.PartySearchPanelDialog_ref.doContinue = () => { };
    }.bind(this);
  }

  showPartySearchPanel4InvestigatorInfoDialog() {
    this.refs.PartySearchDialogForInvestigator_Ref.show();
  }

  createClaimParty(commonParty) {
    const { taskInfo } = this.props;
    ClaimPartyAction.createPartyParty(taskInfo.CaseId, commonParty);
  }

  render() {
    let victimName;
    const { model, IsFirstParty, PartyName } = this.state;
    if (model.InjuryEntity) {
      victimName = model.InjuryEntity.VictimName;
    }
    if (IsFirstParty == true && victimName == undefined) {
      if (PartyName) {
        victimName = PartyName;
      }
    }
    if (!model.InjuryEntity) {
      model.InjuryEntity = {};
    }
    if (model.InjuryEntity.VictimName == ' ') {
      victimName = '';
    }
    model.InjuryEntity.VictimName = victimName;
    const showTreatment = this.showTreatment();
    return (
      <div>
        <PartySearchPanelDialog
          ref="PartySearchPanelDialog_ref"
          parentComponent={this}
          endId="_fi_bodyinjury"
        />
        <UIPanel panelTitle={i18n.ClaimFieldInvestigation.VictimInfo}>
          <UISmartPanelGrid column="3">
            <UIText
              label={i18n.ClaimFieldInvestigation.VictimName}
              validationGroup="bodilyInjury"
              required="true"
              model={model.InjuryEntity}
              property="VictimName"
              io={this.getIOStyle()}
            />
            <UIRadio
              label={i18n.ClaimFieldInvestigation.Gender}
              validationGroup="bodilyInjury"
              required="true"
              valueLink={this.bindToState(
                'model',
                'InjuryEntity.Gender'
              )}
              io={this.getIOStyle()}
              codeTable={this.getCodeTable.bind(
                this,
                CodeTableCstUI.Gender
              )}
              enabled={this.getEnabledStyle()}
            />
            <UIText
              label={i18n.ClaimFieldInvestigation.Age}
              valueLink={this.bindToState(
                'model',
                'InjuryEntity.Age'
              )}
              io={this.getIOStyle()}
            />

            <UIText
              label={i18n.ClaimFieldInvestigation.HomeAddress}
              colspan="2"
              valueLink={this.bindToState(
                'model',
                'InjuryEntity.HomeAddress'
              )}
              io={this.getIOStyle()}
            />
            <UIText
              label={i18n.ClaimFieldInvestigation.ContactTel}
              allowChars="/^\d*$/"
              valueLink={this.bindToState(
                'model',
                'InjuryEntity.ContactTel'
              )}
              io={this.getIOStyle()}
            />

            <UIRadio
              label={i18n.ClaimFieldInvestigation.InjuryType}
              valueLink={this.bindToState(
                'model',
                'InjuryEntity.InjuryType'
              )}
              codeTable={this.getCodeTable.bind(
                this,
                CodeTableCstUI.ClaimInjuryType
              )}
              enabled={this.getEnabledStyle()}
              io={this.getIOStyle()}
            />
            <UISelect
              label={i18n.ClaimFieldInvestigation.DisabilityGrade}
              valueLink={this.bindToState(
                'model',
                'InjuryEntity.InjuryGrade'
              )}
              codeTable={this.getCodeTable.bind(
                this,
                CodeTableCstUI.ClaimDisabilityGrade
              )}
              enabled={this.getEnabledStyle()}
            />
            <UIRadio
              label={i18n.ClaimFieldInvestigation.Treatment}
              valueLink={this.bindToState(
                'model',
                'InjuryEntity.Treatment'
              )}
              codeTable={this.getCodeTable.bind(
                this,
                CodeTableCstUI.ClaimTreatment
              )}
              enabled={this.getEnabledStyle()}
              io={this.getIOStyle()}
              onChange={this.changeTreatment.bind(this)}
            />

            <UIText
              label={i18n.ClaimFieldInvestigation.Hospital}
              io={this.getIOStyle()}
              enabled={false}
              suffixIcon="fa fa-search"
              onSuffixIconClick={this.showPartySearchPanelDialog}
              valueLink={this.bindToState(
                'model',
                'InjuryEntity.Hospital'
              )}
            >
              <UIParam
                name="partyRole"
                value={PartyConst.PARTY_ROLE_CODE_ORG_HEALTHCARE}
              />
              <UIParam
                name="partyCategory"
                value={PartyConst.PARTY_TYPE_ORG}
              />
            </UIText>

            <UIText
              label={i18n.ClaimFieldInvestigation.doctorName}
              io={this.getIOStyle()}
              enabled={false}
              suffixIcon="fa fa-search"
              onSuffixIconClick={this.showPartySearchPanelDialog}
              valueLink={this.bindToState(
                'model',
                'InjuryEntity.DoctorName'
              )}
            >
              <UIParam
                name="partyRole"
                value={PartyConst.PARTY_ROLE_CODE_IND_HEALTHCARE}
              />
              <UIParam
                name="partyCategory"
                value={PartyConst.PARTY_TYPE_IND}
              />
            </UIText>
            <UIText
              label={i18n.ClaimFieldInvestigation.specialty}
              valueLink={this.bindToState(
                'model',
                'InjuryEntity.Specialty'
              )}
              io={this.getIOStyle()}
            />
            <UIDateTimePicker
              label={i18n.ClaimFieldInvestigation.dateOfAdmission}
              validationGroup="bodilyInjury"
              placeHolder="MM/DD/YYYY"
              format="MM/DD/YYYY"
              visibled={showTreatment}
              valueLink={this.bindToState(
                'model',
                'InjuryEntity.DateOfAdmission'
              )}
              io={this.getIOStyle()}
              enabled={this.getEnabledStyle}
              maxDate="TODAY"
              onChange={this.getDaysInHospital}
            />
            <UIDateTimePicker
              label={i18n.ClaimFieldInvestigation.dateOfDischarge}
              validationGroup="bodilyInjury"
              placeHolder="MM/DD/YYYY"
              format="MM/DD/YYYY"
              visibled={showTreatment}
              valueLink={this.bindToState(
                'model',
                'InjuryEntity.DateOfDischarge'
              )}
              io={this.getIOStyle()}
              enabled={this.getEnabledStyle}
              onChange={this.getDaysInHospital}
              maxDate="TODAY"
            />
            <UIText
              label={i18n.ClaimFieldInvestigation.DaysInHospital}
              valueLink={this.bindToState(
                'model',
                'InjuryEntity.DayInHospital'
              )}
              visibled={showTreatment}
              io={this.getIOStyle()}
            />
            <UIText
              label={i18n.ClaimFieldInvestigation.InjuryDescription}
              colspan="2"
              valueLink={this.bindToState(
                'model',
                'InjuryEntity.InjuryRemark'
              )}
              io={this.getIOStyle()}
            />
            <UIDateTimePicker
              label={i18n.ClaimFieldInvestigation.investigatorTime}
              validationGroup="bodilyInjury"
              placeHolder="MM/DD/YYYY HH:mm:ss"
              format="MM/DD/YYYY HH:mm:ss"
              valueLink={this.bindToState(
                'model',
                'InjuryEntity.DateOfArrival'
              )}
              io={this.getIOStyle()}
              enabled={this.getEnabledStyle}
              maxDate="TODAY"
            />
            <UIText
              label={i18n.ClaimFieldInvestigation.investigatorName}
              io={this.getIOStyle()}
              onSuffixIconClick={
                this.showPartySearchPanel4InvestigatorInfoDialog
              }
              valueLink={this.bindToState(
                'model',
                'InvestigatorName'
              )}
              suffixIcon="fa fa-search"
            >
              <UIParam
                name="partyRole"
                value={
                  PartyConst.PARTY_ROLE_CODE_IND_CLAIMANT_INVESTIGATOR
                }
              />
              <UIParam
                name="partyCategory"
                value={PartyConst.PARTY_TYPE_IND}
              />
            </UIText>
            <UIText
              label={
                i18n.ClaimFieldInvestigation.investigatorTelephone
              }
              colspan="2"
              valueLink={this.bindToState('model', 'InvestigatorTel')}
              allowChars="/^\d*$/"
              io={this.getIOStyle()}
            />

            <UIText
              label={i18n.ClaimFieldInvestigation.remark1}
              colspan="3"
              valueLink={this.bindToState('model', 'Remark1')}
              io={this.getIOStyle()}
              onChange={this.validateRemark1}
            />
            <UIText
              label={i18n.ClaimFieldInvestigation.remark2}
              colspan="3"
              valueLink={this.bindToState('model', 'Remark2')}
              io={this.getIOStyle()}
              onChange={this.validateRemark2}
            />
            <UIText
              visibled={false}
              value={model.InvestigatorPartyId}
            />
          </UISmartPanelGrid>
        </UIPanel>

        <UICell style={{ textAlign: 'left' }}>
          {this.getButtons()}
        </UICell>
        <PtyIntegrationSearchDialog
          ref="PartySearchDialogForInvestigator_Ref"
          id="partySearchDialogForInvestigator"
          isSearch={false}
          title={i18n.SearchClaimInvestigator}
          getSearchResult={this.getFieldInvestigator}
          isVisibled={false}
          RoleName={PartyConst.PARTY_ROLE_NAME_CLAIM_INVESTIGATOR}
        />
      </div>
    );
  }
}
ReactMixin.onClass(BodilyInjury, BindToMixin);
BodilyInjury.defaultProps = {
  showButtons: true,
};
BodilyInjury.propTypes = {
  showButtons: PropTypes.bool,
};
