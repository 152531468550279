import { UILink, UIPanel, UITree, UIParam } from 'RainbowUI';
import BindToMixin from 'react-binding';
import PropTypes from 'prop-types';
import ClmPartyTreeStore from '../store/ClmPartyTreeStore';

const ClaimPartyAction = require('../action/ClaimPartyAction');

const ClaimPartyTreePanel = React.createClass({
  propTypes: {
    index: PropTypes.string,
    caseId: PropTypes.string,
  },
  mixins: [BindToMixin],
  getInitialState() {
    return { treeNodeList: [], partyAuth: null };
  },
  onStoreStateChange(storeState) {
    if (!_.isEmpty(storeState.treeNodeList)) {
      this.setState({
        treeNodeList: storeState.treeNodeList,
        partyAuth: storeState.partyAuth,
      });
    }
  },
  componentWillMount() {
    ClmPartyTreeStore.listen(this.onStoreStateChange);

    ClaimPartyAction.initData(this.props.caseId);
  },

  componentWillUnmount() {
    ClmPartyTreeStore.unlisten(this.onStoreStateChange);
  },

  clickSearch() {
    this.props.parentComponent.refs.ClaimPartyRightPanel_Ref.showPageChange(
      'QueryCommonParty',
      {}
    );
  },

  render() {
    const { index } = this.props;
    const { treeNodeList } = this.state;
    return (
      <UIPanel headerable="false">
        {this.getLink()}
        <UITree
          id={`clmPtyRoleTree${index}`}
          dataSource={treeNodeList}
          onClick={ClaimPartyAction.openTreeNode}
        >
          <UIParam name="index" value={index} />
        </UITree>
      </UIPanel>
    );
  },

  getLink() {
    if (this.state.partyAuth === 'true') {
      return (
        <UILink
          value={i18n.ClaimParty.OpenSearchLink}
          onClick={this.clickSearch}
        >
          <UIParam name="index" value={this.props.index} />
        </UILink>
      );
    }
  },
});
export default ClaimPartyTreePanel;
