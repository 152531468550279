import { MessageHelper } from 'RainbowUI';
import alt from '../alt';
import FraudIndicatorService from '../service/FraudIndicatorService';

const ClaimExceptionUtils = require('../util/ClaimExceptionUtils');

class FraudIndicatorAction {
  initFraud(objectId) {
    let result = null;
    if (
      objectId === null ||
      objectId === undefined ||
      objectId === '' ||
      objectId === 'undefined'
    ) {
      return null;
    }

    FraudIndicatorService.loadFraudIndicators(objectId).then(
      data => {
        if (data.Status === 'OK') {
          result = data;
        }
      },
      err => {}
    );
    this.dispatch({ fraudVOList: result.Model });
    return result.Model;
  }

  save(selectMap) {
    FraudIndicatorService.save(selectMap).then(
      data => {
        if (data.Status === 'OK') {
          MessageHelper.info(
            ClaimExceptionUtils.formatI18NMsg(
              i18n.ClaimCommon.SaveSuccess,
              i18n.ClaimCommon.FraudIndicatorTitle
            )
          );
        } else {
          MessageHelper.info(
            ClaimExceptionUtils.formatI18NMsg(
              i18n.ClaimCommon.SaveFail,
              i18n.ClaimCommon.FraudIndicatorTitle
            )
          );
        }
      },
      err => {
        MessageHelper.info(
          ClaimExceptionUtils.formatI18NMsg(
            i18n.ClaimCommon.SaveFail,
            i18n.ClaimCommon.FraudIndicatorTitle
          )
        );
      }
    );
  }
}
export default alt.createActions(FraudIndicatorAction);
