import {
  UIPanel,
  UISmartPanelGrid,
  UIText,
  UILink,
  UIParam,
  UISelect,
} from 'RainbowUI';
import BindToMixin from 'react-binding';
import React from 'react';

const LimitDeductDialog = require('./LimitDeductDialog');
const NoticeOfLossAction = require('../../../../../notice/module/notice/common/action/NoticeOfLossAction');

const PolicyDetailPolicy = React.createClass({
  mixins: [BindToMixin],

  getInitialState() {
    return { PolicyEntity: this.props.policyInfo };
  },

  componentWillReceiveProps(nextProps) {
    this.setState({ PolicyEntity: nextProps.policyInfo });
  },
  getParentComponent() {
    return this;
  },
  showLimitDedut(event) {
    const data = event.getParameter('data');
    this.refs.LimitDeductDialog_ref.showLimitDeductDialog(data);
  },

  render() {
    const { PolicyEntity } = this.state;
    // const limit_deduction = (
    //   <UILink
    //     id="limit_dedut"
    //     value={i18n.ClaimCalculation.LimitAndDeductionInfo}
    //     style={{ color: '#FFF' }}
    //     onClick={this.showLimitDedut}
    //   >
    //     <UIParam name="insuredId" value="1" />
    //     <UIParam name="coverageCode" value="2" />
    //   </UILink>
    // );
    // panelHeader={/*span>{limit_deduction}</span>*/}

    return (
      <div>
        <UIPanel
          id="PolicyInfo"
          panelTitle={i18n.ClaimCommon.PolicyDetail}
        >
          <UISmartPanelGrid column="3" widthAllocation="5,7">
            <UIText
              id="DetailPolicyNo"
              label={i18n.ClaimCommon.PolicyNumber}
              value={PolicyEntity.PolicyNo}
              io="out"
            />

            <UISelect
              id="DetailPolicyOrganId"
              label={i18n.ClaimCommon.PolicyBranch}
              value={PolicyEntity.OrganId}
              codeTable={NoticeOfLossAction.getBranchCodetable()}
              io="out"
            />

            <UIText
              id="DetailPolicyPremiumIsPaid"
              label={i18n.ClaimCommon.PremiunIsPaid}
              value={PolicyEntity.PremiumIsPaid}
              io="out"
            />

            <UIText
              id="DetailPolicyEffDate"
              label={i18n.ClaimCommon.EffectiveDate}
              value={PolicyEntity.EffDate}
              io="out"
            />

            <UIText
              id="DetailPolicyExpDate"
              label={i18n.ClaimCommon.ExpiryDate}
              value={PolicyEntity.ExpDate}
              io="out"
            />
          </UISmartPanelGrid>
        </UIPanel>
        <LimitDeductDialog
          ref="LimitDeductDialog_ref"
          index="LimitDeductDialog"
          level="policy"
          parentComponent={this}
        />
      </div>
    );
  },
});
export default PolicyDetailPolicy;
