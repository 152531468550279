import { UIPage, UILayout, UILayoutUnit } from 'RainbowUI';
import ClaimProduct from 'ClaimProduct';

const ProductCfgTree = require('./ProductCfgTree');

const ProductCfgDetail = ClaimProduct.getProductCfgDetail();

const ProductParameterCfg = React.createClass({
  render() {
    return (
      <UIPage>

        <UILayout id="layout01" height="680px">
          <UILayoutUnit position="west" resizable="true" size="300">
            <ProductCfgTree />
          </UILayoutUnit>
          <UILayoutUnit position="center">
            <ProductCfgDetail />
          </UILayoutUnit>
        </UILayout>
      </UIPage>
    );
  },
});

export default ProductParameterCfg;
