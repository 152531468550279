import {
  UIText,
  UISelect,
  UIDialog,
  UISmartPanelGrid,
  UIBox,
  UIButton,
} from 'RainbowUI';
import CommonCodeTable from '../../codetable/CodeTable';
import PartyConst from '../../const/PartyConst';

const _ = require('underscore');

const ProducerOrgRole = React.createClass({
  getInitialState() {
    const partyRoleList = this.props.partyRoleSelected;

    const producerCode = '';
    let model = {
      '@type':
        'PartyOrganizationalProducerRole-PartyOrganizationalProducerRole',
      BusinessObjectId: 75181044,
      RoleType: PartyConst.PARTY_ROLE_CODE_ORG_PRODUCER,
      ProducerCode: producerCode,
      ProducerType: '',
      CreateDate: '',
      InactiveDate: '',
      Status: '',
      Remark: '',
    };
    _.each(partyRoleList, partyRole => {
      if (
        partyRole.RoleType == PartyConst.PARTY_ROLE_CODE_ORG_PRODUCER
      ) {
        model = partyRole;
        if ($.isEmptyObject(model.ProducerCode)) {
          model.ProducerCode = producerCode;
        }
        return model;
      }
    });

    if (this.props.isEdit === 'Y') {
      const selectedData = partyRoleList[0];
      model.ProducerCode = $.isEmptyObject(selectedData.ProducerCode)
        ? producerCode
        : selectedData.ProducerCode;
      model.ProducerType = selectedData.ProducerType;
      model.Remark = selectedData.Remark;
      model.CreateDate = selectedData.InsertTime;
      model.InactiveDate = selectedData.InactiveDate;
      model.Status = selectedData.Status;
    }
    const status = model.Status;
    const visible = !(
      status == '1' ||
      status === undefined ||
      status === null
    );
    return {
      PartyId: this.props.partyId,
      PartyRoleModel: model,
      Visible: visible,
    };
  },

  render() {
    const _self = this;
    const { PartyRoleModel, Visible } = this.state;
    const { isEdit } = this.props;
    return (
      <div>
        <UISmartPanelGrid column="3">
          <UISelect
            label={i18n.PartyRole}
            value={PartyConst.PARTY_ROLE_CODE_ORG_PRODUCER}
            enabled="false"
            codeTable={CommonCodeTable.getOrgPartyRole}
            required="true"
          />
          <UIText
            label={i18n.ProducerCode}
            enabled="false"
            model={PartyRoleModel}
            property="ProducerCode"
          />
          <UISelect
            label={i18n.ProducerType}
            model={PartyRoleModel}
            property="ProducerType"
            defaultValue="2"
            codeTable={CommonCodeTable.getPartyRoleProducerType}
            required="true"
            validationGroup="partyProducerRoleDialog"
          />
          <UISelect
            label={i18n.Status}
            model={PartyRoleModel}
            property="Status"
            defaultValue="0"
            showBlankOption="false"
            codeTable={CommonCodeTable.getPartyRoleProducerStatus}
            onChange={_self.changeStatus}
            disabled={!(isEdit === 'Y')}
          />
        </UISmartPanelGrid>
        <UIBox direction="center">
          <UIButton
            value={i18n.Save}
            causeValidation="true"
            validationGroup="partyProducerRoleDialog"
            onClick={_self.onClickSubmit}
          />
          <UIButton
            value={i18n.Cancel}
            onClick={_self.onClickClose}
          />
        </UIBox>
      </div>
    );
  },

  changeStatus() {
    const status = model.Status;
    const visible = !(
      status == '1' ||
      status === undefined ||
      status === null
    );
    this.setState({ Visible: visible });
  },

  checkInactiveDate() {
    if (this.state.PartyRoleModel.Status == '1') {
      return false;
    }
    if (this.state.PartyRoleModel.Status == '2') {
      return true;
    }
  },

  onClickSubmit() {
    const _self = this;
    _.each(_self.props.RoleList, temppartyRole => {
      const partyRole = temppartyRole;
      if (
        partyRole.RoleType == PartyConst.PARTY_ROLE_CODE_ORG_PRODUCER
      ) {
        partyRole.Status = _self.state.PartyRoleModel.Status;
      }
    });
    const superParty = _self.props.parentComponent.state.Party;
    const { PartyHistoryList } = superParty;
    if (!_self.state.PartyRoleModel['@pk']) {
      const newPartyHistory = PartyConst.PARTY_HISTORY_CREATE_ROLE;
      PartyHistoryList.push(newPartyHistory);
    } else {
      const newPartyHistory = PartyConst.PARTY_HISTORY_UPDATE_ROLE;
      PartyHistoryList.push(newPartyHistory);
    }
    if (!superParty.PartyRoleList) {
      superParty.PartyRoleList = [];
    }
    superParty.PartyRoleList.push(_self.state.PartyRoleModel);
    _self.props.submitInfoHandler(superParty);
    UIDialog.hide('createProducerOrgPartyDialog');
  },
  onClickClose() {
    UIDialog.hide('createProducerOrgPartyDialog');
  },
});
export default ProducerOrgRole;
