import {
  UICheckbox,
  UIDataTable,
  MessageHelper,
  UIColumn,
  UIBox,
  UIButton,
  UIPanel,
  UILink,
  UIParam,
  UIDialog,
  UISmartPanelGrid,
  UIText,
  UIDateTimePicker,
  UISelect,
  UICell,
  UIUpdatePanel,
} from 'RainbowUI';
import ReactMixin from 'react-mixin';
import BindToMixin from 'react-binding';
import PartyCreationDialog from '../../../party/views/PartyCreationDialog';
import PartyConst from '../../../party/const/PartyConst';
import DefinedCodeTable from '../../../party/codetable/CodeTable';
import PartyAction from '../../../party/actions/PartyAction';
import UrlConfig from '../../../../UrlConfig';

export default class PtyIntegrationSearchCreateDialog extends React.Component {
  constructor(props) {
    super(props);
    const partyType = PartyConst.PARTY_TYPE_IND;
    this.state = {
      RoleType: null,
      definedCondition: { PartyType: partyType },
      conditions: {},
      fuzzyConditions: {},
      orCondtions: {},
      isSearch: false,
      id: this.props.Id,
    };
  }

  getOrgPartyResult(pageindex, pagesize) {
    const {
      isSearch,
      conditions,
      fuzzyConditions,
      orCondtions,
      definedCondition,
    } = this.state;
    if (!isSearch) {
      return { count: 0, result: [] };
    }
    const partyAction = new PartyAction();
    const partyName = conditions.PartyName;
    const fuzzySearch = conditions.FuzzySearch;
    const mobileWorkTel = conditions.MobileWorkTel;
    const orConditions = orCondtions;
    if (conditions.FuzzySearch === 'Y') {
      fuzzyConditions.PartyName = conditions.PartyName;
      delete conditions.PartyName;
    }
    if (conditions.MobileWorkTel) {
      orConditions.WorkTel = conditions.MobileWorkTel;
      orConditions.MobileTel = conditions.MobileWorkTel;
      delete conditions.MobileWorkTel;
    }
    delete conditions.FuzzySearch;
    const partyType = definedCondition.PartyType;
    const result = partyAction.searchPartyByConditions(
      conditions,
      orConditions,
      fuzzyConditions,
      partyType,
      pageindex,
      pagesize
    );
    conditions.PartyName = partyName;
    conditions.FuzzySearch = fuzzySearch;
    conditions.MobileWorkTel = mobileWorkTel;
    if (result) {
      return { count: result.count, result: result.result };
    }
    return { count: 0, result: [] };
  }

  getIndPartyResult(pageindex, pagesize) {
    const {
      isSearch,
      conditions,
      fuzzyConditions,
      orCondtions,
      definedCondition,
    } = this.state;
    if (!isSearch) {
      return { count: 0, result: [] };
    }
    const partyAction = new PartyAction();
    const partyName = conditions.PartyName;
    const fuzzySearch = conditions.FuzzySearch;
    const workHomeMobileTel = conditions.WorkHomeMobileTel;
    const orConditions = orCondtions;
    if (conditions.FuzzySearch === 'Y') {
      fuzzyConditions.PartyName = conditions.PartyName;
      delete conditions.PartyName;
    }
    if (conditions.WorkHomeMobileTel) {
      orConditions.WorkHomeTel = conditions.WorkHomeMobileTel;
      orConditions.MobileTel = conditions.WorkHomeMobileTel;
      delete conditions.WorkHomeMobileTel;
    }
    delete conditions.FuzzySearch;
    const partyType = definedCondition.PartyType;
    const result = partyAction.searchPartyByConditions(
      conditions,
      orConditions,
      fuzzyConditions,
      partyType,
      pageindex,
      pagesize
    );
    conditions.PartyName = partyName;
    conditions.FuzzySearch = fuzzySearch;
    conditions.WorkHomeMobileTel = workHomeMobileTel;
    if (result) {
      return { count: result.count, result: result.result };
    }
    return { count: 0, result: [] };
  }

  indiviPartyConditionRender() {
    const {
      conditions,
      fuzzyConditions,
      RoleType,
      definedCondition,
    } = this.state;
    const disabled = false;
    let roleType = RoleType;
    if (PartyConst.RoleTypeOrgMap[roleType]) {
      roleType = PartyConst.RoleTypeOrgMap[roleType];
    }
    if (roleType) {
      fuzzyConditions.RoleType = roleType;
    }
    const _self = this;
    return (
      <div>
        <UIPanel panelTitle={i18n.PartySearchCriteria}>
          <UISmartPanelGrid column="2">
            <UISelect
              label={i18n.PartyCategory}
              codeTable={DefinedCodeTable.getCategroyCodeTable}
              onChange={_self.partyCategoryChange.bind(this)}
              model={definedCondition}
              property="PartyType"
              showBlankOption="false"
              widthAllocation="2,10"
            />
            <UICell />
            <UISmartPanelGrid widthAllocation="8,4" column="4">
              <UIText
                label={i18n.PartyName}
                model={conditions}
                property="PartyName"
                colspan="2"
              />
              <UICheckbox
                single="true"
                label={i18n.FuzzySearch}
                model={conditions}
                property="FuzzySearch"
              />
            </UISmartPanelGrid>
            <UIText
              label={i18n.PartyFormerCode}
              model={conditions}
              property="PartyCode"
              widthAllocation="2,10"
            />
            <UIText
              label={i18n.IdNo}
              model={conditions}
              property="IdNumber"
              widthAllocation="2,10"
            />
            <UIDateTimePicker
              format="MM/DD/YYYY"
              label={i18n.BirthDay}
              model={conditions}
              property="DateOfBirth"
              widthAllocation="2,10"
              manualInput="true"
            />
            <UIText
              label={i18n.MobileHomeWorkNo}
              model={conditions}
              property="WorkHomeMobileTel"
              widthAllocation="2,10"
            />
            <UIText
              label={i18n.SSSEmployerId}
              model={conditions}
              property="EmployerId"
              widthAllocation="2,10"
            />
            <UIText
              label={i18n.PostalCode}
              model={conditions}
              property="PostCode"
              widthAllocation="2,10"
            />
          </UISmartPanelGrid>
          <UIBox direction="left">
            <UIButton
              value={i18n.Reset}
              onClick={_self.resetHandler}
            />
            <UIButton
              value={i18n.Search}
              onClick={_self.searchHandler.bind(_self)}
              update={`SearchPartyRole${_self.props.id}`}
            />
            <UIButton
              value={i18n.Create}
              onClick={_self.createHandler.bind(_self)}
            />
          </UIBox>
        </UIPanel>
      </div>
    );
  }

  createHandler() {
    this.refs.PartyCreationDialog.showPartyCreateDialog();
  }

  queryHandler(clickEvent) {
    let party = {};
    if (!_.isEmpty(clickEvent.paramJson)) {
      party = clickEvent.paramJson[0].value;
    }
    const { PartyId } = party;
    if (PartyId === null || PartyId === undefined || PartyId === '') {
      MessageHelper.error(
        i18n.PartyIdIsNull,
        i18n.PartyMsg.ErrorMsg.Error_0002,
        MessageHelper.POSITION_TOP_RIGHT
      );
    } else {
      window.location.href = `${UrlConfig.partyCommonUrl}#/viewIntegrationPartyBaseInfo/${PartyId}`;
    }
  }

  editHandler(clickEvent) {
    const selectData = UIDataTable.getSelectedRecord('PartyRecord');
    if (!selectData.length) {
      MessageHelper.warning(
        i18n.PartyMsg.WarnMsg.Warn_0005,
        i18n.PartyMsg.WarnMsg.Warn_0003,
        MessageHelper.POSITION_TOP_RIGHT
      );
      return;
    }
    let party = selectData[0];
    if (!_.isEmpty(clickEvent.paramJson)) {
      party = clickEvent.paramJson[0].value;
    }
    const { PartyId } = party;
    if (PartyId === null || PartyId === undefined || PartyId === '') {
      MessageHelper.error(
        i18n.PartyIdIsNull,
        i18n.PartyMsg.ErrorMsg.Error_0002,
        MessageHelper.POSITION_TOP_RIGHT
      );
    } else {
      window.location.href = `${UrlConfig.partyCommonUrl}#/integrationPartyMaintain/${PartyId}`;
    }
  }

  searchHandler() {
    const { conditions } = this.state;
    if (conditions.Status) {
      if (conditions.Status == '0') {
        conditions.Status = 0;
      }
      if (conditions.Status == '1') {
        conditions.Status = 1;
      }
    }
    this.setState({ isSearch: true });
  }

  resetHandler() {
    this.setState({
      conditions: {},
      fuzzyConditions: {},
      orCondtions: {},
      isSearch: false,
    });
  }

  partyCategoryChange(evnet) {
    this.resetHandler();
    if (evnet.newValue) {
      const party = {};
      party.PartyType = evnet.newValue;
    }
  }

  save() {
    const _self = this;
    const selectData = UIDataTable.getSelectedRecord('PartyRecord');
    if (!selectData.length) {
      MessageHelper.warning(i18n.PartyMsg.WarnMsg.Warn_0003);
      return;
    }
    UIDialog.hide(`SearchPartyRole${_self.props.id}`);
    const party = selectData[0];
    _self.props.getSearchResult(party);
  }

  show(roleType, id) {
    this.setState({ RoleType: roleType });
    UIDialog.show(`SearchPartyRole${id}`);
  }

  orgPartyConditionRender() {
    const _self = this;
    const {
      conditions,
      fuzzyConditions,
      RoleType,
      definedCondition,
    } = this.state;
    console.log('conditions', conditions);
    const disabled = false;
    let roleType = RoleType;
    if (PartyConst.RoleTypeIndMap[roleType]) {
      roleType = PartyConst.RoleTypeIndMap[roleType];
    }
    if (roleType) {
      fuzzyConditions.RoleType = roleType;
    }
    return (
      <div>
        <UIPanel panelTitle={i18n.PartySearchCriteria}>
          <UISmartPanelGrid column="2">
            <UISelect
              label={i18n.PartyCategory}
              codeTable={DefinedCodeTable.getCategroyCodeTable}
              onChange={_self.partyCategoryChange.bind(this)}
              model={definedCondition}
              property="PartyType"
              showBlankOption="false"
              widthAllocation="2,10"
            />
            <UICell />
            <UISmartPanelGrid widthAllocation="8,4" column="4">
              <UIText
                label={i18n.PartyName}
                model={conditions}
                property="PartyName"
                colspan="2"
                widthAllocation="2,10"
              />
              <UICheckbox
                single="true"
                label={i18n.FuzzySearch}
                model={conditions}
                property="FuzzySearch"
              />
            </UISmartPanelGrid>
            <UIText
              label={i18n.PartyFormerCode}
              model={conditions}
              property="PartyCode"
              widthAllocation="2,10"
            />
            <UIText
              label={i18n.CompanyNo}
              model={conditions}
              property="OrganizationIdNumber"
              widthAllocation="2,10"
            />
            <UIText
              label={i18n.MobileWorkNo}
              model={conditions}
              property="MobileWorkTel"
              widthAllocation="2,10"
            />
            <UIText
              label={i18n.SSSEmployerId}
              model={conditions}
              property="EmployerId"
              widthAllocation="2,10"
            />
            <UIText
              label={i18n.PostalCode}
              model={conditions}
              property="PostCode"
              widthAllocation="2,10"
            />
          </UISmartPanelGrid>
          <UIBox direction="left">
            <UIButton
              value={i18n.Reset}
              onClick={this.resetHandler}
            />
            <UIButton
              value={i18n.Search}
              onClick={this.searchHandler.bind(_self)}
              update={`SearchPartyRole${_self.props.id}`}
            />
            <UIButton
              value={i18n.Create}
              onClick={_self.createHandler.bind(_self)}
            />
          </UIBox>
        </UIPanel>
      </div>
    );
  }

  renderIndividualSearchResult() {
    const _self = this;
    return (
      <UIPanel panelTitle={i18n.PartyResult}>
        <UIDataTable
          provider={_self.getIndPartyResult.bind(this)}
          selectionMode="single"
          indexable="false"
          id="PartyRecord"
        >
          <UIColumn headerTitle={i18n.PartyCode} value="PartyCode" />
          <UIColumn
            headerTitle={i18n.PartyName}
            render={data => {
              return (
                <div>
                  <UILink
                    value={data.PartyName}
                    onClick={_self.queryHandler}
                  >
                    <UIParam name="data" value={data} />
                  </UILink>
                </div>
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.IdType}
            value="IdType"
            render={data => {
              return (
                <UISelect
                  io="out"
                  model={data}
                  property="IdType"
                  codeTable={DefinedCodeTable.IdType}
                />
              );
            }}
          />
          <UIColumn headerTitle={i18n.IdNo} value="IdNumber" />
          <UIColumn headerTitle={i18n.Gender} value="Gender">
            <UISelect
              codeTable={DefinedCodeTable.getGenderCodeTable()}
              io="out"
            />
          </UIColumn>
          <UIColumn>
            <UIText value="PartyRoleVoList" visibled="false" />
          </UIColumn>
          <UIColumn>
            <UIText value="PartyId" visibled="false" />
          </UIColumn>
        </UIDataTable>
      </UIPanel>
    );
  }

  renderOrgSearchResult() {
    const _self = this;
    return (
      <UIPanel panelTitle={i18n.PartyResult}>
        <UIDataTable
          provider={_self.getOrgPartyResult.bind(this)}
          selectionMode="single"
          indexable="false"
          id="PartyRecord"
        >
          <UIColumn headerTitle={i18n.PartyCode} value="PartyCode" />
          <UIColumn
            headerTitle={i18n.PartyName}
            render={data => {
              return (
                <div>
                  <UILink
                    value={data.PartyName}
                    onClick={_self.queryHandler}
                  >
                    <UIParam name="data" value={data} />
                  </UILink>
                </div>
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.CompanyNo}
            value="OrganizationIdNumber"
          />
          <UIColumn
            headerTitle={i18n.Country}
            render={data => {
              return (
                <UISelect
                  model={data}
                  property="Country"
                  codeTable={DefinedCodeTable.getCountry}
                  io="out"
                />
              );
            }}
          />
          <UIColumn>
            <UIText value="PartyRoleVoList" visibled="false" />
          </UIColumn>
          <UIColumn>
            <UIText value="PartyId" visibled="false" />
          </UIColumn>
        </UIDataTable>
      </UIPanel>
    );
  }

  render() {
    const _self = this;
    const { definedCondition } = this.state;
    const { id } = this.props;
    const partyType = definedCondition.PartyType;
    let partyConditionRender;
    // 1: Individual; 2:Organization
    console.log('partyType', partyType);
    if (partyType == PartyConst.PARTY_TYPE_IND) {
      partyConditionRender = _self.indiviPartyConditionRender();
    } else {
      partyConditionRender = _self.orgPartyConditionRender();
    }
    let renderSearchResult;

    if (partyType == PartyConst.PARTY_TYPE_IND) {
      renderSearchResult = _self.renderIndividualSearchResult();
    } else {
      renderSearchResult = _self.renderOrgSearchResult();
    }
    const dialogId = `SearchPartyRole${id}`;
    console.log('dialogId', dialogId);
    return (
      <div>
        <UIDialog
          title={i18n.SearchPartyInfo}
          width="80%"
          id={dialogId}
        >
          <UIUpdatePanel id={`SearchPartyRole${id}`}>
            {partyConditionRender}
            {renderSearchResult}
          </UIUpdatePanel>
          <UIBox direction="center">
            <UIButton
              value={i18n.OK}
              onClick={_self.save.bind(this)}
            />
          </UIBox>
        </UIDialog>
        <PartyCreationDialog
          ref="PartyCreationDialog"
          id="PartyCreationDialog"
        />
      </div>
    );
  }
}
ReactMixin.onClass(PtyIntegrationSearchCreateDialog, BindToMixin);
