import { AjaxUtil, MessageHelper } from 'RainbowUI';
import alt from '../alt';
import Url from '../url/Url';
import SearchTaskMessage from './SearchTaskMessage';

class MassiveReassignmentClaimOwnerAction {
  loadClaimList(pageindex, pagesize, tmpreassignmentClaimOwnerVO) {
    const reassignmentClaimOwnerVO = tmpreassignmentClaimOwnerVO;
    const pageNo = (pageindex - 1) / pagesize + 1;

    let localUrl = Url.MassiveReassignmentClaimOwner;
    let result = null;
    reassignmentClaimOwnerVO.PageSize = pagesize;
    reassignmentClaimOwnerVO.PageIndex = pageNo;

    delete reassignmentClaimOwnerVO.flag;
    if (env.IS_MOCK) {
      localUrl = 'workflow/getTaskList';
    }
    AjaxUtil.doPost(localUrl, reassignmentClaimOwnerVO, {
      async: false,
      done(data) {
        if (data.Status === 'OK') {
          result = data.Model;
        } else {
          MessageHelper.error(
            i18n.ClaimCommom.ErrorMsg.GetPoolTaskFail.replace(
              '{0}',
              data.Model.Messages[0].Message
            ),
            i18n.ClaimCommom.ErrorMsg.FailUPPERCASE,
            MessageHelper.POSITION_TOP_FULL_WIDTH
          );
        }
      },
      fail(data) {
        MessageHelper.error(
          i18n.ClaimCommom.ErrorMsg.GetPoolTaskFail.replace(
            '{0}',
            data.Model.Messages[0].Message
          ),
          i18n.ClaimCommom.ErrorMsg.FailUPPERCASE,
          MessageHelper.POSITION_TOP_FULL_WIDTH
        );
      },
    });

    const resultObj = { count: result.Total, result: result.Module };
    const searchTaskMessage = new SearchTaskMessage(resultObj);
    searchTaskMessage.showMessage();
    return resultObj;
  }

  searchTask(condition) {
    this.dispatch(condition);
  }

  submitAssginTo(massiveReassignmentAssignToVO) {
    let status = 'F';
    let susList = [];
    let failList = [];
    const localUrl = Url.MassiveReassignmentClaimOwnerSave;
    AjaxUtil.doPost(localUrl, massiveReassignmentAssignToVO, {
      async: false,
      done(data) {
        if (data.Status === 'OK') {
          status = 'S';
          susList = data.Model.susList;
          failList = data.Model.failList;
        } else {
          MessageHelper.error(
            i18n.ClaimCommom.ErrorMsg.SubmitFailP.replace(
              '{0}',
              data.Model.Messages[0].Message
            ),
            i18n.ClaimCommom.ErrorMsg.FailUPPERCASE,
            MessageHelper.POSITION_TOP_FULL_WIDTH
          );
        }
      },
      fail(data) {
        MessageHelper.error(
          i18n.ClaimCommom.ErrorMsg.GetPoolTaskFail.replace(
            '{0}',
            data.Model.Messages[0].Message
          ),
          i18n.ClaimCommom.ErrorMsg.FailUPPERCASE,
          MessageHelper.POSITION_TOP_FULL_WIDTH
        );
      },
    });
    return { status, susList, failList };
  }
}
export default alt.createActions(MassiveReassignmentClaimOwnerAction);
