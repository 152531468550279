import {
  UITabItem,
  UITab,
  UIBox,
  UICell,
  DataContext,
  UIButton,
  UIUpdatePanel,
  UpdateContext,
} from 'RainbowUI';
import PartyOrgBasicInfo from '../../../party/views/PartyOrgBasicInfo';
import PartyIndBasicInfo from '../../../party/views/PartyIndBasicInfo';
import PartyAddressInfo from '../../../party/views/PartyAddressInfo';
import PartyContactInfo from '../../../party/views/PartyContactInfo';
import PartyAccountInfo from '../../../party/views/PartyAccountInfo';
import PartyRelationShipInfo from '../../../party/views/PartyRelationShipInfo';
import PartyAction from '../../../party/actions/PartyAction';
import PartyHistory from '../../../party/views/PartyHistory';
import PartyConst from '../../../party/const/PartyConst';
import PartyIndAddInfo from '../../../party/views/PartyIndAddInfo';

const PtyIntegrationTabInfo = React.createClass({
  getInitialState() {
    return {
      party: {},
    };
  },

  componentWillMount() {
    const { partyId } = this.props;
    const partyAction = new PartyAction();
    const party = partyAction.loadPartyByPartyId(partyId);
    this.setState({
      party,
    });
    const copyParty = JSON.parse(JSON.stringify(party));
    DataContext.put('Party', copyParty);
  },

  exitHandler() {
    const { routeName } = this.props;
    if (routeName) {
      window.location.hash = routeName;
    } else {
      window.close();
    }
  },

  submitHandler() {
    const { party } = this.state;
    party.PartyAccountList = DataContext.get(
      'Party'
    ).PartyAccountList;
    party.AddressVoList = DataContext.get(
      'Party'
    ).AddressVoList;
    party.PartyRelationShipList = DataContext.get(
      'Party'
    ).PartyRelationShipList;
    party.PartyContactList = DataContext.get(
      'Party'
    ).PartyContactList;
    party.PartyRoleList = DataContext.get('Party').PartyRoleList;
    party.PartyAdditionalList = DataContext.get(
      'Party'
    ).PartyAdditionalList;
    party.PartyHistoryList = DataContext.get(
      'Party'
    ).PartyHistoryList;
    DataContext.put('Party', party);
    if (party.PartyRoleList && party.PartyRoleList.length > 0) {
      console.log('party', party);
      const partyAction = new PartyAction();
      const newPartyHistory =
        PartyConst.PARTY_HISTORY_UPDATE_BASIC_INFO;
      party.PartyHistoryList.push(newPartyHistory);
      let result = partyAction.updateParty(party);
      const { activeIndex } = this.state;
      console.log('activeIndex', activeIndex);
      if (result === 'fail' || !result) {
        result = DataContext.get('Party');
        const existsParty = partyAction.loadPartyByPartyId(
          party.PartyId
        );
        result.PartyHistoryList = existsParty.PartyHistoryList;
        DataContext.put('Party', result);
        this.setState({
          party: result,
          activeIndex,
        });
        UpdateContext.forceUpdate('basicInfo');
      } else {
        this.exitHandler();
      }
    } else {
      MessageHelper.warning(
        i18n.PartyMsg.WarnMsg.Warn_0006,
        '',
        MessageHelper.POSITION_TOP_RIGHT
      );
    }
  },
  submitInfoHandlerCommon(party, activeIndex) {
    const partyAction = new PartyAction();
    const result = partyAction.updatePartyCommon(party);
    let copyParty = this.state.party;
    if (result == PartyConst.FAIL) {
      copyParty = PartyConst.FAIL;
    } else {
      copyParty.PartyRelationShipList = DataContext.get(
        'Party'
      ).PartyRelationShipList;
      copyParty.PartyRoleList = DataContext.get(
        'Party'
      ).PartyRoleList;
      copyParty.PartyAdditionalList = DataContext.get(
        'Party'
      ).PartyAdditionalList;
      copyParty.PartyHistoryList = result.PartyHistoryList;
      result.PartyAccountList = copyParty.PartyAccountList;
      result.AddressVoList = copyParty.AddressVoList;
      result.PartyContactList = copyParty.PartyContactList;
      DataContext.put('Party', result);
      this.setState({
        party: copyParty,
        activeIndex,
      });
      UpdateContext.forceUpdate('partyInfoTab');
    }
    return copyParty;
  },
  submitInfoHandlerForAddress(party, activeIndex) {
    const partyAction = new PartyAction();
    let result = partyAction.updatePartyForAddress(party);
    let copyParty = this.state.party;
    if (result == PartyConst.FAIL) {
      copyParty = PartyConst.FAIL;
    } else {
      result = partyAction.loadPartyByPartyId(party.PartyId);
      DataContext.put('Party', result);
      $.extend(copyParty, result);
      this.setState({
        party: copyParty,
        activeIndex,
      });
      UpdateContext.forceUpdate('partyInfoTab');
    }
    return copyParty;
  },
  submitInfoHandlerForAccount(party, activeIndex) {
    const partyAction = new PartyAction();
    let result = partyAction.updatePartyForAccount(party);
    let copyParty = this.state.party;
    if (result == PartyConst.FAIL) {
      copyParty = PartyConst.FAIL;
    } else {
      result = partyAction.loadPartyByPartyId(party.PartyId);
      DataContext.put('Party', result);
      $.extend(copyParty, result);
      this.setState({
        party: copyParty,
        activeIndex,
      });
      UpdateContext.forceUpdate('partyInfoTab');
    }
    return copyParty;
  },
  submitInfoHandlerForContact(party, activeIndex) {
    const partyAction = new PartyAction();
    let result = partyAction.updatePartyForContact(party);
    let copyParty = this.state.party;
    if (result == PartyConst.FAIL) {
      copyParty = PartyConst.FAIL;
    } else {
      result = partyAction.loadPartyByPartyId(party.PartyId);
      copyParty.PartyRelationShipList = DataContext.get(
        'Party'
      ).PartyRelationShipList;
      copyParty.PartyContactList = DataContext.get(
        'Party'
      ).PartyContactList;
      copyParty.PartyRoleList = DataContext.get(
        'Party'
      ).PartyRoleList;
      copyParty.PartyAdditionalList = DataContext.get(
        'Party'
      ).PartyAdditionalList;
      copyParty.PartyHistoryList = result.PartyHistoryList;
      result.AddressVoList = copyParty.AddressVoList;
      result.PartyAccountList = copyParty.PartyAccountList;
      DataContext.put('Party', result);
      this.setState({
        party: copyParty,
        activeIndex,
      });
      UpdateContext.forceUpdate('partyInfoTab');
    }
    return copyParty;
  },

  render() {
    const { party } = this.state;
    let BasicInfo;
    let AdditionalInfo;
    if (party.PartyType == PartyConst.PARTY_TYPE_IND) {
      BasicInfo = this.renderIndBasicInfo();
      AdditionalInfo = this.renderIndAddInfo();
    } else {
      BasicInfo = this.renderOrgBasicInfo();
      AdditionalInfo = this.renderOrgAddInfo();
    }
    let { activeIndex } = this.state;
    if (!activeIndex) {
      activeIndex = 2;
    }
    return (
      <UIUpdatePanel id="partyInfoTab">
        <div>
          <UICell width="14">
            <UIUpdatePanel id="basicInfo">{BasicInfo}</UIUpdatePanel>
            <UITab activeIndex={activeIndex - 1}>
              <UITabItem title={i18n.AddressDetail}>
                <PartyAddressInfo
                  party={party}
                  submitInfoHandler={this.submitInfoHandlerForAddress}
                />
              </UITabItem>
              <UITabItem title={i18n.BankAccount}>
                <PartyAccountInfo
                  submitInfoHandler={this.submitInfoHandlerForAccount}
                  party={party}
                />
              </UITabItem>
              <UITabItem title={i18n.RelationShip}>
                <PartyRelationShipInfo
                  submitInfoHandler={this.submitInfoHandlerCommon}
                  party={party}
                />
              </UITabItem>
              {AdditionalInfo}
              <UITabItem title={i18n.History}>
                <PartyHistory party={party} />
              </UITabItem>
            </UITab>
          </UICell>

          <UIBox direction="center">
            <UIButton
              value={i18n.Submit}
              onClick={this.submitHandler}
              validationGroup="UpdateBasicInfo"
              causeValidation="true"
            />
            <UIButton value={i18n.Exit} onClick={this.exitHandler} />
          </UIBox>
        </div>
      </UIUpdatePanel>
    );
  },
  renderIndBasicInfo() {
    const { party } = this.state;
    return <PartyIndBasicInfo party={party} />;
  },
  renderOrgBasicInfo() {
    const { party } = this.state;
    return <PartyOrgBasicInfo party={party} />;
  },
  renderIndAddInfo() {
    const { party } = this.state;
    return (
      <UITabItem title={i18n.AdditionalId}>
        <PartyIndAddInfo
          party={party}
          submitInfoHandler={this.submitInfoHandlerCommon}
        />
      </UITabItem>
    );
  },
  renderOrgAddInfo() {
    const { party } = this.state;
    return (
      <UITabItem title={i18n.ContactInfo}>
        <PartyContactInfo
          submitInfoHandler={this.submitInfoHandlerForContact}
          party={party}
        />
      </UITabItem>
    );
  },
});
export default PtyIntegrationTabInfo;
