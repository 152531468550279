const home = env.serverUrl;
const urlDefine = {
  SettlementHistory: `${home}settlement/history`,
  SettlementCancel: `${home}settlement/cancel`,
  RegistrationInitial: `${home}registration/initial/`,
  SettlementReadOnlyLoad: `${home}settlement/load/bySettlementId/`,
  SettlementPayModeLoad: `${home}settlement/findPayModeBySettlePayeeId`,
  SettlementTaskLoad: `${home}settlement/load/`,
  ChequeReceiveDate: `${home}settlement/chequeReceiveDate`,
  ChequeIssueDate: `${home}settlement/chequeIssueDate`,

  SettlementApproveLoad: `${home}common/approve/load/`,
  SettlementSave: `${home}settlement/save/`,
  SettlementSubmit: `${home}settlement/submit/`,
  SettlementApprove: `${home}settlement/approve/`,
  loadPartyLeader: `${home}/party/party/party/byPartyId?partyId=`,
  SettlementHasFraudIndicator: `${home}settlement/fraudIndicator`,
  ClaimHandlingClaimInfo: `${home}claimhandling/claimInfo/`,
  SettlementSaveChequeIssueDate: `${home}settlement/history/ChequeIssueDate`,
  CodeTableDataList: `${home}/public/codetable/data/list/`,
  LoadClaimPartyList: `${home}settlement/load/claimPartyList/`,
  LoadExchangeRate: `${home}settlement/load/exchangeRate/`,
  loadClaimCase: `${home}claimhandling/claimInfo/{caseId}`,
  checkSettlementAmount: `${home}settlement/checkSettlementAmount`,
  checkRecoveryAmount: `${home}settlement/checkRecoveryAmount`,
  getSettlementTypeList: `${home}ap00/product/getSettlementTypeList`,
  // check   policy   feeStatus  show   warning    when  loading  settlrment page
  CheckPolicyFeeStatus: `${env.integrationServerUrl}/bcp/policyPremium/checkPolicyPremium`,
};
export default urlDefine;
