import { AjaxUtil } from 'RainbowUI';
// import Url from "../url/Url.js";
import { ClaimCommon_Url } from 'ClaimCommonConstant';

export default {
  queryEntitySolr(searchCondition) {
    console.log(
      '======solr queryEntity ====searchCondition: ',
      searchCondition
    );

    const deferred = $.Deferred();
    const url = ClaimCommon_Url.QueryEntityBySolr;
    console.log('----------query solr url', url);
    AjaxUtil.doPost(url, searchCondition, {
      async: false,
      done(data) {
        console.log('======solr queryEntity success====');
        deferred.resolve(data);
      },
      fail(err) {
        console.log('======solr queryEntity fail====');
        deferred.reject(err);
      },
    });
    return deferred.promise();
  },
  queryClaimEntity(searchCondition) {
    console.log(
      '======solr queryEntity ====searchCondition: ',
      searchCondition
    );

    const deferred = $.Deferred();
    AjaxUtil.doPost(
      ClaimCommon_Url.ClaimQueryByIndex,
      searchCondition,
      {
        async: false,
        done(data) {
          console.log('======solr queryEntity success====');
          deferred.resolve(data);
        },
        fail(err) {
          console.log('======solr queryEntity fail====');
          deferred.reject(err);
        },
      }
    );
    return deferred.promise();
  },
};
