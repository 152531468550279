export default {
  Delete: 'Delete',
  cancel: 'CANCEL',
  BreachIndicator: 'Breach Indicator',
  DataRectificationRequest: 'Data Rectification Request',
  Query: 'Query',
  Customer: 'Customer',
  Gender: 'Gender',
  PaymentAmount: 'Payment Amount',
  branch: 'Branch',
  idNo: 'ID No',
  SaveExit: 'SAVE & EXIT',
  rejectReason: 'Reject Reason',
  PartyRoleHasAlreadyExisted: 'The Party Role is existed',
  ShareRate: 'Share Rate %',
  PtyGroupIdSearch: 'Group ID Search',
  calculate: 'CALCULATE',
  ReceiveEmailAlerts: 'Receive Email Alerts:',
  AuditTrail: 'Audit Trail',
  Status: 'Status',
  SEARCH: 'SEARCH',
  PaymentComplete: 'Payment Complete',
  PtyGroupIdResult: 'Group ID Result',
  CompanyType: 'Company Type',
  SettledAmount: 'Settled Amount',
  TreatyCode: 'Treaty Code',
  DocumentSubType: 'Document Sub-Type',
  osInstallmentamount: 'O/S Installment Amount',
  SearchClaimInvestigator: 'Search Claim Investigator',
  QUERY: {
    EndoEffectiveDate: 'Endorsement Effective Date',
    Coinsurance: 'Coinsurance',
    Claims: 'Claims',
    EndorsementHistory: 'Endorsement History',
    EndorsementNo: 'Endorsement No.',
    endorsementid: 'Endorsement ID',
    EndorsementType: 'Endorsement Type',
    DMS: 'DMS',
    GeneralInfo: 'General Info',
    createBy: 'Create By',
    policyHistory: 'Policy History',
    Producer: 'Producer',
    premiumDue: 'Endorsement Premium Due',
    Endorsement: 'Endorsement',
    Clause: 'Clause',
    Declaration: 'Declaration',
    Notes: 'Notes',
    endorsementStatus: 'Endorsement Status',
    customer: 'Customer',
  },
  Note: 'Note',
  grossPremium: 'Gross Premium',
  CountryOfIssue: 'Country of Issue',
  IsThisPartyaMinor: 'Is This Party a Minor?',
  PartyFormerCode: 'Party / Former Party Code',
  ConfirmDialog: 'Confirm Dialog',
  commissionAmount: 'Commission Amount',
  InactiveReason: 'Inactive Reason',
  IdOrCompanyRegNumber: 'ID / Company Reg. Number',
  Bind: 'Bind',
  REJECT: 'REJECT',
  PartialFinal: 'Partial/Final',
  BuildingName: 'Building Name',
  PrimaryAccount: 'Primary Account',
  Prefix: 'Prefix',
  RoleCode: 'Role Code',
  ReceiveSMSAlerts: 'Receive SMS Alerts:',
  RoleDetail: 'Role Detail',
  AddressDetail: 'Address Detail',
  RelationShip: 'Relationship',
  installmentPlan: 'Installment Plan',
  commission: 'Commission',
  Download: 'Download',
  CompanyRegNumber: 'Company Reg. Number',
  TransactionDate: 'Transaction Date',
  IDNumber: 'ID Number',
  reinsuranceBroker: 'Reinsurance Broker',
  riCommissionRate: 'RI Commission Rate %',
  CustomerCode: 'Customer Code',
  PartyMsg: {
    Warning: {
      Warn_0018: 'There is pending payment, please wait',
      DuplicateCheck:
        'The selected document already exist.Cannot upload again.',
      Warn_0013: 'Please input Payment Amount.',
      Warn_0014: 'Please input Authorization Code.',
      Warn_0015:
        'Maximum Payment Amount is {currencySign} {maxPremium} , Please adjust.',
      Warn_0016:
        'Minimum Payment Amount is {currencySign} {minAmount} , Please adjust.',
      Warn_0017: 'Payment amount must be greater than 0',
    },
    WarnMsg: {
      Warn_0007: 'No record found!',
      Warn_0008: 'Not exist.',
      Warn_0009:
        'Company Reg. Number can not be empty, please check.',
      Warn_0010: 'Id Type can not be empty, please check.',
      Warn_0011: 'Postal Code is not exist.',
      Warn_0001: 'The size of file exceeds the limitation',
      Warn_0012:
        'The total shareholder percentage under one organization party cannot be more than 100%.',
      Warn_0002: 'Choose Operation Warning ',
      Warn_0003: 'Select One Row Please',
      Warn_0004: 'You can not choose inactive records',
      Warn_0005: 'Edit Operation Warning ',
      Warn_0006: 'Please add a role to the party',
    },
    ErrorMsg: {
      Error_0001: 'Download error',
      Error_0012:
        'the total size limitation of documents had been exceeded 25 MB, system can’t allow to upload any more',
      Error_0002: 'error',
      Error_0003: "Load policy error, can't print.",
      Error_0004: "Load endorsement error, can't print.",
      Error_0005: 'System Error',
      Error_0006: 'Get Document Error.',
      Error_0007: 'Please upload the required document.',
      Error_0008: 'IBAN No Is Error',
      Error_0009: 'Load codeTable error.',
      Error_0010: 'Please fill all information',
      Error_0011: 'Please enter a number between 0 to 12.',
    },
    'Error.ErrorMsg': {
      Error_0014: 'There is pending payment, please wait',
    },
    SuccessMsg: {
      Success_0001: 'Download successfully',
      Success_0003: 'Upload success!',
      Success_0002: 'File is uploaded successfully',
      Success_0005: 'Create Party Success',
      Success_0004: 'Operation Successfully',
    },
    Error: {
      DuplicateTPDriver:
        'The driver with same ID No. already exists in the system,you can not create a duplicated one. Please use the existing driver.',
      Error_0100: 'IBAN No. Is Error',
      Error_0013: 'The SWIFT Code is required and cannot be empty.',
      LoadPartyError: 'Load Party error, please check.',
    },
    InfoMsg: { Info_0001: 'Submit Successfully!' },
  },
  ddoneoffinstallno:
    'One off must have only one installment, no more no less.',
  BankAccount: 'Bank Account',
  DetailInformation: 'Detail Information',
  PartyAddress: 'Party Address',
  TIC: 'T.I.C ',
  DECLINE: 'DECLINE',
  transactionType: 'Transaction Type',
  PartyCategory: 'Party Category',
  productCode: 'Product Code',
  MobileWorkNo: 'Mobile/Work No.',
  CANCEL: 'CANCEL',
  Submit: 'Submit',
  ShareholderDetail: 'Shareholder Detail',
  AddressType: 'Address Type',
  ClaimSettlement: {
    AddPayer: 'ADD PAYER',
    RecoveryApprovalSubmitSuccess:
      'Recovery Approval is submitted successfully',
    OSReserve: 'O/S Reserve',
    SettlemenAmountCheck: 'Settlement Amount must be negative',
    DeletePayeeError: 'This is the last payee you cannot delete it',
    PayeeAccountTitle: 'Payee Account',
    AddItem: 'Add Item',
    SettlementType: 'Settlement Type',
    PayeeBank: 'Bank',
    PayMode: 'Payment Method',
    DeletePayee: 'DELETE PAYEE',
    Recovery: 'Recovery',
    FillBankInfo: 'Please fill in Bank and IBAN No. info.',
    AddRecoveryPayer: 'ADD RECOVERY PAYER',
    Name: 'Payee/Payer',
    RecoveryApproval: 'Recovery Approval',
    AddPayee: 'ADD PAYEE',
    TaxAmount: 'GST Amount',
    WithOutPolicyError:
      'The Claim has no policy attached, please retrieve the policy first before settlement',
    SettlementSubmitSuccess: 'Settlement is submitted successfully',
    ChequeReceiveDate: 'Cheque Receive Date',
    SettlementSubmitError:
      'Every payee must contain an item at least',
    SelectReserveType: 'Please Select ReserveType First',
    RecoverySaveSuccess: 'Recovery is saved successfully',
    Status: 'Status',
    SettlementHistory: 'Settlement History',
    PayeeAccount: 'Account No.',
    RecoveryHistory: 'Recovery History',
    RecoveryType: 'Recovery Type',
    PayeeAddress: 'Payee Address',
    SettlementCancelSuccess: 'Settlement  is canceled successfully',
    ReferenceNumber: 'Reference Number',
    SettlementApproval: 'Settlement Approval',
    CancelSettlementMessage:
      'Are you sure you want to cancel this settlement ?',
    PayType: 'Payee Type',
    ConfirmMessage: ' Are you sure you want to close this window ?',
    PayerName: 'Payer Name',
    SettlementSubmitBlockByExceedOutstandingReserveAmount:
      'Settlement amount can not exceed outstanding reserve amount，please adjust the reserve amount first',
    CancelConfirmation: 'Cancel Confirmation',
    SettlementApprovalSubmitSuccess:
      'Settlement Approval is submitted successfully',
    CheckSettlementAmountError:
      'The accumulated amount of Loss (Indemnity) of this benefit/cover type exceeds the SI/Limitation of this insured benefit/cover type',
    ApprovedBy: 'Approved by',
    ChoosePartialFinalFail:
      'You haved added more than one settlement items with same Reserve Type. Only the last item can be final settlement',
    PaymentMethodBankTransfer: 'Payment Date :',
    PayeeIBAN: 'IBAN No.',
    RecoveryDetail: 'Recovery Detail',
    PayerType: 'Payer Type',
    ExchangeRate: 'Exchange Rate',
    NoPayee: 'Please add Payee',
    PartialFinal: 'Partial/Final',
    RecoveryAmount: 'Recovery Amount',
    Cancel: 'Cancel',
    SettlementSaveSuccess: 'Settlement is saved successfully',
    WarnMsg: {
      Warn_0001: 'System Warning',
      Warn_0002: 'Operation Warning ',
      Warn_0003: "Select 'Pay Mode' firstly please.",
      Warn_0004: "Select 'Payee Name' first please.",
      Warn_0005: "Current 'Payment Method' not support account.",
    },
    ChooseReserveError: 'The reserve type has already been selected',
    CancelRecovery: 'Cancel Recovery',
    PayeeName: 'Payee Name',
    CancelRecoveryMessage:
      'Are you sure you want to cancel this recovery ?',
    CancelRemarkPlaceHolder: 'Please input cancel Remark',
    PaymentMethodDirectCredit: 'Direct Credit Transfer Date :',
    PAY_MOD_DIRECT_CREDIT: '109',
    ChequeDetail: 'Cheque Detail',
    TaxCode: 'Tax Code',
    CoInsurancePayment: 'Co-insurance Payment',
    ConfirmMessageTitle: ' Confirm',
    RecoveryNo: 'Recovery Number',
    RecoveryFrom: 'Recovery From',
    TaxRate: 'GST %',
    SwiftCode: 'SWIFT Number',
    BreachOfContractReason: 'Breach of Contract Reason',
    PaymentMethodCheque: 'Cheque Issue Date :',
    CheckRecoveryAmountError:
      'Recovery amount cannot exceed outstanding reserve amount',
    SettlementNo: 'Settlement Number',
    TransactionDate: 'Transaction Date',
    SettlementAmount: 'Settlement Amount',
    SettlementHistoryDetail: 'Settlement History Detail',
    ChoosePayeeError: 'Please add Payer/Payee',
    RecoveryCancelSuccess: 'Recovery is canceled successfully',
    NotZero: 'You cannot input Settlement Amount zero',
    ErrorMsg: {
      Error_0001: 'Get ClaimCase Error.',
      Error_0002: 'System Error',
      Error_0003: 'CheckSettlementAmount Error.',
      Error_0004: 'checkRecoveryAmount Error.',
    },
    ChequeIssueDate: 'Cheque Issue Date',
    RecoverySubmitError: 'Every payer must contain an item at least',
    FeeStatusContinutMessage: 'Do you want to continue?',
    CancelSettlement: 'Cancel Settlement',
    Settlement: 'Payment',
    RecoverySubmitSuccess: 'Recovery is submitted successfully',
    SettlementDetail: 'Settlement Detail',
    RecoverySubmitBlockByExceedOutstandingReserveAmount:
      'Recovery amount cannot exceed outstanding reserve amount',
    DeleteRecoveryPayer: 'DELETE RECOVERY PAYER',
    DeletePayer: 'DELETE PAYER',
    RecoveryHistoryDetail: 'Recovery History Detail',
    FraudIndicatorWarning:
      'Fraud Indicator has been raised, please check',
    IssuedBy: 'Issued by',
    ReferenceDate: 'Reference Date',
    RefundApprovalSubmitSuccess:
      'Refund Approval is submitted successfully',
    Refund: 'Refund',
    AddRefundPayer: 'ADD REFUND PAYER',
    RefundApproval: 'Refund Approval',
    RefundSaveSuccess: 'Refund is saved successfully',
    RefundHistory: 'Refund History',
    RefundType: 'Refund Type',
    RefundDetail: 'Refund Detail',
    RefundAmount: 'Refund Amount',
    CancelRefund: 'Cancel Refund',
    CancelRefundMessage:
      'Are you sure you want to cancel this refund ?',
    RefundNo: 'Refund Number',
    RefundFrom: 'Refund From',
    CheckRefundAmountError:
      'Refund amount cannot exceed outstanding reserve amount',
    RefundCancelSuccess: 'Refund is canceled successfully',
    Error_0004: 'checkRefundAmount Error.',
    RefundSubmitError: 'Every payer must contain an item at least',
    RefundSubmitSuccess: 'Refund is submitted successfully',
    RefundSubmitBlockByExceedOutstandingReserveAmount:
      'Refund amount cannot exceed outstanding reserve amount',
    DeleteRefundPayer: 'DELETE REFUND PAYER',
    RefundHistoryDetail: 'Refund History Detail',
  },
  IdNo: 'ID No.',
  ADD: 'ADD',
  originalInstallmentAmount: 'Original Installment Amount',
  Email: 'Email',
  dueDate: 'Due Date',
  language: 'Language',
  Remarks: 'Remarks',
  exchangeRate: 'Exchange Rate',
  reinstatementDate: 'Reinstatement Date',
  payerOrPayee: 'Payer/Payee',
  documentSubType: 'Document Sub-Type',
  PartyRole: 'Party Role',
  EditTip: 'EDIT',
  SharePercentage: 'Share Percentage',
  LastNameLocal: 'Last Name Local',
  organizationName: 'Organization Name',
  PartyRoleStatus: 'Party Role Status',
  APPROVE: 'APPROVE',
  Role: { NPCExemption: 'NPC Exemption' },
  Area: 'Area',
  PtyDefGroupID: 'Define Group ID',
  success: 'Success',
  paymentMethod: 'Payment Method',
  AddTip: 'ADD',
  DoNotSendSMS: 'Do Not Send SMS',
  PaymentChannel: 'Payment Channel',
  HealthCareProviderType: 'Health Care Provider Type',
  ReinsurerReinsuranceBroker: 'Reinsurer/Reinsurance Broker',
  ClearCache: 'Clear Cache',
  SearchPayerPayee: 'Search Payer/Payee',
  PartyMainScreen: 'Party Main Screen',
  LossHistory: 'Loss History',
  TypeOfLicense: 'Type of License',
  productCategory: 'Product Category',
  addToList: 'Add to List',
  PartyResult: 'Party Result',
  CoInsurance: 'Coinsurance',
  Language: 'Language',
  DataAccessRequestCategory: 'Data Access Request Category',
  Number: 'Number',
  DiscountLoading: 'Discount/Loading',
  PartyAccount: 'Party Account',
  Bic: 'BIC',
  arapAmount: 'ARAP Amount',
  Broker: 'Broker',
  approval: 'APPROVAL',
  EmployeeNumber: 'Number of Employees',
  DMS: 'DMS',
  transactionDate: 'Transaction Date',
  RestricationOfProcessing: 'Restrication Of Processing',
  home: 'Dashboard',
  ClaimSalvage: {
    RejectedSalvageSuccess: 'Salvage is rejected successfully',
    SameReserveTypeError:
      'You cannot  create 2 records with same subclaim and same Reserve Type',
    SalvageHistoryDetail: 'Salvage History Detail',
    SalvageSaveSuccess: 'Salvage is saved successfully',
    AddItem: 'Add Item',
    SalvagePanelTitle: 'Salvage Detail',
    NoItem: 'Please add Salvage Item',
    Remark: 'Remark',
    Salvage: 'Salvage',
    SalvageApprove: 'Salvage Approval',
    SalvageHistory: 'Salvage History',
    Selected: ' Selected',
    TransactionDate: 'Transaction Date',
    PleaseSelect: 'Please Select',
    SeqNo: 'Seq No.',
    SalvageAmount: 'Salvage Amount',
    ApprovalHistory: 'Approval History',
    ErrorMsg: { Error_0001: 'Fail', Error_0002: 'failed' },
    AtleastOneItem: 'At least one subclaim need to be added',
    TotalAmount: 'Total Amount',
    RemarkForSalvage: 'Remark for Salvage',
    ApprovedSalvageSuccess: 'Salvage is approved successfully',
    TotalSalvageAmount: 'Total Salvage Amount',
    SalvageSubmitSuccess:
      'All salvage tasks need to be sent for senior approval ',
    SelectCoverage: 'Please select Coverage for Salvage',
    OperatedBy: 'Operated By',
    RecoverFrom: 'Recover From',
  },
  ProducerName: 'Producer Name',
  riRecoveryAmount: 'RI Recovery Amount',
  statementNo: 'Statement No.',
  companyName: 'Company Name',
  WorkTel: 'Work Tel.',
  UploadDataBreachDocument: 'Upload Data Breach Document',
  ContactPerson: 'Contact Person',
  UpdatedBy: 'Updated By',
  balance: 'Balance',
  FileDownload: 'Download',
  ChangePassWord: 'Change Password',
  Back: 'BACK',
  action: 'Action',
  ContactInfo: 'Contact Info',
  PtyGroupIdDialog: 'Group ID Dialog',
  ProducerCode: 'Producer Code',
  Exit: 'EXIT',
  DateOfBirth: 'Date Of Birth',
  deleteConfirmMessage: 'Are you sure to delete?',
  PartyCode: 'Party Code',
  Position: 'Position',
  endorsementEffDate: 'Endorsement Effective Date',
  reinsurancePremium: 'Reinsurance Premium',
  generationDateFrom: 'Generation Date From',
  quotationDate: 'Quotation Date',
  netPremium: 'Net Premium',
  IdType: 'ID Type',
  RightOfAccessRequest: 'Right Of Access Request',
  SearchCustomer: 'Search Customer',
  IDNumberOrCompanyRegNumber: 'ID Number/Company Reg. Number',
  ShareholderName: 'Shareholder Name',
  status: 'Status',
  RiskAssessment: 'Risk Assessment',
  PtySearchClaimant: 'Search Claimant',
  ddrecurinstallno:
    'Recurring IBAN must be greater than 2 installments.',
  Cancel: 'CANCEL',
  ClaimParty: {
    BankName: 'Bank Name',
    RelateContactError: 'Please Select Relate Contact',
    CancelButton: 'CANCEL',
    BankAccountNo: 'Bank Account Number',
    PartyName: 'Party Name',
    SelectAsPayer: 'SELECT AS PAYER',
    SaveButton: 'SAVE',
    IDType: 'ID Type',
    CreateNewClaimParty: 'Create New Claim Party',
    SelectCommonParty: 'Please select common party',
    AddressDetail: 'Address Details',
    AccountDetail: 'Account Details',
    RoleInfo: 'Role Info',
    EditLink: 'Edit',
    OpenSearchLink: 'NOT EXIST IN  THE  LIST',
    ContactNumber: 'Contact Number',
    ContactInfo: 'Contact Info',
    SwiftCode: 'SWIFT Number',
    PayerTitle: 'Select a Payer',
    AccountInfo: 'Account Info',
    IDNumber: 'ID Number',
    PayeeTitle: 'Select a Payee',
    PartyRole: 'Party Role',
    NotExistInCommonParty: 'NOT EXIST IN COMMON PARTY',
    FirstName: 'First Name',
    AccountHolderName: 'Account Holder Name',
    AccountType: 'Account Type',
    LoadAuthorityError: 'Load Authority Error',
    AddToClaimParty: 'ADD TO CLAIM PARTY',
    AddressInfo: 'Address Info',
    SelectPayeePayer: 'Search Payer/Payee',
    IDNumberForSearch: 'ID Number',
    RelateAccountError: 'Please Select Relate Account',
    EditClaimParty: 'Edit Claim Party',
    SelectfromCommonParty: 'Select from Common Party',
    RelateAddressError: 'Please Select Relate Address',
    CreateAs: 'Create as',
    SelectAsPayee: 'SELECT AS PAYEE',
    ContactEmail: 'Contact Email',
    ClaimPartyMaintenance: 'Claim Party Maintenance',
    IndOrOrgRadio: 'Individual/Organization',
    LastName: 'Last Name',
    AddressType: 'Address Type',
  },
  save: 'Save',
  Upload: 'Upload',
  Confirm: 'Confirm',
  priorityOrder: 'Priority Order',
  waitingforPayment: 'Waiting For Payment',
  TransDetail: 'Trans. Detail',
  endoCancellation: 'Endorsement Cancellation',
  reinstatement: 'Reinstatement',
  ClaimFieldInvestigation: {
    HomeAddress: 'Home Address',
    VictimInfo: 'Injured Person Info',
    specialty: 'Specialty',
    InvestigatorArrivalTime: 'Investigator Arrival Time',
    DisabilityGrade: 'Disability Grade',
    DaysInHospital: 'Days In Hospital',
    Gender: 'Gender',
    investigatorTime: 'Investigation Date and Time',
    registrationDate: 'Registration Date',
    investigatorArrival: 'Investigator Arrival Date and Time',
    Hospital: 'Hospital',
    PlateNo: 'Plate No.',
    ModuleYear: 'Module Year',
    VehicleSynSuccess: 'Vehicle Info is synchronized successfully',
    InjuryDescription: 'Injury Description',
    FieldInvestigation: 'Field Investigation',
    VictimName: 'Injured Person',
    VehicleInfo: 'Vehicle Info',
    Failed: 'Failed',
    MakeYear: 'Make Year',
    SubmitSuccess: 'Field Investigation is submitted successfully',
    FieldInvestigationAddress: 'Field Investigation Address',
    PropertySynSuccess: 'Property Info is synchronized successfully',
    retrieve: 'RETRIEVE',
    remark1: 'Remark 1',
    PropertyDamageDescription: 'Property Damage Description',
    remark2: 'Remark 2',
    OwnerName: 'Owner Name',
    WarnMsg: {
      DischargeShouldLaterThanDateOfAdmission:
        'Date Of Discharge should be later than DateOfAdmission',
      PleaseCheckYourVinNumberImput:
        'please check your Vin Number Imput',
      ValueMustLessThan3000:
        'The value must be less than 3000 characters long',
    },
    ClaimantIsOwner: 'Claimant is Owner',
    dateOfDischarge: 'Date of Discharge',
    investigatorName: 'Investigator Name',
    Maker: 'Make',
    doctorName: 'Doctor Name',
    EngineNumber: 'Engine Number',
    VictimSynSuccess: 'Victim Info is synchronized successfully',
    vinNumber: 'Chassis/VIN Number',
    InjuryType: 'Injured Type',
    RemarkToLossItems: 'Remark to Loss Items',
    Age: 'Age',
    PropertyName: 'Property Name',
    VehicleType: 'Vehicle Type',
    ErrorMsg: {
      PleaseentertheRegistration: 'Please enter the Registration',
      DOTServicenotresponse: 'DOT Service not response.',
      VehicleInformationNotFoundInDOT:
        'Vehicle information not found in DOT ',
      RegistrationNumberFormat:
        'Registration number  with format: XXX000 or XX000 or  X000.',
    },
    RemarktoVictim: 'Remark to Victim',
    SaveSuccess: 'Field Investigation is saved successfully',
    Treatment: 'Treatment',
    VehicleColor: 'Vehicle Color',
    registrationNo: 'Registration No./Plate No.',
    Model: 'Model',
    investigatorTelephone: 'Investigator Telephone',
    whetherRegistered: 'Is the vehicle registered at DOT',
    VIN: 'VIN',
    dateOfAdmission: 'Date of Admission',
    PropertyInfo: 'Property Info',
    manufactureYear: 'Manufacture Year',
  },
  ContactLanguage: 'Contact Language',
  share: 'Share %',
  SAVE: 'SAVE',
  PartyIdIsNull: 'Party Id IsNull',
  policyExpiryDate: 'Policy Expiry Date',
  product: 'Product',
  referenceNo: 'Reference No.',
  AddressTel: 'Address Tel.',
  SearchPartyInfo: 'Search Party Info',
  CreditLimitNoAuthMsg:
    'Credit limit account has no authority to sell the product.',
  Mobile: 'Mobile',
  Nationality: 'Nationality',
  DataAccessRequestByDataSubject:
    'Data Access Request By Data Subject',
  DateFormat: 'MM/DD/YYYY',
  MobileHomeWorkNo: 'Mobile/Home/Work No.',
  transactionDetail: 'Transaction Detail',
  FirstNameLocal: 'First Name Local',
  arapGenerationDate: 'ARAP Generation Date',
  ExpiryDate: 'Expiry Date',
  OSReserve: 'O/S reserve',
  MiddleNameLocal: 'Middle Name Local',
  SSSEmployerId: 'SSS ID/Employer ID',
  maxmessage:
    'Warning:Total OutStanding Payment Premium is {currencySign} {maxPremium}.',
  PtyConfiguration: 'Configuration',
  TaxRegistrationNo: 'Tax Registration No.',
  AdditionalId: 'Additional ID',
  DateFormatBCP: 'MM/DD/YYYY',
  installmentDetail: 'Installment Detail',
  CompanyName: 'Company Name',
  customerType: 'Customer Type',
  ClaimSubrogation: {
    ApprovalDecision: 'Approval Decision',
    LimitationDate: 'Limitation Date',
    TotalSubrogationAmount: 'Total Subrogation Amount',
    WarnMsg: { Warn_0001: "The Subrogation Item can't be empty." },
    SubrogationPanelTitle: 'Subrogation Detail',
    Remark: 'Remark',
    SubrogationRejectSubmitSuccess:
      'Subrogation is rejected successfully',
    Subrogation: 'Subrogation',
    SubrogationApproval: 'Subrogation Approval',
    SubrogationHistory: 'Subrogation History',
    Selected: ' Selected',
    TransactionDate: 'Transaction Date',
    SeqNo: 'Seq No.',
    PleaseSelect: 'Please Select',
    ApprovalHistory: 'Approval History',
    ErrorMsg: { Error_0001: 'Fail', Error_0002: 'failed' },
    SubrogationSubClaimTotalAmount: 'Total Amount',
    SubrogationApprovalSubmitSuccess:
      'Subrogation is approved successfully',
    RemarkForSubrogation: 'Remark for Subrogation',
    ApprovedBy: 'Approved By',
    SelectCoverage: 'Please select Coverage for Subrogation',
    OverdueDate: 'Overdue Date',
    OperatedBy: 'Operated By',
    RecoverFrom: 'Recover From',
    SubrogationAmount: 'Subrogation Amount',
    SubrogationSubmitSuccess:
      'All subrogation tasks need to be sent for senior approval',
    SubrogationHistoryDetail: 'Subrogation History Detail',
  },
  RoleInfo: 'Role Info',
  Reset: 'RESET',
  FileDelete: 'Delete',
  treatyCode: 'Treaty Code',
  UploadFiles: 'Upload Document',
  Calculate: 'Calculate',
  EndorsementDetails: 'Endorsement Details',
  PartySearchCriteria: 'Party Search Criteria',
  creationDate: 'Creation Date',
  DefaultPaymentMethod: 'Default Payment Method',
  MaritalStatus: 'Marital Status',
  leftExperience: 'Left Hand Side of the Road Experience',
  ContractType: 'Contract Type',
  Save: 'Save',
  Detail: 'Detail',
  policyEffectiveDate: 'Policy Effective Date',
  claimNo: 'Claim No.',
  CompanyNo: 'Company Reg. Number',
  searchCriteria: 'Search Criteria',
  generationDate: 'Generation Date',
  WorkHomeTel: 'Work/Home Tel.',
  arapType: 'ARAP Type',
  PolicyLockedByRIWarning:
    'Policy had been locked by RI. Please visit RI system to unlock it.',
  GDPROfficer: 'GDPR Officer',
  xmlFile: 'XML File',
  netReinsurancePremium: 'Net Reinsurance Premium',
  ClaimCalculation: {
    DeductibleAmount: 'Deductible Amount',
    Description: 'Description',
    SameReserveTypeError:
      'You cannot  create 2 records with same subclaim and same Reserve Type',
    Subclaim: 'Subclaim',
    CalculationSubmitSuccess: 'Calculation is submitted successfully',
    AddItem: 'Add Item',
    NoItem: 'Please add Calculation Item',
    RequestAmount: 'Request Amount',
    Remark: 'Remark',
    RecognizedAmount: 'Recognized Amount',
    ExpenseAmount: 'Expense Amount',
    CalculationSaveSuccess: 'Calculation is saved successfully',
    CalculationExpense: 'Calculate Expense',
    CalculationCalculateSuccess:
      'Calculation is calculated successfully',
    ExpenseAmountError:
      'Expense Amount cannot be less than Settled Amount',
    Calculation: 'Calculation',
    Approver: 'Approver',
    Selected: ' Selected',
    TransactionDate: 'Transaction Date',
    DeductibleType: 'Deductible Type',
    SeqNo: 'Seq No',
    PleaseSelect: 'Please Select',
    CalculatedAmount: 'Calculated Amount',
    TotalIndemnityAmount: 'Total Indemnity Amount',
    CalculationHistory: 'Calculation History',
    Amount: 'Amount',
    CalculattionApprovalSubmitSuccess:
      'Calculation Approval is submitted successfully',
    ErrorMsg: {
      Error_0001:
        'The recognized amount is larger than request amount.',
    },
    Calculate: 'CALCULATE',
    InvoiceNo: 'Invoice No.',
    AtleastOneItem: 'At least one subclaim need to be added',
    NumberofUnits: 'Number of Units',
    SettledAmount: 'Settled Amount',
    CalculationPanelTitle: 'Calculation Detail',
    CalculationHistoryDetail: 'Calculation History Detail',
    CalculationAmount: 'Calculation Amount',
    IndemnityAmountError:
      'Adjusted Amount cannot be less than Calculated Amount',
    SelectSubCoverageAndCoverage:
      'Please select Coverage/Sub Coverage for Calculation',
    SelectCoverage: 'Please select Coverage for Calculation',
    LimitMode: 'Limit Mode',
    LimitAndDeductionInfo: 'Limit And Deductible Info',
    IssuedBy: 'Issued by',
    AppraisalAmount: 'Appraisal Amount',
    AdjustedAmount: 'Adjusted Amount',
    CalculateApprove: 'Calculation Approval',
    TotalExpenseAmount: 'Total Expense Amount',
    OutstandingReserve: 'O/S Reserve',
  },
  Create: 'Create',
  OK: 'OK',
  CONFIRM: 'CONFIRM',
  ok: 'OK',
  PtyGroupIdDesc: 'Group ID Description',
  LeftHandSideoftheRoadExperienceYears:
    'Please select the number of years of left hand side drive experience',
  BirthDay: 'Date of Birth',
  TypeOfPolicy: 'Type Of Policy',
  CountryOfIncorporation: 'Nationality/Country of Incorporation',
  SolicitorID: 'Solicitor ID',
  arapNo: 'ARAP No.',
  Clause: 'Clause',
  remarks: 'remarks',
  EffectiveDate: 'Effective Date',
  PRINT: 'PRINT',
  CREATE: 'CREATE',
  FuzzySearch: 'Fuzzy Search',
  CreateParty: 'Create Party',
  ParticipationDetail: 'Participation Detail',
  policyNo: 'Policy Number',
  CLOSE: 'CLOSE',
  History: 'History',
  Document: 'Document',
  Remark: 'Remark',
  wirelessPosAuth: 'wireless POS',
  Print: 'Print',
  reinsurancePremiumBasis: 'Reinsurance Premium Basis',
  EstYear: 'Est. Year',
  FaxNumber: 'Fax Number',
  PrivacyNoticeReceivedByClient: 'Privacy Notice Received By Client',
  idType: 'ID Type',
  DocumentSize: 'Document Size',
  EDIT: 'EDIT',
  NoTreatyByRIWarning:
    'No Treaty Defintion in RI Module. Please visit RI system to Create a Treaty.',
  Close: 'Close',
  transactionDateTo: 'Transaction Date To',
  InsDetail: 'Inst. Detail',
  Bank: 'Bank',
  POSPaymentAuthorization: {
    POSPaymentAuthorization: 'POS Payment Authorization',
  },
  Warning_PartyHasOthePendingQuotations:
    'The party has other pending quotations should must send to UW process',
  ReinsuranceType: 'Reinsurance Type',
  ClaimAppraisal: {
    Delete: 'Delete',
    DoctorName: 'Doctor Name',
    HomeAddress: 'Home Address',
    SparePartCost: 'Spare Part Cost',
    VictimInfo: 'Injured Person Info',
    DisabilityGrade: 'Disability Grade',
    SuccessMsg: {
      Success_0001: 'Appraisal  is submitted successfully',
      Success_0003: 'Property Info is synchronized successfully',
      Success_0002: 'Victim Info is synchronized successfully',
    },
    DaysInHospital: 'Days in Hospital',
    Gender: 'Gender',
    CantGreaterAmount:
      'The absolute value of salvage amount cannot be greater than the total amount of Labor Cost and Spare Part Cost',
    Remark: 'Remark',
    TotalCost: 'Total Cost',
    SubmitTotal: 'SUBMIT',
    TotalSparePartCost: 'Total Spare Part Cost : ',
    DateOfDischarge: 'Date of Discharge',
    LossItem: 'Loss Item',
    NetAmount: 'Net Amount',
    TotalLaborCost: 'Total Labor Cost',
    SyncInfo: 'SYNCHRONIZE INFO',
    Back: 'BACK',
    investigatorArrival: 'Investigation Date and Time',
    Hospital: 'Hospital',
    PlateNo: 'Registration No./Plate No.',
    ModuleYear: 'Registration Date',
    BodyShopAddress: 'Garage Address',
    RemarktoAppraisal: 'Remark to Appraisal',
    SeqNo: 'Seq No.',
    InjuryDescription: 'Injury Description',
    ApprovalHistory: 'Approval History',
    VictimName: 'Injured Name',
    VehicleInfo: 'Vehicle Info',
    DamagePart: 'Damage Part',
    NumberOfLossItems: 'Number Of Loss Items',
    DeductionDesc: 'Deduction Description',
    ProviderPrice: 'Provider Price',
    MakeYear: 'Manufacture Year',
    BodyShopArrTime: 'Garage Arrival Time',
    NewLossItem: 'New Loss Item',
    Specialty: 'Specialty',
    ApprovedBy: 'Operated By',
    Save: 'SAVE',
    ApprovalDecision: 'Approval Decision',
    BodyShopName: 'Garage Name',
    Description: 'Description',
    RemarkVictim: 'Remark to Victim',
    BodyShopPrice: 'Garage Price',
    BodyShopTel: 'Garage Telephone',
    WarnMsg: {
      Warn_0001:
        'Date Of Discharge should be later than Date Of Admission',
      Warn_0002: 'Please check your Age Info',
      Warn_0003: 'The value must be less than 3000 characters long',
      Warn_0004: 'please check your Vin Number Imput',
      Warn_0005: 'Modify Operation Warning',
      Warn_0006: 'Select Claimant first please.',
      Warn_0007: 'Please enter the Garage Name first.',
    },
    ClaimantIsOwner: 'Claimant is Owner',
    AppraisalHistoryDetail: 'Appraisal History Detail',
    TotalNetAmount: 'Total Net Amount',
    PartsName: 'Parts Name',
    Maker: 'Make',
    ClaimAmount: 'Claim Amount',
    EngineNumber: 'Engine Number',
    ContactTel: 'Contact Tel',
    BodyShopContact: 'Garage Contact',
    SalvageValue: 'Salvage Value',
    NetAmt: 'Net Amount',
    Remark2: 'Remark 2',
    Remark1: 'Remark 1',
    InjuryType: 'Injury Type',
    PartsDescription: 'Parts Description',
    DeductionAmount: 'Deduction Amount',
    RemarkToLossItems: 'Remark to Loss Items',
    Age: 'Age',
    EnterPositiveError: 'Enter the amount can not be positive!',
    TransactionDate: 'Transaction Date',
    PropertyName: 'Property Name',
    LossItems: 'Loss Items',
    WorkType: 'Work Type',
    AppraisalHistory: 'Appraisal History',
    ItemDesc: 'Description',
    VehicleType: 'Vehicle Type',
    ErrorMsg: {
      Error_0001: 'failed',
      Error_0002: 'Please enter the Registration',
      Error_0003: 'DOT Service not response.',
      Error_0004:
        'Registration number  with format: XXX000 or XX000 or  X000.',
      Error_0005: 'Invalid Claim Amount',
      Error_0006: 'Claim amount must great than deduction Amount.',
      Error_0007: 'Invalid Deduction Amount',
      Error_0008: 'Deduction Amount must less than claim amount.',
      Error_0009: 'Fail',
    },
    Appraisal: 'Appraisal',
    TotalAmount: 'Total Amount',
    LaborCost: 'Labor Cost',
    UsedSparePart: 'Used Spare Part?',
    DamageType: 'Extent of Damage',
    Decision: 'Decision',
    Treatment: 'Treatment',
    VehicleColor: 'Vehicle Color',
    DateOfAdmission: 'Date of Admission',
    Model: 'Model',
    OperatedBy: 'Operated By',
    whetherRegistered: 'Is the vehicle registered at DOT',
    ModelYear: 'Registration Date',
    VIN: 'Chassis/VIN Number',
    PropertyInfo: 'Property Info',
    CompanyReferencePrice: 'Surveyor Price',
    RemarkToAppraisal: 'Remark to Appraisal',
  },
  DistrictOrCity: 'City',
  DoNotSendEmail: 'Do Not Send Email',
  DELETE: 'DELETE',
  Party: 'Party',
  premiumDue: 'Premium Due',
  Logout: 'Logout',
  currency: 'Currency',
  ClaimReserve: {
    Reserve: 'Reserve',
    All: 'All',
    CoinLeader: 'Leader',
    CalculationResult: 'New Outstanding Reserve',
    AreYouSure: 'Are you sure you want to reopen the reserve?',
    InputPositive: 'Please input positive number',
    Reopeningconfirmation: 'Reopen Confirmation',
    MaxAmountMessage:
      "The  input amount  can't  greater than 999999999.99",
    UpdateReserveSucess: 'Update Reserve is submitted successfully',
    ClosureConfirmation: 'Closure Confirmation',
    ReserveHistory: 'Reserve History',
    Remark: 'Remark',
    SaveAndExit: 'SAVE AND EXIT',
    CategoryOfReserveChange: 'Category of Reserve Change',
    Remarks: 'Remarks',
    Currency: 'Currency',
    AreYouSureCloseItem: 'Are you sure to close the coverage?',
    ReserveUpdateApprovalSuccess:
      'Your Reserve Update Approval for Claim [@ClaimNo] has been approved by [@UserRealName]',
    IncurredAmount: 'Incurred Amount',
    OpenReserveError: 'Please select a Reserve Type you want to open',
    ShareRate: 'Share Rate',
    ReserveType: 'Reserve Type',
    Status: 'Status',
    ReserveClosureApprovalReject:
      'Your Reserve Closure Approval for Claim [@ClaimNo] has been rejected by [@UserRealName]',
    Unlock: 'Unlock',
    AreYouSureCloseCase: 'Are you sure to close the case?',
    RejectedReserveSuccess: 'Reserve Update is rejected successfully',
    CloseReserveSuccess: 'Close Reserve is submitted successfully',
    ClaimFileType: 'Reserve Type',
    ReserveStatus: 'Reserve Status',
    ReserveUpdateApprovalReject:
      'Your Reserve Update Approval for Claim [@ClaimNo] has been rejected by [@UserRealName]',
    OutstandingReserve: 'Outstanding Reserve',
    Operator: 'Operator',
    Action: 'Action',
    OpenReserveSuccess: 'Open Reserve is submitted successfully',
    ReserveCalculation: 'Reserve Update',
    ReserveUpdateApproval: 'Reserve Update Approval',
    Cancel: 'CANCEL',
    ReserveUpdate: 'Update',
    CauseOfReopening: 'Cause of Reopening',
    AreYouSureCloseObject: 'Are you sure to close the subclaim?',
    Settled: 'Settled',
    Locked: 'Locked',
    AreYouSureCloseReserve:
      'Are you sure you want to close the reserve?',
    CauseOfClosure: 'Cause of Closure',
    ReserveClosureApproval: 'Reserve Closure Approval',
    OpenReserve: 'Open Reserve',
    ReasonTypeOfClosure: 'Reason of Closure',
    LockStatus: 'Lock Status',
    NewOutstandingReserve: 'New Outstanding Reserve',
    CoinSurance: 'Co-Insurer',
    ReserveClosureApprovalSuccess:
      'Your Reserve Closure Approval for Claim [@ClaimNo] has been approved by [@UserRealName]',
    InputNegative: 'Please input negative number',
    Date: 'Date',
    ReopenReserveSucess: 'Reopen Reserve is submitted successfully',
    Total: 'Total',
    ChangeAmount: 'Change amount',
    Submit: 'SUBMIT',
    ApprovedReserveSuccess: 'Reserve Update is approved successfully',
    RetroacitveAdjust: 'ME Retro. Adj.',
  },
  email: 'Email',
  SettlementNo: 'Settlement No.',
  EndorsementNo: 'Endorsement No.',
  FirstName: 'First Name',
  addNewNote: 'Add New Note',
  GarageType: 'Garage Type',
  PtyDelCfmMsg: 'Are you sure to delete?',
  IssueDate: 'Issue Date',
  FormerPartyCode: 'Former Party Code',
  RelationshipDetail: 'Relationship Detail',
  broker: 'Broker',
  paidClaims: 'Paid Claims',
  Decision: 'Decision',
  ContactDetail: 'Contact Detail',
  Occupation: 'Occupation',
  LegalSpecialty: 'Legal Specialty',
  SUBMIT: 'SUBMIT',
  policyFee: 'Fees',
  licenseHeldAs: 'License Held as',
  cancelledPremium: 'Cancelled Premium',
  generationDateTo: 'Generation Date To',
  PolicyExpiryDate: 'Policy Expiry Date',
  SettlementType: 'Settlement Type',
  PartyName: 'Party Name',
  IDType: 'IDType',
  paymentAmountTitle: 'Online/POS Payment',
  CreateDate: 'Create Date',
  VIEW: 'VIEW',
  operator: 'Operator',
  policyPeriod: 'Policy Period',
  DataAccess: 'Data Access',
  IBANType: 'IBAN Type',
  LicenseNo: 'License No.',
  Search: 'Search',
  BankAccountDetail: 'Bank Account Detail',
  To: 'To',
  query: 'Query',
  UploadedBy: 'Uploaded By',
  ExitTip: 'EXIT',
  PartyRoleDetail: 'Party Role Detail',
  reinsuranceCommission: 'Reinsurance Commission',
  DataPortabilityRequest: 'Data Portability Request',
  PtyGroupName: 'Group Name',
  QuotationNumber: 'Quotation Number',
  ClaimProduct: {
    FraudSubjectCode: 'Fraud Subject Code',
    New: 'NEW',
    ProductName: 'Product Name',
    CauseofLossDescription: 'Cause of Loss Description',
    SubclaimTypeCode: 'Subclaim Type Code',
    SubclaimTypeDescription: 'Description',
    CoverageDescription: 'Coverage Description',
    SettlementType: 'SettlementType',
    Product: 'Product',
    Remove: 'Remove',
    MultiLanguage: 'Multi-Language',
    ProductTree: 'Product Tree',
    Warn: 'Warning',
    ExtProductTypeCode: 'Policy Sys Code',
    ExtProductCode: 'Policy Sys Code',
    ListofReserveType: 'List of Reserve Type',
    OptionalSubclaimTypes: 'Optional Subclaim Types',
    Search: 'SEARCH',
    ApplicableDamageTypes: 'Applicable Damage Types',
    DefaultCoverage: 'Default Coverage',
    CoverageCategoryCode: 'Coverage Category Code',
    CoverageType: 'CoverageType',
    SumInsuredEditable: 'Sum Insured Editable',
    ReserveType: 'ReserveType',
    ProductLine: 'ProductLine',
    Exit: 'EXIT',
    CoverageDetail: 'Coverage Detail',
    removeProductFailMessage:
      'Current parameter is involved in following relationship. Please make sure relationships are all cleared before you deleting it.',
    PartyRole: 'Party Role',
    ApplicableCoverageCategories: 'Applicable Coverage Categories',
    SettlementTypeManagement: 'Settlement Type Management',
    PolicySysCode: 'Policy Sys Code',
    IsExist: 'is Existed',
    ProductCode: 'Product Code',
    ExtCoverageTypeCode: 'Product Cover Type Code',
    DeleteSuccess: 'Remove Success',
    ProductLineName: 'Product Line Name',
    SubclaimTypeName: 'Subclaim Type Name',
    ProductType: 'ProductType',
    SubclaimTypeManagement: 'Subclaim Type Management',
    ApplicableSubclaimTypes: 'Applicable Subclaim Types',
    ConfirmMessage: 'Are you sure to remove ?',
    ProductTypeName: 'Product Type Name',
    ProductLineDescription: 'Product Line Description',
    CauseofLossName: 'Cause of Loss',
    ApplicableReserveTypes: 'Applicable Reserve Types',
    OptionalSettlementTypes: 'Optional Settlement Types',
    SubclaimType: 'SubclaimType',
    ProductCoverageCategory: 'Product CoverageCategory',
    ClaimFileType: 'Claim File Type',
    ApplicableSettlementTypes: 'Applicable Settlement Types',
    SettlementTypeCode: 'Settlement Type Code',
    Save: 'SAVE',
    OptionalDamageTypes: 'Optional Damage Types',
    SettlementTypeName: 'Settlement Type Name',
    CauseOfLoss: 'Cause of Loss',
    Action: 'Action',
    Description: 'Description',
    OptionalCoverageCategories: 'Optional Coverage Categories',
    AvailableClaimingCoverage: 'Available Claiming Coverage',
    DefaultReserve: 'Default Reserve',
    Cancel: 'CANCEL',
    CoverageName: 'Coverage Name',
    CoverageCategoryDescription: 'Coverage Category Description',
    SubClmTypeDescription: 'Subclaim Type Description',
    IsInsuredObject: 'Constraint to First Party',
    DamageTypeList: 'Damage Type List',
    ProductTypeCode: 'Product Type Code',
    CoverageCategory: 'Coverage Category',
    ReserveTypeName: 'Reserve Type Name',
    ProductLineCode: 'Product Line Code',
    IsUnique: 'IsUnique',
    CoverageCategoryList: 'Coverage Category List',
    ProductTypeDescription: 'Product Type Descripton',
    CoverageCode: 'Coverage Code',
    ReserveTypeDescription: 'Reserve Type Description',
    CoverageCategoryName: 'Coverage Category Name',
    ErrorMsg: { SaveFAIL: 'Save FAIL ' },
    Coverage: 'Coverage',
    CauseofLossCode: 'Cause of Loss Code',
    SettlementTypeList: 'Settlement Type List',
    SortNo: 'Sort No.',
    ReserveTypeCode: 'Reserve Type Code',
    SaveSuccess: 'Save Success!',
    DeleteFail: 'Remove Fail',
    OptionalReserveTypes: 'Optional Reserve Types',
    Positive: 'Positive',
    ExtCoverageCode: 'Policy Sys Code',
    ProductDescription: 'Product Description',
    InfoMsg: {
      PleaseModifyItems: 'Please Modify items',
      Save: 'Save',
    },
  },
  LastName: 'Last Name',
  paymentCurrency: 'Payment Currency',
  next: 'NEXT',
  PolicyNo: 'Policy No.',
  Action: 'Action',
  submit: 'Submit',
  documentType: 'Document Type',
  description: 'Description',
  PtyEdit: 'Edit',
  ENDO: { EndorsementNumber: 'Endorsement Number' },
  interest: 'Interest',
  ConsentDirectMarketing: 'Consent for Direct Marketing',
  Notes: 'Notes',
  PleaseAddGDPRFirst: 'Please Add GDPR First',
  AuthorizationCode: 'Authorization Code',
  HistoryId: 'History ID',
  DocumentType: 'Document Type',
  driverPenaltyPoints: 'Driver Penalty Points',
  View: 'View',
  BreachComments: 'Breach Comments',
  createBy: 'Created By',
  ShareholderPercentage: 'Shareholder Percentage%',
  SearchIntroducer: 'Search Introducer',
  BreachDate: 'Breach Date',
  installmentNo: 'Installment No.',
  BreachData: 'Breach Data',
  offsetCommission: 'Offset Commission',
  Account: { SwiftCode: 'SWIFT Code' },
  VAT: 'VAT',
  PriorityOrder: 'Priority Order',
  newBusiness: 'New Business',
  ServerDateFormat: 'YYYY-MM-DD[T]HH:mm:ss',
  ContactForMarketing: 'Contact For Marketing',
  productName: 'Product Name',
  logout: 'Logout',
  ShareholderInfo: 'Shareholder Info',
  sumInsured: 'Sum Insured',
  FilePreview: 'Preview',
  finalRiRecoveryAmount: 'Final RI Recovery Amount',
  FormarPartyCode: 'Former Party Code',
  vat: 'V.A.T',
  SearchDriver: 'Search Driver',
  JointVentureName: 'Joint Venture Name',
  IBANNo: 'IBAN No.',
  Country: 'Country',
  existingNote: 'Existing Note',
  searchResult: 'Search Result',
  Description: 'Description',
  PartyMaintenance: 'Party Maintenance',
  DOWNLOAD: 'DOWNLOAD',
  RiskUnitLevel: 'Risk Unit Level',
  PrimaryContact: 'Primary Contact',
  CreditLimitWarningMessage:
    'The Credit Limit for the Producer has moved below the threshold. Please revive the Credit Limit.',
  MobileTel: 'Mobile Tel.',
  PtySearchProducer: 'Search Producer',
  MinorIndicator: 'Minor Indicator',
  premium: 'Premium',
  endorsementType: 'Endorsement Type',
  FinalRatePer: 'Final Rate%',
  PtyGroupID: 'Group ID',
  actualPremium: 'Actual Premium',
  ProducerStaffName: 'Producer/Staff Name',
  InactiveDate: 'Inactive Date',
  AdditionalInformation: 'Additional Information',
  ClaimNumber: 'Claim Number',
  ddinstallnocheck:
    'Not allows to select DD payment term for non-installment policy.',
  minMessage:
    'The minimum Payment Amount is {currencySign} {minAmount}.',
  reinsuranceCompany: 'Reinsurance Company',
  ExportToExcel: 'EXPORT TO EXCEL',
  DataBreach: 'Data Breach',
  RightToErasure: 'Right To Erasure',
  UpdatedTime: 'Updated Time',
  policyHolder: 'Policy Holder',
  date: 'Date',
  StreetName: 'Street Name',
  DECISION: 'DECISION',
  endorsement: 'Endorsement',
  ClaimNotice: {
    NoDamageError: 'At least one damage item should be added',
    winnie: 'winnie',
    DateReported: 'Date Reported to Insured',
    WarnMsg: {
      DateToCannotEarlyThanFrom:
        "'Date of Loss to' can not be earlier than 'Date of Loss from'! ",
    },
    DamageAddressDetail: 'Address Detail',
    NoticeNumber: 'Notice Number',
    DamageDescription: 'Damage Description',
    CLAIM: 'CLAIM',
    DateOfLossFromTo: 'Date of Loss from/to',
    Notice: 'Notice Info',
    ClaimNoticePool: 'Search Pending Notice',
    OperationBy: 'Operated By',
    DamageInfo: 'Damage Info',
    ClaimNoticePoolData: 'Pending Notice',
    AccidentDescription: 'Accident Description',
    PolicyNoError: 'Please retrieve first',
  },
  RelationshipType: 'Relationship Type',
  ChannelInfo: 'Producer Info',
  CorrespondenceAddress: 'Correspondence Address',
  licenseIssueDate: 'Driver License Issue Date',
  PrivacyNotice: 'Privacy Notice',
  UPLOAD: 'UPLOAD',
  SearchParty: 'Search Party',
  SharePercentage1: 'Share Percentage%',
  ConsentInfo: 'Consent Info',
  create: 'New',
  DateOfBreach: 'Date Of Breach',
  DocumentName: 'Document Name',
  CountryRegistered: 'Country Registered',
  ProducerType: 'Producer Type',
  endoPremium: 'Endorsement Premium',
  tax: 'Tax',
  ShareholderCategory: 'Shareholder Category',
  Producer: 'Producer',
  QuotationOrPolicyNo: 'Quotation/Policy No.',
  PoBox: 'P.O Box',
  Didyouconsent: 'Did you consent',
  arapStatus: 'ARAP Status',
  BusinessActivity: 'Business Activity',
  CreditLimitErrorMessage:
    'The Credit Limit has been fully utilised, please revive the Credit Limit before issuing the policy!',
  GDPR: 'GDPR',
  clearSuccess: 'Clear is successful.',
  CountryDom: 'Country of Domicile',
  note: 'Note',
  InsPlan: 'Inst. Plan',
  sendToApproval: 'Send to Approval',
  ClaimRegistration: {
    WarnMsg: {
      Warn_0001:
        'Please verify the policy and retrieve a valid policy first',
      Warn_0002: 'Modify Operation Warning ',
      Warn_0003: 'Select Claimant first please.',
    },
    ClaimRegistration: 'Claim Registration',
  },
  PostalCode: 'Postal Code',
  remark: 'Remark',
  FinalRate: 'Final Rate',
  ApartmentNumber: 'Apartment Number',
  ProducerShouldInSalesChannel:
    'Producer Role should be add or edit in the Sales Channel',
  PrivacyNoticeSend: 'Privacy Notice Send',
  Declaration: 'Declaration',
  stamps: 'Stamps',
  amount: 'Amount',
  branchName: 'Branch Name',
  ClaimCommon: {
    Delete: 'DELETE',
    ClaimHandling: 'Claim Handling',
    DailyRentalFee: 'Daily Rental Fee',
    ChangeClaimOwner: 'CHANGE  CLAIM  OWNER',
    Query: 'Query',
    PolicyBranchSearch: 'Policy Branch Search',
    Product: 'Product',
    RentalCarService: 'Rental Car Service',
    AccidentDateTo: 'Accident Date To',
    LastDaysReview: 'Days since Last Review',
    SubclaimInfoRequiredFieldIsMissing:
      'Please complete the required fields in subclaim. ',
    PoliticalID: 'Political ID',
    PoliceStation: 'Police Station',
    authorityResetSuccess: 'Authority is reset successfully',
    ClaimantT: 'Claimant:',
    ShareRate: 'Share Rate%',
    CompanyCare: 'Assistance Company',
    OverDueDate: 'Over Due Task',
    PremiumCollected:
      'The premium on this policy has  been collected. ',
    PolicyBranch: 'Policy Branch',
    Status: 'Status',
    DriverLicenceNumber: 'Driver Licence Number',
    WorkListCode: 'Please input code here',
    RiskCategory: 'Risk Category',
    ApprovalHistory: 'Approval History',
    NoticeofLoss: 'Notice of Loss',
    PolicyEffectiveDataFromTo: 'Policy Effective Date from/to',
    AutomaticAssignmentApproach: 'Automatic Assignment Approach',
    SettledAmount: 'Settled Amount',
    PolicyDetail: 'Policy Detail',
    ClaimSettlementApprovalTask: 'ClaimSettlementApprovalTask',
    TreatyCode: 'Treaty Code',
    PolicyHolderIDNumber: 'Policy Holder ID Number',
    DownLoad: 'DownLoad',
    DocumentSubType: 'Document Sub-Type',
    HelloWorld: 'HelloWorld',
    ManualAssignmentApproach: 'Manual Assignment Approach',
    ApprovalSummay: 'Summary',
    AttachTo: 'Attach to',
    PremiumDetail: 'Premium Detail',
    Note: 'Notes',
    LossDateSectionTo: 'Date of Loss to',
    CauseofLoss: 'Cause of Loss',
    ApprovalDecision: 'Approval Decision',
    PartialFinal: 'Partial/Final',
    Authority: 'Authority',
    Sex: 'Sex',
    NoteContent: 'Content',
    NoteOverview: 'Overview',
    NoteUserType: 'Internal/External',
    NoteLevel: 'Claim/Subclaim',
    TPDriverTel: '3rd Party Driver Telephone No.',
    onlyShowNoTaskOwner: 'Only Show Tasks without Owner',
    ReserveHistoryBlankOption: 'All',
    InsuredId: 'Insured ID',
    Badge: 'Badge',
    WorkListValue: 'Please input name here',
    SearchRecovery: 'Search Recovery',
    LossStatus: 'Loss Status',
    Catastrophe: 'Catastrophe',
    applicableProducts: 'Applicable Products',
    TransactionDate: 'Transaction Date',
    PolicePresence: 'Police Presence',
    Path: 'Path',
    ProductInfo: 'Product Infomation',
    WarnMessage:
      'The pending tasks for this claim are listed below. You can select a task assigned to you to work on',
    CatastropheCheck: 'Catastrophe Code is exist',
    SearchCriteria: 'Search Criteria',
    TaskDetail: 'Task Detail',
    NoofMatchedPolicy: 'No. of Matched Policy',
    AssignRight: 'Assign Right',
    Submit: 'SUBMIT',
    AccidentDate: 'Accident Date',
    TaskQueryCondition: 'Search My Task',
    ClaimResult: 'Claim Search Result',
    Add: 'ADD',
    InsuranceCompany: '3rd Party Insurance Company',
    Deductible: 'Deductible',
    AccidentDateFrom: 'Accident Date From',
    ProductConfiguration: 'Product Configuration',
    ClaimAuthority: 'Claim Authority',
    LicenseNumber: 'License Number',
    TaskList: 'Task List',
    ModificationHistory: 'Modification History',
    PossibleSalvage: 'Possible Salvage',
    Vehicle: 'Vehicle',
    ClaimGenerated: 'Claim Generated',
    SILimit: 'SI/Limit',
    Test: 'test',
    ShowOpenClaimsOnly: 'Show Open Claims Only',
    InsuredIDNumber: 'Insured ID Number',
    ApprovalTaskCodeToName: {
      ClaimReserveCloseApprovalTask:
        'The reserve task need to be sent for senior approval',
      ClaimSalvageApprovalTask:
        'All salvage tasks need to be sent for senior approval',
      ClaimReserveUpdateApprovalTask:
        'The reserve task need to be sent for senior approval',
      ClaimAppraisalPApprovalTask:
        'The appraisal task need to be sent for senior approval',
      ClaimSubrogationApprovalTask:
        'All subrogation tasks need to be sent for senior approval',
      ClaimAppraisalIApprovalTask:
        'The appraisal task need to be sent for senior approval',
      ClaimCalculationApprovalTask:
        'The calculation task need to be sent for senior approval',
      ClaimSettlementApprovalTask:
        'The settlement task need to be sent for senior approval',
      ClaimRecoveryApprovalTask:
        'All recovery tasks need to be sent for senior approval',
      ClaimAppraisalVApprovalTask:
        'The appraisal task need to be sent for senior approval',
      ClaimRefundApprovalTask:
        'All refund tasks need to be sent for senior approval',
    },
    others: 'Others',
    MessageType: 'Message Type',
    MyClaims: 'My Claim',
    ResetAuthority: 'Reset Authority',
    Color: 'Color',
    FirstOr3rdParty: '1st or 3rd Party',
    DetailLocation: 'Address Details',
    ApprovalName: 'Operated By',
    AccidentDefineCode: 'Define Catastrophe Code',
    Brand: 'Brand',
    LawyerTel: 'Lawyer Telephone',
    LocationPostalCode: 'Location Postal Code',
    claimAuthority: 'Claim Authority',
    Dismiss: 'Dismiss',
    RejectVehicle: 'Reject Vehicle',
    ChangeClaimOwnerPage: 'Change Claim Owner',
    CoverageList: 'Coverage Info',
    CreatorName: 'Creator Name',
    TransactionType: 'Transaction Type',
    ClearCache: 'Clear Cache',
    WarnMsg: {
      ProductIsDifferentNeedCheckPolicy:
        'The product on the policy is different from current product, please verify the policy no.',
      PleaseSelectOneResult: 'Please select one result.',
      ClaimIsBeyondYourAuthorityNeedCheck:
        'The new accumulated reserve amount for the claim is beyond your authority,please contact your supervisor to handle it.',
      TheSizeOfFileExceedsTheLimitation:
        'The size of file exceeds the limitation',
      DateToCannotEarlierThanDateFrom:
        "'Date of Loss to' can not be earlier than 'Date of Loss from'! ",
    },
    PlateNoVesselNo: 'Plate No./Vessel No.',
    NoteRecipient: 'Recipient',
    ExpiryDateofDriverLicence: 'Expiry Date of Driver Licence',
    RiskName: 'Risk Name',
    ConflictedSubclaimList: 'Conflicted Subclaim List',
    ContactTel: 'Claim Contact Telephone',
    ClaimContactEmail: 'Claim Contact Email',
    Language: 'Language',
    ClaimSummary: 'Claim Financial Summary',
    PolicyList: 'Policy List',
    Claimant: 'Claimant',
    CountryStateCity: 'Country/State/City',
    Country: 'Country',
    Home: 'Dashboard',
    Age: 'Age',
    SelectAsClaimant: 'SELECT AS CLAIMANT',
    AssignmentPendingTask: 'Pending Tasks',
    InsuredLiability: 'Insured Liability',
    ChangeTaskOwner: 'CHANGE  TASK  OWNER',
    PolicyInfo: 'Policy Info',
    Coverage: 'Coverage',
    PremiunIsPaid: 'Premium Is Paid',
    VisitorNoate: 'Visitor Note',
    Cateogry: 'Accident Category',
    CreateClaim: 'Create Claim',
    PendingNotice: 'Pending Notice',
    NoteDate: 'Date',
    SettlementDetail: 'Settlement Detail',
    SuitType: 'Suit Type',
    LocationDistrict: 'Location District',
    CarRentalTown: 'Car Rental Town',
    Retrieve: 'RETRIEVE',
    ModifySublaimInfo: 'Modify Subclaim Info',
    ContactPerson: 'Claim Contact Person',
    Assignment: 'Tasks',
    InheritedAuthority: 'Inherited Authority',
    VinNoOrPlateNo: 'Reference Number',
    LocationMap: 'Location Map',
    FileDownload: 'Download',
    ChangePassWord: 'Change Password',
    TaskType: 'Task Type',
    AttachmentPanel: 'Upload Document',
    Back: 'BACK',
    Lawyer: 'Lawyer',
    AssignedBy: 'Assigned By',
    TotalRentalFee: 'Total Rental Fee',
    Exit: 'EXIT',
    DateOfBirth: 'Date of Birth of Third Party Driver',
    UploadDate: 'Upload Date',
    AssignmentClosedBy: 'Closed by',
    ProductCode: 'ProductCode',
    DamagePart: 'Damage Part',
    Routes: 'Routes',
    CareOperator: 'Assistance Operator',
    InsuranceOwner: 'Insurance Owner',
    IdType: 'ID Type',
    SubmitSuccess: '{0} is submitted successfully.',
    CliamQueryCondition: 'Search My Claim',
    Id: 'Id',
    Hide: 'Hide',
    Cancel: 'CANCEL',
    Complete: 'COMPLETE',
    NewMessage: 'New Message',
    RtaVehicleClaimNoGenerated:
      'Claim case has already been generated : ',
    Upload: 'Upload',
    ClaimantName: 'Claimant Name',
    NoCoverageSelectedMessage:
      'Please select a coverage for subclaim ',
    TaskOwner: 'Task Owner',
    MassiveReassignmentTaskowner: 'Bulk Reassignment Tasks',
    MassiveReassignmentTaskownerHeader: 'Tasks',
    DeductibleRate: 'Deductible Rate',
    PolicyPeriod: 'Policy Period',
    removeLitigationConfirm:
      'Are you sure you want to remove the suit?',
    NoteCategory: 'Category',
    RentalCarCompany: 'Rental Car Company',
    AssignmentAttachTo: 'Attach to',
    TotalLoss: 'Total Loss',
    duplicateDialog: 'Duplicate Document Check',
    PolicyNumber: 'Policy Number',
    TPDriverIdNumber: '3rd Party Driver ID No.',
    DaysSinceLastReview: 'Days Since Last Review',
    TaskStatus: 'Task Status',
    FPDriverAge: 'Age of 1st Party Driver',
    TaskTransferM: 'Pending Task',
    RENotice:
      'Claim record(s) exists on the same day, do you want to continue?',
    PolicyRetrieveFail: 'Fail to  get the Policy information',
    Insurance: 'Insurance',
    DateFormat: 'MM/DD/YYYY',
    ClaimStatus: 'Claim Status',
    InsuredList: 'Insured Info',
    ReassignFail:
      'Below task(s) are failed to reassign, because current user is without related task authority.',
    ErrorMessage: {
      CLM__UNKNOWN_ERROR:
        'Unknown system error, please contact the administrator',
    },
    AddRiskButton: 'ADD RISK',
    TaskTransferA: 'Manual Task Assignment',
    RelevantPerson: 'Relevant Person',
    MessageBody: 'Message Body',
    New: 'NEW',
    ExpiryDate: 'Expiry Date',
    SubClaimNo: 'Subclaim Number',
    UploadDocument: 'Upload Document',
    OSReserve: 'O/S Reserve',
    ProductName: 'Product Name',
    AccidentInformation: 'Accident Information',
    LawyerEmail: 'Lawyer E-Mail',
    PolicyHolderName: 'Policy Holder Name',
    TPDriverName: '3rd Party Driver Name',
    Registration: 'Registration',
    ShowSearchOptions: 'Show Search Options',
    MatchedPolicyNo: 'Match policy number : ',
    Reset: 'RESET',
    ClaimAuthority: 'Claim Authority',
    FileDelete: 'Delete',
    ClaimAuthorityManager: 'Claim Manager',
    DateT: 'Date:',
    AccurateAccidentLocation: 'Accurate Accident Location',
    DamageDescription: 'Damage Description',
    MessageGenerationDate: 'Message Generation Date',
    LitigationAction: 'Action',
    btnSave: 'SAVE',
    Save: 'SAVE',
    UploadDrivingLicense: 'Upload Driving License',
    InsuredCategory: 'Insured Category',
    taskAuthority: 'Task Authority',
    Detail: 'Detail',
    claimNo: 'claimNo',
    FPDateOfBirth: 'Date of Birth of 1st Party Driver',
    ResinsuranceSummary: 'Resinsurance Summary',
    CheckClaimDataMessage03:
      'Date of Loss cannot be later than current date',
    SubclaimNumber: 'Sub Claim Number',
    CheckClaimDataMessage04:
      'Date of Notification cannot be later than current date.',
    CheckClaimDataMessage01:
      'Date of loss cannot be later than Date of Notification to Insurer.',
    CheckClaimDataMessage02:
      'Date of Notification must be earlier or equal to Date of Registration.',
    LossDateSectionFrom: 'Date of Loss from',
    ErrorMessageB: 'Error Message',
    litigationInfo: 'Litigation Info',
    AddRiskName: 'Add Risk',
    LastReviewDate: 'Last Review Date',
    Clear: 'RESET',
    ChangePolicyMessage:
      'Re-retrieving policy will clear the following information, do you want to continue ',
    MessageGenerationDateFrom: 'Message Generation Date from',
    CloseTask: 'Close Task',
    DateOfLoss: 'Date of Loss',
    Create: 'CREATE',
    SubclaimInfo: 'Subclaim Info',
    UploadOwnerCertificate: 'Upload Owner Certificate',
    DueDate: 'Due Date',
    ClaimantAddress: 'Claimant Address',
    HelloInsure: 'HelloInsure',
    No: 'No',
    SumInsured: 'Sum Insured',
    VisitTime: 'Visit Time',
    AccidentCondition: 'Accident Condition',
    ClaimBranch: 'Claim Branch',
    Litigation: 'Litigation',
    RegNo: 'Reg No.',
    authoritySubmitSuccess: 'Authority is saved successfully',
    Error: {
      ValidateNPCDuration:
        'The date of loss selected falls within the period when the policy was cancelled due to non-payment',
      PolicyInNPCMessage:
        'The policy is in NPC process, it does not allow claims past the overdue date.',
    },
    TotalIncurred: 'Total Incurred',
    litigationList: 'Litigation List',
    SalesChannelInfo: 'Sales Channel Info',
    EffectiveDate: 'Effective Date',
    UploadRentalAggrement: 'Upload Rental Aggrement',
    AssignmentClosedDate: 'Closed Date',
    RejectDialogConfirm: 'Are you sure to reject the current ',
    Configuration: 'Configuration',
    Subclaim: 'Sub Claim',
    assignmentReassignTaskSu: 'Task is reassigned successfully',
    RegistrationNo: 'Registration Number',
    DateofRegistration: 'Date of Registration',
    InsuredObject: 'Insured Object',
    Edit: 'EDIT',
    okButton: 'OK',
    CLOSE: 'CLOSE',
    Document: 'Document',
    TaskTransfer: 'Task Transfer',
    WorkOnTasks: 'Work On Tasks',
    VehicleRegistrationNo: 'Vehicle Registration No.',
    MigratedDriverInfo: 'Migrated Driver Info',
    Remark: 'Remark',
    Reject: 'REJECT',
    SaveFail:
      '{0} is saved failed, please contact system administrator',
    WorKOn: 'WORK ON',
    SyncInfo: 'SYNCHRONIZE INFO',
    Currency: 'Currency',
    permissionCode: 'Permission Code',
    CareCallDate: 'Assistance Call Date',
    LossDateSection: 'Date of Loss from/to',
    SUB: 'SUB',
    AssignTo: 'Assign to',
    Datefromto: 'Date from/to',
    CheckNoticeVersionMessage:
      'The current notice is not the latest version',
    DocumentSize: 'Document Size',
    ProductType: 'Product Type',
    ProductTypeName: 'Product Type Name',
    NewNoiceOfLoss: 'FNOL',
    LossDescription: 'Loss Description',
    LawyerName: 'Lawyer Name',
    SubclaimType: 'Subclaim Type',
    ClaimTitle: 'CLAIM',
    authoritySubmitError: 'Fail',
    RetrievePolicySuccessfully: 'Retrieve the Policy successfully',
    AccidentCity: 'Accident City',
    ShowUnreadMessageOnly: 'Show Un-read Message Only',
    ClaimInfomation: 'Claim Infomation',
    DamageItem: 'Damage Item',
    LicenseDate: 'License Date',
    PoliceNumber: 'Police Number',
    PoliceReport: 'Police Report',
    UploadInsuranceCertificate: 'Upload Insurance Certificate',
    PremiumNotCollected:
      'The premium on this policy has not been collected. ',
    selectAssignTo: 'Please select Assign To',
    PolicyHolder: 'Policyholder',
    SubclaimInitLossIsEmpty:
      'Please input inital reserve for subclaim ',
    TreatyCurrency: 'Treaty Currency',
    PlaceOfInspection: 'Place of Inspection',
    FPDriverTel: '1st Party Driver Telephone No.',
    SettlementNo: 'Settlement No.',
    FraudStatus: 'Fraud Status',
    AddRiskFail: 'The Policy has new Endorsement.',
    LocationPOI: 'Location POI',
    ReMark: 'ReMark',
    NoClaimantError: 'Claimant infomation cannot  be empty.',
    BacktoClaim: 'BACK TO CLAIM',
    FirstName: 'First Name',
    SearchResult: 'Search Result',
    DamageType: 'Damage Type',
    Decision: 'Decision',
    CareServiceTime: 'Assistance Service Date',
    DamageObject: 'Damage Object',
    Settlement: 'Payment',
    AccidentCountry: 'Accident Country',
    RejectReason: 'Reject Reason',
    RiskLevel: 'Risk Level',
    FraudIndicatorTitle: 'Fraud Indicator',
    ApprovalRemarkHolder: 'Please input remark',
    ContactType: 'Claim Contact Type',
    PolicyEffectiveDataTo: 'Policy Effective Date to',
    PolicyExpiryDate: 'Policy Expiry Date',
    SettlementType: 'Settlement Type',
    Click: 'click',
    PartyName: 'Party Name',
    RiskUnitName: 'Risk Unit Name',
    CreateDate: 'Create Date',
    Name: 'Name',
    AccidentComment: 'Accident Comment',
    LitigationStatus: 'Status',
    ConflictedSubclaimMessage:
      'Below subclaims are conflict with updated Cause of Loss. If you confirm to keep change, those subclaim will be closed automatically.',
    PolicyHolderTelNo: 'Policy Holder Mobile/Home/Work Phone No.',
    LitigationButtonRemove: 'Remove',
    ProcessAccident: 'Process Accident',
    Search: 'SEARCH',
    CareProvider: 'Assistance Provider',
    SuitOpenDate: 'Suit Open Date',
    NoRiskSelected: 'At least one Risk must be selected.',
    ClaimOwner: 'Claim Owner',
    MessageQueryCondition: 'Search Message',
    AssignmentNewTask: 'New Task',
    IDCard: 'ID Card Number',
    FPDriverIdNumber: '1st Party Driver ID No.',
    ProductLineName: 'ProductLineName',
    AccidentFromRTA: 'Accident From RTA',
    DuplicateCheck:
      'The selected document already exist.Cannot upload again.',
    UploadedBy: 'Uploaded By',
    MessageResult: 'Message',
    ReLogin: 'please reLogin',
    Common: 'Common',
    CatastropheCode: 'Catastrophe Code',
    PendingTasks: 'Pending Tasks',
    ClaimTask: 'Claim Task',
    LastName: 'Last Name',
    AccidentCode: 'Catastrophe Code',
    DateOfNotice: 'Date of Notification',
    permissionName: 'Permission Name',
    PolicyNo: 'Policy No.',
    Operator: 'Operator',
    Action: 'Action',
    MigrationRemark: 'Migration Remark',
    AccidentLocation: 'Accident Address',
    LocationRoad: 'Location Road',
    SuitConclusion: 'Suit Conclusion',
    Datefrom: 'Date from',
    Yes: 'Yes',
    CoverageName: 'Coverage Name',
    Time: 'Date',
    DownloadDocument: 'Download Document',
    InseredPersonName: 'Insered Person Name',
    Continue: 'CONTINUE',
    selectATaskFirst: 'Please select a task first',
    SalesChannel: 'Sales Channel',
    RejectSubclaim: 'REJECT SUBCLAIM',
    AssignedDate: 'Assigned Date',
    InsuredUid: 'InsuredUid',
    PriorClaimsLink: 'Prior Claims',
    OtherPolicyClaims: 'Other Policy Claims',
    Party: 'Party',
    UserAccountAndAuthority: 'User Account / Authority',
    More: 'More',
    ChangeCauseofLossMessage:
      'Changing cause of loss will clear the following information, do you want to continue ',
    PriorClaims: 'Prior Claims from CLP',
    ClaimsMade: 'Claims Made',
    AssignmentApproach: 'Assignment Approach',
    SearchClaim: 'Search',
    NewOutstandingReserve: 'New Outstanding Reserve',
    RejectAccident: 'Reject Accident',
    ClaimTaskSU: 'Task(s) are claimed successfully',
    DocumentType: 'Document Type',
    Subject: 'Subject',
    RiskInfo: 'Risk Infomation',
    SaveSuccess: '{0} is saved successfully.',
    DeleteSuccess: '{0} is removed successfully.',
    DeleteFail:
      '{0} is removed failed, please contact system administrator',
    Filter: 'SEARCH',
    ClaimTaskFail: 'Task(s) are claimed fail',
    TreeSelect: 'treeSelect',
    EstimatedLoss: 'Estimated Loss',
    LocationType: 'Location Type',
    DipositionAmount: 'Disposition Amount',
    AmountAppealed: 'Amount Appealed',
    MessageDimissed: 'Message is completed successfully',
    DeductibleAmount: 'Deductible Amount',
    ClaimCurrency: 'Claim Currency',
    AccidentFrom3rd: 'Accident From Third Party',
    CatastropheName: 'Catastrophe Name',
    PriorityOrder: 'Priority Order',
    PersonType: 'Person Type',
    ChangeTaskOwnerPage: 'Change Task Owner',
    SharePoolQueryCondition: 'Search Sharing Pool',
    Rejected: 'Rejected',
    CatastropheSaved: 'Catastrophe is created successfully',
    FilePreview: 'Preview',
    Capacity: 'Capacity',
    PolicyEffectiveDate: 'Policy Effective Date',
    Classification: 'Accident Classification',
    assignmentComfirmClosed:
      'Are you sure you want to close the task? Data entered in this task will be lost once the task is closed',
    IncurredAmount: 'Incurred Amount',
    AgencyCode: 'Agency Code',
    UnreadMessages: 'Unread Messages ',
    ProductLine: 'Product Line',
    Fail: 'Fail',
    SuitStatusRemark: 'Suit Status Remark',
    NewNoiceofLoss: 'FNOL',
    ReassignSus: 'Below task(s) are reassigned successfully.',
    CompleteAccident: 'Complete Accident',
    provideValidEmail: 'Please provide valid Lawyer Email',
    Insured: 'Insured',
    NoRecSelected:
      'Please select at least one record for reassignment.',
    ModifyClaimInfo: 'Modify Claim Info',
    LOS: 'LOS',
    AddressLine1: 'Address Line 1',
    Area: 'Area',
    StreetName: 'Street Name',
    NoPolicy: "The policy number doesn't exist.",
    AssignmentReassign: 'REASSIGN',
    MassiveReassignment: 'Bulk Reassignment',
    Level: 'Level',
    RecoverFrom: 'Recover From',
    deleteConfirmText: 'Are you sure to delete this parameter?',
    Description: 'Description',
    MyTask: 'My Task',
    FraudIndicatorAndDesc: 'Fraud Indicators and Description',
    RentalPeriod: 'Rental Period',
    InitialReserve: 'Initial Reserve',
    OrganzationName: 'Organization Name',
    PolicyEffectiveDataFrom: 'Policy Effective Date from',
    AccidentName: 'Catastrophe Name',
    RejectConfirmation: 'Reject Confirmation',
    MatchPolicyNo: 'Match Policy No.',
    NoteSendMessageTo: 'Send Message to',
    Passenger: 'Passenger',
    DateFullFormat: 'MM/DD/YYYY HH:MM',
    ClaimNumber: 'Claim Number',
    MassiveReassignmentClaimowner:
      'Bulk Reassignment Claims',
    MassiveReassignmentClaimownerHeader: 'Claims',
    TPDriverAge: 'Age of 3rd Party Driver',
    NoteBy: 'By',
    AccidentDesc: 'Description',
    AllSubclaimAreRejected:
      'All sub claims are rejected, please reject the claim. ',
    SuitLocation: 'Suit Location',
    AccidentStreet: 'Accident Street',
    ErrorMsg: {
      Error_0001: 'init Assignment Approach Fail',
      Error_0002: 'ERROR',
      GetDocumentError: 'Get Document Error.',
      Error_0003:
        'the total size limitation of documents had been exceeded 25 MB, system can’t allow to upload any more',
      SystemError: 'System Error',
      FailUPPERCASE: 'FAIL',
      DateOfLossIsNotInThePolicyPeriod:
        'Date of Loss is not in the policy period.',
      Failed: 'failed',
      PleaseUploadTheRequiredDocument:
        'Please upload the required document.',
      PleaseSelectTheDecisionOptionFirst:
        "'Please select the decision option first.",
      LoadCodeTableError: 'Load codeTable error.',
      PARSEPERMISSIONCODEERROR: 'PARSE PERMISSIONCODE ERROR',
      UserHasNoAuthorityToRetrievePolicyPleaseCheck:
        'User has no authority to retrieve policy, please check',
      UpdateTaskOwnerPoolToCurrentUser:
        'update taskOwner pool to currentUser',
      GOrMPolicyChangeCertificate:
        'The Policy you retrieve is Group Policy No. or Master Policy No., Pls change to Certificate Policy No.</br>{0}',
      ClaimTaskFailT: 'claimTask Fail:',
      APermissionCannotBeEmpty:
        'A permission cannot be empty, please try again.',
      DownloadError: 'Download error',
      QueryTransactionTypeError: 'QueryTransactionType Error.',
      QueryFail: 'query fail',
    },
    CreateUser: 'Create User',
    AuthorityConfirmReset:
      'Are you sure you want to reset the authority? All the data will be inherited from the higher level by default once reset is confirmed.',
    AccidentTown: 'Accident State',
    NewSubclaimShouldSubmit: 'Please submit new subclaim firstly. ',
    SuitCloseDate: 'Suit Close Date',
    ValidationInfo: 'Validation Info',
    Type: 'Type',
    Reinsurance: 'Reinsurance',
    AssignmentAssignTo: 'Assigned to',
    AccidentStatus: 'Accident Status',
    AccidentDistrict: 'Accident District',
    AccidentValidFlag: 'Active',
    Model: 'Model',
    AuthorizedAmount: 'Authorized Amount',
    AttachmentList: 'Document List',
    DamageInformation: 'Damage Information',
    ApprovalRemark: 'Remark',
    FPDriverName: '1st Party Driver Name',
    MessageDetail: 'Message Detail',
    AssignmentStatus: 'Status',
    DateTo: 'Date to',
    ClaimAuthoritySenior: 'Senior Claim Adjuster',
    ReviewConfirmation: 'REVIEW CONFIRMATION',
    LitigationButtonEdit: 'Edit',
    InsuredName: 'Insured Name',
    SuitStatus: 'Suit Status',
    ClaimQuery: 'Claim search criteria',
    SuccessMsg: { Downloadsuccessfully: 'Download successfully' },
    ClaimAuthorityJunior: 'Junior Claim Adjuster',
    ReassignSubclaimType: 'Subclaim Type',
    SuitCaseNumber: 'Suit Case Number',
    NoRecordFound: 'No Record Found',
    AccidentTime: 'Accident Time',
    ClaimBranchSearch: 'Claim Branch Search',
    ReserveBigThenSumInsuredError:
      'Initial reserve should not be grater than sum insured',
    PolicySearch: 'Policy Search',
    QueryCondition: 'QueryCondition',
    AssignmentReassignTask: 'Reassign Task',
    ClaimInfo: 'Claim Information',
    ClaimMatrixExport: 'Claim Matrix Export/Import',
    SendMessage: 'Send Message',
    DocumentName: 'Document Name',
    IDNumberOrBusinessRegisterNumber:
      'ID Number/Business Register Number',
    TPDateOfBirth: 'Date of Birth of 3rd Party Driver',
    Priority: 'Priority',
    DocumentUpload: 'Documents',
    Diary: 'Diary',
    SettlementApproval: 'Settlement Approval',
    ViewAccident: 'View Accident',
    MessageGenerationDateTo: 'Message Generation Date to',
    AccidentId: 'Accident ID',
    Postcode: 'Postcode',
    AssignmentCloseTask: 'CLOSE TASK',
    ClaimList: 'Claim List',
    ClaimantInfo: 'Claimant Info',
    ApprovalDate: 'Transaction Date',
    PossibleSubrogation: 'Possible Subrogation',
    NotMatchPolicyMsg:
      "The vehicle and/or driver in the provided accident information cannot be matched with an active policy. Please, match the accident xxxxxx with a policy or discard the accident notification as 'Processed'",
    Producer: 'Producer',
    Content: 'Content',
    SuitName: 'Suit Name',
    Field01: 'field01',
    PolicyHolderInfo: 'Policy Holder Info',
    Field04: 'field04',
    Field05: 'field05',
    Field02: 'field02',
    SubclaimHaveSettle:
      'The cause of loss change will not be allowed for existing successful settlement records in the claim!',
    BirthDate: 'Birth Date',
    AssignToSelectBlankOption: 'POOL',
    SAL: 'SAL',
    TPPlateNo: '3rd Party Vehicle Plate No',
    VisitPerson: 'Visit Person',
    PostalCode: 'Postal Code',
    ClaimHandlingTitle: 'Claim {0}',
    AssignmentClosedTask: 'Closed Tasks',
    ReassignClaimSus: 'Below claim(s) are reassigned successfully.',
    InspectionAddress: 'Inspection Address',
    NotPremiumRecord: 'No premium records found on this policy. ',
    Declaration: 'Declaration',
    Injuries: 'Injuries',
    FileUpload: 'File Upload',
    ReassignClaimFail:
      "Below claim(s) are failed to reassign, because current user is without 'Claim Owner' authority for following claim(s).",
    Officer2: 'Officer 2',
    HelloEbao: 'HelloeBao',
    ShareingPool: 'Sharing Pool',
    DiaryDate: 'Diary Date',
    UploadDeclaration: 'UploadD eclaration',
    Officer1: 'Officer 1',
    DeleteMessage: 'Are you sure to delete?',
    From: 'From',
    assignmentNewTaskSu: 'Task is created successfully',
    Date: 'Date',
    NoAssignToSelected: 'Please select one user for reassignment. ',
    DamageSeverity: 'Damage Severity',
    CheckSubClaimTypeMsg:
      'You can only create one subclaim with below subclaim type(s):',
    ContactEmail: 'Contact Email',
    Tel: 'Tel',
    HideSearchOptions: 'Hide Search Options',
    NotMatchPolicyMsgSimple: 'Does not match any valid policy.',
    assignmentCloseTaskSu: 'Task is closed successfully',
    Limit: 'Limit',
    CallTime: 'CallTime',
    RtaVehicleNotiGenerated:
      'Claim nofification has already been generated : ',
    Claim: 'Claim',
  },
  MiddleName: 'Middle Name',
  PartySearch: 'Party Search',
  policyInfo: 'Policy Info',
  dncnNo: 'DN/CN No.',
  PtyTime: 'Time',
  BasicInfo: 'Basic Information',
  originalProducer: 'Original Producer',
  paymentInfo: 'Payment Info',
  AddNotes: 'Add Notes',
  customer: 'Customer',
};
