import {
  UICurrency,
  UIPanel,
  UISmartPanelGrid,
  UIText,
} from 'RainbowUI';
import React, { Component } from 'react';

import ReactMixin from 'react-mixin';
import BindToMixin from 'react-binding';

export default class UpdateCommon extends Component {
  render() {
    const _self = this;
    return (
      <UIPanel panelTitle={_self.props.title}>
        <UISmartPanelGrid column="2">
          <UICurrency
            id="CURRENCY_CODE_NewOutstandingReserve"
            label={i18n.ClaimCommon.NewOutstandingReserve}
            disabled
            unit={window.EU00.getCurrencySignByCurrencyCode(
              _self.props.curreny
            )}
            valueLink={this.bindTo(
              _self.props.model,
              'NewOutstandingReserve'
            )}
          />
          <UIText
            label={i18n.ClaimCommon.Remark}
            disabled
            valueLink={this.bindTo(_self.props.model, 'Remark')}
          />
        </UISmartPanelGrid>
      </UIPanel>
    );
  }

  handlerStoreChange() {}

  componentDidMount() {}

  componentWillUnmount() {}
}

ReactMixin.onClass(UpdateCommon, BindToMixin);
