import CodeTableCstUI from '../constant/CodeTableCstUI';
import ClaimCstUI from '../constant/ClaimCstUI';

export default {
  200: {
    CurrencyCode: 'ClaimCommon.Currency',
    CurrencyCodeCodeTableId: CodeTableCstUI.ClaimCurrencyCode,
    PolicyNo: 'ClaimCommon.PolicyNumber',
    ProductCode: 'ClaimCommon.ProductName',
    ProductCodeCodeTableId: CodeTableCstUI.ClaimProductCode,
    CaseOrganId: 'ClaimCommon.ClaimBranch',
    CaseOrganIdCodeTableId: CodeTableCstUI.Branch,
    ContactType: 'ClaimCommon.ContactType',
    ContactTypeCodeTableId: CodeTableCstUI.ClaimContactType,
    ContactName: 'ClaimCommon.ContactPerson',
    ContactPhone: 'ClaimCommon.ContactTel',
    AccidentTime: 'ClaimCommon.DateOfLoss',
    NoticeTime: 'ClaimCommon.DateOfNotice',
    LossCause: 'ClaimCommon.CauseofLoss',
    LossCauseCodeTableId: CodeTableCstUI.LossCause,
    AccidentCode: 'ClaimCommon.CatastropheCode',
    AccidentCodeCodeTableId: CodeTableCstUI.ClaimAccidentCode,
    InsuredLiability: 'ClaimCommon.InsuredLiability',
    AccidentDistrict: 'ClaimCommon.AccidentDistrict',
    AccidentCountryCode: 'ClaimCommon.AccidentDistrict',
    AccidentCountryCodeCodeTableId: CodeTableCstUI.ClaimPartyCountry,
    AccidentPlace: 'ClaimCommon.AccidentLocation',
    AccidentAddress: 'ClaimCommon.AccidentLocation',
    AccidentDesc: 'ClaimCommon.LossDescription',

    ReporterEmail: 'ClaimRegistration.ReporterEmail',
    ReporterTel: 'ClaimRegistration.ReporterTel',
    ReportedBy: 'ClaimRegistration.ReportedBy',
    ExpertCompany: 'ClaimRegistration.ExpertCompany',
    AdjusterCompany: 'ClaimRegistration.AdjusterCompany',
    OtherAdjusterCompanyName:
      'ClaimRegistration.OtherAdjusterCompanyName',
    Adjuster: 'ClaimRegistration.Adjuster',
    AccidentAddress1: 'ClaimRegistration.AccidentAddress1',
    InsuranceType: 'ClaimRegistration.Reinsurance',
    AgencyBroker: 'ClaimRegistration.AgencyBroker',
    ProfitCenter: 'ClaimRegistration.ProfitCenter',
    ContactEmail: 'ClaimCommon.ContactEmail',
  },
  201: {
    InsuredId: 'ClaimCommon.InsuredObject',
    InsuredIdCodeTableId: CodeTableCstUI.ClaimPolicyInsured,
    IsSalvage: 'ClaimCommon.PossibleSalvage',
    IsSalvageCodeTableId: CodeTableCstUI.PlateFormYesNoYN,
    IsSubrogation: 'ClaimCommon.PossibleSubrogation',
    IsSubrogationCodeTableId: CodeTableCstUI.PlateFormYesNoYN,
    SubclaimType: 'ClaimCommon.SubclaimType',
    SubclaimTypeCodeTableId: CodeTableCstUI.SubclaimType,
    DamageType: 'ClaimCommon.DamageType',
    DamageTypeCodeTableId: CodeTableCstUI.ClaimDamageTypeCode,
    RiskName: 'ClaimCommon.DamageObject',
    DamageSeverity: 'ClaimCommon.DamageSeverity',
    DamageSeverityCodeTableId:
      CodeTableCstUI.ClaimObjectDamageSeverity,
    OwnerId: 'ClaimCommon.ClaimOwner',
    OwnerIdCodeTableId: CodeTableCstUI.ClaimUser,
    EstimatedLossCurrency: 'ClaimCommon.EstimatedLoss',
    EstimatedLossCurrencyCodeTableId:
      CodeTableCstUI.ClaimCurrencyCode,
    EstimatedLossAmount: 'ClaimCommon.EstimatedLoss',
    LitigationFlag: 'ClaimCommon.Litigation',
    LitigationFlagCodeTableId: CodeTableCstUI.PlateFormYesNoYN,
    TotalLossFlag: 'ClaimCommon.TotalLoss',
    TotalLossFlagCodeTableId: CodeTableCstUI.PlateFormYesNoYN,
    WorkshopId: 'ClaimCommon.PlaceOfInspection',
    WorkshopIdCodeTableId: CodeTableCstUI.ClaimPlaceOfInspaction,
    ObjectPlace: 'ClaimCommon.InspectionAddress',
    DamageDesc: 'ClaimCommon.DamageDescription',
    ClaimantId: 'ClaimCommon.Claimant',
    ClaimantName: 'ClaimCommon.ClaimantName',
    ClaimantIdCodeTableId: CodeTableCstUI.ClaimParty,
    ConsequenceOfLoss: 'ClaimRegistration.ConsequenceOfLoss',
  },
  202: {
    SuitName: 'ClaimCommon.SuitName',
    SuitType: 'ClaimCommon.SuitType',
    SuitTypeCodeTableId: CodeTableCstUI.ClaimLitigationType,
    SuitStatus: 'ClaimCommon.SuitStatus',
    SuitStatusCodeTableId: CodeTableCstUI.ClaimLitigationStatus,
    SuitStatusRemark: 'ClaimCommon.SuitStatusRemark',
    SuitCaseNumber: 'ClaimCommon.SuitCaseNumber',
    SuitOpenDate: 'ClaimCommon.SuitOpenDate',
    AmountApealed: 'ClaimCommon.AmountAppealed',
    SuitLocation: 'ClaimCommon.SuitLocation',
    LawyerName: 'ClaimCommon.LawyerName',
    LawyerTel: 'ClaimCommon.LawyerTel',
    LawyerEmail: 'ClaimCommon.LawyerEmail',
    SuitCloseDate: 'ClaimCommon.SuitCloseDate',
    DepositionAmount: 'ClaimCommon.DipositionAmount',
    ObjectId: 'ClaimCommon.AttachTo',
    ObjectIdCodeTableId: ClaimCstUI.LitigationAttachTo,
  },
  203: {
    // REGISTRATION
  },
};
