import { MessageHelper } from 'RainbowUI';
import alt from '../alt';

const SalvageService = require('../service/SalvageService');

class SalvageSubmitAction {
  saveSalvage(salvageData) {
    SalvageService.saveSalvage(salvageData).then(
      datas => {
        let data = datas;
        data = SalvageService.convertSaveFullSalvageData2Negative(
          data
        );
        MessageHelper.success(
          '',
          i18n.ClaimSalvage.SalvageSaveSuccess
        );
        this.dispatch(data);
      },
      data => {
        MessageHelper.error(data.Messages[0].Message, '');
      }
    );
  }

  submitSalvage(TempsalvageData) {
    let salvageData = TempsalvageData;
    const salvageInfo = salvageData.SalvageInfo;
    if (
      !salvageInfo ||
      !salvageInfo.SalvageItem ||
      salvageInfo.SalvageItem.length < 1
    ) {
      MessageHelper.error(i18n.ClaimSalvage.NoItem, '');
      return;
    }

    salvageData = SalvageService.convertSaveFullSalvageData2Negative(
      salvageData
    );
    const result = SalvageService.submitSalvage(salvageData);

    if (result !== 'OK') {
      MessageHelper.error(result.Messages[0].Message, '');
    }
    return result;
  }

  approveSalvage(approveInfo) {
    let flag = false;

    SalvageService.approveSalvage(approveInfo).then(
      data => {
        if (data.Status === 'OK') {
          if (approveInfo.DecisionCode === 'Y') {
            MessageHelper.success(
              i18n.ClaimSalvage.ApprovedSalvageSuccess
            );
          } else {
            MessageHelper.success(
              i18n.ClaimSalvage.RejectedSalvageSuccess
            );
          }
          flag = true;
        } else {
          MessageHelper.error(
            data.Messages[0].Message,
            i18n.ClaimSalvage.ErrorMsg.Error_0001
          );
        }
      },
      data => {
        MessageHelper.error(data.Messages[0].Message, '');
      }
    );
    return flag;
  }
}
export default alt.createActions(SalvageSubmitAction);
