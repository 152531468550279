import {
  UIDateTimePicker,
  UISelect,
  UITextarea,
  UISmartPanelGrid,
  UIPanel,
  UIText,
} from 'RainbowUI';
import DefinedCodeTable from '../../../codetable/CodeTable';
import GroupIdAction from '../../../actions/GroupIdAction';
import CodeTableTextNoValuelink from '../../../../common/component/CodeTableTextNoValuelink';

const PartyVenBasicInfoReadOnly = React.createClass({
  getInitialState() {
    return {
      party: this.props.party,
    };
  },

  onPartyStoreChange(PartyStoreState) {
    if (PartyStoreState.party) {
      this.setState({ party: PartyStoreState.party });
    }
  },

  componentDidMount() {
    if (this.state.party) {
      const groupId = this.state.party.GroupId;
      if (groupId != null && groupId !== '') {
        $("input[name='IdForPartyGroupSearch']").val(groupId);
        const groupIdAction = new GroupIdAction();
        const groupInfo = groupIdAction.searchByGroupId(groupId);
        if (groupInfo) {
          $("input[name='IdForPartyGroupSearch_value']").val(
            groupInfo.GroupName
          );
          $("textarea[name='IdForPartyGroupDesc']").val(
            groupInfo.Description
          );
        }
      }
    }
  },

  render() {
    const basicInfoRender = this.orgBasicInfoRender();
    return (
      <UIPanel panelTitle={i18n.BasicInfo}>{basicInfoRender}</UIPanel>
    );
  },

  orgBasicInfoRender() {
    const { party } = this.state;
    return (
      <UISmartPanelGrid column="3">
        <UISelect
          label={i18n.PartyCategory}
          disabled="true"
          model={party}
          property="PartyType"
          codeTable={DefinedCodeTable.getCategroyCodeTable}
        />
        <UIText
          label={i18n.PartyCode}
          model={party}
          property="PartyCode"
          disabled="true"
        />
        <UIText
          label={i18n.JointVentureName}
          disabled="true"
          model={party}
          property="JointVentureName"
        />
        <UISelect
          label={i18n.ContactLanguage}
          codeTable={DefinedCodeTable.ContactLanguage}
          showBlankOption="false"
          model={party}
          property="LanguagePreferred"
          disabled="true"
        />
        <UISelect
          label={i18n.CountryDom}
          model={party}
          property="CountryDom"
          codeTable={DefinedCodeTable.getCountry}
          disabled="true"
        />
        <UISelect
          label={i18n.CompanyType}
          model={party}
          property="CompanyType"
          codeTable={DefinedCodeTable.getCompanyType}
          disabled="true"
        />
        <UISelect
          label={i18n.BusinessActivity}
          model={party}
          property="BusinessActivity"
          codeTable={DefinedCodeTable.getBusinessType}
          disabled="true"
        />
        <UIDateTimePicker
          label={i18n.EstYear}
          manualInput="true"
          model={party}
          property="EstYear"
          viewMode="years"
          format="YYYY"
          placeHolder="YYYY"
          disabled="true"
        />
        <UIText
          label={i18n.TIC}
          model={party}
          property="TIC"
          disabled="true"
        />
        <UIText
          label={i18n.vat}
          model={party}
          property="VAT"
          disabled="true"
        />
        <UIText
          label={i18n.EmployeeNumber}
          allowChars="0123456789"
          model={party}
          property="NumberofEmployees"
          disabled="true"
        />
        <UIText
          label={i18n.SSSEmployerId}
          model={party}
          property="EmployerId"
          disabled="true"
        />
        <UIText
          label={i18n.FormerPartyCode}
          model={party}
          property="FormerPartyCode"
          disabled="true"
        />
        <UISelect
          label={i18n.DefaultPaymentMethod}
          model={party}
          property="PaymentMethodId"
          codeTable={DefinedCodeTable.getDefaultPaymentMethod}
          required="true"
          validationGroup="UpdateBasicInfo"
          disabled="true"
        />
        <UITextarea
          label={i18n.Description}
          model={party}
          property="Description"
          disabled="true"
        />
        <CodeTableTextNoValuelink
          id="IdForPartyGroupSearch"
          label={i18n.PtyGroupID}
          onIconClick={this.onClickGroupIdSearch}
          onChange={this.onChangeGroupId}
          disabled="true"
        />
        <UITextarea
          id="IdForPartyGroupDesc"
          label={i18n.PtyGroupIdDesc}
          disabled="true"
        />
      </UISmartPanelGrid>
    );
  },
});
export default PartyVenBasicInfoReadOnly;
