import {
  UIText,
  UISelect,
  UIDataTable,
  UIColumn,
  UICurrency,
  UIPercent,
  UIBox,
  UIButton,
  Caller,
  MessageHelper,
  DataContext,
  UIDialog,
} from 'RainbowUI';
import CommonCodeTable from '../../codetable/CodeTable';
import urlConfig from '../../../../UrlConfig';

const commonCodeTable = new CommonCodeTable();

const RiskBroker = React.createClass({
  getInitialState() {
    return {
      TreatyDetail: {},
    };
  },
  componentWillMount() {
    const urlData = DataContext.get('UrlData');
    console.log('urlData===1111', urlData);
    const ids = DataContext.get('Ids');
    const ContractID = urlData.Priority;
    const { RiclaimInfo } = urlData;
    const ClaimCurrency = RiclaimInfo.split(':')[0];
    const SettledAmount = RiclaimInfo.split(':')[1];
    const treatyCode = urlData.TreatyCode.replace(/%26/g, '&');
    const settlementNo = DataContext.get('SettlementNo');
    let results = null;
    if (ids) {
      const url = urlConfig.loadQueryRiDetail + ids;
      console.log('url==========', url);
      Caller.call(url, null, {
        method: 'GET',
        async: false,
        block: true,
      }).then(
        data => {
          if (data.Status === 'OK') {
            const model = JSON.parse(data.Model);
            results = model;
          }
        },
        err => {
          MessageHelper.error(
            err.responseJSON.Messages[0].Message,
            i18n.Reinsurance.ErrorMsg.ERROR
          );
          console.error('========err:', err);
        }
      );
    }
    console.log('results====', results);
    const flag = ids.split(':')[1];
    let TreatyDetail;
    let recoveries = [];
    recoveries = results.RiClaimRecoveries[settlementNo];
    if (flag == '4') {
      TreatyDetail = results.RiPolicyRiskUnitContent;
    } else if (flag == '1') {
      TreatyDetail =
        results.RiPolicyRiskUnitHistory
          .RiPolicyRiskUnitHistoryContent;
    }
    this.setState({
      TreatyCode: treatyCode,
      TreatyDetail,
      ContractID,
      SettledAmount,
      ClaimCurrency,
      Recoveries: recoveries,
    });
  },
  onClickBack() {
    UIDialog.hide('detailMessage');
  },
  render() {
    const _self = this;
    if (_self.state.TreatyCode === 'Facultative Outwards') {
      return (
        <div>
          {_self.renderFacBrokerTable()}
          <UIBox direction="center">
            <UIButton value={i18n.Back} onClick={_self.onClickBack} />
          </UIBox>
        </div>
      );
    }
    if (_self.state.TreatyCode === 'Facultative XOL') {
      return (
        <div>
          {_self.renderXOLBrokerTable()}
          <UIBox direction="center">
            <UIButton value={i18n.Back} onClick={_self.onClickBack} />
          </UIBox>
        </div>
      );
    }
    return (
      <div>
        {_self.renderQuotaShareOrSurplusBrokerTable()}
        <UIBox direction="center">
          <UIButton value={i18n.Back} onClick={_self.onClickBack} />
        </UIBox>
      </div>
    );
  },
  renderQuotaShareOrSurplusBrokerTable() {
    const _self = this;
    const { Recoveries } = this.state;
    const list = {};
    const reinsuranceCode =
      _self.state.TreatyDetail.TreatyCurrencyCode;
    _.each(
      _self.state.TreatyDetail.RiPolicyTreatyCessions,
      riPolicyTreatyCession => {
        if (
          _self.state.TreatyCode == riPolicyTreatyCession.TreatyCode
        ) {
          console.info(
            'renderQuotaShareOrSurplusBrokerTable ',
            riPolicyTreatyCession.TreatyCode
          );
        }
      }
    );
    const dataTableList = [];
    let index = 0;
    const recoveries = Recoveries;
    const treatyCode = _self.state.TreatyCode;
    let currencyCode = reinsuranceCode;
    _.each(recoveries, tmprecovery => {
      const recovery = tmprecovery;
      if (recovery.TreatyCode == treatyCode) {
        recovery.Index = index;
        dataTableList.push(recovery);
        currencyCode = recovery.CurrencyCode;
        index += 1;
      }
    });
    return (
      <UIDataTable
        id="brokerTable"
        value={dataTableList}
        pageable="false"
        indexable="false"
      >
        <UIColumn
          headerTitle={i18n.reinsuranceCompany}
          value="ReinsurerId"
        >
          <UISelect
            codeTable={commonCodeTable.getReinsuranceCompany}
            io="out"
          />
        </UIColumn>

        <UIColumn
          headerTitle={i18n.reinsuranceBroker}
          value="ReinsurerBrokerId"
        >
          <UISelect
            codeTable={commonCodeTable.getReinsuranceBroker}
            io="out"
          />
        </UIColumn>

        <UIColumn
          headerTitle={i18n.share}
          render={data => {
            return (
              <UIPercent
                id={`PERCENT_CODE_SHARE_RATE_${data.Index}`}
                disabled="true"
                model={data}
                property="FacOutShareRate"
                symbolPosition="none"
              />
            );
          }}
        />

        <UIColumn
          headerTitle={i18n.riRecoveryAmount}
          render={data => {
            return (
              <UICurrency
                id={`CURRENCY_CODE_LEFT_1000${data.Index}`}
                model={data}
                property="RecoveryAmount"
                unit={window.EU00.getCurrencySignByCurrencyCode(
                  data.ClaimCurrencyCode
                )}
                disabled="true"
              />
            );
          }}
        />

        <UIColumn
          headerTitle={i18n.exchangeRate}
          render={data => {
            let exchangeRate = Number(data.ExchangeRate).toFixed(6);
            if (_self.props.activeIndex == '1') {
              exchangeRate = 1.0;
            }
            return <UIText io="out" value={exchangeRate} />;
          }}
        />

        <UIColumn
          headerTitle={i18n.finalRiRecoveryAmount}
          render={data => {
            let recoveryAmountTreatyCur = Number(
              data.RecoveryAmountTreatyCur
            ).toFixed(2);
            if (_self.props.activeIndex == '1') {
              recoveryAmountTreatyCur = Number(
                data.RecoveryAmount
              ).toFixed(2);
              currencyCode = data.ClaimCurrencyCode;
            }
            return (
              <UICurrency
                id={`CURRENCY_CODE_LEFT_2000${data.Index}`}
                value={recoveryAmountTreatyCur}
                unit={window.EU00.getCurrencySignByCurrencyCode(
                  currencyCode
                )}
                disabled="true"
              />
            );
          }}
        />
      </UIDataTable>
    );
  },
  renderXOLBrokerTable() {
    const _self = this;
    const commonCodeTable = new CommonCodeTable();
  },
  getExchangeRate(fromCurrencyCode, toCurrencyCode) {
    const url = `${urlConfig.getExchangeRate +
      fromCurrencyCode}:${toCurrencyCode}`;
    let exchangeRate = 1;
    if (toCurrencyCode) {
      Caller.call(url, null, { method: 'GET', async: false }).then(
        data => {
          if (data.Status === 'OK') {
            exchangeRate = data.Model;
          }
        }
      );
    }
    return exchangeRate;
  },
  renderFacBrokerTable() {
    const _self = this;
    const { Recoveries } = this.state;
    const recoveries = Recoveries;
    let index = 0;
    const dataTableList = [];
    _.each(recoveries, tmprecovery => {
      const recovery = tmprecovery;
      if (recovery.IsFacType == true) {
        recovery.Index = index;
        dataTableList.push(recovery);
        index += 1;
      }
    });
    return (
      <UIDataTable
        id="brokerTable"
        value={dataTableList}
        pageable="false"
        indexable="false"
      >
        <UIColumn
          headerTitle={i18n.reinsuranceCompany}
          value="ReinsurerId"
        >
          <UISelect
            codeTable={commonCodeTable.getReinsuranceCompany}
            io="out"
          />
        </UIColumn>

        <UIColumn headerTitle={i18n.broker} value="ReinsurerBrokerId">
          <UISelect
            codeTable={commonCodeTable.getReinsuranceBroker}
            io="out"
          />
        </UIColumn>

        <UIColumn
          headerTitle={i18n.share}
          render={data => {
            const tempId = `PERCENT_CODE_BROKER_SHARE_RATE_${data.Index}`;
            return (
              <UIPercent
                id={tempId}
                io="out"
                model={data}
                property="FacOutShareRate"
                decimalPrecision="6"
              />
            );
          }}
        />

        <UIColumn
          headerTitle={i18n.riRecoveryAmount}
          render={data => {
            const value = Number(data.RecoveryAmount).toFixed(2);
            return (
              <UICurrency
                id={`CURRENCY_CODE_LEFT_1000${data.Index}`}
                value={value}
                unit={window.EU00.getCurrencySignByCurrencyCode(
                  data.ClaimCurrencyCode
                )}
                disabled="true"
              />
            );
          }}
        />

        <UIColumn
          headerTitle={i18n.exchangeRate}
          render={data => {
            let value = Number(data.ExchangeRate).toFixed(6);
            if (_self.props.activeIndex == '1') {
              value = 1.0;
            }
            return <UIText io="out" value={value} />;
          }}
        />

        <UIColumn
          headerTitle={i18n.finalRiRecoveryAmount}
          render={data => {
            let value = Number(data.RecoveryAmountTreatyCur).toFixed(
              2
            );
            if (_self.props.activeIndex == '1') {
              value = Number(data.RecoveryAmount).toFixed(2);
              return (
                <UICurrency
                  id={`CURRENCY_CODE_LEFT_2000${data.Index}`}
                  value={value}
                  unit={window.EU00.getCurrencySignByCurrencyCode(
                    data.ClaimCurrencyCode
                  )}
                  disabled="true"
                />
              );
            }
            return (
              <UICurrency
                id={`CURRENCY_CODE_LEFT_2000${data.Index}`}
                value={value}
                unit={window.EU00.getCurrencySignByCurrencyCode(
                  data.CurrencyCode
                )}
                disabled="true"
              />
            );
          }}
        />
      </UIDataTable>
    );
  },
});
export default RiskBroker;
