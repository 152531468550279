import { ClaimNoticePool } from 'ClaimNotice';
import {
  ClaimDesktop,
  ClaimWorkFlowMessage,
  ClaimWorkFlowMyClaims,
  ClaimWorkFlowMyTask,
  ClaimWorkFlowSharePool,
} from 'ClaimCommon';

const menuDefinitions = {
  NewMessage: <ClaimWorkFlowMessage />,
  PendingNotice: <ClaimNoticePool />,
  MyClaim: <ClaimWorkFlowMyClaims />,
  MyTask: <ClaimWorkFlowMyTask />,
  PoolTask: <ClaimWorkFlowSharePool />,
};

const iClaimDesktop = () => (
  <ClaimDesktop menuDefinitions={menuDefinitions} />
);

export default iClaimDesktop;
