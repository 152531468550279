import { CodeTable, DataContext } from 'RainbowUI';
import CodeTableService from '../service/CodeTableService';

const DefinCodeTable = {
  getCurrencySign() {
    let currencySignCodeTable = DataContext.get(
      'currencySignCodeTable'
    );
    if (currencySignCodeTable) {
      return currencySignCodeTable;
    }

    const settings = { method: 'GET', async: false, block: false };
    const currencySignList = CodeTableService.getCodeTable(
      72926332,
      null,
      settings
    );
    currencySignCodeTable = DefinCodeTable.formatCodeTableByIdDescription(
      currencySignList
    );
    DataContext.put('currencySignCodeTable', currencySignCodeTable);
    return currencySignCodeTable;
  },

  formatCodeTableByIdDescription(mastList) {
    const result = [];
    const filterList = [];
    _.each(mastList, mapObj => {
      if (!_.contains(filterList, mapObj.id)) {
        filterList.push(mapObj.id);
        const v = { id: mapObj.id, text: mapObj.text };
        result.push(v);
      }
    });
    return new CodeTable(result);
  },
};
export default DefinCodeTable;
