import {
  UIPanel,
  CodeTable,
  UISmartPanelGrid,
  UISelect,
} from 'RainbowUI';
import BindToMixin from 'react-binding';

const PolicyProduct = React.createClass({
  mixins: [BindToMixin],

  getInitialState() {
    return {
      policyEntityCriteria: {},
      productList: this.props.productList,
      productNameList: [],
    };
  },

  componentWillMount() {},

  componentWillUnmount() {},
  onStoreStateChange() {},

  getProductNameList() {
    const productNameList = [];
    const { productList } = this.state;
    if (productList && productList.length > 0) {
      productList.forEach(data => {
        if (data.ProductName) {
          productNameList.push({
            id: data.ProductCode,
            text: data.ProductName,
          });
        }
      });
    }

    let productNameListSorted = [];
    productNameListSorted = _.sortBy(productNameList, n => {
      return n.text;
    });
    return new CodeTable(productNameListSorted);
  },

  changeProductName() {
    this.props.parentComponent.refs.AP100PolicyRisk.filterRiskList(
      this.state.policyEntityCriteria
    );
  },
  getDefaultValue(defaultValue) {
    const { policyEntityCriteria } = this.state;
    policyEntityCriteria.ProductCode = defaultValue;
    this.setState({ policyEntityCriteria });
  },
  render() {
    const _self = this;

    return (
      <UIPanel panelTitle={i18n.ClaimCommon.ProductInfo}>
        <UISmartPanelGrid>
          <UISelect
            required="true"
            validationGroup="retrieve_policy_info"
            id="QueryPolicy_ProductCode"
            label={i18n.ClaimCommon.ProductName}
            codeTable={this.getProductNameList}
            valueLink={_self.bindToState(
              'policyEntityCriteria',
              'ProductCode'
            )}
            onChange={_self.changeProductName}
          />
        </UISmartPanelGrid>
      </UIPanel>
    );
  },
});
export default PolicyProduct;
