import {UIPanel,UICell,UIDataTable,UIDateTimePicker,UIColumn,UIBreadCrumb,UIMenuItem,UIDialog,UIRadio,UINumber,MessageHelper,UIUpdatePanel,DataContext,UIText,UISelect,UIButton,UIBox,UISmartPanelGrid,UIDialogFooter} from 'RainbowUI';
import {History} from 'react-router';
import BindToMixin from "react-binding";
import MassiveReassignmentTaskOwnerAction from '../../action/MassiveReassignmentTaskOwnerAction';

var CodeTableAction = require('../../action/CodeTableAction');
var TaskTransferAction = require('../../action/TaskTransferAction');
var CodeTableCstUI = require('../../constant/CodeTableCstUI');

var MassiveReassignmentTaskOwnerDialog = React.createClass({
    mixins:[BindToMixin],
    getInitialState:function(){
        console.log('-----get into init dialog');
        
        return {assignToObj:{},susList:[],failList:[]};
    },
	save:function(){
        
        var claimList1 = DataContext.get('claimList');
        var massiveReassignmentTaskOwnerVO={};
        if(this.state.assignToObj.AssignTo==undefined){
            MessageHelper.info(i18n.ClaimCommon.NoAssignToSelected,i18n.ClaimCommon.MassiveReassignmentTaskowner,MessageHelper.POSITION_TOP_FULL_WIDTH);
            return ;        
        }
        massiveReassignmentTaskOwnerVO.ClaimList = claimList1;
        massiveReassignmentTaskOwnerVO.AssignTo = this.state.assignToObj.AssignTo;
        var result = MassiveReassignmentTaskOwnerAction.submitAssginTo(massiveReassignmentTaskOwnerVO);
        console.log('result===============',result);
        UIDialog.hide('massiveReassignmentTaskOwnerDialog');
        UIDialog.show('reassignToResultDialog');
        this.setState({susList:result.susList});  
        this.setState({failList:result.failList});
        // if(result.status=='S'){
        //     {this.props.updateparentUI()};
        // }
        
    },
    okToClose:function(){
        UIDialog.hide('reassignToResultDialog');
        this.props.updateparentUI();
    },
    userShow:function(){
        return TaskTransferAction.TaskAuthoryUserList();
        {/*var code = new CodeTable([
            {id:'clm_SH',text:'clm_SH'},
            {id:'general',text:'general'},
            {id:'clm',text:'clm'}
        ]);
        return code;*/}
    },
	render:function(){
        console.log('----in render dialog ');
		return(
            <div>
			<UIDialog id="massiveReassignmentTaskOwnerDialog" width="500px" height="600px" modal="false" title={i18n.ClaimCommon.ChangeTaskOwnerPage}>
                <div>
                    <UIUpdatePanel id="reassignTo" render={this.renderDialogContent}/>
                </div>
                <br/>
                <br/>
                <br/>
                <UIBox direction="center">
                        <UIButton value={i18n.ClaimCommon.Submit} onClick={this.save} />
                        <UIButton value={i18n.ClaimCommon.Cancel}  buttonType='cancel'/>
                </UIBox>
            </UIDialog>
            <UIDialog id="reassignToResultDialog" width="1000px" onClose={this.props.updateparentUI} modal="false" title={i18n.ClaimCommon.ChangeTaskOwnerPage}>
                <div>
                    <UIUpdatePanel id="reassignToResult" render={this.renderReassignToResult}/>
                </div>
                <UIBox direction="center">
                        <UIButton value={i18n.ClaimCommon.okButton} onClick={this.okToClose} position="right"/>
                </UIBox>
            </UIDialog>
            </div>
		);
	},
	renderDialogContent:function(){
        return(
            <div id="massiveReassignmentTaskOwnerdiv" column="1">
            	<UICell>
                    
                    <UICell width="12">
                    <UISelect id="assignTo" label={i18n.ClaimCommon.AssignTo} value="assignee" codeTable={this.userShow} valueLink={this.bindToState("assignToObj", "AssignTo")}/>
                    </UICell>
                </UICell>
                <UICell>
                    <UICell width="6">
                    <UIText id="occupytheline" label="   " value="   " io="out"/>
                    </UICell>
                    <UICell width="6">
                    </UICell>
                </UICell>
            </div>
    		);
    },
    renderReassignToResult:function(){
        return(
            <div id="reassignToResultdiv">
                <ol style={{listStyle:'none',paddingLeft: '5px'}}>
                    <li>
                        <span>
                                {i18n.ClaimCommon.ReassignSus}
                        </span>
                    </li>
                </ol>
                <UIDataTable id="reassignToSusList" indexable="false" styleClass="default" headerTitle={i18n.ClaimCommon.TaskList} pageable="false" value={this.state.susList} >
                    <UIColumn headerTitle={i18n.ClaimCommon.TaskType} value="TaskDefinitionKey">
                        <UISelect codeTable={CodeTableAction.loadDataList(CodeTableCstUI.CLAIMTASK,null)} io="out"/>
                    </UIColumn>
                    <UIColumn headerTitle={i18n.ClaimCommon.TaskOwner} value="TaskAssignee">
                        <UISelect codeTable={CodeTableAction.loadDataList(CodeTableCstUI.ClaimUser,null)} io="out"/>
                    </UIColumn>
                    <UIColumn headerTitle={i18n.ClaimCommon.ClaimOwner} value="ClaimOwner">
                       <UISelect codeTable={CodeTableAction.loadDataList(CodeTableCstUI.ClaimUser,null)} io="out"/>
                    </UIColumn>
                    <UIColumn headerTitle={i18n.ClaimCommon.ClaimNumber} value="ClaimNo"/>
                    <UIColumn headerTitle={i18n.ClaimCommon.CreateDate} value="TaskCreateTime">
                        <UIDateTimePicker placeHolder="MM/DD/YYYY HH:mm:ss" format="MM/DD/YYYY HH:mm:ss" io="out"/>
                    </UIColumn>
                    <UIColumn headerTitle={i18n.ClaimCommon.DueDate} value="TaskDueTime">
                        <UIDateTimePicker placeHolder="MM/DD/YYYY HH:mm:ss" format="MM/DD/YYYY HH:mm:ss" io="out"/>
                    </UIColumn>
                    <UIColumn headerTitle={i18n.ClaimCommon.Priority} value="TaskPrioriry">
                        <UISelect codeTable={CodeTableAction.loadDataList(CodeTableCstUI.Prioriry,null)} io="out"/>
                    </UIColumn>
                </UIDataTable>
                <ol style={{listStyle:'none',paddingLeft: '5px'}}>
                    <li>
                        <span>
                            {i18n.ClaimCommon.ReassignFail}
                        </span>
                    </li>
                </ol>
                <UIDataTable id="reassignToFailList" indexable="false" styleClass="default" headerTitle={i18n.ClaimCommon.TaskList} pageable="false"  value={this.state.failList} >
                    <UIColumn headerTitle={i18n.ClaimCommon.TaskType} value="TaskDefinitionKey">
                        <UISelect codeTable={CodeTableAction.loadDataList(CodeTableCstUI.CLAIMTASK,null)} io="out"/>
                    </UIColumn>
                    <UIColumn headerTitle={i18n.ClaimCommon.TaskOwner} value="TaskAssignee">
                        <UISelect codeTable={CodeTableAction.loadDataList(CodeTableCstUI.ClaimUser,null)} io="out"/>
                    </UIColumn>
                    <UIColumn headerTitle={i18n.ClaimCommon.ClaimOwner} value="ClaimOwner">
                       <UISelect codeTable={CodeTableAction.loadDataList(CodeTableCstUI.ClaimUser,null)} io="out"/>
                    </UIColumn>
                    <UIColumn headerTitle={i18n.ClaimCommon.ClaimNumber} value="ClaimNo"/>
                    <UIColumn headerTitle={i18n.ClaimCommon.CreateDate} value="TaskCreateTime">
                        <UIDateTimePicker placeHolder="MM/DD/YYYY HH:mm:ss" format="MM/DD/YYYY HH:mm:ss" io="out"/>
                    </UIColumn>
                    <UIColumn headerTitle={i18n.ClaimCommon.DueDate} value="TaskDueTime">
                        <UIDateTimePicker placeHolder="MM/DD/YYYY HH:mm:ss" format="MM/DD/YYYY HH:mm:ss" io="out"/>
                    </UIColumn>
                    <UIColumn headerTitle={i18n.ClaimCommon.Priority} value="TaskPrioriry">
                        <UISelect codeTable={CodeTableAction.loadDataList(CodeTableCstUI.Prioriry,null)} io="out"/>
                    </UIColumn>
                </UIDataTable>
            </div>
            );
    },


});
export default  MassiveReassignmentTaskOwnerDialog;
