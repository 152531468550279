import alt from '../alt';
import SettlementCancelAction from '../action/SettlementCancelAction';
import SettlementApproveAction from '../action/SettlementApproveAction';

class SettlementCancelStore {
  constructor() {
    this.cancelInfo = {
      SettlePayeeId: null,
      Remark: '',
    };
    this.approveInfo = {
      BusinessId: null,
      TaskInstanceId: null,
      Remark: '',
      DecisionCode: null,
    };

    this.bindListeners({
      onCancelInit: [SettlementCancelAction.SHOW],
      onApproveInit: [SettlementApproveAction.INIT],
    });
  }

  onCancelInit(settlePayeeId) {
    this.cancelInfo.SettlePayeeId = settlePayeeId;
    this.cancelInfo.Remark = '';
  }

  onApproveInit(approveInfo) {
    this.approveInfo = approveInfo;
    this.approveInfo.Remark = '';
    this.approveInfo.DecisionCode = null;
  }
}
export default alt.createStore(
  SettlementCancelStore,
  SettlementCancelStore
);
