import {
  UIButton,
  UIConfirmDialog,
  UIDialogFooter,
  UIDialog,
} from 'RainbowUI';
import React from 'react';
import BindToMixin from 'react-binding';

const PolicyAction = require('../../action/PolicyAction');
const PolicyDetail = require('./PolicyDetail');
const PolicyStore = require('../../store/PolicyStore');

const PolicyDetailDialogColls = React.createClass({
  mixins: [BindToMixin],
  getInitialState() {
    const ip = window.location.href;
    const { hash } = window.location;
    return {
      url: ip.replace(hash, '#/policy/detail?policyNo='),
      policyEntity: {},
      policyProductRisk: {},
      retrieveInfo: {},
    };
  },
  componentWillMount() {
    PolicyStore.listen(this.onStoreStateChange);
  },

  componentWillUnmount() {
    PolicyStore.unlisten(this.onStoreStateChange);
  },

  onStoreStateChange(storeState) {
    if (!_.isEmpty(storeState.policyInfo)) {
      this.setState({ policyEntity: storeState.policyInfo });
    }
  },

  confirmButton() {
    UIDialog.hide('confirmDialog0_policyColls');
    UIDialog.show('policyDetail_dialogColls');
  },
  showDialogColls(retrieveInfo, PolicyEntity) {
    const pass = PolicyAction.checkPolicy(retrieveInfo, PolicyEntity);
    this.setState({
      retrieveInfo,
      policyEntity: PolicyEntity,
    });
    if (pass === 'WARN') {
      UIDialog.show('confirmDialog0_policyColls');
    }
    if (pass === 'OK') {
      UIDialog.show('policyDetail_dialogColls');
    }
  },

  copyPolicy() {
    if (
      this.state.policyEntity.InsuredList &&
      this.state.policyEntity.InsuredList.length > 0
    ) {
      for (
        let i = 0;
        i < this.state.policyEntity.InsuredList.length;
        i += 1
      ) {
        for (
          let j = 0;
          j <
          this.state.policyEntity.InsuredList[i].CoverageList.length;
          j += 1
        ) {
          delete this.state.policyEntity.InsuredList[i].CoverageList[
            j
          ].dataIndex;
        }

        delete this.state.policyEntity.InsuredList[i].dataIndex;
      }
    }

    this.props.parentComponent.props.parentComponent.updatePolicyInfo(
      this.state.policyEntity
    );
    UIDialog.hide('policyDetail_dialogColls');
  },

  getParentComponent() {
    return this;
  },

  render() {
    const { Url, retrieveInfo, policyEntity } = this.state;
    const url = Url + retrieveInfo.PolicyNo;

    return (
      <div>
        <UIConfirmDialog
          id="confirmDialog0_policyValidationColls"
          title={i18n.CallbackDialog}
          message={i18n.ClaimCommon.RENotice}
          confirmText={i18n.OK}
          cancelText={i18n.Cancel}
          onConfirm={this.confirmButton}
        />

        <UIDialog
          id="confirmDialog0_policyColls"
          title={i18n.CallbackDialog}
          width="500px"
          height="auto !important"
        >
          <div className="message">
            <span
              className="fa fa-exclamation-circle fa-3x"
              style={{ color: '#ff9800' }}
            />
            <span
              style={{ verticalAlign: 'top', paddingLeft: '10px' }}
            >
              {i18n.ClaimCommon.RENotice}
            </span>
          </div>

          <UIDialogFooter>
            <UIButton
              value={i18n.ClaimCommon.Yes}
              onClick={this.confirmButton}
              styleClass="success"
              icon="fa fa-check"
            />
            <UIButton
              value={i18n.ClaimCommon.Cancel}
              onClick={this.cancel}
              styleClass="danger"
              icon="fa fa-ban"
            />
          </UIDialogFooter>
        </UIDialog>

        <UIDialog id="policyDetail_dialogColls" title="" width="95%">
          <PolicyDetail
            policyInfo={policyEntity}
            retrieveInfo={retrieveInfo}
          />

          <UIDialogFooter>
            <UIButton
              value={i18n.ClaimCommon.Continue}
              onClick={this.copyPolicy}
            />
            <UIButton
              value={i18n.ClaimCommon.Cancel}
              onClick={this.cancel}
            />
          </UIDialogFooter>
        </UIDialog>
      </div>
    );
  },
  cancel() {
    UIDialog.hide('confirmDialog0_policyColls');
    UIDialog.hide('policyDetail_dialogColls');
  },
});
export default PolicyDetailDialogColls;
