const home = `${env.serverUrl}`;
const reserve = `${home}reserve`;
const urlDefine = {
  // ClaimCaseClose:home+'common/status/closeCase',
  // ClaimObjectClose:home+'common/status/closeObject',
  // ClaimItemClose:home+'common/status/closeItem',
  // ClaimReserveClose:home+'common/status/closeReserve',
  ClaimCaseClose: `${reserve}/closeCase`,
  ClaimObjectClose: `${reserve}/closeObject`,
  ClaimItemClose: `${reserve}/closeItem`,

  ClaimReserveClose: `${reserve}/closeReserve`,

  ReserveLoad: `${reserve}/`,
  ReserveCalculate: `${reserve}/calculate`,
  ReserveHistory: `${home}reservehis/`,
  UpdateReserveHistory: `${home}reservehis/updateReserveHistory`,
  ReserveOpen: `${reserve}/openReserve`,
  ReserveType: `${reserve}/reserveType/`,
  ReserveReopen: reserve,

  ReserveApprovalTask: `${home}workflow/claimTasks/`,

  ReserveChange: `${reserve}/reserveChange/`,
  ReserveApprovalInfo: `${reserve}/reserveUpdateApproval`,
  ReserveClosureApprovalInfo: `${reserve}/reserveCloseApproval`,
};
export default urlDefine;
