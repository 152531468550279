import { UIParam, UIPanel, UILink } from 'RainbowUI';
import BindToMixin from 'react-binding';
import PropTypes from 'prop-types';

const CalculationItemInfo = require('./CalculationItemInfo');
const CalculationExpenseInfo = require('./CalculationExpenseInfo');
const CalculationLoadAction = require('../action/CalculationLoadAction');

const CalculationCoverageInfo = React.createClass({
  mixins: [BindToMixin],

  propTypes: {
    otherInfo: PropTypes.object.isRequired,
    insured: PropTypes.object.isRequired,
    coverage: PropTypes.object.isRequired,
    calculationInfo: PropTypes.object.isRequired,
    readOnly: PropTypes.bool,
    isApproveTask: PropTypes.bool,
  },

  getCurrentCalculationItem() {
    const { items } = this.bindArrayTo(
      this.props.calculationInfo,
      'CalculationItem'
    );
    const currentItems = new Array();
    const insuredId = this.props.insured.value.InsuredId;
    const coverageCode = this.props.coverage.CoverageCode;
    for (let i = 0; i < items.length; i += 1) {
      if (
        items[i].value.InsuredId == insuredId &&
        items[i].value.CoverageCode == coverageCode
      ) {
        currentItems.push(items[i].value);
      }
    }
    return currentItems;
  },

  getCurrentCalculationExpense() {
    const { items } = this.bindArrayTo(
      this.props.calculationInfo,
      'CalculationExpense'
    );
    const currentExpenses = new Array();
    const insuredId = this.props.insured.value.InsuredId;
    const coverageCode = this.props.coverage.CoverageCode;
    for (let i = 0; i < items.length; i += 1) {
      if (
        items[i].value.InsuredId == insuredId &&
        items[i].value.CoverageCode == coverageCode
      ) {
        currentExpenses.push(items[i].value);
      }
    }
    return currentExpenses;
  },

  render() {
    const {
      componentId,
      readOnly,
      isApproveTask,
      insured,
      coverage,
      history,
      otherInfo,
    } = this.props;
    const insuredId = insured.value.InsuredId;
    const coverageCode = coverage.CoverageCode;
    const suffixId = `cov${insuredId}_${coverageCode.replace(
      /\./g,
      '_'
    )}_${readOnly}_${isApproveTask}${componentId}`;
    const calculationItem = this.getCurrentCalculationItem();
    const calculationExpense = this.getCurrentCalculationExpense();
    let style;
    if (history) {
      style = 'default';
    } else {
      style = 'primary';
    }
    if (!calculationItem || calculationItem.length < 1) {
      return <div />;
    }
    let limit_deduction;
    if (isApproveTask) {
      limit_deduction = (
        <UILink
          id={`${suffixId}ladi`}
          value={i18n.ClaimCalculation.LimitAndDeductionInfo}
          style={{ color: '#FFF' }}
          onClick={event =>
            CalculationLoadAction.showLimitDialog.apply(null, [
              event,
              'cacl',
            ])
          }
        >
          <UIParam name="insuredId" value={insuredId} />
          <UIParam name="coverageCode" value={coverageCode} />
        </UILink>
      );
    } else {
      limit_deduction = readOnly ? (
        <UILink id={`${suffixId}ladi`} value="" />
      ) : (
        <UILink
          id={`${suffixId}ladi`}
          value={i18n.ClaimCalculation.LimitAndDeductionInfo}
          style={{ color: '#FFF' }}
          onClick={event =>
            CalculationLoadAction.showLimitDialog.apply(null, [
              event,
              'cacl',
            ])
          }
        >
          <UIParam name="insuredId" value={insuredId} />
          <UIParam name="coverageCode" value={coverageCode} />
        </UILink>
      );
    }
    return (
      <div id={`${suffixId}_div`} style={{ marginTop: '20px' }}>
        <UIPanel
          panelTitle={`${insured.value.InsuredName}_${coverage.CoverageName}`}
          styleClass={style}
          panelHeader={<span>{limit_deduction}</span>}
        >
          <CalculationItemInfo
            otherInfo={otherInfo}
            insuredId={insuredId}
            coverageCode={coverageCode}
            calculationItem={calculationItem}
            readOnly={readOnly}
            isApproveTask={isApproveTask}
            componentId={componentId}
          />
          {/* {uiSwitch} */}
          <div
            id={`${suffixId}_switchExpense`}
            style={
              calculationExpense.length > 0
                ? { display: 'block' }
                : { display: 'none' }
            }
          >
            <CalculationExpenseInfo
              otherInfo={otherInfo}
              insuredId={insuredId}
              coverageCode={coverageCode}
              calculationExpense={calculationExpense}
              readOnly={readOnly}
              isApproveTask={isApproveTask}
              componentId={componentId}
            />
          </div>
        </UIPanel>
      </div>
    );
  },
});
export default CalculationCoverageInfo;
