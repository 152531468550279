import { UILink, UIParam, UIDialog, UpdateContext } from 'RainbowUI';
import ClaimCommonExt from 'ClaimCommonExt';

const ClaimCstUI = require('../../constant/ClaimCstUI');
const ClaimHandlingAction = require('../../action/ClaimHandlingAction');

const linkStyle = { color: 'white', marginRight: '10px' };

const HandlingLinks = React.createClass({
  getParentComponent() {
    return this;
  },

  render() {
    let otherLinks = [];
    const {
      level,
      relateStatusCode,
      reserveStatus,
      links,
      showReserve,
      modifyClaimInfoAuth,
      relateId,
      clickModifyHistory,
      modifySubClaimInfoAuth,
      subclaimIndex,
      isMotor,
      reserveCloseAuth,
      parentComponent,
    } = this.props;
    const ReserveLight = ClaimCommonExt.getReserveLight();
    const sublinkDisable = relateStatusCode === 'CLOSED';
    let caseLinkDisable = false;
    if (reserveStatus) {
      caseLinkDisable = reserveStatus[0] === 'CLOSED';
    }
    if (links && links.length > 0) {
      otherLinks = links.map((link, index) => {
        if (link.params && link.params.length > 0) {
          const paramCom = link.params.map(param => {
            return <UIParam name={param.name} value={param.value} />;
          });
          return (
            <UILink
              id={`handlingLink${index}`}
              value={link.name}
              style={linkStyle}
              onClick={link.click}
            >
              {paramCom}
            </UILink>
          );
        }
        return (
          <UILink
            value={link.name}
            style={linkStyle}
            onClick={link.click}
          />
        );
      });
    }

    if (showReserve === 'true') {
      if (level == ClaimCstUI.CLAIM_LEVEL) {
        otherLinks.push(
          <UILink
            value={i18n.ClaimCommon.ModifyClaimInfo}
            style={linkStyle}
            onClick={ClaimHandlingAction.modifyClaimInfo}
            disabled={
              caseLinkDisable || modifyClaimInfoAuth == false
                ? 'true'
                : 'false'
            }
          >
            <UIParam name="caseId" value={relateId} />
          </UILink>
        );
        otherLinks.push(
          <UILink
            value={i18n.ClaimCommon.ModificationHistory}
            style={linkStyle}
            onClick={clickModifyHistory}
          >
            <UIParam name="objectId" value={relateId} />
            <UIParam
              name="auditEventType"
              value={ClaimCstUI.AUDIT_EVENT_TYPE_CLAIM}
            />
          </UILink>
        );
      } else if (level == ClaimCstUI.SUBCLAIM_LEVEL) {
        otherLinks.push(
          <UILink
            value={i18n.ClaimCommon.ModifySublaimInfo}
            style={linkStyle}
            onClick={this.modifySubclaim}
            disabled={
              sublinkDisable || modifySubClaimInfoAuth == false
                ? 'true'
                : 'false'
            }
          >
            <UIParam name="subclaimIndex" value={subclaimIndex} />
          </UILink>
        );
        otherLinks.push(
          <UILink
            value={i18n.ClaimCommon.ModificationHistory}
            style={linkStyle}
            onClick={clickModifyHistory}
          >
            <UIParam name="objectId" value={relateId} />
            <UIParam
              name="auditEventType"
              value={ClaimCstUI.AUDIT_EVENT_TYPE_SUBCLAIM}
            />
          </UILink>
        );
      }
    }
    if (level == ClaimCstUI.SUBCLAIM_LEVEL) {
      otherLinks.push(
        <UILink
          value={i18n.ClaimCommon.FraudIndicatorTitle}
          style={linkStyle}
          onClick={this.openFraudIndicator}
          disabled
        >
          <UIParam name="objectId" value={relateId} />
          <UIParam name="subclaimIndex" value={subclaimIndex} />
        </UILink>
      );
    }
    return (
      <div>
        {otherLinks}
        <ReserveLight
          subclaimIndex={subclaimIndex}
          relateStatusCode={relateStatusCode}
          reserveStatus={reserveStatus}
          reserveCloseAuth={reserveCloseAuth}
          relateId={relateId}
          level={level}
          parentComponent={parentComponent}
        />
      </div>
    );
  },

  exit() {
    window.close();
  },

  modifySubclaim(event) {
    sessionStorage.setItem(
      'ModifySubclaim_index',
      event.getParameter('subclaimIndex')
    );
    UIDialog.show('ModifySubclaimInfo_Dialog');
  },
  openFraudIndicator(event) {
    sessionStorage.setItem(
      'ModifySubclaim_index',
      event.getParameter('subclaimIndex')
    );
    sessionStorage.setItem(
      'fraudObjectId',
      event.getParameter('objectId')
    );
    UIDialog.show('claimfraudIndicatorDialog');
  },
  openDriverRemark(event) {
    sessionStorage.setItem(
      'ModifySubclaim_index',
      event.getParameter('subclaimIndex')
    );
    sessionStorage.setItem(
      'DriverObjectId',
      event.getParameter('objectId')
    );
    UpdateContext.forceUpdate('MigrationDriverInfo');
    UIDialog.show('MigrationDriverRemark');
  },
});
export default HandlingLinks;
