import {
  UITabItem,
  UITab,
  UIBox,
  UICell,
  UIButton,
  UpdateContext,
} from 'RainbowUI';
import PartyOrgBasicInfoReadOnly from '../../../../party/views/readonly/views/PartyOrgBasicInfoReadOnly';
import PartyIndBasicInfo from '../../../../party/views/readonly/views/PartyIndBasicInfoReadOnly';
import PartyAddressInfoReadOnly from '../../../../party/views/readonly/views/PartyAddressInfoReadOnly';
import PartyContactInfoReadOnly from '../../../../party/views/readonly/views/PartyContactInfoReadOnly';
import PartyAccountInfoReadOnly from '../../../../party/views/readonly/views/PartyAccountInfoReadOnly';
import PartyRelationShipInfoReadOnly from '../../../../party/views/readonly/views/RelationShipInfoReadOnly';
import PartyAction from '../../../../party/actions/PartyAction';
import PartyHistory from '../../../../party/views/PartyHistory';
import PartyConst from '../../../../party/const/PartyConst';
import PartyIndAddInfoReadOnly from '../../../../party/views/readonly/views/PartyIndAddInfoReadOnly';

const PtyIntegrationTabInfoReadOnly = React.createClass({
  getInitialState() {
    return {
      party: {},
    };
  },

  componentWillMount() {
    const { partyId } = this.props;
    const partyAction = new PartyAction();
    const party = partyAction.loadPartyByPartyId(partyId);
    this.setState({
      party,
    });
  },

  exitHandler() {
    window.close();
  },

  submitHandler() {
    const { party } = this.state;
    const partyAction = new PartyAction();
    const newPartyHistory = {
      '@type': 'PartyHistory-PartyHistory',
      BusinessObjectId: 75158352,
      HistoryType: 2,
    };
    party.PartyHistoryList.push(newPartyHistory);
    const result = partyAction.updateParty(party);
    this.setState(
      {
        party: result,
      },
      () => {
        UpdateContext.forceUpdate('updateBasicInfo');
      }
    );
    // this.exitHandler();
  },

  submitInfoHandler(party) {
    const partyAction = new PartyAction();
    const result = partyAction.updateParty(party);
    this.setState({
      party: result,
    });
    return result;
  },

  render() {
    const { party } = this.state;
    let BasicInfo;
    let AdditionalInfo;
    if (party.PartyType == PartyConst.PARTY_TYPE_IND) {
      BasicInfo = this.renderIndBasicInfo();
      AdditionalInfo = this.renderIndAddInfo();
    } else {
      BasicInfo = this.renderOrgBasicInfo();
      AdditionalInfo = this.renderOrgAddInfo();
    }
    const { AddressVoList } = party;
    return (
      <div>
        <UICell width="14">
          {BasicInfo}
          <UITab activeIndex="1">
            <UITabItem title={i18n.AddressDetail}>
              <PartyAddressInfoReadOnly
                AddressVoList={AddressVoList}
                party={party}
                submitInfoHandler={this.submitInfoHandler}
              />
            </UITabItem>
            <UITabItem title={i18n.BankAccount}>
              <PartyAccountInfoReadOnly
                partyAccountList={PartyAccountList}
                submitInfoHandler={this.submitInfoHandler}
                party={party}
              />
            </UITabItem>
            <UITabItem title={i18n.RelationShip}>
              <PartyRelationShipInfoReadOnly
                partyRelationShipList={party.PartyRelationShipList}
                submitInfoHandler={this.submitInfoHandler}
                party={party}
              />
            </UITabItem>
            {AdditionalInfo}
            <UITabItem title={i18n.History}>
              <PartyHistory party={party} />
            </UITabItem>
          </UITab>
        </UICell>

        <UIBox direction="center">
          {/* <UIButton value={i18n.Submit} onClick={this.submitHandler} validationGroup="UpdateBasicInfo"
                		causeValidation="true"/> */}
          <UIButton value={i18n.Exit} onClick={this.exitHandler} />
        </UIBox>
      </div>
    );
  },
  renderIndBasicInfo() {
    const { party } = this.state;
    return <PartyIndBasicInfo party={party} />;
  },
  renderOrgBasicInfo() {
    const { party } = this.state;
    return <PartyOrgBasicInfoReadOnly party={party} />;
  },
  renderIndAddInfo() {
    const { party } = this.state;
    return (
      <UITabItem title={i18n.AdditionalId}>
        <PartyIndAddInfoReadOnly
          party={party}
          partyAdditionalList={party.PartyAdditionalList}
          submitInfoHandler={this.submitInfoHandler}
        />
      </UITabItem>
    );
  },
  renderOrgAddInfo() {
    const { party } = this.state;
    return (
      <UITabItem title={i18n.ContactInfo}>
        <PartyContactInfoReadOnly
          partyContactList={party.PartyContactList}
          submitInfoHandler={this.submitInfoHandler}
          party={party}
        />
      </UITabItem>
    );
  },
});
export default PtyIntegrationTabInfoReadOnly;
