import {
  UIDialog,
  UIDateTimePicker,
  UIBox,
  CodeTable,
  UILabel,
  MessageHelper,
  UISelect,
  UISmartPanelGrid,
  UITextarea,
  UIButton,
} from 'RainbowUI';
import BindToMixin from 'react-binding';
import AssignmentAction from '../../action/AssignmentAction';
import CodeTableCstUI from '../../constant/CodeTableCstUI';
import ClaimCstUI from '../../constant/ClaimCstUI';
import CodeTableAction from '../../action/CodeTableAction';
import ClaimAuthorityCode from '../../constant/ClaimAuthorityCode';
import TaskAssignUserRuleService from '../../service/TaskAssignUserRuleService';

const NewTaskDialog = React.createClass({
  mixins: [BindToMixin],
  getInitialState() {
    const intance = {
      taskInstance: {},
      readOnly: true,
      readOnlyOfAssignTo: {},
      approachCode: '',
      AssignToSelectBlankOption: i18n.BlankOption,
      isEdit: false,
    };

    return intance;
  },

  componentWillUnmount() {
    TaskAssignUserRuleService.clearUserList();
  },
  save() {
    const assignTo = this.state.taskInstance.AssignTo;
    const requiredAssignTo = this.state.readOnlyOfAssignTo.isTrue;
    if (requiredAssignTo && !assignTo) {
      MessageHelper.info(i18n.ClaimCommon.selectAssignTo);
      return;
    }
    this.state.taskInstance.CaseId = this.props.caseId;
    this.state.taskInstance.ClaimNumber = this.props.claimNumber;
    AssignmentAction.saveClaimTask(
      this.state.taskInstance,
      this.state.readOnly
    );
  },
  showDialog(data, selected) {
    if (data === 'edit') {
      const param = {};
      param.AttachTo = selected.SubclaimId
        ? selected.SubclaimId
        : selected.AttachTo;
      param.TaskInstanceId = selected.id;
      param.TaskCode = selected.TaskDefinitionKey;
      param.AssignTo = selected.TaskAssignee;
      param.Priority = selected.TaskPrioriry;
      param.AssignmentApproach = selected.AssignmentApproach;
      let dueDate = selected.TaskDueTime;
      if (selected.TaskDueTime) {
        dueDate = selected.TaskDueTime.substr(0, 10);
      }
      param.DueDate = dueDate;
      param.TaskDescription = !selected.TaskDescription
        ? ''
        : selected.TaskDescription;
      this.setState({ isEdit: true });
      this.setState({ readOnly: false });
      this.setState({ taskInstance: param });
      if (param.AssignmentApproach === 'USER')
        this.setState({
          readOnlyOfAssignTo: { isTrue: true, group: 'assignment' },
        });
    } else {
      const param = {};
      param.AttachTo = '';
      this.setState({ readOnly: true });
      this.setState({ isEdit: false });
      this.setState({ taskInstance: param });
      this.setState({
        readOnlyOfAssignTo: { isTrue: false, group: '' },
      });
    }
    UIDialog.show('newTaskDialog');
  },
  YesOrNo() {
    return new CodeTable([
      { id: 'Y', text: 'Yes' },
      { id: 'N', text: 'No' },
    ]);
  },
  AssignmentClaimOrSubclaim() {
    if (this.state.isEdit == true) return false;
    const attachTo = AssignmentAction.getAttachTo();
    if (
      attachTo[this.state.taskInstance.TaskCode] == ClaimCstUI.Claim
    ) {
      this.state.taskInstance.AttachTo = 'claim';
      return false;
    }
    if (
      attachTo[this.state.taskInstance.TaskCode] ==
      ClaimCstUI.Subclaim
    ) {
      if (this.state.readOnly) {
        this.state.taskInstance.AttachTo = '';
        return true;
      }
      return false;
    }
    return true;
  },
  AssignmentAttachTo() {
    const taskCode = this.state.taskInstance.TaskCode || '';
    let codeTable = [];
    let params;
    if (this.state.isEdit == true) {
      codeTable.push({ id: 'claim', text: 'Claim' });
      params = this.props.subclaimList;
      for (let i = 0; i < params.length; i += 1) {
        const oid = params[i].ObjectId;
        codeTable.push({
          id: oid,
          text: `Subclaim${params[i].Name}`,
        });
      }
    } else {
      const fc = AssignmentAction.getFATaskRelationInfo(
        this.props.claimNumber,
        'N'
      );
      console.info('fc', fc);
      if (
        taskCode.indexOf('ClaimAppraisal') != -1 ||
        taskCode.indexOf('ClaimFI') != -1
      ) {
        params = this.props.subclaimList;
        let DT = '';
        console.info('subclaimList', this.props.subclaimList);
        if (taskCode.indexOf('BodilyInjury') != -1) DT = 'I';
        else if (taskCode.indexOf('Property') != -1) DT = 'P';
        else if (taskCode.indexOf('Vehicle') != -1) DT = 'V';
        const dataMap = {};
        _.each(
          this.props.claimEntity.ObjectList,
          function (v) {
            const m = this;
            m[v.ObjectId] = v.DamageType;
          },
          dataMap
        );
        const deepclone = JSON.stringify(params);
        const cloneObjectData = JSON.parse(deepclone);
        _.map(cloneObjectData, v => {
          const temp = v;
          if (temp.StatusCode != 'REJECT')
            temp.DamageType = dataMap[temp.ObjectId];
        });
        for (let i = 0; i < cloneObjectData.length; i += 1) {
          const oid = cloneObjectData[i].ObjectId;
          let shouldAdd = true;
          const oIdsArr = fc[oid];
          if (oIdsArr && oIdsArr.length > 0) {
            if (oIdsArr.length > 1) {
              shouldAdd = false;
            } else if (oIdsArr[0] === taskCode) shouldAdd = false;
          }
          if (
            cloneObjectData[i].DamageType === DT &&
            shouldAdd === true
          ) {
            codeTable.push({
              id: oid,
              text: `Subclaim${params[i].Name}`,
            });
          }
        }
      } else {
        codeTable = [{ id: 'claim', text: 'Claim' }];
      }
    }

    return new CodeTable(codeTable);
  },
  userName() {
    console.log(
      `this.state.taskInstance.TaskCode===${this.state.taskInstance.TaskCode}`
    );
    const authcode =
      ClaimAuthorityCode[this.state.taskInstance.TaskCode];
    const { caseId } = this.props;
    const taskId = this.state.taskInstance.TaskInstanceId;
    return TaskAssignUserRuleService.queryUserList(
      authcode,
      caseId,
      taskId
    );
  },
  getAssignType() {
    const code = this.state.taskInstance.TaskCode;
    let result;
    if (code) {
      result = CodeTableAction.loadDataList(
        CodeTableCstUI.ClaimAssignType,
        {
          ASSIGN_TYPE_CODE: AssignmentAction.getApproachCodeByTaskCode(
            code
          ),
        }
      );
    } else {
      result = CodeTableAction.loadDataList(
        CodeTableCstUI.ClaimAssignType,
        null
      );
    }

    const { taskInstance } = this.state;
    if (taskInstance.AssignmentApproach && result) {
      if (!result.map[taskInstance.AssignmentApproach]) {
        taskInstance.AssignmentApproach = '';
        this.setState({ taskInstance });
      }
    }

    return result;
  },
  loadDataList() {
    if (this.state.readOnly)
      return CodeTableAction.loadDataList(CodeTableCstUI.CLAIMTASK, {
        TASK_CODE: AssignmentAction.getTaskCode(this.props.caseId),
      });

    return CodeTableAction.loadDataList(
      CodeTableCstUI.CLAIMTASK,
      null
    );
  },
  changeApproach(event) {
    if (this.state.taskInstance.AssignmentApproach !== 'USER') {
      this.setState(prevState => {
        const task = prevState.taskInstance;
        task.AssignTo = '';
        return {
          taskInstance: task,
        };
      });
      this.setState({
        readOnlyOfAssignTo: { isTrue: false, group: '' },
      });
    } else {
      this.setState(prevState => {
        const task = prevState.taskInstance;
        _.forEach(this.props.userTaskInfo, ontTask => {
          if (ontTask == task.TaskCode) {
            const currentUser = CodeTableAction.renderUser();
            task.AssignTo = currentUser.UserId;
          }
        });
        return {
          taskInstance: task,
        };
      });
      this.setState({
        readOnlyOfAssignTo: { isTrue: true, group: 'assignment' },
      });
    }

    if (event.newValue === 'POOL') {
      this.setState({
        AssignToSelectBlankOption:
          i18n.ClaimCommon.AssignToSelectBlankOption,
      });
    } else {
      this.setState({ AssignToSelectBlankOption: i18n.BlankOption });
    }
  },
  assignToRequired() {
    const {
      readOnlyOfAssignTo,
      AssignToSelectBlankOption,
    } = this.state;
    if (readOnlyOfAssignTo.isTrue == false) {
      return (
        <UISelect
          id="assignmentAssignTo"
          blankOption={AssignToSelectBlankOption}
          valueLink={this.bindToState('taskInstance', 'AssignTo')}
          enabled={readOnlyOfAssignTo.isTrue}
          label={i18n.ClaimCommon.AssignmentAssignTo}
          codeTable={this.userName}
        />
      );
    }
    return (
      <UISelect
        id="assignmentAssignTo"
        blankOption={AssignToSelectBlankOption}
        enabled="true"
        validationGroup="assignment"
        required={readOnlyOfAssignTo.isTrue}
        valueLink={this.bindToState('taskInstance', 'AssignTo')}
        label={i18n.ClaimCommon.AssignmentAssignTo}
        codeTable={this.userName}
      />
    );
  },

  UserListWithPoolSystem() {
    const codeTable = TaskAssignUserRuleService.queryUserList(
      'noAuthCode',
      this.state.data.CaseId
    );
    return codeTable;
  },
  GetDateStr() {
    const dd = new ServerDate();
    if (
      this.state.taskInstance.TaskCode === 'ClaimSubrogationTask' ||
      this.state.taskInstance.TaskCode === 'ClaimFIPropertyTask' ||
      this.state.taskInstance.TaskCode ===
      'ClaimFIBodilyInjuryTask' ||
      this.state.taskInstance.TaskCode === 'ClaimFIVehicleTask' ||
      this.state.taskInstance.TaskCode === 'ClaimSalvageTask' ||
      this.state.taskInstance.TaskCode === 'ClaimRegistrationTask' ||
      this.state.taskInstance.TaskCode === 'ClaimCalculationTask' ||
      this.state.taskInstance.TaskCode ===
      'ClaimAppraisalVehicleTask' ||
      this.state.taskInstance.TaskCode === 'ClaimRecoveryTask' ||
      this.state.taskInstance.TaskCode === 'ClaimRefundTask' ||
      this.state.taskInstance.TaskCode ===
      'ClaimAppraisalPropertyTask' ||
      this.state.taskInstance.TaskCode === 'ClaimSettlementTask' ||
      this.state.taskInstance.TaskCode ===
      'ClaimAppraisalBodilyInjuryTask'
    ) {
      dd.setDate(dd.getDate() + 3);
    } else {
      dd.setDate(dd.getDate() + 1);
    }

    const y = dd.getFullYear();
    let m = dd.getMonth() + 1;
    let d = dd.getDate();

    /* if(m.length==1)
      m='0'+m;
    if(d.length==1)
      d='0'+d; */
    if (m < 10) m = `0${m}`;
    if (d < 10) d = `0${d}`;
    this.state.taskInstance.DueDate = `${y}-${m}-${d}T00:00:00`;
    return `${y}-${m}-${d}T00:00:00`;
  },
  render() {
    const { isEdit, readOnly } = this.state;
    let titles;
    if (isEdit) {
      titles = i18n.ClaimCommon.AssignmentReassignTask;
    } else {
      titles = i18n.ClaimCommon.AssignmentNewTask;
    }
    const assignTo = this.assignToRequired();
    return (
      <UIDialog
        id="newTaskDialog"
        title={titles}
        width="80%"
        modal="false"
      >
        <UISmartPanelGrid column="2">
          <UISelect
            id="assignmentAttachTaskType"
            validationGroup="assignment"
            required="true"
            enabled={readOnly}
            valueLink={this.bindToState('taskInstance', 'TaskCode')}
            label={i18n.ClaimCommon.TaskType}
            codeTable={this.loadDataList}
            onChange={this.GetDateStr}
          />
          <UISelect
            id="assignmentAttachTo"
            validationGroup="assignment"
            required="true"
            enabled={this.AssignmentClaimOrSubclaim()}
            valueLink={this.bindToState('taskInstance', 'AttachTo')}
            label={i18n.ClaimCommon.AssignmentAttachTo}
            codeTable={this.AssignmentAttachTo}
          />
          <UISelect
            id="assignmentApproach"
            validationGroup="assignment"
            required="true"
            valueLink={this.bindToState(
              'taskInstance',
              'AssignmentApproach'
            )}
            label={i18n.ClaimCommon.AssignmentApproach}
            codeTable={this.getAssignType}
            onChange={this.changeApproach}
          />
          {assignTo}

          <UIDateTimePicker
            id="assignmentDueDate"
            placeHolder="MM/DD/YYYY HH:mm:ss"
            format="MM/DD/YYYY HH:mm:ss"
            minDate="TODAY"
            enabled={readOnly}
            defaultValue={this.GetDateStr()}
            valueLink={this.bindToState('taskInstance', 'DueDate')}
            label={i18n.ClaimCommon.DueDate}
          />

          <UISelect
            id="priority"
            validationGroup="assignment"
            required="true"
            valueLink={this.bindToState('taskInstance', 'Priority')}
            label={i18n.ClaimCommon.Priority}
            codeTable={CodeTableAction.loadDataList(
              CodeTableCstUI.Prioriry,
              null
            )}
          />
          {/* <UIRadio id="sendMessage" valueLink={this.state.taskInstance.SendMessage} label={i18n.ClaimCommon.SendMessage} codeTable={this.YesOrNo}>
                            </UIRadio> */}
          <UILabel />
          <UITextarea
            id="Remark"
            validationGroup="assignment"
            valueLink={this.bindToState(
              'taskInstance',
              'TaskDescription'
            )}
            label={i18n.ClaimCommon.TaskDetail}
            colspan="2"
          />
        </UISmartPanelGrid>

        <UIBox direction="center">
          <UIButton
            value={i18n.ClaimCommon.Submit}
            onClick={this.save}
            causeValidation="true"
            validationGroup="assignment"
          />
          <UIButton
            value={i18n.ClaimCommon.Cancel}
            onClick={this.cancel}
          />
        </UIBox>
      </UIDialog>
    );
  },
  cancel() {
    UIDialog.hide('newTaskDialog');
  },
});
export default NewTaskDialog;
