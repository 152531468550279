import {
  CodeTable,
  DataContext,
  UIBox,
  UIButton,
  UIDialog,
} from 'RainbowUI';
import PartyAction from '../actions/PartyAction';
import DefineCodeTable from '../codetable/CodeTable';
import PartyConst from '../const/PartyConst';
import BaseAddress from '../../common/component/BaseAddress';

const PartyAddressInfoDialog = React.createClass({
  getInitialState() {
    const tempCodeTable = new CodeTable([]);
    const countryCodeTable = DefineCodeTable.getCountry();
    const addressTypeCodeTable = DefineCodeTable.getAddressType();
    const statusCodeTable = DefineCodeTable.getPartyStatus();
    return {
      tempCodeTable,
      countryCodeTable,
      addressTypeCodeTable,
      statusCodeTable,
      readOnly: false,
      postCodeCodeTable: tempCodeTable,
      regionCodeTable: tempCodeTable,
      regions: null,
    };
  },

  onShowPartyAddressInfoDialog(partyAddress, flag, regionCodeTable) {
    let { readOnly } = this.state;
    const { party } = this.props;
    let { addressTypeCodeTable } = this.state;
    if (party.PartyType == PartyConst.PARTY_TYPE_ORG) {
      addressTypeCodeTable = DefineCodeTable.getOrgAddressType();
    }
    if (partyAddress) {
      this.state.partyAddress = partyAddress;
    }
    if (partyAddress.AddressStatus == '2') {
      readOnly = true;
    }
    this.setState({
      partyAddress,
      readOnly,
      addressTypeCodeTable,
      regionCodeTable,
      flag,
    });
    DataContext.remove('City');
    DataContext.remove('Area');
    DataContext.remove('PostCode');
    UIDialog.show('partyAddressInfoDialog');
  },

  render() {
    const _self = this;
    let { partyAddress } = this.state;
    const { flag } = this.state;
    const { party } = this.props;
    if (!partyAddress) partyAddress = { Country: 'US' };
    if (!partyAddress.Country) partyAddress.Country = 'US';

    return (
      <UIDialog
        width="95%"
        id="partyAddressInfoDialog"
        title={i18n.AddressDetail}
      >

        <BaseAddress
          baseAddress={partyAddress}
          readOnly={false}
          party={party}
          flag={flag}
        />
        <UIBox direction="center">
          <UIButton
            value={i18n.Save}
            causeValidation="true"
            validationGroup="baseAddressInfoDialog"
            onClick={_self.onClickSubmit}
          />
          <UIButton
            value={i18n.Cancel}
            onClick={_self.onClickClose}
          />
        </UIBox>
      </UIDialog>
    );
  },

  onClickSubmit() {
    let { party } = this.props;
    if (DataContext.get('Party')) {
      party = JSON.parse(JSON.stringify(DataContext.get('Party')));
    }
    const partyAction = new PartyAction();
    const copyParty = partyAction.loadPartyByPartyId(party.PartyId);
    const { partyAddress } = this.state;
    const { PartyHistoryList } = copyParty;
    if (partyAddress['@pk']) {
      const newPartyHistory = {
        '@type': 'PartyHistory-PartyHistory',
        BusinessObjectId: 75158352,
        HistoryType: 6,
      };
      PartyHistoryList.push(newPartyHistory);
    } else {
      const newPartyHistory = {
        '@type': 'PartyHistory-PartyHistory',
        BusinessObjectId: 75158352,
        HistoryType: 5,
      };
      PartyHistoryList.push(newPartyHistory);
    }
    party.PartyHistoryList = PartyHistoryList;

    const addressStatus = partyAddress.AddressStatus;
    let AddressVoList = [];
    if (addressStatus == '2') {
      partyAddress.IsPrimaryAddress = 'N';
    }
    if (party.AddressVoList) {
      AddressVoList = party.AddressVoList;
      if (partyAddress.IsPrimaryAddress === 'Y') {
        _.forEach(AddressVoList, temp => {
          const obj = temp;
          obj.IsPrimaryAddress = 'N';
        });
      }
      for (let i = 0; i < AddressVoList.length; i += 1) {
        if (AddressVoList[i].AddressId == partyAddress.AddressId) {
          AddressVoList.splice(i, 1);
        }
      }
    }
    AddressVoList.push(partyAddress);
    _.each(AddressVoList, temp => {
      const obj = temp;
      delete obj.dataIndex;
    });
    party.AddressVoList = AddressVoList;
    const result = this.props.parentComponet.updatePanel(party);
    if (!(result == PartyConst.FAIL)) {
      UIDialog.hide('partyAddressInfoDialog');
      if (this.props.parentDialog) {
        if (this.props.parentDialog.callBackGetRecord) {
          this.props.parentDialog.callBackGetRecord(
            AddressVoList[AddressVoList.length - 1]
          );
          this.props.parentDialog.hideDialog();
        }
      }
    }
  },

  onClickClose() {
    const { partyAddress } = this.state;
    const { readOnly } = this.state;
    if (!readOnly) {
      this.clearPartyAddress(partyAddress);
    }
    UIDialog.hide('partyAddressInfoDialog');
  },

  clearPartyAddress(temp) {
    const partyAddress = temp;
    if (partyAddress.Area) {
      delete partyAddress.Area;
    }
    if (partyAddress.StreetName) {
      delete partyAddress.StreetName;
    }
    if (partyAddress.StreetNo) {
      delete partyAddress.StreetNo;
    }
    if (partyAddress.BuildingName) {
      delete partyAddress.BuildingName;
    }
    if (partyAddress.ApartmentNumber) {
      delete partyAddress.ApartmentNumber;
    }
    if (partyAddress.AddressTel) {
      delete partyAddress.AddressTel;
    }
    if (partyAddress.POBox) {
      delete partyAddress.POBox;
    }
    if (partyAddress.City) {
      delete partyAddress.City;
    }
    if (partyAddress.PostCode) {
      delete partyAddress.PostCode;
    }
    if (partyAddress.FaxNumber) {
      delete partyAddress.FaxNumber;
    }
  },
});

export default PartyAddressInfoDialog;
