import {
  UITabItem,
  UITab,
  UIBox,
  UICell,
  DataContext,
  UIButton,
  UIUpdatePanel,
  UpdateContext,
  MessageHelper,
} from 'RainbowUI';
import PartyOrgBasicInfo from './PartyOrgBasicInfo';
import PartyIndBasicInfo from './PartyIndBasicInfo';
import PartyVenBasicInfo from './PartyVenBasicInfo';
import RoleInfo from './RoleInfo';
import PartyAddressInfo from './PartyAddressInfo';
import PartyContactInfo from './PartyContactInfo';
import PartyAccountInfo from './PartyAccountInfo';
import PartyRelationShipInfo from './PartyRelationShipInfo';
import PartyAction from '../actions/PartyAction';
import PartyHistory from './PartyHistory';
import PartyGDPR from './PartyGDPR';
import ParticipationInfo from './ParticipationInfo';
import PartyConst from '../const/PartyConst';
import PartyIndAddInfo from './PartyIndAddInfo';
import PartyDocument from './dms/PartyDocument';
import PartyShareholderInfo from './PartyShareholderInfo';

const PartyInfoTab = React.createClass({
  getInitialState() {
    return {
      childMethod: null,
      party: {},
    };
  },

  componentWillMount() {
    const { partyId } = this.props;
    const partyAction = new PartyAction();
    const party = partyAction.loadPartyByPartyId(partyId);
    this.setState({
      party,
    });
    const copyParty = JSON.parse(JSON.stringify(party));
    DataContext.put('Party', copyParty);
    DataContext.put('oldParty', copyParty);
    DataContext.put('DataAccessRequestCategory', {});
    let { PartyGDPR } = party;
    if (!PartyGDPR) {
      PartyGDPR = {
        '@type': 'PartyGDPR-PartyGDPR',
        BusinessObjectId: 75698989,
      };
      DataContext.put('PartyGDPR', PartyGDPR);
    } else {
      DataContext.put('PartyGDPR', PartyGDPR);
    }
    let { PartyGDPRDataAccess } = PartyGDPR;
    if (!PartyGDPRDataAccess) {
      PartyGDPRDataAccess = {
        '@type': 'PartyGDPRDataAccess-PartyGDPRDataAccess',
        BusinessObjectId: 75699032,
      };
      DataContext.put('PartyGDPRDataAccess', PartyGDPRDataAccess);
    } else {
      DataContext.put('PartyGDPRDataAccess', PartyGDPRDataAccess);
    }
  },

  exitHandler() {
    window.close();
  },

  submitHandler() {
    const { party } = this.state;
    const partyAction = new PartyAction();
    if (party.PartyType == PartyConst.PARTY_TYPE_VEN) {
      const oldJointVentureName = DataContext.get('oldParty')
        .JointVentureName;
      const conditions = { PartyName: party.JointVentureName };
      const fuzzyConditions = {};
      const orConditions = {};
      const result = partyAction.searchPartyByConditions(
        conditions,
        orConditions,
        fuzzyConditions,
        '4',
        1,
        10
      );

      if (
        result.count > 0 &&
        result.result[0].PartyName !== oldJointVentureName
      ) {
        MessageHelper.error(
          'JointVentureName is Existed',
          '',
          MessageHelper.POSITION_TOP_RIGHT
        );
        return;
      }
    }
    party.PartyAccountList = DataContext.get(
      'Party'
    ).PartyAccountList;
    party.AddressVoList = DataContext.get(
      'Party'
    ).AddressVoList;
    party.PartyRelationShipList = DataContext.get(
      'Party'
    ).PartyRelationShipList;
    party.PartyContactList = DataContext.get(
      'Party'
    ).PartyContactList;
    party.PartyRoleList = DataContext.get('Party').PartyRoleList;
    party.PartyAdditionalList = DataContext.get(
      'Party'
    ).PartyAdditionalList;
    party.PartyHistoryList = DataContext.get(
      'Party'
    ).PartyHistoryList;
    const partyParticipationList = DataContext.get('Party')
      .PartyParticipationList;
    _.forEach(partyParticipationList, datas => {
      const data = datas;
      delete data.ParticipationPartyInfo;
      delete data.CompanyName;
      delete data.CountryDom;
      delete data.OrganizationIdNumber;
    });
    party.PartyParticipationList = partyParticipationList;
    const DataAccessRequestCategory = DataContext.get(
      'DataAccessRequestCategory'
    );
    DataContext.get('PartyGDPRDataAccess').RestricationOfProcessing =
      'N';
    DataContext.get('PartyGDPRDataAccess').DataPortabilityRequest =
      'N';
    DataContext.get('PartyGDPRDataAccess').DataRectificationRequest =
      'N';
    DataContext.get('PartyGDPRDataAccess').RightOfAccessRequest = 'N';
    DataContext.get('PartyGDPRDataAccess').RightToErasure = 'N';
    if (DataAccessRequestCategory.checkBoxDatas) {
      _.forEach(DataAccessRequestCategory.checkBoxDatas, data => {
        if (data == '1') {
          DataContext.get(
            'PartyGDPRDataAccess'
          ).RestricationOfProcessing = 'Y';
        }
        if (data == '2') {
          DataContext.get(
            'PartyGDPRDataAccess'
          ).DataPortabilityRequest = 'Y';
        }
        if (data == '3') {
          DataContext.get(
            'PartyGDPRDataAccess'
          ).DataRectificationRequest = 'Y';
        }
        if (data == '4') {
          DataContext.get(
            'PartyGDPRDataAccess'
          ).RightOfAccessRequest = 'Y';
        }
        if (data == '5') {
          DataContext.get('PartyGDPRDataAccess').RightToErasure = 'Y';
        }
      });
    }
    DataContext.get(
      'PartyGDPR'
    ).PartyGDPRDataAccess = DataContext.get('PartyGDPRDataAccess');
    DataContext.get('Party').PartyGDPR = DataContext.get('PartyGDPR');
    party.PartyGDPR = DataContext.get('Party').PartyGDPR;

    DataContext.put('Party', party);
    let isPrimaryAccount = false;
    let isValid = true;
    if (party.PartyAccountList) {
      if (party.PartyAccountList.length > 0) {
        _.forEach(party.PartyAccountList, data => {
          if (data.IsPrimaryAccount === 'Y') {
            isPrimaryAccount = true;
          }
        });
      }
    }
    if (party.PaymentMethodId == 109) {
      if (
        !party.PartyAccountList ||
        party.PartyAccountList.length === 0 ||
        isPrimaryAccount == false
      ) {
        isValid = false;
      }
    }
    if (isValid == true) {
      if (party.PartyRoleList && party.PartyRoleList.length > 0) {
        const newPartyHistory =
          PartyConst.PARTY_HISTORY_UPDATE_BASIC_INFO;
        party.PartyHistoryList.push(newPartyHistory);
        let result = partyAction.updateParty(party);
        const newPartyHistoryForGDPR =
          PartyConst.PARTY_HISTORY_UPDATE_GDPR;
        result.PartyHistoryList.push(newPartyHistoryForGDPR);
        result.PartyGDPR = party.PartyGDPR;
        const resultForGDPR = partyAction.updateParty(result);
        const newPartyHistoryForGDPRAccess =
          PartyConst.PARTY_HISTORY_UPDATE_GDPR_DATA_ACCESS;
        resultForGDPR.PartyHistoryList.push(
          newPartyHistoryForGDPRAccess
        );
        resultForGDPR.PartyGDPR = party.PartyGDPR;
        const resultForGDPRAccess = partyAction.updateParty(
          resultForGDPR
        );
        const { activeIndex } = this.state;

        if (
          result === 'fail' ||
          result === undefined ||
          result === null
        ) {
          result = DataContext.get('Party');
          const existsParty = partyAction.loadPartyByPartyId(
            party.PartyId
          );
          result.PartyHistoryList = existsParty.PartyHistoryList;
          DataContext.put('Party', resultForGDPRAccess);
          this.setState({
            party: resultForGDPRAccess,
            activeIndex,
          });
          UpdateContext.forceUpdate('basicInfo');
        } else {
          this.setState({
            party: resultForGDPRAccess,
          });
          UpdateContext.forceUpdate('partyInfoTab');

          window.close();
        }
      } else {
        MessageHelper.warning(
          i18n.PartyMsg.WarnMsg.Warn_0006,
          '',
          MessageHelper.POSITION_TOP_RIGHT
        );
      }
    } else {
      MessageHelper.error(
        'Please maintain the bank info for this party since the default payment method.',
        '',
        MessageHelper.POSITION_TOP_RIGHT
      );
    }
  },
  submitInfoHandlerCommon(party, activeIndex) {
    const partyAction = new PartyAction();
    const result = partyAction.updatePartyCommon(party);
    let copyParty = this.state.party;
    if (result == PartyConst.FAIL) {
      copyParty = PartyConst.FAIL;
    } else {
      copyParty.PartyRelationShipList = DataContext.get(
        'Party'
      ).PartyRelationShipList;

      copyParty.PartyRoleList = DataContext.get(
        'Party'
      ).PartyRoleList;
      copyParty.PartyGDPR.PartyGDPRDataBreach = DataContext.get(
        'Party'
      ).PartyGDPR.PartyGDPRDataBreach;
      copyParty.PartyAdditionalList = DataContext.get(
        'Party'
      ).PartyAdditionalList;
      copyParty.PartyHistoryList = result.PartyHistoryList;
      result.PartyAccountList = copyParty.PartyAccountList;
      result.AddressVoList = copyParty.AddressVoList;
      result.PartyContactList = copyParty.PartyContactList;
      DataContext.put('Party', result);
      $.extend(copyParty, result);
      this.setState({
        party: copyParty,
        activeIndex,
      });
      UpdateContext.forceUpdate('partyInfoTab');
    }
    return copyParty;
  },
  submitInfoHandlerForAddress(party, activeIndex) {
    const partyAction = new PartyAction();
    let result = partyAction.updatePartyForAddress(party);
    let copyParty = this.state.party;
    if (result == PartyConst.FAIL) {
      copyParty = PartyConst.FAIL;
    } else {
      result = partyAction.loadPartyByPartyId(party.PartyId);

      DataContext.put('Party', result);
      $.extend(copyParty, result);
      this.setState({
        party: copyParty,
        activeIndex,
      });
      UpdateContext.forceUpdate('partyInfoTab');
    }
    return copyParty;
  },
  submitInfoHandlerForAccount(party, activeIndex) {
    const partyAction = new PartyAction();
    let result = partyAction.updatePartyForAccount(party);
    let copyParty = this.state.party;
    if (result == PartyConst.FAIL) {
      copyParty = PartyConst.FAIL;
    } else {
      result = partyAction.loadPartyByPartyId(party.PartyId);

      DataContext.put('Party', result);
      $.extend(copyParty, result);
      this.setState({
        party: copyParty,
        activeIndex,
      });
      UpdateContext.forceUpdate('partyInfoTab');
    }
    return copyParty;
  },
  submitInfoHandlerForContact(party, activeIndex) {
    const partyAction = new PartyAction();
    let result = partyAction.updatePartyForContact(party);
    let copyParty = this.state.party;
    if (result == PartyConst.FAIL) {
      copyParty = PartyConst.FAIL;
    } else {
      result = partyAction.loadPartyByPartyId(party.PartyId);

      copyParty.PartyRelationShipList = DataContext.get(
        'Party'
      ).PartyRelationShipList;
      copyParty.PartyContactList = DataContext.get(
        'Party'
      ).PartyContactList;
      copyParty.PartyRoleList = DataContext.get(
        'Party'
      ).PartyRoleList;
      copyParty.PartyAdditionalList = DataContext.get(
        'Party'
      ).PartyAdditionalList;
      copyParty.PartyHistoryList = result.PartyHistoryList;
      result.AddressVoList = copyParty.AddressVoList;
      result.PartyAccountList = copyParty.PartyAccountList;
      DataContext.put('Party', result);
      this.setState({
        party: copyParty,
        activeIndex,
      });
      UpdateContext.forceUpdate('partyInfoTab');
    }
    return copyParty;
  },

  render() {
    const { party } = this.state;
    if (!party.PartyRoleList) {
      party.PartyRoleList = [];
    }
    let BasicInfo;
    let AdditionalInfo;
    let ShareholderInfo;
    let ParticipationInfo;
    if (party.PartyType == PartyConst.PARTY_TYPE_IND) {
      BasicInfo = this.renderIndBasicInfo(party);
      AdditionalInfo = this.renderIndAddInfo();
    }
    if (party.PartyType == PartyConst.PARTY_TYPE_ORG) {
      BasicInfo = this.renderOrgBasicInfo(party);
      AdditionalInfo = this.renderOrgAddInfo();
      ShareholderInfo = this.renderShareholderInfo();
    }
    if (party.PartyType == PartyConst.PARTY_TYPE_VEN) {
      BasicInfo = this.renderVenBasicInfo(party);
      AdditionalInfo = this.renderOrgAddInfo();

      ParticipationInfo = this.renderParticipationInfo();
    }
    const { activeIndex } = this.state;
    return (
      <UIUpdatePanel id="partyInfoTab">
        <div>
          <UICell width="14">
            <UIUpdatePanel id="basicInfo">{BasicInfo}</UIUpdatePanel>
            <UITab activeIndex={activeIndex}>
              <UITabItem title={i18n.RoleInfo}>
                <RoleInfo
                  party={party}
                  submitInfoHandler={this.submitInfoHandlerCommon}
                />
              </UITabItem>
              <UITabItem title={i18n.AddressDetail}>
                <PartyAddressInfo
                  party={party}
                  submitInfoHandler={this.submitInfoHandlerForAddress}
                />
              </UITabItem>
              <UITabItem title={i18n.BankAccount}>
                <PartyAccountInfo
                  submitInfoHandler={this.submitInfoHandlerForAccount}
                  party={party}
                />
              </UITabItem>
              <UITabItem title={i18n.RelationShip}>
                <PartyRelationShipInfo
                  submitInfoHandler={this.submitInfoHandlerCommon}
                  party={party}
                />
              </UITabItem>
              {AdditionalInfo}
              <UITabItem title={i18n.GDPR}>
                <PartyGDPR
                  party={party}
                  getParty={this.getParty}
                  getChildMethod={this.getChildMethod.bind(this)}
                />
              </UITabItem>
              <UITabItem title={i18n.DMS}>
                <PartyDocument
                  readOnly={false}
                  domainObject={party}
                />
              </UITabItem>
              {ShareholderInfo}
              <UITabItem title={i18n.History}>
                <PartyHistory party={party} />
              </UITabItem>
              {ParticipationInfo}
            </UITab>
          </UICell>

          <UIBox direction="center">
            <UIButton
              value={i18n.Submit}
              onClick={this.submitHandler}
              validationGroup="UpdateBasicInfo"
              causeValidation="true"
            />
            <UIButton value={i18n.Exit} onClick={this.exitHandler} />
          </UIBox>
        </div>
      </UIUpdatePanel>
    );
  },
  renderIndBasicInfo(party) {
    return (
      <PartyIndBasicInfo
        party={party}
        changeStatus={this.changeStatus.bind(this)}
      />
    );
  },
  renderOrgBasicInfo(party) {
    return <PartyOrgBasicInfo party={party} />;
  },

  renderVenBasicInfo(party) {
    return <PartyVenBasicInfo party={party} />;
  },
  renderIndAddInfo() {
    const { party } = this.state;
    return (
      <UITabItem title={i18n.AdditionalId}>
        <PartyIndAddInfo
          party={party}
          submitInfoHandler={this.submitInfoHandlerCommon}
        />
      </UITabItem>
    );
  },
  renderOrgAddInfo() {
    const { party } = this.state;
    return (
      <UITabItem title={i18n.ContactInfo}>
        <PartyContactInfo
          submitInfoHandler={this.submitInfoHandlerForContact}
          party={party}
        />
      </UITabItem>
    );
  },
  renderShareholderInfo() {
    const { party } = this.state;
    return (
      <UITabItem title={i18n.ShareholderInfo}>
        <PartyShareholderInfo
          submitInfoHandler={this.submitInfoHandlerCommon}
          party={party}
        />
      </UITabItem>
    );
  },

  renderParticipationInfo() {
    const { party } = this.state;
    return (
      <UITabItem title="Participation">
        <ParticipationInfo
          party={party}
          submitInfoHandler={this.submitInfoHandlerCommon}
        />
      </UITabItem>
    );
  },

  getChildMethod(childMethod) {
    this.state.childMethod = childMethod;
  },

  changeStatus() {
    this.state.childMethod();
  },

  getParty(party) {
    DataContext.put('Party', party);
  },
});

export default PartyInfoTab;
