import alt from '../alt';
import ClaimWorkFlowLeftAction from '../action/desktop/ClaimWorkFlowLeftAction';

class ConditionStore {
  constructor() {
    this.condition = {};
    // this.page={};

    this.bindListeners({
      handleCondition: ClaimWorkFlowLeftAction.INIT_CONDITION,
      // handlePage:ClaimWorkFlowLeftAction.PAGE_CHANGE
    });
  }

  handleCondition(condition) {
    if (condition.flag === 'sharingPool') this.condition = condition;
  }
  /* handlePage(page){
         console.log("ClaimWorkFlowLeftAction===>>handlePage");
    	this.page = page;
    } */
}

export default alt.createStore(ConditionStore, ConditionStore);
