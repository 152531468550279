import { UIPage, UIBreadCrumb, UIMenuItem } from 'RainbowUI';
import ClaimCommonExt from 'ClaimCommonExt';
import PolicyAction from '../../action/PolicyAction';

const SimpleQueryCriteria = require('./SimpleQueryCriteria');

const ComplexQueryCriteria = ClaimCommonExt.getComplexQueryCriteria();
const QueryResult = ClaimCommonExt.getQueryResult();
const ClaimQuery = React.createClass({
  getInitialState() {
    const agentCode = PolicyAction.getLoginUserAgentCode();
    console.log('=========ClaimQuery====getInitialState===');
    return { flag: 'Complex', count: 0, agentCode };
  },

  storeChangeStatus(flag) {
    this.setState(prevState => {
      const count = prevState.count + 1;
      return {
        flag,
        count,
      };
    });
  },

  childStateChange(claimSearchCriteria) {
    this.refs.claimresult.storeChangeStatus(claimSearchCriteria);
  },
  clearResult() {
    this.refs.claimresult.clear.apply();
  },
  clickHome() {
    window.location.hash = '#/desktop';
  },

  render() {
    console.log('start====query');
    const { agentCode, flag } = this.state;
    let show = (
      <ComplexQueryCriteria
        parentComponet={this}
        agentCode={agentCode}
        clearResult={this.clearResult.bind(this)}
      />
    );
    if (flag === 'Simple') {
      show = (
        <SimpleQueryCriteria
          parentComponet={this}
          agentCode={agentCode}
          clearResult={this.clearResult.bind(this)}
        />
      );
    }
    return (
      <div>
        <UIPage>
          {show}
          <QueryResult ref="claimresult" />
        </UIPage>
      </div>
    );
  },
});
export default ClaimQuery;
