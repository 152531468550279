const ClaimSubrogationExt = {
  getSubrogationTaskPage() {
    return require('./module/subrogation/common/component/SubrogationTaskPage');
  },
  getSubrogationApprove() {
    return require('./module/subrogation/common/component/SubrogationApprovel');
  },
  getSubrogationAction() {
    return require('./module/subrogation/common/action/SubrogationAction');
  },
};

export default ClaimSubrogationExt;
