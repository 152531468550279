import { UIPage, UIPanel } from 'RainbowUI';
import PartyInfoTab from '../PartyInfoTab';

const CreatePartyMaintain = React.createClass({
  render() {
    const { params } = this.props;
    return (
      <UIPage>
        <UIPanel
          style={{ margin: '0 0 0 0' }}
          panelTitle={i18n.PartyMaintenance}
        >
          <PartyInfoTab partyId={params.partyId} view="true" />
        </UIPanel>
      </UIPage>
    );
  },
});
export default CreatePartyMaintain;
