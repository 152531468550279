export default {
  ClaimRegistrationTask: 'CLAIM_REGISTRATION',
  ClaimSettlementTask: 'CLAIM_SETTLEMENT',
  ClaimSettlementApprovalTask: 'SETTLEMENT_APPROVAL',
  ClaimRecoveryTask: 'CLAIM_RECOVERY',
  ClaimRecoveryApprovalTask: 'RECOVERY_APPROVAL',
  ClaimCalculationTask: 'CLAIM_CALCULATION',
  ClaimCalculationApprovalTask: 'CLAIM_CALCULATION_APPROVAL',
  ClaimReserveUpdateApprovalTask: 'RESERVE_MANUALLY_CHANGE_APPROVAL_AWAC',
  ClaimReserveCloseApprovalTask: 'RESERVE_MANUALLY_CLOSURE_APPROVAL',

  ClaimFIPropertyTask: 'CLAIM_FIELDINVESTIGATION_PROPERTY',
  ClaimFIBodilyInjuryTask: 'CLAIM_FIELDINVESTIGATION_BODILYINJURY',
  ClaimFIVehicleTask: 'CLAIM_FIELDINVESTIGATION_VEHICLE',

  ClaimAppraisalPropertyTask: 'CLAIM_APPRAVISAL_PROPERTY',
  ClaimAppraisalPApprovalTask: 'CLAIM_APPRAVISAL_PROPERTY_APPROVAL',

  ClaimAppraisalVehicleTask: 'CLAIM_APPRAVISAL_VEHICLE',
  ClaimAppraisalVApprovalTask: 'CLAIM_APPRAVISAL_VEHICLE_APPROVAL',

  ClaimAppraisalBodilyInjuryTask: 'CLAIM_APPRAVISAL_BODILYINJURY',
  ClaimAppraisalIApprovalTask:
    'CLAIM_APPRAVISAL_BODILYINJURY_APPROVAL',

  ClaimSalvageTask: 'CLAIM_SALVAGE',
  ClaimSalvageApprovalTask: 'CLAIM_SALVAGE_APPROVAL',

  ClaimSubrogationTask: 'CLAIM_SUBROGATION',
  ClaimSubrogationApprovalTask: 'CLAIM_SUBROGATION_APPROVAL',
  ClaimRefundTask: 'REFUND',
  ClaimRefundApprovalTask: 'REFUND_APPROVAL',
};
