import {
  UITextarea,
  UISmartPanelGrid,
  MessageHelper,
} from 'RainbowUI';
import BindToMixin from 'react-binding';

const AppraisalPropertyRemark = React.createClass({
  mixins: [BindToMixin],

  getInitialState() {
    return {
      AppraisalPropertyInfo: this.props.appraisalProperInfo,
      AppraisalInfo: this.props.appraisalInfo,
    };
  },
  validateRemark1() {
    this.setState(prevState => {
      const appraisalProperty = prevState.AppraisalPropertyInfo;
      if (
        appraisalProperty.Remark1 &&
        appraisalProperty.Remark1.length > 3000
      ) {
        appraisalProperty.Remark1 = null;
        MessageHelper.warning(i18n.ClaimAppraisal.WarnMsg.Warn_0003);
      }
      return {
        AppraisalPropertyInfo: appraisalProperty,
      };
    });
  },
  validateRemark2() {
    this.setState(prevState => {
      const appraisalProperty = prevState.AppraisalPropertyInfo;
      if (
        appraisalProperty.Remark2 &&
        appraisalProperty.Remark2.length > 3000
      ) {
        appraisalProperty.Remark2 = null;
        MessageHelper.warning(i18n.ClaimAppraisal.WarnMsg.Warn_0003);
      }
      return {
        AppraisalPropertyInfo: appraisalProperty,
      };
    });
  },
  render() {
    const { ioSytle } = this.props;
    return (
      <div>
        <UISmartPanelGrid column="3">
          <UITextarea
            colspan="3"
            widthAllocation="2,10"
            enabled={ioSytle === 'out' ? 'false' : 'true'}
            id="RemarkToLossItems"
            label={i18n.ClaimAppraisal.Remark1}
            valueLink={this.bindToState(
              'AppraisalPropertyInfo',
              'Remark1'
            )}
            onChange={this.validateRemark1.bind(this)}
          />
          <UITextarea
            colspan="3"
            widthAllocation="2,10"
            enabled={ioSytle === 'out' ? 'false' : 'true'}
            id="RemarkToAppraisal"
            label={i18n.ClaimAppraisal.Remark2}
            valueLink={this.bindToState(
              'AppraisalPropertyInfo',
              'Remark2'
            )}
            onChange={this.validateRemark2.bind(this)}
          />
        </UISmartPanelGrid>
      </div>
    );
  },
});
export default AppraisalPropertyRemark;
