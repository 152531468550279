import {
  UIText,
  UIDataTable,
  UIColumn,
  UICurrency,
  UIPanel,
  UICell,
} from 'RainbowUI';

const EndoInCancellation = React.createClass({
  getInitialState() {
    const feeDetail = {};
    return {
      FeeDetail: feeDetail,
    };
  },

  render() {
    const _self = this;
    const { FeeDetail } = this.props;
    const feeDetail = FeeDetail.result;
    return (
      <UIPanel
        panelTitle={`${i18n.endorsement}-${feeDetail[0].Title}`}
      >
        <UIDataTable
          id={`feeDetailForEndoDataTable${_self.randomString(3)}`}
          value={feeDetail}
          pageable="false"
          indexable="false"
        >
          <UIColumn
            headerTitle={i18n.installmentNo}
            render={data => {
              return (
                <UIText
                  io="out"
                  value={data.InstallmentNo}
                  style={_self.getBoldStyle(data)}
                />
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.premiumDue}
            render={data => {
              return (
                <UICell style={_self.getBoldStyle(data)}>
                  <UICurrency
                    unit={data.LocalCurrencySign}
                    io="out"
                    value={data.PremiumDue}
                  />
                </UICell>
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.actualPremium}
            render={data => {
              return (
                <UICell style={_self.getBoldStyle(data)}>
                  <UICurrency
                    unit={data.LocalCurrencySign}
                    io="out"
                    value={data.ActualPremium}
                  />
                </UICell>
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.policyFee}
            render={data => {
              return (
                <UICell style={_self.getBoldStyle(data)}>
                  <UICurrency
                    unit={data.LocalCurrencySign}
                    io="out"
                    value={data.Fee}
                  />
                </UICell>
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.tax}
            render={data => {
              return (
                <UICell style={_self.getBoldStyle(data)}>
                  <UICurrency
                    unit={data.LocalCurrencySign}
                    io="out"
                    value={data.Tax}
                  />
                </UICell>
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.stamps}
            render={data => {
              return (
                <UICell style={_self.getBoldStyle(data)}>
                  <UICurrency
                    unit={data.LocalCurrencySign}
                    io="out"
                    value={data.StampDuty}
                  />
                </UICell>
              );
            }}
          />
        </UIDataTable>
      </UIPanel>
    );
  },

  getBoldStyle(data) {
    if (data.InstallmentNo === 'Total') {
      return { 'font-weight': 'bold' };
    }
    return {};
  },

  // Generate random data table id
  randomString(templen) {
    let len = templen;
    len = len || 32;
    const $chars =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz123456789';
    const maxPos = $chars.length;
    let pwd = '';
    for (let i = 0; i < len; i += 1) {
      pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return pwd;
  },
});
export default EndoInCancellation;
