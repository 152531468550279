import React from 'react'
import {
  UIPage,
  UIButton,
  UICell,
  UpdateContext,
  UIMenuBar,
  UIMenuItem,
  UIBreadCrumb,
  UIBox,
  MessageHelper,
} from 'RainbowUI';
import { ClaimTaskTransferDialog } from 'ClaimCommon';
import ClaimNoticeExt from 'ClaimNoticeExt';
import NoticeStore from '../store/NoticeStore';

const AccidentInfo = require('./AccidentInfo');
const RejectNoticeDialog = require('./RejectNoticeDialog');
const NoticeOfLossAction = require('../action/NoticeOfLossAction');

const FNOL = React.createClass({
  getInitialState() {
    return {
      noticeEntity: { '@type': 'ClaimNotice-ClaimNotice', AddressVo: {} },
      productTree: [],
    };
  },

  componentWillMount() {
    NoticeStore.listen(this.onStoreStateChange);
    NoticeOfLossAction.initNotice();
  },

  componentWillUnmount() {
    NoticeStore.unlisten(this.onStoreStateChange);
  },

  onStoreStateChange(storeState) {
    if (!_.isEmpty(storeState.noticeEntity)) {
      this.setState({ noticeEntity: storeState.noticeEntity });
    }
  },

  clickSave() {
    NoticeOfLossAction.saveNotice(this.getNoticeEntity('NEW'));
    UpdateContext.forceUpdate('countryCityForUpdate');
  },

  clickSubmit() {
    const {
      retrieveInfo,
    } = this.refs.AccidentInfo.refs.PolicyInfo.state;

    if (
      this.refs.AccidentInfo.checkInsuredNameAndInsuredType &&
      this.refs.AccidentInfo.checkInsuredNameAndInsuredType()
    ) {
      return;
    }

    const noticeEntity = this.getNoticeEntity('CLOSED');
    const {
      policyEntity,
    } = this.refs.AccidentInfo.refs.PolicyDetailDialog.state;

    if (policyEntity.PolicyNo) {
      noticeEntity.PolicyEntity = policyEntity;
    }

    const param = NoticeOfLossAction.submitNotice(noticeEntity);
    param.TaskCode = 'ClaimRegistrationTask';
    if (param.CaseId) {
      this.refs.ClaimTaskTransferDialog.showDialog(param);
    }
  },
  checkWithPolicyNo(noticeEntity) {
    if (noticeEntity.PolicyNo && !noticeEntity.PolicyEntity) {
      MessageHelper.error('', i18n.ClaimNotice.PolicyNoError);
      return false;
    }
    return true;
  },

  getNoticeEntity(status) {
    const { noticeEntity } = this.state;

    noticeEntity.NoticeStatus = status;
    return noticeEntity;
  },
  checkDate(data) {
    const myDate = new ServerDate();
    if (Date.parse(data.AccidentTime) > Date.parse(myDate)) {
      MessageHelper.error(
        '',
        i18n.ClaimCommon.CheckClaimDataMessage03
      );
      return false;
    }
    if (Date.parse(data.AccidentTime) > Date.parse(data.NoticeTime)) {
      MessageHelper.error(
        '',
        i18n.ClaimCommon.CheckClaimDataMessage01
      );
      return false;
    }
    if (
      data.RegistrationTime &&
      Date.parse(data.NoticeTime) > Date.parse(data.RegistrationTime)
    ) {
      MessageHelper.error(
        '',
        i18n.ClaimCommon.CheckClaimDataMessage02
      );
      return false;
    }
    return true;
  },
  showRejectNoticeDialog() {
    this.refs.RejectNoticeDialog.showDialog();
  },

  updatePolicyInfo(poicyInfo) {
    this.refs.AccidentInfo.updatePolicyInfo(poicyInfo);
  },

  getCurrentUser() {
    const currentUser = NoticeOfLossAction.getCurrentUserInfo();
  },

  render() {
    const AccidentInfo = ClaimNoticeExt.getAccidentInfo();
    const { noticeEntity } = this.state;
    return (
      <UIPage>
        <UIMenuBar
          headerTitle={i18n.ClaimNotice.CLAIM}
          align="right"
        >
          <UIMenuItem />
        </UIMenuBar>
        <UICell>
          <UIBreadCrumb id="breadCrumb">
            <UIMenuItem
              value={i18n.ClaimCommon.NoticeofLoss}
              icon="fa"
              activeable="true"
            />
          </UIBreadCrumb>
        </UICell>
        <UICell>
          <AccidentInfo
            ref="AccidentInfo"
            data={noticeEntity}
            parentComponent={this}
          />

          <RejectNoticeDialog
            ref="RejectNoticeDialog"
            parentComponent={this}
          />
          <ClaimTaskTransferDialog ref="ClaimTaskTransferDialog" />

          <UIBox direction="left">
            <UIButton
              value={i18n.ClaimCommon.Save}
              onClick={this.clickSave}
              causeValidation="true"
              validationGroup="noticeDetail,noticeDetailClaimBranch,RetrieveInfo,RetrieveInfo_"
            />
            <UIButton
              value={i18n.ClaimCommon.Submit}
              onClick={this.clickSubmit}
              causeValidation="true"
              validationGroup="noticeDetail,noticeDetailClaimBranch,RetrieveInfo,RetrieveInfo_"
            />
            <UIButton
              value={i18n.ClaimCommon.Reject}
              onClick={this.showRejectNoticeDialog}
            />
          </UIBox>
          <br />
        </UICell>
      </UIPage>
    );
  },
});
export default FNOL;
