import alt from '../alt';

const CoverageTreeAction = require('../action/CoverageTreeAction');
// var NewSalvageVO=require('../action/vo/NewSalvageVO');

class CoverageTreeStore {
  constructor() {
    this.CoverageTreeData = {
      CoverageTree: [],
      CheckedCoverage: [],
      TaskInstanceId: null,
      SelectedLength: 0,
    };
    this.SelectedLength = 0;
    this.bindListeners({
      handleLoadCoverageTree: CoverageTreeAction.LOAD_COVERAGE_TREE,
      handleChooseCoverage: CoverageTreeAction.CHOOSE_COVERAGE,
    });
  }

  handleLoadCoverageTree(coverageTreeData) {
    this.CoverageTreeData.CoverageTree = coverageTreeData;
    //		this.CoverageTreeData.TaskInstanceId=coverageTreeData.TaskInstanceId;
    let selectedLength = 0;

    if (coverageTreeData) {
      for (let i = 0; i < coverageTreeData.length; i += 1) {
        if (
          coverageTreeData[i].pId != '0' &&
          coverageTreeData[i].checked == true
        ) {
          selectedLength += 1;
        }
      }
    }

    this.CoverageTreeData.SelectedLength = selectedLength;
  }

  handleChooseCoverage(checkedList) {
    let selectedLength = 0;
    for (let i = 0; i < checkedList.length; i += 1) {
      const checked = checkedList[i];
      if (checked.isParent == true) {
        const coverageList = checked.children;
        for (let j = 0; j < coverageList.length; j += 1) {
          const coverage = coverageList[j];
          if (coverage.checked == true) {
            selectedLength += 1;
          }
        }
      }
    }

    const coverageTrees = this.CoverageTreeData.CoverageTree;
    // update coverageTree
    for (let k = 0; k < coverageTrees.length; k += 1) {
      coverageTrees[k].checked = false;
      for (let i = 0; i < checkedList.length; i += 1) {
        if (
          (coverageTrees[k].id == checkedList[i].id &&
            coverageTrees[k].pId == '0') ||
          (coverageTrees[k].pId == checkedList[i].pId &&
            coverageTrees[k].id == checkedList[i].id)
        ) {
          coverageTrees[k].checked = true;
          break;
        }
      }
    }

    this.SelectedLength = selectedLength;
    this.CoverageTreeData.SelectedLength = selectedLength;
    this.CoverageTreeData.CheckedCoverage = checkedList;
  }
}

export default alt.createStore(CoverageTreeStore, CoverageTreeStore);
