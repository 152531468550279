import {
  UIBox,
  UIButton,
  UISmartPanelGrid,
  UISelect,
  UpdateContext,
  UIUpdatePanel,
  UINumber,
  UIText,
  UIParam,
  UITextarea,
  UILabel,
  DataContext,
  MessageHelper,
  CodeTable,
  CodeTableSorter,
  UICell,
} from 'RainbowUI';
import React from 'react';
import PropTypes from 'prop-types';
import BindToMixin from 'react-binding';
import { SelectPayeeDialog } from 'ClaimCommon';
import {
  CodeTableAction,
  CodeTableCstUI,
  ClaimCstUI,
} from 'ClaimCommonConstant';
import ClaimSettlement from 'ClaimSettlement';
import {
  PtyIntegrationAddress,
  PartyConst,
  PartyAction,
  PtyIntegrationAccount,
  DefinCodeTable,
} from 'EU00PartyCommonUI';
import ClaimCommonExt from 'ClaimCommonExt';

const ClaimPartyAction = ClaimCommonExt.getClaimPartyAction();
const SettlementLoadAction = ClaimSettlement.getSettlementLoadAction();
const mockCodeTable = require('../mock/CodeTable');

const SettlementPayeeInfo = React.createClass({
  mixins: [BindToMixin],

  propTypes: {
    index: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
    settlementPayee: PropTypes.object.isRequired,
    caseId: PropTypes.string.isRequired,
  },

  getInitialState() {
    const payeeTypes = [];
    if (this.props.settlementPayee.PayeeId) {
      const claimParty = ClaimPartyAction.loadClaimParty(
        this.props.settlementPayee.PayeeId
      );
      const partyId = claimParty.Model.PtyPartyId;
      const partyAction = new PartyAction();
      const party = partyAction.loadPartyByPartyId(partyId);
      if (party && party.PartyRoleList) {
        for (let i = 0; i < party.PartyRoleList.length; i += 1) {
          payeeTypes.push({
            id: party.PartyRoleList[i].RoleType,
            text: party.PartyRoleList[i].RoleName,
          });
        }
      }
    }
    const payeeTypeCodeTable = new CodeTable(
      payeeTypes,
      null,
      new CodeTableSorter()
    );
    let isDirectCredit = false;
    if (this.props.settlementPayee.PayMode === '109') {
      isDirectCredit = true;
    }
    const $state = {
      condition: {},
      payee: this.props.settlementPayee,
      settlementPayeeState: {},
      settlementPayeeAddress: '',
      settlementPayeeAccount: {},
      roleType: '',
      payeeTypes: payeeTypeCodeTable,
      payeeName: '',
      isDirectCredit,
    };
    return $state;
  },

  setPayeeTypesCodeTable() { },

  showPartySearchPanelDialog(event) {
    const paymode = this.state.payee.PayMode;

    if (!paymode) {
      MessageHelper.warning(
        i18n.ClaimSettlement.WarnMsg.Warn_0002,
        i18n.ClaimSettlement.WarnMsg.Warn_0003,
        MessageHelper.POSITION_TOP_RIGHT
      );
      return;
    }
    const indPartyRole = event.getParameter('indPartyRole');
    const orgPartyRole = event.getParameter('orgPartyRole');
    const stateParam = event.getParameter('stateParam');
    const dialog = this.props.parentComponent.getPartySearchPanelDialog();

    dialog.showDialog({
      indPartyRole,
      orgPartyRole,
      stateParam,
      createBtnDisable: false,
      editLinkVisiable: false,
      partyRoleSelectVisiable: false,
    });

    dialog.doContinue = (...args) => {
      const party = args[0];
      const partyFull = args[1];
      this.state.roleType = args[2];
      if (!party) return;
      const stateJson = {};
      if (party.partyInfo.length === 1) {
        eval(`stateJson.${stateParam}=party.partyInfo[0]`);
        this.setState(stateJson);
        if (stateParam === 'settlementPayeeState') {
          this.props.settlementPayee.PayeeName =
            party.partyInfo[0].PartyName;
          this.props.settlementPayee.PayeeId =
            party.partyInfo[0].PartyId;
        }
        if (party.partyPrimaryAddress) {
          const partyAction = new PartyAction();
          const address = partyAction.buildAddress(
            party.partyPrimaryAddress
          );
          this.setState({ settlementPayeeAddress: address });

          this.props.settlementPayee.Address = address;
        }
        if (paymode === '106' || paymode === '107') {
          if (party.partyPrimaryAccount) {
            const { settlementPayeeAccount } = this.state;
            settlementPayeeAccount.AccountNo =
              party.partyPrimaryAccount.AccountNo;
            settlementPayeeAccount.SwiftCode =
              party.partyPrimaryAccount.SwiftCode;
            this.setState({
              settlementPayeeAccount,
            });

            this.props.settlementPayee.PtyAccountId =
              party.partyPrimaryAccount.AccountId;
            this.props.settlementPayee.AccountNo =
              party.partyPrimaryAccount.AccountNo;
            this.props.settlementPayee.AccountName =
              party.partyPrimaryAccount.AccountName;
            this.props.settlementPayee.SwiftCode =
              party.partyPrimaryAccount.SwiftCode;
          }
        }
        this.setPayeeTypesCodeTable(partyFull);
        this.createClaimParty();
      }
      dialog.doContinue = () => { };
      const partyInfo = args[1];
      if (!partyInfo) {
        return;
      }
      const partyType = partyInfo['@type'];
      let payeeCode = '';
      let type = '';
      let objectId = '';
      if (partyType === 'PartyIndividual-PartyIndividual') {
        payeeCode = '012';
        type =
          'PartyIndividualPayeePayerRole-PartyIndividualPayeePayerRole';
        objectId = 75198419;
      } else {
        payeeCode = '011';
        type =
          'PartyOrganizationalPayeePayerRole-PartyOrganizationalPayeePayerRole';
        objectId = 75199034;
      }
      let isexist = false;
      if (!partyInfo.PartyRoleList) {
        partyInfo.PartyRoleList = [];
      }
      partyInfo.PartyRoleList.forEach(partyRole => {
        const roleType = partyRole.RoleType;
        if (roleType == payeeCode) {
          isexist = true;
        }
      });
      if (!isexist) {
        partyInfo.PartyRoleList[partyInfo.PartyRoleList.length] = {
          '@type': type,
          BusinessObjectId: objectId,
          Status: 1,
          RoleType: payeeCode,
        };
        let { PartyHistoryList } = partyInfo;
        if (!PartyHistoryList) {
          PartyHistoryList = [];
        }
        const newPartyHistory = PartyConst.PARTY_HISTORY_CREATE_ROLE;
        PartyHistoryList.push(newPartyHistory);
        const partyAction = new PartyAction();
        partyAction.updateParty(partyInfo);
      }
    };
  },

  buildAddress(addressInfo) {

    const { StreetName } = addressInfo.AddressLine1;
    const { BuildingName } = addressInfo;
    const { ApartmentNumber } = addressInfo;
    const { Postcode } = addressInfo;
    const { State } = addressInfo;
    const { City } = addressInfo;

    let addressStr = '';

    if (StreetName) {
      addressStr += `${StreetName}, `;
    }
    if (BuildingName) {
      addressStr += `${BuildingName}, `;
    }
    if (ApartmentNumber) {
      addressStr += `${ApartmentNumber}, `;
    }
    if (Postcode) {
      addressStr += `${Postcode}, `;
    }
    if (State) {
      addressStr += `${State}, `;
    }
    if (City) {
      addressStr += `${City}, `;
    }
    return addressStr;
  },

  showPartyAddressDialog() {
    const payee = this.props.settlementPayee;
    const payeeVal = payee.PayeeId;

    if (!payeeVal) {
      MessageHelper.warning(i18n.ClaimSettlement.WarnMsg.Warn_0004);
      return;
    }
    const claimParty = ClaimPartyAction.loadClaimParty(
      this.props.settlementPayee.PayeeId
    );
    const claimPartyId = claimParty.Model.PtyPartyId;
    this.refs.PtyIntegrationAddress.showDialog(claimPartyId);
  },

  showPartyAccountDialog() {
    const payee = this.props.settlementPayee;
    const payeeVal = payee.PayeeId;

    if (!payeeVal) {
      MessageHelper.warning(i18n.ClaimSettlement.WarnMsg.Warn_0004);
      return;
    }

    const paymode = payee.PayMode;

    if (ClaimCstUI.PAY_MODE.DirectCredit !== paymode) {
      MessageHelper.warning(i18n.ClaimSettlement.WarnMsg.Warn_0005);
      return;
    }
    const claimParty = ClaimPartyAction.loadClaimParty(
      this.props.settlementPayee.PayeeId
    );
    const claimPartyId = claimParty.Model.PtyPartyId;
    this.refs.PtyIntegrationAccount.showDialog(claimPartyId);
  },

  createClaimParty() {
    const commonParty = {
      NewRoleType: this.state.roleType,
      PtyPartyId: Number(this.props.settlementPayee.PayeeId),
      PartyName: this.props.settlementPayee.PartyName,
    };
    ClaimPartyAction.createPartyParty(this.props.caseId, commonParty);
  },

  getPayeeTypeCodeTable() { },

  changeCurrency(event) {
    const SettlementLoadActionProcess = ClaimSettlement.getSettlementLoadActionProcess();
    const data = SettlementLoadActionProcess.changeCurrencyCode(
      event
    );
    this.state.payee.ReserveExchangeRate = data.ReserveExchangeRate;
    if (data.SettlementCurrencyCode) {
      this.props.changeSettlementCurrency(
        data.SettlementCurrencyCode
      );
    }
    UpdateContext.forceUpdate('exchangeRatePanel');
    this.forceUpdate();
  },
  settlementPayeeNamePanel() {
    const nameList = DataContext.get(
      `settlementPayeeName${this.props.index}`
    );
    const roleType = DataContext.get(`roleType${this.props.index}`);
    this.state.roleType = roleType;
    const suffixId = this.props.index;
    const payee = this.props.settlementPayee;
    let actionColumn = (
      <div>
        <UIText
          id={`${suffixId}payeeName`}
          label={i18n.ClaimSettlement.PayeeName}
          colspan="1"
          suffixIcon="fa fa-search"
          onSuffixIconClick={SettlementLoadAction.selectPayee}
          onChange={this.payeeChange()}
          model={payee}
          property="PayeeName"
          required="true"
          validationGroup="submit_validate"
          io={this.getIOStyle(1)}
          enabled="false"
        >
          <UIParam name="payeeIndex" value={this.props.index} />
          <UIParam
            name="indPartyRole"
            value={PartyConst.PARTY_ROLE_CODE_IND_PAYEEPAYER}
          />
          <UIParam
            name="orgPartyRole"
            value={PartyConst.PARTY_ROLE_CODE_ORG_PAYEEPAYER}
          />
          <UIParam name="roleType" value={roleType} />
          <UIParam name="stateParam" value="settlementPayeeState" />
        </UIText>
      </div>
    );
    if (nameList && this.props.settlementPayee.PayeeName) {
      actionColumn = (
        <div>
          <UISelect
            id={`${suffixId}payeeName`}
            label={i18n.ClaimSettlement.PayeeName}
            model={payee}
            property="PayeeId"
            codeTable={nameList}
            io={this.getIOStyle()}
            required="true"
            onChange={this.changePayeeName}
          >
            <UIParam name="payeeNameIndex" value={suffixId} />
          </UISelect>
        </div>
      );
    }
    return actionColumn;
  },

  render() {
    const { readOnly, index, settlementPayee, caseId } = this.props;
    const suffixId = `settlementPayeeInfo_${readOnly}_${index}_`;
    const payee = settlementPayee;
    const accountName = payee.AccountName;
    const accountNo = payee.AccountNo;
    const {
      payee: { ReserveExchangeRate },
      isDirectCredit,
    } = this.state;
    let payeeAccount = '';
    if (accountName) {
      payeeAccount = accountName;
    }
    if (accountNo) {
      payeeAccount = `${payeeAccount} ${accountNo}`;
    }
    const _self = this;

    const settlementPayeeNamePanel = this.settlementPayeeNamePanel();
    return (
      <div>
        <UISmartPanelGrid id={`${suffixId}payeePanel`} column="3">
          <UIUpdatePanel id="setPayeePanel">
            {settlementPayeeNamePanel}
          </UIUpdatePanel>
          <div
            id={`${suffixId}deletePayeeDiv`}
            style={{ display: this.getDisplayStyle() }}
          >
            <UIBox direction="left">
              <UIButton
                id={`${suffixId}removePayee`}
                value={i18n.ClaimSettlement.DeletePayee}
                onClick={this.removePayee}
              >
                <UIParam name="removeIndex" value={index} />
              </UIButton>
            </UIBox>
          </div>

          <UICell />
          <UIText
            id={`${suffixId}address`}
            label={i18n.ClaimSettlement.PayeeAddress}
            colspan="3"
            suffixIcon="fa fa-edit"
            onSuffixIconClick={this.showPartyAddressDialog}
            model={payee}
            property="Address"
            io={this.getIOStyle()}
            enabled="false"
          />
          <UISelect
            id={`${suffixId}payMode`}
            label={i18n.ClaimSettlement.PayMode}
            model={payee}
            property="PayMode"
            codeTable={
              env.IS_MOCK == true
                ? mockCodeTable.payMode
                : CodeTableAction.loadDataList(
                  CodeTableCstUI.PlateFormPayMode,
                  null
                )
            }
            io={this.getIOStyle()}
            required="true"
            validationGroup="submit_validate"
            onChange={this.changePayMode}
          >
            <UIParam name="index" value={index} />
          </UISelect>
          <UISelect
            id={`${suffixId}currency`}
            label={i18n.ClaimCommon.Currency}
            model={payee}
            property="SettleCurrency"
            codeTable={CodeTableAction.loadDataList(
              CodeTableCstUI.ClaimCurrencyCode,
              null
            )}
            onChange={this.changeCurrency}
            required="true"
            validationGroup="submit_validate"
            io={this.getIOStyle()}
          >
            <UIParam name="payeeIndex" value={index} />
            <UIParam name="caseId" value={caseId} />
          </UISelect>

          <UIUpdatePanel id="exchangeRatePanel">
            <UINumber
              id={`NUMBER_CODE_${suffixId}exchangeRate`}
              label={i18n.ClaimSettlement.ExchangeRate}
              model={payee}
              property="ReserveExchangeRate"
              value={ReserveExchangeRate}
              io="out"
              format="###,###,###.######"
            />
          </UIUpdatePanel>
          <UISelect
            id={`${suffixId}bank`}
            label={i18n.ClaimSettlement.PayeeBank}
            model={payee}
            property="BankCode"
            codeTable={DefinCodeTable.getBankName}
            io={this.getIOStyle()}
            visibled={isDirectCredit}
            enabled="false"
          />
          <UIText
            id={`${suffixId}account`}
            label={i18n.ClaimSettlement.PayeeAccount}
            suffixIcon="fa fa-edit"
            onSuffixIconClick={this.showPartyAccountDialog}
            model={payee}
            property="AccountNo"
            io={this.getIOStyle()}
            visibled={isDirectCredit}
            enabled="false"
          />
          <UIText
            id={`${suffixId}IBANNo`}
            label={i18n.ClaimSettlement.PayeeIBAN}
            model={payee}
            property="IBANNo"
            io={this.getIOStyle()}
            visibled={isDirectCredit}
            enabled="false"
          />
          <UIText
            id={`${suffixId}swiftCode`}
            label={i18n.Account.SwiftCode}
            model={payee}
            property="SwiftCode"
            io={this.getIOStyle()}
            colspan="3"
            enabled="false"
            visibled={isDirectCredit}
          />
          <UITextarea
            id={`${suffixId}remark`}
            label={i18n.ClaimCommon.Remark}
            model={payee}
            property="Remark"
            colspan="3"
            enabled={!readOnly}
          />
          <UILabel id={`${suffixId}label`} label="" />
        </UISmartPanelGrid>
        <PtyIntegrationAddress
          ref="PtyIntegrationAddress"
          id="ShowPartyAddress"
          callBackGetRecord={_self.setPayeeAddress}
          title={i18n.ClaimSettlement.PayeeAddress}
        />
        <PtyIntegrationAccount
          ref="PtyIntegrationAccount"
          id="ShowPartyAccount"
          callBackGetRecord={_self.setPayeeAccount}
          title={i18n.ClaimSettlement.PayeeAccountTitle}
        />
        {this.getSelectPayeeDialog()}
      </div>
    );
  },
  setPayeeAddress(partyAddress) {
    const partyAction = new PartyAction();
    const address = partyAction.buildAddress(partyAddress);
    this.props.settlementPayee.Address = address;
    this.forceUpdate();
  },
  setPayeeAccount(partyAccount) {
    this.props.settlementPayee.BankCode = partyAccount.BankName;
    this.props.settlementPayee.AccountNo = partyAccount.AccountNo;
    this.props.settlementPayee.IBANNo = partyAccount.IBANNo;
    this.props.settlementPayee.SwiftCode = partyAccount.SwiftCode;
    this.forceUpdate();
  },
  getSelectPayeeDialog() {
    const _self = this;
    return (
      <SelectPayeeDialog
        index={_self.props.index}
        roleType={_self.state.roleType}
        caseId={_self.props.caseId}
        callback={_self.callback.bind(_self)}
        fromWhere="payee"
        title="Search Payer/Payee"
      />
    );
  },
  removePayee(event) {
    SettlementLoadAction.removePayee(
      event.getParameter('removeIndex')
    );
  },
  payeeChange() {
    this.getInitialState();
  },
  getIOStyle(notInput) {
    const { coInsurance, readOnly } = this.props;
    if (readOnly == true) {
      return 'out';
    }
    if (
      coInsurance &&
      coInsurance.CoinFlag == ClaimCstUI.BooleanConstant.TRUE &&
      coInsurance.CoinLeaderFlag ==
      ClaimCstUI.BooleanConstant.FALSE &&
      coInsurance.Selected == ClaimCstUI.CoFollower.ToCoLeader
    ) {
      if (notInput) {
        return 'out';
      }
      return null;
    }
    return null;
  },

  getDisplayStyle() {
    return this.props.readOnly == true ? 'none' : '';
  },
  getEnabled() {
    return this.props.readOnly == true ? 'false' : 'true';
  },

  getPayeeName() {
    const name = DataContext.get('settlementPayeeName');
    const suffixId =
      `settlementPayeeInfo_${this.props.readOnly}_${this.props.index}_` +
      `payeeNameDiv`;
    const suffixQueryId =
      `settlementPayeeInfo_${this.props.readOnly}_${this.props.index}_` +
      `payeeNameQueryDiv`;

    if (!name) {
      $(`#${suffixId}`).hide();
      $(`#${suffixQueryId}`).show();
    } else {
      $(`#${suffixId}`).show();
      $(`#${suffixQueryId}`).hide();
    }

    return name;
  },
  getPayeeTypes() {
    if (this.state.payeeTypes.codes.length === 0) {
      return this.props.settlementPayee.PayeeTypeCodeTable;
    }
    return this.state.payeeTypes;
  },
  callback(index, payee, roleList) {
    if (payee) {
      SettlementLoadAction.callbackPayee(index, payee);
    }
    if (roleList) {
      const payerTypeCode = [];
      for (let i = 0; i < roleList.length; i += 1) {
        payerTypeCode.push({
          id: roleList[i].RoleType,
          text: roleList[i].RoleName,
        });
      }

      this.state.payeeTypes = new CodeTable(
        payerTypeCode,
        null,
        new CodeTableSorter()
      );
      const suffixId =
        `settlementPayeeInfo_${this.props.readOnly}_${this.props.index}_` +
        `payeeType`;
      UpdateContext.forceUpdate(suffixId);
    }
    this.props.settlementPayee.BankCode = payee.BankName;
    this.props.settlementPayee.AccountNo = payee.AccountNo;
    this.props.settlementPayee.BankCode = payee.BankCode;
    this.props.settlementPayee.SwiftCode = payee.SwiftCode;
    this.props.settlementPayee.IBANNo = payee.IBANNo;
    this.props.settlementPayee.PayeeName = payee.PayeeName;
    this.props.settlementPayee.PayeeId = payee.PayeeId;
    if (payee.PaymentMethodId) {
      this.props.settlementPayee.PayMode = `${payee.PaymentMethodId}`;
      this.state.isDirectCredit =
        `${payee.PaymentMethodId}` === '109';
    }
    this.forceUpdate();
    UpdateContext.forceUpdate('settlement_detail');
  },
  getComponent() {
    return this;
  },
  changePayeeName(event) {
    let results = [];
    const claimPartyId = event.newValue;
    let address = '';
    if (claimPartyId !== '') {
      let partyRoleL = [];
      let partyAddressL = [];
      const claimParty = ClaimPartyAction.loadClaimParty(
        claimPartyId
      );
      const partyId = claimParty.Model.PtyPartyId;
      const partyAction = new PartyAction();
      const partyMo = partyAction.loadPartyByPartyId(partyId);
      partyRoleL = partyMo.PartyRoleList;
      partyAddressL = partyMo.AddressVoList;
      if (partyAddressL) {
        for (let j = 0; j < partyAddressL.length; j += 1) {
          if (partyAddressL[j].IsPrimaryAddress === 'Y') {
            address = `${partyAddressL[j].StreetName},${partyAddressL[j].PostCode},${partyAddressL[j].Area},${partyAddressL[j].City}`;
          }
        }
      }
      const item = [];
      for (let i = 0; i < partyRoleL.length; i += 1) {
        item.push({
          id: partyRoleL[i].RoleType,
          text: partyRoleL[i].RoleName,
        });
      }
      results = new CodeTable(item);
    }
    this.props.settlementPayee.Address = address;
    this.setState({
      payeeTypes: results,
      settlementPayeeAddress: address,
    });
  },
  changePayMode(event) {
    const index = event.getParameter('index');
    const payMode = event.newValue;
    DataContext.put(`settlement_Payee_PayMode${index}`, payMode);
    if (payMode === '109') {
      this.setState({ isDirectCredit: true });
    } else {
      this.setState({ isDirectCredit: false });
    }
  },
});
export default SettlementPayeeInfo;
