import {
  UIBox,
  UIDialog,
  UIUpdatePanel,
  DataContext,
  UIText,
  UIButton,
  UISmartPanelGrid,
} from 'RainbowUI';
import BindToMixin from 'react-binding';
import ProductTypeStore from '../store/ProductTypeStore';

const ProductTypeAction = require('../action/ProductTypeAction');
const ProductCfgTreeAction = require('../action/ProductCfgTreeAction');

const ShowProductTypeDialog = React.createClass({
  mixins: [BindToMixin],

  getInitialState() {
    return {
      ProductTypeEntity: {
        ClaimProductEntiriys: [],
      },
    };
  },

  componentDidMount() {
    ProductTypeStore.listen(this.onStoreStateChange);
  },

  componentWillUnmount() {
    ProductTypeStore.unlisten(this.onStoreStateChange);
  },

  onStoreStateChange(storeState) {
    if (!_.isEmpty(storeState.ProductTypeEntity)) {
      this.setState({
        ProductTypeEntity: storeState.ProductTypeEntity,
        Io: storeState.Io,
      });
    }
  },
  onSave() {
    ProductTypeAction.saveProducttype(this.state.ProductTypeEntity);
    ProductCfgTreeAction.initData();
  },

  render() {
    return (
      <UIDialog
        id="showProductTypeDialog"
        title={i18n.ClaimProduct.ProductType}
        width="80%"
        height="600px"
        modal="false"
      >
        <UIUpdatePanel
          id="showProductTypeDialogUpdateId"
          render={this.updateUIDialogRender}
        />
        <UIBox direction="left">
          <UIButton
            value={i18n.ClaimProduct.Save}
            onClick={this.onSave}
            validationGroup="product"
            causeValidation="true"
          />
          <UIButton
            value={i18n.ClaimProduct.Cancel}
            buttonType="cancel"
          />
        </UIBox>
      </UIDialog>
    );
  },
  updateUIDialogRender() {
    const flag = DataContext.get('showProductType').out;
    return (
      <UISmartPanelGrid column="2" widthAllocation="4,8">
        <UIText
          io={flag}
          id="productTypeCode"
          label={i18n.ClaimProduct.ProductTypeCode}
          valueLink={this.bindToState(
            'ProductTypeEntity',
            'ProductTypeCode'
          )}
          required="true"
          validationGroup="product"
        />
        <UIText
          id="productTypeName"
          label={i18n.ClaimProduct.ProductTypeName}
          valueLink={this.bindToState(
            'ProductTypeEntity',
            'ProductTypeName'
          )}
          required="true"
          validationGroup="product"
        />
        <UIText
          id="productTypeDescription"
          label={i18n.ClaimProduct.ProductTypeDescription}
          valueLink={this.bindToState(
            'ProductTypeEntity',
            'ProductTypeDesc'
          )}
        />
        <UIText
          id="policySysCode"
          label={i18n.ClaimProduct.ExtProductTypeCode}
          valueLink={this.bindToState(
            'ProductTypeEntity',
            'ExtProductTypeCode'
          )}
        />
        {/* <UIText id="desc" value="Use '' to separate policy sys codes" io="out"/> */}
      </UISmartPanelGrid>
    );
  },
});
export default ShowProductTypeDialog;
