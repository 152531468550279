import {
  UIUpdatePanel,
  UIParam,
  UILink,
  UICell,
  UISelect,
  UIButton,
  UIBox,
  UIDataTable,
  UIColumn,
  DataContext,
} from 'RainbowUI';
import { CodeTableAction, CodeTableCstUI } from 'ClaimCommonConstant';

const SubclaimTypeDialog = require('./SubclaimTypeDialog');
const RemoveSubclaimTypeWarning = require('./RemoveSubclaimTypeWarning');
const RemoveProductLineConfirmDialog = require('./RemoveProductLineConfirmDialog');
const SubclaimTypeAction = require('../action/SubclaimTypeAction');

const SettlementTypeList = React.createClass({
  getInitialState() {
    return { count: 0 };
  },

  getSubclaimTypeList(pageindex, pagesize) {
    const subclaimTypeList = SubclaimTypeAction.getSubclaimTypeList(
      pageindex,
      pagesize
    );

    return subclaimTypeList;
  },

  showSubclaimTypeDetail(event) {
    let data = event.getParameter('data');
    const flag = {};
    if (!data) {
      data = {};
      flag.out = 'in';
    } else {
      flag.out = 'out';
    }
    this.refs.SubclaimTypeDialog_ref.showDialog(data, flag);
  },

  updatePage() {
    this.setState(prevState => ({ count: prevState.count + 1 }));
  },

  removeSubclaimTypeConfirmDialog(event) {
    const data = event.getParameter('data');

    data.removeType = 'subclaimtype';
    DataContext.put('data', data);
    this.refs.RemoveProductLineConfirmDialog_ref.showDialog(data);
  },

  render() {
    const self = this;
    return (
      <div>
        <div>
          <UIUpdatePanel id="subclaimTypeListPageSearchResultProvider">
            <UIDataTable
              id="subclaimTypeListPage"
              indexable="false"
              provider={this.getSubclaimTypeList}
            >
              <UIColumn
                headerTitle={i18n.ClaimProduct.SubclaimTypeCode}
                width="15%"
                render={data => {
                  return (
                    <u>
                      <UILink
                        value={data.SubclaimTypeCode}
                        onClick={self.showSubclaimTypeDetail}
                      >
                        <UIParam name="data" value={data} />
                      </UILink>
                    </u>
                  );
                }}
              />
              <UIColumn
                headerTitle={i18n.ClaimProduct.SubclaimTypeName}
                width="20%"
                value="SubclaimTypeName"
              />
              <UIColumn
                headerTitle={i18n.ClaimProduct.FraudSubjectCode}
                width="20%"
                value="FraudSubjectCode"
              >
                <UISelect
                  codeTable={CodeTableAction.loadDataList(
                    CodeTableCstUI.ClaimFraudSubject,
                    null
                  )}
                  io="out"
                />
              </UIColumn>
              <UIColumn
                headerTitle={
                  i18n.ClaimProduct.SubclaimTypeDescription
                }
                width="35%"
                value="SubclaimTypeDescription"
              />
              <UIColumn
                headerTitle={i18n.ClaimProduct.Action}
                width="15%"
                render={data => {
                  return (
                    <UILink
                      value={i18n.ClaimProduct.Remove}
                      icon="fa fa-trash"
                      onClick={self.removeSubclaimTypeConfirmDialog}
                    >
                      <UIParam value={data} name="data" />
                    </UILink>
                  );
                }}
              />
            </UIDataTable>
          </UIUpdatePanel>
        </div>
        <UICell>
          <UIBox direction="left">
            <UIButton
              value={i18n.ClaimProduct.New}
              onClick={this.showSubclaimTypeDetail}
            />
          </UIBox>
        </UICell>
        <SubclaimTypeDialog
          ref="SubclaimTypeDialog_ref"
          parentComponet={this}
        />
        <RemoveSubclaimTypeWarning />
        <RemoveProductLineConfirmDialog
          ref="RemoveProductLineConfirmDialog_ref"
          removeType="subclaimtype"
          parentComponet={this}
        />
      </div>
    );
  },
});
export default SettlementTypeList;
