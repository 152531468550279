import { UIDialog, UIButton, UICell, UIBox } from 'RainbowUI';
import { ApprovalHistory } from 'ClaimCommon';

const SalvageReadOnly = require('./SalvageReadOnly');
const SalvageHistoryStore = require('../store/SalvageHistoryStore');

const SalvageReadOnlyDialog = React.createClass({
  getInitialState() {
    return {};
  },

  componentWillMount() {
    SalvageHistoryStore.listen(this.onStoreStateChange);
  },
  componentWillUnmount() {
    SalvageHistoryStore.unlisten(this.onStoreStateChange);
  },

  onStoreStateChange(storeState) {
    this.setState({ SalvageId: storeState.SalvageId });
  },

  render() {
    const { SalvageId } = this.state;
    const salvageId = SalvageId;
    const approveHistory =
      salvageId === null ||
      salvageId === undefined ||
      salvageId === '' ? (
        <UICell />
      ) : (
        <ApprovalHistory processObjectId={salvageId} history />
      );
    return (
      <UIDialog
        id="SalvageReadOnlyDialog"
        title={i18n.ClaimSalvage.SalvageHistoryDetail}
        width="90%"
        height="1000px"
        modal="false"
      >
        <SalvageReadOnly
          salvageId={salvageId}
          history
          isApproveTask={false}
        />
        <br />
        {approveHistory}
        <UIBox direction="center">
          <UIButton
            value={i18n.ClaimCommon.Back}
            position="right"
            onClick={this.onClose}
          />
        </UIBox>
      </UIDialog>
    );
  },
  onClose() {
    UIDialog.hide('SalvageReadOnlyDialog');
  },
});
export default SalvageReadOnlyDialog;
