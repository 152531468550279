import { UITabItem, UITab, UIParam } from 'RainbowUI';

const ReserveInfo = require('../../../reserve/component/ReserveInfo');
const FATaskTab = require('./FATaskTab');

const SubClaimTabs = React.createClass({
  render() {
    const {
      subclaimTabs: tabs,
      objectId,
      taskInstanceInfo,
      index,
      subclaimStatusCode,
      claimData,
      claimEntity,
      showReserve,
    } = this.props;
    let { dtClaimData } = this.props;
    let tabContent = '';
    const defTabContent = [];
    const taskInfo = taskInstanceInfo || {};
    let activeIndex = 0;
    let damageType = '';
    dtClaimData = dtClaimData || [];
    for (let i = 0; i < dtClaimData.length; i += 1) {
      const oid = dtClaimData[i].ObjectId;
      if (oid == objectId) damageType = dtClaimData[i].DamageType;
    }
    let fromSelf = false;
    if (
      objectId == taskInfo.AttachTo &&
      taskInfo.TaskCode &&
      (taskInfo.TaskCode.indexOf('ClaimFI') != -1 ||
        taskInfo.TaskCode.indexOf('ClaimAppraisal') != -1)
    ) {
      fromSelf = true;
    }
    const subClaim = claimEntity.ObjectList[index];
    const policyInfo = claimEntity.PolicyEntity;
    const p = {
      objectId,
      taskInfo,
      damageType,
      claimNo: claimData.ClaimNo,
      policyInfo,
      subClaim,
    };
    const FaContext = FATaskTab(p);
    if (fromSelf === true) {
      FaContext.tabs('FromSelf', defTabContent);
    } else {
      if (
        showReserve == 'true' &&
        subclaimStatusCode &&
        subclaimStatusCode != 'NEW' &&
        subclaimStatusCode != 'REJECT'
      ) {
        const reserveInfoNew = $.extend(
          true,
          {},
          <ReserveInfo
            id={index}
            objectId={objectId}
            authMap={claimData.AuthMap}
            subclaimStatusCode={subclaimStatusCode}
          />
        );
        defTabContent.push(
          <UITabItem title={i18n.ClaimReserve.Reserve}>
            {reserveInfoNew}
          </UITabItem>
        );
      }
      FaContext.tabs('FromOther', defTabContent);
    }

    if (tabs != null && tabs.length > 0) {
      tabContent = tabs.map(tab => {
        let tabParam = [];
        if (tab.params != null && tab.params.length > 0) {
          tabParam = tab.params.map(param => {
            return <UIParam name={param.name} value={param.value} />;
          });
        }
        if (tab.IsActive != null && tab.IsActive == 1) {
          activeIndex = index;
        }
        tabParam.push(<UIParam name="action" value={tab.action} />);
        tabParam.push(<UIParam name="objectId" value={objectId} />);
        return (
          <UITabItem title={tab.title} param={tabParam}>
            {tab.content}
          </UITabItem>
        );
      });
    }

    if (tabContent != null && tabContent.length > 0) {
      tabContent.forEach(tabcon => {
        defTabContent.push(tabcon);
      });
    }

    if (defTabContent != null && defTabContent.length > 0) {
      return (
        <UITab
          id="subclaimTab"
          activeIndex={activeIndex + 1}
          onTabChange={this.subclaimTabAction}
        >
          {defTabContent}
        </UITab>
      );
    }
    return null;
  },

  subclaimTabAction(event) {
    if (event.getParameter('action') != null) {
      event.getParameter('action')(event);
    }
  },
});
export default SubClaimTabs;
