import ClaimCommonExt from 'ClaimCommonExt';
import QueryCommonParty from './QueryCommonParty';
import ClaimPartyStore from '../store/ClaimPartyStore';
import ClaimPartyAction from '../action/ClaimPartyAction';

const ClaimPartyRightPanel = React.createClass({
  getInitialState() {
    return { showPage: null, partyDetail: {}, partyAuth: null };
  },

  componentWillMount() {
    ClaimPartyStore.listen(this.onStoreStateChange);
  },
  componentWillUnmount() {
    ClaimPartyStore.unlisten(this.onStoreStateChange);
  },

  onStoreStateChange(storeState) {
    if (!_.isEmpty(storeState.partyVo)) {
      this.setState({
        showPage: 'PartyDetail',
        partyDetail: storeState.partyVo,
        partyAuth: storeState.partyAuth,
      });
    }
  },

  showPageChange(showPage, partyDetail) {
    this.setState({
      showPage,
      partyDetail,
      partyAuth: partyDetail.partyAuth,
    });
  },

  loadNewParty(partyDetail) {
    ClaimPartyAction.initData(this.props.caseId);
    ClaimPartyAction.loadParty(partyDetail.PartyId, partyDetail.ClmPtyId, partyDetail.ClmPtyRole);
  },

  render() {
    const { showPage, partyDetail, partyAuth } = this.state;
    const {
      fromWhere,
      showRadio,
      index,
      caseId,
      callback,
      callbackList,
    } = this.props;
    const ClaimPartyDetailPanel = ClaimCommonExt.getClaimPartyDetailPanel();
    if (showPage === 'PartyDetail' || showPage === 'CreateParty') {
      return (
        <ClaimPartyDetailPanel
          fromWhere={fromWhere}
          showRadio={showRadio}
          index={index}
          caseId={caseId}
          data={partyDetail}
          partyAuth={partyAuth}
          callback={callback}
          callbackList={callbackList}
        />
      );
    }
    if (showPage === 'QueryCommonParty') {
      return (
        <QueryCommonParty
          fromWhere={fromWhere}
          parentComponent={this}
          index={index}
          caseId={caseId}
        />
      );
    }

    return null;
  },
  getSuperComponent() {
    return this;
  },
});
export default ClaimPartyRightPanel;
