import {
  UIText,
  UISelect,
  UIDateTimePicker,
  UIDialog,
  UISmartPanelGrid,
  UIBox,
  UIButton,
} from 'RainbowUI';
import CommonCodeTable from '../../codetable/CodeTable';
import PartyConst from '../../const/PartyConst';
import DateUtil from '../../../common/util/DateUtil';

const _ = require('underscore');

const ClaimantIndRole = React.createClass({
  getInitialState() {
    const partyRoleList = this.props.partyRoleSelected;
    let model = {
      '@type':
        'PartyIndividualClaimantRole-PartyIndividualClaimantRole',
      BusinessObjectId: 75197995,
      RoleType: PartyConst.PARTY_ROLE_CODE_IND_CLAIMANT,
    };
    _.each(partyRoleList, partyRole => {
      if (
        partyRole.RoleType == PartyConst.PARTY_ROLE_CODE_IND_CLAIMANT
      ) {
        model = partyRole;

        return model;
      }
    });

    const status = model.Status;
    const visible = !(
      status == '1' ||
      status === undefined ||
      status === null
    );
    return {
      PartyId: this.props.partyId,
      PartyRoleModel: model,
      Visible: visible,
    };
  },

  render() {
    const _self = this;
    const { PartyRoleModel, Visible } = this.state;
    const { isEdit } = this.props;
    return (
      <div>
        <UISmartPanelGrid column="3">
          <UISelect
            label={i18n.PartyRole}
            value={PartyConst.PARTY_ROLE_CODE_IND_CLAIMANT}
            enabled="false"
            codeTable={CommonCodeTable.getIndPartyRole}
            required="true"
          />
          <UIText
            label={i18n.Remarks}
            colspan="3"
            model={PartyRoleModel}
            property="Remark"
          />
          <UISelect
            label={i18n.Status}
            model={PartyRoleModel}
            property="Status"
            defaultValue="1"
            showBlankOption="false"
            codeTable={CommonCodeTable.getPartyStatus}
            onChange={_self.changeStatus}
            disabled={!(isEdit === 'Y')}
          />
          <UIDateTimePicker
            label={i18n.InactiveDate}
            disabled="true"
            format="MM/DD/YYYY"
            visibled={Visible}
            model={PartyRoleModel}
            property="InactiveDate"
          />
        </UISmartPanelGrid>
        <UIBox direction="center">
          <UIButton
            value={i18n.Save}
            causeValidation="true"
            validationGroup="partyClaimantRoleDialog"
            onClick={_self.onClickSubmit}
          />
          <UIButton
            value={i18n.Cancel}
            onClick={_self.onClickClose}
          />
        </UIBox>
      </div>
    );
  },

  changeStatus() {
    this.setState(prevState => {
      const activeStatus = prevState.PartyRoleModel.Status;
      const partyRoleModel = prevState.PartyRoleModel;
      partyRoleModel.InactiveDate = DateUtil.getCurrentDateTime();
      let visible = prevState.Visible;
      visible = !(
        activeStatus === undefined ||
        activeStatus === null ||
        activeStatus == '1'
      );
      return {
        Visible: visible,
        partyRoleModel,
      };
    });
  },

  onClickSubmit() {
    const _self = this;
    _.each(_self.props.RoleList, temppartyRole => {
      const partyRole = temppartyRole;
      if (
        partyRole.RoleType == PartyConst.PARTY_ROLE_CODE_IND_CLAIMANT
      ) {
        partyRole.Status = _self.state.PartyRoleModel.Status;
      }
    });
    const superParty = _self.props.parentComponent.state.Party;
    const { PartyHistoryList } = superParty;
    if (!_self.state.PartyRoleModel['@pk']) {
      const newPartyHistory = PartyConst.PARTY_HISTORY_CREATE_ROLE;
      PartyHistoryList.push(newPartyHistory);
    } else {
      const newPartyHistory = PartyConst.PARTY_HISTORY_UPDATE_ROLE;
      PartyHistoryList.push(newPartyHistory);
    }
    if (!superParty.PartyRoleList) {
      superParty.PartyRoleList = [];
    }
    superParty.PartyRoleList.push(_self.state.PartyRoleModel);
    _self.props.submitInfoHandler(superParty);
    UIDialog.hide('createClaimantIndPartyDialog');
  },
  onClickClose() {
    UIDialog.hide('createClaimantIndPartyDialog');
  },
});
export default ClaimantIndRole;
