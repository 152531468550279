import alt from '../alt';
import ProductTypeAction from '../action/ProductTypeAction';

class ProductTypeStore {
  constructor() {
    this.ProductTypeEntity = {
      ClaimProductEntiriys: [],
    };
    this.TreeNode = {};
    this.Io = {};
    this.ProductType = {};
    this.bindListeners({
      handleInitProductType: ProductTypeAction.INIT_PRODUCTTYPE,
      handleSaveProductType: ProductTypeAction.SAVE_PRODUCTTYPE,
      handleSaveNewProductType: ProductTypeAction.NEW_PRODUCTTYPE,
    });
  }

  handleInitProductType(ProductTypeEntity) {
    this.ProductTypeEntity = ProductTypeEntity;
  }

  handleSaveProductType(ProductTypeEntity) {
    this.ProductTypeEntity = ProductTypeEntity;
  }

  handleSaveNewProductType(ProductTypeEntity) {
    this.ProductTypeEntity = ProductTypeEntity;
  }
}
export default alt.createStore(ProductTypeStore, ProductTypeStore);
