import { UITextarea } from 'RainbowUI';
import BindToMixin from 'react-binding';

const AppraisalPropertyRemark = React.createClass({
  mixins: [BindToMixin],

  getInitialState() {
    return {
      AppraisalBodyInjury: this.props.appraisalBodyInjuryInfo,
    };
  },

  render() {
    return (
      <div>
        <UITextarea
          widthAllocation="2,10"
          id="remarkLossItem"
          label={i18n.ClaimAppraisal.RemarkToLossItems}
          valueLink={this.bindToState(
            'AppraisalBodyInjury',
            'LossItemRemark'
          )}
        />
        <UITextarea
          widthAllocation="2,10"
          id="remarkAppraisal"
          label={i18n.ClaimAppraisal.RemarkToAppraisal}
          valueLink={this.bindToState(
            'AppraisalBodyInjury',
            'BodyRemark'
          )}
        />
      </div>
    );
  },
});
export default AppraisalPropertyRemark;
