import { AjaxUtil, DataContext, MessageHelper } from 'RainbowUI';
import Url from '../url/Url';

const ClaimExceptionUtils = require('../util/ClaimExceptionUtils');

export default {
  loadAssignmentApproach() {
    const defer = $.Deferred();
    AjaxUtil.doGet(
      Url.AssignmentApproachQueryList,
      {},
      {
        async: false,
        done(data, textStatus, jqXHR) {
          if (data) {
            defer.resolve(data);
          } else {
            defer.reject({
              status: jqXHR.status,
              responseText: jqXHR.responseText,
            });
          }
        },
        fail(jqXHR) {
          defer.reject({
            status: jqXHR.status,
            responseText: jqXHR.responseText,
          });
        },
      }
    );
    return defer.promise();
  },

  saveAssignmentApproach() {
    const defer = $.Deferred();
    const de = DataContext.get('ChangeData');
    let result = null;
    AjaxUtil.doPost(Url.AssignmentApproachSaveList, de, {
      async: false,
      done(data) {
        result = data;
        result.result = 'SUCCESS';
        MessageHelper.info(
          ClaimExceptionUtils.formatI18NMsg(
            i18n.ClaimCommon.SaveSuccess,
            i18n.ClaimCommon.AssignmentApproach
          )
        );
      },
      fail(jqXHR) {
        defer.reject({
          status: jqXHR.status,
          responseText: jqXHR.responseText,
        });
      },
    });
    return defer.promise();
  },
};
