import { UIDialog, UIDialogFooter, UIButton } from 'RainbowUI';

const ClearRelatedInfoConfirmDialog = React.createClass({
  getInitialState() {
    return { changeField: '', callBackParm: null };
  },

  showDialog(changeField, callBackParm) {
    UIDialog.show('ClearRelatedInfoConfirmDialog');
    this.setState({
      changeField,
      callBackParm,
    });
  },

  clickContinue() {
    if (this.state.changeField === 'Policy') {
      this.props.parentComponent.countinueUpdatePolicyInfo(
        this.state.callBackParm
      );
    } else if (this.state.changeField === 'CauseofLoss') {
      this.props.parentComponent.countinueChangeCauseOfLoss();
    }
    UIDialog.hide('ClearRelatedInfoConfirmDialog');
  },

  clickCancel() {
    UIDialog.hide('ClearRelatedInfoConfirmDialog');
    if (this.state.changeField === 'CauseofLoss') {
      this.props.parentComponent.cancelChangeCauseOfLoss(
        this.state.callBackParm
      );
    }
  },

  render() {
    return (
      <UIDialog
        id="ClearRelatedInfoConfirmDialog"
        title=""
        width="65%"
        closeable="false"
      >
        {this.getMessage()}
        <h6 style={{ margin: '10px 40%' }}>
          {i18n.ClaimCommon.InsuredObject}
        </h6>
        <h6 style={{ margin: '10px 40%' }}>
          {i18n.ClaimCommon.SubclaimType}
        </h6>
        <h6 style={{ margin: '10px 40%' }}>
          {i18n.ClaimCommon.DamageType}
        </h6>
        <h6 style={{ margin: '10px 40%' }}>
          {i18n.ClaimCommon.Coverage}
        </h6>

        <UIDialogFooter>
          <UIButton
            value={i18n.ClaimCommon.Continue}
            onClick={this.clickContinue}
          />
          <UIButton
            value={i18n.ClaimCommon.Cancel}
            buttonType="cancel"
            onClick={this.clickCancel}
          />
        </UIDialogFooter>
      </UIDialog>
    );
  },

  getMessage() {
    const { changeField } = this.state;
    if (changeField === 'Policy') {
      return (
        <div>
          <h6 style={{ margin: '10px 15%' }}>
            {i18n.ClaimCommon.ChangePolicyMessage}
          </h6>
          <h6 style={{ margin: '10px 40%' }}>
            {i18n.ClaimCommon.CauseofLoss}
          </h6>
          <h6 style={{ margin: '10px 40%' }}>
            {i18n.ClaimCommon.InsuredObject}
          </h6>
        </div>
      );
    }
    if (changeField === 'CauseofLoss') {
      return (
        <h6 style={{ margin: '10px 15%' }}>
          {i18n.ClaimCommon.ChangeCauseofLossMessage}
        </h6>
      );
    }

    return <div />;
  },
});
export default ClearRelatedInfoConfirmDialog;
