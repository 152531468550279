const logo = require('./images/vault_logo.png');

export default {
  logo,
  taskDefinitions: {
    ClaimRegistrationTask: '#/task/registrationHandling',
    ClaimSettlementTask: '#/task/settlementHandling',
    ClaimSettlementApprovalTask: '#/task/settlementApproval',
    ClaimRecoveryTask: '#/task/recoveryHandling',
    ClaimRecoveryApprovalTask: '#/task/recoveryApproval',
    ClaimCalculationTask: '#/task/calculationHandling',
    ClaimCalculationApprovalTask: '#/task/calculationApproval',
    ClaimRefundTask: '#/task/refundHandling',
    ClaimRefundApprovalTask: '#/task/refundApproval',
  },
};
