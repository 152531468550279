import {
  UIPanel,
  UISelect,
  UIParam,
  UIButton,
  UIDialog,
  UIDialogFooter,
  CodeTable,
} from 'RainbowUI';
import { ClaimHandlingAction, ReserveAction } from 'ClaimCommon';

const OpenReserveDialog = React.createClass({
  getInitialState() {
    return {};
  },

  showDialog(newPolicyInfo, TestCaseForm) {
    const caseForm = TestCaseForm;
    caseForm.ObjectRelativeList = [];
    const objectList = caseForm.caseEntity.ObjectList;
    if (objectList) {
      for (let i = 0; i < objectList.length; i += 1) {
        const temp = objectList[i];
        caseForm.ObjectRelativeList.push({ ObjectId: temp.ObjectId });
      }
    }
    this.setState({
      caseForm,
      newPolicyInfo,
    });
    UIDialog.show('withOutPolicyInsured');
  },

  fillCodeTable() {
    const insuredList = this.state.newPolicyInfo.InsuredList;
    const insureds = [];
    for (let i = 0; i < insuredList.length; i += 1) {
      const temp = insuredList[i];
      const codeTableItem = {};
      codeTableItem.id = temp.InsuredName;
      codeTableItem.text = temp.InsuredName;
      insureds.push(codeTableItem);
    }
    const codeTable = new CodeTable(insureds);
    return codeTable;
  },

  insuredObjectChange(event) {
    const _self = this;
    const { caseForm } = _self.state;
    const objectRelativeEntity = event.getParameter(
      'objectRelativeEntity'
    );
    objectRelativeEntity.InsuredName = event.newValue;
    const objList = caseForm.ObjectRelativeList;
    for (let i = 0; i < objList.length; i += 1) {
      const temp = objList[i];
      if (objectRelativeEntity.ObjectId == temp.ObjectId) {
        objList[i].InsuredName = event.newValue;
        break;
      }
    }
  },

  dealView() {
    const _self = this;
    const view = [];
    if (_self.state.caseForm && _self.state.newPolicyInfo) {
      const { caseForm } = _self.state;
      const objectList = caseForm.caseEntity.ObjectList;
      const objectRelativeList = caseForm.ObjectRelativeList;
      if (objectList) {
        for (let i = 0; i < objectList.length; i += 1) {
          const temp = objectList[i];
          const objectRelativeEntity = {};
          if (objectRelativeList) {
            for (let k = 0; k < objectRelativeList.length; k += 1) {
              const relaTemp = objectRelativeList[k];
              if (relaTemp.ObjectId == temp.ObjectId) {
                objectRelativeEntity.InsuredName =
                  relaTemp.InsuredName;
                break;
              }
            }
          }
          objectRelativeEntity.ObjectId = temp.ObjectId;
          view.push(
            <UIPanel
              panelTitle={i18n.ClaimCommon.Subclaim + temp.SeqNo}
            >
              <UISelect
                id={`withOutPolicy${i}`}
                label={i18n.ClaimCommon.InsuredObject}
                onChange={_self.insuredObjectChange}
                value={objectRelativeEntity.InsuredName || ''}
                codeTable={_self.fillCodeTable()}
                required="true"
                validationGroup="withOutPolicy"
              >
                <UIParam
                  name="objectRelativeEntity"
                  value={objectRelativeEntity}
                />
              </UISelect>
            </UIPanel>
          );
        }
      } else {
        view.push(<span>no sub claim </span>);
      }
    } else {
      view.push(<span />);
    }
    return view;
  },

  render() {
    const _self = this;
    const view = _self.dealView();
    return (
      <UIDialog
        id="withOutPolicyInsured"
        title=""
        width="80%"
        height="600px"
        modal="false"
      >
        {view}
        <UIDialogFooter>
          <UIButton
            value={i18n.ClaimCommon.Continue}
            onClick={_self.retrievePolicy}
            validationGroup="withOutPolicy"
            causeValidation="true"
          />
          <UIButton
            value={i18n.ClaimCommon.Cancel}
            onClick={_self.cancel}
          />
        </UIDialogFooter>
      </UIDialog>
    );
  },

  cancel() {
    UIDialog.hide('withOutPolicyInsured');
  },

  retrievePolicy() {
    const _self = this;
    const { caseForm } = _self.state;
    const caseId = caseForm.caseEntity.CaseId;
    const { newPolicyInfo } = _self.state;
    const withOutPolicyEntity = {};
    withOutPolicyEntity.PolicyEntity = newPolicyInfo;
    withOutPolicyEntity.ObjectRelativeList =
      caseForm.ObjectRelativeList;
    ClaimHandlingAction.submitWithOutPolicy(
      withOutPolicyEntity,
      caseId,
      ReserveAction.initReserve,
      this.cancel
    );
  },
});
export default OpenReserveDialog;
