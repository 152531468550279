import { MessageHelper, Caller, AjaxUtil } from 'RainbowUI';
import {
  ClaimCstUI,
  ClaimMessageForamtUtils,
} from 'ClaimCommonConstant';
import { ClaimHandlingAction } from 'ClaimCommon';
import ClaimCommonExt from 'ClaimCommonExt';
import Url from '../url/Url';
import RegistrationService from '../service/RegistrationService';

export default class RegistrationHandlingAction {
  handleClaimEntity(TempClaimEntity) {
    const ClaimEntity = TempClaimEntity;
    delete ClaimEntity.cityCodeTable;
    delete ClaimEntity.postalCodeTable;
    delete ClaimEntity.streetCodeTable;
    delete ClaimEntity.townCodeTable;
    delete ClaimEntity.countryCodeTable;
    delete ClaimEntity.regions;
    delete ClaimEntity.IsMotor;
    const objects = ClaimEntity.ObjectList;
    if (objects) {
      for (let i = 0; i < objects.length; i += 1) {
        objects[i].RentalCarCompany = objects[i].RentalCarCompanyId;
        delete objects[i].RentalCarCompanyId;
      }
    }

    ClaimEntity.AccidentCity = ClaimEntity.City;
    ClaimEntity.AccidentTown = ClaimEntity.Area;
    ClaimEntity.AccidentStreet = ClaimEntity.StreetName;
    ClaimEntity.PostalCode = ClaimEntity.PostCode;

    delete ClaimEntity.City;
    delete ClaimEntity.Area;
    delete ClaimEntity.StreetName;
    delete ClaimEntity.PostCode;
    /* ClaimEntity.CountryCode = ClaimEntity.AccidentCountryCode;
        caseEntity.City = caseEntity.AccidentCity;
        caseEntity.Area = caseEntity.AccidentTown;
        caseEntity.StreetName = caseEntity.AccidentStreet;
        caseEntity.PostCode = caseEntity.PostalCode;

        delete caseEntity.AccidentCountryCode;
        delete caseEntity.AccidentCity;
        delete caseEntity.AccidentTown;
        delete caseEntity.AccidentStreet;
        delete caseEntity.PostalCode;
*/
    return ClaimEntity;
  }

  saveLastReviewClaim(caseId) {
    RegistrationService.saveLastReviewClaim(caseId).then(
      () => { },
      () => {
        MessageHelper.error(i18n.ClaimCommon.Fail);
      }
    );
  }

  saveClaim(tempCaseForm, showMessage, isSubtmit) {
    const caseForm = tempCaseForm;
    if (env.IS_MOCK) return;
    let result = caseForm;

    if (caseForm.ClaimEntity.CareProviderId) {
      caseForm.ClaimEntity.CareProvider =
        caseForm.ClaimEntity.CareProviderId;
      delete caseForm.ClaimEntity.CareProviderId;
    }

    const caseFormBak = jQuery.extend(true, {}, caseForm);

    delete caseFormBak.ClaimEntity.CodeTableInsured;
    delete caseFormBak.ClaimEntity.CodeTableCauseOfLoss;
    delete caseFormBak.ClaimEntity.CodeTableSubclaimType;
    delete caseFormBak.ClaimEntity.CodeTableDamageType;
    delete caseFormBak.ClaimEntity.CodeTableClaimant;
    delete caseFormBak.ClaimEntity.CodeTableClaimOwner;
    const tempChangePositionInfo =
      caseFormBak.ClaimEntity.changePositionInfo;
    delete caseFormBak.ClaimEntity.changePositionInfo;
    const tempCityCodeTable = caseFormBak.ClaimEntity.cityCodeTable;
    const tempPostalCodeTable =
      caseFormBak.ClaimEntity.postalCodeTable;
    const tempStreetCodeTable =
      caseFormBak.ClaimEntity.streetCodeTable;
    const tempTownCodeTable = caseFormBak.ClaimEntity.townCodeTable;
    const tempCountryCodeTable =
      caseFormBak.ClaimEntity.countryCodeTable;
    const tempRegions = caseFormBak.ClaimEntity.regions;
    const tempIsMotor = caseFormBak.ClaimEntity.IsMotor;
    caseFormBak.ClaimEntity = this.handleClaimEntity(
      caseFormBak.ClaimEntity
    );

    Caller.doService(
      RegistrationService.saveRegistration(caseFormBak, isSubtmit)
    ).then(data => {
      if (data.Status == ClaimCstUI.REST_RESTPONSE_STATUS_OK) {
        if (showMessage) {
          MessageHelper.success(
            ClaimMessageForamtUtils.formatI18NMsg(
              i18n.ClaimProduct.SaveSuccess,
              i18n.ClaimCommon.Registration
            )
          );
        }
        result = data.Model;
        result.ClaimEntity.cityCodeTable = tempCityCodeTable;
        result.ClaimEntity.postalCodeTable = tempPostalCodeTable;
        result.ClaimEntity.streetCodeTable = tempStreetCodeTable;
        result.ClaimEntity.townCodeTable = tempTownCodeTable;
        result.ClaimEntity.countryCodeTable = tempCountryCodeTable;
        result.ClaimEntity.regions = tempRegions;
        result.ClaimEntity.IsMotor = tempIsMotor;
        result.ClaimEntity.changePositionInfo = tempChangePositionInfo;
        ClaimHandlingAction.handlingCodeTable(result.ClaimEntity);
      } else {
        MessageHelper.error(data.Messages[0].Message);
        return false;
      }
    });
    return result;
  }

  submitClaim(tempCaseForm) {
    const caseForm = tempCaseForm;
    if (!caseForm.ClaimEntity.PolicyNo) {
      MessageHelper.warning(i18n.ClaimRegistration.WarnMsg.Warn_0001);
      return;
    }
    if (caseForm.ClaimEntity.CareProviderId) {
      caseForm.ClaimEntity.CareProvider =
        caseForm.ClaimEntity.CareProviderId;
      delete caseForm.ClaimEntity.CareProviderId;
    }

    let result = null;
    if (this.checkSubclaim(caseForm)) {
      const caseFormBak = jQuery.extend(true, {}, caseForm);
      delete caseFormBak.ClaimEntity.CodeTableInsured;
      delete caseFormBak.ClaimEntity.CodeTableCauseOfLoss;
      delete caseFormBak.ClaimEntity.CodeTableSubclaimType;
      delete caseFormBak.ClaimEntity.CodeTableDamageType;
      delete caseFormBak.ClaimEntity.CodeTableClaimant;
      delete caseFormBak.ClaimEntity.CodeTableClaimOwner;
      caseFormBak.ClaimEntity = this.handleClaimEntity(
        caseFormBak.ClaimEntity
      );
      Caller.doService(
        RegistrationService.saveRegistration(caseFormBak, true)
      ).then(data => {
        if (data.Status == ClaimCstUI.REST_RESTPONSE_STATUS_OK) {
          result = true;
          MessageHelper.success(
            ClaimMessageForamtUtils.formatI18NMsg(
              i18n.ClaimCommon.SubmitSuccess,
              i18n.ClaimCommon.Registration
            )
          );
        }
      });
    }
    return result;
  }

  checkSubclaim(caseForm) {
    let subclaimNoCoverage = '';
    //let subclaimInitLossIsEmpty = '';
    let noCoverageSelected = true;
    let rejectSubclaimNum = 0;
    let subClaimInfoRequiredfieldIsEmpty = '';
    const subClaimInfoRadioField = [
      'IsSalvage',
      'IsSubrogation',
      'DamageSeverity',
      'LitigationFlag',
      'TotalLossFlag',
    ];
    const filterFields = { Age: true };
    $(caseForm.ClaimEntity.ObjectList).each((index, data) => {
      if (data.StatusCode === 'REJECT') {
        noCoverageSelected = false;
        rejectSubclaimNum += 1;
      } else {
        noCoverageSelected = true;
        for (const field in data) {
          if (data[field] === null || data[field] === undefined) {
            if (!filterFields[field]) {
              subClaimInfoRequiredfieldIsEmpty += `${data.SeqNo} `;
              break;
            }
          }
        }
        for (let i = 0; i < subClaimInfoRadioField.length; i += 1) {
          if (
            !Object.prototype.hasOwnProperty.call(
              data,
              subClaimInfoRadioField[i]
            )
          ) {
            subClaimInfoRequiredfieldIsEmpty += `${data.SeqNo} `;
            break;
          }
        }
        $(data.PolicyCoverageList).each((index1, data1) => {
          if (data1.Selected == '1') {
            noCoverageSelected = false;
            return false;
            // if (
            //   !data1.InitLossIndemnity ||
            //   _.isEmpty(`${data1.InitLossIndemnity}`)
            // ) {
            //   subclaimInitLossIsEmpty = `${subclaimInitLossIsEmpty +
            //     data.SeqNo} `;
            //   return false;
            // }
          }
        });
      }

      if (noCoverageSelected) {
        subclaimNoCoverage = `${subclaimNoCoverage + data.SeqNo} `;
      }
    });

    if (subclaimNoCoverage !== '') {
      MessageHelper.error(
        i18n.ClaimCommon.NoCoverageSelectedMessage +
        subclaimNoCoverage
      );
      return false;
    }
    // if (subclaimInitLossIsEmpty !== '') {
    //   MessageHelper.error(
    //     i18n.ClaimCommon.SubclaimInitLossIsEmpty +
    //     subclaimInitLossIsEmpty
    //   );
    //   return false;
    // }
    if (rejectSubclaimNum == caseForm.ClaimEntity.ObjectList.length) {
      MessageHelper.error(i18n.ClaimCommon.AllSubclaimAreRejected);
      return false;
    }
    if (subClaimInfoRequiredfieldIsEmpty !== '') {
      MessageHelper.error(
        i18n.ClaimCommon.SubclaimInfoRequiredFieldIsMissing +
        subClaimInfoRequiredfieldIsEmpty
      );
      return false;
    }

    return true;
  }

  checkSubClaimType(caseForm) {
    let checkResult = true;
    let msg = '';
    const checkSubclaims = [];
    const msgSubClaimType = [];
    const caseFormBak = jQuery.extend(true, {}, caseForm);
    delete caseFormBak.ClaimEntity.CodeTableInsured;
    delete caseFormBak.ClaimEntity.CodeTableCauseOfLoss;
    delete caseFormBak.ClaimEntity.CodeTableSubclaimType;
    delete caseFormBak.ClaimEntity.CodeTableDamageType;
    delete caseFormBak.ClaimEntity.CodeTableClaimant;
    delete caseFormBak.ClaimEntity.CodeTableClaimOwner;
    caseFormBak.ClaimEntity = this.handleClaimEntity(
      caseFormBak.ClaimEntity
    );
    RegistrationService.checkSubClaimType(caseFormBak).then(data => {
      if (data.Model) {
        data.Model.map(flag => {
          const ifTrue = flag.split(';')[0];
          const subclaimtype = flag.split(';')[2];
          if (ifTrue === 'true') {
            if (
              checkSubclaims.indexOf(`${ifTrue}${subclaimtype}`) ===
              -1
            ) {
              checkSubclaims.push(`${ifTrue}${subclaimtype}`);
            } else if (msgSubClaimType.indexOf(subclaimtype) == -1) {
              msgSubClaimType.push(subclaimtype);
            }
          }
          return flag;
        });
        if (msgSubClaimType.length > 0) {
          msgSubClaimType.forEach(data => {
            msg += `${data},`;
          });
          MessageHelper.error(
            i18n.ClaimCommon.CheckSubClaimTypeMsg + msg
          );
          checkResult = false;
        }
      }
    });
    return checkResult;
  }

  getActiveCode() {
    let list = [];
    RegistrationService.getActiveCode({}).then(
      data => {
        list = data.Model;
      },
      () => {
        MessageHelper.error(i18n.ClaimCommon.Fail);
      }
    );
    return list;
  }

  getExchangeRate(obj) {
    let EXRate;
    AjaxUtil.doGet(
      `${Url.EXChangeRate + obj.dstCode}/${obj.srcCode}`,
      {},
      {
        async: false,
        done(data) {
          EXRate = data.Model;
        },
        fail() {
          MessageHelper.error(i18n.ClaimCommon.Fail);
        },
      }
    );
    return EXRate;
  }

  getlocalExchangeRate(currencyCode) {
    const url = Url.localExchangeRate + currencyCode;
    let rate;
    Caller.call(url, [], { method: 'GET', async: false }).then(
      data => {
        if (data.Status == ClaimCstUI.REST_RESTPONSE_STATUS_OK) {
          rate = data.Model;
        }
      },
      () => {
        rate = '';
      }
    );
    return rate;
  }

  loadCommonParty() {
    const result = {};

    return result;
  }

  chooseClaimant(event, ...args) {
    let index;
    if (event) {
      index = event.getParameter('claimantIndex');
    } else {
      index = args[1];
    }
    const ChooseClaimantAction = ClaimCommonExt.getChooseClaimantAction();

    ChooseClaimantAction.onShow(index);
  }

  chooseNewClaimant(event, index) {
    const ChoosePayeeAction = ClaimCommonExt.getChoosePayeeAction();
    index = `a${index}`;
    ChoosePayeeAction.onShow(index);
  }
}
