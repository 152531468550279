import { UIText, UIButton, UISmartPanelGrid, UIBox } from 'RainbowUI';
import BindToMixin from 'react-binding';

const PartyContactInfo = React.createClass({
  mixins: [BindToMixin],

  getInitialState() {
    return { contactVo: {}, showAddButton: true };
  },

  addContact() {
    this.setState({
      contactVo: {
        '@type': 'PartyContact-PartyContact',
        isAdd: true,
      },
      showAddButton: false,
    });
  },

  editContact(contactVo) {
    this.setState({ contactVo, showAddButton: false });
  },

  saveContact() {
    this.props.parentComponent.saveContact(this.state.contactVo);
    this.cancel();
  },

  cancel() {
    this.setState({ showAddButton: true });
  },

  render() {
    const { showAddButton } = this.state;
    const { index } = this.props;
    if (showAddButton) {
      return <div />;
    }

    return (
      <div>
        <UISmartPanelGrid>
          <UIText
            label={i18n.ClaimParty.ContactNumber}
            id={`contactVoHandPhoneId_${index}`}
            valueLink={this.bindToState('contactVo', 'HandPhone')}
            validationGroup="PartyContactInfo"
            required="true"
          />
          <UIText
            label={i18n.ClaimParty.ContactEmail}
            id={`contactVoEmailId_${index}`}
            valueLink={this.bindToState('contactVo', 'Email')}
            validationGroup="PartyContactInfo"
            required="true"
          />
        </UISmartPanelGrid>

        <UIBox direction="left">
          <UIButton
            value={i18n.ClaimParty.SaveButton}
            onClick={this.saveContact}
            validationGroup="PartyContactInfo"
            causeValidation="true"
          />
          <UIButton
            value={i18n.ClaimParty.CancelButton}
            onClick={this.cancel}
          />
        </UIBox>
        <hr />
      </div>
    );
  },
});
export default PartyContactInfo;
