import {
  UIBox,
  UIPanel,
  UIText,
  UISmartPanelGrid,
  UIButton,
} from 'RainbowUI';
import BindToMixin from 'react-binding';
import CatastropheAction from '../../action/CatastropheAction';

const Catastrophe = React.createClass({
  mixins: [BindToMixin],
  getInitialState() {
    return { accidentVo: {} };
  },
  onSearch() {
    this.state.accidentVo.isInit = false;
    CatastropheAction.initCondition(this.state.accidentVo);
  },
  render() {
    return (
      <UIPanel
        id="accidentVo.serach"
        panelTitle={i18n.ClaimCommon.AccidentDefineCode}
      >
        <UISmartPanelGrid id="accidentVo.UISmartPanelGrid">
          <UIText
            valueLink={this.bindToState('accidentVo', 'AccidentCode')}
            label={i18n.ClaimCommon.AccidentCode}
          />
          <UIText
            valueLink={this.bindToState('accidentVo', 'AccidentName')}
            label={i18n.ClaimCommon.AccidentName}
          />
          <UIBox direction="left">
            <UIButton
              value={i18n.ClaimCommon.Search}
              onClick={this.onSearch}
            />
          </UIBox>
        </UISmartPanelGrid>
      </UIPanel>
    );
  },
});
export default Catastrophe;
