import { UICell, UIButton, UIBox, MessageHelper } from 'RainbowUI';
import React from 'react';
import PropTypes from 'prop-types';
import BindToMixin from 'react-binding';
import { ClaimTaskTransferDialog } from 'ClaimCommon';
import { ClaimCstUI } from 'ClaimCommonConstant';
import ClaimSettlement from 'ClaimSettlement';
import ClaimCommonExt from 'ClaimCommonExt';
import {
  PartySearchPanelDialog,
  PtyIntegrationAddress,
  PtyIntegrationAccount,
} from 'EU00PartyCommonUI';
import SettlementStore from '../store/SettlementStore';

const SettlementLoadAction = ClaimSettlement.getSettlementLoadAction();
const SettlementValidationAction = ClaimSettlement.getSettlementValidationAction();
const SettlementInfoLoad = require('./SettlementInfoLoad');

const ClaimBaseInfo = ClaimCommonExt.getClaimBaseInfo();

const SettlementTaskPage = React.createClass({
  mixins: [BindToMixin],
  propTypes: {
    taskInstanceId: PropTypes.string,
    caseId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  },

  getInitialState() {
    return { SettlementData: {}, CheckCoInsurance: {} };
  },
  componentWillMount() {
    SettlementStore.listen(this.onStoreStateChange);
    if (!_.isEmpty(this.props.taskInstanceId)) {
      SettlementLoadAction.checkCoInsurance(
        this.props.caseId,
        this.props.policyNo,
        this.props.taskInstanceId
      );
      SettlementLoadAction.loadSettlementTask(
        this.props.taskInstanceId
      );
    }
  },

  componentWillUnmount() {
    SettlementStore.unlisten(this.onStoreStateChange);
  },

  onStoreStateChange(storeState) {
    if (
      !_.isEmpty(storeState.SettlementData) ||
      storeState.CheckCoInsurance
    ) {
      this.setState({
        SettlementData: storeState.SettlementData,
        CheckCoInsurance: storeState.CheckCoInsurance,
      });
    }
  },
  onClickSave() {
    const data = SettlementLoadAction.saveSettlement(
      this.state.SettlementData
    );
    this.setState({
      SettlementData: data,
    });
  },
  onClickSubmit() {
    if (!this.props.policyNo) {
      MessageHelper.error(
        '',
        i18n.ClaimSettlement.WithOutPolicyError
      );
      return;
    }
    if (this.validate()) {
      return;
    }
    this.submitSettlement();
  },
  validate() {
    const result = SettlementValidationAction.validateSettlement(
      this.state.SettlementData
    );
    if (
      result &&
      result.Status == ClaimCstUI.REST_RESTPONSE_STATUS_WARN
    ) {
      this.refs.confirmDialog_ref.showDialog(
        i18n.ClaimSettlement.Settlement,
        result.Messages
      );
      return true;
    }
    if (
      result &&
      result.Status == ClaimCstUI.REST_RESTPONSE_STATUS_BLOCK
    ) {
      MessageHelper.error('', result.Messages[0].Message);
      return true;
    }
    return false;
  },

  submitSettlement() {
    const settlementInfo = this.state.SettlementData.SettlementInfo;
    let checkBankInfo = true;
    _.each(settlementInfo.SettlementPayee, payee => {
      if (
        i18n.ClaimSettlement.PAY_MOD_DIRECT_CREDIT == payee.PayMode
      ) {
        if (!payee.IBANNo) {
          checkBankInfo = false;
        }
      }
    });
    if (checkBankInfo) {
      const result = SettlementLoadAction.submitSettlement(
        this.state.SettlementData
      );
      if (result === 'OK') {
        if (settlementInfo.ClaimType === 'LOS,SAL,SUB') {
          MessageHelper.success(
            i18n.ClaimSettlement.RefundSubmitSuccess
          );
        } else if (settlementInfo.ClaimType === 'LOS') {
          MessageHelper.success(
            i18n.ClaimSettlement.SettlementSubmitSuccess
          );
        } else {
          MessageHelper.success(
            i18n.ClaimSettlement.RecoverySubmitSuccess
          );
        }
        this.refs.ClaimTaskTransferDialog.showDialog(
          { CaseId: this.props.caseId },
          'settlementSuccess'
        );
      } else {
        this.forceUpdate();
      }
    } else {
      MessageHelper.warning('', i18n.ClaimSettlement.FillBankInfo);
    }
  },

  getPartySearchPanelDialog() {
    return this.refs.PartySearchPanelDialog_ref;
  },

  getPtyIntegrationAddressDialog() {
    return this.refs.PtyIntegrationAddress_ref;
  },

  getPtyIntegrationAccountDialog() {
    return this.refs.PtyIntegrationAccount_ref;
  },

  updateSettlementInfo(event) {
    this.state.SettlementData.SettlementInfo = event;
  },

  updateSettlementPayee(event) {
    this.state.SettlementData.SettlementInfo.SettlementPayee = event;
  },

  updateOtherInfo() {},

  updateCoInsurancePayment(event) {
    this.state.SettlementData.SettlementInfo.CoInsurancePayment = event;
  },
  render() {
    const SettlementHistory = ClaimSettlement.getSettlementHistory();
    const {
      taskInstanceId,
      claimEntity,
      caseId,
      policyNo,
      readOnly,
      index,
    } = this.props;
    const { SettlementData, CheckCoInsurance } = this.state;
    if (_.isEmpty(taskInstanceId)) {
      return (
        <div>
          <ClaimBaseInfo claimEntity={claimEntity} />
          <SettlementHistory
            caseId={caseId}
            taskCode={ClaimCstUI.CLAIM_TASK_CODE.Settlement}
            canInit={false}
            policyNo={policyNo}
          />
        </div>
      );
    }
    const CommonConfirmDialog = ClaimCommonExt.getCommonConfirmDialog();
    const settlementData = SettlementData;

    let settlementInfo;
    let otherInfo;
    if (settlementData) {
      settlementInfo = settlementData.SettlementInfo;
      otherInfo = settlementData.OtherInfo;
    }
    const partySearchPanelDialog = (
      <PartySearchPanelDialog
        ref="PartySearchPanelDialog_ref"
        parentComponent={this}
        endId="_settlement"
      />
    );
    return (
      <div>
        {partySearchPanelDialog}
        <PtyIntegrationAddress
          ref="PtyIntegrationAddress_ref"
          parentComponent={this}
          endId="_settlement"
        />
        <PtyIntegrationAccount
          ref="PtyIntegrationAccount_ref"
          parentComponent={this}
          endId="_settlement"
        />
        <ClaimBaseInfo claimEntity={claimEntity} />
        <div>
          <SettlementInfoLoad
            parentComponent={this}
            settlementInfo={settlementInfo}
            otherInfo={otherInfo}
            readOnly={false}
            isApprove={false}
            caseId={caseId}
            CoInsurance={CheckCoInsurance}
            taskInstanceId={taskInstanceId}
            updateSettlementInfo={this.updateSettlementInfo}
            updateOtherInfo={this.updateOtherInfo}
            updateCoInsurancePayment={this.updateCoInsurancePayment}
            updateSettlementPayee={this.updateSettlementPayee}
          />
        </div>
        <div>
          <ClaimTaskTransferDialog
            ref="ClaimTaskTransferDialog"
            componentId="set_task"
          />
          <UIBox direction="left">
            <UIButton
              value={i18n.ClaimCommon.Save}
              style={{ marginLeft: '10px' }}
              onClick={this.onClickSave}
              validationGroup="submit_validate"
              causeValidation="true"
            />
            <UIButton
              value={i18n.ClaimCommon.Submit}
              style={{ marginLeft: '10px' }}
              onClick={this.onClickSubmit}
              validationGroup="submit_validate"
              causeValidation="true"
            />
          </UIBox>
        </div>
        <div>
          <UICell style={{ textAlign: 'left' }} />

          <UICell
            style={{ textAlign: 'left', paddingTop: '30px' }}
            width="13"
          >
            <SettlementHistory
              caseId={caseId}
              taskCode={ClaimCstUI.CLAIM_TASK_CODE.Settlement}
              canInit
              policyNo={policyNo}
            />
          </UICell>
        </div>

        <CommonConfirmDialog
          ref="confirmDialog_ref"
          parentComponent={this}
          objectId={`_settlementTaskPage${readOnly}_${index}`}
          onConfirm={this.submitSettlement}
        />
      </div>
    );
  },
});
export default SettlementTaskPage;
