import {
  UIDataTable,
  UIColumn,
  UILink,
  UIParam,
  UICell,
  UIButton,
  UISelect,
  UIText,
} from 'RainbowUI';
import AddOrEditPartyDialog from './AddOrEditPartyDialog';
import PartyMaintenanceStore from '../store/PartyMaintenanceStore';
import PartyMaintenanceAction from '../action/PartyMaintenanceAction';
import CodeTableAction from '../../common/action/CodeTableAction';
import CodeTableCstUI from '../../common/constant/CodeTableCstUI';
import ClaimCstUI from '../../common/constant/ClaimCstUI';

const PartyMaintenanceResult = React.createClass({
  getDefaultProps() {
    return { index: '_000' };
  },

  getInitialState() {
    return {
      clickTabTimes: 0,
      partyName: null,
      codeTablePartyRole: CodeTableAction.loadDataList(
        CodeTableCstUI.ClaimPartyRole,
        null
      ),
      codeTableIndIDType: CodeTableAction.loadDataList(
        CodeTableCstUI.ClaimPartyCertiType,
        null
      ),
      codeTableOrgIDType: CodeTableAction.loadDataList(
        CodeTableCstUI.ClaimPartyOrgType,
        null
      ),
    };
  },

  componentWillMount() {
    PartyMaintenanceStore.listen(this.onStoreStateChange);
  },
  componentWillUnmount() {
    PartyMaintenanceStore.unlisten(this.onStoreStateChange);
  },

  onStoreStateChange(storeState) {
    this.setState({ clickTabTimes: storeState.clickTabTimes });
  },
  changeState(partyName) {
    this.setState({ partyName });
  },
  getPartyList(pageIndex, pageSize) {
    return PartyMaintenanceAction.getPartyList(
      this.props.caseId,
      this.state.partyName,
      pageIndex,
      pageSize
    );
  },

  editParty(event) {
    this.refs.AddOrEditPartyDialog_ref.showDialog(event.getParameter('data'));
    // this.refs.AddOrEditPartyDialog_ref.showEditDialog(
    //   event.getParameter('data'),
    //   null
    // );
    this.setState({ title: i18n.ClaimParty.EditClaimParty });
  },

  searchParty() {
    this.refs.AddOrEditPartyDialog_ref.showDialog(
      null,
      'QueryCommonParty'
    );
    this.setState({ title: i18n.ClaimParty.SelectfromCommonParty });
  },

  clickSearch() {
    const partyName = $('#partyMaintanceSearch_Id').val();
    const { caseId } = this.props;
  },
  render() {
    const self = this;
    const { partyAuth, caseId, claimEntity } = this.props;
    const { title } = this.state;
    return (
      <div>
        <UIDataTable
          indexable="false"
          id="priorClaimsResult"
          provider={this.getPartyList}
          pageable="true"
          isHeading="true"
        >
          <UIColumn
            headerTitle={i18n.ClaimParty.PartyRole}
            render={data => {
              return (
                <UISelect
                  id="PartyMaintenance_PartyRole"
                  codeTable={self.state.codeTablePartyRole}
                  value={data.PartyRole}
                  io="out"
                />
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.ClaimParty.PartyName}
            value="PartyName"
          />
          {}

          <UIColumn
            headerTitle={i18n.ClaimParty.IDNumberForSearch}
            render={data => {
              if (
                data.CommonParty.PartyType ==
                ClaimCstUI.PARTY_TYPE_INDIVIDUAL
              ) {
                return (
                  <UIText
                    id="PartyMaintenance_IndPartyIdNumber"
                    value={data.CommonParty.IdNumber}
                    io="out"
                  />
                );
              }
              return (
                <UIText
                  id="PartyMaintenance_PartyIdNumber"
                  value={data.CommonParty.OrganizationIdNumber}
                  io="out"
                />
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.ClaimCommon.Action}
            render={data => {
              return (
                <UILink icon="fa fa-pencil" onClick={self.editParty}>
                  <UIParam name="data" value={data} />
                </UILink>
              );
            }}
          />
        </UIDataTable>

        <UICell>{this.getButton()}</UICell>
        <AddOrEditPartyDialog
          partyAuth={`${partyAuth}`}
          ref="AddOrEditPartyDialog_ref"
          caseId={caseId}
          index="PartyMaintenance"
          fromWhere="partyMaintance"
          showContactRadio="false"
          title={title}
          claimEntity={claimEntity}
        />
      </div>
    );
  },

  getButton() {
    if (this.props.partyAuth) {
      return (
        <UIButton
          value={i18n.ClaimParty.OpenSearchLink}
          onClick={this.searchParty}
        />
      );
    }
  },
});
export default PartyMaintenanceResult;
