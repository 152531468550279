export default {
  '@type': 'ClaimPolicy-ClaimPolicy',
  OrganId: '1',
  PolicyNo: 'C20160000000008',
  ProductCode: 'GPC_001',
  ProductTypeCode: 'GPC2.0',
  EffDate: '2015-07-01',
  ExpDate: '2016-06-30',
  Premium: 5000.0,
  PremiumIsPaid: 'Y',
  CurrencyCode: 'USD',
  CoinOurShare: 0.3,
  CoinFlag: 'Y',
  CoinLeaderFlag: 'Y',
  InsuranceType: 'D',
  FixedExchangeRate: '0.1560',
  PolicyCoinList: [
    {
      '@type': 'ClaimPolicyCoin-ClaimPolicyCoin',
      Leader: 'Y',
      ShareRate: '0.5',
      RelatedPolicyNo: 'Q20160000000002',
      CoinCompanyName: 'CoinsuranceCompany_SH',
      CoinCompanyId: '54087619',
    },
    {
      '@type': 'ClaimPolicyCoin-ClaimPolicyCoin',
      Leader: 'N',
      ShareRate: '0.3',
      RelatedPolicyNo: 'Q20160000000001',
      CoinCompanyName: 'CoinsuranceCompany_WX',
      CoinCompanyId: '54087623',
    },
    {
      '@type': 'ClaimPolicyCoin-ClaimPolicyCoin',
      Leader: 'N',
      ShareRate: '0.2',
      RelatedPolicyNo: 'Q20160000000001',
      CoinCompanyName: 'CoinsuranceCompany_BJ',
      CoinCompanyId: '54087627',
    },
  ],
  InsuredList: [
    {
      '@type': 'ClaimPolicyInsured-ClaimPolicyInsured',
      InsuredName: 'G324599 Audi A8',
      SumInsured: 600000.0,
      InsuredCategory: 1,
      InsuredUid: 0,
      CoverageList: [
        {
          '@type': 'ClaimPolicyCoverage-ClaimPolicyCoverage',
          CoverageName: 'Own Damage',
          DeductibleAmount: 500.0,
          DeductibleRate: 0.0,
          SumInsured: 100000,
          PolicySumInsured: 500000.0,
          PolicyDeductibleRate: 0.0,
          PolicyDeductibleAmount: 0.0,
          CoverageCode: 'OD',
          ProductCode: 'GPC_001',
          PolicyLimit: {
            '@type': 'ClaimPolicyLimit-ClaimPolicyLimit',
            PolicyLimitValue: [
              {
                '@type':
                  'ClaimPolicyLimitValue-ClaimPolicyLimitValue',
                LimitValue: 600000,
                ModeType: 'AOA',
                Term: '600000 Per Accident',
                ValueType: 'AMOUNT',
              },
            ],
          },
        },
        {
          '@type': 'ClaimPolicyCoverage-ClaimPolicyCoverage',
          CoverageName: 'Third Party Liability',
          DeductibleAmount: 0,
          DeductibleRate: 0.0,
          SumInsured: 500000,
          PolicySumInsured: 500000.0,
          PolicyDeductibleRate: 0.0,
          PolicyDeductibleAmount: 0.0,
          CoverageCode: 'TPL',
          ProductCode: 'GPC_001',
        },
        {
          '@type': 'ClaimPolicyCoverage-ClaimPolicyCoverage',
          CoverageName: 'Property Damage',
          DeductibleAmount: 0,
          DeductibleRate: 0.0,
          SumInsured: 20000,
          PolicySumInsured: 20000.0,
          PolicyDeductibleRate: 0.0,
          PolicyDeductibleAmount: 0.0,
          CoverageCode: 'PD',
          UpperCoverageCode: 'TPL',
          UpperCoverageName: 'Third Party Liability',
          ProductCode: 'GPC_001',
          PolicyLimit: {
            '@type': 'ClaimPolicyLimit-ClaimPolicyLimit',
            PolicyLimitValue: [
              {
                '@type':
                  'ClaimPolicyLimitValue-ClaimPolicyLimitValue',
                LimitValue: 2000,
                ModeType: 'AOA',
                Term: '2000 Per Accident',
                ValueType: 'AMOUNT',
              },
            ],
          },
        },
        {
          '@type': 'ClaimPolicyCoverage-ClaimPolicyCoverage',
          CoverageName: 'Death and Disability',
          DeductibleAmount: 0,
          DeductibleRate: 0.0,
          SumInsured: 110000,
          PolicySumInsured: 110000.0,
          PolicyDeductibleRate: 0.0,
          PolicyDeductibleAmount: 0.0,
          CoverageCode: 'DD',
          UpperCoverageCode: 'TPL',
          UpperCoverageName: 'Third Party Liability',
          ProductCode: 'GPC_001',
          PolicyLimit: {
            '@type': 'ClaimPolicyLimit-ClaimPolicyLimit',
            PolicyLimitValue: [
              {
                '@type':
                  'ClaimPolicyLimitValue-ClaimPolicyLimitValue',
                LimitValue: 110000,
                ModeType: 'AOA',
                Term: '110000 Per Accident',
                ValueType: 'AMOUNT',
              },
            ],
          },
        },
        {
          '@type': 'ClaimPolicyCoverage-ClaimPolicyCoverage',
          CoverageName: 'Medical Expense',
          DeductibleAmount: 0,
          DeductibleRate: 0.0,
          SumInsured: 10000,
          PolicySumInsured: 10000.0,
          PolicyDeductibleRate: 0.0,
          PolicyDeductibleAmount: 0.0,
          CoverageCode: 'MEXP',
          UpperCoverageCode: 'TPL',
          UpperCoverageName: 'Third Party Liability',
          ProductCode: 'GPC_001',
          PolicyLimit: {
            '@type': 'ClaimPolicyLimit-ClaimPolicyLimit',
            PolicyLimitValue: [
              {
                '@type':
                  'ClaimPolicyLimitValue-ClaimPolicyLimitValue',
                LimitValue: 10000,
                ModeType: 'AOA',
                Term: '10000 Per Accident',
                ValueType: 'AMOUNT',
              },
            ],
          },
        },

        {
          '@type': 'ClaimPolicyCoverage-ClaimPolicyCoverage',
          CoverageName: 'Personal Accident',
          DeductibleAmount: 0,
          DeductibleRate: 0.0,
          SumInsured: 5000,
          PolicySumInsured: 500000.0,
          PolicyDeductibleRate: 0.0,
          PolicyDeductibleAmount: 0.0,
          CoverageCode: 'PERS',
          ProductCode: 'GPC_001',
        },
        {
          '@type': 'ClaimPolicyCoverage-ClaimPolicyCoverage',
          CoverageName: 'Death and Disablement',
          DeductibleAmount: 0,
          DeductibleRate: 0.0,
          SumInsured: 100000,
          PolicySumInsured: 100000.0,
          PolicyDeductibleRate: 0.0,
          PolicyDeductibleAmount: 0.0,
          CoverageCode: 'DEDI',
          UpperCoverageCode: 'PERS',
          UpperCoverageName: 'Personal Accident',
          ProductCode: 'GPC_001',
          PolicyLimit: {
            '@type': 'ClaimPolicyLimit-ClaimPolicyLimit',
            PolicyLimitValue: [
              {
                '@type':
                  'ClaimPolicyLimitValue-ClaimPolicyLimitValue',
                LimitValue: 100000,
                ModeType: 'AOA',
                Term: '100000 Per Person',
                ValueType: 'AMOUNT',
              },
            ],
          },
        },
        {
          '@type': 'ClaimPolicyCoverage-ClaimPolicyCoverage',
          CoverageName: 'Medical Expense',
          DeductibleAmount: 0,
          DeductibleRate: 0.0,
          SumInsured: 5000,
          PolicySumInsured: 5000.0,
          PolicyDeductibleRate: 0.0,
          PolicyDeductibleAmount: 0.0,
          CoverageCode: 'MEDI',
          UpperCoverageCode: 'PERS',
          UpperCoverageName: 'Personal Accident',
          ProductCode: 'GPC_001',
          PolicyLimit: {
            '@type': 'ClaimPolicyLimit-ClaimPolicyLimit',
            PolicyLimitValue: [
              {
                '@type':
                  'ClaimPolicyLimitValue-ClaimPolicyLimitValue',
                LimitValue: 5000,
                ModeType: 'AOA',
                Term: '5000 Per Person',
                ValueType: 'AMOUNT',
              },
            ],
          },
        },
        {
          '@type': 'ClaimPolicyCoverage-ClaimPolicyCoverage',
          CoverageName: 'Hospitalization allowance',
          DeductibleAmount: 5,
          DeductibleRate: 0.0,
          SumInsured: 5000,
          PolicySumInsured: 500000.0,
          PolicyDeductibleRate: 0.0,
          PolicyDeductibleAmount: 5,
          CoverageCode: 'HOSP',
          UpperCoverageCode: 'PERS',
          UpperCoverageName: 'Personal Accident',
          ProductCode: 'GPC_001',
          PolicyLimit: {
            '@type': 'ClaimPolicyLimit-ClaimPolicyLimit',
            PolicyLimitValue: [
              {
                '@type':
                  'ClaimPolicyLimitValue-ClaimPolicyLimitValue',
                IsAllowance: 1,
                LimitValue: 30,
                ModeType: 'AOA',
                Term: '200 Per Day Per Person, Max 30 Days',
                UnitAmount: 200,
                UnitType: 'DAY',
                UnitValue: 1,
                ValueType: 'UNIT',
              },
            ],
          },
          PolicyDeductible: {
            '@type': 'ClaimPolicyDeductible-ClaimPolicyDeductible',
            PolicyDeductibleValue: [
              {
                '@type':
                  'ClaimPolicyDeductibleValue-ClaimPolicyDeductibleValue',
                DeductibleValue: 5,
                IsAbsolute: 1,
                ModeType: 'AOA',
                Term: '5 Days Deductible',
                UnitType: 'DAY',
                ValueType: 'UNIT',
              },
            ],
          },
        },
        {
          '@type': 'ClaimPolicyCoverage-ClaimPolicyCoverage',
          CoverageName: 'Windscreen',
          DeductibleAmount: 200,
          DeductibleRate: 0.0,
          SumInsured: 1000,
          PolicySumInsured: 1000.0,
          PolicyDeductibleRate: 0.0,
          PolicyDeductibleAmount: 200,
          CoverageCode: 'WIND',
          ProductCode: 'GPC_001',
          PolicyLimit: {
            '@type': 'ClaimPolicyLimit-ClaimPolicyLimit',
            PolicyLimitValue: [
              {
                '@type':
                  'ClaimPolicyLimitValue-ClaimPolicyLimitValue',
                LimitValue: 1000,
                ModeType: 'AOA',
                Term: '1000 Per Accident',
                ValueType: 'AMOUNT',
              },
            ],
          },
          PolicyDeductible: {
            '@type': 'ClaimPolicyDeductible-ClaimPolicyDeductible',
            PolicyDeductibleValue: [
              {
                '@type':
                  'ClaimPolicyDeductibleValue-ClaimPolicyDeductibleValue',
                DeductibleValue: 200,
                IsAbsolute: 1,
                ModeType: 'AOA',
                Term: '200 Deductible Per Accident',
                ValueType: 'AMOUNT',
              },
            ],
          },
        },
        {
          '@type': 'ClaimPolicyCoverage-ClaimPolicyCoverage',
          CoverageName: 'Towing Expense',
          DeductibleAmount: 0,
          DeductibleRate: 0.0,
          SumInsured: 5000,
          PolicySumInsured: 5000.0,
          PolicyDeductibleRate: 0.0,
          PolicyDeductibleAmount: 0.0,
          CoverageCode: 'TOWE',
          ProductCode: 'GPC_001',
          PolicyLimit: {
            '@type': 'ClaimPolicyLimit-ClaimPolicyLimit',
            PolicyLimitValue: [
              {
                '@type':
                  'ClaimPolicyLimitValue-ClaimPolicyLimitValue',
                LimitValue: 5000,
                ModeType: 'AOP',
                Term: '5000 Per Year',
                ValueType: 'AMOUNT',
              },
            ],
          },
        },
      ],
    },
  ],
  PartyList: [
    {
      '@type': 'ClaimPolicyParty-ClaimPolicyParty',
      RoleType: '07',
      PartyName: 'Alfonso Wang',
      ContactFirstName: 'Alfonso',
      ContactLastName: 'Wang',
      ContactName: 'Alfonso Wang',
      ContactTelephone: '18917596776',
      IdType: '6',
      IdNumber: 'MockPolicy3209223454563443274',
      ContactAddress: 'shaghai',
      ContactType: '1',
      CommonPartyId: 40097365,
    },
    {
      '@type': 'ClaimPolicyParty-ClaimPolicyParty',
      RoleType: '08',
      PartyName: 'Mickle Zhang',
      ContactFirstName: 'Mickle',
      ContactLastName: 'Zhang',
      ContactName: 'Mickle Zhang',
      ContactTelephone: '130193293534376',
      IdType: '7',
      IdNumber: 'MockPolicy3209223454563443276',
      ContactAddress: 'yancheng',
      ContactType: '1',
      CommonPartyId: 38813405,
    },
    {
      '@type': 'ClaimPolicyParty-ClaimPolicyParty',
      RoleType: '09',
      PartyName: 'Alfonso Wang',
      ContactFirstName: 'Alfonso',
      ContactLastName: 'Wang',
      ContactName: 'Alfonso Wang',
      ContactTelephone: '18917596776',
      IdType: '6',
      IdNumber: 'MockPolicy3209223454563443274',
      ContactAddress: 'shaghai',
      ContactType: '1',
      CommonPartyId: 40097365,
    },
    {
      '@type': 'ClaimPolicyParty-ClaimPolicyParty',
      RoleType: '09',
      PartyName: 'TestInsured Zhang',
      ContactFirstName: 'TestInsured',
      ContactLastName: 'Zhang',
      ContactName: 'TestInsured Zhang',
      ContactTelephone: '130193293534376',
      IdType: '1',
      IdNumber: 'MockPolicy3209223454563443299',
      ContactAddress: 'yanchengTestInsured',
      ContactType: '2',
      CommonPartyId: 40097366,
    },
    {
      '@type': 'ClaimPolicyParty-ClaimPolicyParty',

      IdType: '1',
      ContactName: 'CoinsuranceCompany_SH',
      ContactType: '2',
      ContactAddress: 'No.270 songhu RD',
      IdNumber: '61408888',
      CommonPartyId: 54087619,
      PartyName: 'CoinsuranceCompany_SH',
      RoleType: '11',
    },
    {
      '@type': 'ClaimPolicyParty-ClaimPolicyParty',

      IdType: '1',
      ContactName: 'CoinsuranceCompany_WX',
      ContactType: '2',
      ContactAddress: 'WuXi',
      IdNumber: '0510-61408888',
      CommonPartyId: 54087623,
      PartyName: 'CoinsuranceCompany_WX',
      RoleType: '11',
    },
    {
      '@type': 'ClaimPolicyParty-ClaimPolicyParty',

      IdType: '1',
      ContactName: 'CoinsuranceCompany_BJ',
      ContactType: '2',
      ContactAddress: 'BeiJing',
      IdNumber: '010-61408888',
      CommonPartyId: 54087627,
      PartyName: 'CoinsuranceCompany_BJ',
      RoleType: '11',
    },
  ],
};
