import {
  UIText,
  UIParam,
  UILink,
  UISelect,
  UIDateTimePicker,
  UIDataTable,
  UIDialog,
  UISmartPanelGrid,
  UIBox,
  UIButton,
  UIColumn,
  UIPanel,
  DataContext,
  MessageHelper,
  CodeTable,
} from 'RainbowUI';
import {
  CommonCodeTable,
  DefinedCodeTable,
} from '../codetable/CodeTable';
import DateUtil from '../../common/util/DateUtil';
import RoleDetail from './RoleDetail';
import PartyAction from '../actions/PartyAction';
import PartyConst from '../const/PartyConst';
import ViewBankRole from './readonly/partyRoleDialog/ViewBankRole';
import ViewClaimantIndRole from './readonly/partyRoleDialog/ViewClaimantIndRole';
import ViewClaimantOrgRole from './readonly/partyRoleDialog/ViewClaimantOrgRole';
import ViewCollectorIndRole from './readonly/partyRoleDialog/ViewCollectorIndRole';
import ViewCollectorOrgRole from './readonly/partyRoleDialog/ViewCollectorOrgRole';
import ViewClaimantInvestigatorRole from './readonly/partyRoleDialog/ViewClaimantInvestigatorRole';
import ViewClaimantInvestigatorOrgRole from './readonly/partyRoleDialog/ViewClaimantInvestigatorOrgRole';
import ViewDriverIndRole from './readonly/partyRoleDialog/ViewDriverIndRole';
import ViewCustomerIndRole from './readonly/partyRoleDialog/ViewCustomerIndRole';
import ViewCustomerOrgRole from './readonly/partyRoleDialog/ViewCustomerOrgRole';
import ViewEmployeeIndRole from './readonly/partyRoleDialog/ViewEmployeeIndRole';
import ViewGarageIndRole from './readonly/partyRoleDialog/ViewGarageIndRole';
import ViewGarageOrgRole from './readonly/partyRoleDialog/ViewGarageOrgRole';
import ViewHealthCareProviderIndRole from './readonly/partyRoleDialog/ViewHealthCareProviderIndRole';
import ViewHealthCareProviderOrgRole from './readonly/partyRoleDialog/ViewHealthCareProviderOrgRole';
import ViewInsuranceRole from './readonly/partyRoleDialog/ViewInsuranceRole';
import ViewIntroducerIndRole from './readonly/partyRoleDialog/ViewIntroducerIndRole';
import ViewIntroducerOrgRole from './readonly/partyRoleDialog/ViewIntroducerOrgRole';
import ViewLawyerRole from './readonly/partyRoleDialog/ViewLawyerRole';
import ViewLegalFirmRole from './readonly/partyRoleDialog/ViewLegalFirmRole';
import ViewPayeeOrPayerIndRole from './readonly/partyRoleDialog/ViewPayeeOrPayerIndRole';
import ViewPayeeOrPayerOrgRole from './readonly/partyRoleDialog/ViewPayeeOrPayerOrgRole';
import ViewProducerIndRole from './readonly/partyRoleDialog/ViewProducerIndRole';
import ViewProducerOrgRole from './readonly/partyRoleDialog/ViewProducerOrgRole';
import ViewReinsuranceRole from './readonly/partyRoleDialog/ViewReinsuranceRole';
import ViewRiskAssessorIndRole from './readonly/partyRoleDialog/ViewRiskAssessorIndRole';
import ViewRiskAssessorOrgRole from './readonly/partyRoleDialog/ViewRiskAssessorOrgRole';
import ViewCarRentalOrgRole from './readonly/partyRoleDialog/ViewCarRentalOrgRole';
import ViewRecoveryIndRole from './readonly/partyRoleDialog/ViewRecoveryIndRole';
import ViewRecoveryOrgRole from './readonly/partyRoleDialog/ViewRecoveryOrgRole';

const _ = require('underscore');

const RoleInfo = React.createClass({
  getInitialState() {
    const party = DataContext.get('Party');
    let roleList = party.PartyRoleList;
    if (!roleList) {
      roleList = [];
    }
    return {
      Party: party,
      RoleList: roleList,
      condition: { condition: null },
    };
  },
  render() {
    const _self = this;
    const partyId = _self.state.Party.PartyId;
    let partyRoleCodeTable;
    if (_self.props.party.IsOrgParty === 'Y') {
      partyRoleCodeTable = CommonCodeTable.getOrgPartyRoleNoProducer();
      if (_self.props.party.PartyType == PartyConst.PARTY_TYPE_VEN) {
        const filterPartyVenRole = DefinedCodeTable.getPartyVenFilterCodeTable();
        if (filterPartyVenRole) {
          const filterMap = {};
          _.each(filterPartyVenRole.codes, partyRole => {
            filterMap[
              PartyConst.RoleTypeOrgnizationMap[partyRole.id]
            ] = true;
          });
          partyRoleCodeTable = new CodeTable(
            _.filter(partyRoleCodeTable.codes, code => {
              if (!filterMap[code.id]) {
                return code;
              }
            })
          );
        }
      }
    }
    if (_self.props.party.IsOrgParty === 'N') {
      partyRoleCodeTable = CommonCodeTable.getIndPartyRoleNoProducer();
    }
    const { condition } = this.state;
    return (
      <div>
        <UIPanel
          styleClass={_self.props.styleClass}
          panelTitle={i18n.RoleInfo}
        >
          {_self.renderRoleInfoTable()}
        </UIPanel>

        <UIDialog
          id="RoleDialog"
          width="60%"
          title={`Create ${i18n.PartyRole}`}
        >
          <UISmartPanelGrid column="1">
            <UISelect
              id="PartyRoleCate"
              label={i18n.PartyRole}
              enabled="true"
              model={condition}
              property="condition"
              validationGroup="causeRoleDialog"
              required="true"
              codeTable={partyRoleCodeTable}
            />
          </UISmartPanelGrid>
          <UIBox direction="center">
            <UIButton
              value={i18n.Save}
              causeValidation="true"
              validationGroup="causeRoleDialog"
              onClick={_self.onClickSave}
            />
            <UIButton
              value={i18n.Cancel}
              onClick={_self.onClickClose}
            />
          </UIBox>
        </UIDialog>

        <RoleDetail
          ref="roleDetail"
          isEdit="N"
          parentComponent={_self}
          RoleList={_self.state.RoleList}
          submitInfoHandler={_self.submitInfoHandler}
          partyId={partyId}
        />
        <ViewBankRole ref="ViewBankRoleDialog" />
        <ViewClaimantIndRole ref="ViewClaimantIndRole" />
        <ViewClaimantOrgRole ref="ViewClaimantOrgRole" />
        <ViewClaimantInvestigatorRole ref="ViewClaimantInvestigatorRole" />
        <ViewClaimantInvestigatorOrgRole ref="ViewClaimantInvestigatorOrgRole" />
        <ViewCollectorIndRole ref="ViewCollectorIndRole" />
        <ViewCollectorOrgRole ref="ViewCollectorOrgRole" />
        <ViewCustomerIndRole ref="ViewCustomerIndRole" />
        <ViewCustomerOrgRole ref="ViewCustomerOrgRole" />
        <ViewDriverIndRole ref="ViewDriverIndRole" />
        <ViewEmployeeIndRole ref="ViewEmployeeIndRole" />
        <ViewGarageIndRole ref="ViewGarageIndRole" />
        <ViewGarageOrgRole ref="ViewGarageOrgRole" />
        <ViewHealthCareProviderIndRole ref="ViewHealthCareProviderIndRole" />
        <ViewHealthCareProviderOrgRole ref="ViewHealthCareProviderOrgRole" />
        <ViewInsuranceRole ref="ViewInsuranceRole" />
        <ViewIntroducerIndRole ref="ViewIntroducerIndRole" />
        <ViewIntroducerOrgRole ref="ViewIntroducerOrgRole" />
        <ViewLawyerRole ref="ViewLawyerRole" />
        <ViewLegalFirmRole ref="ViewLegalFirmRole" />
        <ViewPayeeOrPayerIndRole ref="ViewPayeeOrPayerIndRole" />
        <ViewPayeeOrPayerOrgRole ref="ViewPayeeOrPayerOrgRole" />
        <ViewProducerIndRole ref="ViewProducerIndRole" />
        <ViewProducerOrgRole ref="ViewProducerOrgRole" />
        <ViewReinsuranceRole ref="ViewReinsuranceRole" />
        <ViewRiskAssessorIndRole ref="ViewRiskAssessorIndRole" />
        <ViewRiskAssessorOrgRole ref="ViewRiskAssessorOrgRole" />
        <ViewCarRentalOrgRole ref="ViewCarRentalOrgRole" />
        <ViewRecoveryIndRole ref="ViewRecoveryIndRole" />
        <ViewRecoveryOrgRole ref="ViewRecoveryOrgRole" />
      </div>
    );
  },
  viewHandler(event) {
    const data = event.getParameter('data');
    const roleType = data.RoleType;
    if (roleType == PartyConst.PARTY_ROLE_CODE_ORG_BANK) {
      this.refs.ViewBankRoleDialog.showDialog(data);
    } else if (roleType == PartyConst.PARTY_ROLE_CODE_IND_CLAIMANT) {
      this.refs.ViewClaimantIndRole.showDialog(data);
    } else if (
      roleType == PartyConst.PARTY_ROLE_CODE_IND_CLAIMANT_INVESTIGATOR
    ) {
      this.refs.ViewClaimantInvestigatorRole.showDialog(data);
    } else if (
      roleType == PartyConst.PARTY_ROLE_CODE_ORG_CLAIMANT_INVESTIGATOR
    ) {
      this.refs.ViewClaimantInvestigatorOrgRole.showDialog(data);
    } else if (roleType == PartyConst.PARTY_ROLE_CODE_ORG_CLAIMANT) {
      this.refs.ViewClaimantOrgRole.showDialog(data);
    } else if (roleType == PartyConst.PARTY_ROLE_CODE_IND_COLLECTOR) {
      this.refs.ViewCollectorIndRole.showDialog(data);
    } else if (roleType == PartyConst.PARTY_ROLE_CODE_ORG_COLLECTOR) {
      this.refs.ViewCollectorOrgRole.showDialog(data);
    } else if (roleType == PartyConst.PARTY_ROLE_CODE_IND_CUSTOMER) {
      this.refs.ViewCustomerIndRole.showDialog(data);
    } else if (roleType == PartyConst.PARTY_ROLE_CODE_ORG_CUSTOMER) {
      this.refs.ViewCustomerOrgRole.showDialog(data);
    } else if (roleType == PartyConst.PARTY_ROLE_CODE_IND_EMPLOYEE) {
      this.refs.ViewEmployeeIndRole.showDialog(data);
    } else if (roleType == PartyConst.PARTY_ROLE_CODE_IND_GARAGE) {
      this.refs.ViewGarageIndRole.showDialog(data);
    } else if (roleType == PartyConst.PARTY_ROLE_CODE_ORG_GARAGE) {
      this.refs.ViewGarageOrgRole.showDialog(data);
    } else if (
      roleType == PartyConst.PARTY_ROLE_CODE_IND_HEALTHCARE
    ) {
      this.refs.ViewHealthCareProviderIndRole.showDialog(data);
    } else if (
      roleType == PartyConst.PARTY_ROLE_CODE_ORG_HEALTHCARE
    ) {
      this.refs.ViewHealthCareProviderOrgRole.showDialog(data);
    } else if (roleType == PartyConst.PARTY_ROLE_CODE_ORG_INSURANCE) {
      this.refs.ViewInsuranceRole.showDialog(data);
    } else if (
      roleType == PartyConst.PARTY_ROLE_CODE_IND_INTRODUCER
    ) {
      this.refs.ViewIntroducerIndRole.showDialog(data);
    } else if (
      roleType == PartyConst.PARTY_ROLE_CODE_ORG_INTRODUCER
    ) {
      this.refs.ViewIntroducerOrgRole.showDialog(data);
    } else if (
      roleType == PartyConst.PARTY_ROLE_CODE_IND_LAWYERLEGALFIRM
    ) {
      this.refs.ViewLawyerRole.showDialog(data);
    } else if (
      roleType == PartyConst.PARTY_ROLE_CODE_ORG_LAWYERLEGALFIRM
    ) {
      this.refs.ViewLegalFirmRole.showDialog(data);
    } else if (
      roleType == PartyConst.PARTY_ROLE_CODE_IND_PAYEEPAYER
    ) {
      this.refs.ViewPayeeOrPayerIndRole.showDialog(data);
    } else if (
      roleType == PartyConst.PARTY_ROLE_CODE_ORG_PAYEEPAYER
    ) {
      this.refs.ViewPayeeOrPayerOrgRole.showDialog(data);
    } else if (roleType == PartyConst.PARTY_ROLE_CODE_IND_PRODUCER) {
      this.refs.ViewProducerIndRole.showDialog(data);
    } else if (roleType == PartyConst.PARTY_ROLE_CODE_ORG_PRODUCER) {
      this.refs.ViewProducerOrgRole.showDialog(data);
    } else if (
      roleType == PartyConst.PARTY_ROLE_CODE_ORG_REINSURANCE
    ) {
      this.refs.ViewReinsuranceRole.showDialog(data);
    } else if (
      roleType == PartyConst.PARTY_ROLE_CODE_IND_RISKASSESSOR
    ) {
      this.refs.ViewRiskAssessorIndRole.showDialog(data);
    } else if (
      roleType == PartyConst.PARTY_ROLE_CODE_ORG_RISKASSESSOR
    ) {
      this.refs.ViewRiskAssessorOrgRole.showDialog(data);
    } else if (roleType == PartyConst.PARTY_ROLE_CODE_IND_DRIVER) {
      this.refs.ViewDriverIndRole.showDialog(data);
    } else if (
      roleType == PartyConst.PARTY_ROLE_CODE_ORG_CAR_RENTAL
    ) {
      this.refs.ViewCarRentalOrgRole.showDialog(data);
    } else if (
      roleType == PartyConst.PARTY_ROLE_CODE_IND_RECOVER_FROM
    ) {
      this.refs.ViewRecoveryIndRole.showDialog(data);
    } else if (
      roleType == PartyConst.PARTY_ROLE_CODE_ORG_RECOVER_FROM
    ) {
      this.refs.ViewRecoveryOrgRole.showDialog(data);
    } else {
      this.refs.CreateCustomerIndPartyDialog.show(data.RoleType);
    }
  },

  checkPartyRoleExisted(condition) {
    let hasThisRoleType = false;
    const existPartyRole = this.state.Party.PartyRoleList;
    _.each(existPartyRole, obj => {
      if (obj.RoleType == condition) {
        hasThisRoleType = true;
      }
    });
    return hasThisRoleType;
  },

  onClickSave() {
    const _self = this;
    const { condition } = this.state.condition;

    if (_self.checkPartyRoleExisted(condition)) {
      MessageHelper.error(i18n.PartyRoleHasAlreadyExisted);
    } else {
      UIDialog.hide('RoleDialog');
      if (condition) {
        switch (condition) {
          case PartyConst.PARTY_ROLE_CODE_IND_LAWYERLEGALFIRM:
            UIDialog.show('createLawyerPartyDialog');
            break;
          case PartyConst.PARTY_ROLE_CODE_ORG_LAWYERLEGALFIRM:
            UIDialog.show('createLegalFirmPartyDialog');
            break;
          case PartyConst.PARTY_ROLE_CODE_ORG_GARAGE:
            UIDialog.show('createGarageOrgPartyDialog');
            break;
          case PartyConst.PARTY_ROLE_CODE_IND_GARAGE:
            UIDialog.show('createGarageIndPartyDialog');
            break;
          case PartyConst.PARTY_ROLE_CODE_ORG_RISKASSESSOR:
            UIDialog.show('createRiskAssessorOrgPartyDialog');
            break;
          case PartyConst.PARTY_ROLE_CODE_IND_RISKASSESSOR:
            UIDialog.show('createRiskAssessorIndPartyDialog');
            break;
          case PartyConst.PARTY_ROLE_CODE_ORG_PRODUCER:
            MessageHelper.error(i18n.ProducerShouldInSalesChannel);
            break;
          case PartyConst.PARTY_ROLE_CODE_IND_PRODUCER:
            MessageHelper.error(i18n.ProducerShouldInSalesChannel);
            break;
          case PartyConst.PARTY_ROLE_CODE_IND_CUSTOMER:
            UIDialog.show('createCustomerIndPartyDialog');
            break;
          case PartyConst.PARTY_ROLE_CODE_ORG_CUSTOMER:
            UIDialog.show('createCustomerOrgPartyDialog');
            break;
          case PartyConst.PARTY_ROLE_CODE_IND_PAYEEPAYER:
            UIDialog.show('createPayeeOrPayerIndPartyDialog');
            break;
          case PartyConst.PARTY_ROLE_CODE_ORG_PAYEEPAYER:
            UIDialog.show('createPayeeOrPayerOrgPartyDialog');
            break;
          case PartyConst.PARTY_ROLE_CODE_IND_COLLECTOR:
            UIDialog.show('createCollectorIndPartyDialog');
            break;
          case PartyConst.PARTY_ROLE_CODE_ORG_COLLECTOR:
            UIDialog.show('createCollectorOrgPartyDialog');
            break;
          case PartyConst.PARTY_ROLE_CODE_IND_EMPLOYEE:
            UIDialog.show('createEmployeeIndPartyDialog');
            break;
          case PartyConst.PARTY_ROLE_CODE_IND_CLAIMANT_INVESTIGATOR:
            UIDialog.show('createClaimantInvestigatorPartyDialog');
            break;
          case PartyConst.PARTY_ROLE_CODE_ORG_CLAIMANT_INVESTIGATOR:
            UIDialog.show('createClaimantInvestigatorOrgPartyDialog');
            break;
          case PartyConst.PARTY_ROLE_CODE_IND_CLAIMANT:
            UIDialog.show('createClaimantIndPartyDialog');
            break;
          case PartyConst.PARTY_ROLE_CODE_ORG_CLAIMANT:
            UIDialog.show('createClaimantOrgPartyDialog');
            break;
          case PartyConst.PARTY_ROLE_CODE_ORG_INSURANCE:
            UIDialog.show('createInsurancePartyDialog');
            break;
          case PartyConst.PARTY_ROLE_CODE_ORG_BANK:
            UIDialog.show('createBankPartyDialog');
            break;
          case PartyConst.PARTY_ROLE_CODE_ORG_REINSURANCE:
            UIDialog.show('createReinsurancePartyDialog');
            break;
          case PartyConst.PARTY_ROLE_CODE_ORG_HEALTHCARE:
            UIDialog.show('createHealthCareProviderOrgPartyDialog');
            break;
          case PartyConst.PARTY_ROLE_CODE_IND_HEALTHCARE:
            UIDialog.show('createHealthCareProviderIndPartyDialog');
            break;
          case PartyConst.PARTY_ROLE_CODE_IND_RECOVER_FROM:
            UIDialog.show('createRecoverFromIndPartyDialog');
            break;
          case PartyConst.PARTY_ROLE_CODE_ORG_RECOVER_FROM:
            UIDialog.show('createRecoverFromOrgPartyDialog');
            break;
          case PartyConst.PARTY_ROLE_CODE_ORG_INTRODUCER:
            UIDialog.show('createIntroducerOrgPartyDialog');
            break;
          case PartyConst.PARTY_ROLE_CODE_IND_INTRODUCER:
            UIDialog.show('createIntroducerIndPartyDialog');
            break;
          case PartyConst.PARTY_ROLE_CODE_IND_FORGOTTEN:
            UIDialog.show('createForgottenPartyDialog');
            break;
          case PartyConst.PARTY_ROLE_CODE_IND_DRIVER:
            UIDialog.show('createDriverIndPartyDialog');
            break;
          case PartyConst.PARTY_ROLE_CODE_ORG_CAR_RENTAL:
            UIDialog.show('createCarRentalOrgPartyDialog');
            break;
          case PartyConst.PARTY_ROLE_CODE_ORG_PRODUCER_SUPPORT_OFFICER:
            UIDialog.show(
              'createProducerSupportOfficerOrgPartyDialog'
            );
            break;
          case PartyConst.PARTY_ROLE_CODE_IND_PRODUCER_SUPPORT_OFFICER:
            UIDialog.show(
              'createProducerSupportOfficerIndPartyDialog'
            );
            break;
          default:
        }
        this.setState({
          condition: { condition: null },
        });
      }
    }
  },
  onClickClose() {
    const partyAction = new PartyAction();
    const { stateParty } = this.state;
    const party = partyAction.loadPartyByPartyId(stateParty.PartyId);
    this.setState({
      Party: party,
    });
    UIDialog.hide('RoleDialog');
    this.setState({
      condition: { condition: null },
    });
  },
  renderRoleInfoTable() {
    const _self = this;
    return (
      <UIDataTable
        id="roleTable"
        value={_self.state.RoleList}
        indexable="false"
        functions={_self.buildTableFunction()}
      >
        <UIColumn selectionMode="single" />
        <UIColumn
          headerTitle={i18n.PartyRole}
          render={data => {
            let roleName;
            if (_self.props.party.IsOrgParty === 'Y') {
              const map = CommonCodeTable.getOrgPartyRole();
              roleName = map.map[data.RoleType].text;
            }
            if (_self.props.party.IsOrgParty === 'N') {
              const map = CommonCodeTable.getIndPartyRole();
              roleName = map.map[data.RoleType].text;
            }
            return (
              <div>
                <UILink onClick={_self.viewHandler} value={roleName}>
                  <UIParam name="data" value={data} />
                </UILink>
              </div>
            );
          }}
        />
        <UIColumn headerTitle={i18n.RoleCode} value="ExternalCode">
          <UIText io="out" />
        </UIColumn>

        <UIColumn headerTitle={i18n.CreateDate} value="CreateDate">
          <UIDateTimePicker io="out" format="MM/DD/YYYY" />
        </UIColumn>

        <UIColumn
          headerTitle={i18n.InactiveDate}
          render={data => {
            if (
              data.Status.toString() != '1' &&
              !(
                PartyConst.PARTY_ROLE_CODE_IND_PRODUCER ==
                data.RoleType
              ) &&
              !(
                PartyConst.PARTY_ROLE_CODE_ORG_PRODUCER ==
                data.RoleType
              )
            )
              return (
                <UIDateTimePicker
                  io="out"
                  format="MM/DD/YYYY"
                  model={data}
                  property="InactiveDate"
                />
              );
            if (
              !(data.Status.toString() == '1') &&
              (PartyConst.PARTY_ROLE_CODE_IND_PRODUCER ==
                data.RoleType ||
                PartyConst.PARTY_ROLE_CODE_ORG_PRODUCER ==
                data.RoleType)
            ) {
              return (
                <UIDateTimePicker
                  io="out"
                  format="MM/DD/YYYY"
                  defaultValue={DateUtil.formatDateTime(
                    new ServerDate()
                  )}
                  model={data}
                  property="InactiveDate"
                />
              );
            }
          }}
        />

        <UIColumn
          headerTitle={i18n.Status}
          render={data => {
            if (
              PartyConst.PARTY_ROLE_CODE_IND_PRODUCER ==
              data.RoleType ||
              PartyConst.PARTY_ROLE_CODE_ORG_PRODUCER == data.RoleType
            ) {
              return (
                <UISelect
                  codeTable={
                    DefinedCodeTable.getPartyRoleProducerStatus
                  }
                  io="out"
                  value={data.Status}
                />
              );
            }
            return (
              <UISelect
                codeTable={DefinedCodeTable.getPartyStatus}
                io="out"
                value={data.Status}
              />
            );
          }}
        />

        <UIColumn headerTitle={i18n.Remarks} value="Remark">
          <UIText io="out" />
        </UIColumn>
      </UIDataTable>
    );
  },
  buildTableFunction() {
    const _self = this;
    return (
      <div>
        <UILink
          icon="fa fa-plus"
          size="lg"
          iconClass="add_iconbtn"
          tooltip={i18n.AddTip}
          onClick={_self.createRoleHandler}
        />

        <UILink
          icon="fa fa-pencil"
          size="lg"
          iconClass="edit_iconbtn"
          tooltip={i18n.EditTip}
          onClick={_self.editRoleHandler}
        />
      </div>
    );
  },
  createRoleHandler() {
    UIDialog.show('RoleDialog');
  },
  editRoleHandler() {
    const selectData = UIDataTable.getSelectedRecord('roleTable');
    if (!selectData.length) {
      MessageHelper.warning(
        i18n.PartyMsg.WarnMsg.Warn_0005,
        i18n.PartyMsg.WarnMsg.Warn_0003,
        MessageHelper.POSITION_TOP_RIGHT
      );
      return;
    }
    this.refs.roleDetail.props.partyRoleSelected = selectData;
    this.refs.roleDetail.props.isEdit = 'Y';
    this.refs.roleDetail.forceUpdate();
    const condition = selectData[0].RoleType;
    if (condition) {
      switch (condition) {
        case PartyConst.PARTY_ROLE_CODE_IND_LAWYERLEGALFIRM:
          UIDialog.show('createLawyerPartyDialog');
          break;
        case PartyConst.PARTY_ROLE_CODE_ORG_LAWYERLEGALFIRM:
          UIDialog.show('createLegalFirmPartyDialog');
          break;
        case PartyConst.PARTY_ROLE_CODE_ORG_GARAGE:
          UIDialog.show('createGarageOrgPartyDialog');
          break;
        case PartyConst.PARTY_ROLE_CODE_IND_GARAGE:
          UIDialog.show('createGarageIndPartyDialog');
          break;
        case PartyConst.PARTY_ROLE_CODE_ORG_RISKASSESSOR:
          UIDialog.show('createRiskAssessorOrgPartyDialog');
          break;
        case PartyConst.PARTY_ROLE_CODE_IND_RISKASSESSOR:
          UIDialog.show('createRiskAssessorIndPartyDialog');
          break;
        case PartyConst.PARTY_ROLE_CODE_ORG_PRODUCER:
          MessageHelper.error(i18n.ProducerShouldInSalesChannel);
          break;
        case PartyConst.PARTY_ROLE_CODE_IND_PRODUCER:
          MessageHelper.error(i18n.ProducerShouldInSalesChannel);
          break;
        case PartyConst.PARTY_ROLE_CODE_IND_CUSTOMER:
          UIDialog.show('createCustomerIndPartyDialog');
          break;
        case PartyConst.PARTY_ROLE_CODE_ORG_CUSTOMER:
          UIDialog.show('createCustomerOrgPartyDialog');
          break;
        case PartyConst.PARTY_ROLE_CODE_IND_PAYEEPAYER:
          UIDialog.show('createPayeeOrPayerIndPartyDialog');
          break;
        case PartyConst.PARTY_ROLE_CODE_ORG_PAYEEPAYER:
          UIDialog.show('createPayeeOrPayerOrgPartyDialog');
          break;
        case PartyConst.PARTY_ROLE_CODE_IND_COLLECTOR:
          UIDialog.show('createCollectorIndPartyDialog');
          break;
        case PartyConst.PARTY_ROLE_CODE_ORG_COLLECTOR:
          UIDialog.show('createCollectorOrgPartyDialog');
          break;
        case PartyConst.PARTY_ROLE_CODE_IND_EMPLOYEE:
          UIDialog.show('createEmployeeIndPartyDialog');
          break;
        case PartyConst.PARTY_ROLE_CODE_IND_CLAIMANT:
          UIDialog.show('createClaimantIndPartyDialog');
          break;
        case PartyConst.PARTY_ROLE_CODE_ORG_CLAIMANT:
          UIDialog.show('createClaimantOrgPartyDialog');
          break;
        case PartyConst.PARTY_ROLE_CODE_IND_CLAIMANT_INVESTIGATOR:
          UIDialog.show('createClaimantInvestigatorPartyDialog');
          break;
        case PartyConst.PARTY_ROLE_CODE_ORG_CLAIMANT_INVESTIGATOR:
          UIDialog.show('createClaimantInvestigatorOrgPartyDialog');
          break;
        case PartyConst.PARTY_ROLE_CODE_ORG_INSURANCE:
          UIDialog.show('createInsurancePartyDialog');
          break;
        case PartyConst.PARTY_ROLE_CODE_ORG_BANK:
          UIDialog.show('createBankPartyDialog');
          break;
        case PartyConst.PARTY_ROLE_CODE_ORG_REINSURANCE:
          UIDialog.show('createReinsurancePartyDialog');
          break;
        case PartyConst.PARTY_ROLE_CODE_ORG_HEALTHCARE:
          UIDialog.show('createHealthCareProviderOrgPartyDialog');
          break;
        case PartyConst.PARTY_ROLE_CODE_IND_HEALTHCARE:
          UIDialog.show('createHealthCareProviderIndPartyDialog');
          break;
        case PartyConst.PARTY_ROLE_CODE_IND_RECOVER_FROM:
          UIDialog.show('createRecoverFromIndPartyDialog');
          break;
        case PartyConst.PARTY_ROLE_CODE_ORG_RECOVER_FROM:
          UIDialog.show('createRecoverFromOrgPartyDialog');
          break;
        case PartyConst.PARTY_ROLE_CODE_ORG_INTRODUCER:
          UIDialog.show('createIntroducerOrgPartyDialog');
          break;
        case PartyConst.PARTY_ROLE_CODE_IND_INTRODUCER:
          UIDialog.show('createIntroducerIndPartyDialog');
          break;
        case PartyConst.PARTY_ROLE_CODE_IND_FORGOTTEN:
          UIDialog.show('createForgottenPartyDialog');
          break;
        case PartyConst.PARTY_ROLE_CODE_IND_DRIVER:
          UIDialog.show('createDriverIndPartyDialog');
          break;
        case PartyConst.PARTY_ROLE_CODE_ORG_CAR_RENTAL:
          UIDialog.show('createCarRentalOrgPartyDialog');
          break;
        case PartyConst.PARTY_ROLE_CODE_ORG_PRODUCER_SUPPORT_OFFICER:
          UIDialog.show('createProducerSupportOfficerOrgPartyDialog');
          break;
        case PartyConst.PARTY_ROLE_CODE_IND_PRODUCER_SUPPORT_OFFICER:
          UIDialog.show('createProducerSupportOfficerIndPartyDialog');
          break;
        default:
      }
    }
  },
  submitInfoHandler(tempParty) {
    const party = tempParty;
    const partyAction = new PartyAction();
    const copyParty = partyAction.loadPartyByPartyId(party.PartyId);
    party.PartyAccountList = copyParty.PartyAccountList;
    party.PartyContactList = copyParty.PartyContactList;
    party.AddressVoList = copyParty.AddressVoList;
    const result = this.props.submitInfoHandler(party);

    this.setState({
      Party: result,
      RoleList: result.PartyRoleList,
    });
  },
});

export default RoleInfo;
