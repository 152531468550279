import {
  DataContext,
  UIPage,
  UIPanel,
  UIParam,
  UICell,
  UIBox,
  UIText,
  UIDataTable,
  UIDialog,
  MessageHelper,
  UIDateTimePicker,
  UISmartPanelGrid,
  UIRadio,
  UIButton,
  UIColumn,
  UILink,
  UISelect,
} from 'RainbowUI';
import BindToMixin from 'react-binding';
import MassiveReassignmentTaskOwnerAction from '../../action/MassiveReassignmentTaskOwnerAction';
import ClaimWorkFlowSharePoolAction from '../../action/desktop/ClaimWorkFlowSharePoolAction';
import ClaimPageHeader from '../ClaimPageHeader';
import ConditionStore from '../../store/PoolConditionStore';

const code = require('../../codeTable/CodeTable');
const CodeTableAction = require('../../action/CodeTableAction');
const CodeTableCstUI = require('../../constant/CodeTableCstUI');

const TaskOwnerSearch = React.createClass({
  mixins: [BindToMixin],

  getInitialState() {
    return { condition: {}, isInital: false, textEnable: true };
  },
  clickSearch() {
    this.setState({ isInital: false });
    MassiveReassignmentTaskOwnerAction.searchTask(
      this.state.condition
    );
  },
  Clear() {
    this.setState({ condition: {} });
  },
  onRadioChange() {
    if (this.state.condition.NoTaskOwner == '1') {
      this.state.condition.TaskAssignee = '';
      this.setState({ condition: this.state.condition });
      this.setState({ textEnable: false });
    } else {
      this.setState({ textEnable: true });
    }
  },
  render() {
    return (
      <div>

        <UIText
          label={i18n.ClaimCommon.MassiveReassignment}
          io="out"
        />
        <UIPanel
          id="searchCriteria"
          panelTitle={i18n.ClaimCommon.MassiveReassignmentTaskowner}
        >
          <UISmartPanelGrid
            id="idUISmartPanelGrid"
            layout="horizontal"
            column="3"
          >
            <UIText
              id="claimNumber"
              label={i18n.ClaimCommon.ClaimNumber}
              valueLink={this.bindToState('condition', 'ClaimNumber')}
            />
            {/* <UISelect id="claimOwner_" label={i18n.ClaimCommon.ClaimOwner} autoComplete="true" codeTable={CodeTableAction.loadDataList(CodeTableCstUI.ClaimUser,null)} valueLink={this.bindToState("condition","ClaimOwner")}/> */}
            <UISelect
              id="taskPrioriry_"
              label={i18n.ClaimCommon.Priority}
              valueLink={this.bindToState(
                'condition',
                'TaskPrioriry'
              )}
              codeTable={CodeTableAction.loadDataList(
                CodeTableCstUI.Prioriry,
                null
              )}
            />

            <UISelect
              id="taskType_"
              label={i18n.ClaimCommon.TaskType}
              valueLink={this.bindToState('condition', 'TaskType')}
              codeTable={CodeTableAction.loadDataList(
                CodeTableCstUI.CLAIMTASK,
                null
              )}
            />
            <UIRadio
              id="noTaskOwner_"
              label={i18n.ClaimCommon.onlyShowNoTaskOwner}
              onChange={this.onRadioChange}
              defaultValue="0"
              codeTable={code.YesOrNo}
              valueLink={this.bindToState('condition', 'NoTaskOwner')}
            />

            <UISelect
              id="taskAssignee_"
              label={i18n.ClaimCommon.TaskOwner}
              autoComplete="true"
              codeTable={CodeTableAction.loadDataList(
                CodeTableCstUI.ClaimUser,
                null
              )}
              valueLink={this.bindToState(
                'condition',
                'TaskAssignee'
              )}
            />

            <UIDateTimePicker
              id="taskDueTime_"
              format="MM/DD/YYYY"
              label={i18n.ClaimCommon.DueDate}
              valueLink={this.bindToState('condition', 'DueDateTo')}
            />
          </UISmartPanelGrid>
          <UIBox direction="left">
            <UIButton
              value={i18n.ClaimCommon.Search}
              onClick={this.clickSearch}
            />

            <UIButton
              value={i18n.ClaimCommon.Reset}
              onClick={this.Clear}
            />
          </UIBox>
        </UIPanel>
      </div>
    );
  },
});
export default TaskOwnerSearch;
