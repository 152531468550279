import { DataContext } from 'RainbowUI';

const ProductTypeListAction = require('./ProductTypeListAction');
const ProductListAction = require('./ProductListAction');
const ProductCoverageListAction = require('./ProductCoverageListAction');
const SettlementTypeAction = require('./SettlementTypeAction');
const CoverageCategoryOfProductLineAction = require('./CoverageCategoryOfProductLineAction');
const LossCauseAction = require('./LossCauseAction');
const SubclaimTypeAction = require('./SubclaimTypeAction');

export default {
  remove() {
    const data = DataContext.get('data');
    if (data.removeType === 'productType')
      ProductTypeListAction.removeProductType();
    else if (data.removeType === 'product')
      ProductListAction.removeProduct();
    else if (data.removeType === 'coverage')
      ProductCoverageListAction.removeCoverage();
    else if (data.removeType === 'settlement')
      SettlementTypeAction.removeSelectSettlementType(data);
    else if (data.removeType === 'coveragecategoryofline')
      CoverageCategoryOfProductLineAction.removeCoverageCategory(
        data
      );
    else if (data.removeType === 'losscause')
      LossCauseAction.removeLossCause(data);
    else if (data.removeType === 'subclaimtype')
      SubclaimTypeAction.removeSubclaimType(data);
  },
};
