import {
  MessageHelper,
  UISmartPanelGrid,
  UIBox,
  UISelect,
  UIParam,
  UIButton,
  UIDialog,
} from 'RainbowUI';
import BindToMixin from 'react-binding';

import QueryCommonPartyOptions from './QueryCommonPartyOptions';
import QueryCommonPartyResult from './QueryCommonPartyResult';
import PartyConst from '../../../../party/js/party/const/PartyConst';

const ClaimPartyAction = require('../action/ClaimPartyAction');
const CodeTableAction = require('../../common/action/CodeTableAction');
const CodeTableCstUI = require('../../common/constant/CodeTableCstUI');

const QueryCommonParty = React.createClass({
  mixins: [BindToMixin],

  getInitialState() {
    return { claimParty: {} };
  },

  addToClaimParty() {
    if (this.props.fromWhere === 'noticeNewClaimant') {
      const commonParty = this.refs.QueryCommonPartyResult_ref.getSelectedRecord();

      const partyDetail = {};
      partyDetail.PartyName = commonParty.PartyName;
      partyDetail.ClmPtyRole = this.state.claimParty.ClmPtyRole;
      partyDetail.CommonPartyId = commonParty.PartyId;

      if (commonParty.IsOrgParty === 'N') {
        partyDetail.ClmPtyType = 1;
        partyDetail.LastName = commonParty.LastName;
        partyDetail.FirstName = commonParty.FirstName;
        partyDetail.IdType = commonParty.IdType;
        partyDetail.IdNumber = commonParty.IdNumber;
      } else {
        partyDetail.ClmPtyType = 2;
        partyDetail.OrganizationIdType = commonParty.IdType;
        partyDetail.OrganizationIdNumber = commonParty.IdNumber;
      }

      partyDetail.partyAuth = 'true';

      this.props.parentComponent.showPageChange(
        'PartyDetail',
        partyDetail
      );
    } else {
      const commonParty = this.refs.QueryCommonPartyResult_ref.getSelectedRecord();

      if (_.isEmpty(commonParty)) {
        MessageHelper.error(i18n.ClaimParty.SelectCommonParty);
        return;
      }
      let { claimParty } = this.state;
      if (this.props.fromWhere === 'claimant') {
        claimParty = { ClmPtyRole: '01' };
      }
      const partyDetail = ClaimPartyAction.addToClaimParty(
        claimParty,
        commonParty,
        this.props.caseId
      );
      partyDetail.partyAuth = 'true';
      if (!partyDetail.ClmPtyType) {
        if (commonParty.IsOrgParty === 'N')
          partyDetail.ClmPtyType = 1;
        else partyDetail.ClmPtyType = 2;
      }
      UIDialog.hide(`AddOrEditPartyDialog_${this.props.index}`);

      this.props.parentComponent.loadNewParty(partyDetail);
    }
  },

  createParty() {
    this.props.parentComponent.showPageChange(
      'CreateParty',
      {
        PartyContactList: [],
        AddressVoList: [],
        PartyAccountList: [],
        partyAuth: 'true',
      },
      i18n.ClaimParty.CreateNewClaimParty
    );
  },

  callback(partyInfo) {
    const { fromWhere } = this.props;
    if (fromWhere !== 'claimant' && fromWhere !== 'payee') {
      let { claimParty } = this.state;
      claimParty.ClmPtyRole =
        PartyConst.PARTY_ROLE_MAP[
        partyInfo.PartyRoleList[0].RoleType
        ];
      this.setState({ claimParty });
    }

    this.refs.QueryCommonPartyResult_ref.queryOptionsChange({
      PartyType: partyInfo.PartyType,
      PartyId: partyInfo.PartyId,
    });
  },

  render() {
    let claimPartyRole = {};
    const { fromWhere, index } = this.props;
    if (fromWhere === 'claimant') {
      const codeList = [];
      claimPartyRole = {};
      codeList[0] = '01';
      claimPartyRole.Code = codeList;
      claimPartyRole.Record_Usage = '05';
      claimPartyRole.flag = false;
      claimPartyRole.PartyRole = '01';
    } else if (fromWhere === 'payee') {
      claimPartyRole = {};
      claimPartyRole.Record_Usage = '05';
      claimPartyRole.flag = true;
    }
    return (
      <div>
        <QueryCommonPartyOptions
          parentComponent={this}
          index={index}
        />
        <QueryCommonPartyResult
          ref="QueryCommonPartyResult_ref"
          index={index}
        />

        <UISmartPanelGrid widthAllocation="5,7">
          <UISelect
            id={`claimPartyRoleId${index}`}
            codeTable={CodeTableAction.loadDataList(
              CodeTableCstUI.ClaimPartyRole
            )}
            label={i18n.ClaimParty.CreateAs}
            required="true"
            validationGroup="addToClmPty"
            valueLink={this.bindToState('claimParty', 'ClmPtyRole')}
            defaultValue={claimPartyRole.PartyRole}
            enabled={claimPartyRole.flag}
          />
        </UISmartPanelGrid>

        <UIBox direction="center">
          <UIButton
            value={i18n.ClaimParty.AddToClaimParty}
            onClick={this.addToClaimParty}
            validationGroup="addToClmPty"
            causeValidation="true"
          >
            <UIParam name="index" value={index} />
          </UIButton>
          {}
        </UIBox>
      </div>
    );
  },
});
export default QueryCommonParty;
