/* eslint-disable import/no-mutable-exports */
/* eslint-disable object-shorthand */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable prefer-const */

import { UICell } from 'RainbowUI';
import ClaimCommonExt from 'ClaimCommonExt';
import PropTypes from 'prop-types';

let ClaimWarning = require('./ClaimWarning');
let TaskTransferDialog = require('../TaskTransferDialog');
let CloseReserveDialog = require('../../../reserve/component/CloseReserveDialog');

let ModificationHistoryDialog = ClaimCommonExt.getModificationHistoryDialog();
let ClaimCstUI = require('../../constant/ClaimCstUI');
let ClaimInfoTabs = require('./ClaimInfoTabs');

let ClaimHandling = React.createClass({
  propTypes: {
    dtClaimData: PropTypes.shape,
    claimInfo: PropTypes.shape,
    subclaimInfo: PropTypes.shape,
    claimLink: PropTypes.arrayOf,
    handlingLink: PropTypes.arrayOf,
    subclaimLink: PropTypes.arrayOf,
    claimTabs: PropTypes.arrayOf,
    accidentTabs: PropTypes.arrayOf,
    subclaimTabs: PropTypes.arrayOf,
    objectAction: PropTypes.func,
    claimData: PropTypes.shape,
    showReserve: PropTypes.string,
    taskInstanceInfo: PropTypes.shape,
  },

  getDefaultProps() {
    return { showReserve: 'true' };
  },

  taskTransfer(event) {
    this.refs.ClaimTaskTransferDialog.showDialog({
      CaseId: event.getParameter('caseId'),
      TaskTransfer: true,
    });
  },


  clickModifyHistory(event) {
    let data = {
      caseId: this.props.claimData.CaseId,
      objectId: event.getParameter('objectId'),
      auditEventType: event.getParameter('auditEventType'),
    };
    this.refs.ModificationHistoryDialog.showDialog(data);
  },

  getLogo() {
    return (
      <img
        src={config.logo}
        style={{
          display: 'block',
          position: 'relative',
          maxHeight: '100%',
          top: '50%',
          transform: 'translate(0 , -50%)',
          width: '120px',
        }}
      />
    );
  },

  getInitialState() {
    let { warningMsg } = this.props;
    return {
      caseEntity: this.props.data,
      warningMsg: warningMsg,
    };
  },

  render() {
    let {
      claimData,
      data: claimEntity,
      taskInstanceInfo,
      dtClaimData,
      showReserve,
      actionButton,
      claimInfo,
      parentComponent,
      accidentTabs,
    } = this.props;
    const { warningMsg } = this.state;
    let self = this;
    return (
      <div>
        <UICell width="12">
          <ClaimWarning warningMsg={warningMsg} />
        </UICell>

        <ClaimInfoTabs
          claimEntity={claimEntity}
          taskInstanceInfo={taskInstanceInfo}
          dtClaimData={dtClaimData}
          claimData={claimData}
          showReserve={showReserve}
          actionButton={actionButton}
          claimInfo={claimInfo}
          parentComponent={this}
          claimLink={self.props.claimLink}
          clickModifyHistory={this.clickModifyHistory}
          registrationHandling={parentComponent}
          accidentTabs={accidentTabs}
        />
        <TaskTransferDialog
          ref="ClaimTaskTransferDialog"
          componentId="claimHandling"
        />
        <CloseReserveDialog
          ref="CloseReserveDialog_ref"
          relateId={claimData.CaseId}
        />
        <ModificationHistoryDialog
          ref="ModificationHistoryDialog"
          domain={ClaimCstUI.AUDIT_EVENT_TYPE_CLAIM}
        />
      </div>
    );
  },

  getComponent() {
    return this;
  },
});
export default ClaimHandling;
