import React from 'react';
import PropTypes from 'prop-types';
import BindToMixin from 'react-binding';

const SalvageCoverageInfo = require('./SalvageCoverageInfo');

const SalvageInsuredInfo = React.createClass({
  mixins: [BindToMixin],

  propTypes: {
    salvageInfo: PropTypes.object.isRequired,
    insured: PropTypes.object.isRequired,
    coverageCode: PropTypes.string.isRequired,
    otherInfo: PropTypes.object.isRequired,
    readOnly: PropTypes.bool,
    isApproveTask: PropTypes.bool,
  },

  render() {
    const {
      insured,
      readOnly,
      isApproveTask,
      salvageInfo,
      otherInfo,
      history,
      componentId,
    } = this.props;

    const coverageList = this.bindArrayTo(insured, 'CoverageList');
    if (_.isEmpty(coverageList)) {
      return <div />;
    }
    const ComponentId = `${componentId}_`;
    const his = history;
    const coverageInfo = coverageList.items.map((coverage, index) => {
      return (
        <div
          id={`div_${insured.value.InsuredId}${readOnly}${isApproveTask}`}
        >
          <SalvageCoverageInfo
            insured={insured}
            history={his}
            coverage={coverage.sourceObject}
            salvageInfo={salvageInfo}
            otherInfo={otherInfo}
            readOnly={readOnly}
            isApproveTask={isApproveTask}
            componentId={ComponentId + index}
          />
        </div>
      );
    });
    return <div>{coverageInfo}</div>;
  },
});
export default SalvageInsuredInfo;
