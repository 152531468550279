import CodeTableAction from '../../common/action/CodeTableAction';

const CodeTableCstUI = require('../../common/constant/CodeTableCstUI');

function reserveTypeConvert(reserveType) {
  const reserveTypeCode = CodeTableAction.loadDataList(
    CodeTableCstUI.ClaimReserveType,
    null
  );
  let cft = reserveType;
  reserveTypeCode.codes.forEach(val => {
    if (reserveType == val.id) {
      cft = val.text;
    }
  });
  return cft;
}

export default function renderReserveDialogTitle(
  subClaimIndex,
  tmpreserveType = '',
  coverageName = '',
  claimtype = ''
) {
  let reserveType = tmpreserveType;
  let subTitile = '';

  reserveType = reserveTypeConvert(reserveType);
  if (typeof subClaimIndex === 'number') {
    const { length } = `${subClaimIndex}`;
    let index = subClaimIndex * 1 + 1;
    const _s = ['00', '0', ''];
    index = _s[length * 1 - 1] + index;
    subTitile = `Subclaim ${index}`;
  }

  if (reserveType) {
    return `${subTitile}-${coverageName}-${reserveType}`;
  }
  if (!coverageName) {
    if (subClaimIndex != null && subClaimIndex !== '') {
      return `${subTitile}-${claimtype}`;
    }
    return `Claim-${claimtype}`;
  }
  return `${subTitile}-${coverageName}-${claimtype}`;
}
