import alt from '../alt';
import NoticeOfLossAction from '../action/NoticeOfLossAction';

class NoticeStore {
  constructor() {
    this.noticeEntity = { '@type': 'ClaimNotice-ClaimNotice' };
    this.bindListeners({
      handleNoticeStore: [
        NoticeOfLossAction.INIT_NOTICE,
        NoticeOfLossAction.SAVE_NOTICE,
      ],
    });
  }

  handleNoticeStore(noticeEntity) {
    this.noticeEntity = noticeEntity;
  }
}
export default alt.createStore(NoticeStore, NoticeStore);
