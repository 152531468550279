import { DataContext } from 'RainbowUI';
import PolicyService from '../service/PolicyService';
import SolrService from '../service/SolrService';

export default class PolicyActionProcess extends React.Component {
  getPolicyList(pageIndex, pageSize, SearchOptions) {
    let dataReal = [];
    let shortData = [];
    let total = {};
    if (
      (pageIndex != 1 && !DataContext.get('onchange')) ||
      (pageIndex == 1 &&
        DataContext.get('isFist') &&
        !DataContext.get('onchange'))
    ) {
      dataReal = DataContext.get('dataReal');
      total = DataContext.get('total');
      if (total < 11) {
        shortData = dataReal;
      } else if (pageIndex + pageSize == total) {
        shortData = dataReal.slice(pageIndex - 1, total);
      } else {
        shortData = dataReal.slice(
          pageIndex - 1,
          pageIndex + pageSize - 1
        );
      }
      return { count: total, result: shortData };
    }
    let result = {};
    let result1 = {};
    let result2 = {};
    let total1 = {};
    let total2 = {};
    const Conditions = !SearchOptions.policySearchOptions
      ? {}
      : SearchOptions.policySearchOptions;
    const FuzzyConditions = !SearchOptions.FuzzyConditions
      ? {}
      : SearchOptions.FuzzyConditions;

    if (Conditions.ProductId) {
      Conditions.ProductId = parseInt(Conditions.ProductId, 10);
    }
    Conditions.FilterStringFlag = 'N';

    const searchCondition = {
      Conditions,
      PageNo: 1,
      PageSize: 9999,
      FuzzyConditions,
      Module: 'Policy',
    };

    const policySearchFlag = this.policySearchFlag();

    let masterPolicyId = null;
    if (policySearchFlag) {
      SolrService.queryEntitySolr(searchCondition).then(data => {
        if (data.Total === 0) {
          result1 = null;
          total1 = 0;
        } else if (
          data.Total === 1 &&
          data.Results[0].SolrDocs[0].PolicyType == 3
        ) {
          masterPolicyId = data.Results[0].SolrDocs[0].PolicyId;
          result1 = data.Results[0].SolrDocs;
          total1 = data.Total;
        } else {
          result1 = data.Results[0].SolrDocs;
          total1 = data.Total;
        }
      });
      const Conditions2 = !SearchOptions.policySearchOptions2
        ? {}
        : SearchOptions.policySearchOptions2;

      const certificateFuzzyCondition = JSON.parse(
        JSON.stringify(FuzzyConditions)
      );
      if (masterPolicyId != null && masterPolicyId !== '') {
        Conditions2.MasterPolicyId = masterPolicyId;
        delete certificateFuzzyCondition.PolicyNo;
      }
      const searchCondition2 = {
        Conditions: Conditions2,
        PageNo: 1,
        PageSize: 9999,
        FuzzyConditions: certificateFuzzyCondition,
        Module: 'Certificate',
      };
      SolrService.queryEntitySolr(searchCondition2).then(data => {
        if (data.Total === 0) {
          result2 = null;
          total2 = 0;
        } else {
          result2 = data.Results[0].SolrDocs;
          total2 = data.Total;
        }
      });

      if (result1 && result2) {
        result = result1.concat(result2);
      } else if (!result1) {
        result = result2;
      } else if (!result2) {
        result = result1;
      }
      DataContext.put('dataReal', result);
      DataContext.put('total', total1 + total2);
      DataContext.put('isFist', false);
      DataContext.remove('onchange');
      let realResult = {};
      if (total1 + total2 < 11) {
        realResult = result;
      } else {
        realResult = result.slice(0, 10);
      }
      return { count: total1 + total2, result: realResult };
    }
    PolicyService.searchPolicy(JSON.stringify(searchCondition)).then(
      data => {
        if (data.Total === 0) {
          result = null;
          total = 0;
        } else {
          result = data.Model;
          total = data.Model.length;
        }
      }
    );
    return { count: total, result };
  }

  policySearchFlag() {
    let result = true;
    PolicyService.policySearchFlag().then(
      data => {
        result = data;
      },
      err => {
        if (err.status !== 200) {
          /* empty */
        }
      }
    );
    return result;
  }
}
