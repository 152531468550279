import { UITwoText } from 'RainbowUI';

export default class EU00TwoText extends UITwoText {
  renderInput() {
    const inputRef = this.getInputRefProperty();
    if (this.parseBool(this.props.hiddenCode)) {
      return (
        <div>
          <div className="input-group col-sm-12 col-md-12 col-lg-12">
            <input
              id={`${this.componentId}_value`}
              name={`${this.componentId}_value`}
              type="text"
              className="form-control"
              placeholder={this.props.placeHolder}
              disabled
              style={{ width: '120px' }}
              data-auto-test={`${this.getNameForTest()}_value`}
            />

            <span
              className="input-group-addon fixalliconposition"
              style={{ cursor: 'pointer' }}
              onClick={this.onShowSearchCodeTableDialog.bind(this)}
            >
              <span className="fa fa-search" />
            </span>
          </div>

          {this.renderSearchCodeTableDialog()}
        </div>
      );
    }

    return (
      <div>
        <div className="input-group col-sm-12 col-md-12 col-lg-12">
          <div
            className="col-sm-6 col-md-6 col-lg-6"
            style={{ padding: '0px', paddingRight: '15px' }}
          >
            <input
              id={this.componentId}
              name={this.getName()}
              type="text"
              className="form-control"
              placeholder={this.props.placeHolder}
              style={{ width: '120px' }}
              ref={inputRef}
              disabled={this.getDisabled()}
              data-auto-test={this.getNameForTest()}
            />
          </div>

          <div
            className="col-sm-6 col-md-6 col-lg-6"
            style={{ padding: '0px' }}
          >
            <input
              id={`${this.componentId}_value`}
              name={`${this.componentId}_value`}
              type="text"
              className="form-control"
              placeholder={this.props.placeHolder}
              disabled
              style={{ width: '80%' }}
              data-auto-test={`${this.getNameForTest()}_value`}
            />
          </div>

          <span
            className="input-group-addon fixalliconposition"
            style={{ cursor: 'pointer' }}
            onClick={this.onShowSearchCodeTableDialog.bind(this)}
          >
            <span className="fa fa-search" />
          </span>
        </div>

        {this.renderSearchCodeTableDialog()}
      </div>
    );
  }
}
