import { AjaxUtil, CodeTable } from 'RainbowUI';
import Url from '../url/Url';

const SettlementMockData = require('../mock/SettlementMockData');

export default {
  loadSettlementTask(taskInstanceId) {
    const deferred = $.Deferred();
    if (env.IS_MOCK) {
      deferred.resolve(SettlementMockData.SettlementData);
      return deferred.promise();
    }
    AjaxUtil.doGet(Url.SettlementTaskLoad + taskInstanceId, null, {
      async: true,
      done(data) {
        if (data.Status !== 'OK') {
          deferred.reject(data.Messages[0].Message);
        } else {
          deferred.resolve(data.Model);
        }
      },
      fail(jqXHR) {
        deferred.reject({
          status: jqXHR.status,
          responseText: jqXHR.responseText,
        });
      },
    });
    return deferred.promise();
  },
  loadCoInsuranceByCaseIdPolicyNo(caseId, policyNo) {
    let results = [];
    const url = `${Url.SettlementTaskLoad}policy/${caseId}/${policyNo}`;
    AjaxUtil.doGet(url, null, {
      async: false,
      done(data) {
        if (data.Status === 'OK') {
          results = data.Model;
        } else {
          results = data;
        }
      },
    });
    return results;
  },
  checkCoInsurance(caseId, policyNo) {
    const deferred = $.Deferred();
    AjaxUtil.doGet(
      `${Url.SettlementTaskLoad}policy/${caseId}/${policyNo}`,
      null,
      {
        async: false,
        done(data) {
          if (data.Status !== 'OK') {
            deferred.reject(data.Messages[0].Message);
          } else {
            deferred.resolve(data.Model);
          }
        },
        fail(jqXHR) {
          deferred.reject({
            status: jqXHR.status,
            responseText: jqXHR.responseText,
          });
        },
      }
    );
    return deferred.promise();
  },
  getSettlementTypeList() {
    let results = [];
    const url = Url.getSettlementTypeList;
    AjaxUtil.doGet(url, null, {
      async: false,
      done(data) {
        if (data.Status === 'OK') {
          results = data.Model;
        } else {
          results = data;
        }
      },
    });
    return results;
  },
  loadSettlePaymentType(codeTableId) {
    let results = [];
    const url = Url.CodeTableDataList + codeTableId;
    AjaxUtil.doGet(url, null, {
      async: false,
      done(data) {
        if (data) {
          const codeTable = data;
          const item = [];
          for (let i = 0; i < codeTable.length; i += 1) {
            item.push({
              id: codeTable[i].Id,
              text: codeTable[i].Description,
            });
          }
          results = new CodeTable(item);
        } else results = new CodeTable([]);
      },
    });
    return results;
  },
  LoadClaimPartyList(claimPartyRole, caseId) {
    let results = [];
    AjaxUtil.doGet(
      `${Url.LoadClaimPartyList + caseId}/${claimPartyRole}`,
      null,
      {
        async: false,
        done(data) {
          if (data.Status !== 'OK') {
            //                     deferred.reject(data.Messages[0].Message);
          } else if (data.Model !== '') {
            const codeTable = data.Model;
            const item = [];
            for (let i = 0; i < codeTable.length; i += 1) {
              item.push({
                id: codeTable[i].PartyId,
                text: codeTable[i].PartyName,
              });
            }
            results = new CodeTable(item);
          } else results = null;
        },
        fail(jqXHR) {
          deferred.reject({
            status: jqXHR.status,
            responseText: jqXHR.responseText,
          });
        },
      }
    );
    return results;
  },
  loadSettlementReadOnly(settlementId) {
    const deferred = $.Deferred();
    AjaxUtil.doGet(Url.SettlementReadOnlyLoad + settlementId, null, {
      async: true,
      done(data) {
        if (data.Status !== 'OK') {
          deferred.reject(data.Messages[0].Message);
        } else {
          deferred.resolve(data.Model);
        }
      },
      fail(jqXHR) {
        deferred.reject({
          status: jqXHR.status,
          responseText: jqXHR.responseText,
        });
      },
    });
    return deferred.promise();
  },

  saveSettlement(settlementData) {
    const deferred = $.Deferred();
    let result = null;
    const settlementEntity = settlementData.SettlementInfo;
    settlementEntity.SettlementPayee.forEach(payee => {
      payee.SettlementItem.forEach(items => {
        const item = items;
        delete item.dataIndex;
      });
    });
    AjaxUtil.doPost(
      Url.SettlementSave,
      {
        SettlementEntity: settlementEntity,
        TaskInstanceId: settlementData.TaskInstanceId,
      },
      {
        async: false,
        done(data) {
          if (data.Status !== 'OK') {
            deferred.reject(data);
          } else {
            // deferred.resolve(data.Model);
            result = data.Model;
          }
        },
        fail(jqXHR) {
          deferred.reject({
            status: jqXHR.status,
            responseText: jqXHR.responseText,
          });
        },
      }
    );
    return result;
  },

  submitSettlement(settlementData) {
    let result = null;
    const settlementEntity = settlementData.SettlementInfo;
    settlementEntity.SettlementPayee.forEach(payee => {
      payee.SettlementItem.forEach(items => {
        const item = items;
        delete item.dataIndex;
      });
    });
    AjaxUtil.doPost(
      Url.SettlementSubmit,
      {
        SettlementEntity: settlementEntity,
        TaskInstanceId: settlementData.TaskInstanceId,
        ChoosePayment: settlementData.ChoosePayment,
        PolicyNo: settlementData.PolicyNo,
      },
      {
        async: false,
        done(data) {
          if (data.Status === 'OK') {
            result = data.Status;
          } else {
            result = data;
          }
        },
        fail(jqXHR) {
          result = {
            status: jqXHR.status,
            Messages: JSON.parse(jqXHR.responseText).Messages,
          };
        },
      }
    );
    return result;
  },
  hasFraudIndicator(settlementData) {
    let result = null;
    AjaxUtil.doPost(
      Url.SettlementHasFraudIndicator,
      {
        SettlementEntity: settlementData.SettlementInfo,
        TaskInstanceId: settlementData.TaskInstanceId,
      },
      {
        async: false,
        done(data) {
          if (data.Status === 'OK') {
            result = data.Model;
          } else {
            result = data;
          }
        },
        fail(jqXHR) {
          result = {
            status: jqXHR.status,
            responseText: jqXHR.responseText,
          };
        },
      }
    );
    return result;
  },

  loadExchangeRate(caseId, settleCurrency) {
    let result = null;
    AjaxUtil.doGet(
      `${Url.LoadExchangeRate + caseId}/${settleCurrency}`,
      null,
      {
        async: false,
        done(data) {
          if (data.Status === 'OK') {
            result = data.Model;
          }
        },
        fail() {
          result = null;
        },
      }
    );
    return result;
  },

  loadPartyLeader(coinCompanyId) {
    const deferred = $.Deferred();
    AjaxUtil.doGet(Url.loadPartyLeader + coinCompanyId, null, {
      async: false,
      done(data) {
        deferred.resolve(data);
      },
      fail(jqXHR) {
        deferred.reject({
          status: jqXHR.status,
          responseText: jqXHR.responseText,
        });
      },
    });
    return deferred.promise();
  },
};
