import { UICell, UITab, UITabItem, UIPage } from 'RainbowUI';
import CommonMatrix from './CommonMatrix';
import ProductMatrix from './ProductMatrix';

const ClaimMatri = React.createClass({
  render() {
    return (
      <div>
        <UIPage>
          <UICell>
            <UITab id="tab01">
              <UITabItem title={i18n.ClaimCommon.Common}>
                <CommonMatrix />
              </UITabItem>
              <UITabItem title={i18n.ClaimCommon.Product}>
                <ProductMatrix />
              </UITabItem>
            </UITab>
          </UICell>
        </UIPage>
      </div>
    );
  },
});
export default ClaimMatri;
