import {
  UIBox,
  UIDialog,
  UIDataTable,
  UICell,
  DataContext,
  UIButton,
  MessageHelper,
} from 'RainbowUI';

import PartyAccountInfo from '../../../party/views/PartyAccountInfo';
import PartyAction from '../../../party/actions/PartyAction';
import PartyConst from '../../../party/const/PartyConst';

const PtyIntegrationAccount = React.createClass({
  getInitialState() {
    return {
      party: {},
    };
  },

  submitHandler() {
    const partyAccountList = UIDataTable.getSelectedRecord(
      'bankAccountInfo'
    );

    if (!partyAccountList.length) {
      MessageHelper.warning(
        i18n.PartyMsg.WarnMsg.Warn_0002,
        i18n.PartyMsg.WarnMsg.Warn_0003,
        MessageHelper.POSITION_TOP_RIGHT
      );
      return;
    }

    if (partyAccountList && partyAccountList.length > 0) {
      if (
        partyAccountList[0].AccountStatus ==
        PartyConst.PARTY_ROLE_STATUS_ACTIVE
      ) {
        if (this.props.callBackGetRecord) {
          this.props.callBackGetRecord(partyAccountList[0]);
        }
        UIDialog.hide(this.id);
      } else {
        MessageHelper.warning(
          i18n.PartyMsg.WarnMsg.Warn_0004,
          '',
          MessageHelper.POSITION_TOP_RIGHT
        );
      }
    }
  },

  hideDialog() {
    UIDialog.hide(this.id);
  },

  submitInfoHandler(party) {
    const partyAction = new PartyAction();
    const result = partyAction.updateParty(party);
    this.setState({
      party: result,
    });
    return result;
  },

  render() {
    const { party } = this.state;
    const { endId, title } = this.props;
    DataContext.put('Party', party);
    this.id = `ptyIntegrationAccount${endId}`;

    let Title = i18n.PartyAccount;
    if (title) {
      Title = title;
    }

    return (
      <div>
        <UIDialog
          id={this.id}
          title={Title}
          width="80%"
          height="100%"
        >
          <UICell width="14">
            <PartyAccountInfo
              submitInfoHandler={this.submitInfoHandler}
              parentDialog={this}
              party={party}
              readOnly="true"
            />
          </UICell>

          <UIBox direction="center">
            <UIButton value={i18n.OK} onClick={this.submitHandler} />
          </UIBox>
        </UIDialog>
      </div>
    );
  },

  showDialog(partyId, isParty) {
    const partyAction = new PartyAction();
    let party;
    if (isParty) {
      party = isParty;
    } else {
      party = partyAction.loadPartyByPartyId(partyId);
    }
    this.setState({
      party,
    });
    UIDialog.show(this.id);
  },
});
export default PtyIntegrationAccount;
