import { config } from 'RainbowUI';

const moment = require('moment');

const DateUtil = {
  getCurrentDateTime() {
    const covertorValue = moment(new ServerDate()).format(
      config.DEFAULT_DATETIME_SUBMIT_FORMATER
    );
    return covertorValue;
  },
  formatDateTime(date) {
    const covertorValue = moment(date).format(
      config.DEFAULT_DATETIME_SUBMIT_FORMATER
    );
    // console.log(covertorValue);
    return covertorValue;
  },
};

export default DateUtil;
