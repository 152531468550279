import { UIUpdatePanel } from 'RainbowUI';
import PartySearchInfo from '../PartySearchInfo';
import PartyConst from '../../const/PartyConst';

const PartySearchPanel = React.createClass({
  getInitialState() {
    return {
      definedCondition: { PartyType: PartyConst.PARTY_TYPE_IND },
      conditions: {},
      fuzzyConditions: {},
      customerList: [],
      orCondtions: {},
      currentCustomer: {},
      customer: {},
      onCustomerReturn: this.props.customerChose,
    };
  },
  createNewCustomer(partyType) {
    this.props.createClicked(partyType);
  },

  render() {
    const {
      definedCondition,
      conditions,
      fuzzyConditions,
      orCondtions,
      customerList,
      currentCustomer,
    } = this.state;
    const { dialog } = this.props;
    let id;
    if (dialog && dialog.endId)
      id = `updateSearchCriteria${dialog.endId}`;
    return (
      <div>
        <UIUpdatePanel id={id}>
          <PartySearchInfo
            ref="PartySearchInfo_ref"
            create={this.createNewCustomer}
            definedCondition={definedCondition}
            conditions={conditions}
            fuzzyConditions={fuzzyConditions}
            orCondtions={orCondtions}
            customerList={customerList}
            currentCustomer={currentCustomer}
            isQueryPolicy={false}
            faxIndividual
            parentDialog={dialog}
          />
        </UIUpdatePanel>
      </div>
    );
  },
});
export default PartySearchPanel;
