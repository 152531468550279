import { AjaxUtil, MessageHelper } from 'RainbowUI';

const Url = require('../constant/AppraisalUrl');

export default {
  getVehicle(subClaimId) {
    // console.log('[Sync]', subClaimId);
    let returnVal = null;
    const url = Url.syncFIData + subClaimId;
    AjaxUtil.doGet(
      url,
      {},
      {
        async: false,
        done(data) {
          // console.log('[Sync _Done]', data);
          if (data.Status === 'OK') {
            returnVal = data.Model;
            MessageHelper.success(
              i18n.ClaimAppraisal.SuccessMsg.Success_0002
            );
          }
        },
        fail() {
          MessageHelper.error(
            '',
            i18n.ClaimAppraisal.ErrorMsg.Error_0001
          );
          // console.log('failed reason', reason);
        },
      }
    );
    return returnVal;
  },
};
