/**
 * Created by jianjun.gao on 12/17/2016.
 */
import {
  UIDateTimePicker,
  UILink,
  UIBox,
  UIButton,
  UIText,
  UIColumn,
  UIDropZone,
  UITextarea,
  UIParam,
  UISmartPanelGrid,
  UISelect,
  UIPanel,
  UIUpdatePanel,
  UIDataTable,
  UIConfirmDialog,
  UpdateContext,
  MessageHelper,
} from 'RainbowUI';
import ReactMixin from 'react-mixin';
import BindToMixin from 'react-binding';
import CodeTableData from '../../../party/codetable/CodeTable';
import PartyConst from '../../../party/const/PartyConst';
import DocumentAction from '../../../party/actions/DocumentAction';
import CommonAction from '../../action/CommonAction';

export default class CommonDocument extends React.Component {
  constructor(props) {
    super(props);
    const {
      moduleName,
      readOnly: ReadOnly,
      domainObject,
    } = this.props;
    const objectId = domainObject['@pk'];
    const commonAction = new CommonAction();
    const permissions = commonAction.getPermissionByType(102);
    this.state = {
      moduleName,
      objectId,
      readOnly: ReadOnly,
      documentModel: {},
      data: {},
      permissionList: permissions,
    };
    console.info(' constructor father');
    console.info('CommonDocument', this, props);
  }

  removeFileFromDMS(event) {
    this.setState({ clickEvent: event });
    UIConfirmDialog.show('deleteRow');
  }

  previewFileFromDMS(event) {
    const docId = event.getParameter('view').Id;
    const commonAction = new CommonAction();
    commonAction.showPDF(docId);
  }

  downloadFromDMS(event) {
    const commonAction = new CommonAction();
    const data = event.getParameter('download');
    commonAction.ajaxGetBlob(data.Id, null);
  }

  onClickConfirmButtonDelete(event) {
    const {
      moduleName,
      objectId,
      clickEvent,
      documentModel,
    } = this.state;
    const deletResult = DocumentAction.removeFileFromDMS(clickEvent);
    const result = DocumentAction.queryDocumentsList(
      moduleName,
      objectId
    );

    this.setState({ data: result });
    documentModel.DocumentType = null;
    documentModel.DocumentSubType = null;
    documentModel.Remark = '';
    UIConfirmDialog.hide('deleteRow');
  }

  onUploadDuplicateCheck(event) {
    UIConfirmDialog.hide('duplicateCheck');
  }

  render() {
    const _self = this;
    console.log('party render state==>', _self.state);
    return (
      <div>
        <UIPanel panelTitle={i18n.Document}>
          <UIConfirmDialog
            id="deleteRow"
            message={i18n.PtyDelCfmMsg}
            onConfirm={_self.onClickConfirmButtonDelete.bind(_self)}
          />
          <UIConfirmDialog
            id="duplicateCheck"
            title={i18n.duplicateDialog}
            message={i18n.DuplicateCheck}
            onConfirm={_self.onUploadDuplicateCheck.bind(_self)}
            confirmText="EXIT"
            cancelButton="false"
            closeable="false"
          />
          {_self.dmsTable()}
        </UIPanel>
        {_self.renderUpload()}
      </div>
    );
  }

  onCompleteUpload() {
    console.log('complete upload this===>', this);
    const alistLength = UIDropZone.getAcceptedFiles('dropZone')
      .length;
    const { moduleName, objectId, documentModel } = this.state;
    const result = DocumentAction.queryDocumentsList(
      moduleName,
      objectId
    );

    documentModel.DocumentType = null;
    documentModel.DocumentSubType = null;
    documentModel.Remark = '';
    // this.state.documentModel.TransactionType = null;
    documentModel.SubClaimNumber = null;
    this.setState({ data: result });
    UpdateContext.forceUpdate(
      'DocumentSearchResult, documentForm_updatePanel'
    );

    const files = UIDropZone.getDropZone(
      'dropZone'
    ).getAcceptedFiles();
    for (let i = 0; i < files.length; i += 1) {
      UIDropZone.getDropZone('dropZone').removeFile(files[i]);
    }
  }

  Uploadfailed(event) {
    if (event.indexOf('The maximum upload file support') > 0) {
      MessageHelper.warning(i18n.PartyMsg.WarnMsg.Warn_0001);
    }
  }

  dmsTable() {
    const _self = this;
    console.info('dmsTable ', _self.state.data);
    const commonAction = new CommonAction();
    return (
      <UIDataTable
        id="DocumentSearchResult"
        value={_self.state.data}
        displayLength="10"
        indexable="true"
        pageable="true"
        isHeading="false"
      >
        <UIColumn
          headerTitle={i18n.transactionType}
          value="TransactionType"
        >
          <UISelect
            codeTable={CodeTableData.getPermission('Party_Module')}
            io="out"
          />
        </UIColumn>
        <UIColumn
          headerTitle={i18n.documentType}
          value="DocumentType"
        >
          <UISelect
            id="documentType"
            codeTable={CodeTableData.getPermission(
              PartyConst.PARTY_PERMISSION_TRANS_TYPE_CODE
            )}
            io="out"
          />
        </UIColumn>
        <UIColumn
          headerTitle={i18n.documentSubType}
          value="DocumentSubType"
          render={data => {
            console.info('ssssss', data);
            return (
              <UISelect
                id="documentSubType"
                model={data}
                property="DocumentSubType"
                codeTable={CodeTableData.getPermission(
                  commonAction.getPermissionCode(
                    data.DocumentType,
                    _self.state.permissionList
                  )
                )}
                io="out"
              />
            );
          }}
        />
        <UIColumn headerTitle={i18n.DocumentName} value="Name" />
        <UIColumn
          headerTitle={i18n.DocumentSize}
          value="DocumentSize"
        />
        <UIColumn
          headerTitle={i18n.remark}
          render={data => {
            return (
              <UIText model={data} value={data.Remark} io="out" />
            );
          }}
        />
        <UIColumn headerTitle={i18n.UploadedBy} value="UpdateName" />
        <UIColumn
          headerTitle={i18n.PtyTime}
          render={data => {
            return (
              <UIDateTimePicker
                model={data}
                value={data.UpdateTime}
                format="MM/DD/YYYY HH:mm"
                io="out"
              />
            );
          }}
        />
        <UIColumn
          headerTitle={i18n.Action}
          render={data => {
            return (
              <div>
                <UILink
                  value={i18n.FilePreview}
                  onClick={_self.previewFileFromDMS.bind(_self)}
                >
                  <UIParam name="view" value={data} />
                </UILink>
                <span> </span>
                <UILink
                  value={i18n.FileDownload}
                  onClick={_self.downloadFromDMS.bind(_self)}
                >
                  <UIParam name="download" value={data} />
                </UILink>
                <span> </span>
                <UILink
                  value={i18n.FileDelete}
                  disabled={_self.state.readOnly}
                  onClick={_self.removeFileFromDMS.bind(_self)}
                >
                  <UIParam name="item" value={data} />
                </UILink>
              </div>
            );
          }}
        />
      </UIDataTable>
    );
  }

  reload() {
    this.setState(prevState => ({
      documentModel: prevState.documentModel,
    }));
  }

  renderUpload() {
    const { readOnly, documentModel } = this.state;
    if (readOnly) {
      return <div />;
    }
    const _self = this;
    const commonAction = new CommonAction();
    return (
      <UIPanel panelTitle={i18n.UploadFiles}>
        <UIUpdatePanel id="documentForm_updatePanel">
          <UISmartPanelGrid>
            <UISelect
              label={i18n.transactionType}
              enabled={false}
              codeTable={commonAction.getPermissionByTypeUserIdParentName(
                'Party_Module'
              )}
              model={_self.state.documentModel}
              property="TransactionType"
              required="true"
              validationGroup="document"
              causeValidation="true"
            />
            <UISelect
              label={i18n.documentType}
              codeTable={commonAction.getPermissionByTypeUserIdParentName(
                PartyConst.PARTY_PERMISSION_TRANS_TYPE_CODE
              )}
              disabled={_self.state.readOnly}
              model={_self.state.documentModel}
              property="DocumentType"
              onChange={_self.reload.bind(_self)}
              required="true"
              validationGroup="document"
              causeValidation="true"
            />
            <UISelect
              label={i18n.documentSubType}
              codeTable={commonAction.getPermissionByTypeUserIdParentName(
                commonAction.getPermissionCode(
                  _self.state.documentModel.DocumentType,
                  _self.state.permissionList
                )
              )}
              disabled={_self.state.readOnly}
              model={_self.state.documentModel}
              property="DocumentSubType"
              required="true"
              validationGroup="document"
              causeValidation="true"
            />
            <UITextarea
              id="Remark_"
              disabled={_self.state.readOnly}
              label={i18n.remark}
              model={_self.state.documentModel}
              property="Remark"
              colspan="2"
            />
            <br />
            <UIDropZone
              id="dropZone"
              io={_self.state.readOnly}
              autoUpload="false"
              width="680px"
              url="/"
              acceptFile=".doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,.jpg,.png,.tiff,.tif,image/bmp,image/gif,application/pdf"
              onSuccess={this.onCompleteUpload.bind(this)}
              colspan="2"
              maxFileSize="50"
              onError={this.Uploadfailed.bind(this)}
            />
          </UISmartPanelGrid>
        </UIUpdatePanel>

        <UIBox direction="right">
          <UIButton
            value={i18n.Upload}
            onClick={DocumentAction.onUpload.bind(
              this,
              documentModel
            )}
            causeValidation="true"
            validationGroup="document"
            styleClass="primary"
            disabled={_self.state.readOnly}
          />
        </UIBox>
      </UIPanel>
    );
  }
}
ReactMixin.onClass(CommonDocument, BindToMixin);
