import alt from '../alt';
import ProductTreeService from '../service/ProductTreeService';

class ProductTreeAction {
  loadProductCodeTree() {
    ProductTreeService.loadProductCodeTree().then(data => {
      this.dispatch(data);
    });
  }
}

export default alt.createActions(ProductTreeAction);
