export default {
  NewNoticeOfLoss: 'CLM_MENU_010',
  SearchClaim: 'CLM_MENU_020',
  MassiveReassignment: 'CLM_MENU_030',
  MassiveReassignmentTaskOwner: 'CLM_MENU_031',
  MassiveReassignmentClaimOwner: 'CLM_MENU_032',
  More: 'CLM_MENU_040',
  Party: 'CLM_MENU_040',
  UserAccountAndAuthority: 'CLM_MENU_040',
  Configuration: 'CLM_MENU_040',
  Catastrophe: 'CLM_MENU_041',
  ProductConfiguration: 'CLM_MENU_042',
  ClaimAuthority: 'CLM_MENU_043',
  JuniorClaimAdjuster: 'CLM_MENU_043001',
  SeniorClaimAdjuster: 'CLM_MENU_043002',
  ClaimManager: 'CLM_MENU_043003',

  AssignmentApproach: 'CLM_MENU_044',
  ClaimMatrixExportImport: 'CLM_MENU_045',

  AccidentFrom3rd: 'CLM_MENU_050',
  AccidentFromRTA: 'CLM_MENU_051',
  PolicyDataManagement: 'CLM_MENU_060',
};
