import {
  AjaxUtil,
  Exception,
  Caller,
  MessageHelper,
} from 'RainbowUI';

const UrlConfig = require('../../../UrlConfig');

export default class CommonService {
  queryPolicy(conditions) {
    const result = {};
    const setting = { method: 'POST', async: false, block: false };
    // TODO to be integrated
    // Caller.call(UrlConfig.queryOutput, conditions, setting).then(
    //   data => {
    //     if (data.Total > 0) {
    //       result = {
    //         result: data.Results[0].SolrDocs,
    //         count: data.Results[0].GroupTotalNum,
    //       };
    //     } else {
    //       MessageHelper.warning(
    //         i18n.PartyMsg.WarnMsg.Warn_0007,
    //         null,
    //         MessageHelper.POSITION_TOP_RIGHT
    //       );
    //     }
    //   },
    //   err => {
    //     MessageHelper.error(
    //       err.statusText,
    //       null,
    //       MessageHelper.POSITION_TOP_RIGHT
    //     );
    //   }
    // );
    return result;
  }

  loadProductList() {
    const url = UrlConfig.loadProductList_url;
    const setting = { method: 'GET', async: false, block: false };
    let productData = [];
    Caller.call(url, null, setting).then(
      data => {
        if (data) {
          productData = data;
        } else {
          MessageHelper.warning(
            i18n.PartyMsg.WarnMsg.Warn_0007,
            null,
            MessageHelper.POSITION_TOP_RIGHT
          );
        }
      },
      err => {
        MessageHelper.error(
          err.statusText,
          null,
          MessageHelper.POSITION_TOP_RIGHT
        );
      }
    );

    return productData;
  }

  print(printParam) {
    const url = UrlConfig.printList;
    const setting = { method: 'POST', async: false, block: false };
    let result = null;
    Caller.call(url, printParam, setting).then(
      data => {
        if (data) {
          result = data;
          for (const i in data) {
            if (data[i].FailReason) {
              MessageHelper.error(
                data[i].FailReason,
                null,
                MessageHelper.POSITION_TOP_RIGHT
              );
              return result;
            }
          }
          MessageHelper.success(
            i18n.PolicyCommon.SendToPrinterSuccess,
            null,
            MessageHelper.POSITION_TOP_RIGHT
          );
        }
      },
      err => {
        MessageHelper.error(
          err.statusText,
          null,
          MessageHelper.POSITION_TOP_RIGHT
        );
      }
    );

    return result;
  }

  loadPolicyIdByPolicyNo(params) {
    let result;
    const url = `${UrlConfig.loadPolicyIdByPolicyNo}?policyNo=${params}`;

    AjaxUtil.doGet(url, null, {
      async: false,
      done(data) {
        result = data;
      },
      fail(error) {
        MessageHelper.error(
          error,
          null,
          MessageHelper.POSITION_TOP_RIGHT
        );
      },
    });
    return result;
  }

  loadPolicyByPolicyId(policyId) {
    let result;
    const url = `${UrlConfig.loadPolicy}?policyId=${policyId}`;

    AjaxUtil.doGet(url, null, {
      async: false,
      done(data) {
        result = data;
      },
      fail() {
        MessageHelper.error(
          null,
          i18n.PartyMsg.ErrorMsg.Error_0003,
          MessageHelper.POSITION_TOP_RIGHT
        );
      },
    });
    return result;
  }

  download(downloadParam) {
    const url =
      UrlConfig.download +
      Buffer.alloc(JSON.stringify(downloadParam)).toString('base64');

    try {
      this.ajaxGetBlob(url, () => {
        MessageHelper.success(
          i18n.PartyMsg.SuccessMsg.Success_0001,
          null,
          MessageHelper.POSITION_TOP_RIGHT
        );
      });
    } catch (e) {
      MessageHelper.error(
        i18n.PartyMsg.ErrorMsg.Error_0001,
        null,
        MessageHelper.POSITION_TOP_RIGHT
      );
    }
  }

  queryQuotationVersion(policyId) {
    const url = `${UrlConfig.queryVersion}?policyId=${policyId}`;
    const setting = { method: 'GET', async: false, block: false };
    let quotationVersionData;
    Caller.call(url, null, setting).then(
      data => {
        if (data) {
          quotationVersionData = data;
        } else {
          MessageHelper.warning(
            i18n.PartyMsg.WarnMsg.Warn_0007,
            null,
            MessageHelper.POSITION_TOP_RIGHT
          );
        }
      },
      err => {
        MessageHelper.error(
          err.statusText,
          null,
          MessageHelper.POSITION_TOP_RIGHT
        );
      }
    );

    return quotationVersionData;
  }

  loadEndorsementByEndoId(endoId) {
    let result;
    const url = UrlConfig.loadEndorsement + endoId;

    AjaxUtil.doGet(url, null, {
      async: false,
      done(data) {
        result = data;
      },
      fail() {
        MessageHelper.error(
          null,
          i18n.PartyMsg.ErrorMsg.Error_0003,
          MessageHelper.POSITION_TOP_RIGHT
        );
      },
    });
    return result;
  }

  ajaxGet(param, url) {
    const policyDefer = $.Deferred();
    AjaxUtil.doGet(url, param, {
      async: false,
      done(data) {
        policyDefer.resolve(data);
      },
      fail(err) {
        policyDefer.reject(err);
        //Exception.throw(err.status, err.responseText);
        MessageHelper.error(
          err.responseJSON.message,
          null,
          MessageHelper.POSITION_TOP_RIGHT
        );
      },
    });
    return policyDefer.promise();
  }

  ajaxPost(param, url) {
    const policyDefer = $.Deferred();
    AjaxUtil.doPost(url, param, {
      async: false,
      done(data) {
        policyDefer.resolve(data);
      },
      fail(err) {
        policyDefer.reject(err);
        //Exception.throw(err.status, err.responseText);
        MessageHelper.error(
          err.responseJSON.message,
          null,
          MessageHelper.POSITION_TOP_RIGHT
        );
      },
    });
    return policyDefer.promise();
  }

  ajaxGetBlob(suffixUrl, callback) {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', suffixUrl, true);
    xhr.setRequestHeader(
      'Authorization',
      `Bearer ${
      localStorage
        .getItem('Authorization')
        .substr(13)
        .split('&')[0]
      }`
    );
    xhr.responseType = 'blob';
    xhr.onreadystatechange = () => {
      if (
        xhr.readyState == XMLHttpRequest.DONE &&
        xhr.status == 200
      ) {
        let fileName = this.getResponseHeader(
          'Content-Disposition'
        ).split('=')[1];
        if (fileName) {
          const nameStr = fileName.split('"');
          if (nameStr && nameStr.length === 3) {
            fileName = nameStr[1];
          }
        }

        const blob = new Blob([this.response], {
          type: this.getResponseHeader('content-type'),
        });
        if (!!window.ActiveXObject || 'ActiveXObject' in window) {
          window.navigator.msSaveBlob(blob, fileName);
        } else {
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
        }
        if (callback) {
          callback();
        }
      }
    };
    xhr.send(null);
  }

  ajaxPostNoDeferred(param, url) {
    let result;
    AjaxUtil.doPost(url, param, {
      async: false,
      done(data) {
        result = data;
      },
      fail(err) {
        //Exception.throw(err.status, err.responseText);
        MessageHelper.error(
          err.responseJSON.message,
          null,
          MessageHelper.POSITION_TOP_RIGHT
        );
      },
    });

    return result;
  }

  ajaxGetNoDeferred(param, url) {
    let result;

    AjaxUtil.doGet(url, param, {
      async: false,
      done(data) {
        result = data;
      },
      fail(err) {
        //Exception.throw(err.status, err.responseText);
        MessageHelper.error(
          err.responseJSON.message,
          null,
          MessageHelper.POSITION_TOP_RIGHT
        );
      },
    });

    return result;
  }
}
