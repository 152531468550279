import {
  UIDialog,
  MessageHelper,
  UIButton,
  UIBox,
  UIText,
} from 'RainbowUI';
import Binder from 'react-binding';
import JCCPaymentAction from '../../action/JCCPaymentAction';

const PaymentAuthorityDialog = React.createClass({
  getInitialState() {
    return {
      paymentlog: {},
    };
  },
  componentWillMount() {},
  onshowDialog(paymentlog) {
    this.state.paymentlog = paymentlog;
    this.setState();
    UIDialog.show('paymentPosAuthCodeDialog');
  },

  render() {
    return (
      <div>
        <UIDialog
          id="paymentPosAuthCodeDialog"
          title={i18n.POSPaymentAuthorization}
          width="50%"
        >
          <UIText
            label={i18n.AuthorizationCode}
            valueLink={Binder.bindToState(
              this,
              'paymentlog',
              'AuthCode'
            )}
            validationGroup="submitAuth"
            enabled="true"
            disabled="false"
            required="true"
          />
          <UIBox direction="center">
            <UIButton
              enabled="true"
              disabled="false"
              value={i18n.SUBMIT}
              onClick={this.onsubmit.bind(this)}
              validationGroup="submitAuth"
            />
          </UIBox>
        </UIDialog>
      </div>
    );
  },
  onsubmit() {
    if (!this.state.paymentlog || !this.state.paymentlog.AuthCode) {
      // MessageHelper.warning("Please input Authorization Code");
      MessageHelper.warning(i18n.PartyMsg.Warning.Warn_0014);
      return;
    }
    if (this.state.paymentlog.AuthCode.length > 6) {
      MessageHelper.warning(
        'Invalid authorization code, please try again'
      );
      return;
    }
    const paymentAction = new JCCPaymentAction();
    const result = paymentAction.savePaymentLog(
      this.state.paymentlog,
      this.props.withoutCollectionFlag
    );
    if (result) {
      this.props.onSubmit(result);
    }
    UIDialog.hide('paymentPosAuthCodeDialog');
  },
  handleExit() {},
});
export default PaymentAuthorityDialog;
