import {
  UIBox,
  MessageHelper,
  UIColumn,
  UIParam,
  UIText,
  UIButton,
  UIDialog,
  UIDataTable,
} from 'RainbowUI';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import BindToMixin from 'react-binding';
import ReserveStore from '../store/ReserveStore';

const OpenReserveAction = require('../action/OpenReserveAction');
const CodeTableAction = require('../../common/action/CodeTableAction');
const CodeTableCstUI = require('../../common/constant/CodeTableCstUI');

const OpenReserveDialog = React.createClass({
  mixins: [BindToMixin],

  propTypes: {
    objectId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  },

  getInitialState() {
    return { openReserveList: [] };
  },

  componentWillMount() {
    ReserveStore.listen(this.onStoreStateChange);
  },

  componentWillUnmount() {
    ReserveStore.unlisten(this.onStoreStateChange);
  },

  onStoreStateChange(storeState) {
    if (!_.isEmpty(storeState.openReserveList)) {
      this.setState({ openReserveList: storeState.openReserveList });
    }
  },

  componentDidUpdate() {
    const dataList = this.state.openReserveList;
    const checkboxFilter = `input[name='openReserve_updatePanel_${this.props.objectId}_multiple']`;
    let allDisabled = true;
    $(checkboxFilter).each((index, datas) => {
      const data = datas;
      if ('1,3,5,7'.indexOf(dataList[index].permission) == -1) {
        data.disabled = true;
      } else {
        allDisabled = false;
      }
    });
    const checkboxAll = `input[name='openReserve_updatePanel_${this.props.objectId}_multiple_selectall']`;
    $(checkboxAll).each((index, datas) => {
      const data = datas;
      data.disabled = allDisabled;
    });
  },

  selectAll() {
    const checkboxFilter = `input[name='openReserve_updatePanel_${this.props.objectId}_multiple']`;
    $(checkboxFilter).each((index, datas) => {
      const data = datas;
      if (data.disabled) {
        data.checked = false;
      }
    });
  },

  rowSelect() {
    let allChecked = true;
    const checkboxFilter = `input[name='openReserve_updatePanel_${this.props.objectId}_multiple']`;
    $(checkboxFilter).each((index, data) => {
      if (data.checked) {
        return;
      }
      if (data.disabled) {
        return;
      }
      allChecked = false;
    });
    const checkboxAll = `input[name='openReserve_updatePanel_${this.props.objectId}_multiple_selectall']`;
    $(checkboxAll).each((index, datas) => {
      const data = datas;
      data.checked = allChecked;
    });
  },

  render() {
    const _self = this;
    const { openReserveList: data } = this.state;
    const { objectId } = this.props;
    const reserveTypeCode = CodeTableAction.loadDataList(
      CodeTableCstUI.ClaimReserveType,
      null
    );
    return (
      <UIDialog
        id={`openReserveDialog_${objectId}`}
        title={i18n.ClaimReserve.OpenReserve}
        width="80%"
        height="600px"
        modal="false"
      >
        <UIDataTable
          id={`openReserve_updatePanel_${objectId}`}
          value={data}
          var="openReserveList"
          indexable="false"
          infoable="true"
          isHeading="false"
          pageable="false"
          selectionMode="multiple"
          onSelectAll={_self.selectAll}
          onRowSelect={_self.rowSelect}
        >
          <UIColumn
            headerTitle={i18n.ClaimReserve.ReserveType}
            render={data => {
              let cft;
              reserveTypeCode.codes.forEach(val => {
                if (data.reserveType == val.id) {
                  cft = val.text;
                }
              });
              return <UIText value={cft} io="out" />;
            }}
          />

          <UIColumn
            headerTitle={i18n.ClaimReserve.Remark}
            render={data => {
              let disabled_ = false;
              if ('1,3,5,7'.indexOf(data.permission) == -1) {
                disabled_ = true;
              }
              return (
                <UIText
                  id={`remark_${_self.props.objectId}${data.dataIndex}`}
                  onChange={_self.textChange}
                  io="in"
                  disabled={disabled_}
                >
                  <UIParam name="textData" value={data} />
                </UIText>
              );
            }}
          />
        </UIDataTable>
        <UIBox direction="center">
          <UIButton
            value={i18n.ClaimReserve.Submit}
            onClick={_self.save}
          />
          <UIButton
            value={i18n.ClaimReserve.Cancel}
            onClick={_self.cancel}
          />
        </UIBox>
      </UIDialog>
    );
  },

  save() {
    const { objectId } = this.props;
    const total = this.state.openReserveList;
    const selec = UIDataTable.getSelectedRecord(
      `openReserve_updatePanel_${objectId}`
    );
    if (selec.length === 0) {
      MessageHelper.error('', i18n.ClaimReserve.OpenReserveError);
      return;
    }
    const seleced = [];
    for (let i = 0; i < total.length; i += 1) {
      for (let j = 0; j < selec.length; j += 1) {
        if (total[i].reserveType == selec[j].reserveType) {
          selec[j].remark = total[i].remark;
          selec[j].currencyCode =
            total[i].currencyCode || selec[j].currencyCode;
          seleced[seleced.length] = selec[j];
        }
      }
    }

    for (let j = 0; j < seleced.length; j += 1) {
      const temp = seleced[j];
      const seleObject = {
        currencyCode: temp.currencyCode,
        itemId: temp.itemId,
        outstandingAmount: temp.outstandingAmount,
        remark: temp.remark,
        reserveType: temp.reserveType,
        statusCode: temp.statusCode,
      };
      seleced[j] = seleObject;
    }
    const dataVO = { List: seleced, ObjectId: objectId };
    OpenReserveAction.saveOpenReserve(dataVO, objectId);
  },

  cancel() {
    UIDialog.hide(`openReserveDialog_${this.props.objectId}`);
  },

  selectChange(event) {
    const selectData = event.getParameter('selectData');
    this.state.openReserveList[selectData.dataIndex].currencyCode =
      event.newValue;
  },

  textChange(event) {
    const textData = event.getParameter('textData');
    this.state.openReserveList[textData.dataIndex].remark =
      event.newValue;
  },
});

export default OpenReserveDialog;
