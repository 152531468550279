import {
  UIPanel,
  UIText,
  UIDataTable,
  UIDateTimePicker,
  UISelect,
  UIColumn,
  UICurrency,
} from 'RainbowUI';
import BindToMixin from 'react-binding';
import { CodeTableAction, CodeTableCstUI } from 'ClaimCommonConstant';

const ApprovalHistory = React.createClass({
  mixins: [BindToMixin],
  getInitialState() {
    return {
      AppraisalApproveHistory: this.props.appraisalApproveHistory,
    };
  },
  render() {
    const { taskCode, history, parentComponent } = this.props;
    const { CurrencyCode } = parentComponent.state;
    const { AppraisalApproveHistory } = this.state;
    let style;
    if (history) {
      style = 'default';
    } else {
      style = 'primary';
    }
    let index = 0;
    return (
      <UIPanel
        panelTitle={i18n.ClaimAppraisal.ApprovalHistory}
        toggleable="true"
        styleClass={style}
      >
        <UIDataTable
          pageable="false"
          indexable="false"
          value={AppraisalApproveHistory}
        >
          <UIColumn
            headerTitle={i18n.ClaimAppraisal.TransactionDate}
            render={data => {
              return (
                <UIDateTimePicker
                  value={data.ApproveEntity.approveDate}
                  placeHolder="MM/DD/YYYY HH:mm:ss"
                  format="MM/DD/YYYY HH:mm:ss"
                  io="out"
                />
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.ClaimAppraisal.ApprovedBy}
            render={data => {
              return (
                <UISelect
                  value={data.ApproveEntity.Approver}
                  codeTable={CodeTableAction.loadDataList(
                    CodeTableCstUI.ClaimUser,
                    null
                  )}
                  io="out"
                />
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.ClaimAppraisal.TotalNetAmount}
            render={data => {
              index += 1;
              return (
                <UICurrency
                  id={`CURRENCY_CODE_TotalNetAmount_${index}`}
                  value={data.ApproveEntity.ProcessSummary}
                  unit={window.EU00.getCurrencySignByCurrencyCode(
                    CurrencyCode
                  )}
                  enabled="false"
                />
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.ClaimAppraisal.ApprovalDecision}
            render={data => {
              return (
                <UIText
                  value={
                    data.ApproveEntity.DecisionCode
                      ? data.ApproveEntity.DecisionCode === 'Y'
                        ? 'approve'
                        : 'reject'
                      : ''
                  }
                  io="out"
                />
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.ClaimAppraisal.Remark}
            render={data => {
              return (
                <UIText value={data.ApproveEntity.Remark} io="out" />
              );
            }}
          />
        </UIDataTable>
      </UIPanel>
    );
  },
});
export default ApprovalHistory;
