import {
  UICell,
  UIDataTable,
  UISmartPanelGrid,
  UIColumn,
  UIUpdatePanel,
  UIText,
  UILink,
  UIParam,
} from 'RainbowUI';
import PropTypes from 'prop-types';

const CalimDamageTypeRelationAction = require('../../action/CalimDamageTypeRelationAction');

const ClaimDamageTypeRelationProductLine = React.createClass({
  propTypes: {
    entity: PropTypes.object,
  },
  getData(pageindex, pagesize) {
    const CalimDamageTypeRelationList = CalimDamageTypeRelationAction.getClmDamageTypeRelation(
      pageindex,
      pagesize
    );
    return CalimDamageTypeRelationList;
  },
  render() {
    const _self = this;
    return (
      <div>
        <UIUpdatePanel id="subclaimTypeandDamageTypeListSearchResultProvider">
          <UIDataTable
            id="subclaimTypeandDamageTypeList"
            indexable="false"
            provider={this.getData}
          >
            <UIColumn
              headerTitle={i18n.ClaimProduct.SubclaimType}
              render={data => {
                const tempData = `${data.Object.SubclaimTypeCode}:${data.Object.SubclaimTypeName}`;
                return (
                  <UILink
                    value={tempData}
                    onClick={
                      CalimDamageTypeRelationAction.openClaimDamageRelationType
                    }
                  >
                    <UIParam
                      name="productLineCode"
                      value={data.Object.ProductLineCode}
                    />
                    <UIParam
                      name="subclaimTypeCode"
                      value={data.Object.SubclaimTypeCode}
                    />
                    <UIParam
                      name="subclaimTypeName"
                      value={data.Object.SubclaimTypeName}
                    />
                    <UIParam
                      name="nodeName"
                      value={_self.props.entity.name}
                    />
                  </UILink>
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.ClaimProduct.DamageTypeList}
              render={data => {
                return this.getDetail(data.SelectedList);
              }}
            />
          </UIDataTable>
        </UIUpdatePanel>
      </div>
    );
  },
  getDetail(changeDataList) {
    if (changeDataList) {
      const result = changeDataList.map(data => {
        return (
          <UIText
            value={`${data.Desc.DamageTypeCode}:${data.Desc.DamageTypeDescription}`}
            io="out"
          />
        );
      });

      return (
        <UISmartPanelGrid column="1">
          <UICell>{result}</UICell>
        </UISmartPanelGrid>
      );
    }
  },
});
export default ClaimDamageTypeRelationProductLine;
