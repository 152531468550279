import {
  UIBox,
  UICell,
  UIParam,
  UILink,
  UIUpdatePanel,
  UIButton,
  UIDataTable,
  UIColumn,
} from 'RainbowUI';
import BindToMixin from 'react-binding';

const ProductCoverageListAction = require('../action/ProductCoverageListAction');
const ProductCoverageDialog = require('./ProductCoverageDialog');
const RemoveConfirmDialog = require('./RemoveConfirmDialog');

const ProductCoverageList = React.createClass({
  mixins: [BindToMixin],
  getInitialState() {
    return { isUpdate: false };
  },
  showCoverageDialog(event) {
    const data = event.getParameter('data');
    this.refs.productCoverageDialog_ref.showDialog(data);
  },
  isUpdate() {
    this.setState({ isUpdate: true });
  },
  render() {
    const _self = this;
    return (
      <div>
        <UIUpdatePanel id="ProductCoverageListIdProvider">
          <UIDataTable
            indexable="false"
            provider={
              ProductCoverageListAction.getProductCoverageList
            }
            id="ProductCoverageListId"
          >
            <UIColumn
              headerTitle={i18n.ClaimProduct.CoverageCode}
              width="15"
              render={data => {
                return (
                  <u>
                    <UILink
                      value={data.CoverageCode}
                      onClick={_self.showCoverageDialog}
                    >
                      <UIParam name="data" value={data} />
                    </UILink>
                  </u>
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.ClaimProduct.ExtCoverageTypeCode}
              width="20%"
              value="ExtCoverageTypeCode"
            />
            <UIColumn
              headerTitle={i18n.ClaimProduct.CoverageName}
              width="20%"
              value="CoverageName"
            />
            <UIColumn
              headerTitle={i18n.ClaimProduct.CoverageDescription}
              width="50%"
              value="CoverageDescription"
            />
            <UIColumn
              headerTitle={
                i18n.ClaimProduct.AvailableClaimingCoverage
              }
              width="20%"
              value="AvailableClaimingFlag"
            />
            <UIColumn
              headerTitle={i18n.ClaimProduct.Action}
              width="15%"
              render={data => {
                return (
                  <UILink
                    value={i18n.ClaimProduct.Remove}
                    onClick={
                      ProductCoverageListAction.removeProductDialog
                    }
                  >
                    <UIParam name="data" value={data} />
                  </UILink>
                );
              }}
            />
          </UIDataTable>
        </UIUpdatePanel>
        <UICell>
          <UIBox direction="left">
            <UIButton
              value={i18n.ClaimProduct.New}
              onClick={this.showCoverageDialog}
            />
          </UIBox>
        </UICell>
        <ProductCoverageDialog
          ref="productCoverageDialog_ref"
          parentFunction={this.isUpdate}
        />
        <RemoveConfirmDialog
          ref="removeConfirmDialog_ref"
          parentFunction={this.isUpdate}
        />
      </div>
    );
  },
});
export default ProductCoverageList;
