import {
  UIDialog,
  UIBox,
  UIButton,
} from 'RainbowUI';
const ClaimParty = require('./ClaimParty');


const SelectPayeeDialog = React.createClass({
  getDefaultProps() {
    return { index: '0' };
  },

  render() {
    return (
      <UIDialog
        title={i18n.ClaimParty.PayeeTitle}
        width="90%"
        id={`SelectPayeeDialogId${this.props.index}`}
        onClose={this.hide}
      >
        <ClaimParty {...this.props} showRadio="true" />
      </UIDialog>
    );
  },
});
export default SelectPayeeDialog;
