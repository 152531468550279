export default {
  // paymod
  PAY_MOD_DIRECT_CREDIT: '109',

  // common
  Status: 'Status',
  SettlementAmount: 'Settlement Amount',
  CoInsurancePayment: 'Co-insurance Payment',
  Cancel: 'Cancel',
  CancelSettlement: 'Cancel Settlement',
  CancelRecovery: 'Cancel Recovery',
  CancelConfirmation: 'Cancel Confirmation',
  CancelRemarkPlaceHolder: 'Please input cancel Remark',
  CancelRecoveryMessage:
    'Are you sure you want to cancel this recovery ?',
  CancelSettlementMessage:
    'Are you sure you want to cancel this settlement ?',
  // Settlement History
  SettlementHistory: 'Settlement History',
  SettlementHistoryDetail: 'Settlement History Detail',
  SettlementNo: 'Settlement Number',
  TransactionDate: 'Transaction Date',
  IssuedBy: 'Issued by',
  ApprovedBy: 'Approved by',
  Name: 'Payee/Payer',

  // Settlement Info
  PayMode: 'Payment Method',
  ExchangeRate: 'Exchange Rate',
  PayeeName: 'Payee Name',
  PayeeAddress: 'Payee Address',
  PayeeAccount: 'Account No',
  PayeeBank: 'Bank',
  PayeeIBAN: 'IBAN No',
  SwiftCode: 'SWIFT Number',
  OSReserve: 'O/S Reserve',
  PartialFinal: 'Partial/Final',
  SettlementType: 'Settlement Type',
  DeletePayee: 'DELETE PAYER',
  AddItem: 'Add Item',
  AddPayee: 'ADD PAYEE',
  AddPayer: 'ADD PAYER',
  AddRecoveryPayer: 'ADD RECOVERY PAYER',
  DeletePayer: 'DELETE PAYER',
  DeleteRecoveryPayer: 'DELETE RECOVERY PAYER',
  BreachOfContractReason: 'Breach of Contract Reason',
  ChequeDetail: 'Cheque Detail',
  PayType: 'Payee Type',

  SettlementDetail: 'Settlement Detail',
  PaymentMethodCheque: 'Cheque Issue Date :',
  PaymentMethodBankTransfer: 'Payment Date :',
  PaymentMethodDirectCredit: 'Direct Credit Transfer Date :',
  Settlement: 'Payment',
  SettlementApproval: 'Settlement Approval',

  NoPayee: 'Please add Payee',

  ReferenceNumber: 'Reference Number',
  ReferenceDate: 'Reference Date',

  SettlemenAmountCheck: 'Settlement Amount must be negative',

  ConfirmMessage: ' Are you sure you want to close this window ?',
  ConfirmMessageTitle: ' Confirm',

  Recovery: 'Recovery',
  RecoveryDetail: 'Recovery Detail',
  RecoveryHistory: 'Recovery History',
  RecoveryHistoryDetail: 'Recovery History Detail',
  PayerName: 'Payer Name',
  RecoveryNo: 'Recovery Number',
  RecoveryAmount: 'Recovery Amount',
  RecoveryType: 'Recovery Type',
  RecoveryFrom: 'Recovery From',
  // add 3 field about tax
  TaxCode: 'Tax Code',
  TaxRate: 'GST %',
  TaxAmount: 'GST Amount',
  ChequeIssueDate: 'Cheque Issue Date',
  ChequeReceiveDate: 'Cheque Receive Date',
  // MessageHelper
  SettlementSaveSuccess: 'Settlement is saved successfully',
  SettlementSubmitSuccess: 'Settlement is submitted successfully',
  FillBankInfo: 'Please fill in Bank and IBAN No. info.',
  SettlementSubmitBlockByExceedOutstandingReserveAmount:
    'Settlement amount can not exceed outstanding reserve amount，please adjust the reserve amount first',
  SettlementApprovalSubmitSuccess:
    'Settlement Approval is submitted successfully',
  SettlementCancelSuccess: 'Settlement  is canceled successfully',
  SettlementSubmitError: 'Every payee must contain an item at least',
  RecoverySaveSuccess: 'Recovery is saved successfully',
  RecoverySubmitSuccess: 'Recovery is submitted successfully',
  RecoverySubmitBlockByExceedOutstandingReserveAmount:
    'Recovery amount cannot exceed outstanding reserve amount',
  RecoveryApprovalSubmitSuccess:
    'Recovery Approval is submitted successfully',
  RecoveryCancelSuccess: 'Recovery is canceled successfully',
  RefundSaveSuccess: 'Refund is saved successfully',
  RefundSubmitSuccess: 'Refund is submitted successfully',
  RefundSubmitBlockByExceedOutstandingReserveAmount:
    'Refund amount cannot exceed outstanding reserve amount',
  RefundApprovalSubmitSuccess:
    'Refund Approval is submitted successfully',
  RefundCancelSuccess: 'Refund is canceled successfully',
  RecoverySubmitError: 'Every payer must contain an item at least',
  ChoosePartialFinalFail:
    'You have added more than one settlement items with same Reserve Type. Only the last item can be final settlement',
  DeletePayeeError: 'This is the last payee you cannot delete it',
  ChooseReserveError: 'The reserve type has already been selected',
  NotZero: 'You cannot input Settlement Amount zero',
  FraudIndicatorWarning:
    'Fraud Indicator has been raised, please check',
  ChoosePayeeError: 'Please add Payer/Payee',
  RecoveryApproval: 'Recovery Approval',

  FeeStatusContinutMessage: 'Do you want to continue?',
  WithOutPolicyError:
    'The Claim has no policy attached, please retrieve the policy first before settlement',
  CheckSettlementAmountError:
    'The accumulated amount of Loss (Indemnity) of this benefit/cover type exceeds the SI/Limitation of this insured benefit/cover type',
  CheckRecoveryAmountError:
    'Recovery amount cannot exceed outstanding reserve amount',
  SelectReserveType: 'Please Select ReserveType First',

  ErrorMsg: {
    Error_0001: 'Get ClaimCase Error.',
    Error_0002: 'System Error',
    Error_0003: 'CheckSettlementAmount Error.',
    Error_0004: 'checkRecoveryAmount Error.',
  },
  WarnMsg: {
    Warn_0001: 'System Warning',
    Warn_0002: 'Operation Warning ',
    Warn_0003: "Select 'Pay Mode' firstly please.",
    Warn_0004: "Select 'Payee Name' first please.",
    Warn_0005: "Current 'Payment Method' not support account.",
  },
  ConfirmMsg: {},

  SuccessMsg: {},
  InfoMsg: {},
};
