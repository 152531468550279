const home = env.serverUrl;
const urlDefine = {
  loadLossItems: `${home}appraisal/loadLossItems`,
  loadClaimAppraisal: `${home}appraisalProperty/loadClaimAppraisal`,
  loadClaimAppraisal4Other: `${home}appraisalProperty/loadClaimAppraisal4Other`,
  showAppraisal: `${home}appraisalProperty/showAppraisal/`,
  saveAppraisalProperty: `${home}appraisalProperty/saveAppraisalProperty`,
  submitAppraisalProperty: `${home}appraisalProperty/submitAppraisalProperty`,
  approveAppraisalProperty: `${home}appraisalProperty/approveAppraisalProperty`,
  saveAppraisalBodyInfo: `${home}appraisal/saveAppraisalBody`,

  syncFIData: `${home}investigation/synModel/`,
  SynButtonIsEnable: `${home}workflow/couldCloneData/1/`,

  AppraisalClosedTasksLoad: `${home}appraisal/closedTasks/`,
};
export default urlDefine;
