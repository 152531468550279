export default {
  "@type": "ClaimPolicy-ClaimPolicy",
  "EffDate": "2019-11-29",
  "ExpDate": "2020-11-29",
  "InsuranceType": "1",
  "PolicyNo": "HO48187912936",
  "PolicyTypeCode": "HO",
  "ProductTypeCode": "HO",
  "ProductCode": "2020001",
  "SumInsured": "0",
  "CurrencyCode": "USD",
  "ExtContent": "{\"PolicyId\":82503748636}",
  "PolicyholderName": "Sam Lee",
  "InsuredList": [
    {
      "@type": "ClaimPolicyInsured-ClaimPolicyInsured",
      "InsuredCategory": "3",
      "PolicyInsuredId": "82503748636",
      "ExtContent": "{\"InsuredId\":82503748636}",
      "InsuredName": "1602 SW 150th Terrace,DAVIE,FL, 33326",
      "SumInsured": "0",
      "CoverageList": [
        {
          "@type": "ClaimPolicyCoverage-ClaimPolicyCoverage",
          "InitLossIndemnity": "0",
          "LossConfigDefaultAmount": "0",
          "CoverageCode": "2020001001",
          "PolicyCoverageCode": "2020001001",
          "CoverageName": "VT Contents Limit",
          "ProductCode": "2020001",
          "SumInsured": "0",
          "ExtContent": "{\"PolicyCoverageId\":82503748636}",
          "RiskCateId": "5",
          "CoveragePeriodAllowed": "N",
          "EffDate": "2019-11-29",
          "ExpDate": "2020-11-29",
        },
      ]
    }
  ],
  "PartyList": [
    {
      "@type": "ClaimPolicyParty-ClaimPolicyParty",
      "RoleType": "07",
      "ContactType": "2",
      "IsInsured": "Y",
      "PartyName": "Commany A_=_Henry_=_Damon",
      "IdType": "1",
      "IdNumber": "2020042106",
      "Email": "vaultTest@ebaotech.com",
      "Mobile": "19863728736"
    },
    {
      "@type": "ClaimPolicyParty-ClaimPolicyParty",
      "RoleType": "07",
      "ContactType": "1",
      "IsInsured": "Y",
      "PartyName": "Sam_=_Lee",
      "IdType": "14",
      "IdNumber": "2020042106",
      "Email": "vaultTest@ebaotech.com",
      "Mobile": "19863728736"
    }
  ]
}
