import {
  MessageHelper,
  AjaxUtil,
  CodeTable,
  Caller,
} from 'RainbowUI';
import ClaimCommonExt from 'ClaimCommonExt';
import ClmPartyService from '../service/ClmPartyService';
import CommonPartyService from '../service/CommonPartyService';
import ClaimCstUI from '../../common/constant/ClaimCstUI';
import SearchTaskMessage from '../../common/action/SearchTaskMessage';
import alt from '../alt';
import PartyConst from '../../../../party/js/party/const/PartyConst';

const CodeTableAction = require('../../common/action/CodeTableAction');
const CodeTableCstUI = require('../../common/constant/CodeTableCstUI');
const Url = require('../../common/url/Url');

class ClaimPartyAction {
  initData(caseId) {
    ClmPartyService.initTreeData(caseId).then(data => {
      const clmPtyRole = data.ClaimPartyRoleList;
      const clmCasePty = data.ClaimPartyList;
      let tree = '[';
      clmPtyRole.forEach((parent, i) => {
        const index_i = i + 1;
        const treeParentNode = `{id:'${index_i}',pId:'0',name:'${parent.RoleName}'},`;
        tree += treeParentNode;
        clmCasePty.forEach((child, j) => {
          if (parent.RoleCode == child.PartyRole) {
            const index_j = j + 1;
            const treeChildNode = `{id:'${index_i}${index_j}',pId:'${index_i}',url:'${child.PtyPartyId}',name:'${child.PartyName.replace(/'/g, "\\\'")}',ClmPtyId:'${child.PartyId}',ClmPtyRole:'${child.PartyRole}'},`;

            tree += treeChildNode;
            clmCasePty[j].roleCode = '';
          }
        });
      });
      tree = tree.substring(tree, tree.length - 1);
      tree += ']';
      const clmPtyTree = eval(`(${tree})`);
      this.dispatch(clmPtyTree);
    });
  }

  openTreeNode(event, treeId, teeNode) {
    this.actions.loadParty(
      teeNode.url,
      teeNode.ClmPtyId,
      teeNode.ClmPtyRole
    );
  }

  loadParty(partyId, clmPtyId, clmPtyRole) {
    const ClaimPartyActionProcess = ClaimCommonExt.getClaimPartyActionProcess();
    let result;
    ClaimPartyActionProcess.loadParty(partyId, clmPtyRole).then(
      tmpdata => {
        const data = tmpdata;
        data.ClmPtyId = clmPtyId;
        data.ClmPtyRole = clmPtyRole;
        data.ClmPtyType = ClaimCstUI.PARTY_TYPE_ORGANIZATION;

        if (data['@type'] == ClaimCstUI.CUSTOMER_PARTY_INDIVIDUAL) {
          data.ClmPtyType = ClaimCstUI.PARTY_TYPE_INDIVIDUAL;
        }
        result = data;
        this.dispatch(data);
      }
    );

    return result;
  }

  loadClaimParty(clmPtyId) {
    let result = {};
    const setting = { method: 'GET', async: false, block: false };
    const url = Url.LoadClaimPartyById + clmPtyId;
    Caller.call(url, null, setting).then(
      data => {
        result = data;
      },
      err => {
        MessageHelper.error(err.statusText);
      }
    );
    return result;
  }

  initPartyDetailForNewClaimant() {
    this.dispatch({
      ClmPtyRole: ClaimCstUI.PARTY_ROLE.Claimant,
      PartyAccountList: [],
      PartyContactList: [],
      AddressVoList: [],
    });
  }

  queryCommonParty(pageIndex, pageSize, searchVo) {
    if (!searchVo) {
      return;
    }
    const partyType = searchVo.PartyType;
    const partyName = searchVo.PartyName;
    const idNumber = searchVo.IdNumber;
    const partyId = searchVo.PartyId;
    const searchParam = {
      SortField: 'PartyName',
      SortType: 'asc',
      PageNo: pageIndex,
      PageSize: pageSize,
      FuzzyConditions: {
        PartyName: searchVo.PartyName,
      },
      NotInFuzzyConditions: {
        RoleType: [PartyConst.PARTY_ROLE_CODE_IND_EMPLOYEE],
      },
      Conditions: {
        IdNumber: searchVo.IdNumber,
        PartyId: partyId,
      },
      OrConditions: {},
      Module: 'PartyIndividual',
    };
    const url = Url.SearchPartyRole;
    if (partyType == '2') {
      searchParam.Module = 'PartyOrg';
      searchParam.Conditions = {
        OrganizationIdNumber: searchVo.IdNumber,
        PartyId: partyId,
      };
      searchParam.NotInFuzzyConditions = {
        RoleType: [
          PartyConst.PARTY_ROLE_CODE_ORG_INSURANCE,
          PartyConst.PARTY_ROLE_CODE_ORG_REINSURANCE,
          PartyConst.PARTY_ROLE_CODE_ORG_BANK,
        ],
      };
    }

    let list = null;
    if (idNumber || partyName || partyId) {
      AjaxUtil.doPost(url, searchParam, {
        async: false,
        done(data) {
          list = data;
        },
      });

      while (partyId && list.Total == 0) {
        AjaxUtil.doPost(url, searchParam, {
          async: false,
          done(data) {
            list = data;
          },
        });
      }
      const resultObj = { count: list.Total, result: list.PartyVos };
      const searchTaskMessage = new SearchTaskMessage(resultObj);
      searchTaskMessage.showMessage();
      return resultObj;
    }
  }

  createCommonParty(partyVo) {
    const ClaimPartyActionProcess = ClaimCommonExt.getClaimPartyActionProcess();
    const commonParty = ClaimPartyActionProcess.createCommonParty(
      partyVo
    );

    let result;
    CommonPartyService.createCommonParty(commonParty).then(
      data => {
        result = data;
      },
      data => {
        MessageHelper.error(
          JSON.parse(data.responseText).Messages[0].Message,
          ''
        );
      }
    );

    return result;
  }

  deleteObjectField(dataList) {
    $(dataList).each((index, datas) => {
      const data = datas;
      delete data.dataIndex;
      delete data.selected;
      delete data.isAdd;
    });

    return dataList;
  }

  addToClaimParty(partyVo, tmpcommonParty, caseId) {
    const commonParty = tmpcommonParty;
    const claimParty = this.actions.createOrUpdateClaimParty(
      partyVo,
      commonParty,
      caseId
    );
    if (claimParty) commonParty.ClmPtyId = claimParty.PartyId;
    if (claimParty) commonParty.ClmPtyRole = claimParty.PartyRole;
    return commonParty;
  }

  createOrUpdateClaimParty(
    partyVo,
    commonParty,
    caseId,
    selectAddressIndex,
    selectAccountIndex,
    selectContactIndex,
    subclaimIndex
  ) {
    const ClaimPartyActionProcess = ClaimCommonExt.getClaimPartyActionProcess();
    const claimParty = ClaimPartyActionProcess.constructClaimParty(
      partyVo,
      commonParty,
      caseId,
      selectAddressIndex,
      selectAccountIndex,
      subclaimIndex
    );

    let result;
    if (caseId != null && caseId !== '') {
      if (partyVo.PartyId) {
        claimParty.PartyId = partyVo.ClmPtyId;
        ClmPartyService.updateClaimParty(claimParty).then(data => {
          result = data;
        });
      } else {
        ClmPartyService.createClmParty(
          claimParty,
          subclaimIndex
        ).then(data => {
          result = data;
        });
      }
      return result;
    }
    claimParty.ClmPtyType = partyVo.ClmPtyType;
    return claimParty;
  }

  createPartyParty(caseId, tmpclaimParty) {
    const claimParty = tmpclaimParty;
    claimParty['@type'] = 'ClaimParty-ClaimParty';
    claimParty.CaseId = caseId;

    let result = {};
    ClmPartyService.createClmParty(claimParty, '1').then(data => {
      result = data;
    });
    return result;
  }

  updateCommonParty(partyVo) {
    const ClaimPartyActionProcess = ClaimCommonExt.getClaimPartyActionProcess();
    const afterPartyVo = ClaimPartyActionProcess.updateCommonParty(
      partyVo
    );

    let result;

    CommonPartyService.updateCommonParty(afterPartyVo).then(
      data => {
        result = data;
      },
      data => {
        MessageHelper.error(
          JSON.parse(data.responseText).Messages[0].Message,
          ''
        );
      }
    );

    return result;
  }

  createOrUpdateParty(
    caseId,
    claimPartyVo,
    isAddressCheck,
    isAccountCheck,
    isContactCheck,
    subclaimIndex
  ) {
    const partyVo = $.extend(true, {}, claimPartyVo);

    const selectedAddInfo = this.actions.getSelectedIndex(
      partyVo.AddressVoList
    );
    const selectedAccInfo = this.actions.getSelectedIndex(
      partyVo.PartyAccountList
    );
    const selectedConInfo = this.actions.getSelectedIndex(
      partyVo.PartyContactList
    );
    if (
      this.actions.checkNoSelectedInfo(
        selectedAddInfo.selectedIndex,
        selectedAccInfo.selectedIndex,
        selectedConInfo.selectedIndex,
        isAddressCheck,
        isAccountCheck,
        isContactCheck
      )
    ) {
      return;
    }

    let commonParty;
    if (partyVo.PartyId) {
      this.actions.updateCommonParty(partyVo);
      CommonPartyService.loadParty(partyVo.PartyId).then(data => {
        commonParty = data;
      });
    } else {
      commonParty = this.actions.createCommonParty(partyVo);
    }
    if (_.isEmpty(commonParty)) {
      return;
    }

    if (selectedAddInfo.selectedIndex > -1) {
      selectedAddInfo.selectedId = commonParty.AddressVoList[selectedAddInfo.selectedIndex].AddressId;
    }

    if (selectedAccInfo.selectedIndex > -1) {
      selectedAccInfo.selectedId = commonParty.PartyAccountList[selectedAccInfo.selectedIndex].AccountId;
    }

    const claimParty = this.actions.createOrUpdateClaimParty(
      claimPartyVo,
      commonParty,
      caseId,
      selectedAddInfo.selectedId,
      selectedAccInfo.selectedId,
      selectedConInfo.selectedId,
      subclaimIndex
    );

    return { commonParty, claimParty };
  }

  getSelectedIndex(dataList) {
    let selectedIndex = -1;
    let selectedId;
    $(dataList).each((index, data) => {
      if (data.selected === 'Y') {
        if (data['@pk']) {
          selectedIndex = -2;
          selectedId = data['@pk'];
        } else {
          selectedIndex = dataList.length - index - 1;
        }
        return false;
      }
    });
    return { selectedIndex, selectedId };
  }

  checkNoSelectedInfo(
    selectAddressIndex,
    selectAccountIndex,
    selectContactIndex,
    isAddressCheck,
    isAccountCheck,
    isContactCheck
  ) {
    if (selectAddressIndex == -1 && isAddressCheck) {
      MessageHelper.error(i18n.ClaimParty.RelateAddressError);
      return true;
    }
    if (selectAccountIndex == -1 && isAccountCheck) {
      MessageHelper.error(i18n.ClaimParty.RelateAccountError);
      return true;
    }
    if (selectContactIndex == -1 && isContactCheck) {
      MessageHelper.error(i18n.ClaimParty.RelateContactError);
      return true;
    }
    return false;
  }

  getPartyAuth(caseId) {
    let result;
    ClmPartyService.getPartyAuth(
      caseId,
      ClaimCstUI.AUTHORITY_CLAIM_PARTY
    ).then(data => {
      if (data.Status === 'OK') {
        result = data.Model;
        this.dispatch(data.Model);
      } else {
        MessageHelper.error(i18n.ClaimParty.LoadAuthorityError);
      }
    });
    return result;
  }

  getPartyAuthInit(tmpcaseId) {
    let caseId = tmpcaseId;
    let result = {};
    if (caseId === null || caseId === undefined || caseId === '')
      caseId = '';
    const code = 'CLAIM_PARTY';
    AjaxUtil.doGet(
      `${Url.ClaimAuthorityCheck}?caseId=${caseId}&code=${code}`,
      {},
      {
        async: false,
        done(data) {
          result = data.Model;
        },
        fail() {
          MessageHelper.error(i18n.ClaimParty.LoadAuthorityError);
        },
      }
    );
    return result;
  }

  filterPtyRoleCodeTB(fromWhere, claimPartyRole) {
    const codeTablePartyRole = CodeTableAction.loadDataList(
      CodeTableCstUI.ClaimPartyRole,
      null
    );

    if (fromWhere === 'partyMaintance') {
      if (codeTablePartyRole.codes) {
        const filterList = ['02', '03', '04', '05', '06'];
        const filterCodeTable = [];
        codeTablePartyRole.codes.forEach((data, index) => {
          const { id } = data;
          for (var index in filterList) {
            if (id == filterList[index]) {
              filterCodeTable.push(data);
            }
          }
        });

        return new CodeTable(filterCodeTable);
      }
    } else {
      CodeTableAction.loadDataList(
        CodeTableCstUI.ClaimPartyRole,
        claimPartyRole
      );
    }
  }

  getByClaimPartyRole(caseId, claimPartyRole) {
    let result = {};
    const setting = { method: 'GET', async: false, block: false };
    const url = `${Url.getByClaimPartyRole +
      caseId}/${claimPartyRole}`;
    Caller.call(url, null, setting).then(
      data => {
        result = data.Model;
      },
      err => {
        MessageHelper.error(err.statusText);
      }
    );
    return result;
  }
}
export default alt.createActions(ClaimPartyAction);
