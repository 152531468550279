import BindToMixin from 'react-binding';
import PropTypes from 'prop-types';

const SubrogationInfoItem = require('./SubrogationInfoItem');

const SubrogationCoverageInfo = React.createClass({
  mixins: [BindToMixin],

  propTypes: {
    otherInfo: PropTypes.object.isRequired,
    insuredId: PropTypes.string.isRequired,
    coverageCode: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
    isApproveTask: PropTypes.bool,
  },

  render() {
    const {
      insured,
      coverage,
      readOnly,
      history,
      otherInfo,
      coverage: { CoverageCode, ItemId },
      insured: {
        value: { InsuredId },
      },
      subrogationInfo: {
        sourceObject: {
          SubrogationInfo: { CurrencyCode },
        },
      },
    } = this.props;
    const objectId = InsuredId;
    const subrogationItems = this.getCurrentSubrogationItem();
    if (!subrogationItems || subrogationItems.length < 1) {
      return <div />;
    }
    return (
      <SubrogationInfoItem
        insuredId={InsuredId}
        readOnly={readOnly}
        history={history}
        itemId={ItemId}
        objectId={objectId}
        insured={insured}
        otherInfo={otherInfo}
        currencyCode={CurrencyCode}
        coverage={coverage}
        subrogationItems={subrogationItems}
        coverageCode={CoverageCode}
      />
    );
  },
  getCurrentSubrogationItem() {
    const { items } = this.bindArrayTo(
      this.props.subrogationInfo,
      'SubrogationItems'
    );
    const currentItems = new Array();
    const objectId = this.props.insured.value.InsuredId;
    const coverageCode = this.props.coverage.CoverageCode;
    for (let i = 0; i < items.length; i += 1) {
      if (
        items[i].value.ObjectId == objectId &&
        items[i].value.CoverageCode == coverageCode
      ) {
        currentItems.push(items[i].value);
      }
    }
    return currentItems;
  },
});
export default SubrogationCoverageInfo;
