export default {
  ClaimPartyMaintenance: '赔案当事人管理',
  SelectCommonParty: '请选择一个当事人',
  OpenSearchLink: '不在列表中',
  IndOrOrgRadio: '个人/公司',
  PartyName: '当事人名称',
  IDNumber: '证件号码',
  IDNumberForSearch: '证件号码',
  CreateAs: '创建为',
  AddToClaimParty: '创建为赔案当事人',
  NotExistInCommonParty: '公共当事人库中不存在',
  FirstName: '名',
  LastName: '姓',
  PartyRole: '当事人角色',
  IDType: '证件类型',
  AddressInfo: '地址信息',
  AddressType: '地址类型',
  AddressDetail: '地址详情',
  AccountInfo: '账户信息',
  AccountType: '账户类型',
  AccountDetail: '账户详情',
  BankName: '银行',
  AccountHolderName: '账户持有人姓名',
  BankAccountNo: '银行账号',
  SwiftCode: 'SWIFT Number',
  ContactInfo: '联系人信息',
  ContactNumber: '联系号码',
  ContactEmail: '联系人邮件',
  SaveButton: '确认',
  CancelButton: '取消',
  SelectAsPayee: '选为领款人',
  SelectAsPayer: '选为赔款人',
  EditLink: '编辑',
  PayeeTitle: '选择一个领款人',
  PayerTitle: '选择一个付款人',
  EditClaimParty: '编辑赔案当事人',
  RoleInfo: '角色信息',
  SelectfromCommonParty: '从公共当事人库中选择',
  CreateNewClaimParty: '创建赔案当事人',

  // party message
  RelateAddressError: '请选择地址',
  RelateAccountError: '请选择账户',
  LoadAuthorityError: '加载权限错误',
};
