const ClaimCalculationExt = {
  getClaimCalculationValidationAction() {
    const CalculationValidationAction = require('./module/calculation/common/action/CalculationValidationAction');
    return new CalculationValidationAction();
  },
  getCalculationServiceProcess() {
    const CalculationServiceProcess = require('./module/calculation/common/service/CalculationServiceProcess');
    return new CalculationServiceProcess();
  },
};

export default ClaimCalculationExt;
