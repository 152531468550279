import {
  UICheckbox,
  UIDataTable,
  MessageHelper,
  UIColumn,
  UIBox,
  UIButton,
  UIPanel,
  UICell,
  UILink,
  UIParam,
  UISmartPanelGrid,
  UIText,
  UIDateTimePicker,
  UISelect,
  UIUpdatePanel,
  CodeTable,
} from 'RainbowUI';
import PartyAction from '../../../party/actions/PartyAction';
import DefinedCodeTable from '../../../party/codetable/CodeTable';
import UrlConfig from '../../../../UrlConfig';
import PartyConst from '../../../party/const/PartyConst';

const PtyIntegrationSearchInfo = React.createClass({
  getInitialState() {
    let partyCategoryEnableFlag = true;
    if (this.props.enableChangePartyCategory === 'N') {
      partyCategoryEnableFlag = false;
    }
    let partyTypeCodeTable = DefinedCodeTable.getCategroyCodeTable();
    const filterPartyVenRole = DefinedCodeTable.getPartyVenFilterCodeTable();
    if (filterPartyVenRole) {
      if (filterPartyVenRole.map[this.props.RoleName]) {
        partyTypeCodeTable = new CodeTable(
          _.filter(partyTypeCodeTable.codes, code => {
            if (code.id !== PartyConst.PARTY_TYPE_VEN) return code;
          })
        );
      }
    }
    return {
      definedCondition: this.props.definedCondition,
      conditions: this.props.conditions,
      fuzzyConditions: this.props.fuzzyConditions,
      orCondtions: this.props.orCondtions,
      isSearch: false,
      RoleName: this.props.RoleName,
      isVisibled: this.props.isVisibled,
      partyCategoryEnableFlag,
      isSearchRole: this.props.isSearchRole,
      partyTypeCodeTable,
      isClaimant: this.props.isClaimant,
    };
  },

  render() {
    const { definedCondition } = this.props;
    const partyType = definedCondition.PartyType;
    let partyConditionRender;
    // 1: Individual; 2:Organization
    if (partyType == PartyConst.PARTY_TYPE_IND) {
      partyConditionRender = this.indiviPartyConditionRender();
    }
    if (partyType == PartyConst.PARTY_TYPE_ORG) {
      partyConditionRender = this.orgPartyConditionRender();
    }
    if (partyType == PartyConst.PARTY_TYPE_VEN) {
      partyConditionRender = this.venPartyConditionRender();
    }
    let renderSearchResult;

    if (partyType == PartyConst.PARTY_TYPE_IND) {
      renderSearchResult = this.renderIndividualSearchResult();
    }
    if (partyType == PartyConst.PARTY_TYPE_ORG) {
      renderSearchResult = this.renderOrgSearchResult();
    }
    if (partyType == PartyConst.PARTY_TYPE_VEN) {
      renderSearchResult = this.renderVenSearchResult();
    }

    return (
      <div id="testetee">
        <UIUpdatePanel id="ptyConditionRenderTable">
          {partyConditionRender}
          {renderSearchResult}
        </UIUpdatePanel>
      </div>
    );
  },

  partyCategoryChange(evnet) {
    this.resetHandler();
    if (evnet.newValue) {
      const party = {};
      party.PartyType = evnet.newValue;
    }
  },

  roleTypeChange(evnet) {
    this.state.fuzzyConditions.RoleTypeCode = evnet.newValue;
  },
  resetHandler() {
    // MODIFY-BEFORE C.P 2018-03-26 artf477459 Parent page's conditions add
    // this.setState({
    //     conditions: {},
    // MODIFY-CHANGE C.P 2018-03-26 artf477459 Parent page's conditions add
    const outConditions = {};
    if (this.props.conditions.RoleType) {
      outConditions.RoleType = this.props.conditions.RoleType;
    }
    this.setState({
      conditions: outConditions,
      // MODIFY-AFTER C.P 2018-03-26 artf477459 Parent page's conditions add
      fuzzyConditions: {},
      orCondtions: {},
      isSearch: false,
    });
  },
  searchHandler() {
    const { conditions } = this.state;
    if (conditions.Status) {
      if (conditions.Status == '0') {
        conditions.Status = 0;
      }
      if (conditions.Status == '1') {
        conditions.Status = 1;
      }
    }
    const { fuzzyConditions } = this.state;
    if (fuzzyConditions.RoleTypeCode) {
      const roleName =
        PartyConst.ClaimPartyRolelMap[fuzzyConditions.RoleTypeCode];
      if (this.state.definedCondition.PartyType == 1) {
        this.state.fuzzyConditions.RoleType =
          PartyConst.RoleTypeIndividualMap[roleName];
      } else if (this.state.definedCondition.PartyType == 2) {
        this.state.fuzzyConditions.RoleType =
          PartyConst.RoleTypeOrgnizationMap[roleName];
      }
    } else {
      this.state.fuzzyConditions.RoleType = '';
    }
    if (
      this.state.RoleName === PartyConst.PARTY_ROLE_NAME_RECOVER_FROM
    ) {
      if (this.state.definedCondition.PartyType === '1') {
        this.state.fuzzyConditions.RoleType = 'RFIND1';
      } else if (this.state.definedCondition.PartyType === '2') {
        this.state.fuzzyConditions.RoleType = 'RFORG1';
      }
    } else if (
      this.state.RoleName ===
      PartyConst.PARTY_ROLE_NAME_CLAIM_INVESTIGATOR
    ) {
      if (this.state.definedCondition.PartyType === '1') {
        this.state.fuzzyConditions.RoleType =
          PartyConst.PARTY_ROLE_CODE_IND_CLAIMANT_INVESTIGATOR;
      } else if (this.state.definedCondition.PartyType === '2') {
        this.state.fuzzyConditions.RoleType =
          PartyConst.PARTY_ROLE_CODE_ORG_CLAIMANT_INVESTIGATOR;
      }
    } else if (
      this.state.RoleName ===
      PartyConst.PARTY_ROLE_NAME_HEALTH_CARE_PROVIDER
    ) {
      if (this.state.definedCondition.PartyType === '1') {
        this.state.fuzzyConditions.RoleType =
          PartyConst.PARTY_ROLE_CODE_IND_HEALTHCARE;
      } else if (this.state.definedCondition.PartyType === '2') {
        this.state.fuzzyConditions.RoleType =
          PartyConst.PARTY_ROLE_CODE_ORG_HEALTHCARE;
      }
    } else if (
      this.state.RoleName === PartyConst.PARTY_ROLE_NAME_GARAGE
    ) {
      if (this.state.definedCondition.PartyType === '1') {
        this.state.fuzzyConditions.RoleType =
          PartyConst.PARTY_ROLE_CODE_IND_GARAGE;
      } else if (this.state.definedCondition.PartyType === '2') {
        this.state.fuzzyConditions.RoleType =
          PartyConst.PARTY_ROLE_CODE_ORG_GARAGE;
      }
    }

    this.setState({ isSearch: true });
  },
  editHandler(clickEvent) {
    const selectData = UIDataTable.getSelectedRecord('PartyRecord');
    if (!selectData.length) {
      MessageHelper.warning(
        i18n.PartyMsg.WarnMsg.Warn_0005,
        i18n.PartyMsg.WarnMsg.Warn_0003,
        MessageHelper.POSITION_TOP_RIGHT
      );
      return;
    }
    let party = selectData[0];
    if (!_.isEmpty(clickEvent.paramJson)) {
      party = clickEvent.paramJson[0].value;
    }
    const { PartyId } = party;
    if (PartyId === null || PartyId === undefined || PartyId === '') {
      MessageHelper.error(
        i18n.PartyIdIsNull,
        i18n.PartyMsg.ErrorMsg.Error_0001,
        MessageHelper.POSITION_TOP_RIGHT
      );
    } else {
      window.location.href = `${UrlConfig.partyCommonUrl}#/integrationPartyMaintain/${PartyId}`;
    }
  },

  queryHandler(clickEvent) {
    let party = {};
    if (!_.isEmpty(clickEvent.paramJson)) {
      party = clickEvent.paramJson[0].value;
    }
    const { PartyId } = party;
    if (PartyId === null || PartyId === undefined || PartyId === '') {
      MessageHelper.error(
        i18n.PartyIdIsNull,
        i18n.PartyMsg.ErrorMsg.Error_0001,
        MessageHelper.POSITION_TOP_RIGHT
      );
    } else {
      window.location.href = `${UrlConfig.partyCommonUrl}#/viewIntegrationPartyBaseInfo/${PartyId}`;
    }
  },

  createHandler() {
    this.props.createHandler();
  },

  indiviPartyConditionRender() {
    const {
      conditions,
      fuzzyConditions,
      partyTypeCodeTable,
      partyCategoryEnableFlag,
      isVisibled,
      isClaimant,
    } = this.state;
    const _self = this;
    const roleName = _self.props.RoleName;

    let showCreatePartyButton = false;
    if (PartyConst.RoleTypeIndividualMap[roleName] === 'DIRIND') {
      showCreatePartyButton = false;
    } else {
      showCreatePartyButton = isVisibled;
    }

    let rolType = _self.props.roleType;
    const { claimFlag } = _self.props;
    let claimOnly = <UICell colspan="2" />;
    if (claimFlag == true) {
      if (isClaimant === true) {
        claimOnly = (
          <UISelect
            label="Party Role"
            codeTable={DefinedCodeTable.getPartyRoleCodeTable}
            onChange={_self.roleTypeChange}
            model={fuzzyConditions}
            enabled="false"
            property="RoleTypeCode"
            defaultValue={rolType}
            showBlankOption="true"
            widthAllocation="7,5"
            colspan="2"
          />
        );
      } else {
        claimOnly = (
          <UISelect
            label="Party Role"
            codeTable={DefinedCodeTable.getPartyRoleCodeTable}
            onChange={_self.roleTypeChange}
            model={fuzzyConditions}
            property="RoleTypeCode"
            defaultValue={rolType}
            showBlankOption="true"
            widthAllocation="7,5"
            colspan="2"
          />
        );
      }
    }

    if (fuzzyConditions.RoleTypeCode) {
      rolType = fuzzyConditions.RoleTypeCode;
    } else if (fuzzyConditions.RoleType) {
      rolType = fuzzyConditions.RoleType;
    }
    return (
      <div>
        <UIPanel panelTitle={i18n.PartySearchCriteria}>
          <UISmartPanelGrid column="4">
            <UISelect
              label={i18n.PartyCategory}
              codeTable={partyTypeCodeTable}
              onChange={_self.partyCategoryChange}
              enabled={partyCategoryEnableFlag}
              model={_self.state.definedCondition}
              property="PartyType"
              showBlankOption="false"
              widthAllocation="7,5"
              colspan="2"
            />
            {claimOnly}
            <UIText
              label={i18n.PartyName}
              model={conditions}
              property="PartyName"
              widthAllocation="7,5"
            />

            <UICheckbox
              single="true"
              label={i18n.FuzzySearch}
              model={conditions}
              property="FuzzySearch"
              defaultValue="Y"
            />
            <UICell colspan="2" />
            <UIText
              label={i18n.PartyFormerCode}
              model={conditions}
              property="PartyCode"
              widthAllocation="7,5"
              colspan="2"
            />
            <UIText
              label={i18n.IdNo}
              model={conditions}
              property="IdNumber"
              widthAllocation="7,5"
              colspan="2"
            />
            <UIDateTimePicker
              format="MM/DD/YYYY"
              label={i18n.BirthDay}
              model={conditions}
              property="DateOfBirth"
              widthAllocation="7,5"
              manualInput="true"
              colspan="2"
            />
            <UIText
              label={i18n.MobileHomeWorkNo}
              model={conditions}
              property="WorkHomeMobileTel"
              widthAllocation="7,5"
              colspan="2"
            />
            <UIText
              label={i18n.SSSEmployerId}
              model={conditions}
              property="EmployerId"
              widthAllocation="7,5"
              colspan="2"
            />
            <UIText
              label={i18n.PostalCode}
              model={conditions}
              property="PostCode"
              widthAllocation="7,5"
              colspan="2"
            />
            <UIText
              label={i18n.PtyGroupID}
              model={conditions}
              property="GroupId"
              widthAllocation="7,5"
              colspan="2"
            />
          </UISmartPanelGrid>
          <UIBox direction="left">
            <UIButton
              value={i18n.Reset}
              onClick={_self.resetHandler}
            />
            <UIButton
              value={i18n.SEARCH}
              onClick={_self.searchHandler}
              update="ptyConditionRenderTable"
            />
            <UIButton
              value={i18n.Create}
              onClick={_self.createHandler}
              visibled={showCreatePartyButton}
            />
          </UIBox>
        </UIPanel>
      </div>
    );
  },
  orgPartyConditionRender() {
    const _self = this;
    const {
      conditions,
      fuzzyConditions,
      partyTypeCodeTable,
      partyCategoryEnableFlag,
      isVisibled,
      isClaimant,
    } = this.state;
    let rolType = _self.props.roleType;
    const { claimFlag } = this.props;
    let claimOnly = <UICell colspan="2" />;
    if (claimFlag == true) {
      claimOnly = (
        <UISelect
          label="Party Role"
          codeTable={DefinedCodeTable.getPartyRoleCodeTable}
          onChange={_self.roleTypeChange}
          model={fuzzyConditions}
          property="RoleTypeCode"
          defaultValue={rolType}
          showBlankOption="true"
          widthAllocation="7,5"
          colspan="2"
        />
      );
    }

    if (claimFlag == true) {
      if (isClaimant === true) {
        claimOnly = (
          <UISelect
            label="Party Role"
            codeTable={DefinedCodeTable.getPartyRoleCodeTable}
            onChange={_self.roleTypeChange}
            model={fuzzyConditions}
            enabled="false"
            property="RoleTypeCode"
            defaultValue={rolType}
            showBlankOption="true"
            widthAllocation="7,5"
            colspan="2"
          />
        );
      } else {
        claimOnly = (
          <UISelect
            label="Party Role"
            codeTable={DefinedCodeTable.getPartyRoleCodeTable}
            onChange={_self.roleTypeChange}
            model={fuzzyConditions}
            property="RoleTypeCode"
            defaultValue={rolType}
            showBlankOption="true"
            widthAllocation="7,5"
            colspan="2"
          />
        );
      }
    }

    if (fuzzyConditions.RoleTypeCode) {
      rolType = fuzzyConditions.RoleTypeCode;
    }
    console.log('conditions', conditions);
    return (
      <div>
        <UIPanel panelTitle={i18n.PartySearchCriteria}>
          <UISmartPanelGrid column="4" widthAllocation="7,5">
            <UISelect
              label={i18n.PartyCategory}
              codeTable={partyTypeCodeTable}
              onChange={this.partyCategoryChange}
              enabled={partyCategoryEnableFlag}
              model={_self.state.definedCondition}
              property="PartyType"
              showBlankOption="false"
              colspan="2"
            />
            {claimOnly}
            <UIText
              label={i18n.PartyName}
              model={conditions}
              property="PartyName"
            />
            <UICheckbox
              single="true"
              label={i18n.FuzzySearch}
              model={conditions}
              property="FuzzySearch"
              defaultValue="Y"
            />
            <UICell colspan="2" />
            <UIText
              label={i18n.PartyFormerCode}
              model={conditions}
              property="PartyCode"
              colspan="2"
            />
            <UIText
              label={i18n.CompanyNo}
              model={conditions}
              property="OrganizationIdNumber"
              colspan="2"
            />
            <UIText
              label={i18n.MobileWorkNo}
              model={conditions}
              property="MobileWorkTel"
              colspan="2"
            />
            <UIText
              label={i18n.SSSEmployerId}
              model={conditions}
              property="EmployerId"
              colspan="2"
            />
            <UIText
              label={i18n.PostalCode}
              model={conditions}
              property="PostCode"
              colspan="2"
            />
            <UIText
              label={i18n.PtyGroupID}
              model={conditions}
              property="GroupId"
              widthAllocation="7,5"
              colspan="2"
            />
          </UISmartPanelGrid>
          <UIBox direction="left">
            <UIButton
              value={i18n.Reset}
              onClick={this.resetHandler}
            />
            <UIButton
              value={i18n.SEARCH}
              onClick={this.searchHandler}
              update="ptyConditionRenderTable"
            />
            <UIButton
              value={i18n.Create}
              onClick={_self.createHandler}
              visibled={isVisibled}
            />
          </UIBox>
        </UIPanel>
      </div>
    );
  },

  venPartyConditionRender() {
    const _self = this;
    const {
      conditions,
      fuzzyConditions,
      partyTypeCodeTable,
      partyCategoryEnableFlag,
      isVisibled,
    } = this.state;
    let rolType = _self.props.roleType;
    const { claimFlag } = this.props;
    let claimOnly = <UICell colspan="2" />;
    if (claimFlag == true) {
      claimOnly = (
        <UISelect
          label="Party Role"
          codeTable={DefinedCodeTable.getPartyRoleCodeTable}
          onChange={_self.roleTypeChange}
          model={fuzzyConditions}
          property="RoleTypeCode"
          defaultValue={rolType}
          showBlankOption="true"
          widthAllocation="7,5"
          colspan="2"
        />
      );
    }
    if (fuzzyConditions.RoleTypeCode) {
      rolType = fuzzyConditions.RoleTypeCode;
    }
    console.log('conditions', conditions);
    return (
      <div>
        <UIPanel panelTitle={i18n.PartySearchCriteria}>
          <UISmartPanelGrid column="4" widthAllocation="7,5">
            <UISelect
              label={i18n.PartyCategory}
              codeTable={partyTypeCodeTable}
              onChange={this.partyCategoryChange}
              enabled={partyCategoryEnableFlag}
              model={_self.state.definedCondition}
              property="PartyType"
              showBlankOption="false"
              colspan="2"
            />
            {claimOnly}
            <UIText
              label={i18n.PartyName}
              model={conditions}
              property="PartyName"
            />
            <UICheckbox
              single="true"
              label={i18n.FuzzySearch}
              model={conditions}
              property="FuzzySearch"
              defaultValue="Y"
            />
            <UICell colspan="2" />
            <UIText
              label={i18n.PartyFormerCode}
              model={conditions}
              property="PartyCode"
              colspan="2"
            />
            {/* <UIText label={i18n.CompanyNo} model={conditions} property="OrganizationIdNumber" colspan="2"/> */}
            <UIText
              label={i18n.MobileWorkNo}
              model={conditions}
              property="MobileWorkTel"
              colspan="2"
            />
            <UIText
              label={i18n.SSSEmployerId}
              model={conditions}
              property="EmployerId"
              colspan="2"
            />
            <UIText
              label={i18n.PostalCode}
              model={conditions}
              property="PostCode"
              colspan="2"
            />
            <UIText
              label={i18n.PtyGroupID}
              model={conditions}
              property="GroupId"
              widthAllocation="7,5"
              colspan="2"
            />
          </UISmartPanelGrid>
          <UIBox direction="left">
            <UIButton
              value={i18n.Reset}
              onClick={this.resetHandler}
            />
            <UIButton
              value={i18n.SEARCH}
              onClick={this.searchHandler}
              update="ptyConditionRenderTable"
            />
          </UIBox>
        </UIPanel>
      </div>
    );
  },

  renderIndividualSearchResult() {
    const _self = this;
    return (
      <UIPanel panelTitle={i18n.PartyResult}>
        <UIDataTable
          provider={_self.getIndPartyResult}
          selectionMode="single"
          indexable="false"
          id="PartyRecord"
        >
          <UIColumn headerTitle={i18n.PartyCode} value="PartyCode" />
          <UIColumn
            headerTitle={i18n.FormarPartyCode}
            value="FormarPartyCode"
          />
          <UIColumn
            headerTitle={i18n.PartyName}
            render={data => {
              return (
                <div>
                  <UILink
                    value={data.PartyName}
                    onClick={_self.queryHandler}
                  >
                    <UIParam name="data" value={data} />
                  </UILink>
                </div>
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.IdType}
            value="IdType"
            render={data => {
              return (
                <UISelect
                  io="out"
                  model={data}
                  property="IdType"
                  codeTable={DefinedCodeTable.IdType}
                />
              );
            }}
          />
          <UIColumn headerTitle={i18n.IdNo} value="IdNumber" />
          <UIColumn headerTitle={i18n.Gender} value="Gender">
            <UISelect
              codeTable={DefinedCodeTable.getGenderCodeTable()}
              io="out"
            />
          </UIColumn>
          <UIColumn
            headerTitle={i18n.PartyRoleStatus}
            render={data => {
              console.log('PartyRoleStatus', data);
              if (
                PartyConst.PARTY_ROLE_NAME_PRODUCER ===
                _self.state.RoleName
              ) {
                return (
                  <UISelect
                    codeTable={
                      DefinedCodeTable.getPartyRoleProducerStatus
                    }
                    io="out"
                    value={data.PartyRoleStatus}
                  />
                );
              }
              return (
                <UISelect
                  codeTable={DefinedCodeTable.getPartyStatus}
                  io="out"
                  value={data.PartyRoleStatus}
                />
              );
            }}
          />
        </UIDataTable>
      </UIPanel>
    );
  },

  renderOrgSearchResult() {
    const _self = this;
    return (
      <UIPanel panelTitle={i18n.PartyResult}>
        <UIDataTable
          provider={_self.getOrgPartyResult}
          selectionMode="single"
          indexable="false"
          id="PartyRecord"
        >
          <UIColumn headerTitle={i18n.PartyCode} value="PartyCode" />
          <UIColumn
            headerTitle={i18n.FormarPartyCode}
            value="FormarPartyCode"
          />
          <UIColumn
            headerTitle={i18n.PartyName}
            render={data => {
              return (
                <div>
                  <UILink
                    value={data.PartyName}
                    onClick={_self.queryHandler}
                  >
                    <UIParam name="data" value={data} />
                  </UILink>
                </div>
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.CompanyNo}
            value="OrganizationIdNumber"
          />
          <UIColumn
            headerTitle={i18n.CountryDom}
            render={data => {
              return (
                <UISelect
                  model={data}
                  property="Country"
                  codeTable={DefinedCodeTable.getCountry}
                  io="out"
                />
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.PartyRoleStatus}
            render={data => {
              console.log('PartyRoleStatus', data);
              if (
                PartyConst.PARTY_ROLE_NAME_PRODUCER ===
                _self.state.RoleName
              ) {
                return (
                  <UISelect
                    codeTable={
                      DefinedCodeTable.getPartyRoleProducerStatus
                    }
                    io="out"
                    value={data.PartyRoleStatus}
                  />
                );
              }
              return (
                <UISelect
                  codeTable={DefinedCodeTable.getPartyStatus}
                  io="out"
                  value={data.PartyRoleStatus}
                />
              );
            }}
          />
        </UIDataTable>
      </UIPanel>
    );
  },

  renderVenSearchResult() {
    const _self = this;
    return (
      <UIPanel panelTitle={i18n.PartyResult}>
        <UIDataTable
          provider={_self.getVenPartyResult}
          selectionMode="single"
          indexable="false"
          id="PartyRecord"
        >
          <UIColumn headerTitle={i18n.PartyCode} value="PartyCode" />
          <UIColumn
            headerTitle={i18n.FormarPartyCode}
            value="FormarPartyCode"
          />
          <UIColumn
            headerTitle={i18n.PartyName}
            render={data => {
              return (
                <div>
                  <UILink
                    value={data.PartyName}
                    onClick={_self.queryHandler}
                  >
                    <UIParam name="data" value={data} />
                  </UILink>
                </div>
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.CountryDom}
            render={data => {
              return (
                <UISelect
                  model={data}
                  property="Country"
                  codeTable={DefinedCodeTable.getCountry}
                  io="out"
                />
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.PartyRoleStatus}
            render={data => {
              console.log('PartyRoleStatus', data.PartyRoleStatus);
              if (
                PartyConst.PARTY_ROLE_NAME_PRODUCER ===
                _self.state.RoleName
              ) {
                return (
                  <UISelect
                    codeTable={
                      DefinedCodeTable.getPartyRoleProducerStatus
                    }
                    io="out"
                    value={data.PartyRoleStatus}
                  />
                );
              }
              return (
                <UISelect
                  codeTable={DefinedCodeTable.getPartyStatus}
                  io="out"
                  value={data.PartyRoleStatus}
                />
              );
            }}
          />
        </UIDataTable>
      </UIPanel>
    );
  },

  getOrgPartyResult(temppageindex, temppagesize) {
    let pageindex = temppageindex;
    let pagesize = temppagesize;
    const { isSearch } = this.state;
    const roleName = this.state.RoleName;
    if (!isSearch) {
      return { count: 0, result: [] };
    }
    const partyAction = new PartyAction();
    const partyName = this.state.conditions.PartyName;
    const fuzzySearch = this.state.conditions.FuzzySearch;
    const mobileWorkTel = this.state.conditions.MobileWorkTel;
    const { conditions } = this.state;
    const { fuzzyConditions } = this.state;
    const orConditions = this.state.orCondtions;
    if (conditions.FuzzySearch === 'Y') {
      fuzzyConditions.PartyName = conditions.PartyName;
      delete conditions.PartyName;
    }
    if (conditions.MobileWorkTel) {
      orConditions.WorkTel = conditions.MobileWorkTel;
      orConditions.MobileTel = conditions.MobileWorkTel;
      delete conditions.MobileWorkTel;
    }
    delete conditions.FuzzySearch;
    delete fuzzyConditions.RoleTypeCode;
    const { isVisibled } = this.state;
    const { isSearchRole } = this.state;
    if ((!isVisibled && isVisibled) || isSearchRole) {
      fuzzyConditions.RoleType =
        PartyConst.RoleTypeOrgnizationMap[roleName];
      if (conditions.RoleType) {
        conditions.RoleType =
          PartyConst.RoleTypeOrgActiveMap[conditions.RoleType];
      }
    }
    const partyType = this.state.definedCondition.PartyType;
    if (!pageindex || !pagesize) {
      pageindex = 1;
      pagesize = 10;
    }
    const result = partyAction.searchPartyByConditions(
      conditions,
      orConditions,
      fuzzyConditions,
      partyType,
      pageindex,
      pagesize
    );
    conditions.PartyName = partyName;
    conditions.FuzzySearch = fuzzySearch;
    conditions.MobileWorkTel = mobileWorkTel;
    if (result) {
      const partyList = this.getPartyList(result.result);
      return { count: result.count, result: partyList };
    }
    return { count: 0, result: [] };
  },
  getVenPartyResult(temppageindex, temppagesize) {
    let pageindex = temppageindex;
    let pagesize = temppagesize;
    const { isSearch } = this.state;
    const roleName = this.state.RoleName;
    if (!isSearch) {
      return { count: 0, result: [] };
    }
    const partyAction = new PartyAction();
    const partyName = this.state.conditions.PartyName;
    const fuzzySearch = this.state.conditions.FuzzySearch;
    const mobileWorkTel = this.state.conditions.MobileWorkTel;
    const { conditions } = this.state;
    const { fuzzyConditions } = this.state;
    const orConditions = this.state.orCondtions;
    if (conditions.FuzzySearch === 'Y') {
      fuzzyConditions.PartyName = conditions.PartyName;
      delete conditions.PartyName;
    }
    if (conditions.MobileWorkTel) {
      orConditions.WorkTel = conditions.MobileWorkTel;
      orConditions.MobileTel = conditions.MobileWorkTel;
      delete conditions.MobileWorkTel;
    }
    delete conditions.FuzzySearch;
    delete fuzzyConditions.RoleTypeCode;
    const { isVisibled } = this.state;
    const { isSearchRole } = this.state;
    if ((!isVisibled && isVisibled) || isSearchRole) {
      fuzzyConditions.RoleType =
        PartyConst.RoleTypeOrgnizationMap[roleName];
      if (conditions.RoleType) {
        conditions.RoleType =
          PartyConst.RoleTypeOrgActiveMap[conditions.RoleType];
      }
    }
    const partyType = this.state.definedCondition.PartyType;
    if (!pageindex || !pagesize) {
      pageindex = 1;
      pagesize = 10;
    }
    const result = partyAction.searchPartyByConditions(
      conditions,
      orConditions,
      fuzzyConditions,
      partyType,
      pageindex,
      pagesize
    );
    conditions.PartyName = partyName;
    conditions.FuzzySearch = fuzzySearch;
    conditions.MobileWorkTel = mobileWorkTel;
    if (result) {
      const partyList = this.getPartyList(result.result);
      return { count: result.count, result: partyList };
    }
    return { count: 0, result: [] };
  },
  getIndPartyResult(pageindex, pagesize) {
    const { isSearch } = this.state;
    const roleName = this.state.RoleName;
    if (!isSearch) {
      return { count: 0, result: [] };
    }
    const partyAction = new PartyAction();
    const partyName = this.state.conditions.PartyName;
    const fuzzySearch = this.state.conditions.FuzzySearch;
    const workHomeMobileTel = this.state.conditions.WorkHomeMobileTel;
    const { conditions } = this.state;
    const { fuzzyConditions } = this.state;
    const orConditions = this.state.orCondtions;
    if (conditions.FuzzySearch === 'Y') {
      fuzzyConditions.PartyName = conditions.PartyName;
      delete conditions.PartyName;
    }
    if (conditions.WorkHomeMobileTel) {
      orConditions.WorkHomeTel = conditions.WorkHomeMobileTel;
      orConditions.MobileTel = conditions.WorkHomeMobileTel;
      delete conditions.WorkHomeMobileTel;
    }
    delete conditions.FuzzySearch;
    delete fuzzyConditions.RoleTypeCode;

    const { isVisibled } = this.state;
    const { isSearchRole } = this.state;
    if ((!isVisibled && isVisibled) || isSearchRole) {
      fuzzyConditions.RoleType =
        PartyConst.RoleTypeIndividualMap[roleName];
      if (conditions.RoleType) {
        conditions.RoleType =
          PartyConst.RoleTypeIndActiveMap[conditions.RoleType];
      }
    }
    const partyType = this.state.definedCondition.PartyType;
    const result = partyAction.searchPartyByConditions(
      conditions,
      orConditions,
      fuzzyConditions,
      partyType,
      pageindex,
      pagesize
    );
    conditions.PartyName = partyName;
    conditions.FuzzySearch = fuzzySearch;
    conditions.WorkHomeMobileTel = workHomeMobileTel;
    if (result) {
      const partyList = this.getPartyList(result.result);
      return { count: result.count, result: partyList };
    }
    return { count: 0, result: [] };
  },

  getPartyList(data) {
    const roleName = this.state.RoleName;
    const partyList = data;
    _.each(partyList, temppartyVo => {
      const partyVo = temppartyVo;
      let partyRoleStatus;
      if (partyVo.PartyRoleVoList) {
        _.each(partyVo.PartyRoleVoList, partyRole => {
          if (
            partyRole.RoleName == roleName &&
            PartyConst.PARTY_ROLE_CODE_IND_CUSTOMER ==
            partyRole.RoleType
          ) {
            partyRoleStatus = partyRole.Status;
          } else if (
            partyRole.RoleName == roleName &&
            PartyConst.PARTY_ROLE_CODE_ORG_CUSTOMER ==
            partyRole.RoleType
          ) {
            partyRoleStatus = partyRole.Status;
          } else if (
            partyRole.RoleName == roleName &&
            PartyConst.PARTY_ROLE_CODE_IND_INTRODUCER ==
            partyRole.RoleType
          ) {
            partyRoleStatus = partyRole.Status;
          } else if (
            partyRole.RoleName == roleName &&
            PartyConst.PARTY_ROLE_CODE_ORG_INTRODUCER ==
            partyRole.RoleType
          ) {
            partyRoleStatus = partyRole.Status;
          } else if (
            partyRole.RoleName == roleName &&
            PartyConst.PARTY_ROLE_CODE_IND_RISKASSESSOR ==
            partyRole.RoleType
          ) {
            partyRoleStatus = partyRole.Status;
          } else if (
            partyRole.RoleName == roleName &&
            PartyConst.PARTY_ROLE_CODE_ORG_RISKASSESSOR ==
            partyRole.RoleType
          ) {
            partyRoleStatus = partyRole.Status;
          } else if (
            partyRole.RoleName == roleName &&
            PartyConst.PARTY_ROLE_CODE_IND_PAYEEPAYER ==
            partyRole.RoleType
          ) {
            partyRoleStatus = partyRole.Status;
          } else if (
            partyRole.RoleName == roleName &&
            PartyConst.PARTY_ROLE_CODE_ORG_PAYEEPAYER ==
            partyRole.RoleType
          ) {
            partyRoleStatus = partyRole.Status;
          } else if (
            partyRole.RoleName == roleName &&
            PartyConst.PARTY_ROLE_CODE_IND_CLAIMANT ==
            partyRole.RoleType
          ) {
            partyRoleStatus = partyRole.Status;
          } else if (
            partyRole.RoleName == roleName &&
            PartyConst.PARTY_ROLE_CODE_ORG_CLAIMANT ==
            partyRole.RoleType
          ) {
            partyRoleStatus = partyRole.Status;
          } else if (
            partyRole.RoleName == roleName &&
            PartyConst.PARTY_ROLE_CODE_IND_GARAGE ==
            partyRole.RoleType
          ) {
            partyRoleStatus = partyRole.Status;
          } else if (
            partyRole.RoleName == roleName &&
            PartyConst.PARTY_ROLE_CODE_ORG_GARAGE ==
            partyRole.RoleType
          ) {
            partyRoleStatus = partyRole.Status;
          } else if (
            partyRole.RoleName == roleName &&
            PartyConst.PARTY_ROLE_CODE_IND_PRODUCER ==
            partyRole.RoleType
          ) {
            partyRoleStatus = partyRole.Status;
          } else if (
            partyRole.RoleName == roleName &&
            PartyConst.PARTY_ROLE_CODE_ORG_PRODUCER ==
            partyRole.RoleType
          ) {
            partyRoleStatus = partyRole.Status;
          } else if (
            partyRole.RoleName == roleName &&
            PartyConst.PARTY_ROLE_CODE_ORG_RECOVER_FROM ==
            partyRole.RoleType
          ) {
            partyRoleStatus = partyRole.Status;
          } else if (
            partyRole.RoleName == roleName &&
            PartyConst.PARTY_ROLE_CODE_IND_RECOVER_FROM ==
            partyRole.RoleType
          ) {
            partyRoleStatus = partyRole.Status;
          } else if (
            partyRole.RoleName == roleName &&
            PartyConst.PARTY_ROLE_CODE_IND_DRIVER ==
            partyRole.RoleType
          ) {
            partyRoleStatus = partyRole.Status;
          } else if (
            partyRole.RoleName == roleName &&
            PartyConst.PARTY_ROLE_CODE_IND_PRODUCER_SUPPORT_OFFICER ==
            partyRole.RoleType
          ) {
            partyRoleStatus = partyRole.Status;
          } else if (
            partyRole.RoleName == roleName &&
            PartyConst.PARTY_ROLE_CODE_ORG_PRODUCER_SUPPORT_OFFICER ==
            partyRole.RoleType
          ) {
            partyRoleStatus = partyRole.Status;
          } else if (
            partyRole.RoleName == roleName &&
            PartyConst.PARTY_ROLE_CODE_IND_CLAIMANT_INVESTIGATOR ==
            partyRole.RoleType
          ) {
            partyRoleStatus = partyRole.Status;
          } else if (
            partyRole.RoleName == roleName &&
            PartyConst.PARTY_ROLE_CODE_ORG_CLAIMANT_INVESTIGATOR ==
            partyRole.RoleType
          ) {
            partyRoleStatus = partyRole.Status;
          }
        });
      }
      partyVo.PartyRoleStatus = partyRoleStatus;
    });
    return partyList;
  },
});
export default PtyIntegrationSearchInfo;
