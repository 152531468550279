import CodeTables from '../js/CodeTable';

const EU00CommonService = {
  extendWindowEU00Fn() {
    if (!window.EU00) {
      window.EU00 = {};
    }

    window.EU00.getCurrencySignByCurrencyCode = this.getCurrencySignByCurrencyCode;
  },

  getCurrencySignByCurrencyCode(currencyCode) {
    let CurrencyData = sessionStorage.getItem('CurrencyData');
    let currencySign = null;
    if (
      !CurrencyData ||
      !CurrencyData.codes ||
      CurrencyData.codes.length <= 0
    ) {
      CurrencyData = CodeTables.getCurrencySign();
      sessionStorage.setItem(
        'CurrencyData',
        JSON.stringify(CurrencyData)
      );
    } else {
      CurrencyData = JSON.parse(CurrencyData);
    }

    for (let i = 0; i < CurrencyData.codes.length; i += 1) {
      if (currencyCode == CurrencyData.codes[i].id) {
        currencySign = CurrencyData.codes[i].text;
        break;
      }
    }
    return currencySign;
  },
};

export default EU00CommonService;
