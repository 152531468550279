import {
  UIText,
  UIParam,
  UILink,
  UISelect,
  UIDataTable,
  UIColumn,
  UIPanel,
} from 'RainbowUI';
import EditIndRelationShipDialogReadOnly from './EditIndRelationShipDialogReadOnly';
import EditOrgRelationShipDialogReadOnly from './EditOrgRelationShipDialogReadOnly';
import DefinedCodeTable from '../../../codetable/CodeTable';
import PartyConst from '../../../const/PartyConst';
import PartyAction from '../../../actions/PartyAction';

const _ = require('underscore');

const PartyIndRelationShipInfo = React.createClass({
  getInitialState() {
    const { party } = this.props;
    let { PartyRelationShipList } = party;
    if (!PartyRelationShipList) {
      PartyRelationShipList = [];
    }
    _.forEach(
      PartyRelationShipList,
      (tempPartyRelationShip, index) => {
        const partyRelationShip = tempPartyRelationShip;
        partyRelationShip.DefinedIndex = index + 1;
      }
    );
    const totalNum = PartyRelationShipList.length;
    return {
      PartyRelationShipList,
      TotalNum: totalNum,
      party: this.props.party,
      readOnly: false,
    };
  },

  viewHandler(clickEvent) {
    let partyRelationShip = {};
    if (!_.isEmpty(clickEvent.paramJson)) {
      partyRelationShip = clickEvent.paramJson[0].value;
    }
    this.refs.editPartyRelationShipDialogReadOnly.showDialog(
      partyRelationShip
    );
  },

  render() {
    const _self = this;
    const { party, PartyRelationShipList } = this.state;
    const partyType = party.PartyType;
    let dialog;
    if (PartyConst.PARTY_TYPE_IND == partyType) {
      dialog = _self.renderIndDialog();
    } else dialog = _self.renderOrgDialog();
    return (
      <div>
        <UIPanel panelTitle={i18n.RelationShip}>
          <UIDataTable
            id="partyRelationShipInfo"
            value={PartyRelationShipList}
            indexable="false"
          >
            <UIColumn
              headerTitle={i18n.RelationshipType}
              render={data => {
                return (
                  <UISelect
                    model={data}
                    property="RelationShipType"
                    codeTable={DefinedCodeTable.getAllRelationShipType()}
                    io="out"
                  />
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.PartyName}
              render={data => {
                const partyAction = new PartyAction();
                const partyName = partyAction.getPartyCodeTable(
                  data.RelationShipPartyId
                ).text;
                return (
                  <div>
                    <UILink
                      value={partyName}
                      onClick={this.viewHandler}
                    >
                      <UIParam name="data" value={data} />
                    </UILink>
                  </div>
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.IdOrCompanyRegNumber}
              render={data => {
                return (
                  <UIText model={data} property="IdNumber" io="out" />
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.Remarks}
              render={data => {
                return (
                  <UIText model={data} property="Remark" io="out" />
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.Status}
              render={data => {
                return (
                  <UISelect
                    model={data}
                    property="Status"
                    codeTable={DefinedCodeTable.getPartyStatus}
                    io="out"
                  />
                );
              }}
            />
          </UIDataTable>
          {dialog}
        </UIPanel>
      </div>
    );
  },

  setPartyRelationShip(partyRelationShip) {
    this.refs.editPartyRelationShipDialogReadOnly.showDialog(
      partyRelationShip
    );
  },

  renderIndDialog() {
    return (
      <div>
        <EditIndRelationShipDialogReadOnly ref="editPartyRelationShipDialogReadOnly" />
      </div>
    );
  },

  renderOrgDialog() {
    return (
      <div>
        <EditOrgRelationShipDialogReadOnly ref="editPartyRelationShipDialogReadOnly" />
      </div>
    );
  },
});
export default PartyIndRelationShipInfo;
