import { AjaxUtil, MessageHelper } from 'RainbowUI';
import Url from '../url/Url';

export default {
  saveRegistration(caseForm, isSubmit) {
    const deferred = $.Deferred();
    let url = Url.RegistrationUpdate;
    if (isSubmit) {
      url = Url.RegistrationSubmit;
    }
    AjaxUtil.doPost(url, caseForm, {
      async: false,
      done(data) {
        deferred.resolve(data);
      },
      fail(err) {
        // add codes here as error model is changed
        if (err.responseJSON.message) {
          MessageHelper.error(err.responseJSON.message);
        } else if (
          err.responseJSON.Messages &&
          err.responseJSON.Messages.length > 2
        ) {
          MessageHelper.error(err.responseJSON.Messages[2].Message);
        } else if (err.responseJSON.Messages) {
          MessageHelper.error(err.responseJSON.Messages[0].Message);
        }
      },
    });
    return deferred.promise();
  },

  checkSubClaimType(caseForm) {
    const deferred = $.Deferred();
    AjaxUtil.doPost(Url.CheckSubClaimType, caseForm, {
      async: false,
      done(data) {
        deferred.resolve(data);
      },
      fail(err) {
        deferred.reject(err);
      },
    });
    return deferred.promise();
  },
  getActiveCode() {
    const deferred = $.Deferred();
    AjaxUtil.doGet(
      `${Url.ClaimCatastropheActiveCode}/Y/Code`,
      {},
      {
        async: false,
        done(data) {
          deferred.resolve(data);
        },
        fail(err) {
          deferred.reject(err);
        },
      }
    );
    return deferred.promise();
  },
  saveLastReviewClaim(caseId) {
    const deferred = $.Deferred();
    AjaxUtil.doGet(
      Url.SaveLastReviewClaim + caseId,
      {},
      {
        async: false,
        done(data) {
          deferred.resolve(data);
        },
        fail(err) {
          deferred.reject(err);
        },
      }
    );
    return deferred.promise();
  },
};
