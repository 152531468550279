const EU00CommonMath = {
  extendWindowMathFn() {
    Math.accAdd = this.accAdd;
    Math.accSub = this.accSub;
    Math.accMul = this.accMul;
    Math.accDiv = this.accDiv;
  },

  accAdd(num1, num2) {
    let r1;
    let r2;
    try {
      r1 = num1.toString().split('.')[1].length;
    } catch (e) {
      r1 = 0;
    }
    try {
      r2 = num2.toString().split('.')[1].length;
    } catch (e) {
      r2 = 0;
    }
    const m = Math.pow(10, Math.max(r1, r2));
    // return (num1*m+num2*m)/m;
    return Number((Math.round(num1 * m + num2 * m) / m).toFixed(2));
  },

  accSub(num1, num2) {
    let r1;
    let r2;
    try {
      r1 = num1.toString().split('.')[1].length;
    } catch (e) {
      r1 = 0;
    }
    try {
      r2 = num2.toString().split('.')[1].length;
    } catch (e) {
      r2 = 0;
    }
    const m = Math.pow(10, Math.max(r1, r2));
    return Number((Math.round(num1 * m - num2 * m) / m).toFixed(2));
  },

  accMul(num1, num2) {
    let m = 0;
    const s1 = num1.toString();
    const s2 = num2.toString();
    try {
      m += s1.split('.')[1].length;
    } catch (e) {
      // continue regardless of error
    }
    try {
      m += s2.split('.')[1].length;
    } catch (e) {
      // continue regardless of error
    }
    return Number(
      (
        (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) /
        Math.pow(10, m)
      ).toFixed(2)
    );
  },

  accDiv(num1, num2) {
    let t1;
    let t2;
    try {
      t1 = num1.toString().split('.')[1].length;
    } catch (e) {
      t1 = 0;
    }
    try {
      t2 = num2.toString().split('.')[1].length;
    } catch (e) {
      t2 = 0;
    }
    const r1 = Number(num1.toString().replace('.', ''));
    const r2 = Number(num2.toString().replace('.', ''));
    return Number(((r1 / r2) * Math.pow(10, t2 - t1)).toFixed(2));
  },
};
export default EU00CommonMath;
