import {
  UIDataTable,
  UIColumn,
  UIDateTimePicker,
  UILink,
  UIParam,
  UICurrency,
  UIText,
} from 'RainbowUI';
import PropTypes from 'prop-types';
import { CodeTableAction, CodeTableCstUI } from 'ClaimCommonConstant';

const CalculationHistoryStore = require('../store/CalculationHistoryStore');
const CalculationLoadAction = require('../action/CalculationLoadAction');
const CalculationReadOnlyDialog = require('./CalculationReadOnlyDialog');

const CalculationHistory = React.createClass({
  propTypes: {
    caseId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  },

  getInitialState() {
    return { CalculationHistory: [] };
  },

  componentWillMount() {
    CalculationHistoryStore.listen(this.onStoreStateChange);
    CalculationLoadAction.loadCalculationHistory(this.props.caseId);
  },

  componentWillUnmount() {
    CalculationHistoryStore.unlisten(this.onStoreStateChange);
  },
  onStoreStateChange(storeState) {
    if (!_.isEmpty(storeState.CalculationHistory)) {
      this.setState({
        CalculationHistory: storeState.CalculationHistory,
      });
    }
  },

  render() {
    const _self = this;
    const { caseId } = this.props;
    const surffix = `calculationHis_${caseId}_`;
    const nameCode = CodeTableAction.loadDataList(
      CodeTableCstUI.ClaimUser,
      null
    );
    let i = 1;
    return (
      <div>
        <div>
          <UIDataTable
            id={`${surffix}dataTable`}
            value={_self.state.CalculationHistory}
            indexable="false"
            isHeading="true"
            pageable="false"
            headerTitle={i18n.ClaimCalculation.CalculationHistory}
          >
            <UIColumn
              headerTitle={i18n.ClaimCalculation.SeqNo}
              width="20%"
              render={data => {
                const temp = i;
                i += 1;
                return (
                  <UILink
                    value={temp}
                    onClick={CalculationLoadAction.showReadOnly}
                  >
                    <UIParam
                      name="calculateId"
                      value={data.CalculateId}
                    />
                  </UILink>
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.ClaimCalculation.TransactionDate}
              value="SubmitDate"
              width="20%"
              render={data => {
                return (
                  <UIDateTimePicker
                    id={`${surffix + data.CalculateId}_submitDate`}
                    placeHolder="MM/DD/YYYY HH:mm:ss"
                    format="MM/DD/YYYY HH:mm:ss"
                    value={data.SubmitDate}
                    io="out"
                  />
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.ClaimCalculation.IssuedBy}
              value="Submiter"
              width="20%"
              render={data => {
                const names = nameCode.codes;
                const submiter = data.Submiter;
                let name;
                for (let i = 0; i < names.length; i += 1) {
                  if (names[i].id == submiter) {
                    name = names[i].text;
                  }
                }
                return <UIText io="out" value={name} />;
              }}
            />
            <UIColumn
              headerTitle={i18n.ClaimCalculation.Approver}
              value="Approver"
              width="20%"
              render={data => {
                const names = nameCode.codes;
                const approver = data.Approver;
                let name;
                for (let i = 0; i < names.length; i += 1) {
                  if (names[i].id == approver) {
                    name = names[i].text;
                  }
                }
                return <UIText io="out" value={name} />;
              }}
            />
            <UIColumn
              headerTitle={i18n.ClaimCalculation.CalculationAmount}
              width="20%"
              render={data => {
                return (
                  <div style={{ float: 'left' }}>
                    <UICurrency
                      id={`CURRENCY_CODE_${surffix}${data.CalculateId}_totalAmount`}
                      value={
                        data.TotalIndemnityAmount +
                        data.TotalExpenseAmount
                      }
                      unit={window.EU00.getCurrencySignByCurrencyCode(
                        data.CaseCurrency
                      )}
                      io="out"
                    />
                  </div>
                );
              }}
            />
          </UIDataTable>
        </div>
        <div>
          <CalculationReadOnlyDialog />
        </div>
      </div>
    );
  },
});
export default CalculationHistory;
