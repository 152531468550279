import {
  UIDialog,
  UISmartPanelGrid,
  UIBox,
  UIButton,
  UIText,
  UITextarea,
} from 'RainbowUI';

import GroupIdAction from '../../actions/GroupIdAction';

const GroupIdDefineDialog = React.createClass({
  getInitialState() {
    const groupDefineInfo = {
      GroupId: null,
      GroupName: null,
      Description: null,
    };

    return {
      GroupDefineInfo: groupDefineInfo,
    };
  },

  showDialog() {
    const groupDefineInfo = {
      GroupId: null,
      GroupName: null,
      Description: null,
    };
    this.setState({ GroupDefineInfo: groupDefineInfo });
    UIDialog.show('GroupIdDefineDialog');
  },

  editDialog(groupDefined) {
    this.setState({ GroupDefineInfo: groupDefined });

    UIDialog.show('GroupIdDefineDialog');
  },

  hideDialog() {
    UIDialog.hide('GroupIdDefineDialog');
  },

  render() {
    const _self = this;
    return (
      <UIDialog
        id="GroupIdDefineDialog"
        width="80%"
        title={i18n.create}
      >
        <UISmartPanelGrid>
          <UIText
            label={i18n.PtyGroupID}
            required="true"
            disabled="true"
            model={_self.state.GroupDefineInfo}
            property="GroupId"
          />
          <UIText
            label={i18n.PtyGroupName}
            required="true"
            model={_self.state.GroupDefineInfo}
            property="GroupName"
            validationGroup="GroupDefineValidation"
          />
          <UITextarea
            label={i18n.Description}
            model={_self.state.GroupDefineInfo}
            property="Description"
            colspan="2"
          />
        </UISmartPanelGrid>
        <UIBox direction="center">
          <UIButton
            value={i18n.submit}
            onClick={_self.onClickSubmit}
            causeValidation="true"
            validationGroup="GroupDefineValidation"
          />
          <UIButton value={i18n.Cancel} onClick={this.hideDialog} />
        </UIBox>
      </UIDialog>
    );
  },

  onClickSubmit() {
    const groupDefineInfo = this.state.GroupDefineInfo;

    const groupIdAction = new GroupIdAction();
    groupIdAction.createOrUpdateGroupDefine(groupDefineInfo);
    this.props.onSearchHandler();
  },
});
export default GroupIdDefineDialog;
