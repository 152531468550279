import alt from '../alt';
import PolicyAction from '../action/PolicyAction';

class PolicyStore {
  constructor() {
    this.policySearchOptions = {};
    this.policyInfo = {};

    this.bindListeners({
      handleSearchPolicy: PolicyAction.SEARCH_POLICY,
      handleRetrievePolicy: PolicyAction.RETRIEVE_POLICY,
      handleRetrieve: PolicyAction.COPY_POLICY,
    });
  }

  handleRetrievePolicy(policyInfo) {
    console.log('============PolicyStore=policyInfo==', policyInfo);
    this.policyInfo = policyInfo;
  }

  handleRetrieve(policyInfo) {
    console.log('============PolicyStore=policyInfo==', policyInfo);
    this.policyInfo = policyInfo;
  }

  handleSearchPolicy(policySearchOptions) {
    console.log(
      '============PolicyStore=policySearchOptions==',
      policySearchOptions
    );
    this.policySearchOptions = policySearchOptions;
  }

  getPolicyInfo() {
    console.log('============PolicyStore=getPolicyInfo==');
  }
}

export default alt.createStore(PolicyStore, PolicyStore);
