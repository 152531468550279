import InvestigationAction from './module/fieldinvestigation/common/action/InvestigationAction';

const PropertyComponent = require('./module/fieldinvestigation/common/component/Property');
const VehicleComponent = require('./module/fieldinvestigation/common/component/Vehicle');
const BodilyInjuryComponent = require('./module/fieldinvestigation/common/component/BodilyInjury');

const ClaimFieldInvestigationExt = {
  PropertyComponent,
  VehicleComponent,
  BodilyInjuryComponent,
  InvestigationAction,
};

export default ClaimFieldInvestigationExt;
