/**
 * Created by jie.huang on 2019/4/23.
 */
import {
  UIText,
  UISmartPanelGrid,
  UIDateTimePicker,
  UIUpdatePanel,
  UIDialog,
} from 'RainbowUI';
import {
  PartyConst,
  PtyIntegrationSearchDialog,
  PartyAction,
  PtyIntegrationThirdParty,
} from 'EU00PartyCommonUI';
import moment from 'moment';

const partyAction = new PartyAction();

export default class FirstPartyDriverInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      claimEntity: props.claimEntity,
      io: props.io,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      claimEntity: nextProps.claimEntity,
    });
  }

  render() {
    const { claimEntity } = this.state;
    const _self = this;
    if (claimEntity.FpDriverId) {
      const party = partyAction.loadPartyByPartyId(
        claimEntity.FpDriverId
      );
      claimEntity.FpDriverName = party.PartyName;
      claimEntity.FpIdNumber = party.IdNumber;
    }
    if (claimEntity.FpDriverBirthDate) {
      claimEntity.Age = moment(claimEntity.AccidentTime).diff(
        moment(claimEntity.FpDriverBirthDate),
        'year'
      );
    } else {
      claimEntity.Age = null;
    }
    const ptyIntegrationSearchDialog =
      _self.props.forModify === 'true' ? (
        <div />
      ) : (
        <PtyIntegrationSearchDialog
          ref="PartySearchDialogForDriver_Ref"
          id="partySearchDialogForDriver"
          isSearch
          getSearchResult={this.getFirstPartyDriver.bind(this)}
          isVisibled={false}
          isSearchRole
          title={i18n.SearchDriver}
          createClicked={this.createNewFirstParty.bind(this)}
          RoleName={PartyConst.PARTY_ROLE_NAME_DRIVER}
          enableChangePartyCategory="N"
        />
      );
    const ptyIntegrationThirdParty =
      _self.props.forModify === 'true' ? (
        <div />
      ) : (
        <PtyIntegrationThirdParty
          id="fpDriverCreationDialogId"
          onClickSubmit={this.onClickSubmit.bind(this)}
          onClickBack={this.onClickBack.bind(this)}
        />
      );
    return (
      <UIUpdatePanel
        id={`firstPartyDriverInfoUpdatePanel_${_self.props.io}`}
      >
        <UISmartPanelGrid column="3">
          <UIText
            label={i18n.ClaimCommon.FPDriverName}
            io={_self.props.io}
            onSuffixIconClick={this.showPartySearchPanelDialog.bind(
              this
            )}
            model={claimEntity}
            property="FpDriverName"
            disabled="true"
            suffixIcon="fa fa-search"
          />
          <UIText
            label={i18n.ClaimCommon.FPDriverIdNumber}
            model={claimEntity}
            property="FpIdNumber"
            io="out"
          />
          <UIText
            label={i18n.ClaimCommon.FPDriverTel}
            model={claimEntity}
            property="FpTelNo"
            io={_self.props.io}
          />
          <UIDateTimePicker
            label={i18n.ClaimCommon.FPDateOfBirth}
            model={claimEntity}
            format="MM/DD/YYYY"
            io={_self.props.io}
            maxDate="TODAY"
            property="FpDriverBirthDate"
            onChange={this.changeBirthDate.bind(this)}
          />
          <UIText
            label={i18n.ClaimCommon.FPDriverAge}
            model={claimEntity}
            io="out"
            property="Age"
          />
          {ptyIntegrationSearchDialog}
          {ptyIntegrationThirdParty}
        </UISmartPanelGrid>
      </UIUpdatePanel>
    );
  }

  changeBirthDate() {
    const { claimEntity } = this.state;
    if (claimEntity.FpDriverBirthDate) {
      claimEntity.Age = moment(claimEntity.AccidentTime).diff(
        moment(claimEntity.FpDriverBirthDate),
        'year'
      );
    } else {
      claimEntity.Age = null;
    }
    this.setState({
      claimEntity,
    });
  }

  getFirstPartyDriver(party) {
    const { claimEntity } = this.state;
    claimEntity.FpDriverName = party.PartyName;
    claimEntity.FpDriverId = party.PartyId;
    claimEntity.FpIdNumber = party.IdNumber;
    claimEntity.FpTelNo = party.MobileTel;
    claimEntity.FpDriverBirthDate = party.DateOfBirth;
    this.setState({
      claimEntity,
    });
  }

  createNewFirstParty() {
    this.refs.PartySearchDialogForDriver_Ref.hide();
    UIDialog.show('fpDriverCreationDialogId');
  }

  onClickBack() {
    this.refs.PartySearchDialogForDriver_Ref.show();
  }

  onClickSubmit(party) {
    const { claimEntity } = this.state;
    claimEntity.FpDriverName = party.PartyName;
    claimEntity.FpDriverId = party.PartyId;
    claimEntity.FpIdNumber = party.IdNumber;
    claimEntity.FpTelNo = party.MobileTel;
    claimEntity.FpDriverBirthDate = party.DateOfBirth;
    this.setState({
      claimEntity,
    });
  }

  showPartySearchPanelDialog() {
    const _self = this;
    if (_self.props.forModify === 'true') {
      UIDialog.hide('ModifyClaimInfo_Dialog');
      UIDialog.show('partySearchDialogForFPDriverForModify');
    } else {
      this.refs.PartySearchDialogForDriver_Ref.show();
    }
  }
}
