import { UILayout, UILayoutUnit } from 'RainbowUI';
import ClaimCommonExt from 'ClaimCommonExt';
import ClaimPartyAction from '../action/ClaimPartyAction';

const ClaimPartyTreePanel = require('./ClaimPartyTreePanel');

const ClaimParty = React.createClass({
  getDefaultProps() {
    return { index: '0' };
  },

  componentWillMount() {
    ClaimPartyAction.getPartyAuth(this.props.caseId);
  },

  render() {
    const {
      index,
      caseId,
      partyAuth,
      fromWhere,
      showRadio,
      callback,
    } = this.props;
    const ClaimPartyRightPanel = ClaimCommonExt.getClaimPartyRightPanel();
    return (
      <UILayout
        headerable="false"
        id={`ClaimParty_UILayout_${index}`}
      >
        <UILayoutUnit
          position="west"
          size="300"
          resizable="true"
          id={`ClaimParty_left_${index}`}
        >
          <ClaimPartyTreePanel
            parentComponent={this}
            index={index}
            caseId={caseId}
            partyAuth={partyAuth}
          />
        </UILayoutUnit>
        <UILayoutUnit
          position="center"
          id={`ClaimParty_right_${index}`}
        >
          <ClaimPartyRightPanel
            fromWhere={fromWhere}
            ref="ClaimPartyRightPanel_Ref"
            showRadio={showRadio}
            index={index}
            callback={callback}
            caseId={caseId}
          />
        </UILayoutUnit>
      </UILayout>
    );
  },
});
export default ClaimParty;
