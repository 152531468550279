import { CodeTable, DataContext, CodeTableSorter } from 'RainbowUI';
import RemoteCodeTable from './RemoteCodeTable';
import CommonService from '../service/CommonService';

const _ = require('underscore');

export default class CommonCodeTable {
  getBranch() {
    return RemoteCodeTable.CodeTableIdFromCache(-103);
  }

  getPartialFinal() {
    return RemoteCodeTable.CodeTableId(1000);
  }

  getSettlementType() {
    return RemoteCodeTable.CodeTableIdFromCache(75800521);
  }

  getPaymentStatus() {
    return RemoteCodeTable.CodeTableIdFromCache(1044);
  }

  getCurrencyCode() {
    return RemoteCodeTable.CodeTableCodefromCache(-104);
  }

  getContactLanguage() {
    return RemoteCodeTable.CodeTableIdFromCache(1108002);
  }

  getAgentCode() {
    return RemoteCodeTable.AgentCodeCache(-105);
  }

  getCurrency() {
    return RemoteCodeTable.CodeTableIdFromCache(-104);
  }

  getUser() {
    return RemoteCodeTable.CodeTableCodefromCache(600026);
  }

  getEndoType() {
    return RemoteCodeTable.EndoTypeCodeTableIdFromCache(600029);
  }

  getEndoTypeBCP() {
    return RemoteCodeTable.EndoTypeCodeTableIdFromCache(-322);
  }

  getARAPEndoType() {
    return RemoteCodeTable.CodeTableIdFromCache(600029);
  }

  getDocumentType() {
    return new CodeTable([
      { id: '1', text: 'PolicySchedule' },
      { id: '2', text: 'DebitOrCreditNote' },
      { id: '3', text: 'CoverNote' },
      { id: '4', text: 'EndorsementSchedule' },
      { id: '5', text: 'QuotationForm' },
      { id: '6', text: 'Renewal Notice' },
    ]);
  }

  getPrintStatus() {
    return new CodeTable([
      { id: 1, text: 'Generating' },
      { id: 2, text: 'Success' },
      { id: 3, text: 'Fail' },
    ]);
  }

  getProductNameCodeList() {
    const codeList = [];
    let data = DataContext.get('outputProductCodeNameList');
    if (!data) {
      data = CommonService.loadProductList();
      DataContext.put('outputProductCodeNameList', data);
    }
    const productNameList = [];
    for (let i = 0; i < data.length; i += 1) {
      if (
        !_.contains(
          productNameList,
          data[i].ProductMaster.ProductCode
        ) &&
        data[i].EffectiveFlag === 'Y'
      ) {
        productNameList.push(data[i].ProductMaster.ProductCode);
        codeList.push({
          id: data[i].ProductId,
          text: `${data[i].ProductMaster.ProductCode}-${data[i].ProductMaster.ProductName}`,
        });
      }
    }
    return new CodeTable(codeList, null, new CodeTableSorter());
  }

  // BCP
  getARAPType() {
    return RemoteCodeTable.CodeTableDistinctCodeFromCache(600111);
  }

  getARAPStatusAll() {
    return RemoteCodeTable.CodeTableIdFromCache(600002);
  }

  getAllActivePaymentMethod() {
    return RemoteCodeTable.CodeTableIdFromCache(600020);
  }

  getReinsuranceCompany() {
    return RemoteCodeTable.getReinsuranceCompany();
  }

  getReinsuranceBroker() {
    return RemoteCodeTable.getReinsuranceBroker();
  }

  getCurrencySign() {
    let currencySignCodeTable = DataContext.get(
      'currencySignCodeTable'
    );
    if (currencySignCodeTable) {
      return currencySignCodeTable;
    }
    const settings = { method: 'GET', async: false, block: false };
    const currencySignList = RemoteCodeTable.codeTableIdForOrignal(
      72926332,
      null,
      settings
    );
    const result = [];
    const filterList = [];
    _.each(currencySignList, mapObj => {
      if (!_.contains(filterList, mapObj.Id)) {
        filterList.push(mapObj.Id);
        const v = { id: mapObj.Id, text: mapObj.Description };
        result.push(v);
      }
    });
    currencySignCodeTable = new CodeTable(result);
    DataContext.put('currencySignCodeTable', currencySignCodeTable);
    return currencySignCodeTable;
  }
}
