import {
  UIPanel,
  UIText,
  UICurrency,
  UISelect,
  UIDataTable,
  UIColumn,
  UILink,
  UIParam,
  UpdateContext,
  MessageHelper,
} from 'RainbowUI';
import BindToMixin from 'react-binding';
import ClaimAppraisalExt from 'ClaimAppraisalExt';
import PropTypes from 'prop-types';
import { ClaimMessageForamtUtils } from 'ClaimCommonConstant';

const AppraisalBodyInjuryAction = ClaimAppraisalExt.getAppraisalBodyInjuryAction();
const BigNumber = require('bignumber.js');
const code = require('../../mock/CodeTable');

const AppraisalLossItems = React.createClass({
  mixins: [BindToMixin],
  propTypes: {
    lossItemsIndex: PropTypes.string.isRequired,
  },

  buildTableFunction() {
    const visibled = this.getVisibled();
    return (
      <div>
        <UILink
          icon="fa fa-plus"
          size="lg"
          tooltip={i18n.Add}
          iconClass="add_iconbtn"
          visibled={visibled}
          onClick={AppraisalBodyInjuryAction.addBodyItem}
        />
      </div>
    );
  },

  getTotalAmount() {
    const { ClaimAppraisalBodyInjury } = this.props;
    const claimAppraisalBodyItems = this.bindArrayTo(
      ClaimAppraisalBodyInjury,
      'ClaimAppraisalBodyItem'
    ).items;
    let totalClaimAmount = new BigNumber(0);
    let totalDeductionAmount = new BigNumber(0);
    let totalNetAmount = new BigNumber(0);
    const { length } = claimAppraisalBodyItems;
    for (let i = 0; i <= length - 2; i += 1) {
      let claimAmount = claimAppraisalBodyItems[i].sourceObject
        .ClaimAmount
        ? claimAppraisalBodyItems[i].sourceObject.ClaimAmount
        : 0;
      let deductionAmount = claimAppraisalBodyItems[i].sourceObject
        .DeductionAmount
        ? claimAppraisalBodyItems[i].sourceObject.DeductionAmount
        : 0;
      if (!claimAmount) {
        claimAmount = 0;
      }
      if (!deductionAmount) {
        deductionAmount = 0;
      }
      totalClaimAmount = totalClaimAmount.plus(claimAmount);

      totalDeductionAmount = totalDeductionAmount.plus(
        Number(deductionAmount)
      );
      const claimAmountOfItems = new BigNumber(Number(claimAmount));
      const deductionAmtOfItems = new BigNumber(
        Number(deductionAmount)
      );

      const netAmount = claimAmountOfItems.minus(deductionAmtOfItems);
      this.props.ClaimAppraisalBodyInjury.sourceObject.appraisalBodyInjury.ClaimAppraisalBodyItem[
        i
      ].NetAmount = netAmount;
    }
    totalNetAmount = totalClaimAmount.minus(totalDeductionAmount);
    claimAppraisalBodyItems[
      length - 1
    ].sourceObject.ClaimAmount = totalClaimAmount;
    claimAppraisalBodyItems[
      length - 1
    ].sourceObject.DeductionAmount = totalDeductionAmount;
    claimAppraisalBodyItems[
      length - 1
    ].sourceObject.NetAmount = totalNetAmount;
    this.props.ClaimAppraisalBodyInjury.sourceObject.appraisalBodyInjury.TotalClaimAmount = totalClaimAmount;
    this.props.ClaimAppraisalBodyInjury.sourceObject.appraisalBodyInjury.TotalDeductionAmount = totalDeductionAmount;
    this.props.ClaimAppraisalBodyInjury.sourceObject.appraisalBodyInjury.TotalNetAmount = totalNetAmount;

    this.forceUpdate();
  },

  refresh(event) {
    this.getTotalAmount();
    this.forceUpdate();
  },

  removeLossItem(event) {
    AppraisalBodyInjuryAction.removeBodyItem(event);
    this.getTotalAmount();
  },

  getIOStyle() {
    let io = null;
    const { taskCode } = this.props;

    if (
      (taskCode && taskCode != 'ClaimAppraisalBodilyInjuryTask') ||
      !taskCode
    ) {
      io = 'out';
    }
    return io;
  },
  getEnabled() {
    let enable = true;
    const { taskCode } = this.props;
    if (
      (taskCode != null &&
        taskCode != 'ClaimAppraisalBodilyInjuryTask') ||
      taskCode === undefined
    ) {
      enable = false;
    }
    return enable;
  },

  getVisibled() {
    let visibled = true;
    const { taskCode } = this.props;

    if (
      (taskCode && taskCode != 'ClaimAppraisalBodilyInjuryTask') ||
      !taskCode
    ) {
      visibled = false;
    }
    return visibled;
  },

  render() {
    const {
      ClaimAppraisalBodyInjury,
      currencyCode,
      history,
    } = this.props;
    const claimAppraisalBodyItems = this.bindArrayTo(
      ClaimAppraisalBodyInjury,
      'ClaimAppraisalBodyItem'
    ).items;
    const CurrencyCode = currencyCode;
    const io = this.getIOStyle();
    const enabled = this.getEnabled();
    const visibled = this.getVisibled();
    const _ref = this;
    const length = claimAppraisalBodyItems.length - 1;

    let style;
    if (history) {
      style = 'default';
    } else {
      style = 'primary';
    }

    const claimAmtValidate = event => {
      const dataIndex = event.getParameter('dataIndex');
      const claimAmtUi = UpdateContext.get(
        `CURRENCY_CODE_ClaimAmount${dataIndex}`
      );
      const deductionUi = UpdateContext.get(
        `CURRENCY_CODE_DeductionAmount${dataIndex}`
      );
      const claimAmt = Number(claimAmtUi.getComponentValue());
      const deductionAmt = deductionUi.getComponentValue()
        ? Number(deductionUi.getComponentValue())
        : 0;
      if (claimAmt < deductionAmt) {
        claimAmtUi.setValue(null, 0);

        MessageHelper.error(
          i18n.ClaimAppraisal.ErrorMsg.Error_0005,
          i18n.ClaimAppraisal.ErrorMsg.Error_0006,
          MessageHelper.POSITION_TOP_RIGHT
        );
      }
      _ref.refresh(event);
    };

    const deductionAmtValidate = event => {
      const dataIndex = event.getParameter('dataIndex');
      const claimAmtUi = UpdateContext.get(
        `CURRENCY_CODE_ClaimAmount${dataIndex}`
      );
      const deductionUi = UpdateContext.get(
        `CURRENCY_CODE_DeductionAmount${dataIndex}`
      );
      const claimAmt = claimAmtUi.getComponentValue()
        ? Number(claimAmtUi.getComponentValue())
        : 0;
      const deductionAmt = deductionUi.getComponentValue();
      if (claimAmt < deductionAmt) {
        deductionUi.setValue(null, 0);
        MessageHelper.error(
          i18n.ClaimAppraisal.ErrorMsg.Error_0007,
          i18n.ClaimAppraisal.ErrorMsg.Error_0008,
          MessageHelper.POSITION_TOP_RIGHT
        );
      }
      _ref.refresh(event);
    };

    return (
      <UIPanel
        panelTitle={i18n.ClaimAppraisal.LossItems}
        toggleable="true"
        styleClass={style}
      >
        <UIDataTable
          id="ClaimAppraisalBodyItem_DataTable"
          pageable="false"
          indexable="false"
          value={claimAppraisalBodyItems}
          functions={this.buildTableFunction(io)}
        >
          <UIColumn
            headerTitle={i18n.ClaimAppraisal.LossItems}
            render={data => {
              if (data.dataIndex == length) {
                return <UIText io="out" />;
              }

              return (
                <UISelect
                  model={data.value}
                  property="LossItem"
                  codeTable={code.lossItems}
                  required="true"
                  validationGroup="bodilyInjury"
                  enabled={enabled}
                  requiredMessage={ClaimMessageForamtUtils.formatI18NMsg(
                    i18n.ClaimCommon.I18nUtilValidate,
                    i18n.ClaimAppraisal.LossItem
                  )}
                />
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.ClaimAppraisal.ItemDesc}
            render={data => {
              if (data.dataIndex == length) {
                return (
                  <UIText
                    model={data.value}
                    property="ItemDesc"
                    io="out"
                  />
                );
              }
              return (
                <UIText
                  model={data.value}
                  property="ItemDesc"
                  io={io}
                />
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.ClaimAppraisal.ClaimAmount}
            render={data => {
              if (data.dataIndex == length) {
                return (
                  <UICurrency
                    id={`CURRENCY_CODE_ClaimAmount${data.dataIndex}`}
                    model={data.value}
                    required="true"
                    validationGroup="bodilyInjury"
                    property="ClaimAmount"
                    defaultValue="0"
                    requiredMessage={ClaimMessageForamtUtils.formatI18NMsg(
                      i18n.ClaimCommon.I18nUtilValidate,
                      i18n.ClaimAppraisal.ClaimAmount
                    )}
                    unit={window.EU00.getCurrencySignByCurrencyCode(
                      CurrencyCode
                    )}
                    enabled="false"
                  />
                );
              }
              return (
                <UICurrency
                  id={`CURRENCY_CODE_ClaimAmount${data.dataIndex}`}
                  model={data.value}
                  required="true"
                  validationGroup="bodilyInjury"
                  property="ClaimAmount"
                  defaultValue="0"
                  requiredMessage={ClaimMessageForamtUtils.formatI18NMsg(
                    i18n.ClaimCommon.I18nUtilValidate,
                    i18n.ClaimAppraisal.ClaimAmount
                  )}
                  unit={window.EU00.getCurrencySignByCurrencyCode(
                    CurrencyCode
                  )}
                  enabled={enabled}
                  onChange={claimAmtValidate.bind(_ref)}
                >
                  <UIParam name="dataIndex" value={data.dataIndex} />
                </UICurrency>
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.ClaimAppraisal.DeductionAmount}
            render={data => {
              if (data.dataIndex == length) {
                return (
                  <UICurrency
                    id={`CURRENCY_CODE_DeductionAmount${data.dataIndex}`}
                    model={data.value}
                    property="DeductionAmount"
                    defaultValue="0"
                    unit={window.EU00.getCurrencySignByCurrencyCode(
                      CurrencyCode
                    )}
                    enabled="false"
                  />
                );
              }
              return (
                <UICurrency
                  id={`CURRENCY_CODE_DeductionAmount${data.dataIndex}`}
                  model={data.value}
                  property="DeductionAmount"
                  defaultValue="0"
                  unit={window.EU00.getCurrencySignByCurrencyCode(
                    CurrencyCode
                  )}
                  onChange={deductionAmtValidate}
                  enabled={enabled}
                >
                  <UIParam name="dataIndex" value={data.dataIndex} />
                </UICurrency>
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.ClaimAppraisal.DeductionDesc}
            render={data => {
              if (data.dataIndex == length) {
                return <div />;
              }
              return (
                <UIText
                  model={data.value}
                  property="DeductionDesc"
                  onChange={_ref.refresh}
                  enabled={enabled}
                />
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.ClaimAppraisal.NetAmount}
            render={data => {
              if (data.dataIndex == length) {
                return (
                  <UICurrency
                    id={`CURRENCY_CODE_NetAmount${data.dataIndex}`}
                    model={data.value}
                    property="NetAmount"
                    defaultValue="0"
                    unit={window.EU00.getCurrencySignByCurrencyCode(
                      CurrencyCode
                    )}
                    enabled="false"
                  />
                );
              }
              return (
                <UICurrency
                  id={`CURRENCY_CODE_NetAmount${data.dataIndex}`}
                  model={data.value}
                  property="NetAmount"
                  defaultValue="0"
                  unit={window.EU00.getCurrencySignByCurrencyCode(
                    CurrencyCode
                  )}
                  enabled="false"
                />
              );
            }}
          />
          <UIColumn
            render={data => {
              if (data.dataIndex == length) {
                return <UIText io="out" />;
              }
              return (
                <UILink
                  onClick={_ref.removeLossItem}
                  icon="fa fa-trash"
                  visibled={visibled}
                >
                  <UIParam
                    name="removeIndex"
                    value={data.dataIndex}
                  />
                </UILink>
              );
            }}
          />
        </UIDataTable>
      </UIPanel>
    );
  },
});
export default AppraisalLossItems;
