import { AjaxUtil } from 'RainbowUI';
import Url from '../url/Url';

export default {
  loadLitigations(caseId) {
    const deferred = $.Deferred();
    const url = `${Url.litigationUrl}?caseId=${caseId}`;
    AjaxUtil.doGet(
      url,
      {},
      {
        async: false,
        done(data) {
          deferred.resolve(data);
        },
        fail(err) {
          deferred.reject(err);
        },
      }
    );
    return deferred.promise();
  },
  save(litigationObj) {
    const deferred = $.Deferred();
    const url = Url.litigationUrl;
    AjaxUtil.doPost(url, litigationObj, {
      async: false,
      done(data) {
        deferred.resolve(data);
      },
      fail(err) {
        deferred.reject(err);
      },
    });
    return deferred.promise();
  },
  remove(litigationObj) {
    const deferred = $.Deferred();
    const url = `${Url.litigationRemove}?suitId=${litigationObj.SuitId}`;
    AjaxUtil.doGet(
      url,
      {},
      {
        async: false,
        done(data) {
          deferred.resolve(data);
        },
        fail(err) {
          deferred.reject(err);
        },
      }
    );
    return deferred.promise();
  },
  getLocalCurrency() {
    const deferred = $.Deferred();
    const url = Url.localCurrency;
    AjaxUtil.doGet(
      url,
      {},
      {
        async: false,
        done(data) {
          deferred.resolve(data);
        },
        fail(err) {
          deferred.reject(err);
        },
      }
    );
    return deferred.promise();
  },
  litigationAuthority(caseId) {
    const deferred = $.Deferred();
    const url = `${Url.litigationAuthorityUrl}?caseId=${caseId}`;
    AjaxUtil.doGet(
      url,
      {},
      {
        async: false,
        done(data) {
          deferred.resolve(data);
        },
        fail(err) {
          deferred.reject(err);
        },
      }
    );
    return deferred.promise();
  },
};
