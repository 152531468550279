import {
  UIPanel,
  UIBox,
  UIUpdatePanel,
  UISmartPanelGrid,
  UIText,
  UIRadio,
  UISelect,
  UIButton,
} from 'RainbowUI';

import BindToMixin from 'react-binding';

const ClaimWorkFlowMyClaimAction = require('../../action/desktop/ClaimWorkFlowMyClaimAction');
const code = require('../../mock/CodeTable');

const CodeTableAction = require('../../action/CodeTableAction');
const CodeTableCstUI = require('../../constant/CodeTableCstUI');

const ClaimWorkFlowMyClaimSearch = React.createClass({
  mixins: [BindToMixin],
  getInitialState() {
    return { condition: {}, isInital: false };
  },
  clickSearch() {
    this.setState({ isInital: false });
    ClaimWorkFlowMyClaimAction.searchTask(this.state.condition);
  },
  Clear() {
    this.setState({ condition: {} });
  },
  render() {
    return (
      <div>
        <UIPanel
          id="searchCriteriaofMyClaim"
          panelTitle={i18n.ClaimCommon.CliamQueryCondition}
        >
          <UIUpdatePanel id="myClaimantId">
            <UISmartPanelGrid
              id="idMessageUISmartPanelGrid"
              layout="horizontal"
              column="2"
            >
              <UIText
                id="myclaimNumber"
                label={i18n.ClaimCommon.ClaimNumber}
                valueLink={this.bindToState(
                  'condition',
                  'ClaimNumber'
                )}
              />
              <UIText
                id="myClaimPolicyNumber"
                label={i18n.ClaimCommon.PolicyNumber}
                valueLink={this.bindToState('condition', 'PolicyNo')}
              />
              <UIText
                id="myClaimClaimantNmae"
                label={i18n.ClaimCommon.ClaimantName}
                valueLink={this.bindToState('condition', 'Claimant')}
              />
              <UIText
                id="myClaimPolicyHolderName"
                label={i18n.ClaimCommon.PolicyHolderName}
                valueLink={this.bindToState(
                  'condition',
                  'PolicyHolder'
                )}
              />
              <UISelect
                id="myClaimProduct"
                label={i18n.ClaimCommon.ProductName}
                codeTable={CodeTableAction.loadDataList(
                  CodeTableCstUI.ClaimProductCode,
                  null
                )}
                valueLink={this.bindToState('condition', 'Product')}
              />
              <UIRadio
                id="myclaimRadio"
                label={i18n.ClaimCommon.ShowOpenClaimsOnly}
                defaultValue="1"
                codeTable={code.YesOrNo}
                valueLink={this.bindToState(
                  'condition',
                  'LossStatus'
                )}
              />
            </UISmartPanelGrid>
          </UIUpdatePanel>
          <UIBox direction="left">
            <UIButton
              value={i18n.ClaimCommon.Search}
              onClick={this.clickSearch}
            />
            <UIButton
              value={i18n.ClaimCommon.Reset}
              onClick={this.Clear}
            />
          </UIBox>
        </UIPanel>
      </div>
    );
  },
});
export default ClaimWorkFlowMyClaimSearch;
