import { AjaxUtil } from 'RainbowUI';
import Url from '../url/Url';

export default {
  loadTaskList(caseId, needNoAssignee) {
    const deferred = $.Deferred();
    console.log(
      '-----get into loadTask list need no asigne is ',
      needNoAssignee
    );
    const url = `${Url.ClaimWorkflowQueryClaimTasks}/${caseId}/${needNoAssignee}`;
    AjaxUtil.doGet(
      url,
      {},
      {
        async: false,
        done(data) {
          deferred.resolve(data);
        },
        fail(err) {
          deferred.reject(err);
        },
      }
    );
    return deferred.promise();
  },
  checkApprovalTask(taskCode) {
    const deferred = $.Deferred();
    const url = `${Url.CheckApprovalTask}/${taskCode}`;
    AjaxUtil.doPost(
      url,
      {},
      {
        async: false,
        done(data) {
          deferred.resolve(data);
        },
        fail(err) {
          deferred.reject(err);
        },
      }
    );
    return deferred.promise();
  },
  updateTaskWithUserName(param) {
    const deferred = $.Deferred();
    const url = Url.updateTaskWithUserNameByProcessInstanceId;
    AjaxUtil.doPost(url, param, {
      async: false,
      done(data) {
        deferred.resolve(data);
      },
      fail(err) {
        deferred.reject(err);
      },
    });
    return deferred.promise();
  },
  updateTask(param) {
    const deferred = $.Deferred();
    console.log('-----in service update task with user name');
    const url = Url.updateTaskByProcessInstanceId;
    AjaxUtil.doPost(url, param, {
      async: false,
      done(data) {
        console.log('---------done ');
        deferred.resolve(data);
      },
      fail(err) {
        console.log('-----------fail....');
        deferred.reject(err);
      },
    });
    return deferred.promise();
  },
  AssignmentApprochCode(param) {
    const deferred = $.Deferred();
    const url = Url.AssignmentApprochCode;
    AjaxUtil.doPost(url, param, {
      async: false,
      done(data) {
        deferred.resolve(data);
      },
      fail(err) {
        deferred.reject(err);
      },
    });
    return deferred.promise();
  },
  TaskAuthoryUserList() {
    const deferred = $.Deferred();
    const url = Url.TaskAuthorityUserList;
    AjaxUtil.doGet(
      url,
      {},
      {
        async: false,
        done(data) {
          deferred.resolve(data);
        },
        fail(err) {
          deferred.reject(err);
        },
      }
    );
    return deferred.promise();
  },
  UserListByAuthoryCode(authoryCode) {
    const deferred = $.Deferred();
    const url = Url.UserListByAuthoryCode;
    AjaxUtil.doGet(
      `${url}/${authoryCode}`,
      {},
      {
        async: false,
        done(data) {
          deferred.resolve(data);
        },
        fail(err) {
          deferred.reject(err);
        },
      }
    );
    return deferred.promise();
  },
  UserListByAuthoryCodeCaseId(authoryCode, caseId, taskInstanceId) {
    const deferred = $.Deferred();
    console.log(
      '-------in TaskTransferService method: UserListByAuthoryCodeCaseId',
      taskInstanceId
    );
    const url = Url.UserListByAuthoryCodeCaseId;
    AjaxUtil.doGet(
      `${url}/${authoryCode}/${caseId}/${taskInstanceId}`,
      {},
      {
        async: false,
        done(data) {
          console.log('------successful deal data');
          deferred.resolve(data);
        },
        fail(err) {
          console.log(
            '-----with error in service UserListByAuthoryCodeCaseId'
          );
          deferred.reject(err);
        },
      }
    );
    return deferred.promise();
  },
  UserListWithPoolSystem(authoryCode, caseId) {
    const deferred = $.Deferred();
    console.log(
      '-------in TaskTransferService method: UserListWithPoolSystem'
    );
    const url = Url.UserListWithPoolSystem;
    AjaxUtil.doGet(
      `${url}/${authoryCode}/${caseId}`,
      {},
      {
        async: false,
        done(data) {
          console.log('------successful deal data');
          deferred.resolve(data);
        },
        fail(err) {
          console.log(
            '-----with error in service UserListByAuthoryCodeCaseId'
          );
          deferred.reject(err);
        },
      }
    );
    return deferred.promise();
  },
  getSubClaimNoByCaseId(caseId) {
    const deferred = $.Deferred();
    const url = Url.getSubClaimNoByCaseId;
    AjaxUtil.doGet(
      `${url}/${caseId}`,
      {},
      {
        async: false,
        done(data) {
          deferred.resolve(data);
        },
        fail(err) {
          deferred.reject(err);
        },
      }
    );
    return deferred.promise();
  },
  assignPoolToCurrentUser(param) {
    const deferred = $.Deferred();
    console.log('-----in service update task with user name');
    const url = Url.ClaimWorkflowWorkOnAssignForPool;
    AjaxUtil.doPost(url, param, {
      async: false,
      done(data) {
        console.log('---------done ');
        deferred.resolve(data);
      },
      fail(err) {
        console.log('-----------fail....');
        deferred.reject(err);
      },
    });
    return deferred.promise();
  },
};
