export default class CalculationValidationAction extends React.Component {
  validateSILimitForCalculationSubmit(calculationData) {
    console.log(
      '=========validateSILimitForCalculationSubmit========'
    );
    return null;
  }

  validateSILimitForCalculationApproval(approveInfo) {
    console.log(
      '=========validateSILimitForCalculationApproval========'
    );
    return null;
  }
}
