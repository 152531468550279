import {
  UIPickList,
  UIText,
  UIButton,
  UIBox,
  UICell,
  UIParam,
  UISmartPanelGrid,
} from 'RainbowUI';
import PropTypes from 'prop-types';

const SubclaimCoverageReserveOfProductLineAction = require('../../action/SubclaimCoverageReserveOfProductLineAction');

const SubclaimCoverageReserveRelation = React.createClass({
  propTypes: {
    entity: PropTypes.object,
  },
  getPickList() {
    const param = {
      SubclaimTypeCode: this.props.entity.subclaimTypeCode,
      CoverageTypeCode: this.props.entity.coverageTypeCode,
      ProductLineCode: this.props.entity.treeNodeCode,
    };
    return SubclaimCoverageReserveOfProductLineAction.getPickList(
      param
    );
  },
  render() {
    const { entity } = this.props;
    return (
      <div>
        <UISmartPanelGrid>
          <UIText
            label={i18n.ClaimProduct.SubclaimType}
            value={`${entity.subclaimTypeCode}:${entity.subclaimTypeName}`}
          />
          <UIText
            label={i18n.ClaimProduct.CoverageCategory}
            value={`${entity.coverageTypeCode}:${entity.coverageTypeName}`}
          />
        </UISmartPanelGrid>
        <div>
          <UICell width="4" />
          <UICell width="8">
            <UIPickList
              id="SubclaimCoverageReserveRelationPickList"
              sourceListLabel={i18n.ClaimProduct.OptionalReserveTypes}
              targetListLabel={
                i18n.ClaimProduct.ApplicableReserveTypes
              }
              dataSource={this.getPickList}
            />
          </UICell>
        </div>
        <div>
          <UIBox direction="left">
            <UIButton
              value={i18n.ClaimProduct.Save}
              onClick={
                SubclaimCoverageReserveOfProductLineAction.saveSubclaimCoverageReserve
              }
            >
              <UIParam name="param" value={entity} />
            </UIButton>
            <UIButton
              value={i18n.ClaimProduct.Cancel}
              onClick={
                SubclaimCoverageReserveOfProductLineAction.cancelSubclaimCoverageReserve
              }
            >
              <UIParam name="param" value={entity} />
            </UIButton>
          </UIBox>
        </div>
      </div>
    );
  },
});
export default SubclaimCoverageReserveRelation;
