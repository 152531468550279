import {
  UIBox,
  UICell,
  UIDialog,
  UIRadio,
  UINumber,
  UIUpdatePanel,
  UIText,
  UISelect,
  UIButton,
} from 'RainbowUI';
import BindToMixin from 'react-binding';
import ProductCfgTreeAction from '../action/ProductCfgTreeAction';

const ProductCoverageListAction = require('../action/ProductCoverageListAction');
const code = require('../mock/CodeTable');

const ProductCoverageDialog = React.createClass({
  mixins: [BindToMixin],
  getInitialState() {
    return { coverage: {}, io: 'out' };
  },
  save() {
    ProductCoverageListAction.save(
      this.state.coverage,
      this.state.io
    );
    this.props.parentFunction();
    ProductCfgTreeAction.initData();
  },
  showDialog(data) {
    if (data) {
      this.setState({ io: 'out' });
      this.setState({ coverage: data });
    } else {
      this.setState({ io: 'in' });
      this.setState({ coverage: {} });
    }
    UIDialog.show('productCoverageDialog');
  },
  render() {
    return (
      <UIDialog
        id="productCoverageDialog"
        title={i18n.ClaimProduct.CoverageDetail}
        width="80%"
        height="600px"
        modal="false"
      >
        <UIUpdatePanel
          id="productCoverageDialogUIUpade"
          render={this.renderDialogContent}
        />
        <UIBox direction="left">
          <UIButton
            value={i18n.ClaimProduct.Save}
            onClick={this.save}
            validationGroup="coverage"
            causeValidation="true"
          />
          <UIButton
            value={i18n.ClaimProduct.Cancel}
            buttonType="cancel"
          />
        </UIBox>
      </UIDialog>
    );
  },
  renderDialogContent() {
    const { io } = this.state;
    return (
      <div id="productCoverageDialogSmartPanel" column="2">
        <UICell>
          <UICell width="6">
            <UIText
              io={io}
              id="productCoverageCode"
              label={i18n.ClaimProduct.CoverageCode}
              valueLink={this.bindToState('coverage', 'CoverageCode')}
              required="true"
              validationGroup="coverage"
            />
          </UICell>
          <UICell width="6">
            <UIText
              id="productCoverageName"
              label={i18n.ClaimProduct.CoverageName}
              valueLink={this.bindToState('coverage', 'CoverageName')}
              required="true"
              validationGroup="coverage"
            />
          </UICell>
          <UICell width="6">
            <UINumber
              id="NUMBER_CODE_SortNo"
              format="######"
              label={i18n.ClaimProduct.SortNo}
              valueLink={this.bindToState('coverage', 'SortNumber')}
            />
          </UICell>
          <UICell width="6">
            <UIText
              id="policySysCode"
              label={i18n.ClaimProduct.ExtCoverageCode}
              valueLink={this.bindToState(
                'coverage',
                'ExtCoverageCode'
              )}
              required="true"
              validationGroup="coverage"
            />
          </UICell>
          <UICell width="6">
            <UIText
              id="coverageDescription"
              label={i18n.ClaimProduct.CoverageDescription}
              valueLink={this.bindToState(
                'coverage',
                'CoverageDescription'
              )}
            />
          </UICell>
          <UICell width="6">
            <UIText
              id="productCoverTypeCode"
              label={i18n.ClaimProduct.ExtCoverageTypeCode}
              valueLink={this.bindToState(
                'coverage',
                'ExtCoverageTypeCode'
              )}
            />
          </UICell>
          <UICell width="6">
            <UIRadio
              id="availableClaimingCoverage"
              label={i18n.ClaimProduct.AvailableClaimingCoverage}
              valueLink={this.bindToState(
                'coverage',
                'AvailableClaimingFlag'
              )}
              codeTable={code.YesOrNo}
            />
          </UICell>
          <UICell width="6">{this.coverateCategoryShow()}</UICell>
          <UICell width="6">{this.defaultCoverageShow()}</UICell>
          <UICell width="6">{this.sumInsuredEditableShow()}</UICell>
        </UICell>
      </div>
    );
  },
  coverateCategoryShow() {
    const { coverage } = this.state;
    if (coverage.AvailableClaimingFlag !== 'N') {
      return (
        <UISelect
          id="coverageCategory"
          label={i18n.ClaimProduct.CoverageCategory}
          valueLink={this.bindToState('coverage', 'CoverageTypeCode')}
          codeTable={
            ProductCoverageListAction.getClaimCoverageTypeCodeAndName
          }
        />
      );
    }
    return <div />;
  },
  defaultCoverageShow() {
    const { coverage } = this.state;
    if (coverage.AvailableClaimingFlag !== 'N') {
      return (
        <UIRadio
          id="defaultCoverage"
          label={i18n.ClaimProduct.DefaultCoverage}
          valueLink={this.bindToState(
            'coverage',
            'DefaultCoverageFlag'
          )}
          codeTable={code.YesOrNo}
        />
      );
    }
    return <div />;
  },
  sumInsuredEditableShow() {
    const { coverage } = this.state;
    if (coverage.AvailableClaimingFlag !== 'N') {
      return (
        <UIRadio
          id="sumInsuredEditable"
          label={i18n.ClaimProduct.SumInsuredEditable}
          valueLink={this.bindToState('coverage', 'SiEditableFlag')}
          codeTable={code.YesOrNo}
        />
      );
    }
    return <div />;
  },
});
export default ProductCoverageDialog;
