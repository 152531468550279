import alt from '../alt';

const SalvageLoadAction = require('../action/SalvageLoadAction');

class SalvageHistoryStore {
  constructor() {
    this.SalvageHistory = [];
    this.SalvageId = null;
    this.bindListeners({
      handleLoad: SalvageLoadAction.LOAD_SALVAGE_HISTORY,
      handleShowReadOnly: SalvageLoadAction.SHOW_READ_ONLY,
    });
  }

  handleLoad(data) {
    this.SalvageHistory = data;
  }

  handleShowReadOnly(salvageId) {
    this.SalvageId = salvageId;
  }
}
export default alt.createStore(
  SalvageHistoryStore,
  SalvageHistoryStore
);
