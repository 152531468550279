import { UIDialog } from 'RainbowUI';
import ClaimCommonExt from 'ClaimCommonExt';
import {
  PtyIntegrationSearchDialog,
  PartyConst,
  PtyIntegrationCreationDialog,
  PartyAction,
} from 'EU00PartyCommonUI';

const ClaimPartyAction = ClaimCommonExt.getClaimPartyAction();

const ChooseClaimantDialog = React.createClass({
  getDefaultProps() {
    return { index: '_0' };
  },
  getTitle() {
    let title = null;
    if (this.props.fromWhere === 'payee') {
      title = i18n.ClaimParty.PayeeTitle;
    } else {
      title = i18n.ClaimParty.PayerTitle;
    }
    return title;
  },
  callback(party) {
    let roleType = PartyConst.PARTY_ROLE_CODE_IND_CLAIMANT;
    if (party.PartyRoleList) {
      _.each(party.PartyRoleList, role => {
        if (
          role.RoleType == PartyConst.PARTY_ROLE_CODE_ORG_CLAIMANT &&
          PartyConst.PARTY_TYPE_ORG == party.PartyType
        ) {
          roleType = PartyConst.PARTY_ROLE_CODE_ORG_CLAIMANT;
        }
      });
    }
    const commonParty = {
      NewRoleType: roleType,
      PtyPartyId: party.PartyId,
      PartyName: party.PartyName,
      PartyRole: '01',
    };
    const claimParty = ClaimPartyAction.createPartyParty(
      this.props.caseId,
      commonParty
    );
    this.props.getDefaultClaimant(claimParty);
    let bankCode = '';
    let accountNo = '';
    let accountName = '';
    let addressStr = '';
    let swiftCode = '';
    let IBANNo = '';
    if (party.AddressVoList) {
      _.each(party.AddressVoList, addressInfo => {
        if (addressInfo.IsPrimaryAddress === 'Y') {
          const partyAction = new PartyAction();
          addressStr = partyAction.buildAddress(addressInfo);
        }
      });
    }
    if (party.PartyAccountList) {
      _.each(party.PartyAccountList, partyAccount => {
        if (partyAccount.IsPrimaryAccount === 'Y') {
          bankCode = partyAccount.BankName;
          accountNo = partyAccount.AccountNo;
          accountName = partyAccount.AccountNo;
          swiftCode = partyAccount.SwiftCode;
          IBANNo = partyAccount.IBANNo;
        }
      });
    }

    const result = { commonParty: party, claimParty };
    this.props.parentComponent.addOrUpdateParty(result);
  },
  createPartyDialog() {
    UIDialog.hide(`ChooseClaimantDialogId${this.props.index}`);
    UIDialog.show(`CreateParty${this.props.index}`);
  },

  getDefaultClaimant() { },

  render() {
    const _self = this;
    const { index } = this.props;
    return (
      <div>
        <PtyIntegrationSearchDialog
          getSearchResult={_self.callback}
          createClicked={_self.createPartyDialog}
          ptyIntegrationCreated={_self.callback}
          id={`ChooseClaimantDialogId${index}`}
          RoleName={PartyConst.PARTY_ROLE_NAME_CLAIMANT}
          forceUpdate="subclaimInfo"
        />
        <PtyIntegrationCreationDialog
          ptyIntegrationCreated={_self.callback}
          id={`CreateParty${index}`}
          RoleName={PartyConst.PARTY_ROLE_NAME_CLAIMANT}
        />
      </div>
    );
  },
});
export default ChooseClaimantDialog;
