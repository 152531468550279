import {
  UIBox,
  UITextarea,
  UIDialog,
  UIText,
  UIButton,
} from 'RainbowUI';

const PartyHistoryDetailDialog = React.createClass({
  getInitialState() {
    const partyHistory = {};
    return {
      partyHistory,
      party: this.props.party,
    };
  },

  onClickClose() {
    UIDialog.hide('HistoryDialog');
  },

  render() {
    const _self = this;
    return (
      <UIDialog
        id="HistoryDialog"
        width="60%"
        title={i18n.DetailInformation}
      >
        <UITextarea
          resize="none"
          value={_self.getInformation()}
          disabled="true"
          layout="vertical"
          rows={_self.getSize()}
        />
        <UIBox direction="center">
          <UIButton
            value={i18n.Cancel}
            onClick={_self.onClickClose}
          />
        </UIBox>
      </UIDialog>
    );
  },

  renderInformation(data) {
    return <UIText model={data} property="text" io="out" />;
  },
  getInformation() {
    let historyList = this.state.partyHistory.HistoryInfo;
    if (historyList) {
      historyList = JSON.parse(historyList);
    }
    if (historyList) {
      delete historyList[' Insert By'];
      delete historyList[' Insert Time'];
      delete historyList[' Update By'];
      delete historyList[' Update Time'];
      delete historyList.InsertBy;
      delete historyList.UpdateBy;
      delete historyList.InsertTime;
      delete historyList.UpdateTime;
    }

    const _self = this;
    const arrTemp = new Map();
    let index = 0;
    _.each(historyList, tempobj => {
      let obj = tempobj;
      if (obj) {
        obj = obj.trim();
        let temp = obj.split(':');
        temp = _self.trimAllElementArray(temp);

        if (temp.length >= 2) {
          arrTemp.set(temp[0], _self.catArrayToString(temp, 1));
        } else if (temp.length === 1) {
          arrTemp.set(index, temp[0].trim());
          index += 1;
        }
      }
    });
    let result = '';
    arrTemp.forEach((items, key) => {
      let item = items;
      if (_self.isNumber(key)) {
        result = `${result + item}\n`;
      } else if (item.indexOf('->') !== -1) {
        const regPos = /->$/;
        if (regPos.test(item)) {
          item = item.replace('->', '');
        }
        result = `${result + key}:${item}\n`;
      } else if (item.trim() !== '') {
        result = `${result + key}:${item}\n`;
      }
    });
    return result;
  },

  isNumber(val) {
    const regPos = /^\d+(\.\d+)?$/;
    const regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/;
    if (regPos.test(val) || regNeg.test(val)) {
      return true;
    }
    return false;
  },

  trimAllElementArray(temparray) {
    const array = temparray;
    for (let i = 0; i < array.length; i += 1) {
      array[i] = array[i].trim();
    }
    return array;
  },

  catArrayToString(array, tempbeginIndex, tempendIndex) {
    let beginIndex = tempbeginIndex;
    let endIndex = tempendIndex;
    let result = '';
    if (array) {
      if (!beginIndex) {
        beginIndex = 0;
      }
      if (!endIndex) {
        endIndex = array.length;
      }
      let i = beginIndex;
      for (i = beginIndex; i < endIndex; i += 1) {
        if (i !== endIndex - 1) {
          result = `${result + array[i].trim()}:`;
        } else {
          result += array[i].trim();
        }
      }
    }
    return result;
  },

  getSize() {
    let historyList = this.state.partyHistory.HistoryInfo;
    if (historyList) {
      historyList = JSON.parse(historyList);
    }
    let size = 0;
    _.each(historyList, () => {
      size += 1;
    });
    return size + 1;
  },

  showHistoryDialog(data) {
    UIDialog.show('HistoryDialog');

    this.setState({
      partyHistory: data,
    });
  },
});
export default PartyHistoryDetailDialog;
