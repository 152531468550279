import {
  CodeTable,
  MessageHelper,
  UIAutoComplete,
  UIBox,
  UIButton,
  UICell,
  UICheckbox,
  UIDialog,
  UISelect,
  UISmartPanelGrid,
  UIText,
  UIUpdatePanel,
  UpdateContext,
} from 'RainbowUI';
import PartyAction from '../../../actions/PartyAction';
import DefineCodeTable from '../../../codetable/CodeTable';
import PartyConst from '../../../const/PartyConst';

const PartyAddressInfoDialog = React.createClass({
  getInitialState() {
    const tempCodeTable = new CodeTable([]);
    const countryCodeTable = DefineCodeTable.getCountry();
    const addressTypeCodeTable = DefineCodeTable.getAddressType();
    const statusCodeTable = DefineCodeTable.getPartyStatus();
    return {
      tempCodeTable,
      countryCodeTable,
      regionCodeTable: tempCodeTable,
      addressTypeCodeTable,
      statusCodeTable,
      postCode: {},
      readOnly: false,
    };
  },

  onShowPartyAddressInfoDialogReadOnly(
    partyAddress,
    flag,
    regionCodeTable
  ) {
    let { readOnly } = this.state;
    const { party } = this.props;
    let { addressTypeCodeTable } = this.state;
    if (party.PartyType == PartyConst.PARTY_TYPE_ORG) {
      addressTypeCodeTable = DefineCodeTable.getOrgAddressType();
    }
    if (flag === 'view') {
      readOnly = true;
    } else {
      readOnly = false;
    }
    if (flag === 'edit' || flag === 'view') {
      this.generateCodeTable(partyAddress, flag);
    }
    this.setState({
      partyAddress,
      readOnly,
      addressTypeCodeTable,
      regionCodeTable,
    });
    UIDialog.show('partyAddressInfoDialogReadOnly');
  },

  render() {
    const _self = this;
    let { partyAddress } = this.state;
    if (typeof partyAddress === 'undefined') {
      partyAddress = {};
    }
    if (!partyAddress.Country) partyAddress.Country = 'US';

    let cityCodeTable = new CodeTable([]);
    let areaCodeTable = new CodeTable([]);
    let postCodeTable = new CodeTable([]);
    let poBoxCodeTable = new CodeTable([]);
    let streetNameCodeTable = new CodeTable([]);
    const cityCodeTableTemp = DefineCodeTable.geRegion(
      partyAddress.Country,
      2
    );
    const cityDesc = cityCodeTableTemp.map[partyAddress.City];
    if (cityDesc) {
      cityCodeTable = new CodeTable([
        { id: partyAddress.City, text: cityDesc.text },
      ]);
      const tempAreaCodeTable = DefineCodeTable.geRegion(
        partyAddress.Country,
        3,
        partyAddress.City
      );
      const areaDes = tempAreaCodeTable.map[partyAddress.State];
      if (areaDes) {
        areaCodeTable = new CodeTable([
          { id: partyAddress.State, text: areaDes.text },
        ]);
        const tempPostCodeTable = DefineCodeTable.geRegion(
          partyAddress.Country,
          4,
          partyAddress.State
        );
        const postCodeDesc =
          tempPostCodeTable.map[partyAddress.PostCode];
        if (postCodeDesc) {
          postCodeTable = new CodeTable([
            { id: partyAddress.PostCode, text: postCodeDesc.text },
          ]);
        } else {
          postCodeTable = new CodeTable([
            {
              id: partyAddress.PostCode,
              text: partyAddress.PostCode,
            },
          ]);
        }
        const tempStreetNameCodeTable = DefineCodeTable.geRegion(
          partyAddress.Country,
          5,
          partyAddress.State
        );
        const streetNameDesc =
          tempStreetNameCodeTable.map[partyAddress.AddressLine1];
        if (streetNameDesc) {
          streetNameCodeTable = new CodeTable([
            {
              id: partyAddress.AddressLine1,
              text: streetNameDesc.text,
            },
          ]);
        } else {
          streetNameCodeTable = new CodeTable([
            {
              id: partyAddress.AddressLine1,
              text: partyAddress.AddressLine1,
            },
          ]);
        }
        const tempPoBoxCodeTable = DefineCodeTable.geRegion(
          partyAddress.Country,
          6,
          partyAddress.State
        );
        const poBoxDesc = tempPoBoxCodeTable.map[partyAddress.AddressLine2];
        if (poBoxDesc) {
          poBoxCodeTable = new CodeTable([
            { id: partyAddress.AddressLine2, text: poBoxDesc.text },
          ]);
        } else {
          poBoxCodeTable = new CodeTable([
            { id: partyAddress.AddressLine2, text: partyAddress.AddressLine2 },
          ]);
        }
      } else {
        areaCodeTable = new CodeTable([
          { id: partyAddress.State, text: partyAddress.State },
        ]);
        postCodeTable = new CodeTable([
          { id: partyAddress.PostCode, text: partyAddress.PostCode },
        ]);
        streetNameCodeTable = new CodeTable([
          {
            id: partyAddress.AddressLine1,
            text: partyAddress.AddressLine1,
          },
        ]);
        poBoxCodeTable = new CodeTable([
          { id: partyAddress.AddressLine2, text: partyAddress.AddressLine2 },
        ]);
      }
    } else {
      cityCodeTable = new CodeTable([
        { id: partyAddress.City, text: partyAddress.City },
      ]);
      areaCodeTable = new CodeTable([
        { id: partyAddress.State, text: partyAddress.State },
      ]);
      postCodeTable = new CodeTable([
        { id: partyAddress.PostCode, text: partyAddress.PostCode },
      ]);
      streetNameCodeTable = new CodeTable([
        {
          id: partyAddress.AddressLine1,
          text: partyAddress.AddressLine1,
        },
      ]);
      poBoxCodeTable = new CodeTable([
        { id: partyAddress.AddressLine2, text: partyAddress.AddressLine2 },
      ]);
    }
    return (
      <UIDialog
        width="85%"
        id="partyAddressInfoDialogReadOnly"
        title={i18n.AddressDetail}
      >
        <UIUpdatePanel id="partyAddressInfoDialogReadOnlyPanel">
          <UISmartPanelGrid column="3">
            <UICheckbox
              singleLabel={i18n.CorrespondenceAddress}
              single="true"
              defaultValue="Y"
              model={partyAddress}
              property="IsPrimaryAddress"
              disabled="true"
            />
            <UISelect
              label={i18n.AddressType}
              onChange={_self.onChangeAddressType}
              showBlankOption="false"
              required="true"
              defaultValue="1"
              codeTable={_self.state.addressTypeCodeTable}
              model={partyAddress}
              property="AddressType"
              disabled="true"
            />
            <UIAutoComplete
              label={i18n.Country}
              required="true"
              dropDown="true"
              acceptInput="true"
              defaultValue="CY"
              codeTable={_self.state.countryCodeTable}
              onChange={_self.changeCountryCode}
              model={partyAddress}
              property="Country"
              disabled="true"
            />
            <UIAutoComplete
              label={i18n.DistrictOrCity}
              required="true"
              validationGroup="partyAddressInfoDialogReadOnly"
              acceptInput="true"
              codeTable={cityCodeTable}
              model={partyAddress}
              property="City"
              disabled="true"
            />
            <UIAutoComplete
              label={i18n.Area}
              required="true"
              validationGroup="partyAddressInfoDialogReadOnly"
              acceptInput="true"
              codeTable={areaCodeTable}
              model={partyAddress}
              property="State"
              disabled="true"
            />
            <UIAutoComplete
              label={i18n.PostalCode}
              required="true"
              validationGroup="partyAddressInfoDialogReadOnly"
              acceptInput="true"
              codeTable={postCodeTable}
              model={partyAddress}
              property="PostCode"
              disabled="true"
            />
            />
          </UISmartPanelGrid>
          {this.addressTypeRender(
            streetNameCodeTable,
            poBoxCodeTable
          )}
          )}
        </UIUpdatePanel>
        <UIBox direction="center">
          <UIButton
            value={i18n.Save}
            causeValidation="true"
            validationGroup="partyAddressInfoDialogReadOnly"
            onClick={_self.onClickSubmit}
            disabled="true"
          />
          <UIButton
            value={i18n.Cancel}
            onClick={_self.onClickClose}
          />
          />
        </UIBox>
      </UIDialog>
    );
  },

  addressTypeRender(streetNameCodeTable, poBoxCodeTable) {
    const _self = this;
    let { partyAddress } = this.state;
    if (typeof partyAddress === 'undefined') {
      partyAddress = {};
    }
    if (partyAddress && partyAddress.AddressType == 3) {
      return (
        <UISmartPanelGrid column="3">
          <UIAutoComplete
            label={i18n.PoBox}
            required="true"
            validationGroup="partyAddressInfoDialogReadOnly"
            acceptInput="true"
            codeTable={poBoxCodeTable}
            model={partyAddress}
            property="POBox"
            disabled="true"
          />
          <UICell />
          <UICell />
          <UISelect
            label={i18n.Status}
            showBlankOption="false"
            codeTable={_self.state.statusCodeTable}
            model={partyAddress}
            property="AddressStatus"
            disabled="true"
          />
          />
        </UISmartPanelGrid>
      );
    }
    return (
      <UISmartPanelGrid column="3">
        <UIAutoComplete
          label={i18n.StreetName}
          required="true"
          validationGroup="partyAddressInfoDialogReadOnly"
          acceptInput="true"
          codeTable={streetNameCodeTable}
          model={partyAddress}
          property="StreetName"
          disabled="true"
        />
        <UIText
          label={i18n.Number}
          model={partyAddress}
          property="StreetNo"
          disabled="true"
        />
        <UIText
          label={i18n.BuildingName}
          model={partyAddress}
          property="BuildingName"
          disabled="true"
        />
        <UIText
          label={i18n.ApartmentNumber}
          model={partyAddress}
          property="ApartmentNumber"
          disabled="true"
        />
        <UIText
          label={i18n.AddressTel}
          model={partyAddress}
          property="AddressTel"
          disabled="true"
        />
        <UIText
          label={i18n.FaxNumber}
          model={partyAddress}
          property="FaxNumber"
          disabled="true"
        />
        <UISelect
          label={i18n.Status}
          showBlankOption="false"
          codeTable={_self.state.statusCodeTable}
          model={partyAddress}
          property="AddressStatus"
          disabled="true"
        />
        />
      </UISmartPanelGrid>
    );
  },

  changePostCode() {
    const { partyAddress } = this.state;
    this.generateCodeTable(partyAddress, null);
  },

  generateCodeTable(tempPartyAddress, flag) {
    const partyAddress = tempPartyAddress;
    const partyAction = new PartyAction();
    const { tempCodeTable } = this.state;
    if (partyAddress) {
      const countryCode = partyAddress.Country;
      const postCode = partyAddress.PostCode;
      const addressType = partyAddress.AddressType;
      this.setState({ postCode });
      const regions = partyAction.getRegionByCountryCodeAndPostCode(
        countryCode,
        postCode
      );
      const cityList = [];
      const cityListTemp = [];
      const areaList = [];
      const areaListTemp = [];
      const streetList = [];
      const streetListTemp = [];
      const cityFilterList = [];
      const areaFilterList = [];
      const streetNameFilterList = [];
      const poBoxList = [];
      const poBoxListTemp = [];
      const poBoxFilterList = [];
      let cityCodeTable;
      let areaCodeTable;
      let streetCodeTable;
      let poBoxCodeTable;
      if (regions) {
        for (let i = 0; i < regions.length; i += 1) {
          cityListTemp.push({
            id: regions[i].RegionName,
            text: regions[i].RegionName,
          });
          areaListTemp.push({
            id: regions[i].Area,
            text: regions[i].Area,
          });
          streetListTemp.push({
            id: regions[i].StreetName,
            text: regions[i].StreetName,
          });
          poBoxListTemp.push({
            id: regions[i].PostOfficeBox,
            text: regions[i].PostOfficeBox,
          });
        }
        _.each(cityListTemp, mapObj => {
          if (!_.contains(cityFilterList, mapObj.id)) {
            cityFilterList.push(mapObj.id);
            cityList.push(mapObj);
          }
        });
        _.each(areaListTemp, mapObj => {
          if (!_.contains(areaFilterList, mapObj.id)) {
            areaFilterList.push(mapObj.id);
            areaList.push(mapObj);
          }
        });
        _.each(streetListTemp, mapObj => {
          if (!_.contains(streetNameFilterList, mapObj.id)) {
            streetNameFilterList.push(mapObj.id);
            streetList.push(mapObj);
          }
        });
        _.each(poBoxListTemp, mapObj => {
          if (!_.contains(poBoxFilterList, mapObj.id)) {
            poBoxFilterList.push(mapObj.id);
            poBoxList.push(mapObj);
          }
        });

        cityCodeTable = new CodeTable(cityList);
        areaCodeTable = new CodeTable(areaList);

        if (streetList[0] && streetList[0].id) {
          streetCodeTable = new CodeTable(streetList);
        } else {
          streetCodeTable = tempCodeTable;
        }
        if (poBoxList[0] && poBoxList[0].id) {
          poBoxCodeTable = new CodeTable(poBoxList);
        } else {
          poBoxCodeTable = tempCodeTable;
        }
        partyAddress.City = cityList[0].id;
        partyAddress.State = areaList[0].id;

        this.setState({
          cityCodeTable,
          areaCodeTable,
          streetCodeTable,
          poBoxCodeTable,
          partyAddress,
        });
        if (addressType == '1' || addressType == '2') {
          if (poBoxList[0] && poBoxList[0].id) {
            this.generateNotExistCodeTable(partyAddress, flag);
            UpdateContext.forceUpdate(
              'partyAddressInfoDialogReadOnlyPanel'
            );
            return;
          }
        } else if (addressType == '3') {
          if (
            poBoxList[0] &&
            (!poBoxList[0].id || poBoxList[0].id === 'undefined')
          ) {
            this.generateNotExistCodeTable(partyAddress, flag);
            UpdateContext.forceUpdate(
              'partyAddressInfoDialogReadOnlyPanel'
            );
            return;
          }
        }
        UpdateContext.forceUpdate(
          'partyAddressInfoDialogReadOnlyPanel'
        );
      } else if (flag === 'edit' || flag === 'view') {
        this.generateNotExistCodeTable(partyAddress, flag);
      } else {
        MessageHelper.warning(
          i18n.PartyMsg.WarnMsg.Warn_0011,
          null,
          MessageHelper.POSITION_TOP_RIGHT
        );
        this.clearPartyAddress(partyAddress);
      }
    }
  },

  generateNotExistCodeTable(partyAddress, flag) {
    let cityCodeTable;
    let areaCodeTable;
    let streetCodeTable;
    let poBoxCodeTable;
    const city = partyAddress.City;
    const area = partyAddress.State;
    const streetName = partyAddress.AddressLine1;
    const poBox = partyAddress.AddressLine2;
    const { tempCodeTable } = this.state;
    if (flag === 'edit' || flag === 'view') {
      if (city) {
        cityCodeTable = new CodeTable([{ id: city, text: city }]);
        this.setState({ cityCodeTable });
      } else {
        this.setState({ cityCodeTable: tempCodeTable });
      }
      if (area) {
        areaCodeTable = new CodeTable([{ id: area, text: area }]);
        this.setState({ areaCodeTable });
      } else {
        this.setState({ areaCodeTable: tempCodeTable });
      }
      if (streetName) {
        streetCodeTable = new CodeTable([
          { id: streetName, text: streetName },
        ]);
        this.setState({ streetCodeTable });
      } else {
        this.setState({ streetCodeTable: tempCodeTable });
      }
      if (poBox) {
        poBoxCodeTable = new CodeTable([{ id: poBox, text: poBox }]);
        this.setState({ poBoxCodeTable });
      } else {
        this.setState({ poBoxCodeTable: tempCodeTable });
      }
    }
  },

  onClickSubmit() {
    const { party } = this.props;
    const { partyAddress } = this.state;
    const { PartyHistoryList } = party;
    if (partyAddress['@pk']) {
      const newPartyHistory = {
        '@type': 'PartyHistory-PartyHistory',
        BusinessObjectId: 75158352,
        HistoryType: 6,
      };
      PartyHistoryList.push(newPartyHistory);
    } else {
      const newPartyHistory = {
        '@type': 'PartyHistory-PartyHistory',
        BusinessObjectId: 75158352,
        HistoryType: 5,
      };
      PartyHistoryList.push(newPartyHistory);
    }

    const { postCode } = this.state;
    const addressStatus = partyAddress.AddressStatus;
    partyAddress.PostCode = postCode;
    let AddressVoList = [];
    if (addressStatus == '2') {
      partyAddress.IsPrimaryAddress = 'N';
    }
    if (party.AddressVoList) {
      AddressVoList = party.AddressVoList;
      if (partyAddress.IsPrimaryAddress === 'Y') {
        _.forEach(AddressVoList, tempObj => {
          const obj = tempObj;
          obj.IsPrimaryAddress = 'N';
        });
      }
      for (let i = 0; i < AddressVoList.length; i += 1) {
        if (AddressVoList[i].AddressId == partyAddress.AddressId) {
          AddressVoList.splice(i, 1);
        }
      }
    }
    AddressVoList.push(partyAddress);
    _.each(AddressVoList, tempObj => {
      const obj = tempObj;
      delete obj.dataIndex;
    });
    party.AddressVoList = AddressVoList;

    UIDialog.hide('partyAddressInfoDialogReadOnly');
    this.props.parentComponet.updatePanel(party);
  },

  onClickClose() {
    const { partyAddress } = this.state;
    const { readOnly } = this.state;
    if (!readOnly) {
      this.clearPartyAddress(partyAddress);
    }
    UIDialog.hide('partyAddressInfoDialogReadOnly');
  },

  onChangeAddressType() {
    const { partyAddress } = this.state;
    if (partyAddress.PostCode) {
      delete partyAddress.PostCode;
    }
    this.clearPartyAddress(partyAddress);
  },

  changeCountryCode() {
    const { partyAddress } = this.state;
    if (partyAddress.PostCode) {
      delete partyAddress.PostCode;
    }
    this.clearPartyAddress(partyAddress);
  },

  clearPartyAddress(tempPartyAddress) {
    const partyAddress = tempPartyAddress;
    if (partyAddress.State) {
      delete partyAddress.State;
    }
    if (partyAddress.AddressLine1) {
      delete partyAddress.AddressLine1;
    }
    if (partyAddress.StreetNo) {
      delete partyAddress.StreetNo;
    }
    if (partyAddress.BuildingName) {
      delete partyAddress.BuildingName;
    }
    if (partyAddress.ApartmentNumber) {
      delete partyAddress.ApartmentNumber;
    }
    if (partyAddress.AddressTel) {
      delete partyAddress.AddressTel;
    }
    if (partyAddress.AddressLine2) {
      delete partyAddress.AddressLine2;
    }

    const { tempCodeTable } = this.state;
    this.setState({
      cityCodeTable: tempCodeTable,
      areaCodeTable: tempCodeTable,
      streetCodeTable: tempCodeTable,
      poBoxCodeTable: tempCodeTable,
      partyAddress,
    });
    UpdateContext.forceUpdate('partyAddressInfoDialogReadOnlyPanel');
  },
});
export default PartyAddressInfoDialog;
