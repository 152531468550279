import {
  DataContext,
  UIBox,
  UIButton,
  UICell,
  UIDialog,
  UISmartPanelGrid,
  UIText,
  UISelect,
  MessageHelper,
} from 'RainbowUI';
import PartyAction from '../actions/PartyAction';
import DefinedCodeTable from '../codetable/CodeTable';
import PartyConst from '../const/PartyConst';

const PartyCreationInfo = React.createClass({
  getInitialState() {
    return {
      PartyType: PartyConst.PARTY_TYPE_IND,
      Party: {},
    };
  },

  render() {
    const { PartyType } = this.state;
    const partyType = PartyType;
    let createCustomerRender;
    if (partyType == PartyConst.PARTY_TYPE_IND) {
      createCustomerRender = this.createIndiviPartyRender();
    }
    if (partyType == PartyConst.PARTY_TYPE_ORG) {
      createCustomerRender = this.createOrgPartyRender();
    }
    if (partyType == PartyConst.PARTY_TYPE_VEN) {
      createCustomerRender = this.createVenPartyRender();
    }

    return <div>{createCustomerRender}</div>;
  },
  createNewParty() {
    const party = this.state.Party;
    const partyAction = new PartyAction();
    const partyType = this.state.PartyType;
    if (
      partyType == PartyConst.PARTY_TYPE_ORG &&
      !party.OrganizationIdNumber
    ) {
      MessageHelper.warning(
        i18n.PartyMsg.WarnMsg.Warn_0009,
        'Warning',
        MessageHelper.POSITION_TOP_RIGHT
      );
      return;
    }
    if (partyType == PartyConst.PARTY_TYPE_IND && !party.IdType) {
      MessageHelper.warning(
        i18n.PartyMsg.WarnMsg.Warn_0010,
        'Warning',
        MessageHelper.POSITION_TOP_RIGHT
      );
      return;
    }
    if (partyType == PartyConst.PARTY_TYPE_IND && !party.IdNumber) {
      MessageHelper.warning(
        i18n.PartyMsg.WarnMsg.Warn_0010,
        'Warning',
        MessageHelper.POSITION_TOP_RIGHT
      );
      return;
    }
    if (partyType == PartyConst.PARTY_TYPE_VEN) {
      const conditions = { PartyName: party.JointVentureName };
      const fuzzyConditions = {};
      const orConditions = {};
      const result = partyAction.searchPartyByConditions(
        conditions,
        orConditions,
        fuzzyConditions,
        '4',
        1,
        10
      );

      if (result.count > 0) {
        MessageHelper.error(
          'JointVentureName is Existed',
          '',
          MessageHelper.POSITION_TOP_RIGHT
        );
        return;
      }
    }
    DataContext.remove('partyCodeTableData');
    const { IsIntegration } = this.props;
    if (IsIntegration) {
      if (partyAction.createIntegrationParty(party, partyType)) {
        UIDialog.hide('createPartyDialog');
      }
    } else if (partyAction.createParty(party, partyType)) {
      UIDialog.hide('createPartyDialog');
    }
  },

  handleCloseDialog() {
    UIDialog.hide('createPartyDialog');
  },
  createOrgPartyRender() {
    const _self = this;
    const { Party } = this.state;
    const party = Party;
    return (
      <div>
        <UISmartPanelGrid column="2">
          <UISelect
            label={i18n.PartyCategory}
            codeTable={DefinedCodeTable.getCategroyCodeTable}
            required="true"
            showBlankOption="false"
            onChange={_self.changePartyCategory.bind(this)}
            value={_self.getCategory}
          />
          <UICell />
          <UIText
            label={i18n.CompanyNo}
            required="true"
            model={party}
            property="OrganizationIdNumber"
            validationGroup="createParty"
          />
        </UISmartPanelGrid>
        <UIBox direction="center">
          <UIButton
            value={i18n.Save}
            onClick={this.createNewParty}
            causeValidation="true"
            validationGroup="createParty"
          />
          <UIButton
            value={i18n.Cancel}
            onClick={this.handleCloseDialog}
          />
        </UIBox>
      </div>
    );
  },

  createVenPartyRender() {
    const _self = this;
    const { Party } = this.state;
    const party = Party;
    return (
      <div>
        <UISmartPanelGrid column="2">
          <UISelect
            label={i18n.PartyCategory}
            codeTable={DefinedCodeTable.getCategroyCodeTable}
            required="true"
            showBlankOption="false"
            onChange={_self.changePartyCategory.bind(this)}
            value={_self.getCategory}
          />
          <UICell />
          <UIText
            label={i18n.JointVentureName}
            model={party}
            property="JointVentureName"
            required="true"
            validationGroup="createParty"
          />
        </UISmartPanelGrid>
        <UIBox direction="center">
          <UIButton
            value={i18n.Save}
            onClick={this.createNewParty}
            causeValidation="true"
            validationGroup="createParty"
          />
          <UIButton
            value={i18n.Cancel}
            onClick={this.handleCloseDialog}
          />
        </UIBox>
      </div>
    );
  },

  changePartyCategory(data) {
    const partyType = data.newValue;

    this.setState({
      PartyType: partyType,
      Party: {},
    });
  },

  getCategory() {
    const partyType = this.state.PartyType;
    return partyType;
  },

  createIndiviPartyRender() {
    const _self = this;
    const { Party } = this.state;
    return (
      <div>
        <UISmartPanelGrid column="2">
          <UISelect
            label={i18n.PartyCategory}
            codeTable={DefinedCodeTable.getCategroyCodeTable}
            widthAllocation="5,7"
            required="true"
            showBlankOption="false"
            value={_self.getCategory}
            onChange={_self.changePartyCategory.bind(this)}
          />
          <UICell />
          <UISelect
            label={i18n.IdType}
            widthAllocation="5,7"
            codeTable={DefinedCodeTable.IdType}
            required="true"
            model={Party}
            property="IdType"
            validationGroup="createParty"
          />
          <UIText
            label={i18n.IdNo}
            widthAllocation="5,7"
            required="true"
            model={Party}
            property="IdNumber"
            validationGroup="createParty"
          />
        </UISmartPanelGrid>
        <UIBox direction="center">
          <UIButton
            value={i18n.Save}
            onClick={this.createNewParty}
            causeValidation="true"
            validationGroup="createParty"
          />
          <UIButton
            value={i18n.Cancel}
            onClick={this.handleCloseDialog}
          />
        </UIBox>
      </div>
    );
  },
});

export default PartyCreationInfo;
