import { UIDialog, UIPanel } from 'RainbowUI';

const RemoveSettlementTypeWarning = React.createClass({
  render() {
    return (
      <UIDialog
        id="removeSettlementTypeDialog"
        width="600px"
        height="600px"
        modal="false"
      >
        <UIPanel
          id="removewarning"
          panelTitle={i18n.ClaimProduct.Warn}
          styleClass="danger"
        >
          <h5>{i18n.ClaimProduct.removeProductFailMessage}</h5>
        </UIPanel>
      </UIDialog>
    );
  },
});
export default RemoveSettlementTypeWarning;
