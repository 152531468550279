import { AjaxUtil, I18NUtil } from 'RainbowUI';
import { ClaimCstUI } from 'ClaimCommonConstant';
import ClaimCommonUrl from '../url/Url';
import ClaimAuthorityAction from '../action/ClaimAuthorityAction';

const UserUtil = {
  initUser() {
    console.log(
      `get current user from:${ClaimCommonUrl.CurrentUserInformation}`
    );
    AjaxUtil.doGet(
      ClaimCommonUrl.CurrentUserInformation,
      {},
      {
        async: false,
        done(data) {
          if (data.Model) {
            sessionStorage.setItem(
              'user',
              JSON.stringify(data.Model)
            );
          }
          console.log(`Current user:${JSON.stringify(data)}`);
          if (!I18NUtil.getSystemI18N())
            I18NUtil.setSystemI18N(data.Model.LangId);
          env.UserId = data.Model.UserId;
          env.UserName = data.Model.UserName;
          env.UserRealName = data.Model.RealName;
        },
        fail() {
          console.log('No current user.');
        },
      }
    );

    console.log(
      `get user permissions from:${ClaimCommonUrl.UserPermissionCodes}`
    );

    env.UserMenuCodes = ClaimAuthorityAction.getPermissionCodes(ClaimCstUI.URP_MODULE_GROUP_ID_MENU);

    // AjaxUtil.doGet(
    //   `${ClaimCommonUrl.UserPermissionCodes}?moduleId=${ClaimCstUI.URP_MODULE_ID}&moduleGroupId=${ClaimCstUI.URP_MODULE_GROUP_ID_MENU}`,
    //   {},
    //   {
    //     async: false,
    //     done(data) {
    //       console.log(`Permission Codes:${JSON.stringify(data)}`);
    //       env.UserMenuCodes = data;
    //     },
    //     fail() {
    //       console.log('load Permission Codes fail.');
    //     },
    //   }
    // );
  },


  hasMenu(menuCode) {
    let hasMenu = false;
    const menuCodes = env.UserMenuCodes;
    if (menuCodes) {
      for (let i = 0; i < menuCodes.length; i += 1) {
        if (menuCodes[i] == menuCode) {
          hasMenu = true;
          break;
        }
      }
    }
    return hasMenu;
  },

  hasRole(role) {
    if (role === 'Claim Manager' && env.UserName === 'Manager001') {
      return true;
    }
    return false;
  },
};

export default UserUtil;
