import { PartyCommon_i18n_en_US } from 'EU00PartyCommonConstant';

export default jQuery.extend(
  {
    ClaimNotice_i18n_en_US: require('./module/notice/common/i18n/i18n-notice-common.en_US'),
    ClaimNotice_i18n_zh_CN: require('./module/notice/common/i18n/i18n-notice-common.zh_CN'),
    ClaimNotice_i18n_ja_JP: require('./module/notice/common/i18n/i18n-notice-common.ja_JP'),
  },
  PartyCommon_i18n_en_US
);
