import { UIDialog } from 'RainbowUI';
import PartyCreationInfo from './PartyCreationInfo';

const PartyCreationDialog = React.createClass({
  getInitialState() {
    return { party: {} };
  },

  showPartyCreateDialog(partyType) {
    const party = {};
    party.PartyType = partyType;
    this.setState({ party });
    UIDialog.hide('SearchPartyRole');
    UIDialog.show('createPartyDialog');
  },

  render() {
    const { faxIndividual, IsIntegration } = this.props;
    const displayPage = (
      <PartyCreationInfo
        faxIndividual={faxIndividual}
        IsIntegration={IsIntegration}
      />
    );

    return (
      <UIDialog
        title={i18n.CreateParty}
        width="80%"
        id="createPartyDialog"
      >
        {{ displayPage }}
      </UIDialog>
    );
  },
});
export default PartyCreationDialog;
