import {
  UIBox,
  UIParam,
  UILink,
  DataContext,
  UIButton,
  UIDataTable,
  UIColumn,
} from 'RainbowUI';

const SettlementTypeDialog = require('./SettlementTypeDialog');
const RemoveSettlementTypeWarning = require('./RemoveSettlementTypeWarning');
const RemoveProductLineConfirmDialog = require('./RemoveProductLineConfirmDialog');
const SettlementTypeAction = require('../action/SettlementTypeAction');

const SettlementTypeList = React.createClass({
  getInitialState() {
    return { count: 0 };
  },

  getSettlementTypeList(pageindex, pagesize) {
    const settlementTypeList = SettlementTypeAction.getSettlementTypeList(
      pageindex,
      pagesize
    );
    return settlementTypeList;
  },

  showSettlementTypeDetail(event) {
    let data = event.getParameter('data');
    const flag = {};
    if (!data) {
      data = {};
      flag.out = 'in';
    } else {
      flag.out = 'out';
    }

    this.refs.SettlementTypeDialog_ref.showDialog(data, flag);
  },

  updatePage() {
    this.setState(prevState => ({ count: prevState.count + 1 }));
  },

  removeSettlementTypeConfirmDialog(event) {
    const data = event.getParameter('data');
    data.removeType = 'settlement';
    DataContext.put('data', data);
    this.refs.RemoveProductLineConfirmDialog_ref.showDialog(data);
  },

  render() {
    const self = this;
    return (
      <div>
        <UIDataTable
          indexable="false"
          id="settlementTypeListPage"
          provider={this.getSettlementTypeList}
        >
          <UIColumn
            headerTitle={i18n.ClaimProduct.SettlementTypeCode}
            render={data => {
              return (
                <u>
                  <UILink
                    value={data.PaymentTypeCode}
                    onClick={self.showSettlementTypeDetail}
                  >
                    <UIParam name="data" value={data} />
                  </UILink>
                </u>
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.ClaimProduct.SettlementTypeName}
            value="PaymentTypeName"
          />
          <UIColumn
            headerTitle={i18n.ClaimProduct.Description}
            value="PaymentTypeDescription"
          />
          <UIColumn
            headerTitle={i18n.ClaimProduct.Action}
            render={data => {
              return (
                <UILink
                  value={i18n.ClaimProduct.Remove}
                  icon="fa fa-trash"
                  onClick={self.removeSettlementTypeConfirmDialog}
                >
                  <UIParam value={data} name="data" />
                </UILink>
              );
            }}
          />
        </UIDataTable>
        <UIBox direction="left">
          <UIButton
            value={i18n.ClaimProduct.New}
            id="new_"
            onClick={this.showSettlementTypeDetail}
          />
        </UIBox>
        <SettlementTypeDialog
          ref="SettlementTypeDialog_ref"
          parentComponet={this}
        />
        <RemoveSettlementTypeWarning />
        <RemoveProductLineConfirmDialog
          ref="RemoveProductLineConfirmDialog_ref"
          parentComponet={this}
        />
      </div>
    );
  },
});
export default SettlementTypeList;
