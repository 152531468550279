export default {
  ProductLineCode: '商品ラインコード',
  ProductLineName: '商品ライン名称',
  ProductLineDescription: '商品ライン詳細',
  ProductCode: '商品コード',
  ProductName: '商品名称',
  ProductDescription: '商品詳細',
  ExportProduct: '商品エクスポート',
  ExtProductCode: '契約システムコード',
  ProductTypeCode: '商品種類コード',
  ProductTypeName: '商品種類名称',
  ProductTypeDescription: '商品種類詳細',
  ExtProductTypeCode: '契約システムコード',
  OptionalCoverageCategories: '選択可能な保障カテゴリ',
  ApplicableCoverageCategories: '適用する保障カテゴリ',
  CoverageCode: '保障コード',
  CoverageName: '保障名称',
  CoverageDescription: '保障詳細',
  Coverage: '保障',
  CoverageCategory: '保障カテゴリ',
  ReserveType: '備金種類',
  DefaultReserve: 'ディフォルト備金',
  SettlementTypeList: '決済タイプリスト',
  SortNo: 'ソード番号',
  ExtCoverageCode: '契約システムコード',
  ExtCoverageTypeCode: '商品保障種類コードProduct Cover Type Code',
  SumInsuredEditable: '編集可能な保険金額',
  DefaultCoverage: 'ディフォルト保障',
  AvailableClaimingCoverage: '請求可能な保障',

  ReserveTypeCode: '備金種類コード',
  ReserveTypeName: '備金種類名称',
  ReserveTypeDescription: '備金種類詳細',
  ClaimFileType: '請求ファイル種類',
  MultiLanguage: '多言語',
  ReserverType: '備金種類',
  Positive: '同意',
  SettlementTypeCode: '決済種類コード',
  SettlementTypeName: '決済種類名称',
  Description: '詳細',
  SettlementTypeManagement: '決済種類管理',
  CoverageCategoryCode: '保障カテゴリコード',
  CoverageCategoryName: '保障カテゴリ名称',
  CoverageCategoryDescription: '保障カテゴリ詳細',
  CauseofLossCode: '事故原因コード',
  CauseofLossName: '事故原因',
  CauseofLossDescription: '事故原因詳細',
  CauseofLoss: '事故原因',
  SubclaimTypeCode: 'サブクレーム種類コード',
  SubclaimTypeName: 'サブクレーム種類名称',
  SubclaimTypeDescription: '詳細',
  SubclaimTypeManagement: 'サブクレーム種類管理',
  IsUnique: 'ユニークな',
  IsInsuredObject: '関係者限定',
  // relation
  SubclaimType: 'サブクレーム種類',
  DamageTypeList: '損害種類リスト',
  OptionalDamageTypes: '選択可能な損害種類',
  ApplicableDamageTypes: '適用損害種類',
  SubClmTypeDescription: 'サブクレーム種類詳細',
  CoverageCategoryList: '保障カテゴリリスト',
  ListofReserveType: '備金種類リスト',
  OptionalReserveTypes: '選択可能な備金種類',
  ApplicableReserveTypes: '適用な備金種類',
  OptionalSettlementTypes: '選択可能な決済種類',
  ApplicableSettlementTypes: '適用な決済種類',
  ProductTree: 'Product Tree',
  CoverageDetail: 'Coverage Detail',
  Warn: 'Warning',
  ConfirmMessage: 'Are you sure to remove ?',
  // 按钮
  Remove: '削除',
  Action: 'アクション',
  Save: '保存',
  Cancel: 'キャンセル',
  Search: '検索',
  New: '新規',

  // Message
  SaveSuccess: '保存しました',
  SaveProductLine: '商品ライン保存しました',
};
