import { UIPanel, MessageHelper } from 'RainbowUI';
import {
  ApprovalInfo,
  ApprovalHistory,
  ClaimTaskTransferDialog,
} from 'ClaimCommon';
import BindToMixin from 'react-binding';
import PropTypes from 'prop-types';
import ClaimCommonExt from 'ClaimCommonExt';
import ClaimCalculationExt from 'ClaimCalculationExt';

const CalculationHistory = require('./CalculationHistory');
const CalculationReadOnly = require('./CalculationReadOnly');
const CalculationLoadAction = require('../action/CalculationLoadAction');
const CalculationSubmitAction = require('../action/CalculationSubmitAction');
const CalculationApproveStore = require('../store/CalculationApproveStore');

const CalculationValidationAction = ClaimCalculationExt.getClaimCalculationValidationAction();

const ClaimBaseInfo = ClaimCommonExt.getClaimBaseInfo();

const CalculationApprove = React.createClass({
  mixins: [BindToMixin],
  propTypes: {
    taskInstanceId: PropTypes.string.isRequired,
    caseId: PropTypes.string.isRequired,
  },

  getInitialState() {
    return {
      approveInfo: {
        BusinessId: null,
        TaskInstanceId: null,
        CaseId: null,
        Remark: '',
        DecisionCode: null,
      },
    };
  },
  onStoreStateChange(storeState) {
    if (!_.isEmpty(storeState.approveInfo)) {
      this.setState({ approveInfo: storeState.approveInfo });
    }
  },
  componentWillMount() {
    CalculationApproveStore.listen(this.onStoreStateChange);
    CalculationLoadAction.loadApproveTask(this.props.taskInstanceId);
  },
  componentWillUnmount() {
    CalculationApproveStore.unlisten(this.onStoreStateChange);
  },

  render() {
    const _self = this;
    const { approveInfo } = this.state;
    const { claimEntity, caseId, readOnly } = this.props;
    const calculateId = approveInfo.BusinessId;
    const CommonConfirmDialog = ClaimCommonExt.getCommonConfirmDialog();
    if (
      calculateId === null ||
      calculateId === undefined ||
      calculateId === ''
    ) {
      return (
        <div>
          <ClaimBaseInfo claimEntity={claimEntity} />
          <CalculationHistory caseId={caseId} />
        </div>
      );
    }

    return (
      <div>
        <ClaimBaseInfo claimEntity={claimEntity} />
        <div>
          <UIPanel
            id={`calculationApproveTaskPanel_${_self.props.taskInstanceId}`}
            panelTitle={i18n.ClaimCalculation.CalculationPanelTitle}
            toggleable="false"
          >
            <CalculationReadOnly
              calculateId={calculateId}
              readOnly
              isApproveTask
            />
          </UIPanel>
        </div>
        <ApprovalInfo
          decision={this.bindToState('approveInfo', 'DecisionCode')}
          remark={this.bindToState('approveInfo', 'Remark')}
          onClick={_self.onClickConfirm}
        />
        <ApprovalHistory processObjectId={calculateId} />
        <CalculationHistory caseId={_self.props.caseId} />
        <ClaimTaskTransferDialog
          ref="ClaimTaskTransferDialog"
          componentId="cal_cul_app"
        />
        <CommonConfirmDialog
          ref="confirmDialog_ref"
          parentComponent={this}
          objectId={`_calculationApprove${readOnly}_${calculateId}`}
          onConfirm={this.confirm}
          onCancel={this.cancel}
        />
      </div>
    );
  },

  confirm() {
    this.state.approveInfo.CaseId = this.props.caseId;
    const flag = CalculationSubmitAction.approveCalculation(
      this.state.approveInfo
    );
    if (flag) {
      this.refs.ClaimTaskTransferDialog.showDialog({
        CaseId: this.props.caseId,
      });
    }
  },
  validate() {
    const result = CalculationValidationAction.validateSILimitForCalculationApproval(
      this.state.approveInfo
    );
    if (result && result.Status === 'WARN') {
      this.refs.confirmDialog_ref.showDialog(
        i18n.ClaimCalculation.Calculation,
        result.Messages[0].Message
      );
      return true;
    }
    if (result && result.Status === 'BLOCK') {
      MessageHelper.error('', result.Messages[0].Message);
      return true;
    }
  },
  cancel() {},
  onClickConfirm() {
    if (this.validate()) {
      return;
    }
    this.confirm();
  },
});
export default CalculationApprove;
