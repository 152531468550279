import { AjaxUtil } from 'RainbowUI';
import Url from '../url/Url';

export default {
  loadRelation(subclaimTypeCode) {
    const url =
      Url.ClaimProductRelationOfSubclaimTypeCoverageCategoryLoad +
      subclaimTypeCode;
    let list = null;
    AjaxUtil.doGet(
      url,
      {},
      {
        async: false,
        done(data) {
          list = data;
        },
      }
    );
    return list.Model;
  },

  loadCoverageCategory(productLineCode) {
    const url =
      Url.ClaimProductRelationOfSubclaimTypeCoverageCategory +
      productLineCode;
    let list = null;
    AjaxUtil.doGet(
      url,
      {},
      {
        async: false,
        done(data) {
          list = data;
        },
      }
    );
    return list.Model;
  },
  updateCoverageCategory(claimRelationData, subclaimTypeCode) {
    const url =
      Url.ClaimProductRelationOfSubclaimTypeCoverageCategoryUpdate +
      subclaimTypeCode;
    AjaxUtil.doPost(url, claimRelationData, {
      async: false,
      done() {},
    });
  },
};
