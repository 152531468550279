import { UIDialog, MessageHelper } from 'RainbowUI';
import alt from '../alt';

const ReserveService = require('../service/ReserveService');

class ReserveHistoryAction {
  showHistory(event) {
    const reservehistory = event.getParameter('reservehistory');
    ReserveService.reserveHistory(reservehistory.itemId).then(
      data => {
        if (data.Status === 'OK') {
          this.dispatch(data.Model);
          UIDialog.show(`historyDialog_${reservehistory.objectId}`);
        } else {
          MessageHelper.error(data.Messages[0].Message, '');
        }
      }
    );
  }

  updateReserveHistory(reserveHisEntities) {
    ReserveService.updateReserveHistory(reserveHisEntities).then(
      data => {
        if (data.Status === 'OK') {
          this.dispatch(data.reservehistory);
        } else {
          MessageHelper.error(data.Messages[0].Message, '');
        }
      }
    );
  }

  chooseHistory(reserveType) {
    this.dispatch(reserveType);
  }

  changeHistory(event) {
    const data = event.getParameter('data');
    const showHistoryList = event.getParameter('showHistoryList');
    const currentMonthDate = event.getParameter('currentMonthDate');
    if (event.newValue === 'Y') {
      const currentMonth = new Date(data.InsertTime.substr(0, 7));
      currentMonth.setHours(0);
      for (let i = 0; i < showHistoryList.length; i += 1) {
        const oneList = showHistoryList[i];
        if (data.HisId == oneList.HisId) {
          const tempDate = new Date(oneList.InsertTime.substr(0, 7));
          tempDate.setHours(0);
          tempDate.setMilliseconds(tempDate.getMilliseconds() - 1);
          oneList.PostDate = Date.parse(tempDate);
        }
        if (
          new Date(oneList.InsertTime) > currentMonthDate &&
          new Date(data.InsertTime) > new Date(oneList.InsertTime) &&
          oneList.ReserveType == data.ReserveType &&
          new Date(oneList.InsertTime) > currentMonth
        ) {
          oneList.RetroacitveAdjust = 'Y';
          const tempDate = new Date(oneList.InsertTime.substr(0, 7));
          tempDate.setHours(0);
          tempDate.setMilliseconds(tempDate.getMilliseconds() - 1);
          oneList.PostDate = Date.parse(tempDate);
        }
      }
    } else {
      for (let i = 0; i < showHistoryList.length; i += 1) {
        const oneList = showHistoryList[i];
        if (data.HisId == oneList.HisId) {
          oneList.PostDate = oneList.InsertTime;
        }
      }
    }
    this.dispatch(showHistoryList);
  }
}
export default alt.createActions(ReserveHistoryAction);
