import { CodeTable } from 'RainbowUI';
import RemoteCodeTable from '../../../../party/js/common/codetable/RemoteCodeTable';

export default {
  statusIsOpen(status) {
    if (
      status === 'OPEN' ||
      status === 'REOPEN' ||
      status === 'NEW'
    ) {
      return true;
    }
    return false;
  }
};

export const getRegionPostBody = (countryCode, regionLevel, parentRegionId) => {
  const body = {
    COUNTRY_CODE: countryCode,
    REGION_LEVEL: regionLevel
  };

  if (parentRegionId && !isNaN(parentRegionId)) {
    body['PARENT_REGION_ID'] = parentRegionId;
  }
  return body;
};

export const getRegionData = (countryCode, regionLevel, parentRegionId) => {
  if (countryCode) {
    const body = getRegionPostBody(countryCode, regionLevel, parentRegionId);
    return RemoteCodeTable.getCodeTableByCondition(
      1605415,
      body
    )
  }
  return new CodeTable([]);
};
