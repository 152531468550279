export default {
  itemToAdd: {
    itemIndex: 0,
    payeeIndex: 0,
    setItemIndex(index) {
      this.itemIndex = index;
    },
    setPayeeIndex(index) {
      this.payeeIndex = index;
    },
    get() {
      return {
        '@pk': null,
        '@type': 'ClaimSettlementItem-ClaimSettlementItem',
        Index: this.itemIndex,
        PayeeIndex: this.payeeIndex,
        ReserveCurrency: '',
        SettleAmount: null,
      };
    },
  },

  payeeToAdd: {
    payeeIndex: 0,
    setPayeeIndex(index) {
      this.payeeIndex = index;
    },
    get() {
      return {
        '@pk': null,
        '@type': 'ClaimSettlementPayee-ClaimSettlementPayee',
        Index: this.payeeIndex,
        SettlementItem: [
          {
            '@pk': null,
            '@type': 'ClaimSettlementItem-ClaimSettlementItem',
            Index: 0,
            PayeeIndex: this.payeeIndex,
            ReserveCurrency: ' ',
            SettleAmount: null,
          },
        ],
      };
    },
  },

  newSettlement: {
    '@pk': null,
    '@type': 'ClaimSettlement-ClaimSettlement',
    SettlementPayee: [
      {
        '@pk': null,
        '@type': 'ClaimSettlementPayee-ClaimSettlementPayee',
        Index: 0,
        SettlementItem: [
          {
            '@pk': null,
            '@type': 'ClaimSettlementItem-ClaimSettlementItem',
            Index: 0,
            PayeeIndex: 0,
            ReserveCurrency: ' ',
            SettleAmount: 0.0,
          },
        ],
      },
    ],
  },
};
