export default {
  home: 'Dashboard',
  query: '照会',
  application: '申請',
  collection: '入金',
  payment: '支払',
  internal: '内部',
  paymentApproval: '支払請求',
  reverseApplication: 'リバース申請&相殺',
  reversePayment: '支払リバース',
  reverseReceipt: 'リバース領収書',
  toleranceOffset: '小額相殺',
  arapOffset: '買掛売掛相殺',
  arap: '買掛売掛',
  arapNo: '買掛売掛番号',
  arapType: '買掛売掛種類',
  arapGenerationDateFrom: '買掛売掛作成日From',
  arapGenerationDateTo: '買掛売掛作成日To',
  arapDueDateFrom: '買掛売掛期日From',
  arapDueDateTo: '買掛売掛期日To',
  branch: '枝番',
  policyNo: '証券番号',
  agencyBroker: '代理人/ブローカ',
  policyEndtEffectiveDateFrom: '主契約/特約発効日From',
  policyEndtEffectiveDateTo: '主契約/特約発効日To',
  search: '検索',
  arapGenerationDate: '買掛売掛作成日',
  policyPeriod: '保険期間',
  endorsementNo: '特約番号',
  installmentNo: '有期払込番号',
  arapAmount: '買掛売掛金額',
  commission: '手数料',
  tax: '税金',
  fee: '料金',
  amount: '金額',
  offsetAmount: '相殺金額',
  balance: '残額',
  dueDate: '期日',
  submit: '送信',
  exit: '閉じる',
  receiptInfo: '領収書情報',
  receiptNo: '領収書番号',
  payer: '支払人',
  collectionAmount: '入金額',
  remainingBalance: '残高',
  claimNo: 'クレーム番号',
  broker: 'ブローカ',
  insured: '被保険者',
  endtNo: '特約番号',
  generationDate: '作成日',
  currency: '通貨',
  appliedAmount: 'Applied Amount',
  arapAmountTotal: 'ARAP Amount Total',
  appliedAmountTotal: 'Applied Amount Total',
  collectionInfo: '入金情報',
  paymentMethod: '支払経路',
  bankAccount: '口座番号',
  collectionDate: '入金日',
  payerName: '支払人名',
  payerCode: '支払人コード',
  payerType: '支払人種類',
  select: '選択',
  payerAddress: '支払人住所',
  receipt: '入金',
  receiptSearch: '入金検索',
  collectionDateFrom: '入金日From',
  collectionDateTo: '入金日To',
  result: '結果',
  commissionSearch: '手数料検索',
  commissionStatus: '手数料状態',
  commissionGenerationDateFrom: '手数料生成日From',
  commissionGenerationDateTo: '手数料生成日To',
  commissionGenerationDate: '手数料生成日',
  commissionAmount: '手数料',
  statusChange: '状態変更',
  paymentDetail: '支払詳細',
  Language: '言語',
  English: '英語',
  Chinese: '中国語',
  Japanese: '日本語',
  ChangePassWord: 'Change PassWord',
  payerInfo: '支払人情報',
  salesChannel: '販売チャンネル',
  paidAmount: '支払金額',
  paymentRequest: '支払請求',
  submitforApproval: '承認待ち',
  paymentInfo: '支払情報',
  payeeCode: '支払先コード',
  payeeName: '支払先名',
  bank: '銀行',
  bankAccountNo: '口座番号',
  paymentAmount: '支払金額',
  companyBankAccount: '法人口座番号',
  paymentSearch: '支払検索',
  paymentNo: '支払番号',
  requestor: '請求者',
  requestDateFrom: '請求日From',
  requestDateTo: '請求日To',
  paymentRequestDateFrom: '支払日に申請する',
  paymentRequestDateTo: '支払日に申請する ',
  payee: '支払先',
  payeeBank: '支払先銀行',
  payeeBankAccountNo: '支払先口座番号',
  arapResult: '買掛売掛結果',
  transactionNo: '処理番号',
  justNew: '新規',
  paymentRequestDate: '支払請求日',
  paymentApprovalRejectDate: '支払承認日',
  approverRejecter: '承認者',
  action: 'アクション',
  suspense: '仮勘定',
  suspenseNo: '仮勘定番号',
  suspenseType: '仮勘定種類',
  suspenseGenerationDateFrom: '仮勘定作成日From',
  suspenseGenerationDateTo: '仮勘定作成日To',
  statementNo: '明細書番号',
  suspenseResult: '仮勘定結果',
  suspenseGenerationDate: '仮勘定作成日',
  paymentApprovalDate: '支払承認日',
  approver: '承認者',
  paymentSuccessDate: '支払完了日',
  modify: '訂正',
  arapDetailInformation: '買掛売掛詳細情報',
  transactionType: '取引種類',
  transactionDate: '取引日',
  transactionAmount: '取引金額',
  operator: '処理者',
  transactionStatus: '取引状態',
  reversalDate: '復旧日',
  reversalOperator: '復旧処理者',
  arapStatus: '買掛売掛状態',
  arapSearch: '買掛売掛検索',
  policyNoStatementNo: '証券番号/明細書番号',
  applicationOffsetSearch: '申請/相殺検索',
  transactionDateFrom: '取引日From',
  transactionDateTo: '取引日To',
  reversalReason: '復旧事由',
  approvalDateFrom: '承認日From',
  approvalDateTo: '承認日To',
  collectionCurrency: '入金通貨',
  depositDate: '預金日',
  suspenseSearch: '仮勘定検索',
  generationDateFrom: '作成日From',
  generationDateTo: '作成日To',
  writeoffAmount: '相殺金額',
  reason: '事由',
  paymentEdit: '支払入力',
  requestDate: '請求日',
  decision: '決定',
  transactionHistory: '取引履歴',
  confirmDialog: '確定',
  successDialog: '完了',
  confirmMessage: '確定しますか?',
  paymentResult: '支払結果',
  collectionNoResult: '入金番号は ',
  suspenseNoResult: '仮勘定番号は ',
  receiptNoResult: '入金番号は ',
  paymentSubmitResult: '支払番号は ',
  paymentStatus: '支払状態',
  paymentReverseDate: '復旧日',
  reverse: '復旧処理者',
  queryARAP: '買掛売掛照会',
  queryPayment: '支払照会',
  queryReceipt: '入金照会',
  receiptOrSuspenseSearch: '入金/仮勘定検索',
  receiptStatus: '入金状態',
  cashBeforeCoverCollection: 'Cash Before Cover Collection',
  receiptAmount: '入金額',
  bankCharge: '銀行手数料',
  receiptBalance: '入金残高',
  quotationNo: '申込書番号',
  bankSlipNo: '銀行スリップ番号',
  receiptDetail: '入金詳細',
  applicationDate: '請求日',
  applicationAmount: '請求金額',
  queryApplication: '請求&相殺照会',
  applicationSearch: '請求&相殺検索',
  applicationDetail: '請求&相殺詳細',
  customerOrPayer: '顧客/支払者',
  offsetNo: '相殺番号',
  offsetType: '相殺種類',
  offsetDateFrom: '相殺日From',
  offsetDateTo: '相殺日To',
  offsetStatus: '相殺状態',
  referenceNo: '照会番号',
  referenceType: '照会種類',
  offsetDate: '相殺日',
  apAmountTotal: '売掛総額',
  arAmountTotal: '買掛総額',
  addToList: 'リストに追加',
  showAll: '全部表示',
  clearList: 'リストをクリアする',
  successMessage: 'The operation is successful. ',
  collectionType: '入金種類',
  suspenseDetail: '仮勘定詳細',
  operationDialog: 'Operation Result',
  operationMessage:
    'There is still small balance on the ARAP.Do you want to proceed to Tolerance Offset screen for further operation?',
  appliedError: 'Applied Amount Error.',
  totalError: 'Total Error.',
  additionSuccess: 'Addition is successful.',
  additionError: 'Addition is Error.',
  additionEmpty: 'Addition is empty.',
  clearSuccess: 'Clear is successful.',
  clearError: 'Clear is error.',
  applicationEmpty: 'Application can not be empty.',
  receiptEmpty: 'Receipt can not be empty.',
  receiptError: ' can not reverse, amount not equal to balance.',
  offsetAmountError: 'Offset Amount Error.',
  writeoffAmountError: 'Write-off Amount Error.',
  decisionEmpty: 'Decision can not be empty.',
  paidAmountError: 'Paid Amount Error.',
  amountEmpty: 'Amount can not be empty.',
  paymentEmpty: 'Payment can not be empty.',

  bankAccountMaintenance: 'Bank Account Maintenance',
  bankAccountSearch: 'Bank Account Search',
  bankAccountName: 'Bank Account Name',
  bankAccountStatus: 'Bank Account Status',
  add: 'Add',
  edit: 'Edit',
  Delete: 'Delete',
  bankAccountCode: 'Bank Account Code',
  foreignBank: 'Foreign Bank',
  bankAccountInfo: 'Bank Account Info',
  bankAccountEdit: 'Bank Account Edit',
  bankAccountRelation: 'Bank Account Relation',
  bankAccountType: 'Bank Account Type',
  newOrUpdate: 'New/Update',
  payeeType: 'Payee Type',
  endorsementType: 'Endorsement Type',
  Logout: 'Logout',
  note: 'Note',
  existingNote: 'Existing Note',
  addNewNote: 'Add New Note',
  receiptResult: 'Receipt Result',
  customerCategory: 'Customer Category',
  New: 'New',
  companyName: 'Company Name',
  firstName: 'First Name',
  middleName: 'Middle Name',
  lastName: 'Last Name',
  swiftCode: 'SWIFT Code',
  save: 'Save',
  payeeInfo: 'Payee Info',

  commissionStatusAdjustment: 'Commission Status Adjustment',
  bizType: 'Biz Type',
  commissionSettlementDateFrom: 'Commission Settlement Date From',
  commissionSettlementDateTo: 'Commission Settlement Date To',
  queryCommission: 'Query Commission',
  settlementDate: 'Settlement Date',
  statusChangeEmpty: 'Status Change can not be empty.',
  agencyBrokerName: 'Agency/Broker Name',
  agencyBrokerCode: 'Agency/Broker Code',
  commissionSettlement: 'Commission Settlement',
  settle: 'Settle',
  certificateNo: 'Certificate No.',
  swissReBankAccount: 'Swiss Re Bank Account',
  paymentPurpose: 'Payment Purpose',
  payeeSearch: 'Payee Search',
  endorsementEffDate: 'Endorsement Effective Date',
  exchangeRate: 'Exchange Rate',
  receiptAmountInCNY: 'Receipt Amount In CNY',
  alipayTransactionNO: 'Alipay Transaction NO.',
  alipayReferenceNO: 'Alipay Reference NO.',
  remainingReceiptBalance: '残高',
  remainingReceiptBalanceInCNY: 'Remaining Receipt Balance In CNY',
  arapAmountInCNY: 'ARAP Amount in CNY',
  exchangeRateARAP: 'Exchange Rate(ARAP:CNY)',
  balanceInCNY: 'Balance In CNY',
  reversalDateFrom: 'Reversal Date From',
  reversalDateTo: 'Reversal Date To',
  extractedDate: 'Extracted Date',
  paymentDate: 'Payment Date',
  approvedAndrejectedBy: 'Approved/Rejected By',
  reverseCollection: 'Reverse Collection',
  currentBankCharge: 'Current Bank Charge',
  commissionAmountInCNY: 'Commission Amount In CNY',
  queryEmptyError: 'You have to choose one search criteria',
  arapTypeBizType: 'ARAP/ Biz Type',
  reversalBy: 'Reversed By',
  approvalDate: 'Approval Date',
  status: 'Status',
  uploadCollection: 'Upload Collection',
  upload: 'Upload',
  uploadResult: 'Upload Result',
  uploadID: 'Upload ID',
  uploadUser: 'Upload User',
  uploadDateFrom: 'Upload Date From',
  uploadDateTo: 'Upload Date To',
  uploadStaus: 'Upload Status',
  uploadDateAndTime: 'Upload Date&Time',
  uploadFileName: 'File Name',
  uploadResultFile: 'Result File',
  uploadErrorMsg:
    'The file type is not accepted, only accept *.xls,*.xlsx file',
  currencyCodeError:
    'All the selected AP and Receipt/Suspense should be with same currency',
  statement: 'Statement',
  statementGeneration: 'Statement Generation',
  statementView: 'Statement View',
  generationStatement: 'Generate Statement',
  statementDateFrom: 'Statement Date From',
  statementDateTo: 'Statement Date To',
  statementGenerationUser: 'Statement Generation User',
  statementDate: 'Statement Date',
  uploadResultSuccessMessage:
    'The operation is successful. The uploadId is ',
  uploadResultFailureMessage: 'The operation is failed. ',
  exportToExcel: 'EXPORT TO EXCEL',
  exportConfirmDialog: 'Confirm',
  exportConfirmMessage: 'Do you confirm to export to the Excel?',
  releaseClaimPayment: 'Release Claim Payment',
  claimPaymentSearch: 'Claim Payment Search',
  release: 'Release',
  enterNegativeError: 'Enter the amount can not be negative.',
  enterPositiveError: 'Enter the amount can not be positive.',
  enterAmountError:
    'Enter the amount can not be greater than the balance.',
  totalAmountError:
    'The remaining receipt balance can not be less than zero and can not be greater than the original the remaining receipt balance.',
  selectError:
    'Please select the data and input box can not be empty.',
  arapAmountError:
    'The AR offset amount must equal the AP offset amount and the offset amount can not be less than zero.',
  arapFlagError: 'Please select the data.',
  authorityConfig: 'Authority Configuration',
  technicalAccountingDataAuthorityConfiguration:
    'Technical Account Data Authority Configuration',
  workLevel: 'Work Level',
  paymentApproalAmount: 'Payment Approval Amount',
  toleranceOffsetAmountOfARAP: 'Tolerance Offset Amount Of ARAP',
  writeOffAmountOfARAP: 'Write Off Amount Of ARAP',
  businessType: 'Business Type',
  customer: 'Customer',
  transactionEffectiveDate: 'Transaction Effective Date',
  cashInPurpose: 'Cash-in Purpose',
  taxpayerType: 'Taxpayer Type',
  taxRegistrationNo: 'Tax Registration No.',
  address: 'Address',
  tel: 'Tel',
  payerCreation:
    'The payer/payee creation or modification has been approved by manager.',
  payerOrPayee: 'Payer/Payee',
  masterPolicyNo: 'Master Policy No.',
  documentUpload: 'Documents',
  actualPaymentCurrency: 'Actual Payment Currency',
  actualPaymentAmount: 'Actual Payment Amount',
  approvalBy: 'Approved By',
  rejectReversalDate: 'Reject/Reversal Date',
  rejectReversalBy: 'Rejected/Reversed By',
  customerReinsurer: 'Customer/Reinsurer',
  documentUploadAndView: 'Document Upload And View',
  payerError: 'The payer/payee is required and cannot be empty.',
  configuration: 'Configuration',
  clearCache: 'Clear Cache',
  payeeError: 'Please select the payee.',
  bankAccountError: 'Please select the bank account.',
  bankInfoError: 'Please save the bank account information.',
  dmsUploadTip: 'Please upload reference document.',
  authorityAmountError:
    'The selected ARAP balance is higher than the user granted authority!',
  applicationButton: 'APPLICATION',
  payerDialog: 'Payer Dialog',
  payerSearch: 'Payer Search',
  payerResult: 'Payer Result',
  cancel: 'CANCEL',
  payeeDialog: 'Payee Dialog',
  payeeResult: 'Payee Result',
  reset: 'RESET',
  receiptDialog: 'Receipt Dialog',
  uploadSearch: 'Upload Search',
  reverseReceiptSearch: 'Reverse Receipt Search',
  reverseApplicationSearch: 'Reverse Application&Offset Search',
  applicationResult: 'Application&Offset Result',
  paymentApprovalSearch: 'Payment Approval Search',
  reversePaymentSearch: 'Reverse Payment Search',
  arapOffsetSearch: 'ARAP Offset Search',
  arapToleranceOffsetSearch: 'ARAP Tolerance Offset Search',
  bankAccountResult: 'Bank Account Result',
  bankAccountDialog: 'Bank Account Dialog',
  commissionStatusAdjustmentSearch:
    'Commission Status Adjustment Search',
  commissionResult: 'Commission Result',
  close: 'CLOSE',
};
