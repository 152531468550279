import { DataContext, AjaxUtil, MessageHelper } from 'RainbowUI';
import ProductDialogAction from './ProductDialogAction';
import ProductTypeAction from './ProductTypeAction';
import alt from '../alt';
import TreeNode from '../constant/TreeNodeCache';
import url from '../url/Url';

class ProductCfgTreeAction {
  initData() {
    let result = {};
    AjaxUtil.doGet(url.ClaimProductTreeNode, null, {
      async: false,
      done(data) {
        result = data;
      },
      fail(data) {
        MessageHelper.error(
          i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
        );
        result = data;
        result.result = 'FAIL';
      },
    });
    const result_toLower = [];
    for (const key in result) {
      const param = {};
      for (const jsonKey in result[key]) {
        const lowKey =
          jsonKey.substring(0, 1).toLowerCase() +
          jsonKey.substring(1, jsonKey.length);
        param[lowKey] = result[key][jsonKey];
      }
      result_toLower[key] = param;
    }
    this.dispatch(result_toLower);
  }

  loadNode(event, treeId, tempTreeNode) {
    const treeNode = tempTreeNode;
    TreeNode.TreeCache.put('TreeNode', treeNode);

    if (treeNode.treeNodeUrl === 'productTypeNode') {
      const productType = {};
      productType.out = 'out';
      DataContext.put('showProductType', productType);
      ProductTypeAction.initProducttype(treeNode);
    } else if (treeNode.treeNodeUrl === 'productDetail') {
      treeNode.objectMap.pId = treeNode.treeNodeCode;
      ProductDialogAction.initProduct(treeNode.objectMap.id);
    } else {
      this.dispatch(treeNode);
    }
  }

  trim(str) {
    return str.replace(/(^\s*)|(\s*$)/g, '').toUpperCase();
  }
}
export default alt.createActions(ProductCfgTreeAction);
