import { UIDataTable, UIColumn, UINumber } from 'RainbowUI';

const PolicyDetailCoverage = React.createClass({
  getInitialState() {
    return { PolicyEntity: this.props.policyInfo };
  },

  componentWillReceiveProps(nextProps) {
    this.setState({ PolicyEntity: nextProps.policyInfo });
  },
  getParentComponent() {
    return this;
  },

  render() {
    const { PolicyEntity } = this.state;
    return (
      <div>
        <UIDataTable
          id="coverageList"
          value={PolicyEntity.InsuredList[0].CoverageList}
          indexable="false"
          infoable="true"
          isHeading="true"
          pageable="false"
          headerTitle={i18n.ClaimCommon.CoverageList}
        >
          <UIColumn
            headerTitle={i18n.ClaimCommon.CoverageName}
            value="CoverageName"
          />
          <UIColumn
            headerTitle={i18n.ClaimCommon.DeductibleAmount}
            value="DeductibleAmount"
          >
            <UINumber
              id="NUMBER_CODE_DeductibleAmount"
              io="out"
              value="DeductibleAmount"
            />
          </UIColumn>
          <UIColumn
            headerTitle={i18n.ClaimCommon.DeductibleRate}
            value="DeductibleRate"
          >
            <UINumber
              id="NUMBER_CODE_DeductibleRate"
              io="out"
              value="DeductibleRate"
            />
          </UIColumn>
          <UIColumn
            headerTitle={i18n.ClaimCommon.SumInsured}
            value="SumInsured"
          >
            <UINumber
              id="NUMBER_CODE_SumInsured"
              io="out"
              value="SumInsured"
            />
          </UIColumn>
        </UIDataTable>
      </div>
    );
  },
});
export default PolicyDetailCoverage;
