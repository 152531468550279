import {
  UICell,
  UIDataTable,
  UIColumn,
  UIDialog,
  MessageHelper,
  UIUpdatePanel,
  DataContext,
  UIText,
  UISelect,
  UIButton,
  UIBox,
} from 'RainbowUI';
import BindToMixin from 'react-binding';
import MassiveReassignmentClaimOwnerAction from '../../action/MassiveReassignmentClaimOwnerAction';

const CodeTableAction = require('../../action/CodeTableAction');
const TaskTransferAction = require('../../action/TaskTransferAction');
const CodeTableCstUI = require('../../constant/CodeTableCstUI');

const MassiveReassignmentClaimOwnerDialog = React.createClass({
  mixins: [BindToMixin],
  getInitialState() {
    return { assignToObj: {} };
  },
  save() {
    const claimList1 = DataContext.get('claimList');
    const massiveReassignmentAssignToVO = {};
    if (!this.state.assignToObj.AssignTo) {
      MessageHelper.info(
        i18n.ClaimCommon.NoAssignToSelected,
        i18n.ClaimCommon.MassiveReassignmentClaimowner,
        MessageHelper.POSITION_TOP_FULL_WIDTH
      );
      return;
    }
    for (let i = 0; i < claimList1.length; i += 1) {
      delete claimList1[i].dataIndex;
    }
    massiveReassignmentAssignToVO.ClaimList = claimList1;
    massiveReassignmentAssignToVO.AssignTo = this.state.assignToObj.AssignTo;
    const result = MassiveReassignmentClaimOwnerAction.submitAssginTo(
      massiveReassignmentAssignToVO
    );
    UIDialog.hide('massiveReassignmentClaimOwnerDialog');
    UIDialog.show('reassignToResultDialog');
    this.setState({ susList: result.susList });
    this.setState({ failList: result.failList });
  },
  okToClose() {
    UIDialog.hide('reassignToResultDialog');
    this.props.updateparentUI();
  },
  userShow() {
    return TaskTransferAction.TaskAuthoryUserList();
  },
  render() {
    const { updateparentUI } = this.state;
    return (
      <div>
        <UIDialog
          id="massiveReassignmentClaimOwnerDialog"
          width="600px"
          modal="false"
          title={i18n.ClaimCommon.ChangeClaimOwnerPage}
        >
          <div>
            <UIUpdatePanel
              id="reassignTo"
              render={this.renderDialogContent}
            />
          </div>
          <br />
          <br />
          <br />
          <UIBox direction="center">
            <UIButton
              value={i18n.ClaimCommon.Submit}
              onClick={this.save}
              position="center"
            />
            <UIButton
              value={i18n.ClaimCommon.Cancel}
              buttonType="cancel"
              position="center"
            />
          </UIBox>
        </UIDialog>
        <UIDialog
          id="reassignToResultDialog"
          width="1000px"
          onClose={updateparentUI}
          modal="false"
          title={i18n.ClaimCommon.ChangeClaimOwnerPage}
        >
          <div>
            <UIUpdatePanel
              id="reassignToResult"
              render={this.renderReassignToResult}
            />
          </div>

          <UIBox direction="center">
            <UIButton
              value={i18n.ClaimCommon.okButton}
              onClick={this.okToClose}
              position="right"
            />
          </UIBox>
        </UIDialog>
      </div>
    );
  },
  renderDialogContent() {
    return (
      <div id="productCoverageDialogSmartPanel" column="1">
        <UICell>
          <UICell width="12">
            <UISelect
              id="assignTo"
              label={i18n.ClaimCommon.AssignTo}
              value="assignee"
              codeTable={this.userShow}
              valueLink={this.bindToState('assignToObj', 'AssignTo')}
            />
          </UICell>
        </UICell>
        <UICell>
          <UICell width="6">
            <UIText
              id="occupytheline"
              label="   "
              value="   "
              io="out"
            />
          </UICell>
          <UICell width="6" />
        </UICell>
      </div>
    );
  },
  renderReassignToResult() {
    const { susList, failList } = this.state;
    return (
      <div>
        <ol style={{ listStyle: 'none', paddingLeft: '5px' }}>
          <li>
            <span>{i18n.ClaimCommon.ReassignClaimSus}</span>
          </li>
        </ol>
        <UIDataTable
          id="reassignToSusList"
          indexable="false"
          styleClass="default"
          pageable="false"
          value={susList}
          headerTitle={i18n.ClaimCommon.ClaimList}
        >
          <UIColumn
            headerTitle={i18n.ClaimCommon.SubClaimNo}
            value="SubClaimNo"
            sortable="true"
          />
          <UIColumn
            headerTitle={i18n.ClaimCommon.Product}
            value="Product"
            sortable="true"
          >
            <UISelect
              codeTable={CodeTableAction.loadDataList(
                CodeTableCstUI.ClaimProduct,
                null
              )}
              io="out"
            />
          </UIColumn>
          <UIColumn
            headerTitle={i18n.ClaimCommon.ReassignSubclaimType}
            value="SubclaimType"
            sortable="true"
          >
            <UISelect
              codeTable={CodeTableAction.loadDataList(
                CodeTableCstUI.SubclaimType,
                null
              )}
              io="out"
            />
          </UIColumn>
          <UIColumn
            headerTitle={i18n.ClaimCommon.Claimant}
            value="Claimant"
            sortable="true"
          />
          <UIColumn
            headerTitle={i18n.ClaimCommon.PolicyHolder}
            value="PolicyHolder"
            sortable="true"
          />
          <UIColumn
            headerTitle={i18n.ClaimCommon.ClaimOwner}
            value="ClaimOwner"
            sortable="true"
          >
            <UISelect
              codeTable={CodeTableAction.loadDataList(
                CodeTableCstUI.ClaimUser,
                null
              )}
              io="out"
            />
          </UIColumn>
          <UIColumn
            headerTitle={i18n.ClaimCommon.ClaimBranch}
            value="ClaimBranch"
            sortable="true"
          >
            <UISelect
              codeTable={CodeTableAction.loadDataList(
                CodeTableCstUI.Branch,
                null
              )}
              io="out"
            />
          </UIColumn>
          <UIColumn
            headerTitle={i18n.ClaimCommon.AssignmentStatus}
            value="LossStatus"
            sortable="true"
          >
            <UISelect
              codeTable={CodeTableAction.loadDataList(
                CodeTableCstUI.ClaimLossStatus,
                null
              )}
              io="out"
            />
          </UIColumn>
        </UIDataTable>
        <ol style={{ listStyle: 'none', paddingLeft: '5px' }}>
          <li>
            <span>{i18n.ClaimCommon.ReassignClaimFail}</span>
          </li>
        </ol>
        <UIDataTable
          id="reassignToFailList"
          indexable="false"
          styleClass="default"
          pageable="false"
          value={failList}
          headerTitle={i18n.ClaimCommon.ClaimList}
        >
          <UIColumn
            headerTitle={i18n.ClaimCommon.SubClaimNo}
            value="SubClaimNo"
            sortable="true"
          />
          <UIColumn
            headerTitle={i18n.ClaimCommon.Product}
            value="Product"
            sortable="true"
          >
            <UISelect
              codeTable={CodeTableAction.loadDataList(
                CodeTableCstUI.ClaimProduct,
                null
              )}
              io="out"
            />
          </UIColumn>
          <UIColumn
            headerTitle={i18n.ClaimCommon.ReassignSubclaimType}
            value="SubclaimType"
            sortable="true"
          >
            <UISelect
              codeTable={CodeTableAction.loadDataList(
                CodeTableCstUI.SubclaimType,
                null
              )}
              io="out"
            />
          </UIColumn>
          <UIColumn
            headerTitle={i18n.ClaimCommon.Claimant}
            value="Claimant"
            sortable="true"
          />
          <UIColumn
            headerTitle={i18n.ClaimCommon.PolicyHolder}
            value="PolicyHolder"
            sortable="true"
          />
          <UIColumn
            headerTitle={i18n.ClaimCommon.ClaimOwner}
            value="ClaimOwner"
            sortable="true"
          >
            <UISelect
              codeTable={CodeTableAction.loadDataList(
                CodeTableCstUI.ClaimUser,
                null
              )}
              io="out"
            />
          </UIColumn>
          <UIColumn
            headerTitle={i18n.ClaimCommon.ClaimBranch}
            value="ClaimBranch"
            sortable="true"
          >
            <UISelect
              codeTable={CodeTableAction.loadDataList(
                CodeTableCstUI.Branch,
                null
              )}
              io="out"
            />
          </UIColumn>
          <UIColumn
            headerTitle={i18n.ClaimCommon.AssignmentStatus}
            value="LossStatus"
            sortable="true"
          >
            <UISelect
              codeTable={CodeTableAction.loadDataList(
                CodeTableCstUI.ClaimLossStatus,
                null
              )}
              io="out"
            />
          </UIColumn>
        </UIDataTable>
      </div>
    );
  },
});
export default MassiveReassignmentClaimOwnerDialog;
