import {
  UIPanel,
  UICell,
  UIButton,
  UISelect,
  UIParam,
  UISmartPanelGrid,
  CodeTable,
  DataContext,
} from 'RainbowUI';
import BindToMixin from 'react-binding';
import PropTypes from 'prop-types';
import {
  ClaimCstUI,
  CodeTableAction,
  CodeTableCstUI,
} from 'ClaimCommonConstant';
import ClaimSettlement from 'ClaimSettlement';
import ClaimCommonExt from 'ClaimCommonExt';
import { PartyAction } from 'EU00PartyCommonUI';

const SettlementLoadService = require('../service/SettlementLoadService');

const SettlementLoadAction = ClaimSettlement.getSettlementLoadAction();
const SettlementItemInfo = ClaimSettlement.getSettlementItemInfo();
const ClaimPartyAction = ClaimCommonExt.getClaimPartyAction();

const SettlementInfoLoad = React.createClass({
  mixins: [BindToMixin],
  propTypes: {
    settlementInfo: PropTypes.object.isRequired,
    otherInfo: PropTypes.object.isRequired,
    readOnly: PropTypes.bool,
    caseId: PropTypes.string.isRequired,
    isApprove: PropTypes.bool,
  },
  getInitialState() {
    return {
      updateSettlementInfo: this.props.updateSettlementInfo,
      updateOtherInfo: this.props.updateOtherInfo,
      settlementInfo: this.props.settlementInfo,
      settlementCurrency: '',
      settlementTypeList: '',
    };
  },

  changeSettlementCurrency(settlementCurrency) {
    this.setState({
      settlementCurrency,
    });
  },

  checkReadOnlyCoInsurance(coInsurancePayment) {
    let readOnlyPayment;
    if (coInsurancePayment) {
      let codePay = [];
      if (
        ClaimCstUI.CoLeader.PayFull == coInsurancePayment ||
        ClaimCstUI.CoLeader.PayPortion == coInsurancePayment
      ) {
        codePay = CodeTableAction.loadDataList(
          CodeTableCstUI.ClaimCoLeader,
          null
        );
      } else {
        codePay = CodeTableAction.loadDataList(
          CodeTableCstUI.ClaimCoFollower,
          null
        );
      }
      readOnlyPayment = (
        <UISmartPanelGrid id="fullPortion_grid_readOnly" column="3">
          <UISelect
            id="fullPortion_select_readOnly"
            codeTable={codePay}
            value={coInsurancePayment}
            label={i18n.ClaimSettlement.CoInsurancePayment}
            visibled={coInsurancePayment !== 'full'}
            io="out"
          />
        </UISmartPanelGrid>
      );
    } else {
      readOnlyPayment = <div />;
    }
    return readOnlyPayment;
  },

  checkEditCoInsurance(coInsurancePayment) {
    const coInsurance = this.props.CoInsurance;
    let codePay = {};
    if (
      coInsurance.CoinLeaderFlag == ClaimCstUI.BooleanConstant.TRUE
    ) {
      codePay = CodeTableAction.loadDataList(
        CodeTableCstUI.ClaimCoLeader,
        null
      );
    } else {
      codePay = CodeTableAction.loadDataList(
        CodeTableCstUI.ClaimCoFollower,
        null
      );
    }
    if (coInsurancePayment) {
      this.props.updateCoInsurancePayment(coInsurancePayment);
    }
    const cPayment = (
      <UISmartPanelGrid id="fullPortion_grid" column="3">
        <UISelect
          id="fullPortion_select"
          codeTable={codePay}
          value={coInsurancePayment || ''}
          onChange={SettlementLoadAction.chooseFullOrPortion}
          visibled={coInsurancePayment !== 'full'}
          required="true"
          validationGroup="fullOrPartion_validate"
          label={i18n.ClaimSettlement.CoInsurancePayment}
        >
          <UIParam
            name="taskInstanceId"
            value={this.props.taskInstanceId}
          />
          <UIParam name="coInsurance" value={coInsurance} />
        </UISelect>
      </UISmartPanelGrid>
    );
    return cPayment;
  },
  updateSettlementPayee(
    name,
    newvalue,
    itemIndex,
    payeeIndex,
    roleType
  ) {
    DataContext.put(`settlementPayeeName${payeeIndex}`, name);
    DataContext.put(`roleType${payeeIndex}`, roleType);
    if (name) {
      this.props.settlementInfo.SettlementPayee[
        payeeIndex
      ].PayeeName = name;
    }
    if (name && name.codes.length == '1') {
      const payeeId = name.codes[0].id;
      this.props.settlementInfo.SettlementPayee[
        payeeIndex
      ].PayeeId = payeeId;
      let results = null;
      const claimPartyId = payeeId;
      let address = '';
      if (claimPartyId !== '') {
        let partyRoleL = [];
        let partyAddressL = [];
        const claimParty = ClaimPartyAction.loadClaimParty(
          claimPartyId
        );
        const partyId = claimParty.Model.PtyPartyId;
        const partyAction = new PartyAction();
        const partyMo = partyAction.loadPartyByPartyId(partyId);
        partyRoleL = partyMo.PartyRoleList;
        partyAddressL = partyMo.AddressVoList;
        if (partyAddressL) {
          for (let j = 0; j < partyAddressL.length; j += 1) {
            if (partyAddressL[j].IsPrimaryAddress === 'Y') {
              address = `${partyAddressL[j].StreetName},${partyAddressL[j].PostCode},${partyAddressL[j].Area},${partyAddressL[j].City}`;
            }
          }
        }
        const item = [];
        if (partyRoleL.length >= 1) {
          if (partyRoleL.length === 1) {
            this.props.settlementInfo.SettlementPayee[
              payeeIndex
            ].PayeeType = partyRoleL[0].RoleType;
          }
          for (let i = 0; i < partyRoleL.length; i += 1) {
            item.push({
              id: partyRoleL[i].RoleType,
              text: partyRoleL[i].RoleName,
            });
          }
          results = new CodeTable(item);
        }
        if (results) {
          this.props.settlementInfo.SettlementPayee[
            payeeIndex
          ].PayeeTypeCodeTable = results;
        }
      }
      this.props.settlementInfo.SettlementPayee[
        payeeIndex
      ].Address = address;
      this.setState({
        payeeTypes: results,
        settlementPayeeAddress: address,
      });
    }
    this.props.settlementInfo.SettlementPayee[
      payeeIndex
    ].SettlementItem[itemIndex].PaymentType = newvalue;
    this.setState({ settlementInfo: this.props.settlementInfo });
  },

  render() {
    const {
      settlementInfo: { ClaimType, CoInsurancePayment },
      history,
      CoInsurance,
    } = this.props;
    DataContext.put('ClaimType', ClaimType);
    const { settlementInfo } = this.props;
    if (undefined == settlementInfo) {
      return;
    }
    const _self = this;
    const settlementPayee = this.getSettlementPayee();
    let coInsurancePayment = CoInsurancePayment;
    if (_self.props.readOnly) {
      const readOnlyPayment = _self.checkReadOnlyCoInsurance(
        coInsurancePayment
      );
      let style;
      if (history) {
        style = 'default';
      } else {
        style = 'primary';
      }
      return (
        <UIPanel
          id="settlement_detail"
          styleClass={style}
          panelTitle={i18n.ClaimSettlement.SettlementDetail}
          toggleable="true"
        >
          {readOnlyPayment}
          {settlementPayee}
        </UIPanel>
      );
    }
    let cPayment;
    const coInsurance = CoInsurance;
    if (coInsurance.CoinFlag == ClaimCstUI.BooleanConstant.TRUE) {
      if (coInsurance.CoinLeaderFlag) {
        coInsurancePayment = ClaimCstUI.CoLeader.PayFull;
      }
      cPayment = _self.checkEditCoInsurance(coInsurancePayment);
      if (!coInsurancePayment) {
        return (
          <UIPanel
            id="settlement_detail"
            panelTitle={i18n.ClaimSettlement.SettlementDetail}
            toggleable="true"
            panelFooter={
              <UIButton
                value={i18n.ClaimSettlement.AddPayee}
                onClick={SettlementLoadAction.addPayee}
                validationGroup="fullOrPartion_validate"
                causeValidation="true"
              />
            }
          >
            {cPayment}
          </UIPanel>
        );
      }
    } else {
      cPayment = <div />;
    }
    return (
      <UIPanel
        id="settlement_detail"
        panelTitle={i18n.ClaimSettlement.SettlementDetail}
        toggleable="true"
        panelFooter={
          <UISmartPanelGrid id="ful" column="3">
            <UIButton
              value={i18n.ClaimSettlement.AddPayee}
              onClick={SettlementLoadAction.addPayee}
              validationGroup="fullOrPartion_validate"
              causeValidation="true"
            />
          </UISmartPanelGrid>
        }
      >
        {cPayment}
        {settlementPayee}
      </UIPanel>
    );
  },
  getSettlementType() {
    let settTypeList = [];
    settTypeList = SettlementLoadService.getSettlementTypeList();
    this.state.settlementTypeList = settTypeList;
  },
  getSettlementPayee() {
    const _self = this;
    const SettlementPayeeInfo = ClaimSettlement.getSettlementPayeeInfo();
    const { settlementCurrency } = _self.state;
    let payeePanel;
    if (undefined !== this.props.settlementInfo) {
      const settlementPayeeList = this.props.settlementInfo
        .SettlementPayee;
      const { otherInfo } = this.props;
      const { caseId } = this.props;
      if (!settlementPayeeList) {
        return <div />;
      }
      if (settlementPayeeList.length === 0) {
        return <div />;
      }

      const { readOnly } = this.props;
      const { isApprove } = this.props;
      const coInsurance = this.props.CoInsurance;
      payeePanel = settlementPayeeList.map(
        (settlementPayee, payeeIndex) => {
          return (
            <div>
              <UICell style={{ textAlign: 'left' }}>
                <SettlementItemInfo
                  payeeIndex={payeeIndex}
                  settlementPayee={settlementPayee}
                  otherInfo={otherInfo}
                  readOnly={readOnly}
                  caseId={caseId}
                  isApprove={isApprove}
                  taskCode={ClaimCstUI.CLAIM_TASK_CODE.Settlement}
                  coInsurance={coInsurance}
                  CoInsurancePayment={
                    _self.props.settlementInfo.CoInsurancePayment
                  }
                  updateSettlementInfo={
                    _self.props.updateSettlementInfo
                  }
                  updateOtherInfo={_self.props.updateOtherInfo}
                  settlementType={_self.state.settlementTypeList}
                  settlementCurrency={settlementCurrency}
                  updateSettlementPayee={_self.updateSettlementPayee}
                />
              </UICell>
              <UICell style={{ textAlign: 'left' }}>
                <SettlementPayeeInfo
                  parentComponent={_self.props.parentComponent}
                  index={payeeIndex}
                  settlementPayee={settlementPayee}
                  readOnly={readOnly}
                  caseId={caseId}
                  coInsurance={coInsurance}
                  updateSettlementInfo={
                    _self.props.updateSettlementInfo
                  }
                  updateSettlementPayee={
                    _self.props.updateSettlementPayee
                  }
                  changeSettlementCurrency={_self.changeSettlementCurrency.bind(
                    _self
                  )}
                />
              </UICell>
            </div>
          );
        }
      );
    }
    return payeePanel;
  },
});
export default SettlementInfoLoad;
