import {
  CodeTable,
  UIPanel,
  UIDateTimePicker,
  UISmartPanelGrid,
  UIText,
  UISelect,
  UIRadio,
  UIButton,
  UIBox,
  UIParam,
  MessageHelper,
} from 'RainbowUI';
import React, { Component, PropTypes } from 'react';
import ReactMixin from 'react-mixin';
import BindToMixin from 'react-binding';
import { CodeTableAction } from 'ClaimCommonConstant';
import {
  PartyConst,
  PartyAction,
  PtyIntegrationSearchDialog,
} from 'EU00PartyCommonUI';
import VehicleAction from '../action/VehicleAction';
import Const from '../constant/FieldinvestigationConst';

export default class Vehicle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      model: {
        VehicleEntity: {},
      },
      synBtnStatu: false,
      vehicleInvetigatorInd: {},
    };
    this.save = this.save.bind(this);
    this.submit = this.submit.bind(this);
    this.getFieldInvestigator = this.getFieldInvestigator.bind(this);
    this.registrationNoVehicleValidate = this.registrationNoVehicleValidate.bind(
      this
    );
    this.getModelCodeTable = this.getModelCodeTable.bind(this);
    this.getEnabledStyle = this.getEnabledStyle.bind(this);
    this.showPartySearchPanelDialog = this.showPartySearchPanelDialog.bind(
      this
    );
    this.validateRemark1 = this.validateRemark1.bind(this);
    this.validateRemark2 = this.validateRemark2.bind(this);
  }

  componentDidMount() {
    this.initData();
    this.synBtnEnable();
  }

  componentWillUnmount() { }

  getFieldInvestigator(party) {
    const { model } = this.state;
    model.InvestigatorName = party.PartyName;
    model.InvestigatorPartyId = party.PartyId;
    if (party.PartyType == PartyConst.PARTY_TYPE_IND) {
      model.InvestigatorTel = party.MobileTel;
    } else if (party.PartyType == PartyConst.PARTY_TYPE_ORG) {
      if (party.PartyContactList) {
        if (party.PartyContactList.length > 0) {
          _.each(party.PartyContactList, partyContact => {
            if (
              partyContact.IsPrimaryContact == PartyConst.IS_PRIMARY
            ) {
              model.InvestigatorTel = !partyContact.HandPhone
                ? partyContact.BusinessTel
                : partyContact.HandPhone;
            }
          });
        }
      }
    }
    this.setState({
      model,
    });
  }

  getButtons() {
    const self = this;
    if (self.props.openForm == Const.Self) {
      return (
        <UIBox>
          <UIButton
            value={i18n.ClaimCommon.Save}
            onClick={this.save}
          />
          <UIButton
            value={i18n.ClaimCommon.Submit}
            validationGroup="vehicleValidate"
            causeValidation="true"
            onClick={this.submit}
          />
        </UIBox>
      );
    }
    return undefined;
  }

  getModelCodeTable() {
    const { model } = this.state;
    const vehicleEntity = model.VehicleEntity;
    const modelList = VehicleAction.getModelCodeTable(
      vehicleEntity.Maker
    );
    vehicleEntity.Model = null;
    this.setState({ modelList });
  }

  getIOStyle() {
    const { model } = this.state;
    const self = this.props;
    return model.Fistatus == Const.DONE ||
      self.openForm == Const.Other
      ? 'out'
      : 'in';
  }

  getEnabledStyle() {
    const { model } = this.state;
    return !!(
      model.VehicleEntity && model.VehicleEntity.IsDot === 'Y'
    );
  }

  getWhetherRegister() {
    return new CodeTable([
      { id: 'Y', text: 'Yes' },
      { id: 'N', text: 'No' },
    ]);
  }

  // load initData from  former investigationTask
  initData() {
    const self = this;
    let fiStatus = Const.DONE;
    if (self.props.openForm == Const.Self) {
      fiStatus = Const.NEW;
    }
    const data = VehicleAction.initData(
      self.props.objectId,
      fiStatus
    );
    console.info('initData: ', data);
    if (data) {
      const makerList = CodeTableAction.loadDataList(937752, null);
      window.makerList = makerList;
      let modelList;
      if (data.VehicleEntity) {
        modelList = VehicleAction.getModelCodeTable(
          data.VehicleEntity.Maker
        );
      } else {
        modelList = new CodeTable([]);
      }
      if (Const.NEW == fiStatus && Const.DONE == data.Fistatus) {
        data.Fistatus = Const.NEW;
        data.Fiid = null;
      }
      let isFirstPary;
      console.log('this.props', this.props);
      if (self.props.subClaim.damageParty === 'FP') {
        // getvalue from original policy
        isFirstPary = true;
      } else {
        isFirstPary = false;
      }
      this.setState({
        makerList,
        modelList,
        model: data,
        isFirstPary,
      });
    }
  }

  synchronizationFromAppraisal() {
    const self = this;
    const { model } = this.state;
    const synModel = VehicleAction.synModel(self.props.objectId);
    let hasChange = false;
    const newSynModelState = model.VehicleEntity;
    const keyps = Object.keys(synModel);
    for (let i = 0; i < keyps.length; i += 1) {
      newSynModelState[keyps[i]] = synModel[keyps[i]];
      hasChange = true;
    }
    console.log('newSynModelState is ', newSynModelState);
    this.forceUpdate();

    if (hasChange) {
      MessageHelper.success(
        i18n.ClaimFieldInvestigation.VehicleSynSuccess
      );
    }
  }

  buildData() {
    const vo = {};
    const { model } = this.state;
    const self = this;
    // ie not compality
    const entity = $.extend({}, model);
    // var entity = Object.assign({},this.state.model);
    entity.ObjectId = self.props.objectId;
    entity['@type'] =
      'ClaimFieldInvestigation-ClaimFieldInvestigation';
    entity.VehicleEntity['@type'] = 'ClaimFIVehicle-ClaimFIVehicle';
    console.info('submit vehicle entity ', entity);
    vo.TaskInstanceId = self.props.taskInfo.TaskInstanceId;
    vo.ClaimFIEntity = entity;
    return vo;
  }

  submit() {
    const entity = this.buildData();
    const vehicleEntity = entity.ClaimFIEntity.VehicleEntity;
    if (
      !vehicleEntity.Vin ||
      !vehicleEntity.Maker ||
      !vehicleEntity.ModelYear ||
      !vehicleEntity.Model ||
      !vehicleEntity.MakerYear
    ) {
      MessageHelper.error(
        i18n.ClaimFieldInvestigation.VehicleInformationNotFoundInDOT
      );
    } else {
      VehicleAction.submit(entity);
    }
  }

  save() {
    const entity = this.buildData();
    VehicleAction.save(entity, this.submitCallback.bind(this));
  }

  submitCallback(data) {
    console.log(
      `-------------submitCallback----------${JSON.stringify(data)}`
    );
    this.setState({
      model: data.Model,
    });
  }

  synBtnEnable() {
    const self = this;
    const enabled = VehicleAction.synButtonIsEnable(
      self.props.taskInfo
    );
    this.setState({
      synBtnStatu: enabled,
    });
  }

  registrationNoVehicleValidate() {
    const { model } = this.state;
    const vehicleEntity = model.VehicleEntity;
    // vehicleEntity.Vin = null;
    // vehicleEntity.Maker = null;
    // vehicleEntity.Model = null;
    // vehicleEntity.ModelYear = null;
    // vehicleEntity.MakerYear = null;
    if (VehicleAction.validatePlateNo(model.VehicleEntity)) {
      if (vehicleEntity.PlateNo) {
        vehicleEntity.PlateNo = vehicleEntity.PlateNo.toUpperCase();
      }
    } else {
      vehicleEntity.PlateNo = null;
    }
    this.setState({ model });
  }

  validateVin() {
    const { model } = this.state;
    const vehicleEntity = model.VehicleEntity;
    if (
      vehicleEntity.Vin &&
      (vehicleEntity.Vin.length < 6 || vehicleEntity.Vin.length > 17)
    ) {
      vehicleEntity.Vin = null;
      MessageHelper.warning(
        i18n.ClaimFieldInvestigation.WarnMsg
          .PleaseCheckYourVinNumberImput
      );
    }
    this.setState({ model });
  }

  validateRemark2() {
    const { model } = this.state;
    if (model.Remark2 && model.Remark2.length > 3000) {
      model.Remark2 = null;
      MessageHelper.warning(
        i18n.ClaimFieldInvestigation.WarnMsg.ValueMustLessThan3000
      );
    }
    this.setState({ model });
  }

  validateRemark1() {
    const { model } = this.state;
    if (model.Remark1 && model.Remark1.length > 3000) {
      model.Remark1 = null;
      MessageHelper.warning(
        i18n.ClaimFieldInvestigation.WarnMsg.ValueMustLessThan3000
      );
    }
    this.setState({ model });
  }

  showPartySearchPanelDialog() {
    this.refs.PartySearchDialogForInvestigator_Ref.show();
  }

  initPartyName(investigatorName) {
    const partyAction = new PartyAction();
    const result = partyAction.findPartyNameByPartyCode(
      investigatorName
    ).data;
    return result.PartyName;
  }

  render() {
    const self = this;
    let disabled;
    let visited;
    const { model, vehicleInvetigatorInd } = this.state;
    // if subclaim is third party
    if (model.isFirstPary == true) {
      visited = false;
    } else {
      disabled = this.getEnabledStyle();
      visited = true;
    }
    const insuredList = self.props.policyInfo.InsuredList;
    let insuredObject;
    _.each(insuredList, obj => {
      if (obj.Maker) {
        insuredObject = obj;
        return obj;
      }
    });

    if (model.isFirstPary == true) {
      if (insuredObject) {
        if (model.VehicleEntity) {
          if (!model.VehicleEntity.MakerYear) {
            model.VehicleEntity.Model = insuredObject.Model;
            model.VehicleEntity.Maker = insuredObject.Maker;
            model.VehicleEntity.ModelYear =
              insuredObject.RegistrationDt;
            model.VehicleEntity.MakerYear = insuredObject.MakerYear;
            model.VehicleEntity.PlateNo = insuredObject.PlateNo;
            model.VehicleEntity.Vin = insuredObject.VinNo;
          }
        }
      }
    }
    let { modelList } = this.state;

    if (model.VehicleEntity) {
      modelList = VehicleAction.getModelCodeTable(
        model.VehicleEntity.Maker
      );
    }
    let investigatorName;
    if (model.InvestigatorName && !vehicleInvetigatorInd.PartyName) {
      vehicleInvetigatorInd.PartyName = investigatorName;
    } else {
      investigatorName = vehicleInvetigatorInd.PartyName;
    }
    const makerList = CodeTableAction.loadDataList(937752, null);

    return (
      <div>
        <PtyIntegrationSearchDialog
          ref="PartySearchDialogForInvestigator_Ref"
          id="partySearchDialogForInvestigator"
          isSearch={false}
          getSearchResult={this.getFieldInvestigator}
          isVisibled={false}
          title={i18n.SearchClaimInvestigator}
          RoleName={PartyConst.PARTY_ROLE_NAME_CLAIM_INVESTIGATOR}
        />
        <UIPanel
          panelTitle={i18n.ClaimFieldInvestigation.VehicleInfo}
        >
          <UISmartPanelGrid column="2">
            <UIText
              label={i18n.ClaimFieldInvestigation.registrationNo}
              validationGroup="vehicleValidate"
              required="true"
              valueLink={this.bindToState(
                'model',
                'VehicleEntity.PlateNo'
              )}
              io={this.getIOStyle()}
              onChange={this.registrationNoVehicleValidate}
            />
            <UIText
              label={i18n.ClaimFieldInvestigation.vinNumber}
              validationGroup="vehicleValidate"
              required="true"
              valueLink={this.bindToState(
                'model',
                'VehicleEntity.Vin'
              )}
              io={this.getIOStyle()}
              disabled={disabled}
              minLength="6"
              maxLength="17"
            />
            <UISelect
              label={i18n.ClaimFieldInvestigation.Maker}
              validationGroup="vehicleValidate"
              required="true"
              valueLink={this.bindToState(
                'model',
                'VehicleEntity.Maker'
              )}
              io={this.getIOStyle()}
              disabled={disabled}
              onChange={this.getModelCodeTable}
              codeTable={makerList}
            />
            <UISelect
              label={i18n.ClaimFieldInvestigation.Model}
              validationGroup="vehicleValidate"
              required="true"
              valueLink={this.bindToState(
                'model',
                'VehicleEntity.Model'
              )}
              io={this.getIOStyle()}
              disabled={disabled}
              codeTable={modelList}
            />
            <UIDateTimePicker
              label={i18n.ClaimFieldInvestigation.manufactureYear}
              validationGroup="vehicleValidate"
              required="true"
              format="YYYY"
              valueLink={this.bindToState(
                'model',
                'VehicleEntity.MakerYear'
              )}
              io={this.getIOStyle()}
              disabled={this.getEnabledStyle}
              maxDate="TODAY"
              placeHolder="YYYY"
            />
            <UIDateTimePicker
              label={i18n.ClaimFieldInvestigation.registrationDate}
              validationGroup="vehicleValidate"
              required="true"
              format="MM/DD/YYYY"
              valueLink={this.bindToState(
                'model',
                'VehicleEntity.ModelYear'
              )}
              io={this.getIOStyle()}
              disabled={this.getEnabledStyle}
              maxDate="TODAY"
              placeHolder="MM/DD/YYYY"
            />
            <UIDateTimePicker
              label={i18n.ClaimFieldInvestigation.investigatorArrival}
              validationGroup="vehicleValidate"
              valueLink={this.bindToState(
                'model',
                'VehicleEntity.ArrivedTime'
              )}
              io={this.getIOStyle()}
              maxDate="TODAY"
              placeHolder="MM/DD/YYYY HH:mm:ss"
              format="MM/DD/YYYY HH:mm:ss"
            />
            <UIText
              label={i18n.ClaimFieldInvestigation.investigatorName}
              io={this.getIOStyle()}
              onSuffixIconClick={this.showPartySearchPanelDialog}
              value={model.InvestigatorName}
              suffixIcon="fa fa-search"
            >
              <UIParam
                name="partyRole"
                value={
                  PartyConst.PARTY_ROLE_CODE_IND_CLAIMANT_INVESTIGATOR
                }
              />
              <UIParam
                name="partyCategory"
                value={PartyConst.PARTY_TYPE_IND}
              />
              <UIParam
                name="stateParam"
                value="vehicleInvetigatorInd"
              />
            </UIText>
            <UIText
              label={i18n.ClaimFieldInvestigation.remark1}
              valueLink={this.bindToState('model', 'Remark1')}
              io={this.getIOStyle()}
              onChange={this.validateRemark1}
            />
            <UIText
              label={
                i18n.ClaimFieldInvestigation.investigatorTelephone
              }
              valueLink={this.bindToState('model', 'InvestigatorTel')}
              io={this.getIOStyle()}
            />
            <UIText
              label={i18n.ClaimFieldInvestigation.remark2}
              colspan="2"
              valueLink={this.bindToState('model', 'Remark2')}
              io={this.getIOStyle()}
              onChange={this.validateRemark2}
            />
            <UIText
              visibled={false}
              value={model.InvestigatorPartyId}
            />
          </UISmartPanelGrid>
        </UIPanel>
        {this.getButtons()}
      </div>
    );
  }
}
ReactMixin.onClass(Vehicle, BindToMixin);
Vehicle.defaultProps = {
  showButtons: true,
};
Vehicle.propTypes = {
  showButtons: PropTypes.bool,
};
