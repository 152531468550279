const ClaimSettlement = {
  getSettlementHandlingAction() {
    return require('./module/settlement/common/action/SettlementHandlingAction');
  },
  getSettlementLoadAction() {
    return require('./module/settlement/common/action/SettlementLoadAction');
  },
  getSettlementValidationAction() {
    const SettlementValidationAction = require('./module/settlement/common/action/SettlementValidationAction');
    return new SettlementValidationAction();
  },
  getSettlementStoreProcess() {
    const SettlementStoreProcess = require('./module/settlement/common/store/SettlementStoreProcess');
    return new SettlementStoreProcess();
  },

  getSettlementTaskPage() {
    return require('./module/settlement/common/component/SettlementTaskPage');
  },
  getSettlementApprove() {
    return require('./module/settlement/common/component/SettlementApprove');
  },
  getRecoveryApprove() {
    return require('./module/settlement/recovery/component/RecoveryApprove');
  },
  getRecoveryTaskPage() {
    return require('./module/settlement/recovery/component/RecoveryTaskPage');
  },
  getRefundApprove() {
    return require('./module/settlement/refund/component/RefundApprove');
  },
  getRefundTaskPage() {
    return require('./module/settlement/refund/component/RefundTaskPage');
  },
  getSettlementPayeeInfo() {
    return require('./module/settlement/common/component/SettlementPayeeInfo');
  },
  getRecoveryPayeeInfo() {
    return require('./module/settlement/recovery/component/RecoveryPayeeInfo');
  },
  getRefundPayeeInfo() {
    return require('./module/settlement/refund/component/RefundPayeeInfo');
  },
  getSettlementHistory() {
    return require('./module/settlement/common/component/SettlementHistory');
  },
  getSettlementItemInfo() {
    return require('./module/settlement/common/component/SettlementItemInfo');
  },
  getSettlementInfoLoad() {
    return require('./module/settlement/common/component/SettlementInfoLoad');
  },
  getSettlementLoadActionProcess() {
    const SettlementLoadActionProcess = require('./module/settlement/common/action/SettlementLoadActionProcess');
    return new SettlementLoadActionProcess();
  },
};

export default ClaimSettlement;
