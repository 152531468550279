import {
  MessageHelper,
  UICheckbox,
  UIParam,
  UISelect,
  UILink,
  UIColumn,
  UIDataTable,
  UIPanel,
  UIText,
  DataContext,
} from 'RainbowUI';
import EditPartyAccountDialog from './EditPartyAccountDialog';
import DefinedCodeTable from '../codetable/CodeTable';
import PartyAction from '../actions/PartyAction';
import PartyAccountAction from '../actions/PartyAccountAction';
import EditPartyAccountDialogReadOnly from './readonly/views/EditPartyAccountDialogReadOnly';
import PartyConst from '../const/PartyConst';

const _ = require('underscore');

const PartyAccountInfo = React.createClass({
  getInitialState() {
    const partyAction = new PartyAction();
    let { party } = this.props;
    if (DataContext.get('Party')) {
      party = DataContext.get('Party');
    }
    party = partyAction.loadPartyByPartyId(party.PartyId);
    let { PartyAccountList } = party;
    if (!PartyAccountList) {
      PartyAccountList = [];
    }
    _.forEach(PartyAccountList, (temppartyAccount, index) => {
      const partyAccount = temppartyAccount;
      partyAccount.DefinedIndex = index + 1;
    });
    const totalNum = PartyAccountList.length;

    return {
      party,
      PartyAccountList,
      TotalNum: totalNum,
    };
  },

  createAccountHandler() {
    const definedIndex = this.state.TotalNum + 1;
    const createPartyAccount = {
      '@type': 'PartyAccount-PartyAccount',
      DefinedIndex: definedIndex,
    };
    this.refs.editPartyAccountDialog.showDialog(createPartyAccount);
  },

  submitHandler(partyAccount) {
    let { party } = this.state;
    if (DataContext.get('Party')) {
      party = JSON.parse(JSON.stringify(DataContext.get('Party')));
    }
    const partyAction = new PartyAction();
    const copyParty = partyAction.loadPartyByPartyId(party.PartyId);
    const { PartyHistoryList } = copyParty;
    if (partyAccount['@pk']) {
      const newPartyHistory = {
        '@type': 'PartyHistory-PartyHistory',
        BusinessObjectId: 75158352,
        HistoryType: 4,
      };
      PartyHistoryList.push(newPartyHistory);
    } else {
      const newPartyHistory = {
        '@type': 'PartyHistory-PartyHistory',
        BusinessObjectId: 75158352,
        HistoryType: 3,
      };
      PartyHistoryList.push(newPartyHistory);
    }
    party.PartyHistoryList = PartyHistoryList;
    let { PartyAccountList } = party;
    if (!PartyAccountList) {
      PartyAccountList = [];
    }
    /* let definedIndex = partyAccount.DefinedIndex;
        for (let idx = 0; idx < PartyAccountList.length; idx++) {
            if (PartyAccountList[idx].DefinedIndex == definedIndex) {
                PartyAccountList.splice(idx, 1);
                break;
            }
        } */
    if (partyAccount.IsPrimaryAccount === 'Y') {
      _.forEach(PartyAccountList, tempPartyAccount => {
        const PartyAccount = tempPartyAccount;
        PartyAccount.IsPrimaryAccount = 'N';
      });
    }

    if (partyAccount['@pk']) {
      for (let i = 0; i < PartyAccountList.length; i += 1) {
        if (PartyAccountList[i]['@pk'] == partyAccount['@pk']) {
          $.extend(PartyAccountList[i], partyAccount);
          break;
        }
      }
    } else {
      PartyAccountList.push(partyAccount);
    }

    _.forEach(PartyAccountList, tempPartyAccount => {
      const PartyAccount = tempPartyAccount;
      delete PartyAccount.DefinedIndex;
      delete PartyAccount.dataIndex;
    });
    party.PartyAccountList = PartyAccountList;
    party.AddressVoList = copyParty.AddressVoList;
    party.PartyContactList = copyParty.PartyContactList;
    const result = this.props.submitInfoHandler(party, 3);
    let flag;
    if (!(PartyConst.FAIL == result)) {
      _.forEach(result.PartyAccountList, (temp, index) => {
        const partyAccount = temp;
        partyAccount.DefinedIndex = index + 1;
      });
      this.setState({
        party: result,
        PartyAccountList: result.PartyAccountList,
        TotalNum: PartyAccountList.length + 1,
      });

      if (this.props.parentDialog) {
        if (this.props.parentDialog.callBackGetRecord) {
          this.props.parentDialog.callBackGetRecord(
            PartyAccountList[PartyAccountList.length - 1]
          );
          this.props.parentDialog.hideDialog();
        }
      }
      flag = true;
    } else {
      let { party } = this.state;
      if (DataContext.get('Party')) {
        party = JSON.parse(JSON.stringify(DataContext.get('Party')));
      }
      this.setState({
        party,
        PartyAccountList: party.PartyAccountList,
      });
      flag = false;
    }
    return flag ? PartyConst.SUCCESS : PartyConst.FAIL;
  },
  editAccountHandler(clickEvent) {
    const selectData = UIDataTable.getSelectedRecord(
      'bankAccountInfo'
    );
    if (!selectData.length) {
      MessageHelper.warning(
        i18n.PartyMsg.WarnMsg.Warn_0005,
        i18n.PartyMsg.WarnMsg.Warn_0003,
        MessageHelper.POSITION_TOP_RIGHT
      );
      return;
    }
    const partyAccount = selectData[0];
    this.refs.editPartyAccountDialog.showDialog(partyAccount);
  },
  queryHandler(clickEvent) {
    let partyAccount = {};
    if (!_.isEmpty(clickEvent.paramJson)) {
      partyAccount = clickEvent.paramJson[0].value;
    }
    this.refs.editPartyAccountDialogReadOnlyReadOnly.showDialog(
      partyAccount
    );
  },
  handlePrimaryAccount(data) {
    const { PartyAccountList } = this.state;
    _.each(PartyAccountList, (temp, index) => {
      const partyAccount = temp;
      if (partyAccount.IsPrimaryAccount === 'Y') {
        partyAccount.IsPrimaryAccount = 'N';
        return partyAccount;
      }
    });
    const pk = data.component.props.model['@pk'];
    if (data.newValue === 'Y') {
      _.each(PartyAccountList, temp => {
        const partyAccount = temp;
        if (partyAccount['@pk'] == pk) {
          partyAccount.IsPrimaryAccount = 'Y';
        }
      });
    }
    this.props.party.PartyAccountList = PartyAccountList;
    DataContext.get('Party').PartyAccountList = PartyAccountList;
    this.setState({
      PartyAccountList,
    });
  },

  render() {
    const _self = this;
    const partyAccountAction = new PartyAccountAction();
    const { PartyAccountList } = this.state;
    const { readOnly } = this.props;
    return (
      <UIPanel panelTitle={i18n.BankAccount}>
        <UIDataTable
          id="bankAccountInfo"
          value={PartyAccountList}
          functions={_self.buildTableFunction()}
          indexable="false"
        >
          <UIColumn selectionMode="single" />
          <UIColumn
            headerTitle={i18n.PrimaryAccount}
            render={data => {
              return (
                <div>
                  <UICheckbox
                    single="true"
                    model={data}
                    property="IsPrimaryAccount"
                    onChange={_self.handlePrimaryAccount}
                    disabled={data.AccountStatus == '2'}
                    enabled={!readOnly}
                  />
                </div>
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.Bank}
            render={data => {
              return (
                <UISelect
                  model={data}
                  property="BankName"
                  codeTable={DefinedCodeTable.getBankName}
                  io="out"
                />
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.BankAccount}
            render={data => {
              const maskBankAccount = partyAccountAction.getMaskValue(
                'PartyAccount',
                'AccountNo',
                data.AccountNo
              );
              return (
                <div>
                  <UILink
                    value={maskBankAccount}
                    onClick={_self.queryHandler}
                  >
                    <UIParam name="data" value={data} />
                  </UILink>
                </div>
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.IBANNo}
            render={data => {
              const maskIbanNo = partyAccountAction.getMaskValue(
                'PartyAccount',
                'IBANNo',
                data.IBANNo
              );
              return <UIText io="out" value={maskIbanNo} />;
            }}
          />
          <UIColumn
            headerTitle={i18n.Account.SwiftCode}
            render={data => {
              const maskSwiftCode = partyAccountAction.getMaskValue(
                'PartyAccount',
                'SwiftCode',
                data.SwiftCode
              );
              return <UIText io="out" value={maskSwiftCode} />;
            }}
          />
          <UIColumn
            headerTitle={i18n.Status}
            render={data => {
              return (
                <UISelect
                  io="out"
                  model={data}
                  property="AccountStatus"
                  codeTable={DefinedCodeTable.getPartyStatus}
                />
              );
            }}
          />
        </UIDataTable>
        <EditPartyAccountDialog
          resetState={_self.resetState}
          ref="editPartyAccountDialog"
          submitHandler={_self.submitHandler}
        />
        <EditPartyAccountDialogReadOnly ref="editPartyAccountDialogReadOnlyReadOnly" />
      </UIPanel>
    );
  },

  buildTableFunction() {
    const _self = this;
    return (
      <div>
        <UILink
          icon="fa fa-plus"
          size="lg"
          iconClass="add_iconbtn"
          tooltip={i18n.add}
          onClick={_self.createAccountHandler}
        />
        <UILink
          icon="fa fa-pencil"
          size="lg"
          iconClass="edit_iconbtn"
          tooltip={i18n.edit}
          onClick={_self.editAccountHandler}
        />
      </div>
    );
  },
  resetState() {
    const { party } = this.state;
    this.setState({
      PartyAccountList: party.PartyAccountList,
    });
  },
});

export default PartyAccountInfo;
