import { CodeTable } from 'RainbowUI';
import { RemoteCodeTable } from 'EU00PartyCommonUI';

export default {
  claimants: new CodeTable([{ id: 'Y', text: '' }]),
  claimant: new CodeTable([
    { id: '1', text: 'Policy Holder' },
    { id: '2', text: 'Benification' },
    { id: '3', text: 'New Claimant' },
  ]),

  product: new CodeTable([
    { id: 'GPC', text: 'Private Vehicle' },
    { id: 'FCL', text: 'Travel ' },
  ]),

  taskType: new CodeTable([
    { id: 'ClaimRegistrationTask', text: 'Claim Registration' },
    { id: 'ClaimSettlementTask', text: 'Claim Settlement' },
    {
      id: 'ClaimSettlementApprovalTask',
      text: 'Claim Settlement Approval',
    },
  ]),

  taskStatus: new CodeTable([
    { id: 'Pending', text: 'Pending' },
    { id: 'New', text: 'Created' },
    { id: 'Closed', text: 'Manual Close' },
    { id: 'Completed', text: 'Completed' },
  ]),
  approvalDecision() {
    return new CodeTable(RemoteCodeTable.codeTableById(76242848));
  },
  approvalName: new CodeTable([
    { id: -11, text: 'andy' },
    { id: 1, text: 'name1' },
    { id: 2, text: 'name2' },
    { id: 3, text: 'name3' },
  ]),
  Priority: new CodeTable([
    { id: '1', text: 'High' },
    { id: '2', text: 'Meduim' },
    { id: '3', text: 'Low' },
  ]),
  YesOrNo: new CodeTable([
    { id: '1', text: i18n.ClaimCommon.Yes },
    { id: '0', text: i18n.ClaimCommon.No },
  ]),
  YesOrNoMessage: new CodeTable([
    { id: '100007', text: i18n.ClaimCommon.Yes },
    { id: '100000', text: i18n.ClaimCommon.No },
  ]),
  messageType: new CodeTable([
    { id: '1', text: 'Alert' },
    { id: '2', text: 'Reminder' },
  ]),
  Product: new CodeTable([
    { id: '1', text: 'PC' },
    { id: '2', text: 'Fire' },
  ]),

  subclaimType: new CodeTable([
    { id: '1_ST01', text: 'FP-PD Property Damages' },
    { id: '1_ST02', text: 'TP-PD Property Damages' },
    { id: '1_ST03', text: 'FP-PD Robbery & Theft' },
    { id: '1_ST04', text: 'TP-BI Body Injury' },
    { id: '1_ST05', text: 'FP-BI Bodily Injury' },
  ]),

  damageType: new CodeTable([
    { id: 'I', text: 'Body Injury' },
    { id: 'P', text: 'Propery' },
    { id: 'V', text: 'Vehicle' },
  ]),

  causeOfLoss: new CodeTable([
    { id: '1', text: 'UNKNOWN' },
    { id: '2', text: 'Collsion Overturn' },
    { id: '3', text: 'Vadamlision' },
    { id: '4', text: 'Other Perils' },
  ]),
  getCatastropheCode: new CodeTable([
    { id: '0000', text: '0000-NO' },
    { id: '0001', text: '0001-Japan earthQuake' },
    { id: '0002', text: '12345-matha' },
  ]),
  getContactType: new CodeTable([
    { id: '1', text: 'insured' },
    { id: '2', text: 'Lawyer' },
    { id: '3', text: 'driver' },
  ]),
  getInsuredLiability: new CodeTable([
    { id: '1', text: '100%' },
    { id: '2', text: '90%' },
    { id: '3', text: '80%' },
    { id: '4', text: '70%' },
    { id: '5', text: '50%' },
    { id: '6', text: '50%' },
  ]),
  getValidationDecision: new CodeTable([
    { id: '1', text: 'Accept' },
    { id: '2', text: 'Reject' },
  ]),
  noteCategory: new CodeTable([
    { id: '1', text: 'Settlement' },
    { id: '2', text: 'Contact' },
    { id: '3', text: 'Reserve' },
  ]),
  test: new CodeTable([{ id: 'Y', text: '' }]),
  insuredCategory: new CodeTable([
    { id: '1', text: 'Vehicle Insured' },
    { id: '2', text: 'Person Insured' },
    { id: '3', text: 'Address Insured' },
    { id: '4', text: 'Vehicle Insured' },
    { id: '5', text: 'Vehicle Insured' },
    { id: '6', text: 'Marine Voyage' },
    { id: '7', text: 'Contract' },
    { id: '8', text: 'Marine Voyage' },
  ]),
  policyStatusTable: new CodeTable([
    { id: '2', text: 'In Force' },
    { id: '3', text: 'Terminated' },
  ]),
};
