export default {
  CodeTableText: require('./js/common/component/CodeTableText'),
  EU00AutoComplete: require('./js/common/component/EU00AutoComplete'),
  CommonConstant: require('./js/common/constant/CommonConstant'),
  PartyCodeTableText: require('./js/common/component/PartyCodeTableText'),
  CustomerCodeTableText: require('./js/common/component/CustomerCodeTableText'),
  CodeTableTextNoValuelink: require('./js/common/component/CodeTableTextNoValuelink'),
  CodeTableTextTwoLines: require('./js/common/component/CodeTableTextTwoLines'),
  PartySearchUi: require('./js/party/views/basic/PartySearchPanel'),
  PartyBaseInfo: require('./js/party/views/basic/CreatePartyMaintain'),
  PartyBaseInfoReadOnly: require('./js/party/views/readonly/basic/PartyMaintainReadOnly'),
  PartyAdditionTab: require('./js/party/views/PartyInfoTab'),
  PartyConst: require('./js/party/const/PartyConst'),
  DefinCodeTable: require('./js/party/codetable/CodeTable'),
  RemoteCodeTable: require('./js/common/codetable/RemoteCodeTable'),
  PartyAction: require('./js/party/actions/PartyAction'),
  EU00CommonService: require('./js/common/service/EU00CommonService'),

  PartySearchPanelDialog: require('./js/party/views/basic/PartySearchPanelDialog'),

  GroupIdDefine: require('./js/party/views/groupId/GroupIdDefine'),

  PtyIntegrationAccount: require('./js/common/component/integration/PtyIntegrationAccount'),
  PtyIntegrationAddress: require('./js/common/component/integration/PtyIntegrationAddress'),
  BaseAddress: require('./js/common/component/BaseAddress'),

  PtyIntegrationSearchUI: require('./js/common/component/integration/PtyIntegrationSearchPanel'),
  PtyIntegrationBaseInfo: require('./js/common/component/integration/PtyIntegrationBaseInfo'),
  PtyIntegrationSearchDialog: require('./js/common/component/integration/PtyIntegrationSearchDialog'),
  PtyIntegrationTabInfoDialog: require('./js/common/component/integration/PtyIntegrationTabInfoDialog'),
  PtyIntegrationBaseInfoReadOnly: require('./js/common/component/integration/readonly/PtyIntegrationBaseInfoReadOnly'),
  PtyIntegrationSearchCreateDialog: require('./js/common/component/integration/PtyIntegrationSearchCreateDialog'),
  PtyIntegrationThirdParty: require('./js/common/component/integration/PtyIntegrationThirdParty'),
  PtyIntegrationGruopId: require('./js/common/component/integration/PtyIntegrationGruopId'),

  PartyOrgBasicInfo: require('./js/party/views/PartyOrgBasicInfo'),
  PartyIndBasicInfo: require('./js/party/views/PartyIndBasicInfo'),
  PartyAddressInfo: require('./js/party/views/PartyAddressInfo'),
  PartyContactInfo: require('./js/party/views/PartyContactInfo'),
  PartyAccountInfo: require('./js/party/views/PartyAccountInfo'),
  PartyRelationShipInfo: require('./js/party/views/PartyRelationShipInfo'),
  RoleInfo: require('./js/party/views/RoleInfo'),
  PartyIndAddInfo: require('./js/party/views/PartyIndAddInfo'),
  PtyIntegrationCreationDialog: require('./js/common/component/integration/PtyIntegrationCreationDialog'),

  ARAPSearchResult: require('./js/common/component/finance/ARAPSearchResult'),
  FeeDetailDialogAction: require('./js/common/component/finance/FeeDetailDialogAction'),
  FeeDetailDialog: require('./js/common/component/finance/FeeDetailDialog'),
  CommonAction: require('./js/common/action/CommonAction'),
  CommonDocument: require('./js/common/component/dms/CommonDocument'),

  MsgFormatUtils: require('./js/common/util/MsgFormatUtils'),

  JCCPaymentAction: require('./js/common/action/JCCPaymentAction'),
  PaymentFail: require('./js/common/component/jccpayment/PaymentFail'),
  PaymentAmountDialog: require('./js/common/component/jccpayment/PaymentAmountDialog'),
  PaymentPendingDialog: require('./js/common/component/jccpayment/PaymentPendingDialog'),
  PaymentAuthorityDialog: require('./js/common/component/jccpayment/PaymentAuthorityDialog'),
  SettlementDetail: require('./js/common/component/claim/SettlementDetail'),
  RiskBroker: require('./js/common/component/claim/RiskBroker'),
  RiskBrokerByClaim: require('./js/common/component/claim/RiskBrokerByClaim'),
  name: 'EU00PartyCommonUI',
};
