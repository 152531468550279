import {
  MessageHelper,
  UICheckbox,
  UIParam,
  UISelect,
  UILink,
  UIColumn,
  UIDataTable,
  UIPanel,
  UIText,
  UINumber,
} from 'RainbowUI';
import DefinedCodeTable from '../../../codetable/CodeTable';
import PartyAddressInfoDialogReadOnly from './PartyAddressInfoDialogReadOnly';

const PartyAddressInfoReadOnly = React.createClass({
  getInitialState() {
    const { party } = this.props;
    const { AddressVoList } = party;
    const countryCodeTable = DefinedCodeTable.getCountry();
    const statusCodeTable = DefinedCodeTable.getPartyStatus();
    const addressTypeCodeTable = DefinedCodeTable.getAddressType();
    const regionCodeTable = DefinedCodeTable.geRegionCodeTable();
    return {
      AddressVoList,
      party,
      countryCodeTable,
      statusCodeTable,
      addressTypeCodeTable,
      regionCodeTable,
    };
  },
  createAddressHandler() {
    const createPartyAddress = {
      '@type': 'PartyAddress-PartyAddress',
    };
    this.refs.partyAddressInfoDialog.onShowPartyAddressInfoDialog(
      createPartyAddress,
      'create'
    );
  },

  editAddressHandler() {
    const selectData = UIDataTable.getSelectedRecord(
      'partyAddressInfo'
    );
    if (!selectData.length) {
      MessageHelper.warning(
        i18n.PartyMsg.WarnMsg.Warn_0005,
        i18n.PartyMsg.WarnMsg.Warn_0003,
        MessageHelper.POSITION_TOP_RIGHT
      );
      return;
    }
    const partyAddress = selectData[0];
    this.refs.partyAddressInfoDialog.onShowPartyAddressInfoDialog(
      partyAddress,
      'edit'
    );
  },
  viewHandler(event) {
    const partyAddress = event.getParameter('data');
    this.refs.partyAddressInfoDialogReadOnly.onShowPartyAddressInfoDialogReadOnly(
      partyAddress,
      'view'
    );
  },

  render() {
    const _self = this;
    const { AddressVoList, statusCodeTable, party } = this.state;
    return (
      <div>
        <UIPanel panelTitle={i18n.AddressDetail}>
          <UIDataTable
            id="partyAddressInfo"
            value={AddressVoList}
            indexable="false"
          >
            <UIColumn selectionMode="single" />
            <UIColumn
              headerTitle={i18n.CorrespondenceAddress}
              width="20%"
              render={data => {
                return (
                  <div className="dochis">
                    <UICheckbox
                      single="true"
                      model={data}
                      property="IsPrimaryAddress"
                      disabled="true"
                    />
                  </div>
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.AddressType}
              value="AddressType"
              render={data => {
                const val = _self.convertAddressType(
                  data.AddressType
                );
                return (
                  <div>
                    <UILink
                      value={val}
                      enabled="true"
                      disabled="false"
                      onClick={this.viewHandler}
                    >
                      <UIParam name="data" value={data} />
                    </UILink>
                  </div>
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.StreetName}
              render={data => {
                if (
                  _self.state.regionCodeTable.map[data.StreetName]
                ) {
                  return (
                    <div>
                      <UISelect
                        codeTable={_self.state.regionCodeTable}
                        value={data.StreetName}
                        io="out"
                      />
                    </div>
                  );
                }
                return (
                  <div>
                    <UIText value={data.StreetName} io="out" />
                  </div>
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.Area}
              render={data => {
                if (_self.state.regionCodeTable.map[data.Area]) {
                  return (
                    <div>
                      <UISelect
                        codeTable={_self.state.regionCodeTable}
                        value={data.Area}
                        io="out"
                      />
                    </div>
                  );
                }
                return (
                  <div>
                    <UIText value={data.Area} io="out" />
                  </div>
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.DistrictOrCity}
              render={data => {
                if (_self.state.regionCodeTable.map[data.City]) {
                  return (
                    <div>
                      <UISelect
                        codeTable={_self.state.regionCodeTable}
                        value={data.City}
                        io="out"
                      />
                    </div>
                  );
                }
                return (
                  <div>
                    <UIText value={data.City} io="out" />
                  </div>
                );
              }}
            />

            <UIColumn
              headerTitle={i18n.Country}
              render={data => {
                if (!data.Country) {
                  return (
                    <div>
                      <UISelect
                        defaultValue="CY"
                        codeTable={_self.state.countryCodeTable}
                        io="out"
                      />
                    </div>
                  );
                }
                return (
                  <div>
                    <UISelect
                      value={data.Country}
                      codeTable={_self.state.countryCodeTable}
                      io="out"
                    />
                  </div>
                );
              }}
            />
            <UINumber
              headerTitle={i18n.PostalCode}
              render={data => {
                if (_self.state.regionCodeTable.map[data.PostCode]) {
                  return (
                    <div style={{ textAlign: 'center' }}>
                      <UISelect
                        codeTable={_self.state.regionCodeTable}
                        value={data.PostCode}
                        io="out"
                      />
                    </div>
                  );
                }
                return (
                  <div style={{ textAlign: 'center' }}>
                    <UIText value={data.PostCode} io="out" />
                  </div>
                );
              }}
            />
            <UIColumn headerTitle={i18n.Status} value="AddressStatus">
              <UISelect codeTable={statusCodeTable} io="out" />
            </UIColumn>
          </UIDataTable>
        </UIPanel>
        <PartyAddressInfoDialogReadOnly
          ref="partyAddressInfoDialogReadOnly"
          parentComponet={this}
          party={party}
        />
      </div>
    );
  },
  updatePanel(party) {
    const result = this.props.submitInfoHandler(party);
    this.setState({
      party: result,
      AddressVoList: result.AddressVoList,
    });
  },
  handlePrimaryAddress(data) {
    const { AddressVoList } = this.state;
    _.each(AddressVoList, tempPartyAddress => {
      const partyAddress = tempPartyAddress;
      if (partyAddress.IsPrimaryAddress === 'Y') {
        partyAddress.IsPrimaryAddress = 'N';
      }
    });
    const pk = data.component.props.model['@pk'];
    if (data.newValue === 'Y') {
      _.each(AddressVoList, tempPartyAddress => {
        const partyAddress = tempPartyAddress;
        if (partyAddress['@pk'] == pk) {
          partyAddress.IsPrimaryAddress = 'Y';
        }
      });
    }
    this.setState({
      AddressVoList,
    });
  },
  convertAddressType(addressType) {
    const addressCodeTable = this.state.addressTypeCodeTable;
    let text = '';
    _.each(addressCodeTable.codes, obj => {
      if (obj.id == addressType) {
        text = obj.text;
      }
    });
    return text;
  },
});
export default PartyAddressInfoReadOnly;
