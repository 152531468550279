import { Caller, MessageHelper } from 'RainbowUI';
import Url from '../url/Url';

const ClaimCaseAction = {
  loadClaimCase(caseid) {
    const url = Url.loadClaimCase.replace('{caseId}', caseid);
    let result = {};
    Caller.call(url, null, {
      method: 'GET',
      async: false,
      block: true,
    }).then(
      data => {
        result = data;
      },
      () => {
        MessageHelper.error(
          i18n.ClaimSettlement.ErrorMsg.Error_0001,
          i18n.ClaimSettlement.ErrorMsg.Error_0002,
          MessageHelper.POSITION_TOP_RIGHT
        );
      }
    );
    return result;
  },
  checkSettlementAmount(settlementItemEntity) {
    const url = Url.checkSettlementAmount;
    let result = {};
    Caller.call(url, settlementItemEntity, {
      method: 'POST',
      async: false,
      block: true,
    }).then(
      data => {
        result = data;
      },
      () => {
        MessageHelper.error(
          i18n.ClaimSettlement.ErrorMsg.Error_0003,
          i18n.ClaimSettlement.ErrorMsg.Error_0001,
          MessageHelper.POSITION_TOP_RIGHT
        );
      }
    );
    return result;
  },
  checkRecoveryAmount(TempsettlementItemEntity) {
    const settlementItemEntity = TempsettlementItemEntity;
    const url = Url.checkRecoveryAmount;
    let result = {};
    delete settlementItemEntity.dataIndex;
    Caller.call(url, settlementItemEntity, {
      method: 'POST',
      async: false,
      block: true,
    }).then(
      data => {
        result = data;
      },
      () => {
        MessageHelper.error(
          i18n.ClaimSettlement.ErrorMsg.Error_0004,
          i18n.ClaimSettlement.ErrorMsg.Error_0002,
          MessageHelper.POSITION_TOP_RIGHT
        );
      }
    );
    return result;
  },
};
export default ClaimCaseAction;
