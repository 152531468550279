import {
  DataContext,
  UpdateContext,
  UIDialog,
  AjaxUtil,
  MessageHelper,
} from 'RainbowUI';
import {
  RestStatus,
  ClaimMessageForamtUtils,
} from 'ClaimCommonConstant';
import Url from '../url/Url';
import TreeNode from '../constant/TreeNodeCache';

export default {
  getLossCauseList(pageindex, pagesize) {
    const data = TreeNode.TreeCache.get('TreeNode');
    const productLineCodeJS = {
      productLineCode: data.treeNodeCode,
    };
    const searchCondition = {};
    searchCondition.ProductLineCode =
      productLineCodeJS.productLineCode;
    searchCondition.PageNo = pageindex - 1;
    searchCondition.PageSize = pagesize;
    const url = Url.getLossCauseOfProductLineListByCauseOfLossCode;
    if (DataContext.get('searchCauseLossCondition')) {
      const condition = DataContext.get('searchCauseLossCondition');
      if (condition.causeofLossCode) {
        searchCondition.CauseofLossCode = condition.causeofLossCode
          .replace(/(^\s*)|(\s*$)/g, '')
          .toUpperCase();
      }
    }
    let result = {};
    AjaxUtil.doPost(url, searchCondition, {
      async: false,
      done(data) {
        result = data.Model;
      },
      fail() {
        MessageHelper.error(
          i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
        );
      },
    });

    return { count: result.Total, result: result.Content };
  },

  saveLossCause(newdata) {
    const newLossCause = newdata;
    let url = Url.ClaimLossCauseOfProductLineUpdate;
    if (!newLossCause.ProductLineCode) {
      const data = TreeNode.TreeCache.get('TreeNode');
      newLossCause.ProductLineCode = data.treeNodeCode;
      url = Url.ClaimLossCauseOfProductLineSave;
    }
    let result = 'false';
    AjaxUtil.doPost(url, newLossCause, {
      async: false,
      done(data) {
        if (data.Status == RestStatus.OK) {
          result = 'true';
          MessageHelper.success(
            ClaimMessageForamtUtils.formatI18NMsg(
              i18n.ClaimCommon.SaveSuccess,
              i18n.ClaimProduct.CauseOfLoss
            )
          );
          UIDialog.hide('lossCauseDialog');
          UpdateContext.forceUpdate('causeLossList');
        } else if (data.Status == RestStatus.BLOCK) {
          newLossCause.ProductLineCode = null;
          MessageHelper.error(
            ClaimMessageForamtUtils.formatI18NMsg(
              i18n.ClaimCommon.IsExist,
              i18n.ClaimProduct.CauseOfLoss
            )
          );
        } else {
          MessageHelper.error(data.Status);
        }
      },
      fail() {
        MessageHelper.error(
          i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
        );
      },
    });
    return result;
  },

  removeLossCause(newdata) {
    const data = newdata;
    const lossCauseCode = {
      LossCauseCode: data.LossCauseCode,
    };
    AjaxUtil.doPost(
      Url.ClaimLossCauseOfProductLineRemove +
        lossCauseCode.LossCauseCode,
      {},
      {
        async: false,
        done(data) {
          if (data.Status == RestStatus.BLOCK) {
            MessageHelper.error(
              ClaimMessageForamtUtils.formatI18NMsg(
                i18n.ClaimCommon.IsExist,
                i18n.ClaimProduct.CauseOfLoss
              )
            );
          } else if (data.Status == RestStatus.OK) {
            MessageHelper.success(
              ClaimMessageForamtUtils.formatI18NMsg(
                i18n.ClaimCommon.DeleteSuccess,
                i18n.ClaimProduct.CauseOfLoss
              )
            );
          }
        },
        fail() {
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
        },
      }
    );
    UpdateContext.forceUpdate('causeLossList');
  },

  searchCauseLossList(condition) {
    DataContext.put('searchCauseLossCondition', condition);
    UpdateContext.forceUpdate('CauseLossListSearchResultProvider');
  },
};
