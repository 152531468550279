import alt from '../alt';

const SubrogationAction = require('../action/SubrogationAction');

class SubrogationReadOnlyStore {
  constructor() {
    this.SubrogationReadOnly = {
      SubrogationInfo: {
        SubrogationItems: [],
      },
      OtherInfo: {
        CoverageStructure: [{ CoverageList: [] }],
        SubrogationStructure: [],
        ReserveCodeTable: [],
        ReserveStructure: [],
      },
    };

    this.SubrogationApprove = {
      SubrogationInfo: {
        SubrogationItems: [],
      },
      OtherInfo: {
        CoverageStructure: [{ CoverageList: [] }],
        SubrogationStructure: [],
        ReserveCodeTable: [],
        ReserveStructure: [],
      },
    };

    this.bindListeners({
      handleLoadSubrogationReadOnly:
        SubrogationAction.LOAD_SUBROGATION_READ_ONLY,
    });
  }

  handleLoadSubrogationReadOnly(response) {
    let updateObj = this.SubrogationReadOnly;
    if (response.isApproveTask == true) {
      updateObj = this.SubrogationApprove;
    }
    updateObj.OtherInfo.CoverageStructure =
      response.data.CoverageStructure;
    updateObj.OtherInfo.SubrogationStructure =
      response.data.SubrogationStructure;
    updateObj.OtherInfo.ReserveCodeTable =
      response.data.ReserveCodeTable;
    updateObj.OtherInfo.ReserveStructure =
      response.data.ReserveStructure;
    updateObj.SubrogationInfo = response.data.SubrogationInfo;
  }
}
export default alt.createStore(
  SubrogationReadOnlyStore,
  SubrogationReadOnlyStore
);
