import {
  UpdateContext,
  UIParam,
  UIDataTable,
  UIDateTimePicker,
  UIColumn,
  UILink,
  UISelect,
  CodeTable,
  UIUpdatePanel,
} from 'RainbowUI';
import BindToMixin from 'react-binding';
import { boundMethod } from 'autobind-decorator';
import ClaimWorkFlowMyTaskAction from '../../action/desktop/ClaimWorkFlowMyTaskAction';
import ConditionStore from '../../store/PendingConditionStore';

const CodeTableAction = require('../../action/CodeTableAction');
const CodeTableCstUI = require('../../constant/CodeTableCstUI');

class ClaimWorkFlowMyTaskList extends React.Component {
  static mixins = [BindToMixin];

  constructor(props) {
    super(props);
    this.state = { condition: {} };
  }

  componentWillUnmount() {
    ConditionStore.unlisten(this.onStoreStateChange);
  }

  onStoreStateChange = storeState => {
    if (!_.isEmpty(storeState.condition)) {
      this.setState({ condition: storeState.condition });
    }
  };

  @boundMethod
  getMyTaskList(pageindex, pagesize) {
    const { condition } = this.state;
    delete condition.flag;
    return ClaimWorkFlowMyTaskAction.getMyTaskList(
      pageindex,
      pagesize,
      condition
    );
  }

  componentDidMount() {
    ConditionStore.listen(this.onStoreStateChange);
  }

  mockUser() {
    const codeTable = [
      { id: '66', text: 'andy' },
      { id: '-11', text: 'System' },
      { id: '67', text: 'gerneral' },
    ];
    return new CodeTable(codeTable);
  }

  onClickClear() {
    this.setState({ condition: {} });
    UpdateContext.forceUpdate('claimWorkFlowMyTask');
  }

  render() {
    return (
      <UIUpdatePanel id="claimWorkFlowMyTask">
        <UIDataTable
          indexable="false"
          id="workListTable"
          provider={this.getMyTaskList}
          headerTitle={i18n.ClaimCommon.MyTask}
        >
          <UIColumn
            headerTitle={i18n.ClaimCommon.TaskType}
            value="TaskDefinitionKey"
            sortable="true"
            render={data => {
              return (
                <UISelect
                  id={`taskType_${data.dataIndex || data.id}`}
                  value={data.TaskDefinitionKey}
                  codeTable={CodeTableAction.loadDataListAsync(
                    CodeTableCstUI.CLAIMTASK,
                    `taskType_${data.dataIndex || data.id}`
                  )}
                  io="out"
                />
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.ClaimCommon.ClaimNumber}
            value="ClaimNo"
            sortable="true"
            render={data => {
              return (
                <UILink
                  value={data.ClaimNo}
                  onClick={ClaimWorkFlowMyTaskAction.openMyTask}
                >
                  <UIParam name="data" value={data} />
                </UILink>
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.ClaimCommon.AssignedBy}
            value="AssignBy"
            sortable="true"
            render={data => {
              return (
                <UISelect
                  id={`assignBy_${data.dataIndex || data.id}`}
                  value={data.AssignBy}
                  codeTable={CodeTableAction.loadDataListAsync(
                    CodeTableCstUI.ClaimUser,
                    `assignBy_${data.dataIndex || data.id}`,
                    "useListNeedSystem"
                  )}
                  io="out"
                />
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.ClaimCommon.DueDate}
            value="TaskDueTime"
            sortable="true"
          >
            <UIDateTimePicker
              placeHolder="MM/DD/YYYY HH:mm:ss"
              format="MM/DD/YYYY HH:mm:ss"
              io="out"
              sortable="true"
            />
          </UIColumn>
          <UIColumn
            headerTitle={i18n.ClaimCommon.Priority}
            value="TaskPrioriry"
            sortable="true"
            render={data => {
              return (
                <UISelect
                  id={`taskPrioriry_${data.dataIndex || data.id}`}
                  value={data.TaskPrioriry}
                  codeTable={CodeTableAction.loadDataListAsync(
                    CodeTableCstUI.Prioriry,
                    `taskPrioriry_${data.dataIndex || data.id}`
                  )}
                  io="out"
                />
              );
            }}
          />
        </UIDataTable>
      </UIUpdatePanel>
    );
  }
}

export default ClaimWorkFlowMyTaskList;
