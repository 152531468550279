import {
  UILink,
  UIColumn,
  UIDataTable,
  UIPanel,
  UIText,
  UIParam,
} from 'RainbowUI';
import moment from 'moment';
import PartyHistoryDetailDialog from './PartyHistoryDetailDialog';
import PartyAction from '../actions/PartyAction';

const _ = require('underscore');

const partyAction = new PartyAction();

const PartyHistory = React.createClass({
  getInitialState() {
    const partyHistoryList = partyAction.loadPartyHistoryListByPartyId(
      this.props.party.PartyId
    );
    return {
      party: this.props.party,
      partyHistoryList,
    };
  },

  render() {
    const _self = this;

    const newArr = new Array();

    _.each(_self.state.party.PartyHistoryList, (ele, index, arry) => {
      const arr = arry;
      const info = ele.HistoryInfo;
      let tempSplit;
      let date = '';
      let user = '';
      if (info) {
        tempSplit = info.split(',');
        const title = tempSplit[0];
        if (title.indexOf('Create') !== -1) {
          _.each(tempSplit, ele => {
            const indey = ele.indexOf('Create Date');
            const indei = ele.indexOf('->');
            if (indey !== -1) {
              date = ele.slice(
                indey + 'Create Date: '.length,
                ele.length - 1
              );
              if (date.indexOf('T') !== -1) {
                date = date.replace('T', ' ');
              }
            }
            const indez = ele.indexOf('Create By: ');

            if (indez !== -1) {
              if (indei !== -1) {
                user = ele.slice(
                  indez + 'Create By: '.length,
                  ele.length - 1 - ' -> '.length
                );
              } else {
                user = ele.slice(
                  indez + 'Create By: '.length,
                  ele.length - 1
                );
              }
            }
          });
        } else if (title.indexOf('Update') !== -1) {
          _.each(tempSplit, ele => {
            const indey = ele.indexOf('Update Time: ');
            const indei = ele.indexOf('->');
            let tempDate;
            let tempUser;
            if (indey !== -1) {
              if (indei !== -1) {
                tempDate = ele.slice(
                  indey + 'Update Time: '.length,
                  ele.length - 1 - ' -> '.length
                );
              } else {
                tempDate = ele.slice(
                  indey + 'Update Time: '.length,
                  ele.length - 1
                );
              }
              if (tempDate === '') {
                /* empty */
              } else {
                date = tempDate;
              }

              if (date.indexOf('T') !== -1) {
                date = date.replace('T', ' ');
              }
            }
            const indez = ele.indexOf('Update By: ');
            if (indez !== -1) {
              if (indei !== -1) {
                tempUser = ele.slice(
                  indez + 'Update By: '.length,
                  ele.length - 1 - ' -> '.length
                );
              } else {
                tempUser = ele.slice(
                  indez + 'Update By: '.length,
                  ele.length - 1
                );
              }

              if (tempUser === '') {
                /* empty */
              } else {
                user = tempUser;
              }
            }

            const indez2 = ele.indexOf('UpdateBy: ');
            if (indez2 !== -1) {
              if (indei !== -1) {
                tempUser = ele.slice(
                  indez2 + 'UpdateBy: '.length,
                  ele.length - 1 - ' -> '.length
                );
              } else {
                tempUser = ele.slice(
                  indez2 + 'UpdateBy: '.length,
                  ele.length - 1
                );
              }

              if (tempUser === '') {
                /* empty */
              } else {
                user = tempUser;
              }
            }
          });
        }
        arr[index].UpdatedTime = date;
        arr[index].UpdatedBy = user;
        newArr.push(arr[index]);
      } else {
        /* empty */
      }
    });
    _self.state.party.PartyHistoryList = newArr;
    this.arraySorter(
      _self.state.partyHistoryList,
      'HistoryId',
      'desc'
    );
    return (
      <UIPanel panelTitle={i18n.History}>
        <UIDataTable
          id="historyInfo"
          indexable="false"
          value={_self.state.partyHistoryList}
        >
          <UIColumn
            headerTitle={i18n.HistoryId}
            render={data => {
              return (
                <div>
                  <UIText
                    model={data}
                    property="HistoryId"
                    io="out"
                  />
                </div>
              );
            }}
          />
          {/* <UIColumn headerTitle={i18n.UpdatedTime} value = "UpdateTime"/> */}
          <UIColumn
            headerTitle={i18n.UpdatedTime}
            render={data => {
              const UpdateTime = moment(data.UpdateTime).format(
                'MM/DD/YYYY'
              );
              return <UIText value={UpdateTime} io="out" />;
            }}
          />

          <UIColumn
            headerTitle={i18n.UpdatedBy}
            render={data => {
              const userName = partyAction.loadUserNameByUserId(
                data.UpdateBy
              );
              return <UIText value={userName} io="out" />;
            }}
          />

          <UIColumn
            headerTitle={i18n.DetailInformation}
            render={data => {
              return (
                <div>
                  <UILink value="Detail" onClick={_self.viewDetail}>
                    <UIParam name="data" value={data} />
                  </UILink>
                </div>
              );
            }}
          />
        </UIDataTable>
        <PartyHistoryDetailDialog
          ref="PartyHistoryDetailDialog"
          party={_self.state.party}
        />
      </UIPanel>
    );
  },

  viewDetail(event) {
    const party = event.getParameter('data');
    this.refs.PartyHistoryDetailDialog.showHistoryDialog(party);
  },

  arraySorter(arrayList, sortColumn, sortWay) {
    if (!arrayList || arrayList.length <= 0 || !sortColumn) {
      return;
    }

    const sorter = () => {
      const v1 = eval(`a.${sortColumn}`);
      const v2 = eval(`b.${sortColumn}`);

      if (!v1) {
        return !v2 ? 0 : -1;
      }
      if (!v2) {
        return 1;
      }
      if (v1 > v2) {
        return 1;
      }
      if (v1 < v2) {
        return -1;
      }
      return 0;
    };

    if (sortWay === 'asc') {
      arrayList.sort(sorter);
    } else {
      arrayList.sort((a, b) => {
        return 0 - sorter(a, b);
      });
    }
  },
});
export default PartyHistory;
