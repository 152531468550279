export default {
  StringFormat(...params) {
    let result = this;
    if (params.length > 0) {
      if (params.length === 1 && typeof params[0] === 'object') {
        const args = params[0];
        if (args.constructor == Array) {
          for (let i = 0; i < args.length; i += 1) {
            if (args[i]) {
              result = result.replace(
                new RegExp(`({[${i}]})`, 'g'),
                args[i]
              );
            }
          }
        } else {
          for (const key in args) {
            if (args[key]) {
              result = result.replace(
                new RegExp(`({${key}})`, 'g'),
                args[key]
              );
            }
          }
        }
      } else {
        for (let i = 0; i < params.length; i += 1) {
          if (params[i]) {
            result = result.replace(
              new RegExp(`({[${i}]})`, 'g'),
              params[i]
            );
          }
        }
      }
    }
    return result;
  },
  getI18NMsg(msgKey, ...srcModules) {
    let msg = '';
    if (msgKey && srcModules.length > 0) {
      let srcMds;
      if (
        srcModules.length === 1 &&
        srcModules[0].constructor == Array
      ) {
        srcMds = srcModules[0];
      } else {
        srcMds = srcModules;
      }
      for (let i = 0; i < srcMds.length; i += 1) {
        try {
          msg = srcMds[i][msgKey];
          if (msg) {
            break;
          }
        } catch (ex) {
          console.log(ex);
        }
      }
    }
    return msg;
  },
  formatI18NMsg(tmpmessage, ...params) {
    let message = tmpmessage;
    if (message && params.length > 0) {
      let args;
      if (params.length === 1) {
        args = params[0];
      } else {
        args = params;
      }
      const defaultFormat = String.prototype.format;
      String.prototype.format = this.StringFormat;
      message = message.format(args);
      String.prototype.format = defaultFormat;
    }
    return message;
  },
  formatMsg(msg, ...srcModules) {
    if (msg) {
      const errI18NMsg = this.getI18NMsg(msg.Type, srcModules);
      if (!errI18NMsg) {
        return '';
      }
      let message;
      if (msg.Message) {
        message = msg.Message.substr(msg.Type.length + 1);
      }
      let params;
      if (message) {
        try {
          if (message.indexOf('{') == -1) {
            message = `{${message}}`;
          }
          params = eval(`(${message})`);
        } catch (ex) {
          console.log(ex);
          params = message;
        }
      }
      const errMsg = this.formatI18NMsg(errI18NMsg, params);
      if (errMsg) {
        return errMsg;
      }
    }
    return '';
  },
};
