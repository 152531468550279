export default {
  // common
  Status: '状态',
  SettlementAmount: '决赔金额',
  CoInsurancePayment: '共保付款',

  CancelSettlement: '取消决赔',
  CancelRecovery: '取消追偿',
  CancelConfirmation: '确认取消',
  CancelRemarkPlaceHolder: '请输入撤消备注',
  CancelRecoveryMessage: '请确认是否撤消此追偿?',
  CancelSettlementMessage: '请确认是否撤消此决赔?',

  // Settlement History
  SettlementHistory: '决赔历史',
  SettlementNo: '决赔编号',
  TransactionDate: '提交时间',
  IssuedBy: '申请人',
  ApprovedBy: '签核人',
  Name: '领款人姓名',

  // Settlement Info
  PayMode: '付款方式',
  ExchangeRate: '汇率',
  PayeeName: '领款人姓名',
  PayeeAddress: '领款人地址',
  PayeeAccount: '领款人账号',
  SwiftCode: 'SWIFT Number',
  OSReserve: '未决准备金',
  PartialFinal: '部分决赔/最终决赔',
  SettlementType: '支付方式',
  DeletePayee: '删除领款人',
  AddItem: '添加',
  AddPayee: '添加领款人',
  AddPayer: '添加付款人 /付款人',
  DeletePayer: '删除付款人 /付款人',

  SettlementDetail: '决赔信息',
  Settlement: '决赔',
  SettlementApproval: '决赔签核',

  NoPayee: '请添加领款人',

  ReferenceNumber: '支付凭证编号',
  ReferenceDate: '支付凭证日期',

  SettlemenAmountCheck: '决赔金额应小于0',

  ConfirmMessage: ' 你确定要关闭窗口吗 ?',
  ConfirmMessageTitle: '确认',

  Recovery: '追偿',
  RecoveryDetail: '追偿信息',
  RecoveryHistory: '追偿历史',
  RecoveryHistoryDetail: '追偿历史信息',
  PayerName: '付款人',
  RecoveryNo: '追偿编号',
  RecoveryAmount: '追偿金额',
  RecoveryType: '追偿方式',

  TaxCode: 'GST代碼',
  TaxRate: 'GST%',
  TaxAmount: 'GST金額',

  // MessageHelper
  SettlementSaveSuccess: '决赔保存成功',
  SettlementSubmitSuccess: '决赔提交成功',
  SettlementApprovalSubmitSuccess: '决赔签核提交成功',
  SettlementCancelSuccess: '取消决赔历史提交成功',
  SettlementSubmitError: '每个领款人至少包含一项',
  RecoverySaveSuccess: '追偿保存成功',
  RecoverySubmitSuccess: '追偿提交成功',
  RecoveryApprovalSubmitSuccess: '追偿签核提交成功',
  RecoveryCancelSuccess: '取消追偿历史提交成功',
  RecoverySubmitError: '每个付款人至少包含一项',
  ChoosePartialFinalFail:
    '针对同一准备金的添加多条决赔项目时，只有最后一条决赔项目允许选择最终赔付',
  DeletePayeeError: '系统必须要有一个领款人',
  ChooseReserveError: '相同准备金类型已存在',
  NotZero: '不能输为零',
  FraudIndicatorWarning: '欺诈指标已提出，请核实',
  ChoosePayeeError: '请添加付款人/领款人',
  RecoveryApproval: '追偿签核',
  FeeStatusContinutMessage: '你还想继续吗?',
  WithOutPolicyError: '无保单赔案,请先抄单',
};
