import {
  MessageHelper,
  UICheckbox,
  UIParam,
  UIDialog,
  UILink,
  UIColumn,
  UIDataTable,
  UIPanel,
  DataContext,
} from 'RainbowUI';
import EditPartyContactDialog from './EditPartyContactDialog';
import EditPartyContactDialogReadOnly from './readonly/views/EditPartyContactDialogReadOnly';
import PartyAction from '../actions/PartyAction';
import PartyConst from '../const/PartyConst';

const PartyContactInfo = React.createClass({
  getInitialState() {
    let { party } = this.props;
    if (DataContext.get('Party')) {
      party = DataContext.get('Party');
    }
    let { PartyContactList } = party;
    if (!PartyContactList) {
      PartyContactList = [];
    }
    _.forEach(PartyContactList, (temppartyContact, index) => {
      const partyContact = temppartyContact;
      partyContact.DefinedIndex = index + 1;
    });
    const totalNum = PartyContactList.length;
    return {
      PartyContactList,
      TotalNum: totalNum,
      party,
    };
  },

  createContactHandler() {
    const definedIndex = this.state.TotalNum + 1;
    const createPartyContact = {
      '@type': 'PartyContact-PartyContact',
      DefinedIndex: definedIndex,
    };
    this.refs.editPartyContactDialog.showDialog(createPartyContact);
  },

  savePartyContact(partyContact) {
    let { party } = this.state;
    if (DataContext.get('Party')) {
      party = JSON.parse(JSON.stringify(DataContext.get('Party')));
    }
    const partyAction = new PartyAction();
    const copyParty = partyAction.loadPartyByPartyId(party.PartyId);
    const { PartyHistoryList } = copyParty;
    if (partyContact['@pk']) {
      const newPartyHistory = {
        '@type': 'PartyHistory-PartyHistory',
        BusinessObjectId: 75158352,
        HistoryType: 12,
      };
      PartyHistoryList.push(newPartyHistory);
    } else {
      const newPartyHistory = {
        '@type': 'PartyHistory-PartyHistory',
        BusinessObjectId: 75158352,
        HistoryType: 11,
      };
      PartyHistoryList.push(newPartyHistory);
    }
    party.PartyHistoryList = PartyHistoryList;
    let { PartyContactList } = party;
    if (!PartyContactList) {
      PartyContactList = [];
    }
    const definedIndex = partyContact.DefinedIndex;
    for (let idx = 0; idx < PartyContactList.length; idx += 1) {
      if (PartyContactList[idx].DefinedIndex == definedIndex) {
        PartyContactList.splice(idx, 1);
        break;
      }
    }
    if (partyContact.IsPrimaryContact === 'Y') {
      _.forEach(PartyContactList, temp => {
        const partyContact = temp;
        partyContact.IsPrimaryContact = 'N';
      });
    }
    PartyContactList.push(partyContact);
    _.forEach(PartyContactList, temp => {
      const partyContact = temp;
      delete partyContact.DefinedIndex;
      delete partyContact.dataIndex;
    });
    party.PartyContactList = PartyContactList;
    party.AddressVoList = copyParty.AddressVoList;
    party.PartyAccountList = copyParty.PartyAccountList;
    const result = this.props.submitInfoHandler(party, 5);
    if (!(PartyConst.FAIL == result)) {
      _.forEach(result.PartyContactList, (temp, index) => {
        const partyContact = temp;
        partyContact.DefinedIndex = index + 1;
      });
      const resultList = result.PartyContactList;
      this.setState({
        party: result,
        PartyContactList: resultList,
        TotalNum: PartyContactList.length + 1,
      });
    } else {
      let { party } = this.state;
      if (DataContext.get('Party')) {
        party = JSON.parse(JSON.stringify(DataContext.get('Party')));
      }
      this.setState({
        party,
        PartyContactList: party.PartyContactList,
      });
      return PartyConst.FAIL;
    }
  },

  editContactHandler() {
    const selectData = UIDataTable.getSelectedRecord(
      'partyContractInfo'
    );
    if (!selectData.length) {
      MessageHelper.warning(
        i18n.PartyMsg.WarnMsg.Warn_0005,
        i18n.PartyMsg.WarnMsg.Warn_0003,
        MessageHelper.POSITION_TOP_RIGHT
      );
      return;
    }
    const partyContact = selectData[0];
    this.refs.editPartyContactDialog.showDialog(partyContact);
  },

  viewHandler(clickEvent) {
    let partyContact = {};
    if (!_.isEmpty(clickEvent.paramJson)) {
      partyContact = clickEvent.paramJson[0].value;
    }
    this.refs.editPartyContactDialogReadOnly.showDialog(partyContact);
  },
  onClickSubmit() {
    MessageHelper.info(i18n.PartyMsg.InfoMsg.Info_0001);
    UIDialog.hide('ContactDialog');
  },
  onClickClose() {
    UIDialog.hide('ContactDialog');
  },

  render() {
    const _self = this;
    const { PartyContactList } = this.state;
    return (
      <div>
        <UIPanel panelTitle={i18n.ContactInfo}>
          <UIDataTable
            id="partyContractInfo"
            value={PartyContactList}
            functions={_self.buildTableFunction()}
            indexable="false"
          >
            <UIColumn selectionMode="single" />
            <UIColumn
              headerTitle={i18n.PrimaryContact}
              render={data => {
                return (
                  <div>
                    <UICheckbox
                      single="true"
                      model={data}
                      property="IsPrimaryContact"
                      disabled={data.ContactStatus == '2'}
                      onChange={_self.handlePrimaryContact}
                    />
                  </div>
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.ContactPerson}
              value="Name"
              render={data => {
                return (
                  <div>
                    <UILink
                      value={data.ContactPerson}
                      enabled="true"
                      disabled="false"
                      onClick={this.viewHandler}
                    >
                      <UIParam name="data" value={data} />
                    </UILink>
                  </div>
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.MobileTel}
              value="HandPhone"
            />
            <UIColumn
              headerTitle={i18n.WorkTel}
              value="BusinessTel"
            />
            <UIColumn headerTitle={i18n.email} value="Email" />
          </UIDataTable>
          <EditPartyContactDialog
            resetState={this.resetState}
            ref="editPartyContactDialog"
            submitHandler={this.savePartyContact}
          />
          <EditPartyContactDialogReadOnly ref="editPartyContactDialogReadOnly" />
        </UIPanel>
      </div>
    );
  },
  buildTableFunction() {
    const _self = this;
    return (
      <div>
        <UILink
          icon="fa fa-plus"
          size="lg"
          iconClass="add_iconbtn"
          tooltip={i18n.add}
          onClick={_self.createContactHandler}
        />
        <UILink
          icon="fa fa-pencil"
          size="lg"
          iconClass="edit_iconbtn"
          tooltip={i18n.edit}
          onClick={_self.editContactHandler}
        />
      </div>
    );
  },

  handlePrimaryContact(data) {
    const { PartyContactList } = this.state;
    _.each(PartyContactList, temppartyContact => {
      const partyContact = temppartyContact;
      if (partyContact.IsPrimaryContact === 'Y') {
        partyContact.IsPrimaryContact = 'N';
        return partyContact;
      }
    });
    const pk = data.component.props.model['@pk'];
    if (data.newValue === 'Y') {
      _.each(PartyContactList, temppartyContact => {
        const partyContact = temppartyContact;
        if (partyContact['@pk'] == pk) {
          partyContact.IsPrimaryContact = 'Y';
        }
      });
    }
    this.props.party.PartyContactList = PartyContactList;
    this.setState({
      PartyContactList,
    });
  },

  resetState() {
    const { party } = this.state;
    this.setState({
      PartyContactList: party.PartyContactList,
    });
  },
});

export default PartyContactInfo;
