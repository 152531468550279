const ClaimRegistration = {
  getClaimInfo() {
    return require('./module/registration/common/component/ClaimInfo');
  },
  getClaimPoliceInfo() {
    return require('./module/registration/common/component/ClaimPoliceInfo');
  },
  ClaimCompanyCareInfo() {
    return require('./module/registration/common/component/ClaimCompanyCareInfo');
  },
  getClaimInfoRead() {
    return require('./module/registration/common/component/ClaimInfoRead');
  },
  getCoverage() {
    return require('./module/registration/common/component/Coverage');
  },
  getSubclaimInfo() {
    return require('./module/registration/common/component/SubclaimInfo');
  },
  getClaimRegistrationHandling() {
    return require('./module/registration/common/component/RegistrationHandling');
  },
  getClaimRegistrationHandlingAction() {
    return require('./module/registration/common/action/RegistrationHandlingAction');
  },

  getRegistrationPolicyDialog() {
    return require('./module/registration/common/component/RegistrationPolicyDialog');
  },
  getWithOutPolicyInsuredDialog() {
    return require('./module/registration/common/component/WithOutPolicyInsuredDialog');
  },

  getModifyClaimInfoDialog() {
    return require('./module/registration/common/component/ModifyClaimInfoDialog');
  },

  getClaimRegistrationValidationAction() {
    const RegistrationValidationAction = require('./module/registration/common/action/RegistrationValidationAction');
    return new RegistrationValidationAction();
  },
};

export default ClaimRegistration;
