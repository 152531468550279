import {
  UIDataTable,
  UIColumn,
  UILink,
  UIParam,
  UICheckbox,
  UIRadio,
  UISmartPanelGrid,
  UISelect,
  UIText,
} from 'RainbowUI';
import PropTypes from 'prop-types';

const PartyAddressInfo = require('./PartyAddressInfo');
const code = require('../mock/codeTable');
const CodeTableAction = require('../../common/action/CodeTableAction');
const CodeTableCstUI = require('../../common/constant/CodeTableCstUI');

const PartyAddress = React.createClass({
  propTypes: {
    addressList: PropTypes.Array,
  },
  getDefaultProps() {
    return {
      addressList: [],
    };
  },
  getInitialState() {
    const partyVo = this.props.partyVo;
    let list;
    let addressId;
    if (partyVo) {
      list = partyVo.AddressVoList || [];
      addressId = partyVo.PtyAddressId;
    } else {
      list = this.props.addressList;
      addressId = this.props.addressId;
    }
    for (const item in list) {
      const vo = list[item];
      if (list.length === 1) {
        vo.selected = 'Y';
        vo.IsPrimaryAddress = 'Y';
        vo.AddressStatus = '1';
      } else if (vo.AddressId == addressId) {
        vo.selected = 'Y';
      } else if (
        addressId == undefined &&
        vo.IsPrimaryAddress === 'Y'
      ) {
        vo.selected = 'Y';
      }
    }
    return {
      list,
    };
  },

  componentWillReceiveProps(nextProps) {
    const list = nextProps.addressList;
    for (const item in list) {
      const vo = list[item];
      if (vo.IsPrimaryAddress === 'Y') {
        vo.selected = 'Y';
      }
    }
    this.setState({ list });
  },
  getAddressList() {
    return this.state.list;
  },
  changePrimary(event) {
    this.setState(prevState => {
      const dataIndex = event.getParameter('dataIndex');
      const addressList = prevState.list;

      for (const item in addressList) {
        addressList[item].IsPrimaryAddress =
          item == dataIndex ? 'Y' : 'N';
      }
      return {
        list: addressList,
      };
    });
  },

  editAddress(event) {
    if (this.props.partyAuth === 'true') {
      this.refs.basicAddress_ref.editAddress(
        event.getParameter('data')
      );
    }
  },

  getEnabled() {
    if (this.props.partyVo.PartyId) {
      return true;
    } else {
      return false;
    }
  },

  saveAddress(tmpaddressVo) {
    const addressVo = tmpaddressVo;
    this.setState(prevState => {
      const addressList = prevState.list;

      if (addressVo.isAdd) {
        addressVo.isAdd = false;
        addressVo.AddressStatus = '1';
        if (addressList.length === 0) {
          addressVo.IsPrimaryAddress = 'Y';
          addressVo.selected = 'Y';
        }
        addressList.push(addressVo);
      } else {
        const { dataIndex } = addressVo;
        addressList[dataIndex] = addressVo;
      }
      return {
        list: addressList,
      };
    });
  },
  changeActive(event) {
    this.setState(prevState => {
      const dataIndex = event.getParameter('dataIndex');
      const addressList = prevState.list;
      const addressVo = addressList[dataIndex];

      if (addressVo.AddressStatus == '1') {
        addressVo.AddressStatus = '2';
        addressVo.selected = 'N';
      } else {
        addressVo.AddressStatus = '1';
      }

      addressList[dataIndex] = addressVo;
      return {
        list: addressList,
      };
    });
  },

  selectAddress(event) {
    const dataIndex = event.getParameter('dataIndex');

    this.setState(prevState => {
      const addressList = prevState.list;
      for (const item in addressList) {
        addressList[item].selected = dataIndex == item ? 'Y' : 'N';
      }
      return {
        list: addressList,
      };
    });
  },
  addButton() {
    if (this.props.partyAuth === 'true') {
      this.refs.basicAddress_ref.addAddress();
    }
  },
  buildTableFunction() {
    return (
      <UILink
        icon="fa fa-plus"
        size="lg"
        iconClass="add_iconbtn"
        onClick={this.addButton}
        enabled={this.getEnabled}
      />
    );
  },
  render() {
    const self = this;
    const _index = self.props.index;
    const enabledVal = () => {
      const hasAuth = self.props.partyAuth === 'true';
      let pm = false;
      if (self.props.index === 'PartyMaintenance') {
        pm = true;
      }
      return hasAuth && pm;
    };
    return (
      <div>
        <UIDataTable
          id={`ClaimPartyAddressId${self.props.index}`}
          indexable="false"
          value={self.state.list}
          pageable="false"
          styleClass="default"
          headerTitle={i18n.ClaimParty.AddressInfo}
          functions={this.buildTableFunction()}
        >
          {this.getUIColumns()}
          <UIColumn
            headerTitle={i18n.ClaimParty.AddressType}
            render={data => {
              return (
                <UISelect
                  id={`addressVoAddressTypeShowId${_index}`}
                  value={data.AddressType}
                  codeTable={code.addressType}
                  io="out"
                />
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.ClaimParty.AddressDetail}
            render={data => {
              return (
                <div>
                  <UISelect
                    value={data.Country}
                    io="out"
                    codeTable={CodeTableAction.loadDataList(
                      CodeTableCstUI.ClaimPartyCountry,
                      null
                    )}
                    style={{ padding: '10px' }}
                  />
                  <UIText
                    value={data.AddressLine1}
                    io="out"
                    style={{ padding: '10px' }}
                  />
                  <UIText
                    value={data.PostCode}
                    io="out"
                    style={{ padding: '10px' }}
                  />
                </div>
              );
            }}
          />
          <UIColumn
            render={data => {
              return (
                <UISmartPanelGrid>
                  <UIRadio
                    id={`addressRadio${_index}${data.dataIndex}`}
                    name="isPrimary"
                    codeTable={code.isPrimary}
                    value={data.IsPrimaryAddress}
                    onChange={self.changePrimary}
                    enabled={enabledVal}
                  >
                    <UIParam
                      name="dataIndex"
                      value={data.dataIndex}
                    />
                  </UIRadio>
                  <UICheckbox
                    id={`addressCheckbox${_index}${data.dataIndex}`}
                    codeTable={code.isActive}
                    value={`${data.AddressStatus}`}
                    onChange={self.changeActive}
                    enabled={enabledVal}
                  >
                    <UIParam
                      name="dataIndex"
                      value={data.dataIndex}
                    />
                  </UICheckbox>
                </UISmartPanelGrid>
              );
            }}
          />
          {this.getLinkColumn()}
        </UIDataTable>
        {this.getDetailPage()}
      </div>
    );
  },

  getUIColumns() {
    let result = <div />;
    if (this.props.showRadio === 'true') {
      result = (
        <UIColumn
          render={data => {
            if (data.AddressStatus == '1') {
              return (
                <UIRadio
                  codeTable={code.test}
                  value={data.selected}
                  onChange={this.selectAddress}
                >
                  <UIParam name="dataIndex" value={data.dataIndex} />
                </UIRadio>
              );
            }
            return null;
          }}
        />
      );
    }
    return result;
  },

  getLinkColumn() {
    let result = <div />;
    if (this.props.partyAuth === 'true') {
      result = (
        <UIColumn
          headerTitle={i18n.ClaimCommon.Action}
          render={data => {
            return (
              <UILink
                tooltip={i18n.ClaimParty.EditLink}
                onClick={this.editAddress}
                icon="fa fa-pencil"
              >
                <UIParam name="data" value={data} />
                <UIParam name="index" value={this.props.index} />
              </UILink>
            );
          }}
        />
      );
    }
    return result;
  },
  getDetailPage() {
    let result = <div />;
    if (this.props.partyAuth === 'true') {
      result = (
        <PartyAddressInfo
          addressVo={this.state.addressVo}
          addAddress={this.state.addAddress}
          ref="basicAddress_ref"
          parentComponent={this}
        />
      );
    }
    return result;
  },
});
export default PartyAddress;
