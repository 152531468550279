import {
  UIDialog,
  UIDataTable,
  UIButton,
  UIColumn,
  UISmartPanelGrid,
  UIBox,
  UIText,
  UIDateTimePicker,
  UISelect,
} from 'RainbowUI';
import PropTypes from 'prop-types';
import AuditAction from '../action/AuditAction';
import CodeTableAction from '../action/CodeTableAction';
import CodeTableCstUI from '../constant/CodeTableCstUI';

const ModificationHistoryDialog = React.createClass({
  propTypes: {
    domain: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  },

  getDefaultProps() {
    return { domain: 'claim_subclaim' };
  },

  getInitialState() {
    return { data: {} };
  },

  showDialog(data) {
    this.setState({ data });
    UIDialog.show(`ModificationHistory_Dialog_${this.props.domain}`);
  },

  getParentComponent() {
    return this;
  },

  getHistoryList(pageIndex, pageSize) {
    return AuditAction.getAuditDataList(
      this.state.data.auditEventType,
      this.state.data.objectId,
      pageIndex,
      pageSize,
      this.state.data.caseId
    );
  },

  render() {
    const domain = this.props.domain;
    return (
      <UIDialog
        id={`ModificationHistory_Dialog_${domain}`}
        width="95%"
        title={i18n.ClaimCommon.ModificationHistory}
      >
        <UIDataTable
          id={`ModificationHistory_Dialog_${domain}`}
          indexable="false"
          pageable="true"
          styleClass="default"
          provider={this.getHistoryList}
          headerTitle={i18n.ClaimCommon.ModificationHistory}
          isHeading="true"
        >
          <UIColumn
            headerTitle={i18n.ClaimCommon.CreateDate}
            value="AuditTime"
          >
            <UIDateTimePicker
              placeHolder="MM/DD/YYYY HH:mm:ss"
              format="MM/DD/YYYY HH:mm:ss"
              io="out"
            />
          </UIColumn>
          <UIColumn
            headerTitle={i18n.ClaimCommon.CreateUser}
            value="OperatorId"
          >
            <UISelect
              codeTable={CodeTableAction.loadDataList(
                CodeTableCstUI.ClaimUser,
                null
              )}
              io="out"
            />
          </UIColumn>

          <UIColumn
            headerTitle={i18n.ClaimCommon.Detail}
            render={data => {
              const coinList = data.ChangeFieldList.map(val_ => {
                return (
                  <div>
                    <UIText io="out" value={val_} />
                  </div>
                );
              });
              return (
                <div>
                  {coinList}
                  <UIText io="out" />
                </div>
              );
            }}
          />
        </UIDataTable>

        <UIBox direction="center">
          <UIButton
            value={i18n.ClaimCommon.Cancel}
            onClick={this.cancel}
            buttonType="cancel"
          />
        </UIBox>
      </UIDialog>
    );
  },

  getDetail(changeDataList) {
    const result = changeDataList.map(data => {
      return <UIText value={data} io="out" />;
    });

    return <UISmartPanelGrid column="1">{result}</UISmartPanelGrid>;
  },

  cancel() {
    UIDialog.hide(`ModificationHistory_Dialog_${this.props.domain}`);
  },
});
export default ModificationHistoryDialog;
