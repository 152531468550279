import alt from '../alt';
import ClaimWorkFlowMessageAction from '../action/desktop/ClaimWorkFlowMessageAction';
import CatastropheAction from '../action/CatastropheAction';

class ConditionStore {
  constructor() {
    this.condition = {};
    // this.page={};

    this.bindListeners({
      handleCondition: [
        ClaimWorkFlowMessageAction.INIT_CONDITION,
        CatastropheAction.INIT_CONDITION,
      ],
      // handlePage:ClaimWorkFlowLeftAction.PAGE_CHANGE
    });
  }

  handleCondition(condition) {
    this.condition = condition;
  }
}

export default alt.createStore(ConditionStore, ConditionStore);
