import { MessageHelper } from 'RainbowUI';
import { ClaimCstUI } from 'ClaimCommonConstant';
import ClaimSettlement from 'ClaimSettlement';
import { PartyConst } from 'EU00PartyCommonUI';
import ClaimCommonExt from 'ClaimCommonExt';
import alt from '../alt';

const ClaimPartyAction = ClaimCommonExt.getClaimPartyAction();
const SettlementLoadAction = ClaimSettlement.getSettlementLoadAction();

class SettlementStore {
  constructor() {
    this.SettlementData = {
      SettlementInfo: {
        SettlementPayee: [],
      },
      OtherInfo: {
        ReserveTree: [],
        ReserveStructure: [],
        ReserveCodeTable: [],
        PaymentTypeCodeTable: [],
      },
      TaskInstanceId: null,
      PolicyNo: null,
    };
    this.CheckCoInsurance = {};

    this.bindListeners({
      handleLoadSettlementTask:
        SettlementLoadAction.LOAD_SETTLEMENT_TASK,
      handleSaveSettlement: SettlementLoadAction.SAVE_SETTLEMENT,
      handleCheckCoInsurance: SettlementLoadAction.CHECK_CO_INSURANCE,
      handleChooseFullOrPortion:
        SettlementLoadAction.CHOOSE_FULL_OR_PORTION,
      loadPartyLeader: SettlementLoadAction.LOAD_PARTY_LEADER,

      handleAddPayee: SettlementLoadAction.ADD_PAYEE,
      handleChangePayee: SettlementLoadAction.CALLBACK_PAYEE,
      handleAddSettlementItem:
        SettlementLoadAction.ADD_SETTLEMENT_ITEM,
      handleRemovePayee: SettlementLoadAction.REMOVE_PAYEE,
      handleRemoveSettlementItem:
        SettlementLoadAction.REMOVE_SETTLEMENT_ITEM,

      handleChooseReserve: SettlementLoadAction.CHOOSE_RESERVE,

      handleChangeCurrency: SettlementLoadAction.CHANGE_CURRENCY,
      handleChangeField: SettlementLoadAction.CHANGE_FIELD,
    });
  }

  handleLoadSettlementTask(settlementData) {
    this.SettlementData.SettlementInfo =
      settlementData.SettlementInfo;
    this.SettlementData.OtherInfo.ReserveTree =
      settlementData.ReserveTree;
    this.SettlementData.OtherInfo.ReserveStructure =
      settlementData.ReserveStructure;
    this.SettlementData.OtherInfo.ReserveCodeTable =
      settlementData.ReserveCodeTable;
    this.SettlementData.OtherInfo.PaymentTypeCodeTable =
      settlementData.PaymentTypeCodeTable;
    this.SettlementData.TaskInstanceId =
      settlementData.TaskInstanceId;
  }

  handleCheckCoInsurance(data) {
    this.SettlementData.PolicyNo = data.policyNo;
    this.CheckCoInsurance = data;
  }

  loadPartyLeader(data) {
    const coInsuranceInfo = this.CheckCoInsurance;
    for (
      let i = 0;
      i < coInsuranceInfo.PolicyCoinList.length;
      i += 1
    ) {
      const coinInfoTemp = coInsuranceInfo.PolicyCoinList[i];
      if (coinInfoTemp.Leader == ClaimCstUI.BooleanConstant.TRUE) {
        coInsuranceInfo.PolicyCoinList[i].Account = data.account;
        coInsuranceInfo.PolicyCoinList[i].Address = data.address;
        coInsuranceInfo.PolicyCoinList[i].Contact = data.contact;
        coInsuranceInfo.PolicyCoinList[i].CoinCompanyName =
          data.PartyName;
        break;
      }
    }
    this.CheckCoInsurance = coInsuranceInfo;
  }

  handleChooseFullOrPortion(data) {
    this.SettlementData.SettlementInfo.CoInsurancePayment = data;
    this.CheckCoInsurance.Selected = data;

    if (data == ClaimCstUI.CoFollower.ToCoLeader) {
      const cpList = this.CheckCoInsurance.PolicyCoinList;
      let leader = {};
      for (let i = 0; i < cpList.length; i += 1) {
        const cpTemp = cpList[i];
        if (cpTemp.Leader == ClaimCstUI.BooleanConstant.TRUE) {
          leader = cpTemp;
          break;
        }
      }
      const temp = this.SettlementData.SettlementInfo
        .SettlementPayee[0];
      temp.Index = 1;
      temp.PayeeName = leader.CoinCompanyName;
      temp.BankCode = ClaimCstUI.CoInsuraceBankCode;
      temp.Address = leader.Address;
      const claimParties = ClaimPartyAction.getByClaimPartyRole(
        this.SettlementData.SettlementInfo.CaseId,
        ClaimCstUI.PARTY_ROLE.CoInsurance
      );
      if (claimParties) {
        temp.PayeeId = claimParties[0]['@pk'];
      }
      temp.PayeeType = PartyConst.PARTY_ROLE_CODE_ORG_INSURANCE;
    }
  }

  handleSaveSettlement(settlementData) {
    this.SettlementData.SettlementInfo =
      settlementData.SettlementInfo;
    this.SettlementData.OtherInfo.ReserveTree =
      settlementData.OtherInfo.ReserveTree;
    this.SettlementData.OtherInfo.ReserveStructure =
      settlementData.OtherInfo.ReserveStructure;
    this.SettlementData.OtherInfo.ReserveCodeTable =
      settlementData.OtherInfo.ReserveCodeTable;
    this.SettlementData.OtherInfo.PaymentTypeCodeTable =
      settlementData.OtherInfo.PaymentTypeCodeTable;
    this.SettlementData.OtherInfo.SettlementContext =
      settlementData.OtherInfo.SettlementContext;
  }

  handleAddPayee(payeeToAdd) {
    const lastIndex = _.isEmpty(
      this.SettlementData.SettlementInfo.SettlementPayee
    )
      ? -1
      : this.SettlementData.SettlementInfo.SettlementPayee[
          this.SettlementData.SettlementInfo.SettlementPayee.length -
            1
        ].Index;
    payeeToAdd.setPayeeIndex(lastIndex + 1);
    const payee = payeeToAdd.get();
    if (
      this.CheckCoInsurance.Selected ==
      ClaimCstUI.CoFollower.ToCoLeader
    ) {
      const cpList = this.CheckCoInsurance.PolicyCoinList;
      let leader = {};
      for (let i = 0; i < cpList.length; i += 1) {
        const cpTemp = cpList[i];
        if (cpTemp.Leader == ClaimCstUI.BooleanConstant.TRUE) {
          leader = cpTemp;
          break;
        }
      }
      payee.PayeeName = leader.CoinCompanyName;
      payee.BankCode = ClaimCstUI.CoInsuraceBankCode;
      payee.Address = leader.Address.AddressLine1;
      payee.AccountNameAndNo = `${leader.Account.AccountHolderName} ${leader.Account.AccountNo}`;
    }
    this.SettlementData.SettlementInfo.SettlementPayee.push(payee);
  }

  handleChangePayee(payeeInfo) {
    const SettlementStoreProcess = ClaimSettlement.getSettlementStoreProcess();
    this.SettlementData = SettlementStoreProcess.updatePayee(
      this.SettlementData,
      payeeInfo
    );
  }

  handleRemovePayee(removeIndex) {
    const settlementPayee = this.SettlementData.SettlementInfo
      .SettlementPayee;
    if (removeIndex == 0 && settlementPayee.length < 2) {
      MessageHelper.error('', i18n.ClaimSettlement.DeletePayeeError);
      return;
    }
    settlementPayee.splice(removeIndex, 1);
    for (let i = 0; i < settlementPayee.length; i += 1) {
      const payee = settlementPayee[i];
      payee.Index = i;
      for (let j = 0; j < payee.SettlementItem.length; j += 1) {
        payee.SettlementItem[j].PayeeIndex = i;
      }
    }
  }

  handleAddSettlementItem(itemToAdd) {
    const itemList = this.SettlementData.SettlementInfo
      .SettlementPayee[itemToAdd.get().PayeeIndex].SettlementItem;
    const lastIndex = _.isEmpty(itemList)
      ? -1
      : itemList[itemList.length - 1].Index;
    itemToAdd.setItemIndex(lastIndex + 1);
    itemList.push(itemToAdd.get());
  }

  handleRemoveSettlementItem(settlementItemLevel) {
    const settlementItem = this.SettlementData.SettlementInfo
      .SettlementPayee[settlementItemLevel.PayeeIndex].SettlementItem;
    settlementItem.splice(settlementItemLevel.RemoveIndex, 1);
    for (let i = 0; i < settlementItem.length; i += 1) {
      settlementItem[i].Index = i;
    }
  }

  handleChooseReserve(TempsettlementItemLevel) {
    const settlementItemLevel = TempsettlementItemLevel;
    if (
      !this.SettlementData.SettlementInfo.SettlementPayee[
        settlementItemLevel.PayeeIndex
      ]
    ) {
      this.SettlementData.SettlementInfo.SettlementPayee[0].Index = 0;
      this.SettlementData.SettlementInfo.SettlementPayee[0].SettlementItem[0].PayeeIndex = 0;
      settlementItemLevel.PayeeIndex = 0;
    }
    const settlementPayeeItems = this.SettlementData.SettlementInfo
      .SettlementPayee[settlementItemLevel.PayeeIndex].SettlementItem;
    const reserveStructure = this.SettlementData.OtherInfo
      .ReserveStructure;
    const settlementItem =
      settlementPayeeItems[settlementItemLevel.Index];
    for (let i = 0; i < settlementPayeeItems.length; i += 1) {
      const temp = settlementPayeeItems[i];
      if (temp.ReserveId == Number(settlementItemLevel.ReserveId)) {
        MessageHelper.error(
          '',
          i18n.ClaimSettlement.ChooseReserveError
        );
        return;
      }
    }
    for (let i = 0; i < reserveStructure.length; i += 1) {
      if (
        reserveStructure[i].ReserveId == settlementItemLevel.ReserveId
      ) {
        settlementItem.ReserveType = reserveStructure[i].ReserveType;
        settlementItem.ReserveId = reserveStructure[i].ReserveId;
        settlementItem.ReserveCurrency =
          reserveStructure[i].CurrencyCode;
        settlementItem.ItemId = reserveStructure[i].ItemId;
        settlementItem.OutstandingAmount =
          reserveStructure[i].OutstandingAmount;
        settlementItem.SeqNo = reserveStructure[i].SeqNo;
        settlementItem.CoverageName =
          reserveStructure[i].CoverageName;
        settlementItem.ReserveSign = reserveStructure[i].ReserveSign;
        settlementItem.OurShareAmount =
          reserveStructure[i].OurShareAmount;
        settlementItem.SubclaimType =
          reserveStructure[i].SubclaimType;
        settlementItem.CoverageTypeCode =
          reserveStructure[i].CoverageTypeCode;

        settlementItem.PayFinal = null;
        settlementItem.SettleAmount = null;
      }
    }
  }

  handleChangeSettlementItem(datas) {
    const data = datas;
    const settPayees = this.SettlementData.SettlementInfo
      .SettlementPayee;
    for (let i = 0; i < settPayees.length; i += 1) {
      const temp = settPayees[i];
      for (let j = 0; j < temp.SettlementItem.length; j += 1) {
        const tempItem = temp.SettlementItem[j];
        if (
          tempItem.PayeeIndex !== data.PayeeIndex &&
          tempItem.ReserveId == data.ReserveId &&
          Number(tempItem.PayFinal) === 4
        ) {
          if (tempItem.PayeeIndex < data.PayeeIndex) {
            data.PayFinal = null;
            MessageHelper.error(
              '',
              i18n.ClaimSettlement.ChoosePartialFinalFail
            );
            return;
          }
        }
        if (
          Number(data.PayFinal) === 4 &&
          tempItem.PayeeIndex > data.PayeeIndex &&
          tempItem.ReserveId == data.ReserveId
        ) {
          data.PayFinal = null;
          MessageHelper.error(
            '',
            i18n.ClaimSettlement.ChoosePartialFinalFail
          );
          return;
        }
      }
    }
  }

  handleChangeCurrency(currency) {
    const SettlementStoreProcess = ClaimSettlement.getSettlementStoreProcess();
    this.SettlementData = SettlementStoreProcess.handleChangeCurrency(
      this.SettlementData,
      currency
    );
  }

  handleChangeField(changeData) {
    const SettlementStoreProcess = ClaimSettlement.getSettlementStoreProcess();
    this.SettlementData = SettlementStoreProcess.handleChangeField(
      this.SettlementData,
      changeData
    );
  }
}
export default alt.createStore(SettlementStore, SettlementStore);
