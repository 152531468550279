const EnvUtil = {
  initEnv() {
    //var projectConfig = sessionStorage.getItem("project_config");
    //var projectConfigJson = JSON.parse(projectConfig);
    //console.log("=============projectConfigJson 11:", projectConfigJson);
    // let config = './project_config.json';
    // if (location.hostname.indexOf('ebaogi-gidev') > -1) {
    //   config = './project_config.dev.json';
    // } else if (location.hostname.indexOf('ebaogi-gitst') > -1) {
    //   config = './project_config.tst.json';
    // } else if (location.hostname.indexOf('210.13.77.89') > -1) {
    //   config = './project_config.shanghai-demo.json';
    // }
    return JSON.parse(localStorage.getItem("project_config"));
  },
};

module.exports = EnvUtil.initEnv();
