import { UIDataTable, UIColumn, UISelect } from 'RainbowUI';
import React from 'react';
import BindToMixin from 'react-binding';
import FraudIndicatorAction from '../../action/FraudIndicatorAction';
import ClaimFraudIndicatorStore from '../../store/ClaimFraudIndicatorStore';

const CodeTableAction = require('../../action/CodeTableAction');
const CodeTableCstUI = require('../../constant/CodeTableCstUI');

const FraudInfos = React.createClass({
  mixins: [BindToMixin],

  getDefaultProps() {
    return { io: 'out', forModify: 'false' };
  },

  getInitialState() {
    return { fraudVOList: {}, selectIndex: [] };
  },

  componentDidMount() {
    const objectId = sessionStorage.getItem('fraudObjectId');
    let FraudVOList = {};
    const selected = [];
    if (objectId && objectId !== 'undefined') {
      FraudVOList = FraudIndicatorAction.initFraud(objectId);
      let j = 0;
      for (let i = 0; i < FraudVOList.length; i += 1) {
        const vo = FraudVOList[i];
        if (vo.Checked && vo.Checked === 'true') {
          selected[j] = i;
          j += 1;
        }
      }
    }
    this.setState({
      fraudVOList: FraudVOList,
      selectIndex: selected,
    });
    ClaimFraudIndicatorStore.listen(this.onStoreStateChange);
  },

  componentWillUnmount() {
    ClaimFraudIndicatorStore.unlisten(this.onStoreStateChange);
  },

  onStoreStateChange(storeState) {
    const result = storeState.taskSearchOptions;
    this.setState({ fraudVOList: result.fraudVOList || [] });
  },

  render() {
    const _self = this;
    const { selectIndex } = this.state;
    return (
      <UIDataTable
        id="authorityTreeTable"
        selectState={selectIndex}
        pageable="false"
        indexable="false"
        value={_self.state.fraudVOList}
      >
        <UIColumn selectionMode="multiple" enabled="ture" />

        <UIColumn
          id="questiong code"
          headerTitle={i18n.ClaimCommon.FraudIndicatorAndDesc}
          value="QuestionCode"
          render={data => {
            return (
              <UISelect
                value={data.QuestionCode}
                codeTable={CodeTableAction.loadDataList(
                  CodeTableCstUI.ClaimFraudQuestion,
                  null
                )}
                io="out"
              />
            );
          }}
        />
      </UIDataTable>
    );
  },
});
export default FraudInfos;
