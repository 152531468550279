import {
  UIDataTable,
  UIColumn,
  UISelect,
  UIDateTimePicker,
} from 'RainbowUI';
import BindToMixin from 'react-binding';
import PolicyStore from '../../store/PolicyStore';
import PolicyForm from '../../mock/policy/PolicyForm';


const PolicyAction = require('../../action/PolicyAction');
const CodeTableAction = require('../../action/CodeTableAction');
const CodeTableCstUI = require('../../constant/CodeTableCstUI');
const code = require('../../mock/CodeTable');

const PolicySearchResult = React.createClass({
  mixins: [BindToMixin],

  getInitialState() {
    return { policySearchOptions: { isInit: true } };
  },

  componentWillMount() {
    PolicyStore.listen(this.onStoreStateChange);
  },

  componentWillUnmount() {
    PolicyStore.unlisten(this.onStoreStateChange);
  },

  onStoreStateChange(storeState) {
    this.setState({
      policySearchOptions: storeState.policySearchOptions,
    });
  },
  clearResult() {
    this.setState({ policySearchOptions: { isInit: true } });
  },
  getPolicyList(pageIndex, pageSize) {
    if (env.isDummyPolicy) {
      return { count: PolicyForm.PolicyList.length, result: PolicyForm.PolicyList }
    }

    if (this.state.policySearchOptions.isInit) {
      return { count: 0, result: [] };
    }

    const list = PolicyAction.getPolicyList(
      pageIndex,
      pageSize,
      this.state.policySearchOptions
    );

    if (list.result && list.result.length) {
      _.each(list.result, tmppolicy => {
        const policy = tmppolicy;
        if (policy.PolicyNo) {
          policy.PolicyNo = policy.PolicyNo.replace(/#/g, ' ');
        }
      });
    }
    return list;
  },

  getSelectedPolicy() {
    const selectRecord = UIDataTable.getSelectedRecord(
      'PolicySearchResult_UIDataTable'
    );
    return selectRecord[0] ? selectRecord[0].PolicyNo : '';
  },
  getParentComponent() {
    return this;
  },
  getPolicyNoById(policyId) {
    const policy = PolicyAction.getPolicyById(policyId);
    return policy.PolicyNo;
  },

  render() {
    return (
      <UIDataTable
        id="PolicySearchResult_UIDataTable"
        provider={this.getPolicyList}
        onRowSelect={this.onRowSelect}
        indexable="false"
        pageable="true"
        isHeading="true"
        headerTitle={i18n.ClaimCommon.PolicyList}
        styleClass="default"
        selectionMode="single"
      >
        <UIColumn
          headerTitle={i18n.ClaimCommon.PolicyNumber}
          value="PolicyNo"
        />
        <UIColumn
          headerTitle={i18n.ClaimCommon.ProductName}
          value="ProductCode"
        >
          <UISelect
            io="out"
            value="ProductCode"
            codeTable={CodeTableAction.loadDataList(
              CodeTableCstUI.ProductTypeCode,
              null
            )}
          />
        </UIColumn>
        <UIColumn
          headerTitle={i18n.ClaimCommon.PolicyHolderName}
          value="PolicyHolder"
        />
        <UIColumn
          headerTitle={i18n.ClaimCommon.PolicyEffectiveDate}
          value="EffectiveDate"
        >
          <UIDateTimePicker
            io="out"
            placeHolder="MM/DD/YYYY HH:mm:ss"
            format="MM/DD/YYYY HH:mm:ss"
          />
        </UIColumn>
        <UIColumn
          headerTitle={i18n.ClaimCommon.PolicyExpiryDate}
          value="ExpiryDate"
        >
          <UIDateTimePicker
            io="out"
            placeHolder="MM/DD/YYYY HH:mm:ss"
            format="MM/DD/YYYY HH:mm:ss"
          />
        </UIColumn>
        <UIColumn
          headerTitle={i18n.ClaimCommon.Status}
          value="PolicyStatus"
        >
          <UISelect
            io="out"
            value="PolicyStatus"
            codeTable={code.policyStatusTable}
          />
        </UIColumn>
      </UIDataTable>
    );
  },
});
export default PolicySearchResult;
