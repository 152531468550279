const ClaimNoticeExt = {
  getAccidentInfo() {
    return require('./module/notice/common/component/AccidentInfo');
  },
  getDamageInfo() {
    return require('./module/notice/common/component/DamageInfo');
  },
  getNoticeStore() {
    return require('./module/notice/common/store/NoticeStore');
  },
  getNoticePoolStore() {
    return require('./module/notice/common/store/NoticePoolStore');
  },
  getNoticeOfLossAction() {
    return require('./module/notice/common/action/NoticeOfLossAction');
  },
  getNoticePoolAction() {
    return require('./module/notice/common/action/NoticePoolAction');
  },
  getNoticePoolSearch() {
    return require('./module/notice/common/component/NoticePoolSearch');
  },
  getNoticePoolResult() {
    return require('./module/notice/common/component/NoticePoolResult');
  },
  getDamageItemDetail() {
    return require('./module/notice/common/component/DamageItemDetail');
  },
};

export default ClaimNoticeExt;
