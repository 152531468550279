export default {
  OpenSearchLink: '該当項目ありません',
  IndOrOrgRadio: '個人/組織',
  PartyName: '関係者名',
  IDNumber: 'ID番号',
  IDNumberForSearch: 'ID番号',
  CreateAs: '…として生成',
  AddToClaimParty: 'クレーム関係者として追加',
  NotExistInCommonParty: '該当項目ありません',
  FirstName: '氏名（名）',
  LastName: '氏名（姓）',
  PartyRole: '関係者ロール',
  IDType: 'IDタイプ',
  AddressInfo: '住所情報',
  AddressType: '住所種類',
  AddressDetail: '住所詳細',
  AccountInfo: '口座情報',
  AccountType: '口座種類',
  AccountDetail: '口座詳細',
  BankName: '銀行名',
  AccountHolderName: '口座名',
  BankAccountNo: '口座番号',
  ContactInfo: '連絡情報',
  ContactNumber: '連絡電話',
  ContactEmail: '連絡Eメール',
  SaveButton: 'OK',
  CancelButton: 'キャンセル',
  SelectAsPayee: '支払者',
  EditLink: '編集',

  // party message
  RelateAddressError: '请选择相关地址',
  RelateAccountError: '请选择相关账户',
  LoadAuthorityError: '加载权限错误',
};
