import { MessageHelper, Caller } from 'RainbowUI';
import PartyConst from '../const/PartyConst';
import CodeTableService from '../service/CodeTableService';

const service = require('../service/PartyService');
const urlConfig = require('../../../UrlConfig');

class PartyAction {
  loadCustomerByPartyId(partyId) {
    const result = service.loadCustomerByPartyId(partyId);
    if (result.status === 'success') {
      return result.data;
    }
    MessageHelper.error(
      result.data,
      '',
      MessageHelper.POSITION_TOP_RIGHT
    );
  }

  loadUserNameByUserId(UserId) {
    const result = service.loadUserByUserId(UserId);
    if (result.status === 'success') {
      if (result.data) {
        return result.data.Username;
      }
      return null;
    }
    MessageHelper.error(
      result.data,
      'No User Data',
      MessageHelper.POSITION_TOP_RIGHT
    );
  }

  deleteDefinedIndexForCustomerObj(party) {
    const { PartyRoleList } = party;
    const { AddressVoList } = party;
    const { PartyContactList } = party;
    const { PartyAccountList } = party;
    const { PartyRelationShipList } = party;
    const { PartyHistoryList } = party;
    const { PartyAdditionalList } = party;
    const { PartyShareHolderList } = party;
    const { PartyParticipationList } = party;
    _.forEach(PartyRoleList, tempPartyRole => {
      const PartyRole = tempPartyRole;
      delete PartyRole.DefinedIndex;
      delete PartyRole.dataIndex;
    });
    _.forEach(AddressVoList, temppartyAddress => {
      const partyAddress = temppartyAddress;
      delete partyAddress.DefinedIndex;
      delete partyAddress.dataIndex;
    });
    _.forEach(PartyAccountList, tempPartyAccount => {
      const PartyAccount = tempPartyAccount;
      delete PartyAccount.DefinedIndex;
      delete PartyAccount.dataIndex;
    });
    _.forEach(PartyRelationShipList, tempPartyRelationShip => {
      const PartyRelationShip = tempPartyRelationShip;
      delete PartyRelationShip.DefinedIndex;
      delete PartyRelationShip.dataIndex;
    });
    _.forEach(PartyContactList, tempPartyContact => {
      const PartyContact = tempPartyContact;
      delete PartyContact.DefinedIndex;
      delete PartyContact.dataIndex;
    });
    _.forEach(PartyAdditionalList, tempPartyAdditional => {
      const PartyAdditional = tempPartyAdditional;
      delete PartyAdditional.DefinedIndex;
      delete PartyAdditional.dataIndex;
    });
    _.forEach(PartyHistoryList, tempPartyHistory => {
      const PartyHistory = tempPartyHistory;
      delete PartyHistory.dataIndex;
    });
    _.forEach(PartyShareHolderList, tempPartyShareholder => {
      const PartyShareholder = tempPartyShareholder;
      delete PartyShareholder.DefinedIndex;
      delete PartyShareholder.dataIndex;
    });
    _.forEach(PartyParticipationList, tempPartyParticipation => {
      const PartyParticipation = tempPartyParticipation;
      delete PartyParticipation.DefinedIndex;
      delete PartyParticipation.dataIndex;
    });
  }

  updateParty(party) {
    const partyAccountList = party.PartyAccountList;
    const AddressVoList = party.AddressVoList;
    const partyContactList = party.PartyContactList;
    let checkAccountFlag = PartyConst.SUCCESS;
    let checkAddressFlag = PartyConst.SUCCESS;
    let checkContactFlag = PartyConst.SUCCESS;
    let existAccountFlag = PartyConst.FAIL;
    let existAddressFlag = PartyConst.FAIL;
    let existContactFlag = PartyConst.FAIL;
    if (partyAccountList) {
      if (partyAccountList.length > 1) {
        _.each(partyAccountList, obj => {
          if (obj.IsPrimaryAccount == PartyConst.IS_PRIMARY) {
            existAccountFlag = PartyConst.SUCCESS;
            return obj;
          }
        });
      }
    }
    if (partyAccountList && existAccountFlag == PartyConst.FAIL) {
      if (partyAccountList.length > 1) {
        _.each(partyAccountList, obj => {
          if (!(obj.IsPrimaryAccount == PartyConst.IS_PRIMARY)) {
            MessageHelper.warning(
              PartyConst.CHECK_PRIMARY_ACCOUNT,
              '',
              MessageHelper.POSITION_TOP_RIGHT
            );
            checkAccountFlag = PartyConst.FAIL;
            return obj;
          }
        });
      }
    }
    if (AddressVoList) {
      if (AddressVoList.length > 1) {
        _.each(AddressVoList, obj => {
          if (obj.IsPrimaryAddress == PartyConst.IS_PRIMARY) {
            existAddressFlag = PartyConst.SUCCESS;
            return obj;
          }
        });
      }
    }
    if (AddressVoList && existAddressFlag == PartyConst.FAIL) {
      if (AddressVoList.length > 1) {
        _.each(AddressVoList, obj => {
          if (!(obj.IsPrimaryAddress == PartyConst.IS_PRIMARY)) {
            MessageHelper.warning(
              PartyConst.CHECK_PRIMARY_ADDRESS,
              '',
              MessageHelper.POSITION_TOP_RIGHT
            );
            checkAddressFlag = PartyConst.FAIL;
            return obj;
          }
        });
      }
    }
    if (party.PartyType == PartyConst.PARTY_TYPE_ORG) {
      if (partyContactList) {
        if (partyContactList.length >= 1) {
          _.each(partyContactList, obj => {
            if (obj.IsPrimaryContact == PartyConst.IS_PRIMARY) {
              existContactFlag = PartyConst.SUCCESS;
              return obj;
            }
          });
        }
      }
    }
    if (party.PartyType == PartyConst.PARTY_TYPE_ORG) {
      if (partyContactList && existContactFlag == PartyConst.FAIL) {
        if (partyContactList.length >= 1) {
          _.each(partyContactList, obj => {
            if (!(obj.IsPrimaryContact == PartyConst.IS_PRIMARY)) {
              MessageHelper.warning(
                PartyConst.CHECK_PRIMARY_CONTACT,
                '',
                MessageHelper.POSITION_TOP_RIGHT
              );
              checkContactFlag = PartyConst.FAIL;
              return obj;
            }
          });
        }
      }
    }
    if (PartyConst.FAIL == checkAccountFlag) {
      return checkAccountFlag;
    }
    if (PartyConst.FAIL == checkAddressFlag) {
      return checkAddressFlag;
    }
    if (PartyConst.FAIL == checkContactFlag) {
      return checkContactFlag;
    }

    const result = this.updatePartyCommonForSc(party);

    return result;
  }

  updatePartyForAccount(party) {
    const partyAccountList = party.PartyAccountList;
    let existAccountFlag = PartyConst.FAIL;
    const activeAcountList = [];
    if (partyAccountList) {
      if (partyAccountList.length > 1) {
        _.each(partyAccountList, obj => {
          if (
            obj.AccountStatus ==
            PartyConst.PARTY_ACCOUNT_STATUS_ACTIVE
          ) {
            activeAcountList.push(obj);
            return obj;
          }
        });
      }
    }
    if (activeAcountList && activeAcountList.length >= 1) {
      _.each(activeAcountList, obj => {
        if (obj.IsPrimaryAccount == PartyConst.IS_PRIMARY) {
          existAccountFlag = PartyConst.SUCCESS;
          return obj;
        }
      });
      if (existAccountFlag == PartyConst.FAIL) {
        MessageHelper.warning(
          PartyConst.CHECK_PRIMARY_ACCOUNT,
          '',
          MessageHelper.POSITION_TOP_RIGHT
        );
      }
    } else {
      existAccountFlag = PartyConst.SUCCESS;
    }

    if (PartyConst.FAIL == existAccountFlag) {
      return existAccountFlag;
    }
    const result = this.updatePartyCommon(party);
    return result;
  }

  updatePartyForAddress(party) {
    const AddressVoList = party.AddressVoList;
    let checkAddressFlag = PartyConst.SUCCESS;
    let existAddressFlag = PartyConst.FAIL;
    if (AddressVoList) {
      if (AddressVoList.length > 1) {
        _.each(AddressVoList, obj => {
          if (obj.IsPrimaryAddress == PartyConst.IS_PRIMARY) {
            existAddressFlag = PartyConst.SUCCESS;
            return obj;
          }
        });
      }
    }
    if (AddressVoList && existAddressFlag == PartyConst.FAIL) {
      if (AddressVoList.length > 1) {
        _.each(AddressVoList, obj => {
          if (!(obj.IsPrimaryAddress == PartyConst.IS_PRIMARY)) {
            checkAddressFlag = PartyConst.FAIL;
            return obj;
          }
        });
        if (checkAddressFlag == PartyConst.FAIL) {
          MessageHelper.warning(
            PartyConst.CHECK_PRIMARY_ADDRESS,
            '',
            MessageHelper.POSITION_TOP_RIGHT
          );
        }
      }
    }
    if (PartyConst.FAIL == checkAddressFlag) {
      return checkAddressFlag;
    }
    const result = this.updatePartyCommon(party);
    return result;
  }

  updatePartyForContact(party) {
    const partyContactList = party.PartyContactList;
    let checkContactFlag = PartyConst.SUCCESS;
    let existContactFlag = PartyConst.FAIL;
    if (party.PartyType == PartyConst.PARTY_TYPE_ORG) {
      if (partyContactList) {
        if (partyContactList.length >= 1) {
          _.each(partyContactList, obj => {
            if (obj.IsPrimaryContact == PartyConst.IS_PRIMARY) {
              existContactFlag = PartyConst.SUCCESS;
              return obj;
            }
          });
        }
      }
    }
    if (party.PartyType == PartyConst.PARTY_TYPE_ORG) {
      if (partyContactList && existContactFlag == PartyConst.FAIL) {
        if (partyContactList.length >= 1) {
          _.each(partyContactList, obj => {
            if (!(obj.IsPrimaryContact == PartyConst.IS_PRIMARY)) {
              MessageHelper.warning(
                PartyConst.CHECK_PRIMARY_CONTACT,
                '',
                MessageHelper.POSITION_TOP_RIGHT
              );
              checkContactFlag = PartyConst.FAIL;
              return obj;
            }
          });
        }
      }
    }
    if (PartyConst.FAIL == checkContactFlag) {
      return checkContactFlag;
    }

    const result = this.updatePartyCommon(party);
    return result;
  }

  getRoleInsertTime(partyRoleId) {
    const time = service.getRoleInsertTime(partyRoleId);
    return time.data;
  }

  updatePartyCommon(tempparty) {
    const party = tempparty;
    this.deleteDefinedIndexForCustomerObj(party);
    const partyId = party.PartyId;
    const partyType = party.PartyType;
    if (partyType == PartyConst.PARTY_TYPE_IND) {
      let PartyName;
      let { FirstName } = party;
      const { MiddleName } = party;
      let { LastName } = party;
      if (!FirstName) {
        FirstName = '';
      }
      if (!LastName) {
        LastName = '';
      }
      if (FirstName !== '') {
        if (!_.isEmpty(MiddleName)) {
          PartyName = `${FirstName} ${MiddleName} ${LastName}`;
        } else {
          PartyName = `${FirstName} ${LastName}`;
        }
      } else {
        PartyName = '';
      }
      if (PartyName !== '') {
        party.PartyName = PartyName;
      }
    }
    const resultConfirm = service.updateParty(party, partyId);

    if (resultConfirm.status !== 'success') {
      MessageHelper.error(
        resultConfirm.data,
        '',
        MessageHelper.POSITION_TOP_RIGHT
      );
      return PartyConst.FAIL;
    }
    const result = service.loadPartyByPartyId(partyId);

    if (result.status === 'success') {
      MessageHelper.success(
        i18n.PartyMsg.SuccessMsg.Success_0004,
        '',
        MessageHelper.POSITION_TOP_RIGHT
      );
      return result.data;
    }
    MessageHelper.error(
      result.data,
      '',
      MessageHelper.POSITION_TOP_RIGHT
    );
    return PartyConst.FAIL;
  }

  updatePartyCommonForSc(tempparty) {
    const party = tempparty;
    this.deleteDefinedIndexForCustomerObj(party);
    const partyId = party.PartyId;
    const partyType = party.PartyType;
    if (partyType == PartyConst.PARTY_TYPE_IND) {
      let PartyName;
      let { FirstName } = party;
      const { MiddleName } = party;
      let { LastName } = party;
      if (!FirstName) {
        FirstName = '';
      }
      if (!LastName) {
        LastName = '';
      }
      if (FirstName !== '') {
        if (!_.isEmpty(MiddleName)) {
          PartyName = `${FirstName} ${MiddleName} ${LastName}`;
        } else {
          PartyName = `${FirstName} ${LastName}`;
        }
      } else {
        PartyName = '';
      }
      if (PartyName !== '') {
        party.PartyName = PartyName;
      }
    }
    const resultConfirm = service.updateParty(party, partyId);
    if (resultConfirm.status !== 'success') {
      MessageHelper.error(
        resultConfirm.data,
        '',
        MessageHelper.POSITION_TOP_RIGHT
      );
      return PartyConst.FAIL;
    }
    const result = service.loadPartyByPartyId(partyId);
    if (result.status === 'success') {
      return result.data;
    }
    MessageHelper.error(
      result.data,
      '',
      MessageHelper.POSITION_TOP_RIGHT
    );
    return PartyConst.FAIL;
  }

  checkPartyAdditional(partyAdditional) {
    const result = service.checkPartyAdditional(partyAdditional);
    if (result.status === 'success') {
      return result;
    }
    MessageHelper.error(
      result.data,
      '',
      MessageHelper.POSITION_TOP_RIGHT
    );
    return result;
  }

  searchPartyByConditions(
    conditions,
    orConditions,
    fuzzyConditions,
    PartyType,
    pageindex,
    pagesize
  ) {
    const searchCondition = {
      SortField: 'PartyName',
      SortType: 'asc',
      PageNo: Math.ceil(pageindex / pagesize),
      PageSize: pagesize,
      FuzzyConditions: fuzzyConditions,
      Conditions: conditions,
      OrConditions: orConditions,
    };
    if (PartyType == PartyConst.PARTY_TYPE_IND) {
      searchCondition.Module = PartyConst.PARTY_IND_SEARCH_MODULE;
    }
    if (PartyType == PartyConst.PARTY_TYPE_ORG) {
      searchCondition.Module = PartyConst.PARTY_ORG_SEARCH_MODULE;
    }
    if (PartyType == PartyConst.PARTY_TYPE_VEN) {
      searchCondition.Module = PartyConst.PARTY_VEN_SEARCH_MODULE;
    }

    const result = service.searchParty(searchCondition);
    return result;
  }

  searchPartyNameForBank(roletype) {
    const result = service.findBank(roletype);
    return result.data;
  }

  searchActivePartyNameForBank(roletype) {
    const result = service.findActiveBank(roletype);
    return result.data;
  }

  refreshCustomer() { }

  createParty(tempparty, partyType) {
    const party = tempparty;
    if (partyType == PartyConst.PARTY_TYPE_IND) {
      party['@type'] = PartyConst.PARTY_BUSINESS_TYPE_IND;
      party.BusinessObjectId =
        PartyConst.PARTY_BUSINESS_OBJECT_ID_IND;
    }
    if (partyType == PartyConst.PARTY_TYPE_ORG) {
      party['@type'] = PartyConst.PARTY_BUSINESS_TYPE_ORG;
      party.BusinessObjectId =
        PartyConst.PARTY_BUSINESS_OBJECT_ID_ORG;
    }
    if (partyType == PartyConst.PARTY_TYPE_VEN) {
      party['@type'] = PartyConst.PARTY_BUSINESS_TYPE_VEN;
      party.BusinessObjectId =
        PartyConst.PARTY_BUSINESS_OBJECT_ID_VEN;
    }
    const result = service.createParty(party);
    if (result.status === 'success') {
      MessageHelper.success(
        i18n.PartyMsg.SuccessMsg.Success_0005,
        '',
        MessageHelper.POSITION_TOP_RIGHT
      );
      const partyId = result.data.PartyId;
      window.location.href = `${urlConfig.partyCommonUrl}#/createPartyMaintain/${partyId}`;
      return true;
    }
    MessageHelper.error(
      result.data,
      '',
      MessageHelper.POSITION_TOP_RIGHT
    );
    return false;
  }

  buildAddress(addressInfo) {
    let addressInfoCity;
    let addressInfoArea;
    let addressInfoStreetName;
    if (addressInfo.Country === 'CY') {
      const cityCodeTable = CodeTableService.geRegion(
        addressInfo.Country,
        2
      ).codes;
      const areaCodeTable = CodeTableService.geRegion(
        addressInfo.Country,
        3,
        addressInfo.City
      ).codes;
      const streetNameCodeTable = CodeTableService.geRegion(
        addressInfo.Country,
        5,
        addressInfo.State
      ).codes;
      _.forEach(cityCodeTable, data => {
        if (data.Code == addressInfo.City) {
          addressInfoCity = data.text;
        }
      });
      _.forEach(areaCodeTable, data => {
        if (data.Code == addressInfo.State) {
          addressInfoArea = data.text;
        }
      });
      _.forEach(streetNameCodeTable, data => {
        if (data.Code == addressInfo.AddressLine1) {
          addressInfoStreetName = data.text;
        }
      });
    } else {
      addressInfoCity = addressInfo.City;
      addressInfoArea = addressInfo.State;
      addressInfoStreetName = addressInfo.AddressLine1;
    }
    const streetNo = addressInfo.StreetNo;
    const StreetName = addressInfoStreetName;
    const { BuildingName } = addressInfo;
    const { ApartmentNumber } = addressInfo;
    const Postcode = addressInfo.PostCode;
    const Area = addressInfoArea;
    const City = addressInfoCity;

    let addressStr = '';
    if (streetNo) {
      addressStr += `${streetNo}, `;
    }
    if (StreetName) {
      addressStr += `${StreetName}, `;
    }
    if (BuildingName) {
      addressStr += `${BuildingName}, `;
    }
    if (ApartmentNumber) {
      addressStr += `${ApartmentNumber}, `;
    }
    if (Postcode) {
      addressStr += `${Postcode}, `;
    }
    if (Area) {
      addressStr += `${Area}, `;
    }
    if (City) {
      addressStr += City;
    }
    return addressStr;
  }

  loadPartyByPartyId(partyId) {
    const result = service.loadPartyByPartyId(partyId);
    if (result.status === 'success') {
      return result.data;
    }
    MessageHelper.error(
      i18n.PartyMsg.Error.LoadPartyError,
      '',
      MessageHelper.POSITION_TOP_RIGHT
    );
  }

  getMinorAge() {
    const result = service.getMinorAge();
    return result;
  }

  getCustomerCode(customerCode) {
    if ($.isEmptyObject(customerCode)) {
      const result = service.getCustomerCode();
      if (result.status === 'success') {
        return result.data;
      }
      MessageHelper.error(
        result.data,
        '',
        MessageHelper.POSITION_TOP_RIGHT
      );
    }
  }

  getPartyCodeTable(partyId) {
    let result = {};
    const url = urlConfig.findPtyByPartyId + partyId;
    Caller.call(url, null, {
      method: 'POST',
      async: false,
      block: false,
    }).then(
      data => {
        result = data;
      },
      () => { }
    );
    return result;
  }

  getPartyByCustomerCode(customerCode) {
    let result = {};
    const url = urlConfig.getPartyByCustomerCode + customerCode;
    Caller.call(url, null, {
      method: 'POST',
      async: false,
      block: false,
    }).then(
      data => {
        result = data;
      },
      () => { }
    );
    return result;
  }

  checkIBANNo(IBANNo) {
    let status = 'success';
    const IBANMap = {
      A: 10,
      B: 11,
      C: 12,
      D: 13,
      E: 14,
      F: 15,
      G: 16,
      H: 17,
      I: 18,
      J: 19,
      K: 20,
      L: 21,
      M: 22,
      N: 23,
      O: 24,
      P: 25,
      Q: 26,
      R: 27,
      S: 28,
      T: 29,
      U: 30,
      V: 31,
      W: 32,
      X: 33,
      Y: 34,
      Z: 35,
    };
    let first = IBANNo.substring(0, 1);
    first = IBANMap[first];
    let second = IBANNo.substring(1, 2);
    second = IBANMap[second];
    if (!first || !second) {
      MessageHelper.error(
        i18n.PartyMsg.Error.Error_0100,
        '',
        MessageHelper.POSITION_TOP_RIGHT
      );
      return PartyConst.FAIL;
    }
    let temp = IBANNo.substring(2, 4);
    const conString = `${first}${second}${temp}`;
    temp = IBANNo.substring(4, IBANNo.length);
    let tempString = '';
    for (let i = 0; i < temp.length; i += 1) {
      let constString = temp[i];
      if (constString >= 'A' && constString <= 'Z') {
        constString = IBANMap[constString];
      }
      tempString += constString;
    }
    temp = tempString + conString;
    let result = parseInt(temp.substring(0, 9), 10);
    while (condition !== '') {
      result %= 97;
      temp = temp.substring(9, temp.length);
      const condition = temp;
      temp = `${result}${temp}`;
      if (temp.length > 9) {
        result = parseInt(temp.substring(0, 9), 10);
      } else {
        result = parseInt(temp, 10);
      }
      if (result === 1 && condition === '') {
        status = 'success';
        break;
      } else {
        status = PartyConst.FAIL;
      }
    }
    if (status !== 'success') {
      MessageHelper.error(
        i18n.PartyMsg.ErrorMsg.Error_0008,
        '',
        MessageHelper.POSITION_TOP_RIGHT
      );
    }
    return status;
  }

  getRegionByCountryCodeAndPostCode(countryCode, postCode) {
    const url = `${urlConfig.queryRegionByCountryCodeAndPostCode}?countryCode=${countryCode}&postCode=${postCode}`;
    let results = null;
    Caller.call(url, null, {
      method: 'GET',
      async: false,
      block: true,
    }).then(
      data => {
        results = data;
      },
      err => {
        MessageHelper.error(
          err,
          '',
          MessageHelper.POSITION_TOP_RIGHT
        );
        console.error('========err:', err);
      }
    );
    return results;
  }

  getRegionByCountryCodeAndRegionName(countryCode, regionName) {
    const url = `${urlConfig.queryRegionByCountryCodeAndRegionName}?countryCode=${countryCode}&regionName=${regionName}`;
    let results = null;
    Caller.call(url, null, {
      method: 'GET',
      async: false,
      block: true,
    }).then(
      data => {
        results = data;
      },
      err => {
        MessageHelper.error(
          err,
          '',
          MessageHelper.POSITION_TOP_RIGHT
        );
        console.error('========err:', err);
      }
    );
    return results;
  }

  createIntegrationParty(tempparty, partyType, partyRole) {
    const party = tempparty;
    if (partyType == PartyConst.PARTY_TYPE_IND) {
      party['@type'] = PartyConst.PARTY_BUSINESS_TYPE_IND;
      party.BusinessObjectId =
        PartyConst.PARTY_BUSINESS_OBJECT_ID_IND;
    } else if (partyType == PartyConst.PARTY_TYPE_ORG) {
      party['@type'] = PartyConst.PARTY_BUSINESS_TYPE_ORG;
      party.BusinessObjectId =
        PartyConst.PARTY_BUSINESS_OBJECT_ID_ORG;
    } else if (partyType == PartyConst.PARTY_TYPE_VEN) {
      party['@type'] = PartyConst.PARTY_BUSINESS_TYPE_VEN;
      party.BusinessObjectId =
        PartyConst.PARTY_BUSINESS_OBJECT_ID_VEN;
    }

    let partyRoleInfo = PartyConst.COMMOM_PARTY_ROLE_INFO[partyRole];
    party.PartyRoleList = [
      {
        '@type': partyRoleInfo['@type'],
        BusinessObjectId: partyRoleInfo.BusinessObjectId,
        RoleType: partyRole,
        Status: '1',
      },
    ];

    party.PaymentMethodId = PartyConst.DEFAULT_PAYMENT_METHOD_ID;

    party.LanguagePreferred = PartyConst.DEFAULT_CONTACT_LANGUAGE;

    const result = service.createParty(party);
    return result;
  }

  createCustomerRole(tempparty) {
    let party = tempparty;
    const partyAction = new PartyAction();
    let model = {};
    const PartyRoleList = [];
    if (PartyConst.PARTY_TYPE_IND == party.PartyType) {
      model = {
        '@type':
          'PartyIndividualCustomerRole-PartyIndividualCustomerRole',
        BusinessObjectId: 75171996,
        CustomerType: 1,
        CustomerCode: party.PartyCode,
      };
    } else if (PartyConst.PARTY_TYPE_ORG == party.PartyType) {
      model = {
        '@type':
          'PartyOrganizationalCustomerRole-PartyOrganizationalCustomerRole',
        BusinessObjectId: 75180924,
        CustomerType: 1,
        CustomerCode: party.PartyCode,
      };
    } else if (PartyConst.PARTY_TYPE_VEN == party.PartyType) {
      model = {
        '@type':
          'PartyOrganizationalCustomerRole-PartyOrganizationalCustomerRole',
        BusinessObjectId: 75180924,
        CustomerType: 1,
        CustomerCode: party.PartyCode,
      };
    }
    if (party.PartyRoleList) {
      let flag = true;
      _.each(party.PartyRoleList, role => {
        if (
          role.RoleType == PartyConst.PARTY_ROLE_CODE_IND_CUSTOMER ||
          role.RoleType == PartyConst.PARTY_ROLE_CODE_ORG_CUSTOMER
        ) {
          flag = false;
        }
      });
      if (flag) {
        party.PartyRoleList.push(model);
        const { PartyHistoryList } = party;
        const newPartyHistory = PartyConst.PARTY_HISTORY_CREATE_ROLE;
        PartyHistoryList.push(newPartyHistory);
        party = partyAction.updateParty(party);
      }
    } else {
      PartyRoleList.push(model);
      party.PartyRoleList = PartyRoleList;
      const { PartyHistoryList } = party;
      const newPartyHistory = PartyConst.PARTY_HISTORY_CREATE_ROLE;
      PartyHistoryList.push(newPartyHistory);
      party = partyAction.updateParty(party);
    }
    return party;
  }

  createDriverRole(tempparty) {
    let party = tempparty;
    const partyAction = new PartyAction();
    let model = {};
    const PartyRoleList = [];
    if (PartyConst.PARTY_TYPE_IND == party.PartyType) {
      model = {
        '@type':
          'PartyIndividualDriverRole-PartyIndividualDriverRole',
        BusinessObjectId: 75628452,
        RoleType: PartyConst.PARTY_ROLE_CODE_IND_DRIVER,
      };
    }
    if (party.PartyRoleList) {
      let flag = true;
      _.each(party.PartyRoleList, role => {
        if (role.RoleType == PartyConst.PARTY_ROLE_CODE_IND_DRIVER) {
          flag = false;
        }
      });
      if (flag) {
        party.PartyRoleList.push(model);
        const { PartyHistoryList } = party;
        const newPartyHistory = PartyConst.PARTY_HISTORY_CREATE_ROLE;
        PartyHistoryList.push(newPartyHistory);
        party = partyAction.updateParty(party);
      }
    } else {
      PartyRoleList.push(model);
      party.PartyRoleList = PartyRoleList;
      const { PartyHistoryList } = party;
      const newPartyHistory = PartyConst.PARTY_HISTORY_CREATE_ROLE;
      PartyHistoryList.push(newPartyHistory);
      party = partyAction.updateParty(party);
    }
    return party;
  }

  createIntroducerRole(tempparty) {
    let party = tempparty;
    const partyAction = new PartyAction();
    let model = {};
    const PartyRoleList = [];
    if (PartyConst.PARTY_TYPE_IND == party.PartyType) {
      model = {
        '@type':
          'PartyIndividualIntroducerRole-PartyIndividualIntroducerRole',
        BusinessObjectId: 75198369,
      };
    } else if (PartyConst.PARTY_TYPE_ORG == party.PartyType) {
      model = {
        '@type':
          'PartyOrganizationalIntroducerRole-PartyOrganizationalIntroducerRole',
        BusinessObjectId: 75198986,
      };
    }
    if (party.PartyRoleList) {
      let flag = true;
      _.each(party.PartyRoleList, role => {
        if (
          role.RoleType ==
          PartyConst.PARTY_ROLE_CODE_IND_INTRODUCER ||
          role.RoleType == PartyConst.PARTY_ROLE_CODE_ORG_INTRODUCER
        ) {
          flag = false;
        }
      });
      if (flag) {
        party.PartyRoleList.push(model);
        const { PartyHistoryList } = party;
        const newPartyHistory = PartyConst.PARTY_HISTORY_CREATE_ROLE;
        PartyHistoryList.push(newPartyHistory);
        party = partyAction.updateParty(party);
      }
    } else {
      PartyRoleList.push(model);
      party.PartyRoleList = PartyRoleList;
      const { PartyHistoryList } = party;
      const newPartyHistory = PartyConst.PARTY_HISTORY_CREATE_ROLE;
      PartyHistoryList.push(newPartyHistory);
      party = partyAction.updateParty(party);
    }
    return party;
  }

  createRiskAssessorRole(tempparty) {
    let party = tempparty;
    const partyAction = new PartyAction();
    let model = {};
    const PartyRoleList = [];
    if (PartyConst.PARTY_TYPE_IND == party.PartyType) {
      model = {
        '@type':
          'PartyIndividualRiskAssessorRole-PartyIndividualRiskAssessorRole',
        BusinessObjectId: 75198444,
      };
    } else if (PartyConst.PARTY_TYPE_ORG == party.PartyType) {
      model = {
        '@type':
          'PartyOrganizationalRiskAssessorRole-PartyOrganizationalRiskAssessorRole',
        BusinessObjectId: 75199082,
      };
    }
    if (party.PartyRoleList) {
      let flag = true;
      _.each(party.PartyRoleList, role => {
        if (
          role.RoleType ==
          PartyConst.PARTY_ROLE_CODE_IND_RISKASSESSOR ||
          role.RoleType == PartyConst.PARTY_ROLE_CODE_ORG_RISKASSESSOR
        ) {
          flag = false;
        }
      });
      if (flag) {
        party.PartyRoleList.push(model);
        const { PartyHistoryList } = party;
        const newPartyHistory = PartyConst.PARTY_HISTORY_CREATE_ROLE;
        PartyHistoryList.push(newPartyHistory);
        party = partyAction.updateParty(party);
      }
    } else {
      PartyRoleList.push(model);
      party.PartyRoleList = PartyRoleList;
      const { PartyHistoryList } = party;
      const newPartyHistory = PartyConst.PARTY_HISTORY_CREATE_ROLE;
      PartyHistoryList.push(newPartyHistory);
      party = partyAction.updateParty(party);
    }
    return party;
  }

  validationPayerRole(party) {
    let result = false;
    _.each(party.PartyRoleList, role => {
      if (
        role.RoleType == PartyConst.PARTY_ROLE_CODE_IND_PAYEEPAYER &&
        PartyConst.PARTY_ROLE_STATUS_ACTIVE !== role.Status
      ) {
        result = true;
      }
      if (
        role.RoleType == PartyConst.PARTY_ROLE_CODE_ORG_PAYEEPAYER &&
        PartyConst.PARTY_ROLE_STATUS_ACTIVE !== role.Status
      ) {
        result = true;
      }
    });
    return result;
  }

  createPayerRole(tempparty) {
    let party = tempparty;
    const partyAction = new PartyAction();
    let model = {};
    const PartyRoleList = [];
    if (PartyConst.PARTY_TYPE_IND == party.PartyType) {
      model = {
        '@type':
          'PartyIndividualPayeePayerRole-PartyIndividualPayeePayerRole',
        BusinessObjectId: 75198419,
      };
    } else if (
      PartyConst.PARTY_TYPE_ORG == party.PartyType ||
      PartyConst.PARTY_TYPE_VEN == party.PartyType
    ) {
      model = {
        '@type':
          'PartyOrganizationalPayeePayerRole-PartyOrganizationalPayeePayerRole',
        BusinessObjectId: 75199034,
      };
    }
    if (party.PartyRoleList) {
      let flag = true;
      _.each(party.PartyRoleList, role => {
        if (
          (role.RoleType ==
            PartyConst.PARTY_ROLE_CODE_IND_PAYEEPAYER &&
            PartyConst.PARTY_TYPE_IND == party.PartyType) ||
          (role.RoleType ==
            PartyConst.PARTY_ROLE_CODE_ORG_PAYEEPAYER &&
            PartyConst.PARTY_TYPE_ORG == party.PartyType) ||
          (role.RoleType ==
            PartyConst.PARTY_ROLE_CODE_ORG_PAYEEPAYER &&
            PartyConst.PARTY_TYPE_VEN == party.PartyType)
        ) {
          flag = false;
        }
      });
      if (flag) {
        party.PartyRoleList.push(model);
        const { PartyHistoryList } = party;
        const newPartyHistory = PartyConst.PARTY_HISTORY_CREATE_ROLE;
        PartyHistoryList.push(newPartyHistory);
        party = partyAction.updateParty(party);
      }
    } else {
      PartyRoleList.push(model);
      party.PartyRoleList = PartyRoleList;
      const { PartyHistoryList } = party;
      const newPartyHistory = PartyConst.PARTY_HISTORY_CREATE_ROLE;
      PartyHistoryList.push(newPartyHistory);
      party = partyAction.updateParty(party);
    }
    return party;
  }

  createClaimAntRole(tempparty) {
    let party = tempparty;
    const partyAction = new PartyAction();
    let model = {};
    const PartyRoleList = [];
    if (PartyConst.PARTY_TYPE_IND == party.PartyType) {
      model = {
        '@type':
          'PartyIndividualClaimantRole-PartyIndividualClaimantRole',
        BusinessObjectId: 75197995,
      };
    } else if (PartyConst.PARTY_TYPE_ORG == party.PartyType) {
      model = {
        '@type':
          'PartyOrganizationalClaimantRole-PartyOrganizationalClaimantRole',
        BusinessObjectId: 75198469,
      };
    }
    if (party.PartyRoleList) {
      let flag = true;
      _.each(party.PartyRoleList, role => {
        if (
          role.RoleType == PartyConst.PARTY_ROLE_CODE_IND_PRODUCER ||
          role.RoleType == PartyConst.PARTY_ROLE_CODE_ORG_PRODUCER
        ) {
          flag = false;
        }
      });
      if (flag) {
        party.PartyRoleList.push(model);
        const { PartyHistoryList } = party;
        const newPartyHistory = PartyConst.PARTY_HISTORY_CREATE_ROLE;
        PartyHistoryList.push(newPartyHistory);
        party = partyAction.updateParty(party);
      }
    } else {
      PartyRoleList.push(model);
      party.PartyRoleList = PartyRoleList;
      const { PartyHistoryList } = party;
      const newPartyHistory = PartyConst.PARTY_HISTORY_CREATE_ROLE;
      PartyHistoryList.push(newPartyHistory);
      party = partyAction.updateParty(party);
    }
    return party;
  }

  createIntroducerRole(tempparty) {
    const party = tempparty;
    const partyAction = new PartyAction();
    let model = {};
    const PartyRoleList = [];
    if (PartyConst.PARTY_TYPE_IND == party.PartyType) {
      model = {
        '@type':
          'PartyIndividualIntroducerRole-PartyIndividualIntroducerRole',
        BusinessObjectId: 75198369,
      };
    } else if (PartyConst.PARTY_TYPE_ORG == party.PartyType) {
      model = {
        '@type':
          'PartyOrganizationalIntroducerRole-PartyOrganizationalIntroducerRole',
        BusinessObjectId: 75198986,
      };
    }
    if (party.PartyRoleList) {
      let flag = true;
      _.each(party.PartyRoleList, role => {
        if (
          role.RoleType ==
          PartyConst.PARTY_ROLE_CODE_IND_INTRODUCER ||
          role.RoleType == PartyConst.PARTY_ROLE_CODE_ORG_INTRODUCER
        ) {
          flag = false;
        }
      });
      if (flag) {
        party.PartyRoleList.push(model);
        const { PartyHistoryList } = party;
        const newPartyHistory = PartyConst.PARTY_HISTORY_CREATE_ROLE;
        PartyHistoryList.push(newPartyHistory);
        party = partyAction.updateParty(party);
      }
    } else {
      PartyRoleList.push(model);
      party.PartyRoleList = PartyRoleList;
      const { PartyHistoryList } = party;
      const newPartyHistory = PartyConst.PARTY_HISTORY_CREATE_ROLE;
      PartyHistoryList.push(newPartyHistory);
      party = partyAction.updateParty(party);
    }
    return party;
  }

  createEmployeeRole(tempparty) {
    let party = tempparty;
    const partyAction = new PartyAction();
    let model = {};
    const PartyRoleList = [];
    if (PartyConst.PARTY_TYPE_IND == party.PartyType) {
      model = {
        '@type':
          'PartyIndividualEmployeeRole-PartyIndividualEmployeeRole',
        BusinessObjectId: 75198294,
        RoleType: PartyConst.PARTY_ROLE_CODE_IND_EMPLOYEE,
      };
      if (party.PartyRoleList) {
        const flag = true;
        _.each(partyRoleList, partyRole => {
          if (
            partyRole.RoleType ==
            PartyConst.PARTY_ROLE_CODE_IND_EMPLOYEE
          ) {
            /* empty */
          }
        });
        if (flag) {
          party.PartyRoleList.push(model);
          const { PartyHistoryList } = party;
          const newPartyHistory =
            PartyConst.PARTY_HISTORY_CREATE_ROLE;
          PartyHistoryList.push(newPartyHistory);
          party = partyAction.updateParty(party);
        }
      } else {
        PartyRoleList.push(model);
        party.PartyRoleList = PartyRoleList;
        const { PartyHistoryList } = party;
        const newPartyHistory = PartyConst.PARTY_HISTORY_CREATE_ROLE;
        PartyHistoryList.push(newPartyHistory);
        party = partyAction.updateParty(party);
      }
    }
    return party;
  }

  createPartyContact(tempparty, temppartyContact) {
    let party = tempparty;
    const partyContact = temppartyContact;
    const partyAction = new PartyAction();

    partyContact['@type'] = 'PartyContact-PartyContact';
    let contactPerson;
    if (partyContact.FirstName) {
      contactPerson = partyContact.FirstName;
    }
    if (partyContact.MiddleName) {
      contactPerson = contactPerson
        ? `${contactPerson} ${partyContact.MiddleName}`
        : partyContact.MiddleName;
    }
    if (partyContact.LastName) {
      contactPerson = contactPerson
        ? `${contactPerson} ${partyContact.LastName}`
        : partyContact.LastName;
    }
    if (contactPerson) {
      partyContact.ContactPerson = contactPerson;
    }
    const partyContactList = [];
    partyContactList.push(partyContact);
    party.PartyContactList = partyContactList;

    const { PartyHistoryList } = party;
    const newPartyHistory = PartyConst.PARTY_HISTORY_CONTACT;
    PartyHistoryList.push(newPartyHistory);
    party = this.updateParty(party);

    return party;
  }

  findPartyNameByCustomerCode(customerCode) {
    const result = service.findPartyNameByCustomerCode(customerCode);
    return result;
  }

  findPartyNameByPartyCode(partyCode) {
    const result = service.findPartyNameByPartyCode(partyCode);
    return result;
  }

  checkPartyShareholderPercentage(
    partyId,
    tempshareholderId,
    shareholderPercentage
  ) {
    let shareholderId = tempshareholderId;
    if (
      shareholderId === null ||
      shareholderId === undefined ||
      shareholderId === ''
    ) {
      shareholderId = 0;
    }
    const result = service.checkPartyShareholderPercentage(
      partyId,
      shareholderId,
      shareholderPercentage
    );
    if (result.status === 'success') {
      return result;
    }
    MessageHelper.error(
      i18n.PartyMsg.ErrorMsg.Error_0005,
      '',
      MessageHelper.POSITION_TOP_RIGHT
    );
  }

  getPartyVenByJointVentureName(jointVentureName) {
    let result = {};
    const url = `${urlConfig.findPartyVenByJointVentureName}?jointVentureName=${jointVentureName}`;
    Caller.call(url, null, {
      method: 'GET',
      async: false,
      block: false,
    }).then(
      data => {
        result = data;
      },
      () => { }
    );
    return result;
  }

  loadPartyHistoryListByPartyId(partyId) {
    let result = {};
    const url = urlConfig.loadPartyHistoryListByPartyId + partyId;
    Caller.call(url, null, {
      method: 'GET',
      async: false,
      block: false,
    }).then(
      data => {
        result = data;
      },
      () => { }
    );
    return result;
  }

  createThirdPartyDriver(tempparty) {
    const party = tempparty;
    let result = {};
    const url = urlConfig.createThirdParty;

    party.PartyName = `${party.FirstName} ${party.LastName}`;
    Caller.call(url, party, {
      method: 'POST',
      async: false,
      block: false,
    }).then(
      data => {
        result = data;
      },
      err => {
        if (err.responseJSON.Model) {
          MessageHelper.error(
            err.responseJSON.Model,
            '',
            MessageHelper.POSITION_TOP_RIGHT
          );
          result = err.responseJSON.Model;
        } else {
          MessageHelper.error(
            i18n.PartyMsg.ErrorMsg.Error_0005,
            '',
            MessageHelper.POSITION_TOP_RIGHT
          );
        }
      }
    );
    return result;
  }

  findPartyIndividualByIdNumber(party) {
    let result = {};
    const url = `${urlConfig.findPartyIndividualByIdNumber}?idNumber=${party.IdNumber}`;
    Caller.call(url, null, {
      method: 'GET',
      async: false,
      block: false,
    }).then(
      data => {
        result = data;
      },
      () => {
        MessageHelper.error(
          i18n.PartyMsg.ErrorMsg.Error_0005,
          '',
          MessageHelper.POSITION_TOP_RIGHT
        );
      }
    );
    return result;
  }
}

export default PartyAction;
