import { UIPanel } from 'RainbowUI';
import {
  ApprovalInfo,
  ApprovalHistory,
  ClaimTaskTransferDialog,
} from 'ClaimCommon';
import BindToMixin from 'react-binding';
import PropTypes from 'prop-types';
import ClaimCommonExt from 'ClaimCommonExt';

const SalvageHistory = require('./SalvageHistory');
const SalvageReadOnly = require('./SalvageReadOnly');
const SalvageLoadAction = require('../action/SalvageLoadAction');
const SalvageSubmitAction = require('../action/SalvageSubmitAction');
const SalvageApproveStore = require('../store/SalvageApproveStore');

const ClaimBaseInfo = ClaimCommonExt.getClaimBaseInfo();

const SalvageApprove = React.createClass({
  mixins: [BindToMixin],
  propTypes: {
    taskInstanceId: PropTypes.string.isRequired,
    caseId: PropTypes.string.isRequired,
  },

  getInitialState() {
    return {
      approveInfo: {
        BusinessId: null,
        TaskInstanceId: null,
        CaseId: null,
        Remark: '',
        DecisionCode: null,
      },
    };
  },
  onStoreStateChange(storeState) {
    if (!_.isEmpty(storeState.approveInfo)) {
      this.setState({ approveInfo: storeState.approveInfo });
    }
  },
  componentWillMount() {
    SalvageApproveStore.listen(this.onStoreStateChange);
    SalvageLoadAction.loadApproveTask(this.props.taskInstanceId);
  },
  componentWillUnmount() {
    SalvageApproveStore.unlisten(this.onStoreStateChange);
  },

  render() {
    const _self = this;
    const { claimEntity, caseId, readOnly } = this.props;
    const {
      approveInfo: { BusinessId },
    } = this.state;
    const salvageId = BusinessId;
    const CommonConfirmDialog = ClaimCommonExt.getCommonConfirmDialog();
    if (
      salvageId === null ||
      salvageId === undefined ||
      salvageId === ''
    ) {
      return (
        <div>
          <ClaimBaseInfo claimEntity={claimEntity} />
          <SalvageHistory caseId={caseId} />
        </div>
      );
    }

    return (
      <div>
        <ClaimBaseInfo claimEntity={claimEntity} />
        <div>
          <UIPanel
            id={`salvageApproveTaskPanel_${_self.props.taskInstanceId}`}
            panelTitle={i18n.ClaimSalvage.SalvagePanelTitle}
            toggleable="false"
          >
            <SalvageReadOnly
              salvageId={salvageId}
              history={false}
              readOnly
              isApproveTask
            />
          </UIPanel>
        </div>

        <ApprovalInfo
          decision={this.bindToState('approveInfo', 'DecisionCode')}
          remark={this.bindToState('approveInfo', 'Remark')}
          onClick={_self.onClickConfirm}
        />
        <ApprovalHistory processObjectId={salvageId} />
        <SalvageHistory caseId={_self.props.caseId} />
        <ClaimTaskTransferDialog
          ref="ClaimTaskTransferDialog"
          componentId="sal_cul_app"
        />
        <CommonConfirmDialog
          ref="confirmDialog_ref"
          parentComponent={this}
          objectId={`_salvageApprove${readOnly}_${salvageId}`}
          onConfirm={this.confirm}
          onCancel={this.cancel}
        />
      </div>
    );
  },

  confirm() {
    this.state.approveInfo.CaseId = this.props.caseId;
    const flag = SalvageSubmitAction.approveSalvage(
      this.state.approveInfo
    );
    if (flag) {
      this.refs.ClaimTaskTransferDialog.showDialog({
        CaseId: this.props.caseId,
      });
    }
  },

  cancel() {},
  onClickConfirm() {
    this.confirm();
  },
});
export default SalvageApprove;
