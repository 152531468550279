import {
  UIBox,
  UIPanel,
  UICell,
  UIButton,
  CodeTable,
} from 'RainbowUI';
import React from 'react';
import PropTypes from 'prop-types';
import BindToMixin from 'react-binding';
import {
  ApprovalHistory,
  ClaimTaskTransferDialog,
  CoverageTree,
} from 'ClaimCommon';
import ClaimCommonExt from 'ClaimCommonExt';

const SalvageInfo = require('./SalvageInfo');
const SalvageLoadAction = require('../action/SalvageLoadAction');
const SalvageSubmitAction = require('../action/SalvageSubmitAction');
const SalvageStore = require('../store/SalvageStore');
const SalvageHistory = require('./SalvageHistory');

const ClaimBaseInfo = ClaimCommonExt.getClaimBaseInfo();

const SalvageTaskPage = React.createClass({
  mixins: [BindToMixin],

  propTypes: {
    taskInstanceId: PropTypes.string.isRequired,
    caseId: PropTypes.string.isRequired,
  },

  getInitialState() {
    return { SalvageData: { OtherInfo: {} }, SelectedLength: 0 };
  },
  componentWillMount() {
    SalvageStore.listen(this.onStoreStateChange);
    if (!_.isEmpty(this.props.taskInstanceId)) {
      SalvageLoadAction.loadSalvageTask(this.props.taskInstanceId);
    }
  },
  componentWillUnmount() {
    SalvageStore.unlisten(this.onStoreStateChange);
  },
  onStoreStateChange(storeState) {
    if (!_.isEmpty(storeState.SalvageData)) {
      this.setState({
        SalvageData: storeState.SalvageData,
        SelectedLength: storeState.SelectedLength,
      });
    }
  },

  cancel() {},
  onClickSubmit() {
    this.submitSalvage();
  },
  submitSalvage() {
    const result = SalvageSubmitAction.submitSalvage(
      this.state.SalvageData
    );
    if (result === 'OK') {
      this.refs.ClaimTaskTransferDialog.showDialog({
        CaseId: this.props.caseId,
      });
    }
  },
  onClickSave() {
    SalvageSubmitAction.saveSalvage(this.state.SalvageData);
  },

  getSelectCode() {
    const otherInfo = this.bindToState('SalvageData', 'OtherInfo');
    const coverageTree = otherInfo.value.CoverageTree || [];
    const seleCodeTableList = [];
    seleCodeTableList.push({
      id: 0,
      text: i18n.ClaimSalvage.PleaseSelect,
    });
    for (let i = 1; i < coverageTree.length; i += 1) {
      seleCodeTableList.push({
        id: i,
        text: i + i18n.ClaimSalvage.Selected,
      });
    }
    const seleCodeTable = new CodeTable(seleCodeTableList);
    return seleCodeTable;
  },

  handleChooseCoverage(checkedList) {
    const { taskInstanceId } = this.props;
    const salvageInfo = this.bindToState(
      'SalvageData',
      'SalvageInfo'
    );
    const salvageId = this.bindTo(salvageInfo, '@pk').value;
    SalvageLoadAction.chooseCoverage(
      checkedList,
      taskInstanceId,
      salvageId
    );
  },

  render() {
    const {
      taskInstanceId,
      claimEntity,
      caseId,
      readOnly,
    } = this.props;
    if (_.isEmpty(taskInstanceId)) {
      return (
        <div>
          <ClaimBaseInfo claimEntity={claimEntity} />
          <SalvageHistory caseId={caseId} />
        </div>
      );
    }
    const suffixId = `salvage_${taskInstanceId}_`;
    const otherInfo = this.bindToState('SalvageData', 'OtherInfo');
    const salvageInfo = this.bindToState(
      'SalvageData',
      'SalvageInfo'
    );
    let coverageTree = otherInfo.CoverageTree;

    const salvageId = this.bindTo(salvageInfo, '@pk').value;
    const approvalHistory =
      salvageId === null ||
      salvageId === undefined ||
      salvageId === '' ? (
        <UICell />
      ) : (
        <UICell style={{ textAlign: 'left', padding: '0' }}>
          <ApprovalHistory
            processObjectId={salvageId}
            componentId={suffixId}
          />
        </UICell>
      );
    coverageTree = otherInfo.value.CoverageTree;
    const CommonConfirmDialog = ClaimCommonExt.getCommonConfirmDialog();
    return (
      <div>
        <ClaimBaseInfo claimEntity={claimEntity} />
        <div>
          <UIPanel
            id={`${suffixId}salvageTaskPanel`}
            panelTitle={i18n.ClaimSalvage.Salvage}
            toggleable="true"
          >
            <CoverageTree
              taskInstanceId={taskInstanceId}
              ref="getChooseCoverage"
              selectCoverageName={i18n.ClaimSalvage.SelectCoverage}
              coverageTree={coverageTree}
              handleParentChooseCoverage={this.handleChooseCoverage}
            />
            <UICell style={{ textAlign: 'left', padding: '0' }} />

            <UICell style={{ textAlign: 'left' }}>
              <SalvageInfo
                salvageInfo={salvageInfo}
                history={false}
                otherInfo={otherInfo}
                readOnly={false}
                isApproveTask={false}
              />
            </UICell>

            <UICell style={{ textAlign: 'left', margin: '15px 0' }}>
              <UIBox direction="left">
                <UIButton
                  value={i18n.ClaimCommon.Save}
                  onClick={this.onClickSave}
                />
                <UIButton
                  value={i18n.ClaimCommon.Submit}
                  validationGroup="salvage_submit_validate"
                  causeValidation="true"
                  onClick={this.onClickSubmit}
                />
              </UIBox>
            </UICell>

            <UICell style={{ textAlign: 'left', padding: '0' }} />
          </UIPanel>
          <ClaimTaskTransferDialog
            ref="ClaimTaskTransferDialog"
            componentId="salvage_task"
          />
          <CommonConfirmDialog
            ref="confirmDialog_ref"
            parentComponent={this}
            objectId={`_subclaimInfo${readOnly}_${caseId}`}
            onConfirm={this.submitSalvage}
            onCancel={this.cancel}
          />
        </div>

        <UICell style={{ textAlign: 'left' }} />

        {approvalHistory}

        <UICell style={{ textAlign: 'left', padding: '0' }}>
          <SalvageHistory caseId={caseId} />
        </UICell>
      </div>
    );
  },
});
export default SalvageTaskPage;
