import {
  UIColumn,
  UIDialogFooter,
  UITreeTable,
  UIButton,
  UICheckbox,
  UIParam,
  UICurrency,
} from 'RainbowUI';
import ClaimAuthorityAction from '../../action/ClaimAuthorityAction';
import ClaimAuthorityUtilAction from '../../action/ClaimAuthorityUtilAction';
import ClaimAuthorityStore from '../../store/ClaimAuthorityStore';
import ComfirmResetAuthority from './ComfirmResetAuthority';

const code = require('../../codeTable/CodeTable');

const ClaimAuthorityPanel = React.createClass({
  getList() {
    return null;
  },
  getInitialState() {
    return {
      param: {
        authorityList: [],
      },
    };
  },
  onStoreStateChange(storeState) {
    // if (!_.isEmpty(storeState.param)) {
    // }
    this.setState({ param: storeState.param });
  },
  componentWillMount() {
    const treeNode = {
      Path: 'root',
      Type: 'ProductLine',
      PermissionCode: this.props.permissionCode,
      pathName: 'ProductLine',
    };
    ClaimAuthorityAction.loadData(null, null, treeNode);
    ClaimAuthorityStore.listen(this.onStoreStateChange);
  },
  componentWillUnmount() {
    ClaimAuthorityStore.unlisten(this.onStoreStateChange);
  },

  checkSelect(event) {
    const data = event.getParameter('data');
    const { vo } = data;
    let list = this.state.param.authorityList;
    let assignFlag = vo.AssignedFlag;
    if (!data.pId) {
      if (assignFlag === 'Y') {
        assignFlag = 'N';
        list = ClaimAuthorityUtilAction.resetChild(list, vo);
      } else {
        assignFlag = 'Y';
        list = ClaimAuthorityUtilAction.checkChild(list, vo);
      }
    } else if (assignFlag === 'Y') {
      assignFlag = 'N';
    } else {
      assignFlag = 'Y';
    }

    data.vo.AssignedFlag = assignFlag;
    const showFlag = ClaimAuthorityUtilAction.checkShowFlag(data);
    data.showFlag = showFlag;
    for (const item in list) {
      const vo1 = list[item].vo;
      if (vo1.Code == data.vo.Code) {
        list[item] = data;
      }
    }
    const { param } = this.state;

    param.authorityList = list;

    this.setState({ param });
  },
  checkAmount(event) {
    const data = event.getParameter('data');
    const { newValue } = event;

    const orginalValue = data.vo.OrginalValue;
    const list = this.state.param.authorityList;

    if (orginalValue !== newValue) {
      data.vo.LimitedAmount = newValue;
      const showFlag = ClaimAuthorityUtilAction.checkShowFlag(data);
      data.showFlag = showFlag;
      for (const item in list) {
        const vo1 = list[item].vo;
        if (vo1.Code == data.vo.Code) {
          list[item] = data;
        }
      }
      const { param } = this.state;
      param.authorityList = list;
      this.setState({ param });
    }
  },
  resetAuthorityComfirmDialog() {
    this.refs.ComfirmResetAuthority_ref.showDialog();
  },
  render() {
    const { param } = this.state;
    const self = this;
    if (!_.isEmpty(param.authorityList)) {
      return (
        <div>
          <UITreeTable
            id="authorityTreeTable"
            dataSource={param.authorityList}
            expandable="false"
          >
            <UIColumn
              render={data => {
                const { vo } = data;

                if (vo.DataType === 'checkbox' && !data.pId) {
                  return (
                    <UICheckbox
                      id={`checkable${data.id}`}
                      codeTable={code.checkable}
                      value={vo.AssignedFlag}
                      onChange={self.checkSelect}
                    >
                      <UIParam name="data" value={data} />
                    </UICheckbox>
                  );
                }
              }}
              width="5px"
            />
            <UIColumn
              headerTitle={i18n.ClaimCommon.Authority}
              render={data => {
                const { vo } = data;
                if (vo.DataType === 'checkbox' && data.pId) {
                  return (
                    <div style={{ position: 'relative' }}>
                      <div style={{ float: 'left' }}>
                        <UICheckbox
                          id={`checkable${data.id}`}
                          codeTable={code.checkable}
                          value={vo.AssignedFlag}
                          onChange={self.checkSelect}
                          io={data.io}
                        >
                          <UIParam name="data" value={data} />
                        </UICheckbox>
                      </div>
                      <div
                        style={{
                          marginTop: '10px',
                          float: 'left',
                          minHeight: '30px',
                        }}
                      >
                        {vo.Name}
                      </div>
                    </div>
                  );
                }
                if (vo.DataType === 'checkbox' && !data.pId) {
                  return <div>{vo.Name}</div>;
                }
                if (vo.DataType === 'amount') {
                  return <div>{vo.Name}</div>;
                }
              }}
              width="auto"
            />
            <UIColumn
              headerTitle={i18n.ClaimCommon.AuthorizedAmount}
              render={data => {
                const { vo } = data;

                if (vo.DataType === 'amount') {
                  return (
                    <UICurrency
                      id={`CURRENCY_CODE_limitedAmount${data.id}`}
                      value={vo.LimitedAmount}
                      onChange={self.checkAmount}
                      io={data.io}
                      unit={window.EU00.getCurrencySignByCurrencyCode(
                        data.localCurrency
                      )}
                    >
                      <UIParam name="data" value={data} />
                    </UICurrency>
                  );
                }
              }}
              width="auto"
            />
          </UITreeTable>
          <UIDialogFooter>
            <UIButton
              value={i18n.ClaimCommon.Reset}
              onClick={this.resetAuthorityComfirmDialog}
            >
              <UIParam
                name="permissionCode"
                value={param.permissionCode}
              />
              <UIParam name="path" value={param.path} />
              <UIParam name="type" value={param.type} />
              <UIParam name="pathName" value={param.pathName} />
            </UIButton>
            <UIButton
              value={i18n.ClaimCommon.Submit}
              onClick={ClaimAuthorityAction.submitAuthority}
            >
              <UIParam
                name="authorityList"
                value={param.authorityList}
              />
              <UIParam
                name="permissionCode"
                value={param.permissionCode}
              />
              <UIParam name="path" value={param.path} />
              <UIParam name="type" value={param.type} />
              <UIParam name="pathName" value={param.pathName} />
            </UIButton>
          </UIDialogFooter>
          <ComfirmResetAuthority
            ref="ComfirmResetAuthority_ref"
            parentParam={param}
          />
        </div>
      );
    }
    return <div />;
  },
});
export default ClaimAuthorityPanel;
