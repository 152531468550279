import { AjaxUtil, CodeTable } from 'RainbowUI';
import { RemoteCodeTable } from 'EU00PartyCommonUI';
import Url from '../url/Url';

export default {
  loadDataList(codeTableId, conditionMap, async) {
    if ("useListNeedSystem" == conditionMap) {
      let dataList = RemoteCodeTable.codeTableById(codeTableId);
      dataList.push({ id: '-11', text: 'System' });
      return dataList;
    }

    if (conditionMap) {
      return RemoteCodeTable.codeTableByCondition(
        codeTableId,
        conditionMap
      );
    }

    return RemoteCodeTable.codeTableById(codeTableId);
  },

  getCodeTableTemp(codeTableId, conditions, settings) {
    return RemoteCodeTable.codeTableByConditionForOrignal(
      codeTableId,
      conditions
    );
  },
  initUser() {
    let currentUser = '';
    const url = Url.getCurrentUser;
    const settings = {
      async: false,
      done(data) {
        console.log(`Current user:${JSON.stringify(data)}`);
        currentUser = data;
        console.log(
          'render===renderuser======>>>>>::::::::',
          currentUser
        );
      },
      fail() {
        console.log('No current user.');
      },
    };
    AjaxUtil.doGet(url, {}, settings);
    return currentUser;
  },
  loadProductList() {
    const url = Url.loadProductList_url;
    let productData = [];
    AjaxUtil.doGet(url, null, {
      async: false,
      done(data) {
        productData = data;
      },
      fail(error) {
        return error;
      },
    });

    return productData;
  },

  formatCodeTableByIdDescription(mastList) {
    const result = [];
    const filterList = [];
    _.each(mastList, mapObj => {
      if (!_.contains(filterList, mapObj.Id)) {
        filterList.push(mapObj.Id);
        const v = { id: mapObj.Id, text: mapObj.Description };
        result.push(v);
      }
    });
    return new CodeTable(result);
  },
};
