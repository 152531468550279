import { MessageHelper } from 'RainbowUI';
import alt from '../../alt';
import SearchTaskMessage from '../SearchTaskMessage';

import ClaimMessageService from '../../service/ClaimMessageService';

class ClaimWorkFlowMyMessageAction {
  initCondition(condition) {
    this.dispatch(condition);
  }

  initMessage(tmpcondition) {
    const condition = tmpcondition;
    let result = [];
    delete condition.isUpdate;
    ClaimMessageService.initMessage(condition).then(
      data => {
        result = data;
      },
      ex => {
        if (ex.status == 401 || ex.status == 403) {
          MessageHelper.error(i18n.ClaimCommon.ReLogin);
        } else {
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
        }
      }
    );
    const resultObj = { count: result.length, result };
    const searchTaskMessage = new SearchTaskMessage(resultObj);
    searchTaskMessage.showMessage();

    return result;
  }

  dimissed(datas) {
    const data = datas;
    data.Status = '100006';
    let result = 'false';
    delete data.isUpdate;
    ClaimMessageService.updateMessage(data).then(
      data => {
        result = 'OK';
        MessageHelper.success(i18n.ClaimCommon.MessageDimissed);
      },
      ex => {
        if (ex.status == 401 || ex.status == 403) {
          MessageHelper.error(i18n.ClaimCommon.ReLogin);
        } else {
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
        }
      }
    );
    return 'OK';
  }
}
export default alt.createActions(ClaimWorkFlowMyMessageAction);
