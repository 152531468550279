import { CodeTable } from 'RainbowUI';
import { RemoteCodeTable } from 'EU00PartyCommonUI';

export default {
  settlementType: new CodeTable([
    { id: 'ST_01', text: 'Insured', Parent: '-41' },
    { id: 'ST_04', text: 'Other Providers', Parent: '-41' },
    { id: 'ST_18', text: 'Beneficiary', Parent: '-41' },
    { id: 'ST_09', text: 'Independent Adjusters', Parent: '-41' },
    {
      id: 'ST_11',
      text: 'Independent others professionals',
      Parent: '-50',
    },
  ]),

  reserveType: new CodeTable([
    { id: '-41', text: 'Loss(Indemnity)' },
    { id: '-50', text: 'Subrogation' },
  ]),

  currency: new CodeTable([
    { id: 'CNY', text: 'CNY' },
    { id: 'EUR', text: 'EUR' },
    { id: 'USD', text: 'USD' },
  ]),

  payPF: new CodeTable([
    { id: '1', text: 'P-Advanced Payment' },
    { id: '4', text: 'Final' },
    { id: '3', text: 'P-Rescue Fee' },
    { id: '2', text: 'P-Normal' },
  ]),

  yesNo: new CodeTable([
    { id: 'Y', text: 'Yes' },
    { id: 'N', text: 'No' },
  ]),

  payMode() {
    return new CodeTable(RemoteCodeTable.codeTableById(76242877));
  },

  accountNo: new CodeTable([
    { id: '1', text: '11111' },
    { id: '2', text: '22222' },
  ]),
  bank: new CodeTable([
    { id: '1', text: 'Bank of China' },
    { id: '2', text: 'Bank of America' },
  ]),
  partyRole: new CodeTable([
    { id: '1', text: 'Employee' },
    { id: '2', text: 'Branch' },
    { id: '3', text: 'Doctor' },
    { id: '4', text: 'Individual Solicitor' },
    { id: '5', text: 'Company Adjuster' },
    { id: '6', text: 'Workshop' },
    { id: '7', text: 'Sparts Vender Company' },
    { id: '8', text: 'Individual Payee' },
    { id: '9', text: 'Organization Payee' },
  ]),
};
