import {
  MessageHelper,
  CodeTable,
  UpdateContext,
  Cache,
  DataContext,
  CodeTableSorter,
} from 'RainbowUI';

import { RemoteCodeTable } from 'EU00PartyCommonUI';

const CodeTableService = require('../service/CodeTableService');
const ClaimCacheUI = require('../constant/ClaimCacheUI');
const CodeTableCstUI = require('../constant/CodeTableCstUI');
import { getRegionPostBody } from "../util/ClaimUtil";

export default {
  renderUser() {
    let currentUser = Cache.get('currentUser');
    console.log(
      'render===renderuser======>>>>>::::::::',
      currentUser
    );
    if (!currentUser) {
      currentUser = CodeTableService.initUser();
      Cache.put('currentUser', currentUser);
    }
    return currentUser;
  },
  geRegion(countryCode, regionLevel, parentRegionId) {
    if (countryCode) {
      const condition = getRegionPostBody(countryCode, regionLevel, parentRegionId);

      return CodeTableService.getCodeTableTemp(1605415, condition);
    }
    return new CodeTable([]);
  },
  getInsuranceCompany() {
    return RemoteCodeTable.getRoleParty('INCORG');
  },
  getbranch() {
    let codeTableList = [];
    const branchMapCode = {};
    const branchMapId = {};
    CodeTableService.loadDataList(
      CodeTableCstUI.Branch,
      null,
      false
    ).then(
      dataList => {
        if (!dataList) new CodeTable([]);
        dataList.forEach((data, index) => {
          codeTableList.push({
            id: data.Code,
            text: data.Description,
          });
          branchMapCode[data.Code] = data.Id;
          branchMapId[data.Id] = data.Code;
        });
      },
      err => {
        if (err.status !== 200) {
          MessageHelper.error(
            err.statusText,
            i18n.ClaimCommon.ErrorMsg.DataLoadERROR.replace(
              '{0}',
              codeTableId
            )
          );
        }
      }
    );
    if (!codeTableList) {
      codeTableList = [];
    }
    const result = {};
    result.branchCodeTable = new CodeTable(codeTableList);
    result.branchMapCode = branchMapCode;
    result.branchMapId = branchMapId;
    console.log('codeTableList', codeTableList);
    return result;
  },
  getCodeTableParam(codeTableId, componentId, conditionMap, isChild) {
    return {
      codeTableId,
      conditionMap,
      isChild,
      componentId,
    };
  },
  getPolicyHolderCodeTable(conditions) {
    const settings = { method: 'POST', async: false, block: false };
    const policyHolderList = CodeTableService.getCodeTableTemp(
      8561208,
      conditions,
      settings
    );

    const result = [];
    const filterList = [];
    _.each(policyHolderList, mapObj => {
      if (!_.contains(filterList, mapObj.Id)) {
        filterList.push(mapObj.Id);
        const v = { id: mapObj.Id, text: mapObj.Description };
        result.push(v);
      }
    });
    const codeTableData = new CodeTable(result);
    return codeTableData;
  },
  loadDataListAsync(codeTableId, componentId, conditionMap) {
    const param = {
      codeTableId,
      conditionMap,
      isChild,
      componentId,
    };
    var { codeTableId } = param;
    var { conditionMap } = param;
    var isChild = param.isChild || false;
    var { componentId } = param;

    const cacheId = `dataListById_${codeTableId}_${isChild}_${JSON.stringify(
      conditionMap
    )}`;
    console.info('cacheId', cacheId);
    const cache_codetable = ClaimCacheUI.CodeTableCache;
    const cache_component = ClaimCacheUI.ComponentCache;

    if (cache_codetable.get(cacheId)) {
      return cache_codetable.get(cacheId);
    }
    if (
      cache_component.get(codeTableId) &&
      cache_component.get(codeTableId).length > 0
    ) {
      cache_component.put(codeTableId, componentId);
      return new CodeTable([]);
    }
    cache_component.put(codeTableId, componentId);

    let codeTableList = [];
    const dataList = CodeTableService.loadDataList(
      codeTableId,
      conditionMap,
      true
    );
    if (!dataList) new CodeTable([]);
    console.info('dataList', dataList);
    dataList.forEach((data, index) => {
      if (
        isChild &&
        data.ConditionFields &&
        data.ConditionFields.length > 0
      ) {
        data.ConditionFields.forEach((condition, index) => {
          codeTableList.push({
            id: data.id,
            text: data.text,
            Parent: condition.ParentId,
          });
        });
      } else {
        codeTableList.push({ id: data.id, text: data.text });
      }
    });
    cache_codetable.put(cacheId, new CodeTable(codeTableList));
    const componentList = cache_component.get(codeTableId);
    for (let i = 0; i < componentList.length; i += 1) {
      UpdateContext.forceUpdate(componentList[i]);
    }
    cache_component.remove(codeTableId);

    console.info('codeTableList', codeTableList);
    if (!codeTableList) {
      codeTableList = [];
    }
    return new CodeTable(codeTableList);
  },

  loadDataList(codeTableId, conditionMap, isChild) {
    const cacheId = `dataListById_${codeTableId}${isChild}${JSON.stringify(
      conditionMap
    )}`;
    const cache_codetable = ClaimCacheUI.CodeTableCache;

    console.log('%ccache_codetable', 'color:red', cache_codetable);
    if (cache_codetable.get(cacheId)) {
      return cache_codetable.get(cacheId);
    }

    let codeTableList = [];
    const dataList = CodeTableService.loadDataList(
      codeTableId,
      conditionMap,
      false
    );
    if (codeTableId == -104) {
      _.forEach(dataList, datas => {
        const data = datas;
        if (data.id === 'USD') {
          data.text = 'USD';
        }
      });
    }
    if (!dataList) new CodeTable([]);
    dataList.forEach((data, index) => {
      if (
        isChild &&
        data.ConditionFields &&
        data.ConditionFields.length > 0
      ) {
        data.ConditionFields.forEach((condition, index) => {
          codeTableList.push({
            id: data.id,
            text: data.text,
            Parent: condition.ParentId,
          });
        });
      } else {
        codeTableList.push({ id: data.id, text: data.text });
      }
    });

    if (codeTableId == 1834755 && codeTableList.length) {
      codeTableList = codeTableList.filter(codes => codes.id !== 'W');
    }
    cache_codetable.put(cacheId, new CodeTable(codeTableList));

    if (!codeTableList) {
      codeTableList = [];
    }
    return new CodeTable(codeTableList);
  },
  clearCacheAll() {
    const cache_codetable = ClaimCacheUI.CodeTableCache;
    const cache_component = ClaimCacheUI.ComponentCache;
    cache_codetable.clearCacheAll();
    cache_component.clearCacheAll();
  },
  getProductNameCodeList() {
    const codeList = [];
    let data = DataContext.get('workListProductCodeNameList');
    if (!data) {
      data = CodeTableService.loadProductList();
      DataContext.put('workListProductCodeNameList', data);
    }

    const productNameList = [];
    for (let i = 0; i < data.length; i += 1) {
      if (
        !_.contains(
          productNameList,
          data[i].ProductMaster.ProductCode
        ) &&
        data[i].EffectiveFlag === 'Y' &&
        data[i].PackageSubProduct !== 'Y'
      ) {
        productNameList.push(data[i].ProductMaster.ProductCode);
        codeList.push({
          id: data[i].ProductId,
          text: `${data[i].ProductMaster.ProductCode}-${data[i].ProductMaster.ProductName}`,
        });
      }
    }

    return new CodeTable(codeList, null, new CodeTableSorter());
  },

  getCodeTableByConditions(codeTableId, conditions, settings) {
    const codeList = [];
    var settings = { method: 'POST', async: false, block: false };
    const result = CodeTableService.getCodeTableTemp(
      codeTableId,
      conditions,
      settings
    );
    for (let i = 0; i < result.length; i += 1) {
      if (result[i].Id) {
        codeList.push({
          id: result[i].Id,
          text: result[i].Description,
        });
      }
    }
    return new CodeTable(codeList);
  },

  getSalesChannelCodeTable(conditions) {
    const settings = { method: 'POST', async: false, block: false };
    const salesChannelList = CodeTableService.getCodeTableTemp(
      987476,
      conditions,
      settings
    );
    const codeTableData = CodeTableService.formatCodeTableByIdDescription(
      salesChannelList
    );
    return codeTableData;
  },
};
