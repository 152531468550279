import {
  UIText,
  UIDataTable,
  UIColumn,
  UILink,
  UIParam,
  UICurrency,
} from 'RainbowUI';
import BindToMixin from 'react-binding';
import { ClaimMessageForamtUtils } from 'ClaimCommonConstant';

const BigNumber = require('bignumber.js');
const AppraisalPropertyAction = require('../../action/AppraisalPropertyAction');

const AppraisalPropertyItem = React.createClass({
  mixins: [BindToMixin],

  getInitialState() {
    return {};
  },

  componentWillMount() {},
  refresh(event) {
    console.log('event', event);
    // var newValue = new BigNumber(event.newValue);
    // var a = new BigNumber(x);
    //
    // var newValue = new BigNumber(event.newValue);

    // var oldValue = new BigNumber(event.oldValue);
    // var oldValue = new BigNumber(event.oldValue);
    this.getTotalAmount(event.newValue, event.oldValue, event);
    this.forceUpdate();
  },
  getTotalAmount(newValue, oldValue, tmpevent) {
    const event = tmpevent;
    const { claimAppraisalProperty } = this.props;
    const claimAppraisalPropertyItems = this.bindArrayTo(
      claimAppraisalProperty,
      'claimAppraisalPropertyItems'
    ).items;
    let totalClaimAmount = new BigNumber(0);
    let totalDeductionAmount = new BigNumber(0);
    const { length } = claimAppraisalPropertyItems;
    for (let i = 0; i <= length - 2; i += 1) {
      let claimAmount =
        claimAppraisalPropertyItems[i].sourceObject.ClaimAmount;
      let deductionAmount =
        claimAppraisalPropertyItems[i].sourceObject.DeductionAmount;
      if (typeof claimAmount === 'undefined') {
        claimAmount = Number.isInteger();
      }
      if (!claimAmount) {
        claimAmount = 0;
      }
      if (typeof deductionAmount === 'undefined') {
        deductionAmount = 0;
      }
      if (!deductionAmount) {
        deductionAmount = 0;
      }
      console.info('ClaimAmount ', claimAmount);
      console.info('DeductionAmount ', deductionAmount);
      const claimAmountOfItems = new BigNumber(Number(claimAmount));
      const deductionAmtOfItems = new BigNumber(
        Number(deductionAmount)
      );
      // var netAmount = claimAmount*1 - deductionAmount*1;
      let netAmount = claimAmountOfItems.minus(deductionAmtOfItems);
      if (netAmount < 0) {
        console.info('netAmount ', netAmount);
        console.info('ClaimAmount ', claimAmount);
        console.info('DeductionAmount ', deductionAmount);
        if (newValue == claimAmount) {
          claimAmount = oldValue;
        }
        if (newValue == deductionAmount) {
          deductionAmount = oldValue;
        }
        console.info('ClaimAmount ', claimAmount);
        console.info('DeductionAmount ', deductionAmount);
        // MessageHelper.warning('The net amount must greater than zero','waring');
        netAmount = claimAmount - deductionAmount;
        event.component.newValue = oldValue;
        console.info('event ', event);
      }
      console.info('netAmount ', netAmount);
      this.props.claimAppraisalProperty.sourceObject.appraisalProperty.claimAppraisalPropertyItems[
        i
      ].NetAmount = netAmount;
      // totalClaimAmount = totalClaimAmount + claimAmount;
      totalClaimAmount = totalClaimAmount.plus(
        new BigNumber(claimAmount)
      );
      console.info('totalClaimAmount ', totalClaimAmount);
      // totalDeductionAmount = totalDeductionAmount + deductionAmount;
      totalDeductionAmount = totalDeductionAmount.plus(
        new BigNumber(Number(deductionAmount))
      );
      console.info('totalDeductionAmount ', totalDeductionAmount);
    }
    // var totalNetAmount = totalClaimAmount - totalDeductionAmount;
    const totalNetAmount = totalClaimAmount.minus(
      totalDeductionAmount
    );
    claimAppraisalPropertyItems[
      length - 1
    ].sourceObject.ClaimAmount = totalClaimAmount;
    claimAppraisalPropertyItems[
      length - 1
    ].sourceObject.DeductionAmount = totalDeductionAmount;
    claimAppraisalPropertyItems[
      length - 1
    ].sourceObject.NetAmount = totalNetAmount;
    this.props.claimAppraisalProperty.sourceObject.appraisalProperty.TotalClaimAmount = totalClaimAmount;
    this.props.claimAppraisalProperty.sourceObject.appraisalProperty.TotalDeductionAmount = totalDeductionAmount;
    this.props.claimAppraisalProperty.sourceObject.appraisalProperty.TotalNetAmount = totalNetAmount;
  },
  removeLossItem(event) {
    AppraisalPropertyAction.removePropertyItem(event);
    this.getTotalAmount();
    this.forceUpdate();
  },
  buildTableFunction() {
    const { ioSytle } = this.props;
    if (ioSytle === 'out') {
      return <div />;
    }
    return (
      <div>
        <UILink
          icon="fa fa-plus"
          size="lg"
          tooltip={i18n.Add}
          iconClass="add_iconbtn"
          onClick={AppraisalPropertyAction.addPropertyItem}
        />
      </div>
    );
  },
  render() {
    const {
      claimAppraisalProperty,
      currencyCode: CurrencyCode,
      history,
      ioSytle: io,
    } = this.props;
    console.log(
      '===claimAppraisalProperty===',
      claimAppraisalProperty
    );
    const appraisalPropertyItem = this.bindArrayTo(
      claimAppraisalProperty,
      'claimAppraisalPropertyItems'
    ).items;
    console.log('===appraisalPropertyItem===', appraisalPropertyItem);
    const length = appraisalPropertyItem.length - 1;
    console.log('===length===', length);
    // var suffixId="settlementItemInfo_"+this.props.readOnly+"_"+this.props.payeeIndex+"_";
    const _ref = this;
    let style;
    if (history) {
      style = 'default';
    } else {
      style = 'primary';
    }
    return (
      <div>
        <UIDataTable
          id="ClaimAppraisalPropertyItems_DataTable"
          indexable="false"
          pageable="false"
          value={appraisalPropertyItem}
          styleClass={style}
          functions={this.buildTableFunction()}
        >
          <UIColumn
            headerTitle={i18n.ClaimAppraisal.LossItem}
            render={data => {
              console.log('===table===', data);
              if (data.dataIndex == length) {
                return <UIText io="out" />;
              }
              return (
                <UIText
                  io={io}
                  model={data.value}
                  property="ItemName"
                  required="true"
                  validationGroup="AppraisalProperty"
                  requiredMessage={ClaimMessageForamtUtils.formatI18NMsg(
                    i18n.ClaimCommon.I18nUtilValidate,
                    i18n.ClaimAppraisal.LossItem
                  )}
                />
              );
            }}
          />

          <UIColumn
            headerTitle={i18n.ClaimAppraisal.Description}
            render={data => {
              if (data.dataIndex == length) {
                return (
                  <UIText
                    io="out"
                    model={data.value}
                    property="ItemDesc"
                  />
                );
              }
              return (
                <UIText
                  io={io}
                  model={data.value}
                  property="ItemDesc"
                />
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.ClaimAppraisal.ClaimAmount}
            render={data => {
              console.log(
                'data-------------------------------',
                data
              );
              if (data.dataIndex == length) {
                return (
                  <UICurrency
                    id={`CURRENCY_CODE_ClaimAmount${data.dataIndex ||
                      data.value['@pk']}`}
                    enabled="false"
                    defaultValue="0"
                    unit={window.EU00.getCurrencySignByCurrencyCode(
                      CurrencyCode
                    )}
                    model={data.value}
                    property="ClaimAmount"
                  />
                );
              }
              return (
                <UICurrency
                  id={`CURRENCY_CODE_ClaimAmount${data.dataIndex ||
                    data.value['@pk']}`}
                  onChange={_ref.refresh}
                  defaultValue="0"
                  enabled={
                    _ref.props.ioSytle === 'out' ? 'false' : 'true'
                  }
                  unit={window.EU00.getCurrencySignByCurrencyCode(
                    CurrencyCode
                  )}
                  model={data.value}
                  property="ClaimAmount"
                  required="true"
                  validationGroup="AppraisalProperty"
                  requiredMessage={ClaimMessageForamtUtils.formatI18NMsg(
                    i18n.ClaimCommon.I18nUtilValidate,
                    i18n.ClaimAppraisal.ClaimAmount
                  )}
                />
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.ClaimAppraisal.NetAmount}
            render={data => {
              if (data.dataIndex == length) {
                return (
                  <UICurrency
                    id={`CURRENCY_CODE_NetAmount${data.dataIndex ||
                      data.value['@pk']}`}
                    enabled="false"
                    defaultValue="0"
                    unit={window.EU00.getCurrencySignByCurrencyCode(
                      CurrencyCode
                    )}
                    model={data.value}
                    property="NetAmount"
                  />
                );
              }
              return (
                <UICurrency
                  id={`CURRENCY_CODE_NetAmount${data.dataIndex ||
                    data.value['@pk']}`}
                  enabled="false"
                  defaultValue="0"
                  unit={window.EU00.getCurrencySignByCurrencyCode(
                    CurrencyCode
                  )}
                  model={data.value}
                  property="NetAmount"
                  required="true"
                  validationGroup="AppraisalProperty"
                  requiredMessage={ClaimMessageForamtUtils.formatI18NMsg(
                    i18n.ClaimCommon.I18nUtilValidate,
                    i18n.ClaimAppraisal.NetAmount
                  )}
                />
              );
            }}
          />
          <UIColumn
            render={data => {
              if (data.dataIndex == length) {
                return <UIText io="io" />;
              }
              if (io === 'out') {
                return <UIText io="io" />;
              }
              return (
                <UILink
                  onClick={_ref.removeLossItem}
                  icon="fa fa-trash"
                >
                  <UIParam
                    name="removeIndex"
                    value={data.dataIndex}
                  />
                </UILink>
              );
            }}
          />
        </UIDataTable>
      </div>
    );
  },
});
export default AppraisalPropertyItem;
