export default {
  home: '首页',
  query: '查询',
  application: '核销',
  collection: '收款',
  payment: '付款',
  internal: '其他操作',
  paymentApproval: '付款审批',
  reverseApplication: '回退核销&冲销',
  reversePayment: '回退付款',
  reverseReceipt: '回退收费',
  toleranceOffset: '小余额销帐',
  arapOffset: '应收应付冲销',
  arap: '应收应付',
  arapNo: '应收应付编号',
  arapType: '应收应付类型',
  arapGenerationDateFrom: '应收应付生成日起于',
  arapGenerationDateTo: '应收应付生成日截至',
  arapDueDateFrom: '应收应付到期日起于',
  arapDueDateTo: '应收应付到期日截至',
  branch: '分支',
  policyNo: '保单号',
  agencyBroker: '代理人/经纪人',
  policyEndtEffectiveDateFrom: '保单/批单生效日起于',
  policyEndtEffectiveDateTo: '保单/批单生效日截至',
  search: '搜索',
  arapGenerationDate: '应收应付生成日',
  policyPeriod: '保单有效期',
  endorsementNo: '批单号',
  installmentNo: '分期付款编号',
  arapAmount: '应收应付金额',
  commission: '佣金',
  tax: '税',
  fee: '费用',
  amount: '金额',
  offsetAmount: '冲销金额',
  balance: '余额',
  dueDate: '到期日',
  submit: '提交',
  exit: '退出',
  receiptInfo: '实收信息',
  receiptNo: '实收编号',
  payer: '付款人',
  collectionAmount: '实收金额',
  remainingBalance: '余额',
  claimNo: '赔案号',
  broker: '经纪人',
  insured: '被保险人',
  endtNo: '批单号',
  generationDate: '生成日期',
  currency: '货币',
  appliedAmount: '核销金额',
  arapAmountTotal: '应收应付金额总数',
  appliedAmountTotal: '核销金额总数',
  collectionInfo: '收款信息',
  paymentMethod: '支付方式',
  bankAccount: '银行帐号',
  collectionDate: '收款日期',
  payerName: '付款人姓名',
  payerCode: '付款人编号',
  payerType: '付款人类型',
  select: '选择',
  payerAddress: '付款人地址',
  receipt: '实收',
  receiptSearch: '实收搜索',
  collectionDateFrom: '收款日起于',
  collectionDateTo: '收款日截至',
  result: '列表',
  commissionSearch: '佣金搜索',
  commissionStatus: '佣金状态',
  commissionGenerationDateFrom: '佣金生成日从',
  commissionGenerationDateTo: '佣金生成日至',
  commissionGenerationDate: '佣金生成日',
  commissionAmount: '佣金数额',
  statusChange: '状态更改',
  paymentDetail: '付款详情',
  Language: '语言',
  English: '英语',
  Chinese: '中文',
  Japanese: '日语',
  ChangePassWord: '修改密码',
  payerInfo: '付款人信息',
  salesChannel: '销售渠道',
  paidAmount: '已付金额',
  paymentRequest: '付款请求',
  submitforApproval: '提交待批准',
  paymentInfo: '付款信息',
  payeeCode: '收款人代码',
  payeeName: '收款人姓名',
  bank: '银行',
  bankAccountNo: '银行帐号',
  paymentAmount: '付款金额',
  companyBankAccount: '保险公司银行帐号',
  paymentSearch: '付款记录搜索',
  paymentNo: '付款编码',
  requestor: '付款申请人',
  requestDateFrom: '申请日起于',
  requestDateTo: '申请日截至',
  paymentRequestDateFrom: '付款日申请起于',
  paymentRequestDateTo: '付款日申请截至',
  payee: '收款人',
  payeeBank: '收款人银行',
  payeeBankAccountNo: '收款人银行帐号',
  arapResult: '应收应付搜索结果',
  transactionNo: '交易编号',
  justNew: '添加',
  paymentRequestDate: '付款申请日',
  paymentApprovalRejectDate: '付款审批日',
  approverRejecter: '审批者',
  action: '执行',
  suspense: '收费账户',
  suspenseNo: '收费帐号',
  suspenseType: '收费账户类型',
  suspenseGenerationDateFrom: '收费账户生成日起于',
  suspenseGenerationDateTo: '收费账户生成日截至',
  statementNo: '账单号',
  suspenseResult: '收费账户列表',
  suspenseGenerationDate: '收费账户生成日',
  paymentApprovalDate: '付款批准日',
  approver: '审批者',
  paymentSuccessDate: '付款成功日',
  modify: '修改',
  arapDetailInformation: '应收应付详细信息',
  transactionType: '交易类型',
  transactionDate: '交易日期',
  transactionAmount: '交易金额',
  operator: '操作者',
  transactionStatus: '交易状态',
  reversalDate: '回退日期',
  reversalOperator: '回退操作者',
  arapStatus: '应收应付状态',
  arapSearch: '应收应付搜索',
  policyNoStatementNo: '保单号/账单号',
  applicationOffsetSearch: '核销&冲销查询',
  transactionDateFrom: '交易日起于',
  transactionDateTo: '交易日截至',
  reversalReason: '回退原因',
  approvalDateFrom: '批准日起于',
  approvalDateTo: '批准日截至',
  collectionCurrency: '收款货币',
  depositDate: '收款日期',
  suspenseSearch: '收款账户搜索',
  generationDateFrom: '生成日起于',
  generationDateTo: '生成日截至',
  writeoffAmount: '销帐金额',
  reason: '原因',
  paymentEdit: '付款编辑',
  requestDate: '申请日期',
  decision: '审批决定',
  transactionHistory: '交易历史',
  confirmDialog: '确认对话框',
  successDialog: '成功对话框',
  confirmMessage: '是否确认提交?',
  paymentResult: '付款结果',
  collectionNoResult: '收款编号为 ',
  suspenseNoResult: '收费账户编号为 ',
  receiptNoResult: '收据号为 ',
  paymentSubmitResult: '付款编号为 ',
  paymentStatus: '付款状态',
  paymentReverseDate: '付款回退日期',
  reverse: '回退人',
  queryARAP: '查询应收应付',
  queryPayment: '查询付款',
  queryReceipt: '查询收款',
  receiptOrSuspenseSearch: '收费搜索',
  receiptStatus: '收费状态',
  cashBeforeCoverCollection: '见费出单',
  receiptAmount: '收费金额',
  bankCharge: '银行费用',
  receiptBalance: '收费余额',
  quotationNo: '询价单号',
  bankSlipNo: '银行水单号',
  receiptDetail: '收款详情',
  applicationDate: '支付日期',
  applicationAmount: '支付金额',
  queryApplication: '核销查询',
  applicationSearch: '核销搜索',
  applicationDetail: '核销详情',
  customerOrPayer: '客户/付款人',
  offsetNo: '冲销编号',
  offsetType: '冲销类型',
  offsetDateFrom: '冲销日起于',
  offsetDateTo: '冲销日截至',
  offsetStatus: '冲销状态',
  referenceNo: '引用编号',
  referenceType: '引用类型',
  offsetDate: '冲销日期',
  arAmountTotal: '应收金额总数',
  apAmountTotal: '应付金额总数',
  addToList: '增加到列表',
  showAll: '显示所有',
  clearList: '清除列表',
  successMessage: '操作成功. ',
  collectionType: '收款类型',
  suspenseDetail: '收费详情',
  operationDialog: '操作结果',
  operationMessage:
    '应收应付余额还可以小金额冲销.你想继续到小金额冲销页面进行操作吗?',
  appliedError: '实用金额错误!',
  totalError: '金额总数错误!',
  additionSuccess: '添加数据成功!',
  additionError: '添加数据失败!',
  additionEmpty: '添加数据为空!',
  clearSuccess: '清空数据成功!',
  clearError: '清空数据失败!',
  applicationEmpty: '支付数据不能为空!',
  receiptEmpty: '实收数据不能为空!',
  receiptError: ' 不能回退, 金额不等于余额!',
  offsetAmountError: '冲销金额错误!',
  writeoffAmountError: '冲销金额错误!',
  decisionEmpty: '决定不能为空!',
  paidAmountError: '支付金额错误!',
  amountEmpty: '金额不能为空!',
  paymentEmpty: '付款数据不能为空!',

  bankAccountMaintenance: '银行账户维护',
  bankAccountSearch: '银行账户搜索',
  bankAccountName: '银行账户名称',
  bankAccountStatus: '银行账户状态',
  add: '添加',
  edit: '编辑',
  Delete: '删除',
  bankAccountCode: '银行账户编码',
  foreignBank: '是否外国银行',
  bankAccountInfo: '银行账户信息',
  bankAccountEdit: '银行账户编辑',
  bankAccountRelation: '银行账户关系',
  bankAccountType: '银行账户类型',
  newOrUpdate: '创建/更新',
  payeeType: '收款人类型',
  endorsementType: '批单类型',
  Logout: '注销',
  note: '注解',
  existingNote: '现有注解',
  addNewNote: '添加新注解',
  receiptResult: '实收搜索结果',
  customerCategory: '客户类别',
  New: '创建',
  companyName: '公司名称',
  firstName: '首名称',
  middleName: '中间名称',
  lastName: '末名称',
  swiftCode: 'SWIFT编号',
  save: '保存',
  payeeInfo: '收款人信息',

  commissionStatusAdjustment: '佣金状态调整',
  bizType: 'Biz类型',
  commissionSettlementDateFrom: '佣金结算日从',
  commissionSettlementDateTo: '佣金结算日至',
  queryCommission: '查询佣金',
  settlementDate: '结算日期',
  statusChangeEmpty: '状态更改不能为空!',
  agencyBrokerName: '代理人/经纪人名称',
  agencyBrokerCode: '代理人/经纪人编号',
  commissionSettlement: '佣金结算',
  settle: '结算',
  certificateNo: '凭证号',
  swissReBankAccount: '瑞再银行帐号',
  paymentPurpose: '付款原因',
  payeeSearch: '收款人搜索',
  endorsementEffDate: '批单生效日',
  exchangeRate: '汇率',
  receiptAmountInCNY: '实收金额（人民币）',
  alipayTransactionNO: 'Alipay Transaction NO.',
  alipayReferenceNO: 'Alipay Reference NO.',
  remainingReceiptBalance: '实收余额',
  remainingReceiptBalanceInCNY: '实收余额（人民币）',
  arapAmountInCNY: '应收应付金额（人民币）',
  exchangeRateARAP: '汇率（应收应付：实收）',
  balanceInCNY: '余额（人民币）',
  reversalDateFrom: '回退日起于',
  reversalDateTo: '回退日截至',
  extractedDate: '抽取日',
  paymentDate: '实际支付日',
  approvedAndrejectedBy: '审批人',
  reverseCollection: '回退收款',
  currentBankCharge: '银行手续费',
  commissionAmountInCNY: '佣金金额（人民币）',
  queryEmptyError: '请填写搜索条件。',
  arapTypeBizType: '应收应付/业务类型',
  reversalBy: '回退人',
  approvalDate: '审批日',
  status: '状态',
  uploadCollection: '批量收款',
  upload: '上传',
  uploadResult: '批处理搜索结果',
  uploadID: '上传编号',
  uploadUser: '上传者',
  uploadDateFrom: '上传日起于',
  uploadDateTo: '上传日截至',
  uploadStaus: '上传状态',
  uploadDateAndTime: '上传时间',
  uploadFileName: '文件名',
  uploadResultFile: '错误原因',
  uploadErrorMsg:
    'The file type is not accepted, only accept *.xls,*.xlsx file',
  currencyCodeError:
    'All the selected AP and Receipt/Suspense should be with same currency',
  statement: '对账单',
  statementGeneration: '生成对账单',
  statementView: '对账单列表',
  generationStatement: '生成对账单',
  statementDateFrom: '对账单日起于',
  statementDateTo: '对账单日截至',
  statementGenerationUser: '生成对账单用户',
  statementDate: '对账单日',
  uploadResultSuccessMessage:
    'The operation is successful. The uploadId is ',
  uploadResultFailureMessage: '操作失败。',
  exportToExcel: '导出至Excel',
  exportConfirmDialog: '确认',
  exportConfirmMessage: 'Do you confirm to export to the Excel?',
  releaseClaimPayment: '解锁理赔赔付',
  claimPaymentSearch: '理赔赔付搜索',
  release: '解锁',
  enterNegativeError: '输入金额不能为负!',
  enterPositiveError: '输入金额不能为正!',
  enterAmountError: '输入金额不能大于余额!',
  totalAmountError: '账户的余额不能小于零且不能大于原来的帐户余额!',
  selectError: '请选择数据且输入框不能为空!',
  arapAmountError:
    'AR冲销金额必须等于AP冲销金额且冲销金额不能小于零!',
  arapFlagError: '请选择应收应付记录!',
  authorityConfig: '权限配置',
  technicalAccountingDataAuthorityConfiguration: 'TA数据权限配置',
  workLevel: '权限级别',
  paymentApproalAmount: '付款审批金额',
  toleranceOffsetAmountOfARAP: '小余额销帐金额',
  writeOffAmountOfARAP: '销帐金额',
  businessType: '业务类型',
  customer: '客户',
  transactionEffectiveDate: '交易生效日',
  cashInPurpose: '收款原因',
  taxpayerType: '纳税人类型',
  taxRegistrationNo: '税务注册号',
  address: '地址',
  tel: '电话',
  payerCreation:
    'The payer/payee creation or modification has been approved by manager.',
  payerOrPayee: '付款人/收款人',
  masterPolicyNo: '统括保单号',
  documentUpload: '文档上传',
  actualPaymentCurrency: '实际付款货币',
  actualPaymentAmount: '实际付款金额',
  approvalBy: '审批人',
  rejectReversalDate: '拒绝/回退日期',
  rejectReversalBy: '拒绝/回退人',
  customerReinsurer: '客户/再保险人',
  documentUploadAndView: '文档上传和浏览',
  payerError: 'The payer/payee is required and cannot be empty.',
  configuration: '配置',
  clearCache: '清除缓存',
  payeeError: 'Please select the payee!',
  bankAccountError: 'Please select the bank account!',
  bankInfoError: 'Please save the bank account information!',
  dmsUploadTip: 'Please upload reference document.',
  authorityAmountError:
    'The selected ARAP balance is higher than the user granted authority!',
  applicationButton: '核销',
  payerDialog: '付款人框',
  payerSearch: '付款人搜索',
  payerResult: '付款人搜索结果',
  cancel: '取消',
  payeeDialog: '收款人框',
  payeeResult: '收款人搜索结果',
  reset: '重置',
  receiptDialog: '实收框',
  uploadSearch: '上传搜索',
  reverseReceiptSearch: '回退收费搜索',
  reverseApplicationSearch: '回退核销&冲销搜索',
  applicationResult: '回退核销&冲销搜索结果',
  paymentApprovalSearch: '付款审批搜索',
  reversePaymentSearch: '回退付款搜索',
  arapOffsetSearch: '应收应付冲销搜索',
  arapToleranceOffsetSearch: '应收应付小余额销帐搜索',
  bankAccountResult: '银行帐号搜索结果',
  bankAccountDialog: '银行帐号编辑框',
  commissionStatusAdjustmentSearch: '佣金状态调整搜索',
  commissionResult: '佣金搜索结果',
  close: '关闭',
};
