export default {
  reserveListData: {
    Model: [
      {
        BusinessObjectId: 517950,
        CoverageCode: 'CI_-7282',
        CoverageName: 'Accessory',
        ItemId: -31,
        LossStatus: 'REOPEN',
        PolCoverageId: 1,
        ProductCode: '198',
        ReserveList: [
          {
            BusinessObjectId: 518015,
            CurrencyCode: 'CNY',
            LockFlag: 'N',
            OutstandingAmount: 140,
            ReserveId: -41,
            ReserveType: 'RC_01',
            SettleAmount: -70,
            StatusCode: 'REOPEN',
          },
          {
            BusinessObjectId: 518015,
            CurrencyCode: 'CNY',
            LockFlag: 'N',
            OutstandingAmount: -1,
            ReserveId: -42,
            ReserveType: 'RC_04',
            SettleAmount: 0,
            StatusCode: 'OPEN',
          },
        ],
        SeqNo: '001',
        StatusCode: 'REOPEN',
        SubrogationStatus: 'REOPEN',
        SumInsured: 0,
      },
      {
        BusinessObjectId: 517950,
        CoverageCode: 'CI_47766',
        CoverageName: 'Third Party Property Damage',
        ItemId: -32,
        LossStatus: 'REOPEN',
        PolCoverageId: 2,
        ProductCode: '198',
        ReserveList: [
          {
            BusinessObjectId: 518015,
            CurrencyCode: 'CNY',
            LockFlag: 'N',
            OutstandingAmount: 1,
            ReserveId: -43,
            ReserveType: 'RC_01',
            SettleAmount: 0,
            StatusCode: 'CLOSED',
          },
          {
            BusinessObjectId: 518015,
            CurrencyCode: 'CNY',
            LockFlag: 'N',
            OutstandingAmount: -1,
            ReserveId: -44,
            ReserveType: 'RC_04',
            SettleAmount: 0,
            StatusCode: 'REOPEN',
          },
        ],
        SeqNo: '002',
        StatusCode: 'REOPEN',
        SubrogationStatus: 'REOPEN',
        SumInsured: 0,
      },
    ],
    Status: 'OK',
  },
  reserveHistory: {
    Model: [
      {
        '@pk': 1648713,
        ChangeType: '9',
        CurrencyCode: 'CNY',
        HisId: 1648713,
        NewStatus: 'REOPEN',
        OldStatus: 'REOPEN',
        OutstandingAmount: 11,
        OutstandingChanged: 10,
        ReserveType: 'RC_01',
        SettleAmount: -30,
        SettleChanged: -10,
      },
      {
        '@pk': 1648217,
        ChangeType: '2',
        CurrencyCode: 'CNY',
        HisId: 1648217,
        NewStatus: 'REOPEN',
        OldStatus: 'CLOSED',
        OutstandingAmount: -1,
        OutstandingChanged: -1,
        Remark: 'dfsfs',
        ReopenCause: '4',
        ReserveType: 'RC_04',
        SettleAmount: 0,
        SettleChanged: 0,
      },
      {
        '@pk': 1656199,
        ChangeType: '8',
        CloseType: '2',
        CurrencyCode: 'CNY',
        HisId: 1656199,
        NewStatus: 'CLOSED',
        OldStatus: 'REOPEN',
        OutstandingAmount: 0,
        OutstandingChanged: 11,
        RejectReason: '01',
        Remark: 'aaaaaaa',
        ReserveType: 'RC_04',
        SettleAmount: 0,
        SettleChanged: 0,
      },
    ],
    Status: 'OK',
  },

  openReserve: {
    Model: [
      {
        currencyCode: '',
        itemId: -31,
        outstandingAmount: 0,
        remark: '',
        reserveType: 'RC_05',
        statusCode: 'NULL',
      },
      {
        currencyCode: '',
        itemId: -31,
        outstandingAmount: 0,
        remark: '',
        reserveType: 'RC_06',
        statusCode: 'NULL',
      },
      {
        currencyCode: '',
        itemId: -31,
        outstandingAmount: 0,
        remark: '',
        reserveType: 'RC_07',
        statusCode: 'NULL',
      },
    ],
    Status: 'OK',
  },

  reserveType: {
    Model: -1,
    Status: 'OK',
  },
};
