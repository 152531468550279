import { AjaxUtil } from 'RainbowUI';
import ClaimCalculationExt from 'ClaimCalculationExt';
import Url from '../url/Url';

const CalculationMockData = require('../mock/CalculationMockData');

export default {
  loadCalculationTask(taskInstanceId) {
    const deferred = $.Deferred();
    if (env.IS_MOCK) {
      deferred.resolve(CalculationMockData.CalculationData);
      return deferred.promise();
    }
    AjaxUtil.doGet(Url.CalculationTaskLoad + taskInstanceId, null, {
      async: false,
      done(data) {
        if (data.Status !== 'OK') {
          deferred.reject(data);
        } else {
          deferred.resolve(data.Model);
        }
      },
      fail(jqXHR) {
        deferred.reject({
          status: jqXHR.status,
          responseText: jqXHR.responseText,
        });
      },
    });
    return deferred.promise();
  },

  loadCalculationReadOnly(calculateId) {
    const deferred = $.Deferred();
    if (env.IS_MOCK) {
      deferred.resolve(CalculationMockData.CalculationData);
      return deferred.promise();
    }
    AjaxUtil.doGet(Url.CalculationReadOnlyLoad + calculateId, null, {
      async: true,
      done(data) {
        if (data.Status !== 'OK') {
          deferred.reject(data);
        } else {
          deferred.resolve(data.Model);
        }
      },
      fail(jqXHR) {
        deferred.reject({
          status: jqXHR.status,
          responseText: jqXHR.responseText,
        });
      },
    });
    return deferred.promise();
  },

  loadCalculationHistory(caseId) {
    const deferred = $.Deferred();
    if (env.IS_MOCK) {
      deferred.resolve(CalculationMockData.CalculationHistory);
      return deferred.promise();
    }
    AjaxUtil.doGet(Url.CalculationHistoryLoad + caseId, null, {
      async: true,
      done(data) {
        if (data.Status !== 'OK') {
          deferred.reject(data);
        } else {
          deferred.resolve(data.Model);
        }
      },
      fail(jqXHR) {
        deferred.reject({
          status: jqXHR.status,
          responseText: jqXHR.responseText,
        });
      },
    });
    return deferred.promise();
  },

  loadCalculationApproveTask(taskInstanceId) {
    const defer = $.Deferred();
    AjaxUtil.doGet(
      Url.CalculationApproveLoad + taskInstanceId,
      null,
      {
        async: true,
        done(data) {
          if (data.Status !== 'OK') {
            defer.reject(data);
          } else {
            defer.resolve(data.Model);
          }
        },
        fail(jqXHR) {
          defer.reject({
            status: jqXHR.status,
            responseText: jqXHR.responseText,
          });
        },
      }
    );
    return defer.promise();
  },

  loadLimitDialogData(insuredId, coverageCode) {
    const defer = $.Deferred();
    if (env.IS_MOCK) {
      defer.resolve(CalculationMockData.LimitAndDeductInformation);
      return defer.promise();
    }
    AjaxUtil.doGet(
      `${Url.LimitAndDeductionInfo + insuredId}/${coverageCode}`,
      null,
      {
        async: false,
        done(data) {
          if (data.Status !== 'OK') {
            defer.reject(data);
          } else {
            defer.resolve(data.Model);
          }
        },
        fail(jqXHR) {
          defer.reject({
            status: jqXHR.status,
            responseText: jqXHR.responseText,
          });
        },
      }
    );
    return defer.promise();
  },

  saveCalculation(calculationData) {
    const defer = $.Deferred();
    AjaxUtil.doPost(
      Url.CalculationSave,
      {
        CalculationEntity: calculationData.CalculationInfo,
        TaskInstanceId: calculationData.TaskInstanceId,
      },
      {
        async: false,
        done(data) {
          if (data.Status !== 'OK') {
            defer.reject(data);
          } else {
            defer.resolve(data.Model);
          }
        },
        fail(jqXHR) {
          defer.reject({
            status: jqXHR.status,
            responseText: jqXHR.responseText,
          });
        },
      }
    );
    return defer.promise();
  },

  submitCalculation(calculationData) {
    let result = null;
    const CalculationServiceProcess = ClaimCalculationExt.getCalculationServiceProcess();
    const submitUrl = CalculationServiceProcess.getClaimCalculationSubmitUrl();

    AjaxUtil.doPost(
      submitUrl,
      {
        CalculationEntity: calculationData.CalculationInfo,
        TaskInstanceId: calculationData.TaskInstanceId,
      },
      {
        async: false,
        done(data) {
          if (data.Status === 'OK') {
            result = data.Status;
          } else {
            result = data;
          }
        },
        fail(jqXHR) {
          result = jqXHR.responseText;
        },
      }
    );
    return result;
  },

  approveCalculation(approveInfo) {
    const defer = $.Deferred();
    AjaxUtil.doPost(Url.CalculationApprove, approveInfo, {
      async: false,
      done(data) {
        if (data.Status === 'OK') {
          defer.resolve(data);
        } else {
          defer.reject({
            status: data.Status,
            responseText: data.Messages[0].Message,
          });
        }
      },
      fail(jqXHR) {
        defer.reject({
          status: jqXHR.status,
          responseText: jqXHR.responseText,
        });
      },
    });
    return defer.promise();
  },

  calculate(calculationData) {
    const defer = $.Deferred();
    AjaxUtil.doPost(
      Url.CalculationCalculate,
      {
        CalculationEntity: calculationData.CalculationInfo,
        TaskInstanceId: calculationData.TaskInstanceId,
      },
      {
        async: true,
        done(data) {
          if (data.Status !== 'OK') {
            defer.reject(data);
          } else {
            defer.resolve(data.Model);
          }
        },
        fail(jqXHR) {
          defer.reject({
            status: jqXHR.status,
            responseText: jqXHR.responseText,
          });
        },
      }
    );
    return defer.promise();
  },
};
