import { UIDataTable, UIColumn } from 'RainbowUI';

const ProductLineListAction = require('../action/ProductLineListAction');

const ProductLineList = React.createClass({
  getProductLineList(pageindex, pagesize) {
    const productLineList = ProductLineListAction.getProductLineList(
      pageindex,
      pagesize
    );
    return productLineList;
  },

  render() {
    return (
      <UIDataTable
        indexable="false"
        provider={this.getProductLineList}
        id="productLineListId"
      >
        <UIColumn
          headerTitle={i18n.ClaimProduct.ProductLineCode}
          value="ProductLineCode"
          width="20%"
        />
        <UIColumn
          headerTitle={i18n.ClaimProduct.ProductLineName}
          value="ProductLineName"
          width="30%"
        />
        <UIColumn
          headerTitle={i18n.ClaimProduct.ProductLineDescription}
          value="ProductLineDesc"
          width="50%"
        />
      </UIDataTable>
    );
  },
});
export default ProductLineList;
