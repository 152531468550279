import alt from '../alt';

const SalvageLoadAction = require('../action/SalvageLoadAction');
const NewSalvageVO = require('../action/vo/NewSalvageVO');

const SalvageSubmitAction = require('../action/SalvageSubmitAction');

class SalvageStore {
  constructor() {
    this.SalvageData = {
      SalvageInfo: {
        SalvageItem: [],
      },
      OtherInfo: {
        CoverageTree: [],
        CoverageStructure: [{ CoverageList: [] }],
        OriginalCoverageStructure: [{ CoverageList: [] }],
      },
      TaskInstanceId: null,
    };
    this.SelectedLength = 0;
    this.OriginalSalvageItem = [];
    this.bindListeners({
      handleLoadSalvageTask: SalvageLoadAction.LOAD_SALVAGE_TASK,
      handleChooseCoverage: SalvageLoadAction.CHOOSE_COVERAGE,
      salvageAmountChange: SalvageLoadAction.SALVAGE_AMOUNT_CHANGE,
      remarkForSalvageChange:
        SalvageLoadAction.REMARK_FOR_SALVAGE_CHANGE,
      recoverFromChange: SalvageLoadAction.RECOVER_FROM_CHANGE,
      handleAddItem: SalvageLoadAction.ADD_ITEM,
      handleDeleteItem: SalvageLoadAction.DELETE_ITEM,
      handleSaveSalvageTask: SalvageSubmitAction.SAVE_SALVAGE,
    });
  }

  handleLoadSalvageTask(salvageData) {
    this.SelectedLength = 0;
    this.SalvageData.OtherInfo.CoverageStructure =
      salvageData.CoverageStructure;
    this.SalvageData.OtherInfo.OriginalCoverageStructure =
      salvageData.CoverageStructure;
    this.SalvageData.OtherInfo.CoverageTree =
      salvageData.CoverageTree;

    this.SalvageData.SalvageInfo = salvageData.SalvageInfo;

    this.SalvageData.TaskInstanceId = salvageData.TaskInstanceId;
    if (!this.SalvageData.SalvageInfo.SalvageItem) {
      this.SalvageData.SalvageInfo.SalvageItem = [];
    }

    if (salvageData.CoverageTree) {
      for (let i = 0; i < salvageData.CoverageTree.length; i += 1) {
        if (
          salvageData.CoverageTree[i].pId != '0' &&
          salvageData.CoverageTree[i].checked == true
        ) {
          this.SelectedLength = this.SelectedLength + 1;
        }
      }
    }
  }

  handleChooseCoverage(data) {
    const coverageStructure = new Array();

    let items = [];

    if (data[0] !== '' && data[0].SalvageInfo.SalvageItem) {
      items = data[0].SalvageInfo.SalvageItem;
    }

    const checkedList = data[1];

    const currencyCode = this.SalvageData.SalvageInfo.CaseCurrency;

    const updatedItems = new Array();
    const coverageTrees = this.SalvageData.OtherInfo.CoverageTree;
    const storeCoverageStructure = this.SalvageData.OtherInfo
      .OriginalCoverageStructure;

    let selectedLength = 0;

    for (let i = 0; i < checkedList.length; i += 1) {
      const checked = checkedList[i];
      if (checked.isParent == true) {
        const coverageArray = new Array();
        const insuredElement = {
          ObjectId: checked.id,
          SubclaimName: checked.name,
          CoverageList: [],
        };
        const coverageList = checked.children;
        for (let j = 0; j < coverageList.length; j += 1) {
          const coverage = coverageList[j];

          if (coverage.checked == true) {
            let exists = false;

            for (let k = 0; k < items.length; k += 1) {
              if (
                items[k].ObjectId == checked.id &&
                items[k].CoverageCode == coverage.id
              ) {
                exists = true;
                updatedItems.push(items[k]);
                coverageArray.push({
                  ItemId: items[k].ItemId,
                  CoverageCode: coverage.id,
                  CoverageName: coverage.name,
                });
              }
            }
            if (exists == false) {
              const itemToAdd = NewSalvageVO.salvageItemToAdd;

              for (
                let l = 0;
                l < storeCoverageStructure.length;
                l += 1
              ) {
                if (
                  storeCoverageStructure[l].ObjectId == checked.id
                ) {
                  for (
                    let m = 0;
                    m < storeCoverageStructure[l].CoverageList.length;
                    m += 1
                  ) {
                    if (
                      storeCoverageStructure[l].CoverageList[m]
                        .CoverageCode == coverage.id
                    ) {
                      const itemId =
                        storeCoverageStructure[l].CoverageList[m]
                          .ItemId;
                      itemToAdd.initial(
                        checked.id,
                        itemId,
                        coverage.id,
                        currencyCode,
                        updatedItems.length + 1
                      );
                      updatedItems.push(itemToAdd.get());
                      coverageArray.push({
                        ItemId: itemId,
                        CoverageCode: coverage.id,
                        CoverageName: coverage.name,
                      });
                    }
                  }
                }
              }
            }
            selectedLength += 1;
          }
        }

        insuredElement.CoverageList = this.uniqueArr(coverageArray);

        coverageStructure.push(insuredElement);
      }
    }
    this.SelectedLength = selectedLength;

    for (let k = 0; k < coverageTrees.length; k += 1) {
      coverageTrees[k].checked = false;
      for (let i = 0; i < checkedList.length; i += 1) {
        if (
          (coverageTrees[k].id == checkedList[i].id &&
            coverageTrees[k].pId == '0') ||
          (coverageTrees[k].pId == checkedList[i].pId &&
            coverageTrees[k].id == checkedList[i].id)
        ) {
          coverageTrees[k].checked = true;
          break;
        }
      }
    }
    this.SalvageData.SalvageInfo.SalvageItem = updatedItems;
    this.SalvageData.OtherInfo.CoverageStructure = coverageStructure;
  }

  uniqueArr(arr) {
    const result = [];
    let isRepeated;
    for (let i = 0; i < arr.length; i += 1) {
      isRepeated = false;
      for (let j = 0; j < result.length; j += 1) {
        if (
          arr[i].ItemId == result[j].ItemId &&
          arr[i].CoverageCode == result[j].CoverageCode
        ) {
          isRepeated = true;
          break;
        }
      }
      if (!isRepeated) {
        result.push(arr[i]);
      }
    }
    return result;
  }

  handleAddItem(itemToAdd) {
    const items = this.SalvageData.SalvageInfo.SalvageItem;
    itemToAdd.setIndex(items.length + 1);
    items.push(itemToAdd.get());
  }

  handleDeleteItem(data) {
    const items = this.SalvageData.SalvageInfo.SalvageItem;
    if (data.Length > 1) {
      let deleteIndex = null;
      for (let i = 0; i < items.length; i += 1) {
        const temp = items[i];
        if (temp.dataIndex == data.Index) {
          deleteIndex = temp.dataIndex;
          items.splice(i, 1);
          break;
        }
      }
      const filtered = items.map(value => {
        const val = value;
        if (val.dataIndex > deleteIndex) {
          val.dataIndex -= 1;
        }
        return val;
      });

      this.SalvageData.SalvageInfo.SalvageItem = filtered;
    } else {
      for (let i = 0; i < items.length; i += 1) {
        const temp = items[i];
        if (temp.dataIndex == data.Index) {
          temp.Remark = '';
          temp.RecoverFrom = '';
          temp.CurrencyCode = '';
          temp.ItemId = '';
          temp.SalvageAmount = null;
          break;
        }
      }
    }
  }

  salvageAmountChange(data) {
    const items = this.SalvageData.SalvageInfo.SalvageItem;
    for (let i = 0; i < items.length; i += 1) {
      const temp = items[i];
      if (temp.dataIndex == data.Index) {
        items[i].SalvageAmount = data.SalvageAmount;
        break;
      }
    }
  }

  recoverFromChange(data) {
    const items = this.SalvageData.SalvageInfo.SalvageItem;
    for (let i = 0; i < items.length; i += 1) {
      const temp = items[i];
      if (temp.dataIndex == data.Index) {
        items[i].RecoverFrom = data.RecoverFrom;
        break;
      }
    }
  }

  remarkForSalvageChange(data) {
    const items = this.SalvageData.SalvageInfo.SalvageItem;
    for (let i = 0; i < items.length; i += 1) {
      const temp = items[i];
      if (temp.dataIndex == data.Index) {
        items[i].Remark = data.Remark;
        break;
      }
    }
  }

  handleSaveSalvageTask(salvageData) {
    this.SalvageData.OtherInfo.CoverageStructure =
      salvageData.CoverageStructure;
    this.SalvageData.OtherInfo.CoverageTree =
      salvageData.CoverageTree;

    this.SalvageData.SalvageInfo = salvageData.SalvageInfo;

    if (!this.SalvageData.SalvageInfo.SalvageItem) {
      this.SalvageData.SalvageInfo.SalvageItem = [];
    }
  }
}
export default alt.createStore(SalvageStore, SalvageStore);
