import {
  UIDataTable,
  UIColumn,
  UILink,
  UISelect,
  UIParam,
  UIDateTimePicker,
} from 'RainbowUI';
import BindToMixin from 'react-binding';
import { CodeTableAction, CodeTableCstUI } from 'ClaimCommonConstant';
import NoticePoolStore from '../store/NoticePoolStore';

const NoticePoolAction = require('../action/NoticePoolAction');

const NoticePoolResult = React.createClass({
  mixins: [BindToMixin],

  getInitialState() {
    return {
      noticeEntityCriteria: { '@type': 'ClaimNotice-ClaimNotice' },
    };
  },

  componentWillMount() {
    NoticePoolStore.listen(this.onStoreStateChange);
  },

  componentWillUnmount() {
    NoticePoolStore.unlisten(this.onStoreStateChange);
  },

  onStoreStateChange(storeState) {
    this.setState({
      noticeEntityCriteria: storeState.noticeSearchOptions,
    });
  },

  getNoticeList(pageIndex, pageSize) {
    return NoticePoolAction.getNoticeList(
      pageIndex,
      pageSize,
      this.state.noticeEntityCriteria
    );
  },

  render() {
    return (
      <UIDataTable
        id="noticeSearchResult"
        provider={this.getNoticeList}
        headerTitle={i18n.ClaimNotice.ClaimNoticePoolData}
        indexable="false"
        pageable="true"
        isHeading="true"
      >
        <UIColumn
          headerTitle={i18n.ClaimNotice.NoticeNumber}
          sortable="true"
          sortValue="NoticeNo"
          render={data => {
            const noticeId = data.NoticeId;
            return (
              <UILink
                value={data.NoticeNo}
                onClick={NoticePoolAction.editNotice}
              >
                <UIParam name="noticeId" value={noticeId} />
              </UILink>
            );
          }}
        />
        <UIColumn
          headerTitle={i18n.ClaimCommon.PolicyNumber}
          value="PolicyNo"
          sortable="true"
        />
        <UIColumn
          headerTitle={i18n.ClaimCommon.ProductName}
          value="ProductCode"
          sortable="true"
        >
          <UISelect
            io="out"
            codeTable={CodeTableAction.loadDataList(
              CodeTableCstUI.ClaimProduct,
              null
            )}
          />
        </UIColumn>
        <UIColumn
          headerTitle={i18n.ClaimCommon.PolicyHolderName}
          value="PolicyHolderName"
          sortable="true"
        />
        <UIColumn
          headerTitle={i18n.ClaimCommon.DateOfLoss}
          value="AccidentTime"
          sortable="true"
          width="15%"
        >
          <UIDateTimePicker
            io="out"
            placeHolder="MM/DD/YYYY"
            format="MM/DD/YYYY"
          />
        </UIColumn>
        <UIColumn
          headerTitle={i18n.ClaimCommon.ContactPerson}
          value="ContactPerson"
          sortable="true"
        />
        <UIColumn
          headerTitle={i18n.ClaimNotice.OperationBy}
          value="OperationBy"
          sortable="true"
        />
      </UIDataTable>
    );
  },
});
export default NoticePoolResult;
