import {
  UIUpdatePanel,
  UIDataTable,
  UICell,
  UIColumn,
  UILink,
  UIParam,
  UIBox,
  UIButton,
  DataContext,
} from 'RainbowUI';

const LossCauseAction = require('../action/LossCauseAction');
const LossCauseDialog = require('./LossCauseDialog');
const RemoveLossCauseWarning = require('./RemoveLossCauseWarning');
const RemoveProductLineConfirmDialog = require('./RemoveProductLineConfirmDialog');

const LossCauseList = React.createClass({
  getInitialState() {
    return { count: 0 };
  },

  getLossCauseList(pageindex, pagesize) {
    const lossCauseList = LossCauseAction.getLossCauseList(
      pageindex,
      pagesize
    );

    return lossCauseList;
  },

  showLossCauseDetail(event) {
    let data = event.getParameter('data');
    const flag = {};
    if (!data) {
      data = {};
      flag.out = 'in';
    } else {
      flag.out = 'out';
    }
    this.refs.LossCauseDialog_ref.showDialog(data, flag);
  },

  updatePage() {
    this.setState(prevState => ({ count: prevState.count + 1 }));
  },

  removeLossCauseConfirmDialog(event) {
    const data = event.getParameter('data');

    data.removeType = 'losscause';
    DataContext.put('data', data);
    this.refs.RemoveProductLineConfirmDialog_ref.showDialog(data);
  },

  render() {
    self = this;
    return (
      <div>
        <UIUpdatePanel id="CauseLossListSearchResultProvider">
          <UIDataTable
            indexable="false"
            id="causeLossList"
            provider={this.getLossCauseList}
          >
            <UIColumn
              headerTitle={i18n.ClaimProduct.CauseofLossCode}
              width="15%"
              render={data => {
                return (
                  <u>
                    <UILink
                      value={data.LossCauseCode}
                      onClick={self.showLossCauseDetail}
                    >
                      <UIParam name="data" value={data} />
                    </UILink>
                  </u>
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.ClaimProduct.CauseofLossName}
              width="20%"
              value="LossCauseName"
            />
            <UIColumn
              headerTitle={i18n.ClaimProduct.CauseofLossDescription}
              width="50%"
              value="LossCauseDescription"
            />
            <UIColumn
              headerTitle={i18n.ClaimProduct.Action}
              width="15%"
              render={data => {
                return (
                  <UILink
                    value={i18n.ClaimProduct.Remove}
                    icon="fa fa-trash"
                    onClick={self.removeLossCauseConfirmDialog}
                  >
                    <UIParam value={data} name="data" />
                  </UILink>
                );
              }}
            />
          </UIDataTable>
        </UIUpdatePanel>
        <UICell>
          <UIBox direction="left">
            <UIButton
              value={i18n.ClaimProduct.New}
              onClick={self.showLossCauseDetail}
            />
          </UIBox>
        </UICell>
        <LossCauseDialog
          ref="LossCauseDialog_ref"
          parentComponet={this}
        />
        <RemoveLossCauseWarning />
        <RemoveProductLineConfirmDialog
          ref="RemoveProductLineConfirmDialog_ref"
          parentComponet={this}
        />
      </div>
    );
  },
});
export default LossCauseList;
