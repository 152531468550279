import {
  UICell,
  UIDataTable,
  UISmartPanelGrid,
  UIColumn,
  UIUpdatePanel,
  UIText,
  UILink,
  UIParam,
} from 'RainbowUI';
import PropTypes from 'prop-types';

const CauseOfLossRelationAction = require('../../action/CauseOfLossRelationAction');

const ClaimLossCauseRelationProductLine = React.createClass({
  propTypes: {
    entity: PropTypes.object,
  },
  getData(pageindex, pagesize) {
    const CalimCauseOfLossRelationList = CauseOfLossRelationAction.getCauseOfLossTypeRelation(
      pageindex,
      pagesize
    );
    return CalimCauseOfLossRelationList;
  },
  render() {
    const _self = this;
    return (
      <div>
        <UIUpdatePanel id="causeofLossAndSubclaimTypeListSearchResultProvider">
          <UIDataTable
            id="causeofLossAndSubclaimTypeList"
            indexable="false"
            provider={this.getData}
          >
            <UIColumn
              headerTitle={i18n.ClaimProduct.CauseOfLoss}
              render={data => {
                console.log(`column data==${JSON.stringify(data)}`);
                const tempData = `${data.Object.LossCauseCode}:${data.Object.LossCauseName}`;
                return (
                  <UILink
                    value={tempData}
                    onClick={
                      CauseOfLossRelationAction.openCauseOfLossRelation
                    }
                  >
                    <UIParam
                      name="lossCauseCode"
                      value={data.Object.LossCauseCode}
                    />
                    <UIParam
                      name="lossCauseName"
                      value={data.Object.LossCauseName}
                    />
                    <UIParam
                      name="productLineCode"
                      value={data.Object.ProductLineCode}
                    />
                    <UIParam
                      name="nodeName"
                      value={_self.props.entity.name}
                    />
                  </UILink>
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.ClaimProduct.SubClmTypeDescription}
              render={data => {
                return this.getDetail(data.SelectedList);
              }}
            />
          </UIDataTable>
        </UIUpdatePanel>
      </div>
    );
  },
  getDetail(changeDataList) {
    if (changeDataList) {
      const result = changeDataList.map(data => {
        return (
          <UIText
            value={`${data.Desc.SubclaimTypeCode}:${data.Desc.SubclaimTypeName}`}
            io="out"
          />
        );
      });

      return (
        <UISmartPanelGrid column="1">
          <UICell>{result}</UICell>
        </UISmartPanelGrid>
      );
    }
  },
});
export default ClaimLossCauseRelationProductLine;
