import { AjaxUtil, DataContext } from 'RainbowUI';
import Url from '../url/Url';
import TreeNode from '../constant/TreeNodeCache';

const Q = require('q');

export default {
  loadProductType(data) {
    const defer = Q.defer();
    AjaxUtil.doGet(
      `${Url.ClaimProductTypeGetProductTypeDetail}/${data}`,
      {},
      {
        async: false,
        done(data, textStatus, jqXHR) {
          if (data) {
            defer.resolve(data);
          } else {
            defer.reject({
              status: jqXHR.status,
              responseText: jqXHR.responseText,
            });
          }
        },
        fail(jqXHR) {
          defer.reject({
            status: jqXHR.status,
            responseText: jqXHR.responseText,
          });
        },
      }
    );
    return defer.promise;
  },
  saveProductType(tempProductTypeEntity) {
    const ProductTypeEntity = tempProductTypeEntity;
    const data = DataContext.get('showProductType');
    let urlSaveOrUpdate = Url.ClaimProductTypeSave;
    if (data.out !== 'in') {
      urlSaveOrUpdate = Url.ClaimProductTypeUpdateProductType;
      delete ProductTypeEntity.InsertBy;
      delete ProductTypeEntity.InsertTime;
      delete ProductTypeEntity.UpdateBy;
      delete ProductTypeEntity.UpdateTime;
    } else {
      ProductTypeEntity.ProductLineCode = TreeNode.TreeCache.get(
        'TreeNode'
      ).treeNodeCode;
    }
    delete ProductTypeEntity.ClaimProductEntitys;
    const defer = $.Deferred();
    AjaxUtil.doPost(urlSaveOrUpdate, ProductTypeEntity, {
      async: false,
      done(data, textStatus, jqXHR) {
        if (data) {
          defer.resolve(data);
        } else {
          defer.reject({
            status: jqXHR.status,
            responseText: jqXHR.responseText,
          });
        }
      },
      fail(jqXHR) {
        defer.reject({
          status: jqXHR.status,
          responseText: jqXHR.responseText,
        });
      },
    });
    return defer.promise();
  },
};
