/**
 * Created by jie.huang on 9/20/2017.
 */
import { UIPage, UIPanel, UIUpdatePanel } from 'RainbowUI';
import PtyIntegrationTabInfo from './PtyIntegrationTabInfo';

const PtyIntegrationBaseInfo = React.createClass({
  getInitialState() {
    const { routeName } = this.props.location.query;
    return { routeName };
  },
  render() {
    const { params } = this.props;
    const { routeName } = this.state;
    return (
      <UIPage>
        <UIPanel
          style={{ margin: '0 0 0 0' }}
          panelTitle={i18n.PartyMaintenance}
        >
          <UIUpdatePanel id="updateBasicInfo">
            <PtyIntegrationTabInfo
              partyId={params.partyId}
              view="true"
              routeName={routeName}
            />
          </UIUpdatePanel>
        </UIPanel>
      </UIPage>
    );
  },
});
export default PtyIntegrationBaseInfo;
