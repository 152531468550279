/**
 * Created by jack.li on 10/25/2017.
 */
import { UIPage, UIPanel, UIUpdatePanel } from 'RainbowUI';
import PtyIntegrationTabInfoReadOnly from './PtyIntegrationTabInfoReadOnly';

const PtyIntegrationBaseInfoReadOnly = React.createClass({
  render() {
    const { params } = this.props;
    return (
      <UIPage>
        <UIPanel
          style={{ margin: '0 0 0 0' }}
          panelTitle={i18n.PartyMaintenance}
        >
          <UIUpdatePanel id="updateBasicInfo">
            <PtyIntegrationTabInfoReadOnly
              partyId={params.partyId}
              view="true"
            />
          </UIUpdatePanel>
        </UIPanel>
      </UIPage>
    );
  },
});
export default PtyIntegrationBaseInfoReadOnly;
