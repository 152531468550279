const urlDefine = {
  NoticeCreate: `${env.serverUrl}notice/creation`,
  NoticeUpdate: `${env.serverUrl}notice/updation`,
  NoticeLoad: `${env.serverUrl}notice/`,
  NoticeVersionUpdate: `${env.serverUrl}notice/version/`,
  NoticeLoadList: `${env.serverUrl}notice/List`,
  ProductTree: `${env.serverUrl}productTree/productLineTree`,
  BranchQuery: `${env.serverUrl}public/branch/query`,  
  NoticeCurrentUserInformation: `${env.serverUrl}system/currentUser`,
  RtaLoadAccident: `${env.serverUrl}eu00/rta/accident/loadAccident/`,
  queryRegionByCountryCodeAndRegionName: `${env.serverUrl}party/region/region/byCountryCodeAndRegionName`,
  IsMotor: `${env.serverUrl}productLine/isMotorByProductTypeCode`,
};
export default urlDefine;
