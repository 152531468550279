import {
  UIPage,
  UILayout,
  UIText,
  UISmartPanelGrid,
  UILayoutUnit,
  UIPanel,
  UICell,
  UISelect,
} from 'RainbowUI';
import ClaimCommonExt from 'ClaimCommonExt';
import ClaimAuthorityTree from './ClaimAuthorityTree';
import ExitButton from '../../button/ExitButton';
import ClaimAuthorityAction from '../../action/ClaimAuthorityAction';


const ClaimAuthorityPanel = ClaimCommonExt.getClaimAuthorityPanel();
const AuthorityConfiguration = React.createClass({
  getInitialState() {
    return {
      permissionCodetable: ClaimAuthorityAction.getPermissionCodeTable(),
    };
  },

  componentDidMount() {
  },

  onChangeTreeNodeStatus(event, treeId, treeNode) {
    this.refs.tree_ref.onChangeTreeNodeStatus(
      event,
      treeId,
      treeNode
    );
  },

  permissionOnChange(event) {
    ClaimAuthorityAction.clearData();
    let permissionCode = event.newValue;
    this.setState({ permissionCode: event.newValue });
  },

  render() {
    const self = this;
    const {
      permissionCode,
      permissionCodetable,
    } = this.state;
    return (
      <UIPage>
        <UIPanel panelTitle={i18n.ClaimCommon.claimAuthority}>
          <UISmartPanelGrid>
            <UISelect
              label={i18n.ClaimCommon.permissionName}
              codeTable={permissionCodetable}
              onChange={this.permissionOnChange}
            />
          </UISmartPanelGrid>

          <UICell id="authorityConfiguration" style={{ padding: '0px' }}>
            <div style={{ width: '25%', float: 'left', height: '100%', overflow: 'auto' }}>
              <UIPanel
                panelTitle={i18n.ClaimCommon.applicableProducts}
              >
                <ClaimAuthorityTree
                  parentComponent={self}
                  permissionCode={permissionCode}
                  ref="tree_ref"
                />
              </UIPanel>
            </div>
            <div style={{ width: '74%', float: 'right', height: '100%', overflow: 'auto' }}>
              <UIPanel panelTitle={i18n.ClaimCommon.taskAuthority}>
                <ClaimAuthorityPanel
                  permissionCode={permissionCode}
                />
              </UIPanel>
            </div>
          </UICell>
        </UIPanel>
      </UIPage>
    );
  },
});
export default AuthorityConfiguration;
