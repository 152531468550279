export default {
  Notice: '通知 ',
  ClaimNoticePool: '検索条件',
  ClaimNoticePoolData: 'ペンディング通知',
  NoticeNumber: '通知番号',
  DateOfLossFromTo: '事故日 from/to',
  DateReported: '被保険者が報告された日付 ',

  AccidentDescription: '事故詳細',
  DamageInfo: '損害情報',
  DamageDescription: '損害詳細',
  DamageAddressDetail: '住所詳細',
  NoDamageError: '損害発生日を追加してください。',
  NoClaimantError: '請求権利者選択してください。',
};
