import { MessageHelper } from 'RainbowUI';

const SettlementHistoryService = require('../service/SettlementHistoryService');

export default class SettlementHistoryActionNoDispatch {
  getPayModeBySettlePayeeId(SettlePayeeId) {
    let payMode = null;
    SettlementHistoryService.getPayModeBySettlePayeeId(
      SettlePayeeId
    ).then(data => {
      if (data.Status === 'OK') {
        payMode = data.Model;
      } else {
        MessageHelper.error(data.Messages[0].Message, '');
      }
    });
    return payMode;
  }
}
