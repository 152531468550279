import {
  DataContext,
  UpdateContext,
  AjaxUtil,
  MessageHelper,
} from 'RainbowUI';
import {
  RestStatus,
  ClaimMessageForamtUtils,
} from 'ClaimCommonConstant';
import Url from '../url/Url';
import TreeNode from '../constant/TreeNodeCache';

export default {
  getCoverageCategoryList(pageindex, pagesize) {
    const data = TreeNode.TreeCache.get('TreeNode');
    const productLineCodeJS = {
      productLineCode: data.treeNodeCode,
    };
    const searchCondition = {};
    searchCondition.ProductLineCode =
      productLineCodeJS.productLineCode;
    searchCondition.PageNo = pageindex - 1;
    searchCondition.PageSize = pagesize;
    const url =
      Url.ClaimCoverageTypeOfProductLineLoadByCoverageCategoryCode;
    if (DataContext.get('searchCoverageCategoryCondition')) {
      const condition = DataContext.get(
        'searchCoverageCategoryCondition'
      );
      if (condition.coverageCategoryCode) {
        searchCondition.CoverageCategoryCode = condition.coverageCategoryCode
          .replace(/(^\s*)|(\s*$)/g, '')
          .toUpperCase();
      }
    }
    let result = {};
    AjaxUtil.doPost(url, searchCondition, {
      async: false,
      done(data) {
        result = data.Model;
      },
      fail() {
        MessageHelper.error(
          i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
        );
      },
    });

    return { count: result.Total, result: result.Content };
  },

  saveCoverageType(newdata) {
    let result = {};

    const newCoverageType = newdata;
    let url = Url.ClaimCoverageTypeOfProductLineUpdate;
    if (!newCoverageType.ProductLineCode) {
      const data = TreeNode.TreeCache.get('TreeNode');
      newCoverageType.ProductLineCode = data.treeNodeCode;
      url = Url.ClaimCoverageTypeOfProductLineSave;
    }
    result = 'false';
    AjaxUtil.doPost(url, newCoverageType, {
      async: false,
      done(data) {
        if (data.Status == RestStatus.OK) {
          MessageHelper.success(
            ClaimMessageForamtUtils.formatI18NMsg(
              i18n.ClaimCommon.SaveSuccess,
              i18n.ClaimProduct.CoverageCategory
            )
          );
          result = 'true';
        } else if (data.Status == RestStatus.BLOCK) {
          newCoverageType.ProductLineCode = null;
          MessageHelper.error(
            ClaimMessageForamtUtils.formatI18NMsg(
              i18n.ClaimCommon.IsExist,
              i18n.ClaimProduct.CoverageCategory
            )
          );
        } else if (data == 0) {
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
        }
      },
      fail() {
        MessageHelper.error(
          i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
        );
      },
    });
    return result;
  },

  removeCoverageCategory(data) {
    var data = data;
    const coverageTypeCode = {
      CoverageTypeCode: data.CoverageTypeCode,
    };

    AjaxUtil.doPost(
      Url.ClaimCoverageTypeOfProductLineRemove +
        coverageTypeCode.CoverageTypeCode,
      {},
      {
        async: false,
        done(data) {
          if (data.Status == RestStatus.BLOCK) {
            MessageHelper.error(
              i18n.ClaimProduct.removeProductFailMessage
            );
          } else if (data.Status == RestStatus.OK) {
            MessageHelper.success(
              ClaimMessageForamtUtils.formatI18NMsg(
                i18n.ClaimCommon.DeleteSuccess,
                i18n.ClaimProduct.CoverageCategory
              )
            );
          }
        },
        fail() {
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
        },
      }
    );
  },

  searchCoverageCategoryOfProductLinePage(condition) {
    DataContext.put('searchCoverageCategoryCondition', condition);
    UpdateContext.forceUpdate(
      'CoverageCategoryOfProductLinePageSearchResultProvider'
    );
  },
};
