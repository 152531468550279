import { AjaxUtil, MessageHelper } from 'RainbowUI';
import Url from '../../url/Url';
import alt from '../../alt';
import SearchTaskMessage from '../SearchTaskMessage';

class ClaimWorkFlowMyClaimAction {
  loadClaimList(pageindex, pagesize, tmpmyClaimVO) {
    const myClaimVO = tmpmyClaimVO;
    if (env.IS_MOCK) {
      this.getMyClaimList();
      return;
    }
    const pageNo = (pageindex - 1) / pagesize + 1;

    const localUrl = Url.ClaimWorkFlowLoadMyClaim;
    let result = null;
    myClaimVO.PageSize = pagesize;
    myClaimVO.PageIndex = pageNo;

    delete myClaimVO.flag;

    AjaxUtil.doPost(localUrl, myClaimVO, {
      async: false,
      done(data) {
        if (data.Status === 'OK') {
          result = data.Model;
        } else {
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
        }
      },
      fail() {
        MessageHelper.error(
          i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
        );
      },
    });

    const resultObj = { count: result.Total, result: result.Module };
    const searchTaskMessage = new SearchTaskMessage(resultObj);
    searchTaskMessage.showMessage();
    return resultObj;
  }

  searchTask(condition) {
    this.dispatch(condition);
  }
}
export default alt.createActions(ClaimWorkFlowMyClaimAction);
