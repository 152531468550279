import {
  UIPanel,
  UISmartPanelGrid,
  UIParam,
  UILink,
  UIRadio,
} from 'RainbowUI';
import { NoticePoolAction } from 'ClaimNoticeConstant';
import CodeTableCstUI from '../../constant/CodeTableCstUI';
import CodeTableAction from '../../action/CodeTableAction';

const ClaimWorkFlowLeftAction = require('../../action/desktop/ClaimWorkFlowLeftAction');

const ClaimWorkFlowLeft = React.createClass({
  getInitialState() {
    const date_to = this.format(new ServerDate(), 'yyyy-MM-dd', 1);
    const pendingTaskCount = ClaimWorkFlowLeftAction.pendingTaskCount();
    const sharingPoolCount = ClaimWorkFlowLeftAction.sharingPoolCount();
    const pendingNoticeCount = ClaimWorkFlowLeftAction.pendingNoticeCount();
    const myClaimCount = ClaimWorkFlowLeftAction.myClaimCount();
    const newMessageCount = ClaimWorkFlowLeftAction.newMessageCount(
      date_to
    );
    return {
      pendingTask: pendingTaskCount,
      sharingPool: sharingPoolCount,
      pendingNoticeCount,
      myClaimCount,
      newMessageCount,
    };
  },
  format(date, tmpfmt, status) {
    let fmt = tmpfmt;
    const o = {
      'M+': date.getMonth() + 1,
      'd+': date.getDate() + status,
      'h+': date.getHours(),
      'm+': date.getMinutes(),
      's+': date.getSeconds(),
      'q+': Math.floor((date.getMonth() + 3) / 3),
      S: date.getMilliseconds(),
    };
    if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        `${date.getFullYear()}`.substr(4 - RegExp.$1.length)
      );
    }
    for (const k in o) {
      if (new RegExp(`(${k})`).test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          RegExp.$1.length === 1
            ? o[k]
            : `00${o[k]}`.substr(`${o[k]}`.length)
        );
      }
    }
    return fmt;
  },
  myRender(data_) {
    const _self = this.filter;
    const code = CodeTableAction.loadDataList(
      CodeTableCstUI.CLAIMTASK,
      null
    );

    const task = this.state[data_];
    const badge = val => <span className="badge" style={{ float: "right" }}>{val}</span>;
    const span = task.taskCode.map((data, index) => {
      const key = `tr${index}`;
      return (
        <tr width="100%" key={key}>
          <td width="80%">
            <UIRadio value={data} io="out" codeTable={code} />
          </td>
          <td width="20%">
            <UILink value={badge(task[data])} onClick={_self}>
              <UIParam name="data" value={data} />
              <UIParam name="data_" value={data_} />
            </UILink>
          </td>
        </tr>
      );
    });
    /* $.each(task.taskCode,(i,data) => {
    			span.push(<span><UISelect value={data} io="out" codeTable={code}/><UILink value={"("+task[data]+")"} onClick={_self}><UIParam name="data" value={data}/><UIParam name="data_" value={data_}/></UILink></span>);
    	}); */
    return span;
  },
  filter(event) {
    const data = event.getParameter('data');
    const data_ = event.getParameter('data_');
    let flag = 'ER';
    if (data_ === 'sharingPool')
      flag = ClaimWorkFlowLeftAction.pageChange({
        myPage: 'PoolTask',
      });
    else
      flag = ClaimWorkFlowLeftAction.pageChange({ myPage: 'MyTask' });
    if (flag === 'OK')
      ClaimWorkFlowLeftAction.initCondition({
        TaskType: data,
        flag: data_,
      });
  },
  onClickPendingTaskItemLeft(event) {
    console.log(
      '===========onClickPendingTaskItemLeft===================',
      NoticePoolAction
    );
    const data = event.getParameter('data');
    ClaimWorkFlowLeftAction.pageChange({ myPage: data });

    NoticePoolAction.searchNotice({
      '@type': 'ClaimNotice-ClaimNotice',
    });

    console.log(
      '===========onClickPendingTaskItemLeftafter==================='
    );
  },
  render() {
    const {
      sharingPool,
      newMessageCount,
      myClaimCount,
      pendingNoticeCount,
      pendingTask,
    } = this.state;
    const badge = (title, val) => {
      return (
        <div>
          <span>{title}</span>
          <span className="badge" style={{ float: "right", marginTop: '6px' }}>{val}</span>
        </div>
      )
    };

    return (
      <div>
        <UIPanel
          panelTitle={(
            <UILink
              value={badge(
                i18n.ClaimCommon.NewMessage,
                newMessageCount
              )}
              onClick={this.onClickPendingTaskItemLeft}
            >
              <UIParam name="data" value="NewMessage" />
            </UILink>
          )}
          onlyHead="true"
        />
        <UIPanel
          panelTitle={(
            <UILink
              value={badge(i18n.ClaimCommon.MyClaims, myClaimCount)}
              onClick={this.onClickPendingTaskItemLeft}
            >
              <UIParam name="data" value="MyClaim" />
            </UILink>
          )}
          onlyHead="true"
        />
        <UIPanel
          panelTitle={(
            <UILink
              value={badge(
                i18n.ClaimCommon.PendingNotice,
                pendingNoticeCount.Total
              )}
              onClick={this.onClickPendingTaskItemLeft}
            >
              <UIParam name="data" value="PendingNotice" />
            </UILink>
          )}
          onlyHead="true"
        />
        <UIPanel
          panelTitle={(
            <UILink
              value={badge(
                i18n.ClaimCommon.MyTask,
                pendingTask ? pendingTask.Total : 0
              )}
              onClick={this.onClickPendingTaskItemLeft}
            >
              <UIParam name="data" value="MyTask" />
            </UILink>
          )}
        >
          <UISmartPanelGrid column="1" colspan="1">
            <table width="100%">{this.myRender('pendingTask')}</table>
          </UISmartPanelGrid>
        </UIPanel>
        <UIPanel
          panelTitle={(
            <UILink
              value={badge(
                i18n.ClaimCommon.ShareingPool,
                sharingPool ? sharingPool.Total : 0
              )}
              onClick={this.onClickPendingTaskItemLeft}
            >
              <UIParam name="data" value="PoolTask" />
            </UILink>
          )}
        >
          <UISmartPanelGrid
            id="idUISmartPanelGridPengding"
            column="1"
            colspan="1"
          >
            <table width="100%">{this.myRender('sharingPool')}</table>
          </UISmartPanelGrid>
        </UIPanel>
      </div>
    );
  },
});
export default ClaimWorkFlowLeft;
