import { UIBox, UIButton, UIDialog } from 'RainbowUI';
import ClaimCommonExt from 'ClaimCommonExt';

const PolicySearchDialog = React.createClass({
  showDialog() {
    UIDialog.show('policySearch_dialog');
  },

  searchCountinue() {
    const selectedPolicyNo = this.refs.PolicySearchResult_ref.getSelectedPolicy();
    this.props.parentComponent.refs.PolicyInfo.searchCountinue(
      selectedPolicyNo
    );
    UIDialog.hide('policySearch_dialog');
  },
  clearResult() {
    this.refs.PolicySearchResult_ref.clearResult.apply();
  },
  render() {
    const PolicySearchResult = ClaimCommonExt.getPolicySearchResult();
    const _self = this;
    return (
      <UIDialog
        id="policySearch_dialog"
        title={i18n.ClaimCommon.PolicySearch}
        width="95%"
      >
        {this.renderPolicySearchOptions()}
        <PolicySearchResult
          ref="PolicySearchResult_ref"
          parentComponent={this}
        />
        <UIBox direction="center">
          <UIButton
            value={i18n.ClaimCommon.Continue}
            onClick={this.searchCountinue}
          />
          <UIButton
            value={i18n.ClaimCommon.Cancel}
            onClick={this.cancel}
          />
        </UIBox>
      </UIDialog>
    );
  },

  renderPolicySearchOptions() {
    if (env.isDummyPolicy) return (<div />);

    const PolicySearchOptions = ClaimCommonExt.getPolicySearchOptions();
    return (
      <PolicySearchOptions
        clearResult={this.clearResult}
        productDisabled={this.props.productDisabled}
        productTypeCode={this.props.productTypeCode}
      />
    )
  },

  cancel() {
    UIDialog.hide('policySearch_dialog');
  },
});
export default PolicySearchDialog;
