import {
  UIBox,
  UIDialog,
  UIUpdatePanel,
  UIText,
  UISelect,
  UIButton,
  UISmartPanelGrid,
} from 'RainbowUI';

import BindToMixin from 'react-binding';

const SettlementTypeAction = require('../action/SettlementTypeAction');

const SettlementTypeDialog = React.createClass({
  mixins: [BindToMixin],

  getInitialState() {
    return { settlementTypeEntity: {}, flag: {} };
  },

  showDialog(data, flag) {
    this.setState({ settlementTypeEntity: data });
    this.setState({ flag });

    UIDialog.show('settlementTypeDialog');
  },

  clickSave(evevt) {
    const result = SettlementTypeAction.saveSettlementType(
      this.state.settlementTypeEntity,
      this.state.flag
    );
    if (result === 'true') {
      this.props.parentComponet.updatePage();
      UIDialog.hide('settlementTypeDialog');
    }
  },

  render() {
    return (
      <UIDialog
        id="settlementTypeDialog"
        title={i18n.ClaimProduct.SettlementTypeManagement}
        width="80%"
        height="600px"
        modal="false"
      >
        <UIUpdatePanel
          id="settlementTypeDialogUIUpdate"
          render={this.renderDialogContent}
        />
        <UIBox direction="left">
          <UIButton
            id="savestelqqqq_"
            value={i18n.ClaimProduct.Save}
            onClick={this.clickSave}
            validationGroup="settlement"
            causeValidation="true"
          />
          <UIButton
            value={i18n.ClaimProduct.Cancel}
            buttonType="cancel"
          />
        </UIBox>
      </UIDialog>
    );
  },
  renderDialogContent() {
    const _self = this;
    const flag = _self.state.flag.out;
    return (
      <UISmartPanelGrid column="2" widthAllocation="4,8">
        <UIText
          io={flag}
          id="settlementTypeCode"
          label={i18n.ClaimProduct.SettlementTypeCode}
          valueLink={this.bindToState(
            'settlementTypeEntity',
            'PaymentTypeCode'
          )}
          required="true"
          validationGroup="settlement"
          render={data => {
            if (data.typeCode !== '') {
              _self.init();
            }
          }}
        />
        <UIText
          id="settlementTypeName"
          label={i18n.ClaimProduct.SettlementTypeName}
          valueLink={this.bindToState(
            'settlementTypeEntity',
            'PaymentTypeName'
          )}
          required="true"
          validationGroup="settlement"
        />
        <UIText
          colspan="2"
          id="settlementTypeDescription"
          label={i18n.ClaimProduct.Description}
          valueLink={this.bindToState(
            'settlementTypeEntity',
            'PaymentTypeDescription'
          )}
        />
      </UISmartPanelGrid>
    );
  },
});
export default SettlementTypeDialog;
