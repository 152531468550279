import {
  UpdateContext,
  UIPage,
  UIButton,
  UIDropZone,
  UISmartPanelGrid,
  UIPanel,
  UICell,
  UIBox,
  UIUpdatePanel,
  MessageHelper,
} from 'RainbowUI';

import BindToMixin from 'react-binding';
import UrlConfig from '../../url/Url';

const PolicyDataDocument = React.createClass({
  mixins: [BindToMixin],

  clickHome() {
    window.location.hash = '#/desktop';
  },
  onDownload() {
    let token = localStorage.getItem('Authorization');
    const projectConfigJson = JSON.parse(
      localStorage.getItem('project_config')
    );
    if (projectConfigJson.auth_type === 'Okta') {
      token = `access_token=${localStorage
        .getItem('Authorization')
        .substr(13)}`;
    }
    const downloadUrl = `${UrlConfig.policyDataDownload}?${token}`;
    window.location.href = downloadUrl;
  },
  onCompleteUpload() {
    MessageHelper.success('Upload success');
    UpdateContext.forceUpdate('documentForm_updatePanel');
    const files = UIDropZone.getDropZone(
      'policy_data_fileupload_dropZone'
    ).getAcceptedFiles();
    for (let i = 0; i < files.length; i += 1) {
      UIDropZone.getDropZone(
        'policy_data_fileupload_dropZone'
      ).removeFile(files[i]);
    }
  },

  onUpload() {
    const fileList = UIDropZone.getAcceptedFiles(
      'policy_data_fileupload_dropZone'
    );

    if (fileList.length === 0) {
      MessageHelper.error(
        i18n.ClaimCommon.ErrorMsg.PleaseUploadTheRequiredDocument
      );
    } else {
      const uploadUrl = UrlConfig.policyDataUpload;
      UIDropZone.getDropZone(
        'policy_data_fileupload_dropZone'
      ).options.url = uploadUrl;
      UIDropZone.upload('policy_data_fileupload_dropZone');
    }
  },
  Uploadfailed(event) {
    MessageHelper.warning(event);
  },
  render() {
    return (
      <UIPage>
        <UIPanel panelTitle="Upload Policy Data Document">
          <UIUpdatePanel id="documentForm_updatePanel">
            <UISmartPanelGrid>
              <br />
              <UIDropZone
                id="policy_data_fileupload_dropZone"
                autoUpload="false"
                width="680px"
                url="/"
                acceptFile=".xls,.xlsx"
                onSuccess={this.onCompleteUpload}
                colspan="2"
                maxFileSize="50"
                onError={this.Uploadfailed}
              />
            </UISmartPanelGrid>
          </UIUpdatePanel>
          <UICell style={{ textAlign: 'right' }}>
            <UIButton
              value={i18n.ClaimCommon.Upload}
              onClick={this.onUpload}
              styleClass="primary"
              causeValidation="true"
              validationGroup="document"
            />
          </UICell>
        </UIPanel>
        <UIPanel panelTitle="Download Policy Data Document">
          <UIBox direction="right" gap="15" padding="0 0 0 0">
            <UIButton
              value="Download"
              enabled="true"
              disabled="false"
              onClick={this.onDownload}
              visibled="true"
            />
          </UIBox>
        </UIPanel>
      </UIPage>
    );
  },
});

module.exports = PolicyDataDocument;
