import { MessageHelper, Caller, Cache } from 'RainbowUI';
import urlConfig from '../url/Url';
import CommonService from '../service/CommonService';

export default class CommonAction {
  onClickedHome() {
    const ip = window.location.origin;
    const url = `${ip}/portal`;
    window.location.href = url;
  }

  goMaintenancecenter() {
    window.location.href = urlConfig.maintenancecenter;
  }

  onClickedClose() {
    window.close();
  }

  renderUser() {
    let currentUser = Cache.get('currentUser');

    if (!currentUser) {
      currentUser = CommonService.initUser();
      Cache.put('currentUser', currentUser);
    }
    return currentUser;
  }

  onClearCache() {
    const url = urlConfig.ClearCache;
    const setting = { method: 'GET', async: false, block: false };
    Caller.call(url, null, setting).then(
      data => {
        if (data) {
          MessageHelper.success('', i18n.clearSuccess);
        } else {
          MessageHelper.error(i18n.clearError);
        }
      },
      err => {
        MessageHelper.error(i18n.clearError + err);
      }
    );
  }

  onClickedExit() {
    window.location.hash = '/';
  }

  loadPrintType(para) {
    return null;
    /*
    let result = null;
    const url = urlConfig.loadPrintType;
    const setting = { method: 'POST', async: false, block: false };
    Caller.call(url, para, setting).then(
      data => {
        if (data) {
          result = data;
        } else {
          MessageHelper.error(i18n.PrintMessage.ErrorMsg.Error_0003);
        }
      },
      err => {
        MessageHelper.error(err.responseJSON.Messages['0'].Message);
      }
    );

    return result;
    */
  }

  loadPrintTypeByTrans(transType, productCode) {
    let result = null;
    const url = `${urlConfig.loadPrintTypeByTrans}?transType=${transType}&productCode=${productCode}`;
    CommonService.ajaxGet(null, url).then(data => {
      if (data) {
        result = data;
      }
    });
    return result;
  }

  queryPDF(taskId) {
    CommonService.queryPDF(taskId);
  }

  printPDF(taskId, policyId, documentType) {
    CommonService.printPDF(taskId, policyId, documentType);
  }

  queryCertificateList(masterPolicyId) {
    const newQueryConditions = { MasterPolicyId: masterPolicyId };
    const conditions = {
      Module: 'Certificate',
      PageNo: 1,
      PageSize: 1000,
      SortField: 'index_time',
      SortType: 'desc',
      Conditions: newQueryConditions,
    };
    const policyResult = CommonService.querySolrEntity(
      conditions,
      false
    );
    return policyResult;
  }

  queryDocHistory(documentHisCondition, showMsg) {
    const conditions = {
      Module: 'PrintTaskEntity',
      PageNo: documentHisCondition.PageNo,
      PageSize: documentHisCondition.PageSize,
      SortField: 'id',
      SortType: 'desc',
      Conditions: {
        TransactionType: documentHisCondition.TransactionType.toString(),
        TransactionPage: documentHisCondition.TransactionPage.toString(),
        DocumentCode: documentHisCondition.DocumentType,
        Status: documentHisCondition.Status,
        PrintFormat: documentHisCondition.PrintFormat,
        TransactionNo: documentHisCondition.TransactionNo.toString(),
      },

      OrConditions: {},
      BetweenConditions: {},
    };
    if (conditions.Conditions.DocumentType) {
      conditions.Conditions.DocumentType.toString();
    }
    if (conditions.Conditions.Status) {
      conditions.Conditions.Status = parseInt(
        conditions.Conditions.Status,
        10
      );
    }
    if (conditions.Conditions.PrintFormat) {
      conditions.Conditions.PrintFormat.toString();
    }
    if (documentHisCondition.TransactionNo2) {
      conditions.Conditions.TransactionNo2 = `${documentHisCondition.TransactionNo2}`;
    }
    if (documentHisCondition.TransactionNo3) {
      conditions.Conditions.TransactionNo3 = `${documentHisCondition.TransactionNo3}`;
    }
    const policyResult = CommonService.querySolrEntity(
      conditions,
      showMsg
    );
    return policyResult;
  }

  queryAuditTrail(taskId, pageNo, pageSize) {
    return CommonService.queryAuditTrail(taskId, pageNo, pageSize);
  }

  downloadDoc(taskIds) {
    if (taskIds.length === 0) {
      MessageHelper.warning(
        i18n.PrintMessage.WarnMsg.PleaseSelectOne,
        null,
        MessageHelper.POSITION_TOP_RIGHT
      );
      return;
    }
    CommonService.download(taskIds);
  }

  downloadAllDoc(masterPolicyId) {
    CommonService.downloadAll(masterPolicyId);
  }

  loadCertificate(masterPolicyId) {
    const newQueryConditions = {
      TransactionNo: `${masterPolicyId}`,
      TransactionPage: '15',
      TransactionType: '1',
      Status: 2,
    };
    const conditions = {
      Module: 'PrintTaskEntity',
      PageNo: 1,
      PageSize: 500,
      SortField: 'index_time',
      SortType: 'desc',
      Conditions: newQueryConditions,
    };
    const policyResult = CommonService.querySolrEntity(
      conditions,
      false
    );
    return policyResult;
  }

  queryEmailGroup(para) {
    let result = null;
    const url = urlConfig.queryEmailGroup;
    CommonService.ajaxPost(para, url).then(data => {
      if (data) {
        result = data;
        if (result.TotalElements === 0) {
          MessageHelper.warning(
            i18n.PrintMessage.WarnMsg.Warn_0003,
            null,
            MessageHelper.POSITION_TOP_RIGHT
          );
        }
      }
    });
    return result;
  }

  getTransDocTypeList(transId) {
    let result = null;
    const url = `${urlConfig.getTransDocTypeList}?transType=${transId}`;
    CommonService.ajaxGet(null, url).then(data => {
      if (data) {
        result = data;
      }
    });
    return result;
  }

  saveEmailGroup(group) {
    let result = null;
    const url = urlConfig.saveEmailGroup;
    CommonService.ajaxPost(group, url).then(data => {
      if (data) {
        result = data;
      }
    });
    return result;
  }

  saveEmailGroupDetail(body) {
    let result = null;
    const url = urlConfig.saveEmailGroupDetail;
    CommonService.ajaxPost(body, url).then(data => {
      if (data) {
        result = data;
      }
    });
    return result;
  }

  deleteEmailGroup(groupId) {
    let result = null;
    const url = `${urlConfig.deleteEmailGroup}?groupId=${groupId}`;
    CommonService.ajaxGet(null, url).then(data => {
      if (data) {
        result = data;
      }
    });
    return result;
  }

  loadEmailGroup(groupId) {
    let result = null;
    const url = `${urlConfig.loadEmailGroup}?groupId=${groupId}`;
    CommonService.ajaxGet(null, url).then(data => {
      if (data) {
        result = data;
      }
    });
    return result;
  }
}
