export default {
  '@type': 'ClaimPolicy-ClaimPolicy',
  OrganId: '1',
  PolicyNo: 'Q15123400031676',
  ProductCode: '198',
  ProductTypeCode: 'GPC',
  EffDate: '2015-02-01T00:00:00',
  ExpDate: '2016-02-01T00:00:00',
  ClaimsMade: '1',
  ExtendReportingDate: '2016-05-02T00:00:00',
  MaintananceDate: '2016-05-02T00:00:00',
  RetroactiveDate: '2014-02-01T00:00:00',
  Premium: 5000.0,
  PremiumIsPaid: 'Y',
  CurrencyCode: 'USD',
  InsuranceType: 'D',
  FixedExchangeRate: '0.1560',
  InsuredList: [
    {
      '@type': 'ClaimPolicyInsured-ClaimPolicyInsured',
      InsuredName: 'G324599 Audi A8',
      SumInsured: 100000.0,
      InsuredCategory: 1,
      InsuredUid: 0,
      CoverageList: [
        {
          '@type': 'ClaimPolicyCoverage-ClaimPolicyCoverage',
          CoverageName: 'Own Damage or Loss',
          DeductibleAmount: 500.0,
          DeductibleRate: 0.0,
          SumInsured: 100000,
          PolicySumInsured: 500000.0,
          PolicyDeductibleRate: 0.0,
          PolicyDeductibleAmount: 0.0,
          CoverageCode: 'CT_7416',
          ProductCode: '198',
        },
        {
          '@type': 'ClaimPolicyCoverage-ClaimPolicyCoverage',
          CoverageName: 'Third Party Body Injury',
          DeductibleAmount: 0,
          DeductibleRate: 0.0,
          SumInsured: 500000,
          PolicySumInsured: 500000.0,
          PolicyDeductibleRate: 0.0,
          PolicyDeductibleAmount: 0.0,
          CoverageCode: 'CT_7340',
          ProductCode: '198',
        },
        {
          '@type': 'ClaimPolicyCoverage-ClaimPolicyCoverage',
          CoverageName: 'Third Party Property Damage',
          DeductibleAmount: 0,
          DeductibleRate: 0.0,
          SumInsured: 500000,
          PolicySumInsured: 500000.0,
          PolicyDeductibleRate: 0.0,
          PolicyDeductibleAmount: 0.0,
          CoverageCode: 'CT_7339',
          ProductCode: '198',
        },
        {
          '@type': 'ClaimPolicyCoverage-ClaimPolicyCoverage',
          CoverageName: 'Personal Accident',
          DeductibleAmount: 0,
          DeductibleRate: 0.0,
          SumInsured: 500000,
          PolicySumInsured: 500000.0,
          PolicyDeductibleRate: 0.0,
          PolicyDeductibleAmount: 0.0,
          CoverageCode: 'CT_7338',
          ProductCode: '198',
        },
        {
          '@type': 'ClaimPolicyCoverage-ClaimPolicyCoverage',
          CoverageName: 'Medical Expense',
          DeductibleAmount: 0,
          DeductibleRate: 0.0,
          SumInsured: 5000,
          PolicySumInsured: 500000.0,
          PolicyDeductibleRate: 0.0,
          PolicyDeductibleAmount: 0.0,
          CoverageCode: 'CT_7337',
          ProductCode: '198',
        },
        {
          '@type': 'ClaimPolicyCoverage-ClaimPolicyCoverage',
          CoverageName: 'Towing Fee',
          DeductibleAmount: 0,
          DeductibleRate: 0.0,
          SumInsured: 5000,
          PolicySumInsured: 500000.0,
          PolicyDeductibleRate: 0.0,
          PolicyDeductibleAmount: 0.0,
          CoverageCode: 'CT_7336',
          ProductCode: '198',
        },
      ],
    },
  ],
  PartyList: [
    {
      '@type': 'ClaimPolicyParty-ClaimPolicyParty',
      RoleType: '07',
      PartyName: 'Alfonso Wang',
      ContactFirstName: 'Alfonso',
      ContactLastName: 'Wang',
      ContactName: 'Alfonso Wang',
      ContactTelephone: '18917596776',
      IdType: '6',
      IdNumber: 'MockPolicy3209223454563443274',
      ContactAddress: 'shaghai',
      ContactType: '1',
      CommonPartyId: 40097365,
    },
    {
      '@type': 'ClaimPolicyParty-ClaimPolicyParty',
      RoleType: '08',
      PartyName: 'Mickle Zhang',
      ContactFirstName: 'Mickle',
      ContactLastName: 'Zhang',
      ContactName: 'Mickle Zhang',
      ContactTelephone: '130193293534376',
      IdType: '7',
      IdNumber: 'MockPolicy3209223454563443276',
      ContactAddress: 'yancheng',
      ContactType: '1',
      CommonPartyId: 38813405,
    },
    {
      '@type': 'ClaimPolicyParty-ClaimPolicyParty',
      RoleType: '09',
      PartyName: 'Alfonso Wang',
      ContactFirstName: 'Alfonso',
      ContactLastName: 'Wang',
      ContactName: 'Alfonso Wang',
      ContactTelephone: '18917596776',
      IdType: '6',
      IdNumber: 'MockPolicy3209223454563443274',
      ContactAddress: 'shaghai',
      ContactType: '1',
      CommonPartyId: 40097365,
    },
    {
      '@type': 'ClaimPolicyParty-ClaimPolicyParty',
      RoleType: '09',
      PartyName: 'TestInsured Zhang',
      ContactFirstName: 'TestInsured',
      ContactLastName: 'Zhang',
      ContactName: 'TestInsured Zhang',
      ContactTelephone: '130193293534376',
      IdType: '1',
      IdNumber: 'MockPolicy3209223454563443299',
      ContactAddress: 'yanchengTestInsured',
      ContactType: '2',
      CommonPartyId: 40097366,
    },
  ],
};
