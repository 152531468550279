import {
  UIDialog,
  UIPanel,
  UIButton,
  MessageHelper,
  UIBox,
} from 'RainbowUI';
import { ClaimHandlingAction } from 'ClaimCommon';
import ClaimRegistration from 'ClaimRegistration';
import {
  PartyConst,
  PtyIntegrationSearchDialog,
  PtyIntegrationThirdParty,
} from 'EU00PartyCommonUI';
import ClaimCommonExt from 'ClaimCommonExt';

const ReserveAction = ClaimCommonExt.getReserveAction();
const RegistrationValidationAction = ClaimRegistration.getClaimRegistrationValidationAction();

const ModifySubclaimInfoDialog = React.createClass({
  getInitialState() {
    return {
      data: this.props.data,
      claimantId: null,
    };
  },

  cancel() {
    UIDialog.hide('ModifySubclaimInfo_Dialog');
  },

  afterSubmitCallback() {
    const { objectEntity } = this.refs.ModifySubclaimInfo_ref.state;
    ReserveAction.initReserve(objectEntity.ObjectId);
  },

  validate() {
    const result = RegistrationValidationAction.validateSILimitForModifySubclaim(
      this.refs.ModifySubclaimInfo_ref.state.objectEntity
    );
    if (result && result.Status === 'WARN') {
      this.refs.confirmDialog_ref.showDialog(
        i18n.ClaimCommon.Registration,
        result.Messages[0].Message
      );
      return true;
    }
    if (result && result.Status === 'BLOCK') {
      MessageHelper.error('', result.Messages[0].Message);
      return true;
    }
  },
  submitModify() {
    if (this.validate()) {
      return;
    }
    const { objectEntity } = this.refs.ModifySubclaimInfo_ref.state;
    delete objectEntity.claimantType;
    ClaimHandlingAction.modifySubclaimSubmit(
      objectEntity,
      this.afterSubmitCallback
    );
  },
  submitAfterValidation() {
    ClaimHandlingAction.modifySubclaimSubmit(
      this.refs.ModifySubclaimInfo_ref.state.objectEntity
    );
  },
  render() {
    const subclaimIndex = sessionStorage.getItem(
      'ModifySubclaim_index'
    );
    const SubclaimInfo = ClaimRegistration.getSubclaimInfo();
    const CommonConfirmDialog = ClaimCommonExt.getCommonConfirmDialog();
    const { readOnly, index } = this.props;
    const { data } = this.state;
    return (
      <div>
        <UIDialog
          id="ModifySubclaimInfo_Dialog"
          title={i18n.ClaimCommon.ModifySublaimInfo}
          width="95%"
        >
          <UIPanel
            panelTitle={i18n.ClaimCommon.ModifySublaimInfo}
            styleClass="default"
          >
            <SubclaimInfo
              ref="ModifySubclaimInfo_ref"
              data={data}
              from="modifySubClaim"
              subclaimIndex={subclaimIndex}
              forModify="true"
              io="in"
              modify="true"
            />
          </UIPanel>
          <UIBox direction="center">
            <UIButton
              value={i18n.ClaimCommon.Submit}
              onClick={this.submitModify}
              causeValidation="true"
              validationGroup="RegistrationInfo"
            />
            <UIButton
              value={i18n.ClaimCommon.Cancel}
              onClick={this.cancel}
              buttonType="cancel"
            />
          </UIBox>
        </UIDialog>
        <PtyIntegrationSearchDialog
          ref="partySearchDialogForTPDriverForModify_Ref"
          id="partySearchDialogForTPDriverForModify"
          isSearch
          getSearchResult={this.getThirdPartyDriver}
          isVisibled
          isSearchRole
          title={i18n.SearchDriver}
          onHide={this.onHide}
          createClicked={this.createNewThirdParty}
          RoleName={PartyConst.PARTY_ROLE_NAME_DRIVER}
          enableChangePartyCategory="N"
        />
        <PtyIntegrationThirdParty
          id="tpDriverCreationDialogForModify"
          onClickSubmit={this.onClickSubmit}
          onClickBack={this.onClickBack}
        />
        <CommonConfirmDialog
          ref="confirmDialog_ref"
          parentComponent={this}
          objectId={`_modifySubclaimInfoDialog${readOnly}_${index}`}
          onConfirm={this.submitAfterValidation}
          onCancel={this.cancel}
        />
      </div>
    );
  },

  onHide() {
    UIDialog.show('ModifySubclaimInfo_Dialog');
  },

  onClickSubmit(party) {
    const subclaimIndex = sessionStorage.getItem(
      'ModifySubclaim_index'
    );
    const objectEntity = this.state.data.ObjectList[subclaimIndex];
    objectEntity.TpDriverName = party.PartyName;
    objectEntity.TpDriverId = party.PartyId;
    objectEntity.IdNumber = party.IdNumber;
    objectEntity.TpTelNo = party.MobileTel;
    objectEntity.TpDriverBirthDate = party.DateOfBirth;
    UIDialog.show('ModifySubclaimInfo_Dialog');
  },

  onClickBack() {
    UIDialog.show('partySearchDialogForTPDriverForModify');
  },

  createNewThirdParty() {
    UIDialog.hide('partySearchDialogForTPDriverForModify');
    UIDialog.show('tpDriverCreationDialogForModify');
  },

  getThirdPartyDriver(party) {
    const subclaimIndex = sessionStorage.getItem(
      'ModifySubclaim_index'
    );
    const objectEntity = this.state.data.ObjectList[subclaimIndex];
    objectEntity.TpDriverName = party.PartyName;
    objectEntity.TpDriverId = party.PartyId;
    objectEntity.IdNumber = party.IdNumber;
    objectEntity.TpTelNo = party.MobileTel;
    objectEntity.TpDriverBirthDate = party.DateOfBirth;
    UIDialog.show('ModifySubclaimInfo_Dialog');
  },
});
export default ModifySubclaimInfoDialog;
