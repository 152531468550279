import {
  UIText,
  UICurrency,
  UIPanel,
  UIDateTimePicker,
  UIDataTable,
  UIColumn,
  UILink,
  UIParam,
} from 'RainbowUI';
import { CodeTableAction, CodeTableCstUI } from 'ClaimCommonConstant';

const SubrogationHistoryStore = require('../store/SubrogationHistoryStore');
const SubrogationAction = require('../action/SubrogationAction');
const SubrogationReadOnlyDialog = require('./SubrogationReadOnlyDialog');

const SubrogationHistory = React.createClass({
  getInitialState() {
    return { SubrogationHistory: [] };
  },

  componentWillMount() {
    SubrogationHistoryStore.listen(this.onStoreStateChange);
    SubrogationAction.loadSubrogationHistory(this.props.caseId);
  },

  componentWillUnmount() {
    SubrogationHistoryStore.unlisten(this.onStoreStateChange);
  },
  onStoreStateChange(storeState) {
    if (!_.isEmpty(storeState.SubrogationHistory)) {
      this.setState({
        SubrogationHistory: storeState.SubrogationHistory,
      });
    }
  },
  render() {
    const _self = this;
    let i = _self.state.SubrogationHistory.length + 1;
    const nameCode = CodeTableAction.loadDataList(
      CodeTableCstUI.ClaimUser,
      null
    );
    return (
      <div>
        <UIPanel
          panelTitle={i18n.ClaimSubrogation.SubrogationHistory}
          toggleable="true"
          indexable="false"
          isHeading="true"
          pageable="false"
        >
          <UIDataTable
            pageable="false"
            indexable="false"
            value={_self.state.SubrogationHistory}
          >
            <UIColumn
              headerTitle={i18n.ClaimSubrogation.SeqNo}
              width="20%"
              render={data => {
                i -= 1;
                const temp = i;
                return (
                  <UILink
                    value={temp}
                    onClick={SubrogationAction.showReadOnly}
                  >
                    <UIParam
                      name="subrogationId"
                      value={data.SubrogationId}
                    />
                  </UILink>
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.ClaimSubrogation.TransactionDate}
              value="TransactionDate"
              render={data => {
                return (
                  <UIDateTimePicker
                    value={data.ApproveDate}
                    format="MM/DD/YYYY HH:mm:ss"
                    io="out"
                  />
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.ClaimSubrogation.OperatedBy}
              value="OperatedBy"
              render={data => {
                const names = nameCode.codes;
                const submiter = data.Submiter;
                let name;
                for (let i = 0; i < names.length; i += 1) {
                  if (names[i].id == submiter) {
                    name = names[i].text;
                  }
                }
                return <UIText io="out" value={name} />;
              }}
            />
            <UIColumn
              headerTitle={
                i18n.ClaimSubrogation.TotalSubrogationAmount
              }
              value="TotalSubrogationAmount"
              render={data => {
                const tempId = `CURRENCY_CODE_TotalAmount${data.SubrogationId}`;
                return (
                  <div style={{ float: 'left' }}>
                    <UICurrency
                      id={tempId}
                      io="out"
                      value={data.TotalAmount}
                      unit={window.EU00.getCurrencySignByCurrencyCode(
                        data.CurrencyCode
                      )}
                    />
                  </div>
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.ClaimSubrogation.Remark}
              value="Remark"
              render={data => {
                return <UIText value={data.Remark} io="out" />;
              }}
            />
          </UIDataTable>
          <div>
            <SubrogationReadOnlyDialog />
          </div>
        </UIPanel>
      </div>
    );
  },
});
export default SubrogationHistory;
