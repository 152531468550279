import { MessageHelper, CodeTable } from 'RainbowUI';
import alt from '../alt';
import ClaimLitigationService from '../service/ClaimLitigationService';
import AuditService from '../service/AuditService';
import CodeTableAction from './CodeTableAction';
import AuditUtil from '../util/AuditUtil';

const ClaimExceptionUtils = require('../util/ClaimExceptionUtils');
const ClaimCstUI = require('../constant/ClaimCstUI');
const ClaimHandlingAction = require('./ClaimHandlingAction');
const TaskTransferAction = require('./TaskTransferAction');

export default alt.createActions({
  loadLitigations(caseId) {
    let result = null;
    ClaimLitigationService.loadLitigations(caseId).then(
      data => {
        if (data.Status === 'OK') {
          result = data;
        }
      },
      err => {}
    );
    return result.Model;
  },
  save(litigationObj) {
    let result = null;
    ClaimLitigationService.save(litigationObj).then(data => {
      if (data.Status == ClaimCstUI.REST_RESTPONSE_STATUS_OK) {
        result = data.Model;
        MessageHelper.info(
          ClaimExceptionUtils.formatI18NMsg(
            i18n.ClaimCommon.SaveSuccess,
            i18n.ClaimCommon.Litigation
          )
        );
        setTimeout(() => {}, 1000);
        this.dispatch({
          objectEntity: result,
          claimData: ClaimHandlingAction.getClaimData(
            litigationObj.CaseId
          ),
        });
      } else {
        MessageHelper.info(
          ClaimExceptionUtils.formatI18NMsg(
            i18n.ClaimCommon.SaveFail,
            i18n.ClaimCommon.Litigation
          )
        );
      }
    });
  },
  remove(litigationObj) {
    let result = null;
    ClaimLitigationService.remove(litigationObj).then(data => {
      if (data.Status == ClaimCstUI.REST_RESTPONSE_STATUS_OK) {
        result = data.Model;
        MessageHelper.info(
          ClaimExceptionUtils.formatI18NMsg(
            i18n.ClaimCommon.DeleteSuccess,
            i18n.ClaimCommon.Litigation
          )
        );
        setTimeout(() => {}, 1000);
        if (result) {
          this.dispatch({
            objectEntity: result,
            claimData: ClaimHandlingAction.getClaimData(
              litigationObj.CaseId
            ),
          });
        }
      } else {
        MessageHelper.info(
          ClaimExceptionUtils.formatI18NMsg(
            i18n.ClaimCommon.DeleteFail,
            i18n.ClaimCommon.Litigation
          )
        );
      }
    });
  },

  getLocalCurrency() {
    let result = null;
    ClaimLitigationService.getLocalCurrency().then(
      data => {
        if (data.Status === 'OK') {
          result = data;
        }
      },
      err => {}
    );
    return result.Model;
  },

  litigationAuthority(caseId) {
    let result = null;
    ClaimLitigationService.litigationAuthority(caseId).then(
      data => {
        if (data.Status === 'OK') {
          result = data;
        }
      },
      err => {}
    );
    return result.Model;
  },

  getAttachToCodeTable(caseId) {
    const codeTable = [];
    const entityList = TaskTransferAction.getSubClaimNoByCaseId(
      caseId
    );
    for (let i = 0; i < entityList.length; i += 1) {
      codeTable.push({
        id: entityList[i].ObjectId,
        text: `Subclaim ${entityList[i].SeqNo}`,
      });
    }
    return new CodeTable(codeTable);
  },

  getAuditDataList(arg, pageIndex, pageSize) {
    let searchResult = {};
    AuditService.queryAuditData(
      arg.auditEventType,
      arg.objectId,
      pageIndex,
      pageSize
    ).then(data => {
      if (data.Status == ClaimCstUI.REST_RESTPONSE_STATUS_OK) {
        searchResult = data.Model;
      } else if (
        data.Status == ClaimCstUI.REST_RESTPONSE_STATUS_BLOCK
      ) {
        MessageHelper.error(i18n.ClaimCommon.Fail);
      }
    });

    return {
      count: searchResult.Total,
      result: this.actions.handleChangeData(
        searchResult.Content,
        arg
      ),
    };
  },

  handleChangeData(content, arg) {
    const { auditEventType } = arg;
    const self = this.actions;
    const result = [];
    $(content).each((index, datas) => {
      const data = datas;
      const changeFieldList = [];
      $(JSON.parse(data.ChangeData)).each((index1, data1) => {
        const path = data1.path.replace('/', '');
        const i18nParm = AuditUtil[auditEventType][path];
        if (i18nParm) {
          let codetableId =
            AuditUtil[auditEventType][`${path}CodeTableId`];
          if (codetableId == ClaimCstUI.LitigationAttachTo) {
            codetableId = `${ClaimCstUI.LitigationAttachTo}-${arg.attachToCaseId}`;
          }
          const i18nParmList = i18nParm.split('.');
          let msg;
          if (ClaimCstUI.AUDIT_ADD == data1.op) {
            msg = i18n.ClaimCommon.AuditAdd;
            changeFieldList.push(
              msg
                .replace(
                  ClaimCstUI.PLACEHOLDER_FIELDNAME,
                  i18n[i18nParmList[0]][i18nParmList[1]]
                )
                .replace(
                  ClaimCstUI.PLACEHOLDER_NEW_VALUE,
                  self.getValue(data1.value, codetableId)
                )
            );
          } else if (ClaimCstUI.AUDIT_REMOVE == data1.op) {
            msg = i18n.ClaimCommon.AuditRemove;
            changeFieldList.push(
              msg.replace(
                ClaimCstUI.PLACEHOLDER_FIELDNAME,
                i18n[i18nParmList[0]][i18nParmList[1]]
              )
            );
          } else if (ClaimCstUI.AUDIT_REPLACE == data1.op) {
            msg = i18n.ClaimCommon.AuditReplace;
            changeFieldList.push(
              msg
                .replace(
                  ClaimCstUI.PLACEHOLDER_FIELDNAME,
                  i18n[i18nParmList[0]][i18nParmList[1]]
                )
                .replace(
                  ClaimCstUI.PLACEHOLDER_OLD_VALUE,
                  self.getValue(data1.oldValue, codetableId)
                )
                .replace(
                  ClaimCstUI.PLACEHOLDER_NEW_VALUE,
                  self.getValue(data1.newValue, codetableId)
                )
            );
          }
        }
      });
      if (changeFieldList.length > 0) {
        data.ChangeFieldList = _.sortBy(changeFieldList, n => {
          return n;
        });
        result.push(data);
      }
    });

    return result;
  },

  getValue(tmpvalue, codetableId) {
    let value = tmpvalue;
    const self = this.actions;
    if (codetableId != null && codetableId !== '') {
      let codeTableData;
      if (
        codetableId
          .toString()
          .indexOf(ClaimCstUI.LitigationAttachTo) !== -1
      ) {
        const caseId = codetableId.substring(
          codetableId.indexOf('-') + 1,
          codetableId.length
        );
        codeTableData = self.getAttachToCodeTable(caseId);
      } else {
        codeTableData = CodeTableAction.loadDataList(
          codetableId,
          null
        );
      }
      if (codeTableData.map[value]) {
        value = codeTableData.map[value].text;
      }
    }
    return value;
  },

  updateLitigationTabs(flag, index) {
    let title = '';
    if (flag) {
      title = `${i18n.ClaimCommon.Litigation} (Y) `;
    } else {
      title = i18n.ClaimCommon.Litigation;
    }
    $(`a[href='#claimInfoTabs_${index}']>span`).html(title);
  },
});
