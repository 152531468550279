import {
  DataContext,
  UpdateContext,
  UIDialog,
  MessageHelper,
} from 'RainbowUI';
import urlConfig from '../../../../UrlConfig';
import FeeQueryType from './FeeQueryType';
import CommonService from '../../service/CommonService';
import Util from '../../util/Util';

export default class ARAPSearchAction {
  initData() {
    const searchModel = {
      FeeNo: null,
      Status: null,
      InsertTimeFrom: null,
      InsertTimeTo: null,
      PolicyNo: null,
      ClaimNo: null,
      PartyName: null,
      AgentName: null,
      AgentCode: DataContext.get('userMenuCodes').agentCode,
      BranchId: null,
      EffDateFrom: null,
      EffDateTo: null,
      ArapType: null,
      DueDateFrom: null,
      DueDateTo: null,
      StatementNo: null,
      QueryType: null,
      PageNo: null,
      PageSize: null,
      PolicyHolder: null,
    };

    const noteModel = {
      NoteType: null,
      RefId: null,
      NoteContent: null,
    };
    DataContext.put('noteModel', noteModel);
    DataContext.put('arapSearchModel', searchModel);
    const arapSearchFlag = { flag: false };
    DataContext.put('arapSearchFlag', arapSearchFlag);
  }

  onClickedSearch() {
    DataContext.get('arapSearchFlag').flag = true;
    UpdateContext.forceUpdate('updateAparResultPanel');
  }

  onClickedClear() {
    new ArapSearchAction().initData();
    this.setState({
      arapSearchModel: DataContext.get('arapSearchModel'),
    });
    UpdateContext.forceUpdate(
      'updateAparSearchPanel,query_resultTable'
    );
  }

  getARAPProvider(pageIndex, pageSize, component) {
    const policyNo = this.state.PolicyNo;
    const isQuery = this.state.IsQuery;
    const pageNo = (pageIndex - 1) / pageSize + 1;
    const total = 0;
    const results = [];
    let condition = DataContext.get('arapSearchModel');
    if (!condition) {
      condition = {};
      condition = { PolicyNo: policyNo };
    }
    if (!condition.AgentCode) {
      condition.AgentName = null;
    }

    if (
      component &&
      component.state &&
      component.state.sortColumn &&
      component.state.sortWay
    ) {
      condition.SortColumn = component.state.sortColumn;
      condition.SortWay = component.state.sortWay;
    }

    if (isQuery == true) {
      if (policyNo) {
        condition.PageNo = pageNo;
        condition.PageSize = pageSize;
        condition.QueryType = FeeQueryType.FOR_QUERY;
        condition.IsPolicyQuery = true;
        const url = urlConfig.arapSearchByGroup;
        // TODO bcp to be integrated
        // CommonService.ajaxPost(condition, url).then(data => {
        //   if (
        //     data.ElementsInCurrentPage &&
        //     data.ElementsInCurrentPage.length > 0
        //   ) {
        //     total = data.TotalElements;
        //     results = data.ElementsInCurrentPage;
        //   }
        // });
      }
    } else if (DataContext.get('arapSearchFlag').flag) {
      if (Util.isEmptyQueryModel(condition)) {
        MessageHelper.error('', i18n.queryEmptyError, null);
        return;
      }

      const userMenuCodes = DataContext.get('userMenuCodes');
      if (userMenuCodes && userMenuCodes.noRole) {
        console.error('no employee and producer role');
        return;
      }

      condition.PageNo = pageNo;
      condition.PageSize = pageSize;
      condition.QueryType = FeeQueryType.FOR_QUERY;
      const url = urlConfig.arapSearchByGroup;
      // TODO bcp to be integrated
      // CommonService.ajaxPost(condition, url).then(data => {
      //   if (
      //     data.ElementsInCurrentPage &&
      //     data.ElementsInCurrentPage.length > 0
      //   ) {
      //     total = data.TotalElements;
      //     results = data.ElementsInCurrentPage;
      //   }
      // });
    }

    return { count: total, result: results };
  }

  onClickedInstallmentPlanInfo(event) {
    const dataModel = {
      data: event.getParameter('data'),
    };
    DataContext.put('installmentPlanInfoParameter', dataModel);
    UIDialog.show('installmentPlanInfoDialog');
  }

  getInstallmentDetailInfoProvider(pageIndex, pageSize) {
    const groupdata = DataContext.get('installmentPlanInfoParameter');
    let results = [];

    const para = {
      policyId: groupdata.data.PolicyId,
      debitCreditNo: groupdata.data.DebitCreditNo,
      arapType: groupdata.data.ArapType,
      paymentType: groupdata.data.PaymentType,
      endoId: groupdata.data.EndoId,
      claimId: groupdata.data.ClaimId,
      showDDDCRecord: true,
    };
    const url = urlConfig.arapSearchListByGroupInfo;

    CommonService.ajaxGet(para, url).then(data => {
      results = data;
    });
    const total = {
      CurPeriod: 'Total',
      Amount: 0,
      CommissionAmount: 0,
      Balance: 0,
      CommissionCurrencyCode: groupdata.data.CommissionCurrencyCode,
      IsAR: groupdata.data.IsAR,
      CurrencyCode: groupdata.data.CurrencyCode,
    };

    const resultTemp = results;

    for (let i = 0; i < resultTemp.length; i += 1) {
      const item = resultTemp[i];
      total.Amount = Math.accAdd(total.Amount, item.Amount);
      total.CommissionAmount = Math.accAdd(
        total.CommissionAmount,
        item.CommissionAmount
      );
      total.Balance = Math.accAdd(total.Balance, item.Balance);
    }
    let { Balance } = total;
    let { Amount } = total;
    if (!total.IsAR) {
      Amount = 0 - Amount;
      Balance = 0 - Balance;
    }
    total.Balance = Balance;
    total.Amount = Amount;
    //      results.push(total);

    const arapSearchAction = new ARAPSearchAction();
    arapSearchAction.generateTotalRow(resultTemp, total);

    DataContext.put('installmentResults', resultTemp);
    DataContext.put('installmentTotalResults', total);
    return { count: resultTemp.length, result: resultTemp };
  }

  generateTotalRow(resultTemp, temptotal) {
    const total = temptotal;
    let unit = '';
    if (total.CurrencyCode) {
      unit = window.EU00.getCurrencySignByCurrencyCode(
        total.CurrencyCode
      );
    }
    total.Unit = unit;

    let unitCommisson = '';
    if (total.CommissionCurrencyCode) {
      unitCommisson = window.EU00.getCurrencySignByCurrencyCode(
        total.CommissionCurrencyCode
      );
    }
    total.UnitCommisson = unitCommisson;

    if (resultTemp) {
      resultTemp.push(total);
    }
  }

  getArapResultsDataFromByDataIndex(dataIndex) {
    const object = DataContext.get('arapResults');
    let obj = null;
    for (let i = 0; i < object.length; i += 1) {
      if (object[i].dataIndex == dataIndex) {
        obj = object[i];
      }
    }
    return obj;
  }

  onInstallmentDialogClickedCancel() {
    UIDialog.hide('installmentPlanInfoDialog');
  }

  onDownloadXMLlinkClick(event) {
    const msgNo = event.paramJson['0'].value;
    new ARAPSearchAction().downloadDDdcXMLByMsgNo(msgNo);
  }

  downloadDDdcXMLByMsgNo(msgNo) {
    const url = urlConfig.dd_downloadXMLByMsgNo + msgNo;
    try {
      new ARAPSearchAction().ajaxGetBlob(url, () => {
        MessageHelper.success(
          i18n.PartyMsg.SuccessMsg.Success_0001,
          null,
          MessageHelper.POSITION_TOP_RIGHT
        );
      });
    } catch (e) {
      MessageHelper.error(
        i18n.PartyMsg.ErrorMsg.Error_0001,
        null,
        MessageHelper.POSITION_TOP_RIGHT
      );
    }
  }

  ajaxGetBlob(suffixUrl, callback) {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', suffixUrl, true);
    xhr.setRequestHeader(
      'Authorization',
      `Bearer ${
      localStorage
        .getItem('Authorization')
        .substr(13)
        .split('&')[0]
      }`
    );
    xhr.responseType = 'blob';
    xhr.onreadystatechange = () => {
      if (
        xhr.readyState == XMLHttpRequest.DONE &&
        xhr.status == 200
      ) {
        let fileName = this.getResponseHeader(
          'Content-Disposition'
        ).split('=')[1];
        if (fileName) {
          const nameStr = fileName.split('"');
          if (nameStr && nameStr.length === 3) {
            fileName = nameStr[1];
          }
        }
        const blob = new Blob([this.response], {
          type: this.getResponseHeader('content-type'),
        });
        if (!!window.ActiveXObject || 'ActiveXObject' in window) {
          window.navigator.msSaveBlob(blob, fileName);
        } else {
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
        }
        if (callback) {
          callback();
        }
      }
    };
    xhr.send(null);
  }

  onClickedNote(event) {
    const noteModel = {
      refId: event.getParameter('refId'),
      noteType: event.getParameter('noteType'),
      tableId: event.getParameter('tableId'),
    };
    DataContext.put('noteParameter', noteModel);
    UIDialog.show('noteDialog');
  }
}
