/* eslint-disable react/jsx-no-bind */
import {
  UIDataTable,
  MessageHelper,
  UIBox,
  UIButton,
  UIDialog,
  UpdateContext,
} from 'RainbowUI';
import PartyConst from '../../../party/const/PartyConst';
import PartyAction from '../../../party/actions/PartyAction';
import PtyIntegrationSearchPanel from './PtyIntegrationSearchPanel';

const PtyIntegrationSearchDialog = React.createClass({
  getInitialState() {
    return {
      RoleName: this.props.RoleName,
      isVisibled: this.props.isVisibled,
    };
  },

  show() {
    UIDialog.show(this.props.id);
  },

  hide() {
    UIDialog.hide(this.props.id);
    if (this.props.onHide) {
      this.props.onHide();
    }
    if (this.props.forceUpdate) {
      UpdateContext.forceUpdate('subclaimInfo');
    }
  },

  save() {
    const selectData = UIDataTable.getSelectedRecord('PartyRecord');
    if (!selectData.length) {
      MessageHelper.warning(i18n.PartyMsg.WarnMsg.Warn_0003);
      return;
    }
    const partyAction = new PartyAction();
    const partyRoleStatus = selectData[0].PartyRoleStatus;
    if (!this.props.isSearch) {
      if (PartyConst.PARTY_ROLE_STATUS_INACTIVE == partyRoleStatus) {
        MessageHelper.warning(
          i18n.PartyMsg.WarnMsg.Warn_0004,
          null,
          MessageHelper.POSITION_TOP_RIGHT
        );
        return;
      }
    }
    UIDialog.hide(this.props.id);
    let party = partyAction.loadPartyByPartyId(selectData[0].PartyId);
    console.info('save Info: ', !partyRoleStatus);
    if (!partyRoleStatus) {
      if (PartyConst.PARTY_ROLE_NAME_PAYER == this.state.RoleName) {
        party = partyAction.createPayerRole(party);
      } else if (
        PartyConst.PARTY_ROLE_NAME_INTRODUCER == this.state.RoleName
      ) {
        party = partyAction.createIntroducerRole(party);
      } else if (
        PartyConst.PARTY_ROLE_NAME_CLAIMANT == this.state.RoleName
      ) {
        party = partyAction.createClaimAntRole(party);
      }
    }
    this.props.getSearchResult(party);
  },

  createNewCustomer() {
    this.props.createClicked();
  },

  render() {
    let title = i18n.SearchIntroducer;
    if (this.props.title) {
      title = this.props.title;
    }
    const displayPage = (
      <PtyIntegrationSearchPanel
        RoleName={this.props.RoleName}
        routeName={this.props.routeName}
        createNewCustomer={this.createNewCustomer}
        isVisibled={this.state.isVisibled}
        roleType={this.props.roleType}
        claimFlag={this.props.claimFlag}
        isSearchRole={this.props.isSearchRole}
        enableChangePartyCategory={
          this.props.enableChangePartyCategory
        }
      />
    );
    return (
      <UIDialog
        title={title}
        width="85%"
        id={this.props.id}
        onClose={this.hide}
      >
        {displayPage}
        <UIBox direction="center">
          <UIButton
            value={i18n.OK}
            onClick={this.save}
            visibled={this.state.isShowOkButton}
          />
        </UIBox>
      </UIDialog>
    );
  },
});
export default PtyIntegrationSearchDialog;
