import {
  UIBox,
  UIDialog,
  UIUpdatePanel,
  UIText,
  UIButton,
  UISmartPanelGrid,
} from 'RainbowUI';
const ProductDialogAction = require('../action/ProductDialogAction');


const NewProductDialog = React.createClass({
  saveProduct() {
    ProductDialogAction.saveProduct();
    this.props.parentFunction();
    console.log('saveProduct');
  },
  render() {
    return (
      <UIDialog
        id="newProductDialog"
        title={i18n.ClaimProduct.Product}
        width="80%"
        height="600px"
        modal="false"
      >
        <UIUpdatePanel
          id="newProductDialogUIUpdate"
          render={this.renderDialogContent}
        />
        <UIBox direction="left">
          <UIButton
            value={i18n.ClaimProduct.Save}
            onClick={this.saveProduct}
            validationGroup="productType"
            causeValidation="true"
          />
          <UIButton
            value={i18n.ClaimProduct.Cancel}
            buttonType="cancel"
          />
        </UIBox>
      </UIDialog>
    );
  },

  renderDialogContent() {
    return (
      <UISmartPanelGrid column="2" widthAllocation="4,8">
        <UIText
          io="out"
          id="productCode"
          label={i18n.ClaimProduct.ProductCode}
          value="#{showProduct.ProductCode}"
          required="true"
          validationGroup="productType"
        />
        <UIText
          id="productName"
          label={i18n.ClaimProduct.ProductName}
          value="#{showProduct.ProductName}"
          required="true"
          validationGroup="productType"
        />
        <UIText
          id="productDescription_"
          label={i18n.ClaimProduct.ProductDescription}
          value="#{showProduct.ProductDescription}"
        />
        <UIText
          id="policySysCode_"
          label={i18n.ClaimProduct.ExtProductCode}
          value="#{showProduct.ExtProductCode}"
        />
      </UISmartPanelGrid>
    );
  },
});
export default NewProductDialog;
