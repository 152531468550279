import {
  AjaxUtil,
  MessageHelper,
  DataContext,
  UIDataTable,
} from 'RainbowUI';
import alt from '../alt.js';
import Url from '../url/Url';
import SearchTaskMessage from './SearchTaskMessage';

class MassiveReassignmentTaskOwnerAction {
  getSharePoolList(pageindex, pagesize, claimTaskInstance) {
    const pageNo = (pageindex - 1) / pagesize + 1;
    console.log(
      'call MassiveReassignmentTaskOwnerAction getSharePoolList method'
    );
    let localUrl = Url.ClaimWorkflowQueryClaimTasks;
    let result = null;
    claimTaskInstance.PageSize = pagesize;
    claimTaskInstance.PageIndex = pageNo;
    delete claimTaskInstance.flag;
    if (env.IS_MOCK) {
      localUrl = 'workflow/getTaskList';
    }
    AjaxUtil.doPost(localUrl, claimTaskInstance, {
      async: false,
      done(data) {
        if (data.Model.Results == null) {
          data.Model.Results = [];
          const SolrDocs = {};
          data.Model.Results.push(SolrDocs);
        }

        if (data.Status == 'OK') {
          result = data;
        } else {
          MessageHelper.error(
            i18n.ClaimCommom.ErrorMsg.GetPoolTaskFail.replace(
              '{0}',
              data.Model.Messages[0].Message
            ),
            i18n.ClaimCommom.ErrorMsg.FailUPPERCASE,
            MessageHelper.POSITION_TOP_FULL_WIDTH
          );
        }
      },
      fail(data) {
        MessageHelper.error(
          i18n.ClaimCommom.ErrorMsg.GetPoolTaskFail.replace(
            '{0}',
            data.Model.Messages[0].Message
          ),
          i18n.ClaimCommom.ErrorMsg.FailUPPERCASE,
          MessageHelper.POSITION_TOP_FULL_WIDTH
        );
      },
    });
    const resultObj = {
      count: result.Model.Total,
      result: result.Model.Results[0].SolrDocs,
    };
    const searchTaskMessage = new SearchTaskMessage(resultObj);
    searchTaskMessage.showMessage();
    return resultObj;
  }

  submitAssginTo(massiveReassignmentTaskOwnerVO) {
    console.log(
      'call MassiveReassignmentTaskOwnerAction submitAssginTo method'
    );
    let status = 'F';
    let susList = [];
    let failList = [];
    const localUrl = Url.ClaimWorkflowMassiveReassignmentTaskOwner;
    AjaxUtil.doPost(localUrl, massiveReassignmentTaskOwnerVO, {
      async: false,
      done(data) {
        if (data.Status == 'OK') {
          // result = data;
          // UIDialog.hide('MassiveReassignmentTaskOwnerDialog');
          status = 'S';
          susList = data.Model.susList;
          failList = data.Model.failList;
          // MessageHelper.info(i18n.ClaimCommon.submitSuccessfully,i18n.ClaimCommon.MassiveReassignmentTaskowner,MessageHelper.POSITION_TOP_FULL_WIDTH);
        } else {
          MessageHelper.error(
            i18n.ClaimCommom.ErrorMsg.SubmitFailP.replace(
              '{0}',
              data.Model.Messages[0].Message
            ),
            i18n.ClaimCommom.ErrorMsg.FailUPPERCASE,
            MessageHelper.POSITION_TOP_FULL_WIDTH
          );
        }
      },
      fail(data) {
        MessageHelper.error(
          i18n.ClaimCommom.ErrorMsg.GetPoolTaskFail.replace(
            '{0}',
            data.Model.Messages[0].Message
          ),
          i18n.ClaimCommom.ErrorMsg.FailUPPERCASE,
          MessageHelper.POSITION_TOP_FULL_WIDTH
        );
      },
    });
    return { status, susList, failList };
  }

  getSharePoolDetail(event) {
    const data = event.getParameter('data');
    alert(JSON.stringify(data));
  }

  onRowSelect(event) {
    const data = event.getSelectRecord('workflowsharepoollist');
    DataContext.put('SharePoolTask', data);
  }

  searchTask(condition) {
    console.log('============search task owner:', condition);
    this.dispatch(condition);
  }

  updateSelectedTask() {
    const selecteds = UIDataTable.getSelectedRecord(
      'workflowsharepoollist'
    );
    console.log(JSON.stringify(selecteds));
    const taskInstances = [];
    for (let i = 0; i < selecteds.length; i++) {
      const taskInstance = {};
      taskInstance.TaskInstanceId = selecteds[i].id;
      taskInstance.TaskCode = selecteds[i].TaskDefinitionKey;
      taskInstance.DueDate = selecteds[i].DueDate;
      taskInstance.Priority = selecteds[i].TaskPrioriry;
      taskInstance.CaseId = selecteds[i].CaseId;
      taskInstance.AssignTo = selecteds[i].TaskAssignee;
      taskInstance.TaskDescription = selecteds[i].TaskDescription;
      taskInstances.push(taskInstance);
    }
    AjaxUtil.doPost(
      Url.ClaimWorkflowQueryClaimTasks,
      JSON.stringify(taskInstances),
      {
        async: false,
        done(data) {
          if (data.Status == 'OK') {
            MessageHelper.info(
              'claimTask Success',
              '',
              MessageHelper.POSITION_TOP_FULL_WIDTH
            );
          } else {
            MessageHelper.error(
              i18n.ClaimCommom.ErrorMsg.ClaimTaskFailP.replace(
                '{0}',
                data.Model.Messages[0].Message
              ),
              i18n.ClaimCommom.ErrorMsg.FailUPPERCASE,
              MessageHelper.POSITION_TOP_FULL_WIDTH
            );
          }
        },
        fail(data) {
          MessageHelper.error(
            i18n.ClaimCommom.ErrorMsg.GetPoolTaskFail.replace(
              '{0}',
              data.Model.Messages[0].Message
            ),
            i18n.ClaimCommom.ErrorMsg.FailUPPERCASE,
            MessageHelper.POSITION_TOP_FULL_WIDTH
          );
        },
      }
    );
  }
}
export default alt.createActions(MassiveReassignmentTaskOwnerAction);
