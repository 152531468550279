import { UITabItem, UITab, UIParam, MessageHelper } from 'RainbowUI';
import SubclaimContent from './SubclaimContent';
import ReserveAction from '../../../reserve/action/ReserveAction';
import ClaimHandlingAction from '../../action/ClaimHandlingAction';

const ClaimTabs = React.createClass({
  componentDidUpdate() {
    if (this.props.claimData.reloadReserveByObjectId) {
      ReserveAction.initReserve(
        this.props.claimData.reloadReserveByObjectId
      );
      this.props.claimData.reloadReserveByObjectId = null;
    }
  },

  componentDidMount() {
    const objectDatas = this.props.claimData.ObjectDatas;
    if (!objectDatas || objectDatas.length < 1) {
      if (this.props.registrationHandling)
        this.props.registrationHandling.saveClaim();
    } else {
      const firstSublaim = this.props.claimData.ObjectDatas[0];

      if (
        !this.props.claimData.TaskInstanceId &&
        firstSublaim.StatusCode &&
        firstSublaim.StatusCode != 'NEW' &&
        firstSublaim.StatusCode != 'REJECT'
      ) {
        // do nothing
      }
    }
  },

  render() {
    let tabItems = [];
    const {
      subclaimInfo,
      subclaimLink,
      subclaimTabs,
      claimData,
      claimTabs,
      objectAction,
      showReserve,
      taskInstanceInfo,
      dtClaimData,
      authMap,
      clickModifyHistory,
      registrationHandling,
    } = this.props;
    const objectDatas = claimData.ObjectDatas;
    const tabs = claimTabs;
    let activeIndex = 0;
    const taskInfo = taskInstanceInfo || {};
    let contentIndex;
    if (objectDatas && objectDatas.length > 0) {
      tabItems = objectDatas.filter((objectVo, index) => {
        if (
          taskInfo.TaskCode &&
          (taskInfo.TaskCode.indexOf('ClaimFI') != -1 ||
            taskInfo.TaskCode.indexOf('ClaimAppraisal') != -1)
        ) {
          if (objectVo.ObjectId != taskInfo.AttachTo) {
            return false;
          }
          contentIndex = index;
        }
        return true;
      });
      tabItems = tabItems.map((objectVo, tmpindex) => {
        let index = tmpindex;
        const subclaimInfoNew = $.extend(true, {}, subclaimInfo);
        let tabParam = [];
        if (objectVo.IsActive == 1) {
          activeIndex = index;
        }

        if (objectVo.Params && objectVo.Params.length > 0) {
          tabParam = objectVo.Params.map(param => {
            return <UIParam name={param.name} value={param.value} />;
          });
        }
        tabParam.push(
          <UIParam
            name="showReserve"
            value={objectVo.ObjectId ? showReserve : 'false'}
          />
        );
        tabParam.push(
          <UIParam name="objectId" value={objectVo.ObjectId} />
        );
        tabParam.push(<UIParam name="action" value={objectAction} />);
        tabParam.push(<UIParam name="isSubclaimTab" value="true" />);

        if (contentIndex != null && contentIndex != '') {
          index = contentIndex;
        }
        return (
          <UITabItem
            title={`${i18n.ClaimCommon.Subclaim} ${objectVo.Name}`}
            param={tabParam}
          >
            <div>
              <SubclaimContent
                claimData={claimData}
                dtClaimData={dtClaimData}
                id={index}
                authMap={authMap}
                content={subclaimInfoNew}
                subclaimLink={subclaimLink}
                parentComponent={this}
                subclaimTabs={subclaimTabs}
                objectVo={objectVo}
                clickModifyHistory={clickModifyHistory}
                taskInstanceInfo={taskInstanceInfo}
                showReserve={
                  objectVo.ObjectId ? showReserve : 'false'
                }
              />
            </div>
          </UITabItem>
        );
      });
    }
    if (
      !(
        taskInfo.TaskCode &&
        (taskInfo.TaskCode.indexOf('ClaimFI') != -1 ||
          taskInfo.TaskCode.indexOf('ClaimAppraisal') != -1)
      )
    ) {
      tabItems.push(
        <UITabItem
          title={i18n.ClaimCommon.New}
          param={[<UIParam name="newSubclaim" value="true" />]}
        >
          <div />
        </UITabItem>
      );
    }

    if (tabs && tabs.length > 0) {
      const beforeTabSize = tabItems.length;
      $.each(tabs, (index, tab) => {
        if (tab.IsActive == 1) {
          activeIndex = index + beforeTabSize;
        }
        let tabParam = [];
        if (tab.params && tab.params.length > 0) {
          tabParam = tab.params.map(param => {
            return <UIParam name={param.name} value={param.value} />;
          });
        }
        tabParam.push(<UIParam name="action" value={tab.action} />);

        tabItems.push(
          <UITabItem title={tab.title} param={tabParam}>
            <div>{tab.content}</div>
          </UITabItem>
        );
      });
    }

    activeIndex =
      tabItems.length > 1 ? tabItems.length - 1 : tabItems.length;
    if (dtClaimData || registrationHandling) {
      return (
        <UITab
          id="claimTabs-UITab"
          lazyLoad="false"
          activeIndex={activeIndex}
          onBeforeTabChange={this.onBeforeTabCliked}
          onTabChange={this.claimTabAction}
        >
          {tabItems}
        </UITab>
      );
    }
    return <div />;
  },

  onBeforeTabCliked(event) {
    if (
      event &&
      event.getParameter('newSubclaim') === 'true' &&
      !this.props.registrationHandling
    ) {
      const lastSubclaimIndex =
        this.props.claimData.ObjectDatas.length - 1;
      if (
        !this.props.claimData.ObjectDatas[lastSubclaimIndex].ObjectId
      ) {
        MessageHelper.error(i18n.ClaimCommon.NewSubclaimShouldSubmit);
        return false;
      }
    }
    return true;
  },

  claimTabAction(event) {
    if (event.getParameter('action')) {
      event.getParameter('action')(event);
    }
    if (event.getParameter('action_assign')) {
      AssignmentAction.initAssignment(this.props.claimData.CaseId);
    }

    if (event.getParameter('newSubclaim') === 'true') {
      this.clickNewTab();
      this.handleRegistrationActionButtons(true);
    }

    if (event.getParameter('isSubclaimTab') === 'true') {
      this.clickSubclaimTab(event);
      this.handleRegistrationActionButtons(true);
    } else if (event.getParameter('newSubclaim') != 'true') {
      this.handleRegistrationActionButtons(false);
    }
  },

  clickNewTab() {
    if (this.props.registrationHandling) {
      this.props.registrationHandling.saveClaim();
    } else {
      const lastSubclaimIndex =
        this.props.claimData.ObjectDatas.length - 1;
      if (
        this.props.claimData.ObjectDatas[lastSubclaimIndex].ObjectId
      ) {
        ClaimHandlingAction.newSubclaim();
      }
    }
  },

  clickSubclaimTab(event) {
    if (event.getParameter('showReserve') === 'true') {
      const objectId = event.getParameter('objectId');

      if (objectId != null && objectId != '') {
        sessionStorage.setItem('claim_objectId', objectId);
        ReserveAction.initReserve(objectId);
      }
    }
  },

  handleRegistrationActionButtons(isShow) {
    if (this.props.registrationHandling) {
      this.props.registrationHandling.handleButtons(isShow);
    }
  },
});
export default ClaimTabs;
