export default {
  PLACEHOLDER_FIELDNAME: '{fieldName}',
  PLACEHOLDER_NEW_VALUE: '{newValue}',
  PLACEHOLDER_OLD_VALUE: '{oldValue}',
  CLAIM_LEVEL: 1,
  SUBCLAIM_LEVEL: 2,
  COVERAGE_LEVEL: 3,
  RESERVE_LEVEL: 4,
  REST_RESTPONSE_STATUS_OK: 'OK',
  REST_RESTPONSE_STATUS_WARN: 'WARN',
  REST_RESTPONSE_STATUS_BLOCK: 'BLOCK',
  REST_RESTPONSE_STATUS_ERROR: 'ERROR',

  AUDIT_EVENT_TYPE_CLAIM: 200,
  AUDIT_EVENT_TYPE_SUBCLAIM: 201,
  AUDIT_EVENT_TYPE_LITIGATION: 202,

  AUDIT_ADD: 'add',
  AUDIT_REMOVE: 'remove',
  AUDIT_REPLACE: 'replace',
  CLAIMANT_NAME: 'ClaimantName',

  CLAIM_TASK_CODE: {
    Registration: 'ClaimRegistrationTask',
    Settlement: 'ClaimSettlementTask',
    SettlementApproval: 'ClaimSettlementApprovalTask',
    Recovery: 'ClaimRecoveryTask',
    RecoveryApproval: 'ClaimRecoveryApprovalTask',
    Calculation: 'ClaimCalculationTask',
    CalculationApproval: 'ClaimCalculationApprovalTask',
    ReserveUpdateApprovalTask: 'ClaimReserveUpdateApprovalTask',
    ReserveCloseApprovalTask: 'ClaimReserveCloseApprovalTask',
    AppraisalProperty: 'ClaimAppraisalPropertyTask',
    AppraisalVehicle: 'ClaimAppraisalVehicleTask',
    AppraisalBodilyInjury: 'ClaimAppraisalBodilyInjuryTask',
    AppraisalIApproval: 'ClaimAppraisalIApprovalTask',
    AppraisalPApproval: 'ClaimAppraisalPApprovalTask',
    AppraisalVApproval: 'ClaimAppraisalVApprovalTask',
    FieldInvestigationProperty: 'ClaimFIPropertyTask',
    FieldInvestigationVehicle: 'ClaimFIVehicleTask',
    FieldInvestigationBodilyInjury: 'ClaimFIBodilyInjuryTask',
    Salvage: 'ClaimSalvageTask',
    SalvageApproval: 'ClaimSalvageApprovalTask',
    Subrogation: 'ClaimSubrogationTask',
    SubrogationApproval: 'ClaimSubrogationApprovalTask',
    Refund: 'ClaimRefundTask',
    RefundApproval: 'ClaimRefundApprovalTask',
  },

  PARTY_TYPE_INDIVIDUAL: '1',
  PARTY_TYPE_ORGANIZATION: '2',
  CUSTOMER_PARTY_INDIVIDUAL:
    'PartyIndividualCustomer-PartyIndividualCustomer',
  CUSTOMER_PARTY_ORGANIZATION: 'PartyOrgCustomer-PartyOrgCustomer',

  PARTY_ROLE: {
    Claimant: '01',
    CarRentalComapny: '02',
    Lawyer: '03',
    PolicyHolder: '07',
    Beneficary: '08',
    Insured: '09',
    Payee: '04',
    HealthCareProvider: '05',
    Garage: '06',
    Recovery: '08',
    LegalFirm: '09',
    CoInsurance: '11',
  },
  PAY_MODE: {
    Cash: 100,
    Cheque: 101,
    DirectDebit: 102,
    BankTransfer: 106,
    CreditCard: 107,
    Others: 108,
    DirectCredit: '109',
  },
  AUTHORITY_CLAIM_PARTY: 'CLAIM_PARTY',
  AUTHORITY_CLAIM_OWNER_ASSIGNMENT: 'CLAIM_OWNER_ASSIGNMENT',
  AUTHORITY_CLAIM_TASK_ASSIGNMENT: 'TASK_ASSIGNMENT',
  RESERVE_STATUS: {
    Closed: 'CLOSED',
    Open: 'OPEN',
    Reopen: 'REOPEN',
    Unopen: 'UNOPEN',
  },
  CASE_STATUS: {
    FNOL: '1',
    Outstanding: '2',
    Settled: '3',
    Rejected: '4',
  },
  Claim: 'Claim',
  Subclaim: 'Subclaim',
  CoLeader: {
    PayFull: 'full',
    PayPortion: 'portion',
    IsPayFull: true,
  },
  CoFollower: {
    ToCoLeader: 'leader',
    ToOthers: 'others',
  },
  BooleanConstant: {
    TRUE: 'Y',
    FALSE: 'N',
  },
  CoInsuraceBankCode: '101',
  Cancel: 'CANCEL',
  Paid: 'PAID',
  Settled: 'SETTLED',
  LitigationAttachTo: 'LitigationAttachTo',
  All: 'all',
  URP_FUNCTION_TYPE: 6,
  URP_MODULE_ID: 370,
  URP_MODULE_GROUP_ID_MENU: 200,
  URP_MODULE_GROUP_ID_AUTHORITY: 201,
};
