import {
  MessageHelper,
  UIParam,
  UISelect,
  UILink,
  UIColumn,
  UIDataTable,
  UIPanel,
  DataContext,
} from 'RainbowUI';
import DefinedCodeTable from '../codetable/CodeTable';
import EditAdditionalDialog from './EditAdditionalDialog';
import EditAdditionalDialogReadOnly from './readonly/views/EditAdditionalDialogReadOnly';
import PartyAction from '../actions/PartyAction';

const PartyIndAddInfo = React.createClass({
  getInitialState() {
    let { party } = this.props;
    if (DataContext.get('Party')) {
      party = DataContext.get('Party');
    }
    let { PartyAdditionalList } = party;
    if (!PartyAdditionalList) {
      PartyAdditionalList = [];
    }
    _.forEach(PartyAdditionalList, (temppartyAdditional, index) => {
      const partyAdditional = temppartyAdditional;
      partyAdditional.DefinedIndex = index + 1;
    });
    const totalNum = PartyAdditionalList.length;
    return {
      PartyAdditionalList,
      TotalNum: totalNum,
      party,
    };
  },

  createAdditionalHandler() {
    const definedIndex = this.state.TotalNum + 1;
    const createPartyAdditional = {
      '@type': 'PartyAdditional-PartyAdditional',
      DefinedIndex: definedIndex,
    };
    this.refs.editPartyAdditionalDialog.showDialog(
      createPartyAdditional
    );
  },

  savePartyAdditional(partyAdditional) {
    const partyAction = new PartyAction();
    const existsParty = partyAction.loadPartyByPartyId(
      this.state.party.PartyId
    );
    const { PartyHistoryList } = existsParty;
    if (partyAdditional['@pk']) {
      const newPartyHistory = {
        '@type': 'PartyHistory-PartyHistory',
        BusinessObjectId: 75158352,
        HistoryType: 10,
      };
      PartyHistoryList.push(newPartyHistory);
    } else {
      const newPartyHistory = {
        '@type': 'PartyHistory-PartyHistory',
        BusinessObjectId: 75158352,
        HistoryType: 9,
      };
      PartyHistoryList.push(newPartyHistory);
    }
    this.state.party.PartyHistoryList = PartyHistoryList;
    const { PartyAdditionalList } = this.state;
    const definedIndex = partyAdditional.DefinedIndex;
    for (let idx = 0; idx < PartyAdditionalList.length; idx += 1) {
      if (PartyAdditionalList[idx].DefinedIndex == definedIndex) {
        PartyAdditionalList.splice(idx, 1);
        break;
      }
    }
    PartyAdditionalList.push(partyAdditional);
    const { party } = this.state;
    party.PartyAdditionalList = PartyAdditionalList;
    party.PartyAccountList = existsParty.PartyAccountList;
    party.PartyContactList = existsParty.PartyContactList;
    party.AddressVoList = existsParty.AddressVoList;
    let result = this.props.submitInfoHandler(party, 5);
    if (!(result === 'fail')) {
      _.forEach(
        result.PartyAdditionalList,
        (temppartyAdditional, index) => {
          const partyAdditional = temppartyAdditional;
          partyAdditional.DefinedIndex = index + 1;
        }
      );
      const resultList = result.PartyAdditionalList;
      this.setState({
        party: result,
        PartyAdditionalList: resultList,
        TotalNum: PartyAdditionalList.length + 1,
      });
    }
    if (result === 'fail') {
      const partyAction = new PartyAction();
      result = partyAction.loadPartyByPartyId(party.PartyId);
      _.forEach(
        result.PartyAdditionalList,
        (temppartyAdditional, index) => {
          const partyAdditional = temppartyAdditional;
          partyAdditional.DefinedIndex = index + 1;
        }
      );
      this.setState({
        party: result,
        PartyAdditionalList: result.PartyAdditionalList,
        TotalNum: result.PartyAdditionalList.length + 1,
      });
      return 'fail';
    }
    return 'success';
  },

  editAdditionalHandler() {
    const selectData = UIDataTable.getSelectedRecord(
      'partyAdditionalInfo'
    );
    if (!selectData.length) {
      MessageHelper.warning(
        i18n.PartyMsg.WarnMsg.Warn_0005,
        i18n.PartyMsg.WarnMsg.Warn_0003,
        MessageHelper.POSITION_TOP_RIGHT
      );
      return;
    }
    const partyAdditional = selectData[0];
    this.refs.editPartyAdditionalDialog.showDialog(partyAdditional);
  },

  viewHandler(clickEvent) {
    let partyAdditional = {};
    if (!_.isEmpty(clickEvent.paramJson)) {
      partyAdditional = clickEvent.paramJson[0].value;
    }
    this.refs.editPartyAdditionalDialogReadOnly.showDialog(
      partyAdditional
    );
  },

  render() {
    const _self = this;
    const { PartyAdditionalList } = this.state;
    return (
      <div>
        <UIPanel panelTitle={i18n.AdditionalId}>
          <UIDataTable
            id="partyAdditionalInfo"
            value={PartyAdditionalList}
            functions={_self.buildTableFunction()}
            indexable="false"
          >
            <UIColumn selectionMode="single" />
            <UIColumn
              headerTitle={i18n.IdType}
              value="IdType"
              render={data => {
                return (
                  <UISelect
                    model={data}
                    property="IdType"
                    codeTable={DefinedCodeTable.IdType}
                    io="out"
                  />
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.IdNo}
              render={data => {
                return (
                  <div>
                    <UILink
                      value={data.IdNumber}
                      onClick={this.viewHandler}
                    >
                      <UIParam name="data" value={data} />
                    </UILink>
                  </div>
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.CountryOfIssue}
              render={data => {
                return (
                  <UISelect
                    model={data}
                    property="Country"
                    codeTable={DefinedCodeTable.getCountry}
                    io="out"
                  />
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.Status}
              render={data => {
                return (
                  <UISelect
                    model={data}
                    property="Status"
                    codeTable={DefinedCodeTable.getPartyStatus}
                    io="out"
                  />
                );
              }}
            />
          </UIDataTable>
          <EditAdditionalDialog
            ref="editPartyAdditionalDialog"
            submitHandler={this.savePartyAdditional}
          />
          <EditAdditionalDialogReadOnly
            ref="editPartyAdditionalDialogReadOnly"
            submitHandler={this.savePartyAdditional}
          />
        </UIPanel>
      </div>
    );
  },
  buildTableFunction() {
    const _self = this;
    return (
      <div>
        <UILink
          icon="fa fa-plus"
          size="lg"
          iconClass="add_iconbtn"
          tooltip={i18n.add}
          onClick={_self.createAdditionalHandler}
        />
        <UILink
          icon="fa fa-pencil"
          size="lg"
          iconClass="edit_iconbtn"
          tooltip={i18n.edit}
          onClick={_self.editAdditionalHandler}
        />
      </div>
    );
  },
});

export default PartyIndAddInfo;
