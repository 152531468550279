import {
  UIPage,
  UIPanel,
  UIUpdatePanel,
  UpdateContext,
  UIDateRangePicker,
  UIParam,
  UIBox,
  UIText,
  UIDataTable,
  MessageHelper,
  UIDateTimePicker,
  UISmartPanelGrid,
  UIButton,
  UIColumn,
  UILink,
  UISelect,
} from 'RainbowUI';

import BindToMixin from 'react-binding';
import Url from '../../url/Url';

const CodeTable = require('../../codeTable/CodeTable');
const RtaAction = require('../../action/RtaAccidentAction');

const AccidentRta = React.createClass({
  mixins: [BindToMixin],
  getInitialState() {
    return {
      Condition: {},
      FuzzyConditions: {},
      nCondition: {},
      nFuzzyConditions: {},
      AccidentList: {},
      Search: false,
    };
  },
  clickSearch() {
    this.state.Search = true;
    UpdateContext.forceUpdate('updateSearchResult');
  },
  Clear() {
    this.setState({ nCondition: {}, nFuzzyConditions: {} });
  },
  onEdit(accident) {
    window.location.hash = `#/Accident/RtaDetail/${accident.paramJson['0'].value.entity_id}`;
  },
  getPolicySchedule(pageindex, pagesize) {
    if (this.state.nCondition.AccidentStatus) {
      this.state.nCondition.AccidentStatus = parseInt(
        this.state.nCondition.AccidentStatus,
        10
      );
    }
    if (this.state.Search) {
      this.state.Condition.AccidentTime_from = this.state.nCondition.AccidentTime_from;
      this.state.Condition.AccidentTime_to = this.state.nCondition.AccidentTime_to;
      this.state.Condition.AccidentStatus = this.state.nCondition.AccidentStatus;
      this.state.FuzzyConditions.RegNos = this.state.nFuzzyConditions.RegNos;
    }

    const page = parseInt(pageindex / pagesize, 1) + 1;

    const querys = {
      Module: 'rtaAccident',
      PageNo: page,
      PageSize: pagesize,
      SortField: 'index_time',
      SortType: 'desc',
      Conditions: this.state.Condition,
      FuzzyConditions: this.state.FuzzyConditions,
    };

    const result = RtaAction.queryAccidents(querys);
    if (this.state.Search) {
      this.state.Search = false;
      if (!result.count) {
        MessageHelper.warning(
          i18n.ClaimCommon.NoRecordFound,
          null,
          MessageHelper.POSITION_TOP_RIGHT
        );
      }
    }
    if (result) {
      return { count: result.count, result: result.result };
    }
    return { count: 0, result: [] };
  },

  render() {
    const _self = this;
    return (
      <div>
        <UIPage>
          <UIText label={i18n.ClaimCommon.AccidentFrom3rd} io="out" />

          <UIPanel
            id="searchCriteria"
            panelTitle={i18n.ClaimCommon.AccidentFromRTA}
          >
            <UISmartPanelGrid
              id="idUISmartPanelGrid"
              layout="horizontal"
              column="3"
            >
              <UIDateRangePicker
                format="MM/DD/YYYY"
                label={i18n.ClaimCommon.AccidentDate}
                validationGroup="search"
                ddateLimit={{ months: 1 }}
                maxDate="TODAY"
                startValueLink={this.bindToState(
                  'nCondition',
                  'AccidentTime_from'
                )}
                endValueLink={this.bindToState(
                  'nCondition',
                  'AccidentTime_to'
                )}
              />
              <UIText
                label={i18n.ClaimCommon.VehicleRegistrationNo}
                valueLink={this.bindToState(
                  'nFuzzyConditions',
                  'RegNos'
                )}
              />
              <UISelect
                label={i18n.ClaimCommon.AccidentStatus}
                codeTable={CodeTable.rtaAccidentStatus}
                valueLink={this.bindToState(
                  'nCondition',
                  'AccidentStatus'
                )}
              />
            </UISmartPanelGrid>
            <UIBox direction="left">
              <UIButton
                value={i18n.ClaimCommon.Search}
                validationGroup="search"
                causeValidation="true"
                onClick={this.clickSearch}
              />

              <UIButton
                value={i18n.ClaimCommon.Reset}
                onClick={this.Clear}
              />
            </UIBox>
          </UIPanel>
          <UIUpdatePanel id="updateSearchResult">
            <UIDataTable
              id="reassignmentList"
              indexable="false"
              provider={_self.getPolicySchedule}
              headerTitle={i18n.ClaimCommon.SearchResult}
              detailable="true"
              rowDetailRender={data => {
                const accident = RtaAction.loadAccident(
                  data.entity_id
                );
                if (accident.VehicleList) {
                  for (
                    let i = 0;
                    i < accident.VehicleList.length;
                    i += 1
                  ) {
                    const veh = accident.VehicleList[i];
                    veh.AccidentId = data.entity_id;
                  }
                }
                return React.renderToString(
                  <div>
                    <UIDataTable
                      value={accident.VehicleList}
                      pageable="false"
                      indexable="false"
                      styleClass="primary"
                    >
                      <UIColumn
                        headerTitle={i18n.ClaimCommon.RegNo}
                        value="RegNo"
                      />
                      <UIColumn
                        headerTitle={i18n.ClaimCommon.MatchPolicyNo}
                        value="PolicyNo"
                        width="auto"
                        render={data => {
                          if (data.Status !== 4) {
                            return (
                              <div>
                                <UIText
                                  io="out"
                                  value={data.PolicyNo}
                                />
                              </div>
                            );
                          }
                          let msg =
                            i18n.ClaimCommon.NotMatchPolicyMsg;
                          msg = msg.replace(
                            'xxxxxx',
                            data.AccidentId
                          );
                          return (
                            <div>
                              <UIText io="out" value={msg} />
                            </div>
                          );
                        }}
                      />
                      <UIColumn
                        headerTitle={i18n.ClaimCommon.PolicyPeriod}
                        render={data => {
                          return (
                            <div>
                              <UIDateRangePicker
                                io="out"
                                format="MM/DD/YYYY"
                                startValue={data.EffectiveDate}
                                endValue={data.ExpiryDate}
                              />
                            </div>
                          );
                        }}
                      />
                      <UIColumn
                        headerTitle={
                          i18n.ClaimCommon.PolicyHolderName
                        }
                        value="PolicyHolderName"
                        width="auto"
                      />
                    </UIDataTable>
                  </div>
                );
              }}
            >
              <UIColumn
                headerTitle={i18n.ClaimCommon.AccidentId}
                value="entity_id"
              />
              <UIColumn
                headerTitle={i18n.ClaimCommon.AccidentTime}
                value="AccidentTime"
              >
                <UIDateTimePicker
                  io="out"
                  format="MM/DD/YYYY HH:mm:ss"
                  placeHolder="MM/DD/YYYY HH:mm:ss"
                />
              </UIColumn>
              <UIColumn
                headerTitle={i18n.ClaimCommon.Cateogry}
                value="Cateogry"
              />
              <UIColumn
                headerTitle={i18n.ClaimCommon.Classification}
                value="Classification"
              />
              <UIColumn
                headerTitle={i18n.ClaimCommon.CallTime}
                value="AccidentTime"
              >
                <UIDateTimePicker
                  io="out"
                  format="MM/DD/YYYY HH:mm:ss"
                  placeHolder="MM/DD/YYYY HH:mm:ss"
                />
              </UIColumn>
              <UIColumn
                headerTitle={i18n.ClaimCommon.Operator}
                value="Operator"
              />
              <UIColumn
                headerTitle={i18n.ClaimCommon.NoofMatchedPolicy}
                value="MatchPolicyNumber"
              />
              <UIColumn
                headerTitle={i18n.ClaimCommon.AccidentStatus}
                value="AccidentStatus"
              >
                <UISelect
                  codeTable={CodeTable.rtaAccidentStatus}
                  io="out"
                />
              </UIColumn>
              <UIColumn
                headerTitle={i18n.ClaimCommon.Action}
                render={data => {
                  if (data.AccidentStatus == 1) {
                    return (
                      <div>
                        <UILink
                          value={i18n.ClaimCommon.ProcessAccident}
                          onClick={_self.onEdit}
                        >
                          <UIParam name="data" value={data} />
                        </UILink>
                      </div>
                    );
                  }
                  return (
                    <div>
                      <UILink
                        value={i18n.ClaimCommon.ViewAccident}
                        onClick={_self.onEdit}
                      >
                        <UIParam name="data" value={data} />
                      </UILink>
                    </div>
                  );
                }}
              />
            </UIDataTable>
          </UIUpdatePanel>
        </UIPage>
      </div>
    );
  },
});
export default AccidentRta;
