import {
  UITwoText,
  CodeTable,
  UpdateContext,
  config,
} from 'RainbowUI';
import PropTypes from 'prop-types';

import PartyAction from '../../party/actions/PartyAction';

export default class CustomerCodeTableText extends UITwoText {
  /**
   * Init codetable
   */
  initCodeTable() {
    console.info('CustomerCodeTableText');
    if (this.props.condition) {
      if (this.props.descLink) {
        const id_ = this.getComponentValue();
        const text_ = this.props.descLink.value;
        this.codeTable = new CodeTable([{ id: id_, text: text_ }]);
      }
    } else {
      super.initCodeTable();
    }
  }

  initValue() {
    if (this.props.condition) {
      const key = this.getComponentValue();
      let value;
      if (this.props.descLink) {
        value = this.props.descLink.value;
      } else {
        value = this.getCodeTableValue(key);
      }

      $(`#${this.componentId}`).val(key);
      $(`#${this.componentId}_value`).val(value || '');
    } else {
      super.initValue();
    }
  }

  componentDidMount() {
    super._componentDidMount();
    if (this.props.io !== 'out') {
      this.initEvent();
      this.initProperty();
      this.initValidator();
      UpdateContext.put(this.componentId, this);

      const _self = this;
      $(`#${this.componentId}`).blur(event => {
        const key = event.target.value;
        const value = _self.getCodeTableValue(key);
        if (!value) {
          _self.setValue(_self.props.value, '');
          $(`#${_self.componentId}`).val('');
          if (!_self.props.isQuery) {
            $(`#${_self.componentId}_value`).val('');
          }
        } else {
          if (_self.props.onAfterBlur) {
            _self.props.descLink.requestChange(value);
          }
          $(`#${_self.componentId}_value`).val(value);
          if (_self.props.onAfterBlur) {
            _self.props.onAfterBlur(key);
          }
        }
      });
    }
  }

  componentDidUpdate() {
    super._componentDidUpdate();
    if (this.props.io !== 'out') {
      this.initCodeTableEvent();
      this.initProperty();
      this.initValidator();
      this.initComponent();

      UpdateContext.put(this.componentId, this);
    }
  }

  getCodeTableValue(key) {
    let value = '';
    if (!key) {
      return value;
    }
    if (this.props.condition) {
      const partyAction = new PartyAction();
      const result = partyAction.getPartyByCustomerCode(key);
      if (result.PartyName) {
        return result.PartyName;
      }
      const codeTable = this.codeTable.getMap();
      value = codeTable[key]
        ? codeTable[key][config.DEFAULT_CODETABLE_KEYVALUE.VALUE]
        : null;
    } else {
      const codeTable = this.codeTable.getMap();
      value = codeTable[key]
        ? codeTable[key][config.DEFAULT_CODETABLE_KEYVALUE.VALUE]
        : null;
    }
    return value;
  }

  renderInput() {
    const inputRef = this.getInputRefProperty();
    let codePlaceHolder;
    let valuePlaceHolder;
    if (Array.isArray(this.props.placeHolder)) {
      codePlaceHolder = this.props.placeHolder[0];
      valuePlaceHolder = this.props.placeHolder[1];
    } else {
      codePlaceHolder = this.props.placeHolder;
      valuePlaceHolder = this.props.placeHolder;
    }
    return (
      <div>
        <div className="input-group col-sm-12 col-md-12 col-lg-12">
          <div
            className="col-sm-6 col-md-6 col-lg-6"
            style={{ padding: '0px', paddingRight: '15px' }}
          >
            <input
              id={this.componentId}
              name={this.getName()}
              type="text"
              className="form-control"
              placeholder={codePlaceHolder}
              ref={inputRef}
              disabled={this.getDisabled()}
              data-auto-test={this.getNameForTest()}
            />
          </div>

          <div
            className="col-sm-6 col-md-6 col-lg-6"
            style={{ padding: '0px' }}
          >
            <input
              id={`${this.componentId}_value`}
              name={`${this.componentId}_value`}
              type="text"
              className="form-control"
              style={{ width: '90%' }}
              placeholder={valuePlaceHolder}
              disabled={this.getDisabled()}
              data-auto-test={`${this.getNameForTest()}_value`}
            />
          </div>

          <span
            className="input-group-addon fixalliconposition"
            style={{ cursor: 'pointer' }}
            onClick={this.props.onIconClick}
          >
            <span className="fa fa-search" />
          </span>
        </div>
      </div>
    );
  }
}
CustomerCodeTableText.propTypes = $.extend({}, UITwoText.propTypes, {
  onIconClick: PropTypes.func,
  getValueInfo: PropTypes.func,
  condition: PropTypes.string,
  onAfterBlur: PropTypes.func,
  descLink: PropTypes.shape({
    value: PropTypes.string.isRequired,
    requestChange: PropTypes.func.isRequired,
  }),
});
