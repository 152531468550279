import {
  UIPanel,
  UIText,
  UIBox,
  UIButton,
  UIDataTable,
  UIColumn,
  UICurrency,
  MessageHelper,
} from 'RainbowUI';
import BindToMixin from 'react-binding';

const AppraisalTotalCost = React.createClass({
  mixins: [BindToMixin],

  getInitialState() {
    return {
      totalCost: {
        result: [
          {
            totalLaborAmount: this.props.appraisalVehicle.sourceObject
              .appraisalVehicle.TotalLaborAmount,
            totalSparePartAmount: this.props.appraisalVehicle
              .sourceObject.appraisalVehicle.TotalSparePartAmount,
            totalSalvageAmount: this.props.appraisalVehicle
              .sourceObject.appraisalVehicle.TotalSalvageAmount,
            totalNetAmount: this.props.appraisalVehicle.sourceObject
              .appraisalVehicle.TotalNetAmount,
          },
        ],
      },
      AppraisalInfo: this.props.appraisalInfo,
    };
  },

  getTableResult() {
    return this.state.totalCost;
  },

  changeSparePartCost(data) {
    this.state.totalCost.result[0].totalSparePartAmount = data;
  },

  changeLaborCost(data) {
    this.state.totalCost.result[0].totalLaborAmount = data;
  },

  changeSalvageAmt(event) {
    let newValue = Number(event.newValue);
    if (newValue > 0) {
      newValue = 0 - newValue;
    }

    const totalLaborAmount = Number(
      this.state.totalCost.result[0].totalLaborAmount
    );

    const totalSparePartAmount = Number(
      this.state.totalCost.result[0].totalSparePartAmount
    );
    let totalAmount = totalLaborAmount + totalSparePartAmount;

    totalAmount += newValue;

    if (totalAmount < 0) {
      MessageHelper.error(i18n.ClaimAppraisal.CantGreaterAmount);
      this.state.totalCost.result[0].totalSalvageAmount = Number(0);
      this.forceUpdate();
      return;
    }
    this.state.totalCost.result[0].totalSalvageAmount = newValue;
    this.props.parentComponent.changeSalvageCost(newValue);
    this.forceUpdate();
  },

  changeTotalNetCost(data) {
    this.state.totalCost.result[0].totalNetAmount = data;

    this.forceUpdate();
  },

  getVisibled() {
    return this.props.readOnly == true ? 'false' : 'true';
  },
  getIOStyle() {
    return this.props.readOnly == true ? 'out' : null;
  },

  render() {
    const visibled = this.getVisibled();
    const io = this.getIOStyle();
    const { parentComponent, history } = this.props;
    const { CurrencyCode } = parentComponent.state;

    let style;
    if (history) {
      style = 'default';
    } else {
      style = 'primary';
    }
    let indexableTotalCost = 0;
    return (
      <UIPanel
        panelTitle={i18n.ClaimAppraisal.TotalCost}
        toggleable="true"
        styleClass={style}
      >
        <UIDataTable
          id="totalCost"
          pageable="false"
          indexable="false"
          provider={this.getTableResult}
        >
          <UIColumn
            headerTitle={i18n.ClaimAppraisal.LaborCost}
            width="10%"
            required="true"
            render={data => {
              indexableTotalCost += 1;
              return (
                <UICurrency
                  id={`CURRENCY_CODE_TotalLaborAmount${indexableTotalCost}`}
                  defaultValue="0"
                  value={data.totalLaborAmount}
                  required="true"
                  unit={window.EU00.getCurrencySignByCurrencyCode(
                    CurrencyCode
                  )}
                  enabled="false"
                />
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.ClaimAppraisal.SparePartCost}
            required="true"
            width="10%"
            render={data => {
              indexableTotalCost += 1;
              return (
                <UICurrency
                  id={`CURRENCY_CODE_TotalSparePartAmount${indexableTotalCost}`}
                  defaultValue="0"
                  value={data.totalSparePartAmount}
                  required="true"
                  unit={window.EU00.getCurrencySignByCurrencyCode(
                    CurrencyCode
                  )}
                  enabled="false"
                />
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.ClaimAppraisal.NetAmt}
            required="true"
            width="10%"
            render={data => {
              return (
                <UICurrency
                  id={`CURRENCY_CODE_TotalNetAmount${indexableTotalCost}`}
                  defaultValue="0"
                  value={data.totalNetAmount}
                  required="true"
                  unit={window.EU00.getCurrencySignByCurrencyCode(
                    CurrencyCode
                  )}
                  enabled="false"
                />
              );
            }}
          />
        </UIDataTable>
        <UIText
          id="remarkttoAppra"
          label={i18n.ClaimAppraisal.RemarktoAppraisal}
          enabled={visibled}
          io={io}
          valueLink={this.bindToState(
            'AppraisalInfo',
            'AppraisalRemark'
          )}
          colspan="3"
        />
      </UIPanel>
    );
  },

  getTotalCostButtion() {
    const { readOnly } = this.props;
    const a = readOnly == true;
    if (a) {
      return null;
    }

    const buttons = [];

    buttons.push(
      <UIButton
        id="Syncinfo_button"
        value={i18n.ClaimAppraisal.SyncInfo}
      />
    );
    buttons.push(
      <UIButton
        id="save_appra_totalcost_button"
        value={i18n.ClaimAppraisal.Save}
      />
    );
    buttons.push(
      <UIButton
        id="submit_appra_totalcost_button"
        value={i18n.ClaimAppraisal.SubmitTotal}
      />
    );

    return <UIBox direction="left">{buttons}</UIBox>;
  },
});
export default AppraisalTotalCost;
