import { UISelect, UITree, CodeTable } from 'RainbowUI';
import React from 'react';
import ReactDOM from 'react-dom';
import BindToMixin from 'react-binding';
import ProductTreeStore from '../../store/ProductTreeStore';
import ProductTreeAction from '../../action/ProductTreeAction';

let removeMaskFn;
const ProductTree = React.createClass({
  mixins: [BindToMixin],

  getInitialState() {
    return { productCodeTree: null, selectedCode: [] };
  },

  componentWillMount() {
    ProductTreeStore.listen(this.onStoreStateChange);
    ProductTreeAction.loadProductCodeTree();
  },

  componentWillUnmount() {
    ProductTreeStore.unlisten(this.onStoreStateChange);
  },

  get2LevelTreeDate(treenode) {
    const level1 = _.filter(treenode, node => node.pId == '0');
    const level2 = level1.reduce(
      (acc, pnode) =>
        acc.concat(_.filter(treenode, node => node.pId == pnode.id)),
      []
    );
    return [].concat(level1).concat(level2);
  },
  onStoreStateChange(storeState) {
    if (!_.isEmpty(storeState.productCodeTree)) {
      this.setState({ productCodeTree: storeState.productCodeTree });
      this.initSelectedCode();
    }
  },

  createMask() {
    const maskDiv = document.createElement('div');
    maskDiv.style.zIndex = '90';
    maskDiv.style.width = '100%';
    maskDiv.style.height = 'auto';
    maskDiv.style.minHeight = '768px';
    maskDiv.style.position = 'absolute';
    maskDiv.style.top = 0;
    document.body.appendChild(maskDiv);

    removeMaskFn = e => {
      maskDiv.removeEventListener('click', removeMaskFn);
      document.body.removeChild(maskDiv);
      removeMaskFn = null;
      this.hideTree();
    };

    maskDiv.addEventListener('click', removeMaskFn);
  },
  displayTree(event) {
    this.createMask();
    this.ProductTree.style.display = 'block';
    const componentId = this.props.componentId || '';
    const option = $(
      `#treeProductSelect${componentId}>option`
    ).hide();
  },
  clearSelect() {
    this.setState({ selectedCode: [] });
  },
  hideTree() {
    this.ProductTree.style.display = 'none';
  },
  getProductName(productCode) {
    let productName = '';
    const { productCodeTree } = this.state;
    for (let i = 0; i < productCodeTree.length; i += 1) {
      const temp = productCodeTree[i];

      if (productCode == temp.id) {
        productName = temp.name;
        break;
      }
    }
    this.ProductTree.style.display = 'none';
    const selectedCode = [{ id: productCode, text: productName }];
    this.setState({ selectedCode });
    return productName;
  },

  initSelectedCode() {
    if (!this.props.productCode) {
      return;
    }
    let productName = '';
    const { productCodeTree } = this.state;
    for (let i = 0; i < productCodeTree.length; i += 1) {
      const temp = productCodeTree[i];
      if (this.props.productCode == temp.id) {
        productName = temp.name;
        break;
      }
    }

    this.setState({
      selectedCode: [
        { id: this.props.productCode, text: productName || '' },
      ],
    });
  },

  onClickNode(event, treeId, treeNode) {
    if (treeNode.children) {
      return;
    }

    if (!treeNode.pId) return;

    const productTypeCode = treeNode.pId;
    const productCode = treeNode.id;

    this.ProductTree.style.display = 'none';
    this.setState({
      selectedCode: [{ id: productCode, text: treeNode.name || '' }],
    });

    this.props.treeCallBack({
      productTypeCode,
      productCode,
    });
    if (removeMaskFn) removeMaskFn();
  },

  render() {
    const _self = this;
    const { productCode } = this.props;
    const { selectedCode, productCodeTree } = this.state;
    const componentId = _self.props.componentId || '';
    return (
      <div>
        <UISelect
          id={`treeProductSelect${componentId}`}
          onFocus={_self.displayTree}
          label={i18n.ClaimCommon.ProductName}
          required={_self.props.required || 'false'}
          enabled={_self.props.enabled}
          disabled={_self.props.disabled}
          value={productCode}
          codeTable={new CodeTable(selectedCode)}
          validationGroup={_self.props.validationGroup || ''}
          showBlankOption="false"
        />

        <div
          style={{
            display: 'none',
            position: 'absolute',
            backgroundColor: '#fff',
            zIndex: '99',
            border: 'solid 1px #e5e5e5',
            marginTop: '-25px',
            marginLeft: '30%',
            width: '65%',
          }}
          id={`productTree${componentId}`}
          ref={node => (this.ProductTree = node)}
        >
          <UITree
            dataSource={productCodeTree || []}
            onClick={this.onClickNode}
            id={`ProductTree${componentId}`}
            searchable="true"
          />
          {/*		  		<UIButton style={{marginLeft:'1em'}} onClick={_self.hideTree} value={i18n.ClaimCommon.Hide} /> */}
        </div>
      </div>
    );
  },
});
export default ProductTree;
