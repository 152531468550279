import { UIDialog, MessageHelper } from 'RainbowUI';
import alt from '../alt';
import CatastropheService from '../service/CatastropheService';

class CatastropheAction {
  initCondition(condition) {
    this.dispatch(condition);
  }

  initCatastrophe(pageIndex, pageSize, tmpparam) {
    const param = tmpparam;
    param.Limit = pageSize;
    param.Start = pageIndex - 1;
    let result = null;
    CatastropheService.loadCatastrophe(param).then(
      data => {
        result = data;
      },
      () => {
        MessageHelper.error(
          i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
        );
      }
    );
    return { count: result.Model.Count, result: result.Model.Module };
  }

  saveCatastrophe(tmpatastrophe, status) {
    const atastrophe = tmpatastrophe;
    if (status == true || status === 'true') {
      CatastropheService.checkAccidentCodeExists(
        atastrophe.AccidentCode
      ).then(
        data => {
          if (data.Status !== 'OK')
            MessageHelper.error(
              i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
            );
          else if (data.Status === 'OK') {
            if (data.Model == true) {
              MessageHelper.error(i18n.ClaimCommon.CatastropheCheck);
            } else {
              delete atastrophe.dataIndex;
              CatastropheService.saveCatastrophe(atastrophe).then(
                data => {
                  if (data.Status !== 'OK')
                    MessageHelper.error(
                      i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
                    );
                  else if (data.Status === 'OK') {
                    MessageHelper.info(
                      i18n.ClaimCommon.CatastropheSaved
                    );
                    UIDialog.hide('catastropheDialog');
                  }
                },
                () => {
                  MessageHelper.error(
                    i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
                  );
                }
              );
            }
          }
        },
        () => {
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
        }
      );
    } else {
      delete atastrophe.dataIndex;
      CatastropheService.saveCatastrophe(atastrophe).then(
        data => {
          if (data.Status !== 'OK')
            MessageHelper.error(
              i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
            );
          else if (data.Status === 'OK') {
            MessageHelper.info(i18n.ClaimCommon.CatastropheSaved);
            UIDialog.hide('catastropheDialog');
          }
        },
        () => {
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
        }
      );
    }
  }

  loadCatastropheByAccidentCode(accidentCode) {
    CatastropheService.loadCatastropheByAccidentCode(
      accidentCode
    ).then(
      data => {
        if (data.Model.Status === 'OK') return data.Model;
        MessageHelper.info(
          i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
        );
      },
      () => {
        MessageHelper.error(
          i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
        );
      }
    );
  }
}
export default alt.createActions(CatastropheAction);
