import Q15123400031676 from './Q15123400031676';
import Q15123400031679 from './Q15123400031679';
import Q20160000000001 from './Q20160000000001';
import Q20160000000002 from './Q20160000000002';
import Q20160000000004 from './Q20160000000004';
import Q20160000000005 from './Q20160000000005';
import V20160000000006 from './V20160000000006';
import C20160000000008 from './C20160000000008';

export default {
  Q15123400031676,
  Q15123400031679,
  Q20160000000001,
  Q20160000000002,
  Q20160000000004,
  Q20160000000005,
  V20160000000006,
  C20160000000008,
  gsErrorMsg: null,
  PolicyEntity: {
    OrganId: 1,
    PolicyNo: '1400000509',
    ProductCode: '198',
    EffDate: '2015-11-11',
    ExpDate: '2016-11-11',
    Premium: 5000.0,
    PremiumIsPaid: 'Y',
    InsuredList: [
      {
        InsuredName: '1',
        EngineNo: '1',
        MakeYear: '2015',
        SeatNum: 1,
        NewMarketValue: 0.0,
        MakeMonth: 1,
        SumInsured: 500000.0,
        InsuredCategory: 1,
        ExtContent: 'insuredId=11932;',
        VehicleUsage: '2',
        InsuredUid: 9,
        CoverageList: [
          {
            CoverageCode: 'CT_7279',
            ProductCode: '198',
            CoverageName: 'Comprehensive',
            DeductibleAmount: 0.0,
            DeductibleRate: 0.0,
            SumInsured: 500000.0,
            PolicyCoverageCode: 'CT_7279',
            PolicySumInsured: 500000.0,
            PolicyDeductibleRate: 0.0,
            PolicyDeductibleAmount: 0.0,
            GsRiskCat: 999,
            ExtContent: 'riskCat=999;policyCtId=11931;',
            SumInsuredLockFlag: 'N',
            GsCoverageType: 'COMP',
          },
          {
            CoverageCode: 'CI_47909',
            ProductCode: '198',
            CoverageName: 'Third Party Bodily Injury',
            DeductibleAmount: 0.0,
            DeductibleRate: 0.0,
            UpperCoverageCode: 'CT_7279',
            UpperCoverageName: 'Comprehensive',
            SumInsured: 100000.0,
            PolicyCoverageCode: 'CI_47909',
            PolicyUpperCoverageCode: 'CT_7279',
            PolicySumInsured: 100000.0,
            PolicyDeductibleRate: 0.0,
            PolicyDeductibleAmount: 0.0,
            GsRiskCat: 999,
            ExtContent:
              'riskCat=999;policyCtId=11931;subInsuredId=11930;',
            SumInsuredLockFlag: 'N',
          },
          {
            CoverageCode: 'CI_47779',
            ProductCode: '198',
            CoverageName: 'Own Damage/Theft',
            DeductibleAmount: 0.0,
            DeductibleRate: 0.0,
            UpperCoverageCode: 'CT_7279',
            UpperCoverageName: 'Comprehensive',
            SumInsured: 300000.0,
            PolicyCoverageCode: 'CI_47779',
            PolicyUpperCoverageCode: 'CT_7279',
            PolicySumInsured: 300000.0,
            PolicyDeductibleRate: 0.0,
            PolicyDeductibleAmount: 0.0,
            GsRiskCat: 999,
            ExtContent:
              'riskCat=999;policyCtId=11931;subInsuredId=11933;',
            SumInsuredLockFlag: 'N',
          },
          {
            CoverageCode: 'CI_47766',
            ProductCode: '198',
            CoverageName: 'Third Party Property Damage',
            DeductibleAmount: 0.0,
            DeductibleRate: 0.0,
            UpperCoverageCode: 'CT_7279',
            UpperCoverageName: 'Comprehensive',
            SumInsured: 100000.0,
            PolicyCoverageCode: 'CI_47766',
            PolicyUpperCoverageCode: 'CT_7279',
            PolicySumInsured: 100000.0,
            PolicyDeductibleRate: 0.0,
            PolicyDeductibleAmount: 0.0,
            GsRiskCat: 999,
            ExtContent:
              'riskCat=999;policyCtId=11931;subInsuredId=11934;',
            SumInsuredLockFlag: 'N',
          },
        ],
      },
    ],

    PartyList: [
      {
        RoleType: '01',
        PartyName: '10000 10000',
        CertiType: '1',
        ExtPartyId: 1200000100,
        PartyCode: 'IPH00000016',
        CertiCode: '10000',
      },
      {
        RoleType: '02',
        PartyName: 'cherry ebao',
        ExtPartyId: 12800,
        PartyCode: '0000001',
        Email: 'cuiping.yang@ebaotech.com',
        Telephone: '147852',
      },
      {
        RoleType: '03',
        PartyName: '10000 10000',
        CertiType: '1',
        ExtPartyId: 1200000100,
        PartyCode: 'IIN00000015',
        CertiCode: '10000',
      },
      {
        RoleType: '10',
        PartyName: '10000 10000',
        CertiType: '1',
        BirthDate: '1980-08-09',
        ExtPartyId: 341,
        Gender: 'M',
        CertiCode: '10000',
      },
    ],
  },
};
