export default {
  Remark: 'Remark',
  Calculate: 'CALCULATE',
  Calculation: 'Calculation',
  CalculationPanelTitle: 'Calculation Detail',
  AddItem: 'Add Item',
  Selected: ' Selected',
  PleaseSelect: 'Please Select',

  SelectCoverage: 'Please select Coverage for Calculation',
  SelectSubCoverageAndCoverage:
    'Please select Coverage/Sub Coverage for Calculation',
  TotalIndemnityAmount: 'Total Indemnity Amount',
  TotalExpenseAmount: 'Total Expense Amount',
  NoItem: 'Please add Calculation Item',

  AppraisalAmount: 'Appraisal Amount',
  NumberofUnits: 'Number of Units',
  CalculatedAmount: 'Calculated Amount',
  AdjustedAmount: 'Adjusted Amount',
  LimitAndDeductionInfo: 'Limit And Deductible Info',
  LimitMode: 'Limit Mode',
  Amount: 'Amount',
  Description: 'Description',
  DeductibleType: 'Deductible Type',
  DeductibleAmount: 'Deductible Amount',

  SettledAmount: 'Settled Amount',
  ExpenseAmount: 'Expense Amount',

  CalculationHistory: 'Calculation History',
  TransactionDate: 'Transaction Date',
  IssuedBy: 'Issued by',
  Approver: 'Approver',
  CalculationAmount: 'Calculation Amount',
  CalculationExpense: 'Calculate Expense',
  SeqNo: 'Seq No',
  Subclaim: 'Subclaim',
  OutstandingReserve: 'O/S Reserve',
  RequestAmount: 'Request Amount',
  RecognizedAmount: 'Recognized Amount',
  InvoiceNo: 'Invoice No.',
  CalculationHistoryDetail: 'Calculation History Detail',

  CalculationSaveSuccess: 'Calculation is saved successfully',
  CalculationSubmitSuccess: 'Calculation is submitted successfully',
  CalculationCalculateSuccess:
    'Calculation is calculated successfully',
  CalculattionApprovalSubmitSuccess:
    'Calculation Approval is submitted successfully',
  AtleastOneItem: 'At least one subclaim need to be added',
  SameReserveTypeError:
    'You cannot  create 2 records with same subclaim and same Reserve Type',
  IndemnityAmountError:
    'Adjusted Amount cannot be less than Calculated Amount',
  ExpenseAmountError:
    'Expense Amount cannot be less than Settled Amount',
  CalculateApprove: 'Calculation Approval',

  ErrorMsg: {
    Error_0001:
      'The recognized amount is larger than request amount.',
  },
  ConfirmMsg: {},
  WarnMsg: {},
  SuccessMsg: {},
  InfoMsg: {},
};
