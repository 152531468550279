import { UIPage } from 'RainbowUI';
import CatastropheCriteria from './CatastropheCriteria';
import CatastropheResult from './CatastropheResult';

const Catastrophe = React.createClass({
  render() {
    return (
      <UIPage>
        <CatastropheCriteria />
        <CatastropheResult />
      </UIPage>
    );
  },
});

export default Catastrophe;
