const constDefine = {
  ClaimFIPropertyTask: 'ClaimFIPropertyTask',
  ClaimFIVehicleTask: 'ClaimFIVehicleTask',
  ClaimFIBodilyInjuryTask: 'ClaimFIBodilyInjuryTask',
  DONE: 'DONE',
  NEW: 'NEW',
  Self: 'Self',
  Other: 'Other',
  PropertyInfo: 'Property Info',
  VehicleInfo: 'Vehicle Info',
  VictimInfo: 'Victim Info',
};
export default constDefine;
