import { AjaxUtil } from 'RainbowUI';
import Url from '../url/Url';

const SalvageMockData = require('../mock/SalvageMockData');

export default {
  loadSalvageTask(taskInstanceId) {
    const deferred = $.Deferred();
    if (env.IS_MOCK) {
      deferred.resolve(SalvageMockData.SalvageData);
      return deferred.promise();
    }
    AjaxUtil.doGet(Url.SalvageTaskLoad + taskInstanceId, null, {
      async: false,
      done(data) {
        if (data.Status !== 'OK') {
          deferred.reject(data);
        } else {
          deferred.resolve(data.Model);
        }
      },
      fail(jqXHR) {
        deferred.reject({
          status: jqXHR.status,
          responseText: jqXHR.responseText,
        });
      },
    });
    return deferred.promise();
  },

  loadSalvageReadOnly(salvageId) {
    const deferred = $.Deferred();
    if (env.IS_MOCK) {
      deferred.resolve(SalvageMockData.SalvageData);
      return deferred.promise();
    }
    AjaxUtil.doGet(Url.SalvageReadOnlyLoad + salvageId, null, {
      async: true,
      done(data) {
        if (data.Status !== 'OK') {
          deferred.reject(data);
        } else {
          deferred.resolve(data.Model);
        }
      },
      fail(jqXHR) {
        deferred.reject({
          status: jqXHR.status,
          responseText: jqXHR.responseText,
        });
      },
    });
    return deferred.promise();
  },

  loadSalvageItems(taskInstanceId, salvageId) {
    const deferred = $.Deferred();
    if (env.IS_MOCK) {
      deferred.resolve(SalvageMockData.SalvageData);
      return deferred.promise();
    }

    AjaxUtil.doGet(
      `${Url.SalvageItemsLoad + taskInstanceId}/${salvageId}`,
      null,
      {
        async: true,
        done(data) {
          if (data.Status !== 'OK') {
            deferred.reject(data);
          } else {
            deferred.resolve(data.Model);
          }
        },
        fail(jqXHR) {
          deferred.reject({
            status: jqXHR.status,
            responseText: jqXHR.responseText,
          });
        },
      }
    );
    return deferred.promise();
  },

  loadSalvageHistory(caseId) {
    const deferred = $.Deferred();
    if (env.IS_MOCK) {
      deferred.resolve(SalvageMockData.SalvageHistory);
      return deferred.promise();
    }
    AjaxUtil.doGet(Url.SalvageHistoryLoad + caseId, null, {
      async: true,
      done(data) {
        if (data.Status !== 'OK') {
          deferred.reject(data);
        } else {
          deferred.resolve(data.Model);
        }
      },
      fail(jqXHR) {
        deferred.reject({
          status: jqXHR.status,
          responseText: jqXHR.responseText,
        });
      },
    });
    return deferred.promise();
  },

  loadSalvageApproveTask(taskInstanceId) {
    const defer = $.Deferred();
    AjaxUtil.doGet(Url.SalvageApproveLoad + taskInstanceId, null, {
      async: true,
      done(data) {
        if (data.Status !== 'OK') {
          defer.reject(data);
        } else {
          defer.resolve(data.Model);
        }
      },
      fail(jqXHR) {
        defer.reject({
          status: jqXHR.status,
          responseText: jqXHR.responseText,
        });
      },
    });
    return defer.promise();
  },

  loadLimitDialogData(insuredId, coverageCode) {
    const defer = $.Deferred();
    if (env.IS_MOCK) {
      defer.resolve(SalvageMockData.LimitAndDeductInformation);
      return defer.promise();
    }
    AjaxUtil.doGet(
      `${Url.LimitAndDeductionInfo + insuredId}/${coverageCode}`,
      null,
      {
        async: false,
        done(data) {
          if (data.Status !== 'OK') {
            defer.reject(data);
          } else {
            defer.resolve(data.Model);
          }
        },
        fail(jqXHR) {
          defer.reject({
            status: jqXHR.status,
            responseText: jqXHR.responseText,
          });
        },
      }
    );
    return defer.promise();
  },

  saveSalvage(salvageData) {
    const defer = $.Deferred();
    AjaxUtil.doPost(
      Url.SalvageSave,
      {
        SalvageEntity: salvageData.SalvageInfo,
        TaskInstanceId: salvageData.TaskInstanceId,
      },
      {
        async: false,
        done(data) {
          if (data.Status !== 'OK') {
            defer.reject(data);
          } else {
            defer.resolve(data.Model);
          }
        },
        fail(jqXHR) {
          defer.reject({
            status: jqXHR.status,
            responseText: jqXHR.responseText,
          });
        },
      }
    );
    return defer.promise();
  },

  submitSalvage(SalvageData) {
    let result = null;
    AjaxUtil.doPost(
      Url.SalvageSubmit,
      {
        SalvageEntity: SalvageData.SalvageInfo,
        TaskInstanceId: SalvageData.TaskInstanceId,
      },
      {
        async: false,
        done(data) {
          if (data.Status === 'OK') {
            result = data.Status;
          } else {
            result = data;
          }
        },
        fail(jqXHR) {
          result = jqXHR.responseText;
        },
      }
    );
    return result;
  },

  approveSalvage(approveInfo) {
    const defer = $.Deferred();
    AjaxUtil.doPost(Url.SalvageApprove, approveInfo, {
      async: false,
      done(data) {
        if (data.Status === 'OK') {
          defer.resolve(data);
        } else {
          defer.reject({
            status: data.Status,
            responseText: data.Messages[0].Message,
          });
        }
      },
      fail(jqXHR) {
        defer.reject({
          status: jqXHR.status,
          responseText: jqXHR.responseText,
        });
      },
    });
    return defer.promise();
  },

  getClosedTasks(caseId) {
    let returnVal = null;
    const url = Url.SalvageClosedTasksLoad + caseId;
    AjaxUtil.doGet(
      url,
      {},
      {
        async: false,
        done(data) {
          if (data.Status === 'OK') {
            returnVal = data.Model;
          }
        },
        fail() {
          MessageHelper.error(
            '',
            i18n.ClaimSalvage.ErrorMsg.Error_0002
          );
        },
      }
    );
    return returnVal;
  },

  convertLoadFullSalvageData2Positive(tempfullSalvageData) {
    const fullSalvageData = tempfullSalvageData;
    if (fullSalvageData.SalvageInfo) {
      if (
        fullSalvageData.SalvageInfo.SalvageItem &&
        fullSalvageData.SalvageInfo.SalvageItem.length > 0
      ) {
        fullSalvageData.SalvageInfo.SalvageItem = fullSalvageData.SalvageInfo.SalvageItem.filter(
          Temp => {
            const temp = Temp;
            if (temp.Amount && temp.Amount < 0) {
              temp.Amount *= -1;
            }
            return temp;
          }
        );
      }
    }
    return fullSalvageData;
  },
  convertLoadLessSalvageData2Positive(TemplessSalvageData) {
    const lessSalvageData = TemplessSalvageData;
    if (
      lessSalvageData.SalvageItem &&
      lessSalvageData.SalvageItem.length > 0
    ) {
      lessSalvageData.SalvageItem = lessSalvageData.SalvageItem.filter(
        Temp => {
          const temp = Temp;
          if (temp.SalvageAmount && temp.SalvageAmount < 0) {
            temp.Amount = temp.SalvageAmount;
          }
          return temp;
        }
      );
    }

    return lessSalvageData;
  },

  convertSaveFullSalvageData2Negative(tempfullSalvageData) {
    const fullSalvageData = tempfullSalvageData;
    if (fullSalvageData.SalvageInfo) {
      if (fullSalvageData.SalvageInfo.SalvageItem) {
        fullSalvageData.SalvageInfo.SalvageItem = fullSalvageData.SalvageInfo.SalvageItem.filter(
          Temp => {
            const temp = Temp;
            if (temp.SalvageAmount && temp.SalvageAmount > 0) {
              temp.SalvageAmount *= -1;
            }
            return temp;
          }
        );
      }
    }

    return fullSalvageData;
  },
};
