import { UIUpdatePanel } from 'RainbowUI';
import PtyIntegrationSearchInfoNew from './PtyIntegrationSearchInfoNew';
import PartyConst from '../../../party/const/PartyConst';
/**
 * Parameters:
 *  customerChose - function: handler return party
 *  createClicked - function: handler the event when user click create
 */
const PtyIntegrationSearchPanelNew = React.createClass({
  getInitialState() {
    return {
      definedCondition: { PartyType: PartyConst.PARTY_TYPE_IND },
      conditions: {},
      fuzzyConditions: {},
      customerList: [],
      orCondtions: {},
      currentCustomer: {},
      customer: {},
      onCustomerReturn: this.props.customerChose,
    };
  },
  createNewCustomer(partyType) {
    this.props.createClicked(partyType);
  },

  render() {
    let id;
    const { dialog } = this.props;
    const {
      definedCondition,
      conditions,
      fuzzyConditions,
      orCondtions,
      customerList,
      currentCustomer,
    } = this.state;
    if (dialog && dialog.endId)
      id = `updateSearchCriteria${dialog.endId}`;
    return (
      <div>
        <UIUpdatePanel id={id}>
          <PtyIntegrationSearchInfoNew
            ref="PartySearchInfo_ref"
            create={this.createNewCustomer}
            definedCondition={definedCondition}
            conditions={conditions}
            fuzzyConditions={fuzzyConditions}
            orCondtions={orCondtions}
            customerList={customerList}
            currentCustomer={currentCustomer}
            isQueryPolicy={false}
            faxIndividual
            parentDialog={dialog}
          />
        </UIUpdatePanel>
      </div>
    );
  },
});
export default PtyIntegrationSearchPanelNew;
