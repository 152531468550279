import { UIDialog, DataContext } from 'RainbowUI';
import ClaimCommonExt from 'ClaimCommonExt';
import { ClaimCstUI } from 'ClaimCommonConstant';
import {
  PtyIntegrationSearchDialog,
  PartyConst,
  PtyIntegrationCreationDialog,
  PartyAction,
} from 'EU00PartyCommonUI';

const ClaimPartyAction = ClaimCommonExt.getClaimPartyAction();

const ChoosePayeeOrPayerDialog = React.createClass({
  getDefaultProps() {
    return { index: '_0' };
  },
  getTitle() {
    let title = null;
    if (this.props.fromWhere === 'payee') {
      title = i18n.ClaimParty.SelectPayeePayer;
    } else {
      /* empty */
    }
    title = i18n.ClaimParty.SelectPayeePayer;
    if (this.props.fromWhere === 'claimant') {
      title = i18n.PtySearchClaimant;
    }
    return title;
  },
  callback(party) {
    const claimType = DataContext.get('ClaimType');

    let partyRole = '';
    let roleType = '';
    if (claimType === 'SAL,SUB') {
      partyRole = ClaimCstUI.PARTY_ROLE.Claimant;
      if (PartyConst.PARTY_TYPE_ORG == party.PartyType) {
        roleType = PartyConst.PARTY_ROLE_CODE_IND_PAYEEPAYER;
      } else {
        roleType = PartyConst.PARTY_ROLE_CODE_ORG_PAYEEPAYER;
      }
    } else if (claimType === 'LOS') {
      partyRole = ClaimCstUI.PARTY_ROLE.Recovery;
      if (PartyConst.PARTY_TYPE_ORG == party.PartyType) {
        roleType = PartyConst.PARTY_ROLE_CODE_IND_RECOVER_FROM;
      } else {
        roleType = PartyConst.PARTY_ROLE_CODE_ORG_RECOVER_FROM;
      }
    }
    const commonParty = {
      NewRoleType: roleType,
      PtyPartyId: party.PartyId,
      PartyRole: partyRole,
      PartyName: party.PartyName,
    };
    const claimParty = ClaimPartyAction.createPartyParty(
      this.props.caseId,
      commonParty
    );
    let bankCode = '';
    let accountNo = '';
    let accountName = '';
    let addressStr = '';
    let swiftCode = '';
    let IBANNo = '';
    if (party.AddressVoList) {
      _.each(party.AddressVoList, addressInfo => {
        if (addressInfo.IsPrimaryAddress === 'Y') {
          const partyAction = new PartyAction();
          addressStr += partyAction.buildAddress(addressInfo);
        }
      });
    }
    if (party.PartyAccountList) {
      _.each(party.PartyAccountList, partyAccount => {
        if (partyAccount.IsPrimaryAccount === 'Y') {
          bankCode = partyAccount.BankName;
          accountNo = partyAccount.AccountNo;
          accountName = partyAccount.AccountNo;
          swiftCode = partyAccount.SwiftCode;
          IBANNo = partyAccount.IBANNo;
        }
      });
    }
    const clmPayee = {
      PayeeId: claimParty.PartyId,
      PayeeName: party.PartyName,
      BankCode: bankCode,
      AccountNo: accountNo,
      AccountName: accountName,
      Address: addressStr,
      SwiftCode: swiftCode,
      IBANNo,
      PaymentMethodId: party.PaymentMethodId,
    };
    if (typeof this.props.index === 'string') {
      this.props.callback(
        this.props.index,
        claimParty,
        party.PartyRoleList
      );
    } else {
      this.props.callback(
        this.props.index,
        clmPayee,
        party.PartyRoleList
      );
    }
  },
  createPartyDialog() {
    UIDialog.hide(`ChoosePayeeOrPayerDialogId${this.props.index}`);
    this.refs[
      `PtyIntegrationCreationDialog${this.props.index}`
    ].showPartyCreateDialog();
  },

  render() {
    const _self = this;
    const title = this.getTitle();
    const { fromWhere, index, roleType } = this.props;
    let roleName = PartyConst.PARTY_ROLE_NAME_PAYER;
    if (fromWhere === 'payee' || fromWhere === 'payer') {
      roleName = PartyConst.PARTY_ROLE_NAME_PAYER;
    } else if (fromWhere === 'claimant') {
      roleName = PartyConst.PARTY_ROLE_NAME_CLAIMANT;
    }
    return (
      <div>
        <PtyIntegrationSearchDialog
          getSearchResult={_self.callback}
          createClicked={_self.createPartyDialog}
          ptyIntegrationCreated={_self.callback}
          title={title}
          id={`ChoosePayeeOrPayerDialogId${index}`}
          RoleName={roleName}
          roleType={roleType}
          claimFlag
        />
        <PtyIntegrationCreationDialog
          ref={`PtyIntegrationCreationDialog${index}`}
          ptyIntegrationCreated={_self.callback}
          id={`CreatePartyForSe${index}`}
          RoleName={roleName}
        />
      </div>
    );
  },
});
export default ChoosePayeeOrPayerDialog;
