export default {
  Notice: 'Notice Info',
  ClaimNoticePool: 'Search Pending Notice',
  ClaimNoticePoolData: 'Pending Notice',
  NoticeNumber: 'Notice Number',
  DateOfLossFromTo: 'Date of Loss from/to',
  OperationBy: 'Operated By',
  DateReported: 'Date Reported to Insured',

  AccidentDescription: 'Accident Description',
  DamageInfo: 'Damage Info',
  DamageDescription: 'Damage Description',
  DamageAddressDetail: 'Address Detail',
  NoDamageError: 'At least one damage item should be added',
  PolicyNoError: 'Please retrieve first',

  // title
  CLAIM: 'CLAIM',

  WarnMsg: {
    DateToCannotEarlyThanFrom:
      "'Date of Loss to' can not be earlier than 'Date of Loss from'! ",
  },

  ErrorMsg: {},
  ConfirmMsg: {},

  SuccessMsg: {},
  InfoMsg: {},
};
