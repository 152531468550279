import { UIDialog, UIButton, UIBox } from 'RainbowUI';
import PropTypes from 'prop-types';

const ClaimPartyDetailPanel = require('./ClaimPartyDetailPanel');
const QueryCommonParty = require('./QueryCommonParty');
const ClaimPartyAction = require('../action/ClaimPartyAction');
const PartyMaintenanceAction = require('../action/PartyMaintenanceAction');
const ClaimHandlingAction = require('../../common/action/ClaimHandlingAction');
const Url = require('../../common/url/Url');

const AddOrEditPartyDialog = React.createClass({
  propTypes: {
    index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  },

  getDefaultProps() {
    return { index: '_0' };
  },

  getInitialState() {
    console.info('====AddOrEditPartyDialog=======getInitialState===');

    return {
      showPage: null,
      partyDetail: {},
      partyAuth: ClaimPartyAction.getPartyAuthInit(this.props.caseId),
      isEdit: false,
    };
  },

  componentWillReceiveProps(nextProps) {
    this.setState({ title: nextProps.title });
  },

  showDialog(claimParty, showPage) {
    this.setState({ isEdit: false });
    if (claimParty) {
      const result = ClaimPartyAction.loadParty(
        claimParty.PtyPartyId,
        claimParty.PartyId,
        claimParty.PartyRole
      );
      result.PtyContactId = claimParty.PtyContactId;
      result.PtyAddressId = claimParty.PtyAddressId;
      result.PtyAccountId = claimParty.PtyAccountId;

      this.setState({ partyDetail: result });
      this.setState({ showPage });
      this.setState(prevState => {
        return {
          partyAuth:
            this.props.fromWhere === 'claimant'
              ? 'true'
              : prevState.partyAuth,
        };
      });
    } else {
      ClaimPartyAction.initPartyDetailForNewClaimant();
      this.setState({ showPage, partyDetail: {} });
    }

    UIDialog.show(`AddOrEditPartyDialog_${this.props.index}`);
  },

  showEditDialog(claimParty, showPage) {
    window.location.href = `${Url.PartyCommonUrl}#/createPartyMaintain/${claimParty.PtyPartyId}`;
  },

  clickSubmit() {
    let isCheck = true;
    if (
      this.props.fromWhere === 'claimant' ||
      this.props.fromWhere === 'partyMaintance'
    ) {
      isCheck = false;
    }
    const partyVo = this.refs.ClaimPartyDetailPanel_ref.getPartyVo();

    const contactList = partyVo.PartyContactList;
    const addressList = partyVo.AddressVoList;
    let selectedContactIndex = -1;
    let selectedAddressIndex = -1;
    if (contactList && contactList.length > 0) {
      selectedContactIndex = ClaimPartyAction.getSelectedIndex(
        contactList
      );
    }
    if (addressList && addressList.length > 0) {
      selectedAddressIndex = ClaimPartyAction.getSelectedIndex(
        addressList
      );
    }

    const result = ClaimPartyAction.createOrUpdateParty(
      this.props.caseId,
      partyVo,
      isCheck,
      isCheck,
      isCheck,
      this.props.index
    );

    if (this.props.fromWhere === 'claimant') {
      result.commonParty.selectedContactIndex = selectedContactIndex;
      result.commonParty.selectedAddressIndex = selectedAddressIndex;
      this.props.parentComponent.addOrUpdateParty(result);
      if (
        this.props.parentComponent.getCommonPartyBasicInfo !=
        undefined
      ) {
        this.props.parentComponent.getCommonPartyBasicInfo(
          result.claimParty
        );
      }
      if (
        this.props.parentComponent.updateSelectedClaimatInfo !=
        undefined
      ) {
        this.props.parentComponent.updateSelectedClaimatInfo(partyVo);
      }
    } else if (this.props.fromWhere === 'policyHolder') {
      this.props.parentComponent.updatePolicyHolder(result.claimParty);
    } else {
      PartyMaintenanceAction.clickPartyTab();
    }
    UIDialog.hide(`AddOrEditPartyDialog_${this.props.index}`);
  },

  clickCancel() {
    UIDialog.hide(`AddOrEditPartyDialog_${this.props.index}`);
  },

  showPageChange(showPage, partyDetail, title) {
    this.setState(prevState => {
      return {
        showPage,
        partyDetail,
        title,
        partyAuth:
          this.props.fromWhere === 'claimant'
            ? 'true'
            : prevState.partyAuth,
      };
    });
  },

  loadNewParty(newParty) {
    PartyMaintenanceAction.clickPartyTab();
    const claimParty = ClaimPartyAction.loadClaimParty(newParty.ClmPtyId);
    ClaimHandlingAction.updateClaimPartyList(
      claimParty.Model, this.props.claimEntity
    );
  },

  render() {
    const { index } = this.props;
    const { title } = this.state;
    return (
      <UIDialog
        id={`AddOrEditPartyDialog_${index}`}
        width="95%"
        title={title}
      >
        {this.getContent()}
      </UIDialog>
    );
  },
  getContent() {
    const {
      fromWhere,
      index,
      caseId,
      showRadio,
      showContactRadio,
    } = this.props;
    const { showPage, partyAuth, partyDetail, isEdit } = this.state;
    if (showPage === 'QueryCommonParty') {
      return (
        <QueryCommonParty
          fromWhere={fromWhere}
          parentComponent={this}
          index={index}
          caseId={caseId}
        />
      );
    }

    if (partyAuth === 'true') {
      return (
        <div>
          <ClaimPartyDetailPanel
            showRadio={showRadio}
            ref="ClaimPartyDetailPanel_ref"
            showContactRadio={showContactRadio}
            existButtons="true"
            index={index}
            caseId={caseId}
            partyAuth={partyAuth}
            data={partyDetail}
            fromWhere={fromWhere}
          />
          <UIBox direction="center">
            <UIButton
              value={
                fromWhere === 'claimant'
                  ? i18n.ClaimCommon.SelectAsClaimant
                  : i18n.ClaimCommon.Submit
              }
              onClick={this.clickSubmit}
              causeValidation="true"
              validationGroup="addToClmPty"
            />
            <UIButton
              value={i18n.ClaimCommon.Cancel}
              onClick={this.clickCancel}
            />
          </UIBox>
        </div>
      );
    }

    return (
      <div>
        <ClaimPartyDetailPanel
          ref="ClaimPartyDetailPanel_ref"
          existButtons="true"
          index={index}
          caseId={caseId}
          partyAuth={partyAuth}
          data={partyDetail}
          fromWhere={fromWhere}
          showContactRadio={showContactRadio}
          isEdit={isEdit}
        />
        <UIBox direction="center">
          <UIButton
            value={i18n.ClaimCommon.Cancel}
            onClick={this.clickCancel}
          />
        </UIBox>
      </div>
    );
  },
});
export default AddOrEditPartyDialog;
