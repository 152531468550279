import { CodeTable } from 'RainbowUI';
import { ClaimCstUI } from 'ClaimCommonConstant';
import moment from 'moment';
import ClaimHandlingService from '../service/ClaimHandlingService';

const CodeTableAction = require('./CodeTableAction');
const CodeTableCstUI = require('../constant/CodeTableCstUI');

export default {
  filterSubclaimType(codeTable, lossCause) {
    const result = [];
    if (codeTable.codes) {
      codeTable.codes.forEach((data, index) => {
        if (data.Parent == lossCause) {
          result.push(data);
        }
      });
    }
    return new CodeTable(result);
  },

  ifExistedSubclaimTypeOnLossCause(
    codeTable,
    lossCause,
    tmpsubclaimType
  ) {
    let subclaimType = tmpsubclaimType;
    const result = [];
    if (codeTable.codes) {
      codeTable.codes.forEach(data => {
        if (data.Parent == lossCause) result.push(data);
      });
    }
    if (!subclaimType) {
      subclaimType = '';
    }
    let exist = false;
    for (let i = 0; i < result.length; i += 1) {
      if (subclaimType == result[i].id) {
        exist = true;
        break;
      }
    }
    return exist;
  },

  hasCoverageList(subclaimType, insuredId, productCode) {
    let result;
    if (subclaimType && insuredId && productCode) {
      ClaimHandlingService.getCoverageList(
        subclaimType,
        insuredId,
        productCode
      ).then(data => {
        result = data.Model;
      });
    }

    return result;
  },

  filterCoverageList(
    subclaimType,
    insuredId,
    productCode,
    itemList,
    accidentTime
  ) {
    let result = [];
    if (subclaimType && insuredId && productCode) {
      ClaimHandlingService.getCoverageList(
        subclaimType,
        insuredId,
        productCode
      ).then(data => {
        result = this.handleCoverageList(
          itemList,
          data.Model,
          accidentTime
        );
      });
    }
    return result;
  },

  handleCoverageList(itemList, coverageList, accidentTime) {
    if (itemList) {
      itemList.forEach((item, index) => {
        coverageList.map((tmpcoverager, index) => {
          const coverager = tmpcoverager;
          if (item.PolCoverageId == coverager.PolCoverageId) {
            coverager.Selected = '1';
            coverager.InitLossIndemnity = item.InitLossIndemnity;
            coverager.ItemCurrencyCode = item.CurrencyCode;
            coverager.ItemStatusCode = item.StatusCode;
            return false;
          }
          return coverager;
        });
      });
    }
    const filterCoverageList = _.filter(coverageList, coverage => {
      if (!accidentTime) {
        return coverage;
      }
      if (!coverage.EffDate) {
        return coverage;
      }
      if (
        moment(accidentTime).isBetween(
          moment(coverage.EffDate),
          moment(coverage.ExpDate)
        ) ||
        moment(accidentTime).isSame(moment(coverage.EffDate)) ||
        moment(accidentTime).isSame(moment(coverage.ExpDate))
      ) {
        return coverage;
      }
    });
    return filterCoverageList;
  },

  filterDamageType(codeTable, subclaimType) {
    if (subclaimType) {
      const result = [];
      if (codeTable.codes) {
        codeTable.codes.forEach((data, index) => {
          if (data.Parent == subclaimType) {
            result.push(data);
          }
        });
      }
      return new CodeTable(result);
    }
    return CodeTableAction.loadDataList(
      CodeTableCstUI.ClaimDamageTypeCode,
      null
    );
  },

  handlingCurrentValue(codeTable, curentValue) {
    let result;
    if (codeTable && codeTable.codes) {
      codeTable.codes.forEach(data => {
        if (data.id == curentValue) {
          result = data.id;
          return false;
        }
      });
    }
    return result;
  },

  getClaimParty(claimPartyId, claimPartyList) {
    let claimParty = {};
    $(claimPartyList).each((index, data) => {
      if (data.PartyId == claimPartyId) {
        claimParty = data;
        return false;
      }
    });
    return claimParty;
  },

  getClaimPartyInfo(claimPartyId, claimPartyList) {
    let claimParty = null;
    $(claimPartyList).each((index, data) => {
      if (data.PartyId == claimPartyId) {
        const ptyPartyId = data.PtyPartyId;
        ClaimHandlingService.loadParty(data.PtyPartyId).then(data => {
          claimParty = data;
          claimParty.PtyPartyId = ptyPartyId;
        });
      }
    });

    return claimParty;
  },

  changeClaimPartyRole(partyRole, claimPartyList) {
    const claimantObj = { id: -1, text: '*New Claimant' };
    const codeTableClaimant = [];
    if (_.isEmpty(claimPartyList)) {
      codeTableClaimant.push(claimantObj);
      return new CodeTable(codeTableClaimant);
    }

    claimPartyList.forEach((data, index) => {
      if (partyRole == data.PartyRole && data.PartyName) {
        codeTableClaimant.push({
          id: data.PartyId,
          text: data.PartyName,
        });
      }
    });

    let codeTableClaimantSorted = [];
    if (codeTableClaimant && codeTableClaimant.length > 0) {
      codeTableClaimantSorted = _.sortBy(codeTableClaimant, n => {
        return n.text;
      });
    }
    if (partyRole == ClaimCstUI.PARTY_ROLE.Claimant) {
      codeTableClaimantSorted.push(claimantObj);
    }

    return new CodeTable(codeTableClaimantSorted);
  },
  getCodeTableClaimPartyRole(claimPartyRole, claimPartyList) {
    const codeTableRole = [];
    const roles = [];
    const claimantObj = { id: '01', text: 'Claimant' };
    if (_.isEmpty(claimPartyList)) {
      codeTableRole.push(claimantObj);
      return new CodeTable(codeTableRole);
    }
    claimPartyList.forEach((data, index) => {
      if (roles.indexOf(data.PartyRole) == -1) {
        roles.push(data.PartyRole);
      }
    });
    if (roles && roles.length > 0) {
      roles.forEach((role, index) => {
        claimPartyRole.codes.forEach((data, index) => {
          if (data.id == role) {
            codeTableRole.push({ id: role, text: data.text });
          }
        });
      });
    }
    if (roles.indexOf('01') == -1) {
      codeTableRole.push(claimantObj);
    }

    let codeTableRoleSorted = [];
    if (codeTableRole && codeTableRole.length > 0) {
      codeTableRoleSorted = _.sortBy(codeTableRole, n => {
        return n.text;
      });
    }
    return new CodeTable(codeTableRoleSorted);
  },
  getClaimPartyCountry(countryCode, codeTableCountry) {
    let country = '';
    codeTableCountry.codes.forEach((data, index) => {
      if (data.id == countryCode) {
        country = data.text;
      }
    });
    return country;
  },
};
