import {
  DataContext,
  UpdateContext,
  UIDialog,
  MessageHelper,
  CodeTable,
  AjaxUtil,
  UIConfirmDialog,
} from 'RainbowUI';
import {
  RestStatus,
  ClaimMessageForamtUtils,
} from 'ClaimCommonConstant';
import TreeNode from '../constant/TreeNodeCache';

const url = require('../url/Url');

export default {
  getProductCoverageList(pageindex, pagesize) {
    const data = TreeNode.TreeCache.get('TreeNode');
    const productLineCodeJS = {
      productCode: data.objectMap.pId,
      productCoverageCode: data.objectMap.id,
    };
    if (data.treeNodeLevel === 'RootCoverage') {
      productLineCodeJS.productCoverageCode = null;
    } else {
      productLineCodeJS.productCode = null;
    }
    const searchCondition = {};
    searchCondition.ProductCode = productLineCodeJS.productCode;
    searchCondition.ProductCoverageCode =
      productLineCodeJS.productCoverageCode;
    searchCondition.PageNo = pageindex - 1;
    searchCondition.PageSize = pagesize;
    const urlSave = url.ClaimProductCoverageListByConditions;
    if (DataContext.get('searchProductCoverageList')) {
      const condition = DataContext.get('searchProductCoverageList');
      if (condition.coverageCode) {
        searchCondition.CoverageCode = condition.coverageCode
          .replace(/(^\s*)|(\s*$)/g, '')
          .toUpperCase();
      }
      if (condition.productCoverTypeCode) {
        searchCondition.ExtCoverageTypeCode = condition.productCoverTypeCode
          .replace(/(^\s*)|(\s*$)/g, '')
          .toUpperCase();
      }
      if (condition.claimable) {
        searchCondition.AvailableClaimingFlag = condition.claimable
          .replace(/(^\s*)|(\s*$)/g, '')
          .toUpperCase();
      }
    }
    let result = {};
    AjaxUtil.doPost(urlSave, searchCondition, {
      async: false,
      done(data) {
        result = data.Model;
      },
      fail() {
        MessageHelper.error(
          i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
        );
      },
    });
    return { count: result.Total, result: result.Content };
  },

  save(datas, isUpdate) {
    const data = datas;
    const treeNode = TreeNode.TreeCache.get('TreeNode');
    let urlSave = url.ClaimProductCoverageSave;

    if (isUpdate === 'out') urlSave = url.ClaimProductCoverageUpdate;
    else {
      if (treeNode.treeNodeLevel === 'RootCoverage')
        data.ParentCoverageCode = null;
      else data.ParentCoverageCode = treeNode.objectMap.id;
      data.ProductCode = treeNode.objectMap.productCode;
    }
    delete data.dataIndex;
    AjaxUtil.doPost(urlSave, data, {
      async: false,
      done(data) {
        if (data.Status == RestStatus.OK) {
          UIDialog.hide('productCoverageDialog');
          MessageHelper.success(
            ClaimMessageForamtUtils.formatI18NMsg(
              i18n.ClaimCommon.SaveSuccess,
              i18n.ClaimProduct.Coverage
            )
          );
        } else if (data.Status == RestStatus.BLOCK) {
          MessageHelper.error(
            ClaimMessageForamtUtils.formatI18NMsg(
              i18n.ClaimCommon.IsExist,
              i18n.ClaimProduct.Coverage
            )
          );
        } else {
          MessageHelper.error(data.Status);
        }
      },
      fail() {
        MessageHelper.error(
          i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
        );
      },
    });
  },
  removeProductDialog(event) {
    const data = event.getParameter('data');
    data.removeType = 'coverage';
    DataContext.put('data', data);
    UIConfirmDialog.show('RemoveConfirmDialog');
  },

  removeCoverage() {
    const coverageCode = DataContext.get('data').CoverageCode;
    AjaxUtil.doGet(
      `${url.ClaimProductCoverageDelete}/${coverageCode}`,
      {},
      {
        async: false,
        done(data) {
          if (data.Status == RestStatus.OK) {
            UpdateContext.forceUpdate('treeCfgUIupdateId');
            UpdateContext.forceUpdate('ProductCoverageListId');
            MessageHelper.success(
              ClaimMessageForamtUtils.formatI18NMsg(
                i18n.ClaimCommon.DeleteSuccess,
                i18n.ClaimProduct.Coverage
              )
            );
          } else {
            MessageHelper.error(
              ClaimMessageForamtUtils.formatI18NMsg(
                i18n.ClaimCommon.DeleteFail,
                i18n.ClaimProduct.Coverage
              )
            );
          }
        },
        fail() {
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
        },
      }
    );
  },

  getClaimCoverageTypeCodeAndName() {
    const treeNode = TreeNode.TreeCache.get('TreeNode');
    const productLineCode = treeNode.treeNodeCode;
    const { productTypeCode } = treeNode.objectMap;
    let result = null;
    AjaxUtil.doGet(
      `${url.ClaimProductCoverageType_getTypeCodeAndTypeName}/${productLineCode}/${productTypeCode}`,
      {},
      {
        async: false,
        done(data) {
          if (data.Status == RestStatus.OK) result = data.Model;
          else MessageHelper.error(data.Status);
        },
        fail() {
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
        },
      }
    );
    return new CodeTable(result);
  },

  searchProductCoverageList(condition) {
    DataContext.put('searchProductCoverageList', condition);
    UpdateContext.forceUpdate('ProductCoverageListIdProvider');
  },
};
