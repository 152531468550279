import {
  UIEmail,
  UISmartPanelGrid,
  UIPanel,
  UICell,
  UIBox,
  UIDateTimePicker,
  CodeTable,
  UICurrency,
  UIDialog,
  UIText,
  UISelect,
  UIButton,
} from 'RainbowUI';
import BindToMixin from 'react-binding';

const CodeTableAction = require('../../action/CodeTableAction');
const CodeTableCstUI = require('../../constant/CodeTableCstUI');
const ClaimLitigationAction = require('../../action/ClaimLitigationAction');
const ClaimExceptionUtils = require('../../util/ClaimExceptionUtils');
const TaskTransferAction = require('../../action/TaskTransferAction');

const LitigationContentDialog = React.createClass({
  mixins: [BindToMixin],
  getInitialState() {
    const localCurrency = ClaimLitigationAction.getLocalCurrency();
    return {
      litigationObj: { '@type': 'ClaimLitigation-ClaimLitigation' },
      isSelected: '',
      updatUI: false,
      suitStatusRequired: false,
      localCurrency: { localCurrency },
    };
  },
  showDialog(data) {
    this.setState({ litigationObj: data });
    UIDialog.show('LitigationContentDialog');
  },
  save() {
    delete this.state.litigationObj.dataIndex;
    this.state.litigationObj.CaseId = this.props.CaseId;
    const entityList = this.props.subclaimList;
    let objectEntity = null;
    for (let i = 0; i < entityList.length; i += 1) {
      if (
        this.state.litigationObj.ObjectId == entityList[i].ObjectId
      ) {
        objectEntity = entityList[i];
      }
    }
    ClaimLitigationAction.save(this.state.litigationObj);
    this.setState({ litigationObj: {} });
    this.setState({ isSelected: '' });
    UIDialog.hide('LitigationContentDialog');
    this.props.updateParentTab(true, this.props.index);
    this.props.parentUpdateUI();
  },

  showAttachTo() {
    const codeTable = [];
    const entityList = TaskTransferAction.getSubClaimNoByCaseId(
      this.props.CaseId
    );
    for (let i = 0; i < entityList.length; i += 1) {
      codeTable.push({
        id: entityList[i].ObjectId,
        text: `Subclaim ${entityList[i].SeqNo}`,
      });
    }
    return new CodeTable(codeTable);
  },
  cancel() {
    this.setState({ suitStatusRequired: false });
    UIDialog.hide('LitigationContentDialog');
  },
  statusRemarkRequired() {
    if (this.state.litigationObj.SuitStatus === 'OTHER') {
      this.setState({ suitStatusRequired: true });
    } else {
      this.setState({ suitStatusRequired: false });
    }
  },
  render() {
    const { suitStatusRequired, localCurrency } = this.state;
    return (
      <div>
        <UIDialog
          id="LitigationContentDialog"
          onClose={this.cancel}
          title={i18n.ClaimCommon.litigationInfo}
        >
          <div column="1">
            <UIPanel
              panelTitle={i18n.ClaimCommon.litigationInfo}
              styleClass="default"
            >
              <UICell>
                <UICell width="12">
                  <UIText
                    maxLength="200"
                    maxLengthMessage={ClaimExceptionUtils.formatI18NMsg(
                      i18n.ClaimCommon.FieldMaxLength,
                      i18n.ClaimCommon.SuitName,
                      '200'
                    )}
                    widthAllocation="1,11"
                    label={i18n.ClaimCommon.SuitName}
                    valueLink={this.bindToState(
                      'litigationObj',
                      'SuitName'
                    )}
                    required="true"
                    validationGroup="litigationVal"
                  />
                </UICell>
                <UICell width="12">
                  <UISmartPanelGrid layout="horizontal" column="3">
                    <UISelect
                      label={i18n.ClaimCommon.SuitType}
                      codeTable={CodeTableAction.loadDataList(
                        CodeTableCstUI.ClaimLitigationType,
                        null
                      )}
                      valueLink={this.bindToState(
                        'litigationObj',
                        'SuitType'
                      )}
                      required="true"
                      validationGroup="litigationVal"
                    />
                    <UISelect
                      label={i18n.ClaimCommon.AttachTo}
                      codeTable={this.showAttachTo}
                      valueLink={this.bindToState(
                        'litigationObj',
                        'ObjectId'
                      )}
                      required="true"
                      validationGroup="litigationVal"
                    />
                    <UISelect
                      label={i18n.ClaimCommon.LitigationStatus}
                      codeTable={CodeTableAction.loadDataList(
                        CodeTableCstUI.ClaimLitigationStatus,
                        null
                      )}
                      valueLink={this.bindToState(
                        'litigationObj',
                        'SuitStatus'
                      )}
                      required="true"
                      onChange={this.statusRemarkRequired}
                      validationGroup="litigationVal"
                    />
                  </UISmartPanelGrid>
                </UICell>
                <UICell width="12">
                  <UIText
                    maxLength="500"
                    maxLengthMessage={ClaimExceptionUtils.formatI18NMsg(
                      i18n.ClaimCommon.FieldMaxLength,
                      i18n.ClaimCommon.SuitStatusRemark,
                      '500'
                    )}
                    widthAllocation="1,11"
                    label={i18n.ClaimCommon.SuitStatusRemark}
                    valueLink={this.bindToState(
                      'litigationObj',
                      'SuitStatusRemark'
                    )}
                    required={suitStatusRequired}
                    validationGroup="litigationVal"
                  />
                </UICell>
                <UICell width="12">
                  <UISmartPanelGrid layout="horizontal" column="3">
                    <UIText
                      maxLength="200"
                      maxLengthMessage={ClaimExceptionUtils.formatI18NMsg(
                        i18n.ClaimCommon.FieldMaxLength,
                        i18n.ClaimCommon.SuitCaseNumber,
                        '200'
                      )}
                      label={i18n.ClaimCommon.SuitCaseNumber}
                      valueLink={this.bindToState(
                        'litigationObj',
                        'SuitCaseNumber'
                      )}
                      validationGroup="litigationVal"
                    />
                    <UIDateTimePicker
                      format="MM/DD/YYYY"
                      maxDate="TODAY"
                      label={i18n.ClaimCommon.SuitOpenDate}
                      valueLink={this.bindToState(
                        'litigationObj',
                        'SuitOpenDate'
                      )}
                      validationGroup="litigationVal"
                    />
                    <UICurrency
                      id="CURRENCY_CODE_AmountAppealed"
                      maxLength="20"
                      maxLengthMessage={ClaimExceptionUtils.formatI18NMsg(
                        i18n.ClaimCommon.FieldMaxLength,
                        i18n.ClaimCommon.AmountAppealed,
                        '20'
                      )}
                      unit={window.EU00.getCurrencySignByCurrencyCode(
                        localCurrency
                      )}
                      label={i18n.ClaimCommon.AmountAppealed}
                      valueLink={this.bindToState(
                        'litigationObj',
                        'AmountApealed'
                      )}
                      validationGroup="litigationVal"
                    />
                  </UISmartPanelGrid>
                </UICell>
                <UICell width="12">
                  <UIText
                    maxLength="500"
                    maxLengthMessage={ClaimExceptionUtils.formatI18NMsg(
                      i18n.ClaimCommon.FieldMaxLength,
                      i18n.ClaimCommon.SuitLocation,
                      '500'
                    )}
                    widthAllocation="1,11"
                    label={i18n.ClaimCommon.SuitLocation}
                    valueLink={this.bindToState(
                      'litigationObj',
                      'SuitLocation'
                    )}
                    validationGroup="litigationVal"
                  />
                </UICell>
                <UICell width="12">
                  <UISmartPanelGrid layout="horizontal" column="3">
                    <UIText
                      maxLength="200"
                      maxLengthMessage={ClaimExceptionUtils.formatI18NMsg(
                        i18n.ClaimCommon.FieldMaxLength,
                        i18n.ClaimCommon.LawyerName,
                        '200'
                      )}
                      label={i18n.ClaimCommon.LawyerName}
                      valueLink={this.bindToState(
                        'litigationObj',
                        'LawyerName'
                      )}
                      validationGroup="litigationVal"
                    />
                    <UIText
                      maxLength="200"
                      maxLengthMessage={ClaimExceptionUtils.formatI18NMsg(
                        i18n.ClaimCommon.FieldMaxLength,
                        i18n.ClaimCommon.LawyerTel,
                        '200'
                      )}
                      label={i18n.ClaimCommon.LawyerTel}
                      valueLink={this.bindToState(
                        'litigationObj',
                        'LawyerTel'
                      )}
                      validationGroup="litigationVal"
                    />
                    <UIEmail
                      maxLength="200"
                      maxLengthMessage={ClaimExceptionUtils.formatI18NMsg(
                        i18n.ClaimCommon.FieldMaxLength,
                        i18n.ClaimCommon.LawyerEmail,
                        '200'
                      )}
                      label={i18n.ClaimCommon.LawyerEmail}
                      valueLink={this.bindToState(
                        'litigationObj',
                        'LawyerEmail'
                      )}
                      validationGroup="litigationVal"
                    />
                  </UISmartPanelGrid>
                </UICell>
              </UICell>
            </UIPanel>
            <UIPanel
              panelTitle={i18n.ClaimCommon.SuitConclusion}
              styleClass="default"
            >
              <div column="2">
                <UICell width="12">
                  <UISmartPanelGrid layout="horizontal" column="3">
                    <UIDateTimePicker
                      format="MM/DD/YYYY"
                      maxDate="TODAY"
                      label={i18n.ClaimCommon.SuitCloseDate}
                      valueLink={this.bindToState(
                        'litigationObj',
                        'SuitCloseDate'
                      )}
                    />
                    <UICurrency
                      maxLength="20"
                      maxLengthMessage={ClaimExceptionUtils.formatI18NMsg(
                        i18n.ClaimCommon.FieldMaxLength,
                        i18n.ClaimCommon.DipositionAmount,
                        '20'
                      )}
                      unit={window.EU00.getCurrencySignByCurrencyCode(
                        localCurrency
                      )}
                      label={i18n.ClaimCommon.DipositionAmount}
                      valueLink={this.bindToState(
                        'litigationObj',
                        'DepositionAmount'
                      )}
                      validationGroup="litigationVal"
                    />
                  </UISmartPanelGrid>
                </UICell>
              </div>
            </UIPanel>

            <div>
              <UIBox direction="center">
                <UIButton
                  value={i18n.ClaimCommon.Submit}
                  onClick={this.save}
                  validationGroup="litigationVal"
                  causeValidation="true"
                />
                <UIButton
                  value={i18n.ClaimCommon.Cancel}
                  onClick={this.cancel}
                />
              </UIBox>
            </div>
          </div>
        </UIDialog>
      </div>
    );
  },
});
export default LitigationContentDialog;
