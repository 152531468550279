import {
  UIPanel,
  UIButton,
  UIDialog,
  UIDateTimePicker,
  UIBox,
} from 'RainbowUI';
import BindToMixin from 'react-binding';
import SettlementDetailDialogAction from '../action/SettlementDetailDialogAction';

const SettlementDetailDialog = React.createClass({
  mixins: [BindToMixin],

  getInitialState() {
    return {
      SettlementDetailDialogInfo: {
        settlementIssueDate: null,
      },
    };
  },

  showDialog(settlePayeeId, settleId, paymentMethod) {
    const SettlementDetailDialogInfo = {};
    SettlementDetailDialogInfo.SettlePayeeId = settlePayeeId;
    SettlementDetailDialogInfo.SettleId = settleId;
    SettlementDetailDialogInfo.PaymentMethod = paymentMethod;
    if (SettlementDetailDialogInfo.SettleId) {
      SettlementDetailDialogInfo.SettlementIssueDate = SettlementDetailDialogAction.loadSettlementSubmitDate(
        SettlementDetailDialogInfo.SettleId
      );
    }

    this.setState({
      SettlementDetailDialogInfo,
    });
    const componentId = this.props.componentId || '';
    UIDialog.show(`settlementDetailDialog${componentId}`);
  },

  render() {
    const { componentId } = this.props;
    const {
      SettlementDetailDialogInfo,
      SettlementDetailDialogInfo: { PaymentMethod },
    } = this.state;
    const ComponentId = componentId || '';
    const paymentMethod = PaymentMethod;
    let payMod = null;
    if (paymentMethod === '101') {
      payMod = i18n.ClaimSettlement.PaymentMethodCheque;
    }
    if (paymentMethod === '106') {
      payMod = i18n.ClaimSettlement.PaymentMethodBankTransfer;
    }
    if (paymentMethod === '109') {
      payMod = i18n.ClaimSettlement.PaymentMethodDirectCredit;
    }

    return (
      <UIDialog
        id={`settlementDetailDialog${ComponentId}`}
        width="30%"
        height="300px"
        modal="false"
        title={i18n.ClaimSettlement.SettlementDetail}
      >
        <UIPanel headerable="false">
          <UIDateTimePicker
            format="MM/DD/YYYY"
            id="settlementIssueDate"
            label={payMod}
            placeHolder="MM/DD/YYYY"
            enabled="false"
            model={SettlementDetailDialogInfo}
            property="SettlementIssueDate"
          />
        </UIPanel>
        <UIBox direction="center">
          <UIButton
            value={i18n.ClaimCommon.Cancel}
            position="right"
            onClick={this.OnCancel}
          />
        </UIBox>
      </UIDialog>
    );
  },
  OnCancel() {
    const componentId = this.props.componentId || '';
    UIDialog.hide(`settlementDetailDialog${componentId}`);
  },
});
export default SettlementDetailDialog;
