import { CodeTable, DataContext, Caller } from 'RainbowUI';
import CommonService from '../service/CommonService';
import urlConfig from '../../../UrlConfig';

export default {
  CodeTableIdFromCache(codeTableId) {
    console.info(' CodeTableIdFromCache codeTableId', codeTableId);
    let codeTableList = [];
    const codeTableKey = `CodeTable_${codeTableId}`;
    if (DataContext.get(codeTableKey)) {
      codeTableList = DataContext.get(codeTableKey);
    } else {
      codeTableList = this.CodeTableId(codeTableId, true);
      DataContext.put(codeTableKey, codeTableList);
    }
    console.info(' CodeTableIdFromCache size', codeTableList.length);
    return new CodeTable(codeTableList);
  },

  VenCodeTableIdFromCache(codeTableId) {
    console.info(' CodeTableIdFromCache codeTableId', codeTableId);
    let codeTableList = [];
    const codeTableKey = `CodeTable_${codeTableId}`;
    if (DataContext.get(codeTableKey)) {
      codeTableList = DataContext.get(codeTableKey);
    } else {
      codeTableList = this.CodeTableId(codeTableId, true);
      DataContext.put(codeTableKey, codeTableList);
    }
    console.info(' CodeTableIdFromCache size', codeTableList.length);
    for (let idx = 0; idx < codeTableList.length; idx += 1) {
      if (
        codeTableList[idx].id == '1' ||
        codeTableList[idx].id == '4'
      ) {
        codeTableList.splice(idx, 1);
      }
    }
    return new CodeTable(codeTableList);
  },
  getCodeTableByCondition(codeTableId, condition) {
    if (condition) {
      const codeTableList = this.codeTableByCondition(
        codeTableId,
        condition
      );
      return new CodeTable(codeTableList);
    }
    return this.CodeTableIdFromCache(codeTableId);
  },
  EndoTypeCodeTableIdFromCache(codeTableId) {
    let codetableList = [];
    if (DataContext.get(codeTableId)) {
      codetableList = DataContext.get(codeTableId);
    } else {
      const newCodetableList = this.CodeTableId(codeTableId);
      for (const codetable of newCodetableList) {
        if (
          codetable.id == 11 ||
          codetable.id == 21 ||
          codetable.id == 31 ||
          codetable.id == 51 ||
          codetable.id == 71
        ) {
          codetableList.push(codetable);
        }
      }
      DataContext.put(codeTableId, codetableList);
    }
    return new CodeTable(codetableList);
  },
  codeTableById(codeTableId) {
    return this.CodeTableId(codeTableId, true);
  },
  codeTableByCode(codeTableId) {
    return this.CodeTableId(codeTableId, false);
  },
  CodeTableId(codeTableId, isId) {
    const codeTableList = [];
    let backData = [];
    const { codeTable_json } = window;
    if (codeTable_json && codeTableId !== 76804571) {
      backData = eval(codeTable_json[eval(codeTableId)]);
    }
    console.info('static file ', codeTableId);
    console.info('static file ', backData);
    if (!backData || backData.length === 0) {
      const url = urlConfig.codetableList + codeTableId;
      CommonService.ajaxGet(null, url).then(data => {
        if (data) {
          backData = data;
        }
      });
    }
    for (let i = 0; i < backData.length; i += 1) {
      const newData = {};
      newData.id = backData[i].Id;
      if (isId != null && isId !== '') {
        newData.text = backData[i].Description;
      } else {
        newData.text = backData[i].Code;
      }
      newData.ConditionFields = backData[i].ConditionFields;
      codeTableList.push(newData);
    }
    return codeTableList;
  },
  codeTableIdForOrignal(codeTableId) {
    const codeTableList = [];
    let backData = [];
    const { codeTable_json } = window;
    if (codeTable_json) {
      backData = eval(codeTable_json[eval(codeTableId)]);
    }
    console.info('static file ', codeTableId);
    console.info('static file ', backData);
    if (!backData || backData.length === 0) {
      const url = urlConfig.codetableList + codeTableId;
      CommonService.ajaxGet(null, url).then(data => {
        if (data) {
          backData = data;
        }
      });
    }
    return backData;
  },
  conditionContainIn(condition) {
    for (const conditionKey of Object.keys(condition)) {
      if (
        typeof condition[conditionKey] === 'string' &&
        condition[conditionKey].indexOf('in(') !== -1
      ) {
        return true;
      }
    }
    return false;
  },
  codeTableByCondition(codeTableId, condition) {
    if (!condition) {
      return this.codeTableById(codeTableId);
    }
    const codeTableList = [];
    let backData = [];
    const { codeTable_json } = window;
    if (codeTable_json) {
      backData = eval(codeTable_json[eval(codeTableId)]);
    }
    if (
      !backData ||
      backData.length === 0 ||
      this.conditionContainIn(condition)
    ) {
      const url = urlConfig.loadCodeTableByCondition + codeTableId;
      CommonService.ajaxPost(condition, url).then(data => {
        if (data) {
          backData = data;
        }
      });
    }
    for (let i = 0; i < backData.length; i += 1) {
      const newData = {};
      const conditionFields = backData[i].ConditionFields;
      let matchedCount = 0;
      if (conditionFields) {
        for (const conditionField of conditionFields) {
          for (const key of Object.keys(conditionField)) {
            if (conditionField[key] == condition[key]) {
              matchedCount += 1;
              break;
            }
          }
        }
      }
      if (
        (conditionFields && matchedCount) ||
        this.conditionContainIn(condition)
      ) {
        newData.id = backData[i].Id;
        newData.Code = backData[i].Id;
        newData.text = backData[i].Description;
        newData.ConditionFields = conditionFields;
        codeTableList.push(newData);
      }
    }
    console.info('codeTableByCondition', condition);
    console.info('codeTableByCondition', codeTableList);
    return codeTableList;
  },
  codeTableByConditionForOrignal(codeTableId, condition) {
    if (!condition) {
      return this.codeTableIdForOrignal(codeTableId);
    }
    const codeTableList = [];
    let backData = [];
    const { codeTable_json } = window;
    if (codeTable_json) {
      backData = eval(codeTable_json[eval(codeTableId)]);
    }
    if (
      !backData ||
      backData.length === 0 ||
      this.conditionContainIn(condition)
    ) {
      const url = urlConfig.loadCodeTableByCondition + codeTableId;
      CommonService.ajaxPost(condition, url).then(data => {
        if (data) {
          backData = data;
        }
      });
    }
    for (let i = 0; i < backData.length; i += 1) {
      const conditionFields = backData[i].ConditionFields;
      let matchedCount = 0;
      if (conditionFields) {
        for (const conditionField of conditionFields) {
          for (const key of Object.keys(conditionField)) {
            if (conditionField[key] == condition[key]) {
              matchedCount += 1;
              break;
            }
          }
        }
      }
      if (
        (conditionFields && matchedCount) ||
        this.conditionContainIn(condition)
      ) {
        codeTableList.push(backData[i]);
      }
    }
    return codeTableList;
  },
  CodeTableCodeFromCache(codeTableId) {
    let codetableList = null;
    if (DataContext.get(codeTableId)) {
      codetableList = DataContext.get(codeTableId);
    } else {
      codetableList = this.CodeTableId(codeTableId, false);
      DataContext.put(codeTableId, codetableList);
    }
    return new CodeTable(codetableList);
  },
  CodeTableDistinctCodeFromCache(codeTableId) {
    let codetableList = null;
    if (DataContext.get(codeTableId)) {
      codetableList = DataContext.get(codeTableId);
    } else {
      codetableList = this.CodeTableDistinctCode(codeTableId);
      DataContext.put(codeTableId, codetableList);
    }
    return new CodeTable(codetableList);
  },
  CodeTableDistinctCode(codeTableId) {
    const url = urlConfig.codetableList + codeTableId;
    const codetableList = [];
    const codetableObj = {};
    CommonService.ajaxGet(null, url).then(data => {
      if (data) {
        for (let i = 0; i < data.length; i += 1) {
          if (typeof codetableObj[data[i].Id] === 'undefined') {
            codetableList.push({
              id: data[i].Id,
              text: data[i].Code,
            });
            codetableObj[data[i].Id] = data[i].Code;
          }
        }
      }
    });
    return codetableList;
  },
  getActivePartyReinsuranceCache(roleType, subRoleType) {
    let url = `${urlConfig.loadSolrReinsurancer}/${roleType}`;
    if (subRoleType) {
      url += `?subRoleType=${subRoleType}&roleStatus=1`; // active
    }
    const codeTableList = [];
    // var model = new Model().initPartyReinsuranceModel();
    // model.FuzzyConditions.RoleType=roleType;
    CommonService.ajaxPost(null, url).then(data => {
      _.each(data, obj => {
        codeTableList.push(obj);
      });
    });
    return new CodeTable(codeTableList);
  },

  getReinsuranceCompany() {
    let url = `${urlConfig.loadSolrReinsurancer}/RECORG`;
    url += '?subRoleType=1&roleStatus=1'; // active
    const codeTableList = [];
    // var model = new Model().initPartyReinsuranceModel();
    // model.FuzzyConditions.RoleType=roleType;
    const setting = { method: 'POST', async: false, block: false };
    Caller.call(url, null, setting).then(data => {
      _.each(data, obj => {
        codeTableList.push(obj);
      });
    });
    return new CodeTable(codeTableList);
  },
  getRoleParty(roleType, roleStatus) {
    let url = `${urlConfig.loadSolrReinsurancer}/${roleType}`;
    if (roleStatus) {
      url += `&roleStatus=${roleStatus}`; // active
    }
    const codeTableList = [];
    // var model = new Model().initPartyReinsuranceModel();
    // model.FuzzyConditions.RoleType=roleType;
    const setting = { method: 'POST', async: false, block: false };
    Caller.call(url, null, setting).then(data => {
      _.each(data, obj => {
        if (obj.text) {
          codeTableList.push(obj);
        }
      });
    });
    return new CodeTable(codeTableList);
  },

  getReinsuranceBroker() {
    let url = `${urlConfig.loadSolrReinsurancer}/RECORG`;
    url += '?subRoleType=2&roleStatus=1'; // active
    const codeTableList = [];
    // var model = new Model().initPartyReinsuranceModel();
    // model.FuzzyConditions.RoleType=roleType;
    const setting = { method: 'POST', async: false, block: false };
    Caller.call(url, null, setting).then(data => {
      _.each(data, obj => {
        codeTableList.push(obj);
      });
    });
    return new CodeTable(codeTableList);
  },
};
