import {
  UIPanel,
  UIBox,
  UIUpdatePanel,
  UpdateContext,
  UISmartPanelGrid,
  UIText,
  UISelect,
  UIButton,
  UIDateTimePicker,
} from 'RainbowUI';
import BindToMixin from 'react-binding';
import {
  PartySearchPanelDialog,
  PartyConst,
  PtyIntegrationSearchDialog,
  PartyAction,
  CodeTableTextNoValuelink,
} from 'EU00PartyCommonUI';
import ClaimWorkFlowLeftAction from '../../action/desktop/ClaimWorkFlowLeftAction';
import ConditionStore from '../../store/PendingConditionStore';

const CodeTableAction = require('../../action/CodeTableAction');
const CodeTableCstUI = require('../../constant/CodeTableCstUI');

const ClaimWorkFlowMyTaskCriteria = React.createClass({
  mixins: [BindToMixin],

  componentWillUnmount() {
    ConditionStore.unlisten(this.onStoreStateChange);
  },
  componentDidUpdate() {
    // let claimantValue = $('#WorkCenterSearchFormClaimantid').find('input').eq(1).val();
    // let insuredValue = $('#WorkCenterSearchFormInsuredid').find('input').eq(1).val();
    // let condition = this.state.condition;
    // if (claimantValue) {
    //     condition.ClaimantName = claimantValue;
    // }
    // if (insuredValue) {
    //     condition.InsuredName = insuredValue;
    // }
    // let claimantName = condition.ClaimantName;
    // let insuredName = condition.InsuredName;
    // if (insuredName) {
    //     $('#WorkCenterSearchFormInsuredid').find('input').eq(1).val(this.state.condition.InsuredName);
    // }
    // if (claimantName) {
    //     $('#WorkCenterSearchFormClaimantid').find('input').eq(1).val(this.state.condition.ClaimantName);
    // }
  },
  onStoreStateChange(storeState) {
    if (!_.isEmpty(storeState.condition)) {
      this.setState({ condition: storeState.condition });
    }
  },
  componentDidMount() {
    // ClaimWorkFlowLeftAction.initCondition({"TaskType":"ClaimSettlementApproval"});
    ConditionStore.listen(this.onStoreStateChange);
  },
  getInitialState() {
    const $state = {
      condition: {},
      insured: {},
      claimant: {},
    };
    return $state;
  },
  clickSearch() {
    UpdateContext.forceUpdate('workListTable');
    const conditionTask = this.state.condition;
    conditionTask.flag = 'pendingTask';
    conditionTask.ClaimantName = $('#WorkCenterSearchFormClaimantid')
      .find('input')
      .eq(1)
      .val();
    conditionTask.InsuredName = $('#WorkCenterSearchFormInsuredid')
      .find('input')
      .eq(1)
      .val();

    const claimStatusChar = JSON.stringify(conditionTask.ClaimStatus);
    if (claimStatusChar === '{}') {
      conditionTask.ClaimStatus = null;
    }
    conditionTask.ClaimantId = null;
    ClaimWorkFlowLeftAction.initCondition(conditionTask);
    UpdateContext.forceUpdate('claimWorkFlowMyTask');
  },

  Clear() {
    this.setState({ condition: {} });
    $('#WorkCenterSearchFormInsuredid')
      .find('input')
      .eq(0)
      .val('');
    $('#WorkCenterSearchFormInsuredid')
      .find('input')
      .eq(1)
      .val('');
    $('#WorkCenterSearchFormClaimantid')
      .find('input')
      .eq(0)
      .val('');
    $('#WorkCenterSearchFormClaimantid')
      .find('input')
      .eq(1)
      .val('');
    this.props.parentComponent.onClickClear();
  },
  selectInsuredDialog() {
    // let indPartyRole = PartyConst.PARTY_ROLE_CODE_IND_CUSTOMER;
    // let orgPartyRole = PartyConst.PARTY_ROLE_CODE_ORG_CUSTOMER;
    // let partyRole = PartyConst.PARTY_ROLE_CODE_IND_CUSTOMER;
    // let stateParam = "insured";
    //
    // let backSet = (partyId) => {
    //     let condition = this.state.condition;
    //     condition.InsuredCode = partyId;
    //     this.setState({"condition":condition});
    // }
    //
    // this.showPartySearchPanelDialog(partyRole,stateParam,backSet);

    this.refs.partySearchDialogForCustomer.show();
  },
  selectClaimantDialog() {
    // let indPartyRole = PartyConst.PARTY_ROLE_CODE_IND_CLAIMANT;
    // let orgPartyRole = PartyConst.PARTY_ROLE_CODE_ORG_CLAIMANT;
    // let partyRole = PartyConst.PARTY_ROLE_CODE_IND_CLAIMANT;
    // let stateParam = "claimant";
    //
    // let backSet = (partyId) => {
    //     let condition = this.state.condition;
    //     condition.ClaimantId = partyId;
    //     this.setState({"condition": condition});
    // }
    //
    // this.showPartySearchPanelDialog(partyRole, stateParam, backSet);
    this.refs.partySearchDialogForClaimant.show();
  },

  showPartySearchPanelDialog(partyRole, stateParam, backSet) {
    this.refs.PartySearchPanelDialog_ref.doContinue = (...args) => {
      const party = args[0];
      if (!party) return;
      if (party.partyInfo.length === 1) {
        backSet(party.partyInfo[0].PartyId);
      }
      this.refs.PartySearchPanelDialog_ref.doContinue = () => { };
    };
    this.refs.PartySearchPanelDialog_ref.showDialog({
      partyRole,
      stateParam,
      partyRoleSelectEnable: false,
      createBtnDisable: true,
      editLinkVisiable: false,
      partyRoleSelectVisiable: false,
    });
  },
  getCustomer(customer) {
    const { condition } = this.state;
    if (customer) {
      condition.InsuredCode = customer.PartyCode;
    }
    const partyAction = new PartyAction();
    let insuredNameCodeTable = partyAction.findPartyNameByCustomerCode(
      customer.PartyCode
    );
    insuredNameCodeTable = insuredNameCodeTable.data;
    let partyName;
    if (!insuredNameCodeTable.PartyName) {
      $('#WorkCenterSearchFormInsuredid')
        .find('input')
        .eq(0)
        .val('');
      $('#WorkCenterSearchFormInsuredid')
        .find('input')
        .eq(1)
        .val('');
    } else {
      condition.InsuredCode = insuredNameCodeTable.PartyId;
      partyName = insuredNameCodeTable.PartyName;
      $('#WorkCenterSearchFormInsuredid')
        .find('input')
        .eq(0)
        .val(customer.PartyCode);
      $('#WorkCenterSearchFormInsuredid')
        .find('input')
        .eq(1)
        .val(partyName);
    }
    condition.InsuredName = partyName;
  },
  getInsuredNameCodeTable(event) {
    const { newValue } = event;
    const partyAction = new PartyAction();
    const { condition } = this.state;
    let insuredNameCodeTable = partyAction.findPartyNameByCustomerCode(
      newValue
    );
    insuredNameCodeTable = insuredNameCodeTable.data;
    let partyName;
    if (!insuredNameCodeTable.PartyName) {
      condition.InsuredCode = '';
      $('#WorkCenterSearchFormInsuredid')
        .find('input')
        .eq(0)
        .val('');
      $('#WorkCenterSearchFormInsuredid')
        .find('input')
        .eq(1)
        .val('');
    } else {
      condition.InsuredCode = insuredNameCodeTable.PartyId;
      partyName = insuredNameCodeTable.PartyName;
      $('#WorkCenterSearchFormInsuredid')
        .find('input')
        .eq(0)
        .val(insuredNameCodeTable.RoleCode);
      $('#WorkCenterSearchFormInsuredid')
        .find('input')
        .eq(1)
        .val(partyName);
    }
    condition.InsuredName = partyName;
    this.setState({
      condition,
    });
  },
  getPartyNameCodeTable(event) {
    const { newValue } = event;
    const partyAction = new PartyAction();
    const { condition } = this.state;
    let partyNameCodeTable = partyAction.findPartyNameByPartyCode(
      newValue
    );
    partyNameCodeTable = partyNameCodeTable.data;
    let partyName;
    if (!partyNameCodeTable.PartyName) {
      condition.ClaimantId = '';
      $('#WorkCenterSearchFormClaimantid')
        .find('input')
        .eq(0)
        .val('');
      $('#WorkCenterSearchFormClaimantid')
        .find('input')
        .eq(1)
        .val('');
    } else {
      condition.ClaimantId = partyNameCodeTable.PartyId;
      partyName = partyNameCodeTable.PartyName;
      $('#WorkCenterSearchFormClaimantid')
        .find('input')
        .eq(0)
        .val(partyNameCodeTable.RoleCode);
      $('#WorkCenterSearchFormClaimantid')
        .find('input')
        .eq(1)
        .val(partyName);
    }
    this.setState({
      condition,
    });
  },
  getClaimant(claimant) {
    const { condition } = this.state;
    if (claimant) {
      condition.ClaimantName = claimant.PartyName;
    }
    const partyAction = new PartyAction();
    let partyNameCodeTable = partyAction.findPartyNameByPartyCode(
      claimant.PartyCode
    );
    partyNameCodeTable = partyNameCodeTable.data;
    let partyName;
    if (!partyNameCodeTable.PartyName) {
      $('#WorkCenterSearchFormClaimantid')
        .find('input')
        .eq(0)
        .val('');
      $('#WorkCenterSearchFormClaimantid')
        .find('input')
        .eq(1)
        .val('');
    } else {
      condition.ClaimantId = partyNameCodeTable.PartyId;
      partyName = partyNameCodeTable.PartyName;
      $('#WorkCenterSearchFormClaimantid')
        .find('input')
        .eq(0)
        .val(partyNameCodeTable.RoleCode);
      $('#WorkCenterSearchFormClaimantid')
        .find('input')
        .eq(1)
        .val(partyName);
    }
  },

  render() {
    const placeHolderCodeText = [];
    placeHolderCodeText[0] = i18n.ClaimCommon.WorkListCode;
    placeHolderCodeText[1] = i18n.ClaimCommon.WorkListValue;
    const { condition } = this.state;
    const _self = this;
    return (
      <div>
        <PartySearchPanelDialog
          ref="PartySearchPanelDialog_ref"
          parentComponent={_self}
          endId="claimSearch"
        />
        <PtyIntegrationSearchDialog
          ref="partySearchDialogForCustomer"
          id="customer"
          isSearch
          getSearchResult={_self.getCustomer}
          isVisibled={false}
          RoleName={PartyConst.PARTY_ROLE_NAME_CUSTOMER}
        />
        <PtyIntegrationSearchDialog
          ref="partySearchDialogForClaimant"
          id="claimant"
          isSearch
          getSearchResult={_self.getClaimant}
          isVisibled={false}
          RoleName={PartyConst.PARTY_ROLE_NAME_CLAIMANT}
        />
        <UIPanel
          id="searchCriteria_task"
          panelTitle={i18n.ClaimCommon.TaskQueryCondition}
        >
          <UISmartPanelGrid layout="horizontal" column="2">
            <UISelect
              id="taskType_task"
              label={i18n.ClaimCommon.TaskType}
              valueLink={_self.bindToState('condition', 'TaskType')}
              codeTable={CodeTableAction.loadDataListAsync(
                CodeTableCstUI.CLAIMTASK,
                'taskType_task'
              )}
            />
            <UISelect
              id="claimStatus_task"
              label={i18n.ClaimCommon.ClaimStatus}
              valueLink={_self.bindToState(
                'condition',
                'ClaimStatus'
              )}
              codeTable={CodeTableAction.loadDataListAsync(
                CodeTableCstUI.ClaimStatus,
                'claimStatus_task'
              )}
            />
            <UIText
              id="claimNumbe-TASKr"
              label={i18n.ClaimCommon.ClaimNumber}
              valueLink={_self.bindToState(
                'condition',
                'ClaimNumber'
              )}
            />
            <UIDateTimePicker
              io="in"
              enabled="true"
              disabled="false"
              format="MM/DD/YYYY"
              label={i18n.ClaimCommon.DateOfLoss}
              valueLink={_self.bindToState(
                'condition',
                'AccidentTime'
              )}
            />
            <UIText
              id="policyNo_TASKr"
              label={i18n.ClaimCommon.PolicyNo}
              valueLink={_self.bindToState('condition', 'PolicyNo')}
            />
            <UISelect
              id="productType_task"
              label={i18n.ClaimCommon.ProductType}
              valueLink={_self.bindToState(
                'condition',
                'ProductCode'
              )}
              codeTable={CodeTableAction.loadDataListAsync(
                CodeTableCstUI.ProductTypeCode,
                'productType_task'
              )}
            />
            <UIUpdatePanel id="WorkCenterSearchFormInsuredid">
              <CodeTableTextNoValuelink
                label={i18n.ClaimCommon.Insured}
                value={condition.InsuredCode}
                onIconClick={_self.selectInsuredDialog}
                onChange={_self.getInsuredNameCodeTable}
                placeHolder={placeHolderCodeText}
              />
            </UIUpdatePanel>
            <UIUpdatePanel id="WorkCenterSearchFormClaimantid">
              <CodeTableTextNoValuelink
                label={i18n.ClaimCommon.Claimant}
                value={condition.ClaimantId}
                onIconClick={_self.selectClaimantDialog}
                suffixIcon="fa fa-search"
                onChange={_self.getPartyNameCodeTable}
                placeHolder={placeHolderCodeText}
                disabled={false}
                isQuery
              />
            </UIUpdatePanel>
          </UISmartPanelGrid>
          <UIBox direction="left">
            <UIButton
              value={i18n.ClaimCommon.Filter}
              onClick={_self.clickSearch}
            />
            <UIButton
              value={i18n.ClaimCommon.Reset}
              onClick={_self.Clear}
            />
          </UIBox>
        </UIPanel>
      </div>
    );
  },
});
export default ClaimWorkFlowMyTaskCriteria;
