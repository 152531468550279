import { UIPage } from 'RainbowUI';
import { ClaimHandlingAction, ClaimHandlingStore } from 'ClaimCommon';
import ClaimRegistration from 'ClaimRegistration';
import ClaimCommonExt from 'ClaimCommonExt';
import ConfigClaimTabsInHandling from './ConfigClaimTabsInHandling';

const ClaimHandling = ClaimCommonExt.getClaimHanding();
const TasksHandling = React.createClass({
  getInitialState() {
    return { caseForm: {} };
  },

  componentWillMount() {
    ClaimHandlingStore.listen(this.onStoreStateChange);
    ClaimHandlingAction.initHandling();
  },
  componentDidMount() {},
  componentWillUnmount() {
    ClaimHandlingStore.unlisten(this.onStoreStateChange);
  },

  onStoreStateChange(storeState) {
    if (!_.isEmpty(storeState.caseForm)) {
      let winTitle = `${i18n.ClaimCommon.Claim} ${storeState.caseForm.ClaimData.ClaimNo}`;
      if (storeState.caseForm.TaskInstance) {
        winTitle = `${winTitle} ${storeState.caseForm.TaskInstance.Variables.TaskName}`;
      }
      document.title = winTitle;
      this.setState({ caseForm: storeState.caseForm });
    }
  },

  render() {
    const { caseForm } = this.state;
    if (!caseForm.ClaimData) {
      return <div />;
    }
    let validationMsg = caseForm.ClaimData.WarningMsg;
    if (!validationMsg) {
      validationMsg = new Array();
    }

    const SubclaimInfo = ClaimRegistration.getSubclaimInfo();
    const ClaimInfoRead = ClaimRegistration.getClaimInfoRead();

    const claimTabs = [];
    const accidentTabs = ConfigClaimTabsInHandling.getAccidentTabs(
      caseForm
    );

    const dtmap = {};
    _.each(
      caseForm.ClaimEntity.ObjectList,
      v => {
        const m = this;
        m[v.ObjectId] = v.DamageType;
      },
      dtmap
    );

    let cloneObjectDatas;
    if (caseForm.ClaimData.ObjectDatas) {
      const deepclone = JSON.stringify(
        caseForm.ClaimData.ObjectDatas
      );
      cloneObjectDatas = JSON.parse(deepclone);
      _.map(cloneObjectDatas, tmpv => {
        const v = tmpv;
        v.DamageType = dtmap[v.ObjectId];
      });
    }

    return (
      <UIPage>
        <ClaimHandling
          claimInfo={(
            <ClaimInfoRead
              prefixId="01"
              data={caseForm.ClaimEntity}
              authMap={caseForm.ClaimData.AuthMap}
              ref="claim_ref"
            />
          )}
          taskInstanceInfo={caseForm.TaskInstance}
          claimData={caseForm.ClaimData}
          handlingLink={caseForm.ClaimData.HandlingLinks}
          warningMsg={validationMsg}
          claimLink={caseForm.ClaimData.claimLinks}
          subclaimInfo={(
            <SubclaimInfo
              data={caseForm.ClaimEntity}
              parentComponent={this}
            />
          )}
          subclaimLink={caseForm.ClaimData.subclaimLinks}
          claimTabs={claimTabs}
          accidentTabs={accidentTabs}
          dtClaimData={cloneObjectDatas}
        />
      </UIPage>
    );
  },
});
export default TasksHandling;
