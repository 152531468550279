import {
  Caller,
  MessageHelper,
  AjaxUtil,
  Exception,
} from 'RainbowUI';
import UrlConfig from '../url/Url';

const CommonService = {
  initUser() {
    let currentUser = '';
    const url = UrlConfig.getCurrentUser;
    const settings = {
      async: false,
      done(data) {
        console.log(`Current user:${JSON.stringify(data)}`);
        currentUser = data;
        console.log(
          'render===renderuser======>>>>>::::::::',
          currentUser
        );
      },
      fail() {
        console.log('No current user.');
      },
    };
    AjaxUtil.doGet(url, {}, settings);
    return currentUser;
  },
  querySolrEntity(conditions, showMsg) {
    let result = {};
    const setting = { method: 'POST', async: false, block: false };
    // TODO to be integrated
    // Caller.call(UrlConfig.queryOutput, conditions, setting).then(
    //   data => {
    //     if (data.Total > 0) {
    //       result = {
    //         result: data.Results[0].SolrDocs,
    //         count: data.Results[0].GroupTotalNum,
    //       };
    //     } else if (showMsg !== false) {
    //       MessageHelper.warning(
    //         i18n.PrintMessage.WarnMsg.Warn_0003,
    //         null,
    //         MessageHelper.POSITION_TOP_RIGHT
    //       );
    //     }
    //   },
    //   err => {
    //     if (showMsg !== false) {
    //       MessageHelper.error(
    //         err.statusText,
    //         null,
    //         MessageHelper.POSITION_TOP_RIGHT
    //       );
    //     }
    //   }
    // );
    return result;
  },
  queryPDCReceipt(conditions, showMsg) {
    let result = {};
    const setting = { method: 'POST', async: false, block: false };
    Caller.call(UrlConfig.queryPDCReceipt, conditions, setting).then(
      data => {
        if (data.TotalElements > 0) {
          result = {
            result: data.ElementsInCurrentPage,
            count: data.TotalElements,
          };
        } else if (showMsg !== false) {
          MessageHelper.warning(
            i18n.PrintMessage.WarnMsg.Warn_0003,
            null,
            MessageHelper.POSITION_TOP_RIGHT
          );
        }
      },
      err => {
        if (showMsg !== false) {
          MessageHelper.error(
            err.statusText,
            null,
            MessageHelper.POSITION_TOP_RIGHT
          );
        }
      }
    );
    return result;
  },
  loadDataList(codeTableId, conditionMap, async) {
    let url = null;
    if (conditionMap) {
      const param = conditionMap;
      console.log(`CodeTable->param:${JSON.stringify(param)}`);
      url = UrlConfig.CodeTableDataCondition + codeTableId;
      return Caller.call(url, param, {
        method: 'POST',
        async: false,
      });
    }
    url = UrlConfig.CodeTableDataList + codeTableId;
    return Caller.call(url, null, { method: 'GET', async: false });
  },
  getprintdoctype(transId, policyId, endoId) {
    let result = [];
    const url = `${UrlConfig.queryPrintTask}?transId=${transId}&policyId=${policyId}&endoId=${endoId}`;
    // var url=UrlConfig.loadPolicyIdByPolicyNo+"?policyNo="+policyId;
    AjaxUtil.doGet(url, null, {
      async: false,
      done(data) {
        console.log('data====>>>>', data);
        result = data;
      },
      fail(error) {
        console.log('load polcy error ====>>>>', error);
        MessageHelper.error(
          null,
          i18n.PrintMessage.ErrorMsg.Error_0005,
          MessageHelper.POSITION_TOP_RIGHT
        );
      },
    });
    return result;
  },
  getDocTypeCodeByConfigType(configType) {
    let result = [];
    const url = `${UrlConfig.getDocTypeCodeByConfigType}/${configType}`;
    AjaxUtil.doGet(url, null, {
      async: false,
      done(data) {
        console.log('data====>>>>', data);
        result = data;
      },
      fail(error) {
        console.log('load polcy error ====>>>>', error);
        MessageHelper.error(
          null,
          i18n.PrintMessage.ErrorMsg.Error_0005,
          MessageHelper.POSITION_TOP_RIGHT
        );
      },
    });
    return result;
  },
  queryProducerCodeByPartyCode(partyCode) {
    let result = [];
    const url = `${UrlConfig.queryProducerCodeByPartyCode}/${partyCode}`;
    AjaxUtil.doGet(url, null, {
      async: false,
      done(data) {
        console.log('data====>>>>', data);
        result = data;
      },
      fail(error) {
        console.log('load polcy error ====>>>>', error);
        MessageHelper.error(
          null,
          i18n.PrintMessage.ErrorMsg.Error_0005,
          MessageHelper.POSITION_TOP_RIGHT
        );
      },
    });
    return result;
  },
  getPrintLoginUserInfo() {
    let result = {};
    const url = UrlConfig.getPrintLoginUserInfo;
    AjaxUtil.doGet(url, null, {
      async: false,
      done(data) {
        console.log('data====>>>>', data);
        result = data;
      },
      fail(error) {
        console.log('load polcy error ====>>>>', error);
        MessageHelper.error(
          null,
          i18n.PrintMessage.ErrorMsg.Error_0005,
          MessageHelper.POSITION_TOP_RIGHT
        );
      },
    });
    return result;
  },
  getUserBranch() {
    let result = [];
    const url = UrlConfig.UserBranch;
    AjaxUtil.doGet(url, null, {
      async: false,
      done(data) {
        console.log('data====>>>>', data);
        result = data;
      },
      fail(error) {
        console.log('load polcy error ====>>>>', error);
        MessageHelper.error(
          null,
          i18n.PrintMessage.ErrorMsg.Error_0005,
          MessageHelper.POSITION_TOP_RIGHT
        );
      },
    });
    return result;
  },
  getNPCUser() {
    let result = [];
    const url = UrlConfig.GetNPCUser;
    AjaxUtil.doGet(url, null, {
      async: false,
      done(data) {
        console.log('data====>>>>', data);
        result = data;
      },
      fail(error) {
        console.log('load polcy error ====>>>>', error);
        MessageHelper.error(
          null,
          i18n.PrintMessage.ErrorMsg.Error_0005,
          MessageHelper.POSITION_TOP_RIGHT
        );
      },
    });
    return result;
  },
  queryAuditTrail(taskId, pageNo, pageSize) {
    let result = {};
    const url = `${UrlConfig.queryAuditTrail}?taskId=${taskId}&pageNo=${pageNo}&pageSize=${pageSize}`;
    const setting = { method: 'GET', async: false, block: false };
    Caller.call(url, null, setting).then(
      data => {
        if (data) {
          result = data;
        } else {
          MessageHelper.warning(
            i18n.PrintMessage.WarnMsg.Warn_0003,
            null,
            MessageHelper.POSITION_TOP_RIGHT
          );
        }
      },
      err => {
        console.log('===error===', err);
        MessageHelper.error(
          err.statusText,
          null,
          MessageHelper.POSITION_TOP_RIGHT
        );
      }
    );
    return result;
  },

  loadProductList() {
    const url = UrlConfig.loadProductList_url;
    const setting = { method: 'GET', async: false, block: false };
    let productData = [];
    Caller.call(url, null, setting).then(
      data => {
        if (data) {
          productData = data;
        } else {
          MessageHelper.warning(
            i18n.PrintMessage.WarnMsg.Warn_0004,
            null,
            MessageHelper.POSITION_TOP_RIGHT
          );
        }
      },
      err => {
        console.log('===error===', err);
        MessageHelper.error(
          err.statusText,
          null,
          MessageHelper.POSITION_TOP_RIGHT
        );
      }
    );

    return productData;
  },
  getInitialDataTypeList() {
    const url = UrlConfig.getInitialDataTypeList;
    const setting = { method: 'GET', async: false, block: false };
    let productData = [];
    Caller.call(url, null, setting).then(
      data => {
        if (data) {
          productData = data;
        } else {
          MessageHelper.warning(
            i18n.PrintMessage.WarnMsg.Warn_0004,
            null,
            MessageHelper.POSITION_TOP_RIGHT
          );
        }
      },
      err => {
        console.log('===error===', err);
        MessageHelper.error(
          err.statusText,
          null,
          MessageHelper.POSITION_TOP_RIGHT
        );
      }
    );

    return productData;
  },

  loadProductLineList() {
    const url = UrlConfig.ProductLineList;
    const setting = { method: 'GET', async: false, block: false };
    let productLineData = [];
    Caller.call(url, null, setting).then(
      data => {
        if (data) {
          productLineData = data;
        } else {
          MessageHelper.warning(
            i18n.PrintMessage.WarnMsg.Warn_0005,
            null,
            MessageHelper.POSITION_TOP_RIGHT
          );
        }
      },
      err => {
        console.log('===error===', err);
        MessageHelper.error(
          err.statusText,
          null,
          MessageHelper.POSITION_TOP_RIGHT
        );
      }
    );

    return productLineData;
  },
  getSubClaimTypeByProductCategory(productCode) {
    const url = `${UrlConfig.SubclaimTypeOfProductLineList}?productLineCode=${productCode}`;
    const setting = { method: 'GET', async: false, block: false };
    let subClaimDataList = [];
    Caller.call(url, null, setting).then(
      data => {
        if (data) {
          subClaimDataList = data;
        } else {
          MessageHelper.warning(
            i18n.PrintMessage.WarnMsg.Warn_0005,
            null,
            MessageHelper.POSITION_TOP_RIGHT
          );
        }
      },
      err => {
        console.log('===error===', err);
        MessageHelper.error(
          err.statusText,
          null,
          MessageHelper.POSITION_TOP_RIGHT
        );
      }
    );

    return subClaimDataList;
  },
  saveOverdueList(printParam) {
    const url = UrlConfig.saveOverdueList;
    const setting = { method: 'POST', async: false, block: false };
    const result = null;
    Caller.call(url, printParam, setting).then(
      data => {
        MessageHelper.success(
          i18n.SendToOverDueSuccess,
          null,
          MessageHelper.POSITION_TOP_RIGHT
        );
      },
      err => {
        console.log('===error===', err);
        MessageHelper.error(
          err.statusText,
          null,
          MessageHelper.POSITION_TOP_RIGHT
        );
      }
    );
    return result;
  },
  savePrintRenew(printParam) {
    const url = UrlConfig.savePrintRenew;
    const setting = { method: 'POST', async: false, block: false };
    const result = null;
    Caller.call(url, printParam, setting).then(
      data => {
        MessageHelper.success(
          'The batch has been submitted successfully',
          null,
          MessageHelper.POSITION_TOP_RIGHT
        );
      },
      err => {
        console.log('===error===', err);
        MessageHelper.error(
          err.statusText,
          null,
          MessageHelper.POSITION_TOP_RIGHT
        );
      }
    );
    return result;
  },

  queryPrintRenewPrintTask(printParam) {
    const url = UrlConfig.queryPrintRenewPrintTask;
    const setting = { method: 'POST', async: false, block: false };
    let result = null;
    Caller.call(url, printParam, setting).then(
      data => {
        result = data;
      },
      err => {
        console.log('===error===', err);
        MessageHelper.error(
          err.statusText,
          null,
          MessageHelper.POSITION_TOP_RIGHT
        );
      }
    );
    return result;
  },

  queryRenewPolicyId(printParam) {
    const url = UrlConfig.queryRenewPolicyId;
    const setting = { method: 'POST', async: false, block: false };
    let result = null;
    Caller.call(url, printParam, setting).then(
      data => {
        result = data;
        // MessageHelper.success(i18n.SendToOverDueSuccess, null, MessageHelper.POSITION_TOP_RIGHT);
      },
      err => {
        console.log('===error===', err);
        MessageHelper.error(
          err.statusText,
          null,
          MessageHelper.POSITION_TOP_RIGHT
        );
      }
    );
    return result;
  },

  queryPolicyIdByWorkFlow(printParam) {
    const url = UrlConfig.queryPolicyIdByWorkFlow;
    const setting = { method: 'POST', async: false, block: false };
    let result = null;
    Caller.call(url, printParam, setting).then(
      data => {
        if (data.Total > 0) {
          result = {
            result: data.Results[0].SolrDocs,
            count: data.Results[0].GroupTotalNum,
          };
        }
      },
      err => {
        console.log('===error===', err);
        MessageHelper.error(
          err.statusText,
          null,
          MessageHelper.POSITION_TOP_RIGHT
        );
      }
    );
    return result;
  },
  queryRenewHistory(policyId) {
    const url = `${UrlConfig.queryRenewHistory}/${policyId}`;
    const setting = { method: 'GET', async: false, block: false };
    let result = null;
    Caller.call(url, {}, setting).then(
      data => {
        result = data;
      },
      err => {
        console.log('===error===', err);
        MessageHelper.error(
          err.statusText,
          null,
          MessageHelper.POSITION_TOP_RIGHT
        );
      }
    );
    return result;
  },
  print(printParam) {
    const url = UrlConfig.printListByRuleId;
    const setting = { method: 'POST', async: false, block: false };
    let result = null;
    Caller.call(url, printParam, setting).then(
      data => {
        if (data) {
          result = data;
          for (const i in data) {
            if (data[i].FailReason) {
              MessageHelper.error(
                data[i].FailReason,
                null,
                MessageHelper.POSITION_TOP_RIGHT
              );
              return result;
            }
          }
          MessageHelper.success(
            'Send to printer successfully',
            null,
            MessageHelper.POSITION_TOP_RIGHT
          );
        }
      },
      err => {
        console.log('===error===', err);
        // ;
        if (err.responseJSON && err.responseJSON.Messages) {
          MessageHelper.error(
            err.responseJSON.Messages['0'].Message,
            null,
            MessageHelper.POSITION_TOP_RIGHT
          );
        } else {
          MessageHelper.warning(
            i18n.PrintMessage.Warning.LetterGeneratingTimeout,
            null,
            MessageHelper.POSITION_TOP_RIGHT
          );
        }
      }
    );

    return result;
  },

  loadPolicyIdByPolicyNo(params) {
    let result;
    const url = `${UrlConfig.loadPolicyIdByPolicyNo}?policyNo=${params}`;
    console.log('url====>>>>', url);
    AjaxUtil.doGet(url, null, {
      async: false,
      done(data) {
        console.log('data====>>>>', data);
        result = data;
      },
      fail(error) {
        MessageHelper.error(
          error,
          null,
          MessageHelper.POSITION_TOP_RIGHT
        );
      },
    });
    return result;
  },

  queryPrintTask(tasks) {
    let result;
    const url = UrlConfig.queryPrintTask;
    console.log('url====>>>>', url);
    AjaxUtil.doPost(url, tasks, {
      async: false,
      done(data) {
        console.log('data====>>>>', data);
        result = data;
      },
      fail(error) {
        MessageHelper.error(
          error,
          null,
          MessageHelper.POSITION_TOP_RIGHT
        );
      },
    });

    return result;
  },

  loadPolicyByPolicyId(policyId) {
    let result;
    const url = `${UrlConfig.loadPolicy}?policyId=${policyId}`;
    console.log('url====>>>>', url);
    AjaxUtil.doGet(url, null, {
      async: false,
      done(data) {
        // console.log("data====>>>>",data);
        result = data;
      },
      fail(error) {
        console.log('load polcy error ====>>>>', error);
        MessageHelper.error(
          null,
          i18n.PrintMessage.ErrorMsg.Error_0005,
          MessageHelper.POSITION_TOP_RIGHT
        );
      },
    });
    return result;
  },

  loadFirstPolicyVersion(policyId) {
    let result;
    const url = UrlConfig.loadFirstPolicyVersion + policyId;
    AjaxUtil.doGet(url, null, {
      async: false,
      done(data) {
        result = data;
      },
      fail() {
        MessageHelper.error(
          null,
          i18n.PrintMessage.ErrorMsg.Error_0005,
          MessageHelper.POSITION_TOP_RIGHT
        );
      },
    });
    return result;
  },

  loadPolicyByVersionId(policyId, versionId) {
    let result;
    const paramStr = `?policyId=${policyId}&revisionId=${versionId}`;
    const url = UrlConfig.loadPolicyByVersion + paramStr;
    AjaxUtil.doGet(url, null, {
      async: false,
      done(data) {
        result = data;
      },
      fail() {
        MessageHelper.error(
          null,
          i18n.PrintMessage.ErrorMsg.Error_0005,
          MessageHelper.POSITION_TOP_RIGHT
        );
      },
    });
    return result;
  },

  download(downloadParam) {
    const policyDefer = $.Deferred();
    const url =
      UrlConfig.download +
      Buffer.alloc(JSON.stringify(downloadParam)).toString('base64');
    console.log('download url', url);
    try {
      this.ajaxGetBlob(url, () => {
        MessageHelper.success(
          'Download successfully',
          null,
          MessageHelper.POSITION_TOP_RIGHT
        );
        policyDefer.resolve();
      });
    } catch (e) {
      MessageHelper.error(
        'Download error',
        null,
        MessageHelper.POSITION_TOP_RIGHT
      );
      policyDefer.reject(e);
    }
    return policyDefer.promise();
  },

  downloadAll(masterPolicyId) {
    const url = UrlConfig.downloadALL + masterPolicyId;
    try {
      this.ajaxGetBlob(url, () => {
        MessageHelper.success(
          i18n.PrintMessage.SuccessMsg.Success_0001,
          null,
          MessageHelper.POSITION_TOP_RIGHT
        );
      });
    } catch (e) {
      MessageHelper.error(
        i18n.PrintMessage.ErrorMsg.Error_0006,
        null,
        MessageHelper.POSITION_TOP_RIGHT
      );
    }
  },

  loadCertificate(masterPolicyId) {
    let result;
    const url = UrlConfig.loadCertificate + masterPolicyId;
    AjaxUtil.doGet(url, null, {
      async: false,
      done(data) {
        // console.log("data====>>>>",data);
        result = data;
      },
      fail(error) {
        console.log('load polcy error ====>>>>', error);
        MessageHelper.error(
          null,
          i18n.PrintMessage.ErrorMsg.Error_0005,
          MessageHelper.POSITION_TOP_RIGHT
        );
      },
    });
    return result;
  },

  downloadAndMergePDF(downloadParam) {
    const policyDefer = $.Deferred();
    const url =
      UrlConfig.downloadAndMergePDF +
      Buffer.alloc(JSON.stringify(downloadParam)).toString('base64');
    console.log('url', url);
    try {
      this.ajaxGetBlob(url, () => {
        MessageHelper.success(
          i18n.PrintMessage.SuccessMsg.Success_0001,
          null,
          MessageHelper.POSITION_TOP_RIGHT
        );
        policyDefer.resolve();
      });
    } catch (e) {
      MessageHelper.error(
        i18n.PrintMessage.ErrorMsg.Error_0006,
        null,
        MessageHelper.POSITION_TOP_RIGHT
      );
      policyDefer.reject(e);
    }
    return policyDefer.promise();
  },

  emailLetterToRecipients(param) {
    const url = UrlConfig.emailLetterToRecipients;
    const setting = { method: 'POST', async: false, block: false };
    const claimSubTypeList = [];
    Caller.call(url, param, setting).then(
      data => {
        if (!data.SuccessList || data.SuccessList.length === 0) {
          MessageHelper.error(
            'Email could not be sent successfully, please check email address is correct .',
            null,
            MessageHelper.POSITION_TOP_RIGHT
          );
        } else if (data.FailList && data.FailList.length > 0) {
          let names = '';
          _.each(data.FailList, failrecept => {
            if (names === '') {
              names = `${failrecept.RecipientTypeName} ${failrecept.PartyName}`;
            } else {
              names = `${names}, ${failrecept.RecipientTypeName} ${failrecept.PartyName}`;
            }
          });
          MessageHelper.warning(
            'Email could not be sent to {0} , please check email address is correct .'.replace(
              '{0}',
              names
            ),
            null,
            MessageHelper.POSITION_TOP_RIGHT
          );
        } else {
          MessageHelper.success(
            'Email has been sent successfully.',
            null,
            MessageHelper.POSITION_TOP_RIGHT
          );
        }
      },
      err => {
        let errorMsg = err.responseJSON.Messages['0'].Message;
        if (!errorMsg) {
          errorMsg = 'Email could not be sent successfully';
        }
        MessageHelper.error(
          errorMsg,
          null,
          MessageHelper.POSITION_TOP_RIGHT
        );
      }
    );
    return claimSubTypeList;
  },
  getSubClaimByCaseId(caseId) {
    const url = UrlConfig.getSubClaimByCaseId + caseId;
    const setting = { method: 'GET', async: false, block: false };
    let claimSubTypeList = [];
    Caller.call(url, null, setting).then(
      data => {
        if (data) {
          claimSubTypeList = data;
        } else {
          MessageHelper.warning(
            i18n.PrintMessage.WarnMsg.Warn_0003,
            null,
            MessageHelper.POSITION_TOP_RIGHT
          );
        }
      },
      err => {
        console.log('===error===', err);
        MessageHelper.error(
          err.statusText,
          null,
          MessageHelper.POSITION_TOP_RIGHT
        );
      }
    );
    return claimSubTypeList;
  },
  getSettlementHistory(caseId) {
    const url = `${UrlConfig.getSettlementHistory +
      caseId}&taskCode=ClaimSettlementTask`;
    const setting = { method: 'GET', async: false, block: false };
    let result = [];
    Caller.call(url, null, setting).then(
      data => {
        if (data) {
          result = data.Model;
        }
      },
      err => {
        console.log('===error===', err);
        MessageHelper.error(
          err.statusText,
          null,
          MessageHelper.POSITION_TOP_RIGHT
        );
      }
    );
    return result;
  },
  getSalvagePayInfo(salvageId) {
    const url = UrlConfig.getSalvagePayInfo + salvageId;
    const setting = { method: 'GET', async: false, block: false };
    let result = [];
    Caller.call(url, null, setting).then(
      data => {
        if (data) {
          result = data.Model;
        }
      },
      err => {
        console.log('===error===', err);
        MessageHelper.error(
          err.statusText,
          null,
          MessageHelper.POSITION_TOP_RIGHT
        );
      }
    );
    return result;
  },

  getSubrogationPayInfo(subrogationId) {
    const url = UrlConfig.getSubrogationPayInfo + subrogationId;
    const setting = { method: 'GET', async: false, block: false };
    let result = [];
    Caller.call(url, null, setting).then(
      data => {
        if (data) {
          result = data.Model;
        }
      },
      err => {
        console.log('===error===', err);
        MessageHelper.error(
          err.statusText,
          null,
          MessageHelper.POSITION_TOP_RIGHT
        );
      }
    );
    return result;
  },

  getSalvageHistory(caseId) {
    const url = UrlConfig.getSalvageHistory + caseId;
    const setting = { method: 'GET', async: false, block: false };
    let result = [];
    Caller.call(url, null, setting).then(
      data => {
        if (data) {
          result = data.Model;
        }
      },
      err => {
        console.log('===error===', err);
        MessageHelper.error(
          err.statusText,
          null,
          MessageHelper.POSITION_TOP_RIGHT
        );
      }
    );
    return result;
  },
  getSubrogationHistory(caseId) {
    const url = UrlConfig.getSubrogationHistory + caseId;
    const setting = { method: 'GET', async: false, block: false };
    let result = [];
    Caller.call(url, null, setting).then(
      data => {
        if (data) {
          result = data.Model;
        }
      },
      err => {
        console.log('===error===', err);
        MessageHelper.error(
          err.statusText,
          null,
          MessageHelper.POSITION_TOP_RIGHT
        );
      }
    );
    return result;
  },
  getSettlement(settlementId) {
    const url = UrlConfig.getSettlement + settlementId;
    const setting = { method: 'GET', async: false, block: false };
    let result = [];
    Caller.call(url, null, setting).then(
      data => {
        if (data) {
          result = data.Model;
        }
      },
      err => {
        console.log('===error===', err);
        MessageHelper.error(
          err.statusText,
          null,
          MessageHelper.POSITION_TOP_RIGHT
        );
      }
    );
    return result;
  },
  getSalvage(settlementId) {
    const url = UrlConfig.getSalvage + settlementId;
    const setting = { method: 'GET', async: false, block: false };
    let result = [];
    Caller.call(url, null, setting).then(
      data => {
        if (data) {
          result = data.Model;
        }
      },
      err => {
        console.log('===error===', err);
        MessageHelper.error(
          err.statusText,
          null,
          MessageHelper.POSITION_TOP_RIGHT
        );
      }
    );
    return result;
  },
  getSubrogation(settlementId) {
    const url = UrlConfig.getSubrogation + settlementId;
    const setting = { method: 'GET', async: false, block: false };
    let result = [];
    Caller.call(url, null, setting).then(
      data => {
        if (data) {
          result = data.Model;
        }
      },
      err => {
        console.log('===error===', err);
        MessageHelper.error(
          err.statusText,
          null,
          MessageHelper.POSITION_TOP_RIGHT
        );
      }
    );
    return result;
  },
  getSubClaimTasks(caseId) {
    const url = `${UrlConfig.getSubClaimTasks + caseId}/false`;
    const setting = { method: 'GET', async: false, block: false };
    let claimSubTypeList = [];
    Caller.call(url, null, setting).then(
      data => {
        if (data) {
          claimSubTypeList = data;
        } else {
          MessageHelper.warning(
            i18n.PrintMessage.WarnMsg.Warn_0003,
            null,
            MessageHelper.POSITION_TOP_RIGHT
          );
        }
      },
      err => {
        console.log('===error===', err);
        MessageHelper.error(
          err.statusText,
          null,
          MessageHelper.POSITION_TOP_RIGHT
        );
      }
    );
    return claimSubTypeList;
  },
  loadClaimInfo(caseId) {
    const url = UrlConfig.loadClaimInfoByCaseId + caseId;
    const setting = { method: 'GET', async: false, block: false };
    let claim = null;
    Caller.call(url, null, setting).then(
      data => {
        if (data) {
          claim = data;
        } else {
          MessageHelper.warning(
            'No record found!',
            null,
            MessageHelper.POSITION_TOP_RIGHT
          );
        }
      },
      err => {
        console.log('===error===', err);
        MessageHelper.error(
          err.statusText,
          null,
          MessageHelper.POSITION_TOP_RIGHT
        );
      }
    );
    return claim;
  },
  queryQuotationVersion(policyId) {
    const url = `${UrlConfig.queryVersion}?policyId=${policyId}`;
    const setting = { method: 'GET', async: false, block: false };
    let quotationVersionData;
    Caller.call(url, null, setting).then(
      data => {
        if (data) {
          quotationVersionData = data;
        } else {
          MessageHelper.warning(
            i18n.PrintMessage.WarnMsg.Warn_0003,
            null,
            MessageHelper.POSITION_TOP_RIGHT
          );
        }
      },
      err => {
        console.log('===error===', err);
        MessageHelper.error(
          err.statusText,
          null,
          MessageHelper.POSITION_TOP_RIGHT
        );
      }
    );

    return quotationVersionData;
  },
  queryAllSchema(policyId) {
    const url = `${UrlConfig.queryAllSchema}?policyId=${policyId}`;
    const setting = { method: 'GET', async: false, block: false };
    let schemaList = [];
    Caller.call(url, null, setting).then(
      data => {
        if (data) {
          schemaList = data;
        } else {
          MessageHelper.warning(
            i18n.PrintMessage.WarnMsg.Warn_0003,
            null,
            MessageHelper.POSITION_TOP_RIGHT
          );
        }
      },
      err => {
        console.log('===error===', err);
        MessageHelper.error(
          err.statusText,
          null,
          MessageHelper.POSITION_TOP_RIGHT
        );
      }
    );

    return schemaList;
  },
  ajaxGet(param, url) {
    // console.log(param);
    console.log(url);
    const policyDefer = $.Deferred();
    AjaxUtil.doGet(url, param, {
      async: false,
      done(data) {
        policyDefer.resolve(data);
      },
      fail(err) {
        policyDefer.reject(err);
        //Exception.throw(err.status, err.responseText);
        MessageHelper.error(
          err.responseJSON.message,
          null,
          MessageHelper.POSITION_TOP_RIGHT
        );
      },
    });
    return policyDefer.promise();
  },

  ajaxPost(param, url) {
    // console.log(param);
    console.log(url);
    const policyDefer = $.Deferred();
    AjaxUtil.doPost(url, param, {
      async: false,
      done(data) {
        policyDefer.resolve(data);
      },
      fail(err) {
        policyDefer.reject(err);
        //Exception.throw(err.status, err.responseText);
        MessageHelper.error(
          err.responseJSON.message,
          null,
          MessageHelper.POSITION_TOP_RIGHT
        );
      },
    });
    return policyDefer.promise();
  },
  loadEndorsementByEndoId(endoId) {
    let result;
    const url = UrlConfig.loadEndorsement + endoId;
    console.log('url====>>>>', url);
    AjaxUtil.doGet(url, null, {
      async: false,
      done(data) {
        console.log('data====>>>>------', data);
        result = data;
      },
      fail(error) {
        console.log('load endorsement error ====>>>>', error);
        MessageHelper.error(
          null,
          i18n.PrintMessage.ErrorMsg.Error_0008,
          MessageHelper.POSITION_TOP_RIGHT
        );
      },
    });
    return result;
  },
  queryPDF(Param) {
    const url =
      UrlConfig.download +
      Buffer.alloc(JSON.stringify(Param)).toString('base64');
    console.log('lily======', url);
    try {
      this.showPDF(url);
    } catch (e) {
      MessageHelper.error(
        i18n.PrintMessage.ErrorMsg.Error_0009,
        null,
        MessageHelper.POSITION_TOP_RIGHT
      );
    }
  },
  printPDF(Param, policyId, documentType) {
    const url =
      UrlConfig.download +
      new Buffer(JSON.stringify(Param)).toString('base64');
    console.log('lily======', url);
    try {
      this.showandprintPDF(url);
    } catch (e) {
      MessageHelper.error(
        i18n.PrintMessage.ErrorMsg.Error_0009,
        null,
        MessageHelper.POSITION_TOP_RIGHT
      );
    }
  },
  queryCertificateList(masterPolicyId, showMsg) {
    let result = {};
    const setting = { method: 'GET', async: false, block: false };
    const url = UrlConfig.queryCertificateList + masterPolicyId;
    Caller.call(url, null, setting).then(
      data => {
        if (data.Total > 0) {
          result = {
            result: data.Results[0].SolrDocs,
            count: data.Results[0].GroupTotalNum,
          };
        } else if (showMsg !== false) {
          MessageHelper.warning(
            i18n.PrintMessage.WarnMsg.Warn_0003,
            null,
            MessageHelper.POSITION_TOP_RIGHT
          );
        }
      },
      err => {
        if (showMsg !== false) {
          MessageHelper.error(
            err.statusText,
            null,
            MessageHelper.POSITION_TOP_RIGHT
          );
        }
      }
    );
    return result;
  },
  showPDF2(suffixUrl) {
    const xhr = new XMLHttpRequest();

    xhr.open('GET', suffixUrl, true);
    xhr.setRequestHeader(
      'Authorization',
      `Bearer ${
      localStorage
        .getItem('Authorization')
        .substr(13)
        .split('&')[0]
      }`
    );
    xhr.responseType = 'blob';
    xhr.onreadystatechange = () => {
      if (
        xhr.readyState == XMLHttpRequest.DONE &&
        xhr.status == 200
      ) {
        let fileName = this.getResponseHeader(
          'Content-Disposition'
        ).split('=')[1];
        if (fileName) {
          const nameStr = fileName.split('"');
          if (nameStr && nameStr.length === 3) {
            fileName = nameStr[1];
          }
        }
        const blob = new Blob([this.response], {
          type: this.getResponseHeader('content-type'),
        });
        if (!!window.ActiveXObject || 'ActiveXObject' in window) {
          window.navigator.msSaveBlob(blob, fileName);
        } else {
          console.log('blob==', blob);
          const downloadUrl = URL.createObjectURL(blob);

          const win = window.open(downloadUrl, 'privew');
          win.origin = null;
          win.parent = null;
          win.opener = null;
          win.location.reload();
        }
      }
    };
    xhr.send(null);
  },
  showPDF(suffixUrl) {
    const xhr = new XMLHttpRequest();

    xhr.open('GET', suffixUrl, true);
    xhr.setRequestHeader(
      'Authorization',
      `Bearer ${
      localStorage
        .getItem('Authorization')
        .substr(13)
        .split('&')[0]
      }`
    );
    xhr.responseType = 'blob';
    xhr.onreadystatechange = () => {
      if (
        xhr.readyState == XMLHttpRequest.DONE &&
        xhr.status == 200
      ) {
        let fileName = this.getResponseHeader(
          'Content-Disposition'
        ).split('=')[1];
        if (fileName) {
          const nameStr = fileName.split('"');
          if (nameStr && nameStr.length === 3) {
            fileName = nameStr[1];
          }
        }
        const blob = new Blob([this.response], {
          type: this.getResponseHeader('content-type'),
        });
        if (!!window.ActiveXObject || 'ActiveXObject' in window) {
          window.navigator.msSaveBlob(blob, fileName);
        } else {
          console.log('blob==', blob);
          const downloadUrl = URL.createObjectURL(blob);
          window.open(downloadUrl, 'privew');
        }
      }
    };
    xhr.send(null);
  },
  showandprintPDF(suffixUrl) {
    const xhr = new XMLHttpRequest();

    xhr.open('GET', suffixUrl, true);
    xhr.setRequestHeader(
      'Authorization',
      `Bearer ${
      localStorage
        .getItem('Authorization')
        .substr(13)
        .split('&')[0]
      }`
    );
    xhr.responseType = 'blob';
    xhr.onreadystatechange = () => {
      if (
        xhr.readyState == XMLHttpRequest.DONE &&
        xhr.status == 200
      ) {
        let fileName = this.getResponseHeader(
          'Content-Disposition'
        ).split('=')[1];
        if (fileName) {
          const nameStr = fileName.split('"');
          if (nameStr && nameStr.length === 3) {
            fileName = nameStr[1];
          }
        }
        const blob = new Blob([this.response], {
          type: this.getResponseHeader('content-type'),
        });
        if (!!window.ActiveXObject || 'ActiveXObject' in window) {
          window.navigator.msSaveBlob(blob, fileName);
        } else {
          const downloadUrl = URL.createObjectURL(blob);

          const win = window.open(downloadUrl, 'print');

          win.origin = null;
          win.parent = null;
          win.opener = null;
          // win.open("",'print');
          // var tt = win.open(downloadUrl,'print');
          win.location.reload();
          win.print();
        }
      }
    };
    xhr.send(null);
  },
  ajaxGetBlob(suffixUrl, callback) {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', suffixUrl, true);
    xhr.setRequestHeader(
      'Authorization',
      `Bearer ${
      localStorage
        .getItem('Authorization')
        .substr(13)
        .split('&')[0]
      }`
    );
    xhr.responseType = 'blob';
    xhr.onreadystatechange = () => {
      if (
        xhr.readyState == XMLHttpRequest.DONE &&
        xhr.status == 200
      ) {
        // var fileName = this.getResponseHeader('Content-Disposition').split("=")[1];
        let fileName = decodeURIComponent(
          this.getResponseHeader('Content-Disposition').split('=')[1],
          'UTF-8'
        ).replace(/%20/g, ' ');
        if (fileName) {
          const nameStr = fileName.split('"');
          if (nameStr && nameStr.length === 3) {
            fileName = nameStr[1];
          }
        }
        const blob = new Blob([this.response], {
          type: this.getResponseHeader('content-type'),
        });
        if (!!window.ActiveXObject || 'ActiveXObject' in window) {
          window.navigator.msSaveBlob(blob, fileName);
        } else {
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
        }
        if (callback) {
          callback();
        }
      }
    };
    xhr.send(null);
  },
};

export default CommonService;
