export default {
  ClaimPartyMaintenance: 'Claim Party Maintenance',
  SelectCommonParty: 'Please select common party',
  OpenSearchLink: 'NOT EXIST IN  THE  LIST',
  IndOrOrgRadio: 'Individual/Organization',
  PartyName: 'Party Name',
  IDNumber: 'ID Number',
  IDNumberForSearch: 'ID Number',
  CreateAs: 'Create as',
  AddToClaimParty: 'ADD TO CLAIM PARTY',
  NotExistInCommonParty: 'NOT EXIST IN COMMON PARTY',
  FirstName: 'First Name',
  LastName: 'Last Name',
  PartyRole: 'Party Role',
  IDType: 'ID Type',
  AddressInfo: 'Address Info',
  AddressType: 'Address Type',
  AddressDetail: 'Address Details',
  AccountInfo: 'Account Info',
  AccountType: 'Account Type',
  AccountDetail: 'Account Details',
  BankName: 'Bank Name',
  AccountHolderName: 'Account Holder Name',
  BankAccountNo: 'Bank Account Number',
  SwiftCode: 'SWIFT Number',
  ContactInfo: 'Contact Info',
  ContactNumber: 'Contact Number',
  ContactEmail: 'Contact Email',
  SaveButton: 'SAVE',
  CancelButton: 'CANCEL',
  SelectAsPayee: 'SELECT AS PAYEE',
  SelectAsPayer: 'SELECT AS PAYER',
  EditLink: 'Edit',
  PayeeTitle: 'Select a Payee',
  PayerTitle: 'Select a Payer',
  EditClaimParty: 'Edit Claim Party',
  RoleInfo: 'Role Info',
  SelectfromCommonParty: 'Select from Common Party',
  CreateNewClaimParty: 'Create New Claim Party',

  // party message
  RelateContactError: 'Please Select Relate Contact',
  RelateAddressError: 'Please Select Relate Address',
  RelateAccountError: 'Please Select Relate Account',
  LoadAuthorityError: 'Load Authority Error',

  ErrorMsg: {},
  ConfirmMsg: {},
  WarnMsg: {},
  SuccessMsg: {},
  InfoMsg: {},
};
