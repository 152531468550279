import {
  UIPanel,
  UISmartPanelGrid,
  UIText,
  UISelect,
} from 'RainbowUI';
import React, { Component } from 'react';
import ReactMixin from 'react-mixin';
import BindToMixin from 'react-binding';

const CodeTableAction = require('../../common/action/CodeTableAction');
const CodeTableCstUI = require('../../common/constant/CodeTableCstUI');

export default class ClosureCommon extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const _self = this;
    return (
      <UIPanel panelTitle={_self.props.title}>
        <UISmartPanelGrid column="1">
          <UISelect
            label={i18n.ClaimReserve.ReasonTypeOfClosure}
            disabled
            codeTable={CodeTableAction.loadDataList(
              CodeTableCstUI.ClaimCloseReason,
              null
            )}
            valueLink={this.bindTo(_self.props.model, 'RejectReason')}
          />
          <UIText
            label={i18n.ClaimReserve.Remark}
            disabled
            valueLink={this.bindTo(_self.props.model, 'Remark')}
          />
        </UISmartPanelGrid>
      </UIPanel>
    );
  }

  handlerStoreChange() {}

  componentDidMount() {}

  componentWillUnmount() {}
}
ReactMixin.onClass(ClosureCommon, BindToMixin);
