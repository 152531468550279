import React from 'react';
import PropTypes from 'prop-types';
import BindToMixin from 'react-binding';

const CalculationReadOnlyStore = require('../store/CalculationReadOnlyStore');
const CalculationLoadAction = require('../action/CalculationLoadAction');
const CalculationInfo = require('./CalculationInfo');

const CalculationReadOnly = React.createClass({
  mixins: [BindToMixin],
  propTypes: {
    calculateId: PropTypes.string,
    isApproveTask: PropTypes.bool,
  },
  getInitialState() {
    return {
      CalculationReadOnly: {
        CalculationInfo: {
          CalculationItem: [],
          CalculationExpense: [],
        },
        OtherInfo: {},
      },

      CalculationApprove: {
        CalculationInfo: {
          CalculationItem: [],
          CalculationExpense: [],
        },
        OtherInfo: {},
      },
    };
  },

  componentDidMount() {
    CalculationLoadAction.loadCalculationReadOnly(
      this.props.calculateId,
      this.props.isApproveTask
    );
    CalculationReadOnlyStore.listen(this.onStoreStateChange);
  },

  componentWillUnMount() {
    CalculationReadOnlyStore.unlisten(this.onStoreStateChange);
  },

  onStoreStateChange(storeState) {
    if (this.props.isApproveTask == true) {
      if (!_.isEmpty(storeState.CalculationApprove)) {
        this.setState({
          CalculationApprove: storeState.CalculationApprove,
        });
      }
    } else if (!_.isEmpty(storeState.CalculationReadOnly)) {
      this.setState({
        CalculationReadOnly: storeState.CalculationReadOnly,
      });
    }
  },

  render() {
    let calculationInfo = this.bindToState(
      'CalculationReadOnly',
      'CalculationInfo'
    );
    let otherInfo = this.bindToState(
      'CalculationReadOnly',
      'OtherInfo'
    );
    const { isApproveTask, history } = this.props;
    const his = history;
    if (isApproveTask == true) {
      calculationInfo = this.bindToState(
        'CalculationApprove',
        'CalculationInfo'
      );
      otherInfo = this.bindToState('CalculationApprove', 'OtherInfo');
    }
    if (_.isEmpty(calculationInfo.value)) {
      return <div />;
    }
    return (
      <div id={`CalculationReadOnly_${isApproveTask}_div`}>
        <CalculationInfo
          calculationInfo={calculationInfo}
          history={his}
          otherInfo={otherInfo}
          readOnly
          isApproveTask={isApproveTask}
        />
      </div>
    );
  },
});
export default CalculationReadOnly;
