import { AjaxUtil } from 'RainbowUI';
import Url from '../url/Url';

export default {
  retrieve(policyNo, policyDate) {
    const deferred = $.Deferred();
    AjaxUtil.doGet(
      `${Url.ClaimNoticeRetrievePolicy + policyNo}/${policyDate}`,
      {},
      {
        async: false,
        done(data) {
          deferred.resolve(data);
        },
        fail(err) {
          deferred.reject(err);
        },
      }
    );
    return deferred.promise();
  },

  retrieveProduct(policyNo, policyDate) {
    const deferred = $.Deferred();
    AjaxUtil.doGet(
      `${Url.ClaimNoticeRetrievePolicyProduct +
        policyNo}/${policyDate}`,
      {},
      {
        async: false,
        done(data) {
          deferred.resolve(data);
        },
        fail(err) {
          deferred.reject(err);
        },
      }
    );
    return deferred.promise();
  },

  retrieveByProduct(params) {
    const deferred = $.Deferred();
    AjaxUtil.doPost(Url.ClaimNoticeRetrievePolicyByProduct, params, {
      async: false,
      done(data) {
        deferred.resolve(data);
      },
      fail(err) {
        deferred.reject(err);
      },
    });
    return deferred.promise();
  },
  retrieveByProductRisk(params) {
    const deferred = $.Deferred();
    AjaxUtil.doPost(
      Url.ClaimNoticeRetrievePolicyByProductRisk,
      params,
      {
        async: false,
        done(data) {
          deferred.resolve(data);
        },
        fail(err) {
          deferred.reject(err);
        },
      }
    );
    return deferred.promise();
  },
  searchPolicy(searchCondition) {
    const deferred = $.Deferred();
    AjaxUtil.doGet(
      Url.SearchPolicy + searchCondition,
      {},
      {
        async: false,
        done(data) {
          deferred.resolve(data);
        },
        fail(err) {
          deferred.reject(err);
        },
      }
    );
    return deferred.promise();
  },
  retrievePolicy(searchCondition) {
    const deferred = $.Deferred();
    AjaxUtil.doPost(Url.RetrievePolicy, searchCondition, {
      async: false,
      done(data) {
        deferred.resolve(data);
      },
      fail(err) {
        deferred.reject(err);
      },
    });
    return deferred.promise();
  },
  checkPolicy(data) {
    const deferred = $.Deferred();
    const url = Url.PolicyValidationURL;
    AjaxUtil.doPost(url, data, {
      async: false,
      done(data) {
        deferred.resolve(data);
      },
      fail(err) {
        deferred.reject(err);
      },
    });
    return deferred.promise();
  },
  checkPolicyNo(policyNo) {
    const deferred = $.Deferred();
    const url = Url.PolicyNoValidation;
    AjaxUtil.doGet(
      url + policyNo,
      {},
      {
        async: false,
        done(data) {
          deferred.resolve(data);
        },
        fail(err) {
          deferred.reject(err);
        },
      }
    );
    return deferred.promise();
  },
  policySearchFlag() {
    const deferred = $.Deferred();
    const url = Url.SearchPolicyFlag;
    console.log('====policySearchFlag=========', url);
    AjaxUtil.doGet(
      url,
      {},
      {
        async: false,
        done(data) {
          deferred.resolve(data);
        },
        fail(err) {
          deferred.reject(err);
        },
      }
    );
    return deferred.promise();
  },

  policyCollsFlag() {
    const deferred = $.Deferred();
    const url = Url.PolicyCollsFlag;
    AjaxUtil.doGet(
      url,
      {},
      {
        async: false,
        done(data) {
          deferred.resolve(data);
        },
        fail(err) {
          deferred.reject(err);
        },
      }
    );
    return deferred.promise();
  },

  getLoginUserAgentCode() {
    let result;
    const url = Url.getLoginUserAgentCode;
    AjaxUtil.doGet(
      url,
      {},
      {
        async: false,
        done(data) {
          result = data;
        },
      }
    );
    return result;
  },

  isChildProduct(packageProductId, childProductId) {
    const deferred = $.Deferred();
    AjaxUtil.doGet(
      `${Url.IsChildProduct + packageProductId}/${childProductId}`,
      {},
      {
        async: false,
        done(data) {
          deferred.resolve(data);
        },
        fail(err) {
          deferred.reject(err);
        },
      }
    );
    return deferred.promise();
  },
  checkPendingEndoByPolicyNo(policyNo) {
    let result;
    AjaxUtil.doGet(
      `${Url.checkPendingEndoByPolicyNo}/${policyNo}`,
      {},
      {
        async: false,
        done(data) {
          result = data;
        },
        fail(err) {
          console.error(err);
        },
      }
    );
    return result;
  },
};
