import {
  UIPanel,
  UITab,
  UITabItem,
  UIBox,
  UIButton,
  DataContext,
} from 'RainbowUI';
import { SettlementDetail } from 'EU00PartyCommonUI';
import ClaimCurrencyData from './ClaimCurrencyData';
import TreatyCurrencyData from './TreatyCurrencyData';
import UrlConfig from '../../url/Url';
import RiAction from '../../../claimparty/action/RiAction';
import ClaimBaseInfo from './ClaimBaseInfo';

const ResinsuranceInfoTab = React.createClass({
  getInitialState() {
    const ReinsuranceDetail = {};
    RiAction.generateReinsuranceDetail(
      ReinsuranceDetail,
      this.props.claimEntity
    );
    let id = null;
    if (ReinsuranceDetail.RiPolicyHistoryId) {
      id = ReinsuranceDetail.RiPolicyHistoryId;
    }
    return {
      id,
      ReinsuranceDetail,
      list: [],
      treatyList: [],
      dataArray: [],
      treatyDataArray: [],
      settlementDetailDatas: [],
      settlementDetailRateArray: [],
      commonTreatyArray: [],
      childMethod: null,
      XOLNum: 0,
    };
  },

  componentWillMount() {
    const { id } = this.state;
    const self = this;
    let flag = false;
    if (id) {
      const data = self.state.ReinsuranceDetail.RiskUnitModel;
      const list = [];
      let treatyList = [];
      let index = 0;
      const dataArray = [];
      let settlementDetailDatas = [];
      const settlementDetailRateArray = [];
      let exchangeRateToClaim = 1;

      if (self.state.ReinsuranceDetail.RiskUnitModel) {
        for (let i = 0; i < data.length; i += 1) {
          data[i].RiPolicyRiskUnitContent.RiPolicyRiskUnitId =
            data[i].RiPolicyRiskUnitId;
          data[i].RiPolicyRiskUnitContent.Index = index;
          list.push(data[i].RiPolicyRiskUnitContent);
          index += 1;
        }
        treatyList = [].concat(JSON.parse(JSON.stringify(list)));
        _.forEach(
          self.state.ReinsuranceDetail.ClaimInfoModel.Records,
          record => {
            if (record.ClaimNo == self.props.claimEntity.ClaimNo) {
              flag = true;
              _.forEach(list, tmpcontent => {
                const content = tmpcontent;
                if (content.RiskUnitNo == record.RiskUnitNo) {
                  content.Reserve = record.Reserve;
                  content.PaymentAmount = record.PaymentAmount;
                  content.totalAmount =
                    Number(record.Reserve) +
                    Number(record.PaymentAmount);
                  content.CurrencyCode = record.ClaimCurrencyCode;
                  content.ClaimId = record.ClaimId;
                  content.RiClaimId = record.RiClaimId;
                }
              });
              _.forEach(treatyList, tmpcontent => {
                const content = tmpcontent;
                if (content.RiskUnitNo == record.RiskUnitNo) {
                  const url = `${UrlConfig.loadRiClaimRiskUnit +
                    record.RiClaimRiskId}:${record.RiClaimId}`;
                  const riClaimRiskUnit = RiAction.loadRiClaimRiskUnit(
                    url
                  );
                  if (
                    riClaimRiskUnit.RiClaimRiskUnitContent
                      .ReserveProportionalTreatyCur
                  ) {
                    content.Reserve = Number(
                      riClaimRiskUnit.RiClaimRiskUnitContent.ReserveProportionalTreatyCur.toFixed(
                        2
                      )
                    );
                  } else {
                    content.Reserve = Number(0.0);
                  }
                  if (
                    riClaimRiskUnit.RiClaimRiskUnitContent
                      .TotalClaimPaidAmountProportionalTreatyCur
                  ) {
                    content.PaymentAmount = Number(
                      riClaimRiskUnit.RiClaimRiskUnitContent.TotalClaimPaidAmountProportionalTreatyCur.toFixed(
                        2
                      )
                    );
                  } else {
                    content.PaymentAmount = Number(0.0);
                  }
                  content.totalAmount =
                    Number(content.PaymentAmount) +
                    Number(content.Reserve);
                  content.CurrencyCode = content.TreatyCurrencyCode;
                  content.ClaimId = record.ClaimId;
                  content.RiClaimId = record.RiClaimId;
                  exchangeRateToClaim =
                    riClaimRiskUnit.RiClaimRiskUnitContent
                      .TotalClaimPaidAmountProportionalTreatyCur /
                    riClaimRiskUnit.RiClaimRiskUnitContent
                      .TotalClaimPaidAmount;
                  DataContext.put(
                    'ExchangeRateToClaim',
                    exchangeRateToClaim
                  );
                  DataContext.put(
                    'TreatyCurrencyCode',
                    content.TreatyCurrencyCode
                  );
                }
              });
            }
          }
        );
        if (flag == false) {
          _.forEach(list, tmpcontent => {
            const content = tmpcontent;
            content.Reserve = 0;
            content.PaymentAmount = 0;
            content.totalAmount = 0;
          });
          _.forEach(treatyList, tmpcontent => {
            const content = tmpcontent;
            content.Reserve = 0;
            content.PaymentAmount = 0;
            content.totalAmount = 0;
          });
        }

        let polCoverageIdsArray = [];
        if (list[0] && list[0].CedingPolicyCoverageIds) {
          polCoverageIdsArray = list[0].CedingPolicyCoverageIds.split(
            ' '
          );
        }
        settlementDetailDatas = RiAction.generateSettlementDetailDatas(
          polCoverageIdsArray,
          self.props.claimEntity.CaseId
        );
        let commonTreatyArray = RiAction.generateShareRateArray(
          list[0]
        );

        _.forEach(commonTreatyArray, commonArray => {
          const tempData = {
            ClaimId: commonArray.ClaimId,
            RiClaimId: commonArray.RiClaimId,
            RiskUnitNo: commonArray.RiskUnitNo,
            RiPolicyRiskUnitId: commonArray.RiPolicyRiskUnitId,
            Priority: commonArray.Priority,
            ShareRate: commonArray.ShareRate,
            TreatyCode: commonArray.TreatyCode,
            CessionType: commonArray.CessionType,
          };
          dataArray.push(tempData);
        });
        const shareRateTableByClaim = RiAction.generateCurrencyRateData(
          dataArray,
          list[0],
          settlementDetailDatas
        );

        _.forEach(commonTreatyArray, commonArray => {
          settlementDetailRateArray.push({
            ClaimId: commonArray.ClaimId,
            RiClaimId: commonArray.RiClaimId,
            RiskUnitNo: commonArray.RiskUnitNo,
            RiPolicyRiskUnitId: commonArray.RiPolicyRiskUnitId,
            Priority: commonArray.Priority,
            ShareRate: commonArray.ShareRate,
            TreatyCode: commonArray.TreatyCode,
            CessionType: commonArray.CessionType,
          });
        });
        let XOLNum = 0;
        if (shareRateTableByClaim.length > 0) {
          _.forEach(
            shareRateTableByClaim[0].RiskUnitShareRateTable,
            DArray => {
              if (DArray.CessionType == '4') {
                XOLNum += 1;
              }
            }
          );
        }
        if (settlementDetailDatas.length > 0) {
          const results = RiAction.generateSettlementDetailRateArray(
            settlementDetailRateArray,
            settlementDetailDatas
          );
          commonTreatyArray = RiAction.getTreatySourceList(
            results,
            settlementDetailDatas
          );
        }
        DataContext.put(
          'ShareRateTableByClaim',
          shareRateTableByClaim
        );
        DataContext.put(
          'SettlementShareRateTable',
          commonTreatyArray
        );
        self.setState({
          list,
          treatyList,
          dataArray,
          settlementDetailDatas,
          settlementDetailRateArray,
          commonTreatyArray,
          XOLNum,
          activeIndex: '1',
        });
      }
    }
  },

  componentDidMount() { },

  render() {
    const self = this;
    return (
      <div>
        {self.renderClaimInfomation()}
        {self.renderResinsuranceSummary()}
        {self.renderSettlementDetail()}
      </div>
    );
  },

  getTabItems() {
    const self = this;
    const tabItems = [];
    tabItems.push(
      <UITabItem title={i18n.ClaimCommon.ClaimCurrency}>
        <ClaimCurrencyData
          shareRateTableByClaim={self.state.shareRateTableByClaim}
          XOLNum={self.state.XOLNum}
          renderSettleDatas={self.renderSettleDatas}
          claimEntity={self.props.claimEntity}
          list={self.state.list}
          dataArray={self.state.dataArray}
        />
      </UITabItem>
    );
    tabItems.push(
      <UITabItem title={i18n.ClaimCommon.TreatyCurrency}>
        <TreatyCurrencyData
          shareRateTableByClaim={self.state.shareRateTableByClaim}
          XOLNum={self.state.XOLNum}
          renderSettleDatas={self.renderSettleDatas}
          claimEntity={self.props.claimEntity}
          list={self.state.treatyList}
        />
      </UITabItem>
    );
    return tabItems;
  },

  renderClaimInfomation() {
    const { claimEntity } = this.props;
    return <ClaimBaseInfo claimEntity={claimEntity} />;
  },

  renderResinsuranceSummary() {
    const self = this;
    const tabItems = self.getTabItems();
    return (
      <UIPanel panelTitle={i18n.ClaimCommon.ResinsuranceSummary}>
        <UITab
          id="resinsuranceInfoTabs"
          onTabChange={self.onTabChange}
        >
          {tabItems}
        </UITab>
      </UIPanel>
    );
  },

  renderSettlementDetail() {
    const self = this;
    return (
      <UIPanel panelTitle={i18n.ClaimCommon.SettlementDetail}>
        <SettlementDetail
          XOLNum={self.state.XOLNum}
          getChildMethod={this.getChildMethod}
          claimEntity={self.props.claimEntity}
          settlementDetailDatas={self.state.settlementDetailDatas}
          settlementDetailRateArray={
            self.state.settlementDetailRateArray
          }
          commonTreatyArray={self.state.commonTreatyArray}
          activeIndex={self.state.activeIndex}
        />
      </UIPanel>
    );
  },

  getChildMethod(childMethod) {
    this.state.childMethod = childMethod;
  },

  renderSettleDatas(
    settlementDetailDatas,
    settlementDetailRateArray
  ) {
    this.state.childMethod(
      settlementDetailDatas,
      settlementDetailRateArray
    );
  },

  onTabChange(event) {
    const { list } = this.state;
    const { dataArray } = this.state;
    const { settlementDetailDatas } = this.state;
    const shareRateTableByClaim = RiAction.generateCurrencyRateData(
      dataArray,
      list[0],
      settlementDetailDatas
    );
    let { commonTreatyArray } = this.state;
    const { settlementDetailRateArray } = this.state;
    _.forEach(commonTreatyArray, commonArray => {
      settlementDetailRateArray.push({
        ClaimId: commonArray.ClaimId,
        RiClaimId: commonArray.RiClaimId,
        RiskUnitNo: commonArray.RiskUnitNo,
        RiPolicyRiskUnitId: commonArray.RiPolicyRiskUnitId,
        Priority: commonArray.Priority,
        ShareRate: commonArray.ShareRate,
        TreatyCode: commonArray.TreatyCode,
        CessionType: commonArray.CessionType,
      });
    });
    if (settlementDetailDatas.length > 0) {
      commonTreatyArray = RiAction.generateSettlementDetailRateArray(
        settlementDetailRateArray,
        settlementDetailDatas
      );
    }
    DataContext.put('ShareRateTableByClaim', shareRateTableByClaim);
    const XOLNum = 0;
    this.renderSettleDatas(settlementDetailDatas, commonTreatyArray);

    this.setState({
      list,
      dataArray,
      settlementDetailDatas,
      settlementDetailRateArray,
      commonTreatyArray,
      XOLNum,
      activeIndex: event.newActiveIndex,
    });
  },
});
export default ResinsuranceInfoTab;
