import {
  AjaxUtil,
  DataContext,
  UIPickList,
  UpdateContext,
} from 'RainbowUI';
import TreeNode from '../constant/TreeNodeCache';

const Url = require('../url/Url');
var ProductCfgTreeAction = require('./ProductCfgTreeAction');
const CauseOfLossRelationService = require('../service/CauseOfLossRelationService');
var ProductCfgTreeAction = require('../action/ProductCfgTreeAction');

export default {
  getCauseOfLossTypeRelation(pageindex, pagesize) {
    const data = TreeNode.TreeCache.get('TreeNode');
    const productLineCodeJS = {
      productLineCode: data.treeNodeCode,
    };
    const searchCondition = {};
    searchCondition.ProductLineCode =
      productLineCodeJS.productLineCode;
    searchCondition.PageNo = pageindex - 1;
    searchCondition.PageSize = pagesize;
    const url =
      Url.ClaimProductRelationOfSubclaimTypeLossOfCauseByCauseOfLoss;
    if (DataContext.get('searchClaimLossCauseCondition')) {
      const condition = DataContext.get(
        'searchClaimLossCauseCondition'
      );
      if (condition.causeofLossCode) {
        searchCondition.CauseofLossCode = condition.causeofLossCode
          .replace(/(^\s*)|(\s*$)/g, '')
          .toUpperCase();
      }
    }
    let result = {};
    AjaxUtil.doPost(url, searchCondition, {
      async: false,
      done(data) {
        result = data.Model;
      },
      fail() {
        MessageHelper.error(
          i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
        );
      },
    });

    return { count: result.Total, result: result.Content };
  },
  openCauseOfLossRelation(event) {
    const lossCauseCode = event.getParameter('lossCauseCode');
    const ProductLineCode = event.getParameter('productLineCode');
    const lossCauseName = event.getParameter('lossCauseName');
    const nodeName = event.getParameter('nodeName');
    const entity = {
      treeNodeCode: ProductLineCode,
      lossCauseCode,
      lossCauseName,
      treeNodeUrl: 'causeofLossAndSubclaimTypeRelation',
      name: nodeName,
    };

    ProductCfgTreeAction.loadNode(event, entity.treeId, entity);
  },
  getPickList(lossCauseCode) {
    const data = CauseOfLossRelationService.loadRelation(
      lossCauseCode
    );
    const pickList = [];
    let pickListItem = {};
    const selectList = data.SelectedList;
    const noSelectList = data.NoSelectedList;
    for (const item in selectList) {
      pickListItem = {
        value: selectList[item].Desc.SubclaimTypeCode,
        label: `${selectList[item].Desc.SubclaimTypeCode}:${selectList[item].Desc.SubclaimTypeName}`,
        selected: true,
      };
      pickList.push(pickListItem);
    }
    for (const item in noSelectList) {
      pickListItem = {
        value: noSelectList[item].SubclaimTypeCode,
        label: `${noSelectList[item].SubclaimTypeCode}:${noSelectList[item].SubclaimTypeName}`,
        selected: false,
      };
      pickList.push(pickListItem);
    }
    return pickList;
  },
  saveCauseOfLoss(event) {
    const entity = event.getParameter('entity');

    const { lossCauseCode } = entity;
    const productLineCode = entity.treeNodeCode;
    const selectList = UIPickList.getTargetList(
      'lossOfCauseSubclaimTypeRelationPickList'
    );
    const saveList = [];
    let saveObj = {};

    for (const item in selectList) {
      saveObj = {
        ProductLineCode: productLineCode,
        SubclaimTypeCode: selectList[item],
        LossCauseCode: lossCauseCode,
      };
      saveList.push(saveObj);
    }
    const claimRelationData = { ClaimLossSubclaimTypeList: saveList };

    CauseOfLossRelationService.updateCauseOfLoss(
      claimRelationData,
      lossCauseCode
    );
    entity.treeNodeUrl = 'causeofLossAndSubclaimType';
    ProductCfgTreeAction.loadNode(event, entity.treeId, entity);
  },
  cancelCauseOfLoss(event) {
    const entity = event.getParameter('entity');
    entity.treeNodeUrl = 'causeofLossAndSubclaimType';
    ProductCfgTreeAction.loadNode(event, entity.treeId, entity);
  },

  searchClaimLossCauseRelationList(condition) {
    DataContext.put('searchClaimLossCauseCondition', condition);
    UpdateContext.forceUpdate(
      'causeofLossAndSubclaimTypeListSearchResultProvider'
    );
  },
};
