import {
  UIDialog,
  UIDialogFooter,
  UIButton,
  UIPanel,
  UIDataTable,
  UIColumn,
  UIText,
  UITextarea,
} from 'RainbowUI';

const CalculationStore = require('../store/CalculationStore');

const LimitAndDeductionInfoDialog = React.createClass({
  getInitialState() {
    return { LimitAndDeductInformation: null };
  },

  componentWillMount() {
    CalculationStore.listen(this.onStoreStateChange);
  },
  componentWillUnmount() {
    CalculationStore.unlisten(this.onStoreStateChange);
  },

  onStoreStateChange(storeState) {
    if (!_.isEmpty(storeState.LimitAndDeductInformation)) {
      this.setState({
        LimitAndDeductInformation:
          storeState.LimitAndDeductInformation,
      });
    }
  },

  render() {
    const { id } = this.props;
    const _id = id || '';
    const mainPage = this.getMainPage();
    return (
      <UIDialog
        id={`limitAndDeductionInfoDialog${_id}`}
        title={i18n.ClaimCalculation.LimitAndDeductionInfo}
        onClose={this.onClose}
        width="90%"
        height="1000px"
        modal="false"
      >
        <UIPanel
          panelTitle={i18n.ClaimCalculation.LimitAndDeductionInfo}
          styleClass="default"
        >
          {mainPage}
        </UIPanel>
        <UIDialogFooter>
          <UIButton
            value={i18n.ClaimCommon.Back}
            onClick={this.back}
          />
        </UIDialogFooter>
      </UIDialog>
    );
  },

  onClose() {
    if (this.props.onClose) {
      this.props.onClose();
    }
  },

  getMainPage() {
    const _self = this;
    const info = this.state.LimitAndDeductInformation;
    if (_.isEmpty(info)) {
      return <div />;
    }
    const policyCurrencyCode = info.PolicyCurrencyCode;

    const mainPage = info.map(tmpval => {
      const val = tmpval;
      if (!val.LimitAndDeductList) {
        val.LimitAndDeductList = [];
      }
      const limitList = [];
      const deductList = [];
      _.each(val.LimitAndDeductList, tmpobj => {
        const obj = tmpobj;
        if (obj.Mode === 'Limit' && obj.Amount != null) {
          if (obj.Description && policyCurrencyCode) {
            obj.Description = _self.addCurrencySign(
              obj.Description,
              policyCurrencyCode
            );
          }
          limitList.push(obj);
        }
        if (obj.Mode === 'Deduct' && obj.Amount != null) {
          if (obj.Description && policyCurrencyCode) {
            obj.Description = _self.addCurrencySign(
              obj.Description,
              policyCurrencyCode
            );
          }
          deductList.push(obj);
        }
      });

      const infoHtml = (
        <div>
          <UIDataTable
            indexable="false"
            pageable="false"
            value={limitList}
          >
            <UIColumn
              width="50%"
              headerTitle={i18n.ClaimCalculation.LimitMode}
              render={data => {
                return (
                  <UIText
                    model={data}
                    property="LimitType"
                    io="out"
                  />
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.ClaimCalculation.Description}
              render={data => {
                _self.formatDescription(data);
                return (
                  <UITextarea
                    model={data}
                    property="Description"
                    enabled="false"
                  />
                );
              }}
            />
          </UIDataTable>
          <UIDataTable
            indexable="false"
            pageable="false"
            value={deductList}
          >
            <UIColumn
              width="50%"
              headerTitle={i18n.ClaimCalculation.DeductibleType}
              render={data => {
                return (
                  <UIText
                    model={data}
                    property="LimitType"
                    io="out"
                  />
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.ClaimCalculation.Description}
              render={data => {
                _self.formatDescription(data);
                return (
                  <UITextarea
                    model={data}
                    property="Description"
                    enabled="false"
                  />
                );
              }}
            />
          </UIDataTable>
        </div>
      );

      let groupCTHTML = null;
      if (!_.isEmpty(val.GroupCTNames)) {
        groupCTHTML = val.GroupCTNames.map(ctName => {
          if (_.isEmpty(ctName)) {
            return <div />;
          }
          return <div>{ctName}</div>;
        });
      }
      return (
        <div
          style={{ borderBottom: '1px solid #cbcbcb' }}
          className="clearfix"
        >
          <h5
            style={{
              borderBottom: '1px solid #cbcbcb',
              marginBottom: '0px',
            }}
          >
            {val.Name}
            <br />
            {groupCTHTML}
          </h5>

          {infoHtml}
        </div>
      );
    });
    return mainPage;
  },

  back() {
    const _id = this.props.id || '';
    UIDialog.hide(`limitAndDeductionInfoDialog${_id}`);
    this.onClose();
  },

  fmoney(tmps, tmpn) {
    let s = tmps;
    let n = tmpn;
    n = n > 0 && n <= 20 ? n : 2;
    s = `${parseFloat(`${s}`.replace(/[^\d.-]/g, '')).toFixed(n)}`;
    const l = s
      .split('.')[0]
      .split('')
      .reverse();
    const r = s.split('.')[1];
    let t = '';
    for (let i = 0; i < l.length; i += 1) {
      t +=
        l[i] + ((i + 1) % 3 === 0 && i + 1 !== l.length ? ',' : '');
    }
    return `${t
      .split('')
      .reverse()
      .join('')}.${r}`;
  },

  formatDescription(datas) {
    const data = datas;
    if (data.Description) {
      const tempArr = data.Description.split(':');
      const tempMoney = tempArr[1];
      let finalTempMoney = tempMoney.substr(
        tempMoney.lastIndexOf(' ') + 1
      );
      const oldPart = tempMoney.substr(
        0,
        tempMoney.lastIndexOf(' ') + 1
      );
      if (parseInt(finalTempMoney, 10)) {
        finalTempMoney = this.fmoney(tempMoney, 2);
        tempArr[1] = oldPart + finalTempMoney;
        data.Description = tempArr.join(':');
      }
    }
  },

  addCurrencySign(descObject, currencyCode) {
    const _self = this;
    let subIndex = 0;
    let unit = '';
    let returnDesc = '';
    let amountFlag = true;
    let hasColon = true;
    if (currencyCode) {
      unit = window.EU00.getCurrencySignByCurrencyCode(currencyCode);
    }
    if (descObject.indexOf(unit) !== -1) {
      return descObject;
    }
    for (let i = 0; i < descObject.length; i += 1) {
      const charTemp = descObject.charAt(i);
      if (_self.isDigit(charTemp)) {
        subIndex = i;
        break;
      }
    }
    const subDescStr = descObject.substring(
      subIndex,
      descObject.length
    );
    const reg = /^[0-9][0-9]*(.[0-9]+)?$/;
    const pattern = new RegExp(reg);
    if (!pattern.test(subDescStr)) {
      amountFlag = false;
    }
    const subDescPrefix = descObject.substring(0, subIndex);
    if (subDescPrefix && subDescPrefix.indexOf(':') < 0) {
      hasColon = false;
    }
    if (amountFlag) {
      if (!hasColon) {
        returnDesc = `${descObject.substring(
          0,
          subIndex
        )}: ${unit} ${descObject.substring(
          subIndex,
          descObject.length
        )}`;
      } else {
        returnDesc = `${descObject.substring(
          0,
          subIndex
        )} ${unit} ${descObject.substring(
          subIndex,
          descObject.length
        )}`;
      }
      return returnDesc;
    }
    return descObject;
  },
  isDigit(charTemp) {
    const reg = /^[0-9]*$/;
    const pattern = new RegExp(reg);
    return pattern.test(charTemp);
  },
});
export default LimitAndDeductionInfoDialog;
