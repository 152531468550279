import {
  UIText,
  UICurrency,
  UIDataTable,
  UIColumn,
  UILink,
  UIParam,
  UIUpdatePanel,
} from 'RainbowUI';
import ClaimCommonExt from 'ClaimCommonExt';
import PropTypes from 'prop-types';
import ReserveStore from '../store/ReserveStore';

const CloseReserveDialog = require('./CloseReserveDialog');
const ReserveAction = require('../action/ReserveAction');
const ReserveHistoryAction = require('../action/ReserveHistoryAction');
const OpenReserveAction = require('../action/OpenReserveAction');
const ReserveHistoryDialog = require('./ReserveHistoryDialog');
const OpenReserveDialog = require('./OpenReserveDialog');
const CodeTableAction = require('../../common/action/CodeTableAction');
const CodeTableCstUI = require('../../common/constant/CodeTableCstUI');
const ClaimCstUI = require('../../common/constant/ClaimCstUI');

const ReserveLight = ClaimCommonExt.getReserveLight();

const coInsurenceWith = {
  ReserveType: '10%',
  ReserveStatus: '9%',
  LockStatus: '9%',
  CoInsurer: '15%',
  Leader: '9%',
  ShareRate: '9%',
  OutstandingReserve: '10%',
  Settled: '10%',
  IncurredAmount: '10%',
  Action: '9%',
};
let normalWith = {
  ReserveType: '16%',
  ReserveStatus: '13%',
  LockStatus: '13%',
  CoInsurer: '0%',
  Leader: '0%',
  ShareRate: '0%',
  OutstandingReserve: '15%',
  Settled: '15%',
  IncurredAmount: '15%',
  Action: '13%',
};

const displayNone = { display: 'none' };
const ReserveInfo = React.createClass({
  propTypes: {
    objectId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  },

  getInitialState() {
    return { reserveListData: [] };
  },
  componentDidMount() {
    $('.ReserveType')
      .find('a')
      .css('color', '#0065b3');
  },
  componentWillMount() {
    ReserveStore.listen(this.onStoreStateChange);
    ReserveAction.initReserve(this.props.objectId);
  },

  componentWillUnmount() {
    ReserveStore.unlisten(this.onStoreStateChange);
  },

  onStoreStateChange(storeState) {
    this.setState({ reserveListData: storeState.reserveListData });
  },

  convertOutstandingReserve2Positive(tempvalue) {
    let value = tempvalue;
    if (value < 0) {
      value *= -1;
    }
    return value;
  },

  getPanelHeader(item) {
    let paCode = CodeTableAction.loadDataList(
      CodeTableCstUI.ClaimCoverageCodePACode,
      null
    ).getValue(item.CoverageCode);

    if (!_.isEmpty(`${paCode}`)) {
      return `${item.CoverageName}(${paCode})`;
    }

    return item.CoverageName;
  },

  render() {
    const ReopenReserveDialog = ClaimCommonExt.getReopenReserveDialog();
    const CalculateReserveDialog = ClaimCommonExt.getCalculateReserveDialog();
    const { objectId, id, authMap } = this.props;
    return (
      <div>
        <UIUpdatePanel
          id={`reserve_updatePanel_${objectId}`}
          render={this.getReserveListData}
        />
        <CloseReserveDialog
          relateId={objectId}
          id={id}
          ref="CloseReserveDialog_ref"
        />
        <ReopenReserveDialog
          objectId={objectId}
          ref="ReopenReserveDialog"
        />
        <CalculateReserveDialog
          objectId={objectId}
          ref="CalculateReserveDialog"
        />
        <ReserveHistoryDialog objectId={objectId} authMap={authMap} />
        <OpenReserveDialog objectId={objectId} />
      </div>
    );
  },
  getReserveListData() {
    const _self = this;
    const { reserveListData } = this.state;
    const { objectId } = _self.props;

    if (!reserveListData) {
      return <div />;
    }
    let coinColumn_Leader = <div style={displayNone} />;
    let coinColumn_ShareRate = <div style={displayNone} />;
    let coinColumn_Insurance = <div style={displayNone} />;

    if (
      reserveListData.length === 0 ||
      (reserveListData[0].ReserveList &&
        !reserveListData[0].ReserveList[0].ReserveCoinList)
    ) {
      coinColumn_Leader = <div style={displayNone} />;
      coinColumn_ShareRate = <div style={displayNone} />;
      coinColumn_Insurance = <div style={displayNone} />;
    } else {
      //console.log('reserveListData', reserveListData[0]);
      normalWith = coInsurenceWith;
      if (
        reserveListData[0].ReserveList &&
        reserveListData[0].ReserveList[0].ReserveCoinList.length > 0
      ) {
        coinColumn_Leader = (
          <UIColumn
            headerTitle={i18n.ClaimReserve.CoinLeader}
            width={normalWith.Leader}
            render={data => {
              const coinList = data.ReserveCoinList.map(val_ => {
                return (
                  <div>
                    <UIText io="out" value={val_.Leader} />
                    <br />
                  </div>
                );
              });
              return (
                <div>
                  {coinList}
                  <UIText io="out" value="-" />
                </div>
              );
            }}
          />
        );
      }
      if (
        reserveListData[0].ReserveList &&
        reserveListData[0].ReserveList[0].ReserveCoinList.length > 0
      ) {
        coinColumn_ShareRate = (
          <UIColumn
            headerTitle={i18n.ClaimReserve.ShareRate}
            width={normalWith.ShareRate}
            render={data => {
              const coinList = data.ReserveCoinList.map(val_ => {
                return (
                  <div>
                    <UIText
                      io="out"
                      value={`${val_.ShareRate * 100}%`}
                    />
                    <br />
                  </div>
                );
              });
              return (
                <div>
                  {coinList}
                  <UIText io="out" value="100%" />
                </div>
              );
            }}
          />
        );
      }
      if (
        reserveListData[0].ReserveList &&
        reserveListData[0].ReserveList[0].ReserveCoinList.length > 0
      ) {
        coinColumn_Insurance = (
          <UIColumn
            headerTitle={i18n.ClaimReserve.CoinSurance}
            width={normalWith.CoInsurer}
            render={data => {
              const coinList = data.ReserveCoinList.map(val_ => {
                return (
                  <div>
                    <UIText io="out" value={val_.CoinCompanyName} />
                    <br />
                  </div>
                );
              });
              return (
                <div>
                  {coinList}
                  <UIText io="out" value={i18n.ClaimReserve.Total} />
                </div>
              );
            }}
          />
        );
      }
    }

    const reserveTypeCode = CodeTableAction.loadDataList(
      CodeTableCstUI.ClaimReserveType,
      null
    );
    const statusTypeCode = CodeTableAction.loadDataList(
      CodeTableCstUI.ClaimReserveStatus,
      null
    );
    let renderColumnIndex = 0;
    const reserveData = reserveListData.map(val => {
      return (
        <div>
          <div
            style={{
              position: 'relative',
              float: 'right',
              margin: '5px',
            }}
          >
            <UILink
              value={i18n.ClaimReserve.ReserveHistory}
              onClick={ReserveHistoryAction.showHistory}
              style={{
                marginLeft: '10px',
                color: '#FFF',
                textDecoration: 'underline',
              }}
            >
              <UIParam
                name="reservehistory"
                value={{ itemId: val.ItemId, objectId }}
              />
            </UILink>
            <UILink
              value={i18n.ClaimReserve.OpenReserve}
              onClick={OpenReserveAction.showOpenReserve}
              style={{
                marginLeft: '10px',
                color: '#FFF',
                textDecoration: 'underline',
                marginRight: '10px',
              }}
            >
              <UIParam
                name="openReserve"
                value={{ itemId: val.ItemId, objectId }}
              />
            </UILink>
            <ReserveLight
              subclaimIndex={_self.props.id}
              reserveStatus={[
                val.LossStatus,
                val.SalvageStatus,
                val.SubrogationStatus,
              ]}
              reserveCloseAuth={val.ReserveCloseAuth}
              relateId={val.ItemId}
              level="3"
              coverageName={val.CoverageName}
              parentComponent={_self}
            />
          </div>
          <UIDataTable
            value={val.ReserveList}
            indexable="false"
            infoable="false"
            isHeading="false"
            pageable="false"
            headerTitle={this.getPanelHeader(val)}
          >
            <UIColumn
              headerTitle={i18n.ClaimReserve.ReserveType}
              width={normalWith.ReserveType}
              render={data => {
                let cft;
                reserveTypeCode.codes.forEach(val => {
                  if (data.ReserveType == val.id) {
                    cft = val.text;
                  }
                });
                return <UIText value={cft} io="out" />;
              }}
            />
            <UIColumn
              headerTitle={i18n.ClaimReserve.ReserveStatus}
              width={normalWith.ReserveStatus}
              render={data => {
                let staCode;
                for (
                  let i = 0, j = statusTypeCode.codes.length;
                  i < j;
                  i += 1
                ) {
                  if (data.StatusCode == statusTypeCode.codes[i].id) {
                    staCode = statusTypeCode.codes[i].text;
                    break;
                  }
                }
                let colo = '#2196f3';
                let disabled = false;
                if (!data.CanClose) {
                  colo = 'grey';
                  disabled = true;
                }
                const auth = data.Permission;
                if (!disabled && auth == 0) {
                  colo = 'grey';
                  disabled = true;
                }
                if (!disabled) {
                  let authCst = '';
                  if (
                    data.StatusCode ==
                    ClaimCstUI.RESERVE_STATUS.Unopen
                  ) {
                    authCst = '1,3,5,7';
                  } else if (
                    data.StatusCode ==
                    ClaimCstUI.RESERVE_STATUS.Closed
                  ) {
                    authCst = '4,5,6,7';
                  } else {
                    authCst = '2,3,6,7';
                  }
                  if (authCst.indexOf(auth) == -1) {
                    colo = 'grey';
                    disabled = true;
                  }
                }
                return (
                  <div>
                    <UILink
                      value={staCode}
                      onClick={_self.onChangeStatus}
                      disabled={disabled}
                      style={{ color: colo }}
                    >
                      <UIParam
                        name="statusCode"
                        value={data.StatusCode}
                      />
                      <UIParam
                        name="reserveId"
                        value={data.ReserveId}
                      />
                      <UIParam
                        name="currencyCode"
                        value={data.CurrencyCode}
                      />
                      <UIParam
                        name="reserveType"
                        value={data.ReserveType}
                      />
                      <UIParam
                        name="positive"
                        value={data.Positive}
                      />
                      <UIParam name="objectId" value={objectId} />
                      <UIParam
                        name="coverageName"
                        value={val.CoverageName}
                      />
                    </UILink>
                  </div>
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.ClaimReserve.LockStatus}
              width={normalWith.LockStatus}
              render={data => {
                return (
                  <UIText
                    io="out"
                    value={
                      data.LockFlag === 'N'
                        ? i18n.ClaimReserve.Unlock
                        : i18n.ClaimReserve.Locked
                    }
                  />
                );
              }}
            />
            {coinColumn_Insurance}
            {coinColumn_Leader}
            {coinColumn_ShareRate}
            <UIColumn
              headerTitle={i18n.ClaimReserve.OutstandingReserve}
              width={normalWith.OutstandingReserve}
              textAlign="right"
              render={data => {
                let coinList = null;
                renderColumnIndex += 1;
                console.log('renderColumnIndex', renderColumnIndex);
                if (
                  data.ReserveCoinList &&
                  data.ReserveCoinList.length > 0
                ) {
                  coinList = data.ReserveCoinList.map(val_ => {
                    console.log('val_  ', val_);
                    return (
                      <div>
                        <UICurrency
                          id={`CURRENCY_CODE_ReserveCoinList${renderColumnIndex}_${val_['@pk']}`}
                          io="out"
                          value={val_.OutstandingAmount}
                          unit={
                            window.EU00.getCurrencySignByCurrencyCode(
                              data.CurrencyCode
                            ) || ''
                          }
                        />
                        <br />
                      </div>
                    );
                  });
                }

                return (
                  <div>
                    {coinList}
                    <UICurrency
                      id={`CURRENCY_CODE_ReserveCoinAmount${renderColumnIndex}${data.ReserveId}`}
                      io="out"
                      value={data.OutstandingAmount}
                      unit={
                        window.EU00.getCurrencySignByCurrencyCode(
                          data.CurrencyCode
                        ) || ''
                      }
                    />
                  </div>
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.ClaimReserve.Settled}
              width={normalWith.Settled}
              textAlign="right"
              render={data => {
                let coinList = null;
                if (
                  data.ReserveCoinList &&
                  data.ReserveCoinList.length > 0
                ) {
                  coinList = data.ReserveCoinList.map(val_ => {
                    return (
                      <div>
                        <UICurrency
                          id={`CURRENCY_CODE_SettleInfo${renderColumnIndex}_${val_['@pk']}`}
                          io="out"
                          value={val_.SettleAmount}
                          unit={
                            window.EU00.getCurrencySignByCurrencyCode(
                              data.CurrencyCode
                            ) || ''
                          }
                        />
                        <br />
                      </div>
                    );
                  });
                }
                return (
                  <div>
                    {coinList}
                    <UICurrency
                      id={`CURRENCY_CODE_SettleAmountTotal${renderColumnIndex}${data.ReserveId}`}
                      io="out"
                      value={data.SettleAmount}
                      unit={
                        window.EU00.getCurrencySignByCurrencyCode(
                          data.CurrencyCode
                        ) || ''
                      }
                    />
                  </div>
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.ClaimReserve.IncurredAmount}
              width={normalWith.IncurredAmount}
              textAlign="right"
              render={data => {
                let coinList = null;
                if (
                  data.ReserveCoinList &&
                  data.ReserveCoinList.length > 0
                ) {
                  coinList = data.ReserveCoinList.map(val_ => {
                    return (
                      <div>
                        <UICurrency
                          id={`CURRENCY_CODE_IncurredAmount${renderColumnIndex}_${val_['@pk']}`}
                          io="out"
                          value={val_.OutstandingAmount + val_.SettleAmount}
                          unit={
                            window.EU00.getCurrencySignByCurrencyCode(
                              data.CurrencyCode
                            ) || ''
                          }
                        />
                        <br />
                      </div>
                    );
                  });
                }

                return (
                  <div>
                    {coinList}
                    <UICurrency
                      id={`CURRENCY_CODE_IncurredAmountTotal${renderColumnIndex}${data.ReserveId}`}
                      io="out"
                      value={data.OutstandingAmount + data.SettleAmount}
                      unit={
                        window.EU00.getCurrencySignByCurrencyCode(
                          data.CurrencyCode
                        ) || ''
                      }
                    />
                  </div>
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.ClaimReserve.Action}
              width={normalWith.Action}
              render={data => {
                let colo = '#2196f3';
                let disabled = false;
                if (
                  data.StatusCode ==
                  ClaimCstUI.RESERVE_STATUS.Unopen ||
                  data.StatusCode == ClaimCstUI.RESERVE_STATUS.Closed
                ) {
                  colo = 'grey';
                  disabled = true;
                }
                const auth = data.Permission;
                if (!disabled && '1,3,5,7'.indexOf(auth) == -1) {
                  colo = 'grey';
                  disabled = true;
                }
                return (
                  <UILink
                    value={i18n.ClaimReserve.ReserveUpdate}
                    onClick={_self.onCalculate}
                    disabled={disabled}
                    style={{ color: colo }}
                  >
                    <UIParam
                      name="reserveId"
                      value={data.ReserveId}
                    />
                    <UIParam
                      name="outstandingAmount"
                      value={data.OutstandingAmount}
                    />
                    <UIParam
                      name="currencyCode"
                      value={data.CurrencyCode}
                    />
                    <UIParam
                      name="reserveType"
                      value={data.ReserveType}
                    />
                    <UIParam name="positive" value={data.Positive} />
                    <UIParam name="objectId" value={objectId} />
                    <UIParam
                      name="coverageName"
                      value={val.CoverageName}
                    />
                  </UILink>
                );
              }}
            />
          </UIDataTable>
        </div>
      );
    });
    return reserveData;
  },

  onChangeStatus(event) {
    const statusCode = event.getParameter('statusCode');
    const reserveId = event.getParameter('reserveId');
    const currencyCode = event.getParameter('currencyCode');
    const reserveType = event.getParameter('reserveType');
    const positive = event.getParameter('positive');
    const coverageName = event.getParameter('coverageName');
    const subclaimIndex = this.props.id;
    if (
      statusCode == ClaimCstUI.RESERVE_STATUS.Open ||
      statusCode == ClaimCstUI.RESERVE_STATUS.Reopen
    ) {
      const level = 4;
      this.refs.CloseReserveDialog_ref.showDialog(
        reserveType,
        level,
        null,
        reserveId,
        coverageName,
        '',
        subclaimIndex
      );
    } else {
      const reserveVO = {
        ReserveId: reserveId,
        CurrencyCode: currencyCode,
        Positive: positive,
        CoverageName: coverageName,
        ReserveType: reserveType,
        SubclaimIndex: subclaimIndex,
      };
      this.refs.ReopenReserveDialog.showDialog(reserveVO);
    }
  },

  onCalculate(event) {
    const reserveId = event.getParameter('reserveId');
    const outstandingAmount = event.getParameter('outstandingAmount');
    const currencyCode = event.getParameter('currencyCode');
    const objectId = event.getParameter('objectId');
    const coverageName = event.getParameter('coverageName');
    const reserveType = event.getParameter('reserveType');
    const positive = event.getParameter('positive');
    const subclaimIndex = this.props.id;
    const reserveVO = {
      ReserveId: reserveId,
      OutstandingAmount: outstandingAmount,
      CurrencyCode: currencyCode,
      ObjectId: objectId,
      CoverageName: coverageName,
      Positive: positive,
      ReserveType: reserveType,
      SubclaimIndex: subclaimIndex,
    };
    this.refs.CalculateReserveDialog.showDialog(reserveVO);
  },
});

export default ReserveInfo;
