import {
  UIDataTable,
  UIColumn,
  UIText,
  UISelect,
  DataContext,
  CodeTable,
  UICurrency,
  UIDateTimePicker,
} from 'RainbowUI';
import CodeTableAction from '../../codetable/CodeTable';
import SettlementDetailRateData from './SettlementDetailRateData';

const SettlementDetail = React.createClass({
  getInitialState() {
    const result = new CodeTableAction().getPartialFinal();
    const payFinalCodeTable = [];
    let RiClaimRecoveries = null;
    _.forEach(result, (data, index) => {
      if (data.id == 4) {
        payFinalCodeTable.push({ id: data.id, text: 'Final' });
      } else {
        payFinalCodeTable.push({ id: data.id, text: 'Partial' });
      }
    });
    _.forEach(this.props.settlementDetailRateArray, data => {
      if (data.CessionType == '4') {
        RiClaimRecoveries = data.RiClaimRecoveries;
      }
    });
    return {
      childMethod: null,
      selectedData: [],
      settlementDetailDatas: this.props.settlementDetailDatas,
      settlementDetailRateArray: this.props.settlementDetailRateArray,
      commonTreatyArray: this.props.commonTreatyArray,
      payFinalCodeTable,
      RiClaimRecoveries,
    };
  },

  componentWillMount() { },

  componentDidMount() {
    this.props.getChildMethod(this.renderSettleDetail);
  },

  render() {
    const { activeIndex, riPolicyRiskUnitList, XOLNum } = this.props;
    const { settlementDetailRateArray } = this.state;
    return (
      <div>
        {this.renderSettlementDetailDataTable()}
        <SettlementDetailRateData
          activeIndex={activeIndex}
          riPolicyRiskUnitList={riPolicyRiskUnitList}
          XOLNum={XOLNum}
          defaultData={settlementDetailRateArray}
          getChildMethod={this.getChildMethod}
        />
      </div>
    );
  },

  renderSettlementDetailDataTable() {
    const self = this;
    const list = self.state.settlementDetailDatas;
    if (list[0]) {
      DataContext.put('SettlementNo', list[0].SettlementNo);
    } else {
      DataContext.put('SettlementNo', null);
    }
    return (
      <UIDataTable
        id="SettlementDetailData"
        pageable="false"
        indexable="false"
        value={list}
        selectionMode="single"
        onRowSelect={self.selectSettlementData}
        selectState={[0]}
      >
        <UIColumn
          headerTitle={i18n.SettlementType}
          value="SettlementType"
        >
          <UISelect
            io="out"
            codeTable={new CodeTableAction().getSettlementType()}
          />
        </UIColumn>
        <UIColumn
          headerTitle={i18n.SettlementNo}
          render={data => {
            return (
              <div>
                <UIText value={data.SettlementNo} io="out" />
              </div>
            );
          }}
        />
        <UIColumn
          headerTitle={i18n.TransactionDate}
          render={data => {
            return (
              <div>
                <UIDateTimePicker
                  placeHolder="MM/DD/YYYY"
                  format="MM/DD/YYYY"
                  value={data.TransactionDate}
                  io="out"
                />
              </div>
            );
          }}
        />
        <UIColumn headerTitle={i18n.PartialFinal} value="PayFinal">
          <UISelect
            io="out"
            codeTable={new CodeTable(self.state.payFinalCodeTable)}
          />
        </UIColumn>
        <UIColumn
          headerTitle={i18n.SettledAmount}
          width="auto"
          render={data => {
            const treatyCurrencyCode = DataContext.get(
              'TreatyCurrencyCode'
            );
            if (self.props.activeIndex == '1') {
              return (
                <div style={{ float: 'left' }}>
                  <UICurrency
                    value={data.SettleAmount}
                    io="out"
                    unit={window.EU00.getCurrencySignByCurrencyCode(
                      data.SettleCurrency
                    )}
                  />
                </div>
              );
            }
            return (
              <div style={{ float: 'left' }}>
                <UICurrency
                  value={data.SettlementAmountTreatyCur}
                  io="out"
                  unit={window.EU00.getCurrencySignByCurrencyCode(
                    treatyCurrencyCode
                  )}
                />
              </div>
            );
          }}
        />

        <UIColumn
          headerTitle={i18n.Status}
          render={data => {
            return (
              <UISelect
                value={data.PaymentStatus}
                codeTable={new CodeTableAction().getPaymentStatus()}
                io="out"
              />
            );
          }}
        />
      </UIDataTable>
    );
  },

  selectSettlementData() {
    const self = this;
    let { commonTreatyArray } = self.state;
    const selectedData = UIDataTable.getSelectedRecord(
      'SettlementDetailData'
    )[0];
    const settlementNo = selectedData.SettlementNo;
    DataContext.put('SettlementNo', settlementNo);
    commonTreatyArray = self.generateSettlementDetailRateArray(
      commonTreatyArray,
      selectedData
    );
    self.state.childMethod(commonTreatyArray);
  },

  getChildMethod(childMethod) {
    this.state.childMethod = childMethod;
  },

  renderSettleDetail(
    settlementDetailDatas,
    settlementDetailRateArray
  ) {
    this.setState({ settlementDetailDatas });
    this.state.childMethod(settlementDetailRateArray);
  },

  generateSettlementDetailRateArray(temparray, data) {
    const array = temparray;
    const self = this;
    const settleSelectedData = data;
    let totalSettleAmount = 0;
    _.forEach(array, (tempsettleRate, index) => {
      const settleRate = tempsettleRate;
      if (
        index > 0 &&
        index < array.length - 1 &&
        settleRate.CessionType != '4'
      ) {
        array[index].SettleAmount =
          Math.round(
            settleSelectedData.SettleAmount * array[index].ShareRate
          ) / 100;
        array[index].SettleCurrency =
          settleSelectedData.SettleCurrency;
        totalSettleAmount += array[index].SettleAmount;
      }
      if (settleRate.CessionType == '4') {
        let treatyCessionAmount = 0;
        const riClaimRecoveries = self.state.RiClaimRecoveries;
        if (riClaimRecoveries) {
          const RiClaimRecoveries =
            riClaimRecoveries[data.SettlementNo];
          _.each(RiClaimRecoveries, RiClaimRecoveriesDetail => {
            if (
              RiClaimRecoveriesDetail.TreatyCode ==
              settleRate.TreatyCode
            ) {
              treatyCessionAmount +=
                RiClaimRecoveriesDetail.RecoveryAmount;
            }
          });
        }
        settleRate.SettleAmount = treatyCessionAmount;
        settleRate.SettleCurrency = settleSelectedData.SettleCurrency;
        totalSettleAmount += settleRate.SettleAmount;
      }
    });
    array[array.length - 1].SettleAmount =
      settleSelectedData.SettleAmount;
    array[array.length - 1].SettleCurrency =
      settleSelectedData.SettleCurrency;
    array[0].SettleAmount =
      array[array.length - 1].SettleAmount - totalSettleAmount;
    array[0].SettleCurrency = settleSelectedData.SettleCurrency;
    return array;
  },
});
export default SettlementDetail;
