import { AjaxUtil } from 'RainbowUI';
import Url from '../constant/FieldinvestigationUrl';

const Q = require('q');

class PropertyService {
  postPromise(url, payload) {
    const deferred = Q.defer();
    const entity = payload || {};

    AjaxUtil.doPost(url, entity, {
      async: true,
      done(data) {
        deferred.resolve(data);
      },
      fail(err) {
        deferred.reject(err);
      },
    });
    return deferred.promise;
  }

  getPromise(url, payload) {
    const deferred = Q.defer();
    const entity = payload || {};
    AjaxUtil.doGet(url, entity, {
      async: true,
      done(data) {
        deferred.resolve(data);
      },
      fail(err) {
        deferred.reject(err);
      },
    });
    return deferred.promise;
  }

  propertySubmit(entity) {
    const url = Url.PropertySubmit;
    return this.postPromise(url, entity);
  }

  propertySave(entity) {
    const url = Url.PropertySave;
    return this.postPromise(url, entity);
  }
}

const PropertyServiceInstance = new PropertyService();
export default PropertyServiceInstance;
