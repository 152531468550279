import { Caller, MessageHelper, AjaxUtil } from 'RainbowUI';
import alt from '../alt';
import Url from '../url/Url';

export default alt.createActions({
  queryAccidents(conditions) {
    let result = {};
    Caller.call(Url.QueryEntityBySolr, conditions).then(
      data => {
        if (data.Total > 0) {
          result = {
            result: data.Results[0].SolrDocs,
            count: data.Results[0].GroupTotalNum,
          };
        }
      },
      err => {
        MessageHelper.error(
          err.statusText,
          null,
          MessageHelper.POSITION_TOP_RIGHT
        );
      }
    );
    return result;
  },
  loadAccident(accidentId) {
    const url = Url.RtaLoadAccident + accidentId;

    let accident = null;
    AjaxUtil.doGet(
      url,
      {},
      {
        async: false,
        done(data) {
          accident = data;
        },
      }
    );
    return accident;
  },
  loadPaPolicy(policyId) {
    const querys = {
      Module: 'Policy',
      PageNo: 1,
      PageSize: 10,
      SortField: 'index_time',
      SortType: 'desc',
      Conditions: {
        PolicyId: policyId,
      },
    };

    const result = this.actions.queryAccidents(querys);
    if (result.count >= 1) {
      return result.result['0'];
    }
    return null;
  },
  loadAccidentWithMatchPolicy(accidentId) {
    const acc = this.actions.loadAccident(accidentId);
    for (let i = 0; i < acc.VehicleList.length; i += 1) {
      const veh = acc.VehicleList[i];
      if (veh.Status !== 4 && veh.PolicyId) {
        const policy = this.actions.loadPaPolicy(veh.PolicyId);
        veh.Policy = policy;
        if (!veh.Policy) {
          veh.Policy = {};
        }
      } else {
        veh.Policy = {};
      }
    }
    return acc;
  },
  saveAccident(accJson) {
    const url = Url.RtaSaveAccident;

    const content = { Content: accJson };
    let accident = null;
    AjaxUtil.doPost(url, content, {
      async: false,
      done(data) {
        accident = data;
      },
      fail() { },
    });
    return accident;
  },
  searchAccidents(condition) {
    const url = Url.RtaLoadAccident + accidentId;

    let accident = null;
    AjaxUtil.doGet(
      url,
      {},
      {
        async: false,
        done(data) {
          accident = data;
        },
      }
    );
    return accident;
  },

  ajaxGetBlob(suffixUrl, callback) {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', suffixUrl, true);
    xhr.setRequestHeader(
      'Authorization',
      `Bearer ${
      localStorage
        .getItem('Authorization')
        .substr(13)
        .split('&')[0]
      }`
    );
    xhr.responseType = 'blob';
    xhr.onreadystatechange = () => {
      if (
        xhr.readyState == XMLHttpRequest.DONE &&
        xhr.status == 200
      ) {
        let fileName = this.getResponseHeader(
          'Content-Disposition'
        ).split('=')[1];
        if (fileName) {
          const nameStr = fileName.split('"');
          if (nameStr && nameStr.length === 3) {
            fileName = nameStr[1];
          }
        }
        const blob = new Blob([this.response], {
          type: this.getResponseHeader('content-type'),
        });
        if (!!window.ActiveXObject || 'ActiveXObject' in window) {
          window.navigator.msSaveBlob(blob, fileName);
        } else {
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
        }
        if (callback) {
          callback();
        }
      }
    };
    xhr.send(null);
  },
  download(accidentId) { },
});
