import { CodeTable, Caller, DataContext } from 'RainbowUI';
import ClaimConstant from '../../common/constant/ClaimConstant';
import alt from '../alt';
import UrlConfig from '../../common/url/Url';

class RiAction {
  loadRiClaimRiskUnit(url) {
    let results = null;
    Caller.call(url, null, {
      method: 'GET',
      async: false,
      block: true,
    }).then(
      data => {
        if (data.Status === 'OK') {
          const model = JSON.parse(data.Model);
          results = model;
        }
      },
      err => {}
    );
    return results;
  }

  getRiskCategoryCache() {
    let codetableList = [];
    if (DataContext.get('RiskCategoryCodetable')) {
      codetableList = DataContext.get('RiskCategoryCodetable');
    } else {
      const url = UrlConfig.getAllRiskCategory;
      const codeTableList = [];
      // TODO RI to be integrated
      // Caller.call(url, null, {
      //   method: 'GET',
      //   async: false,
      //   block: true,
      // }).then(data => {
      //   if (data) {
      //     for (let i = 0; i < data.length; i += 1) {
      //       codetableList.push({
      //         id: data[i].RiskCategoryCode,
      //         text: data[i].RiskCategoryName,
      //       });
      //     }
      //   }
      // });
      // DataContext.put('RiskCategoryCodetable', codetableList);
    }
    return new CodeTable(codetableList);
  }

  getRiskLevelCache() {
    let codetableList = null;
    if (DataContext.get('RiskLevelCodetable')) {
      codetableList = DataContext.get('RiskLevelCodetable');
    } else {
      const url = UrlConfig.getAllRiskLevel;
      codetableList = [];
      // TODO RI to be integrated
      // Caller.call(url, null, {
      //   method: 'GET',
      //   async: false,
      //   block: true,
      // }).then(data => {
      //   if (data) {
      //     for (let i = 0; i < data.length; i += 1) {
      //       const obj = {
      //         id: data[i].RiskCatLevel,
      //         text: data[i].RiskLevelDesc,
      //       };
      //       codetableList.push(obj);
      //     }
      //   }
      // });
      DataContext.put('RiskLevelCodetable', codetableList);
    }
    return new CodeTable(codetableList);
  }

  generateShareRateArray(selectData) {
    if (selectData) {
      /* empty */
    } else {
      return [];
    }
    const commonTreatyArray = [];
    const commonData = selectData;
    const commonRetention = {};
    let totalShareRate = 0;
    let priorityOrder = 0;
    let xolpriorityOrder = 0;

    commonRetention.TreatyCode = 'Retention';
    commonRetention.Priority = 0;
    commonRetention.ShareRate = (
      commonData.NetRetentionShareRate * 100
    ).toFixed(6);
    commonRetention.RiPolicyRiskUnitId =
      commonData.RiPolicyRiskUnitId;
    commonRetention.ClaimId = commonData.ClaimId;
    commonRetention.RiClaimId = commonData.RiClaimId;
    commonRetention.RiskUnitNo = commonData.RiskUnitNo;
    totalShareRate += parseFloat(commonRetention.ShareRate);
    commonTreatyArray.push(commonRetention);
    _.forEach(commonData.RiPolicyTreatyCessions, treaty => {
      if (treaty.CessionType == '4') {
        xolpriorityOrder += 1;
        const xoltreatyDataTmp = {
          TreatyCode: treaty.TreatyCode,
          Priority: xolpriorityOrder,
          ShareRate: null,
          CessionType: treaty.CessionType,
          RiPolicyRiskUnitId: commonData.RiPolicyRiskUnitId,
          ClaimId: commonData.ClaimId,
          RiClaimId: commonData.RiClaimId,
          RiskUnitNo: commonData.RiskUnitNo,
        };

        commonTreatyArray.push(xoltreatyDataTmp);
      }
    });
    _.forEach(commonData.RiPolicyTreatyCessions, treaty => {
      if (treaty.ShareRate && treaty.CessionType != '4') {
        priorityOrder += 1;
        const treatyData = {};
        treatyData.TreatyCode = treaty.TreatyCode;
        treatyData.Priority = priorityOrder;
        treatyData.ShareRate = (treaty.ShareRate * 100).toFixed(6);
        treatyData.RiPolicyRiskUnitId = commonData.RiPolicyRiskUnitId;
        treatyData.ClaimId = commonData.ClaimId;
        treatyData.RiClaimId = commonData.RiClaimId;
        treatyData.RiskUnitNo = commonData.RiskUnitNo;
        treatyData.CessionType = treaty.CessionType;
        totalShareRate += parseFloat(treatyData.ShareRate);
        commonTreatyArray.push(treatyData);
      }
    });
    const riPolicyFacOutPropotionCession =
      commonData.RiPolicyFacOutPropotionCession;

    if (riPolicyFacOutPropotionCession) {
      const treatyData = {};
      treatyData.ShareRate = 0;
      treatyData.TreatyCode = 'Facultative Outwards';
      priorityOrder += 1;
      treatyData.Priority = priorityOrder;
      _.forEach(
        riPolicyFacOutPropotionCession.RiPolicyFacOutReinsurers,
        facoutCession => {
          treatyData.ShareRate += facoutCession.ShareRate * 100;
        }
      );
      treatyData.ShareRate = treatyData.ShareRate.toFixed(6);
      treatyData.RiPolicyRiskUnitId = commonData.RiPolicyRiskUnitId;
      treatyData.ClaimId = commonData.ClaimId;
      treatyData.RiClaimId = commonData.RiClaimId;
      treatyData.RiskUnitNo = commonData.RiskUnitNo;
      treatyData.CessionType = commonData.CessionType;
      totalShareRate += parseFloat(treatyData.ShareRate);
      commonTreatyArray.push(treatyData);
    }
    const commonTotalData = {};
    commonTotalData.TreatyCode = 'Total';
    commonTotalData.ShareRate = totalShareRate.toFixed(6);
    commonTreatyArray.push(commonTotalData);
    return commonTreatyArray;
  }

  generateFinalShareRateArray(array, data, settlementDetailDatas) {
    const self = this;
    if (data) {
      /* empty */
    } else {
      return [];
    }
    const selectedData = data;
    for (let i = 0; i < array.length; i += 1) {
      if (array[i].CessionType == '4') {
        let XOLPaymentAmount = 0;
        _.forEach(settlementDetailDatas, settlementDetailData => {
          const arrays = self.actions.generateSettlementDetailRateArray(
            array,
            settlementDetailData
          );
          _.forEach(arrays, array1 => {
            if (
              array1.CessionType == '4' &&
              array[i].TreatyCode == array1.TreatyCode
            ) {
              if (
                array1.SettleCurrency == selectedData.CurrencyCode
              ) {
                XOLPaymentAmount += array1.SettleAmount;
              } else {
                XOLPaymentAmount += array1.RecoveryAmountTreatyCur;
              }
            }
          });
        });
        if (XOLPaymentAmount === 0) {
          array.splice(i, 1);
          i -= 1;
        }
      }
    }
  }

  generateCurrencyRateData(array, data, settlementDetailData) {
    const self = this;
    if (data) {
      /* empty */
    } else {
      return [];
    }

    const dataArray = [];
    const riPolicyRiskUnits = self.actions.generateSettlementDetailRateArrayByClaim(
      data
    );
    _.each(riPolicyRiskUnits, record => {
      const riskUnitNo = record.RiClaimRiskUnit.RiskUnitNo;
      const riskUnitShareRateTable = self.actions.getTreatySourceListByClaim(
        record,
        settlementDetailData
      );
      const filterRiskUnitShareRateTable = [];
      _.forEach(riskUnitShareRateTable, shareRateTable => {
        if (shareRateTable.ShareRate !== 0) {
          filterRiskUnitShareRateTable.push(shareRateTable);
        }
      });
      const shareRateModel = {};
      shareRateModel.RiskUnitNo = riskUnitNo;
      shareRateModel.RiskUnitShareRateTable = filterRiskUnitShareRateTable;
      dataArray.push(shareRateModel);
    });
    return dataArray;
  }

  generateSettlementDetailRateArrayByClaim(data) {
    let RiClaimId = null;
    let RiskUnitNo = null;
    let ClaimId = null;
    const riPolicyRiskUnitTable = [];
    if (data.RiClaimId) {
      RiClaimId = data.RiClaimId;
    }
    if (data.RiskUnitNo) {
      RiskUnitNo = data.RiskUnitNo;
    }
    if (data.ClaimId) {
      ClaimId = data.ClaimId;
    }
    const ids = `${RiClaimId}:1:${RiskUnitNo}:${ClaimId}`;
    DataContext.put('Ids', `${RiClaimId}:1:${RiskUnitNo}`);
    let results = null;
    const url = UrlConfig.loadQueryRiDetail + ids;
    if (RiClaimId && RiskUnitNo && ClaimId) {
      Caller.call(url, null, {
        method: 'GET',
        async: false,
        block: true,
      }).then(
        data => {
          if (data.Status === 'OK') {
            const model = JSON.parse(data.Model);
            results = model;
          }
        },
        err => {}
      );
    }
    if (results) {
      riPolicyRiskUnitTable.push(results);
    }
    return riPolicyRiskUnitTable;
  }

  generateSettlementDetailRateArray(array) {
    let RiClaimId = null;
    let RiskUnitNo = null;
    let ClaimId = null;
    _.forEach(array, data => {
      if (data.RiClaimId) {
        RiClaimId = data.RiClaimId;
      }
      if (data.RiskUnitNo) {
        RiskUnitNo = data.RiskUnitNo;
      }
      if (data.ClaimId) {
        ClaimId = data.ClaimId;
      }
    });

    const ids = `${RiClaimId}:1:${RiskUnitNo}:${ClaimId}`;
    DataContext.put('Ids', `${RiClaimId}:1:${RiskUnitNo}`);
    let results = null;
    const url = UrlConfig.loadQueryRiDetail + ids;
    if (RiClaimId && RiskUnitNo && ClaimId) {
      Caller.call(url, null, {
        method: 'GET',
        async: false,
        block: true,
      }).then(
        data => {
          if (data.Status === 'OK') {
            const model = JSON.parse(data.Model);
            results = model;
          }
        },
        err => {}
      );
    }
    return results;
  }

  getTreatySourceListByClaim(
    riPolicyRiskUnitModel,
    settlementDetailData
  ) {
    const tableData = [];
    const claimRiskUnit = riPolicyRiskUnitModel.RiClaimRiskUnit;
    const riPolicyRiskUnitHistory =
      riPolicyRiskUnitModel.RiPolicyRiskUnitHistory
        .RiPolicyRiskUnitHistoryContent;
    const isTriggerXOLTreaty =
      riPolicyRiskUnitModel.IsTriggerXOLTreaty;
    const xolTreatyCodeList = [];
    let retentionReserve = 0;
    let retentionReserveTreatyCur = 0;
    let retentionClaimAmount = 0;
    let retentionClaimAmountTreatyCur = 0;
    let retentionShareRate = 0;
    const proportionalTreatyCode =
      riPolicyRiskUnitHistory.TreatyCurrencyCode;
    _.each(
      riPolicyRiskUnitHistory.RiPolicyTreatyCessions,
      treatyCession => {
        if (treatyCession.CessionType == '4') {
          const treatyModel = {};
          treatyModel.TreatyCode = treatyCession.TreatyCode;
          treatyModel.Priority = treatyCession.Priority;
          treatyModel.CessionType = treatyCession.CessionType;
          xolTreatyCodeList.push(treatyModel);
        }
      }
    );

    let xolNum = 0;
    if (isTriggerXOLTreaty === 'Y') {
      _.each(xolTreatyCodeList, tmpxolTreaty => {
        const xolTreaty = tmpxolTreaty;
        if (riPolicyRiskUnitModel.RiClaimRecoveries) {
          _.each(settlementDetailData, settlementData => {
            const recoveries =
              riPolicyRiskUnitModel.RiClaimRecoveries[
                settlementData.SettlementNo
              ];
            if (recoveries) {
              let totalClaimPaidAmount = 0;
              let totalClaimPaidAmountTreatyCur = 0;
              _.each(recoveries, recovery => {
                if (recovery.TreatyCode == xolTreaty.TreatyCode) {
                  totalClaimPaidAmount += recovery.RecoveryAmount;
                  totalClaimPaidAmountTreatyCur +=
                    recovery.RecoveryAmountTreatyCur;
                  xolTreaty.ClaimCurrencyCode =
                    recovery.ClaimCurrencyCode;
                  xolTreaty.TreatyCurrencyCode =
                    recovery.CurrencyCode;
                }
              });
              xolTreaty.PropotionalTreatyCurrencyCode = proportionalTreatyCode;
              xolTreaty.PaymentAmount = Number(
                totalClaimPaidAmount.toFixed(2)
              );
              xolTreaty.PaymentAmountTreatyCur = Number(
                totalClaimPaidAmountTreatyCur.toFixed(2)
              );
              xolTreaty.Reserve = 0;
              xolTreaty.ReserveTreatyCur = 0;
              xolTreaty.TotalAmount = Number(
                totalClaimPaidAmount.toFixed(2)
              );

              const exchangeRateToClaim =
                claimRiskUnit.TotalClaimPaidAmountProportionalTreatyCur /
                claimRiskUnit.TotalClaimPaidAmount;
              xolTreaty.PaymentAmountProptionalTreatyCur = Number(
                (exchangeRateToClaim * totalClaimPaidAmount).toFixed(
                  2
                )
              );
              xolTreaty.TotalAmountTreatyCur =
                xolTreaty.PaymentAmountProptionalTreatyCur;

              retentionClaimAmount = Number(
                (retentionClaimAmount + totalClaimPaidAmount).toFixed(
                  2
                )
              );
              retentionClaimAmountTreatyCur = Number(
                (
                  retentionClaimAmountTreatyCur +
                  xolTreaty.PaymentAmountProptionalTreatyCur
                ).toFixed(2)
              );
            }
          });
          if (xolTreaty.TotalAmount !== 0) {
            tableData.push(xolTreaty);
            xolNum += 1;
          }
        }
      });
    }
    DataContext.put('XOLNum', xolNum);

    let priorityForFac = 0;
    _.each(
      riPolicyRiskUnitHistory.RiPolicyTreatyCessions,
      treatyCession => {
        if (treatyCession.CessionType != '4') {
          const treatyModel = {};
          treatyModel.TreatyCode = treatyCession.TreatyCode;
          treatyModel.ShareRate = treatyCession.ShareRate;
          treatyModel.Priority = treatyCession.Priority;
          treatyModel.Reserve = Number(
            (treatyCession.ShareRate * claimRiskUnit.Reserve).toFixed(
              2
            )
          );
          treatyModel.ReserveTreatyCur = Number(
            (
              treatyCession.ShareRate *
              claimRiskUnit.ReserveProportionalTreatyCur
            ).toFixed(2)
          );
          treatyModel.PaymentAmount = Number(
            (
              treatyCession.ShareRate *
              claimRiskUnit.TotalClaimPaidAmount
            ).toFixed(2)
          );
          treatyModel.PaymentAmountTreatyCur = Number(
            (
              treatyCession.ShareRate *
              claimRiskUnit.TotalClaimPaidAmountProportionalTreatyCur
            ).toFixed(2)
          );
          treatyModel.TotalAmount = Number(
            (treatyModel.Reserve + treatyModel.PaymentAmount).toFixed(
              2
            )
          );
          treatyModel.TotalAmountTreatyCur = Number(
            (
              treatyModel.ReserveTreatyCur +
              treatyModel.PaymentAmountTreatyCur
            ).toFixed(2)
          );
          treatyModel.ClaimCurrencyCode =
            claimRiskUnit.ClaimCurrencyCode;
          treatyModel.TreatyCurrencyCode = proportionalTreatyCode;
          tableData.push(treatyModel);
          priorityForFac += 1;

          retentionReserve = Number(
            (retentionReserve + treatyModel.Reserve).toFixed(2)
          );
          retentionReserveTreatyCur = Number(
            (
              retentionReserveTreatyCur + treatyModel.ReserveTreatyCur
            ).toFixed(2)
          );
          retentionClaimAmount = Number(
            (
              retentionClaimAmount + treatyModel.PaymentAmount
            ).toFixed(2)
          );
          retentionClaimAmountTreatyCur = Number(
            (
              retentionClaimAmountTreatyCur +
              treatyModel.PaymentAmountTreatyCur
            ).toFixed(2)
          );
          retentionShareRate += treatyModel.ShareRate;
        }
      }
    );

    const riPolicyFacOutPropotionCession =
      riPolicyRiskUnitHistory.RiPolicyFacOutPropotionCession;
    if (riPolicyFacOutPropotionCession) {
      if (riPolicyFacOutPropotionCession.ShareRate !== 0) {
        const facTreatyRateModel = {};
        facTreatyRateModel.TreatyCode = 'Facultative Outwards';
        facTreatyRateModel.ShareRate =
          riPolicyFacOutPropotionCession.ShareRate;
        facTreatyRateModel.Priority = priorityForFac + 1;
        facTreatyRateModel.ClaimCurrencyCode =
          claimRiskUnit.ClaimCurrencyCode;
        facTreatyRateModel.TreatyCurrencyCode = proportionalTreatyCode;
        facTreatyRateModel.Reserve = Number(
          (
            facTreatyRateModel.ShareRate * claimRiskUnit.Reserve
          ).toFixed(2)
        );
        facTreatyRateModel.ReserveTreatyCur = Number(
          (
            facTreatyRateModel.ShareRate *
            claimRiskUnit.ReserveProportionalTreatyCur
          ).toFixed(2)
        );
        facTreatyRateModel.PaymentAmount = Number(
          (
            facTreatyRateModel.ShareRate *
            claimRiskUnit.TotalClaimPaidAmount
          ).toFixed(2)
        );
        facTreatyRateModel.PaymentAmountTreatyCur = Number(
          (
            facTreatyRateModel.ShareRate *
            claimRiskUnit.TotalClaimPaidAmountProportionalTreatyCur
          ).toFixed(2)
        );
        facTreatyRateModel.TotalAmount = Number(
          (
            facTreatyRateModel.Reserve +
            facTreatyRateModel.PaymentAmount
          ).toFixed(2)
        );
        facTreatyRateModel.TotalAmountTreatyCur = Number(
          (
            facTreatyRateModel.ReserveTreatyCur +
            facTreatyRateModel.PaymentAmountTreatyCur
          ).toFixed(2)
        );
        tableData.push(facTreatyRateModel);

        retentionReserve = Number(
          (retentionReserve + facTreatyRateModel.Reserve).toFixed(2)
        );
        retentionReserveTreatyCur = Number(
          (
            retentionReserveTreatyCur +
            facTreatyRateModel.ReserveTreatyCur
          ).toFixed(2)
        );
        retentionClaimAmount = Number(
          (
            retentionClaimAmount + facTreatyRateModel.PaymentAmount
          ).toFixed(2)
        );
        retentionClaimAmountTreatyCur = Number(
          (
            retentionClaimAmountTreatyCur +
            facTreatyRateModel.PaymentAmountTreatyCur
          ).toFixed(2)
        );
        retentionShareRate += facTreatyRateModel.ShareRate;
      }
    }
    const retentionData = {};
    retentionData.TreatyCode = 'Retention';
    retentionData.ShareRate = Number(
      (1 - retentionShareRate).toFixed(8)
    );
    retentionData.Priority = 0;
    retentionData.ClaimCurrencyCode = claimRiskUnit.ClaimCurrencyCode;
    retentionData.TreatyCurrencyCode = proportionalTreatyCode;
    retentionData.Reserve = Number(
      (
        Number(claimRiskUnit.Reserve.toFixed(2)) - retentionReserve
      ).toFixed(2)
    );
    retentionData.ReserveTreatyCur = Number(
      (
        Number(
          claimRiskUnit.ReserveProportionalTreatyCur.toFixed(2)
        ) - retentionReserveTreatyCur
      ).toFixed(2)
    );
    retentionData.PaymentAmount = Number(
      (
        Number(claimRiskUnit.TotalClaimPaidAmount.toFixed(2)) -
        retentionClaimAmount
      ).toFixed(2)
    );
    retentionData.PaymentAmountTreatyCur = Number(
      (
        Number(
          claimRiskUnit.TotalClaimPaidAmountProportionalTreatyCur.toFixed(
            2
          )
        ) - retentionClaimAmountTreatyCur
      ).toFixed(2)
    );
    retentionData.TotalAmount = Number(
      (
        Number(retentionData.Reserve.toFixed(2)) +
        retentionData.PaymentAmount
      ).toFixed(2)
    );
    retentionData.TotalAmountTreatyCur = Number(
      (
        Number(retentionData.ReserveTreatyCur.toFixed(2)) +
        retentionData.PaymentAmountTreatyCur
      ).toFixed(2)
    );
    const totalData = {};
    totalData.TreatyCode = null;
    totalData.ShareRate = 1;
    totalData.Priority = 'Total';
    totalData.ClaimCurrencyCode = claimRiskUnit.ClaimCurrencyCode;
    totalData.TreatyCurrencyCode = proportionalTreatyCode;
    totalData.Reserve = Number(claimRiskUnit.Reserve.toFixed(2));
    totalData.ReserveTreatyCur = Number(
      claimRiskUnit.ReserveProportionalTreatyCur.toFixed(2)
    );
    totalData.PaymentAmount = Number(
      claimRiskUnit.TotalClaimPaidAmount.toFixed(2)
    );
    totalData.PaymentAmountTreatyCur = Number(
      claimRiskUnit.TotalClaimPaidAmountProportionalTreatyCur.toFixed(
        2
      )
    );
    totalData.TotalAmount = Number(
      (totalData.Reserve + totalData.PaymentAmount).toFixed(2)
    );
    totalData.TotalAmountTreatyCur = Number(
      (
        totalData.ReserveTreatyCur + totalData.PaymentAmountTreatyCur
      ).toFixed(2)
    );
    tableData.unshift(retentionData);
    tableData.push(totalData);
    return tableData;
  }

  getTreatySourceList(results, ProportionalList) {
    const tableData = [];
    let totalSettledAmount = 0;
    let totalShare = 0;
    let CurrencyCode;

    if (!results) {
      return [];
    }
    const { RiPolicyRiskUnitHistory } = results;
    const { IsTriggerXOLTreaty } = results;
    const claimRiskUnit = results.RiClaimRiskUnit;
    const exchangeRateToClaim =
      claimRiskUnit.TotalClaimPaidAmountProportionalTreatyCur /
      claimRiskUnit.TotalClaimPaidAmount;

    _.each(ProportionalList, source => {
      const settlementShareRateTable = [];

      let settleAmount = source.SettlementAmountPaymentCur;
      totalShare = 1.0;
      totalSettledAmount = source.SettlementAmountPaymentCur;

      if (source.SettlementType === 'SAL,SUB') {
        settleAmount *= -1;
      }
      CurrencyCode = source.ReserveCurrency;
      let priorityOrder = 0;
      let xolpriorityOrder = 0;
      const riPolicyTreatyCessions =
        RiPolicyRiskUnitHistory.RiPolicyRiskUnitHistoryContent
          .RiPolicyTreatyCessions;
      if (!results.RiClaimRecoveries) {
        return;
      }
      const RiClaimRecoveries =
        results.RiClaimRecoveries[source.SettlementNo];

      if (IsTriggerXOLTreaty === 'Y') {
        _.each(riPolicyTreatyCessions, data => {
          if (data.CessionType == '4') {
            let treatyCessionAmount = 0;
            _.each(RiClaimRecoveries, RiClaimRecoveriesDetail => {
              if (
                RiClaimRecoveriesDetail.TreatyCode == data.TreatyCode
              ) {
                treatyCessionAmount +=
                  RiClaimRecoveriesDetail.RecoveryAmount;
              }
            });

            xolpriorityOrder += 1;
            const xoltreatyDataTmp = {
              TreatyCode: data.TreatyCode,
              Priority: xolpriorityOrder,
              ShareRate: null,
              SettledAmount: Number(treatyCessionAmount.toFixed(2)),
              SettledAmountTreatyCur: Number(
                (treatyCessionAmount * exchangeRateToClaim).toFixed(2)
              ),
              CurrencyCode,
            };
            if (xoltreatyDataTmp.SettledAmount !== 0) {
              settlementShareRateTable.push(xoltreatyDataTmp);
            } else {
              xolpriorityOrder -= 1;
            }
          }
        });
      }
      DataContext.put('XOLNum', xolpriorityOrder);

      _.each(riPolicyTreatyCessions, data => {
        if (data.ShareRate && data.CessionType != '4') {
          priorityOrder += 1;
          let treatyCessionAmount = 0;
          let treatyCessionAmountTreatyCur = 0;
          _.each(RiClaimRecoveries, RiClaimRecoveriesDetail => {
            if (
              RiClaimRecoveriesDetail.TreatyCode == data.TreatyCode
            ) {
              treatyCessionAmount +=
                RiClaimRecoveriesDetail.RecoveryAmount;
              treatyCessionAmountTreatyCur +=
                RiClaimRecoveriesDetail.RecoveryAmountTreatyCur;
            }
          });
          const treatyDataTmp = {
            TreatyCode: data.TreatyCode,
            Priority: priorityOrder,
            ShareRate: !data.ShareRate ? 0 : data.ShareRate,
            SettledAmount: Number(treatyCessionAmount.toFixed(2)),
            SettledAmountTreatyCur: Number(
              treatyCessionAmountTreatyCur.toFixed(2)
            ),
            CurrencyCode,
          };
          settlementShareRateTable.push(treatyDataTmp);
        }
      });

      const riPolicyFacOutPropotionCession =
        RiPolicyRiskUnitHistory.RiPolicyRiskUnitHistoryContent
          .RiPolicyFacOutPropotionCession;
      if (riPolicyFacOutPropotionCession) {
        if (riPolicyFacOutPropotionCession.ShareRate) {
          priorityOrder += 1;

          let facCessionAmount = 0;
          _.each(RiClaimRecoveries, RiClaimRecoveriesDetail => {
            if (RiClaimRecoveriesDetail.IsFacType) {
              facCessionAmount +=
                RiClaimRecoveriesDetail.RecoveryAmount;
            }
          });
          const facultativeOutwardsData = {
            TreatyCode: 'Facultative Outwards',
            Priority: priorityOrder,
            ShareRate: Number(
              riPolicyFacOutPropotionCession.ShareRate
            ),
            SettledAmount: Number(facCessionAmount.toFixed(2)),
            SettledAmountTreatyCur: Number(
              (facCessionAmount * exchangeRateToClaim).toFixed(2)
            ),
            CurrencyCode,
          };
          settlementShareRateTable.push(facultativeOutwardsData);
        }
      }

      let TreatySumAmount = 0;
      let TreatySumAmountTreatyCur = 0;
      _.each(settlementShareRateTable, data => {
        TreatySumAmount += data.SettledAmount;
        TreatySumAmountTreatyCur += data.SettledAmountTreatyCur;
      });
      let retentionSettledAmount = 0;
      let retentionSettledAmountTreatyCur = 0;
      const settleAmountTreatyCur =
        settleAmount * exchangeRateToClaim;
      if (TreatySumAmount < 0) {
        retentionSettledAmount = Number(
          (-settleAmount - TreatySumAmount).toFixed(2)
        );
        retentionSettledAmountTreatyCur = Number(
          (-settleAmountTreatyCur - TreatySumAmountTreatyCur).toFixed(
            2
          )
        );
      } else {
        retentionSettledAmount = Number(
          (settleAmount - TreatySumAmount).toFixed(2)
        );
        retentionSettledAmountTreatyCur = Number(
          (settleAmountTreatyCur - TreatySumAmountTreatyCur).toFixed(
            2
          )
        );
      }

      let TreatySumShareRate = 0;
      _.each(settlementShareRateTable, data => {
        TreatySumShareRate += data.ShareRate;
      });
      const retentionShareRate = Number(
        (totalShare - TreatySumShareRate).toFixed(8)
      );
      const retentionData = {
        TreatyCode: 'Retention',
        Priority: 0,
        ShareRate: retentionShareRate,
        SettledAmount: retentionSettledAmount,
        SettledAmountTreatyCur: retentionSettledAmountTreatyCur,
        CurrencyCode,
      };
      settlementShareRateTable.unshift(retentionData);
      const totalData = {
        Priority: 'Total',
        TreatyCode: null,
        ShareRate: totalShare,
        SettledAmount: settleAmount.toFixed(2),
        SettledAmountTreatyCur: settleAmountTreatyCur.toFixed(2),
        CurrencyCode,
      };
      settlementShareRateTable.push(totalData);
      const settlementObject = {};
      settlementObject.SettlementNo = source.SettlementNo;
      settlementObject.SettlementShareRateTable = settlementShareRateTable;
      tableData.push(settlementObject);
    });
    return tableData;
  }

  generateSettlementDetailDatas(polCoverageIdsArray, caseId) {
    const settlementDetailDatas = [];
    const finalSettlementDetailDatas = [];
    const url = UrlConfig.loadSettlementInfoByCaseId + caseId;
    let results = [];
    Caller.call(url, null, {
      method: 'GET',
      async: false,
      block: true,
    }).then(
      data => {
        results = data;
      },
      err => {}
    );
    if (results.length > 0) {
      const settlementNoArrs = [];
      _.forEach(results, result => {
        _.forEach(polCoverageIdsArray, polCoverageId => {
          if (polCoverageId == result.PolCoverageId) {
            if (
              result.SettlementType === 'LOS' &&
              result.SettlementAmountPaymentCur < 0
            ) {
              return result;
            }
            if (
              result.SettlementType === 'SAL,SUB' &&
              result.SettlementAmountPaymentCur > 0
            ) {
              return result;
            }
            let isexistNo = false;
            _.forEach(settlementNoArrs, settlementNoArr => {
              if (settlementNoArr == result.SettlementNo) {
                isexistNo = true;
              }
            });
            if (!isexistNo) {
              settlementNoArrs.push(result.SettlementNo);
            }
            const settlementDetailData = {};
            settlementDetailData.SettlementType =
              result.SettlementType;
            settlementDetailData.SettlementNo = result.SettlementNo;
            settlementDetailData.TransactionDate =
              result.TransactionDate;
            settlementDetailData.PayFinal = result.IsFinal;
            if (result.CoinLeaderFlag === 'N') {
              settlementDetailData.SettleAmount =
                result.SettlementAmount;
              settlementDetailData.SettlementAmountPaymentCur =
                result.SettlementAmountPaymentCur;
            } else {
              settlementDetailData.SettleAmount =
                result.SettlementAmount * result.CoinShareRate;
              settlementDetailData.SettlementAmountPaymentCur =
                result.SettlementAmountPaymentCur *
                result.CoinShareRate;
            }
            settlementDetailData.PaymentStatus = result.Status;
            settlementDetailData.SettleCurrency =
              result.PaymentCurrencyCode;
            settlementDetailData.ReserveCurrency =
              result.PaymentCurrencyCode;
            settlementDetailDatas.push(settlementDetailData);
          }
        });
      });
      _.forEach(settlementNoArrs, arr => {
        let allFinal = true;
        let allPaid = true;
        let cancel = false;
        const finalSettlementDetailData = {};
        finalSettlementDetailData.SettlementNo = arr;
        let finalSettlementAmount = 0;
        let finalSettlementAmountPayment = 0;
        _.forEach(settlementDetailDatas, detailData => {
          if (detailData.SettlementNo == arr) {
            finalSettlementAmount += detailData.SettleAmount;
            finalSettlementAmountPayment +=
              detailData.SettlementAmountPaymentCur;
            finalSettlementDetailData.SettlementType =
              detailData.SettlementType;
            finalSettlementDetailData.TransactionDate =
              detailData.TransactionDate;
            finalSettlementDetailData.SettleCurrency =
              detailData.SettleCurrency;
            finalSettlementDetailData.ReserveCurrency =
              detailData.ReserveCurrency;
            if (
              detailData.PayFinal !==
              ClaimConstant.SettlementItemFinal
            ) {
              allFinal = false;
            }
            if (
              detailData.PaymentStatus !=
              ClaimConstant.SettlementPayeePaid
            ) {
              allPaid = false;
            }
            if (
              detailData.PaymentStatus ==
              ClaimConstant.SettlementPayeeCancel
            ) {
              cancel = true;
            }
          }
        });
        if (allPaid != null && allPaid !== '') {
          finalSettlementDetailData.PaymentStatus =
            ClaimConstant.SettlementPayeePaid;
        } else if (cancel) {
          finalSettlementDetailData.PaymentStatus =
            ClaimConstant.SettlementPayeeCancel;
        } else {
          finalSettlementDetailData.PaymentStatus =
            ClaimConstant.SettlementPayeePending;
        }
        if (allFinal) {
          finalSettlementDetailData.PayFinal =
            ClaimConstant.SettlementItemFinal;
        } else {
          finalSettlementDetailData.PayFinal =
            ClaimConstant.SettlementItemPartial;
        }
        if (finalSettlementAmount < 0) {
          finalSettlementAmount = -finalSettlementAmount;
        }
        finalSettlementDetailData.SettleAmount = finalSettlementAmount;
        finalSettlementDetailData.SettlementAmountPaymentCur = finalSettlementAmountPayment;
        const exchangeRateToClaim = DataContext.get(
          'ExchangeRateToClaim'
        );
        finalSettlementDetailData.SettlementAmountTreatyCur = Number(
          (
            finalSettlementAmountPayment * exchangeRateToClaim
          ).toFixed(2)
        );
        finalSettlementDetailDatas.push(finalSettlementDetailData);
      });
    }
    return finalSettlementDetailDatas;
  }

  generateReinsuranceDetail(tmpReinsuranceDetail, claimInfo) {
    const ReinsuranceDetail = tmpReinsuranceDetail;
    const searchConditions = { Conditions: {} };
    let policyNo = claimInfo.PolicyEntity.PolicyNo;
    if (
      claimInfo.PolicyEntity.GroupFlag ==
      ClaimConstant.GroupPolicyType
    ) {
      policyNo = claimInfo.PolicyEntity.MasterPolicyNo;
    }

    searchConditions.Conditions.CedingPolicyNo = policyNo;
    searchConditions.Conditions.CessionBound = null;
    searchConditions.Conditions.CedingInwardPolicyNo = null;
    searchConditions.Conditions.CedingQuotationNo = null;
    searchConditions.Conditions.CedingEndorsementNo = null;
    searchConditions.Conditions.ClaimNo = claimInfo.ClaimNo;
    searchConditions.Conditions.VehicleNo = null;
    searchConditions.Conditions.ProductId = null;
    searchConditions.Conditions.ReinsurerId = null;
    searchConditions.Conditions.ReinsurerBrokerId = null;
    searchConditions.Conditions.CedingPolicyHolderName = null;
    searchConditions.Conditions.CedingPolicyEffectiveDateFrom = null;
    searchConditions.Conditions.CedingPolicyEffectiveDateTo = null;
    searchConditions.Conditions.CedingPolicyExpiryDateFrom = null;
    searchConditions.Conditions.CedingPolicyExpiryDateTo = null;
    searchConditions.PageNo = 0;
    searchConditions.PageSize = 10;
    searchConditions.SortField = 'effectiveDate';
    searchConditions.SortType = 'desc';
    const requestData = { Content: searchConditions };
    const url = UrlConfig.queryRiPolicy;
    const riPolicyResults = [];
    // TODO ri to be integrated
    // Caller.call(url, requestData, {
    //   method: 'POST',
    //   async: false,
    //   block: true,
    // }).then(data => {
    //   if (data.Status === 'OK') {
    //     const model = JSON.parse(data.Model);
    //     riPolicyResults = model.Records;
    //   }
    // });
    let id = 0;
    const DateOfLoss = new Date(Date.parse(claimInfo.AccidentTime));
    if (riPolicyResults.length > 0) {
      id = riPolicyResults[0].RiPolicyId;
      const url = `${UrlConfig.loadRiPolicyForQuery +
        id}:0:${policyNo}?null`;
      let results = {};
      Caller.call(url, null, {
        method: 'GET',
        async: false,
        block: true,
      }).then(
        data => {
          if (data.Status === 'OK') {
            const model = JSON.parse(data.Model);
            results = model;
          }
        },
        err => {}
      );

      ReinsuranceDetail.RiskUnitModel =
        results.RiPolicyRiskUnitListContent;
      let riPolicyHisId = null;
      let result = null;
      results.PolicyHistoryIdContent.Records.sort((a, b) => {
        return a.CedingEndorsementId - b.CedingEndorsementId;
      });
      if (results.PolicyHistoryIdContent.TotalCount >= 2) {
        for (
          let i = 1;
          i < results.PolicyHistoryIdContent.TotalCount;
          i += 1
        ) {
          const endoEffDeta = new Date(
            Date.parse(
              results.PolicyHistoryIdContent.Records[i]
                .CedingEndorsementEffectiveDate
            )
          );
          if (DateOfLoss < endoEffDeta) {
            riPolicyHisId =
              results.PolicyHistoryIdContent.Records[i - 1]
                .RiPolicyId;
            id = riPolicyHisId;
            const url = `${UrlConfig.loadRiPolicyForQuery +
              id}:1:${policyNo}?null`;
            Caller.call(url, null, {
              method: 'GET',
              async: false,
              block: true,
            }).then(
              data => {
                if (data.Status === 'OK') {
                  const model = JSON.parse(data.Model);
                  result = model;
                }
              },
              err => {}
            );
            break;
          }
          if (
            DateOfLoss >
            new Date(
              Date.parse(
                results.PolicyHistoryIdContent.Records[
                  results.PolicyHistoryIdContent.TotalCount - 1
                ].CedingEndorsementEffectiveDate
              )
            )
          ) {
            break;
          }
        }
      }

      ReinsuranceDetail.RiPolicyHistoryId = id;
      if (result) {
        ReinsuranceDetail.RiskUnitModel =
          result.RiPolicyRiskUnitListContent;
      }
      if (ReinsuranceDetail.RiskUnitModel) {
        const data = ReinsuranceDetail.RiskUnitModel;
        for (let i = 0; i < data.length; i += 1) {
          const model = data[i];
        }
      }
      ReinsuranceDetail.ClaimInfoModel = results.ClaimContent;
      if (
        results.RiPolicyRiskUnitListContent &&
        results.RiPolicyRiskUnitListContent[0]
      ) {
        ReinsuranceDetail.RiPolicyRiskUnitId =
          results.RiPolicyRiskUnitListContent[0].RiPolicyRiskUnitId;
      }
    }
  }

  isInteger(obj) {
    return Math.floor(obj) == obj;
  }

  toInteger(floatNum) {
    const ret = { times: 1, num: 0 };
    if (this.actions.isInteger(floatNum)) {
      ret.num = floatNum;
      return ret;
    }
    const strfi = `${floatNum}`;
    const dotPos = strfi.indexOf('.');
    const len = strfi.substr(dotPos + 1).length;
    const times = Math.pow(10, len);
    const intNum = parseInt(floatNum * times + 0.5, 10);
    ret.times = times;
    ret.num = intNum;
    return ret;
  }

  operation(a, b, op) {
    const o1 = this.actions.toInteger(a);
    const o2 = this.actions.toInteger(b);
    const n1 = o1.num;
    const n2 = o2.num;
    const t1 = o1.times;
    const t2 = o2.times;
    const max = t1 > t2 ? t1 : t2;
    let result = null;
    switch (op) {
      case 'add':
        if (t1 == t2) {
          result = n1 + n2;
        } else if (t1 > t2) {
          result = n1 + n2 * (t1 / t2);
        } else {
          result = n1 * (t2 / t1) + n2;
        }
        return result / max;
      case 'subtract':
        if (t1 == t2) {
          result = n1 - n2;
        } else if (t1 > t2) {
          result = n1 - n2 * (t1 / t2);
        } else {
          result = n1 * (t2 / t1) - n2;
        }
        return result / max;
      case 'multiply':
        result = (n1 * n2) / (t1 * t2);
        return result;
      case 'divide':
        result = (n1 / n2) * (t2 / t1);
        return result;
      default:
    }
  }
}
export default alt.createActions(RiAction);
