import { MessageHelper } from 'RainbowUI';
import InvestigationService from '../service/InvestigationService';

class InvestigationAction {
  getClosedTasks(objectId) {
    let result = null;
    if (objectId) {
      InvestigationService.getClosedTasks(objectId).then(
        data => {
          result = data.Model;
        },
        data => {
          MessageHelper.error(data.Messages[0].Message, '');
        }
      );
    }
    return result;
  }
}

const InvestigationActionInstance = new InvestigationAction();
export default InvestigationActionInstance;
