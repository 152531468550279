/* eslint-disable prettier/prettier */
import { UIPanel } from 'RainbowUI';
import ClaimCommonExt from 'ClaimCommonExt';

const SubClaimTabs = require('./SubClaimTabs');
const CloseReserveDialog = require('../../../reserve/component/CloseReserveDialog');
const ClaimCstUI = require('../../constant/ClaimCstUI');

const SubclaimContent = React.createClass({
  render() {
    const {
      id,
      content,
      objectVo,
      authMap,
      subclaimLink,
      clickModifyHistory,
      showReserve,
      claimData,
      dtClaimData,
      subclaimTabs,
      subclaimTabAction,
      taskInstanceInfo,
    } = this.props;
    content.props.subclaimIndex = id;
    content.ref = `subclaim_ref${id}`;
    const isMotor = content.props.data.IsMotor;
    const HandlingLinks = ClaimCommonExt.getHandlingLinks();
    return (
      <div>
        <UIPanel
          panelTitle={i18n.ClaimCommon.SubclaimInfo}
          id={`subclaimPanel_00000000${id}`}
          toggleable="true"
          panelHeader={(
            <HandlingLinks
              authMap={authMap}
              links={subclaimLink}
              level={ClaimCstUI.SUBCLAIM_LEVEL}
              subclaimIndex={id}
              relateId={objectVo.ObjectId}
              relateStatusCode={objectVo.StatusCode}
              reserveStatus={objectVo.ReserveStatus}
              reserveCloseAuth={objectVo.ReserveCloseAuth}
              clickModifyHistory={clickModifyHistory}
              isMotor={isMotor}
              modifySubClaimInfoAuth={objectVo.ModifySubClaimInfoAuth}
              showReserve={showReserve}
              parentComponent={this}
            />
          )}
        >
          {content}
        </UIPanel>
        <CloseReserveDialog
          ref="CloseReserveDialog_ref"
          relateId={objectVo.ObjectId}
        />
        <SubClaimTabs
          claimData={claimData}
          dtClaimData={dtClaimData}
          subclaimTabs={subclaimTabs}
          index={id}
          subclaimTabAction={subclaimTabAction}
          taskInstanceInfo={taskInstanceInfo}
          claimEntity={content.props.data}
          objectId={objectVo.ObjectId}
          subclaimStatusCode={objectVo.StatusCode}
          showReserve={showReserve}
        />
      </div>
    );
  },
});
export default SubclaimContent;
