import { UIDialog, MessageHelper } from 'RainbowUI';
import alt from '../alt';

const ReserveService = require('../service/ReserveService');
const ReserveAction = require('./ReserveAction');
const ClaimHandlingAction = require('../../common/action/ClaimHandlingAction');

class OpenReserveAction {
  showOpenReserve(event) {
    const openReserve = event.getParameter('openReserve');
    const caseId = sessionStorage.getItem('claim_caseId');
    ReserveService.showOpenReserve(openReserve.itemId, caseId).then(
      data => {
        if (data.Status === 'OK') {
          this.dispatch(data.Model);
          UIDialog.show(`openReserveDialog_${openReserve.objectId}`);
        } else {
          MessageHelper.error(data.Messages[0].Message, '');
        }
      }
    );
  }

  saveOpenReserve(openReserveVO, objectId) {
    ReserveService.saveOpenReserve(openReserveVO).then(data => {
      if (data.Status === 'OK') {
        ReserveAction.initReserve(objectId);
        ClaimHandlingAction.changeStatus();
        MessageHelper.success(
          '',
          i18n.ClaimReserve.OpenReserveSuccess
        );
        UIDialog.hide(`openReserveDialog_${objectId}`);
      } else {
        MessageHelper.error(data.Messages[0].Message, '');
      }
    });
  }
}
export default alt.createActions(OpenReserveAction);
