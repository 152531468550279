import {
  MessageHelper,
  UIParam,
  UISelect,
  UILink,
  UIColumn,
  UIDataTable,
  UIDateTimePicker,
  UIPanel,
  DataContext,
  UIPercent,
} from 'RainbowUI';
import DefinedCodeTable from '../codetable/CodeTable';
import PartyShareholderDialogReadOnly from './readonly/views/PartyShareholderDialogReadOnly';
import PartyShareholderDialog from './PartyShareholderDialog';
import PartyAction from '../actions/PartyAction';

const PartyShareholderInfo = React.createClass({
  getInitialState() {
    let { party } = this.props;
    if (DataContext.get('Party')) {
      party = DataContext.get('Party');
    }
    let partyShareholderList = party.PartyShareHolderList;
    if (!partyShareholderList) {
      partyShareholderList = [];
    }
    _.forEach(partyShareholderList, (tempPartyShareholder, index) => {
      const partyShareholder = tempPartyShareholder;
      partyShareholder.DefinedIndex = index + 1;
    });
    const totalNum = partyShareholderList.length;
    const categroyCodeTable = DefinedCodeTable.getCategroyCodeTable();
    const nationalityCodeTable = DefinedCodeTable.getCountry();
    return {
      party,
      partyShareholderList,
      TotalNum: totalNum,
      categroyCodeTable,
      nationalityCodeTable,
    };
  },
  createHandler() {
    const definedIndex = this.state.TotalNum + 1;
    const createPartyShareholder = {
      '@type': 'PartyShareHolder-PartyShareHolder',
      DefinedIndex: definedIndex,
    };
    this.refs.partyShareholderDialog.showDialog(
      createPartyShareholder
    );
  },

  editHandler() {
    const selectData = UIDataTable.getSelectedRecord(
      'partyIndShareholderInfo'
    );
    if (!selectData.length) {
      MessageHelper.warning(
        i18n.PartyMsg.WarnMsg.Warn_0005,
        i18n.PartyMsg.WarnMsg.Warn_0003,
        MessageHelper.POSITION_TOP_RIGHT
      );
      return;
    }
    const partyShareholder = selectData[0];
    this.refs.partyShareholderDialog.showDialog(partyShareholder);
  },

  viewHandler(event) {
    const partyShareholder = event.getParameter('data');
    this.refs.partyShareholderDialogReadOnly.showDialog(
      partyShareholder
    );
  },

  savePartyShareholder(partyShareholder) {
    const { party } = this.state;
    const partyAction = new PartyAction();
    const existsParty = partyAction.loadPartyByPartyId(
      this.state.party.PartyId
    );
    const { PartyHistoryList } = existsParty;
    if (partyShareholder['@pk']) {
      const newPartyHistory = {
        '@type': 'PartyHistory-PartyHistory',
        BusinessObjectId: 75158352,
        HistoryType: 19,
      };
      PartyHistoryList.push(newPartyHistory);
    } else {
      const newPartyHistory = {
        '@type': 'PartyHistory-PartyHistory',
        BusinessObjectId: 75158352,
        HistoryType: 18,
      };
      PartyHistoryList.push(newPartyHistory);
    }
    party.PartyHistoryList = PartyHistoryList;
    const { partyShareholderList } = this.state;
    const definedIndex = partyShareholder.DefinedIndex;
    for (let idx = 0; idx < partyShareholderList.length; idx += 1) {
      if (partyShareholderList[idx].DefinedIndex == definedIndex) {
        partyShareholderList.splice(idx, 1);
        break;
      }
    }
    partyShareholderList.push(partyShareholder);
    party.PartyShareHolderList = partyShareholderList;
    party.PartyAccountList = existsParty.PartyAccountList;
    party.PartyContactList = existsParty.PartyContactList;
    party.AddressVoList = existsParty.AddressVoList;
    party.PartyAdditionalList = existsParty.PartyAdditionalList;
    let result = this.props.submitInfoHandler(party, 8);
    let flag;
    if (!(result === 'fail')) {
      _.forEach(
        result.PartyShareHolderList,
        (tempPartyShareholder, index) => {
          const partyShareholder = tempPartyShareholder;
          partyShareholder.DefinedIndex = index + 1;
        }
      );
      const resultList = result.PartyShareHolderList;
      this.setState({
        party: result,
        partyShareholderList: resultList,
        TotalNum: partyShareholderList.length + 1,
      });
      flag = true;
    } else {
      const partyAction = new PartyAction();
      result = partyAction.loadPartyByPartyId(party.PartyId);
      _.forEach(
        result.PartyShareHolderList,
        (tempPartyShareholder, index) => {
          const partyShareholder = tempPartyShareholder;
          partyShareholder.DefinedIndex = index + 1;
        }
      );
      this.setState({
        party: result,
        partyShareholderList: result.PartyShareHolderList,
        TotalNum: result.PartyShareHolderList.length + 1,
      });
      flag = false;
    }
    return flag ? 'success' : 'fail';
  },

  render() {
    return (
      <div>
        <UIPanel panelTitle={i18n.ShareholderDetail}>
          {this.renderDataTable()}
        </UIPanel>
        <PartyShareholderDialog
          ref="partyShareholderDialog"
          submitHandler={this.savePartyShareholder}
        />
        <PartyShareholderDialogReadOnly ref="partyShareholderDialogReadOnly" />
      </div>
    );
  },

  renderDataTable() {
    const {
      nationalityCodeTable,
      partyShareholderList,
      categroyCodeTable,
    } = this.state;
    return (
      <UIDataTable
        id="partyIndShareholderInfo"
        value={partyShareholderList}
        functions={this.buildTableFunction()}
        indexable="false"
      >
        <UIColumn selectionMode="single" />
        <UIColumn
          headerTitle={i18n.ShareholderName}
          value="ShareholderName"
          render={data => {
            return (
              <div>
                <UILink
                  value={data.ShareholderName}
                  enabled="true"
                  disabled="false"
                  onClick={this.viewHandler}
                >
                  <UIParam name="data" value={data} />
                </UILink>
              </div>
            );
          }}
        />
        <UIColumn
          headerTitle={i18n.ShareholderCategory}
          value="ShareholderCategory"
        >
          <UISelect codeTable={categroyCodeTable} io="out" />
        </UIColumn>
        <UIColumn
          headerTitle={i18n.ShareholderPercentage}
          value="ShareholderPercentage"
        >
          <UIPercent io="out" />
        </UIColumn>
        <UIColumn
          headerTitle={i18n.IDNumberOrCompanyRegNumber}
          value="IdNumberOrCompanyRegNumber"
        />
        <UIColumn headerTitle={i18n.BirthDay} value="DateOfBirth">
          <UIDateTimePicker format="MM/DD/YYYY" io="out" />
        </UIColumn>
        <UIColumn
          headerTitle={i18n.CountryOfIncorporation}
          value="Country"
        >
          <UISelect codeTable={nationalityCodeTable} io="out" />
        </UIColumn>
      </UIDataTable>
    );
  },

  buildTableFunction() {
    const _self = this;
    const { isQuery } = this.props;
    return (
      <div>
        <UILink
          icon="fa fa-plus"
          size="lg"
          iconClass="add_iconbtn"
          tooltip={i18n.add}
          visibled={!isQuery}
          onClick={_self.createHandler}
          validationGroup="CustomerOrgAddress"
          causeValidation="true"
        />
        <UILink
          icon="fa fa-pencil"
          size="lg"
          iconClass="edit_iconbtn"
          tooltip={i18n.edit}
          visibled={!isQuery}
          onClick={_self.editHandler}
        />
      </div>
    );
  },
});

export default PartyShareholderInfo;
