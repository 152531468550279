import {
  UIBox,
  UIButton,
  UIConfirmDialog,
  UIDialogFooter,
  UIDialog,
  MessageHelper,
} from 'RainbowUI';
import PolicyProductInfo from './PolicyProductInfo';
import PolicyDetail from './PolicyDetail';

const PolicyAction = require('../../action/PolicyAction');

const PolicyDetailDialog = React.createClass({
  getInitialState() {
    const ip = window.location.href;
    const { hash } = window.location;
    return {
      url: ip.replace(hash, '#/policy/detail?policyNo='),
      policyEntity: {},
      policyProductRisk: {},
      retrieveInfo: {},
    };
  },

  confirmButton() {
    UIDialog.hide('confirmDialog0_policy');
    UIDialog.show('policyDetail_dialog');
  },
  showDialog(retrieveInfo, policyInfo) {

    const policy = {
      '@type': 'ClaimPolicy-ClaimPolicy',
    };
    policy.EffDate = policyInfo.EffDate;
    policy.ExpDate = policyInfo.ExpDate;
    policy.RetroactiveDate = policyInfo.RetroactiveDate;
    policy.ExtendReportingDate = policyInfo.ExtendReporingDate;
    policy.MaintenanceDate = policyInfo.MaintenanceDate;
    policy.PolicyStatus = policyInfo.PolicyStatus;
    policy.PolicyNo = retrieveInfo.PolicyNo;
    policy.CaseId = retrieveInfo.CaseId;
    const pass = PolicyAction.checkPolicy(retrieveInfo, policy);

    this.setState({
      retrieveInfo,
      policyInfo,
    });
    if (pass === 'WARN') {
      UIDialog.show('confirmDialog0_policy');
    }
    if (pass === 'OK') {
      UIDialog.show('policyDetail_dialog');
    }
  },
  showDialogInfo(retrieveInfo, policyProductRisk, ifExist) {

    this.setState({
      retrieveInfo,
      policyProductRisk,
    });

    // checkPolicy
    const policy = {
      '@type': 'ClaimPolicy-ClaimPolicy',
    };
    if (
      policyProductRisk &&
      policyProductRisk !== 'undefined' &&
      policyProductRisk[0]
    ) {
      policy.EffDate = policyProductRisk[0].EffDate;
      policy.ExpDate = policyProductRisk[0].ExpDate;
      policy.RetroactiveDate = policyProductRisk[0].RetroactiveDate;
      policy.ExtendReportingDate =
        policyProductRisk[0].ExtendReporingDate;
      policy.MaintenanceDate = policyProductRisk[0].MaintenanceDate;
      policy.PolicyStatus = policyProductRisk[0].PolicyStatus;
      policy.PolicyNo = retrieveInfo.PolicyNo;
      policy.CaseId = retrieveInfo.CaseId;
      const pass = PolicyAction.checkPolicy(
        retrieveInfo,
        policy,
        policyProductRisk[0].InsuredList
      );
      if (pass === 'WARN') {
        UIDialog.show('confirmDialog0_policy');
      }
      if (pass === 'OK') {
        UIDialog.show('policyDetail_dialog');
      }
    } else if (ifExist) {
      //  mock  policy
      UIDialog.show('policyDetail_dialog');
    }
  },

  copyPolicy() {
    for (
      let i = 0;
      i < this.state.policyEntity.InsuredList.length;
      i += 1
    ) {
      for (
        let j = 0;
        j <
        this.state.policyEntity.InsuredList[i].CoverageList.length;
        j += 1
      ) {
        delete this.state.policyEntity.InsuredList[i].CoverageList[j]
          .dataIndex;
      }
      delete this.state.policyEntity.InsuredList[i].dataIndex;
    }
    this.props.parentComponent.updatePolicyInfo(
      this.state.policyEntity
    );
    UIDialog.hide('policyDetail_dialog');
  },
  continueCopyPolicy() {
    const { policyInfo, retrieveInfo } = this.state;
    for (let i = 0; i < policyInfo.InsuredList.length; i++) {
      delete policyInfo.InsuredList[i].dataIndex;
    }
    if (env.isDummyPolicy) {
      policyInfo.AccidentTime = retrieveInfo.AccidentTime;
      policyInfo.NoticeTime = retrieveInfo.NoticeTime;
    }
    this.props.parentComponent.updatePolicyInfo(policyInfo);
    UIDialog.hide('policyDetail_dialog');



    //Trust logic
    // const ProductRisk = this.refs.PolicyProductInfo.getProductRisk();
    // if (!(ProductRisk.length && ProductRisk[0].InsuredList.length)) {
    //   MessageHelper.error(i18n.ClaimCommon.NoRiskSelected);
    //   return;
    // }
    // const policyEntity = PolicyAction.copyPolicyByProduct(
    //   this.state.retrieveInfo,
    //   ProductRisk
    // );
    // if (policyEntity) {
    //   this.setState({
    //     policyEntity,
    //   });
    //   this.props.parentComponent.updatePolicyInfo(policyEntity);
    //   UIDialog.hide('policyDetail_dialog');
    // } else {
    //   MessageHelper.error(i18n.ClaimCommon.PolicyRetrieveFail);
    // }
  },
  iFrameHeight() {
    $('#iframepage').attr('height', '800px');
  },
  getParentComponent() {
    return this;
  },

  render() {
    return (
      <div>
        <UIConfirmDialog
          id="confirmDialog0_policyValidation"
          title={i18n.CallbackDialog}
          message={i18n.ClaimCommon.RENotice}
          confirmText={i18n.OK}
          cancelText={i18n.Cancel}
          onConfirm={this.confirmButton}
        />
        <UIDialog
          id="confirmDialog0_policy"
          title={i18n.CallbackDialog}
          width="500px"
          height="auto !important"
        >
          <div className="message">
            <span
              className="fa fa-exclamation-circle fa-3x"
              style={{
                color: '#ff9800',
              }}
            />
            <span
              style={{
                verticalAlign: 'top',
                paddingLeft: '10px',
              }}
            >
              {i18n.ClaimCommon.RENotice}
            </span>
          </div>
          <UIDialogFooter>
            <UIButton
              value={i18n.ClaimCommon.Yes}
              onClick={this.confirmButton}
              styleClass="success"
              icon="fa fa-check"
            />
            <UIButton
              value={i18n.ClaimCommon.Cancel}
              onClick={this.cancel}
              styleClass="danger"
              icon="fa fa-ban"
            />
          </UIDialogFooter>
        </UIDialog>
        <UIDialog
          id="policyDetail_dialog"
          title={i18n.ClaimCommon.PolicyInfo}
          width="95%"
        >
          {this.renderPolicyDetail()}
          <UIBox direction="center">
            <UIButton
              value={i18n.ClaimCommon.Continue}
              validationGroup="retrieve_policy_info"
              causeValidation="true"
              onClick={this.continueCopyPolicy}
            />
            <UIButton
              value={i18n.ClaimCommon.Cancel}
              onClick={this.cancel}
            />
          </UIBox>
        </UIDialog>
      </div>
    );
  },
  renderPolicyDetail() {
    const { retrieveInfo, policyProductRisk } = this.state;
    return (
      <PolicyDetail
        policyInfo={this.state.policyInfo}
        retrieveInfo={retrieveInfo}
      />
    );

    //Trust logic
    let policyNo = retrieveInfo.PolicyNo;
    if (policyNo) {
      policyNo = policyNo.trim();
    }
    const token_id = localStorage.getItem('Authorization');
    const policyUrl = `${env.PA_HOME_URL}/#/workCenter?policyId=-9999&transactionCode=query&transactionType=claim&policyNo=${policyNo}&${token_id}`;
    return (
      <div>
        <iframe
          title="iframepage"
          src={policyUrl}
          width="100%"
          id="iframepage"
          frameBorder="0"
          scrolling="yes"
          marginHeight="0"
          height="600px"
          marginWidth="0"
          onLoad={this.iFrameHeight}
        />
        <PolicyProductInfo
          ref="PolicyProductInfo"
          policyProductRisk={policyProductRisk}
        />
      </div>
    );
  },

  cancel() {
    UIDialog.hide('confirmDialog0_policy');
    UIDialog.hide('policyDetail_dialog');
  },
});
export default PolicyDetailDialog;
