import { CodeTable } from 'RainbowUI';

export default {
  transactionType: new CodeTable([
    { id: 'Registration', text: 'Registration' },
    { id: 'Field Investigation', text: 'Field Investigation' },
    { id: 'Appraisal', text: 'Appraisal' },
    { id: 'Calculation', text: 'Calculation' },
    { id: 'Settlement', text: 'Settlement' },
    { id: 'Salvage', text: 'Salvage' },
    { id: 'Subrogation', text: 'Subrogation' },
    { id: 'Recovery', text: 'Recovery' },
    { id: 'Refund', text: 'Refund' },
  ]),
};
