import {
  UIText,
  UISelect,
  UIDateTimePicker,
  UIDialog,
  UISmartPanelGrid,
  UIBox,
  UIButton,
} from 'RainbowUI';
import CommonCodeTable from '../../../codetable/CodeTable';
import PartyConst from '../../../const/PartyConst';

const ViewProducerIndRole = React.createClass({
  getInitialState() {
    return {
      partyRole: {},
    };
  },

  render() {
    const _self = this;
    const { partyRole } = this.state;
    const status = partyRole.Status;
    let visible = false;
    visible = status != '1';
    return (
      <div>
        <UIDialog
          id="viewProducerIndPartyDialog"
          title={i18n.PartyRoleDetail}
          width="80%"
        >
          <UISmartPanelGrid column="3">
            <UISelect
              label={i18n.PartyRole}
              value={PartyConst.PARTY_ROLE_CODE_IND_PRODUCER}
              enabled="false"
              codeTable={CommonCodeTable.getIndPartyRole}
              required="true"
            />
            <UIText
              label={i18n.ProducerCode}
              enabled="false"
              model={partyRole}
              property="ProducerCode"
              disabled="true"
            />
            <UISelect
              label={i18n.ProducerType}
              model={partyRole}
              property="ProducerType"
              codeTable={CommonCodeTable.getPartyRoleProducerType}
              required="true"
              validationGroup="partyProducerRoleDialog"
              disabled="true"
            />
            <UISelect
              label={i18n.Status}
              model={partyRole}
              property="Status"
              defaultValue="1"
              showBlankOption="false"
              disabled="true"
              codeTable={CommonCodeTable.getPartyRoleProducerStatus}
            />
            <UIDateTimePicker
              label={i18n.InactiveDate}
              format="MM/DD/YYYY"
              disabled="true"
              visibled={visible}
              model={partyRole}
              property="InactiveDate"
            />
          </UISmartPanelGrid>
          <UIBox direction="center">
            <UIButton value={i18n.Save} disabled="true" />
            <UIButton
              value={i18n.Cancel}
              onClick={_self.onClickClose}
            />
          </UIBox>
        </UIDialog>
      </div>
    );
  },
  onClickClose() {
    UIDialog.hide('viewProducerIndPartyDialog');
  },
  showDialog(partyRole) {
    this.setState({
      partyRole,
    });
    UIDialog.show('viewProducerIndPartyDialog');
  },
});
export default ViewProducerIndRole;
