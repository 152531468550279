import {
  DataContext,
  UpdateContext,
  AjaxUtil,
  MessageHelper,
} from 'RainbowUI';
import {
  RestStatus,
  ClaimMessageForamtUtils,
} from 'ClaimCommonConstant';
import Url from '../url/Url';
import TreeNode from '../constant/TreeNodeCache';

export default {
  getSubclaimTypeList(pageindex, pagesize) {
    const data = TreeNode.TreeCache.get('TreeNode');
    const productLineCodeJS = {
      productLineCode: data.treeNodeCode,
    };
    const searchCondition = {};
    searchCondition.ProductLineCode =
      productLineCodeJS.productLineCode;
    searchCondition.PageNo = pageindex - 1;
    searchCondition.PageSize = pagesize;
    const url =
      Url.ClaimSubclaimTypeOfProductLineLoadBySubclaimTypeCode;
    if (DataContext.get('searchSubclaimTypeCondition')) {
      const condition = DataContext.get(
        'searchSubclaimTypeCondition'
      );
      if (condition.subclaimTypeCode) {
        searchCondition.SubclaimTypeCode = condition.subclaimTypeCode
          .replace(/(^\s*)|(\s*$)/g, '')
          .toUpperCase();
      }
    }
    let result = {};
    AjaxUtil.doPost(url, searchCondition, {
      async: false,
      done(data) {
        result = data.Model;
      },
      fail() {
        MessageHelper.error(
          i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
        );
      },
    });

    return { count: result.Total, result: result.Content };
  },

  saveSubclaim(newdata) {
    const newSubclaimType = newdata;
    let url = Url.ClaimSubclaimTypeOfProductLineUpdate;

    if (!newSubclaimType.ProductLineCode) {
      const data = TreeNode.TreeCache.get('TreeNode');
      newSubclaimType.ProductLineCode = data.treeNodeCode;
      url = Url.ClaimSubclaimTypeOfProductLineSave;
    }
    let result = 'false';
    AjaxUtil.doPost(url, newSubclaimType, {
      async: false,
      done(data) {
        if (data.Status == RestStatus.OK) {
          MessageHelper.success(
            ClaimMessageForamtUtils.formatI18NMsg(
              i18n.ClaimCommon.SaveSuccess,
              i18n.ClaimProduct.SubclaimType
            )
          );
          result = 'true';
        } else if (data.Status == RestStatus.BLOCK) {
          MessageHelper.error(
            ClaimMessageForamtUtils.formatI18NMsg(
              i18n.ClaimCommon.IsExist,
              i18n.ClaimProduct.SubclaimType
            )
          );
        } else {
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
        }
      },
      fail() {
        MessageHelper.error(
          i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
        );
      },
    });
    return result;
  },

  removeSubclaimType(removedata) {
    const data = removedata;

    const subclaimTypeCode = {
      SubclaimTypeCode: data.SubclaimTypeCode,
    };

    AjaxUtil.doPost(
      Url.ClaimSubclaimTypeOfProductLineRemove +
        subclaimTypeCode.SubclaimTypeCode,
      {},
      {
        async: false,
        done(data) {
          if (data.Status == RestStatus.BLOCK) {
            MessageHelper.error(
              ClaimMessageForamtUtils.getI18NMsg(
                i18n.ClaimCommon.DeleteFail,
                i18n.ClaimProduct.SubclaimType
              )
            );
          } else if (data.Status == RestStatus.OK) {
            MessageHelper.success(
              ClaimMessageForamtUtils.getI18NMsg(
                i18n.ClaimCommon.DeleteSuccess,
                i18n.ClaimProduct.SubclaimType
              )
            );
          }
        },
        fail() {
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
        },
      }
    );
    UpdateContext.forceUpdate('subclaimTypeListPage');
  },

  searchSubclaimTypeList(condition) {
    DataContext.put('searchSubclaimTypeCondition', condition);
    UpdateContext.forceUpdate(
      'subclaimTypeListPageSearchResultProvider'
    );
  },
};
