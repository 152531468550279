import {
  UICell,
  UITextarea,
  UISmartPanelGrid,
  UIPanel,
  MessageHelper,
  UIButton,
  UIDialog,
  UIBox,
} from 'RainbowUI';
import { ClaimCstUI } from 'ClaimCommonConstant';
import BindToMixin from 'react-binding';
import SettlementHistoryAction from '../action/SettlementHistoryAction';

const SettlementCancelDialog = React.createClass({
  mixins: [BindToMixin],

  getInitialState() {
    return {
      cancelInfo: {
        SettlePayeeId: null,
        Remark: '',
      },
    };
  },

  showDialog(data) {
    const cancelInfo = {};
    cancelInfo.SettlePayeeId = data;
    const self = this;
    const { taskCode } = self.props;
    cancelInfo.TaskCode = taskCode;
    cancelInfo.TransactionType = 'C';
    this.setState({ cancelInfo });
    const componentId = this.props.componentId || '';
    UIDialog.show(`settlementCancelDialog${componentId}`);
  },

  render() {
    const _self = this;
    const componentId = _self.props.componentId || '';
    const { taskCode } = _self.props;
    let panelTitle;
    let cancelMessage;
    if (
      taskCode == ClaimCstUI.CLAIM_TASK_CODE.Recovery ||
      taskCode == ClaimCstUI.CLAIM_TASK_CODE.RecoveryApproval
    ) {
      panelTitle = i18n.ClaimSettlement.CancelRecovery;
      cancelMessage = i18n.ClaimSettlement.CancelRecoveryMessage;
    } else if (
      taskCode == ClaimCstUI.CLAIM_TASK_CODE.Refund ||
      taskCode == ClaimCstUI.CLAIM_TASK_CODE.RefundApproval
    ) {
      panelTitle = i18n.ClaimSettlement.CancelRefund;
      cancelMessage = i18n.ClaimSettlement.CancelRefundMessage;
    } else if (
      taskCode == ClaimCstUI.CLAIM_TASK_CODE.Settlement ||
      taskCode == ClaimCstUI.CLAIM_TASK_CODE.SettlementApproval
    ) {
      panelTitle = i18n.ClaimSettlement.CancelSettlement;
      cancelMessage = i18n.ClaimSettlement.CancelSettlementMessage;
    } else {
      console.error('settlementCancelDialog is taskCode error');
    }
    return (
      <UIDialog
        id={`settlementCancelDialog${componentId}`}
        width="80%"
        height="800px"
        modal="false"
        title={i18n.ClaimSettlement.CancelConfirmation}
      >
        <UIPanel
          id={`settlement_cancel_payee_panel${componentId}`}
          panelTitle={panelTitle}
          styleClass="default"
        >
          <UICell
            style={{
              textAlign: 'left',
              marginLeft: '-15px',
              lineHeight: '3em',
            }}
          >
            {cancelMessage}
          </UICell>
          <UISmartPanelGrid
            id={`settlement_cancel_payee_SmartPanelGrid${componentId}`}
          >
            <UITextarea
              id={`cancel_remark${componentId}`}
              valueLink={_self.bindToState('cancelInfo', 'Remark')}
              label={i18n.ClaimCommon.Remark}
              placeHolder={
                i18n.ClaimSettlement.CancelRemarkPlaceHolder
              }
              colspan="3"
            />
          </UISmartPanelGrid>
        </UIPanel>
        <UIBox direction="center">
          <UIButton
            value={i18n.ClaimCommon.Submit}
            position="right"
            onClick={_self.submit}
          />
          <UIButton
            value={i18n.ClaimCommon.Cancel}
            onClick={this.onCancel}
            position="right"
          />
        </UIBox>
      </UIDialog>
    );
  },

  submit() {
    const componentId = this.props.componentId || '';
    const { taskCode } = this.props;
    const { caseId } = this.props;
    const flag = SettlementHistoryAction.cancelSubmit(
      this.state.cancelInfo,
      componentId,
      caseId,
      taskCode
    );
    if (flag) {
      if (
        taskCode == ClaimCstUI.CLAIM_TASK_CODE.Recovery ||
        taskCode == ClaimCstUI.CLAIM_TASK_CODE.RecoveryApproval
      ) {
        MessageHelper.success(
          '',
          i18n.ClaimSettlement.RecoveryCancelSuccess
        );
      } else if (
        taskCode == ClaimCstUI.CLAIM_TASK_CODE.Settlement ||
        taskCode == ClaimCstUI.CLAIM_TASK_CODE.SettlementApproval
      ) {
        MessageHelper.success(
          '',
          i18n.ClaimSettlement.SettlementCancelSuccess
        );
        window.location.reload();
      } else {
        console.error('settlementCancelDialog is taskCode error');
      }
    }
  },

  onCancel() {
    const componentId = this.props.componentId || '';
    UIDialog.hide(`settlementCancelDialog${componentId}`);
  },
});
export default SettlementCancelDialog;
