import { MessageHelper } from 'RainbowUI';
import PriorClaimsService from '../service/PriorClaimsService';
import alt from '../alt';

class PriorClaimsAction {
  getPriorClaimsList(pageIndex, pageSize, caseId) {
    let priorClaimList = [];
    let searchResult = {
      Total: 0
    };

    PriorClaimsService.loadList(pageIndex - 1, pageSize, caseId).then(
      data => {
        searchResult = data.Model;
        priorClaimList = data.Model.Content;
        if (priorClaimList && priorClaimList.length > 0) {
          priorClaimList.map(datas => {
            const data = datas;
            data.PolicyholderName =
              priorClaimList[0].PolicyholderName;
            return data;
          });
        }
      },
      err => {
        if (err.status !== 200) {
          MessageHelper.error(
            err.statusText,
            i18n.ClaimCommon.ErrorMsg.QueryFail,
            MessageHelper.POSITION_TOP_FULL_WIDTH
          );
        }
      }
    );

    return { count: searchResult.Total, result: priorClaimList };
  }

  loadLink(event) {
    const caseId = event.getParameter('caseId');

    window.location.hash = `#/handling/tasks?caseId=${caseId}`;
  }
}
export default alt.createActions(PriorClaimsAction);
