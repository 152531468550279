import MaskService from '../../common/service/MaskService';

class PartyAccountAction {
  initMaskInfo(temppartyAccount) {
    const partyAccount = temppartyAccount;
    if (partyAccount) {
      let maskRule;
      const accountNo = partyAccount.AccountNo;
      if (accountNo) {
        maskRule = MaskService.loadMaskRule(
          'PartyAccount',
          'AccountNo'
        );
        if (maskRule) {
          const maskAccountNo = MaskService.getMaskValue(
            maskRule,
            accountNo
          );
          partyAccount.AccountNoMask = maskAccountNo;
        } else {
          partyAccount.AccountNoMask = accountNo;
        }
      }
      const ibanNo = partyAccount.IBANNo;
      if (ibanNo) {
        maskRule = MaskService.loadMaskRule('PartyAccount', 'IBANNo');
        if (maskRule) {
          const maskIbanNo = MaskService.getMaskValue(
            maskRule,
            ibanNo
          );
          partyAccount.IBANNoMask = maskIbanNo;
        } else {
          partyAccount.IBANNoMask = ibanNo;
        }
      }
      const swiftCode = partyAccount.SwiftCode;
      if (swiftCode) {
        maskRule = MaskService.loadMaskRule(
          'PartyAccount',
          'SwiftCode'
        );
        if (maskRule) {
          const maskSwiftCode = MaskService.getMaskValue(
            maskRule,
            swiftCode
          );
          partyAccount.SwiftCodeMask = maskSwiftCode;
        } else {
          partyAccount.SwiftCodeMask = swiftCode;
        }
      }
    }
  }

  removeMaskInfo(temppartyAccount) {
    const partyAccount = temppartyAccount;
    delete partyAccount.AccountNoMask;
    delete partyAccount.IBANNoMask;
    delete partyAccount.SwiftCodeMask;
  }

  getMaskValue(modelName, fieldName, value) {
    let maskValue = value;

    if (modelName && fieldName && value) {
      const maskRule = MaskService.loadMaskRule(modelName, fieldName);
      if (maskRule) {
        maskValue = MaskService.getMaskValue(maskRule, value);
      }
    }

    return maskValue;
  }
}
export default PartyAccountAction;
