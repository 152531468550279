import {
  UIParam,
  UISelect,
  UILink,
  UIColumn,
  UIDataTable,
  UIPanel,
} from 'RainbowUI';
import DefinedCodeTable from '../../../codetable/CodeTable';
import EditAdditionalDialogReadOnly from './EditAdditionalDialogReadOnly';

const PartyIndAddInfo = React.createClass({
  getInitialState() {
    let PartyAdditionalList = this.props.partyAdditionalList;
    if (!PartyAdditionalList) {
      PartyAdditionalList = [];
    }
    _.forEach(PartyAdditionalList, (temppartyAdditional, index) => {
      const partyAdditional = temppartyAdditional;
      partyAdditional.DefinedIndex = index + 1;
    });
    const totalNum = PartyAdditionalList.length;
    return {
      PartyAdditionalList,
      TotalNum: totalNum,
      party: this.props.party,
    };
  },

  viewHandler(clickEvent) {
    let partyAdditional = {};
    if (!_.isEmpty(clickEvent.paramJson)) {
      partyAdditional = clickEvent.paramJson[0].value;
    }
    this.refs.editPartyAdditionalDialog.showDialog(partyAdditional);
  },

  render() {
    const { PartyAdditionalList } = this.state;
    return (
      <div>
        <UIPanel panelTitle={i18n.AdditionalId}>
          <UIDataTable
            id="partyAdditionalInfo"
            value={PartyAdditionalList}
            indexable="false"
          >
            <UIColumn
              headerTitle={i18n.IdType}
              value="IdType"
              render={data => {
                return (
                  <UISelect
                    model={data}
                    property="IdType"
                    codeTable={DefinedCodeTable.IdType}
                    io="out"
                  />
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.IdNo}
              render={data => {
                return (
                  <div>
                    <UILink
                      value={data.IdNumber}
                      onClick={this.viewHandler}
                    >
                      <UIParam name="data" value={data} />
                    </UILink>
                  </div>
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.CountryOfIssue}
              render={data => {
                return (
                  <UISelect
                    model={data}
                    property="Country"
                    codeTable={DefinedCodeTable.getCountry}
                    io="out"
                  />
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.Status}
              render={data => {
                return (
                  <UISelect
                    model={data}
                    property="Status"
                    codeTable={DefinedCodeTable.getPartyStatus}
                    io="out"
                  />
                );
              }}
            />
          </UIDataTable>
          <EditAdditionalDialogReadOnly ref="editPartyAdditionalDialog" />
        </UIPanel>
      </div>
    );
  },
});
export default PartyIndAddInfo;
