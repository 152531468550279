import alt from '../alt';

const SalvageLoadAction = require('../action/SalvageLoadAction');

class SalvageApproveStore {
  constructor() {
    this.approveInfo = {
      BusinessId: null,
      TaskInstanceId: null,
      Remark: '',
      DecisionCode: null,
    };

    this.bindListeners({
      handleLoadApproveTask: SalvageLoadAction.LOAD_APPROVE_TASK,
    });
  }

  handleLoadApproveTask(data) {
    this.approveInfo = data;
    this.approveInfo.Remark = '';
    this.approveInfo.DecisionCode = null;
  }
}
export default alt.createStore(
  SalvageApproveStore,
  SalvageApproveStore
);
