const getUrlPrefix = url => {
  let result = url;
  if (!url.endsWith('/')) {
    result += '/';
  }
  return result;
};

const urlDefine = {
  ClaimCaseClose: `${env.serverUrl}common/status/closeCase/`,
  ClaimObjectClose: `${env.serverUrl}common/status/closeObject/`,
  ClaimItemClose: `${env.serverUrl}common/status/closeItem/`,
  ClaimReserveClose: `${env.serverUrl}common/status/closeReserve/`,

  ReserveLoad: `${env.serverUrl}reserve/`,
  ReserveCalculate: `${env.serverUrl}reserve/calculate`,
  ReserveHistory: `${env.serverUrl}reservehis/`,
  ReserveOpen: `${env.serverUrl}reserve/openReserve`,
  ReserveType: `${env.serverUrl}reserve/reserveType/`,
  ReserveReopen: `${env.serverUrl}reserve`,

  ClaimExRate2LocalCurrency: `${env.serverUrl}util/localExchangeRate/`,
  ChangeNotificationToClaim: `${env.serverUrl}claimhandling/changeNotification/`,
  ClaimGetPolicyById: `${env.serverUrl}policy/`,
  SearchPolicy: `${env.serverUrl}policy/search/`,
  SearchPolicyFlag: `${env.serverUrl}policy/policySearchFlag`,
  PolicyCollsFlag: `${env.serverUrl}policy/policyCollsFlag`,
  QueryEntityBySolr: `${env.serverUrl}public/ap00/query/entity`,
  checkPendingEndoByPolicyNo: `${env.integrationServerUrl}pa/ap00/npcendo/checkPendingEndoByPolicyNo`,
  CheckNewSubClaimType: `${env.serverUrl}claimhandling/newSubClaimType/`,
  checkChangeSubClaimType: `${env.serverUrl}claimhandling/subClaimType/`,
  ClaimNoticeRetrievePolicy: `${env.serverUrl}policy/retrieve/`,
  ClaimNoticeRetrievePolicyProduct: `${env.serverUrl}policy/retrieveProduct/`,
  ClaimNoticeRetrievePolicyByProduct: `${env.serverUrl}policy/retrieve/`,
  ClaimNoticeRetrievePolicyByProductRisk: `${env.serverUrl}policy/retrieveRisk/`,
  ClaimHandlingRetrievePolicy: `${env.serverUrl}claimhandling/retrievePolicy/`,
  ClaimHandlingInfoLoad: `${env.serverUrl}claimhandling/caseForm/`,
  ClaimHandlingCogetageList: `${env.serverUrl}claimhandling/subclaim/coverageList/`,
  ClaimHandlingClaimInfo: `${env.serverUrl}claimhandling/claimInfo/`,
  ClaimHandlingSubclaim: `${env.serverUrl}claimhandling/subclaim/`,
  ClaimHandlingLoadStatus: `${env.serverUrl}claimhandling/loadStatus/`,
  RegistrationUpdate: `${env.serverUrl}registration/`,
  loadProductList_url: `${env.integrationServerUrl}product/query/getAllProductList`,
  PriorClaimsList: `${env.serverUrl}claimhandling/priorClaims/`,
  ClaimHandlingWithOutPolicy: `${env.serverUrl}claimhandling/withOutPolicyRetrieve/`,
  QueryAuditData: `${env.serverUrl}audit/`,
  QueryCaseClaimantPartyData: `${env.serverUrl}party/clmParty/`,
  LoadClaimPartyById: `${env.serverUrl}party/getClaimParty/`,
  getByClaimPartyRole: `${env.serverUrl}party/getByClaimPartyRole/`,
  PolicySave: `${env.serverUrl}policy/`,
  IsChildProduct: `${env.integrationServerUrl}product/isChildProduct/`,

  NoticeCreate: `${env.serverUrl}notice/create`,
  NoticeUpdate: `${env.serverUrl}notice/update`,
  NoticeLoad: `${env.serverUrl}notice/load/`,
  NoticeLoadList: `${env.serverUrl}notice/List`,

  ApprovalHistory: `${env.serverUrl}common/approve/`,

  RtaLoadAccident: `${env.serverUrl}eu00/rta/accident/loadAccident/`,
  RtaSaveAccident: `${env.serverUrl}eu00/rta/accident/saveAccident/`,
  RtaDownLoadZip: `${env.serverUrl}eu00/rta/accident/download/`,
  GetPaPolicy: `${env.integrationServerUrl}pa/policy/`,

  ApprovalReserveHistoryList: `${env.serverUrl}common/approve/reserve/history/`,
  ApprovalReserveHistory: `${env.serverUrl}common/approve/reserve/`,

  SettlementCancel: `${env.serverUrl}settlement/cancel`,
  SettlementHistory: `${env.serverUrl}settlement/history/`,
  SettlementApprove: `${env.serverUrl}settlement/approve`,
  CheckSubclaimDidSettlement: `${env.serverUrl}settlement/checkSubclaimDidSettlement/`,

  CodeTableDataList: `${env.serverUrl}public/codetable/data/keyword/`,
  CodeTableDataCondition: `${env.serverUrl}public/codetable/data/condition/`,
  CurrentUserInformation: `${env.serverUrl}party/party/party/loadCurrentUser`,
  UserProfileLocale: `${env.serverUrl}system/users/profile/locale`,
  ClearCache: `${env.serverUrl}public/cache/v1/clear/all`,
  UserPermissionCodes: `${env.serverUrl}public/authorities/users/permissioncodes`,
  UserPermissions: `${env.serverUrl}public/authorities/users/permissions`,
  PermissionsSearchByModule: `${env.serverUrl}public/permissions/search/byModule`,

  ClaimPartyCreate: `${env.serverUrl}party/create/`,
  ClaimPartyInit: `${env.serverUrl}party/`,
  ClaimPartyUpdate: `${env.serverUrl}party/`,
  ClaimPartyList: `${env.serverUrl}party/partylist/`,
  ClaimPartyListByPartyName: `${env.serverUrl}party/listByPartyName/`,

  ClaimWorkflowGetMyTaskList: `${env.serverUrl}workflow/pendingTasks`,
  ClaimWorkflowGetMyTaskListSummary: `${env.serverUrl}workflow/pendingTasksSummary`,
  ClaimWorkflowGetSharingPoolList: `${env.serverUrl}workflow/sharingPool`,
  ClaimWorkflowGetSharingPoolListSummary: `${env.serverUrl}workflow/sharingPoolSummary`,

  ClaimWorkflowCloseTask: `${env.serverUrl}workflow/taskClosed`,
  ClaimWorkflowAssignOrReAssignTask: `${env.serverUrl}workflow/task`,
  ClaimWorkflowQueryClaimTasks: `${env.serverUrl}workflow/claimTasks`,
  ClaimWorkflowQueryHisClaimTasks: `${env.serverUrl}workflow/hisClaimTask`,
  ClaimWorkflowUpdateTasks: `${env.serverUrl}workflow/claimReassignTasks`,
  updateTaskWithUserNameByProcessInstanceId: `${env.serverUrl}workflow/assignmentApproch`,
  AssignmentApprochCode: `${env.serverUrl}workflow/assignmentApprochCode`,
  UserListByAuthoryCode: `${env.serverUrl}workflow/userList`,
  TaskAuthorityUserList: `${env.serverUrl}workflow/taskAuthoryUserList`,
  CheckApprovalTask: `${env.serverUrl}workflow/checkApprovalTask`,

  PartyLoad: `${env.serverUrl}party/party/party/byPartyId?partyId=`,
  SearchPartyRole: `${env.serverUrl}party/party/party/searchPartyRole`,
  PartyListOrg: `${env.serverUrl}pa/customer/list/org`,
  PartyListInd: `${env.serverUrl}pa/customer/list/individual`,
  PartyCreate: `${env.serverUrl}pa/customer/`,
  PartyUpdate: `${env.serverUrl}party/party/party/update`,
  PartyCommonUrl: `${env.PARTY_HOME_URL}`,

  ClaimCatastropheSave: `${env.serverUrl}common/accident`,
  ClaimCatastropheQueryDetailByAccidentCode: `${env.serverUrl}common`,
  ClaimCatastropheQueryList: `${env.serverUrl}common/accident/list`,
  ClaimCatastropheActiveCode: `${env.serverUrl}common/accident`,

  AssignmentApproachQueryList: `${env.serverUrl}assignment/list`,
  AssignmentApproachSaveList: `${env.serverUrl}assignment/save`,

  ClaimCatastropheCheckAccidentCode: `${env.serverUrl}common`,

  ClaimNoteSave: `${env.serverUrl}notes/save`,
  ClaimNoteLoad: `${env.serverUrl}notes/`,
  ClaimNoteCheckAuthority: `${env.serverUrl}notes/`,

  ClaimQueryLoad: `${env.serverUrl}claimquery/`,
  ClaimQueryByIndex: `${env.serverUrl}claimquery/entity`,

  ClaimAuthorityInit: `${env.serverUrl}authority/init`,
  ClaimAuthorityLoad: `${env.serverUrl}authority/load`,
  ClaimAuthoritySubmit: `${env.serverUrl}authority/submit`,
  ClaimAuthorityReset: `${env.serverUrl}authority/reset`,
  ClaimAuthorityCheck: `${env.serverUrl}authority/check`,
  getAuthortyByPermissionCode: `${env.serverUrl}authority/getAuthorityByPermissionCode`,
  ClaimCoverageTreeLoad: `${env.serverUrl}common/coverageTree/`,

  ClaimUserAuthInfo: `${env.serverUrl}userAuthInfo/user/:uid/authInfo/:authtype`,

  getApproachCodeByTaskCode: `${env.serverUrl}workflow/assigmentApproachCodeList`,
  ClaimWorkflowMassiveReassignmentTaskOwner: `${env.serverUrl}workflow/reassignTaskOwner`,
  MassiveReassignmentClaimOwner: `${env.serverUrl}reassignment/claimowner`,
  MassiveReassignmentClaimOwnerSave: `${env.serverUrl}reassignment/claimowner/save`,

  ClaimWorkFlowLoadMyClaim: `${env.serverUrl}myclaim/loadMyClaim`,
  ClaimWorkFlowCountMyClaim: `${env.serverUrl}workflow/myClaimSummary`,
  ClaimTaskWorkOnChangeTaskStatus: `${env.serverUrl}workflow/taskStatus`,

  getSubClaimNoByCaseId: `${env.serverUrl}workflow/subclaimByCaseId`,
  getTaskCode: `${env.serverUrl}workflow/taskCode`,
  InitMessage: `${env.serverUrl}claimMessage/type/1`,
  UpdateMessage: `${env.serverUrl}oa/oaEntity/changeOAEntityStatus/entityId`,
  AssignmentAttachTo: `${env.serverUrl}workflow/attachTo`,
  FATaskRelationInfo: `${env.serverUrl}workflow/faTaskRelationInfo`,
  ClaimWorkflowWorkOnAssignForPool: `${env.serverUrl}workflow/workOnAssignForPool`,
  updateTaskByProcessInstanceId: `${env.serverUrl}workflow/updateTaskByProcessInstanceId`,

  UserListWithPoolSystem: `${env.serverUrl}workflow/userListWithPoolSystem`,
  UserListByAuthoryCodeCaseId: `${env.serverUrl}workflow/userListByCaseId`,
  // Litigation url
  litigationUrl: `${env.serverUrl}litigation`,
  litigationRemove: `${env.serverUrl}litigation/remove`,
  localCurrency: `${env.serverUrl}util/localCurrency`,
  litigationAuthorityUrl: `${env.serverUrl}litigation/authority`,
  // FraudIndicator
  ClaimFraudIndicatorUrl: `${env.serverUrl}FraudIndicator`,
  PolicyValidationURL: `${env.serverUrl}policy/validation`,
  PolicyNoValidation: `${env.serverUrl}policy/checkPolicyNo/`,
  // check   policy   feeStatus  show   warning    when  loading  settlement page
  CheckPolicyFeeStatus: `${env.integrationServerUrl}/bcp/policyPremium/checkPolicyPremium`,

  ProductTree: `${env.serverUrl}productTree/productLineTree`,

  getLoginUserAgentCode: `${env.serverUrl}system/ap00sc/channel/loadCurrentUserAgentCode`,

  CancelNewSubclaim: `${env.serverUrl}claimhandling/cancelNewSubclaim/`,
  queryTransactionType: `${env.serverUrl}workflow/claimTasksTransactionType/{caseId}/{needNoAssignee}`,

  queryDocList: `${env.serverUrl}public/ap00/repository/queryRelativeList/{TransactionType}/{TransactionNo}`,
  uploadManuFileToDMS: `${env.serverUrl}public/ap00/repository/uploadManuList`,
  uploadFileToDMS: `${env.serverUrl}public/ap00/repository/upload`,
  downloadFileFromDMS: `${env.serverUrl}public/ap00/repository/download/`,
  removeFileFromDMS: `${env.serverUrl}public/ap00/repository/remove/{id}`,
  queryByModuleAndFileName: `${env.serverUrl}public/ap00/repository/query/byModuleAndFileName/{ModuleName}/{Name}`,
  previewFileFromDMS: `${env.serverUrl}public/ap00/repository/preview/`,

  ClaimProductLineLoad: `${env.serverUrl}productLine/getProductLineList`,
  getClaimProductByConditions: `${env.serverUrl}ap00/product/getClaimProductByConditions`,
  downLoadClaimMatrix: `${env.serverUrl}ap00/datatransfer/ExportClaimMatrixByCondition`,
  downLoadConfigData: `${env.serverUrl}ap00/datatransfer/exportConfigData`,
  upLoadClaimMatrix: `${env.serverUrl}ap00/datatransfer/import/configData`,
  getCurrentUser: `${env.serverUrl}authority/currentUser`,
  Exit: env.CLAIM_HOME_URL,
  IsMotor: `${env.serverUrl}productLine/isMotor`,
  IsMotorByProductCode: `${env.serverUrl}productLine/isMotorByProductCode`,
  loadSettlementInfoBySettleId: `${env.serverUrl}settlement/load/bySettlementId/`,
  loadSettlementByCaseId: `${env.serverUrl}settlement/history`,
  loadSettlementInfoByCaseId: `${env.serverUrl}settlement/load/byCaseId/`,

  queryRegionByCountryCodeAndRegionName: `${env.integrationServerUrl}party/region/region/byCountryCodeAndRegionName`,
  queryRiPolicy: `${env.integrationServerUrl}/ri/eu00/queryRiPolicy`,
  loadQueryRiDetail: `${env.integrationServerUrl}/ri/eu00/loadQueryRiDetail/`,
  loadRiPolicyForQuery: `${env.integrationServerUrl}/ri/eu00/loadRiPolicyForQuery/`,
  getAllRiskCategory: `${env.integrationServerUrl}/ri/eu00/config/riskCategory/all`,
  getAllRiskLevel: `${env.integrationServerUrl}/ri/eu00/config/riskCategoryLevel/all`,
  loadRiClaimRiskUnit: `${env.integrationServerUrl}/ri/eu00/loadRiClaimRiskUnit/`,
  loadPolicyByQuoteOrPolicyNo: `${env.integrationServerUrl}pa/ap00/copy/policy/loadPolicyByQuoteOrPolicyNo`,
  loadPolicy: `${env.integrationServerUrl}pa/policy/load/byPolicyId`,
  policyDataUpload: `${env.claimIntserverUrl}doc/upload`,
  policyDataDownload: `${env.claimIntserverUrl}doc/download`,
  QueryOutput: `${getUrlPrefix(
    env.integrationServerUrl
  )}integration/eu00/output/print/queryOutputEntity`,
  download: `${getUrlPrefix(
    env.integrationServerUrl
  )}integration/eu00/output/print/download/`,
  loadPrintType: `${getUrlPrefix(
    env.integrationServerUrl
  )}integration/eu00/output/print/getAvailableDocType`,
  loadClaimInfoByCaseId: `${getUrlPrefix(
    env.serverUrl
  )}claimhandling/claimInfo/`,
  printListByRuleId: `${getUrlPrefix(
    env.integrationServerUrl
  )}integration/eu00/output/print/printlist/rule/UnicornPrintQueueName`,
  queryPrintTask: `${getUrlPrefix(
    env.integrationServerUrl
  )}integration/eu00/output/print/queryPrintTask`,
  codetableList: `${getUrlPrefix(
    env.serverUrl
  )}public/codetable/data/list/`,
  emailLetterToRecipients: `${getUrlPrefix(
    env.integrationServerUrl
  )}integration/eu00/output/print/emailLetterToRecipients`,
};
export default urlDefine;
