import { AjaxUtil } from 'RainbowUI';
import Url from '../url/Url';

export default {
  initApprovalHistoryData(processObjectId) {
    const defer = $.Deferred();
    AjaxUtil.doGet(
      Url.ApprovalHistory + processObjectId,
      {},
      {
        async: false,
        done(data, textStatus, jqXHR) {
          if (data) {
            defer.resolve(data);
          } else {
            defer.reject({
              status: jqXHR.status,
              responseText: jqXHR.responseText,
            });
          }
        },
        fail(jqXHR) {
          defer.reject({
            status: jqXHR.status,
            responseText: jqXHR.responseText,
          });
        },
      }
    );
    return defer.promise();
  },
};
