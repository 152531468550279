const FeeQueryType = {};
(() => {
  FeeQueryType.FOR_COLLECTION = 1;
  FeeQueryType.FOR_PAYMENT = 2;
  FeeQueryType.FOR_OFFSET = 3;
  FeeQueryType.FOR_TOLERANCE_OFFSET = 4;
  FeeQueryType.FOR_WRITE_OFF = 5;
  FeeQueryType.FOR_QUERY = 9;
})();
export default FeeQueryType;
