export default {
  Appraisal: 'Appraisal',
  LossItems: 'Loss Items',
  LossItem: 'Loss Item',
  Description: 'Description',
  ClaimAmount: 'Claim Amount',
  DeductionAmount: 'Deduction Amount',
  NetAmount: 'Net Amount',
  NewLossItem: 'New Loss Item',
  TotalAmount: 'Total Amount',
  RemarkToLossItems: 'Remark to Loss Items',
  RemarkToAppraisal: 'Remark to Appraisal',
  PropertyInfo: 'Property Info',

  VehicleInfo: 'Vehicle Info',
  PlateNo: 'Registration No./Plate No.',
  Maker: 'Make',
  Model: 'Model',
  ModelYear: 'Registration Date',
  ModuleYear: 'Registration Date',
  MakeYear: 'Manufacture Year',
  VehicleType: 'Vehicle Type',
  VehicleColor: 'Vehicle Color',
  VIN: 'Chassis/VIN Number',
  EngineNumber: 'Engine Number',
  BodyShopName: 'Garage Name',
  BodyShopContact: 'Garage Contact',
  BodyShopTel: 'Garage Telephone',
  BodyShopAddress: 'Garage Address',
  BodyShopArrTime: 'Garage Arrival Time',
  DamagePart: 'Damage Part',
  LaborCost: 'Labor Cost',
  PartsName: 'Parts Name',
  PartsDescription: 'Parts Description',
  WorkType: 'Work Type',
  DamageType: 'Extent of Damage',
  Delete: 'Delete',
  TotalLaborCost: 'Total Labor Cost',
  ApprovalHistory: 'Approval History',
  TransactionDate: 'Transaction Date',
  ApprovedBy: 'Operated By',
  TotalNetAmount: 'Total Net Amount',
  ApprovalDecision: 'Approval Decision',
  Remark: 'Remark',

  TotalCost: 'Total Cost',
  SparePartCost: 'Spare Part Cost',
  SalvageValue: 'Salvage Value',
  NetAmt: 'Net Amount',
  RemarktoAppraisal: 'Remark to Appraisal',
  SyncInfo: 'SYNCHRONIZE INFO',
  Save: 'SAVE',
  SubmitTotal: 'SUBMIT',
  Back: 'BACK',
  investigatorArrival: 'Investigation Date and Time',
  Decision: 'Decision',

  PropertyName: 'Property Name',
  ClaimantIsOwner: 'Claimant is Owner',

  UsedSparePart: 'Used Spare Part?',

  whetherRegistered: 'Is the vehicle registered at DOT',
  CompanyReferencePrice: 'Surveyor Price',
  BodyShopPrice: 'Garage Price',
  ProviderPrice: 'Provider Price',
  TotalSparePartCost: 'Total Spare Part Cost : ',
  SeqNo: 'Seq No.',
  OperatedBy: 'Operated By',
  NumberOfLossItems: 'Number Of Loss Items',
  AppraisalHistory: 'Appraisal History',
  AppraisalHistoryDetail: 'Appraisal History Detail',

  VictimInfo: 'Injured Person Info',
  VictimName: 'Injured Name',
  Gender: 'Gender',
  Age: 'Age',
  HomeAddress: 'Home Address',
  ContactTel: 'Contact Tel',
  InjuryType: 'Injury Type',
  DisabilityGrade: 'Disability Grade',
  Treatment: 'Treatment',
  Hospital: 'Hospital',
  DaysInHospital: 'Days in Hospital',
  InjuryDescription: 'Injury Description',
  RemarkVictim: 'Remark to Victim',
  ItemDesc: 'Description',

  DeductionDesc: 'Deduction Description',
  DoctorName: 'Doctor Name',
  Specialty: 'Specialty',
  DateOfAdmission: 'Date of Admission',
  DateOfDischarge: 'Date of Discharge',
  Remark1: 'Remark 1',
  Remark2: 'Remark 2',
  EnterPositiveError: 'Enter the amount can not be positive!',
  CantGreaterAmount:
    'The absolute value of salvage amount cannot be greater than the total amount of Labor Cost and Spare Part Cost',

  ErrorMsg: {
    Error_0001: 'failed',
    Error_0002: 'Please enter the Registration',
    Error_0003: 'DOT Service not response.',
    Error_0004:
      'Registration number  with format: XXX000 or XX000 or  X000.',
    Error_0005: 'Invalid Claim Amount',
    Error_0006: 'Claim amount must great than deduction Amount.',
    Error_0007: 'Invalid Deduction Amount',
    Error_0008: 'Deduction Amount must less than claim amount.',
    Error_0009: 'Fail',
  },

  WarnMsg: {
    Warn_0001:
      'Date Of Discharge should be later than Date Of Admission',
    Warn_0002: 'Please check your Age Info',
    Warn_0003: 'The value must be less than 3000 characters long',
    Warn_0004: 'please check your Vin Number Imput',
    Warn_0005: 'Modify Operation Warning',
    Warn_0006: 'Select Claimant first please.',
  },

  SuccessMsg: {
    Success_0001: 'Appraisal  is submitted successfully',
    Success_0002: 'Victim Info is synchronized successfully',
    Success_0003: 'Property Info is synchronized successfully',
  },
  ConfirmMsg: {},
  InfoMsg: {},
};
