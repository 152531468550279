/**
 * Created by jie.huang on 10/7/2017.
 */
import {
  UICheckbox,
  UIBox,
  UISelect,
  UIDialog,
  UISmartPanelGrid,
  UIText,
  UIButton,
} from 'RainbowUI';
import PartyAccountAction from '../../../actions/PartyAccountAction';
import DefinedCodeTable from '../../../codetable/CodeTable';

const EditPartyAccountDialogReadOnlyReadOnly = React.createClass({
  getInitialState() {
    return {
      partyAccount: {},
    };
  },

  onClickClose() {
    const partyAccountAction = new PartyAccountAction();
    partyAccountAction.removeMaskInfo(this.state.partyAccount);
    UIDialog.hide('AccountDialogReadOnly');
  },

  render() {
    const _self = this;
    const { partyAccount } = this.state;
    return (
      <UIDialog
        id="AccountDialogReadOnly"
        width="80%"
        title={i18n.BankAccountDetail}
        onClose={_self.onClickClose}
      >
        <UISmartPanelGrid column="3">
          <UICheckbox
            single="true"
            singleLabel={i18n.PrimaryAccount}
            model={partyAccount}
            property="IsPrimaryAccount"
            disabled="true"
          />
          <UISelect
            label={i18n.Bank}
            model={partyAccount}
            property="BankName"
            codeTable={DefinedCodeTable.getBankName}
            disabled="true"
          />
          <UIText
            label={i18n.BankAccount}
            model={partyAccount}
            property="AccountNoMask"
            disabled="true"
          />
          <UIText
            label={i18n.IBANNo}
            model={partyAccount}
            property="IBANNoMask"
            disabled="true"
          />
          <UISelect
            label={i18n.IBANType}
            model={partyAccount}
            property="AccountType"
            codeTable={DefinedCodeTable.getIBANType}
            showBlankOption="false"
            disabled="true"
          />
          <UIText
            label={i18n.Account.SwiftCode}
            model={partyAccount}
            property="SwiftCodeMask"
            disabled="true"
          />
          <UISelect
            label={i18n.Status}
            codeTable={DefinedCodeTable.getPartyStatus}
            model={partyAccount}
            property="AccountStatus"
            showBlankOption="false"
            disabled="true"
          />
        </UISmartPanelGrid>
        <UIBox direction="center">
          <UIButton value={i18n.Save} disabled="true" />
          <UIButton
            value={i18n.Cancel}
            onClick={_self.onClickClose}
          />
        </UIBox>
      </UIDialog>
    );
  },

  showDialog(partyAccount) {
    const partyAccountAction = new PartyAccountAction();
    partyAccountAction.initMaskInfo(partyAccount);
    UIDialog.show('AccountDialogReadOnly');
    this.setState({
      partyAccount,
    });
  },
});
export default EditPartyAccountDialogReadOnlyReadOnly;
