import { UITree } from 'RainbowUI';
import alt from '../alt';

class CoverageTreeAction {
  loadCoverageTree(coverageTree) {
    this.dispatch(coverageTree);
  }

  displayCoverageTree(event) {
    $('.coverageTree').show();
    $('.coverageValue').hide();
  }

  chooseCoverage(event) {
    const treeId = event.getParameter('treeId');

    const checkedList = UITree.getCheckedNode(treeId, true);
    $('.coverageValue').show();
    $('.coverageTree').hide();
    this.dispatch(checkedList);
  }
}

export default alt.createActions(CoverageTreeAction);
