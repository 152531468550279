import {
  UIParam,
  UIBox,
  UIDataTable,
  UILink,
  UIColumn,
  UIRadio,
  UIButton,
} from 'RainbowUI';
import BindToMixin from 'react-binding';
import CatastropheAction from '../../action/CatastropheAction';
import CatastropheDialog from './CatastropheDialog';
import ConditionStore from '../../store/ConditionStore';
import CodeTableAction from '../../action/CodeTableAction';
import CodeTableCstUI from '../../constant/CodeTableCstUI';
import Url from '../../url/Url';

const Catastrophe = React.createClass({
  mixins: [BindToMixin],
  componentWillUnmount() {
    ConditionStore.unlisten(this.onStoreStateChange);
  },
  onStoreStateChange(storeState) {
    if (!_.isEmpty(storeState.condition)) {
      this.setState({ accidentVo: storeState.condition });
    }
  },
  componentDidMount() {
    ConditionStore.listen(this.onStoreStateChange);
  },
  getInitialState() {
    return { accidentVo: { isInit: true } };
  },

  showDialog(event) {
    const data = event.getParameter('data');
    this.refs.CatastropheDialog_ref.showDialog(data);
  },
  getAccidentList(pageindex, pagesize) {
    if (this.state.accidentVo.isInit) return [];
    delete this.state.accidentVo.isInit;
    return CatastropheAction.initCatastrophe(
      pageindex,
      pagesize,
      this.state.accidentVo
    );
  },
  updateThisPage() {
    this.setState({ inital: false });
  },

  render() {
    const _self = this;
    return (
      <div>
        <UIDataTable
          headerTitle={i18n.ClaimCommon.SearchResult}
          indexable="false"
          id="catastropheListTableId"
          provider={this.getAccidentList}
        >
          <UIColumn
            headerTitle={i18n.ClaimCommon.AccidentCode}
            value="AccidentCode"
            render={data => {
              return (
                <UILink
                  value={data.AccidentCode}
                  onClick={_self.showDialog}
                >
                  <UIParam value={data} name="data" />
                </UILink>
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.ClaimCommon.AccidentName}
            value="AccidentName"
          />
          <UIColumn
            headerTitle={i18n.ClaimCommon.AccidentValidFlag}
            value="ValidFlag"
          >
            <UIRadio
              io="out"
              codeTable={CodeTableAction.loadDataList(
                CodeTableCstUI.ClaimYesOrNo,
                null
              )}
            />
          </UIColumn>
          <UIColumn
            headerTitle={i18n.ClaimCommon.AccidentDesc}
            value="AccidentDesc"
          />
        </UIDataTable>
        <UIBox direction="left">
          <UIButton
            value={i18n.ClaimCommon.New}
            onClick={this.showDialog}
          />
        </UIBox>
        <CatastropheDialog
          ref="CatastropheDialog_ref"
          parnetFunction={this.updateThisPage}
        />
        <br />
      </div>
    );
  },
});
export default Catastrophe;
