import {
  UICheckbox,
  UIParam,
  UIPanel,
  UISmartPanelGrid,
  UICell,
  UIUpdatePanel,
  CodeTable,
  UIRadio,
  UpdateContext,
} from 'RainbowUI';

import CommonAction from '../../action/CommonAction';
import DocumentFormat from './DocumentFormat';

const PrintType = React.createClass({
  getInitialState() {
    const { paraData } = this.props;
    let typeList = [
      {
        DocFormatPDF: true,
        DocFormatWORD: true,
        DocName:
          'CYPCLL005 - Document to First Party for not Notifying the Claim',
        DocType: 20005,
        FormatPDF: false,
        FormatWORD: false,
        Orders: 1,
        ProductCode: 'COMMON',
        RuleId: 100004,
        TransType: 2,
      },
    ];
    if (paraData) {
      const commonAction = new CommonAction();
      typeList = commonAction.loadPrintType(paraData);
    }
    return {
      PrintFormat: DocumentFormat.PDF,
      typeList,
      AlreadyPrinted: 0,
      Hide: true,
    };
  },

  onInitAndShow(data) {
    const commonAction = new CommonAction();
    const para = {
      TransType: data.TransType,
      ProductCode: data.ProductCode,
      TransNumber: data.TransactionNo,
      TransNumber2: data.TransactionNo2,
      DocTypeCodeList: data.PrintDocCodeList,
      ClaimLevel: data.ClaimLevel,
      ClaimTaskType: data.ClaimTaskType,
      EndoFlag: data.EndoFlag,
      PrintContent: data.PrintContent,
    };
    const typeList = commonAction.loadPrintType(para);
    this.setState({ typeList, Hide: false });
    const QueryConditions = {};
    QueryConditions.TransactionNo = data.TransactionNo;
    QueryConditions.TransactionType = data.TransType;
    QueryConditions.TransactionPage = data.TransPage;
    const { result } = new CommonAction().queryDocHistory(
      QueryConditions,
      false
    );
    if (result && result.length > 0) {
      this.setState({ AlreadyPrinted: 1 });
    } else {
      this.setState({ AlreadyPrinted: 0 });
    }
    UpdateContext.forceUpdate('typeContent');
  },
  onInvalidAndShow(data) {
    this.setState({ typeList: [], Hide: true });
    UpdateContext.forceUpdate('typeContent');
  },
  onNoSelectAndHide() {
    this.setState({ Hide: true });
  },

  getSelPrintTypes() {
    const types = [];
    _.each(this.state.typeList, type => {
      if (type.PdfSelected == true || type.WordSelected == true) {
        types.push(type);
      }
    });
    return types;
  },

  onAllTypeChanged(event) {
    let selValue = null;
    if (event.newValue === 'Y') {
      selValue = true;
    } else {
      selValue = false;
    }
    if (this.state.PrintFormat == DocumentFormat.PDF) {
      this.changePdfAllSelected(selValue);
    } else {
      this.changeWordAllSelected(selValue);
    }
    UpdateContext.forceUpdate('typeContent');
  },

  onClickPrintFormat(event) {
    this.state.PrintFormat = event.newValue;
    this.changePdfAllSelected(false);
    this.changeWordAllSelected(false);
    UpdateContext.forceUpdate('typeContent');
  },

  changeWordAllSelected(newValue) {
    _.each(this.state.typeList, tmptype => {
      const type = tmptype;
      if (type.FormatWORD) {
        type.WordSelected = newValue;
      }
    });
  },
  changePdfAllSelected(newValue) {
    _.each(this.state.typeList, tmptype => {
      const type = tmptype;
      if (type.FormatPDF) {
        type.PdfSelected = newValue;
      }
    });
  },

  onTypeChanged(tmpevent) {
    const event = tmpevent;
    let selValue = null;
    if (event.newValue === 'Y') {
      selValue = true;
    } else {
      selValue = false;
    }

    if (this.state.PrintFormat == DocumentFormat.PDF) {
      event.paramJson['0'].value.PdfSelected = selValue;
    } else {
      event.paramJson['0'].value.WordSelected = selValue;
    }
    UpdateContext.forceUpdate('typeContent');
  },

  renderOneType(type) {
    const { PrintFormat } = this.state;
    let defaultValue = 'N';
    if (
      PrintFormat == DocumentFormat.PDF &&
      type.PdfSelected == true
    ) {
      defaultValue = 'Y';
    } else if (
      PrintFormat == DocumentFormat.Word &&
      type.WordSelected == true
    ) {
      defaultValue = 'Y';
    }

    let enable = true;
    if (
      PrintFormat == DocumentFormat.PDF &&
      type.DocFormatPDF == true
    ) {
      enable = type.FormatPDF;
    } else if (
      PrintFormat == DocumentFormat.PDF &&
      type.DocFormatPDF == false
    ) {
      enable = type.FormatPDF;
    } else if (
      PrintFormat == DocumentFormat.Word &&
      type.DocFormatWORD == true
    ) {
      enable = type.FormatWORD;
    } else if (
      PrintFormat == DocumentFormat.Word &&
      type.DocFormatWORD == false
    ) {
      enable = type.FormatWORD;
    }
    return (
      <UICell width="4">
        <UICheckbox
          disabled={false}
          visibled
          onChange={this.onTypeChanged}
          enabled
          singleLabel={type.DocName}
          defaultValue={defaultValue}
          single="true"
        >
          <UIParam name="data" value={type} />
        </UICheckbox>
      </UICell>
    );
  },
  renderAllTypes() {
    const _self = this;
    let types = [];
    _.each(this.state.typeList, type => {
      const tt = _self.renderOneType(type);
      types.push(tt);
    });

    if (types.length === 0) {
      types = '';
    }
    return types;
  },
  getPrintFormatCodeTable() {
    /*
        var userInfo=UserUtil.initUser();
        if(userInfo){
            var printFormat=userInfo.PrintFormat;
            if(printFormat==0){
                return new CodeTable([
                    {id: '1', text: 'PDF'}
                ]);
            }else if(printFormat==1){
                return new CodeTable([
                    {id: '1', text: 'PDF'},
                    {id: '2', text: 'WORD'}
                ]);
            }
            return new CodeTable([
                ]);
        } */
    return new CodeTable([
      { id: '1', text: 'PDF' },
      { id: '2', text: 'WORD' },
    ]);
  },
  renderTypeContentSection() {
    const _self = this;
    const { AlreadyPrinted } = this.state;
    const isPrinted = AlreadyPrinted;
    let disabledFlag = 'false';
    let enabledFlag = 'true';

    if (isPrinted == 1) {
      disabledFlag = 'true';
      enabledFlag = 'false';
    }

    return (
      <div>
        <UIUpdatePanel
          id="typeContent"
          render={() => {
            let allDefaultValue = 'Y';
            let atLeastOne = false;
            _.each(_self.state.typeList, type => {
              if (_self.state.PrintFormat == DocumentFormat.PDF) {
                if (
                  type.FormatPDF == true &&
                  type.PdfSelected !== true
                ) {
                  allDefaultValue = 'N';
                } else if (
                  type.FormatPDF == true &&
                  type.PdfSelected == true
                ) {
                  atLeastOne = true;
                }
              } else if (
                _self.state.PrintFormat == DocumentFormat.Word
              ) {
                if (
                  type.FormatWORD == true &&
                  type.WordSelected !== true
                ) {
                  allDefaultValue = 'N';
                } else if (
                  type.FormatWORD == true &&
                  type.WordSelected == true
                ) {
                  atLeastOne = true;
                }
              }
            });
            if (allDefaultValue === 'Y' && atLeastOne == false) {
              allDefaultValue = 'N';
            }
            return (
              <div>
                <UISmartPanelGrid column="3">
                  <UIRadio
                    label="Document Format"
                    value={_self.state.PrintFormat}
                    codeTable={_self.getPrintFormatCodeTable}
                    onChange={_self.onClickPrintFormat}
                    disabled="false"
                    enabled="true"
                  />
                  <UICell />
                  <UICell />
                </UISmartPanelGrid>
                <UISmartPanelGrid column="1">
                  <div className="UICellColor">
                    {_self.renderAllTypes()}
                  </div>
                </UISmartPanelGrid>
              </div>
            );
          }}
        />
      </div>
    );
  },
  exit() {
    window.location.hash = '/';
  },

  render() {
    const _self = this;
    const showPanel = _self.props.showPanelHeader;
    if (showPanel || showPanel === 'true') {
      return _self.renderTypeContentSection();
    }
    return (
      <UIPanel panelTitle={i18n.DocumentType}>
        {_self.renderTypeContentSection()}
      </UIPanel>
    );
  },
});
export default PrintType;
