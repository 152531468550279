import {
  UIDialog,
  UISmartPanelGrid,
  UIBox,
  UIButton,
  UIText,
  UIDataTable,
  UIPanel,
  UIColumn,
  UIUpdatePanel,
} from 'RainbowUI';

import GroupIdAction from '../../actions/GroupIdAction';

const GroupIdSearchDialog = React.createClass({
  getInitialState() {
    const searchCondition = {
      GroupId: null,
      Description: null,
    };

    return {
      isSearch: false,
      searchCondition,
    };
  },

  showDialog() {
    const searchCondition = {
      GroupId: null,
      Description: null,
    };
    this.setState({
      searchCondition,
      isSearch: false,
    });
    UIDialog.show('GroupIdSearchDialog');
  },

  hideDialog() {
    UIDialog.hide('GroupIdSearchDialog');
  },

  onSearchHandler() {
    this.setState({ isSearch: true });
  },

  onResetHandler() {
    const searchCondition = {
      GroupId: null,
      Description: null,
    };
    this.setState({
      searchCondition,
      isSearch: false,
    });
  },

  onSubmitHandler() {
    const selected = UIDataTable.getSelectedRecord(
      'groupIdDefine_resultTable'
    );
    if (selected && selected.length > 0) {
      this.props.customerChose(selected[0]);
    }

    UIDialog.hide('GroupIdSearchDialog');
  },

  getPartyGroupResult(pageIndex, pageSize) {
    const groupIdAction = new GroupIdAction();

    const { isSearch } = this.state;
    if (!isSearch) {
      return { count: 0, result: [] };
    }

    return groupIdAction.getPartyGroupResult(
      this.state.searchCondition,
      pageIndex,
      pageSize
    );
  },

  render() {
    const _self = this;
    return (
      <UIDialog
        id="GroupIdSearchDialog"
        width="80%"
        title={i18n.PtyGroupIdDialog}
      >
        <UIPanel panelTitle={i18n.PtyGroupIdSearch}>
          <UISmartPanelGrid>
            <UIText
              label={i18n.PtyGroupID}
              model={_self.state.searchCondition}
              property="GroupId"
            />
            <UIText
              label={i18n.PtyGroupIdDesc}
              model={_self.state.searchCondition}
              property="Description"
            />
          </UISmartPanelGrid>
          <UIBox direction="left">
            <UIButton
              value={i18n.Search}
              onClick={this.onSearchHandler}
            />
            <UIButton
              value={i18n.Reset}
              onClick={this.onResetHandler}
            />
          </UIBox>
        </UIPanel>
        {_self.renderTable()}
        <UIBox direction="center">
          <UIButton
            value={i18n.Submit}
            onClick={this.onSubmitHandler}
          />
          <UIButton value={i18n.Cancel} onClick={this.hideDialog} />
        </UIBox>
      </UIDialog>
    );
  },

  renderTable() {
    const _self = this;
    return (
      <UIUpdatePanel id="GroupIdDefine_SearchRst">
        <UIDataTable
          id="groupIdDefine_resultTable"
          provider={_self.getPartyGroupResult}
          headerTitle={i18n.PtyGroupIdResult}
          indexable="false"
          detailable="false"
          selectionMode="single"
        >
          <UIColumn headerTitle={i18n.PtyGroupID} value="GroupId">
            <UIText io="out" />
          </UIColumn>
          <UIColumn headerTitle={i18n.PtyGroupName} value="GroupName">
            <UIText io="out" />
          </UIColumn>
          <UIColumn
            headerTitle={i18n.PtyGroupIdDesc}
            value="Description"
          >
            <UIText io="out" />
          </UIColumn>
        </UIDataTable>
      </UIUpdatePanel>
    );
  },
});
export default GroupIdSearchDialog;
