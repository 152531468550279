import {
  UpdateContext,
  UIUpdatePanel,
  MessageHelper,
  UIBox,
  UISmartPanelGrid,
  UIText,
  UIDateTimePicker,
  UIButton,
} from 'RainbowUI';
import React from 'react';
import BindToMixin from 'react-binding';
import PolicyDetailDialogColls from './PolicyDetailDialogColls';
import PolicyForm from '../../mock/policy/PolicyForm';

const PolicyAction = require('../../action/PolicyAction');

const PolicyInfo = React.createClass({
  mixins: [BindToMixin],

  getInitialState() {
    return {
      retrieveInfo: this.props.data,
      retrieve: false,
    };
  },

  stateChange(data) {
    if (data) {
      this.setState({
        retrieveInfo: data,
        retrieve: false,
      });
    }
  },

  showPolicySearchDialog() {
    this.props.parentComponent.refs.PolicySearchDialog.showDialog();
  },
  showPolicyDetailFlag() {
    this.showPolicyDetailDialog();

    //Trust logic
    // const policyNo = this.state.retrieveInfo.PolicyNo;
    // const result = PolicyAction.checkPendingEndoByPolicyNo(policyNo);
    // if (result) {
    //   MessageHelper.error(i18n.ClaimCommon.Error.PolicyInNPCMessage);
    //   return;
    // }
    // const flag = PolicyAction.policyCollsFlag();
    // console.log('===showPolicyDetailFlag===', flag);
    // if (flag == true) {
    //   console.log('===showPolicyDetailDialogColls===');
    //   this.showPolicyDetailDialogColls();
    // } else {
    //   console.log('===showPolicyDetailDialog===');
    //   this.showPolicyDetailDialog();
    // }
  },
  showPolicyDetailDialogColls() {
    if (undefined == this.state.retrieveInfo.NoticeTime) {
      const date = new ServerDate();
      const year = date.getFullYear();
      let month = date.getMonth() + 1;
      let strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = `0${month}`;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = `0${strDate}`;
      }
      strDate = `${year}-${month}-${strDate}T00:00:00`;
      this.state.retrieveInfo.NoticeTime = strDate;
    }

    console.log('=========showPolicyDetailDialogColls========');
    const data = {
      AccidentTime: this.state.retrieveInfo.AccidentTime,
      NoticeTime: this.state.retrieveInfo.NoticeTime,
      RegistrationTime: this.props.parentComponent.getRegistrationTime
        ? this.props.parentComponent.getRegistrationTime()
        : null,
      PolicyNo: this.state.retrieveInfo.PolicyNo,
    };

    const PolicyEntity = PolicyAction.copyPolicy(
      this.state.retrieveInfo
    );

    console.log(
      '=========showPolicyDetailDialogColls========',
      PolicyEntity
    );
    if (PolicyEntity) {
      this.refs.PolicyDetailDialogColls.showDialogColls(
        this.state.retrieveInfo,
        PolicyEntity
      );
    } else {
      PolicyAction.checkPolicyNo(data.PolicyNo);
    }
  },

  showPolicyDetailDialog() {
    if (undefined == this.state.retrieveInfo.NoticeTime) {
      const date = new ServerDate();
      const year = date.getFullYear();
      let month = date.getMonth() + 1;
      let strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = `0${month}`;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = `0${strDate}`;
      }
      strDate = `${year}-${month}-${strDate}T00:00:00`;
      this.state.retrieveInfo.NoticeTime = strDate;
    }

    let policyInfo;
    if (env.isDummyPolicy) {
      policyInfo = PolicyForm[this.state.retrieveInfo.PolicyNo];
      if (policyInfo) {
        delete policyInfo.default;
      }
    } else {
      policyInfo = PolicyAction.copyPolicy(this.state.retrieveInfo);
    }

    if (policyInfo) {
      this.props.parentComponent.refs.PolicyDetailDialog.showDialog(
        this.state.retrieveInfo, policyInfo);
    } else {
      MessageHelper.error(
        i18n.ClaimCommon.NoPolicy,
        '',
        MessageHelper.POSITION_TOP_RIGHT
      );
    }




    //Trust logic
    // const data = {
    //   AccidentTime: this.state.retrieveInfo.AccidentTime,
    //   NoticeTime: this.state.retrieveInfo.NoticeTime,
    //   RegistrationTime: this.props.parentComponent.getRegistrationTime
    //     ? this.props.parentComponent.getRegistrationTime()
    //     : null,
    //   PolicyNo: this.state.retrieveInfo.PolicyNo,
    // };

    // const policyProductRisk = PolicyAction.copyPolicyProduct(
    //   this.state.retrieveInfo
    // );

    // if (
    //   policyProductRisk ===
    //   'i18n.ClaimCommon.Error.ValidateNPCDuration'
    // ) {
    //   MessageHelper.error(
    //     i18n.ClaimCommon.Error.ValidateNPCDuration,
    //     '',
    //     MessageHelper.POSITION_TOP_RIGHT
    //   );
    //   return;
    // }
    // if (policyProductRisk && policyProductRisk.length > 0) {
    //   const { PolicyType } = policyProductRisk[0];
    //   if (PolicyType && (PolicyType == '3' || PolicyType == '2')) {
    //     MessageHelper.error(
    //       i18n.ClaimCommon.ErrorMsg.GOrMPolicyChangeCertificate.replace(
    //         '{0}',
    //         data.PolicyNo
    //       ),
    //       i18n.ClaimCommon.ErrorMsg.Error_0002,
    //       MessageHelper.POSITION_TOP_RIGHT
    //     );
    //     return;
    //   }

    //   if (!this.props.existNoticeNo) {
    //     this.props.parentComponent.refs.PolicyDetailDialog.showDialogInfo(
    //       this.state.retrieveInfo,
    //       policyProductRisk
    //     );
    //   } else if (
    //     policyProductRisk[0].ProductCode == this.props.productCode
    //   ) {
    //     this.props.parentComponent.refs.PolicyDetailDialog.showDialogInfo(
    //       this.state.retrieveInfo,
    //       policyProductRisk
    //     );
    //   } else {
    //     const isChildProduct = PolicyAction.isChildProduct(
    //       this.props.productCode,
    //       policyProductRisk[0].ProductCode
    //     );
    //     if (isChildProduct) {
    //       this.props.parentComponent.refs.PolicyDetailDialog.showDialogInfo(
    //         this.state.retrieveInfo,
    //         policyProductRisk
    //       );
    //     } else {
    //       MessageHelper.warning(
    //         i18n.ClaimCommon.WarnMsg
    //           .ProductIsDifferentNeedCheckPolicy,
    //         '',
    //         MessageHelper.POSITION_TOP_RIGHT
    //       );
    //     }
    //   }
    // } else {
    //   const ifExist = PolicyAction.checkPolicyNo(data.PolicyNo);
    //   if (ifExist) {
    //     console.log('===showAP100PolicyDetailDialog======', ifExist);
    //     this.props.parentComponent.refs.PolicyDetailDialog.showDialogInfo(
    //       this.state.retrieveInfo,
    //       policyProductRisk,
    //       ifExist
    //     );
    //   }
    // }
  },

  clearRetrieveInfo() {
    if (this.props.from === 'notice') {
      this.props.parentComponent.clearPolicyInfo();
    }
    this.setState(prevState => {
      const retrieveIn = prevState.retrieveInfo;
      retrieveIn.ClaimsMade = null;
      retrieveIn.PolicyNo = null;
      retrieveIn.NoticeTime = null;
      retrieveIn.AccidentTime = null;
      retrieveIn.DateReportedInsured = null;
      retrieveIn.ClaimsMade = null;
      retrieveIn.ProductCode = null;
      retrieveIn.ProductTypeCode = null;
      retrieveIn.ProductName = null;
      return {
        retrieveInfo: retrieveIn,
        retrieve: false,
      };
    });
    UpdateContext.forceUpdate('updateAparSearchPanel');
    $('#dateOfLoss')
      .data('DateTimePicker')
      .clear();
    $('#dateOfNotice')
      .data('DateTimePicker')
      .clear();
  },

  searchCountinue(selectedPolicyNo) {
    if (selectedPolicyNo) {
      const { retrieveInfo } = this.state;
      retrieveInfo.PolicyNo = selectedPolicyNo;
      this.setState({
        retrieveInfo,
      });
    }
  },
  retrieveFromAccident() {
    this.setState({
      retrieve: true,
    });
  },
  getEnabled() {
    let isEnabled = 'true';
    if (this.state.retrieve) {
      isEnabled = 'false';
    }
    return isEnabled;
  },
  getParentComponent() {
    return this;
  },
  selectClaimsMade() {
    this.setState(prevState => {
      const retrieve = prevState.retrieveInfo;
      if (retrieve.ClaimsMade === 'Y') {
        retrieve.ClaimsMade = 'N';
      } else {
        retrieve.ClaimsMade = 'Y';
      }
      return {
        retrieveInfo: retrieve,
      };
    });
  },

  isWithOutPolicy() {
    const noticeEntity = this.state.retrieveInfo;
    if (noticeEntity.PolicyNo && !this.props.inNotice) {
      return 'true';
    }
    return 'false';
  },

  render() {
    const myDate = new ServerDate();
    const { retrieveInfo } = this.state;
    const year = myDate.getFullYear();
    let month = myDate.getMonth() + 1;
    let strDate = myDate.getDate();
    if (month >= 1 && month <= 9) {
      month = `0${month}`;
    }
    if (strDate >= 0 && strDate <= 9) {
      strDate = `0${strDate}`;
    }
    const strDateShow = `${month}/${strDate}/${year} 00:00:00`;
    return (
      <div>
        <UIUpdatePanel id="updateAparSearchPanel">
          <UISmartPanelGrid column="3" widthAllocation="5,7">
            <UIText
              id="policyNo"
              label={i18n.ClaimCommon.PolicyNumber}
              required={this.isWithOutPolicy()}
              validationGroup="RetrieveInfo_"
              enabled={this.getEnabled}
              suffixIcon={env.isDummyPolicy ? "fa fa-search" : ""}
              onSuffixIconClick={this.showPolicySearchDialog}
              valueLink={this.bindToState('retrieveInfo', 'PolicyNo')}
            />
            <UIDateTimePicker
              id="dateOfLoss"
              label={i18n.ClaimCommon.DateOfLoss}
              maxDate="TODAY"
              required="true"
              enabled={this.getEnabled}
              manualInput="true"
              placeHolder="MM/DD/YYYY HH:mm:ss"
              format="MM/DD/YYYY HH:mm:ss"
              validationGroup="RetrieveInfo"
              valueLink={this.bindToState(
                'retrieveInfo',
                'AccidentTime'
              )}
            />
            <UIDateTimePicker
              id="dateOfNotice"
              label={i18n.ClaimCommon.DateOfNotice}
              placeHolder={strDateShow}
              enabled={this.getEnabled}
              manualInput="true"
              format="MM/DD/YYYY HH:mm:ss"
              valueLink={this.bindToState(
                'retrieveInfo',
                'NoticeTime'
              )}
              maxDate="TODAY"
              required="true"
            />
            {this.getReportedTimeComponent()}
          </UISmartPanelGrid>
        </UIUpdatePanel>
        <UIBox direction="left">
          <UIButton
            value={i18n.ClaimCommon.Retrieve}
            onClick={this.showPolicyDetailFlag}
            causeValidation="true"
            validationGroup="RetrieveInfo,RetrieveInfo_"
          />
          <UIButton
            value={i18n.ClaimCommon.Clear}
            onClick={this.clearRetrieveInfo}
            update="updateAparSearchPanel"
          />
        </UIBox>
        <PolicyDetailDialogColls
          ref="PolicyDetailDialogColls"
          retrieveInfo={retrieveInfo}
          parentComponent={this}
        />
      </div>
    );
  },
  getReportedTimeComponent() {
    const { retrieveInfo } = this.state;
    if (retrieveInfo.ClaimsMade === 'Y') {
      return (
        <UIDateTimePicker
          id="reportedTime"
          label={i18n.ClaimNotice.DateReported}
          format="MM/DD/YYYY HH:mm:ss"
          valueLink={this.bindToState(
            'retrieveInfo',
            'DateReportedInsured'
          )}
          maxDate="TODAY"
          required="true"
          validationGroup="RetrieveInfo"
          enabled={this.getEnabled}
          placeHolder="MM/DD/YYYY HH:mm:ss"
        />
      );
    }
    return null;
  },
});
export default PolicyInfo;
