import alt from '../alt';
import AppraisalVehicleAction from '../action/AppraisalVehicleAction';
import AppraisalPropertyAction from '../action/AppraisalPropertyAction';
import AppraisalBodyInjuryAction from '../action/AppraisalBodyInjuryAction';

class AppraisalVehicleStore {
  constructor() {
    this.AppraisalData = {
      ObjectId: 0,
      TaskInstanceId: 0,
      AppraisalInfo: {
        appraisalVehicle: {
          claimAppraisalVehicleLabor: [],
          claimAppraisalVehiclePart: [],
        },
        appraisalProperty: {
          claimAppraisalPropertyItems: [],
        },
        appraisalBodyInjury: {
          ClaimAppraisalBodyItem: [],
        },
      },
      ApprovalHistoryVO: {},
      AppraisalHistory: [],
      SynBtnStatus: false,
      CurrencyCode: null,
    };

    this.bindListeners({
      loadClaimAppraisal:
        AppraisalPropertyAction.LOAD_CLAIM_APPRAISAL,
      handleAddLaborCostItem:
        AppraisalVehicleAction.ADD_LABOR_COST_ITEM,
      handleAddPropertyItem:
        AppraisalPropertyAction.ADD_PROPERTY_ITEM,
      handleAddBodyItem: AppraisalBodyInjuryAction.ADD_BODY_ITEM,
      handleRemoveLaborCostItem:
        AppraisalVehicleAction.REMOVE_LABOR_COST_ITEM,
      handleRemovePropertyItem:
        AppraisalPropertyAction.REMOVE_PROPERTY_ITEM,
      handleRemoveBodyItem:
        AppraisalBodyInjuryAction.REMOVE_BODY_ITEM,
      handleAddSpareCostItem:
        AppraisalVehicleAction.ADD_SPARE_COST_ITEM,
      handleRemoveSpareCostItem:
        AppraisalVehicleAction.REMOVE_SPARE_COST_ITEM,
      handleGetProperty: AppraisalPropertyAction.GET_PROPERTY,
    });
  }

  handleAddLaborCostItem(itemToAdd) {
    const itemList = this.AppraisalData.AppraisalInfo.appraisalVehicle
      .claimAppraisalVehicleLabor;
    const lastIndex = itemList.length;

    const totalAmount = itemList.splice(lastIndex - 1, 1);

    itemToAdd.setDataIndex(lastIndex);
    itemToAdd.seCurrencyCode(this.AppraisalData.CurrencyCode);
    itemList.push(itemToAdd.get());

    itemList.push(totalAmount[0]);
  }

  loadClaimAppraisal(AppraisalData) {
    this.AppraisalData.ObjectId = AppraisalData.ObjectId;

    if (AppraisalData.AppraisalInfo) {
      this.AppraisalData = AppraisalData;
    }
  }

  handleGetProperty(fiProperty) {
    if (fiProperty) {
      this.AppraisalData.AppraisalInfo.appraisalProperty.PropertyName =
        fiProperty.PropertyName;
      this.AppraisalData.AppraisalInfo.appraisalProperty.OwnerName =
        fiProperty.OwnerName;
      this.AppraisalData.AppraisalInfo.appraisalProperty.ClaimantIsOwner =
        fiProperty.ClaimantIsOwner;
      this.AppraisalData.AppraisalInfo.appraisalProperty.ContactTel =
        fiProperty.ContactTel;
      this.AppraisalData.AppraisalInfo.appraisalProperty.PropertyRemark =
        fiProperty.PropertyRemark;
      this.AppraisalData.AppraisalInfo.appraisalProperty.LossItemRemark =
        fiProperty.LossItemRemark;
    }
  }

  handleAddPropertyItem(itemToAdd) {
    let itemList = this.AppraisalData.AppraisalInfo.appraisalProperty
      .claimAppraisalPropertyItems;
    if (!itemList) {
      this.AppraisalData.AppraisalInfo.appraisalProperty.claimAppraisalPropertyItems = [];
      itemList = this.AppraisalData.AppraisalInfo.appraisalProperty
        .claimAppraisalPropertyItems;
    }
    const lastIndex = this.AppraisalData.AppraisalInfo
      .appraisalProperty.claimAppraisalPropertyItems.length;
    const totalAmount = itemList.splice(lastIndex - 1, 1);

    itemToAdd.setDataIndex(lastIndex);
    itemToAdd.seCurrencyCode(this.AppraisalData.CurrencyCode);
    itemList.push(itemToAdd.get());
    itemList.push(totalAmount[0]);
  }

  handleAddBodyItem(tmpitemToAdd) {
    const itemToAdd = tmpitemToAdd;
    let itemList = this.AppraisalData.AppraisalInfo
      .appraisalBodyInjury.ClaimAppraisalBodyItem;
    if (!itemList) {
      this.AppraisalData.AppraisalInfo.appraisalBodyInjury.ClaimAppraisalBodyItem = [];
      itemList = this.AppraisalData.AppraisalInfo.appraisalBodyInjury
        .ClaimAppraisalBodyItem;
    }
    const lastIndex = itemList.length;
    const totalAmount = itemList.splice(lastIndex - 1, 1);
    itemToAdd.setDataIndex(lastIndex);
    itemToAdd.CurrencyCode = this.AppraisalData.CurrencyCode;
    itemList.push(itemToAdd.get());
    itemList.push(totalAmount[0]);
  }

  handleRemoveLaborCostItem(removeIndex) {
    const laborCostItemList = this.AppraisalData.AppraisalInfo
      .appraisalVehicle.claimAppraisalVehicleLabor;

    laborCostItemList.splice(removeIndex, 1);

    for (let i = 0; i < laborCostItemList.length; i += 1) {
      laborCostItemList[i].dataIndex = i;
    }
  }

  handleRemovePropertyItem(removeIndex) {
    const peopertyItemList = this.AppraisalData.AppraisalInfo
      .appraisalProperty.claimAppraisalPropertyItems;
    peopertyItemList.splice(removeIndex, 1);
    for (let i = 0; i < peopertyItemList.length; i += 1) {
      peopertyItemList[i].dataIndex = i;
    }
  }

  handleRemoveBodyItem(removeIndex) {
    const bodyItemList = this.AppraisalData.AppraisalInfo
      .appraisalBodyInjury.ClaimAppraisalBodyItem;
    bodyItemList.splice(removeIndex, 1);
    for (let i = 0; i < bodyItemList.length; i += 1) {
      bodyItemList[i].dataIndex = i;
    }
  }

  handleAddSpareCostItem(itemToAdd) {
    const itemList = this.AppraisalData.AppraisalInfo.appraisalVehicle
      .claimAppraisalVehiclePart;
    const lastIndex = itemList.length;
    const totalAmount = itemList.splice(lastIndex - 1, 1);
    itemToAdd.setDataIndex(lastIndex);
    itemToAdd.seCurrencyCode(this.AppraisalData.CurrencyCode);
    itemList.push(itemToAdd.get());
    itemList.push(totalAmount[0]);
  }

  handleRemoveSpareCostItem(removeIndex) {
    const spareCostItemList = this.AppraisalData.AppraisalInfo
      .appraisalVehicle.claimAppraisalVehiclePart;

    spareCostItemList.splice(removeIndex, 1);

    for (let i = 0; i < spareCostItemList.length; i += 1) {
      spareCostItemList[i].dataIndex = i;
    }
  }
}
export default alt.createStore(
  AppraisalVehicleStore,
  AppraisalVehicleStore
);
