import {
  UIEmail,
  UICurrency,
  UILink,
  UIConfirmDialog,
  UIParam,
  UICell,
  UIText,
  CodeTable,
  UIBox,
  UIPanel,
  UIDataTable,
  UIDateTimePicker,
  UISelect,
  UISmartPanelGrid,
  UIColumn,
} from 'RainbowUI';
import BindToMixin from 'react-binding';
import ClaimBaseInfo from '../claimhandling/ClaimBaseInfo';

const CodeTableAction = require('../../action/CodeTableAction');
const CodeTableCstUI = require('../../constant/CodeTableCstUI');
const ClaimCstUI = require('../../constant/ClaimCstUI');
const ClaimLitigationAction = require('../../action/ClaimLitigationAction');
const TaskTransferAction = require('../../action/TaskTransferAction');
const LitigationRemoveConfirmDialog = require('./LitigationRemoveConfirmDialog');
const LitigationContentDialog = require('./LitigationContentDialog');
const ClaimExceptionUtils = require('../../util/ClaimExceptionUtils');
const LitigationModificationHistoryDialog = require('./LitigationModificationHistoryDialog');

const LitigationTab = React.createClass({
  mixins: [BindToMixin],

  getInitialState() {
    const localCurrency = ClaimLitigationAction.getLocalCurrency();
    const hasAuthority = ClaimLitigationAction.litigationAuthority(
      this.props.claimData.CaseId
    );
    return {
      litigationObj: { '@type': 'ClaimLitigation-ClaimLitigation' },
      enabled: false,
      isSelected: '',
      updatUI: false,
      localCurrency: { localCurrency },
      hasAuthority: { hasAuthority },
    };
  },

  showAttachTo() {
    const codeTable = [];
    const entityList = TaskTransferAction.getSubClaimNoByCaseId(
      this.props.claimData.CaseId
    );
    for (let i = 0; i < entityList.length; i += 1) {
      codeTable.push({
        id: entityList[i].ObjectId,
        text: `Subclaim ${entityList[i].SeqNo}`,
      });
    }
    return new CodeTable(codeTable);
  },

  loadLitigations() {
    const litigations = ClaimLitigationAction.loadLitigations(
      this.props.claimData.CaseId
    );
    return litigations;
  },

  rowSelected() {
    const selRecord = UIDataTable.getSelectedRecord(
      'litigationTable'
    );
    this.setState({ isSelected: [selRecord[0].dataIndex] });
    this.setState({ litigationObj: selRecord[0] });
  },
  add() {
    this.refs.LitigationContentDialog.showDialog({
      '@type': 'ClaimLitigation-ClaimLitigation',
    });
  },
  remove(event) {
    UIConfirmDialog.show('removeLitigationDialog');
    const data = event.getParameter('data');
    this.setState({ enabled: false });
    this.setState({ isSelected: data.dataIndex });
    this.setState({ litigationObj: data });
  },
  updateUI() {
    this.setState({ updatUI: true });
    this.setState({ enabled: false });
    this.setState({ isSelected: '' });
    this.setState({ litigationObj: {} });
  },
  cancel() {
    this.setState({ updatUI: false });
    this.setState({ enabled: false });
    this.setState({ isSelected: '' });
    this.setState({ litigationObj: {} });
  },
  edit(event) {
    const data = event.getParameter('data');
    this.refs.LitigationContentDialog.showDialog(data);
  },
  clickModifyHistory(event) {
    const data = {
      objectId: event.getParameter('objectId'),
      auditEventType: event.getParameter('auditEventType'),
      attachToCaseId: event.getParameter('attachToCaseId'),
    };
    this.refs.LitigationModificationHistoryDialog.showDialog(data);
  },
  buildTableFunction() {
    const { hasAuthority } = this.state;
    const buttonDisabled = !hasAuthority.hasAuthority;

    return (
      <UILink
        icon="fa fa-plus"
        size="lg"
        iconClass="add_iconbtn"
        onClick={this.add}
        visibled={hasAuthority.hasAuthority}
        disabled={buttonDisabled}
      />
    );
  },
  render() {
    const _self = this;
    const {
      isSelected,
      hasAuthority,
      litigationObj,
      enabled,
      localCurrency,
    } = this.state;
    const {
      claimEntity,
      claimData,
      updateParentTab,
      index,
      subclaimList,
    } = this.props;
    let showOrHiden = 'none';
    if (isSelected !== '') {
      showOrHiden = 'block';
    }

    const buttonDisabled = !hasAuthority.hasAuthority;
    return (
      <div id="litigationdiv" height="100%">
        <ClaimBaseInfo claimEntity={claimEntity} />
        <div id="lititablediv">
          <UIPanel panelTitle={i18n.ClaimCommon.litigationList}>
            <UIDataTable
              styleClass="primary"
              functions={this.buildTableFunction()}
              id="litigationTable"
              indexable="false"
              pageable="false"
              selectState={isSelected}
              onRowSelect={this.rowSelected}
              value={this.loadLitigations}
            >
              <UIColumn
                selectionMode="single"
                enabled="true"
                width="10%"
              />
              <UIColumn
                headerTitle={i18n.ClaimCommon.SuitName}
                value="SuitName"
                width="20%"
              />
              <UIColumn
                headerTitle={i18n.ClaimCommon.Lawyer}
                value="LawyerName"
                width="20%"
              />
              <UIColumn
                headerTitle={i18n.ClaimCommon.AttachTo}
                value="ObjectId"
                width="20%"
              >
                <UISelect codeTable={this.showAttachTo} io="out" />
              </UIColumn>
              <UIColumn
                headerTitle={i18n.ClaimCommon.LitigationStatus}
                value="SuitStatus"
                width="15%"
              >
                <UISelect
                  codeTable={CodeTableAction.loadDataList(
                    CodeTableCstUI.ClaimLitigationStatus,
                    null
                  )}
                  io="out"
                />
              </UIColumn>
              <UIColumn
                headerTitle={i18n.ClaimCommon.LitigationAction}
                width="15%"
                render={data => {
                  return (
                    <div>
                      <UILink
                        disabled={buttonDisabled}
                        icon="fa fa-pencil"
                        onClick={_self.edit}
                      >
                        <UIParam value={data} name="data" />
                      </UILink>
                      <UILink
                        disabled={buttonDisabled}
                        icon="fa fa-trash"
                        onClick={_self.remove}
                      >
                        <UIParam value={data} name="data" />
                      </UILink>
                    </div>
                  );
                }}
              />
            </UIDataTable>
          </UIPanel>
        </div>
        <div
          id="LitigationInfoDialog"
          style={{ display: showOrHiden }}
        >
          <div id="litigationEditdiv" column="1">
            <UIBox>
              <UILink
                value={i18n.ClaimCommon.ModificationHistory}
                onClick={_self.clickModifyHistory}
              >
                <UIParam
                  value={litigationObj.SuitId}
                  name="objectId"
                />
                <UIParam
                  value={ClaimCstUI.AUDIT_EVENT_TYPE_LITIGATION}
                  name="auditEventType"
                />
                <UIParam
                  value={claimData.CaseId}
                  name="attachToCaseId"
                />
              </UILink>
            </UIBox>
            <UIPanel panelTitle={i18n.ClaimCommon.litigationInfo}>
              <UICell>
                <UICell width="12">
                  <UIText
                    id="suiteNameId"
                    maxLength="200"
                    maxLengthMessage={ClaimExceptionUtils.formatI18NMsg(
                      i18n.ClaimCommon.FieldMaxLength,
                      i18n.ClaimCommon.SuitName,
                      '200'
                    )}
                    enabled={enabled}
                    widthAllocation="1,11"
                    label={i18n.ClaimCommon.SuitName}
                    valueLink={this.bindToState(
                      'litigationObj',
                      'SuitName'
                    )}
                    required="true"
                    validationGroup="litigationVal"
                  />
                </UICell>
                <UICell width="12">
                  <UISmartPanelGrid
                    id="id1"
                    layout="horizontal"
                    column="3"
                  >
                    <UISelect
                      id="suitTypeId"
                      enabled={enabled}
                      label={i18n.ClaimCommon.SuitType}
                      codeTable={CodeTableAction.loadDataList(
                        CodeTableCstUI.ClaimLitigationType,
                        null
                      )}
                      valueLink={this.bindToState(
                        'litigationObj',
                        'SuitType'
                      )}
                      required="true"
                      validationGroup="litigationVal"
                    />
                    <UISelect
                      id="AttachToId"
                      enabled={enabled}
                      label={i18n.ClaimCommon.AttachTo}
                      codeTable={this.showAttachTo}
                      valueLink={this.bindToState(
                        'litigationObj',
                        'ObjectId'
                      )}
                      required="true"
                      validationGroup="litigationVal"
                    />
                    <UISelect
                      id="StatusId"
                      enabled={enabled}
                      label={i18n.ClaimCommon.LitigationStatus}
                      codeTable={CodeTableAction.loadDataList(
                        CodeTableCstUI.ClaimLitigationStatus,
                        null
                      )}
                      valueLink={this.bindToState(
                        'litigationObj',
                        'SuitStatus'
                      )}
                      required="true"
                      validationGroup="litigationVal"
                    />
                  </UISmartPanelGrid>
                </UICell>
                <UICell width="12">
                  <UIText
                    id="SuitStatusRemarkId"
                    maxLength="500"
                    maxLengthMessage={ClaimExceptionUtils.formatI18NMsg(
                      i18n.ClaimCommon.FieldMaxLength,
                      i18n.ClaimCommon.SuitStatusRemark,
                      '500'
                    )}
                    enabled={enabled}
                    widthAllocation="1,11"
                    label={i18n.ClaimCommon.SuitStatusRemark}
                    valueLink={this.bindToState(
                      'litigationObj',
                      'SuitStatusRemark'
                    )}
                    required="true"
                    validationGroup="litigationVal"
                  />
                </UICell>
                <UICell width="12">
                  <UISmartPanelGrid
                    id="id2"
                    layout="horizontal"
                    column="3"
                  >
                    <UIText
                      id="SuitCaseNumber"
                      maxLength="200"
                      maxLengthMessage={ClaimExceptionUtils.formatI18NMsg(
                        i18n.ClaimCommon.FieldMaxLength,
                        i18n.ClaimCommon.SuitCaseNumber,
                        '200'
                      )}
                      enabled={enabled}
                      label={i18n.ClaimCommon.SuitCaseNumber}
                      valueLink={this.bindToState(
                        'litigationObj',
                        'SuitCaseNumber'
                      )}
                      validationGroup="litigationVal"
                    />
                    <UIDateTimePicker
                      enabled={enabled}
                      format="MM/DD/YYYY HH:mm:ss"
                      maxDate="TODAY"
                      id="SuitOpenDate"
                      label={i18n.ClaimCommon.SuitOpenDate}
                      valueLink={this.bindToState(
                        'litigationObj',
                        'SuitOpenDate'
                      )}
                      validationGroup="litigationVal"
                    />
                    <UICurrency
                      id="CURRENCY_CODE_AmountAppealed1"
                      maxLength="20"
                      maxLengthMessage={ClaimExceptionUtils.formatI18NMsg(
                        i18n.ClaimCommon.FieldMaxLength,
                        i18n.ClaimCommon.AmountAppealed,
                        '20'
                      )}
                      unit={window.EU00.getCurrencySignByCurrencyCode(
                        localCurrency
                      )}
                      enabled={enabled}
                      label={i18n.ClaimCommon.AmountAppealed}
                      valueLink={this.bindToState(
                        'litigationObj',
                        'AmountApealed'
                      )}
                      validationGroup="litigationVal"
                    />
                  </UISmartPanelGrid>
                </UICell>
                <UICell width="12">
                  <UIText
                    id="SuitLocation"
                    maxLength="500"
                    maxLengthMessage={ClaimExceptionUtils.formatI18NMsg(
                      i18n.ClaimCommon.FieldMaxLength,
                      i18n.ClaimCommon.SuitLocation,
                      '500'
                    )}
                    enabled={enabled}
                    widthAllocation="1,11"
                    label={i18n.ClaimCommon.SuitLocation}
                    valueLink={this.bindToState(
                      'litigationObj',
                      'SuitLocation'
                    )}
                    validationGroup="litigationVal"
                  />
                </UICell>
                <UICell width="12">
                  <UISmartPanelGrid layout="horizontal" column="3">
                    <UIText
                      id="LawyerName"
                      maxLength="200"
                      maxLengthMessage={ClaimExceptionUtils.formatI18NMsg(
                        i18n.ClaimCommon.FieldMaxLength,
                        i18n.ClaimCommon.LawyerName,
                        '200'
                      )}
                      enabled={enabled}
                      label={i18n.ClaimCommon.LawyerName}
                      valueLink={this.bindToState(
                        'litigationObj',
                        'LawyerName'
                      )}
                      validationGroup="litigationVal"
                    />
                    <UIText
                      id="LawyerTel"
                      maxLength="200"
                      maxLengthMessage={ClaimExceptionUtils.formatI18NMsg(
                        i18n.ClaimCommon.FieldMaxLength,
                        i18n.ClaimCommon.LawyerTel,
                        '200'
                      )}
                      enabled={enabled}
                      label={i18n.ClaimCommon.LawyerTel}
                      valueLink={this.bindToState(
                        'litigationObj',
                        'LawyerTel'
                      )}
                      validationGroup="litigationVal"
                    />
                    <UIEmail
                      id="LawyerEmail"
                      maxLength="200"
                      maxLengthMessage={ClaimExceptionUtils.formatI18NMsg(
                        i18n.ClaimCommon.FieldMaxLength,
                        i18n.ClaimCommon.LawyerEmail,
                        '200'
                      )}
                      enabled={enabled}
                      label={i18n.ClaimCommon.LawyerEmail}
                      valueLink={this.bindToState(
                        'litigationObj',
                        'LawyerEmail'
                      )}
                      validationGroup="litigationVal"
                    />
                  </UISmartPanelGrid>
                </UICell>
              </UICell>
            </UIPanel>
            <UIPanel
              id="litigationPanel"
              panelTitle={i18n.ClaimCommon.SuitConclusion}
            >
              <div id="litigationConclusionDiv" column="2">
                <UICell width="12">
                  <UISmartPanelGrid
                    id="id3"
                    layout="horizontal"
                    column="3"
                  >
                    <UIDateTimePicker
                      format="MM/DD/YYYY HH:mm:ss"
                      id="SuitCloseDateId"
                      maxDate="TODAY"
                      enabled={enabled}
                      label={i18n.ClaimCommon.SuitCloseDate}
                      valueLink={_self.bindToState(
                        'litigationObj',
                        'SuitCloseDate'
                      )}
                    />
                    <UICurrency
                      id="CURRENCY_CODE_DipositionAmount"
                      maxLength="20"
                      maxLengthMessage={ClaimExceptionUtils.formatI18NMsg(
                        i18n.ClaimCommon.FieldMaxLength,
                        i18n.ClaimCommon.DipositionAmount,
                        '20'
                      )}
                      unit={window.EU00.getCurrencySignByCurrencyCode(
                        localCurrency
                      )}
                      enabled={enabled}
                      label={i18n.ClaimCommon.DipositionAmount}
                      valueLink={this.bindToState(
                        'litigationObj',
                        'DepositionAmount'
                      )}
                      validationGroup="litigationVal"
                    />
                  </UISmartPanelGrid>
                </UICell>
              </div>
            </UIPanel>
          </div>
        </div>
        <LitigationRemoveConfirmDialog
          parentFunction={this.updateUI}
          litigationObject={litigationObj}
          updateParentTab={updateParentTab}
          index={index}
          CaseId={claimData.CaseId}
        />
        <LitigationContentDialog
          ref="LitigationContentDialog"
          subclaimList={subclaimList}
          parentUpdateUI={this.updateUI}
          updateParentTab={updateParentTab}
          index={index}
          CaseId={claimData.CaseId}
        />
        <LitigationModificationHistoryDialog
          ref="LitigationModificationHistoryDialog"
          domain={ClaimCstUI.AUDIT_EVENT_TYPE_LITIGATION}
        />
      </div>
    );
  },
});
export default LitigationTab;
