import ClaimSettlement from 'ClaimSettlement';
import alt from '../alt';

const SettlementLoadAction = ClaimSettlement.getSettlementLoadAction();
class SettlementReadOnlyStore {
  constructor() {
    this.SettlementReadOnly = {
      SettlementInfo: {},
      OtherInfo: {
        ReserveStructure: [],
        ReserveCodeTable: [],
        PaymentTypeCodeTable: [],
      },
    };
    this.SettlementApprove = {
      SettlementInfo: {},
      OtherInfo: {
        ReserveStructure: [],
        ReserveCodeTable: [],
        PaymentTypeCodeTable: [],
      },
    };
    this.bindListeners({
      handleLoadSettlementReadOnly:
        SettlementLoadAction.LOAD_SETTLEMENT_READ_ONLY,
    });
  }

  handleLoadSettlementReadOnly(response) {
    let updateObject = this.SettlementReadOnly;
    if (response.isApprove == true) {
      updateObject = this.SettlementApprove;
    }
    updateObject.SettlementInfo = response.data.SettlementInfo;
    updateObject.OtherInfo.ReserveStructure =
      response.data.ReserveStructure;
    updateObject.OtherInfo.ReserveCodeTable =
      response.data.ReserveCodeTable;
    updateObject.OtherInfo.PaymentTypeCodeTable =
      response.data.PaymentTypeCodeTable;
  }
}
export default alt.createStore(
  SettlementReadOnlyStore,
  SettlementReadOnlyStore
);
