/**
 * Created by jie.huang on 2019/5/6.
 */
import CodeTableTextNoValuelink from '../CodeTableTextNoValuelink';
import GroupIdSearchDialog from '../../../party/views/groupId/GroupIdSearchDialog';
import GroupIdAction from '../../../party/actions/GroupIdAction';

export default class PtyIntegrationGruopId extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      party: {},
    };
  }

  render() {
    const { party } = this.state;
    const { widthAllocation } = this.props;
    const { colspan } = this.props;
    return (
      <div>
        <CodeTableTextNoValuelink
          id="IdForPartyGroupSearch"
          label={i18n.PtyGroupID}
          model={party}
          widthAllocation={widthAllocation}
          colspan={colspan}
          property="GroupId"
          onIconClick={this.onClickGroupIdSearch.bind(this)}
          onChange={this.onChangeGroupId.bind(this)}
        />
        <GroupIdSearchDialog
          ref="refIndiGroupIdSearchDialog"
          customerChose={this.onGetGroupInfoFromPopup.bind(this)}
        />
      </div>
    );
  }

  onClickGroupIdSearch() {
    this.refs.refIndiGroupIdSearchDialog.showDialog();
  }

  onGetGroupInfoFromPopup(groupInfo) {
    const _self = this;
    if (groupInfo) {
      const { party } = this.state;
      party.GroupId = groupInfo.GroupId;
      $("input[name='IdForPartyGroupSearch']").val(groupInfo.GroupId);
      $("input[name='IdForPartyGroupSearch_value']").val(
        groupInfo.GroupName
      );
      $("textarea[name='IdForPartyGroupDesc']").val(
        groupInfo.Description
      );
      _self.props.onCallBack(groupInfo);
    }
  }

  onChangeGroupId(event) {
    const { newValue } = event;
    const _self = this;
    if (newValue) {
      const groupIdAction = new GroupIdAction();
      const groupInfo = groupIdAction.searchByGroupId(newValue);
      if (groupInfo) {
        $("input[name='IdForPartyGroupSearch']").val(
          groupInfo.GroupId
        );
        $("input[name='IdForPartyGroupSearch_value']").val(
          groupInfo.GroupName
        );
      } else {
        $("input[name='IdForPartyGroupSearch_value']").val(null);
      }
      _self.props.onChangeGroupId(groupInfo);
    } else {
      $("input[name='IdForPartyGroupSearch_value']").val(null);
      _self.props.onChangeGroupId();
    }
  }
}
