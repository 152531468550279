import {
  UIDialog,
  UIDialogFooter,
  UIPanel,
  UIButton,
  UIBox,
} from 'RainbowUI';
import {
  ClaimHandlingAction,
  PolicyAction,
  ClaimTaskTransferDialog,
  ReserveAction,
} from 'ClaimCommon';
import ClaimRegistration from 'ClaimRegistration';
import {
  PartyConst,
  PtyIntegrationSearchDialog,
  PtyIntegrationThirdParty,
} from 'EU00PartyCommonUI';
import ConflictSubclaimList from './ConflictSubclaimList';

const ClaimInfo = ClaimRegistration.getClaimInfo();
const ModifyClaimInfoDialog = React.createClass({
  getInitialState() {
    return {
      oldCauseOfLoss: this.props.data.LossCause,
      checkResult: {},
    };
  },

  componentWillReceiveProps() {
    if (!_.isEmpty(this.state.checkResult.conflictedSubclaims)) {
      this.setState({ checkResult: {} });
    }
  },

  getParentComponent() {
    return this;
  },

  submitModify() {
    this.refs.ClaimInfo_ref.state.caseEntity.AccidentTown = this.refs.ClaimInfo_ref.state.caseEntity.Area;
    this.refs.ClaimInfo_ref.state.caseEntity.AccidentStreet = this.refs.ClaimInfo_ref.state.caseEntity.StreetName;
    this.refs.ClaimInfo_ref.state.caseEntity.PostalCode = this.refs.ClaimInfo_ref.state.caseEntity.PostCode;

    delete this.refs.ClaimInfo_ref.state.caseEntity.Area;
    delete this.refs.ClaimInfo_ref.state.caseEntity.StreetName;
    delete this.refs.ClaimInfo_ref.state.caseEntity.PostCode;
    const data = {
      AccidentTime: this.refs.ClaimInfo_ref.state.caseEntity
        .AccidentTime,
      NoticeTime: this.refs.ClaimInfo_ref.state.caseEntity.NoticeTime,
      RegistrationTime: this.refs.ClaimInfo_ref.state.caseEntity
        .RegisterTime,
    };
    const claimCaseEntity = this.refs.ClaimInfo_ref.state.caseEntity;
    if (
      Array.isArray(
        this.refs.ClaimInfo_ref.state.caseEntity.UnpaidReasonCode
      )
    ) {
      const unpaidReason = JSON.stringify(
        this.refs.ClaimInfo_ref.state.caseEntity.UnpaidReasonCode
      );
      claimCaseEntity.UnpaidReasonCode = unpaidReason;
    }

    if (
      Array.isArray(
        this.refs.ClaimInfo_ref.state.caseEntity.UnpaidResReasonCode
      )
    ) {
      const unpaidReason = JSON.stringify(
        this.refs.ClaimInfo_ref.state.caseEntity.UnpaidResReasonCode
      );
      claimCaseEntity.UnpaidResReasonCode = unpaidReason;
    }
    const checkCauseOfLoss = () => {
      return ClaimHandlingAction.checkCauseOfLoss(
        this.state.oldCauseOfLoss,
        this.refs.ClaimInfo_ref.state.caseEntity
      );
    };
    const tackleCheckResult = conflictObj => {
      if (conflictObj.objectIdList) {
        this.setState({ checkResult: conflictObj });
      } else {
        ClaimHandlingAction.modifyClaimSubmit(this.getCaseEntity());
      }
    };
    if (PolicyAction.checkDatePass(data)) {
      const conflictObj = checkCauseOfLoss();
      tackleCheckResult(conflictObj);
    }
  },

  continueSubmitModify() {
    if (
      ClaimHandlingAction.allowToCloseSubclaim(
        this.state.checkResult.objectIdList
      )
    ) {
      ClaimHandlingAction.modifyClaimSubmit(this.getCaseEntity());
      const storObjectId = sessionStorage.getItem('claim_objectId');
      if (storObjectId != null && storObjectId !== '') {
        ReserveAction.initReserve(storObjectId);
      }
    }
  },

  getCaseEntity() {
    const { caseEntity } = this.refs.ClaimInfo_ref.state;
    caseEntity.ConflictedObjectIdList = '';
    if (this.state.checkResult.objectIdList) {
      caseEntity.ConflictedObjectIdList = this.state.checkResult.objectIdList;
    }
    if (caseEntity.cityCodeTable) {
      delete caseEntity.cityCodeTable;
    }
    if (caseEntity.countryCodeTable) {
      delete caseEntity.countryCodeTable;
    }
    if (caseEntity.townCodeTable) {
      delete caseEntity.townCodeTable;
    }
    if (caseEntity.regions) {
      delete caseEntity.regions;
    }
    if (caseEntity.postalCodeTable) {
      delete caseEntity.postalCodeTable;
    }
    if (caseEntity.streetCodeTable) {
      delete caseEntity.streetCodeTable;
    }
    if (!caseEntity.IsMotor) {
      delete caseEntity.IsMotor;
    }
    return caseEntity;
  },

  causeOfLossChanged() {
    if (!_.isEmpty(this.state.checkResult.conflictedSubclaims)) {
      this.setState({ checkResult: {} });
    }
  },

  render() {
    const _self = this;
    _self.props.data.Area = _self.props.data.AccidentTown;
    _self.props.data.StreetName = _self.props.data.AccidentStreet;
    const prefixId = '02';
    return (
      <div>
        <UIDialog
          id="ModifyClaimInfo_Dialog"
          title={i18n.ClaimCommon.ModifyClaimInfo}
          width="95%"
          heigth="800px"
        >
          <UIPanel
            panelTitle={i18n.ClaimCommon.ModifyClaimInfo}
            styleClass="default"
          >
            <ClaimInfo
              prefixId={prefixId}
              ref="ClaimInfo_ref"
              getObjectList={() => _self.props.data.ObjectList || []}
              data={_self.props.data}
              from="modifyClaim"
              parentComponent={this}
              authMap={_self.props.authMap}
              forModify="true"
            />
          </UIPanel>
          {this.getConflictSubclaimList(prefixId)}
        </UIDialog>
        <PtyIntegrationSearchDialog
          ref="partySearchDialogForFPDriverForModify_Ref"
          id="partySearchDialogForFPDriverForModify"
          isSearch
          getSearchResult={this.getFirstPartyDriver}
          isVisibled
          isSearchRole
          title={i18n.SearchDriver}
          onHide={this.onHide}
          createClicked={this.createNewFirstParty}
          RoleName={PartyConst.PARTY_ROLE_NAME_DRIVER}
          enableChangePartyCategory="N"
        />
        <PtyIntegrationThirdParty
          id="fpDriverCreationDialogForModify"
          onClickSubmit={this.onClickSubmit}
          onClickBack={this.onClickBack}
        />
        <ClaimTaskTransferDialog ref="ClaimTaskTransferDialog" />
      </div>
    );
  },

  getConflictSubclaimList(prefixId) {
    const {
      checkResult: { objectIdList, conflictedSubclaims },
    } = this.state;
    if (objectIdList) {
      const message = i18n.ClaimCommon.ConflictedSubclaimMessage;
      return (
        <div>
          <ConflictSubclaimList dataList={conflictedSubclaims} />
          <UIDialogFooter>
            <UIButton
              value={i18n.ClaimCommon.Submit}
              onClick={this.continueSubmitModify}
              causeValidation="true"
              validationGroup={`${prefixId}RegistrationInfo`}
            />
            <UIButton
              value={i18n.ClaimCommon.Cancel}
              onClick={this.cancel}
            />
          </UIDialogFooter>
          <ClaimTaskTransferDialog ref="ClaimTaskTransferDialog" />
        </div>
      );
    }
    return (
      <UIBox direction="center">
        <UIButton
          value={i18n.ClaimCommon.Submit}
          onClick={this.submitModify}
          causeValidation="true"
          validationGroup={`${prefixId}RegistrationInfo`}
        />
        <UIButton
          value={i18n.ClaimCommon.Cancel}
          onClick={this.cancel}
        />
      </UIBox>
    );
  },

  getFirstPartyDriver(party) {
    const claimEntity = this.props.data;
    claimEntity.FpDriverName = party.PartyName;
    claimEntity.FpDriverId = party.PartyId;
    claimEntity.IdNumber = party.IdNumber;
    claimEntity.FpTelNo = party.MobileTel;
    claimEntity.FpDriverBirthDate = party.DateOfBirth;
    UIDialog.show('ModifyClaimInfo_Dialog');
  },

  cancel() {
    UIDialog.hide('ModifyClaimInfo_Dialog');
  },

  createNewFirstParty() {
    UIDialog.hide('partySearchDialogForFPDriverForModify');
    UIDialog.show('fpDriverCreationDialogForModify');
  },

  onClickBack() {
    this.refs.partySearchDialogForFPDriverForModify_Ref.show();
  },

  onHide() {
    UIDialog.show('ModifyClaimInfo_Dialog');
  },

  onClickSubmit(party) {
    const claimEntity = this.props.data;
    claimEntity.FpDriverName = party.PartyName;
    claimEntity.FpDriverId = party.PartyId;
    claimEntity.IdNumber = party.IdNumber;
    claimEntity.FpTelNo = party.MobileTel;
    claimEntity.FpDriverBirthDate = party.DateOfBirth;
    UIDialog.show('ModifyClaimInfo_Dialog');
  },
});
export default ModifyClaimInfoDialog;
