/**
* Created by jack.li on 10/30/2017.
*/
import {
  MessageHelper,
  UIBox,
  UIButton,
  UICell,
  UIDialog,
  UISmartPanelGrid,
  UIText,
  UISelect,
  UIEmail,
  UICheckbox,
} from 'RainbowUI';
import PartyAction from '../../../party/actions/PartyAction';
import DefinedCodeTable from '../../../party/codetable/CodeTable';
import PartyConst from '../../../party/const/PartyConst';

const PtyIntegrationCreationDialog = React.createClass({
  getInitialState() {
    let pr = this.props.RoleName == PartyConst.PARTY_ROLE_NAME_CLAIMANT ? PartyConst.PARTY_ROLE_CODE_IND_CLAIMANT : '';
    return {
      Party: {},
      PartyType: PartyConst.PARTY_TYPE_IND,
      RoleName: this.props.RoleName,
      PartyContact: {},
      partyRole: pr,
    };
  },

  showPartyCreateDialog() {
    const Party = {};
    Party.PartyType = PartyConst.PARTY_TYPE_IND;
    this.setState({ Party, RoleName: this.props.RoleName });
    UIDialog.show(this.props.id);
  },
  render() {
    const { PartyType } = this.state;
    const { id } = this.props;
    const partyType = PartyType;
    let createCustomerRender;
    if (partyType == PartyConst.PARTY_TYPE_IND) {
      createCustomerRender = this.createIndiviPartyRender();
    } else if (partyType == PartyConst.PARTY_TYPE_ORG) {
      createCustomerRender = this.createOrgPartyRender();
    } else if (partyType == PartyConst.PARTY_TYPE_VEN) {
      createCustomerRender = this.createVenPartyRender();
    }
    return (
      <div>
        <UIDialog title={i18n.CreateParty} width="80%" id={id}>
          {createCustomerRender}
        </UIDialog>
      </div>
    );
  },
  createNewParty(event) {
    const party = this.state.Party;
    const partyType = this.state.PartyType;
    let { partyRole } = this.state;

    if (partyType == PartyConst.PARTY_TYPE_IND) {
      let partyName;
      if (party.FirstName) {
        partyName = party.FirstName;
      }
      if (party.LastName) {
        partyName = partyName
          ? `${partyName} ${party.LastName}`
          : party.LastName;
      }
      party.PartyName = partyName;
    }

    const partyAction = new PartyAction();
    const result = partyAction.createIntegrationParty(
      party,
      partyType,
      partyRole
    );

    const partyContact = this.state.PartyContact;

    if (
      (partyType == PartyConst.PARTY_TYPE_ORG ||
        partyType == PartyConst.PARTY_TYPE_VEN) &&
      partyContact &&
      result.status === 'success'
    ) {
      result.data = partyAction.createPartyContact(
        result.data,
        partyContact
      );
    }
    if (this.props.parentDialog) {
      this.props.parentDialog.Continue(null, result.data);
      UIDialog.hide(this.props.id);
      this.props.parentDialog.hideDialog();
      return;
    }

    if (this.callBack) {
      this.callBack(result);
      UIDialog.hide(this.props.id);
      return;
    }

    if (result.status === 'success') {
      // if (
      //   this.state.RoleName == PartyConst.PARTY_ROLE_NAME_CUSTOMER
      // ) {
      //   result.data = partyAction.createCustomerRole(result.data);
      // } else if (
      //   this.state.RoleName == PartyConst.PARTY_ROLE_NAME_CLAIMANT
      // ) {
      //   result.data = partyAction.createClaimAntRole(result.data);
      // } else if (
      //   this.state.RoleName == PartyConst.PARTY_ROLE_NAME_PAYER
      // ) {
      //   result.data = partyAction.createPayerRole(result.data);
      // } else if (
      //   this.state.RoleName == PartyConst.PARTY_ROLE_NAME_INTRODUCER
      // ) {
      //   result.data = partyAction.createIntroducerRole(result.data);
      // } else if (
      //   this.state.RoleName == PartyConst.PARTY_ROLE_NAME_DRIVER
      // ) {
      //   result.data = partyAction.createDriverRole(result.data);
      // } else if (
      //   this.state.RoleName == PartyConst.PARTY_ROLE_NAME_EMPLOYEE
      // ) {
      //   result.data = partyAction.createEmployeeRole(result.data);
      // }
      UIDialog.hide(this.props.id);
      this.props.ptyIntegrationCreated(result.data);
      this.setState({
        Party: {},
        PartyType: PartyConst.PARTY_TYPE_IND,
        PartyContact: {},
      });
    } else {
      MessageHelper.error(
        result.data,
        '',
        MessageHelper.POSITION_TOP_RIGHT
      );
    }
  },
  handleCloseDialog() {
    UIDialog.hide(this.props.id);
    this.setState({
      Party: {},
      PartyType: PartyConst.PARTY_TYPE_IND,
      PartyContact: {},
    });
  },
  createOrgPartyRender() {
    const _self = this;
    const { Party, PartyContact, MobileStatus, RoleName } = this.state;
    const party = Party;
    const partyContact = PartyContact;

    return (
      <div>
        <UISmartPanelGrid column="2">
          <UISelect
            label={i18n.PartyCategory}
            codeTable={DefinedCodeTable.getCategroyCodeTable}
            required="true"
            widthAllocation="5,7"
            showBlankOption="false"
            onChange={_self.changePartyCategory}
            value={_self.getCategory}
          />
          <UISelect
            label={i18n.PartyRole}
            codeTable={DefinedCodeTable.getOrgPartyRoleClaim}
            widthAllocation="5,7"
            required="true"
            value={_self.getPartyRole}
            disabled={RoleName == PartyConst.PARTY_ROLE_NAME_CLAIMANT}
            validationGroup="createIntegrationParty"
            onChange={_self.changePartyRole}
          />
          <UIText
            label={i18n.CompanyNo}
            required="true"
            model={party}
            property="OrganizationIdNumber"
            widthAllocation="5,7"
            validationGroup="createIntegrationParty"
          />
          <UIText
            label={i18n.CompanyName}
            model={party}
            property="PartyName"
            required="true"
            widthAllocation="5,7"
            validationGroup="createIntegrationParty"
          />
        </UISmartPanelGrid>

        <UISmartPanelGrid column="3">
          <UICheckbox
            single="true"
            singleLabel={i18n.PrimaryContact}
            model={partyContact}
            property="IsPrimaryContact"
            defaultValue="Y"
            showBlankOption="false"
          />
          <UICell />
          <UICell />
          <UIText
            label={i18n.FirstName}
            model={partyContact}
            property="FirstName"
            required="true"
            validationGroup="createIntegrationParty"
          />
          <UIText
            label={i18n.MiddleName}
            model={partyContact}
            property="MiddleName"
          />
          <UIText
            label={i18n.LastName}
            model={partyContact}
            property="LastName"
            required="true"
            validationGroup="createIntegrationParty"
          />
          <UIText
            label={i18n.MobileTel}
            model={partyContact}
            property="HandPhone"
            required={MobileStatus}
            validationGroup="UpdateContactInfo"
          />
          <UIEmail
            label={i18n.email}
            model={partyContact}
            property="Email"
            validationGroup="createIntegrationParty"
          />
          <UIText
            label={i18n.WorkTel}
            model={partyContact}
            property="BusinessTel"
          />
        </UISmartPanelGrid>
        <UIBox direction="center">
          <UIButton
            value={i18n.Save}
            onClick={this.createNewParty}
            validationGroup="createIntegrationParty"
            causeValidation="true"
          />
          <UIButton
            value={i18n.Cancel}
            onClick={this.handleCloseDialog}
          />
        </UIBox>
      </div>
    );
  },
  createVenPartyRender() {
    const _self = this;
    const { Party, PartyContact, MobileStatus } = this.state;
    const party = Party;
    const partyContact = PartyContact;
    return (
      <div>
        <UISmartPanelGrid column="2">
          <UISelect
            label={i18n.PartyCategory}
            codeTable={DefinedCodeTable.getCategroyCodeTable}
            required="true"
            widthAllocation="5,7"
            showBlankOption="false"
            onChange={_self.changePartyCategory}
            value={_self.getCategory}
          />
          <UICell />
          <UIText
            label={i18n.JointVentureName}
            model={party}
            property="JointVentureName"
            required="true"
            widthAllocation="5,7"
            validationGroup="createIntegrationParty"
          />
        </UISmartPanelGrid>

        <UISmartPanelGrid column="3">
          <UICheckbox
            single="true"
            singleLabel={i18n.PrimaryContact}
            model={partyContact}
            property="IsPrimaryContact"
            defaultValue="Y"
            showBlankOption="false"
          />
          <UICell />
          <UICell />
          <UIText
            label={i18n.FirstName}
            model={partyContact}
            property="FirstName"
            required="true"
            validationGroup="createIntegrationParty"
          />
          <UIText
            label={i18n.MiddleName}
            model={partyContact}
            property="MiddleName"
          />
          <UIText
            label={i18n.LastName}
            model={partyContact}
            property="LastName"
            required="true"
            validationGroup="createIntegrationParty"
          />
          <UIText
            label={i18n.MobileTel}
            model={partyContact}
            property="HandPhone"
            required={MobileStatus}
            validationGroup="UpdateContactInfo"
          />
          <UIEmail
            label={i18n.email}
            model={partyContact}
            property="Email"
            validationGroup="createIntegrationParty"
          />
          <UIText
            label={i18n.WorkTel}
            model={partyContact}
            property="BusinessTel"
          />
        </UISmartPanelGrid>
        <UIBox direction="center">
          <UIButton
            value={i18n.Save}
            onClick={this.createNewParty}
            validationGroup="createIntegrationParty"
            causeValidation="true"
          />
          <UIButton
            value={i18n.Cancel}
            onClick={this.handleCloseDialog}
          />
        </UIBox>
      </div>
    );
  },
  changePartyCategory(event) {
    const partyType = event.newValue;
    let pr = '';
    if (this.state.RoleName == PartyConst.PARTY_ROLE_NAME_CLAIMANT) {
      if (PartyType = PartyConst.PARTY_TYPE_ORG) {
        pr = PartyConst.PARTY_ROLE_CODE_ORG_CLAIMANT;
      } else {
        pr = PartyConst.PARTY_ROLE_CODE_IND_CLAIMANT;
      }
    }
    this.setState({
      PartyType: partyType,
      Party: {},
      PartyContact: {},
      partyRole: pr,
    });
  },

  changePartyRole(event) {
    this.setState({ partyRole: event.newValue });
  },

  getCategory() {
    const partyType = this.state.PartyType;
    return partyType;
  },

  getPartyRole() {
    return this.state.partyRole;
  },

  createIndiviPartyRender() {
    const _self = this;
    const { RoleName: roleName, Party } = this.state;
    const { RoleName } = this.props;
    let disabled = false;
    if (
      undefined !== roleName &&
      (roleName == PartyConst.PARTY_ROLE_NAME_DRIVER ||
        RoleName == PartyConst.PARTY_ROLE_NAME_EMPLOYEE)
    ) {
      disabled = true;
    }

    return (
      <div>
        <UISmartPanelGrid column="2">
          <UISelect
            label={i18n.PartyCategory}
            codeTable={DefinedCodeTable.getCategroyCodeTable}
            widthAllocation="5,7"
            required="true"
            disabled={disabled}
            showBlankOption="false"
            value={_self.getCategory}
            onChange={_self.changePartyCategory}
          />
          <UISelect
            label={i18n.PartyRole}
            codeTable={DefinedCodeTable.getIndPartyRoleClaim}
            widthAllocation="5,7"
            required="true"
            value={_self.getPartyRole}
            disabled={disabled || roleName == PartyConst.PARTY_ROLE_NAME_CLAIMANT}
            validationGroup="createIntegrationParty"
            onChange={_self.changePartyRole}
          />
          <UIText
            label={i18n.FirstName}
            required="true"
            model={Party}
            property="FirstName"
            widthAllocation="5,7"
            validationGroup="createIntegrationParty"
          />
          <UIText
            label={i18n.LastName}
            required="true"
            model={Party}
            property="LastName"
            widthAllocation="5,7"
            validationGroup="createIntegrationParty"
          />
          <UISelect
            label={i18n.IdType}
            widthAllocation="5,7"
            codeTable={DefinedCodeTable.IdType}
            required="true"
            model={Party}
            property="IdType"
            validationGroup="createIntegrationParty"
          />
          <UIText
            label={i18n.IdNo}
            widthAllocation="5,7"
            required="true"
            model={Party}
            property="IdNumber"
            validationGroup="createIntegrationParty"
          />
          <UIEmail
            label={i18n.email}
            model={Party}
            property="Email"
            widthAllocation="5,7"
            validationGroup="createIntegrationParty"
          />
          <UIText
            label={i18n.MobileTel}
            model={Party}
            property="MobileTel"
            widthAllocation="5,7"
            validationGroup="createIntegrationParty"
          />
        </UISmartPanelGrid>
        <UIBox direction="center">
          <UIButton
            value={i18n.Save}
            onClick={this.createNewParty}
            validationGroup="createIntegrationParty"
            causeValidation="true"
          />
          <UIButton
            value={i18n.Cancel}
            onClick={this.handleCloseDialog}
          />
        </UIBox>
      </div>
    );
  },
});
export default PtyIntegrationCreationDialog;
