import { MessageHelper } from 'RainbowUI';
import ClaimCommonExt from 'ClaimCommonExt';
import alt from '../alt';

import SolrService from '../service/SolrService';
import PolicyService from '../service/PolicyService';
import CodeTableAction from './CodeTableAction';

const PolicyForm = require('../mock/policy/PolicyForm');

class PolicyAction {
  searchPolicy(policySearchOptions) {
    this.dispatch(policySearchOptions);
  }

  getPolicyList(pageIndex, pageSize, SearchOptions) {
    const PolicyActionProcess = ClaimCommonExt.getPolicyActionProcess();

    return PolicyActionProcess.getPolicyList(
      pageIndex,
      pageSize,
      SearchOptions
    );
  }

  getLoginUserAgentCode() {
    let AgentCode = null;
    const result = PolicyService.getLoginUserAgentCode();
    if (result && result.Status === 'OK') {
      AgentCode = result.Model;
    }
    return AgentCode;
  }

  getPolicyListData(pageIndex, pageSize, SearchOptions) {
    if (SearchOptions) {
      const searchResult = {
        total: 10,
        result: PolicyForm.PolicyList,
      };
      return {
        count: searchResult.total,
        result: searchResult.result,
      };
    }

    let result = {};
    let total = {};
    const Conditions = !SearchOptions.policySearchOptions
      ? {}
      : SearchOptions.policySearchOptions;
    const FuzzyConditions = !SearchOptions.FuzzyConditions
      ? {}
      : SearchOptions.FuzzyConditions;
    const pageInd = Math.ceil(pageIndex / pageSize);
    if (Conditions.ProductId) {
      Conditions.ProductId = parseInt(Conditions.ProductId, 10);
    }
    const searchCondition = {
      Conditions,
      PageNo: pageInd,
      PageSize: pageSize,
      FuzzyConditions,
      Module: 'Policy',
    };

    SolrService.queryEntitySolr(searchCondition).then(data => {
      if (data.Total === 0) {
        result = null;
        total = 0;
      } else {
        result = data.Results[0].SolrDocs;
        total = data.Total;
      }
    });

    return { count: total, result };
  }

  checkPolicy(retrieveInfo, tmpPolicyEntity, InsuredList) {
    const PolicyEntity = tmpPolicyEntity;
    let flag = 'OK';
    const data = {};
    data.DateOfLoss = retrieveInfo.AccidentTime;
    data.DateOfNotification = retrieveInfo.NoticeTime;
    data.DateOfReported = retrieveInfo.DateReportedInsured;
    if (retrieveInfo.ClaimsMade === 'Y') {
      data.IsClaimsMade = retrieveInfo.ClaimsMade;
    } else {
      data.IsClaimsMade = 'N';
    }
    data.InsuredList = InsuredList;
    const user = CodeTableAction.renderUser();
    if (PolicyEntity.PermitOrgId && user.DirectUser !== 'Y') {
      const branchList = PolicyEntity.PermitOrgId.split(',');
      if (branchList.length > 1) {
        let isvalidate = false;
        for (let i = 1; i < branchList.length - 1; i += 1) {
          if (branchList[i] == user.OrgId) {
            isvalidate = true;
          }
        }
        if (!isvalidate) {
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMsg
              .UserHasNoAuthorityToRetrievePolicyPleaseCheck
          );
          return 'BLOCK';
        }
      }
    }
    delete PolicyEntity.PermitOrgId;
    data.Policy = PolicyEntity;
    PolicyService.checkPolicy(data).then(
      data => {
        const status = data.Status;
        if (status === 'BLOCK') {
          MessageHelper.error(data.Messages[0].Message);
          flag = 'BLOCK';
        }
        if (status === 'WARN') {
          flag = 'WARN';
        }
      },
      err => {
        if (err.responseJSON) {
          if (err.responseJSON.Messages) {
            MessageHelper.error(
              err.responseJSON.Messages['0'].Message
            );
          }
        }
        flag = 'BLOCK';
      }
    );
    return flag;
  }

  retrievePolicy(policyNo) {
    const policyInfo = this.actions.copyPolicy({
      PolicyNo: policyNo,
      AccidentTime: '2016-05-23T00:00:00',
    });

    this.dispatch(policyInfo);
    return policyInfo;
  }

  getParamId() {
    let policyNo = '';
    const urlParam = window.location.hash;
    const begin = urlParam.indexOf('?');
    const paramStr = urlParam.substr(begin + 1);
    const paramArray = paramStr.split('&');
    for (let i = 0; i < paramArray.length; i += 1) {
      if (paramArray[i].split('=')[0] === 'policyNo') {
        policyNo = paramArray[i].split('=')[1];
        break;
      }
    }
    return { PolicyNo: policyNo };
  }

  checkPolicyNo(policyNo) {
    let flag = true;
    PolicyService.checkPolicyNo(policyNo).then(
      data => {
        if (!data.Model) {
          flag = false;
          MessageHelper.error(i18n.ClaimCommon.NoPolicy);
        }
      },
      err => {
        MessageHelper.error(i18n.ClaimCommon.NoPolicy);
        console.error(err);
        flag = false;
      }
    );
    return flag;
  }

  checkDatePass(data) {
    return true;
  }

  copyPolicy(retrieveInfo) {
    const policyNo = retrieveInfo.PolicyNo;
    let policyDate = retrieveInfo.AccidentTime;
    if (retrieveInfo.ClaimsMade === 'Y') {
      policyDate = retrieveInfo.DateReportedInsured;
    }
    let PolicyEntity = null;
    PolicyService.retrieve(policyNo, policyDate).then(
      data => {
        PolicyEntity = data.Model;

        if (PolicyEntity) {
          PolicyEntity.PolicyNo = retrieveInfo.PolicyNo;
          PolicyEntity.AccidentTime = retrieveInfo.AccidentTime;
          PolicyEntity.NoticeTime = retrieveInfo.NoticeTime;
        }
      },
      err => {}
    );
    this.dispatch(PolicyEntity);
    return PolicyEntity;
  }

  copyPolicyProduct(retrieveInfo) {
    const policyNo = retrieveInfo.PolicyNo;
    let policyDate = retrieveInfo.AccidentTime;
    if (retrieveInfo.ClaimsMade === 'Y') {
      policyDate = retrieveInfo.DateReportedInsured;
    }
    let PolicyEntity = null;
    PolicyService.retrieveProduct(policyNo, policyDate).then(
      data => {
        PolicyEntity = data.Model;
        if (
          PolicyEntity ===
          'i18n.ClaimCommon.Error.ValidateNPCDuration'
        ) {
          return PolicyEntity;
        }
        if (PolicyEntity && PolicyEntity.length > 0) {
          for (let i = 0; i < PolicyEntity.length; i += 1) {
            if (PolicyEntity[i].ProductName === 'Liability') {
              const insuredsOld = PolicyEntity[i].InsuredList;
              if (insuredsOld && insuredsOld.length > 0) {
                const insuredsNew = [];
                for (let j = 0; j < insuredsOld.length; j += 1) {
                  if (insuredsOld[j].InsuredName) {
                    insuredsNew.push(insuredsOld[j]);
                  }
                }
                PolicyEntity[i].InsuredList = insuredsNew;
              }
            }
          }
        }
      },
      err => {
        if (err.status !== 200) {
          /* empty */
        }
      }
    );
    this.dispatch(PolicyEntity);
    return PolicyEntity;
  }

  copyPolicyByProduct(retrieveInfo, productRisk) {
    const policyNo = retrieveInfo.PolicyNo;
    let policyDate = retrieveInfo.AccidentTime;
    if (retrieveInfo.ClaimsMade === 'Y') {
      policyDate = retrieveInfo.DateReportedInsured;
    }
    let PolicyEntity = null;
    const params = {
      PolicyNo: policyNo,
      PolicyDate: policyDate,
      ProductList: productRisk,
    };
    PolicyService.retrieveByProduct(params).then(
      data => {
        PolicyEntity = data.Model;

        if (PolicyEntity) {
          PolicyEntity.AccidentTime = retrieveInfo.AccidentTime;
          PolicyEntity.NoticeTime = retrieveInfo.NoticeTime;
        }
      },
      err => {
        if (err.status !== 200) {
          MessageHelper.error(
            err.statusText,
            i18n.ClaimCommon.ErrorMsg.QueryFail,
            MessageHelper.POSITION_TOP_FULL_WIDTH
          );
        }
      }
    );
    this.dispatch(PolicyEntity);
    return PolicyEntity;
  }

  copyPolicyByProductRisk(policyNo, policyDate, productCode) {
    let productList = null;
    const productRisk = [];
    productRisk.push({ ProductCode: productCode });
    const params = {
      PolicyNo: policyNo,
      PolicyDate: policyDate,
      ProductList: productRisk,
    };
    PolicyService.retrieveByProductRisk(params).then(
      data => {
        productList = data.Model;
      },
      err => {
        if (err.status !== 200) {
          MessageHelper.error(
            err.statusText,
            i18n.ClaimCommon.ErrorMsg.QueryFail,
            MessageHelper.POSITION_TOP_FULL_WIDTH
          );
        }
      }
    );
    return productList;
  }

  policySearchFlag() {
    let result = true;
    PolicyService.policySearchFlag().then(
      data => {
        result = data;
      },
      err => {
        if (err.status !== 200) {
          /* empty */
        }
      }
    );
    return result;
  }

  policyCollsFlag() {
    let result = true;
    PolicyService.policyCollsFlag().then(
      data => {
        result = data;
      },
      err => {
        if (err.status !== 200) {
          /* empty */
        }
      }
    );
    return result;
  }

  isChildProduct(packageProductId, childProductId) {
    let result = false;
    PolicyService.isChildProduct(
      packageProductId,
      childProductId
    ).then(
      data => {
        result = data;
      },
      err => {
        if (err.status !== 200) {
          result = false;
        }
      }
    );
    return result;
  }

  checkPendingEndoByPolicyNo(policyNo) {
    // TO DO INT
    /*
    const result = PolicyService.checkPendingEndoByPolicyNo(policyNo);
    if (!result || result.Status !== 'OK') {
      MessageHelper.error(
        err.statusText,
        i18n.ClaimCommon.ErrorMsg.QueryFail,
        MessageHelper.POSITION_TOP_FULL_WIDTH
      );
      return true;
    }
    return result.Model;
    */
    return false;
  }
}

export default alt.createActions(PolicyAction);
