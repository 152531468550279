import {
  UITwoText,
  UISelect,
  UIButton,
  UIDateTimePicker,
  UIText,
  UISmartPanelGrid,
  CodeTable,
  UITextarea,
} from 'RainbowUI';
import { CodeTableAction, CodeTableCstUI } from 'ClaimCommonConstant';
import React from 'react';
import BindToMixin from 'react-binding';
import { ClaimHandlingStore, RejectDialog } from 'ClaimCommon';
import ClaimRegistration from 'ClaimRegistration';
import ModifySubclaimInfoDialog from './ModifySubclaimInfoDialog';
import ClaimFraudIndicatorDialog from './ClaimFraudIndicatorDialog';
import MigrationDriverRemark from './MigrationDriverRemark';
import FirstPartyDriverInfo from './FirstPartyDriverInfo';

const RegistrationPolicyDialog = ClaimRegistration.getRegistrationPolicyDialog();
const ModifyClaimInfoDialog = ClaimRegistration.getModifyClaimInfoDialog();
const ClaimPoliceInfo = require('./ClaimPoliceInfo');

const ClaimInfoRead = React.createClass({
  mixins: [BindToMixin],

  getInitialState() {
    return { caseEntity: this.props.data };
  },

  componentWillMount() {
    ClaimHandlingStore.listen(this.onStoreStateChange);
    this.formatDateTime();
  },

  componentWillUnmount() {
    ClaimHandlingStore.unlisten(this.onStoreStateChange);
  },

  onStoreStateChange(storeState) {
    if (!_.isEmpty(storeState.caseForm)) {
      this.setState({ caseEntity: storeState.caseForm.ClaimEntity });
    }
  },

  retrievePolicy() {
    this.refs.RegistrationPolicyDialog_ref.showDialog();
  },

  withOutPolicy() {
    if (
      !this.state.caseEntity.PolicyEntity.PolicyNo &&
      this.props.authMap.MODIFY_CLAIM_INFO
    ) {
      return 'in';
    }
    return 'out';
  },
  clickReview() {
    const RegistrationHandlingAction = ClaimRegistration.getClaimRegistrationHandlingAction();
    const registrationHandlingAction = new RegistrationHandlingAction();
    registrationHandlingAction.saveLastReviewClaim(
      this.state.caseEntity.CaseId
    );
  },
  createStreetNameCodeTable(_self, baseAddress) {
    let streetNameCodeTable = new CodeTable([]);
    if (baseAddress.AddressVo.State) {
      streetNameCodeTable = CodeTableAction.geRegion(
        baseAddress.AddressVo.Country,
        5,
        baseAddress.AddressVo.State
      );
      const result = [];
      const filterList = [];
      _.each(streetNameCodeTable, mapObj => {
        if (!_.contains(filterList, mapObj.Id)) {
          filterList.push(mapObj.Id);
          const v = { id: mapObj.Id, text: mapObj.Description };
          result.push(v);
        }
      });
      streetNameCodeTable = new CodeTable(result);
    }
    return streetNameCodeTable;
  },
  createAreaCodeTable(self, baseAddress) {
    const _self = self;
    let areaCodeTable = new CodeTable([]);
    if (
      !this.state.caseEntity.AccidentCity
        ? this.state.caseEntity.AddressVo.City
        : this.state.caseEntity.AccidentCity
    ) {
      areaCodeTable = CodeTableAction.geRegion(
        baseAddress.AddressVo.Country,
        3,
        !this.state.caseEntity.AccidentCity
          ? this.state.caseEntity.AddressVo.City
          : this.state.caseEntity.AccidentCity
      );
      const result = [];
      const filterList = [];
      _.each(areaCodeTable, mapObj => {
        if (!_.contains(filterList, mapObj.Id)) {
          filterList.push(mapObj.Id);
          const v = { id: mapObj.Id, text: mapObj.Description };
          result.push(v);
        }
      });
      areaCodeTable = new CodeTable(result);
    }
    _self.baseAddress = baseAddress;
    return areaCodeTable;
  },
  createCityCodeTable(self, baseAddress) {
    const _self = self;
    let cityCodeTable = new CodeTable([]);
    if (
      baseAddress.AddressVo.Country === 'CY' ||
      baseAddress.AddressVo.Country === 'US'
    ) {
      cityCodeTable = CodeTableAction.geRegion(
        baseAddress.AddressVo.Country,
        2
      );
      const result = [];
      const filterList = [];
      if (cityCodeTable) {
        _.each(cityCodeTable, mapObj => {
          if (!_.contains(filterList, mapObj.Id)) {
            filterList.push(mapObj.Id);
            const v = { id: mapObj.Id, text: mapObj.Description };
            result.push(v);
          }
        });
        cityCodeTable = new CodeTable(result);
      }
    }
    _self.baseAddress = baseAddress;
    return cityCodeTable;
  },
  formatDateTime() {
    let accidentTime = this.state.caseEntity.AccidentTime;
    let noticeTime = this.state.caseEntity.NoticeTime;
    if (accidentTime && accidentTime.indexOf(':') == -1) {
      accidentTime += ' 00:00:00';
      this.state.caseEntity.AccidentTime = accidentTime;
    }
    if (noticeTime && noticeTime.indexOf(':') == -1) {
      noticeTime += ' 00:00:00';
      this.state.caseEntity.NoticeTime = noticeTime;
    }
  },
  render() {
    const _self = this;
    const { caseEntity } = this.state;
    const prefixId = _self.props.prefixId || '';
    const RegistrationHandlingAction = ClaimRegistration.getClaimRegistrationHandlingAction();
    const registrationHandlingAction = new RegistrationHandlingAction();
    const catastropheCodeTable = new CodeTable(
      registrationHandlingAction.getActiveCode()
    );
    const readCity = this.createCityCodeTable(this, caseEntity);
    let showReadCity;
    if (readCity.codes.length === 0) {
      showReadCity = (
        <UIText
          id={`${prefixId}accidentCity`}
          label={i18n.ClaimCommon.AccidentCity}
          io="out"
          value={caseEntity.AddressVo.City}
        />
      );
    } else {
      showReadCity = (
        <UISelect
          id={`${prefixId}accidentCity`}
          label={i18n.ClaimCommon.AccidentCity}
          io="out"
          value={
            !caseEntity.AccidentCity
              ? caseEntity.AddressVo.City
              : caseEntity.AccidentCity
          }
          codeTable={readCity}
        />
      );
    }
    const readArea = this.createAreaCodeTable(this, caseEntity);
    let showReadArea;
    if (readArea.codes.length === 0) {
      showReadArea = (
        <UIText
          id={`${prefixId}accidentTown`}
          label={i18n.ClaimCommon.AccidentTown}
          io="out"
          value={caseEntity.AddressVo.State}
        />
      );
    } else {
      showReadArea = (
        <UISelect
          id={`${prefixId}accidentTown`}
          label={i18n.ClaimCommon.AccidentTown}
          io="out"
          value={caseEntity.AddressVo.State}
          codeTable={readArea}
        />
      );
    }
    const readStree = this.createStreetNameCodeTable(
      this,
      caseEntity
    );
    let showReadStree;
    if (readStree.codes.length === 0) {
      showReadStree = (
        <UIText
          id={`${prefixId}accidentStreet`}
          label={i18n.ClaimCommon.AccidentStreet}
          io="out"
          value={caseEntity.AddressVo.AddressLine1}
        />
      );
    } else {
      showReadStree = (
        <UISelect
          id={`${prefixId}accidentStreet`}
          label={i18n.ClaimCommon.AccidentStreet}
          io="out"
          value={caseEntity.AddressVo.AddressLine1}
          codeTable={readStree}
        />
      );
    }
    return (
      <div>
        <UISmartPanelGrid column="3" widthAllocation="5,7">
          {/* <UIText id={`${prefixId}policyNumber`} label={i18n.ClaimCommon.PolicyNumber}
                  value={this.state.caseEntity.PolicyEntity.PolicyNo} io="out" /> */}
          <UIText
            id={`${prefixId}policyNumber`}
            label={i18n.ClaimCommon.PolicyNumber}
            enabled="false"
            suffixIcon="fa fa-copy"
            onSuffixIconClick={this.retrievePolicy}
            value={caseEntity.PolicyEntity.PolicyNo}
            io={this.withOutPolicy()}
          />
          <UISelect
            id={`${prefixId}product`}
            label={i18n.ClaimCommon.ProductName}
            value={caseEntity.PolicyEntity.ProductCode}
            codeTable={CodeTableAction.loadDataList(
              CodeTableCstUI.ClaimProductCode,
              null
            )}
            io="out"
          />

          <UISelect
            id={`${prefixId}currency`}
            label={i18n.ClaimCommon.Currency}
            defaultValue={caseEntity.PolicyEntity.CurrencyCode}
            codeTable={CodeTableAction.loadDataList(
              CodeTableCstUI.ClaimCurrencyCode,
              null
            )}
            onChange={this.changeCurrency}
            value={caseEntity.CurrencyCode}
            showBlankOption="false"
            io="out"
          />

          <UIText
            id={`${prefixId}policyholderName`}
            label={i18n.ClaimCommon.PolicyHolderName}
            value={
              caseEntity.PolicyHolderParty
                ? caseEntity.PolicyHolderParty.PartyName
                : ''
            }
            io="out"
          />

          <UIText
            id={`${prefixId}policyholderInfo`}
            label={i18n.ClaimCommon.PolicyHolderInfo}
            suffixIcon="fa fa-edit"
            onSuffixIconClick={this.editClaimant}
            value={
              caseEntity.PolicyHolderParty
                ? caseEntity.PolicyHolderParty.PartyName
                : ''
            }
            io="out"
          />

          <UIText
            id={`${prefixId}salesChannel`}
            label={i18n.ClaimCommon.Producer}
            value={
              caseEntity.SalesChannelParty
                ? caseEntity.SalesChannelParty.PartyName
                : ''
            }
            io="out"
          />

          <UISelect
            id={`${prefixId}contactType`}
            label={i18n.ClaimCommon.ContactType}
            codeTable={CodeTableAction.loadDataList(
              CodeTableCstUI.ClaimContactType,
              null
            )}
            value={caseEntity.ContactType}
            io="out"
          />

          <UIText
            id={`${prefixId}contactPerson`}
            label={i18n.ClaimCommon.ContactPerson}
            value={caseEntity.ContactName}
            io="out"
          />

          <UIText
            id={`${prefixId}contactTelephone`}
            label={i18n.ClaimCommon.ContactTel}
            value={caseEntity.ContactPhone}
            io="out"
          />

          <UIText
            id={`${prefixId}contactEmail`}
            label={i18n.ClaimCommon.ClaimContactEmail}
            value={caseEntity.ContactPersonEmail}
            io="out"
          />
        </UISmartPanelGrid>
        <UISmartPanelGrid column="3" widthAllocation="5,7">

          <UIDateTimePicker
            id={`${prefixId}dateOfLoss`}
            format="MM/DD/YYYY HH:mm:ss"
            label={i18n.ClaimCommon.DateOfLoss}
            value={caseEntity.AccidentTime}
            io="out"
          />

          <UIDateTimePicker
            id={`${prefixId}dateOfNotification`}
            format="MM/DD/YYYY HH:mm:ss"
            label={i18n.ClaimCommon.DateOfNotice}
            value={caseEntity.NoticeTime}
            io="out"
          />

          <UIDateTimePicker
            id={`${prefixId}dateOfRegistration`}
            format="MM/DD/YYYY HH:mm:ss"
            label={i18n.ClaimCommon.DateofRegistration}
            value={caseEntity.RegisterTime}
            io="out"
          />

          <UISelect
            id={`${prefixId}causeOfLoss_UISelect`}
            label={i18n.ClaimCommon.CauseofLoss}
            codeTable={caseEntity.CodeTableCauseOfLoss}
            value={caseEntity.LossCause}
            io="out"
          />

          <UITwoText
            id={`${prefixId}catastropheCode`}
            label={i18n.ClaimCommon.CatastropheCode}
            searchDialog={{
              dialogTitle: `${i18n.ClaimCommon.CatastropheCode} ${i18n.ClaimCommon.Search}`,
            }}
            codeTable={catastropheCodeTable}
            value={caseEntity.AccidentCode}
            io="out"
          />

          <UISelect
            id={`${prefixId}insuredLiability`}
            label={i18n.ClaimCommon.InsuredLiability}
            codeTable={CodeTableAction.loadDataList(
              CodeTableCstUI.ClaimLiability,
              null
            )}
            value={caseEntity.InsuredLiability}
            io="out"
          />

          <UISelect
            id={`${prefixId}recoverFrom`}
            label={i18n.ClaimCommon.RecoverFrom}
            codeTable={CodeTableAction.loadDataList(
              CodeTableCstUI.ClaimRecoverFrom,
              null
            )}
            value={caseEntity.RecoverFrom}
            io="out"
          />

          <UISelect
            id={`${prefixId}accidentCountry`}
            label={i18n.ClaimCommon.AccidentCountry}
            required="true"
            io="out"
            value={caseEntity.AddressVo.Country}
            codeTable={CodeTableAction.loadDataList(
              CodeTableCstUI.ClaimPartyCountry,
              null
            )}
          />
          {showReadCity}
          {showReadArea}
          {showReadStree}
          <UIText
            id={`${prefixId}accidentPostalCode`}
            label={i18n.ClaimCommon.PostalCode}
            io="out"
            value={caseEntity.AddressVo.PostCode}
          />

          <UIText
            id={`${prefixId}accidentLocation`}
            label={i18n.ClaimCommon.DetailLocation}
            value={caseEntity.AccidentAddress}
            colspan="3"
            io="out"
          />

          <UITextarea
            id={`${prefixId}lossDescription`}
            label={i18n.ClaimNotice.AccidentDescription}
            value={caseEntity.AccidentDesc}
            colspan="3"
            disabled="true"
          />
          {/*
           
           
          <ClaimPoliceInfo
            caseEntity={caseEntity}
            colspan="3"
            ioflag="out"
          />

          {this.renderMigrationRemark(prefixId)}*/}
        </UISmartPanelGrid>
        {this.getDialogs()}
      </div>
    );
  },

  renderFirstPartyDriverInfo() {
    const { caseEntity } = this.state;
    const isMotor = caseEntity.IsMotor;
    if (isMotor) {
      return (
        <FirstPartyDriverInfo
          claimEntity={caseEntity}
          io="out"
          colspan="3"
        />
      );
    }
  },

  renderMigrationRemark(prefixId) {
    // if(this.state.caseEntity.DcFlag=='Y'){
    const { caseEntity } = this.state;
    return (
      <UIText
        id={`${prefixId}Remark`}
        label={i18n.ClaimCommon.MigrationRemark}
        model={caseEntity}
        property="Remark"
        disabled="true"
        colspan="3"
      />
    );
    // }else{
    //   return (
    //     <div/>
    //   )
    // }
  },
  getDialogs() {
    const _parent = this;
    const { caseEntity } = this.state;
    const { data, authMap } = this.props;
    let productCode;
    if (caseEntity.PolicyEntity) {
      productCode = caseEntity.PolicyEntity.ProductCode;
    }
    return (
      <div>
        <RegistrationPolicyDialog
          ref="RegistrationPolicyDialog_ref"
          productCode={productCode}
          parentComponent={_parent}
          isWithOutPolicy={this.withOutPolicy() === 'in'}
          caseId={caseEntity.CaseId}
        />
        <ModifyClaimInfoDialog
          ref="ModifyClaimInfoDialog_ref"
          data={data}
          authMap={authMap}
        />
        <ModifySubclaimInfoDialog
          ref="ModifySubclaimInfoDialog_ref"
          data={data}
        />
        <ClaimFraudIndicatorDialog
          ref="ClaimFraudIndicatorDialog_ref"
          data={data}
        />
        <RejectDialog ref="RejectDialog_ref" />
      </div>
    );
  },

  getParentComponent() {
    return this;
  },
});
export default ClaimInfoRead;
