/**
 * Created by jianjun.gao
 */
import {
  CodeTable,
  DataContext,
  UIAutoComplete,
  UICell,
  UIEmail,
  UIPanel,
  UICheckbox,
  UISelect,
  UISmartPanelGrid,
  UIText,
  UIUpdatePanel,
  UpdateContext,
} from 'RainbowUI';
import DefineCodeTable from '../../party/codetable/CodeTable';
import PartyConst from '../../party/const/PartyConst';
import EU00AutoComplete from './EU00AutoComplete';

const BranchConst = {
  BRANCH_COMPANY_LEVEL: '1',
  BRANCH_DISTRICT_LEVEL: '2',
  BRANCH_OFFICE_LEVEL: '3',

  BRANCH_STATUS_ACTIVE: '1',
  BRANCH_STATUS_INACTIVE: '2',
  COMPANY_LOGO: '1',
  CEO_SIGNATURE: '2',
};
const BaseAddress = React.createClass({
  getInitialState() {
    const tempCodeTable = new CodeTable([]);
    let cityCodeTable = tempCodeTable;

    const countryCodeTable = DefineCodeTable.getCountry();
    DataContext.put('countryCodeTable', countryCodeTable);
    const { party } = this.props;
    let addressTypeCodeTable = DefineCodeTable.getAddressType();
    if (party) {
      if (party.PartyType == PartyConst.PARTY_TYPE_ORG) {
        addressTypeCodeTable = DefineCodeTable.getOrgAddressType();
      }
    }
    DataContext.put('addressTypeCodeTable', addressTypeCodeTable);
    const statusCodeTable = DefineCodeTable.getPartyStatus();
    DataContext.put('statusCodeTable', statusCodeTable);
    // type is getted from props
    // type = null or undefined : partyAddress
    // type = "branch";
    let baseAddress = {};
    console.log(
      `Party baseAddress location[Initial]: ${JSON.stringify(
        this.props.baseAddress
      )}`
    );
    if (this.props.baseAddress) {
      baseAddress = this.props.baseAddress;
    }
    if (!baseAddress.Country) baseAddress.Country = 'US';
    if (
      baseAddress.Country === 'CY' ||
      baseAddress.Country === 'US'
    ) {
      cityCodeTable = this.createCityCodeTable(this, baseAddress);
      DataContext.put('cityCodeTable', cityCodeTable);
    }
    const validationGroup = this.props.validationGroup
      ? this.props.validationGroup
      : 'baseAddressInfoDialog';

    return {
      tempCodeTable,
      countryCodeTable,
      cityCodeTable,
      addressTypeCodeTable,
      statusCodeTable,
      readOnly: this.props.readOnly,
      postCodeCodeTable: tempCodeTable,
      baseAddress,
      require: this.props.require,
      proposalRequire: this.props.proposalRequire,
      validationGroup,
    };
  },

  render() {
    const { type } = this.props;
    const mainPart = this.renderOriginalMainPart(this);
    if (type === 'branch') {
      const baseAddress = DataContext.get('BranchAddressInfo');
      if (baseAddress) {
        this.state.baseAddress = baseAddress;
      }
      return (
        <div>
          <UIPanel panelTitle={i18n.AdditionalInformation}>
            {mainPart}
          </UIPanel>
        </div>
      );
    }
    return <div>{mainPart}</div>;
  },
  //= =====================IndexMain=========================//
  renderOriginalMainPart(_self) {
    if (
      _self.props.type === 'claim' ||
      _self.props.type === 'claimRegistration'
    ) {
      _self.claimI18NHandle();
    } else if (_self.props.type === 'branch') {
      _self.branchI18NHandle();
    }
    else {
      _self.claimI18NHandle();
    }
    return (
      <div>
        <UIUpdatePanel id="BaseAddressPanel">
          {_self.primaryAddressRender()}
          {_self.addressTypeRender()}
        </UIUpdatePanel>
      </div>
    );
  },

  claimI18NHandle() {
    // link with i18n ClaimCommon would be better
    i18n.DistrictOrCity = 'Accident City';
    i18n.Country = 'Accident Country';
    i18n.Area = 'Accident State';
    i18n.StreetName = 'Accident Street';
    i18n.FaxNumber = 'Fax';
  },

  branchI18NHandle() {
    i18n.telphone = 'Tel';
    i18n.FaxNumber = 'Fax';
    i18n.Website = 'WebSite';
    i18n.CeoName = 'CEO Name';
  },
  //= =====================IndexPrimaryAddress=========================//
  primaryAddressRender() {
    // search renderPartyPrimaryAddress as a full exemple
    const _self = this;
    let cityCodeTable = new CodeTable([]);
    let areaCodeTable = new CodeTable([]);
    let postCodeTable = new CodeTable([]);
    const { baseAddress } = _self.state;

    cityCodeTable = _self.createCityCodeTable(_self, baseAddress);
    areaCodeTable = _self.createAreaCodeTable(_self, baseAddress);
    postCodeTable = _self.createPostCodeTable(_self, baseAddress);
    DataContext.put('cityCodeTable', cityCodeTable);
    DataContext.put('areaCodeTable', areaCodeTable);
    DataContext.put('postCodeTable', postCodeTable);
    let primaryAddress = null;
    if (this.props.type === 'claim') {
      primaryAddress = _self.renderClaimPrimaryAddress(_self);
    } else if (this.props.type === 'branch') {
      primaryAddress = _self.renderBranchPrimaryAddress(_self);
    } else if (this.props.party && !this.props.type) {
      primaryAddress = _self.renderPartyPrimaryAddress(_self);
    } else if (this.props.type === 'location') {
      primaryAddress = _self.renderLocationPrimaryAddress(_self);
    } else if (this.props.type === 'claimRegistration') {
      // primaryAddress = _self.renderClaimregistrationPrimaryAddress(_self);
      primaryAddress = _self.renderClaimPrimaryAddress(_self);
    } else {
      primaryAddress = _self.renderDefaultPrimaryAddress(_self);
    }
    return primaryAddress;
  },

  renderClaimregistrationPrimaryAddress(_self) {
    const Country = _self.renderClaimRegistrationCountry(_self);
    const City = _self.renderClaimRegistrationCity(
      _self,
      DataContext.get('cityCodeTable'),
      false
    );
    const Area = _self.renderClaimRegistrationArea(
      _self,
      DataContext.get('areaCodeTable'),
      false
    );
    return (
      <div>
        <UISmartPanelGrid column="3">
          {Country}
          {City}
          {Area}
        </UISmartPanelGrid>
      </div>
    );
  },

  renderClaimPrimaryAddress(_self) {
    const isMotor = _self.props.isMotor == true;
    const Country = _self.renderOriginalCountry(_self);
    const City = _self.renderOriginalCity(
      _self,
      DataContext.get('cityCodeTable'),
      isMotor
    );
    const Area = _self.renderOriginalArea(
      _self,
      DataContext.get('areaCodeTable'),
      isMotor
    );
    return (
      <div>
        <UISmartPanelGrid column="3">
          {Country}
          {City}
          {Area}
        </UISmartPanelGrid>
      </div>
    );
  },

  renderBranchPrimaryAddress(_self) {
    const Country = _self.renderOriginalCountry(_self);
    const City = _self.renderBranchCity(
      _self,
      DataContext.get('cityCodeTable'),
      false
    );
    const Area = _self.renderOriginalArea(
      _self,
      DataContext.get('areaCodeTable'),
      false
    );
    const PostCode = _self.renderOriginalPostCode(
      _self,
      DataContext.get('postCodeTable'),
      false
    );
    return (
      <div>
        <UISmartPanelGrid column="3">
          {Country}
          <UICell />
          <UICell />
          {City}
          {Area}
          {PostCode}
        </UISmartPanelGrid>
      </div>
    );
  },

  renderPartyPrimaryAddress(_self) {
    const CheckBoxCorrespondenceAddress = _self.renderOriginalCheckBoxCorrespondenceAddress(
      _self
    );
    const AddressType = _self.renderOriginalAddressType(_self);
    const Country = _self.renderOriginalCountry(_self);
    const City = _self.renderOriginalCity(
      _self,
      DataContext.get('cityCodeTable')
    );
    const Area = _self.renderOriginalArea(
      _self,
      DataContext.get('areaCodeTable')
    );
    const PostCode = _self.renderOriginalPostCode(
      _self,
      DataContext.get('postCodeTable')
    );
    return (
      <div>
        <UISmartPanelGrid column="3">
          {CheckBoxCorrespondenceAddress}
          {AddressType}
          {Country}
          {City}
          {Area}
          {PostCode}
        </UISmartPanelGrid>
      </div>
    );
  },

  renderLocationPrimaryAddress(_self) {
    const { require } = this.state;
    const Country = _self.renderOriginalCountry(_self, require);
    const City = _self.renderOriginalCity(
      _self,
      DataContext.get('cityCodeTable'),
      require
    );
    const Area = _self.renderOriginalArea(
      _self,
      DataContext.get('areaCodeTable'),
      require
    );
    const PostCode = _self.renderOriginalPostCode(
      _self,
      DataContext.get('postCodeTable'),
      require
    );
    return (
      <div>
        <UISmartPanelGrid column="2">
          {Country}
          {City}
          {Area}
          {PostCode}
        </UISmartPanelGrid>
      </div>
    );
  },

  renderDefaultPrimaryAddress(_self) {
    const AddressType = _self.renderOriginalAddressType(_self);
    const Country = _self.renderOriginalCountry(_self);
    const City = _self.renderOriginalCity(
      _self,
      DataContext.get('cityCodeTable')
    );
    const Area = _self.renderOriginalArea(
      _self,
      DataContext.get('areaCodeTable')
    );
    const PostCode = _self.renderOriginalPostCode(
      _self,
      DataContext.get('postCodeTable')
    );
    return (
      <div>
        <UISmartPanelGrid column="3">
          {AddressType}
          {Country}
          {City}
          {Area}
          {PostCode}
        </UISmartPanelGrid>
      </div>
    );
  },
  //= =====================IndexAddressType=========================//

  addressTypeRender() {
    const _self = this;
    const { baseAddress } = this.state;
    let addressTypePart = null;
    if (
      baseAddress &&
      (baseAddress.AddressType == 3 || baseAddress.AddressType == '3')
    ) {
      // Home
      addressTypePart = _self.renderAddressTypeHome(_self);
    } else {
      const streetNameCodeTable = _self.createStreetNameCodeTable(
        baseAddress,
        _self
      );
      DataContext.put('streetNameCodeTable', streetNameCodeTable);
      if (
        this.props.type === 'claim' ||
        this.props.type === 'claimRegistration'
      ) {
        addressTypePart = _self.renderAddressTypeClaim(_self);
      } else if (this.props.type === 'branch') {
        const pOBoxCodeTable = _self.createPoBoxCodeTable(
          baseAddress,
          _self
        );
        DataContext.put('pOBoxCodeTable', pOBoxCodeTable);
        addressTypePart = _self.renderAddressTypeParty(_self);
      } else if (this.props.party && !this.props.type) {
        addressTypePart = _self.renderAddressTypeParty(_self);
      } else if (this.props.type === 'location') {
        addressTypePart = _self.renderAddressTypeParty(_self);
      } else {
        addressTypePart = _self.renderAddressTypeParty(_self);
      }
    }
    return addressTypePart;
  },

  renderAddressTypeHome(_self) {
    const { party } = this.props;
    let pOBoxCodeTable = new CodeTable([]);
    let POBox;
    pOBoxCodeTable = _self.createPoBoxCodeTable(
      _self.baseAddress,
      _self
    );
    if (party) {
      POBox = _self.renderOriginalPoBox(
        _self,
        DataContext.get('pOBoxCodeTable')
      );
      const Status = _self.renderOriginalStatus(
        _self,
        _self.state.statusCodeTable
      );
      return (
        <UISmartPanelGrid column="3">
          {POBox}
          <UICell />
          <UICell />
          {Status}
        </UISmartPanelGrid>
      );
    }
    POBox = _self.renderOriginalPoBox(
      _self,
      DataContext.get('pOBoxCodeTable')
    );
    return <UISmartPanelGrid column="3">{POBox}</UISmartPanelGrid>;
  },

  renderAddressTypeClaim(_self) {
    const isMotor = _self.props.isMotor == true;
    const PostCode = _self.renderOriginalPostCode(
      _self,
      DataContext.get('postCodeTable'),
      isMotor
    );
    const StreetName = _self.renderOriginalStreetName(
      _self,
      DataContext.get('streetNameCodeTable'),
      isMotor
    );
    return (
      <UISmartPanelGrid column="3">
        {StreetName}
        {PostCode}
        <UICell />
      </UISmartPanelGrid>
    );
  },

  renderAddressTypeBranch(_self) {
    const StreetName = _self.renderOriginalStreetName(
      _self,
      DataContext.get('streetNameCodeTable'),
      false
    );
    const POBox = _self.renderOriginalPoBox(
      _self,
      DataContext.get('pOBoxCodeTable'),
      false
    );
    // this visible isnot the same with the "visible"property of component
    const visible = _self.props.BranchLevel == 1;
    if (visible == true) {
      return (
        <UISmartPanelGrid column="3">
          {StreetName}
          <UICell />
          <UIText
            disabled={_self.state.readOnly}
            label={i18n.Number}
            model={_self.state.baseAddress}
            property="StreetNo"
          />
          {POBox}
          <UIText
            disabled={_self.state.readOnly}
            label={i18n.POBOXPOSTALCODE}
            model={_self.state.baseAddress}
            property="POBOXPostalCode"
          />
          <UIText
            disabled={_self.state.readOnly}
            label={i18n.BuildingName}
            model={_self.state.baseAddress}
            property="BuildingName"
          />
          <UIText
            label={i18n.telphone}
            model={_self.state.baseAddress}
            property="AddressTel"
            validationGroup="checkFirst"
          />
          <UIText
            label={i18n.FaxNumber}
            model={_self.state.baseAddress}
            property="FaxNumber"
            validationGroup="checkFirst"
            enabled={_self.state.enabledFlag}
          />
          <UIText
            label={i18n.Website}
            model={_self.state.baseAddress}
            property="WebAddress"
          />
          <UIEmail
            label={i18n.email}
            model={_self.state.baseAddress}
            property="Email"
          />
          <UIText
            label={i18n.CeoName}
            model={_self.state.baseAddress}
            property="CeoName"
          />
        </UISmartPanelGrid>
      );
    }
    if (visible == false) {
      return (
        <UISmartPanelGrid column="3">
          {StreetName}
          <UICell />
          <UIText
            disabled={_self.state.readOnly}
            label={i18n.Number}
            model={_self.state.baseAddress}
            property="StreetNo"
          />
          <UIText
            label={i18n.telphone}
            model={_self.state.baseAddress}
            property="AddressTel"
            validationGroup="checkFirst"
          />
          <UIText
            label={i18n.FaxNumber}
            model={_self.state.baseAddress}
            property="FaxNumber"
            validationGroup="checkFirst"
            enabled={_self.state.enabledFlag}
          />
        </UISmartPanelGrid>
      );
    }
  },

  renderAddressTypeParty(_self) {
    const StreetName = _self.renderOriginalStreetName(
      _self,
      DataContext.get('streetNameCodeTable')
    );
    const Status = _self.renderOriginalStatus(
      _self,
      _self.state.statusCodeTable
    );
    return (
      <UISmartPanelGrid column="3">
        {StreetName}
        {Status}
      </UISmartPanelGrid>
    );
  },

  renderAddressTypeLocation(_self) {
    const { require } = _self.props;
    const { proposalRequire } = _self.props;
    const StreetName = _self.renderOriginalStreetName(
      _self,
      DataContext.get('streetNameCodeTable'),
      require
    );
    let { readOnly } = _self.state;
    if (proposalRequire) {
      readOnly = false;
    }
    return (
      <div>
        {StreetName}
        <UISmartPanelGrid column="2">
          <UIText
            disabled={readOnly}
            enabled={!readOnly}
            label={i18n.Number}
            model={_self.state.baseAddress}
            property="StreetNo"
            required={proposalRequire}
          />
          <UIText
            disabled={readOnly}
            enabled={!readOnly}
            label={i18n.BuildingName}
            model={_self.state.baseAddress}
            property="BuildingName"
          />
          <UIText
            disabled={readOnly}
            enabled={!readOnly}
            label={i18n.ApartmentNumber}
            model={_self.state.baseAddress}
            property="ApartmentNumber"
          />
        </UISmartPanelGrid>
      </div>
    );
  },

  renderAddressTypeDefault(_self) {
    const StreetName = _self.renderOriginalStreetName(
      _self,
      _self.state.baseAddress.streetNameCodeTable
    );
    const { baseAddress } = _self.state;
    return (
      <UISmartPanelGrid column="3">
        {StreetName}
        <UIText
          disabled={_self.state.readOnly}
          label={i18n.Number}
          model={baseAddress}
          property="StreetNo"
        />
        <UIText
          disabled={_self.state.readOnly}
          label={i18n.BuildingName}
          model={baseAddress}
          property="BuildingName"
        />
        <UIText
          disabled={_self.state.readOnly}
          label={i18n.ApartmentNumber}
          model={baseAddress}
          property="ApartmentNumber"
        />
        <UIText
          disabled={_self.state.readOnly}
          label={i18n.AddressTel}
          model={baseAddress}
          property="AddressTel"
        />
        <UIText
          disabled={_self.state.readOnly}
          label={i18n.FaxNumber}
          model={baseAddress}
          property="FaxNumber"
        />
      </UISmartPanelGrid>
    );
  },
  //= =========IndexCodeTable==================//
  getIdFromCodeTable(unit, unitCodeTable, type) {
    if (!isNaN(unit) || !unitCodeTable || !type) {
      return unit;
    }
    const unitObject = DataContext.get(`current_${type}`);
    if (unitObject) {
      if (unitObject.id) {
        return unitObject.id;
      }
    }

    if (unitCodeTable.codes) {
      for (let i = 0; i < unitCodeTable.codes.length; i += 1) {
        if (unitCodeTable.codes[i].text == unit) {
          if (type) {
            DataContext.put(`current_${type}`, {
              id: unitCodeTable.codes[i].id,
              Name: unit,
            });
          }
          return unitCodeTable.codes[i].id;
        }
      }
    }

    return unit;
  },
  idInCodeTable(unit, unitCodeTable) {
    if (unit && unitCodeTable) {
      if (unitCodeTable.codes) {
        for (let i = 0; i < unitCodeTable.codes.length; i += 1) {
          if (
            unitCodeTable.codes[i].text == unit ||
            unitCodeTable.codes[i].id == unit
          ) {
            return true;
          }
        }
      }
    }
    return false;
  },
  createCityCodeTable(tmpself, tmpbaseAddress) {
    const _self = tmpself;
    const baseAddress = tmpbaseAddress;
    let cityCodeTable = new CodeTable([]);
    if (
      baseAddress.Country === 'CY' ||
      baseAddress.Country === 'US'
    ) {
      cityCodeTable = DefineCodeTable.geRegion(
        baseAddress.Country,
        2
      );
      DataContext.put('cityCodeTable', cityCodeTable);
      if (cityCodeTable.map[baseAddress.City]) {
        /* empty */
      } else if (baseAddress.City) {
        cityCodeTable = new CodeTable([
          { id: baseAddress.City, text: baseAddress.City },
        ]);
      }
    } else if (this.props.type === 'claimRegistration') {
      cityCodeTable = new CodeTable([
        { id: baseAddress.City, text: baseAddress.City },
      ]);
    }
    if (cityCodeTable.codes && cityCodeTable.codes.length === 0) {
      const city = DataContext.get('City');
      if (city) {
        baseAddress.City = city;
        cityCodeTable = new CodeTable([{ id: city, text: city }]);
      }
    }
    if (DataContext.get('City')) {
      if (
        this.idInCodeTable(
          DataContext.get('City'),
          DataContext.get('cityCodeTable')
        )
      ) {
        /* empty */
      } else {
        cityCodeTable = new CodeTable([
          {
            id: DataContext.get('City'),
            text: DataContext.get('City'),
          },
        ]);
      }
    }

    _self.baseAddress = baseAddress;
    return cityCodeTable;
  },

  createAreaCodeTable(tmpself, tmpbaseAddress) {
    const _self = tmpself;
    const baseAddress = tmpbaseAddress;
    let areaCodeTable = new CodeTable([]);
    if (baseAddress.City && !isNaN(baseAddress.City)) {
      const idCity = this.getIdFromCodeTable(
        baseAddress.City,
        DataContext.get('cityCodeTable'),
        'city'
      );
      if (
        _self.idInCodeTable(idCity, DataContext.get('cityCodeTable'))
      ) {
        areaCodeTable = DefineCodeTable.geRegion(
          baseAddress.Country,
          3,
          idCity
        );
        DataContext.put('areaCodeTable', areaCodeTable);
        if (baseAddress.State) {
          if (
            areaCodeTable.codes.length === 0 ||
            !areaCodeTable.map[baseAddress.State]
          ) {
            const tempAreaCodeTable = [
              {
                id: baseAddress.State,
                text: baseAddress.State,
              },
            ];
            areaCodeTable = new CodeTable(tempAreaCodeTable);
          }
        } else {
          const area = DataContext.get('Area');
          if (area) {
            const tempAreaCodeTable = [
              {
                id: area,
                text: area,
              },
            ];
            baseAddress.State = area;
            areaCodeTable = new CodeTable(tempAreaCodeTable);
          }
        }
      } else {
        const area = DataContext.get('Area');
        if (area) {
          const tempAreaCodeTable = [
            {
              id: area,
              text: area,
            },
          ];
          baseAddress.State = area;
          areaCodeTable = new CodeTable(tempAreaCodeTable);
        }
      }
    } else if (baseAddress.State) {
      areaCodeTable = new CodeTable([
        { id: baseAddress.State, text: baseAddress.State },
      ]);
    }
    _self.baseAddress = baseAddress;
    return areaCodeTable;
  },

  createPostCodeTable(tmpself, tmpbaseAddress) {
    const baseAddress = tmpbaseAddress;
    const _self = tmpself;
    let postCodeTable = new CodeTable([]);
    if (baseAddress.State) {
      const idCity = this.getIdFromCodeTable(
        baseAddress.City,
        DataContext.get('cityCodeTable'),
        'city'
      );
      let areaCodeTable = DataContext.get('areaCodeTable');
      if (!areaCodeTable && !isNaN(idCity)) {
        areaCodeTable = DefineCodeTable.geRegion(
          baseAddress.Country,
          3,
          idCity
        );
        DataContext.put('areaCodeTable', areaCodeTable);
      }
      let idArea = this.getIdFromCodeTable(
        baseAddress.State,
        areaCodeTable,
        'area'
      );

      let tempAreaCodeTable = new CodeTable([]);
      if (
        this.idInCodeTable(
          DataContext.get('Area'),
          DataContext.get('areaCodeTable')
        )
      ) {
        if (
          DataContext.get('areaCodeTable').id !=
          DataContext.get('areaCodeTable').text &&
          !isNaN(idCity)
        ) {
          tempAreaCodeTable = DefineCodeTable.geRegion(
            baseAddress.Country,
            3,
            idCity
          );
        }
      }
      if (baseAddress.Country && !isNaN(idCity)) {
        tempAreaCodeTable = DefineCodeTable.geRegion(
          baseAddress.Country,
          3,
          idCity
        );
      }

      DataContext.put('postCodeTable', postCodeTable);
      if (tempAreaCodeTable.map[baseAddress.State] && !isNaN(idArea)) {
        postCodeTable = DefineCodeTable.geRegion(
          baseAddress.Country,
          4,
          idArea
        );
        DataContext.put('postCodeTable', postCodeTable);
      } else {
        const postCode = baseAddress.PostCode;
        if (postCode) {
          const tempPostCodeList = [
            {
              id: postCode,
              text: postCode,
            },
          ];
          postCodeTable = new CodeTable(tempPostCodeList);
        } else {
          postCodeTable = new CodeTable([]);
        }
      }
      let postCode = baseAddress.PostCode;
      if (
        postCode !== null &&
        postCode !== undefined &&
        postCode !== ''
      ) {
        if (postCode) {
          const tempPostCodeList = [
            {
              id: postCode,
              text: postCode,
            },
          ];
          postCodeTable = new CodeTable(tempPostCodeList);
        } else {
          postCodeTable = new CodeTable([]);
        }
      } else if (postCode === '' && !isNaN(idArea)) {
        try {
          idArea = this.getIdFromCodeTable(
            baseAddress.State,
            DataContext.get('areaCodeTable')
          );
          postCodeTable = DefineCodeTable.geRegion(
            baseAddress.Country,
            4,
            idArea
          );
        } catch (err) {
          postCodeTable = new CodeTable([]);
        }
      } else {
        postCode = DataContext.get('PostCode');
        if (postCode) {
          const tempPostCodeList = [
            {
              id: postCode,
              text: postCode,
            },
          ];
          baseAddress.PostCode = postCode;
          postCodeTable = new CodeTable(tempPostCodeList);
        }
      }
    } else if (baseAddress.PostCode) {
      postCodeTable = new CodeTable([
        { id: baseAddress.PostCode, text: baseAddress.PostCode },
      ]);
    }
    _self.state.baseAddress = baseAddress;
    return postCodeTable;
  },

  createPoBoxCodeTable(baseAddress, _self) {
    let pOBoxCodeTable = new CodeTable([]);
    if (baseAddress.State && !isNaN(baseAddress.State)) {
      const tempPoBoxCodeTable = DefineCodeTable.geRegion(
        baseAddress.Country,
        6,
        baseAddress.State
      );
      // if (_self.state.flag == "create") {
      pOBoxCodeTable = tempPoBoxCodeTable;
      // }
      if (_self.state.flag === 'edit') {
        const poBoxDesc = tempPoBoxCodeTable.map[baseAddress.POBox];
        pOBoxCodeTable = tempPoBoxCodeTable;
        if (!poBoxDesc) {
          tempPoBoxCodeTable.codes.push({
            id: baseAddress.POBox,
            text: baseAddress.POBox,
          });
          pOBoxCodeTable = new CodeTable(tempPoBoxCodeTable.codes);
        }
      }
      DataContext.put('pOBoxCodeTable', pOBoxCodeTable);
    }
    return pOBoxCodeTable;
  },

  createStreetNameCodeTable(baseAddress, _self) {
    let streetNameCodeTable = new CodeTable([]);
    if (baseAddress.State && !isNaN(baseAddress.State)) {
      streetNameCodeTable = DefineCodeTable.geRegion(
        baseAddress.Country,
        5,
        baseAddress.State
      );
      if (
        _.isEmpty(streetNameCodeTable.getCode()) &&
        baseAddress.AddressLine1
      ) {
        streetNameCodeTable = new CodeTable([
          {
            id: baseAddress.AddressLine1,
            text: baseAddress.AddressLine1,
          },
        ]);
      }
    } else if (baseAddress.AddressLine1) {
      streetNameCodeTable = new CodeTable([
        {
          id: baseAddress.AddressLine1,
          text: baseAddress.AddressLine1,
        },
      ]);
    }
    return streetNameCodeTable;
  },

  //= ==========IndexComponent===============//

  renderOriginalCheckBoxCorrespondenceAddress(_self) {
    return (
      <div>
        <UICheckbox
          singledisabled={_self.state.readOnly}
          label={i18n.CorrespondenceAddress}
          single="true"
          defaultValue="Y"
          model={_self.state.baseAddress}
          property="IsPrimaryAddress"
          disabled={_self.state.readOnly}
        />
      </div>
    );
  },

  renderOriginalAddressType(_self, tmprequire, visible) {
    let require = tmprequire;
    if (require) {
      /* empty */
    } else {
      require = true;
    }
    return (
      <div>
        <UISelect
          disabled={_self.state.readOnly}
          label={i18n.AddressType}
          onChange={_self.onChangeAddressType}
          showBlankOption="false"
          required={require}
          defaultValue="1"
          codeTable={_self.state.addressTypeCodeTable}
          model={_self.state.baseAddress}
          property="AddressType"
        />
      </div>
    );
  },

  renderOriginalCountry(_self, tmprequire, visible) {
    let require = tmprequire;
    if (require) {
      /* empty */
    } else {
      require = true;
    }
    const { proposalRequire } = _self.props;
    let { readOnly } = _self.state;
    if (proposalRequire) {
      readOnly = false;
    }
    return (
      <div>
        <UIAutoComplete
          disabled={readOnly}
          enabled={!readOnly}
          label={i18n.Country}
          required={require}
          dropDown="true"
          acceptInput="true"
          defaultValue="US"
          codeTable={DefineCodeTable.getCountry()}
          onChange={_self.changeCountryCode}
          model={_self.state.baseAddress}
          property="Country"
        />
      </div>
    );
  },

  renderOriginalCity(_self, cityCodeTable, tmprequire, visible) {
    let require = tmprequire;
    const { validationGroup } = this.state;
    if (require) {
      /* empty */
    } else {
      require = true;
    }
    const { proposalRequire } = _self.props;
    let { readOnly } = _self.state;
    if (proposalRequire) {
      readOnly = false;
    }

    return (
      <div>
        <EU00AutoComplete
          disabled={readOnly}
          enabled={!readOnly}
          label={i18n.DistrictOrCity}
          required={require}
          validationGroup={validationGroup}
          DataContextName="City"
          acceptInput="true"
          codeTable={cityCodeTable}
          onChange={_self.changeCity}
          model={_self.state.baseAddress}
          property="City"
        />
      </div>
    );
  },
  renderBranchCity(_self, cityCodeTable, tmprequire, visible) {
    let require = tmprequire;
    const { validationGroup } = this.state;
    if (require) {
      /* empty */
    } else {
      require = true;
    }
    require = _self.props.BranchLevel !== 1;
    return (
      <div>
        <EU00AutoComplete
          disabled={_self.state.readOnly}
          label={i18n.DistrictOrCity}
          required={require}
          validationGroup={validationGroup}
          DataContextName="City"
          acceptInput="true"
          codeTable={cityCodeTable}
          onChange={_self.changeCity}
          model={_self.state.baseAddress}
          property="City"
        />
      </div>
    );
  },

  renderOriginalArea(_self, areaCodeTable, tmprequire, visible) {
    let require = tmprequire;
    const { validationGroup } = this.state;
    if (require) {
      /* empty */
    } else {
      require = true;
    }
    const { proposalRequire } = _self.props;
    let { readOnly } = _self.state;
    if (proposalRequire) {
      readOnly = false;
    }
    return (
      <div>
        <EU00AutoComplete
          disabled={readOnly}
          enabled={!readOnly}
          label={i18n.Area}
          required={require}
          validationGroup={validationGroup}
          acceptInput="true"
          codeTable={areaCodeTable}
          DataContextName="Area"
          onChange={_self.changeArea}
          model={_self.state.baseAddress}
          property="State"
        />
      </div>
    );
  },
  renderOriginalPostCode(_self, postCodeTable, tmprequire, visible) {
    let require = tmprequire;
    const { validationGroup } = this.state;
    if (require) {
      /* empty */
    } else {
      require = true;
    }
    const { proposalRequire } = _self.props;
    let { readOnly } = _self.state;
    if (proposalRequire) {
      readOnly = false;
    }
    return (
      <div>
        <EU00AutoComplete
          disabled={readOnly}
          enabled={!readOnly}
          label={i18n.PostalCode}
          required={require}
          acceptInput="true"
          codeTable={postCodeTable}
          onChange={_self.changePostCode}
          DataContextName="PostCode"
          validationGroup={validationGroup}
          model={_self.state.baseAddress}
          property="PostCode"
          maxLength="10"
        />
      </div>
    );
  },

  renderOriginalPoBox(_self, pOBoxCodeTable, tmprequire, tmpvisible) {
    let require = tmprequire;
    let visible = tmpvisible;
    const { validationGroup } = this.state;
    if (require) {
      /* empty */
    } else {
      require = true;
    }
    if (visible) {
      /* empty */
    } else {
      visible = true;
    }
    if (
      !pOBoxCodeTable ||
      (pOBoxCodeTable.codes && pOBoxCodeTable.codes.length === 0) ||
      pOBoxCodeTable == new CodeTable([])
    ) {
      return (
        <UIText
          disabled={_self.state.readOnly}
          label={i18n.PoBox}
          validationGroup={validationGroup}
          acceptInput="true"
          model={_self.state.baseAddress}
          required={require}
          visibled={visible}
          property="AddressLine2"
        />
      );
    }
    return (
      <div>
        <UIAutoComplete
          disabled={_self.state.readOnly}
          label={i18n.PoBox}
          required={require}
          visibled={visible}
          validationGroup={validationGroup}
          acceptInput="true"
          codeTable={pOBoxCodeTable}
          model={_self.state.baseAddress}
          property="AddressLine2"
        />
      </div>
    );
  },

  renderOriginalStatus(_self, statusCodeTable) {
    return (
      <div>
        <UISelect
          disabled={_self.state.readOnly}
          label={i18n.Status}
          showBlankOption="false"
          codeTable={statusCodeTable}
          model={_self.state.baseAddress}
          property="AddressStatus"
        />
      </div>
    );
  },

  renderOriginalStreetName(
    _self,
    streetNameCodeTable,
    tmprequire,
    visible
  ) {
    let require = tmprequire;
    const { validationGroup } = this.state;
    if (require) {
      /* empty */
    } else {
      require = true;
    }
    const { proposalRequire } = _self.props;
    let { readOnly } = _self.state;
    if (proposalRequire) {
      readOnly = false;
    }
    return (
      <div>
        <EU00AutoComplete
          disabled={readOnly}
          enabled={!readOnly}
          label={i18n.StreetName}
          required={require}
          validationGroup={validationGroup}
          colspan={2}
          acceptInput="true"
          codeTable={streetNameCodeTable}
          model={_self.state.baseAddress}
          property="AddressLine1"
          DataContextName="StreetName"
        />
      </div>
    );
  },

  onBlurFaxNumber(onEvent) {
    const reg = /^[0-9]*-[0-9]*-[0-9]*$/;
    const s = reg.exec(onEvent.component.onEvent.newValue);
    if (s) {
      this.state.baseAddress.FaxNumber = s[0];
    } else {
      this.state.baseAddress.FaxNumber = '';
    }
    this.forceUpdate();
  },

  changeAddressStatus(event) {
    const status = event.newValue;
    let { readOnly } = this.state;
    const { baseAddress } = this.state;
    if (status == '1') {
      readOnly = false;
    } else {
      readOnly = true;
      baseAddress.IsPrimaryAddress = 'N';
    }
    this.setState({ readOnly, baseAddress });
  },

  onChangeAddressType() {
    const { baseAddress } = this.state;
    this.clearBaseAddress(baseAddress);
    this.forceUpdate();
    UpdateContext.forceUpdate('BaseAddressPanel');
  },

  changeCountryCode() {
    this.clearBaseAddress(this.state.baseAddress);
    DataContext.remove('City');
    DataContext.remove('Area');
    DataContext.remove('PostCode');
    DataContext.remove('StreetName');
    this.state.baseAddress.City = null;
    this.state.baseAddress.State = null;
    this.state.baseAddress.PostCode = null;
    this.state.baseAddress.StreetName = null;
    DataContext.remove('cityCodeTable');
    DataContext.remove('areaCodeTable');
    DataContext.remove('postCodeTable');
    DataContext.remove('streetNameCodeTable');
    this.state.cityCodeTable = null;
    this.state.areaCodeTable = null;
    this.state.postCodeTable = null;
    this.state.streetNameCodeTable = null;
    this.forceUpdate();
    UpdateContext.forceUpdate('BaseAddressPanel');
  },

  changeClaimRegistrationCountryCode() {
    this.clearBaseAddress(this.state.baseAddress);
    DataContext.remove('AccidentCity');
    DataContext.remove('AccidentTown');
    DataContext.remove('PostalCode');
    DataContext.remove('AccidentStreet');
    this.state.baseAddress.AccidentCity = null;
    this.state.baseAddress.AccidentTown = null;
    this.state.baseAddress.PostalCode = null;
    this.state.baseAddress.AccidentStreet = null;
    DataContext.remove('cityCodeTable');
    DataContext.remove('areaCodeTable');
    DataContext.remove('postCodeTable');
    DataContext.remove('streetNameCodeTable');
    this.state.cityCodeTable = null;
    this.state.areaCodeTable = null;
    this.state.postCodeTable = null;
    this.state.streetNameCodeTable = null;
    this.forceUpdate();
    UpdateContext.forceUpdate('BaseAddressPanel');
  },

  changeCity(event) {
    DataContext.remove('Area');
    DataContext.remove('PostCode');
    DataContext.remove('StreetName');
    this.state.baseAddress.State = null;
    this.state.baseAddress.PostCode = null;
    this.state.baseAddress.StreetName = null;
    // luan shu de shihou
    if (
      this.idInCodeTable(
        DataContext.get('City'),
        DataContext.get('cityCodeTable')
      )
    ) {
    } else {
      this.state.baseAddress.City = DataContext.get('City');
    }
    this.forceUpdate();
  },

  changeArea(event) {
    if (
      this.idInCodeTable(
        DataContext.get('Area'),
        DataContext.get('areaCodeTable')
      )
    ) {
    } else {
      this.state.baseAddress.State = DataContext.get('Area');
    }
    this.forceUpdate();
  },

  changePostCode(event) {
    const reg = /^[0-9]*$/;
    const s = reg.exec(DataContext.get('PostCode'));
    if (s) {
      this.state.baseAddress.PostCode = s[0];
    } else {
      this.state.baseAddress.PostCode = '';
      DataContext.put('PostCode', event.component.onEvent.newValue);
    }
    // if (this.props.type !="claim"){
    //    DataContext.remove("StreetName");
    //    this.state.baseAddress.StreetName = null;
    // }

    if (
      this.idInCodeTable(
        DataContext.get('PostCode'),
        DataContext.get('postCodeTable')
      )
    ) {
      /* empty */
    } else {
      this.state.baseAddress.PostCode = DataContext.get('PostCode');
    }
    this.forceUpdate();
    UpdateContext.forceUpdate('BaseAddressPanel');
  },

  clearBaseAddress(tmpbaseAddress) {
    const baseAddress = tmpbaseAddress;
    console.log(
      `====clearBaseAddress====before ${JSON.stringify(baseAddress)}`
    );
    if (baseAddress.State) {
      delete baseAddress.State;
    }
    if (baseAddress.AddressVoList) {
      delete baseAddress.AddressVoList;
    }
    if (baseAddress.StreetName) {
      delete baseAddress.StreetName;
    }
    if (baseAddress.StreetNo) {
      delete baseAddress.StreetNo;
    }
    if (baseAddress.BuildingName) {
      delete baseAddress.BuildingName;
    }
    if (baseAddress.ApartmentNumber) {
      delete baseAddress.ApartmentNumber;
    }
    if (baseAddress.AddressTel) {
      delete baseAddress.AddressTel;
    }
    if (baseAddress.POBox) {
      delete baseAddress.POBox;
    }
    if (baseAddress.City) {
      delete baseAddress.City;
    }
    if (baseAddress.PostCode) {
      delete baseAddress.PostCode;
    }
    if (baseAddress.FaxNumber) {
      delete baseAddress.FaxNumber;
    }
  },
});

export default BaseAddress;
