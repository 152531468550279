import { MessageHelper } from 'RainbowUI';
import ClaimCommonExt from 'ClaimCommonExt';
import PropertyService from '../service/PropertyService';
import InvestigationService from '../service/InvestigationService';

const TriggerTaskTransformDialogAction = ClaimCommonExt.getReserveAction();

class PropertyAction {
  synButtonIsEnable(taskInfo, damageType = 'P') {
    const enabled = InvestigationService.synButtonIsEnable(
      taskInfo,
      damageType
    );
    return enabled;
  }

  synModel(subClaimId) {
    return InvestigationService.synData(subClaimId);
  }

  save(entity, callback) {
    const promise = PropertyService.propertySave(entity);
    promise.then(
      data => {
        if (data.Status === 'OK') {
          callback(data);
          MessageHelper.success(
            i18n.ClaimFieldInvestigation.SaveSuccess
          );
        }
      },
      () => {
        MessageHelper.error('', i18n.ClaimFieldInvestigation.Failed);
      }
    );
  }

  submit(entity) {
    const promise = PropertyService.propertySubmit(entity);
    promise.then(
      data => {
        if (data.Status === 'OK') {
          MessageHelper.success(
            i18n.ClaimFieldInvestigation.SubmitSuccess
          );
          TriggerTaskTransformDialogAction.reserveApprovalTaskAssigned(
            'TaskComplete'
          );
        }
      },
      () => {
        MessageHelper.error('', i18n.ClaimFieldInvestigation.Failed);
      }
    );
  }

  initData(subCliamId, fiStatus) {
    return InvestigationService.initData(subCliamId, fiStatus);
  }
}

const PropertyActionInstance = new PropertyAction();
export default PropertyActionInstance;
