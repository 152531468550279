import {
  UITabItem,
  UIBox,
  UIDialog,
  UICell,
  UIButton,
} from 'RainbowUI';
import PartyOrgBasicInfo from '../../../party/views/PartyOrgBasicInfo';
import PartyIndBasicInfo from '../../../party/views/PartyIndBasicInfo';
import PartyContactInfo from '../../../party/views/PartyContactInfo';
import PartyAction from '../../../party/actions/PartyAction';
import PartyConst from '../../../party/const/PartyConst';
import PartyIndAddInfo from '../../../party/views/PartyIndAddInfo';

const PtyIntegrationTabInfoDialog = React.createClass({
  getInitialState() {
    return {
      party: {},
    };
  },

  showDialog(partyId, inParty) {
    const partyAction = new PartyAction();
    let party;
    if (inParty) {
      party = inParty;
    } else {
      party = partyAction.loadPartyByPartyId(partyId);
    }
    this.setState({
      party,
    });
    UIDialog.show('ptyIntegrationTabInfoDialog');
  },

  exitHandler() {
    this.props.callBackGetRecord(this.state.party);
    UIDialog.hide('ptyIntegrationTabInfoDialog');
  },

  submitHandler() {
    const { party } = this.state;
    const partyAction = new PartyAction();
    const newPartyHistory = {
      '@type': 'PartyHistory-PartyHistory',
      BusinessObjectId: 75158352,
      HistoryType: 2,
    };
    party.PartyHistoryList.push(newPartyHistory);
    const result = partyAction.updateParty(party);
    this.props.callBackGetRecord(result);
    UIDialog.hide('ptyIntegrationTabInfoDialog');
  },

  submitInfoHandler(party) {
    const partyAction = new PartyAction();
    const result = partyAction.updateParty(party);
    this.setState({
      party: result,
    });
    return result;
  },

  render() {
    const { party } = this.state;
    let BasicInfo;
    if (party.PartyType == PartyConst.PARTY_TYPE_IND) {
      BasicInfo = this.renderIndBasicInfo();
    } else {
      BasicInfo = this.renderOrgBasicInfo();
    }
    return (
      <UIDialog id="ptyIntegrationTabInfoDialog" width="80%">
        <UICell width="14">{BasicInfo}</UICell>

        <UIBox direction="center">
          <UIButton
            value={i18n.Submit}
            onClick={this.submitHandler}
            validationGroup="UpdateBasicInfo"
            causeValidation="true"
          />
          <UIButton value={i18n.Exit} onClick={this.exitHandler} />
        </UIBox>
      </UIDialog>
    );
  },
  renderIndBasicInfo() {
    const { party } = this.state;
    return <PartyIndBasicInfo party={party} />;
  },
  renderOrgBasicInfo() {
    const { party } = this.state;
    return <PartyOrgBasicInfo party={party} />;
  },
  renderIndAddInfo() {
    const { party } = this.state;
    return (
      <UITabItem title={i18n.AdditionalId}>
        <PartyIndAddInfo
          party={party}
          partyAdditionalList={party.PartyAdditionalList}
          submitInfoHandler={this.submitInfoHandler}
        />
      </UITabItem>
    );
  },
  renderOrgAddInfo() {
    const { party } = this.state;
    return (
      <UITabItem title={i18n.ContactInfo}>
        <PartyContactInfo
          partyContactList={party.PartyContactList}
          submitInfoHandler={this.submitInfoHandler}
          party={party}
        />
      </UITabItem>
    );
  },
});
export default PtyIntegrationTabInfoDialog;
