import {
  UIPickList,
  UIText,
  UIButton,
  UIBox,
  UIParam,
} from 'RainbowUI';
import PropTypes from 'prop-types';

const CauseOfLossRelationAction = require('../../action/CauseOfLossRelationAction');

const LossOfCauseSubclaimTypeRelation = React.createClass({
  propTypes: {
    entity: PropTypes.object,
  },
  getPickList() {
    return CauseOfLossRelationAction.getPickList(
      this.props.entity.lossCauseCode
    );
  },
  render() {
    const { entity } = this.props;
    return (
      <div>
        <UIText
          label={i18n.ClaimProduct.CauseofLossName}
          value={`${entity.lossCauseCode}:${entity.lossCauseName}`}
          io="out"
        />
        <div>
          <UIPickList
            id="lossOfCauseSubclaimTypeRelationPickList"
            sourceListLabel={i18n.ClaimProduct.OptionalSubclaimTypes}
            targetListLabel={
              i18n.ClaimProduct.ApplicableSubclaimTypes
            }
            dataSource={this.getPickList}
          />
        </div>
        <div>
          <UIBox direction="left">
            <UIButton
              value={i18n.ClaimProduct.Save}
              onClick={CauseOfLossRelationAction.saveCauseOfLoss}
            >
              <UIParam name="entity" value={entity} />
            </UIButton>

            <UIButton
              value={i18n.ClaimProduct.Cancel}
              onClick={CauseOfLossRelationAction.cancelCauseOfLoss}
            >
              <UIParam name="entity" value={entity} />
            </UIButton>
          </UIBox>
        </div>
      </div>
    );
  },
});
export default LossOfCauseSubclaimTypeRelation;
