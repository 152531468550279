import {
  UIDataTable,
  UIColumn,
  UILink,
  UIParam,
  UIRadio,
  UICheckbox,
  UISmartPanelGrid,
} from 'RainbowUI';
import PropTypes from 'prop-types';

const PartyContactInfo = require('./PartyContactInfo');
const code = require('../mock/codeTable');

const PartyContact = React.createClass({
  propTypes: {
    contactList: PropTypes.Array,
  },
  getDefaultProps() {
    return { contactList: [] };
  },
  getInitialState() {
    const list = this.props.contactList;
    const { contactId } = this.props;

    for (const item in list) {
      const vo = list[item];

      if (list.length === 1) {
        vo.selected = 'Y';
        vo.IsPrimaryContact = 'Y';
        vo.ContactStatus = '1';
      } else if (vo.ContactId == contactId) {
        vo.selected = 'Y';
      } else if (
        contactId == undefined &&
        vo.IsPrimaryContact === 'Y'
      ) {
        vo.selected = 'Y';
      }
    }
    return { list };
  },

  componentWillReceiveProps(nextProps) {
    const list = nextProps.contactList;
    for (const item in list) {
      const vo = list[item];
      if (vo.IsPrimaryContact === 'Y') {
        vo.selected = 'Y';
      }
    }
    this.setState({ list });
  },
  getContactList() {
    return this.state.list;
  },

  changeActive(event) {
    this.setState(prevState => {
      const dataIndex = event.getParameter('dataIndex');
      const contactList = prevState.list;
      const contactVo = contactList[dataIndex];

      if (contactVo.ContactStatus == '1') {
        contactVo.ContactStatus = '2';
        contactVo.selected = 'N';
      } else {
        contactVo.ContactStatus = '1';
      }

      contactList[dataIndex] = contactVo;
      return {
        list: contactList,
      };
    });
  },

  changePrimary(event) {
    this.setState(prevState => {
      const dataIndex = event.getParameter('dataIndex');
      const contactList = prevState.list;
      for (const item in prevState.list) {
        contactList[item].IsPrimaryContact =
          item == dataIndex ? 'Y' : 'N';
      }
      return {
        list: contactList,
      };
    });
  },

  editContact(event) {
    if (this.props.partyAuth === 'true') {
      this.refs.PartyContactInfo_ref.editContact(
        event.getParameter('data')
      );
    }
  },

  saveContact(tmpcontactVo) {
    const contactVo = tmpcontactVo;
    this.setState(prevState => {
      const contactList = prevState.list;
      if (contactVo.isAdd) {
        contactVo.isAdd = false;
        contactVo.ContactStatus = '1';
        if (contactList.length === 0) {
          contactVo.IsPrimaryContact = 'Y';
          contactVo.selected = 'Y';
        }
        contactList.push(contactVo);
      } else {
        const { dataIndex } = contactVo;
        contactList[dataIndex] = contactVo;
      }
      return {
        list: contactList,
      };
    });
  },
  addButton() {
    if (this.props.partyAuth === 'true') {
      this.refs.PartyContactInfo_ref.addContact();
    }
  },
  buildTableFunction() {
    return (
      <UILink
        icon="fa fa-plus"
        size="lg"
        iconClass="add_iconbtn"
        onClick={this.addButton}
      />
    );
  },
  render() {
    const { index: _index, list } = this.props;
    const self = this;

    const enabledVal = () => {
      const hasAuth = self.props.partyAuth === 'true';
      let pm = false;
      if (self.props.index === 'PartyMaintenance') {
        pm = true;
      }
      return hasAuth && pm;
    };

    return (
      <div>
        <UIDataTable
          id={`ClaimPartyContactListId${_index}`}
          value={list}
          headerTitle={i18n.ClaimParty.ContactInfo}
          indexable="false"
          pageable="false"
          styleClass="default"
          functions={this.buildTableFunction()}
        >
          {this.getUIColumns()}

          <UIColumn
            headerTitle={i18n.ClaimParty.ContactNumber}
            value="HandPhone"
          />
          <UIColumn
            headerTitle={i18n.ClaimParty.ContactEmail}
            value="Email"
          />
          <UIColumn
            render={data => {
              return (
                <UISmartPanelGrid>
                  <UIRadio
                    id={`contactRadio${_index}${data.dataIndex}`}
                    codeTable={code.isPrimary}
                    value={data.IsPrimaryContact}
                    onChange={self.changePrimary}
                    enabled={enabledVal}
                  >
                    <UIParam
                      name="dataIndex"
                      value={data.dataIndex}
                    />
                  </UIRadio>

                  <UICheckbox
                    id={`contactCheckbox${_index}${data.dataIndex}`}
                    codeTable={code.isActive}
                    value={`${data.ContactStatus}`}
                    onChange={self.changeActive}
                    enabled={enabledVal}
                  >
                    <UIParam
                      name="dataIndex"
                      value={data.dataIndex}
                    />
                  </UICheckbox>
                </UISmartPanelGrid>
              );
            }}
          />
          {this.getLinkColumn()}
        </UIDataTable>
        {this.getDetailPage()}
      </div>
    );
  },

  getUIColumns() {
    let result = <div />;

    if (this.props.showContactRadio !== 'false') {
      result = (
        <UIColumn
          render={data => {
            return (
              <UIRadio
                codeTable={code.test}
                value={data.selected}
                onChange={this.selectContact}
              >
                <UIParam name="dataIndex" value={data.dataIndex} />
              </UIRadio>
            );
          }}
        />
      );
    }
    return result;
  },
  selectContact(event) {
    const dataIndex = event.getParameter('dataIndex');
    this.setState(prevState => {
      const contactList = prevState.list;
      for (const item in contactList) {
        contactList[item].selected = dataIndex == item ? 'Y' : 'N';
      }
      return {
        list: contactList,
      };
    });
  },

  getLinkColumn() {
    let result = <div />;
    const self = this;
    if (this.props.partyAuth === 'true') {
      result = (
        <UIColumn
          headerTitle={i18n.ClaimCommon.Action}
          render={data => {
            return (
              <UILink
                tooltip={i18n.ClaimParty.EditLink}
                onClick={self.editContact}
                icon="fa fa-pencil"
              >
                <UIParam name="data" value={data} />
                <UIParam name="index" value={self.props.index} />
              </UILink>
            );
          }}
        />
      );
    }
    return result;
  },
  getDetailPage() {
    let result = <div />;
    if (this.props.partyAuth === 'true') {
      result = (
        <PartyContactInfo
          contactVo={this.state.contactVo}
          ref="PartyContactInfo_ref"
          parentComponent={this}
        />
      );
    }
    return result;
  },
});
export default PartyContact;
