import {
  CodeTable,
  UIDialog,
  UITwoText,
  UISelect,
  UIButton,
  UIDateTimePicker,
  UIText,
  UISmartPanelGrid,
  UITextarea,
  UIUpdatePanel,
  UpdateContext,
  UIEmail
} from 'RainbowUI';
import {
  ClaimHandlingAction,
  ClaimHandlingStore,
  RejectDialog,
  AddOrEditPartyDialog,
} from 'ClaimCommon';
import { CodeTableAction, CodeTableCstUI } from 'ClaimCommonConstant';
import React from 'react';
import PropTypes from 'prop-types';
import BindToMixin from 'react-binding';
import { BaseAddress } from 'EU00PartyCommonUI';
import ClaimRegistration from 'ClaimRegistration';
import MigrationDriverRemark from './MigrationDriverRemark';
import ClaimFraudIndicatorDialog from './ClaimFraudIndicatorDialog';
import ClearRelatedInfoConfirmDialog from './ClearRelatedInfoConfirmDialog';
import FirstPartyDriverInfo from './FirstPartyDriverInfo';
import Url from '../url/Url';

const RegistrationPolicyDialog = ClaimRegistration.getRegistrationPolicyDialog();
const ClaimPoliceInfo = require('./ClaimPoliceInfo');

const ClaimInfo = React.createClass({
  mixins: [BindToMixin],

  propTypes: {
    forModify: PropTypes.string,
    prefixId: PropTypes.string,
  },

  getDefaultProps() {
    return { forModify: 'false' };
  },

  getInitialState() {
    let caseEntity = this.props.data;

    caseEntity = this.handleCaseEntityAddress(caseEntity);
    return { caseEntity, policeInfoIsRequired: false };
  },
  renderMigrationRemark(prefixId) {
    const { caseEntity } = this.state;
    return (
      <UIText
        id={`${prefixId}Remark`}
        label={i18n.ClaimCommon.MigrationRemark}
        model={caseEntity}
        property="Remark"
        disabled="true"
        colspan="3"
      />
    );
  },
  handleCaseEntityAddress(tempCaseEntity) {
    const caseEntity = tempCaseEntity;
    if (!caseEntity.AddressVo) caseEntity.AddressVo = {};
    delete caseEntity.AccidentCity;
    delete caseEntity.AccidentTown;
    delete caseEntity.AccidentStreet;
    delete caseEntity.PostalCode;

    return caseEntity;
  },

  componentWillMount() {
    ClaimHandlingStore.listen(this.onStoreStateChange);
    this.formatDateTime();
  },

  componentWillUnmount() {
    ClaimHandlingStore.unlisten(this.onStoreStateChange);
  },

  onStoreStateChange(storeState) {
    if (!_.isEmpty(storeState.caseForm)) {
      UpdateContext.forceUpdate('countryCityForUpdate');
      UpdateContext.forceUpdate('policeInfoForUpdate');

      this.setState({ caseEntity: storeState.caseForm.ClaimEntity });
    }
  },

  retrievePolicy() {
    this.refs.RegistrationPolicyDialog_ref.showDialog();
  },

  updatePolicyInfo(policyInfo) {
    if (
      !_.isEmpty(this.state.caseEntity.LossCause) &&
      this.checkSubclaimTypeValue()
    ) {
      this.refs.ClearRelatedInfoConfirmDialog_ref.showDialog(
        'Policy',
        policyInfo
      );
    } else {
      this.countinueUpdatePolicyInfo(policyInfo);
    }
  },

  countinueUpdatePolicyInfo(tempPolicyInfo) {
    const policyInfo = tempPolicyInfo;
    const caseForm = this.props.parentComponent.getCaseForm();
    policyInfo.CaseId = this.state.caseEntity.CaseId;
    caseForm.ClaimEntity.PolicyEntity = policyInfo;
    ClaimHandlingAction.retrievePolicyCountinue(caseForm);
    if (this.props.parentComponent.refs.claimHandling_ref) {
      const message = ClaimHandlingAction.validatePolicyPremium(policyInfo.PolicyNo);
      if (message)
        this.props.parentComponent.refs.claimHandling_ref.setState({ warningMsg: [message] });
    }
  },
  clickReview() {
    const RegistrationHandlingAction = ClaimRegistration.getClaimRegistrationHandlingAction();
    const registrationHandlingAction = new RegistrationHandlingAction();
    registrationHandlingAction.saveLastReviewClaim(
      this.state.caseEntity.CaseId
    );
  },
  changeCauseOfLoss(event) {
    if (this.props.forModify === 'false') {
      if (
        !_.isEmpty(event.oldValue) &&
        this.checkSubclaimTypeValue() &&
        !this.ifExistedNotBelongsSubClaimType()
      ) {
        this.refs.ClearRelatedInfoConfirmDialog_ref.showDialog(
          'CauseofLoss',
          event.oldValue
        );
      } else {
        this.countinueChangeCauseOfLoss(event.oldValue);
      }
    } else {
      this.props.parentComponent.causeOfLossChanged();
    }
  },

  ifExistedNotBelongsSubClaimType() {
    let notExisted = true;
    for (
      let i = 0;
      i < this.state.caseEntity.ObjectList.length;
      i += 1
    ) {
      const existed = this.props.parentComponent.refs[
        `subclaim_ref${i}`
      ].ifSubClaimTypeExistedInNewCauseOfLoss(
        this.state.caseEntity.LossCause
      );
      if (!existed) {
        notExisted = false;
        break;
      }
    }
    return notExisted;
  },

  countinueChangeCauseOfLoss(oldValue) {
    const flag = 'countinueChangeCauseOfLoss';
    for (
      let i = 0;
      i < this.state.caseEntity.ObjectList.length;
      i += 1
    ) {
      this.props.parentComponent.refs[
        `subclaim_ref${i}`
      ].filterSubclaimType(
        this.state.caseEntity.LossCause,
        flag,
        oldValue,
        true
      );
    }
  },
  cancelChangeCauseOfLoss(oldValue) {
    const { caseEntity } = this.state;
    caseEntity.LossCause = oldValue;
    this.setState({ caseEntity });
  },

  checkSubclaimTypeValue() {
    let objList;
    if (
      this.props.parentComponent &&
      this.props.parentComponent.getCaseForm
    ) {
      objList =
        this.props.parentComponent.getCaseForm().ClaimEntity
          .ObjectList || [];
    } else {
      objList =
        (this.props.getObjectList && this.props.getObjectList()) ||
        [];
    }
    return (
      objList &&
      objList.length > 0 &&
      objList.some(data => !_.isEmpty(data.SubclaimType))
    );
  },

  componentDidMount() {
    const prefixId = this.props.prefixId || '';
    const { caseEntity } = this.state;
    if (caseEntity.City) {
      $(`#${prefixId}accidentCity`).val(caseEntity.City);
    }
  },

  clearRelateInfo() { },

  changeCurrency(event) {
    for (
      let i = 0;
      i < this.state.caseEntity.ObjectList.length;
      i += 1
    ) {
      this.props.parentComponent.refs[
        `subclaim_ref${i}`
      ].currencyChanged(event.newValue);
    }
  },

  editPolicyHolder() {
    const partyId = this.state.caseEntity.PolicyHolderParty
      .PtyPartyId;
    if (partyId != null && partyId !== '') {
      window.location.href = `${Url.PartyCommonUrl}/#/viewIntegrationPartyBaseInfo/${partyId}`;
    }
  },
  editSalesChannel() {
    this.refs.AddOrEditPartyDialog_ref.showDialog(
      this.state.caseEntity.SalesChannelParty
    );
    this.setState({ title: i18n.ClaimCommon.SalesChannelInfo });
  },
  updatePolicyHolder(claimParty) {
    const caseEntity =
      this.props.parentComponent &&
      this.props.parentComponent.getCaseForm &&
      this.props.parentComponent.getCaseForm().ClaimEntity;

    ClaimHandlingAction.updateClaimPartyList(
      claimParty,
      caseEntity || this.state.caseEntity
    );
  },

  getCauseOfLossIo() {
    if (this.props.forModify === 'true') {
      if (this.props.authMap.CHANGE_CAUSE_OF_LOSS) {
        return 'in';
      }
      return 'out';
    }
    return 'in';
  },

  sortClaimLiability(claimLiability) {
    const sort = _.sortBy(claimLiability, n => {
      return n.id;
    });
    return new CodeTable(sort);
  },
  getCodeTableCauseOfLoss() {
    return this.state.caseEntity.CodeTableCauseOfLoss;
  },

  withOutPolicy() {
    const io = this.props.forModify === 'true' ? 'out' : 'in';
    if (!this.state.caseEntity.PolicyEntity.PolicyNo) {
      return 'in';
    }
    return io;
  },

  isForModify() {
    return this.props.forModify === 'true' ? 'out' : 'in';
  },

  formatDateTime() {
    let accidentTime = this.state.caseEntity.AccidentTime;
    let noticeTime = this.state.caseEntity.NoticeTime;
    if (accidentTime && accidentTime.indexOf(':') == -1) {
      accidentTime += 'T00:00:00';
      this.state.caseEntity.AccidentTime = accidentTime;
    }
    if (noticeTime && noticeTime.indexOf(':') == -1) {
      noticeTime += 'T00:00:00';
      this.state.caseEntity.NoticeTime = noticeTime;
    }
  },

  updatePoliceInfoIsRequired(event) {
    this.setState({ policeInfoIsRequired: event });
  },

  render() {
    const _self = this;
    const { forModify } = this.props;
    const io = forModify === 'true' ? 'out' : 'in';
    const RegistrationHandlingAction = ClaimRegistration.getClaimRegistrationHandlingAction();
    const registrationHandlingAction = new RegistrationHandlingAction();

    const prefixId = _self.props.prefixId || '';

    let validationGroup = 'RegistrationInfo';
    if (forModify === 'true')
      validationGroup = `${prefixId}RegistrationInfo`;
    const caseEntity = this.handleCaseEntityAddress(
      _self.state.caseEntity
    );
    const catastropheCodeTable = new CodeTable(
      registrationHandlingAction.getActiveCode()
    );
    return (
      <div>
        <UIUpdatePanel id="claimInfo">
          <UISmartPanelGrid column="3" widthAllocation="5,7">
            <UIText
              id={`${prefixId}policyNumber`}
              label={i18n.ClaimCommon.PolicyNumber}
              enabled="false"
              suffixIcon="fa fa-copy"
              onSuffixIconClick={this.retrievePolicy}
              value={_self.state.caseEntity.PolicyEntity.PolicyNo}
              io={this.withOutPolicy()}
            />

            <UISelect
              id={`${prefixId}product`}
              label={i18n.ClaimCommon.ProductName}
              enabled="false"
              value={_self.state.caseEntity.PolicyEntity.ProductCode}
              codeTable={CodeTableAction.loadDataList(
                CodeTableCstUI.ClaimProductCode,
                null
              )}
              io={io}
            />

            <UISelect
              id={`${prefixId}currency`}
              label={i18n.ClaimCommon.Currency}
              defaultValue={
                _self.state.caseEntity.PolicyEntity.CurrencyCode
              }
              codeTable={CodeTableAction.loadDataList(
                CodeTableCstUI.ClaimCurrencyCode,
                null
              )}
              onChange={this.changeCurrency}
              model={caseEntity}
              property="CurrencyCode"
              showBlankOption="false"
              io={io}
            />

            <UIText
              id={`${prefixId}policyholderName`}
              label={i18n.ClaimCommon.PolicyHolderName}
              enabled="false"
              value={
                _self.state.caseEntity.PolicyHolderParty
                  ? _self.state.caseEntity.PolicyHolderParty.PartyName
                  : ''
              }
              io={io}
            />

            <UIText
              id={`${prefixId}policyholderInfo`}
              label={i18n.ClaimCommon.PolicyHolderInfo}
              enabled="false"
              suffixIcon="fa fa-search"
              onSuffixIconClick={this.editPolicyHolder}
              value={
                _self.state.caseEntity.PolicyHolderParty
                  ? _self.state.caseEntity.PolicyHolderParty.PartyName
                  : ''
              }
            />

            <UIText
              id={`${prefixId}salesChannel`}
              label={i18n.ClaimCommon.Producer}
              enabled="false"
              value={
                _self.state.caseEntity.SalesChannelParty
                  ? _self.state.caseEntity.SalesChannelParty.PartyName
                  : ''
              }
              io={io}
            />

            <UISelect
              id={`${prefixId}contactType`}
              label={i18n.ClaimCommon.ContactType}
              onChange={this.changeContectType}
              codeTable={CodeTableAction.loadDataList(
                CodeTableCstUI.ClaimContactType,
                null
              )}
              model={caseEntity}
              property="ContactType"
              validationGroup={validationGroup}
            />

            <UIText
              id={`${prefixId}contactPerson`}
              label={i18n.ClaimCommon.ContactPerson}
              validationGroup={validationGroup}
              model={caseEntity}
              property="ContactName"
            />

            <UIText
              id={`${prefixId}contactTelephone`}
              label={i18n.ClaimCommon.ContactTel}
              validationGroup={validationGroup}
              model={caseEntity}
              property="ContactPhone"
            />
            <UIEmail
              label={i18n.ClaimCommon.ClaimContactEmail}
              model={caseEntity}
              property="ContactPersonEmail"
              maxLength="60"
              validationGroup={validationGroup}
            />
          </UISmartPanelGrid>
          <UISmartPanelGrid column="3" widthAllocation="5,7">
            <UIDateTimePicker
              format="MM/DD/YYYY HH:mm:ss"
              id={`${prefixId}DateOfLoss`}
              label={i18n.ClaimCommon.DateOfLoss}
              enabled="false"
              model={caseEntity}
              property="AccidentTime"
              maxDate="TODAY"
              required="true"
              validationGroup={validationGroup}
              placeHolder="MM/DD/YYYY HH:mm:ss"
            />

            <UIDateTimePicker
              format="MM/DD/YYYY HH:mm:ss"
              id={`${prefixId}dateOfNotification`}
              label={i18n.ClaimCommon.DateOfNotice}
              enabled="false"
              model={caseEntity}
              property="NoticeTime"
              required="true"
              validationGroup={validationGroup}
              placeHolder="MM/DD/YYYY HH:mm:ss"
            />

            <UIDateTimePicker
              format="MM/DD/YYYY HH:mm:ss"
              id={`${prefixId}dateOfRegistration`}
              label={i18n.ClaimCommon.DateofRegistration}
              enabled="false"
              model={caseEntity}
              property="RegisterTime"
              required="true"
              validationGroup={validationGroup}
              placeHolder="MM/DD/YYYY HH:mm:ss"
            />

            <UISelect
              id={`${prefixId}causeOfLoss_UISelect`}
              label={i18n.ClaimCommon.CauseofLoss}
              required="true"
              validationGroup={validationGroup}
              codeTable={this.getCodeTableCauseOfLoss}
              onChange={this.changeCauseOfLoss}
              model={caseEntity}
              property="LossCause"
              io={this.getCauseOfLossIo()}
            />

            <UITwoText
              id={`${prefixId}catastropheCode`}
              label={i18n.ClaimCommon.CatastropheCode}
              searchDialog={{
                dialogTitle: i18n.ClaimCommon.CatastropheCode,
              }}
              handleKey={i18n.ClaimCommon.CatastropheCode}
              handleValue={i18n.ClaimCommon.CatastropheName}
              codeTable={catastropheCodeTable}
              onClose={this.onCloseTwoTextDialog}
              onChange={this.onCloseTwoTextDialog}
              model={caseEntity}
              property="AccidentCode"
            />

            <UISelect
              id={`${prefixId}insuredLiability`}
              label={i18n.ClaimCommon.InsuredLiability}
              codeTable={this.sortClaimLiability(
                CodeTableAction.loadDataList(
                  CodeTableCstUI.ClaimLiability,
                  null
                ).codes
              )}
              model={caseEntity}
              property="InsuredLiability"
            />

            <UISelect
              id={`${prefixId}recoverFrom`}
              label={i18n.ClaimCommon.RecoverFrom}
              codeTable={CodeTableAction.loadDataList(
                CodeTableCstUI.ClaimRecoverFrom,
                null
              )}
              model={caseEntity}
              property="RecoverFrom"
            />
            <UITextarea
              id={`${prefixId}lossDescription`}
              label={i18n.ClaimNotice.AccidentDescription}
              required="true"
              validationGroup={validationGroup}
              model={caseEntity}
              property="AccidentDesc"
              colspan="3"
            />
            <UIUpdatePanel id="countryCityForUpdate" colspan="3">
              <BaseAddress
                baseAddress={caseEntity.AddressVo}
                readOnly={false}
                type="claimRegistration"
                isMotor={caseEntity.IsMotor}
                validationGroup={validationGroup}
              />
            </UIUpdatePanel>

            <UIText
              id={`${prefixId}accidentLocation`}
              label={i18n.ClaimCommon.DetailLocation}
              validationGroup={validationGroup}
              model={caseEntity}
              property="AccidentAddress"
              colspan="3"
            />



            {/*
            <UIUpdatePanel id="policeInfoForUpdate" colspan="3">
              <ClaimPoliceInfo
                caseEntity={caseEntity}
                onChangePoliceInfoIsRequired={
                  this.updatePoliceInfoIsRequired
                }
                colspan="3"
                ioflag="in"
              />
            </UIUpdatePanel>*/}
          </UISmartPanelGrid>
          {this.getDialogs()}
        </UIUpdatePanel>
      </div>
    );
  },

  renderFirstPartyDriverInfo() {
    const caseEntity = this.props.data;
    const isMotor = caseEntity.IsMotor;
    if (isMotor) {
      return (
        <FirstPartyDriverInfo
          claimEntity={caseEntity}
          forModify={this.props.forModify}
          colspan="3"
        />
      );
    }
  },

  getDialogs() {
    const _parent = this;
    const { caseEntity, title } = this.state;
    const { forModify, data } = this.props;
    let productCode;
    if (caseEntity.PolicyEntity) {
      productCode = caseEntity.PolicyEntity.ProductCode;
    }
    return (
      <div>
        <RegistrationPolicyDialog
          ref="RegistrationPolicyDialog_ref"
          parentComponent={_parent}
          isWithOutPolicy={
            forModify === 'true' && this.withOutPolicy() === 'in'
          }
          caseId={caseEntity.CaseId}
          productCode={productCode}
        />
        <RejectDialog ref="RejectDialog_ref" />
        <AddOrEditPartyDialog
          fromWhere="policyHolder"
          ref="AddOrEditPartyDialog_ref"
          caseId={_parent.props.data.CaseId}
          parentComponent={_parent}
          title={title}
        />
        <ClearRelatedInfoConfirmDialog
          parentComponent={_parent}
          ref="ClearRelatedInfoConfirmDialog_ref"
        />
        <ClaimFraudIndicatorDialog ref="ClaimFraudIndicatorDialog_ref" />
      </div>
    );
  },

  getParentComponent() {
    return this;
  },

  onCloseTwoTextDialog() {
    if (this.props.forModify === 'true') {
      UIDialog.show('ModifyClaimInfo_Dialog');
    }
  },
});
export default ClaimInfo;
