import { DataContext, UIDialog } from 'RainbowUI';
import ClaimPartyAction from './ClaimPartyAction';

const CodeTableAction = require('../../common/action/CodeTableAction');
const CodeTableCstUI = require('../../common/constant/CodeTableCstUI');

export default class ChooseClaimantAction extends React.Component {
  onShow(tmpindex) {
    let index = tmpindex;
    if (!index) {
      index = 0;
    }
    UIDialog.show(`ChooseClaimantDialogId${index}`);
  }

  onHide(tmpindex) {
    let index = tmpindex;
    if (!index) {
      index = 0;
    }
    UIDialog.hide(`ChooseClaimantDialogId${index}`);
  }

  createOrUpdateParty(caseId, partyVo, isCheck) {
    return ClaimPartyAction.createOrUpdateParty(
      caseId,
      partyVo,
      isCheck
    );
  }

  createOrUpdatePartyWithCheck(
    caseId,
    partyVo,
    isAddressCheck,
    isAccountCheck
  ) {
    return ClaimPartyAction.createOrUpdateParty(
      caseId,
      partyVo,
      isAddressCheck,
      isAccountCheck
    );
  }

  selectedAsPayee(caseId, partyVo, index, payeeCallback) {
    if (!payeeCallback) {
      return;
    }

    const payMode = DataContext.get(
      `settlement_Payee_PayMode${index}`
    );
    let isCheck = false;
    if (payMode === '102') {
      isCheck = true;
    }

    const result = this.createOrUpdatePartyWithCheck(
      caseId,
      partyVo,
      true,
      isCheck
    );

    if (_.isEmpty(result)) {
      return;
    }

    const commonPty = result.commonParty;
    const claimPty = result.claimParty;

    const payeeId = claimPty.PartyId;

    if (payeeId === null || payeeId === undefined || payeeId === '') {
      return;
    }
    let bankCode = null;
    let accountNo = null;
    let accountName = null;
    let swiftCode = null;
    const payeeName = commonPty.PartyName;
    const ptyAccountId = claimPty.PtyAccountId;
    const ptyAddressId = claimPty.PtyAddressId;
    if (ptyAccountId != null && ptyAccountId !== '') {
      const AccountList = commonPty.PartyAccountList;
      if (AccountList) {
        for (let i = 0; i < AccountList.length; i += 1) {
          if (ptyAccountId == AccountList[i].AccountId) {
            bankCode = AccountList[i].BankCode;
            accountNo = AccountList[i].AccountNo;
            accountName = AccountList[i].AccountHolderName;
            swiftCode = AccountList[i].SwiftCode;
            break;
          }
        }
      }
    }
    let address = null;
    if (ptyAddressId != null && ptyAddressId !== '') {
      const AddressList = commonPty.AddressVoList;
      if (AddressList) {
        for (let i = 0; i < AddressList.length; i += 1) {
          if (ptyAddressId == AddressList[i].AddressId) {
            address = AddressList[i];
            break;
          }
        }
      }
    }

    let myAddress;
    const country = address.Country;
    const countries = CodeTableAction.loadDataList(
      CodeTableCstUI.ClaimPartyCountry,
      null
    );
    countries.codes.forEach(val => {
      if (country == val.id) {
        myAddress = val.text;
      }
    });
    if (myAddress) {
      myAddress = myAddress
        .concat('_')
        .concat(address.AddressLine1)
        .concat('_')
        .concat(address.PostCode);
    }
    const clmPayee = {
      PayeeId: payeeId,
      PayeeName: payeeName,
      PtyAccountId: ptyAccountId,
      PtyAddressId: ptyAddressId,
      BankCode: bankCode,
      AccountNo: accountNo,
      AccountName: accountName,
      Address: myAddress,
      SwiftCode: swiftCode,
    };

    for (const item in clmPayee) {
      if (!clmPayee[item]) {
        delete clmPayee[item];
      }
    }

    payeeCallback(index, clmPayee);
  }
}
