import { AjaxUtil } from 'RainbowUI';
import Url from '../../common/url/Url';

export default {
  loadParty(partyId) {
    const url = Url.PartyLoad + partyId;

    const defer = $.Deferred();
    AjaxUtil.doGet(
      url,
      {},
      {
        async: false,
        done(data, textStatus, jqXHR) {
          if (data) {
            defer.resolve(data);
          } else {
            defer.reject({
              status: jqXHR.status,
              responseText: jqXHR.responseText,
            });
          }
        },
        fail(jqXHR) {
          defer.reject({
            status: jqXHR.status,
            responseText: jqXHR.responseText,
          });
        },
      }
    );
    return defer.promise();
  },
  createCommonParty(partyVo) {
    const deferred = $.Deferred();
    AjaxUtil.doPost(Url.PartyCreate, partyVo, {
      async: false,
      done(data) {
        deferred.resolve(data);
      },
      fail(err) {
        deferred.reject(err);
      },
    });
    return deferred.promise();
  },
  updateCommonParty(partyVo) {
    const deferred = $.Deferred();
    //    AjaxUtil.doPut(Url.PartyUpdate + partyVo.PartyId, partyVo, {
    AjaxUtil.doPost(Url.PartyUpdate, partyVo, {
      async: false,
      done(data) {
        deferred.resolve(data);
      },
      fail(err) {
        deferred.reject(err);
      },
    });
    return deferred.promise();
  },
};
