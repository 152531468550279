const PartyConst = {
  PARTY_TYPE_IND: '1',
  PARTY_TYPE_ORG: '2',
  PARTY_TYPE_BRANCH: '3',
  PARTY_TYPE_VEN: '4',
  PARTY_ROLE_STATUS_ACTIVE: 1,
  PARTY_ROLE_STATUS_INACTIVE: 2,
  PARTY_ACCOUNT_STATUS_ACTIVE: 1,
  PARTY_ACCOUNT_STATUS_INACTIVE: 2,

  IS_PRIMARY: 'Y',
  FAIL: 'fail',
  SUCCESS: 'success',
  CHECK_PRIMARY_ADDRESS: 'Please select a Correspondence Address',
  CHECK_PRIMARY_ACCOUNT: 'Please select a Primary Bank',
  CHECK_PRIMARY_CONTACT: 'Please select a Primary Contact',
  CHECK_PRIMARY_SHAREHOLDER: 'Please select a Primary Shareholder',

  PARTY_IND_SEARCH_MODULE: 'PartyIndividual',
  PARTY_ORG_SEARCH_MODULE: 'PartyOrg',
  PARTY_VEN_SEARCH_MODULE: 'PartyVen',

  PARTY_BUSINESS_TYPE_IND: 'PartyIndividual-PartyIndividual',
  PARTY_BUSINESS_TYPE_ORG: 'PartyOrg-PartyOrg',
  PARTY_BUSINESS_TYPE_VEN: 'PartyVen-PartyVen',

  PARTY_BUSINESS_OBJECT_ID_IND: 1000000243,
  PARTY_BUSINESS_OBJECT_ID_ORG: 1000000245,
  PARTY_BUSINESS_OBJECT_ID_VEN: 77555630,

  PARTY_ROLE_CODE_CUSTOMER: 'CUS',
  PARTY_ROLE_CODE_IND_CUSTOMER: 'CUSIND',
  PARTY_ROLE_CODE_ORG_CUSTOMER: 'CUSORG',

  PARTY_ROLE_CODE_IND_DRIVER: 'DIRIND',

  PARTY_ROLE_CODE_PRODUCER: 'SCI',
  PARTY_ROLE_CODE_IND_PRODUCER: 'SCIND',
  PARTY_ROLE_CODE_ORG_PRODUCER: 'SCIORG',

  PARTY_ROLE_CODE_ORG_REINSURANCE: 'RECORG',
  PARTY_ROLE_CODE_ORG_INSURANCE: 'INCORG',

  PARTY_ROLE_CODE_RISKASSESSOR: 'RA',
  PARTY_ROLE_CODE_IND_RISKASSESSOR: 'RAIND',
  PARTY_ROLE_CODE_ORG_RISKASSESSOR: 'RAORG',

  PARTY_ROLE_CODE_IND_LAWYERLEGALFIRM: 'LAWIND',
  PARTY_ROLE_CODE_ORG_LAWYERLEGALFIRM: 'LGORG',

  PARTY_ROLE_CODE_GARAGE: 'GAR',
  PARTY_ROLE_CODE_IND_GARAGE: 'GARIND',
  PARTY_ROLE_CODE_ORG_GARAGE: 'GARORG',

  PARTY_ROLE_CODE_HEALTHCARE: 'HCP',
  PARTY_ROLE_CODE_IND_HEALTHCARE: 'HCPIND',
  PARTY_ROLE_CODE_ORG_HEALTHCARE: 'HCPORG',

  PARTY_ROLE_CODE_ORG_BANK: 'BAKORG',

  PARTY_ROLE_CODE_PAYEEPAYER: 'PAY',
  PARTY_ROLE_CODE_IND_PAYEEPAYER: 'PAYIND',
  PARTY_ROLE_CODE_ORG_PAYEEPAYER: 'PAYORG',

  PARTY_ROLE_CODE_CLAIMANT: 'CAT',
  PARTY_ROLE_CODE_IND_CLAIMANT: 'CATIND',
  PARTY_ROLE_CODE_ORG_CLAIMANT: 'CATORG',

  PARTY_ROLE_CODE_COLLECTOR: 'COL',
  PARTY_ROLE_CODE_IND_COLLECTOR: 'COLIND',
  PARTY_ROLE_CODE_ORG_COLLECTOR: 'COLORG',

  PARTY_ROLE_CODE_LIEN_HOLDER: 'LH',
  PARTY_ROLE_CODE_IND_LIEN_HOLDER: 'LHIND',
  PARTY_ROLE_CODE_ORG_LIEN_HOLDER: 'LHORG',

  PARTY_ROLE_CODE_IND_EXPERT: 'EXPIND',
  PARTY_ROLE_CODE_ORG_EXPERT: 'EXPORG',

  PARTY_ROLE_CODE_IND_EMPLOYEE: 'EMPIND',

  PARTY_ROLE_CODE_IND_CLAIMANT_INVESTIGATOR: 'CIGIND',
  PARTY_ROLE_CODE_ORG_CLAIMANT_INVESTIGATOR: 'CIGORG',

  PARTY_ROLE_CODE_INTRODUCER: 'ITD',
  PARTY_ROLE_CODE_IND_INTRODUCER: 'ITDIND',
  PARTY_ROLE_CODE_ORG_INTRODUCER: 'ITDORG',

  PARTY_ROLE_CODE_RECOVER_FROM: 'RF',
  PARTY_ROLE_CODE_IND_RECOVER_FROM: 'RFIND',
  PARTY_ROLE_CODE_ORG_RECOVER_FROM: 'RFORG',

  PARTY_ROLE_CODE_CAR_RENTAL: 'CAR',
  PARTY_ROLE_CODE_ORG_CAR_RENTAL: 'CARORG',

  PARTY_ROLE_CODE_PRODUCER_OFFICER_SUPPORT: 'POS',
  PARTY_ROLE_CODE_IND_PRODUCER_SUPPORT_OFFICER: 'PSOIND',
  PARTY_ROLE_CODE_ORG_PRODUCER_SUPPORT_OFFICER: 'PSOORG',

  CREATE_PARTY_ROLE_MODEL: {
    ProducerSupportOfficerOrgRole: {
      '@type':
        'PartyOrganizationalProducerSupportOfficerRole-PartyOrganizationalProducerSupportOfficerRole',
      BusinessObjectId: 76040401,
      RoleType: 'PSOORG',
    },
    ProducerSupportOfficerIndRole: {
      '@type':
        'PartyIndividualProducerSupportOfficerRole-PartyIndividualProducerSupportOfficerRole',
      BusinessObjectId: 76040435,
      RoleType: 'PSOIND',
    },
  },

  PARTY_ROLE_MAP: {
    CARORG: '02',
    CATORG: '01',
    CATIND: '01',
    CIGORG: '15',
    CIGIND: '15',
    CUSORG: '14',
    CUSIND: '14',
    DIRIND: '17',
    GARORG: '06',
    GARIND: '06',
    HCPORG: '05',
    HCPIND: '05',
    LAWIND: '03',
    LGORG: '16',
    PAYORG: '04',
    PAYIND: '04',
    RFORG: '08',
    RFIND: '08',
    SCIORG: '10',
    SCIND: '10',
    LHIND: '18',
    LHORG: '18',
    EXPIND: '19',
    EXPORG: '19',
  },

  COMMOM_PARTY_ROLE_INFO: {
    CARORG: {
      '@type':
        'PartyOrganizationalCarRentalRole-PartyOrganizationalCarRentalRole',
      BusinessObjectId: 75697723,
    },
    CATORG: {
      '@type':
        'PartyOrganizationalClaimantRole-PartyOrganizationalClaimantRole',
      BusinessObjectId: 75198469,
    },
    CATIND: {
      '@type':
        'PartyIndividualClaimantRole-PartyIndividualClaimantRole',
      BusinessObjectId: 75197995,
    },
    CIGORG: {
      '@type':
        'PartyOrganizationalClaimInvestigator-PartyOrganizationalClaimInvestigator',
      BusinessObjectId: 77881484,
    },
    CIGIND: {
      '@type':
        'PartyIndividualClaimInvestigator-PartyIndividualClaimInvestigator',
      BusinessObjectId: 75204495,
    },
    CUSORG: {
      '@type':
        'PartyOrganizationalCustomerRole-PartyOrganizationalCustomerRole',
      BusinessObjectId: 75180924,
    },
    CUSIND: {
      '@type':
        'PartyIndividualCustomerRole-PartyIndividualCustomerRole',
      BusinessObjectId: 75171996,
    },
    DIRIND: {
      '@type': 'PartyIndividualDriverRole-PartyIndividualDriverRole',
      BusinessObjectId: 75628452,
    },
    GARORG: {
      '@type':
        'PartyOrganizationalGarageRole-PartyOrganizationalGarageRole',
      BusinessObjectId: 75198539,
    },
    GARIND: {
      '@type': 'PartyIndividualGarageRole-PartyIndividualGarageRole',
      BusinessObjectId: 75198319,
    },
    HCPORG: {
      '@type':
        'PartyOrganizationalHealthCareRole-PartyOrganizationalHealthCareRole',
      BusinessObjectId: 75198804,
    },
    HCPIND: {
      '@type':
        'PartyIndividualHealthCareRole-PartyIndividualHealthCareRole',
      BusinessObjectId: 75198344,
    },
    LAWIND: {
      '@type': 'PartyIndividualLawyerRole-PartyIndividualLawyerRole',
      BusinessObjectId: 75198394,
    },
    LGORG: {
      '@type':
        'PartyOrganizationalLegalFirmRole-PartyOrganizationalLegalFirmRole',
      BusinessObjectId: 75199009,
    },
    PAYORG: {
      '@type':
        'PartyOrganizationalPayeePayerRole-PartyOrganizationalPayeePayerRole',
      BusinessObjectId: 75199034,
    },
    PAYIND: {
      '@type':
        'PartyIndividualPayeePayerRole-PartyIndividualPayeePayerRole',
      BusinessObjectId: 75198419,
    },
    RFORG: {
      '@type':
        'PartyOrganizationalRecoverFromRole-PartyOrganizationalRecoverFromRole',
      BusinessObjectId: 75434016,
    },
    RFIND: {
      '@type':
        'PartyIndividualRecoverFromRole-PartyIndividualRecoverFromRole',
      BusinessObjectId: 75433994,
    },
    SCIORG: {
      '@type':
        'PartyOrganizationalProducerRole-PartyOrganizationalProducerRole',
      BusinessObjectId: 75181044,
    },
    SCIND: {
      '@type':
        'PartyIndividualProducerRole-PartyIndividualProducerRole',
      BusinessObjectId: 75180947,
    },
    LHORG: {
      '@type':
        'PartyOrganizationalLienHolderRole-PartyOrganizationalLienHolderRole',
      BusinessObjectId: 77780001,
    },
    LHIND: {
      '@type':
        'PartyIndividualLienHolderRole-PartyIndividualLienHolderRole',
      BusinessObjectId: 77780002,
    },
    EXPORG: {
      '@type':
        'PartyOrganizationalExpertRole-PartyOrganizationalExpertRole',
      BusinessObjectId: 1399576,
    },
    EXPIND: {
      '@type': 'PartyIndividualExpertRole-PartyIndividualExpertRole',
      BusinessObjectId: 1399579,
    },
  },

  PARTY_ROLE_CODE_IND_FORGOTTEN: '27',

  PARTY_HISTORY_CREATE_ROLE: {
    '@type': 'PartyHistory-PartyHistory',
    BusinessObjectId: 75158352,
    HistoryType: 13,
  },
  PARTY_HISTORY_UPDATE_ROLE: {
    '@type': 'PartyHistory-PartyHistory',
    BusinessObjectId: 75158352,
    HistoryType: 14,
  },
  PARTY_HISTORY_UPDATE_BASIC_INFO: {
    '@type': 'PartyHistory-PartyHistory',
    BusinessObjectId: 75158352,
    HistoryType: 2,
  },
  PARTY_HISTORY_UPDATE_GDPR: {
    '@type': 'PartyHistory-PartyHistory',
    BusinessObjectId: 75158352,
    HistoryType: 16,
  },
  PARTY_HISTORY_UPDATE_GDPR_DATA_ACCESS: {
    '@type': 'PartyHistory-PartyHistory',
    BusinessObjectId: 75158352,
    HistoryType: 17,
  },
  PARTY_HISTORY_CONTACT: {
    '@type': 'PartyHistory-PartyHistory',
    BusinessObjectId: 75158352,
    HistoryType: 11,
  },

  PARTY_ROLE_NAME_LIEN_HOLDER: 'Lien Holder',
  PARTY_ROLE_NAME_LAWYER: 'Lawyer',
  PARTY_ROLE_NAME_LEGAL_FIRM: 'Legal Firm',
  PARTY_ROLE_NAME_GARAGE: 'Garage',
  PARTY_ROLE_NAME_RISK_ASSESSOR: 'Risk Assessor',
  PARTY_ROLE_NAME_CUSTOMER: 'Customer',
  PARTY_ROLE_NAME_PAYER: 'Payer/Payee',
  PARTY_ROLE_NAME_COLLECTOR: 'Collector',
  PARTY_ROLE_NAME_EMPLOYEE: 'Employee',
  PARTY_ROLE_NAME_DRIVER: 'Driver',
  PARTY_ROLE_NAME_CLAIM_INVESTIGATOR: 'Claim Investigator',
  PARTY_ROLE_NAME_CLAIMANT: 'Claimant',
  PARTY_ROLE_NAME_INSURANCE_COMPANY: 'Insurance Company',
  PARTY_ROLE_NAME_REINSURANCE_COMPANY: 'Reinsurance Company',
  PARTY_ROLE_NAME_BANK: 'Bank',
  PARTY_ROLE_NAME_HEALTH_CARE_PROVIDER: 'Health Care Provider',
  PARTY_ROLE_NAME_INTRODUCER: 'Introducer',
  PARTY_ROLE_NAME_PRODUCER: 'Producer',
  PARTY_ROLE_NAME_RECOVER_FROM: 'Claim Recovery Party',
  PARTY_ROLE_NAME_CAR_RENTAL: 'Car Rental Company',
  PARTY_ROLE_NAME_PRODUCER_SUPPORT_OFFICER:
    'Producer Support Officer',

  PARTY_PERMISSION_TRANS_TYPE: 75697416,
  PARTY_PERMISSION_TRANS_TYPE_CODE: 'Trans_Party',

  RoleTypeOrgMap: {
    GARIND: 'GARORG',
  },
  RoleTypeIndMap: {
    GARORG: 'GARIND',
  },
  RoleTypeOrgActiveMap: {
    SCIND1: 'SCIORG1',
    SCIORG1: 'SCIORG1',
  },
  RoleTypeIndActiveMap: {
    SCIORG1: 'SCIND1',
    SCIND1: 'SCIND1',
  },
  RoleTypeIndividualMap: {
    'Risk Assessor': 'RAIND',
    Introducer: 'ITDIND',
    Garage: 'GARIND',
    Producer: 'SCIND',
    Customer: 'CUSIND',
    Claimant: 'CATIND',
    'Claim Recovery Party': 'RFIND',
    'Producer Support Officer': 'PSOIND',
    Lawyer: 'LAWIND',
    'Payer/Payee': 'PAYIND',
    'Health Care Provider': 'HCPIND',
    Employee: 'EMPIND',
    'Claim Investigator': 'CIGIND',
    Driver: 'DIRIND',
    'Lien Holder': 'LHIND',
    Expert: 'EXPIND',
  },
  ClaimPartyRolelMap: {
    '01': 'Claimant',
    '02': 'Car Rental Company',
    '03': 'Lawyer',
    '04': 'Payer/Payee',
    '05': 'Health Care Provider',
    '06': 'Garage',
    '07': 'Legal Firm',
    '08': 'Claim Recovery Party',
  },
  RoleTypeOrgnizationMap: {
    'Risk Assessor': 'RAORG',
    Introducer: 'ITDORG',
    Garage: 'GARORG',
    Producer: 'SCIORG',
    Customer: 'CUSORG',
    Claimant: 'CATORG',
    'Claim Recovery Party': 'RFORG',
    'Car Rental Company': 'CARORG',
    'Producer Support Officer': 'PSOORG',
    'Payer/Payee': 'PAYORG',
    'Health Care Provider': 'HCPORG',
    'Legal Firm': 'LGORG',
    Employee: 'EMPORG',
    'Claim Investigator': 'CIGORG',
    'Lien Holder': 'LHORG',
    Expert: 'EXPORG',
  },

  DEFAULT_PAYMENT_METHOD_ID: 101,
  DEFAULT_CONTACT_LANGUAGE: 'el_GR',
};

export default PartyConst;
