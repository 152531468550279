import { UIDialog, MessageHelper } from 'RainbowUI';
import alt from '../alt';

const SettlementHistoryService = require('../service/SettlementHistoryService');

class SettlementHistoryAction {
  initData(caseId, taskCode) {
    SettlementHistoryService.loadHistory(caseId, taskCode).then(
      datas => {
        const data = datas;
        if (data.Status === 'OK') {
          for (let i = 0; i < data.Model.length - 1; i += 1) {
            const temp = data.Model[i];
            if (temp.SettlementNo == data.Model[i + 1].SettlementNo) {
              data.Model[i + 1].hasParent = true;
            }
          }
          this.dispatch(data.Model);
        } else {
          MessageHelper.error(data.Messages[0].Message, '');
        }
      }
    );
  }

  onShow(event) {
    const settleid = event.getParameter('settleid');
    const componentId = event.getParameter('componentId') || '';
    this.dispatch(settleid);
    UIDialog.show(`SettlementReadOnlyDialog${componentId}`);
  }

  cancelSubmit(cancelInfo, componentId, caseId, taskCode) {
    let flag = false;
    SettlementHistoryService.cancelSubmit(cancelInfo).then(data => {
      if (data.Status === 'OK') {
        UIDialog.hide(`settlementCancelDialog${componentId}`);
        this.actions.initData(caseId, taskCode);

        flag = true;
      } else {
        MessageHelper.error(data.Messages[0].Message, '');
      }
    });
    return flag;
  }

  getPayModeBySettlePayeeId(SettlePayeeId) {
    let payMode = null;
    SettlementHistoryService.getPayModeBySettlePayeeId(
      SettlePayeeId
    ).then(data => {
      if (data.Status === 'OK') {
        payMode = data.Model;
      } else {
        MessageHelper.error(data.Messages[0].Message, '');
      }
    });
    return payMode;
  }
}
export default alt.createActions(SettlementHistoryAction);
