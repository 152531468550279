const urlDefine = {
  ClaimProductTreeNode: `${env.serverUrl}productTree/`,
  ClaimProductSave: `${env.serverUrl}product/saveProduct`,
  ClaimProductDelete: `${env.serverUrl}product/deleteProduct`,
  ClaimProductGetProductDetail: `${env.serverUrl}product/productDetailByProductCode`,
  ClaimProductGetProductList: `${env.serverUrl}product/productList`,
  ClaimProductUpdate: `${env.serverUrl}product/updateProduct`,

  ClaimProductTypeSave: `${env.serverUrl}product/productType/saveProductType`,
  ClaimProductTypeDelete: `${env.serverUrl}product/productType/deleteProductType`,
  ClaimProductTypeGetProductTypeDetail: `${env.serverUrl}product/productType/getProductTypeDetail`,
  ClaimProductTypeGetProductTypeList: `${env.serverUrl}product/productType/getProductTypeList`,
  ClaimProductTypeUpdateProductType: `${env.serverUrl}product/productType/updateProductType`,
  ClaimProductTypeViewParameterMatrix: `${env.serverUrl}product/productType/productTypeViewParameterMatrix`,
  ClaimProductTypeCoverageCategoryList: `${env.serverUrl}product/productType/initOptionStatus`,
  ClaimProductTypeCoverageCategorySave: `${env.serverUrl}product/productType/saveOptionStatus`,
  ClaimSubclaimTypeList: `${env.serverUrl}product/productType/claimSubclaimTypeByPLCode`,
  ClaimProductCoverageListByProductCode: `${env.serverUrl}product/productCoverage/productCoveragesByProductCode`,
  ClaimProductCoverageSave: `${env.serverUrl}product/productCoverage/saveproductCoverage`,
  ClaimProductCoverageDelete: `${env.serverUrl}product/productCoverage/deleteProductCoverageCoverageCode`,
  ClaimProductCoverageList: `${env.serverUrl}product/productCoverage/getProductCoverageCoverageCodeList`,
  ClaimProductCoverageGetDetail: `${env.serverUrl}product/productCoverage/getProductCoverageDetailByCoverageCode`,
  ClaimProductCoverageUpdate: `${env.serverUrl}product/productCoverage/updateproductCoverage`,
  ClaimProductCoverageType_getTypeCodeAndTypeName: `${env.serverUrl}product/productCoverage/getCoverageTypeCodeAndName`,
  CodeTableDataList: `${env.serverUrl}/public/codetable/data/list/`,
  ClaimProductDefaultReserveSaveList: `${env.serverUrl}product/defaultReserve/saveproductDefaultReserve`,
  ClaimProductDefaultReserveGetList: `${env.serverUrl}product/defaultReserve/getDefaultReserveList`,

  ClaimProductRelationOfSubclaimTypeDamage: `${env.serverUrl}product/subclaim/relation/`,
  ClaimProductRelationOfSubclaimTypeDamageLoad: `${env.serverUrl}product/subclaim/relation/load/`,
  ClaimProductRelationOfSubclaimTypeDamageUpdate: `${env.serverUrl}product/subclaim/relation/update/`,
  ClaimProductRelationOfSubclaimTypeLossOfCause: `${env.serverUrl}product/causeOfLoss/relation/`,
  ClaimProductRelationOfSubclaimTypeLossOfCauseLoad: `${env.serverUrl}product/causeOfLoss/relation/load/`,
  ClaimProductRelationOfSubclaimTypeLossOfCauseUpdate: `${env.serverUrl}product/causeOfLoss/relation/update/`,
  ClaimProductRelationOfSubclaimTypeCoverageCategory: `${env.serverUrl}product/coverageCategory/relation/`,
  ClaimProductRelationOfSubclaimTypeCoverageCategoryLoad: `${env.serverUrl}product/coverageCategory/relation/load/`,
  ClaimProductRelationOfSubclaimTypeCoverageCategoryUpdate: `${env.serverUrl}product/coverageCategory/relation/update/`,
  SubclaimCoverageCategoryReserve: `${env.serverUrl}product/reserve/relation/`,
  SubclaimCoverageCategoryReserveLoad: `${env.serverUrl}product/reserve/relation/load/`,
  SubclaimCoverageCategoryReserveUpdate: `${env.serverUrl}product/reserve/relation/update/`,
  SubclaimCoverageCategoryReservePayment: `${env.serverUrl}product/payment/relation/`,
  SubclaimCoverageCategoryReservePaymentLoad: `${env.serverUrl}product/payment/relation/load/`,
  SubclaimCoverageCategoryReservePaymentUpdate: `${env.serverUrl}product/payment/relation/update/`,

  ClaimCommonReserveTypeLoad: `${env.serverUrl}product/getReserverTypeList`,
  ClaimCommonReserveTypeSave: `${env.serverUrl}product/saveReserveType`,
  ClaimCommonSettlementTypeLoad: `${env.serverUrl}product/getSettlementTypeList`,
  ClaimCommonSettlementTypeRemove: `${env.serverUrl}product/removeSettlementType/`,
  ClaimCommonSettlementTypeSave: `${env.serverUrl}product/saveSettlementType`,
  ClaimCommonSettlementTypeUpdate: `${env.serverUrl}product/updateSettlementType`,

  ClaimCoverageTypeOfProductLineLoad: `${env.serverUrl}productLine/getCoverageTypeOfProductLineList`,
  ClaimCoverageTypeOfProductLineSave: `${env.serverUrl}productLine/saveCoverageTypeOfProductLineList`,
  ClaimCoverageTypeOfProductLineRemove: `${env.serverUrl}productLine/removeCoverageTypeOfProductLineList/`,
  ClaimLossCauseOfProductLineLoad: `${env.serverUrl}productLine/getLossCauseOfProductLineList`,
  ClaimLossCauseOfProductLineSave: `${env.serverUrl}productLine/saveLossCauseOfProductLineList`,
  ClaimLossCauseOfProductLineRemove: `${env.serverUrl}productLine/removeLossCauseOfProductLineList/`,
  ClaimSubclaimTypeOfProductLineLoad: `${env.serverUrl}productLine/getSubclaimTypeOfProductLineList`,
  ClaimSubclaimTypeOfProductLineSave: `${env.serverUrl}productLine/saveSubclaimTypeOfProductLineList`,
  ClaimSubclaimTypeOfProductLineRemove: `${env.serverUrl}productLine/removeSubclaimTypeOfProductLineList/`,
  ClaimProductLineLoad: `${env.serverUrl}productLine/getProductLineList`,
  ClaimProductLineOfList: `${env.serverUrl}productLine/load`,
  ClaimProductLineUpdate: `${env.serverUrl}productLine/update/`,

  ClaimCoverageTypeOfProductLineUpdate: `${env.serverUrl}productLine/updateCoverageTypeOfProductLineList`,
  ClaimLossCauseOfProductLineUpdate: `${env.serverUrl}productLine/updateLossCauseOfProductLineList`,
  ClaimSubclaimTypeOfProductLineUpdate: `${env.serverUrl}productLine/updateSubclaimTypeOfProductLineList`,

  ClaimCoverageTypeOfProductLineLoadByCoverageCategoryCode: `${env.serverUrl}productLine/getCoverageTypeOfProductLineListByCoverageCategoryCode`,
  getLossCauseOfProductLineListByCauseOfLossCode: `${env.serverUrl}productLine/getLossCauseOfProductLineListByCauseOfLossCode`,
  ClaimSubclaimTypeOfProductLineLoadBySubclaimTypeCode: `${env.serverUrl}productLine/getSubclaimTypeOfProductLineListBySubclaimTypeCode`,
  ClaimProductRelationOfSubclaimTypeDamageBySubClaimType: `${env.serverUrl}product/subclaim/relation/bySubclaimTypeCode`,
  ClaimProductRelationOfSubclaimTypeLossOfCauseByCauseOfLoss: `${env.serverUrl}product/causeOfLoss/relation/byLossCauseCode`,
  ClaimProductRelationOfSubclaimTypeCoverageCategoryBySubclaimTypeCode: `${env.serverUrl}product/coverageCategory/relation/bySubclaimTypeCode`,
  SubclaimCoverageCategoryReserveBySubclaimTypeCodeAndCoverageCategoryCode: `${env.serverUrl}product/reserve/relation/bySubclaimTypeCodeAndCoverageCategoryCode`,
  SubclaimCoverageCategoryReservePaymentByConditions: `${env.serverUrl}product/payment/relation/byConditions`,
  ClaimProductTypeViewParameterMatrixByConditions: `${env.serverUrl}product/productType/productTypeViewParameterMatrixByConditions`,
  ClaimProductCoverageListByConditions: `${env.serverUrl}product/productCoverage/getProductCoverageCoverageCodeListByConditions`,

  Exit: env.CLAIM_HOME_URL,
};
export default urlDefine;
