import alt from '../alt';
import AttachmentAction from '../action/AttachmentAction';

class AttachmentStore {
  constructor() {
    this.attachments = [];

    this.bindListeners({
      handleInitAttachment: AttachmentAction.INIT_ATTACHMENT,
    });
  }

  handleInitAttachment(attachments) {
    this.attachments = attachments;
  }
}

export default alt.createStore(AttachmentStore, AttachmentStore);
