import {
  UIParam,
  UILink,
  UICell,
  UIBox,
  UIButton,
  UIDataTable,
  UIColumn,
} from 'RainbowUI';
import ProductTreeNodeStore from '../store/ProductTreeNodeStore';

const ProductTypeListAction = require('../action/ProductTypeListAction');
const ProductTypeAction = require('../action/ProductTypeAction');
const RemoveConfirmDialog = require('./RemoveConfirmDialog');

const ProductTypeList = React.createClass({
  getInitialState() {
    return {
      ProductTreeEntity: {},
      isUpdate: true,
    };
  },

  componentDidMount() {
    ProductTreeNodeStore.listen(this.onStoreStateChange);
  },

  componentWillUnmount() {
    ProductTreeNodeStore.unlisten(this.onStoreStateChange);
  },

  onStoreStateChange(storeState) {
    if (!_.isEmpty(storeState.ProductTreeEntity)) {
      this.setState({
        ProductTreeEntity: storeState.ProductTreeEntity,
      });
    }
  },
  isUpdate() {
    this.setState({ isUpdate: true });
  },
  onNewProductType() {
    ProductTypeAction.newProducttype();
  },
  render() {
    return (
      <div>
        <UIDataTable
          indexable="false"
          provider={ProductTypeListAction.getProductTypeList}
          id="productTypeListId"
        >
          <UIColumn
            headerTitle={i18n.ClaimProduct.ProductTypeCode}
            width="15%"
            value="ProductTypeCode"
          />
          <UIColumn
            headerTitle={i18n.ClaimProduct.ProductTypeName}
            width="20%"
            value="ProductTypeName"
          />
          <UIColumn
            headerTitle={i18n.ClaimProduct.ProductTypeDescription}
            width="50%"
            value="ProductTypeDesc"
          />
          <UIColumn
            headerTitle={i18n.ClaimProduct.Action}
            width="15%"
            render={data => {
              return (
                <UILink
                  value={i18n.ClaimProduct.Remove}
                  onClick={
                    ProductTypeListAction.removeProductTypeDialog
                  }
                >
                  <UIParam name="data" value={data} />
                </UILink>
              );
            }}
          />
        </UIDataTable>
        <UICell>
          <UIBox direction="left">
            <UIButton
              value={i18n.ClaimProduct.New}
              onClick={this.onNewProductType}
            />
          </UIBox>
        </UICell>
        <RemoveConfirmDialog parentFunction={this.isUpdate} />
      </div>
    );
  },
});
export default ProductTypeList;
