import {
  UIPanel,
  UIText,
  UIParam,
  UIDataTable,
  UIDateTimePicker,
  UISelect,
  UIColumn,
  UICurrency,
  UILink,
} from 'RainbowUI';
import { CodeTableAction, CodeTableCstUI } from 'ClaimCommonConstant';

const AppraisalReadOnlyDialog = require('./AppraisalReadOnlyDialog');

const AppraisalHistory = React.createClass({
  getInitialState() {
    return { appraisalHistory: this.props.appraisalHistory };
  },

  componentWillMount() {
    if (this.state.appraisalHistory) {
      const appraisalHistoryInfo = this.state.appraisalHistory;
      for (let i = 0; i < appraisalHistoryInfo.length; i += 1) {
        const laborNum =
          appraisalHistoryInfo[i].AppraisalInfo.appraisalVehicle
            .claimAppraisalVehicleLabor.length;
        const partNum =
          appraisalHistoryInfo[i].AppraisalInfo.appraisalVehicle
            .claimAppraisalVehiclePart.length;
        const numberOfLossItems = laborNum + partNum;
        appraisalHistoryInfo[
          i
        ].AppraisalInfo.NumberOfLossItems = numberOfLossItems;
      }
      this.state.appraisalHistory = appraisalHistoryInfo;
    }
  },

  showAppraisalReadOnly(event) {
    this.refs.AppraisalReadOnlyDialog_ref.showDialog(
      event.getParameter('seqNo'),
      this.props.parentComponent.state.CurrencyCode
    );
  },

  render() {
    const _self = this;
    const { parentComponent } = this.props;
    const { CurrencyCode } = parentComponent.state;
    const { appraisalHistory } = this.state;
    let index = 0;
    return (
      <UIPanel
        panelTitle={i18n.ClaimAppraisal.AppraisalHistory}
        toggleable="true"
      >
        <UIDataTable
          pageable="false"
          indexable="false"
          value={appraisalHistory}
        >
          <UIColumn
            headerTitle={i18n.ClaimAppraisal.SeqNo}
            width="20%"
            render={data => {
              return (
                <UILink
                  value={data.AppraisalInfo.SeqNo}
                  onClick={_self.showAppraisalReadOnly}
                >
                  <UIParam name="seqNo" value={data} />
                </UILink>
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.ClaimAppraisal.TransactionDate}
            render={data => {
              return (
                <UIDateTimePicker
                  value={data.AppraisalInfo.ApproveDate}
                  placeHolder="MM/DD/YYYY HH:mm:ss"
                  format="MM/DD/YYYY HH:mm:ss"
                  io="out"
                />
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.ClaimAppraisal.OperatedBy}
            value="OperatedBy"
            render={data => {
              return (
                <UISelect
                  value={data.AppraisalInfo.Submitter}
                  codeTable={CodeTableAction.loadDataList(
                    CodeTableCstUI.ClaimUser,
                    null
                  )}
                  io="out"
                />
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.ClaimAppraisal.NumberOfLossItems}
            value="NumberOfLossItems"
            render={data => {
              return (
                <UIText
                  value={data.AppraisalInfo.NumberOfLossItems}
                  io="out"
                />
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.ClaimAppraisal.TotalNetAmount}
            value="TotalNetAmount"
            render={data => {
              index += 1;
              return (
                <UICurrency
                  id={`CURRENCY_CODE_VEHICLE_Appraisal_TotalNetAmount${index}`}
                  value={
                    data.AppraisalInfo.appraisalVehicle.TotalNetAmount
                  }
                  unit={window.EU00.getCurrencySignByCurrencyCode(
                    CurrencyCode
                  )}
                  enabled="false"
                />
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.ClaimAppraisal.Remark}
            value="PropertyRemark"
            render={data => {
              return (
                <UIText
                  value={data.AppraisalInfo.AppraisalRemark}
                  io="out"
                />
              );
            }}
          />
        </UIDataTable>
        <AppraisalReadOnlyDialog
          ref="AppraisalReadOnlyDialog_ref"
          parentComponet={this}
        />
      </UIPanel>
    );
  },
});
export default AppraisalHistory;
