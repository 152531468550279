import {
  UIDialog,
  UIBox,
  UIText,
  UISmartPanelGrid,
  UITextarea,
  UIRadio,
  UIButton,
} from 'RainbowUI';
import BindToMixin from 'react-binding';
import CatastropheAction from '../../action/CatastropheAction';
import CodeTableAction from '../../action/CodeTableAction';
import CodeTableCstUI from '../../constant/CodeTableCstUI';

const CatastropheDialog = React.createClass({
  mixins: [BindToMixin],
  getInitialState() {
    return { accident: { ValidFlag: 'Y' }, readOnly: true };
  },
  componentWillMount() {
    this.state.accident.CaseId = this.props.caseId;
    this.state.accident.ClaimNumber = this.props.claimNumber;
  },
  save() {
    CatastropheAction.saveCatastrophe(
      this.state.accident,
      this.state.readOnly
    );
    this.props.parnetFunction();
    CodeTableAction.clearCacheAll();
  },
  showDialog(datas) {
    let data = datas;
    if (data) this.setState({ readOnly: false });
    else {
      data = { ValidFlag: 'Y' };
      this.setState({ readOnly: true });
    }

    this.setState({ accident: data });
    UIDialog.show('catastropheDialog');
  },
  render() {
    const { readOnly } = this.state;
    return (
      <UIDialog
        id="catastropheDialog"
        title={i18n.ClaimCommon.New}
        width="80%"
      >
        <UISmartPanelGrid id="catastropheDialogUISmart">
          <UIText
            required="true"
            enabled={readOnly}
            validationGroup="catastrophe"
            label={i18n.ClaimCommon.AccidentCode}
            valueLink={this.bindToState('accident', 'AccidentCode')}
          />
          <UIText
            required="true"
            validationGroup="catastrophe"
            label={i18n.ClaimCommon.AccidentName}
            valueLink={this.bindToState('accident', 'AccidentName')}
          />
          <UIRadio
            required="true"
            validationGroup="catastrophe"
            label={i18n.ClaimCommon.AccidentValidFlag}
            valueLink={this.bindToState('accident', 'ValidFlag')}
            codeTable={CodeTableAction.loadDataList(
              CodeTableCstUI.ClaimYesOrNo,
              null
            )}
          />
          <UITextarea
            colspan="2"
            label={i18n.ClaimCommon.AccidentDesc}
            valueLink={this.bindToState('accident', 'AccidentDesc')}
          />
        </UISmartPanelGrid>

        <UIBox direction="center">
          <UIButton
            value={i18n.ClaimCommon.Submit}
            onClick={this.save}
            causeValidation="true"
            validationGroup="catastrophe"
          />
          <UIButton
            value={i18n.ClaimCommon.Cancel}
            position="right"
            buttonType="cancel"
          />
        </UIBox>
      </UIDialog>
    );
  },
});
export default CatastropheDialog;
