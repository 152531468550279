import {
  UIBox,
  UIButton,
  UIPanel,
  UITextSelect,
  UIText,
  UIUpdatePanel,
  UIDateTimePicker,
  MessageHelper,
  UpdateContext,
  UISmartPanelGrid,
} from 'RainbowUI';
import BindToMixin from 'react-binding';
import { CodeTableAction, CodeTableCstUI } from 'ClaimCommonConstant';
import NoticePoolStore from '../store/NoticePoolStore';

const NoticePoolAction = require('../action/NoticePoolAction');

const NoticePoolSearch = React.createClass({
  mixins: [BindToMixin],

  getInitialState() {
    return {
      noticeEntityCriteria: { '@type': 'ClaimNotice-ClaimNotice' },
    };
  },

  componentWillMount() {
    NoticePoolStore.listen(this.onStoreStateChange);
  },

  componentWillUnmount() {
    NoticePoolStore.unlisten(this.onStoreStateChange);
  },
  onStoreStateChange(storeState) {
    this.setState({
      noticeEntityCriteria: storeState.noticeSearchOptions,
    });
  },

  clickSearch() {
    NoticePoolAction.searchNotice(this.state.noticeEntityCriteria);
  },

  reset() {
    this.setState({
      noticeEntityCriteria: { '@type': 'ClaimNotice-ClaimNotice' },
    });
  },
  validateDateOfDueDateFromTo(event) {
    console.log(event);
    const Conditions = this.state.noticeEntityCriteria;
    if (Conditions.DateFormTime > Conditions.DateToTime) {
      MessageHelper.warning(
        i18n.ClaimNotice.WarnMsg.DateToCannotEarlyThanFrom,
        null,
        MessageHelper.POSITION_TOP_RIGHT
      );
      if (event.component.componentId === 'qLossDateFrom') {
        Conditions.DateFormTime = null;
      } else {
        Conditions.DateToTime = null;
      }
      console.log(Conditions);
      UpdateContext.forceUpdate('fresh1');
      UpdateContext.forceUpdate('fresh2');
      this.setState({ noticeEntityCriteria: Conditions });
    }
  },
  render() {
    return (
      <UIPanel panelTitle={i18n.ClaimNotice.ClaimNoticePool}>
        <UISmartPanelGrid>
          <UIText
            id="noticeNumber"
            label={i18n.ClaimNotice.NoticeNumber}
            valueLink={this.bindToState(
              'noticeEntityCriteria',
              'NoticeNo'
            )}
          />
          <UIText
            id="policyNumber"
            label={i18n.ClaimCommon.PolicyNumber}
            valueLink={this.bindToState(
              'noticeEntityCriteria',
              'PolicyNo'
            )}
          />
          <UIText
            id="contactPerson"
            label={i18n.ClaimCommon.ContactPerson}
            valueLink={this.bindToState(
              'noticeEntityCriteria',
              'ContactPerson'
            )}
          />
          <UIText
            id="policyholderName"
            label={i18n.ClaimCommon.PolicyHolderName}
            valueLink={this.bindToState(
              'noticeEntityCriteria',
              'PolicyHolderName'
            )}
          />
          <UIUpdatePanel id="fresh1">
            <UIDateTimePicker
              id="qLossDateFrom"
              format="MM/DD/YYYY"
              label={i18n.ClaimCommon.LossDateSectionFrom}
              valueLink={this.bindToState(
                'noticeEntityCriteria',
                'DateFormTime'
              )}
              onChange={this.validateDateOfDueDateFromTo}
            />
          </UIUpdatePanel>
          <UIUpdatePanel id="fresh2">
            <UIDateTimePicker
              id="qLossDateTo"
              format="MM/DD/YYYY"
              label={i18n.ClaimCommon.LossDateSectionTo}
              valueLink={this.bindToState(
                'noticeEntityCriteria',
                'DateToTime'
              )}
              onChange={this.validateDateOfDueDateFromTo}
            />
          </UIUpdatePanel>
          <UITextSelect
            id="productCode"
            label={i18n.ClaimCommon.ProductName}
            codeTable={CodeTableAction.loadDataList(
              CodeTableCstUI.ProductTypeCode,
              null
            )}
            required="false"
            validationGroup="customerSearch"
            valueLink={this.bindToState(
              'noticeEntityCriteria',
              'ProductTypeCode'
            )}
          />
          <UIText
            id="operationBy"
            label={i18n.ClaimNotice.OperationBy}
            valueLink={this.bindToState(
              'noticeEntityCriteria',
              'OperationBy'
            )}
          />
        </UISmartPanelGrid>

        <UIBox>
          <UIButton
            value={i18n.ClaimCommon.Filter}
            onClick={this.clickSearch}
          />
          <UIButton
            value={i18n.ClaimCommon.Reset}
            onClick={this.reset}
          />
          />
        </UIBox>
      </UIPanel>
    );
  },
});
export default NoticePoolSearch;
