import {
  UIParam,
  UISelect,
  UILink,
  UIColumn,
  UIDataTable,
  UIDateTimePicker,
  UIPanel,
  UIPercent,
} from 'RainbowUI';
import DefinedCodeTable from '../../../codetable/CodeTable';
import PartyShareholderDialogReadOnly from './PartyShareholderDialogReadOnly';

const PartyShareholderInfoReadOnly = React.createClass({
  getInitialState() {
    const { party } = this.props;
    let partyShareholderList = party.PartyShareHolderList;
    if (!partyShareholderList) {
      partyShareholderList = [];
    }

    const totalNum = partyShareholderList.length;
    const categroyCodeTable = DefinedCodeTable.getCategroyCodeTable();
    const nationalityCodeTable = DefinedCodeTable.getCountry();
    return {
      party,
      partyShareholderList,
      TotalNum: totalNum,
      categroyCodeTable,
      nationalityCodeTable,
    };
  },

  viewHandler(event) {
    const partyShareholder = event.getParameter('data');
    this.refs.partyShareholderDialogReadOnly.showDialog(
      partyShareholder
    );
  },

  render() {
    return (
      <div>
        <UIPanel panelTitle={i18n.ShareholderDetail}>
          {this.renderDataTable()}
        </UIPanel>
        <PartyShareholderDialogReadOnly ref="partyShareholderDialogReadOnly" />
      </div>
    );
  },

  renderDataTable() {
    const {
      partyShareholderList,
      categroyCodeTable,
      nationalityCodeTable,
    } = this.state;
    return (
      <UIDataTable
        id="partyIndShareholderInfo"
        value={partyShareholderList}
        indexable="false"
      >
        <UIColumn
          headerTitle={i18n.ShareholderName}
          value="ShareholderName"
          render={data => {
            return (
              <div>
                <UILink
                  value={data.ShareholderName}
                  enabled="true"
                  disabled="false"
                  onClick={this.viewHandler}
                >
                  <UIParam name="data" value={data} />
                </UILink>
              </div>
            );
          }}
        />
        <UIColumn
          headerTitle={i18n.ShareholderCategory}
          value="ShareholderCategory"
        >
          <UISelect codeTable={categroyCodeTable} io="out" />
        </UIColumn>
        <UIColumn
          headerTitle={i18n.ShareholderPercentage}
          value="ShareholderPercentage"
        >
          <UIPercent io="out" />
        </UIColumn>
        <UIColumn
          headerTitle={i18n.IDNumberOrCompanyRegNumber}
          value="IdNumberOrCompanyRegNumber"
        />
        <UIColumn headerTitle={i18n.BirthDay} value="DateOfBirth">
          <UIDateTimePicker io="out" format="MM/DD/YYYY" />
        </UIColumn>
        <UIColumn
          headerTitle={i18n.CountryOfIncorporation}
          value="Country"
        >
          <UISelect codeTable={nationalityCodeTable} io="out" />
        </UIColumn>
      </UIDataTable>
    );
  },
});
export default PartyShareholderInfoReadOnly;
