import { DataContext, MessageHelper } from 'RainbowUI';
import alt from '../alt';
import AssignmentApproachService from '../service/AssignmentApproachService';

class AssignmentApproachAction {
  initAssignmentApproach() {
    let result = null;
    AssignmentApproachService.loadAssignmentApproach().then(
      data => {
        result = data;
      },
      ex => {
        MessageHelper.error(
          JSON.stringify(ex),
          i18n.CliamCommon.ErrorMsg.Error_0001,
          MessageHelper.POSITION_TOP_FULL_WIDTH
        );
      }
    );

    return _.sortBy(result.Model, 'TaskCode');
  }

  save() {
    AssignmentApproachService.saveAssignmentApproach();
  }

  saveChangeData(event) {
    const data = event.getParameter('data');
    data.AutomaticAssignmentApproach = event.newValue;
    let list = DataContext.get('ChangeData');
    if (!list) {
      list = [];
    }
    delete data.dataIndex;
    list.push(data);
    DataContext.put('ChangeData', list);
  }

  saveChangeDataForCheckBox(event) {
    const data = event.getParameter('data');
    data.ManualAssignCheckedData = event.newValue;
    data.ManualAssignCheckedData = event.newValue;
    let list = DataContext.get('ChangeData');
    if (!list) {
      list = [];
    }
    delete data.dataIndex;
    list.push(data);
    DataContext.put('ChangeData', list);
  }
}
export default alt.createActions(AssignmentApproachAction);
