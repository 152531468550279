import {
  DataContext,
  UpdateContext,
  UIDialog,
  Caller,
} from 'RainbowUI';
import urlConfig from '../../../../UrlConfig';

export default class NoteAction {
  initDate() {
    const noteModel = {
      NoteType: null,
      RefId: null,
      NoteContent: null,
    };
    DataContext.put('noteModel', noteModel);
  }

  onClickedNote(event) {
    const noteModel = {
      refId: event.getParameter('refId'),
      noteType: event.getParameter('noteType'),
      tableId: event.getParameter('tableId'),
    };
    DataContext.put('noteParameter', noteModel);
    UIDialog.show('noteDialog');
  }

  getDataValue() {
    const noteParameter = DataContext.get('noteParameter');
    const noteModel = {
      refId: noteParameter.refId,
      noteType: noteParameter.noteType,
    };
    let result = [];
    const url = urlConfig.loadNotes;
    Caller.call(url, noteModel, {
      method: 'GET',
      async: false,
      block: true,
    }).then(data => {
      result = data;
    });
    return result;
  }

  onClickedExit() {
    new NoteAction().initDate();
    UIDialog.hide('noteDialog');
    const noteParameter = DataContext.get('noteParameter');
    UpdateContext.forceUpdate(noteParameter.tableId);
  }
}
