import {
  UIDialog,
  UIDialogFooter,
  UICell,
  UIButton,
} from 'RainbowUI';
import PropTypes from 'prop-types';

const LimitDeductDialog = React.createClass({
  propTypes: {
    index: PropTypes.string,
  },

  getDefaultProps() {
    return { index: '_0' };
  },
  getInitialState() {
    return { LimitInfo: '', DedutInfo: '' };
  },

  showLimitDeductDialog(data) {
    if (data) {
      let limit;
      let limitValue;
      if (data.PolicyLimit) {
        limit = data.PolicyLimit.PolicyLimitValue[0];
        if (limit) {
          limitValue = limit.Term;
        }
      }
      let limintInfo = '';
      if (limitValue) {
        limintInfo = `   ${limitValue}`;
      } else if (limit)
        limintInfo = `   ${limit.UnitValue} ${limit.UnitType} ${limit.UnitAmount}`;

      let dedut;
      let dedutValueInfo;
      if (data.PolicyDeductible) {
        dedut = data.PolicyDeductible.PolicyDeductibleValue[0];
      }

      if (dedut) {
        dedutValueInfo = dedut.Term;
      }
      let dedutInfo = '';
      if (dedutInfo) {
        dedutInfo = dedutValueInfo;
      } else if (dedut)
        dedutInfo = `   ${dedut.UnitValue} ${dedut.UnitType} ${dedut.UnitAmount}`;

      this.setState({ LimitInfo: limintInfo, DedutInfo: dedutInfo });
    }
    UIDialog.show(
      `LimitDeductDialog${this.props.index}${this.props.level}`
    );
  },

  clickCancel() {
    UIDialog.hide('LimitDeductDialog');
  },

  render() {
    const { index, level } = this.props;
    return (
      <div>
        <UIDialog
          id={`LimitDeductDialog${index}${level}`}
          width="600px"
          height="300px"
          modal="false"
        >
          {this.getMessage()}

          <UIDialogFooter>
            <UIButton
              value={i18n.ClaimCommon.Cancel}
              buttonType="cancel"
              onClick={this.clickCancel}
            />
          </UIDialogFooter>
        </UIDialog>
      </div>
    );
  },

  getMessage() {
    const { LimitInfo } = this.state;
    return (
      <div
        style={{ borderBottom: '1px solid #cbcbcb' }}
        className="clearfix"
      >
        <h5
          style={{
            borderBottom: '1px solid #cbcbcb',
            marginBottom: '0px',
          }}
        >
          <UICell width="12">{`Limit Info is ${LimitInfo}`}</UICell>
          <br />
          <UICell width="10">{this.getDedutInfo}</UICell>
        </h5>
      </div>
    );
  },

  getDedutInfo() {
    if (this.state.DedutInfo) {
      return `Deduction Info is ${this.state.DedutInfo}`;
    }
  },
});
export default LimitDeductDialog;
