import { UIDialog, UIButton, UIBox } from 'RainbowUI';
import BindToMixin from 'react-binding';
import PropTypes from 'prop-types';

const AppraisalBodyInfo = require('./AppraisalBodyInfo');
const AppraisalBodyLossItems = require('./AppraisalBodyLossItems');
const AppraisalBodyRemark = require('./AppraisalBodyRemark');
const ApprovalHistory = require('./../vehicle/ApprovalHistory');

const AppraisalBodyReadOnlyDialog = React.createClass({
  mixins: [BindToMixin],

  propTypes: {
    objectId: PropTypes.string.isRequired,
  },

  showDialog(data) {
    this.setState({ AppraisalInfo: data.AppraisalInfo });
    this.setState({
      appraisalBodyInjury: data.AppraisalInfo.appraisalBodyInjury,
    });
    this.setState({ ApprovalHistoryVO: data.ApprovalHistoryVO });
    this.setState({ CurrencyCode: data.CurrencyCode });
    UIDialog.show('AppraisalBodyReadOnlyDialog');
  },

  getInitialState() {
    return {
      AppraisalInfo: {},
      appraisalBodyInjury: {},
      ApprovalHistoryVO: {},
      CurrencyCode: '',
    };
  },

  onClose() {
    UIDialog.hide('AppraisalBodyReadOnlyDialog');
  },

  render() {
    const {
      AppraisalInfo,
      appraisalBodyInjury,
      CurrencyCode,
    } = this.state;
    const _self = this;
    if (!AppraisalInfo) {
      return (
        <UIDialog
          id="AppraisalBodyReadOnlyDialog"
          title={i18n.ClaimAppraisal.AppraisalHistoryDetail}
          width="90%"
          height="1000px"
          modal="false"
        />
      );
    }

    const ClaimAppraisalBodyInjury = this.bindToState(
      'AppraisalInfo',
      'appraisalBodyInjury'
    );
    const O = JSON.stringify(_self.state.ApprovalHistoryVO);
    const ApprovalHistoryVO = JSON.parse(O);
    return (
      <UIDialog
        id="AppraisalBodyReadOnlyDialog"
        title={i18n.ClaimAppraisal.AppraisalHistoryDetail}
        width="90%"
        height="1000px"
        modal="false"
      >
        <AppraisalBodyInfo
          history
          readOnly
          parentComponent={this}
          appraisalBodyInjuryInfo={appraisalBodyInjury}
        />
        <AppraisalBodyLossItems
          history
          readOnly
          parentComponent={this}
          ClaimAppraisalBodyInjury={ClaimAppraisalBodyInjury}
          currencyCode={CurrencyCode}
        />
        <AppraisalBodyRemark
          readOnly
          parentComponent={this}
          appraisalBodyInjuryInfo={appraisalBodyInjury}
        />
        <ApprovalHistory
          history
          readOnly
          parentComponent={this}
          appraisalApproveHistory={ApprovalHistoryVO}
          currencyCode={CurrencyCode}
        />
        <UIBox direction="center">
          <UIButton
            value={i18n.ClaimAppraisal.Back}
            position="right"
            onClick={this.onClose}
          />
        </UIBox>
      </UIDialog>
    );
  },
});
export default AppraisalBodyReadOnlyDialog;
