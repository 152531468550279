import { DataContext, CodeTable, AjaxUtil } from 'RainbowUI';
import Url from '../url/Url';
import ClaimMatrixService from '../service/ClaimMatrixService';

export default {
  initQuery() {
    const queryModel = {
      Complex: {},
    };

    DataContext.put('queryModel', queryModel);
  },
  getProductLineList(pageindex, pagesize) {
    let result = {};
    const codeList = [];
    AjaxUtil.doGet(
      Url.ClaimProductLineLoad,
      {},
      {
        async: false,
        done(data) {
          result = data.Model.claimProductLineListByPage;
        },
        fail() {
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
        },
      }
    );
    for (let i = 0; i < result.length; i += 1) {
      if (result[i].ProductLineCode && result[i].ProductLineName) {
        codeList.push({
          id: result[i].ProductLineCode,
          text: result[i].ProductLineName,
        });
      }
    }
    return new CodeTable(codeList);
  },

  searchProductLineList(pageindex, pagesize) {
    let result = {};
    AjaxUtil.doGet(
      `${Url.ClaimProductLineLoad}?pageindex=${pageindex -
      1}&pagesize=${pagesize}`,
      {},
      {
        async: false,
        done(data) {
          result = data.Model;
        },
        fail() {
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
        },
      }
    );
    return {
      count: result.claimProductLineAmount,
      result: result.claimProductLineListByPage,
    };
  },

  getQueryClaimProductProvider(pageIndex, pageSize) {
    const pageNo = (pageIndex - 1) / pageSize + 1;
    const queryModel = DataContext.get('queryModel');
    const endRowNum = pageIndex + Number(pageSize);
    const condition = {
      Conditions: {
        ProductLineCode: queryModel.Complex.productLineCode,
        ProductTypeCode: queryModel.Complex.productTypeCode,
        ProductCode: queryModel.Complex.productCode,
        Start_rowNum: pageIndex,
        End_rowNum: endRowNum,
      },
      PageNo: pageNo,
      PageSize: pageSize,
    };
    let dataTemp = null;
    const surl = Url.getClaimProductByConditions;
    AjaxUtil.doPost(surl, condition, {
      async: false,
      done(data) {
        dataTemp = data.Model;
      },
    });
    return {
      count: dataTemp.countClaimProduct,
      result: dataTemp.claimProductListByPage,
    };
  },
  downLoadByProductLine(recordData) {
    let parmName = '';
    let parmValue = '';
    let dataSetName = '';
    if (recordData[0].ProductCode) {
      for (let i = 0; i < recordData.length; i += 1) {
        parmName = 'productCode';
        dataSetName = 'ProductMatrix';
        parmValue = `${parmValue + recordData[i].ProductCode},`;
      }
    } else {
      for (let i = 0; i < recordData.length; i += 1) {
        parmName = 'productLineCode';
        parmValue = `${parmValue + recordData[i].ProductLineCode},`;
        dataSetName = 'Matrix';
      }
    }

    const params = `&dataSetName=${dataSetName}&criteriaName=CodeVersion&parmValue=${parmValue}&parmName=${parmName}`;
    const downloadUrl = `${Url.downLoadConfigData}?${localStorage.getItem("Authorization")}${params}`;
    window.location.href = downloadUrl;
  },
  downLoadForCommon() {
    const params = `&dataSetName=Reserve,Settlement&criteriaName=CodeVersion&parmValue=1&parmName=condition`;
    const downloadUrl = `${Url.downLoadConfigData}?${localStorage.getItem("Authorization")}${params}`;
    window.location.href = downloadUrl;

  },
};
