import { UIDialog, UIButton, UICell, UIBox } from 'RainbowUI';
import { ApprovalHistory } from 'ClaimCommon';

const CalculationReadOnly = require('./CalculationReadOnly');
const CalculationHistoryStore = require('../store/CalculationHistoryStore');

const CalculationReadOnlyDialog = React.createClass({
  getInitialState() {
    return {};
  },

  componentWillMount() {
    CalculationHistoryStore.listen(this.onStoreStateChange);
  },
  componentWillUnmount() {
    CalculationHistoryStore.unlisten(this.onStoreStateChange);
  },

  onStoreStateChange(storeState) {
    this.setState({ CalculateId: storeState.CalculateId });
  },

  render() {
    const { CalculateId } = this.state;
    const calculateId = CalculateId;
    const approveHistory =
      calculateId === null ||
      calculateId === undefined ||
      calculateId === '' ? (
        <UICell />
      ) : (
        <ApprovalHistory processObjectId={calculateId} history />
      );
    return (
      <UIDialog
        id="CalculateReadOnlyDialog"
        title={i18n.ClaimCalculation.CalculationHistoryDetail}
        width="90%"
        height="1000px"
        modal="false"
      >
        <CalculationReadOnly
          calculateId={calculateId}
          history
          isApproveTask={false}
        />
        {approveHistory}
        <UIBox direction="center">
          <UIButton
            value={i18n.ClaimCommon.Back}
            position="right"
            onClick={this.onClose}
          />
        </UIBox>
      </UIDialog>
    );
  },

  onClose() {
    UIDialog.hide('CalculateReadOnlyDialog');
  },
});
export default CalculationReadOnlyDialog;
