import { AjaxUtil, MessageHelper } from 'RainbowUI';

import { PartyAction } from 'EU00PartyCommonUI';
import Url from '../url/Url';

export default {
  loadClaimHandlingInfo(data) {
    const deferred = $.Deferred();
    AjaxUtil.doGet(
      `${Url.ClaimHandlingInfoLoad + data.taskInstranceId}/${
      data.caseId
      }`,
      {},
      {
        async: false,
        done(data) {
          deferred.resolve(data);
        },
        fail(err) {
          deferred.reject(err);
        },
      }
    );
    return deferred.promise();
  },

  cancelNewSubclaim(data, caseId) {
    const deferred = $.Deferred();
    AjaxUtil.doGet(
      `${Url.CancelNewSubclaim + data.taskInstranceId}/${caseId}`,
      {},
      {
        async: false,
        done(data) {
          deferred.resolve(data);
        },
        fail(err) {
          deferred.reject(err);
        },
      }
    );
    return deferred.promise();
  },

  isMotor(productCode) {
    const deferred = $.Deferred();
    let result = false;
    const url = `${Url.IsMotorByProductCode}?productCode=${productCode}`;
    AjaxUtil.doGet(
      url,
      {},
      {
        async: false,
        done(data) {
          try {
            if (data.Status === 'OK') {
              result = data.Model;
            }
          } catch (err) {
            // continue regardless of error
          }
        },
        fail(err) {
          deferred.reject(err);
          return result;
        },
      }
    );

    return result;
  },

  retrievePolicy(caseForm) {
    const deferred = $.Deferred();
    AjaxUtil.doPost(Url.ClaimHandlingRetrievePolicy, caseForm, {
      async: false,
      done(data) {
        deferred.resolve(data);
      },
      fail(err) {
        deferred.reject(err);
      },
    });
    return deferred.promise();
  },

  savePolicy(policy) {
    const deferred = $.Deferred();
    AjaxUtil.doPost(Url.PolicySave, policy, {
      async: false,
      done(data) {
        deferred.resolve(data);
      },
      fail(err) {
        deferred.reject(err);
      },
    });
    return deferred.promise();
  },

  getCoverageList(subclaimType, insuredId, productCode) {
    const deferred = $.Deferred();
    AjaxUtil.doGet(
      `${Url.ClaimHandlingCogetageList +
      subclaimType}/${productCode}/${insuredId}`,
      {},
      {
        async: false,
        done(data) {
          deferred.resolve(data);
        },
        fail(err) {
          deferred.reject(err);
        },
      }
    );
    return deferred.promise();
  },

  getClaimInfo(caseId) {
    const deferred = $.Deferred();
    AjaxUtil.doGet(
      Url.ClaimHandlingClaimInfo + caseId,
      {},
      {
        async: false,
        done(data) {
          deferred.resolve(data);
        },
        fail(err) {
          deferred.reject(err);
        },
      }
    );
    return deferred.promise();
  },

  getExchangeRate2LocalCurrency(currencyCode) {
    const deferred = $.Deferred();
    AjaxUtil.doGet(
      Url.ClaimExRate2LocalCurrency + currencyCode,
      {},
      {
        async: false,
        done(data) {
          deferred.resolve(data.Model);
        },
        fail(err) {
          deferred.reject(err);
        },
      }
    );
    return deferred.promise();
  },

  checkNewSubClaimType(caseId, ObjectEntity) {
    const deferred = $.Deferred();
    let status = true;
    AjaxUtil.doPost(Url.CheckNewSubClaimType + caseId, ObjectEntity, {
      async: false,
      done(data) {
        deferred.resolve(data);
        if (data.Status === 'BLOCK') {
          MessageHelper.error(data.Messages[0].Message);
          status = false;
        }
      },
      fail(err) {
        deferred.reject(err);
      },
    });
    if (!status) {
      return status;
    }
    return deferred.promise();
  },

  checkChangeSubClaimType(caseForm) {
    const deferred = $.Deferred();
    AjaxUtil.doPost(Url.checkChangeSubClaimType, caseForm, {
      async: false,
      done(data) {
        deferred.resolve(data);
      },
      fail(err) {
        deferred.reject(err);
      },
    });
    return deferred.promise();
  },

  putClaimInfo(claim) {
    const deferred = $.Deferred();
    const claimBak = jQuery.extend(true, {}, claim);

    delete claimBak.CodeTableInsured;
    delete claimBak.CodeTableCauseOfLoss;
    delete claimBak.CodeTableSubclaimType;
    delete claimBak.CodeTableDamageType;
    delete claimBak.CodeTableClaimant;
    delete claimBak.CodeTableClaimOwner;
    if (claimBak.ObjectList) {
      for (let i = 0; i < claimBak.ObjectList.length; i += 1) {
        if (claimBak.ObjectList[i].RentalCarCompanyId) {
          claimBak.ObjectList[i].RentalCarCompany =
            claimBak.ObjectList[i].RentalCarCompanyId;
          delete claimBak.ObjectList[i].RentalCarCompanyId; //
        }
      }
      claimBak.ObjectList.map((datas, index) => {
        const data = datas;
        if (data.dataIndex) {
          delete data.dataIndex;
        }
        return data;
      });
    }
    AjaxUtil.doPost(
      Url.ClaimHandlingClaimInfo,
      { ClaimEntity: claimBak },
      {
        async: false,
        done(data) {
          deferred.resolve(data);
        },
        fail(err) {
          deferred.reject(err);
        },
      }
    );
    return deferred.promise();
  },

  checkSubclaimDidSettlement(objectIdList) {
    const deferred = $.Deferred();
    AjaxUtil.doPost(
      Url.CheckSubclaimDidSettlement,
      { Parm: objectIdList },
      {
        async: false,
        done(data) {
          deferred.resolve(data);
        },
        fail(err) {
          deferred.reject(err);
        },
      }
    );
    return deferred.promise();
  },

  postNewSubclaim(caseId, objectEntity) {
    const deferred = $.Deferred();
    AjaxUtil.doPost(
      Url.ClaimHandlingSubclaim + caseId,
      objectEntity,
      {
        async: false,
        done(data) {
          if (data.Status === 'OK') {
            deferred.resolve(data);
          } else {
            MessageHelper.error(data.Messages[0].Message);
          }
        },
        fail(err) {
          if (err.responseJSON.Messages.length > 2) {
            MessageHelper.error(err.responseJSON.Messages[2].Message);
          } else {
            MessageHelper.error(err.responseJSON.Messages[0].Message);
          }
        },
      }
    );

    return deferred.promise();
  },

  putSubclaim(objectEntity) {
    const deferred = $.Deferred();
    AjaxUtil.doPost(Url.ClaimHandlingSubclaim, objectEntity, {
      async: false,
      done(data) {
        deferred.resolve(data);
      },
      fail(err) {
        if (err.responseJSON.Messages.length > 2) {
          MessageHelper.error(err.responseJSON.Messages[2].Message);
        } else {
          MessageHelper.error(err.responseJSON.Messages[0].Message);
        }
      },
    });
    return deferred.promise();
  },

  loadStatus(caseId) {
    const deferred = $.Deferred();
    AjaxUtil.doGet(
      Url.ClaimHandlingLoadStatus + caseId,
      {},
      {
        async: false,
        done(data) {
          deferred.resolve(data);
        },
        fail(err) {
          deferred.reject(err);
        },
      }
    );
    return deferred.promise();
  },
  loadParty(partyId) {
    const partyAction = new PartyAction();
    return partyAction.loadPartyByPartyId(partyId);
  },

  changeNotification(caseId) {
    const url = Url.ChangeNotificationToClaim + caseId;
    const deferred = $.Deferred();
    AjaxUtil.doGet(
      url,
      {},
      {
        async: false,
        done(data) {
          if (data) {
            deferred.resolve(data);
          }
        },
        fail(err) {
          // jqXHR.status, jqXHR.responseText
          deferred.reject(err);
        },
      }
    );
    return deferred.promise();
  },

  submitWithOutPolicy(retrievePolicy, caseId) {
    const deferred = $.Deferred();
    AjaxUtil.doPost(
      Url.ClaimHandlingWithOutPolicy + caseId,
      retrievePolicy,
      {
        async: false,
        done(data) {
          if (data.Status === 'OK') {
            deferred.resolve(data.Model);
          } else {
            deferred.reject(data.Messages[0].Message);
          }
        },
        fail(err) {
          // jqXHR.status, jqXHR.responseText
          deferred.reject(err);
        },
      }
    );
    return deferred.promise();
  },

  checkPolicyFeeStatus(policyNo) {
    const deferred = $.Deferred();
    AjaxUtil.doGet(
      Url.CheckPolicyFeeStatus,
      { policyNo },
      {
        async: false,
        done(data) {
          deferred.resolve(data);
        },
        fail(jqXHR) {
          deferred.reject({
            status: jqXHR.status,
            responseText: jqXHR.responseText,
          });
        },
      }
    );
    return deferred.promise();
  },

  getCurrentUserInfo() {
    const deferred = $.Deferred();
    AjaxUtil.doGet(
      Url.CurrentUserInformation,
      {},
      {
        async: false,
        done(data) {
          deferred.resolve(data);
        },
        fail() {
          // TODO: console.log('No current user.');
        },
      }
    );
    return deferred.promise();
  },
};
