import {
  UIDialog,
  UIPanel,
  UIDataTable,
  UIButton,
  UIBox,
} from 'RainbowUI';
import {
  FraudIndicatorInfo,
  FraudIndicatorAction,
} from 'ClaimCommon';

const ClaimFraudIndicatorDialog = React.createClass({
  cancel() {
    UIDialog.hide('claimfraudIndicatorDialog');
  },
  save() {
    const objectId = sessionStorage.getItem('fraudObjectId');
    const selRecord = UIDataTable.getSelectedRecord(
      'authorityTreeTable'
    );
    if (selRecord) {
      for (let i = 0; i < selRecord.length; i += 1) {
        delete selRecord[i].dataIndex;
      }
    }
    const selMap = { ObjectId: objectId, VoList: selRecord };
    FraudIndicatorAction.save(selMap);
    UIDialog.hide('claimfraudIndicatorDialog');
  },

  render() {
    return (
      <UIDialog
        id="claimfraudIndicatorDialog"
        title={i18n.ClaimCommon.FraudIndicatorTitle}
        width="95%"
      >
        <UIPanel
          panelTitle={i18n.ClaimCommon.FraudIndicatorTitle}
          styleClass="default"
        >
          <FraudIndicatorInfo />
        </UIPanel>
        <UIBox direction="center">
          <UIButton
            value={i18n.ClaimCommon.Submit}
            onClick={this.save}
          />
          <UIButton
            value={i18n.ClaimCommon.Cancel}
            onClick={this.cancel}
          />
        </UIBox>
      </UIDialog>
    );
  },
});
export default ClaimFraudIndicatorDialog;
