import {
  UIText,
  UISelect,
  UIDialog,
  UISmartPanelGrid,
  UIBox,
  UIButton,
} from 'RainbowUI';
import CommonCodeTable from '../../codetable/CodeTable';
import PartyConst from '../../const/PartyConst';

const _ = require('underscore');

const ForgottenRole = React.createClass({
  getInitialState() {
    const partyRoleList = this.props.partyRoleSelected;
    let model = {
      '@type':
        'PartyIndividualForgottenRole-PartyIndividualForgottenRole',
      BusinessObjectId: 75198515,
      RoleType: PartyConst.PARTY_ROLE_CODE_IND_FORGOTTEN,
      ForgottenType: '',
      Status: '',
      Remark: '',
    };
    _.each(partyRoleList, partyRole => {
      if (
        partyRole.RoleType == PartyConst.PARTY_ROLE_CODE_IND_FORGOTTEN
      ) {
        model = partyRole;

        return model;
      }
    });

    if (this.props.isEdit === 'Y') {
      const selectedData = partyRoleList[0];
      model.ForgottenType = selectedData.ForgottenType;
      model.Remark = selectedData.Remark;
      model.Status = selectedData.Status;
    }
    return {
      PartyId: this.props.partyId,
      PartyRoleModel: model,
    };
  },

  render() {
    const _self = this;
    const { PartyRoleModel } = this.state;
    return (
      <div>
        <UISmartPanelGrid column="3">
          <UISelect
            label={i18n.PartyRole}
            value={PartyConst.PARTY_ROLE_CODE_IND_FORGOTTEN}
            enabled="false"
            codeTable={CommonCodeTable.getIndPartyRole}
            required="true"
          />

          <UIText
            label={i18n.Remarks}
            colspan="3"
            model={PartyRoleModel}
            property="Remark"
          />
          <UISelect
            label={i18n.Status}
            model={PartyRoleModel}
            property="Status"
            defaultValue="1"
            showBlankOption="false"
            codeTable={CommonCodeTable.getPartyStatus}
          />
        </UISmartPanelGrid>
        <UIBox direction="center">
          <UIButton
            value={i18n.Save}
            causeValidation="true"
            validationGroup="partyForgottenRoleDialog"
            onClick={_self.onClickSubmit}
          />
          <UIButton
            value={i18n.Cancel}
            onClick={_self.onClickClose}
          />
        </UIBox>
      </div>
    );
  },

  onClickSubmit() {
    const _self = this;
    _.each(_self.props.RoleList, temppartyRole => {
      const partyRole = temppartyRole;
      if (
        partyRole.RoleType == PartyConst.PARTY_ROLE_CODE_IND_FORGOTTEN
      ) {
        partyRole.Status = _self.state.PartyRoleModel.Status;
      }
    });
    const superParty = _self.props.parentComponent.state.Party;
    const { PartyHistoryList } = superParty;
    if (!_self.state.PartyRoleModel['@pk']) {
      const newPartyHistory = PartyConst.PARTY_HISTORY_CREATE_ROLE;
      PartyHistoryList.push(newPartyHistory);
    } else {
      const newPartyHistory = PartyConst.PARTY_HISTORY_UPDATE_ROLE;
      PartyHistoryList.push(newPartyHistory);
    }
    if (!superParty.PartyRoleList) {
      superParty.PartyRoleList = [];
    }
    superParty.PartyRoleList.push(_self.state.PartyRoleModel);
    _self.props.submitInfoHandler(superParty);
    UIDialog.hide('createForgottenParty');
  },
  onClickClose() {
    UIDialog.hide('createForgottenParty');
  },
});
export default ForgottenRole;
