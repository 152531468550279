import { UIPanel } from 'RainbowUI';
import PartyMaintenanceStore from '../store/PartyMaintenanceStore';
import PartyMaintenanceAction from '../action/PartyMaintenanceAction';
import CodeTableAction from '../../common/action/CodeTableAction';
import CodeTableCstUI from '../../common/constant/CodeTableCstUI';
import PartyMaintanceOption from './PartyMaintenanceOption';
import PartyMaintanceResult from './PartyMaintenanceResult';

const PartyMaintenance = React.createClass({
  getDefaultProps() {
    return { index: '_000' };
  },

  getInitialState() {
    return {
      clickTabTimes: 0,
      codeTablePartyRole: CodeTableAction.loadDataList(
        CodeTableCstUI.ClaimPartyRole,
        null
      ),
      codeTableIndIDType: CodeTableAction.loadDataList(
        CodeTableCstUI.ClaimPartyCertiType,
        null
      ),
      codeTableOrgIDType: CodeTableAction.loadDataList(
        CodeTableCstUI.ClaimPartyOrgType,
        null
      ),
    };
  },

  componentWillMount() {
    PartyMaintenanceStore.listen(this.onStoreStateChange);
  },
  componentWillUnmount() {
    PartyMaintenanceStore.unlisten(this.onStoreStateChange);
  },

  onStoreStateChange(storeState) {
    this.setState({ clickTabTimes: storeState.clickTabTimes });
  },

  getPartyList(pageIndex, pageSize) {
    return PartyMaintenanceAction.getPartyList(
      this.props.caseId,
      pageIndex,
      pageSize
    );
  },

  editParty(event) {
    this.refs.AddOrEditPartyDialog_ref.showDialog(
      event.getParameter('data'),
      null
    );
    this.setState({ title: i18n.ClaimParty.EditClaimParty });
  },

  searchParty() {
    this.refs.AddOrEditPartyDialog_ref.showDialog(
      null,
      'QueryCommonParty'
    );
    this.setState({ title: i18n.ClaimParty.SelectfromCommonParty });
  },
  searchFromParty(partyName) {
    this.refs.partyMaintainceResult_ref.changeState(partyName);
  },
  render() {
    const { clickTabTimes } = this.state;
    const { caseId, partyAuth, claimEntity } = this.props;
    if (clickTabTimes < 1) {
      return <div />;
    }

    return (
      <div>
        <UIPanel
          id="damageItem_UIPanel"
          panelTitle={i18n.ClaimParty.ClaimPartyMaintenance}
        >
          <PartyMaintanceOption
            caseId={caseId}
            parentComponment={this}
          />
          <PartyMaintanceResult
            caseId={caseId}
            ref="partyMaintainceResult_ref"
            partyAuth={partyAuth}
            claimEntity={claimEntity}
          />
        </UIPanel>
      </div>
    );
  },
});
export default PartyMaintenance;
