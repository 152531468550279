import {
  UIPanel,
  UIText,
  UIDataTable,
  UIColumn,
  UICurrency,
} from 'RainbowUI';
import ClaimCommonExt from 'ClaimCommonExt';
import ClaimHandlingStore from '../../store/ClaimHandlingStore';

const ClaimBaseInfo = ClaimCommonExt.getClaimBaseInfo();

export default class ClaimSummaryTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = { claimEntity: props.claimEntity };
  }

  componentWillMount() {
    ClaimHandlingStore.listen(this.onStoreStateChange.bind(this));
  }

  componentWillUnmount() {
    ClaimHandlingStore.unlisten(this.onStoreStateChange.bind(this));
  }

  onStoreStateChange(storeState) {
    if (!_.isEmpty(storeState.caseForm)) {
      this.setState({
        claimEntity: storeState.caseForm.ClaimEntity,
      });
    }
  }

  render() {
    const list = this.generateClaimSummary();
    const { claimEntity } = this.props;
    return (
      <div>
        <ClaimBaseInfo claimEntity={claimEntity} />
        <UIPanel
          id="ClaimSummaryTab_ClaimSummaryPanel"
          panelTitle={i18n.ClaimCommon.ClaimSummary}
        >
          <UIDataTable value={list} indexable="false">
            <UIColumn
              headerTitle={i18n.ClaimCommon.Level}
              width="30%"
              render={data => {
                if (data.css) {
                  if (data.css.marginLeft) {
                    return (
                      <div style={data.css}>
                        <UIText
                          model={data}
                          property="Level"
                          io="out"
                        />
                      </div>
                    );
                  }
                  return (
                    <UIText
                      model={data}
                      property="Level"
                      io="out"
                      style={data.css}
                    />
                  );
                }
                return (
                  <UIText model={data} property="Level" io="out" />
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.ClaimCommon.OSReserve}
              textAlign="right"
              render={data => {
                let className = '';
                if (data.css) {
                  if (data.css.fontWeight) {
                    className = 'ui-span';
                  }
                }
                return (
                  <div className={className}>
                    <UICurrency
                      model={data}
                      property="ReserveAmount"
                      io="out"
                      unit={window.EU00.getCurrencySignByCurrencyCode(
                        data.CurrencyCode
                      )}
                    />
                  </div>
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.ClaimCommon.SettledAmount}
              textAlign="right"
              render={data => {
                let className = '';
                if (data.css) {
                  if (data.css.fontWeight) {
                    className = 'ui-span';
                  }
                }
                return (
                  <div className={className}>
                    <UICurrency
                      model={data}
                      property="SettlementAmount"
                      io="out"
                      unit={window.EU00.getCurrencySignByCurrencyCode(
                        data.CurrencyCode
                      )}
                    />
                  </div>
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.ClaimCommon.IncurredAmount}
              textAlign="right"
              render={data => {
                let className = '';
                if (data.css) {
                  if (data.css.fontWeight) {
                    className = 'ui-span';
                  }
                }
                return (
                  <div className={className}>
                    <UICurrency
                      model={data}
                      property="IncurredAmount"
                      io="out"
                      unit={window.EU00.getCurrencySignByCurrencyCode(
                        data.CurrencyCode
                      )}
                    />
                  </div>
                );
              }}
            />
          </UIDataTable>
        </UIPanel>
      </div>
    );
  }

  generateClaimSummary() {
    const { claimEntity } = this.state;
    let tableData = [];
    let totalOSReserveAmount = 0;
    let totalSettlementAmount = 0;
    if (claimEntity.ObjectList) {
      _.each(claimEntity.ObjectList, object => {
        if (object.ItemList) {
          const objectData = {};
          const objectLevel = `Sub Claim ${object.SeqNo} `;
          objectData.Level = `${objectLevel}Total`;
          let objectReserveAmount = 0;
          let objectSettlementAmount = 0;
          const itemReserveList = [];
          _.each(object.ItemList, item => {
            if (item.ReserveList) {
              let itemReserveAmount = 0;
              let itemSettlementAmount = 0;
              _.each(item.ReserveList, reserve => {
                if (reserve.ReserveType === 'RC_01' || reserve.ReserveType === 'RC_02') {
                  itemReserveAmount += reserve.OutstandingAmount;
                  itemSettlementAmount += reserve.SettleAmount;
                }
              });
              objectReserveAmount += itemReserveAmount;
              objectSettlementAmount += itemSettlementAmount;
              const itemReserve = {};
              itemReserve.Level = `${objectLevel} - ${item.CoverageName}`;
              itemReserve.ReserveAmount = itemReserveAmount;
              itemReserve.SettlementAmount = itemSettlementAmount;
              itemReserve.IncurredAmount = Number(
                Number(
                  itemReserveAmount + itemSettlementAmount
                ).toFixed(2)
              );
              itemReserve.CurrencyCode = claimEntity.CurrencyCode;
              itemReserve.css = {
                marginLeft: '10%',
              };
              itemReserveList.push(itemReserve);
            }
          });
          objectData.ReserveAmount = objectReserveAmount;
          objectData.SettlementAmount = objectSettlementAmount;
          objectData.IncurredAmount = Number(
            Number(
              objectReserveAmount + objectSettlementAmount
            ).toFixed(2)
          );
          objectData.CurrencyCode = claimEntity.CurrencyCode;
          objectData.css = {
            fontWeight: 'bolder',
            marginLeft: '5%',
          };
          tableData.push(objectData);
          tableData = tableData.concat(itemReserveList);
          totalOSReserveAmount += objectReserveAmount;
          totalSettlementAmount += objectSettlementAmount;
        }
      });
    }

    const totalData = {};
    totalData.Level = 'Claim Total';
    totalData.ReserveAmount = totalOSReserveAmount;
    totalData.SettlementAmount = totalSettlementAmount;
    totalData.IncurredAmount = Number(
      Number(
        totalData.ReserveAmount + totalData.SettlementAmount
      ).toFixed(2)
    );
    totalData.CurrencyCode = claimEntity.CurrencyCode;
    totalData.css = {
      fontWeight: 'bolder',
    };
    tableData.unshift(totalData);

    return tableData;
  }
}
