import {
  UIPanel,
  UIDataTable,
  UISmartPanelGrid,
  UIColumn,
  UIButton,
  UIText,
  UICell,
  UIDropZone,
} from 'RainbowUI';

import AttachmentStore from '../../store/AttachmentStore';

const Attachment = React.createClass({
  getInitialState() {
    return { attachments: [] };
  },

  componentWillMount() {
    AttachmentStore.listen(this.onStoreStateChange);
  },

  componentWillUnmount() {
    AttachmentStore.unlisten(this.onStoreStateChange);
  },

  onStoreStateChange(storeState) {
    if (!_.isEmpty(storeState.attachments)) {
      this.setState({ attachments: storeState.attachments });
    }
  },

  render() {
    return (
      <div>
        <UIDataTable
          id="attachmentTableList"
          indexable="false"
          pageable="false"
          headerTitle={i18n.ClaimCommon.AttachmentList}
          value={this.attachments}
        >
          <UIColumn
            headerTitle={i18n.ClaimCommon.DocumentName}
            value="DocumentName"
          />
          <UIColumn
            headerTitle={i18n.ClaimCommon.DocumentType}
            value="DocumentType"
          />
          <UIColumn
            headerTitle={i18n.ClaimCommon.UploadDate}
            value="UploadDate"
          />
          <UIColumn
            headerTitle={i18n.ClaimCommon.CreatorName}
            value="CreatorName"
          />
          <UIColumn
            headerTitle={i18n.ClaimCommon.ReMark}
            value="ReMark"
          />
        </UIDataTable>
        <UIPanel
          id="attachmentPanel"
          panelTitle={i18n.ClaimCommon.AttachmentPanel}
        >
          <UICell width="5">
            <UIDropZone
              id="dropzone02"
              clickable="false"
              url="http://localhost:8080"
              width="300px"
              height="300px"
            />
          </UICell>
          <UICell>
            <UISmartPanelGrid id="attachment.UISmartPanelGrid">
              <UIText label={i18n.ClaimCommon.DocumentName} />
              <UIText label={i18n.ClaimCommon.DocumentType} />
              <UIText label={i18n.ClaimCommon.ReMark} />
            </UISmartPanelGrid>
          </UICell>
          <UIButton vlue={i18n.ClaimCommon.Upload} />
        </UIPanel>
      </div>
    );
  },
});
export default Attachment;
