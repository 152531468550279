const home = env.serverUrl;
const urlDefine = {
  PropertySubmit: `${home}investigation/property/submit`,
  PropertySave: `${home}investigation/property/save`,

  BodilyInjurySubmit: `${home}investigation/bodyInjury/submit`,
  BodilyInjurySave: `${home}investigation/bodyInjury/save`,

  VehicleSubmit: `${home}investigation/vehicle/submit`,
  VehicleSave: `${home}investigation/vehicle/save`,

  InvestigationInitData: `${home}investigation/load`,
  SynButtonIsEnable: `${home}workflow/couldCloneData/0/`,

  SynData: `${home}appraisalProperty/synModel`,

  InvestigationClosedTasksLoad: `${home}investigation/closedTasks/`,
};
export default urlDefine;
