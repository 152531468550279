import UrlConfig from '../../../UrlConfig';
import CommonService from './CommonService';

const MaskService = {
  loadMaskDefinitionInfo() {
    if (!window.EU00) {
      window.EU00 = {};
    }

    let defInfo = [];
    const storage = window.localStorage;

    if (
      window.EU00.EMEA_MASK_INFOR_DEFINITION &&
      window.EU00.EMEA_MASK_INFOR_DEFINITION !== 'undefined'
    ) {
      defInfo = window.EU00.EMEA_MASK_INFOR_DEFINITION;
    } else if (
      storage.getItem('EMEA_MASK_INFOR_DEFINITION') &&
      storage.getItem('EMEA_MASK_INFOR_DEFINITION') !== 'undefined'
    ) {
      defInfo = JSON.parse(
        storage.getItem('EMEA_MASK_INFOR_DEFINITION')
      );
      window.EU00.EMEA_MASK_INFOR_DEFINITION = defInfo;
    } else {
      const url = UrlConfig.loadMaskDefinitionInfo;
      defInfo = CommonService.ajaxGetNoDeferred(null, url);

      storage.setItem(
        'EMEA_MASK_INFOR_DEFINITION',
        JSON.stringify(defInfo)
      );
      window.EU00.EMEA_MASK_INFOR_DEFINITION = defInfo;
    }

    return defInfo;
  },

  loadMaskRule(modelName, fieldName) {
    let maskRule;

    const defInfo = MaskService.loadMaskDefinitionInfo();
    if (defInfo && defInfo.length > 0) {
      for (let i = 0; i < defInfo.length; i += 1) {
        const info = defInfo[i];
        if (
          info.ModelName == modelName &&
          info.FieldName == fieldName
        ) {
          maskRule = info.MaskRule;
          break;
        }
      }
    }

    return maskRule;
  },

  getMaskValue(maskRule, value) {
    let maskValue = value;

    if (maskRule && value) {
      const rule = JSON.parse(maskRule);
      if (value.length > rule.Left && value.length > rule.Right) {
        const values = value.split('');
        for (let i = rule.Left; i < rule.Right; i += 1) {
          values.splice(i, 1, rule.MaskChar);
        }
        maskValue = values.join('');
      }
    }

    return maskValue;
  },
};
export default MaskService;
