import { UIDataTable, UIColumn, UINumber } from 'RainbowUI';

const PolicyDetailInsured = React.createClass({
  getInitialState() {
    return { PolicyEntity: this.props.policyInfo };
  },

  componentWillReceiveProps(nextProps) {
    this.setState({ PolicyEntity: nextProps.policyInfo });
  },
  getParentComponent() {
    return this;
  },
  render() {
    const { PolicyEntity } = this.state;
    return (
      <div>
        <UIDataTable
          id="insuredList"
          value={PolicyEntity.InsuredList}
          indexable="false"
          infoable="true"
          isHeading="true"
          pageable="false"
          headerTitle={i18n.ClaimCommon.InsuredList}
        >
          <UIColumn
            headerTitle={i18n.ClaimCommon.InsuredName}
            value="InsuredName"
          />
          <UIColumn
            headerTitle={i18n.ClaimCommon.SumInsured}
            value="SumInsured"
          >
            <UINumber
              id="NUMBER_CODE_SumInsured"
              io="out"
              value="SumInsured"
            />
          </UIColumn>
          <UIColumn
            headerTitle={i18n.ClaimCommon.InsuredCategory}
            value="InsuredCategory"
          />
          <UIColumn
            headerTitle={i18n.ClaimCommon.InsuredId}
            value="InsuredUid"
          />
        </UIDataTable>
      </div>
    );
  },
});
export default PolicyDetailInsured;
