import { UISmartPanelGrid, UIText, UICurrency } from 'RainbowUI';
import BindToMixin from 'react-binding';
import PropTypes from 'prop-types';

const LimitAndDeductionInfoDialog = require('./LimitAndDeductionInfoDialog');
const CalculationInsuredInfo = require('./CalculationInsuredInfo');

const CalculationInfo = React.createClass({
  mixins: [BindToMixin],

  propTypes: {
    calculationInfo: PropTypes.object.isRequired,
    otherInfo: PropTypes.object.isRequired,
    readOnly: PropTypes.bool,
    isApproveTask: PropTypes.bool,
  },
  getIOStyle() {
    return this.props.readOnly == true ? 'out' : null;
  },
  render() {
    const { calculationInfo, readOnly, isApproveTask } = this.props;
    const calculationItem = this.bindTo(
      calculationInfo,
      'CalculationItem'
    );
    if (!calculationItem || calculationItem.length < 1) {
      return <div />;
    }
    const calculateId = this.bindTo(calculationInfo, 'CalculateId')
      .value;
    const suffixId = `calculationInfo_${readOnly}_${isApproveTask}_${calculateId}_`;
    const insuredInfo = this.getInsuredInfo();
    const io = this.getIOStyle();
    return (
      <div>
        {insuredInfo}

        <UISmartPanelGrid id={`${suffixId}panel`} column="2">
          <div style={{ float: 'left', whiteSpace: 'nowrap' }}>
            <UICurrency
              id={`CURRENCY_CODE_${suffixId}totalIndemnityAmount`}
              label={i18n.ClaimCalculation.TotalIndemnityAmount}
              valueLink={this.bindTo(
                calculationInfo,
                'TotalIndemnityAmount'
              )}
              unit={window.EU00.getCurrencySignByCurrencyCode(
                this.bindTo(calculationInfo, 'CaseCurrency').value
              )}
              io="out"
            />
          </div>
          <UIText
            id={`${suffixId}remark`}
            label={i18n.ClaimCalculation.Remark}
            valueLink={this.bindTo(calculationInfo, 'Remark')}
            colspan="2"
            io={io}
          />
        </UISmartPanelGrid>
        <LimitAndDeductionInfoDialog id="cacl" />
      </div>
    );
  },

  getInsuredInfo() {
    const { calculationInfo } = this.props;
    const { otherInfo } = this.props;
    const coverageStructure = this.bindArrayTo(
      otherInfo,
      'CoverageStructure'
    );
    if (_.isEmpty(coverageStructure.items)) {
      return <div />;
    }
    const { readOnly } = this.props;
    const { isApproveTask } = this.props;
    const his = this.props.history;
    const insuredInfo = coverageStructure.items.map(
      (insured, index) => {
        return (
          <CalculationInsuredInfo
            calculationInfo={calculationInfo}
            history={his}
            insured={insured}
            otherInfo={otherInfo}
            readOnly={readOnly}
            isApproveTask={isApproveTask}
            componentId={index}
          />
        );
      }
    );

    return insuredInfo;
  },
});
export default CalculationInfo;
