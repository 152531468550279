import { AjaxUtil, MessageHelper } from 'RainbowUI';
import Url from '../../common/url/Url';

export default {
  initTreeData(caseId) {
    const defer = $.Deferred();
    AjaxUtil.doGet(
      Url.ClaimPartyInit + caseId,
      {},
      {
        async: false,
        done(data, textStatus, jqXHR) {
          if (data) {
            defer.resolve(data);
          } else {
            defer.reject({
              status: jqXHR.status,
              responseText: jqXHR.responseText,
            });
          }
        },
        fail(jqXHR) {
          defer.reject({
            status: jqXHR.status,
            responseText: jqXHR.responseText,
          });
        },
      }
    );
    return defer.promise();
  },

  createClmParty(clmPtyVo, subclaimIndex) {
    const deferred = $.Deferred();
    AjaxUtil.doPost(
      `${Url.ClaimPartyCreate + clmPtyVo.PartyId}/${subclaimIndex}`,
      clmPtyVo,
      {
        async: false,
        done(data) {
          deferred.resolve(data);
        },
        fail(err) {
          deferred.reject(err);
        },
      }
    );
    return deferred.promise();
  },

  updateClaimParty(clmPtyVo) {
    const deferred = $.Deferred();
    AjaxUtil.doPost(
      Url.ClaimPartyUpdate + clmPtyVo.PartyId,
      clmPtyVo,
      {
        async: false,
        done(data) {
          deferred.resolve(data);
        },
        fail(err) {
          deferred.reject(err);
        },
      }
    );
    return deferred.promise();
  },

  getPartyListByPartyName(caseId, partyName, pageIndex, pageSize) {
    const deferred = $.Deferred();
    AjaxUtil.doGet(
      `${Url.ClaimPartyListByPartyName +
        caseId}/${partyName}/${pageIndex}/${pageSize}`,
      {},
      {
        async: false,
        done(data) {
          deferred.resolve(data);
        },
        fail(err) {
          deferred.reject(err);
        },
      }
    );
    return deferred.promise();
  },

  getPartyListByCaseId(caseId, pageIndex, pageSize) {
    const deferred = $.Deferred();
    AjaxUtil.doGet(
      `${Url.ClaimPartyList + caseId}/${pageIndex}/${pageSize}`,
      {},
      {
        async: false,
        done(data) {
          deferred.resolve(data);
        },
        fail(err) {
          deferred.reject(err);
        },
      }
    );
    return deferred.promise();
  },
  getPartyIdByCaseId(caseId) {
    const preurl = Url.QueryCaseClaimantPartyData + caseId;
    const result = [];
    let partyList = [];
    AjaxUtil.doGet(
      preurl,
      {},
      {
        async: false,
        done(data) {
          partyList = data.Model;
        },
        fail() {
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMsg.QueryTransactionTypeError,
            i18n.ClaimCommon.ErrorMsg.SystemError,
            MessageHelper.POSITION_TOP_RIGHT
          );
        },
      }
    );
    if (partyList) {
      $.each(partyList, (i, info) => {
        if (info.PtyPartyId) {
          result.push(info.PtyPartyId);
        }
      });
    }
    return result;
  },

  getPartyAuth(caseId, code) {
    const deferred = $.Deferred();
    AjaxUtil.doGet(
      `${Url.ClaimAuthorityCheck}?caseId=${caseId}&code=${code}`,
      {},
      {
        async: false,
        done(data) {
          deferred.resolve(data);
        },
        fail(err) {
          deferred.reject(err);
        },
      }
    );
    return deferred.promise();
  },
};
