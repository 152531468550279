/**
 * Created by jie.huang on 2019/4/23.
 */
import {
  MessageHelper,
  UIBox,
  UIButton,
  UIDialog,
  UISmartPanelGrid,
  UISelect,
  UIEmail,
  UIText,
  UICell,
  UIRadio,
} from 'RainbowUI';
import DefinedCodeTable from '../../../party/codetable/CodeTable';
import PartyConst from '../../../party/const/PartyConst';
import PartyAction from '../../../party/actions/PartyAction';

const partyAction = new PartyAction();

export default class PtyIntegrationThirdParty extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      party: {
        PartyType: PartyConst.PARTY_TYPE_IND,
        '@type': PartyConst.PARTY_BUSINESS_TYPE_IND,
        BusinessObjectId: PartyConst.PARTY_BUSINESS_OBJECT_ID_IND,
      },
      partyRoleModel: {
        '@type':
          'PartyIndividualDriverRole-PartyIndividualDriverRole',
        BusinessObjectId: 75628452,
        RoleType: PartyConst.PARTY_ROLE_CODE_IND_DRIVER,
      },
      LeftHandExpYrsVisible: false,
    };
  }

  onClickSubmit() {
    let { party } = this.state;
    const { partyRoleModel } = this.state;
    const _self = this;
    party.PartyRoleList = [];
    party.PartyRoleList.push(partyRoleModel);
    let result = partyAction.findPartyIndividualByIdNumber(party);
    if (!result) {
      result = partyAction.createThirdPartyDriver(party);
    } else {
      party = result;
      let existDriver = false;
      if (party.PartyRoleList) {
        _.each(party.PartyRoleList, role => {
          if (
            role.RoleType == PartyConst.PARTY_ROLE_CODE_IND_DRIVER
          ) {
            existDriver = true;
            return role;
          }
        });
      }
      if (existDriver) {
        MessageHelper.error(
          i18n.PartyMsg.Error.DuplicateTPDriver,
          '',
          MessageHelper.POSITION_TOP_RIGHT
        );
        return;
      }
      party.PartyRoleList.push(partyRoleModel);
      party.PartyHistoryList.push(
        PartyConst.PARTY_HISTORY_CREATE_ROLE
      );

      result = partyAction.createThirdPartyDriver(party);
    }
    if (result.Status === 'OK') {
      UIDialog.hide(_self.props.id);
      _self.props.onClickSubmit(result.Model);
      this.setState({
        party: {
          PartyType: PartyConst.PARTY_TYPE_IND,
          '@type': PartyConst.PARTY_BUSINESS_TYPE_IND,
          BusinessObjectId: PartyConst.PARTY_BUSINESS_OBJECT_ID_IND,
        },
        partyRoleModel: {
          '@type':
            'PartyIndividualDriverRole-PartyIndividualDriverRole',
          BusinessObjectId: 75628452,
          RoleType: PartyConst.PARTY_ROLE_CODE_IND_DRIVER,
        },
        LeftHandExpYrsVisible: false,
      });
    }
  }

  onClickBack() {
    const _self = this;
    this.setState({
      party: {
        PartyType: PartyConst.PARTY_TYPE_IND,
        '@type': PartyConst.PARTY_BUSINESS_TYPE_IND,
        BusinessObjectId: PartyConst.PARTY_BUSINESS_OBJECT_ID_IND,
      },
      partyRoleModel: {
        '@type':
          'PartyIndividualDriverRole-PartyIndividualDriverRole',
        BusinessObjectId: 75628452,
        RoleType: PartyConst.PARTY_ROLE_CODE_IND_DRIVER,
      },
      LeftHandExpYrsVisible: false,
    });
    UIDialog.hide(_self.props.id);
    _self.props.onClickBack();
  }

  setLeftHandSideExp(event) {
    if (event.newValue === 'Y') {
      this.setState({
        LeftHandExpYrsVisible: true,
      });
    } else {
      this.setState({
        LeftHandExpYrsVisible: false,
      });
    }
  }

  driverPenaltyPointsOnChange(event) {
    const { partyRoleModel } = this.state;
    if (!event.newValue) {
      MessageHelper.error(
        '',
        i18n.PartyMsg.ErrorMsg.Error_0011,
        MessageHelper.POSITION_TOP_RIGHT
      );
      return;
    }
    const regex = new RegExp('^(([0-9])|(1[0-2]))$', 'gi');
    const myArray = regex.exec(event.newValue);
    if (myArray) {
      [partyRoleModel.DriverPenaltyPoints] = myArray;
    } else {
      MessageHelper.error(
        '',
        i18n.PartyMsg.ErrorMsg.Error_0011,
        MessageHelper.POSITION_TOP_RIGHT
      );
      partyRoleModel.DriverPenaltyPoints = myArray;
    }
  }

  render() {
    const {
      party,
      partyRoleModel,
      LeftHandExpYrsVisible,
    } = this.state;
    const _self = this;
    return (
      <UIDialog
        title={i18n.CreateParty}
        id={_self.props.id}
        width="80%"
        closeable="false"
      >
        <UISmartPanelGrid column="2">
          <UISelect
            label={i18n.PartyCategory}
            disabled="true"
            model={party}
            property="PartyType"
            codeTable={DefinedCodeTable.getCategroyCodeTable}
          />
          <UICell />
          <UIText
            label={i18n.FirstName}
            model={party}
            property="FirstName"
            required="true"
            validationGroup="submitValidation"
          />
          <UIText
            label={i18n.LastName}
            model={party}
            property="LastName"
            required="true"
            validationGroup="submitValidation"
          />
          <UISelect
            label={i18n.IdType}
            model={party}
            property="IdType"
            required="true"
            codeTable={DefinedCodeTable.IdType}
          />
          <UIText
            label={i18n.IdNo}
            model={party}
            property="IdNumber"
            required="true"
            validationGroup="submitValidation"
          />
          <div id="email">
            <UIEmail
              label={i18n.email}
              model={party}
              property="Email"
            />
          </div>
          <UIText
            label={i18n.MobileTel}
            model={party}
            property="MobileTel"
            validationGroup="submitValidation"
          />
          <UIText
            label={i18n.driverPenaltyPoints}
            id="NUMBER_CODE_DriverPenaltyPoints"
            model={partyRoleModel}
            property="DriverPenaltyPoints"
            allowChars="0123456789"
            onChange={this.driverPenaltyPointsOnChange.bind(this)}
          />
          <UISelect
            label={i18n.TypeOfLicense}
            codeTable={DefinedCodeTable.getCodeTable(73982696)}
            model={partyRoleModel}
            property="LicenseTypeCd"
          />
          <UIRadio
            label={i18n.leftExperience}
            codeTable={DefinedCodeTable.getYesOrNo}
            model={partyRoleModel}
            property="LeftHandSideExp"
            onChange={this.setLeftHandSideExp.bind(this)}
          />
          <UISelect
            label={i18n.LeftHandSideoftheRoadExperienceYears}
            codeTable={DefinedCodeTable.getCodeTable(73982694)}
            visibled={LeftHandExpYrsVisible}
            model={partyRoleModel}
            property="LeftHandExpYrs"
          />
        </UISmartPanelGrid>
        <UIBox direction="center">
          <UIButton
            value={i18n.Submit}
            causeValidation="true"
            validationGroup="submitValidation"
            onClick={this.onClickSubmit.bind(this)}
          />
          <UIButton
            value={i18n.Back}
            onClick={this.onClickBack.bind(this)}
          />
        </UIBox>
      </UIDialog>
    );
  }
}
