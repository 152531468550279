import { ClaimCstUI } from 'ClaimCommonConstant';
import { ReserveApproval } from 'ClaimCommon';
import ClaimSettlement from 'ClaimSettlement';
import ClaimSubrogationExt from 'ClaimSubrogationExt';
import {
  CalculationHandlingAction,
  CalculationTaskPage,
  CalculationApprove,
} from 'ClaimCalculation';
import {
  SalvageHandlingAction,
  SalvageLoadAction,
  SalvageTaskPage,
  SalvageApprove,
} from 'ClaimSalvageExt';

const SettlementHandlingAction = ClaimSettlement.getSettlementHandlingAction();
const SettlementTaskPage = ClaimSettlement.getSettlementTaskPage();
const SettlementApprove = ClaimSettlement.getSettlementApprove();
const RecoveryApprove = ClaimSettlement.getRecoveryApprove();
const RecoveryTaskPage = ClaimSettlement.getRecoveryTaskPage();
const SubrogationTaskPage = ClaimSubrogationExt.getSubrogationTaskPage();
const SubrogationApprovel = ClaimSubrogationExt.getSubrogationApprove();
const SubrogationAction = ClaimSubrogationExt.getSubrogationAction();
const RefundApprove = ClaimSettlement.getRefundApprove();
const RefundTaskPage = ClaimSettlement.getRefundTaskPage();

// create by sihuan.huang 9.22
const TabStrategy = function(caseForm) {
  return new TabStrategy.fn(caseForm);
};

let claimData;
let taskInstance;
let claimEntity;
let authMap;
let objectDatas;
let caseId;
let policyNo;
let caseIdParam;
let taskCode;
let taskInstanceId;
let taskInstanceIdParam;
let claimTabs;
const instance = {};
const strategyContext = {};

TabStrategy.fn = function(caseForm) {
  this.init(caseForm);
};
TabStrategy.fn.prototype = {
  constructor: TabStrategy.fn,
  alternativeTabs: [
    'SettlementApproval',
    'RecoveryApproval',
    'CalculationApproval',
    'ReserveUpdateApproval',
    'ReserveCloseApproval',
    'ClaimSubrogationApprovalTask',
    'RefundApproval',
  ],
  defaultTabs: [
    'Settlement',
    'Recovery',
    'Calculation',
    'ClaimSubrogationTask',
    'Refund',
  ],

  init(caseForm) {
    claimData = caseForm.ClaimData;
    taskInstance = caseForm.TaskInstance;
    claimEntity = caseForm.ClaimEntity;
    authMap = claimData.AuthMap;
    objectDatas = claimData.ObjectDatas;
    caseId = claimEntity.CaseId;
    policyNo = claimEntity.PolicyNo;
    caseIdParam = { name: 'caseId', value: caseId };
    taskCode = taskInstance ? taskInstance.TaskCode : '';
    taskInstanceId = taskInstance ? taskInstance.TaskInstanceId : '';
    taskInstanceIdParam = {
      name: 'taskInstanceId',
      value: taskInstanceId,
    };
    claimTabs = [];

    const salList = SalvageLoadAction.getClosedTasks(caseId) || [];
    if (
      (ClaimCstUI.CLAIM_TASK_CODE.Salvage == taskCode ||
        salList.length > 0) &&
      ClaimCstUI.CLAIM_TASK_CODE.SalvageApproval != taskCode
    ) {
      if (this.defaultTabs.indexOf('Salvage') == -1) {
        this.defaultTabs.push('Salvage');
      }
    } else if (
      (ClaimCstUI.CLAIM_TASK_CODE.SalvageApproval == taskCode ||
        salList.length > 0) &&
      ClaimCstUI.CLAIM_TASK_CODE.Salvage != taskCode
    ) {
      this.defaultTabs.push('SalvageApproval');
    }

    const sCode = this.taskCodeTransform(taskCode);
    strategyContext.DefaultStrategy = this.defaultStrategy(sCode);
    strategyContext.FIStrategy = this.FIStrategy();
    strategyContext.RegistrationStrategy = this.RegistrationStrategy();
  },
  taskCodeTransform(taskcode) {
    let strategyTaskCode = '';
    switch (taskcode) {
      case ClaimCstUI.CLAIM_TASK_CODE.Recovery:
        strategyTaskCode = 'Recovery';
        break;
      case ClaimCstUI.CLAIM_TASK_CODE.RecoveryApproval:
        strategyTaskCode = 'RecoveryApproval';
        break;
      case ClaimCstUI.CLAIM_TASK_CODE.Settlement:
        strategyTaskCode = 'Settlement';
        break;
      case ClaimCstUI.CLAIM_TASK_CODE.SettlementApproval:
        strategyTaskCode = 'SettlementApproval';
        break;
      case ClaimCstUI.CLAIM_TASK_CODE.Calculation:
        strategyTaskCode = 'Calculation';
        break;
      case ClaimCstUI.CLAIM_TASK_CODE.CalculationApproval:
        strategyTaskCode = 'CalculationApproval';
        break;
      case ClaimCstUI.CLAIM_TASK_CODE.ReserveUpdateApprovalTask:
        strategyTaskCode = 'ReserveUpdateApproval';
        break;
      case ClaimCstUI.CLAIM_TASK_CODE.ReserveCloseApprovalTask:
        strategyTaskCode = 'ReserveCloseApproval';
        break;
      case ClaimCstUI.CLAIM_TASK_CODE.Salvage:
        strategyTaskCode = 'Salvage';
        break;
      case ClaimCstUI.CLAIM_TASK_CODE.SalvageApproval:
        strategyTaskCode = 'SalvageApproval';
        break;
      case ClaimCstUI.CLAIM_TASK_CODE.Refund:
        strategyTaskCode = 'Refund';
        break;
      case ClaimCstUI.CLAIM_TASK_CODE.RefundApproval:
        strategyTaskCode = 'RefundApproval';
        break;
      default:
        break;
    }
    if (!strategyTaskCode && taskCode.indexOf('Subrogation') != -1)
      strategyTaskCode = taskcode;
    return strategyTaskCode;
  },
  tabs(s) {
    // if(instance[s]) return instance[s] ;
    const self = this;
    var tabs;
    var tabs = strategyContext[s];
    if (!tabs) throw 'incorrect strategy';
    console.info('tabs', tabs);
    tabs.forEach(d => {
      const o = self[d].apply();
      claimTabs.push(o);
    });
    instance[s] = claimTabs;
    return claimTabs;
  },

  defaultStrategy(s) {
    let adddedTabs = [];
    console.info('adddedTabs', adddedTabs);
    if (this.defaultTabs.indexOf(s) != -1) {
      adddedTabs = this.defaultTabs;
    } else {
      const index = this.alternativeTabs.indexOf(s);
      const a1 = this.alternativeTabs.slice(index, index + 1) || [];
      let a2 = [];
      if (index + 1 > this.defaultTabs.length) {
        a2 = this.defaultTabs;
      } else {
        console.info('adddedTabs index', index);
        this.defaultTabs.forEach(function(v, i) {
          if (i != index) a2.push(v);
        });
      }
      console.info('adddedTabs a2', a2);
      adddedTabs = a2.concat(a1);
    }
    console.info('adddedTabs', s);
    console.info('adddedTabs', adddedTabs);
    const subrogations = SubrogationAction.loadSubrogationHistory4Tab(
      caseId
    );
    console.info('adddedTabs subrogations ', subrogations);
    if (
      ((subrogations == null || subrogations.length == 0) &&
        s != 'ClaimSubrogationTask') ||
      s == 'ClaimSubrogationApprovalTask'
    ) {
      for (let i = 0; i < adddedTabs.length; i += 1) {
        if (adddedTabs[i] == 'ClaimSubrogationTask') {
          adddedTabs.splice(i, 1);
          break;
        }
      }
    }
    console.info('adddedTabs', adddedTabs);
    return adddedTabs;
  },
  FIStrategy(s) {
    return [];
  },
  RegistrationStrategy(s) {
    return [];
  },
  Recovery() {
    const workOnThis =
      taskCode == ClaimCstUI.CLAIM_TASK_CODE.Recovery;
    return {
      title: i18n.ClaimSettlement.Recovery,
      action: workOnThis
        ? SettlementHandlingAction.changeSettlement
        : SettlementHandlingAction.changeHistory,
      IsActive: workOnThis ? 1 : null,
      params: workOnThis
        ? [
            taskInstanceIdParam,
            caseIdParam,
            { name: 'policyNo', value: policyNo },
            {
              name: 'taskCode',
              value: ClaimCstUI.CLAIM_TASK_CODE.Recovery,
            },
          ]
        : [
            caseIdParam,
            {
              name: 'taskCode',
              value: ClaimCstUI.CLAIM_TASK_CODE.Recovery,
            },
          ],
      content: (
        <RecoveryTaskPage
          claimEntity={claimEntity}
          taskInstanceId={workOnThis ? taskInstanceId : null}
          caseId={caseId}
          policyNo={policyNo}
          readOnly="false"
        />
      ),
    };
  },
  RecoveryApproval() {
    return {
      title: i18n.ClaimSettlement.RecoveryApproval,
      action: SettlementHandlingAction.changeSettlement,
      IsActive: 1,
      params: [
        taskInstanceIdParam,
        caseIdParam,
        {
          name: 'taskCode',
          value: ClaimCstUI.CLAIM_TASK_CODE.RecoveryApproval,
        },
      ],
      content: (
        <RecoveryApprove
          claimEntity={claimEntity}
          taskInstanceId={taskInstanceId}
          caseId={caseId}
          readOnly="false"
        />
      ),
    };
  },
  Settlement() {
    const workOnThis =
      taskCode == ClaimCstUI.CLAIM_TASK_CODE.Settlement;
    return {
      title: i18n.ClaimSettlement.Settlement,
      action: workOnThis
        ? SettlementHandlingAction.changeSettlement
        : SettlementHandlingAction.changeHistory,
      IsActive: workOnThis ? 1 : null,
      params: workOnThis
        ? [
            taskInstanceIdParam,
            caseIdParam,
            { name: 'policyNo', value: policyNo },
            {
              name: 'taskCode',
              value: ClaimCstUI.CLAIM_TASK_CODE.Settlement,
            },
          ]
        : [
            caseIdParam,
            {
              name: 'taskCode',
              value: ClaimCstUI.CLAIM_TASK_CODE.Settlement,
            },
          ],
      content: (
        <SettlementTaskPage
          claimEntity={claimEntity}
          taskInstanceId={workOnThis ? taskInstanceId : null}
          caseId={caseId}
          policyNo={policyNo}
          readOnly="false"
        />
      ),
    };
  },
  SettlementApproval() {
    return {
      title: i18n.ClaimSettlement.SettlementApproval,
      action: SettlementHandlingAction.changeSettlement,
      IsActive: 1,
      params: [
        taskInstanceIdParam,
        caseIdParam,
        {
          name: 'taskCode',
          value: ClaimCstUI.CLAIM_TASK_CODE.SettlementApproval,
        },
      ],
      content: (
        <SettlementApprove
          claimEntity={claimEntity}
          taskInstanceId={taskInstanceId}
          caseId={caseId}
          readOnly="false"
        />
      ),
    };
  },

  Calculation() {
    const workOnThis =
      taskCode == ClaimCstUI.CLAIM_TASK_CODE.Calculation;
    return {
      title: i18n.ClaimCalculation.Calculation,
      action: workOnThis
        ? CalculationHandlingAction.changeCalculation
        : null,
      IsActive: workOnThis ? 1 : null,
      params: workOnThis ? [taskInstanceIdParam] : null,
      content: (
        <CalculationTaskPage
          claimEntity={claimEntity}
          taskInstanceId={workOnThis ? taskInstanceId : null}
          caseId={caseId}
          readOnly="false"
        />
      ),
    };
  },

  CalculationApproval() {
    return {
      title: i18n.ClaimCalculation.CalculateApprove,
      action: CalculationHandlingAction.changeCalculation,
      IsActive: 1,
      params: [taskInstanceIdParam],
      content: (
        <CalculationApprove
          claimEntity={claimEntity}
          taskInstanceId={taskInstanceId}
          caseId={caseId}
          readOnly="false"
        />
      ),
    };
  },

  Salvage() {
    const workOnThis = taskCode == ClaimCstUI.CLAIM_TASK_CODE.Salvage;
    return {
      title: i18n.ClaimSalvage.Salvage,
      action: workOnThis ? SalvageHandlingAction.changeSalvage : null,
      IsActive: workOnThis ? 1 : null,
      params: workOnThis ? [taskInstanceIdParam] : null,
      content: (
        <SalvageTaskPage
          claimEntity={claimEntity}
          taskInstanceId={workOnThis ? taskInstanceId : null}
          caseId={caseId}
          readOnly="false"
        />
      ),
    };
  },

  SalvageApproval() {
    const workOnThis =
      taskCode == ClaimCstUI.CLAIM_TASK_CODE.SalvageApproval;
    return {
      title: i18n.ClaimSalvage.SalvageApprove,
      action: workOnThis ? SalvageHandlingAction.changeSalvage : null,
      IsActive: workOnThis ? 1 : null,
      params: workOnThis ? [taskInstanceIdParam] : null,
      content: (
        <SalvageApprove
          claimEntity={claimEntity}
          taskInstanceId={workOnThis ? taskInstanceId : null}
          caseId={caseId}
          readOnly="false"
        />
      ),
    };
  },

  ReserveUpdateApproval() {
    const title = i18n.ClaimReserve.ReserveUpdateApproval;
    const commontype = 'update';
    return {
      title,
      action: null,
      IsActive: 1,
      params: [],
      content: (
        <ReserveApproval
          claimEntity={claimEntity}
          claimNo={claimData.ClaimNo}
          commontype={commontype}
          reserveApprovalId={taskInstance.BusinessId}
          claimData={claimData}
        />
      ),
    };
  },
  ReserveCloseApproval() {
    const title = i18n.ClaimReserve.ReserveClosureApproval;
    const commontype = 'closure';
    return {
      title,
      action: null,
      IsActive: 1,
      params: [],
      content: (
        <ReserveApproval
          claimEntity={claimEntity}
          claimNo={claimData.ClaimNo}
          commontype={commontype}
          reserveApprovalId={taskInstance.BusinessId}
          claimData={claimData}
        />
      ),
    };
  },
  ClaimSubrogationTask() {
    const title = i18n.ClaimSubrogation.Subrogation;
    const workOnThis =
      taskCode == ClaimCstUI.CLAIM_TASK_CODE.Subrogation;
    return {
      title,
      action: null,
      IsActive: workOnThis ? 1 : null,
      params: [],
      content: (
        <SubrogationTaskPage
          claimEntity={claimEntity}
          taskInstanceId={workOnThis ? taskInstanceId : null}
          caseId={caseId}
          policyNo={policyNo}
          readOnly="false"
        />
      ),
    };
  },
  ClaimSubrogationApprovalTask() {
    const title = i18n.ClaimSubrogation.SubrogationApproval;
    return {
      title,
      action: null,
      IsActive: 1,
      params: [],
      content: (
        <SubrogationApprovel
          claimEntity={claimEntity}
          taskInstanceId={taskInstanceId}
          caseId={caseId}
          policyNo={policyNo}
          readOnly="false"
        />
      ),
    };
  },
  Refund() {
    const workOnThis = taskCode == ClaimCstUI.CLAIM_TASK_CODE.Refund;
    return {
      title: i18n.ClaimSettlement.Refund,
      action: workOnThis
        ? SettlementHandlingAction.changeSettlement
        : SettlementHandlingAction.changeHistory,
      IsActive: workOnThis ? 1 : null,
      params: workOnThis
        ? [
            taskInstanceIdParam,
            caseIdParam,
            { name: 'policyNo', value: policyNo },
            {
              name: 'taskCode',
              value: ClaimCstUI.CLAIM_TASK_CODE.Refund,
            },
          ]
        : [
            caseIdParam,
            {
              name: 'taskCode',
              value: ClaimCstUI.CLAIM_TASK_CODE.Refund,
            },
          ],
      content: (
        <RefundTaskPage
          claimEntity={claimEntity}
          taskInstanceId={workOnThis ? taskInstanceId : null}
          caseId={caseId}
          policyNo={policyNo}
          readOnly="false"
        />
      ),
    };
  },
  RefundApproval() {
    return {
      title: i18n.ClaimSettlement.RefundApproval,
      action: SettlementHandlingAction.changeSettlement,
      IsActive: 1,
      params: [
        taskInstanceIdParam,
        caseIdParam,
        {
          name: 'taskCode',
          value: ClaimCstUI.CLAIM_TASK_CODE.RefundApproval,
        },
      ],
      content: (
        <RefundApprove
          claimEntity={claimEntity}
          taskInstanceId={taskInstanceId}
          caseId={caseId}
          readOnly="false"
        />
      ),
    };
  },
};

export default TabStrategy;
