export default {
  salvageItemToAdd: {
    objectId: null,
    itemId: null,
    coverageCode: null,
    currencyCode: null,
    index: 0,
    initial(objectId, itemId, coverageCode, currencyCode, index) {
      this.objectId = objectId;
      this.itemId = itemId;
      this.coverageCode = coverageCode;
      this.currencyCode = currencyCode;
      this.index = index;
    },
    setIndex(index) {
      this.index = index;
    },
    get() {
      return {
        '@pk': null,
        '@type': 'ClaimSalvageItem-ClaimSalvageItem',

        ObjectId: this.objectId,
        ItemId: this.itemId,
        CoverageCode: this.coverageCode,
        CurrencyCode: this.currencyCode,
        Index: this.index,
        RecoverFrom: '',
        SalvageAmount: 0,
        Remark: '',
      };
    },
  },
};
