import {
  UIPanel,
  UIButton,
  UIBox,
  UISmartPanelGrid,
  UIText,
} from 'RainbowUI';
import BindToMixin from 'react-binding';
import PropTypes from 'prop-types';

const ProductCfgTreeAction = require('../action/ProductCfgTreeAction');
const ShowProductTypeDialog = require('./ShowProductTypeDialog');
const ClaimDamageTypeRelationProductLine = require('./relation/ClaimDamageTypeRelationProductLine');
const ClaimDamageTypeRelationSubclaimTypeCode = require('./relation/ClaimDamageTypeRelationSubclaimTypeCode');
const ClaimLossCauseRelationProductLine = require('./relation/ClaimLossCauseRelationProductLine');
const LossOfCauseSubclaimTypeRelation = require('./relation/LossOfCauseSubclaimTypeRelation');
const CoverageCategoryRelationProductLine = require('./relation/CoverageCategoryRelationProductLine');
const CoverageCategorySubclaimTypeRelation = require('./relation/CoverageCategorySubclaimTypeRelation');
const SubCalimCoverageReservePaymentRelationProductLine = require('./relation/SubCalimCoverageReservePaymentRelationProductLine');
const SubCalimCoverageReserveRelationProductLine = require('./relation/SubCalimCoverageReserveRelationProductLine');
const SubclaimCoverageReservePaymentRelation = require('./relation/SubclaimCoverageReservePaymentRelation');
const SubclaimCoverageReserveRelation = require('./relation/SubclaimCoverageReserveRelation');

const Config = require('../constant/Configuration_componentMapUrl');
const ProductTreeNodeStore = require('../store/ProductTreeNodeStore');
const NewProductDialog = require('./NewProductDialog');
const Url = require('../url/Url');
const CoverageCategoryOfProductLineAction = require('../action/CoverageCategoryOfProductLineAction');
const LossCauseAction = require('../action/LossCauseAction');
const SubclaimTypeAction = require('../action/SubclaimTypeAction');
const CalimDamageTypeRelationAction = require('../action/CalimDamageTypeRelationAction');
const CauseOfLossRelationAction = require('../action/CauseOfLossRelationAction');
const CoverageCategoryRelationAction = require('../action/CoverageCategoryRelationAction');
const SubclaimCoverageReserveOfProductLineAction = require('../action/SubclaimCoverageReserveOfProductLineAction');
const ClmCoverageReservePaymentOfProductLineAction = require('../action/ClmCoverageReservePaymentOfProductLineAction');
const ViewParameterMatrixAction = require('../action/ViewParameterMatrixAction');
const ProductCoverageListAction = require('../action/ProductCoverageListAction');

const ProductCfgDetail = React.createClass({
  mixins: [BindToMixin],
  propTypes: {
    productCfgDetail: PropTypes.string,
    content: PropTypes.object,
  },
  getInitialState() {
    return {
      ProductTreeEntity: {
        name: i18n.ClaimProduct.ProductLine,
        treeNodeUrl: 'pl',
      },
      isUpdate: false,
      searchConditions: {},
    };
  },
  isUpdate() {
    ProductCfgTreeAction.initData();
    this.setState({ isUpdate: true });
  },

  componentDidMount() {
    ProductTreeNodeStore.listen(this.onStoreStateChange);
  },

  componentWillUnmount() {
    ProductTreeNodeStore.unlisten(this.onStoreStateChange);
  },
  getSuperComponent() {
    return this;
  },
  onStoreStateChange(storeState) {
    if (!_.isEmpty(storeState.ProductTreeEntity)) {
      this.setState({
        ProductTreeEntity: storeState.ProductTreeEntity,
      });
    }
  },
  getDefaultProps() {
    return {
      productCfgDetail: 'Product Line',
    };
  },
  render() {
    const { ProductTreeEntity } = this.state;
    return (
      <div id="treeDiv">
        {this.showProductCfgSearch(ProductTreeEntity)}
        <UIPanel
          id="productCfgDetail"
          panelTitle={ProductTreeEntity.name}
        >
          {this.showProductCfgDetail(ProductTreeEntity)}
        </UIPanel>

        <ShowProductTypeDialog parentFunction={this.isUpdate} />
        <NewProductDialog parentFunction={this.isUpdate} />
      </div>
    );
  },

  showProductCfgSearch(entity) {
    const self = this;
    const { treeNodeUrl } = self.state.ProductTreeEntity;
    const { treeNodeLevel } = self.state.ProductTreeEntity;
    const url = entity.treeNodeUrl;
    if (
      treeNodeUrl === 'coverageCategory' ||
      treeNodeUrl === 'causeofLoss' ||
      treeNodeUrl === 'subclaimType' ||
      treeNodeUrl === 'subclaimTypeandDamageType' ||
      treeNodeUrl === 'causeofLossAndSubclaimType' ||
      treeNodeUrl === 'subclaimTypeandCoverageCategory' ||
      treeNodeUrl === 'reserveType' ||
      treeNodeUrl === 'settlementType' ||
      treeNodeUrl === 'parameterMatrix' ||
      (treeNodeUrl === 'coverage' && treeNodeLevel === 'RootCoverage')
    ) {
      if (url === 'coverageCategory') {
        return (
          <div>
            <UIPanel
              id="productCfgSearch"
              panelTitle={i18n.ClaimCommon.SearchCriteria}
            >
              <UIText
                id="coverageCategoryCode"
                valueLink={self.bindToState(
                  'searchConditions',
                  'coverageCategoryCode'
                )}
                label={i18n.ClaimProduct.CoverageCategoryCode}
              />
              <UIBox direction="left">
                <UIButton
                  value={i18n.Reset}
                  onClick={self.reset.bind(self)}
                />
                <UIButton
                  value={i18n.SEARCH}
                  update="CoverageCategoryOfProductLinePage"
                  onClick={self.searchCoverageCategoryOfProductLinePage.bind(
                    self
                  )}
                />
              </UIBox>
            </UIPanel>
          </div>
        );
      }
      if (url === 'causeofLoss') {
        return (
          <div>
            <UIPanel
              id="productCfgSearch"
              panelTitle={i18n.ClaimCommon.SearchCriteria}
            >
              <UIText
                id="causeofLossCode"
                valueLink={self.bindToState(
                  'searchConditions',
                  'causeofLossCode'
                )}
                label={i18n.ClaimProduct.CauseofLossCode}
              />
              <UIBox direction="left">
                <UIButton
                  value={i18n.Reset}
                  onClick={self.reset.bind(self)}
                />
                <UIButton
                  value={i18n.SEARCH}
                  update="causeLossList"
                  onClick={self.searchCauseLossList.bind(self)}
                />
              </UIBox>
            </UIPanel>
          </div>
        );
      }
      if (url === 'subclaimType') {
        return (
          <div>
            <UIPanel
              id="productCfgSearch"
              panelTitle={i18n.ClaimCommon.SearchCriteria}
            >
              <UIText
                id="subclaimTypeCode"
                valueLink={self.bindToState(
                  'searchConditions',
                  'subclaimTypeCode'
                )}
                label={i18n.ClaimProduct.SubclaimTypeCode}
              />
              <UIBox direction="left">
                <UIButton
                  value={i18n.Reset}
                  onClick={self.reset.bind(self)}
                />
                <UIButton
                  value={i18n.SEARCH}
                  update="subclaimTypeListPage"
                  onClick={self.searchSubclaimTypeList.bind(self)}
                />
              </UIBox>
            </UIPanel>
          </div>
        );
      }
      if (url === 'subclaimTypeandDamageType') {
        return (
          <div>
            <UIPanel
              id="productCfgSearch"
              panelTitle={i18n.ClaimCommon.SearchCriteria}
            >
              <UIText
                id="subclaimType1"
                valueLink={self.bindToState(
                  'searchConditions',
                  'subclaimTypeCode1'
                )}
                label={i18n.ClaimProduct.SubclaimTypeCode}
              />
              <UIBox direction="left">
                <UIButton
                  value={i18n.Reset}
                  onClick={self.reset.bind(self)}
                />
                <UIButton
                  value={i18n.SEARCH}
                  update="subclaimTypeandDamageTypeList"
                  onClick={self.searchSubclaimTypeandDamageTypeList.bind(
                    self
                  )}
                />
              </UIBox>
            </UIPanel>
          </div>
        );
      }
      if (url === 'causeofLossAndSubclaimType') {
        return (
          <div>
            <UIPanel
              id="productCfgSearch"
              panelTitle={i18n.ClaimCommon.SearchCriteria}
            >
              <UIText
                id="causeofLoss"
                valueLink={self.bindToState(
                  'searchConditions',
                  'causeofLossCode1'
                )}
                label={i18n.ClaimProduct.CauseofLossCode}
              />
              <UIBox direction="left">
                <UIButton
                  value={i18n.Reset}
                  onClick={self.reset.bind(self)}
                />
                <UIButton
                  value={i18n.SEARCH}
                  update="causeofLossAndSubclaimTypeList"
                  onClick={self.searchLossCauseRelationList.bind(
                    self
                  )}
                />
              </UIBox>
            </UIPanel>
          </div>
        );
      }
      if (url === 'subclaimTypeandCoverageCategory') {
        return (
          <div>
            <UIPanel
              id="productCfgSearch"
              panelTitle={i18n.ClaimCommon.SearchCriteria}
            >
              <UIText
                id="subclaimType2"
                valueLink={self.bindToState(
                  'searchConditions',
                  'subclaimTypeCode2'
                )}
                label={i18n.ClaimProduct.SubclaimTypeCode}
              />
              <UIBox direction="left">
                <UIButton
                  value={i18n.Reset}
                  onClick={self.reset.bind(self)}
                />
                <UIButton
                  value={i18n.SEARCH}
                  update="coverageCategoryRelationList"
                  onClick={self.searchSubclaimTypeandCoverageCategoryList.bind(
                    self
                  )}
                />
              </UIBox>
            </UIPanel>
          </div>
        );
      }
      if (url === 'reserveType') {
        return (
          <div>
            <UIPanel
              id="productCfgSearch"
              panelTitle={i18n.ClaimCommon.SearchCriteria}
            >
              <UISmartPanelGrid column="2" widthAllocation="4,8">
                <UIText
                  id="subclaimType3"
                  valueLink={self.bindToState(
                    'searchConditions',
                    'subclaimTypeCode3'
                  )}
                  label={i18n.ClaimProduct.SubclaimTypeCode}
                />
                <UIText
                  id="coverageCategory1"
                  valueLink={self.bindToState(
                    'searchConditions',
                    'coverageCategoryCode1'
                  )}
                  label={i18n.ClaimProduct.CoverageCategoryCode}
                />
              </UISmartPanelGrid>
              <UIBox direction="left">
                <UIButton
                  value={i18n.Reset}
                  onClick={self.reset.bind(self)}
                />
                <UIButton
                  value={i18n.SEARCH}
                  update="subClaimCoverageReserveList"
                  onClick={self.searchReserveTypeList.bind(self)}
                />
              </UIBox>
            </UIPanel>
          </div>
        );
      }
      if (url === 'settlementType') {
        return (
          <div>
            <UIPanel
              id="productCfgSearch"
              panelTitle={i18n.ClaimCommon.SearchCriteria}
            >
              <UISmartPanelGrid column="2" widthAllocation="4,8">
                <UIText
                  id="subclaimType4"
                  valueLink={self.bindToState(
                    'searchConditions',
                    'subclaimTypeCode4'
                  )}
                  label={i18n.ClaimProduct.SubclaimTypeCode}
                />
                <UIText
                  id="coverageCategory2"
                  valueLink={self.bindToState(
                    'searchConditions',
                    'coverageCategoryCode2'
                  )}
                  label={i18n.ClaimProduct.CoverageCategoryCode}
                />
                <UIText
                  id="reserveType1"
                  valueLink={self.bindToState(
                    'searchConditions',
                    'reserveTypeCode'
                  )}
                  label={i18n.ClaimProduct.ReserveTypeCode}
                />
              </UISmartPanelGrid>
              <UIBox direction="left">
                <UIButton
                  value={i18n.Reset}
                  onClick={self.reset.bind(self)}
                />
                <UIButton
                  value={i18n.SEARCH}
                  update="settlementTypeList"
                  onClick={self.searchSettlementTypeList.bind(self)}
                />
              </UIBox>
            </UIPanel>
          </div>
        );
      }
      if (url === 'parameterMatrix') {
        return (
          <div>
            <UIPanel
              id="productCfgSearch"
              panelTitle={i18n.ClaimCommon.SearchCriteria}
            >
              <UISmartPanelGrid column="2" widthAllocation="4,8">
                <UIText
                  id="subclaimType5"
                  valueLink={self.bindToState(
                    'searchConditions',
                    'subclaimTypeCode5'
                  )}
                  label={i18n.ClaimProduct.SubclaimTypeCode}
                />
                <UIText
                  id="coverageCategory3"
                  valueLink={self.bindToState(
                    'searchConditions',
                    'coverageCategoryCode3'
                  )}
                  label={i18n.ClaimProduct.CoverageCategoryCode}
                />
                <UIText
                  id="reserveType2"
                  valueLink={self.bindToState(
                    'searchConditions',
                    'reserveTypeCode2'
                  )}
                  label={i18n.ClaimProduct.ReserveTypeCode}
                />
              </UISmartPanelGrid>
              <UIBox direction="left">
                <UIButton
                  value={i18n.Reset}
                  onClick={self.reset.bind(self)}
                />
                <UIButton
                  value={i18n.SEARCH}
                  update="parameterMatrixList"
                  onClick={self.parameterMatrixList.bind(self)}
                />
              </UIBox>
            </UIPanel>
          </div>
        );
      }

      if (url === 'coverage') {
        return (
          <div>
            <UIPanel
              id="productCfgSearch"
              panelTitle={i18n.ClaimCommon.SearchCriteria}
            >
              <UISmartPanelGrid column="2" widthAllocation="4,8">
                <UIText
                  id="coverageCode"
                  label={i18n.ClaimProduct.CoverageCategoryCode}
                  valueLink={self.bindToState(
                    'searchConditions',
                    'coverageCode'
                  )}
                />
                <UIText
                  id="productCoverTypeCodeSearch"
                  label={i18n.ClaimProduct.ExtCoverageTypeCode}
                  valueLink={self.bindToState(
                    'searchConditions',
                    'productCoverTypeCode'
                  )}
                />
                <UIText
                  id="claimable"
                  label="Claimable"
                  valueLink={self.bindToState(
                    'searchConditions',
                    'claimable'
                  )}
                />
              </UISmartPanelGrid>
              <UIBox direction="left">
                <UIButton
                  value={i18n.Reset}
                  onClick={self.reset.bind(self)}
                />
                <UIButton
                  value={i18n.SEARCH}
                  onClick={self.searchProductCoverageList.bind(self)}
                />
              </UIBox>
            </UIPanel>
          </div>
        );
      }
    }
    return null;
  },

  showProductCfgDetail(entity) {
    const url = entity.treeNodeUrl;

    if (url === 'subclaimTypeandDamageType') {
      return <ClaimDamageTypeRelationProductLine entity={entity} />;
    }
    if (url === 'subclaimTypeandDamageTypeRelation') {
      return (
        <ClaimDamageTypeRelationSubclaimTypeCode entity={entity} />
      );
    }
    if (url === 'causeofLossAndSubclaimType') {
      return <ClaimLossCauseRelationProductLine entity={entity} />;
    }
    if (url === 'causeofLossAndSubclaimTypeRelation') {
      return <LossOfCauseSubclaimTypeRelation entity={entity} />;
    }
    if (url === 'subclaimTypeandCoverageCategory') {
      return <CoverageCategoryRelationProductLine entity={entity} />;
    }
    if (url === 'subclaimTypeandCoverageCategoryRelation') {
      return <CoverageCategorySubclaimTypeRelation entity={entity} />;
    }
    if (url === 'reserveType') {
      return (
        <SubCalimCoverageReserveRelationProductLine entity={entity} />
      );
    }
    if (url === 'reserveTypeRelation') {
      return <SubclaimCoverageReserveRelation entity={entity} />;
    }
    if (url === 'settlementType') {
      return (
        <SubCalimCoverageReservePaymentRelationProductLine
          entity={entity}
        />
      );
    }
    if (url === 'settlementTypeRelation') {
      return (
        <SubclaimCoverageReservePaymentRelation entity={entity} />
      );
    }

    if (!Config.UrlMapComponent[url]) {
      return;
    }
    return Config.UrlMapComponent[url];
  },

  reset() {
    this.setState({ searchConditions: {} });
  },

  searchCoverageCategoryOfProductLinePage() {
    const condition = {};
    const { coverageCategoryCode } = this.state.searchConditions;
    if (coverageCategoryCode) {
      condition.coverageCategoryCode = coverageCategoryCode;
    }
    CoverageCategoryOfProductLineAction.searchCoverageCategoryOfProductLinePage(
      condition
    );
  },

  searchCauseLossList() {
    const condition = {};
    const { causeofLossCode } = this.state.searchConditions;
    if (causeofLossCode) {
      condition.causeofLossCode = causeofLossCode;
    }
    LossCauseAction.searchCauseLossList(condition);
  },

  searchSubclaimTypeList() {
    const condition = {};
    const { subclaimTypeCode } = this.state.searchConditions;
    if (subclaimTypeCode) {
      condition.subclaimTypeCode = subclaimTypeCode;
    }
    SubclaimTypeAction.searchSubclaimTypeList(condition);
  },

  searchSubclaimTypeandDamageTypeList() {
    const condition = {};
    const subclaimTypeCode = this.state.searchConditions
      .subclaimTypeCode1;
    if (subclaimTypeCode) {
      condition.subclaimTypeCode = subclaimTypeCode;
    }
    CalimDamageTypeRelationAction.searchClaimDamageTypeRelationList(
      condition
    );
  },

  searchLossCauseRelationList() {
    const condition = {};
    const causeofLossCode = this.state.searchConditions
      .causeofLossCode1;
    if (causeofLossCode) {
      condition.causeofLossCode = causeofLossCode;
    }
    CauseOfLossRelationAction.searchClaimLossCauseRelationList(
      condition
    );
  },

  searchSubclaimTypeandCoverageCategoryList() {
    const condition = {};
    const subclaimTypeCode = this.state.searchConditions
      .subclaimTypeCode2;
    if (subclaimTypeCode) {
      condition.subclaimTypeCode = subclaimTypeCode;
    }
    CoverageCategoryRelationAction.searchSubclaimTypeandCoverageCategoryList(
      condition
    );
  },

  searchReserveTypeList() {
    const condition = {};
    const subclaimTypeCode = this.state.searchConditions
      .subclaimTypeCode3;
    const coverageCategoryCode = this.state.searchConditions
      .coverageCategoryCode1;
    if (subclaimTypeCode) {
      condition.subclaimTypeCode = subclaimTypeCode;
    }
    if (coverageCategoryCode) {
      condition.coverageCategoryCode = coverageCategoryCode;
    }
    SubclaimCoverageReserveOfProductLineAction.searchReserveTypeList(
      condition
    );
  },

  searchSettlementTypeList() {
    const condition = {};
    const subclaimTypeCode = this.state.searchConditions
      .subclaimTypeCode4;
    const coverageCategoryCode = this.state.searchConditions
      .coverageCategoryCode2;
    const { reserveTypeCode } = this.state.searchConditions;
    if (subclaimTypeCode) {
      condition.subclaimTypeCode = subclaimTypeCode;
    }
    if (coverageCategoryCode) {
      condition.coverageCategoryCode = coverageCategoryCode;
    }
    if (reserveTypeCode) {
      condition.reserveTypeCode = reserveTypeCode;
    }
    ClmCoverageReservePaymentOfProductLineAction.searchSettlementTypeList(
      condition
    );
  },

  parameterMatrixList() {
    const condition = {};
    const subclaimTypeCode = this.state.searchConditions
      .subclaimTypeCode5;
    const coverageCategoryCode = this.state.searchConditions
      .coverageCategoryCode3;
    const reserveTypeCode = this.state.searchConditions
      .reserveTypeCode2;
    if (subclaimTypeCode) {
      condition.subclaimTypeCode = subclaimTypeCode;
    }
    if (coverageCategoryCode) {
      condition.coverageCategoryCode = coverageCategoryCode;
    }
    if (reserveTypeCode) {
      condition.reserveTypeCode = reserveTypeCode;
    }
    ViewParameterMatrixAction.searchParameterMatrixList(condition);
  },

  searchProductCoverageList() {
    const condition = {};
    const { coverageCode } = this.state.searchConditions;
    const { productCoverTypeCode } = this.state.searchConditions;
    const { claimable } = this.state.searchConditions;
    if (coverageCode) {
      condition.coverageCode = coverageCode;
    }
    if (productCoverTypeCode) {
      condition.productCoverTypeCode = productCoverTypeCode;
    }
    if (claimable) {
      condition.claimable = claimable;
    }
    ProductCoverageListAction.searchProductCoverageList(condition);
  },
});
export default ProductCfgDetail;
