import {
  UIPage,
  UIPanel,
  UITab,
  UITabItem,
  UIParam,
  UIBox,
  UIOutputText,
  UIText,
  UIDataTable,
  MessageHelper,
  UIDateTimePicker,
  UISmartPanelGrid,
  UIButton,
  UIColumn,
  UILink,
  UISelect,
} from 'RainbowUI';

import BindToMixin from 'react-binding';
import Url from '../../url/Url';

const CodeTable = require('../../codeTable/CodeTable');
const RtaAction = require('../../action/RtaAccidentAction');

const AccidentRtaDetail = React.createClass({
  mixins: [BindToMixin],
  getInitialState() {
    const accidentid = this.props.params.AccidentId;
    const accident = RtaAction.loadAccident(accidentid);

    let acceptNum = 0;
    let rejectNum = 0;
    let pendingNum = 0;
    let noMatchNum = 0;
    for (let i = 0; i < accident.VehicleList.length; i += 1) {
      const veh = accident.VehicleList[i];

      if (veh.Status == 1) {
        pendingNum += 1;
      } else if (veh.Status == 2) {
        acceptNum += 1;
      } else if (veh.Status == 3) {
        rejectNum += 1;
      } else if (veh.Status == 4) {
        noMatchNum += 1;
      }
    }

    let showRejectAccidentButtton = false;
    if (accident.AccidentStatus == 1 && acceptNum === 0) {
      showRejectAccidentButtton = true;
    }
    let showProcessedAccidentButtton = false;
    if (accident.AccidentStatus == 1 && acceptNum > 0) {
      showProcessedAccidentButtton = true;
    }

    return {
      Accident: accident,
      AccidentId: accidentid,
      ShowRejectAccidentButtton: showRejectAccidentButtton,
      ShowProcessedAccidentButtton: showProcessedAccidentButtton,
    };
  },
  renderGenerateOrRejectText(vehicle) {
    if (
      vehicle.Status == 2 &&
      vehicle.ClaimNo &&
      vehicle.ClaimNo !== ''
    ) {
      return (
        <UIOutputText
          value={
            i18n.ClaimCommon.RtaVehicleClaimNoGenerated +
            vehicle.ClaimNo
          }
        />
      );
    }
    if (
      vehicle.Status == 2 &&
      vehicle.NoticeNo &&
      vehicle.NoticeNo !== ''
    ) {
      return (
        <UIOutputText
          value={
            i18n.ClaimCommon.RtaVehicleNotiGenerated +
            vehicle.NoticeNo
          }
        />
      );
    }
    return <div />;
  },
  renderPolicyNoText(vehicle) {
    if (vehicle.PolicyNo && vehicle.PolicyNo !== '') {
      return (
        <div>
          <UIOutputText
            value={
              i18n.ClaimCommon.MatchedPolicyNo + vehicle.PolicyNo
            }
          />
        </div>
      );
    }
    return <div />;
  },
  clickBack() {
    window.location.hash = '#/Accident/Rta';
  },
  clickAccurateLocation(event) {
    const loc = event.paramJson['0'].value;
    const uri = 'http://maps.google.com/maps?q=loc:{location}';
    const newuri = uri.replace('{location}', loc);
    window.open(newuri, 'newwin');
  },
  clickDownload() {
    const url = Url.RtaDownLoadZip + this.state.AccidentId;
    console.log('url', url);
    try {
      RtaAction.ajaxGetBlob(url, () => {
        MessageHelper.success(
          i18n.ClaimCommon.SuccessMsg.Downloadsuccessfully,
          null,
          MessageHelper.POSITION_TOP_RIGHT
        );
      });
    } catch (e) {
      MessageHelper.error(
        i18n.ClaimCommon.ErrorMsg.DownloadError,
        null,
        MessageHelper.POSITION_TOP_RIGHT
      );
    }
  },
  clickRejectAccident() {
    const accident = this.state.Accident;
    for (let i = 0; i < accident.VehicleList.length; i += 1) {
      const veh = accident.VehicleList[i];
      if (veh.Status == 1) {
        veh.Status = 3;
      }
    }
    this.checkAccidentStatusAndSave();
  },

  clickCompleteAccident() {
    const accident = this.state.Accident;
    for (let i = 0; i < accident.VehicleList.length; i += 1) {
      const veh = accident.VehicleList[i];
      if (veh.Status == 1) {
        veh.Status = 3;
      }
    }
    this.checkAccidentStatusAndSave();
  },
  onRejectVehicle(event) {
    const regno = event.getParameter('RegNo');
    const accident = this.state.Accident;
    for (let i = 0; i < accident.VehicleList.length; i += 1) {
      const veh = accident.VehicleList[i];
      if (veh.RegNo == regno) {
        veh.Status = 3;
      }
    }
    this.checkAccidentStatusAndSave();
  },
  onAcceptVehicle(event) {
    const regno = event.getParameter('RegNo');
    window.location.hash = `#/notice/noticeofloss/${regno}/${this.state.AccidentId}`;
  },
  checkAccidentStatusAndSave() {
    const { Accident } = this.state;
    let accident = Accident;
    let acceptNum = 0;
    let rejectNum = 0;
    let pendingNum = 0;
    let noMatchNum = 0;
    for (let i = 0; i < accident.VehicleList.length; i += 1) {
      const veh = accident.VehicleList[i];
      if (veh.Status == 1) {
        pendingNum += 1;
      } else if (veh.Status == 2) {
        acceptNum += 1;
      } else if (veh.Status == 3) {
        rejectNum += 1;
      } else {
        noMatchNum += 1;
      }
    }

    if (pendingNum === 0) {
      if (acceptNum === 0) {
        accident.AccidentStatus = 3;
      } else {
        accident.AccidentStatus = 2;
      }
    } else {
      accident.AccidentStatus = 1;
    }

    for (let i = 0; i < accident.VehicleList.length; i += 1) {
      const veh = accident.VehicleList[i];
      for (let j = 0; j < veh.PersonList.length; j += 1) {
        const per = veh.PersonList[j];
        delete per.dataIndex;
      }
    }

    let showRejectAccidentButtton = false;
    if (accident.AccidentStatus == 1 && acceptNum === 0) {
      showRejectAccidentButtton = true;
    }
    let showProcessedAccidentButtton = false;
    if (accident.AccidentStatus == 1 && acceptNum > 0) {
      showProcessedAccidentButtton = true;
    }

    accident = RtaAction.saveAccident(accident);
    if (accident) {
      this.setState({
        Accident: accident,
        ShowRejectAccidentButtton: showRejectAccidentButtton,
        ShowProcessedAccidentButtton: showProcessedAccidentButtton,
      });
    }
  },

  renderVehicleTabitem(vehicleIndex) {
    const _self = this;
    const { Accident } = this.state;
    const vehicle = Accident.VehicleList[vehicleIndex];
    let tabTitle = `${i18n.ClaimCommon.Vehicle} ${vehicle.RegNo}`;
    if (vehicle.Status == 3) {
      tabTitle = `${tabTitle} ${i18n.ClaimCommon.Rejected}`;
    } else if (vehicle.Status == 2) {
      tabTitle = `${tabTitle} ${i18n.ClaimCommon.ClaimGenerated}`;
    }

    const policyText = _self.renderPolicyNoText(vehicle);
    const claimInfotext = _self.renderGenerateOrRejectText(vehicle);

    return (
      <UITabItem title={tabTitle}>
        {policyText}
        {claimInfotext}
        <UISmartPanelGrid column="2">
          <UIText
            label={i18n.ClaimCommon.AgencyCode}
            value={vehicle.AgencyCode}
            io="out"
          />
          <UIText
            label={i18n.ClaimCommon.RegistrationNo}
            value={vehicle.RegNo}
            io="out"
          />
          <UIText
            label={i18n.ClaimCommon.Brand}
            value={vehicle.Brand}
            io="out"
          />
          <UIText
            label={i18n.ClaimCommon.Model}
            value={vehicle.Model}
            io="out"
          />
          <UIText
            label={i18n.ClaimCommon.Type}
            value={vehicle.Type}
            io="out"
          />
          <UIText
            label={i18n.ClaimCommon.Capacity}
            value={vehicle.Cc}
            io="out"
          />
          <UIText
            label={i18n.ClaimCommon.Color}
            value={vehicle.Color}
            io="out"
          />
          <UIText
            label={i18n.ClaimCommon.Passenger}
            value={vehicle.Passengers}
            io="out"
          />
          <UIText
            label={i18n.ClaimCommon.Declaration}
            value={vehicle.Declaration}
            io="out"
          />
          <UIText
            label={i18n.ClaimCommon.VisitorNoate}
            value={vehicle.VisitorNotes}
            io="out"
          />
          <UIText
            label={i18n.ClaimCommon.DamagePart}
            value={vehicle.DamagePartList.join(', ')}
            io="out"
          />
          <UIText
            label={i18n.ClaimCommon.Insurance}
            value={vehicle.InsuranceList.join(', ')}
            io="out"
          />
          <UIText
            label={i18n.ClaimCommon.UploadDeclaration}
            value={vehicle.UploadDeclaration}
            io="out"
          />
          <UIText
            label={i18n.ClaimCommon.UploadDrivingLicense}
            value={vehicle.UploadDrivingLicense}
            io="out"
          />
          <UIText
            label={i18n.ClaimCommon.UploadOwnerCertificate}
            value={vehicle.UploadOwnerCertificate}
            io="out"
          />
          <UIText
            label={i18n.ClaimCommon.UploadRentalAggrement}
            value={vehicle.UploadRentalAggrement}
            io="out"
          />
          <UIText
            label={i18n.ClaimCommon.UploadInsuranceCertificate}
            value={vehicle.UploadInsuranceCertificate}
            io="out"
          />
          <UISelect
            label={i18n.ClaimCommon.Status}
            codeTable={CodeTable.rtaVehicleStatus}
            value={vehicle.Status}
            io="out"
          />
        </UISmartPanelGrid>
        <UIPanel panelTitle={i18n.ClaimCommon.RelevantPerson}>
          <UIDataTable
            value={vehicle.PersonList}
            pageable="false"
            indexable="false"
            styleClass="primary"
          >
            <UIColumn
              headerTitle={i18n.ClaimCommon.PoliticalID}
              value="PoliticalID"
            />
            <UIColumn
              headerTitle={i18n.ClaimCommon.Name}
              value="Name"
            />
            <UIColumn
              headerTitle={i18n.ClaimCommon.Sex}
              value="Sex"
            />
            <UIColumn
              headerTitle={i18n.ClaimCommon.BirthDate}
              value="Birthdate"
            />
            <UIColumn
              headerTitle={i18n.ClaimCommon.Tel}
              value="Tel"
            />
            <UIColumn
              headerTitle={i18n.ClaimCommon.LicenseNumber}
              value="LicenseNumber"
            />
            <UIColumn
              headerTitle={i18n.ClaimCommon.LicenseDate}
              value="LicenseDate"
            />
            <UIColumn
              headerTitle={i18n.ClaimCommon.PersonType}
              value="PersonType"
            />
            <UIColumn
              headerTitle={i18n.ClaimCommon.Vehicle}
              value="Vehicle"
            />
            <UIColumn
              headerTitle={i18n.ClaimCommon.InsuranceOwner}
              value="InsuranceOwner"
            />
            <UIColumn
              headerTitle={i18n.ClaimCommon.Injuries}
              value="Injuries"
            />
          </UIDataTable>
        </UIPanel>
        <UIBox direction="center">
          <UIButton
            value={i18n.ClaimCommon.RejectVehicle}
            visibled={
              vehicle.Status == 1 ||
              vehicle.Status === undefined ||
              vehicle.Status === null
            }
            onClick={_self.onRejectVehicle}
          >
            <UIParam name="RegNo" value={vehicle.RegNo} />
          </UIButton>
          <UIButton
            value={i18n.ClaimCommon.CreateClaim}
            visibled={
              vehicle.Status == 1 ||
              vehicle.Status === undefined ||
              vehicle.Status === null
            }
            onClick={_self.onAcceptVehicle}
          >
            <UIParam name="RegNo" value={vehicle.RegNo} />
          </UIButton>
        </UIBox>
      </UITabItem>
    );
  },
  renderVehicleTab() {
    const tabs = [];
    for (
      let i = 0;
      i < this.state.Accident.VehicleList.length;
      i += 1
    ) {
      const tt = this.renderVehicleTabitem(i);
      tabs.push(tt);
    }
    return tabs;
  },
  render() {
    let downloadenable = false;
    const { Accident } = this.state;
    if (Accident.ZipFilePath) {
      downloadenable = true;
    }

    const _self = this;
    return (
      <UIPage>
        <div>
          <UIPanel headerable="false">
            <UIPanel
              panelTitle={i18n.ClaimCommon.AccidentInformation}
            >
              <UISmartPanelGrid column="2">
                <UIText
                  label={i18n.ClaimCommon.AccidentId}
                  io="out"
                  valueLink={this.bindToState(
                    'Accident',
                    'AccidentId'
                  )}
                />
                <UIText
                  label={i18n.ClaimCommon.Cateogry}
                  io="out"
                  valueLink={this.bindToState('Accident', 'Cateogry')}
                />
                <UIText
                  label={i18n.ClaimCommon.Classification}
                  io="out"
                  valueLink={this.bindToState(
                    'Accident',
                    'Classification'
                  )}
                />
                <UIDateTimePicker
                  label={i18n.ClaimCommon.AccidentTime}
                  placeHolder="MM/DD/YYYY HH:mm:ss"
                  format="MM/DD/YYYY HH:mm:ss"
                  io="out"
                  valueLink={this.bindToState(
                    'Accident',
                    'AccidentTime'
                  )}
                />
                <UIDateTimePicker
                  label={i18n.ClaimCommon.CallTime}
                  placeHolder="MM/DD/YYYY HH:mm:ss"
                  format="MM/DD/YYYY HH:mm:ss"
                  io="out"
                  valueLink={this.bindToState('Accident', 'CallTime')}
                />
                <UIText
                  label={i18n.ClaimCommon.Operator}
                  io="out"
                  valueLink={this.bindToState('Accident', 'Operator')}
                />
                <UIText
                  label={i18n.ClaimCommon.VisitPerson}
                  io="out"
                  valueLink={this.bindToState(
                    'Accident',
                    'VisitPerson'
                  )}
                />
                <UIDateTimePicker
                  label={i18n.ClaimCommon.VisitTime}
                  placeHolder="MM/DD/YYYY HH:mm:ss"
                  format="MM/DD/YYYY HH:mm:ss"
                  io="out"
                  valueLink={this.bindToState(
                    'Accident',
                    'VisitTime'
                  )}
                />
                <UIText
                  label={i18n.ClaimCommon.PolicePresence}
                  io="out"
                  valueLink={this.bindToState(
                    'Accident',
                    'PolicePresence'
                  )}
                />
                <UIText
                  label={i18n.ClaimCommon.PoliceNumber}
                  io="out"
                  valueLink={this.bindToState(
                    'Accident',
                    'PoliceNumber'
                  )}
                />
                <UIText
                  label={i18n.ClaimCommon.AccidentCondition}
                  io="out"
                  valueLink={this.bindToState(
                    'Accident',
                    'AccidentConditions'
                  )}
                />
                <UIText
                  label={i18n.ClaimCommon.AccidentComment}
                  io="out"
                  valueLink={this.bindToState(
                    'Accident',
                    'AccidentComments'
                  )}
                />
                <UIText
                  label={i18n.ClaimCommon.LocationType}
                  io="out"
                  valueLink={this.bindToState(
                    'Accident',
                    'LocationType'
                  )}
                />
                <UIText
                  label={i18n.ClaimCommon.LocationDistrict}
                  io="out"
                  valueLink={this.bindToState(
                    'Accident',
                    'LocationDistrict'
                  )}
                />
                <UIText
                  label={i18n.ClaimCommon.LocationRoad}
                  io="out"
                  valueLink={this.bindToState(
                    'Accident',
                    'LocationRoad'
                  )}
                />
                <UIText
                  label={i18n.ClaimCommon.LocationPostalCode}
                  io="out"
                  valueLink={this.bindToState(
                    'Accident',
                    'LocationPostalCode'
                  )}
                />
                <UIText
                  label={i18n.ClaimCommon.LocationPOI}
                  io="out"
                  valueLink={this.bindToState(
                    'Accident',
                    'LocationPOI'
                  )}
                />

                <UILink
                  value={i18n.ClaimCommon.AccurateAccidentLocation}
                  onClick={_self.clickAccurateLocation}
                >
                  <UIParam
                    name="location"
                    value={Accident.LocationGoogleMap}
                  />
                </UILink>
              </UISmartPanelGrid>
            </UIPanel>
            <UIPanel panelTitle={i18n.ClaimCommon.DamageInformation}>
              <UITab id="tab01">{_self.renderVehicleTab()}</UITab>
            </UIPanel>
            <UIBox direction="center">
              <UIButton
                value={i18n.ClaimCommon.DownloadDocument}
                enabled={downloadenable}
                onClick={this.clickDownload}
              />
              <UIButton
                value={i18n.ClaimCommon.RejectAccident}
                onClick={this.clickRejectAccident}
                visibled={_self.state.ShowRejectAccidentButtton}
              />
              <UIButton
                value={i18n.ClaimCommon.CompleteAccident}
                onClick={this.clickCompleteAccident}
                visibled={_self.state.ShowProcessedAccidentButtton}
              />
              <UIButton
                value={i18n.ClaimCommon.Back}
                onClick={this.clickBack}
              />
            </UIBox>
          </UIPanel>
        </div>
      </UIPage>
    );
  },
});
export default AccidentRtaDetail;
