import DefaultReserveCriteria from './DefaultReserveCriteria';
import DefaultReserveResult from './DefaultReserveResult';

export default class DefaultReserve extends React.Component {
  render() {
    return (
      <div>
        <DefaultReserveCriteria />
        <DefaultReserveResult />
      </div>
    );
  }
}
