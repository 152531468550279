/* eslint-disable prefer-const */
/* eslint-disable import/named */
import {
  UIPanel,
  UISmartPanelGrid,
  UIText,
  UIBox,
  UIButton,
  UIRadio,
  CodeTable,
  UICheckbox,
  UIDateTimePicker,
  UISelect,
  MessageHelper,
} from 'RainbowUI';
import BindToMixin from 'react-binding';
import { CodeTableAction, CodeTableCstUI } from 'ClaimCommonConstant';
import ClaimCommonExt from 'ClaimCommonExt';

import {
  PtyIntegrationAddress,
  PartyConst,
  PtyIntegrationSearchDialog,
  PartyAction,
} from 'EU00PartyCommonUI';
import AppraisalVehicleAction from '../../action/AppraisalVehicleAction';

let ClaimPartyAction = ClaimCommonExt.getClaimPartyAction();

const AppraisalVehicleInfo = React.createClass({
  mixins: [BindToMixin],

  getInitialState() {
    let makerList = CodeTableAction.loadDataList(937752, null);
    window.makerList = makerList;
    let modelList;
    if (this.props.appraVehicleInfo) {
      modelList = AppraisalVehicleAction.getModelCodeTable(
        this.props.appraVehicleInfo.Maker
      );
    } else {
      modelList = new CodeTable([]);
    }
    return {
      makerList,
      modelList,
      vehicleInfo: this.props.appraVehicleInfo,
      appraisalVehicleInfo: { DamagePart: null },
      appraisalVehicleParty: {},
      mobileDisabled: true,
    };
  },
  getWhetherRegister() {
    return new CodeTable([
      { id: 'Y', text: 'Yes' },
      { id: 'N', text: 'No' },
    ]);
  },
  componentWillMount() {
    console.log(
      '[start damgePart Transfor1]',
      this.props.appraVehicleInfo
    );
    let damagePart = this.state.vehicleInfo.sourceObject
      .appraisalVehicle.DamagePart;
    if (damagePart != undefined && damagePart != null) {
      console.log('[start damgePart Transfor2]', damagePart);
      let damagePartArray = damagePart.split('');
      console.log('[start damgePart Transfor3]', damagePartArray);
      this.state.appraisalVehicleInfo.DamagePart = damagePartArray;
    }
  },

  componentDidMount() {
    let vehicleEntity = this.props.appraVehicleInfo.sourceObject
      .appraisalVehicle;
    let modelList = AppraisalVehicleAction.getModelCodeTable(
      vehicleEntity.Maker
    );
    this.setState({ modelList });
  },

  selectDamagePart() {
    console.log(
      'selectDangePart',
      this.state.appraisalVehicleInfo.DamagePart
    );
    let damagePart = this.state.appraisalVehicleInfo.DamagePart;
    console.log('[select damagePart]', damagePart);
    if (damagePart != undefined && damagePart != null) {
      let newDamagePart = '';
      for (let i = 0; i < damagePart.length; i++) {
        newDamaePart += damagePart[i];
      }
      console.log('[newDamaePart]', newDamaePart);
      console.log('[Test]', typeof newDamaePart);
      this.props.parentComponent.state.AppraisalInfo.appraisalVehicle.DamagePart = newDamaePart;
    }
  },

  getVehicleInfo() {
    return this.state.vehicleInfo;
  },

  getEnabled() {
    return !(
      this.props.isThirdParty == 'N' ||
      (this.props.appraVehicleInfo.sourceObject.appraisalVehicle &&
        this.props.appraVehicleInfo.sourceObject.appraisalVehicle
          .IsDot == 'Y')
    );
  },
  getPlanNoEnabled() {
    return !(
      this.props.isFirstParty == true ||
      this.props.isFirstParty == undefined
    );
  },
  getIOStyle() {
    return this.props.readOnly == true ? 'out' : null;
  },
  getModelCodeTable() {
    let vehicleEntity = this.props.appraVehicleInfo.sourceObject
      .appraisalVehicle;
    let modelList = AppraisalVehicleAction.getModelCodeTable(
      vehicleEntity.Maker
    );
    vehicleEntity.Model = null;
    this.setState({ modelList });
  },
  registrationNoVehicleValidate() {
    let vehicleEntity = this.props.appraVehicleInfo.sourceObject
      .appraisalVehicle;

    if (AppraisalVehicleAction.validatePlateNo(vehicleEntity)) {
      if (vehicleEntity.PlateNo) {
        vehicleEntity.PlateNo = vehicleEntity.PlateNo.toUpperCase();
      }
    } else {
      vehicleEntity.PlateNo = null;
    }
    this.props.updateVehicleInfo(vehicleEntity);
  },
  validateVin() {
    let vehicleEntity = this.props.appraVehicleInfo.sourceObject
      .appraisalVehicle;
    if (
      vehicleEntity.Vin != null &&
      (vehicleEntity.Vin.length < 6 || vehicleEntity.Vin.length > 17)
    ) {
      vehicleEntity.Vin = null;
      MessageHelper.warning(i18n.ClaimAppraisal.WarnMsg.Warn_0004);
    }
    this.props.updateVehicleInfo(vehicleEntity);
  },
  showPartySearchPanelDialog() {
    this.refs.partySearchDialogForGarage.show();
  },

  createClaimParty(commonParty) {
    ClaimPartyAction.createPartyParty(this.props.caseId, commonParty);
  },

  showPartyAddressDialog() {
    let { appraisalVehicleParty } = this.state;
    if (!appraisalVehicleParty.PartyId) {
      MessageHelper.warning(i18n.ClaimAppraisal.WarnMsg.Warn_0007);
    } else {
      this.refs.PtyIntegrationAddress_ref.showDialog(
        appraisalVehicleParty.PartyId
      );
    }
  },

  callBackGetRecord(partyAddress) {
    let { vehicleInfo } = this.state;
    let partyAction = new PartyAction();
    let { appraisalVehicle } = vehicleInfo.sourceObject;
    if (
      !appraisalVehicle.BodyShopName ||
      appraisalVehicle.BodyShopName == ''
    ) {
      MessageHelper.warning(
        i18n.ClaimAppraisal.WarnMsg.Warn_0005,
        i18n.ClaimAppraisal.WarnMsg.Warn_0006,
        MessageHelper.POSITION_TOP_RIGHT
      );
      return;
    }
    if (partyAddress) {
      appraisalVehicle.BodyShopAddress = partyAction.buildAddress(
        partyAddress
      );
    }
    this.setState({
      vehicleInfo,
    });
  },

  getGarage(garage) {
    let { vehicleInfo } = this.state;
    let { appraisalVehicle } = vehicleInfo.sourceObject;
    let { appraisalVehicleParty } = this.state;
    let { mobileDisabled } = this.state;
    if (garage != null || garage != undefined) {
      appraisalVehicle.BodyShopName = garage.PartyName;
      appraisalVehicle.BodyShopTel = garage.MobileTel;
      appraisalVehicle.GaragePartyId = garage.PartyId;
      if (garage.MobileTel === undefined || garage.MobileTel === '') {
        mobileDisabled = false;
      }
      appraisalVehicleParty.PartyId = garage.PartyId;
      let partyAction = new PartyAction();
      let party = partyAction.loadPartyByPartyId(garage.PartyId);
      if (party.AddressVoList) {
        _.each(party.AddressVoList, function (obj) {
          if (obj.IsPrimaryAddress === 'Y') {
            if (obj.Country == undefined) {
              obj.Country = garage.Country;
            }
            appraisalVehicle.BodyShopAddress = partyAction.buildAddress(
              obj
            );
            return obj;
          }
        });
      }
    }
    this.setState({
      vehicleInfo,
      appraisalVehicleParty,
      mobileDisabled,
    });
  },

  render() {
    let io = this.getIOStyle();
    let enabled = this.getEnabled();
    console.log('[enabled]', enabled);
    let insuredList = this.props.policyInfo.InsuredList;
    let insuredObject;
    _.each(insuredList, function (obj) {
      if (obj.Maker) {
        insuredObject = obj;
        return obj;
      }
    });
    let vehicleInfo = this.props.appraVehicleInfo;
    let VehicleInfo = this.props.appraVehicleInfo.sourceObject
      .appraisalVehicle;
    if (this.props.isFirstParty === true) {
      if (insuredObject) {
        if (VehicleInfo) {
          if (!VehicleInfo.MakeYear) {
            VehicleInfo.Model = insuredObject.Model;
            VehicleInfo.Maker = insuredObject.Maker;
            VehicleInfo.ModelYear = insuredObject.RegistrationDt;
            VehicleInfo.MakeYear = insuredObject.MakerYear;
            VehicleInfo.PlateNo = insuredObject.PlateNo;
            VehicleInfo.Vin = insuredObject.VinNo;
          }
        }
      }
    }
    let style;
    if (this.props.history) {
      style = 'default';
    } else {
      style = 'primary';
    }
    let modelList = AppraisalVehicleAction.getModelCodeTable(
      VehicleInfo.Maker
    );
    let { mobileDisabled } = this.state;
    return (
      <div>
        <PtyIntegrationSearchDialog
          ref="partySearchDialogForGarage"
          id="garage"
          getSearchResult={this.getGarage}
          RoleName={PartyConst.PARTY_ROLE_NAME_GARAGE}
          isVisibled={false}
        />
        {/* <PartySearchPanelDialog ref="PartySearchPanelDialog_ref" parentComponent={this} */}
        {/* endId="_appraisal_vehicle"/> */}
        <PtyIntegrationAddress
          ref="PtyIntegrationAddress_ref"
          endId="_appraisal_vehicle"
          callBackGetRecord={this.callBackGetRecord}
        />
        <UIPanel
          panelTitle={i18n.ClaimAppraisal.VehicleInfo}
          styleClass={style}
        >
          <UISmartPanelGrid column="2" widthAllocation="4,8">
            <UIText
              id="appraPlateNo"
              label={i18n.ClaimAppraisal.PlateNo}
              io={io}
              required="true"
              validationGroup="appraisalSubmit"
              valueLink={this.bindTo(vehicleInfo, 'PlateNo')}
              onChange={this.registrationNoVehicleValidate.bind(this)}
            />
            <UIText
              id="appraVIN"
              label={i18n.ClaimAppraisal.VIN}
              io={io}
              enabled={enabled}
              required="true"
              validationGroup="appraisalSubmit"
              valueLink={this.bindTo(vehicleInfo, 'Vin')}
              minLength="6"
              maxLength="17"
            />
            <UISelect
              id="appraMaker"
              label={i18n.ClaimAppraisal.Maker}
              io={io}
              required="true"
              validationGroup="appraisalSubmit"
              enabled={enabled}
              model={VehicleInfo}
              property="Maker"
              codeTable={this.state.makerList}
              onChange={this.getModelCodeTable.bind(this)}
            />
            <UISelect
              id="appraModel"
              label={i18n.ClaimAppraisal.Model}
              io={io}
              required="true"
              validationGroup="appraisalSubmit"
              enabled={enabled}
              model={VehicleInfo}
              property="Model"
              codeTable={modelList}
            />
            <UIDateTimePicker
              id="moduleyear"
              label={i18n.ClaimAppraisal.ModelYear}
              io={io}
              required="true"
              validationGroup="appraisalSubmit"
              enabled={enabled}
              format="MM/DD/YYYY"
              maxDate="TODAY"
              valueLink={this.bindTo(vehicleInfo, 'ModelYear')}
            />
            <UIDateTimePicker
              id="makeyear"
              label={i18n.ClaimAppraisal.MakeYear}
              io={io}
              required="true"
              validationGroup="appraisalSubmit"
              enabled={enabled}
              format="YYYY"
              maxDate="TODAY"
              placeHolder="YYYY"
              valueLink={this.bindTo(vehicleInfo, 'MakeYear')}
            />
            <UIText
              id="bodyshopName"
              label={i18n.ClaimAppraisal.BodyShopName}
              io={io}
              suffixIcon="fa fa-search"
              onSuffixIconClick={this.showPartySearchPanelDialog}
              valueLink={this.bindTo(vehicleInfo, 'BodyShopName')}
              disabled="true"
            />
            <UIText
              id="bodyshopTel"
              label={i18n.ClaimAppraisal.BodyShopTel}
              io={io}
              valueLink={this.bindTo(vehicleInfo, 'BodyShopTel')}
              disabled={mobileDisabled}
            />
            <UIText
              id="bodyshopAddress"
              label={i18n.ClaimAppraisal.BodyShopAddress}
              colspan="2"
              io={io}
              valueLink={this.bindTo(vehicleInfo, 'BodyShopAddress')}
              suffixIcon="fa fa-edit"
              disabled="true"
              onSuffixIconClick={this.showPartyAddressDialog.bind(
                this
              )}
            />
            <UICheckbox
              id="appraDamagePart"
              label={i18n.ClaimAppraisal.DamagePart}
              codeTable={CodeTableAction.loadDataList(
                CodeTableCstUI.ClaimDamagePart,
                null
              )}
              colspan="2"
              valueLink={this.bindToState(
                'appraisalVehicleInfo',
                'DamagePart'
              )}
              onChange={this.selectDamagePart}
            />
          </UISmartPanelGrid>
        </UIPanel>
      </div>
    );
  },
});
export default AppraisalVehicleInfo;
