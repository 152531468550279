export default {
  ProductLineCode: 'Product Line Code',
  ProductLineName: 'Product Line Name',
  ProductLineDescription: 'Product Line Description',
  ProductCode: 'Product Code',
  ProductName: 'Product Name',
  ProductDescription: 'Product Description',
  ExtProductCode: 'Policy Sys Code',
  ProductTypeCode: 'Product Type Code',
  ProductTypeName: 'Product Type Name',
  PartyRole: 'Party Role',
  ProductTypeDescription: 'Product Type Descripton',
  ExtProductTypeCode: 'Policy Sys Code',
  OptionalCoverageCategories: 'Optional Coverage Categories',
  ApplicableCoverageCategories: 'Applicable Coverage Categories',
  CoverageCode: 'Coverage Code',
  CoverageName: 'Coverage Name',
  CoverageDescription: 'Coverage Description',
  SettlementTypeList: 'Settlement Type List',
  SortNo: 'Sort No.',
  ExtCoverageCode: 'Policy Sys Code',
  ExtCoverageTypeCode: 'Product Cover Type Code',
  SumInsuredEditable: 'Sum Insured Editable',
  DefaultCoverage: 'Default Coverage',
  AvailableClaimingCoverage: 'Available Claiming Coverage',

  ReserveTypeCode: 'Reserve Type Code',
  ReserveTypeName: 'Reserve Type Name',
  ReserveTypeDescription: 'Reserve Type Description',
  ClaimFileType: 'Claim File Type',
  MultiLanguage: 'Multi-Language',
  Positive: 'Positive',
  SettlementTypeCode: 'Settlement Type Code',
  SettlementTypeName: 'Settlement Type Name',
  Description: 'Description',
  SettlementTypeManagement: 'Settlement Type Management',
  CoverageCategoryCode: 'Coverage Category Code',
  CoverageCategoryName: 'Coverage Category Name',
  CoverageCategoryDescription: 'Coverage Category Description',
  CauseofLossCode: 'Cause of Loss Code',
  CauseofLossName: 'Cause of Loss',
  CauseofLossDescription: 'Cause of Loss Description',
  SubclaimTypeCode: 'Subclaim Type Code',
  SubclaimTypeName: 'Subclaim Type Name',
  FraudSubjectCode: 'Fraud Subject Code',
  SubclaimTypeDescription: 'Description',
  SubclaimTypeManagement: 'Subclaim Type Management',
  IsUnique: 'IsUnique',
  IsInsuredObject: 'Constraint to First Party',
  // relation
  DamageTypeList: 'Damage Type List',
  OptionalDamageTypes: 'Optional Damage Types',
  ApplicableDamageTypes: 'Applicable Damage Types',
  SubClmTypeDescription: 'Subclaim Type Description',
  CoverageCategoryList: 'Coverage Category List',
  ListofReserveType: 'List of Reserve Type',
  OptionalReserveTypes: 'Optional Reserve Types',
  ApplicableReserveTypes: 'Applicable Reserve Types',
  OptionalSettlementTypes: 'Optional Settlement Types',
  ApplicableSettlementTypes: 'Applicable Settlement Types',
  OptionalSubclaimTypes: 'Optional Subclaim Types',
  ApplicableSubclaimTypes: 'Applicable Subclaim Types',
  PolicySysCode: 'Policy Sys Code',

  Coverage: 'Coverage',
  CoverageType: 'CoverageType',
  ReserveType: 'ReserveType',
  DefaultReserve: 'Default Reserve',
  CoverageCategory: 'Coverage Category',
  SettlementType: 'SettlementType',
  SubclaimType: 'SubclaimType',
  CauseOfLoss: 'Cause of Loss',
  ProductType: 'ProductType',
  Product: 'Product',
  ProductCoverageCategory: 'Product CoverageCategory',
  ProductLine: 'ProductLine',
  ProductTree: 'Product Tree',
  CoverageDetail: 'Coverage Detail',
  Warn: 'Warning',
  ConfirmMessage: 'Are you sure to remove ?',

  // 按钮
  Remove: 'Remove',
  Action: 'Action',
  Save: 'SAVE',
  Cancel: 'CANCEL',
  Search: 'SEARCH',
  New: 'NEW',
  Exit: 'EXIT',

  // Message
  removeProductFailMessage:
    'Current parameter is involved in following relationship. Please make sure relationships are all cleared before you deleting it.',
  SaveSuccess: 'Save Success!',
  DeleteSuccess: 'Remove Success',
  DeleteFail: 'Remove Fail',
  IsExist: 'is Existed',

  InfoMsg: {
    Save: 'Save',
    PleaseModifyItems: 'Please Modify items',
  },
  ErrorMsg: {
    SaveFAIL: 'Save FAIL ',
  },

  ConfirmMsg: {},
  WarnMsg: {},
  SuccessMsg: {},
};
