export default {
  CodeTableCache: {
    CacheList: [],
    get(cacheId) {
      let result = null;
      this.CacheList.forEach((data, index) => {
        if (data.CacheId == cacheId) {
          result = data.CodeTable;
        }
      });
      return result;
    },
    put(cacheId, codeTable) {
      if (!this.get(cacheId)) {
        this.CacheList.push({
          CacheId: cacheId,
          CodeTable: codeTable,
        });
      }
    },
    clearCache(cacheId) {
      if (this.get(cacheId)) {
        this.CacheList.push({ CacheId: cacheId, CodeTable: null });
      }
    },
    clearCacheAll() {
      [];
    },
  },

  ComponentCache: {
    ComponentList: [],
    get(codetableId) {
      const result = new Array();
      this.ComponentList.forEach((data, index) => {
        if (data.CodeTableId == codetableId) {
          result.push(data.ComponentId);
        }
      });
      return result;
    },
    put(codeTableId, componentId) {
      this.ComponentList.push({
        CodeTableId: codeTableId,
        ComponentId: componentId,
      });
    },
    remove(codeTableId) {
      const newComponent = new Array();
      if (this.get(codeTableId)) {
        for (let i = 0; i < this.ComponentList.length; i += 1) {
          if (this.ComponentList[i].CodeTableId !== codeTableId) {
            newComponent.push(this.ComponentList[i]);
          }
        }
        this.ComponentList = newComponent;
      }
    },
    clearCacheAll() {
      [];
    },
  },
};
