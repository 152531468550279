import {
  UISelect,
  UIRadio,
  UIText,
  UISmartPanelGrid,
  UICell,
  UIUpdatePanel,
} from 'RainbowUI';
import { CodeTableAction, CodeTableCstUI } from 'ClaimCommonConstant';
import React from 'react';
import BindToMixin from 'react-binding';

const ClaimPoliceInfo = React.createClass({
  mixins: [BindToMixin],
  getInitialState() {
    return {
      caseEntity: this.props.caseEntity,
      IsPoliceReport: false,
    };
  },

  render() {
    const { caseEntity } = this.props;
    const { IsPoliceReport } = this.state;
    const _self = this;
    let policeInfoIsRequired = false;
    const validationGroup = 'RegistrationInfo';
    policeInfoIsRequired = IsPoliceReport && caseEntity.IsMotor;
    let ioflag = 'in';
    caseEntity.IsMotor = caseEntity.IsMotor == true;
    if (_self.props.ioflag) {
      ioflag = _self.props.ioflag;
    } else {
      ioflag = 'in';
    }
    return (
      <UIUpdatePanel id="policeInformation" colspan="3">
        <UISmartPanelGrid column="3" widthAllocation="5,7">
          <UIRadio
            id="isFraudStatus"
            label={i18n.ClaimCommon.FraudStatus}
            validationGroup={validationGroup}
            model={caseEntity}
            property="IsFraudStatus"
            codeTable={CodeTableAction.loadDataList(
              CodeTableCstUI.PlateFormYesNoYN,
              null
            )}
            visibled={caseEntity.IsMotor}
            io={ioflag}
          />
          <UIRadio
            id="isPoliceReport"
            label={i18n.ClaimCommon.PoliceReport}
            validationGroup={validationGroup}
            model={caseEntity}
            property="IsPoliceReport"
            onChange={this.onChangeIsPoliceReport}
            codeTable={CodeTableAction.loadDataList(
              CodeTableCstUI.PlateFormYesNoYN,
              null
            )}
            visibled={caseEntity.IsMotor}
            io={ioflag}
          />
          <UISelect
            label={i18n.ClaimCommon.PoliceStation}
            validationGroup={validationGroup}
            model={caseEntity}
            property="PoliceStation"
            codeTable={CodeTableAction.loadDataList(76310707, null)}
            required={policeInfoIsRequired}
            visibled={caseEntity.IsMotor}
            io={ioflag}
          />

          <UIText
            label={i18n.ClaimCommon.Officer1}
            validationGroup={validationGroup}
            model={caseEntity}
            property="Officer1"
            required={policeInfoIsRequired}
            visibled={caseEntity.IsMotor}
            io={ioflag}
          />

          <UIText
            label={i18n.ClaimCommon.Badge}
            validationGroup={validationGroup}
            model={caseEntity}
            property="Badge1"
            required={policeInfoIsRequired}
            visibled={caseEntity.IsMotor}
            io={ioflag}
          />
          <UICell />
          <UIText
            label={i18n.ClaimCommon.Officer2}
            validationGroup={validationGroup}
            model={caseEntity}
            property="Officer2"
            required={policeInfoIsRequired}
            visibled={caseEntity.IsMotor}
            io={ioflag}
          />
          <UIText
            label={i18n.ClaimCommon.Badge}
            validationGroup={validationGroup}
            model={caseEntity}
            property="Badge2"
            required={policeInfoIsRequired}
            visibled={caseEntity.IsMotor}
            io={ioflag}
          />
          <UICell />
        </UISmartPanelGrid>
      </UIUpdatePanel>
    );
  },

  onChangeIsPoliceReport(event) {
    if (event.newValue === 'Y') {
      this.state.IsPoliceReport = true;
      this.props.onChangePoliceInfoIsRequired(true);
    } else {
      this.state.IsPoliceReport = false;
      this.props.onChangePoliceInfoIsRequired(false);
      this.clearPoliceReportInfo();
    }

    this.forceUpdate('policeInformation');
  },

  clearPoliceReportInfo() {
    const { caseEntity } = this.state;
    delete caseEntity.PoliceStation;
    delete caseEntity.Officer1;
    delete caseEntity.Officer2;
    delete caseEntity.Badge1;
    delete caseEntity.Badge2;
    this.setState({ caseEntity });
  },
});
export default ClaimPoliceInfo;
