import { UITree, DataContext } from 'RainbowUI';
import ClaimAuthorityAction from '../../action/ClaimAuthorityAction';
import ClaimAuthorityUtilAction from '../../action/ClaimAuthorityUtilAction';

const ClaimAuthorityTree = React.createClass({
  getInitialState() {
    return {};
  },

  componentWillMount() {
  },

  initData() {
    return ClaimAuthorityAction.initData(this.props.permissionCode);
  },

  loadData(event, treeId, treeNode) {
    ClaimAuthorityAction.loadData(event, treeId, treeNode);
  },

  onChangeTreeNodeStatus(event, treeId, treeNode) {
    const path = treeNode.Path;
    const tree = $.fn.zTree.getZTreeObj('claimAuthorityId');
    const nodes = tree.getNodes();
    const id = this.getCheckNode(path, nodes);

    const node = tree.getNodeByParam('id', id);

    tree.selectNode(node);

    tree.expandNode(node, true);
  },

  getCheckNode(path, nodes) {
    let id = null;
    const children = nodes[0].ChirdrenList;

    if (path == nodes[0].Path) {
      id = nodes[0].id;
    } else {
      for (const item in children) {
        const vo = children[item];

        if (path == vo.Path) {
          id = vo.id;
        }
      }
    }

    return id;
  },

  render() {

    if (!this.props.permissionCode) {
      return null;
    }

    return (
      <UITree
        id="claimAuthorityId"
        dataSource={this.initData()}
        onClick={this.loadData}
        searchable="true"
        checkable="true"
      />
    );
  },
});
export default ClaimAuthorityTree;
