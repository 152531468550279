export default {
  Remark: '備考',
  Calculate: '計算',
  Calculation: '理算',
  CalculationPanelTitle: '評価詳細',
  AddItem: '追加',
  Selected: ' 已选',
  PleaseSelect: '请选择',

  SelectCoverage: '計算のため、保障を選択してください',
  TotalIndemnityAmount: '総保障額',
  TotalExpenseAmount: '総費用',
  NoItem: '計算科目を追加してください',

  AppraisalAmount: '見積金額',
  NumberofUnits: 'ユニット番号',
  CalculatedAmount: '評価金額',
  AdjustedAmount: '調整後の金額',
  LimitAndDeductionInfo: '限度および控除情報',

  SettledAmount: '決済額',
  ExpenseAmount: '費用',

  CalculationHistory: '評価履歴',
  TransactionDate: '処理日',
  IssuedBy: '発行先',
  Approver: '承認者',
  CalculationAmount: '評価金額',
  CalculationExpense: '費用',
  SeqNo: 'Seq No',
  CalculationHistoryDetail: '評価履歴詳細',

  CalculationSaveSuccess: '理算保存成功',
  CalculationSubmitSuccess: '理算提交成功',
  CalculationCalculateSuccess: '理算成功',
  CalculattionApprovalSubmitSuccess: '理算签核提交成功',
  AtleastOneItem: '至少添加一个子赔案',
  SameReserveTypeError: '你不能多次选相同子赔案中相同的准备金类型',
  IndemnityAmountError: '调整金额不能比结算金额少',
  ExpenseAmountError: '费用金额不能比结算金额少',
};
