import {
  UICell,
  UIDataTable,
  UISmartPanelGrid,
  UIColumn,
  UIUpdatePanel,
  UIText,
  UILink,
  UIParam,
} from 'RainbowUI';
import PropTypes from 'prop-types';

const ClmCoverageReservePaymentOfProductLineAction = require('../../action/ClmCoverageReservePaymentOfProductLineAction');

const SubCalimCoverageReservePaymentRelationProductLine = React.createClass(
  {
    propTypes: {
      entity: PropTypes.object,
    },
    getData(pageindex, pagesize) {
      const settlementTypeList = ClmCoverageReservePaymentOfProductLineAction.getClmPaymentRelation(
        pageindex,
        pagesize
      );
      return settlementTypeList;
    },
    render() {
      const _self = this;
      return (
        <div>
          <UIUpdatePanel id="settlementTypeListProvider">
            <UIDataTable
              indexable="true"
              id="settlementTypeList"
              provider={this.getData}
            >
              <UIColumn
                headerTitle={i18n.ClaimProduct.SubclaimType}
                render={data => {
                  const tempData = `${data.Object.SubclaimTypeCode}:${data.Object.SubclaimTypeName}`;
                  return (
                    <UILink
                      value={tempData}
                      onClick={
                        ClmCoverageReservePaymentOfProductLineAction.openSubclaimCoverageReservePaymentRelation
                      }
                    >
                      <UIParam
                        name="subclaimTypeCode"
                        value={data.Object.SubclaimTypeCode}
                      />
                      <UIParam
                        name="subclaimTypeName"
                        value={data.Object.SubclaimTypeName}
                      />
                      <UIParam
                        name="productLineCode"
                        value={data.Object.ProductLineCode}
                      />
                      <UIParam
                        name="coverageTypeCode"
                        value={data.Object1.CoverageTypeCode}
                      />
                      <UIParam
                        name="coverageTypeName"
                        value={data.Object1.CoverageTypeName}
                      />
                      <UIParam
                        name="reserveTypeCode"
                        value={data.Object2.ReserveTypeCode}
                      />
                      <UIParam
                        name="reserveTypeName"
                        value={data.Object2.ReserveTypeName}
                      />
                      <UIParam
                        name="nodeName"
                        value={_self.props.entity.name}
                      />
                    </UILink>
                  );
                }}
              />
              <UIColumn
                headerTitle={i18n.ClaimProduct.CoverageCategory}
                render={data => {
                  const tempData = `${data.Object1.CoverageTypeCode}:${data.Object1.CoverageTypeName}`;
                  return <UIText value={tempData} io="out" />;
                }}
              />
              <UIColumn
                headerTitle={i18n.ClaimProduct.ReserveType}
                render={data => {
                  const tempData = `${data.Object2.ReserveTypeCode}:${data.Object2.ReserveTypeName}`;
                  return <UIText value={tempData} io="out" />;
                }}
              />
              <UIColumn
                headerTitle={i18n.ClaimProduct.SettlementTypeList}
                render={data => {
                  return this.getDetail(data.SelectedList);
                }}
              />
            </UIDataTable>
          </UIUpdatePanel>
        </div>
      );
    },
    getDetail(changeDataList) {
      if (changeDataList) {
        const result = changeDataList.map(data => {
          return (
            <UIText
              value={`${data.Desc.PaymentTypeCode}:${data.Desc.PaymentTypeName}`}
              io="out"
            />
          );
        });

        return (
          <UISmartPanelGrid column="1">
            <UICell>{result}</UICell>
          </UISmartPanelGrid>
        );
      }
    },
  }
);
export default SubCalimCoverageReservePaymentRelationProductLine;
