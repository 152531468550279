import {
  UIBox,
  UISelect,
  UIDialog,
  UISmartPanelGrid,
  UICell,
  UIText,
  UIButton,
  MessageHelper,
} from 'RainbowUI';
import DefinedCodeTable from '../codetable/CodeTable';
import PartyCodeTableText from '../../common/component/PartyCodeTableText';
import PartyConst from '../const/PartyConst';

const EditRelationShipDialog = React.createClass({
  getInitialState() {
    return {
      partyRelationShip: {},
      readOnly: false,
      partySearchType: PartyConst.PARTY_TYPE_IND,
    };
  },

  onClickClose() {
    UIDialog.hide('RelationShipDialog');
  },

  onSave() {
    const { partyRelationShip } = this.state;
    if (!partyRelationShip.IdNumber) {
      MessageHelper.error(
        i18n.PartyMsg.ErrorMsg.Error_0010,
        '',
        MessageHelper.POSITION_TOP_RIGHT
      );
    } else {
      const result = this.props.submitHandler(partyRelationShip);
      if (result === 'fail') {
        /* empty */
      } else {
        UIDialog.hide('RelationShipDialog');
      }
    }
  },

  renderReadOnlyCodeTableText(partyRelationShip) {
    return (
      <PartyCodeTableText
        label={i18n.PartyName}
        model={partyRelationShip}
        property="RelationShipPartyId"
        disabled="true"
        condition="RelationShipPartyId"
        validationGroup="UpdateRelationShipInfo"
        required="true"
      />
    );
  },

  renderCodeTableText(partyRelationShip) {
    return (
      <PartyCodeTableText
        label={i18n.PartyName}
        model={partyRelationShip}
        property="RelationShipPartyId"
        validationGroup="UpdateRelationShipInfo"
        suffixIcon="fa fa-search"
        disabled="true"
        condition="RelationShipPartyId"
        onIconClick={this.showPartySearchDialog}
        required="true"
      />
    );
  },

  render() {
    const _self = this;
    const {
      partyRelationShip,
      readOnly,
      partySearchType,
    } = this.state;
    let codeTableText;
    let relationShipType;
    if (readOnly == true) {
      codeTableText = this.renderReadOnlyCodeTableText(
        partyRelationShip
      );
    } else {
      codeTableText = this.renderCodeTableText(partyRelationShip);
    }
    if (partySearchType == PartyConst.PARTY_TYPE_IND) {
      relationShipType = this.renderIndRelationShipType(
        partyRelationShip
      );
    } else {
      relationShipType = this.renderBothRelationShipType(
        partyRelationShip
      );
    }
    if (readOnly) {
      relationShipType = this.renderAllRelationShipType(
        partyRelationShip
      );
    }
    return (
      <UIDialog
        id="RelationShipDialog"
        width="80%"
        title={i18n.RelationshipDetail}
      >
        <UISmartPanelGrid column="3">
          {codeTableText}
          {relationShipType}
          <UIText
            label={i18n.IdOrCompanyRegNumber}
            disabled="true"
            model={partyRelationShip}
            property="IdNumber"
          />
          <UIText
            label={i18n.Remarks}
            model={partyRelationShip}
            property="Remark"
            colspan="2"
          />
          <UICell />
          <UISelect
            label={i18n.Status}
            codeTable={DefinedCodeTable.getPartyStatus}
            model={partyRelationShip}
            property="Status"
            showBlankOption="false"
          />
        </UISmartPanelGrid>
        <UIBox direction="center">
          <UIButton
            value={i18n.Save}
            onClick={_self.onSave}
            validationGroup="UpdateRelationShipInfo"
            causeValidation="true"
          />
          <UIButton
            value={i18n.Cancel}
            onClick={_self.onClickClose}
          />
        </UIBox>
      </UIDialog>
    );
  },

  showDialog(partyRelationShip, readOnly, partySearchType) {
    UIDialog.show('RelationShipDialog');
    this.setState(
      {
        partyRelationShip,
        readOnly,
        partySearchType,
      },
      () =>
        console.log('partyRelationShip', this.state.partyRelationShip)
    );
  },

  showPartySearchDialog() {
    this.props.searchPartyName(this.state.partyRelationShip);
  },
  renderIndRelationShipType(partyRelationShip) {
    const { readOnly } = this.state;
    return (
      <UISelect
        label={i18n.RelationshipType}
        codeTable={DefinedCodeTable.getIndRelationShipType}
        model={partyRelationShip}
        validationGroup="UpdateRelationShipInfo"
        required="true"
        property="RelationShipType"
        disabled={readOnly}
      />
    );
  },
  renderBothRelationShipType(partyRelationShip) {
    const { readOnly } = this.state;
    return (
      <UISelect
        label={i18n.RelationshipType}
        codeTable={DefinedCodeTable.getOrgToIndRelationShipType}
        model={partyRelationShip}
        validationGroup="UpdateRelationShipInfo"
        required="true"
        property="RelationShipType"
        disabled={readOnly}
      />
    );
  },
  renderAllRelationShipType(partyRelationShip) {
    const { readOnly } = this.state;
    return (
      <UISelect
        label={i18n.RelationshipType}
        codeTable={DefinedCodeTable.getAllRelationShipType}
        model={partyRelationShip}
        validationGroup="UpdateRelationShipInfo"
        required="true"
        property="RelationShipType"
        disabled={readOnly}
      />
    );
  },
});
export default EditRelationShipDialog;
