import {
  UIDialog,
  UIBox,
  UIButton,
  UIDataTable,
  MessageHelper,
} from 'RainbowUI';
import PartyAction from '../../actions/PartyAction';

const PtyIntegrationSearchPanelNew = require('../../../common/component/integration/PtyIntegrationSearchPanelNew');

class PartySearchPanelDialog extends React.Component {
  showDialog(arg) {
    this.dialog_p = arg;
    this.setState({ dialog_p: arg });
    UIDialog.show(this.id);
  }

  Continue(event, d) {
    const selectData = UIDataTable.getSelectedRecord('PartyRecord');
    let data;
    if (selectData.length > 0) {
      data = selectData[0];
      if (data.PartyRoleStatus && data.PartyRoleStatus == '2') {
        MessageHelper.warning(
          i18n.PartyMsg.WarnMsg.Warn_0004,
          '',
          MessageHelper.POSITION_TOP_RIGHT
        );
        return;
      }
    }
    if (d) {
      data = d;
    }
    if (!data) {
      MessageHelper.warning(
        i18n.PartyMsg.WarnMsg.Warn_0005,
        i18n.PartyMsg.WarnMsg.Warn_0003,
        MessageHelper.POSITION_TOP_RIGHT
      );
      return;
    }
    if (data.PartyRoleStatus && data.PartyRoleStatus == '2') {
      MessageHelper.warning(
        i18n.PartyMsg.WarnMsg.Warn_0004,
        '',
        MessageHelper.POSITION_TOP_RIGHT
      );
      return;
    }
    const partyAction = new PartyAction();
    const partyId = data.PartyId;
    let partyFullInfo = partyAction.loadPartyByPartyId(partyId);
    if (!partyFullInfo) {
      partyFullInfo = {};
    }

    let partyPrimaryAddress = {};
    if (
      partyFullInfo &&
      partyFullInfo.AddressVoList &&
      partyFullInfo.AddressVoList[0]
    ) {
      partyPrimaryAddress = partyFullInfo.AddressVoList[0];
    }

    let partyPrimaryAccount = {};
    if (
      partyFullInfo &&
      partyFullInfo.PartyAccountList &&
      partyFullInfo.PartyAccountList[0]
    ) {
      partyPrimaryAccount = partyFullInfo.PartyAccountList[0];
    }

    if (!data.PartyName) {
      data.PartyName = `${data.LastName} ${data.FirstName}`;
    }

    const partyRole = data.PartyType;
    let roleType = '';
    const self = this.state;
    if (partyRole == '1' && self && self.dialog_p) {
      roleType = self.indPartyRole;
    } else if (partyRole == '2' && this.dialog_p) {
      roleType = self.orgPartyRole;
    }
    const arr = [];
    arr[0] = data;
    if (this.doContinue) {
      this.doContinue(
        {
          partyInfo: arr,
          partyPrimaryAddress,
          partyPrimaryAccount,
          props: this.dialog_p,
        },
        partyFullInfo,
        roleType
      );
      this.hideDialog();
    }
  }

  hideDialog() {
    const _self = this;
    UIDialog.hide(this.id);
    if (_self.props.onClose) {
      _self.props.onClose();
    }
  }

  render() {
    const _self = this;
    if (_self.props.endId)
      this.id = `PartySearchPanel_dialog${_self.props.endId}`;
    return (
      <div>
        <UIDialog
          id={this.id}
          width="90%"
          title={i18n.Party}
          onClose={this.hideDialog}
        >
          <PtyIntegrationSearchPanelNew
            ref="PartySearchPanel_ref"
            dialog={this}
          />
          <UIBox direction="center">
            <UIButton value={i18n.OK} onClick={this.Continue.bind(this)} />
          </UIBox>
        </UIDialog>
      </div>
    );
  }
}

export default PartySearchPanelDialog;
