const SettlementInfoMock = require('./SettlementInfoMock');

export default {
  ClaimData: {
    CaseId: 1001,
    ClaimNo: 14000222,
    ReserveStatus: ['OPEN', 'null', 'null'],
    ObjectDatas: [
      {
        ObjectId: 11,
        Name: '001',
        ReserveStatus: ['OPEN', 'null', 'null'],
        IsActive: 1,
      },
      {
        ObjectId: 22,
        Name: '002',
        ReserveStatus: ['OPEN', 'null', 'null'],
      },
    ],
    HandlingLinks: [
      {
        name: 'Policy Information',
        params: [{ name: 'policyId', value: '-1' }],
      },
    ],

    warningMsgs: [
      'This policy had other claim(s).',
      'Policy premium is not collected.',
    ],
    claimLinks: [
      {
        name: i18n.ClaimCommon.ModifyClaimInfo,
        params: [{ name: 'caseId', value: '-1' }],
      },
      {
        name: i18n.ClaimCommon.ValidationInfo,
        params: [{ name: 'caseId', value: '-1' }],
      },
    ],
    subclaimLinks: [
      {
        name: i18n.ClaimCommon.ModifySublaimInfo,
        params: [{ name: 'objectId', value: '-1' }],
      },
    ],
    subclaimTabs: [
      {
        title: i18n.ClaimCommon.Settlement,
        IsActive: 1,
        content: <SettlementInfoMock />,
      },
    ],
  },
  ClaimEntity: {
    AccidentCode: '0000',
    AccidentDesc: 'Car Collision',
    AccidentDistrict: 1,
    AccidentPlace: 'Beijing',
    AccidentTime: '2015-11-25T09:03:34',
    AgentTel: 189175696758,
    BusinessObjectId: 517748,
    CallerName: 'Alfonso Wang',
    CallerPhone: 68888888888,
    CallerType: 2,
    CaseId: 1468693,
    CaseOrganId: 1,
    ClaimNo: 15000005,
    ContactName: 'Alfonso Wang',
    ContactPhone: 68888888888,
    ContactType: 2,
    FnolType: 1,
    InsureOrganId: 1,
    InsuredLiability: 1,
    InsuredName: '1000 1000',
    LossCause: 2,
    NoticeTime: '2015-09-22T19:47:28',
    PolicyNo: 'Q15123400031676',
    ProcessOrganId: 24,
    ProductCode: 198,
    RegisterTime: '2015-11-23T19:47:28',
    ValidationDecision: 1,
    ValidationRemark: 'Accept',
    PolicyholderName: 'Alfonso Wang',

    ObjectList: [
      {
        SubclaimType: '1_ST02',
        InsuredObject: '1',
        IsSubrogation: '2',
        IsSalvage: '2',
        RiskName: 'Vehicle',
        FirstName: 'Wang',
        LastName: 'Alfonso',
        ContactTel: '68888888888',
        Country: 'China',
        Litigation: '2',
        TotalLossFlag: '2',
        PlaceOfInspection: '1',
        PolicyCoverageList: [
          {
            Selected: '1',
            UpperCoverageName: 'Comprehensive',
            CoverageName: '',
            PolicySumInsured: 1000000,
            PolicyDeductibleRate: 0.0,
            PolicyDeductibleAmount: 0.0,
            InitLossIndemnity: 1050.0,
          },
          {
            Selected: '',
            UpperCoverageName: 'Comprehensive',
            CoverageName: 'Third Party Bodily Injury',
            PolicySumInsured: 1000000,
            PolicyDeductibleRate: 0.0,
            PolicyDeductibleAmount: 0.0,
            InitLossIndemnity: 2050.0,
          },
          {
            Selected: '',
            UpperCoverageName: 'Comprehensive',
            CoverageName: 'Third Party Property Damage',
            PolicySumInsured: 1000000,
            PolicyDeductibleRate: 0.0,
            PolicyDeductibleAmount: 0.0,
            InitLossIndemnity: 3050.0,
          },
          {
            Selected: '1',
            UpperCoverageName: 'Comprehensive',
            CoverageName: 'Own Damage/Theft',
            PolicySumInsured: 1000000,
            PolicyDeductibleRate: 0.0,
            PolicyDeductibleAmount: 0.0,
            InitLossIndemnity: 4050.0,
          },
        ],
      },
      {
        SubclaimType: '1_ST02',
        InsuredObject: '1',
        IsSubrogation: '2',
        IsSalvage: '2',
        RiskName: 'Vehicle',
        FirstName: 'Wang',
        LastName: 'Alfonso',
        ContactTel: '68888888888',
        Country: 'China',
        Litigation: '2',
        TotalLossFlag: '2',
        PlaceOfInspection: '1',
        PolicyCoverageList: [
          {
            Selected: '',
            UpperCoverageName: 'Comprehensive',
            CoverageName: '',
            PolicySumInsured: 2000000,
            PolicyDeductibleRate: 20.0,
            PolicyDeductibleAmount: 20.0,
            InitLossIndemnity: 221050.0,
          },
          {
            Selected: '1',
            UpperCoverageName: 'Comprehensive',
            CoverageName: 'Third Party Bodily Injury',
            PolicySumInsured: 2000000,
            PolicyDeductibleRate: 20.0,
            PolicyDeductibleAmount: 0.0,
            InitLossIndemnity: 222050.0,
          },
          {
            Selected: '1',
            UpperCoverageName: 'Comprehensive',
            CoverageName: 'Third Party Property Damage',
            PolicySumInsured: 2000000,
            PolicyDeductibleRate: 20.0,
            PolicyDeductibleAmount: 0.0,
            InitLossIndemnity: 223050.0,
          },
          {
            Selected: '',
            UpperCoverageName: 'Comprehensive',
            CoverageName: 'Own Damage/Theft',
            PolicySumInsured: 2000000,
            PolicyDeductibleRate: 20.0,
            PolicyDeductibleAmount: 0.0,
            InitLossIndemnity: 224050.0,
          },
        ],
      },
    ],
  },
};
