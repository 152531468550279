import { CodeTable } from 'RainbowUI';

export default {
  damagePart: new CodeTable([
    { id: '1', text: 'Ahead' },
    { id: '2', text: 'Front Left' },
    { id: '3', text: 'Front Right' },
    { id: '4', text: 'Passenger Left' },
    { id: '5', text: 'Passenger Right' },
    { id: '6', text: 'Rear' },
  ]),

  damageType: new CodeTable([
    { id: 'I', text: 'Body Injury' },
    { id: 'P', text: 'Propery' },
    { id: 'V', text: 'Vehicle' },
  ]),

  decisionType: new CodeTable([
    { id: 'Y', text: 'Approve' },
    { id: 'N', text: 'Reject' },
  ]),

  totalSparePartCost: new CodeTable([
    { id: '1', text: 'USD    500' },
    { id: '2', text: 'USD    500' },
    { id: '3', text: 'USD    500' },
  ]),

  lossItems: new CodeTable([
    { id: '1', text: 'Medical Expense' },
    { id: '2', text: 'Follow-up Medical Expense' },
    { id: '3', text: 'Nursing Cost' },
    { id: '4', text: 'Delay Compensation' },
    { id: '5', text: 'Hospital Food Subsidies' },
    { id: '6', text: 'Nutrition Fee' },
    { id: '7', text: 'Disability Compensation' },
    { id: '8', text: 'Equipment of Aid for Disability' },
    { id: '9', text: 'Death Compensation' },
    {
      id: '10',
      text:
        'Mental Injury (Solarium – compensation for emotional harm)',
    },
  ]),

  vehicleColor: new CodeTable([
    { id: '01', text: 'White' },
    { id: '02', text: 'Black' },
    { id: '03', text: 'Silvery' },
    { id: '04', text: 'Red' },
    { id: '05', text: 'Pink' },
    { id: '06', text: 'Orange' },
    { id: '07', text: 'Yellow' },
    { id: '08', text: 'Green' },
    { id: '09', text: 'Blue' },
    { id: '10', text: 'Purple' },
  ]),

  vehicleType: new CodeTable([
    { id: '1', text: '六座以下客车' },
    { id: '2', text: '六座至十座客车' },
    { id: '3', text: '十座至二十座以下客车' },
    { id: '4', text: '二十座至三十六座客车' },
    { id: '5', text: '三十六座以上客车' },
    { id: '6', text: '二吨以下货车' },
    { id: '7', text: '二吨至五吨货车' },
    { id: '8', text: '五吨至十吨货车' },
    { id: '9', text: '十吨以上货车' },
    { id: '10', text: '低速载货汽车' },
    { id: '11', text: '二吨以下挂车' },
    { id: '12', text: '二吨至五吨挂车' },
    { id: '13', text: '五吨至十吨挂车' },
    { id: '14', text: '十吨以上挂车' },
    { id: '15', text: '特种车一' },
    { id: '16', text: '特种车一挂车' },
    { id: '17', text: '特种车二' },
    { id: '18', text: '特种车二挂车' },
    { id: '19', text: '装置有冷藏、保温等设备的挂车' },
    { id: '20', text: '特种车三' },
    { id: '21', text: '特种车三挂车' },
    { id: '22', text: '特种车四' },
    { id: '23', text: '摩托车（50cc及以下' },
    { id: '24', text: '摩托车（50cc-250cc' },
    { id: '25', text: '摩托车（250cc以上及侧三轮' },
    { id: '26', text: '拖拉机（14.7kw及以下' },
    { id: '27', text: '拖拉机14.7kw以上' },
    { id: '28', text: '运输型拖拉机14.7KW及以下' },
    { id: '29', text: '运输型拖拉机14.7KW以上' },
  ]),
};
