import {
  UISelect,
  UIDataTable,
  UIColumn,
  UILink,
  UICurrency,
  UIParam,
  UIText,
  UIDateTimePicker,
  UIBox,
} from 'RainbowUI';
import {
  CodeTableAction,
  CodeTableCstUI,
  ClaimCstUI,
} from 'ClaimCommonConstant';
import PropTypes from 'prop-types';
import SettlementHistoryStore from '../store/SettlementHistoryStore';
import SettlementHistoryActionNoDispatch from '../action/SettlementHistoryActionNoDispatch';

const SettlementHistoryAction = require('../action/SettlementHistoryAction');
const SettlementLoadAction = require('../action/SettlementLoadAction');
const SettlementReadOnlyDialog = require('./SettlementReadOnlyDialog');
const RecoveryReadOnlyDialog = require('../../recovery/component/RecoveryReadOnlyDialog');
const SettlementCancelDialog = require('./SettlementCancelDialog');
const SettlementDetailDialog = require('./SettlementDetailDialog');
const RefundReadOnlyDialog = require('../../refund/component/RefundReadOnlyDialog');

const settlementHistoryActionNoDispatch = new SettlementHistoryActionNoDispatch();

const SettlementHistory = React.createClass({
  propTypes: {
    caseId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  },

  getInitialState() {
    return { historyList: {}, coInsurance: {} };
  },

  componentWillMount() {
    SettlementHistoryStore.listen(this.onStoreStateChange);
    if (this.props.canInit) {
      SettlementHistoryAction.initData(
        this.props.caseId,
        this.props.taskCode
      );
    }
    const coInsurance1 = SettlementLoadAction.loadCoInsuranceByCaseIdPolicyNo(
      this.props.caseId,
      this.props.policyNo
    );
    this.setState({ coInsurance: coInsurance1 });
  },
  componentWillUnmount() {
    SettlementHistoryStore.unlisten(this.onStoreStateChange);
  },

  onStoreStateChange(storeState) {
    this.setState({ historyList: storeState.historyList });
  },
  getReadOnlyDialog() {
    const componentId = this.props.componentId || '';
    const { taskCode } = this.props;
    if (
      taskCode == ClaimCstUI.CLAIM_TASK_CODE.Recovery ||
      taskCode == ClaimCstUI.CLAIM_TASK_CODE.RecoveryApproval
    ) {
      return <RecoveryReadOnlyDialog componentId={componentId} />;
    }
    if (
      taskCode == ClaimCstUI.CLAIM_TASK_CODE.Settlement ||
      taskCode == ClaimCstUI.CLAIM_TASK_CODE.SettlementApproval
    ) {
      return <SettlementReadOnlyDialog componentId={componentId} />;
    }
    if (
      taskCode == ClaimCstUI.CLAIM_TASK_CODE.Refund ||
      taskCode == ClaimCstUI.CLAIM_TASK_CODE.RefundApproval
    ) {
      return <RefundReadOnlyDialog componentId={componentId} />;
    }
  },
  getCancelDialog() {
    const { componentId, taskCode, caseId } = this.props;
    const ComponentId = componentId || '';
    return (
      <SettlementCancelDialog
        callback={SettlementHistoryAction.cancelSubmit}
        ref="SettlementCancelDialog"
        componentId={ComponentId}
        taskCode={taskCode}
        caseId={caseId}
      />
    );
  },
  getSettlementDetailAvailable() {
    const { componentId, taskCode, caseId } = this.props;
    const ComponentId = componentId || '';
    return (
      <SettlementDetailDialog
        ref="SettlementDetailDialog"
        componentId={ComponentId}
        taskCode={taskCode}
        caseId={caseId}
      />
    );
  },
  showSettlementDetail(event) {
    SettlementHistoryAction.onShow(event);
  },
  getSuperComponent() {
    return this;
  },
  render() {
    const _self = this;
    const componentId = _self.props.componentId || '';
    const { taskCode } = _self.props;
    let columnNo;
    let columnAmount;
    let headerName;
    let detailsLink;
    if (
      taskCode == ClaimCstUI.CLAIM_TASK_CODE.Refund ||
      taskCode == ClaimCstUI.CLAIM_TASK_CODE.RefundApproval
    ) {
      columnNo = i18n.ClaimSettlement.RefundNo;
      columnAmount = i18n.ClaimSettlement.RefundAmount;
      headerName = i18n.ClaimSettlement.RefundHistory;
      detailsLink = i18n.ClaimSettlement.RefundDetail;
    } else if (
      taskCode == ClaimCstUI.CLAIM_TASK_CODE.Recovery ||
      taskCode == ClaimCstUI.CLAIM_TASK_CODE.RecoveryApproval
    ) {
      columnNo = i18n.ClaimSettlement.RecoveryNo;
      columnAmount = i18n.ClaimSettlement.RecoveryAmount;
      headerName = i18n.ClaimSettlement.RecoveryHistory;
      detailsLink = i18n.ClaimSettlement.SettlementDetail;
    } else if (
      taskCode == ClaimCstUI.CLAIM_TASK_CODE.Settlement ||
      taskCode == ClaimCstUI.CLAIM_TASK_CODE.SettlementApproval
    ) {
      columnNo = i18n.ClaimSettlement.SettlementNo;
      columnAmount = i18n.ClaimSettlement.SettlementAmount;
      headerName = i18n.ClaimSettlement.SettlementHistory;
      detailsLink = i18n.ClaimSettlement.SettlementDetail;
    }
    return (
      <div>
        <UIDataTable
          id={`settlementHistoryUpdatePanel${componentId}`}
          value={_self.state.historyList}
          width="auto"
          indexable="false"
          infoable="true"
          isHeading="true"
          pageable="false"
          headerTitle={headerName}
        >
          <UIColumn
            headerTitle={columnNo}
            value="SettlementNo"
            render={data => {
              if (data.SettlementNo && !data.hasParent) {
                return (
                  <UILink
                    value={data.SettlementNo}
                    onClick={_self.showSettlementDetail}
                  >
                    <UIParam name="settleid" value={data.SettleId} />
                    <UIParam name="componentId" value={componentId} />
                  </UILink>
                );
              }
              return '';
            }}
          />
          <UIColumn
            headerTitle={i18n.ClaimSettlement.Name}
            value="PayeeName"
            render={data => {
              return <UIText io="out" value={data.PayeeName} />;
            }}
          />
          <UIColumn
            headerTitle={i18n.ClaimSettlement.TransactionDate}
            value="SubmitDate"
            render={data => {
              let transactionDate = data.ApproveDate;
              if (!transactionDate) {
                transactionDate = data.SubmitDate;
              }
              return (
                <UIDateTimePicker
                  value={transactionDate}
                  placeHolder="MM/DD/YYYY HH:mm:ss"
                  format="MM/DD/YYYY HH:mm:ss"
                  io="out"
                />
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.ClaimSettlement.IssuedBy}
            value="Submiter"
            render={data => {
              return (
                <UISelect
                  value={data.Submiter}
                  codeTable={CodeTableAction.loadDataList(
                    CodeTableCstUI.ClaimUser,
                    null
                  )}
                  io="out"
                />
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.ClaimSettlement.ApprovedBy}
            value="Approver"
            render={data => {
              return (
                <UISelect
                  value={data.Approver}
                  codeTable={CodeTableAction.loadDataList(
                    CodeTableCstUI.ClaimUser,
                    null
                  )}
                  io="out"
                />
              );
            }}
          />
          <UIColumn
            headerTitle={columnAmount}
            value="SettleAmount"
            render={data => {
              return (
                <div style={{ float: 'left' }}>
                  <UICurrency
                    value={data.SettleAmount}
                    unit={window.EU00.getCurrencySignByCurrencyCode(
                      data.SettleCurrency
                    )}
                    io="out"
                  />
                </div>
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.ClaimSettlement.Status}
            value="PaymentStatus"
            render={data => {
              return (
                <UISelect
                  value={data.PaymentStatus}
                  codeTable={CodeTableAction.loadDataList(
                    CodeTableCstUI.ClaimPaymentStatus,
                    null
                  )}
                  io="out"
                />
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.ClaimCommon.Action}
            value="cancel"
            render={data => {
              let settlementDetailAvailable = false;
              const payMode = settlementHistoryActionNoDispatch.getPayModeBySettlePayeeId(
                data.SettlePayeeId
              );
              if (data.PaymentStatus == ClaimCstUI.Paid) {
                settlementDetailAvailable = true;
              }

              if (
                data.PaymentStatus == ClaimCstUI.Cancel ||
                data.Submiter == -11
              ) {
                return <span />;
              }
              return (
                <div>
                  <UIBox direction="left">
                    <UILink
                      value={i18n.ClaimSettlement.Cancel}
                      onClick={_self.onCancelShow}
                      disabled={!data.AllowCancel}
                      style={
                        !data.AllowCancel ? { color: 'grey' } : {}
                      }
                    >
                      <UIParam
                        name="settlePayeeId"
                        value={data.SettlePayeeId}
                      />
                    </UILink>

                    <UILink
                      value={detailsLink}
                      onClick={_self.onSettlementDetailAvailable}
                      disabled={!settlementDetailAvailable}
                      style={
                        settlementDetailAvailable
                          ? { color: 'blue' }
                          : { color: 'grey' }
                      }
                    >
                      <UIParam
                        name="settlePayeeId"
                        value={data.SettlePayeeId}
                      />
                      <UIParam
                        name="settleid"
                        value={data.SettleId}
                      />
                      <UIParam name="paymentMethod" value={payMode} />
                    </UILink>
                  </UIBox>
                </div>
              );
            }}
          />
        </UIDataTable>
        {_self.getReadOnlyDialog()}
        {_self.getCancelDialog()}
        {_self.getSettlementDetailAvailable()}
      </div>
    );
  },

  onCancelShow(event) {
    const settlePayeeId = event.getParameter('settlePayeeId');
    this.refs.SettlementCancelDialog.showDialog(settlePayeeId);
  },

  onSettlementDetailAvailable(event) {
    const settlePayeeId = event.getParameter('settlePayeeId');
    const settleId = event.getParameter('settleid');
    const paymentMethod = event.getParameter('paymentMethod');
    this.refs.SettlementDetailDialog.showDialog(
      settlePayeeId,
      settleId,
      paymentMethod
    );
  },
});
export default SettlementHistory;
