const ClaimAppraisalExt = {
  getAppraisalProperty() {
    return require('./module/appraisal/common/component/property/AppraisalProperty');
  },
  getAppraisalVehicle() {
    return require('./module/appraisal/common/component/vehicle/AppraisalVehicle');
  },

  getAppraisalBodyInjury() {
    return require('./module/appraisal/common/component/bodyInjury/AppraisalBodyInjury');
  },

  getAppraisalBodyInjuryAction() {
    return require('./module/appraisal/common/action/AppraisalBodyInjuryAction');
  },
  getAppraisaAction() {
    return require('./module/appraisal/common/action/AppraisalLoadAction');
  },
};

export default ClaimAppraisalExt;
