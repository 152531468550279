import {
  UISelect,
  UIButton,
  UIPanel,
  UITextarea,
  UIDialog,
  UIBox,
  UICell,
  UIUpdatePanel,
} from 'RainbowUI';
import BindToMixin from 'react-binding';

const ReserveAction = require('../action/ReserveAction');
const CodeTableAction = require('../../common/action/CodeTableAction');
const CodeTableCstUI = require('../../common/constant/CodeTableCstUI');
const ClaimCstUI = require('../../common/constant/ClaimCstUI');

const CloseReserveDialog = React.createClass({
  mixins: [BindToMixin],

  getInitialState() {
    return { newClosure: {} };
  },

  showDialog(
    reserveType,
    level,
    itemid,
    reserveId,
    coverageName,
    status,
    subclaimIndex
  ) {
    const reserveTypeCode = CodeTableAction.loadDataList(
      CodeTableCstUI.ClaimReserveType,
      null
    );
    let cft;
    reserveTypeCode.codes.forEach(val => {
      if (reserveType == val.id) {
        cft = val.text;
      }
    });

    let surfix = this.props.relateId;
    let CloseReserve = {};
    if (ClaimCstUI.CLAIM_LEVEL == level) {
      CloseReserve = {
        CaseId: surfix,
        ClaimType: reserveType,
        Status: status,
      };
    } else if (ClaimCstUI.SUBCLAIM_LEVEL == level) {
      CloseReserve = {
        ObjectId: surfix,
        ClaimType: reserveType,
        Status: status,
      };
    } else if (ClaimCstUI.COVERAGE_LEVEL == level) {
      CloseReserve = {
        ItemId: itemid,
        ClaimType: reserveType,
        ObjectId: surfix,
        Status: status,
      };
    } else if (ClaimCstUI.RESERVE_LEVEL == level) {
      CloseReserve = {
        ReserveId: reserveId,
        ObjectId: surfix,
        Status: status,
      };
    } else {
      /* empty */
    }
    this.setState({
      newClosure: {
        CloseReserve,
        CoverageName: coverageName,
        ReserveType: cft,
        SubclaimIndex: subclaimIndex,
      },
    });
    const parentId = this.props.id;
    if (parentId != null && parentId !== '') {
      surfix = `${surfix}_${parentId}`;
    }
    UIDialog.show(`closeReserveDialog_${surfix}`);
  },
  getCoverageName() {
    let subTitile = '';
    let claimtype;
    if (this.state.newClosure.SubclaimIndex) {
      const { length } = `${this.state.newClosure.SubclaimIndex}`;
      let index = this.state.newClosure.SubclaimIndex * 1 + 1;
      const _s = ['00', '0', ''];
      index = _s[length * 1 - 1] + index;
      subTitile = `Subclaim ${index}`;
    }

    if (this.state.newClosure.ReserveType) {
      return `${subTitile}-${this.state.newClosure.CoverageName}-${this.state.newClosure.ReserveType}`;
    }
    if (!this.state.newClosure.CoverageName) {
      claimtype = this.state.newClosure.CloseReserve.Status;
      if (this.state.newClosure.SubclaimIndex) {
        return `${subTitile}-${claimtype}`;
      }
      return `Claim-${claimtype}`;
    }
    claimtype = this.state.newClosure.CloseReserve.Status;
    const coverage = this.state.newClosure.CoverageName;
    return `${subTitile}-${coverage}-${claimtype}`;
  },
  render() {
    const _self = this;
    let surfix = _self.props.relateId;
    const parentId = _self.props.id;
    if (parentId != null && parentId !== '') {
      surfix = `${surfix}_${parentId}`;
    }
    return (
      <UIDialog
        id={`closeReserveDialog_${surfix}`}
        title={i18n.ClaimReserve.ClosureConfirmation}
        width="80%"
        height="70%"
        modal="false"
      >
        <UIPanel
          id={`closeDialog_panel_${surfix}`}
          panelTitle={_self.getCoverageName}
          styleClass="default"
        >
          <UIUpdatePanel
            id={`closeDialog_message_${surfix}`}
            render={_self.getMessage}
          />
          <UICell style={{ textAlign: 'left' }}>
            <UISelect
              id={`closeType_${surfix}`}
              label={i18n.ClaimReserve.CauseOfClosure}
              required="true"
              valueLink={_self.bindToState(
                'newClosure',
                'CloseReserve.CloseType'
              )}
              validationGroup="closure_valid"
              codeTable={CodeTableAction.loadDataList(
                CodeTableCstUI.ClaimCloseType,
                { TYPE_CODE: 'in(2,4)' }
              )}
              visibled="false"
              defaultValue="4"
            />
          </UICell>
          <UICell style={{ textAlign: 'left' }}>
            <UISelect
              id={`rejectReason_${surfix}`}
              label={i18n.ClaimReserve.ReasonTypeOfClosure}
              codeTable={CodeTableAction.loadDataList(
                CodeTableCstUI.ClaimCloseReason,
                null
              )}
              valueLink={_self.bindToState(
                'newClosure',
                'CloseReserve.RejectReason'
              )}
            />
          </UICell>
          <UICell style={{ textAlign: 'left' }}>
            <UITextarea
              id={`remark_${surfix}`}
              label={i18n.ClaimReserve.Remark}
              valueLink={_self.bindToState(
                'newClosure',
                'CloseReserve.Remark'
              )}
            />
          </UICell>
        </UIPanel>
        <UIBox direction="center">
          <UIButton
            id="saveBtn"
            value={i18n.ClaimReserve.Submit}
            position="right"
            onClick={_self.submit}
            validationGroup="closure_valid"
            causeValidation="true"
          />
          <UIButton
            id="cancelBtn"
            value={i18n.ClaimReserve.Cancel}
            position="right"
            onClick={_self.cancel}
          />
        </UIBox>
      </UIDialog>
    );
  },

  submit() {
    let surfix = this.props.relateId;
    const parentId = this.props.id;
    if (parentId != null && parentId !== '') {
      surfix = `${surfix}_${parentId}`;
    }
    ReserveAction.closeSubmit(
      this.state.newClosure.CloseReserve,
      surfix
    );
  },

  getMessage() {
    if (this.state.newClosure.CloseReserve.ReserveId) {
      return (
        <UICell style={{ textAlign: 'left' }}>
          {i18n.ClaimReserve.AreYouSureCloseReserve}
        </UICell>
      );
    }
    if (this.state.newClosure.CloseReserve.ItemId) {
      return (
        <UICell style={{ textAlign: 'left' }}>
          {i18n.ClaimReserve.AreYouSureCloseItem}
        </UICell>
      );
    }
    if (this.state.newClosure.CloseReserve.ObjectId) {
      return (
        <UICell style={{ textAlign: 'left' }}>
          {i18n.ClaimReserve.AreYouSureCloseObject}
        </UICell>
      );
    }
    if (this.state.newClosure.CloseReserve.CaseId) {
      return (
        <UICell style={{ textAlign: 'left' }}>
          {i18n.ClaimReserve.AreYouSureCloseCase}
        </UICell>
      );
    }
  },

  cancel() {
    this.setState({ newClosure: {} });
    let surfix = this.props.relateId;
    const parentId = this.props.id;
    if (parentId != null && parentId !== '') {
      surfix = `${surfix}_${parentId}`;
    }
    UIDialog.hide(`closeReserveDialog_${surfix}`);
  },
});

export default CloseReserveDialog;
