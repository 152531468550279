import alt from '../alt';
import ProductCfgTreeAction from '../action/ProductCfgTreeAction';

class ProductTreeNodeStore {
  constructor() {
    this.bindListeners({
      handleInitProductTreeNode: ProductCfgTreeAction.LOAD_NODE,
    });
    this.ProductTreeEntity = {};
  }

  handleInitProductTreeNode(ProductTreeEntity) {
    this.ProductTreeEntity = ProductTreeEntity;
  }
}
export default alt.createStore(
  ProductTreeNodeStore,
  ProductTreeNodeStore
);
