import { UITree, UIPanel, UIUpdatePanel } from 'RainbowUI';

const ProductCfgTreeAction = require('../action/ProductCfgTreeAction');
const ProductTreeStore = require('../store/ProductTreeStore');

const ProductCfgTree = React.createClass({
  getInitialState() {
    return { ProductTree: [] };
  },
  componentDidMount() {
    ProductTreeStore.listen(this.onStoreStateChange);
    ProductCfgTreeAction.initData();
  },

  componentWillUnmount() {
    ProductTreeStore.unlisten(this.onStoreStateChange);
  },

  onStoreStateChange(storeState) {
    if (!_.isEmpty(storeState.ProductTree)) {
      this.setState({ ProductTree: storeState.ProductTree });
    }
  },
  render() {
    const { ProductTree } = this.state;
    return (
      <div id="tree">
        <UIUpdatePanel id="productTreeUdate_id">
          <UIPanel
            panelTitle={i18n.ClaimProduct.ProductTree}
            onlyHead="true"
          />
          <UITree
            id="tree01"
            dataSource={ProductTree}
            onClick={ProductCfgTreeAction.loadNode}
          />
        </UIUpdatePanel>
      </div>
    );
  },
});
export default ProductCfgTree;
