import { MessageHelper, UIDialog, UpdateContext } from 'RainbowUI';
import alt from '../alt';
import SubrogationService from '../service/SubrogationService';

const NewSubrogationItemVO = require('../action/vo/NewSubrogationItemVO');

class SubrogationAction {
  displayCoverageTree() {
    $('.coverageTree').show();
    $('.coverageValue').hide();
  }

  chooseCoverage(checkedList) {
    $('.coverageValue').show();
    $('.coverageTree').hide();
    this.dispatch(checkedList);
  }

  loadData(taskInstanceId) {
    let data = SubrogationService.loadSubrogationDatal(
      taskInstanceId
    );
    // data = SubrogationService.convertLoadFullSubrogationData2Positive(
    //   data
    // );
    return data;
  }

  loadSubrogationHistory(caseId) {
    let subrogationHistory = SubrogationService.loadSubrogationHistory(
      caseId
    );
    // subrogationHistory = SubrogationService.convertLoadSubrogationData2Positive(
    //   subrogationHistory
    // );
    return subrogationHistory;
  }

  loadSubrogationHistory4Tab(caseId) {
    let subrogationHistory = SubrogationService.loadSubrogationHistory(
      caseId
    );
    // subrogationHistory = SubrogationService.convertLoadSubrogationData2Positive(
    //   subrogationHistory
    // );
    return subrogationHistory;
  }

  loadApprovalSubrogationHistory(subrogationId) {
    return SubrogationService.loadApprovalSubrogationHistory(
      subrogationId
    );
  }

  loadSubrogationReadOnly(subrogationId, isApproveTask) {
    SubrogationService.loadSubrogationReadOnly(subrogationId).then(
      tempdata => {
        let data = tempdata;
        // data = SubrogationService.convertLoadFullSubrogationData2Positive(
        //   data
        // );
        this.dispatch({
          data,
          isApproveTask,
        });
      },
      data => {
        MessageHelper.error(data.Messages[0].Message, '');
      }
    );
  }

  showReadOnly(event) {
    const subrogationId = event.getParameter('subrogationId');

    this.dispatch(subrogationId);
    UIDialog.show('SubrogationReadOnlyDialog');
  }

  saveSubrogation(SubrogationData) {
    const subrogationItemList =
      SubrogationData.SubrogationInfo.SubrogationItems;
    let status = true;
    _.forEach(subrogationItemList, obj => {
      if (!obj.Amount) {
        status = false;
      }
    });

    if (status) {
      return SubrogationService.saveSubrogation(SubrogationData);
    }
    MessageHelper.warning(
      null,
      i18n.ClaimSubrogation.WarnMsg.Warn_0001,
      MessageHelper.POSITION_TOP_RIGHT
    );
  }

  loadApproveTask(taskInstanceId) {
    return SubrogationService.loadApproveTask(taskInstanceId);
  }

  handleSubroagtionData(SubrogationDatas) {
    const SubrogationData = SubrogationDatas;
    const subrogationItemslist =
      SubrogationData.SubrogationInfo.SubrogationItems;
    _.each(subrogationItemslist, items => {
      const item = items;
      item.RecoverFrom = item.RecoverFromId;
      delete item.RecoverFromId;
    });
    SubrogationData.SubrogationInfo.SubrogationItems = subrogationItemslist;
    return SubrogationData;
  }

  submitSubrogation(SubrogationDatas) {
    let SubrogationData = SubrogationDatas;
    const subrogationItemList =
      SubrogationData.SubrogationInfo.SubrogationItems;

    let status = true;
    _.forEach(subrogationItemList, obj => {
      if (!obj.Amount) {
        status = false;
      }
    });
    if (status) {
      const { SubrogationInfo } = SubrogationData;
      if (
        !SubrogationInfo ||
        !SubrogationInfo.SubrogationItems ||
        SubrogationInfo.SubrogationItems.length < 1
      ) {
        MessageHelper.error(i18n.ClaimSubrogation.NoItem, '');
        return;
      }
      SubrogationData = SubrogationService.convertSaveFullSubrogationData2Negative(
        SubrogationData
      );

      const result = SubrogationService.submitSubrogation(
        SubrogationData
      );

      if (result != 'OK') {
        MessageHelper.error(result.Messages[0].Message, '');
      }
      return result;
    }
    MessageHelper.warning(
      null,
      i18n.ClaimSubrogation.WarnMsg.Warn_0001,
      MessageHelper.POSITION_TOP_RIGHT
    );
  }

  approveSubrogation(approveInfo) {
    let flag = false;

    SubrogationService.approveSubrogation(approveInfo).then(
      data => {
        if (data.Status === 'OK') {
          if (approveInfo.DecisionCode === 'Y') {
            MessageHelper.success(
              i18n.ClaimSubrogation.SubrogationApprovalSubmitSuccess
            );
          } else {
            MessageHelper.success(
              i18n.ClaimSubrogation.SubrogationRejectSubmitSuccess
            );
          }
          flag = true;
        } else {
          MessageHelper.error(
            data.Messages[0].Message,
            i18n.ClaimSubrogation.ErrorMsg.Error_0001
          );
        }
      },
      data => {
        MessageHelper.error(
          data.Messages[0].Message,
          i18n.ClaimSubrogation.ErrorMsg.Error_0001
        );
      }
    );
    return flag;
  }

  chooseItem(event) {
    const insuredId = event.getParameter('insuredId');
    const coverageCode = event.getParameter('coverageCode');
    const index = event.getParameter('index');
    const indemnityAmount = event.getParameter('indemnityAmount');
    const data = {
      InsuredId: insuredId,
      CoverageCode: coverageCode,
      Index: index,
      ItemId: event.newValue,
      IndemnityAmount: indemnityAmount,
    };

    this.dispatch(data);
  }

  addItem(event) {
    const objectId = event.getParameter('objectId');
    const itemId = event.getParameter('itemId');
    const coverageCode = event.getParameter('coverageCode');
    const currencyCode = event.getParameter('currencyCode');
    const itemToAdd = NewSubrogationItemVO.subrogationItemToAdd;
    itemToAdd.initial(
      objectId,
      coverageCode,
      0,
      currencyCode,
      itemId
    );
    this.dispatch(itemToAdd);
  }

  deleteItem(event) {
    const insuredId = event.getParameter('insuredId');
    const coverageCode = event.getParameter('coverageCode');
    const index = event.getParameter('index');
    const indemnityAmount = event.getParameter('indemnityAmount');
    const itemsLength = event.getParameter('itemsLength');
    if (itemsLength <= 1) {
      MessageHelper.error('', i18n.ClaimCalculation.AtleastOneItem);
      this.dispatch({});
      return;
    }
    const data = {
      InsuredId: insuredId,
      CoverageCode: coverageCode,
      Index: index,
      IndemnityAmount: indemnityAmount,
      Length: itemsLength,
    };

    this.dispatch(data);
    UpdateContext.forceUpdate('subrogationDateTimePicker');
  }

  remarkChange(event) {
    const index = event.getParameter('index');

    const remark = event.newValue;

    const data = {
      remark,
      Index: index,
    };
    this.dispatch(data);
  }

  dateChange(event) {
    const index = event.getParameter('index');

    const dataChange = event.newValue;

    const data = {
      dataChange,
      Index: index,
    };
    this.dispatch(data);
  }

  amountChange(event) {
    const index = event.getParameter('index');
    let tempDataChange = Number(event.newValue);
    if (tempDataChange > 0) {
      tempDataChange *= -1;
    }
    const dataChange = tempDataChange;

    const data = {
      dataChange,
      Index: index,
    };
    this.dispatch(data);
  }

  fromChange(event) {
    const index = event.getParameter('index');

    const dataChange = event.newValue;

    const data = {
      dataChange,
      Index: index,
    };
    this.dispatch(data);
  }
}
export default alt.createActions(SubrogationAction);
