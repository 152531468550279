import React, { Component } from 'react';
import {
  UIDialog,
  UIButton,
  UIBox,
  UIRadio,
  DataContext,
  UISmartPanelGrid,
  UIText,
  UIDateTimePicker,
} from 'RainbowUI';
import CommonCodeTable from '../codetable/CodeTable';

export default class AddDataBreachDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() { }

  componentDidMount() { }

  render() {
    return (
      <div>
        <UISmartPanelGrid column="2">
          <UIRadio
            label={i18n.DataBreach}
            value="#{PartyDataBreach.BreachIndicator}"
            codeTable={CommonCodeTable.getGDPR}
            defaultValue="N"
          />
          <UIDateTimePicker
            value="#{PartyDataBreach.BreachDate}"
            format="MM/DD/YYYY"
            label={i18n.DateOfBreach}
          />
          <UIText
            label={i18n.BreachComments}
            value="#{PartyDataBreach.BreachComments}"
          />
        </UISmartPanelGrid>
        <UIBox direction="right">
          <UIButton
            value={i18n.SAVE}
            onClick={this.saveDataBreach.bind(this)}
          />
          <UIButton
            value={i18n.CANCEL}
            onClick={this.hideAddDataBreachDialog.bind(this)}
          />
        </UIBox>
      </div>
    );
  }

  hideAddDataBreachDialog() {
    UIDialog.hide('addDataBreach');
  }

  saveDataBreach() {
    const { submitHandler } = this.props;
    const partyDataBreach = DataContext.get('PartyDataBreach');
    const result = submitHandler(partyDataBreach);
    if (result === 'fail') {
      /* empty */
    } else {
      UIDialog.hide('addDataBreach');
    }
  }
}
