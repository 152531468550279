import { UIPage } from 'RainbowUI';

import ClaimWorkFlowMyClaimSearch from './ClaimWorkFlowMyClaimSearch';
import ClaimWorkFlowMyClaimResult from './ClaimWorkFlowMyClaimResult';

const ClaimWorkFlowMyClaims = React.createClass({
  render() {
    return (
      <UIPage>
        <ClaimWorkFlowMyClaimSearch />
        <ClaimWorkFlowMyClaimResult ref="ClaimWorkFlowMyClaimResult" />
      </UIPage>
    );
  },
});
export default ClaimWorkFlowMyClaims;
