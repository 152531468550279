import { MessageHelper } from 'RainbowUI';
import alt from '../alt';
import SettlementApproveService from '../service/SettlementApproveCancelService';

class SettlementApproveAction {
  init(taskInstanceId) {
    SettlementApproveService.loadApproveInfo(taskInstanceId).then(
      data => {
        this.dispatch(data);
      },
      data => {
        MessageHelper.error(data, '');
      }
    );
  }

  submit(approveInfo) {
    let flag = false;
    SettlementApproveService.approve(approveInfo).then(data => {
      if (data.Status === 'OK') {
        flag = true;
      } else {
        MessageHelper.error(data.Messages[0].Message, '');
      }
    });
    return flag;
  }
}
export default alt.createActions(SettlementApproveAction);
