import {
  DataContext,
  UIBox,
  UIText,
  UIDataTable,
  UIDialog,
  MessageHelper,
  UIButton,
  UIColumn,
  UISelect,
} from 'RainbowUI';
import BindToMixin from 'react-binding';
import MassiveReassignmentClaimOwnerAction from '../../action/MassiveReassignmentClaimOwnerAction';
import ClaimAuthorityAction from '../../action/ClaimAuthorityAction';
import ClaimCstUI from '../../constant/ClaimCstUI';

import ReassignmentClaimOwnerStore from '../../store/ReassignmentClaimOwnerStore';
import Url from '../../url/Url';

const CodeTableAction = require('../../action/CodeTableAction');
const CodeTableCstUI = require('../../constant/CodeTableCstUI');

const MassiveReassignmentClaimOwnerDialog = require('./MassiveReassignmentClaimOwnerDialog');

const ClaimOwnerResult = React.createClass({
  mixins: [BindToMixin],

  getInitialState() {
    return {
      condition: {},
      isInital: true,
      update: false,
      ownerAtuh: 'true',
    };
  },

  componentWillMount() {
    const result = ClaimAuthorityAction.checkAuth(
      '',
      ClaimCstUI.AUTHORITY_CLAIM_OWNER_ASSIGNMENT
    );
    this.setState({ ownerAtuh: result });
    ReassignmentClaimOwnerStore.listen(this.onStoreStateChange);
  },

  componentWillUnmount() {
    ReassignmentClaimOwnerStore.unlisten(this.onStoreStateChange);
  },
  onStoreStateChange(storeState) {
    this.setState({ condition: storeState.taskSearchOptions });
    this.setState({ isInital: false });
  },
  loadClaimList(pageindex, pagesize) {
    if (this.state.isInital) {
      return [];
    }
    DataContext.put('pageindex', pageindex);
    DataContext.put('pagesize', pagesize);
    return MassiveReassignmentClaimOwnerAction.loadClaimList(
      pageindex,
      pagesize,
      this.state.condition
    );
  },
  updateThisUI() {
    this.setState({ update: true });
  },
  showDialog() {
    const selRecord = UIDataTable.getSelectedRecord(
      'claimResultList'
    );
    if (!selRecord) {
      MessageHelper.info(
        i18n.ClaimCommon.NoRecSelected,
        i18n.ClaimCommon.MassiveReassignmentClaimowner,
        MessageHelper.POSITION_TOP_FULL_WIDTH
      );
      return;
    }

    DataContext.put('claimList', selRecord);
    UIDialog.show('massiveReassignmentClaimOwnerDialog');
  },

  render() {
    const self = this;

    return (
      <div>
        <UIText
          label={i18n.ClaimCommon.MassiveReassignment}
          io="out"
        />
        <UIDataTable
          id="claimResultList"
          indexable="false"
          var="poolSelected"
          provider={this.loadClaimList}
          headerTitle={i18n.ClaimCommon.SearchResult}
        >
          <UIColumn selectionMode="multiple" enabled="ture" />
          <UIColumn
            headerTitle={i18n.ClaimCommon.SubClaimNo}
            value="SubClaimNo"
            sortable="true"
          />
          <UIColumn
            headerTitle={i18n.ClaimCommon.Product}
            value="Product"
            sortable="true"
          >
            <UISelect
              codeTable={CodeTableAction.loadDataList(
                CodeTableCstUI.ClaimProduct,
                null
              )}
              io="out"
            />
          </UIColumn>
          <UIColumn
            headerTitle={i18n.ClaimCommon.ReassignSubclaimType}
            value="SubclaimType"
            sortable="true"
          >
            <UISelect
              codeTable={CodeTableAction.loadDataList(
                CodeTableCstUI.SubclaimType,
                null
              )}
              io="out"
            />
          </UIColumn>
          <UIColumn
            headerTitle={i18n.ClaimCommon.Claimant}
            value="Claimant"
            sortable="true"
          />
          <UIColumn
            headerTitle={i18n.ClaimCommon.PolicyHolder}
            value="PolicyHolder"
            sortable="true"
          />
          <UIColumn
            headerTitle={i18n.ClaimCommon.ClaimOwner}
            value="ClaimOwner"
            sortable="true"
          >
            <UISelect
              codeTable={CodeTableAction.loadDataList(
                CodeTableCstUI.ClaimUser,
                null
              )}
              io="out"
            />
          </UIColumn>
          <UIColumn
            headerTitle={i18n.ClaimCommon.ClaimBranch}
            value="ClaimBranch"
            sortable="true"
          >
            <UISelect
              codeTable={CodeTableAction.loadDataList(
                CodeTableCstUI.Branch,
                null
              )}
              io="out"
            />
          </UIColumn>
          <UIColumn
            headerTitle={i18n.ClaimCommon.AssignmentStatus}
            value="LossStatus"
            sortable="true"
          >
            <UISelect
              codeTable={CodeTableAction.loadDataList(
                CodeTableCstUI.ClaimLossStatus,
                null
              )}
              io="out"
            />
          </UIColumn>
        </UIDataTable>
        <UIBox direction="left">
          <UIButton
            value={i18n.ClaimCommon.ChangeClaimOwner}
            onClick={this.showDialog}
            enabled={self.state.ownerAtuh}
          />
        </UIBox>
        <MassiveReassignmentClaimOwnerDialog
          updateparentUI={this.updateThisUI}
        />
      </div>
    );
  },
});
export default ClaimOwnerResult;
