import BindToMixin from 'react-binding';
import PolicyProduct from './PolicyProduct';
import PolicyRisk from './PolicyRisk';

const PolicyProductInfo = React.createClass({
  mixins: [BindToMixin],
  getInitialState() {
    return {
      policyEntity: this.props.policyEntity,
      productList: this.props.policyProductRisk,
    };
  },

  componentWillMount() {},

  componentWillUnmount() {},
  onStoreStateChange() {},
  getProductRisk() {
    return this.refs.AP100PolicyRisk.getProductRisk();
  },
  componentDidMount() {
    let defaultValue = null;
    const { productList } = this.state;
    if (productList && productList.length > 0) {
      productList.forEach(data => {
        if (data.ProductCode) {
          defaultValue = data.ProductCode;
        }
      });
    }
    if (defaultValue) {
      const policyEntityCriteria = {};
      policyEntityCriteria.ProductCode = defaultValue;
      this.refs.AP100PolicyRisk.filterRiskList(policyEntityCriteria);
      this.refs.AP100ProductCode.getDefaultValue(defaultValue);
    }
  },
  render() {
    const defaultValue = null;
    const { productList } = this.state;
    return (
      <div>
        <PolicyProduct
          ref="AP100ProductCode"
          productList={productList}
          parentComponent={this}
          defaultValue={defaultValue}
        />
        <PolicyRisk
          ref="AP100PolicyRisk"
          productList={productList}
          parentComponent={this}
          defaultValue={defaultValue}
        />
      </div>
    );
  },
});
export default PolicyProductInfo;
