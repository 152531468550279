import {
  ApprovalInfo,
  ApprovalHistory,
  ClaimTaskTransferDialog,
} from 'ClaimCommon';
import React from 'react';
import PropTypes from 'prop-types';
import BindToMixin from 'react-binding';
import ClaimCommonExt from 'ClaimCommonExt';
import { UIPanel } from 'RainbowUI';

const SubrogationReadOnly = require('./SubrogationReadOnly');
const SubrogationHistory = require('./SubrogationHistory');
const SubrogationAction = require('../action/SubrogationAction');
const SubrogationApproveStore = require('../store/SubrogationApproveStore');

const ClaimBaseInfo = ClaimCommonExt.getClaimBaseInfo();

const SubrogationApprovel = React.createClass({
  mixins: [BindToMixin],
  propTypes: {
    taskInstanceId: PropTypes.string.isRequired,
    caseId: PropTypes.string.isRequired,
  },
  componentWillMount() {
    SubrogationApproveStore.listen(this.onStoreStateChange);
    SubrogationAction.loadApproveTask(this.props.taskInstanceId);
  },
  onStoreStateChange(storeState) {
    if (!_.isEmpty(storeState.approveInfo)) {
      this.setState({ approveInfo: storeState.approveInfo });
    }
  },
  getInitialState() {
    return {
      approveInfo: {
        BusinessId: null,
        TaskInstanceId: null,
        CaseId: null,
        Remark: '',
        DecisionCode: null,
      },
    };
  },
  render() {
    const _self = this;
    const CommonConfirmDialog = ClaimCommonExt.getCommonConfirmDialog();
    const {
      approveInfo: { BusinessId },
    } = this.state;
    const { claimEntity, caseId, readOnly } = this.props;
    const subrogationId = BusinessId;
    const suffixId = 'SubrogationApprovel';
    return (
      <div>
        <ClaimBaseInfo claimEntity={claimEntity} />
        <UIPanel
          panelTitle={i18n.ClaimSubrogation.SubrogationPanelTitle}
          toggleable="false"
        >
          <SubrogationReadOnly
            subrogationId={subrogationId}
            history={false}
            readOnly
            isApproveTask
          />
        </UIPanel>
        <ApprovalInfo
          decision={this.bindToState('approveInfo', 'DecisionCode')}
          remark={this.bindToState('approveInfo', 'Remark')}
          onClick={_self.onClickConfirm}
        />
        <ApprovalHistory
          processObjectId={subrogationId}
          componentId={suffixId}
        />
        <SubrogationHistory caseId={caseId} />
        <ClaimTaskTransferDialog
          ref="ClaimTaskTransferDialog"
          componentId="cal_cul_app"
        />
        <CommonConfirmDialog
          ref="confirmDialog_ref"
          parentComponent={this}
          objectId={`_subrogationApprove${readOnly}_${subrogationId}`}
          onConfirm={this.confirm}
          onCancel={this.cancel}
        />
      </div>
    );
  },
  confirm() {
    this.state.approveInfo.CaseId = this.props.caseId;
    const flag = SubrogationAction.approveSubrogation(
      this.state.approveInfo
    );
    if (flag) {
      this.refs.ClaimTaskTransferDialog.showDialog({
        CaseId: this.props.caseId,
      });
    }
  },
  validate() {
    return true;
  },
  cancel() {},
  onClickConfirm() {
    this.confirm();
  },
});
export default SubrogationApprovel;
