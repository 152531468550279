import {
  UIText,
  UISelect,
  UIDateTimePicker,
  UIDialog,
  UISmartPanelGrid,
  UIBox,
  UIButton,
  UICheckbox,
} from 'RainbowUI';
import CommonCodeTable from '../../../codetable/CodeTable';
import PartyConst from '../../../const/PartyConst';

const ViewCustomerIndRole = React.createClass({
  getInitialState() {
    return {
      partyRole: {},
    };
  },

  render() {
    const _self = this;
    const { partyRole, PartyRoleModel } = this.state;
    const status = partyRole.Status;
    let visible = false;
    visible = status != '1';
    return (
      <div>
        <UIDialog
          id="viewCustomerIndPartyDialog"
          title={i18n.PartyRoleDetail}
          width="80%"
        >
          <UISmartPanelGrid column="3">
            <UISelect
              label={i18n.PartyRole}
              value={PartyConst.PARTY_ROLE_CODE_IND_CUSTOMER}
              enabled="false"
              codeTable={CommonCodeTable.getIndPartyRole}
              required="true"
            />
            <UIText
              label={i18n.CustomerCode}
              enabled="false"
              model={partyRole}
              property="CustomerCode"
            />
            <UISelect
              label={i18n.customerType}
              defaultValue="1"
              showBlankOption="false"
              model={partyRole}
              property="CustomerType"
              codeTable={CommonCodeTable.getPartyRoleCustomerType}
              disabled="true"
            />
            <UIText
              label={i18n.Remarks}
              colspan="3"
              model={partyRole}
              property="Remark"
              disabled="true"
            />
            <UISelect
              label={i18n.Status}
              model={partyRole}
              property="Status"
              showBlankOption="false"
              disabled="true"
              codeTable={CommonCodeTable.getPartyStatus}
              onChange={_self.changeStatus}
            />
            <UIDateTimePicker
              label={i18n.InactiveDate}
              format="MM/DD/YYYY"
              disabled="true"
              visibled={visible}
              model={partyRole}
              property="InactiveDate"
            />
            <UICheckbox
              single="true"
              singleLabel={i18n.Role.NPCExemption}
              disabled="true"
              enabled="false"
              model={PartyRoleModel}
              property="NPCExemption"
            />
          </UISmartPanelGrid>
          <UIBox direction="center">
            <UIButton value={i18n.Save} disabled="true" />
            <UIButton
              value={i18n.Cancel}
              onClick={_self.onClickClose}
            />
          </UIBox>
        </UIDialog>
      </div>
    );
  },

  onClickClose() {
    UIDialog.hide('viewCustomerIndPartyDialog');
  },
  showDialog(partyRole) {
    this.setState({
      partyRole,
    });
    UIDialog.show('viewCustomerIndPartyDialog');
  },
});
export default ViewCustomerIndRole;
