import { MessageHelper, UIDialog } from 'RainbowUI';
import CommonService from '../service/CommonService';

const urlConfig = require('../../../UrlConfig');

class GroupIdAction {
  onClickedExit() {
    window.location.hash = '/';
  }

  createOrUpdateGroupDefine(groupDefine) {
    const commonService = new CommonService();
    const urlSaveOrUpdate = urlConfig.partyGroupSaveOrUpdate;
    commonService
      .ajaxPost(groupDefine, urlSaveOrUpdate)
      .then(data => {
        MessageHelper.success(
          i18n.PartyMsg.SuccessMsg.Success_0004,
          null,
          MessageHelper.POSITION_TOP_RIGHT
        );
        UIDialog.hide('GroupIdDefineDialog');
      });
  }

  getPartyGroupResult(searchCondition, pageIndex, pageSize) {
    const commonService = new CommonService();

    let total = 0;
    let results = [];

    const pageNo = (pageIndex - 1) / pageSize + 1;
    const condition = {
      PageNo: pageNo,
      PageSize: pageSize,
      GroupId: searchCondition.GroupId,
      Description: searchCondition.Description,
    };

    const url = urlConfig.searchPartyGroupByCondition;
    commonService.ajaxPost(condition, url).then(data => {
      if (
        data.ElementsInCurrentPage &&
        data.ElementsInCurrentPage.length > 0
      ) {
        total = data.TotalElements;
        results = data.ElementsInCurrentPage;
      }
    });

    return { count: total, result: results };
  }

  searchByGroupId(groupId) {
    const commonService = new CommonService();
    const url = `${urlConfig.partySearchByGroupId}?groupId=${groupId}`;
    return commonService.ajaxGetNoDeferred(null, url);
  }
}

export default GroupIdAction;
