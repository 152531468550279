export default {
  Currency: '通貨',

  Remark: '備考',

  // 按钮
  Action: 'アクション',
  Submit: '確定',
  Cancel: 'キャンセル',

  // reserve
  Reserve: '備金',
  ReserveHistory: '備金履歴',
  OpenReserve: 'オープン備金',
  ReserveType: '備金種類',
  ReserveStatus: '備金状態',
  OutstandingReserve: '未決済備金',
  ReserveUpdate: '更新',
  Settled: '決済',
  LockStatus: 'フリーズ状態',
  Locked: 'フリーズ',
  Unlock: '解錠',

  // reservereopen
  Reopeningconfirmation: '再開確認',
  AreYouSure: '備金種類再開しますか？確認してください。',
  CauseOfReopening: '再開事由',
  NewOutstandingReserve: '新規未決済備金',

  // reserveclose
  ClosureConfirmation: 'クローズ確認',
  AreYouSureCloseReserve: '備金をクローズしますか？',
  AreYouSureCloseItem: '備金をクローズしますか？',
  AreYouSureCloseObject: '備金をクローズしますか？',
  AreYouSureCloseCase: '備金をクローズしますか？',
  CauseOfClosure: 'クローズ事由',
  ReasonTypeOfClosure: 'クローズ事由種類',

  // openreserve
  Status: '状態',

  // reservehistory
  ClaimFileType: 'クレームファイル種類',
  ChangeAmount: '金額変更',
  Operator: '担当者',
  Date: '日',
  CategoryOfReserveChange: '備金変更カテゴリ',
  Remarks: '備考',
  CoinSurance: 'Coin-insurance',
  CoinLeader: 'Leader',
  ShareRate: 'Share Rate',
  Total: 'Total',

  // calculateReserve
  ReserveCalculation: '備金アップデート',
  CalculationResult: '新しい未決済備金',

  // MessageHelper
  OpenReserveSuccess: '开启准备金提交成功',
  CloseReserveSuccess: '关闭准备金保存成功',
  UpdateReserveSucess: '更新准备金提交成功',
  ReopenReserveSucess: '重开准备金保存成功',
  InputNegative: '请输入负数',
  InputPositive: '请输入正数',
  OpenReserveError: '请选择需要打开准备金',

  // reserveApproval
  ReserveUpdateApproval: '准备金更新签核',
  ReserveClosureApproval: '准备金关闭签核',
};
