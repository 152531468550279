import {
  UIDateTimePicker,
  UISelect,
  UITextarea,
  UISmartPanelGrid,
  UIPanel,
  UIText,
} from 'RainbowUI';
import DefinedCodeTable from '../codetable/CodeTable';
import GroupIdAction from '../actions/GroupIdAction';
import GroupIdSearchDialog from './groupId/GroupIdSearchDialog';
import CodeTableTextNoValuelink from '../../common/component/CodeTableTextNoValuelink';

const PartyOrgBasicInfo = React.createClass({
  getInitialState() {
    return {
      isReadOnly: false,
      party: this.props.party,
    };
  },

  componentDidMount() {
    if (this.state.party) {
      const groupId = this.state.party.GroupId;
      if (groupId != null && groupId !== '') {
        $("input[name='IdForPartyGroupSearch']").val(groupId);
        const groupIdAction = new GroupIdAction();
        const groupInfo = groupIdAction.searchByGroupId(groupId);
        if (groupInfo) {
          $("input[name='IdForPartyGroupSearch_value']").val(
            groupInfo.GroupName
          );
          $("textarea[name='IdForPartyGroupDesc']").val(
            groupInfo.Description
          );
        }
      }
    }
  },

  onPartyStoreChange(PartyStoreState) {
    if (PartyStoreState.party) {
      this.setState({ party: PartyStoreState.party });
    }
  },

  render() {
    const basicInfoRender = this.orgBasicInfoRender();
    return (
      <UIPanel panelTitle={i18n.BasicInfo}>{basicInfoRender}</UIPanel>
    );
  },

  orgBasicInfoRender() {
    const { party } = this.state;
    return (
      <div>
        <UISmartPanelGrid column="3">
          <UISelect
            label={i18n.PartyCategory}
            disabled="true"
            model={party}
            property="PartyType"
            codeTable={DefinedCodeTable.getCategroyCodeTable}
          />
          <UIText
            label={i18n.PartyCode}
            disabled="true"
            model={party}
            property="PartyCode"
          />
          <UIText
            label={i18n.CompanyNo}
            model={party}
            property="OrganizationIdNumber"
            required="true"
            validationGroup="UpdateBasicInfo"
          />
          <UIText
            label={i18n.CompanyName}
            model={party}
            property="PartyName"
            colspan="2"
            required="true"
            validationGroup="UpdateBasicInfo"
          />
          <UISelect
            label={i18n.ContactLanguage}
            codeTable={DefinedCodeTable.ContactLanguage}
            defaultValue="el_GR"
            showBlankOption="false"
            model={party}
            property="LanguagePreferred"
            required="true"
            validationGroup="UpdateBasicInfo"
          />
          <UISelect
            label={i18n.CountryDom}
            model={party}
            property="CountryDom"
            codeTable={DefinedCodeTable.getCountry}
            defaultValue="CY"
          />
          <UISelect
            label={i18n.CompanyType}
            model={party}
            property="CompanyType"
            codeTable={DefinedCodeTable.getCompanyType}
          />
          <UISelect
            label={i18n.BusinessActivity}
            model={party}
            property="BusinessActivity"
            codeTable={DefinedCodeTable.getBusinessType}
          />
          <UIDateTimePicker
            label={i18n.EstYear}
            manualInput="true"
            model={party}
            property="EstYear"
            viewMode="years"
            format="YYYY"
            placeHolder="YYYY"
            maxDate="TODAY"
          />
          <UIText
            label={i18n.EmployeeNumber}
            allowChars="0123456789"
            model={party}
            property="NumberofEmployees"
          />
          <UIText
            label={i18n.SSSEmployerId}
            model={party}
            property="EmployerId"
          />
          <UIText
            label={i18n.FormerPartyCode}
            disabled="true"
            model={party}
            property="FormerPartyCode"
          />
          <UIText label={i18n.vat} model={party} property="VAT" />
          <UIText label={i18n.TIC} model={party} property="TIC" />
          <UISelect
            label={i18n.DefaultPaymentMethod}
            model={party}
            property="PaymentMethodId"
            defaultValue="101"
            codeTable={DefinedCodeTable.getDefaultPaymentMethod}
            required="true"
            validationGroup="UpdateBasicInfo"
          />
          <UITextarea
            label={i18n.Description}
            model={party}
            property="Description"
          />
          <CodeTableTextNoValuelink
            id="IdForPartyGroupSearch"
            label={i18n.PtyGroupID}
            onIconClick={this.onClickGroupIdSearch}
            onChange={this.onChangeGroupId}
          />
          <UITextarea
            id="IdForPartyGroupDesc"
            label={i18n.PtyGroupIdDesc}
            disabled="true"
          />
        </UISmartPanelGrid>
        <GroupIdSearchDialog
          ref="refOrgGroupIdSearchDialog"
          customerChose={this.onGetGroupInfoFromPopup}
        />
      </div>
    );
  },

  onClickGroupIdSearch() {
    this.refs.refOrgGroupIdSearchDialog.showDialog();
  },

  onGetGroupInfoFromPopup(groupInfo) {
    if (groupInfo && groupInfo) {
      const { party } = this.state;
      party.GroupId = groupInfo.GroupId;
      $("input[name='IdForPartyGroupSearch']").val(groupInfo.GroupId);
      $("input[name='IdForPartyGroupSearch_value']").val(
        groupInfo.GroupName
      );
      $("textarea[name='IdForPartyGroupDesc']").val(
        groupInfo.Description
      );
    }
  },

  onChangeGroupId(event) {
    const { newValue } = event;
    if (newValue) {
      const groupIdAction = new GroupIdAction();
      const groupInfo = groupIdAction.searchByGroupId(newValue);
      if (groupInfo) {
        $("input[name='IdForPartyGroupSearch']").val(
          groupInfo.GroupId
        );
        $("input[name='IdForPartyGroupSearch_value']").val(
          groupInfo.GroupName
        );
        $("textarea[name='IdForPartyGroupDesc']").val(
          groupInfo.Description
        );
      } else {
        $("input[name='IdForPartyGroupSearch_value']").val(null);
        $("textarea[name='IdForPartyGroupDesc']").val(null);
      }
    }
  },
});

export default PartyOrgBasicInfo;
