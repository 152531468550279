import {
  UICell,
  UICurrency,
  UIDialog,
  UIButton,
  UIPanel,
  UITextarea,
  MessageHelper,
  UIBox,
} from 'RainbowUI';
import BindToMixin from 'react-binding';
import ClaimCommonExt from 'ClaimCommonExt';
import PropTypes from 'prop-types';

import { CommonConstant } from 'EU00PartyCommonUI';

const ReserveAction = require('../action/ReserveAction');

const CodeTableAction = require('../../common/action/CodeTableAction');
const CodeTableCstUI = require('../../common/constant/CodeTableCstUI');
const ClaimCstUI = require('../../common/constant/ClaimCstUI');

const ReserveValidationAction = ClaimCommonExt.getReserveValidationAction();
const CalculateReserveDialog = React.createClass({
  mixins: [BindToMixin],

  getSuperComponent() {
    return this;
  },

  propTypes: {
    objectId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  },

  getInitialState() {
    return { newCalculate: {} };
  },

  showDialog(tempdata) {
    const newCalculate = {};
    const data = tempdata;
    // if (data.Positive && data.OutstandingAmount) {
    //   data.OutstandingAmount *= -1;
    // }
    newCalculate.reserveVO = data;
    this.setState({ newCalculate });
    UIDialog.show(`calculateReserveDialog_${this.props.objectId}`);
  },
  changeOutstandingAmount(event) {
    const { newValue } = event;
    const { oldValue } = event;
    const maxAmount = Number(999999999.99);
    const oaAbsAmt = Math.abs(Number(newValue));
    if (maxAmount < oaAbsAmt) {
      if (maxAmount < Number(oldValue)) {
        this.state.newCalculate.reserveVO.OutstandingAmount = 0;
      } else {
        this.state.newCalculate.reserveVO.OutstandingAmount = oldValue;
      }
      this.forceUpdate();
      MessageHelper.error(i18n.ClaimReserve.MaxAmountMessage);
    }
  },
  render() {
    const _self = this;
    const CommonConfirmDialog = ClaimCommonExt.getCommonConfirmDialog();
    return (
      <UIDialog
        id={`calculateReserveDialog_${_self.props.objectId}`}
        title={i18n.ClaimReserve.ReserveCalculation}
        width="80%"
        height="600px"
        modal="false"
      >
        <UIPanel
          id={`calculateReserve_panel_${_self.props.objectId}`}
          panelTitle={_self.getCoverageName}
          styleClass="default"
        >
          <UICell width="12">
            {/* <UICurrency id={"CURRENCY_CODE_calculationResult_"+_self.props.objectId} label={i18n.ClaimReserve.CalculationResult} required="true" unit={window.EU00.getCurrencySignByCurrencyCode((_self.state.newCalculate.reserveVO==null)? '':_self.state.newCalculate.reserveVO.CurrencyCode)}
					    	valueLink={_self.bindToState("newCalculate","reserveVO.OutstandingAmount")} allowNegative={(_self.state.newCalculate.reserveVO==null)? '':_self.state.newCalculate.reserveVO.Positive}
					    	helpText={(_self.state.newCalculate.reserveVO==null)? '':_self.state.newCalculate.reserveVO.Positive?(i18n.ClaimReserve.InputNegative):(i18n.ClaimReserve.InputPositive)}
					    	validationGroup="calculate_valid" />
					    </UICell> */}
            <UICurrency
              maxLength={CommonConstant.AMOUNT_MAX_LENGTH_4_CLAIM}
              maxLengthMessage={i18n.ClaimReserve.MaxAmountMessage}
              id={`CURRENCY_CODE_calculationResult_${_self.props.objectId}`}
              label={i18n.ClaimReserve.CalculationResult}
              required="true"
              onChange={_self.changeOutstandingAmount}
              unit={window.EU00.getCurrencySignByCurrencyCode(
                !_self.state.newCalculate.reserveVO
                  ? ''
                  : _self.state.newCalculate.reserveVO.CurrencyCode
              )}
              model={_self.state.newCalculate.reserveVO}
              property="OutstandingAmount"
              helpText={(_self.state.newCalculate.reserveVO == null) ? '' : _self.state.newCalculate.reserveVO.Positive ? (i18n.ClaimReserve.InputNegative) : (i18n.ClaimReserve.InputPositive)}
              validationGroup="calculate_valid"
              allowNegative="true"
            />
          </UICell>
          <UICell width="12">
            <UITextarea
              id={`remark_${_self.props.objectId}`}
              label={i18n.ClaimReserve.Remark}
              model={_self.state.newCalculate.reserveVO}
              property="Remark"
            />
          </UICell>
        </UIPanel>
        <UIBox direction="center">
          <UIButton
            value={i18n.ClaimReserve.Submit}
            position="right"
            onClick={_self.onClickSubmit}
            causeValidation="true"
            validationGroup="calculate_valid"
          />
          <UIButton
            value={i18n.ClaimReserve.Cancel}
            position="right"
            onClick={_self.cancel}
          />
        </UIBox>
        <CommonConfirmDialog
          ref="confirmDialog_ref"
          objectId={_self.props.objectId}
          onConfirm={_self.submit}
        />
      </UIDialog>
    );
  },

  onClickSubmit() {
    if (this.validate()) {
      return;
    }

    this.submit();
  },
  validate() {
    const { reserveVO } = this.state.newCalculate;
    const { objectId } = this.props;
    if (reserveVO.Positive && reserveVO.OutstandingAmount > 0) {
      MessageHelper.error('', i18n.ClaimReserve.InputNegative);
      return true;
    };
    // if (reserveVO.Positive) {
    //   reserveVO.OutstandingAmount *= -1;
    // }
    if (!reserveVO.Positive && reserveVO.OutstandingAmount < 0) {
      MessageHelper.error('', i18n.ClaimReserve.InputPositive);
      return true;
    }

    const result = ReserveValidationAction.validate(
      reserveVO,
      objectId
    );

    if (
      result &&
      result.Status == ClaimCstUI.REST_RESTPONSE_STATUS_WARN
    ) {
      const msg = result.Messages[0].Message;
      this.refs.confirmDialog_ref.showDialog(
        i18n.ClaimReserve.ReserveCalculation,
        msg
      );
      return true;
    }

    return false;
  },
  submit() {
    const { reserveVO } = this.state.newCalculate;
    const { objectId } = this.props;
    const calculateVO = {
      ReserveId: reserveVO.ReserveId,
      OutstandingAmount: reserveVO.OutstandingAmount,
      CurrencyCode: reserveVO.CurrencyCode,
      Remark: reserveVO.Remark,
    };
    ReserveAction.submitCalculate(calculateVO, objectId);
  },

  getCoverageName() {
    const { newCalculate } = this.state;
    let panelTitleData;
    if (!newCalculate || !newCalculate.reserveVO) {
      panelTitleData = '';
    } else {
      const reserveTypeCode = CodeTableAction.loadDataList(
        CodeTableCstUI.ClaimReserveType,
        null
      );

      if (newCalculate.reserveVO.ReserveType) {
        let cft;
        reserveTypeCode.codes.forEach(val => {
          if (newCalculate.reserveVO.ReserveType == val.id) {
            cft = val.text;
          }
        });
        let subTitile = '';
        if (newCalculate.reserveVO.SubclaimIndex) {
          const {
            length,
          } = `${newCalculate.reserveVO.SubclaimIndex}`;
          let index = newCalculate.reserveVO.SubclaimIndex * 1 + 1;
          const _s = ['00', '0', ''];
          index = _s[length * 1 - 1] + index;
          subTitile = `Subclaim ${index}`;
        }

        panelTitleData = `${subTitile}-${newCalculate.reserveVO.CoverageName}-${cft}`;
      } else {
        panelTitleData = newCalculate.reserveVO.CoverageName;
      }
    }
    return panelTitleData;
  },

  cancel() {
    UIDialog.hide(`calculateReserveDialog_${this.props.objectId}`);
  },
});

export default CalculateReserveDialog;
