import alt from '../alt';

const CalculationLoadAction = require('../action/CalculationLoadAction');

class CalculationHistoryStore {
  constructor() {
    this.CalculationHistory = [];
    this.CalculateId = null;
    this.bindListeners({
      handleLoad: CalculationLoadAction.LOAD_CALCULATION_HISTORY,
      handleShowReadOnly: CalculationLoadAction.SHOW_READ_ONLY,
    });
  }

  handleLoad(data) {
    this.CalculationHistory = data;
  }

  handleShowReadOnly(calculateId) {
    this.CalculateId = calculateId;
  }
}
export default alt.createStore(
  CalculationHistoryStore,
  CalculationHistoryStore
);
