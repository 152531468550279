import {
  UIPickList,
  UIText,
  UIButton,
  UIBox,
  UISmartPanelGrid,
  UICell,
  UIParam,
} from 'RainbowUI';
import PropTypes from 'prop-types';

const ClmCoverageReservePaymentOfProductLineAction = require('../../action/ClmCoverageReservePaymentOfProductLineAction');

const SubclaimCoverageReservePaymentRelation = React.createClass({
  propTypes: {
    entity: PropTypes.object,
  },
  getPickList() {
    const entity = {
      SubclaimTypeCode: this.props.entity.subclaimTypeCode,
      CoverageTypeCode: this.props.entity.coverageTypeCode,
      ProductLineCode: this.props.entity.treeNodeCode,
      ReserveTypeCode: this.props.entity.reserveTypeCode,
    };

    return ClmCoverageReservePaymentOfProductLineAction.getPickList(
      entity
    );
  },
  render() {
    const { entity } = this.props;
    return (
      <div>
        <UISmartPanelGrid>
          <UIText
            label={i18n.ClaimProduct.SubclaimType}
            value={`${entity.subclaimTypeCode}:${entity.subclaimTypeName}`}
          />
          <UIText
            label={i18n.ClaimProduct.CoverageType}
            value={`${entity.coverageTypeCode}:${entity.coverageTypeName}`}
          />
          <UIText
            label={i18n.ClaimProduct.ReserveType}
            value={`${entity.reserveTypeCode}:${entity.reserveTypeName}`}
          />
        </UISmartPanelGrid>
        <div>
          <UICell width="4" />
          <UICell width="8">
            <UIPickList
              id="SubclaimCoverageReservePaymentRelationPickList"
              sourceListLabel={
                i18n.ClaimProduct.OptionalSettlementTypes
              }
              targetListLabel={
                i18n.ClaimProduct.ApplicableSettlementTypes
              }
              dataSource={this.getPickList}
            />
          </UICell>
        </div>
        <UIBox direction="left">
          <UIButton
            value={i18n.ClaimProduct.Save}
            onClick={
              ClmCoverageReservePaymentOfProductLineAction.saveSubclaimCoverageReservePayment
            }
          >
            <UIParam name="param" value={entity} />
          </UIButton>
          <UIButton
            value={i18n.ClaimProduct.Cancel}
            onClick={
              ClmCoverageReservePaymentOfProductLineAction.cancelSubclaimCoverageReservePayment
            }
          >
            <UIParam name="param" value={entity} />
          </UIButton>
        </UIBox>
      </div>
    );
  },
});
export default SubclaimCoverageReservePaymentRelation;
