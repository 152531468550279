const ClaimCommonExt = {
  //= ===================Claim handling page=========================
  getPriorClaimsDialog() {
    return require('./module/common/component/PriorClaimsDialog');
  },
  getClaimHanding() {
    return require('./module/common/component/claimhandling/ClaimHandling');
  },
  getHandlingLinks() {
    return require('./module/common/component/claimhandling/HandlingLinks');
  },
  getClaimInfoTab() {
    return require('./module/common/component/claimhandling/ClaimInfoTab');
  },

  getPolicyInfo() {
    return require('./module/common/component/policy/PolicyInfo');
  },
  //= ===================Claim policy page=========================
  getPolicySearchOptions() {
    return require('./module/common/component/policy/PolicySearchOptions');
  },
  getPolicySearchResult() {
    return require('./module/common/component/policy/PolicySearchResult');
  },
  getPolicyAction() {
    return require('./module/common/action/PolicyAction');
  },
  getPolicyDetail() {
    return require('./module/common/component/policy/PolicyDetail');
  },
  getPolicyDetailCoverage() {
    return require('./module/common/component/policy/PolicyDetailCoverage');
  },
  getPolicyDetailDialog() {
    return require('./module/common/component/policy/PolicyDetailDialog');
  },
  getPolicyDetailInsured() {
    return require('./module/common/component/policy/PolicyDetailInsured');
  },
  getPolicyDetailPolicy() {
    return require('./module/common/component/policy/PolicyDetailPolicy');
  },
  //= ===================Claim Reserve Page===================
  getReserveLight() {
    return require('./module/common/component/claimhandling/ReserveLight');
  },
  getReopenReserveDialog() {
    return require('./module/reserve/component/ReopenReserveDialog');
  },
  getCalculateReserveDialog() {
    return require('./module/reserve/component/CalculateReserveDialog');
  },
  getReserveValidationAction() {
    const ReserveValidationAction = require('./module/reserve/action/ReserveValidationAction');
    return new ReserveValidationAction();
  },

  //= ================Claim party Page========================
  getPartyAccountInfo() {
    return require('./module/claimparty/component/PartyAccountInfo');
  },
  getPartyBasicInfo() {
    return require('./module/claimparty/component/PartyBasicInfo');
  },
  getPartyContact() {
    return require('./module/claimparty/component/PartyContact');
  },
  getPartyAddress() {
    return require('./module/claimparty/component/PartyAddress');
  },
  getClaimPartyDetailPanel() {
    return require('./module/claimparty/component/ClaimPartyDetailPanel');
  },
  getPartyAccount() {
    return require('./module/claimparty/component/PartyAccount');
  },
  getChooseClaimantAction() {
    const ChooseClaimantAction = require('./module/claimparty/action/ChooseClaimantAction');
    return new ChooseClaimantAction();
  },
  getChoosePayeeAction() {
    const ChoosePayeeAction = require('./module/claimparty/action/ChoosePayeeAction');
    return new ChoosePayeeAction();
  },
  getClaimPartyRightPanel() {
    return require('./module/claimparty/component/ClaimPartyRightPanel');
  },
  getClaimPartyAddOrEditPartyDialog() {
    return require('./module/claimparty/component/AddOrEditPartyDialog');
  },
  getChoosePayeeOrPayerDialog() {
    return require('./module/claimparty/component/ChoosePayeeOrPayerDialog');
  },
  getSelectPayeeDialog() {
    return require('./module/claimparty/component/SelectPayeeDialog');
  },
  getClaimParty() {
    return require('./module/claimparty/component/ClaimParty');
  },
  getApprovalHistory() {
    return require('./module/common/component/ApprovalHistory');
  },
  getApprovalInfo() {
    return require('./module/common/component/ApprovalInfo');
  },
  //= ====================claim Common========================
  getReserveAlt() {
    return require('./module/reserve/alt');
  },
  getReserveAction() {
    return require('./module/reserve/action/ReserveAction');
  },
  //= ====================claim Common========================
  getCommonConfirmDialog() {
    return require('./module/common/component/CommonConfirmDialog');
  },

  //= ====================claim query========================
  getComplexQueryCriteria() {
    return require('./module/common/component/claimquery/ComplexQueryCriteria');
  },
  getQueryResult() {
    return require('./module/common/component/claimquery/QueryResult');
  },
  getNote() {
    return require('./module/common/component/notes/Note');
  },
  getClaimNoteAction() {
    return require('./module/common/action/ClaimNoteAction');
  },
  getModificationHistoryDialog() {
    return require('./module/common/component/ModificationHistoryDialog');
  },
  getSearchTaskMessageInstance() {
    const SearchTaskMessageClass = require('./module/common/action/SearchTaskMessage');
    return new SearchTaskMessageClass();
  },
  getClaimPartyActionProcess() {
    const ClaimPartyActionProcess = require('./module/claimparty/action/ClaimPartyActionProcess');
    return new ClaimPartyActionProcess();
  },
  getPolicyActionProcess() {
    const PolicyActionProcess = require('./module/common/action/PolicyActionProcess');
    return new PolicyActionProcess();
  },
  getClaimAuthorityPanel() {
    return require('./module/common/component/authority/ClaimAuthorityPanel');
  },
  getClaimQueryAction() {
    return require('./module/common/action/claimquery/ClaimQueryAction');
  },
  getQueryCommonPartyOptions() {
    return require('./module/claimparty/component/QueryCommonPartyOptions');
  },
  getUrl() {
    return require('./module/common/url/Url');
  },
  getClaimWorkFlowLeft() {
    return require('./module/common/component/desktop/ClaimWorkFlowLeft');
  },
  getFileUpload() {
    return require('./module/common/component/fileupload/FileUpload');
  },
  getFileUploadAction() {
    return require('./module/common/action/DocumentAction');
  },
  getClaimBaseInfo() {
    return require('./module/common/component/claimhandling/ClaimBaseInfo');
  },
  getClaimPartyAction() {
    return require('./module/claimparty/action/ClaimPartyAction');
  },
  getReinsuranceInfoTab() {
    return require('./module/common/component/claimhandling/ReinsuranceInfoTab');
  },
};

export default ClaimCommonExt;
