import { UIButton, UIBox } from 'RainbowUI';
import BindToMixin from 'react-binding';
import AppraisalVehicleAction from '../../action/AppraisalVehicleAction';
import AppraisalVehicleStore from '../../store/AppraisalVehicleStore';
import TaskTransferDialog from '../../../../../../common/module/common/component/TaskTransferDialog';

const BigNumber = require('bignumber.js');
const AppraisalVehicleInfo = require('./AppraisalVehicleInfo');
const AppraisalLaborCost = require('./AppraisalLaborCost');
const ApprovalHistory = require('./ApprovalHistory');
const AppraisalTotalCost = require('./AppraisalTotalCost');
const AppraisalSparePartCost = require('./AppraisalSparePartCost');
const ApprovalDecision = require('./ApprovalDecision');
const AppraisalHistory = require('./AppraisalHistory');
const AppraisalPropertyAction = require('../../action/AppraisalPropertyAction');


const AppraisalVehicle = React.createClass({
  mixins: [BindToMixin],

  getInitialState() {
    return {
      AppraisalData: {},
      AppraisalInfo: {},
      appraisalVehicle: null,
      AppraisalHistory: null,
      ApprovalHistoryVO: null,
      CurrencyCode: null,
      SynBtnStatus: false,
    };
  },

  componentWillMount() {
    AppraisalVehicleStore.listen(this.onStoreStateChange);

    AppraisalPropertyAction.loadClaimAppraisal(
      this.props.objectId,
      this.props.openForm
    );
  },

  componentDidMount() {
    this.synBtnEnable();
  },

  onStoreStateChange(storeState) {
    if (!_.isEmpty(storeState.AppraisalData)) {
      this.setState({ AppraisalData: storeState.AppraisalData });
      this.setState({
        AppraisalInfo: storeState.AppraisalData.AppraisalInfo,
      });
      this.setState({
        appraisalVehicle:
          storeState.AppraisalData.AppraisalInfo.appraisalVehicle,
      });
      this.setState({
        AppraisalHistory: storeState.AppraisalData.AppraisalHistory,
      });
      this.setState({
        ApprovalHistoryVO: storeState.AppraisalData.ApprovalHistoryVO,
      });
      this.setState({
        CurrencyCode: storeState.AppraisalData.CurrencyCode,
      });
    }
  },

  synBtnEnable() {
    const damageType = 'V';
    const enabled = AppraisalPropertyAction.synButtonIsEnable(
      this.props.taskInfo,
      damageType
    );

    this.setState({
      SynBtnStatus: enabled,
    });
  },

  getTotalCost(TotalCost) { },

  calTotalNetAmt(x, y, z) {
    const a = new BigNumber(x);
    const b = new BigNumber(y);
    const c = new BigNumber(z);
    const totalAmt = a.plus(b).plus(c);
    return totalAmt;
  },

  changSpareInTotalCost(selectValue) {
    this.state.AppraisalInfo.appraisalVehicle.TotalSparePartAmount = selectValue;

    this.refs.totalCost_ref.changeSparePartCost(selectValue);
    const totalLaborAmount =
      this.state.AppraisalInfo.appraisalVehicle.TotalLaborAmount ==
        null
        ? 0
        : this.state.AppraisalInfo.appraisalVehicle.TotalLaborAmount;
    const salvageAmount =
      this.state.AppraisalInfo.appraisalVehicle.TotalSalvageAmount ==
        null
        ? 0
        : this.state.AppraisalInfo.appraisalVehicle
          .TotalSalvageAmount;

    const totalAmt = this.calTotalNetAmt(
      totalLaborAmount,
      selectValue,
      salvageAmount
    );

    this.state.AppraisalInfo.appraisalVehicle.TotalNetAmount = totalAmt;
    this.refs.totalCost_ref.changeTotalNetCost(totalAmt);
  },

  changeLaborInTotalCost() {
    const data = this.state.AppraisalInfo.appraisalVehicle
      .TotalLaborAmount;

    this.refs.totalCost_ref.changeLaborCost(data);
    const totalSpareAmt = !this.state.AppraisalInfo.appraisalVehicle
      .TotalSparePartAmount
      ? 0
      : this.state.AppraisalInfo.appraisalVehicle
        .TotalSparePartAmount;
    const salvageAmount =
      this.state.AppraisalInfo.appraisalVehicle.TotalSalvageAmount ==
        null
        ? 0
        : this.state.AppraisalInfo.appraisalVehicle
          .TotalSalvageAmount;

    const totalAmt = this.calTotalNetAmt(
      data,
      totalSpareAmt,
      salvageAmount
    );
    this.state.AppraisalInfo.appraisalVehicle.TotalNetAmount = totalAmt;
    this.refs.totalCost_ref.changeTotalNetCost(totalAmt);
  },

  changeSalvageCost(data) {
    this.state.AppraisalInfo.appraisalVehicle.TotalSalvageAmount = Number(
      data
    );
    const totalSpareAmt = !this.state.AppraisalInfo.appraisalVehicle
      .TotalSparePartAmount
      ? 0
      : this.state.AppraisalInfo.appraisalVehicle
        .TotalSparePartAmount;
    const totalLaborAmount =
      this.state.AppraisalInfo.appraisalVehicle.TotalLaborAmount ==
        null
        ? 0
        : this.state.AppraisalInfo.appraisalVehicle.TotalLaborAmount;

    const totalAmt = this.calTotalNetAmt(
      totalLaborAmount,
      totalSpareAmt,
      Number(data)
    );
    this.state.AppraisalInfo.appraisalVehicle.TotalNetAmount = totalAmt;
    this.refs.totalCost_ref.changeTotalNetCost(totalAmt);
  },
  updateVehicleInfo(domainObject) {
    if (domainObject) {
      const { AppraisalInfo } = this.state;
      AppraisalInfo.appraisalVehicle = domainObject;
      this.setState({ AppraisalInfo });
    }
  },
  render() {
    const { policyInfo, subClaim, taskInfo } = this.props;
    const {
      AppraisalInfo,
      AppraisalData,
      ApprovalHistoryVO,
    } = this.state;
    const _self = this;
    const caseId = policyInfo.CaseId;
    const claimAppraisalVehicle = this.bindToState(
      'AppraisalInfo',
      'appraisalVehicle'
    );
    let isFirstParty = true;
    if (subClaim.damageParty === 'FP') {
      isFirstParty = true;
    } else {
      isFirstParty = false;
    }
    if (
      taskInfo &&
      taskInfo.TaskCode === 'ClaimAppraisalVehicleTask'
    ) {
      return (
        <div>
          <TaskTransferDialog ref="ClaimTaskTransferDialog" />
          <AppraisalVehicleInfo
            policyInfo={policyInfo}
            isFirstParty={isFirstParty}
            ref="vehicleInfo_ref"
            caseId={caseId}
            parentComponent={this}
            appraVehicleInfo={claimAppraisalVehicle}
            updateVehicleInfo={this.updateVehicleInfo.bind(this)}
          />
          <AppraisalLaborCost
            ref="laborCost_ref"
            parentComponent={this}
            appraisalVehicle={claimAppraisalVehicle}
          />
          <AppraisalSparePartCost
            ref="sparePartCost_ref"
            parentComponent={this}
            appraisalVehicle={claimAppraisalVehicle}
          />
          <AppraisalTotalCost
            ref="totalCost_ref"
            parentComponent={this}
            appraisalVehicle={claimAppraisalVehicle}
            appraisalInfo={AppraisalInfo}
          />
          {this.getOperationButtion()}
          <br />
          {/* <ApprovalHistory parentComponent={this} appraisalApproveHistory={this.state.ApprovalHistoryVO}/>
                     <AppraisalHistory parentComponent={this} appraisalHistory={this.state.AppraisalHistory}/> */}
        </div>
      );
    }
    if (
      taskInfo &&
      taskInfo.TaskCode === 'ClaimAppraisalVApprovalTask'
    ) {
      return (
        <div>
          <TaskTransferDialog ref="ClaimTaskTransferDialog" />
          <AppraisalVehicleInfo
            policyInfo={policyInfo}
            readOnly
            caseId={caseId}
            ref="vehicleInfo_ref"
            parentComponent={this}
            isFirstParty={isFirstParty}
            appraVehicleInfo={claimAppraisalVehicle}
          />
          <AppraisalLaborCost
            readOnly
            ref="laborCost_ref"
            parentComponent={this}
            appraisalVehicle={claimAppraisalVehicle}
          />
          <AppraisalSparePartCost
            readOnly
            ref="sparePartCost_ref"
            parentComponent={this}
            appraisalVehicle={claimAppraisalVehicle}
          />
          <AppraisalTotalCost
            readOnly
            ref="totalCost_ref"
            parentComponent={this}
            appraisalVehicle={claimAppraisalVehicle}
            appraisalInfo={AppraisalInfo}
          />
          <br />
          <ApprovalDecision
            appraisalData={AppraisalData}
            decision={this.bindToState(
              'AppraisalData',
              'ApprovalDecision'
            )}
            remark={this.bindToState('AppraisalData', 'ApproveMark')}
            taskInstanceId={taskInfo.TaskInstanceId}
          />
          <ApprovalHistory
            parentComponent={this}
            appraisalApproveHistory={ApprovalHistoryVO}
          />
          <AppraisalHistory
            parentComponent={this}
            appraisalHistory={_self.state.AppraisalHistory}
          />
          />
        </div>
      );
    }
    return (
      <div>
        <TaskTransferDialog ref="ClaimTaskTransferDialog" />
        <AppraisalVehicleInfo
          policyInfo={policyInfo}
          readOnly
          caseId={caseId}
          ref="vehicleInfo_ref"
          parentComponent={this}
          appraVehicleInfo={claimAppraisalVehicle}
        />
        <AppraisalLaborCost
          readOnly
          ref="laborCost_ref"
          parentComponent={this}
          appraisalVehicle={claimAppraisalVehicle}
        />
        <AppraisalSparePartCost
          readOnly
          ref="sparePartCost_ref"
          parentComponent={this}
          appraisalVehicle={claimAppraisalVehicle}
        />
        <AppraisalTotalCost
          readOnly
          ref="totalCost_ref"
          parentComponent={this}
          appraisalVehicle={claimAppraisalVehicle}
          appraisalInfo={AppraisalInfo}
        />
        {/* <ApprovalHistory parentComponent={this} appraisalApproveHistory={this.state.ApprovalHistoryVO}/>
                     <AppraisalHistory parentComponent={this} appraisalHistory={this.state.AppraisalHistory}/> */}
      </div>
    );
  },

  getOperationButtion() {
    const buttons = [];
    const { SynBtnStatus } = this.state;
    buttons.push(
      <UIButton
        id="Syncinfo_button"
        value={i18n.ClaimAppraisal.SyncInfo}
        onClick={this.synFIVehicle.bind(this, false)}
        enabled={SynBtnStatus}
      />
    );
    buttons.push(
      <UIButton
        id="save_appra_totalcost_button"
        value={i18n.ClaimAppraisal.Save}
        causeValidation="true"
        validationGroup="appraisalSubmit"
        onClick={this.saveAppraVehicle}
      />
    );
    buttons.push(
      <UIButton
        id="submit_appra_totalcost_button"
        value={i18n.ClaimAppraisal.SubmitTotal}
        onClick={this.submitAppraisalVehicle}
        validationGroup="appraisalSubmit"
        causeValidation="true"
      />
    );
    return <UIBox direction="right">{buttons}</UIBox>;
  },

  removeDataIndex() {
    const appraisalLaborItem = this.state.AppraisalInfo
      .appraisalVehicle.claimAppraisalVehicleLabor;
    if (appraisalLaborItem && appraisalLaborItem.length) {
      for (let i = 0; i < appraisalLaborItem.length; i += 1) {
        if (appraisalLaborItem[i].dataIndex) {
          delete appraisalLaborItem[i].dataIndex;
        }
      }
      this.state.AppraisalInfo.appraisalVehicle.claimAppraisalVehicleLabor = appraisalLaborItem;
    }

    const appraisalSparePartItem = this.state.AppraisalInfo
      .appraisalVehicle.claimAppraisalVehiclePart;
    if (appraisalSparePartItem && appraisalSparePartItem.length) {
      for (let i = 0; i < appraisalSparePartItem.length; i += 1) {
        if (appraisalSparePartItem[i].dataIndex) {
          delete appraisalSparePartItem[i].dataIndex;
        }
      }
      this.state.AppraisalInfo.appraisalVehicle.claimAppraisalVehiclePart = appraisalSparePartItem;
    }
  },

  saveAppraVehicle() {
    this.removeDataIndex();
    AppraisalPropertyAction.saveAppraisalProperty(
      this.state.AppraisalInfo
    );
  },

  submitAppraisalVehicle() {
    this.removeDataIndex();
    const tempData = this.state.AppraisalData;
    tempData.TaskInstanceId = this.props.taskInfo.TaskInstanceId;
    tempData.ApprovalHistoryVO = null;
    tempData.AppraisalHistory = [];
    AppraisalPropertyAction.submitAppraisalProperty(tempData);
  },

  synFIVehicle() {
    const _synModel = AppraisalVehicleAction.getVehicle(
      this.props.objectId
    );
    let p;
    const newSynModelState = this.state.AppraisalInfo
      .appraisalVehicle;

    for (p in _synModel) {
      newSynModelState[p] = _synModel[p];
    }
    if (_synModel) {
      if (_synModel.MakerYear) {
        newSynModelState.MakeYear = _synModel.MakerYear;
      }
    }

    this.forceUpdate();
  },
});
export default AppraisalVehicle;
