export default {
  ClaimCommon_i18n_en_US: require('./module/common/i18n/i18n-claim-common.en_US'),
  ClaimCommon_i18n_zh_CN: require('./module/common/i18n/i18n-claim-common.zh_CN'),
  ClaimCommon_i18n_ja_JP: require('./module/common/i18n/i18n-claim-common.ja_JP'),

  ClaimReserve_i18n_en_US: require('./module/reserve/i18n/i18n-claim-reserve.en_US'),
  ClaimReserve_i18n_zh_CN: require('./module/reserve/i18n/i18n-claim-reserve.zh_CN'),
  ClaimReserve_i18n_ja_JP: require('./module/reserve/i18n/i18n-claim-reserve.ja_JP'),

  ClaimParty_i18n_en_US: require('./module/claimparty/i18n/i18n-claim-party.en_US'),
  ClaimParty_i18n_zh_CN: require('./module/claimparty/i18n/i18n-claim-party.zh_CN'),
  ClaimParty_i18n_ja_JP: require('./module/claimparty/i18n/i18n-claim-party.ja_JP'),
};
