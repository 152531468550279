import alt from '../alt';
import ClaimPartyAction from '../action/ClaimPartyAction';

class ClmPartyTreeStore {
  constructor() {
    this.treeNodeList = [];
    this.partyAuth;

    this.bindListeners({
      handleTreeNodeList: ClaimPartyAction.INIT_DATA,
      handlePartyAuth: ClaimPartyAction.GET_PARTY_AUTH,
    });
  }

  handleTreeNodeList(treeNodeList) {
    this.treeNodeList = treeNodeList;
  }

  handlePartyAuth(partyAuth) {
    this.partyAuth = partyAuth;
  }
}

export default alt.createStore(ClmPartyTreeStore, ClmPartyTreeStore);
