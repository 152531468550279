import {
  UIText,
  UIParam,
  UILink,
  UISelect,
  UIDataTable,
  UIColumn,
  UIPanel,
  DataContext,
  MessageHelper,
} from 'RainbowUI';
import EditIndRelationShipDialog from './EditIndRelationShipDialog';
import EditOrgRelationShipDialog from './EditOrgRelationShipDialog';
import DefinedCodeTable from '../codetable/CodeTable';
import SearchForRelationShip from './searchForRelation/SearchForRelationShip';
import PartyConst from '../const/PartyConst';
import PartyAction from '../actions/PartyAction';
import EditIndRelationShipDialogReadOnly from './readonly/views/EditIndRelationShipDialogReadOnly';
import EditOrgRelationShipDialogReadOnly from './readonly/views/EditOrgRelationShipDialogReadOnly';

const _ = require('underscore');

const PartyIndRelationShipInfo = React.createClass({
  getInitialState() {
    let { party } = this.props;
    if (DataContext.get('Party')) {
      party = DataContext.get('Party');
    }
    let { PartyRelationShipList } = party;
    if (!PartyRelationShipList) {
      PartyRelationShipList = [];
    }
    _.forEach(
      PartyRelationShipList,
      (temppartyRelationShip, index) => {
        const partyRelationShip = temppartyRelationShip;
        partyRelationShip.DefinedIndex = index + 1;
      }
    );
    const totalNum = PartyRelationShipList.length;
    return {
      PartyRelationShipList,
      TotalNum: totalNum,
      party,
      readOnly: false,
    };
  },

  createRelationShipHandler() {
    const definedIndex = this.state.TotalNum + 1;
    const createPartyRelationShip = {
      '@type': 'PartyRelationShip-PartyRelationShip',
      DefinedIndex: definedIndex,
    };
    this.refs.editPartyRelationShipDialog.showDialog(
      createPartyRelationShip
    );
  },

  savePartyRelationShip(partyRelationShip) {
    const { PartyHistoryList } = this.state.party;
    if (partyRelationShip['@pk']) {
      const newPartyHistory = {
        '@type': 'PartyHistory-PartyHistory',
        BusinessObjectId: 75158352,
        HistoryType: 8,
      };
      PartyHistoryList.push(newPartyHistory);
    } else {
      const newPartyHistory = {
        '@type': 'PartyHistory-PartyHistory',
        BusinessObjectId: 75158352,
        HistoryType: 7,
      };
      PartyHistoryList.push(newPartyHistory);
    }
    const { PartyRelationShipList } = this.state;
    const definedIndex = partyRelationShip.DefinedIndex;
    for (let idx = 0; idx < PartyRelationShipList.length; idx += 1) {
      if (PartyRelationShipList[idx].DefinedIndex == definedIndex) {
        PartyRelationShipList.splice(idx, 1);
        break;
      }
    }
    PartyRelationShipList.push(partyRelationShip);
    const { party } = this.state;
    party.PartyRelationShipList = PartyRelationShipList;
    const partyParticipationList = party.PartyParticipationList;
    _.forEach(partyParticipationList, datas => {
      const data = datas;
      delete data.ParticipationPartyInfo;
      delete data.CompanyName;
      delete data.CountryDom;
      delete data.OrganizationIdNumber;
    });
    const partyAction = new PartyAction();
    const copyParty = partyAction.loadPartyByPartyId(party.PartyId);
    party.PartyAccountList = copyParty.PartyAccountList;
    party.PartyContactList = copyParty.PartyContactList;
    party.AddressVoList = copyParty.AddressVoList;
    let result = this.props.submitInfoHandler(party, 4);
    if (!(result === 'fail')) {
      _.forEach(
        result.PartyRelationShipList,
        (temppartyRelationShip, index) => {
          const partyRelationShip = temppartyRelationShip;
          partyRelationShip.DefinedIndex = index + 1;
        }
      );
      const resultList = result.PartyRelationShipList;
      this.setState({
        party: result,
        PartyRelationShipList: resultList,
        TotalNum: PartyRelationShipList.length + 1,
      });
    }
    if (result === 'fail') {
      const partyAction = new PartyAction();
      result = partyAction.loadPartyByPartyId(party.PartyId);
      _.forEach(
        result.PartyRelationShipList,
        (temppartyRelationShip, index) => {
          const partyRelationShip = temppartyRelationShip;
          partyRelationShip.DefinedIndex = index + 1;
        }
      );
      DataContext.put('Party', result);
      this.setState({
        party: result,
        PartyRelationShipList: result.PartyRelationShipList,
        TotalNum: result.PartyRelationShipList.length + 1,
      });
      return 'fail';
    }
    return 'success';
  },

  editRelationShipHandler() {
    const selectData = UIDataTable.getSelectedRecord(
      'partyRelationShipInfo'
    );
    if (!selectData.length) {
      MessageHelper.warning(
        i18n.PartyMsg.WarnMsg.Warn_0005,
        i18n.PartyMsg.WarnMsg.Warn_0003,
        MessageHelper.POSITION_TOP_RIGHT
      );
      return;
    }
    const partyRelationShip = selectData[0];
    this.refs.editPartyRelationShipDialog.showDialog(
      partyRelationShip,
      true
    );
  },

  viewHandler(clickEvent) {
    let partyRelationShip = {};
    if (!_.isEmpty(clickEvent.paramJson)) {
      partyRelationShip = clickEvent.paramJson[0].value;
    }
    this.refs.editPartyRelationShipDialogReadOnly.showDialog(
      partyRelationShip
    );
  },

  searchPartyName(partyRelationShip) {
    this.refs.searchIndSearchRelationShipDialog.showDialog(
      partyRelationShip
    );
  },
  render() {
    const _self = this;
    const { PartyRelationShipList, party } = this.state;
    const partyType = party.PartyType;
    let dialog;
    if (PartyConst.PARTY_TYPE_IND == partyType) {
      dialog = _self.renderIndDialog();
    } else dialog = _self.renderOrgDialog();
    return (
      <div>
        <UIPanel panelTitle={i18n.RelationShip}>
          <UIDataTable
            id="partyRelationShipInfo"
            value={PartyRelationShipList}
            functions={_self.buildTableFunction()}
            indexable="false"
          >
            <UIColumn selectionMode="single" />
            <UIColumn
              headerTitle={i18n.RelationshipType}
              render={data => {
                return (
                  <UISelect
                    model={data}
                    property="RelationShipType"
                    codeTable={DefinedCodeTable.getAllRelationShipType()}
                    io="out"
                  />
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.PartyName}
              render={data => {
                const partyName = _self.convertPartyName(
                  data.RelationShipPartyId
                );
                return (
                  <div>
                    <UILink
                      value={partyName}
                      onClick={this.viewHandler}
                    >
                      <UIParam name="data" value={data} />
                    </UILink>
                  </div>
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.IdOrCompanyRegNumber}
              render={data => {
                return (
                  <UIText model={data} property="IdNumber" io="out" />
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.Remarks}
              render={data => {
                return (
                  <UIText model={data} property="Remark" io="out" />
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.Status}
              render={data => {
                return (
                  <UISelect
                    model={data}
                    property="Status"
                    codeTable={DefinedCodeTable.getPartyStatus}
                    io="out"
                  />
                );
              }}
            />
          </UIDataTable>
          {dialog}
        </UIPanel>
      </div>
    );
  },

  setPartyRelationShip(partyRelationShip, partySearchType) {
    this.refs.editPartyRelationShipDialog.showDialog(
      partyRelationShip,
      false,
      partySearchType
    );
  },

  buildTableFunction() {
    const _self = this;
    return (
      <div>
        <UILink
          icon="fa fa-plus"
          size="lg"
          iconClass="add_iconbtn"
          tooltip={i18n.add}
          onClick={_self.createRelationShipHandler}
        />
        <UILink
          icon="fa fa-pencil"
          size="lg"
          iconClass="edit_iconbtn"
          tooltip={i18n.edit}
          onClick={_self.editRelationShipHandler}
        />
      </div>
    );
  },

  convertPartyName(relationShipPartyId) {
    const partyAction = new PartyAction();
    return partyAction.getPartyCodeTable(relationShipPartyId).text;
  },

  renderIndDialog() {
    const { readOnly } = this.state;
    return (
      <div>
        <EditIndRelationShipDialog
          ref="editPartyRelationShipDialog"
          submitHandler={this.savePartyRelationShip}
          searchPartyName={this.searchPartyName}
          readOnly={readOnly}
        />
        <EditIndRelationShipDialogReadOnly ref="editPartyRelationShipDialogReadOnly" />
        <SearchForRelationShip
          ref="searchIndSearchRelationShipDialog"
          submitHandler={this.setPartyRelationShip}
        />
      </div>
    );
  },

  renderOrgDialog() {
    const { readOnly } = this.state;
    return (
      <div>
        <EditOrgRelationShipDialog
          ref="editPartyRelationShipDialog"
          submitHandler={this.savePartyRelationShip}
          searchPartyName={this.searchPartyName}
          readOnly={readOnly}
        />
        <EditOrgRelationShipDialogReadOnly ref="editPartyRelationShipDialogReadOnly" />
        <SearchForRelationShip
          ref="searchIndSearchRelationShipDialog"
          submitHandler={this.setPartyRelationShip}
        />
      </div>
    );
  },
});

export default PartyIndRelationShipInfo;
