export default {
  Branch: -103,
  ClaimPayFinal: 1000,
  LossCause: 1006,
  SubclaimType: 1007,
  ClaimReserveType: 1008,
  CLAIMTASK: 1023,
  Prioriry: 1024,
  ClaimReserveStatus: 1010,
  ClaimCloseType: 1011,
  ClaimChangeType: 1012,
  ClaimCloseReason: 1013,
  ClaimCoverageType: 1014,
  ProductTypeCode: 1015,
  ClaimFnolTypeCode: 1016,
  ClaimProductCode: 1017,
  ClaimDamagePartyCode: 1018,
  ClaimDamageTypeCode: 1019,
  ClaimAccidentCode: 1025,
  ClaimContactType: 1026,
  ClaimLiability: 1030,
  ClaimRecoverFrom: 77583910,
  ClaimValidationDecision: 1031,
  ClaimDamageTypeCondition: 1027,
  ClaimPlaceOfInspaction: 1032,
  ClaimCurrencyCode: -104,
  ClaimPolicyInsured: 1021,
  ClaimApproveDecison: 1022,
  ClaimPartyType: 1035,
  PartyAddressType: 101751,
  ClaimCoverageCodeName: 1033,
  ClaimRejectReason: 1034,
  ClaimFileType: 1028,
  ClaimReserveSign: 1029,
  ClaimYesOrNo: 1036,
  ClaimUser: -106,
  ClaimNoteCategory: 1038,
  ClaimPartyRole: 1039,
  ClaimPartyCertiType: 1040,
  ClaimPartyOrgType: 1041,
  ClaimLossStatus: 1042,
  ClaimAssignType: 1043,
  ClaimPaymentStatus: 1044,
  ClaimProduct: 1045,
  ClaimPartyCountry: 1046,
  ClaimPartyAccountType: 1047,
  ClaimCauseOfReopening: 1048,
  ClaimMsgType: 1049,
  PlateFormPayMode: 75283381,
  PlateCommonFormPayMode: 600020,
  PlateFormYesNoYN: 1834755,
  ClaimObjectDamageSeverity: 1050,
  ClaimParty: 1051,
  ClaimCoLeader: 1052,
  ClaimCoFollower: 1053,
  ClaimLitigationStatus: 1054,
  ClaimLitigationType: 1055,
  ClaimFraudQuestion: 1056,
  ClaimFraudSubject: 1057,
  ClaimAddressType: 1058,
  PubBank: 600022,
  ClaimTaxCode: 1061,
  ClaimPaymentType: 1009,
  ClaimDamageSeverityThreshold: 74915434,
  ClaimStatus: 98100257238,
  ClaimPartyCity: 1605415,

  ClaimDecision: 8990259,
  ClaimInjuryType: 8990131,
  ClaimDisabilityGrade: 8990070,
  ClaimTreatment: 8990071,
  Gender: 936959,

  ClaimWorkType: 9612686,
  ClaimDamagePart: 9559196,

  ClaimLossItems: 10284897,
  ClaimDamageType: 10554077,

  ClaimVehicleType: 10790876,
  ClaimVehicleColor: 10833219,
  ClaimCoverageCodePACode: 1501,

  DocumentType: 73858556,
  DocumentSubType: 75693084,

  SettlementType: 75800521,
  ClaimNoteUserType: 98100257247
};
