import {
  DataContext,
  UpdateContext,
  MessageHelper,
  UIDialog,
  AjaxUtil,
} from 'RainbowUI';
import {
  RestStatus,
  ClaimMessageForamtUtils,
} from 'ClaimCommonConstant';
import alt from '../alt';
import TreeNode from '../constant/TreeNodeCache';

const url = require('../url/Url');

class ProductDialogAction {
  saveProduct() {
    const data = DataContext.get('showProduct');
    const treeNode = TreeNode.TreeCache.get('TreeNode');
    data.ProductTypeCode = treeNode.treeNodeCode;
    const saveUrl = url.ClaimProductUpdate;
    delete data.InsertBy;
    delete data.InsertTime;
    delete data.UpdateBy;
    delete data.UpdateTime;
    delete data.dataIndex;
    delete data.ClaimCoverageEntities;
    let result = null;
    AjaxUtil.doPost(saveUrl, data, {
      async: false,
      done(data) {
        result = data.Model;
        if (data.Status == RestStatus.OK) {
          UIDialog.hide('newProductDialog');
          MessageHelper.success(
            ClaimMessageForamtUtils.formatI18NMsg(
              i18n.ClaimCommon.SaveSuccess,
              i18n.ClaimProduct.Product
            )
          );
          UpdateContext.forceUpdate('productList');
          UpdateContext.forceUpdate('productTreeUdate_id');
        } else {
          MessageHelper.error(data.Status);
        }
      },
      fail(data) {
        MessageHelper.error(data.responseText);
      },
    });
    return result.Status;
  }

  save(datas, isUpdate) {
    const data = datas;
    const treeNode = TreeNode.TreeCache.get('TreeNode');
    data.ProductTypeCode = treeNode.treeNodeCode;
    let saveUrl = url.ClaimProductSave;
    if (isUpdate === 'out') saveUrl = url.ClaimProductUpdate;
    delete data.dataIndex;
    delete data.ClaimCoverageEntities;
    AjaxUtil.doPost(saveUrl, data, {
      async: true,
      done(data) {
        if (data.Status == RestStatus.OK) {
          MessageHelper.success(
            ClaimMessageForamtUtils.formatI18NMsg(
              i18n.ClaimCommon.SaveSuccess,
              i18n.ClaimProduct.Product
            )
          );
          UIDialog.hide('showProductDialog');
          UpdateContext.forceUpdate('productList');
        } else if (data.Status == RestStatus.BLOCK) {
          MessageHelper.error(
            ClaimMessageForamtUtils.formatI18NMsg(
              i18n.ClaimCommon.IsExist,
              i18n.ClaimProduct.Product
            )
          );
        } else {
          MessageHelper.error(data.Status.Messages.Message);
        }
      },
      fail() {
        MessageHelper.error(
          i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
        );
      },
    });
  }

  initProduct(productId) {
    let result = null;
    AjaxUtil.doGet(
      `${url.ClaimProductGetProductDetail}/${productId}`,
      {},
      {
        async: false,
        done(data) {
          if (data.Status == RestStatus.OK) {
            result = data.Model;
          } else {
            MessageHelper.error(data.Status);
          }
        },
        fail() {
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
        },
      }
    );
    DataContext.put('showProduct', result);
    UpdateContext.forceUpdate('newProductDialogUIUpdate');

    UIDialog.show('newProductDialog');
  }

  getProduct(productId) {
    let result = null;
    AjaxUtil.doGet(
      `${url.ClaimProductGetProductDetail}/${productId}`,
      {},
      {
        async: false,
        done(data) {
          if (data.Status == RestStatus.OK) {
            result = data.Model;
          } else {
            MessageHelper.error(data.Status);
          }
        },
        fail() {
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
        },
      }
    );
    DataContext.put('showProduct', result);
  }
}
export default alt.createActions(ProductDialogAction);
