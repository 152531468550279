import HO48187912936 from './HO48187912936';
import HO48187912937 from './HO48187912937';


export default {
  HO48187912936,
  HO48187912937,
  PolicyList: [
    {
      PolicyNo: "HO48187912937",
      ProductCode: "2020001",
      EffectiveDate: "2020-03-01",
      ExpiryDate: "2021-03-01",
      PolicyHolder: "Henry Damon",
      PolicyStatus: 2,
    },
    {
      PolicyNo: "HO48187912936",
      ProductCode: "2020001",
      EffectiveDate: "2019-11-29",
      ExpiryDate: "2020-11-29",
      PolicyHolder: "Sam Lee",
      PolicyStatus: 2,
    },
  ],
};
