import {
  UIText,
  UIParam,
  UIColumn,
  UILink,
  UIDataTable,
  UIUpdatePanel,
  UpdateContext,
  UISelect,
  UIDateTimePicker,
  UICurrency,
} from 'RainbowUI';
import ClaimCommonExt from 'ClaimCommonExt';
import CodeTableAction from '../../action/CodeTableAction';
import CodeTableCstUI from '../../constant/CodeTableCstUI';

const NoticeOfLossAction = require('../../../../../notice/module/notice/common/action/NoticeOfLossAction');
const ClaimQueryAction = ClaimCommonExt.getClaimQueryAction();
const QueryResult = React.createClass({
  getInitialState() {
    return { searchConditions: {}, serachResult: {} };
  },

  storeChangeStatus(searchConditions) {
    this.setState({ searchConditions });
  },

  clear() {
    UpdateContext.forceUpdate('claimQueryUpdate');
    this.setState({ searchConditions: {} });
  },
  getClaimQueryList(pageindex, pagesize) {
    if (
      _.isEmpty(this.state.searchConditions.Conditions) &&
      _.isEmpty(this.state.searchConditions.FuzzyConditions) &&
      _.isEmpty(this.state.searchConditions.OrConditions) &&
      _.isEmpty(this.state.searchConditions.BetweenConditions)
    ) {
      return { count: 0, result: [] };
    }
    const queryResult = ClaimQueryAction.getclaimlist(
      this.state.searchConditions,
      pageindex,
      pagesize
    );

    return queryResult;
  },

  onClickLink(event) {
    const data = event.getParameter('data');
    const caseId = ClaimQueryAction.getCaseIdByClaimNo(data);
    window.location.hash = `#/handling/tasks?caseId=${caseId.result}&readOnly=false&a=a`;
  },

  render() {
    const self = this;
    return (
      <UIUpdatePanel id="claimQueryUpdate">
        <UIDataTable
          indexable="false"
          headerTitle={i18n.ClaimCommon.ClaimResult}
          provider={this.getClaimQueryList}
          sortable="true"
        >
          <UIColumn
            headerTitle={i18n.ClaimCommon.ClaimNumber}
            value="ClaimNo"
            render={data => {
              return (
                <UILink
                  value={data.ClaimNo}
                  onClick={self.onClickLink}
                >
                  <UIParam value={data.ClaimNo} name="data" />
                </UILink>
              );
            }}
            width="14%"
          />

          <UIColumn
            headerTitle={i18n.ClaimCommon.DateOfLoss}
            value="AccidentTime"
            width="10%"
          >
            <UIDateTimePicker io="out" format="MM/DD/YYYY" />
          </UIColumn>
          <UIColumn
            headerTitle={i18n.ClaimCommon.PolicyNumber}
            value="PolicyNo"
            width="14%"
          />
          <UIColumn
            headerTitle={i18n.ClaimCommon.PolicyHolderName}
            value="PolicyholderName"
            width="10%"
          />
          <UIColumn
            headerTitle={i18n.ClaimCommon.PolicyBranch}
            value="PolicyBranch"
            width="22%"
          >
            <UISelect
              codeTable={NoticeOfLossAction.getBranchCodetable()}
              io="out"
            />
          </UIColumn>
          <UIColumn
            headerTitle={i18n.ClaimCommon.ClaimStatus}
            value="StatusCode"
            width="6%"
          >
            <UISelect
              codeTable={CodeTableAction.loadDataList(
                CodeTableCstUI.ClaimStatus,
                null
              )}
              io="out"
            />
          </UIColumn>

          <UIColumn
            headerTitle={i18n.ClaimCommon.TotalIncurred}
            value="TotalAmount"
            width="12%"
            render={data => {
              return (
                <div style={{ float: 'left' }}>
                  <UICurrency
                    id="CURRENCY_CODE_TotalIncurred"
                    io="out"
                    unit={window.EU00.getCurrencySignByCurrencyCode(
                      data.CurrencyCode
                    )}
                    value={data.TotalAmount}
                  />
                </div>
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.ClaimCommon.LastReviewDate}
            value="LastReviewDate"
            width="2%"
          >
            <UIDateTimePicker io="out" format="MM/DD/YYYY" />
          </UIColumn>
          <UIColumn
            headerTitle={i18n.ClaimCommon.DaysSinceLastReview}
            width="10%"
            render={data => {
              if (data.LastReviewDate) {
                const myDate = new ServerDate();
                const strDate = `${myDate.getFullYear()}/${myDate.getMonth() +
                  1}/${myDate.getDate()}`;
                const d1 = new Date(strDate);

                const lastRe = new Date(data.LastReviewDate);
                const strDate2 = `${lastRe.getFullYear()}/${lastRe.getMonth() +
                  1}/${lastRe.getDate()}`;
                const d2 = new Date(strDate2);

                const time = d1.getTime() - d2.getTime();
                const betweenDays = parseInt(
                  time / (1000 * 60 * 60 * 24),
                  10
                );
                return <UIText io="out" value={betweenDays} />;
              }
            }}
          />
        </UIDataTable>
      </UIUpdatePanel>
    );
  },
});
export default QueryResult;
