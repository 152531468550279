import { AjaxUtil } from 'RainbowUI';

const Url = require('../url/Url');

export default {
  saveBothDate(data) {
    const dtd = $.Deferred();

    const url = `${Url.ChequeIssueDate}?settlePayeeId=${data.SettlePayeeId}&chequeIssueDate=${data.SettlementDetailDialogInfo.settlementIssueDate}&chequeReceiveDate=${data.SettlementDetailDialogInfo.settlementReceiveDate}`;
    AjaxUtil.doGet(
      url,
      {},
      {
        async: false,
        done(data) {
          if (data.Status === 'OK') {
            dtd.resolve(data);
          } else {
            dtd.reject(data);
          }
        },
        fail(jqXHR) {
          dtd.reject({
            status: jqXHR.status,
            responseText: jqXHR.responseText,
          });
        },
      }
    );
    return dtd.promise();
  },

  saveSettlementReceiveDate(data) {
    const deferred = $.Deferred();
    const url = `${Url.ChequeReceiveDate}?settlePayeeId=${data.SettlePayeeId}&chequeReceiveDate=${data.SettlementDetailDialogInfo.settlementReceiveDate}`;
    AjaxUtil.doGet(
      url,
      {},
      {
        async: false,
        done(data, jqXHR) {
          if (data) {
            deferred.resolve(data);
          } else {
            deferred.reject({
              status: jqXHR.status,
              responseText: jqXHR.responseText,
            });
          }
        },
        fail(jqXHR) {
          deferred.reject({
            status: jqXHR.status,
            responseText: jqXHR.responseText,
          });
        },
      }
    );
    return deferred.promise();
  },
};
