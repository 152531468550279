import { MessageHelper } from 'RainbowUI';
import ClaimCommonExt from 'ClaimCommonExt';
import alt from '../alt';

const noticeService = require('../service/NoticeService');

class NoticePoolAction {
  getNoticeList(pageIndex, pageSize, tmppolicySearchCriteria) {
    const policySearchCriteria = tmppolicySearchCriteria;
    if (env.IS_MOCK) return;
    policySearchCriteria.Start = pageIndex;
    policySearchCriteria.Limit = pageSize;
    let searchResult = null;
    noticeService.queryNoticeList(policySearchCriteria).then(
      data => {
        searchResult = data;
      },
      ex => {
        MessageHelper.error(
          JSON.stringify(ex),
          '',
          MessageHelper.POSITION_TOP_FULL_WIDTH
        );
      }
    );
    const resultObj = {
      count: searchResult.Total,
      result: searchResult.Content,
    };
    const searchTaskMessage = ClaimCommonExt.getSearchTaskMessageInstance();
    searchTaskMessage.init(resultObj);
    searchTaskMessage.showMessage();
    return resultObj;
  }

  editNotice(event) {
    const noticeId = event.getParameter('noticeId');
    noticeService.loadSyn(noticeId).then(
      data => {
        window.location.hash = `#/notice/noticeofloss?noticeId=${noticeId}`;
      },
      ex => {
        MessageHelper.error(
          JSON.stringify(ex),
          '',
          MessageHelper.POSITION_TOP_FULL_WIDTH
        );
      }
    );
  }

  editNoticeById(noticeId) {
    if (
      noticeId === null ||
      noticeId === undefined ||
      noticeId === ''
    ) {
      return false;
    }
    noticeService.loadSyn(noticeId).then(
      data => {
        window.location.hash = `#/notice/noticeofloss?noticeId=${noticeId}`;
      },
      ex => {
        MessageHelper.error(
          JSON.stringify(ex),
          '',
          MessageHelper.POSITION_TOP_FULL_WIDTH
        );
      }
    );
  }

  searchNotice(noticeEntityCriteria) {
    this.dispatch(noticeEntityCriteria);
  }
}
export default alt.createActions(NoticePoolAction);
