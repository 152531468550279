import { AjaxUtil } from 'RainbowUI';
import Url from '../url/Url';

export default {
  loadProductCodeTree() {
    const deferred = $.Deferred();
    AjaxUtil.doGet(
      Url.ProductTree,
      {},
      {
        async: true,
        done(data) {
          if (data.Status === 'OK') {
            deferred.resolve(data.Model);
          } else {
            deferred.reject(data.Messages[0].Message);
          }
        },
        fail(err) {
          deferred.reject(err);
        },
      }
    );
    return deferred.promise();
  },
};
