import {
  DataContext,
  UpdateContext,
  MessageHelper,
  UIConfirmDialog,
  UIDialog,
  AjaxUtil,
} from 'RainbowUI';
import {
  RestStatus,
  ClaimMessageForamtUtils,
} from 'ClaimCommonConstant';
import TreeNode from '../constant/TreeNodeCache';

const url = require('../url/Url');

export default {
  getProductTypeList(pageindex, pagesize) {
    let result = {};
    const productLineCode = TreeNode.TreeCache.get('TreeNode')
      .treeNodeCode;
    AjaxUtil.doGet(
      `${url.ClaimProductTypeGetProductTypeList}/${pageindex}/${pagesize}/${productLineCode}`,
      {},
      {
        async: false,
        done(data) {
          result = data.Model;
        },
        fail(data) {
          MessageHelper.error(data.responseText);
          result = data;
        },
      }
    );
    return { count: result.Count, result: result.Module };
  },
  newProductType() {
    const productType = {};
    productType.productTypeCode = '';
    productType.productTypeName = '';
    productType.extProductTypeCode = '';
    productType.productTypeDesc = '';
    DataContext.put('showProductType', productType);
    productType.out = 'in';
    DataContext.put('showProductType', productType);
    UIDialog.show('showProductTypeDialog');
  },
  removeProductTypeDialog(event) {
    const data = event.getParameter('data');
    data.removeType = 'productType';
    DataContext.put('data', data);
    UIConfirmDialog.show('RemoveConfirmDialog');
  },

  removeProductType() {
    const data = DataContext.get('data');
    AjaxUtil.doGet(
      `${url.ClaimProductTypeDelete}/${data.ProductTypeCode}`,
      {},
      {
        async: false,
        done(data) {
          if (data.Status == RestStatus.OK) {
            MessageHelper.success(
              ClaimMessageForamtUtils.formatI18NMsg(
                i18n.ClaimCommon.DeleteSuccess,
                i18n.ClaimProduct.ProductType
              )
            );
            UpdateContext.forceUpdate('treeCfgUIupdateId');
            UpdateContext.forceUpdate('productTypeListId');
          } else {
            MessageHelper.error(
              ClaimMessageForamtUtils.formatI18NMsg(
                i18n.ClaimCommon.DeleteFail,
                i18n.ClaimProduct.ProductType
              )
            );
          }
        },
        fail() {
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
        },
      }
    );
  },
};
