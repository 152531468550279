import {
  UISelect,
  UIDateTimePicker,
  UIText,
  UISmartPanelGrid,
  UIPanel,
} from 'RainbowUI';
import React from 'react';
import { CodeTableAction, CodeTableCstUI } from 'ClaimCommonConstant';

const ClaimBaseInfo = React.createClass({
  render() {
    const { claimEntity: $claimEntity } = this.props;
    return (
      <div>
        <UIPanel panelTitle={i18n.ClaimCommon.ClaimInfo}>
          <UISmartPanelGrid column="3" widthAllocation="5,7">
            <UIText
              label={`${i18n.ClaimCommon.PolicyNumber} `}
              enabled="false"
              value={$claimEntity.PolicyEntity.PolicyNo}
              io="out"
            />
            <UISelect
              label={`${i18n.ClaimCommon.ProductName} `}
              enabled="false"
              value={$claimEntity.PolicyEntity.ProductCode}
              codeTable={CodeTableAction.loadDataList(
                CodeTableCstUI.ClaimProductCode,
                null
              )}
              io="out"
            />
            <UIText
              label={`${i18n.ClaimCommon.PolicyHolderName} `}
              value={
                $claimEntity.PolicyHolderParty
                  ? $claimEntity.PolicyHolderParty.PartyName
                  : ''
              }
              io="out"
            />
            <UIText
              label={`${i18n.ClaimCommon.ClaimNumber} `}
              enabled="false"
              value={$claimEntity.ClaimNo}
              io="out"
            />
            <UIDateTimePicker
              placeHolder="MM/DD/YYYY HH:mm:ss"
              format="MM/DD/YYYY HH:mm:ss"
              label={`${i18n.ClaimCommon.DateOfNotice} `}
              value={$claimEntity.NoticeTime}
              io="out"
            />
          </UISmartPanelGrid>
        </UIPanel>
      </div>
    );
  },
});
export default ClaimBaseInfo;
