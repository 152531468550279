export default {
  CodeTableCstUI: require('./module/common/constant/CodeTableCstUI'),
  ClaimCstUI: require('./module/common/constant/ClaimCstUI'),
  CodeTableAction: require('./module/common/action/CodeTableAction'),
  UITreeSelect: require('./module/common/component/treeSelectComponent/UITreeSelect'),
  ClaimMessageForamtUtils: require('./module/common/util/ClaimExceptionUtils'),
  ClaimCommon_Url: require('./module/common/url/Url'),
  RestStatus: {
    OK: 'OK',
    BLOCK: 'BLOCK',
    ERROR: 'ERROR',
    WARN: 'WARN',
  },

  ClaimMenu: require('./module/common/constant/ClaimMenu'),

  name: 'ClaimCommonConstant',
};
