import alt from '../alt';
import ProductCfgTreeAction from '../action/ProductCfgTreeAction';

class DefaultReserveStore {
  constructor() {
    this.bindListeners({
      handleInitProductTreeNode: ProductCfgTreeAction.LOAD_NODE,
    });
    this.state = {
      productCode: '',
      productLineCode: '',
    };
  }

  handleInitProductTreeNode(ProductTreeEntity) {
    const node = ProductTreeEntity;
    if (node.treeNodeUrl === 'defaultReserve') {
      this.state.productLineCode = node.objectMap.plcode;
      this.state.productCode = node.objectMap.pId;
    }
  }
}
export default alt.createStore(
  DefaultReserveStore,
  DefaultReserveStore
);
