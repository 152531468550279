import { UIDataTable, UIText, UIColumn, UINumber } from 'RainbowUI';

const PolicyDetailCoverageInfo = React.createClass({
  getInitialState() {
    return { coveragelist: this.props.coveragelist };
  },

  componentWillReceiveProps(nextProps) {
    this.setState({ coveragelist: nextProps.coveragelist });
  },
  getParentComponent() {
    return this;
  },

  LimitDedutInfoCoverage(event) {
    const data = event.getParameter('data');

    this.props.parentComponent.refs.LimitDeductDialog_ref.showLimitDeductDialog(
      data
    );
  },

  render() {
    const self = this;
    return (
      <div>
        <UIDataTable
          id="coverageList"
          value={self.props.coveragelist}
          indexable="false"
          infoable="true"
          isHeading="true"
          pageable="false"
          headerTitle={i18n.ClaimCommon.CoverageList}
        >
          <UIColumn
            headerTitle={i18n.ClaimCommon.CoverageName}
            value="CoverageName"
          />

          <UIColumn
            headerTitle={i18n.ClaimCommon.SumInsured}
            value="SumInsured"
          >
            <UINumber
              id="NUMBER_CODE_SumInsured"
              io="out"
              value="SumInsured"
            />
          </UIColumn>
          <UIColumn
            headerTitle={i18n.ClaimCalculation.LimitAndDeductionInfo}
            render={data => {
              let limitArray;
              if (data.PolicyLimit)
                limitArray = data.PolicyLimit[0].PolicyLimitValueList;
              let dedutArray;
              if (data.PolicyDeductible)
                dedutArray = data.PolicyDeductible[0].PolicyDeductibleValueList;

              let limit;
              let dedut;
              if (limitArray && limitArray.length > 0) {
                limit = limitArray[0];
              }
              if (dedutArray && dedutArray.length > 0) {
                dedut = dedutArray[0];
              }
              let limintInfo = '';
              if (limit) {
                limintInfo = 'Limit Info    ' + limit.LimitValue;
              }
              let dedutInfo = '';
              if (dedut) {
                dedutInfo = ' ; Deduction Info   ' + dedut.DeductibleValue;
              }
              const colo = '#2196f3';
              return (
                <UIText
                  value={limintInfo + dedutInfo}
                  io="out"
                  style={{ color: colo }}
                />
              );
            }}
          />
        </UIDataTable>
      </div>
    );
  },
});
export default PolicyDetailCoverageInfo;
