import { MessageHelper, CodeTable } from 'RainbowUI';
import moment from 'moment';
import CommonService from '../service/CommonService';
import DocumentFormat from '../component/print/DocumentFormat';
import CommonAction from './CommonAction';

const ClaimLetterAction = {
  queryClaim(
    FuzzyQueryConditions,
    QueryConditions,
    pageindex,
    pagesize
  ) {
    const newQueryConditions = ClaimLetterAction.buildNewQueryConditions(
      FuzzyQueryConditions,
      QueryConditions
    );
    const orConditions = ClaimLetterAction.buildOrConditions(
      FuzzyQueryConditions,
      QueryConditions
    );
    const fuzzyQueryConditions = ClaimLetterAction.buildFuzzyQueryConditions(
      FuzzyQueryConditions
    );
    const betweenConditions = ClaimLetterAction.buildBetweenConditions(
      QueryConditions
    );
    const conditions = {
      Module: 'ClaimCase',
      PageNo: Math.ceil(pageindex / pagesize),
      PageSize: pagesize,
      SortField: 'index_time',
      SortType: 'desc',
      Conditions: newQueryConditions,
      FuzzyConditions: fuzzyQueryConditions,
    };
    const policyResult = CommonService.querySolrEntity(conditions);
    return policyResult;
  },
  queryAuditTrail(transactionNo, transactionType, documentType) {
    return CommonService.queryAuditTrail(
      transactionNo,
      transactionType,
      documentType
    );
  },

  buildOrConditions(FuzzyQueryConditions, QueryConditions) {
    const orConditions = {};
    if (QueryConditions.QuotePolicyNo) {
      orConditions.QuotePolicyNo = {
        QuotationNo: QueryConditions.QuotePolicyNo,
        PolicyNo: QueryConditions.QuotePolicyNo,
      };
    }
    if (QueryConditions.MasterPolicyNo) {
      orConditions.MasterPolicyNo = {
        PolicyNo: QueryConditions.MasterPolicyNo,
      };
    }
    return orConditions;
  },
  buildNewQueryConditions(FuzzyQueryConditions, tmpQueryConditions) {
    const QueryConditions = tmpQueryConditions;
    if (QueryConditions) {
      if (QueryConditions.ProcessOrganId)
        QueryConditions.ProcessOrganId *= 1;
      if (QueryConditions.PolicyBranch)
        QueryConditions.PolicyBranch *= 1;
    }
    return QueryConditions;
  },
  buildHistoryQueryConditions(FuzzyQueryConditions, QueryConditions) {
    let productId;
    if (QueryConditions.ProductId) {
      productId = Number(QueryConditions.ProductId);
    }
    let policyStatus;
    if (QueryConditions.PolicyStatus) {
      policyStatus = Number(QueryConditions.PolicyStatus);
    }
    let policyId;
    if (QueryConditions.PolicyId) {
      policyId = Number(QueryConditions.PolicyId);
    }
    const HistoryQueryConditions = {
      ProductId: productId,
      IsRenewalPolicy: QueryConditions.IsRenewalPolicy,
      AgentCode: QueryConditions.AgentCode,
      PlateNo: QueryConditions.PlateNo,
      PolicyStatus: policyStatus,
      TransactionType: QueryConditions.TransactionType,
      DocumentType: QueryConditions.DocumentType,
      Status: parseInt(QueryConditions.Status, 10),
      PolicyId: policyId,
      PrintFormat: QueryConditions.PrintFormat,
    };
    return HistoryQueryConditions;
  },
  buildFuzzyQueryConditions(tmpFuzzyQueryConditions) {
    const FuzzyQueryConditions = tmpFuzzyQueryConditions;
    if (FuzzyQueryConditions.ProcessOrganId) {
      FuzzyQueryConditions.ProcessOrganId *= 1;
    }
    if (FuzzyQueryConditions.OwnerNames) {
      FuzzyQueryConditions.OwnerNames = `,${FuzzyQueryConditions.OwnerNames},`;
    }
    const commonAction = new CommonAction();
    const user = commonAction.renderUser();
    if (user.DirectUser !== 'Y') {
      FuzzyQueryConditions.PermitOrgId = `,${user.OrgId},`;
    }
    return FuzzyQueryConditions;
  },
  buildBetweenConditions(QueryConditions) {
    let effectiveDateFrom;
    if (QueryConditions.EffectiveDateFrom) {
      effectiveDateFrom = moment(
        QueryConditions.EffectiveDateFrom
      ).format('YYYY-MM-DD hh:mm:ss');
    }
    let effectiveDateTo;
    if (QueryConditions.EffectiveDateTo) {
      effectiveDateTo = moment(
        QueryConditions.EffectiveDateTo
      ).format('YYYY-MM-DD 23:59:59');
    }
    let expiryDateFrom;
    if (QueryConditions.ExpiryDateFrom) {
      expiryDateFrom = moment(QueryConditions.ExpiryDateFrom).format(
        'YYYY-MM-DD hh:mm:ss'
      );
    }
    let expiryDateTo;
    if (QueryConditions.ExpiryDateTo) {
      expiryDateTo = moment(QueryConditions.ExpiryDateTo).format(
        'YYYY-MM-DD 23:59:59'
      );
    }

    const betweenConditions = {
      EffectiveDate_from: effectiveDateFrom,
      EffectiveDate_to: effectiveDateTo,
      ExpiryDate_from: expiryDateFrom,
      ExpiryDate_to: expiryDateTo,
    };
    return betweenConditions;
  },

  generateExpiryDate(even) {
    const effectiveDateTo = even.QueryConditions.EffectiveDateTo;
    const effectiveDateFrom = even.QueryConditions.EffectiveDateFrom;
    const { ExpiryDateFrom } = even.QueryConditions;
    const { ExpiryDateTo } = even.QueryConditions;

    if (effectiveDateFrom > effectiveDateTo) {
      MessageHelper.error(i18n.PrintMessage.ErrorMsg.Error_0001, '');
    }
    if (ExpiryDateFrom > ExpiryDateTo) {
      MessageHelper.error(i18n.PrintMessage.ErrorMsg.Error_0002, '');
    }
  },

  getClaimPrintContent(caseId, seq) {
    const claim = CommonService.loadClaimInfo(caseId);
    const objectList = claim.ObjectList;
    delete claim.ObjectList;
    let currentSelType = null;
    if (objectList && seq) {
      _.each(objectList, type => {
        if (type.SeqNo == seq) {
          currentSelType = type;
        }
      });
      claim.ObjectList = [currentSelType];
    }
    const printContent = JSON.stringify(claim);
    return printContent;
  },

  onClaimPrint(
    transType,
    transPage,
    productCode,
    CaseId,
    printTypes,
    tsno2,
    tsno3,
    seq,
    tsno4
  ) {
    const claim = CommonService.loadClaimInfo(CaseId);
    if (!claim) {
      return;
    }

    const objectList = claim.ObjectList;
    delete claim.ObjectList;
    let currentSelType = null;
    if (objectList && seq) {
      _.each(objectList, type => {
        if (type.SeqNo == seq) {
          currentSelType = type;
        }
      });
      claim.ObjectList = [currentSelType];
    }

    const doclist = [];
    const rulelist = [];

    _.each(printTypes, obj => {
      doclist.push(obj.DocType);
      rulelist.push(obj.RuleId);
    });
    let printFormat = DocumentFormat.PDF;
    if (printTypes['0'].WordSelected == true) {
      printFormat = DocumentFormat.Word;
    }
    const printContent = JSON.stringify(claim);
    const printParam = {
      TransactionType: transType,
      TransactionPage: transPage,
      TransactionNo: claim.CaseId,
      TransactionNo2: tsno2,
      TransactionNo3: tsno3,
      TransactionNo4: tsno4,
      Doclist: doclist,
      Rulelist: rulelist,
      PrintFormat: printFormat,
      ProductCode: productCode,
      PrintContent: printContent,
    };
    CommonService.print(printParam);
  },
  getSubClaimByCaseId(caseId) {
    return CommonService.getSubClaimByCaseId(caseId);
  },
  getSubClaimCodeTableByCaseId(caseId, allClaimSubTypeCodeTable) {
    const result = CommonService.getSubClaimByCaseId(caseId);
    const codeTableList = new CodeTable([]);
    _.each(result.Model, type => {
      _.each(allClaimSubTypeCodeTable, atype => {
        if (atype.id == type.SubclaimType) {
          const newcode = {
            id: `${atype.id} ${type.SeqNo}`,
            text: `${atype.text} ${type.SeqNo}`,
          };
          codeTableList.codes.push(newcode);
        }
      });
    });
    return codeTableList;
  },
  getSubClaimTasksCodeTableNew(caseId) {
    const codeTableList = new CodeTable([]);
    const settleHis = CommonService.getSettlementHistory(caseId);
    const salvageHis = CommonService.getSalvageHistory(caseId);
    const subroHis = CommonService.getSubrogationHistory(caseId);

    _.each(settleHis, task => {
      const settledetail = CommonService.getSettlement(task.SettleId);
      const newcode = {
        subClaimSeq:
          settledetail.SettlementInfo.SettlementPayee['0']
            .SettlementItem['0'].SeqNo,
        id: task.SettleId,
        text: `${'Settlement_'}${task.SettlementNo}`,
      };
      codeTableList.codes.push(newcode);
    });
    let num = 1;
    if (salvageHis && salvageHis.length > 0) {
      for (let i = salvageHis.length; i > 0; i -= 1) {
        const task = salvageHis[i - 1];
        const settledetail = CommonService.getSalvage(task.SalvageId);
        const newcode = {
          subClaimSeq:
            settledetail.CoverageStructure['0'].SubclaimName,
          id: task.SalvageId,
          text: `${'Salvage_'}${num}`,
        };
        newcode.subClaimSeq = newcode.subClaimSeq.substring(
          newcode.subClaimSeq.length - 3,
          newcode.subClaimSeq.length
        );
        codeTableList.codes.push(newcode);
        num += 1;
      }
    }
    num = 1;
    if (subroHis && subroHis.length > 0) {
      for (let i = subroHis.length; i > 0; i -= 1) {
        const task = subroHis[i - 1];
        const settledetail = CommonService.getSubrogation(
          task.SubrogationId
        );
        const newcode = {
          subClaimSeq:
            settledetail.CoverageStructure['0'].InsuredName,
          id: task.SubrogationId,
          text: `${'Subrogation_'}${num}`,
        };
        newcode.subClaimSeq = newcode.subClaimSeq.substring(
          newcode.subClaimSeq.length - 3,
          newcode.subClaimSeq.length
        );
        codeTableList.codes.push(newcode);
        num += 1;
      }
    }
    return codeTableList;
  },
  getSettlementInfo(settlementId) {
    const settledetail = CommonService.getSettlement(settlementId);
    return settledetail;
  },

  getSalvagePayInfo(salvageId) {
    const salvagePayInfo = CommonService.getSalvagePayInfo(salvageId);
    return salvagePayInfo;
  },
  getSubrogationPayInfo(subrogationId) {
    const subrogationPayInfo = CommonService.getSubrogationPayInfo(
      subrogationId
    );
    return subrogationPayInfo;
  },
  getSubClaimTasksCodeTable(caseId) {
    const result = CommonService.getSubClaimTasks(caseId);
    const workingtasks = result.Model.loadClaimTasks;
    const closetasks = result.Model.loadClosedTasks;
    const alltasks = closetasks.concat(workingtasks);

    const codeTableList = new CodeTable([]);
    _.each(alltasks, task => {
      const newcode = {
        subClaimSeq: 123,
        id: task.id,
        text: `${task.TaskDefinitionKey}_${task.id}`,
      };
      codeTableList.codes.push(newcode);
    });
    return codeTableList;
  },
  loadClaimInfo(caseId) {
    return CommonService.loadClaimInfo(caseId);
  },
};
export default ClaimLetterAction;
