import {
  UIPanel,
  UIButton,
  UIDataTable,
  UIColumn,
  UILink,
  UIParam,
  UIDateTimePicker,
  UIBox,
  UISelect,
} from 'RainbowUI';
import PriorClaimsAction from '../action/PriorClaimsAction';
import CodeTableCstUI from '../constant/CodeTableCstUI';
import CodeTableAction from '../action/CodeTableAction';

const PriorClaimsDialog = React.createClass({

  getPriorClaimsList(pageIndex, pageSize) {
    return PriorClaimsAction.getPriorClaimsList(
      pageIndex,
      pageSize,
      this.props.caseId
    );
  },
  getParentComponent() {
    return this;
  },
  loadClaimCase(event) {
    PriorClaimsAction.loadLink(event);
    window.location.reload(true);
  },
  render() {
    const _self = this;
    return (
      <div>
        <UIPanel
          id="priorClaimsDialog"
          panelTitle={i18n.ClaimCommon.PriorClaims}
        >
          <UIDataTable
            id="priorClaimsResult"
            provider={this.getPriorClaimsList}
            styleClass="default"
            headerTitle={i18n.ClaimCommon.PriorClaims}
            indexable="false"
            pageable="true"
            isHeading="true"
          >
            <UIColumn
              headerTitle={i18n.ClaimCommon.ClaimNumber}
              render={data => {
                const caseId = data.CaseId;
                return (
                  <UILink
                    value={data.ClaimNo}
                    onClick={_self.loadClaimCase}
                  >
                    <UIParam name="caseId" value={caseId} />
                  </UILink>
                );
              }}
            />
            <UIColumn
              headerTitle={i18n.ClaimCommon.PolicyNumber}
              value="PolicyNo"
            />
            <UIColumn
              headerTitle={i18n.ClaimCommon.PolicyHolderName}
              value="PolicyholderName"
            />
            <UIColumn
              headerTitle={i18n.ClaimCommon.DateOfLoss}
              value="AccidentTime"
            >
              <UIDateTimePicker
                io="out"
                placeHolder="MM/DD/YYYY HH:mm:ss"
                format="MM/DD/YYYY HH:mm:ss"
                sortable="true"
              />
            </UIColumn>
            <UIColumn
              headerTitle={i18n.ClaimCommon.DateOfNotice}
              value="NoticeTime"
            >
              <UIDateTimePicker
                io="out"
                placeHolder="MM/DD/YYYY HH:mm:ss"
                format="MM/DD/YYYY HH:mm:ss"
                sortable="true"
              />
            </UIColumn>
            <UIColumn
              headerTitle={i18n.ClaimCommon.Status}
              value="StatusCode"
            >
              <UISelect
                codeTable={CodeTableAction.loadDataList(
                  CodeTableCstUI.ClaimStatus,
                  null
                )}
                io="out"
              />
            </UIColumn>
          </UIDataTable>

        </UIPanel>
      </div>
    );
  },
});
export default PriorClaimsDialog;
