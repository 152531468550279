import {
  UIBox,
  UISelect,
  UIDialog,
  UISmartPanelGrid,
  UICell,
  UIText,
  UIButton,
} from 'RainbowUI';
import DefinedCodeTable from '../../../codetable/CodeTable';
import PartyCodeTableText from '../../../../common/component/PartyCodeTableText';

const EditOrgRelationShipDialogReadOnly = React.createClass({
  getInitialState() {
    return {
      partyRelationShip: {},
    };
  },

  onClickClose() {
    UIDialog.hide('RelationShipDialogReadOnly');
  },

  render() {
    const _self = this;
    const { partyRelationShip } = this.state;
    return (
      <UIDialog
        id="RelationShipDialogReadOnly"
        width="80%"
        title={i18n.RelationshipDetail}
      >
        <UISmartPanelGrid column="3">
          <PartyCodeTableText
            label={i18n.PartyName}
            model={partyRelationShip}
            property="RelationShipPartyId"
            disabled="true"
            condition="RelationShipPartyId"
          />
          <UISelect
            label={i18n.RelationshipType}
            codeTable={DefinedCodeTable.getAllRelationShipType}
            model={partyRelationShip}
            property="RelationShipType"
            disabled="true"
          />
          <UIText
            label={i18n.IdOrCompanyRegNumber}
            disabled="true"
            model={partyRelationShip}
            property="IdNumber"
          />
          <UIText
            label={i18n.Remarks}
            model={partyRelationShip}
            property="Remark"
            colspan="2"
            disabled="true"
          />
          <UICell />
          <UISelect
            label={i18n.Status}
            codeTable={DefinedCodeTable.getPartyStatus}
            model={partyRelationShip}
            property="Status"
            showBlankOption="false"
            disabled="true"
          />
        </UISmartPanelGrid>
        <UIBox direction="center">
          <UIButton value={i18n.Save} disabled="true" />
          <UIButton
            value={i18n.Cancel}
            onClick={_self.onClickClose}
          />
        </UIBox>
      </UIDialog>
    );
  },

  showDialog(partyRelationShip) {
    UIDialog.show('RelationShipDialogReadOnly');
    this.setState({
      partyRelationShip,
    });
  },
});
export default EditOrgRelationShipDialogReadOnly;
