import {
  UIPanel,
  UIText,
  UIParam,
  UIDataTable,
  UIDateTimePicker,
  UISelect,
  UIColumn,
  UICurrency,
  UILink,
} from 'RainbowUI';
import { CodeTableAction, CodeTableCstUI } from 'ClaimCommonConstant';

const AppraisalBodyReadOnlyDialog = require('./AppraisalBodyReadOnlyDialog');

const AppraisalHistory = React.createClass({
  getInitialState() {
    return { appraisalHistory: this.props.appraisalHistory };
  },

  showAppraisalReadOnly(event) {
    this.refs.AppraisalBodyReadOnlyDialog_ref.showDialog(
      event.getParameter('seqNo')
    );
  },

  render() {
    const _self = this;
    const { currencyCode: CurrencyCode } = this.props;
    const { appraisalHistory } = this.state;
    let index = 0;
    return (
      <UIPanel
        panelTitle={i18n.ClaimAppraisal.AppraisalHistory}
        toggleable="true"
      >
        <UIDataTable
          pageable="false"
          indexable="false"
          value={appraisalHistory}
        >
          <UIColumn
            headerTitle={i18n.ClaimAppraisal.SeqNo}
            width="20%"
            render={data => {
              return (
                <UILink
                  value={data.AppraisalInfo.SeqNo}
                  onClick={_self.showAppraisalReadOnly}
                >
                  <UIParam name="seqNo" value={data} />
                </UILink>
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.ClaimAppraisal.TransactionDate}
            render={data => {
              return (
                <UIDateTimePicker
                  value={data.AppraisalInfo.ApproveDate}
                  placeHolder="MM/DD/YYYY"
                  format="MM/DD/YYYY"
                  io="out"
                />
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.ClaimAppraisal.OperatedBy}
            value="OperatedBy"
            render={data => {
              return (
                <UISelect
                  value={data.AppraisalInfo.Submitter}
                  codeTable={CodeTableAction.loadDataList(
                    CodeTableCstUI.ClaimUser,
                    null
                  )}
                  io="out"
                />
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.ClaimAppraisal.NumberOfLossItems}
            value="NumberOfLossItems"
            render={data => {
              return (
                <UIText
                  value={
                    data.AppraisalInfo.appraisalBodyInjury
                      .ClaimAppraisalBodyItem.length - 1
                  }
                  io="out"
                />
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.ClaimAppraisal.TotalNetAmount}
            value="TotalNetAmount"
            render={data => {
              index += 1;
              return (
                <UICurrency
                  id={`CURRENCY_CODE_BODY_Appraisal_TotalNetAmount${index}`}
                  value={
                    data.AppraisalInfo.appraisalBodyInjury
                      .TotalNetAmount
                  }
                  unit={window.EU00.getCurrencySignByCurrencyCode(
                    CurrencyCode
                  )}
                  io="out"
                />
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.ClaimAppraisal.Remark}
            value="PropertyRemark"
            render={data => {
              return (
                <UIText
                  value={data.AppraisalInfo.AppraisalRemark}
                  io="out"
                />
              );
            }}
          />
        </UIDataTable>
        <AppraisalBodyReadOnlyDialog
          ref="AppraisalBodyReadOnlyDialog_ref"
          parentComponet={this}
        />
      </UIPanel>
    );
  },

  onClose() {
    UIDialog.hide('AppraisalReadOnlyDialog');
  },
});
export default AppraisalHistory;
