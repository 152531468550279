import {
  UISelect,
  UIText,
  UISmartPanelGrid,
  UICell,
  UIUpdatePanel,
  UpdateContext,
  UIParam,
  UIDateTimePicker,
  CodeTable,
} from 'RainbowUI';
import { CodeTableAction } from 'ClaimCommonConstant';
import { PartyConst } from 'EU00PartyCommonUI';

import React from 'react';
import BindToMixin from 'react-binding';

const ClaimCompanyCareInfo = React.createClass({
  mixins: [BindToMixin],
  getInitialState() {
    return {
      caseEntity: this.props.caseEntity,
      IsCompanyCareRequired: false,
    };
  },

  getCompanyCareCodetable() {
    return new CodeTable([]);
    //trust data
    return CodeTableAction.loadDataList(76310869, null);
  },

  render() {
    const {
      caseEntity,
      IsCompanyCareRequired,
    } = this.state;
    const _self = this;
    const validationGroup = 'RegistrationInfo';
    let ioflag = 'in';
    if (_self.props.ioflag) {
      ioflag = _self.props.ioflag;
    } else {
      ioflag = 'in';
    }
    let CompanyCareId = 'CompanyCare';
    let CareCallDateId = 'CareCallDate';
    let CareOperatorId = 'CareOperator';
    let CareProviderId = 'CareProvider';
    let CareServiceDateId = 'CareServiceDate';
    if (caseEntity) {
      CompanyCareId += caseEntity.SeqNo;
      CareCallDateId += caseEntity.SeqNo;
      CareOperatorId += caseEntity.SeqNo;
      CareProviderId += caseEntity.SeqNo;
      CareServiceDateId += caseEntity.SeqNo;
    }
    return (
      <UIUpdatePanel id="companyCareInformation" colspan="3">
        <UISmartPanelGrid column="3" widthAllocation="5,7">
          <UISelect
            id={CompanyCareId}
            label={i18n.ClaimCommon.CompanyCare}
            validationGroup={validationGroup}
            onChange={this.onChangeCompanyCare}
            codeTable={this.getCompanyCareCodetable()}
            model={caseEntity}
            property="CompanyCare"
            io={ioflag}
          />

          <UIDateTimePicker
            format="MM/DD/YYYY HH:mm:ss"
            id={CareCallDateId}
            label={i18n.ClaimCommon.CareCallDate}
            validationGroup={validationGroup}
            model={caseEntity}
            required={IsCompanyCareRequired}
            property="CareCallDate"
            placeHolder="MM/DD/YYYY HH:mm:ss"
            io={ioflag}
          />
          <UIText
            id={CareOperatorId}
            label={i18n.ClaimCommon.CareOperator}
            validationGroup={validationGroup}
            model={caseEntity}
            required={IsCompanyCareRequired}
            property="CareOperator"
            io={ioflag}
          />

          <UIText
            id={CareProviderId}
            label={i18n.ClaimCommon.CareProvider}
            model={caseEntity}
            property="CareProvider"
            required={IsCompanyCareRequired}
            validationGroup="RegistrationInfo"
            io={ioflag}
          >
            <UIParam
              name="partyRole"
              value={PartyConst.PARTY_ROLE_CODE_ORG_HEALTHCARE}
            />
            <UIParam
              name="partyCategory"
              value={PartyConst.PARTY_TYPE_ORG}
            />
            <UIParam name="stateParam" value="rentalOrgCarCompany" />
          </UIText>
          <UIDateTimePicker
            format="MM/DD/YYYY HH:mm:ss"
            id={CareServiceDateId}
            label={i18n.ClaimCommon.CareServiceTime}
            validationGroup={validationGroup}
            model={caseEntity}
            required={IsCompanyCareRequired}
            property="CareServiceDate"
            placeHolder="MM/DD/YYYY HH:mm:ss"
            io={ioflag}
          />
          <UICell />
        </UISmartPanelGrid>
      </UIUpdatePanel>
    );
  },

  onChangeCompanyCare(event) {
    if (event.newValue == 2) {
      this.state.IsCompanyCareRequired = false;
      this.clearCompanyCareInfo();
    } else {
      this.state.IsCompanyCareRequired = true;
      this.setState();
    }
    this.syncProperty();
    UpdateContext.forceUpdate('companyCareInformation');
  },
  clearCompanyCareInfo() {
    const { caseEntity } = this.state;
    delete caseEntity.CareCallDate;
    delete caseEntity.CareOperator;
    delete caseEntity.CareProvider;
    delete caseEntity.CareServiceDate;
    this.setState({ caseEntity });
  },
  syncProperty() {
    const { caseEntity } = this.state;
    if (this.props.syncProperty) {
      this.props.syncProperty(caseEntity);
    }
  },
});
export default ClaimCompanyCareInfo;
