import {
  UIBox,
  UISelect,
  UILink,
  UIText,
  UIParam,
  UIDataTable,
  UIColumn,
  UICurrency,
  CodeTable,
} from 'RainbowUI';
import BindToMixin from 'react-binding';
import { ClaimMessageForamtUtils } from 'ClaimCommonConstant';
import PropTypes from 'prop-types';

const CalculationLoadAction = require('../action/CalculationLoadAction');

const CalculationItemInfo = React.createClass({
  mixins: [BindToMixin],

  propTypes: {
    otherInfo: PropTypes.object.isRequired,
    insuredId: PropTypes.string.isRequired,
    coverageCode: PropTypes.string.isRequired,
    calculationItem: PropTypes.object.isRequired,
    readOnly: PropTypes.bool,
    isApproveTask: PropTypes.bool,
  },

  render() {
    const calculationItem = this.getCalculationItemPanel();
    const { insuredId } = this.props;
    const { coverageCode } = this.props;
    const { readOnly } = this.props;
    const { isApproveTask } = this.props;
    const button = readOnly ? (
      <div />
    ) : (
      <UIBox>
        <UILink
          icon="fa fa-plus"
          size="lg"
          iconClass="add_iconbtn"
          onClick={CalculationLoadAction.addItem}
        >
          <UIParam name="insuredId" value={insuredId} />
          <UIParam name="coverageCode" value={coverageCode} />
        </UILink>
      </UIBox>
    );
    const hr = readOnly ? <div /> : <hr />;
    return (
      <div>
        {button}
        {calculationItem}
        {hr}
      </div>
    );
  },

  getSubclaimCodeTable() {
    const calculationStructure = this.props.otherInfo.value
      .CalculationStructure;
    for (let i = 0; i < calculationStructure.length; i += 1) {
      if (
        calculationStructure[i].InsuredId == this.props.insuredId &&
        calculationStructure[i].CoverageCode ==
          this.props.coverageCode
      ) {
        return new CodeTable(
          calculationStructure[i].SubclaimCodeTable || []
        );
      }
    }
    return '';
  },
  getshowNumberOfUnits() {
    const calculationStructure = this.props.otherInfo.value
      .CalculationStructure;
    for (let i = 0; i < calculationStructure.length; i += 1) {
      if (
        calculationStructure[i].InsuredId == this.props.insuredId &&
        calculationStructure[i].CoverageCode ==
          this.props.coverageCode
      ) {
        return calculationStructure[i].ExistUnit;
      }
    }
    return '';
  },
  getIOStyle() {
    return this.props.readOnly == true ? 'out' : null;
  },
  getDisplayStyle() {
    return this.props.readOnly == true ? 'none' : '';
  },
  getCalculationItemPanel() {
    const _self = this;

    const {
      componentId,
      isApproveTask,
      insuredId,
      coverageCode,
      readOnly,
    } = this.props;
    const io = this.getIOStyle();
    const existUnit = _self.getshowNumberOfUnits() === 'Y';
    const suffixId = `calculationItem_${readOnly}_${isApproveTask}${insuredId}_${coverageCode.replace(
      /\./g,
      '_'
    )}_${componentId}`;
    return (
      <UIDataTable
        id={`${suffixId}dataTable`}
        indexable="false"
        value={_self.props.calculationItem}
        pageable="false"
        headable="false"
      >
        <UIColumn
          headerTitle={i18n.ClaimCalculation.Subclaim}
          width="13%"
          value="ItemId"
          render={data => {
            return (
              <UISelect
                id={`${suffixId + data.Index}_itemId`}
                value={data.ItemId}
                codeTable={_self.getSubclaimCodeTable}
                io={io}
                onChange={CalculationLoadAction.chooseItem}
                required="true"
                label=""
                requiredMessage={ClaimMessageForamtUtils.formatI18NMsg(
                  i18n.ClaimCommon.I18nUtilValidate,
                  i18n.ClaimCommon.Subclaim
                )}
                validationGroup="calculation_submit_validate"
                widthAllocation="2,10"
              >
                <UIParam name="insuredId" value={insuredId} />
                <UIParam name="coverageCode" value={coverageCode} />
                <UIParam name="index" value={data.Index} />
                <UIParam name="calculationItem" value={data} />
              </UISelect>
            );
          }}
        />
        <UIColumn
          headerTitle={
            _self.props.isApproveTask == true ||
            _self.props.readOnly == false
              ? i18n.ClaimCalculation.OutstandingReserve
              : ''
          }
          width={
            _self.props.isApproveTask == true ||
            _self.props.readOnly == false
              ? '13%'
              : '0%'
          }
          render={data => {
            if (
              _self.props.isApproveTask == true ||
              _self.props.readOnly == false
            ) {
              return (
                <div style={{ float: 'left' }}>
                  <UICurrency
                    id={`CURRENCY_CODE_${suffixId}${data.Index}_out_amo`}
                    value={data.ReserveAmount}
                    unit={window.EU00.getCurrencySignByCurrencyCode(
                      data.ReserveCurrency
                    )}
                    io="out"
                  />
                </div>
              );
            }
            return '';
          }}
        />
        <UIColumn
          headerTitle={
            _self.props.isApproveTask == true ||
            _self.props.readOnly == false
              ? i18n.ClaimCalculation.SettledAmount
              : ''
          }
          width={
            _self.props.isApproveTask == true ||
            _self.props.readOnly == false
              ? '13%'
              : '0%'
          }
          render={data => {
            if (
              _self.props.isApprove == true ||
              _self.props.readOnly == false
            ) {
              return (
                <div style={{ float: 'left' }}>
                  <UICurrency
                    id={`CURRENCY_CODE_${suffixId}${data.Index}_set_amou`}
                    value={data.SettledAmount}
                    unit={window.EU00.getCurrencySignByCurrencyCode(
                      data.ReserveCurrency
                    )}
                    io="out"
                  />
                </div>
              );
            }
            return '';
          }}
        />
        <UIColumn
          headerTitle={i18n.ClaimCalculation.RequestAmount}
          width="13%"
          render={data => {
            return (
              <UICurrency
                id={`CURRENCY_CODE_${suffixId}${data.Index}_requ_amou`}
                value={data.RequestAmount}
                unit={window.EU00.getCurrencySignByCurrencyCode(
                  data.CurrencyCode
                )}
                onChange={CalculationLoadAction.requestAmountChange}
                required="true"
                label=""
                requiredMessage={ClaimMessageForamtUtils.formatI18NMsg(
                  i18n.ClaimCommon.I18nUtilValidate,
                  i18n.ClaimCalculation.RequestAmount
                )}
                validationGroup="calculation_submit_validate"
                allowNegative="false"
                io={io}
                widthAllocation="2,10"
              >
                <UIParam name="index" value={data.Index} />
                <UIParam name="calculationItem" value={data} />
              </UICurrency>
            );
          }}
        />
        <UIColumn
          headerTitle={i18n.ClaimCalculation.RecognizedAmount}
          width="13%"
          render={data => {
            return (
              <UICurrency
                id={`CURRENCY_CODE_${suffixId}${data.Index}_reco_amou`}
                value={data.RecognizedAmount}
                unit={window.EU00.getCurrencySignByCurrencyCode(
                  data.CurrencyCode
                )}
                onChange={
                  CalculationLoadAction.recognizedAmountChange
                }
                required="true"
                label=""
                requiredMessage={ClaimMessageForamtUtils.formatI18NMsg(
                  i18n.ClaimCommon.I18nUtilValidate,
                  i18n.ClaimCalculation.RecognizedAmount
                )}
                validationGroup="calculation_submit_validate"
                allowNegative="false"
                io={io}
                widthAllocation="2,10"
              >
                <UIParam name="index" value={data.Index} />
                <UIParam name="calculationItem" value={data} />
              </UICurrency>
            );
          }}
        />
        <UIColumn
          headerTitle={i18n.ClaimCalculation.CalculatedAmount}
          value="CalculatedAmount"
          width="13%"
          render={data => {
            return (
              <div style={{ float: 'left' }}>
                <UICurrency
                  id={`CURRENCY_CODE_${suffixId}${data.Index}_cal_amou`}
                  value={data.CalculatedAmount}
                  allowNegative="false"
                  unit={window.EU00.getCurrencySignByCurrencyCode(
                    data.CurrencyCode
                  )}
                  io="out"
                />
              </div>
            );
          }}
        />
        <UIColumn
          headerTitle={i18n.ClaimCalculation.AdjustedAmount}
          width="11%"
          render={data => {
            return (
              <UICurrency
                id={`CURRENCY_CODE_${suffixId}${data.Index}_adj_amout`}
                value={data.IndemnityAmount}
                unit={window.EU00.getCurrencySignByCurrencyCode(
                  data.CurrencyCode
                )}
                onChange={CalculationLoadAction.indemnityAmountChange}
                required="true"
                label=""
                requiredMessage={ClaimMessageForamtUtils.formatI18NMsg(
                  i18n.ClaimCommon.I18nUtilValidate,
                  i18n.ClaimCalculation.AdjustedAmount
                )}
                validationGroup="calculation_submit_validate"
                io={io}
                widthAllocation="2,10"
              >
                <UIParam name="index" value={data.Index} />
                <UIParam
                  name="calculatedAmount"
                  value={data.CalculatedAmount}
                />
              </UICurrency>
            );
          }}
        />
        <UIColumn
          headerTitle={i18n.ClaimCalculation.InvoiceNo}
          width="13%"
          render={data => {
            return (
              <UIText
                io={io}
                id={`${suffixId + data.Index}_invoice_no`}
                value={data.InvoiceNo}
                onChange={CalculationLoadAction.invoiceNoChange}
                label=""
                widthAllocation="2,10"
              >
                <UIParam name="index" value={data.Index} />
              </UIText>
            );
          }}
        />
        <UIColumn
          headerTitle={readOnly ? '' : i18n.ClaimCommon.Action}
          render={data => {
            if (readOnly) {
              return '';
            }
            return (
              <UILink
                id={`${suffixId + data.dataIndex}cal_ite_rem`}
                tooltip={i18n.ClaimCommon.Delete}
                icon="fa fa-trash"
                onClick={CalculationLoadAction.deleteItem}
              >
                <UIParam name="insuredId" value={insuredId} />
                <UIParam name="coverageCode" value={coverageCode} />
                <UIParam name="index" value={data.Index} />
                <UIParam
                  name="indemnityAmount"
                  value={data.IndemnityAmount}
                />
                <UIParam
                  name="itemsLength"
                  value={_self.props.calculationItem.length}
                />
              </UILink>
            );
          }}
        />
      </UIDataTable>
    );
  },
});
export default CalculationItemInfo;
