const ClaimWorkFlowSharePoolList = require('./ClaimWorkFlowSharePoolList');
const ClaimWorkFlowSharePoolCriteria = require('./ClaimWorkFlowSharePoolCriteria');

const ClaimWorkFlowSharePool = React.createClass({
  onClickClear() {
    this.refs.ClaimWorkFlowSharePoolCriteria.onClickClear();
  },
  render() {
    return (
      <div>
        <ClaimWorkFlowSharePoolCriteria
          ref="ClaimWorkFlowSharePoolCriteria"
          parentComponent={this}
        />
        <ClaimWorkFlowSharePoolList
          ref="ClaimWorkFlowSharePoolList"
          parentComponent={this}
        />
      </div>
    );
  },
});
export default ClaimWorkFlowSharePool;
