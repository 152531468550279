import {
  UIDataTable,
  UIColumn,
  UIPanel,
  UITextarea,
  UISelect,
  UIDateTimePicker,
  CodeTable,
} from 'RainbowUI';
import CreateNote from './CreateNote';
import ClaimNoteAction from '../../action/ClaimNoteAction';
import CodeTableCstUI from '../../constant/CodeTableCstUI';
import CodeTableAction from '../../action/CodeTableAction';
import ClaimBaseInfo from '../claimhandling/ClaimBaseInfo';
import TaskTransferAction from '../../action/TaskTransferAction';

const Note = React.createClass({
  getInitialState() {
    return {
      addStatus: '',
      noteVo: {},
      count: 0,
    };
  },

  componentDidMount() {
    $('textarea').css('resize', 'vertical');
  },

  getList(pageIndex, pageSize) {
    return ClaimNoteAction.loadList(
      this.props.caseId,
      pageIndex,
      pageSize
    );
  },
  stateStoreChange() {
    this.setState(prevState => ({ count: prevState.count + 1 }));
  },
  showNoteLevel() {
    const codeTable = [{ id: 'Claim', text: 'Claim' }];
    const entityList = TaskTransferAction.getSubClaimNoByCaseId(this.props.caseId);
    for (let i = 0; i < entityList.length; i += 1) {
      codeTable.push({
        id: entityList[i].ObjectId,
        text: `Subclaim ${entityList[i].SeqNo}`,
      });
    }
    return new CodeTable(codeTable);
  },
  render() {
    const { claimEntity, caseId, claimNo, noteAuth } = this.props;
    return (
      <div>
        <ClaimBaseInfo claimEntity={claimEntity} />
        <UIPanel panelTitle={i18n.ClaimCommon.Note}>
          <CreateNote
            caseId={caseId}
            claimNo={claimNo}
            parentComponent={this}
            noteAuth={noteAuth}
            showNoteLevel={this.showNoteLevel}
          />
          <div>
            <UIDataTable
              indexable="false"
              headerable="false"
              provider={this.getList}
            >
              <UIColumn
                headerTitle={i18n.ClaimCommon.NoteDate}
                sortable="true"
                value="Date"
              >
                <UIDateTimePicker
                  format="MM/DD/YYYY HH:mm:ss"
                  io="out"
                  placeHolder="MM/DD/YYYY HH:mm:ss"
                />
              </UIColumn>
              <UIColumn
                headerTitle={i18n.ClaimCommon.NoteBy}
                sortable="true"
                value="UserId"
              >
                <UISelect
                  io="out"
                  codeTable={CodeTableAction.loadDataList(
                    CodeTableCstUI.ClaimUser,
                    null
                  )}
                />
              </UIColumn>
              <UIColumn
                headerTitle={i18n.ClaimCommon.NoteRecipient}
                sortable="true"
                value="ClaimNoteEntity.SendMessageTo"
              >
                <UISelect
                  codeTable={CodeTableAction.loadDataList(
                    CodeTableCstUI.ClaimUser,
                    null
                  )}
                  io="out"
                />
              </UIColumn>
              <UIColumn
                headerTitle={i18n.ClaimCommon.NoteOverview}
                value="ClaimNoteEntity.NoteOverview"
                sortable="false"
              >
                <UITextarea resize="vertical" io="out" />
              </UIColumn>
              <UIColumn
                headerTitle={i18n.ClaimCommon.NoteContent}
                value="ClaimNoteEntity.NoteContent"
              >
                <UITextarea resize="vertical" io="out" />
              </UIColumn>
              <UIColumn
                headerTitle={i18n.ClaimCommon.NoteCategory}
                sortable="true"
                value="ClaimNoteEntity.NoteCategory"
              >
                <UISelect
                  codeTable={CodeTableAction.loadDataList(
                    CodeTableCstUI.ClaimNoteCategory,
                    null
                  )}
                  io="out"
                />
              </UIColumn>
              <UIColumn
                headerTitle={i18n.ClaimCommon.NoteUserType}
                sortable="true"
                value="ClaimNoteEntity.UserType"
              >
                <UISelect
                  codeTable={CodeTableAction.loadDataList(
                    CodeTableCstUI.ClaimNoteUserType,
                    null
                  )}
                  io="out"
                />
              </UIColumn>
              <UIColumn
                headerTitle={i18n.ClaimCommon.NoteLevel}
                sortable="true"
                value="ClaimNoteEntity.NoteLevel"
              >
                <UISelect
                  codeTable={this.showNoteLevel}
                  io="out"
                />
              </UIColumn>
            </UIDataTable>
          </div>
        </UIPanel>
      </div>
    );
  },
});
export default Note;
