import alt from '../alt';
import ReserveAction from '../action/ReserveAction';

class ReserveApprovalStore {
  constructor() {
    this.displayName = 'ReserveApprovalStore';
    this.bindListeners({
      handleReserveChangeInit: ReserveAction.INIT_RESERVE_CHANGED,
    });
  }

  handleReserveChangeInit(payload) {
    const stateData = { common: {} };

    if (!payload.CloseType) {
      stateData.commontype = 'update';
      stateData.common.Update = {
        ShowType: payload.ShowType,
        NewOutstandingReserve: payload.OutstandingChanged,
        Remark: payload.Remark,
        CurrenyCode: payload.CurrenyCode,
      };
    } else {
      stateData.common.Closure = {
        ShowType: payload.ShowType,
        Remark: payload.Remark,
        RejectReason: payload.RejectReason,
        CloseType: payload.CloseType,
      };
      stateData.commontype = 'closure';
    }

    this.setState(stateData);
  }
}

export default alt.createStore(ReserveApprovalStore);
