import { AjaxUtil } from 'RainbowUI';
import TaskTransferAction from '../action/TaskTransferAction';
import Url from '../url/Url';

class TaskAssignUserRuleService {
  constructor() {
    this.userList = {};
    this.userAuth = [];
  }

  // userListAuthoryCode  userListPoolSystem  userListAuthoryCodeCaseId
  queryUserList(authCode, caseId = '', taskId = '') {
    const { userList } = this;
    console.info('TaskAssignUserRuleService', userList);
    let key = `code:${authCode}`;
    let codes;
    if (caseId === null || caseId === undefined || caseId === '') {
      codes =
        userList[key] ||
        TaskTransferAction.UserListByAuthoryCode(authCode);
    } else if (
      taskId === null ||
      taskId === undefined ||
      taskId === ''
    ) {
      key = `code:${authCode}|param:${caseId}`;
      codes =
        userList[key] ||
        TaskTransferAction.UserListWithPoolSystem(authCode, caseId);
    } else {
      key = `code:${authCode}|param:${caseId}_${taskId}`;
      // codes = userList[key] || TaskTransferAction.UserListByAuthoryCodeCaseId(authCode,caseId,taskId);
      codes = TaskTransferAction.UserListByAuthoryCodeCaseId(
        authCode,
        caseId,
        taskId
      );
    }
    userList[key] = codes;
    console.info('userList', this.userList);
    return codes;
  }

  queryUserAuthInfo(uid, caseId, authtype = 'task') {
    let { userAuth } = this;
    if (userAuth.length === 0) {
      const url = Url.ClaimUserAuthInfo.replace(
        /:uid/,
        uid || 'self'
      ).replace(/:authtype/, authtype);
      AjaxUtil.doGet(
        url,
        { caseId },
        {
          async: false,
          done(data) {
            userAuth = data.Model || [];
          },
          fail() {
            console.log('queryUserAuthInfo err');
          },
        }
      );
    }
    return userAuth;
  }

  clearUserList() {
    this.userList = {};
    this.userAuth = [];
  }
}
const ruleServiceInstance = new TaskAssignUserRuleService();
export default ruleServiceInstance;
