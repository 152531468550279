export default {
  "@type": "ClaimPolicy-ClaimPolicy",
  "EffDate": "2020-03-01",
  "ExpDate": "2021-03-01",
  "InsuranceType": "1",
  "PolicyNo": "HO48187912937",
  "PolicyTypeCode": "HO",
  "ProductTypeCode": "HO",
  "ProductCode": "2020001",
  "CurrencyCode": "USD",
  "ExtContent": "{\"PolicyId\":82503748637}",
  "PolicyholderName": "Henry Damon",
  "InsuredList": [
    {
      "@type": "ClaimPolicyInsured-ClaimPolicyInsured",
      "InsuredCategory": "3",
      "PolicyInsuredId": "825037486371",
      "ExtContent": "{\"InsuredId\":825037486371}",
      "InsuredName": "1111 John Drive, San Diego, US, 21213",

      "CoverageList": [
        {
          "@type": "ClaimPolicyCoverage-ClaimPolicyCoverage",
          "InitLossIndemnity": "0",
          "LossConfigDefaultAmount": "0",
          "CoverageCode": "2020001001",
          "PolicyCoverageCode": "2020001001",
          "CoverageName": "Contents",
          "ProductCode": "2020001",

          "ExtContent": "{\"PolicyCoverageId\":825037486371001}",
          "RiskCateId": "5",
          "CoveragePeriodAllowed": "N",
          "EffDate": "2020-03-01",
          "ExpDate": "2021-03-01",
        },
        {
          "@type": "ClaimPolicyCoverage-ClaimPolicyCoverage",
          "InitLossIndemnity": "0",
          "LossConfigDefaultAmount": "0",
          "CoverageCode": "2020001002",
          "PolicyCoverageCode": "2020001002",
          "CoverageName": "Ded Waiver Large Losses",
          "ProductCode": "2020001",

          "ExtContent": "{\"PolicyCoverageId\":825037486371002}",
          "RiskCateId": "5",
          "CoveragePeriodAllowed": "N",
          "EffDate": "2020-03-01",
          "ExpDate": "2021-03-01",
        },
        {
          "@type": "ClaimPolicyCoverage-ClaimPolicyCoverage",
          "InitLossIndemnity": "0",
          "LossConfigDefaultAmount": "0",
          "CoverageCode": "2020001003",
          "PolicyCoverageCode": "2020001003",
          "CoverageName": "Dwelling",
          "ProductCode": "2020001",

          "ExtContent": "{\"PolicyCoverageId\":825037486371003}",
          "RiskCateId": "5",
          "CoveragePeriodAllowed": "N",
          "EffDate": "2020-03-01",
          "ExpDate": "2021-03-01",
        },
        {
          "@type": "ClaimPolicyCoverage-ClaimPolicyCoverage",
          "InitLossIndemnity": "0",
          "LossConfigDefaultAmount": "0",
          "CoverageCode": "2020001004",
          "PolicyCoverageCode": "2020001004",
          "UpperCoverageCode": "2020001003",
          "CoverageName": "Dwelling",
          "ProductCode": "2020001",

          "ExtContent": "{\"PolicyCoverageId\":825037486371004}",
          "RiskCateId": "5",
          "CoveragePeriodAllowed": "N",
          "EffDate": "2020-03-01",
          "ExpDate": "2021-03-01",
        },
        {
          "@type": "ClaimPolicyCoverage-ClaimPolicyCoverage",
          "InitLossIndemnity": "0",
          "LossConfigDefaultAmount": "0",
          "CoverageCode": "2020001005",
          "PolicyCoverageCode": "2020001005",
          "UpperCoverageCode": "2020001003",
          "CoverageName": "Sewer & Drain Back-Up",
          "ProductCode": "2020001",

          "ExtContent": "{\"PolicyCoverageId\":825037486371005}",
          "RiskCateId": "5",
          "CoveragePeriodAllowed": "N",
          "EffDate": "2020-03-01",
          "ExpDate": "2021-03-01",
        },
        {
          "@type": "ClaimPolicyCoverage-ClaimPolicyCoverage",
          "InitLossIndemnity": "0",
          "LossConfigDefaultAmount": "0",
          "CoverageCode": "2020001006",
          "PolicyCoverageCode": "2020001006",
          "CoverageName": "Business Property",
          "ProductCode": "2020001",

          "ExtContent": "{\"PolicyCoverageId\":825037486371006}",
          "RiskCateId": "5",
          "CoveragePeriodAllowed": "N",
          "EffDate": "2020-03-01",
          "ExpDate": "2021-03-01",
        },
        {
          "@type": "ClaimPolicyCoverage-ClaimPolicyCoverage",
          "InitLossIndemnity": "0",
          "LossConfigDefaultAmount": "0",
          "CoverageCode": "2020001007",
          "PolicyCoverageCode": "2020001007",
          "CoverageName": "Ensuing Fungi Increase",
          "ProductCode": "2020001",

          "ExtContent": "{\"PolicyCoverageId\":825037486371007}",
          "RiskCateId": "5",
          "CoveragePeriodAllowed": "N",
          "EffDate": "2020-03-01",
          "ExpDate": "2021-03-01",
        },
        {
          "@type": "ClaimPolicyCoverage-ClaimPolicyCoverage",
          "InitLossIndemnity": "0",
          "LossConfigDefaultAmount": "0",
          "CoverageCode": "2020001008",
          "PolicyCoverageCode": "2020001008",
          "CoverageName": "Home Systems Protection",
          "ProductCode": "2020001",

          "ExtContent": "{\"PolicyCoverageId\":825037486371008}",
          "RiskCateId": "5",
          "CoveragePeriodAllowed": "N",
          "EffDate": "2020-03-01",
          "ExpDate": "2021-03-01",
        },
        {
          "@type": "ClaimPolicyCoverage-ClaimPolicyCoverage",
          "InitLossIndemnity": "0",
          "LossConfigDefaultAmount": "0",
          "CoverageCode": "2020001009",
          "PolicyCoverageCode": "2020001009",
          "CoverageName": "Loss Of Use",
          "ProductCode": "2020001",

          "ExtContent": "{\"PolicyCoverageId\":825037486371009}",
          "RiskCateId": "5",
          "CoveragePeriodAllowed": "N",
          "EffDate": "2020-03-01",
          "ExpDate": "2021-03-01",
        },
        {
          "@type": "ClaimPolicyCoverage-ClaimPolicyCoverage",
          "InitLossIndemnity": "0",
          "LossConfigDefaultAmount": "0",
          "CoverageCode": "2020001010",
          "PolicyCoverageCode": "2020001010",
          "CoverageName": "Medical Payments",
          "ProductCode": "2020001",

          "ExtContent": "{\"PolicyCoverageId\":825037486371010}",
          "RiskCateId": "5",
          "CoveragePeriodAllowed": "N",
          "EffDate": "2020-03-01",
          "ExpDate": "2021-03-01",
        },
        {
          "@type": "ClaimPolicyCoverage-ClaimPolicyCoverage",
          "InitLossIndemnity": "0",
          "LossConfigDefaultAmount": "0",
          "CoverageCode": "2020001011",
          "PolicyCoverageCode": "2020001011",
          "CoverageName": "Other Structures",
          "ProductCode": "2020001",

          "ExtContent": "{\"PolicyCoverageId\":825037486371011}",
          "RiskCateId": "5",
          "CoveragePeriodAllowed": "N",
          "EffDate": "2020-03-01",
          "ExpDate": "2021-03-01",
        },
        {
          "@type": "ClaimPolicyCoverage-ClaimPolicyCoverage",
          "InitLossIndemnity": "0",
          "LossConfigDefaultAmount": "0",
          "CoverageCode": "2020001012",
          "PolicyCoverageCode": "2020001012",
          "CoverageName": "Personal Liability",
          "ProductCode": "2020001",

          "ExtContent": "{\"PolicyCoverageId\":825037486371012}",
          "RiskCateId": "5",
          "CoveragePeriodAllowed": "N",
          "EffDate": "2020-03-01",
          "ExpDate": "2021-03-01",
        },
        {
          "@type": "ClaimPolicyCoverage-ClaimPolicyCoverage",
          "InitLossIndemnity": "0",
          "LossConfigDefaultAmount": "0",
          "CoverageCode": "2020001013",
          "PolicyCoverageCode": "2020001013",
          "CoverageName": "Service Line Protection",
          "ProductCode": "2020001",

          "ExtContent": "{\"PolicyCoverageId\":825037486371013}",
          "RiskCateId": "5",
          "CoveragePeriodAllowed": "N",
          "EffDate": "2020-03-01",
          "ExpDate": "2021-03-01",
        },
        {
          "@type": "ClaimPolicyCoverage-ClaimPolicyCoverage",
          "InitLossIndemnity": "0",
          "LossConfigDefaultAmount": "0",
          "CoverageCode": "2020001014",
          "PolicyCoverageCode": "2020001014",
          "CoverageName": "Sinkhole Collapse Extension",
          "ProductCode": "2020001",

          "ExtContent": "{\"PolicyCoverageId\":825037486371014}",
          "RiskCateId": "5",
          "CoveragePeriodAllowed": "N",
          "EffDate": "2020-03-01",
          "ExpDate": "2021-03-01",
        },
        {
          "@type": "ClaimPolicyCoverage-ClaimPolicyCoverage",
          "InitLossIndemnity": "0",
          "LossConfigDefaultAmount": "0",
          "CoverageCode": "2020001015",
          "PolicyCoverageCode": "2020001015",
          "CoverageName": "Cyber Protection",
          "ProductCode": "2020001",

          "ExtContent": "{\"PolicyCoverageId\":825037486371015}",
          "RiskCateId": "5",
          "CoveragePeriodAllowed": "N",
          "EffDate": "2020-03-01",
          "ExpDate": "2021-03-01",
        },
        {
          "@type": "ClaimPolicyCoverage-ClaimPolicyCoverage",
          "InitLossIndemnity": "0",
          "LossConfigDefaultAmount": "0",
          "CoverageCode": "2020001016",
          "PolicyCoverageCode": "2020001016",
          "CoverageName": "Class Total Blanket",
          "ProductCode": "2020001",

          "ExtContent": "{\"PolicyCoverageId\":825037486371016}",
          "RiskCateId": "5",
          "CoveragePeriodAllowed": "N",
          "EffDate": "2020-03-01",
          "ExpDate": "2021-03-01",
        },
        {
          "@type": "ClaimPolicyCoverage-ClaimPolicyCoverage",
          "InitLossIndemnity": "0",
          "LossConfigDefaultAmount": "0",
          "CoverageCode": "2020001017",
          "PolicyCoverageCode": "2020001017",
          "CoverageName": "Scheduled Property Item",
          "ProductCode": "2020001",

          "ExtContent": "{\"PolicyCoverageId\":825037486371017}",
          "RiskCateId": "5",
          "CoveragePeriodAllowed": "N",
          "EffDate": "2020-03-01",
          "ExpDate": "2021-03-01",
        },
        {
          "@type": "ClaimPolicyCoverage-ClaimPolicyCoverage",
          "InitLossIndemnity": "0",
          "LossConfigDefaultAmount": "0",
          "CoverageCode": "2020001018",
          "PolicyCoverageCode": "2020001018",
          "CoverageName": "Market Appreciation",
          "ProductCode": "2020001",

          "ExtContent": "{\"PolicyCoverageId\":825037486371}",
          "RiskCateId": "5",
          "CoveragePeriodAllowed": "N",
          "EffDate": "2020-03-01",
          "ExpDate": "2021-03-01",
        },
        {
          "@type": "ClaimPolicyCoverage-ClaimPolicyCoverage",
          "InitLossIndemnity": "0",
          "LossConfigDefaultAmount": "0",
          "CoverageCode": "2020001019",
          "PolicyCoverageCode": "2020001019",
          "CoverageName": "Landscape",
          "ProductCode": "2020001",

          "ExtContent": "{\"PolicyCoverageId\":825037486371019}",
          "RiskCateId": "5",
          "CoveragePeriodAllowed": "N",
          "EffDate": "2020-03-01",
          "ExpDate": "2021-03-01",
        },
        {
          "@type": "ClaimPolicyCoverage-ClaimPolicyCoverage",
          "InitLossIndemnity": "0",
          "LossConfigDefaultAmount": "0",
          "CoverageCode": "2020001020",
          "PolicyCoverageCode": "2020001020",
          "CoverageName": "Earthquake",
          "ProductCode": "2020001",

          "ExtContent": "{\"PolicyCoverageId\":825037486371020}",
          "RiskCateId": "5",
          "CoveragePeriodAllowed": "N",
          "EffDate": "2020-03-01",
          "ExpDate": "2021-03-01",
        },
        {
          "@type": "ClaimPolicyCoverage-ClaimPolicyCoverage",
          "InitLossIndemnity": "0",
          "LossConfigDefaultAmount": "0",
          "CoverageCode": "2020001021",
          "PolicyCoverageCode": "2020001021",
          "CoverageName": "Extended & Guaranteed Relacement Costs Dwelling",
          "ProductCode": "2020001",

          "ExtContent": "{\"PolicyCoverageId\":825037486371021}",
          "RiskCateId": "5",
          "CoveragePeriodAllowed": "N",
          "EffDate": "2020-03-01",
          "ExpDate": "2021-03-01",
        },
        {
          "@type": "ClaimPolicyCoverage-ClaimPolicyCoverage",
          "InitLossIndemnity": "0",
          "LossConfigDefaultAmount": "0",
          "CoverageCode": "2020001022",
          "PolicyCoverageCode": "2020001022",
          "CoverageName": "Extended & Guaranteed Relacement Costs Cotents",
          "ProductCode": "2020001",

          "ExtContent": "{\"PolicyCoverageId\":825037486371022}",
          "RiskCateId": "5",
          "CoveragePeriodAllowed": "N",
          "EffDate": "2020-03-01",
          "ExpDate": "2021-03-01",
        },
        {
          "@type": "ClaimPolicyCoverage-ClaimPolicyCoverage",
          "InitLossIndemnity": "0",
          "LossConfigDefaultAmount": "0",
          "CoverageCode": "2020001023",
          "PolicyCoverageCode": "2020001023",
          "CoverageName": "Identity Theft",
          "ProductCode": "2020001",

          "ExtContent": "{\"PolicyCoverageId\":825037486371023}",
          "RiskCateId": "5",
          "CoveragePeriodAllowed": "N",
          "EffDate": "2020-03-01",
          "ExpDate": "2021-03-01",
        },
        {
          "@type": "ClaimPolicyCoverage-ClaimPolicyCoverage",
          "InitLossIndemnity": "0",
          "LossConfigDefaultAmount": "0",
          "CoverageCode": "2020001024",
          "PolicyCoverageCode": "2020001024",
          "CoverageName": "Workers Compensation",
          "ProductCode": "2020001",

          "ExtContent": "{\"PolicyCoverageId\":825037486371024}",
          "RiskCateId": "5",
          "CoveragePeriodAllowed": "N",
          "EffDate": "2020-03-01",
          "ExpDate": "2021-03-01",
        }
      ]
    },
    {
      "@type": "ClaimPolicyInsured-ClaimPolicyInsured",
      "InsuredCategory": "3",
      "PolicyInsuredId": "825037486372",
      "ExtContent": "{\"InsuredId\":825037486372}",
      "InsuredName": "2222 First Drive, San Diego, US, 21213",

      "CoverageList": [
        {
          "@type": "ClaimPolicyCoverage-ClaimPolicyCoverage",
          "InitLossIndemnity": "0",
          "LossConfigDefaultAmount": "0",
          "CoverageCode": "2020001001",
          "PolicyCoverageCode": "2020001001",
          "CoverageName": "Contents",
          "ProductCode": "2020001",

          "ExtContent": "{\"PolicyCoverageId\":825037486372001}",
          "RiskCateId": "5",
          "CoveragePeriodAllowed": "N",
          "EffDate": "2020-03-01",
          "ExpDate": "2021-03-01",
        },
        {
          "@type": "ClaimPolicyCoverage-ClaimPolicyCoverage",
          "InitLossIndemnity": "0",
          "LossConfigDefaultAmount": "0",
          "CoverageCode": "2020001002",
          "PolicyCoverageCode": "2020001002",
          "CoverageName": "Ded Waiver Large Losses",
          "ProductCode": "2020001",

          "ExtContent": "{\"PolicyCoverageId\":825037486372002}",
          "RiskCateId": "5",
          "CoveragePeriodAllowed": "N",
          "EffDate": "2020-03-01",
          "ExpDate": "2021-03-01",
        },
        {
          "@type": "ClaimPolicyCoverage-ClaimPolicyCoverage",
          "InitLossIndemnity": "0",
          "LossConfigDefaultAmount": "0",
          "CoverageCode": "2020001003",
          "PolicyCoverageCode": "2020001003",
          "CoverageName": "Dwelling",
          "ProductCode": "2020001",

          "ExtContent": "{\"PolicyCoverageId\":825037486372003}",
          "RiskCateId": "5",
          "CoveragePeriodAllowed": "N",
          "EffDate": "2020-03-01",
          "ExpDate": "2021-03-01",
        },
        {
          "@type": "ClaimPolicyCoverage-ClaimPolicyCoverage",
          "InitLossIndemnity": "0",
          "LossConfigDefaultAmount": "0",
          "CoverageCode": "2020001004",
          "PolicyCoverageCode": "2020001004",
          "UpperCoverageCode": "2020001003",
          "CoverageName": "Dwelling",
          "ProductCode": "2020001",

          "ExtContent": "{\"PolicyCoverageId\":825037486372004}",
          "RiskCateId": "5",
          "CoveragePeriodAllowed": "N",
          "EffDate": "2020-03-01",
          "ExpDate": "2021-03-01",
        },
        {
          "@type": "ClaimPolicyCoverage-ClaimPolicyCoverage",
          "InitLossIndemnity": "0",
          "LossConfigDefaultAmount": "0",
          "CoverageCode": "2020001005",
          "PolicyCoverageCode": "2020001005",
          "UpperCoverageCode": "2020001003",
          "CoverageName": "Sewer & Drain Back-Up",
          "ProductCode": "2020001",

          "ExtContent": "{\"PolicyCoverageId\":825037486372005}",
          "RiskCateId": "5",
          "CoveragePeriodAllowed": "N",
          "EffDate": "2020-03-01",
          "ExpDate": "2021-03-01",
        },
        {
          "@type": "ClaimPolicyCoverage-ClaimPolicyCoverage",
          "InitLossIndemnity": "0",
          "LossConfigDefaultAmount": "0",
          "CoverageCode": "2020001006",
          "PolicyCoverageCode": "2020001006",
          "CoverageName": "Business Property",
          "ProductCode": "2020001",

          "ExtContent": "{\"PolicyCoverageId\":825037486372006}",
          "RiskCateId": "5",
          "CoveragePeriodAllowed": "N",
          "EffDate": "2020-03-01",
          "ExpDate": "2021-03-01",
        },
        {
          "@type": "ClaimPolicyCoverage-ClaimPolicyCoverage",
          "InitLossIndemnity": "0",
          "LossConfigDefaultAmount": "0",
          "CoverageCode": "2020001007",
          "PolicyCoverageCode": "2020001007",
          "CoverageName": "Ensuing Fungi Increase",
          "ProductCode": "2020001",

          "ExtContent": "{\"PolicyCoverageId\":825037486372007}",
          "RiskCateId": "5",
          "CoveragePeriodAllowed": "N",
          "EffDate": "2020-03-01",
          "ExpDate": "2021-03-01",
        },
        {
          "@type": "ClaimPolicyCoverage-ClaimPolicyCoverage",
          "InitLossIndemnity": "0",
          "LossConfigDefaultAmount": "0",
          "CoverageCode": "2020001008",
          "PolicyCoverageCode": "2020001008",
          "CoverageName": "Home Systems Protection",
          "ProductCode": "2020001",

          "ExtContent": "{\"PolicyCoverageId\":825037486372008}",
          "RiskCateId": "5",
          "CoveragePeriodAllowed": "N",
          "EffDate": "2020-03-01",
          "ExpDate": "2021-03-01",
        },
        {
          "@type": "ClaimPolicyCoverage-ClaimPolicyCoverage",
          "InitLossIndemnity": "0",
          "LossConfigDefaultAmount": "0",
          "CoverageCode": "2020001009",
          "PolicyCoverageCode": "2020001009",
          "CoverageName": "Loss Of Use",
          "ProductCode": "2020001",

          "ExtContent": "{\"PolicyCoverageId\":825037486372009}",
          "RiskCateId": "5",
          "CoveragePeriodAllowed": "N",
          "EffDate": "2020-03-01",
          "ExpDate": "2021-03-01",
        },
        {
          "@type": "ClaimPolicyCoverage-ClaimPolicyCoverage",
          "InitLossIndemnity": "0",
          "LossConfigDefaultAmount": "0",
          "CoverageCode": "2020001010",
          "PolicyCoverageCode": "2020001010",
          "CoverageName": "Medical Payments",
          "ProductCode": "2020001",

          "ExtContent": "{\"PolicyCoverageId\":825037486372010}",
          "RiskCateId": "5",
          "CoveragePeriodAllowed": "N",
          "EffDate": "2020-03-01",
          "ExpDate": "2021-03-01",
        },
        {
          "@type": "ClaimPolicyCoverage-ClaimPolicyCoverage",
          "InitLossIndemnity": "0",
          "LossConfigDefaultAmount": "0",
          "CoverageCode": "2020001011",
          "PolicyCoverageCode": "2020001011",
          "CoverageName": "Other Structures",
          "ProductCode": "2020001",

          "ExtContent": "{\"PolicyCoverageId\":825037486372011}",
          "RiskCateId": "5",
          "CoveragePeriodAllowed": "N",
          "EffDate": "2020-03-01",
          "ExpDate": "2021-03-01",
        },
        {
          "@type": "ClaimPolicyCoverage-ClaimPolicyCoverage",
          "InitLossIndemnity": "0",
          "LossConfigDefaultAmount": "0",
          "CoverageCode": "2020001012",
          "PolicyCoverageCode": "2020001012",
          "CoverageName": "Personal Liability",
          "ProductCode": "2020001",

          "ExtContent": "{\"PolicyCoverageId\":825037486372012}",
          "RiskCateId": "5",
          "CoveragePeriodAllowed": "N",
          "EffDate": "2020-03-01",
          "ExpDate": "2021-03-01",
        },
        {
          "@type": "ClaimPolicyCoverage-ClaimPolicyCoverage",
          "InitLossIndemnity": "0",
          "LossConfigDefaultAmount": "0",
          "CoverageCode": "2020001013",
          "PolicyCoverageCode": "2020001013",
          "CoverageName": "Service Line Protection",
          "ProductCode": "2020001",

          "ExtContent": "{\"PolicyCoverageId\":825037486372013}",
          "RiskCateId": "5",
          "CoveragePeriodAllowed": "N",
          "EffDate": "2020-03-01",
          "ExpDate": "2021-03-01",
        },
        {
          "@type": "ClaimPolicyCoverage-ClaimPolicyCoverage",
          "InitLossIndemnity": "0",
          "LossConfigDefaultAmount": "0",
          "CoverageCode": "2020001014",
          "PolicyCoverageCode": "2020001014",
          "CoverageName": "Sinkhole Collapse Extension",
          "ProductCode": "2020001",

          "ExtContent": "{\"PolicyCoverageId\":825037486372014}",
          "RiskCateId": "5",
          "CoveragePeriodAllowed": "N",
          "EffDate": "2020-03-01",
          "ExpDate": "2021-03-01",
        },
        {
          "@type": "ClaimPolicyCoverage-ClaimPolicyCoverage",
          "InitLossIndemnity": "0",
          "LossConfigDefaultAmount": "0",
          "CoverageCode": "2020001015",
          "PolicyCoverageCode": "2020001015",
          "CoverageName": "Cyber Protection",
          "ProductCode": "2020001",

          "ExtContent": "{\"PolicyCoverageId\":825037486372015}",
          "RiskCateId": "5",
          "CoveragePeriodAllowed": "N",
          "EffDate": "2020-03-01",
          "ExpDate": "2021-03-01",
        },
        {
          "@type": "ClaimPolicyCoverage-ClaimPolicyCoverage",
          "InitLossIndemnity": "0",
          "LossConfigDefaultAmount": "0",
          "CoverageCode": "2020001016",
          "PolicyCoverageCode": "2020001016",
          "CoverageName": "Class Total Blanket",
          "ProductCode": "2020001",

          "ExtContent": "{\"PolicyCoverageId\":825037486372016}",
          "RiskCateId": "5",
          "CoveragePeriodAllowed": "N",
          "EffDate": "2020-03-01",
          "ExpDate": "2021-03-01",
        },
        {
          "@type": "ClaimPolicyCoverage-ClaimPolicyCoverage",
          "InitLossIndemnity": "0",
          "LossConfigDefaultAmount": "0",
          "CoverageCode": "2020001017",
          "PolicyCoverageCode": "2020001017",
          "CoverageName": "Scheduled Property Item",
          "ProductCode": "2020001",

          "ExtContent": "{\"PolicyCoverageId\":825037486372017}",
          "RiskCateId": "5",
          "CoveragePeriodAllowed": "N",
          "EffDate": "2020-03-01",
          "ExpDate": "2021-03-01",
        },
        {
          "@type": "ClaimPolicyCoverage-ClaimPolicyCoverage",
          "InitLossIndemnity": "0",
          "LossConfigDefaultAmount": "0",
          "CoverageCode": "2020001018",
          "PolicyCoverageCode": "2020001018",
          "CoverageName": "Market Appreciation",
          "ProductCode": "2020001",

          "ExtContent": "{\"PolicyCoverageId\":825037486372}",
          "RiskCateId": "5",
          "CoveragePeriodAllowed": "N",
          "EffDate": "2020-03-01",
          "ExpDate": "2021-03-01",
        },
        {
          "@type": "ClaimPolicyCoverage-ClaimPolicyCoverage",
          "InitLossIndemnity": "0",
          "LossConfigDefaultAmount": "0",
          "CoverageCode": "2020001019",
          "PolicyCoverageCode": "2020001019",
          "CoverageName": "Landscape",
          "ProductCode": "2020001",

          "ExtContent": "{\"PolicyCoverageId\":825037486372019}",
          "RiskCateId": "5",
          "CoveragePeriodAllowed": "N",
          "EffDate": "2020-03-01",
          "ExpDate": "2021-03-01",
        },
        {
          "@type": "ClaimPolicyCoverage-ClaimPolicyCoverage",
          "InitLossIndemnity": "0",
          "LossConfigDefaultAmount": "0",
          "CoverageCode": "2020001020",
          "PolicyCoverageCode": "2020001020",
          "CoverageName": "Earthquake",
          "ProductCode": "2020001",

          "ExtContent": "{\"PolicyCoverageId\":825037486372020}",
          "RiskCateId": "5",
          "CoveragePeriodAllowed": "N",
          "EffDate": "2020-03-01",
          "ExpDate": "2021-03-01",
        },
        {
          "@type": "ClaimPolicyCoverage-ClaimPolicyCoverage",
          "InitLossIndemnity": "0",
          "LossConfigDefaultAmount": "0",
          "CoverageCode": "2020001021",
          "PolicyCoverageCode": "2020001021",
          "CoverageName": "Extended & Guaranteed Relacement Costs Dwelling",
          "ProductCode": "2020001",

          "ExtContent": "{\"PolicyCoverageId\":825037486372021}",
          "RiskCateId": "5",
          "CoveragePeriodAllowed": "N",
          "EffDate": "2020-03-01",
          "ExpDate": "2021-03-01",
        },
        {
          "@type": "ClaimPolicyCoverage-ClaimPolicyCoverage",
          "InitLossIndemnity": "0",
          "LossConfigDefaultAmount": "0",
          "CoverageCode": "2020001022",
          "PolicyCoverageCode": "2020001022",
          "CoverageName": "Extended & Guaranteed Relacement Costs Cotents",
          "ProductCode": "2020001",

          "ExtContent": "{\"PolicyCoverageId\":825037486372022}",
          "RiskCateId": "5",
          "CoveragePeriodAllowed": "N",
          "EffDate": "2020-03-01",
          "ExpDate": "2021-03-01",
        },
        {
          "@type": "ClaimPolicyCoverage-ClaimPolicyCoverage",
          "InitLossIndemnity": "0",
          "LossConfigDefaultAmount": "0",
          "CoverageCode": "2020001023",
          "PolicyCoverageCode": "2020001023",
          "CoverageName": "Identity Theft",
          "ProductCode": "2020001",

          "ExtContent": "{\"PolicyCoverageId\":825037486372023}",
          "RiskCateId": "5",
          "CoveragePeriodAllowed": "N",
          "EffDate": "2020-03-01",
          "ExpDate": "2021-03-01",
        },
        {
          "@type": "ClaimPolicyCoverage-ClaimPolicyCoverage",
          "InitLossIndemnity": "0",
          "LossConfigDefaultAmount": "0",
          "CoverageCode": "2020001024",
          "PolicyCoverageCode": "2020001024",
          "CoverageName": "Workers Compensation",
          "ProductCode": "2020001",

          "ExtContent": "{\"PolicyCoverageId\":825037486372024}",
          "RiskCateId": "5",
          "CoveragePeriodAllowed": "N",
          "EffDate": "2020-03-01",
          "ExpDate": "2021-03-01",
        }
      ]
    }
  ],
  "PartyList": [
    {
      "@type": "ClaimPolicyParty-ClaimPolicyParty",
      "RoleType": "07",
      "ContactType": "1",
      "IsInsured": "Y",
      "PartyName": "Henry_=_Damon",
      "IdType": "14",
      "IdNumber": "2020042106",
      "Email": "vaultTest@ebaotech.com",
      "Mobile": "19863728736"
    }
  ]
}
