import { JSONUtil } from 'RainbowUI';

const UrlConfig = { common: {} };

(() => {
  const setURL = projectConfig => {
    UrlConfig.loadSolrReinsurancer = `${projectConfig.serverUrl}/party/party/party/findPartyRole`;
    UrlConfig.loadQueryRiDetail = `${projectConfig.serverUrl}ri/eu00/loadQueryRiDetail/`;
    UrlConfig.localCurrency = `${projectConfig.serverUrl}public/common/currency/localCurrency`;
    UrlConfig.loadAssignerLsit_url = `${projectConfig.serverUrl}public/users/role/Underwriter`;
    UrlConfig.loadUserByUserId = `${projectConfig.serverUrl}public/users/`;

    UrlConfig.getCodeTableByNameUrl = `${projectConfig.serverUrl}public/codetable/data/list/name/`;
    UrlConfig.getCodeTableByNameByConditionUrl = `${projectConfig.serverUrl}public/codetable/data/condition/name/`;
    UrlConfig.loadCodeTable_url = `${projectConfig.serverUrl}public/codetable/data/list/`;
    UrlConfig.codetableList = `${projectConfig.serverUrl}public/codetable/data/list/`;
    UrlConfig.loadCodeTableByCondition = `${projectConfig.serverUrl}public/codetable/data/condition/`;
    UrlConfig.loadSalesChannelNameByPartyId = `${projectConfig.serverUrl}system/ap00sc/channel/setSCNameByPartyId`;

    UrlConfig.ap00SearchCustomer = `${projectConfig.serverUrl}public/ap00/query/entity`;
    UrlConfig.searchPartyRole = `${projectConfig.serverUrl}party/party/party/searchPartyRole`;
    UrlConfig.queryDocList = `${projectConfig.serverUrl}public/ap00/repository/query/{ModuleName}/{PolicyId}`;
    UrlConfig.queryByModuleAndFileName = `${projectConfig.serverUrl}public/ap00/repository/query/byModuleAndFileName/{ModuleName}/{Name}`;
    UrlConfig.previewFileFromDMS = `${projectConfig.serverUrl}public/ap00/repository/preview/`;

    UrlConfig.uploadFileToDMS = `${projectConfig.serverUrl}public/ap00/repository/upload`;

    UrlConfig.downloadFileFromDMS = `${projectConfig.serverUrl}public/ap00/repository/download/`;

    UrlConfig.removeFileFromDMS = `${projectConfig.serverUrl}public/ap00/repository/remove/{id}`;

    UrlConfig.loadExchangeRate_url = `${projectConfig.serverUrl}system/ap00/common/exchangeRate/`;
    UrlConfig.loadLocalExchangeRate_url = `${projectConfig.serverUrl}system/ap00/common/LocalExchangeRate/`;
    UrlConfig.clearCache = `${projectConfig.serverUrl}public/cache/v1/clear/all`;

    UrlConfig.getProductMasterList = 'product/list/master';
    UrlConfig.getProduct = `${projectConfig.serverUrl}product/`;

    UrlConfig.loadMaskDefinitionInfo = `${projectConfig.serverUrl}public/dictionary/resource/loadMaskDefinitionInfo`;

    UrlConfig.getBranchUserUrl = `${projectConfig.serverUrl}public/ap00/users/branch/ByBranchId`;
    UrlConfig.getUnderWriterUrl = 'public/users/role/Underwriter';
    UrlConfig.getUnderWriterAssistantUrl =
      'public/users/role/UnderwriterAssistant';
    UrlConfig.getAccountManagerUrl =
      'public/users/role/AccountManager';
    UrlConfig.getTechnicalAccountUrl =
      'public/users/role/TechnicalAccounting';
    UrlConfig.getDelegationUsers = `${projectConfig.serverUrl}public/ap00/users/roles/names`;
    UrlConfig.getUserPermissionCodes = `${projectConfig.serverUrl}/public/authorities/users/permissioncodes`;
    UrlConfig.getUserByPartyId = `${projectConfig.serverUrl}public/users/{userId}`;
    UrlConfig.getPartyBranchByPartyId = `${projectConfig.serverUrl}public/party/load`;

    UrlConfig.ap00GetClause = `${projectConfig.serverUrl}form/ap00/form/model/load/byTypeAndProductCodeAndEffDate`;
    UrlConfig.ap00GetConfigs = `${projectConfig.serverUrl}form/ap00/form/config/load/byTypeAndProductCodeAndEffDate`;

    UrlConfig.searchCustomerByCondition = `${projectConfig.serverUrl}public/query/entity`;
    UrlConfig.modifyOrgCustomer = `${projectConfig.serverUrl}pa/party/update`;
    UrlConfig.loadCustomerByPartyId = `${projectConfig.serverUrl}pa/customer/load/byCustomerId`;

    UrlConfig.createCustomer = `${projectConfig.serverUrl}pa/party/create`;

    UrlConfig.common.casUrl = projectConfig.cas_url;
    UrlConfig.common.mainUrl = projectConfig.MAIN_URL;

    UrlConfig.getOrgNameByOrgId = `${projectConfig.serverUrl}pa/ap00/coCompany/load/{partyId}`;
    UrlConfig.getIndividualAgentNameByAgentId = `${projectConfig.serverUrl}system/sc/indi/{agentId}`;
    UrlConfig.getGroupAgentNameByAgentId = `${projectConfig.serverUrl}system/sc/org/{agentId}`;

    UrlConfig.currentTime = `${projectConfig.serverUrl}public/ap00/content/currentTime`;

    UrlConfig.paCommonUrl = projectConfig.serverUrl;
    UrlConfig.loadProductList_url = `${projectConfig.serverUrl}product/query/getAllProductList`;

    UrlConfig.initClauseData = `${projectConfig.serverUrl}form/formConfig/formConfig/list`;
    UrlConfig.loadPolicyClauseList = `${projectConfig.serverUrl}form/policyForm/listPolicyForm/`;
    UrlConfig.deleteFormByPolicyId = `${projectConfig.serverUrl}form/policyForm/deleteFormByPolicyId/`;
    UrlConfig.savePolicyClauseList = `${projectConfig.serverUrl}form/policyForm/savePolicyFormByPolicyId`;
    UrlConfig.loadLoginUserSalesChannelCode = `${projectConfig.serverUrl}system/ap00sc/channel/loadCurrentUserAgentCode`;

    UrlConfig.currentUser = `${projectConfig.serverUrl}public/ap00/content/currentUser`;

    UrlConfig.getUIPageByNameAndContext = `${projectConfig.serverUrl}ui/page/byNameAndContextId`;

    UrlConfig.CurrentUserInformation = `${projectConfig.serverUrl}public/users/current/info`;
    UrlConfig.UserPermissionCodes = `${projectConfig.serverUrl}/public/authorities/users/permissioncodes`;
    UrlConfig.loadLoginUserSalesChannelCode = `${projectConfig.serverUrl}system/ap00sc/channel/loadCurrentUserAgentCode`;

    UrlConfig.getPermissionByTypeUserIdParentName = `${projectConfig.serverUrl}public/permissions/loadRolePermissionByRoleIdAndPermissionType/`;

    UrlConfig.getRoleInsertTimeByRoleId = `${projectConfig.serverUrl}party/party/party/getRoleInsertTimeByRoleId`;
    UrlConfig.partyCommonUrl = `${projectConfig.PARTY_HOME_URL}`;
    UrlConfig.createNewParty = `${projectConfig.serverUrl}party/party/party/create`;
    UrlConfig.updateParty = `${projectConfig.serverUrl}party/party/party/update`;
    UrlConfig.loadPartyByPartyId = `${projectConfig.serverUrl}party/party/party/byPartyId`;
    UrlConfig.queryParty = `${projectConfig.serverUrl}party/party/searchParty`;
    UrlConfig.loadHistoryByHistoryId = `${projectConfig.serverUrl}party/party/historybyHistoryId/`;
    UrlConfig.queryRegionByCountryCodeAndPostCode = `${projectConfig.serverUrl}party/region/region/byCountryCodeAndPostCode`;
    UrlConfig.ap00GetCustomerCode = `${projectConfig.serverUrl}pa/ap00/numbering/generate/customer/code`;
    UrlConfig.loadPartyNameForBank = `${projectConfig.serverUrl}party/party/party/findPartyRole`;
    UrlConfig.checkPartyAdditional = `${projectConfig.serverUrl}party/party/checkParty`;
    UrlConfig.findPtyByPartyId = `${projectConfig.serverUrl}party/party/party/findPtyByPartyId/`;
    UrlConfig.loadPartyHistoryListByPartyId = `${projectConfig.serverUrl}party/party/party/loadPartyHistoryByPartyId/`;
    UrlConfig.findPartyNameByCustomerCode = `${projectConfig.serverUrl}party/party/party/getPartyByCustomerCode/`;
    UrlConfig.loadSolrReinsurancer = `${projectConfig.serverUrl}party/party/party/findPartyRole`;

    UrlConfig.getPartyByCustomerCode = `${projectConfig.serverUrl}party/party/party/getPartyByCustomerCode/`;
    UrlConfig.getPartyByPartyCode = `${projectConfig.serverUrl}party/party/party/getPartyByPartyCode/`;
    UrlConfig.queryRegionByCountryCodeAndRegionName = `${projectConfig.serverUrl}party/region/region/byCountryCodeAndRegionName`;
    UrlConfig.uploadFile = `${projectConfig.serverUrl}party/party/party/uploadFile`;
    UrlConfig.downloadFile = `${projectConfig.serverUrl}party/party/party/downloadFile`;
    UrlConfig.getMinorAge = `${projectConfig.serverUrl}party/party/party/getMinorAge`;
    UrlConfig.checkPartyShareholderPercentage = `${projectConfig.serverUrl}party/party/checkPartyShareholderPercentage`;
    UrlConfig.findPartyVenByJointVentureName = `${projectConfig.serverUrl}party/party/party/findPartyVenByJointVentureName`;
    UrlConfig.createThirdParty = `${projectConfig.serverUrl}party/party/party/createThirdParty`;
    UrlConfig.findPartyIndividualByIdNumber = `${projectConfig.serverUrl}party/party/findPartyIndividualByIdNumber`;

    UrlConfig.partyGroupSaveOrUpdate = `${projectConfig.serverUrl}party/group/saveOrUpdate`;
    UrlConfig.partySearchByGroupId = `${projectConfig.serverUrl}party/group/searchByGroupId`;
    UrlConfig.searchPartyGroupByCondition = `${projectConfig.serverUrl}party/group/searchPartyGroupByCondition`;

    UrlConfig.createNewJCCPayment = `${projectConfig.serverUrl}bcp/OnlinePayment/createNewJCCPayment`;
    UrlConfig.getCurrentMonthForEndBatch = `${projectConfig.serverUrl}bcp/monthEndBatch/getCurrentMonthForEndBatch`;
    UrlConfig.createNewJCCPaymentWithoutCollection = `${projectConfig.serverUrl}bcp/OnlinePayment/createNewJCCPaymentWithoutCollection`;
    UrlConfig.queryPaidAmount = `${projectConfig.serverUrl}bcp/OnlinePayment/queryPaidAmount`;
    UrlConfig.updateJCCPaymentRecord = `${projectConfig.serverUrl}bcp/OnlinePayment/updateJCCPaymentRecord`;
    UrlConfig.updateJCCPaymentRecordWithoutCollection = `${projectConfig.serverUrl}bcp/OnlinePayment/updateJCCPaymentRecordWithoutCollection`;
    UrlConfig.getTotalBalanceByRefNo = `${projectConfig.serverUrl}bcp/OnlinePayment/getTotalBalanceByRefNo`;
    UrlConfig.arapSearchByGroup = `${projectConfig.serverUrl}bcp/query/queryFeeGroup`;
    UrlConfig.arapSearchListByGroupInfo = `${projectConfig.serverUrl}bcp/query/queryFeeListByGroupInfo`;
    UrlConfig.dd_downloadXMLByMsgNo = `${projectConfig.serverUrl}bcp/eu00/ddgenerate/downloadddxmlbyMsgNo/`;

    UrlConfig.loadPolicyIdByPolicyNo = `${projectConfig.serverUrl}pa/policy/policyId/byPolicyNo`;
    UrlConfig.loadPolicy = `${projectConfig.serverUrl}pa/policy/load/byPolicyId`;
    UrlConfig.loadEndorsement = `${projectConfig.serverUrl}pa/endorsement/load/`;
    UrlConfig.ap00GeneratePrmDtls = `${projectConfig.serverUrl}pa/ap00/policy/generatePrmDtls`;
    UrlConfig.getNewbizPolicyFeeInfo = `${projectConfig.serverUrl}pa/policy/newbizfee/load`;
    UrlConfig.getEndoPolicyFeeInfo = `${projectConfig.serverUrl}pa/policy/endofee/load`;
    UrlConfig.getFirstEffectiveRevision = `${projectConfig.serverUrl}pa/policy/revision/getFirstEffectiveRevision`;
    UrlConfig.loadIssuedNewbiz = `${projectConfig.serverUrl}pa/ap00/policy/revision/load/byPolicyIdAndRevisionId`;
    UrlConfig.getEndoIdListByPolicyId = `${projectConfig.serverUrl}bcp/query/getEndoIdListByPolicyId`;
    UrlConfig.queryEndoFeeListByEndoIdIncludeCancel = `${projectConfig.serverUrl}bcp/query/queryEndoFeeListByEndoIdIncludeCancel`;
    UrlConfig.queryPolicyFeeListByPolicyIdIncludeCancel = `${projectConfig.serverUrl}bcp/query/queryPolicyFeeListByPolicyIdIncludeCancel`;
    UrlConfig.getPermissionByType = `${projectConfig.serverUrl}public/permissions/`;
  };

  // var baseUrl = { serverUrl: '' };
  // let config = './project_config.json';
  // if (location.hostname.indexOf('ebaogi-gidev') > -1) {
  //   config = './project_config.dev.json';
  // } else if (location.hostname.indexOf('ebaogi-gitst') > -1) {
  //   config = './project_config.tst.json';
  // } else if (location.hostname.indexOf('210.13.77.89') > -1) {
  //   config = './project_config.shanghai-demo.json';
  // }
  // baseUrl = JSONUtil.getJSON(config);
  // setURL(baseUrl);
  //var projectConfig = sessionStorage.getItem("project_config");
  //var projectConfigJson = JSON.parse(projectConfig);
  setURL(JSON.parse(localStorage.getItem('project_config')));
})();

export default UrlConfig;
