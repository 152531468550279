import {
  DataContext,
  UpdateContext,
  MessageHelper,
  AjaxUtil,
} from 'RainbowUI';
import {
  RestStatus,
  ClaimMessageForamtUtils,
} from 'ClaimCommonConstant';
import TreeNode from '../constant/TreeNodeCache';

const url = require('../url/Url');

export default {
  getDefaultReserveList(pageindex, pagesize) {
    const reserve = DataContext.get('defaultReserve');
    const treeNode = TreeNode.TreeCache.get('TreeNode');
    const param = {};
    param.Limit = pagesize;
    param.Start = pageindex - 1;
    param.ReserveTypeCode = reserve.ReserveTypeCode;
    param.SubclaimTypeCode = reserve.SubclaimTypeCode;
    param.CoverageCode = reserve.CoverageCode;
    param.ProductCode = treeNode.objectMap.pId;
    let result = {};
    AjaxUtil.doPost(url.ClaimProductDefaultReserveGetList, param, {
      async: false,
      done(data) {
        if (data.Status == RestStatus.OK) {
          result = data.Model;
        } else {
          MessageHelper.error(data.Status);
        }
      },
      fail(data) {
        MessageHelper.error(data.responseText);
      },
    });
    return { count: result.Count, result: result.Module };
  },

  save() {
    const de = DataContext.get('ChangeData');

    if (!de) {
      MessageHelper.info(
        i18n.ClaimProduct.InfoMsg.Save,
        i18n.ClaimProduct.InfoMsg.PleaseModifyItems
      );
      return;
    }
    AjaxUtil.doPost(
      url.ClaimProductDefaultReserveSaveList,
      JSON.stringify(de),
      {
        async: false,
        done(data) {
          if (data.Status == RestStatus.OK) {
            MessageHelper.success(
              ClaimMessageForamtUtils.formatI18NMsg(
                i18n.ClaimCommon.SaveSuccess,
                i18n.ClaimProduct.DefaultReserve
              )
            );
          } else {
            MessageHelper.error(
              ClaimMessageForamtUtils.formatI18NMsg(
                i18n.ClaimCommon.SaveFail,
                i18n.ClaimProduct.DefaultReserve
              )
            );
          }
        },
        fail(data) {
          MessageHelper.error(data.responseText);
        },
      }
    );
  },
  searchChange(event) {
    UpdateContext.forceUpdate('defaultReserveResultTable');
    const list = [];
    DataContext.put('ChangeData', list);
  },
  init() {
    const Reserve = {};
    Reserve.ReserveTypeCode = '';
    Reserve.SubclaimTypeCode = '';
    Reserve.CoverageCode = '';
    DataContext.put('defaultReserve', Reserve);
  },
  saveChangeData(event) {
    const data = event.getParameter('data');
    data.DefaultAmount = event.newValue;

    let list = DataContext.get('ChangeData');
    if (!list) {
      list = [];
    }
    delete data.dataIndex;
    list.push(data);
    DataContext.put('ChangeData', list);
  },

  getCoverageFilterList(req) {
    if (!req.productCode) return;
    const { productCode } = req;
    let result = {};
    AjaxUtil.doGet(
      `${url.ClaimProductCoverageListByProductCode}/${productCode}`,
      {},
      {
        async: false,
        done(data) {
          if (data.Status == RestStatus.OK) {
            result = data.Model.map(arr => {
              return arr.CoverageCode;
            });
          }
        },
        fail() {
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
        },
      }
    );
    return { COVERAGE_CODE: result };
  },

  getSubClaimTypeFilterList(req) {
    if (!req.productLineCode) return;
    const { productLineCode } = req;
    let result = {};
    AjaxUtil.doGet(
      `${url.ClaimSubclaimTypeList}/${productLineCode}`,
      {},
      {
        async: false,
        done(data) {
          if (data.Status == RestStatus.OK) {
            result = data.Model.map(arr => {
              return arr.SubclaimTypeCode;
            });
          }
        },
        fail() {
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
        },
      }
    );
    return { Code: result };
  },
};
