import alt from '../alt';
import ProductCfgTreeAction from '../action/ProductCfgTreeAction';

class ProductTreeStore {
  constructor() {
    this.bindListeners({
      handleInitTree: ProductCfgTreeAction.INIT_DATA,
    });

    this.ProductTree = [];
  }

  handleInitTree(ProductTree) {
    this.ProductTree = ProductTree;
  }
}
export default alt.createStore(ProductTreeStore, ProductTreeStore);
