import {
  UIPanel,
  UIText,
  UICheckbox,
  UIParam,
  UIDataTable,
  UIColumn,
  UICurrency,
  UILink,
  UIRadio,
  CodeTable,
} from 'RainbowUI';
import BindToMixin from 'react-binding';
import { ClaimMessageForamtUtils } from 'ClaimCommonConstant';
import AppraisalVehicleStore from '../../store/AppraisalVehicleStore';

const BigNumber = require('bignumber.js');
const AppraisalVehicleAction = require('../../action/AppraisalVehicleAction');

const AppraisalSparePartCost = React.createClass({
  mixins: [BindToMixin],

  getInitialState() {
    return { claimAppraisalVehicle: this.props.appraisalVehicle };
  },

  componentWillMount() {
    AppraisalVehicleStore.listen(this.onStoreStateChange);
  },

  componentWillUnmount() {
    AppraisalVehicleStore.unlisten(this.onStoreStateChange);
  },

  onStoreStateChange() {
    this.forceUpdate();
  },

  getTableResult() {
    return this.state;
  },

  getTotalSparePartCostInfo() {
    return this.state.mockValue.result;
  },

  addSparePartCostItem() {
    AppraisalVehicleAction.addSpareCostItem();
  },

  getSparePartCostInfo() {
    return this.state.mockValue.result;
  },

  buildTableFunction(io) {
    return (
      <div>
        <UILink
          icon="fa fa-plus"
          size="lg"
          iconClass="add_iconbtn"
          onClick={this.addSparePartCostItem}
          visibled={io !== 'out'}
        />
      </div>
    );
  },

  totalPlus(x, y) {
    const a = new BigNumber(Number(x));
    const b = new BigNumber(Number(y));
    const totalAmt = a.plus(b);
    return totalAmt.toFixed(2);
  },
  componentWillUpdate() {
    if (
      this.state.claimAppraisalVehicle &&
      this.state.claimAppraisalVehicle.sourceObject &&
      this.state.claimAppraisalVehicle.sourceObject.appraisalVehicle
        .PartsType
    ) {
      this.selectSparePartCostValue(
        this.state.claimAppraisalVehicle.sourceObject.appraisalVehicle
          .PartsType
      );
    }
  },
  getTotalSparePartCostCRP() {
    const claimAppraisalVehicle = this.props.appraisalVehicle;
    const sparePartItem = this.bindArrayTo(
      claimAppraisalVehicle,
      'claimAppraisalVehiclePart'
    ).items;
    let totalCRP = 0;
    let totalBP = 0;
    let totalPP = 0;
    for (let i = 0; i <= sparePartItem.length - 1; i += 1) {
      totalCRP = this.totalPlus(
        totalCRP,
        sparePartItem[i].sourceObject.ReferenceAmount
      );
      totalBP = this.totalPlus(
        totalBP,
        sparePartItem[i].sourceObject.BodyshopAmount
      );
      totalPP = this.totalPlus(
        totalPP,
        sparePartItem[i].sourceObject.ProviderAmout
      );
    }

    const { CurrencyCode } = this.props.parentComponent.state;
    totalCRP = this.fmoney(totalCRP, 2);
    return new CodeTable([
      {
        id: 1,
        pid: totalCRP,
        text: (
          <div
            style={{
              display: 'inline-block',
              position: 'relative',
              width: '185px',
              left: '8px',
            }}
          >
            <span>
              {window.EU00.getCurrencySignByCurrencyCode(
                CurrencyCode
              )}
            </span>
            <span style={{ 'padding-left': '6px' }}>{totalCRP}</span>
          </div>
        ),
      },
    ]);
  },

  fmoney(tmps, tmpn) {
    let s = tmps;
    let n = tmpn;
    n = n > 0 && n <= 20 ? n : 2;
    s = `${parseFloat(`${s}`.replace(/[^\d.-]/g, '')).toFixed(n)}`;
    const l = s
      .split('.')[0]
      .split('')
      .reverse();
    const r = s.split('.')[1];
    let t = '';
    for (let i = 0; i < l.length; i += 1) {
      t +=
        l[i] + ((i + 1) % 3 === 0 && i + 1 !== l.length ? ',' : '');
    }
    return `${t
      .split('')
      .reverse()
      .join('')}.${r}`;
  },

  getTotalSparePartCostBP() {
    const claimAppraisalVehicle = this.props.appraisalVehicle;
    const sparePartItem = this.bindArrayTo(
      claimAppraisalVehicle,
      'claimAppraisalVehiclePart'
    ).items;
    let totalCRP = 0;
    let totalBP = 0;
    let totalPP = 0;
    for (let i = 0; i <= sparePartItem.length - 1; i += 1) {
      totalCRP = this.totalPlus(
        totalCRP,
        sparePartItem[i].sourceObject.ReferenceAmount
      );
      totalBP = this.totalPlus(
        totalBP,
        sparePartItem[i].sourceObject.BodyshopAmount
      );
      totalPP = this.totalPlus(
        totalPP,
        sparePartItem[i].sourceObject.ProviderAmout
      );
    }

    const { CurrencyCode } = this.props.parentComponent.state;
    totalBP = this.fmoney(totalBP, 2);
    return new CodeTable([
      {
        id: 2,
        pid: totalBP,
        text: (
          <div
            style={{
              display: 'inline-block',
              position: 'relative',
              width: '185px',
              left: '8px',
            }}
          >
            <span>
              {window.EU00.getCurrencySignByCurrencyCode(
                CurrencyCode
              )}
            </span>
            <span style={{ 'padding-left': '6px' }}>{totalBP}</span>
          </div>
        ),
      },
    ]);
  },
  getTotalSparePartCostPP() {
    const claimAppraisalVehicle = this.props.appraisalVehicle;
    const sparePartItem = this.bindArrayTo(
      claimAppraisalVehicle,
      'claimAppraisalVehiclePart'
    ).items;
    let totalCRP = 0;
    let totalBP = 0;
    let totalPP = 0;
    for (let i = 0; i <= sparePartItem.length - 1; i += 1) {
      totalCRP = this.totalPlus(
        totalCRP,
        sparePartItem[i].sourceObject.ReferenceAmount
      );
      totalBP = this.totalPlus(
        totalBP,
        sparePartItem[i].sourceObject.BodyshopAmount
      );
      totalPP = this.totalPlus(
        totalPP,
        sparePartItem[i].sourceObject.ProviderAmout
      );
    }

    const { CurrencyCode } = this.props.parentComponent.state;
    totalPP = this.fmoney(totalPP, 2);
    return new CodeTable([
      {
        id: 3,
        pid: totalPP,
        text: (
          <div
            style={{
              display: 'inline-block',
              position: 'relative',
              width: '185px',
              left: '8px',
            }}
          >
            <span>
              {window.EU00.getCurrencySignByCurrencyCode(
                CurrencyCode
              )}
            </span>
            <span style={{ 'padding-left': '6px' }}>{totalPP}</span>
          </div>
        ),
      },
    ]);
  },

  getTotalSparePartCost() {
    const claimAppraisalVehicle = this.props.appraisalVehicle;
    const sparePartItem = this.bindArrayTo(
      claimAppraisalVehicle,
      'claimAppraisalVehiclePart'
    ).items;
    let totalCRP = 0;
    let totalBP = 0;
    let totalPP = 0;
    for (let i = 0; i <= sparePartItem.length - 1; i += 1) {
      totalCRP += sparePartItem[i].sourceObject.ReferenceAmount;
      totalBP += sparePartItem[i].sourceObject.BodyshopAmount;
      totalPP += sparePartItem[i].sourceObject.ProviderAmout;
    }

    const { CurrencyCode } = this.props.parentComponent.state;
    return new CodeTable([
      {
        id: 1,
        pid: totalCRP,
        text: (
          <UICurrency
            unit={window.EU00.getCurrencySignByCurrencyCode(
              CurrencyCode
            )}
            value={totalCRP}
            enabled="false"
          />
        ),
      },
      {
        id: 2,
        pid: totalBP,
        text: (
          <UICurrency
            unit={window.EU00.getCurrencySignByCurrencyCode(
              CurrencyCode
            )}
            value={totalBP}
            enabled="false"
          />
        ),
      },
      {
        id: 3,
        pid: totalPP,
        text: (
          <UICurrency
            unit={window.EU00.getCurrencySignByCurrencyCode(
              CurrencyCode
            )}
            value={totalPP}
            enabled="false"
          />
        ),
      },
    ]);
  },
  refresh() {
    this.getTotalSparePartCostCRP();
    this.getTotalSparePartCostBP();
    this.getTotalSparePartCostPP();
    this.forceUpdate();
  },

  selectSparePartCost(event) {
    this.selectSparePartCostValue(event.jsEvent.target.defaultValue);
  },
  selectSparePartCostValue(value) {
    this.state.claimAppraisalVehicle.sourceObject.appraisalVehicle.PartsType = value;
    const selectIndex = value - 1;

    const codeTableSpareCost = this.getTotalSparePartCost().codes;
    let selectValue = '';
    for (const key in codeTableSpareCost) {
      if (key == selectIndex) {
        selectValue = codeTableSpareCost[key].pid;
        break;
      }
    }

    this.props.parentComponent.changSpareInTotalCost(
      parseFloat(selectValue)
    );
  },
  componentDidMount() { },
  getIOStyle() {
    return this.props.readOnly == true ? 'out' : null;
  },

  getVisibled() {
    return this.props.readOnly == true ? 'false' : 'true';
  },
  returnUsedValue() {
    return new CodeTable([{ id: 'Y', text: 'N' }]);
  },
  onChangeTest() { },
  render() {
    const io = this.getIOStyle();
    const visibled = this.getVisibled();
    const _ref = this;
    const {
      appraisalVehicle: claimAppraisalVehicle,
      history,
    } = this.props;

    const appraisalVehicleSparePartItem = this.bindArrayTo(
      claimAppraisalVehicle,
      'claimAppraisalVehiclePart'
    ).items;
    let style;
    if (history) {
      style = 'default';
    } else {
      style = 'primary';
    }
    const length = appraisalVehicleSparePartItem.length - 1;

    const CurrencyCode =
      appraisalVehicleSparePartItem[0].value.CurrencCode;
    let indexableSparePartCost = 0;
    return (
      <UIPanel
        panelTitle={i18n.ClaimAppraisal.SparePartCost}
        toggleable="true"
        styleClass={style}
      >
        <UIDataTable
          id="ClaimAppraisalVehicleSpareCostItems_DataTable"
          pageable="false"
          indexable="false"
          value={appraisalVehicleSparePartItem}
          functions={this.buildTableFunction(io)}
        >
          <UIColumn
            headerTitle={i18n.ClaimAppraisal.PartsName}
            render={data => {
              if (data.dataIndex == length) {
                return <div />;
              }
              return (
                <UIText
                  model={data.value}
                  property="PartsName"
                  enabled={visibled}
                  requiredMessage={ClaimMessageForamtUtils.formatI18NMsg(
                    i18n.ClaimCommon.I18nUtilValidate,
                    i18n.ClaimAppraisal.PartsName
                  )}
                  required="true"
                  validationGroup="appraisalSubmit"
                  label=""
                  widthAllocation="2,10"
                />
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.ClaimAppraisal.PartsDescription}
            render={data => {
              if (data.dataIndex == length) {
                return (
                  <UIText
                    model={data.value}
                    property="PartsDesc"
                    io="out"
                  />
                );
              }
              return (
                <UIText
                  model={data.value}
                  property="PartsDesc"
                  enabled={visibled}
                />
              );
            }}
          />

          <UIColumn
            headerTitle={i18n.ClaimAppraisal.CompanyReferencePrice}
            render={data => {
              indexableSparePartCost += 1;
              if (data.dataIndex == length) {
                return (
                  <UIRadio
                    valueLink={_ref.bindTo(
                      _ref.props.appraisalVehicle,
                      'PartsType'
                    )}
                    codeTable={_ref.getTotalSparePartCostCRP}
                    onChange={_ref.selectSparePartCost}
                    enabled={visibled}
                    requiredMessage={ClaimMessageForamtUtils.formatI18NMsg(
                      i18n.ClaimCommon.I18nUtilValidate,
                      i18n.ClaimAppraisal.CompanyReferencePrice
                    )}
                    validationGroup="appraisalSubmit"
                    label=""
                    widthAllocation="0,12"
                  />
                );
              }
              return (
                <UICurrency
                  id={`CURRENCY_CODE_ReferenceAmount${indexableSparePartCost}`}
                  model={data.value}
                  property="ReferenceAmount"
                  onChange={_ref.refresh}
                  unit={window.EU00.getCurrencySignByCurrencyCode(
                    CurrencyCode
                  )}
                  enabled={visibled}
                  defaultValue="0"
                  requiredMessage={ClaimMessageForamtUtils.formatI18NMsg(
                    i18n.ClaimCommon.I18nUtilValidate,
                    i18n.ClaimAppraisal.CompanyReferencePrice
                  )}
                  required="true"
                  validationGroup="appraisalSubmit"
                  label=""
                  widthAllocation="2,10"
                />
              );
            }}
          />

          <UIColumn
            headerTitle={i18n.ClaimAppraisal.BodyShopPrice}
            render={data => {
              if (data.dataIndex == length) {
                return (
                  <UIRadio
                    valueLink={_ref.bindTo(
                      _ref.props.appraisalVehicle,
                      'PartsType'
                    )}
                    codeTable={_ref.getTotalSparePartCostBP}
                    onChange={_ref.selectSparePartCost}
                    enabled={visibled}
                    requiredMessage={ClaimMessageForamtUtils.formatI18NMsg(
                      i18n.ClaimCommon.I18nUtilValidate,
                      i18n.ClaimAppraisal.CompanyReferencePrice
                    )}
                    validationGroup="appraisalSubmit"
                    label=""
                    widthAllocation="0,12"
                  />
                );
              }
              return (
                <UICurrency
                  id={`CURRENCY_CODE_BodyshopAmount${indexableSparePartCost}`}
                  model={data.value}
                  property="BodyshopAmount"
                  onChange={_ref.refresh}
                  unit={window.EU00.getCurrencySignByCurrencyCode(
                    CurrencyCode
                  )}
                  enabled={visibled}
                  defaultValue="0"
                  requiredMessage={ClaimMessageForamtUtils.formatI18NMsg(
                    i18n.ClaimCommon.I18nUtilValidate,
                    i18n.ClaimAppraisal.CompanyReferencePrice
                  )}
                  required="true"
                  label=""
                  validationGroup="appraisalSubmit"
                  widthAllocation="2,10"
                />
              );
            }}
          />

          <UIColumn
            headerTitle={i18n.ClaimAppraisal.ProviderPrice}
            render={data => {
              if (data.dataIndex == length) {
                return (
                  <UIRadio
                    valueLink={_ref.bindTo(
                      _ref.props.appraisalVehicle,
                      'PartsType'
                    )}
                    codeTable={_ref.getTotalSparePartCostPP}
                    onChange={_ref.selectSparePartCost}
                    enabled={visibled}
                    requiredMessage={ClaimMessageForamtUtils.formatI18NMsg(
                      i18n.ClaimCommon.I18nUtilValidate,
                      i18n.ClaimAppraisal.ProviderPrice
                    )}
                    validationGroup="appraisalSubmit"
                    label=""
                    widthAllocation="0,12"
                  />
                );
              }
              return (
                <UICurrency
                  id={`CURRENCY_CODE_ProviderAmount${indexableSparePartCost}`}
                  model={data.value}
                  property="ProviderAmout"
                  required="true"
                  validationGroup="appraisalSubmit"
                  onChange={_ref.refresh}
                  label=""
                  defaultValue="0"
                  requiredMessage={ClaimMessageForamtUtils.formatI18NMsg(
                    i18n.ClaimCommon.I18nUtilValidate,
                    i18n.ClaimAppraisal.ProviderPrice
                  )}
                  unit={window.EU00.getCurrencySignByCurrencyCode(
                    CurrencyCode
                  )}
                  enabled={visibled}
                  widthAllocation="2,10"
                />
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.ClaimAppraisal.UsedSparePart}
            width="15%"
            render={data => {
              if (data.dataIndex == length) {
                return <div />;
              }
              return (
                <UICheckbox
                  single="true"
                  defaultValue="N"
                  onChange={_ref.onChangeTest}
                  codeTable={_ref.returnUsedValue}
                />
              );
            }}
          />
          <UIColumn
            width="20px"
            render={data => {
              if (data.dataIndex == length) {
                return <div />;
              }
              return (
                <UILink
                  icon="fa fa-trash"
                  visibled={visibled}
                  onClick={AppraisalVehicleAction.removeSpareCostItem}
                >
                  <UIParam
                    name="removeIndex"
                    value={data.dataIndex}
                  />
                </UILink>
              );
            }}
          />
        </UIDataTable>
      </UIPanel>
    );
  },
});
export default AppraisalSparePartCost;
