export default {
  laborCostItemToAdd: {
    dataIndex: 0,
    CurrencCode: null,

    setDataIndex(index) {
      this.dataIndex = index;
    },

    seCurrencyCode(CurrencCode) {
      this.CurrencCode = CurrencCode;
    },

    get() {
      return {
        '@pk': null,
        '@type':
          'ClaimAppraisalVehicleLabor-ClaimAppraisalVehicleLabor',
        // "AppraisalItemId": 0,
        dataIndex: this.dataIndex,
        PartsName: '',
        PartsDesc: '',
        WorkType: '',
        DamageType: '',
        CurrencCode: this.CurrencCode,
        LaborAmount: 0,
      };
    },
  },

  spareCostItemToAdd: {
    dataIndex: 0,
    CurrencCode: null,

    setDataIndex(index) {
      this.dataIndex = index;
    },

    seCurrencyCode(CurrencCode) {
      this.CurrencCode = CurrencCode;
    },

    get() {
      return {
        '@pk': null,
        '@type':
          'ClaimAppraisalVehiclePart-ClaimAppraisalVehiclePart',
        // "AppraisalItemId": 0,
        dataIndex: this.dataIndex,
        PartsName: '',
        PartsDesc: '',
        CurrencCode: this.CurrencCode,
        ReferenceAmount: 0,
        BodyshopAmount: 0,
        ProviderAmout: 0,
      };
    },
  },
  propertyItemToAdd: {
    dataIndex: 0,
    CurrencyCode: null,
    setDataIndex(index) {
      this.dataIndex = index;
    },
    seCurrencyCode(CurrencyCode) {
      this.CurrencyCode = CurrencyCode;
    },

    get() {
      return {
        '@pk': null,
        '@type':
          'ClaimAppraisalPropertyItem-ClaimAppraisalPropertyItem',
        dataIndex: this.dataIndex,
        ItemName: '',
        ItemDesc: '',
        CurrencyCode: this.CurrencyCode,
        ClaimAmount: 0,
        DeductionAmount: 0,
        NetAmount: 0,
      };
    },
  },

  bodyItemToAdd: {
    dataIndex: 0,
    CurrencyCode: null,
    setDataIndex(index) {
      this.dataIndex = index;
    },
    seCurrencyCode(CurrencyCode) {
      this.CurrencyCode = CurrencyCode;
    },

    get() {
      return {
        '@pk': null,
        '@type': 'ClaimAppraisalInjuryItem-ClaimAppraisalInjuryItem',
        DataIndex: this.dataIndex,
        LossItem: '',
        ItemDesc: '',
        CurrencyCode: this.CurrencyCode,
        ClaimAmount: 0,
        DeductionAmount: 0,
        NetAmount: 0,
      };
    },
  },
};
