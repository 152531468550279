import { UIDialog, MessageHelper } from 'RainbowUI';
import alt from '../alt';
import SettlementCancelService from '../service/SettlementApproveCancelService';

class SettlementCancelAction {
  show(settlePayeeId) {
    this.dispatch(settlePayeeId);
    UIDialog.show('settlementCancelDialog');
  }

  submit(cancelInfo, callback) {
    SettlementCancelService.cancel(cancelInfo).then(data => {
      if (data.Status === 'OK') {
        if (typeof callback === 'function') {
          callback(cancelInfo.SettlePayeeId);
        }
        UIDialog.hide('settlementCancelDialog');
      } else {
        MessageHelper.error(data.Messages[0].Message, '');
      }
    });
  }
}
export default alt.createActions(SettlementCancelAction);
