import React from 'react';
import PropTypes from 'prop-types';
import {
  UIPanel,
  UICell,
  UIButton,
  CodeTable,
  UIBox,
} from 'RainbowUI';
import {
  ClaimTaskTransferDialog,
  ApprovalHistory,
  CoverageTree,
} from 'ClaimCommon';
import BindToMixin from 'react-binding';
import ClaimCommonExt from 'ClaimCommonExt';

const SubrogationAction = require('../action/SubrogationAction');
const SubrogationInfo = require('./SubrogationInfo');
const SubrogationHistory = require('./SubrogationHistory');
const SubrogationStore = require('../store/SubrogationStore');

const ClaimBaseInfo = ClaimCommonExt.getClaimBaseInfo();

const SubrogationTaskPage = React.createClass({
  mixins: [BindToMixin],

  propTypes: {
    taskInstanceId: PropTypes.string.isRequired,
    caseId: PropTypes.string.isRequired,
  },

  getInitialState() {
    return { SubrogationData: { OtherInfo: {} }, SelectedLength: 0 };
  },
  componentWillMount() {
    SubrogationStore.listen(this.onStoreStateChange);
    if (!_.isEmpty(this.props.taskInstanceId)) {
      SubrogationAction.loadData(this.props.taskInstanceId);
    }
  },

  componentWillUnmount() {
    SubrogationStore.unlisten(this.onStoreStateChange);
  },

  onStoreStateChange(storeState) {
    if (!_.isEmpty(storeState.SubrogationData)) {
      this.setState({
        SubrogationData: storeState.SubrogationData,
        SelectedLength: storeState.SelectedLength,
      });
    }
  },
  getSelectCode() {
    const otherInfo = this.bindToState(
      'SubrogationData',
      'OtherInfo'
    );

    const coverageTree = otherInfo.value.CoverageTree || [];
    const seleCodeTableList = [];
    seleCodeTableList.push({
      id: 0,
      text: i18n.ClaimSubrogation.PleaseSelect,
    });
    for (let i = 1; i < coverageTree.length; i += 1) {
      seleCodeTableList.push({
        id: i,
        text: i + i18n.ClaimSubrogation.Selected,
      });
    }

    const seleCodeTable = new CodeTable(seleCodeTableList);
    return seleCodeTable;
  },
  onClickSave() {
    SubrogationAction.saveSubrogation(this.state.SubrogationData);
  },
  submitSubrogation() {
    const result = SubrogationAction.submitSubrogation(
      this.state.SubrogationData
    );
    if (result === 'OK') {
      this.refs.ClaimTaskTransferDialog.showDialog({
        CaseId: this.props.caseId,
      });
    }
  },
  onClickSubmit() {
    this.submitSubrogation();
  },
  cancel() {},
  handleChooseCoverage(checkedList) {
    SubrogationAction.chooseCoverage(checkedList);
  },
  render() {
    const {
      caseId,
      readOnly,
      taskInstanceId,
      claimEntity,
    } = this.props;
    if (_.isEmpty(taskInstanceId)) {
      return (
        <div>
          <ClaimBaseInfo claimEntity={claimEntity} />
          <SubrogationHistory caseId={caseId} />
        </div>
      );
    }
    const suffixId = `subrogation_${taskInstanceId}_`;
    const otherInfo = this.bindToState(
      'SubrogationData',
      'OtherInfo'
    );

    const subrogationInfo = this.bindToState(
      'SubrogationData',
      'SubrogationInfo'
    );
    const subrogationId = this.bindTo(subrogationInfo, '@pk').value;

    const CommonConfirmDialog = ClaimCommonExt.getCommonConfirmDialog();

    const coverageTree = otherInfo.value.CoverageTree;

    const approvalHistory =
      subrogationId === null ||
      subrogationId === undefined ||
      subrogationId === '' ? (
        <UICell />
      ) : (
        <ApprovalHistory
          processObjectId={subrogationId}
          componentId={suffixId}
        />
      );
    return (
      <div>
        <ClaimBaseInfo claimEntity={claimEntity} />
        <div>
          <UIPanel
            id={`${suffixId}subrogationTaskPanel`}
            panelTitle={i18n.ClaimSubrogation.Subrogation}
            toggleable="true"
          >
            <CoverageTree
              taskInstanceId={taskInstanceId}
              claimType="SUB"
              coverageTree={coverageTree}
              ref="getChooseCoverage"
              selectCoverageName={
                i18n.ClaimSubrogation.SelectCoverage
              }
              handleParentChooseCoverage={this.handleChooseCoverage}
            />
            <UICell style={{ textAlign: 'left' }}>
              <SubrogationInfo
                otherInfo={otherInfo}
                history={false}
                subrogationInfo={subrogationInfo}
                readOnly={false}
                isApproveTask={false}
              />
            </UICell>
            <UICell style={{ textAlign: 'left', margin: '15px 0' }}>
              <UIBox direction="left">
                <UIButton
                  value={i18n.ClaimCommon.Save}
                  onClick={this.onClickSave}
                />
                <UIButton
                  value={i18n.ClaimCommon.Submit}
                  validationGroup="subrogation_submit_validate"
                  causeValidation="true"
                  onClick={this.onClickSubmit}
                />
              </UIBox>
            </UICell>
          </UIPanel>
          <ClaimTaskTransferDialog
            ref="ClaimTaskTransferDialog"
            componentId="subro_task"
          />
          <CommonConfirmDialog
            ref="confirmDialog_ref"
            parentComponent={this}
            objectId={`_subclaimInfo${readOnly}_${caseId}`}
            onConfirm={this.submitSubrogation}
            onCancel={this.cancel}
          />
        </div>
        {approvalHistory}
        <UICell style={{ textAlign: 'left', padding: '0' }}>
          <SubrogationHistory caseId={caseId} />
        </UICell>
      </div>
    );
  },
});
export default SubrogationTaskPage;
