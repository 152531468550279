import { UIDialog, UIButton, UICell, UIBox } from 'RainbowUI';
import { ApprovalHistory } from 'ClaimCommon';

const SubrogationReadOnly = require('./SubrogationReadOnly');
const SubrogationHistoryStore = require('../store/SubrogationHistoryStore');

const SubrogationReadOnlyDialog = React.createClass({
  getInitialState() {
    return {};
  },

  componentWillMount() {
    SubrogationHistoryStore.listen(this.onStoreStateChange);
  },
  componentWillUnmount() {
    SubrogationHistoryStore.unlisten(this.onStoreStateChange);
  },

  onStoreStateChange(storeState) {
    this.setState({ SubrogationId: storeState.SubrogationId });
  },

  render() {
    const { SubrogationId } = this.state;
    const approveHistory =
      SubrogationId === null ||
      SubrogationId === undefined ||
      SubrogationId === '' ? (
        <UICell />
      ) : (
        <ApprovalHistory processObjectId={SubrogationId} history />
      );
    return (
      <UIDialog
        id="SubrogationReadOnlyDialog"
        title={i18n.ClaimSubrogation.SubrogationHistoryDetail}
        width="90%"
        height="1000px"
        modal="false"
      >
        <SubrogationReadOnly
          SubrogationId={SubrogationId}
          history
          isApproveTask={false}
        />
        <br />
        {approveHistory}
        <UIBox direction="center">
          <UIButton
            value={i18n.ClaimCommon.Back}
            position="right"
            onClick={this.onClose}
          />
        </UIBox>
      </UIDialog>
    );
  },

  onClose() {
    UIDialog.hide('SubrogationReadOnlyDialog');
  },
});
export default SubrogationReadOnlyDialog;
