import { AjaxUtil, UISeparator, I18NUtil } from 'RainbowUI';
import PropTypes from 'prop-types';
import CodeTableAction from '../action/CodeTableAction';
import Url from '../url/Url';
import { logout } from '../../../../../RainbowUIConfig/template/login';

export default class UserSetting extends React.Component {
  componentDidMount() {
    const languageCodetable = CodeTableAction.getCodeTableByConditions(
      1108002,
      null,
      null
    );
    const languages = [];
    for (let i = 0; i < languageCodetable.codes.length; i += 1) {
      languages.push({ name: languageCodetable.codes[i].id });
    }

    $.each(languages, (index, value) => {
      const id = value.name;
      $(`#${id}`).bind('click', () => {
        AjaxUtil.doPost(
          `${Url.UserProfileLocale}?lang=${id}`,
          {},
          {
            async: false,
            done() {
              I18NUtil.setSystemI18N(id);
            },
            fail() { },
          }
        );

        location.reload();
      });
    });
  }

  render() {
    const _self = this;
    return (
      <ul
        className="dropdown-menu menu-top pull-right"
        style={{ padding: '15px', marginTop: '10px' }}
      >
        <li
          role="presentation"
          className="dropdown-header"
          style={{ overflow: 'hidden' }}
        >
          <div id={_self.props.id} className="user_setting">
            <div className="float_left usericon">
              <i className="fa fa-user" />
            </div>
            <div className="userinfo">{_self.props.userRealName}</div>
          </div>
        </li>
        <UISeparator />

        <li
          id="language"
          role="presentation"
          className="dropdown-submenu pull-left"
          style={{ width: '100%' }}
        >
          <a href="javascript:void(0);">
            <span
              className="fa fa-cog"
              style={{ paddingRight: '5px' }}
            />
            {i18n.ClaimCommon.Language}
          </a>
          <ul className="dropdown-menu">{this.getLanguage()}</ul>
        </li>

        <li
          id="clearcache"
          role="presentation"
          className="ui-menuitem"
        >
          <a
            href="javascript:void(0);"
            onClick={this.onClickClearCache.bind(this)}
          >
            <span
              className="fa fa-lock"
              style={{ paddingRight: '5px' }}
            />
            {i18n.ClaimCommon.ClearCache}
          </a>
        </li>
        <UISeparator />
        <li id="logout" role="presentation">
          <a
            className="logoutbtn"
            href="javascript: void(0);"
            onClick={logout}
          >
            {i18n.Logout || 'logout'}
          </a>
        </li>
      </ul>
    );
  }

  onClickClearCache() {
    CodeTableAction.clearCacheAll();
    AjaxUtil.doGet(
      Url.ClearCache,
      {},
      {
        async: false,
        done() { },
        fail() { },
      }
    );
    location.reload();
  }

  changePassWord() {
    const Authorization = localStorage.getItem('Authorization');
    window.open(
      `${env.serverUrl}maintenancecenter/#/password?${Authorization}`,
      'PassWord',
      'height=500px,width=1000px,top=0,left=0,toolbar=no,menubar=no,scrollbars=no, resizable=no,location=no, status=no'
    );
  }

  getLanguage() {
    const languageCodetable = CodeTableAction.getCodeTableByConditions(
      1108002,
      null,
      null
    );
    const liArray = [];
    for (let i = 0; i < languageCodetable.codes.length; i += 1) {
      const languageId = languageCodetable.codes[i].id;
      const languageName = languageCodetable.codes[i].text;
      liArray.push(
        <li id={languageId} className="ui-menuitem">
          <a href="javascript:void(0);" id={languageId}>
            <span
              className="fa fa-jpy"
              style={{ paddingRight: '5px' }}
            />
            {languageName}
          </a>
        </li>
      );
    }

    return liArray;
  }
}

/**
 * UserSetting component prop types
 */
UserSetting.propTypes = {
  id: PropTypes.string.isRequired,
  userName: PropTypes.string,
  userRole: PropTypes.string,
};
