import { MessageHelper, AjaxUtil } from 'RainbowUI';
import {
  RestStatus,
  ClaimMessageForamtUtils,
} from 'ClaimCommonConstant';
import Url from '../url/Url';
import TreeNode from '../constant/TreeNodeCache';

export default {
  getProductLine() {
    let result = {};
    const data = TreeNode.TreeCache.get('TreeNode');

    const productLineCodeJS = {
      productLineCode: data.treeNodeCode,
    };
    AjaxUtil.doGet(
      `${Url.ClaimProductLineOfList}?productLineCode=${productLineCodeJS.productLineCode}`,
      {},
      {
        async: false,
        done(data) {
          if (data.Status == RestStatus.OK) {
            result = data.Model;
          }
        },
        fail(data) {
          MessageHelper.error(
            i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
          );
          result = data;
        },
      }
    );
    return { result };
  },

  save(productLineEntity) {
    const datas = TreeNode.TreeCache.get('TreeNode');
    const productLineCodeJS = {
      productLineCode: datas.treeNodeCode,
    };
    const result = null;
    const prodctLineCode = datas.treeNodeCode;
    const url = Url.ClaimProductLineUpdate + prodctLineCode;
    AjaxUtil.doPost(url, productLineEntity, {
      async: false,

      done(data) {
        if (data.Status == RestStatus.OK) {
          MessageHelper.success(
            ClaimMessageForamtUtils.formatI18NMsg(
              i18n.ClaimCommon.SaveSuccess,
              i18n.ClaimProduct.ProductLine
            )
          );
        } else if (data.Status == RestStatus.ERROR) {
          MessageHelper.error(
            ClaimMessageForamtUtils.formatI18NMsg(
              i18n.ClaimCommon.SaveFail,
              i18n.ClaimProduct.ProductLine
            )
          );
        }
      },
      fail() {
        MessageHelper.error(
          i18n.ClaimCommon.ErrorMessage.CLM__UNKNOWN_ERROR
        );
      },
    });
  },
};
