import '@babel/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router, Route, IndexRoute } from 'react-router';
import { ClaimNoticePool, ClaimNotice } from 'ClaimNotice';
import ClaimRegistration from 'ClaimRegistration';

import {
  SettlementHandling,
  SettlementApproveHandling,
  RecoveryApproveHandling,
  RecoveryHandling,
  RefundApproveHandling,
  RefundHandling,
} from 'ClaimSettlement';
import {
  CalculationHandling,
  CalculationApproveHandling,
} from 'ClaimCalculation';

import ClaimProduct from 'ClaimProduct';
import {
  ClaimQueryPage,
  AuthorityConfiguration,
  PolicyDetail,
  AccidentRta,
  AccidentRtaDetail,
  Dashboard,
  PolicyDataDocument,
  Catastrophe,
  ClaimMatri,
  AssignmentApproach,
  MassiveReassignmentTaskOwner,
  MassiveReassignmentClaimOwner,
} from 'ClaimCommon';

import iClaimDesktop from './i-claim-desktop';

import TasksHandling from './package/TasksHandling';
import EU00CommonService from './service/EU00CommonService';

import './style/application.css';
import ClaimPageHeader from './common/module/common/component/ClaimPageHeader';

const ClaimRegistrationHandling = ClaimRegistration.getClaimRegistrationHandling();
const ProductConfiguration = ClaimProduct.getProductConfiguration();

(() => {
  EU00CommonService.extendWindowEU00Fn();
})();

const renderApp = () =>
  ReactDOM.render(
    <div>
      <ClaimPageHeader />
      <Router>
        <Route path="/">
          <IndexRoute component={iClaimDesktop} />

          <Route path="handling">
            <Route
              path="registration"
              component={ClaimRegistrationHandling}
            />
            <Route path="tasks" component={TasksHandling} />
            <Route path="claims" component={TasksHandling} />
          </Route>

          <Route path="notice">
            <Route path="pool" component={ClaimNoticePool} />
            <Route path="noticeofloss" component={ClaimNotice} />
            <Route
              path="noticeofloss/:RegNo/:AccidentId"
              component={ClaimNotice}
            />
          </Route>

          <Route path="policy">
            <Route path="detail" component={PolicyDetail} />
          </Route>

          <Route path="Catastrophe" component={Catastrophe} />
          <Route path="product">
            <Route
              path="configuration"
              component={ProductConfiguration}
            />
          </Route>
          <Route path="ClaimMatri" component={ClaimMatri} />
          <Route path="authority">
            <Route
              path="configuration"
              component={AuthorityConfiguration}
            />
          </Route>

          <Route path="task">
            <Route
              path="registrationHandling"
              component={ClaimRegistrationHandling}
            />
            <Route
              path="settlementHandling"
              component={SettlementHandling}
            />
            <Route
              path="settlementApproval"
              component={SettlementApproveHandling}
            />
            <Route
              path="recoveryHandling"
              component={RecoveryHandling}
            />
            <Route
              path="recoveryApproval"
              component={RecoveryApproveHandling}
            />
            <Route path="refundHandling" component={RefundHandling} />
            <Route
              path="refundApproval"
              component={RefundApproveHandling}
            />
            <Route
              path="calculationHandling"
              component={CalculationHandling}
            />
            <Route
              path="calculationApproval"
              component={CalculationApproveHandling}
            />
          </Route>

          <Route path="query">
            <Route path="claimQuery" component={ClaimQueryPage} />
          </Route>
          <Route path="dashboard">
            <Route path="dashboard" component={Dashboard} />
          </Route>
          <Route path="policydata">
            <Route path="policydata" component={PolicyDataDocument} />
          </Route>
          <Route
            path="AssignmentApproach"
            component={AssignmentApproach}
          />
          <Route path="reassignment">
            <Route
              path="MassiveReassignmentTaskOwner"
              component={MassiveReassignmentTaskOwner}
            />
            <Route
              path="MassiveReassignmentClaimOwner"
              component={MassiveReassignmentClaimOwner}
            />
          </Route>
          <Route path="Accident">
            <Route path="Rta" component={AccidentRta} />
            <Route
              path="RtaDetail/:AccidentId"
              component={AccidentRtaDetail}
            />
          </Route>
        </Route>
      </Router>
    </div>,
    document.getElementById('content')
  );

// only render after logged in
if ($.cookie('session') === 'active') {
  renderApp();
}
