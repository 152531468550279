import { UIBox, UICell, UIPickList, UIButton } from 'RainbowUI';
import ProductTypeCoverageCategoryAction from '../action/ProductTypeCoverageCategoryAction';

export default class CoverageCategory extends React.Component {
  render() {
    return (
      <div>
        <UICell>
          <UIPickList
            id="pickList02"
            dataSource={this.getProductTypeCoverageCategory}
            sourceListLabel={
              i18n.ClaimProduct.OptionalCoverageCategories
            }
            targetListLabel={
              i18n.ClaimProduct.ApplicableCoverageCategories
            }
            style={{ width: '1200px' }}
          />
        </UICell>
        <UICell>
          <UIBox direction="left">
            <UIButton
              value={i18n.ClaimProduct.Save}
              onClick={ProductTypeCoverageCategoryAction.saveOption}
            />
          </UIBox>
        </UICell>
      </div>
    );
  }

  getProductTypeCoverageCategory() {
    const result = ProductTypeCoverageCategoryAction.initData();
    if (result) {
      _.each(result, tempObj => {
        const obj = tempObj;
        obj.label = `${obj.value}:${obj.label}`;
      });
    }
    return result;
  }
}
