function SearchTaskMessageCreator() {
  function SearchTaskMessage(data) {
    if (!data) return;
    this.count = data.count;
    this.result = data.count;
  }

  SearchTaskMessage.prototype = {
    constructor: SearchTaskMessage,
    init(data) {
      if (!data) return;
      this.count = data.count;
      this.result = data.count;
    },
    showMessage() {
      if (this.count === 0) {
        /* empty */
      }
    },
  };

  return SearchTaskMessage;
}

const SearchTaskMessage = new SearchTaskMessageCreator();
export default SearchTaskMessage;
