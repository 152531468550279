import PropTypes from 'prop-types';

export default class BaseComponent extends React.Component {
  render() {
    return <div>Development in process</div>;
  }
}

/**
 * Define component prop types
 */
BaseComponent.propTypes = {
  name: PropTypes.string,
  taskDefinitions: PropTypes.any,
  menuDefinitions: PropTypes.any,
};

/**
 * Get component default props
 */
BaseComponent.defaultProps = {
  name: 'BaseComponent',
};
