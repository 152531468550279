import {
  UIText,
  UICell,
  UISelect,
  UISmartPanelGrid,
  UIRadio,
  UIPanel,
} from 'RainbowUI';
import BindToMixin from 'react-binding';

const CodeTableAction = require('../../common/action/CodeTableAction');
const CodeTableCstUI = require('../../common/constant/CodeTableCstUI');
const ClaimCstUI = require('../../common/constant/ClaimCstUI');

const PartyBasicInfo = React.createClass({
  mixins: [BindToMixin],

  getInitialState() {
    const state = { partyVo: this.props.partyVo, codeTableType: {} };
    const { partyVo } = state;
    switch (this.props.fromWhere) {
      case 'partyMaintance':
        break;
      case 'noticeNewClaimant':
      case 'claimant':
        partyVo.ClmPtyRole = ClaimCstUI.PARTY_ROLE.Claimant;
        break;
      case 'policyHolder':
        partyVo.ClmPtyRole = ClaimCstUI.PARTY_ROLE.PolicyHolder;
        break;
      case 'payee':
      case 'payer':
        partyVo.ClmPtyRole = ClaimCstUI.PARTY_ROLE.Payee;
        break;
      default:
        break;
    }
    return state;
  },
  componentWillReceiveProps(tmpnextProps) {
    const nextProps = tmpnextProps;
    if (!nextProps.partyVo.ClmPtyType) {
      nextProps.partyVo.ClmPtyType = ClaimCstUI.PARTY_TYPE_INDIVIDUAL;
    }
    this.setState({ partyVo: nextProps.partyVo });
  },

  getEnabled() {
    if (
      this.props.isEdit &&
      this.props.fromWhere === 'partyMaintance'
    ) {
      return 'false';
    }

    if (this.props.fromWhere === 'policyHolder') return 'false';

    //const enabled = this.state.partyVo.PartyId ? 'false' : 'true';
    
    const enabled ='false';
    
    return enabled;
   
  },

  getEnabledNewClaimant() {
    let enabled = this.state.partyVo.PartyId ? 'false' : 'true';
    if (!this.state.partyVo.PartyId) {
      enabled =
        this.state.partyVo.ClmPtyRole ==
          ClaimCstUI.PARTY_ROLE.Claimant
          ? 'false'
          : 'true';
    }
    return enabled;
  },
  clickPartyType() {
    this.forceUpdate();
  },
  filterByOpenFrom() {
    const openfrom = this.props.fromWhere;
    let claimPartyRole = null;

    if (openfrom === 'noticeNewClaimant') {
      const codeList = [];
      claimPartyRole = {};
      codeList[0] = '01';
      claimPartyRole.Code = codeList;
      claimPartyRole.Record_Usage = '05';
    } else if (
      this.props.fromWhere === 'partyMaintance' &&
      !this.props.isEdit
    ) {
      claimPartyRole = {};
      const filterList = ['02', '03', '04', '05', '06'];
      claimPartyRole.Code = filterList;
      claimPartyRole.Record_Usage = '05';
    }
    const roleCodeTable = CodeTableAction.loadDataList(
      CodeTableCstUI.ClaimPartyRole,
      null
    );
    console.info('%croleCodeTable', 'color:blue', roleCodeTable);
    return roleCodeTable;
  },
  roleEnable() {
    if (
      this.props.fromWhere === 'partyMaintance' &&
      !this.state.partyVo.PartyId
    )
      return 'true';
    return 'false';
  },
  render() {
    const { partyVo } = this.state;
    const { index } = this.props;
    if (!partyVo.ClmPtyType) {
      partyVo.ClmPtyType = ClaimCstUI.PARTY_TYPE_INDIVIDUAL;
    }

    console.log("===================partyvo: ", partyVo);

    return (
      <UIPanel
        panelTitle={i18n.ClaimParty.RoleInfo}
        styleClass="default"
      >
        <UISmartPanelGrid column="2" widthAllocation="6,6">
          <UICell>
            <UIRadio
              id={`ClaimPartytype_${index}`}
              codeTable={CodeTableAction.loadDataList(
                CodeTableCstUI.ClaimPartyType,
                null
              )}
              onChange={this.clickPartyType}
              valueLink={this.bindToState('partyVo', 'PartyType')}
              enabled={this.getEnabled}
              validationGroup="partyDetail,addToClmPty"
              defaultValue="1"
              required="true"
            />
          </UICell>

          <UISelect
            id={`claimIndPartyRoleId${index}`}
            codeTable={this.filterByOpenFrom()}
            validationGroup="partyDetail,addToClmPty"
            required="true"
            label={i18n.ClaimParty.PartyRole}
            valueLink={this.bindToState('partyVo', 'ClmPtyRole')}
            enabled={this.roleEnable}
          />

          {this.getPartyInfo()}
        </UISmartPanelGrid>
      </UIPanel>
    );
  },

  getPartyInfo() {
    const { partyVo } = this.state;
    const { index } = this.props;
    if (!partyVo.ClmPtyType) {
      partyVo.ClmPtyType = ClaimCstUI.PARTY_TYPE_INDIVIDUAL;
    }
    const certiType = CodeTableAction.loadDataList(
      CodeTableCstUI.ClaimPartyCertiType,
      null
    );
    const orgType = CodeTableAction.loadDataList(
      CodeTableCstUI.ClaimPartyOrgType,
      null
    );

    const enabled = partyVo.PartyId ? 'false' : 'true';

    const claimType = !partyVo.PartyType
      ? ClaimCstUI.PARTY_TYPE_INDIVIDUAL
      : partyVo.PartyType;

    if (claimType == ClaimCstUI.PARTY_TYPE_INDIVIDUAL) {
      return (
        <UISmartPanelGrid colspan="2">
          <UIText
            label={i18n.ClaimParty.FirstName}
            id={`PartyFirstName${index}`}
            validationGroup="partyDetail,addToClmPty"
            required="true"
            valueLink={this.bindToState('partyVo', 'FirstName')}
            enabled={this.getEnabled}
          />
          <UIText
            label={i18n.ClaimParty.LastName}
            id={`PartyLastName${index}`}
            validationGroup="partyDetail,addToClmPty"
            required="true"
            valueLink={this.bindToState('partyVo', 'LastName')}
            enabled={this.getEnabled}
          />

          <UISelect
            id={`ClaimPartyIdtype_${index}`}
            label={i18n.ClaimParty.IDType}
            valueLink={this.bindToState('partyVo', 'IdType')}
            validationGroup="partyDetail,addToClmPty"
            required="true"
            codeTable={certiType}
            enabled={this.getEnabled}
          />
          <UIText
            id={`ClaimPartyIdNumber_${index}`}
            label={i18n.ClaimParty.IDNumber}
            valueLink={this.bindToState('partyVo', 'IdNumber')}
            validationGroup="partyDetail,addToClmPty"
            required="true"
            enabled={this.getEnabled}
          />
        </UISmartPanelGrid>
      );
    }
    return (
      <UISmartPanelGrid id="Organization" colspan="2">
        <UIText
          label={i18n.ClaimParty.PartyName}
          id={`PartyName${index}`}
          validationGroup="partyDetail"
          required="true"
          valueLink={this.bindToState('partyVo', 'PartyName')}
          colspan="2"
          enabled={this.getEnabled}
        />
        <UIText
          id={`ClaimPartyIdNumber_1${index}`}
          label={i18n.ClaimParty.IDNumber}
          valueLink={this.bindToState(
            'partyVo',
            'OrganizationIdNumber'
          )}
          validationGroup="partyDetail"
          required="true"
          enabled={this.getEnabled}
        />
      </UISmartPanelGrid>
    );
  },
});
export default PartyBasicInfo;
