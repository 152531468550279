/* eslint-disable react/destructuring-assignment */
/* eslint-disable prefer-destructuring */
import {
  UISmartPanelGrid,
  UIText,
  UICurrency,
  UIPanel,
  UIDateTimePicker,
  UIDataTable,
  UIColumn,
  UIParam,
  UILink,
  CodeTable,
  DataContext,
  UIUpdatePanel,
  UpdateContext,
} from 'RainbowUI';
import BindToMixin from 'react-binding';
import { ClaimMessageForamtUtils } from 'ClaimCommonConstant';
import {
  PartyConst,
  PtyIntegrationSearchDialog,
} from 'EU00PartyCommonUI';

const SubrogationAction = require('../action/SubrogationAction');

const SubrogationInfoItem = React.createClass({
  mixins: [BindToMixin],

  getInitialState() {
    return {};
  },
  getIOStyle() {
    return this.props.readOnly == true ? 'out' : null;
  },
  getSubclaimCodeTable() {
    const subrogationStructure = this.props.otherInfo.sourceObject
      .OtherInfo.SubrogationStructure;
    for (let i = 0; i < subrogationStructure.length; i += 1) {
      if (
        subrogationStructure[i].InsuredId == this.props.insuredId &&
        subrogationStructure[i].CoverageCode ==
          this.props.coverageCode
      ) {
        return new CodeTable(
          subrogationStructure[i].SubclaimCodeTable || []
        );
      }
    }
    return '';
  },
  buildTableFunction() {
    const {
      readOnly,
      objectId,
      itemId,
      coverageCode,
      currencyCode,
    } = this.props;
    if (readOnly == true) {
      return <div />;
    }
    return (
      <div>
        <UILink
          icon="fa fa-plus"
          size="lg"
          tooltip={i18n.Add}
          iconClass="add_iconbtn"
          onClick={SubrogationAction.addItem}
        >
          <UIParam name="objectId" value={objectId} />
          <UIParam name="itemId" value={itemId} />
          <UIParam name="coverageCode" value={coverageCode} />
          <UIParam name="currencyCode" value={currencyCode} />
        </UILink>
      </div>
    );
  },

  showPartySearchDialog(event) {
    let index;
    if (event.paramJson.length > 0) {
      index = event.paramJson[0].value;
    }
    DataContext.put('subrogationIndex', index);
    this.refs.partySearchDialogForRecover.show();
  },

  getRecoveryParty(party) {
    const index = DataContext.get('subrogationIndex');
    if (index != undefined) {
      this.props.subrogationItems[index].RecoverFrom = party.PartyId;
      this.props.subrogationItems[index].RecoverPartyName =
        party.PartyName;
    }
    UpdateContext.forceUpdate('subrogationUpdatePanel');
  },
  render() {
    const self = this;
    const io = this.getIOStyle();
    const componentId = this.props.componentId;
    const readOnly = this.props.readOnly;
    const currencyCode = this.props.currencyCode;
    const coverageCode = this.props.coverage.CoverageCode;
    const insuredId = this.props.insuredId;
    let totalAmount = 0;
    const items = this.props.subrogationItems;
    const panelName = `${this.props.insured.sourceObject.InsuredName}_${this.props.coverage.CoverageName}`;
    for (let i = 0; i < items.length; i += 1) {
      const item = items[i];
      totalAmount += item.Amount;
    }
    const suffixId = `subraogationItem_${readOnly}_${
      this.props.isApproveTask
    }${this.props.insuredId}_${coverageCode.replace(
      /\./g,
      '_'
    )}_${componentId}`;
    let style;
    if (this.props.history) {
      style = 'default';
    } else {
      style = 'primary';
    }

    return (
      <div>
        <PtyIntegrationSearchDialog
          ref="partySearchDialogForRecover"
          id="partySearchDialogForRecover"
          isSearch="true"
          title={i18n.ClaimCommon.SearchRecovery}
          getSearchResult={self.getRecoveryParty}
          isVisibled={false}
          RoleName={PartyConst.PARTY_ROLE_NAME_RECOVER_FROM}
        />
        <UIUpdatePanel id="subrogationUpdatePanel">
          <UIPanel
            panelTitle={panelName}
            toggleable="true"
            styleClass={style}
          >
            <UIDataTable
              id={`${suffixId}dataTable`}
              indexable="false"
              value={self.props.subrogationItems}
              pageable="false"
              headable="false"
              functions={this.buildTableFunction()}
            >
              <UIColumn
                headerTitle={i18n.ClaimSubrogation.RecoverFrom}
                width="auto"
                render={data => {
                  return (
                    <div>
                      <UIText
                        io={io}
                        value={data.RecoverPartyName}
                        onChange={SubrogationAction.fromChange}
                        validationGroup="subrogation_submit_validate"
                        requiredMessage={ClaimMessageForamtUtils.formatI18NMsg(
                          i18n.ClaimCommon.I18nUtilValidate,
                          i18n.ClaimSubrogation.RecoverFrom
                        )}
                        required="true"
                        disabled="true"
                        suffixIcon="fa fa-search"
                        onSuffixIconClick={self.showPartySearchDialog}
                      >
                        <UIParam name="index" value={data.Index} />
                      </UIText>
                    </div>
                  );
                }}
              />
              <UIColumn
                headerTitle={i18n.ClaimSubrogation.SubrogationAmount}
                width="auto"
                render={data => {
                  return (
                    <div style={{ float: 'left' }}>
                      <UICurrency
                        validationGroup="subrogation_submit_validate"
                        required="true"
                        requiredMessage={ClaimMessageForamtUtils.formatI18NMsg(
                          i18n.ClaimCommon.I18nUtilValidate,
                          i18n.ClaimSubrogation.SubrogationAmount
                        )}
                        id={`CURRENCY_CODE_${suffixId}${data.Index}_subrogation_amount`}
                        allowNegative="true"
                        io={io}
                        defaultValue="0"
                        unit={window.EU00.getCurrencySignByCurrencyCode(
                          data.CurrencyCode
                        )}
                        value={data.Amount}
                        onChange={SubrogationAction.amountChange}
                      >
                        <UIParam name="index" value={data.Index} />
                      </UICurrency>
                    </div>
                  );
                }}
              />
              <UIColumn
                headerTitle={i18n.ClaimSubrogation.OverdueDate}
                width="auto"
                render={data => {
                  return (
                    <UIUpdatePanel id="subrogationDateTimePicker">
                      <UIDateTimePicker
                        io={io}
                        placeHolder="MM/DD/YYYY"
                        format="MM/DD/YYYY"
                        minDate="TODAY"
                        model={data}
                        property="LimitationDate"
                      >
                        <UIParam name="index" value={data.Index} />
                        <UIParam
                          name="limitationDate"
                          value={data.LimitationDate}
                        />
                      </UIDateTimePicker>
                    </UIUpdatePanel>
                  );
                }}
              />
              <UIColumn
                headerTitle={
                  i18n.ClaimSubrogation.RemarkForSubrogation
                }
                width="auto"
                render={data => {
                  return (
                    <UIText
                      io={io}
                      value={data.Remark ? data.Remark : ''}
                      onChange={SubrogationAction.remarkChange}
                    >
                      <UIParam name="index" value={data.Index} />
                    </UIText>
                  );
                }}
              />
              <UIColumn
                headerTitle={readOnly ? '' : i18n.ClaimCommon.Action}
                render={data => {
                  if (readOnly) {
                    return '';
                  }
                  return (
                    <UILink
                      id={`${suffixId + data.dataIndex}sub_ite_rem`}
                      icon="fa fa-trash"
                      onClick={SubrogationAction.deleteItem}
                    >
                      <UIParam name="insuredId" value={insuredId} />
                      <UIParam
                        name="coverageCode"
                        value={coverageCode}
                      />
                      <UIParam name="index" value={data.Index} />
                      <UIParam
                        name="indemnityAmount"
                        value={data.IndemnityAmount}
                      />
                      <UIParam
                        name="itemsLength"
                        value={self.props.subrogationItems.length}
                      />
                    </UILink>
                  );
                }}
              />
            </UIDataTable>
            <UISmartPanelGrid column="3">
              <UICurrency
                id="CURRENCY_CODE_SubrogationSubClaimTotalAmount"
                io="out"
                unit={window.EU00.getCurrencySignByCurrencyCode(
                  currencyCode
                )}
                label={
                  i18n.ClaimSubrogation.SubrogationSubClaimTotalAmount
                }
                allowNegative="true"
                value={totalAmount}
              />
            </UISmartPanelGrid>
          </UIPanel>
        </UIUpdatePanel>
      </div>
    );
  },
});
export default SubrogationInfoItem;
