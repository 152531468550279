import { AjaxUtil } from 'RainbowUI';
import Url from '../url/Url';

export default {
  loadFraudIndicators(objectId) {
    const deferred = $.Deferred();
    const url = `${Url.ClaimFraudIndicatorUrl}?objectId=${objectId}`;
    AjaxUtil.doGet(
      url,
      {},
      {
        async: false,
        done(data) {
          deferred.resolve(data);
        },
        fail(err) {
          deferred.reject(err);
        },
      }
    );
    return deferred.promise();
  },
  save(selMap) {
    const deferred = $.Deferred();
    const url = Url.ClaimFraudIndicatorUrl;
    AjaxUtil.doPost(url, selMap, {
      async: false,
      done(data) {
        deferred.resolve(data);
      },
      fail(err) {
        deferred.reject(err);
      },
    });
    return deferred.promise();
  },
};
