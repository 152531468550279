import alt from '../alt';
import ClaimHandlingAction from '../action/ClaimHandlingAction';

class ClaimTabCommonStore {
  constructor() {
    this.displayName = 'ClaimTabCommonStore';
    this.bindListeners({
      handleWithoutPolicyTaskAssigned:
        ClaimHandlingAction.SUBMIT_WITH_OUT_TASK_ASSIGNED,
    });
  }

  handleWithoutPolicyTaskAssigned(payload) {
    this.setState(payload);
  }
}

export default alt.createStore(ClaimTabCommonStore);
