export default {
  Notice: '报案 ',
  ClaimNoticePool: '查询待处理报案',
  ClaimNoticePoolData: '待处理报案',
  NoticeNumber: '报案号码',
  DateOfLossFromTo: '出险日期',
  OperationBy: '操作人',
  DateReported: '报告被保险人日期',

  AccidentDescription: '事故描述',
  DamageInfo: '损失信息',
  DamageDescription: '损失描述',
  DamageAddressDetail: '详细地址',
  NoDamageError: '至少存在一个损失项目!',
  PolicyNoError: '请先抄单',
};
