import {
  UIPanel,
  UIBox,
  CodeTable,
  UIText,
  UISmartPanelGrid,
  UIRadio,
  UIButton,
  UISelect,
} from 'RainbowUI';
import BindToMixin from 'react-binding';
import ClaimWorkFlowLeftAction from '../../action/desktop/ClaimWorkFlowLeftAction';
import SharePoolConditionStore from '../../store/SharePoolConditionStore';

const CodeTableAction = require('../../action/CodeTableAction');
const CodeTableCstUI = require('../../constant/CodeTableCstUI');

const ClaimWorkFlowSharePoolCriteria = React.createClass({
  mixins: [BindToMixin],
  componentWillUnmount() {
    SharePoolConditionStore.unlisten(this.onStoreStateChange);
  },

  onStoreStateChange(storeState) {
    if (!_.isEmpty(storeState.condition)) {
      this.setState({ condition: storeState.condition });
    }
  },
  componentDidMount() {
    SharePoolConditionStore.listen(this.onStoreStateChange);
  },
  getInitialState() {
    return { condition: {} };
  },
  clickSearch() {
    const sharePool = this.state.condition;
    sharePool.flag = 'sharingPool';
    ClaimWorkFlowLeftAction.initCondition(sharePool);
  },
  Clear() {
    this.setState({ condition: {} });
    this.props.parentComponent.onClickClear();
  },
  mockUser() {
    const codeTable = [
      { id: '66', text: 'andy' },
      { id: 'system', text: 'System' },
      { id: 'pool', text: 'Pool' },
    ];
    return new CodeTable(codeTable);
  },
  render() {
    return (
      <UIPanel
        id="searchCriteria"
        panelTitle={i18n.ClaimCommon.SharePoolQueryCondition}
      >
        <UISmartPanelGrid layout="horizontal" column="2">
          <UISelect
            id="taskType_"
            label={i18n.ClaimCommon.TaskType}
            valueLink={this.bindToState('condition', 'TaskType')}
            codeTable={CodeTableAction.loadDataList(
              CodeTableCstUI.CLAIMTASK,
              null
            )}
          />
          <UISelect
            id="priority_"
            label={i18n.ClaimCommon.Priority}
            valueLink={this.bindToState('condition', 'TaskPrioriry')}
            codeTable={CodeTableAction.loadDataList(
              CodeTableCstUI.Prioriry,
              null
            )}
          />
          <UIText
            id="claimNumber"
            label={i18n.ClaimCommon.ClaimNumber}
            valueLink={this.bindToState('condition', 'ClaimNumber')}
          />
          <UIRadio
            id="overDueTask"
            label={i18n.ClaimCommon.OverDueDate}
            valueLink={this.bindToState('condition', 'Flag')}
            codeTable={CodeTableAction.loadDataList(
              CodeTableCstUI.ClaimYesOrNo,
              null
            )}
          />
        </UISmartPanelGrid>

        <UIBox direction="left">
          <UIButton
            value={i18n.ClaimCommon.Filter}
            onClick={this.clickSearch}
          />

          <UIButton
            value={i18n.ClaimCommon.Reset}
            onClick={this.Clear}
          />
        </UIBox>
      </UIPanel>
    );
  },
});
export default ClaimWorkFlowSharePoolCriteria;
