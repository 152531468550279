import {
  UISelect,
  UILink,
  UIText,
  UIParam,
  UIConfirmDialog,
  UIDataTable,
  UIColumn,
  UICurrency,
  UICell,
  UITree,
  CodeTable,
  CodeTableSorter,
} from 'RainbowUI';
import BindToMixin from 'react-binding';
import PropTypes from 'prop-types';
import {
  CodeTableAction,
  CodeTableCstUI,
  ClaimCstUI,
  ClaimMessageForamtUtils,
} from 'ClaimCommonConstant';
import ClaimSettlement from 'ClaimSettlement';

const mockCodeTable = require('../mock/CodeTable');
const ClaimCaseAction = require('../action/ClaimCaseAction');

const SettlementLoadAction = ClaimSettlement.getSettlementLoadAction();

const SettlementItemInfo = React.createClass({
  mixins: [BindToMixin],

  propTypes: {
    payeeIndex: PropTypes.string.isRequired,
    payeeName: PropTypes.string.isRequired,
    settlementPayee: PropTypes.object.isRequired,
    otherInfo: PropTypes.object.isRequired,
    readOnly: PropTypes.bool,
    caseId: PropTypes.string.isRequired,
    isApprove: PropTypes.bool,
  },
  getIOStyle() {
    return this.props.readOnly == true ? 'out' : null;
  },
  getSuperComponent() {
    return this;
  },
  getClaimCase(caseId) {
    return ClaimCaseAction.loadClaimCase(caseId);
  },
  changeSettlementItemOther(event) {
    const payFinal = event.getParameter('payFinal');
    const settleAmount = event.getParameter('settleAmount');
    const dataIndex = event.getParameter('dataIndex');
    if (payFinal)
      this.props.settlementPayee.SettlementItem[dataIndex].PayFinal =
        event.newValue;

    if (settleAmount) {
      const reserveSign = this.props.settlementPayee.SettlementItem[
        dataIndex
      ].ReserveSign;
      let newAmt = event.newValue;
      let signIsWrong = (reserveSign * newAmt) < 0;
      if (signIsWrong) {
        newAmt *= -1;
      }
      this.props.settlementPayee.SettlementItem[
        dataIndex
      ].SettleAmount = Number(newAmt);

      if (signIsWrong) {
        setTimeout(this.forceUpdate(), 0);
      }
    }
  },
  changeSettlementItem(event) {
    const settlementTList = this.props.settlementType;
    let roleType = null;
    let paymentT = null;
    _.forEach(settlementTList, typeList => {
      if (typeList.PaymentTypeCode == event.newValue) {
        paymentT = typeList.PartyRole;
        if (paymentT) {
          roleType = paymentT;
        }
      }
    });
    const payeeNameList = SettlementLoadAction.changeSettlementItem(
      paymentT,
      event
    );

    const itemIndex = event.getParameter('dataIndex');
    const payeeIndex = event.getParameter('payeeIndex');
    this.props.updateSettlementPayee(
      payeeNameList,
      event.newValue,
      itemIndex,
      payeeIndex,
      roleType
    );
  },
  checkSettlementAmount(settleAmount, reserveId) {
    return ClaimCaseAction.checkSettlementAmount(
      settleAmount,
      reserveId
    );
  },
  getDisplayStyle() {
    return this.props.readOnly == true ? 'none' : '';
  },

  checkCoInsurancePortion() {
    if (
      this.props.CoInsurancePayment == ClaimCstUI.CoLeader.PayPortion
    ) {
      return true;
    }
    return false;
  },

  buildTableFunction(io) {
    const { payeeIndex } = this.props;
    return (
      <div>
        <UILink
          icon="fa fa-plus"
          size="lg"
          tooltip={i18n.ClaimSettlement.AddItem}
          iconClass="add_iconbtn"
          onClick={SettlementLoadAction.addSettlementItem}
          visibled={io !== 'out'}
        >
          <UIParam name="payeeIndex" value={payeeIndex} />
        </UILink>
      </div>
    );
  },

  getPaymentTypeCodeTable(reserveId) {
    let paymentTypeCodeTable = this.props.otherInfo
      .PaymentTypeCodeTable;
    if (paymentTypeCodeTable && reserveId) {
      paymentTypeCodeTable = paymentTypeCodeTable.filter(
        codes => codes.Parent == reserveId
      );
      return new CodeTable(
        paymentTypeCodeTable,
        null,
        new CodeTableSorter()
      );
    }
    return new CodeTable([]);
  },

  render() {
    const settlementItemPanel = this.getSettlementItemPanel();
    return (
      <div>
        {settlementItemPanel}
        <UIConfirmDialog
          id="checkSettlementDialog"
          title="Warning"
          message={i18n.ClaimSettlement.CheckSettlementAmountError}
        />
        <UIConfirmDialog
          id="checkRecoveryAmountDialog"
          title="Warning"
          message={i18n.ClaimSettlement.CheckRecoveryAmountError}
        />
      </div>
    );
  },

  getSettlementItemPanel() {
    const _seft = this;
    const io = this.getIOStyle();
    const {
      otherInfo: { ReserveCodeTable, ReserveTree },
      settlementPayee: { SettlementItem, SettleCurrency },
      readOnly,
      payeeIndex,
      isApprove,
    } = this.props;
    const reserveCodeTable = !ReserveCodeTable
      ? ''
      : new CodeTable(ReserveCodeTable);
    const reserveTree = ReserveTree;

    const suffixId = `settlementItemInfo_${readOnly}_${payeeIndex}_`;

    const settlementItem = SettlementItem;
    const settlementCurrency = SettleCurrency;
    const actionColumn =
      readOnly == true ? (
        <UICell />
      ) : (
          <UIColumn
            headerTitle={i18n.ClaimCommon.Action}
            render={data => {
              return (
                <UILink
                  tooltip={i18n.ClaimCommon.Delete}
                  onClick={SettlementLoadAction.removeSettlementItem}
                  icon="fa fa-trash"
                >
                  <UIParam name="removeIndex" value={data.Index} />
                  <UIParam name="payeeIndex" value={data.PayeeIndex} />
                </UILink>
              );
            }}
          />
        );
    let outstandingColumn =
      isApprove == true || readOnly == false ? (
        <UIColumn
          headerTitle={i18n.ClaimSettlement.OSReserve}
          width="10%"
          render={datas => {
            const data = datas;
            if (_seft.checkCoInsurancePortion()) {
              return (
                <div style={{ float: 'left' }}>
                  <UICurrency
                    id={`CURRENCY_CODE_${suffixId}${data.Index}_outstanding`}
                    value={data.OurShareAmount}
                    unit={window.EU00.getCurrencySignByCurrencyCode(
                      data.ReserveCurrency
                    )}
                    io="out"
                  />
                </div>
              );
            }
            return (
              <div style={{ float: 'left' }}>
                <UICurrency
                  id={`CURRENCY_CODE_${suffixId}${data.Index}_outstanding`}
                  value={data.OutstandingAmount}
                  unit={window.EU00.getCurrencySignByCurrencyCode(
                    data.ReserveCurrency
                  )}
                  io="out"
                />
              </div>
            );
          }}
        />
      ) : (
          <UICell />
        );
    if (readOnly) {
      outstandingColumn = (
        <UIColumn
          headerTitle={i18n.ClaimSettlement.OSReserve}
          width="10%"
          render={datas => {
            const data = datas;
            if (_seft.checkCoInsurancePortion()) {
              return (
                <div style={{ float: 'left' }}>
                  <UICurrency
                    id={`CURRENCY_CODE_${suffixId}${data.Index}_outstanding`}
                    value={data.OurShareAmount}
                    unit={window.EU00.getCurrencySignByCurrencyCode(
                      data.ReserveCurrency
                    )}
                    io="out"
                  />
                </div>
              );
            }
            return (
              <div style={{ float: 'left' }}>
                <UICurrency
                  id={`CURRENCY_CODE_${suffixId}${data.Index}_outstanding`}
                  value={data.OutstandingAmount}
                  unit={window.EU00.getCurrencySignByCurrencyCode(
                    data.ReserveCurrency
                  )}
                  io="out"
                />
              </div>
            );
          }}
        />
      );
    }
    const { taskCode } = this.props;
    let columnAmount;
    let columnType;
    if (
      taskCode == ClaimCstUI.CLAIM_TASK_CODE.Recovery ||
      taskCode == ClaimCstUI.CLAIM_TASK_CODE.RecoveryApproval
    ) {
      columnType = i18n.ClaimSettlement.RecoveryType;
      columnAmount = i18n.ClaimSettlement.RecoveryAmount;
    } else if (
      taskCode == ClaimCstUI.CLAIM_TASK_CODE.Refund ||
      taskCode == ClaimCstUI.CLAIM_TASK_CODE.RefundApproval
    ) {
      columnType = i18n.ClaimSettlement.RefundType;
      columnAmount = i18n.ClaimSettlement.RefundAmount;
    } else if (
      taskCode == ClaimCstUI.CLAIM_TASK_CODE.Settlement ||
      taskCode == ClaimCstUI.CLAIM_TASK_CODE.SettlementApproval
    ) {
      columnType = i18n.ClaimSettlement.SettlementType;
      columnAmount = i18n.ClaimSettlement.SettlementAmount;
    }

    return (
      <UIDataTable
        id={`${suffixId}dataTable`}
        indexable="false"
        value={settlementItem}
        pageable="false"
        headable="false"
        functions={this.buildTableFunction(io)}
      >
        <UIColumn
          headerTitle={i18n.ClaimReserve.ReserveType}
          width="20%"
          render={data => {
            return (
              <div>
                <div
                  id={`${suffixId + data.Index}_reserveValueDiv`}
                  style={{ display: '' }}
                >
                  <UISelect
                    id={`${suffixId + data.Index}_reserveType`}
                    onFocus={SettlementLoadAction.displayReserveTree}
                    value={data.ReserveId}
                    onBlur={
                      SettlementLoadAction.displayReserveTreeBlur
                    }
                    codeTable={reserveCodeTable}
                    childrenId={`${suffixId +
                      data.Index}_paymentType`}
                    io={io}
                    required="true"
                    validationGroup="submit_validate"
                    label=""
                    requiredMessage={ClaimMessageForamtUtils.formatI18NMsg(
                      i18n.ClaimCommon.I18nUtilValidate,
                      i18n.ClaimReserve.ReserveType
                    )}
                    widthAllocation="2,10"
                  >
                    <UIParam
                      name="reserveTreeDivId"
                      value={`${suffixId +
                        data.Index}_reserveTreeDiv`}
                    />
                    <UIParam
                      name="reserveValueDivId"
                      value={`${suffixId +
                        data.Index}_reserveValueDiv`}
                    />
                  </UISelect>
                </div>

                <div
                  id={`${suffixId + data.Index}_reserveTreeDiv`}
                  className="reserveTree"
                  style={{
                    display: 'none',
                    position: 'absolute',
                    backgroundColor: '#fff',
                    zIndex: '9',
                    padding: '15px',
                    border: 'solid 1px #e5e5e5',
                  }}
                >
                  <UITree
                    id={`${suffixId + data.Index}_reserveTree`}
                    searchable="true"
                    dataSource={reserveTree}
                    onClick={SettlementLoadAction.chooseReserve}
                  >
                    <UIParam
                      name="reserveTreeDivId"
                      value={`${suffixId +
                        data.Index}_reserveTreeDiv`}
                    />
                    <UIParam
                      name="reserveValueDivId"
                      value={`${suffixId +
                        data.Index}_reserveValueDiv`}
                    />
                    <UIParam name="data" value={data} />
                  </UITree>
                </div>
              </div>
            );
          }}
        />
        <UIColumn
          headerTitle={i18n.ClaimCommon.Subclaim}
          width="10%"
          render={data => {
            return (
              <UIText
                id={`${suffixId + data.Index}_seqNo`}
                value={data.SeqNo}
                io="out"
              />
            );
          }}
        />
        <UIColumn
          headerTitle={i18n.ClaimCommon.Coverage}
          width="10%"
          render={data => {
            return (
              <UIText
                id={`${suffixId + data.Index}_coverageName`}
                value={data.CoverageName}
                io="out"
              />
            );
          }}
        />
        {outstandingColumn}
        <UIColumn
          headerTitle={i18n.ClaimSettlement.PartialFinal}
          width="15%"
          render={data => {
            return (
              <UISelect
                id={`${suffixId + data.Index}_pf`}
                value={data.PayFinal ? data.PayFinal : ''}
                codeTable={
                  env.IS_MOCK == true
                    ? mockCodeTable.payPF
                    : CodeTableAction.loadDataList(
                      CodeTableCstUI.ClaimPayFinal,
                      null
                    )
                }
                io={io}
                onChange={_seft.changeSettlementItemOther}
                required="true"
                label=""
                requiredMessage={ClaimMessageForamtUtils.formatI18NMsg(
                  i18n.ClaimCommon.I18nUtilValidate,
                  i18n.ClaimSettlement.PartialFinal
                )}
                validationGroup="submit_validate"
                widthAllocation="2,10"
              >
                <UIParam name="payFinal" value={data} />
                <UIParam name="dataIndex" value={data.Index} />
              </UISelect>
            );
          }}
        />
        <UIColumn
          headerTitle={columnType}
          width="15%"
          render={data => {
            return (
              <UISelect
                id={`${suffixId + data.Index}_paymentType`}
                value={data.PaymentType ? data.PaymentType : ''}
                codeTable={_seft.getPaymentTypeCodeTable(
                  data.ReserveId
                )}
                io={io}
                onChange={_seft.changeSettlementItem}
                required="true"
                label=""
                requiredMessage={ClaimMessageForamtUtils.formatI18NMsg(
                  i18n.ClaimCommon.I18nUtilValidate,
                  columnType
                )}
                validationGroup="submit_validate"
                widthAllocation="2,10"
              >
                <UIParam name="data" value={_seft.props.caseId} />
                <UIParam name="payeeIndex" value={data.PayeeIndex} />
                <UIParam name="dataIndex" value={data.Index} />
              </UISelect>
            );
          }}
        />
        <UIColumn
          headerTitle={columnAmount}
          width="15%"
          render={datas => {
            const data = datas;
            if (data.SettleAmount === 0) {
              data.SettleAmount = '';
            }
            let unit = '';
            if (settlementCurrency) {
              unit = window.EU00.getCurrencySignByCurrencyCode(
                settlementCurrency
              );
            }
            return (
              <UICurrency
                id={`CURRENCY_CODE_${suffixId}${data.Index}_settlementAmount`}
                value={data.SettleAmount ? data.SettleAmount : ''}
                io={io}
                onChange={_seft.changeSettlementItemOther}
                allowNegative={true}
                required="true"
                unit={unit}
                label=""
                requiredMessage={ClaimMessageForamtUtils.formatI18NMsg(
                  i18n.ClaimCommon.I18nUtilValidate,
                  columnAmount
                )}
                validationGroup="submit_validate"
                widthAllocation="2,10"
              >
                <UIParam name="settleAmount" value={data} />
                <UIParam name="dataIndex" value={data.Index} />
                <UIParam
                  name="taskCode"
                  value={_seft.props.taskCode}
                />
                <UIParam
                  name="settlementItem"
                  value={settlementItem}
                />
              </UICurrency>
            );
          }}
        />
        {/** <UIColumn headerTitle={i18n.ClaimSettlement.TaxCode} width="9%" render={
               (data) => {
                 return(
                   <UISelect id={suffixId+data.value.Index+"_paymentTaxCode"} value={data.value.TaxCode} onChange={SettlementLoadAction.changeSettlementItem} codeTable={CodeTableAction.loadDataList(CodeTableCstUI.ClaimTaxCode,null)} io={io} label="" widthAllocation='2,10'>
                       <UIParam name="data" value={data}/>
                   </UISelect>
                 );
               }
             }/>
                 <UIColumn headerTitle={i18n.ClaimSettlement.TaxRate} width="8%" render={
               (data) => {
                 return(
                   <UIPercent id={suffixId+data.value.Index+"_paymentTaxRate"} value={data.value.TaxRate} onChange={SettlementLoadAction.changeSettlementItem} io={io} label=""  widthAllocation='2,10'>
                       <UIParam name="data" value={data}/>
                   </UIPercent>
                 );
               }
             }/>
                 <UIColumn headerTitle={i18n.ClaimSettlement.TaxAmount} width="10%" render={
               (data) => {
                 return(
                   <UICurrency id={suffixId+data.value.Index+"_paymentTaxAmount"} value={data.value.TaxAmount} onChange={SettlementLoadAction.changeSettlementItem} unit={settlementCurrency} io={io} label="" widthAllocation='2,10'>
                       <UIParam name="data" value={data}/>
                   </UICurrency>
                 );
               }
             }/>  * */}
        {actionColumn}
      </UIDataTable>
    );
  },
});
export default SettlementItemInfo;
