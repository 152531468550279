import { UIPanel } from 'RainbowUI';
import ClaimCommonExt from 'ClaimCommonExt';
import { ClaimMessageForamtUtils } from 'ClaimCommonConstant';

const ClaimCstUI = require('../../constant/ClaimCstUI');
const ClaimTabs = require('./ClaimTabs');

const ClaimInfoTab = React.createClass({
  getParentComponent() {
    return this;
  },
  render() {
    const {
      dtClaimData,
      claimData,
      showReserve,
      claimInfo,
      taskInstanceInfo,
      parentComponent,
      registrationHandling,
      claimLink,
      clickModifyHistory,
      actionButton,
    } = this.props;
    const parentProp = parentComponent.props;
    const HandlingLinks = ClaimCommonExt.getHandlingLinks();
    return (
      <div>
        <UIPanel
          id="claimhandling_claimPanel"
          toggleable="true"
          panelTitle={ClaimMessageForamtUtils.formatI18NMsg(
            i18n.ClaimCommon.ClaimHandlingTitle,
            claimData.ClaimNo
          )}
          panelHeader={
            <HandlingLinks
              links={claimLink}
              level={ClaimCstUI.CLAIM_LEVEL}
              showReserve={showReserve}
              relateId={claimData.CaseId}
              relateStatusCode={claimData.StatusCode}
              reserveStatus={claimData.ReserveStatus}
              reserveCloseAuth={claimData.ReserveCloseAuth}
              clickModifyHistory={clickModifyHistory}
              modifyClaimInfoAuth={claimData.ModifyClaimInfoAuth}
              changeNotificationToClaimAuth={
                claimData.ChangeNotificationToClaimAuth
              }
              authMap={claimData.AuthMap}
              parentComponent={parentComponent}
            />
          }
        >
          {claimInfo}
        </UIPanel>
        <ClaimTabs
          dtClaimData={dtClaimData}
          subclaimInfo={parentProp.subclaimInfo}
          subclaimLink={parentProp.subclaimLink}
          taskInstanceInfo={taskInstanceInfo}
          claimTabs={parentProp.claimTabs}
          objectAction={parentProp.objectAction}
          clickModifyHistory={clickModifyHistory}
          claimData={parentProp.claimData}
          showReserve={parentProp.showReserve}
          registrationHandling={registrationHandling}
          subclaimTabs={parentProp.subclaimTabs}
          authMap={claimData.AuthMap}
          claimLink={parentProp.claimLink}
        />
        {actionButton}
      </div>
    );
  },
});
export default ClaimInfoTab;
