import alt from '../alt';
import AssignmentAction from '../action/AssignmentAction';

class AssignmentStore {
  constructor() {
    console.log(
      '================AssignmentStore=========constructor============='
    );
    this.assgnments = {
      pendingTasks: [],
      closedTasks: [],
    };

    this.bindListeners({
      handleInitAssignment: AssignmentAction.INIT_ASSIGNMENT,
    });
  }

  handleInitAssignment(assgnments) {
    console.log(
      '================AssignmentStore======handleInitAssignment================'
    );
    this.assgnments = assgnments;
  }
}

export default alt.createStore(AssignmentStore, AssignmentStore);
