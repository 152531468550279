import {
  UIDataTable,
  UIColumn,
  UINumber,
  UISelect,
  UILink,
  UIParam,
} from 'RainbowUI';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import BindToMixin from 'react-binding';

const code = require('../../mock/CodeTable');
const PolicyDetailCoverageInfo = require('./PolicyDetailCoverageInfo');
const LimitDeductDialog = require('./LimitDeductDialog');

const PolicyDetailInsuredInfo = React.createClass({
  mixins: [BindToMixin],
  getInitialState() {
    return { PolicyEntity: this.props.policyInfo };
  },

  componentWillReceiveProps(nextProps) {
    this.setState({ PolicyEntity: nextProps.policyInfo });
  },
  getParentComponent() {
    return this;
  },

  LimitDedutInfo(event) {
    const data = event.getParameter('data');
    this.refs.LimitDeductDialog_ref.showLimitDeductDialog(data);
  },

  render() {
    const self = this;

    return (
      <div>
        <UIDataTable
          id="insuredList"
          value={self.state.PolicyEntity.InsuredList}
          headerTitle={i18n.ClaimCommon.InsuredList}
          indexable="false"
          infoable="true"
          isHeading="true"
          pageable="false"
          detailable="true"
          rowDetailRender={this.renderRowDetail}
        >
          <UIColumn
            headerTitle={i18n.ClaimCommon.InsuredName}
            value="InsuredName"
          />
          <UIColumn
            headerTitle={i18n.ClaimCommon.SumInsured}
            value="SumInsured"
          >
            <UINumber
              id="NUMBER_CODE_SumInsured"
              io="out"
              value="SumInsured"
            />
          </UIColumn>
          <UIColumn
            headerTitle={i18n.ClaimCommon.InsuredCategory}
            value="InsuredCategory"
            render={data => {
              return (
                <UISelect
                  value={data.InsuredCategory}
                  codeTable={code.insuredCategory}
                  io="out"
                />
              );
            }}
          />
          {/*
          <UIColumn
            headerTitle={i18n.ClaimReserve.Action}
            value="InsuredUid"
            render={data => {
              const colo = '#2196f3';
              return (
                <UILink
                  value={i18n.ClaimCalculation.LimitAndDeductionInfo}
                  onClick={self.LimitDedutInfo}
                  style={{ color: colo }}
                >
                  <UIParam name="data" value={data} />
                </UILink>
              );
            }}
          />*/}
        </UIDataTable>

        <LimitDeductDialog
          ref="LimitDeductDialog_ref"
          index="LimitDeductDialog"
          level="inusred"
          parentComponent={self}
        />
      </div>
    );
  },
  renderRowDetail(data) {
    return ReactDOMServer.renderToString(
      <div>
        <PolicyDetailCoverageInfo
          coveragelist={data.CoverageList}
          parentComponent={self}
        />
      </div>
    );
  }
});
export default PolicyDetailInsuredInfo;
