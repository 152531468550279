import { CodeTable, DataContext } from 'RainbowUI';
import PartyConst from '../const/PartyConst';
import PartyAction from '../actions/PartyAction';
import RemoteCodeTable from '../../common/codetable/RemoteCodeTable';
import CodeTableService from '../service/CodeTableService';
import { getRegionData } from "../../../../common/module/common/util/ClaimUtil";

const PartyCodeTable = {
  getUserByBranchId() {
    const branchUsers = CodeTableService.getUserByBranchId();
    const arr = [];
    let result = [];
    branchUsers.forEach(user => {
      arr.push({ id: user.Id, text: user.Username });
    });
    result = arr.sort((object1, object2) => {
      const value1 = object1.text;
      const value2 = object2.text;
      if (value1 < value2) {
        return -1;
      }
      if (value1 > value2) {
        return 1;
      }
      return 0;
    });
    return new CodeTable(result);
  },

  formatCodeTable(data) {
    const codeList = [];
    for (let i = 0; i < data.length; i += 1) {
      const newData = {};
      newData.id = data[i].Id;
      newData.text = data[i].Description;
      if (data[i].Parent) {
        newData.Parent = data[i].Parent;
      }

      codeList.push(newData);
    }
    return codeList;
  },

  getCategroyCodeTable() {
    return PartyCodeTable.getCodeTable(76243192);
  },

  getPartyVenFilterCodeTable() {
    return PartyCodeTable.getCodeTable(77881493);
  },

  getVenCategroyCodeTable() {
    return PartyCodeTable.getVenSearchCodeTable(76243192);
  },
  getPartyRoleCodeTable() {
    return PartyCodeTable.getCodeTable(1039);
  },

  IdType() {
    return PartyCodeTable.getCodeTable(1040);
  },

  ContactLanguage() {
    return PartyCodeTable.getCodeTable(1108002);
  },
  getCustomerStateCodeTable() {
    return PartyCodeTable.getCodeTable(1605429);
  },

  getCodeTable(codeTableId) {
    return RemoteCodeTable.CodeTableIdFromCache(codeTableId);
  },
  getVenSearchCodeTable(codeTableId) {
    return RemoteCodeTable.VenCodeTableIdFromCache(codeTableId);
  },

  getNationalityCodeTabled() {
    return PartyCodeTable.getCodeTable(12838691);
  },

  getMaritalStatus() {
    return PartyCodeTable.getCodeTable(12585815);
  },

  getOccupation() {
    return PartyCodeTable.getCodeTable(915073);
  },
  getPrefixCodeTable() {
    return PartyCodeTable.getCodeTable(912426);
  },

  getGenderCodeTable() {
    return PartyCodeTable.getCodeTable(936959);
  },

  getCountry() {
    return PartyCodeTable.getCodeTable(9800004365);
  },

  getCompanyType() {
    return PartyCodeTable.getCodeTable(972459);
  },

  getBusinessType() {
    return PartyCodeTable.getCodeTable(75190406);
  },

  getIndPartyRole() {
    return PartyCodeTable.getCodeTable(75147813);
  },
  getOrgPartyRole() {
    return PartyCodeTable.getCodeTable(75147812);
  },

  getIndPartyRoleClaim() {
    return PartyCodeTable.getCodeTable(1399241);
  },
  getOrgPartyRoleClaim() {
    return PartyCodeTable.getCodeTable(1399239);
  },
  getIndPartyRoleNoProducer() {
    return PartyCodeTable.getCodeTable(75147815);
  },
  getOrgPartyRoleNoProducer() {
    return PartyCodeTable.getCodeTable(75147814);
  },
  getPartyStatus() {
    return PartyCodeTable.getCodeTable(75173045);
  },
  getAddressType() {
    return PartyCodeTable.getCodeTable(75173047);
  },
  getGDPR() {
    return PartyCodeTable.getCodeTable(987153);
  },
  getDataAccessRequestCategory() {
    return PartyCodeTable.getCodeTable(75699087);
  },
  getOrgAddressType() {
    const codeTable = PartyCodeTable.getCodeTable(75173047);
    const addressList = codeTable.codes;
    for (let i = 0; i < addressList.length; i += 1) {
      if (addressList[i].id == 1) {
        addressList.splice(i, 1);
      }
    }
    return new CodeTable(addressList);
  },

  getPartyPosition() {
    return PartyCodeTable.getCodeTable(75196584);
  },
  getInactiveReason() {
    return PartyCodeTable.getCodeTable(75196586);
  },
  getPartyRoleCustomerType() {
    return PartyCodeTable.getCodeTable(75197390);
  },
  getPartyRoleOrgCustomerType() {
    return PartyCodeTable.getCodeTable(75348554);
  },
  getPartyRoleContractType() {
    return PartyCodeTable.getCodeTable(75201277);
  },
  getPartyRoleLegalSpecialtyLawyer() {
    return PartyCodeTable.getCodeTable(75201280);
  },
  getPartyRoleGarageType() {
    return PartyCodeTable.getCodeTable(75201282);
  },
  getOrgRelationShipType() {
    return PartyCodeTable.getCodeTable(75197387);
  },
  getIndRelationShipType() {
    return PartyCodeTable.getCodeTable(75197388);
  },
  getIndToOrgRelationShipType() {
    return PartyCodeTable.getCodeTable(75291611);
  },
  getOrgToIndRelationShipType() {
    return PartyCodeTable.getCodeTable(75291612);
  },
  getAllRelationShipType() {
    return PartyCodeTable.getCodeTable(75197386);
  },
  getIBANType() {
    return PartyCodeTable.getCodeTable(75197526);
  },
  getBankName() {
    const roleType = PartyConst.PARTY_ROLE_CODE_ORG_BANK;
    const partyAction = new PartyAction();
    const result = partyAction.searchPartyNameForBank(roleType);

    return new CodeTable(result);
  },
  getActiveBankName() {
    const roleType = PartyConst.PARTY_ROLE_CODE_ORG_BANK;
    const partyAction = new PartyAction();
    const result = partyAction.searchActivePartyNameForBank(roleType);

    return new CodeTable(result);
  },
  getUserName() {
    return PartyCodeTable.getCodeTable(1037);
  },
  getReinsuranceType() {
    return PartyCodeTable.getCodeTable(75201273);
  },
  getHealthCareProviderType() {
    return PartyCodeTable.getCodeTable(75205561);
  },
  getYesOrNo() {
    return PartyCodeTable.getCodeTable(1036);
  },
  getPartyRoleProducerType() {
    return PartyCodeTable.getCodeTable(75280761);
  },

  getPartyRoleProducerStatus() {
    return PartyCodeTable.getCodeTable(938336);
  },
  getPartyAddressCityCodeTable(countryCode) {
    const condition = {
      COUNTRY_CODE: countryCode,
    };
    return RemoteCodeTable.getCodeTableByCondition(
      75283380,
      condition
    );
  },
  getTransactionTypeCodeTableCodeTable() {
    return PartyCodeTable.getCodeTable(73858555);
  },
  getDocumentTypeCodeTable() {
    return PartyCodeTable.getCodeTable(73858556);
  },
  getDocumentSubTypeCodeTable() {
    return PartyCodeTable.getCodeTable(75693084);
  },
  getPermission(parent) {
    if (parent) {
      const condition = {
        PARENT_NAME: parent,
      };
      return RemoteCodeTable.getCodeTableByCondition(
        75697508,
        condition
      );
    }
    return new CodeTable([]);
  },

  geRegion(countryCode, regionLevel, parentRegionId) {
    const key = countryCode + regionLevel + parentRegionId;

    const cachedRegionCodeTable = DataContext.get(key);

    if (cachedRegionCodeTable) {
      return cachedRegionCodeTable;
    }

    const regionCodeTable = getRegionData(countryCode, regionLevel, parentRegionId);
    DataContext.put(key, regionCodeTable);
    return regionCodeTable;
  },
  geRegionCodeTable() {
    return PartyCodeTable.getCodeTable(1605415);
  },

  getDefaultPaymentMethod() {
    return PartyCodeTable.getCodeTable(75723525);
  },
};
export default PartyCodeTable;
