export default {
  Currency: 'Currency',

  Remark: 'Remark',

  // 按钮
  Action: 'Action',
  Submit: 'SUBMIT',
  Cancel: 'CANCEL',

  // reserve
  Reserve: 'Reserve',
  ReserveHistory: 'Reserve History',
  OpenReserve: 'Open Reserve',
  ReserveType: 'Reserve Type',
  ReserveStatus: 'Reserve Status',
  OutstandingReserve: 'Outstanding Reserve',
  ReserveUpdate: 'Update',
  Settled: 'Settled',
  IncurredAmount: 'Incurred Amount',
  LockStatus: 'Lock Status',
  Locked: 'Locked',
  Unlock: 'Unlock',

  // reservereopen
  Reopeningconfirmation: 'Reopen Confirmation',
  AreYouSure: 'Are you sure you want to reopen the reserve?',
  CauseOfReopening: 'Cause of Reopening',
  NewOutstandingReserve: 'New Outstanding Reserve',

  // reserveclose
  ClosureConfirmation: 'Closure Confirmation',
  AreYouSureCloseReserve:
    'Are you sure you want to close the reserve?',
  AreYouSureCloseItem: 'Are you sure to close the coverage?',
  AreYouSureCloseObject: 'Are you sure to close the subclaim?',
  AreYouSureCloseCase: 'Are you sure to close the case?',
  CauseOfClosure: 'Cause of Closure',
  ReasonTypeOfClosure: 'Reason of Closure',

  // openreserve
  Status: 'Status',

  // reservehistory
  ClaimFileType: 'Reserve Type',
  ChangeAmount: 'Change amount',
  Operator: 'Operator',
  Date: 'Date',
  CategoryOfReserveChange: 'Category of Reserve Change',
  Remarks: 'Remarks',
  CoinSurance: 'Co-Insurer',
  All: 'All',

  CoinLeader: 'Leader',
  ShareRate: 'Share Rate',
  Total: 'Total',

  // calculateReserve
  ReserveCalculation: 'Reserve Update',
  CalculationResult: 'New Outstanding Reserve',

  // MessageHelper
  OpenReserveSuccess: 'Open Reserve is submitted successfully',
  CloseReserveSuccess: 'Close Reserve is submitted successfully',
  UpdateReserveSucess: 'Update Reserve is submitted successfully',
  ReopenReserveSucess: 'Reopen Reserve is submitted successfully',
  InputNegative: 'Please input negative number',
  InputPositive: 'Please input positive number',
  OpenReserveError: 'Please select a Reserve Type you want to open',

  // reserveApproval
  ReserveUpdateApproval: 'Reserve Update Approval',
  ReserveClosureApproval: 'Reserve Closure Approval',

  ReserveUpdateApprovalSuccess:
    'Your Reserve Update Approval for Claim [@ClaimNo] has been approved by [@UserRealName]',
  ReserveUpdateApprovalReject:
    'Your Reserve Update Approval for Claim [@ClaimNo] has been rejected by [@UserRealName]',
  ReserveUpdateApprovalBlock:
    'Your Reserve Update Approval for Claim [@ClaimNo] has been approved by [@UserRealName]; higher level approval still needed.',

  ReserveClosureApprovalSuccess:
    'Your Reserve Closure Approval for Claim [@ClaimNo] has been approved by [@UserRealName]',
  ReserveClosureApprovalReject:
    'Your Reserve Closure Approval for Claim [@ClaimNo] has been rejected by [@UserRealName]',
  ReserveClosureApprovalBlock:
    'Your Reserve Closure Approval for Claim [@ClaimNo] has been approved by [@UserRealName]; higher level approval still needed.',

  ApprovedReserveSuccess: 'Reserve Update is approved successfully',
  RejectedReserveSuccess: 'Reserve Update is rejected successfully',
  MaxAmountMessage:
    "The  input amount  can't  greater than 999999999.99",

  ErrorMsg: {},
  ConfirmMsg: {},
  WarnMsg: {},
  SuccessMsg: {},
  InfoMsg: {},
};
