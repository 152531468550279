import ClaimCommonExt from 'ClaimCommonExt';

const alt = ClaimCommonExt.getReserveAlt();
const reserveAction = ClaimCommonExt.getReserveAction();
class ClaimTabStore {
  constructor() {
    this.displayName = 'ClaimTabStore';
    this.bindListeners({
      handleReserveApprovalTask:
        reserveAction.RESERVE_APPROVAL_TASK_ASSIGNED,
    });
  }

  handleReserveApprovalTask(payload) {
    console.info('ReserveAlt', alt);
    console.info('ReserveAction', reserveAction);
    console.log(
      '================ClaimTabStore======handleReserveApprovalTask================',
      payload
    );
    this.setState(payload);
  }
}

export default alt.createStore(ClaimTabStore);
