import {
  UIPickList,
  UIText,
  UIButton,
  UIBox,
  UIParam,
} from 'RainbowUI';
import PropTypes from 'prop-types';

const CalimDamageTypeRelationAction = require('../../action/CalimDamageTypeRelationAction');

const ClaimDamageTypeRelationSubclaimTypeCode = React.createClass({
  propTypes: {
    entity: PropTypes.object,
  },
  getPickList() {
    return CalimDamageTypeRelationAction.getPickList(
      this.props.entity.subclaimTypeCode
    );
  },
  render() {
    const { entity } = this.props;
    return (
      <div>
        <UIText
          label={i18n.ClaimProduct.SubclaimType}
          value={`${entity.subclaimTypeCode}:${entity.subclaimTypeName}`}
          io="out"
        />
        <div>
          <UIPickList
            id="claimTypeAndDamagePickList"
            sourceListLabel={i18n.ClaimProduct.OptionalDamageTypes}
            targetListLabel={i18n.ClaimProduct.ApplicableDamageTypes}
            dataSource={this.getPickList}
          />
        </div>
        <div>
          <UIBox direction="left">
            <UIButton
              value={i18n.ClaimProduct.Save}
              onClick={
                CalimDamageTypeRelationAction.saveClmDamageType
              }
            >
              <UIParam name="entity" value={entity} />
            </UIButton>
            <UIButton
              value={i18n.ClaimProduct.Cancel}
              onClick={
                CalimDamageTypeRelationAction.cancelClmDamageType
              }
            >
              <UIParam name="entity" value={entity} />
            </UIButton>
          </UIBox>
        </div>
      </div>
    );
  },
});
export default ClaimDamageTypeRelationSubclaimTypeCode;
