import {
  UIDialog,
  UIButton,
  UIBox,
  UITextarea,
  UIUpdatePanel,
} from 'RainbowUI';

const MigrationDriverRemark = React.createClass({
  getInitialState() {
    const objectId = sessionStorage.getItem('ModifySubclaim_index');
    let remark = null;
    if (
      this.props.data.ObjectList &&
      objectId &&
      this.props.data.ObjectList.length > objectId
    ) {
      remark = this.props.data.ObjectList[objectId].DriverRemark;
    }
    return { remark };
  },
  onshowDialog() { },
  cancel() {
    UIDialog.hide('MigrationDriverRemark');
  },
  render() {
    const { remark } = this.state;
    return (
      <UIDialog
        id="MigrationDriverRemark"
        title={i18n.ClaimCommon.MigratedDriverInfo}
        width="95%"
      >
        <UIUpdatePanel id="MigrationDriverInfo">
          <UITextarea
            label={i18n.ClaimCommon.MigratedDriverInfo}
            value={remark}
            disabled="true"
          />
        </UIUpdatePanel>
        <UIBox direction="center">
          <UIButton
            value={i18n.ClaimCommon.Back}
            onClick={this.cancel}
          />
        </UIBox>
      </UIDialog>
    );
  },
});
export default MigrationDriverRemark;
