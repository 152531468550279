import {
  UIText,
  UISelect,
  UIButton,
  UIParam,
  UISmartPanelGrid,
  UIBox,
} from 'RainbowUI';
import BindToMixin from 'react-binding';

const CodeTableAction = require('../../common/action/CodeTableAction');
const CodeTableCstUI = require('../../common/constant/CodeTableCstUI');

const PartyAddressInfo = React.createClass({
  mixins: [BindToMixin],

  getInitialState() {
    return { showAddButton: true };
  },

  addAddress() {
    this.setState({
      addressVo: {
        '@type': 'PartyAddress-PartyAddress',
        AddressStatus: '1',
        isAdd: true,
      },
      showAddButton: false,
    });
  },

  editAddress(addressVo) {
    this.setState({ addressVo, showAddButton: false });
  },

  saveAddress() {
    this.props.parentComponent.saveAddress(this.state.addressVo);
    this.cancel();
  },

  cancel() {
    this.setState({ showAddButton: true });
  },

  render() {
    const { showAddButton } = this.state;
    const { index } = this.props;
    if (showAddButton) {
      return <div />;
    }

    return (
      <div>
        <UISmartPanelGrid>
          <UISelect
            id="addressVoAddressTypeId"
            label={i18n.ClaimParty.AddressType}
            codeTable={CodeTableAction.loadDataList(
              CodeTableCstUI.ClaimAddressType,
              null
            )}
            valueLink={this.bindToState('addressVo', 'AddressType')}
            validationGroup="PartyAddressInfo"
            required="true"
          />

          <UISelect
            id="addressCountryStateCity"
            label={i18n.ClaimCommon.Country}
            codeTable={CodeTableAction.loadDataList(
              CodeTableCstUI.ClaimPartyCountry,
              null
            )}
            valueLink={this.bindToState('addressVo', 'Country')}
            validationGroup="PartyAddressInfo"
            required="true"
          />

          <UIText
            label={i18n.ClaimCommon.AddressLine1}
            id="AddressLine1"
            colspan="2"
            valueLink={this.bindToState('addressVo', 'AddressLine1')}
            validationGroup="PartyAddressInfo"
            required="true"
          />

          <UIText
            label={i18n.ClaimCommon.Postcode}
            id="PostCode"
            colspan="2"
            valueLink={this.bindToState('addressVo', 'PostCode')}
            validationGroup="PartyAddressInfo"
            required="true"
          />
        </UISmartPanelGrid>

        <UIBox direction="left">
          <UIButton
            value={i18n.ClaimParty.SaveButton}
            validationGroup="PartyAddressInfo"
            causeValidation="true"
            onClick={this.saveAddress}
          >
            <UIParam name="index" value={index} />
          </UIButton>
          <UIButton
            value={i18n.ClaimParty.CancelButton}
            onClick={this.cancel}
          >
            <UIParam name="index" value={index} />
          </UIButton>
        </UIBox>
        <hr />
      </div>
    );
  },
});
export default PartyAddressInfo;
