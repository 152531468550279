import {
  ClaimHandling,
  ClaimHandlingAction,
  ClaimHandlingStore,
} from 'ClaimCommon';
import { ClaimCstUI } from 'ClaimCommonConstant';
import { UIPage, UIButton, UIBox } from 'RainbowUI';

import ClaimRegistration from 'ClaimRegistration';
import ConfigClaimTabsInHandling from 'ConfigClaimTabsInHandling';

import ClaimCommonExt from 'ClaimCommonExt';

export default React.createClass({
  handleButtons(isShow) {
    if (isShow !== this.state.showButtons) {
      this.setState({ showButtons: isShow });
    }
  },
  saveClaim() {
    const RegistrationHandlingAction = ClaimRegistration.getClaimRegistrationHandlingAction();
    const registrationHandlingAction = new RegistrationHandlingAction();

    ClaimHandlingAction.newSubclaim(
      registrationHandlingAction.saveClaim(
        this.getCaseForm(ClaimCstUI.CASE_STATUS.FNOL)
      )
    );
  },

  clickSave() {
    const RegistrationHandlingAction = ClaimRegistration.getClaimRegistrationHandlingAction();
    const registrationHandlingAction = new RegistrationHandlingAction();
    const caseForm = this.getCaseForm(ClaimCstUI.CASE_STATUS.FNOL);
    caseForm.ClaimEntity = this.handleClaimEntity(
      caseForm.ClaimEntity
    );

    this.resetSubclaim(
      registrationHandlingAction.saveClaim(caseForm, true)
    );
  },

  clickSubmitCheck() {
    const caseForm = this.getCaseForm(ClaimCstUI.CASE_STATUS.FNOL);
    caseForm.ClaimEntity = this.handleClaimEntity(
      caseForm.ClaimEntity
    );
    if (this.checkSubClaimType(caseForm)) {
      const RegistrationHandlingAction = ClaimRegistration.getClaimRegistrationHandlingAction();
      const registrationHandlingAction = new RegistrationHandlingAction();
      if (registrationHandlingAction.submitClaim(caseForm)) {
        this.refs.claimHandling_ref.refs.ClaimTaskTransferDialog.showDialog(
          {
            CaseId: this.state.caseForm.ClaimData.CaseId,
            TaskCode: 'ClaimSettlementTask',
          }
        );
      }
    }
  },
  clickSubmit() {
    const RegistrationHandlingAction = ClaimRegistration.getClaimRegistrationHandlingAction();
    const registrationHandlingAction = new RegistrationHandlingAction();
    const caseForm = this.getCaseForm(ClaimCstUI.CASE_STATUS.FNOL);

    if (registrationHandlingAction.submitClaim(caseForm)) {
      this.refs.claimHandling_ref.refs.ClaimTaskTransferDialog.showDialog(
        {
          CaseId: this.state.caseForm.ClaimData.CaseId,
          TaskCode: 'ClaimSettlementTask',
        }
      );
    }
  },

  checkSubClaimType(caseForm) {
    const RegistrationHandlingAction = ClaimRegistration.getClaimRegistrationHandlingAction();
    const registrationHandlingAction = new RegistrationHandlingAction();
    return registrationHandlingAction.checkSubClaimType(caseForm);
  },

  resetSubclaim(caseForm) {
    this.setState({ caseForm });
    if (caseForm) {
      const subclaimIndex =
        this.state.caseForm.ClaimData.ObjectDatas.length - 1;
      this.refs[`subclaim_ref${subclaimIndex}`].resetSubclaim(
        caseForm.ClaimEntity.ObjectList[subclaimIndex]
      );
    }
  },

  cancelNewSubclaim() {
    const RegistrationHandlingAction = ClaimRegistration.getClaimRegistrationHandlingAction();
    const registrationHandlingAction = new RegistrationHandlingAction();
    registrationHandlingAction.saveClaim(
      this.getCaseForm(null, true)
    );
  },

  rejectClaim() {
    const ClaimEntity = this.handleClaimEntity(
      this.getCaseForm().ClaimEntity
    );
    this.refs.claim_ref.refs.RejectDialog_ref.showDialog(
      ClaimEntity,
      i18n.ClaimCommon.Claim
    );
  },

  handleClaimEntity(tempClaimEntity) {
    const ClaimEntity = tempClaimEntity;
    delete ClaimEntity.cityCodeTable;
    delete ClaimEntity.postalCodeTable;
    delete ClaimEntity.streetCodeTable;
    delete ClaimEntity.townCodeTable;
    delete ClaimEntity.countryCodeTable;
    delete ClaimEntity.regions;
    delete ClaimEntity.IsMotor;
    return ClaimEntity;
  },

  getCaseForm(status, cancelNewSubclaim) {
    if (
      this.state.caseForm.ClaimEntity.UnpaidReasonCode &&
      this.state.caseForm.ClaimEntity.UnpaidReasonCode.constructor ==
        Array
    ) {
      const unpaidReason = JSON.stringify(
        this.state.caseForm.ClaimEntity.UnpaidReasonCode
      );
      this.state.caseForm.ClaimEntity.UnpaidReasonCode = unpaidReason;
    }
    if (
      this.state.caseForm.ClaimEntity.UnpaidResReasonCode &&
      this.state.caseForm.ClaimEntity.UnpaidResReasonCode
        .constructor == Array
    ) {
      const unpaidReason = JSON.stringify(
        this.state.caseForm.ClaimEntity.UnpaidResReasonCode
      );
      this.state.caseForm.ClaimEntity.UnpaidResReasonCode = unpaidReason;
    }

    let objSize = 0;
    if (this.state.caseForm.ClaimData.ObjectDatas) {
      objSize = this.state.caseForm.ClaimData.ObjectDatas.length;
    }
    const objectList = [];
    for (let i = 0; i < objSize; i += 1) {
      const { objectEntity } = this.refs[`subclaim_ref${i}`].state;
      if (
        undefined !=
        this.refs[`subclaim_ref${i}`].state.objectEntity
          .RentalCarCompanyId
      ) {
        objectEntity.RentalCarCompany = this.refs[
          `subclaim_ref${i}`
        ].state.objectEntity.RentalCarCompanyId;
      }
      if (cancelNewSubclaim) {
        if (objectEntity.ObjectId) {
          objectList.push(objectEntity);
        }
      } else {
        objectList.push(objectEntity);
      }
    }
    const { caseEntity } = this.refs.claim_ref.state;
    caseEntity.ObjectList = objectList;
    if (status) {
      caseEntity.StatusCode = status;
    }

    const { caseForm } = this.state;
    caseForm.ClaimEntity = caseEntity;

    return caseForm;
  },

  getParentComponent() {
    return this;
  },

  getInitialState() {
    return { caseForm: {}, showButtons: true, warningMsg: [] };
  },

  componentWillMount() {
    ClaimHandlingStore.listen(this.onStoreStateChange);
    ClaimHandlingAction.initHandling();
  },

  componentWillUnmount() {
    ClaimHandlingStore.unlisten(this.onStoreStateChange);
  },

  onStoreStateChange(storeState) {
    if (!_.isEmpty(storeState.caseForm)) {
      document.title = `${i18n.ClaimCommon.Claim}${storeState.caseForm.ClaimData.ClaimNo} ${storeState.caseForm.TaskInstance.Variables.TaskName}`;
      this.setState({ caseForm: storeState.caseForm });
    }
  },

  render() {
    const SubclaimInfo = ClaimRegistration.getSubclaimInfo();
    const ClaimInfo = ClaimRegistration.getClaimInfo();
    const _self = this;
    let validationMsg;
    try {
      validationMsg = _self.state.caseForm.ClaimData.WarningMsg;
    } catch (err) {
      validationMsg = [];
    }
    const CommonConfirmDialog = ClaimCommonExt.getCommonConfirmDialog();

    this.state.warningMsg = validationMsg;
    console.log(
      `===validationMsg===${JSON.stringify(validationMsg)}`
    );

    if (_self.state.caseForm.ClaimData) {
      const accidentTabs = ConfigClaimTabsInHandling.getAccidentTabs(
        _self.state.caseForm,
        true
      );
      return (
        <UIPage>
          <ClaimHandling
            parentComponent={this}
            claimData={_self.state.caseForm.ClaimData}
            ref="claimHandling_ref"
            claimInfo={
              <ClaimInfo
                parentComponent={this}
                data={_self.state.caseForm.ClaimEntity}
                io="in"
                claimHandling={this.refs.claimHandling_ref}
                ref="claim_ref"
              />
            }
            warningMsg={_self.state.warningMsg}
            subclaimInfo={
              <SubclaimInfo
                parentComponent={this}
                functionParent={this.cancelNewSubclaim}
                data={_self.state.caseForm.ClaimEntity}
                io="in"
              />
            }
            showReserve="false"
            actionButton={this.getActionButtons()}
            accidentTabs={accidentTabs}
          />
          <CommonConfirmDialog
            ref="confirmDialog_ref_registration"
            parentComponent={this}
            objectId="registrationHanding"
            onConfirm={this.clickSubmit}
          />
        </UIPage>
      );
    }
    return null;
  },

  getActionButtons() {
    if (this.state.showButtons) {
      return (
        <UIBox direction="left">
          <UIButton
            id="registration_save"
            value={i18n.ClaimCommon.Save}
            onClick={this.clickSave}
          />
          <UIButton
            id="registration_submit"
            value={i18n.ClaimCommon.Submit}
            onClick={this.clickSubmitCheck}
            causeValidation="true"
            validationGroup="RegistrationInfo"
          />
          <UIButton
            id="registration_reject"
            value={i18n.ClaimCommon.Reject}
            onClick={this.rejectClaim}
          />
        </UIBox>
      );
    }
  },
});
