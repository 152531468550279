export default {
  TreeCache: {
    CacheTree: {},
    get(treeNode) {
      let result = null;
      if (this.CacheTree[treeNode]) {
        result = this.CacheTree[treeNode];
      }
      return result;
    },
    put(treeNode, treeNodeObject) {
      this.CacheTree[treeNode] = treeNodeObject;
    },
  },
};
