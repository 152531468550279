import { AjaxUtil } from 'RainbowUI';
import Url from '../url/Url';

export default {
  loadRelation(entity) {
    const url = Url.SubclaimCoverageCategoryReserveLoad;
    let list = null;
    AjaxUtil.doPost(url, entity, {
      async: false,
      done(data) {
        list = data;
      },
    });
    return list.Model;
  },

  loadCoverageCategoryReserve(productLineCode) {
    const url = Url.SubclaimCoverageCategoryReserve + productLineCode;

    let list = null;
    AjaxUtil.doGet(
      url,
      {},
      {
        async: false,
        done(data) {
          list = data;
        },
      }
    );
    return list.Model;
  },
  updateCoverageCategoryReserve(claimRelationData) {
    const url = Url.SubclaimCoverageCategoryReserveUpdate;
    AjaxUtil.doPost(url, claimRelationData, {
      async: false,
      done() {},
    });
  },
};
