import {
  UISelect,
  UIPercent,
  UIDialog,
  UISmartPanelGrid,
  UIText,
  DataContext,
  UIButton,
  UIDateTimePicker,
  UIBox,
  MessageHelper,
} from 'RainbowUI';
import PartyAction from '../actions/PartyAction';
import PartyConst from '../const/PartyConst';
import DefinedCodeTable from '../codetable/CodeTable';

const PartyShareholderDialog = React.createClass({
  getInitialState() {
    let { party } = this.props;
    if (DataContext.get('Party')) {
      party = DataContext.get('Party');
    }
    const nationalityCodeTable = DefinedCodeTable.getCountry();
    return {
      party,
      PartyShareholder: {},
      nationalityCodeTable,
    };
  },

  onClickClose() {
    UIDialog.hide('PartyShareholderDialog');
  },

  onSave() {
    const partyShareholder = this.state.PartyShareholder;
    const { party } = this.state;
    const partyAction = new PartyAction();

    const result = partyAction.checkPartyShareholderPercentage(
      party.PartyId,
      partyShareholder.ShareholderId,
      partyShareholder.ShareholderPercentage
    );

    if (result.status === 'success' && result.data == true) {
      this.props.submitHandler(partyShareholder);
      UIDialog.hide('PartyShareholderDialog');
    } else {
      MessageHelper.warning(
        i18n.PartyMsg.WarnMsg.Warn_0012,
        '',
        MessageHelper.POSITION_TOP_RIGHT
      );
    }
  },

  render() {
    const _self = this;
    const { PartyShareholder, nationalityCodeTable } = this.state;
    const partyShareholder = PartyShareholder;
    if (!partyShareholder.ShareholderCategory) {
      partyShareholder.ShareholderCategory =
        PartyConst.PARTY_TYPE_IND;
    }
    return (
      <UIDialog
        id="PartyShareholderDialog"
        width="90%"
        title={i18n.ShareholderDetail}
      >
        <UISmartPanelGrid column="3">
          <UIText
            label={i18n.ShareholderName}
            model={partyShareholder}
            property="ShareholderName"
            required="true"
            validationGroup="UpdateShareholderInfo"
          />
          <UISelect
            label={i18n.ShareholderCategory}
            model={partyShareholder}
            onChange={this.changeShareholderCategory}
            property="ShareholderCategory"
            codeTable={DefinedCodeTable.getCategroyCodeTable}
            required="true"
            validationGroup="UpdateShareholderInfo"
            showBlankOption={false}
          />
          <UIPercent
            label={i18n.ShareholderPercentage}
            model={partyShareholder}
            property="ShareholderPercentage"
            required="true"
            validationGroup="UpdateShareholderInfo"
          />
          <UIText
            label={i18n.IDNumberOrCompanyRegNumber}
            model={partyShareholder}
            property="IdNumberOrCompanyRegNumber"
            required="true"
            validationGroup="UpdateShareholderInfo"
          />
          {_self.renderDateOfBirth(partyShareholder)}
          <UISelect
            label={
              partyShareholder.ShareholderCategory ==
                PartyConst.PARTY_TYPE_IND
                ? i18n.Nationality
                : i18n.CountryOfIncorporation
            }
            model={partyShareholder}
            property="Country"
            codeTable={nationalityCodeTable}
            required="true"
            validationGroup="UpdateShareholderInfo"
          />
        </UISmartPanelGrid>
        <UIBox direction="center">
          <UIButton
            value={i18n.Save}
            onClick={_self.onSave}
            validationGroup="UpdateShareholderInfo"
            causeValidation="true"
          />
          <UIButton
            value={i18n.Cancel}
            onClick={_self.onClickClose}
          />
        </UIBox>
      </UIDialog>
    );
  },

  renderDateOfBirth(partyShareholder) {
    if (
      partyShareholder.ShareholderCategory ==
      PartyConst.PARTY_TYPE_IND
    ) {
      return (
        <UIDateTimePicker
          label={i18n.BirthDay}
          format="MM/DD/YYYY"
          model={partyShareholder}
          property="DateOfBirth"
          required="true"
          maxDate="TODAY"
          manualInput="true"
          validationGroup="UpdateShareholderInfo"
        />
      );
    }
  },

  showDialog(partyShareholder) {
    UIDialog.show('PartyShareholderDialog');
    this.setState({
      PartyShareholder: partyShareholder,
    });
  },

  changeShareholderCategory() {
    this.setState(prevState => {
      const partyShareholder = prevState.PartyShareholder;
      partyShareholder.DateOfBirth = null;
      return { partyShareholder };
    });
  },
});
export default PartyShareholderDialog;
