import ClaimNoticeExt from 'ClaimNoticeExt';
import React from 'react'
const NoticePool = React.createClass({
  render() {
    const NoticePoolSearch = ClaimNoticeExt.getNoticePoolSearch();
    const NoticePoolResult = ClaimNoticeExt.getNoticePoolResult();
    return (
      <div>
        <NoticePoolSearch />
        <NoticePoolResult ref="NoticePoolResult" />
      </div>
    );
  },
});
export default NoticePool;
