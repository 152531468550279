import { MessageHelper, DataContext, UITree, AjaxUtil, CodeTable } from 'RainbowUI';
import alt from '../alt';
import ClaimAuthorityUtilAction from './ClaimAuthorityUtilAction';
import ClaimAuthorityService from '../service/ClaimAuthorityService';
import { ClaimCstUI } from 'ClaimCommonConstant';
import ClaimCommonUrl from '../url/Url';



class ClaimAuthorityAction {
  initData(permissionCode) {
    const buttonIsVisible = true;
    const data = ClaimAuthorityService.initClaimAuthorityTree();
    const treeList = [];
    const model = data.Model;
    model.id = model.Code;
    model.pId = '0';
    model.name = model.Name;
    model.PermissionCode = permissionCode;
    model.pathName = model.name;
    model.checked = false;
    model.open = true;
    model.chkDisabled = !buttonIsVisible;
    let flag = false;
    const resultList = ClaimAuthorityService.getAuthortyByPermissionCode(
      { permissionCode }
    );

    let treeNodeVo = null;
    let treeNodeGC = null;
    for (const item in model.ChirdrenList) {
      const vo = model.ChirdrenList[item];
      vo.id = vo.Code;
      treeNodeVo = vo;
      treeNodeVo.id = vo.id;
      treeNodeVo.pId = model.id;
      treeNodeVo.name = treeNodeVo.Name;
      treeNodeVo.PermissionCode = permissionCode;
      treeNodeVo.pathName = `${model.name}/${treeNodeVo.name}`;
      treeNodeVo.checked = false;
      treeNodeVo.chkDisabled = model.chkDisabled;
      // productType
      for (const item in vo.ChirdrenList) {
        const vo1 = vo.ChirdrenList[item];
        treeNodeGC = vo1;
        treeNodeGC.id = vo1.Code;
        treeNodeGC.pId = vo.id;
        treeNodeGC.name = treeNodeGC.Name;
        treeNodeGC.PermissionCode = permissionCode;
        treeNodeGC.pathName = `${vo.pathName}/${treeNodeGC.name}`;
        treeNodeGC.checked = false;
        treeNodeGC.chkDisabled = model.chkDisabled;
        treeNodeGC.Path = vo1.Path;
        for (let i = 0; i < resultList.length; i += 1) {
          if (resultList[i].AuthorityPath == treeNodeGC.Path) {
            treeNodeGC.checked = true;
            flag = true;
          }
        }
        treeList.push(treeNodeGC);
      }
      treeNodeVo.checked = flag;
      treeList.push(treeNodeVo);
      flag = false;
    }
    for (let i = 0; i < treeList.length; i += 1) {
      if (treeList[i].checked == true) {
        model.checked = true;
        break;
      }
    }
    treeList.push(model);

    return treeList;
  }

  loadData(event, treeId, treeNode) {
    const permissionCode = treeNode.PermissionCode;
    const path = treeNode.Path;
    const type = treeNode.Type;
    const { pathName } = treeNode;
    const data = ClaimAuthorityService.loadClaimAuthority(
      permissionCode,
      path,
      type
    ).then(data => {
      const list = data.Model.List;
      const localCurrency = data.Model.LocalCurrency;
      const treeList = [];
      for (const item in list) {
        const vo = list[item];
        vo.path = path;
        let treeVo = {};
        let showFlag = 'Y';
        if (vo.ConfigFlag === 'Y' || vo.path === 'root') {
          showFlag = 'N';
        }
        if (!vo.ParentCode) {
          treeVo = {
            id: vo.Code,
            pId: null,
            name: vo.Name,
            vo,
            localCurrency,
            showFlag,
          };
        } else {
          treeVo = {
            id: vo.Code,
            pId: vo.ParentCode,
            name: vo.Name,
            vo,
            localCurrency,
            showFlag,
          };
        }
        treeList.push(treeVo);
      }
      for (const item in treeList) {
        const vo = treeList[item];
        if (vo.pId) {
          const parentVo = ClaimAuthorityUtilAction.getParent(
            treeList,
            vo
          );
          if (parentVo.vo.AssignedFlag === 'Y') {
            vo.io = 'in';
          } else {
            vo.io = 'out';
          }
          treeList[item] = vo;
        } else {
          vo.io = 'in';
          treeList[item] = vo;
        }
      }
      const param = {
        authorityList: treeList,
        path,
        type,
        permissionCode,
        pathName,
      };

      this.dispatch(param);
    });
  }

  clearData() {
    this.dispatch([]);
  }

  submitAuthority(event) {
    const authorityList = event.getParameter('authorityList');
    const checkedNode = UITree.getCheckedNode('claimAuthorityId');
    if (checkedNode.length === 0) {
      MessageHelper.error(
        i18n.ClaimCommon.ErrorMsg.APermissionCannotBeEmpty,
        null,
        MessageHelper.POSITION_TOP_RIGHT
      );
      return;
    }
    const list = [];
    for (const item in authorityList) {
      const { vo } = authorityList[item];
      delete vo.dataIndex;
      list.push(vo);
    }
    const permissionCode = event.getParameter('permissionCode');
    const path = event.getParameter('path');
    const type = event.getParameter('type');
    const pathName = event.getParameter('pathName');
    const treeNode = {
      Path: path,
      Type: type,
      PermissionCode: permissionCode,
      pathName,
    };

    for (const item in list) {
      delete list[item].path;
    }
    const returnData = ClaimAuthorityService.submitClaimAuthorityList(
      list,
      permissionCode,
      path,
      type
    );
    this.actions.loadData(event, null, treeNode);
    if (returnData.Status === 'OK') {
      MessageHelper.success(
        i18n.ClaimCommon.authoritySubmitSuccess,
        null,
        null
      );
      // window.close();
    } else {
      MessageHelper.error(
        i18n.ClaimCommon.authoritySubmitError,
        null,
        null
      );
    }
  }

  resetAuthority(permissionCode, path, type) {
    const returnData = ClaimAuthorityService.resetClaimAuthorityList(
      permissionCode,
      path
    );
    const treeNode = {
      Path: path,
      Type: type,
      PermissionCode: permissionCode,
    };
    this.actions.loadData(event, null, treeNode);
    if (returnData.Status === 'OK') {
      MessageHelper.success(
        i18n.ClaimCommon.authorityResetSuccess,
        null,
        null
      );
    } else {
      MessageHelper.error(
        i18n.ClaimCommon.authorityResetSuccess,
        null,
        null
      );
    }
  }

  checkAuth(caseId, code) {
    const result = ClaimAuthorityService.checkAuth(caseId, code);
    return result.Model;
  }

  getPermissionCodes(moduleGroupId, functionType) {
    let param = `?moduleId=${ClaimCstUI.URP_MODULE_ID}`;
    if (moduleGroupId) {
      param += `&moduleGroupId=${moduleGroupId}`
    }

    if (functionType) {
      param += `&type=${functionType}`
    }

    let result;
    AjaxUtil.doGet(
      `${ClaimCommonUrl.UserPermissionCodes}${param}`,
      {},
      {
        async: false,
        done(data) {
          console.log(`Permission Codes:${JSON.stringify(data)}`);
          result = data;
        },
        fail() {
          console.log('load Permission Codes fail.');
        },
      }
    );

    return result;
  }

  getPermissions(moduleGroupId, functionType) {
    let param = `?moduleId=${ClaimCstUI.URP_MODULE_ID}`;
    if (moduleGroupId) {
      param += `&moduleGroupId=${moduleGroupId}`
    }

    if (functionType) {
      param += `&type=${functionType}`
    }

    let result;
    AjaxUtil.doGet(
      `${ClaimCommonUrl.PermissionsSearchByModule}${param}`,
      {},
      {
        async: false,
        done(data) {
          console.log(`Permission Codes:${JSON.stringify(data)}`);
          result = data;
        },
        fail() {
          console.log('load Permission Codes fail.');
        },
      }
    );

    return result;
  }

  getPermissionCodeTable() {
    let permissions = this.actions.getPermissions(ClaimCstUI.URP_MODULE_GROUP_ID_AUTHORITY, ClaimCstUI.URP_FUNCTION_TYPE);
    if (permissions) {
      let permissionArray = [];
      for (let i = 0; i < permissions.length; i++) {
        permissionArray.push({ id: permissions[i].Code, text: permissions[i].Name });
      }
      return new CodeTable(permissionArray);
    }
    return new CodeTable([]);
  }
}
export default alt.createActions(ClaimAuthorityAction);
