import { Caller, MessageHelper } from 'RainbowUI';

const urlConfig = require('../../../UrlConfig');

export default {
  createCustomer(customer) {
    const url = urlConfig.createCustomer;
    const result = {};
    Caller.call(url, customer, {
      method: 'POST',
      async: false,
      block: false,
    }).then(
      data => {
        result.status = 'success';
        result.data = data;
      },
      err => {
        result.status = 'fail';
        result.data = err.responseJSON.Messages[0].Message;
      }
      );
    return result;
  },

  loadUserByUserId(userId) {
    const url = urlConfig.loadUserByUserId + userId;
    const result = {};
    Caller.call(url, null, {
      method: 'GET',
      async: false,
      block: false,
    }).then(
      data => {
        result.status = 'success';
        result.data = data;
      },
      err => {
        result.status = 'fail';
        result.data = err;
      }
      );
    return result;
  },

  loadCustomerByPartyId(partyId) {
    const url = `${urlConfig.loadCustomerByPartyId}?customerId=${partyId}`;
    const result = {};
    Caller.call(url, null, {
      method: 'GET',
      async: false,
      block: false,
    }).then(
      data => {
        result.status = 'success';
        result.data = data;
      },
      err => {
        result.status = 'fail';
        result.data = err;
      }
      );
    return result;
  },

  updateCustomer(tempcustomer, partyId) {
    const customer = tempcustomer;
    delete customer.role;
    const url = `${urlConfig.modifyOrgCustomer}?customerId=${partyId}`;
    const result = {};
    Caller.call(url, customer, {
      method: 'POST',
      async: false,
      block: false,
    }).then(
      data => {
        result.status = 'success';
        result.data = data;
      },
      err => {
        result.status = 'fail';
        result.data = err;
      }
      );
    return result;
  },
  updateParty(tempparty) {
    const party = tempparty;
    delete party.role;
    this.handlePartyBeforeSubmit(party);
    const url = urlConfig.updateParty;
    const result = {};
    Caller.call(url, party, {
      method: 'POST',
      async: false,
      block: false,
    }).then(
      data => {
        result.status = 'success';
        result.data = data;
      },
      err => {
        result.status = 'fail';
        result.data = err.responseJSON.Messages[0].Message;
      }
      );
    return result;
  },
  updatePartyNameInSalesChannel(partyName, partyId) {
    const url = urlConfig.loadSalesChannelNameByPartyId;
    const result = {};
    const entity = {};
    entity.partyName = partyName;
    entity.partyId = partyId;
    Caller.call(url, entity, {
      method: 'POST',
      async: false,
      block: false,
    }).then(
      data => {
        result.status = 'success';
        result.data = data;
      },
      err => {
        result.status = 'fail';
        result.data = err.responseJSON.Messages[0].Message;
      }
      );
    return result;
  },

  checkPartyAdditional(temppartyAdditional) {
    const partyAdditional = temppartyAdditional;
    delete partyAdditional.DefinedIndex;
    delete partyAdditional.dataIndex;
    const url = urlConfig.checkPartyAdditional;
    const result = {};
    Caller.call(url, partyAdditional, {
      method: 'POST',
      async: false,
      block: false,
    }).then(
      data => {
        result.status = 'success';
        result.data = data;
      },
      err => {
        result.status = 'fail';
        result.data = err.responseJSON.Messages[0].Message;
      }
      );
    return result;
  },

  searchParty(conditions) {
    let result = [];
    const url = urlConfig.searchPartyRole;
    Caller.call(url, conditions, {
      method: 'POST',
      async: false,
      block: false,
    }).then(
      data => {
        if (data.Total > 0) {
          result = { result: data.PartyVos, count: data.Total };
        } else {
          MessageHelper.warning(
            i18n.PartyMsg.WarnMsg.Warn_0007,
            null,
            MessageHelper.POSITION_TOP_RIGHT
          );
        }
      },
      err => {
        MessageHelper.error(
          err.statusText,
          null,
          MessageHelper.POSITION_TOP_RIGHT
        );
      }
      );
    return result;
  },

  queryParty(conditions) {
    let result = [];
    const url = urlConfig.queryParty;
    Caller.call(url, conditions, {
      method: 'POST',
      async: false,
      block: false,
    }).then(
      data => {
        if (data.TotalElements > 0) {
          result = {
            result: data.ElementsInCurrentPage,
            count: data.TotalElements,
          };
        } else {
          MessageHelper.warning(
            i18n.PartyMsg.WarnMsg.Warn_0007,
            null,
            MessageHelper.POSITION_TOP_RIGHT
          );
        }
      },
      err => {
        MessageHelper.error(
          err.statusText,
          null,
          MessageHelper.POSITION_TOP_RIGHT
        );
      }
      );
    return result;
  },

  loadPartyByPartyId(partyId) {
    const url = `${urlConfig.loadPartyByPartyId}?partyId=${partyId}`;
    const result = {};
    Caller.call(url, null, {
      method: 'GET',
      async: false,
      block: false,
    }).then(
      data => {
        result.status = 'success';
        result.data = data;
      },
      err => {
        result.status = 'fail';
        result.data = err;
      }
      );
    return result;
  },

  loadHistoryByHistoryId(historyId) {
    const url = urlConfig.loadPartyByPartyId + historyId;
    const result = {};
    Caller.call(url, null, {
      method: 'GET',
      async: false,
      block: false,
    }).then(
      data => {
        result.status = 'success';
        result.data = data;
      },
      err => {
        result.status = 'fail';
        result.data = err;
      }
      );
    return result;
  },

  handlePartyBeforeSubmit(party) {
    _.each(party.PartyHistoryList, tempele => {
      const ele = tempele;
      delete ele.UpdatedTime;
      delete ele.UpdatedBy;
    });
  },

  createParty(party) {
    this.handlePartyBeforeSubmit(party);
    const url = urlConfig.createNewParty;
    const result = {};
    Caller.call(url, party, {
      method: 'POST',
      async: false,
      block: false,
    }).then(
      data => {
        if (data.Messages) {
          result.status = 'fail';
          result.data = data.Messages[0].Message;
        } else {
          result.status = 'success';
          result.data = data;
        }
      },
      err => {
        result.status = 'fail';
        if (err.responseJSON) {
          result.data = err.responseJSON.message;
        } else {
          result.data = null;
        }
      }
      );
    return result;
  },

  getCustomerCode() {
    const url = urlConfig.ap00GetCustomerCode;
    const result = {};
    Caller.call(url, null, {
      method: 'POST',
      async: false,
      block: false,
    }).then(
      data => {
        result.status = 'success';
        result.data = data;
      },
      err => {
        result.status = 'fail';
        result.data = err.responseJSON.Messages[0].Message;
      }
      );
    return result;
  },
  findPartyNameByCustomerCode(customerCode) {
    let url = urlConfig.findPartyNameByCustomerCode;
    const result = {};
    url += customerCode;
    Caller.call(url, null, {
      method: 'POST',
      async: false,
      block: false,
    }).then(
      data => {
        result.status = 'success';
        result.data = data;
      },
      err => {
        result.status = 'fail';
        result.data = err.responseJSON.Messages[0].Message;
      }
      );
    return result;
  },
  findPartyNameByPartyCode(partyCode) {
    let url = urlConfig.getPartyByPartyCode;
    const result = {};
    url += partyCode;
    Caller.call(url, null, {
      method: 'POST',
      async: false,
      block: false,
    }).then(
      data => {
        result.status = 'success';
        result.data = data;
      },
      err => {
        result.status = 'fail';
        result.data = err.responseJSON.Messages[0].Message;
      }
      );
    return result;
  },

  findBank(roleType) {
    const result = [];
    const url = `${urlConfig.loadPartyNameForBank}/${roleType}`;
    Caller.call(url, null, {
      method: 'POST',
      async: false,
      block: false,
    }).then(
      data => {
        result.status = 'success';
        result.data = data;
      },
      err => {
        result.status = 'fail';
        result.data = err.responseJSON.Messages[0].Message;
      }
      );
    return result;
  },
  findActiveBank(roleType) {
    const result = [];
    const url = `${urlConfig.loadPartyNameForBank}/${roleType}?roleStatus=1`;
    Caller.call(url, null, {
      method: 'POST',
      async: false,
      block: false,
    }).then(
      data => {
        result.status = 'success';
        result.data = data;
      },
      err => {
        result.status = 'fail';
        result.data = err.responseJSON.Messages[0].Message;
      }
      );
    return result;
  },

  getMinorAge() {
    let result = null;
    const url = urlConfig.getMinorAge;
    Caller.call(url, null, {
      method: 'GET',
      async: false,
      block: false,
    }).then(
      data => {
        result = data;
      },
      () => { }
      );
    return result;
  },

  checkPartyShareholderPercentage(
    partyId,
    shareholderId,
    shareholderPercentage
  ) {
    const url = `${urlConfig.checkPartyShareholderPercentage}?partyId=${partyId}&shareholderId=${shareholderId}&shareholderPercentage=${shareholderPercentage}`;
    const result = {};
    Caller.call(url, null, {
      method: 'GET',
      async: false,
      block: false,
    }).then(
      data => {
        result.status = 'success';
        result.data = data;
      },
      err => {
        result.status = 'fail';
        result.data = err;
      }
      );
    return result;
  },
};
