import {
  UIBox,
  UISelect,
  UIParam,
  UIDataTable,
  UIColumn,
  UICurrency,
  UILink,
  UITree,
  CodeTable,
} from 'RainbowUI';
import BindToMixin from 'react-binding';
import PropTypes from 'prop-types';
import { ClaimMessageForamtUtils } from 'ClaimCommonConstant';
import CalculationLoadAction from '../action/CalculationLoadAction';

const CalculationExpenseInfo = React.createClass({
  mixins: [BindToMixin],

  propTypes: {
    otherInfo: PropTypes.object.isRequired,
    insured: PropTypes.object.isRequired,
    coverage: PropTypes.object.isRequired,
    calculationInfo: PropTypes.object.isRequired,
    readOnly: PropTypes.bool,
    isApproveTask: PropTypes.bool,
  },

  render() {
    const mainPage = this.getCalculationItemPanel();
    return <div>{mainPage}</div>;
  },

  getIOStyle() {
    return this.props.readOnly == true ? 'out' : null;
  },

  getDisplayStyle() {
    return this.props.readOnly == true ? 'none' : '';
  },

  getReserveTree() {
    const { otherInfo } = this.props;
    const { insuredId } = this.props;
    const { coverageCode } = this.props;
    let reserveTree = [];
    for (
      let i = otherInfo.value.CalculationStructure.length - 1;
      i >= 0;
      i -= 1
    ) {
      const temp = otherInfo.value.CalculationStructure[i];
      if (
        Number(insuredId) == temp.InsuredId &&
        coverageCode == temp.CoverageCode
      ) {
        reserveTree = temp.ReserveTree;
        break;
      }
    }
    return reserveTree;
  },

  selectCode() {
    const calculationStructures = this.props.otherInfo.value
      .CalculationStructure;
    const { insuredId } = this.props;
    const { coverageCode } = this.props;
    const reserveCodes = [];
    for (let i = 0; i < calculationStructures.length; i += 1) {
      const temp = calculationStructures[i];
      if (temp.ReserveTree) {
        if (
          Number(insuredId) == temp.InsuredId &&
          coverageCode == temp.CoverageCode
        ) {
          for (let j = 0; j < temp.ReserveTree.length; j += 1) {
            const tempReserveTree = temp.ReserveTree[j];
            const reserveCode = {
              id: tempReserveTree.id,
              text: tempReserveTree.name,
            };
            reserveCodes.push(reserveCode);
          }
        }
      }
    }
    const code = new CodeTable(reserveCodes);
    return code;
  },

  getCalculationItemPanel() {
    const _self = this;
    const ioStyle = _self.getIOStyle();
    const { componentId } = this.props;
    const { insuredId, isApproveTask } = _self.props;
    const { coverageCode } = _self.props;
    const { readOnly } = _self.props;
    const reserveCodes = _self.selectCode();
    const reserveTree = _self.getReserveTree();
    const suffixId = `calculationExpense_${readOnly}_${isApproveTask}_${insuredId}_${coverageCode.replace(
      /\./g,
      '_'
    )}_${componentId}`;
    const button = readOnly ? (
      <div />
    ) : (
      <UIBox>
        <UILink
          icon="fa fa-plus"
          size="lg"
          iconClass="add_iconbtn"
          onClick={CalculationLoadAction.addExpense}
        >
          <UIParam name="insuredId" value={insuredId} />
          <UIParam name="coverageCode" value={coverageCode} />
        </UILink>
      </UIBox>
    );
    const hr = readOnly ? <div /> : <hr />;
    return (
      <div>
        <UIDataTable
          id={`${suffixId}dataTable`}
          indexable="false"
          value={_self.props.calculationExpense}
          pageable="false"
          headable="false"
        >
          <UIColumn
            headerTitle={i18n.ClaimReserve.ReserveType}
            render={data => {
              return (
                <div>
                  <div
                    id={`${suffixId + data.dataIndex}reserveTypeSele`}
                    style={{ display: '' }}
                  >
                    <UISelect
                      id={`${suffixId + data.dataIndex}cal_exp_sele`}
                      onFocus={CalculationLoadAction.showExpenseTree}
                      codeTable={reserveCodes}
                      value={data.ReserveId}
                      io={ioStyle}
                      required="true"
                      label=""
                      requiredMessage={ClaimMessageForamtUtils.formatI18NMsg(
                        i18n.ClaimCommon.I18nUtilValidate,
                        i18n.ClaimReserve.ReserveType
                      )}
                      validationGroup="calculation_submit_validate"
                      widthAllocation="2,10"
                    >
                      <UIParam
                        name="reserveTypeParam"
                        value={{
                          suffixId,
                          dataIndex: data.dataIndex,
                        }}
                      />
                    </UISelect>
                  </div>

                  <div
                    id={`${suffixId + data.dataIndex}reserveTypeTree`}
                    className="reserveTree"
                    style={{
                      display: 'none',
                      position: 'absolute',
                      backgroundColor: '#fff',
                      zIndex: '9',
                      padding: '15px',
                      border: 'solid 1px #e5e5e5',
                    }}
                  >
                    <UITree
                      id={`${suffixId + data.dataIndex}cal_exp_tree`}
                      searchable="true"
                      dataSource={reserveTree}
                      onClick={
                        CalculationLoadAction.chooseExpenseReserve
                      }
                    >
                      <UIParam
                        name="reserveTypeParam"
                        value={{
                          suffixId,
                          dataIndex: data.dataIndex,
                        }}
                      />
                      <UIParam name="insuredId" value={insuredId} />
                      <UIParam
                        name="coverageCode"
                        value={coverageCode}
                      />
                      <UIParam
                        name="reserveId"
                        value={data.ReserveId}
                      />
                      <UIParam name="index" value={data.Index} />
                      <UIParam
                        name="expenseAmount"
                        value={data.ExpenseAmount}
                      />
                    </UITree>
                  </div>
                </div>
              );
            }}
          />
          <UIColumn
            headerTitle={
              _self.props.isApproveTask == true ||
              _self.props.readOnly == false
                ? i18n.ClaimReserve.OutstandingReserve
                : ''
            }
            width={
              _self.props.isApproveTask == true ||
              _self.props.readOnly == false
                ? '20%'
                : 0
            }
            render={data => {
              if (
                _self.props.isApproveTask == true ||
                _self.props.readOnly == false
              ) {
                return (
                  <UICurrency
                    id={`CURRENCY_CODE_${suffixId}${data.Index}_outstandingAmount`}
                    value={data.ReserveAmount}
                    unit={window.EU00.getCurrencySignByCurrencyCode(
                      data.ReserveCurrency
                    )}
                    io="out"
                  />
                );
              }
              return '';
            }}
          />
          <UIColumn
            headerTitle={
              _self.props.isApproveTask == true ||
              _self.props.readOnly == false
                ? i18n.ClaimCalculation.SettledAmount
                : ''
            }
            width={
              _self.props.isApproveTask == true ||
              _self.props.readOnly == false
                ? '20%'
                : 0
            }
            render={data => {
              if (
                _self.props.isApproveTask == true ||
                _self.props.readOnly == false
              ) {
                return (
                  <UICurrency
                    id={`CURRENCY_CODE_${suffixId}${data.Index}_settledAmount`}
                    value={data.SettledAmount}
                    unit={window.EU00.getCurrencySignByCurrencyCode(
                      data.ReserveCurrency
                    )}
                    io="out"
                  />
                );
              }
              return '';
            }}
          />
          <UIColumn
            headerTitle={i18n.ClaimCalculation.ExpenseAmount}
            render={data => {
              return (
                <UICurrency
                  id={`CURRENCY_CODE_${suffixId}${data.dataIndex}cal_exp_exam`}
                  required="true"
                  label=""
                  requiredMessage={ClaimMessageForamtUtils.formatI18NMsg(
                    i18n.ClaimCommon.I18nUtilValidate,
                    i18n.ClaimCalculation.ExpenseAmount
                  )}
                  validationGroup="calculation_submit_validate"
                  allowNegative="false"
                  value={data.ExpenseAmount}
                  unit={window.EU00.getCurrencySignByCurrencyCode(
                    data.CurrencyCode
                  )}
                  io={ioStyle}
                  onChange={CalculationLoadAction.expenseAmountChange}
                  widthAllocation="2,10"
                >
                  <UIParam name="insuredId" value={insuredId} />
                  <UIParam name="coverageCode" value={coverageCode} />
                  <UIParam name="reserveId" value={data.ReserveId} />
                  <UIParam
                    name="settedReserveAmmout"
                    value={data.SettedReserveAmmout}
                  />
                </UICurrency>
              );
            }}
          />
          <UIColumn
            headerTitle={readOnly ? '' : i18n.ClaimCommon.Action}
            width={readOnly ? 0 : '20%'}
            render={data => {
              if (readOnly) {
                return '';
              }
              return (
                <UILink
                  id={`${suffixId + data.dataIndex}cal_exp_rem`}
                  onClick={CalculationLoadAction.deleteExpenseReserve}
                >
                  <UIParam name="insuredId" value={insuredId} />
                  <UIParam name="coverageCode" value={coverageCode} />
                  <UIParam name="reserveId" value={data.ReserveId} />
                  <UIParam
                    name="expenseAmount"
                    value={data.ExpenseAmount}
                  />
                  <UIParam name="index" value={data.Index} />
                </UILink>
              );
            }}
          />
        </UIDataTable>
        {button}
        {hr}
      </div>
    );
  },
});
export default CalculationExpenseInfo;
