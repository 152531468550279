import {
  UIPanel,
  UICell,
  UIRadio,
  MessageHelper,
  CodeTable,
  UIDataTable,
  UIColumn,
  UIBox,
  UIButton,
  UIDialog,
  UIDropZone,
  UITextarea,
  UIDialogFooter,
} from 'RainbowUI';
import BindToMixin from 'react-binding';
import ClaimMatrixAction from '../../action/ClaimMatrixAction';
import Url from '../../url/Url';

const CommonMatrix = React.createClass({
  mixins: [BindToMixin],
  getInitialState() {
    return {
      SearchModel: { IsCommonFlag: '1' },
    };
  },

  uploadHandler() {
    this.setState({ errData: '', Status: 'OK' });
    UIDialog.show('fileUploadDialog');
  },
  handleSuccess(file) {
    let status = '';
    const dataList = file.Messages[0].Message;
    if (dataList !== 'Success') {
      status = 'BLOCK';
    } else {
      status = 'OK';
    }
    const errData = dataList;
    this.setState({ errData, Status: status });
  },

  getVisibled() {
    if (this.state.Status !== 'OK') {
      return true;
    }
    return false;
  },

  render() {
    const uploadUrl = Url.upLoadClaimMatrix;
    const { errData } = this.state;
    return (
      <div>
        <UIPanel panelTitle={i18n.ClaimCommon.SearchCriteria}>
          <UIRadio
            codeTable={this.getSerachCodeTable}
            itemSpacing="100px"
            defaultValue="1"
            valueLink={this.bindToState(
              'SearchModel',
              'IsCommonFlag'
            )}
          />
          <br />
          <UIBox direction="right">
            <UIButton
              value={i18n.ClaimCommon.DownLoad}
              onClick={this.onClickDownload}
            />
            <UIButton
              value={i18n.ClaimCommon.Upload}
              onClick={this.uploadHandler}
            />
          </UIBox>
        </UIPanel>
        {this.renderSearchResult()}
        <UIDialog
          id="fileUploadDialog"
          title={i18n.ClaimCommon.FileUpload}
          width="960px"
        >
          <UIPanel id="panelDropzone" headerable="false">
            <UICell>
              <UIDropZone
                id="dropzone"
                autoUpload="true"
                url={uploadUrl}
                onSuccess={this.handleSuccess}
                onError={this.handleError}
              />
            </UICell>
          </UIPanel>
          <UICell visibled={this.getVisibled}>
            <UITextarea
              id="comments"
              label={i18n.ClaimCommon.ErrorMessageB}
              value={errData}
            />
          </UICell>
          <UIDialogFooter>
            <UIButton
              value={i18n.ClaimCommon.CLOSE}
              position="right"
              onClick={this.hideAllDialog}
            />
          </UIDialogFooter>
        </UIDialog>
      </div>
    );
  },


  renderSearchResult() {
    const isCommonFlag = this.state.SearchModel.IsCommonFlag;
    if (isCommonFlag == '1') {
      return (
        <div>
          <UIPanel panelTitle={i18n.ClaimCommon.SearchResult}>
            <UIDataTable
              indexable="false"
              provider={ClaimMatrixAction.searchProductLineList}
              id="productLineList"
            >
              <UIColumn selectionMode="multiple" enabled="ture" />
              <UIColumn
                headerTitle={i18n.ClaimProduct.ProductLineCode}
                value="ProductLineCode"
                width="20%"
              />
              <UIColumn
                headerTitle={i18n.ClaimProduct.ProductLineName}
                value="ProductLineName"
                width="30%"
              />
              <UIColumn
                headerTitle={i18n.ClaimProduct.ProductLineDescription}
                value="ProductLineDesc"
                width="50%"
              />
            </UIDataTable>
          </UIPanel>
        </div>
      );
    }
  },

  getSerachCodeTable() {
    return new CodeTable([
      { id: '1', text: 'Product Line' },
      { id: '2', text: 'Reserve/Settlement Type' },
    ]);
  },
  onClickDownload() {
    const isCommonFlag = this.state.SearchModel.IsCommonFlag;
    if (isCommonFlag == '1') {
      const recordData = UIDataTable.getSelectedRecord(
        'productLineList'
      );
      if (!recordData) {
        MessageHelper.warning(
          i18n.ClaimCommon.WarnMsg.PleaseSelectOneResult
        );
        return;
      }
      ClaimMatrixAction.downLoadByProductLine(recordData);
    } else {
      ClaimMatrixAction.downLoadForCommon();
    }
  },
  hideAllDialog() {
    UIDialog.hide('fileUploadDialog');
  },
});
export default CommonMatrix;
