import alt from '../alt';
import ClaimPartyAction from '../action/ClaimPartyAction';

class ClaimPartyStore {
  constructor() {
    this.partyVo = {
      AccountList: [],
      AddressVoList: [],
      PartyContactList: [],
    };
    this.partyAuth;

    this.bindListeners({
      handleChangePanel: [
        ClaimPartyAction.LOAD_PARTY,
        ClaimPartyAction.INIT_PARTY_DETAIL_FOR_NEW_CLAIMANT,
      ],
      handlePartyAuth: ClaimPartyAction.GET_PARTY_AUTH,
    });
  }

  handleChangePanel(partyVo) {
    this.partyVo = partyVo;
  }

  handlePartyAuth(partyAuth) {
    this.partyAuth = partyAuth;
  }
}

export default alt.createStore(ClaimPartyStore, ClaimPartyStore);
