import {
  UIPanel,
  UIBox,
  UIText,
  UISmartPanelGrid,
  UIButton,
  UISelect,
} from 'RainbowUI';
import BindToMixin from 'react-binding';
import MassiveReassignmentClaimOwnerAction from '../../action/MassiveReassignmentClaimOwnerAction';

const CodeTableAction = require('../../action/CodeTableAction');
const CodeTableCstUI = require('../../constant/CodeTableCstUI');

const ClaimOwnerSearch = React.createClass({
  mixins: [BindToMixin],
  getInitialState() {
    return { condition: {}, isInital: false };
  },
  clickSearch() {
    this.setState({ isInital: false });
    MassiveReassignmentClaimOwnerAction.searchTask(
      this.state.condition
    );
  },
  Clear() {
    this.setState({ condition: {} });
  },
  render() {
    return (
      <div>
        <div>
          <UIText
            label={i18n.ClaimCommon.MassiveReassignment}
            io="out"
          />
          <UIPanel
            id="searchCriteria"
            panelTitle={
              i18n.ClaimCommon.MassiveReassignmentClaimowner
            }
          >
            <UISmartPanelGrid
              id="idUISmartPanelGrid"
              layout="horizontal"
              column="3"
            >
              <UIText
                id="claimNumber"
                label={i18n.ClaimCommon.ClaimNumber}
                valueLink={this.bindToState(
                  'condition',
                  'ClaimNumber'
                )}
              />
              <UISelect
                id="product_"
                label={i18n.ClaimCommon.Product}
                valueLink={this.bindToState('condition', 'Product')}
                codeTable={CodeTableAction.loadDataList(
                  CodeTableCstUI.ClaimProduct,
                  null
                )}
              />
              <UIText
                id="policyHolder_"
                label={i18n.ClaimCommon.PolicyHolder}
                valueLink={this.bindToState(
                  'condition',
                  'PolicyHolder'
                )}
              />
              <UISelect
                id="claimOwner_"
                label={i18n.ClaimCommon.ClaimOwner}
                autoComplete="true"
                codeTable={CodeTableAction.loadDataList(
                  CodeTableCstUI.ClaimUser,
                  null
                )}
                valueLink={this.bindToState(
                  'condition',
                  'ClaimOwner'
                )}
              />
              <UIText
                id="claimant_"
                label={i18n.ClaimCommon.Claimant}
                valueLink={this.bindToState('condition', 'Claimant')}
              />
              <UISelect
                id="status_"
                label={i18n.ClaimCommon.AssignmentStatus}
                valueLink={this.bindToState(
                  'condition',
                  'LossStatus'
                )}
                codeTable={CodeTableAction.loadDataList(
                  CodeTableCstUI.ClaimLossStatus,
                  null
                )}
              />
            </UISmartPanelGrid>
            <UIBox direction="left">
              <UIButton
                value={i18n.ClaimCommon.Search}
                onClick={this.clickSearch}
              />

              <UIButton
                value={i18n.ClaimCommon.Reset}
                onClick={this.Clear}
              />
            </UIBox>
          </UIPanel>
        </div>
      </div>
    );
  },
});
export default ClaimOwnerSearch;
