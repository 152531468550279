import { CodeTable } from 'RainbowUI';

export default {
  addressFormatTypeOptions: new CodeTable([
    { id: '1', text: 'formated' },
    { id: '2', text: 'UnFormated' },
  ]),
  accountHolderType: new CodeTable([
    { id: '1', text: 'IndividualAccount' },
    { id: '2', text: 'CompanyAccount' },
  ]),
  jointAccountType: new CodeTable([
    { id: '1', text: 'yes' },
    { id: '2', text: 'no' },
  ]),
  status: new CodeTable([
    { id: '1', text: 'valid' },
    { id: '2', text: 'invalid' },
  ]),
  debitAndCreditType: new CodeTable([
    { id: '1', text: 'Both' },
    { id: '2', text: 'Credit' },
    { id: '3', text: 'Debit' },
  ]),
  partyRole: new CodeTable([
    { id: '1', text: 'Employee' },
    { id: '2', text: 'Branch' },
    { id: '3', text: 'Doctor' },
    { id: '4', text: 'Individual Solicitor' },
    { id: '5', text: 'Company Adjuster' },
    { id: '6', text: 'Workshop' },
    { id: '7', text: 'Sparts Vender Company' },
    { id: '8', text: 'Individual Payee' },
    { id: '9', text: 'Organization Payee' },
  ]),
  certiType: new CodeTable([
    { id: '1', text: 'ID Card' },
    { id: '2', text: 'Military ID' },
    { id: '3', text: 'Passport' },
    { id: '4', text: 'Income Tax No' },
    { id: '5', text: 'Drivers Licence' },
    { id: '6', text: 'Birth Certificate' },
    { id: '7', text: 'Dummy' },
    { id: '8', text: 'Others' },
  ]),
  orgIdType: new CodeTable([{ id: '1', text: 'TEST' }]),
  thirdPartyPaymentType: new CodeTable([
    { id: '1', text: 'Alipay' },
    { id: '2', text: 'Weixin' },
  ]),
  isPrimaryAccount: new CodeTable([{ id: '1', text: 'Y' }]),
  accountType: new CodeTable([
    { id: '1', text: 'Auto Debit' },
    { id: '2', text: 'Credit Card' },
    { id: '3', text: 'Third party Payment' },
  ]),
  addressStatus: new CodeTable([
    { id: '1', text: 'valid' },
    { id: '2', text: 'invalid' },
  ]),
  addressType: new CodeTable([
    { id: '1', text: 'Home' },
    { id: '2', text: 'Work' },
  ]),
  isActive: new CodeTable([{ id: '1', text: 'Active' }]),
  isPrimary: new CodeTable([{ id: 'Y', text: 'Primary' }]),
  isPrimartyContact: new CodeTable([
    { id: '1', text: 'Y' },
    { id: '2', text: 'N' },
  ]),
  partyType: new CodeTable([
    { id: '1', text: 'individual' },
    { id: '2', text: 'organization' },
  ]),
  country: new CodeTable([
    { id: '1', text: 'China' },
    { id: '2', text: 'Japan' },
    { id: '3', text: 'America' },
    { id: '4', text: 'England' },
  ]),
  city: new CodeTable([
    { id: '1', text: 'nanjing' },
    { id: '2', text: 'wuxi' },
  ]),
  region: new CodeTable([
    { id: '1', text: 'xinqu' },
    { id: '2', text: 'gaoxinqu' },
  ]),
  bankCode: new CodeTable([
    { id: '1', text: 'CBS' },
    { id: '2', text: 'CBC' },
  ]),
  clmPartyRole: new CodeTable([
    { id: '01', text: 'Claimant' },
    { id: '02', text: 'Witness' },
    { id: '03', text: 'Driver' },
    { id: '04', text: 'Payee' },
    { id: '05', text: 'Attorney' },
    { id: '06', text: 'Car Owner' },
  ]),
  test: new CodeTable([{ id: 'Y', text: '' }]),
};
