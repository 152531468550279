import { AjaxUtil } from 'RainbowUI';

const Url = require('../constant/AppraisalUrl');

export default {
  getClosedTasks(objectId) {
    const deferred = $.Deferred();
    AjaxUtil.doGet(Url.AppraisalClosedTasksLoad + objectId, null, {
      async: false,
      done(data) {
        deferred.resolve(data);
      },
      fail(err) {
        deferred.reject(err);
      },
    });
    return deferred.promise();
  },
};
