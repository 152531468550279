import { MessageHelper, AjaxUtil } from 'RainbowUI';
import Url from '../constant/FieldinvestigationUrl';

class InvestigationService {
  initData(subClaimId, fiStatus) {
    let returnVal = null;
    const url = `${Url.InvestigationInitData}/${subClaimId}/${fiStatus}`;

    AjaxUtil.doGet(
      url,
      {},
      {
        async: false,
        done(data) {
          if (data.Status === 'OK') {
            returnVal = data.Model;
          }
        },
        fail() {
          MessageHelper.error(
            '',
            i18n.ClaimFieldInvestigation.Failed
          );
        },
      }
    );

    return returnVal;
  }

  synButtonIsEnable(taskInfo, damageType) {
    const claimNo = taskInfo.ClaimNumber;
    const subClaimId = taskInfo.AttachTo;
    const url = `${Url.SynButtonIsEnable +
      claimNo}/${subClaimId}/${damageType}`;

    let _enable = false;
    AjaxUtil.doGet(
      url,
      {},
      {
        async: false,
        done(data) {
          if (data.Status === 'OK') {
            _enable = !!data.Model;
          }
        },
        fail() {
          MessageHelper.error(
            '',
            i18n.ClaimFieldInvestigation.Failed
          );
        },
      }
    );
    return _enable;
  }

  synData(subClaimId) {
    const url = `${Url.SynData}/${subClaimId}`;

    let rtnData;
    AjaxUtil.doGet(
      url,
      {},
      {
        async: false,
        done(data) {
          if (data.Status === 'OK') {
            rtnData = data.Model;
          }
        },
        fail() {
          MessageHelper.error(
            '',
            i18n.ClaimFieldInvestigation.Failed
          );
        },
      }
    );
    return rtnData;
  }

  getClosedTasks(objectId) {
    const deferred = $.Deferred();
    AjaxUtil.doGet(
      Url.InvestigationClosedTasksLoad + objectId,
      null,
      {
        async: false,
        done(data) {
          deferred.resolve(data);
        },
        fail(err) {
          deferred.reject(err);
        },
      }
    );
    return deferred.promise();
  }
}

const InvestigationServiceInstance = new InvestigationService();
export default InvestigationServiceInstance;
