import {
  UIPanel,
  UISmartPanelGrid,
  UIText,
  CodeTable,
  UIRadio,
  UITextarea,
} from 'RainbowUI';
import BindToMixin from 'react-binding';

const AppraisalPropertyInfo = React.createClass({
  mixins: [BindToMixin],

  getInitialState() {
    return {
      AppraisalPropertyInfo: this.props.appraisalProperInfo,
    };
  },
  isOwnerCodeTable() {
    return new CodeTable(
      [{ id: 'Y', text: 'Yes' }, { id: 'N', text: 'No' }],
      null,
      null
    );
  },
  changeClaimantIsOwner() {
    this.setState(prevState => {
      const { subClaim } = this.props;
      const appraisalProperty = prevState.AppraisalPropertyInfo;
      if (appraisalProperty.ClaimantIsOwner === 'Y') {
        appraisalProperty.OwnerName = subClaim.ClaimantName;
      } else {
        appraisalProperty.OwnerName = null;
      }
      return {
        AppraisalPropertyInfo: appraisalProperty,
      };
    });
  },
  claimantEnabled() {
    if (!this.state.AppraisalPropertyInfo) {
      return false;
    }
    const appraisalProperty = this.state.AppraisalPropertyInfo;
    if (appraisalProperty.ClaimantIsOwner === 'Y') {
      return true;
    }
    return false;
  },
  render() {
    let style;
    const { history, ioSytle } = this.props;
    if (history) {
      style = 'default';
    } else {
      style = 'primary';
    }
    return (
      <UIPanel
        panelTitle={i18n.ClaimAppraisal.PropertyInfo}
        toggleable="true"
        styleClass={style}
      >
        <UISmartPanelGrid column="3">
          <UIText
            io={ioSytle}
            label={i18n.ClaimAppraisal.PropertyName}
            valueLink={this.bindToState(
              'AppraisalPropertyInfo',
              'PropertyName'
            )}
            required="true"
            validationGroup="AppraisalProperty"
          />
          <UIRadio
            io={ioSytle}
            label={i18n.ClaimAppraisal.ClaimantIsOwner}
            codeTable={this.isOwnerCodeTable}
            valueLink={this.bindToState(
              'AppraisalPropertyInfo',
              'ClaimantIsOwner'
            )}
            onChange={this.changeClaimantIsOwner.bind(this)}
          />
          <UIText
            io={ioSytle}
            label={i18n.ClaimFieldInvestigation.OwnerName}
            valueLink={this.bindToState(
              'AppraisalPropertyInfo',
              'OwnerName'
            )}
            required="true"
            validationGroup="AppraisalProperty"
            disabled={this.claimantEnabled.bind(this)}
          />
          <UITextarea
            enabled={ioSytle === 'out' ? 'false' : 'true'}
            label={
              i18n.ClaimFieldInvestigation.PropertyDamageDescription
            }
            colspan="3"
            valueLink={this.bindToState(
              'AppraisalPropertyInfo',
              'PropertyRemark'
            )}
          />
        </UISmartPanelGrid>
      </UIPanel>
    );
  },
});
export default AppraisalPropertyInfo;
