import {
  Assignment,
  PartyMaintenance,
  LitigationTab,
  PartyMaintenanceAction,
  AssignmentAction,
  ClaimLitigationAction,
  ClaimSummaryTab,
  PremiumDetailTab,
  ClaimHandlingAction,
} from 'ClaimCommon';
import ClaimCommonExt from 'ClaimCommonExt';

const Note = ClaimCommonExt.getNote();
const FileUpload = ClaimCommonExt.getFileUpload();
const ClaimNoteAction = ClaimCommonExt.getClaimNoteAction();
const ReinsuranceInfoTab = ClaimCommonExt.getReinsuranceInfoTab();
const OtherPolicyClaims = ClaimCommonExt.getPriorClaimsDialog();

const AccidentTabStrategy = function (caseForm, isRegistration) {
  return new AccidentTabStrategy.fn(caseForm, isRegistration);
};

let claimData;
let taskInstance;
let claimEntity;
let authMap;
let objectDatas;
let caseId;
let policyNo;
let caseIdParam;
let taskCode;
let taskInstanceId;
let taskInstanceIdParam;
let accidentTabs;

const instance = {};
const strategyContext = {};
AccidentTabStrategy.fn = function (caseForm, isRegistration) {
  this.init(caseForm, isRegistration);
};
AccidentTabStrategy.fn.prototype.init = function (
  caseForm,
  isRegistration
) {
  claimData = caseForm.ClaimData;
  taskInstance = caseForm.TaskInstance;
  claimEntity = caseForm.ClaimEntity;
  authMap = claimData.AuthMap;
  objectDatas = claimData.ObjectDatas;
  caseId = claimEntity.CaseId;
  policyNo = claimEntity.PolicyNo;
  caseIdParam = { name: 'caseId', value: caseId };
  taskCode = taskInstance ? taskInstance.TaskCode : '';
  taskInstanceId = taskInstance ? taskInstance.TaskInstanceId : '';
  taskInstanceIdParam = {
    name: 'taskInstanceId',
    value: taskInstanceId,
  };
  accidentTabs = [];

  strategyContext.DefaultStrategy = this.defaultStrategy();
  strategyContext.FIStrategy = this.FIStrategy();
  strategyContext.RegistrationStrategy = this.RegistrationStrategy();
};

AccidentTabStrategy.fn.prototype.tabs = function (s) {
  const self = this;
  var tabs = [];
  var tabs = strategyContext[s];
  if (!tabs) throw 'incorrect strategy';
  console.info('tabs', tabs);
  tabs.forEach(d => {
    const o = self[d].apply();
    accidentTabs.push(o);
  });
  instance[s] = accidentTabs;
  return accidentTabs;
};
AccidentTabStrategy.fn.prototype.defaultStrategy = function (s) {
  return [
    'Note',
    'Litigation',
    'Assignment',
    'PartyMaintenance',
    'Diary',
    'FileUpload',
    'OtherPolicyClaims',
    'ReinsuranceInfoTab',
    'PremiumDetailTab',
    'ClaimSummaryTab',
  ];
};
AccidentTabStrategy.fn.prototype.FIStrategy = function (s) {
  return [
    'Note',
    'Assignment',
    'Diary',
    'FileUpload',
    'OtherPolicyClaims',
    'ReinsuranceInfoTab',
    'PremiumDetailTab',
    'ClaimSummaryTab',
  ];
};
AccidentTabStrategy.fn.prototype.RegistrationStrategy = function (s) {
  return [
    'Note',
    'Litigation',
    'Assignment',
    'PartyMaintenance',
    'Diary',
    'FileUpload',
    'OtherPolicyClaims',
    'ReinsuranceInfoTab',
    'PremiumDetailTab',
    'ClaimSummaryTab',
  ];
};
AccidentTabStrategy.fn.prototype.Note = function () {
  return {
    title:
      ClaimNoteAction.loadList(caseId, 1, 20).count != 0
        ? `${i18n.ClaimCommon.Note} (Y) `
        : i18n.ClaimCommon.Note,
    action: null,
    params: [],
    content: (
      <Note
        caseId={caseId}
        noteAuth={claimData.AuthMap.NOTES}
        claimNo={claimData.ClaimNo}
        claimEntity={claimEntity}
      />
    ),
  };
};

AccidentTabStrategy.fn.prototype.Litigation = function () {
  return {
    title:
      ClaimLitigationAction.loadLitigations(caseId).length != 0
        ? `${i18n.ClaimCommon.Litigation} (Y) `
        : i18n.ClaimCommon.Litigation,
    action: null,
    params: [],
    content: (
      <LitigationTab
        index={accidentTabs.length + 2}
        subclaimList={objectDatas}
        updateParentTab={ClaimLitigationAction.updateLitigationTabs}
        claimData={claimData}
        claimEntity={claimEntity}
      />
    ),
  };
};

AccidentTabStrategy.fn.prototype.Assignment = function () {
  return {
    title: i18n.ClaimCommon.Assignment,
    action: AssignmentAction.changeTopTab,
    params: [caseIdParam],
    content: (
      <Assignment
        claimEntity={claimEntity}
        caseId={caseId}
        claimNumber={claimData.ClaimNo}
        subclaimList={objectDatas}
        claimStatus={claimData.StatusCode}
        assignAuth={authMap.TASK_ASSIGNMENT}
        userTaskInfo={authMap.USER_TASK_AUTH_INFO}
      />
    ),
  };
};

AccidentTabStrategy.fn.prototype.PartyMaintenance = function () {
  return {
    title: i18n.ClaimParty.ClaimPartyMaintenance,
    action: PartyMaintenanceAction.clickPartyTab,
    params: [],
    content: (
      <PartyMaintenance
        caseId={caseId}
        partyAuth={authMap.CLAIM_PARTY}
        claimEntity={claimEntity}
      />
    ),
  };
};

AccidentTabStrategy.fn.prototype.FileUpload = function () {
  return {
    title: i18n.ClaimCommon.DocumentUpload,
    action: null,
    params: [],
    content: (
      <FileUpload claimNo={caseId} claimEntity={claimEntity} />
    ),
  };
};

AccidentTabStrategy.fn.prototype.Diary = function () {
  return {
    title: i18n.ClaimCommon.Diary,
    action: null,
    params: [],
  };
};
AccidentTabStrategy.fn.prototype.OtherPolicyClaims = function () {
  return {
    title: i18n.ClaimCommon.OtherPolicyClaims,
    action: null,
    params: [],
    content: (
      <OtherPolicyClaims caseId={caseId} />
    ),
  };
};

AccidentTabStrategy.fn.prototype.ReinsuranceInfoTab = function () {
  return {
    title: i18n.ClaimCommon.Reinsurance,
    action: null,
    params: [],
    content: <ReinsuranceInfoTab claimEntity={claimEntity} />,
  };
};

AccidentTabStrategy.fn.prototype.PremiumDetailTab = function () {
  return {
    title: i18n.ClaimCommon.PremiumDetail,
    action: null,
    params: [],
    content: <PremiumDetailTab claimEntity={claimEntity} />,
  };
};

AccidentTabStrategy.fn.prototype.ClaimSummaryTab = function () {
  return {
    title: i18n.ClaimCommon.ClaimSummary,
    action: ClaimHandlingAction.initClaimSummary,
    params: [caseIdParam],
    content: <ClaimSummaryTab claimEntity={claimEntity} />,
  };
};

export default AccidentTabStrategy;
