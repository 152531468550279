import ClaimWorkFlowMyTaskList from './ClaimWorkFlowMyTaskList';
import ClaimWorkFlowMyTaskCriteria from './ClaimWorkFlowMyTaskCriteria';

export default class ClaimWorkFlowMyTask extends React.Component {
  onClickClear() {
    this.refs.MyTaskList.onClickClear();
  }

  render() {
    return (
      <div>
        <ClaimWorkFlowMyTaskCriteria
          ref="MyTaskCriteria"
          parentComponent={this}
        />
        <ClaimWorkFlowMyTaskList
          ref="MyTaskList"
          parentComponent={this}
        />
      </div>
    );
  }
}
