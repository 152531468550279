/**
 * Created by jie.huang on 11/26/2017.
 */
import { UITwoText, UpdateContext } from 'RainbowUI';
import PropTypes from 'prop-types';

export default class CodeTableTextNoValuelink extends UITwoText {
  componentDidMount() {
    super._componentDidMount();
    if (this.props.io !== 'out') {
      this.initEvent();
      this.initProperty();
      this.initValidator();
      UpdateContext.put(this.componentId, this);

      const _self = this;
      $(`#${this.componentId}`).blur(event => {
        if (_self.codeTable) {
          const codeTable = _self.codeTable.getMap();
          if (!codeTable[event.target.value]) {
            _self.setValue(_self.props.value, '');
            $(`#${_self.componentId}`).val('');
            $(`#${_self.componentId}_value`).val('');
          } else {
            $(`#${_self.componentId}_value`).val(
              codeTable[event.target.value][
                config.DEFAULT_CODETABLE_KEYVALUE.VALUE
              ]
            );
          }
        }
      });
    }
  }

  initValue() {
    if (this.codeTable) {
      const codeTable = this.codeTable.getMap();
      const key = this.getComponentValue();
      const value = codeTable[key]
        ? codeTable[key][config.DEFAULT_CODETABLE_KEYVALUE.VALUE]
        : null;

      $(`#${this.componentId}`).val(key);
      $(`#${this.componentId}_value`).val(value);
    }
  }

  renderInput() {
    const inputRef = this.getInputRefProperty();
    let codePlaceHolder;
    let valuePlaceHolder;
    if (Array.isArray(this.props.placeHolder)) {
      codePlaceHolder = this.props.placeHolder[0];
      valuePlaceHolder = this.props.placeHolder[1];
    } else {
      codePlaceHolder = this.props.placeHolder;
      valuePlaceHolder = this.props.placeHolder;
    }
    return (
      <div>
        <div className="input-group col-sm-12 col-md-12 col-lg-12">
          <div
            className="col-sm-6 col-md-6 col-lg-6"
            style={{ padding: '0px', paddingRight: '15px' }}
          >
            <input
              id={this.componentId}
              name={this.getName()}
              type="text"
              className="form-control"
              placeholder={codePlaceHolder}
              ref={inputRef}
              disabled={this.getDisabled()}
              data-auto-test={this.getNameForTest()}
            />
          </div>

          <div
            className="col-sm-6 col-md-6 col-lg-6"
            style={{ padding: '0px' }}
          >
            <input
              id={`${this.componentId}_value`}
              name={`${this.componentId}_value`}
              type="text"
              className="form-control"
              style={{ width: '90%' }}
              placeholder={valuePlaceHolder}
              disabled={this.getDisabled()}
              data-auto-test={`${this.getNameForTest()}_value`}
            />
          </div>

          <span
            className="input-group-addon fixalliconposition"
            style={{ cursor: 'pointer' }}
            onClick={this.props.onIconClick}
          >
            <span className="fa fa-search" />
          </span>
        </div>
      </div>
    );
  }
}
CodeTableTextNoValuelink.propTypes = $.extend(
  {},
  UITwoText.propTypes,
  {
    onIconClick: PropTypes.func,
  }
);
