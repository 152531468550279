export default {
  ProductLineCode: '产品线代码',
  ProductLineName: '产品线名称',
  ProductLineDescription: '产品线描述',
  ProductCode: '产品代码',
  ProductName: '产品名称',
  ProductDescription: '产品描述',
  ExtProductCode: '保单系统代码',
  ProductTypeCode: '产品类型代码',
  ProductTypeName: '产品类型名称',
  ProductTypeDescription: '产品类型描述',
  ExtProductTypeCode: '保单系统代码',
  OptionalCoverageCategories: '可选的险种类型',
  ApplicableCoverageCategories: '适用的险种类型',

  Coverage: '险种',
  CoverageType: '险种类型',
  ReserveType: '准备金类型',
  DefaultReserve: '默认准备金',
  CoverageCategory: '险种类型',
  SettlementType: '决赔类型',
  SubclaimType: '子赔案类型',
  CauseOfLoss: '出险原因',
  ProductType: '产品类型',
  Product: '产品',
  ProductCoverageCategory: '可用的险种',
  ProductLine: '产品线',

  CoverageCode: '险种代码',
  CoverageName: '险种名称',
  CoverageDescription: '险种描述',
  SettlementTypeList: '决赔类型列表',
  SortNo: '序号',
  ExtCoverageCode: '保单系统代码',
  ExtCoverageTypeCode: 'Product Cover Type Code',
  SumInsuredEditable: '保险金额可编辑',
  DefaultCoverage: '默认险种',
  AvailableClaimingCoverage: '可理赔险种',
  PolicySysCode: '保单系统代码',

  ReserveTypeCode: '准备金类型代码',
  ReserveTypeName: '准备金类型名称',
  ReserveTypeDescription: '准备金类型描述',
  ClaimFileType: '准备金大类',
  MultiLanguage: '多语言',
  Positive: '正数',
  SettlementTypeCode: '支付类型代码',
  SettlementTypeName: '支付类型名称',
  Description: '支付类型描述',
  SettlementTypeManagement: '支付方式管理',
  CoverageCategoryCode: '险种类型代码',
  CoverageCategoryName: '险种类型名称',
  CoverageCategoryDescription: '险种类型描述',
  CauseofLossCode: '出险原因代码',
  CauseofLossName: '出险原因名称',
  CauseofLossDescription: '出险原因描述',
  SubclaimTypeCode: '子赔案类型代码',
  SubclaimTypeName: '子赔案类型名称',
  FraudSubjectCode: '欺诈标题编码',
  SubclaimTypeDescription: '子赔案类型描述',
  SubclaimTypeManagement: '损失标的管理',
  IsUnique: '是否唯一',
  IsInsuredObject: '是否第一方',

  // relation
  DamageTypeList: '损失类型列表',
  OptionalDamageTypes: '可选的损失类型',
  ApplicableDamageTypes: '适用的损失类型',
  SubClmTypeDescription: '损失类型详情',
  CoverageCategoryList: '险种类型列表',
  ListofReserveType: '准备金类型列表',
  OptionalReserveTypes: '可选的准备金类型',
  ApplicableReserveTypes: '适用的准备金类型',
  OptionalSettlementTypes: '可选的赔付类型',
  ApplicableSettlementTypes: '适用的赔付类型',
  OptionalSubclaimTypes: '可选的子赔案类型',
  ApplicableSubclaimTypes: '适用的子赔案类型',
  ProductTree: '产品树',
  CoverageDetail: '险种信息',
  Warn: '警告',
  ConfirmMessage: '你确定删除吗？',

  // button
  Remove: '移除',
  Action: '操作',
  Save: '保存',
  Cancel: '取消',
  Search: '查询',
  New: '新建',

  // Message
  removeProductFailMessage:
    '当前参数涉及以下关联关系，如需删除，请预先解除所有关联关系。',
  SaveSuccess: '保存成功!',
  DeleteSuccess: '删除成功',
  DeleteFail: '删除失败',
  IsExist: '已存在',
};
