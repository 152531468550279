import {
  UpdateContext,
  UILink,
  CodeTable,
  UIButton,
  UIText,
  DataContext,
  UIColumn,
  UIDropZone,
  UITextarea,
  UIParam,
  UISmartPanelGrid,
  UIFileDownload,
  UISelect,
  UIPanel,
  UICell,
  UITab,
  UITabItem,
  UIBox,
  UIConfirmDialog,
  UIUpdatePanel,
  UIPage,
  UIDataTable,
  UICheckbox,
  UIEvent,
  Caller,
  UIDialog,
  UIDateTimePicker,
  MessageHelper
} from "RainbowUI";
import DocumentAction from "../../action/DocumentAction";
import CodeTableData from "../../codeTable/CodeTable";
import CodeTableCstUI from "../../constant/CodeTableCstUI";
import CodeTableAction from "../../action/CodeTableAction";
import PartyMaintenanceAction from "../../../claimparty/action/PartyMaintenanceAction";
import TransactionType from './TransactionType';
import BindToMixin from "react-binding";
import UrlConfig from '../../url/Url';
import { CommonAction, DefinCodeTable } from 'EU00PartyCommonUI';
import LetterCommonAction from "../../action/CommonAction";
import ClaimLetterAction from "../../action/ClaimLetterAction";
import PrintType from "../print/PrintType";
import PrintType1 from "../print/PrintType1";
import PrintType2 from "../print/PrintType2";
import EmailRecipientDialog from "./EmailRecipientDialog";

var Document = React.createClass({
  mixins: [BindToMixin],
  getInitialState: function () {
    var moduleName = "Claim";
    console.log("ModuleName" + moduleName);
    var transactionNos = this.props.claimNo;
    var mainTransationNo = transactionNos;
    console.log("transactionNo" + transactionNos);
    var readOnly = this.props.readOnly;
    //this.setState({subClaimSelectVisiable:false});
    let subclaimNos = [];
    let $SubClaims;
    if (this.props.claimEntity) {
      $SubClaims = this.props.claimEntity.ObjectList;
    }
    if ($SubClaims) {
      $SubClaims.map(function (subclaim) {
        if (subclaim["@pk"]) {
          subclaimNos.push({
            "id": subclaim["@pk"],
            "text": i18n.ClaimCommon.Subclaim + " " + subclaim["SeqNo"]
          });
        }
      });
    }
    //this.props.subclaims = subclaimNos;
    let subclaims = subclaimNos;
    //this.props.subclaimNos = new CodeTable(JSON.parse(JSON.stringify(subclaimNos)));
    subclaimNos = new CodeTable(JSON.parse(JSON.stringify(subclaimNos)));
    let relaList = PartyMaintenanceAction.getPartyIdList(this.props.claimEntity.CaseId);
    if (this.props.claimEntity.PolicyEntity.PolicyId) {
      relaList.push(this.props.claimEntity.PolicyEntity.PolicyId);
    }
    let commonAction = new CommonAction();
    let permissionList = commonAction.getPermissionByType(102);
    return {
      relaList: relaList,
      moduleName: moduleName,
      transactionType: null,
      transactionNo: transactionNos,
      mainTransationNo: mainTransationNo,
      readOnly: readOnly,
      subClaimSelectVisiable: false,
      documentModel: {},
      data: { count: 0, result: [] },
      result: {},
      permissionList: permissionList,
      selectDocHisDatas: [],
      subclaims: subclaims,
      subclaimNos: subclaimNos
    }
  },
  componentWillMount: function () {
    console.log("componentWillMount。。。");

    var moduleName = this.state.moduleName;
    var transactionNos = this.state.transactionNo;
    var mainTransationNo = this.state.mainTransationNo;
    var result = DocumentAction.queryDocumentsList(moduleName, mainTransationNo, this.props.subclaims, this.state.relaList);
    for (var i = 0; i < result.length; i++) {
      result[i].DocumentSize = (result[i].DocumentSize / 1024).toFixed(2) + "KB";
      // fileSize = fileSize + "KB";
    }
    console.log("result====>>>>", result);
    var documentModel = {
      ModuleName: moduleName,
      TransactionType: "",
      TransactionNo: transactionNos,
      DocumentType: null,
      DocumentSubType: null,
      Remark: null,
      MainTransationNo: mainTransationNo
    };
    this.setState({ documentModel: documentModel });
    let data = this.state.data;
    data.count = result.length;
    data.result = result;
    this.setState({ data: data });
  },

  removeFileFromDMS(event) {
    this.setState({ clickEvent: event });
    UIConfirmDialog.show("deleteRow");
  },

  previewFileFromDMS(event) {
    var docId = event.getParameter("view").Id;
    let commonAction = new LetterCommonAction();
    commonAction.showPDF(docId);
  },

  downloadFromDMS(event) {
    let commonAction = new LetterCommonAction();
    let data = event.getParameter("download");
    commonAction.ajaxGetBlob(data.Id, null)
  },

  onClickConfirmButtonDelete(event) {
    var deletResult = DocumentAction.removeFileFromDMS(this.state.clickEvent);
    //var transactionTypes = this.state.transactionType;
    var moduleName = this.state.moduleName;
    var mainTransationNo = this.state.mainTransationNo;
    var policyId = this.state.policyId;
    var result = DocumentAction.queryDocumentsList(moduleName, mainTransationNo, this.props.subclaims, this.state.relaList);
    for (var i = 0; i < result.length; i++) {
      result[i].DocumentSize = (result[i].DocumentSize / 1024).toFixed(2) + "KB";
      // fileSize = fileSize + "KB";
    }
    let data = this.state.data;
    data.count = result.length;
    data.result = result;
    this.setState({ data: data });

    UIConfirmDialog.hide("deleteRow");
  },

  onCompleteUpload: function () {
    var mainTransationNo = this.state.mainTransationNo;
    //var transactionTypes = this.state.transactionType;
    var moduleName = this.state.moduleName;
    var transactionNos = this.state.transactionNo;
    var transactionType = DocumentAction.queryTransactionType(transactionNos);
    var result = DocumentAction.queryDocumentsList(moduleName, mainTransationNo, this.props.subclaims, this.state.relaList);
    for (var i = 0; i < result.length; i++) {
      result[i].DocumentSize = (result[i].DocumentSize / 1024).toFixed(2) + "KB";
      // fileSize = fileSize + "KB";
    }
    this.state.documentModel.DocumentType = null;
    this.state.documentModel.DocumentSubType = null;
    this.state.documentModel.Remark = null;
    this.state.documentModel.TransactionType = null;
    this.state.documentModel.SubClaimNumber = null;
    this.state.documentModel.MainTransationNo = null;
    let data = this.state.data;
    data.count = result.length;
    data.result = result;
    this.setState({ data: data });
    UpdateContext.forceUpdate("DocumentSearchResult, documentForm_updatePanel");
    let files = UIDropZone.getDropZone("claim_fileupload_dropZone").getAcceptedFiles();
    for (var i = 0; i < files.length; i++) {
      UIDropZone.getDropZone("claim_fileupload_dropZone").removeFile(files[i]);
    }
  },

  initDocumentModel: function () {
    var documentModel = this.state.documentModel;
    console.log("documentModel", documentModel);
    DocumentAction.onUpload(documentModel);
  },

  getTransactionType: function (caseId) {
    var transactionType = TransactionType.transactionType;
    return transactionType;
  },

  onShowFileInfo: function (event) {
    UIConfirmDialog.addData("deleteFileConfirmDialog", event.getParameter("item"));
    UIConfirmDialog.show("deleteFileConfirmDialog");
  },
  showSubClaimSelect: function (event) {
    let transactionType = event.newValue;
    console.log("====transactionType====" + transactionType);
    if (transactionType == "Field Investigation" || transactionType == "Appraisal")
      this.setState({ "subClaimSelectVisiable": true });
    else {
      this.state.documentModel.SubClaimNumber = null;
      this.setState({ "subClaimSelectVisiable": false });
    }
  },

  getData: function (pageindex, pagesize) {
    return this.state.data;
  },

  componentDidMount() {
    $("#Remark_").css("resize", "vertical");
    $("#Remark_").css("min-height", "105px");
  },
  onUploadDuplicateCheck(event) {

    UIConfirmDialog.hide("duplicateCheck");
  },
  Uploadfailed(event) {
    MessageHelper.warning(i18n.ClaimCommon.WarnMsg.TheSizeOfFileExceedsTheLimitation)
  },

  filterSuccess(letter) {
    return true;//letter.Status == 2;
  },

  querySolr: function (pageindex, pagesize) {
    /*
    var QueryConditions = {
      "Module": "PrintTaskEntity",
      "PageNo": 1,
      "PageSize": 10,
      "SortField": "id",
      "SortType": "desc",
      "Conditions": {
        "TransactionType": "2",
        "TransactionPage": "20",
        "TransactionNo": this.props.claimEntity.CaseId + ""
      },
      "OrConditions": {},
      "BetweenConditions": {}
    }
    QueryConditions.PageNo = Math.ceil(pageindex / pagesize);
    QueryConditions.PageSize = pagesize;
    var setting = { method: 'POST', async: false, block: false };
    let showMsg = false;
    let result = {};
    Caller.call(UrlConfig.QueryOutput, QueryConditions, setting).then(
      (data) => {
        if (data["Total"] > 0) {
          result = { result: data["Results"][0]["SolrDocs"], count: data["Results"][0]["GroupTotalNum"] };
        }
        else {
          if (showMsg != false) {
            MessageHelper.warning(i18n.PrintMessage.WarnMsg.Warn_0003, null, MessageHelper.POSITION_TOP_RIGHT);
          }
        }
      },
      (err) => {
        if (showMsg != false) {
          MessageHelper.error(err["statusText"], null, MessageHelper.POSITION_TOP_RIGHT);
        }
      }
    );
    if (result && result.result) {
      let successLetter = result.result.filter(this.filterSuccess);
      return { count: successLetter.length, result: successLetter };
    } else {
      return { count: 0, result: [] };
    }
    */
  },

  previewDocumentOnline: function (event) {
    var taskId = event.getParameter("data").TaskId;
    var mycars = new Array();
    mycars[0] = taskId;
    const commonAction = new LetterCommonAction();
    commonAction.queryPDF(mycars);

  },
  printDocumentOnline: function (event) {
    var taskId = event.getParameter("data").TaskId;
    var documentType = event.getParameter("data").DocumentType;
    var policyId = event.getParameter("data").TransactionNo;

    var mycars = new Array();
    mycars[0] = taskId;
    const commonAction = new LetterCommonAction();
    commonAction.printPDF(mycars, policyId, documentType);

  },
  showTrail: function (event) {
    var data = event.getParameter("data");
    this.refs.auditTrailDialog.onInitAndShow(data);
  },

  onClickPrint: function (event) {
    var types = this.refs.printType_claim_ref.getSelPrintTypes();;
    var tsno2 = "Claim";
    var tsno3 = null;
    var tsno4 = null;
    var produccode = "MCMP01";

    let caseId = this.props.claimEntity.CaseId;
    if (types == null || types.length == 0 || caseId == null) {
      MessageHelper.warning("Please select document type to print", null, MessageHelper.POSITION_TOP_RIGHT);
      return;
    }
    ClaimLetterAction.onClaimPrint(2, 20, produccode, caseId, types, tsno2, tsno3, null, tsno4);
    UpdateContext.forceUpdate("DocumentLetterList");
  },

  selectDocHis: function (event) {
    var docHis = event.getParameter("data");
    var selectDocHisDatas = this.state.selectDocHisDatas;
    if (docHis && docHis.IsSelect != null && docHis.IsSelect == "Y") {
      selectDocHisDatas.push(docHis.TaskId);
    } else {
      for (var i in selectDocHisDatas) {
        if (docHis.TaskId == selectDocHisDatas[i]) {
          selectDocHisDatas.splice(i, 1);
        }
      }
    }
  },

  onClickDownload: function () {
    var selectDocHisDatas = this.state.selectDocHisDatas;
    new LetterCommonAction().downloadDoc(selectDocHisDatas);
  },

  onClickEmail: function () {
    var selectDocHisDatas = this.state.selectDocHisDatas;
    this.refs.emailRecipientDialog_ref.onInitAndShow(selectDocHisDatas, this.state.EmailProcess);
  },

  render() {
    let _self = this;
    var enable = true;
    var transactionNoSplit = this.state.transactionNo;
    console.log("transactionNo", transactionNoSplit.length);
    if (DataContext.get("dmsUpdate") == true) {
      let relaList = PartyMaintenanceAction.getPartyIdList(this.props.claimEntity.CaseId);
      if (this.props.claimEntity.PolicyEntity.PolicyId) {
        relaList.push(this.props.claimEntity.PolicyEntity.PolicyId);
      }
      this.state.relaList = relaList;
      let moduleName = this.state.moduleName;
      let mainTransationNo = this.state.mainTransationNo;
      var result = DocumentAction.queryDocumentsList(moduleName, mainTransationNo, this.props.subclaims, relaList);
      let data = this.state.data;
      data.count = result.length;
      data.result = result;
      this.state.data = data;
      DataContext.remove("dmsUpdate");
    }

    if (transactionNoSplit.length > 1) {
      enable = false;
    }
    let commonAction = new CommonAction();


    return (
      <div>
        <UIPanel panelTitle="Letter Generation" >
          {/*
          <UITab id="tab01" ref="tab_ref" lazyLoad="false"  >
            <UITabItem title="Claim Level">
              <PrintType ref="printType_claim_ref" showPanelHeader="false" />
            </UITabItem>
            <UITabItem title="SubClaim Level" visibled={false}>

            </UITabItem>

          </UITab>
          <UIBox direction="left">
            <UIButton value="Generate" onClick={this.onClickPrint} styleClass="primary" disabled="false" enabled="true" />
          </UIBox>
          */}
        </UIPanel>
        <UIPanel panelTitle="Letter List">
          {/*
          <UIDataTable id="DocumentLetterList" indexable="false" provider={_self.querySolr} styleClass="primary" >
            <UIColumn render={
              function (data) {
                return (
                  <div className="dochis">
                    <UICheckbox single="true" model={data} property="IsSelect" enabled={data.Status == 2} disabled="false">
                      <UIEvent event="change" listener={_self.selectDocHis}>
                        <UIParam name="data" value={data} />
                      </UIEvent>
                    </UICheckbox>
                  </div>
                );
              }
            }></UIColumn>
            <UIColumn headerTitle="Document Type" value="DocumentName">
            </UIColumn>
            <UIColumn headerTitle="Document Format" value="PrintFormat">
              <UISelect io="out" codeTable={CodeTableData.getPrintFormat} />
            </UIColumn>

            <UIColumn headerTitle="Generation Date" value="RequestDate">
              <UIDateTimePicker io="out" format="DD/MM/YYYY HH:mm:ss" />
            </UIColumn>
            <UIColumn headerTitle="Status" value="Status">
              <UISelect io="out" codeTable={CodeTableData.getPrintStatus()} />
            </UIColumn>
          </UIDataTable>
          <div style={{ marginBottom: "20px" }} >
            <UIBox direction="center">
              <UIButton value="Download" disabled="false" enabled="true" onClick={_self.onClickDownload} styleClass="primary" />
              <UIButton value="Email" disabled="false" enabled="true" onClick={this.onClickEmail} styleClass="primary" />
            </UIBox>
            <EmailRecipientDialog ref="emailRecipientDialog_ref" onStartEmailCallBack={_self.onStartEmailCallBack} onEndEmailCallBack={_self.onEndEmailCallBack} />
            <UIDialog id="errorMessageDialog" width="60%" title={i18n.ErrMessage} >
              <UIUpdatePanel id="errorMessageUpdate" render={function () {
                var msg = _self.state.errorMessage.FailReason;
                return (
                  <UIText io="out" style={{ color: "red" }} value={msg}></UIText>
                );
              }} />
            </UIDialog>
            </div>*/}
        </UIPanel>
        <UIPanel panelTitle={i18n.ClaimCommon.Document}>
          {/*
          <UIConfirmDialog id="deleteRow" message={i18n.ClaimCommon.DeleteMessage} onConfirm={_self.onClickConfirmButtonDelete.bind(_self)} />
          <UIConfirmDialog id="duplicateCheck" title={i18n.ClaimCommon.duplicateDialog} message={i18n.ClaimCommon.DuplicateCheck}
            onConfirm={_self.onUploadDuplicateCheck.bind(_self)} confirmText="EXIT" cancelButton="false" closeable="false" />

          <UIDataTable id="DocumentSearchResult" value={_self.state.data.result} indexable="true" pageable="ture" isHeading="false">
            <UIColumn headerTitle={i18n.ClaimCommon.TransactionType} value="TransactionType">
              <UISelect codeTable={DefinCodeTable.getTransactionTypeCodeTableCodeTable} enabled="false" />
            </UIColumn>
            <UIColumn headerTitle={i18n.ClaimCommon.DocumentType} render={
              function (data) {
                return (
                  <UISelect model={data} property="DocumentType"
                    codeTable={DefinCodeTable.getDocumentTypeCodeTable}
                    enabled="false" />
                );
              }
            }>
            </UIColumn>
            <UIColumn headerTitle={i18n.ClaimCommon.DocumentSubType} render={
              function (data) {
                return (
                  <UISelect model={data} property="DocumentSubType"
                    codeTable={DefinCodeTable.getDocumentSubTypeCodeTable}
                    enabled="false" />

                );
              }
            }>
            </UIColumn>
            <UIColumn headerTitle={i18n.ClaimCommon.SubclaimNumber} render={
              function (data) {
                let _subclaims = _self.props.subclaims;
                let text = "";
                if (_subclaims !== undefined) {
                  for (var i = 0; i < _subclaims.length; i++) {
                    if (data.Path.endsWith(_subclaims[i].id)) {
                      text = _subclaims[i].text;
                      break;
                    }
                  }
                }
                return (<UIText value={text} enabled="false" />);
              }} />
            <UIColumn headerTitle={i18n.ClaimCommon.DocumentName} value="Name" />
            <UIColumn headerTitle={i18n.ClaimCommon.DocumentSize} value="DocumentSize" />
            <UIColumn headerTitle={i18n.ClaimCommon.Remark} render={
              function (data) {
                if (!data.Remark || data.Remark == 'null') {
                  data.Remark = "";
                }
                return (<UITextarea model={data} value={data.Remark} enabled="false" />);
              }} />
            <UIColumn headerTitle={i18n.ClaimCommon.UploadedBy} value="UpdateName" />
            <UIColumn headerTitle={i18n.ClaimCommon.Date} render={
              function (data) {
                return (<UIDateTimePicker model={data} value={data.UpdateTime} enabled="false" io="out" format="DD/MM/YYYY" />);
              }} />
            <UIColumn headerTitle={i18n.ClaimCommon.Action} width="170px" render={
              function (data) {
                return (
                  <div>
                    <UILink value={i18n.ClaimCommon.FilePreview}
                      onClick={_self.previewFileFromDMS.bind(_self)}
                      enabled={_self.state.readOnly}>
                      <UIParam name="view" value={data} />
                    </UILink>
                    <span> </span>
                    <UILink value={i18n.ClaimCommon.FileDownload}
                      onClick={_self.downloadFromDMS.bind(_self)}>
                      <UIParam name="download" value={data} />
                    </UILink>
                    <span> </span>
                    <UILink value={i18n.ClaimCommon.FileDelete} onClick={_self.removeFileFromDMS.bind(_self)} enabled={_self.state.readOnly}>
                      <UIParam name="item" value={data} />
                    </UILink>
                  </div>
                );
              }
            } />
          </UIDataTable>
          */}
        </UIPanel>
        <UIPanel panelTitle={i18n.ClaimCommon.UploadDocument}>
          <UIUpdatePanel id="documentForm_updatePanel">
            <UISmartPanelGrid>
              <UISelect label={i18n.ClaimCommon.TransactionType} codeTable={commonAction.getPermissionByTypeUserIdParentName("Claim_Module")} required='true'
                enabled={this.state.readOnly} validationGroup="document" causeValidation="true" valueLink={this.bindToState("documentModel", "TransactionType")} onChange={this.showSubClaimSelect}>
              </UISelect>
              <UISelect label={i18n.ClaimCommon.DocumentType}
                valueLink={this.bindToState("documentModel", "DocumentType")} required='true'
                visibled="true"
                codeTable={commonAction.getPermissionByTypeUserIdParentName(commonAction.getPermissionCode(_self.state.documentModel.TransactionType, _self.state.permissionList))}
                enabled={this.state.readOnly} causeValidation="true" validationGroup="document" />
              <UISelect label={i18n.ClaimCommon.DocumentSubType}
                valueLink={this.bindToState("documentModel", "DocumentSubType")}
                required='true' visibled="true"
                codeTable={commonAction.getPermissionByTypeUserIdParentName(commonAction.getPermissionCode(_self.state.documentModel.DocumentType, _self.state.permissionList))}
                enabled={this.state.readOnly} causeValidation="true" validationGroup="document" />
              <UISelect label={i18n.ClaimCommon.SubclaimNumber} valueLink={this.bindToState("documentModel", "SubClaimNumber")}
                required="true" visibled="true" codeTable={this.state.subclaimNos} enabled={this.state.readOnly} causeValidation="true" validationGroup="document" />
              <UITextarea id="Remark_" label={i18n.ClaimCommon.Remark} enabled={this.state.readOnly} valueLink={this.bindToState("documentModel", "Remark")} colspan="2" />
              <br />
              <UIDropZone id="claim_fileupload_dropZone"
                autoUpload="false" width="680px" url="/"
                acceptFile=".doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,.jpg,.png,.tiff,.tif,image/bmp,image/gif,application/pdf"
                onSuccess={this.onCompleteUpload.bind(this)} colspan="2" maxFileSize="50" onError={this.Uploadfailed.bind(this)} />
            </UISmartPanelGrid>
          </UIUpdatePanel>
          <UICell style={{ textAlign: "right" }}>
            <UIButton value={i18n.ClaimCommon.Upload} onClick={this.initDocumentModel} styleClass="primary" causeValidation="true" validationGroup="document" />
          </UICell>
          <div>
            <UIBox direction="center" gap="15" padding="0 0 0 0">
              <UIButton value={i18n.Save} enabled="true" disabled="false" onClick="" visibled={"true"} />
              <UIButton value={i18n.Cancel} enabled="true" disabled="false" onClick="" visibled={"true"} />
            </UIBox>
          </div>
        </UIPanel>
      </div>
    );
  }
});

module.exports = Document;