import { UIParam, UILink, UIDataTable, UIColumn } from 'RainbowUI';

const ReserverTypeDialog = require('./ReserverTypeDialog');
const ReserverTypeAction = require('../action/ReserverTypeAction');

const ReserveTypeList = React.createClass({
  getInitialState() {
    return { count: 0 };
  },

  getReserverTypeList(pageindex, pagesize) {
    const reserveTypeList = ReserverTypeAction.getReserverTypeList(
      pageindex,
      pagesize
    );

    return reserveTypeList;
  },

  showReserverTypeDetail(event) {
    this.refs.ReserverTypeDialog_ref.showDialog(
      event.getParameter('data')
    );
  },

  updateReserveType() {
    this.setState(prevState => ({ count: prevState.count + 1 }));
  },

  render() {
    const self = this;
    return (
      <div>
        <UIDataTable
          indexable="false"
          id="reserveTypeListPage_UIDataTable"
          provider={this.getReserverTypeList}
        >
          <UIColumn
            headerTitle={i18n.ClaimProduct.ReserveTypeCode}
            render={data => {
              return (
                <UILink
                  value={data.ReserveTypeCode}
                  onClick={self.showReserverTypeDetail}
                >
                  <UIParam name="data" value={data} />
                </UILink>
              );
            }}
          />
          <UIColumn
            headerTitle={i18n.ClaimProduct.ReserveTypeName}
            value="ReserveTypeName"
          />
          <UIColumn
            headerTitle={i18n.ClaimProduct.ReserveTypeDescription}
            value="ReserveTypeDescription"
          />
          <UIColumn
            headerTitle={i18n.ClaimProduct.ClaimFileType}
            value="ClaimTypeCode"
          />
        </UIDataTable>
        <ReserverTypeDialog
          ref="ReserverTypeDialog_ref"
          parentComponet={this}
        />
      </div>
    );
  },
});
export default ReserveTypeList;
