import {
  UIBox,
  UISelect,
  UIDialog,
  UISmartPanelGrid,
  UIText,
  UIButton,
} from 'RainbowUI';
import PartyAction from '../actions/PartyAction';
import DefinedCodeTable from '../codetable/CodeTable';

const EditAdditionalDialog = React.createClass({
  getInitialState() {
    return {
      partyAdditional: {},
      Status: false,
    };
  },

  onClickClose() {
    UIDialog.hide('AdditionalDialog');
  },

  onSave() {
    const { partyAdditional } = this.state;
    const partyAction = new PartyAction();

    const result = partyAction.checkPartyAdditional(partyAdditional);

    if (result.status === 'success') {
      this.props.submitHandler(partyAdditional);
      UIDialog.hide('AdditionalDialog');
    }
  },

  render() {
    const _self = this;
    const { partyAdditional, Status } = this.state;
    const status = Status;
    return (
      <UIDialog
        id="AdditionalDialog"
        width="90%"
        title={i18n.AdditionalId}
      >
        <UISmartPanelGrid column="3">
          <UISelect
            label={i18n.IdType}
            model={partyAdditional}
            property="IdType"
            codeTable={DefinedCodeTable.IdType}
            required="true"
            validationGroup="UpdateAdditionalInfo"
          />
          <UIText
            label={i18n.IdNo}
            model={partyAdditional}
            property="IdNumber"
            required="true"
            validationGroup="UpdateAdditionalInfo"
          />
          <UISelect
            label={i18n.CountryOfIssue}
            model={partyAdditional}
            property="Country"
            codeTable={DefinedCodeTable.getCountry}
            required="true"
            defaultValue="CY"
            validationGroup="UpdateAdditionalInfo"
          />
          <UISelect
            label={i18n.Status}
            codeTable={DefinedCodeTable.getPartyStatus}
            model={partyAdditional}
            property="Status"
            showBlankOption="false"
            onChange={_self.getInactiveStatus.bind(this)}
          />
          <UISelect
            label={i18n.InactiveReason}
            visibled={status}
            model={partyAdditional}
            property="InactiveReason"
            codeTable={DefinedCodeTable.getInactiveReason}
            required="true"
            validationGroup="UpdateAdditionalInfo"
          />
        </UISmartPanelGrid>
        <UIBox direction="center">
          <UIButton
            value={i18n.Save}
            onClick={_self.onSave}
            validationGroup="UpdateAdditionalInfo"
            causeValidation="true"
          />
          <UIButton
            value={i18n.Cancel}
            onClick={_self.onClickClose}
          />
        </UIBox>
      </UIDialog>
    );
  },

  showDialog(partyAdditional) {
    UIDialog.show('AdditionalDialog');
    this.setState({
      partyAdditional,
    });
  },
  getInactiveStatus() {
    const status = this.state.partyAdditional.Status;
    if (status == '1' || status === undefined || status === null) {
      this.setState({
        Status: false,
      });
    } else {
      this.setState({
        Status: true,
      });
    }
  },
});
export default EditAdditionalDialog;
