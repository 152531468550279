import alt from '../alt';
import ProductTreeAction from '../action/ProductTreeAction';

class ProductTreeStore {
  constructor() {
    this.productCodeTree = [];
    this.bindListeners({
      handleProductTree: ProductTreeAction.LOAD_PRODUCT_CODE_TREE,
    });
  }

  handleProductTree(productCodeTree) {
    this.productCodeTree = productCodeTree;
  }
}
export default alt.createStore(ProductTreeStore, ProductTreeStore);
