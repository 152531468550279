import { AjaxUtil, MessageHelper } from 'RainbowUI';
import alt from '../alt';
import Url from '../url/Url';
import ApprovalService from '../service/ApprovalService';

class ApprovalHistoryAction {
  initData(processObjectId) {
    ApprovalService.initApprovalHistoryData(processObjectId).then(
      data => {
        if (data.Status === 'OK') {
          this.dispatch(data.Model);
        } else {
          MessageHelper.error(
            JSON.stringify(data.Messages),
            i18n.ClaimCommon.ErrorMsg.Error_0002,
            MessageHelper.POSITION_TOP_FULL_WIDTH
          );
        }
      }
    );
  }

  initDataSyn(processObjectId, isHistory) {
    let result = [];
    let url;
    if (!isHistory) {
      url = Url.ApprovalHistory + processObjectId;
    } else {
      url = Url.ApprovalReserveHistoryList + processObjectId;
    }
    AjaxUtil.doGet(
      url,
      {},
      {
        async: false,
        done(data) {
          result = data.Model;
        },
      }
    );
    return result;
  }
}

export default alt.createActions(ApprovalHistoryAction);
