export default class SettlementStoreProcess extends React.Component {
  updatePayee(SettlementData, newPayee) {
    if (newPayee) {
      const { index } = newPayee;
      const { payee } = newPayee;
      const currPayee =
        SettlementData.SettlementInfo.SettlementPayee[index];
      if (currPayee) {
        currPayee.PayeeId = payee.PayeeId;
        currPayee.PayeeName = payee.PayeeName;
        currPayee.PtyAccountId = payee.PtyAccountId;
        currPayee.PtyAddressId = payee.PtyAddressId;
        currPayee.BankCode = payee.BankCode;
        currPayee.AccountNo = payee.AccountNo;
        currPayee.AccountName = payee.AccountName;
        currPayee.Address = payee.Address;
        currPayee.SwiftCode = payee.SwiftCode;
      }
      return SettlementData;
    }
  }

  handleChangeCurrency(TempSettlementData, currency) {
    const SettlementData = TempSettlementData;
    SettlementData.SettlementInfo.SettlementPayee[
      currency.PayeeIndex
    ].ReserveExchangeRate = currency.ReserveExchangeRate;
    return SettlementData;
  }

  handleChangeField(SettlementData) {
    return SettlementData;
  }
}
