import { UIButton, UIText, UISmartPanelGrid } from 'RainbowUI';
import BindToMixin from 'react-binding';

const PartyMaintenanceOption = React.createClass({
  mixins: [BindToMixin],
  getDefaultProps() {
    return { index: '_000' };
  },
  getInitialState() {
    return {
      searchVo: { PartyName: null },
    };
  },
  searchParty() {
    this.refs.AddOrEditPartyDialog_ref.showDialog(
      null,
      'QueryCommonParty'
    );
    this.setState({ title: i18n.ClaimParty.SelectfromCommonParty });
  },

  clickSearch() {
    this.props.parentComponment.searchFromParty(
      this.state.searchVo.PartyName
    );
  },
  render() {
    return (
      <div>
        <UISmartPanelGrid>
          <UIText
            id="partyMaintanceSearch_Id"
            valueLink={this.bindToState('searchVo', 'PartyName')}
            label={i18n.ClaimCommon.PartyName}
          />
          <UIButton
            value={i18n.ClaimCommon.Search}
            onClick={this.clickSearch}
          />
        </UISmartPanelGrid>
      </div>
    );
  },
});
export default PartyMaintenanceOption;
