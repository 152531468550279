import { UIDataTable, UIColumn, UIText } from 'RainbowUI';
import BindToMixin from 'react-binding';

const ClaimPartyAction = require('../action/ClaimPartyAction');

const QueryCommonPartyResult = React.createClass({
  mixins: [BindToMixin],

  getInitialState() {
    return { queryCommonPartyOptions: { isInit: true } };
  },

  getPartyList(pageIndex, pageSize) {
    if (this.state.queryCommonPartyOptions.isInit) {
      return [];
    }
    return ClaimPartyAction.queryCommonParty(
      pageIndex,
      pageSize,
      this.state.queryCommonPartyOptions
    );
  },

  queryOptionsChange(queryCommonPartyOptions) {
    if (!_.isEmpty(queryCommonPartyOptions)) {
      this.setState({
        queryCommonPartyOptions,
      });
    }
  },

  getSelectedRecord() {
    const selectRecord = UIDataTable.getSelectedRecord(
      `QueryCommonPartyResult_${this.props.index}`
    );

    if (selectRecord[0]) {
      this.setState({ selectIndex: selectRecord[0].dataIndex });
    }
    return selectRecord[0] ? selectRecord[0] : '';
  },

  render() {
    const { index } = this.props;
    let { selectIndex } = this.state;
    if (this.state.queryCommonPartyOptions.PartyId) {
      selectIndex = 0;
    }
    return (
      <UIDataTable
        id={`QueryCommonPartyResult_${index}`}
        indexable="false"
        infoable="true"
        selectionMode="single"
        provider={this.getPartyList}
        var="s_partyRole"
        onRowSelect={this.getSelectedRecord}
        isHeading="false"
        selectState={[selectIndex]}
      >
        <UIColumn
          headerTitle={i18n.ClaimParty.PartyName}
          value="PartyName"
        />
        <UIColumn
          headerTitle={i18n.ClaimParty.IDNumberForSearch}
          render={data => {
            if (data.PartyType == 1) {
              return <UIText value={data.IdNumber} io="out" />;
            }
            return (
              <UIText value={data.OrganizationIdNumber} io="out" />
            );
          }}
        />
      </UIDataTable>
    );
  },
});
export default QueryCommonPartyResult;
