import { MessageHelper, AjaxUtil } from 'RainbowUI';

const Q = require('q');
const Url = require('../constant/AppraisalUrl');

export default {
  postPromise(url, payload, successMessage) {
    const deferred = Q.defer();
    const entity = payload || {};
    AjaxUtil.doPost(url, entity, {
      async: false,
      done(data) {
        deferred.resolve(data);
        if (data.Status === 'OK') {
          MessageHelper.success(successMessage);
        }
      },
      fail(err) {
        deferred.reject(err);
        MessageHelper.error(
          err,
          i18n.claimAppraisal.ErrorMsg.Error_0009
        );
      },
    });
    return deferred.promise;
  },
  loadLossItems() {
    const defer = $.Deferred();
    AjaxUtil.doPost(Url.loadLossItems, cancelInfo, {
      async: false,
      done(data, textStatus, jqXHR) {
        if (data) {
          defer.resolve(data);
        } else {
          defer.reject({
            status: jqXHR.status,
            responseText: jqXHR.responseText,
          });
        }
      },
      fail(jqXHR) {
        defer.reject({
          status: jqXHR.status,
          responseText: jqXHR.responseText,
        });
      },
    });
    return defer.promise();
  },

  loadClaimAppraisal(subClaimId) {
    let returnVal = null;
    const url = `${Url.loadClaimAppraisal}?objectId=${subClaimId}`;
    AjaxUtil.doGet(
      url,
      {},
      {
        async: false,
        done(data) {
          if (data.Status === 'OK') {
            returnVal = data.Model;
          }
        },
        fail() {
          MessageHelper.error(
            '',
            i18n.ClaimAppraisal.ErrorMsg.Error_0001
          );
        },
      }
    );
    return returnVal;
  },
  loadClaimAppraisal4Other(subClaimId) {
    let returnVal = null;
    const url = `${Url.loadClaimAppraisal4Other}?objectId=${subClaimId}`;
    AjaxUtil.doGet(
      url,
      {},
      {
        async: false,
        done(data) {
          if (data.Status === 'OK') {
            returnVal = data.Model;
          }
        },
        fail() {
          MessageHelper.error(
            '',
            i18n.ClaimAppraisal.ErrorMsg.Error_0001
          );
        },
      }
    );
    return returnVal;
  },
  showAppraisal(subClaimId) {
    let returnVal = null;
    const url = Url.showAppraisal + subClaimId;
    AjaxUtil.doGet(
      url,
      {},
      {
        async: false,
        done(data) {
          if (data.Status === 'OK') {
            returnVal = data.Model;
          }
        },
        fail() {
          MessageHelper.error(
            '',
            i18n.ClaimAppraisal.ErrorMsg.Error_0001
          );
        },
      }
    );
    return returnVal;
  },
  getProperty(subClaimId) {
    let returnVal = null;
    const url = Url.syncFIData + subClaimId;
    AjaxUtil.doGet(
      url,
      {},
      {
        async: false,
        done(data) {
          if (data.Status === 'OK') {
            returnVal = data.Model;
            MessageHelper.success(
              i18n.ClaimAppraisal.SuccessMsg.Success_0003
            );
          }
        },
        fail() {
          MessageHelper.error(
            '',
            i18n.ClaimAppraisal.ErrorMsg.Error_0001
          );
        },
      }
    );
    return returnVal;
  },
  saveAppraisalProperty(claimAppraisal) {
    const url = Url.saveAppraisalProperty;
    return this.postPromise(
      url,
      claimAppraisal,
      'Appraisal  is saved successfully'
    );
  },
  approveAppraisalProperty(claimAppraisal) {
    let message = '';
    if (claimAppraisal.ApprovalDecision === 'Y') {
      message = 'Appraisal is approved successfully';
    } else {
      message = 'Appraisal is rejected successfully';
    }
    const url = Url.approveAppraisalProperty;
    return this.postPromise(url, claimAppraisal, message);
  },
  submitAppraisalProperty(claimAppraisal) {
    const deferred = $.Deferred();
    const url = Url.submitAppraisalProperty;
    AjaxUtil.doPost(url, claimAppraisal, {
      async: false,
      done(data) {
        deferred.resolve(data);
      },
      fail(jqXHR) {
        deferred.reject({
          status: jqXHR.status,
          responseText: jqXHR.responseText,
        });
      },
    });
    return deferred.promise();
  },
  synButtonIsEnable(taskInfo, damageType) {
    const claimNo = taskInfo.ClaimNumber;
    const subClaimId = taskInfo.AttachTo;
    const url = `${Url.SynButtonIsEnable +
      claimNo}/${subClaimId}/${damageType}`;

    let _enable = false;
    AjaxUtil.doGet(
      url,
      {},
      {
        async: false,
        done(data) {
          if (data.Status === 'OK') {
            _enable = !!data.Model;
          }
        },
        fail() {
          MessageHelper.error(
            '',
            i18n.ClaimAppraisal.ErrorMsg.Error_0001
          );
        },
      }
    );
    return _enable;
  },
};
