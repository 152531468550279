import { UIDialog, UIButton, UIBox, UIText } from 'RainbowUI';

const PaymentFail = React.createClass({
  getInitialState() {
    return {
      message: null,
    };
  },
  componentWillMount() {},
  onshowDialog() {
    UIDialog.show('paymentFail');
  },
  render() {
    const message =
      'Payment Fail. Please try another payment method. ';
    return (
      <UIDialog id="paymentFail" title="Warning" width="50%">
        <UIText value={message} io="out" />
        <UIBox direction="center">
          <UIButton
            enabled="true"
            disabled="false"
            value={i18n.OK}
            onClick={this.onsubmit.bind(this)}
          />
        </UIBox>
      </UIDialog>
      // <UIConfirmDialog id="paymentFail" title="Warning" message={message}
      // 			  cancelButton="false"  confirmText={i18n.OK} onConfirm={this.onsubmit.bind(this)}>
      //     </UIConfirmDialog>
    );
  },
  onsubmit() {
    UIDialog.hide('paymentFail');
  },
});
export default PaymentFail;
