import { RemoteCodeTable } from 'EU00PartyCommonUI';
import {getRegionData} from '../../../../../common/module/common/util/ClaimUtil';

const { CodeTable } = RainbowUI;

export default {
  currency: new CodeTable([
    { id: 'CNY', text: 'CNY' },
    { id: 'EUR', text: 'EUR' },
    { id: 'USD', text: 'USD' },
  ]),

  claimant: new CodeTable([
    { id: '1', text: 'Alfonso Wang' },
    { id: '2', text: 'Cherry Zha' },
  ]),

  insuredObject: new CodeTable([{ id: '1', text: 'Vehicle' }]),

  accidentDistrict: new CodeTable([{ id: '1', text: 'Beijing' }]),

  yesOrNo: new CodeTable([
    { id: '1', text: 'Yes' },
    { id: '2', text: 'No' },
  ]),

  subclaimType: new CodeTable([
    { id: '1_ST01', text: 'FP-PD Property Damages' },
    { id: '1_ST02', text: 'TP-PD Property Damages' },
    { id: '1_ST03', text: 'FP-PD Robbery & Theft' },
    { id: '1_ST04', text: 'TP-BI Body Injury' },
    { id: '1_ST05', text: 'FP-BI Bodily Injury' },
  ]),

  damageType: new CodeTable([
    { id: 'I', text: 'Body Injury' },
    { id: 'P', text: 'Propery' },
    { id: 'V', text: 'Vehicle' },
  ]),

  damageSeverity: new CodeTable([
    { id: '1', text: 'Small' },
    { id: '2', text: 'Medium' },
    { id: '3', text: 'Large' },
  ]),

  placeOfInspection: new CodeTable([
    { id: '1', text: 'Accident Place' },
    { id: '2', text: 'Hospital' },
    { id: '3', text: 'Any Place' },
  ]),

  damageParty: new CodeTable([
    { id: '1', text: '1st Party' },
    { id: '2', text: '3rd Party' },
  ]),

  branch: new CodeTable([
    { id: '1', text: 'Beijing Claim Center' },
    { id: '2', text: 'shanghai claim center' },
    { id: '3', text: 'yangpu claim center' },
  ]),

  country: new CodeTable([
    { id: '1', text: 'China' },
    { id: '2', text: 'United States' },
  ]),

  fnolType: new CodeTable([
    { id: '1', text: 'Assistance Claim' },
    { id: '2', text: 'Service Claim' },
    { id: '3', text: 'Suspense/Normal Claim' },
  ]),

  product: new CodeTable([
    { id: '198', text: 'Private Vehicle' },
    { id: '204', text: 'Travel ' },
  ]),

  causeOfLoss: new CodeTable([
    { id: '1', text: 'UNKNOWN' },
    { id: '2', text: 'Collsion Overturn' },
    { id: '3', text: 'Vadamlision' },
    { id: '4', text: 'Other Perils' },
  ]),
  getCatastropheCode: new CodeTable([
    { id: '0000', text: '0000-NO' },
  ]),
  getContactType: new CodeTable([
    { id: '1', text: 'insured' },
    { id: '2', text: 'Lawyer' },
    { id: '3', text: 'driver' },
  ]),
  getInsuredLiability: new CodeTable([
    { id: '1', text: '100%' },
    { id: '2', text: '90%' },
    { id: '3', text: '80%' },
    { id: '4', text: '70%' },
    { id: '5', text: '50%' },
    { id: '6', text: '50%' },
  ]),
  getValidationDecision: new CodeTable([
    { id: '1', text: 'Accept' },
    { id: '2', text: 'Reject' },
  ]),

  policeStation: new CodeTable([
    { id: 'policeStation1', text: 'policeStation1' },
    { id: 'policeStation2', text: 'policeStation2' },
  ]),

  companyCare: new CodeTable([
    { id: 'Rescue Line', text: 'Rescue Line' },
    { id: 'Kosmos R/A', text: 'Kosmos R/A' },
    { id: 'N/A', text: 'N/A' },
  ]),

  geRegion(countryCode, regionLevel, parentRegionId) {
    return getRegionData(countryCode, regionLevel, parentRegionId);
  },
};
