import alt from '../alt';
import ClaimWorkFlowMyClaimAction from '../action/desktop/ClaimWorkFlowMyClaimAction';

class ClaimWorkFlowMyClaimStore {
  constructor() {
    this.taskSearchOptions = {};

    this.bindListeners({
      handleSearchTaskPool: ClaimWorkFlowMyClaimAction.SEARCH_TASK,
    });
  }

  handleSearchTaskPool(taskSearchOptions) {
    console.log(
      '============taskSearchOptions=taskSearchOptions==',
      taskSearchOptions
    );
    this.taskSearchOptions = taskSearchOptions;
  }
}

export default alt.createStore(
  ClaimWorkFlowMyClaimStore,
  ClaimWorkFlowMyClaimStore
);
