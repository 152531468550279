const home = env.serverUrl;
const urlDefine = {
  subrogationLoad: `${home}subrogation/task/`,

  subrogationHistoryLoad: `${home}subrogation/history/`,
  loadApprovalSubrogationHistory: `${home}subrogation/approve/load/`,

  subrogationReadOnlyLoad: `${home}subrogation/load/`,
  subrogationSave: `${home}subrogation/save`,
  subrogationSubmit: `${home}subrogation/submit`,
  subrogationApprove: `${home}subrogation/approve`,
  subrogationApproveLoad: `${home}common/approve/load/`,
};
export default urlDefine;
