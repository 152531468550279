import alt from '../alt';
import FraudIndicatorAction from '../action/FraudIndicatorAction';

class ClaimFraudIndicatorStore {
  constructor() {
    console.log('------fraud store');
    this.taskSearchOptions = {};
    console.log('------fraud store2', FraudIndicatorAction);
    this.bindListeners({
      loadFraudIndicators: FraudIndicatorAction.INIT_FRAUD,
    });
    console.log('------fraud store3');
  }

  loadFraudIndicators(taskSearchOptions) {
    console.log(
      '============taskSearchOptions=taskSearchOptions==',
      taskSearchOptions
    );
    this.taskSearchOptions = taskSearchOptions;
  }
}

export default alt.createStore(
  ClaimFraudIndicatorStore,
  ClaimFraudIndicatorStore
);
