import {
  UICheckbox,
  UIBox,
  UISelect,
  UIDialog,
  UISmartPanelGrid,
  UIText,
  UIButton,
  UIEmail,
} from 'RainbowUI';
import DefinedCodeTable from '../codetable/CodeTable';
import PartyConst from '../const/PartyConst';

const EditPartyContactDialog = React.createClass({
  getInitialState() {
    return {
      partyContact: {},
      EmailStatus: false,
      MobileStatus: false,
    };
  },

  onClickClose() {
    this.props.resetState();
    UIDialog.hide('ContactDialog');
    this.setState({
      EmailStatus: false,
      MobileStatus: false,
    });
  },

  onSave() {
    const { partyContact } = this.state;
    const middleName = partyContact.MiddleName;
    if (!middleName) {
      partyContact.ContactPerson = `${partyContact.FirstName} ${partyContact.LastName}`;
    } else {
      partyContact.ContactPerson = `${partyContact.FirstName} ${partyContact.MiddleName} ${partyContact.LastName}`;
    }
    if (partyContact.ContactStatus == '2') {
      partyContact.IsPrimaryContact = 'N';
    }
    const result = this.props.submitHandler(partyContact);
    if (!(PartyConst.FAIL == result)) {
      UIDialog.hide('ContactDialog');
    } else {
      this.setState({
        partyContact,
      });
    }
  },

  render() {
    const _self = this;
    const { partyContact, MobileStatus, EmailStatus } = this.state;
    return (
      <UIDialog
        id="ContactDialog"
        width="80%"
        title={i18n.ContactDetail}
      >
        <UISmartPanelGrid column="3">
          <UICheckbox
            single="true"
            singleLabel={i18n.PrimaryContact}
            model={partyContact}
            property="IsPrimaryContact"
            defaultValue="Y"
          />
          <UISelect
            label={i18n.Position}
            codeTable={DefinedCodeTable.getPartyPosition}
            model={partyContact}
            property="Position"
          />
          <UISelect
            label={i18n.Prefix}
            codeTable={DefinedCodeTable.getPrefixCodeTable}
            model={partyContact}
            property="Prefix"
          />
          <UIText
            label={i18n.FirstName}
            model={partyContact}
            property="FirstName"
            required="true"
            validationGroup="UpdateContactInfo"
          />
          <UIText
            label={i18n.MiddleName}
            model={partyContact}
            property="MiddleName"
          />
          <UIText
            label={i18n.LastName}
            model={partyContact}
            property="LastName"
            required="true"
            validationGroup="UpdateContactInfo"
          />
          <UIText
            label={i18n.MobileTel}
            model={partyContact}
            property="HandPhone"
            required={MobileStatus}
            validationGroup="UpdateContactInfo"
          />
          <div id="email">
            <UIEmail
              label={i18n.email}
              model={partyContact}
              property="Email"
              required={EmailStatus}
              validationGroup="UpdateContactInfo"
            />
          </div>
          <UIText
            label={i18n.WorkTel}
            model={partyContact}
            property="BusinessTel"
          />
          <UICheckbox
            single="true"
            label={i18n.ReceiveSMSAlerts}
            onChange={this.setMobileStatus}
            model={partyContact}
            property="ReceiveSMSAlerts"
          />
          <UICheckbox
            single="true"
            label={i18n.ReceiveEmailAlerts}
            onChange={this.setEmailStatus}
            model={partyContact}
            property="ReceiveEmailAlerts"
          />
          <UIText
            label={i18n.FaxNumber}
            model={partyContact}
            property="Fax"
          />
          <UISelect
            label={i18n.Status}
            codeTable={DefinedCodeTable.getPartyStatus}
            model={partyContact}
            property="ContactStatus"
            showBlankOption="false"
          />
        </UISmartPanelGrid>
        <UIBox direction="center">
          <UIButton
            value={i18n.Save}
            onClick={_self.onSave}
            validationGroup="UpdateContactInfo"
            causeValidation="true"
          />
          <UIButton
            value={i18n.Cancel}
            onClick={_self.onClickClose}
          />
        </UIBox>
      </UIDialog>
    );
  },

  showDialog(partyContact) {
    UIDialog.show('ContactDialog');
    let EmailStatus = false;
    let MobileStatus = false;
    if (
      partyContact.ReceiveEmailAlerts &&
      partyContact.ReceiveEmailAlerts === 'Y'
    ) {
      EmailStatus = true;
    }
    if (
      partyContact.ReceiveSMSAlerts &&
      partyContact.ReceiveSMSAlerts === 'Y'
    ) {
      MobileStatus = true;
    }
    this.setState({
      partyContact,
      EmailStatus,
      MobileStatus,
    });
  },

  setEmailStatus() {
    const { EmailStatus } = this.state;
    this.setState({
      EmailStatus: !EmailStatus,
    });
  },
  setMobileStatus() {
    const { MobileStatus } = this.state;
    this.setState({
      MobileStatus: !MobileStatus,
    });
  },
});
export default EditPartyContactDialog;
