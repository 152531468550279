import { AjaxUtil } from 'RainbowUI';

const Url = require('../url/Url');
const mockData = require('../mock/ReserveMockData');

export default {
  initReserve(objectId) {
    const deferred = $.Deferred();
    if (env.IS_MOCK) {
      const result = mockData.reserveListData;
      deferred.resolve(result);
    } else {
      AjaxUtil.doGet(
        Url.ReserveLoad + objectId,
        {},
        {
          async: false,
          done(data) {
            deferred.resolve(data);
          },
          fail(jqXHR) {
            deferred.reject({
              status: jqXHR.status,
              responseText: jqXHR.responseText,
            });
          },
        }
      );
    }
    return deferred.promise();
  },
  confirmReserveApprovalInfo(reserveApproval) {
    const deferred = $.Deferred();
    if (env.IS_MOCK) {
      /* var result=mockData.reserveListData;
             deferred.resolve(result) */
    } else {
      AjaxUtil.doPost(Url.ReserveApprovalInfo, reserveApproval, {
        async: false,
        done(data) {
          deferred.resolve(data);
        },
        fail(jqXHR) {
          deferred.reject({
            status: jqXHR.status,
            responseText: jqXHR.responseText,
          });
        },
      });
    }
  },
  initReserveChanged(reserveId) {
    const deferred = $.Deferred();
    if (env.IS_MOCK) {
      /* var result=mockData.reserveListData;
             deferred.resolve(result) */
    } else {
      AjaxUtil.doGet(
        Url.ReserveChange + reserveId,
        {},
        {
          async: false,
          done(data) {
            deferred.resolve(data);
          },
          fail(jqXHR) {
            deferred.reject({
              status: jqXHR.status,
              responseText: jqXHR.responseText,
            });
          },
        }
      );
    }
    return deferred.promise();
  },
  reserveApprovalTaskAssigned(caseId) {
    const deferred = $.Deferred();

    if (env.IS_MOCK) {
      /* empty */
    } else {
      AjaxUtil.doGet(
        `${Url.ReserveApprovalTask + caseId}/yes`,
        {},
        {
          async: false,
          done(data) {
            deferred.resolve(data);
          },
          fail(jqXHR) {
            deferred.reject({
              status: jqXHR.status,
              responseText: jqXHR.responseText,
            });
          },
        }
      );
    }
    return deferred.promise();
  },

  reserveHistory(itemId) {
    const deferred = $.Deferred();
    if (env.IS_MOCK) {
      const result = mockData.reserveHistory;
      deferred.resolve(result);
    } else {
      AjaxUtil.doGet(
        Url.ReserveHistory + itemId,
        {},
        {
          async: false,
          done(data) {
            deferred.resolve(data);
          },
          fail(jqXHR) {
            deferred.reject({
              status: jqXHR.status,
              responseText: jqXHR.responseText,
            });
          },
        }
      );
    }
    return deferred.promise();
  },
  updateReserveHistory(reserveHisEntities) {
    const deferred = $.Deferred();
    if (env.IS_MOCK) {
      const result = mockData.reserveHistory;
      deferred.resolve(result);
    } else {
      AjaxUtil.doPost(Url.UpdateReserveHistory, reserveHisEntities, {
        async: false,
        done(data) {
          deferred.resolve(data);
        },
        fail(jqXHR) {
          deferred.reject({
            status: jqXHR.status,
            responseText: jqXHR.responseText,
          });
        },
      });
    }
    return deferred.promise();
  },

  showOpenReserve(itemId, caseId) {
    const deferred = $.Deferred();
    if (env.IS_MOCK) {
      const result = mockData.openReserve;
      deferred.resolve(result);
    } else {
      AjaxUtil.doGet(
        `${Url.ReserveOpen}/${itemId}/${caseId}`,
        {},
        {
          async: false,
          done(data) {
            deferred.resolve(data);
          },
          fail(jqXHR) {
            deferred.reject({
              status: jqXHR.status,
              responseText: jqXHR.responseText,
            });
          },
        }
      );
    }
    return deferred.promise();
  },

  saveOpenReserve(openReserveVO) {
    const deferred = $.Deferred();
    AjaxUtil.doPost(Url.ReserveOpen, openReserveVO, {
      async: false,
      done(data) {
        deferred.resolve(data);
      },
      fail(jqXHR) {
        deferred.reject({
          status: jqXHR.status,
          responseText: jqXHR.responseText,
        });
      },
    });
    return deferred.promise();
  },

  closeSubmit(newClosure) {
    let submitUrl = '';
    if (newClosure.ItemId != null) {
      sessionStorage.setItem('claim_closeType', 'item');
      submitUrl = Url.ClaimItemClose;
    } else if (newClosure.CaseId != null) {
      sessionStorage.setItem('claim_closeType', 'case');
      submitUrl = Url.ClaimCaseClose;
    } else if (newClosure.ReserveId != null) {
      sessionStorage.setItem('claim_closeType', 'reserve');
      submitUrl = Url.ClaimReserveClose;
    } else if (newClosure.ObjectId != null) {
      sessionStorage.setItem('claim_closeType', 'object');
      submitUrl = Url.ClaimObjectClose;
    } else {
      sessionStorage.setItem('claim_closeType', '');
      return;
    }
    const deferred = $.Deferred();
    AjaxUtil.doPost(submitUrl, newClosure, {
      async: false,
      done(data) {
        deferred.resolve(data);
      },
      fail(jqXHR) {
        deferred.reject({
          status: jqXHR.status,
          responseText: jqXHR.responseText,
        });
      },
    });
    return deferred.promise();
  },

  onReopenReserve(reserveType) {
    const deferred = $.Deferred();
    if (env.IS_MOCK) {
      const result = mockData.reserveType;
      deferred.resolve(result);
    } else {
      AjaxUtil.doGet(
        Url.ReserveType + reserveType,
        {},
        {
          async: false,
          done(data) {
            deferred.resolve(data);
          },
          fail(jqXHR) {
            deferred.reject({
              status: jqXHR.status,
              responseText: jqXHR.responseText,
            });
          },
        }
      );
    }
    return deferred.promise();
  },

  submitReopen(dataVO) {
    const deferred = $.Deferred();
    AjaxUtil.doPost(Url.ReserveReopen, dataVO, {
      async: false,
      done(data) {
        deferred.resolve(data);
      },
      fail(jqXHR) {
        deferred.reject({
          status: jqXHR.status,
          responseText: jqXHR.responseText,
        });
      },
    });
    return deferred.promise();
  },

  submitCalculate(calculateVO) {
    const deferred = $.Deferred();
    AjaxUtil.doPost(Url.ReserveCalculate, calculateVO, {
      async: false,
      done(data) {
        deferred.resolve(data);
      },
      fail(err) {
        deferred.reject(err);
      },
    });
    return deferred.promise();
  },
};
