import { MessageHelper, Caller } from 'RainbowUI';
import alt from '../alt';

const CalculationService = require('../service/CalculationService');

class CalculationSubmitAction {
  saveCalculation(calculationData) {
    CalculationService.saveCalculation(calculationData).then(
      data => {
        MessageHelper.success(
          '',
          i18n.ClaimCalculation.CalculationSaveSuccess
        );
        this.dispatch(data);
      },
      data => {
        MessageHelper.error(data.Messages[0].Message, '');
      }
    );
  }

  submitCalculation(calculationData) {
    const calculationInfo = calculationData.CalculationInfo;
    if (
      !calculationInfo ||
      !calculationInfo.CalculationItem ||
      calculationInfo.CalculationItem.length < 1
    ) {
      MessageHelper.error(i18n.ClaimCalculation.NoItem, '');
      return;
    }

    const result = CalculationService.submitCalculation(
      calculationData
    );

    if (result !== 'OK') {
      MessageHelper.error(result.Messages[0].Message, '');
    }
    return result;
  }

  calculate(calculationData) {
    const calculationInfo = calculationData.CalculationInfo;
    if (
      !calculationInfo ||
      !calculationInfo.CalculationItem ||
      calculationInfo.CalculationItem.length < 1
    ) {
      MessageHelper.error(i18n.ClaimCalculation.NoItem, '');
      return;
    }

    Caller.doService(
      CalculationService.calculate(calculationData)
    ).then(
      data => {
        MessageHelper.success(
          '',
          i18n.ClaimCalculation.CalculationCalculateSuccess
        );
        this.dispatch(data);
      },
      data => {
        MessageHelper.error(data.Messages[0].Message, '');
      }
    );
  }

  approveCalculation(approveInfo) {
    let flag = false;

    CalculationService.approveCalculation(approveInfo).then(
      data => {
        MessageHelper.success(
          '',
          i18n.ClaimCalculation.CalculattionApprovalSubmitSuccess
        );
        flag = true;
      },
      data => {
        MessageHelper.error(data.Messages[0].Message, '');
      }
    );
    return flag;
  }

  /* saveCalculationApprove(){
		CalculationService.saveCalculationApprove(approveInfo)
		.then(
			(data) => {
				MessageHelper.success('',i18n.ClaimCalculation.CalculattionApprovalSaveSuccess);
			},
			(data) => {
				MessageHelper.error(data.Messages[0].Message,'');
			}
		)
	} */
}
export default alt.createActions(CalculationSubmitAction);
